<div
  *ngIf="
    option &&
    option.product_id_option_id &&
    (!option.dependenciesVariable ||
      option.dependenciesVariable.length === 0 ||
      (option.dependenciesVariable.length > 0 && option.showDependent))
  "
>
  <div
    *ngIf="
      option.type !== 'field' &&
      !(
        option.choices &&
        (option.choices | choicesPreselected).length === 0 &&
        !option.reverseChoices
      )
    "
  >
    <ion-label class="ion-text-wrap">
      <ion-text
        ><strong
          ><i
            class="icon ion-pricetag"
            *ngIf="option.promo && !option.doNotPrint"
          ></i>
          {{ option.doNotPrint ? "" : option.title }}
          {{
            option.parent_free && option.option_parent_total_price === 0
              ? ("orders.free" | translate)
              : option.option_parent_total_price
              ? option.option_parent_total_price + "€"
              : ""
          }}
        </strong></ion-text
      >
      <ion-text
        *ngIf="
          option.type === 'checkbox' &&
          option.reverseChoices &&
          option.choicesComment
        "
      >
        {{ option.choicesComment }}</ion-text
      >
    </ion-label>
    <ion-label
      class="ion-text-wrap"
      *ngIf="
        !option.bundle &&
        !option.choices.doNotPrint &&
        (option.type !== 'checkbox' ||
          !option.reverseChoices ||
          (!option.choicesComment && !option.reverseChoices))
      "
    >
      <ion-text *ngFor="let choice of option.choices | choicesPreselected">
        <ion-text
          ><i
            class="icon ion-pricetag"
            *ngIf="choice.promo && !product.promo"
          ></i>
          {{ choice.printLabel ? choice.printLabel : choice.title }}</ion-text
        >
        <ion-text color="danger" *ngIf="!choice.availableTotal">
          <strong>({{ "orders.not_available" | translate }})</strong>
        </ion-text>
        <ion-text *ngIf="!choice.choicePriceComment"
          ><strong
            >{{ choice.isFree ? ("orders.free_choice" | translate) : "" }}

            {{
              !order.pickup && order.dinein && choice.price_dinein
                ? " " + choice.price_dinein + "€"
                : order.pickup && !order.dinein && choice.price_takeaway
                ? " " + choice.price_takeaway + "€"
                : choice.price === "0.00" || choice.price === 0
                ? ""
                : " " + choice.price + "€"
            }}
          </strong></ion-text
        >
        <ion-text *ngIf="choice.choicePriceComment"
          ><strong>{{ choice.choicePriceComment }}</strong></ion-text
        >
      </ion-text>
    </ion-label>
    <div
      class="ion-text-wrap"
      *ngIf="
        option.bundle &&
        !option.choices.doNotPrint &&
        (option.type !== 'checkbox' ||
          !option.reverseChoices ||
          (!option.choicesComment && !option.reverseChoices))
      "
    >
      <span *ngFor="let choice of option.choices | choicesPreselected">
        <app-order-details-bundle-product-item
          *ngIf="choice && choice.bundle && choice.bundled_product"
          [product]="choice.bundled_product"
          [order]="order"
          [bundle_options]="choice.bundle_options"
        ></app-order-details-bundle-product-item>
      </span>
    </div>
  </div>
  <!--Field option-->
  <ion-label class="ion-text-wrap">
    <p *ngIf="option.type === 'field' && option.text">
      <strong>{{ option.title }}:</strong> {{ option.text }}
    </p>
  </ion-label>
</div>
