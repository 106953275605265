import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { auditTime, distinctUntilChanged, first, Subscription } from "rxjs";
import * as fromApp from "../../store/app.reducer";
import * as _ from "lodash";
import { AnimationOptions } from "ngx-lottie";
import {
  AlertController,
  ModalController,
  Platform,
  PopoverController,
  ToastController,
} from "@ionic/angular";
import { QrcardModalComponent } from "./qrcard-modal/qrcard-modal.component";
import { CampaignItemInformationComponent } from "./campaign-item-information/campaign-item-information.component";
import { Router } from "@angular/router";
import * as LoyaltySystemActions from "../store/loyalty-system.actions";
import { TranslateService } from "@ngx-translate/core";
import { SelectStoreModalComponent } from "./select-store-modal/select-store-modal.component";
import * as CurrentStoreActions from "../../store/current-store/current-store.actions";
import * as selectors from "../../store/app.selectors";
import { ProductPopoverComponent } from "./product-popover/product-popover.component";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import { CreateCouponModalComponent } from "./create-coupon-modal/create-coupon-modal.component";
import { AnalyticsServiceService } from "src/app/services/analitycsService/analytics-service.service";
import { LoginPopupsServiceService } from "src/app/services/login-popups-service/login-popups-service.service";
import * as AuthActions from "../../auth/store/auth.actions";
import { Capacitor } from "@capacitor/core";

import SwiperCore, {
  Autoplay,
  EffectCards,
  EffectCoverflow,
  EffectFade,
  Keyboard,
  Navigation,
  Pagination,
  Swiper,
  SwiperOptions,
  Zoom,
} from "swiper";
import { SwiperComponent } from "swiper/angular";
import { UserWinnerModalComponent } from "./user-winner-modal/user-winner-modal.component";
import { KioskGetPromoModalComponent } from "src/app/kiosk/kiosk-get-promo-modal/kiosk-get-promo-modal.component";
import { LoyaltyContestGiftsComponent } from "../loyalty-contests/loyalty-contest-gifts/loyalty-contest-gifts.component";
import { ContestModalComponent } from "../loyalty-contests/contest-modal/contest-modal.component";
import { MigratePointsComponent } from "../loyalty-more/migrate-points/migrate-points.component";

SwiperCore.use([
  Autoplay,
  Navigation,
  Keyboard,
  Pagination,
  Zoom,
  EffectFade,
  EffectCoverflow,
  EffectCards,
]);
declare let window: any;

@Component({
  selector: "app-loyalty-home",
  templateUrl: "./loyalty-home.page.html",
  styleUrls: ["./loyalty-home.page.scss"],
})
export class LoyaltyHomePage implements OnInit, OnDestroy {
  @ViewChild("swiperSlideShow") swiperSlideShow: SwiperComponent;
  config: SwiperOptions = {};
  public freeCoffeeAnimationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/coffee-date.json",
  };
  public freeBurgerAnimationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/burger.json",
  };
  public noDiscountCouponOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/no-discount-coupon.json",
  };
  public rankUpAnimationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/confetti.json",
  };
  options: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/success-animation.json",
  };
  public imageSettings: any;
  public imagesArray = [];
  public groupApp: any;
  public selectedLanguage: string;
  public flattenedIconBackgroundFlag: boolean = true;
  public storeImage: any;
  public headerLogo: any;
  public darkHeaderLogo: any;
  public currentUser: any;
  public rootDir: string;
  public sliderImages: any;
  public webp: boolean;
  public selectedLogoFileName: string;
  public storeName: string;
  public customerCoupons: any;
  public campaignsArrayRules: any;
  public activeLoyaltyCoupons: number = 0;
  public loading = true;
  public userLoyaltyProducts: any;
  public stores: any;
  public selectedStoreId: any;
  public selectedStoreInfo: any;
  public currentScheduleOfStore: any;
  public windowDimensions: any;
  public bigScreenStyle: boolean;
  public userLoyaltyCampaigns: any[];
  public activeProductsBought: any[] = [];
  public coffeesUsedForRule: any[] = [];
  public burgersUsedForRule: any[] = [];
  public unableToLoadUserLoyaltyProducts: boolean = false;

  private browserPlatform: boolean;
  private freeCoffeeAnimation: any;
  private freeBurgerAnimation: any;
  private unsubscribeBackEvent: Subscription;
  private subscriptions: Subscription[] = [];
  private mo_token: any;
  selectedTheme: any;
  instoreCoupons: any;
  userRanking: any;
  playRankUpAnimation: boolean = false;
  rankUpAnimation: any;
  animationLogin: boolean;
  newCustomer: any;
  animationLoginInstance: any;
  loginState: any;
  activeInStoreCoupons: any;
  stars: number[] = [1, 2, 3, 4, 5];
  selectedStarsValue: any;
  activeContest: any;
  activeSwiperIndex: number = 0;
  groupContests: any;
  activeContestDraws: any;
  drawsSum: any = null;
  latestContestWinners: any;
  winnerModal: HTMLIonModalElement;
  customerLoyaltyCouponRules: any;
  redeemCouponRuleModal: HTMLIonModalElement;
  migrationErrorToast: any;
  migrationModalIsOpen: boolean = false;
  userPoints: any;
  currentTab: any;

  constructor(
    private store: Store<fromApp.AppState>,
    private modalController: ModalController,
    private platform: Platform,
    private router: Router,
    private alertController: AlertController,
    private translateService: TranslateService,
    private popoverController: PopoverController,
    private dataStorageService: DataStorageService,
    private analyticsService: AnalyticsServiceService,
    private loginPopupService: LoginPopupsServiceService,
    private changeDetector: ChangeDetectorRef,
    private toastController: ToastController
  ) {}

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      100,
      () => {
        this.exitApp();
      }
    );

    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.windowDimensions &&
            !_.isEqual(this.windowDimensions, state.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);

            if (this.windowDimensions && this.windowDimensions.width) {
              let width = this.windowDimensions.width;
              if (width > 1000) {
                this.bigScreenStyle = true;
              } else {
                this.bigScreenStyle = false;
              }
            }
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("customerCoupons")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.allCoupons &&
            !_.isEqual(this.customerCoupons, state.allCoupons)
          ) {
            this.customerCoupons = _.cloneDeep(state.allCoupons);
            this.instoreCoupons = _.filter(this.customerCoupons, (coupon) => {
              return coupon.use_type === "instore";
            });
            this.instoreCoupons = _.orderBy(
              _.cloneDeep(this.instoreCoupons),
              ["status", "type", "timestamp"],
              ["asc", "asc", "desc"]
            );
            this.activeInStoreCoupons = _.filter(
              this.instoreCoupons,
              (coupon) => {
                return coupon.status === "active";
              }
            );
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("loyaltySystem")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(200))
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(
              this.unableToLoadUserLoyaltyProducts,
              state.unableToLoadUserLoyaltyProducts
            )
          ) {
            this.unableToLoadUserLoyaltyProducts = _.cloneDeep(
              state.unableToLoadUserLoyaltyProducts
            );
          }
          if (
            state &&
            state.campaignsArray &&
            state.campaignsArray.rules &&
            !_.isEqual(this.campaignsArrayRules, state.campaignsArray.rules)
          ) {
            this.campaignsArrayRules = _.cloneDeep(
              _.filter(state.campaignsArray.rules, { active: true })
            );
            console.log("campaignsArrayRules", this.campaignsArrayRules);
            this.userLoyaltyCampaigns = [];
            _.forEach(this.campaignsArrayRules, (rule) => {
              if (rule.product_type === "coffee") {
                rule["coffees"] = [];
                this.userLoyaltyCampaigns.push(rule);
              } else if (rule.product_type === "burger") {
                rule["burgers"] = [];
                this.userLoyaltyCampaigns.push(rule);
              }
            });
          }
          if (
            state &&
            state.campaignsArray &&
            state.campaignsArray.rules &&
            state.userLoyaltyProducts &&
            !_.isEqual(this.userLoyaltyProducts, state.userLoyaltyProducts)
          ) {
            this.userLoyaltyProducts = _.cloneDeep(state.userLoyaltyProducts);

            this.userLoyaltyCampaigns = [];
            _.forEach(this.campaignsArrayRules, (rule) => {
              if (rule.product_type === "coffee") {
                this.calculateProductsBoughtAndUsedForRule(rule, "coffees");
              } else if (rule.product_type === "burger") {
                this.calculateProductsBoughtAndUsedForRule(rule, "burgers");
              }
            });
            console.log("userLoyaltyCampaigns", this.userLoyaltyCampaigns);
          }
          if (
            state &&
            state.userRanking &&
            !_.isEqual(this.userRanking, state.userRanking)
          ) {
            if (
              this.userRanking &&
              this.userRanking.rank &&
              !_.isEqual(this.userRanking.rank, state.userRanking.rank)
            ) {
              this.playRankUpAnimation = true;
              setTimeout(() => {
                this.playRankUpAnimation = false;
              }, 3500);
            }
            this.userRanking = _.cloneDeep(state.userRanking);
          }

          if (
            state &&
            state.groupContests &&
            !_.isEqual(this.groupContests, state.groupContests)
          ) {
            this.groupContests = _.cloneDeep(state.groupContests);
            console.log("groupContests", this.groupContests);
            const currentDate = Date.now();
            this.activeContest = _.find(this.groupContests, (contest) => {
              return (
                currentDate >= contest.activeFrom &&
                currentDate < contest.activeTo
              );
            });
          }

          if (
            state &&
            state.latestContestWinners &&
            !_.isEqual(this.latestContestWinners, state.latestContestWinners)
          ) {
            this.latestContestWinners = _.cloneDeep(state.latestContestWinners);
            console.log("latestContestWinners", this.latestContestWinners);
            if (this.currentUser) {
              this.checkCurrentUserIfWinner(this.latestContestWinners);
            }
          }

          if (
            state &&
            state.activeContestDraws &&
            !_.isEqual(this.activeContestDraws, state.activeContestDraws)
          ) {
            this.activeContestDraws = _.cloneDeep(state.activeContestDraws);
            console.log("activeContestDraws", this.activeContestDraws);
            this.drawsSum = _.sumBy(
              _.filter(_.cloneDeep(this.activeContestDraws), (draw) => {
                return draw.status === "active";
              }),
              "draws"
            );
            console.log("drawsSum", this.drawsSum);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("platformInfo")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.browserPlatform, state.browserPlatform)
          ) {
            this.browserPlatform = _.cloneDeep(state.browserPlatform);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe()
        .subscribe((storeInfo) => {
          if (storeInfo) {
            console.log("storeINFooo", storeInfo);
            this.selectedStoreInfo = _.cloneDeep(storeInfo);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("points")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.userPoints &&
            !_.isEqual(this.userPoints, state.userPoints)
          ) {
            this.userPoints = _.cloneDeep(state.userPoints);
            console.log("user points", this.userPoints);
          }
        })
    );

    this.groupAppSubscription();
    this.authSubscription();
    this.storesSubscription();
    this.checkStoreSchedule();
    this.currentTab = "points";
  }

  handleSegmentChange(ev) {
    if (ev && ev.target && ev.target.value) {
      this.currentTab = ev.target.value;
    }
  }

  async openMigratePointsModal() {
    const modal = await this.modalController.create({
      component: MigratePointsComponent,
      cssClass: "migratePointsModal",
      backdropDismiss: false,
    });

    await modal.present();
  }

  async showMigrationModal() {
    if (this.currentUser.migration_points_taken) {
      return;
    }

    this.migrationModalIsOpen = true;
    const migrationModalShown = localStorage.getItem("migration_modal_shown");
    if (migrationModalShown) {
      return;
    }

    let isProccessing = false;

    const migrationAlert = await this.alertController.create({
      header: "Μεταφορά Πόντων",
      message:
        "Για να μεταφέρετε τους πόντους σας και τον κωδικό μέλους σας από το παλιό σύστημα στην νέα μας εφαρμογή, εισάγετε το τηλέφωνο ή τον κωδικό μέλους που χρησιμοποιούσατε για τις συναλλαγές σας έως τώρα.",
      inputs: [
        {
          name: "member_number",
          placeholder: "Αριθμός Κάρτας",
        },
      ],
      buttons: [
        {
          text: "OK",
          handler: (value) => {
            let isSuccess = false;
            console.log("value", value);
            if (value.member_number && !isProccessing) {
              isProccessing = true;
              this.dataStorageService
                .migrateCustomerPoints(this.mo_token, value.member_number)
                .subscribe(async (res: any) => {
                  isProccessing = false;

                  if (res?.success) {
                    isSuccess = true;
                    localStorage.setItem(
                      "migration_modal_shown",
                      Date.now().toString()
                    );
                    const toast = await this.toastController.create({
                      header: this.translateService.instant(
                        "Επιτυχής μεταφορά μέλους"
                      ),
                      color: "success",
                      position: "middle",
                      duration: 2000,
                    });
                    await toast.present();
                  } else {
                    if (!this.migrationErrorToast) {
                      this.migrationErrorToast =
                        await this.toastController.create({
                          header:
                            "Αποτυχία: " +
                            this.translateService.instant(
                              "loyaltySystem." + res.comment_id
                            ),
                          color: "danger",
                          position: "middle",
                          duration: 4000,
                        });
                      await this.migrationErrorToast.present();
                      this.migrationErrorToast = null;
                    }
                  }
                });
            }
            return isSuccess;
          },
        },
        {
          text: this.translateService.instant("cancel"),
          handler: () => {
            localStorage.setItem(
              "migration_modal_shown",
              Date.now().toString()
            );
          },
        },
      ],
    });

    migrationAlert.present();
  }

  async checkCurrentUserIfWinner(winners) {
    const winnerModalSeen = localStorage.getItem("winnerModalSeen");
    if (winnerModalSeen) {
      return;
    }
    const userIsWinner = _.find(winners, {
      customer_id: this.currentUser.customer_id,
    });
    if (userIsWinner) {
      if (!this.winnerModal) {
        this.winnerModal = await this.modalController.create({
          component: UserWinnerModalComponent,
          cssClass: "user-winner-modal",
          componentProps: {
            winner: userIsWinner,
          },
          backdropDismiss: false,
        });

        await this.winnerModal.present();
      }
    }
  }

  public onSwiper(swiper): void {
    // This method will be called when the Swiper instance is available.
    swiper.on("slideChange", (slide) => {
      // console.log(slide);
      this.activeSwiperIndex = slide.activeIndex;
    });
  }

  calculateProductsBoughtAndUsedForRule(rule, product_type: string) {
    const productsBought = _.filter(this.userLoyaltyProducts, (product) => {
      return product.loyalty_product_rule_id === rule.loyalty_product_rule_id;
    });
    this.activeProductsBought = _.filter(productsBought, (product) => {
      return product.status === "active";
    });
    rule[product_type] = this.activeProductsBought;
    if (this.activeProductsBought.length > rule.target_quantity) {
      _.orderBy(this.activeProductsBought, ["purchase_timestamp"], ["asc"]);
      if (product_type === "coffees") {
        this.coffeesUsedForRule = this.activeProductsBought.slice(
          0,
          rule.target_quantity
        );
      }
      if (product_type === "burgers") {
        this.burgersUsedForRule = this.activeProductsBought.slice(
          0,
          rule.target_quantity
        );
      }
    } else {
      if (product_type === "coffees") {
        this.coffeesUsedForRule = this.activeProductsBought;
      }
      if (product_type === "burgers") {
        this.burgersUsedForRule = this.activeProductsBought;
      }
    }
    this.userLoyaltyCampaigns.push(rule);
  }

  customerLoyaltyCampaignsTrackBy(index: number, item: any): string {
    return item.loyalty_product_rule_id;
  }

  countStar(star) {
    this.selectedStarsValue = star;
    console.log("Value of star", star);
  }

  groupAppSubscription() {
    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(200))
        .subscribe((state) => {
          console.log("groupAPP state", state);
          this.storeImage = _.cloneDeep(state.groupApp.iconImage);
          this.headerLogo = _.cloneDeep(state.groupApp.headerLogo);
          this.darkHeaderLogo = _.cloneDeep(state.groupApp.headerLogoDark);
          if (
            state &&
            state.imageSettings &&
            state.groupApp &&
            !_.isEqual(this.imageSettings, state.imageSettings)
          ) {
            this.imageSettings = _.cloneDeep(state.imageSettings);
            if (this.browserPlatform && state.groupApp) {
              this.imagesArray = this.findImagesInArray(
                state.groupApp.group + "/home",
                _.cloneDeep(state.groupApp.homeImages)
              );
            }
          }
          if (
            state &&
            state.selectedLangugage &&
            !_.isEqual(this.selectedLanguage, state.selectedLangugage)
          ) {
            this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
          }
          if (
            state &&
            state.selectedTheme &&
            !_.isEqual(this.selectedTheme, state.selectedTheme)
          ) {
            this.selectedTheme = _.cloneDeep(state.selectedTheme);
          }
          if (
            state &&
            state.groupApp &&
            state.groupApp.app_name &&
            !_.isEqual(this.storeName, state.groupApp.app_name)
          ) {
            this.storeName = _.cloneDeep(state.groupApp.app_name);
          }
          if (
            state &&
            state.rootDir &&
            !_.isEqual(this.rootDir, state.rootDir)
          ) {
            this.rootDir = _.cloneDeep(state.rootDir);
          }
          if (
            state &&
            state.groupApp &&
            !_.isEqual(this.groupApp, state.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
            this.imagesArray = this.findImagesInArray(
              this.groupApp.group + "/home",
              _.cloneDeep(this.groupApp.homeImages)
            );
            if (!_.isEmpty(this.groupApp.logosImages)) {
              this.selectedLogoFileName = _.find(this.groupApp.logosImages, {
                selected: true,
              }).fileName;
            }

            if (state && state.webp && !_.isEqual(this.webp, state.webp)) {
              this.webp = _.cloneDeep(state.webp);
              if (this.webp && this.selectedLogoFileName) {
                this.selectedLogoFileName = this.selectedLogoFileName.replace(
                  ".png",
                  "512x512.webp"
                );
              }
            }
            if (!this.webp && this.selectedLogoFileName) {
              this.selectedLogoFileName = this.selectedLogoFileName.replace(
                ".png",
                "512x512.png"
              );
            }
          }
        })
    );
  }

  findImagesInArray(directory, images) {
    var newImages = _.filter(images, (image) => {
      return this.findImageObject(image);
    });
    _.each(newImages, (image, key, list) => {
      if (!_.isObject(image.settings) && image.fileName) {
        newImages[key].path = this.rootDir + directory + "/" + image.fileName;
      } else {
        newImages[key].path =
          this.rootDir + directory + "/" + "16x9" + "/" + image.fileName;
      }
    });

    //Replacing all the images with webp
    if (this.webp) {
      _.each(newImages, function (img, key, list) {
        newImages[key].path = newImages[key].path
          .replace(".jpg", ".webp")
          .replace(".png", ".webp");
      });
    }

    return newImages;
  }

  findImageObject(image) {
    if (
      this.imageSettings &&
      image.settings &&
      ((this.browserPlatform &&
        image.settings[this.imageSettings.imageRatio].tinified &&
        image.settings[this.imageSettings.imageRatio].browser) ||
        (!this.browserPlatform &&
          image.settings[this.imageSettings.imageRatio].tinified &&
          image.settings[this.imageSettings.imageRatio].mobile))
    ) {
      return true;
    } else if (!_.isObject(image.settings) && image.fileName) {
      return true;
    } else {
      return false;
    }
  }

  authSubscription() {
    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(auditTime(200))
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.mo_token &&
            !_.isEqual(this.mo_token, state.mo_token)
          ) {
            this.mo_token = _.cloneDeep(state.mo_token);

            if (this.mo_token) {
              this.store
                .select("groupApp")
                .pipe(first())
                .subscribe((groupAppState) => {
                  this.store
                    .select("currentStore")
                    .pipe(first())
                    .subscribe((currentStoreState) => {
                      this.fetchLoyaltyAppCouponRules(
                        state.mo_token,
                        currentStoreState.currentStoreId,
                        false,
                        groupAppState.groupApp
                          ? groupAppState.groupApp.group
                          : null
                      );
                    })
                    .unsubscribe();
                });
            }
          }
          if (state && !_.isEqual(state.loginState, this.loginState)) {
            this.loginState = _.cloneDeep(state.loginState);
            if (this.loginState === "no_login") {
              this.store
                .select("groupApp")
                .pipe(first())
                .subscribe((groupAppState) => {
                  this.store
                    .select("currentStore")
                    .pipe(first())
                    .subscribe((currentStoreState) => {
                      console.log("fetch coupon rules for no user");
                      this.fetchLoyaltyAppCouponRules(
                        state.mo_token,
                        currentStoreState.currentStoreId,
                        true,
                        groupAppState.groupApp
                          ? groupAppState.groupApp.group
                          : null
                      );
                    })
                    .unsubscribe();
                })
                .unsubscribe();
            }
          }
          if (state && state.loginState === "no_login") {
            this.userLoyaltyProducts = [];
          }
          if (state && state.user && !_.isEqual(state.user, this.currentUser)) {
            this.currentUser = _.cloneDeep(state.user);
            if (this.latestContestWinners) {
              this.checkCurrentUserIfWinner(this.latestContestWinners);
            }
            if (
              this.currentUser &&
              state.loginState === "login_completed" &&
              !this.migrationModalIsOpen
            ) {
              this.showMigrationModal();
            }
            if (state.freshLogin) {
              this.animationLogin = true;
              this.store.dispatch(new AuthActions.SetFreshLogin(false));
            }
          }
          if (state && !_.isEqual(state.newCustomer, this.newCustomer)) {
            this.newCustomer = _.cloneDeep(state.newCustomer);
          }
        })
    );
  }

  storesSubscription() {
    this.subscriptions.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (state && state.stores && !_.isEqual(this.stores, state.stores)) {
            this.stores = _.cloneDeep(state.stores);
            console.log("stores", this.stores);
          }
        })
    );
  }

  async openStoreSelectionModal() {
    const modal = await this.modalController.create({
      component: SelectStoreModalComponent,
      componentProps: {
        stores: this.stores,
        selectedStoreId: this.selectedStoreId,
      },
      backdropDismiss: false,
    });

    await modal.present();

    modal.onDidDismiss().then((data) => {
      if (data && data.data) {
        console.log(data);
        this.store.dispatch(
          new CurrentStoreActions.SetCurrentStoreId(data.data)
        );
      }
    });
  }

  async showRaffleGiftsModal(contest) {
    const modal = await this.modalController.create({
      component: LoyaltyContestGiftsComponent,
      cssClass: "contestModal",
      componentProps: {
        contest,
        group: this.groupApp.group,
      },
    });
    await modal.present();
  }

  async openContestModal() {
    const modal = await this.modalController.create({
      component: ContestModalComponent,
      cssClass: "contestModal",
      componentProps: {
        contest: this.activeContest,
        bigScreenStyle: this.bigScreenStyle,
      },
    });
    await modal.present();
  }

  async presentMagicLoginPopup() {
    this.analyticsService.dmAnalyticsMessage(
      "login_button",
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
    // this.MagicLoginservice.presentLoginModal(this.newCustomer);
    if (!Capacitor.isNativePlatform()) {
      this.loginPopupService.presentFirebaseModalLogin(null, null);
    } else {
      this.loginPopupService.closeMobileMagicLoginAlert();
      this.loginPopupService.presentMobileMagicLoginAlert(
        this.newCustomer,
        this.groupApp
      );
    }
  }

  animationCreated(ev) {
    this.animationLoginInstance = ev;
    this.animationLoginInstance.setSpeed(0.8);
  }

  animationComplete(ev) {
    this.animationLoginInstance.stop();
    this.animationLogin = false;
    this.changeDetector.detectChanges();
  }

  rankUpAnimationCreated(event) {
    this.rankUpAnimation = event;
    this.rankUpAnimation.setSpeed(1);
  }

  freeCoffeeAnimationCreated(ev) {
    this.freeCoffeeAnimation = ev;
    this.freeCoffeeAnimation.setSpeed(1.2);
  }
  freeCoffeeEarnedAnimationCreated(ev) {
    this.freeCoffeeAnimation = ev;
    this.freeCoffeeAnimation.setSpeed(2);
  }
  freeBurgerAnimationCreated(ev) {
    this.freeBurgerAnimation = ev;
    this.freeBurgerAnimation.setSpeed(0.7);
  }
  noDiscountCouponAnimationCreated(ev) {
    const noDiscountCoupon = ev;
    noDiscountCoupon.setSpeed(1);
  }
  freeBurgerEarnedAnimationCreated(ev) {
    this.freeBurgerAnimation = ev;
    this.freeBurgerAnimation.setSpeed(1.2);
  }

  async openQrCodeModal() {
    const modal = await this.modalController.create({
      component: QrcardModalComponent,
      componentProps: {
        currentUser: this.currentUser,
        campaignsArray: this.campaignsArrayRules,
        selectedStoreStoreName: this.selectedStoreInfo.store_name,
        group: this.groupApp.group,
        activeContest: this.activeContest,
        drawsSum: this.drawsSum,
        bigScreenStyle: this.bigScreenStyle,
        userPoints: this.userPoints,
      },
      backdropDismiss: false,
    });

    await modal.present();
  }

  async informationsForCampaign(userLoyaltyProducts, campaign) {
    const modal = await this.modalController.create({
      component: CampaignItemInformationComponent,
      componentProps: {
        campaign: campaign,
        userLoyaltyProducts,
      },
      backdropDismiss: false,
    });

    await modal.present();
  }

  pageChange(route) {
    this.router.navigateByUrl("/" + route, {
      replaceUrl: true,
    });
  }

  private async exitApp() {
    const alert = await this.alertController.create({
      header: this.translateService.instant("alert"),
      message: this.translateService.instant("do-you-want-to-exit-app"),
      buttons: [
        {
          text: this.translateService.instant("cancel"),
          role: "cancel",
          handler: () => {
            console.log("Application exit prevented!");
          },
        },
        {
          text: this.translateService.instant("exit"),
          handler: () => {
            // tslint:disable-next-line: no-string-literal
            if (this.platform.is("android")) {
              window.navigator["app"].exitApp(); // Close this application
            }
          },
        },
      ],
    });
    return await alert.present();
  }

  async presentPopover(ev, product) {
    const popover = await this.popoverController.create({
      component: ProductPopoverComponent,
      cssClass: "my-custom-class",
      event: ev,
      translucent: true,
      componentProps: {
        product: product,
      },
    });
    await popover.present();

    const { role } = await popover.onDidDismiss();
    console.log("onDidDismiss resolved with role", role);
  }

  checkStoreSchedule() {
    this.subscriptions.push(
      this.store.select("currentStore").subscribe((currrentStore) => {
        if (
          currrentStore &&
          currrentStore.currentStoreId &&
          !_.isEqual(this.selectedStoreId, currrentStore.currentStoreId)
        ) {
          this.selectedStoreId = _.cloneDeep(currrentStore.currentStoreId);
          this.store
            .select("groupApp")
            .pipe(first())
            .subscribe((groupApp) => {
              if (groupApp && groupApp.groupApp && groupApp.selectedLangugage) {
                this.dataStorageService
                  .checkStoreSchedule(
                    currrentStore.currentStoreId,
                    groupApp.groupApp.group,
                    groupApp.selectedLangugage
                  )
                  .subscribe({
                    next: async (res: any) => {
                      this.loading = false;
                      if (!res || !res.success) {
                        const alert = await this.alertController.create({
                          header: this.translateService.instant("alert"),
                          message:
                            res && res.comment_id
                              ? this.translateService.instant(res.comment_id)
                              : this.translateService.instant(
                                  "errorMessages.general_error"
                                ),
                          backdropDismiss: false,
                          buttons: ["OK"],
                        });

                        await alert.present();
                      } else {
                        console.log("checkStoreSchedule", res);
                        this.currentScheduleOfStore = _.cloneDeep(res);
                      }
                    },
                    error: async (error) => {
                      this.loading = false;
                      const alert = await this.alertController.create({
                        header: this.translateService.instant("alert"),
                        message: this.translateService.instant(
                          "errorMessages.problem_reaching_server"
                        ),

                        buttons: ["OK"],
                      });
                      await alert.present();
                      return error;
                    },
                  });
              }
            })
            .unsubscribe();
        }
      })
    );
  }

  fetchLoyaltyAppCouponRules(token, store_id, no_user, group) {
    console.log("fetchLoyaltyAppCouponRules");
    (no_user
      ? this.dataStorageService.getNoUserLoyaltyAppCouponRules(store_id, group)
      : this.dataStorageService.getLoyaltyAppCustomerCouponRules(
          token,
          store_id
        )
    ).subscribe({
      next: (res: any) => {
        if (res && res.success) {
          console.log("success", res);
          this.customerLoyaltyCouponRules = res.coupon_rules;
        } else {
          if (res.comment_id) {
            let errorMsg = this.translateService.instant(
              "errorMessages-an-error-occurred"
            );
            this.presentErrorToast(errorMsg, "danger");
          } else {
            this.presentErrorToast(res.comments, "danger");
          }
        }
      },
      error: async (err) => {
        const alert = await this.alertController.create({
          header: this.translateService.instant("alert"),
          message: this.translateService.instant(
            "errorMessages.problem_reaching_server"
          ),

          buttons: ["OK"],
        });
        await alert.present();
        return err;
      },
    });
  }

  async openGetPromoModal(promo, type) {
    this.redeemCouponRuleModal = await this.modalController.create({
      component: KioskGetPromoModalComponent,
      cssClass: "kioskGetPromoModal",
      backdropDismiss: false,
      componentProps: {
        promo: _.cloneDeep(promo),
        type: type,
        groupApp: this.groupApp,
        stores: this.stores,
        showLoginRegisterButton: this.currentUser ? null : true,
      },
    });

    await this.redeemCouponRuleModal.present();

    await this.redeemCouponRuleModal.onDidDismiss().then((data) => {
      if (this.redeemCouponRuleModal) {
        this.redeemCouponRuleModal = null;
      }
      if (data && data.data && data.data === "navigate_coupons_tab") {
        setTimeout(() => {
          // this.segment = "kiosk-coupons";
        }, 300);
      } else if (data && data.data && data.data === "showLoginRegister") {
        this.presentMagicLoginPopup();
      }
    });
  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }

  async createCouponModal(campaign) {
    const modal = await this.modalController.create({
      component: CreateCouponModalComponent,
      cssClass: "createCouponModal",
      backdropDismiss: false,
      componentProps: {
        campaign: campaign,
      },
    });
    await modal.present();
  }

  ngOnDestroy() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    this.subscriptions = [];
  }
}
