<ion-header
  *ngIf="windowInfo"
  [hidden]="windowInfo && windowInfo.width && menuMinWidthToShow &&  windowInfo.width> menuMinWidthToShow"
>
  <app-no-internet-connection></app-no-internet-connection>
  <app-ionic-update-error></app-ionic-update-error>
  <ion-toolbar>
    <ion-title>{{"pages.ratings" | translate}}</ion-title>
    <ion-buttons
      slot="start"
      *ngIf="groupApp && windowInfo"
    >
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="windowInfo">
  <ion-grid
    [class]="windowInfo && windowInfo.width && menuMinWidthToShow &&  windowInfo.width< menuMinWidthToShow ? '': 'ion-padding'"
  >
    <ion-row *ngIf="!lastReviewsArray || (!currentUser && !loginLoading )">
      <ion-col size="12" class="ion-padding ion-text-center">
        <ion-spinner></ion-spinner>
      </ion-col>
    </ion-row>

    <ion-row
      class="ion-padding"
      *ngIf=" lastReviewsArray && !lastReviewsArray.length"
    >
      <ion-col size="12" class="ion-text-center">
        <ion-grid style="width: 130px; height: 130px; padding: 0">
          <ng-lottie [options]="animationNoReviewsOptions"></ng-lottie>
        </ion-grid>
        <ion-text
          ><h5 style="margin: 0">
            {{ "ratings.there-are-no-reviews" | translate }}
          </h5></ion-text
        >
        <ion-grid
          class="ion-no-padding ion-no-margin"
          style="max-width: 400px"
          class="ion-text-center"
        >
          <ion-item
            button
            class="menuButtons"
            color="primary"
            lines="none"
            detail="false"
            (click)="goToOrdersPage()"
          >
            <ion-label
              style="font-weight: 700; padding: 5px"
              class="ion-no-padding ion-no-margin ion-text-center"
            >
              {{("ratings.my-orders" | translate )}}
            </ion-label>
          </ion-item>
          <!-- <ion-item
            *ngIf="orderPickup != null && dineIn != null"
            [disabled]="checkAddressLoading"
            lines="none"
            detail="false"
            button
            class="menuButtons"
            color="primary"
            (click)="checkTheCatalog()"
          >
            <ion-label
              style="font-weight: 700; padding: 5px"
              class="ion-no-padding ion-no-margin ion-text-center"
            >
              <ion-spinner *ngIf="checkAddressLoading"> </ion-spinner>

              <div *ngIf="!checkAddressLoading">
                <div
                  *ngIf="orderPickup == true || (orderPickup==false && dineIn==true) || (orderPickup==false && dineIn==false)"
                >
                  {{ cartProducts && cartProducts.length ?
                  ("homepage.continue-order" | translate ) :
                  ("homepage.continue-to-catalog" | translate )}}
                </div>

                <p
                  *ngIf="orderPickup == true && selectedLanguage"
                  style="font-size: small; padding: 0; margin: 0"
                >
                  {{'homepage.take-away' | translate}}: {{ currentStore &&
                  currentStore.store_name_langs &&
                  currentStore.store_name_langs[selectedLanguage] ?
                  currentStore.store_name_langs[selectedLanguage] : currentStore
                  && currentStore.store_name_langs &&
                  currentStore.store_name_langs.el ?
                  currentStore.store_name_langs.el : currentStore &&
                  currentStore.store_name ? currentStore.store_name : "" }}
                </p>
                <p
                  *ngIf="orderPickup == false && dineIn ==true"
                  style="font-size: small; padding: 0; margin: 0"
                >
                  {{'homepage.dine-in' | translate}}: {{selectedDineInTable ?
                  selectedDineInTable.name : ''}}
                </p>

                <p
                  *ngIf="orderPickup == false && dineIn ==false"
                  style="font-size: small; padding: 0; margin: 0"
                >
                  {{'homepage.delivery' | translate}}: {{selectedAddress ?
                  selectedAddress.formatted_address : '' }}
                </p>
              </div>
            </ion-label>
          </ion-item>
          <ion-item
            *ngIf="orderPickup == null && dineIn == null"
            [disabled]="checkAddressLoading"
            lines="none"
            detail="false"
            button
            class="menuButtons"
            color="primary"
            expand="block"
            (click)="presentOrderNowModal()"
            ><ion-label
              style="font-weight: 700; padding: 10px"
              class="ion-text-wrap ion-no-padding ion-no-margin ion-text-center"
            >
              <div>{{"homepage.new-order"| translate}}</div>
            </ion-label></ion-item
          > -->
        </ion-grid>
      </ion-col>
    </ion-row>

    <span *ngIf="lastReviewsArray && lastReviewsArray.length > 0">
      <app-ratings-item
        *ngFor="let review of lastReviewsArray; trackBy: trackFn"
        [currentReview]="review"
      >
      </app-ratings-item>
    </span>
  </ion-grid>
  <div
    *ngIf="groupApp &&
      groupApp.design_settings &&
      groupApp.design_settings.menuFooterTabs && windowInfo && windowInfo.width<900"
    style="margin-top: 65px"
  ></div>
</ion-content>

<app-menu-footer-tabs></app-menu-footer-tabs>
