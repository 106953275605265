import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { Subscription, distinctUntilChanged } from "rxjs";
import * as fromApp from "../../store/app.reducer";
import * as _ from "lodash";
import { AnalyticsServiceService } from "src/app/services/analitycsService/analytics-service.service";
import { LoginPopupsServiceService } from "src/app/services/login-popups-service/login-popups-service.service";
import { Capacitor } from "@capacitor/core";

declare let window: any;

@Component({
  selector: "app-fixed-address-info-popup",
  templateUrl: "./fixed-address-info-popup.component.html",
  styleUrls: ["./fixed-address-info-popup.component.scss"],
})
export class FixedAddressInfoPopupComponent implements OnInit, OnDestroy {
  @Input() fixed_address: any;
  public currentUser: any;
  public mo_token: any;
  public loginState: any;
  public newCustomer: any;
  public groupApp: any;

  private subscription2: Subscription;
  private subscription: Subscription;
  private unsubscribeBackEvent: Subscription;

  constructor(
    private modalController: ModalController,
    private analyticsService: AnalyticsServiceService,
    private loginPopupsService: LoginPopupsServiceService,
    private platform: Platform,
    private store: Store<fromApp.AppState>
  ) {}

  closeModal() {
    this.modalController.dismiss();
  }

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );
    this.subscription2 = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.groupApp &&
          !_.isEqual(this.groupApp, state.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp);
        }
      });

    this.subscription = this.store
      .select("auth")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (state && !_.isEqual(state.newCustomer, this.newCustomer)) {
          this.newCustomer = _.cloneDeep(state.newCustomer);
        }
        if (state && !_.isEqual(state.user, this.currentUser)) {
          this.currentUser = _.cloneDeep(state.user);
        }

        if (state && !_.isEqual(state.mo_token, this.mo_token)) {
          this.mo_token = _.cloneDeep(state.mo_token);
        }

        if (state && !_.isEqual(state.loginState, this.loginState)) {
          this.loginState = _.cloneDeep(state.loginState);
        }
      });
  }

  onClickOk() {
    if (this.currentUser && this.mo_token) {
      this.modalController.dismiss("accepted");
    } else {
      this.presentMagicLoginPopup();
    }
  }

  async presentMagicLoginPopup() {
    this.analyticsService.dmAnalyticsMessage(
      "login_button",
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
    // this.MagicLoginservice.presentLoginModal(this.newCustomer);
    if (!Capacitor.isNativePlatform()) {
      this.loginPopupsService.presentFirebaseModalLogin(null, null);
    } else {
      this.loginPopupsService.closeMobileMagicLoginAlert();
      this.loginPopupsService.presentMobileMagicLoginAlert(
        this.newCustomer,
        this.groupApp
      );
    }
  }

  ngOnDestroy(): void {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscription2) {
      this.subscription2.unsubscribe();
    }
    this.loginState = null;
    this.groupApp = null;
    this.currentUser = null;
    this.fixed_address = null;
  }
}
