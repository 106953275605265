<ion-item
  *ngIf="category"
  [ngClass]="{
    categoryItem:
      !(
        groupStoreData &&
        groupStoreData.store_info &&
        groupStoreData.store_info.design_settings &&
        groupStoreData.store_info.design_settings.overFlowImageDesign
      ) || !(category && categoryHasAnimation),
    overFlowImgProductCategoryItem:
      !categoryHasAnimation &&
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.overFlowImageDesign,

    categoryItemAnimationRight:
      category &&
      categoryHasAnimation &&
      !(
        groupStoreData &&
        groupStoreData.store_info &&
        groupStoreData.store_info.design_settings &&
        groupStoreData.store_info.design_settings.overFlowImageDesign
      ),

    overFlowImgProductcategoryItemAnimationRight:
      category &&
      categoryHasAnimation &&
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.overFlowImageDesign
  }"
  [id]="category.category_id"
  [lines]="
    groupStoreData &&
    groupStoreData.store_info &&
    groupStoreData.store_info.design_settings &&
    groupStoreData.store_info.design_settings.overFlowImageDesign
      ? 'none'
      : ''
  "
>
  <ion-row style="width: 100%">
    <ion-col size="12" class="noPaddingCols">
      <strong style="font-size: larger"
        ><ion-text color="primary">{{ category.name }}</ion-text></strong
      >
    </ion-col>
    <ion-col
      size="12"
      class="noPaddingCols"
      *ngIf="!category.availableTotal || category.avpc || category.tags"
    >
      <app-category-item-tags
        [categoryItem]="category"
        [groupStoreData]="groupStoreData"
      ></app-category-item-tags>
      <!-- {{ "catalog.categories.category-item.unavailable-category" | translate }} -->
    </ion-col>

    <ion-col size="12" class="DescriptionPaddings" *ngIf="category.description">
      <span
        [ngClass]="{
          'unavailable line-through ': !!!category.availableTotal
        }"
        class="fontSizeSmaller"
      >
        <app-description
          [three_lines_description]="true"
          [description]="category.description"
        ></app-description>
      </span>
    </ion-col>
    <ion-col
      size="12"
      class="noPaddingCols"
      *ngIf="category.promotions && category.promotions.length > 0"
    >
      <div
        *ngFor="
          let promo of category.promotions;
          let i = index;
          trackBy: trackFunc
        "
        class="categoriesWarningColor"
      >
        <span style="font-weight: bold; font-size: small">{{
          promo.description
        }}</span>
      </div>
    </ion-col>
  </ion-row>

  <ion-grid
    *ngIf="
      selectedCategoryImageAnimation &&
      groupStoreData &&
      groupStoreData.store_info
    "
    slot="end"
    style="max-height: 60px; max-width: 60px; padding: 0; z-index: -1"
  >
    <img
      loading="lazy"
      width="100%"
      [src]="
        'https://images.deliverymanager.gr/' +
        groupStoreData.store_info.store_id +
        '/categories/catalogCategoryAnimationImages/' +
        selectedCategoryImageAnimation.fileName
      "
    />
  </ion-grid>
</ion-item>
<ion-row *ngIf="category && !category.products" style="width: 100%">
  <ion-col class="ion-text-center">
    <ion-spinner></ion-spinner>
  </ion-col>
</ion-row>
