import { Action } from "@ngrx/store";

export const SET_STORES = "[STORES] Set Stores";
export const SET_CHECK_STORE_INFO = "[STORES] Set Check Store Info";
export const CLEAR_STORES = "[STORES] Clear Stores";
export const SET_STORES_VARIABLES = "[STORES] Set Store Variables";

export class SetStores implements Action {
  readonly type = SET_STORES;
  constructor(public payload: any) {}
}

export class SetCheckStoreInfo implements Action {
  readonly type = SET_CHECK_STORE_INFO;
  constructor(public payload: any) {}
}

export class ClearStores implements Action {
  readonly type = CLEAR_STORES;
  constructor() {}
}

export type StoresActions = SetStores | ClearStores | SetCheckStoreInfo;
