import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  AlertController,
  ModalController,
  ToastController,
} from "@ionic/angular";
import * as fromApp from "../../../store/app.reducer";
import * as _ from "lodash";
import { Store } from "@ngrx/store";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import { Subscription, distinctUntilChanged, first } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import * as LoyaltySystemActions from "../../store/loyalty-system.actions";
import { AnimationOptions } from "ngx-lottie";
import { AnalyticsServiceService } from "src/app/services/analitycsService/analytics-service.service";
import { LoginPopupsServiceService } from "src/app/services/login-popups-service/login-popups-service.service";
import { Capacitor } from "@capacitor/core";
import { each } from "lodash";

@Component({
  selector: "app-contest-modal",
  templateUrl: "./contest-modal.component.html",
  styleUrls: ["./contest-modal.component.scss"],
})
export class ContestModalComponent implements OnInit, OnDestroy {
  @Input() contest;
  @Input() bigScreenStyle;
  public noDrawsOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/sad-emoji-1.json",
  };
  public contestCustomerDraws: any = null;
  public drawsSum: any = null;
  public currentUser: any;
  public newCustomer;
  public loginLoading;
  public profileAnimation;
  public groupApp;
  public profileAnimationOptions;
  public customer_draw_numbers: any;
  public winner_draws: any[] = [];
  public alternate_draws: any[] = [];
  private selectedTheme;
  private authSubscription: Subscription;
  private groupAppSub: Subscription;

  constructor(
    private modalController: ModalController,
    private store: Store<fromApp.AppState>,
    private dataStorageService: DataStorageService,
    private translateService: TranslateService,
    private toastController: ToastController,
    private alertController: AlertController,
    private analyticsService: AnalyticsServiceService,
    private LoginPopupsService: LoginPopupsServiceService
  ) {}

  ngOnInit() {
    console.log("contest in contest-modal", this.contest);
    this.fetchCustomerDraws(
      this.contest.contest_id,
      this.contest.winners_fileName ? this.contest.winners_fileName : null,
      this.contest.status
    );

    this.groupAppSub = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.groupApp &&
          !_.isEqual(state.groupApp, this.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp);
        }

        if (
          state &&
          state.selectedTheme &&
          !_.isEqual(state.selectedTheme, this.selectedTheme)
        ) {
          this.selectedTheme = _.cloneDeep(state.selectedTheme);
          if (this.selectedTheme && this.selectedTheme === "dark") {
            this.profileAnimationOptions = {
              ...this.profileAnimationOptions,
              path: "https://data.deliverymanager.gr/animations/profile-animation-dark.json",
            };
          } else if (this.selectedTheme && this.selectedTheme === "light") {
            this.profileAnimationOptions = {
              ...this.profileAnimationOptions,
              path: "https://data.deliverymanager.gr/animations/profile-animation.json",
            };
          }
        }
      });

    this.profileAnimation = true;

    this.authSubscription = this.store
      .select("auth")
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (state && state.user && !_.isEqual(state.user, this.currentUser)) {
          if (!this.currentUser && state.user) {
            this.fetchCustomerDraws(
              this.contest.contest_id,
              this.contest.winners_fileName
                ? this.contest.winners_fileName
                : null,
              this.contest.status
            );
          }
          this.currentUser = _.cloneDeep(state.user);
        }
        if (state && !_.isEqual(state.newCustomer, this.newCustomer)) {
          this.newCustomer = _.cloneDeep(state.newCustomer);
        }
        if (state && !_.isEqual(state.loginLoading, this.loginLoading)) {
          this.loginLoading = _.cloneDeep(state.loginLoading);
        }
      });
  }

  closeModal() {
    this.modalController.dismiss();
  }

  fetchCustomerDraws(contestId, winners_fileName, status) {
    this.store
      .select("auth")
      .pipe(first())
      .subscribe((state) => {
        if (state && state.mo_token) {
          this.dataStorageService
            .fetchCustomerDraws(
              _.cloneDeep(state.mo_token),
              contestId,
              winners_fileName,
              status
            )
            .subscribe({
              next: async (res: any) => {
                if (res && res.success) {
                  console.log("fetchCustomerDraws", res);
                  if (res.customerDraws) {
                    this.contestCustomerDraws = _.cloneDeep(
                      _.orderBy(res.customerDraws, ["timestamp"], ["desc"])
                    );
                    this.store.dispatch(
                      new LoyaltySystemActions.SetActiveContestCustomerDraws(
                        this.contestCustomerDraws
                      )
                    );
                    this.drawsSum = _.sumBy(
                      _.filter(this.contestCustomerDraws, (draw) => {
                        return draw.status === "active";
                      }),
                      "draws"
                    );
                  }
                  if (res.customer_lottery_numbers) {
                    this.customer_draw_numbers = _.cloneDeep(
                      res.customer_lottery_numbers
                    );

                    _.each(this.customer_draw_numbers, (draw_number, index) => {
                      const winner_index = _.findIndex(this.contest.winners, {
                        lottery_number: draw_number.lottery_number.toString(),
                      });
                      if (
                        winner_index > -1 &&
                        _.startsWith(
                          this.contest.winners[winner_index].type,
                          "winner"
                        )
                      ) {
                        this.customer_draw_numbers[index].isWinner = true;
                        this.winner_draws.push(draw_number);
                      } else if (
                        winner_index > -1 &&
                        _.startsWith(
                          this.contest.winners[winner_index].type,
                          "alternate"
                        )
                      ) {
                        this.customer_draw_numbers[index].isAlternate = true;
                        this.alternate_draws.push(draw_number);
                      }
                    });

                    console.log(
                      "customer_draw_numbers",
                      this.customer_draw_numbers
                    );
                  }
                } else {
                  if (res.comment_id) {
                    const alert = await this.alertController.create({
                      header:
                        res && res.comment_id
                          ? this.translateService.instant(res.comment_id)
                          : this.translateService.instant(
                              "errorMessages.general_error"
                            ),

                      backdropDismiss: false,
                      buttons: ["OK"],
                    });
                    await alert.present();
                  } else if (res.comments) {
                    const toast = await this.toastController.create({
                      message: this.translateService.instant(res.comments),
                      color: "danger",
                      position: "middle",
                      duration: 5000,
                      buttons: [
                        {
                          side: "end",
                          text: "OK",
                          role: "cancel",
                          handler: () => {
                            console.log("Cancel clicked");
                          },
                        },
                      ],
                    });
                    await toast.present();
                  }
                }
              },
              error: async (err) => {
                const errMsg = this.translateService.instant(
                  "errorMessages.error: There was an error on saving"
                );
                const toast = await this.toastController.create({
                  message: errMsg,
                  color: "danger",
                  position: "middle",
                  duration: 5000,
                  buttons: [
                    {
                      side: "end",
                      text: "OK",
                      role: "cancel",
                      handler: () => {
                        console.log("Cancel clicked");
                      },
                    },
                  ],
                });
                await toast.present();
                return err;
              },
            });
        }
        if (state && state.user && !_.isEqual(state.user, this.currentUser)) {
          this.currentUser = _.cloneDeep(state.user);
        }
      });
  }

  async presentMagicLoginPopup() {
    this.analyticsService.dmAnalyticsMessage(
      "login_button",
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
    // this.MagicLoginservice.presentLoginModal(this.newCustomer);
    if (!Capacitor.isNativePlatform()) {
      this.LoginPopupsService.presentFirebaseModalLogin(null, null);
    } else {
      this.LoginPopupsService.closeMobileMagicLoginAlert();
      this.LoginPopupsService.presentMobileMagicLoginAlert(
        this.newCustomer,
        this.groupApp
      );
    }
  }

  noDrawsAnimationCreated(ev) {
    const noDraws = ev;
    noDraws.setSpeed(1);
  }

  ngOnDestroy(): void {
    this.contestCustomerDraws = null;
    this.drawsSum = null;
  }
}
