<ion-header>
  <app-no-internet-connection></app-no-internet-connection>
  <app-ionic-update-error></app-ionic-update-error>
  <ion-toolbar>
    <ion-title>{{"pages.payment-cards" | translate}}</ion-title>
    <ion-buttons slot="start" *ngIf="groupApp && windowDimensions">
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>
    <ion-buttons
      slot="end"
      *ngIf="groupApp && windowDimensions && currentUser && currentUser.cards && currentUser.cards.length && ((windowDimensions.width<=600 && !(groupApp.design_settings && groupApp.design_settings.menuFooterTabs)) ||(groupApp.design_settings && groupApp.design_settings.menuFooterTabs && windowDimensions.width<900))"
    >
      <ion-button
        title='{{"payment-cards.add-card" | translate}}'
        (click)="saveCard()"
        ><ion-icon src="assets/ionicons/add.svg"></ion-icon
      ></ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row *ngIf="!loginLoading && !currentUser">
      <ion-col class="ion-text-center">
        <ion-spinner> </ion-spinner>
      </ion-col>
    </ion-row>

    <ion-row
      *ngIf="currentUser &&(!currentUser.cards || ( currentUser.cards && currentUser.cards.length===0)) &&loginLoading==='not-loading'"
    >
      <ion-col size="12" class="ion-text-center">
        <ion-grid style="width: 120px; height: 120px; padding: 0">
          <ng-lottie [options]="AnimationNoCardsOptions"></ng-lottie>
        </ion-grid>
        <ion-text
          ><h5 style="margin-top: 3px; margin-bottom: 3px">
            {{"payment-cards.no-saved-payment-cards" | translate}}
          </h5></ion-text
        >
        <ion-button (click)="saveCard()">
          {{"payment-cards.add-card" | translate}}
        </ion-button>
      </ion-col>
    </ion-row>

    <ion-list
      *ngIf="currentUser && currentUser.cards && currentUser.cards.length>0"
    >
      <ion-item
        *ngFor="let card of currentUser.cards; trackBy:trackCards;let index=index"
      >
        <div
          *ngIf="card.ticketRestaurant"
          style="position: absolute; top: 35px; right: 22px"
        >
          <img
            alt="ticketRestaurantCard"
            width="50px"
            src="https://data.deliverymanager.gr/bankicons/ticketRestaurantCard.png"
          />
        </div>
        <ion-label
          class="ion-no-padding"
          *ngIf="card.expiry"
          style="position: absolute; right: 23px; top: 0"
        >
          <ion-text>{{ card.expiry | date : "MM/yyyy"}}</ion-text>
        </ion-label>
        <ion-row>
          <ion-col size="12" class="ion-no-padding ion-no-margin">
            <ion-label class="ion-text-wrap">
              <strong>{{card.number}}</strong>
              <p>
                <ion-text>
                  {{card.cardType.Name}}&nbsp;{{card.bank ? '- ' + card.bank :
                  ''}}
                </ion-text>
              </p>
              <p>
                <ion-text>
                  {{"payment-cards.payment-gateway" | translate}}:
                  {{card.paymentGatewayLabel}}
                </ion-text>
              </p>

              <p
                *ngIf="!card.invalid && card.stores.length > 0 && selectedLangugage"
              >
                {{"payment-cards.saved-for" | translate}}:
                <ion-text *ngFor="let store of card.stores; let i=index"
                  >{{ store .store_name_mobileorder &&
                  store.store_name_mobileorder[ selectedLangugage ] ? store
                  .store_name_mobileorder[ selectedLangugage ] :
                  (store.store_name_langs && store.store_name_langs[
                  selectedLangugage] ? store.store_name_langs[
                  selectedLangugage] : store.store_name_langs &&
                  store.store_name_langs.el ? store.store_name_langs.el :
                  store.store_name ? store.store_name : "") }}
                  {{(card.stores.length - 1 > i ? ', ' : '')}}</ion-text
                >
              </p>
              <p *ngIf="card.invalid">
                <ion-text color="danger">
                  {{"payment-cards.this-card-should-be-saved-again" |
                  translate}}</ion-text
                >
              </p>
            </ion-label>
          </ion-col>
          <ion-col class="ion-text-right ion-no-padding ion-no-margin">
            <ion-button
              (click)="deleteCardAlert(index)"
              color="danger"
              fill="clear"
              style="font-weight: 600"
              >{{ "invoice-data.delete" | translate }}</ion-button
            ></ion-col
          >
        </ion-row>
      </ion-item>
    </ion-list>
  </ion-grid>
  <div
    *ngIf="groupApp &&
    groupApp.design_settings &&
    groupApp.design_settings.menuFooterTabs && windowDimensions && windowDimensions.width<900"
    style="margin-top: 65px"
  ></div>
</ion-content>

<app-menu-footer-tabs></app-menu-footer-tabs>

<ion-footer
  *ngIf="currentUser && currentUser.cards && currentUser.cards.length && windowDimensions && groupApp && (((!groupApp.design_settings || !groupApp.design_settings.menuFooterTabs) && windowDimensions.width>600) || (groupApp.design_settings && groupApp.design_settings.menuFooterTabs && windowDimensions.width>=900))"
>
  <ion-grid>
    <ion-row style="width: 100%">
      <ion-col class="ion-text-center">
        <ion-button (click)="saveCard()">
          {{"payment-cards.add-card" | translate}}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
