<ion-header>
  <ion-toolbar>
    <ion-title>{{ "download_app" | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col class="ion-text-center">
        <h2>Κατέβασε το app για να σκανάρεις qr codes</h2>
      </ion-col>
    </ion-row>
    <ion-grid *ngIf="nativePlatform === 'android'">
      <ng-lottie
        [options]="animationOptionsAndroid"
        (click)="openLink(branchLink)"
      ></ng-lottie>
    </ion-grid>
    <ion-grid *ngIf="nativePlatform === 'ios'">
      <ng-lottie
        [options]="animationOptionsIos"
        (click)="openLink(branchLink)"
      ></ng-lottie>
    </ion-grid>
    <ion-grid *ngIf="!nativePlatform">
      <ion-row>
        <ion-col>
          <ng-lottie [options]="animationOptionsIos"></ng-lottie>
        </ion-col>
        <ion-col>
          <ng-lottie [options]="animationOptionsAndroid"></ng-lottie>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="ion-text-center">
          <img
            width="100"
            height="100"
            [src]="'https://data.deliverymanager.gr/' + group + '/qr/qr.png'"
          />
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-row>
      <ion-col>
        <p>
          <span> ή </span>
        </p>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="ion-text-center">
        {{ "open_camera_and_scan_table_qr" | translate }}
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
