import { Action } from "@ngrx/store";

export const SET_BROWSER_PLATFORM = "[PLATFORM_INFO] Set Browser Platform";
export const SET_ANDROID_PLATFORM = "[PLATFORM_INFO] Set Android Platform";
export const SET_IOS_PLATFORM = "[PLATFORM_INFO] Set IOS Platform";
export const SET_ANDROID_MOBILE_PLATFORM =
  "[PLATFORM_INFO] Set Android Mobile Platform";
export const SET_IOS_MOBILE_PLATFORM =
  "[PLATFORM_INFO] Set IOS Mobile Platform";
export const CLEAR_PLATFORM_INFO = "[PLATFORM_INFO] Clear Browser Platform";

export const SET_VERSION_NUMBER = "[PLATFORM_INFO] Set Version Number";

export const SET_SESSION_ID = "[PLATFORM_INFO] Set Session ID";

export const SET_DEVICE_ID = "[PLATFORM_INFO] Set Device ID";

export class SetBrowserPlatform implements Action {
  readonly type = SET_BROWSER_PLATFORM;
  constructor(public payload: any) {}
}
export class SetAndroidPlatform implements Action {
  readonly type = SET_ANDROID_PLATFORM;
  constructor(public payload: any) {}
}
export class SetIosPlatform implements Action {
  readonly type = SET_IOS_PLATFORM;
  constructor(public payload: any) {}
}
export class SetAndroidMobilePlatform implements Action {
  readonly type = SET_ANDROID_MOBILE_PLATFORM;
  constructor(public payload: any) {}
}
export class SetIosMobilePlatform implements Action {
  readonly type = SET_IOS_MOBILE_PLATFORM;
  constructor(public payload: any) {}
}

export class ClearPlatformInfo implements Action {
  readonly type = CLEAR_PLATFORM_INFO;
  constructor() {}
}

export class SetVersionNumber implements Action {
  readonly type = SET_VERSION_NUMBER;
  constructor(public payload: any) {}
}

export class SetSessionId implements Action {
  readonly type = SET_SESSION_ID;
  constructor(public payload: any) {}
}

export class SetDeviceId implements Action {
  readonly type = SET_DEVICE_ID;
  constructor(public payload: any) {}
}
export type PlatformInfoActions =
  | SetBrowserPlatform
  | SetAndroidPlatform
  | SetIosPlatform
  | SetAndroidMobilePlatform
  | SetIosMobilePlatform
  | ClearPlatformInfo
  | SetVersionNumber
  | SetDeviceId
  | SetSessionId;
