<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">{{
      "catalog.search-modal.title" | translate
    }}</ion-title>
    <p class="ion-no-padding ion-no-margin" style="padding-inline-start: 22px">
      <ion-text
        style="font-size: small"
        *ngIf="
          currentView && currentView === 'categories' && selectedHyperCategory
        "
      >
        {{ selectedHyperCategory.name }}
      </ion-text>
      <ion-text
        style="font-size: small"
        *ngIf="currentView && currentView === 'products' && selectedCategory"
      >
        {{ selectedCategory.name }}
      </ion-text>
    </p>
    <ion-buttons slot="end">
      <ion-button color="danger" fill="clear" (click)="ondismiss()">
        <ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons
      *ngIf="
        (currentView === 'categories' &&
          hyperCategories &&
          hyperCategories.length > 0) ||
        currentView === 'products'
      "
      slot="start"
    >
      <ion-button fill="clear" (click)="goBack()">
        <ion-icon slot="icon-only" name="arrow-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-searchbar
    *ngIf="hyperCategories"
    clearIcon="assets/ionicons/close.svg"
    searchIcon="assets/ionicons/search.svg"
    animated
    [placeholder]="
      currentView === 'hyperCategories'
        ? ('catalog.search-modal.in-catalog' | translate)
        : currentView === 'categories'
        ? selectedHyperCategory && selectedHyperCategory.name
          ? selectedHyperCategory.name
          : ('catalog.search-modal.in-catalog' | translate)
        : currentView === 'products'
        ? selectedCategory && selectedCategory.name
          ? selectedCategory.name
          : ''
        : ''
    "
    debounce="1200"
    (ionClear)="handleResetSearch()"
    type="text"
    [(ngModel)]="searchTerm"
    [(ngModel)]="searchTerm"
    (ionChange)="onSearch()"
  >
    <ion-button
      *ngIf="currentUser"
      title="{{ 'catalog.search-modal.search-filters' | translate }}"
      fill="clear"
      (click)="selectFilter()"
    >
      <ion-icon
        name="filter-outline"
        [color]="filtersArray && filtersArray.length ? 'danger' : ''"
      >
      </ion-icon> </ion-button
  ></ion-searchbar>
</ion-header>

<ion-content>
  <ion-grid *ngIf="windowDimensions && groupApp">
    <ion-item hidden="true">
      <ion-label>
        {{ "catalog.search-modal.search-filters" | translate }}</ion-label
      >
      <ion-select
        #selectFilter
        (ionChange)="filtersChanged($event)"
        cancelText="{{ 'cancel' | translate }}"
        [(ngModel)]="filtersArray"
        multiple="true"
      >
        <ion-select-option value="favorites"
          >{{ "catalog.favorite-products" | translate }}
        </ion-select-option>
      </ion-select>
    </ion-item>
    <div *ngIf="hyperCategories">
      <ion-item
        lines="none"
        *ngIf="
          searchMode &&
          renderedSearchProducts &&
          renderedSearchProducts.length === 0
        "
      >
        <ion-label class="ion-text-center">
          {{ "catalog.search-modal.no-products-found" | translate }}
        </ion-label>
      </ion-item>

      <ion-row
        *ngIf="searchMode && !renderedSearchProducts"
        style="width: 100%"
      >
        <ion-col class="ion-text-center">
          <ion-spinner></ion-spinner>
        </ion-col>
      </ion-row>

      <ion-row *ngIf="searchMode && !allCategoriesMarketCardsDesign">
        <ion-col
          size="12"
          *ngFor="let product of renderedSearchProducts; trackBy: productsTrack"
        >
          <span *ngIf="product.category">
            <app-product-items-mobile
              *ngIf="product.activeTotal && product.category.activeTotal"
              [product_id]="product.product_id"
              [productImage]="product.image"
              [category_id]="product.category.category_id"
              [hyperCategories]="hyperCategories"
              [imageBrowser]="imageBrowser"
              [cartProductIds]="cartProductIds"
              [dineIn]="dineIn"
              [orderPickup]="orderPickup"
              [groupStoreData]="groupStoreData"
              [selectedDineInTable]="selectedDineInTable"
              [selectedPreorderDate]="selectedPreorderDate"
              [webp]="webp"
            ></app-product-items-mobile>
          </span>
        </ion-col>
      </ion-row>

      <ion-row *ngIf="searchMode && allCategoriesMarketCardsDesign">
        <ion-col
          *ngFor="let product of renderedSearchProducts; trackBy: productsTrack"
          size="6"
        >
          <span
            *ngIf="
              product.category &&
              windowDimensions &&
              windowDimensions.width > 500
            "
          >
            <app-product-item-market-type-card-browser
              *ngIf="product.activeTotal && product.category.activeTotal"
              [product]="product"
              [category]="product.category"
              [cartProductIds]="cartProductIds"
              [dineIn]="dineIn"
              [orderPickup]="orderPickup"
              [groupStoreData]="groupStoreData"
              [selectedDineInTable]="selectedDineInTable"
              [selectedPreorderDate]="selectedPreorderDate"
              [webp]="webp"
              [productIncludedInCart]="product.productIncludedInCart"
              [finalPriceLoading]="finalPriceLoading"
            ></app-product-item-market-type-card-browser>
          </span>

          <span
            *ngIf="
              product.category &&
              windowDimensions &&
              windowDimensions.width <= 500
            "
          >
            <app-product-item-market-type-card-mobile
              *ngIf="product.activeTotal && product.category.activeTotal"
              [product]="product"
              [category]="product.category"
              [cartProductIds]="cartProductIds"
              [dineIn]="dineIn"
              [orderPickup]="orderPickup"
              [groupStoreData]="groupStoreData"
              [selectedDineInTable]="selectedDineInTable"
              [selectedPreorderDate]="selectedPreorderDate"
              [webp]="webp"
              [productIncludedInCart]="product.productIncludedInCart"
              [finalPriceLoading]="finalPriceLoading"
            ></app-product-item-market-type-card-mobile>
          </span>
        </ion-col>
      </ion-row>

      <div *ngIf="!searchMode">
        <ion-list *ngIf="currentView === 'hyperCategories'">
          <ion-item
            button
            detail="false"
            (click)="selectHyperCategory(hyperCategory)"
            *ngFor="
              let hyperCategory of hyperCategories;
              trackBy: hyperCategoriesTrack
            "
          >
            <ion-label
              >{{
                hyperCategory.name !== "others"
                  ? hyperCategory.name
                  : ("others" | translate)
              }}
            </ion-label>
            <ion-icon
              slot="end"
              src="assets/ionicons/chevron-forward-outline.svg"
            ></ion-icon>
          </ion-item>
        </ion-list>

        <ion-list *ngIf="currentView === 'categories'">
          <ion-item
            button
            detail="false"
            lines="full"
            (click)="selectCategory(category)"
            *ngFor="
              let category of renderedCategories;
              trackBy: categoriesTrack
            "
          >
            <ion-label>{{ category.name }} </ion-label>
            <ion-icon
              slot="end"
              src="assets/ionicons/chevron-forward-outline.svg"
            >
            </ion-icon>
          </ion-item>
        </ion-list>

        <ion-item
          lines="none"
          *ngIf="
            currentView === 'products' &&
            selectedCategory &&
            selectedCategory.products &&
            !selectedCategory.products.length &&
            selectedCategory.emptyCategoryForSelectedDeliveryMethod
          "
        >
          <ion-label class="ion-text-wrap ion-text-center">
            <ion-text
              color="danger"
              *ngIf="orderPickup == false && dineIn == false"
              >{{
                "catalog.categories.there-are-no-products-in-this-category-for-delivery"
                  | translate
              }}
            </ion-text>
            <ion-text
              color="danger"
              *ngIf="orderPickup == true && dineIn == false"
              >{{
                "catalog.categories.there-are-no-products-in-this-category-for-takeaway"
                  | translate
              }}
            </ion-text>
            <ion-text
              color="danger"
              *ngIf="orderPickup == false && dineIn == true"
              >{{
                "catalog.categories.there-are-no-products-in-this-category-for-dinein"
                  | translate
              }}
            </ion-text>
          </ion-label>
        </ion-item>

        <ion-list
          *ngIf="
            currentView === 'products' &&
            selectedCategory &&
            selectedCategory.marketCardsDesign
          "
        >
          <ion-grid>
            <ion-row
              style="width: 100%"
              *ngIf="windowDimensions && windowDimensions.width < 500"
            >
              <ion-col
                [size]="
                  windowDimensions && windowDimensions.width < 330 ? '12' : '6'
                "
                *ngFor="
                  let product of selectedCategory.products;
                  trackBy: productsTrack
                "
              >
                <span
                  *ngIf="product.activeTotal && selectedCategory.activeTotal"
                >
                  <app-product-item-market-type-card-mobile
                    [product]="product"
                    [category]="selectedCategory"
                    [cartProductIds]="cartProductIds"
                    [dineIn]="dineIn"
                    [orderPickup]="orderPickup"
                    [groupStoreData]="groupStoreData"
                    [selectedDineInTable]="selectedDineInTable"
                    [selectedPreorderDate]="selectedPreorderDate"
                    [webp]="webp"
                    [productIncludedInCart]="product.productIncludedInCart"
                    [finalPriceLoading]="finalPriceLoading"
                  ></app-product-item-market-type-card-mobile>
                </span>
              </ion-col>
            </ion-row>

            <ion-row
              style="width: 100%"
              *ngIf="windowDimensions && windowDimensions.width >= 500"
            >
              <ion-col
                [size]="'6'"
                *ngFor="
                  let product of selectedCategory.products;
                  trackBy: productsTrack
                "
              >
                <span
                  *ngIf="product.activeTotal && selectedCategory.activeTotal"
                >
                  <app-product-item-market-type-card-browser
                    [product]="product"
                    [category]="selectedCategory"
                    [cartProductIds]="cartProductIds"
                    [dineIn]="dineIn"
                    [orderPickup]="orderPickup"
                    [groupStoreData]="groupStoreData"
                    [selectedDineInTable]="selectedDineInTable"
                    [selectedPreorderDate]="selectedPreorderDate"
                    [webp]="webp"
                    [productIncludedInCart]="product.productIncludedInCart"
                    [finalPriceLoading]="finalPriceLoading"
                  ></app-product-item-market-type-card-browser>
                </span>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-list>

        <ion-list
          *ngIf="
            currentView === 'products' &&
            selectedCategory &&
            !selectedCategory.marketCardsDesign
          "
        >
          <span
            *ngFor="
              let product of selectedCategory.products;
              trackBy: productsTrack
            "
          >
            <app-product-items-mobile
              *ngIf="product.activeTotal && selectedCategory.activeTotal"
              [product_id]="product.product_id"
              [productImage]="product.image"
              [category_id]="selectedCategory.category_id"
              [hyperCategories]="hyperCategories"
              [imageBrowser]="imageBrowser"
              [cartProductIds]="cartProductIds"
              [dineIn]="dineIn"
              [orderPickup]="orderPickup"
              [groupStoreData]="groupStoreData"
              [selectedDineInTable]="selectedDineInTable"
              [selectedPreorderDate]="selectedPreorderDate"
              [webp]="webp"
            ></app-product-items-mobile>
          </span>
        </ion-list>

        <ion-infinite-scroll
          *ngIf="
            currentView === 'products' &&
            selectedCategory &&
            (selectedCategory.marketCardsDesign ||
              (storeInfo &&
                storeInfo.design_settings &&
                storeInfo.design_settings.showOnlySelectedCategoryProducts)) &&
            allSelectedCategoryProducts &&
            selectedCategory &&
            selectedCategory.products
          "
          [disabled]="
            allSelectedCategoryProducts.length ===
            selectedCategory.products.length
          "
          threshold="30%"
          (ionInfinite)="loadData($event)"
        >
          <ion-infinite-scroll-content loadingSpinner="bubbles">
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>
      </div>
    </div>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-grid class="ion-no-padding ion-no-margin">
    <ion-row class="ion-no-padding ion-no-margin" style="width: 100%">
      <ion-col class="ion-text-center ion-no-padding-ion-no-margin">
        <ion-button (click)="navigateAndClose()">
          <ion-text *ngIf="!finalPriceLoading">
            {{ "catalog.search-modal.cart" | translate }}</ion-text
          >
          <ion-spinner *ngIf="finalPriceLoading"> </ion-spinner>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
