import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-homepage-logo",
  templateUrl: "./homepage-logo.component.html",
  styleUrls: ["./homepage-logo.component.scss"],
})
export class HomepageLogoComponent implements OnInit {
  @Input() startAnimationLogo;
  @Input() groupApp;
  @Input() rootDir;
  @Input() selectedLogoFileName;
  @Input() windowDimensions;
  public three_buttons;
  public four_buttons;
  constructor() {}

  ngOnInit() {
    this.caluclateButtonsActive();
  }

  caluclateButtonsActive() {
    this.four_buttons = false;
    this.three_buttons = false;
    if (
      this.groupApp &&
      this.groupApp.design_settings &&
      this.groupApp.design_settings.customHomepageButtons &&
      this.groupApp.design_settings.customHomepageButtons.active &&
      this.groupApp.design_settings.customHomepageButtons.button_one &&
      this.groupApp.design_settings.customHomepageButtons.button_one.active &&
      !(
        this.groupApp.design_settings.customHomepageButtons.button_two &&
        this.groupApp.design_settings.customHomepageButtons.button_two.active
      )
    ) {
      this.four_buttons = false;
      this.three_buttons = true;
    } else if (
      this.groupApp &&
      this.groupApp.design_settings &&
      this.groupApp.design_settings.customHomepageButtons &&
      this.groupApp.design_settings.customHomepageButtons.active &&
      this.groupApp.design_settings.customHomepageButtons.button_two &&
      this.groupApp.design_settings.customHomepageButtons.button_two.active &&
      !(
        this.groupApp.design_settings.customHomepageButtons.button_one &&
        this.groupApp.design_settings.customHomepageButtons.button_one.active
      )
    ) {
      this.four_buttons = false;
      this.three_buttons = true;
    } else if (
      this.groupApp &&
      this.groupApp.design_settings &&
      this.groupApp.design_settings.customHomepageButtons &&
      this.groupApp.design_settings.customHomepageButtons.active &&
      this.groupApp.design_settings.customHomepageButtons.button_two &&
      this.groupApp.design_settings.customHomepageButtons.button_two.active &&
      this.groupApp.design_settings.customHomepageButtons.button_one &&
      this.groupApp.design_settings.customHomepageButtons.button_one.active
    ) {
      this.four_buttons = true;
      this.three_buttons = false;
    }
  }
}
