import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateDetails'
})
export class DateDetailsPipe implements PipeTransform {
  private padZero(value: number): string {
    return value < 10 ? `0${value}` : value.toString();
  }
  transform(timestamp: any): string {
    if (timestamp) {
      const daysOfWeek = [
        'Κυριακή', // Sunday
        'Δευτέρα', // Monday
        'Τρίτη',   // Tuesday
        'Τετάρτη', // Wednesday
        'Πέμπτη',  // Thursday
        'Παρασκευή', // Friday
        'Σάββατο'  // Saturday
      ];

      const monthsInGreek = [
        'Ιανουαρίου',
        'Φεβρουαρίου',
        'Μαρτίου',
        'Απριλίου',
        'Μαΐου',
        'Ιουνίου',
        'Ιουλίου',
        'Αυγούστου',
        'Σεπτεμβρίου',
        'Οκτωβρίου',
        'Νοεμβρίου',
        'Δεκεμβρίου'
      ];

      const date = new Date(parseInt(timestamp)); // Convert timestamp to milliseconds
      const dayIndex = date.getDay(); // Get the day of the week (0-6)
      const dayNumber = date.getDate(); // Get the day of the month (1-31)
      const monthIndex = date.getMonth(); // Get the month index (0-11)

      const dayName = daysOfWeek[dayIndex];
      const dayInGreek = dayNumber; // Add 'η' to form '1η', '2η', etc.
      const monthInGreek = monthsInGreek[monthIndex];

      const milliseconds = parseInt(timestamp);
      const date2 = new Date(milliseconds);
      const hours = this.padZero(date2.getHours());
      const minutes = this.padZero(date2.getMinutes());
      return `${dayName} ${dayInGreek} ${monthInGreek}, ${hours}:${minutes}`;
    }
    return '';
  }
}
