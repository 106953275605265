<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <!-- <ion-button fill="clear">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button> -->
    </ion-buttons>
    <ion-title>
      <ion-text>{{'loyaltySystem.all-coupons' | translate}}</ion-text>
    </ion-title>
  </ion-toolbar>
</ion-header>

<!-- <ion-grid *ngIf="!customerCoupons">
  <ion-row style="width: 100%">
    <ion-col class="ion-text-center">
      <ion-spinner></ion-spinner>
    </ion-col>
  </ion-row>
</ion-grid> -->

<ion-content *ngIf="currentUser">
  <ion-grid style="max-width: 800px;">
    <ion-row *ngIf="customerCoupons && customerCoupons.length === 0">
      <ion-col class="ion-text-center">
        {{"loyaltySystem.no-coupons"| translate}}
      </ion-col>
    </ion-row>
    <ion-row *ngFor="let coupon of customerCoupons">
      <ion-col>
        <app-loyalty-coupon-item
          [coupon]="coupon">
        </app-loyalty-coupon-item>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
<ion-grid *ngIf="!currentUser && loginState && loginState !== 'login_loading'">
  <ion-row class="ion-justify-content-center ion-align-items-center" style="height: 100%;">
    <ion-col>
      <ion-row>
        <ion-col class="ion-text-center">
          {{'loyaltySystem.login-to-see-coupons' | translate}}
        </ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-center ion-align-items-center">
        <ion-col class="ion-text-center">
          <div style="max-width: 300px; display: inline-block;">
            <ng-lottie
            (animationCreated)="couponPageAnimationCreated($event)"
            [options]="pageCouponAnimationOptions"
            ></ng-lottie>
          </div>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="!currentUser && loginState && loginState==='no_login'">
        <ion-col class="ion-text-center">
          <ion-button 
          [disabled]="loginState === 'login_loading'"
          *ngIf="!currentUser && loginState && loginState ==='no_login' && groupApp" 
          shape="round" class="floating-card-button" 
          (click)="presentMagicLoginPopup()">
          <ion-icon name="log-in-outline"></ion-icon>
          <ion-text style="margin-left: 10px; text-transform: uppercase;"> Σύνδεση</ion-text>
        </ion-button>
        </ion-col>
      </ion-row>
    </ion-col>
  </ion-row>
</ion-grid>
