<ion-grid
  id="cart-catalog-grid"
  [class]="
    (grid &&
      grid === 'wrapper-grid-lg' &&
      !(
        hyperCategories &&
        !hyperCategories.length &&
        windowDimensions &&
        windowDimensions.width < 1500
      )) ||
    (grid &&
      grid === 'wrapper-grid-lg' &&
      hyperCategories &&
      hyperCategories.length &&
      hyperCategories.length > 0 &&
      windowDimensions &&
      windowDimensions.width < 1600)
      ? 'wrapper-grid-lg-without-toolbar'
      : (grid &&
          grid === 'wrapper-grid-lg' &&
          hyperCategories &&
          !hyperCategories.length &&
          windowDimensions &&
          windowDimensions.width < 1500) ||
        (grid &&
          grid === 'wrapper-grid-lg' &&
          hyperCategories &&
          hyperCategories.length &&
          hyperCategories.length > 0 &&
          windowDimensions &&
          windowDimensions.width < 1600)
      ? 'wrapper-grid-lg-with-toolbar'
      : grid
  "
>
  <ion-grid
    [class]="
      windowDimensions && windowDimensions.height > 900
        ? 'height-90 ion-no-padding'
        : windowDimensions &&
          windowDimensions.height <= 900 &&
          windowDimensions.height > 550
        ? 'height-80 ion-no-padding'
        : windowDimensions &&
          windowDimensions.height <= 550 &&
          windowDimensions.height > 360
        ? 'height-70 ion-no-padding'
        : windowDimensions && windowDimensions.height <= 360
        ? 'height-50 ion-no-padding'
        : 'height-90 ion-no-padding'
    "
  >
    <ion-header
      *ngIf="
        groupApp &&
        !groupApp.store_change_catalog_buttons &&
        !(
          hyperCategories &&
          !hyperCategories.length &&
          windowDimensions &&
          windowDimensions.width < 1500
        ) &&
        !(
          hyperCategories &&
          hyperCategories.length &&
          hyperCategories.length > 0 &&
          windowDimensions &&
          windowDimensions.width < 1600
        )
      "
    >
      <ion-toolbar
        mode="ios"
        [class]="
          groupApp &&
          groupApp.design_settings &&
          groupApp.design_settings.beatCatalogHeader &&
          transparentCatalogElements
            ? 'transparentToolbar'
            : ''
        "
      >
        <ion-title mode="ios">
          {{ "catalog.cart-catalog.order-list" | translate }}</ion-title
        >
      </ion-toolbar>
    </ion-header>

    <div scrollY="true" class="inner-scroll-div">
      <ion-grid
        style="padding-bottom: 0"
        *ngIf="groupApp && groupApp.affiliateActive && languagePath"
      >
        <ion-card button="true" color="primary" (click)="navigate('affiliate')">
          <ion-card-content
            style="padding: 8px; font-size: small"
            class="ion-text-center"
          >
            {{ "affiliate.bring-your-friends" | translate }}!
            <div *ngIf="groupApp.affiliateSideMenuTitle">
              <ion-icon
                style="
                  font-size: 14px;
                  margin-right: 5px;
                  vertical-align: middle;
                "
                name="megaphone"
              ></ion-icon>
              <strong>
                {{ groupApp.affiliateSideMenuTitle[languagePath] }}</strong
              >
            </div>
          </ion-card-content>
        </ion-card>
      </ion-grid>

      <ion-grid *ngIf="showPointSystem" style="margin-bottom: 10px">
        <app-point-system [cartCatalogMode]="true"></app-point-system>
      </ion-grid>

      <ion-grid
        style="
          max-width: 400px;
          padding-left: 10px;
          padding-right: 10px;
          padding-top: 10px;
        "
        *ngIf="
          store_info && store_info.vatBasedCustomerProductsActive && currentUser
        "
      >
        <ion-item
          detail="true"
          color="deliveryMethodAddressButtons"
          lines="none"
          (click)="presentSelectInvoiceModal()"
          button
        >
          <ion-label class="ion-text-wrap">
            <ion-text style="font-size: small">
              {{ "catalog.cart-catalog.vatNumber" | translate }}:
              {{
                selectedInvoice && selectedInvoice.vatNumber
                  ? selectedInvoice.vatNumber
                  : ("catalog.cart-catalog.no-selection" | translate)
              }}
            </ion-text>
          </ion-label>
        </ion-item>
      </ion-grid>

      <app-delivery-method-address
        *ngIf="showDeliveryMethodAddressComponent"
      ></app-delivery-method-address>

      <ion-item
        lines="none"
        *ngIf="
          (!cartProducts || (cartProducts && cartProducts.length < 1)) &&
          showEmptyCardImage
        "
      >
        <ion-row class="ion-no-padding" style="width: 100%">
          <ion-col
            *ngIf="
              groupApp.design_settings &&
              groupApp.design_settings.cartIconName &&
              groupApp.design_settings.cartIconName === 'trolley-cart'
            "
            class="ion-no-padding ion-text-center"
            size="12"
          >
            <ion-icon
              color="dark"
              style="font-size: 100px"
              src="assets/flatIcons/trolley-cart.svg"
            ></ion-icon>
          </ion-col>

          <ion-col
            *ngIf="
              groupApp.design_settings &&
              groupApp.design_settings.cartIconName &&
              groupApp.design_settings.cartIconName === 'trayDark'
            "
            class="ion-no-padding ion-text-center"
            size="12"
          >
            <ion-icon
              color="dark"
              style="font-size: 100px"
              src="assets/flatIcons/trayDark.svg"
            ></ion-icon>
          </ion-col>

          <ion-col
            *ngIf="
              !groupApp.design_settings ||
              !groupApp.design_settings.cartIconName ||
              (groupApp.design_settings &&
                groupApp.design_settings.cartIconName &&
                groupApp.design_settings.cartIconName === 'animationTray')
            "
            class="ion-no-padding"
            size="12"
          >
            <ion-grid
              style="
                width: 120px;
                height: 120px;
                padding: 0;
                transform: scale(2);
                z-index: -10;
              "
            >
              <ng-lottie [options]="trayIconAnimationOptions"></ng-lottie>
            </ion-grid>
          </ion-col>

          <ion-col
            *ngIf="
              groupApp.design_settings &&
              groupApp.design_settings.cartIconName &&
              groupApp.design_settings.cartIconName === 'animationCoffee'
            "
            class="ion-no-padding"
            size="12"
          >
            <ion-grid
              style="
                width: 120px;
                height: 120px;
                padding: 0;
                transform: scale(1);
                z-index: -10;
              "
            >
              <ng-lottie
                (animationCreated)="coffeeAnimationCreated($event)"
                [options]="iconCoffeeOptions"
              ></ng-lottie>
            </ion-grid>
          </ion-col>

          <ion-col
            *ngIf="
              groupApp.design_settings &&
              groupApp.design_settings.cartIconName &&
              groupApp.design_settings.cartIconName !== 'shoppingBag' &&
              groupApp.design_settings.cartIconName !== 'trayDark' &&
              groupApp.design_settings.cartIconName !== 'trolley-cart' &&
              groupApp.design_settings.cartIconName !== 'animationCoffee'
            "
            class="ion-no-padding"
            size="12"
          >
            <ion-grid
              style="
                width: 130px;
                height: 130px;
                padding: 0;

                z-index: -10;
              "
            >
              <ng-lottie [options]="otherLottieAnimationOptions"></ng-lottie>
            </ion-grid>
          </ion-col>
        </ion-row>
      </ion-item>
      <ion-item
        *ngIf="!cartProducts || (cartProducts && cartProducts.length < 1)"
        lines="none"
      >
        <ion-label class="ion-text-wrap ion-text-center">
          {{ "catalog.cart-catalog.your-cart-is-empty" | translate }}
        </ion-label>
      </ion-item>
      <span *ngIf="showCartList">
        <ion-list>
          <app-cart-catalog-product-item
            *ngFor="let product of cartProducts; trackBy: trackFn"
            [cartProduct]="product"
          >
          </app-cart-catalog-product-item>
        </ion-list>
      </span>

      <ion-item
        class="buttonItem"
        *ngIf="cartProducts && cartProducts.length > 0 && showCartList"
        lines="none"
      >
        <ion-row style="width: 100%" class="ion-no-padding ion-no-margin">
          <ion-col class="ion-text-center ion-no-padding ion-no-margin">
            <ion-button (click)="clearCart()" color="danger" fill="clear">
              {{ "cart.cart-components.cart-list.clear-cart" | translate }}
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-item>

      <app-cart-prices
        *ngIf="showCartPrices"
        [catalogMode]="true"
      ></app-cart-prices>

      <ion-card
        *ngIf="
          windowDimensions &&
          windowDimensions.height > 700 &&
          catalogInlineInfoMessage &&
          infoMessagesCardsHide &&
          cartProducts &&
          !cartProducts.length
        "
        [hidden]="infoMessagesCardsHide[catalogInlineInfoMessage.id]"
        color="primary"
        mode="md"
        style="
          position: absolute;
          bottom: 10px;
          width: 82%;
          max-height: 250px;
          z-index: 1000;
        "
        [button]="
          catalogInlineInfoMessage && catalogInlineInfoMessage.navigationPage
            ? true
            : false
        "
        (click)="
          catalogInlineInfoMessage && catalogInlineInfoMessage.navigationPage
            ? navigateToPage(catalogInlineInfoMessage)
            : ''
        "
      >
        <img
          *ngIf="catalogInlineInfoMessage.image && groupApp"
          src="https://s3-eu-west-1.amazonaws.com/storegroupseditedbgimages/{{
            groupApp.group
          }}/info_messages/{{ catalogInlineInfoMessage.image }}"
        />
        <ion-row
          *ngIf="
            catalogInlineInfoMessage &&
            catalogInlineInfoMessage.title &&
            languagePath &&
            (catalogInlineInfoMessage.title[languagePath] ||
              catalogInlineInfoMessage.title[mo_base] ||
              catalogInlineInfoMessage.title['en'])
          "
          class="infoMessageCardRow ion-align-items-center ion-justify-content-center"
        >
          <ion-col class="ion-text-center" size="12">
            <div style="padding: 5px">
              <ion-text
                [innerHTML]="
                  catalogInlineInfoMessage.title[languagePath]
                    ? catalogInlineInfoMessage.title[languagePath]
                    : catalogInlineInfoMessage.title[mo_base]
                    ? catalogInlineInfoMessage.title[mo_base]
                    : catalogInlineInfoMessage.title['en']
                "
                style="line-height: 1.4; font-weight: bold"
                *ngIf="
                  catalogInlineInfoMessage && catalogInlineInfoMessage.title
                "
              ></ion-text>
            </div>
          </ion-col>
        </ion-row>
        <ion-progress-bar
          *ngIf="
            catalogInlineInfoMessage.auto_dismiss &&
            catalogInlineInfoMessage.auto_dismiss_seconds
          "
          [value]="motoCardsProgressValues[catalogInlineInfoMessage.id]"
          color="success"
          style="height: 5px"
        ></ion-progress-bar>
      </ion-card>
    </div>
  </ion-grid>

  <ion-grid style="width: 100%; bottom: 5px; z-index: 101">
    <ion-item class="ion-no-margin" lines="none" *ngIf="amountToMinimumOrder">
      <ion-label
        style="line-height: 1"
        class="ion-text-wrap ion-text-center ion-no-margin"
      >
        <div>
          <ion-text style="font-size: small" color="danger">
            {{ "cart.cart-components.cart-list.you-need" | translate }}
            {{ amountToMinimumOrder }}€
            {{
              "cart.cart-components.cart-list.in-order-to-reach-the-min-order"
                | translate
            }}
          </ion-text>
        </div>
        <ion-progress-bar
          style="margin-top: 5px"
          *ngIf="amountToMinimumOrderProgressValue"
          color="primary"
          [value]="amountToMinimumOrderProgressValue / 100"
        >
        </ion-progress-bar>
      </ion-label>
    </ion-item>

    <ion-button
      expand="block"
      routerDirection="root"
      [class]="
        animateButtonContinue && !finalPriceLoading
          ? 'animate__animated animate__pulse'
          : ''
      "
      (click)="onClickContinue()"
      [disabled]="finalPriceLoading"
    >
      <div
        slot="start"
        style="position: absolute; left: 0; font-size: small; font-weight: bold"
        *ngIf="cartProductsCount"
      >
        {{ cartProductsCount }}
      </div>
      <ion-text *ngIf="!finalPriceLoading">{{
        "catalog.cart-catalog.continue" | translate
      }}</ion-text>
      <div
        style="
          position: absolute;
          right: 0;
          font-size: small;
          font-weight: bold;
        "
        slot="end"
        *ngIf="cartPrices && cartPrices.grand_total"
      >
        {{ cartPrices.grand_total }}€
      </div>

      <ion-spinner *ngIf="finalPriceLoading"></ion-spinner> </ion-button
  ></ion-grid>

  <ion-grid
    *ngIf="
      selectedCategoryImageAnimation &&
      categoryImageAnimation &&
      canShowAnimationCategories
    "
    style="
      position: absolute;
      bottom: 15%;
      right: 0;
      padding: 0;
      z-index: -1;
      max-width: 200px;
    "
    [class]="
      categoryImageAnimation
        ? 'animate__animated animate__' +
          selectedCategoryImageAnimation.animation
        : ''
    "
  >
    <img
      width="100%"
      [src]="
        'https://images.deliverymanager.gr/' +
        store_info.store_id +
        '/categories/catalogCategoryAnimationImages/' +
        selectedCategoryImageAnimation.fileName
      "
    />
  </ion-grid>
</ion-grid>
