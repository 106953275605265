<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">
      {{
        addOrEdit === "edit"
          ? ("cart.cart-components.invoice.invoice-add-edit-modal.edit-invoice-data"
              | translate) +
            ": " +
            addEditForm.get("vatNumber").value
          : ("cart.cart-components.invoice.invoice-add-edit-modal.new-invoice-data"
            | translate)
      }}
    </ion-title>
    <ion-button
      color="danger"
      slot="end"
      fill="clear"
      color="dark"
      (click)="closeModal()"
    >
      <ion-icon src="assets/ionicons/close.svg" slot="icon-only"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]="addEditForm">
    <ion-list>
      <ion-item *ngIf="addOrEdit === 'add'">
        <ion-label position="stacked">{{
          "cart.cart-components.invoice.invoice-add-edit-modal.vat-number"
            | translate
        }}</ion-label>

        <ion-input
          #vatNumberInput
          type="text"
          placeholder="{{ 'write-here' | translate }}"
          formControlName="vatNumber"
        >
        </ion-input>

        <ion-text
          color="danger"
          style="font-size: small"
          *ngIf="
            (addEditForm.get('vatNumber').touched ||
              addEditForm.get('vatNumber').dirty) &&
            addEditForm.get('vatNumber').hasError('required')
          "
        >
          {{
            "cart.cart-components.invoice.invoice-add-edit-modal.required"
              | translate
          }}
        </ion-text>
        <ion-text
          color="danger"
          style="font-size: small"
          *ngIf="
            (addEditForm.get('vatNumber').touched ||
              addEditForm.get('vatNumber').dirty) &&
            addEditForm.get('vatNumber').hasError('vatLength')
          "
        >
          {{
            "cart.cart-components.invoice.invoice-add-edit-modal.please-fill-vat-number-correctly"
              | translate
          }}
        </ion-text>
      </ion-item>

      <ion-row
        *ngIf="
          invoiceForEdit && !invoiceForEdit.checked && addOrEdit === 'edit'
        "
      >
        <ion-col class="ion-text-center">
          <ion-text color="danger">{{
            "cart.cart-components.invoice.invoice-list-modal.the-auto-check-of-vatnumber-failed"
              | translate
          }}</ion-text>
        </ion-col>
      </ion-row>

      <ion-item *ngIf="addOrEdit === 'edit'">
        <ion-label position="stacked">{{
          "cart.cart-components.invoice.invoice-add-edit-modal.company-name"
            | translate
        }}</ion-label>

        <ion-input
          placeholder="{{ 'write-here' | translate }}"
          formControlName="company_name"
          type="text"
        ></ion-input>

        <ion-text color="danger">
          <p
            style="margin: 0px; font-size: small"
            *ngIf="!addEditForm.get('company_name').valid"
          >
            {{
              "cart.cart-components.invoice.invoice-add-edit-modal.required"
                | translate
            }}
          </p>
        </ion-text>
      </ion-item>

      <ion-item *ngIf="addOrEdit === 'edit'">
        <ion-label position="stacked">{{
          "cart.cart-components.invoice.invoice-add-edit-modal.company-address"
            | translate
        }}</ion-label>

        <ion-input
          placeholder="{{ 'write-here' | translate }}"
          formControlName="company_address"
          type="text"
        >
        </ion-input>

        <ion-text color="danger">
          <p
            style="margin: 0px; font-size: small"
            *ngIf="!addEditForm.get('company_address').valid"
          >
            {{
              "cart.cart-components.invoice.invoice-add-edit-modal.required"
                | translate
            }}
          </p>
        </ion-text>
      </ion-item>

      <ion-item *ngIf="addOrEdit === 'edit'">
        <ion-label position="stacked">{{
          "cart.cart-components.invoice.invoice-add-edit-modal.vat-office"
            | translate
        }}</ion-label>

        <ion-input
          placeholder="{{ 'write-here' | translate }}"
          type="text"
          formControlName="vatOffice"
        ></ion-input>

        <ion-text color="danger">
          <p
            style="margin: 0px; font-size: small"
            *ngIf="!addEditForm.get('vatOffice').valid"
          >
            {{
              "cart.cart-components.invoice.invoice-add-edit-modal.required"
                | translate
            }}
          </p>
        </ion-text>
      </ion-item>
      <ion-item *ngIf="addOrEdit === 'edit'">
        <ion-label position="stacked">{{
          "cart.cart-components.invoice.invoice-add-edit-modal.activity"
            | translate
        }}</ion-label>

        <ion-input
          placeholder="{{ 'write-here' | translate }}"
          type="text"
          formControlName="activity"
        ></ion-input>

        <ion-text color="danger">
          <p
            style="margin: 0px; font-size: small"
            *ngIf="!addEditForm.get('activity').valid"
          >
            {{
              "cart.cart-components.invoice.invoice-add-edit-modal.required"
                | translate
            }}
          </p>
        </ion-text>
      </ion-item>
    </ion-list>
  </form>
</ion-content>

<ion-footer>
  <div class="ion-padding" class="ion-text-center">
    <ion-button
      *ngIf="addOrEdit === 'add'"
      [disabled]="!addEditForm.get('vatNumber').valid || newInvoiceLoading"
      (click)="addNewVatNumber()"
    >
      <ion-spinner *ngIf="newInvoiceLoading"></ion-spinner>

      <ion-icon
        *ngIf="!newInvoiceLoading"
        src="assets/ionicons/add.svg"
      ></ion-icon>
      <ion-text *ngIf="!newInvoiceLoading">
        {{
          "cart.cart-components.invoice.invoice-add-edit-modal.add" | translate
        }}</ion-text
      >
    </ion-button>

    <ion-button
      *ngIf="addOrEdit === 'edit'"
      [disabled]="!addEditForm.valid || newInvoiceLoading"
      (click)="onSubmit()"
    >
      {{
        "cart.cart-components.invoice.invoice-add-edit-modal.save" | translate
      }}</ion-button
    >
  </div>
</ion-footer>
