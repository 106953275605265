import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../store/app.reducer";
import * as HyperCategoriesActions from "../../../store/hyperCategories/hyperCategories.actions";

import * as _ from "lodash";
import * as selectors from "../../../store/app.selectors";
import { audit, auditTime, distinctUntilChanged, first } from "rxjs/operators";
import { Subscription } from "rxjs";
import * as CategoriesSelectionActions from "../../categories/store/categoriesSelection.actions";

@Component({
  selector: "app-next-hyper-category",
  templateUrl: "./next-hyper-category.component.html",
  styleUrls: ["./next-hyper-category.component.scss"],
})
export class NextHyperCategoryComponent implements OnInit, OnDestroy {
  public hyperCategories: any;
  public selectedHyperCategory: any;
  public groupApp: any;
  public selectedCategoryId;
  public storeInfo: any;
  private subscription: Subscription;
  private subscription2: Subscription;
  private subscription3: Subscription;
  private storeSub: Subscription;
  constructor(private store: Store<fromApp.AppState>) {}

  ngOnInit() {
    this.storeSub = this.store
      .select(selectors.getStoreInfo)
      .pipe(distinctUntilChanged())
      .subscribe((storeInfo) => {
        if (storeInfo && !_.isEqual(this.storeInfo, storeInfo)) {
          this.storeInfo = _.cloneDeep(storeInfo);
        }
      });

    this.subscription = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (
          state &&
          state.groupApp &&
          !_.isEqual(this.groupApp, state.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp);
        }
      });

    this.subscription2 = this.store
      .select("hyperCategories")
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (
          state &&
          state.hyperCategories &&
          !_.isEqual(this.hyperCategories, state.hyperCategories)
        ) {
          this.hyperCategories = _.cloneDeep(state.hyperCategories);
        }
        if (
          state &&
          state.currentHyperCategory &&
          !_.isEqual(this.selectedHyperCategory, state.currentHyperCategory)
        ) {
          this.selectedHyperCategory = _.cloneDeep(state.currentHyperCategory);
        }
      });

    this.subscription3 = this.store
      .select("verticalCategory")
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (
          state &&
          state.selectedCategoryId &&
          !_.isEqual(this.selectedCategoryId, state.selectedCategoryId)
        ) {
          this.selectedCategoryId = _.cloneDeep(state.selectedCategoryId);
        }
      });
  }

  goToNext() {
    if (
      this.storeInfo &&
      this.storeInfo.design_settings &&
      this.storeInfo.design_settings.showOnlySelectedCategoryProducts
    ) {
      this.store
        .select("catalog")
        .pipe(first())
        .subscribe((catalogState) => {
          if (catalogState && catalogState.catalog) {
            console.log("catalogState", catalogState);
            const categories: any[] = _.cloneDeep(catalogState.catalog);
            if (this.selectedCategoryId === _.last(categories).category_id) {
              this.goToNextHyperCategory();
            } else {
              const cat_index = _.findIndex(categories, {
                category_id: this.selectedCategoryId,
              });
              if (cat_index !== -1 && categories[cat_index + 1]) {
                this.store.dispatch(
                  new CategoriesSelectionActions.SetSelectedCategory(
                    _.cloneDeep(categories[cat_index + 1].category_id)
                  )
                );
              }
            }
          }
        })
        .unsubscribe();
    } else {
      this.goToNextHyperCategory();
    }
  }

  goToNextHyperCategory() {
    const previewsHyperCatIndex = _.findIndex(this.hyperCategories, {
      hyper_category_id: this.selectedHyperCategory,
    });
    // console.log("hyper cat length", this.hyperCategories.length);
    // console.log("preview cat length", previewsHyperCatIndex);
    if (this.hyperCategories.length > previewsHyperCatIndex + 1) {
      const nextHyperCategory = this.hyperCategories[previewsHyperCatIndex + 1];
      // console.log("netx hyper index", nextHyperCategory);
      this.store.dispatch(
        new HyperCategoriesActions.SetCurrentHyperCategory(
          _.cloneDeep(nextHyperCategory.hyper_category_id)
        )
      );
      if (navigator.cookieEnabled) {
        localStorage.setItem(
          "selectedHyperCategory",
          nextHyperCategory.hyper_category_id
        );
      }
    } else {
      const first: any = _.first(this.hyperCategories);
      if (first) {
        this.store.dispatch(
          new HyperCategoriesActions.SetCurrentHyperCategory(
            _.cloneDeep(first.hyper_category_id)
          )
        );
        if (navigator.cookieEnabled) {
          localStorage.setItem(
            "selectedHyperCategory",
            first.hyper_category_id
          );
        }
      }
    }
  }

  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscription2) {
      this.subscription2.unsubscribe();
    }
    if (this.subscription3) {
      this.subscription3.unsubscribe();
    }
  }
}
