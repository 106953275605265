import { Component, OnDestroy, OnInit } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { AnimationOptions } from "ngx-lottie";
import { first, Subscription } from "rxjs";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import * as fromApp from "../../store/app.reducer";
import * as selectors from "../../store/app.selectors";
import * as _ from "lodash";
import { PreorderPopupComponent } from "../preorder-popup/preorder-popup.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-create-order-failed-preorder-modal",
  templateUrl: "./create-order-failed-preorder-modal.component.html",
  styleUrls: ["./create-order-failed-preorder-modal.component.scss"],
})
export class CreateOrderFailedPreorderModalComponent
  implements OnInit, OnDestroy
{
  public checkStoreOnlineMessage: any;
  public animationInstance: any;
  public loading: boolean = false;
  public error = false;
  deletedUserAnimationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/create-order-failed-preorder-active.json",
  };

  private unsubscribeBackEvent: Subscription;

  constructor(
    private modalController: ModalController,
    private platform: Platform,
    private dataStorageService: DataStorageService,
    private translateService: TranslateService,
    private store: Store<fromApp.AppState>
  ) {}
  closeModal() {
    this.modalController.dismiss();
  }

  ngOnInit() {
    this.checkStoreOnline();
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      300,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );
  }

  checkStoreOnline() {
    this.store
      .select("groupApp")
      .pipe(first())
      .subscribe((state) => {
        this.store
          .select(selectors.getStoreInfo)
          .pipe(first())
          .subscribe((store_info) => {
            this.loading = true;
            var checkStoreOnlineParams: any = {
              languageCode: state.selectedLangugage || "el",
            };

            if (store_info.store_id && state.groupApp.group) {
              checkStoreOnlineParams.store_id = _.cloneDeep(
                store_info.store_id
              );
              checkStoreOnlineParams.group = _.cloneDeep(state.groupApp.group);
            }
            this.dataStorageService
              .checkStoreOnline(checkStoreOnlineParams)
              .subscribe({
                next: async (res: any) => {
                  this.loading = false;
                  if (!res || !res.success) {
                    this.error = true;
                    this.checkStoreOnlineMessage =
                      this.translateService.instant(
                        "errorMessages.something_went_wrong_try_again"
                      );
                  } else {
                    this.checkStoreOnlineMessage = res.schedule_comments;
                  }
                },
                error: (err) => {
                  console.log("error", err);
                  this.error = true;
                  this.checkStoreOnlineMessage = this.translateService.instant(
                    "errorMessages.something_went_wrong_try_again"
                  );
                },
              });
          })
          .unsubscribe();
      })
      .unsubscribe();
  }

  async openPreorderModal() {
    this.modalController.dismiss();
    const modal = await this.modalController.create({
      component: PreorderPopupComponent,
      cssClass: "preorderPopup",
      backdropDismiss: false,
      componentProps: {
        showNotValidDateToast: false,
      },
    });

    modal.present();
    modal.onDidDismiss().then(() => {});
  }

  animationCreated(ev) {
    if (ev) {
      this.animationInstance = ev;
    }
  }

  ngOnDestroy(): void {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
  }
}
