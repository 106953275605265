import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { auditTime, distinctUntilChanged } from "rxjs/operators";
import * as fromApp from "../../store/app.reducer";
import * as _ from "lodash";

@Component({
  selector: "app-mobile-catalog",
  templateUrl: "./mobile-catalog.component.html",
  styleUrls: ["./mobile-catalog.component.scss"],
})
export class MobileCatalogComponent implements OnInit, OnChanges, OnDestroy {
  @Input() rerenderProductsItemsTimestamp: string | null;

  @Output() listScrollFromTopEv = new EventEmitter();
  @Output() listScrollFromBottomEv = new EventEmitter();

  public categories: any;
  public doNotShowProductsList: boolean;

  private subscription: Subscription;

  constructor(private store: Store<fromApp.AppState>) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.rerenderProductsItemsTimestamp &&
      changes.rerenderProductsItemsTimestamp.previousValue &&
      changes.rerenderProductsItemsTimestamp.currentValue &&
      changes.rerenderProductsItemsTimestamp.previousValue !==
        changes.rerenderProductsItemsTimestamp.currentValue
    ) {
      this.doNotShowProductsList = true;
      setTimeout(() => {
        this.doNotShowProductsList = false;
      }, 300);
    }
  }

  ngOnInit() {
    this.subscription = this.store
      .select("catalog")
      .pipe(auditTime(300))
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.catalog &&
          !_.isEqual(this.categories, state.catalog)
        ) {
          this.categories = _.cloneDeep(state.catalog);
        }
      });
  }

  scrollFromTopEvent(ev) {
    this.listScrollFromTopEv.emit(ev);
  }

  scrollFromBottomEvent(ev) {
    this.listScrollFromBottomEv.emit(ev);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
