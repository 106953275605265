import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import { Subscription } from "rxjs";
import { auditTime, distinctUntilChanged } from "rxjs/operators";
import * as fromApp from "../../../store/app.reducer";
import * as selectors from "../../../store/app.selectors";

@Component({
  selector: "app-product-items-mobile",
  templateUrl: "./product-items-mobile.component.html",
  styleUrls: ["./product-items-mobile.component.scss"],
})
export class ProductItemsMobileComponent implements OnInit, OnDestroy {
  @Input() product_id: any;
  @Input() category_id: any;
  @Input() productImage: any;
  @Input() hyperCategories: any;
  @Input() imageBrowser: any;
  @Input() cartProductIds: any;
  @Input() favoriteProduct: boolean;
  @Input() orderPickup;
  @Input() dineIn;
  @Input() selectedDineInTable: any;
  @Input() selectedPreorderDate: any;
  @Input() fromUnavailableProductsModal;

  @Input() webp: any;
  @Input() groupStoreData;
  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {}
}
