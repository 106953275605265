<app-catalog-search-component
  [scrollFromBottom]="scrollFromBottom"
  [scrollFromTop]="scrollFromTop"
  [forMobile]="true"
  [fabView]="true"
  *ngIf="
    !imageBrowser &&
    (!(
      groupApp &&
      groupApp.design_settings &&
      groupApp.design_settings.menuFooterTabs
    ) ||
      (groupApp &&
        groupApp.design_settings &&
        groupApp.design_settings.menuFooterTabs &&
        scrollFromTop > 80))
  "
></app-catalog-search-component>

<div
  #scrollDiv
  (scroll)="
    storeInfo &&
    storeInfo.design_settings &&
    storeInfo.design_settings.showOnlySelectedCategoryProducts
      ? calculateScrollFromTopAndBottom()
      : checkCategoryScrollDebounce()
  "
  [class]="disable_scroll ? 'scrollHeight disable-scroll' : 'scrollHeight'"
  id="scrollDiv"
  scrollY="true"
  scrollX="false"
  *ngIf="categories && groupApp"
>
  <ion-list class="ion-no-padding ion-no-margin">
    <app-previous-orders-slider-mobile
      [sliderButtons]="sliderButtons"
      *ngIf="
        (currentUser && hyperCategories && hyperCategories.length === 0) ||
        (currentUser &&
          hyperCategories &&
          hyperCategories.length &&
          currentHyperCategory === hyperCategories[0].hyper_category_id)
      "
    ></app-previous-orders-slider-mobile>
    <app-favorites-mobile
      [mobileMode]="true"
      *ngIf="
        storeInfo &&
        !storeInfo.showOnlyFavoritesCatalog &&
        ((currentUser && hyperCategories && hyperCategories.length === 0) ||
          (currentUser &&
            hyperCategories &&
            hyperCategories.length &&
            currentHyperCategory === hyperCategories[0].hyper_category_id))
      "
    ></app-favorites-mobile>
    <div
      *ngFor="
        let category of categories
          | filterSelectedCategoryProducts : selectedCategoryId : storeInfo
          | filterEmptyCategoriesForOnlyDineInDeliveryPickupProducts
            : orderPickup
            : dineIn;
        trackBy: categoriesTrackFn
      "
    >
      <app-product-market-type-cards-category-mobile
        *ngIf="
          category.activeTotal &&
          !category.emptyCategoryForSelectedDeliveryMethod &&
          !category.cardsDesign &&
          !category.sliderDesign &&
          category.marketCardsDesign
        "
        id="{{ 'category_' + category.category_id }}"
        [category_id]="category.category_id"
        [groupStoreData]="groupStoreData"
        [selectedDineInTable]="selectedDineInTable"
        [selectedPreorderDate]="selectedPreorderDate"
        [cartProductIds]="cartProductIds"
        [scrollFromBottom]="scrollFromBottom"
        (showNextCategoryButtonEvent)="showNextCategoryButton($event)"
      >
      </app-product-market-type-cards-category-mobile>
      <app-product-items-cards-category-mobile
        *ngIf="
          category.activeTotal &&
          category.cardsDesign &&
          !category.emptyCategoryForSelectedDeliveryMethod &&
          !category.sliderDesign &&
          !category.marketCardsDesign
        "
        id="{{ 'category_' + category.category_id }}"
        [category_id]="category.category_id"
        [groupStoreData]="groupStoreData"
        [selectedDineInTable]="selectedDineInTable"
        [selectedPreorderDate]="selectedPreorderDate"
      >
      </app-product-items-cards-category-mobile>
      <app-product-items-popular-category-mobile
        *ngIf="
          category.sliderDesign &&
          !category.sliderDesignOnlyImage &&
          !category.emptyCategoryForSelectedDeliveryMethod &&
          !category.cardsDesign &&
          !category.marketCardsDesign &&
          category.activeTotal
        "
        [category_id]="category.category_id"
        [sliderButtons]="sliderButtons"
        [groupStoreData]="groupStoreData"
        [selectedDineInTable]="selectedDineInTable"
        [selectedPreorderDate]="selectedPreorderDate"
        id="{{ 'category_' + category.category_id }}"
      >
      </app-product-items-popular-category-mobile>

      <app-product-items-popular-category-only-image-mobile
        *ngIf="
          category.sliderDesign &&
          category.sliderDesignOnlyImage &&
          !category.emptyCategoryForSelectedDeliveryMethod &&
          !category.cardsDesign &&
          !category.marketCardsDesign &&
          category.activeTotal
        "
        [category_id]="category.category_id"
        [sliderButtons]="sliderButtons"
        [groupStoreData]="groupStoreData"
        [selectedDineInTable]="selectedDineInTable"
        [selectedPreorderDate]="selectedPreorderDate"
        id="{{ 'category_' + category.category_id }}"
      >
      </app-product-items-popular-category-only-image-mobile>
      <app-category-item-mobile
        *ngIf="
          category.activeTotal &&
          !category.sliderDesign &&
          !category.cardsDesign &&
          !category.marketCardsDesign
        "
        id="{{ 'category_' + category.category_id }}"
        [category_id]="category.category_id"
        [groupStoreData]="groupStoreData"
      >
      </app-category-item-mobile>

      <app-subcategory-products-mobile
        *ngIf="
          category.subCategoriesActive &&
          category.subCategories &&
          !category.emptyCategoryForSelectedDeliveryMethod &&
          category.subCategories.length &&
          !category.sliderDesign &&
          !category.marketCardsDesign &&
          !category.cardsDesign
        "
        [category_id]="category.category_id"
        [imageBrowser]="imageBrowser"
        [unavailableProductsByCategoryId]="unavailableProductsByCategoryId"
        [changedUnavailableProductsByCategoryIdTimestamp]="
          changedUnavailableProductsByCategoryIdTimestamp
        "
        [scrollFromBottom]="scrollFromBottom"
      >
      </app-subcategory-products-mobile>

      <app-mobile-product-list-products
        [storeInfo]="storeInfo"
        [category_id]="category.category_id"
        [category]="category"
        [imageBrowser]="imageBrowser"
        [cartProductIds]="cartProductIds"
        [dineIn]="dineIn"
        [orderPickup]="orderPickup"
        [groupStoreData]="groupStoreData"
        [hyperCategories]="hyperCategoriesForProducts"
        [selectedDineInTable]="selectedDineInTable"
        [selectedPreorderDate]="selectedPreorderDate"
        [webp]="webp"
        [scrollFromBottom]="scrollFromBottom"
        (showNextCategoryButton)="showNextCategoryButtonEv($event)"
      >
      </app-mobile-product-list-products>

      <ion-grid
        style="width: 100%"
        *ngIf="
          category &&
          category.emptyCategoryForSelectedDeliveryMethod &&
          (!(
            storeInfo &&
            storeInfo.design_settings &&
            storeInfo.design_settings.showOnlySelectedCategoryProducts
          ) ||
            showNextCategoryButton)
        "
        class="ion-text-center"
      >
        <ion-item lines="none">
          <ion-label class="ion-text-wrap ion-text-center">
            <ion-text
              color="danger"
              *ngIf="orderPickup == false && dineIn == false"
              >{{
                "catalog.categories.there-are-no-products-in-this-category-for-delivery"
                  | translate
              }}
            </ion-text>
            <ion-text
              color="danger"
              *ngIf="orderPickup == true && dineIn == false"
              >{{
                "catalog.categories.there-are-no-products-in-this-category-for-takeaway"
                  | translate
              }}
            </ion-text>
            <ion-text
              color="danger"
              *ngIf="orderPickup == false && dineIn == true"
              >{{
                "catalog.categories.there-are-no-products-in-this-category-for-dinein"
                  | translate
              }}
            </ion-text>
          </ion-label>
        </ion-item>
      </ion-grid>

      <ion-grid
        style="width: 100%"
        *ngIf="
          storeInfo &&
          !storeInfo.showAllUnavailableProducts &&
          unavailableProductsByCategoryId &&
          category &&
          category.products &&
          !category.emptyCategoryForSelectedDeliveryMethod &&
          unavailableProductsByCategoryId[category.category_id] &&
          unavailableProductsByCategoryId[category.category_id].length &&
          !category.sliderDesign &&
          (!(
            storeInfo &&
            storeInfo.design_settings &&
            storeInfo.design_settings.showOnlySelectedCategoryProducts
          ) ||
            showNextCategoryButton)
        "
        class="ion-text-center"
      >
        <ion-button
          class="unavailableMinHeight"
          (click)="openUnavailableProductsModal(category)"
          button="true"
          lines="none"
          fill="clear"
          size="small"
        >
          <ion-label class="ion-text-center ion-no-margin">
            {{
              unavailableProductsByCategoryId[category.category_id].length <
              category.products.length
                ? "+"
                : ""
            }}{{ unavailableProductsByCategoryId[category.category_id].length }}
            {{ "unavailable-products" | translate }}
          </ion-label>
        </ion-button>
      </ion-grid>
    </div>

    <app-next-hyper-category-mobile
      [hidden]="!scrollFromTop || (scrollFromTop && scrollFromTop < 60)"
      [scrollFromTop]="scrollFromTop"
      *ngIf="
        hyperCategories &&
        hyperCategories.length &&
        hyperCategories.length > 1 &&
        (!(
          storeInfo &&
          storeInfo.design_settings &&
          storeInfo.design_settings.showOnlySelectedCategoryProducts
        ) ||
          showNextCategoryButton)
      "
    >
    </app-next-hyper-category-mobile>

    <div
      *ngIf="atLeastOneFrozenTag"
      style="
        margin-top: 20px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      "
      class="ion-text-center"
    >
      <ion-icon
        style="font-size: 14px; margin-right: 5px"
        color="primary"
        src="assets/flatIcons/frozen.svg"
      ></ion-icon>
      <ion-text style="font-size: small; font-weight: 500">
        {{ "item-tags.frozen" | translate }}
      </ion-text>
    </div>

    <app-catalog-disclaimer-button> </app-catalog-disclaimer-button>
  </ion-list>
</div>

<!-- <div *ngIf="testSignEvent"
  style="position: absolute;top:0px;right: 5px;height: 50px;width: 50px;background-color: brown;"></div>
<div *ngIf="testSignDirection"
  style="position: absolute;top:0px;left: 5px;height: 50px;width: 50px;background-color: black;"></div>
<div *ngIf="testSignScrollEvent"
  style="position: absolute;bottom:15px;left: 5px; height: 50px;width: 50px;background-color: blue;"></div> -->

<app-complete-order-button
  [hidden]="
    imageBrowser ||
    !cartProducts ||
    !cartProducts.length ||
    (groupApp &&
      groupApp.design_settings &&
      groupApp.design_settings.menuFooterTabs)
  "
  [scrollFromTop]="scrollFromTop"
  [scrollFromBottom]="scrollFromBottom"
>
</app-complete-order-button>
