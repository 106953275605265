import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { AnimationOptions } from "ngx-lottie";

@Component({
  selector: "app-user-winner-modal",
  templateUrl: "./user-winner-modal.component.html",
  styleUrls: ["./user-winner-modal.component.scss"],
})
export class UserWinnerModalComponent implements OnInit {
  @Input() winner;
  public winnerAnimationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/gift-opens.json",
  };
  winnerAnimation: any;
  constructor(private modalController: ModalController) {}

  ngOnInit() {}

  contestWinnerAnimationCreated(ev) {
    this.winnerAnimation = ev;
    this.winnerAnimation.setSpeed(1.2);
  }

  dismissModal() {
    const currentDate = Date.now();
    localStorage.setItem("winnerModalSeen", currentDate.toString());
    this.modalController.dismiss();
  }
}
