import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import { Subscription } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { PaymentGatewaysService } from "src/app/services/payment-gateways/payment-gateways.service";
import * as fromApp from "../../../store/app.reducer";
import * as selectors from "../../../store/app.selectors";

@Component({
  selector: "app-select-payment-gateway-tokenization",
  templateUrl: "./select-payment-gateway-tokenization.component.html",
  styleUrls: ["./select-payment-gateway-tokenization.component.scss"],
})
export class SelectPaymentGatewayTokenizationComponent
  implements OnInit, OnDestroy
{
  @Input() selectedStoreId: any;
  @Input() storesByStoreId: any;
  @Input() paymentGateways: any;
  public currentUser: any;
  public storeInfo: any;
  public darkMode: boolean;
  private selectedTheme: any;
  private groupSub: Subscription;
  private selectorSubscription: Subscription;
  private unsubscribeBackEvent: Subscription;
  constructor(
    private store: Store<fromApp.AppState>,
    private modalCtrl: ModalController,
    private paymentGatewaysService: PaymentGatewaysService,
    private platform: Platform
  ) {}

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );
    console.log("storesBystoreId", this.storesByStoreId);
    console.log("paymentGateways", this.paymentGateways);
    this.groupSub = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.selectedTheme &&
          !_.isEqual(this.selectedTheme, state.selectedTheme)
        ) {
          this.selectedTheme = _.cloneDeep(state.selectedTheme);
          console.log("selected theme", this.selectedTheme);
          if (this.selectedTheme === "dark") {
            this.darkMode = true;
          } else {
            this.darkMode = false;
          }
        }
      });

    this.selectorSubscription = this.store
      .select(selectors.getStoreInfo)
      .pipe(distinctUntilChanged())
      .subscribe((storeInfo) => {
        if (storeInfo && !_.isEqual(this.storeInfo, storeInfo)) {
          this.storeInfo = _.cloneDeep(storeInfo);
          if (!this.selectedStoreId) {
            this.selectedStoreId = _.cloneDeep(this.storeInfo.store_id);
          }
        }
      });
  }

  selectedPaymentGateway(grand_total, gateway, type, selectedCardStore) {
    this.paymentGatewaysService.selectedPaymentGateway(
      grand_total,
      gateway,
      type,
      selectedCardStore,
      null,
      this.storeInfo.store_id,
      null,
      null,
      null,
      null,
      null,
      null
    );
    this.modalCtrl.dismiss();
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  ngOnDestroy() {
    if (this.groupSub) {
      this.groupSub.unsubscribe();
    }
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.selectorSubscription) {
      this.selectorSubscription.unsubscribe();
    }
  }
}
