import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import * as _ from "lodash";

@Component({
  selector: "app-change-quantity",
  templateUrl: "./change-quantity.component.html",
  styleUrls: ["./change-quantity.component.scss"],
})
export class ChangeQuantityComponent implements OnInit, OnChanges {
  public roundQuantity: any = 1;
  @Input() centerQuantityItem: boolean;
  @Input() showIntegerQuantity;
  @Input() choiceStep: any;
  @Input() quantity;
  @Input() disableRemoveButton;
  @Input() disablePlusButton;
  @Input() hiddeQuntityComponent;
  @Input() buttonsSize;
  @Input() textSizeMedium;
  @Input() quantityInChoicesKind: any;
  @Input() productQtyWithStep: boolean;
  @Input() productStepKind: any;
  @Input() fromCartCatalogItem: boolean;
  @Input() hideQuantityButtons: boolean;
  @Output() changingQuantity = new EventEmitter();

  public textSizeSmall = false;
  public textSizeExtraSmall = false;

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.quantity &&
      changes.quantity.previousValue !== changes.quantity.currentValue &&
      changes.quantity.previousValue &&
      this.showIntegerQuantity
    ) {
      this.roundQuantity = this.roundUp(
        _.cloneDeep(changes.quantity.currentValue)
      );
    }
  }
  ngOnInit() {
    if (this.quantity && this.showIntegerQuantity) {
      this.roundQuantity = this.roundUp(_.cloneDeep(this.quantity));
    }

    if (this.productQtyWithStep && this.productStepKind) {
      this.buttonsSize = "small";
      this.textSizeMedium = false;
      if (this.fromCartCatalogItem) {
        this.textSizeExtraSmall = true;
      } else {
        this.textSizeSmall = true;
      }
    }
  }

  roundUp(quantity) {
    if (quantity) {
      // console.log(
      //   "qqqqqq",
      //   this.choiceStep,
      //   quantity,

      //   parseFloat(quantity.toString()) / parseFloat(this.choiceStep.toString())
      // );
      return Math.round(
        parseFloat(quantity.toString()) / parseFloat(this.choiceStep.toString())
      );
    }
  }

  QuantityDecrease() {
    this.changingQuantity.emit("decrease");
  }

  QuantityIncrease() {
    this.changingQuantity.emit("increase");
  }
}
