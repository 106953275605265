import * as SelectedPaymentMethodIDACtions from "./selectePaymentMethod.actions";
import * as _ from "lodash";

export interface State {
  selectedPaymentMethodID: any;
}

const initialState: State = {
  selectedPaymentMethodID: null,
};

export function selectedPaymentMethodIDReducer(
  state = initialState,
  action: SelectedPaymentMethodIDACtions.SelectedPaymentMethodIDActions
) {
  switch (action.type) {
    case SelectedPaymentMethodIDACtions.SET_SELECTED_PAYMENT_METHOD_ID:
      return {
        ...state,
        selectedPaymentMethodID: action.payload,
      };

    case SelectedPaymentMethodIDACtions.CLEAR_SELECTED_PAYMENT_METHOD_ID:
      return {
        ...state,
        selectedPaymentMethodID: null,
      };

    default:
      return state;
  }
}
