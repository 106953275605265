<ion-row *ngIf="!categories || doNotShowProductsList">
  <ion-col class="ion-text-center">
    <ion-spinner></ion-spinner>
  </ion-col>
</ion-row>

<app-mobile-product-list
  *ngIf="categories && !doNotShowProductsList"
  (listScrollFromTopEvent)="scrollFromTopEvent($event)"
  (listScrollFromBottomEvent)="scrollFromBottomEvent($event)"
></app-mobile-product-list>
