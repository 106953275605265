import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { auditTime, distinctUntilChanged } from "rxjs/operators";
import * as fromApp from "../../../store/app.reducer";
import * as _ from "lodash";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import * as OrdersActions from "../../../orders/store/orders.actions";

@Component({
  selector: "app-nbg-simplify-modal",
  templateUrl: "./nbg-simplify-modal.component.html",
  styleUrls: ["./nbg-simplify-modal.component.scss"],
})
export class NbgSimplifyModalComponent implements OnInit {
  @Input() nbg_simplify_frame;
  @Input() frame_width;
  @Input() frame_height;
  private ethnikiModalIsOpen: boolean = true;
  private subscription: Subscription;
  private unsubscribeBackEvent: Subscription;

  constructor(
    private modalCtrl: ModalController,
    private store: Store<fromApp.AppState>,
    private platform: Platform
  ) {}

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      300,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );
    if (this.frame_width && this.frame_height) {
      const el: any = document.querySelector(".paymentModal");
      el.style.setProperty("--paymentModalWidth", this.frame_width + "px");
      el.style.setProperty("--paymentModalHeight", this.frame_height + "px");
    }
    this.store.dispatch(new OrdersActions.SetEthnikiPopupOpen(true));
    this.subscription = this.store
      .select("orders")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (
          state &&
          !_.isEqual(this.ethnikiModalIsOpen, state.ethnikiPopupOpen)
        ) {
          this.ethnikiModalIsOpen = _.cloneDeep(state.ethnikiPopupOpen);
          if (!this.ethnikiModalIsOpen) {
            this.closeModal();
          }
        }
      });
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  ngOnDestroy() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
