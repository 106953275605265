import * as CartCustomOptionsSelectionsActions from "./cart-custom-options-selections.actions";
import * as _ from "lodash";

export interface State {
  cartCustomOptionsSelections: any[];
}

const initialState: State = {
  cartCustomOptionsSelections: null,
};

export function cartCustomOptionsSelectionsReducer(
  state = initialState,
  action: CartCustomOptionsSelectionsActions.CartCustomOptionsSelectionsActions
) {
  switch (action.type) {
    case CartCustomOptionsSelectionsActions.ADD_UPDATE_CUSTOM_OPTION_SELECTION:
      const key = _.findIndex(state.cartCustomOptionsSelections, (option) => {
        return option.timestamp === action.payload.timestamp;
      });
      if (key !== -1) {
        const updateOption = {
          ...state.cartCustomOptionsSelections[key],
          ...action.payload,
        };
        const updatedCartCustomOptions = [...state.cartCustomOptionsSelections];
        updatedCartCustomOptions[key] = updateOption;
        return {
          ...state,
          cartCustomOptionsSelections: updatedCartCustomOptions,
        };
      } else {
        if (!state.cartCustomOptionsSelections) {
          return {
            ...state,
            cartCustomOptionsSelections: [action.payload],
          };
        } else {
          return {
            ...state,
            cartCustomOptionsSelections: [
              ...state.cartCustomOptionsSelections,
              action.payload,
            ],
          };
        }
      }

    case CartCustomOptionsSelectionsActions.CLEAR_CART_CUSTOM_OPTIONS_SELECTIONS:
      return {
        ...state,
        cartCustomOptionsSelections: null,
      };

    default:
      return state;
  }
}
