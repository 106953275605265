<!-- <ion-row
  class="ion-padding"
  *ngIf="
    currentUser &&
    loginState &&
    loginState === 'login_completed' &&
    userPoints &&
    userPoints.pointsSystem
  "
>
  <ion-col class="ion-text-center">
    <ion-badge>
      {{ "points.rewards.your-points" | translate }}:
      <strong>{{ userPoints.pointsSystem.active }}</strong>
    </ion-badge>
  </ion-col>
</ion-row> -->

<!-- <ion-segment
  *ngIf="storeInfo && storeInfo.dm_kiosk_app_active && thereIsOnlyKioskOffers"
  style="min-height: 55px"
  mode="ios"
  [(ngModel)]="filterType"
>
  <ion-segment-button value="mobileorder">
    <ion-item class="item-segment-style">
      <ion-icon
        slot="start"
        style="font-size: 35px"
        src="assets/flatIcons/smartphone.svg"
      >
      </ion-icon>

      <ion-label> Apps </ion-label>
    </ion-item>
  </ion-segment-button>
  <ion-segment-button value="kiosk">
    <ion-item class="item-segment-style">
      <ion-icon
        slot="start"
        style="font-size: 38px"
        src="assets/flatIcons/kiosk.svg"
      >
      </ion-icon>

      <ion-label> Kiosk </ion-label>
    </ion-item>
  </ion-segment-button>
</ion-segment> -->

<ion-grid
  style="max-width: 630px"
  *ngIf="
    groupApp &&
    groupApp.pointsSystem &&
    (rewards || groupApp.points.rewards) &&
    ((!currentUser && loginState && loginState === 'no_login') ||
      (currentUser && loginState && loginState === 'login_completed'))
  "
>
  <ion-row style="width: 100%">
    <ion-col
      *ngFor="
        let reward of (rewards ? rewards : groupApp.points.rewards)
          | kioskReward : filterType;
        trackBy: trackRewards
      "
      [size]="colSizeRewards"
    >
      <ion-grid style="width: 320px; height: 250px; padding: 5px">
        <ion-card
          (click)="confirmUsePoints(reward)"
          color="light"
          button="true"
          style="height: 240px; border-radius: 15px"
          mode="md"
        >
          <div
            *ngIf="reward.loyaltyCardLevelsActive && reward.loyaltyCardLevels"
            style="
              position: absolute;
              top: 10px;
              right: 10px;
              z-index: 100000;
              width: 90%;
            "
          >
            <span *ngFor="let level_id of reward.loyaltyCardLevels">
              <ion-badge
                *ngIf="
                  level_id &&
                  loyaltyCardLevelsById &&
                  loyaltyCardLevelsById[level_id] &&
                  loyaltyCardLevelsById[level_id].name &&
                  selectedLangugage &&
                  mo_base_lang
                "
                style="margin-left: 2px"
              >
                {{
                  loyaltyCardLevelsById[level_id].name[selectedLangugage]
                    ? loyaltyCardLevelsById[level_id].name[mo_base_lang]
                    : loyaltyCardLevelsById[level_id].id
                }}
              </ion-badge>
            </span>
          </div>

          <div *ngIf="!reward.image" style="margin-top: 145px"></div>
          <img
            *ngIf="reward.image"
            class="rewardImage"
            alt="rewardImage"
            loading="lazy"
            [src]="
              'https://d3vm1ym7xlqx7l.cloudfront.net/' +
              groupApp.group +
              '/rewards/' +
              reward.image
            "
          />
          <ion-item color="light" class="noPaddingsItem" lines="none">
            <ion-label class="ion-text-wrap" style="margin: 0">
              <div
                style="font-size: small; font-weight: bold; line-height: 1.1"
              >
                <ion-text> {{ reward.title }} </ion-text>
              </div>
            </ion-label>
          </ion-item>
          <ion-badge
            *ngIf="reward.points"
            style="
              position: absolute;
              bottom: 10px;
              right: 10px;
              z-index: 100000;
            "
          >
            {{ reward.points }} {{ "points.rewards.points" | translate }}
          </ion-badge>

          <ion-ripple-effect></ion-ripple-effect>
        </ion-card>
      </ion-grid>
    </ion-col>
  </ion-row>
</ion-grid>
