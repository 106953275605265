<!-- 
  TODO CHECK IF WE NEED SEARCH COMPONENT IN RESERVATION APP
  
  <app-catalog-search-component
  [scrollFromBottom]="scrollFromBottom"
  [scrollFromTop]="scrollFromTop"
  [forMobile]="true"
  [fabView]="true"
  *ngIf="!imageBrowser"
></app-catalog-search-component> -->

<div
  #scrollDiv
  (scroll)="
    storeInfo &&
    storeInfo.design_settings &&
    storeInfo.design_settings.showOnlySelectedCategoryProducts
      ? calculateScrollFromTopAndBottom()
      : checkCategoryScrollDebounce()
  "
  [class]="disable_scroll ? 'scrollHeight disable-scroll' : 'scrollHeight'"
  id="scrollDiv"
  scrollY="true"
  scrollX="false"
  *ngIf="categories && groupApp"
>
  <app-reservation-catalog-coupons
    *ngIf="groupApp.pointsSystem"
  ></app-reservation-catalog-coupons>
  <ion-item lines="none">
    <ion-label
      class="ion-text-center ion-text-wrap"
      style="font-size: large; font-weight: bold"
    >
      {{ "reservation-app.storeServices" | translate }}
    </ion-label>
  </ion-item>
  <ion-list class="ion-no-padding ion-no-margin">
    <div
      *ngFor="
        let category of categories
          | filterSelectedCategoryProducts : selectedCategoryId : storeInfo
          | filterEmptyCategoriesForOnlyDineInDeliveryPickupProducts
            : orderPickup
            : dineIn;
        trackBy: categoriesTrackFn
      "
    >
      <app-reservation-product-list-products
        [storeInfo]="storeInfo"
        [category_id]="category.category_id"
        [category]="category"
        [imageBrowser]="imageBrowser"
        [cartProductIds]="cartProductIds"
        [dineIn]="dineIn"
        [orderPickup]="orderPickup"
        [groupStoreData]="groupStoreData"
        [selectedDineInTable]="selectedDineInTable"
        [selectedPreorderDate]="selectedPreorderDate"
        [webp]="webp"
        [scrollFromBottom]="scrollFromBottom"
        [finalPriceLoading]="finalPriceLoading"
        (showNextCategoryButton)="showNextCategoryButtonEv($event)"
      >
      </app-reservation-product-list-products>

      <ion-grid
        style="width: 100%"
        *ngIf="
          category &&
          category.emptyCategoryForSelectedDeliveryMethod &&
          (!(
            storeInfo &&
            storeInfo.design_settings &&
            storeInfo.design_settings.showOnlySelectedCategoryProducts
          ) ||
            showNextCategoryButton)
        "
        class="ion-text-center"
      >
        <ion-item lines="none">
          <ion-label class="ion-text-wrap ion-text-center">
            <ion-text
              color="danger"
              *ngIf="orderPickup == false && dineIn == false"
              >{{
                "catalog.categories.there-are-no-products-in-this-category-for-delivery"
                  | translate
              }}
            </ion-text>
            <ion-text
              color="danger"
              *ngIf="orderPickup == true && dineIn == false"
              >{{
                "catalog.categories.there-are-no-products-in-this-category-for-takeaway"
                  | translate
              }}
            </ion-text>
            <ion-text
              color="danger"
              *ngIf="orderPickup == false && dineIn == true"
              >{{
                "catalog.categories.there-are-no-products-in-this-category-for-dinein"
                  | translate
              }}
            </ion-text>
          </ion-label>
        </ion-item>
      </ion-grid>

      <ion-grid
        style="width: 100%"
        *ngIf="
          storeInfo &&
          !storeInfo.showAllUnavailableProducts &&
          unavailableProductsByCategoryId &&
          category &&
          category.products &&
          !category.emptyCategoryForSelectedDeliveryMethod &&
          unavailableProductsByCategoryId[category.category_id] &&
          unavailableProductsByCategoryId[category.category_id].length &&
          !category.sliderDesign &&
          (!(
            storeInfo &&
            storeInfo.design_settings &&
            storeInfo.design_settings.showOnlySelectedCategoryProducts
          ) ||
            showNextCategoryButton)
        "
        class="ion-text-center"
      >
        <ion-button
          class="unavailableMinHeight"
          (click)="openUnavailableProductsModal(category)"
          button="true"
          lines="none"
          fill="clear"
          size="small"
        >
          <ion-label class="ion-text-center ion-no-margin">
            {{
              unavailableProductsByCategoryId[category.category_id].length <
              category.products.length
                ? "+"
                : ""
            }}{{ unavailableProductsByCategoryId[category.category_id].length }}
            {{ "unavailable-products" | translate }}
          </ion-label>
        </ion-button>
      </ion-grid>
    </div>

    <app-next-hyper-category-mobile
      [hidden]="!scrollFromTop || (scrollFromTop && scrollFromTop < 60)"
      [scrollFromTop]="scrollFromTop"
      *ngIf="
        hyperCategories &&
        hyperCategories.length &&
        hyperCategories.length > 1 &&
        (!(
          storeInfo &&
          storeInfo.design_settings &&
          storeInfo.design_settings.showOnlySelectedCategoryProducts
        ) ||
          showNextCategoryButton)
      "
    >
    </app-next-hyper-category-mobile>
  </ion-list>
</div>

<!-- <div *ngIf="testSignEvent"
  style="position: absolute;top:0px;right: 5px;height: 50px;width: 50px;background-color: brown;"></div>
<div *ngIf="testSignDirection"
  style="position: absolute;top:0px;left: 5px;height: 50px;width: 50px;background-color: black;"></div>
<div *ngIf="testSignScrollEvent"
  style="position: absolute;bottom:15px;left: 5px; height: 50px;width: 50px;background-color: blue;"></div> -->

<!-- <app-complete-order-button
  [hidden]="imageBrowser || !cartProducts || !cartProducts.length"
  [scrollFromTop]="scrollFromTop"
  [scrollFromBottom]="scrollFromBottom"
>
</app-complete-order-button> -->
