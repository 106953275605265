import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Subscription } from "rxjs";
import { auditTime, debounceTime, distinctUntilChanged } from "rxjs/operators";
import * as selectors from "../../../../store/app.selectors";
import * as _ from "lodash";
import * as fromApp from "../../../../store/app.reducer";
import { Store } from "@ngrx/store";

import { ModalController, Platform } from "@ionic/angular";
import { RadioClosedChoicesModalComponent } from "./radio-closed-choices-selection-modal/radio-closed-choices-selection-modalt.component";

@Component({
  selector: "app-radio-option-closed-choices",
  templateUrl: "./radio-option-closed-choices.component.html",
  styleUrls: ["./radio-option-closed-choices.component.scss"],
})
export class RadioOptionClosedChoicesComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() selectedProductId: any;
  @Input() product_id;
  @Input() productName: any;
  @Input() bundleMode: any;
  @Input() selectedProductParentDiscount;
  @Input() option_id;
  @Input() selectedProductPromo: any;
  @Input() selectedProductCategory: any;
  @Input() selectedProductFreeParentOption: any;
  @Output() optionChange = new EventEmitter();
  @Input() optionValidationObject;
  @Input() groupStoreData;
  public option: any;
  public selectedChoice: any;
  public orderPickup;
  public dineIn;
  public selectedDineInTable;
  public alt_dinein_active;
  public store_info;
  private optionValidations: any;
  private lastValidTimestamp: any = null;
  private radioClosedChoicesModal: any;
  private unsubscribeBackEvent: Subscription;
  private subscriptions: Subscription[] = [];
  private optionSubscription: Subscription;

  constructor(
    private store: Store<fromApp.AppState>,
    private modalCtrl: ModalController,
    private platform: Platform
  ) {}

  selectOption() {
    if (this.optionSubscription) {
      this.optionSubscription.unsubscribe();
    }
    this.optionSubscription = this.store
      .select(
        selectors.getOptionByOptionId(this.selectedProductId, this.option_id)
      )
      .pipe(distinctUntilChanged())
      .pipe(auditTime(50))
      .subscribe((state) => {
        if (!_.isEqual(this.option, state)) {
          this.option = _.cloneDeep(state);
          if (this.option && this.option.choices) {
            this.selectedChoice = null;
            _.each(this.option.choices, (ch) => {
              if (ch.is_preselected) {
                this.selectedChoice = _.cloneDeep(ch);
                console.log("selected choice", this.selectedChoice);
              }
            });
          }
        }
      });
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (
      simpleChanges &&
      simpleChanges.option_id &&
      simpleChanges.option_id.currentValue !==
        simpleChanges.option_id.previousValue
    ) {
      this.selectOption();
    }
  }

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      300,
      () => {
        if (this.platform.is("android")) {
          this.modalCtrl.dismiss();
        }
      }
    );

    this.subscriptions.push(
      this.store
        .select("deliveryMethodAddress")

        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && !_.isEqual(this.dineIn, state.dineIn)) {
            this.dineIn = _.cloneDeep(state.dineIn);
          }
          if (state && !_.isEqual(this.orderPickup, state.orderPickup)) {
            this.orderPickup = _.cloneDeep(state.orderPickup);
          }
          if (
            state &&
            !_.isEqual(this.selectedDineInTable, state.selectedDineInTable)
          ) {
            this.selectedDineInTable = _.cloneDeep(state.selectedDineInTable);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .subscribe((store_info) => {
          if (store_info && !_.isEqual(this.store_info, store_info)) {
            this.store_info = _.cloneDeep(store_info);
            if (this.store_info && this.store_info.dinein) {
              this.alt_dinein_active = this.store_info.alt_dinein_active;
            } else {
              this.alt_dinein_active = false;
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("productOptionsValidation")
        .pipe(distinctUntilChanged())
        .pipe(debounceTime(300))
        .subscribe((state) => {
          if (
            state &&
            state.lastValidTimestampBySelectedProductId &&
            state.lastValidTimestampBySelectedProductId[
              this.selectedProductId
            ] &&
            !_.isEqual(
              this.lastValidTimestamp,
              state.lastValidTimestampBySelectedProductId[
                this.selectedProductId
              ]
            )
          ) {
            console.log(
              "change the timestamp",
              state.lastValidTimestampBySelectedProductId[
                this.selectedProductId
              ]
            );
            this.lastValidTimestamp = _.cloneDeep(
              state.lastValidTimestampBySelectedProductId[
                this.selectedProductId
              ]
            );
            if (
              !_.isEmpty(
                state.optionValidationsBySelectedProductId[
                  this.selectedProductId
                ]
              )
            ) {
              this.optionValidations = _.cloneDeep(
                state.optionValidationsBySelectedProductId[
                  this.selectedProductId
                ]
              );
              let foundOptionToOpen = _.find(this.optionValidations, {
                optionToOpen: true,
              });
              if (
                foundOptionToOpen &&
                this.option &&
                _.isEqual(foundOptionToOpen.option_id, this.option.option_id)
              ) {
                if (!this.radioClosedChoicesModal) {
                  this.radioClosedChoicesModal = true;
                  this.openSelectionModalDebounce();
                }
              }
            }
          }
        })
    );
  }

  optionChanged(opt) {
    console.log(opt);
    this.optionChange.emit(opt);
  }

  toFloat(num) {
    return parseFloat(num.toString());
  }

  async openSelectionModal(option_id) {
    this.radioClosedChoicesModal = await this.modalCtrl.create({
      component: RadioClosedChoicesModalComponent,
      cssClass: "radioClosedChoicesSelectionModal",
      backdropDismiss: false,
      animated: true,

      componentProps: {
        option_id: option_id,
        selectedProductCategory: this.selectedProductCategory,
        selectedProductPromo: this.selectedProductPromo,
        bundleMode: this.bundleMode,
        selectedProductId: this.selectedProductId,
        groupStoreData: this.groupStoreData,
      },
    });

    await this.radioClosedChoicesModal.present();
    this.radioClosedChoicesModal.onDidDismiss().then((data) => {
      this.radioClosedChoicesModal = null;
      if (data && data.data) {
        this.optionChanged(data.data);
      } else {
      }
    });
  }
  openSelectionModalDebounce = _.debounce(
    () => {
      this.openSelectionModal(this.option.option_id);
    },

    100
  );

  ngOnDestroy() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.radioClosedChoicesModal) {
      this.radioClosedChoicesModal.dismiss();
      this.radioClosedChoicesModal = null;
    }
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    if (this.optionSubscription) {
      this.optionSubscription.unsubscribe();
    }
  }
}
