import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import { ModalController, Platform } from "@ionic/angular";

import { AnimationOptions } from "ngx-lottie";
import { Subscription } from "rxjs";

declare let window: any;

@Component({
  selector: "app-delivery-info-modal",
  templateUrl: "./delivery-info-modal.component.html",
  styleUrls: ["./delivery-info-modal.component.scss"],
})
export class DeliveryInfoModalComponent implements OnInit, OnDestroy {
  @Input() message: any;
  @Input() button_one: any;
  @Input() button_two: any;

  @Input() selectedLanguage: any;
  @Input() baseLanguage;

  public loading = false;

  private unsubscribeBackEvent: Subscription;

  animationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/delivery-info.json",
  };

  constructor(
    private modalController: ModalController,
    private platform: Platform
  ) {}

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      300,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );
    // this.animationOptions = {
    //   ...this.animationOptions,
    //   path: this.lottie_url,
    // };
  }

  closeModal() {
    this.modalController.dismiss();
  }

  animationCreated(ev) {}

  onClickOk(link) {
    if (link) {
      this.openLink(link);
      this.modalController.dismiss();
    }
  }

  openLink = async (link) => {
    //open link external on new page
    if (Capacitor.isNativePlatform()) {
      window.open(link, "_system");
    } else {
      window.open(link, "_blank");
    }
  };

  ngOnDestroy(): void {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
  }
}
