import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { AlertController, ModalController, Platform } from "@ionic/angular";
import { AnimationOptions } from "ngx-lottie";

import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import { TranslateService } from "@ngx-translate/core";
import _ from "lodash";
import { Subscription, auditTime, distinctUntilChanged } from "rxjs";
import { Store } from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";
import { Router } from "@angular/router";

@Component({
  selector: "app-extend-modal",
  templateUrl: "./extend-modal.component.html",
  styleUrls: ["./extend-modal.component.scss"],
})
export class ExtendModalComponent implements OnInit, OnDestroy {
  @Input() review: any;
  @Input() reservationForEdit: any;
  @Input() submit: any;
  public options: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/checkmark-reservations-animation.json",
  };
  public options2: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/cancel-reservations-animation.json",
  };
  public animation: any;

  private mo_token: any;
  private subscription: Subscription;
  private unsubscribeBackEvent: Subscription;
  public canceling = false;

  constructor(
    public modalCtrl: ModalController,
    private alertController: AlertController,
    private dataStorageService: DataStorageService,
    private translateService: TranslateService,
    private router: Router,
    private platform: Platform,
    private store: Store<fromApp.AppState>
  ) {}

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      500,
      () => {
        if (this.platform.is("android")) {
          this.modalCtrl.dismiss(null, null, "extendModal");
        }
      }
    );
    this.subscription = this.store
      .select("auth")
      .pipe(auditTime(200))
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.mo_token &&
          !_.isEqual(this.mo_token, state.mo_token)
        ) {
          this.mo_token = _.cloneDeep(state.mo_token);
        }
      });

    if (this.review) {
      setTimeout(() => {
        this.modalCtrl.dismiss(null, null, "extendModal");
      }, 3000);
    }
  }

  animationCreated(ev) {
    this.animation = ev;
    this.animation.setSpeed(1);
  }

  navigateToCatalog() {
    this.router.navigateByUrl("/reservationSystem/reservation-catalog", {
      replaceUrl: true,
    });
    this.modalCtrl.dismiss(null, null, "extendModal");
  }

  naviateToMyDates() {
    this.router.navigateByUrl("/reservationSystem/reservation-my-dates", {
      replaceUrl: true,
    });
  }

  async navigateOnDates() {
    //TODO NAVIGATING TO RESERVATIONS CATALOG ????
    // this.modalCtrl.dismiss(true);
    // const modal = await this.modalCtrl.create({
    //   component: ReservationStorePreviewPage,
    //   animated: true,
    //   backdropDismiss: false,
    //   cssClass: "fullScreenModal",
    //   componentProps: {
    //     canceled: this.canceled,
    //   },
    // });
    // await modal.present();
  }

  async confirmAndCancelDate() {
    const message = this.translateService.instant(
      "reservation-app.areYouSureUwantCancelDate"
    );
    await this.presentConfirmationAlert(message, "cancel");
  }

  async confirmAndNavigateOnDates() {
    const message = this.translateService.instant(
      "reservation-app.cancelAndRecreate"
    );
    await this.presentConfirmationAlert(message, "recreate");
  }

  async cancelSuccessAlert() {
    const alert = await this.alertController.create({
      header: this.translateService.instant(
        "reservation-app.successful_cancel"
      ),
      //backdropDismiss: false,
      buttons: ["OK"],
    });
    await alert.present();
  }

  async presentConfirmationAlert(message: string, from): Promise<boolean> {
    const alert = await this.alertController.create({
      header: this.translateService.instant("reservation-app.confirmation"),
      message: message,
      buttons: [
        {
          text: this.translateService.instant("reservation-app.cancel"),
          role: "cancel",
          cssClass: "secondary",
          handler: () => {
            return false;
          },
        },
        {
          text: "OK",
          handler: async () => {
            this.canceling = true;
            this.dataStorageService
              .handleReservation(
                this.mo_token,
                this.reservationForEdit,
                "cancel"
              )
              .subscribe({
                next: async (res: any) => {
                  this.canceling = false;
                  if (!res || !res.success) {
                    const alert = await this.alertController.create({
                      header: this.translateService.instant("alert"),
                      message:
                        res && res.comment_id
                          ? this.translateService.instant(res.comment_id)
                          : this.translateService.instant(
                              "errorMessages.general_error"
                            ),
                      backdropDismiss: false,
                      buttons: ["OK"],
                    });
                    await alert.present();
                  } else {
                    this.modalCtrl.dismiss(true, null, "extendModal");
                    if (from === "recreate") {
                      await this.navigateToCatalog();
                    }
                    await this.cancelSuccessAlert();
                  }
                },
                error: async (error) => {
                  this.canceling = false;
                  const alert = await this.alertController.create({
                    header: this.translateService.instant("alert"),
                    message: this.translateService.instant(
                      "errorMessages.problem_reaching_server"
                    ),

                    buttons: ["OK"],
                  });
                  await alert.present();
                },
              });
            return true;
          },
        },
      ],
    });

    await alert.present();
    const { role } = await alert.onDidDismiss();
    return role === "ok";
  }

  close() {
    this.modalCtrl.dismiss(null, null, "extendModal");
  }

  ngOnDestroy(): void {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
