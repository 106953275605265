<ion-row
  class="ion-padding"
  *ngIf="
    currentUser &&
    loginState &&
    loginState === 'login_completed' &&
    userPoints &&
    userPoints.pointsSystem
  "
>
  <ion-col class="ion-text-center">
    <ion-badge>
      {{ "points.rewards.your-points" | translate }}:
      <strong>{{ userPoints.pointsSystem.active }}</strong>
    </ion-badge>
  </ion-col>
</ion-row>

<ion-segment
  *ngIf="
    groupApp &&
    storeInfo &&
    storeInfo.dm_kiosk_app_active &&
    !(
      groupApp.design_settings &&
      groupApp.design_settings.dm_kiosk_app_disable_mobileorder
    ) &&
    thereIsOnlyKioskOffers
  "
  style="min-height: 55px"
  mode="ios"
  [(ngModel)]="filterType"
>
  <ion-segment-button value="mobileorder">
    <ion-item class="item-segment-style">
      <ion-icon
        slot="start"
        style="font-size: 35px"
        src="assets/flatIcons/smartphone.svg"
      >
      </ion-icon>

      <ion-label> Apps </ion-label>
    </ion-item>
  </ion-segment-button>
  <ion-segment-button value="kiosk">
    <ion-item class="item-segment-style">
      <ion-icon
        slot="start"
        style="font-size: 38px"
        src="assets/flatIcons/kiosk.svg"
      >
      </ion-icon>

      <ion-label> Kiosk </ion-label>
    </ion-item>
  </ion-segment-button>
</ion-segment>

<span
  *ngIf="
    groupApp &&
    groupApp.pointsSystem &&
    (rewards || groupApp.points.rewards) &&
    ((!currentUser && loginState && loginState === 'no_login') ||
      (currentUser && loginState && loginState === 'login_completed'))
  "
>
  <ion-grid style="max-width: 670px; padding: 0">
    <ion-row style="width: 100%">
      <ion-col
        style="padding: 0"
        *ngFor="
          let reward of (rewards ? rewards : groupApp.points.rewards)
            | kioskReward : filterType;
          trackBy: trackRewards
        "
        [size]="
          windowDimensions &&
          windowDimensions.width &&
          windowDimensions.width > 600 &&
          ((rewards ? rewards : groupApp.points.rewards)
            | kioskReward : filterType) &&
          (
            (rewards ? rewards : groupApp.points.rewards)
            | kioskReward : filterType
          ).length > 1
            ? '6'
            : '12'
        "
      >
        <ion-grid
          [ngStyle]="{
            'max-width':
              windowDimensions &&
              windowDimensions.width &&
              windowDimensions.width <= 600
                ? '450px'
                : '330px',
            padding: '0'
          }"
        >
          <ion-card
            class="ion-text-center"
            [ngStyle]="{
              height:
                windowDimensions &&
                windowDimensions.width &&
                windowDimensions.width >= 600
                  ? canGetAtLeastOneReward
                    ? reward.image
                      ? '320px'
                      : '150px'
                    : reward.image
                    ? '260px'
                    : '100px'
                  : '',
              padding: '0'
            }"
          >
            <ion-button
              (click)="openGetPromoModal(reward)"
              fill="clear"
              size="small"
              style="position: absolute; left: 0; top: 0; z-index: 10000"
            >
              <ion-icon
                style="font-size: 20px"
                slot="icon-only"
                name="information-circle"
              ></ion-icon>
            </ion-button>

            <img
              [class]="
                windowDimensions &&
                windowDimensions.width &&
                windowDimensions.width >= 600
                  ? 'rewardImageMaxHeight'
                  : ''
              "
              alt="rewardImage"
              loading="lazy"
              *ngIf="reward.image"
              [src]="
                'https://d3vm1ym7xlqx7l.cloudfront.net/' +
                groupApp.group +
                '/rewards/' +
                reward.image
              "
            />

            <ion-item
              lines="none"
              [class]="
                !currentUser &&
                !reward.image &&
                windowDimensions &&
                windowDimensions.width &&
                windowDimensions.width >= 600
                  ? 'ion-text-wrap ion-text-center fullHeightItem'
                  : 'ion-text-wrap ion-text-center'
              "
            >
              <ion-label
                style="line-height: 1.1"
                class="ion-text-wrap ion-text-center wrapInTwoLines"
              >
                <ion-text style="font-size: small; font-weight: bold">
                  {{ reward.title }} - {{ reward.points }}
                  {{ "points.rewards.points" | translate }}
                </ion-text>
              </ion-label>
            </ion-item>

            <ion-item
              lines="none"
              *ngIf="
                currentUser &&
                currentUser &&
                loginState &&
                loginState === 'login_completed' &&
                userPoints &&
                userPoints.pointsSystem.pending > 0
              "
            >
              {{ userPoints.pointsSystem.pending }}
              {{ "points.rewards.points-pending" | translate }}
            </ion-item>
            <ion-item *ngIf="reward.user_points_percent" lines="none">
              <div
                style="width: 100%"
                [class]="
                  reward.user_points_percent < 50
                    ? 'progressbarBlack'
                    : reward.user_points_percent === 100
                    ? 'progressbarSuccess'
                    : 'progressbarWarning'
                "
              >
                <div [ngStyle]="{ width: reward.user_points_percent + '%' }">
                  <ion-text
                    [color]="
                      reward.user_points_percent >= 50 &&
                      reward.user_points_percent < 100
                        ? 'black'
                        : reward.user_points_percent < 50
                        ? 'white'
                        : 'white'
                    "
                    >{{ reward.user_points_percent }}%</ion-text
                  >
                </div>
              </div>
            </ion-item>

            <ion-button
              *ngIf="canGetAtLeastOneReward"
              style="margin-bottom: 18px"
              (click)="openGetPromoModal(reward)"
              [disabled]="
                usePointsLoading || reward.user_points_percent !== 100
              "
              size="small"
              color="primary"
            >
              <ion-label class="ion-text-center">
                <ion-text *ngIf="!usePointsLoading">
                  {{
                    "homepage.point-system.get-the-coupon" | translate
                  }}</ion-text
                >
                <ion-spinner
                  *ngIf="usePointsLoading"
                  style="color: var(--ion-color-text)"
                >
                </ion-spinner>
              </ion-label>
            </ion-button> </ion-card></ion-grid></ion-col></ion-row
  ></ion-grid>

  <ion-item
    lines="none"
    *ngIf="
      storeInfo &&
      storeInfo.dm_kiosk_app_active &&
      !(
        groupApp.design_settings &&
        groupApp.design_settings.dm_kiosk_app_disable_mobileorder
      ) &&
      ((!kioskRewardsFound && filterType === 'kiosk') ||
        (!mobileorderRewardsFound && filterType === 'mobileorder'))
    "
  >
    <ion-label class="ion-text-center ion-text-wrap">
      <ion-text *ngIf="!kioskRewardsFound && filterType === 'kiosk'">
        {{ "points.rewards.no-kiosk-rewards-found" | translate }}
      </ion-text>
      <ion-text
        *ngIf="!mobileorderRewardsFound && filterType === 'mobileorder'"
      >
        {{ "points.rewards.no-rewards-found" | translate }}</ion-text
      >
    </ion-label>
  </ion-item>
</span>
