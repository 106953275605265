<ion-header>
  <app-no-internet-connection></app-no-internet-connection>
  <app-ionic-update-error></app-ionic-update-error>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button
        [disabled]="finalPriceLoadingWithLatency"
        color="primary"
        (click)="modalCtrl.dismiss('back')"
      >
        <ion-icon name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title *ngIf="storeInfo" size="small" mode="md"
      >{{ storeInfo.store_name_mobileorder &&
      storeInfo.store_name_mobileorder[selectedLanguage] ?
      storeInfo.store_name_mobileorder[selectedLanguage] :
      storeInfo.store_name_langs && storeInfo.store_name_langs[selectedLanguage]
      ? storeInfo.store_name_langs[selectedLanguage] :
      storeInfo.store_name_langs && storeInfo ? storeInfo.store_name_langs.el :
      storeInfo.store_name }}</ion-title
    >
  </ion-toolbar>
</ion-header>
<ion-content class="item-list">
  <ion-grid *ngIf="!storeInfo || !selectedProduct">
    <ion-row style="width: 100%">
      <ion-col class="ion-text-center">
        <ion-spinner></ion-spinner>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid *ngIf="storeInfo && selectedProduct">
    <ion-row>
      <ion-col [size]="columnSize2" *ngIf="selectedProduct">
        <ion-item lines="none">
          <ion-text style="font-size: 24px; font-weight: bold"
            >{{"reservation-app.chooseArtist" |translate}}</ion-text
          >
        </ion-item>
        <ion-item lines="none" style="margin-top: 10px">
          <ion-text
            >{{"reservation-app.service" |translate}}
            <span style="font-weight: bold"> {{selectedProduct.name}}</span>
            <p></p>
            {{"reservation-app.duration" | translate}}
            {{selectedProduct.service_duration_min}} {{"minutes" | translate}}
            <p></p>

            <div *ngIf="!selectedProduct.gift">
              <span *ngIf="!finalPriceLoading && selectedProduct.productTotal">
                {{"reservation-app.cost" |translate}}
                {{selectedProduct.productTotal}}€
              </span>
              <ion-spinner
                *ngIf="finalPriceLoading || !selectedProduct.productTotal"
                name="dots"
              ></ion-spinner>
            </div>

            <div *ngIf="selectedProduct.gift">
              <span *ngIf="!finalPriceLoading ">
                {{"reservation-app.cost" |translate}} {{'free' | translate}}
              </span>
              <ion-spinner *ngIf="finalPriceLoading" name="dots"></ion-spinner>
            </div>
          </ion-text>
        </ion-item>
      </ion-col>

      <ion-col
        *ngIf="!tables_artists"
        [size]="columnSize"
        class="ion-text-center ion-padding"
      >
        <ion-spinner></ion-spinner
      ></ion-col>

      <ion-col *ngIf="tables_artists">
        <ion-spinner *ngIf="loading"> </ion-spinner>
        <ion-list *ngIf="!loading">
          <ion-item
            style="
              margin-left: 10px;
              margin-right: 10px;
              border-radius: 20px;
              margin-top: 10px;
            "
            lines="none"
            color="light"
            *ngFor="let artist of tables_artists; let i = index; last as isLast"
            (click)="selectArtist(i, artist)"
            [class.box]="i === selectedArtistIndex"
            [style.margin-bottom]="isLast ? '40px' : '0'"
            [disabled]="!artist.dinein_table || !artist.user"
            button="true"
          >
            <ion-avatar slot="start">
              <ion-img *ngIf="artist.img"
                [src]="'https://d2489tad05qh3v.cloudfront.net/'+ storeInfo.store_id + '/adminImages/' + artist.img"
              ></ion-img>
              <ion-img *ngIf="!artist.img"
                [src]="'https://ionicframework.com/docs/img/demos/avatar.svg'"
              ></ion-img>
            </ion-avatar>
            <ion-label class="ion-text-wrap" *ngIf="artist.name"
              >{{artist.name && artist.name[selectedLanguage] ?
              artist.name[selectedLanguage] : artist.name.el }}</ion-label
            >
            <ion-icon
              *ngIf="artist.reviewStar"
              size="small"
              color="primary"
              slot="end"
              name="star"
            ></ion-icon>
            <div *ngIf="artist.reviewStar" slot="end">
              {{artist.reviewStar}}
            </div>
          </ion-item>
          <ion-item lines="none" *ngIf="!tables_artists.length">
            <ion-label class="ion-text-wrap ion-text-center">
              <ion-grid style="max-width: 100px">
                <ng-lottie [options]="sadAnimationOptions"></ng-lottie>
              </ion-grid>
              <ion-text color="danger">
                {{'reservation-app.there-are-no-available-artists-at-the-moment'
                | translate}}
              </ion-text>
            </ion-label>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>

    <div class="button-wrapper">
      <div [ngClass]="{'wobble-animation': isWobble}">
        <ion-button
          *ngIf="showButton"
          expand="block"
          shape="round"
          (click)="selectDatePage()"
        >
          <ion-text> {{"reservation-app.continue" | translate}} </ion-text>
        </ion-button>
      </div>
    </div>
  </ion-grid>
</ion-content>
