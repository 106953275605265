import * as PendingOrdersActions from "./pendingOrders.actions";
import * as _ from "lodash";

export interface State {
  pendingOrders: any;
}

const initialState: State = {
  pendingOrders: [],
};

export function pendingOrdersReducer(
  state = initialState,
  action: PendingOrdersActions.PendingOrdersActions
) {
  switch (action.type) {
    case PendingOrdersActions.SET_PENDING_ORDERS:
      return {
        ...state,
        pendingOrders: action.payload,
      };

    case PendingOrdersActions.CLEAR_PENDING_ORDERS:
      return {
        ...state,
        pendingOrders: [],
      };

    case PendingOrdersActions.CHECK_PENDING_ORDERS_FROM_FETCH:
      const fetchedOrdersArray = _.cloneDeep(action.payload);
      let newPendingOrdersArray = _.cloneDeep(state.pendingOrders);
      if (
        state &&
        state.pendingOrders &&
        fetchedOrdersArray &&
        fetchedOrdersArray.length
      ) {
        if (newPendingOrdersArray.length && newPendingOrdersArray.length > 0) {
          _.each(newPendingOrdersArray, (pendingOrder) => {
            const foundOrder = _.findIndex(fetchedOrdersArray, {
              order_id: pendingOrder.order_id,
              store_id: pendingOrder.store_id,
            });

            if (foundOrder && !_.isEqual(foundOrder, pendingOrder)) {
              pendingOrder = _.cloneDeep(foundOrder);
            }
          });
        } else {
          newPendingOrdersArray = [];

          newPendingOrdersArray = _.filter(
            fetchedOrdersArray,
            (fetchedOrder) => {
              if (
                fetchedOrder.status &&
                (fetchedOrder.status === "pending" ||
                  fetchedOrder.status === "pendingPayment" ||
                  fetchedOrder.status === "processingPayment" ||
                  fetchedOrder.status === "processing")
              ) {
                return fetchedOrder;
              }
            }
          );
        }

        return {
          ...state,
          pendingOrders: newPendingOrdersArray,
        };
      } else {
        return state;
      }

    case PendingOrdersActions.UPDATE_PENDING_ORDER:
      if (state && state.pendingOrders) {
        const key = _.findIndex(state.pendingOrders, (order: any) => {
          return (
            order.order_id === action.payload.order_id &&
            order.store_id === action.payload.store_id
          );
        });
        if (key !== -1) {
          const updatedOrder = {
            ...state.pendingOrders[key],
            ...action.payload,
          };
          const updatedOrders = [...state.pendingOrders];
          updatedOrders[key] = updatedOrder;

          console.log("updatedOrders", updatedOrders);

          const updatedOrdersFiltered = _.filter(updatedOrders, (order) => {
            if (
              order.status === "pending" ||
              order.status === "pendingPayment" ||
              order.status === "processingPayment" ||
              order.status === "processing"
            ) {
              return order;
            }
          });
          console.log("updatedOrdersFiltered", updatedOrdersFiltered);
          return {
            ...state,
            pendingOrders: updatedOrdersFiltered,
          };
        } else {
          if (
            action &&
            action.payload &&
            (action.payload.status === "pending" ||
              action.payload.status === "pendingPayment" ||
              action.payload.status === "processingPayment" ||
              action.payload.status === "processing")
          )
            return {
              ...state,
              pendingOrders: _.orderBy(
                _.unionBy([...state.pendingOrders, action.payload], "order_id"),
                ["timestamp"],
                ["desc"]
              ),
            };
        }
      } else {
        return state;
      }

    default:
      return state;
  }
}
