import { Action } from "@ngrx/store";

export const SET_ONE_SIGNAL_MESSAGE =
  "[ONE SIGNAL MESSAGE]  Set One Signal Message";
export const CLEAR_ONE_SIGNAL_MESSAGES =
  "[ONE SIGNAL MESSAGE]  Clear One Signal Message";

export class SetOneSignalMessage implements Action {
  readonly type = SET_ONE_SIGNAL_MESSAGE;
  constructor(public payload: any) {}
}

export class ClearOneSignalMessage implements Action {
  readonly type = CLEAR_ONE_SIGNAL_MESSAGES;
  constructor(public payload: any) {}
}

export type OneSignalMessagesActions =
  | SetOneSignalMessage
  | ClearOneSignalMessage;
