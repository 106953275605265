import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ModalController, IonModal, Platform } from "@ionic/angular";
import { ExtendModalComponent } from "../extend-modal/extend-modal.component";
import { Subscription, distinctUntilChanged } from "rxjs";
import * as fromApp from "../../store/app.reducer";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
@Component({
  selector: "app-reservation-completed-date-info",
  templateUrl: "./reservation-completed-date-info.page.html",
  styleUrls: ["./reservation-completed-date-info.page.scss"],
})
export class ReservationCompletedDateInfoPage implements OnInit, OnDestroy {
  @Input() item: any;
  selectedStars: number = 0;
  @ViewChild(IonModal) modal: IonModal;
  private unsubscribeBackEvent: Subscription;
  public storeAccount;
  private allStores;
  private storesSub: Subscription;
  private groupSub: Subscription;
  public selectedLanguage;
  public groupApp;

  selectStar(star: number) {
    this.selectedStars = star;
  }

  openLink = async () => {
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${this.storeAccount.mapCenterStoreLat},${this.storeAccount.mapCenterStoreLng}`;

    //open link external on new page
    if (Capacitor.isNativePlatform()) {
      window.open(googleMapsUrl, "_system");
    } else {
      window.open(googleMapsUrl, "_blank");
    }
  };

  submitReview() {
    if (this.selectedStars === 0) {
      alert("Please select a star rating before submitting.");
    } else {
      // Implement your review submission logic here, e.g., send it to an API.
      console.log(`You rated ${this.selectedStars} stars.`);
    }
  }
  constructor(
    public modalCtrl: ModalController,
    private platform: Platform,
    private store: Store<fromApp.AppState>
  ) {}

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.modalCtrl.dismiss();
        }
      }
    );

    this.storesSub = this.store.select("stores").subscribe((state) => {
      if (state && state.stores && !_.isEqual(state.stores, this.allStores)) {
        this.allStores = _.cloneDeep(state.stores);

        const found = _.find(
          this.allStores,
          (store) => store.store_id === this.item.store_id
        );
        if (found) {
          this.storeAccount = _.cloneDeep(found);
        }
      }
    });

    this.groupSub = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.selectedLangugage &&
          !_.isEqual(state.selectedLangugage, this.selectedLanguage)
        ) {
          this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
        }
        if (
          state &&
          state.groupApp &&
          !_.isEqual(state.groupApp, this.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp);
        }
      });
  }

  async openModal() {
    const modal = await this.modalCtrl.create({
      component: ExtendModalComponent,
      id: "extendModal",
      animated: true,
      backdropDismiss: false,
      cssClass: "extendModal",
      componentProps: {
        review: true,
      },
    });
    await modal.present();
  }

  async cancel() {
    await this.openModal();
    this.modal.dismiss(null, "cancel");
  }

  ngOnDestroy() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.storesSub) {
      this.storesSub.unsubscribe();
    }
    if (this.groupSub) {
      this.groupSub.unsubscribe();
    }
  }
}
