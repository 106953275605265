<ion-header>
  <ion-toolbar mode="md">
    <ion-title
      size="small"
      *ngIf="infoMessage && infoMessage.title && selectedLangugage && mo_base"
    >
      <div
        [innerHTML]="
          infoMessage.title[selectedLangugage]
            ? infoMessage.title[selectedLangugage]
            : infoMessage.title[mo_base]
            ? infoMessage.title[mo_base]
            : infoMessage.title['en']
        "
      ></div>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button color="dark" size="small" (click)="closeModal()" fill="clear">
        <ion-icon
          slot="icon-only"
          src="assets/ionicons/close-outline.svg"
        ></ion-icon
      ></ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid *ngIf="groupApp && infoMessage">
    <ion-grid class="ion-text-center ion-no-padding" *ngIf="infoMessage.image">
      <img
        [class]="browserSize ? 'browser-size-img' : 'mobile-size-img'"
        src="https://s3-eu-west-1.amazonaws.com/storegroupseditedbgimages/{{
          groupApp.group
        }}/info_messages/{{ infoMessage.image }}"
      />
    </ion-grid>
    <ion-item
      lines="none"
      *ngIf="
        infoMessage && infoMessage.description && selectedLangugage && mo_base
      "
    >
      <ion-label class="ion-text-wrap">
        <p
          [innerHTML]="
            infoMessage.description[selectedLangugage]
              ? infoMessage.description[selectedLangugage]
              : infoMessage.description[mo_base]
              ? infoMessage.description[mo_base]
              : infoMessage.description['en']
          "
        ></p>
      </ion-label>
    </ion-item>

    <ion-grid
      *ngIf="infoMessage.download_app_btn"
      class="ion-no-padding"
      style="margin-top: -18px; margin-bottom: -18px"
    >
      <ion-row style="width: 100%">
        <ion-col
          class="ion-no-padding"
          [size]="!isBrowserFromAndroid && !isBrowserFromAndroid ? '6' : '12'"
        >
          <ion-grid
            *ngIf="
              isBrowserFromAndroid ||
              (!isBrowserFromAndroid && !isBrowserFromIos)
            "
            [class]="
              !isBrowserFromAndroid && !isBrowserFromIos
                ? 'width-200'
                : 'width-200'
            "
          >
            <ng-lottie [options]="animationOptionsAndroid"></ng-lottie>
          </ion-grid>
        </ion-col>
        <ion-col
          class="ion-no-padding"
          [size]="!isBrowserFromAndroid && !isBrowserFromIos ? '6' : '12'"
        >
          <ion-grid
            *ngIf="
              isBrowserFromIos || (!isBrowserFromAndroid && !isBrowserFromIos)
            "
            [class]="
              !isBrowserFromAndroid && !isBrowserFromIos
                ? 'width-200'
                : 'width-200'
            "
          >
            <ng-lottie [options]="animationOptionsIos"></ng-lottie>
          </ion-grid>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-grid *ngIf="!infoMessage.download_app_btn">
    <ion-row style="width: 100%" class="ion-no-padding">
      <ion-col class="ion-text-center ion-no-padding">
        <ion-button (click)="openLink(infoMessage.link)">
          <ion-text
            [innerHTML]="
              infoMessage.link_title[selectedLangugage]
                ? infoMessage.link_title[selectedLangugage]
                : infoMessage.link_title[mo_base]
                ? infoMessage.link_title[mo_base]
                : infoMessage.link_title['en']
            "
          ></ion-text>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid *ngIf="infoMessage.download_app_btn">
    <ion-row
      *ngIf="isBrowserFromAndroid || isBrowserFromIos"
      style="width: 100%"
      class="ion-no-padding"
    >
      <ion-col class="ion-text-center ion-no-padding">
        <ion-button (click)="downloadApp()">
          <ion-text>{{ "Download App" | translate }}</ion-text>
        </ion-button>
      </ion-col>
    </ion-row>

    <ion-row
      *ngIf="!isBrowserFromAndroid && !isBrowserFromIos && groupApp"
      style="width: 100%"
      class="ion-no-padding"
    >
      <ion-col size="12" class="ion-text-center ion-no-padding"
        ><ion-text style="font-size: small; font-weight: bold"
          >Download App</ion-text
        ></ion-col
      >
      <ion-col size="12" class="ion-text-center ion-no-padding">
        <img
          width="80"
          height="80"
          [src]="
            'https://data.deliverymanager.gr/' + groupApp.group + '/qr/qr.png'
          "
        />
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
