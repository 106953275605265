<div
  *ngIf="
    categories &&
    storeInfo &&
    !(
      storeInfo.design_settings &&
      storeInfo.design_settings.mobileorderHorizontalCategoriesSquareDesign
    )
  "
>
  <ion-slides
    #categoriesSlider
    [class]="
      icons_categories_design
        ? 'slidesHeightWithIcons ion-text-center'
        : 'slidesHeightNoIcons ion-text-center'
    "
    *ngIf="categories"
    scrollbar
    [options]="sliderOptions"
  >
    <ion-slide
      style="width: auto"
      *ngFor="let category of categories; trackBy: trackFn"
    >
      <ion-item
        class="icons-item"
        button="true"
        (click)="categorySelection(category)"
        *ngIf="
          icons_categories_design && !icons_categories_design_only_vertical
        "
        lines="none"
        detail="false"
      >
        <ion-label
          style="max-width: 140px; height: 100%; align-items: top"
          [ngClass]="{ buttonBold: true }"
          class="ion-text-center ion-no-margin"
        >
          <ion-icon
            [color]="
              category.category_id === currentScrollCategoryId ||
              category.category_id === selectedCategoryId
                ? 'selected'
                : 'horizontalCategories'
            "
            style="font-size: 35px; margin: 0"
            [src]="categoryIconLink + category.mobileorder_category_icon"
          ></ion-icon>
          <div>
            <ion-text
              style="font-size: small"
              [color]="
                category.category_id === currentScrollCategoryId ||
                category.category_id === selectedCategoryId
                  ? 'selected'
                  : 'horizontalCategories'
              "
            >
              {{ category.name }}</ion-text
            >
          </div>
        </ion-label>
      </ion-item>

      <ion-chip
        *ngIf="
          !icons_categories_design || icons_categories_design_only_vertical
        "
        (click)="categorySelection(category)"
        [color]="
          category.category_id === currentScrollCategoryId ||
          category.category_id === selectedCategoryId
            ? 'selected'
            : 'horizontalCategories'
        "
        ><ion-label [ngClass]="{ buttonBold: true }">{{
          category.name
        }}</ion-label></ion-chip
      >
    </ion-slide>
  </ion-slides>
</div>

<div
  *ngIf="
    categories &&
    storeInfo &&
    storeInfo.design_settings &&
    storeInfo.design_settings.mobileorderHorizontalCategoriesSquareDesign
  "
>
  <ion-slides
    #categoriesSlider
    *ngIf="categories"
    scrollbar
    style="height: 60px"
    [class]="
      icons_categories_design
        ? 'slidesHeightWithIcons ion-text-center'
        : 'slidesHeightNoIcons ion-text-center'
    "
    [options]="noIconssliderOptions"
  >
    <ion-slide
      class="no-icons-item-slide"
      *ngFor="let category of categories; trackBy: trackFn"
    >
      <ion-item
        *ngIf="
          windowDimensions &&
          (windowDimensions.width < 600 ||
            !(
              icons_categories_design &&
              !icons_categories_design_only_vertical &&
              category.mobileorder_category_icon
            ))
        "
        class="no-icons-item"
        button="true"
        (click)="categorySelection(category)"
        lines="none"
        detail="false"
        [color]="
          category.category_id === currentScrollCategoryId ||
          category.category_id === selectedCategoryId
            ? 'selected'
            : 'horizontalCategories'
        "
      >
        <ion-label
          [ngClass]="{ buttonBold: true }"
          [class]="'ion-text-center ion-no-margin'"
          style="margin-right: 7px; margin-left: 7px"
        >
          <ion-icon
            *ngIf="
              icons_categories_design &&
              !icons_categories_design_only_vertical &&
              category.mobileorder_category_icon
            "
            style="font-size: 20px; margin: 0; vertical-align: middle"
            [src]="categoryIconLink + category.mobileorder_category_icon"
          ></ion-icon>
          <ion-text style="font-size: small"> {{ category.name }}</ion-text>
        </ion-label>
      </ion-item>

      <ion-item
        class="icons-item"
        button="true"
        (click)="categorySelection(category)"
        *ngIf="
          windowDimensions &&
          windowDimensions.width >= 600 &&
          icons_categories_design &&
          !icons_categories_design_only_vertical &&
          category.mobileorder_category_icon
        "
        lines="none"
        detail="false"
      >
        <ion-label
          style="max-width: 140px; height: 100%; align-items: top"
          [ngClass]="{ buttonBold: true }"
          class="ion-text-center ion-no-margin"
        >
          <ion-icon
            [color]="
              category.category_id === currentScrollCategoryId ||
              category.category_id === selectedCategoryId
                ? 'selected'
                : 'verticalCategories'
            "
            style="font-size: 35px; margin: 0"
            [src]="categoryIconLink + category.mobileorder_category_icon"
          ></ion-icon>
          <div>
            <ion-text
              style="font-size: small"
              [color]="
                category.category_id === currentScrollCategoryId ||
                category.category_id === selectedCategoryId
                  ? 'selected'
                  : 'verticalCategories'
              "
            >
              {{ category.name }}</ion-text
            >
          </div>
        </ion-label>
      </ion-item>
    </ion-slide>
  </ion-slides>
</div>
