<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">
      <ion-text style="font-size: medium">
        {{ "reservations.reservation-info" | translate }}</ion-text
      >
    </ion-title>
    <ion-button
      (click)="closeModal()"
      size="small"
      slot="end"
      fill="clear"
      color="dark"
    >
      <ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content scrollEvents="false" scrollX="false" scrollY="false">
  <ion-grid>
    <ion-item>
      <ion-label class="ion-text-wrap">
        <!-- <div *ngIf="reservation.table_category_name">
          {{ "reservations.area" | translate }}: {{ reservation.table_category_name[lang] }}
        </div> -->
        <div *ngIf="!without_table">
          <strong><b>{{ reservation.table_name[lang] }}</b></strong>
        </div>
        <div *ngIf="without_table">
          <strong><b>{{ "reservations.without_table" | translate }}</b></strong>
        </div>
        <div>
          <strong>
            {{ "reservations.reservation-day" | translate }}:
          </strong>
          {{ reservation.dateString }}
        </div>
        <div>
          <strong>
            {{ "reservations.reservation-time" | translate }}:
          </strong>
          {{ reservation.availablePeriod.start | date:'HH:mm'}} - {{ reservation.availablePeriod.end | date:'HH:mm' }}
        </div>
        <div *ngIf="!without_table && reservation.prepay_amount">
          <strong>{{ 'reservations.prepay' | translate }}: </strong>
          {{ reservation.prepay_amount }}€
        </div>
        <div *ngIf="without_table && prepay_per_person">
          <strong>{{ 'reservations.prepay' | translate }}: </strong>
          <ion-text *ngIf="seats && seats !== '-'">{{ prepay_per_person * seats }}€</ion-text>
          <ion-text *ngIf="!seats || seats === '-'">-</ion-text>
          <p>* {{ prepay_per_person }}€ {{ "reservations.per_person" | translate }}</p>
        </div>
      </ion-label>
    </ion-item>

    <ion-item *ngIf="!disable_seats">
      <ion-label>{{ "reservations.seats" | translate }} <ion-text *ngIf="reservation.max_seats">({{ reservation.max_seats === reservation.seats ? reservation.seats : reservation.seats + " - " + reservation.max_seats }})</ion-text>:</ion-label>
      <ion-input style="text-align: center" inputmode="numeric" class="seatsInput" type="number" [(ngModel)]="seats" [min]="reservation.max_seats ? reservation.seats : 1" required [placeholder]="'write-here' | translate" [max]="reservation.max_seats || 99"></ion-input>
    </ion-item>
    <ion-item *ngIf="disable_seats">
      <ion-label>{{ "reservations.seats" | translate }}:</ion-label>
      <ion-input readonly style="text-align: center" inputmode="numeric" class="seatsInput" type="number" [(ngModel)]="seats" required [placeholder]="'write-here' | translate"></ion-input>
    </ion-item>
    <ion-list *ngIf="formQuestions && formQuestions.length">
      <div style="margin: 6px 16px" *ngFor="let item of formQuestions">
        <div style="margin-bottom: 16px">{{ item.label[lang] }}</div>
        <ion-textarea [readonly]="!!disable_seats" class="ion-no-padding" [(ngModel)]="formAnswers[item.id]" *ngIf="item.type === 'text'" [placeholder]="'write-here' | translate"></ion-textarea>
      </div>
    </ion-list>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-grid>
    <ion-row style="width: 100%">
      <ion-col class="ion-text-center">
        <ion-button [disabled]="seats === '-' || !seats || (reservation.max_seats && seats > reservation.max_seats) || (!reservation.max_seats && seats > 99) || (reservation.seats && seats < reservation.seats) || seats < 1" (click)="continueToPayment()">
          {{ "popups.send-order-popup.continue-to-payment" | translate }}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
