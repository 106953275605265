<ion-grid
  style="max-width: 400px"
  *ngIf="activeCoupons && activeCoupons.length"
>
  <ion-row style="width: 100%">
    <ion-col size="12" class="ion-text-center">
      <ion-item
        button="true"
        detail="false"
        color="primary"
        style="border-radius: 8px"
        (click)="showSelectCouponModal()"
      >
        <ion-label class="ion-text-wrap ion-text-center">
          <ion-text>
            {{ "reservation-app.your-active-coupons" | translate }}:
            <strong>
              {{ activeCoupons.length }}
            </strong>
          </ion-text>
        </ion-label>
      </ion-item>
    </ion-col>
  </ion-row>
</ion-grid>
