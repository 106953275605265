<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="cancel()">{{'cancel' | translate}}</ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button type="submit"
                  form="myForm"
                  [disabled]="customerDataSaveLoading"
                  [strong]="true">
        <ion-text *ngIf="!customerDataSaveLoading">

          {{'save' | translate}}
        </ion-text>
        <ion-spinner *ngIf="customerDataSaveLoading">

        </ion-spinner>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <ion-row>
    <ion-col class="ion-text-center">
      <ion-note>
        Συμπληρώστε τα στοιχεία σας για να συμμετέχετε στις κληρώσεις μας
      </ion-note>
    </ion-col>
  </ion-row>
  <form #accountInfoForm="ngForm"
        id="myForm"
        (ngSubmit)="confirm(accountInfoForm)">
    <ion-item>
      <ion-label position="floating">
        Όνομα
      </ion-label>
      <ion-input type="text"
                 placeholder="Όνομα"
                 pattern=".*\S+.*"
                 required
                 name="name"
                 [(ngModel)]="currentUser.firstName"></ion-input>
      <ion-note slot="error">Το όνομα δεν μπορεί να είναι κενό</ion-note>
    </ion-item>
    <ion-item>
      <ion-label position="floating">
        Επώνυμο
      </ion-label>
      <ion-input type="text"
                 name="surname"
                 required
                 pattern=".*\S+.*"
                 placeholder="Επώνυμο"
                 [(ngModel)]="currentUser.lastName"></ion-input>
      <ion-note slot="error">Το επώνυμο δεν μπορεί να είναι κενό</ion-note>
    </ion-item>
    <ion-item lines="full">
      <ion-label class="ion-text-wrap">Λήψη ειδοποιήσεων πόντων</ion-label>
      <ion-checkbox slot="start"
                    name="pointsPush"
                    style="margin: 0 10px 0 0;"
                    [(ngModel)]="currentUser.alertExpirationPoints.push"></ion-checkbox>
    </ion-item>
    <ion-item lines="full"
              class="ion-invalid">
      <ion-label class="ion-text-wrap">Aποδοχή όρων κλήρωσης</ion-label>
      <ion-checkbox slot="start"
                    name="acceptTerms"
                    style="margin: 0 10px 0 0;"
                    required
                    [(ngModel)]="currentDrawAcceptedTerms"></ion-checkbox>
      <ion-button slot="end"
                  size="normal"
                  style="margin: 0;"
                  (click)="openLink('https://draws.ILPOSTOPLUS.GR')"
                  fill="clear">
        <ion-icon size="normal"
                  name="information-circle"></ion-icon>

      </ion-button>
      <ion-note slot="helper"
                color="danger"
                *ngIf="!currentDrawAcceptedTerms && accountInfoForm.controls['acceptTerms']?.touched">
        Πρέπει να αποδεχθείτε τους όρους </ion-note>
    </ion-item>
  </form>
</ion-content>