import { Action } from "@ngrx/store";

export const SET_SELECTED_STEP_OPTION_ID =
  "[OPTION STEPPER] Set Selected Step Option Id";
export const SET_OPTIONS_STEPS = "[OPTION STEPPER] Set All Valid Options Steps";
export const CLEAR_STATE = "[OPTION STEPPER] Clear State";
export const CLEAR_STATE_FOR_PRODUCT =
  "[OPTION STEPPER] Clear State For Product";

export class SetSelectedStepOptionId implements Action {
  readonly type = SET_SELECTED_STEP_OPTION_ID;
  constructor(
    public payload: { selectedStepOptionId: any; selectedProductId: any }
  ) {}
}

export class SetOptionsSteps implements Action {
  readonly type = SET_OPTIONS_STEPS;
  constructor(public payload: { optionsSteps: any; selectedProductId: any }) {}
}

export class ClearStateForProduct implements Action {
  readonly type = CLEAR_STATE_FOR_PRODUCT;
  constructor(public payload: any) {}
}

export class ClearState implements Action {
  readonly type = CLEAR_STATE;
  constructor() {}
}

export type OptionStepperActions =
  | SetSelectedStepOptionId
  | SetOptionsSteps
  | ClearState
  | ClearStateForProduct;
