import * as OneSignalMessagesActions from "./oneSignalMessages.actions";
import * as _ from "lodash";

export interface State {
  oneSignalMessage: any;
}

const initialState: State = {
  oneSignalMessage: null,
};

export function oneSignalMessagesReduce(
  state = initialState,
  action: OneSignalMessagesActions.OneSignalMessagesActions
) {
  switch (action.type) {
    //only for hyper categories
    case OneSignalMessagesActions.SET_ONE_SIGNAL_MESSAGE:
      return {
        ...state,
        oneSignalMessage: action.payload,
      };

    case OneSignalMessagesActions.CLEAR_ONE_SIGNAL_MESSAGES:
      return {
        ...state,
        oneSignalMessage: null,
      };
  }
}
