import * as RequestsErrorHandlingActions from "./requestsErrorHandling.actions";
import * as _ from "lodash";

export interface State {
  requestsErrorsArray: any; //array of error objects
}

const initialState: State = {
  requestsErrorsArray: [],
};

export function requestsErrorHandlingReducer(
  state = initialState,
  action: RequestsErrorHandlingActions.RequestsErrorHandlingActions
) {
  switch (action.type) {
    case RequestsErrorHandlingActions.SET_REQUESTS_ERROR_HANDLING:
      return {
        ...state,
        requestsErrorsArray: action.payload,
      };

    case RequestsErrorHandlingActions.ADD_UPDATE_REQUESTS_ERROR_HANDLING:
      const key = _.findIndex(state.requestsErrorsArray, (errorObject: any) => {
        return errorObject.id === action.payload.id;
      });
      if (key !== -1) {
        const updatedErrorObject = {
          ...state.requestsErrorsArray[key],
          ...action.payload,
        };
        const updatedRequestsErrorsArray = [...state.requestsErrorsArray];
        updatedRequestsErrorsArray[key] = updatedErrorObject;
        return {
          ...state,
          requestsErrorsArray: updatedRequestsErrorsArray,
        };
      } else {
        if (!state.requestsErrorsArray) {
          return {
            ...state,
            requestsErrorsArray: [action.payload],
          };
        } else {
          return {
            ...state,
            requestsErrorsArray: [...state.requestsErrorsArray, action.payload],
          };
        }
      }

    case RequestsErrorHandlingActions.CLEAR_REQUESTS_ERROR_HANDLING:
      return {
        ...state,
        requestsErrorsArray: [],
      };

    default:
      return state;
  }
}
