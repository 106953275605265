<ion-item
  [ngClass]="{
    'line-through': !!(!option.availableTotal || !choice.availableTotal)
  }"
  *ngIf="choice && option"
  lines="none"
>
  <ion-label
    [class]="
      !option.availableTotal ||
      !choice.availableTotal ||
      (option.dependenciesVariable &&
        option.dependenciesVariable.length > 0 &&
        !option.dependentHidden &&
        !option.showDependent)
        ? 'ion-text-wrap opacityDisabled'
        : 'ion-text-wrap'
    "
    style="line-height: 1.2"
  >
    <div>
      {{ choice.title }}
      <ion-icon
        [color]="
          groupStoreData &&
          groupStoreData.store_info &&
          groupStoreData.store_info.design_settings &&
          groupStoreData.store_info.design_settings.tagsColors &&
          groupStoreData.store_info.design_settings.tagsColors.frozen
            ? groupStoreData.store_info.design_settings.tagsColors.frozen
            : 'primary'
        "
        class="badge-margin"
        style="font-size: 10px"
        src="assets/flatIcons/frozen.svg"
        *ngIf="choice.tags && choice.tags.frozen"
      >
        <ion-text>{{ "item-tags.frozen" | translate }}</ion-text>
      </ion-icon>
    </div>

    <div>
      <app-product-choice-tags
        [optionItem]="option"
        [choiceItem]="choice"
        [selectedProductPromo]="selectedProductPromo"
        [groupStoreData]="groupStoreData"
      >
      </app-product-choice-tags>
    </div>
  </ion-label>

  <ion-checkbox
    [disabled]="
      !option.availableTotal ||
      !choice.availableTotal ||
      (option.dependenciesVariable &&
        option.dependenciesVariable.length > 0 &&
        !option.dependentHidden &&
        !option.showDependent)
    "
    #checkbox
    slot="start"
    [(ngModel)]="choice.is_preselected"
    (ionChange)="onCheckboxChanged($event)"
  >
  </ion-checkbox>

  <div
    *ngIf="choice && choice.is_preselected && thereIsAnImageInOption"
    style="align-items: center; text-align: center; z-index: 10000000"
  >
    <div [class]="choice && choice.is_preselected ? 'price-style' : ''">
      <ion-text
        class="item-text-wrap"
        *ngIf="!choice.isFree || choice.quantity > choice.freeQuantity"
      >
        <span
          [ngClass]="{
            disabled: !!(
              !option.availableTotal ||
              !choice.availableTotal ||
              (option.dependenciesVariable &&
                option.dependenciesVariable.length > 0 &&
                !option.dependentHidden &&
                !option.showDependent)
            ),
            'line-through ': !!(
              !option.availableTotal || !choice.availableTotal
            )
          }"
          *ngIf="
            ((orderPickup == false && dineIn == false) ||
              (orderPickup == null && dineIn == null) ||
              (orderPickup == true &&
                dineIn == false &&
                !choice.price_takeaway) ||
              (orderPickup == false &&
                dineIn == true &&
                (!alt_dinein_active ||
                  !selectedDineInTable ||
                  !selectedDineInTable.alt_dinein) &&
                !choice.price_dinein) ||
              (orderPickup == false &&
                dineIn == true &&
                alt_dinein_active &&
                selectedDineInTable &&
                selectedDineInTable.alt_dinein &&
                !choice.price_alt_dinein)) &&
            choice.price.toString().trim().replace(',', '.') > 0 &&
            choice.freeQuantity
          "
          >{{ !option.changesBasePrice ? "+" : ""
          }}{{
            choice.price.toString().trim().replace(",", ".") *
              (choice.quantity - choice.freeQuantity) | number : "1.2-2"
          }}€</span
        >
        <span
          [ngClass]="{
            disabled: !!(
              !option.availableTotal ||
              !choice.availableTotal ||
              (option.dependenciesVariable &&
                option.dependenciesVariable.length > 0 &&
                !option.dependentHidden &&
                !option.showDependent)
            ),
            'line-through ': !!(
              !option.availableTotal || !choice.availableTotal
            )
          }"
          *ngIf="
            ((orderPickup == false && dineIn == false) ||
              (orderPickup == null && dineIn == null) ||
              (orderPickup == true &&
                dineIn == false &&
                !choice.price_takeaway) ||
              (orderPickup == false &&
                dineIn == true &&
                (!alt_dinein_active ||
                  !selectedDineInTable ||
                  !selectedDineInTable.alt_dinein) &&
                !choice.price_dinein) ||
              (orderPickup == false &&
                dineIn == true &&
                alt_dinein_active &&
                selectedDineInTable &&
                selectedDineInTable.alt_dinein &&
                !choice.price_alt_dinein)) &&
            choice.price.toString().trim().replace(',', '.') > 0 &&
            !choice.freeQuantity
          "
          >{{ !option.changesBasePrice ? "+" : ""
          }}{{
            choice.price.toString().trim().replace(",", ".") * choice.quantity
              | number : "1.2-2"
          }}€</span
        >
        <span
          [ngClass]="{
            disabled: !!(
              !option.availableTotal ||
              !choice.availableTotal ||
              (option.dependenciesVariable &&
                option.dependenciesVariable.length > 0 &&
                !option.dependentHidden &&
                !option.showDependent)
            ),
            'line-through ': !!(
              !option.availableTotal || !choice.availableTotal
            )
          }"
          *ngIf="
            ((orderPickup == false && dineIn == false) ||
              (orderPickup == null && dineIn == null) ||
              (orderPickup == true &&
                dineIn == false &&
                !choice.price_takeaway) ||
              (orderPickup == false &&
                dineIn == true &&
                (!alt_dinein_active ||
                  !selectedDineInTable ||
                  !selectedDineInTable.alt_dinein) &&
                !choice.price_dinein) ||
              (orderPickup == false &&
                dineIn == true &&
                alt_dinein_active &&
                selectedDineInTable &&
                selectedDineInTable.alt_dinein &&
                !choice.price_alt_dinein)) &&
            choice.price.toString().trim().replace(',', '.') < 0
          "
          >{{
            choice.price.toString().trim().replace(",", ".") | number : "1.2-2"
          }}€</span
        >

        <span
          [ngClass]="{
            disabled: !!(
              !option.availableTotal ||
              !choice.availableTotal ||
              (option.dependenciesVariable &&
                option.dependenciesVariable.length > 0 &&
                !option.dependentHidden &&
                !option.showDependent)
            ),
            'line-through ': !!(
              !option.availableTotal || !choice.availableTotal
            )
          }"
          *ngIf="
            orderPickup == true &&
            dineIn == false &&
            choice.price_takeaway &&
            choice.price_takeaway.toString().trim().replace(',', '.') > 0 &&
            choice.freeQuantity
          "
          >{{ !option.changesBasePrice ? "+" : ""
          }}{{
            choice.price_takeaway.toString().trim().replace(",", ".") *
              (choice.quantity - choice.freeQuantity) | number : "1.2-2"
          }}€</span
        >
        <span
          [ngClass]="{
            disabled: !!(
              !option.availableTotal ||
              !choice.availableTotal ||
              (option.dependenciesVariable &&
                option.dependenciesVariable.length > 0 &&
                !option.dependentHidden &&
                !option.showDependent)
            ),
            'line-through ': !!(
              !option.availableTotal || !choice.availableTotal
            )
          }"
          *ngIf="
            orderPickup == true &&
            dineIn == false &&
            choice.price_takeaway &&
            choice.price_takeaway.toString().trim().replace(',', '.') > 0 &&
            !choice.freeQuantity
          "
          >{{ !option.changesBasePrice ? "+" : ""
          }}{{
            choice.price_takeaway.toString().trim().replace(",", ".") *
              choice.quantity | number : "1.2-2"
          }}€</span
        >
        <span
          [ngClass]="{
            disabled: !!(
              !option.availableTotal ||
              !choice.availableTotal ||
              (option.dependenciesVariable &&
                option.dependenciesVariable.length > 0 &&
                !option.dependentHidden &&
                !option.showDependent)
            ),
            'line-through ': !!(
              !option.availableTotal || !choice.availableTotal
            )
          }"
          *ngIf="
            orderPickup == true &&
            dineIn == false &&
            choice.price_takeaway &&
            choice.price_takeaway.toString().trim().replace(',', '.') < 0
          "
          >{{
            choice.price_takeaway.toString().trim().replace(",", ".")
              | number : "1.2-2"
          }}€</span
        >

        <span
          [ngClass]="{
            disabled: !!(
              !option.availableTotal ||
              !choice.availableTotal ||
              (option.dependenciesVariable &&
                option.dependenciesVariable.length > 0 &&
                !option.dependentHidden &&
                !option.showDependent)
            ),
            'line-through ': !!(
              !option.availableTotal || !choice.availableTotal
            )
          }"
          *ngIf="
            orderPickup == false &&
            dineIn == true &&
            (!alt_dinein_active ||
              !selectedDineInTable ||
              !selectedDineInTable.alt_dinein ||
              !choice.price_alt_dinein) &&
            choice.price_dinein &&
            choice.price_dinein.toString().trim().replace(',', '.') > 0 &&
            choice.freeQuantity
          "
          >{{ !option.changesBasePrice ? "+" : ""
          }}{{
            choice.price_dinein.toString().trim().replace(",", ".") *
              (choice.quantity - choice.freeQuantity) | number : "1.2-2"
          }}€</span
        >
        <span
          [ngClass]="{
            disabled: !!(
              !option.availableTotal ||
              !choice.availableTotal ||
              (option.dependenciesVariable &&
                option.dependenciesVariable.length > 0 &&
                !option.dependentHidden &&
                !option.showDependent)
            ),
            'line-through ': !!(
              !option.availableTotal || !choice.availableTotal
            )
          }"
          *ngIf="
            orderPickup == false &&
            dineIn == true &&
            (!alt_dinein_active ||
              !selectedDineInTable ||
              !selectedDineInTable.alt_dinein ||
              !choice.price_alt_dinein) &&
            choice.price_dinein &&
            choice.price_dinein.toString().trim().replace(',', '.') > 0 &&
            !choice.freeQuantity
          "
          >{{ !option.changesBasePrice ? "+" : ""
          }}{{
            choice.price_dinein.toString().trim().replace(",", ".") *
              choice.quantity | number : "1.2-2"
          }}€</span
        >
        <span
          [ngClass]="{
            disabled: !!(
              !option.availableTotal ||
              !choice.availableTotal ||
              (option.dependenciesVariable &&
                option.dependenciesVariable.length > 0 &&
                !option.dependentHidden &&
                !option.showDependent)
            ),
            'line-through ': !!(
              !option.availableTotal || !choice.availableTotal
            )
          }"
          *ngIf="
            orderPickup == false &&
            dineIn == true &&
            (!alt_dinein_active ||
              !selectedDineInTable ||
              !selectedDineInTable.alt_dinein ||
              !choice.price_alt_dinein) &&
            choice.price_dinein &&
            choice.price_dinein.toString().trim().replace(',', '.') < 0
          "
          >{{
            choice.price_dinein.toString().trim().replace(",", ".")
              | number : "1.2-2"
          }}€</span
        >

        <span
          [ngClass]="{
            disabled: !!(
              !option.availableTotal ||
              !choice.availableTotal ||
              (option.dependenciesVariable &&
                option.dependenciesVariable.length > 0 &&
                !option.dependentHidden &&
                !option.showDependent)
            ),
            'line-through ': !!(
              !option.availableTotal || !choice.availableTotal
            )
          }"
          *ngIf="
            orderPickup == false &&
            dineIn == true &&
            alt_dinein_active &&
            selectedDineInTable &&
            selectedDineInTable.alt_dinein &&
            choice.price_alt_dinein &&
            choice.price_alt_dinein.toString().trim().replace(',', '.') > 0 &&
            choice.freeQuantity
          "
          >{{ !option.changesBasePrice ? "+" : ""
          }}{{
            choice.price_alt_dinein.toString().trim().replace(",", ".") *
              (choice.quantity - choice.freeQuantity) | number : "1.2-2"
          }}€</span
        >
        <span
          [ngClass]="{
            disabled: !!(
              !option.availableTotal ||
              !choice.availableTotal ||
              (option.dependenciesVariable &&
                option.dependenciesVariable.length > 0 &&
                !option.dependentHidden &&
                !option.showDependent)
            ),
            'line-through ': !!(
              !option.availableTotal || !choice.availableTotal
            )
          }"
          *ngIf="
            orderPickup == false &&
            dineIn == true &&
            alt_dinein_active &&
            selectedDineInTable &&
            selectedDineInTable.alt_dinein &&
            choice.price_alt_dinein &&
            choice.price_alt_dinein.toString().trim().replace(',', '.') > 0 &&
            !choice.freeQuantity
          "
          >{{ !option.changesBasePrice ? "+" : ""
          }}{{
            choice.price_alt_dinein.toString().trim().replace(",", ".") *
              choice.quantity | number : "1.2-2"
          }}€</span
        >
        <span
          [ngClass]="{
            disabled: !!(
              !option.availableTotal ||
              !choice.availableTotal ||
              (option.dependenciesVariable &&
                option.dependenciesVariable.length > 0 &&
                !option.dependentHidden &&
                !option.showDependent)
            ),
            'line-through ': !!(
              !option.availableTotal || !choice.availableTotal
            )
          }"
          *ngIf="
            orderPickup == false &&
            dineIn == true &&
            alt_dinein_active &&
            selectedDineInTable &&
            selectedDineInTable.alt_dinein &&
            choice.price_alt_dinein &&
            choice.price_alt_dinein.toString().trim().replace(',', '.') < 0
          "
          >{{
            choice.price_alt_dinein.toString().trim().replace(",", ".")
              | number : "1.2-2"
          }}€</span
        >
      </ion-text>

      <ion-text
        align="center"
        class="mainFontSize categoriesWarningColor"
        [ngClass]="{
          disabled: !!(
            !option.availableTotal ||
            !choice.availableTotal ||
            (option.dependenciesVariable &&
              option.dependenciesVariable.length > 0 &&
              !option.dependentHidden &&
              !option.showDependent)
          ),
          'line-through ': !!(!option.availableTotal || !choice.availableTotal)
        }"
        *ngIf="
          (choice.isFree &&
            choice.freeQuantity &&
            choice.quantity <= choice.freeQuantity) ||
          (choice.isFree && !choice.freeQuantity)
        "
      >
        {{ "product-details.free" | translate }}
      </ion-text>
    </div>

    <app-change-quantity
      style="margin: 0px; z-index: 100000000"
      [buttonsSize]="'small'"
      [quantityInChoicesKind]="choice.quantityInChoicesKind"
      *ngIf="choice && choice.is_preselected"
      [disablePlusButton]="
        !option.availableTotal ||
        !choice.availableTotal ||
        (option.dependenciesVariable &&
          option.dependenciesVariable.length > 0 &&
          !option.dependentHidden &&
          !option.showDependent)
      "
      [disableRemoveButton]="
        !option.availableTotal ||
        !choice.availableTotal ||
        (option.dependenciesVariable &&
          option.dependenciesVariable.length > 0 &&
          !option.dependentHidden &&
          !option.showDependent)
      "
      [quantity]="choice.quantity"
      (changingQuantity)="changeChoiceQuantity($event)"
    ></app-change-quantity>
  </div>

  <span *ngIf="!thereIsAnImageInOption || !choice.is_preselected">
    <ion-text
      *ngIf="
        (!choice.isFree ||
          (choice.freeQuantity && choice.quantity > choice.freeQuantity)) &&
        showChoicePrices
      "
    >
      <ion-icon
        src="assets/flatIcons/new.svg"
        slot="end
          "
      ></ion-icon>

      <span
        [ngClass]="{
          disabled: !!(
            !option.availableTotal ||
            !choice.availableTotal ||
            (option.dependenciesVariable &&
              option.dependenciesVariable.length > 0 &&
              !option.dependentHidden &&
              !option.showDependent)
          ),
          'line-through ': !!(!option.availableTotal || !choice.availableTotal)
        }"
        *ngIf="
          ((orderPickup == false && dineIn == false) ||
            (orderPickup == null && dineIn == null) ||
            (orderPickup == true &&
              dineIn == false &&
              !choice.price_takeaway) ||
            (orderPickup == false &&
              dineIn == true &&
              (!alt_dinein_active ||
                !selectedDineInTable ||
                !selectedDineInTable.alt_dinein) &&
              !choice.price_dinein) ||
            (orderPickup == false &&
              dineIn == true &&
              alt_dinein_active &&
              selectedDineInTable &&
              selectedDineInTable.alt_dinein &&
              !choice.price_alt_dinein)) &&
          choice.price.toString().trim().replace(',', '.') > 0 &&
          choice.freeQuantity
        "
        >{{ !option.changesBasePrice ? "+" : ""
        }}{{
          choice.price.toString().trim().replace(",", ".") *
            (choice.quantity - choice.freeQuantity) | number : "1.2-2"
        }}€</span
      >
      <span
        [ngClass]="{
          disabled: !!(
            !option.availableTotal ||
            !choice.availableTotal ||
            (option.dependenciesVariable &&
              option.dependenciesVariable.length > 0 &&
              !option.dependentHidden &&
              !option.showDependent)
          ),
          'line-through ': !!(!option.availableTotal || !choice.availableTotal)
        }"
        *ngIf="
          ((orderPickup == false && dineIn == false) ||
            (orderPickup == null && dineIn == null) ||
            (orderPickup == true &&
              dineIn == false &&
              !choice.price_takeaway) ||
            (orderPickup == false &&
              dineIn == true &&
              (!alt_dinein_active ||
                !selectedDineInTable ||
                !selectedDineInTable.alt_dinein) &&
              !choice.price_dinein) ||
            (orderPickup == false &&
              dineIn == true &&
              alt_dinein_active &&
              selectedDineInTable &&
              selectedDineInTable.alt_dinein &&
              !choice.price_alt_dinein)) &&
          choice.price.toString().trim().replace(',', '.') > 0 &&
          !choice.freeQuantity
        "
        >{{ !option.changesBasePrice ? "+" : ""
        }}{{
          choice.price.toString().trim().replace(",", ".") * choice.quantity
            | number : "1.2-2"
        }}€</span
      >
      <span
        [ngClass]="{
          disabled: !!(
            !option.availableTotal ||
            !choice.availableTotal ||
            (option.dependenciesVariable &&
              option.dependenciesVariable.length > 0 &&
              !option.dependentHidden &&
              !option.showDependent)
          ),
          'line-through ': !!(!option.availableTotal || !choice.availableTotal)
        }"
        *ngIf="
          ((orderPickup == false && dineIn == false) ||
            (orderPickup == null && dineIn == null) ||
            (orderPickup == true &&
              dineIn == false &&
              !choice.price_takeaway) ||
            (orderPickup == false &&
              dineIn == true &&
              (!alt_dinein_active ||
                !selectedDineInTable ||
                !selectedDineInTable.alt_dinein) &&
              !choice.price_dinein) ||
            (orderPickup == false &&
              dineIn == true &&
              alt_dinein_active &&
              selectedDineInTable &&
              selectedDineInTable.alt_dinein &&
              !choice.price_alt_dinein)) &&
          choice.price.toString().trim().replace(',', '.') < 0
        "
        >{{
          choice.price.toString().trim().replace(",", ".") | number : "1.2-2"
        }}€</span
      >

      <span
        [ngClass]="{
          disabled: !!(
            !option.availableTotal ||
            !choice.availableTotal ||
            (option.dependenciesVariable &&
              option.dependenciesVariable.length > 0 &&
              !option.dependentHidden &&
              !option.showDependent)
          ),
          'line-through ': !!(!option.availableTotal || !choice.availableTotal)
        }"
        *ngIf="
          orderPickup == true &&
          dineIn == false &&
          choice.price_takeaway &&
          choice.price_takeaway.toString().trim().replace(',', '.') > 0 &&
          choice.freeQuantity
        "
        >{{ !option.changesBasePrice ? "+" : ""
        }}{{
          choice.price_takeaway.toString().trim().replace(",", ".") *
            (choice.quantity - choice.freeQuantity) | number : "1.2-2"
        }}€</span
      >
      <span
        [ngClass]="{
          disabled: !!(
            !option.availableTotal ||
            !choice.availableTotal ||
            (option.dependenciesVariable &&
              option.dependenciesVariable.length > 0 &&
              !option.dependentHidden &&
              !option.showDependent)
          ),
          'line-through ': !!(!option.availableTotal || !choice.availableTotal)
        }"
        *ngIf="
          orderPickup == true &&
          dineIn == false &&
          choice.price_takeaway &&
          choice.price_takeaway.toString().trim().replace(',', '.') > 0 &&
          !choice.freeQuantity
        "
        >{{ !option.changesBasePrice ? "+" : ""
        }}{{
          choice.price_takeaway.toString().trim().replace(",", ".") *
            choice.quantity | number : "1.2-2"
        }}€</span
      >
      <span
        [ngClass]="{
          disabled: !!(
            !option.availableTotal ||
            !choice.availableTotal ||
            (option.dependenciesVariable &&
              option.dependenciesVariable.length > 0 &&
              !option.dependentHidden &&
              !option.showDependent)
          ),
          'line-through ': !!(!option.availableTotal || !choice.availableTotal)
        }"
        *ngIf="
          orderPickup == true &&
          dineIn == false &&
          choice.price_takeaway &&
          choice.price_takeaway.toString().trim().replace(',', '.') < 0
        "
        >{{
          choice.price_takeaway.toString().trim().replace(",", ".")
            | number : "1.2-2"
        }}€</span
      >

      <span
        [ngClass]="{
          disabled: !!(
            !option.availableTotal ||
            !choice.availableTotal ||
            (option.dependenciesVariable &&
              option.dependenciesVariable.length > 0 &&
              !option.dependentHidden &&
              !option.showDependent)
          ),
          'line-through ': !!(!option.availableTotal || !choice.availableTotal)
        }"
        *ngIf="
          orderPickup == false &&
          dineIn == true &&
          (!alt_dinein_active ||
            !selectedDineInTable ||
            !selectedDineInTable.alt_dinein ||
            !choice.price_alt_dinein) &&
          choice.price_dinein &&
          choice.price_dinein.toString().trim().replace(',', '.') > 0 &&
          choice.freeQuantity
        "
        >{{ !option.changesBasePrice ? "+" : ""
        }}{{
          choice.price_dinein.toString().trim().replace(",", ".") *
            (choice.quantity - choice.freeQuantity) | number : "1.2-2"
        }}€</span
      >
      <span
        [ngClass]="{
          disabled: !!(
            !option.availableTotal ||
            !choice.availableTotal ||
            (option.dependenciesVariable &&
              option.dependenciesVariable.length > 0 &&
              !option.dependentHidden &&
              !option.showDependent)
          ),
          'line-through ': !!(!option.availableTotal || !choice.availableTotal)
        }"
        *ngIf="
          orderPickup == false &&
          dineIn == true &&
          choice.price_dinein &&
          (!alt_dinein_active ||
            !selectedDineInTable ||
            !selectedDineInTable.alt_dinein ||
            !choice.price_alt_dinein) &&
          choice.price_dinein.toString().trim().replace(',', '.') > 0 &&
          !choice.freeQuantity
        "
        >{{ !option.changesBasePrice ? "+" : ""
        }}{{
          choice.price_dinein.toString().trim().replace(",", ".") *
            choice.quantity | number : "1.2-2"
        }}€</span
      >
      <span
        [ngClass]="{
          disabled: !!(
            !option.availableTotal ||
            !choice.availableTotal ||
            (option.dependenciesVariable &&
              option.dependenciesVariable.length > 0 &&
              !option.dependentHidden &&
              !option.showDependent)
          ),
          'line-through ': !!(!option.availableTotal || !choice.availableTotal)
        }"
        *ngIf="
          orderPickup == false &&
          dineIn == true &&
          (!alt_dinein_active ||
            !selectedDineInTable ||
            !selectedDineInTable.alt_dinein ||
            !choice.price_alt_dinein) &&
          choice.price_dinein &&
          choice.price_dinein.toString().trim().replace(',', '.') < 0
        "
        >{{
          choice.price_dinein.toString().trim().replace(",", ".")
            | number : "1.2-2"
        }}€</span
      >

      <span
        [ngClass]="{
          disabled: !!(
            !option.availableTotal ||
            !choice.availableTotal ||
            (option.dependenciesVariable &&
              option.dependenciesVariable.length > 0 &&
              !option.dependentHidden &&
              !option.showDependent)
          ),
          'line-through ': !!(!option.availableTotal || !choice.availableTotal)
        }"
        *ngIf="
          orderPickup == false &&
          dineIn == true &&
          alt_dinein_active &&
          selectedDineInTable &&
          selectedDineInTable.alt_dinein &&
          choice.price_alt_dinein &&
          choice.price_alt_dinein.toString().trim().replace(',', '.') > 0 &&
          choice.freeQuantity
        "
        >{{ !option.changesBasePrice ? "+" : ""
        }}{{
          choice.price_alt_dinein.toString().trim().replace(",", ".") *
            (choice.quantity - choice.freeQuantity) | number : "1.2-2"
        }}€</span
      >
      <span
        [ngClass]="{
          disabled: !!(
            !option.availableTotal ||
            !choice.availableTotal ||
            (option.dependenciesVariable &&
              option.dependenciesVariable.length > 0 &&
              !option.dependentHidden &&
              !option.showDependent)
          ),
          'line-through ': !!(!option.availableTotal || !choice.availableTotal)
        }"
        *ngIf="
          orderPickup == false &&
          dineIn == true &&
          alt_dinein_active &&
          selectedDineInTable &&
          selectedDineInTable.alt_dinein &&
          choice.price_alt_dinein &&
          choice.price_alt_dinein.toString().trim().replace(',', '.') > 0 &&
          !choice.freeQuantity
        "
        >{{ !option.changesBasePrice ? "+" : ""
        }}{{
          choice.price_alt_dinein.toString().trim().replace(",", ".") *
            choice.quantity | number : "1.2-2"
        }}€</span
      >
      <span
        [ngClass]="{
          disabled: !!(
            !option.availableTotal ||
            !choice.availableTotal ||
            (option.dependenciesVariable &&
              option.dependenciesVariable.length > 0 &&
              !option.dependentHidden &&
              !option.showDependent)
          ),
          'line-through ': !!(!option.availableTotal || !choice.availableTotal)
        }"
        *ngIf="
          orderPickup == false &&
          dineIn == true &&
          alt_dinein_active &&
          selectedDineInTable &&
          selectedDineInTable.alt_dinein &&
          choice.price_alt_dinein &&
          choice.price_alt_dinein.toString().trim().replace(',', '.') < 0
        "
        >{{
          choice.price_alt_dinein.toString().trim().replace(",", ".")
            | number : "1.2-2"
        }}€</span
      >
    </ion-text>
    <ion-text
      align="center"
      class="mainFontSize categoriesWarningColor"
      [ngClass]="{
        disabled: !!(
          !option.availableTotal ||
          !choice.availableTotal ||
          (option.dependenciesVariable &&
            option.dependenciesVariable.length > 0 &&
            !option.dependentHidden &&
            !option.showDependent)
        ),
        'line-through ': !!(!option.availableTotal || !choice.availableTotal)
      }"
      *ngIf="
        (choice.isFree &&
          choice.freeQuantity &&
          choice.quantity <= choice.freeQuantity) ||
        (choice.isFree && !choice.freeQuantity)
      "
    >
      {{ "product-details.free" | translate }}
    </ion-text>
  </span>
  <app-change-quantity
    style="margin: 0px"
    [buttonsSize]="'small'"
    [quantityInChoicesKind]="choice.quantityInChoicesKind"
    slot="end"
    *ngIf="
      choice &&
      choice.is_preselected &&
      (!thereIsAnImageInOption || !choice.is_preselected) &&
      showQuantityComponent
    "
    [disablePlusButton]="
      !option.availableTotal ||
      !choice.availableTotal ||
      (option.dependenciesVariable &&
        option.dependenciesVariable.length > 0 &&
        !option.dependentHidden &&
        !option.showDependent)
    "
    [disableRemoveButton]="
      !option.availableTotal ||
      !choice.availableTotal ||
      (option.dependenciesVariable &&
        option.dependenciesVariable.length > 0 &&
        !option.dependentHidden &&
        !option.showDependent)
    "
    [quantity]="choice.quantity"
    (changingQuantity)="changeChoiceQuantity($event)"
  ></app-change-quantity>
</ion-item>
