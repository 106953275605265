import * as PreviousOrdersActions from "./previousOrders.actions";
import * as _ from "lodash";

export interface State {
  previousOrders: any;
  previousOrderCart: any;
  onPreorderChangeFetchAgain: boolean;
}

const initialState: State = {
  previousOrders: null,
  previousOrderCart: null,
  onPreorderChangeFetchAgain: null,
};

export function previousOrdersReducer(
  state = initialState,
  action: PreviousOrdersActions.PreviousOrdersActions
) {
  switch (action.type) {
    case PreviousOrdersActions.SET_PREVIOUS_ORDERS:
      return {
        ...state,
        previousOrders: action.payload,
      };

    case PreviousOrdersActions.CLEAR_PREVIOUS_ORDERS:
      return {
        ...state,
        previousOrders: null,
      };
    case PreviousOrdersActions.SET_ON_PREORDER_CHANGE_FETCH_AGAIN:
      return {
        ...state,
        onPreorderChangeFetchAgain: action.payload,
      };

    case PreviousOrdersActions.SET_PREVIOUS_ORDER_CART:
      return {
        ...state,
        previousOrderCart: action.payload,
      };

    case PreviousOrdersActions.CLEAR_PREVIOUS_ORDER_CART:
      return {
        ...state,
        previousOrderCart: null,
      };

    case PreviousOrdersActions.ADD_UPDATE_PREVIOUS_ORDER_CART_ITEM:
      const key = _.findIndex(state.previousOrderCart, (item: any) => {
        return item.cart_id === action.payload.cart_id;
      });
      if (key !== -1) {
        const updateProduct = {
          ...state.previousOrderCart[key],
          ...action.payload,
        };
        const updatedProducts = [...state.previousOrderCart];
        updatedProducts[key] = updateProduct;
        return {
          ...state,
          previousOrderCart: updatedProducts,
        };
      } else {
        if (!state.previousOrderCart) {
          return {
            ...state,
            previousOrderCart: [action.payload],
          };
        } else {
          return {
            ...state,
            previousOrderCart: [...state.previousOrderCart, action.payload],
          };
        }
      }

    case PreviousOrdersActions.DELETE_PREVIOUS_ORDER_CART_ITEM:
      const delKey = _.findIndex(state.previousOrderCart, (item: any) => {
        return item.cart_id === action.payload.cart_id;
      });
      console.log("del key", delKey);
      if (delKey !== -1) {
        let newProducts = [...state.previousOrderCart];
        newProducts.splice(delKey, 1);
        return {
          ...state,
          previousOrderCart: newProducts,
        };
      } else {
        return state;
      }

    default:
      return state;
  }
}
