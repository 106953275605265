import { Action } from "@ngrx/store";

export const SET_CART_CUSTOMER_OPTIONS =
  "[CART CUSTOMER OPTIONS] Set Cart Customer Options";
export const SET_NOT_VALID_CUSTOM_OPTIONS =
  "[CART CUSTOMER OPTIONS] Set Not Valid Custom Options";
export const CLEAR_CART_CUSTOMER_OPTIONS =
  "[CART CUSTOMER OPTIONS] Clear Cart Customer Options";

export class SetCartCustomerOptions implements Action {
  readonly type = SET_CART_CUSTOMER_OPTIONS;
  constructor(public payload: any) {}
}

export class SetNotValidCustomOptions implements Action {
  readonly type = SET_NOT_VALID_CUSTOM_OPTIONS;
  constructor(public payload: any) {}
}

export class ClearCartCustomerOptions implements Action {
  readonly type = CLEAR_CART_CUSTOMER_OPTIONS;
  constructor() {}
}

export type CartCustomerOptionsActions =
  | SetCartCustomerOptions
  | SetNotValidCustomOptions
  | ClearCartCustomerOptions;
