<ion-button
  color="black"
  fill="clear"
  (click)="closeModal()"
  style="position: absolute; top: -10px; right: 0px"
  ><ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
</ion-button>
<iframe
  sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
  style="padding: 0"
  [src]="paypal_iframe"
  width="320px"
  height="340px"
>
</iframe>
