import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import { auditTime, distinctUntilChanged, first, Subscription } from "rxjs";
import * as fromApp from "../../store/app.reducer";
import * as _ from "lodash";
import { PaymentGatewaysService } from "src/app/services/payment-gateways/payment-gateways.service";
import * as selectors from "../../store/app.selectors";
import { VERSION } from "../../../environments/version";
import {
  AlertController,
  IonContent,
  LoadingController,
  ModalController,
  Platform,
} from "@ionic/angular";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import { AnalyticsServiceService } from "src/app/services/analitycsService/analytics-service.service";
import * as CartActions from "../../cart/store/cart.actions";
import * as OrderCommentsActions from "../../cart/cart-components/order-comments/store/orderComments.actions";
import * as DisableFinalPriceActions from "../../store/disable-final-price/disable-final-price.actions";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import * as selectedInvoiceActions from "../../cart/cart-components/invoice/store/selectedInvoice.actions";
import * as DeliveryMethodAddressActions from "../../store/deliveryMethodAddress/deliveryMethodAddress.actions";
import * as CartCustomerOptionsActions from "../../../app/cart/cart-components/cart-custom-options/store/cart-customer-options/cart-customer-options.actions";
import * as CartCustomerOptionsSelectionsActions from "../../../app/cart/cart-components/cart-custom-options/store/cart-custom-options-selections/cart-custom-options-selections.actions";
import { AnimationOptions } from "ngx-lottie";
import { CartPageAlertsService } from "src/app/services/cartPageAlerts/cart-page-alerts.service";
import { Capacitor } from "@capacitor/core";
declare let window: any;
@Component({
  selector: "app-send-reservation-popup",
  templateUrl: "./send-reservation-popup.component.html",
  styleUrls: ["./send-reservation-popup.component.scss"],
})
export class SendReservationPopupComponent implements OnInit {
  @Input() reservation;
  @Input() type;
  //@Input() existedOrderItem;
  public selectedAddress: any;
  public currentUser: any;
  //public orderComments: any;
  public generalComments: any;
  public mo_token: any;
  public loading: boolean;
  private unsubscribeBackEvent: Subscription;
  private subscription: Subscription;
  private storeInfoSubscription: Subscription;
  private groupApp: any;
  private store_info: any;
  public currentStoreId: any;
  public createOrderLoadingAnimation = false;
  private ionLoading: any;
  private selectedLanguage: any;
  private createOrderRetried;
  public newCard: any = { selected: false };

  public selectedPaymentMethodID = "piraeus";
  private userSubscription: Subscription;
  private createOrderLoadingAnimationInstance: any;
  animationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/create-order-animation.json",
  };

  constructor(
    private modalController: ModalController,
    private platform: Platform,
    private store: Store<fromApp.AppState>,
    private paymentGatewaysService: PaymentGatewaysService,
    private dataStorageService: DataStorageService,
    private router: Router,
    private alertController: AlertController,
    private translate: TranslateService,
    private loadingController: LoadingController
  ) {}

  ngOnInit() {
    // this.dataStorageService.triggerReconnectSocket();
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      300,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );

    this.subscription = this.store
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (
          state &&
          state.auth &&
          state.auth.mo_token &&
          !_.isEqual(state.auth.mo_token, this.mo_token)
        ) {
          this.mo_token = _.cloneDeep(state.auth.mo_token);
        }
        if (
          state &&
          state.currentStore &&
          state.currentStore.currentStoreId &&
          !_.isEqual(state.currentStore.currentStoreId, this.currentStoreId)
        ) {
          this.currentStoreId = _.cloneDeep(state.currentStore.currentStoreId);
        }
        if (
          state &&
          state.groupApp &&
          state.groupApp.groupApp &&
          !_.isEqual(state.groupApp.groupApp, this.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp.groupApp);

          if (this.groupApp.design_settings) {
            if (this.groupApp.design_settings.animation_loader) {
              this.animationOptions = {
                ...this.animationOptions,
                path:
                  "https://data.deliverymanager.gr/animations/" +
                  this.groupApp.design_settings.animation_loader,
              };
            } else {
              this.animationOptions = {
                ...this.animationOptions,
                path: "https://data.deliverymanager.gr/animations/spinner-default-1.json",
              };
            }
          } else {
            this.animationOptions = {
              ...this.animationOptions,
              path: "https://data.deliverymanager.gr/animations/spinner-default-1.json",
            };
          }
        }
      });

    this.userSubscription = this.store
      .select("auth")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (state && !_.isEqual(state.user, this.currentUser)) {
          this.currentUser = _.cloneDeep(state.user);
        }
      });

    this.storeInfoSubscription = this.store
      .select(selectors.getStoreInfo)
      .pipe(distinctUntilChanged())
      .subscribe((storeInfo) => {
        if (storeInfo && !_.isEqual(storeInfo, this.store_info)) {
          this.store_info = _.cloneDeep(storeInfo);
          console.log("store Info from cart options", this.store_info);
        }
      });
  }

  checkForSendOrder() {
    if (this.newCard && this.newCard.selected && !this.newCard.acceptedTerms) {
      this.errorAlert(
        this.translate.instant(
          "Θα πρέπει να αποδεχτείτε πρώτα τους όρους χρήσης και την πολιτική επιστροφής!"
        )
      );

      //This appears only when adding the card the first time not with recurring payments
    } else {
      this.sendOrder();
    }
  }

  sendPayment() {
    let PaymentProcess = {
      orderItem: _.assign(this.reservation, {
        customer_id: this.currentUser.customer_id,
        store_id: this.currentStoreId,
      }),
    };
    PaymentProcess.orderItem.order_id = PaymentProcess.orderItem.res_id;

    this.store_info.paymentGateways[this.selectedPaymentMethodID] = _.extend(
      this.store_info.paymentGateways[this.selectedPaymentMethodID],
      {
        store_id: this.store_info.store_id,
        id: this.selectedPaymentMethodID,
      }
    );

    this.paymentGatewaysService.selectedPaymentGateway(
      parseFloat(this.reservation.prepay_amount),
      this.store_info.paymentGateways[this.selectedPaymentMethodID],
      "reservation",
      null,
      this.reservation.res_id,
      this.reservation.store_id,
      null,
      null,
      "",
      "dnsc",
      PaymentProcess,
      null
    );

    this.closeModal();
  }

  sendOrder() {
    if (this.newCard && !this.newCard.acceptedTerms) {
      this.errorAlert(
        this.translate.instant(
          "Θα πρέπει να αποδεχτείτε πρώτα τους όρους χρήσης και την πολιτική επιστροφής!"
        )
      );
      return;
    }
    let platform = "browser";
    if (!Capacitor.isNativePlatform() && this.inIframe()) {
      platform = "facebook";
    } else if (Capacitor.isNativePlatform()) {
      platform = Capacitor.getPlatform();
    }
    if (this.type && this.type === "only_pay") {
      this.sendPayment();
      return;
    }
    this.loading = true;
    this.createOrderLoadingAnimation = true;
    // const langs = _.cloneDeep(this.groupApp.languages);
    // let baseLanguage;
    // const moBaseLanguage = _.find(langs, { mo_base: true });
    // if (moBaseLanguage && moBaseLanguage.code) {
    //   baseLanguage = moBaseLanguage.code;
    // }
    const reservation = _.assign(this.reservation, {
      customer_id: this.currentUser.customer_id,
      store_id: this.currentStoreId,
    });
    this.dataStorageService
      .createReservation(this.mo_token, reservation)
      .subscribe({
        next: async (res: any) => {
          if (res && res.success) {
            this.loading = false;
            this.createOrderLoadingAnimation = false;
            this.createOrderRetried = false;

            //this.cartPageAlertsService.clearPendingOrderAlertAlreadyShowedInCompleteOrder();
            //this.store.dispatch(new CartActions.ClearCart());

            // this.store.dispatch(
            //   new selectedInvoiceActions.ClearSelectedInvoice()
            // );
            // this.store.dispatch(
            //   new DeliveryMethodAddressActions.setSelectedPreorderDate(null)
            // );
            // this.store.dispatch(
            //   new OrderCommentsActions.SetOrderComments(null)
            // );

            // this.store.dispatch(
            //   new CartCustomerOptionsActions.ClearCartCustomerOptions()
            // );

            // this.store.dispatch(
            //   new CartCustomerOptionsSelectionsActions.ClearCartCustomOptionsSelections()
            // );

            let PaymentProcess = {
              orderItem: res.reservation,
            };
            PaymentProcess.orderItem.order_id = PaymentProcess.orderItem.res_id;

            this.store_info.paymentGateways[this.selectedPaymentMethodID] =
              _.extend(
                this.store_info.paymentGateways[this.selectedPaymentMethodID],
                {
                  store_id: this.store_info.store_id,
                  id: this.selectedPaymentMethodID,
                }
              );

            this.paymentGatewaysService.selectedPaymentGateway(
              parseFloat(res.reservation.prepay_amount),
              this.store_info.paymentGateways[this.selectedPaymentMethodID],
              "reservation",
              null,
              res.reservation.res_id,
              res.reservation.store_id,
              null,
              null,
              "",
              "dnsc",
              PaymentProcess,
              null
            );

            this.closeModal();
            //this.presentOrderCompletedAlert();

            this.router.navigateByUrl("/reservations", {
              replaceUrl: true,
            });
          } else {
            const alert = await this.alertController.create({
              header: this.translate.instant("alert"),
              message: this.translate.instant(
                res.comment_id || "reservations.create-reservation-failed"
              ),
              backdropDismiss: false,
              buttons: ["OK"],
            });
            await alert.present();
          }
        },
        error: (error) => {
          console.log("Error on sending order: ", error);

          if (!this.createOrderRetried) {
            this.createOrderRetried = true;
            setTimeout(() => {
              this.sendOrder();
            }, 5000);
          } else {
            this.createOrderRetried = false;
            this.loading = false;
            this.createOrderLoadingAnimation = false;
            this.presentErrorAlert(
              "errorMessages.sending-you-order-failed-please-try-again"
            );
          }
        },
      });
  }

  async presentPreorderInfoModal() {
    // const modal = await this.modalController.create({
    //   component: CreateOrderFailedPreorderModalComponent,
    //   cssClass: "create-order-failed-preorder-modal",
    //   backdropDismiss: false,
    // });
    // await modal.present();
  }

  animationCreated(ev) {
    this.createOrderLoadingAnimationInstance = ev;
    this.createOrderLoadingAnimationInstance.setSpeed(1);
  }

  inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  async loadingSimulation() {
    await this.presentLoading();
    setTimeout(async () => {
      if (this.ionLoading) {
        await this.ionLoading.dismiss();
        this.ionLoading = null;
      }
    }, 1500);
  }

  async presentLoading() {
    console.log("present loading from send order pay popup");
    let message = this.translate.instant("loading-please-wait");
    this.ionLoading = await this.loadingController.create({
      duration: 20000,
      message: message,
      cssClass: "ionicLoadingCss",
      keyboardClose: true,
    });
    return await this.ionLoading.present();
  }

  closeModal() {
    this.modalController.dismiss();
  }

  async errorAlert(message) {
    const alert = await this.alertController.create({
      header: this.translate.instant("alert"),

      message: this.translate.instant(message),
      buttons: ["OK"],
    });

    await alert.present();
  }

  async presentErrorAlert(message) {
    const alert = await this.alertController.create({
      cssClass: "errorOnOrderCompletedAlert",
      header: this.translate.instant("errorMessages.alert"),

      message: this.translate.instant(message),
      buttons: ["OK"],
    });

    await alert.present();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.storeInfoSubscription) {
      this.storeInfoSubscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
  }
}
