<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button fill="clear" (click)="closeModal()">
        <ion-icon src="assets/ionicons/close.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <ion-text>{{ contest.title }} Δώρα</ion-text>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid style="max-width: 600px">
    <ion-row style="width: 100%">
      <ion-col
        *ngFor="let gift of contest.gifts"
        [size]="
          windowDimensions.width > 600 &&
          contest.gifts &&
          contest.gifts.length > 1
            ? '6'
            : '12'
        "
      >
        <ion-card>
          <img
            *ngIf="gift.image"
            alt="Gift image"
            [src]="
              'https://d3vm1ym7xlqx7l.cloudfront.net/' +
              group +
              '/contests/' +
              contest.contest_id +
              '/' +
              gift.image
            "
          />
          <ion-img
            *ngIf="!gift.image"
            style="width: 100%; height: 100%; object-fit: cover"
            [src]="
              'https://d3vm1ym7xlqx7l.cloudfront.net/' +
              group +
              '/contests/default-gift.jpg'
            "
          ></ion-img>
          <ion-card-header>
            <ion-card-title>{{ gift.title }}</ion-card-title>
            <!-- <ion-card-subtitle>Card Subtitle</ion-card-subtitle> -->
          </ion-card-header>

          <ion-card-content>
            {{ gift.description }}
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
