import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../../../store/app.reducer";
import * as CartCustomOptionsSelectionsActions from "../../store/cart-custom-options-selections/cart-custom-options-selections.actions";

import * as _ from "lodash";
import { auditTime, distinctUntilChanged } from "rxjs/operators";
import { Subscription } from "rxjs";
import moment from "moment-timezone";

@Component({
  selector: "app-radio-type-choices",
  templateUrl: "./radio-type-choices.component.html",
  styleUrls: ["./radio-type-choices.component.scss"],
})
export class RadioTypeChoicesComponent implements OnInit, OnDestroy {
  @Input() option_timestamp;
  @Input() selectedLang: any;
  public option: any;
  public CustomSelections;
  public finalPriceLoading;
  public selectedChoiceTimestamp;
  public cartPrices;
  public grand_total;

  private subscriptions: Subscription[] = [];
  private doNotCheckAllChoicesPeriod = false;

  constructor(private store: Store<fromApp.AppState>) {}

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select("cartCustomOptionsSelections")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.cartCustomOptionsSelections &&
            !_.isEqual(state.cartCustomOptionsSelections, this.CustomSelections)
          ) {
            this.CustomSelections = _.cloneDeep(
              state.cartCustomOptionsSelections
            );
            this.option = _.cloneDeep(
              _.find(this.CustomSelections, {
                timestamp: this.option_timestamp,
              })
            );

            if (
              this.option &&
              this.option.choices &&
              this.option.choices.length > 0
            ) {
              this.option.choices.forEach((choice) => {
                if (choice.selected) {
                  this.selectedChoiceTimestamp = choice.timestamp;
                }
              });
            }

            if (this.doNotCheckAllChoicesPeriod) {
              this.doNotCheckAllChoicesPeriod = false;
              this.checkAllChoicesForShowPeriodDebounced();
            } else {
              this.checkAllChoicesForShowPeriodDebounced();
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("disableFinalPrice")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(state.disableFinalPrice, this.finalPriceLoading)
          ) {
            this.finalPriceLoading = _.cloneDeep(state.disableFinalPrice);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("cartPrices")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (state && !_.isEqual(state.cartPrices, this.cartPrices)) {
            this.cartPrices = _.cloneDeep(state.cartPrices);

            if (this.cartPrices && this.cartPrices.grand_total) {
              this.grand_total = parseFloat(
                _.cloneDeep(this.cartPrices.grand_total)
              );
            } else {
              this.grand_total = 0;
            }
          }
        })
    );
  }

  checkForSelectedChoiceIgnoringPeriod(choice) {
    let ignoreShowPeriod = false;

    if (
      choice &&
      choice.ignoreShowPeriodWhenOptionObject &&
      choice.ignoreShowPeriodWhenOptionObject.option_timestamp &&
      this.CustomSelections
    ) {
      const option = _.find(this.CustomSelections, {
        timestamp: choice.ignoreShowPeriodWhenOptionObject.option_timestamp,
      });

      console.log(
        "ignoreShowPeriod",
        choice.ignoreShowPeriodWhenOptionObject,
        option
      );

      if (option && option.choices) {
        _.each(
          choice.ignoreShowPeriodWhenOptionObject.choices,
          (ingore_choice) => {
            const found = _.find(option.choices, {
              timestamp: ingore_choice.timestamp,
            });

            if (found && found.selected) {
              ignoreShowPeriod = true;
            }
          }
        );
      }
    }

    return ignoreShowPeriod;
  }

  private checkAllChoicesForShowPeriodDebounced = _.debounce(() => {
    this.checkAllChoicesForShowPeriod();
  }, 200);

  checkAllChoicesForShowPeriod() {
    if (this.option && this.option.choices && this.option.choices.length > 0) {
      this.option.choices.forEach((choice) => {
        if (
          choice.enableShowPeriod &&
          choice.start_timestamp &&
          choice.stop_timestamp &&
          !this.checkForSelectedChoiceIgnoringPeriod(choice)
        ) {
          choice.outOfPeriod = this.checkShowPeriod(_.cloneDeep(choice));

          if (choice.outOfPeriod) {
            choice.selected = false;

            if (choice.timestamp === this.selectedChoiceTimestamp) {
              this.selectedChoiceTimestamp = null;
            }
          }
        } else if (
          choice.enableShowPeriod &&
          choice.start_timestamp &&
          choice.stop_timestamp &&
          this.checkForSelectedChoiceIgnoringPeriod(choice)
        ) {
          delete choice.outOfPeriod;
        }
      });
    }
  }

  checkShowPeriod(ch) {
    const today = moment();

    const startDate = moment(ch.start_timestamp).set({
      year: today.year(),
      month: today.month(),
      date: today.date(),
    });

    const stopDate = moment(ch.stop_timestamp).set({
      year: today.year(),
      month: today.month(),
      date: today.date(),
    });

    if (today.isBefore(startDate) || today.isAfter(stopDate)) {
      //out of period
      return true;
    }

    return false;
  }

  onRadioChanges(ev) {
    this.checkAllChoicesForShowPeriod();

    if (this.selectedChoiceTimestamp) {
      let choiceIndex = _.findIndex(this.option.choices, {
        timestamp: this.selectedChoiceTimestamp,
      });
      console.log("choice index", choiceIndex);
      this.option.choices.forEach((choice) => {
        choice.selected = false;
      });
      if (choiceIndex != -1) {
        // console.log(
        //   "choice selected",
        //   this.option.choices[choiceIndex].selected
        // );
        this.option.choices[choiceIndex].selected = true;
        this.store.dispatch(
          new CartCustomOptionsSelectionsActions.AddUpdateCartCustomOptionSelections(
            _.cloneDeep(this.option)
          )
        );
      }
    } else {
      this.option.choices.forEach((choice) => {
        choice.selected = false;
      });
      this.store.dispatch(
        new CartCustomOptionsSelectionsActions.AddUpdateCartCustomOptionSelections(
          _.cloneDeep(this.option)
        )
      );
    }
  }

  trackTimestamp(index, choice) {
    return choice.timestamp;
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }
}
