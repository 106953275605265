import { Injectable } from "@angular/core";
import { LoadingController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class AppLoadingService {
  private ionLoading: any;

  constructor(
    private translateService: TranslateService,
    private loadingController: LoadingController
  ) {}

  async presentLoading() {
    const message = this.translateService.instant("loading-please-wait");
    this.ionLoading = await this.loadingController.create({
      duration: 3000,
      message: message,
      cssClass: "ionicLoadingCss",
      keyboardClose: true,
    });
    await this.ionLoading.present();
    await this.ionLoading.onDidDismiss().then(() => {
      this.ionLoading = null;
    });
  }

  async hideLoading() {
    if (this.ionLoading) {
      return await this.ionLoading.dismiss().then(() => {
        this.ionLoading = null;
      });
    }
    return Promise.resolve({});
  }
}
