import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { Subscription, auditTime, distinctUntilChanged } from "rxjs";
import * as _ from "lodash";
import * as fromApp from "../../store/app.reducer";
import mapStyleJson from "../../../assets/mapStyle/mapStyle.json";
import mapStyleJsonDark from "../../../assets/mapStyle/mapStyleDark.json";
import { Loader } from "@googlemaps/js-api-loader";
import { Store } from "@ngrx/store";
import { Capacitor } from "@capacitor/core";
import { getDistance } from "geolib";

@Component({
  selector: "app-delivery-tracking-modal",
  templateUrl: "./delivery-tracking-modal.component.html",
  styleUrls: ["./delivery-tracking-modal.component.scss"],
})
export class DeliveryTrackingModalComponent implements OnInit, OnDestroy {
  @Input() order_id: any;
  public trakingOrder: any;
  public loading: boolean = false;
  public groupApp: any;
  public stores: any;
  public selectedTheme: any;
  public selectedLanguage;
  public libraryLoaded: boolean = false;
  public map: any;
  public homeMarker;
  public storeMarker;

  private ordersArray: any;
  private mobilePlatform: boolean;
  private subscriptions: Subscription[] = [];
  constructor(
    private modalController: ModalController,
    private changeDetector: ChangeDetectorRef,
    private platform: Platform,
    private store: Store<fromApp.AppState>
  ) {}

  ngOnInit() {
    if (Capacitor.isNativePlatform()) {
      this.mobilePlatform = true;
    } else {
      this.mobilePlatform = false;
    }

    this.subscriptions.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (state && state.stores && !_.isEqual(state.stores, this.stores)) {
            this.stores = _.cloneDeep(state.stores);
            if (
              this.groupApp &&
              this.selectedLanguage &&
              this.stores &&
              this.selectedTheme &&
              this.trakingOrder
            ) {
              this.createMapsScript();
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(this.groupApp, state.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);

            if (
              this.groupApp &&
              this.selectedLanguage &&
              this.stores &&
              this.selectedTheme &&
              this.trakingOrder
            ) {
              this.createMapsScript();
            }
          }
          if (
            state &&
            state.selectedLangugage &&
            !_.isEqual(this.selectedLanguage, state.selectedLangugage)
          ) {
            this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
            if (
              this.groupApp &&
              this.selectedLanguage &&
              this.stores &&
              this.trakingOrder &&
              this.selectedTheme
            ) {
              this.createMapsScript();
            }
          }

          if (
            state &&
            state.selectedTheme &&
            !_.isEqual(this.selectedTheme, state.selectedTheme)
          ) {
            this.selectedTheme = _.cloneDeep(state.selectedTheme);

            if (
              this.groupApp &&
              this.selectedLanguage &&
              this.stores &&
              this.selectedTheme &&
              this.trakingOrder
            ) {
              this.createMapsScript();
            }
            if (this.selectedTheme && this.map) {
              if (this.selectedTheme === "dark") {
                this.map.set("styles", mapStyleJsonDark);
              } else {
                this.map.set("styles", mapStyleJson);
              }
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("orders")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            state.ordersArray &&
            !_.isEqual(this.ordersArray, state.ordersArray)
          ) {
            this.ordersArray = _.cloneDeep(state.ordersArray);

            const orderForTracking = _.find(_.cloneDeep(this.ordersArray), {
              order_id: this.order_id,
            });

            if (
              orderForTracking &&
              !_.isEqual(this.trakingOrder, orderForTracking)
            ) {
              this.trakingOrder = _.cloneDeep(orderForTracking);
              if (
                this.groupApp &&
                this.selectedLanguage &&
                this.stores &&
                this.selectedTheme &&
                this.trakingOrder
              ) {
                this.createMapsScript();
              }
            }
          }
        })
    );
  }

  createMapsScript() {
    console.log("createMapsScript");
    var loader: any;
    // console.log("loader elemnt", document.getElementById("googleMapsLoader"));
    if (!document.getElementById("googleMapsLoader")) {
      loader = new Loader({
        id: "googleMapsLoader",
        apiKey: this.groupApp.googleMapsKey,
        language: this.selectedLanguage,
        libraries: ["geometry", "drawing", "places"],
      });
      loader.load().then(() => {
        console.log("library loaded");
        this.libraryLoaded = true;
        this.changeDetector.detectChanges();

        this.initializationSetAddress();
      });
    } else {
      this.libraryLoaded = true;
      this.changeDetector.detectChanges();

      this.initializationSetAddress();
    }
  }

  initializationSetAddress() {
    console.log("initializations map");
    this.map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        zoom: parseInt(this.groupApp.groupMapZoom),
        gestureHandling: "greedy",
        center: {
          lat: parseFloat(this.groupApp.groupMapCenterLat),
          lng: parseFloat(this.groupApp.groupMapCenterLng),
        },

        zoomControl: this.mobilePlatform ? false : true,
        scaleControl: false,
        scrollwheel: false,
        disableDefaultUI: true,
        mapTypeControl: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles:
          this.selectedTheme && this.selectedTheme === "dark"
            ? mapStyleJsonDark
            : mapStyleJson,
      }
    );

    const storeIcon = {
      url: "assets/ionicons/store-location.png",
      scaledSize: new google.maps.Size(60, 60),
    };

    const homeIcon = {
      url: "assets/ionicons/home.png",
      scaledSize: new google.maps.Size(50, 50),
    };

    const orderStore = _.find(this.stores, {
      store_id: this.trakingOrder.store_id,
    });

    const distance = getDistance(
      {
        latitude: orderStore.mapCenterStoreLat,
        longitude: orderStore.mapCenterStoreLng,
      },
      {
        latitude: this.trakingOrder.userLat,
        longitude: this.trakingOrder.userLng,
      }
    );

    const middlePoint = this.findMidpoint(
      orderStore.mapCenterStoreLat,
      orderStore.mapCenterStoreLng,
      this.trakingOrder.userLat,
      this.trakingOrder.userLng
    );
    if (middlePoint) {
      console.log("distance", distance);
      if (distance < 2000) {
        this.map.setZoom(15);
      } else if (distance >= 2000 && distance <= 6000) {
        this.map.setZoom(13);
      } else if (distance > 6000 && distance < 10000) {
        this.map.setZoom(11);
      } else if (distance >= 10000) {
        this.map.setZoom(9);
      }

      this.map.setCenter(middlePoint);
    }

    this.storeMarker = new google.maps.Marker({
      animation: google.maps.Animation.DROP,
      icon: storeIcon,
      map: this.map,
      position: {
        lat: orderStore.mapCenterStoreLat,
        lng: orderStore.mapCenterStoreLng,
      },
      visible: true,
      draggable: false,
    });

    console.log("orderStore", this.trakingOrder);

    this.homeMarker = new google.maps.Marker({
      animation: google.maps.Animation.DROP,
      icon: homeIcon,
      map: this.map,
      position: {
        lat: this.trakingOrder.userLat,
        lng: this.trakingOrder.userLng,
      },

      visible: true,
      draggable: false,
    });

    this.changeDetector.detectChanges();
  }

  closeModal() {
    this.modalController.dismiss();
  }

  findMidpoint(lat1, lng1, lat2, lng2) {
    const midpointLat = (lat1 + lat2) / 2;
    const midpointLon = (lng1 + lng2) / 2;
    return {
      lat: midpointLat,
      lng: midpointLon,
    };
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });

      this.subscriptions = [];
    }
  }
}
