import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../../store/app.reducer";
import * as selectors from "../../../../store/app.selectors";
import * as _ from "lodash";
import { AlertController, ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import * as CartActions from "../../../../cart/store/cart.actions";
import { distinctUntilChanged, first } from "rxjs/operators";
import { Subscription } from "rxjs";
import { ProductDetailsComponent } from "src/app/product-details/product-details.component";
import * as selectedProductActions from "../../../store/selectedProduct.actions";
import { ProductValidDeliveryAreasPopupComponent } from "src/app/popups/product-valid-delivery-areas-popup/product-valid-delivery-areas-popup.component";
declare let window: any;
@Component({
  selector: "app-bundle-product-item",
  templateUrl: "./bundle-product-item.component.html",
  styleUrls: ["./bundle-product-item.component.scss"],
})
export class BundleProductItemComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() selectedProductId: any;
  @Input() choice: any;
  @Input() option_id: any;
  @Input() bundleProduct: any;
  @Input() groupStoreData: any;
  @Output() optionChanged = new EventEmitter();
  public finalPriceLoading: boolean;
  public showProductItemDetails: boolean = false;
  public orderPickup: any;
  public dineIn: any;
  public deleteAlertConfirm: any;
  public groupApp: any;
  public productPointsSystemException: boolean;
  public productPointSystemWeight: number = 1;
  public store_info: any;
  public selectedProduct: any;
  public selectedDineInTable: any;
  public onlyAvailableForPreorderProductsById: any;
  public image: any = {};
  public selectedAddress: any;

  private productValidDeliveryAreasModal;
  private productDetailsModal: any;
  private subscription: Subscription;
  private subscription2: Subscription;
  private subscription3: Subscription;
  private subscription4: Subscription;
  private subscription5: Subscription;
  private subscription6: Subscription;

  constructor(
    private store: Store<fromApp.AppState>,
    private alertCtrl: AlertController,
    private translateService: TranslateService,
    private modalController: ModalController
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    console.log("bundle product", this.bundleProduct);
    this.initProductImage();
  }

  checkForDepentantOption() {
    if (this.option_id) {
      const option = _.find(this.selectedProduct.options, {
        option_id: this.option_id,
      });
      if (
        option.dependenciesVariable &&
        option.dependenciesVariable.length > 0 &&
        !option.dependentHidden &&
        !option.showDependent
      ) {
        this.deleteBundleProduct(this.bundleProduct);
      }
    }
  }

  ngOnInit() {
    this.initProductImage();
    this.subscription6 = this.store
      .select(
        selectors.getSelectedProductBySelectedProductId(this.selectedProductId)
      )
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (state && !_.isEqual(this.selectedProduct, state)) {
          this.selectedProduct = _.cloneDeep(state);
          this.checkForDepentantOption();
        }
      });

    this.subscription4 = this.store
      .select("deliveryMethodAddress")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (state && !_.isEqual(this.orderPickup, state.orderPickup)) {
          this.orderPickup = _.cloneDeep(state.orderPickup);
        }

        if (state && !_.isEqual(this.dineIn, state.dineIn)) {
          this.dineIn = _.cloneDeep(state.dineIn);
        }

        if (
          state &&
          !_.isEqual(this.selectedDineInTable, state.selectedDineInTable)
        ) {
          this.selectedDineInTable = _.cloneDeep(state.selectedDineInTable);
        }

        if (state && !_.isEqual(this.selectedAddress, state.selectedAddress)) {
          this.selectedAddress = _.cloneDeep(state.selectedAddress);
        }
      });

    this.subscription = this.store
      .select("disableFinalPrice")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          !_.isEqual(this.finalPriceLoading, state.disableFinalPrice)
        ) {
          this.finalPriceLoading = _.cloneDeep(state.disableFinalPrice);
        }
      });

    // if (this.cartProduct) {
    //   this.cartProduct.options.forEach((opt) => {
    //     //console.log(opt);
    //   });
    // }
    // this.subscription2 = this.store
    //   .select("deliveryMethodAddress")
    //   .subscribe((state) => {
    //     if (state && _.isEqual(state.orderPickup, this.orderPickup)) {
    //       this.orderPickup = _.cloneDeep(state.orderPickup);
    //     }
    //   });

    this.subscription6 = this.store
      .select("cart")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          !_.isEqual(
            this.onlyAvailableForPreorderProductsById,
            state.onlyAvailableForPreorderCartProductsById
          )
        ) {
          this.onlyAvailableForPreorderProductsById = _.cloneDeep(
            state.onlyAvailableForPreorderCartProductsById
          );
        }
      });

    this.subscription3 = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.groupApp &&
          !_.isEqual(state.groupApp, this.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp);
        }
      });

    this.subscription5 = this.store
      .select(selectors.getStoreInfo)
      .pipe(distinctUntilChanged())
      .subscribe((store_info) => {
        if (store_info && !_.isEqual(store_info, this.store_info)) {
          this.store_info = _.cloneDeep(store_info);
        }
      });
  }

  initProductImage() {
    this.store
      .select("groupApp")
      .pipe(first())
      .subscribe((state) => {
        if (
          this.bundleProduct &&
          this.bundleProduct.image &&
          this.bundleProduct.image.true &&
          this.bundleProduct.image.true["4x3"] &&
          this.bundleProduct.image.true["4x3"].path &&
          state.groupApp &&
          (!state.groupApp.disableCatalogPhoto ||
            state.groupApp.disableCatalogPhoto)
        ) {
          this.image.path =
            "https://images.deliverymanager.gr/" +
            this.choice.bundled_product.image.true["4x3"].path +
            (state.webp
              ? ".webp"
              : this.choice.bundled_product.image.true["4x3"].type || ".jpg");
        }
      })
      .unsubscribe();

    console.log("imagegegegeg", this.image);
  }

  calculateProductPointSystemAttributes() {}

  // calculatePrice() {
  //   let price = parseFloat(this.cartProduct.price);
  //   let quantity = parseFloat(this.cartProduct.quantity);
  //   return (price * quantity).toFixed(2) + "€";
  // }

  presentAlertConfirm(bundleProduct) {
    if (!this.deleteAlertConfirm) {
      this.deleteAlertConfirm = true;
      this.showAlertConfirm(bundleProduct);
    }
  }

  showProductValidDeliveryAreasInfoModal() {
    if (!this.productValidDeliveryAreasModal) {
      this.presentProductValidDeliveryAreasModal();
    }
  }

  async presentProductValidDeliveryAreasModal() {
    this.productValidDeliveryAreasModal = await this.modalController.create({
      component: ProductValidDeliveryAreasPopupComponent,
      cssClass: "productValidDeliveryAreasPopup",
      animated: true,
      componentProps: {
        notValidAddress: this.selectedAddress,
        cartProduct: this.selectedProduct,
      },
      backdropDismiss: false,
    });

    await this.productValidDeliveryAreasModal.present();
    this.productValidDeliveryAreasModal.onDidDismiss().then(() => {
      this.productValidDeliveryAreasModal = null;
    });
  }

  async showAlertConfirm(bundleProduct) {
    this.deleteAlertConfirm = await this.alertCtrl.create({
      cssClass: "my-custom-class",
      header: this.translateService.instant(
        "product-details.bundle-option.bundle-product-item.delete"
      ),
      message: this.translateService.instant(
        "product-details.bundle-option.bundle-product-item.do-you-want-to-delete-product"
      ),
      buttons: [
        {
          text: this.translateService.instant(
            "product-details.bundle-option.bundle-product-item.cancel"
          ),
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: this.translateService.instant(
            "product-details.bundle-option.bundle-product-item.delete"
          ),
          handler: () => {
            this.deleteBundleProduct(bundleProduct);
          },
        },
      ],
    });

    await this.deleteAlertConfirm.present();
    this.deleteAlertConfirm.onDidDismiss().then(() => {
      this.deleteAlertConfirm = false;
    });
  }

  showProductDetailsModal(bundleChoice, product) {
    let detailsCssClass;
    console.log("teeeest", product);
    if (
      product &&
      product.options &&
      product.options.length < 2 &&
      product.noImageProduct
    ) {
      detailsCssClass = "productDetailsModalSmall";
    } else if (
      product &&
      !product.squareImageDesign &&
      product.options &&
      product.options.length > 2 &&
      !product.noImageProduct
    ) {
      detailsCssClass = "productDetailsModalBig";
    } else if (
      product &&
      product.squareImageDesign &&
      product.options &&
      product.options.length > 2 &&
      window &&
      window.innerWidth &&
      window.innerWidth >= 1200 &&
      !product.noImageProduct
    ) {
      detailsCssClass = "productDetailsModalBigSquareImageDesign";
    } else {
      detailsCssClass = "productDetailsModal";
    }
    console.log("detals css", detailsCssClass, product);
    if (!this.productDetailsModal) {
      this.productDetailsModal = true;
      this.store
        .select(
          selectors.getSelectedProductBySelectedProductId(
            this.selectedProductId
          )
        )
        .pipe(first())
        .subscribe((parentProduct) => {
          this.presentProductDetailsModal(
            _.cloneDeep(parentProduct),
            bundleChoice,
            product,
            detailsCssClass
          );
        })
        .unsubscribe();
    }
  }

  async presentProductDetailsModal(
    parentProduct,
    bundleChoice,
    product,
    cssClass
  ) {
    let product_id: string;
    let cart_id: string;
    if (product && product.product_id) {
      product_id = product.product_id;
    }

    if (product && product.cart_id) {
      cart_id = product.cart_id;
    }

    this.productDetailsModal = await this.modalController.create({
      component: ProductDetailsComponent,
      cssClass: cssClass,
      animated: true,
      componentProps: {
        bundleMode: "edit",
        product_id: product.product_id,
        bundleOptions: this.choice.bundle_options,
        parentProduct: parentProduct,
        parentBundleChoice: bundleChoice,
        edited_bundle_product: product,
        cart_id: null,
      },
      backdropDismiss: false,
    });

    await this.productDetailsModal.present();
    this.productDetailsModal.onDidDismiss().then((data) => {
      this.productDetailsModal = null;
      if (data && data.data && data.data.product) {
        let bundleProduct = _.cloneDeep(data.data.product);
        let selectedProduct = _.cloneDeep(this.selectedProduct);
        bundleProduct.productTotal = _.cloneDeep(data.data.price);
        const optIndex = _.findIndex(selectedProduct.options, {
          option_id: this.option_id,
        });
        console.log("bundle product item opt index", optIndex);
        if (optIndex !== -1) {
          //for radio choices
          const chIndex = _.findIndex(
            selectedProduct.options[optIndex].choices,
            {
              is_preselected: true,
            }
          );
          console.log("bundle product item ch index", chIndex);
          if (
            chIndex !== -1 &&
            selectedProduct.options[optIndex] &&
            selectedProduct.options[optIndex].choices[chIndex] &&
            selectedProduct.options[optIndex].choices[chIndex]
              .bundled_product &&
            selectedProduct.options[optIndex].choices[chIndex].bundled_product
              .product_id === product.product_id
          ) {
            selectedProduct.options[optIndex].choices[chIndex].bundled_product =
              _.cloneDeep(bundleProduct);
          }
        }
        this.bundleProduct = _.cloneDeep(bundleProduct);
        console.log(
          "set selectedProduct from bundle product item",
          selectedProduct
        );
        if (optIndex !== -1) {
          this.optionChanged.emit(
            _.cloneDeep(selectedProduct.options[optIndex])
          );
        }
      }

      this.productDetailsModal = null;
    });
  }

  productHaveDetails(prd) {
    let showProducts = false;

    _.each(prd.options, (option) => {
      if (
        option &&
        option.option_id &&
        (!option.dependenciesVariable ||
          option.dependenciesVariable.length === 0 ||
          (option.dependenciesVariable.length > 0 && option.showDependent))
      ) {
        if (option.type !== "field") {
          if (
            !(
              option.choices &&
              !option.reverseChoices &&
              this.choicesIsPreselected(option.choices).length === 0
            ) ||
            (option.is_require && option.availableTotal)
          ) {
            if (
              !option.doNotPrint ||
              (option.parent_free && option.option_parent_total_price === 0)
            ) {
              //console.log("retuntrue");
              showProducts = true;
            } else {
              //console.log("retunfalse");
            }
          }
          if (
            option.type === "checkbox" &&
            option.reverseChoices &&
            option.choicesComment
          ) {
            //console.log("retuntrue");
            showProducts = true;
          }
          _.each(option.choices, (choice) => {
            if (
              !choice.doNotPrint &&
              (option.type !== "checkbox" ||
                !option.reverseChoices ||
                (!option.choicesComment && !option.reverseChoices))
            ) {
              showProducts = true;
            }

            if (choice.choicePriceComment) {
              showProducts = true;
            } else {
              if (choice.price) {
                showProducts = true;
              }
            }
          });
        } else {
          if (option.text) {
            //console.log("retuntrue");
            showProducts = true;
          } else {
            // console.log("retunfalse");
          }
        }
      } else {
        // console.log("retunfalse");
      }
    });
    return showProducts;
  }

  choicesIsPreselected(choices) {
    let preselectedArray = [];
    choices.forEach((choice) => {
      if (choice && choice.is_preselected) {
        preselectedArray.push(choice);
      }
    });

    return preselectedArray;
  }

  deleteBundleProduct(bundled_product) {
    let selectedProduct = _.cloneDeep(this.selectedProduct);
    const optionIndex = _.findIndex(selectedProduct.options, {
      option_id: this.option_id,
    });
    console.log(
      "optio index",
      optionIndex,
      selectedProduct.options[optionIndex],
      this.choice.choice_id
    );
    if (optionIndex !== -1) {
      const choiceIndex = _.findIndex(
        selectedProduct.options[optionIndex].choices,
        { choice_id: this.choice.choice_id }
      );
      console.log("choic index", choiceIndex);
      if (choiceIndex !== -1) {
        selectedProduct.options[optionIndex].choices[
          choiceIndex
        ].bundled_product = null;
        selectedProduct.options[optionIndex].choices[
          choiceIndex
        ].is_preselected = false;
        console.log("set selectedProduct");
        if (optionIndex !== -1) {
          this.optionChanged.emit(
            _.cloneDeep(selectedProduct.options[optionIndex])
          );
        }
      }
    }
  }

  ngOnDestroy() {
    if (this.productValidDeliveryAreasModal) {
      this.productValidDeliveryAreasModal.dismiss();
      this.productValidDeliveryAreasModal = null;
    }

    if (this.productDetailsModal) {
      this.productDetailsModal.dismiss();
      this.productDetailsModal = null;
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.subscription2) {
      this.subscription2.unsubscribe();
    }

    if (this.subscription3) {
      this.subscription3.unsubscribe();
    }

    if (this.subscription4) {
      this.subscription4.unsubscribe();
    }

    if (this.subscription5) {
      this.subscription5.unsubscribe();
    }
    if (this.subscription6) {
      this.subscription6.unsubscribe();
    }
  }
}
