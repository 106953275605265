<div
  *ngIf="option && (!selectedChoice || !selectedChoice.bundled_product)"
  [class]="'added_product_valid_border'"
  style="margin: 8px; padding-inline-start: 0; padding-inline-end: 5px"
>
  <ion-grid *ngIf="bundleOptionLoading">
    <ion-row style="width: 100%">
      <ion-col class="ion-text-center">
        <ion-spinner></ion-spinner>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-item
    *ngIf="!bundleOptionLoading"
    detail="false"
    button
    [disabled]="
      option.dependenciesVariable &&
      option.dependenciesVariable.length > 0 &&
      !option.dependentHidden &&
      !option.showDependent
    "
    (click)="openSelectionModal(option.option_id)"
    lines="none"
  >
    <ion-row style="width: 100%">
      <ion-col
        class="ion-no-padding ion-no-margin"
        size="12"
        class="ion-text-right"
      >
        <ion-badge
          *ngIf="
            optionValidationObject &&
            optionValidationObject.errorArray &&
            optionValidationObject.errorArray.length > 0
          "
          color="danger"
        >
          <ion-text style="font-size: smaller">
            {{ optionValidationObject.errorArray[0] }}
          </ion-text>
        </ion-badge>
      </ion-col>
      <ion-col
        class="ion-text-right"
        size="12"
        *ngIf="
          selectedProductFreeParentOption &&
          (!option.dependenciesVariable ||
            option.dependenciesVariable.length === 0) &&
          option.parent_free &&
          option.option_parent_total_price === 0
        "
      >
        <ion-badge color="danger">
          <ion-text>
            {{ "product-details.options.free" | translate }}
          </ion-text>
        </ion-badge>
      </ion-col>
      <ion-col
        class="ion-text-right"
        size="12"
        *ngIf="
          selectedProductFreeParentOption &&
          (!option.dependenciesVariable ||
            option.dependenciesVariable.length === 0) &&
          option.parent_free &&
          option.option_parent_total_price &&
          option.option_total_price &&
          toFloat(option.option_total_price) >
            toFloat(option.option_parent_total_price)
        "
      >
        <ion-badge color="danger">
          <ion-text>
            {{ option.option_parent_total_price }}€ ({{
              selectedProductParentDiscount
            }}% {{ "discount" | translate }})
          </ion-text>
        </ion-badge>
      </ion-col>
      <ion-col size="12" class="ion-no-padding ion-no-margin">
        <div>
          <strong>
            {{ option.title }}
            <span
              *ngIf="option.is_require && option.availableTotal"
              style="font-weight: bold"
              >*</span
            >
          </strong>

          <p *ngIf="option && option.description">{{ option.description }}</p>

          <div
            style="padding: 0px; margin: 0px"
            *ngIf="
              option.promo ||
              option.pickup ||
              option.tags ||
              !option.availableTotal
            "
          >
            <app-product-option-tags
              [selectedProductPromo]="selectedProductPromo"
              [groupStoreData]="groupStoreData"
              [optionItem]="option"
            ></app-product-option-tags>
          </div>

          <ion-text color="danger">
            <p style="margin: 0">
              {{ "product-details.no-choice" | translate }}
            </p>
          </ion-text>
        </div>
      </ion-col>
    </ion-row>

    <ion-icon
      src="assets/ionicons/chevron-down-outline.svg"
      slot="end"
    ></ion-icon>
  </ion-item>
</div>

<div
  *ngIf="selectedChoice && selectedChoice.bundled_product"
  [class]="
    selectedChoice.bundled_product &&
    (selectedChoice.bundled_product | cartProductOptionRequired)
      ? 'added_product_invalid_border'
      : 'added_product_valid_border'
  "
  style="margin: 8px; padding-inline-start: 0; padding-inline-end: 5px"
>
  <ion-row style="width: 100%">
    <ion-col size="12" class="ion-text-right">
      <ion-badge
        *ngIf="
          optionValidationObject &&
          optionValidationObject.errorArray &&
          optionValidationObject.errorArray.length > 0
        "
        color="danger"
      >
        <ion-text style="font-size: smaller">
          {{ optionValidationObject.errorArray[0] }}
        </ion-text>
      </ion-badge>
    </ion-col>
    <ion-col
      class="ion-text-right"
      size="12"
      *ngIf="
        selectedProductFreeParentOption &&
        (!option.dependenciesVariable ||
          option.dependenciesVariable.length === 0) &&
        option.parent_free &&
        option.option_parent_total_price === 0
      "
    >
      <ion-badge color="danger">
        <ion-text>
          {{ "product-details.options.free" | translate }}
        </ion-text>
      </ion-badge>
    </ion-col>
    <ion-col
      class="ion-text-right"
      size="12"
      *ngIf="
        selectedProductFreeParentOption &&
        (!option.dependenciesVariable ||
          option.dependenciesVariable.length === 0) &&
        option.parent_free &&
        option.option_parent_total_price &&
        option.option_total_price &&
        toFloat(option.option_total_price) >
          toFloat(option.option_parent_total_price)
      "
    >
      <ion-badge color="danger">
        <ion-text>
          {{ option.option_parent_total_price }}€ ({{
            selectedProductParentDiscount
          }}% {{ "discount" | translate }})
        </ion-text>
      </ion-badge>
    </ion-col>
    <!-- <ion-col size="12">
      <ion-item
        button="true"
        detail="false"
        lines="none"
        color="light"
        (click)="openSelectionModal(option.option_id)"
      >
        <div>
          <strong>
            {{ option.title }}
            <span
              *ngIf="option.is_require && option.availableTotal"
              style="font-weight: bold"
              >*</span
            >
          </strong>

          <p *ngIf="option && option.description">{{ option.description }}</p>

          <div
            style="padding: 0px; margin: 0px"
            *ngIf="
              option.promo ||
              option.pickup ||
              option.tags ||
              !option.availableTotal
            "
          >
            <app-product-option-tags
              [selectedProductPromo]="selectedProductPromo"
              [optionItem]="option"
            ></app-product-option-tags>
          </div>
        </div>

        <ion-text
          *ngIf="
            option &&
            option.bundle_options &&
            option.bundle_options.enable_change_bundle_product_text &&
            option.bundle_options.change_bundle_product_text &&
            selectedLanguage &&
            option.bundle_options.change_bundle_product_text[selectedLanguage]
          "
          style="font-size: small"
          color="primary"
          slot="end"
        >
          {{
            option.bundle_options.change_bundle_product_text[selectedLanguage]
          }}
        </ion-text>

        <ion-text
          *ngIf="
            option &&
            (!option.bundle_options ||
              !option.bundle_options.enable_change_bundle_product_text ||
              !option.bundle_options.change_bundle_product_text ||
              !selectedLanguage ||
              !option.bundle_options.change_bundle_product_text[
                selectedLanguage
              ])
          "
          style="font-size: small"
          color="primary"
          slot="end"
        >
          {{ "product-details.change" | translate }}
        </ion-text>
        <ion-icon
          style="font-size: 20px"
          color="primary"
          src="assets/ionicons/chevron-down-outline.svg"
          slot="end"
        ></ion-icon>
      </ion-item>
    </ion-col> -->
  </ion-row>

  <app-bundle-product-item
    *ngIf="selectedChoice && selectedChoice.bundled_product"
    [option_id]="option_id"
    [choice]="selectedChoice"
    [bundleProduct]="selectedChoice.bundled_product"
    [groupStoreData]="groupStoreData"
    [selectedProductId]="selectedProductId"
    (optionChanged)="optionChanged($event)"
  >
  </app-bundle-product-item>
</div>
