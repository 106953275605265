import { Component, Input, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../../../store/app.reducer";
import * as _ from "lodash";
import * as CartCustomOptionsSelectionsActions from "../../store/cart-custom-options-selections/cart-custom-options-selections.actions";
import { AlertController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { auditTime, distinctUntilChanged, first } from "rxjs/operators";
import { Subscription } from "rxjs";

@Component({
  selector: "app-plastic-bug-tax-option",
  templateUrl: "./plastic-bug-tax-option.component.html",
  styleUrls: ["./plastic-bug-tax-option.component.scss"],
})
export class PlasticBugTaxOptionComponent implements OnInit {
  @Input() option_timestamp;
  @Input() selectedLang: any;
  @Input() catalogMode: boolean;
  public option;
  public CustomSelections;
  public cartProducts: any;
  private subscriptions: Subscription[] = [];
  public finalPriceLoading: any;

  constructor(
    private store: Store<fromApp.AppState>,
    private alertCtrl: AlertController,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    //this.setTimeOut();

    this.subscriptions.push(
      this.store
        .select("cart")
        .pipe(distinctUntilChanged())
        .subscribe((cartState) => {
          if (cartState && !_.isEqual(this.cartProducts, cartState.products)) {
            this.cartProducts = _.cloneDeep(cartState.products);
            if (this.option && this.option.choices) {
              _.each(this.option.choices, (ch) => {
                ch.qty = this.calculateChoiceQuantity(this.cartProducts);

                if (ch.qty >= 0) {
                  ch.totalPrice = this.calculateChoicePrice(ch);
                  this.store.dispatch(
                    new CartCustomOptionsSelectionsActions.AddUpdateCartCustomOptionSelections(
                      _.cloneDeep(this.option)
                    )
                  );
                }
              });
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("cartCustomOptionsSelections")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.cartCustomOptionsSelections &&
            !_.isEqual(state.cartCustomOptionsSelections, this.CustomSelections)
          ) {
            this.CustomSelections = _.cloneDeep(
              state.cartCustomOptionsSelections
            );
            this.option = _.cloneDeep(
              _.find(this.CustomSelections, {
                timestamp: this.option_timestamp,
              })
            );
            if (this.cartProducts && this.option && this.option.choices) {
              _.each(this.option.choices, (ch) => {
                ch.qty = this.calculateChoiceQuantity(this.cartProducts);
                console.log(
                  "choice qty",
                  ch,
                  this.calculateChoiceQuantity(this.cartProducts)
                );
                if (ch.qty >= 0) {
                  ch.totalPrice = this.calculateChoicePrice(ch);
                  this.store.dispatch(
                    new CartCustomOptionsSelectionsActions.AddUpdateCartCustomOptionSelections(
                      _.cloneDeep(this.option)
                    )
                  );
                }
              });
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("disableFinalPrice")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(state.disableFinalPrice, this.finalPriceLoading)
          ) {
            this.finalPriceLoading = _.cloneDeep(state.disableFinalPrice);
          }
        })
    );
  }

  calculateChoiceQuantityFromProduct(
    parentProduct,
    product,
    wrapperSum,
    wrapperSumForSeperate
  ) {
    if (product && product.options) {
      _.each(product.options, (option) => {
        if (option && option.choices) {
          _.each(option.choices, (choice) => {
            if (choice.bundle && !_.isEmpty(choice.bundled_product)) {
              const returnObj = this.calculateChoiceQuantityFromProduct(
                _.cloneDeep(product),
                _.cloneDeep(choice.bundled_product),
                _.cloneDeep(wrapperSum),
                _.cloneDeep(wrapperSumForSeperate)
              );

              if (returnObj) {
                wrapperSum = _.cloneDeep(returnObj.wrapperSum);
                wrapperSumForSeperate = _.cloneDeep(
                  returnObj.wrapperSumForSeperate
                );
              }
            }
          });
        }
      });
    }

    if (
      product &&
      product.plasticBugTax &&
      product.plasticBugTaxWeight &&
      product.separatePlasticBag
    ) {
      if (parentProduct) {
        wrapperSumForSeperate =
          wrapperSumForSeperate +
          (product.enable_quantity_step && product.quantity_step
            ? 1
            : product.quantity) *
            product.plasticBugTaxWeight *
            (parentProduct.enable_quantity_step && parentProduct.quantity_step
              ? 1
              : parentProduct.quantity);
      } else {
        wrapperSumForSeperate =
          wrapperSumForSeperate +
          (product.enable_quantity_step && product.quantity_step
            ? 1
            : product.quantity) *
            product.plasticBugTaxWeight;
      }
    } else if (
      product &&
      product.plasticBugTax &&
      product.plasticBugTaxWeight &&
      !product.separatePlasticBag
    ) {
      if (parentProduct) {
        wrapperSum =
          wrapperSum +
          (product.enable_quantity_step && product.quantity_step
            ? 1
            : product.quantity) *
            product.plasticBugTaxWeight *
            (parentProduct.enable_quantity_step && parentProduct.quantity_step
              ? 1
              : parentProduct.quantity);
      } else {
        wrapperSum =
          wrapperSum +
          (product.enable_quantity_step && product.quantity_step
            ? 1
            : product.quantity) *
            product.plasticBugTaxWeight;
      }
    }

    _.each(product.options, (option) => {
      if (option && option.choices && option.choices.length) {
        _.each(option.choices, (choice) => {
          if (
            choice &&
            choice.plasticBugTax &&
            choice.plasticBugTaxWeight &&
            choice.separatePlasticBag
          ) {
            if (parentProduct) {
              wrapperSumForSeperate =
                wrapperSumForSeperate +
                (product.enable_quantity_step && product.quantity_step
                  ? 1
                  : product.quantity) *
                  choice.quantity *
                  choice.plasticBugTaxWeight *
                  (parentProduct.enable_quantity_step &&
                  parentProduct.quantity_step
                    ? 1
                    : parentProduct.quantity);
            } else {
              wrapperSumForSeperate =
                wrapperSumForSeperate +
                (product.enable_quantity_step && product.quantity_step
                  ? 1
                  : product.quantity) *
                  choice.quantity *
                  choice.plasticBugTaxWeight;
            }
          } else if (
            choice &&
            choice.plasticBugTax &&
            choice.plasticBugTaxWeight &&
            !choice.separatePlasticBag
          ) {
            if (parentProduct) {
              wrapperSum =
                wrapperSum +
                (product.enable_quantity_step && product.quantity_step
                  ? 1
                  : product.quantity) *
                  choice.quantity *
                  choice.plasticBugTaxWeight *
                  (parentProduct.enable_quantity_step &&
                  parentProduct.quantity_step
                    ? 1
                    : parentProduct.quantity);
            } else {
              wrapperSum =
                wrapperSum +
                (product.enable_quantity_step && product.quantity_step
                  ? 1
                  : product.quantity) *
                  choice.quantity *
                  choice.plasticBugTaxWeight;
            }
          }
        });
      }
    });

    return {
      wrapperSum: _.cloneDeep(wrapperSum),
      wrapperSumForSeperate: _.cloneDeep(wrapperSumForSeperate),
    };
  }
  calculateChoiceQuantity(products) {
    let cartProducts = _.cloneDeep(products);
    if (cartProducts && cartProducts.length) {
      let wrapperSumForSeperate = 0.0;
      let wrapperSum = 0.0;
      _.each(cartProducts, (product) => {
        const returnObj = this.calculateChoiceQuantityFromProduct(
          null,
          _.cloneDeep(product),
          _.cloneDeep(wrapperSum),
          _.cloneDeep(wrapperSumForSeperate)
        );

        if (returnObj) {
          wrapperSum = _.cloneDeep(returnObj.wrapperSum);
          wrapperSumForSeperate = _.cloneDeep(returnObj.wrapperSumForSeperate);
        }
      });

      //console.log("wrapperSum", wrapperSum);
      //console.log("wrapperSumForSeperate", wrapperSumForSeperate);

      var quotient = Math.floor(wrapperSum);
      var remainder = wrapperSum % 1;
      if (quotient === 0) {
        if (remainder && remainder > 0.05) {
          remainder = 1;
        } else {
          remainder = 0;
        }
      } else if (quotient > 0) {
        if (remainder && remainder > 0.21) {
          remainder = 1;
        } else {
          remainder = 0;
        }
      }

      var quotientSeparate = Math.floor(wrapperSumForSeperate);
      var remainderSeparate = wrapperSumForSeperate % 1;
      if (quotientSeparate === 0) {
        if (remainderSeparate && remainderSeparate > 0.05) {
          remainderSeparate = 1;
        } else {
          remainderSeparate = 0;
        }
      } else if (quotientSeparate > 0) {
        if (remainderSeparate && remainderSeparate > 0.21) {
          remainderSeparate = 1;
        } else {
          remainderSeparate = 0;
        }
      }

      // console.log("quotient", quotient);
      // console.log("remainder", remainder);
      //  console.log("quotientSeparate", quotientSeparate);
      // console.log("remainderSeparate", remainderSeparate);
      return quotient + remainder + (quotientSeparate + remainderSeparate);
    } else {
      return 0;
    }
  }

  calculateChoicePrice(choice) {
    //choice.qty = this.calculateChoiceQuantity();
    //console.log("choice before calc price", choice);
    if (choice && choice.qty > 0 && choice.price > 0) {
      // console.log("wrapper price", (choice.qty * choice.price).toFixed(2));
      return (choice.qty * choice.price).toFixed(2);
    } else {
      return null;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
    this.subscriptions = [];
  }
}
