import { Action } from "@ngrx/store";

export const SET_APP_INIT_LOADING_STEP =
  "[APP INIT LOADING] Set App Init Loading Step";

export class SetAppInitLoadingStep implements Action {
  readonly type = SET_APP_INIT_LOADING_STEP;
  constructor(public payload: any) {}
}

export type AppInitLoadingActions = SetAppInitLoadingStep;
