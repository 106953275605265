import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  auditTime,
  distinct,
  distinctUntilChanged,
  first,
} from "rxjs/operators";
import * as fromApp from "../../store/app.reducer";
import * as _ from "lodash";
import * as CurrentStoreActions from "../../store/current-store/current-store.actions";
import {
  AlertController,
  IonContent,
  ModalController,
  Platform,
} from "@ionic/angular";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { CheckLocationDistanceService } from "src/app/services/checkLocationDistance/check-location-distance.service";
import { AnimationOptions } from "ngx-lottie";

@Component({
  selector: "app-select-store-popup",
  templateUrl: "./select-store-popup.component.html",
  styleUrls: ["./select-store-popup.component.scss"],
})
export class SelectStorePopupComponent implements OnInit, OnDestroy {
  @ViewChild(IonContent) content: IonContent;
  @Input() onlyReturnSelectedStoreId;
  @Input() storesForSelection: any;
  @Input() showValidStores;
  @Input() forSaveCard;
  @Input() forDineinSelection: boolean;
  public stores: any;
  public validStores;
  public orderPickup: boolean;
  public dineIn: boolean;
  renderedValidStores;
  public selectedLangugage: any;
  public gpsDistanceLoading = false;
  public searchTerm = null;
  public filteredStores;
  public searchStoreLoading = false;
  public gpsLoadingAnimation;
  public showGpsLoadingAnimation: boolean = false;

  private currentStoreId: any;
  private subscription: Subscription;
  private subscription2: Subscription;
  private subscription3: Subscription;
  private subscription4: Subscription;
  private unsubscribeBackEvent: Subscription;

  options: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/gps-stores-loading-animation.json",
  };
  constructor(
    private store: Store<fromApp.AppState>,
    private modalCtrl: ModalController,
    private router: Router,
    private translateService: TranslateService,
    private alertController: AlertController,
    private checkLocationDistanceService: CheckLocationDistanceService,
    private platform: Platform
  ) {}

  ngOnInit() {
    this.store
      .select("stores")
      .pipe(first())
      .subscribe(async (state) => {
        if (state.stores && state.stores.length > 1 && !this.validStores) {
          this.gpsDistanceLoading = true;

          const res: any =
            await this.checkLocationDistanceService.calculateDistanceFromAllStoresByGPS(
              state.stores,
              false
            );

          if (this.storesForSelection && this.onlyReturnSelectedStoreId) {
            _.each(this.stores, (store) => {
              const storeFromGps = _.cloneDeep(
                _.find(res.stores, { store_id: store.store_id })
              );
              if (store.store_id && storeFromGps) {
                store.estimatedDistanceFromClientGps =
                  storeFromGps.estimatedDistanceFromClientGps;
              }
            });

            this.filteredStores = _.cloneDeep(this.stores);
          }
          this.gpsDistanceLoading = false;
        }
      })
      .unsubscribe();

    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );
    if (!this.onlyReturnSelectedStoreId) {
      if (this.showValidStores) {
        this.subscription = this.store
          .select("stores")
          .pipe(distinctUntilChanged())
          .pipe(auditTime(100))
          .subscribe(async (state) => {
            if (
              state &&
              state.stores &&
              !_.isEqual(state.stores, this.stores)
            ) {
              if (state.stores && state.stores.length > 1 && this.stores) {
                let showLottieGpsLoading = false;

                _.each(_.cloneDeep(state.stores), (stateStore) => {
                  const previousStoreVar = _.find(this.stores, {
                    store_id: stateStore.store_id,
                  });
                  if (
                    stateStore &&
                    previousStoreVar &&
                    stateStore.estimatedDistanceFromClientGps &&
                    (!previousStoreVar.estimatedDistanceFromClientGps ||
                      previousStoreVar.estimatedDistanceFromClientGps !==
                        stateStore.estimatedDistanceFromClientGps)
                  ) {
                    showLottieGpsLoading = true;
                  }
                });

                if (showLottieGpsLoading) {
                  this.showGpsReorderAnimationLoading();
                }
              }

              this.stores = _.cloneDeep(state.stores);

              this.filteredStores = _.cloneDeep(this.stores);
              if (!this.subscription2) {
                this.subscription2 = this.store
                  .select("deliveryMethodAddress")
                  .pipe(distinctUntilChanged())
                  .pipe(auditTime(100))
                  .subscribe((state) => {
                    if (
                      state &&
                      !_.isEqual(state.validStores, this.validStores)
                    ) {
                      this.validStores = _.cloneDeep(state.validStores);
                      this.calculateStores();
                    }
                  });
              }
            }
          });
      } else {
        this.subscription2 = this.store
          .select("stores")
          .pipe(distinctUntilChanged())
          .pipe(auditTime(100))
          .subscribe((state) => {
            if (
              state &&
              state.stores &&
              !_.isEqual(state.stores, this.stores)
            ) {
              if (state.stores && state.stores.length > 1 && this.stores) {
                let showLottieGpsLoading = false;

                _.each(_.cloneDeep(state.stores), (stateStore) => {
                  const previousStoreVar = _.find(this.stores, {
                    store_id: stateStore.store_id,
                  });
                  if (
                    stateStore &&
                    previousStoreVar &&
                    stateStore.estimatedDistanceFromClientGps &&
                    (!previousStoreVar.estimatedDistanceFromClientGps ||
                      previousStoreVar.estimatedDistanceFromClientGps !==
                        stateStore.estimatedDistanceFromClientGps)
                  ) {
                    showLottieGpsLoading = true;
                  }
                });

                if (showLottieGpsLoading) {
                  this.showGpsReorderAnimationLoading();
                }
              }

              this.stores = _.cloneDeep(state.stores);
              this.filteredStores = _.cloneDeep(this.stores);
              console.log(this.stores);
            }
          });
      }
    } else {
      this.stores = _.cloneDeep(this.storesForSelection);
      this.filteredStores = _.cloneDeep(this.stores);
    }

    this.subscription3 = this.store
      .select("currentStore")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (
          state &&
          state.currentStoreId &&
          !_.isEqual(state.currentStoreId, this.currentStoreId)
        ) {
          this.currentStoreId = _.cloneDeep(state.currentStoreId);
        }
      });

    this.subscription4 = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (
          state &&
          state.selectedLangugage &&
          !_.isEqual(state.selectedLangugage, this.selectedLangugage)
        ) {
          this.selectedLangugage = _.cloneDeep(state.selectedLangugage);
        }
      });
  }

  removeGreekAccents(input) {
    const accentsMap = {
      ά: "α",
      έ: "ε",
      ή: "η",
      ί: "ι",
      ύ: "υ",
      ό: "ο",
      ώ: "ω",
      ϊ: "ι",
      ϋ: "υ",
    };

    return input.replace(/[άέήίύόώϊϋ]/g, (match) => accentsMap[match]);
  }

  onSearchType() {
    this.searchStoreLoading = true;
    this.searchStoreDebounced();
  }

  private searchStoreDebounced = _.debounce(() => {
    this.searchStore();
  }, 1200);

  searchStore() {
    if (this.searchTerm) {
      this.filteredStores = _.filter(this.stores, (store) => {
        if (
          store &&
          ((store.store_name_mobileorder &&
            store.store_name_mobileorder[this.selectedLangugage] &&
            this.removeGreekAccents(
              store.store_name_mobileorder[this.selectedLangugage]
                .toString()
                .toLowerCase()
            ).indexOf(this.removeGreekAccents(this.searchTerm.toLowerCase())) >
              -1) ||
            (store.store_name_langs &&
              store.store_name_langs[this.selectedLangugage] &&
              this.removeGreekAccents(
                store.store_name_langs[this.selectedLangugage]
                  .toString()
                  .toLowerCase()
              ).indexOf(
                this.removeGreekAccents(this.searchTerm.toLowerCase())
              ) > -1) ||
            (store.store_name_langs &&
              store.store_name_langs[this.selectedLangugage] &&
              this.removeGreekAccents(
                store.store_name_langs[this.selectedLangugage]
                  .toString()
                  .toLowerCase()
              ).indexOf(
                this.removeGreekAccents(this.searchTerm.toLowerCase())
              ) > -1) ||
            (store.store_name_langs &&
              store.store_name_langs.el &&
              store.store_name_langs.el.toString().indexOf(this.searchTerm) >
                -1) ||
            (store.store_name &&
              this.removeGreekAccents(
                store.store_name.toString().toLowerCase()
              ).indexOf(
                this.removeGreekAccents(this.searchTerm.toLowerCase())
              ) > -1) ||
            (store.address &&
              this.removeGreekAccents(
                store.address.toString().toLowerCase()
              ).indexOf(
                this.removeGreekAccents(this.searchTerm.toLowerCase())
              ) > -1))
        ) {
          return store;
        }
      });
    } else {
      this.filteredStores = _.cloneDeep(this.stores);
    }

    this.searchStoreLoading = false;
  }

  resetSearchStore() {
    this.searchTerm = null;
    this.filteredStores = _.cloneDeep(this.stores);
  }

  trackStores(index, item) {
    return item.store_id;
  }

  async calculateLocations() {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      message: this.translateService.instant(
        "popups.select-store-popup.do-you-want-to-order-stores-by-location"
      ),
      buttons: [
        {
          text: this.translateService.instant("cancel"),
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {},
        },
        {
          text: this.translateService.instant("yes"),
          handler: async () => {
            if (this.stores && this.stores.length > 1) {
              const res: any =
                await this.checkLocationDistanceService.calculateDistanceFromAllStoresByGPS(
                  this.stores,
                  true
                );
              if (this.storesForSelection && this.onlyReturnSelectedStoreId) {
                _.each(this.stores, (store) => {
                  const storeFromGps = _.cloneDeep(
                    _.find(res.stores, { store_id: store.store_id })
                  );
                  if (store.store_id && storeFromGps) {
                    store.estimatedDistanceFromClientGps =
                      storeFromGps.estimatedDistanceFromClientGps;
                  }
                });

                this.filteredStores = _.cloneDeep(this.stores);
              }
              this.gpsDistanceLoading = false;
            }
          },
        },
      ],
    });
    await alert.present();
  }

  showGpsReorderAnimationLoading() {
    this.showGpsLoadingAnimation = true;
    if (this.content) {
      this.content.scrollToTop();
    }
    setTimeout(() => {
      this.showGpsLoadingAnimation = false;
    }, 1700);
  }

  animationCreated(ev) {
    this.gpsLoadingAnimation = ev;
  }

  calculateStores() {
    this.renderedValidStores = [];
    _.each(this.stores, (store) => {
      _.each(this.validStores, (validStores) => {
        if (store.store_id === validStores.store_id) {
          this.renderedValidStores.push(_.cloneDeep(store));
        }
      });
    });
  }

  async returnSelectedStoreId(storeID) {
    const selectedStore = _.find(_.cloneDeep(this.stores), { store_id: storeID });
    if (selectedStore && selectedStore.hasTheOffer) {
      this.modalCtrl.dismiss(storeID);
    } else if (selectedStore && selectedStore.supported) {
      this.modalCtrl.dismiss(storeID);
    } else {
      if (this.forSaveCard) {
        const alert = await this.alertController.create({
          cssClass: "my-custom-class",
          header: this.translateService.instant("alert"),
          message: this.translateService.instant(
            "payment-cards.store-doesnt-support-save-card"
          ),
          buttons: ["OK"],
        });
        await alert.present();
      } else if (this.forDineinSelection) {
        const alert = await this.alertController.create({
          cssClass: "my-custom-class",
          header: this.translateService.instant("alert"),
          message: this.translateService.instant(
            "popups.dine-in-modal.dinein-is-disabled-in-this-store-short"
          ),
          buttons: ["OK"],
        });
        await alert.present();
      } else {
        const alert = await this.alertController.create({
          cssClass: "my-custom-class",
          header: this.translateService.instant("alert"),
          message: this.translateService.instant(
            "homepage.store-doesnt-have-the-offer"
          ),
          buttons: ["OK"],
        });
        await alert.present();
      }
    }
  }

  selectStore(storeID) {
    if (this.showValidStores) {
      if (storeID) {
        this.modalCtrl.dismiss({ selectedStoreId: storeID });
      }
    } else {
      this.store
        .select("cart")
        .pipe(first())
        .subscribe(async (state) => {
          if (
            state &&
            state.products &&
            state.products.length > 0 &&
            !_.isEqual(this.currentStoreId, storeID)
          ) {
            const alert = await this.alertController.create({
              cssClass: "my-custom-class",
              header: this.translateService.instant("alert"),
              message: this.translateService.instant(
                "catalog.if-you-change-store-your-cart-products-will-be-deleted-do-you-want-to-continue"
              ),
              buttons: [
                {
                  text: this.translateService.instant("cancel"),
                  role: "cancel",
                  cssClass: "secondary",
                  handler: (blah) => {},
                },
                {
                  text: this.translateService.instant("yes"),
                  handler: () => {
                    if (storeID) {
                      console.log("set store id select store modal");
                      this.store.dispatch(
                        new CurrentStoreActions.SetCurrentStoreId(storeID)
                      );
                    }
                    this.modalCtrl.dismiss();
                    this.router.navigateByUrl("/catalog", {
                      replaceUrl: true,
                    });
                  },
                },
              ],
            });
            await alert.present();
          } else if (
            (state &&
              (!state.products ||
                (state.products && state.products.length === 0))) ||
            _.isEqual(this.currentStoreId, storeID)
          ) {
            if (storeID) {
              console.log("set store id select store modal");
              this.store.dispatch(
                new CurrentStoreActions.SetCurrentStoreId(storeID)
              );
            }
            this.modalCtrl.dismiss();
            this.router.navigateByUrl("/catalog", {
              replaceUrl: true,
            });
          }
        })
        .unsubscribe();
    }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  ngOnDestroy() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.subscription2) {
      this.subscription2.unsubscribe();
    }
    if (this.subscription3) {
      this.subscription3.unsubscribe();
    }
  }
}
