<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">
      {{ "popups.delivery-cost-info-modal.title" | translate }}
    </ion-title>
    <ion-button
      (click)="closeModal()"
      size="small"
      slot="end"
      fill="clear"
      color="dark"
    >
      <ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid
    *ngIf="currentStore && validStore && deliveryCost && selectedAddress"
  >
    <ion-grid class="ion-text-center" style="max-width: 140px">
      <ng-lottie [options]="animationOptions"></ng-lottie>
    </ion-grid>
    <ion-item lines="none">
      <ion-label class="ion-text-wrap ion-text-center">
        <div style="margin-bottom: 10px">
          <ion-text
            *ngIf="
              !validStore.calculation_type ||
              validStore.calculation_type === 'path'
            "
          >
            {{
              "popups.delivery-cost-info-modal.your-delivery-cost-is-calculated-by-your-distance-from-store"
                | translate
            }}
          </ion-text>

          <ion-text
            *ngIf="
              validStore.calculation_type &&
              validStore.calculation_type === 'radius'
            "
          >
            {{
              "popups.delivery-cost-info-modal.your-delivery-cost-is-calculated-by-your-radius-distance-from-store"
                | translate
            }}
          </ion-text>
        </div>
        <div>
          {{ "popups.delivery-cost-info-modal.deliveryCost" | translate }}:
          <strong> {{ deliveryCost }}€ </strong>
          <ion-text
            style="font-size: small"
            *ngIf="
              validStore &&
              validStore.deliveryCost &&
              !(validStore.step_active && validStore.step_distance)
            "
          >
            ({{ validStore.deliveryCost | twoDecimalsRound : true }}€ / km)
          </ion-text>
        </div>
        <div
          *ngIf="
            (validStore &&
              (!validStore.calculation_type ||
                validStore.calculation_type === 'path') &&
              selectedAddress &&
              selectedAddress.distance_by_store_id &&
              selectedAddress.distance_by_store_id[validStore.store_id]) ||
            (validStore &&
              (!validStore.calculation_type ||
                validStore.calculation_type === 'radius') &&
              selectedAddress &&
              selectedAddress.radius_distance_by_store_id &&
              selectedAddress.radius_distance_by_store_id[validStore.store_id])
          "
        >
          {{ "popups.delivery-cost-info-modal.distance" | translate }}:

          <ion-text
            *ngIf="
              !validStore.calculation_type ||
              validStore.calculation_type === 'path'
            "
          >
            <strong>
              {{
                selectedAddress.distance_by_store_id[validStore.store_id]
                  .distance | twoDecimalsRound : true
              }}
              km</strong
            >
          </ion-text>

          <ion-text
            *ngIf="
              validStore.calculation_type &&
              validStore.calculation_type === 'radius'
            "
          >
            <strong>
              {{
                selectedAddress.radius_distance_by_store_id[validStore.store_id]
              }}
              km</strong
            >
          </ion-text>
        </div>

        <div
          *ngIf="
            validStore &&
            validStore.distance_charge_type &&
            validStore.distance_charge_type === 'levels'
          "
        >
          <ion-text
            style="font-size: small"
            *ngIf="
              validStore.calculation_type &&
              validStore.calculation_type === 'radius' &&
              selectedAddress.radius_distance_by_store_id &&
              selectedAddress.radius_distance_by_store_id[validStore.store_id]
            "
          >
            <strong>
              ({{ "popups.delivery-cost-info-modal.charge-level" | translate }}:
              {{
                selectedAddress.radius_distance_by_store_id[validStore.store_id]
                  | calculateDeliveryCostDistanceChargeType
                    : (validStore.step_active && validStore.step_distance
                        ? validStore.step_distance
                        : 1000)
              }}
              km)
            </strong>
          </ion-text>

          <ion-text
            style="font-size: small"
            *ngIf="
              (!validStore.calculation_type ||
                validStore.calculation_type === 'path') &&
              selectedAddress.distance_by_store_id &&
              selectedAddress.distance_by_store_id[validStore.store_id]
            "
          >
            <strong>
              ({{ "popups.delivery-cost-info-modal.charge-level" | translate }}:
              {{
                selectedAddress.distance_by_store_id[validStore.store_id]
                  .distance
                  | calculateDeliveryCostDistanceChargeType
                    : (validStore.step_active && validStore.step_distance
                        ? validStore.step_distance
                        : 1000)
              }}
              km)
            </strong>
          </ion-text>
        </div>

        <div style="font-size: small">
          <ion-text style="font-weight: bold">
            {{ "delivery-areas.calculation" | translate }}:
          </ion-text>
          <ion-text color="dark">
            {{ validStore.deliveryCost }}€
            {{ "delivery-areas.per" | translate }}
            {{
              validStore.step_active && validStore.step_distance
                ? validStore.step_distance + " " + ("meters" | translate)
                : "km"
            }}</ion-text
          >

          <ion-text color="dark" *ngIf="validStore.charge_after_distance">
            {{ "delivery-areas.with-charge-after" | translate }}
            {{ validStore.charge_after_distance }}
            {{ "meters" | translate }}
          </ion-text>
          <ion-text color="dark" *ngIf="validStore.fixed_charge">
            {{ "delivery-areas.and-fixed-charge" | translate }}
            {{ validStore.fixed_charge }}€
          </ion-text>

          <ion-text
            *ngIf="
              validStore &&
              validStore.distance_charge_type &&
              validStore.distance_charge_type === 'levels' &&
              validStore.store_id &&
              (selectedAddress.distance_by_store_id ||
                selectedAddress.radius_distance_by_store_id)
            "
          >
            <strong>
              ({{
                "popups.delivery-cost-info-modal.charge-level-used-is"
                  | translate
              }}:
              {{
                !validStore.calculation_type ||
                validStore.calculation_type === "path"
                  ? (selectedAddress.distance_by_store_id[validStore.store_id]
                      .distance
                    | calculateDeliveryCostDistanceChargeType
                      : (validStore.step_active && validStore.step_distance
                          ? validStore.step_distance
                          : 1000))
                  : ((selectedAddress.radius_distance_by_store_id
                      ? selectedAddress.radius_distance_by_store_id[
                          validStore.store_id
                        ]
                      : selectedAddress.distance_by_store_id[
                          validStore.store_id
                        ].distance
                    )
                    | calculateDeliveryCostDistanceChargeType
                      : (validStore.step_active && validStore.step_distance
                          ? validStore.step_distance
                          : 1000))
              }}
              km)
            </strong>
          </ion-text>
        </div>
        <div
          *ngIf="
            !currentUser &&
            (!validStore.calculation_type ||
              validStore.calculation_type === 'path')
          "
        >
          <ion-text color="danger" style="font-size: small; font-weight: bold">
            {{
              "popups.delivery-cost-info-modal.distance-is-an-estimation-please-connect-to-get-real-price"
                | translate
            }}
          </ion-text>
        </div>
      </ion-label>
    </ion-item>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-grid
    *ngIf="
      currentStore &&
      currentStore.mapCenterStoreLat &&
      currentStore.mapCenterStoreLng &&
      selectedAddress &&
      selectedAddress.userLat &&
      selectedAddress.userLng &&
      (!validStore ||
        !validStore.calculation_type ||
        validStore.calculation_type === 'path')
    "
  >
    <ion-row style="width: 100%">
      <ion-col class="ion-text-center">
        <ion-button (click)="openMap()">
          {{ "popups.delivery-cost-info-modal.show-on-map" | translate }}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
