import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  AlertController,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import {
  auditTime,
  debounceTime,
  distinctUntilChanged,
  first,
  Subscription,
} from "rxjs";
import { ProductDetailsComponent } from "src/app/product-details/product-details.component";
import * as fromApp from "../../../store/app.reducer";
import * as selectors from "../../../store/app.selectors";
import * as CartActions from "../../../cart/store/cart.actions";
import * as PreviousOrdersActions from "../../../catalog/previous-order/store/previousOrders.actions";
import { TranslateService } from "@ngx-translate/core";
import { ProductDetailsOperationsService } from "src/app/product-details/product-details-operations.service";
import { currentStoreID } from "src/app/store/app.selectors";
import * as CurrentStoreActions from "../../../store/current-store/current-store.actions";
import * as DeliveryMethodAddressActions from "../../../store/deliveryMethodAddress/deliveryMethodAddress.actions";
import { Router } from "@angular/router";
import { AnalyticsServiceService } from "src/app/services/analitycsService/analytics-service.service";
import * as bluebird from "bluebird";
import moment from "moment";

@Component({
  selector: "app-previous-order-modal",
  templateUrl: "./previous-order-modal.component.html",
  styleUrls: ["./previous-order-modal.component.scss"],
})
export class PreviousOrderModalComponent implements OnInit, OnDestroy {
  @Input() previousOrderFromOrderPage: any;
  @Input() previousOrderId: any;
  public previousOrderIntitialCart: any;
  public previousOrderCart: any;
  public orderPickup: boolean;
  public groupApp: any;
  public finalPriceLoading: boolean;
  public valid = true;
  public stores: any;
  public previousOrderStore: any;
  public dineIn: any;
  public selectedLangugage: any;
  public store_info: any;
  public disableAddToCartButton: boolean;
  public selectedDineInTable: any;
  public allPreviousOrders: any;
  public selectedPreorderDate: any;
  public windowDimensions;

  private validatingCartLoading = false;
  private waitingValidatingCartForAddingToCart = false;
  private selectedAddress: any;
  private productDetailsModal: any;
  private deliveryMethodAddressSub: Subscription;
  private subscription: Subscription;
  private unsubscribeBackEvent: Subscription;
  private storesSub: Subscription;
  private groupAppSub: Subscription;
  private cartSub: Subscription;
  private storeInfoSub: Subscription;
  private windowInfoSub: Subscription;
  constructor(
    private modalController: ModalController,
    private store: Store<fromApp.AppState>,
    private toastController: ToastController,
    private translate: TranslateService,
    private productDetailsOperationsService: ProductDetailsOperationsService,
    private platform: Platform,
    private alertController: AlertController,
    private router: Router,
    private analyticsService: AnalyticsServiceService
  ) {}

  ngOnInit() {
    //console.log("previous oreder", this.previousOrderFromOrderPage);
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );

    this.windowInfoSub = this.store
      .select("windowInfo")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          !_.isEqual(state.windowDimensions, this.windowDimensions)
        ) {
          this.windowDimensions = _.cloneDeep(state.windowDimensions);
        }
      });

    this.cartSub = this.store
      .select("cart")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          !_.isEqual(state.validatingCartLoading, this.validatingCartLoading)
        ) {
          if (
            state.validatingCartLoading == false &&
            this.validatingCartLoading == true &&
            this.waitingValidatingCartForAddingToCart &&
            this.previousOrderFromOrderPage
          ) {
            this.waitingValidatingCartForAddingToCart = false;
            this.addToCart();
          }
          this.validatingCartLoading = _.cloneDeep(state.validatingCartLoading);
        }
      });

    this.groupAppSub = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.groupApp &&
          !_.isEqual(this.groupApp, state.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp);
        }
        if (
          state &&
          state.selectedLangugage &&
          !_.isEqual(this.selectedLangugage, state.selectedLangugage)
        ) {
          this.selectedLangugage = _.cloneDeep(state.selectedLangugage);
        }
      });

    this.storesSub = this.store
      .select("stores")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (state && state.stores && !_.isEqual(state.stores, this.stores)) {
          this.stores = _.cloneDeep(state.stores);
          //console.log("stores", this.stores, this.previousOrderFromOrderPage);

          if (this.previousOrderFromOrderPage) {
            this.previousOrderStore = _.cloneDeep(
              _.find(this.stores, {
                store_id: this.previousOrderFromOrderPage.store_id,
              })
            );
            // console.log("previous order store", this.previousOrderStore);
          }
        }
      });

    this.storeInfoSub = this.store
      .select(selectors.getStoreInfo)
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((store_info) => {
        if (store_info && !_.isEqual(this.store_info, store_info)) {
          this.store_info = _.cloneDeep(store_info);
        }
      });

    this.deliveryMethodAddressSub = this.store
      .select("deliveryMethodAddress")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (state && !_.isEqual(state.orderPickup, this.orderPickup)) {
          this.orderPickup = _.cloneDeep(state.orderPickup);
        }
        if (state && !_.isEqual(state.dineIn, this.dineIn)) {
          this.dineIn = _.cloneDeep(state.dineIn);
        }
        if (state && !_.isEqual(this.selectedAddress, state.selectedAddress)) {
          this.selectedAddress = _.cloneDeep(state.selectedAddress);
        }
        if (
          state &&
          !_.isEqual(this.selectedDineInTable, state.selectedDineInTable)
        ) {
          this.selectedDineInTable = _.cloneDeep(state.selectedDineInTable);
        }

        if (
          state &&
          !_.isEqual(this.selectedPreorderDate, state.selectedPreorderDate)
        ) {
          this.selectedPreorderDate = _.cloneDeep(state.selectedPreorderDate);
        }
      });

    this.subscription = this.store
      .select("previousOrders")
      .pipe(distinctUntilChanged())

      .subscribe((products) => {
        if (
          products &&
          !_.isEqual(products.previousOrders, this.allPreviousOrders)
        ) {
          this.allPreviousOrders = _.cloneDeep(products.previousOrders);
          //console.log("all previous orders", this.allPreviousOrders);
          if (
            this.previousOrderIntitialCart &&
            this.previousOrderIntitialCart.length
          ) {
            _.each(this.allPreviousOrders, (order) => {
              if (
                order &&
                order.products &&
                order.products.length &&
                this.previousOrderId === order.order_id &&
                !_.isEqual(order.products, this.previousOrderIntitialCart)
              ) {
                _.each(order.products, (orderProduct) => {
                  if (
                    orderProduct &&
                    ((orderProduct.tags &&
                      orderProduct.tags.availableForPreorder) ||
                      orderProduct.preorder_availability_stock_enabled)
                  ) {
                    const indexInPreviousOrderCart = _.findIndex(
                      this.previousOrderIntitialCart,
                      { cart_id: orderProduct.cart_id }
                    );

                    if (indexInPreviousOrderCart !== -1) {
                      _.assign(
                        this.previousOrderIntitialCart[
                          indexInPreviousOrderCart
                        ],
                        _.pick(orderProduct, [
                          "needsEdit",
                          "available",
                          "availableTotal",
                          "tags",
                          "availability_stock",
                          "availability_stock_enabled",
                          "availableInitializationActive",
                          "availableInitializationTime",
                          "preorder_availability_stock",
                          "preorder_availability_stock_enabled",
                        ])
                      );

                      orderProduct =
                        this.productDetailsOperationsService.calculateIfProductNeedsEdit(
                          _.cloneDeep(orderProduct)
                        );
                      console.log(
                        "calculate needsEdit for ",
                        orderProduct.name,
                        orderProduct.needsEdit
                      );
                    }
                  }
                });
              }
            });

            this.store.dispatch(
              new PreviousOrdersActions.SetPreviousOrderCart(
                _.cloneDeep(this.previousOrderIntitialCart)
              )
            );
          }
        }

        if (
          products &&
          !_.isEqual(products.previousOrderCart, this.previousOrderIntitialCart)
        ) {
          this.previousOrderIntitialCart = _.cloneDeep(
            products.previousOrderCart
          );

          console.log(
            "previousOrderCartpreviousOrderCart",
            this.previousOrderIntitialCart
          );

          this.store
            .select(selectors.getStoreInfo)
            .pipe(first())
            .subscribe((store_info) => {
              this.store
                .select("deliveryMethodAddress")
                .pipe(first())
                .subscribe((state) => {
                  this.initializeProducts(
                    state.orderPickup,
                    state.dineIn,
                    state.selectedPreorderDate,
                    store_info && store_info.dinein
                      ? store_info.alt_dinein_active
                      : false,
                    state.selectedDineInTable
                  );
                })
                .unsubscribe();
            })
            .unsubscribe();
          console.log("selected order for reorder", this.previousOrderCart);
        }
      });
  }

  calculateOnlyAvailableForPreorderProducts() {
    if (this.previousOrderCart && this.previousOrderCart.length) {
      this.store
        .select("deliveryMethodAddress")
        .pipe(first())
        .subscribe(async (state) => {
          if (state) {
            const allCartProducts: any =
              await this.productDetailsOperationsService.calculateonlyForPreorderForCartProducts(
                _.cloneDeep(this.previousOrderCart),
                state.selectedPreorderDate
              );

            if (allCartProducts && allCartProducts.length) {
              const onlyAvailableForPreorderProductsById = {};
              _.each(allCartProducts, (cartPrd) => {
                this.onlyAvailableForPreorderActions(
                  cartPrd,
                  onlyAvailableForPreorderProductsById
                );
              });

              this.store.dispatch(
                new CartActions.SetOnlyAvailableForPreorderCartProducts(
                  _.cloneDeep(onlyAvailableForPreorderProductsById)
                )
              );
            }
          }
        })
        .unsubscribe();
    }
  }

  onlyAvailableForPreorderActions(
    cart_product,
    onlyAvailableForPreorderProductsById
  ) {
    console.log("onlyAvailableForPreorderActions", cart_product);
    if (cart_product.onlyForPreorder) {
      onlyAvailableForPreorderProductsById[cart_product.product_id] = true;
    } else {
      onlyAvailableForPreorderProductsById[cart_product.product_id] = false;
    }
    if (cart_product && cart_product.options) {
      _.each(cart_product.options, (option) => {
        if (option.bundle && option.choices) {
          _.each(option.choices, (choice) => {
            if (
              choice &&
              choice.bundle === true &&
              choice.bundled_with_product_id &&
              choice.bundled_product
            ) {
              this.onlyAvailableForPreorderActions(
                choice.bundled_product,
                onlyAvailableForPreorderProductsById
              );
            }
          });
        }
      });
    }
  }

  selectForReorder(product) {
    console.log("selectForReorder", product);
    if (
      product.availableTotal &&
      (!product.tags || !product.tags.initializationActivePreorder)
    ) {
      product.selectedForReorder = !product.selectedForReorder;
    } else if (
      !product.availableTotal &&
      product.tags &&
      product.tags.availableForPreorder &&
      !product.hideAvailableForPreorderTag
    ) {
      this.showProductDetailsModal(product);
    } else if (
      product.tags &&
      product.tags.initializationActivePreorder &&
      (!this.selectedPreorderDate ||
        !this.selectedPreorderDate.date ||
        !this.selectedPreorderDate.hour ||
        !this.checkPreorderDateIsAfterToday() ||
        (product.preorder_availability_stock_enabled &&
          product.preorder_availability_stock <= 0))
    ) {
      this.showProductDetailsModal(product);
    }

    this.checkDisableAddToCartButton();
  }

  checkPreorderDateIsAfterToday() {
    if (
      this.selectedPreorderDate &&
      this.selectedPreorderDate.date &&
      this.selectedPreorderDate.hour
    ) {
      const preorderDate = moment(
        `${this.selectedPreorderDate.date} ${this.selectedPreorderDate.hour}`,
        "DD-MM-YYYY HH:mm"
      );

      const tomorrowDate = moment()
        .add(1, "day")
        .set("hour", 0)
        .set("minutes", 0);

      if (preorderDate.isAfter(tomorrowDate)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  trackProducts(index, product) {
    return product.product_id;
  }

  checkDisableAddToCartButton() {
    let disableAddToCart = false;
    _.each(this.previousOrderCart, (product) => {
      if (
        product.selectedForReorder &&
        (!product.availableTotal ||
          !product.category ||
          !product.product_categories_availability_check ||
          !product.activeTotal ||
          !product.product_categories_active_check)
      ) {
        disableAddToCart = true;
      }
    });

    if (disableAddToCart) {
      this.disableAddToCartButton = true;
    } else {
      this.disableAddToCartButton = false;
    }

    // console.log("disableAddToCartButton", this.disableAddToCartButton);
  }

  async initializeProducts(
    orderPickup,
    dineIn,
    selectedPreorderDate,
    alt_dinein_active,
    selectedDineInTable
  ) {
    console.log("previous order initialize products");
    this.previousOrderCart = _.cloneDeep(this.previousOrderIntitialCart);
    if (this.previousOrderCart && this.previousOrderCart.length) {
      this.previousOrderCart = await bluebird.mapSeries(
        _.cloneDeep(this.previousOrderCart),
        async (product) => {
          if (
            product.availableTotal &&
            product.category &&
            product.product_categories_availability_check &&
            product.activeTotal &&
            product.product_categories_active_check
          ) {
            product.selectedForReorder = true;
          }

          // this.disableAddToCartButton = true;
          // if (
          //   !(
          //     !!(
          //       !product.availableTotal ||
          //       !product.category ||
          //       !product.category.availableTotal
          //     ) ||
          //     !!(
          //       !product.activeTotal ||
          //       !product.category ||
          //       !product.category.activeTotal
          //     )
          //   )
          // ) {
          //   this.disableAddToCartButton = false;
          // }

          this.checkDisableAddToCartButton();

          if (product && product.options) {
            _.each(product.options, (option) => {
              this.productDetailsOperationsService.productChoicesPriceComment(
                option.option_id,
                orderPickup,
                dineIn,
                product,
                alt_dinein_active,
                selectedDineInTable
              );
            });
          }

          product = await this.checkPreorderProductsTags(_.cloneDeep(product));

          product =
            await this.productDetailsOperationsService.checkProductAvailabilityForPreorder(
              _.cloneDeep(product),
              selectedPreorderDate,
              dineIn
            );

          product =
            this.productDetailsOperationsService.calculateIfProductNeedsEdit(
              _.cloneDeep(product)
            );

          console.log(
            "calculate needsEdit for ",
            product.name,
            product.needsEdit
          );

          return Promise.resolve(product);
        }
      );
    }

    this.calculateOnlyAvailableForPreorderProducts();
    console.log("previous cart products", this.previousOrderCart);
  }

  async checkPreorderProductsTags(orderProduct) {
    return new Promise((res, rej) => {
      this.store
        .select(selectors.getStoreInfo)
        .pipe(first())
        .subscribe((store_info) => {
          bluebird.map(orderProduct.options, async (option, option_index) => {
            return bluebird.map(
              option.choices,
              async (choice, choice_index) => {
                if (
                  choice.bundle === true &&
                  !_.isEmpty(choice.bundled_product)
                ) {
                  orderProduct.options[option_index].choices[
                    choice_index
                  ].bundled_product = await this.checkPreorderProductsTags(
                    _.cloneDeep(choice.bundled_product)
                  );
                }
                Promise.resolve({});
              }
            );
          });

          if (orderProduct.hideAvailableForPreorderTag) {
            orderProduct.hideAvailableForPreorderTag = false;
          }
          if (
            orderProduct &&
            orderProduct.product_categories_availability_check &&
            orderProduct.product_categories_active_check &&
            !orderProduct.availableTotal &&
            !orderProduct.activePeriod &&
            !orderProduct.availablePeriod &&
            orderProduct.availableInitializationActive &&
            orderProduct.availableInitializationTime &&
            (!orderProduct.preorder_availability_stock_enabled ||
              orderProduct.preorder_availability_stock > 0) &&
            store_info &&
            store_info.preorderActive &&
            (((!store_info.preorderDaysStart ||
              store_info.preorderDaysStart === 0) &&
              store_info.preorderDaysAhead > 1) ||
              store_info.preorderDaysStart > 0) &&
            (!orderProduct.tags || !orderProduct.tags.availableForPreorder)
          ) {
            if (!orderProduct.tags) {
              orderProduct.tags = {};
            }
            orderProduct.tags.initializationActivePreorder = true;
            orderProduct.availableTotal = true;
          } else {
            if (
              orderProduct.tags &&
              orderProduct.tags.initializationActivePreorder
            ) {
              delete orderProduct.tags.initializationActivePreorder;
            }
          }

          res(_.cloneDeep(orderProduct));
        })
        .unsubscribe();
    });
  }

  beforeAddingToCart() {
    if (
      this.previousOrderFromOrderPage &&
      this.previousOrderFromOrderPage.order_id
    ) {
      this.store
        .select("currentStore")
        .pipe(first())
        .subscribe((state) => {
          if (
            state &&
            state.currentStoreId &&
            this.previousOrderFromOrderPage.store_id !== state.currentStoreId
          ) {
            //change store alert
            this.changeStoreAlert();
          } else {
            this.addToCart();
          }
        })
        .unsubscribe();
    }
  }

  addToCart() {
    this.valid = true;
    let foundOne = false;

    _.each(this.previousOrderCart, (product) => {
      if (product.selectedForReorder) {
        foundOne = true;
      }
      console.log(product, this.valid);
      this.valid = this.validProducts(product);
      console.log(
        "product validation add to previous orders cart",
        product,
        this.valid
      );
      if (!this.valid && foundOne) {
        //present product details modal

        this.showProductDetailsModal(product);
        return false;
      } else {
        if (product.needsEdit && product.selectedForReorder && foundOne) {
          //present product details modal
          this.showProductDetailsModal(product);
          this.valid = false;
          return false;
        }
      }
    });
    if (!this.valid || !foundOne) {
      console.log("error");
      if (!foundOne) {
        this.presentErrorToast(
          this.translate.instant(
            "catalog.previous-order.previous-order-modal.you-must-select-at-least-one"
          ),
          "danger"
        );
      }
    } else if (this.valid && foundOne) {
      // add previous-order-cart to cart
      let previousOrderCart = [];

      _.each(this.previousOrderCart, (product, key) => {
        let prd = _.cloneDeep(product);
        prd.cart_id = (Date.now() + key).toString();
        if (prd.selectedForReorder) {
          previousOrderCart.push(_.omit(prd, ["selectedForReorder"]));
        }
      });

      this.store
        .select("cart")
        .pipe(first())
        .subscribe((state) => {
          if (state && state.products && state.products.length > 0) {
            let newCart = _.unionBy(
              _.cloneDeep(state.products),
              previousOrderCart,
              "cart_id"
            );
            _.each(newCart, (product) => {
              if (
                !product.image ||
                (product.image && !product.image.true) ||
                (product.image && !product.image.true["16x9"]) ||
                (product.image &&
                  product.image.true["16x9"] &&
                  !product.image.true["16x9"].path)
              ) {
                product.noImageProduct = true;
              }
            });
            console.log(
              "previous order cart before added to cart",
              previousOrderCart
            );
            this.analyticsService.dmAnalyticsMessage(
              "add_reorder_cart",
              null,
              null,
              null,
              previousOrderCart,
              this.previousOrderId,
              null,
              null,
              null,
              null,
              null
            );
            this.store.dispatch(
              new CartActions.SetCartProducts(_.cloneDeep(newCart))
            );
            if (this.previousOrderFromOrderPage) {
              this.presentSuccessAlert();
            }
            this.modalController.dismiss();
          } else if (state) {
            _.each(previousOrderCart, (product) => {
              if (
                !product.image ||
                (product.image && !product.image.true) ||
                (product.image && !product.image.true["16x9"]) ||
                (product.image &&
                  product.image.true["16x9"] &&
                  !product.image.true["16x9"].path)
              ) {
                product.noImageProduct = true;
              }
            });
            console.log(
              "previous order cart before added to cart",
              previousOrderCart
            );
            this.analyticsService.dmAnalyticsMessage(
              "add_reorder_cart",
              null,
              null,
              null,
              previousOrderCart,
              this.previousOrderId,
              null,
              null,
              null,
              null,
              null
            );
            this.store.dispatch(
              new CartActions.SetCartProducts(_.cloneDeep(previousOrderCart))
            );
            if (this.previousOrderFromOrderPage) {
              this.presentSuccessAlert();
            }
            this.modalController.dismiss();
          }
        })
        .unsubscribe();
    }
  }

  async presentSuccessAlert() {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: this.translate.instant("success"),
      message: this.translate.instant(
        "catalog.previous-order.previous-order-modal.products-from-previous-order-added-to-cart"
      ),
      buttons: [
        {
          text: this.translate.instant("cancel"),
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {},
        },
        {
          text: this.translate.instant(
            "catalog.previous-order.previous-order-modal.navigate-to-cart"
          ),
          cssClass: "secondary",
          handler: (blah) => {
            this.router.navigateByUrl("/cart", {
              replaceUrl: true,
            });
          },
        },
        {
          text: this.translate.instant(
            "catalog.previous-order.previous-order-modal.navigate-to-catalog"
          ),
          handler: () => {
            this.router.navigateByUrl("/catalog", {
              replaceUrl: true,
            });
          },
        },
      ],
    });
    await alert.present();
  }

  changeStoreAlert() {
    this.store
      .select("cart")
      .pipe(first())
      .subscribe(async (state) => {
        if (
          state &&
          state.products &&
          state.products.length > 0 &&
          !this.selectedAddress
        ) {
          const alert = await this.alertController.create({
            cssClass: "my-custom-class",
            header: this.translate.instant("alert"),
            message: this.translate.instant(
              "catalog.previous-order.previous-order-modal.if-you-change-store-your-cart-products-will-be-deleted-do-you-want-to-continue"
            ),
            buttons: [
              {
                text: this.translate.instant("cancel"),
                role: "cancel",
                cssClass: "secondary",
                handler: (blah) => {},
              },
              {
                text: this.translate.instant("yes"),
                handler: () => {
                  this.store.dispatch(
                    new CurrentStoreActions.SetCurrentStoreId(
                      _.cloneDeep(this.previousOrderFromOrderPage.store_id)
                    )
                  );
                  this.waitingValidatingCartForAddingToCart = true;
                },
              },
            ],
          });
          await alert.present();
        } else if (
          state &&
          state.products &&
          state.products.length > 0 &&
          this.selectedAddress
        ) {
          const alert = await this.alertController.create({
            cssClass: "my-custom-class",
            header: this.translate.instant("alert"),
            message: this.translate.instant(
              "catalog.previous-order.previous-order-modal.if-you-change-store-your-cart-products-and-your-address-will-be-deleted-do-you-want-to-continue"
            ),
            buttons: [
              {
                text: this.translate.instant("cancel"),
                role: "cancel",
                cssClass: "secondary",
                handler: (blah) => {},
              },
              {
                text: this.translate.instant("yes"),
                handler: () => {
                  this.deleteSelectedAddress();

                  this.store.dispatch(
                    new CurrentStoreActions.SetCurrentStoreId(
                      _.cloneDeep(this.previousOrderFromOrderPage.store_id)
                    )
                  );
                  this.waitingValidatingCartForAddingToCart = true;
                },
              },
            ],
          });
          await alert.present();
        } else if (
          state &&
          state.products &&
          state.products.length === 0 &&
          this.selectedAddress
        ) {
          const alert = await this.alertController.create({
            cssClass: "my-custom-class",
            header: this.translate.instant("alert"),
            message: this.translate.instant(
              "catalog.previous-order.previous-order-modal.if-you-change-store-your-address-will-be-deleted-do-you-want-to-continue"
            ),
            buttons: [
              {
                text: this.translate.instant("cancel"),
                role: "cancel",
                cssClass: "secondary",
                handler: (blah) => {},
              },
              {
                text: this.translate.instant("yes"),
                handler: () => {
                  this.deleteSelectedAddress();

                  this.store.dispatch(
                    new CurrentStoreActions.SetCurrentStoreId(
                      _.cloneDeep(this.previousOrderFromOrderPage.store_id)
                    )
                  );
                  this.waitingValidatingCartForAddingToCart = true;
                },
              },
            ],
          });
          await alert.present();
        } else if (
          state &&
          (!state.products ||
            (state.products && state.products.length === 0)) &&
          !this.selectedAddress
        ) {
          this.store.dispatch(
            new CurrentStoreActions.SetCurrentStoreId(
              _.cloneDeep(this.previousOrderFromOrderPage.store_id)
            )
          );
          this.waitingValidatingCartForAddingToCart = true;
        }
      });
  }

  deleteSelectedAddress() {
    this.store.dispatch(new DeliveryMethodAddressActions.setValidStores(null));
    this.store.dispatch(
      new DeliveryMethodAddressActions.setValidDeliveryAreas(null)
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setCheckSelectedAddress(null)
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setSelectedAddress(null)
    );
    this.store.dispatch(new DeliveryMethodAddressActions.setOrderPickup(null));
    this.store.dispatch(new DeliveryMethodAddressActions.setDineIn(null));
    this.store.dispatch(
      new DeliveryMethodAddressActions.setSelectedDineInTable(_.cloneDeep(null))
    );

    if (navigator.cookieEnabled) {
      let deliveryMethodAddressObj: any = localStorage.getItem(
        "deliveryMethodAddress"
      );
      if (deliveryMethodAddressObj) {
        deliveryMethodAddressObj = JSON.parse(deliveryMethodAddressObj);
        deliveryMethodAddressObj.selectedAddress = null;
        deliveryMethodAddressObj.orderPickup = null;
        deliveryMethodAddressObj.dineIn = null;

        localStorage.setItem(
          "deliveryMethodAddress",
          JSON.stringify(deliveryMethodAddressObj)
        );
      }
    }
  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }

  showProductDetailsModal(product) {
    let detailsCssClass;
    console.log("product from show pr details mod", product);
    if (
      product &&
      product.options &&
      product.options.length < 2 &&
      product.noImageProduct
    ) {
      detailsCssClass = "productDetailsModalSmall";
    } else if (
      product &&
      product.options &&
      !product.squareImageDesign &&
      product.options.length > 2 &&
      !product.noImageProduct
    ) {
      detailsCssClass = "productDetailsModalBig";
    } else if (
      product &&
      product.squareImageDesign &&
      product.options &&
      product.options.length > 2 &&
      !product.noImageProduct &&
      this.windowDimensions &&
      this.windowDimensions.width >= 1200
    ) {
      detailsCssClass = "productDetailsModalBigSquareImageDesign";
    } else {
      detailsCssClass = "productDetailsModal";
    }
    if (!this.productDetailsModal) {
      this.productDetailsModal = true;
      this.presentProductDetailsModal(product, detailsCssClass);
    }
  }

  async presentProductDetailsModal(product, detailsCssClass) {
    this.productDetailsModal = await this.modalController.create({
      component: ProductDetailsComponent,
      cssClass: detailsCssClass,
      animated: true,
      componentProps: {
        product_id: product.product_id,
        cart_id: product.cart_id,
        order: { store_id: product.store_id },
        previousOrderMode: true,
      },
      backdropDismiss: false,
    });

    await this.productDetailsModal.present();
    this.productDetailsModal.onDidDismiss().then(() => {
      this.store
        .select(selectors.getStoreInfo)
        .pipe(first())
        .subscribe((store_info) => {
          this.store
            .select("deliveryMethodAddress")
            .pipe(first())
            .subscribe((state) => {
              this.initializeProducts(
                state.orderPickup,
                state.dineIn,
                state.selectedPreorderDate,
                store_info && store_info.dinein
                  ? store_info.alt_dinein_active
                  : false,
                state.selectedDineInTable
              );
            })
            .unsubscribe();
        });

      this.productDetailsModal = null;
    });
  }

  validProducts(product) {
    let value: boolean = true;
    if (product && product.options && product.selectedForReorder) {
      _.each(product.options, (option) => {
        if (option.is_require && option.choices) {
          let arrayWithPreselectedChoices = _.filter(
            option.choices,
            (choice) => {
              if (choice.is_preselected) {
                return choice;
              }
            }
          );
          console.log(
            "arrau with preselected choices",
            arrayWithPreselectedChoices,
            option
          );
          if (
            !arrayWithPreselectedChoices ||
            (arrayWithPreselectedChoices &&
              arrayWithPreselectedChoices.length === 0)
          ) {
            value = false;
            return false;
          } else {
            value = true;
            return false;
          }
        }
      });
      return value;
    } else {
      return true;
    }
  }

  closeModal() {
    if (this.productDetailsModal) {
      this.productDetailsModal.dismiss();
      this.productDetailsModal = null;
    }
    this.modalController.dismiss();
  }

  ngOnDestroy() {
    if (this.storeInfoSub) {
      this.storeInfoSub.unsubscribe();
    }

    if (this.groupAppSub) {
      this.groupAppSub.unsubscribe();
    }

    if (this.cartSub) {
      this.cartSub.unsubscribe();
    }
    if (this.storesSub) {
      this.storesSub.unsubscribe();
    }
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.productDetailsModal) {
      this.productDetailsModal.dismiss();
      this.productDetailsModal = null;
    }

    if (this.windowInfoSub) {
      this.windowInfoSub.unsubscribe();
    }
    this.store.dispatch(new PreviousOrdersActions.ClearPreviousOrderCart());
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.deliveryMethodAddressSub) {
      this.deliveryMethodAddressSub.unsubscribe();
    }
  }
}
