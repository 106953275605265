<span *ngIf="bundleProduct">
  <span>
    {{
      bundle_options && bundle_options.enable_bundle_product_quantity
        ? bundleProduct.quantity + " X "
        : ""
    }}
    {{ bundleProduct.name }}
  </span>
  <span *ngFor="let option of bundleProduct.options">
    <div
      style="margin-inline-start: 10px"
      *ngIf="
        option.option_id &&
        (!option.dependenciesVariable ||
          option.dependenciesVariable.length === 0 ||
          (option.dependenciesVariable.length > 0 && option.showDependent))
      "
      class="secondaryFontSize item-text-wrap"
    >
      <span
        style="font-size: smaller"
        *ngIf="
          option.type !== 'field' &&
          (!(
            option.choices &&
            !option.reverseChoices &&
            (option.choices | choicesPreselected).length === 0
          ) ||
            (option.is_require && option.availableTotal))
        "
      >
        <strong>
          {{ option.doNotPrint ? "" : option.title ? option.title + ": " : "" }}
          {{
            option.parent_free && option.option_parent_total_price === 0
              ? "(" +
                ("cart.cart-components.cart-list.cart-list-product-item.free"
                  | translate) +
                ")"
              : option.option_parent_total_price
              ? option.parent_free && bundleProduct.parent_discount
                ? option.option_parent_total_price +
                  "€ (-" +
                  bundleProduct.bundled_product.parent_discount +
                  "%)"
                : option.option_parent_total_price + "€"
              : ""
          }}
        </strong>
        <span
          *ngIf="
            option.type === 'checkbox' &&
            option.reverseChoices &&
            option.choicesComment
          "
          style="display: inline-block"
          class="mainFontSize"
          >{{ option.choicesComment }}
        </span>
        <span
          *ngFor="
            let choice of option.choices | choicesPreselected;
            let last = last
          "
        >
          <span
            style="display: inline-block"
            *ngIf="
              !choice.bundle &&
              !choice.doNotPrint &&
              (option.type !== 'checkbox' ||
                !option.reverseChoices ||
                (!option.choicesComment && !option.reverseChoices))
            "
          >
            {{ choice.printLabel ? choice.printLabel : choice.title
            }}<strong *ngIf="!choice.choicePriceComment">{{
              choice.isFree
                ? ("cart.cart-components.cart-list.cart-list-product-item.free-choice"
                  | translate)
                : !choice.price
                ? ""
                : " " +
                  (orderPickup == true && dineIn == false
                    ? choice.price_takeaway || choice.price
                    : orderPickup == false && dineIn == true
                    ? store_info.dinein &&
                      store_info.alt_dinein_active &&
                      selectedDineInTable &&
                      selectedDineInTable.alt_dinein &&
                      choice.price_alt_dinein
                      ? choice.price_alt_dinein
                      : choice.price_dinein || choice.price
                    : choice.price) +
                  "€"
            }}</strong
            ><span *ngIf="choice.choicePriceComment">{{
              choice.choicePriceComment
            }}</span
            >{{ last ? "" : "," }}&nbsp;
          </span>

          <span
            *ngIf="
              choice.bundle &&
              choice.bundled_with_product_id &&
              choice.bundled_product &&
              !choice.doNotPrint &&
              (option.type !== 'checkbox' ||
                !option.reverseChoices ||
                (!option.choicesComment && !option.reverseChoices))
            "
          >
            <app-previous-order-bundle-product-comment
              [bundle_options]="choice.bundle_options"
              [bundleProduct]="choice.bundled_product"
              [store_info]="store_info"
              [selectedDineInTable]="selectedDineInTable"
              [dineIn]="dineIn"
              [orderPickup]="orderPickup"
            ></app-previous-order-bundle-product-comment>
          </span>
        </span>
        <!-- 
                      <span
                        class="cartWarningColor"
                        ng-if="
                          option.choices &&
                          (option.choices | filter: { is_preselected: true }).length ===
                            0 &&
                          option.is_require
                        "
                      >
                          {{
                            "cart.cart-components.cart-list.cart-list-product-item.you-must-make-a-choice"
                              | translate
                          }}
                      </span> -->
      </span>

      <!--Field option-->
      <!-- <p
                      style="margin: 0 auto 0 0; font-size: smaller"
                      *ngIf="option.type === 'field' && option.text"
                    >
                      <strong>{{ option.title }}:</strong> {{ option.text }}
                    </p> -->
    </div>
  </span>
</span>
