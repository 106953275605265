<span
  *ngIf="
    !(
      groupApp &&
      groupApp.design_settings &&
      groupApp.design_settings.customHomepageButtons &&
      groupApp.design_settings.customHomepageButtons.active
    )
  "
>
  <!--Logo-->
  <span
    *ngIf="
      (!groupApp.homepage_upper_slider_active ||
        !groupApp.homeUpperSliderImages ||
        !groupApp.homeUpperSliderImages.length) &&
      (!groupApp.images_select_store_mobileorder ||
        groupApp.pointsSystem ||
        (windowDimensions && windowDimensions.width <= 700))
    "
  >
    <div
      id="homeLogo"
      [hidden]="!startAnimationLogo"
      [ngClass]="{
        'ion-text-center animate__animated animate__fadeIn': startAnimationLogo,
        'home-logo-three-buttons': three_buttons,
        'home-logo-four-buttons': four_buttons,
        'home-logo': !three_buttons && !four_buttons
      }"
      *ngIf="
        groupApp &&
        !groupApp.doNotShowHomeLogo &&
        selectedLogoFileName &&
        groupApp &&
        !groupApp.pointsSystem &&
        windowDimensions &&
        windowDimensions.height > 450
      "
    >
      <img
        alt="logo"
        style="width: 100%; height: 100%"
        alt="{{ groupApp.group }}"
        src="{{ rootDir }}{{ groupApp.group }}/logos/{{ selectedLogoFileName }}"
      />
    </div>
    <div
      class="home-logo-points ion-text-center"
      style="margin-top: 70px"
      *ngIf="
        groupApp &&
        !groupApp.doNotShowHomeLogo &&
        selectedLogoFileName &&
        groupApp &&
        groupApp.pointsSystem &&
        windowDimensions &&
        (windowDimensions.width <= 330 || windowDimensions.height <= 520)
      "
    ></div>
    <div
      style="margin-top: 150px"
      *ngIf="
        groupApp &&
        !selectedLogoFileName &&
        windowDimensions &&
        windowDimensions.height > 520
      "
    ></div>
    <div
      style="margin-top: 75px"
      *ngIf="
        groupApp &&
        !selectedLogoFileName &&
        windowDimensions &&
        windowDimensions.height <= 520
      "
    ></div>
    <div
      id="homeLogo2"
      [hidden]="!startAnimationLogo"
      [ngClass]="{
        'ion-text-center animate__animated animate__fadeIn': startAnimationLogo,
        'home-logo-three-buttons-points': three_buttons,
        'home-logo-four-buttons-points': four_buttons,
        'home-logo-points': !three_buttons && !four_buttons
      }"
      *ngIf="
        groupApp &&
        !groupApp.doNotShowHomeLogo &&
        selectedLogoFileName &&
        groupApp &&
        groupApp.pointsSystem &&
        windowDimensions &&
        windowDimensions.width > 330 &&
        windowDimensions.height > 520
      "
    >
      <img
        alt="logo"
        style="width: 100%; height: 100%"
        alt="{{ groupApp.group }}"
        src="{{ rootDir }}{{ groupApp.group }}/logos/{{ selectedLogoFileName }}"
      />
    </div>
  </span>

  <!--Logo For Store Images Sytem Images-->
  <span
    *ngIf="
      (!groupApp.homepage_upper_slider_active ||
        !groupApp.homeUpperSliderImages ||
        !groupApp.homeUpperSliderImages.length) &&
      groupApp.images_select_store_mobileorder &&
      !groupApp.pointsSystem &&
      windowDimensions &&
      windowDimensions.width > 700
    "
  >
    <div
      id="homeLogo"
      [hidden]="!startAnimationLogo"
      [class]="
        startAnimationLogo
          ? 'home-logo-image-select-store-system ion-text-center animate__animated animate__fadeIn'
          : 'home-logo-image-select-store-system '
      "
      *ngIf="
        groupApp &&
        !groupApp.doNotShowHomeLogo &&
        selectedLogoFileName &&
        groupApp &&
        !groupApp.pointsSystem &&
        windowDimensions &&
        windowDimensions.height > 800
      "
    >
      <img
        alt="logo"
        style="width: 100%; height: 100%"
        alt="{{ groupApp.group }}"
        src="{{ rootDir }}{{ groupApp.group }}/logos/{{ selectedLogoFileName }}"
      />
    </div>

    <!-- <div
     style="margin-top: 150px"
     *ngIf=" groupApp   && !selectedLogoFileName && windowDimensions && windowDimensions.height >520"
   ></div> -->
    <div
      style="margin-top: 250px"
      *ngIf="
        groupApp &&
        !selectedLogoFileName &&
        windowDimensions &&
        windowDimensions.height <= 800
      "
    ></div>
  </span>

  <div
    class="logo-blank-space-on-upper-slider"
    *ngIf="
      groupApp &&
      groupApp.pointsSystem &&
      groupApp.homepage_upper_slider_active &&
      groupApp.homeUpperSliderImages &&
      groupApp.homeUpperSliderImages.length
    "
  ></div>
</span>

<span
  *ngIf="
    groupApp &&
    groupApp.design_settings &&
    groupApp.design_settings.customHomepageButtons &&
    groupApp.design_settings.customHomepageButtons.active
  "
>
  <!--Logo-->
  <span
    *ngIf="
      (!groupApp.homepage_upper_slider_active ||
        !groupApp.homeUpperSliderImages ||
        !groupApp.homeUpperSliderImages.length) &&
      (!groupApp.images_select_store_mobileorder ||
        groupApp.pointsSystem ||
        (windowDimensions && windowDimensions.width <= 700))
    "
  >
    <div
      id="homeLogo"
      [hidden]="!startAnimationLogo"
      [ngClass]="{
        'ion-text-center animate__animated animate__fadeIn': startAnimationLogo,
        'home-logo-three-buttons': three_buttons,
        'home-logo-four-buttons': four_buttons,
        'home-logo': !three_buttons && !four_buttons
      }"
      *ngIf="
        groupApp &&
        !groupApp.doNotShowHomeLogo &&
        selectedLogoFileName &&
        groupApp &&
        !groupApp.pointsSystem &&
        windowDimensions &&
        windowDimensions.height > 450
      "
    >
      <img
        alt="logo"
        style="width: 100%; height: 100%"
        alt="{{ groupApp.group }}"
        src="{{ rootDir }}{{ groupApp.group }}/logos/{{ selectedLogoFileName }}"
      />
    </div>
    <div
      class="home-logo-points ion-text-center"
      style="margin-top: 70px"
      *ngIf="
        groupApp &&
        !groupApp.doNotShowHomeLogo &&
        selectedLogoFileName &&
        groupApp &&
        groupApp.pointsSystem &&
        windowDimensions &&
        (windowDimensions.width <= 330 || windowDimensions.height <= 520)
      "
    ></div>
    <div
      style="margin-top: 150px"
      *ngIf="
        groupApp &&
        !selectedLogoFileName &&
        windowDimensions &&
        windowDimensions.height > 520
      "
    ></div>
    <div
      style="margin-top: 75px"
      *ngIf="
        groupApp &&
        !selectedLogoFileName &&
        windowDimensions &&
        windowDimensions.height <= 520
      "
    ></div>
    <div
      id="homeLogo2"
      [hidden]="!startAnimationLogo"
      [ngClass]="{
        'ion-text-center animate__animated animate__fadeIn': startAnimationLogo,
        'home-logo-three-buttons-points': three_buttons,
        'home-logo-four-buttons-points': four_buttons,
        'home-logo-points': !three_buttons && !four_buttons
      }"
      *ngIf="
        groupApp &&
        !groupApp.doNotShowHomeLogo &&
        selectedLogoFileName &&
        groupApp &&
        groupApp.pointsSystem &&
        windowDimensions &&
        windowDimensions.width > 330 &&
        windowDimensions.height > 520
      "
    >
      <img
        alt="logo"
        style="width: 100%; height: 100%"
        alt="{{ groupApp.group }}"
        src="{{ rootDir }}{{ groupApp.group }}/logos/{{ selectedLogoFileName }}"
      />
    </div>
  </span>

  <!--Logo For Store Images Sytem Images-->
  <span
    *ngIf="
      (!groupApp.homepage_upper_slider_active ||
        !groupApp.homeUpperSliderImages ||
        !groupApp.homeUpperSliderImages.length) &&
      groupApp.images_select_store_mobileorder &&
      !groupApp.pointsSystem &&
      windowDimensions &&
      windowDimensions.width > 700
    "
  >
    <div
      id="homeLogo"
      [hidden]="!startAnimationLogo"
      [class]="
        startAnimationLogo
          ? 'home-logo-image-select-store-system ion-text-center animate__animated animate__fadeIn'
          : 'home-logo-image-select-store-system '
      "
      *ngIf="
        groupApp &&
        !groupApp.doNotShowHomeLogo &&
        selectedLogoFileName &&
        groupApp &&
        !groupApp.pointsSystem &&
        windowDimensions &&
        windowDimensions.height > 800
      "
    >
      <img
        alt="logo"
        style="width: 100%; height: 100%"
        alt="{{ groupApp.group }}"
        src="{{ rootDir }}{{ groupApp.group }}/logos/{{ selectedLogoFileName }}"
      />
    </div>

    <!-- <div
     style="margin-top: 150px"
     *ngIf=" groupApp   && !selectedLogoFileName && windowDimensions && windowDimensions.height >520"
   ></div> -->
    <div
      style="margin-top: 250px"
      *ngIf="
        groupApp &&
        !selectedLogoFileName &&
        windowDimensions &&
        windowDimensions.height <= 800
      "
    ></div>
  </span>

  <div
    class="logo-blank-space-on-upper-slider"
    *ngIf="
      groupApp &&
      groupApp.pointsSystem &&
      groupApp.homepage_upper_slider_active &&
      groupApp.homeUpperSliderImages &&
      groupApp.homeUpperSliderImages.length
    "
  ></div>
</span>
