<div
  style="width: 100%; height: 220px; margin-top: 5px"
  *ngIf="
    groupApp &&
    groupApp.homepage_upper_slider_active &&
    windowDimensions &&
    windowDimensions.height > 620 &&
    windowDimensions.width > 500
  "
>
  <ion-slides
    #slides
    (ionSlideDidChange)="swiperChange($event)"
    *ngIf="
      homeUpperSliderImages &&
      homeUpperSliderImages.length &&
      imagesArray &&
      loginState &&
      (loginState === 'no_login' ||
        (loginState === 'login_completed' &&
          customerOrders &&
          kioskCustomerOrders))
    "
    style="height: 220px; width: 100%"
    [options]="upperSliderOptions"
    pager="true"
  >
    <ion-slide
      style="
        height: 215px;
        width: 382px;
        opacity: 1;
        margin-left: 30px;
        cursor: pointer;
      "
      *ngFor="let image of homeUpperSliderImages"
      (click)="clickOnUpperSliderImage(image)"
    >
      <img
        loading="lazy"
        style="z-index: 100; border-radius: 10px"
        class="ImageDemensions"
        [src]="
          rootDir + groupApp.group + homepageUpperSliderDir + image.fileName
        "
      />
    </ion-slide>
  </ion-slides>
</div>

<div
  style="width: 100%; height: 220px"
  *ngIf="
    groupApp &&
    groupApp.homepage_upper_slider_active &&
    windowDimensions &&
    windowDimensions.height > 580 &&
    windowDimensions.width > 390 &&
    windowDimensions.width <= 500
  "
>
  <ion-slides
    #slides
    (ionSlideDidChange)="swiperChange($event)"
    *ngIf="
      homeUpperSliderImages &&
      homeUpperSliderImages.length &&
      imagesArray &&
      loginState &&
      loginState &&
      (loginState === 'no_login' ||
        (loginState === 'login_completed' &&
          customerOrders &&
          kioskCustomerOrders))
    "
    style="height: 220px; width: 100%"
    [options]="upperSliderOptionsMobileLarge"
  >
    <ion-slide
      style="height: 215px; width: 382px; opacity: 1; cursor: pointer"
      *ngFor="let image of homeUpperSliderImages"
      (click)="clickOnUpperSliderImage(image)"
    >
      <img
        loading="lazy"
        style="z-index: 100; border-radius: 10px"
        class="ImageDemensions"
        [src]="
          rootDir + groupApp.group + homepageUpperSliderDir + image.fileName
        "
      />
    </ion-slide>
  </ion-slides>
</div>

<div
  style="width: 100%; height: 215px"
  *ngIf="
    groupApp &&
    groupApp.homepage_upper_slider_active &&
    windowDimensions &&
    windowDimensions.height > 600 &&
    windowDimensions.width <= 390
  "
>
  <ion-slides
    #slides
    (ionSlideWillChange)="swiperChange($event)"
    *ngIf="
      homeUpperSliderImages &&
      homeUpperSliderImages.length &&
      imagesArray &&
      loginState &&
      (loginState === 'no_login' ||
        (loginState === 'login_completed' &&
          customerOrders &&
          kioskCustomerOrders))
    "
    style="height: 215px; width: 100%"
    [options]="upperSliderOptionsMobile"
    pager
  >
    <ion-slide
      *ngFor="let image of homeUpperSliderImages"
      style="background: url('{{rootDir + groupApp.group + homepageUpperSliderDir + image.fileName}}')  no-repeat center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      background-size: cover; z-index: 1;cursor: pointer;"
      (click)="clickOnUpperSliderImage(image)"
    >
    </ion-slide>
  </ion-slides>
</div>

<!-- TO-DO SWIPER FOR UPPER SLIDER UNCOMENT THIS FOR IONIC 7 <swiper
  style="height: 220px;  width: 100%"
  *ngIf="groupApp && groupApp.homepage_upper_slider_active && homeUpperSliderImages && homeUpperSliderImages.length && windowDimensions && windowDimensions.height>620 && windowDimensions.width>500"
  [slidesPerView]="'auto'"
  [freeMode]="true"
  [pagination]="false"
  [spaceBetween]="4"
  [autoplay]="false"
  [grabCursor]="true"
  [options]
>
  <ng-template
    style="max-width: 200px"
    class="swiperSlideHomepageUpperSlider"
    swiperSlide
    *ngFor="let image of homeUpperSliderImages"
  >
    <div (click)="clickOnUpperSliderImage(image)">
      <img
        loading="lazy"
        style="z-index: 100; border-radius: 10px"
        class="ImageDemensions"
        [src]="rootDir +  groupApp.group  + homepageUpperSliderDir + image.fileName"
      />
    </div>
  </ng-template>
</swiper> -->
