import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import { Subscription } from "rxjs";
import { auditTime, distinctUntilChanged } from "rxjs/operators";
import * as fromApp from "../../../../store/app.reducer";
import * as selectors from "../../../../store/app.selectors";

@Component({
  selector: "app-checkbox-option-open-choices",
  templateUrl: "./checkbox-option-open-choices.component.html",
  styleUrls: ["./checkbox-option-open-choices.component.scss"],
})
export class CheckboxOptionOpenChoicesComponent implements OnInit, OnDestroy {
  @Input() selectedProductId;
  @Input() option_id;
  @Input() bundleMode;
  @Input() productName: any;
  @Input() groupStoreData: any;
  public option: any;
  public store_info;
  public alt_dinein_active;
  @Input() selectedProductPromo: any;
  @Input() selectedProductCategory: any;
  @Output() optionChange = new EventEmitter();
  private optionsSubscription: Subscription;
  private storeSub: Subscription;
  constructor(private store: Store<fromApp.AppState>) {}
  selectOption() {
    if (this.optionsSubscription) {
      this.optionsSubscription.unsubscribe();
    }
    this.optionsSubscription = this.store
      .select(
        selectors.getOptionByOptionId(this.selectedProductId, this.option_id)
      )
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (!_.isEqual(this.option, state)) {
          this.option = _.cloneDeep(state);
        }
      });
  }
  ngOnInit() {
    this.selectOption();

    this.storeSub = this.store
      .select(selectors.getStoreInfo)
      .pipe(distinctUntilChanged())
      .subscribe((store_info) => {
        if (store_info && !_.isEqual(this.store_info, store_info)) {
          this.store_info = _.cloneDeep(store_info);
          if (this.store_info && this.store_info.dinein) {
            this.alt_dinein_active = this.store_info.alt_dinein_active;
          } else {
            this.alt_dinein_active = false;
          }
        }
      });

    // this.store
    //   .select("selectedProduct")
    //   .pipe(distinctUntilChanged())
    //   .subscribe((state) => {
    //     if (
    //       state &&
    //       state.selectedProduct &&
    //       !_.isEqual(this.selectedProduct, state.selectedProduct)
    //     ) {
    //       this.selectedProduct = _.cloneDeep(state.selectedProduct);
    //       if (
    //         this.selectedProduct &&
    //         this.selectedProduct.options &&
    //         this.option_id
    //       ) {
    //         let optionInx = _.findIndex(this.selectedProduct.options, {
    //           option_id: this.option_id,
    //         });
    //         if (
    //           optionInx !== -1 &&
    //           !_.isEqual(this.selectedProduct.options[optionInx], this.option)
    //         ) {
    //           this.option = _.cloneDeep(
    //             this.selectedProduct.options[optionInx]
    //           );
    //         }
    //       }
    //     }
    //   });
  }

  ngOnChanges(simpleChange: SimpleChanges) {
    if (
      simpleChange &&
      simpleChange.option_id &&
      simpleChange.option_id.currentValue !==
        simpleChange.option_id.previousValue
    ) {
      this.selectOption();
    }
  }

  optionChanged(newChoicesOption) {
    this.optionChange.emit(newChoicesOption);
  }

  ngOnDestroy() {
    if (this.optionsSubscription) {
      this.optionsSubscription.unsubscribe();
    }

    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
  }
}
