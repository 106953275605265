import { Pipe, PipeTransform } from "@angular/core";
import * as _ from "lodash";

@Pipe({
  name: "filterAddressesMigrationCustomerId",
})
export class FilterAddressesMigrationCustomerIdPipe implements PipeTransform {
  transform(addressesArray: any, migration_customer_id: string): any {
    if (!migration_customer_id) {
      return addressesArray;
    } else {
      return _.filter(addressesArray, (address) => {
        if (
          address &&
          address.migration_customer_id &&
          address.migration_customer_id.toString() ===
            migration_customer_id.toString()
        ) {
          return address;
        }
      });
    }
  }
}
