import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loyalty-coupons-slider',
  templateUrl: './loyalty-coupons-slider.component.html',
  styleUrls: ['./loyalty-coupons-slider.component.scss'],
})
export class LoyaltyCouponsSliderComponent implements OnInit {
  @Input() loyaltyCoupons: any
  constructor() { }

  ngOnInit() {}

  activateCoupon(coupon){
    console.log(coupon);
    coupon.activated_for_scan = !coupon.activated_for_scan;
  }

}
