import { Pipe, PipeTransform } from "@angular/core";
import * as _ from "lodash";

@Pipe({
  name: "kioskReward",
})
export class KioskRewardPipe implements PipeTransform {
  transform(rewards: any, type: string): any {
    return _.filter(rewards, (rew) => {
      if (type === "kiosk") {
        if (rew.kiosk) {
          return rew;
        }
      } else if (type === "mobileorder") {
        if (!rew.only_kiosk) {
          return rew;
        }
      }
    });
  }
}
