import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { debounce, debounceTime, distinctUntilChanged } from "rxjs/operators";
import * as fromApp from "../../store/app.reducer";
import * as selectors from "../../store/app.selectors";
import * as _ from "lodash";
import { Subscription } from "rxjs";

@Component({
  selector: "app-description",
  templateUrl: "./description.component.html",
  styleUrls: ["./description.component.scss"],
})
export class DescriptionComponent implements OnInit, OnDestroy {
  @Input() description: string;
  @Input() three_lines_description: boolean;
  @Input() show_option_description: any;
  @Input() fontSize: any;
  @Input() imageBrowser: boolean;
  @Input() noReadMoreButton;
  public showOptionInDescriptionJoined: boolean = false;
  @ViewChild("divContainerElement") divContainerElement: ElementRef;
  @ViewChild("descriptionElement") descElement: ElementRef;
  @ViewChild("displayedDescriptionElem") displayedDescElem: ElementRef;

  public widthOfWindow;
  private subscription: Subscription;
  public fullDescription: boolean;
  public displayReadMoreButton: boolean;

  constructor(private store: Store<fromApp.AppState>) {}

  ngOnInit() {
    //console.log("productid", this.productId);
    //console.log("show", this.show_option_description);
    if (this.show_option_description && this.show_option_description.length) {
      if (this.description) {
        const temp = _.cloneDeep(
          this.description + "<br>" + this.show_option_description.join("<br>")
        );
        this.description = temp;

        // console.log("description after joinde", this.description);
        this.showOptionInDescriptionJoined = true;
      } else {
        const temp = this.show_option_description.join("<br>");
        this.description = _.cloneDeep(temp);

        this.showOptionInDescriptionJoined = true;
      }

      //console.log("last desc", this.description);
    }
    if (
      this.descElement &&
      this.descElement.nativeElement &&
      this.descElement.nativeElement.offsetWidth
    ) {
      let descMaxWidth = _.cloneDeep(
        this.descElement.nativeElement.offsetWidth
      );

      this.calculateDescription();
    }
    this.subscription = this.store
      .select(selectors.windowWidth)
      .pipe(debounceTime(600))
      .pipe(distinctUntilChanged())
      .subscribe((width) => {
        if (this.descElement && !_.isEqual(this.widthOfWindow, width)) {
          this.widthOfWindow = _.cloneDeep(width);

          if (
            this.descElement &&
            this.descElement.nativeElement &&
            this.descElement.nativeElement.offsetWidth
          ) {
            let descMaxWidth = _.cloneDeep(
              this.descElement.nativeElement.offsetWidth
            );

            this.calculateDescription();
          }
        }
      });
  }

  calculateDescription() {
    // console.log(
    //   this.descElement.nativeElement.scrollHeight,
    //   this.descElement.nativeElement.style.offsetHeight,
    //   this.descElement.nativeElement.scrollHeight >
    //     this.descElement.nativeElement.style.offsetHeight
    // );
    if (
      this.descElement &&
      this.descElement.nativeElement &&
      this.descElement.nativeElement.scrollHeight &&
      this.descElement.nativeElement.offsetHeight &&
      this.descElement.nativeElement.scrollHeight >
        this.descElement.nativeElement.offsetHeight + 1 &&
      !this.noReadMoreButton
    ) {
      this.displayReadMoreButton = true;

      if (this.divContainerElement && this.divContainerElement.nativeElement) {
        this.divContainerElement.nativeElement.style.minHeight =
          (this.descElement.nativeElement.offsetHeight + 10).toString() + "px";
      }
    } else if (
      this.descElement &&
      this.descElement.nativeElement &&
      this.descElement.nativeElement.scrollHeight &&
      this.descElement.nativeElement.offsetHeight &&
      this.descElement.nativeElement.scrollHeight <=
        this.descElement.nativeElement.offsetHeight + 1
    ) {
      this.displayReadMoreButton = false;
    }
  }

  showFullDescription() {
    // this.displayedDescription = this.description;
    this.fullDescription = true;
    this.displayReadMoreButton = false;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
