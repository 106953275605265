import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { distinctUntilChanged, first } from "rxjs/operators";
import * as fromApp from "../../../store/app.reducer";
import * as selectors from "../../../store/app.selectors";
import * as _ from "lodash";
import * as SelectedProductActions from "../../store/selectedProduct.actions";
import { ProductDetailsOperationsService } from "../../product-details-operations.service";
import { Subject, Subscription } from "rxjs";
import * as OptionStepperActions from "../option-stepper/store/optionStepper.actions";
import { GestureController, Platform } from "@ionic/angular";

@Component({
  selector: "app-option-stepper",
  templateUrl: "./option-stepper.component.html",
  styleUrls: ["./option-stepper.component.scss"],
})
export class OptionStepperComponent implements OnInit, OnDestroy {
  @Input() selectedProductId: any;
  @Input() bundleMode: any;
  @Input() parentBundleChoice: any;
  @Input() swipeMoveEventSubject: Subject<any>;
  @Input() selectedProductFromParent: any;
  @Input() lastStepValid: boolean;
  @Input() groupStoreData: any;
  do_not_show_comments_option;
  public selectedProduct: any;
  public must_select_something: any;
  public selectedOptionId: any;
  public allOptionSteps: any;
  public direction: string = "";
  private subjectEventValue: any;
  private isIos: boolean;
  private subscription: Subscription;
  private optionStepperSub: Subscription;
  private subjectSubscription: Subscription;

  constructor(
    private store: Store<fromApp.AppState>,
    private productDetailsOperationsService: ProductDetailsOperationsService,
    private changeDetector: ChangeDetectorRef,
    private gestureController: GestureController,
    private platform: Platform
  ) {}

  onMoveEnd(ev) {
    console.log("moved end", ev);
    this.direction = null;

    if (ev && ev.type === "pan") {
      if (this.isIos) {
        this.direction =
          Math.abs(ev.deltaX) > 20 ? (ev.deltaX > 0 ? "Right" : "Left") : "";
      } else {
        if (Math.abs(ev.velocityX) > 0.1) {
          this.direction =
            Math.abs(ev.deltaX) > 25 ? (ev.deltaX > 0 ? "Right" : "Left") : "";
        }
      }
    }

    if (
      this.selectedOptionId &&
      this.direction === "Left" &&
      this.allOptionSteps
    ) {
      let index = _.indexOf(this.allOptionSteps, this.selectedOptionId);
      console.log("index", index);
      if (index !== -1 && this.allOptionSteps[index + 1]) {
        this.store.dispatch(
          new OptionStepperActions.SetSelectedStepOptionId({
            selectedProductId: this.selectedProductId,
            selectedStepOptionId: _.cloneDeep(this.allOptionSteps[index + 1]),
          })
        );

        this.changeDetector.detectChanges();
      }
    } else if (
      this.selectedOptionId &&
      this.direction === "Right" &&
      this.allOptionSteps
    ) {
      let index = _.indexOf(this.allOptionSteps, this.selectedOptionId);
      console.log("index", index);
      if (index !== -1 && this.allOptionSteps[index - 1]) {
        this.store.dispatch(
          new OptionStepperActions.SetSelectedStepOptionId({
            selectedProductId: this.selectedProductId,
            selectedStepOptionId: _.cloneDeep(this.allOptionSteps[index - 1]),
          })
        );
      }

      this.changeDetector.detectChanges();
    }
  }

  ngOnInit() {
    this.subjectSubscription = this.swipeMoveEventSubject.subscribe(
      (subjectEventValue) => {
        if (
          subjectEventValue &&
          !_.isEqual(this.subjectEventValue, subjectEventValue)
        ) {
          this.onMoveEnd(subjectEventValue);
        }
      }
    );
    if (this.platform && this.platform.is("ios")) {
      this.isIos = true;
    }
    if (this.selectedProductFromParent) {
      this.selectedProduct = _.cloneDeep(this.selectedProductFromParent);
      this.calculateIfShowFieldOption();
      this.must_select_something = this.selectedProduct.must_select_something;
      this.checkIsDependentOptions();
      this.store
        .select(selectors.getStoreInfo)
        .pipe(first())
        .subscribe((store_info) => {
          this.store
            .select("deliveryMethodAddress")
            .pipe(first())
            .subscribe((state) => {
              this.selectedProduct =
                this.productDetailsOperationsService.cartProductPrice(
                  this.selectedProduct,
                  state.orderPickup,
                  state.dineIn,
                  this.parentBundleChoice && this.bundleMode
                    ? _.cloneDeep(this.parentBundleChoice)
                    : null,
                  null,
                  store_info && store_info.dinein
                    ? store_info.alt_dinein_active
                    : false,
                  state.selectedDineInTable,
                  store_info.decimalPlacesPrices
                );

              this.calculateIfShowFieldOption();
            })
            .unsubscribe();
        });

      this.writeProductToState();
      this.writeAllStepsToState();
      this.store.dispatch(
        new OptionStepperActions.SetSelectedStepOptionId({
          selectedProductId: this.selectedProductId,
          selectedStepOptionId: _.cloneDeep("not_set"),
        })
      );
    }
    this.subscription = this.store
      .select(
        selectors.getSelectedProductBySelectedProductId(this.selectedProductId)
      )
      .pipe(distinctUntilChanged())
      //.pipe(auditTime(200))
      .subscribe((selectedProduct) => {
        if (
          selectedProduct &&
          selectedProduct.quantity &&
          !_.isEqual(this.selectedProduct.quantity, selectedProduct.quantity)
        ) {
          this.selectedProduct = _.cloneDeep(selectedProduct);
          this.calculateIfShowFieldOption();
          this.store
            .select(selectors.getStoreInfo)
            .pipe(first())
            .subscribe((store_info) => {
              this.store
                .select("deliveryMethodAddress")
                .pipe(first())
                .subscribe((state) => {
                  this.selectedProduct =
                    this.productDetailsOperationsService.cartProductPrice(
                      this.selectedProduct,
                      state.orderPickup,
                      state.dineIn,
                      this.parentBundleChoice && this.bundleMode
                        ? _.cloneDeep(this.parentBundleChoice)
                        : null,
                      null,
                      store_info && store_info.dinein
                        ? store_info.alt_dinein_active
                        : false,
                      state.selectedDineInTable,
                      store_info.decimalPlacesPrices
                    );

                  this.calculateIfShowFieldOption();
                })
                .unsubscribe();
            })
            .unsubscribe();
        }
      });

    this.optionStepperSub = this.store
      .select("optionStepper")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.selectedProductOptionStepsBySelectedProductId &&
          state.selectedProductOptionStepsBySelectedProductId[
            this.selectedProductId
          ] &&
          state.selectedProductOptionStepsBySelectedProductId[
            this.selectedProductId
          ].selectedStepOptionId !== "not_set" &&
          !_.isEqual(
            this.selectedOptionId,
            state.selectedProductOptionStepsBySelectedProductId[
              this.selectedProductId
            ].selectedStepOptionId
          )
        ) {
          this.selectedOptionId = _.cloneDeep(
            state.selectedProductOptionStepsBySelectedProductId[
              this.selectedProductId
            ].selectedStepOptionId
          );
        } else if (
          state &&
          state.selectedProductOptionStepsBySelectedProductId &&
          state.selectedProductOptionStepsBySelectedProductId[
            this.selectedProductId
          ] &&
          state.selectedProductOptionStepsBySelectedProductId[
            this.selectedProductId
          ].selectedStepOptionId &&
          state.selectedProductOptionStepsBySelectedProductId[
            this.selectedProductId
          ].selectedStepOptionId === "not_set" &&
          this.selectedProduct &&
          this.selectedProduct.options &&
          this.selectedProduct.options.length
        ) {
          const firstOption = _.find(this.selectedProduct.options, (option) => {
            if (
              option &&
              option.option_id &&
              (!option.dependenciesVariable ||
                option.dependenciesVariable.length === 0 ||
                (option.dependenciesVariable.length > 0 &&
                  !option.dependentHidden) ||
                (option.dependenciesVariable.length > 0 &&
                  option.dependentHidden &&
                  option.showDependent))
            ) {
              console.log("option id in initialization", option.option_id);

              return option;
            }
          });

          this.store.dispatch(
            new OptionStepperActions.SetSelectedStepOptionId({
              selectedProductId: this.selectedProductId,
              selectedStepOptionId: _.cloneDeep(firstOption.option_id),
            })
          );
        }
        if (
          state &&
          state.selectedProductOptionStepsBySelectedProductId &&
          state.selectedProductOptionStepsBySelectedProductId[
            this.selectedProductId
          ] &&
          state.selectedProductOptionStepsBySelectedProductId[
            this.selectedProductId
          ].optionsSteps &&
          !_.isEqual(
            state.selectedProductOptionStepsBySelectedProductId[
              this.selectedProductId
            ].optionsSteps,
            this.allOptionSteps
          )
        ) {
          this.allOptionSteps = _.cloneDeep(
            state.selectedProductOptionStepsBySelectedProductId[
              this.selectedProductId
            ].optionsSteps
          );
          console.log("all option steps", this.allOptionSteps);
        }
      });
  }

  // onSwipe(ev) {
  //   //console.log("swipe", ev);
  //   const x =
  //     Math.abs(ev.deltaX) > 40 ? (ev.deltaX > 0 ? "Right" : "Left") : "";
  //   const y = Math.abs(ev.deltaY) > 40 ? (ev.deltaY > 0 ? "Down" : "Up") : "";
  //   this.direction = x || y;
  //   console.log("direction", this.direction);
  //   if (
  //     this.selectedOptionId &&
  //     this.direction === "Left" &&
  //     this.allOptionSteps
  //   ) {
  //     let index = _.indexOf(this.allOptionSteps, this.selectedOptionId);
  //     console.log("index", index);
  //     if (index !== -1 && this.allOptionSteps[index + 1]) {
  //       this.store.dispatch(
  //         new OptionStepperActions.SetSelectedStepOptionId(
  //           _.cloneDeep(this.allOptionSteps[index + 1])
  //         )
  //       );
  //       this.changeDetector.detectChanges();
  //     }
  //   } else if (
  //     this.selectedOptionId &&
  //     this.direction === "Right" &&
  //     this.allOptionSteps
  //   ) {
  //     let index = _.indexOf(this.allOptionSteps, this.selectedOptionId);
  //     console.log("index", index);
  //     if (index !== -1 && this.allOptionSteps[index - 1]) {
  //       this.store.dispatch(
  //         new OptionStepperActions.SetSelectedStepOptionId(
  //           _.cloneDeep(this.allOptionSteps[index - 1])
  //         )
  //       );
  //       this.changeDetector.detectChanges();
  //     }
  //   }
  // }

  writeAllStepsToState() {
    let optionStepsArr = [];
    _.each(this.selectedProduct.options, (option) => {
      if (
        option &&
        option.option_id &&
        (!option.dependenciesVariable ||
          option.dependenciesVariable.length === 0 ||
          (option.dependenciesVariable.length > 0 && !option.dependentHidden) ||
          (option.dependenciesVariable.length > 0 &&
            option.dependentHidden &&
            option.showDependent))
      ) {
        optionStepsArr.push(option.option_id);
      }
    });
    this.store.dispatch(
      new OptionStepperActions.SetOptionsSteps({
        selectedProductId: this.selectedProductId,
        optionsSteps: _.cloneDeep(optionStepsArr),
      })
    );
  }

  selectedOptionChanged(ev) {
    console.log("select", this.selectedOptionId);

    this.store.dispatch(
      new OptionStepperActions.SetSelectedStepOptionId({
        selectedProductId: this.selectedProductId,
        selectedStepOptionId: ev.target.value,
      })
    );

    this.changeDetector.detectChanges();
  }

  trackFnc(index, option) {
    return option.product_id_option_id;
  }

  calculateIfShowFieldOption() {
    this.store
      .select(selectors.getStoreInfo)
      .pipe(first())
      .subscribe((storeInfo) => {
        if (storeInfo && storeInfo.bridge_split_bundle_products) {
          let onlyBundleOptionsProduct = true;

          if (
            !this.selectedProduct.options ||
            (this.selectedProduct.options &&
              this.selectedProduct.options.length === 1)
          ) {
            onlyBundleOptionsProduct = false;
          }

          _.each(this.selectedProduct.options, (opt) => {
            if (!opt.bundle && !(opt.default || opt.template === "comments")) {
              onlyBundleOptionsProduct = false;
            }
          });
          if (onlyBundleOptionsProduct) {
            this.do_not_show_comments_option = true;
          } else {
            if (
              this.selectedProduct &&
              this.selectedProduct.hideCommentsOption
            ) {
              this.do_not_show_comments_option = true;
            } else {
              this.do_not_show_comments_option = false;
            }
          }
        } else if (
          this.selectedProduct &&
          this.selectedProduct.hideCommentsOption
        ) {
          this.do_not_show_comments_option = true;
        } else {
          this.do_not_show_comments_option = false;
        }
      });
  }

  checkIsDependentOptions() {
    console.log("checkIsDependentOptions");

    if (!this.selectedProduct) {
      return;
    }

    this.selectedProduct.options = _.filter(
      this.selectedProduct.options,
      (opt) => {
        return _.isObject(opt);
      }
    );

    _.each(this.selectedProduct.options, (option, o, l) => {
      this.selectedProduct.options[o].dependenciesVariable = [];
    });

    _.each(this.selectedProduct.options, (option, o, l) => {
      _.each(this.selectedProduct.options[o].choices, (choice, c, list) => {
        if (!_.isEmpty(choice.dependentOptions)) {
          console.log(choice.title, choice.dependentOptions);

          _.each(choice.dependentOptions, (dependentOption, k, lo) => {
            console.log("dependentOption.option_id", dependentOption.option_id);
            var index = _.findIndex(this.selectedProduct.options, {
              option_id: dependentOption.option_id,
            });
            if (index !== -1) {
              this.selectedProduct.options[index].dependenciesVariable.push({
                when_is_preselected: true,
                option_id: option.option_id,
                optionTitle: option.title,
                choiceTitle: choice.title,
                choice_id: choice.choice_id,
                option_id_choice_id: choice.option_id_choice_id,
              });
            }
          });
        }

        if (!_.isEmpty(choice.dependenciesWhenNotPreselected)) {
          console.log(
            choice.title,
            "not",
            choice.dependenciesWhenNotPreselected
          );

          _.each(
            choice.dependenciesWhenNotPreselected,
            (dependentOption, k, lo) => {
              var index = _.findIndex(this.selectedProduct.options, {
                option_id: dependentOption.option_id,
              });
              if (index !== -1) {
                this.selectedProduct.options[index].dependenciesVariable.push({
                  when_is_preselected: false,
                  option_id: option.option_id,
                  optionTitle: option.title,
                  choiceTitle: choice.title,
                  choice_id: choice.choice_id,
                  option_id_choice_id: choice.option_id_choice_id,
                });
              }
            }
          );
        }
      });
    });
    this.hideShowDependentOptions();
  }

  checkIfAnyChoiceDependencies(dependenciesVariable) {
    var found = false;

    _.each(this.selectedProduct.options, (option, o, q) => {
      if (
        this.selectedProduct.options &&
        this.selectedProduct.options[o] &&
        (_.isEmpty(this.selectedProduct.options[o].dependenciesVariable) ||
          this.selectedProduct.options[o].showDependent) &&
        this.selectedProduct.options[o].activeTotal &&
        this.selectedProduct.options[o].availableTotal
      ) {
        _.each(this.selectedProduct.options[o].choices, (choice, c, w) => {
          if (choice.activeTotal && choice.availableTotal) {
            var foundChoice = _.filter(dependenciesVariable, {
              when_is_preselected: !!choice.is_preselected,
              option_id: option.option_id,
              choice_id: choice.choice_id,
            });

            if (foundChoice.length > 0) {
              found = true;
            }
          }
        });
      }
    });
    dependenciesVariable = null;
    return found;
  }

  hideShowDependentOptions() {
    console.log("hideShowDependentOptions");

    var optionsThatAreDependent = _.filter(
      this.selectedProduct.options,
      (option) => {
        return (
          option &&
          typeof option === "object" &&
          option.dependenciesVariable &&
          option.dependenciesVariable.length > 0
        );
      }
    );

    _.each(optionsThatAreDependent, (option, key, list) => {
      this.selectedProduct.options[
        _.findIndex(this.selectedProduct.options, {
          option_id: option.option_id,
        })
      ].showDependent = this.checkIfAnyChoiceDependencies(
        option.dependenciesVariable
      );
    });
    optionsThatAreDependent = null;
  }

  selectedProductChanged(ChangedOption) {
    console.log("changed", ChangedOption);
    if (this.selectedProduct && this.selectedProduct.options) {
      let optIndex = _.findIndex(this.selectedProduct.options, {
        option_id: ChangedOption.option_id,
      });
      if (optIndex !== -1) {
        this.selectedProduct.options[optIndex] = _.cloneDeep(ChangedOption);
        this.checkIsDependentOptions();
        this.writeAllStepsToState();
        this.store
          .select(selectors.getStoreInfo)
          .pipe(first())
          .subscribe((store_info) => {
            this.store
              .select("deliveryMethodAddress")
              .pipe(first())
              .subscribe((state) => {
                this.selectedProduct =
                  this.productDetailsOperationsService.cartProductPrice(
                    this.selectedProduct,
                    state.orderPickup,
                    state.dineIn,
                    this.parentBundleChoice && this.bundleMode
                      ? _.cloneDeep(this.parentBundleChoice)
                      : null,
                    null,
                    store_info && store_info.dinein
                      ? store_info.alt_dinein_active
                      : false,
                    state.selectedDineInTable,
                    store_info.decimalPlacesPrices
                  );

                this.calculateIfShowFieldOption();
              })
              .unsubscribe();
          })
          .unsubscribe();

        this.writeProductToStateDebounce();
      } else {
        console.log("something went wrong");
      }
    }
  }

  writeProductToState() {
    console.log("set selectedProduct");
    this.store.dispatch(
      new SelectedProductActions.SetSelectedProduct(
        _.cloneDeep(this.selectedProduct)
      )
    );
  }

  //Debounce for writing to state we also have debounce on check-box-choices and radio-choices component for every option
  // You must to sychronize these two debounces to avoid bugs when you change a choice (the other debounce is 600)
  writeProductToStateDebounce = _.debounce(() => {
    this.writeProductToState();
  }, 20);

  ionViewDidLeave() {}

  ngOnDestroy() {
    if (this.subjectSubscription) {
      this.subjectSubscription.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.optionStepperSub) {
      this.optionStepperSub.unsubscribe();
    }
  }
}
