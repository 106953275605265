import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ModalController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { auditTime, distinctUntilChanged, Subscription } from "rxjs";
import { LoyaltyPurchaseHistoryComponent } from "./loyalty-purchase-history/loyalty-purchase-history.component";
import * as fromApp from "../../store/app.reducer";
import * as _ from "lodash";
import { HallOfFameComponent } from "./hall-of-fame/hall-of-fame.component";
import { LoginPopupsServiceService } from "src/app/services/login-popups-service/login-popups-service.service";
import { AnalyticsServiceService } from "src/app/services/analitycsService/analytics-service.service";
import { AnimationOptions } from "ngx-lottie";
import { MigratePointsComponent } from "./migrate-points/migrate-points.component";
import { Capacitor } from "@capacitor/core";
import { SelectStoreModalComponent } from "../loyalty-home/select-store-modal/select-store-modal.component";
import * as CurrentStoreActions from "../../store/current-store/current-store.actions";

declare let window;
@Component({
  selector: "app-loyalty-more",
  templateUrl: "./loyalty-more.page.html",
  styleUrls: ["./loyalty-more.page.scss"],
})
export class LoyaltyMorePage implements OnInit, OnDestroy {
  public morePageAnimationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/user.json",
  };
  private unsubscribeBackEvent: Subscription;
  private subscriptions: Subscription[] = [];

  windowDimensions: any;
  bigScreenStyle: boolean;
  currentUser: any;
  loginState: any;
  newCustomer: any;
  groupApp: any;
  morePageAnimation: any;
  mo_token: any;
  loyaltyProductRules: any;
  activeloyaltyProductRules: any;
  selectedStoreId: string;
  stores: any;

  constructor(
    private store: Store<fromApp.AppState>,
    private modalController: ModalController,
    private platform: Platform,
    private router: Router,
    private loginPopupService: LoginPopupsServiceService,
    private analyticsService: AnalyticsServiceService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.windowDimensions &&
            !_.isEqual(this.windowDimensions, state.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
            if (this.windowDimensions && this.windowDimensions.width) {
              let width = this.windowDimensions.width;
              if (width > 1000) {
                this.bigScreenStyle = true;
              } else if (width >= 600 && width <= 1000) {
                this.bigScreenStyle = false;
              } else if (width < 600) {
                this.bigScreenStyle = false;
              }
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(200))
        .subscribe((state) => {
          if (state && state.user && !_.isEqual(this.currentUser, state.user)) {
            this.currentUser = _.cloneDeep(state.user);
          }
          if (state && !_.isEqual(state.loginState, this.loginState)) {
            this.loginState = _.cloneDeep(state.loginState);
          }
          if (state && !_.isEqual(state.newCustomer, this.newCustomer)) {
            this.newCustomer = _.cloneDeep(state.newCustomer);
          }
          if (state && !_.isEqual(this.mo_token, state.mo_token)) {
            this.mo_token = _.cloneDeep(state.mo_token);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(200))
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(this.groupApp, state.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
            this.loyaltyProductRules =
              this.groupApp?.loyalty_products?.coupon_rules;
            if (this.loyaltyProductRules) {
              this.activeloyaltyProductRules = _.find(
                this.loyaltyProductRules,
                { active: true }
              );
            }
          }
        })
    );

    this.subscriptions.push(
      this.store.select("currentStore").subscribe((currrentStore) => {
        if (
          currrentStore &&
          currrentStore.currentStoreId &&
          !_.isEqual(this.selectedStoreId, currrentStore.currentStoreId)
        ) {
          this.selectedStoreId = _.cloneDeep(currrentStore.currentStoreId);
        }
      })
    );

    this.subscriptions.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (state && state.stores && !_.isEqual(this.stores, state.stores)) {
            this.stores = _.cloneDeep(state.stores);
          }
        })
    );
  }

  ionViewDidEnter() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      100,
      () => {
        this.navigateToLoyaltyHome();
      }
    );
  }

  async openStoreSelectionModal() {
    const modal = await this.modalController.create({
      component: SelectStoreModalComponent,
      componentProps: {
        stores: this.stores,
        selectedStoreId: this.selectedStoreId,
      },
      backdropDismiss: false,
    });

    await modal.present();

    modal.onDidDismiss().then((data) => {
      if (data && data.data) {
        console.log(data);
        this.store.dispatch(
          new CurrentStoreActions.SetCurrentStoreId(data.data)
        );
      }
    });
  }

  async openPurchaseHistory() {
    const modal = await this.modalController.create({
      component: LoyaltyPurchaseHistoryComponent,
      backdropDismiss: false,
    });

    await modal.present();
  }

  async openHallOfFameModal() {
    const modal = await this.modalController.create({
      component: HallOfFameComponent,
      backdropDismiss: false,
    });

    await modal.present();
  }

  openMyAccountPage() {}

  morePageAnimationCreated(ev) {
    this.morePageAnimation = ev;
    this.morePageAnimation.setSpeed(1.2);
  }

  pageChange(route) {
    this.router.navigateByUrl("/" + route, {
      replaceUrl: true,
    });
  }

  navigateToLoyaltyHome() {
    if (this.platform.is("android")) {
      this.router.navigateByUrl("/loyaltySystem/loyalty-home", {
        replaceUrl: true,
      });
    }
  }

  async presentMagicLoginPopup() {
    this.analyticsService.dmAnalyticsMessage(
      "login_button",
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
    // this.MagicLoginservice.presentLoginModal(this.newCustomer);
    if (!Capacitor.isNativePlatform()) {
      this.loginPopupService.presentFirebaseModalLogin(null, null);
    } else {
      this.loginPopupService.closeMobileMagicLoginAlert();
      this.loginPopupService.presentMobileMagicLoginAlert(
        this.newCustomer,
        this.groupApp
      );
    }
  }

  async openMigratePointsModal() {
    const modal = await this.modalController.create({
      component: MigratePointsComponent,
      cssClass: "migratePointsModal",
      backdropDismiss: false,
    });

    await modal.present();
  }

  ionViewDidLeave() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    this.subscriptions = [];
  }
}
