<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">
      <ion-text>
        {{ "popups.no-location-dine-in-modal.title" | translate }}
      </ion-text>
    </ion-title>
    <ion-button
      (click)="closeModal()"
      size="small"
      slot="end"
      fill="clear"
      color="dark"
    >
      <ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid class="ion-text-center ion-no-padding" style="max-width: 150px">
    <ng-lottie
      (animationCreated)="animationCreated($event)"
      [options]="options"
    ></ng-lottie>
  </ion-grid>
  <ion-grid *ngIf="message">
    <ion-item lines="none">
      <ion-label
        class="ion-text-wrap ion-text-center"
        style="font-weight: bold"
        >{{ message | translate }}</ion-label
      >
    </ion-item>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-grid>
    <ion-row style="width: 100%">
      <ion-col class="ion-text-center">
        <ion-button [disabled]="disableButton" (click)="checkLocationAgain()">{{
          "popups.no-location-dine-in-modal.locate-again" | translate
        }}</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
