<ion-header
  *ngIf="windowInfo&& groupApp&&!groupApp.active_reservation_app"
  [hidden]="windowInfo && windowInfo.width && menuMinWidthToShow &&  windowInfo.width> menuMinWidthToShow"
>
  <app-no-internet-connection></app-no-internet-connection>
  <app-ionic-update-error></app-ionic-update-error>
  <ion-toolbar>
    <ion-title>{{"pages.store-schedule" | translate}}</ion-title>
    <ion-buttons
      slot="start"
      *ngIf="groupApp && windowInfo"
    >
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-header *ngIf="groupApp&&groupApp.active_reservation_app">
  <app-no-internet-connection></app-no-internet-connection>
  <app-ionic-update-error></app-ionic-update-error>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="primary" (click)="modalCtrl.dismiss()">
        <ion-icon name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{'reservations.information' | translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="windowInfo">
  <ion-grid
    [class]="windowInfo && windowInfo.width && menuMinWidthToShow &&  windowInfo.width< menuMinWidthToShow ? '': 'ion-padding'"
  >
    <ion-row *ngIf="!allSchedulesArray">
      <ion-col class="ion-text-center">
        <ion-spinner></ion-spinner>
      </ion-col>
    </ion-row>
    <span *ngIf="allSchedulesArray && selectedLangugage && storeInfo">
      <div
        *ngIf="allSchedulesArray.length>1 && storeInfo && !storeInfo.virtual_store_change_hyper_categories_buttons&&
        groupApp.store_change_catalog_buttons &&
        stores &&
        stores.length > 1 

      "
        style="z-index: 10000; width: 100%"
      >
        <ion-grid style="max-width: 340px">
          <ion-segment
            mode="ios"
            [(ngModel)]="selectedStoreId"
            (ngModelChange)="selectedStoreChangedFromSegment($event)"
          >
            <ion-segment-button
              [ngClass]="{selectedSegment: store &&  selectedStoreId && store.store_id === selectedStoreId, selectedSegmentMinHeight: store.store_change_catalog_buttons_icon}"
              [value]="store.store_id"
              *ngFor="let store of stores"
            >
              <ion-icon
                style="font-size: 25px"
                *ngIf="store.store_change_catalog_buttons_icon"
                [src]="deliveryManagerPath +store.store_id + '/' + 'storeChangeCatalogButtonsIcons/' +  store.store_change_catalog_buttons_icon"
              >
              </ion-icon>

              <ion-text style="font-size: small; font-weight: bold">
                {{ store.store_name_catalog_change_langs &&
                store.store_name_catalog_change_langs[selectedLangugage] ?
                store.store_name_catalog_change_langs[selectedLangugage] :
                store.store_name_catalog_change_langs &&
                store.store_name_catalog_change_langs.el ?
                store.store_name_catalog_change_langs.el :
                store.store_name_mobileorder &&
                store.store_name_mobileorder[selectedLangugage] ?
                store.store_name_mobileorder[selectedLangugage] :
                store.store_name_langs &&
                store.store_name_langs[selectedLangugage] ?
                store.store_name_langs[selectedLangugage] :
                store.store_name_langs && store.store_name_langs.el ?
                store.store_name_langs.el : store.store_name ? store.store_name
                : "" }}</ion-text
              >
            </ion-segment-button>
          </ion-segment>
        </ion-grid>
      </div>

      <ion-item
        color="primary"
        style="margin-bottom: 10px"
        *ngIf="allSchedulesArray.length>1 && !(allSchedulesArray.length>1 && storeInfo && !storeInfo.virtual_store_change_hyper_categories_buttons&&
        groupApp.store_change_catalog_buttons &&
        stores &&
        stores.length > 1 )"
      >
        <ion-label class="ion-text-wrap"
          >{{'store-schedule.select-store' | translate}}</ion-label
        >
        <ion-select
          (ionChange)="toggleStoreSchedule()"
          [(ngModel)]="selectedStoreId"
          [cancelText]="'cancel' | translate"
        >
          <ion-select-option
            *ngFor="let store of allSchedulesArray"
            [value]="store.store_id"
          >
            {{ store.store_name_mobileorder &&
            store.store_name_mobileorder[selectedLangugage] ?
            store.store_name_mobileorder[selectedLangugage] :
            (store.store_panel_name_langs &&
            store.store_panel_name_langs[selectedLangugage] ?
            store.store_panel_name_langs[selectedLangugage] :
            store.store_panel_name_langs && store.store_panel_name_langs.el ?
            store.store_panel_name_langs.el : store.store_name_panel ?
            store.store_name_panel : store.store_name ? store.store_name : "")
            }}
          </ion-select-option>
        </ion-select>
      </ion-item>
      <div *ngFor="let store of allSchedulesArray">
        <ion-row *ngIf="store && store.loading">
          <ion-col class="ion-text-center">
            <ion-spinner></ion-spinner>
          </ion-col>
        </ion-row>

        <ion-list *ngIf="store && store.isExpanded && !store.loading">
          <ion-item
            lines="none"
            *ngIf="store.storeStatus.schedule_comments&&groupApp&&!groupApp.active_reservation_app"
          >
            <ion-label
              style="font-weight: bold"
              class="ion-text-wrap ion-text-center"
            >
              <div [innerHTML]="store.storeStatus.schedule_comments"></div
            ></ion-label>
          </ion-item>
          <ion-item
            *ngIf="store.storeStatus.schedule_comments && groupApp&& groupApp.active_reservation_app"
          >
            <ion-text style="font-size: medium; font-weight: bold"
              >{{"reservation-app.timeSchedule" |translate}}</ion-text
            >
          </ion-item>
          <ion-grid
            style="max-width: 400px"
            *ngIf="groupApp&&!groupApp.active_reservation_app"
          >
            <ion-segment
              *ngIf="store && atLeastTwoOrderTypes && (store.pickupScheduleActive || store.dineinScheduleActive)"
              mode="ios"
              [(ngModel)]="segment"
              (ngModelChange)="changeSelectedSchedule($event)"
            >
              <ion-segment-button
                value="delivery"
                *ngIf="!store.disableOrderDelivery"
                >Delivery</ion-segment-button
              >
              <ion-segment-button
                value="takeaway"
                *ngIf="!store.disableOrderPickup"
                >Take Away</ion-segment-button
              >
              <ion-segment-button value="dinein" *ngIf="store.dinein"
                >Dine In</ion-segment-button
              >
            </ion-segment>
          </ion-grid>

          <ion-row
            style="width: 100%"
            *ngIf="store && !atLeastTwoOrderTypes && singleOrderType"
          >
            <ion-col size="12" class="ion-text-center">
              <ion-label
                style="font-weight: bold; font-size: medium"
                class="ion-text-wrap"
              >
                <ion-text *ngIf="singleOrderType==='delivery'">
                  {{"store-schedule.delivery" | translate}}</ion-text
                >
                <ion-text *ngIf="singleOrderType==='takeaway'">
                  {{"store-schedule.take-away" | translate}}</ion-text
                >
                <ion-text *ngIf="singleOrderType==='dinein'">
                  {{"store-schedule.dinein" | translate}}</ion-text
                >
              </ion-label>
            </ion-col>
          </ion-row>

          <ion-row
            style="width: 100%"
            *ngFor="let scheduleDay of (segment === 'delivery'? store.scheduleArray : (segment === 'takeaway' ? (store.pickupScheduleActive ? store.pickupScheduleArray : store.scheduleArray ) : (store.dineinScheduleActive ? store.dineinScheduleArray  : store.scheduleArray)));let i = index"
          >
            <ion-col size="4" *ngIf="scheduleDay && scheduleDay.day">
              <ion-label style="font-weight: bold" class="ion-text-wrap">
                {{scheduleDay.day}}
              </ion-label>
            </ion-col>
            <ion-col class="ion-text-right">
              <ion-label class="ion-text-wrap"
                >{{segment === 'delivery' ? store.scheduleArray[i].schedule :
                (segment === 'takeaway' ? (store.pickupScheduleActive ?
                store.pickupScheduleArray[i].schedule :
                store.scheduleArray[i].schedule ) : (store.dineinScheduleActive
                ? store.dineinScheduleArray[i].schedule :
                store.scheduleArray[i].schedule))}}</ion-label
              >
            </ion-col>
          </ion-row>

          <ion-item lines="none" class="ion-text-center">
            <ion-row style="width: 100%">
              <ion-col size="12">
                <ion-label
                  class="ion-text-wrap"
                  *ngIf="store.disableOrderDelivery"
                >
                  <ion-text
                    color="danger"
                    style="font-weight: bold; font-size: small"
                  >
                    {{'store-schedule.we-do-not-serve-delivery-orders-from-this-app'
                    | translate}}</ion-text
                  >
                </ion-label>
              </ion-col>
              <ion-col
                size="12"
                *ngIf="storeScheduleInfoMessages && storeScheduleInfoMessages.inline && storeScheduleInfoMessages.inline.length"
              >
                <ion-label
                  *ngFor="let infoMessage of storeScheduleInfoMessages.inline"
                  class="ion-text-wrap"
                >
                  <div
                    [innerHTML]="
                  infoMessage.title[selectedLang]
                    ? infoMessage.title[selectedLang]
                    : infoMessage.title[mo_base]
                    ? infoMessage.title[mo_base]
                    : infoMessage.title['en']
                "
                    *ngIf="infoMessage && infoMessage.title"
                  ></div>
                  <p
                    [innerHTML]="
                   infoMessage.description[selectedLang]
                   ? infoMessage.description[selectedLang]
                   : infoMessage.description[mo_base]
                 ? infoMessage.description[mo_base]
                : infoMessage.description['en']
                  "
                    style="margin: 0"
                    *ngIf="infoMessage && infoMessage.description"
                  ></p>
                </ion-label>
              </ion-col>
              <ion-col
                size="12"
                *ngIf="store&& groupApp&&!groupApp.active_reservation_app"
              >
                <ion-label class="ion-text-wrap"
                  >{{ store.address_langs && store.address_langs[selectedLang] ?
                  store.address_langs[selectedLang] : store.address_langs &&
                  store.address_langs.el ? store.address_langs.el :
                  store.address }}</ion-label
                >
              </ion-col>
              <ion-col
                size="12"
                *ngIf="groupApp&&!groupApp.active_reservation_app"
              >
                <ion-label class="ion-text-wrap" *ngIf="store.telephoneArray">
                  <ion-label *ngFor="let tel of store.telephoneArray"
                    >{{tel}}</ion-label
                  >
                </ion-label>
              </ion-col>
            </ion-row>
          </ion-item>
          <ion-item
            lines="none"
            *ngIf="groupApp&&groupApp.active_reservation_app"
          >
            <ion-text style="font-size: medium; font-weight: bold"
              >{{"reservation-app.ourLocation" |translate}}</ion-text
            >
          </ion-item>
          <div
            style="height: 170px"
            *ngIf="groupApp&&groupApp.active_reservation_app"
          >
            <app-reservation-location-preview
              [height]="height"
              [selectedStore]="reservationAppStore || storeInfo"
            >
            </app-reservation-location-preview>
          </div>
          <ion-item
            lines="none"
            *ngIf="groupApp&&groupApp.active_reservation_app"
          >
            <ion-text style="font-size: medium; font-weight: bold"
              >{{"reservation-app.payOrders" |translate}}</ion-text
            >
          </ion-item>
          <ion-item
            lines="none"
            *ngIf="groupApp&&groupApp.active_reservation_app"
          >
            <ion-icon color="primary" name="wallet"></ion-icon
            ><ion-text style="margin-left: 10px"
              >{{"reservation-app.creditDebitCard" |translate}}</ion-text
            >
          </ion-item>
          <ion-item
            lines="none"
            *ngIf="groupApp&&groupApp.active_reservation_app"
            ><ion-icon color="primary" name="storefront"></ion-icon
            ><ion-text style="margin-left: 10px"
              >{{"reservation-app.payAtStore" |translate}}</ion-text
            >
          </ion-item>
        </ion-list>
      </div>
    </span>
  </ion-grid>
  <div
    *ngIf="groupApp &&
      groupApp.design_settings &&
      groupApp.design_settings.menuFooterTabs && windowInfo && windowInfo.width<900"
    style="margin-top: 65px"
  ></div>
</ion-content>

<app-menu-footer-tabs></app-menu-footer-tabs>
