import { Action } from "@ngrx/store";

export const SET_CART_PRICES = "[CART PRICES] Set Cart Prices";
export const CLEAR_CART_PRICES = "[CART PRICES] Clear Cart Prices";

export class SetCartPrices implements Action {
  readonly type = SET_CART_PRICES;
  constructor(public payload: any) {}
}

export class ClearCartPrices implements Action {
  readonly type = CLEAR_CART_PRICES;
  constructor() {}
}

export type CartPricesActions = SetCartPrices | ClearCartPrices;
