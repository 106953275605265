import * as CheckboxPromotionsActions from "./checkbox-promotions.actions";
import * as _ from "lodash";

export interface State {
  checkboxPromotions: any;
  subTotalNoPromos: any;
}

const initialState: State = {
  checkboxPromotions: null,
  subTotalNoPromos: null,
};

export function checkboxPromotionsReducer(
  state = initialState,
  action: CheckboxPromotionsActions.CheckboxPromotionsActions
) {
  switch (action.type) {
    case CheckboxPromotionsActions.SET_CHECKBOX_PROMOTIONS:
      return {
        ...state,
        checkboxPromotions: action.payload,
      };

    case CheckboxPromotionsActions.CLEAR_CHECKBOX_PROMOTIONS:
      return {
        ...state,
        checkboxPromotions: null,
      };

    case CheckboxPromotionsActions.SET_SUBTOTAL_NO_PROMOS:
      return {
        ...state,
        subTotalNoPromos: action.payload,
      };

    case CheckboxPromotionsActions.CLEAR_SUBTOTAL_NO_PROMOS:
      return {
        ...state,
        subTotalNoPromos: null,
      };

    default:
      return state;
  }
}
