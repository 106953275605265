<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">
      <ion-text>
        {{ "popups.deliveryInfoModal.title" | translate }}
      </ion-text>
    </ion-title>
    <ion-button
      (click)="closeModal()"
      size="small"
      slot="end"
      fill="clear"
      color="dark"
    >
      <ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid class="ion-text-center ion-no-padding" style="max-width: 180px">
    <ng-lottie
      (animationCreated)="animationCreated($event)"
      [options]="animationOptions"
    ></ng-lottie>
  </ion-grid>
  <ion-grid
    *ngIf="
      message &&
      message.translations &&
      (message.translations[selectedLanguage] ||
        message.translations[baseLanguage])
    "
  >
    <ion-item lines="none">
      <ion-label class="ion-text-wrap ion-text-center">
        <div>
          {{
            message.translations[selectedLanguage] ||
              message.translations[baseLanguage]
          }}
        </div>
      </ion-label>
    </ion-item>
  </ion-grid>

  <ion-grid *ngIf="!message">
    <ion-item lines="none">
      <ion-label class="ion-text-wrap ion-text-center">
        <div>
          <div>
            {{
              "store-schedule.we-do-not-serve-delivery-orders-from-this-app"
                | translate
            }}
          </div>
        </div>
      </ion-label>
    </ion-item>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-grid
    *ngIf="
      !(
        (button_one &&
          button_one.translations &&
          (button_one.translations[selectedLanguage] ||
            button_one.translations[baseLanguage])) ||
        (button_two &&
          button_two.translations &&
          (button_two.translations[selectedLanguage] ||
            button_two.translations[baseLanguage]))
      )
    "
  >
    <ion-row style="width: 100%">
      <ion-col class="ion-text-center" size="12">
        <ion-button (click)="closeModal()"> OK</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid
    *ngIf="
      (button_one &&
        button_one.translations &&
        (button_one.translations[selectedLanguage] ||
          button_one.translations[baseLanguage])) ||
      (button_two &&
        button_two.translations &&
        (button_two.translations[selectedLanguage] ||
          button_two.translations[baseLanguage]))
    "
  >
    <ion-row style="width: 100%">
      <ion-col
        *ngIf="
          button_one &&
          button_one.translations &&
          (button_one.translations[selectedLanguage] ||
            button_one.translations[baseLanguage])
        "
        class="ion-text-center"
        [size]="button_one && button_two ? '6' : '12'"
      >
        <ion-button
          size="small"
          mode="ios"
          [disabled]="loading"
          (click)="onClickOk(button_one.link)"
          >{{
            button_one.translations[selectedLanguage] ||
              button_one.translations[baseLanguage]
          }}</ion-button
        >
      </ion-col>
      <ion-col
        *ngIf="
          button_two &&
          button_two.translations &&
          (button_two.translations[selectedLanguage] ||
            button_two.translations[baseLanguage])
        "
        class="ion-text-center"
        [size]="button_one && button_two ? '6' : '12'"
      >
        <ion-button
          size="small"
          mode="ios"
          [disabled]="loading"
          (click)="onClickOk(button_two.link)"
          >{{
            button_two.translations[selectedLanguage] ||
              button_two.translations[baseLanguage]
          }}</ion-button
        >
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
