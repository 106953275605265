<app-quantity-options
  *ngIf="
    store_info &&
    store_info.cartCustomOptions &&
    cart &&
    showQuantityOptions === 'quantity-options'
  "
  [store_info]="store_info"
  [catalogMode]="catalogMode"
  [cart]="cart"
  [selectedPaymentMethodID]="selectePaymentMethodID"
  [orderPickup]="orderPickup"
  [dineIn]="dineIn"
>
</app-quantity-options>

<app-no-quantity-options
  *ngIf="
    store_info &&
    store_info.cartCustomOptions &&
    cart &&
    showQuantityOptions === 'no-quantity-options' &&
    !catalogMode
  "
  [store_info]="store_info"
  [cart]="cart"
  [selectedPaymentMethodID]="selectePaymentMethodID"
  [orderPickup]="orderPickup"
  [dineIn]="dineIn"
>
</app-no-quantity-options>
