import * as ProductDetailsDimensionsActions from "./product-details-dimensions.actions";
import * as _ from "lodash";

export interface State {
  productDetailsDimensions: any;
}

const initialState: State = {
  productDetailsDimensions: null,
};

export function productDetailsDimensionsReducer(
  state = initialState,
  action: ProductDetailsDimensionsActions.ProductDetailsDimensionsActions
) {
  switch (action.type) {
    case ProductDetailsDimensionsActions.SET_PRODUCT_DETAILS_DIMENSIONS:
      const key = _.findIndex(state.productDetailsDimensions, (item: any) => {
        return item.selectedProductId === action.payload.selectedProductId;
      });
      if (key !== -1) {
        const updateProduct = {
          ...state.productDetailsDimensions[key],
          ...action.payload,
        };
        const updatedProducts = [...state.productDetailsDimensions];
        updatedProducts[key] = updateProduct;
        return {
          ...state,
          productDetailsDimensions: updatedProducts,
        };
      } else {
        if (!state.productDetailsDimensions) {
          return {
            ...state,
            productDetailsDimensions: [action.payload],
          };
        } else {
          return {
            ...state,
            productDetailsDimensions: [
              ...state.productDetailsDimensions,
              action.payload,
            ],
          };
        }
      }
    case ProductDetailsDimensionsActions.CLEAR_PRODUCT_DETAILS_DEMENSIONS:
      const indexForDelete = _.findIndex(
        state.productDetailsDimensions,
        (item: any) => {
          return item.selectedProductId === action.payload;
        }
      );
      let updatedArray = _.cloneDeep(state.productDetailsDimensions);
      if (indexForDelete !== -1) {
        updatedArray.splice(indexForDelete, 1);
      }

      return {
        ...state,
        productDetailsDimensions: _.isEmpty(updatedArray) ? null : updatedArray,
      };
    default:
      return state;
  }
}
