import * as FavoriteProductsActions from "./favoriteProducts.actions";
import * as _ from "lodash";

export interface State {
  favoriteProducts: any;
}

const initialState: State = {
  favoriteProducts: null,
};

export function favoriteProductsActions(
  state = initialState,
  action: FavoriteProductsActions.FavoriteProductsActions
) {
  switch (action.type) {
    case FavoriteProductsActions.SET_FAVORITE_PRODUCTS:
      return {
        ...state,
        favoriteProducts: action.payload,
      };

    case FavoriteProductsActions.ADD_FAVORITE_PRODUCT:
      let newFavoriteProducts = _.cloneDeep(state.favoriteProducts);
      let findIndex = _.findIndex(state.favoriteProducts, {
        store_id: action.payload.store_id,
        customer_id: action.payload.customer_id,
        product_id: action.payload.product_id,
      });
      if (findIndex === -1) {
        if (!newFavoriteProducts) {
          newFavoriteProducts = [];
        }
        newFavoriteProducts.push(action.payload);
      }
      return {
        ...state,
        favoriteProducts: newFavoriteProducts,
      };

    case FavoriteProductsActions.DELETE_FAVORITE_PRODUCT:
      //console.log("action payload delete", action.payload);
      let favoriteProducts = _.cloneDeep(state.favoriteProducts);
      let deletedIndex = _.findIndex(state.favoriteProducts, {
        store_id: action.payload.store_id,
        customer_id: action.payload.customer_id,
        product_id: action.payload.product_id,
      });
      if (deletedIndex !== -1) {
        favoriteProducts.splice(deletedIndex, 1);
      }
      return {
        ...state,
        favoriteProducts: favoriteProducts,
      };

    case FavoriteProductsActions.CLEAR_CURRENT_STORE_ID:
      return {
        ...state,
        favoriteProducts: null,
      };

    default:
      return state;
  }
}
