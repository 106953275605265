<ion-row *ngIf="!currentUser">
  <ion-col class="ion-text-center ion-padding">
    <ion-grid
      *ngIf="profileAnimation"
      style="width: 100px; height: 100px; padding: 0"
    >
      <ng-lottie [options]="profileAnimationOptions"></ng-lottie>
    </ion-grid>
    <ion-text>
      <h5>{{ "points.history.first-login" | translate }}</h5>
    </ion-text>
    <ion-button
      [disabled]="!loginLoading"
      color="primary"
      size="medium"
      (click)="presentMagicLoginPopup()"
    >
      <ion-text *ngIf="loginLoading">
        {{ "homepage.magic-login" | translate }}
      </ion-text>
      <ion-spinner *ngIf="!loginLoading"></ion-spinner>
    </ion-button>
  </ion-col>
</ion-row>

<ion-row *ngIf="currentUser && !userPoints">
  <ion-col class="ion-text-center ion-padding">
    <ion-spinner></ion-spinner>
  </ion-col>
</ion-row>

<ion-list *ngIf="currentUser && userPoints">
  <ion-item-divider mode="ios" *ngIf="doNotShowTitleDivider">
    <ion-grid>
      <ion-row>
        <ion-col class="ion-text-center">
          {{ "points.profile.points-analysis" | translate }}
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-item-divider>
  <ion-item>
    {{ "points.profile.active-points" | translate }}
    <span slot="end">{{ userPoints.pointsSystem.active }}</span>
  </ion-item>
  <ion-item *ngIf="userPoints.pointsSystem.pending > 0">
    {{ "points.profile.pending-points" | translate }}
    <span slot="end">{{ userPoints.pointsSystem.pending }}</span>
  </ion-item>
  <ion-item>
    {{ "points.profile.redeemed-points" | translate }}
    <span slot="end">{{ userPoints.pointsSystem.used }}</span>
  </ion-item>
  <ion-item *ngIf="userPoints.pointsSystem.expired > 0">
    {{ "points.profile.expired-points" | translate }}
    <span slot="end">{{ userPoints.pointsSystem.expired }}</span>
  </ion-item>
  <ion-item *ngIf="userPoints.pointsSystem.canceled > 0">
    {{ "points.profile.canceled-points" | translate }}
    <span slot="end">{{ userPoints.pointsSystem.canceled }}</span>
  </ion-item>
  <ion-item>
    {{ "points.profile.total-points" | translate }}
    <span slot="end">{{ userPoints.pointsSystem.total }}</span>
  </ion-item>
</ion-list>
