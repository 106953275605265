import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import * as _ from "lodash";
import * as fromApp from "../../store/app.reducer";
import * as selectors from "../../store/app.selectors";
import { Store } from "@ngrx/store";
import { distinctUntilChanged, Subscription } from "rxjs";

@Component({
  selector: "app-catalog-disclaimer",
  templateUrl: "./catalog-disclaimer.component.html",
  styleUrls: ["./catalog-disclaimer.component.scss"],
})
export class CatalogDisclaimerComponent implements OnInit, OnDestroy {
  @Input() catalogDisclaimer: any;
  public selectedLanguage: any;
  public groupApp: any;
  public mo_base: any;

  private groupSubscription: Subscription;

  constructor(private store: Store<fromApp.AppState>) {}

  ngOnInit() {
    this.groupSubscription = this.store
      .select("groupApp")
      .subscribe((state) => {
        if (
          state &&
          state.selectedLangugage &&
          !_.isEqual(this.selectedLanguage, state.selectedLangugage)
        ) {
          this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
        }

        if (
          state &&
          state.groupApp &&
          !_.isEqual(this.groupApp, state.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp);

          this.mo_base = _.cloneDeep(
            _.find(state.groupApp.languages, {
              mo_base: true,
            })
          );
        }
      });
  }

  ngOnDestroy(): void {
    if (this.groupSubscription) {
      this.groupSubscription.unsubscribe();
    }
  }
}
