<ion-header class="ion-no-border" style="z-index: 2">
  <app-no-internet-connection></app-no-internet-connection>
  <app-ionic-update-error></app-ionic-update-error>
  <ion-toolbar
    *ngIf="loginState && (loginState==='no_login' || loginState==='login_completed' ) && groupApp && (!groupApp.design_settings || !groupApp.design_settings.simplified_homepage)"
    [class]="imagesArray && imagesArray.length ===0? 'contentBackgroundColor':'contentBackgroundTransparent'"
  >
    <ion-buttons
      color="homepageButtons"
      id="menuButton"
      [hidden]="!startAnimationMenu"
      [class]="startAnimationMenu ?  'ion-margin animate__animated animate__bounceInLeft' : 'ion-margin'"
      size="small"
      slot="start"
    >
      <ion-button
        fill="solid"
        color="homepageButtons"
        *ngIf="!sideMenuIsOpen"
        (click)="openSideMenu()"
      >
        <ion-icon src="assets/ionicons/menu-outline.svg"></ion-icon>
        <strong> MENU </strong>
      </ion-button>
    </ion-buttons>

    <ion-title
      mode="ios"
      *ngIf=" groupApp && windowDimensions && windowDimensions.width && windowDimensions.width <1500 && groupApp.homepage_upper_slider_active  && groupApp.homeUpperSliderImages &&
      groupApp.headerLogo && selectedTheme"
    >
      <div
        class="headerLogoToolbar"
        *ngIf="(selectedTheme && selectedTheme==='light') || !groupApp.headerLogoDark"
      >
        <img
          alt="logo"
          height="90%"
          src="https://images.deliverymanager.gr/{{ groupApp.group }}/header/{{
          groupApp.headerLogo
        }}"
        />
      </div>
      <div
        class="headerLogoToolbar"
        *ngIf="selectedTheme && selectedTheme==='dark' && groupApp.headerLogoDark"
      >
        <img
          alt="logo"
          height="90%"
          src="https://images.deliverymanager.gr/{{ groupApp.group }}/header/{{
          groupApp.headerLogoDark
        }}"
        />
      </div>
    </ion-title>

    <app-language-selection slot="end"></app-language-selection>
  </ion-toolbar>
  <ion-toolbar
    *ngIf="loginState && (loginState==='no_login' || loginState==='login_completed' ) && groupApp && groupApp.design_settings && groupApp.design_settings.simplified_homepage"
    [class]="imagesArray && imagesArray.length ===0? 'contentBackgroundColor':'contentBackgroundTransparent'"
  >
    <ion-buttons
      color="homepageButtons"
      id="menuButton"
      [hidden]="!startAnimationMenu"
      [class]="startAnimationMenu ?  'ion-margin animate__animated animate__bounceInLeft' : 'ion-margin'"
      size="small"
      slot="start"
    >
      <ion-button
        fill="solid"
        color="homepageButtons"
        *ngIf="!sideMenuIsOpen"
        (click)="openSideMenu()"
      >
        <ion-icon src="assets/ionicons/menu-outline.svg"></ion-icon>
        <strong> MENU </strong>
      </ion-button>
    </ion-buttons>

    <app-language-selection slot="end"></app-language-selection>
  </ion-toolbar>
</ion-header>
<swiper
  *ngIf="imagesArray && imagesArray.length"
  [slidesPerView]="1"
  [autoplay]="true"
  [lazy]="true"
  [loop]="false"
  [navigation]="false"
  [keyboard]="true"
  [pagination]="  groupApp && groupApp.design_settings && groupApp.design_settings.simplified_homepage ? true : false"
  effect="fade"
  [ngClass]="{
            whitePaginationSwiper:
            groupApp && groupApp.design_settings && groupApp.design_settings.simplified_homepage }"
  (swiper)="setSwiperInstance($event)"
  style="position: absolute; height: 100%; width: 100%; z-index: 0"
>
  <ng-template swiperSlide *ngFor="let image of imagesArray">
    <div
      class="homepage-background-slider-slide"
      style="background: url('{{image.path}}')  no-repeat center center;width: 100%;height: 100%;
-webkit-background-size: cover;
-moz-background-size: cover;
background-size: cover; z-index: 1;"
    ></div>
  </ng-template>
</swiper>

<app-homepage-upper-slider
  [groupApp]="groupApp"
  [windowDimensions]="windowDimensions"
  [imagesArray]="imagesArray"
  [loginState]="loginState"
  [homepageUpperSliderDir]="homepageUpperSliderDir"
  [rootDir]="rootDir"
></app-homepage-upper-slider>

<ion-content
  #pageContent
  [class]="imagesArray && imagesArray.length ===0 && (loginState && (loginState ==='no_login' || loginState==='login_completed'))? 'contentBackgroundColor':'contentBackgroundTransparent'"
  fullscreen="true"
  [scrollEvents]="false"
  scrollX="false"
  scrollY="false"
>
  <ion-grid
    style="position: absolute; top: 0; width: 100%"
    *ngIf="groupApp && (!groupApp.design_settings || !groupApp.design_settings.simplified_homepage) && groupApp.images_select_store_mobileorder && windowDimensions && windowDimensions.height>650 && windowDimensions.width>700 && !groupApp.pointsSystem &&
    (!groupApp.homepage_upper_slider_active || !groupApp.homeUpperSliderImages ||
    !groupApp.homeUpperSliderImages.length) &&store_info &&
    !store_info.virtual_store_change_hyper_categories_buttons&&
    groupApp.store_change_catalog_buttons && stores && stores.length > 1"
  >
    <ion-row
      style="width: 100%"
      class="ion-align-items-center ion-justify-content-center"
    >
      <ion-col
        [size]="sizeColImageSelectStore"
        *ngFor="let store of stores"
        class="ion-text-center"
      >
        <ion-grid [class]="'grid300'">
          <ion-card
            class="hover-change-store-card"
            mode="md"
            button="true"
            (click)="changeStore(store.store_id)"
          >
            <div class="image-container">
              <img
                width="100%"
                [src]="deliveryManagerPath +store.store_id + '/' + 'storeChangeCatalogButtonsIcons/' +  store.select_store_mobileorder_image"
              />
              <div
                style="
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                "
              >
                <ion-text
                  color="white"
                  style="font-size: large; font-weight: bold"
                >
                  {{ store.store_name_catalog_change_langs &&
                  store.store_name_catalog_change_langs[selectedLangugage] ?
                  store.store_name_catalog_change_langs[ selectedLangugage ] :
                  store.store_name_catalog_change_langs &&
                  store.store_name_catalog_change_langs.el ?
                  store.store_name_catalog_change_langs.el :
                  store.store_name_mobileorder &&
                  store.store_name_mobileorder[selectedLangugage] ?
                  store.store_name_mobileorder[selectedLangugage] :
                  store.store_name_langs &&
                  store.store_name_langs[selectedLangugage] ?
                  store.store_name_langs[selectedLangugage] :
                  store.store_name_langs && store.store_name_langs.el ?
                  store.store_name_langs.el : store.store_name ?
                  store.store_name : "" }}
                </ion-text>
              </div>
            </div>
          </ion-card>
        </ion-grid>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid
    style="z-index: 1"
    *ngIf="groupApp && (!groupApp.design_settings || !groupApp.design_settings.simplified_homepage) && (!groupApp.images_select_store_mobileorder || windowDimensions.width<=700 ) && windowDimensions && windowDimensions.height>600  && !groupApp.pointsSystem &&
(!groupApp.homepage_upper_slider_active || !groupApp.homeUpperSliderImages ||
!groupApp.homeUpperSliderImages.length) &&store_info &&
!store_info.virtual_store_change_hyper_categories_buttons&&
groupApp.store_change_catalog_buttons && stores && stores.length > 1 "
    [class]="windowDimensions.height >= 600 && windowDimensions.width>=320 ? 'storeChangeButtonsGridBig' : 'storeChangeButtonsGridSmall'"
  >
    <ion-row style="width: 100%">
      <ion-col
        class="ion-no-padding paddingColStoreChangeButton"
        size="6"
        *ngFor="let store of stores"
      >
        <ion-item
          detail="false"
          class="storeChangeButtonItem"
          button="true"
          (click)="changeStore(store.store_id)"
          [color]="store_info.store_id === store.store_id ? 'primary' : 'medium'"
        >
          <ion-label class="ion-text-wrap ion-text-center">
            <div
              *ngIf="windowDimensions && windowDimensions.height && windowDimensions.height>639 && windowDimensions.width>320"
            >
              <ion-icon
                style="font-size: 25px"
                *ngIf="store.store_change_catalog_buttons_icon"
                [src]="deliveryManagerPath +store.store_id + '/' + 'storeChangeCatalogButtonsIcons/' +  store.store_change_catalog_buttons_icon"
              >
              </ion-icon>
            </div>
            <ion-text style="font-size: small; font-weight: bold">
              {{ store.store_name_catalog_change_langs &&
              store.store_name_catalog_change_langs[selectedLangugage] ?
              store.store_name_catalog_change_langs[selectedLangugage] :
              store.store_name_catalog_change_langs &&
              store.store_name_catalog_change_langs.el ?
              store.store_name_catalog_change_langs.el :
              store.store_name_mobileorder &&
              store.store_name_mobileorder[selectedLangugage] ?
              store.store_name_mobileorder[selectedLangugage] :
              store.store_name_langs &&
              store.store_name_langs[selectedLangugage] ?
              store.store_name_langs[selectedLangugage] : store.store_name_langs
              && store.store_name_langs.el ? store.store_name_langs.el :
              store.store_name ? store.store_name : "" }}</ion-text
            >
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>
  <div
    style="
      position: absolute;
      width: 300px;
      height: auto;
      top: 50%;
      right: 15px;
      margin-left: -150px;
      margin-top: -75px;
      z-index: 100;
    "
    *ngIf="loginState && (loginState==='no_login' || loginState ==='login_completed')  &&homepageInfoMessagesStore && homepageInfoMessagesStore.alert && homepageInfoMessagesStore.alert.length  && selectedLangugage && mo_base && groupApp && windowDimensions && windowDimensions.width && windowDimensions.width>=1080"
  >
    <ion-card
      *ngFor="let homeStoreMessage of homepageInfoMessagesStore.alert;let index= index"
      [ngClass]="infoMessagesAnimations[homeStoreMessage.id] ?    'animate__animated animate__backInRight' :  'animate__animated animate__fadeOut'"
      [hidden]="infoMessagesCardsHide[homeStoreMessage.id]"
      [ngStyle]="{top: (50+ index * 10).toString() + '%'}"
      [button]="homeStoreMessage.alert_link ? true: false"
      (click)="openExternalLink(homeStoreMessage.alert_link)"
      color="primary"
      mode="md"
    >
      <ion-icon
        (click)="closeInfoCard(homeStoreMessage.id)"
        style="
          position: absolute;
          top: 1px;
          right: 1px;
          font-size: 19px;
          cursor: pointer;
          z-index: 150;
        "
        src="assets/ionicons/close.svg"
      ></ion-icon>

      <ion-row
        class="infoMessageCardRow ion-align-items-center ion-justify-content-center"
      >
        <ion-col *ngIf="homeStoreMessage.image" size="3" class="ion-no-padding">
          <ion-thumbnail class="infoMessageThumb">
            <img
              src="https://s3-eu-west-1.amazonaws.com/storegroupseditedbgimages/{{
              groupApp.group
            }}/info_messages/{{ homeStoreMessage.image }}"
            />
          </ion-thumbnail>
        </ion-col>
        <ion-col
          [size]="!homeStoreMessage.image ? '12':'9'"
          class="ion-no-padding"
        >
          <div>
            <ion-text
              *ngIf="homeStoreMessage && homeStoreMessage.title"
              [innerHTML]="homeStoreMessage.title[selectedLangugage] ?
                      homeStoreMessage.title[selectedLangugage]
                      :(homeStoreMessage.title[mo_base] ?
                      homeStoreMessage.title[mo_base] :
                      homeStoreMessage.title['en']) "
              style="line-height: 1.5; font-weight: bold"
            ></ion-text>
          </div>
        </ion-col>
      </ion-row>
      <ion-progress-bar
        *ngIf="homeStoreMessage.auto_dismiss && homeStoreMessage.auto_dismiss_seconds"
        [value]="motoCardsProgressValues[homeStoreMessage.id]"
        color="success"
        style="height: 5px"
      ></ion-progress-bar>
    </ion-card>
  </div>

  <div
    style="
      position: absolute;
      width: 300px;
      height: auto;
      top: 30%;
      right: 15px;
      margin-left: -150px;
      margin-top: -75px;
      z-index: 100;
    "
    *ngIf="loginState && (loginState==='no_login' || loginState ==='login_completed')  &&((homepageInfoMessagesGroup && homepageInfoMessagesGroup.alert && homepageInfoMessagesGroup.alert.length)) && selectedLangugage && mo_base && groupApp && windowDimensions && windowDimensions.width && windowDimensions.width>=1080"
  >
    <ion-card
      [ngClass]="infoMessagesAnimations[homeGroupMessage.id] ?    'animate__animated animate__backInRight' :  'animate__animated animate__fadeOut'"
      [hidden]="infoMessagesCardsHide[homeGroupMessage.id] "
      color="primary"
      mode="md"
      [ngStyle]="{top: (50+ index * 10).toString() + '%'}"
      [button]="homeGroupMessage.alert_link ? true: false"
      (click)="openExternalLink(homeGroupMessage.alert_link)"
      *ngFor="let homeGroupMessage of homepageInfoMessagesGroup.alert;let index = index"
    >
      <ion-icon
        (click)="$event.stopImmediatePropagation();closeInfoCard(homeGroupMessage.id)"
        style="
          position: absolute;
          top: 1px;
          right: 1px;
          font-size: 19px;
          cursor: pointer;
          z-index: 150;
        "
        src="assets/ionicons/close.svg"
      ></ion-icon>

      <ion-row
        class="infoMessageCardRow ion-align-items-center ion-justify-content-center"
      >
        <ion-col *ngIf="homeGroupMessage.image" size="3" class="ion-no-padding">
          <ion-thumbnail class="infoMessageThumb">
            <img
              src="https://s3-eu-west-1.amazonaws.com/storegroupseditedbgimages/{{
              groupApp.group
            }}/info_messages/{{ homeGroupMessage.image }}"
            />
          </ion-thumbnail>
        </ion-col>
        <ion-col
          [size]="!homeGroupMessage.image ? '12':'9'"
          class="ion-no-padding"
        >
          <div>
            <ion-text
              [innerHTML]="homeGroupMessage.title[selectedLangugage] ?
        homeGroupMessage.title[selectedLangugage]
        :(homeGroupMessage.title[mo_base] ?
        homeGroupMessage.title[mo_base] :
        homeGroupMessage.title['en']) "
              style="line-height: 1.5; font-weight: bold"
              *ngIf="homeGroupMessage && homeGroupMessage.title"
            ></ion-text>
          </div>
        </ion-col>
      </ion-row>
      <ion-progress-bar
        *ngIf="homeGroupMessage.auto_dismiss && homeGroupMessage.auto_dismiss_seconds"
        [value]="motoCardsProgressValues[homeGroupMessage.id]"
        color="success"
        style="height: 5px"
      ></ion-progress-bar>
    </ion-card>
  </div>

  <ion-grid
    *ngIf="animationLogin"
    style="width: 100%; height: 100%; position: absolute; top: 0; z-index: 1000"
  >
    <ion-row
      style="width: 100%; height: 100%"
      class="ion-align-items-center ion-justify-content-center"
    >
      <ion-col class="ion-align-self-center ion-text-center">
        <ion-grid style="max-width: 300px">
          <ng-lottie
            (animationCreated)="animationCreated($event)"
            (loopComplete)="animationComplete($event)"
            [options]="options"
          ></ng-lottie>
        </ion-grid>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid
    style="height: 100%; z-index: 1"
    *ngIf="groupApp && windowDimensions && selectedLangugage"
  >
    <ion-row
      style="height: 100%"
      class="ion-justify-content-center ion-align-items-center"
    >
      <ion-col
        *ngIf="loginState && (loginState==='no_login' || loginState ==='login_completed')"
        class="ion-align-self-center"
        style="max-width: 450px; max-height: 100%"
      >
        <div
          *ngIf="groupApp && groupApp.design_settings &&  groupApp.design_settings.simplified_homepage"
          style="margin-top: 50vh"
        ></div>

        <!--Points-->
        <div
          [class]="groupApp && groupApp && groupApp.design_settings && groupApp.design_settings.customHomepageButtons && groupApp.design_settings.customHomepageButtons.active  && groupApp && groupApp.homepage_upper_slider_active  && groupApp.homeUpperSliderImages && windowDimensions && windowDimensions.height>750 ? 'paddingBottom' : ''"
          [hidden]="groupApp && groupApp && groupApp.design_settings && groupApp.design_settings.customHomepageButtons && groupApp.design_settings.customHomepageButtons.active  && groupApp && groupApp.homepage_upper_slider_active  && groupApp.homeUpperSliderImages && ((windowDimensions && windowDimensions.height<800 && fourButtonsActive) ||(windowDimensions && windowDimensions.height<650 && threeButtonsActive)) || (groupApp && groupApp.design_settings  && groupApp.design_settings.simplified_homepage)"
        >
          <app-point-system
            *ngIf="groupApp && groupApp.pointsSystem && windowDimensions && windowDimensions.height"
          ></app-point-system>
        </div>

        <!--logo-->

        <app-homepage-logo
          [hidden]="groupApp && groupApp.design_settings && groupApp.design_settings.simplified_homepage"
          [startAnimationLogo]="startAnimationLogo"
          [groupApp]="groupApp"
          [rootDir]="rootDir"
          [selectedLogoFileName]="selectedLogoFileName"
          [windowDimensions]="windowDimensions"
        >
        </app-homepage-logo>

        <div class="ion-text-center">
          <ion-text
            style="font-size: large; font-weight: bold"
            color="homePageMotoColor"
            *ngIf="currentUser"
          >
            {{"homepage.welcome" | translate}}{{currentUser.firstName ? '
            '+currentUser.firstName+'!' : '!'}}
          </ion-text>

          <div
            *ngIf="(homepageInfoMessagesStore && homepageInfoMessagesStore.inline &&  homepageInfoMessagesStore.inline.length ) ||( homepageInfoMessagesGroup && homepageInfoMessagesGroup.inline && homepageInfoMessagesGroup.inline.length) && selectedLangugage && mo_base"
          >
            <ion-text
              *ngFor="let homeInfoMessage of homepageInfoMessagesStore.inline"
              class="homepageinfo"
              style="font-size: large"
              [innerHTML]="homeInfoMessage && homeInfoMessage.title ? homeInfoMessage.title[selectedLangugage] ?
              homeInfoMessage.title[selectedLangugage]
              :(homeInfoMessage.title[mo_base] ?
              homeInfoMessage.title[mo_base] :
              homeInfoMessage.title['en']) :''"
            ></ion-text>
            <ion-text
              *ngFor="let homeInfoMessage of homepageInfoMessagesGroup.inline"
              class="homepageinfo"
              style="font-size: large"
              [innerHTML]="homeInfoMessage && homeInfoMessage.title ? homeInfoMessage.title[selectedLangugage] ?
              homeInfoMessage.title[selectedLangugage]
              :(homeInfoMessage.title[mo_base] ?
              homeInfoMessage.title[mo_base] :
              homeInfoMessage.title['en']) :''"
            ></ion-text>
          </div>
        </div>

        <span
          *ngIf="groupApp && groupApp.design_settings && groupApp.design_settings.customHomepageButtons && groupApp.design_settings.customHomepageButtons.active  &&((currentStore && !currentStore.showOnlyFavoritesCatalog) || (currentStore && currentStore.showOnlyFavoritesCatalog && currentUser))"
        >
          <ion-item
            *ngIf="groupApp.design_settings.customHomepageButtons.button_one && groupApp.design_settings.customHomepageButtons.button_one.active && selectedLangugage && mo_base &&windowDimensions.height>320&& ((!groupApp.pointsSystem && windowDimensions.height>490) || (groupApp.pointsSystem && windowDimensions.height>520))"
            [disabled]="checkAddressLoading"
            lines="none"
            detail="false"
            color="homepageButtons"
            button
            class="menuButtons"
            expand="block"
            (click)="naviagateToPage(groupApp.design_settings.customHomepageButtons.button_one.page)"
          >
            <ion-label
              style="font-weight: 700; padding: 10px"
              class="ion-no-padding ion-no-margin ion-text-center"
            >
              <div
                [innerHTML]="groupApp.design_settings.customHomepageButtons.button_one
              .translations[selectedLangugage] ||
              groupApp.design_settings.customHomepageButtons.button_one
              .translations[mo_base.code]"
              ></div>
            </ion-label>
          </ion-item>

          <ion-item
            *ngIf="groupApp.design_settings.customHomepageButtons.button_two && groupApp.design_settings.customHomepageButtons.button_two.active && selectedLangugage && mo_base &&windowDimensions.height>320&& ((!groupApp.pointsSystem && windowDimensions.height>490) || (groupApp.pointsSystem && windowDimensions.height>520))"
            [disabled]="checkAddressLoading"
            lines="none"
            detail="false"
            color="homepageButtons"
            button
            class="menuButtons"
            expand="block"
            (click)="naviagateToPage(groupApp.design_settings.customHomepageButtons.button_two.page)"
          >
            <ion-label
              style="font-weight: 700; padding: 10px"
              class="ion-no-padding ion-no-margin ion-text-center"
            >
              <div
                [innerHTML]="groupApp.design_settings.customHomepageButtons.button_two
              .translations[selectedLangugage] ||
              groupApp.design_settings.customHomepageButtons.button_two
              .translations[mo_base.code]"
              ></div>
            </ion-label>
          </ion-item>

          <ion-item
            *ngIf="groupApp && (!groupApp.design_settings || !groupApp.design_settings.disable_homepage_display_menu_btn )"
            color="homepageButtons"
            [disabled]="checkAddressLoading"
            lines="none"
            detail="false"
            button
            class="menuButtons"
            (click)="currentStore && !currentStore.vatBasedCustomerProductsActive ?  checkTheCatalog() : checkMigrationCustomerIdCatalog()"
          >
            <ion-label
              style="font-weight: 700; padding: 5px"
              class="ion-no-padding ion-no-margin ion-text-center"
            >
              <ion-spinner *ngIf="checkAddressLoading"> </ion-spinner>

              <div *ngIf="!checkAddressLoading">
                <div
                  *ngIf="orderPickup == true || (orderPickup==false && dineIn==true) || (orderPickup==false && dineIn==false)"
                >
                  {{ cartProducts && cartProducts.length ?
                  ("homepage.continue-order" | translate ) :
                  ("homepage.continue-to-catalog" | translate )}}
                </div>
                <div *ngIf="orderPickup ==null && dineIn == null">
                  <ion-text
                    *ngIf=" 
              !groupApp.design_settings  || !groupApp.design_settings.homeMenuButtonCustomTextEnabled || (groupApp.design_settings  && groupApp.design_settings.homeMenuButtonCustomTextEnabled && (!groupApp.design_settings.homeMenuButtonCustomText || !groupApp.design_settings.homeMenuButtonCustomText[selectedLangugage]) ) "
                  >
                    {{ "homepage.see-the-menu" | translate }}
                  </ion-text>

                  <ion-text
                    *ngIf="groupApp.design_settings && groupApp.design_settings.homeMenuButtonCustomTextEnabled && groupApp.design_settings.homeMenuButtonCustomText && groupApp.design_settings.homeMenuButtonCustomText[selectedLangugage]"
                    [innerHTML]="groupApp.design_settings.homeMenuButtonCustomText[selectedLangugage]"
                  >
                  </ion-text>
                </div>
                <p
                  *ngIf="orderPickup == true &&selectedLangugage"
                  style="font-size: small; padding: 0; margin: 0"
                >
                  {{'homepage.take-away' | translate}}: {{
                  currentStore.store_name_mobileorder &&
                  currentStore.store_name_mobileorder[selectedLangugage] ?
                  currentStore.store_name_mobileorder[selectedLangugage] :
                  (currentStore.store_name_langs &&
                  currentStore.store_name_langs[selectedLangugage] ?
                  currentStore.store_name_langs[selectedLangugage] :
                  currentStore.store_name_langs &&
                  currentStore.store_name_langs.el ?
                  currentStore.store_name_langs.el : currentStore.store_name ?
                  currentStore.store_name : "" )}}
                </p>
                <p
                  *ngIf="orderPickup == false && dineIn ==true"
                  style="font-size: small; padding: 0; margin: 0"
                >
                  {{'homepage.dine-in' | translate}}: {{selectedDineInTable ?
                  selectedDineInTable.name : ''}}
                </p>

                <p
                  *ngIf="orderPickup == false && dineIn ==false"
                  style="font-size: small; padding: 0; margin: 0"
                >
                  {{'homepage.delivery' | translate}}: {{selectedAddress ?
                  selectedAddress.formatted_address : '' }}
                </p>
              </div>
            </ion-label>
          </ion-item>

          <ion-item
            [disabled]="checkAddressLoading"
            lines="none"
            detail="false"
            color="homepageButtons"
            button
            class="menuButtons"
            expand="block"
            (click)="store_info && !store_info.vatBasedCustomerProductsActive ? presentOrderNowModal() : newOrderWithMigrationCustomerId()"
          >
            <ion-label
              style="font-weight: 700; padding: 10px"
              class="ion-no-padding ion-no-margin ion-text-center"
            >
              <div>
                <ion-text
                  *ngIf="!(groupApp.design_settings && groupApp.design_settings.homeNewOrderButtonCustomTextEnabled && groupApp.design_settings.homeNewOrderButtonCustomText && groupApp.design_settings.homeNewOrderButtonCustomText[selectedLangugage])"
                >
                  {{ "homepage.new-order" | translate }}
                </ion-text>

                <ion-text
                  *ngIf="groupApp.design_settings && groupApp.design_settings.homeNewOrderButtonCustomTextEnabled &&  groupApp.design_settings.homeNewOrderButtonCustomText  && groupApp.design_settings.homeNewOrderButtonCustomText && groupApp.design_settings.homeNewOrderButtonCustomText[selectedLangugage]"
                  [innerHTML]="groupApp.design_settings.homeNewOrderButtonCustomText[selectedLangugage]"
                >
                </ion-text>
              </div>
            </ion-label>
          </ion-item>
        </span>

        <span
          *ngIf="!(groupApp && groupApp.design_settings && groupApp.design_settings.customHomepageButtons && groupApp.design_settings.customHomepageButtons.active) && (currentStore && !currentStore.showOnlyFavoritesCatalog) || (currentStore && currentStore.showOnlyFavoritesCatalog && currentUser)"
        >
          <ion-item
            *ngIf="groupApp && (!groupApp.design_settings || !groupApp.design_settings.disable_homepage_display_menu_btn )"
            color="homepageButtons"
            [disabled]="checkAddressLoading"
            lines="none"
            detail="false"
            button
            class="menuButtons"
            (click)="currentStore && !currentStore.vatBasedCustomerProductsActive ?  checkTheCatalog() : checkMigrationCustomerIdCatalog()"
          >
            <ion-label
              style="font-weight: 700; padding: 5px"
              class="ion-no-padding ion-no-margin ion-text-center"
            >
              <ion-spinner *ngIf="checkAddressLoading"> </ion-spinner>

              <div *ngIf="!checkAddressLoading">
                <div
                  *ngIf="orderPickup == true || (orderPickup==false && dineIn==true) || (orderPickup==false && dineIn==false)"
                >
                  {{ cartProducts && cartProducts.length ?
                  ("homepage.continue-order" | translate ) :
                  ("homepage.continue-to-catalog" | translate )}}
                </div>
                <div *ngIf="orderPickup ==null && dineIn == null">
                  <ion-text
                    *ngIf=" 
            !groupApp.design_settings  || !groupApp.design_settings.homeMenuButtonCustomTextEnabled || (groupApp.design_settings  && groupApp.design_settings.homeMenuButtonCustomTextEnabled && (!groupApp.design_settings.homeMenuButtonCustomText || !groupApp.design_settings.homeMenuButtonCustomText[selectedLangugage]) ) "
                  >
                    {{ "homepage.see-the-menu" | translate }}
                  </ion-text>

                  <ion-text
                    *ngIf="groupApp.design_settings && groupApp.design_settings.homeMenuButtonCustomTextEnabled && groupApp.design_settings.homeMenuButtonCustomText && groupApp.design_settings.homeMenuButtonCustomText[selectedLangugage]"
                    [innerHTML]="groupApp.design_settings.homeMenuButtonCustomText[selectedLangugage]"
                  >
                  </ion-text>
                </div>
                <p
                  *ngIf="orderPickup == true &&selectedLangugage"
                  style="font-size: small; padding: 0; margin: 0"
                >
                  {{'homepage.take-away' | translate}}: {{
                  currentStore.store_name_mobileorder &&
                  currentStore.store_name_mobileorder[selectedLangugage] ?
                  currentStore.store_name_mobileorder[selectedLangugage] :
                  (currentStore.store_name_langs &&
                  currentStore.store_name_langs[selectedLangugage] ?
                  currentStore.store_name_langs[selectedLangugage] :
                  currentStore.store_name_langs &&
                  currentStore.store_name_langs.el ?
                  currentStore.store_name_langs.el : currentStore.store_name ?
                  currentStore.store_name : "" )}}
                </p>
                <p
                  *ngIf="orderPickup == false && dineIn ==true"
                  style="font-size: small; padding: 0; margin: 0"
                >
                  {{'homepage.dine-in' | translate}}: {{selectedDineInTable ?
                  selectedDineInTable.name : ''}}
                </p>

                <p
                  *ngIf="orderPickup == false && dineIn ==false"
                  style="font-size: small; padding: 0; margin: 0"
                >
                  {{'homepage.delivery' | translate}}: {{selectedAddress ?
                  selectedAddress.formatted_address : '' }}
                </p>
              </div>
            </ion-label>
          </ion-item>

          <ion-grid
            *ngIf="store_info && store_info.reservationMobileorderActive"
          >
            <ion-row style="width: 100%">
              <ion-col class="ion-no-padding" size="6">
                <ion-item
                  [disabled]="checkAddressLoading"
                  lines="none"
                  detail="false"
                  color="homepageButtons"
                  class="menuButtonsDoubleRight"
                  button
                  expand="block"
                  (click)="goToReservationsPage()"
                >
                  <ion-label
                    style="font-weight: 700; font-size: small"
                    class="ion-no-padding ion-no-margin ion-text-center"
                  >
                    <div>{{"homepage.reservation"| translate}}</div>
                  </ion-label>
                </ion-item>
              </ion-col>
              <ion-col class="ion-no-padding" size="6">
                <ion-item
                  [disabled]="checkAddressLoading"
                  lines="none"
                  detail="false"
                  color="homepageButtons"
                  class="menuButtonsDoubleLeft"
                  button
                  expand="block"
                  (click)="store_info && !store_info.vatBasedCustomerProductsActive ? presentOrderNowModal() : newOrderWithMigrationCustomerId()"
                >
                  <ion-label
                    style="font-weight: 700; font-size: small"
                    class="ion-no-padding ion-no-margin ion-text-center"
                  >
                    <div>
                      <ion-text
                        *ngIf="!(groupApp.design_settings && groupApp.design_settings.homeNewOrderButtonCustomTextEnabled && groupApp.design_settings.homeNewOrderButtonCustomText && groupApp.design_settings.homeNewOrderButtonCustomText[selectedLangugage])"
                      >
                        {{ "homepage.new-order" | translate }}
                      </ion-text>

                      <ion-text
                        *ngIf="groupApp.design_settings && groupApp.design_settings.homeNewOrderButtonCustomTextEnabled &&  groupApp.design_settings.homeNewOrderButtonCustomText  && groupApp.design_settings.homeNewOrderButtonCustomText && groupApp.design_settings.homeNewOrderButtonCustomText[selectedLangugage]"
                        [innerHTML]="groupApp.design_settings.homeNewOrderButtonCustomText[selectedLangugage]"
                      >
                      </ion-text>
                    </div>
                  </ion-label>
                </ion-item>
              </ion-col>
            </ion-row>
          </ion-grid>
          <ion-item
            *ngIf="store_info && !store_info.reservationMobileorderActive"
            [disabled]="checkAddressLoading"
            lines="none"
            detail="false"
            color="homepageButtons"
            button
            class="menuButtons"
            expand="block"
            [ngStyle]="{
              'border-radius': groupApp && groupApp.design_settings && groupApp.design_settings.simplified_homepage ? '10px' : ''
            }"
            (click)="store_info && !store_info.vatBasedCustomerProductsActive ? presentOrderNowModal() : newOrderWithMigrationCustomerId()"
          >
            <ion-label
              style="font-weight: 700; padding: 10px"
              class="ion-no-padding ion-no-margin ion-text-center"
            >
              <div>
                <ion-text
                  *ngIf="!(groupApp.design_settings && groupApp.design_settings.homeNewOrderButtonCustomTextEnabled && groupApp.design_settings.homeNewOrderButtonCustomText && groupApp.design_settings.homeNewOrderButtonCustomText[selectedLangugage])"
                >
                  {{ "homepage.new-order" | translate }}
                </ion-text>

                <ion-text
                  *ngIf="groupApp.design_settings && groupApp.design_settings.homeNewOrderButtonCustomTextEnabled &&  groupApp.design_settings.homeNewOrderButtonCustomText  && groupApp.design_settings.homeNewOrderButtonCustomText && groupApp.design_settings.homeNewOrderButtonCustomText[selectedLangugage]"
                  [innerHTML]="groupApp.design_settings.homeNewOrderButtonCustomText[selectedLangugage]"
                >
                </ion-text>
              </div>
            </ion-label>
          </ion-item>

          <ion-grid
            *ngIf="groupApp && groupApp.design_settings && groupApp.design_settings.simplified_homepage"
          >
            <ion-row style="width: 100%" *ngIf="!currentUser">
              <ion-col class="ion-text-center">
                <ion-grid style="max-width: 290px">
                  <ion-item
                    lines="none"
                    button="true"
                    detail="false"
                    style="border-radius: 10px"
                    color="homepageButtons"
                    class="menuButtons"
                    [disabled]="!loginLoading"
                    (click)="presentMagicLoginPopup()"
                  >
                    <ion-label
                      style="font-weight: 500; padding: 10px"
                      class="ion-no-padding ion-no-margin ion-text-center"
                    >
                      <ion-text *ngIf="loginLoading">
                        {{"homepage.magic-login"| translate}}
                      </ion-text>
                      <ion-spinner *ngIf="!loginLoading"></ion-spinner>
                    </ion-label>
                  </ion-item>
                </ion-grid>
              </ion-col>
            </ion-row>
          </ion-grid>
        </span>

        <span
          [class]="groupApp && groupApp.pointsSystem ? 'infoMessageCardWithPoints':'infoMessageCardWithoutPoints'"
          *ngIf="homepageInfoMessagesStore && homepageInfoMessagesStore.alert && homepageInfoMessagesStore.alert .length  && selectedLangugage && mo_base && groupApp && windowDimensions && windowDimensions.width && windowDimensions.width<1080"
        >
          <ion-card
            *ngFor="let homeStoreMessage of homepageInfoMessagesStore.alert;let index = index"
            [ngClass]="infoMessagesAnimations[homeStoreMessage.id] ?    'animate__animated animate__backInRight' :  'animate__animated animate__fadeOut'"
            [hidden]="infoMessagesCardsHide[homeStoreMessage.id]"
            [ngStyle]="{top: (50+ index * 10).toString() + '%'}"
            [button]="homeStoreMessage.alert_link ? true: false"
            (click)="openExternalLink(homeStoreMessage.alert_link)"
            color="primary"
            mode="md"
          >
            <ion-icon
              (click)="$event.stopImmediatePropagation();closeInfoCard(homeStoreMessage.id)"
              style="
                position: absolute;
                top: 1px;
                right: 1px;
                font-size: 19px;
                cursor: pointer;
                z-index: 150;
              "
              src="assets/ionicons/close.svg"
            ></ion-icon>

            <ion-row
              class="infoMessageCardRow ion-align-items-center ion-justify-content-center"
            >
              <ion-col
                *ngIf="homeStoreMessage.image"
                size="3"
                class="ion-no-padding"
              >
                <ion-thumbnail class="infoMessageThumb">
                  <img
                    src="https://s3-eu-west-1.amazonaws.com/storegroupseditedbgimages/{{
                    groupApp.group
                  }}/info_messages/{{ homeStoreMessage.image }}"
                  />
                </ion-thumbnail>
              </ion-col>
              <ion-col
                [size]="!homeStoreMessage.image ? '12':'9'"
                class="ion-no-padding"
              >
                <div>
                  <ion-text
                    *ngIf="homeStoreMessage && homeStoreMessage.title"
                    [innerHTML]="homeStoreMessage.title[selectedLangugage] ?
                      homeStoreMessage.title[selectedLangugage]
                      :(homeStoreMessage.title[mo_base] ?
                      homeStoreMessage.title[mo_base] :
                      homeStoreMessage.title['en']) "
                    style="line-height: 1.5; font-weight: bold"
                  ></ion-text>
                </div>
              </ion-col>
            </ion-row>
            <ion-progress-bar
              *ngIf="homeStoreMessage.auto_dismiss && homeStoreMessage.auto_dismiss_seconds"
              [value]="motoCardsProgressValues[homeStoreMessage.id]"
              color="success"
              style="height: 5px"
            >
            </ion-progress-bar>
          </ion-card>
        </span>

        <span
          [class]="groupApp && groupApp.pointsSystem ? 'infoMessageCardWithPoints':'infoMessageCardWithoutPoints'"
          *ngIf="((homepageInfoMessagesGroup && homepageInfoMessagesGroup.alert && homepageInfoMessagesGroup.alert.length)) && selectedLangugage && mo_base && groupApp && windowDimensions && windowDimensions.width && windowDimensions.width<1080"
        >
          <ion-card
            [ngClass]="infoMessagesAnimations[homeGroupMessage.id] ?    'animate__animated animate__backInRight' :  'animate__animated animate__fadeOut'"
            [hidden]="infoMessagesCardsHide[homeGroupMessage.id] "
            color="primary"
            mode="md"
            [button]="homeGroupMessage.alert_link ? true: false"
            (click)="openExternalLink(homeGroupMessage.alert_link)"
            [ngStyle]="{top: (50+ index * 10).toString() + '%'}"
            *ngFor="let homeGroupMessage of homepageInfoMessagesGroup.alert;let index = index"
          >
            <ion-icon
              (click)="$event.stopImmediatePropagation();closeInfoCard(homeGroupMessage.id)"
              style="
                position: absolute;
                top: 1px;
                right: 1px;
                font-size: 19px;
                cursor: pointer;
                z-index: 150;
              "
              src="assets/ionicons/close.svg"
            ></ion-icon>

            <ion-row
              class="infoMessageCardRow ion-align-items-center ion-justify-content-center"
            >
              <ion-col
                *ngIf="homeGroupMessage.image"
                size="4"
                class="ion-no-padding"
              >
                <ion-thumbnail class="infoMessageThumb">
                  <img
                    src="https://s3-eu-west-1.amazonaws.com/storegroupseditedbgimages/{{
                    groupApp.group
                  }}/info_messages/{{ homeGroupMessage.image }}"
                  />
                </ion-thumbnail>
              </ion-col>
              <ion-col
                [size]="!homeGroupMessage.image ? '12':'8'"
                class="ion-no-padding"
              >
                <div>
                  <ion-text
                    [innerHTML]="homeGroupMessage.title[selectedLangugage] ?
                                  homeGroupMessage.title[selectedLangugage]
                                   :(homeGroupMessage.title[mo_base] ?
                                   homeGroupMessage.title[mo_base] :
                                   homeGroupMessage.title['en']) "
                    style="line-height: 1.5; font-weight: bold"
                    *ngIf="homeGroupMessage && homeGroupMessage.title"
                  ></ion-text>
                </div>
              </ion-col>
            </ion-row>
            <ion-progress-bar
              *ngIf="homeGroupMessage.auto_dismiss && homeGroupMessage.auto_dismiss_seconds"
              [value]="motoCardsProgressValues[homeGroupMessage.id]"
              color="success"
              style="height: 5px"
            >
            </ion-progress-bar>
          </ion-card>
        </span>
      </ion-col>

      <ion-col
        *ngIf="loginState && loginState==='login_loading'"
        class="ion-align-self-center ion-text-center"
        style="max-width: 450px; max-height: 100%"
      >
        <ion-spinner color="dark"></ion-spinner>
      </ion-col>
    </ion-row>

    <!-- TO-DO CHECK THIS MODAL WITH PREVIOUS ORDERS
      <ion-modal
      [hidden]="!(previousOrders && previousOrders.length &&loginState && loginState==='login_completed' && windowDimensions && windowDimensions.width && windowDimensions.width<750)"
      #previousOrdersSwipeModal
      trigger="open-modal"
      [initialBreakpoint]="0.20"
      [breakpoints]="[0.20, 0.60]"
      handleBehavior="cycle"
      class="previous-orders-homepage-modal"
    >
      <ng-template>
        <ion-header>
          <ion-toolbar>
            <ion-title size="small"
              >{{"catalog.previous-order.previous-orders" |
              translate}}</ion-title
            >
          </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
          <ion-grid>
            <ion-card
              *ngFor="let order of previousOrders"
              button
              color="primary"
              detail="false"
              (click)="showPreviousOrderModal(order)"
              style="z-index: 20; cursor: pointer"
            >
              <ion-item
                detail="false"
                style="z-index: 10"
                color="primary"
                class="productItem"
                lines="none"
              >
                <ion-label class="ion-text-right">
                  <ion-text style="font-weight: bold; font-size: small"
                    >#{{ order.order_id }}</ion-text
                  >
                </ion-label>
              </ion-item>
              <ion-item
                color="primary"
                detail="false"
                style="z-index: 10"
                class="productItem"
                lines="none"
                *ngFor="let product of order.products"
              >
                <ion-label>
                  <ion-text
                    *ngIf="product"
                    style="font-weight: bold; font-size: small"
                  >
                    {{ product.quantity }} x {{ product.name }}
                  </ion-text>
                </ion-label>
              </ion-item>
            </ion-card>
          </ion-grid>
        </ion-content>
      </ng-template>
    </ion-modal> -->
  </ion-grid>
</ion-content>

<ion-footer
  *ngIf="groupApp && (!groupApp.design_settings || !groupApp.design_settings.simplified_homepage)"
  [class]="imagesArray && imagesArray.length ===0? 'footerBackgroundColor':'footerBackgroundTransparent'"
>
  <ion-grid
    *ngIf="loginState && (loginState==='no_login' || loginState ==='login_completed')"
    [class]="isIos && !currentUser ? 'iosFooter' : ''"
  >
    <ion-row
      style="width: 100%"
      class="ion-align-items-center ion-justify-content-center"
    >
      <ion-col
        *ngIf="groupApp && groupApp.cardIcons"
        style="width: 100%"
        class="ion-text-center"
      >
        <div style="align-items: center; vertical-align: middle; height: 100%">
          <img
            *ngIf="groupApp.cardIcons.visa"
            width="35px"
            alt="visa"
            style="padding-left: 2px; padding-right: 2px"
            src="https://data.deliverymanager.gr/bankicons/visa.png"
          />
          <img
            *ngIf="groupApp.cardIcons.mastercard"
            width="35px"
            alt="mastercard"
            style="padding-left: 2px; padding-right: 2px"
            src="https://data.deliverymanager.gr/bankicons/mastercard.png"
          />
          <img
            *ngIf="groupApp.cardIcons.maestro "
            width="55px"
            alt="maestro"
            style="padding-left: 2px; padding-right: 2px"
            src="https://data.deliverymanager.gr/bankicons/maestro.png"
          />
          <img
            *ngIf="groupApp.cardIcons.discover"
            width="55px"
            alt="discover"
            style="padding-left: 2px; padding-right: 2px"
            src="https://data.deliverymanager.gr/bankicons/discover.png"
          />
          <img
            *ngIf="groupApp.cardIcons.diners"
            width="48px"
            alt="diners"
            style="padding-left: 2px; padding-right: 2px"
            src="https://data.deliverymanager.gr/bankicons/diners.png"
          />
          <img
            *ngIf="groupApp.cardIcons.american"
            width="88px"
            alt="american"
            style="padding-left: 2px; padding-right: 2px"
            src="https://data.deliverymanager.gr/bankicons/american.png"
          />

          <img
            *ngIf="groupApp.cardIcons.klarna"
            height="24px"
            alt="klarna"
            style="padding-left: 2px; padding-right: 2px"
            src="https://data.deliverymanager.gr/bankicons/klarna-logo.png"
          />
          <img
            *ngIf="groupApp.cardIcons.paypal"
            height="24px"
            alt="paypal"
            style="padding-left: 2px; padding-right: 2px"
            src="https://data.deliverymanager.gr/bankicons/paypal.png"
          />
          <img
            *ngIf="groupApp.cardIcons.ticketrestaurant"
            height="24px"
            style="padding-left: 2px; padding-right: 2px"
            src="https://data.deliverymanager.gr/bankicons/ticketrestaurant.png"
          />
          <img
            *ngIf="groupApp.cardIcons.mastercardsimple"
            height="30px"
            style="padding-left: 2px; padding-right: 2px"
            src="https://data.deliverymanager.gr/bankicons/mastercard-simple.png"
          />
          <img
            *ngIf="groupApp.cardIcons.visasimple"
            height="28px"
            style="padding-left: 2px; padding-right: 2px"
            src="https://data.deliverymanager.gr/bankicons/visa-simple.png"
          />

          <img
            *ngIf="groupApp.cardIcons.googlePay"
            alt="google-pay"
            width="60px"
            style="padding-left: 2px; padding-right: 2px"
            src="https://data.deliverymanager.gr/bankicons/googlepay.png"
          />

          <img
            *ngIf="groupApp.cardIcons.applePay"
            alt="apple-pay"
            width="60px"
            style="padding-left: 2px; padding-right: 2px"
            src="https://data.deliverymanager.gr/bankicons/applepay.png"
          />
        </div>
      </ion-col>
      <ion-col
        *ngIf="!currentUser"
        size="12"
        class="ion-text-center ion-no-margin ion-no-padding"
      >
        <ion-button
          color="homepageButtons"
          [disabled]="!loginLoading"
          [shape]="buttonShape"
          [size]="connectButtonSize"
          (click)="presentMagicLoginPopup()"
        >
          <ion-text *ngIf="loginLoading">
            {{"homepage.magic-login"| translate}}
          </ion-text>
          <ion-spinner *ngIf="!loginLoading"></ion-spinner>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>

<app-menu-footer-tabs> </app-menu-footer-tabs>
