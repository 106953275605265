<ion-item
  *ngIf="category"
  class="categoryItem ion-no-padding ion-no-margin"
  [id]="category.category_id"
>
  <ion-button
    *ngIf="sliderButtons"
    style="position: absolute; top: 0px; right: 30px"
    size="small"
    fill="clear"
    (click)="prevSlide()"
  >
    <ion-icon slot="icon-only" src="assets/ionicons/chevron-back-outline.svg">
    </ion-icon>
  </ion-button>
  <ion-button
    *ngIf="sliderButtons"
    style="position: absolute; top: 0px; right: 2px"
    size="small"
    (click)="nextSlide()"
    fill="clear"
  >
    <ion-icon
      slot="icon-only"
      src="assets/ionicons/chevron-forward-outline.svg"
    >
    </ion-icon>
  </ion-button>
  <ion-row style="width: 100%">
    <ion-col size="12" class="noPaddingCols">
      <strong style="font-size: larger; padding: 0">
        <ion-text color="primary">
          {{ category.name }}
        </ion-text>
      </strong>
    </ion-col>
    <ion-col
      size="12"
      class="noPaddingCols"
      *ngIf="!category.availableTotal || category.avpc"
    >
      <app-category-item-tags
        [categoryItem]="category"
        [groupStoreData]="groupStoreData"
      ></app-category-item-tags>
      <!-- {{ "catalog.categories.category-item.unavailable-category" | translate }} -->
    </ion-col>

    <ion-col size="12" class="DescriptionPaddings" *ngIf="category.description">
      <span
        [ngClass]="{
          'unavailable line-through ': !!!category.availableTotal
        }"
        class="fontSizeSmaller"
      >
        <app-description [description]="category.description"></app-description>
      </span>
    </ion-col>
    <ion-col
      size="12"
      class="noPaddingCols"
      *ngIf="category.promotions && category.promotions.length > 0"
    >
      <div
        *ngFor="
          let promo of category.promotions;
          let i = index;
          trackBy: trackFunc
        "
        class="categoriesWarningColor"
      >
        <span style="font-weight: bold; font-size: small">{{
          promo.description
        }}</span>
      </div>
    </ion-col>
  </ion-row>
</ion-item>

<div *ngIf="category && category.activeTotal">
  <ion-slides
    #productsSlider
    class="ion-text-center"
    scrollbar
    [options]="sliderOptions"
  >
    <ion-slide
      style="width: 280px; height: 150px"
      *ngFor="let product of category.products; trackBy: trackProducts"
    >
      <ion-card
        class="ion-no-margin"
        button
        detail="false"
        (click)="showProductDetailsModal(product)"
        style="width: 90%; height: 95%; cursor: pointer"
        *ngIf="product.activeTotal && category.activeTotal"
      >
        <img
          *ngIf="
            product &&
            product.image &&
            product.image.true &&
            product.image.true['16x9'] &&
            product.image.true['16x9'].path
          "
          [alt]="product.name"
          loading="lazy"
          style="z-index: 0px"
          class="ImageDemensions"
          [src]="
            'https://images.deliverymanager.gr/' +
            product.image.true['16x9'].path +
            (webp ? '.webp' : product.image.true['16x9'].type || '.jpg')
          "
        />
      </ion-card>
    </ion-slide>
  </ion-slides>
</div>
