import { Action } from "@ngrx/store";

export const SET_REQUESTS_ERROR_HANDLING =
  "[REQUESTS ERROR HANDLING] Set Requests Error Handling";
export const ADD_UPDATE_REQUESTS_ERROR_HANDLING =
  "[REQUESTS ERROR HANDLING]  Add or Update Requests Error Handling";
export const CLEAR_REQUESTS_ERROR_HANDLING =
  "[REQUESTS ERROR HANDLING]   Clear Requests Error Handling";

export class SetRequestsErrorHandling implements Action {
  readonly type = SET_REQUESTS_ERROR_HANDLING;
  constructor(public payload: any) {}
}

export class AddUpdateRequestsErrorHandling implements Action {
  readonly type = ADD_UPDATE_REQUESTS_ERROR_HANDLING;
  constructor(public payload: any) {}
}

export class ClearRequestsErrorHandling implements Action {
  readonly type = CLEAR_REQUESTS_ERROR_HANDLING;
  constructor() {}
}

export type RequestsErrorHandlingActions =
  | SetRequestsErrorHandling
  | AddUpdateRequestsErrorHandling
  | ClearRequestsErrorHandling;
