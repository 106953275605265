<ion-card style="border-radius: 10px">
  <ion-grid class="ion-no-padding">
    <ion-row>
      <ion-col [size]="'12'">
        <swiper style="height: 100%" [pagination]="true" effect="creative" grabCursor="true" #swiperSlideShow
          [creativeEffect]="{
            prev: {
              shadow: true,
              translate: ['-20%', 0, -1]
            },
            next: {
              translate: ['100%', 0, 0]
            }
          }">
          <ng-template swiperSlide *ngFor="let prize of contest.gifts; trackBy: trackGift">
            <ion-grid style="height: 100%" class="ion-no-padding">
              <ion-row style="width: 100%; height: 100%">
                <ion-col>
                  <ion-img *ngIf="prize.image" style="width: 100%; height: 100%; object-fit: cover" [src]="
                      'https://d3vm1ym7xlqx7l.cloudfront.net/' +
                      group +
                      '/contests/' +
                      contest.contest_id +
                      '/' +
                      prize.image
                    "></ion-img>
                  <ion-img *ngIf="!prize.image" style="width: 100%; height: 100%; object-fit: cover" [src]="
                      'https://d3vm1ym7xlqx7l.cloudfront.net/' +
                      group +
                      '/contests/default-gift.jpg'
                    "></ion-img>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ng-template>
        </swiper>
      </ion-col>
      <ion-col [size]="'12'">
        <ion-card-header style="padding-bottom: 0">
          <ion-card-title>{{ contest.title }}</ion-card-title>
          <ion-card-subtitle style="margin-top: 10px">
            <ion-icon style="vertical-align: bottom" [color]="statusColor" name="ellipse"></ion-icon>
            <ion-text *ngIf="
                currentDate >= contest.activeFrom &&
                currentDate < contest.activeTo
              ">
              {{ "loyaltySystem.valid-from" | translate }}
              {{ contest.activeFrom | date : "dd/MM" }}
              {{ "loyaltySystem.valid-to" | translate }}
              {{ contest.activeTo | date : "dd/MM" }}
            </ion-text>
            <ion-text *ngIf="currentDate > contest.activeTo">
              {{ "expired" | translate }}
              {{ contest.activeTo | date : "dd/MM" }}
            </ion-text>
            <ion-text *ngIf="currentDate < contest.activeFrom">
              {{ "loyaltySystem.starts" | translate }}
              {{ contest.activeFrom | date : "dd/MM" }}
            </ion-text>
            <ion-badge style="vertical-align: bottom; float: right" *ngIf="contest.status === 'completed'"
              color="success">
              {{ "loyaltySystem.contest-completed" | translate }}
            </ion-badge>
          </ion-card-subtitle>
        </ion-card-header>

        <ion-card-content style="padding-top: 13px">
          <ion-grid *ngIf="
              contest.collection_methods && contest.collection_methods.length
            ">
            <ion-row *ngFor="let collectionMethod of contest.collection_methods">
              <ion-col class="ion-text-center" size="12" *ngIf="collectionMethod.fromPoints">
                <ion-badge>
                  <ion-text style="font-size: larger">{{ collectionMethod.points_ratio }}
                  </ion-text>
                  <ion-text>{{ "points-translation" | translate }}</ion-text>
                </ion-badge>
                <ion-icon src="/assets/flatIcons/equals.svg" style="
                    font-size: large;
                    padding-left: 10px;
                    padding-right: 10px;
                    padding-bottom: 2px;
                  ">
                </ion-icon>
                <ion-badge>
                  <ion-text style="font-size: larger">1</ion-text>
                  <ion-text>
                    {{ "loyaltySystem.participation" | translate }}</ion-text>
                </ion-badge>
              </ion-col>

              <ion-col class="ion-text-center" size="12" *ngIf="collectionMethod.fromOrders">
                <ion-badge>
                  <ion-text style="font-size: larger">{{
                    collectionMethod.amount_ratio
                    }}</ion-text>
                  <ion-text>€ {{ "loyaltySystem.order" | translate }}</ion-text>
                </ion-badge>
                <ion-icon src="/assets/flatIcons/equals.svg" style="
                    font-size: large;
                    padding-left: 10px;
                    padding-right: 10px;
                    padding-bottom: 2px;
                  ">
                </ion-icon>
                <ion-badge>
                  <ion-text style="font-size: larger">1</ion-text>
                  <ion-text>{{ "loyaltySystem.participation" | translate }}
                  </ion-text>
                </ion-badge>
              </ion-col>
            </ion-row>
          </ion-grid>

          <ion-row style="width: 100%; border-bottom: 1px solid">
            <ion-col>
              <!-- <ion-item
                *ngIf="contest.num_of_winners && contest.num_of_winners > 1"
                lines="none"
                style="--padding-start: 0px !important"
              >
                <ion-icon
                  name="trophy"
                  color="primary"
                  style="margin-right: 10px"
                ></ion-icon>
                <ion-label>
                  <h2>
                    {{ "loyaltySystem.number-of-winners" | translate }}:
                    {{ contest.num_of_winners }}
                  </h2>
                </ion-label>
              </ion-item> -->
              <ion-item *ngIf="contest && contest.gifts && contest.gifts.length > 1" lines="none" button="true"
                (click)="showRaffleGiftsModal(contest)">
                <ion-icon name="gift" color="primary" style="margin-right: 10px"></ion-icon>
                <ion-label>
                  <h2>{{ "loyaltySystem.contest-gifts" | translate }}</h2>
                </ion-label>
                <ion-icon name="information-circle" color="primary" style="margin-right: 10px"></ion-icon>
              </ion-item>
              <ion-item button="true" lines="none" (click)="openContestModal()">
                <ion-icon name="ticket" color="primary" style="margin-right: 10px"></ion-icon>
                <ion-label style="text-wrap: initial">
                  <h2>{{ "loyaltySystem.info-participations" | translate }}</h2>
                </ion-label>
                <ion-icon name="information-circle" color="primary" style="margin-right: 10px"></ion-icon>
              </ion-item>

              <ion-item *ngIf="contest.status === 'completed' && contest.winners && contest.winners.length > 0"
                button="true" lines="none" (click)="openWinnersModal()">
                <ion-icon name="trophy-outline" color="primary" style="margin-right: 10px"></ion-icon>
                <ion-label style="text-wrap: initial">
                  <h2>{{ "loyaltySystem.winners" | translate }}</h2>
                </ion-label>
                <ion-icon name="information-circle" color="primary" style="margin-right: 10px"></ion-icon>
              </ion-item>
            </ion-col>
          </ion-row>
          <p style="margin-top: 15px">
            {{ contest.short_description }}
          </p>
        </ion-card-content>
        <ion-row *ngIf="
            currentDate >= contest.activeFrom &&
            currentDate < contest.activeTo &&
            activeContestDrawsSum !== null
          " style="margin-bottom: 10px">
          <ion-grid *ngIf="activeContestDrawsSum !== null" style="max-width: 350px">
            <ion-row style="
                background-color: var(--ion-color-primary);
                border-radius: 20px;
              ">
              <ion-col>
                <ion-item lines="none" button="true" style="
                    --border-radius: 20px;
                    --background: var(--ion-color-primary);
                  " (click)="openContestModal()">
                  <ion-avatar slot="start" style="height: 50px; width: 50px">
                    <img *ngIf="
                        !currentUser ||
                        (currentUser && !currentUser.profile_image)
                      " alt="Silhouette of mountains" src="https://ionicframework.com/docs/img/demos/thumbnail.svg" />

                    <img width="auto" height="auto" *ngIf="
                        currentUser &&
                        currentUser.profile_image &&
                        currentUser.profile_image.optimized
                      " [src]="
                        'https://primg.deliverymanager.gr/' +
                        group +
                        '/' +
                        currentUser.customer_id +
                        '/' +
                        currentUser.profile_image.filename
                      " />
                  </ion-avatar>
                  <ion-label style="color: white; font-weight: bold">
                    {{ "loyaltySystem.my-entries" | translate }}
                  </ion-label>
                  <ion-button class="drawsSum" style="cursor: none" shape="round">
                    {{ activeContestDrawsSum }}
                  </ion-button>
                </ion-item>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-card>