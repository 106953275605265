import { Pipe, PipeTransform } from '@angular/core';
import * as _ from "lodash";

@Pipe({
  name: 'reservationTables'
})
export class ReservationTablesPipe implements PipeTransform {
  transform(tables: any[], seats: string): any[] {
    if (!tables || !seats || !_.isFinite(parseInt(seats))){
      return tables;
    }

    return _.filter(tables, table => table.seats <= parseInt(seats) && table.max_seats >= parseInt(seats));
  }

}
