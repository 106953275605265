<!-- <div *ngFor="let option of optionWithOnlySelectedChoices">
  <ion-item
    *ngIf="
      option &&
      option.type &&
      option.type !== 'field' &&
      option.option_id &&
      (!option.dependenciesVariable ||
        option.dependenciesVariable.length === 0 ||
        (option.dependenciesVariable.length > 0 && !option.dependentHidden) ||
        (option.dependenciesVariable.length > 0 &&
          option.dependentHidden &&
          option.showDependent))
    "
  >
    <ion-label class="ion-text-wrap">
      <strong>{{ option.title }}:&nbsp;</strong>
      <ion-text
        style="font-size: small"
        *ngFor="let choice of option.choices; let i = index"
        >{{ choice.title
        }}{{
          option.choices.length > 1 && i < option.choices.length - 1 ? "," : ""
        }}&nbsp;</ion-text
      >
    </ion-label>
  </ion-item>
</div> -->

<ion-list *ngIf="showComponent">
  <ion-item-divider mode="ios">
    <ion-label>
      {{ "product-details.your-choices-in-product" | translate }}
    </ion-label>
  </ion-item-divider>
  <ion-item>
    <ion-label class="ion-text-wrap">
      <div>
        <div>
          <span *ngFor="let option of selectedProduct.options">
            <div
              *ngIf="
                option.option_id &&
                (!option.dependenciesVariable ||
                  option.dependenciesVariable.length === 0 ||
                  (option.dependenciesVariable.length > 0 &&
                    option.showDependent))
              "
              class="secondaryFontSize item-text-wrap"
            >
              <p
                style="font-size: smaller; margin: 0px"
                *ngIf="
                  option.type !== 'field' &&
                  (!(
                    option.choices &&
                    !option.reverseChoices &&
                    (option.choices | choicesPreselected).length === 0
                  ) ||
                    (option.is_require && option.availableTotal))
                "
              >
                <strong>
                  {{
                    option.doNotPrint
                      ? ""
                      : option.title
                      ? option.title + ": "
                      : ""
                  }}
                  {{
                    option.parent_free && option.option_parent_total_price === 0
                      ? "(" +
                        ("cart.cart-components.cart-list.cart-list-product-item.free"
                          | translate) +
                        ")"
                      : option.option_parent_total_price
                      ? option.parent_free && selectedProduct.parent_discount
                        ? option.option_parent_total_price +
                          "€ (-" +
                          selectedProduct.parent_discount +
                          "%)"
                        : option.option_parent_total_price + "€"
                      : ""
                  }}
                </strong>
                <span
                  *ngIf="
                    option.type === 'checkbox' &&
                    option.reverseChoices &&
                    option.choicesComment
                  "
                  style="display: inline-block"
                  class="mainFontSize"
                  >{{ option.choicesComment }}
                </span>
                <span
                  *ngFor="
                    let choice of option.choices | choicesPreselected;
                    let last = last
                  "
                >
                  <span
                    style="display: inline-block"
                    *ngIf="
                      !choice.bundle &&
                      !choice.doNotPrint &&
                      (option.type !== 'checkbox' ||
                        !option.reverseChoices ||
                        (!option.choicesComment && !option.reverseChoices))
                    "
                  >
                    {{ choice.printLabel ? choice.printLabel : choice.title
                    }}<strong *ngIf="!choice.choicePriceComment">{{
                      choice.isFree
                        ? ("cart.cart-components.cart-list.cart-list-product-item.free-choice"
                          | translate)
                        : !choice.price
                        ? ""
                        : " " +
                          (orderPickup == true && dineIn == false
                            ? choice.price_takeaway || choice.price
                            : orderPickup == false && dineIn == true
                            ? store_info.dinein &&
                              store_info.alt_dinein_active &&
                              selectedDineInTable &&
                              selectedDineInTable.alt_dinein &&
                              choice.price_alt_dinein
                              ? choice.price_alt_dinein
                              : choice.price_dinein || choice.price
                            : choice.price) +
                          "€"
                    }}</strong
                    ><span *ngIf="choice.choicePriceComment">{{
                      choice.choicePriceComment
                    }}</span
                    >{{ last ? "" : "," }}&nbsp;
                  </span>

                  <app-cart-list-product-choice-comment
                    [bundleProduct]="choice.bundled_product"
                    [bundle_options]="choice.bundle_options"
                    [orderPickup]="orderPickup"
                    [store_info]="store_info"
                    [selectedDineInTable]="selectedDineInTable"
                    [dineIn]="dineIn"
                    *ngIf="
                      choice.bundle &&
                      choice.bundled_with_product_id &&
                      choice.bundled_product &&
                      !choice.doNotPrint &&
                      (option.type !== 'checkbox' ||
                        !option.reverseChoices ||
                        (!option.choicesComment && !option.reverseChoices))
                    "
                  >
                  </app-cart-list-product-choice-comment>
                </span>
                <!-- 
                  <span
                    class="cartWarningColor"
                    ng-if="
                      option.choices &&
                      (option.choices | filter: { is_preselected: true }).length ===
                        0 &&
                      option.is_require
                    "
                  >
                      {{
                        "cart.cart-components.cart-list.cart-list-product-item.you-must-make-a-choice"
                          | translate
                      }}
                  </span> -->
              </p>

              <p
                style="margin: 0 auto 0 0; font-size: smaller"
                *ngIf="option.type === 'field' && option.text"
              >
                <strong>{{ option.title }}:</strong> {{ option.text }}
              </p>
            </div>
          </span>
        </div>
      </div>
    </ion-label>
  </ion-item>
</ion-list>
