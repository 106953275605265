import * as _ from "lodash";
import * as CompleteOrderValidationActions from "./completeOrderValidation.actions";

export interface State {
  lastValidTimestamp: any;
  completeOrderValidations: any[];
  checkAddressSuccess: boolean;
  finalPriceSuccess: boolean;
}

const initialState: State = {
  lastValidTimestamp: null,
  completeOrderValidations: [],
  checkAddressSuccess: false,
  finalPriceSuccess: false,
};

export function completeOrderValidationReducer(
  state = initialState,
  action: CompleteOrderValidationActions.CompleteOrderValidationActions
) {
  switch (action.type) {
    case CompleteOrderValidationActions.SET_COMPLETE_ORDER_VALIDATION:
      return {
        ...state,
        lastValidTimestamp: action.payload.lastValidTimestamp,
        completeOrderValidations: [
          ...state.completeOrderValidations,
          action.payload.completeOrderValidations,
        ],
      };

    case CompleteOrderValidationActions.ADD_UDPATE_COMPLETE_ORDER_VALIDATION:
      const key = _.findIndex(
        state.completeOrderValidations,
        (orderValidationObj) => {
          return orderValidationObj.id === action.payload.id;
        }
      );
      if (key !== -1) {
        const updateOrder = {
          ...state.completeOrderValidations[key],
          ...action.payload,
        };
        const updatedOrderValidation = [...state.completeOrderValidations];
        updatedOrderValidation[key] = updateOrder;
        return {
          ...state,
          completeOrderValidations: updatedOrderValidation,
        };
      } else {
        if (!state.completeOrderValidations) {
          return {
            ...state,
            completeOrderValidations: [action.payload],
          };
        } else {
          return {
            ...state,
            completeOrderValidations: [
              ...state.completeOrderValidations,
              action.payload,
            ],
          };
        }
      }
    case CompleteOrderValidationActions.CLEAR_COMPLETE_ORDER_VALIDATION:
      return {
        ...state,
        lastValidTimestamp: null,
        completeOrderValidations: [],
      };
    case CompleteOrderValidationActions.SET_FINAL_PRICE_SUCCESS:
      return {
        ...state,
        checkAddressSuccess: null,
      };
    case CompleteOrderValidationActions.SET_CHECK_ADDRESS_SUCCESS:
      return {
        ...state,
        finalPriceSuccess: null,
      };

    default:
      return state;
  }
}
