import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import { ModalController, Platform } from "@ionic/angular";

import { AnimationOptions } from "ngx-lottie";
import { Subscription } from "rxjs";

declare let window: any;

@Component({
  selector: "app-general-info-modal",
  templateUrl: "./general-info-modal.component.html",
  styleUrls: ["./general-info-modal.component.scss"],
})
export class GeneralInfoModalComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() message: any;
  @Input() ok_button_text: any;
  @Input() go_to_link: string;
  @Input() lottie_url: any;

  public loading = false;

  private unsubscribeBackEvent: Subscription;

  animationOptions: AnimationOptions = {
    path: "",
  };

  constructor(
    private modalController: ModalController,
    private platform: Platform
  ) {}

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );
    this.animationOptions = {
      ...this.animationOptions,
      path: this.lottie_url,
    };
  }

  closeModal() {
    this.modalController.dismiss();
  }

  animationCreated(ev) {}

  onClickOk() {
    if (this.go_to_link) {
      this.loading = true;
      this.openLink(this.go_to_link);
      this.modalController.dismiss();
    }
  }

  openLink = async (link) => {
    //open link external on new page
    if (Capacitor.isNativePlatform()) {
      window.open(link, "_system");
    } else {
      window.open(link, "_blank");
    }
  };

  ngOnDestroy(): void {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
  }
}
