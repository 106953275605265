import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Store } from "@ngrx/store";
import { filter, map, Observable } from "rxjs";
import * as fromApp from "../store/app.reducer";

@Injectable({
  providedIn: "root",
})
export class BlockReservationAppGuard implements CanActivate {
  constructor(private router: Router, private store: Store<fromApp.AppState>) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store.select("groupApp").pipe(
      map((groupApp) => {
        return groupApp.groupApp;
      }),
      filter((groupApp) => groupApp !== null),
      map((groupApp) => {
        if (!groupApp.active_reservation_app) {
          return this.router.createUrlTree(["/homepage"]);
        } else {
          return true;
        }
      })
    );
  }
}
