<ion-card>
  <ion-item-divider mode="ios">
    {{ "cart.cart-components.add-coupon-code.title" | translate }}
  </ion-item-divider>

  <ion-item-divider
    mode="ios"
    color="primary"
    *ngIf="
      groupApp &&
      groupApp.affiliateActive &&
      groupApp.affiliateSideMenuTitle &&
      languagePath
    "
    (click)="navigate('affiliate')"
    style="
      cursor: pointer;
      max-height: 40px;
      min-height: 20px;
      font-size: small;
    "
  >
    <ion-label style="margin: 2px; line-height: 1.2" class="ion-text-wrap">
      <ion-text style="font-weight: 600"
        >{{ "affiliate.bring-your-friends" | translate }}!&nbsp;</ion-text
      >

      <strong> {{ groupApp.affiliateSideMenuTitle[languagePath] }}</strong>
    </ion-label>
    <ion-icon
      style="font-size: 20px"
      slot="end"
      name="information-circle-outline"
    >
    </ion-icon>
  </ion-item-divider>

  <ion-list>
    <ion-item>
      <!-- <ion-label class="ion-text-wrap" position="stacked">
        {{ "cart.cart-components.add-coupon-code.coupon-code" | translate }}
      </ion-label> -->
      <ion-input
        placeholder="{{
          'cart.cart-components.add-coupon-code.write-here-coupon-code'
            | translate
        }}"
        (ionChange)="change($event)"
        type="text"
        [(ngModel)]="couponCode"
      >
      </ion-input>
      <ion-button
        [color]="dirtyField ? 'danger' : 'primary'"
        [disabled]="loading || !couponCode"
        (click)="addCouponCode()"
        size="medium"
        [fill]="dirtyField ? 'solid' : 'clear'"
        slot="end"
      >
        <ion-icon
          slot="icon-only"
          *ngIf="!loading"
          src="assets/ionicons/add-outline.svg"
        >
        </ion-icon>
        <ion-spinner *ngIf="loading"></ion-spinner>
      </ion-button>
    </ion-item>
  </ion-list>

  <ion-grid
    *ngIf="infoMessage && infoMessagesCardsHide"
    [hidden]="infoMessagesCardsHide[infoMessage.id]"
    style="width: 100%; z-index: 1000"
  >
    <ion-card
      color="primary"
      mode="md"
      [button]="infoMessage && infoMessage.navigationPage ? true : false"
      (click)="
        infoMessage && infoMessage.navigationPage
          ? navigateToPage(infoMessage)
          : ''
      "
    >
      <img
        *ngIf="infoMessage.image && groupApp"
        src="https://s3-eu-west-1.amazonaws.com/storegroupseditedbgimages/{{
          groupApp.group
        }}/info_messages/{{ infoMessage.image }}"
      />
      <ion-row
        *ngIf="
          infoMessage &&
          infoMessage.title &&
          languagePath &&
          (infoMessage.title[languagePath] ||
            infoMessage.title[mo_base] ||
            infoMessage.title['en'])
        "
        class="infoMessageCardRow ion-align-items-center ion-justify-content-center"
      >
        <ion-col class="ion-text-center" size="12">
          <div style="padding: 5px">
            <ion-text
              [innerHTML]="
                infoMessage.title[languagePath]
                  ? infoMessage.title[languagePath]
                  : infoMessage.title[mo_base]
                  ? infoMessage.title[mo_base]
                  : infoMessage.title['en']
              "
              style="line-height: 1.4; font-weight: bold"
              *ngIf="infoMessage && infoMessage.title"
            ></ion-text>
          </div>
        </ion-col>
      </ion-row>
      <ion-progress-bar
        *ngIf="infoMessage.auto_dismiss && infoMessage.auto_dismiss_seconds"
        [value]="motoCardsProgressValues[infoMessage.id]"
        color="success"
        style="height: 5px"
      ></ion-progress-bar>
    </ion-card>
  </ion-grid>
</ion-card>
