import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../store/app.reducer";
import * as selectors from "../../../store/app.selectors";
import * as _ from "lodash";
import {
  auditTime,
  debounceTime,
  distinctUntilChanged,
  first,
} from "rxjs/operators";
import * as CategoriesSelectionActions from "../../categories/store/categoriesSelection.actions";
import { Subscription } from "rxjs";
import * as currentStoreActions from "../../../store/current-store/current-store.actions";
import { SelectStorePopupComponent } from "src/app/popups/select-store-popup/select-store-popup.component";
import { AlertController, ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { UnavailableProductsModalComponent } from "../../categories/unavailable-products-modal/unavailable-products-modal.component";
import { SetCatalog } from "../../store/catalog.actions";
import * as CatalogActions from "../../store/catalog.actions";
import * as DisableFinalPriceActions from "../../../store/disable-final-price/disable-final-price.actions";

@Component({
  selector: "app-browser-product-list",
  templateUrl: "./browser-product-list.component.html",
  styleUrls: ["./browser-product-list.component.scss"],
})
export class BrowserProductListComponent implements OnInit, OnDestroy {
  @Output() listScrollFromBottomEvent = new EventEmitter();
  @Output() listScrollFromTopEvent = new EventEmitter();
  public storeInfo: any;
  public currentUser: any;
  public categories: any;
  public selectedCategoryId;
  public currentScrollCategoryId: any;
  public cartProductIds: any;
  public windowDimensions: any;
  public currentHyperCategory: any;
  public hyperCategories: any;
  public cartProducts: any;
  public direction: any;
  public selectedStoreId: any;
  public stores: any;
  public groupApp: any;
  public unavailableProductsByCategoryId: any = {};
  public category_id: any;
  public allCategories: any;
  public selectedDineInTable: any;
  public selectedPreorderDate: any;
  public changedUnavailableProductsByCategoryIdTimestamp: string;
  public mo_base;
  public selectedLanguage;
  public scrollFromBottom;
  public allCategoriesMarketCardsDesign;
  public showNextCategoryButton;
  public hyperCategoriesForProductItem: any;
  public atLeastOneFrozenTag: boolean = false;

  private changed = false;
  private catalogSubscription: Subscription;
  private catalogUserPricelistSub: Subscription;
  private unavailableProductsModal: any;
  private subscriptions: Subscription[] = [];
  private customerPricelist;
  private customerPromotions: any;
  private loginState;
  private calculateApiState;
  private disableFinalPrice;
  private checkExclusiveCustomerPromotionsCompleted;


  public orderPickup;
  public dineIn;
  public webp: any;
  public groupStoreData = {
    groupApp: null,
    store_info: null,
    webp: null,
    selectedLanguage: null,
    mo_base: null,
    forCustomerPricelists: null,
  };

  constructor(
    private store: Store<fromApp.AppState>,
    private modalCtrl: ModalController,
    private alertController: AlertController,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.store.dispatch(
      new DisableFinalPriceActions.SetTriggerFinalPriceTimestamp(
        Date.now().toString()
      )
    );

    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .subscribe((storeInfo) => {
          if (storeInfo && !_.isEqual(this.storeInfo, storeInfo)) {
            this.storeInfo = _.cloneDeep(storeInfo);
            if (this.categories) {
              this.checkAtLeastOneFrozenTagDebounced();
            }
            const catalogCustomTagsById = {};
            _.each(_.cloneDeep(this.storeInfo.catalogCustomTags), (tag) => {
              catalogCustomTagsById[tag.id] = tag;
            });
            this.groupStoreData.store_info = {
              store_id: storeInfo.store_id,
              overridePromoItemsForPointsSystem: _.cloneDeep(
                this.storeInfo.overridePromoItemsForPointsSystem
              ),
              alt_dinein_active: storeInfo.dinein
                ? _.cloneDeep(this.storeInfo.alt_dinein_active)
                : null,
              catalogCustomTagsById: catalogCustomTagsById,
              design_settings: storeInfo.design_settings,
            };

            this.selectedStoreId = _.cloneDeep(this.storeInfo.store_id);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("disableFinalPrice")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(state.disableFinalPrice, this.disableFinalPrice)
          ) {
            if (state.disableFinalPrice && !this.disableFinalPrice) {
              this.calculateApiState = "completed";
              if (
                this.categories &&
                this.loginState &&
                this.calculateApiState === "completed"
              ) {
                this.checkExclusiveCustomerPromotionsDebounced();
              }
            }

            this.disableFinalPrice = _.cloneDeep(state.disableFinalPrice);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(this.groupApp, state.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
            this.groupStoreData.groupApp = {
              group: _.cloneDeep(this.groupApp.group),
              disableCatalogPhoto: _.cloneDeep(
                this.groupApp.disableCatalogPhoto
              ),
              pointsSystem: _.cloneDeep(this.groupApp.pointsSystem),
              iconImage: _.cloneDeep(this.groupApp.iconImage),
            };
            this.groupStoreData.groupApp.mo_base = _.find(
              _.cloneDeep(this.groupApp.languages),
              { mo_base: true }
            );
          }
          if (
            state &&
            state.selectedLangugage &&
            !_.isEqual(this.selectedLanguage, state.selectedLangugage)
          ) {
            this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
            this.groupStoreData.groupApp.selectedLanguage = _.cloneDeep(
              this.selectedLanguage
            );
          }
          if (state && state.webp && !_.isEqual(this.webp, state.webp)) {
            this.webp = _.cloneDeep(state.webp);
            this.groupStoreData.webp = _.cloneDeep(this.webp);
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("deliveryMethodAddress")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && !_.isEqual(state, state.orderPickup)) {
            this.orderPickup = _.cloneDeep(state.orderPickup);
          }
          if (state && !_.isEqual(state, state.dineIn)) {
            this.dineIn = _.cloneDeep(state.dineIn);
          }
          if (
            state &&
            !_.isEqual(this.selectedDineInTable, state.selectedDineInTable)
          ) {
            this.selectedDineInTable = _.cloneDeep(state.selectedDineInTable);
          }

          if (
            state &&
            !_.isEqual(this.selectedPreorderDate, state.selectedPreorderDate)
          ) {
            this.selectedPreorderDate = _.cloneDeep(state.selectedPreorderDate);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && state.user && !_.isEqual(this.currentUser, state.user)) {
            this.currentUser = _.cloneDeep(state.user);
          }

          if (
            state &&
            state.loginState &&
            (state.loginState === "login_completed" ||
              state.loginState === "login_loading")
          ) {
            this.subscribeToCatalogUserPricelists();
          }

          if (
            state &&
            state.loginState &&
            !_.isEqual(this.loginState, state.loginState)
          ) {
            this.loginState = _.cloneDeep(state.loginState);
            if (
              this.categories &&
              this.loginState &&
              this.calculateApiState === "completed"
            ) {
              this.checkExclusiveCustomerPromotionsDebounced();
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && state.stores && !_.isEqual(this.stores, state.stores)) {
            this.stores = _.cloneDeep(state.stores);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("cart")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            state.products &&
            !_.isEqual(this.cartProducts, state.products)
          ) {
            this.cartProducts = _.cloneDeep(state.products);
            this.cartProductIds = [];
            _.each(this.cartProducts, (product) => {
              if (
                !product.quantity ||
                product.quantity === 0 ||
                product.quantity === 1 ||
                (product.enable_quantity_step && product.quantity_step)
              ) {
                this.cartProductIds.push(_.cloneDeep(product.product_id));
              } else {
                for (let i = 0; i < product.quantity; i++) {
                  this.cartProductIds.push(_.cloneDeep(product.product_id));
                }
              }
            });
          }
        })
    );
    // let scrollDiv = document.getElementById("scrollDiv");
    // console.log(scrollDiv);
    // scrollDiv.addEventListener("scroll", () => {
    //   let categorie = this.catalog[2];
    //   console.log(categorie.category_id);
    //   let CategoryElement = document.getElementById(categorie.category_id);
    //   console.log(CategoryElement);
    //   console.log(this.checkIfCategoryIsWithinViewport(CategoryElement));
    //   if (this.checkIfCategoryIsWithinViewport(CategoryElement)) {
    //     this.store.dispatch(
    //       new CategoriesSelectionActions.SetCurrentScrollCategory(
    //         categorie.category_id
    //       )
    //     );
    //   }
    // });

    this.subscriptions.push(
      this.store
        .select("hyperCategories")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.hyperCategories &&
            !_.isEqual(
              this.hyperCategoriesForProductItem,
              state.hyperCategories
            )
          ) {
            this.hyperCategoriesForProductItem = _.cloneDeep(
              state.hyperCategories
            );
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("hyperCategories")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(400))
        .subscribe((state) => {
          if (
            state &&
            state.currentHyperCategory &&
            !_.isEqual(this.currentHyperCategory, state.currentHyperCategory)
          ) {
            this.currentHyperCategory = _.cloneDeep(state.currentHyperCategory);
            this.changeHyperCategoryScrollToTopDebounce();
          }
          if (
            state &&
            state.hyperCategories &&
            !_.isEqual(this.hyperCategories, state.hyperCategories)
          ) {
            this.hyperCategories = _.cloneDeep(state.hyperCategories);
            this.subscribeToCatalog();
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("verticalCategory")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.selectedCategoryId, state.selectedCategoryId)
          ) {
            this.selectedCategoryId = _.cloneDeep(state.selectedCategoryId);

            this.scrollToCategory(this.selectedCategoryId);
          }
          if (
            state &&
            !_.isEqual(
              this.currentScrollCategoryId,
              state.selectedScrollCategoryId
            )
          ) {
            this.currentScrollCategoryId = _.cloneDeep(
              state.selectedScrollCategoryId
            );
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("customerPromotions")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.customerPromotions, state.customerPromotions)
          ) {
            this.customerPromotions = _.cloneDeep(state.customerPromotions);

            if (
              this.categories &&
              this.loginState &&
              this.calculateApiState === "completed"
            ) {
              this.checkExclusiveCustomerPromotionsDebounced();
            }
          }
        })
    );
  }

  subscribeToCatalogUserPricelists() {
    if (!this.catalogUserPricelistSub) {
      this.catalogUserPricelistSub = this.store
        .select("catalog")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.customerPricelist, state.customerPricelist)
          ) {
            this.customerPricelist = _.cloneDeep(state.customerPricelist);
            console.log("customerPricelists", this.customerPricelist);
            if (this.customerPricelist) {
              this.groupStoreData.forCustomerPricelists = true;
            } else {
              this.groupStoreData.forCustomerPricelists = false;
            }
          }
        });
    }
  }

  async openUnavailableProductsModal(category) {
    this.unavailableProductsModal = await this.modalCtrl.create({
      component: UnavailableProductsModalComponent,
      cssClass: "unavailable-products-modal",
      animated: true,
      componentProps: {
        products: this.unavailableProductsByCategoryId[category.category_id],
        category: _.cloneDeep(category),
      },
      backdropDismiss: false,
    });

    await this.unavailableProductsModal.present();
    this.unavailableProductsModal.onDidDismiss().then(() => {
      this.unavailableProductsModal = null;
    });
  }

  private changeHyperCategoryScrollToTopDebounce = _.debounce(() => {
    this.store.dispatch(
      new CategoriesSelectionActions.SetScrollSelectedCategory(null)
    );
    if (this.categories && this.categories.length) {
      this.store.dispatch(
        new CategoriesSelectionActions.SetSelectedCategory(
          _.cloneDeep(this.categories[0].category_id)
        )
      );
    }

    this.scrollToTop();
  }, 50);

  findUnavailableProductsByCategoryid() {
    this.unavailableProductsByCategoryId = {};

    if (this.allCategories && this.allCategories.length) {
      _.each(this.allCategories, (category) => {
        this.unavailableProductsByCategoryId[category.category_id] = [];
        if (category.products) {
          _.each(category.products, (product) => {
            if (
              product &&
              (!product.availableTotal ||
                !product.product_categories_availability_check)
            ) {
              if (
                !_.find(
                  this.unavailableProductsByCategoryId[category.category_id],
                  { product_id: product.product_id }
                )
              )
                this.unavailableProductsByCategoryId[category.category_id].push(
                  _.cloneDeep(product)
                );
            }
          });
        }
      });
    }
    this.changedUnavailableProductsByCategoryIdTimestamp =
      Date.now().toString();
  }

  subscribeToCatalog() {
    if (this.hyperCategories && this.hyperCategories.length > 0) {
      if (!this.catalogSubscription) {
        this.catalogSubscription = this.store
          .select("catalog")
          .pipe(distinctUntilChanged())
          .pipe(auditTime(300))
          .subscribe((state) => {
            if (
              state &&
              state.catalog &&
              !_.isEqual(this.categories, state.catalog)
            ) {
              this.categories = _.cloneDeep(state.catalog);

              this.checkAtLeastOneFrozenTagDebounced();

              if (
                this.categories &&
                this.loginState &&
                this.calculateApiState === "completed"
              ) {
                this.checkExclusiveCustomerPromotionsDebounced();
              }
            }
            if (
              state &&
              state.allCategories &&
              !_.isEqual(this.allCategories, state.allCategories)
            ) {
              this.allCategories = _.cloneDeep(state.allCategories);
              this.findUnavailableProductsByCategoryid();
            }
            if (
              state &&
              state.allCategoriesMarketCardsDesign &&
              !_.isEqual(
                this.allCategoriesMarketCardsDesign,
                state.allCategoriesMarketCardsDesign
              )
            ) {
              this.allCategoriesMarketCardsDesign = _.cloneDeep(
                state.allCategoriesMarketCardsDesign
              );
            }
          });
      }
    } else if (this.hyperCategories && this.hyperCategories.length === 0) {
      if (!this.catalogSubscription) {
        this.catalogSubscription = this.store
          .select("catalog")
          .pipe(distinctUntilChanged())

          .subscribe((state) => {
            if (
              state &&
              state.catalog &&
              !_.isEqual(this.categories, state.catalog)
            ) {
              this.categories = _.cloneDeep(state.catalog);

              this.checkAtLeastOneFrozenTagDebounced();

              if (
                this.categories &&
                this.loginState &&
                this.calculateApiState === "completed"
              ) {
                this.checkExclusiveCustomerPromotionsDebounced();
              }
            }
            if (
              state &&
              state.allCategories &&
              !_.isEqual(this.allCategories, state.allCategories)
            ) {
              this.allCategories = _.cloneDeep(state.allCategories);
              this.findUnavailableProductsByCategoryid();
            }
            if (
              state &&
              state.allCategoriesMarketCardsDesign &&
              !_.isEqual(
                this.allCategoriesMarketCardsDesign,
                state.allCategoriesMarketCardsDesign
              )
            ) {
              this.allCategoriesMarketCardsDesign = _.cloneDeep(
                state.allCategoriesMarketCardsDesign
              );
            }
          });
      }
    }
  }

  private checkExclusiveCustomerPromotionsDebounced = _.debounce(
    () => {
      this.checkExclusiveCustomerPromotions();
    },
    400,
    { maxWait: 800 }
  );

  async checkExclusiveCustomerPromotions() {
    if (
      this.categories &&
      this.loginState &&
      (this.loginState === "no_login" ||
        this.loginState === "login_completed") &&
      !this.checkExclusiveCustomerPromotionsCompleted
    ) {
      console.log("checkExclusiveCustomerPromotions called");
      this.store
        .select("customerPromotions")
        .pipe(first())
        .subscribe((customerPromotionsState) => {
          this.store
            .select("catalog")
            .pipe(first())
            .subscribe((categoriesState) => {
              let categoriesChanged;

              const categories = _.cloneDeep(categoriesState.catalog);

              const customerPromotions =
                customerPromotionsState.customerPromotions || [];

              if (
                categories &&
                categories.length &&
                !customerPromotions.length
              ) {
                _.each(categories, (category, category_index) => {
                  if (
                    category &&
                    category.promotions &&
                    category.promotions.length &&
                    this.categories[category_index]
                  ) {
                    categoriesChanged = true;
                    this.categories[category_index].promotions = _.map(
                      _.cloneDeep(category.promotions),
                      (promotion) => {
                        return !promotion.exclusiveMobilesActive;
                      }
                    );
                  }
                });
              } else if (
                categories &&
                categories.length &&
                customerPromotions.length
              ) {
                _.each(customerPromotions, (customerPromo) => {
                  if (customerPromo.exclusiveMobilesActive) {
                    _.each(categories, (category, category_index) => {
                      if (category && !category.promotions) {
                        category.promotions = [];
                      }

                      if (
                        customerPromo.appliesToCategoriesOrProducts &&
                        _.find(customerPromo.appliesToCategoriesOrProducts, {
                          id: category.category_id,
                        }) &&
                        !_.find(category.promotions, {
                          id: category.category_id,
                        }) &&
                        this.categories[category_index]
                      ) {
                        if (!this.categories[category_index].promotions) {
                          this.categories[category_index].promotions = [];
                        }
                        categoriesChanged = true;
                        this.categories[category_index].promotions.push({
                          id: category.category_id,
                          description: customerPromo.description,
                          exclusiveMobilesActive: true,
                        });
                      }
                    });
                  }
                });
              }

              if (
                this.categories &&
                this.categories.length &&
                categoriesChanged
              ) {
                this.store.dispatch(
                  new CatalogActions.UpdateCategoriesPromotions(
                    _.cloneDeep(this.categories)
                  )
                );
              }

              this.checkExclusiveCustomerPromotionsCompleted = true;
            })
            .unsubscribe();
        })
        .unsubscribe();
    }
  }

  scrollToTop() {
    const scrollElement = document.getElementById("scrollDiv");
    if (scrollElement) {
      scrollElement.scrollTop = 0;
    }
  }

  checkComponentScroll() {
    let el: any = document.getElementById("scrollDiv");
    if (el) {
      this.scrollFromBottom = _.cloneDeep(
        el.scrollHeight - el.scrollTop - el.offsetHeight
      );
    }
  }

  checkCategoryScroll() {
    let el: any = document.getElementById("scrollDiv");
    console.log();
    if (el) {
      const scrollFromBottom = _.cloneDeep(
        el.scrollHeight - el.scrollTop - el.offsetHeight
      );

      this.listScrollFromBottomEvent.emit(scrollFromBottom);

      this.listScrollFromTopEvent.emit(_.cloneDeep(el.scrollTop));
    }

    if (!this.categories) {
      return;
    }

    let currentCategoryIndex = _.findLastIndex(
      this.categories,
      (category: any) => {
        let categoryElement: any = document.getElementById(
          "category_" + category.category_id
        );

        if (categoryElement && el) {
          return categoryElement.offsetTop - el.scrollTop - 80 <= 0;
        } else {
          return false;
        }
      }
    );
    // console.log("current category index", currentCategoryIndex);
    var currentCategory = this.categories[currentCategoryIndex];
    // console.log("current category", currentCategory);
    if (!currentCategory) {
      return;
    }
    let maxTop;
    let centerPixel;
    let scrollBottom;
    if (el) {
      maxTop = el.offsetHeight;
      centerPixel = maxTop / 2;
      //console.log("centerPixel", centerPixel);
      //console.log("el top", el.scrollTop);
      scrollBottom = el.scrollHeight - el.scrollTop - el.offsetHeight;
      //console.log("scrollBottom", scrollBottom);
    }
    if (scrollBottom > 0) {
      _.each(this.categories, (category, key, list) => {
        if (
          currentCategory &&
          currentCategory.category_id === category.category_id
        ) {
          this.category_id = currentCategory.category_id;
          this.categories[key].selected = true;
          if (
            !_.isEqual(
              this.currentScrollCategoryId,
              this.categories[key].category_id
            )
          ) {
            this.store.dispatch(
              new CategoriesSelectionActions.SetScrollSelectedCategory(
                _.cloneDeep(this.categories[key].category_id)
              )
            );
            this.store.dispatch(
              new CategoriesSelectionActions.SetSelectedCategory(
                _.cloneDeep(null)
              )
            );
          }
        } else {
          this.categories[key].selected = false;
        }
      });
    } else {
      _.each(this.categories, (category, key, list) => {
        this.categories[key].selected = false;
      });
      this.category_id =
        this.categories[this.categories.length - 1].category_id;
      this.categories[this.categories.length - 1].selected = true;
      if (
        !_.isEqual(
          this.currentScrollCategoryId,
          this.categories[this.categories.length - 1].category_id
        )
      ) {
        this.store.dispatch(
          new CategoriesSelectionActions.SetScrollSelectedCategory(
            _.cloneDeep(this.categories[this.categories.length - 1].category_id)
          )
        );
        this.store.dispatch(
          new CategoriesSelectionActions.SetSelectedCategory(_.cloneDeep(null))
        );
      }
    }
    if (currentCategory) {
      let previousCategory = document.getElementById(
        "verticalCategoriesMenu_" + currentCategory.category_id
      );
      if (
        (previousCategory && maxTop - previousCategory.offsetTop <= 42) ||
        (previousCategory && previousCategory.offsetTop - 50 <= 0)
      ) {
        this.scrollToVerticalCategory(
          currentCategory.category_id,
          currentCategoryIndex
        );
      }
      currentCategoryIndex = null;
    }
  }

  checkCategoryScrollDebounce = _.debounce(
    () => {
      this.checkCategoryScroll();
    },
    300,
    { maxWait: 300 }
  );

  scrollToCategory(categoryId) {
    // console.log(categoryId);

    if (categoryId) {
      let element = document.getElementById("category_" + categoryId);
      if (element) {
        element.scrollIntoView({ behavior: "auto" });
      }
    }
  }

  private checkAtLeastOneFrozenTagDebounced = _.debounce(() => {
    this.checkAtLeastOneFrozenTag();
  }, 400);

  checkAtLeastOneFrozenTag() {
    this.atLeastOneFrozenTag = false;
    if (
      this.storeInfo.design_settings &&
      this.storeInfo.design_settings.showOnlySelectedCategoryProducts &&
      this.selectedCategoryId
    ) {
      const category = _.find(this.categories, {
        category_id: this.selectedCategoryId,
      });

      this.atLeastOneFrozenTag = !!_.find(category.products, (prd) => {
        if (
          (prd && prd.tags && prd.tags.frozen) ||
          (category && category.tags && category.tags.frozen)
        ) {
          return true;
        }
      });
    } else {
      _.each(this.categories, (cat) => {
        if (cat && cat.tags && cat.tags.frozen) {
          this.atLeastOneFrozenTag = true;
        }
        _.each(cat.products, (prd) => {
          if (prd && prd.tags && prd.tags.frozen) {
            this.atLeastOneFrozenTag = true;
          }
        });
      });
    }
  }

  scrollToVerticalCategory(category_id, currentCategoryIndex) {
    let lastIndex = _.findLastIndex(this.categories);
    // console.log("last index", lastIndex, currentCategoryIndex);
    if (lastIndex !== -1 && currentCategoryIndex !== -1) {
      if (category_id) {
        let elem = document.getElementById(
          "verticalCategoriesMenu_" + category_id
        );
        if (elem && lastIndex != currentCategoryIndex) {
          elem.scrollIntoView({ block: "end", behavior: "auto" });
        } else if (
          (elem && lastIndex == currentCategoryIndex) ||
          lastIndex == currentCategoryIndex - 1
        ) {
          let scrollDiv = document.getElementById("scrollVerticalCategories");
          scrollDiv.scrollTo(0, scrollDiv.scrollHeight);
        }
      }
    }
  }

  categoriesTrackFn(index, item) {
    return item.category_id;
  }

  productsTrackFn(index, item) {
    return item.product_id;
  }

  async changeStore(store) {
    if (this.cartProducts && this.cartProducts.length > 0) {
      const alert = await this.alertController.create({
        cssClass: "my-custom-class",
        header: this.translateService.instant("alert"),
        message: this.translateService.instant(
          "catalog.if-you-change-store-your-cart-products-will-be-deleted-do-you-want-to-continue"
        ),
        buttons: [
          {
            text: this.translateService.instant("cancel"),
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              this.selectedStoreId = _.cloneDeep(this.storeInfo.store_id);
            },
          },
          {
            text: this.translateService.instant("yes"),
            handler: () => {
              console.log("set store id deliveryMethod address");
              this.store.dispatch(
                new currentStoreActions.SetCurrentStoreId(
                  _.cloneDeep(store.store_id)
                )
              );
            },
          },
        ],
      });

      await alert.present();
    } else {
      console.log("set store id deliveryMethod address");
      this.store.dispatch(
        new currentStoreActions.SetCurrentStoreId(_.cloneDeep(store.store_id))
      );
    }
  }

  onSwipe(ev) {
    //console.log("swipe", ev);
    const x =
      Math.abs(ev.deltaX) > 40 ? (ev.deltaX > 0 ? "Right" : "Left") : "";
    const y = Math.abs(ev.deltaY) > 40 ? (ev.deltaY > 0 ? "Down" : "Up") : "";
    this.direction = x || y;
    console.log("direction", this.direction);
    if (this.direction && this.direction === "Left") {
      if (this.hyperCategories && this.hyperCategories.length === 0) {
        if (this.categories && this.categories.length) {
        }
      } else if (this.hyperCategories && this.hyperCategories.length > 0) {
      }
    } else if (this.direction && this.direction === "Right") {
    }
    // if (
    //   this.selectedOptionId &&
    //   this.direction === "Left" &&
    //   this.allOptionSteps
    // ) {
    //   let index = _.indexOf(this.allOptionSteps, this.selectedOptionId);
    //   console.log("index", index);
    //   if (index !== -1 && this.allOptionSteps[index + 1]) {
    //     this.store.dispatch(
    //       new OptionStepperActions.SetSelectedStepOptionId(
    //         _.cloneDeep(this.allOptionSteps[index + 1])
    //       )
    //     );
    //     this.changeDetector.detectChanges();
    //   }
    // } else if (
    //   this.selectedOptionId &&
    //   this.direction === "Right" &&
    //   this.allOptionSteps
    // ) {
    //   let index = _.indexOf(this.allOptionSteps, this.selectedOptionId);
    //   console.log("index", index);
    //   if (index !== -1 && this.allOptionSteps[index - 1]) {
    //     this.store.dispatch(
    //       new OptionStepperActions.SetSelectedStepOptionId(
    //         _.cloneDeep(this.allOptionSteps[index - 1])
    //       )
    //     );
    //     this.changeDetector.detectChanges();
    //   }
    // }
  }

  showNextCategoryButtonEv(ev) {
    this.showNextCategoryButton = ev;
  }

  ngOnDestroy() {
    if (this.catalogSubscription) {
      this.catalogSubscription.unsubscribe();
    }
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }

    if (this.catalogUserPricelistSub) {
      this.catalogUserPricelistSub.unsubscribe();
      this.catalogUserPricelistSub = null;
    }

    if (this.unavailableProductsModal) {
      this.unavailableProductsModal.dismiss();
      this.unavailableProductsModal = null;
    }
  }
}
