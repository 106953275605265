<ion-grid style="max-width: 150px">
  <ion-item lines="full">
    <ion-input
      [step]="choiceStep || 1"
      type="number"
      [(ngModel)]="quantityValue"
    ></ion-input>
  </ion-item>

  <ion-item lines="none">
    <ion-button (click)="clickCancel()" size="small">{{
      "cancel" | translate
    }}</ion-button>
    <ion-button (click)="clickOk()" size="small">OK</ion-button>
  </ion-item>
</ion-grid>
