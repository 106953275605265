import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import * as fromApp from "../../../store/app.reducer";
import * as selectors from "../../../store/app.selectors";
import * as _ from "lodash";
import { auditTime, distinctUntilChanged } from "rxjs/operators";
import { ModalController, ToastController } from "@ionic/angular";
import * as CartActiveCouponsActions from "./store/cartActiveCoupons.actions";
import { TranslateService } from "@ngx-translate/core";
import * as CompleteOrderValidationActions from "../../../store/complete-order-validation/completeOrderValidation.actions";
import { AnalyticsServiceService } from "src/app/services/analitycsService/analytics-service.service";
import { CouponsScheduleInfoPopupComponent } from "src/app/popups/coupons-schedule-info-popup/coupons-schedule-info-popup.component";
import moment from "moment";
import { isMoment } from "moment-timezone";
import { CartPageAlertsService } from "src/app/services/cartPageAlerts/cart-page-alerts.service";
import * as CartActions from "../../store/cart.actions";

@Component({
  selector: "app-cart-active-coupons",
  templateUrl: "./cart-active-coupons.component.html",
  styleUrls: ["./cart-active-coupons.component.scss"],
})
export class CartActiveCoupons implements OnInit, OnDestroy {
  public currentUser: any;
  public activeCoupons: any;
  public finalPriceLoading: boolean;
  public couponsSchedule: any;
  public todayIsCouponsExceptionDay: any = null;
  public currentDayName = moment().format("dddd").toLowerCase();
  public currentDayCouponsSchedule: any;
  public couponsExceptionDaysSchedule: any;
  public storeInfo: any;
  public allCartProductsPromo: boolean;
  public showCouponsSheduleButton: boolean = false;
  public cartProducts: any;

  private promoCartNoCouponTimestamp;
  private preorderOnlyNoCouponTimestamp;
  private onlinePaymentOnlyNoCouponTimestamp;
  private selectedLanguage: any;
  private lastValidTimestamp: any = null;
  private toastError: any;
  private cartPrices: any;
  private subscriptions: Subscription[] = [];
  private couponsScheduleInfoModal: any;

  constructor(
    private store: Store<fromApp.AppState>,
    private toastController: ToastController,
    private translateService: TranslateService,
    private analyticsService: AnalyticsServiceService,
    private modalController: ModalController,
    private cartAlertService: CartPageAlertsService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.selectedLangugage &&
            !_.isEqual(this.selectedLanguage, state.selectedLangugage)
          ) {
            this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .subscribe((storeInfo) => {
          if (storeInfo && !_.isEqual(this.storeInfo, storeInfo)) {
            this.storeInfo = _.cloneDeep(storeInfo);
            this.couponsSchedule = this.formatStoreSchedule(
              this.storeInfo.couponsSchedule
            );
            this.showCouponsSheduleButton = false;
            if (this.couponsSchedule && this.couponsSchedule.length) {
              console.log("coupon schedulecscs", this.couponsSchedule);

              _.each(this.couponsSchedule, (day) => {
                if (day && day.schedule) {
                  this.showCouponsSheduleButton = true;
                }
              });
            }

            if (!_.isEmpty(this.storeInfo.couponsSchedule)) {
              console.log("couponsschedule", this.storeInfo.couponsSchedule);
              this.initializeActions(
                _.cloneDeep(this.storeInfo.couponsSchedule),
                "couponsSchedule"
              );
            }
            if (!_.isEmpty(this.storeInfo.couponsExceptionDays)) {
              this.initializeActions(
                _.cloneDeep(this.storeInfo.couponsExceptionDays),
                "couponsExceptionDays"
              );
            }

            console.log("coupons schedule", this.couponsSchedule);
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && state.user && !_.isEqual(this.currentUser, state.user)) {
            this.currentUser = _.cloneDeep(state.user);
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("disableFinalPrice")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(state.disableFinalPrice, this.finalPriceLoading)
          ) {
            this.finalPriceLoading = _.cloneDeep(state.disableFinalPrice);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("cartActiveCoupons")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((state) => {
          if (state && !_.isEqual(this.activeCoupons, state.activeCoupons)) {
            this.activeCoupons = _.cloneDeep(state.activeCoupons);
            this.activeCoupons = _.filter(this.activeCoupons, (coupon) => {
              return (
                !coupon.use_type ||
                (coupon.use_type &&
                  coupon.use_type !== "third_party" &&
                  coupon.use_type !== "instore")
              );
            });
            this.editActiveToDate();
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("cart")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.products &&
            !_.isEqual(this.cartProducts, state.products)
          ) {
            this.cartProducts = _.cloneDeep(state.products);
            const foundProductWithoutPromo = _.find(
              this.cartProducts,
              (product) => {
                if (!product.promo) {
                  return product;
                }
              }
            );

            if (!foundProductWithoutPromo) {
              this.allCartProductsPromo = true;
            } else {
              this.allCartProductsPromo = false;
            }
          }

          if (
            state &&
            state.promoCartNoCouponTimestamp &&
            !_.isEqual(
              this.promoCartNoCouponTimestamp,
              state.promoCartNoCouponTimestamp
            )
          ) {
            this.cartAlertService.closeCantUseCouponsWithPromoCartAlert();
            this.cartAlertService.presentCantUseCouponsWithPromoCartAlert(
              this.storeInfo
            );
            this.store.dispatch(
              new CartActions.SetPromoCartNoCouponTimestamp(null)
            );
          }

          if (
            state &&
            state.preorderOnlyNoCouponTimestamp &&
            !_.isEqual(
              this.preorderOnlyNoCouponTimestamp,
              state.preorderOnlyNoCouponTimestamp
            )
          ) {
            this.cartAlertService.closeCantUseCouponsPreorderOnlyAlert();
            this.cartAlertService.presentCantUseCouponPreorderOnlyAlert(
              this.storeInfo
            );
            this.store.dispatch(
              new CartActions.setPreorderOnlyNoCouponTimestamp(null)
            );
          }

          if (
            state &&
            state.onlinePaymentOnlyNoCouponTimestamp &&
            !_.isEqual(
              this.onlinePaymentOnlyNoCouponTimestamp,
              state.onlinePaymentOnlyNoCouponTimestamp
            )
          ) {
            this.cartAlertService.closeCantUseCouponsOnlinePaymentOnlyAlert();
            this.cartAlertService.presentCantUseCouponOnlinePaymentOnlyAlert();
            this.store.dispatch(
              new CartActions.setOnlinePaymentOnlyNoCouponTimestamp(null)
            );
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("cartPrices")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.cartPrices &&
            !_.isEqual(this.cartPrices, state.cartPrices)
          ) {
            this.cartPrices = _.cloneDeep(state.cartPrices);
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("completeOrderValidation")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.lastValidTimestamp &&
            !_.isEqual(this.lastValidTimestamp, state.lastValidTimestamp)
          ) {
            this.lastValidTimestamp = _.cloneDeep(state.lastValidTimestamp);
            this.completeOrderCartCoupons();
          }
        })
    );
  }

  editActiveToDate() {
    _.each(this.activeCoupons, (coupon) => {
      if (coupon && coupon.activeTo) {
        coupon.activeTo = moment(
          _.cloneDeep(parseInt(coupon.activeTo.toString()))
        )
          .subtract(1, "day")
          .format("x")
          .toString();
      }
    });
  }

  async openCouponsScheduleInfoModal() {
    this.couponsScheduleInfoModal = await this.modalController.create({
      component: CouponsScheduleInfoPopupComponent,
      cssClass: "CouponsScheduleInfoPopup",
      backdropDismiss: false,
    });
    await this.couponsScheduleInfoModal.present();

    this.couponsScheduleInfoModal.onDidDismiss().then(() => {
      this.couponsScheduleInfoModal = null;
    });
  }

  //TO_DO CHECK TRACK FUNCTION TO RETRUN ITEM ATTR
  trackFunc(index, item) {
    return index;
  }

  async presentErrorToast(message, color) {
    if (this.toastError) {
      // console.log("toast exist");
      try {
        console.log(this.toastError);
        this.toastError.dismiss();
        this.toastError = null;
      } catch (e) {}
    }
    this.toastError = await this.toastController.create({
      message: message,
      duration: 4000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
    });

    this.toastError.present();
  }

  private presentErrorToastDebounced = _.debounce((message, color) => {
    this.presentErrorToast(message, color);
  }, 300);

  clickCheckboxCoupon(coupon) {
    var index = _.findIndex(this.activeCoupons, {
      timestamp: coupon.timestamp,
    });
    if (document.activeElement.tagName === "ION-CHECKBOX") {
      if (index !== -1) {
        if (!coupon.validDate) {
          let errorMsg;
          if (coupon.validDateException) {
            if (
              this.storeInfo &&
              this.storeInfo.couponsExceptionsDescription &&
              this.selectedLanguage &&
              this.storeInfo.couponsExceptionsDescription[this.selectedLanguage]
            ) {
              errorMsg = _.cloneDeep(
                this.storeInfo.couponsExceptionsDescription[
                  this.selectedLanguage
                ]
              );
            } else {
              errorMsg = this.translateService.instant(
                "cart.cart-components.cart-active-coupons.today-exceptionally-you-cant-use-the-coupon"
              );
            }
          } else if (coupon.preorderValidDateException) {
            errorMsg =
              this.translateService.instant(
                "cart.cart-components.cart-active-coupons.your-coupon-is-valid-from"
              ) +
              " " +
              moment(parseInt(coupon.activeFrom.toString()))
                .format("DD/MM/YYYY")
                .toString() +
              ". " +
              this.translateService.instant(
                "cart.cart-components.cart-active-coupons.your-can-select-preorder-to-use-it"
              );
          } else {
            errorMsg = this.translateService.instant(
              "cart.cart-components.cart-active-coupons.coupon-is-not-valid-now"
            );
          }
          this.doCheckboxFalseDebounce(index);

          this.presentErrorToastDebounced(errorMsg, "danger");
        } else if (!coupon.validCombo) {
          this.doCheckboxFalseDebounce(index);
          let errorMsg = this.translateService.instant(
            "cart.cart-components.cart-active-coupons.cannot-be-combined-with-your-other-choices"
          );

          this.presentErrorToastDebounced(errorMsg, "danger");
        } else if (!coupon.validMinOrder) {
          this.doCheckboxFalseDebounce(index);
          let errorMsg = this.translateService.instant(
            "cart.cart-components.cart-active-coupons.coupon-requires-a-minimum-order"
          );

          this.presentErrorToastDebounced(errorMsg, "danger");
        } else if (coupon.status === "pending_confirmation") {
          this.doCheckboxFalseDebounce(index);
          let errorMsg = this.translateService.instant(
            "cart.cart-components.cart-active-coupons.coupon-is-pending-confirmation-contact-store"
          );

          this.presentErrorToastDebounced(errorMsg, "danger");
        } else {
          console.log("active coupon", coupon);
          this.analyticsService.dmAnalyticsMessage(
            "selected_coupon",
            null,
            null,
            coupon.description,
            null,
            null,
            null,
            null,
            null,
            null,
            null
          );
          //selected_coupon
          //coupon_description
          this.store.dispatch(
            new CartActiveCouponsActions.SetActiveCoupons(
              _.cloneDeep(this.activeCoupons)
            )
          );
        }
      }
    }
  }

  completeOrderCartCoupons() {
    console.log("complete order cupons");
    let validationObject = {
      id: "active-coupons",
      valid: true,
      errorMessages: [],
      openActiveCouponAlert: false,
    };
    if (
      this.activeCoupons &&
      !_.isEmpty(this.activeCoupons) &&
      !_.find(this.activeCoupons, {
        couponCheckbox: true,
      }) &&
      (_.find(
        this.activeCoupons,
        (coupon) =>
          coupon.validDate &&
          coupon.validCombo &&
          coupon.validMinOrder &&
          !coupon.product_id &&
          this.cartPrices &&
          this.cartPrices.subTotalNoPromos !== 0
      ) ||
        _.find(
          this.activeCoupons,
          (coupon) =>
            coupon.validDate &&
            coupon.validCombo &&
            coupon.validMinOrder &&
            coupon.product_id
        ))
    ) {
      validationObject.openActiveCouponAlert = true;
    }
    this.store.dispatch(
      new CompleteOrderValidationActions.AddUpdateCompleteOrderValidation(
        _.cloneDeep(validationObject)
      )
    );
  }

  calculateBadgeColor(coupon) {
    if (coupon) {
      if (coupon.status === "used") {
        return "used-coupon";
      } else if (coupon.status === "active") {
        return "active-coupon";
      } else if (coupon.status === "expired") {
        return "expired-coupon";
      } else if (coupon.status === "outdated") {
        return "expired-coupon";
      } else if (coupon.status === "canceled") {
        return "used-coupon";
      } else if (coupon.status === "pending_confirmation") {
        return "warning";
        //console.log("set exp coupon");
      }
    }
  }

  doCheckboxFalseDebounce = _.debounce(
    (index) => {
      this.activeCoupons[index].couponCheckbox = false;
    },
    100,
    { maxWait: 200 }
  );

  initializeActions(schedule, type) {
    console.log("initialize actions called", schedule, type);
    if (type === "couponsExceptionDays") {
      _.each(schedule, (item, key) => {
        let day = item.date.split("-");
        let date = moment();
        console.log(
          "valuesss",
          parseInt(day[0]),
          parseInt(day[1]),
          parseInt(day[2])
        );
        date.set("date", parseInt(day[0]));
        date.set("month", parseInt(day[1]) - 1);
        date.set("year", parseInt(day[2]));
        let today = moment();
        console.log("date today", date, today);
        console.log("diff", date.diff(today), key);
        if (date.diff(today) >= 0) {
          item.oldDate = false;
        } else {
          item.oldDate = true;
        }
      });
    }

    console.log("schedule", schedule);

    if (!_.isEmpty(schedule)) {
      let allDaysSchedule = schedule.map((item) => {
        if (!_.isObject(item)) {
          item = {};
        }

        if (!_.isArray(item.time_periods)) {
          item.time_periods = [];
        }
        if (item.date) {
          let day = item.date.split("-");
          // let month = item.date.split("-", 1);
          // let year = item.date.split("-", 2);
          // let date = moment().set({
          //   day: parseInt(day[0]),
          //   month: parseInt(day[1]) - 1,
          //   year: parseInt(day[2]),
          //   hour: 0,
          //   minute: 0,
          //   second: 0,
          // });
          let date = moment();
          date.date(parseInt(day[0]));
          date.month(parseInt(day[1]) - 1);
          date.year(parseInt(day[2]));

          console.log(
            "moment date values",
            parseInt(day[0]),
            parseInt(day[1]),
            parseInt(day[2])
          );
          console.log("moment date object", date);
          item.date = _.cloneDeep(date);
        }
        for (let index = 0; index < item.time_periods.length; index++) {
          if (
            !_.isNumber(item.time_periods[index].stop) ||
            !item.time_periods[index].stop
          ) {
            item.time_periods[index].stop = 1;
          }

          if (
            !_.isNumber(item.time_periods[index].start) ||
            !item.time_periods[index].start
          ) {
            item.time_periods[index].start = 1;
          }

          const start_minutes = +item.time_periods[index].start / 60;
          const start_hours = start_minutes / 60;
          const start_rest_minutes = start_minutes % 60;

          item.time_periods[index].start_timestamp = moment(0)
            .hour(start_hours)
            .minutes(0)
            .add(start_rest_minutes, "minutes")
            .startOf("minute")
            .format();

          const stop_minutes = +item.time_periods[index].stop / 60;
          const stop_hours = stop_minutes / 60;
          const stop_rest_minutes = stop_minutes % 60;

          item.time_periods[index].stop_timestamp = moment(0)
            .hour(stop_hours)
            .minutes(0)
            .add(stop_rest_minutes, "minutes")
            .startOf("minute")
            .format();
        }

        return item;
      });

      if (type === "couponsSchedule") {
        this.currentDayCouponsSchedule = _.cloneDeep(
          allDaysSchedule[moment().weekday()]
        );
      } else if (type === "couponsExceptionDays") {
        this.couponsExceptionDaysSchedule = _.cloneDeep(allDaysSchedule);
        this.checkIfIsExceptionDay(this.couponsExceptionDaysSchedule);
      }
    }

    console.log("type shedule", schedule);
  }

  formatStoreSchedule(schedule) {
    var weekDays = [
      this.translateService.instant("store-schedule.sunday"),
      this.translateService.instant("store-schedule.monday"),
      this.translateService.instant("store-schedule.tuesday"),
      this.translateService.instant("store-schedule.wednesday"),
      this.translateService.instant("store-schedule.thursday"),
      this.translateService.instant("store-schedule.friday"),
      this.translateService.instant("store-schedule.saturday"),
    ];

    var singleScheduleArray = [];

    _.each(schedule, (scheduleDay, key, list) => {
      if (scheduleDay.active && !scheduleDay.all_day) {
        var commentSchedule = "";
        scheduleDay.time_periods.forEach((tp, i) => {
          if (i > 0) {
            commentSchedule += ", ";
          }
          commentSchedule +=
            this.toHHMMSS(tp.start) + " - " + this.toHHMMSS(tp.stop);
        });

        singleScheduleArray.push({
          day: weekDays[key],
          schedule: commentSchedule,
        });
      } else if (scheduleDay.active && scheduleDay.all_day) {
        singleScheduleArray.push({
          day: weekDays[key],
          schedule: null,
        });
      } else {
        singleScheduleArray.push({
          day: weekDays[key],
          schedule: "disabled",
        });
      }
    });

    var sunday = _.head(singleScheduleArray);
    singleScheduleArray = _.takeRight(singleScheduleArray, 6);
    singleScheduleArray.push(sunday);
    return singleScheduleArray;
  }

  toHHMMSS(val) {
    // console.log("val", val);
    if (val) {
      let sec_num = parseInt(val, 10); // don't forget the second param
      let hours = Math.floor(sec_num / 3600);
      let minutes = Math.floor((sec_num - hours * 3600) / 60);
      let newhours = hours.toString();
      let newmins = minutes.toString();
      if (hours < 10) {
        newhours = "0" + hours;
      }
      if (minutes < 10) {
        newmins = "0" + minutes;
      }

      return newhours + ":" + newmins;
    } else {
      return "00:00";
    }
  }

  checkIfIsExceptionDay(couponsExceptionDays) {
    var weekDays = [
      this.translateService.instant("store-schedule.sunday"),
      this.translateService.instant("store-schedule.monday"),
      this.translateService.instant("store-schedule.tuesday"),
      this.translateService.instant("store-schedule.wednesday"),
      this.translateService.instant("store-schedule.thursday"),
      this.translateService.instant("store-schedule.friday"),
      this.translateService.instant("store-schedule.saturday"),
    ];
    this.todayIsCouponsExceptionDay = null;
    console.log("exception days called", couponsExceptionDays);
    if (!_.isEmpty(couponsExceptionDays)) {
      _.each(couponsExceptionDays, (day) => {
        if (isMoment(day.date)) {
          day.date.set("hour", 0);
          day.date.set("minutes", 0);
          day.date.set("seconds", 0);
          day.date.set("milliseconds", 0);
          let now = moment();
          now.set("hour", 0);
          now.set("minutes", 0);
          now.set("seconds", 0);
          now.set("milliseconds", 0);
          if (moment(day.date).isSame(now)) {
            this.todayIsCouponsExceptionDay = weekDays[moment().weekday()];
            this.showCouponsSheduleButton = true;
            console.log(
              "today is excpetion day",
              this.todayIsCouponsExceptionDay
            );
          }
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.couponsScheduleInfoModal) {
      this.couponsScheduleInfoModal.dismiss();
      this.couponsScheduleInfoModal = null;
    }

    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    this.subscriptions = [];
  }
}
