import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { Subscription } from "rxjs";

@Component({
  selector: "app-select-store",
  templateUrl: "./select-store.component.html",
  styleUrls: ["./select-store.component.scss"],
})
export class SelectStoreComponent implements OnInit, OnDestroy {
  @Input() paymentGatewayStoresByID: any;
  @Input() storesByStoreID: any;
  @Input() selectedLanguage: any;
  private unsubscribeBackEvent: Subscription;
  constructor(private modalCtrl: ModalController, private platform: Platform) {}

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  selectStore(store_id) {
    this.modalCtrl.dismiss(store_id);
  }

  ngOnDestroy() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
  }
}
