<ion-card
  [class]="starColor ? 'ion-card-' + starColor : ''"
  title="{{ 'ratings.ratings-item.details' | translate }}"
  button="true"
  detail="false"
  *ngIf="currentReview"
  (click)="onReviewClick()"
>
  <ion-item lines="none">
    <ion-label class="ion-text-wrap ion-no-margin">
      <p *ngIf="currentReview.order_id && currentReview.timestamp">
        <strong
          >#{{ currentReview.order_id }}
          {{ currentReview.timestamp | date: "dd/MM/yyyy HH:mm" }}
        </strong>
      </p>
      <ion-badge color="complete" *ngIf="currentReview.storeAnswer">
        {{ "ratings.ratings-item.store-has-answered-your-review" | translate }}
      </ion-badge>

      <ionic5-star-rating
        *ngIf="reviewStarAverage || reviewStarAverage === 0"
        #rating
        activeIcon="star"
        defaultIcon="star-outline"
        [activeColor]="'var(--ion-color-' + starColor + ')'"
        defaultColor="#f4f4f4"
        halfStar="true"
        readonly="true"
        rating="{{ reviewStarAverage }}"
        fontSize="18px"
        style="line-height: 0.5"
      ></ionic5-star-rating>
    </ion-label>

    <ion-icon
      size="small"
      slot="end"
      src="assets/ionicons/chevron-forward.svg"
    ></ion-icon>
  </ion-item>
</ion-card>
