<ion-tabs *ngIf="groupApp" (ionTabsDidChange)="changeIcon($event)">
  <ion-tab-bar
    id="reservationSystemTabBar"
    color="primary"
    slot="bottom"
    selectedTab="{{ selectedTab }}"
  >
    <ion-tab-button tab="reservation-home" (click)="changeIcon('home')">
      <ion-icon
        name="{{ selectedTab === 'reservation-home' ? 'home' : 'home-outline' }}"
      ></ion-icon>
      <ion-label>{{ "reservation-app.home" | translate }}</ion-label>
    </ion-tab-button>

    <ion-tab-button tab="reservation-my-dates" (click)="changeIcon('myDates')">
      <ion-icon
        name="{{ selectedTab === 'reservation-my-dates' ? 'calendar' : 'calendar-outline' }}"
      ></ion-icon>
      <ion-label>{{ "reservation-app.myDates" | translate }}</ion-label>
    </ion-tab-button>

    <ion-tab-button
      *ngIf="groupApp && groupApp.pointsSystem"
      tab="reservation-points-page"
    >
      <ion-icon
        name="{{ selectedTab === 'reservation-points-page' ? 'card' : 'card-outline' }}"
      ></ion-icon>
      <ion-label>{{ "reservation-app.points" | translate }}</ion-label>
    </ion-tab-button>

    <ion-tab-button tab="reservation-more-info">
      <ion-icon
        name="{{ selectedTab === 'reservation-more-info' ? 'ellipsis-horizontal' : 'ellipsis-horizontal-outline' }}"
      ></ion-icon>
      <ion-label>{{ "reservation-app.more" | translate }}</ion-label>
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>
