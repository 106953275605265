<div style="width: 100%; z-index: 10000; text-align: right">
  <ion-button fill="clear" (click)="closeModal()">
    <ion-icon src="assets/ionicons/close.svg" slot="icon-only"></ion-icon>
  </ion-button>
</div>

<div
  style="
    width: 100%;
    height: 100%;
    background-color: transparent;
    text-align: center;
  "
>
  <ion-grid class="ion-text-center" style="width: 100%; height: 100%">
    <ion-row
      style="width: 100%; height: 100%"
      class="ion-justify-content-center ion-align-items-center"
    >
      <ion-col class="ion-text-center ion-align-self-center">
        <ion-grid style="width: 270px; height: 270px">
          <ion-thumbnail
            (click)="closeModal()"
            *ngIf="image_path"
            style="background: url({{image_path}}) no-repeat center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      background-size: cover;
      z-index: 1;
      border-radius: 8px;
      width: 270px;
      height: 270px;
      "
          >
          </ion-thumbnail>
        </ion-grid>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
