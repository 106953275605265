<ion-badge
  color="danger"
  class="badge-margin"
  *ngIf="optionItem && !optionItem.availableTotal"
>
  <ion-text>{{ "item-tags.unavailable" | translate }}</ion-text>
</ion-badge>

<ion-badge class="badge-margin" *ngIf="optionItem && optionItem.pickup">
  <ion-text>{{ "items-tags.pickup" | translate }}</ion-text>
</ion-badge>
<ion-badge
  [color]="
    groupStoreData &&
    groupStoreData.store_info &&
    groupStoreData.store_info.design_settings &&
    groupStoreData.store_info.design_settings.tagsColors &&
    groupStoreData.store_info.design_settings.tagsColors.new
      ? groupStoreData.store_info.design_settings.tagsColors.new
      : ''
  "
  class="badge-margin"
  *ngIf="optionItem && optionItem.tags && optionItem.tags.new"
>
  <ion-text>{{ "item-tags.new" | translate }}</ion-text>
</ion-badge>

<ion-badge
  [color]="
    groupStoreData &&
    groupStoreData.store_info &&
    groupStoreData.store_info.design_settings &&
    groupStoreData.store_info.design_settings.tagsColors &&
    groupStoreData.store_info.design_settings.tagsColors.veg
      ? groupStoreData.store_info.design_settings.tagsColors.veg
      : ''
  "
  class="badge-margin"
  *ngIf="optionItem && optionItem.tags && optionItem.tags.veg"
>
  <ion-text>{{ "item-tags.veg" | translate }}</ion-text>
</ion-badge>

<ion-badge
  [color]="
    groupStoreData &&
    groupStoreData.store_info &&
    groupStoreData.store_info.design_settings &&
    groupStoreData.store_info.design_settings.tagsColors &&
    groupStoreData.store_info.design_settings.tagsColors.fasting
      ? groupStoreData.store_info.design_settings.tagsColors.fasting
      : ''
  "
  class="badge-margin"
  *ngIf="optionItem && optionItem.tags && optionItem.tags.fasting"
>
  <ion-text>{{ "item-tags.fasting" | translate }}</ion-text>
</ion-badge>

<ion-badge
  [color]="
    groupStoreData &&
    groupStoreData.store_info &&
    groupStoreData.store_info.design_settings &&
    groupStoreData.store_info.design_settings.tagsColors &&
    groupStoreData.store_info.design_settings.tagsColors.gloutenfree
      ? groupStoreData.store_info.design_settings.tagsColors.gloutenfree
      : ''
  "
  class="badge-margin"
  *ngIf="optionItem && optionItem.tags && optionItem.tags.gloutenfree"
>
  <ion-text>{{ "item-tags.gloutenfree" | translate }}</ion-text>
</ion-badge>

<ion-badge
  [color]="
    groupStoreData &&
    groupStoreData.store_info &&
    groupStoreData.store_info.design_settings &&
    groupStoreData.store_info.design_settings.tagsColors &&
    groupStoreData.store_info.design_settings.tagsColors.spicy
      ? groupStoreData.store_info.design_settings.tagsColors.spicy
      : ''
  "
  class="badge-margin"
  *ngIf="optionItem && optionItem.tags && optionItem.tags.spicy"
>
  <ion-text>{{ "item-tags.spicy" | translate }}</ion-text>
</ion-badge>

<ion-badge
  [color]="
    groupStoreData &&
    groupStoreData.store_info &&
    groupStoreData.store_info.design_settings &&
    groupStoreData.store_info.design_settings.tagsColors &&
    groupStoreData.store_info.design_settings.tagsColors.lactosefree
      ? groupStoreData.store_info.design_settings.tagsColors.lactosefree
      : ''
  "
  class="badge-margin"
  *ngIf="optionItem && optionItem.tags && optionItem.tags.lactosefree"
>
  <ion-text>{{ "item-tags.lactosefree" | translate }}</ion-text>
</ion-badge>
<ion-badge
  [color]="
    groupStoreData &&
    groupStoreData.store_info &&
    groupStoreData.store_info.design_settings &&
    groupStoreData.store_info.design_settings.tagsColors &&
    groupStoreData.store_info.design_settings.tagsColors.frozen
      ? groupStoreData.store_info.design_settings.tagsColors.frozen
      : ''
  "
  class="badge-margin"
  *ngIf="optionItem && optionItem.tags && optionItem.tags.frozen"
>
  <ion-text>{{ "item-tags.frozen" | translate }}</ion-text>
</ion-badge>
<ion-badge
  [color]="
    groupStoreData &&
    groupStoreData.store_info &&
    groupStoreData.store_info.design_settings &&
    groupStoreData.store_info.design_settings.tagsColors &&
    groupStoreData.store_info.design_settings.tagsColors.vegan
      ? groupStoreData.store_info.design_settings.tagsColors.vegan
      : ''
  "
  class="badge-margin"
  *ngIf="optionItem && optionItem.tags && optionItem.tags.vegan"
>
  <ion-text>{{ "item-tags.vegan" | translate }}</ion-text>
</ion-badge>
<ion-badge
  [color]="
    groupStoreData &&
    groupStoreData.store_info &&
    groupStoreData.store_info.design_settings &&
    groupStoreData.store_info.design_settings.tagsColors &&
    groupStoreData.store_info.design_settings.tagsColors.deal
      ? groupStoreData.store_info.design_settings.tagsColors.deal
      : ''
  "
  class="badge-margin"
  *ngIf="optionItem && optionItem.tags && optionItem.tags.deal"
>
  <ion-text>{{ "item-tags.deal" | translate }}</ion-text>
</ion-badge>
<ion-badge
  [color]="
    groupStoreData &&
    groupStoreData.store_info &&
    groupStoreData.store_info.design_settings &&
    groupStoreData.store_info.design_settings.tagsColors &&
    groupStoreData.store_info.design_settings.tagsColors.popular
      ? groupStoreData.store_info.design_settings.tagsColors.popular
      : ''
  "
  class="badge-margin"
  *ngIf="optionItem && optionItem.tags && optionItem.tags.popular"
>
  <ion-text>{{ "item-tags.popular" | translate }}</ion-text>
</ion-badge>

<ion-badge
  [color]="
    groupStoreData &&
    groupStoreData.store_info &&
    groupStoreData.store_info.design_settings &&
    groupStoreData.store_info.design_settings.tagsColors &&
    groupStoreData.store_info.design_settings.tagsColors.custom_tag_1
      ? groupStoreData.store_info.design_settings.tagsColors.custom_tag_1
      : ''
  "
  class="badge-margin"
  *ngIf="
    groupStoreData &&
    groupStoreData.store_info &&
    groupStoreData.store_info.catalogCustomTagsById &&
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_1 &&
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_1.active &&
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_1.translations &&
    groupStoreData.groupApp &&
    groupStoreData.groupApp.mo_base &&
    groupStoreData.groupApp.mo_base.code &&
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_1.translations[
      groupStoreData.groupApp.mo_base.code
    ] &&
    optionItem &&
    optionItem.tags &&
    optionItem.tags.custom_tag_1
  "
>
  <ion-text>{{
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_1.translations[
      groupStoreData.groupApp.selectedLanguage
    ] ||
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_1.translations[
        groupStoreData.groupApp.mo_base.code
      ]
  }}</ion-text>
</ion-badge>
<ion-badge
  [color]="
    groupStoreData &&
    groupStoreData.store_info &&
    groupStoreData.store_info.design_settings &&
    groupStoreData.store_info.design_settings.tagsColors &&
    groupStoreData.store_info.design_settings.tagsColors.custom_tag_2
      ? groupStoreData.store_info.design_settings.tagsColors.custom_tag_2
      : ''
  "
  class="badge-margin"
  *ngIf="
    groupStoreData &&
    groupStoreData.store_info &&
    groupStoreData.store_info.catalogCustomTagsById &&
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_2 &&
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_2.active &&
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_2.translations &&
    groupStoreData.groupApp &&
    groupStoreData.groupApp.mo_base &&
    groupStoreData.groupApp.mo_base.code &&
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_2.translations[
      groupStoreData.groupApp.mo_base.code
    ] &&
    optionItem &&
    optionItem.tags &&
    optionItem.tags.custom_tag_2
  "
>
  <ion-text>{{
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_2.translations[
      groupStoreData.groupApp.selectedLanguage
    ] ||
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_2.translations[
        groupStoreData.groupApp.mo_base.code
      ]
  }}</ion-text>
</ion-badge>
<ion-badge
  [color]="
    groupStoreData &&
    groupStoreData.store_info &&
    groupStoreData.store_info.design_settings &&
    groupStoreData.store_info.design_settings.tagsColors &&
    groupStoreData.store_info.design_settings.tagsColors.custom_tag_3
      ? groupStoreData.store_info.design_settings.tagsColors.custom_tag_3
      : ''
  "
  class="badge-margin"
  *ngIf="
    groupStoreData &&
    groupStoreData.store_info.catalogCustomTagsById &&
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_3 &&
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_3.active &&
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_3.translations &&
    groupStoreData.groupApp &&
    groupStoreData.groupApp.mo_base &&
    groupStoreData.groupApp.mo_base.code &&
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_3.translations[
      groupStoreData.groupApp.mo_base.code
    ] &&
    optionItem &&
    optionItem.tags &&
    optionItem.tags.custom_tag_3
  "
>
  <ion-text>{{
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_3.translations[
      groupStoreData.groupApp.selectedLanguage
    ] ||
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_3.translations[
        groupStoreData.groupApp.mo_base.code
      ]
  }}</ion-text>
</ion-badge>
<ion-badge
  [color]="
    groupStoreData &&
    groupStoreData.store_info &&
    groupStoreData.store_info.design_settings &&
    groupStoreData.store_info.design_settings.tagsColors &&
    groupStoreData.store_info.design_settings.tagsColors.custom_tag_4
      ? groupStoreData.store_info.design_settings.tagsColors.custom_tag_4
      : ''
  "
  class="badge-margin"
  *ngIf="
    groupStoreData &&
    groupStoreData.store_info &&
    groupStoreData.store_info.catalogCustomTagsById &&
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_4 &&
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_4.active &&
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_4.translations &&
    groupStoreData.groupApp &&
    groupStoreData.groupApp.mo_base &&
    groupStoreData.groupApp.mo_base.code &&
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_4.translations[
      groupStoreData.groupApp.mo_base.code
    ] &&
    optionItem &&
    optionItem.tags &&
    optionItem.tags.custom_tag_4
  "
>
  <ion-text>{{
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_4.translations[
      groupStoreData.groupApp.selectedLanguage
    ] ||
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_4.translations[
        groupStoreData.groupApp.mo_base.code
      ]
  }}</ion-text>
</ion-badge>
<ion-badge
  [color]="
    groupStoreData &&
    groupStoreData.store_info &&
    groupStoreData.store_info.design_settings &&
    groupStoreData.store_info.design_settings.tagsColors &&
    groupStoreData.store_info.design_settings.tagsColors.custom_tag_5
      ? groupStoreData.store_info.design_settings.tagsColors.custom_tag_5
      : ''
  "
  class="badge-margin"
  *ngIf="
    groupStoreData &&
    groupStoreData.store_info &&
    groupStoreData.store_info.catalogCustomTagsById &&
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_5 &&
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_5.active &&
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_5.translations &&
    groupStoreData.groupApp &&
    groupStoreData.groupApp.mo_base &&
    groupStoreData.groupApp.mo_base.code &&
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_5.translations[
      groupStoreData.groupApp.mo_base.code
    ] &&
    optionItem &&
    optionItem.tags &&
    optionItem.tags.custom_tag_5
  "
>
  <ion-text>{{
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_5.translations[
      groupStoreData.groupApp.selectedLanguage
    ] ||
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_5.translations[
        groupStoreData.groupApp.mo_base.code
      ]
  }}</ion-text>
</ion-badge>
<ion-badge
  [color]="
    groupStoreData &&
    groupStoreData.store_info &&
    groupStoreData.store_info.design_settings &&
    groupStoreData.store_info.design_settings.tagsColors &&
    groupStoreData.store_info.design_settings.tagsColors.custom_tag_6
      ? groupStoreData.store_info.design_settings.tagsColors.custom_tag_6
      : ''
  "
  class="badge-margin"
  *ngIf="
    groupStoreData &&
    groupStoreData.store_info &&
    groupStoreData.store_info.catalogCustomTagsById &&
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_6 &&
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_6.active &&
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_6.translations &&
    groupStoreData.groupApp &&
    groupStoreData.groupApp.mo_base &&
    groupStoreData.groupApp.mo_base.code &&
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_6.translations[
      groupStoreData.groupApp.mo_base.code
    ] &&
    optionItem &&
    optionItem.tags &&
    optionItem.tags.custom_tag_6
  "
>
  <ion-text>{{
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_6.translations[
      groupStoreData.groupApp.selectedLanguage
    ] ||
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_6.translations[
        groupStoreData.groupApp.mo_base.code
      ]
  }}</ion-text>
</ion-badge>
<ion-badge
  [color]="
    groupStoreData &&
    groupStoreData.store_info &&
    groupStoreData.store_info.design_settings &&
    groupStoreData.store_info.design_settings.tagsColors &&
    groupStoreData.store_info.design_settings.tagsColors.custom_tag_7
      ? groupStoreData.store_info.design_settings.tagsColors.custom_tag_7
      : ''
  "
  class="badge-margin"
  *ngIf="
    groupStoreData &&
    groupStoreData.store_info &&
    groupStoreData.store_info.catalogCustomTagsById &&
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_7 &&
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_7.active &&
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_7.translations &&
    groupStoreData.groupApp &&
    groupStoreData.groupApp.mo_base &&
    groupStoreData.groupApp.mo_base.code &&
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_7.translations[
      groupStoreData.groupApp.mo_base.code
    ] &&
    optionItem &&
    optionItem.tags &&
    optionItem.tags.custom_tag_7
  "
>
  <ion-text>{{
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_7.translations[
      groupStoreData.groupApp.selectedLanguage
    ] ||
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_7.translations[
        groupStoreData.groupApp.mo_base.code
      ]
  }}</ion-text>
</ion-badge>
<ion-badge
  [color]="
    groupStoreData &&
    groupStoreData.store_info &&
    groupStoreData.store_info.design_settings &&
    groupStoreData.store_info.design_settings.tagsColors &&
    groupStoreData.store_info.design_settings.tagsColors.custom_tag_8
      ? groupStoreData.store_info.design_settings.tagsColors.custom_tag_8
      : ''
  "
  class="badge-margin"
  *ngIf="
    groupStoreData &&
    groupStoreData.store_info &&
    groupStoreData.store_info.catalogCustomTagsById &&
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_8 &&
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_8.active &&
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_8.translations &&
    groupStoreData.groupApp &&
    groupStoreData.groupApp.mo_base &&
    groupStoreData.groupApp.mo_base.code &&
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_8.translations[
      groupStoreData.groupApp.mo_base.code
    ] &&
    optionItem &&
    optionItem.tags &&
    optionItem.tags.custom_tag_8
  "
>
  <ion-text>{{
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_8.translations[
      groupStoreData.groupApp.selectedLanguage
    ] ||
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_8.translations[
        groupStoreData.groupApp.mo_base.code
      ]
  }}</ion-text>
</ion-badge>
<ion-badge
  [color]="
    groupStoreData &&
    groupStoreData.store_info &&
    groupStoreData.store_info.design_settings &&
    groupStoreData.store_info.design_settings.tagsColors &&
    groupStoreData.store_info.design_settings.tagsColors.custom_tag_9
      ? groupStoreData.store_info.design_settings.tagsColors.custom_tag_9
      : ''
  "
  class="badge-margin"
  *ngIf="
    groupStoreData &&
    groupStoreData.store_info &&
    groupStoreData.store_info.catalogCustomTagsById &&
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_9 &&
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_9.active &&
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_9.translations &&
    groupStoreData.groupApp &&
    groupStoreData.groupApp.mo_base &&
    groupStoreData.groupApp.mo_base.code &&
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_9.translations[
      groupStoreData.groupApp.mo_base.code
    ] &&
    optionItem &&
    optionItem.tags &&
    optionItem.tags.custom_tag_9
  "
>
  <ion-text>{{
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_9.translations[
      groupStoreData.groupApp.selectedLanguage
    ] ||
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_9.translations[
        groupStoreData.groupApp.mo_base.code
      ]
  }}</ion-text>
</ion-badge>

<ion-badge
  [color]="
    groupStoreData &&
    groupStoreData.store_info &&
    groupStoreData.store_info.design_settings &&
    groupStoreData.store_info.design_settings.tagsColors &&
    groupStoreData.store_info.design_settings.tagsColors.custom_tag_10
      ? groupStoreData.store_info.design_settings.tagsColors.custom_tag_10
      : ''
  "
  class="badge-margin"
  *ngIf="
    groupStoreData &&
    groupStoreData.store_info &&
    groupStoreData.store_info.catalogCustomTagsById &&
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_10 &&
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_10.active &&
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_10
      .translations &&
    groupStoreData.groupApp &&
    groupStoreData.groupApp.mo_base &&
    groupStoreData.groupApp.mo_base.code &&
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_10.translations[
      groupStoreData.groupApp.mo_base.code
    ] &&
    optionItem &&
    optionItem.tags &&
    optionItem.tags.custom_tag_10
  "
>
  <ion-text>{{
    groupStoreData.store_info.catalogCustomTagsById.custom_tag_10.translations[
      groupStoreData.groupApp.selectedLanguage
    ] ||
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_10
        .translations[groupStoreData.groupApp.mo_base.code]
  }}</ion-text>
</ion-badge>
