import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../store/app.reducer";
import * as _ from "lodash";
import { auditTime, catchError, distinctUntilChanged } from "rxjs/operators";
import * as DeliveryMethodAddressActions from "../../../store/deliveryMethodAddress/deliveryMethodAddress.actions";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { AlertController, ToastController } from "@ionic/angular";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import * as AuthActions from "../../../auth/store/auth.actions";
import { LoginPopupsServiceService } from "src/app/services/login-popups-service/login-popups-service.service";
import * as CompleteOrderValidationActions from "../../../store/complete-order-validation/completeOrderValidation.actions";
import { AnalyticsServiceService } from "src/app/services/analitycsService/analytics-service.service";
import { Capacitor } from "@capacitor/core";

declare let window: any;

@Component({
  selector: "app-customer-info",
  templateUrl: "./customer-info.component.html",
  styleUrls: ["./customer-info.component.scss"],
})
export class CustomerInfoComponent implements OnInit, OnDestroy {
  public currentUser: any;
  public address: any;
  public orderType: string = "delivery";
  public mo_token: any;
  public selectRef;
  public loginLoading: boolean = false;
  public newCustomer: any;
  public finalPriceLoading: boolean;
  public valid: any = true;
  public lastValidTimestamp: any = null;
  public customerDataSaveLoading: boolean = false;
  public customerDataSaveSuccess: boolean = false;
  public customerDataDirty: boolean = false;
  public groupApp: any;
  private validationObject: any;
  private subscriptions: Subscription[] = [];
  private orderPickup: boolean;
  private dineIn: boolean;
  private toast: any;

  constructor(
    private store: Store<fromApp.AppState>,
    private translateService: TranslateService,
    private toastController: ToastController,
    private dataStorageService: DataStorageService,
    private loginPopupService: LoginPopupsServiceService,
    private alertController: AlertController,
    private analyticsService: AnalyticsServiceService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(this.groupApp, state.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("completeOrderValidation")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.lastValidTimestamp &&
            !_.isEqual(this.lastValidTimestamp, state.lastValidTimestamp)
          ) {
            //console.log("inside complete order validation sub");
            this.lastValidTimestamp = _.cloneDeep(state.lastValidTimestamp);
            this.completeOrderCustomerInfoValidations();
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && !_.isEqual(state.user, this.currentUser)) {
            //console.log("user sub triggered");
            this.currentUser = _.cloneDeep(state.user);
            this.customerInfoValidations();
          }
          if (state && !_.isEqual(state.newCustomer, this.newCustomer)) {
            this.newCustomer = _.cloneDeep(state.newCustomer);
          }
          if (state && !_.isEqual(state.loginLoading, this.loginLoading)) {
            this.loginLoading = _.cloneDeep(state.loginLoading);
          }
          if (
            state &&
            state.mo_token &&
            !_.isEqual(this.mo_token, state.mo_token)
          ) {
            this.mo_token = _.cloneDeep(state.mo_token);
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("disableFinalPrice")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.finalPriceLoading, state.disableFinalPrice)
          ) {
            this.finalPriceLoading = _.cloneDeep(state.disableFinalPrice);
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("deliveryMethodAddress")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.orderPickup != null &&
            !_.isEqual(this.orderPickup, state.orderPickup)
          ) {
            this.orderPickup = _.cloneDeep(state.orderPickup);
          }

          if (
            state &&
            state.dineIn != null &&
            !_.isEqual(this.dineIn, state.dineIn)
          ) {
            this.dineIn = _.cloneDeep(state.dineIn);
          }

          if (
            state &&
            state.selectedAddress &&
            !_.isEqual(this.address, state.selectedAddress)
          ) {
            this.address = _.cloneDeep(state.selectedAddress);
          }
        })
    );
  }

  customerInfoValidations() {
    // console.log("user validation", this.currentUser);
    this.valid = true;
    if (this.currentUser) {
      if (!this.currentUser.firstName) {
        this.valid = false;
        //console.log("user firstName", this.currentUser);
      }
      if (!this.currentUser.lastName) {
        this.valid = false;
        // console.log("user lastName", this.currentUser);
      }
    } else {
      console.log("validation no user");
      this.valid = false;
    }
  }

  completeOrderCustomerInfoValidations() {
    if (this.currentUser) {
      this.valid = true;
      this.validationObject = {
        id: "customer-info",
        valid: true,
        errorMessages: [],
      };
      if (!this.currentUser.firstName) {
        this.validationObject.errorMessages.push(
          "cart.cart-components.customer-info.please-type-first-name"
        );
        this.validationObject.valid = false;
        this.valid = false;
      }
      if (!this.currentUser.lastName) {
        this.validationObject.errorMessages.push(
          "cart.cart-components.customer-info.please-type-last-name"
        );
        this.validationObject.valid = false;
        this.valid = false;
      }
      this.store.dispatch(
        new CompleteOrderValidationActions.AddUpdateCompleteOrderValidation(
          _.cloneDeep(this.validationObject)
        )
      );
    } else {
      this.valid = false;
      this.validationObject = {
        id: "customer-info",
        modal: "loginModal",
        valid: false,
        errorMessages: [
          this.groupApp && this.groupApp.active_reservation_app
            ? "cart.cart-components.customer-info.you-must-login-to-complete-reservation"
            : "cart.cart-components.customer-info.you-must-login-to-complete-order",
        ],
      };

      this.store.dispatch(
        new CompleteOrderValidationActions.AddUpdateCompleteOrderValidation(
          _.cloneDeep(this.validationObject)
        )
      );
    }
  }

  async presentMagicLoginPopup() {
    // this.MagicLoginservice.presentLoginModal(this.newCustomer);
    this.analyticsService.dmAnalyticsMessage(
      "login_button",
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
    if (this.groupApp && this.groupApp.active_reservation_app) {
      this.loginPopupService.presentFirebaseModalLogin(null, null);
    } else {
      if (!Capacitor.isNativePlatform()) {
        this.loginPopupService.presentFirebaseModalLogin(null, null);
      } else {
        this.loginPopupService.presentMobileMagicLoginAlert(
          this.newCustomer,
          this.groupApp
        );
      }
    }
  }

  calculateMethod() {
    if (this.orderPickup != null && this.dineIn != null) {
      if (this.orderPickup && !this.dineIn) {
        return "takeAway";
      } else if (!this.orderPickup && !this.dineIn) {
        return "delivery";
      } else if (this.dineIn && !this.orderPickup) {
        return "dineIn";
      }
    }
  }

  onMobileClick() {
    let notify = this.translateService.instant(
      "account-settings.this-mobile-number-cannot-be-changed"
    );
    this.presentErrorToast(notify, "primary");
  }

  async presentErrorToast(message, color) {
    this.toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            //  console.log("Toast Closed");
          },
        },
      ],
    });
    this.toast.present();
  }

  onInputFocus() {
    this.customerDataDirty = true;
  }

  updateCustomerDataDebounced = _.debounce((attr) => {
    this.updateCustomerData(attr);
  }, 1500);

  updateCustomerData(attr) {
    if (this.customerDataDirty) {
      // console.log("ngmodelchange", this.currentUser.firstName);
      this.customerDataSaveSuccess = false;

      if (
        (attr === "firstName" && this.currentUser.firstName) ||
        (attr === "lastName" && this.currentUser.lastName)
      ) {
        let obj = { updatedData: _.cloneDeep(this.currentUser) };
        this.customerDataSaveLoading = true;
        // console.log("customerObjecy", obj);
        // console.log("ngmodelchange", this.currentUser);
        this.dataStorageService

          .updateCustomerData(obj, this.mo_token)

          .subscribe({
            next: (res: any) => {
              if (res && res.success) {
                //console.log("ngmodelchange", this.currentUser);
                this.customerDataSaveLoading = false;
                this.customerDataSaveSuccess = true;
                let updatedUser = res.data;
                this.store.dispatch(
                  new AuthActions.SetUser(_.cloneDeep(updatedUser))
                );
                this.customerInfoValidations();
              } else {
                this.customerDataSaveLoading = false;
                if (res.comment_id) {
                  this.presentErrorToast(
                    this.translateService.instant(res.comment_id),
                    "danger"
                  );
                } else if (res.comments) {
                  this.presentErrorToast(res.comments, "danger");
                }
              }
            },
            error: (err) => {
              this.customerDataSaveLoading = false;
              let errMsg = this.translateService.instant(
                "errorMessages.error: There was an error on saving"
              );

              this.presentErrorToast(errMsg, "danger");
              return err;
              console.log("error updating the information");
            },
          });
      } else {
        this.customerDataSaveLoading = false;
        if (this.toast) {
          this.toast.dismiss();
          this.toast = null;
        }
        this.presentErrorToast(
          this.translateService.instant(
            "cart.cart-components.customer-info.the-filed-is-required"
          ),
          "danger"
        );
      }
    }
  }

  ngOnDestroy() {
    this.loginPopupService.closeFirebaseModalLogin();
    this.loginPopupService.closeConfirmationLoginModal();
    this.loginPopupService.closeMobileMagicLoginAlert();

    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }
}
