import { Action } from "@ngrx/store";

export const SET_CATALOG = "[CATALOG] Set Catalog";
export const UPDATE_CATEGORIES_PROMOTIONS =
  "[CATALOG] Update Categories Promotions";
export const SET_CATEGORY_PRODUCTS = "[CATALOG] Set Category Products";
export const SET_ALL_CATEGORIES = "[CATALOG] Set All Categories";
export const SET_CATALOG_UNFILTERED = "[CATALOG] Set Catalog Unfiltered";
export const SET_CUSTOMER_PRICELIST = "[CATALOG] Set Customer Pricelist";
export const SET_PRODUCTS_CATALOG_VISIBILITY =
  "[CATALOG] Set Products Catalog Visibility";
export const SET_ALL_CATEGORIES_MARKET_CARDS_DESIGN =
  "[CATALOG] Set All Categories Market Cards Design";

export const SET_HYPERCATEGORIES_SELECTION_MODAL_OPENED =
  "[CATALOG] Set Hyper Categories Selection Modal Opened";

export const CLEAR_CATALOG = "[CATALOG] Clear Catalog";

export class SetCatalog implements Action {
  readonly type = SET_CATALOG;
  constructor(public payload: any) {}
}

export class UpdateCategoriesPromotions implements Action {
  readonly type = UPDATE_CATEGORIES_PROMOTIONS;
  constructor(public payload: any) {}
}

export class SetAllCategories implements Action {
  readonly type = SET_ALL_CATEGORIES;
  constructor(public payload: any) {}
}

export class SetCatalogUnfiltered implements Action {
  readonly type = SET_CATALOG_UNFILTERED;
  constructor(public payload: any) {}
}

export class SetCategoryProducts implements Action {
  readonly type = SET_CATEGORY_PRODUCTS;
  constructor(public payload: { category_id: string; products: any }) {}
}

export class SetCustomerPricelist implements Action {
  readonly type = SET_CUSTOMER_PRICELIST;
  constructor(public payload: any) {}
}

export class SetProductsCatalogVisibility implements Action {
  readonly type = SET_PRODUCTS_CATALOG_VISIBILITY;
  constructor(
    public payload: { product_id: any; visibility: boolean; name: string }
  ) {}
}

export class SetAllCategoriesMarketCardsDesign implements Action {
  readonly type = SET_ALL_CATEGORIES_MARKET_CARDS_DESIGN;
  constructor(public payload: any) {}
}

export class SetHyperCategoriesSelectionModalOpened implements Action {
  readonly type = SET_HYPERCATEGORIES_SELECTION_MODAL_OPENED;
  constructor(public payload: any) {}
}

export class ClearCatalog implements Action {
  readonly type = CLEAR_CATALOG;
  constructor() {}
}

export type CatalogActions =
  | SetCatalog
  | UpdateCategoriesPromotions
  | SetCatalogUnfiltered
  | SetAllCategories
  | SetCategoryProducts
  | SetCustomerPricelist
  | SetProductsCatalogVisibility
  | SetAllCategoriesMarketCardsDesign
  | SetHyperCategoriesSelectionModalOpened
  | ClearCatalog;
