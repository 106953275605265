import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { AlertController, ModalController, Platform } from "@ionic/angular";
import { ReservationSelectDatePage } from "../reservation-select-date/reservation-select-date.page";
import { Subscription, auditTime, distinctUntilChanged, first } from "rxjs";
import { Store } from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";
import * as selectors from "../../store/app.selectors";
import _ from "lodash";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import { TranslateService } from "@ngx-translate/core";
import * as ReservationsActions from "../../reservations/store/reservations.actions";
import * as DeliveryMethodAddressActions from "../../store/deliveryMethodAddress/deliveryMethodAddress.actions";
import { AnimationOptions } from "ngx-lottie";
@Component({
  selector: "app-reservation-select-artist",
  templateUrl: "./reservation-select-artist.page.html",
  styleUrls: ["./reservation-select-artist.page.scss"],
})
export class ReservationSelectArtistPage implements OnInit, OnDestroy {
  @Input() product_id;
  public selectedProduct;
  public tables_artists: any;
  public storeInfo: any;
  public selectedArtist: any;
  public showButton: boolean | undefined;
  public selectedArtistIndex: number = -1; // Index of the selected artist (-1 means none selected)
  public isWobble = false;
  public selectedLanguage;
  public finalPriceLoading;
  public reservationsFetchTimestamp = null;
  public loading = false;
  public sadAnimationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/sad-emoji-1.json",
  };

  public windowDimensions: any;
  public columnSize: any;
  public columnSize2: number;
  public groupApp: any;
  public finalPriceLoadingWithLatency;

  private cartProducts;
  private reservationSelectedDateModal;
  private fetchTablesLoading;
  private subscriptions: Subscription[] = [];
  private unsubscribeBackEvent: Subscription;

  constructor(
    public modalCtrl: ModalController,
    private translateService: TranslateService,
    private store: Store<fromApp.AppState>,
    private dataStorageService: DataStorageService,
    private alertController: AlertController,
    private platform: Platform
  ) {}

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android") && !this.finalPriceLoadingWithLatency) {
          this.modalCtrl.dismiss("back");
        }
      }
    );
    this.subscriptions.push(
      this.store
        .select("cart")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(200))
        .subscribe((state) => {
          if (state && !_.isEqual(state.products, this.cartProducts)) {
            this.cartProducts = _.cloneDeep(state.products);
            if (this.cartProducts && this.cartProducts.length) {
              this.selectedProduct = _.find(_.cloneDeep(this.cartProducts), {
                product_id: this.product_id,
              });
            } else {
              this.selectedProduct = null;
              this.modalCtrl.dismiss("back");
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("disableFinalPrice")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(state.disableFinalPrice, this.finalPriceLoading)
          ) {
            this.finalPriceLoading = _.cloneDeep(state.disableFinalPrice);
            if (this.finalPriceLoading) {
              this.finalPriceLoadingWithLatency = _.cloneDeep(
                this.finalPriceLoading
              );
            } else {
              setTimeout(() => {
                this.finalPriceLoadingWithLatency = _.cloneDeep(
                  this.finalPriceLoading
                );
              }, 400);
            }
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(auditTime(50))
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(this.groupApp, state.groupApp)
          ) {
            const firstTime = !!(!this.groupApp && _.cloneDeep(state.groupApp));
            this.groupApp = _.cloneDeep(state.groupApp);
            if (firstTime) {
              this.getReservationTables();
            }
          }

          if (
            state &&
            state.selectedLangugage &&
            !_.isEqual(this.selectedLanguage, state.selectedLangugage)
          ) {
            this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.windowDimensions, state.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
            // [size]="windowDimensions.width > 600 ? '6': '12'"
            if (this.windowDimensions.width > 700) {
              this.columnSize = 8;
              this.columnSize2 = 4;
            } else {
              this.columnSize = 12;
              this.columnSize2 = 12;
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .subscribe((storeInfo) => {
          if (storeInfo && !_.isEqual(storeInfo, this.storeInfo)) {
            this.storeInfo = _.cloneDeep(storeInfo);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("reservations")

        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.reservationsFetchTimestamp &&
            !_.isEqual(
              state.reservationsFetchTimestamp,
              this.reservationsFetchTimestamp
            )
          ) {
            this.reservationsFetchTimestamp = _.cloneDeep(
              state.reservationsFetchTimestamp
            );
            if (this.reservationSelectedDateModal) {
              this.reservationSelectedDateModal.dismiss();
              this.reservationSelectedDateModal = null;
            }
            this.store.dispatch(
              new ReservationsActions.SetFetchReservationsTimestamp(null)
            );
            this.getReservationTables();
          }
        })
    );

    this.finalPriceLoadingWithLatency = true;
  }

  getReservationTables() {
    this.loading = true;
    this.dataStorageService
      .fetchArtistTables(
        this.groupApp.group,
        this.storeInfo.store_id,
        this.product_id
      )
      .subscribe({
        next: async (res: any) => {
          this.loading = false;
          if (!res || !res.success) {
            const alert = await this.alertController.create({
              header: this.translateService.instant("alert"),
              message:
                res && res.comment_id
                  ? this.translateService.instant(res.comment_id)
                  : this.translateService.instant(
                      "errorMessages.general_error"
                    ),
              backdropDismiss: false,
              buttons: ["OK"],
            });
            await alert.present();
          } else {
            this.tables_artists = _.cloneDeep(res.tables);
            this.loading = false;
            if (this.selectedArtist) {
              const selectedArtistIndex = _.find(this.tables_artists, {
                table_id: this.selectedArtist.table_id,
              });

              if (selectedArtistIndex !== -1) {
                this.selectArtist(
                  selectedArtistIndex,
                  _.cloneDeep(this.selectedArtist)
                );
                this.selectDatePage();
              } else {
                this.selectedArtist = null;
              }
            }
          }
        },
        error: async (error) => {
          const alert = await this.alertController.create({
            header: this.translateService.instant("alert"),
            message: this.translateService.instant(
              "errorMessages.problem_reaching_server"
            ),

            buttons: ["OK"],
          });
          await alert.present();
        },
      });
  }

  selectArtist(index: number, item: any) {
    if (this.selectedArtistIndex !== index) {
      this.selectedArtistIndex = index;
      if (this.showButton) {
        this.isWobble = !this.isWobble;
      }
      this.showButton = true;
      this.selectedArtist = item;
      this.setDineInAndSelectedTable();

      setTimeout(() => {
        this.isWobble = false;
      }, 1000);
    }
  }

  async selectDatePage() {
    if (this.selectedArtist && this.selectedArtist.dinein_table_id) {
      if (!this.reservationSelectedDateModal) {
        this.reservationSelectedDateModal = await this.modalCtrl.create({
          component: ReservationSelectDatePage,
          animated: true,
          backdropDismiss: false,
          componentProps: {
            artist: this.selectedArtist,
            storeInfo: this.storeInfo,
            product_id: this.selectedProduct.product_id,
            selectedLanguage: this.selectedLanguage,
          },
          cssClass: "fullScreenModal",
        });
        await this.reservationSelectedDateModal.present();
        await this.reservationSelectedDateModal.onDidDismiss().then((data) => {
          this.reservationSelectedDateModal = null;
        });
      }
    }
  }

  // fetchCheckDineInTables(table_id) {
  //   return new Promise((resolve, reject) => {
  //     this.fetchTablesLoading = true;
  //     this.dataStorageService
  //       .getDineInTables(this.storeInfo.store_id)
  //       .subscribe({
  //         next: async (res: any) => {
  //           this.fetchTablesLoading = false;

  //           if (!res || !res.success) {
  //             resolve(null);
  //             const alert = await this.alertController.create({
  //               header: this.translateService.instant("alert"),
  //               message:
  //                 res && res.comment_id
  //                   ? this.translateService.instant(res.comment_id)
  //                   : this.translateService.instant(
  //                       "errorMessages.general_error"
  //                     ),
  //               backdropDismiss: false,
  //               buttons: ["OK"],
  //             });

  //             await alert.present();
  //           } else {
  //             resolve(_.find(res.tables, { table_id: table_id }));
  //           }
  //         },
  //         error: async (error) => {
  //           this.fetchTablesLoading = false;
  //           resolve(null);
  //           const alert = await this.alertController.create({
  //             header: this.translateService.instant("alert"),
  //             message: this.translateService.instant(
  //               "errorMessages.problem_reaching_server"
  //             ),

  //             buttons: ["OK"],
  //           });
  //           await alert.present();
  //           return error;
  //         },
  //       });
  //   });
  // }

  async setDineInAndSelectedTable() {
    const selectedTable: any = this.selectedArtist.dinein_table;
    selectedTable.reservation_table_id = this.selectedArtist.table_id;
    if (this.selectedArtist.user && this.selectedArtist.user.name){
      selectedTable.user_name = {
        'el': this.selectedArtist.user.name
      };
    }

    if (selectedTable) {
      this.store.dispatch(
        new DeliveryMethodAddressActions.setSelectedDineInTable(_.cloneDeep(selectedTable))
      );
      this.store.dispatch(new DeliveryMethodAddressActions.setDineIn(true));
      this.store.dispatch(
        new DeliveryMethodAddressActions.setOrderPickup(false)
      );
      this.store.dispatch(
        new DeliveryMethodAddressActions.setValidDeliveryAreas(null)
      );

      if (navigator.cookieEnabled) {
        if (localStorage.getItem("deliveryMethodAddress")) {
          localStorage.removeItem("deliveryMethodAddress");
        }

        let deliveryMethodAddress = {
          orderPickup: false,
          dineIn: true,
          selectedDineInTable: selectedTable,
        };
        window.localStorage.setItem(
          "deliveryMethodAddress",
          JSON.stringify(deliveryMethodAddress)
        );
      }
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.forEach((sub) => {
        if (sub) {
          sub.unsubscribe();
        }
      });
    }
    this.subscriptions = [];

    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }

    if (this.reservationSelectedDateModal) {
      this.reservationSelectedDateModal.dismiss();
      this.reservationSelectedDateModal = null;
    }
  }
}
