import { Action } from "@ngrx/store";

export const ADD_SELECTED_PRODUCT = "[SELECTED PRODUCT] Add Selected Product";
export const SET_SELECTED_PRODUCT_TOTAL_PRICE =
  "[SELECTED PRODUCT] Set Selected Product Total Price";
export const SET_SELECTED_PRODUCT_POINTS_TO_EARN =
  "[SELECTED PRODUCT] Set Selected Product Points To Earn";
export const SET_RECALCULATE_PRODUCT_PREORDER_AVAILABILITY =
  "[SELECTED PRODUCT] Set Recaluclate Product Preorder Availability";
export const SET_OPEN_PRODUCT_DETAILS_MODAL_APP_COMPONENT =
  "[SELECTED PRODUCT] Set Open Product Details Modal App Componenent";
export const CLEAR_SELECTED_PRODUCT_TOTAL_PRICE =
  "[SELECTED PRODUCT] Clear Selected Product Total Price";
export const CLEAR_SELECTED_PRODUCT_POINTS_TO_EARN =
  "[SELECTED PRODUCT] Clear Selected Product Points To Earn";
export const CLEAR_SELECTED_PRODUCT =
  "[SELECTED PRODUCT] Clear Selected Product";
export const CLEAR_SELECTED_BUNDLE_CHOICE_BY_ID =
  "[SELECTED PRODUCT] Clear Selected Bundle Choice By Id";

export class SetSelectedProduct implements Action {
  readonly type = ADD_SELECTED_PRODUCT;
  constructor(public payload: any) {}
}

export class SetRecalculateProductPreorderAvailability implements Action {
  readonly type = SET_RECALCULATE_PRODUCT_PREORDER_AVAILABILITY;
  constructor(public payload: any) {}
}

export class SetSelectedProductTotalPrice implements Action {
  readonly type = SET_SELECTED_PRODUCT_TOTAL_PRICE;
  constructor(
    public payload: { selectedProductId: any; productTotalPrice: any }
  ) {}
}

export class SetSelectedProductPointsToEarn implements Action {
  readonly type = SET_SELECTED_PRODUCT_POINTS_TO_EARN;
  constructor(
    public payload: { selectedProductId: any; productPointsToEarn: any }
  ) {}
}

export class SetOpenProductDetailsModalFromAppComponent implements Action {
  readonly type = SET_OPEN_PRODUCT_DETAILS_MODAL_APP_COMPONENT;
  constructor(public payload: any) {}
}

export class ClearSelectedProductTotalPrice implements Action {
  readonly type = CLEAR_SELECTED_PRODUCT_TOTAL_PRICE;
  constructor(public payload: any) {}
}
export class ClearSelectedProductPointsToEarn implements Action {
  readonly type = CLEAR_SELECTED_PRODUCT_POINTS_TO_EARN;
  constructor(public payload: any) {}
}
export class ClearSelectedProduct implements Action {
  readonly type = CLEAR_SELECTED_PRODUCT;
  constructor(public payload: any) {}
}

export class clearSelectedBundleChoiceById implements Action {
  readonly type = CLEAR_SELECTED_BUNDLE_CHOICE_BY_ID;
  constructor(
    public payload: {
      product_id_option_id: any;
      option_id_choice_id: any;
      selectedProductId: any;
    }
  ) {}
}

export type SelectedProductActions =
  | SetSelectedProduct
  | SetSelectedProductTotalPrice
  | SetSelectedProductPointsToEarn
  | SetRecalculateProductPreorderAvailability
  | SetOpenProductDetailsModalFromAppComponent
  | clearSelectedBundleChoiceById
  | ClearSelectedProductTotalPrice
  | ClearSelectedProductPointsToEarn
  | ClearSelectedProduct;
