<div *ngIf="option && !catalogMode">
  <div *ngFor="let choice of option.choices; let index = index">
    <ion-item
      *ngIf="
        choice.qty > 0 && choice.totalPrice && choice.totalPrice !== '0.00'
      "
      lines="none"
    >
      <ion-label class="ion-text-wrap ion-no-margin"
        ><strong style="margin-right: 30px">{{
          "cartCustomOptions.plastic_bag_tax_message" | translate
        }}</strong>

        <p *ngIf="choice.qty >= 1">
          {{ "cartCustomOptions.it-was-calculated-that-you-need" | translate }}
          {{ choice.qty }}
          {{
            choice.qty === 1
              ? ("cartCustomOptions.plastic-bug" | translate)
              : ("cartCustomOptions.plastic-bugs" | translate)
          }}
        </p>
        <div
          style="
            margin-left: 2px;
            position: absolute;
            top: 2px;
            right: 5px;
            font-size: medium;
            font-weight: 500;
          "
        >
          {{ choice.totalPrice + "€" }}
        </div>
      </ion-label>
    </ion-item>
  </div>
</div>
