import * as SelectedProductActions from "./selectedProduct.actions";
import * as _ from "lodash";

export interface State {
  selectedProductsArray: any;
  selectedProductsTotalPricesArray: any;
  selectedProductsPointsToEarnArray: any;
  recalculateProductPreorderAvailabilityTimestamp: any;
  clearSelectedBundleChoiceObject: any;
  openProductDetailsModalAppComponentProductId: any;
}

const initialState: State = {
  selectedProductsArray: null,
  selectedProductsTotalPricesArray: null,
  selectedProductsPointsToEarnArray: null,
  recalculateProductPreorderAvailabilityTimestamp: null,
  clearSelectedBundleChoiceObject: null,
  openProductDetailsModalAppComponentProductId: null,
};

export function selectedProductReducer(
  state = initialState,
  action: SelectedProductActions.SelectedProductActions
) {
  switch (action.type) {
    case SelectedProductActions.ADD_SELECTED_PRODUCT:
      const key = _.findIndex(state.selectedProductsArray, (item: any) => {
        return item.selectedProductId === action.payload.selectedProductId;
      });
      if (key !== -1) {
        const updateProduct = {
          ...state.selectedProductsArray[key],
          ...action.payload,
        };
        const updatedProducts = [...state.selectedProductsArray];
        updatedProducts[key] = updateProduct;
        return {
          ...state,
          selectedProductsArray: updatedProducts,
        };
      } else {
        if (!state.selectedProductsArray) {
          return {
            ...state,
            selectedProductsArray: [action.payload],
          };
        } else {
          return {
            ...state,
            selectedProductsArray: [
              ...state.selectedProductsArray,
              action.payload,
            ],
          };
        }
      }

    case SelectedProductActions.SET_RECALCULATE_PRODUCT_PREORDER_AVAILABILITY:
      return {
        ...state,
        recalculateProductPreorderAvailabilityTimestamp: action.payload,
      };

    case SelectedProductActions.SET_OPEN_PRODUCT_DETAILS_MODAL_APP_COMPONENT:
      return {
        ...state,
        openProductDetailsModalAppComponentProductId: action.payload,
      };

    case SelectedProductActions.SET_SELECTED_PRODUCT_TOTAL_PRICE:
      let updatedPriceArray = _.cloneDeep(
        state.selectedProductsTotalPricesArray
      );
      if (!updatedPriceArray) {
        updatedPriceArray = [];
      }

      const indx = _.findIndex(
        state.selectedProductsTotalPricesArray,
        (item: any) => {
          return item.selectedProductId === action.payload.selectedProductId;
        }
      );

      if (indx !== -1) {
        const updatedItem = _.cloneDeep(
          state.selectedProductsTotalPricesArray[indx]
        );
        updatedItem.productTotalPrice = action.payload.productTotalPrice;
        updatedPriceArray[indx] = updatedItem;
      } else {
        updatedPriceArray = [...updatedPriceArray, action.payload];
      }

      return {
        ...state,
        selectedProductsTotalPricesArray: updatedPriceArray,
      };

    case SelectedProductActions.CLEAR_SELECTED_PRODUCT_TOTAL_PRICE:
      const indexDelete = _.findIndex(
        state.selectedProductsTotalPricesArray,
        (item: any) => {
          return item.selectedProductId === action.payload;
        }
      );
      let newArr = _.cloneDeep(state.selectedProductsTotalPricesArray);
      if (indexDelete !== -1) {
        newArr.splice(indexDelete, 1);
      }

      return {
        ...state,
        selectedProductsTotalPricesArray: _.isEmpty(newArr) ? null : newArr,
      };

    case SelectedProductActions.SET_SELECTED_PRODUCT_POINTS_TO_EARN:
      let pointsUpdatedArray = _.cloneDeep(
        state.selectedProductsPointsToEarnArray
      );
      if (!pointsUpdatedArray) {
        pointsUpdatedArray = [];
      }

      const index_arr = _.findIndex(
        state.selectedProductsPointsToEarnArray,
        (item: any) => {
          return item.selectedProductId === action.payload.selectedProductId;
        }
      );

      if (index_arr !== -1) {
        const pointUpdatedItem = _.cloneDeep(
          state.selectedProductsPointsToEarnArray[index_arr]
        );
        pointUpdatedItem.productPointsToEarn =
          action.payload.productPointsToEarn;
        pointsUpdatedArray[index_arr] = pointUpdatedItem;
      } else {
        pointsUpdatedArray = [...pointsUpdatedArray, action.payload];
      }

      return {
        ...state,
        selectedProductsPointsToEarnArray: pointsUpdatedArray,
      };

    case SelectedProductActions.CLEAR_SELECTED_PRODUCT_POINTS_TO_EARN:
      const deletionIndex = _.findIndex(
        state.selectedProductsPointsToEarnArray,
        (item: any) => {
          return item.selectedProductId === action.payload;
        }
      );
      let newArray = _.cloneDeep(state.selectedProductsPointsToEarnArray);
      if (deletionIndex !== -1) {
        newArray.splice(deletionIndex, 1);
      }

      return {
        ...state,
        selectedProductsPointsToEarnArray: _.isEmpty(newArray)
          ? null
          : newArray,
      };

    case SelectedProductActions.CLEAR_SELECTED_PRODUCT:
      const indexForDelete = _.findIndex(
        state.selectedProductsArray,
        (item: any) => {
          return item.selectedProductId === action.payload;
        }
      );
      let updatedArray = _.cloneDeep(state.selectedProductsArray);
      if (indexForDelete !== -1) {
        updatedArray.splice(indexForDelete, 1);
      }

      return {
        ...state,
        selectedProductsArray: _.isEmpty(updatedArray) ? null : updatedArray,
      };

    case SelectedProductActions.CLEAR_SELECTED_BUNDLE_CHOICE_BY_ID:
      if (
        !action.payload.option_id_choice_id &&
        !action.payload.product_id_option_id &&
        !action.payload.selectedProductId
      ) {
        return {
          ...state,
          clearSelectedBundleChoiceObject: null,
        };
      } else {
        return {
          ...state,
          clearSelectedBundleChoiceObject: action.payload,
        };
      }

    default:
      return state;
  }
}
