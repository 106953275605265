import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";
import { Subscription, auditTime, distinctUntilChanged, first } from "rxjs";
import * as _ from "lodash";
import { ModalController, Platform } from "@ionic/angular";
import * as selectors from "../../store/app.selectors";
import * as RecommendedProductsActions from "../../store/recommended-products/recommended-products.actions";
import { ProductDetailsOperationsService } from "src/app/product-details/product-details-operations.service";
import { resolve } from "path";

@Component({
  selector: "app-recommended-products-popup",
  templateUrl: "./recommended-products-popup.component.html",
  styleUrls: ["./recommended-products-popup.component.scss"],
})
export class RecommendedProductsPopupComponent implements OnInit, OnDestroy {
  public recommended_products: any;
  public recommended_products_by_category_id: any;
  public numberOfRecommendedCategories = 0;
  public groupStoreData: any = {};
  public cartProducts: any;
  public cartProductIds: any;
  public dineIn;
  public orderPickup;
  public selectedDineInTable;
  public hyperCategories;
  public groupApp;
  public webp;
  public mo_base;
  public productsColSizeMobile = 6;
  public productsColSizeBrowser = 6;
  public selectedPreorderDate;
  public windowDimensions: any;
  public finalPriceLoading: any;
  public loading = true;
  public recommended_products_filtered;
  public firstTime = true;
  public selectedLanguage;
  public uniqueCategory;
  public thereIsARecommendedInCart: boolean = false;

  public gridProductsMaxWidth = "800px";

  private storeInfo: any;
  private unsubscribeBackEvent: Subscription;
  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store<fromApp.AppState>,
    private modalController: ModalController,
    private platform: Platform,
    private productDetailsOperations: ProductDetailsOperationsService
  ) {}

  closeModal() {
    this.modalController.dismiss();

    const appearedRecommendedProductsUpdate = _.cloneDeep(
      this.recommended_products
    );
    _.each(appearedRecommendedProductsUpdate, (prd) => {
      prd.recommendation_appeared = true;
    });

    this.store.dispatch(
      new RecommendedProductsActions.SetRecommendedProducts(
        _.cloneDeep(appearedRecommendedProductsUpdate)
      )
    );
  }
  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      300,
      () => {
        if (this.platform.is("android")) {
          this.modalController.dismiss();
        }
      }
    );
    this.subscriptions.push(
      this.store
        .select("recommendedProducts")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(200))
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(state.recommended_products, this.recommended_products)
          ) {
            this.recommended_products = _.cloneDeep(state.recommended_products);

            if (
              !this.recommended_products_filtered ||
              (this.recommended_products_filtered &&
                this.recommended_products_filtered.length &&
                state.recommended_products.length &&
                _.filter(_.cloneDeep(state.recommended_products), (prd) => {
                  return !prd.recommendation_appeared;
                }).length > this.recommended_products_filtered.length)
            ) {
              if (
                !this.recommended_products_filtered ||
                _.isEmpty(this.recommended_products_filtered)
              ) {
                this.recommended_products_filtered = _.filter(
                  _.cloneDeep(state.recommended_products),
                  (prd) => {
                    return !prd.recommendation_appeared;
                  }
                );
              } else {
                _.each(
                  _.cloneDeep(state.recommended_products),
                  (recProduct) => {
                    if (
                      !_.find(this.recommended_products_filtered, {
                        product_id: recProduct.product_id,
                      }) &&
                      !recProduct.recommendation_appeared
                    ) {
                      this.recommended_products_filtered.push(
                        _.cloneDeep(recProduct)
                      );
                    }
                  }
                );
              }

              if (this.recommended_products_filtered) {
                this.calculateRecommendedProductsByCategoryId();
              }
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("disableFinalPrice")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(state.disableFinalPrice, this.finalPriceLoading)
          ) {
            this.finalPriceLoading = _.cloneDeep(state.disableFinalPrice);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("deliveryMethodAddress")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && !_.isEqual(state.orderPickup, this.orderPickup)) {
            this.orderPickup = _.cloneDeep(state.orderPickup);
          }
          if (state && !_.isEqual(state.dineIn, this.dineIn)) {
            this.dineIn = _.cloneDeep(state.dineIn);
          }
          if (
            state &&
            !_.isEqual(state.selectedDineInTable, this.selectedDineInTable)
          ) {
            this.selectedDineInTable = _.cloneDeep(state.selectedDineInTable);
          }

          if (
            state &&
            !_.isEqual(state.selectedPreorderDate, this.selectedPreorderDate)
          ) {
            this.selectedPreorderDate = _.cloneDeep(state.selectedPreorderDate);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("hyperCategories")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(state.hyperCategories, this.hyperCategories)
          ) {
            this.hyperCategories = _.cloneDeep(state.hyperCategories);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(state.windowDimensions, this.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
            this.calculateProductsColSize();
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .subscribe((storeInfo) => {
          if (storeInfo && !_.isEqual(this.storeInfo, storeInfo)) {
            this.storeInfo = _.cloneDeep(storeInfo);
            const catalogCustomTagsById = {};
            _.each(_.cloneDeep(this.storeInfo.catalogCustomTags), (tag) => {
              catalogCustomTagsById[tag.id] = tag;
            });
            this.groupStoreData.store_info = {
              overridePromoItemsForPointsSystem: _.cloneDeep(
                this.storeInfo.overridePromoItemsForPointsSystem
              ),
              alt_dinein_active: this.storeInfo.dinein
                ? _.cloneDeep(this.storeInfo.alt_dinein_active)
                : null,
              catalogCustomTagsById: catalogCustomTagsById,
              design_settings: storeInfo.design_settings,
            };
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(200))
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(this.groupApp, state.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
            this.groupStoreData.groupApp = {
              group: _.cloneDeep(this.groupApp.group),
              disableCatalogPhoto: _.cloneDeep(
                this.groupApp.disableCatalogPhoto
              ),
              pointsSystem: _.cloneDeep(this.groupApp.pointsSystem),
              iconImage: _.cloneDeep(this.groupApp.iconImage),
            };
            this.mo_base = _.cloneDeep(
              _.find(state.groupApp.languages, {
                mo_base: true,
              })
            );

            if (!this.mo_base) {
              this.mo_base = "en";
            }
            this.groupStoreData.groupApp.mo_base = _.find(
              _.cloneDeep(this.groupApp.languages),
              { mo_base: true }
            );
          }

          if (state && state.webp && !_.isEqual(this.webp, state.webp)) {
            this.webp = _.cloneDeep(state.webp);
            this.groupStoreData.webp = _.cloneDeep(this.webp);
          }

          if (
            state &&
            state.selectedLangugage &&
            !_.isEqual(this.selectedLanguage, state.selectedLangugage)
          ) {
            this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("cart")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            state.products &&
            !_.isEqual(this.cartProducts, state.products)
          ) {
            this.cartProducts = _.cloneDeep(state.products);
            this.cartProductIds = [];
            _.each(this.cartProducts, (product) => {
              this.thereIsARecommendedInCart = !!_.find(
                this.recommended_products_filtered,
                (prd) => {
                  if (
                    prd &&
                    _.find(this.cartProducts, { product_id: prd.product_id })
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                }
              );
              if (
                !product.quantity ||
                product.quantity === 0 ||
                product.quantity === 1 ||
                (product.enable_quantity_step && product.quantity_step)
              ) {
                this.cartProductIds.push(_.cloneDeep(product.product_id));
              } else {
                for (let i = 0; i < product.quantity; i++) {
                  this.cartProductIds.push(_.cloneDeep(product.product_id));
                }
              }
            });
          }
        })
    );
  }

  productsTrackFn(index, item) {
    return item.product_id;
  }

  checkOriginalCategoryIsPromo(product, catalog) {
    const original_category = _.find(catalog, {
      category_id: product.category_id,
    });
    if (_.has(product, ["originalCategoryIsPromo"])) {
      delete product.originalCategoryIsPromo;
    }
    if (original_category && original_category.promo && !product.promo) {
      product.originalCategoryIsPromo = true;
    }
  }

  filterRecommendedProducts(
    product,
    cat,
    catalog,
    groupApp,
    store_info,
    customerPricelistProducts
  ) {
    this.checkOriginalCategoryIsPromo(product, catalog);

    if (
      groupApp &&
      groupApp.pointsSystem &&
      store_info &&
      store_info.design_settings &&
      store_info.design_settings.showPointRulesOnProductItems
    ) {
      if (store_info.overridePromoItemsForPointsSystem) {
        this.calculateProductShowOptionInDescriptionObject(product, false);
      } else {
        this.calculateShowPointRulesOnProductItem(product);
        this.calculateProductShowOptionInDescriptionObject(product, true);
      }
    } else {
      this.calculateProductShowOptionInDescriptionObject(product, false);
    }

    if (
      product.image &&
      product.image.true &&
      product.image.true["4x3"] &&
      product.image.true["4x3"].path
    ) {
      product.thumb = true;
    }

    this.checkProductsCategoriesAvailabilityActions(product, cat, catalog);

    if (
      !(
        cat.availableTotal &&
        !product.availableTotal &&
        product.available &&
        product.avpc
      )
    ) {
      cat.avpc = null;
    }
    if (
      (!product.available && !product.availableTotal) ||
      !cat.availableTotal
    ) {
      product.lineThrough = true;
    }
    if (
      !product.availableTotal ||
      !cat.availableTotal ||
      product.initial_price
    ) {
      product.priceWidthUnavailable = true;
    }
    if (
      product.availableTotal &&
      cat.availableTotal &&
      !product.initial_price
    ) {
      product.priceWidthAvailable = true;
    }

    //all the products that has avalabilityForPreorder

    //this is for the preorder only category for user to have the ability to order this products only with preorder
    if (
      product &&
      !product.availableTotal &&
      store_info.preorderActive &&
      product.tags &&
      product.tags.availableForPreorder &&
      cat &&
      cat.promoCategory &&
      cat.availableTotal &&
      cat.activeTotal &&
      cat.tags &&
      cat.tags.availableForPreorder
    ) {
      product.availableTotal = true;
      product.avpc = null;
      product.hideAvailableForPreorderTag = false;
    } else if (
      product &&
      product.tags &&
      product.tags.availableForPreorder &&
      product.availableTotal &&
      cat &&
      (!cat.promoCategory ||
        (cat.promoCategory && (!cat.tags || !cat.tags.availableForPreorder)))
    ) {
      product.hideAvailableForPreorderTag = true;
    } else if (
      product &&
      product.tags &&
      product.tags.availableForPreorder &&
      !product.availableTotal &&
      !cat.promoCategory
    ) {
      let foundProductInPromoCategory;
      _.each(catalog, (categ) => {
        if (
          categ &&
          categ.promoCategory &&
          categ.availableTotal &&
          categ.activeTotal &&
          categ.tags &&
          categ.tags.availableForPreorder
        ) {
          _.each(categ.products, (categ_product) => {
            if (categ_product.product_id === product.product_id) {
              foundProductInPromoCategory = true;
            }
          });
        }
      });

      if (!foundProductInPromoCategory) {
        product.hideAvailableForPreorderTag = true;
      }
    }

    if (
      product.product_categories_active_check &&
      product.product_categories_availability_check &&
      (!cat.promoCategory || !cat.tags || !cat.tags.availableForPreorder) &&
      //TO-DO check the above condition
      // (!cat.promoCategory ||
      //   (cat.promoCategory &&
      //     !cat.check_promo_category_products_availability)) &&
      //
      !product.availableTotal &&
      !product.activePeriod &&
      !product.availablePeriod &&
      product.availableInitializationActive &&
      product.availableInitializationTime &&
      (!product.preorder_availability_stock_enabled ||
        product.preorder_availability_stock > 0) &&
      store_info &&
      store_info.preorderActive &&
      (((!store_info.preorderDaysStart || store_info.preorderDaysStart === 0) &&
        store_info.preorderDaysAhead > 1) ||
        store_info.preorderDaysStart > 0) &&
      (!product.tags || !product.tags.availableForPreorder)
    ) {
      if (!product.tags) {
        product.tags = {};
      }
      product.tags.initializationActivePreorder = true;
      product.availableTotal = true;
    }

    if (customerPricelistProducts && customerPricelistProducts.length) {
      _.each(customerPricelistProducts, (customerProduct) => {
        if (customerProduct.product_id === product.product_id) {
          if (customerProduct.price) {
            let price = _.cloneDeep(
              parseFloat(customerProduct.price.toString()).toFixed(
                store_info && store_info.decimalPlacesPrices
                  ? store_info.decimalPlacesPrices
                  : 2
              )
            );
            product.price = price.toString() + "€";
          }

          if (customerProduct.price_takeaway) {
            let price_takeaway = _.cloneDeep(
              parseFloat(customerProduct.price_takeaway.toString()).toFixed(
                store_info && store_info.decimalPlacesPrices
                  ? store_info.decimalPlacesPrices
                  : 2
              )
            );
            price_takeaway = price_takeaway.toString() + "€";

            product.price_takeaway = price_takeaway;
          }
        }
      });
    }

    return (
      product.activeTotal &&
      !product.hidden &&
      !product.showOnlyInQRCatalog &&
      product.product_categories_active_check
    );
  }

  calculateProductPoints(price, order_rule) {
    if (
      order_rule &&
      order_rule.points_ratio &&
      order_rule.amount_ratio &&
      price
    ) {
      price = parseFloat(
        parseFloat(_.cloneDeep(price).toString().replace("€", "")).toFixed(2)
      );
      return parseInt(
        parseFloat(
          ((price * order_rule.points_ratio) / order_rule.amount_ratio)
            .toFixed(2)
            .toString()
        )
          .toFixed()
          .toString()
      );
    } else {
      return null;
    }
  }

  calculateShowPointRulesOnProductItem(product) {
    if (product && !product.promo) {
      if (product.price) {
        product.points_to_earn = this.calculateProductPoints(
          _.cloneDeep(product.price),
          _.find(this.groupApp.points.rules, { type: "order" })
        );
      }
      if (product.price_takeaway) {
        product.points_to_earn_takeaway = this.calculateProductPoints(
          _.cloneDeep(product.price_takeaway),
          _.find(this.groupApp.points.rules, { type: "order" })
        );
      }
      if (product.price_dinein) {
        product.points_to_earn_dinein = this.calculateProductPoints(
          _.cloneDeep(product.price_dinein),
          _.find(this.groupApp.points.rules, { type: "order" })
        );
      }
      if (product.price_alt_dinein) {
        product.points_to_earn_alt_dinein = this.calculateProductPoints(
          _.cloneDeep(product.price_alt_dinein),
          _.find(this.groupApp.points.rules, { type: "order" })
        );
      }
    }
  }

  calculateProductShowOptionInDescriptionObject(product, withPoints) {
    if (
      product.showOptionInDescriptionObject &&
      product.showOptionInDescriptionObject.choices &&
      product.showOptionInDescriptionObject.choices.length
    ) {
      _.each(product.showOptionInDescriptionObject.choices, (ch, indx) => {
        product.showOptionInDescriptionObject.choices[indx] = {
          title: _.cloneDeep(ch.title),
          price: _.cloneDeep(ch.price),
          points:
            withPoints && !product.promo && !product.gift
              ? this.calculateProductPoints(
                  _.cloneDeep(ch.price),
                  _.find(this.groupApp.points.rules, { type: "order" })
                )
              : null,
        };
      });
    }
    if (
      product.showOptionInDescriptionObject_takeaway &&
      product.showOptionInDescriptionObject_takeaway.choices &&
      product.showOptionInDescriptionObject_takeaway.choices.length
    ) {
      _.each(
        product.showOptionInDescriptionObject_takeaway.choices,
        (ch, indx) => {
          product.showOptionInDescriptionObject_takeaway.choices[indx] = {
            title: _.cloneDeep(ch.title),
            price: _.cloneDeep(ch.price),
            points:
              withPoints && !product.promo && !product.gift
                ? this.calculateProductPoints(
                    _.cloneDeep(ch.price),
                    _.find(this.groupApp.points.rules, { type: "order" })
                  )
                : null,
          };
        }
      );
    }
    if (
      product.showOptionInDescriptionObject_dinein &&
      product.showOptionInDescriptionObject_dinein.choices &&
      product.showOptionInDescriptionObject_dinein.choices.length
    ) {
      _.each(
        product.showOptionInDescriptionObject_dinein.choices,
        (ch, indx) => {
          product.showOptionInDescriptionObject_dinein.choices[indx] = {
            title: _.cloneDeep(ch.title),
            price: _.cloneDeep(ch.price),
            points:
              withPoints && !product.promo && !product.gift
                ? this.calculateProductPoints(
                    _.cloneDeep(ch.price),
                    _.find(this.groupApp.points.rules, { type: "order" })
                  )
                : null,
          };
        }
      );
    }
    if (
      product.showOptionInDescriptionObject_alt_dinein &&
      product.showOptionInDescriptionObject_alt_dinein.choices &&
      product.showOptionInDescriptionObject_alt_dinein.choices.length
    ) {
      _.each(
        product.showOptionInDescriptionObject_alt_dinein.choices,
        (ch, indx) => {
          product.showOptionInDescriptionObject_alt_dinein.choices[indx] = {
            title: _.cloneDeep(ch.title),
            price: _.cloneDeep(ch.price),
            points:
              withPoints && !product.promo && !product.gift
                ? this.calculateProductPoints(
                    _.cloneDeep(ch.price),
                    _.find(this.groupApp.points.rules, { type: "order" })
                  )
                : null,
          };
        }
      );
    }
  }

  checkProductsCategoriesAvailabilityActions(product, cat, catalog) {
    if (product && product.options) {
      _.each(product.options, (opt) => {
        if (opt && opt.choices) {
          _.each(opt.choices, (ch) => {
            if (ch.bundle === true && ch.bundled_product) {
              this.checkProductsCategoriesAvailabilityActions(
                ch.bundled_product,
                cat,
                catalog
              );
            }
          });
        }
      });
    }

    product = this.checkProductCategoriesAvailabilityActions(
      product,
      cat,
      catalog
    );
  }

  checkProductCategoriesAvailabilityActions(product, cat, catalog) {
    //check for categories copy products availability and active
    if (product.categories && product.categories.length) {
      const original_category = _.find(catalog, {
        category_id: product.category_id,
      });

      if (
        original_category &&
        (!original_category.availableTotal || !original_category.activeTotal)
      ) {
        if (
          cat.promoCategory &&
          cat.check_promo_category_products_availability
        ) {
          const productCategories = _.filter(product.categories, (ct) => {
            return ct.category_id !== cat.category_id;
          });
          let productCategoryActiveTotal, productCategoryAvailableTotal;
          _.each(productCategories, (product_category) => {
            const pr_cat = _.find(catalog, {
              category_id: product_category.category_id,
            });

            if (pr_cat.availableTotal) {
              productCategoryAvailableTotal = true;
            }

            if (pr_cat.activeTotal) {
              productCategoryActiveTotal = true;
            }
          });
          if (productCategoryActiveTotal) {
            product.product_categories_active_check = true;
          }

          if (productCategoryAvailableTotal) {
            product.product_categories_availability_check = true;
          }
        } else if (
          cat.promoCategory &&
          !cat.check_promo_category_products_availability
        ) {
          let productCategoryActiveTotal, productCategoryAvailableTotal;

          _.each(product.categories, (product_category) => {
            const pr_cat = _.find(catalog, {
              category_id: product_category.category_id,
            });

            if (pr_cat.availableTotal) {
              productCategoryAvailableTotal = true;
            }

            if (pr_cat.activeTotal) {
              productCategoryActiveTotal = true;
            }
          });
          if (productCategoryActiveTotal) {
            product.product_categories_active_check = true;
          }

          if (productCategoryAvailableTotal) {
            product.product_categories_availability_check = true;
          }
        } else if (!cat.promoCategory) {
          let productCategoryActiveTotal, productCategoryAvailableTotal;
          if (cat.availableTotal) {
            productCategoryAvailableTotal = true;
          }

          if (cat.activeTotal) {
            productCategoryActiveTotal = true;
          }

          if (!cat.availableTotal || !cat.activeTotal) {
            _.each(product.categories, (product_category) => {
              const pr_cat = _.find(catalog, {
                category_id: product_category.category_id,
              });

              if (pr_cat.availableTotal) {
                productCategoryAvailableTotal = true;
              }

              if (pr_cat.activeTotal) {
                productCategoryActiveTotal = true;
              }
            });
          }

          if (productCategoryActiveTotal) {
            product.product_categories_active_check = true;
          }

          if (productCategoryAvailableTotal) {
            product.product_categories_availability_check = true;
          }
        }
      } else {
        product.product_categories_availability_check = _.cloneDeep(
          original_category.availableTotal
        );
        product.product_categories_active_check = _.cloneDeep(
          original_category.activeTotal
        );
      }
    } else {
      product.product_categories_availability_check = _.cloneDeep(
        cat.availableTotal
      );
      product.product_categories_active_check = _.cloneDeep(cat.activeTotal);
    }
  }

  async calculateRecommendedProductsByCategoryId() {
    this.recommended_products_by_category_id = {};

    await this.calculationsByCategoryId();

    setTimeout(() => {
      this.calculateProductsColSize();
      this.checkRecommendedProductsNumberOfCategories();
    }, 200);
  }

  async calculationsByCategoryId() {
    return new Promise((resolve, rej) => {
      this.store
        .select("groupApp")
        .pipe(first())
        .subscribe((groupState) => {
          this.store
            .select(selectors.getStoreInfo)
            .pipe(first())
            .subscribe((storeInfo) => {
              this.store
                .select("catalog")
                .pipe(first())
                .subscribe(async (state) => {
                  if (state) {
                    this.loading = true;
                    let allCategories;
                    if (state && state.catalogUnfiltered) {
                      allCategories = _.cloneDeep(state.catalogUnfiltered);
                    } else {
                      allCategories =
                        await this.productDetailsOperations.fetchCatalogCdn();
                    }

                    _.each(
                      this.recommended_products_filtered,
                      (recommended_product, index) => {
                        const productsCategory = _.find(allCategories, {
                          category_id: recommended_product.category_id,
                        });
                        if (productsCategory) {
                          this.calculateRecommendedProductsByCategoryIdActions(
                            productsCategory,
                            recommended_product,
                            _.cloneDeep(allCategories),
                            _.cloneDeep(groupState.groupApp),
                            _.cloneDeep(storeInfo),
                            _.cloneDeep(state.customerPricelist)
                          );
                        }
                      }
                    );

                    setTimeout(() => {
                      this.loading = false;
                    }, 300);

                    resolve(null);
                  } else {
                    resolve(null);
                  }
                });
            })
            .unsubscribe();
        })
        .unsubscribe();
    });
  }

  calculateRecommendedProductsByCategoryIdActions(
    productsCategory,
    recProduct,
    catalog,
    groupApp,
    store_info,
    customerPricelists
  ) {
    if (productsCategory && recProduct) {
      if (
        !this.recommended_products_by_category_id[productsCategory.category_id]
      ) {
        this.recommended_products_by_category_id[productsCategory.category_id] =
          [];
      }

      if (
        !_.find(
          this.recommended_products_by_category_id[
            productsCategory.category_id
          ],
          { product_id: recProduct.product_id }
        )
      ) {
        let prd = _.cloneDeep(recProduct);
        prd.category = _.cloneDeep(productsCategory);

        const pushProduct = this.filterRecommendedProducts(
          prd,
          prd.category,
          catalog,
          groupApp,
          store_info,
          customerPricelists
        );
        if (pushProduct) {
          this.recommended_products_by_category_id[
            productsCategory.category_id
          ].push(_.cloneDeep(prd));
        }
      }
    }
  }

  checkRecommendedProductsNumberOfCategories() {
    this.numberOfRecommendedCategories = 0;
    for (const property in this.recommended_products_by_category_id) {
      this.numberOfRecommendedCategories++;

      if (
        this.recommended_products_by_category_id[property] &&
        this.recommended_products_by_category_id[property][0] &&
        this.recommended_products_by_category_id[property][0].category &&
        !_.isEmpty(
          this.recommended_products_by_category_id[property][0].category
        )
      ) {
        this.uniqueCategory =
          this.recommended_products_by_category_id[property][0].category;
      }
    }
  }

  trackProducts(index, item) {
    return item.product_id;
  }

  calculateProductsColSize() {
    if (this.windowDimensions && this.windowDimensions.width) {
      if (this.windowDimensions.width >= 850) {
        //browser size calc
        if (this.windowDimensions.width >= 800) {
          this.productsColSizeBrowser = 4;
          this.gridProductsMaxWidth = "760px";
        } else {
          this.productsColSizeBrowser = 6;
          this.gridProductsMaxWidth = "500px";
        }
      } else {
        //mobile size calc
        if (this.windowDimensions.width < 356) {
          this.productsColSizeMobile = 12;
          this.gridProductsMaxWidth = "100%";
        } else if (
          this.windowDimensions.width >= 356 &&
          this.windowDimensions.width < 550
        ) {
          this.productsColSizeMobile = 6;
          this.gridProductsMaxWidth = "350px";
        } else if (
          this.windowDimensions.width >= 550 &&
          this.windowDimensions.width < 700
        ) {
          this.productsColSizeMobile = 4;
          this.gridProductsMaxWidth = "500px";
        } else if (
          this.windowDimensions.width >= 700 &&
          this.windowDimensions.width <= 850
        ) {
          this.productsColSizeMobile = 3;
          this.gridProductsMaxWidth = "680px";
        } else {
          this.productsColSizeMobile = 6;
          this.gridProductsMaxWidth = "350px";
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
      this.subscriptions = [];
    }

    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
  }
}
