import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";

@Injectable({
  providedIn: "root",
})
export class PointsProductScheduleService {
  constructor(private translateService: TranslateService) {}

  formatStoreSchedule(schedule) {
    var weekDays = ["0", "1", "2", "3", "4", "5", "6"];

    var singleScheduleArray = [];

    _.each(schedule, (scheduleDay, key, list) => {
      if (scheduleDay.active && !scheduleDay.all_day) {
        var commentSchedule = [];

        scheduleDay.time_periods.forEach((tp, i) => {
          commentSchedule.push({
            start: this.toHHMMSS(tp.start),
            stop: this.toHHMMSS(tp.stop),
            weight: tp.weight || 1,
          });
        });

        singleScheduleArray.push({
          day: weekDays[key],
          schedule: commentSchedule,
        });
      } else if (scheduleDay.active && scheduleDay.all_day) {
        singleScheduleArray.push({
          day: weekDays[key],
          schedule: "24hour",
          weight: scheduleDay.weight || 1,
        });
      } else {
        singleScheduleArray.push({
          day: weekDays[key],
          schedule: "disabled",
        });
      }
    });

    var sunday = _.head(singleScheduleArray);
    singleScheduleArray = _.takeRight(singleScheduleArray, 6);
    singleScheduleArray.push(sunday);
    return singleScheduleArray;
  }

  toHHMMSS(val) {
    console.log("val", val);
    if (val) {
      let sec_num = parseInt(val, 10); // don't forget the second param
      let hours = Math.floor(sec_num / 3600);
      let minutes = Math.floor((sec_num - hours * 3600) / 60);
      let newhours = hours.toString();
      let newmins = minutes.toString();
      if (hours < 10) {
        newhours = "0" + hours;
      }
      if (minutes < 10) {
        newmins = "0" + minutes;
      }

      return newhours + ":" + newmins;
    } else {
      return "00:00";
    }
  }
}
