<span
  *ngIf="
    option &&
    option.choices &&
    (option.preNameId !== 'delivery_tip' || !option.deliveryTipButtonsDesign)
  "
>
  <ion-item
    lines="none"
    *ngFor="
      let choice of option.choices;
      let index = index;
      trackBy: trackTimestamp
    "
  >
    <ion-label
      *ngIf="
        choice.title &&
        (!choice.translations ||
          (choice.translations && !choice.translations.title) ||
          (choice.translations &&
            choice.translations.title &&
            !choice.translations.title[selectedLang]))
      "
      class="ion-text-wrap"
    >
      {{ choice.title
      }}{{
        choice.price ? " - " + choice.price.toFixed(2) + "€" : ""
      }}</ion-label
    >

    <ion-label
      *ngIf="
        choice.translations &&
        choice.translations.title &&
        choice.translations.title[selectedLang]
      "
      class="ion-text-wrap"
    >
      {{ choice.translations.title[selectedLang]
      }}{{
        choice.price ? " - " + choice.price.toFixed(2) + "€" : ""
      }}</ion-label
    >

    <span slot="end" style="margin: 0px">
      <app-change-quantity
        style="margin: 0px"
        [buttonsSize]="'small'"
        [quantity]="choice.qty"
        (changingQuantity)="changeChoiceQty($event, choice, index)"
        [disablePlusButton]="finalPriceLoading"
        [disableRemoveButton]="finalPriceLoading"
      ></app-change-quantity>
    </span>

    <ion-text
      style="position: absolute; top: 0; right: 3px; font-size: small"
      *ngIf="
        choice.qty > 1 && choice.totalPrice && choice.totalPrice !== '0.00'
      "
    >
      {{ choice.totalPrice + "€" }}
    </ion-text>
  </ion-item>
</span>

<span
  *ngIf="
    option &&
    option.choices &&
    option.preNameId === 'delivery_tip' &&
    option.deliveryTipButtonsDesign
  "
>
  <ion-item
    lines="none"
    *ngFor="
      let choice of option.choices;
      let index = index;
      trackBy: trackTimestamp
    "
  >
    <ion-grid class="ion-no-padding ion-no-margin">
      <ion-row style="width: 100%">
        <ion-col class="ion-no-padding ion-no-margin ion-text-center" size="12">
          <ion-label
            *ngIf="
              choice.title &&
              (!choice.translations ||
                (choice.translations && !choice.translations.title) ||
                (choice.translations &&
                  choice.translations.title &&
                  !choice.translations.title[selectedLang]))
            "
            class="ion-text-wrap"
          >
            {{ choice.title }}</ion-label
          >

          <ion-label
            *ngIf="
              choice.translations &&
              choice.translations.title &&
              choice.translations.title[selectedLang]
            "
            class="ion-text-wrap"
          >
            {{ choice.translations.title[selectedLang] }}</ion-label
          >
          <!-- <ion-text
            style="position: absolute; top: 0; right: 3px; font-size: small"
            *ngIf="
              choice.qty > 1 &&
              choice.totalPrice &&
              choice.totalPrice !== '0.00'
            "
          >
            {{ choice.totalPrice + "€" }}
          </ion-text> -->
        </ion-col>

        <ion-col class="ion-no-padding ion-no-margin ion-text-center" size="12">
          <ion-chip
            [color]="choice.qty === 1 ? 'selected' : 'primary'"
            (click)="
              changeChoiceQtyFromButton(choice.qty === 1 ? 0 : 1, choice, index)
            "
            ><ion-text style="width: 40px; text-align: center"
              >0,5€ {{ choice.qty !== 1 ? "+" : "x" }}</ion-text
            ></ion-chip
          >
          <ion-chip
            [color]="choice.qty === 2 ? 'selected' : 'primary'"
            (click)="
              changeChoiceQtyFromButton(choice.qty === 2 ? 0 : 2, choice, index)
            "
            ><ion-text style="width: 40px; text-align: center"
              >1€ {{ choice.qty !== 2 ? "+" : "x" }}</ion-text
            ></ion-chip
          >
          <ion-chip
            [color]="choice.qty === 4 ? 'selected' : 'primary'"
            (click)="
              changeChoiceQtyFromButton(choice.qty === 4 ? 0 : 4, choice, index)
            "
            ><ion-text style="width: 40px; text-align: center"
              >2€ {{ choice.qty !== 4 ? "+" : "x" }}</ion-text
            ></ion-chip
          >
          <ion-chip
            [color]="choice.qty === 10 ? 'selected' : 'primary'"
            (click)="
              changeChoiceQtyFromButton(
                choice.qty === 10 ? 0 : 10,
                choice,
                index
              )
            "
            ><ion-text style="width: 40px; text-align: center"
              >5€ {{ choice.qty !== 10 ? "+" : "x" }}</ion-text
            ></ion-chip
          >
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-item>
</span>
