<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">{{
      "select-fixed-points-address-modal.fixed-points-address-selection-modal.title"
        | translate
    }}</ion-title>
    <ion-buttons slot="end">
      <ion-button
        *ngIf="fixedPointsAddresses && fixedPointsAddresses.length > 1"
        (click)="calculateLocations()"
        slot="end"
        size="small"
        fill="clear"
        color="primary"
      >
        <ion-icon slot="icon-only" src="assets/ionicons/location.svg">
        </ion-icon>
      </ion-button>
      <ion-button color="danger" fill="clear" (click)="ondismiss()">
        <ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-backdrop
  *ngIf="showGpsLoadingAnimation"
  style="z-index: 2147483647; width: 100%; height: 100%"
  [tappable]="false"
>
</ion-backdrop>
<ion-grid
  *ngIf="showGpsLoadingAnimation"
  style="
    position: absolute;
    top: 25%;
    z-index: 2147483647;
    width: 100%;
    height: 100%;
  "
  class="ion-text-center"
>
  <ion-grid style="max-width: 180px">
    <ng-lottie
      (animationCreated)="animationCreated($event)"
      [options]="options"
    ></ng-lottie>
  </ion-grid>
</ion-grid>

<ion-content>
  <ion-list
    class="ion-no-padding"
    *ngIf="fixedPointsAddresses && fixedPointsAddresses.length"
  >
    <div
      *ngFor="
        let fixedAddressPoint of fixedPointsAddresses | orderStoresByDistance;
        trackBy: trackFixedPoints
      "
    >
      <ion-item
        [class]="'fixedPointsItem'"
        lines="full"
        mode="ios"
        button
        detail="false"
        (click)="selectFixedAdressPoint(fixedAddressPoint.fixed_address_id)"
      >
        <ion-label class="ion-text-wrap">
          <strong>
            {{ fixedAddressPoint.name }}
          </strong>
          <p *ngIf="fixedAddressPoint && fixedAddressPoint.address">
            {{ fixedAddressPoint.address.formatted_address }}
          </p>
        </ion-label>
        <ion-button fill="clear" slot="end">
          <ion-icon
            src="assets/ionicons/chevron-forward-outline.svg"
            slot="icon-only"
          >
          </ion-icon>
        </ion-button>

        <p
          style="
            margin: 0;
            position: absolute;
            bottom: 5px;
            right: 10px;
            font-size: small;
            z-index: 100;
          "
          *ngIf="
            fixedAddressPoint.estimatedDistanceFromClientGps &&
            !gpsDistanceLoading &&
            fixedPointsAddresses.length > 1
          "
        >
          <ion-text color="primary">
            {{ fixedAddressPoint.estimatedDistanceFromClientGps }}km
          </ion-text>
        </p>
      </ion-item>
    </div>
  </ion-list>
</ion-content>
