import { Component, OnDestroy, OnInit } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import * as _ from "lodash";
import * as fromApp from "../../store/app.reducer";
import * as selectors from "../../store/app.selectors";
import { Subscription, distinctUntilChanged } from "rxjs";
import { Store } from "@ngrx/store";

@Component({
  selector: "app-vat-levels-details-modal",
  templateUrl: "./vat-levels-details-modal.component.html",
  styleUrls: ["./vat-levels-details-modal.component.scss"],
})
export class VatLevelsDetailsModalComponent implements OnInit, OnDestroy {
  public cartPrices;
  public storeInfo;
  public vatLevelsById;
  public vatLevelsWithTotals = [];

  private subscription: Subscription;
  private cartPricesSubscription: Subscription;
  private unsubscribeBackEvent: Subscription;

  constructor(
    private modalContoroller: ModalController,
    private platform: Platform,
    private store: Store<fromApp.AppState>
  ) {}

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );
    this.subscription = this.store
      .select(selectors.getStoreInfo)
      .pipe(distinctUntilChanged())
      .subscribe((storeInfo) => {
        if (storeInfo && !_.isEqual(this.storeInfo, storeInfo)) {
          this.storeInfo = _.cloneDeep(storeInfo);

          if (this.storeInfo && this.storeInfo.catalogVatLevels) {
            this.vatLevelsById = {};
            _.each(this.storeInfo.catalogVatLevels, (vat_level) => {
              this.vatLevelsById[vat_level.vat_level_id] = vat_level;
            });
          }
          if (this.storeInfo && this.cartPrices) {
            this.calculateVatLevelWithTotals();
          }
        }
      });

    this.cartPricesSubscription = this.store
      .select("cartPrices")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.cartPrices &&
          !_.isEqual(this.cartPrices, state.cartPrices)
        ) {
          this.cartPrices = _.cloneDeep(state.cartPrices);

          if (this.storeInfo && this.cartPrices) {
            this.calculateVatLevelWithTotals();
          }
        }
      });
  }

  calculateVatLevelWithTotals() {
    for (let key in this.cartPrices.vatLevelsTotals) {
      if (
        key &&
        this.vatLevelsById[key] &&
        !_.find(this.vatLevelsWithTotals, { vat_level_id: key }) &&
        this.cartPrices.vatLevelsTotals[key]
      ) {
        this.vatLevelsWithTotals.push({
          vat_level_id: key,
          percent: this.vatLevelsById[key].percent,
          total: this.cartPrices.vatLevelsTotals[key].toFixed(2),
        });
      }
    }

    console.log("daklsjdklas", this.vatLevelsById, this.vatLevelsWithTotals);
  }

  closeModal() {
    this.modalContoroller.dismiss();
  }

  ngOnDestroy(): void {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }

    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.cartPricesSubscription) {
      this.cartPricesSubscription.unsubscribe();
    }
  }
}
