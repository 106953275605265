import { Action } from "@ngrx/store";

export const SET_FIREBASE_RECAPTCHA_TOKEN =
  "[FIREBASE RECAPTCHA] Set Firebase Recaptcha Token";
export const CLEAR_FIREBASE_RECAPTCHA_TOKEN =
  "[FIREBASE RECAPTCHA] Clear Firebase Recaptcha Token";

export class SetFirebaseRecaptchaToken implements Action {
  readonly type = SET_FIREBASE_RECAPTCHA_TOKEN;
  constructor(public payload: string) {}
}

export class ClearFirebaseRecaptchaToken implements Action {
  readonly type = CLEAR_FIREBASE_RECAPTCHA_TOKEN;
  constructor() {}
}

export type FirebaseRecaptchaActions =
  | SetFirebaseRecaptchaToken
  | ClearFirebaseRecaptchaToken;
