<div style="width: 100%">
  <ngx-file-drop [hidden]="loading" [dropZoneLabel]="'options.drop_files'| translate" dropZoneClassName="dropZoneClass" (onFileDrop)="dropped($event)"
    (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
      <!-- <ion-button (click)="openFileSelector()" fill="clear" style="position: absolute; bottom:0; left:-4px;">
        <ion-icon name="cloud-upload"></ion-icon>
      </ion-button> -->
      <div
        (click)="openFileSelector()"
        style="
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          text-align: center;
        "
      >
        {{
          "options.drop_files"
            | translate
        }}
      </div>
    </ng-template>
  </ngx-file-drop>

  <ion-progress-bar *ngIf="
    uploadProgressValue &&
    uploadProgressValue > 0 &&
    uploadProgressValue < 1" 
    type="determinate" [value]="uploadProgressValue">
  </ion-progress-bar>
</div>
<app-photo-cropper
  [image]="imageFile"
>
</app-photo-cropper>