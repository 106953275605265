<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="primary" (click)="modalCtrl.dismiss()">
        <ion-icon name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-label
      class="ion-text-wrap ion-text-center"
      style="font-size: medium; font-weight: bold"
      >{{"reservation-app.myDate" |translate}}</ion-label
    >
  </ion-toolbar>
</ion-header>

<ion-content class="item-list">
  <ion-grid *ngIf="storeAccount">
    <ion-row>
      <ion-col [size]="infoSize">
        <ion-item lines="none">
          <ion-label class="ion-text-wrap">
            {{"reservation-app.info" |translate}}
            <ion-text style="font-weight: bold" *ngIf="item && item.order_id"
              >#{{ item.order_id }}</ion-text
            >
          </ion-label>
        </ion-item>
        <ion-item (click)="openLink()" style="cursor: pointer">
          <ion-icon name="location-sharp"></ion-icon>
          <ion-label class="ion-text-wrap" style="margin-left: 10px">
            <ion-text style="font-weight: bold"
              >{{ groupApp.app_name }}</ion-text
            >
            <ion-text *ngIf="storeAccount.address">
              | {{ storeAccount.address }}</ion-text
            >
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-icon name="calendar"></ion-icon>
          <ion-label
            style="margin-left: 10px; white-space: normal; font-size: 15px"
          >
            <span style="font-weight: bold">{{ item.date | dateDetails }}</span>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-icon name="person-circle"></ion-icon>
          <ion-label
            style="margin-left: 10px; font-size: 15px"
            class="ion-text-wrap"
            ><span style="font-weight: bold">{{item.product_name}}</span>
            {{"reservation-app.with"|translate}}
            {{item.table_name.el}}</ion-label
          >
        </ion-item>
        <ion-item *ngIf="item.grand_total">
          <ion-icon name="wallet"></ion-icon>
          <ion-label
            style="margin-left: 10px; font-size: 15px"
            class="ion-text-wrap"
            ><span style="font-weight: bold"
              >{{"reservation-app.total" |translate}}</span
            >
            <span style="font-weight: bold"> {{ item.grand_total }}€</span>
          </ion-label>
        </ion-item>
      </ion-col>
      <ion-col [size]="columnSize">
        <ion-item lines="none">
          <ion-label
            class="ion-text-wrap ion-text-center"
            style="
              font-size: 22px;
              font-weight: bold;
              white-space: normal;
              margin: 0;
            "
          >
            QR Code
            <p>{{"reservation-app.pleaseScanQrcode" |translate}}</p>
          </ion-label>
          <!-- <ion-button fill="clear" slot="end" (click)="scanningInstractions()"
            ><ion-icon slot="icon-only" name="information-circle"></ion-icon
          ></ion-button> -->
        </ion-item>

        <div
          *ngIf="item && item.kiosk_one_time_password"
          class="qrcode ion-text-center"
        >
          <qrcode
            [qrdata]="item.kiosk_one_time_password"
            [width]="170"
            [errorCorrectionLevel]="'H'"
            [margin]="1"
          ></qrcode>
        </div>

        <div
          *ngIf="item && item.kiosk_one_time_password"
          style="text-align: center"
        >
          {{ "kiosk-page.or-enter-the-code" | translate }}:
          <span style="font-size: large">
            <strong>{{ item.kiosk_one_time_password }}</strong>
          </span>
        </div>
      </ion-col>
    </ion-row>

    <ion-button
      expand="block"
      shape="round"
      class="absolute-bottom-button"
      (click)="editOrCancelDate()"
    >
      {{"reservation-app.editDate" |translate}}
    </ion-button>
  </ion-grid>

  <div style="margin-top: 60px"></div>
</ion-content>
