<ion-item *ngIf="category" class="categoryItem">
  <ion-button
    style="position: absolute; top: 0px; right: 30px"
    size="small"
    fill="clear"
    (click)="prevSlide()"
  >
    <ion-icon slot="icon-only" src="assets/ionicons/chevron-back-outline.svg">
    </ion-icon>
  </ion-button>
  <ion-button
    style="position: absolute; top: 0px; right: 2px"
    size="small"
    (click)="nextSlide()"
    fill="clear"
  >
    <ion-icon
      slot="icon-only"
      src="assets/ionicons/chevron-forward-outline.svg"
    >
    </ion-icon>
  </ion-button>

  <ion-row style="width: 100%">
    <ion-col size="12" class="noPaddingCols">
      <strong style="font-size: larger"
        ><ion-text color="primary">{{ category.name }}</ion-text>
      </strong>
    </ion-col>

    <ion-col
      size="12"
      class="noPaddingCols"
      *ngIf="!category.availableTotal || category.avpc"
    >
      <app-category-item-tags
        [categoryItem]="category"
        [groupStoreData]="groupStoreData"
      ></app-category-item-tags>
      <!-- {{ "catalog.categories.category-item.unavailable-category" | translate }} -->
    </ion-col>

    <ion-col size="12" class="DescriptionPaddings" *ngIf="category.description">
      <app-description [description]="category.description"></app-description>
    </ion-col>
    <ion-col
      class="noPaddingCols"
      size="12"
      *ngIf="category.promotions && category.promotions.length > 0"
    >
      <div
        *ngFor="let promo of category.promotions"
        class="categoriesWarningColor"
      >
        <span style="font-weight: bold; font-size: small">{{
          promo.description
        }}</span>
      </div>
    </ion-col>
  </ion-row>
</ion-item>
<div>
  <ion-slides
    #productsSlider
    class="ion-text-center"
    *ngIf="category && category.activeTotal"
    scrollbar
    [options]="sliderOptions"
  >
    <ion-slide
      style="width: 250px; height: 262px"
      *ngFor="let product of category.products; trackBy: productTrack"
    >
      <app-product-item-popular-category
        style="height: 100%; width: 100%"
        [product]="product"
        [category]="category"
        [groupStoreData]="groupStoreData"
        [dineIn]="dineIn"
        [orderPickup]="orderPickup"
        [selectedDineInTable]="selectedDineInTable"
        [webp]="webp"
      >
      </app-product-item-popular-category>
    </ion-slide>
  </ion-slides>
</div>
