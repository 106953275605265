<ion-grid style="width: 100%">
  <ion-row *ngIf="!categories">
    <ion-col class="ion-text-center">
      <ion-spinner></ion-spinner>
    </ion-col>
  </ion-row>

  <ion-row *ngIf="categories" style="width: 100%">
    <ion-col
      [size]="
        storeInfo &&
        storeInfo.design_settings &&
        storeInfo.design_settings.mobileorderHideCartCatalog
          ? '12'
          : '8'
      "
    >
      <ion-grid *ngIf="doNotShowProductsList">
        <ion-row style="width: 100%">
          <ion-col class="ion-text-center"
            ><ion-spinner></ion-spinner>
          </ion-col>
        </ion-row>
      </ion-grid>
      <app-mobile-product-list
        *ngIf="!doNotShowProductsList"
        [sliderButtons]="true"
        [imageBrowser]="true"
        (listScrollFromTopEvent)="scrollFromTopEvent($event)"
        (listScrollFromBottomEvent)="scrollFromBottomEvent($event)"
      ></app-mobile-product-list>
    </ion-col>
    <!-- <ion-col
      style="text-align: center"
      class="ion-padding"
      size="4"
      class="ion-text-wrap"
    >
    </ion-col> -->
    <app-cart-catalog
      *ngIf="
        storeInfo &&
        !(
          storeInfo.design_settings &&
          storeInfo.design_settings.mobileorderHideCartCatalog
        )
      "
      [grid]="'wrapper-grid-md'"
      [transparentCatalogElements]="transparentCatalogElements"
      [catalogInlineInfoMessage]="catalogInlineInfoMessage"
      [motoCardsProgressValues]="motoCardsProgressValues"
      [infoMessagesCardsHide]="infoMessagesCardsHide"
    ></app-cart-catalog>
  </ion-row>
</ion-grid>
