<ion-header *ngIf="!review && reservationForEdit">
  <ion-toolbar>
    <ion-title size="small" mode="md">
      {{ "reservation-app.editDate" | translate }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button color="primary" (click)="modalCtrl.dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar> </ion-header
><ion-content class="item-list">
  <ion-grid>
    <ion-item *ngIf="!reservationForEdit" lines="none">
      <ng-lottie
        (animationCreated)="animationCreated($event)"
        [options]="options"
      ></ng-lottie>
    </ion-item>
    <ion-item *ngIf="reservationForEdit" lines="none">
      <ng-lottie
        (animationCreated)="animationCreated($event)"
        [options]="options2"
        style="margin: auto"
      ></ng-lottie>
    </ion-item>
    <ion-item *ngIf="submit" lines="none">
      <ion-label
        class="ion-text-center ion-text-wrap"
        style="font-weight: bold; font-size: 19px"
      >
        {{ "reservation-app.moreDate" | translate }}
      </ion-label>
    </ion-item>
    <ion-item *ngIf="review" lines="none">
      <ion-label
        class="ion-text-center ion-text-wrap"
        style="font-weight: bold; font-size: 19px"
      >
        {{ "reservation-app.tnxReview" | translate }}
      </ion-label>
    </ion-item>
    <ion-item *ngIf="submit" lines="none">
      <ion-row
        style="width: 100%"
        class="ion-align-items-center ion-align-self-center ion-justify-content-center"
      >
        <ion-col size="6">
          <ion-button
            color="light"
            style="width: 100%"
            size="medium"
            expand="block"
            shape="round"
            (click)="close()"
          >
            {{ "no" | translate }}
          </ion-button>
        </ion-col>
        <ion-col size="6">
          <ion-button
            (click)="navigateToCatalog()"
            color="primary"
            style="width: 100%"
            size="medium"
            expand="block"
            shape="round"
            >{{ "yes" | translate }}</ion-button
          ></ion-col
        >
      </ion-row>
    </ion-item>
    <ion-item *ngIf="reservationForEdit" lines="none">
      <ion-row
        style="width: 100%"
        class="ion-align-items-center ion-align-self-center ion-justify-content-center"
      >
        <ion-col size="12">
          <ion-button
            color="dark"
            style="width: 100%"
            size="medium"
            expand="block"
            shape="round"
            (click)="confirmAndCancelDate()"
            [disabled]="canceling"
          >
            <ion-text *ngIf="!canceling">{{ "reservation-app.cancelDate" | translate }}</ion-text>
            <ion-spinner *ngIf="canceling"></ion-spinner>
          </ion-button>
        </ion-col>
        <ion-col size="12">
          <ion-button
            color="primary"
            style="width: 100%"
            size="medium"
            expand="block"
            shape="round"
            (click)="confirmAndNavigateOnDates()"
            [disabled]="canceling"
          >
            <ion-text *ngIf="!canceling">{{ "reservation-app.cancelRecreate" | translate }}</ion-text>
            <ion-spinner *ngIf="canceling"></ion-spinner>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-item>
  </ion-grid>
</ion-content>
