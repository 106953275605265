import * as CartPricesActions from "./cart-prices.actions";
import * as _ from "lodash";

export interface State {
  cartPrices: any;
}

const initialState: State = {
  cartPrices: null,
};

export function cartPricesReducer(
  state = initialState,
  action: CartPricesActions.CartPricesActions
) {
  switch (action.type) {
    case CartPricesActions.SET_CART_PRICES:
      return {
        ...state,
        cartPrices: action.payload,
      };

    case CartPricesActions.CLEAR_CART_PRICES:
      return {
        ...state,
        cartPrices: null,
      };

    default:
      return state;
  }
}
