import { Injectable } from "@angular/core";
import { AlertController, ModalController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import { SendOrderPayPopupComponent } from "src/app/popups/send-order-pay-popup/send-order-pay-popup.component";
import { SendOrderPopupComponent } from "src/app/popups/send-order-popup/send-order-popup.component";
import * as fromApp from "../../store/app.reducer";
import * as CartActiveCouponsActions from "../../cart/cart-components/cart-active-coupons/store/cartActiveCoupons.actions";
import { async } from "@firebase/util";
import { SendOrderAndPayInfoPopupComponent } from "src/app/popups/send-order-pay-popup/send-order-and-pay-info-popup/send-order-and-pay-info-popup.component";
import { AnalyticsServiceService } from "../analitycsService/analytics-service.service";
import * as selectors from "../../store/app.selectors";
import { first } from "rxjs";
import { InformationMessagesService } from "../informationMessages/information-messages.service";
import { ReservationSendOrderPopupComponent } from "src/app/reservation-system/reservation-send-order-popup/reservation-send-order-popup.component";

@Injectable({
  providedIn: "root",
})
export class CartPageAlertsService {
  public activateCouponAlert: any;
  public sendOrderModal: any;
  public sendOrderAndPayModal: any;
  public sendOrderAndPayInfoModal: any;
  public pendingOrderAlert: any;
  public cantUseCouponsAlert: any;
  private pendingOrderAlertAlreadyShowed: boolean = false;

  constructor(
    private alertController: AlertController,
    private translateService: TranslateService,
    private modalController: ModalController,
    private store: Store<fromApp.AppState>,
    private infoMessagesService: InformationMessagesService,
    private analyticsService: AnalyticsServiceService
  ) {}

  presentSendOrderModal() {
    this.store
      .select("groupApp")
      .pipe(first())
      .subscribe((state) => {
        if (!this.sendOrderModal) {
          this.sendOrderModal = true;
          this.showSendOrderModal(_.cloneDeep(state ? state.groupApp : null));
        }
      })
      .unsubscribe();
  }

  presentSendOrderAndPayModal(currentUser) {
    if (!this.sendOrderAndPayInfoModal) {
      this.sendOrderAndPayInfoModal = true;
      this.showSendOrderAndPayInfoModal(currentUser);
    }
  }

  checkIfSendOrderModalHasInfoMessages() {
    return new Promise((resolve) => {
      this.store
        .select("groupApp")
        .pipe(first())
        .subscribe((state) => {
          this.store
            .select(selectors.getStoreInfo)
            .pipe(first())
            .subscribe(async (store_info) => {
              let infoMessagesGroup, infoMessagesStore;
              if (state && state.groupApp && store_info) {
                infoMessagesGroup =
                  this.infoMessagesService.checkForMobileOrderInfoMessagesGroupApp(
                    state.groupApp,
                    store_info,
                    "completeOrderModal"
                  );

                infoMessagesStore =
                  this.infoMessagesService.checkForMobileOrderInfoMessagesGroupApp(
                    state.groupApp,
                    store_info,
                    "completeOrderModal"
                  );
              }

              console.log("cxvvcxcxv", infoMessagesStore, infoMessagesGroup);
              let modalHasInfoMessage = false;
              if (infoMessagesStore && infoMessagesStore.length) {
                modalHasInfoMessage = !!infoMessagesStore[0];
              } else {
                if (infoMessagesGroup && infoMessagesGroup.length) {
                  modalHasInfoMessage = !!infoMessagesGroup[0];
                }
              }

              resolve(modalHasInfoMessage);
            })
            .unsubscribe();
        })
        .unsubscribe();
    });
  }

  async showSendOrderModal(groupApp) {
    const hasInfoMessage = await this.checkIfSendOrderModalHasInfoMessages();

    this.sendOrderModal = await this.modalController.create({
      component:
        groupApp && groupApp.active_reservation_app
          ? ReservationSendOrderPopupComponent
          : SendOrderPopupComponent,
      cssClass: hasInfoMessage
        ? "sendOrderModalWithInfoMessage"
        : "sendOrderModal",
      backdropDismiss: false,
    });
    await this.sendOrderModal.present();
    this.sendOrderModal.onDidDismiss().then(() => {
      this.sendOrderModal = null;
    });
  }

  async showSendOrderAndPayInfoModal(currentUser) {
    const hasInfoMessage = await this.checkIfSendOrderModalHasInfoMessages();

    this.sendOrderAndPayInfoModal = await this.modalController.create({
      component: SendOrderAndPayInfoPopupComponent,
      cssClass: hasInfoMessage
        ? "sendOrderAndPayModalWithInfoMessage"
        : "sendOrderAndPayModal",

      backdropDismiss: false,
    });
    await this.sendOrderAndPayInfoModal.present();
    this.sendOrderAndPayInfoModal.onDidDismiss().then((data) => {
      this.sendOrderAndPayInfoModal = null;
      if (data && data.data && data.data === "continue") {
        if (!this.sendOrderAndPayModal) {
          this.sendOrderAndPayModal = true;
          this.showSendOrderAndPayModal(currentUser);
        }
      }
    });
  }

  async showSendOrderAndPayModal(currentUser) {
    let user = _.cloneDeep(currentUser);
    user.cards = _.filter(_.cloneDeep(user.cards), (card) => {
      if (card && !card.invalid) {
        return card;
      }
    });
    this.sendOrderAndPayModal = await this.modalController.create({
      component: SendOrderPayPopupComponent,
      cssClass:
        user && user.cards && user.cards.length > 0
          ? "sendOrderAndPayModal"
          : "sendOrderAndPayModalSmall",

      backdropDismiss: false,
    });
    await this.sendOrderAndPayModal.present();
    this.sendOrderAndPayModal.onDidDismiss().then(() => {
      this.sendOrderAndPayModal = null;
    });
  }

  async presentCantUseCouponsWithPromoCartAlert(store_info) {
    this.cantUseCouponsAlert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: this.translateService.instant("alert"),
      message:
        store_info &&
        store_info.cant_use_coupons_already_discount_products_message
          ? this.translateService.instant(
              "cart.cart-components.cart-active-coupons.cart-products-cant-get-coupon-2"
            )
          : this.translateService.instant(
              "cart.cart-components.cart-active-coupons.cart-products-cant-get-coupon"
            ),
      backdropDismiss: false,
      buttons: ["OK"],
    });

    await this.cantUseCouponsAlert.present();
    this.cantUseCouponsAlert.onDidDismiss().then(() => {
      this.cantUseCouponsAlert = null;
    });
  }

  closeCantUseCouponsWithPromoCartAlert() {
    if (this.cantUseCouponsAlert) {
      this.cantUseCouponsAlert.dismiss();
      this.cantUseCouponsAlert = null;
    }
  }

  async presentCantUseCouponPreorderOnlyAlert(store_info) {
    this.cantUseCouponsAlert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: this.translateService.instant("alert"),
      message: this.translateService.instant(
        "cart.cart-components.cart-active-coupons.coupon-is-preorder-only-please-select-preorder-to-use-it"
      ),
      backdropDismiss: false,
      buttons: ["OK"],
    });

    await this.cantUseCouponsAlert.present();
    this.cantUseCouponsAlert.onDidDismiss().then(() => {
      this.cantUseCouponsAlert = null;
    });
  }

  async presentCantUseCouponOnlinePaymentOnlyAlert() {
    this.cantUseCouponsAlert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: this.translateService.instant("alert"),
      message: this.translateService.instant(
        "cart.cart-components.cart-active-coupons.coupon-is-online-payment-only-please-select-preorder-to-use-it"
      ),
      backdropDismiss: false,
      buttons: ["OK"],
    });

    await this.cantUseCouponsAlert.present();
    this.cantUseCouponsAlert.onDidDismiss().then(() => {
      this.cantUseCouponsAlert = null;
    });
  }

  closeCantUseCouponsPreorderOnlyAlert() {
    if (this.cantUseCouponsAlert) {
      this.cantUseCouponsAlert.dismiss();
      this.cantUseCouponsAlert = null;
    }
  }

  closeCantUseCouponsOnlinePaymentOnlyAlert() {
    if (this.cantUseCouponsAlert) {
      this.cantUseCouponsAlert.dismiss();
      this.cantUseCouponsAlert = null;
    }
  }

  async openActiveCouponAlertReservationApp(
    selectedPaymentMethodId,
    activeCoupons,
    currentUser,
    cartProducts
  ) {
    this.activateCouponAlert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: this.translateService.instant(
        "cart.cart-components.cart-active-coupons.coupons"
      ),
      message: this.translateService.instant(
        "reservation-app.cart-active-coupons.you-have-an-active-coupon-do-you-want-to-use-it"
      ),
      backdropDismiss: false,

      buttons: [
        {
          text: this.translateService.instant("no"),
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            if (selectedPaymentMethodId) {
              if (
                selectedPaymentMethodId === "cash" ||
                selectedPaymentMethodId === "mobilePOS"
              ) {
                this.presentSendOrderModal();
              } else {
                this.presentSendOrderAndPayModal(currentUser);
              }
            }
          },
        },
        {
          text: this.translateService.instant("yes"),
          handler: () => {
            if (
              activeCoupons &&
              activeCoupons.length &&
              activeCoupons.length > 0
            ) {
              let product_coupon_index = _.findIndex(
                activeCoupons,
                (coupon: any) => {
                  if (
                    coupon.product_id &&
                    coupon.validDate &&
                    coupon.validCombo &&
                    coupon.validMinOrder &&
                    _.find(cartProducts, { product_id: coupon.product_id })
                  ) {
                    return true;
                  }
                }
              );

              let coupon_index;

              if (product_coupon_index !== -1) {
                coupon_index = product_coupon_index;
              } else {
                coupon_index = _.findIndex(
                  activeCoupons,
                  (coupon: any) =>
                    !coupon.product_id &&
                    coupon.validDate &&
                    coupon.validCombo &&
                    coupon.validMinOrder
                );
              }

              if (coupon_index !== -1) {
                let newCoupons = _.cloneDeep(activeCoupons);
                newCoupons[coupon_index].couponCheckbox = true;
                this.store.dispatch(
                  new CartActiveCouponsActions.SetActiveCoupons(newCoupons)
                );
                if (!newCoupons[coupon_index].product_id) {
                  //TO_DO present complete order alert-modal
                }
              }
            }
          },
        },
      ],
    });

    await this.activateCouponAlert.present();
  }

  async openActivateCouponAlert(
    selectedPaymentMethodId,
    activeCoupons,
    pendingOrders,
    currentUser
  ) {
    this.activateCouponAlert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: this.translateService.instant(
        "cart.cart-components.cart-active-coupons.coupons"
      ),
      message: this.translateService.instant(
        "cart.cart-components.cart-active-coupons.you-have-an-active-coupon-do-you-want-to-use-it"
      ),
      backdropDismiss: false,

      buttons: [
        {
          text: this.translateService.instant("no"),
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            if (
              pendingOrders &&
              pendingOrders.length &&
              pendingOrders.length > 0
            ) {
              this.openPendingOrderAlert(
                selectedPaymentMethodId,
                pendingOrders[0],
                true,
                currentUser
              );
            } else {
              if (selectedPaymentMethodId) {
                if (
                  selectedPaymentMethodId === "cash" ||
                  selectedPaymentMethodId === "mobilePOS"
                ) {
                  this.presentSendOrderModal();
                } else {
                  this.presentSendOrderAndPayModal(currentUser);
                }
              }
            }
          },
        },
        {
          text: this.translateService.instant("yes"),
          handler: () => {
            if (
              activeCoupons &&
              activeCoupons.length &&
              activeCoupons.length > 0
            ) {
              let coupon_index = _.findIndex(
                activeCoupons,
                (coupon: any) =>
                  coupon.validDate && coupon.validCombo && coupon.validMinOrder
              );
              if (coupon_index !== -1) {
                let newCoupons = _.cloneDeep(activeCoupons);
                newCoupons[coupon_index].couponCheckbox = true;
                this.store.dispatch(
                  new CartActiveCouponsActions.SetActiveCoupons(newCoupons)
                );
                if (!newCoupons[coupon_index].product_id) {
                  //TO_DO present complete order alert-modal
                }
              }
            }
          },
        },
      ],
    });

    await this.activateCouponAlert.present();
  }

  async openPendingOrderAlert(
    selectedPaymentMethodId,
    pendingOrder,
    openedFromOtherAlert,
    currentUser
  ) {
    this.analyticsService.dmAnalyticsMessage(
      "pending_order_cart_alert_opened",
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
    setTimeout(
      async () => {
        let disableButtons: boolean = true;
        if (!this.pendingOrderAlertAlreadyShowed) {
          let alertMessage;
          if (pendingOrder && pendingOrder.status === "pending") {
            alertMessage = "cart.there-is-a-pending-order";
          } else if (pendingOrder && pendingOrder.status === "processing") {
            alertMessage = "cart.there-is-a-processing-order";
          } else if (
            pendingOrder &&
            pendingOrder.status === "processingPayment"
          ) {
            alertMessage = "cart.there-is-a-processing-payment-order";
          } else if (pendingOrder && pendingOrder.status === "pendingPayment") {
            alertMessage = "cart.there-is-a-pending-payment-payment-order";
          }
          this.pendingOrderAlert = await this.alertController.create({
            cssClass: "my-custom-class",
            header: this.translateService.instant("alert"),
            message:
              this.translateService.instant(alertMessage) +
              " " +
              this.translateService.instant(
                "cart.are-you-sure-that-you-want-to-create-a-new-order"
              ),
            backdropDismiss: false,

            buttons: [
              {
                text: this.translateService.instant("no"),
                role: "cancel",

                cssClass: "secondary",
                handler: (blah) => {},
              },
              {
                text: this.translateService.instant("yes"),
                handler: () => {
                  if (selectedPaymentMethodId) {
                    if (
                      selectedPaymentMethodId === "cash" ||
                      selectedPaymentMethodId === "mobilePOS"
                    ) {
                      this.presentSendOrderModal();
                    } else {
                      this.presentSendOrderAndPayModal(currentUser);
                    }
                  }
                },
              },
            ],
          });

          await this.pendingOrderAlert.present();
          this.pendingOrderAlertAlreadyShowed = true;
        } else {
          if (selectedPaymentMethodId) {
            if (
              selectedPaymentMethodId === "cash" ||
              selectedPaymentMethodId === "mobilePOS"
            ) {
              this.presentSendOrderModal();
            } else {
              this.presentSendOrderAndPayModal(currentUser);
            }
          }
        }
      },
      openedFromOtherAlert ? 800 : 0
    );
  }

  clearPendingOrderAlertAlreadyShowedInCompleteOrder() {
    this.pendingOrderAlertAlreadyShowed = false;
  }

  closePendingOrderAlert() {
    if (this.pendingOrderAlert) {
      this.pendingOrderAlert.dismiss();
      this.pendingOrderAlert = null;
    }
  }

  closeActivateCouponAlert() {
    if (this.activateCouponAlert) {
      this.activateCouponAlert.dismiss();
      this.activateCouponAlert = null;
    }
  }

  closeSendOrderAndPayModal() {
    if (this.sendOrderAndPayInfoModal) {
      this.sendOrderAndPayInfoModal.dismiss();
      this.sendOrderAndPayInfoModal = null;
    }
    if (this.sendOrderAndPayModal) {
      this.sendOrderAndPayModal.dismiss();
      this.sendOrderAndPayModal = null;
    }
  }

  closeSendOrderModal() {
    if (this.sendOrderModal) {
      this.sendOrderModal.dismiss();
      this.sendOrderModal = null;
    }
  }

  getActivateCouponAlert() {
    return _.cloneDeep(this.activateCouponAlert);
  }

  getActivatePendingOrderAlert() {
    return _.cloneDeep(this.pendingOrderAlert);
  }
}
