import {
  AfterContentChecked,
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { IonSlides, ModalController } from "@ionic/angular";
import { from, Observable, Subscription } from "rxjs";
import * as _ from "lodash";
import { auditTime, distinctUntilChanged } from "rxjs/operators";
import * as fromApp from "../../../store/app.reducer";
import * as selectors from "../../../store/app.selectors";
import { Store } from "@ngrx/store";
import { ProductDetailsComponent } from "src/app/product-details/product-details.component";
declare let window: any;

@Component({
  selector: "app-product-items-popular-category-only-image-mobile",
  templateUrl:
    "./product-items-popular-category-only-image-mobile.component.html",
  styleUrls: [
    "./product-items-popular-category-only-image-mobile.component.scss",
  ],
})
export class ProductItemsPopularCategoryOnlyImageMobileComponent
  implements OnInit, AfterViewChecked, OnDestroy
{
  @Input() category_id: any;
  @Input() sliderButtons: boolean;
  @Input() groupStoreData;
  @Input() selectedDineInTable;
  @Input() selectedPreorderDate;
  @ViewChild("productsSlider") slider: IonSlides;
  @ViewChild("productTitle") productTitleRef: ElementRef;
  public category: any;
  public categoriesSlider: any;
  public browserPlatform: any;
  public orderPickup: any;
  public dineIn: any;
  public groupApp: any;
  public webp: any;
  private subscriptions: Subscription[] = [];
  public sliderOptions = {
    allowTouchMove: true,
    slidesPerView: "auto",
    direction: "horizontal",
    slideToClickedSlide: true,
    mousewheel: true,
    freeMode: true,
    freeModeMomentumBounce: false,

    // autoplay:true,
    spaceBetween: 2,

    watchSlidesVisibility: true,
  };
  private productDetailsModal: any;

  constructor(
    private store: Store<fromApp.AppState>,
    private modalCtrl: ModalController,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select(selectors.getCategoryByCategoryId(this.category_id))
        .pipe(distinctUntilChanged())

        .subscribe((category) => {
          if (category && !_.isEqual(category, this.category)) {
            let tempCategory = _.cloneDeep(category);
            _.each(tempCategory.products, (product) => {
              if (
                product &&
                (!product.availableTotal ||
                  !product.product_categories_availability_check)
              ) {
                product.tags = [];
              } else {
                if (product.tags) {
                  let keys = _.keys(product.tags);

                  const fistKey = keys[0].toString();

                  if (fistKey) {
                    product.tags = {};
                    product.tags[fistKey] = true;
                  }
                }
              }
            });
            this.category = _.cloneDeep(tempCategory);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("deliveryMethodAddress")

        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && !_.isEqual(this.dineIn, state.dineIn)) {
            this.dineIn = _.cloneDeep(state.dineIn);
          }
          if (state && !_.isEqual(this.orderPickup, state.orderPickup)) {
            this.orderPickup = _.cloneDeep(state.orderPickup);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("platformInfo")
        .pipe(auditTime(200))
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.browserPlatform, state.browserPlatform)
          ) {
            this.browserPlatform = _.cloneDeep(state.browserPlatform);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(auditTime(200))
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(this.groupApp, state.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
          }
          if (state && state.webp && !_.isEqual(this.webp, state.webp)) {
            this.webp = _.cloneDeep(state.webp);
          }
        })
    );
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  //TO_DO CHECK THIS TRACKBY FOR RETURN ITEM ATTR
  trackFunc(index, item) {
    return index;
  }

  trackProducts(index, item) {
    return item.product_id;
  }

  prdWithoutImage(product) {
    return (
      !product.image ||
      (product.image && !product.image.true) ||
      (product.image && !product.image.true["16x9"]) ||
      (product.image &&
        product.image.true["16x9"] &&
        !product.image.true["16x9"].path)
    );
  }

  showProductDetailsModal(product) {
    let detailsCssClass;
    if (
      product &&
      product.num_of_options &&
      product.num_of_options < 2 &&
      this.prdWithoutImage(product)
    ) {
      detailsCssClass = "productDetailsModalSmall";
    } else if (
      product &&
      product.num_of_options &&
      !product.squareImageDesign &&
      product.num_of_options > 2 &&
      !this.prdWithoutImage(product)
    ) {
      detailsCssClass = "productDetailsModalBig";
    } else if (
      product &&
      product.squareImageDesign &&
      product.num_of_options &&
      product.num_of_options > 2 &&
      window &&
      window.innerWidth &&
      window.innerWidth >= 1200 &&
      !this.prdWithoutImage(product)
    ) {
      detailsCssClass = "productDetailsModalBigSquareImageDesign";
    } else {
      detailsCssClass = "productDetailsModal";
    }
    if (!this.productDetailsModal) {
      this.productDetailsModal = true;
      this.presentProductDetailsModal(product, detailsCssClass);
    }
  }

  nextSlide() {
    if (this.slider) {
      this.slider.slideNext();
    }
  }

  prevSlide() {
    if (this.slider) {
      this.slider.slidePrev();
    }
  }

  async presentProductDetailsModal(product, detailsCssClass) {
    let product_id: string;
    let cart_id: string;
    if (product && product.product_id) {
      product_id = product.product_id;
    }

    if (product && product.cart_id) {
      cart_id = product.product_id;
    }

    this.productDetailsModal = await this.modalCtrl.create({
      component: ProductDetailsComponent,
      cssClass: detailsCssClass,
      animated: true,
      componentProps: {
        product_id: product_id,
        cart_id: cart_id,
      },
      backdropDismiss: false,
    });

    await this.productDetailsModal.present();
    this.productDetailsModal.onDidDismiss().then(() => {
      this.productDetailsModal = null;
    });
  }

  ngOnDestroy() {
    if (this.productDetailsModal) {
      this.productDetailsModal.dismiss();
      this.productDetailsModal = null;
    }
    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }
}
