<ion-item *ngIf="category" class="categoryItem">
  <ion-button
    style="position: absolute; top: 0px; right: 30px"
    size="small"
    fill="clear"
    (click)="prevSlide()"
  >
    <ion-icon slot="icon-only" src="assets/ionicons/chevron-back-outline.svg">
    </ion-icon>
  </ion-button>
  <ion-button
    style="position: absolute; top: 0px; right: 2px"
    size="small"
    (click)="nextSlide()"
    fill="clear"
  >
    <ion-icon
      slot="icon-only"
      src="assets/ionicons/chevron-forward-outline.svg"
    >
    </ion-icon>
  </ion-button>

  <ion-row style="width: 100%">
    <ion-col size="12" class="noPaddingCols"> 
      <strong style="font-size: larger"
        ><ion-text color="primary">{{ category.name }}</ion-text>
      </strong>
    </ion-col>

    <ion-col
      size="12"
      class="noPaddingCols"
      *ngIf="!category.availableTotal || category.avpc"
    >
      <app-category-item-tags
        [categoryItem]="category"
        [groupStoreData]="groupStoreData"
      ></app-category-item-tags>
      <!-- {{ "catalog.categories.category-item.unavailable-category" | translate }} -->
    </ion-col>

    <ion-col size="12" class="DescriptionPaddings" *ngIf="category.description">
      <app-description [description]="category.description"></app-description>
    </ion-col>
    <ion-col
      class="noPaddingCols"
      size="12"
      *ngIf="category.promotions && category.promotions.length > 0"
    >
      <div
        *ngFor="let promo of category.promotions"
        class="categoriesWarningColor"
      >
        <span style="font-weight: bold; font-size: small">{{
          promo.description
        }}</span>
      </div>
    </ion-col>
  </ion-row>
</ion-item>
<div>
  <ion-slides
    #productsSlider
    class="ion-text-center"
    *ngIf="category && category.activeTotal"
    scrollbar
    [options]="sliderOptions"
  >
    <ion-slide
      style="width: 340px; height: 180px"
      *ngFor="let product of category.products; trackBy: productTrack"
      ><ion-card
        class="ion-no-margin"
        button
        detail="false"
        (click)="showProductDetailsModal(product)"
        style="width: 90%; height: 95%; z-index: 100; cursor: pointer"
        *ngIf="product.activeTotal && category.activeTotal"
      >
        <img
          [alt]="product.name"
          loading="lazy"
          style="z-index: 0"
          class="ImageDemensions"
          [src]="
            'https://images.deliverymanager.gr/' +
            product.image.true['16x9'].path +
            (webp ? '.webp' : product.image.true['16x9'].type || '.jpg')
          "
          *ngIf="
            product &&
            product.image &&
            product.image.true &&
            product.image.true['16x9'] &&
            product.image.true['16x9'].path
          "
        />
      </ion-card>
    </ion-slide>
  </ion-slides>
</div>
