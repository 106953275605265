import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { auditTime, distinctUntilChanged, first } from "rxjs/operators";
import * as fromApp from "../../../store/app.reducer";
import * as _ from "lodash";

import * as selectors from "./../../../store/app.selectors";

@Component({
  selector: "app-extra-choices",
  templateUrl: "./extra-choices.component.html",
  styleUrls: ["./extra-choices.component.scss"],
})
export class ExtraChoicesComponent implements OnInit, OnDestroy {
  public store_info: any;
  public cart: any;
  public cartCustomerOptions: any;
  public cartOptionsShown: any;

  private orderPickup: any;
  private dineIn: any;
  private selectedPaymentMethodID: any;
  private subscriptions: Subscription[] = [];

  constructor(private store: Store<fromApp.AppState>) {}

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select("cartCustomerOptions")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.cartCustomerOptions &&
            !_.isEqual(this.cartCustomerOptions, state.cartCustomerOptions)
          ) {
            this.cartCustomerOptions = _.cloneDeep(state.cartCustomerOptions);
            this.checkIfCartOptionsShownDebounced();
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("cart")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.products &&
            !_.isEqual(this.cart, state.products)
          ) {
            this.cart = _.cloneDeep(state.products);
            if (this.selectedPaymentMethodID && this.store_info && this.cart) {
              this.checkIfCartOptionsShownDebounced();
            }
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("deliveryMethodAddress")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && !_.isEqual(this.orderPickup, state.orderPickup)) {
            this.orderPickup = _.cloneDeep(state.orderPickup);
            if (this.selectedPaymentMethodID && this.store_info && this.cart) {
              this.checkIfCartOptionsShownDebounced();
            }
          }

          if (state && !_.isEqual(this.dineIn, state.dineIn)) {
            this.dineIn = _.cloneDeep(state.dineIn);
            if (this.selectedPaymentMethodID && this.store_info && this.cart) {
              this.checkIfCartOptionsShownDebounced();
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())

        .subscribe((storeInfo) => {
          if (storeInfo && !_.isEqual(storeInfo, this.store_info)) {
            this.store_info = _.cloneDeep(storeInfo);
            if (this.selectedPaymentMethodID && this.store_info && this.cart) {
              this.checkIfCartOptionsShownDebounced();
            }
            console.log("store Info from cart options", this.store_info);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("selectedPaymentMethodID")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(
              state.selectedPaymentMethodID,
              this.selectedPaymentMethodID
            )
          ) {
            this.selectedPaymentMethodID = _.cloneDeep(
              state.selectedPaymentMethodID
            );
            if (this.selectedPaymentMethodID && this.store_info && this.cart) {
              this.checkIfCartOptionsShownDebounced();
            }
          }
        })
    );
  }

  private checkIfCartOptionsShownDebounced = _.debounce(() => {
    this.checkIfCartOptionsShown();
  }, 50);

  checkIfCartOptionsShown() {
    this.cartOptionsShown = null;
    // console.log(
    //   "check if cart options is shown",
    //   this.store_info,
    //   this.selectedPaymentMethodID,
    //   this.orderPickup,
    //   this.cart,
    //   this.cartCustomerOptions
    // );
    _.each(this.cartCustomerOptions, (option) => {
      if (option && option.type && option.type === "plasticTax") {
        _.each(option.choices, (choice) => {
          console.log("choices of plastic tax", choice);
          if (
            choice.qty > 0 &&
            choice.totalPrice &&
            choice.totalPrice !== "0.00"
          ) {
            this.cartOptionsShown = "show";
          }
        });
      }
    });

    _.each(this.store_info.cartCustomOptions, (option) => {
      if (
        this.store_info.cartCustomOptionsActive &&
        this.cart &&
        this.cart.length > 0 &&
        option.active &&
        !option.only_kiosk &&
        option &&
        option.type !== "plasticTax" &&
        (!this.dineIn || (this.dineIn && option.showInDinein)) &&
        (!option.onlinePaymentOnly ||
          (this.selectedPaymentMethodID !== "cash" &&
            this.selectedPaymentMethodID !== "mobilePOS")) &&
        ((!option.pickupOnly && !option.deliveryOnly) ||
          (option.deliveryOnly && !option.pickupOnly && !this.orderPickup) ||
          (option.pickupOnly && !option.deliveryOnly && this.orderPickup))
      ) {
        this.cartOptionsShown = "show";
      }
    });
    if (!this.cartOptionsShown) {
      this.cartOptionsShown = "notShow";
    }
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }
}
