import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import * as fromApp from "../../../../store/app.reducer";
import * as selectors from "../../../../store/app.selectors";
import * as _ from "lodash";
import { auditTime, distinctUntilChanged, first } from "rxjs/operators";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import {
  AlertController,
  GestureController,
  IonSlides,
  ModalController,
  Platform,
} from "@ionic/angular";
import * as CatalogActions from "../../../../catalog/store/catalog.actions";
import * as CategoriesSelectionActions from "../../../../catalog/categories/store/categoriesSelection.actions";
import * as HyperCategoriesActions from "../../../../store/hyperCategories/hyperCategories.actions";
import { TranslateService } from "@ngx-translate/core";
import { UnavailableProductsModalComponent } from "../../../../catalog/categories/unavailable-products-modal/unavailable-products-modal.component";

@Component({
  selector: "app-reservation-products-list",
  templateUrl: "./reservation-products-list.component.html",
  styleUrls: ["./reservation-products-list.component.scss"],
})
export class ReservationProductsListComponent implements OnInit {
  @Input() imageBrowser: any;
  @Input() sliderButtons: boolean;
  @ViewChild("scrollDiv") scrollDivEl: any;
  public storeInfo: any;
  public categories: any;
  public currentUser: any;
  public category_id: any;
  public selectedCategoryId: any;
  public currentScrollCategoryId: any;
  public currentHyperCategory: any;
  public hyperCategories: any;
  public cartProductIds: any;
  public scrollFromTop: any = 0;
  public scrollFromBottom: any = 50;
  public showSearchFab: boolean = false;
  public selectedStoreId: any;
  public stores: any;
  public groupApp: any;
  public allCategories: any;
  public cartProducts: any;
  public orderPickup;
  public dineIn;
  public webp: any;
  public groupStoreData = {
    groupApp: null,
    store_info: null,
    webp: null,
    selectedLanguage: null,
    mo_base: null,
  };
  public selectedLanguage;
  public mo_base;
  public showNextCategoryButton;
  public allCategoriesMarketCardsDesign;
  public finalPriceLoading;

  // public testSignEvent;
  private windowDimensions: any;
  public testSignDirection;
  public testSignScrollEvent;
  public unavailableProductsByCategoryId: any = {};
  public changedUnavailableProductsByCategoryIdTimestamp: string;
  public disable_scroll: boolean = false;
  public selectedDineInTable: any;
  public selectedPreorderDate: any;
  private unavailableProductsModal: any;
  private isIos: boolean;
  private gesture: any;
  private catalogChanged: boolean = false;
  private subscriptions: Subscription[] = [];
  private direction: string;

  private onStartMovingX;

  constructor(
    private store: Store<fromApp.AppState>,
    private gestureController: GestureController,
    private platform: Platform,
    private modalCtrl: ModalController
  ) {}

  // onMoveTestStart(ev) {
  //   console.log("moved start", ev);
  //   if (ev && ev.type === "pan") {
  //     this.onStartMovingX = _.cloneDeep(ev.startX);
  //   }
  // }

  onMoveEnd(ev) {
    console.log("moved end", ev);
    this.direction = null;

    // this.testSignEvent = true;
    // setTimeout(() => {
    //   this.testSignEvent = false;
    // }, 200);

    if (ev && ev.type === "pan") {
      if (this.isIos) {
        this.direction =
          Math.abs(ev.deltaX) > 5 ? (ev.deltaX > 0 ? "Right" : "Left") : "";
        if (this.direction) {
          // this.testSignDirection = true;
          // setTimeout(() => {
          //   this.testSignDirection = false;
          // }, 200);
        }
      } else {
        if (Math.abs(ev.velocityX) > 0.1) {
          this.direction =
            Math.abs(ev.deltaX) > 25 ? (ev.deltaX > 0 ? "Right" : "Left") : "";
          if (this.direction) {
            // this.testSignDirection = true;
            // setTimeout(() => {
            //   this.testSignDirection = false;
            // }, 200);
          }
        }
      }
    }

    if (
      (this.hyperCategories && this.hyperCategories.length === 0) ||
      this.storeInfo.virtual_store_change_hyper_categories_buttons ||
      (this.storeInfo &&
        this.storeInfo.design_settings &&
        this.storeInfo.design_settings.showOnlySelectedCategoryProducts)
    ) {
      if (this.direction && this.direction === "Left") {
        if (
          this.categories &&
          this.categories.length &&
          (this.selectedCategoryId || this.currentScrollCategoryId)
        ) {
          const selectedCategoryIndex = _.findIndex(this.categories, {
            category_id:
              this.selectedCategoryId || this.currentScrollCategoryId,
          });
          if (
            selectedCategoryIndex !== -1 &&
            this.categories[selectedCategoryIndex + 1]
          ) {
            this.store.dispatch(
              new CategoriesSelectionActions.SetSelectedCategory(
                this.categories[selectedCategoryIndex + 1].category_id
              )
            );
            this.store.dispatch(
              new CategoriesSelectionActions.SetScrollSelectedCategory(null)
            );
          }
        }
      } else if (this.direction && this.direction === "Right") {
        // console.log(
        //   "no hyper categories",
        //   this.direction,
        //   this.categories,
        //   this.selectedCategoryId,
        //   this.currentScrollCategoryId
        // );
        if (
          this.categories &&
          this.categories.length &&
          (this.selectedCategoryId || this.currentScrollCategoryId)
        ) {
          const selectedCategoryIndex = _.findIndex(this.categories, {
            category_id:
              this.selectedCategoryId || this.currentScrollCategoryId,
          });
          if (
            selectedCategoryIndex !== -1 &&
            this.categories[selectedCategoryIndex - 1]
          ) {
            this.store.dispatch(
              new CategoriesSelectionActions.SetSelectedCategory(
                this.categories[selectedCategoryIndex - 1].category_id
              )
            );
            this.store.dispatch(
              new CategoriesSelectionActions.SetScrollSelectedCategory(null)
            );
          }
        }
      }
    } else if (
      this.hyperCategories &&
      this.hyperCategories.length > 0 &&
      !this.storeInfo.virtual_store_change_hyper_categories_buttons
    ) {
      if (this.direction && this.direction === "Left") {
        if (this.currentHyperCategory) {
          const selectedHyperCategoryIndex = _.findIndex(this.hyperCategories, {
            hyper_category_id: this.currentHyperCategory,
          });
          if (
            selectedHyperCategoryIndex !== -1 &&
            this.hyperCategories[selectedHyperCategoryIndex + 1]
          ) {
            this.store.dispatch(
              new HyperCategoriesActions.SetCurrentHyperCategory(
                this.hyperCategories[
                  selectedHyperCategoryIndex + 1
                ].hyper_category_id
              )
            );
          }
        }
      } else if (this.direction && this.direction === "Right") {
        if (this.currentHyperCategory) {
          const selectedHyperCategoryIndex = _.findIndex(this.hyperCategories, {
            hyper_category_id: this.currentHyperCategory,
          });
          if (
            selectedHyperCategoryIndex !== -1 &&
            this.hyperCategories[selectedHyperCategoryIndex - 1]
          ) {
            this.store.dispatch(
              new HyperCategoriesActions.SetCurrentHyperCategory(
                this.hyperCategories[
                  selectedHyperCategoryIndex - 1
                ].hyper_category_id
              )
            );
          }
        }
      }
    }
  }

  // onIntersection(
  //   { target, visible }: { target: Element; visible: boolean },
  //   product
  // ): void {
  //   this.store.dispatch(
  //     new CatalogActions.SetProductsCatalogVisibility({
  //       name: product.name,
  //       product_id: product.product_id,
  //       visibility: visible,
  //     })
  //   );
  // }

  ngOnInit() {
    if (this.platform.is("ios")) {
      this.isIos = true;
    }
    setTimeout(() => {
      if (this.gesture) {
        this.gesture.destroy();
        this.gesture = null;
      }
      this.gesture = this.gestureController.create(
        {
          el: this.scrollDivEl ? this.scrollDivEl.nativeElement : "",
          threshold: this.isIos ? 10 : 10,
          maxAngle: 50,
          gestureName: "swipe-catelog",

          onEnd: (ev) => this.onMoveEnd(ev),
        },
        true
      );
      this.gesture.enable(true);
      this.subscriptions.push(
        this.store
          .select("windowInfo")
          .pipe(distinctUntilChanged())
          .subscribe((state) => {
            if (
              state &&
              state.windowDimensions &&
              !_.isEqual(this.windowDimensions, state.windowDimensions)
            ) {
              this.windowDimensions = _.cloneDeep(state.windowDimensions);
              if (
                this.windowDimensions &&
                this.windowDimensions.width &&
                this.windowDimensions.width < 1200
              ) {
                if (!this.platform.is("android") && !this.platform.is("ios")) {
                  this.gesture.enable(false);
                } else if (
                  this.platform.is("android") ||
                  this.platform.is("ios")
                ) {
                  this.gesture.enable(true);
                }
              }
            }
          })
      );
    }, 1000);

    this.subscriptions.push(
      this.store
        .select("disableFinalPrice")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(state.disableFinalPrice, this.finalPriceLoading)
          ) {
            this.finalPriceLoading = _.cloneDeep(state.disableFinalPrice);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("deliveryMethodAddress")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && !_.isEqual(state, state.orderPickup)) {
            this.orderPickup = _.cloneDeep(state.orderPickup);
          }
          if (state && !_.isEqual(state, state.dineIn)) {
            this.dineIn = _.cloneDeep(state.dineIn);
          }
          if (
            state &&
            !_.isEqual(this.selectedDineInTable, state.selectedDineInTable)
          ) {
            this.selectedDineInTable = _.cloneDeep(state.selectedDineInTable);
          }

          if (
            state &&
            !_.isEqual(this.selectedPreorderDate, state.selectedPreorderDate)
          ) {
            this.selectedPreorderDate = _.cloneDeep(state.selectedPreorderDate);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(this.groupApp, state.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
            this.groupStoreData.groupApp = {
              group: _.cloneDeep(this.groupApp.group),
              disableCatalogPhoto: _.cloneDeep(
                this.groupApp.disableCatalogPhoto
              ),
              pointsSystem: _.cloneDeep(this.groupApp.pointsSystem),
              iconImage: _.cloneDeep(this.groupApp.iconImage),
            };
            this.groupStoreData.groupApp.mo_base = _.find(
              _.cloneDeep(this.groupApp.languages),
              { mo_base: true }
            );
          }
          if (
            state &&
            state.selectedLangugage &&
            !_.isEqual(this.selectedLanguage, state.selectedLangugage)
          ) {
            this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
            this.groupStoreData.groupApp.selectedLanguage = _.cloneDeep(
              this.selectedLanguage
            );
          }
          if (state && state.webp && !_.isEqual(this.webp, state.webp)) {
            this.webp = _.cloneDeep(state.webp);
            this.groupStoreData.webp = _.cloneDeep(this.webp);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && state.stores && !_.isEqual(this.stores, state.stores)) {
            this.stores = _.cloneDeep(state.stores);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .subscribe((storeInfo) => {
          if (storeInfo && !_.isEqual(this.storeInfo, storeInfo)) {
            this.storeInfo = _.cloneDeep(storeInfo);
            const catalogCustomTagsById = {};
            _.each(_.cloneDeep(this.storeInfo.catalogCustomTags), (tag) => {
              catalogCustomTagsById[tag.id] = tag;
            });
            this.groupStoreData.store_info = {
              store_id: storeInfo.store_id,
              overridePromoItemsForPointsSystem: _.cloneDeep(
                this.storeInfo.overridePromoItemsForPointsSystem
              ),
              alt_dinein_active: storeInfo.dinein
                ? _.cloneDeep(this.storeInfo.alt_dinein_active)
                : null,
              catalogCustomTagsById: catalogCustomTagsById,
              mobileorder_show_img_borders:
                storeInfo.design_settings &&
                storeInfo.design_settings.mobileorder_show_img_borders
                  ? true
                  : false,
              design_settings: storeInfo.design_settings,
            };

            this.selectedStoreId = _.cloneDeep(this.storeInfo.store_id);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && state.user && !_.isEqual(this.currentUser, state.user)) {
            this.currentUser = _.cloneDeep(state.user);
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("catalog")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(300))
        .subscribe((state) => {
          if (
            state &&
            state.catalog &&
            !_.isEqual(this.categories, state.catalog)
          ) {
            this.categories = _.cloneDeep(state.catalog);
            if (!this.showSearchFab) {
              this.showSearchFab = true;
            }
          }

          if (
            state &&
            state.allCategories &&
            !_.isEqual(this.allCategories, state.allCategories)
          ) {
            this.allCategories = _.cloneDeep(state.allCategories);
            this.findUnavailableProductsByCategoryid();
          }

          if (
            state &&
            state.allCategoriesMarketCardsDesign &&
            !_.isEqual(
              this.allCategoriesMarketCardsDesign,
              state.allCategoriesMarketCardsDesign
            )
          ) {
            this.allCategoriesMarketCardsDesign = _.cloneDeep(
              state.allCategoriesMarketCardsDesign
            );
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("hyperCategories")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(400))
        .subscribe((state) => {
          if (
            state &&
            state.currentHyperCategory &&
            !_.isEqual(this.currentHyperCategory, state.currentHyperCategory)
          ) {
            this.currentHyperCategory = _.cloneDeep(state.currentHyperCategory);
            this.scrollToTopDebounce();
          }
          if (
            state &&
            state.hyperCategories &&
            !_.isEqual(this.hyperCategories, state.hyperCategories)
          ) {
            this.hyperCategories = _.cloneDeep(state.hyperCategories);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("verticalCategory")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.selectedCategoryId, state.selectedCategoryId)
          ) {
            this.selectedCategoryId = _.cloneDeep(state.selectedCategoryId);
            this.scrollToCategory(this.selectedCategoryId);
          }
          if (
            state &&
            state.selectedScrollCategoryId &&
            !_.isEqual(
              this.currentScrollCategoryId,
              state.selectedScrollCategoryId
            )
          ) {
            this.currentScrollCategoryId = _.cloneDeep(
              state.selectedScrollCategoryId
            );
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("cart")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            state.products &&
            !_.isEqual(this.cartProducts, state.products)
          ) {
            this.cartProducts = _.cloneDeep(state.products);
            this.cartProductIds = [];
            _.each(this.cartProducts, (product) => {
              if (
                !product.quantity ||
                product.quantity === 0 ||
                product.quantity === 1 ||
                (product.enable_quantity_step && product.quantity_step)
              ) {
                this.cartProductIds.push(_.cloneDeep(product.product_id));
              } else {
                for (let i = 0; i < product.quantity; i++) {
                  this.cartProductIds.push(_.cloneDeep(product.product_id));
                }
              }
            });
          }
        })
    );
  }

  private scrollToTopDebounce = _.debounce(() => {
    this.scrollToTop();
  }, 500);

  scrollToTop() {
    const scrollElement = document.getElementById("scrollDiv");
    if (scrollElement) {
      scrollElement.scrollTop = 0;
    }
  }
  scrollToCategory(categoryId) {
    //console.log(categoryId);

    const scrollElement = document.getElementById("scrollDiv");
    //we check platform here because on ios devices like iphone and ipad swipe gesture dont stops the scroll on transition and causing white screen to appear
    if (this.platform && this.platform.is("ios")) {
      if (scrollElement) {
        scrollElement.classList.add("disable-scroll");
      }
    }

    setTimeout(
      () => {
        if (this.platform && this.platform.is("ios")) {
          if (scrollElement) {
            scrollElement.classList.remove("disable-scroll");
          }
        }

        if (categoryId) {
          let element = document.getElementById("category_" + categoryId);
          if (element) {
            element.scrollIntoView(true);
          }
        }
      },
      this.platform && this.platform.is("ios") ? 50 : 0
    );
  }

  showNextCategoryButtonEv(ev) {
    this.showNextCategoryButton = ev;
  }

  calculateScrollFromTopAndBottom() {
    let el: any = document.getElementById("scrollDiv");

    this.scrollFromTop = _.cloneDeep(el.scrollTop);
    // console.log("scrollFromTop", this.scrollFromTop);
    this.scrollFromBottom = _.cloneDeep(
      el.scrollHeight - el.scrollTop - el.offsetHeight
    );
  }

  checkCategoryScroll() {
    this.testSignScrollEvent = true;
    setTimeout(() => {
      this.testSignScrollEvent = false;
    }, 200);
    let el: any = document.getElementById("scrollDiv");

    this.scrollFromTop = _.cloneDeep(el.scrollTop);
    // console.log("scrollFromTop", this.scrollFromTop);
    this.scrollFromBottom = _.cloneDeep(
      el.scrollHeight - el.scrollTop - el.offsetHeight
    );

    if (!this.categories) {
      return;
    }

    let currentCategoryIndex = _.findLastIndex(
      this.categories,
      (category: any) => {
        let categoryElement: any = document.getElementById(
          "category_" + category.category_id
        );
        if (categoryElement && el) {
          return categoryElement.offsetTop - el.scrollTop - 80 <= 0;
        } else {
          return false;
        }
      }
    );
    // console.log("current category index", currentCategoryIndex);
    var currentCategory = this.categories[currentCategoryIndex];
    // console.log("current category", currentCategory);
    if (!currentCategory) {
      return;
    }
    if (el) {
      let maxTop = el.offsetHeight;
      let centerPixel = maxTop / 2;
      //  console.log("centerPixel", centerPixel);
      //  console.log("el top", el.scrollTop);
      var scrollBottom = el.scrollHeight - el.scrollTop - el.offsetHeight;
      // console.log("scrollBottom", scrollBottom);
    }
    if (scrollBottom > 0) {
      _.each(this.categories, (category, key, list) => {
        if (
          currentCategory &&
          currentCategory.category_id === category.category_id
        ) {
          this.category_id = currentCategory.category_id;
          this.categories[key].selected = true;
          if (
            !_.isEqual(
              this.currentScrollCategoryId,
              this.categories[key].category_id
            )
          ) {
            this.store.dispatch(
              new CategoriesSelectionActions.SetScrollSelectedCategory(
                _.cloneDeep(this.categories[key].category_id)
              )
            );
            this.store.dispatch(
              new CategoriesSelectionActions.SetSelectedCategory(
                _.cloneDeep(null)
              )
            );
          }
        } else {
          this.categories[key].selected = false;
        }
      });
    } else {
      _.each(this.categories, (category, key, list) => {
        this.categories[key].selected = false;
      });
      this.category_id =
        this.categories[this.categories.length - 1].category_id;
      this.categories[this.categories.length - 1].selected = true;
      if (
        !_.isEqual(
          this.currentScrollCategoryId,
          this.categories[this.categories.length - 1].category_id
        )
      ) {
        this.store.dispatch(
          new CategoriesSelectionActions.SetScrollSelectedCategory(
            _.cloneDeep(this.categories[this.categories.length - 1].category_id)
          )
        );
        this.store.dispatch(
          new CategoriesSelectionActions.SetSelectedCategory(_.cloneDeep(null))
        );
      }
    }

    currentCategoryIndex = null;
  }

  findUnavailableProductsByCategoryid() {
    this.unavailableProductsByCategoryId = {};

    if (this.allCategories && this.allCategories.length) {
      _.each(this.allCategories, (category) => {
        this.unavailableProductsByCategoryId[category.category_id] = [];
        if (category.products) {
          _.each(category.products, (product) => {
            if (
              product &&
              (!product.availableTotal ||
                !product.product_categories_availability_check)
            ) {
              if (
                !_.find(
                  this.unavailableProductsByCategoryId[category.category_id],
                  { product_id: product.product_id }
                )
              )
                this.unavailableProductsByCategoryId[category.category_id].push(
                  _.cloneDeep(product)
                );
            }
          });
        }
      });
    }
    this.changedUnavailableProductsByCategoryIdTimestamp =
      Date.now().toString();
  }

  checkCategoryScrollDebounce = _.debounce(
    () => {
      this.checkCategoryScroll();
    },
    300,
    { maxWait: 300 }
  );

  onSwipe(ev) {
    //console.log("swipe", ev);
    const x =
      Math.abs(ev.deltaX) > 40 ? (ev.deltaX > 0 ? "Right" : "Left") : "";
    const y = Math.abs(ev.deltaY) > 40 ? (ev.deltaY > 0 ? "Down" : "Up") : "";
    this.direction = x || y;
    // console.log("direction", this.direction);
  }

  async openUnavailableProductsModal(category) {
    this.unavailableProductsModal = await this.modalCtrl.create({
      component: UnavailableProductsModalComponent,
      cssClass: "unavailable-products-modal",
      animated: true,
      componentProps: {
        products: this.unavailableProductsByCategoryId[category.category_id],
        category: _.cloneDeep(category),
      },
      backdropDismiss: false,
    });

    await this.unavailableProductsModal.present();
    this.unavailableProductsModal.onDidDismiss().then(() => {
      this.unavailableProductsModal = null;
    });
  }

  categoriesTrackFn(index, item) {
    return item.category_id;
  }

  productsTrackFn(index, item) {
    return item.product_id;
  }

  ngOnDestroy() {
    if (this.unavailableProductsModal) {
      this.unavailableProductsModal.dismiss();
      this.unavailableProductsModal = null;
    }

    if (this.gesture) {
      this.gesture.destroy();
      this.gesture = null;
    }
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    this.subscriptions = [];
  }
}
