import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { Subscription, auditTime, distinctUntilChanged } from "rxjs";
import { StoreSchedulePage } from "src/app/store-schedule/store-schedule.page";
import { Store } from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";
import _ from "lodash";
import { Browser } from "@capacitor/browser";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import * as selectors from "../../store/app.selectors";
import { Capacitor } from "@capacitor/core";

@Component({
  selector: "app-group-info",
  templateUrl: "./group-info.component.html",
  styleUrls: ["./group-info.component.scss"],
})
export class GroupInfoComponent implements OnInit, OnDestroy {
  @Input() rootDir: any;
  @Input() selectedLogoFileName: any;

  public groupApp: any;
  public headerLogo: any;
  public storeImage: any;
  public darkHeaderLogo: any;
  public windowDimensions: any;
  public stores: any;
  public storeInfo: any;
  public selectedLanguage: any;

  private subscriptions: Subscription[] = [];
  private unsubscribeBackEvent: Subscription;
  constructor(
    public modalCtrl: ModalController,
    private store: Store<fromApp.AppState>,
    private platform: Platform,
    private dataStorageService: DataStorageService
  ) {}

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.modalCtrl.dismiss();
        }
      }
    );

    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .subscribe((storeInfo) => {
          if (storeInfo && !_.isEqual(this.storeInfo, storeInfo)) {
            this.storeInfo = _.cloneDeep(storeInfo);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && state.stores && !_.isEqual(this.stores, state.stores)) {
            this.stores = _.cloneDeep(state.stores);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(auditTime(50))
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(this.groupApp, state.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
            this.dataStorageService
              .fetchGroupApp(this.groupApp.group)
              .subscribe({
                next: (res: any) => {
                  if (res) {
                    this.storeImage = res.iconImage;
                    this.headerLogo = res.headerLogo;
                    console.log("groupAppHeaderLogo", res.headerLogo);
                    this.darkHeaderLogo = res.headerLogoDark;
                  } else {
                  }
                },
                error: (error) => {
                  return error;
                },
              });
          }

          if (
            state &&
            state.selectedLangugage &&
            !_.isEqual(state.selectedLangugage, this.selectedLanguage)
          ) {
            this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(300))
        .subscribe((state) => {
          if (!_.isEqual(state.windowDimensions, this.windowDimensions)) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
          }
        })
    );
  }

  async openInfo(store) {
    const modal = await this.modalCtrl.create({
      component: StoreSchedulePage,
      animated: true,
      backdropDismiss: false,
      cssClass: "fullScreenModal",
      componentProps: {
        reservationAppStore: store,
      },
    });
    await modal.present();
  }

  openLink = async (lat, lng) => {
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;

    //open link external on new page
    if (Capacitor.isNativePlatform()) {
      window.open(googleMapsUrl, "_system");
    } else {
      window.open(googleMapsUrl, "_blank");
    }
  };

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }

    this.subscriptions = [];

    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
  }
}
