import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "selectedProductPrice",
})
export class SelectedProductPricePipe implements PipeTransform {
  transform(
    selectedProductTotalPrice: any,
    gift: any,
    plasticWrapperTaxProductPrice,
    store_info
  ): any {
    if (
      !selectedProductTotalPrice ||
      gift ||
      selectedProductTotalPrice === "0" ||
      selectedProductTotalPrice === 0 ||
      selectedProductTotalPrice === "0.00"
    ) {
      selectedProductTotalPrice = "";
    } else {
      if (
        plasticWrapperTaxProductPrice &&
        plasticWrapperTaxProductPrice !== "0" &&
        plasticWrapperTaxProductPrice !== "0.00"
      ) {
        selectedProductTotalPrice =
          (
            parseFloat(selectedProductTotalPrice.toString()) +
            parseFloat(plasticWrapperTaxProductPrice.toString())
          ).toFixed(
            store_info && store_info.decimalPlacesPrices
              ? store_info.decimalPlacesPrices
              : 2
          ) + "€";
      } else {
        selectedProductTotalPrice = selectedProductTotalPrice.toString() + "€";
      }
    }

    return selectedProductTotalPrice;
  }
}
