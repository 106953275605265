import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import { Subscription } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import * as fromApp from "../../../store/app.reducer";
import * as selectors from "../../../store/app.selectors";

@Component({
  selector: "app-category-item-mobile",
  templateUrl: "./category-item-mobile.component.html",
  styleUrls: ["./category-item-mobile.component.scss"],
})
export class CategoryItemMobileComponent implements OnInit, OnDestroy {
  @Input() category_id;
  @Input() groupStoreData;
  public category: any;
  public selectedCategoryImageAnimation;
  public categoryImageAnimation;
  public categoryHasAnimation: boolean;

  private selectedCategoryId;
  private selectedScrollCategoryId;
  private clearCategoryAnimationImgTimeout;
  private clearCategoryAnimationImgTimeout2;
  private subscription: Subscription;
  private verticalCategoriesSub: Subscription;

  constructor(private store: Store<fromApp.AppState>) {}

  ngOnInit() {
    this.subscription = this.store
      .select(selectors.getCategoryByCategoryId(this.category_id))
      .pipe(distinctUntilChanged())

      .subscribe((category) => {
        if (category && !_.isEqual(category, this.category)) {
          this.category = _.cloneDeep(category);

          this.selectedCategoryImageAnimation = _.find(
            _.cloneDeep(this.category.catalogCategoryAnimationImages),
            {
              position: "mobileCategory",
            }
          );

          if (this.category && this.category.catalogCategoryAnimationImages) {
            this.categoryHasAnimation = !!_.find(
              this.category.catalogCategoryAnimationImages,
              {
                position: "mobileCategory",
              }
            );
          }

          //   if (
          //     this.category &&
          //     this.category.catalogCategoryAnimationImages &&
          //     this.category.catalogCategoryAnimationImages.length &&
          //     _.find(this.category.catalogCategoryAnimationImages, {
          //       position: "mobileCategory",
          //     }) &&
          //     !this.verticalCategoriesSub
          //   ) {
          //     this.subscribeToVerticalCategories();
          //   }
        }
      });
  }

  subscribeToVerticalCategories() {
    this.verticalCategoriesSub = this.store
      .select("verticalCategory")
      .pipe(distinctUntilChanged())

      .subscribe(async (state) => {
        if (
          state &&
          state.selectedCategoryId &&
          !state.selectedScrollCategoryId &&
          !_.isEqual(state.selectedCategoryId, this.selectedCategoryId)
        ) {
          this.selectedCategoryId = _.cloneDeep(state.selectedCategoryId);

          // if (this.selectedCategoryId === this.category.category_id) {
          //   this.handleCategoriesAnimationImg(_.cloneDeep(this.category));
          // }
        }

        if (
          state &&
          state.selectedScrollCategoryId &&
          !state.selectedCategoryId &&
          !_.isEqual(
            state.selectedScrollCategoryId,
            this.selectedScrollCategoryId
          )
        ) {
          this.selectedScrollCategoryId = _.cloneDeep(
            state.selectedScrollCategoryId
          );
          // if (this.selectedScrollCategoryId === this.category.category_id) {
          //   this.handleCategoriesAnimationImg(_.cloneDeep(this.category));
          // }
        }
      });
  }

  // handleCategoriesAnimationImg(selectedCat) {
  //   if (
  //     selectedCat &&
  //     selectedCat.catalogCategoryAnimationImages &&
  //     selectedCat.catalogCategoryAnimationImages.length &&
  //     _.find(selectedCat.catalogCategoryAnimationImages, {
  //       position: "mobileCategory",
  //     })
  //   ) {
  //     const browserLeftAnimation = _.find(
  //       selectedCat.catalogCategoryAnimationImages,
  //       {
  //         position: "mobileCategory",
  //       }
  //     );

  //     this.clearCategoryAnimationImgTimeout2 = setTimeout(() => {
  //       this.categoryImageAnimation = true;
  //       this.selectedCategoryImageAnimation = _.cloneDeep(browserLeftAnimation);
  //     }, 200);
  //   } else {
  //     if (this.clearCategoryAnimationImgTimeout2) {
  //       clearTimeout(this.clearCategoryAnimationImgTimeout2);
  //       this.clearCategoryAnimationImgTimeout2 = null;
  //     }
  //     this.selectedCategoryImageAnimation = null;
  //     this.categoryImageAnimation = false;
  //   }
  // }

  //TO_DO CHECK TRACK FUNCTION TO RETRUN ITEM ATTR
  trackFunc(index, item) {
    return index;
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.verticalCategoriesSub) {
      this.verticalCategoriesSub.unsubscribe();
    }
  }
}
