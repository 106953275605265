import { Component, Input, OnInit } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import * as _ from "lodash";

@Component({
  selector: "app-change-quantity-input-popover",
  templateUrl: "./change-quantity-input-popover.component.html",
  styleUrls: ["./change-quantity-input-popover.component.scss"],
})
export class ChangeQuantityInputPopoverComponent implements OnInit {
  @Input() quantityValue;
  @Input() choiceStep: any;
  constructor(private popoverController: PopoverController) {}

  ngOnInit() {
    console.log("ste step ", this.choiceStep);
    if (!this.quantityValue) {
      this.quantityValue = 1;
    }
  }

  clickCancel() {
    this.popoverController.dismiss();
  }

  clickOk() {
    this.popoverController.dismiss(_.cloneDeep(this.quantityValue));
  }
}
