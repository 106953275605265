<div
  *ngIf="categories && storeInfo"
  id="scrollVerticalCategories"
  scrollY="true"
  [class]="hyperCategories && hyperCategories.length > 0 ? '' : 'scrollHeight'"
>
  <ion-list
    id="verticalCategoriesList"
    [class]="
      hyperCategories && hyperCategories.length > 0
        ? 'ion-no-margin ion-no-padding'
        : ''
    "
  >
    <span *ngFor="let cat of categories; trackBy: trackFn">
      <ion-item
        detail="false"
        id="{{ 'verticalCategoriesMenu_' + cat.category_id }}"
        button
        lines="none"
        detail="false"
        *ngIf="cat.activeTotal"
        [ngClass]="{
          buttonBold:
            cat.category_id === scrollCategoryId ||
            cat.category_id === verticalCategoryId
        }"
        (click)="categorySelection(cat)"
      >
        <ion-icon
          *ngIf="icons_categories_design && cat.mobileorder_category_icon"
          [color]="
            cat.category_id === scrollCategoryId ||
            cat.category_id === verticalCategoryId
              ? 'selected'
              : 'verticalCategories'
          "
          style="
            font-size: 30px;
            margin-right: 10px;
            margin-bottom: 5px;
            margin-top: 5px;
          "
          slot="start"
          [src]="categoryIconLink + cat.mobileorder_category_icon"
        >
        </ion-icon>
        <ion-label
          style="font-size: large"
          [class]="
            hyperCategories && hyperCategories.length > 0
              ? 'ion-no-padding ion-no-margin fontSizeHyperCategories'
              : 'ion-no-padding ion-no-margin'
          "
          ><ion-text
            [color]="
              cat.category_id === scrollCategoryId ||
              cat.category_id === verticalCategoryId
                ? 'selected'
                : 'verticalCategories'
            "
            >{{ cat.name }}</ion-text
          ></ion-label
        ></ion-item
      >
    </span>
  </ion-list>
</div>
