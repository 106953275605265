<ion-header>
  <ion-toolbar>
    <ion-title>{{'loyaltySystem.loyalty-more'| translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<!-- <ion-grid *ngIf="!currentUser">
  <ion-row style="width: 100%">
    <ion-col class="ion-text-center">
      <ion-spinner></ion-spinner>
    </ion-col>
  </ion-row>
</ion-grid> -->
<ion-content *ngIf="currentUser">
  <ion-grid [ngStyle]="{'width': bigScreenStyle ? '50%': ''}">
    <ion-list>
      <!-- <ion-item
        button
        detail="true"
        (click)="openPurchaseHistory()"
        lines="full"
      >
        <ion-icon
          src="/assets/flatIcons/purchase-history.svg"
          style="font-size: xx-large; margin-right: 15px"
        ></ion-icon>
        <ion-label>
          {{'loyaltySystem.loyalty-more-page.purchase-history' | translate}}
        </ion-label>
      </ion-item> -->
      <ion-item button
                detail="true"
                lines="full"
                (click)="pageChange('points')">
        <ion-icon src="/assets/flatIcons/points.svg"
                  style="font-size: xx-large; margin-right: 15px"></ion-icon>
        <ion-label>
          {{'loyaltySystem.loyalty-more-page.points' | translate}}
        </ion-label>
      </ion-item>
      <ion-item button
                detail="true"
                lines="full"
                (click)="pageChange('account-settings')">
        <ion-icon src="/assets/flatIcons/account.svg"
                  style="font-size: xx-large; margin-right: 15px"></ion-icon>
        <ion-label>
          {{'loyaltySystem.loyalty-more-page.my-account' | translate}}
        </ion-label>
      </ion-item>
      <ion-item *ngIf="activeloyaltyProductRules"
                button
                detail="true"
                lines="full"
                (click)="openHallOfFameModal()">
        <ion-icon src="/assets/flatIcons/ranking.svg"
                  style="font-size: xx-large; margin-right: 15px"></ion-icon>
        <ion-label>
          {{'loyaltySystem.loyalty-more-page.hof' | translate}}
        </ion-label>
      </ion-item>
      <ion-item *ngIf="!currentUser?.migration_points_taken"
                button
                detail="true"
                lines="full"
                (click)="openMigratePointsModal()">
        <ion-icon src="/assets/flatIcons/transfer.svg"
                  style="font-size: xx-large; margin-right: 15px"></ion-icon>
        <ion-label>
          {{'loyaltySystem.loyalty-more-page.migrate-points' | translate}}
        </ion-label>
      </ion-item>
      <ion-item button
                detail="true"
                lines="full"
                (click)="openStoreSelectionModal()">
        <ion-icon src="/assets/ionicons/store.svg"
                  style="font-size: xx-large; margin-right: 15px"></ion-icon>
        <ion-label>
          {{'loyaltySystem.loyalty-more-page.store-info' | translate}}
        </ion-label>
      </ion-item>
    </ion-list>
  </ion-grid>
</ion-content>
<ion-grid *ngIf="!currentUser && loginState && loginState !== 'login_loading'">
  <ion-row class="ion-justify-content-center ion-align-items-center"
           style="height: 100%">
    <ion-col>
      <ion-row>
        <ion-col class="ion-text-center">
          {{'loyaltySystem.login-for-more-options' | translate}}
        </ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-center ion-align-items-center">
        <ion-col class="ion-text-center">
          <div style="max-width: 180px; display: inline-block">
            <ng-lottie (animationCreated)="morePageAnimationCreated($event)"
                       [options]="morePageAnimationOptions"></ng-lottie>
          </div>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="!currentUser && loginState && loginState==='no_login'">
        <ion-col class="ion-text-center">
          <ion-button [disabled]="loginState === 'login_loading'"
                      *ngIf="!currentUser && loginState && loginState ==='no_login' && groupApp"
                      shape="round"
                      class="floating-card-button"
                      (click)="presentMagicLoginPopup()">
            <ion-icon name="log-in-outline"></ion-icon>
            <ion-text style="margin-left: 10px; text-transform: uppercase">
              Σύνδεση</ion-text>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-col>
  </ion-row>
</ion-grid>