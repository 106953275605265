import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { IonSlides, ModalController } from "@ionic/angular";
import { from, Observable, Subscription } from "rxjs";
import * as _ from "lodash";
import { auditTime, distinctUntilChanged } from "rxjs/operators";
import * as fromApp from "../../../store/app.reducer";
import * as selectors from "../../../store/app.selectors";
import { Store } from "@ngrx/store";
import { ProductDetailsComponent } from "src/app/product-details/product-details.component";
declare let window: any;

@Component({
  selector: "app-product-items-cards-category-mobile",
  templateUrl: "./product-items-cards-category-mobile.component.html",
  styleUrls: ["./product-items-cards-category-mobile.component.scss"],
})
export class ProductItemsCardsCategoryMobileComponent
  implements OnInit, OnDestroy
{
  @Input() category_id: any;
  @Input() groupStoreData;
  @Input() selectedDineInTable;
  @Input() selectedPreorderDate;
  @ViewChild("productTitle") productTitleRef: ElementRef;
  public category: any;
  public browserPlatform: any;
  public orderPickup: any;
  public dineIn: any;
  public groupApp: any;
  public webp: any;
  public colSize = "6";
  public storeInfo: any;
  public windowDimensions: any;
  public selectedCategoryId;
  private subscriptions: Subscription[] = [];
  private productDetailsModal: any;

  constructor(
    private store: Store<fromApp.AppState>,
    private modalCtrl: ModalController,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select("verticalCategory")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.selectedCategoryId, state.selectedCategoryId)
          ) {
            this.selectedCategoryId = _.cloneDeep(state.selectedCategoryId);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .subscribe((storeInfo) => {
          if (storeInfo && !_.isEqual(storeInfo, this.storeInfo)) {
            this.storeInfo = _.cloneDeep(storeInfo);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select(selectors.getCategoryByCategoryId(this.category_id))
        .pipe(distinctUntilChanged())

        .subscribe((category) => {
          if (category && !_.isEqual(category, this.category)) {
            let tempCategory = _.cloneDeep(category);
            _.each(tempCategory.products, (product) => {
              if (
                product &&
                (!product.availableTotal ||
                  !product.product_categories_availability_check)
              ) {
                product.tags = [];
              } else {
                if (product.tags) {
                  let keys = _.keys(product.tags);

                  const fistKey = keys[0].toString();

                  if (fistKey) {
                    product.tags = {};
                    product.tags[fistKey] = true;
                  }
                }
              }
            });
            this.category = _.cloneDeep(tempCategory);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("deliveryMethodAddress")

        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && !_.isEqual(this.dineIn, state.dineIn)) {
            this.dineIn = _.cloneDeep(state.dineIn);
          }
          if (state && !_.isEqual(this.orderPickup, state.orderPickup)) {
            this.orderPickup = _.cloneDeep(state.orderPickup);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("windowInfo")

        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.windowDimensions, state.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
            this.calculateColSize();
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("platformInfo")
        .pipe(auditTime(200))
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.browserPlatform, state.browserPlatform)
          ) {
            this.browserPlatform = _.cloneDeep(state.browserPlatform);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(auditTime(200))
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(this.groupApp, state.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
          }
          if (state && state.webp && !_.isEqual(this.webp, state.webp)) {
            this.webp = _.cloneDeep(state.webp);
          }
        })
    );
  }

  calculateColSize() {
    if (this.windowDimensions && this.windowDimensions.width) {
      if (this.windowDimensions.width < 340) {
        this.colSize = "12";
      } else if (
        this.windowDimensions.width >= 341 &&
        this.windowDimensions.width < 480
      ) {
        this.colSize = "6";
      } else if (
        this.windowDimensions.width >= 480 &&
        this.windowDimensions.width < 650
      ) {
        this.colSize = "4";
      } else if (
        this.windowDimensions.width >= 650 &&
        this.windowDimensions.width < 850
      ) {
        this.colSize = "3";
      } else if (
        this.windowDimensions.width >= 850 &&
        this.windowDimensions.width < 950
      ) {
        this.colSize = "4";
      } else if (
        this.windowDimensions.width >= 950 &&
        this.windowDimensions.width <= 1200
      ) {
        this.colSize = "4";
      }
    }
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  //TO_DO CHECK THIS TRACKBY FOR RETURN ITEM ATTR
  trackFunc(index, item) {
    return index;
  }

  trackProducts(index, item) {
    return item.product_id;
  }

  prdWithoutImage(product) {
    return (
      !product.image ||
      (product.image && !product.image.true) ||
      (product.image && !product.image.true["16x9"]) ||
      (product.image &&
        product.image.true["16x9"] &&
        !product.image.true["16x9"].path)
    );
  }

  showProductDetailsModal(product) {
    let detailsCssClass;
    if (
      product &&
      product.num_of_options &&
      product.num_of_options < 2 &&
      this.prdWithoutImage(product)
    ) {
      detailsCssClass = "productDetailsModalSmall";
    } else if (
      product &&
      product.num_of_options &&
      !product.squareImageDesign &&
      product.num_of_options > 2 &&
      !this.prdWithoutImage(product)
    ) {
      detailsCssClass = "productDetailsModalBig";
    } else if (
      product &&
      product.squareImageDesign &&
      product.num_of_options &&
      product.num_of_options > 2 &&
      this.windowDimensions &&
      this.windowDimensions.width >= 1200 &&
      !this.prdWithoutImage(product)
    ) {
      detailsCssClass = "productDetailsModalBigSquareImageDesign";
    } else {
      detailsCssClass = "productDetailsModal";
    }
    if (!this.productDetailsModal) {
      this.productDetailsModal = true;
      this.presentProductDetailsModal(product, detailsCssClass);
    }
  }

  async presentProductDetailsModal(product, detailsCssClass) {
    let product_id: string;
    let cart_id: string;
    if (product && product.product_id) {
      product_id = product.product_id;
    }

    if (product && product.cart_id) {
      cart_id = product.product_id;
    }

    this.productDetailsModal = await this.modalCtrl.create({
      component: ProductDetailsComponent,
      cssClass: detailsCssClass,
      animated: true,
      componentProps: {
        product_id: product_id,
        cart_id: cart_id,
      },
      backdropDismiss: false,
    });

    await this.productDetailsModal.present();
    this.productDetailsModal.onDidDismiss().then(() => {
      this.productDetailsModal = null;
    });
  }

  ngOnDestroy() {
    if (this.productDetailsModal) {
      this.productDetailsModal.dismiss();
      this.productDetailsModal = null;
    }
    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }
}
