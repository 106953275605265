<span
  *ngIf="
    option &&
    option.choices &&
    option.choices.length &&
    !option.always_split_columns
  "
>
  <div *ngFor="let choice of option.choices; trackBy: trackFn">
    <app-quantity-checkbox-choice-with-measurements
      *ngIf="
        option.quantityInChoices &&
        !option.inputQuantityInChoices &&
        option.showSumChoicesWeight &&
        !checkForImage(choice)
      "
      [option_id]="option.option_id"
      [choice_id]="choice.choice_id"
      [bundleMode]="bundleMode"
      [selectedProductId]="selectedProductId"
      [groupStoreData]="groupStoreData"
      (choiceChanged)="choiceChanged($event)"
    ></app-quantity-checkbox-choice-with-measurements>
    <app-quantity-checkbox-choice
      *ngIf="
        option.quantityInChoices &&
        !option.inputQuantityInChoices &&
        !option.showSumChoicesWeight &&
        !checkForImage(choice)
      "
      [option_id]="option.option_id"
      [choice_id]="choice.choice_id"
      [bundleMode]="bundleMode"
      [selectedProductId]="selectedProductId"
      [alt_dinein_active]="alt_dinein_active"
      [groupStoreData]="groupStoreData"
      (choiceChanged)="choiceChanged($event)"
    ></app-quantity-checkbox-choice>
    <app-quantity-checkbox-choices-with-input
      *ngIf="
        option.quantityInChoices &&
        !option.showSumChoicesWeight &&
        option.inputQuantityInChoices &&
        !checkForImage(choice)
      "
      [option_id]="option.option_id"
      [choice_id]="choice.choice_id"
      [bundleMode]="bundleMode"
      [selectedProductId]="selectedProductId"
      [alt_dinein_active]="alt_dinein_active"
      [groupStoreData]="groupStoreData"
      (choiceChanged)="choiceChanged($event)"
    ></app-quantity-checkbox-choices-with-input>
    <app-no-quantity-checkbox-choice
      *ngIf="!option.quantityInChoices && !checkForImage(choice)"
      [option_id]="option.option_id"
      [choice_id]="choice.choice_id"
      [bundleMode]="bundleMode"
      [selectedProductId]="selectedProductId"
      [alt_dinein_active]="alt_dinein_active"
      [groupStoreData]="groupStoreData"
      (choiceChanged)="choiceChanged($event)"
    ></app-no-quantity-checkbox-choice>

    <app-quantity-image-checkbox-choice
      *ngIf="
        option.quantityInChoices &&
        !option.showSumChoicesWeight &&
        !option.inputQuantityInChoices &&
        checkForImage(choice)
      "
      [option_id]="option.option_id"
      [choice_id]="choice.choice_id"
      [bundleMode]="bundleMode"
      [selectedProductId]="selectedProductId"
      [alt_dinein_active]="alt_dinein_active"
      [imagePath]="imagePath"
      [groupStoreData]="groupStoreData"
      (choiceChanged)="choiceChanged($event)"
    ></app-quantity-image-checkbox-choice>
    <app-quantity-checkbox-choice-with-measurements-image
      *ngIf="
        option.quantityInChoices &&
        option.showSumChoicesWeight &&
        !option.inputQuantityInChoices &&
        checkForImage(choice)
      "
      [option_id]="option.option_id"
      [choice_id]="choice.choice_id"
      [bundleMode]="bundleMode"
      [selectedProductId]="selectedProductId"
      [imagePath]="imagePath"
      [groupStoreData]="groupStoreData"
      (choiceChanged)="choiceChanged($event)"
    ></app-quantity-checkbox-choice-with-measurements-image>
    <app-quantity-checkbox-choices-with-input-image
      *ngIf="
        option.quantityInChoices &&
        !option.showSumChoicesWeight &&
        option.inputQuantityInChoices &&
        checkForImage(choice)
      "
      [option_id]="option.option_id"
      [choice_id]="choice.choice_id"
      [bundleMode]="bundleMode"
      [selectedProductId]="selectedProductId"
      [alt_dinein_active]="alt_dinein_active"
      [imagePath]="imagePath"
      [groupStoreData]="groupStoreData"
      (choiceChanged)="choiceChanged($event)"
    ></app-quantity-checkbox-choices-with-input-image>

    <app-no-quantity-image-checkbox-choice
      *ngIf="!option.quantityInChoices && checkForImage(choice)"
      [option_id]="option.option_id"
      [choice_id]="choice.choice_id"
      [bundleMode]="bundleMode"
      [selectedProductId]="selectedProductId"
      [alt_dinein_active]="alt_dinein_active"
      [imagePath]="imagePath"
      [groupStoreData]="groupStoreData"
      (choiceChanged)="choiceChanged($event)"
    ></app-no-quantity-image-checkbox-choice>
  </div>
</span>
<ion-row
  class="ion-no-padding ion-no-margin"
  class="ion-align-items-center"
  *ngIf="
    option &&
    option.choices &&
    option.choices.length &&
    option.always_split_columns
  "
>
  <ion-col
    class="ion-no-padding ion-no-margin ion-text-center"
    [size]="colSize"
    *ngFor="let choice of option.choices; trackBy: trackFn"
  >
    <app-quantity-checkbox-choice-with-measurements
      *ngIf="
        option.quantityInChoices &&
        option.showSumChoicesWeight &&
        !option.inputQuantityInChoices &&
        !checkForImage(choice)
      "
      [option_id]="option.option_id"
      [choice_id]="choice.choice_id"
      [bundleMode]="bundleMode"
      [selectedProductPromo]="selectedProductPromo"
      [selectedProductCategory]="selectedProductCategory"
      [selectedProductId]="selectedProductId"
      [groupStoreData]="groupStoreData"
      (choiceChanged)="choiceChanged($event)"
    ></app-quantity-checkbox-choice-with-measurements>
    <app-quantity-checkbox-choices-with-input
      *ngIf="
        option.quantityInChoices &&
        !option.showSumChoicesWeight &&
        option.inputQuantityInChoices &&
        !checkForImage(choice)
      "
      [option_id]="option.option_id"
      [choice_id]="choice.choice_id"
      [bundleMode]="bundleMode"
      [selectedProductPromo]="selectedProductPromo"
      [selectedProductCategory]="selectedProductCategory"
      [alt_dinein_active]="alt_dinein_active"
      [selectedProductId]="selectedProductId"
      [groupStoreData]="groupStoreData"
      (choiceChanged)="choiceChanged($event)"
    ></app-quantity-checkbox-choices-with-input>

    <app-quantity-checkbox-choice
      *ngIf="
        option.quantityInChoices &&
        !option.showSumChoicesWeight &&
        !option.inputQuantityInChoices &&
        !checkForImage(choice)
      "
      [option_id]="option.option_id"
      [choice_id]="choice.choice_id"
      [bundleMode]="bundleMode"
      [selectedProductPromo]="selectedProductPromo"
      [selectedProductCategory]="selectedProductCategory"
      [alt_dinein_active]="alt_dinein_active"
      [selectedProductId]="selectedProductId"
      [groupStoreData]="groupStoreData"
      (choiceChanged)="choiceChanged($event)"
    ></app-quantity-checkbox-choice>
    <app-no-quantity-checkbox-choice
      *ngIf="!option.quantityInChoices && !checkForImage(choice)"
      [option_id]="option.option_id"
      [choice_id]="choice.choice_id"
      [bundleMode]="bundleMode"
      [selectedProductPromo]="selectedProductPromo"
      [selectedProductCategory]="selectedProductCategory"
      [alt_dinein_active]="alt_dinein_active"
      [selectedProductId]="selectedProductId"
      [groupStoreData]="groupStoreData"
      (choiceChanged)="choiceChanged($event)"
    ></app-no-quantity-checkbox-choice>
    <app-quantity-image-checkbox-choice
      *ngIf="
        option.quantityInChoices &&
        !option.showSumChoicesWeight &&
        checkForImage(choice)
      "
      [option_id]="option.option_id"
      [choice_id]="choice.choice_id"
      [bundleMode]="bundleMode"
      [imagePath]="imagePath"
      [selectedProductPromo]="selectedProductPromo"
      [selectedProductId]="selectedProductId"
      [selectedProductCategory]="selectedProductCategory"
      [alt_dinein_active]="alt_dinein_active"
      [groupStoreData]="groupStoreData"
      (choiceChanged)="choiceChanged($event)"
    ></app-quantity-image-checkbox-choice>
    <app-no-quantity-image-checkbox-choice
      *ngIf="!option.quantityInChoices && checkForImage(choice)"
      [option_id]="option.option_id"
      [choice_id]="choice.choice_id"
      [bundleMode]="bundleMode"
      [imagePath]="imagePath"
      [selectedProductPromo]="selectedProductPromo"
      [selectedProductCategory]="selectedProductCategory"
      [alt_dinein_active]="alt_dinein_active"
      [selectedProductId]="selectedProductId"
      [showChoicePrices]="showChoicePrices"
      [groupStoreData]="groupStoreData"
      (choiceChanged)="choiceChanged($event)"
    ></app-no-quantity-image-checkbox-choice>

    <app-quantity-checkbox-choice-with-measurements-image
      *ngIf="
        option.quantityInChoices &&
        option.showSumChoicesWeight &&
        !option.inputQuantityInChoices &&
        checkForImage(choice)
      "
      [option_id]="option.option_id"
      [choice_id]="choice.choice_id"
      [bundleMode]="bundleMode"
      [selectedProductId]="selectedProductId"
      [imagePath]="imagePath"
      [groupStoreData]="groupStoreData"
      (choiceChanged)="choiceChanged($event)"
    ></app-quantity-checkbox-choice-with-measurements-image>
    <app-quantity-checkbox-choices-with-input-image
      *ngIf="
        option.quantityInChoices &&
        !option.showSumChoicesWeight &&
        option.inputQuantityInChoices &&
        checkForImage(choice)
      "
      [option_id]="option.option_id"
      [choice_id]="choice.choice_id"
      [bundleMode]="bundleMode"
      [selectedProductId]="selectedProductId"
      [alt_dinein_active]="alt_dinein_active"
      [imagePath]="imagePath"
      [groupStoreData]="groupStoreData"
      (choiceChanged)="choiceChanged($event)"
    ></app-quantity-checkbox-choices-with-input-image>
  </ion-col>
</ion-row>
