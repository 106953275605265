<ion-item *ngIf="category && storeInfo" class="categoryItem">
  <ion-row style="width: 100%">
    <ion-col size="12" class="noPaddingCols">
      <strong style="font-size: larger"
        ><ion-text color="primary">{{ category.name }}</ion-text>
      </strong>
    </ion-col>

    <ion-col
      size="12"
      class="noPaddingCols"
      *ngIf="!category.availableTotal || category.avpc"
    >
      <app-category-item-tags
        [categoryItem]="category"
        [groupStoreData]="groupStoreData"
      ></app-category-item-tags>
      <!-- {{ "catalog.categories.category-item.unavailable-category" | translate }} -->
    </ion-col>

    <ion-col size="12" class="DescriptionPaddings" *ngIf="category.description">
      <app-description [description]="category.description"></app-description>
    </ion-col>
    <ion-col
      class="noPaddingCols"
      size="12"
      *ngIf="category.promotions && category.promotions.length > 0"
    >
      <div
        *ngFor="let promo of category.promotions"
        class="categoriesWarningColor"
      >
        <span style="font-weight: bold; font-size: small">{{
          promo.description
        }}</span>
      </div>
    </ion-col>
  </ion-row>
</ion-item>
<ion-grid *ngIf="storeInfo" [ngStyle]="{ 'max-width.px': gridMaxWidth }">
  <ion-list>
    <ion-row
      style="width: 100%"
      *ngIf="category && category.activeTotal && storeInfo"
    >
      <ion-col
        class="ion-text-center"
        [size]="colSize"
        *ngFor="
          let product of (storeInfo &&
          storeInfo.design_settings &&
          storeInfo.design_settings.showOnlySelectedCategoryProducts
            ? filteredScrollProducts
            : category.products
          )
            | filterAvailableProducts : storeInfo.showAllUnavailableProducts
            | filterOnlyDineInDeliveryPickupProducts : orderPickup : dineIn;
          trackBy: productTrack
        "
      >
        <app-product-item-market-type-card-mobile
          [category]="category"
          [product]="product"
          [webp]="webp"
          [orderPickup]="orderPickup"
          [selectedDineInTable]="selectedDineInTable"
          [selectedPreorderDate]="selectedPreorderDate"
          [groupStoreData]="groupStoreData"
          [productIncludedInCart]="product.productIncludedInCart"
          [finalPriceLoading]="finalPriceLoading"
          [dineIn]="dineIn"
          [cartProductIds]="cartProductIds"
        ></app-product-item-market-type-card-mobile>
      </ion-col>
    </ion-row>
  </ion-list>

  <ion-row
    style="width: 100%"
    *ngIf="
      storeInfo &&
      storeInfo.design_settings &&
      storeInfo.design_settings.showOnlySelectedCategoryProducts &&
      category &&
      category.products &&
      filteredScrollProducts &&
      filteredScrollProducts.length !== category.products.length
    "
  >
    <ion-col class="ion-text-center">
      <ion-spinner name="bubbles"></ion-spinner>
    </ion-col>
  </ion-row>
</ion-grid>
