import { Pipe, PipeTransform } from "@angular/core";
import * as _ from "lodash";

@Pipe({
  name: "filterShowInDescriptionObjectChoices",
})
export class FilterShowInDescriptionObjectChoicesPipe implements PipeTransform {
  transform(choices: any): any {
    if (choices && choices.length) {
      return _.take(choices, 3);
    } else {
      return choices;
    }
  }
}
