<div style="position: relative; width: 100%">
  <ion-item
    *ngIf="product"
    lines="none"
    detail="false"
    button
    (click)="showProductDetailsModal(product)"
    [class]="productInCart ? 'added_to_cart_border' : ''"
  >
    <div
      style="
        position: absolute;
        top: 2px;
        left: 0px;
        z-index: 10000;
        font-size: small;
        font-weight: bold;
      "
      *ngIf="productInCartCounter && productInCartCounter > 1"
    >
      <ion-text color="primary"> x{{ productInCartCounter }}</ion-text>
    </div>
    <ion-row class="ion-padding" style="width: 100%">
      <ion-col
        [ngClass]="{
          'unavailable categoriesBrowserItemUnavailable': !!(
            !product.availableTotal ||
            !product.product_categories_availability_check
          ),
          categoriesBrowserItemAvailable: product.availableTotal
        }"
        size="12"
        style="line-height: initial; padding-left: 0px"
      >
        <span
          color="dark"
          style="font-weight: bold"
          [ngClass]="{
            'line-through': !!(
              !product.availableTotal ||
              !product.product_categories_availability_check
            )
          }"
        >
          {{ product.name }}
        </span>
      </ion-col>

      <ion-col
        size="12"
        *ngIf="product.description || product.showOptionInDescription"
        style="line-height: initial; padding-left: 0px"
      >
        <span
          [ngClass]="{
            'unavailable line-through ': !!(
              !product.availableTotal ||
              !product.product_categories_availability_check
            )
          }"
          class="fontSizeSmaller"
        >
          <app-description
            *ngIf="
              (orderPickup == null && dineIn == null) ||
              (orderPickup == false && dineIn == false) ||
              (orderPickup == true &&
                dineIn == false &&
                !product.showOptionInDescription_takeaway) ||
              (orderPickup == false &&
                dineIn == true &&
                !product.showOptionInDescription_dinein)
            "
            [show_option_description]="product.showOptionInDescription"
            [description]="product.description"
            [imageBrowser]="true"
          >
          </app-description>

          <app-description
            *ngIf="
              orderPickup == true &&
              dineIn == false &&
              product.showOptionInDescription_takeaway
            "
            [show_option_description]="product.showOptionInDescription_takeaway"
            [description]="product.description"
            [imageBrowser]="true"
          >
          </app-description>

          <app-description
            *ngIf="
              orderPickup == false &&
              dineIn == true &&
              product.showOptionInDescription_dinein
            "
            [show_option_description]="product.showOptionInDescription_dinein"
            [description]="product.description"
            [imageBrowser]="true"
          >
          </app-description>
        </span>
      </ion-col>

      <app-product-show-option-in-description-object
        [product]="product"
        [dineIn]="dineIn"
        [orderPickup]="orderPickup"
        [store_info]="groupStoreData.store_info"
        [selectedDineInTable]="selectedDineInTable"
      ></app-product-show-option-in-description-object>
      <!-- 
    <ion-col
      style="padding-left: 0px"
      size="12"
      *ngIf="product.showOptionInDescription"
      class="fontSizeSmaller"
      [ngClass]="{
        'line-through unavailable': !!(
          !product.availableTotal || !product.product_categories_availability_check
        )
      }"
    >
      <p
        *ngFor="
          let description of product.showOptionInDescription;
          let i = index;
          trackBy: trackFc
        "
      >
        {{ description }}
      </p>
    </ion-col> -->
      <ion-col
        *ngIf="product.promotions && product.promotions.length > 0"
        [ngClass]="{
          'line-through unavailable': !!(
            !product.availableTotal ||
            !product.product_categories_availability_check
          )
        }"
        class="categoriesWarningColor"
        style="font-weight: bold; padding-left: 0px"
      >
        <p
          *ngFor="
            let promo of product.promotions;
            let i = index;
            trackBy: trackFc
          "
        >
          {{ promo.description }}
        </p>
      </ion-col>

      <ion-col
        *ngIf="
          (orderPickup == false && dineIn == false) ||
          (orderPickup == null && dineIn == null)
        "
        style="padding-left: 0px"
        class="pricePaddings"
        size="12"
        [ngClass]="{
          priceWidthUnavailable: !!product.priceWidthUnavailable,
          priceWidthAvailable: !!product.priceWidthAvailable
        }"
      >
        <span
          style="padding-right: 3px; font-size: smaller"
          *ngIf="product.initial_price"
        >
          <span class="line-through">
            {{ product.initial_price
            }}{{
              product.enable_quantity_step &&
              product.quantity_step &&
              product.quantity_step_kind
                ? "/" + product.quantity_step_kind
                : ""
            }}
          </span>
        </span>
        <span
          [ngClass]="{
            'lowerFromTop unavailable line-through': !!(
              !product.availableTotal ||
              !product.product_categories_availability_check
            )
          }"
        >
          <ion-text
            *ngIf="product.price"
            color="primary"
            style="font-weight: 700; vertical-align: middle"
            >{{ product.price
            }}{{
              product.enable_quantity_step &&
              product.quantity_step &&
              product.quantity_step_kind
                ? "/" + product.quantity_step_kind
                : ""
            }}
          </ion-text>

          <ion-text *ngIf="product.price && product.points_to_earn">
            <span> | </span>
            <span class="points-to-earn">
              {{ product.points_to_earn }}
              {{ "points-translation" | translate }}
            </span>
          </ion-text>

          <ion-text
            *ngIf="!product.price && product.virtual_price"
            color="primary"
            style="font-weight: 700"
            >{{ product.virtual_price
            }}{{
              product.enable_quantity_step &&
              product.quantity_step &&
              product.quantity_step_kind
                ? "/" + product.quantity_step_kind
                : ""
            }}</ion-text
          >
        </span>
      </ion-col>

      <ion-col
        *ngIf="orderPickup == true && dineIn == false"
        style="padding-left: 0px"
        class="pricePaddings"
        size="12"
        [ngClass]="{
          priceWidthUnavailable: !!product.priceWidthUnavailable,
          priceWidthAvailable: !!product.priceWidthAvailable
        }"
      >
        <span
          style="padding-right: 3px; font-size: smaller"
          *ngIf="product.initial_price && !product.price_takeaway"
        >
          <span class="line-through">
            {{ product.initial_price
            }}{{
              product.enable_quantity_step &&
              product.quantity_step &&
              product.quantity_step_kind
                ? "/" + product.quantity_step_kind
                : ""
            }}
          </span>
        </span>
        <span
          style="padding-right: 3px; font-size: smaller"
          *ngIf="product.initial_price_takeaway && product.price_takeaway"
        >
          <span class="line-through">
            {{ product.initial_price_takeaway
            }}{{
              product.enable_quantity_step &&
              product.quantity_step &&
              product.quantity_step_kind
                ? "/" + product.quantity_step_kind
                : ""
            }}
          </span>
        </span>
        <span
          [ngClass]="{
            'lowerFromTop unavailable line-through': !!(
              !product.availableTotal ||
              !product.product_categories_availability_check
            )
          }"
        >
          <ion-text
            *ngIf="product.price_takeaway"
            color="primary"
            style="font-weight: 700"
            >{{ product.price_takeaway
            }}{{
              product.enable_quantity_step &&
              product.quantity_step &&
              product.quantity_step_kind
                ? "/" + product.quantity_step_kind
                : ""
            }}
          </ion-text>

          <ion-text
            *ngIf="product.price_takeaway && product.points_to_earn_takeaway"
          >
            <span> | </span>
            <span class="points-to-earn">
              {{ product.points_to_earn_takeaway }}
              {{ "points-translation" | translate }}
            </span>
          </ion-text>

          <ion-text
            *ngIf="!product.price_takeaway && product.virtual_price_takeaway"
            color="primary"
            style="font-weight: 700"
            >{{ product.virtual_price_takeaway
            }}{{
              product.enable_quantity_step &&
              product.quantity_step &&
              product.quantity_step_kind
                ? "/" + product.quantity_step_kind
                : ""
            }}</ion-text
          >

          <ion-text
            *ngIf="!product.price_takeaway && !product.virtual_price_takeaway"
            color="primary"
            style="font-weight: 700"
            >{{ product.price ? product.price : product.virtual_price
            }}{{
              product.enable_quantity_step &&
              product.quantity_step &&
              product.quantity_step_kind
                ? "/" + product.quantity_step_kind
                : ""
            }}
          </ion-text>

          <ion-text
            *ngIf="
              product.points_to_earn &&
              product.price &&
              !product.price_takeaway &&
              !product.virtual_price_takeaway
            "
          >
            <span> | </span>
            <span class="points-to-earn">
              {{ product.points_to_earn }}
              {{ "points-translation" | translate }}
            </span>
          </ion-text>
        </span>
      </ion-col>

      <ion-col
        *ngIf="
          orderPickup == false &&
          dineIn == true &&
          groupStoreData &&
          groupStoreData.store_info &&
          (!groupStoreData.store_info.alt_dinein_active ||
            !selectedDineInTable ||
            !selectedDineInTable.alt_dinein)
        "
        style="padding-left: 0px"
        class="pricePaddings"
        size="12"
        [ngClass]="{
          priceWidthUnavailable: !!product.priceWidthUnavailable,
          priceWidthAvailable: !!product.priceWidthAvailable
        }"
      >
        <span
          style="padding-right: 3px; font-size: smaller"
          *ngIf="product.initial_price && !product.price_dinein"
        >
          <span class="line-through">
            {{ product.initial_price
            }}{{
              product.enable_quantity_step &&
              product.quantity_step &&
              product.quantity_step_kind
                ? "/" + product.quantity_step_kind
                : ""
            }}
          </span>
        </span>
        <span
          style="padding-right: 3px; font-size: smaller"
          *ngIf="product.initial_price_dinein && product.price_dinein"
        >
          <span class="line-through">
            {{ product.initial_price_dinein
            }}{{
              product.enable_quantity_step &&
              product.quantity_step &&
              product.quantity_step_kind
                ? "/" + product.quantity_step_kind
                : ""
            }}
          </span>
        </span>
        <span
          [ngClass]="{
            'lowerFromTop unavailable line-through': !!(
              !product.availableTotal ||
              !product.product_categories_availability_check
            )
          }"
        >
          <ion-text
            *ngIf="product.price_dinein"
            color="primary"
            style="font-weight: 700"
            >{{ product.price_dinein
            }}{{
              product.enable_quantity_step &&
              product.quantity_step &&
              product.quantity_step_kind
                ? "/" + product.quantity_step_kind
                : ""
            }}
          </ion-text>

          <ion-text
            *ngIf="product.price_dinein && product.points_to_earn_dinein"
          >
            <span> | </span>
            <span class="points-to-earn">
              {{ product.points_to_earn_dinein }}
              {{ "points-translation" | translate }}
            </span>
          </ion-text>

          <ion-text
            *ngIf="!product.price_dinein && product.virtual_price_dinein"
            color="primary"
            style="font-weight: 700"
            >{{ product.virtual_price_dinein
            }}{{
              product.enable_quantity_step &&
              product.quantity_step &&
              product.quantity_step_kind
                ? "/" + product.quantity_step_kind
                : ""
            }}</ion-text
          >

          <ion-text
            *ngIf="!product.price_dinein && !product.virtual_price_dinein"
            color="primary"
            style="font-weight: 700"
            >{{ product.price ? product.price : product.virtual_price
            }}{{
              product.enable_quantity_step &&
              product.quantity_step &&
              product.quantity_step_kind
                ? "/" + product.quantity_step_kind
                : ""
            }}
          </ion-text>

          <ion-text
            *ngIf="
              product.points_to_earn &&
              product.price &&
              !product.price_dinein &&
              !product.virtual_price_dinein
            "
          >
            <span> | </span>
            <span class="points-to-earn">
              {{ product.points_to_earn }}
              {{ "points-translation" | translate }}
            </span>
          </ion-text>
        </span>
      </ion-col>

      <ion-col
        *ngIf="
          orderPickup == false &&
          dineIn == true &&
          groupStoreData &&
          groupStoreData.store_info &&
          groupStoreData.store_info.alt_dinein_active &&
          selectedDineInTable &&
          selectedDineInTable.alt_dinein
        "
        style="padding-left: 0px"
        class="pricePaddings"
        size="12"
        [ngClass]="{
          priceWidthUnavailable: !!product.priceWidthUnavailable,
          priceWidthAvailable: !!product.priceWidthAvailable
        }"
      >
        <span
          style="padding-right: 3px; font-size: smaller"
          *ngIf="
            product.initial_price &&
            !product.price_alt_dinein &&
            !product.price_dinein
          "
        >
          <span class="line-through">
            {{ product.initial_price
            }}{{
              product.enable_quantity_step &&
              product.quantity_step &&
              product.quantity_step_kind
                ? "/" + product.quantity_step_kind
                : ""
            }}
          </span>
        </span>
        <span
          style="padding-right: 3px; font-size: smaller"
          *ngIf="product.initial_price_alt_dinein && product.price_alt_dinein"
        >
          <span class="line-through">
            {{ product.initial_price_alt_dinein
            }}{{
              product.enable_quantity_step &&
              product.quantity_step &&
              product.quantity_step_kind
                ? "/" + product.quantity_step_kind
                : ""
            }}
          </span>
        </span>
        <span
          style="padding-right: 3px; font-size: smaller"
          *ngIf="
            !product.price_alt_dinein &&
            product.initial_price_dinein &&
            product.price_dinein
          "
        >
          <span class="line-through">
            {{ product.initial_price_dinein
            }}{{
              product.enable_quantity_step &&
              product.quantity_step &&
              product.quantity_step_kind
                ? "/" + product.quantity_step_kind
                : ""
            }}
          </span>
        </span>
        <span
          [ngClass]="{
            'lowerFromTop unavailable line-through': !!(
              !product.availableTotal ||
              !product.product_categories_availability_check
            )
          }"
        >
          <ion-text
            *ngIf="product.price_alt_dinein"
            color="primary"
            style="font-weight: 700"
            >{{ product.price_alt_dinein
            }}{{
              product.enable_quantity_step &&
              product.quantity_step &&
              product.quantity_step_kind
                ? "/" + product.quantity_step_kind
                : ""
            }}</ion-text
          >

          <ion-text
            *ngIf="
              product.price_alt_dinein && product.points_to_earn_alt_dinein
            "
          >
            <span> | </span>
            <span class="points-to-earn">
              {{ product.points_to_earn_alt_dinein }}
              {{ "points-translation" | translate }}
            </span>
          </ion-text>

          <ion-text
            *ngIf="
              !product.price_alt_dinein && product.virtual_price_alt_dinein
            "
            color="primary"
            style="font-weight: 700"
            >{{ product.virtual_price_alt_dinein
            }}{{
              product.enable_quantity_step &&
              product.quantity_step &&
              product.quantity_step_kind
                ? "/" + product.quantity_step_kind
                : ""
            }}</ion-text
          >

          <ion-text
            *ngIf="
              !product.price_alt_dinein &&
              product.price_dinein &&
              !product.virtual_price_alt_dinein
            "
            color="primary"
            style="font-weight: 700"
            >{{ product.price_dinein
            }}{{
              product.enable_quantity_step &&
              product.quantity_step &&
              product.quantity_step_kind
                ? "/" + product.quantity_step_kind
                : ""
            }}</ion-text
          >

          <ion-text
            *ngIf="
              !product.price_alt_dinein &&
              !product.price_dinein &&
              !product.virtual_price_alt_dinein &&
              product.virtual_price_dinein
            "
            color="primary"
            style="font-weight: 700"
            >{{ product.virtual_price_dinein
            }}{{
              product.enable_quantity_step &&
              product.quantity_step &&
              product.quantity_step_kind
                ? "/" + product.quantity_step_kind
                : ""
            }}</ion-text
          >

          <ion-text
            *ngIf="
              !product.price_alt_dinein &&
              !product.virtual_price_alt_dinein &&
              !product.price_dinein &&
              !product.virtual_price_dinein
            "
            color="primary"
            style="font-weight: 700"
            >{{ product.price ? product.price : product.virtual_price
            }}{{
              product.enable_quantity_step &&
              product.quantity_step &&
              product.quantity_step_kind
                ? "/" + product.quantity_step_kind
                : ""
            }}
          </ion-text>

          <ion-text
            *ngIf="
              product.points_to_earn &&
              product.price &&
              !product.price_alt_dinein &&
              !product.virtual_price_alt_dinein &&
              !product.price_dinein &&
              !product.virtual_price_dinein
            "
          >
            <span> | </span>
            <span class="points-to-earn">
              {{ product.points_to_earn }}
              {{ "points-translation" | translate }}
            </span>
          </ion-text>
        </span>
      </ion-col>

      <ion-col
        style="padding: 0px"
        size="12"
        *ngIf="
          (product &&
            !!(
              !product.availableTotal ||
              !product.product_categories_availability_check
            )) ||
          product.avpc ||
          product.promo ||
          product.tags ||
          product.delivery ||
          product.pickup ||
          product.dinein ||
          (product.availableTotal &&
            product.availability_stock_enabled &&
            product.availability_stock) ||
          (product.preorder_availability_stock_enabled &&
            product.preorder_availability_stock) ||
          (product &&
            groupStoreData &&
            groupStoreData.groupApp &&
            groupStoreData.groupApp.pointsSystem &&
            groupStoreData.store_info &&
            groupStoreData.store_info.overridePromoItemsForPointsSystem &&
            !product.pointsSystemScheduleActive &&
            !product.pointsSystemException &&
            product.pointsSystemWeight &&
            product.pointsSystemWeight > 1)
        "
      >
        <app-product-item-tags
          [productItem]="product"
          [groupApp]="
            groupStoreData && groupStoreData.groupApp
              ? groupStoreData.groupApp
              : null
          "
          [store_info]="
            groupStoreData && groupStoreData.store_info
              ? groupStoreData.store_info
              : null
          "
          [selectedPreorderDate]="selectedPreorderDate"
          [orderPickup]="orderPickup"
          [dineIn]="dineIn"
        >
        </app-product-item-tags>
      </ion-col>
    </ion-row>

    <div
      slot="end"
      [class]="
        product && product.squareImageDesign
          ? 'squareIonThumbnail'
          : 'standardIonThumbnail'
      "
    ></div>
  </ion-item>

  <ion-thumbnail
    *ngIf="product && product.squareImageDesign"
    (click)="showProductDetailsModal(product)"
    [class]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.mobileorder_show_img_borders
        ? 'product-image-border squareIonThumbnail'
        : 'squareIonThumbnail'
    "
    style="
      z-index: 1000;
      position: absolute;
      bottom: -15px;
      right: 10px;
      cursor: pointer;
    "
    loading="lazy"
  >
    <img style="border-radius: 7px" loading="lazy" [src]="imageUrl" />
  </ion-thumbnail>

  <ion-thumbnail
    *ngIf="product && !(product && product.squareImageDesign)"
    (click)="showProductDetailsModal(product)"
    [class]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.mobileorder_show_img_borders
        ? 'product-image-border-radius standardIonThumbnail'
        : 'standardIonThumbnail'
    "
    style="
      z-index: 1000;
      position: absolute;
      bottom: -15px;
      right: 10px;
      cursor: pointer;
    "
  >
    <img
      *ngIf="
        product &&
        product.image &&
        product.image.true &&
        product.image.true['4x3'] &&
        product.image.true['4x3'].path &&
        groupStoreData &&
        groupStoreData.groupApp &&
        (!groupStoreData.groupApp.disableCatalogPhoto ||
          groupStoreData.groupApp.disableCatalogPhoto)
      "
      [alt]="product.name"
      loading="lazy"
      style="border-radius: 10px"
      [src]="
        'https://images.deliverymanager.gr/' +
        product.image.true['4x3'].path +
        (groupStoreData.webp
          ? '.webp'
          : product.image.true['4x3'].type || '.jpg')
      "
    />
  </ion-thumbnail>
</div>
