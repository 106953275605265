<ion-header *ngIf="campaign" class="ion-no-border">
  <ion-toolbar >
    <ion-buttons style="position: absolute; top: 3px; right: 3px">
      <ion-button color="dark" fill="clear" (click)="closeModal()">
        <ion-icon src="assets/ionicons/close.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-img [src]="link">
      
    </ion-img>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="campaign">
  <ion-grid style="padding: 0;">
    <ion-row style="border-bottom: 0.5px solid rgba(0, 0, 0, .1); padding: 5px" class="ion-align-items-center ion-justify-content-center ">
      <ion-col class="ion-text-center">
        <ion-button size="small" *ngIf="campaign.target_quantity === currentCampaignQuantity.length">
          {{'loyaltySystem.gift-available' | translate}}
        </ion-button>
        <ion-text *ngIf="campaign.target_quantity !== currentCampaignQuantity.length">
          {{"loyaltySystem.campaign-item.need-more" | translate}} {{campaign.target_quantity - currentCampaignQuantity.length}} {{"loyaltySystem.campaign-item.to-get-gift" | translate}}
        </ion-text>
      </ion-col>
    </ion-row>
    <ion-row style="margin-top: 25px; padding: 5px; ">
      <ion-col class="ion-text-left">
        <ion-text style="font-size: x-large; font-weight: bold;">
          {{campaign.title.el}}
        </ion-text>
      </ion-col>
    </ion-row>
    <ion-row style="padding: 5px">
      <ion-col>
        <ion-text style="font-size: medium;" [innerHTML]="campaign.description.el">
          
        </ion-text>
      </ion-col>
    </ion-row>
    <ion-row style="padding: 5px; margin-top: 15px; border-bottom: 0.5px solid rgba(0, 0, 0, .1); border-top: 0.5px solid rgba(0, 0, 0, .1)" class="ion-align-items-center">
      <ion-col size="1" class="ion-text-center">
        <ion-icon size="large" name="calendar"></ion-icon> 
      </ion-col>
      <ion-col >
        <ion-text>Ισχύει <ion-text style="font-weight: bold;"> από </ion-text> {{campaign.activeFrom | date: 'dd/MM/yy'}} <ion-text style="font-weight: bold;"> εώς </ion-text> {{campaign.activeTo | date: 'dd/MM/yy'}} 
        </ion-text>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>