import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import moment from "moment";
import { auditTime, distinctUntilChanged, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import * as fromApp from "../../../store/app.reducer";


@Component({
  selector: 'app-campaign-item-information',
  templateUrl: './campaign-item-information.component.html',
  styleUrls: ['./campaign-item-information.component.scss'],
})
export class CampaignItemInformationComponent implements OnInit, OnDestroy {

  @Input() campaign;
  @Input() userLoyaltyProducts;
  public expiresInDays: any;
  public link: string;
  currentCampaignQuantity: any;
  public groupApp: any;

  private unsubscribeBackEvent: Subscription;
  private subscriptions: Subscription[] = [];

  constructor(
    private modalController: ModalController,
    private platform: Platform,
    private store: Store<fromApp.AppState>,

  ) { }

  ngOnInit() {
    console.log(this.campaign);
    // if(this.campaign.product_type === 'burger'){
      this.currentCampaignQuantity = _.filter(this.userLoyaltyProducts, (product)=>{
        return product.status === 'active' && product.type === this.campaign.product_type
      });
    // }
    // else if(this.campaign.product_type === 'coffee'){
    //   this.currentCampaignQuantity = this.campaign.coffees.length;
    // }
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );

    this.subscriptions.push( 
      this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(200))
      .subscribe((state) => {
        console.log("groupAPP state", state)
        if (
          state &&
          state.groupApp &&
          !_.isEqual(this.groupApp, state.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp);
          this.link = "https://d3vm1ym7xlqx7l.cloudfront.net/" + this.groupApp.group + "/" + this.campaign.image;
        }
      })
    )
    // let difference = this.campaign.expiration_date - Date.now();
    // this.expiresInDays = Math.floor(difference/1000/60/60/24);

    // console.log(this.expiresInDays);
  }

  closeModal() {
    this.modalController.dismiss();
  }

  ngOnDestroy() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    this.subscriptions = [];
    this.currentCampaignQuantity = null;
  }
}
