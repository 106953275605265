<ion-tabs (ionTabsDidChange)="setCurrentTab($event)">
  <ion-tab-bar slot="bottom" selectedTab="loyalty-home">
    <ion-tab-button tab="loyalty-home">
      <ion-icon
                [name]="activeTab == 'loyalty-home' ? 'home' : 'home-outline'"></ion-icon>
      <ion-label>{{'loyaltySystem.loyalty-home' | translate}}</ion-label>
    </ion-tab-button>

    <ion-tab-button tab="loyalty-coupons">
      <ion-icon
                [name]="activeTab == 'loyalty-coupons' ? 'pricetag' : 'pricetag-outline'"></ion-icon>
      <ion-label>{{'loyaltySystem.loyalty-coupons' | translate}}</ion-label>
      <ion-badge
                 *ngIf="currentUser && activeCustomerCoupons && activeCustomerCoupons.length > 0">{{activeCustomerCoupons.length}}</ion-badge>
    </ion-tab-button>

    <ion-tab-button tab="loyalty-contests">
      <ion-icon
                [name]="activeTab == 'loyalty-contests' ? 'trophy' : 'trophy-outline'"></ion-icon>
      <ion-label>{{'loyaltySystem.loyalty-contests.loyalty-contests' |
        translate}}</ion-label>
      <ion-badge *ngIf="groupContests && groupContests.length > 0">{{drawsSum || 0}}</ion-badge>
    </ion-tab-button>

    <ion-tab-button tab="loyalty-more">
      <ion-icon
                [name]="activeTab == 'loyalty-more' ? 'ellipsis-horizontal' : 'ellipsis-horizontal-outline'"></ion-icon>
      <ion-label>{{'loyaltySystem.loyalty-more' | translate}}</ion-label>
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>