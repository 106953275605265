import { Action } from "@ngrx/store";

export const SET_HYPER_CATEGORIES = "[HYPER CATEGORIES]  Set Hyper Categories";
export const CLEAR_HYPER_CATEGORIES =
  "[HYPER CATEGORIES]  Clear Hyper Categories";
export const SET_ALL_CATEGORIES = "[HYPER CATEGORIES]  Set All Categories"; // used only for hyper categories
export const CLEAR_ALL_CATEGORIES = "[HYPER CATEGORIES]  Clear All Categories"; // used only for hyper categories

export const SET_CURRENT_HYPER_CATEGORY =
  "[HYPER CATEGORIES]  Set Current Hyper Category";

export class SetAllCategories implements Action {
  // used only for hyper categories
  readonly type = SET_ALL_CATEGORIES;
  constructor(public payload: { catalog: any }) {}
}

export class ClearAllCategories implements Action {
  // used only for hyper categories
  readonly type = CLEAR_ALL_CATEGORIES;
  constructor() {}
}
export class SetHyperCategories implements Action {
  // used only for hyper categories
  readonly type = SET_HYPER_CATEGORIES;
  constructor(public payload: any) {}
}

export class SetCurrentHyperCategory implements Action {
  // used only for hyper categories
  readonly type = SET_CURRENT_HYPER_CATEGORY;
  constructor(public payload: any) {}
}

export class ClearHyperCategories implements Action {
  // used only for hyper categories
  readonly type = CLEAR_HYPER_CATEGORIES;
  constructor() {}
}
export type HyperCategoriesActions =
  | SetAllCategories
  | ClearAllCategories
  | SetCurrentHyperCategory
  | SetHyperCategories
  | ClearHyperCategories;
