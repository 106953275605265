<span *ngIf="!mobileMode && allFavoriteProductsBeforeSearch">
  <ion-row
    style="width: 100%"
    *ngIf="allFavoriteProductsBeforeSearch.length > 0"
  >
    <ion-col>
      <ion-item class="favoritesItem">
        <ion-label>
          <ion-text color="primary">
            <strong style="font-size: larger">{{
              "catalog.favorite-products" | translate
            }}</strong>
          </ion-text>
        </ion-label>
      </ion-item>
      <ion-grid style="max-width: 600px">
        <ion-searchbar
          *ngIf="
            enableFavoritesPageSearch &&
            allFavoriteProductsBeforeSearch &&
            allFavoriteProductsBeforeSearch.length &&
            allFavoriteProductsBeforeSearch.length > 9
          "
          clearIcon="assets/ionicons/close.svg"
          searchIcon="assets/ionicons/search.svg"
          animated
          placeholder="{{ 'catalog.search-in-favorites' | translate }}"
          debounce="1200"
          (ionClear)="handleResetSearch()"
          type="text"
          [(ngModel)]="favoritesSearchTerm"
          (ionChange)="onSearch()"
        >
        </ion-searchbar>
      </ion-grid>
    </ion-col>
  </ion-row>

  <span *ngIf="!allCategoriesMarketCardsDesign">
    <span
      *ngFor="
        let product of renderedFavoriteProducts;
        trackBy: favoriteProductsTrackFn
      "
    >
      <app-product-items
        *ngIf="
          product.activeTotal &&
          product.category &&
          product.category.activeTotal
        "
        [category_id]="product.category.category_id"
        [productImage]="product.image"
        [product_id]="product.product_id"
        [hyperCategories]="hyperCategories"
        [cartProductIds]="cartProductIds"
        [cartProductIds]="cartProductIds"
        [groupStoreData]="groupStoreData"
        [selectedDineInTable]="selectedDineInTable"
        [selectedPreorderDate]="selectedPreorderDate"
        [dineIn]="dineIn"
        [orderPickup]="orderPickup"
        [webp]="webp"
        [favoriteProduct]="true"
      >
      </app-product-items>
    </span>
  </span>

  <ion-grid
    [ngStyle]="{ 'max-width.px': gridMaxWidth }"
    *ngIf="allCategoriesMarketCardsDesign"
  >
    <ion-row style="width: 100%">
      <ion-col
        *ngFor="
          let product of renderedFavoriteProducts;
          trackBy: favoriteProductsTrackFn
        "
        [size]="colSize"
      >
        <span
          *ngIf="
            product.category && windowDimensions && windowDimensions.width > 500
          "
        >
          <app-product-item-market-type-card-browser
            *ngIf="product.activeTotal && product.category.activeTotal"
            [product]="product"
            [category]="product.category"
            [cartProductIds]="cartProductIds"
            [dineIn]="dineIn"
            [orderPickup]="orderPickup"
            [groupStoreData]="groupStoreData"
            [selectedDineInTable]="selectedDineInTable"
            [selectedPreorderDate]="selectedPreorderDate"
            [webp]="webp"
            [productIncludedInCart]="product.productIncludedInCart"
            [finalPriceLoading]="finalPriceLoading"
          ></app-product-item-market-type-card-browser>
        </span>

        <span
          *ngIf="
            product.category &&
            windowDimensions &&
            windowDimensions.width <= 500
          "
        >
          <app-product-item-market-type-card-mobile
            *ngIf="product.activeTotal && product.category.activeTotal"
            [product]="product"
            [category]="product.category"
            [cartProductIds]="cartProductIds"
            [dineIn]="dineIn"
            [orderPickup]="orderPickup"
            [groupStoreData]="groupStoreData"
            [selectedDineInTable]="selectedDineInTable"
            [selectedPreorderDate]="selectedPreorderDate"
            [webp]="webp"
            [productIncludedInCart]="product.productIncludedInCart"
            [finalPriceLoading]="finalPriceLoading"
          ></app-product-item-market-type-card-mobile>
        </span>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-item
    lines="none"
    *ngIf="
      renderedFavoriteProducts &&
      !renderedFavoriteProducts.length &&
      enableFavoritesPageSearch
    "
  >
    <ion-label class="ion-text-center ion-text-wrap">
      {{ "catalog.no-favorite-products-found" | translate }}
    </ion-label>
  </ion-item>
</span>

<span *ngIf="mobileMode && allFavoriteProductsBeforeSearch">
  <ion-row *ngIf="allFavoriteProductsBeforeSearch.length > 0">
    <ion-col>
      <ion-item lines="full" class="favoritesItem">
        <ion-label>
          <ion-text color="primary">
            <strong style="font-size: large">{{
              "catalog.favorite-products" | translate
            }}</strong>
          </ion-text>
        </ion-label>
      </ion-item>
      <ion-grid style="max-width: 600px">
        <ion-searchbar
          *ngIf="
            enableFavoritesPageSearch &&
            allFavoriteProductsBeforeSearch &&
            allFavoriteProductsBeforeSearch.length &&
            allFavoriteProductsBeforeSearch.length > 9
          "
          clearIcon="assets/ionicons/close.svg"
          searchIcon="assets/ionicons/search.svg"
          animated
          placeholder="{{ 'catalog.search-in-favorites' | translate }}"
          debounce="1200"
          (ionClear)="handleResetSearch()"
          type="text"
          [(ngModel)]="favoritesSearchTerm"
          (ionChange)="onSearch()"
        >
        </ion-searchbar
      ></ion-grid>
    </ion-col>
  </ion-row>

  <span
    *ngFor="
      let product of renderedFavoriteProducts;
      trackBy: favoriteProductsTrackFn
    "
  >
    <app-product-items-mobile
      *ngIf="
        product.activeTotal && product.category && product.category.activeTotal
      "
      [category_id]="product.category.category_id"
      [productImage]="product.image"
      [product_id]="product.product_id"
      [hyperCategories]="hyperCategories"
      [cartProductIds]="cartProductIds"
      [dineIn]="dineIn"
      [orderPickup]="orderPickup"
      [groupStoreData]="groupStoreData"
      [selectedDineInTable]="selectedDineInTable"
      [selectedPreorderDate]="selectedPreorderDate"
      [webp]="webp"
      [favoriteProduct]="true"
    >
    </app-product-items-mobile>
  </span>
  <ion-item
    lines="none"
    *ngIf="
      enableFavoritesPageSearch &&
      renderedFavoriteProducts &&
      !renderedFavoriteProducts.length
    "
  >
    <ion-label class="ion-text-center ion-text-wrap">
      {{ "catalog.no-favorite-products-found" | translate }}
    </ion-label>
  </ion-item>
</span>
