import { Component, Input, OnInit } from "@angular/core";
import { AnimationOptions } from "ngx-lottie";

@Component({
  selector: "app-loading-splashscreen",
  templateUrl: "./loading-splashscreen.component.html",
  styleUrls: ["./loading-splashscreen.component.scss"],
})
export class LoadingSplashscreenComponent implements OnInit {
  @Input() splashScreenImage: any;
  @Input() groupApp: any;

  animationOptions: AnimationOptions = {
    path: "",
  };

  constructor() {}

  ngOnInit() {
    console.log("splashScreenImage", this.splashScreenImage);

    if (this.groupApp.design_settings) {
      if (this.groupApp.design_settings.animation_loader) {
        this.animationOptions = {
          ...this.animationOptions,
          path:
            "https://data.deliverymanager.gr/animations/" +
            this.groupApp.design_settings.animation_loader,
        };
      } else {
        this.animationOptions = {
          ...this.animationOptions,
          path: "https://data.deliverymanager.gr/animations/spinner-default-1.json",
        };
      }
    } else {
      this.animationOptions = {
        ...this.animationOptions,
        path: "https://data.deliverymanager.gr/animations/spinner-default-1.json",
      };
    }
  }
}
