import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import { Subscription } from "rxjs";
import { TranslateConfigService } from "src/app/services/translate-config/translate-config.service";
import * as fromApp from "../../../store/app.reducer";
import moment from "moment";

@Component({
  selector: 'app-reservation-date-popup',
  templateUrl: './reservation-date-popup.component.html',
  styleUrls: ['./reservation-date-popup.component.scss'],
})
export class ReservationDatePopupComponent {
  @Input() lang;
  @Input() reservationDays;
  @Input() preselectedDate; //case 2
  @Input() preselectedTimestamp; //case 1
  @Input() preselectedEndTimestamp; //case 1
  @Input() startLimit; //case 2
  @Input() endLimit; //case 2
  @Input() priority;
  @Input() createReservationWithoutTable;
  public selectedDate;
  public selectedEndHour;
  public selectedHour;
  private sub: Subscription;
  private unsubscribeBackEvent: Subscription;

  public startHourMin;
  public startHourMax;
  public endHourMin;
  public endHourMax;
  public endPresentation = "time";
  public disableStartHours = false;

  private reservationDuration = 5 * 60;
  private slotSize = 15; //minutes

  constructor(private modalController: ModalController,
              private platform: Platform,
              private store: Store<fromApp.AppState>,
              private translateConfigService: TranslateConfigService) {}

  ngOnInit() {

    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      this.priority || 400,
      () => {
        if (this.platform.is("android")) {
          this.modalController.dismiss();
        }
      }
    );

    // console.log("reservationDays", this.reservationDays);
    //console.log("LIMITS", this.startLimit, this.endLimit);
    this.reservationDays = _.map(this.reservationDays, item => {
      item.dayString = moment(item.day, "DD-MM-YYYY").locale(this.lang).format("ddd, DD MMM")
      return item;
    });
    if (this.preselectedDate){
      const mom = moment(parseInt(this.preselectedDate));
      const date = mom.format("DD-MM-YYYY");
      const foundDay = _.find(this.reservationDays, item => {
        return item.day === date;
      });
      if (foundDay){
        this.selectedDate = date;
      }
    } else if (this.preselectedTimestamp){
      const mom = moment(parseInt(this.preselectedTimestamp));
      const date = mom.format("DD-MM-YYYY");
      const foundDay = _.find(this.reservationDays, item => {
        return item.day === date;
      });
      if (foundDay){
        this.selectedDate = date;
        
        this.selectedHour = moment(parseInt(this.preselectedTimestamp)).format("YYYY-MM-DDTHH:mm");
        if (this.preselectedEndTimestamp){
          this.selectedEndHour = moment(parseInt(this.preselectedEndTimestamp)).format("YYYY-MM-DDTHH:mm");
        }
        this.endHourMin = moment(this.selectedHour).add(this.slotSize, "minutes").format("YYYY-MM-DDTHH:mm");
        this.endHourMax = moment(this.selectedHour).add(this.reservationDuration, "minutes").format("YYYY-MM-DDTHH:mm");

        if (this.endLimit){
          const isoLimit = moment(parseInt(this.endLimit)).format("YYYY-MM-DDTHH:mm");

          if (isoLimit < this.endHourMax){
            this.endHourMax = isoLimit;
          }
        }

        if (moment(this.endHourMin).format("DD-MM-YYYY") !== moment(this.endHourMax).format("DD-MM-YYYY")){
          this.endPresentation = "date-time"
        } else {
          this.endPresentation = "time"
        }
      }
    }

    const min = new Date().getMinutes();
    const div = _.floor(min/15) + 1; 
    this.startHourMin = moment().add(div*15 + 30, "minutes").startOf("hour").format("YYYY-MM-DDTHH:mm");
    // console.log("LIMITS2", this.startHourMin);

    if (this.startLimit){
      const isoLimit = moment(parseInt(this.startLimit)).format("YYYY-MM-DDTHH:mm");
      if (isoLimit > this.startHourMin){
        this.startHourMin = isoLimit;
      }
    }
    // console.log("LIMITS3", this.startHourMin);

    if (this.endLimit){
      this.startHourMax = moment(parseInt(this.endLimit)).subtract(this.slotSize, "minutes").format("YYYY-MM-DDTHH:mm");

      if (parseInt(this.endLimit) - Date.now() < 1000 * 60 * 50){
        this.disableStartHours = true;
      }
    }
  }


  // dummyData(){
  //   this.reservationDays = [
  //     {
  //         "day": "12-04-2023",
  //         "day_schedule": {
  //             "all_day": true,
  //             "active": true,
  //             "schedule_comment": "Για Πέμπτη 30/03/2023 δεχόμαστε κρατήσεις όλο το 24ωρο."
  //         },
  //         "time_slots": [
  //             "15:15",
  //             "15:30",
  //             "15:45",
  //         ]
  //     },
  //     {
  //         "day": "13-04-2023",
  //         "day_schedule": {
  //             "all_day": true,
  //             "active": false,
  //             "schedule_comment": "Για Παρασκευή 31/03/2023 δεν δεχόμαστε κρατήσεις."
  //         },
  //         "time_slots": null
  //     }
  // ];
    
  // }

  onCalendarFocus() {
    if (!this.selectedHour){
      this.selectedHour = moment().add(1, "hour").startOf("hour").format("YYYY-MM-DDTHH:mm");
    }
    if (this.selectedHour < this.startHourMin){
      this.selectedHour = this.startHourMin;
    }
    if (this.selectedHour > this.startHourMax) {
      this.selectedHour = this.startHourMin;
    }
  }

  onCalendarEndFocus() {
    if (!this.selectedEndHour){
      this.selectedEndHour = moment(this.selectedHour).add(2, "hours").format("YYYY-MM-DDTHH:mm");
    }
    if (this.selectedEndHour > this.endHourMax){
      this.selectedEndHour = this.endHourMax;
    }
  }

  selectDate() {
    this.selectedHour = null;
    this.selectedEndHour = null;
  }

  onSelectHour() {
    if (this.selectedHour){
      this.selectedEndHour = null;
      this.endHourMin = moment(this.selectedHour).add(this.slotSize, "minutes").format("YYYY-MM-DDTHH:mm");
      this.endHourMax = moment(this.selectedHour).add(this.reservationDuration, "minutes").format("YYYY-MM-DDTHH:mm");

      if (this.endLimit){
        const isoLimit = moment(parseInt(this.endLimit)).format("YYYY-MM-DDTHH:mm");

        if (isoLimit < this.endHourMax){
          this.endHourMax = isoLimit;
        }
      }

      if (moment(this.endHourMin).format("DD-MM-YYYY") !== moment(this.endHourMax).format("DD-MM-YYYY")){
        this.endPresentation = "date-time"
      } else {
        this.endPresentation = "time"
      }
    }
  }

  onSubmit() {
    const data = {
      day: this.selectedDate,
      start: moment(this.selectedHour).format("HH:mm"),
      end: moment(this.selectedEndHour).format("HH:mm")
    }
    const role = this.createReservationWithoutTable ? "confirm-create" : "confirm";
    this.modalController.dismiss(data, role);
    //this.modalController.dismiss(this.selectedDate + " " + this.selectedHour, "confirm");
  }

  closeModal() {
    this.modalController.dismiss();
  }

  ngOnDestroy() {
    if (this.unsubscribeBackEvent){
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.sub){
      this.sub.unsubscribe();
    }
  }

}

