import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { MenuController, ModalController } from "@ionic/angular";
import * as fromApp from "../store/app.reducer";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import { Subscription, auditTime, distinctUntilChanged } from "rxjs";
import { NavigationEnd, Router } from "@angular/router";
import { OrderNowModalPopupsService } from "../services/orderNowModalPopupsService/orderNowModalPopups.service";
import * as DeliveryMethodAddressActions from "../store/deliveryMethodAddress/deliveryMethodAddress.actions";
import { AnalyticsServiceService } from "../services/analitycsService/analytics-service.service";
import { LoginPopupsServiceService } from "../services/login-popups-service/login-popups-service.service";
import { OrderNowPopupComponent } from "../popups/order-now-popup/order-now-popup.component";
import { Capacitor } from "@capacitor/core";
import { CatalogSearchModalComponent } from "../catalog/catalog-search-component/catalog-search-modal/catalog-search-modal.component";

@Component({
  selector: "app-menu-footer-tabs",
  templateUrl: "./menu-footer-tabs.component.html",
  styleUrls: ["./menu-footer-tabs.component.scss"],
})
export class MenuFooterTabsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() playAnimationFadeIn: boolean;
  @Input() playAnimationFadeOut: boolean;
  public groupApp;
  public windowDimensions;
  public activeRoute;
  public currentUser;
  public newCustomer;
  public loginLoading;
  public showDeliveryButton: boolean;
  public infoShowDeliveryButton;
  public showPickupButton: boolean;
  public showDineInButton: boolean;
  public sideMenuIsOpen;

  private currentStoreId;
  private orderPickup;
  private dineIn;
  private selectedAddress;
  private selectedDineInTable;
  private checkAddressLoading;
  private navigationOnDeliveryMethodChange;
  private stores;
  private subscriptions: Subscription[] = [];
  private orderNowModal;
  private searchModal;
  constructor(
    private menuCtrl: MenuController,
    private router: Router,
    private orderNowModalPopupsService: OrderNowModalPopupsService,
    private analyticsService: AnalyticsServiceService,
    private LoginPopupsService: LoginPopupsServiceService,
    private modalCtrl: ModalController,

    private store: Store<fromApp.AppState>
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    console.log("onChangesMenuFooter", changes);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select("currentStore")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (state && !_.isEqual(state.currentStoreId, this.currentStoreId)) {
            this.currentStoreId = _.cloneDeep(state.currentStoreId);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("deliveryMethodAddress")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (state && !_.isEqual(state.orderPickup, this.orderPickup)) {
            this.orderPickup = _.cloneDeep(state.orderPickup);
          }
          if (state && !_.isEqual(state.dineIn, this.dineIn)) {
            this.dineIn = _.cloneDeep(state.dineIn);
          }
          if (
            state &&
            state.selectedAddress &&
            !_.isEqual(state.selectedAddress, this.selectedAddress)
          ) {
            this.selectedAddress = _.cloneDeep(state.selectedAddress);
          }
          if (
            state &&
            !_.isEqual(this.selectedDineInTable, state.selectedDineInTable)
          ) {
            this.selectedDineInTable = _.cloneDeep(state.selectedDineInTable);
          }
          if (
            state &&
            !_.isEqual(state.selectedAddressLoading, this.checkAddressLoading)
          ) {
            this.checkAddressLoading = _.cloneDeep(
              state.selectedAddressLoading
            );
          }
          if (
            state &&
            !_.isEqual(
              state.navigationOnDeliveryMethodChange,
              this.navigationOnDeliveryMethodChange
            )
          ) {
            this.navigationOnDeliveryMethodChange = _.cloneDeep(
              state.navigationOnDeliveryMethodChange
            );
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe(async (state) => {
          if (state && state.stores && !_.isEqual(state.stores, this.stores)) {
            this.stores = _.cloneDeep(state.stores);

            this.showDeliveryButton = !_.isEmpty(
              _.filter(this.stores, (store) => {
                if (!store.disableOrderDelivery) {
                  return store;
                }
              })
            );

            if (!this.showDeliveryButton) {
              this.infoShowDeliveryButton =
                await this.orderNowModalPopupsService.checkToShowInfoDeliveryButton();

              this.infoShowDeliveryButton = !!this.infoShowDeliveryButton;
            }
            console.log("infoShowDeliveryButton", this.infoShowDeliveryButton);

            this.showPickupButton = !_.isEmpty(
              _.filter(this.stores, (store) => {
                if (!store.disableOrderPickup) {
                  return store;
                }
              })
            );
            console.log("pickup button", this.showPickupButton);

            this.showDineInButton = !_.isEmpty(
              _.filter(this.stores, (store) => {
                if (store.dinein) {
                  return store;
                }
              })
            );
            console.log("dine in butto");
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && !_.isEqual(state.user, this.currentUser)) {
            this.currentUser = _.cloneDeep(state.user);
          }
          if (state && !_.isEqual(state.newCustomer, this.newCustomer)) {
            this.newCustomer = _.cloneDeep(state.newCustomer);
          }
          if (state && !_.isEqual(state.loginLoading, this.loginLoading)) {
            this.loginLoading = _.cloneDeep(state.loginLoading);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(state.groupApp, this.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
            if (
              this.groupApp &&
              this.groupApp.design_settings &&
              this.groupApp.design_settings.menuFooterTabs &&
              this.windowDimensions &&
              this.windowDimensions.width < 900
            ) {
            }
          }

          if (
            state &&
            state.activeRoute &&
            !_.isEqual(state.activeRoute, this.activeRoute)
          ) {
            this.activeRoute = _.cloneDeep(state.activeRoute);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.windowDimensions &&
            !_.isEqual(state.windowDimensions, this.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.windowDimensions &&
            !_.isEqual(state.windowDimensions, this.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
          }
        })
    );
  }

  async presentOrderNowModal() {
    if (
      this.router &&
      this.router.url &&
      this.router.url.includes("homepage")
    ) {
      this.store.dispatch(
        new DeliveryMethodAddressActions.SetNavigationOnDeliveryMethodChanged(
          true
        )
      );
      if (!this.showPickupButton && !this.showDineInButton) {
        this.orderNowModalPopupsService.checkAndOpenSettingsModal(
          "delivery",
          this.currentUser,
          this.selectedAddress,
          this.stores,
          this.currentStoreId,
          true,
          false
        );
      } else if (
        !this.showPickupButton &&
        !this.showDeliveryButton &&
        !this.infoShowDeliveryButton
      ) {
        this.orderNowModalPopupsService.checkAndOpenSettingsModal(
          "dine-in",
          this.currentUser,
          this.selectedAddress,
          this.stores,
          this.currentStoreId,
          true,
          false
        );
      } else if (
        !this.showDineInButton &&
        !this.showDeliveryButton &&
        !this.infoShowDeliveryButton
      ) {
        this.orderNowModalPopupsService.checkAndOpenSettingsModal(
          "take-away",
          this.currentUser,
          this.selectedAddress,
          this.stores,
          this.currentStoreId,
          true,
          false
        );
      } else {
        this.orderNowModal = await this.modalCtrl.create({
          component: OrderNowPopupComponent,
          cssClass: "orderNowPopupCss",
          backdropDismiss: true,
        });

        await this.orderNowModal.present();
        this.orderNowModal.onDidDismiss().then(() => {
          this.orderNowModal = null;
        });
      }
    } else {
      this.router.navigateByUrl("/catalog", {
        replaceUrl: true,
      });
    }
  }

  async presentMagicLoginPopup() {
    this.analyticsService.dmAnalyticsMessage(
      "login_button",
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );

    // this.MagicLoginservice.presentLoginModal(this.newCustomer);
    if (!Capacitor.isNativePlatform()) {
      console.log("before presentFirebaseModalLogin");
      this.LoginPopupsService.presentFirebaseModalLogin(null, null);
    } else {
      console.log("before closeMobileMagicLoginAlert");
      this.LoginPopupsService.closeMobileMagicLoginAlert();
      this.LoginPopupsService.presentMobileMagicLoginAlert(
        this.newCustomer,
        this.groupApp
      );
    }
  }

  pageChange(route) {
    this.router.navigateByUrl("/" + route, {
      replaceUrl: true,
    });
  }

  openSideMenu() {
    this.menuCtrl.open();
  }

  async showSearchModal() {
    this.searchModal = await this.modalCtrl.create({
      component: CatalogSearchModalComponent,
      cssClass: "catalog-search-modal",
      backdropDismiss: false,
    });

    await this.searchModal.present();
    this.searchModal.onDidDismiss().then(() => {
      this.searchModal = null;
    });
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });

      this.subscriptions = [];
    }

    if (this.orderNowModal) {
      this.orderNowModal.dismiss();
      this.orderNowModal = null;
    }

    if (this.searchModal) {
      this.searchModal.dismiss();
      this.searchModal = null;
    }
  }
}
