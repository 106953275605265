import { Action } from "@ngrx/store";

export const SET_ORDER_COMMENTS = "[ORDER COMMENTS] Set Order Comments";

export class SetOrderComments implements Action {
  readonly type = SET_ORDER_COMMENTS;
  constructor(public payload: any) {}
}

export type OrderCommentsActions = SetOrderComments;
