<ion-header>
  <app-no-internet-connection></app-no-internet-connection>
  <app-ionic-update-error></app-ionic-update-error>
  <ion-toolbar>
    <ion-title>{{"cart.title"| translate}}</ion-title>

    <ion-buttons slot="start">
      <ion-back-button
        [text]="isIos ? ('back' | translate) : ''"
        [defaultHref]="'/catalog'"
      ></ion-back-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <app-cart-browser-lg
      *ngIf="currentView === 'cartBrowserLg'"
    ></app-cart-browser-lg>
    <app-cart-browser-md
      *ngIf="currentView === 'cartBrowserMd'"
    ></app-cart-browser-md>
    <app-cart-mobile *ngIf="currentView === 'cartMobile'"></app-cart-mobile>
  </ion-grid>
</ion-content>

<ion-footer
  style="background-color: var(--ion-color-danger)"
  *ngIf="pendingOrders && pendingOrders.length && pendingOrders.length>0"
>
  <ion-grid>
    <ion-row style="width: 100%">
      <ion-col class="ion-text-center">
        <div color="black" style="font-weight: bold">
          <ion-text
            color="white"
            *ngIf="pendingOrders && pendingOrders[0] && pendingOrders[0].status && pendingOrders[0].status === 'pending'"
          >
            {{'cart.there-is-a-pending-order' | translate}}</ion-text
          >
          <ion-text
            color="white"
            *ngIf="pendingOrders && pendingOrders[0] && pendingOrders[0].status && pendingOrders[0].status === 'processing'"
          >
            {{'cart.there-is-a-processing-order' | translate}}</ion-text
          >
          <ion-text
            color="white"
            *ngIf="pendingOrders && pendingOrders[0] && pendingOrders[0].status && pendingOrders[0].status === 'processingPayment'"
          >
            {{'cart.there-is-a-processing-payment-order' | translate}}</ion-text
          >
          <ion-text
            color="white"
            *ngIf="pendingOrders && pendingOrders[0] && pendingOrders[0].status && pendingOrders[0].status === 'pendingPayment'"
          >
            {{'cart.there-is-a-pending-payment-payment-order' |
            translate}}</ion-text
          >
        </div>
        <ion-button
          (click)=" pendingOrders && pendingOrders[0] && pendingOrders[0].status && pendingOrders[0].status === 'pendingPayment' ? navigateToOrdersPage( pendingOrders[0].store_id, pendingOrders[0].order_id) :navigateToOrdersPage( null, null)"
          color="black"
        >
          <ion-text
            *ngIf="pendingOrders && pendingOrders[0] && pendingOrders[0].status && (pendingOrders[0].status === 'pending' ||
            pendingOrders[0].status === 'processingPayment' ||
          
            pendingOrders[0].status === 'processing')"
          >
            {{'cart.see-your-order' | translate}}
          </ion-text>
          <ion-text
            *ngIf="pendingOrders && pendingOrders[0] && pendingOrders[0].status && (pendingOrders[0].status === 'pendingPayment')"
          >
            {{'cart.complete-payment' | translate}}
          </ion-text>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
