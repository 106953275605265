<ion-item>
  <ion-thumbnail
    slot="start"
    *ngIf="
      image &&
      image.path &&
      choice &&
      choice.bundled_product &&
      !choice.bundled_product.squareImageDesign
    "
    class="standardIonThumbnail"
  >
    <img
      style="border-radius: 10px"
      [class]="
        !choice.bundled_product.availableTotal ||
        !choice.availableTotal ||
        !(
          choice.bundled_product.product_categories_availability_check &&
          choice.bundled_product.availableTotal &&
          (choice.bundled_product.pickup ? (orderPickup ? true : false) : true)
        )
      "
      [alt]="choice.bundled_product.name"
      loading="lazy"
      *ngIf="image"
      [src]="image.path"
    />
  </ion-thumbnail>

  <ion-thumbnail
    *ngIf="
      image &&
      image.path &&
      choice &&
      choice.bundled_product &&
      choice.bundled_product.squareImageDesign
    "
    slot="start"
    [class]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.mobileorder_show_img_borders
        ? 'product-image-border squareIonThumbnail'
        : 'squareIonThumbnail'
    "
    style="z-index: 1"
  >
    <img style="border-radius: 7px" loading="lazy" [src]="image.path" />
  </ion-thumbnail>
  <ion-label class="ion-text-wrap">
    <div class="titlePaddings">
      <strong style="font-size: medium"
        >{{
          !bundleProduct.disable_qty &&
          choice.bundle_options &&
          choice.bundle_options.enable_bundle_product_quantity
            ? bundleProduct.quantity
            : ""
        }}
        {{
          !bundleProduct.disable_qty &&
          choice.bundle_options &&
          choice.bundle_options.enable_bundle_product_quantity
            ? "x"
            : ""
        }}
        {{ bundleProduct.name }}</strong
      >
    </div>

    <ion-text color="primary">
      <p
        style="
          font-size: x-small;
          line-height: 14px;
          font-weight: bold;
          white-space: normal;
          margin: 0px;
        "
        *ngIf="bundleProduct.promotionDescription"
      >
        {{ bundleProduct.promotionDescription }}
      </p>
      <p
        color="primary"
        style="
          font-size: x-small;
          line-height: 14px;
          font-weight: bold;
          white-space: normal;
          margin: 0px;
        "
        *ngIf="
          bundleProduct.do_not_include_in_min_order &&
          !orderPickup &&
          bundleProduct.extra_delivery_charge
        "
      >
        Δε λαμβάνεται υπόψιν στην ελάχιστη παραγγελία<br />Επιβάλλεται χρέωση
        {{ bundleProduct.extra_delivery_charge }}€ αν το παραγγείλετε μόνο του!
      </p>
      <p
        style="
          font-size: x-small;
          line-height: 14px;
          font-weight: bold;
          white-space: normal;
          margin: 0px;
        "
        *ngIf="
          groupApp &&
          groupApp.pointsSystem &&
          store_info &&
          store_info.overridePromoItemsForPointsSystem &&
          bundleProduct.pointsSystemException
        "
      >
        <ion-text color="primary">
          {{
            "product-details.bundle-option.bundle-product-item.product-is-point-exception"
              | translate
          }}
        </ion-text>
      </p>
      <p
        style="
          font-size: x-small;
          line-height: 14px;
          font-weight: bold;
          white-space: normal;
          margin: 0px;
        "
        *ngIf="
          groupApp &&
          groupApp.pointsSystem &&
          store_info &&
          store_info.overridePromoItemsForPointsSystem &&
          !bundleProduct.pointsSystemScheduleActive &&
          !bundleProduct.pointsSystemException &&
          bundleProduct.pointsSystemWeight &&
          bundleProduct.pointsSystemWeight > 1
        "
      >
        <ion-text color="primary">
          {{
            "product-details.bundle-option.bundle-product-item.you-win-points"
              | translate
          }}
          x
          {{ bundleProduct.pointsSystemWeight }}
        </ion-text>
      </p>
    </ion-text>
    <h6 *ngIf="!bundleProduct.gift" color="dark">
      <span
        [ngClass]="{
          'line-through': !!(
            bundleProduct.specialPrice !== 0 && bundleProduct.specialPrice
          ),
          fontSizeSmaller: !!(
            bundleProduct.specialPrice !== 0 && bundleProduct.specialPrice
          )
        }"
      >
        {{ bundleProduct.productTotal }}€
      </span>

      <ion-text
        style="font-weight: 700; padding-left: 3px"
        *ngIf="
          !bundleProduct.gift &&
          bundleProduct.specialPrice &&
          bundleProduct.specialPrice !== 0
        "
      >
        {{ bundleProduct.specialPriceTotal }}€
      </ion-text>
    </h6>
    <h6 *ngIf="bundleProduct.gift">
      {{ "product-details.bundle-option.bundle-product-item.free" | translate }}
    </h6>

    <ion-text
      style="font-weight: 900; cursor: pointer"
      color="primary"
      *ngIf="
        !showProductItemDetails &&
        productHaveDetails(bundleProduct) &&
        groupApp &&
        !groupApp.cartChoicesAlwaysOpen
      "
      (click)="showProductItemDetails = !showProductItemDetails"
    >
      <p>
        {{
          "product-details.bundle-option.bundle-product-item.show-more-details"
            | translate
        }}
      </p>
    </ion-text>
    <span
      *ngIf="
        groupApp && (showProductItemDetails || groupApp.cartChoicesAlwaysOpen)
      "
    >
      <div>
        <span *ngFor="let option of bundleProduct.options">
          <div
            *ngIf="
              option.option_id &&
              (!option.dependenciesVariable ||
                option.dependenciesVariable.length === 0 ||
                (option.dependenciesVariable.length > 0 &&
                  option.showDependent))
            "
            class="secondaryFontSize item-text-wrap"
          >
            <p
              style="font-size: smaller; margin: 0px"
              *ngIf="
                option.type !== 'field' &&
                (!(
                  option.choices &&
                  !option.reverseChoices &&
                  (option.choices | choicesPreselected).length === 0
                ) ||
                  (option.is_require && option.availableTotal))
              "
            >
              <strong>
                {{
                  option.doNotPrint
                    ? ""
                    : option.title
                    ? option.title + ": "
                    : ""
                }}
                {{
                  option.parent_free && option.option_parent_total_price === 0
                    ? "(" +
                      ("product-details.bundle-option.bundle-product-item.free"
                        | translate) +
                      ")"
                    : option.option_parent_total_price
                    ? option.parent_free && bundleProduct.parent_discount
                      ? option.option_parent_total_price +
                        "€ (-" +
                        bundleProduct.parent_discount +
                        "%)"
                      : option.option_parent_total_price + "€"
                    : ""
                }}
              </strong>
              <span
                *ngIf="
                  option.type === 'checkbox' &&
                  option.reverseChoices &&
                  option.choicesComment
                "
                style="display: inline-block"
                class="mainFontSize"
                >{{ option.choicesComment }}
              </span>
              <span
                *ngFor="
                  let choice of option.choices | choicesPreselected;
                  let last = last
                "
              >
                <span
                  style="display: inline-block"
                  *ngIf="
                    !choice.bundle &&
                    !choice.doNotPrint &&
                    (option.type !== 'checkbox' ||
                      !option.reverseChoices ||
                      (!option.choicesComment && !option.reverseChoices))
                  "
                >
                  {{ choice.printLabel ? choice.printLabel : choice.title
                  }}<strong *ngIf="!choice.choicePriceComment">{{
                    choice.isFree
                      ? ("cart.cart-components.cart-list.cart-list-product-item.free-choice"
                        | translate)
                      : !choice.price
                      ? ""
                      : " " +
                        (orderPickup == true && dineIn == false
                          ? choice.price_takeaway || choice.price
                          : orderPickup == false && dineIn == true
                          ? store_info &&
                            store_info.alt_dinein_active &&
                            selectedDineInTable &&
                            selectedDineInTable.alt_dinein &&
                            choice.price_alt_dinein
                            ? choice.price_alt_dinein
                            : choice.price_dinein || choice.price
                          : choice.price) +
                        "€"
                  }}</strong
                  ><span *ngIf="choice.choicePriceComment">{{
                    choice.choicePriceComment
                  }}</span
                  >{{ last ? "" : "," }}&nbsp;
                </span>

                <app-cart-list-product-choice-comment
                  [bundleProduct]="choice.bundled_product"
                  [orderPickup]="orderPickup"
                  [store_info]="store_info"
                  [selectedDineInTable]="selectedDineInTable"
                  [dineIn]="dineIn"
                  *ngIf="
                    choice.bundle &&
                    choice.bundled_with_product_id &&
                    choice.bundled_product &&
                    !choice.doNotPrint &&
                    (option.type !== 'checkbox' ||
                      !option.reverseChoices ||
                      (!option.choicesComment && !option.reverseChoices))
                  "
                >
                </app-cart-list-product-choice-comment>
              </span>
              <!-- 
                <span
                  class="cartWarningColor"
                  ng-if="
                    option.choices &&
                    (option.choices | filter: { is_preselected: true }).length ===
                      0 &&
                    option.is_require
                  "
                >
                    {{
                      "product-details.bundle-option.bundle-product-item.you-must-make-a-choice"
                        | translate
                    }}
                </span> -->
            </p>

            <p
              style="margin: 0 auto 0 0; font-size: smaller"
              *ngIf="option.type === 'field' && option.text"
            >
              <strong>{{ option.title }}:</strong> {{ option.text }}
            </p>
          </div>
        </span>
      </div>
    </span>

    <div *ngIf="bundleProduct && bundleProduct | cartProductOptionRequired">
      <ion-text color="danger">
        {{
          "product-details.bundle-option.bundle-product-item.you-must-make-a-choice"
            | translate
        }}
      </ion-text>
    </div>

    <div
      *ngIf="
        bundleProduct &&
        !bundleProduct.availableTotal &&
        (!onlyAvailableForPreorderProductsById ||
          !onlyAvailableForPreorderProductsById[bundleProduct.product_id])
      "
    >
      <ion-text color="danger">
        {{
          "product-details.bundle-option.bundle-product-item.product-needs-to-be-deleted"
            | translate
        }}
      </ion-text>
    </div>

    <app-product-item-order-type-tags
      [productItem]="bundleProduct"
      [orderPickup]="orderPickup"
      [dineIn]="dineIn"
    >
    </app-product-item-order-type-tags>

    <ion-item
      *ngIf="
        bundleProduct && bundleProduct.notValidDeliveryArea && selectedAddress
      "
      (click)="showProductValidDeliveryAreasInfoModal()"
      lines="none"
      button="true"
      detail="false"
    >
      <ion-label
        class="ion-text-center ion-text-wrap"
        color="danger"
        style="font-size: x-small; font-weight: bold"
      >
        {{
          "cart.cart-components.cart-list.product-has-not-valid-delivery-area-click-for-info"
            | translate
        }}
      </ion-label>

      <ion-icon
        style="font-size: 20px; margin-right: 5px"
        color="danger"
        slot="start"
        src="assets/ionicons/information-circle-outline.svg"
      >
      </ion-icon>
    </ion-item>

    <div
      *ngIf="
        bundleProduct &&
        !bundleProduct.availableTotal &&
        onlyAvailableForPreorderProductsById &&
        onlyAvailableForPreorderProductsById[bundleProduct.product_id]
      "
    >
      <ion-text color="danger">
        {{
          "product-details.bundle-option.bundle-product-item.product-is-only-for-preorder-right-now"
            | translate
        }}
      </ion-text>
    </div>
    <div>
      <ion-text
        *ngIf="
          bundleProduct &&
          bundleProduct.needsEdit &&
          bundleProduct.availableTotal
        "
        color="danger"
      >
        {{
          "product-details.bundle-option.bundle-product-item.product-needs-to-be-edited"
            | translate
        }}
      </ion-text>
    </div>
  </ion-label>

  <ion-button
    color="dark"
    size="small"
    style="position: absolute; top: 5px; right: 0"
    [disabled]="finalPriceLoading"
    (click)="presentAlertConfirm(bundleProduct)"
    fill="clear"
  >
    <ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
  </ion-button>

  <ion-button
    color="dark"
    size="small"
    style="position: absolute; top: 5px; right: 30px"
    [disabled]="finalPriceLoading"
    (click)="showProductDetailsModal(choice, bundleProduct)"
    fill="clear"
  >
    <ion-icon slot="icon-only" src="assets/flatIcons/cartEdit.svg"></ion-icon>
  </ion-button>

  <ion-badge
    *ngIf="
      groupApp &&
      groupApp.pointsSystem &&
      store_info &&
      store_info.overridePromoItemsForPointsSystem &&
      bundleProduct.points
    "
    style="position: absolute; bottom: 10px; right: 15px"
  >
    {{ bundleProduct.points }}
    {{ "product-details.bundle-option.bundle-product-item.points" | translate }}
  </ion-badge>
</ion-item>
