<ion-header>
  <app-no-internet-connection></app-no-internet-connection>
  <app-ionic-update-error></app-ionic-update-error>

  <ion-toolbar *ngIf="windowDimensions" [mode]="'ios'">
    <ion-buttons slot="start">
      <ion-button color="primary" (click)="goBack()">
        <ion-icon name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title
      *ngIf="store_info && selectedLanguage"
      [ngStyle]="{'font-size': windowDimensions && windowDimensions.width <= 450 ? 'small' : 'medium',
        'font-weight':'bold',
        'padding-left':'50px',
        'padding-right':'50px'
      }"
      >{{ store_info.store_name_langs &&
      store_info.store_name_langs[selectedLanguage] ?
      store_info.store_name_langs[selectedLanguage] :
      store_info.store_name_langs && store_info ? store_info.store_name_langs.el
      : store_info.store_name }}

      <div
        style="font-size: small; font-weight: 500"
        class="ion-text-wrap ion-text-center"
      >
        {{store_info.address}}
      </div>
    </ion-title>
  </ion-toolbar>

  <ion-grid style="max-width: 900px; padding: 0">
    <app-horizontal-hyper-categories
      *ngIf=" (hyperCategories && hyperCategories.length>0 && !(store_info &&
    store_info.design_settings &&
    store_info.design_settings.showOnlySelectedCategoryProducts))  && !catalogEmpty && showCategoriesSlider && store_info && !store_info.virtual_store_change_hyper_categories_buttons"
    >
    </app-horizontal-hyper-categories>
    <app-horizontal-categories
      *ngIf=" ((hyperCategories && hyperCategories.length===0 ) || (store_info &&
    store_info.design_settings &&
    store_info.design_settings.showOnlySelectedCategoryProducts)) && !catalogEmpty && showCategoriesSlider"
    >
    </app-horizontal-categories>
  </ion-grid>
</ion-header>

<ion-grid
  *ngIf="!(catalogEmpty || (hyperCategories && windowInfo))"
  style="position: absolute; top: 20px; width: 100%; height: 100%"
>
  <ion-row
    style="width: 100%; height: 100%"
    class="ion-justify-content-center ion-align-items-center"
  >
    <ion-col class="ion-text-center">
      <ion-spinner color="dark"></ion-spinner>
    </ion-col>
  </ion-row>
</ion-grid>

<ion-content
  *ngIf="catalogEmpty || (hyperCategories && windowInfo)"
  scrollX="false"
  scrollY="false"
  [scrollEvents]="false"
>
  <ion-grid style="padding-left: 0; padding-right: 0" *ngIf="hyperCategories">
    <div
      [class]="windowDimensions.width<850 ? 'info-message-mobile-card' : 'info-message-browser-card'"
      *ngIf="loginState && (loginState==='no_login' || loginState ==='login_completed')  &&((infoMessagesStore && infoMessagesStore.alert && infoMessagesStore.alert.length)) && languagePath && mo_base && groupApp && windowDimensions && windowDimensions.width && windowDimensions.width<1080"
    >
      <ion-card
        [ngClass]="infoMessagesAnimations[catalogInfoMessage.id] ?    'animate__animated animate__backInRight' :  'animate__animated animate__fadeOut'"
        [hidden]="infoMessagesCardsHide[catalogInfoMessage.id] "
        color="primary"
        mode="md"
        [ngStyle]="{top: (50+ index * 10).toString() + '%'}"
        *ngFor="let catalogInfoMessage of infoMessagesStore.alert;let index = index"
      >
        <ion-icon
          (click)="closeInfoCard(catalogInfoMessage.id)"
          style="
            position: absolute;
            top: 1px;
            right: 1px;
            font-size: 19px;
            cursor: pointer;
            z-index: 150;
          "
          src="assets/ionicons/close.svg"
        ></ion-icon>

        <ion-row
          class="infoMessageCardRow ion-align-items-center ion-justify-content-center"
        >
          <ion-col
            *ngIf="catalogInfoMessage.image"
            size="3"
            class="ion-no-padding"
          >
            <ion-thumbnail class="infoMessageThumb">
              <img
                src="https://s3-eu-west-1.amazonaws.com/storegroupseditedbgimages/{{
              groupApp.group
            }}/info_messages/{{ catalogInfoMessage.image }}"
              />
            </ion-thumbnail>
          </ion-col>
          <ion-col
            [size]="!catalogInfoMessage.image ? '12':'9'"
            class="ion-no-padding"
          >
            <div>
              <ion-text
                [innerHTML]="catalogInfoMessage.title[languagePath] ?
        catalogInfoMessage.title[languagePath]
        :(catalogInfoMessage.title[mo_base] ?
        catalogInfoMessage.title[mo_base] :
        catalogInfoMessage.title['en']) "
                style="line-height: 1.5; font-weight: bold"
                *ngIf="catalogInfoMessage && catalogInfoMessage.title"
              ></ion-text>
            </div>
          </ion-col>
        </ion-row>
        <ion-progress-bar
          *ngIf="catalogInfoMessage.auto_dismiss && catalogInfoMessage.auto_dismiss_seconds"
          [value]="motoCardsProgressValues[catalogInfoMessage.id]"
          color="success"
          style="height: 5px"
        ></ion-progress-bar>
      </ion-card>
    </div>

    <span *ngIf="!catalogEmpty">
      <app-reservation-products-catalog
        [rerenderProductsItemsTimestamp]="rerenderProductsItemsTimestamp"
      ></app-reservation-products-catalog>
    </span>
    <span
      *ngIf="loginState && (loginState==='no_login' || loginState ==='login_completed')  &&((infoMessagesStore && infoMessagesStore.alert && infoMessagesStore.alert.length)) && languagePath && mo_base && groupApp && windowDimensions && windowDimensions.width && windowDimensions.width>=1080"
    >
      <div
        style="z-index: 1000"
        [class]="catalogInfoMessage && catalogInfoMessage.position ==='bottom_left' ? 'bottom-left-info-message': 'top-right-info-message'"
        *ngFor="let catalogInfoMessage of infoMessagesStore.alert;let index = index"
      >
        <ion-card
          [ngClass]="infoMessagesAnimations[catalogInfoMessage.id] ?    'animate__animated animate__backInRight' :  'animate__animated animate__fadeOut'"
          [hidden]="infoMessagesCardsHide[catalogInfoMessage.id] "
          color="primary"
          mode="md"
          [ngStyle]="{top: (50+ index * 10).toString() + '%'}"
        >
          <ion-icon
            (click)="closeInfoCard(catalogInfoMessage.id)"
            style="
              position: absolute;
              top: 1px;
              right: 1px;
              font-size: 19px;
              cursor: pointer;
              z-index: 150;
            "
            src="assets/ionicons/close.svg"
          ></ion-icon>

          <ion-row
            class="infoMessageCardRow ion-align-items-center ion-justify-content-center"
          >
            <ion-col
              *ngIf="catalogInfoMessage.image"
              size="3"
              class="ion-no-padding"
            >
              <ion-thumbnail class="infoMessageThumb">
                <img
                  src="https://s3-eu-west-1.amazonaws.com/storegroupseditedbgimages/{{
              groupApp.group
            }}/info_messages/{{ catalogInfoMessage.image }}"
                />
              </ion-thumbnail>
            </ion-col>
            <ion-col
              [size]="!catalogInfoMessage.image ? '12':'9'"
              class="ion-no-padding"
            >
              <div>
                <ion-text
                  [innerHTML]="catalogInfoMessage.title[languagePath] ?
        catalogInfoMessage.title[languagePath]
        :(catalogInfoMessage.title[mo_base] ?
        catalogInfoMessage.title[mo_base] :
        catalogInfoMessage.title['en']) "
                  style="line-height: 1.5; font-weight: bold"
                  *ngIf="catalogInfoMessage && catalogInfoMessage.title"
                ></ion-text>
              </div>
            </ion-col>
          </ion-row>
          <ion-progress-bar
            *ngIf="catalogInfoMessage.auto_dismiss && catalogInfoMessage.auto_dismiss_seconds"
            [value]="motoCardsProgressValues[catalogInfoMessage.id]"
            color="success"
            style="height: 5px"
          ></ion-progress-bar>
        </ion-card>
      </div>
    </span>
  </ion-grid>

  <app-cart-custom-options
    *ngIf="hyperCategories"
    [catalogMode]="true"
    [showQuantityOptions]="'quantity-options'"
  ></app-cart-custom-options>
</ion-content>

<ion-footer>
  <ion-grid
    *ngIf="!store_info || !windowDimensions"
    style="width: 100%; min-height: 60px; max-width: 400px"
  >
    <ion-progress-bar type="indeterminate"></ion-progress-bar>
  </ion-grid>
  <ion-grid
    *ngIf="store_info && windowDimensions"
    style="max-width: 600px; padding-bottom: 20px"
  >
    <ion-row
      style="width: 100%"
      class="ion-justify-content-center ion-align-items-center"
    >
      <ion-col size="6" class="ion-text-center ion-no-padding">
        <ion-chip (click)="openLocation()"
          ><ion-icon
            style="margin-right: 3px"
            color="primary"
            name="location-sharp"
          ></ion-icon>
          <ion-label
            color="primary"
            class="ion-text-wrap"
            [ngStyle]="{
                'line-height': windowDimensions && windowDimensions.width>450 ? '' : '1',
                'font-size': windowDimensions && windowDimensions.width>450 ? '' : 'small'
                }"
            >{{"reservation-app.uWillFindUs" |translate}}</ion-label
          >
          <ion-icon
            *ngIf="windowDimensions && windowDimensions.width>350 "
            color="primary"
            name="chevron-forward"
          ></ion-icon>
        </ion-chip>
      </ion-col>
      <ion-col size="6" class="ion-text-center ion-no-padding">
        <ion-chip (click)="openInfo()"
          ><ion-icon
            style="margin-right: 3px"
            color="primary"
            name="information-outline"
          ></ion-icon
          ><ion-label
            color="primary"
            class="ion-text-wrap"
            [ngStyle]="{
              'line-height': windowDimensions && windowDimensions.width>450 ? '' : '1',
              'font-size': windowDimensions && windowDimensions.width>450 ? '' : 'small'
              }"
            >{{"reservation-app.info" |translate}}</ion-label
          >

          <ion-icon
            *ngIf="windowDimensions && windowDimensions.width>350 "
            color="primary"
            name="chevron-forward"
          ></ion-icon
        ></ion-chip>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
