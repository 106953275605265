import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { AlertController, ModalController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import {
  auditTime,
  debounceTime,
  distinctUntilChanged,
  Subscription,
} from "rxjs";
import { NewAddressModalComponent } from "src/app/address/new-address-modal/new-address-modal.component";
import { OrderNowPopupComponent } from "src/app/popups/order-now-popup/order-now-popup.component";
import { OrderNowSettingsPopupComponent } from "src/app/popups/order-now-popup/order-now-settings-popup/order-now-settings-popup.component";
import { SelectStorePopupComponent } from "src/app/popups/select-store-popup/select-store-popup.component";
import { ProductDetailsComponent } from "src/app/product-details/product-details.component";
import * as fromApp from "../../../../store/app.reducer";
import * as selectors from "../../../../store/app.selectors";
import * as _ from "lodash";
import * as currentStoreActions from "../../../../store/current-store/current-store.actions";
import * as CompleteOrderValidationActions from "../../../../store/complete-order-validation/completeOrderValidation.actions";
import { TranslateService } from "@ngx-translate/core";
import { PreorderPopupComponent } from "src/app/popups/preorder-popup/preorder-popup.component";
import { OrderNowModalPopupsService } from "src/app/services/orderNowModalPopupsService/orderNowModalPopups.service";
import * as DeliveryMethodAddressActions from "../../../../store/deliveryMethodAddress/deliveryMethodAddress.actions";
import { setTimeout } from "timers";
import { PreorderValidationServiceService } from "src/app/services/preorder-validation-service/preorder-validation-service.service";
import { SelectStoreImagePopupComponent } from "src/app/popups/select-store-image-popup/select-store-image-popup.component";
import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import { DeliveryInfoModalComponent } from "src/app/popups/delivery-info-modal/delivery-info-modal.component";
declare let window: any;
@Component({
  selector: "app-delivery-method-address",
  templateUrl: "./delivery-method-address.component.html",
  styleUrls: ["./delivery-method-address.component.scss"],
})
export class DeliveryMethodAddressComponent implements OnInit, OnDestroy {
  @Input() cartMode: boolean = false;
  public dineIn: boolean;
  public selectedAddress: any;
  public store_info: any;
  public stores: any;
  public currentUser: any;
  public cartProducts: any = null;
  public finalPriceLoading: boolean = false;
  public orderPickup: boolean;
  public validStores: any;
  public valid: boolean = true;
  public selectedPreorderDate: any;
  public showDeliveryButton: boolean;
  public showPickupButton: boolean;
  public showDineInButton: boolean;
  public checkStoreOnlineInfo: any;
  public deliveryTimeIsNumber: boolean;
  public pickUpTimeIsNumber: boolean;
  public selectedTableDineIn: any;
  public selectedLangugage: any;
  public groupApp;

  private preorderResponse: any;
  private preorder_pickup_days: any;
  private preorder_delivery_days: any;
  private selectStorePopup: any;
  private orderNowModal: any;
  private newAddressModal: any;
  private orderNowSettingsPopup: any;
  private currentStoreId: string;
  private lastValidTimestamp: any = null;
  private infoShowDeliveryButton;
  private deliveryInfoModal;
  private baseLanguage;
  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store<fromApp.AppState>,
    private modalCtrl: ModalController,
    private alertController: AlertController,
    private translateService: TranslateService,
    private orderNowModalPopupsService: OrderNowModalPopupsService,
    private preorderValidationService: PreorderValidationServiceService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(this.groupApp, state.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);

            const moBaseLanguage = _.find(this.groupApp.languages, {
              mo_base: true,
            });
            if (moBaseLanguage && moBaseLanguage.code) {
              this.baseLanguage = moBaseLanguage.code;
            }
          }

          if (
            state &&
            state.selectedLangugage &&
            !_.isEqual(this.selectedLangugage, state.selectedLangugage)
          ) {
            this.selectedLangugage = _.cloneDeep(state.selectedLangugage);
          }
        })
    );
    if (this.cartMode) {
      this.subscriptions.push(
        this.store
          .select("completeOrderValidation")
          .pipe(distinctUntilChanged())
          .subscribe((state) => {
            if (
              state &&
              state.lastValidTimestamp &&
              !_.isEqual(this.lastValidTimestamp, state.lastValidTimestamp)
            ) {
              this.lastValidTimestamp = _.cloneDeep(state.lastValidTimestamp);
              this.deliveryMethodCompleteOrderValidations();
            }
          })
      );
    }
    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((state) => {
          if (state && !_.isEqual(state.user, this.currentUser)) {
            this.currentUser = _.cloneDeep(state.user);
          }
        })
    );
    this.subscriptions.push(
      this.store.select("disableFinalPrice").subscribe((state) => {
        if (
          state &&
          !_.isEqual(state.disableFinalPrice, this.finalPriceLoading)
        ) {
          this.finalPriceLoading = _.cloneDeep(state.disableFinalPrice);
        }
      })
    );

    this.subscriptions.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())

        .subscribe(async (state) => {
          if (state && state.stores && !_.isEqual(state.stores, this.stores)) {
            this.stores = _.cloneDeep(state.stores);
            this.showDeliveryButton = !_.isEmpty(
              _.filter(this.stores, (store) => {
                if (!store.disableOrderDelivery) {
                  return store;
                }
              })
            );

            if (!this.showDeliveryButton) {
              this.infoShowDeliveryButton =
                await this.orderNowModalPopupsService.checkToShowInfoDeliveryButton();

              this.infoShowDeliveryButton = !!this.infoShowDeliveryButton;
            }

            this.showPickupButton = !_.isEmpty(
              _.filter(this.stores, (store) => {
                if (!store.disableOrderPickup) {
                  return store;
                }
              })
            );
            console.log("pickup button", this.showPickupButton);

            this.showDineInButton = !_.isEmpty(
              _.filter(this.stores, (store) => {
                if (store.dinein) {
                  return store;
                }
              })
            );
          }
          if (
            state &&
            state.checkStoreOnlineInfo &&
            !_.isEqual(state.checkStoreOnlineInfo, this.checkStoreOnlineInfo)
          ) {
            this.checkStoreOnlineInfo = _.cloneDeep(state.checkStoreOnlineInfo);

            if (
              this.checkStoreOnlineInfo &&
              this.checkStoreOnlineInfo.deliveryTime &&
              this.checkStoreOnlineInfo.deliveryTime
                .toString()
                .match(/^[0-9]+$/) != null
            ) {
              this.deliveryTimeIsNumber = true;
            } else {
              this.deliveryTimeIsNumber = false;
            }
            if (
              this.checkStoreOnlineInfo &&
              this.checkStoreOnlineInfo.pickupDeliveryTime &&
              this.checkStoreOnlineInfo.pickupDeliveryTime
                .toString()
                .match(/^[0-9]+$/) != null
            ) {
              this.pickUpTimeIsNumber = true;
            } else {
              this.pickUpTimeIsNumber = false;
            }
          }
        })
    );

    // this.subscriptions.push(
    //   this.store.select("auth").subscribe((state) => {
    //     if (
    //       state &&
    //       !_.isEqual(state.user, this.currentStoreId)
    //     ) {
    //       this.finalPriceLoading = _.cloneDeep(state.disableFinalPrice);
    //     }
    //   })
    // );

    this.subscriptions.push(
      this.store
        .select("cart")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (state && !_.isEqual(this.cartProducts, state.products)) {
            this.cartProducts = _.cloneDeep(state.products);
            if (navigator.cookieEnabled) {
              window.localStorage.setItem(
                "cart",
                JSON.stringify(this.cartProducts)
              );
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("deliveryMethodAddress")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (state && !_.isEqual(this.orderPickup, state.orderPickup)) {
            console.log("preivous pickup", this.orderPickup, state.orderPickup);

            this.orderPickup = _.cloneDeep(state.orderPickup);
            if (
              this.orderPickup != null &&
              !this.preorderValidationService.preorderModalIsOpen() &&
              state.preorderResponse &&
              !_.isEmpty(state.preorderResponse)
            ) {
              if (state.selectedPreorderDate) {
                console.log(
                  "call preorder valid calcs from preorder order pickup change"
                );
                this.preorderValidationService.preorderValidCalculations(
                  state.orderPickup,
                  state.dineIn,
                  state.preorderResponse.preorder_delivery_days,
                  state.preorderResponse.preorder_pickup_days,
                  state.selectedPreorderDate
                );
              }
            }
            this.deliveryMethodValidations();
          }
          if (state && !_.isEqual(this.dineIn, state.dineIn)) {
            this.dineIn = _.cloneDeep(state.dineIn);
            this.deliveryMethodValidations();
            // console.log(
            //   "dine in delivery method  address component",
            //   this.dineIn
            // );
          }
          if (
            state &&
            !_.isEqual(this.selectedTableDineIn, state.selectedDineInTable)
          ) {
            this.selectedTableDineIn = _.cloneDeep(state.selectedDineInTable);
            this.deliveryMethodValidations();
          }
          if (
            state &&
            !_.isEqual(this.selectedAddress, state.selectedAddress)
          ) {
            this.selectedAddress = _.cloneDeep(state.selectedAddress);
            this.deliveryMethodValidations();
          }

          if (state && !_.isEqual(this.validStores, state.validStores)) {
            this.validStores = _.cloneDeep(state.validStores);
          }

          if (
            state &&
            !_.isEqual(this.selectedPreorderDate, state.selectedPreorderDate)
          ) {
            this.selectedPreorderDate = _.cloneDeep(state.selectedPreorderDate);
          }

          if (
            state &&
            !_.isEqual(this.preorderResponse, state.preorderResponse)
          ) {
            //console.log("preivous pickup", this.orderPickup, state.orderPickup);
            this.preorderResponse = _.cloneDeep(state.preorderResponse);
            if (
              state.orderPickup != null &&
              !this.preorderValidationService.preorderModalIsOpen() &&
              state.preorderResponse &&
              !_.isEmpty(state.preorderResponse)
            ) {
              if (state.selectedPreorderDate) {
                this.preorderValidationService.preorderValidCalculations(
                  state.orderPickup,
                  state.dineIn,
                  state.preorderResponse.preorder_delivery_days,
                  state.preorderResponse.preorder_pickup_days,
                  state.selectedPreorderDate
                );
              }
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .subscribe((store_info) => {
          if (store_info && !_.isEqual(this.store_info, store_info)) {
            this.store_info = _.cloneDeep(store_info);

            console.log("store Info Changed", this.store_info);
          }
        })
    );

    // this.store
    //   .select("currentStore")
    //   .pipe(distinctUntilChanged())

    //   .subscribe((state) => {
    //     if (
    //       state &&
    //       state.currentStoreId &&
    //       !_.isEqual(this.currentStoreId, state.currentStoreId)
    //     ) {
    //       this.currentStoreId = _.cloneDeep(state.currentStoreId);
    //      this.validateCartDebounce();
    //     }
    //   });
  }

  async openDeliveryInfoModal() {
    this.deliveryInfoModal = await this.modalCtrl.create({
      component: DeliveryInfoModalComponent,
      cssClass: "delivery-info-modal",
      animated: true,
      componentProps: {
        message:
          this.groupApp &&
          this.groupApp.design_settings &&
          this.groupApp.design_settings.disableDeliveryInfo
            ? this.groupApp.design_settings.disableDeliveryInfo.message
            : null,
        button_one:
          this.groupApp &&
          this.groupApp.design_settings &&
          this.groupApp.design_settings.disableDeliveryInfo
            ? this.groupApp.design_settings.disableDeliveryInfo.button_one
            : null,
        button_two:
          this.groupApp &&
          this.groupApp.design_settings &&
          this.groupApp.design_settings.disableDeliveryInfo
            ? this.groupApp.design_settings.disableDeliveryInfo.button_two
            : null,
        selectedLanguage: this.selectedLangugage,
        baseLanguage: this.baseLanguage,
      },
      backdropDismiss: false,
    });

    await this.deliveryInfoModal.present();
    this.deliveryInfoModal.onDidDismiss().then(async (data) => {
      this.deliveryInfoModal = null;
    });
  }

  async showToastForScanQr() {
    const alert = await this.alertController.create({
      header: this.translateService.instant("alert"),
      message: this.translateService.instant("open_camera_and_scan_table_qr"),
      backdropDismiss: true,
      buttons: ["OK"],
    });
    await alert.present();
    await alert.onDidDismiss().then(() => {
      this.orderNowModalPopupsService.closeSettingsModal();
      //this.modalCtrl.dismiss();
    });
  }

  googleMaps(store) {
    if (store) {
      this.showOnMap(store.mapCenterStoreLat, store.mapCenterStoreLng);
    }
  }

  presentPreorderModal(showNotValidDateToast) {
    this.preorderValidationService.presentPreorderModal(showNotValidDateToast);
  }

  deliveryMethodValidations() {
    //console.log("delivery method address validations");
    this.valid = true;
    if (this.orderPickup === null && this.dineIn === null) {
      this.valid = false;
      // console.log(
      //   "delivery method address validations",
      //   this.orderPickup,
      //   this.dineIn
      // );
    } else if (
      this.orderPickup == false &&
      this.dineIn == false &&
      !this.selectedAddress
    ) {
      this.valid = false;
      // console.log(
      //   "delivery method address validations",
      //   this.orderPickup,
      //   this.dineIn
      // );
    }

    if (
      !this.validStores ||
      (this.validStores && this.validStores.length === 0)
    ) {
    }
  }

  deliveryMethodCompleteOrderValidations() {
    this.valid = true;
    if (this.orderPickup === null && this.dineIn === null) {
      this.valid = false;
      let validationObject = {
        id: "delivery-method-address",
        valid: false,
        modal: "selectDeliveryMethodModal",
        errorMessages: [
          "delivery-method-address.you-must-select-a-delivery-method",
        ],
      };
      this.store.dispatch(
        new CompleteOrderValidationActions.AddUpdateCompleteOrderValidation(
          _.cloneDeep(validationObject)
        )
      );
    } else if (
      this.orderPickup == false &&
      this.dineIn == false &&
      !this.selectedAddress
    ) {
      this.valid = false;
      let validationObject = {
        id: "delivery-method-address",
        valid: false,
        modal: "selectDeliveryMethodModal",
        errorMessages: ["delivery-method-address.you-must-select-an-address"],
      };
      this.store.dispatch(
        new CompleteOrderValidationActions.AddUpdateCompleteOrderValidation(
          _.cloneDeep(validationObject)
        )
      );
    }

    if (
      !this.validStores ||
      (this.validStores && this.validStores.length === 0)
    ) {
    }
  }

  presentSelectStoreModal() {
    if (!this.selectStorePopup) {
      this.selectStorePopup = true;
      this.showSelectStoreModal();
    }
  }

  async showSelectStoreModal() {
    if (this.groupApp && this.groupApp.images_select_store_mobileorder) {
      this.selectStorePopup = await this.modalCtrl.create({
        component: SelectStoreImagePopupComponent,
        cssClass: "selectStoreImagePopup",
        backdropDismiss: true,
        componentProps: { showValidStores: true },
      });
    } else {
      this.selectStorePopup = await this.modalCtrl.create({
        component: SelectStorePopupComponent,
        cssClass: "selectStorePopupCss",
        backdropDismiss: false,
        componentProps: { showValidStores: true },
      });
    }

    await this.selectStorePopup.present();
    this.selectStorePopup.onDidDismiss().then(async (data) => {
      //When you select a store calling selectStoreToSaveCard in order to checkPaymentGatewaysPopup
      if (data && data.data && data.data.selectedStoreId) {
        if (this.cartProducts && this.cartProducts.length > 0) {
          const alert = await this.alertController.create({
            cssClass: "my-custom-class",
            header: this.translateService.instant("alert"),
            message: this.translateService.instant(
              "catalog.if-you-change-store-your-cart-products-will-be-deleted-do-you-want-to-continue"
            ),
            buttons: [
              {
                text: this.translateService.instant("cancel"),
                role: "cancel",
                cssClass: "secondary",
                handler: (blah) => {},
              },
              {
                text: this.translateService.instant("yes"),
                handler: () => {
                  console.log("set store id deliveryMethod address");
                  this.store.dispatch(
                    new currentStoreActions.SetCurrentStoreId(
                      _.cloneDeep(data.data.selectedStoreId)
                    )
                  );
                },
              },
            ],
          });

          await alert.present();
        } else {
          console.log("set store id deliveryMethod address");
          this.store.dispatch(
            new currentStoreActions.SetCurrentStoreId(
              _.cloneDeep(data.data.selectedStoreId)
            )
          );
        }
      }
      this.selectStorePopup = null;
    });
  }

  async openOrderNowModal() {
    if (!this.showPickupButton && !this.showDineInButton) {
      this.orderNowModalPopupsService.checkAndOpenSettingsModal(
        "delivery",
        this.currentUser,
        this.selectedAddress,
        this.stores,
        this.currentStoreId,
        false,
        false
      );
    } else if (!this.showPickupButton && !this.showDeliveryButton) {
      this.orderNowModalPopupsService.checkAndOpenSettingsModal(
        "dine-in",
        this.currentUser,
        this.selectedAddress,
        this.stores,
        this.currentStoreId,
        false,
        false
      );
    } else if (!this.showDineInButton && !this.showDeliveryButton) {
      this.orderNowModalPopupsService.checkAndOpenSettingsModal(
        "take-away",
        this.currentUser,
        this.selectedAddress,
        this.stores,
        this.currentStoreId,
        false,
        false
      );
    } else {
      this.orderNowModal = await this.modalCtrl.create({
        component: OrderNowPopupComponent,
        cssClass: "orderNowPopupCss",
        backdropDismiss: true,
      });

      await this.orderNowModal.present();
      this.orderNowModal.onDidDismiss().then(() => {
        this.orderNowModal = null;
      });
    }
  }

  async orderNowSettingsModal(mode) {
    console.log("change address", this.currentUser, mode);
    if (mode === "delivery") {
      this.orderNowModalPopupsService.checkAndOpenSettingsModal(
        mode,
        this.currentUser,
        this.selectedAddress,
        this.stores,
        this.currentStoreId,
        false,
        null
      );
    } else if (mode === "take-away") {
      if (this.groupApp && this.groupApp.images_select_store_mobileorder) {
        this.orderNowSettingsPopup = await this.modalCtrl.create({
          component: SelectStoreImagePopupComponent,
          cssClass: "selectStoreImagePopup",
          backdropDismiss: true,
          componentProps: {
            settingsPopupMode: true,
          },
        });
      } else {
        let cssClass = "orderNowSettingsPopup";

        if (this.stores && this.stores.length > 1 && this.stores.length <= 3) {
          cssClass = "orderNowSettingsPopupThreeStores";
        } else if (this.stores && this.stores.length > 3) {
          cssClass = "orderNowSettingsPopupMultipleStores";
        }
        this.orderNowSettingsPopup = await this.modalCtrl.create({
          component: OrderNowSettingsPopupComponent,
          cssClass: cssClass,
          backdropDismiss: false,
          componentProps: {
            mode: mode,
          },
        });
      }

      await this.orderNowSettingsPopup.present();
      this.orderNowSettingsPopup.onDidDismiss().then(() => {
        this.orderNowSettingsPopup = null;
      });
    } else if (mode === "dine-in") {
      console.log("dineininin");
      this.orderNowModalPopupsService.checkAndOpenSettingsModal(
        mode,
        this.currentUser,
        this.selectedAddress,
        this.stores,
        this.currentStoreId,
        false,
        false
      );
    }
  }

  showOnMap(lat, lng) {
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;

    window.open(googleMapsUrl, "_system");
  }

  ngOnDestroy() {
    if (this.preorderValidationService.preorderModalIsOpen()) {
      this.preorderValidationService.closePreorderModal();
    }

    if (this.selectStorePopup) {
      this.selectStorePopup.dismiss();
      this.selectStorePopup = null;
    }
    if (this.orderNowSettingsPopup) {
      this.orderNowSettingsPopup.dismiss();
      this.orderNowSettingsPopup = null;
    }
    if (this.orderNowModal) {
      this.orderNowModal.dismiss();
      this.orderNowModal = null;
    }

    if (this.newAddressModal) {
      this.newAddressModal.dismiss();
      this.newAddressModal = null;
    }

    if (this.deliveryInfoModal) {
      this.deliveryInfoModal.dismiss();
      this.deliveryInfoModal = null;
    }

    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }
}
