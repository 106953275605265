import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { ModalController } from "@ionic/angular";
import moment from "moment";
import { ProductDetailsComponent } from "src/app/product-details/product-details.component";
import * as _ from "lodash";
import * as fromApp from "../../../../store/app.reducer";
import { Store } from "@ngrx/store";
import { Subscription, distinctUntilChanged } from "rxjs";
declare let window: any;

@Component({
  selector: "app-product-item-card",
  templateUrl: "./product-item-card.component.html",
  styleUrls: ["./product-item-card.component.scss"],
})
export class ProductItemCardComponent
  implements OnInit, AfterViewChecked, OnChanges, OnDestroy
{
  @ViewChild("productTitle") productTitleRef: ElementRef;
  @Input() groupStoreData;
  @Input() selectedDineInTable;
  @Input() selectedPreorderDate;
  @Input() category: any;
  @Input() product: any;
  @Input() webp: any;
  @Input() orderPickup: any;
  @Input() dineIn: any;
  @Input() recommendedModal: boolean;
  public preorderDateIsAfterToday;
  public productIsInCart: boolean;

  private cartProducts: any;
  private productDetailsModal: any;
  private cartSubscription: Subscription;

  constructor(
    private modalCtrl: ModalController,
    private store: Store<fromApp.AppState>,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.selectedPreorderDate &&
      !_.isEqual(
        changes.selectedPreorderDate.previousValue,
        changes.selectedPreorderDate.currentValue
      )
    ) {
      this.checkPreorderDate();
    }
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngOnInit() {
    if (this.recommendedModal) {
      this.cartSubscription = this.store
        .select("cart")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.products &&
            !_.isEqual(state.products, this.cartProducts)
          ) {
            this.cartProducts = _.cloneDeep(state.products);
            this.productIsInCart = !!_.find(this.cartProducts, {
              product_id: this.product.product_id,
            });
          }
        });
    }
  }

  checkPreorderDate() {
    if (
      this.selectedPreorderDate &&
      this.selectedPreorderDate.date &&
      this.selectedPreorderDate.hour
    ) {
      const preorderDate = moment(
        `${this.selectedPreorderDate.date} ${this.selectedPreorderDate.hour}`,
        "DD-MM-YYYY HH:mm"
      );

      const tomorrowDate = moment()
        .add(1, "day")
        .set("hour", 0)
        .set("minutes", 0);

      if (preorderDate.isAfter(tomorrowDate)) {
        this.preorderDateIsAfterToday = true;
      } else {
        this.preorderDateIsAfterToday = false;
      }
    } else {
      this.preorderDateIsAfterToday = false;
    }
  }

  prdWithoutImage(product) {
    return (
      !product.image ||
      (product.image && !product.image.true) ||
      (product.image && !product.image.true["16x9"]) ||
      (product.image &&
        product.image.true["16x9"] &&
        !product.image.true["16x9"].path)
    );
  }

  showProductDetailsModal(product) {
    let detailsCssClass;
    if (
      product &&
      product.num_of_options &&
      product.num_of_options < 2 &&
      this.prdWithoutImage(product)
    ) {
      detailsCssClass = "productDetailsModalSmall";
    } else if (
      product &&
      product.num_of_options &&
      !product.squareImageDesign &&
      product.num_of_options > 2 &&
      !this.prdWithoutImage(product)
    ) {
      detailsCssClass = "productDetailsModalBig";
    } else if (
      product &&
      product.squareImageDesign &&
      product.num_of_options &&
      product.num_of_options > 2 &&
      window &&
      window.innerWidth &&
      window.innerWidth >= 1200 &&
      !this.prdWithoutImage(product)
    ) {
      detailsCssClass = "productDetailsModalBigSquareImageDesign";
    } else {
      detailsCssClass = "productDetailsModal";
    }
    if (!this.productDetailsModal) {
      this.productDetailsModal = true;
      this.presentProductDetailsModal(product, detailsCssClass);
    }
  }

  async presentProductDetailsModal(product, detailsCssClass) {
    let product_id: string;
    let cart_id: string;
    if (product && product.product_id) {
      product_id = product.product_id;
    }

    if (product && product.cart_id) {
      cart_id = product.product_id;
    }

    this.productDetailsModal = await this.modalCtrl.create({
      component: ProductDetailsComponent,
      cssClass: detailsCssClass,
      animated: true,
      componentProps: {
        product_id: product_id,
        cart_id: cart_id,
      },
      backdropDismiss: false,
    });

    await this.productDetailsModal.present();
    this.productDetailsModal.onDidDismiss().then(() => {
      this.productDetailsModal = null;
    });
  }

  ngOnDestroy(): void {
    if (this.productDetailsModal) {
      this.productDetailsModal.dismiss();
      this.productDetailsModal = null;
    }

    if (this.cartSubscription) {
      this.cartSubscription.unsubscribe();
    }
  }
}
