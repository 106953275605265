import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import { distinctUntilChanged, Subscription } from "rxjs";
import * as fromApp from "../../store/app.reducer";
import * as selectors from "../../store/app.selectors";

@Component({
  selector: "app-order-timeline-modal",
  templateUrl: "./order-timeline-modal.component.html",
  styleUrls: ["./order-timeline-modal.component.scss"],
})
export class OrderTimelineModalComponent implements OnInit, OnDestroy {
  @Input() order: any;
  public storeInfo: any;
  private subscription: Subscription;
  private unsubscribeBackEvent: Subscription;
  constructor(
    private modalController: ModalController,
    private store: Store<fromApp.AppState>,
    private platform: Platform
  ) {}

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );

    this.subscription = this.store
      .select(selectors.getStoreInfo)
      .pipe(distinctUntilChanged())
      .subscribe((storeInfo) => {
        if (storeInfo && !_.isEqual(storeInfo, this.storeInfo)) {
          this.storeInfo = _.cloneDeep(storeInfo);
        }
      });
  }

  closeModal() {
    this.modalController.dismiss();
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
  }
}
