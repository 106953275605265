<ion-item lines="none"
          [ngClass]="coupon.status + '_border'"
          style="margin-bottom: 0.1em; border-radius: 4px">
  <ion-label class="ion-text-wrap">
    <ion-text>
      {{ coupon.description }}
    </ion-text>
    <p style="padding-top: 10px">
      <ion-badge *ngIf="coupon.use_type === 'instore'"
                 color="primary">instore</ion-badge>
      <ion-badge *ngIf="coupon.use_type === 'online' || !coupon.use_type"
                 color="secondary">online</ion-badge>
    </p>
    <p>
      <ion-badge *ngIf="coupon.status"
                 [color]="badgeColor">
        {{ coupon.status | translate }}
        <div *ngIf="coupon.status==='used'"
             style="margin-top: 5px;">
          <span>
            {{(coupon.use_timestamp ? coupon.use_timestamp : coupon.instore_use_timestamp) | date:
            'dd/MM/YY, HH:mm'}}
          </span>
        </div>
      </ion-badge>
    </p>

    <p *ngIf="coupon.discountType === 'fixed'">
      {{ "loyaltySystem.discount-amount" | translate }}: {{ coupon.discount }}€
    </p>
    <p *ngIf="coupon.discountType === 'free_product'">
      {{ "loyaltySystem.gift" | translate }}: {{ coupon.discount }}
      {{ coupon.product }}
    </p>
    <p *ngIf="coupon.activeFrom && !coupon.activeTo">
      {{ "loyaltySystem.valid-from" | translate }}:
      {{ coupon.activeFrom | date : "dd/MM" }}
    </p>
    <p *ngIf="coupon.activeFrom && coupon.activeTo">
      {{ "loyaltySystem.valid-from" | translate }}:
      {{ coupon.activeFrom | date : "dd/MM" }}
      {{ "loyaltySystem.valid-to" | translate }}
      {{ coupon.activeTo | date : "dd/MM" }}
    </p>
  </ion-label>
  <ion-badge slot="end"
             *ngIf="coupon.short_coupon_code"
             style="vertical-align: bottom; font-size: xx-large; padding: 10px">
    <strong style="padding: 30">{{ coupon.short_coupon_code }}</strong>
  </ion-badge>
  <!-- <ion-button style="min-width: 120px;" [disabled]="couponIsLoading || coupon.use_type === 'online' || !coupon.use_type ||coupon.status !== 'active'" slot="end" (click)="presentRedeemAlert()">
    <ion-text *ngIf="!showSpinner">
      {{'loyaltySystem.home-redeem' | translate}}
    </ion-text>
    <ion-spinner *ngIf="showSpinner"></ion-spinner>
  </ion-button> -->
</ion-item>