<ion-radio-group
  *ngIf="
    option &&
    option.choices &&
    option.choices.length &&
    !option.always_split_columns
  "
  allow-empty-selection
  (ionChange)="onChoiceSelectedChange($event)"
  [(ngModel)]="selectedChoiceId"
>
  <div *ngFor="let choice of option.choices; trackBy: trackFn">
    <app-no-image-radio-choice
      *ngIf="!checkForImage(choice)"
      [option_id]="option_id"
      [choice_id]="choice.choice_id"
      [selectedProductId]="selectedProductId"
      [alt_dinein_active]="alt_dinein_active"
      [groupStoreData]="groupStoreData"
    ></app-no-image-radio-choice>

    <app-image-radio-choice
      *ngIf="checkForImage(choice)"
      [option_id]="option_id"
      [choice_id]="choice.choice_id"
      [imagePath]="imagePath"
      [selectedProductId]="selectedProductId"
      [alt_dinein_active]="alt_dinein_active"
      [groupStoreData]="groupStoreData"
    ></app-image-radio-choice>
  </div>
</ion-radio-group>

<ion-radio-group
  allow-empty-selection
  (ionChange)="onChoiceSelectedChange($event)"
  [(ngModel)]="selectedChoiceId"
  *ngIf="
    option &&
    option.choices &&
    option.choices.length &&
    option.always_split_columns
  "
>
  <ion-row
    style="width: 100%"
    class="ion-no-padding ion-no-margin"
    class="ion-align-items-center"
  >
    <ion-col
      *ngFor="let choice of option.choices; trackBy: trackFn"
      [size]="colSize"
      class="ion-no-padding ion-no-margin"
    >
      <app-no-image-radio-choice
        *ngIf="!checkForImage(choice)"
        [option_id]="option_id"
        [choice_id]="choice.choice_id"
        [selectedProductCategory]="selectedProductCategory"
        [selectedProductPromo]="selectedProductPromo"
        [selectedProductId]="selectedProductId"
        [alt_dinein_active]="alt_dinein_active"
        [groupStoreData]="groupStoreData"
      ></app-no-image-radio-choice>

      <app-image-radio-choice
        *ngIf="checkForImage(choice)"
        [option_id]="option_id"
        [choice_id]="choice.choice_id"
        [selectedProductCategory]="selectedProductCategory"
        [selectedProductPromo]="selectedProductPromo"
        [imagePath]="imagePath"
        [selectedProductId]="selectedProductId"
        [alt_dinein_active]="alt_dinein_active"
        [groupStoreData]="groupStoreData"
      ></app-image-radio-choice>
    </ion-col>
  </ion-row>
</ion-radio-group>
