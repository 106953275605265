import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { auditTime, delay, distinctUntilChanged, map, tap } from "rxjs/operators";
import { Store } from "@ngrx/store";
import * as fromApp from "../store/app.reducer";
import * as AuthActions from "./store/auth.actions";
import * as _ from "lodash";
import { Subscription, timer } from "rxjs";
import { timeout, retry } from "rxjs/operators";


@Injectable({ providedIn: "root" })
export class AuthService {
  token: string;
  expirationDate: Date;
  user: any;
  localserver: any;
  connecting: boolean;
  private tokenSubscription: Subscription;
  private subscription: Subscription;
  public currentUrl: string;
  public server: any;
  public posClientToken: string;
  public loadspinner = true;
  public loading = false;
  constructor(
    private http: HttpClient,
    private store: Store<fromApp.AppState>
  ) {}

  isUserLoggedIn() {
    // return this.store.select('auth').pipe(map(res=>{
    //   console.log('authservice',res);
    //   return res.user
    // })
    // )
  }
}
