<div
  *ngIf="
    widthTitle &&
    windowDimensions &&
    ((product.showOptionInDescriptionObject &&
      product.showOptionInDescriptionObject.choices &&
      product.showOptionInDescriptionObject.choices.length) ||
      (product.showOptionInDescriptionObject_takeaway &&
        product.showOptionInDescriptionObject_takeaway.choices &&
        product.showOptionInDescriptionObject_takeaway.choices.length) ||
      (product.showOptionInDescriptionObject_dinein &&
        product.showOptionInDescriptionObject_dinein.choices &&
        product.showOptionInDescriptionObject_dinein.choices.length) ||
      (product.showOptionInDescriptionObject_alt_dinein &&
        product.showOptionInDescriptionObject_alt_dinein.choices &&
        product.showOptionInDescriptionObject_alt_dinein.choices.length))
  "
>
  <div
    *ngIf="
      (orderPickup == null && dineIn == null) ||
      (orderPickup == false && dineIn == false)
    "
  >
    <ion-col
      style="padding: 0"
      size="12"
      *ngIf="
        product &&
        product.showOptionInDescriptionObject &&
        product.showOptionInDescriptionObject.choices &&
        product.showOptionInDescriptionObject.choices.length
      "
    >
      <span
        style="font-weight: bold"
        *ngFor="
          let ch of product.showOptionInDescriptionObject.choices
            | filterShowInDescriptionObjectChoices
        "
      >
        <div style="padding-bottom: 3px; padding-top: 3px">
          <div
            [ngStyle]="{
              'width.px': widthTitle,
              'font-size': fontSizeDiv,
              display: 'inline-block'
            }"
          >
            {{ ch.title }}
          </div>
          <ion-text
            color="primary"
            [ngStyle]="{
              'font-size': fontSizePrice
            }"
          >
            {{ ch.price }}

            <div
              style="text-align: right; line-height: 0.8"
              *ngIf="
                ch.points &&
                windowDimensions &&
                windowDimensions.width > 335 &&
                maxTitleLength > 8 &&
                windowDimensions.width < 360
              "
            >
              <ion-text
                color="dark"
                [ngStyle]="{
                  'font-size': fontSizePointsDiv
                }"
                >{{ ch.points }}
                {{ "points-translation" | translate }}</ion-text
              >
            </div>
          </ion-text>

          <ion-text
            *ngIf="
              ch.points &&
              windowDimensions &&
              windowDimensions.width > 335 &&
              ((maxTitleLength <= 8 && windowDimensions.width < 360) ||
                windowDimensions.width >= 360)
            "
          >
            &nbsp;|
            <span
              [ngStyle]="{
                'font-size': fontSizePointsDiv
              }"
              >{{ ch.points }} {{ "points-translation" | translate }}</span
            >
          </ion-text>
        </div>
      </span>
    </ion-col>
  </div>

  <div *ngIf="orderPickup == true && dineIn == false">
    <ion-col
      style="padding: 0"
      size="12"
      *ngIf="
        product &&
        !(
          product.showOptionInDescriptionObject_takeaway &&
          product.showOptionInDescriptionObject_takeaway.choices &&
          product.showOptionInDescriptionObject_takeaway.choices.length
        ) &&
        product.showOptionInDescriptionObject &&
        product.showOptionInDescriptionObject.choices &&
        product.showOptionInDescriptionObject.choices.length
      "
    >
      <span
        style="font-weight: bold"
        *ngFor="
          let ch of product.showOptionInDescriptionObject.choices
            | filterShowInDescriptionObjectChoices
        "
      >
        <div style="padding-bottom: 3px; padding-top: 3px">
          <div
            [ngStyle]="{
              'width.px': widthTitle,
              'font-size': 'smaller',
              display: 'inline-block'
            }"
          >
            {{ ch.title }}
          </div>
          <ion-text
            color="primary"
            [ngStyle]="{
              'font-size': fontSizePrice
            }"
          >
            {{ ch.price }}

            <div
              style="text-align: right; line-height: 0.8"
              *ngIf="
                ch.points &&
                windowDimensions &&
                windowDimensions.width > 335 &&
                maxTitleLength > 8 &&
                windowDimensions.width < 360
              "
            >
              <ion-text
                color="dark"
                [ngStyle]="{
                  'font-size': fontSizePointsDiv
                }"
                >{{ ch.points }}
                {{ "points-translation" | translate }}</ion-text
              >
            </div>
          </ion-text>

          <ion-text
            *ngIf="
              ch.points &&
              windowDimensions &&
              windowDimensions.width > 335 &&
              ((maxTitleLength <= 8 && windowDimensions.width < 360) ||
                windowDimensions.width >= 360)
            "
          >
            &nbsp;|
            <span
              [ngStyle]="{
                'font-size': fontSizePointsDiv
              }"
              >{{ ch.points }} {{ "points-translation" | translate }}</span
            >
          </ion-text>
        </div>
      </span>
    </ion-col>

    <ion-col
      style="padding: 0"
      size="12"
      *ngIf="
        product &&
        product.showOptionInDescriptionObject_takeaway &&
        product.showOptionInDescriptionObject_takeaway.choices &&
        product.showOptionInDescriptionObject_takeaway.choices.length
      "
    >
      <span
        style="font-weight: bold"
        *ngFor="
          let ch of product.showOptionInDescriptionObject_takeaway.choices
            | filterShowInDescriptionObjectChoices
        "
      >
        <div style="padding-bottom: 3px; padding-top: 3px">
          <div
            [ngStyle]="{
              'width.px': widthTitle,
              'font-size': 'smaller',
              display: 'inline-block'
            }"
          >
            {{ ch.title }}
          </div>
          <ion-text
            color="primary"
            [ngStyle]="{
              'font-size': fontSizePrice
            }"
          >
            {{ ch.price }}

            <div
              style="text-align: right; line-height: 0.8"
              *ngIf="
                ch.points &&
                windowDimensions &&
                windowDimensions.width > 335 &&
                maxTitleLength > 8 &&
                windowDimensions.width < 360
              "
            >
              <ion-text
                color="dark"
                [ngStyle]="{
                  'font-size': fontSizePointsDiv
                }"
                >{{ ch.points }}
                {{ "points-translation" | translate }}</ion-text
              >
            </div>
          </ion-text>

          <ion-text
            *ngIf="
              ch.points &&
              windowDimensions &&
              windowDimensions.width > 335 &&
              ((maxTitleLength <= 8 && windowDimensions.width < 360) ||
                windowDimensions.width >= 360)
            "
          >
            &nbsp;|
            <span
              [ngStyle]="{
                'font-size': fontSizePointsDiv
              }"
              >{{ ch.points }} {{ "points-translation" | translate }}</span
            >
          </ion-text>
        </div>
      </span>
    </ion-col>
  </div>

  <div
    *ngIf="
      orderPickup == false &&
      dineIn == true &&
      (!store_info.alt_dinein_active ||
        !selectedDineInTable ||
        !selectedDineInTable.alt_dinein)
    "
  >
    <ion-col
      style="padding: 0"
      size="12"
      *ngIf="
        product &&
        !(
          product.showOptionInDescriptionObject_dinein &&
          product.showOptionInDescriptionObject_dinein.choices &&
          product.showOptionInDescriptionObject_dinein.choices.length
        ) &&
        product.showOptionInDescriptionObject &&
        product.showOptionInDescriptionObject.choices &&
        product.showOptionInDescriptionObject.choices.length
      "
    >
      <span
        style="font-weight: bold"
        *ngFor="
          let ch of product.showOptionInDescriptionObject.choices
            | filterShowInDescriptionObjectChoices
        "
      >
        <div style="padding-bottom: 3px; padding-top: 3px">
          <div
            [ngStyle]="{
              'width.px': widthTitle,
              'font-size': 'smaller',
              display: 'inline-block'
            }"
          >
            {{ ch.title }}
          </div>

          <ion-text
            color="primary"
            [ngStyle]="{
              'font-size': fontSizePrice
            }"
          >
            {{ ch.price }}

            <div
              style="text-align: right; line-height: 0.8"
              *ngIf="
                ch.points &&
                windowDimensions &&
                windowDimensions.width > 335 &&
                maxTitleLength > 8 &&
                windowDimensions.width < 360
              "
            >
              <ion-text
                color="dark"
                [ngStyle]="{
                  'font-size': fontSizePointsDiv
                }"
                >{{ ch.points }}
                {{ "points-translation" | translate }}</ion-text
              >
            </div>
          </ion-text>

          <ion-text
            *ngIf="
              ch.points &&
              windowDimensions &&
              windowDimensions.width > 335 &&
              ((maxTitleLength <= 8 && windowDimensions.width < 360) ||
                windowDimensions.width >= 360)
            "
          >
            &nbsp;|
            <span
              [ngStyle]="{
                'font-size': fontSizePointsDiv
              }"
              >{{ ch.points }} {{ "points-translation" | translate }}</span
            >
          </ion-text>
        </div>
      </span>
    </ion-col>
    <ion-col
      style="padding: 0"
      size="12"
      *ngIf="
        product &&
        product.showOptionInDescriptionObject_dinein &&
        product.showOptionInDescriptionObject_dinein.choices &&
        product.showOptionInDescriptionObject_dinein.choices.length
      "
    >
      <span
        style="font-weight: bold"
        *ngFor="
          let ch of product.showOptionInDescriptionObject_dinein.choices
            | filterShowInDescriptionObjectChoices
        "
      >
        <div style="padding-bottom: 3px; padding-top: 3px">
          <div
            [ngStyle]="{
              'width.px': widthTitle,
              'font-size': 'smaller',
              display: 'inline-block'
            }"
          >
            {{ ch.title }}
          </div>
          <ion-text
            color="primary"
            [ngStyle]="{
              'font-size': fontSizePrice
            }"
          >
            {{ ch.price }}

            <div
              style="text-align: right; line-height: 0.8"
              *ngIf="
                ch.points &&
                windowDimensions &&
                windowDimensions.width > 335 &&
                maxTitleLength > 8 &&
                windowDimensions.width < 360
              "
            >
              <ion-text
                color="dark"
                [ngStyle]="{
                  'font-size': fontSizePointsDiv
                }"
                >{{ ch.points }}
                {{ "points-translation" | translate }}</ion-text
              >
            </div>
          </ion-text>

          <ion-text
            *ngIf="
              ch.points &&
              windowDimensions &&
              windowDimensions.width > 335 &&
              ((maxTitleLength <= 8 && windowDimensions.width < 360) ||
                windowDimensions.width >= 360)
            "
          >
            &nbsp;|
            <span
              [ngStyle]="{
                'font-size': fontSizePointsDiv
              }"
              >{{ ch.points }} {{ "points-translation" | translate }}</span
            >
          </ion-text>
        </div>
      </span>
    </ion-col>
  </div>

  <div
    *ngIf="
      orderPickup == false &&
      dineIn == true &&
      store_info.alt_dinein_active &&
      selectedDineInTable &&
      selectedDineInTable.alt_dinein
    "
  >
    <ion-col
      style="padding: 0"
      size="12"
      *ngIf="
        product &&
        !(
          product.showOptionInDescriptionObject_alt_dinein &&
          product.showOptionInDescriptionObject_alt_dinein.choices &&
          product.showOptionInDescriptionObject_alt_dinein.choices.length
        ) &&
        !(
          product.showOptionInDescriptionObject_dinein &&
          product.showOptionInDescriptionObject_dinein.choices &&
          product.showOptionInDescriptionObject_dinein.choices.length
        ) &&
        product.showOptionInDescriptionObject &&
        product.showOptionInDescriptionObject.choices &&
        product.showOptionInDescriptionObject.choices.length
      "
    >
      <span
        style="font-weight: bold"
        *ngFor="
          let ch of product.showOptionInDescriptionObject.choices
            | filterShowInDescriptionObjectChoices
        "
      >
        <div style="padding-bottom: 3px; padding-top: 3px">
          <div
            [ngStyle]="{
              'width.px': widthTitle,
              'font-size': 'smaller',
              display: 'inline-block'
            }"
          >
            {{ ch.title }}
          </div>

          <ion-text
            color="primary"
            [ngStyle]="{
              'font-size': fontSizePrice
            }"
          >
            {{ ch.price }}

            <div
              style="text-align: right; line-height: 0.8"
              *ngIf="
                ch.points &&
                windowDimensions &&
                windowDimensions.width > 335 &&
                maxTitleLength > 8 &&
                windowDimensions.width < 360
              "
            >
              <ion-text
                color="dark"
                [ngStyle]="{
                  'font-size': fontSizePointsDiv
                }"
                >{{ ch.points }}
                {{ "points-translation" | translate }}</ion-text
              >
            </div>
          </ion-text>

          <ion-text
            *ngIf="
              ch.points &&
              windowDimensions &&
              windowDimensions.width > 335 &&
              ((maxTitleLength <= 8 && windowDimensions.width < 360) ||
                windowDimensions.width >= 360)
            "
          >
            &nbsp;|
            <span
              [ngStyle]="{
                'font-size': fontSizePointsDiv
              }"
              >{{ ch.points }} {{ "points-translation" | translate }}</span
            >
          </ion-text>
        </div>
      </span>
    </ion-col>
    <ion-col
      style="padding: 0"
      size="12"
      *ngIf="
        product &&
        !(
          product.showOptionInDescriptionObject_alt_dinein &&
          product.showOptionInDescriptionObject_alt_dinein.choices &&
          product.showOptionInDescriptionObject_alt_dinein.choices.length
        ) &&
        product.showOptionInDescriptionObject_dinein &&
        product.showOptionInDescriptionObject_dinein.choices &&
        product.showOptionInDescriptionObject_dinein.choices.length
      "
    >
      <span
        style="font-weight: bold"
        *ngFor="
          let ch of product.showOptionInDescriptionObject_dinein.choices
            | filterShowInDescriptionObjectChoices
        "
      >
        <div style="padding-bottom: 3px; padding-top: 3px">
          <div
            [ngStyle]="{
              'width.px': widthTitle,
              'font-size': 'smaller',
              display: 'inline-block'
            }"
          >
            {{ ch.title }}
          </div>
          <ion-text
            color="primary"
            [ngStyle]="{
              'font-size': fontSizePrice
            }"
          >
            {{ ch.price }}

            <div
              style="text-align: right; line-height: 0.8"
              *ngIf="
                ch.points &&
                windowDimensions &&
                windowDimensions.width > 335 &&
                maxTitleLength > 8 &&
                windowDimensions.width < 360
              "
            >
              <ion-text
                color="dark"
                [ngStyle]="{
                  'font-size': fontSizePointsDiv
                }"
                >{{ ch.points }}
                {{ "points-translation" | translate }}</ion-text
              >
            </div>
          </ion-text>

          <ion-text
            *ngIf="
              ch.points &&
              windowDimensions &&
              windowDimensions.width > 335 &&
              ((maxTitleLength <= 8 && windowDimensions.width < 360) ||
                windowDimensions.width >= 360)
            "
          >
            &nbsp;|
            <span
              [ngStyle]="{
                'font-size': fontSizePointsDiv
              }"
              >{{ ch.points }} {{ "points-translation" | translate }}</span
            >
          </ion-text>
        </div>
      </span>
    </ion-col>

    <ion-col
      style="padding: 0"
      size="12"
      *ngIf="
        product &&
        product.showOptionInDescriptionObject_alt_dinein &&
        product.showOptionInDescriptionObject_alt_dinein.choices &&
        product.showOptionInDescriptionObject_alt_dinein.choices.length
      "
    >
      <span
        style="font-weight: bold"
        *ngFor="
          let ch of product.showOptionInDescriptionObject_alt_dinein
            | filterShowInDescriptionObjectChoices
        "
      >
        <div style="padding-bottom: 3px; padding-top: 3px">
          <div
            [ngStyle]="{
              'width.px': widthTitle,
              'font-size': 'smaller',
              display: 'inline-block'
            }"
          >
            {{ ch.title }}
          </div>

          <ion-text
            color="primary"
            [ngStyle]="{
              'font-size': fontSizePrice
            }"
          >
            {{ ch.price }}

            <div
              style="text-align: right; line-height: 0.8"
              *ngIf="
                ch.points &&
                windowDimensions &&
                windowDimensions.width > 335 &&
                maxTitleLength > 8 &&
                windowDimensions.width < 360
              "
            >
              <ion-text
                color="dark"
                [ngStyle]="{
                  'font-size': fontSizePointsDiv
                }"
                >{{ ch.points }}
                {{ "points-translation" | translate }}</ion-text
              >
            </div>
          </ion-text>

          <ion-text
            *ngIf="
              ch.points &&
              windowDimensions &&
              windowDimensions.width > 335 &&
              ((maxTitleLength <= 8 && windowDimensions.width < 360) ||
                windowDimensions.width >= 360)
            "
          >
            &nbsp;|
            <span
              [ngStyle]="{
                'font-size': fontSizePointsDiv
              }"
              >{{ ch.points }} {{ "points-translation" | translate }}</span
            >
          </ion-text>
        </div>
      </span>
    </ion-col>
  </div>
</div>
