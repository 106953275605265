import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import { Subscription } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import * as fromApp from "../../../store/app.reducer";
import * as selectors from "../../../store/app.selectors";

@Component({
  selector: "app-category-item",
  templateUrl: "./category-item.component.html",
  styleUrls: ["./category-item.component.scss"],
})
export class CategoryItemComponent implements OnInit, OnDestroy {
  @Input() category_id;
  @Input() groupStoreData;
  public category: any;
  private subscription: Subscription;

  constructor(private store: Store<fromApp.AppState>) {}

  ngOnInit() {
    this.subscription = this.store
      .select(selectors.getCategoryByCategoryId(this.category_id))
      .pipe(distinctUntilChanged())

      .subscribe((category) => {
        if (category && !_.isEqual(category, this.category)) {
          this.category = _.cloneDeep(category);
        }
      });
  }

  //TO_DO CHECK TRACK FUNCTION TO RETRUN ITEM ATTR
  trackFunc(index, item) {
    return index;
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
