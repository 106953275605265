<ion-header class="custom-header">
  <app-no-internet-connection></app-no-internet-connection>
  <app-ionic-update-error></app-ionic-update-error>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="navigateHome()">
        <ion-icon slot="icon-only" color="primary" name="home"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title
      class="ion-text-center"
      style="
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        font-size: medium;
        font-weight: bold;
      "
    >
      {{ "reservation-app.points" | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid
    *ngIf="usePointsAnimation"
    style="
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50px;
      z-index: 1000;
    "
  >
    <ion-row
      style="width: 100%; height: 100%"
      class="ion-align-items-center ion-justify-content-center"
    >
      <ion-col class="ion-align-self-center ion-text-center">
        <ion-grid style="max-width: 350px">
          <ng-lottie
            (animationCreated)="animationCreated($event)"
            (loopComplete)="animationComplete($event)"
            [options]="usePointsSuccessAnimationOptions"
          ></ng-lottie>
        </ion-grid>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid>
    <div style="max-width: 500px; margin: auto">
      <ion-item
        lines="none"
        class="ion-text-center"
        *ngIf="(loginState && loginState === 'login_loading')"
      >
        <ion-label>
          <ion-spinner></ion-spinner>
        </ion-label>
      </ion-item>
      <ion-item
        style="height: 53px"
        lines="none"
        class="ion-text-center"
        *ngIf="!currentUser && loginState && loginState === 'no_login'"
      >
        <ion-label class="ion-text-wrap">
          {{"reservation-app.connectToGetAccess"| translate}}
        </ion-label>
      </ion-item>
      <ion-item
        lines="none"
        *ngIf="!currentUser && loginState && loginState === 'no_login'"
      >
        <ion-button
          shape="round"
          style="width: 80%; height: 35px; margin-left: 10%"
          (click)="presentModalLogin()"
          >{{"reservation-app.login" |translate}}</ion-button
        >
      </ion-item>

      <ion-item
        *ngIf="loginState && loginState === 'login_completed'"
        style="
          margin-left: 10px;
          margin-right: 10px;
          border-radius: 10px;
          margin-top: 10px;
        "
        lines="none"
        color="light"
      >
        <ion-label *ngIf="currentUser"
          >{{"reservation-app.active-points" |translate}}:</ion-label
        >

        <ion-label
          *ngIf="currentUser && (userPoints && userPoints.pointsSystem && (userPoints.pointsSystem.active || userPoints.pointsSystem.active===0))"
          style="font-weight: bold"
          slot="end"
        >
          {{userPoints.pointsSystem.active}}
          {{"reservation-app.point"|translate}}</ion-label
        >

        <ion-button
          (click)="presentReservationPointsProfileModal()"
          style="margin-left: 5px"
          slot="end"
          size="small"
          fill="clear"
        >
          <ion-icon slot="icon-only" name="information-circle-outline">
          </ion-icon>
        </ion-button>
      </ion-item>
      <ion-label
        *ngIf="currentUser && nextUserLoyaltyCardLevel && nextUserLoyaltyCardLevel.name"
        style="font-size: 13px; margin-left: 10px"
        class="ion-text-wrap"
        >{{"reservation-app.uNeed"| translate}}
        {{nextUserLoyaltyCardLevel.points}}
        {{"reservation-app.pointsToWIn"|translate}} {{
        nextUserLoyaltyCardLevel.name[selectedLangugage] ?
        nextUserLoyaltyCardLevel.name[mo_base_lang] :
        nextUserLoyaltyCardLevel.id }}</ion-label
      >

      <ion-card
        color="primary"
        style="
          margin: auto;
          height: 54mm;
          width: 85mm;
          border-radius: 20px;
          background: url('assets/icons/pink.png') center/cover no-repeat;
        "
        *ngIf="!currentUser && loginState && loginState === 'no_login' && groupApp"
      >
        <ion-card-header>
          <ion-card-title style="font-size: 18px; font-weight: bold"
            >{{groupApp.app_name}}</ion-card-title
          >
        </ion-card-header>

        <ion-card-content style="font-size: 36px; font-weight: bold"
          >{{"reservation-app.vipCard" |translate}}</ion-card-content
        >
      </ion-card>

      <ion-card
        color="primary"
        style="
          height: 54mm;
          width: 85mm;
          border-radius: 20px;
          background: url('assets/icons/pink.png') center/cover no-repeat;
          margin: auto;
        "
        *ngIf="loginState && loginState === 'login_completed' && groupApp && groupApp.points && groupApp.points.loyaltyCardLevelsActive && groupApp.points.loyaltyCardLevels && groupApp.points.loyaltyCardLevels.length"
      >
        <ion-card-header *ngIf="currentUser">
          <ion-card-title style="font-size: 18px; font-weight: bold"
            >{{groupApp.app_name}}</ion-card-title
          >
          <ion-card-title
            *ngIf="!currentUser.current_loyalty_card_level"
            style="font-size: 30px; font-weight: bold; margin-top: 15px"
            >MEMBERS</ion-card-title
          >

          <ion-card-title
            *ngIf="currentUser.current_loyalty_card_level"
            style="font-size: 30px; font-weight: bold; margin-top: 15px"
            >{{ currentUser.current_loyalty_card_level.name[selectedLangugage] ?
            currentUser.current_loyalty_card_level.name[mo_base_lang] :
            currentUser.current_loyalty_card_level.id }}</ion-card-title
          >
          <ion-card-title>CARD</ion-card-title>
          <ion-card-title style="font-size: 10px; margin-top: 12px"
            >{{firstName}} {{lastName}}</ion-card-title
          >

          <div style="position: absolute; bottom: 20px; right: 15px">
            <qrcode
              [qrdata]="currentUser.customer_id"
              [width]="80"
              [errorCorrectionLevel]="'H'"
              [margin]="1"
            ></qrcode>
          </div>
        </ion-card-header>
      </ion-card>
    </div>

    <ion-item lines="none">
      <ion-label
        [class.ion-text-center]="textPosition"
        style="font-weight: bold; font-size: 22px"
        >{{"reservation-app.vipBenefits" |translate}}</ion-label
      >
    </ion-item>

    <ion-card
      color="light"
      style="
        height: 100%;
        width: 92%;
        border-radius: 20px;
        margin: auto;
        max-width: 600px;
      "
    >
      <ion-card-header>
        <ion-label style="font-weight: bold"
          ><span class="highlighted-word">•</span>
          {{"reservation-app.easyBook"|translate}}</ion-label
        >
        <hr style="border: 0.5px solid #000; margin: 10px 0" />
        <!-- Add a horizontal line -->
        <ion-label style="font-weight: bold"
          ><span class="highlighted-word">•</span>
          {{"reservation-app.reservationNopoints"|translate}}</ion-label
        >
        <hr style="border: 0.2px solid #000; margin: 10px 0" />
        <!-- Add a horizontal line -->
        <ion-label style="font-weight: bold"
          ><span class="highlighted-word">•</span>
          {{"reservation-app.priority"|translate}}</ion-label
        >
      </ion-card-header>
    </ion-card>

    <ion-item lines="none" style="margin: auto; margin-top: 10px">
      <ion-label
        [class.ion-text-center]="textPosition"
        class="ion-text-wrap"
        style="font-weight: bold; font-size: 22px"
        >{{"reservation-app.bonusMember"|translate}}</ion-label
      >
    </ion-item>

    <app-reservation-rewards
      [userPoints]="userPoints"
      (rewardsEvents)="rewardsEventsHandler($event)"
    >
    </app-reservation-rewards>
  </ion-grid>
</ion-content>
