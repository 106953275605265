<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button fill="clear" (click)="closeModal()">
        <ion-icon src="assets/ionicons/close.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <ion-text>{{'loyaltySystem.hof.hof' | translate}}</ion-text>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid *ngIf="loading" style="max-width: 800px;">
    <ion-row>
      <ion-col class="ion-align-self-center ion-text-center">
        <ion-spinner>

        </ion-spinner>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid *ngIf="hallOfFame && currentUser" style="max-width: 800px;">
    <ion-row style="background-color: #E6E6E6;">
      <ion-col size="2" class="ion-text-center" style="font-weight: bold;">
        Θέση
      </ion-col>
      <ion-col size="2">

      </ion-col>
      <ion-col size="5" class="ion-text-left" style="font-weight: bold;">
        <ion-text style="margin-left: 10px;">
          Ονοματεπώνυμο
        </ion-text>
      </ion-col>
      <ion-col size="3" class="ion-text-right" style="font-weight: bold;">
        Προϊόντα
      </ion-col>
    </ion-row>
    <ion-row *ngFor="let item of hallOfFame; let i=index" class="ion-justify-content-center ion-align-items-center"
      [ngStyle]="{'background-color': i%2 === 0 ? '#e4e4e461' : ''}" [id]="item.customer_id === currentUser.customer_id? 'currentUser': ''">
      <ion-col size="2" class="ion-align-self-center ion-text-center">
        <ion-icon style="font-size: xxx-large;" *ngIf="item.rank === 1" src="/assets/flatIcons/first-rank.svg"></ion-icon>
        <ion-icon style="font-size: xxx-large;" *ngIf="item.rank === 2" src="/assets/flatIcons/second-rank.svg"></ion-icon>
        <ion-icon style="font-size: xxx-large;" *ngIf="item.rank === 3" src="/assets/flatIcons/third-rank.svg"></ion-icon>
        <ion-text *ngIf="item.rank !== 1 && item.rank !== 2 && item.rank !== 3">
          {{item.rank}}
        </ion-text>
      </ion-col>
      <ion-col size="2" class="ion-align-self-center ion-text-center">
        <!-- <ion-item lines="none" style="--background:transparent;"> -->
          <img *ngIf="groupApp && item.profile_image && item.profile_image.optimized" style="width: 40px; height:40px; border-radius: 4px;" alt="Silhouette of a person's head"
          [src]="'https://primg.deliverymanager.gr/'+ groupApp.group + '/'+ item.customer_id + '/' + item.profile_image.filename" />

          <img *ngIf="!groupApp || (item.profile_image && !item.profile_image.optimized) || !item.profile_image" style="width: 40px; height:40px; border-radius: 4px;" alt="Silhouette of a person's head" src="https://ionicframework.com/docs/img/demos/avatar.svg" />
          <!-- <ion-avatar>
          </ion-avatar> -->
        <!-- </ion-item> -->
      </ion-col>
      <ion-col size="6" class="ion-align-self-center ion-text-left">
        <ion-text style="margin-left: 10px;">
          {{item.firstName}} {{item.lastName}} <ion-text *ngIf="item.customer_id === currentUser.customer_id">(Εσύ)</ion-text>
        </ion-text>
      </ion-col>
      <ion-col size="2" class="ion-align-self-center ion-text-right">
        <ion-badge style="margin-right:10px;">
          {{item.products}}
        </ion-badge>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
