<ion-item
  (click)="coupon.status === 'active' ? openKioskCoupounQrDisplayModal() : ''"
  [button]="coupon.status === 'active' ? true : false"
  lines="full"
  detail="false"
  *ngIf="coupon"
  [ngClass]="coupon.status + '_border'"
>
  <ion-icon
    *ngIf="coupon.status === 'active'"
    style="font-size: 35px; margin: 0"
    slot="start"
    src="assets/flatIcons/magnifier-qr.svg"
  >
  </ion-icon>
  <div
    *ngIf="coupon.status !== 'active'"
    slot="start"
    style="width: 35px; margin: 0"
  ></div>
  <ion-row style="width: 100%">
    <ion-col size="12">
      <ion-label class="ion-no-padding" class="ion-text-wrap">
        <p *ngIf="coupon.description">
          <strong>{{ coupon.description }}</strong>
        </p>

        <p
          *ngIf="
            coupon.use_type &&
            coupon.use_type === 'third_party' &&
            coupon.third_party_code
          "
        >
          {{
            "reservation-app.discount-coupons.reservation-coupons-item.third_party_code"
              | translate
          }}:
          {{ coupon.third_party_code }}
        </p>
        <p
          style="font-size: x-small; font-weight: 700"
          *ngIf="coupon.label && coupon.appliesToOrderGreaterThan > 0"
        >
          {{
            "reservation-app.discount-coupons.reservation-coupons-item.minimum-order"
              | translate
          }}: {{ coupon.appliesToOrderGreaterThan }}‎€
        </p>
        <p
          style="font-size: x-small; font-weight: 700"
          *ngIf="coupon.activeFrom || coupon.activeTo"
        >
          {{
            "reservation-app.discount-coupons.reservation-coupons-item.valid"
              | translate
          }}&nbsp;
          {{
            coupon.activeFrom
              ? ("reservation-app.discount-coupons.reservation-coupons-item.from"
                  | translate) +
                " " +
                (coupon.activeFrom | date : "dd/MM/yyyy HH:mm") +
                " "
              : ""
          }}
          {{
            coupon.activeTo
              ? ("reservation-app.discount-coupons.reservation-coupons-item.to"
                  | translate) +
                " " +
                (coupon.activeTo | date : "dd/MM/yyyy")
              : ""
          }}
        </p>
        <p *ngIf="coupon.single_store_use">
          {{
            "reservation-app.discount-coupons.reservation-coupons-item.coupon-single-store-use"
              | translate
          }}
        </p>

        <p *ngIf="coupon.preorder_only">
          <ion-text color="danger">
            {{
              "reservation-app.discount-coupons.reservation-coupons-item.coupon-can-be-used-only-for-preorder"
                | translate
            }}
          </ion-text>
        </p>

        <p *ngIf="coupon.status === 'pending_confirmation'">
          <ion-text color="danger">
            {{
              "discount-coupons.coupons-item.coupon-must-be-confirmed-from-store"
                | translate
            }}
          </ion-text>
        </p>
        <p
          *ngIf="
            stores &&
            stores.length > 1 &&
            storesByStoreId &&
            storesByStoreId[coupon.store_id] &&
            selectedLangugage
          "
        >
          {{
            "reservation-app.discount-coupons.reservation-coupons-item.valid-to-store"
              | translate
          }}<strong>
            {{
              storesByStoreId[coupon.store_id].store_name_mobileorder &&
              storesByStoreId[coupon.store_id].store_name_mobileorder[
                selectedLangugage
              ]
                ? storesByStoreId[coupon.store_id].store_name_mobileorder[
                    selectedLangugage
                  ]
                : storesByStoreId[coupon.store_id].store_name_langs &&
                  storesByStoreId[coupon.store_id].store_name_langs[
                    selectedLangugage
                  ]
                ? storesByStoreId[coupon.store_id].store_name_langs[
                    selectedLangugage
                  ]
                : storesByStoreId[coupon.store_id].store_name_langs &&
                  storesByStoreId[coupon.store_id].store_name_langs.el
                ? storesByStoreId[coupon.store_id].store_name_langs.el
                : storesByStoreId[coupon.store_id].store_name
                ? storesByStoreId[coupon.store_id].store_name
                : ""
            }}
          </strong>
        </p>
      </ion-label>
    </ion-col>

    <ion-col size="12" class="ion-text-left" style="padding-top: 0px">
      <ion-badge *ngIf="coupon.status" [color]="badgeColor">
        {{
          "reservation-app.discount-coupons.reservation-coupons-item." +
            coupon.status | translate
        }}
        <ion-text *ngIf="coupon.order_id && coupon.status === 'used'"
          >#{{ coupon.order_id }}</ion-text
        >
      </ion-badge>
    </ion-col>
    <div
      style="position: absolute; bottom: 5px; right: 10px; font-size: x-small"
      *ngIf="coupon && coupon.points"
    >
      {{
        "reservation-app.discount-coupons.reservation-coupons-item.from_lowercase"
          | translate
      }}
      {{ coupon.points }}
      {{
        "reservation-app.discount-coupons.reservation-coupons-item.points"
          | translate
      }}
    </div>
  </ion-row>

  <ion-badge style="min-width: 32px; margin-left: 10px" slot="end">
    <span
      *ngIf="
        coupon.discountType !== 'percent' &&
        coupon.discountType !== 'combined' &&
        coupon.discount
      "
    >
      -{{ coupon.discount }}€
    </span>
    <span *ngIf="coupon.discountType === 'percent' && coupon.discount">
      -{{ coupon.discount }}%
    </span>
    <span
      *ngIf="
        coupon.discountType === 'combined' &&
        coupon.discount_percent &&
        coupon.discount_fixed
      "
    >
      -{{ coupon.discount_percent }}% & -{{ coupon.discount_fixed }}€
    </span>

    <span
      *ngIf="
        !(
          (coupon.discountType !== 'percent' &&
            coupon.discountType !== 'combined' &&
            coupon.discount) ||
          (coupon.discountType === 'combined' &&
            coupon.discount_percent &&
            coupon.discount_fixed)
        ) &&
        coupon.product_id &&
        coupon.classic_offer_product &&
        coupon.tag_comment
      "
    >
      {{ coupon.tag_comment }}
    </span>
    <span
      *ngIf="
        !(
          (coupon.discountType !== 'percent' &&
            coupon.discountType !== 'combined' &&
            coupon.discount) ||
          (coupon.discountType === 'combined' &&
            coupon.discount_percent &&
            coupon.discount_fixed)
        ) &&
        coupon.product_id &&
        !coupon.classic_offer_product
      "
    >
      {{ "free" | translate }}
    </span>
  </ion-badge>
</ion-item>
