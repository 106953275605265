import * as PointsActions from "./points.actions";
import * as _ from "lodash";

export interface State {
  userPoints: any;
  highestReward: any;
  rewards: any;
}

const initialState: State = {
  userPoints: null,
  highestReward: null,
  rewards: null,
};

export function pointsReducer(
  state = initialState,
  action: PointsActions.PointsActions
) {
  switch (action.type) {
    case PointsActions.SET_HIGHEST_REWARD:
      return {
        ...state,
        highestReward: action.payload,
      };

    case PointsActions.SET_REWARDS:
      return {
        ...state,
        rewards: action.payload,
      };
    case PointsActions.SET_USER_POINTS:
      return {
        ...state,
        userPoints: action.payload,
      };

    case PointsActions.CLEAR_POINTS:
      return {
        ...state,
        highestReward: null,
        rewards: null,
        userPoints: null,
      };

    default:
      return state;
  }
}
