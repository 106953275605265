<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">
      <ion-text>
        {{ "delivery-drive.delivery-tracking-modal.title" | translate }}
      </ion-text>
    </ion-title>
    <ion-button
      (click)="closeModal()"
      size="small"
      slot="end"
      fill="clear"
      color="dark"
    >
      <ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid *ngIf="loading">
    <ion-row class="ion-padding" style="width: 100%">
      <ion-col class="ion-text-center">
        <ion-spinner> </ion-spinner>
      </ion-col>
    </ion-row>
  </ion-grid>

  <div
    class="container"
    *ngIf="trakingOrder"
    style="
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 10000;
    "
  >
    <div class="circle">
      <div class="circle-info">
        {{ "Στην πόρτα σου σε περίπου 20'" }}
      </div>
    </div>
  </div>

  <div
    [hidden]="!libraryLoaded || loading"
    id="map"
    class="gmStyle"
    style="width: 100%; height: 100%"
    data-tap-disabled="true"
  ></div>
</ion-content>
