import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../store/app.reducer";
import * as _ from "lodash";
import { auditTime, distinctUntilChanged, first, sample } from "rxjs/operators";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import * as SelectedPaymentMethodIDActions from "./store/selectePaymentMethod.actions";
import { SelectStorePopupComponent } from "src/app/popups/select-store-popup/select-store-popup.component";
import { ModalController } from "@ionic/angular";
import { PaymentGatewaysService } from "src/app/services/payment-gateways/payment-gateways.service";
import * as selectors from "../../../store/app.selectors";
import * as CompleteOrderValidationActions from "../../../store/complete-order-validation/completeOrderValidation.actions";
import { ReservationSelectPaymentMethodModalComponent } from "src/app/reservation-system/reservation-select-payment-method-modal/reservation-select-payment-method-modal.component";

@Component({
  selector: "app-payment-methods",
  templateUrl: "./payment-methods.component.html",
  styleUrls: ["./payment-methods.component.scss"],
})
export class PaymentMethodsComponent implements OnInit, OnDestroy {
  public cartPaymentGateWays: any;
  public storesByStoreId: any;
  public paymentGateways: any;
  public cart: any;
  public currentUser: any;
  public currentStoreId: string;
  public customerInvoiceEnabled: boolean;
  public subscriptions: Subscription[] = [];
  public selectedPaymentMethodID: any;
  public finalPriceLoading: boolean;
  public disableAllPaymentSelections: boolean;
  public valid: boolean = true;
  public darkMode: boolean;
  public storeInfo: any;
  public groupApp: any;
  public loginState: any;
  public numberOfDisplaydPaymentMethods: number = 0;
  public dineIn;
  public orderPickup;

  private selectedTheme: any;
  private lastValidTimestamp: any;
  private checkboxPromotions: any;
  private stores: any;
  private reservationAppSelectionModal;

  constructor(
    private store: Store<fromApp.AppState>,
    private translateService: TranslateService,
    private modalCtrl: ModalController,
    private paymentGatewaysService: PaymentGatewaysService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((storeInfo) => {
          if (storeInfo && !_.isEqual(this.storeInfo, storeInfo)) {
            this.storeInfo = _.cloneDeep(storeInfo);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(state.groupApp, this.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
          }

          if (
            state &&
            state.selectedTheme &&
            !_.isEqual(this.selectedTheme, state.selectedTheme)
          ) {
            this.selectedTheme = _.cloneDeep(state.selectedTheme);
            //console.log("selected theme", this.selectedTheme);
            if (this.selectedTheme === "dark") {
              this.darkMode = true;
            } else {
              this.darkMode = false;
            }
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("completeOrderValidation")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.lastValidTimestamp &&
            !_.isEqual(this.lastValidTimestamp, state.lastValidTimestamp)
          ) {
            this.lastValidTimestamp = _.cloneDeep(state.lastValidTimestamp);
            this.paymentMethodValidationsCart(true);
          }
        })
    );
    this.subscriptions.push(
      this.store.select("disableFinalPrice").subscribe((state) => {
        if (
          state &&
          !_.isEqual(state.disableFinalPrice, this.finalPriceLoading)
        ) {
          this.finalPriceLoading = _.cloneDeep(state.disableFinalPrice);
        }
      })
    );
    this.subscriptions.push(
      this.store.select("selectedPaymentMethodID").subscribe((state) => {
        if (
          state &&
          state.selectedPaymentMethodID &&
          !_.isEqual(
            state.selectedPaymentMethodID,
            this.selectedPaymentMethodID
          )
        ) {
          this.selectedPaymentMethodID = _.cloneDeep(
            state.selectedPaymentMethodID
          );
          this.paymentMethodValidationsCart(false);
        }
      })
    );
    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(auditTime(200))
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && state.user && !_.isEqual(this.currentUser, state.user)) {
            this.currentUser = _.cloneDeep(state.user);
          }

          if (
            state &&
            state.loginState &&
            !_.isEqual(this.loginState, state.loginState)
          ) {
            this.loginState = _.cloneDeep(state.loginState);

            if (this.cartPaymentGateWays) {
              if (
                this.loginState &&
                (this.loginState === "no_login" ||
                  this.loginState === "login_completed")
              ) {
                this.numberOfDisplaydPaymentMethods = 0;
                for (const property in this.cartPaymentGateWays) {
                  if (
                    this.cartPaymentGateWays &&
                    this.cartPaymentGateWays[property] &&
                    this.cartPaymentGateWays[property].active &&
                    (!this.cartPaymentGateWays[property].adminOnly ||
                      (state.user && state.user.admin)) &&
                    (!this.cartPaymentGateWays[property].customerOnly ||
                      (state.user &&
                        ((state.user.admin &&
                          this.cartPaymentGateWays[property].adminOnly) ||
                          (state.user.customerPaymentMethods &&
                            state.user.customerPaymentMethods[property]))))
                  ) {
                    this.numberOfDisplaydPaymentMethods++;
                  }
                }
              }
            }
          }
        })
    );

    this.subscriptions.push(
      this.store.select("stores").subscribe((state) => {
        if (state && state.stores && !_.isEqual(state.stores, this.stores)) {
          this.stores = _.cloneDeep(state.stores);
          this.storesByStoreId = _.groupBy(this.stores, "store_id");
          _.each(this.storesByStoreId, (store, key, list) => {
            this.storesByStoreId[key] = store[0];
          });
        }
      })
    );

    this.subscriptions.push(
      this.store.select("currentStore").subscribe((state) => {
        if (
          state &&
          state.currentStoreId &&
          !_.isEqual(state.currentStoreId, this.currentStoreId)
        ) {
          this.currentStoreId = _.cloneDeep(state.currentStoreId);
        }
      })
    );

    this.subscriptions.push(
      this.store.select("deliveryMethodAddress").subscribe((state) => {
        if (state && !_.isEqual(state.dineIn, this.dineIn)) {
          this.dineIn = _.cloneDeep(state.dineIn);
        }
        if (state && !_.isEqual(state.orderPickup, this.orderPickup)) {
          this.orderPickup = _.cloneDeep(state.orderPickup);
        }
      })
    );

    this.subscriptions.push(
      this.store
        .select("paymentGateWays")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((state) => {
          if (
            state &&
            state.paymentGateWays &&
            !_.isEqual(this.paymentGateways, state.paymentGateWays)
          ) {
            this.paymentGateways = _.cloneDeep(state.paymentGateWays);
            //  console.log("state payment gateways", this.paymentGateways);
          }
          if (
            state &&
            state.cartPaymentGateWays &&
            !_.isEqual(this.cartPaymentGateWays, state.cartPaymentGateWays)
          ) {
            this.cartPaymentGateWays = _.cloneDeep(state.cartPaymentGateWays);

            if (
              this.loginState &&
              (this.loginState === "no_login" ||
                this.loginState === "login_completed")
            ) {
              this.numberOfDisplaydPaymentMethods = 0;
              for (const property in this.cartPaymentGateWays) {
                if (
                  this.cartPaymentGateWays &&
                  this.cartPaymentGateWays[property] &&
                  this.cartPaymentGateWays[property].active &&
                  (!this.cartPaymentGateWays[property].adminOnly ||
                    (this.currentUser && this.currentUser.admin)) &&
                  (!this.cartPaymentGateWays[property].customerOnly ||
                    (this.currentUser &&
                      ((this.currentUser.admin &&
                        this.cartPaymentGateWays[property].adminOnly) ||
                        (this.currentUser.customerPaymentMethods &&
                          this.currentUser.customerPaymentMethods[property]))))
                ) {
                  this.numberOfDisplaydPaymentMethods++;
                }
              }
            }

            // console.log("carttt payment gateways", this.paymentGateways);
          }
        })
    );
    this.checkForOnlineOnlyPaymentPromos();
  }

  paymentMethodValidationsCart(writeToState) {
    let validationObj = {
      id: "payments-methods",
      valid: true,
      errorMessages: [],
    };
    this.valid = true;
    if (!this.selectedPaymentMethodID) {
      validationObj.valid = false;
      validationObj.errorMessages.push(
        this.translateService.instant(
          "cart.cart-components.payment-methods.please-select-payment-method"
        )
      );
      this.valid = false;
    }
    if (writeToState) {
      this.store.dispatch(
        new CompleteOrderValidationActions.AddUpdateCompleteOrderValidation(
          _.cloneDeep(validationObj)
        )
      );
    }
  }

  async openReservationAppSelectPaymentMethodModal() {
    if (!this.reservationAppSelectionModal) {
      this.reservationAppSelectionModal = true;

      this.reservationAppSelectionModal = await this.modalCtrl.create({
        component: ReservationSelectPaymentMethodModalComponent,
        cssClass: "reservation-select-payment-method-modal",
        animated: true,
        componentProps: {},
        backdropDismiss: false,
      });

      await this.reservationAppSelectionModal.present();
      this.reservationAppSelectionModal.onDidDismiss().then(async (data) => {
        if (data && data.data) {
          this.selectedPaymentMethodID = _.cloneDeep(data.data);
          this.selectPaymentMethodRadio("");
        }
        this.reservationAppSelectionModal = null;
      });
    }
  }

  selectPaymentMethodRadio(ev) {
    let id = this.selectedPaymentMethodID;

    this.store.dispatch(
      new SelectedPaymentMethodIDActions.SetSelectedPaymentMethodID(
        this.selectedPaymentMethodID
      )
    );

    if (navigator.cookieEnabled) {
      window.localStorage.setItem(
        "selectedPaymentMethodID",
        JSON.stringify(this.selectedPaymentMethodID)
      );
    }

    // TO_DO DO WE WANT TO LOAD CUSTOM OPTIONS HERE?

    // $rootScope.loadCustomOptions();
    // if ($rootScope.store_info.cartCustomOptionsActive) {
    //   $rootScope.calculateCartInline(true);
    // }
  }

  checkForOnlineOnlyPaymentPromos() {
    this.subscriptions.push(
      this.store
        .select("checkboxPromotions")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            state.checkboxPromotions &&
            !_.isEqual(this.checkboxPromotions, state.checkboxPromotions)
          ) {
            this.checkboxPromotions = _.cloneDeep(state.checkboxPromotions);

            if (this.checkboxPromotions && this.checkboxPromotions.length > 0) {
              this.disableAllPaymentSelections = false;
              _.each(this.checkboxPromotions, (promo) => {
                if (promo.onlinePaymentOnly && promo.promoCheckbox) {
                  this.disableAllPaymentSelections = true;
                  if (this.paymentGateways && this.paymentGateways.length > 0) {
                    _.each(this.paymentGateways, (gateway) => {
                      if (gateway.id !== "paypal" && gateway.id !== "klarna") {
                        this.store.dispatch(
                          new SelectedPaymentMethodIDActions.SetSelectedPaymentMethodID(
                            _.cloneDeep(gateway.id)
                          )
                        );
                        return;
                      }
                    });
                  }
                }
              });
            }
          }
        })
    );
  }

  checkForDisablingSomeMethods(key, method, ev) {
    if (
      this.disableAllPaymentSelections &&
      (key == "cash" ||
        key === "mobilePOS" ||
        key === "paypal" ||
        key == "klarna")
    ) {
      return true;
    } else {
      if (method.disable_payment_gateway) {
        return true;
      } else {
        return false;
      }
    }
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    if (this.reservationAppSelectionModal) {
      this.reservationAppSelectionModal.dismiss();
      this.reservationAppSelectionModal = null;
    }
  }
}
