import { Action } from "@ngrx/store";

export const SET_GROUPAPP = "[GROUPAPP] Set GroupApp";
export const CLEAR_GROUPAPP = "[GROUPAPP] Clear GroupApp";
export const SET_COOKIES_ENABLED = "[GROUPAPP] Set Cookies Enabled";
export const SET_IMAGE_SETTINGS = "[GROUPAPP] Set Image Settings";
export const SET_WEBP = "[GROUPAPP] Set Webp";
export const SET_ROOT_DIR = "[GROUPAPP] Set RootDir";
export const SET_SELECTED_LANGUAGE = "[GROUPAPP] Set Selected Language";
export const SET_SELECTED_THEME = "[GROUPAPP] Set Selected Theme";
export const SET_HOMEPAGE_MODAL_INFO_MESSAGES_DISPLAYED =
  "[GROUPAPP] Set Homepage Info Messages Displayed";
export const SET_CATALOG_MODAL_INFO_MESSAGES_DISPLAYED =
  "[GROUPAPP] Set Catalog Info Messages Displayed";
export const SET_CHECK_STORE_ONLINE_EXPIRES_AT =
  "[GROUPAPP] Set Check Store Online Expires At"; //THIS IS FOR THE CHECKSTORE ONLINE MESSAGES
export const SET_CHECK_STORE_ONLINE_EXPIRES_NOW =
  "[GROUPAPP] Set Check Store Online Expires Now"; //THIS IS FOR THE CHECKSTORE ONLINE MESSAGES

export const SET_ACTIVE_ROUTE = "[GROUPAPP] Set Active Route"; //THIS IS FOR THE CHECKSTORE ONLINE MESSAGES

export const SET_CHANGE_CHANNEL_TIMESTAMP =
  "[GROUPAPP] Set Change Channel Timestamp";

export class SetGroupApp implements Action {
  readonly type = SET_GROUPAPP;
  constructor(public payload: any) {}
}

export class ClearGroupApp implements Action {
  readonly type = CLEAR_GROUPAPP;
  constructor() {}
}

export class SetCookiesEnabled implements Action {
  readonly type = SET_COOKIES_ENABLED;
  constructor(public payload: any) {}
}

export class SetImageSettings implements Action {
  readonly type = SET_IMAGE_SETTINGS;
  constructor(public payload: any) {}
}

export class SetWebp implements Action {
  readonly type = SET_WEBP;
  constructor(public payload: any) {}
}

export class SetRootDir implements Action {
  readonly type = SET_ROOT_DIR;
  constructor(public payload: any) {}
}

export class SetSelectedLanguage implements Action {
  readonly type = SET_SELECTED_LANGUAGE;
  constructor(public payload: any) {}
}

export class SetSelectedTheme implements Action {
  readonly type = SET_SELECTED_THEME;
  constructor(public payload: any) {}
}

export class SetCheckStoreOnlineExpiresAt implements Action {
  readonly type = SET_CHECK_STORE_ONLINE_EXPIRES_AT;
  constructor(public payload: any) {}
}

export class SetCheckStoreOnlineExpiresNow implements Action {
  readonly type = SET_CHECK_STORE_ONLINE_EXPIRES_NOW;
  constructor(public payload: any) {}
}

export class SetHomepageInfoMessagesDisplayed implements Action {
  readonly type = SET_HOMEPAGE_MODAL_INFO_MESSAGES_DISPLAYED;
  constructor(public payload: any) {}
}

export class SetCatalogPageInfoMessagesDisplayed implements Action {
  readonly type = SET_CATALOG_MODAL_INFO_MESSAGES_DISPLAYED;
  constructor(public payload: any) {}
}

export class SetActiveRoute implements Action {
  readonly type = SET_ACTIVE_ROUTE;
  constructor(public payload: any) {}
}
export class SetChangeChannelTimestamp implements Action {
  readonly type = SET_CHANGE_CHANNEL_TIMESTAMP;
  constructor(public payload: any) {}
}

export type GroupAppActions =
  | SetGroupApp
  | ClearGroupApp
  | SetCookiesEnabled
  | SetImageSettings
  | SetWebp
  | SetRootDir
  | SetSelectedLanguage
  | SetSelectedTheme
  | SetCheckStoreOnlineExpiresAt
  | SetCheckStoreOnlineExpiresNow
  | SetHomepageInfoMessagesDisplayed
  | SetCatalogPageInfoMessagesDisplayed
  | SetActiveRoute
  | SetChangeChannelTimestamp;
