import { Component, OnInit } from "@angular/core";
import {
  AlertController,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { Subscription, auditTime, distinctUntilChanged, first } from "rxjs";
import { Store } from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";
import _ from "lodash";
import * as AuthActions from "../../auth/store/auth.actions";

import { LoginPopupsServiceService } from "src/app/services/login-popups-service/login-popups-service.service";
import * as currentStoreActions from "../../store/current-store/current-store.actions";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: "app-reservation-home",
  templateUrl: "./reservation-home.page.html",
  styleUrls: ["./reservation-home.page.scss"],
})
export class ReservationHomePage implements OnInit {
  private subscriptions: Subscription[] = [];
  public selectedLanguage: string;
  public artists;
  public stores: any;
  public colSize: number;
  public loginState: any;
  public currentUser: any;
  public animationLogin: boolean;
  public windowDimensions: any;
  public groupApp;

  private newCustomer: any;
  private mo_token: any;
  private unsubscribeBackEvent: Subscription;

  constructor(
    private store: Store<fromApp.AppState>,
    private modalCtrl: ModalController,
    private alertController: AlertController,
    private translateService: TranslateService,
    private router: Router,
    private toastController: ToastController,
    private platform: Platform,
    private loginPopupsService: LoginPopupsServiceService
  ) {}

  ngOnInit() {}

  private async appExit() {
    const alert = await this.alertController.create({
      header: this.translateService.instant("alert"),
      message: this.translateService.instant("do-you-want-to-exit-app"),
      buttons: [
        {
          text: this.translateService.instant("cancel"),
          role: "cancel",
          handler: () => {
            console.log("Application exit prevented!");
          },
        },
        {
          text: this.translateService.instant("exit"),
          handler: () => {
            // tslint:disable-next-line: no-string-literal
            if (this.platform.is("android")) {
              window.navigator["app"].exitApp(); // Close this application
            }
          },
        },
      ],
    });
    return await alert.present();
  }

  ionViewDidEnter() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      100,
      () => {
        if (this.loginPopupsService.checkMobileMagicLoginIsOpen()) {
          this.loginPopupsService.closeMobileMagicLoginAlert();
        } else {
          this.appExit();
        }
      }
    );
    this.subscriptions.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && state.stores && !_.isEqual(this.stores, state.stores)) {
            this.stores = _.cloneDeep(state.stores);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && !_.isEqual(this.groupApp, state.groupApp)) {
            this.groupApp = _.cloneDeep(state.groupApp);
          }
          if (state && state.selectedLangugage && !_.isEqual(state.selectedLangugage, this.selectedLanguage)) {
            this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
          }
        })
    );

    this.authSubscription();

    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.windowDimensions, state.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
            // [size]="windowDimensions.width > 600 ? '6': '12'"
            if (
              this.windowDimensions.width > 600 &&
              this.windowDimensions.width < 1100
            ) {
              this.colSize = 6;
            } else if (this.windowDimensions.width < 600) {
              this.colSize = 12;
            } else {
              this.colSize = 6;
            }
          }
        })
    );
  }

  async goToCatalog(store: any) {
    if (store) {
      this.store.dispatch(
        new currentStoreActions.SetCurrentStoreId(_.cloneDeep(store.store_id))
      );

      this.router.navigateByUrl("/reservationSystem/reservation-catalog", {
        replaceUrl: true,
      });
    }
  }

  goToCatalogDebounced = _.debounce((store) => {
    this.goToCatalog(store);
  }, 200);

  authSubscription() {
    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(auditTime(200))
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.mo_token &&
            !_.isEqual(this.mo_token, state.mo_token)
          ) {
            this.mo_token = _.cloneDeep(state.mo_token);

            if (this.mo_token) {
              this.store
                .select("groupApp")
                .pipe(first())
                .subscribe((groupAppState) => {
                  this.store
                    .select("currentStore")
                    .pipe(first())
                    .subscribe((currentStoreState) => {
                      this.fetchLoyaltyAppCouponRules(
                        state.mo_token,
                        currentStoreState.currentStoreId,
                        false,
                        groupAppState.groupApp
                          ? groupAppState.groupApp.group
                          : null
                      );
                    })
                    .unsubscribe();
                });
            }
          }
          if (state && !_.isEqual(state.loginState, this.loginState)) {
            this.loginState = _.cloneDeep(state.loginState);
            if (this.loginState === "no_login") {
              this.store
                .select("groupApp")
                .pipe(first())
                .subscribe((groupAppState) => {
                  this.store
                    .select("currentStore")
                    .pipe(first())
                    .subscribe((currentStoreState) => {
                      console.log("fetch coupon rules for no user");
                      this.fetchLoyaltyAppCouponRules(
                        state.mo_token,
                        currentStoreState.currentStoreId,
                        true,
                        groupAppState.groupApp
                          ? groupAppState.groupApp.group
                          : null
                      );
                    })
                    .unsubscribe();
                })
                .unsubscribe();
            }
          }

          if (state && state.user && !_.isEqual(state.user, this.currentUser)) {
            this.currentUser = _.cloneDeep(state.user);
            if (state.freshLogin) {
              this.animationLogin = true;
              this.store.dispatch(new AuthActions.SetFreshLogin(false));
            }
          }
          if (state && !_.isEqual(state.newCustomer, this.newCustomer)) {
            this.newCustomer = _.cloneDeep(state.newCustomer);
          }
        })
    );
  }

  async presentModal() {
    this.loginPopupsService.showFirebaseModalLogin(null, null);
  }

  fetchLoyaltyAppCouponRules(token, store_id, no_user, group) {
    // console.log("fetchLoyaltyAppCouponRules");
    // (no_user
    //   ? this.dataStorageService.getNoUserLoyaltyAppCouponRules(store_id, group)
    //   : this.dataStorageService.getLoyaltyAppCustomerCouponRules(
    //       token,
    //       store_id
    //     )
    // ).subscribe({
    //   next: (res: any) => {
    //     if (res && res.success) {
    //       console.log("success", res);
    //       this.customerLoyaltyCouponRules = res.coupon_rules;
    //     } else {
    //       if (res.comment_id) {
    //         let errorMsg = this.translateService.instant(
    //           "errorMessages-an-error-occurred"
    //         );
    //         this.presentErrorToast(errorMsg, "danger");
    //       } else {
    //         this.presentErrorToast(res.comments, "danger");
    //       }
    //     }
    //   },
    //   error: async (err) => {
    //     const alert = await this.alertController.create({
    //       header: this.translateService.instant("alert"),
    //       message: this.translateService.instant(
    //         "errorMessages.problem_reaching_server"
    //       ),
    //       buttons: ["OK"],
    //     });
    //     await alert.present();
    //     return err;
    //   },
    // });
  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }

  ionViewDidLeave() {
    this.currentUser = null;
    this.mo_token = null;
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    this.subscriptions = [];

    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
  }

  ngOnDestroy() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
  }
}
