import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import moment from "moment";
import { Subscription } from "rxjs";
import { auditTime, distinctUntilChanged } from "rxjs/operators";
import * as fromApp from "../../../store/app.reducer";
import { ModalController } from "@ionic/angular";
import { KioskCouponQrDisplayModalComponent } from "../../../kiosk/kiosk-coupon-qr-display-modal/kiosk-coupon-qr-display-modal.component";

@Component({
  selector: "app-reservation-kiosk-coupons-item",
  templateUrl: "./reservation-kiosk-coupons-item.component.html",
  styleUrls: ["./reservation-kiosk-coupons-item.component.scss"],
})
export class ReservationKioskCouponsItemComponent implements OnInit {
  @Output() clearTimestampToOpenEvent = new EventEmitter();
  @Input() coupon: any;
  @Input() timestamp_to_open;

  public stores: any;
  public storesByStoreId: any;
  public badgeColor: string;
  public selectedLangugage: any;
  private kioskQrDisplayModal;
  private notOpened = true;
  private subscription: Subscription;
  private subscription2: Subscription;

  constructor(
    private store: Store<fromApp.AppState>,
    private modalController: ModalController
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.coupon &&
      changes &&
      changes.timestamp_to_open &&
      changes.timestamp_to_open.previousValue !==
        changes.timestamp_to_open.currentValue &&
      this.coupon.timestamp === changes.timestamp_to_open.currentValue &&
      this.coupon.status &&
      this.coupon.status === "active" &&
      this.notOpened
    ) {
      this.clearTimestampToOpenEvent.emit(Date.now().toString());
      this.openKioskCoupounQrDisplayModal();
    }

    if (
      changes &&
      changes.coupon &&
      changes.coupon.previousValue &&
      changes.coupon.previousValue !== changes.coupon.currentValue
    ) {
      if (this.coupon && this.coupon.activeTo) {
        this.coupon.activeTo = moment(
          _.cloneDeep(parseInt(this.coupon.activeTo.toString()))
        )
          .subtract(1, "day")
          .format("x")
          .toString();
      }
    }
  }

  ngOnInit() {
    if (this.coupon && this.coupon.activeTo) {
      this.coupon.activeTo = moment(
        _.cloneDeep(parseInt(this.coupon.activeTo.toString()))
      )
        .subtract(1, "day")
        .format("x")
        .toString();
    }
    if (this.coupon) {
      // console.log("coupon exists");
      if (this.coupon.status === "used") {
        this.badgeColor = "used-coupon";
        //console.log("set used coupon");
      } else if (this.coupon.status === "active") {
        this.badgeColor = "active-coupon";
        //console.log("set active coupon");
      } else if (this.coupon.status === "expired") {
        this.badgeColor = "expired-coupon";
        //console.log("set exp coupon");
      } else if (this.coupon.status === "outdated") {
        this.badgeColor = "expired-coupon";
        // console.log("set exp coupon");
      } else if (this.coupon.status === "canceled") {
        this.badgeColor = "used-coupon";
        //console.log("set exp coupon");
      } else if (this.coupon.status === "pending_confirmation") {
        this.badgeColor = "warning";
        //console.log("set exp coupon");
      }
    }
    this.subscription = this.store
      .select("stores")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (state && state.stores && !_.isEqual(state.stores, this.stores)) {
          this.stores = _.cloneDeep(state.stores);
          if (this.stores && this.stores.length > 1) {
            this.storesByStoreId = _.groupBy(this.stores, "store_id");

            _.each(this.storesByStoreId, (store, key, list) => {
              this.storesByStoreId[key] = store[0];
            });
          }
        }
      });

    this.subscription2 = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (
          state &&
          state.selectedLangugage &&
          !_.isEqual(state.selectedLangugage, this.selectedLangugage)
        ) {
          this.selectedLangugage = _.cloneDeep(state.selectedLangugage);
        }
      });
  }

  async openKioskCoupounQrDisplayModal() {
    this.notOpened = false;
    this.kioskQrDisplayModal = await this.modalController.create({
      component: KioskCouponQrDisplayModalComponent,
      cssClass: "kioskQrInfoModal",
      backdropDismiss: false,
      componentProps: {
        coupon: _.cloneDeep(this.coupon),
        stores: _.cloneDeep(this.stores),
        selectedLangugage: _.cloneDeep(this.selectedLangugage),
      },
    });

    await this.kioskQrDisplayModal.present();

    await this.kioskQrDisplayModal.onDidDismiss().then((data) => {
      if (this.kioskQrDisplayModal) {
        this.kioskQrDisplayModal = null;
      }
    });
  }

  ngOnDestroy() {
    this.coupon = null;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscription2) {
      this.subscription2.unsubscribe();
    }

    if (this.kioskQrDisplayModal) {
      this.kioskQrDisplayModal.dismiss();
      this.kioskQrDisplayModal = null;
    }
  }
}
