<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small" *ngIf="currentReview && currentReview.order_id">
      {{ "orders.order-rating-modal.rate-order" | translate }} #{{
        currentReview.order_id
      }}
    </ion-title>
    <ion-button
      (click)="closeModal()"
      size="small"
      slot="end"
      fill="clear"
      color="dark"
    >
      <ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row *ngIf="!currentReview" class="ion-padding">
      <ion-col class="ion-text-center">
        <ion-spinner></ion-spinner>
      </ion-col>
    </ion-row>

    <ion-list *ngIf="currentReview && currentReview.storeAnswer">
      <ion-item-divider mode="ios">
        <ion-grid>
          <ion-row>
            <ion-col class="ion-text-center">
              {{ "ratings.modals.rating-details.stores-answer" | translate }}
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item-divider>
      <ion-item lines="none">
        <ion-label class="ion-text-wrap">
          {{ currentReview.storeAnswer }}
        </ion-label>
      </ion-item>
      <ion-item-divider mode="ios" *ngIf="currentReview.timestamp">
        <ion-grid>
          <ion-row>
            <ion-col class="ion-text-center">
              {{ "ratings.modals.rating-details.rated-at" | translate }}
              {{ currentReview.timestamp | date: "d/M/yyyy, HH:mm" }}
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item-divider>
    </ion-list>

    <ion-list>
      <div *ngFor="let question of currentReview.questions">
        <ion-item
          *ngIf="
            question.type !== 'stars' &&
            (question.required ||
              (question.type === 'textarea' && question.inputted_text) ||
              (question.type === 'checkbox' && question.answers.length > 0) ||
              (question.type === 'radio' && question.answer_id))
          "
        >
          <ion-row
            style="width: 100%"
            class="ion-align-items-center ion-justify-content-center"
          >
            <ion-col sizeSm="6" sizeXs="12">{{ question.text }} </ion-col>
            <ion-col sizeSm="6" sizeXs="12" class="textFloating">
              <!--checkbox answer-->
              <ion-label
                *ngIf="
                  question.type === 'checkbox' &&
                  question.answers &&
                  question.answers.length > 0
                "
              >
                <ion-text *ngFor="let answer of question.answers">
                  <p *ngIf="answer.id === question.answer_id">
                    {{ answer.text }}
                  </p>
                </ion-text>
              </ion-label>

              <!--radio answer-->
              <ion-text
                class="ion-no-padding"
                *ngIf="question.type === 'radio'"
              >
                <ion-text *ngFor="let answer of question.answers">
                  <p *ngIf="answer.id === question.answer_id">
                    {{ answer.text }}
                  </p>
                </ion-text>
              </ion-text>

              <!--text answer-->
              <ion-label
                *ngIf="question.type === 'textarea'"
                style="white-space: initial"
              >
                {{ question.inputted_text }}
              </ion-label>
            </ion-col>
          </ion-row>
        </ion-item>
        <ion-item *ngIf="question.type === 'stars'">
          <!--review's question-->
          <ion-label class="ion-text-wrap">
            <ion-text>
              <p>
                <strong slot="start">{{ question.text }}</strong>
              </p>
            </ion-text>
          </ion-label>

          <!--stars answer-->

          <ionic5-star-rating
            slot="end"
            #rating
            activeIcon="star"
            defaultIcon="star-outline"
            activeColor="#488aff"
            defaultColor="#f4f4f4"
            readonly="true"
            rating="{{ question.stars }}"
            fontSize="32px"
          >
          </ionic5-star-rating>
        </ion-item>
      </div>
    </ion-list>
  </ion-grid>
</ion-content>
