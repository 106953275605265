import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import * as SelectedProductActions from "./store/selectedProduct.actions";
import * as fromApp from "./../store/app.reducer";
import * as selectors from "./../store/app.selectors";
import { distinctUntilChanged, first } from "rxjs/operators";
import { v4 as uuid } from "uuid";
import { DataStorageService } from "../services/data-storage/data-storage.service";
import moment from "moment";
import { AlertController } from "@ionic/angular";
import { AnalyticsServiceService } from "../services/analitycsService/analytics-service.service";
import * as CartActions from "../cart/store/cart.actions";

@Injectable({
  providedIn: "root",
})
export class ProductDetailsOperationsService {
  public disableFinalPrice;
  private catalog;
  private dineIn;
  private orderPickup;
  constructor(
    private translateService: TranslateService,
    private dataStorageService: DataStorageService,
    private store: Store<fromApp.AppState>,
    private alertController: AlertController,
    private analyticsService: AnalyticsServiceService
  ) {}

  public productDetailsDisableFinalPriceSubscriprion() {
    this.store.select("disableFinalPrice").subscribe((state) => {
      if (
        state &&
        !_.isEqual(state.disableFinalPrice, this.disableFinalPrice)
      ) {
        this.disableFinalPrice = _.cloneDeep(state.disableFinalPrice);
        if (this.disableFinalPrice) {
          this.catalog = null;
        }
      }
    });
  }

  public productDetailsDeliveryMethodAddressSub() {
    this.store.select("deliveryMethodAddress").subscribe((state) => {
      if (state && !_.isEqual(state.orderPickup, this.orderPickup)) {
        this.orderPickup = _.cloneDeep(state.orderPickup);
      }
      if (state && !_.isEqual(state.dineIn, this.dineIn)) {
        this.dineIn = _.cloneDeep(state.dineIn);
      }
    });
  }

  public findChoicesComment(
    option,
    orderPickup,
    dineIn,
    alt_dinein_active,
    selectedDineInTable
  ) {
    console.log("findChoicesComment", option.title);

    var choicesComment = "";
    var allPreselected = true;
    var preselectedCounter = 0;
    var extrasComment = "";
    var withoutChoicesComment = "";

    _.each(option.preselectedChoices, (choice, k, l) => {
      var choiceSelected = _.find(option.choices, {
        choice_id: choice,
      });

      //Η επιλογή δεν είναι επιλεγμένη αυτή τη στιγμή ενώ κανονικά ήταν προεπιλεγμένη.
      if (!choiceSelected.is_preselected) {
        allPreselected = false;

        if (withoutChoicesComment) {
          withoutChoicesComment += ", ";
        }

        withoutChoicesComment += choiceSelected.printLabel
          ? choiceSelected.printLabel
          : choiceSelected.title;

        //Η επιλογή  είναι επιλεγμένη αυτή τη στιγμή
      } else if (choiceSelected.choicePriceComment) {
        if (extrasComment) {
          extrasComment += ", ";
        }
        extrasComment +=
          (choiceSelected.printLabel
            ? choiceSelected.printLabel
            : choiceSelected.title) +
          " " +
          choiceSelected.choicePriceComment;

        preselectedCounter++;
      } else {
        preselectedCounter++;
      }
    });

    _.each(option.choices, (choice) => {
      //For the extra selected that do not exist in the preselected!
      if (
        choice.is_preselected &&
        option.preselectedChoices.indexOf(choice.choice_id) === -1
      ) {
        //This means that is extra
        if (extrasComment) {
          extrasComment += ", ";
        }

        extrasComment +=
          (choice.printLabel ? choice.printLabel : choice.title) +
          " " +
          choice.choicePriceComment;
      }
    });

    if (allPreselected) {
      choicesComment = option.allPreselectedLabel
        ? option.allPreselectedLabel
        : "";

      //It goes in here becausewe might have extra choices besided all the preselected
      if (extrasComment) {
        choicesComment +=
          " " +
          this.translateService.instant("product-details.extras") +
          ": " +
          extrasComment;
      }
    } else if (preselectedCounter > option.preselectedChoices.length / 2) {
      choicesComment = option.allPreselectedLabel
        ? option.allPreselectedLabel
        : "";

      if (withoutChoicesComment) {
        choicesComment +=
          " " +
          this.translateService.instant("product-details.without") +
          ": " +
          withoutChoicesComment;
      }
      //It goes in here, because if the choicesComment does not get a value then we print the choices normally!
      if (extrasComment) {
        choicesComment +=
          " " +
          this.translateService.instant("product-details.extras") +
          ": " +
          extrasComment;
      }
    } else if (
      _.find(option.choices, {
        is_preselected: true,
      })
    ) {
      choicesComment += "ΜΟΝΟ ΜΕ: ";

      _.each(option.choices, (choice, key, list) => {
        if (
          choice.is_preselected &&
          !choice.choicePriceComment &&
          !choice.doNotPrint
        ) {
          if (choicesComment.replace("ΜΟΝΟ ΜΕ: ", "")) {
            choicesComment += ", ";
          }
          if (orderPickup == true && dineIn == false && choice.price_takeaway) {
            choicesComment +=
              (choice.promo ? "(%)" : "") +
              (choice.printLabel ? choice.printLabel : choice.title) +
              (choice.isFree
                ? " (" +
                  this.translateService.instant("product-details.free") +
                  ")"
                : choice.price_takeaway === "0" ||
                  choice.price_takeaway === 0 ||
                  choice.price_takeaway === "0.00"
                ? ""
                : " (" + choice.price_takeaway + ")");
          } else if (
            orderPickup == false &&
            dineIn == true &&
            (!alt_dinein_active ||
              !selectedDineInTable ||
              !selectedDineInTable.alt_dinein ||
              !choice.price_alt_dinein) &&
            choice.price_dinein
          ) {
            choicesComment +=
              (choice.promo ? "(%)" : "") +
              (choice.printLabel ? choice.printLabel : choice.title) +
              (choice.isFree
                ? " (" +
                  this.translateService.instant("product-details.free") +
                  ")"
                : choice.price_dinein === "0" ||
                  choice.price_dinein === 0 ||
                  choice.price_dinein === "0.00"
                ? ""
                : " (" + choice.price_dinein + ")");
          } else if (
            orderPickup == false &&
            dineIn == true &&
            alt_dinein_active &&
            selectedDineInTable &&
            selectedDineInTable.alt_dinein &&
            choice.price_alt_dinein
          ) {
            choicesComment +=
              (choice.promo ? "(%)" : "") +
              (choice.printLabel ? choice.printLabel : choice.title) +
              (choice.isFree
                ? " (" +
                  this.translateService.instant("product-details.free") +
                  ")"
                : choice.price_alt_dinein === "0" ||
                  choice.price_alt_dinein === 0 ||
                  choice.price_alt_dinein === "0.00"
                ? ""
                : " (" + choice.price_alt_dinein + ")");
          } else {
            choicesComment +=
              (choice.promo ? "(%)" : "") +
              (choice.printLabel ? choice.printLabel : choice.title) +
              (choice.isFree
                ? " (" +
                  this.translateService.instant("product-details.free") +
                  ")"
                : choice.price === "0" ||
                  choice.price === 0 ||
                  choice.price === "0.00"
                ? ""
                : " (" + choice.price + ")");
          }
        } else if (
          choice.is_preselected &&
          choice.choicePriceComment &&
          !choice.doNotPrint
        ) {
          if (choicesComment.replace("ΜΟΝΟ ΜΕ: ", "")) {
            choicesComment += ", ";
          }
          choicesComment +=
            (choice.promo ? "(%)" : "") +
            (choice.printLabel ? choice.printLabel : choice.title) +
            choice.choicePriceComment.replace("€", "");
        }
      });
    } else {
      if (withoutChoicesComment) {
        choicesComment +=
          " " +
          this.translateService.instant("product-details.without") +
          ": " +
          withoutChoicesComment;
      }
    }

    //it can be null so I will need to print all the choices.
    return choicesComment;
  }

  check_upper_parent_dependencies(product, option, option_total_price_to_add) {
    if (!option_total_price_to_add) {
      option_total_price_to_add = 0;
    }

    _.each(_.uniqBy(option.dependenciesVariable, "option_id"), (opt: any) => {
      const parent_option_index = _.findIndex(product.options, {
        option_id: opt.option_id,
      });

      // If the parent is dependent then I need to add to the upper level.
      if (
        typeof product.options[parent_option_index] === "object" &&
        product.options[parent_option_index] !== null &&
        !_.isEmpty(product.options[parent_option_index].dependenciesVariable) &&
        product.options[parent_option_index].showDependent
      ) {
        if (!product.options[parent_option_index].option_total_price) {
          product.options[parent_option_index].option_total_price = 0;
        }
        this.check_upper_parent_dependencies(
          product,
          product.options[parent_option_index],
          option_total_price_to_add +
            product.options[parent_option_index].option_total_price
        );
      } else {
        product.options[parent_option_index].option_parent_total_price +=
          parseFloat(option_total_price_to_add);
      }
    });
  }

  cartProductPrice(
    product,
    orderPickup,
    dineIn,
    parent_bundle_choice,
    dontWriteToState,
    alt_dinein_active,
    selectedDineInTable,
    decimalPlacesPrices
  ) {
    if (!product) {
      return "-";
    }

    var extras = 0;
    var basePrice;

    if (
      parent_bundle_choice &&
      parent_bundle_choice.bundle &&
      parent_bundle_choice.bundle_options &&
      parent_bundle_choice.bundle_options.override_product_prices &&
      parent_bundle_choice.bundle_options.override_product_prices.price
    ) {
      const parentChoiceOverridePricesObject = _.cloneDeep(
        parent_bundle_choice.bundle_options.override_product_prices
      );

      if (
        orderPickup == true &&
        dineIn == false &&
        parentChoiceOverridePricesObject.price_takeaway
      ) {
        basePrice = parseFloat(
          parentChoiceOverridePricesObject.price_takeaway
            .toString()
            .trim()
            .replace(",", ".")
        )
          ? parseFloat(
              parentChoiceOverridePricesObject.price_takeaway
                .toString()
                .trim()
                .replace(",", ".")
            )
          : 0;
      } else if (
        orderPickup == false &&
        dineIn == true &&
        (!alt_dinein_active ||
          !selectedDineInTable ||
          !selectedDineInTable.alt_dinein ||
          !parentChoiceOverridePricesObject.price_alt_dinein) &&
        parentChoiceOverridePricesObject.price_dinein
      ) {
        basePrice = parseFloat(
          parentChoiceOverridePricesObject.price_dinein
            .toString()
            .trim()
            .replace(",", ".")
        )
          ? parseFloat(
              parentChoiceOverridePricesObject.price_dinein
                .toString()
                .trim()
                .replace(",", ".")
            )
          : 0;
      } else if (
        orderPickup == false &&
        dineIn == true &&
        alt_dinein_active &&
        selectedDineInTable &&
        selectedDineInTable.alt_dinein &&
        parentChoiceOverridePricesObject.price_alt_dinein
      ) {
        basePrice = parseFloat(
          parentChoiceOverridePricesObject.price_alt_dinein
            .toString()
            .trim()
            .replace(",", ".")
        )
          ? parseFloat(
              parentChoiceOverridePricesObject.price_alt_dinein
                .toString()
                .trim()
                .replace(",", ".")
            )
          : 0;
      } else {
        basePrice = parseFloat(
          parentChoiceOverridePricesObject.price
            .toString()
            .trim()
            .replace(",", ".")
        )
          ? parseFloat(
              parentChoiceOverridePricesObject.price
                .toString()
                .trim()
                .replace(",", ".")
            )
          : 0;
      }
    } else {
      if (orderPickup == true && dineIn == false && product.price_takeaway) {
        basePrice = parseFloat(
          product.price_takeaway.toString().trim().replace(",", ".")
        )
          ? parseFloat(
              product.price_takeaway.toString().trim().replace(",", ".")
            )
          : 0;
      } else if (
        orderPickup == false &&
        dineIn == true &&
        (!alt_dinein_active ||
          !selectedDineInTable ||
          !selectedDineInTable.alt_dinein ||
          !product.price_alt_dinein) &&
        product.price_dinein
      ) {
        basePrice = parseFloat(
          product.price_dinein.toString().trim().replace(",", ".")
        )
          ? parseFloat(product.price_dinein.toString().trim().replace(",", "."))
          : 0;
      } else if (
        orderPickup == false &&
        dineIn == true &&
        alt_dinein_active &&
        selectedDineInTable &&
        selectedDineInTable.alt_dinein &&
        product.price_alt_dinein
      ) {
        basePrice = parseFloat(
          product.price_alt_dinein.toString().trim().replace(",", ".")
        )
          ? parseFloat(
              product.price_alt_dinein.toString().trim().replace(",", ".")
            )
          : 0;
      } else {
        basePrice = parseFloat(
          product.price.toString().trim().replace(",", ".")
        )
          ? parseFloat(product.price.toString().trim().replace(",", "."))
          : 0;
      }
    }
    console.log("basssse_price", basePrice);

    _.each(product.options, (option, key, list) => {
      if (
        option &&
        typeof option === "object" &&
        option !== null &&
        (_.isEmpty(option.dependenciesVariable) || option.showDependent)
      ) {
        if (option.changesBasePrice) {
          let choiceFound = _.find(option.choices, { is_preselected: true });
          if (choiceFound) {
            if (
              choiceFound &&
              choiceFound.bundle === true &&
              !_.isEmpty(choiceFound.bundled_product)
            ) {
              // Here we have already calculated the bundled_product prices for every kind of order!
              // So now we can use them!
              basePrice = parseFloat(
                choiceFound.bundled_product.productTotal
                  .toString()
                  .trim()
                  .replace(",", ".")
              );
            } else {
              if (
                orderPickup == true &&
                dineIn == false &&
                choiceFound.price_takeaway
              ) {
                basePrice = parseFloat(
                  choiceFound.price_takeaway.toString().trim().replace(",", ".")
                );
              } else if (
                orderPickup == false &&
                dineIn == true &&
                (!alt_dinein_active ||
                  !selectedDineInTable ||
                  !selectedDineInTable.alt_dinein ||
                  !choiceFound.price_alt_dinein) &&
                choiceFound.price_dinein
              ) {
                basePrice = parseFloat(
                  choiceFound.price_dinein.toString().trim().replace(",", ".")
                );
              } else if (
                orderPickup == false &&
                dineIn == true &&
                alt_dinein_active &&
                selectedDineInTable &&
                selectedDineInTable.alt_dinein &&
                choiceFound.price_alt_dinein
              ) {
                basePrice = parseFloat(
                  choiceFound.price_alt_dinein
                    .toString()
                    .trim()
                    .replace(",", ".")
                );
              } else {
                basePrice = parseFloat(
                  choiceFound.price.toString().trim().replace(",", ".")
                );
              }
            }
          }
        } else {
          product.options[key].option_total_price = 0;

          _.each(option.choices, (choice: any, k, l) => {
            if (!choice.hasOwnProperty("freeQuantity")) {
              choice.freeQuantity = 0;
            }

            if (!choice.hasOwnProperty("quantity")) {
              choice.quantity = 1;
            }

            if (
              choice &&
              choice.bundle === true &&
              !_.isEmpty(choice.bundled_product)
            ) {
              if (
                _.isEmpty(choice.bundle_options) ||
                !choice.bundle_options.enable_bundled_product_quantity
              ) {
                // Here in this case we do not have quantity in the product! We might have qunatity in the choice!
                if (
                  choice.is_preselected &&
                  (!choice.isFree || choice.freeQuantity !== choice.quantity)
                ) {
                  // We have already calculated the correct productTotal without the quantity!
                  option.option_total_price +=
                    parseFloat(
                      choice.bundled_product.productTotal
                        .toString()
                        .trim()
                        .replace(",", ".")
                    ) *
                    (choice.quantity - choice.freeQuantity);
                  extras =
                    parseFloat(extras.toString()) +
                    parseFloat(
                      choice.bundled_product.productTotal
                        .toString()
                        .trim()
                        .replace(",", ".")
                    ) *
                      (choice.quantity - choice.freeQuantity);
                }
              }
            } else {
              if (
                choice.is_preselected &&
                (!choice.isFree || choice.freeQuantity !== choice.quantity)
              ) {
                if (
                  choice.is_preselected &&
                  (!choice.isFree || choice.freeQuantity !== choice.quantity)
                ) {
                  if (
                    orderPickup == true &&
                    dineIn == false &&
                    choice.price_takeaway
                  ) {
                    product.options[key].option_total_price +=
                      parseFloat(
                        choice.price_takeaway
                          .toString()
                          .trim()
                          .replace(",", ".")
                      ) *
                      (choice.quantity - choice.freeQuantity);
                    extras =
                      parseFloat(extras.toString()) +
                      parseFloat(
                        choice.price_takeaway
                          .toString()
                          .trim()
                          .replace(",", ".")
                      ) *
                        (choice.quantity - choice.freeQuantity);
                  } else if (
                    orderPickup == false &&
                    dineIn == true &&
                    (!alt_dinein_active ||
                      !selectedDineInTable ||
                      !selectedDineInTable.alt_dinein ||
                      !choice.price_alt_dinein) &&
                    choice.price_dinein
                  ) {
                    product.options[key].option_total_price +=
                      parseFloat(
                        choice.price_dinein.toString().trim().replace(",", ".")
                      ) *
                      (choice.quantity - choice.freeQuantity);
                    extras =
                      parseFloat(extras.toString()) +
                      parseFloat(
                        choice.price_dinein.toString().trim().replace(",", ".")
                      ) *
                        (choice.quantity - choice.freeQuantity);
                  } else if (
                    orderPickup == false &&
                    dineIn == true &&
                    alt_dinein_active &&
                    selectedDineInTable &&
                    selectedDineInTable.alt_dinein &&
                    choice.price_alt_dinein
                  ) {
                    product.options[key].option_total_price +=
                      parseFloat(
                        choice.price_alt_dinein
                          .toString()
                          .trim()
                          .replace(",", ".")
                      ) *
                      (choice.quantity - choice.freeQuantity);
                    extras =
                      parseFloat(extras.toString()) +
                      parseFloat(
                        choice.price_alt_dinein
                          .toString()
                          .trim()
                          .replace(",", ".")
                      ) *
                        (choice.quantity - choice.freeQuantity);
                  } else {
                    product.options[key].option_total_price +=
                      parseFloat(
                        choice.price.toString().trim().replace(",", ".")
                      ) *
                      (choice.quantity - choice.freeQuantity);
                    extras =
                      parseFloat(extras.toString()) +
                      parseFloat(
                        choice.price.toString().trim().replace(",", ".")
                      ) *
                        (choice.quantity - choice.freeQuantity);
                  }
                }
              }
            }
          });
        }
      }
    });

    var totalPrice = 0;

    if (product.free_parent_option) {
      totalPrice = parseFloat(basePrice.toString());

      _.each(product.options, (option, key, list) => {
        if (!product.options[key].option_parent_total_price) {
          product.options[key].option_parent_total_price = 0;
        }

        if (
          option &&
          typeof option === "object" &&
          _.isEmpty(option.dependenciesVariable)
        ) {
          //This means that this is a parent option that is not dependent
          product.options[key].option_parent_total_price = parseFloat(
            product.options[key].option_total_price
          );
        } else if (
          option &&
          typeof option === "object" &&
          option.showDependent &&
          !_.isEmpty(option.dependenciesVariable)
        ) {
          //This is the case of a dependent option that has price option_total_price above zero
          //Then here I need to find at what parent option it belongs to.
          //console.log("option.dependenciesVariable", option.dependenciesVariable);
          this.check_upper_parent_dependencies(
            product,
            option,
            option.option_total_price
          );
        }
      });

      var min_total_price_option = _.minBy(
        _.filter(product.options, (option) => {
          return (
            option &&
            typeof option === "object" &&
            _.isEmpty(option.dependenciesVariable) &&
            option.template !== "comments"
          );
        }),
        "option_parent_total_price"
      );

      //console.log("min_total_price_option", min_total_price_option);

      _.each(product.options, (option, k) => {
        if (
          min_total_price_option.option_id === option.option_id &&
          option.option_parent_total_price
        ) {
          //console.log("min_total_price_option", min_total_price_option);
          product.options[k].parent_free = true;
          if (
            product.parent_discount &&
            product.parent_discount < 100 &&
            product.parent_discount > 0
          ) {
            product.options[k].option_parent_total_price =
              product.options[k].option_parent_total_price -
              (product.options[k].option_parent_total_price *
                product.parent_discount) /
                100;
          } else {
            product.options[k].option_parent_total_price = 0;
          }
        } else {
          product.options[k].parent_free = false;
        }

        if (
          option &&
          typeof option === "object" &&
          _.isEmpty(option.dependenciesVariable)
        ) {
          //This means that this is a parent option that is not dependent
          totalPrice += parseFloat(option.option_parent_total_price);
        }

        if (product.options[k].option_parent_total_price) {
          product.options[k].option_parent_total_price = parseFloat(
            product.options[k].option_parent_total_price
          ).toFixed(2);
        }
      });
    } else {
      totalPrice =
        parseFloat(basePrice.toString()) + parseFloat(extras.toString());
    }
    console.log("pipe price extras", extras);

    console.log("cartProductPrice product", product);
    totalPrice =
      parseFloat(product.quantity ? product.quantity : 1) * totalPrice;

    let returnedtotalPrice = parseFloat(totalPrice.toString()).toFixed(
      decimalPlacesPrices ? decimalPlacesPrices : 2
    );
    console.log("calculate products price price", returnedtotalPrice);
    if (!dontWriteToState) {
      this.store.dispatch(
        new SelectedProductActions.SetSelectedProductTotalPrice({
          selectedProductId: product.selectedProductId,
          productTotalPrice: returnedtotalPrice,
        })
      );
    } else {
      product.productTotal = returnedtotalPrice;
    }

    return _.cloneDeep(product);
  }

  handle_choice_step_price(
    choice,
    orderPickup,
    dineIn,
    alt_dinein_active,
    selectedDineInTable
  ) {
    var comment = "";

    if (orderPickup == true && dineIn == false && choice.price_takeaway) {
      if (
        choice.quantityInChoicesStep &&
        choice.quantityInChoicesStep !== 1 &&
        choice.price_takeaway !== "0" &&
        choice.price_takeaway !== 0 &&
        choice.price_takeaway !== "0.00"
      ) {
        comment += " (" + choice.price_takeaway + "€";
        if (choice.quantityInChoicesKind) {
          comment += "/" + choice.quantityInChoicesKind + ") ";
        } else {
          comment += ") ";
        }
      }
      return comment;
    } else if (
      orderPickup == false &&
      dineIn == true &&
      (!alt_dinein_active ||
        !selectedDineInTable ||
        !selectedDineInTable.alt_dinein ||
        !choice.price_alt_dinein) &&
      choice.price_dinein
    ) {
      if (
        choice.quantityInChoicesStep &&
        choice.quantityInChoicesStep !== 1 &&
        choice.price_dinein !== "0" &&
        choice.price_dinein !== 0 &&
        choice.price_dinein !== "0.00"
      ) {
        comment += " (" + choice.price_dinein + "€";
        if (choice.quantityInChoicesKind) {
          comment += "/" + choice.quantityInChoicesKind + ") ";
        } else {
          comment += ") ";
        }
      }
      return comment;
    } else if (
      orderPickup == false &&
      dineIn == true &&
      alt_dinein_active &&
      selectedDineInTable &&
      selectedDineInTable.alt_dinein &&
      choice.price_alt_dinein
    ) {
      if (
        choice.quantityInChoicesStep &&
        choice.quantityInChoicesStep !== 1 &&
        choice.price_alt_dinein !== "0" &&
        choice.price_alt_dinein !== 0 &&
        choice.price_alt_dinein !== "0.00"
      ) {
        comment += " (" + choice.price_alt_dinein + "€";
        if (choice.quantityInChoicesKind) {
          comment += "/" + choice.quantityInChoicesKind + ") ";
        } else {
          comment += ") ";
        }
      }
      return comment;
    } else {
      if (
        choice.quantityInChoicesStep &&
        choice.quantityInChoicesStep !== 1 &&
        choice.price !== "0" &&
        choice.price !== 0 &&
        choice.price !== "0.00"
      ) {
        comment += " (" + choice.price + "€";
        if (choice.quantityInChoicesKind) {
          comment += "/" + choice.quantityInChoicesKind + ") ";
        } else {
          comment += ") ";
        }
      }
      return comment;
    }
  }

  initializeProductOptionsCheckbox(product) {
    console.log(
      "initializeProductOptionsCheckbox",

      product.options
    );

    _.each(product.options, (option, o, p) => {
      product.options[o].optionData = {
        freeChoices: 0,
        minChoices: 0,
        maxChoices: 0,
      };
      var choiceWithStepFound = _.find(option.choices, (choice) => {
        return (
          choice.quantityInChoicesStep && choice.quantityInChoicesStep !== 1
        );
      });

      //console.log("initializePrdOptionsCheckbox", choiceWithStepFound);

      if (
        option.is_require &&
        !_.has(option, "minChoices") &&
        !choiceWithStepFound
      ) {
        product.options[o].minChoices = "1";
      } else if (
        option.is_require &&
        !_.has(option, "minChoices") &&
        choiceWithStepFound
      ) {
        product.options[o].minChoices =
          choiceWithStepFound.quantityInChoicesStep;
      }

      _.each(option.choices, (choice, c, l) => {
        if (
          !(
            choice.is_preselected &&
            choice.activeTotal &&
            choice.availableTotal
          )
        ) {
          product.options[o].choices[c].is_preselected = false;
        }

        if (!choice.quantity) {
          if (!choice.quantityInChoicesStep) {
            product.options[o].choices[c].quantity = 1;
          } else {
            product.options[o].choices[c].quantity = _.cloneDeep(
              choice.quantityInChoicesStep
            );
          }
        }

        if (choice.quantity && choice.quantityInChoicesStep) {
          product.options[o].choices[c].quantity = _.cloneDeep(
            choice.quantityInChoicesStep
          );
        }

        if (!choice.freeQuantity) {
          product.options[o].choices[c].freeQuantity = 0;
        }
      });

      this.assignFreeChoices(option.option_id, product);
      this.calculateTotalChoiceQuantity(option.option_id, product);
    });

    return product;
  }

  calculateTotalChoiceQuantity(option_id, product) {
    if (!option_id) {
      return;
    }
    var optionIndex = _.findIndex(product.options, {
      option_id: option_id,
    });
    var choicesQuantity = 0;
    _.each(product.options[optionIndex].choices, (choice) => {
      if (
        choice.is_preselected &&
        choice.activeTotal &&
        choice.availableTotal
      ) {
        choicesQuantity = choicesQuantity + choice.quantity;
      }
    });

    product.options[optionIndex].optionData.freeChoices =
      choicesQuantity >= parseInt(product.options[optionIndex].freeChoices)
        ? product.options[optionIndex].freeChoices
        : choicesQuantity;
    product.options[optionIndex].optionData.maxChoices =
      choicesQuantity >= parseInt(product.options[optionIndex].maxChoices)
        ? product.options[optionIndex].maxChoices
        : choicesQuantity;
    product.options[optionIndex].optionData.minChoices =
      choicesQuantity >= parseFloat(product.options[optionIndex].minChoices)
        ? product.options[optionIndex].minChoices
        : choicesQuantity;
  }

  assignFreeChoices(option_id, product) {
    if (!option_id) {
      // There are some options that do not have option_id
      return;
    }
    var optionIndex = _.findIndex(product.options, {
      option_id: option_id,
    });

    var optionsFreeChoices = parseInt(product.options[optionIndex].freeChoices);

    let sortByVariable: any = "price";

    if (
      product &&
      product.options &&
      product.options[optionIndex] &&
      product.options[optionIndex].freeChoices &&
      product.options[optionIndex].freeChoicesBySelectionOrder
    ) {
      sortByVariable = "selected_timestamp";
    }

    if (
      product &&
      product.options &&
      product.options[optionIndex] &&
      product.options[optionIndex].freeChoices &&
      product.options[optionIndex].freeChoicesByPosition
    ) {
      sortByVariable = (choice, index) => index;
    }

    const sortedChoices = _.sortBy(
      product.options[optionIndex].choices,
      (choice, index) => {
        if (sortByVariable === "price") {
          if (this.orderPickup && choice.price_takeaway) {
            sortByVariable = "price_takeway";
            return choice.price_takeaway;
          } else if (this.dineIn && choice.price_dinein) {
            sortByVariable = "price_dinein";
            return choice.price_dinein;
          }
          return choice.price;
        } else if (typeof sortByVariable === "function") {
          return sortByVariable(choice, index);
        } else {
          return choice[sortByVariable];
        }
      }
    );

    console.log("sortedChoices", sortedChoices);

    _.each(sortedChoices, (choice) => {
      if (
        this.dineIn &&
        choice.price_dinein === 0 &&
        choice.price === 0 &&
        product.options[optionIndex].freeChoicesbyPriceSkipZeros
      ) {
        return;
      }
      if (
        this.orderPickup &&
        choice.price_takeaway === 0 &&
        choice.price === 0 &&
        product.options[optionIndex].freeChoicesbyPriceSkipZeros
      ) {
        return;
      }

      if (
        !this.orderPickup &&
        !this.dineIn &&
        choice.price === 0 &&
        product.options[optionIndex].freeChoicesbyPriceSkipZeros
      ) {
        return;
      }

      if (
        optionsFreeChoices > 0 &&
        choice.is_preselected &&
        choice.activeTotal &&
        choice.availableTotal
      ) {
        product.options[optionIndex].choices[
          _.findIndex(product.options[optionIndex].choices, {
            choice_id: choice.choice_id,
          })
        ].isFree = true;
        if (choice.quantity > 1) {
          if (optionsFreeChoices - choice.quantity >= 0) {
            product.options[optionIndex].choices[
              _.findIndex(product.options[optionIndex].choices, {
                choice_id: choice.choice_id,
              })
            ].freeQuantity = choice.quantity;
            optionsFreeChoices -= choice.quantity;
          } else {
            product.options[optionIndex].choices[
              _.findIndex(product.options[optionIndex].choices, {
                choice_id: choice.choice_id,
              })
            ].freeQuantity = optionsFreeChoices;
            optionsFreeChoices -= optionsFreeChoices;
          }
        } else {
          product.options[optionIndex].choices[
            _.findIndex(product.options[optionIndex].choices, {
              choice_id: choice.choice_id,
            })
          ].freeQuantity = 1;
          optionsFreeChoices -= 1;
        }
      } else {
        product.options[optionIndex].choices[
          _.findIndex(product.options[optionIndex].choices, {
            choice_id: choice.choice_id,
          })
        ].isFree = false;
        product.options[optionIndex].choices[
          _.findIndex(product.options[optionIndex].choices, {
            choice_id: choice.choice_id,
          })
        ].freeQuantity = 0;
      }
    });

    optionsFreeChoices = null;
  }

  checkIsDependentOptions(product) {
    console.log("checkIsDependentOptions");

    if (!product) {
      return;
    }

    product.options = _.filter(product.options, (opt) => {
      return _.isObject(opt);
    });

    _.each(product.options, (option, o, l) => {
      product.options[o].dependenciesVariable = [];
    });

    _.each(product.options, (option, o, l) => {
      _.each(product.options[o].choices, (choice, c, list) => {
        if (!_.isEmpty(choice.dependentOptions)) {
          console.log(choice.title, choice.dependentOptions);

          _.each(choice.dependentOptions, (dependentOption, k, lo) => {
            console.log("dependentOption.option_id", dependentOption.option_id);
            var index = _.findIndex(product.options, {
              option_id: dependentOption.option_id,
            });
            if (index !== -1) {
              product.options[index].dependenciesVariable.push({
                when_is_preselected: true,
                option_id: option.option_id,
                optionTitle: option.title,
                choiceTitle: choice.title,
                choice_id: choice.choice_id,
                option_id_choice_id: choice.option_id_choice_id,
              });
            }
          });
        }

        if (!_.isEmpty(choice.dependenciesWhenNotPreselected)) {
          console.log(
            choice.title,
            "not",
            choice.dependenciesWhenNotPreselected
          );

          _.each(
            choice.dependenciesWhenNotPreselected,
            (dependentOption, k, lo) => {
              var index = _.findIndex(product.options, {
                option_id: dependentOption.option_id,
              });
              if (index !== -1) {
                product.options[index].dependenciesVariable.push({
                  when_is_preselected: false,
                  option_id: option.option_id,
                  optionTitle: option.title,
                  choiceTitle: choice.title,
                  choice_id: choice.choice_id,
                  option_id_choice_id: choice.option_id_choice_id,
                });
              }
            }
          );
        }
      });
    });
    this.hideShowDependentOptions(product);
    return product;
  }

  hideShowDependentOptions(product) {
    console.log("hideShowDependentOptions");

    var optionsThatAreDependent = _.filter(product.options, (option) => {
      return (
        option &&
        typeof option === "object" &&
        option.dependenciesVariable &&
        option.dependenciesVariable.length > 0
      );
    });

    _.each(optionsThatAreDependent, (option, key, list) => {
      product.options[
        _.findIndex(product.options, {
          option_id: option.option_id,
        })
      ].showDependent = this.checkIfAnyChoiceDependencies(
        option.dependenciesVariable,
        product
      );
    });
    optionsThatAreDependent = null;
  }

  checkIfAnyChoiceDependencies(dependenciesVariable, product) {
    var found = false;

    _.each(product.options, (option, o, q) => {
      if (
        typeof product.options[o] === "object" &&
        product.options[o] !== null &&
        (_.isEmpty(product.options[o].dependenciesVariable) ||
          product.options[o].showDependent) &&
        product.options[o].activeTotal &&
        product.options[o].availableTotal
      ) {
        _.each(product.options[o].choices, (choice, c, w) => {
          if (choice.activeTotal && choice.availableTotal) {
            var foundChoice = _.filter(dependenciesVariable, {
              when_is_preselected: !!choice.is_preselected,
              option_id: option.option_id,
              choice_id: choice.choice_id,
            });

            if (foundChoice.length > 0) {
              found = true;
            }
          }
        });
      }
    });
    dependenciesVariable = null;
    return found;
  }

  productAllOptionsPriceComments(
    product,
    orderPickup,
    dineIn,
    alt_dinein_active,
    selectedDineInTable
  ) {
    console.log("productAllOptionsPriceComments");

    _.each(product.options, (option, o, p) => {
      this.productChoicesPriceComment(
        option.option_id,
        orderPickup,
        dineIn,
        product,
        alt_dinein_active,
        selectedDineInTable
      );
    });
    return product;
  }

  productChoicesPriceComment(
    option_id,
    orderPickup,
    dineIn,
    product,
    alt_dinein_active,
    selectedDineInTable
  ) {
    var o = _.findIndex(product.options, {
      option_id: option_id,
    });

    if (
      o > -1 &&
      (product.options[o].type === "radio" ||
        product.options[o].type === "checkbox")
    ) {
      _.each(product.options[o].choices, (choice, c, q) => {
        if (choice.isFree && choice.quantity === choice.freeQuantity) {
          product.options[o].choices[c].choicePriceComment =
            this.handle_choice_step_price(
              choice,
              orderPickup,
              dineIn,
              alt_dinein_active,
              selectedDineInTable
            ) +
            ((product.options[o].alwaysShowChoicesQuantity ||
            choice.quantity !== 1
              ? " x" + choice.quantity + ": "
              : " ") +
              this.translateService.instant("product-details.free"));
        } else {
          var tempPrice;
          if (orderPickup == true && dineIn == false && choice.price_takeaway) {
            tempPrice =
              choice.price_takeaway.toString().trim().replace(",", ".") *
              (choice.quantity - choice.freeQuantity);
            product.options[o].choices[c].choicePriceComment =
              this.handle_choice_step_price(
                choice,
                orderPickup,
                dineIn,
                alt_dinein_active,
                selectedDineInTable
              ) +
              (choice.price_takeaway
                ? (product.options[o].alwaysShowChoicesQuantity ||
                  choice.quantity !== 1
                    ? " x" + choice.quantity + ": "
                    : " ") +
                  parseFloat(tempPrice.toString()).toFixed(2) +
                  "€"
                : product.options[o].alwaysShowChoicesQuantity ||
                  choice.quantity !== 1
                ? " x" + choice.quantity
                : "");
          } else if (
            orderPickup == false &&
            dineIn == true &&
            (!alt_dinein_active ||
              !selectedDineInTable ||
              !selectedDineInTable.alt_dinein ||
              !choice.price_alt_dinein) &&
            choice.price_dinein
          ) {
            tempPrice =
              choice.price_dinein.toString().trim().replace(",", ".") *
              (choice.quantity - choice.freeQuantity);
            product.options[o].choices[c].choicePriceComment =
              this.handle_choice_step_price(
                choice,
                orderPickup,
                dineIn,
                alt_dinein_active,
                selectedDineInTable
              ) +
              (choice.price_dinein
                ? (product.options[o].alwaysShowChoicesQuantity ||
                  choice.quantity !== 1
                    ? " x" + choice.quantity + ": "
                    : " ") +
                  parseFloat(tempPrice.toString()).toFixed(2) +
                  "€"
                : product.options[o].alwaysShowChoicesQuantity ||
                  choice.quantity !== 1
                ? " x" + choice.quantity
                : "");
          } else if (
            orderPickup == false &&
            dineIn == true &&
            alt_dinein_active &&
            selectedDineInTable &&
            selectedDineInTable.alt_dinein &&
            choice.price_alt_dinein
          ) {
            tempPrice =
              choice.price_alt_dinein.toString().trim().replace(",", ".") *
              (choice.quantity - choice.freeQuantity);
            product.options[o].choices[c].choicePriceComment =
              this.handle_choice_step_price(
                choice,
                orderPickup,
                dineIn,
                alt_dinein_active,
                selectedDineInTable
              ) +
              (choice.price_alt_dinein
                ? (product.options[o].alwaysShowChoicesQuantity ||
                  choice.quantity !== 1
                    ? " x" + choice.quantity + ": "
                    : " ") +
                  parseFloat(tempPrice.toString()).toFixed(2) +
                  "€"
                : product.options[o].alwaysShowChoicesQuantity ||
                  choice.quantity !== 1
                ? " x" + choice.quantity
                : "");
          } else {
            tempPrice =
              choice.price.toString().trim().replace(",", ".") *
              (choice.quantity - choice.freeQuantity);
            product.options[o].choices[c].choicePriceComment =
              this.handle_choice_step_price(
                choice,
                orderPickup,
                dineIn,
                alt_dinein_active,
                selectedDineInTable
              ) +
              (choice.price
                ? (product.options[o].alwaysShowChoicesQuantity ||
                  choice.quantity !== 1
                    ? " x" + choice.quantity + ": "
                    : " ") +
                  parseFloat(tempPrice.toString()).toFixed(2) +
                  "€"
                : product.options[o].alwaysShowChoicesQuantity ||
                  choice.quantity !== 1
                ? " x" + choice.quantity
                : "");
          }
        }
      });

      if (
        product.options[o].type === "checkbox" &&
        product.options[o].reverseChoices &&
        !_.isEmpty(product.options[o].preselectedChoices)
      ) {
        product.options[o].choicesComment = this.findChoicesComment(
          product.options[o],
          orderPickup,
          dineIn,
          alt_dinein_active,
          selectedDineInTable
        );
      }
    }
  }

  checkIgnoreImage(product, parentProductBundleChoice) {
    if (
      parentProductBundleChoice &&
      parentProductBundleChoice.bundle_options &&
      parentProductBundleChoice.bundle_options.ignore_bundled_product_image &&
      product &&
      product.image &&
      product.image.true
    ) {
      delete product.image.true;
    }

    return _.cloneDeep(product);
  }

  calculateBundleProductIgnoreOptionPrices(
    parentRootProduct,
    parent_bundle_choice,
    bundled_product
  ) {
    if (
      parentRootProduct &&
      parentRootProduct.bundle &&
      parentRootProduct.bundle_options
    ) {
      if (
        parentRootProduct.bundle_options.ignore_only_bundled_products_prices
      ) {
        bundled_product.price = 0;
        if (_.has(bundled_product, "price_takeaway")) {
          bundled_product.price_takeaway = 0;
        }
        if (_.has(bundled_product, "price_dinein")) {
          bundled_product.price_dinein = 0;
        }
        if (_.has(bundled_product, "price_alt_dinein")) {
          bundled_product.price_alt_dinein = 0;
        }
      }
      if (parentRootProduct.bundle_options.ignore_option_prices) {
        bundled_product.price = 0;
        if (_.has(bundled_product, "price_takeaway")) {
          bundled_product.price_takeaway = 0;
        }
        if (_.has(bundled_product, "price_dinein")) {
          bundled_product.price_dinein = 0;
        }

        if (_.has(bundled_product, "price_alt_dinein")) {
          bundled_product.price_alt_dinein = 0;
        }
        _.each(bundled_product.options, (option) => {
          if (option.choices && option.choices.length) {
            _.each(option.choices, (choice) => {
              choice.price = 0;
              if (_.has(choice, "price_takeaway")) {
                choice.price_takeaway = 0;
              }
              if (_.has(choice, "price_dinein")) {
                choice.price_dinein = 0;
              }

              if (_.has(choice, "price_alt_dinein")) {
                choice.price_alt_dinein = 0;
              }
            });
          }
        });
      } else if (
        !parentRootProduct.bundle_options.ignore_option_prices &&
        parentRootProduct.bundle_options.ignored_option_prices_array
      ) {
        console.log("mpika choice index", parentRootProduct.bundle_options);
        _.each(
          parentRootProduct.bundle_options.ignored_option_prices_array,
          (ignoredOption) => {
            const ignored_option_index = _.findIndex(
              parentRootProduct.options,
              {
                product_id_option_id: ignoredOption.product_id_option_id,
              }
            );
            console.log("ignored_option_index", ignored_option_index);
            if (ignored_option_index !== -1) {
              if (
                ignoredOption.ignore_all_choices_prices &&
                _.find(
                  parentRootProduct.options[ignored_option_index].choices,
                  {
                    option_id_choice_id:
                      parent_bundle_choice.option_id_choice_id,
                  }
                )
              ) {
                console.log("clear prices from option");
                _.each(bundled_product.options, (option) => {
                  if (option.choices && option.choices.length) {
                    _.each(option.choices, (choice) => {
                      choice.price = 0;
                      if (_.has(choice, "price_takeaway")) {
                        choice.price_takeaway = 0;
                      }
                      if (_.has(choice, "price_dinein")) {
                        choice.price_dinein = 0;
                      }
                      if (_.has(choice, "price_alt_dinein")) {
                        choice.price_alt_dinein = 0;
                      }
                    });
                  }
                });
              } else if (
                !ignoredOption.ignore_all_choices_prices &&
                ignoredOption.choices &&
                ignoredOption.choices.length
              ) {
                _.each(ignoredOption.choices, (ignored_choice) => {
                  const ignored_choice_index = _.findIndex(
                    parentRootProduct.options[ignored_option_index].choices,
                    {
                      option_id_choice_id: ignored_choice.option_id_choice_id,
                    }
                  );
                  console.log(
                    "ignored_choice_index",
                    ignored_choice_index,
                    parentRootProduct.options[ignored_option_index].choices[
                      ignored_choice_index
                    ].option_id_choice_id,
                    parent_bundle_choice.option_id_choice_id
                  );
                  if (
                    ignored_choice_index !== -1 &&
                    parentRootProduct.options[ignored_option_index].choices[
                      ignored_choice_index
                    ].option_id_choice_id ===
                      parent_bundle_choice.option_id_choice_id
                  ) {
                    //ignore for bundle choice of parent product
                    console.log("clear prices from choice");
                    _.each(bundled_product.options, (option) => {
                      if (option.choices && option.choices.length) {
                        _.each(option.choices, (choice) => {
                          choice.price = 0;
                          if (_.has(choice, "price_takeaway")) {
                            choice.price_takeaway = 0;
                          }
                          if (_.has(choice, "price_dinein")) {
                            choice.price_dinein = 0;
                          }
                          if (_.has(choice, "price_alt_dinein")) {
                            choice.price_alt_dinein = 0;
                          }
                        });
                      }
                    });
                  }
                });
              }
            }
          }
        );
      }
    }
    if (parent_bundle_choice && parent_bundle_choice.bundle_options) {
      if (parent_bundle_choice.bundle_options.ignore_bundled_product_price) {
        bundled_product.price = 0;
        if (_.has(bundled_product, "price_takeaway")) {
          bundled_product.price_takeaway = 0;
        }
        if (_.has(bundled_product, "price_dinein")) {
          bundled_product.price_dinein = 0;
        }
        if (_.has(bundled_product, "price_alt_dinein")) {
          bundled_product.price_alt_dinein = 0;
        }
      }
      if (parent_bundle_choice.bundle_options.ignore_option_prices) {
        //ignore all option and choice prices
      } else if (
        !parent_bundle_choice.bundle_options.ignore_option_prices &&
        parent_bundle_choice.bundle_options.ignored_option_prices_array
      ) {
        //ignore specific choices and options
        _.each(
          parent_bundle_choice.bundle_options.ignored_option_prices_array,
          (ignoredOption) => {
            const ignored_option_index = _.findIndex(bundled_product.options, {
              product_id_option_id: ignoredOption.product_id_option_id,
            });
            console.log("ignored_option_index", ignored_option_index);
            if (ignored_option_index !== -1) {
              if (ignoredOption.ignore_all_choices_prices) {
                _.each(
                  bundled_product.options[ignored_option_index].choices,
                  (choice) => {
                    choice.price = 0;
                    if (_.has(choice, "price_takeaway")) {
                      choice.price_takeaway = 0;
                    }
                    if (_.has(choice, "price_dinein")) {
                      choice.price_dinein = 0;
                    }
                    if (_.has(choice, "price_alt_dinein")) {
                      choice.price_alt_dinein = 0;
                    }
                  }
                );
              } else {
                _.each(ignoredOption.choices, (ignored_choice) => {
                  const ignored_choice_index = _.findIndex(
                    bundled_product.options[ignored_option_index].choices,
                    {
                      option_id_choice_id: ignored_choice.option_id_choice_id,
                    }
                  );
                  if (ignored_choice_index !== -1) {
                    bundled_product.options[ignored_option_index].choices[
                      ignored_choice_index
                    ].price = 0;

                    if (
                      _.has(
                        bundled_product.options[ignored_option_index].choices[
                          ignored_choice_index
                        ],
                        "price_takeaway"
                      )
                    ) {
                      bundled_product.options[ignored_option_index].choices[
                        ignored_choice_index
                      ].price_takeaway = 0;
                    }
                    if (
                      _.has(
                        bundled_product.options[ignored_option_index].choices[
                          ignored_choice_index
                        ],
                        "price_dinein"
                      )
                    ) {
                      bundled_product.options[ignored_option_index].choices[
                        ignored_choice_index
                      ].price_dinein = 0;
                    }
                    if (
                      _.has(
                        bundled_product.options[ignored_option_index].choices[
                          ignored_choice_index
                        ],
                        "price_alt_dinein"
                      )
                    ) {
                      bundled_product.options[ignored_option_index].choices[
                        ignored_choice_index
                      ].price_alt_dinein = 0;
                    }
                  }
                });
              }
            }
          }
        );
      }
    }

    return bundled_product;
  }

  calculateBundleProductOptionsExceptions(product, parentBundleChoice) {
    console.log(
      "calculateBundleProductOptionsExceptions",
      product,
      parentBundleChoice
    );
    if (
      parentBundleChoice &&
      parentBundleChoice.bundle_options &&
      parentBundleChoice.bundle_options.optionsExceptions &&
      !_.isEmpty(parentBundleChoice.bundle_options.optionsExceptions) &&
      _.isArray(parentBundleChoice.bundle_options.optionsExceptions) &&
      product &&
      product.options
    ) {
      let dependencies_to_delete_array = [];
      _.each(
        parentBundleChoice.bundle_options.optionsExceptions,
        (excOption) => {
          if (excOption.hide) {
            const index = _.findIndex(product.options, {
              product_id_option_id: excOption.product_id_option_id,
            });

            if (index !== -1) {
              _.each(product.options[index].choices, (choice) => {
                if (choice.dependentOptions && choice.dependentOptions.length) {
                  _.each(choice.dependentOptions, (dep_opt) => {
                    dependencies_to_delete_array.push(dep_opt);
                  });
                }

                if (
                  choice.dependenciesWhenNotPreselected &&
                  choice.dependenciesWhenNotPreselected.length
                ) {
                  _.each(choice.dependenciesWhenNotPreselected, (dep_opt) => {
                    dependencies_to_delete_array.push(dep_opt);
                  });
                }
              });

              product.options.splice(index, 1);
            }
          } else {
            if (excOption && excOption.choices && excOption.choices.length) {
              const optionIndex = _.findIndex(product.options, {
                product_id_option_id: excOption.product_id_option_id,
              });
              _.each(excOption.choices, (excChoice) => {
                if (excChoice.hide && optionIndex !== -1) {
                  const choiceIndex = _.findIndex(
                    product.options[optionIndex].choices,
                    {
                      option_id_choice_id: excChoice.option_id_choice_id,
                    }
                  );

                  if (choiceIndex !== -1) {
                    if (
                      product.options[optionIndex].choices[choiceIndex]
                        .dependentOptions &&
                      product.options[optionIndex].choices[choiceIndex]
                        .dependentOptions.length
                    ) {
                      _.each(
                        product.options[optionIndex].choices[choiceIndex]
                          .dependentOptions,
                        (dep_opt) => {
                          dependencies_to_delete_array.push(dep_opt);
                        }
                      );
                    }

                    if (
                      product.options[optionIndex].choices[choiceIndex]
                        .dependenciesWhenNotPreselected &&
                      product.options[optionIndex].choices[choiceIndex]
                        .dependenciesWhenNotPreselected.length
                    ) {
                      _.each(
                        product.options[optionIndex].choices[choiceIndex]
                          .dependenciesWhenNotPreselected,
                        (dep_opt) => {
                          dependencies_to_delete_array.push(dep_opt);
                        }
                      );
                    }

                    product.options[optionIndex].choices.splice(choiceIndex, 1);
                  }
                }
              });
            }
          }
        }
      );

      // this is for checking to remove dependant option that their parent choices has been removed!!!

      if (dependencies_to_delete_array && dependencies_to_delete_array.length) {
        _.each(dependencies_to_delete_array, (dependency) => {
          _.each(product.options, (option) => {
            _.each(option.choices, (choice) => {
              if (choice.dependentOptions) {
                _.each(choice.dependentOptions, (depOption) => {
                  if (
                    depOption.product_id_option_id ===
                    dependency.product_id_option_id
                  ) {
                    dependency.do_not_remove = true;
                  }
                });
              }
              if (choice.dependenciesWhenNotPreselected) {
                _.each(choice.dependenciesWhenNotPreselected, (depOption) => {
                  if (
                    depOption.product_id_option_id ===
                    dependency.product_id_option_id
                  ) {
                    dependency.do_not_remove = true;
                  }
                });
              }
            });
          });
        });

        _.each(dependencies_to_delete_array, (dep) => {
          if (!dep.do_not_remove) {
            const delete_option_index = _.findIndex(product.options, {
              product_id_option_id: dep.product_id_option_id,
            });

            if (delete_option_index !== -1) {
              product.options.splice(delete_option_index, 1);
            }
          }
        });
      }
    }

    return _.cloneDeep(product);
  }

  calculateBundleChoiceProducts(selectedProduct, parent_bundle_choice) {
    return new Promise((resolve, reject) => {
      let productHasOptionsForLoadCategories = false;
      _.each(selectedProduct.options, (option) => {
        if (
          option &&
          option.bundle &&
          option.bundle_options &&
          option.bundle_options &&
          option.bundle_options.load_category_choice_products &&
          option.bundle_options.choiceCategoriesProducts &&
          option.bundle_options.choiceCategoriesProducts.length
        ) {
          productHasOptionsForLoadCategories = true;
        }
      });

      if (!productHasOptionsForLoadCategories) {
        resolve(_.cloneDeep(selectedProduct));
        return;
      }

      this.store
        .select("catalog")
        .pipe(first())
        .subscribe((catalogState) => {
          this.store
            .select("hyperCategories")
            .pipe(first())
            .subscribe(async (hyperCatState) => {
              let categories;
              if (
                hyperCatState &&
                hyperCatState.hyperCategories &&
                hyperCatState.hyperCategories.length &&
                hyperCatState.allCategories
              ) {
                categories = _.cloneDeep(hyperCatState.allCategories);
              } else if (
                hyperCatState &&
                hyperCatState.hyperCategories &&
                !hyperCatState.hyperCategories.length &&
                catalogState &&
                catalogState.allCategories
              ) {
                categories = _.cloneDeep(catalogState.allCategories);
              } else if (this.catalog) {
                categories = _.cloneDeep(this.catalog);
              } else {
                await this.fetchCatalogCdn();
              }
              _.each(selectedProduct.options, (option) => {
                if (
                  option &&
                  option.bundle &&
                  option.bundle_options &&
                  option.bundle_options &&
                  option.bundle_options.load_category_choice_products &&
                  option.bundle_options.choiceCategoriesProducts &&
                  option.bundle_options.choiceCategoriesProducts.length
                ) {
                  console.log("calculate bundle choice products");
                  if (!option.choices) {
                    option.choices = [];
                  }
                  _.each(
                    option.bundle_options.choiceCategoriesProducts,
                    (categoryObj) => {
                      if (categoryObj && categoryObj.category_id) {
                        const current_category = _.find(categories, {
                          category_id: categoryObj.category_id,
                        });
                        if (current_category && current_category.products) {
                          _.each(current_category.products, (product) => {
                            let productsExceptions =
                              _.cloneDeep(categoryObj.productsExceptions) || [];
                            if (
                              parent_bundle_choice &&
                              parent_bundle_choice.bundle_options &&
                              parent_bundle_choice.bundle_options
                                .optionsExceptions
                            ) {
                              const opt_index = _.findIndex(
                                parent_bundle_choice.bundle_options
                                  .optionsExceptions,
                                {
                                  product_id_option_id:
                                    option.product_id_option_id,
                                }
                              );

                              if (opt_index !== -1) {
                                if (
                                  productsExceptions &&
                                  _.isArray(productsExceptions) &&
                                  parent_bundle_choice.bundle_options
                                    .optionsExceptions[opt_index]
                                    .override_load_categories_choice_product_exceptions &&
                                  parent_bundle_choice.bundle_options
                                    .optionsExceptions[opt_index]
                                    .override_load_categories_choice_product_exceptions
                                    .length
                                ) {
                                  _.each(
                                    parent_bundle_choice.bundle_options
                                      .optionsExceptions[opt_index]
                                      .override_load_categories_choice_product_exceptions,
                                    (prd) => {
                                      if (
                                        prd.exception &&
                                        !_.includes(
                                          productsExceptions,
                                          prd.product_id
                                        )
                                      ) {
                                        productsExceptions.push(prd.product_id);
                                      }
                                    }
                                  );
                                }
                              }
                            }

                            if (
                              productsExceptions &&
                              _.isArray(productsExceptions) &&
                              !_.includes(
                                productsExceptions,
                                product.product_id
                              )
                            ) {
                              const choice_id = uuid();
                              option.choices.push({
                                bundle: true,
                                option_id_choice_id:
                                  option.option_id + "_" + choice_id,
                                choice_id: choice_id,
                                product_id: selectedProduct.product_id,
                                category_id: selectedProduct.category_id,
                                price: 0,
                                active: true,
                                available: true,
                                availableTotal: true,
                                activeTotal: true,
                                bundled_with_product_id: product.product_id,
                                bundle_options: {},
                                freeQuantity: 0,
                                isFree: false,
                                is_preselected: false,
                                price_dinein: 0,
                                price_takeaway: 0,
                                quantity: 1,
                                quantityInChoicesStep: 1,
                                status: "active",
                              });
                            }
                          });
                        }
                      }
                    }
                  );
                }
              });

              resolve(_.cloneDeep(selectedProduct));
            })
            .unsubscribe();
        })
        .unsubscribe();
    });
  }

  getStoreInfo() {
    return new Promise((resolve, reject) => {
      this.store
        .select(selectors.getStoreInfo)
        .pipe(first())
        .subscribe((storeInfo) => {
          resolve(storeInfo);
        });
    });
  }

  getCatalogUnfiltered() {
    return new Promise((resolve, reject) => {
      this.store
        .select("catalog")
        .pipe(first())
        .subscribe((catalogState) => {
          resolve(_.cloneDeep(catalogState.catalogUnfiltered));
        });
    });
  }

  getCategories() {
    return new Promise((resolve, reject) => {
      this.store
        .select("hyperCategories")
        .pipe(first())
        .subscribe((state) => {
          this.store
            .select("catalog")
            .pipe(first())
            .subscribe((catalogState) => {
              if (
                state &&
                state.hyperCategories &&
                state.hyperCategories.length
              ) {
                resolve(state.allCategories);
              } else {
                resolve(catalogState.allCategories);
              }
            })
            .unsubscribe();
        });
    });
  }

  getSelectedLanguage() {
    return new Promise((resolve, reject) => {
      this.store
        .select("groupApp")
        .pipe(first())
        .subscribe((groupState) => {
          resolve(groupState.selectedLangugage);
        });
    });
  }

  async fetchCatalogCdn() {
    const store_info: any = await this.getStoreInfo();
    const selectedLanguage = await this.getSelectedLanguage();
    return new Promise((resolve, reject) => {
      this.dataStorageService
        .getCatalog(store_info.store_id, selectedLanguage || "el")
        .subscribe({
          next: (res: any) => {
            if (!res || !res.catalog) {
              resolve(null);
            } else {
              resolve(res.catalog);
            }
          },
          error: (err) => {
            let errMsg = this.translateService.instant(
              "errorMessages.problem_reaching_server"
            );
            resolve(null);
          },
        });
    });
  }

  async checkSelectChoiceAgainBundleProductPreorderAvailability(
    product,
    selectedPreorderDate,
    dineIn
  ) {
    return new Promise(async (resolve, reject) => {
      let selectChoiceAgain = false;
      const storeInfo: any = await this.getStoreInfo();

      console.log(
        "checkSelectChoiceAgainBundleProductPreorderAvailability",

        product
      );

      if (storeInfo && storeInfo.preorderActive && product && !dineIn) {
        const categories: any = await this.getCategories();

        if (categories && categories.length) {
          _.each(categories, (cat) => {
            const product_index = _.findIndex(cat.products, {
              product_id: product.product_id,
            });

            if (
              product_index !== -1 &&
              product.tags &&
              product.tags.availableForPreorder &&
              cat.tags &&
              cat.tags.availableForPreorder &&
              !product.availableTotal &&
              cat.availableTotal &&
              cat.activeTotal &&
              cat.promoCategory
            ) {
              selectChoiceAgain = true;
            } else if (
              cat.tags &&
              cat.tags.availableForPreorder &&
              product.tags &&
              product.tags.availableForPreorder &&
              product.availableTotal &&
              cat.availableTotal &&
              cat.promoCategory
            ) {
            } else if (
              product &&
              product.tags &&
              product.tags.availableForPreorder &&
              product.tags &&
              product.tags.availableForPreorder &&
              !cat.promoCategory
            ) {
              let foundProductInPromoCategory;
              _.each(categories, (categ) => {
                if (
                  categ &&
                  categ.promoCategory &&
                  categ.availableTotal &&
                  categ.tags &&
                  categ.tags.availableForPreorder
                ) {
                  _.each(categ.products, (categ_product) => {
                    if (categ_product.product_id === product.product_id) {
                      foundProductInPromoCategory = true;
                    }
                  });
                }
              });

              if (!foundProductInPromoCategory) {
                selectChoiceAgain = true;
              }
            }

            if (
              product.product_categories_availability_check &&
              product.product_categories_active_check &&
              (!cat.promoCategory ||
                !cat.tags ||
                !cat.tags.availableForPreorder) &&
              !product.availableTotal &&
              !product.activePeriod &&
              !product.availablePeriod &&
              product.availableInitializationActive &&
              product.availableInitializationTime &&
              (((!storeInfo.preorderDaysStart ||
                storeInfo.preorderDaysStart === 0) &&
                storeInfo.preorderDaysAhead > 1) ||
                storeInfo.preorderDaysStart > 0) &&
              (!product.preorder_availability_stock_enabled ||
                product.preorder_availability_stock > 0) &&
              (!product.tags || !product.tags.availableForPreorder)
            ) {
              if (!product.tags) {
                product.tags = {};
              }
              product.tags.initializationActivePreorder = true;

              selectChoiceAgain = true;
            }
          });
        } else {
          if (!this.catalog) {
            this.catalog = await this.fetchCatalogCdn();
          }

          if (this.catalog && this.catalog.length) {
            _.each(this.catalog, (cat) => {
              const product_index = _.findIndex(cat.products, {
                product_id: product.product_id,
              });

              if (
                product_index !== -1 &&
                product.tags &&
                product.tags.availableForPreorder &&
                cat.tags &&
                cat.tags.availableForPreorder &&
                !product.availableTotal &&
                cat.availableTotal &&
                cat.activeTotal &&
                cat.promoCategory
              ) {
                selectChoiceAgain = true;
              } else if (
                product.tags &&
                product.tags.availableForPreorder &&
                cat.tags &&
                cat.tags.availableForPreorder &&
                product.availableTotal &&
                cat.availableTotal &&
                cat.activeTotal &&
                cat.promoCategory
              ) {
              } else if (
                product &&
                product.tags &&
                product.tags.availableForPreorder &&
                !cat.promoCategory
              ) {
                let foundProductInPromoCategory;
                _.each(this.catalog, (categ) => {
                  if (
                    categ &&
                    categ.promoCategory &&
                    categ.availableTotal &&
                    categ.activeTotal &&
                    categ.tags &&
                    categ.tags.availableForPreorder
                  ) {
                    _.each(categ.products, (categ_product) => {
                      if (categ_product.product_id === product.product_id) {
                        foundProductInPromoCategory = true;
                      }
                    });
                  }
                });

                if (!foundProductInPromoCategory) {
                  selectChoiceAgain = true;
                }
              }

              if (
                product.product_categories_availability_check &&
                product.product_categories_active_check &&
                (!cat.promoCategory ||
                  !cat.tags ||
                  !cat.tags.availableForPreorder) &&
                !product.availableTotal &&
                !product.activePeriod &&
                !product.availablePeriod &&
                product.availableInitializationActive &&
                product.availableInitializationTime &&
                (((!storeInfo.preorderDaysStart ||
                  storeInfo.preorderDaysStart === 0) &&
                  storeInfo.preorderDaysAhead > 1) ||
                  storeInfo.preorderDaysStart > 0) &&
                (!product.preorder_availability_stock_enabled ||
                  product.preorder_availability_stock > 0) &&
                (!product.tags || !product.tags.availableForPreorder)
              ) {
                if (!product.tags) {
                  product.tags = {};
                }
                product.tags.initializationActivePreorder = true;

                selectChoiceAgain = true;
              }
            });
          }
        }

        resolve(_.cloneDeep(selectChoiceAgain));
      } else {
        resolve(_.cloneDeep(selectChoiceAgain));
      }
    });
  }

  async checkProductAvailabilityForPreorder(
    product,
    selectedPreorderDate,
    dineIn
  ) {
    console.log("checkProductAvailabilityForPreorder", product);
    return new Promise(async (resolve, reject) => {
      const storeInfo: any = await this.getStoreInfo();

      if (
        storeInfo &&
        storeInfo.preorderActive &&
        product &&
        ((product.tags && product.tags.availableForPreorder) ||
          (!product.availableTotal &&
            !product.activePeriod &&
            !product.availablePeriod &&
            product.availableInitializationActive &&
            product.availableInitializationTime &&
            (((!storeInfo.preorderDaysStart ||
              storeInfo.preorderDaysStart === 0) &&
              storeInfo.preorderDaysAhead > 1) ||
              storeInfo.preorderDaysStart > 0) &&
            (!product.preorder_availability_stock_enabled ||
              product.preorder_availability_stock > 0) &&
            (!product.tags || !product.tags.availableForPreorder))) &&
        !dineIn
      ) {
        const categories: any = await this.getCategories();

        if (categories && categories.length) {
          _.each(categories, (cat) => {
            const product_index = _.findIndex(cat.products, {
              product_id: product.product_id,
            });

            if (
              product_index !== -1 &&
              product.tags &&
              product.tags.availableForPreorder &&
              cat.tags &&
              cat.tags.availableForPreorder &&
              !product.availableTotal &&
              cat.availableTotal &&
              cat.activeTotal &&
              cat.promoCategory &&
              selectedPreorderDate &&
              selectedPreorderDate.date &&
              selectedPreorderDate.hour
            ) {
              product.availableTotal = true;
              product.avpc = null;
              product.hideAvailableForPreorderTag = false;
              product.availabilityChanged = true;
            } else if (
              product.tags &&
              product.tags.availableForPreorder &&
              cat.tags &&
              cat.tags.availableForPreorder &&
              product.availableTotal &&
              cat.availableTotal &&
              cat.activeTotal &&
              cat.promoCategory &&
              !product.availabilityChanged
            ) {
              product.hideAvailableForPreorderTag = true;
            } else if (
              product &&
              product.tags &&
              product.tags.availableForPreorder &&
              !cat.promoCategory
            ) {
              let foundProductInPromoCategory;
              _.each(categories, (categ) => {
                if (
                  categ &&
                  categ.promoCategory &&
                  categ.availableTotal &&
                  categ.activeTotal &&
                  categ.tags &&
                  categ.tags.availableForPreorder
                ) {
                  _.each(categ.products, (categ_product) => {
                    if (categ_product.product_id === product.product_id) {
                      foundProductInPromoCategory = true;
                    }
                  });
                }
              });

              if (!foundProductInPromoCategory) {
                product.hideAvailableForPreorderTag = true;
              }
            }

            if (
              product.product_categories_availability_check &&
              product.product_categories_active_check &&
              (!cat.promoCategory ||
                !cat.tags ||
                !cat.tags.availableForPreorder) &&
              !product.availableTotal &&
              !product.activePeriod &&
              !product.availablePeriod &&
              product.availableInitializationActive &&
              product.availableInitializationTime &&
              (((!storeInfo.preorderDaysStart ||
                storeInfo.preorderDaysStart === 0) &&
                storeInfo.preorderDaysAhead > 1) ||
                storeInfo.preorderDaysStart > 0) &&
              (!product.preorder_availability_stock_enabled ||
                product.preorder_availability_stock > 0) &&
              (!product.tags || !product.tags.availableForPreorder)
            ) {
              if (!product.tags) {
                product.tags = {};
              }
              product.tags.initializationActivePreorder = true;
              if (
                this.checkPreorderDatesForInitializationActiveProducts(
                  selectedPreorderDate
                )
              ) {
                product.availableTotal = true;
              }
            }
          });
        } else {
          if (!this.catalog) {
            this.catalog = await this.fetchCatalogCdn();
          }

          if (this.catalog && this.catalog.length) {
            _.each(this.catalog, (cat) => {
              const product_index = _.findIndex(cat.products, {
                product_id: product.product_id,
              });

              if (
                product_index !== -1 &&
                product.tags &&
                product.tags.availableForPreorder &&
                cat.tags &&
                cat.tags.availableForPreorder &&
                !product.availableTotal &&
                cat.availableTotal &&
                cat.activeTotal &&
                cat.promoCategory &&
                selectedPreorderDate &&
                selectedPreorderDate.date &&
                selectedPreorderDate.hour
              ) {
                product.availableTotal = true;
                product.avpc = null;
                product.hideAvailableForPreorderTag = false;
                product.availabilityChanged = true;
              } else if (
                product.tags &&
                product.tags.availableForPreorder &&
                cat.tags &&
                cat.tags.availableForPreorder &&
                product.availableTotal &&
                cat.availableTotal &&
                cat.activeTotal &&
                cat.promoCategory &&
                !product.availabilityChanged
              ) {
                product.hideAvailableForPreorderTag = true;
              } else if (
                product &&
                product.tags &&
                product.tags.availableForPreorder &&
                !cat.promoCategory
              ) {
                let foundProductInPromoCategory;
                _.each(this.catalog, (categ) => {
                  if (
                    categ &&
                    categ.promoCategory &&
                    categ.availableTotal &&
                    categ.activeTotal &&
                    categ.tags &&
                    categ.tags.availableForPreorder
                  ) {
                    _.each(categ.products, (categ_product) => {
                      if (categ_product.product_id === product.product_id) {
                        foundProductInPromoCategory = true;
                      }
                    });
                  }
                });

                if (!foundProductInPromoCategory) {
                  product.hideAvailableForPreorderTag = true;
                }
              }

              if (
                product.product_categories_availability_check &&
                product.product_categories_active_check &&
                (!cat.promoCategory ||
                  !cat.tags ||
                  !cat.tags.availableForPreorder) &&
                !product.availableTotal &&
                !product.activePeriod &&
                !product.availablePeriod &&
                product.availableInitializationActive &&
                product.availableInitializationTime &&
                (((!storeInfo.preorderDaysStart ||
                  storeInfo.preorderDaysStart === 0) &&
                  storeInfo.preorderDaysAhead > 1) ||
                  storeInfo.preorderDaysStart > 0) &&
                (!product.preorder_availability_stock_enabled ||
                  product.preorder_availability_stock > 0) &&
                (!product.tags || !product.tags.availableForPreorder)
              ) {
                if (!product.tags) {
                  product.tags = {};
                }
                if (
                  this.checkPreorderDatesForInitializationActiveProducts(
                    selectedPreorderDate
                  )
                ) {
                  product.availableTotal = true;
                }
                product.tags.initializationActivePreorder = true;
              }
            });
          }
        }
        if (product && _.has(product, ["availabilityChanged"])) {
          product = _.omit(_.cloneDeep(product), ["availabilityChanged"]);
        }

        resolve(_.cloneDeep(product));
      } else {
        if (product && _.has(product, ["availabilityChanged"])) {
          product = _.omit(_.cloneDeep(product), ["availabilityChanged"]);
        }
        resolve(_.cloneDeep(product));
      }
    });
  }

  async checkShowProductAvailabilityForPreorderAlert(
    product,
    selectedPreorderDate,
    dinein
  ) {
    console.log("checkShowProductAvailabilityForPreorderAlert", product);
    return new Promise(async (resolve, reject) => {
      if (dinein) {
        resolve(false);
      }
      const storeInfo: any = await this.getStoreInfo();

      if (
        storeInfo &&
        storeInfo.preorderActive &&
        (!selectedPreorderDate ||
          !selectedPreorderDate.date ||
          !selectedPreorderDate.hour) &&
        product &&
        !product.availableTotal &&
        product.tags &&
        product.tags.availableForPreorder
      ) {
        const categories: any = await this.getCategories();

        if (categories && categories.length) {
          _.each(categories, (cat) => {
            if (cat.promoCategory && cat.availableTotal && cat.activeTotal) {
              const product_index = _.findIndex(cat.products, {
                product_id: product.product_id,
              });

              if (
                product_index !== -1 &&
                cat.tags &&
                cat.tags.availableForPreorder
              ) {
                resolve({
                  show: true,
                  message:
                    "product-details.this-product-is-available-only-for-preorder-do-you-want-to-enable-preorder-mode",
                });
              }
            }
          });

          resolve({ show: false });
        } else {
          if (!this.catalog) {
            this.catalog = await this.fetchCatalogCdn();
          }

          if (this.catalog && this.catalog.length) {
            _.each(this.catalog, (cat) => {
              if (cat.promoCategory && cat.availableTotal) {
                const product_index = _.findIndex(cat.products, {
                  product_id: product.product_id,
                });

                if (
                  product_index !== -1 &&
                  cat.tags &&
                  cat.tags.availableForPreorder
                ) {
                  resolve({
                    show: true,
                    message:
                      "product-details.this-product-is-available-only-for-preorder-do-you-want-to-enable-preorder-mode",
                  });
                }
              }
            });

            resolve({ show: false });
          }

          resolve({ show: false });
        }
      } else if (
        storeInfo &&
        storeInfo.preorderActive &&
        !product.availableTotal &&
        !product.activePeriod &&
        !product.availablePeriod &&
        product.availableInitializationActive &&
        product.availableInitializationTime &&
        (((!storeInfo.preorderDaysStart || storeInfo.preorderDaysStart === 0) &&
          storeInfo.preorderDaysAhead > 1) ||
          storeInfo.preorderDaysStart > 0) &&
        (!product.preorder_availability_stock_enabled ||
          product.preorder_availability_stock > 0) &&
        !this.checkPreorderDatesForInitializationActiveProducts(
          selectedPreorderDate
        ) &&
        (!product.tags || !product.tags.availableForPreorder)
      ) {
        const categories: any = await this.getCategories();

        if (categories && categories.length) {
          _.each(categories, (cat) => {
            if (
              (!cat.promoCategory ||
                !cat.tags ||
                !cat.tags.availableForPreorder) &&
              cat.availableTotal &&
              cat.activeTotal
            ) {
              const product_index = _.findIndex(cat.products, {
                product_id: product.product_id,
              });

              if (product_index !== -1) {
                resolve({
                  show: true,
                  message:
                    "product-details.this-product-is-available-only-for-preorder-after-tommorow",
                });
              }
            }
          });
        } else {
          if (!this.catalog) {
            this.catalog = await this.fetchCatalogCdn();
          }

          if (this.catalog && this.catalog.length) {
            _.each(this.catalog, (cat) => {
              if (
                (!cat.promoCategory ||
                  !cat.tags ||
                  !cat.tags.availableForPreorder) &&
                cat.availableTotal &&
                cat.activeTotal
              ) {
                const product_index = _.findIndex(cat.products, {
                  product_id: product.product_id,
                });

                if (product_index !== -1) {
                  resolve({
                    show: true,
                    message:
                      "product-details.this-product-is-available-only-for-preorder-after-tommorow",
                  });
                }
              }
            });
          }
        }

        resolve({ show: false });
      } else {
        resolve({ show: false });
      }
    });
  }

  checkPreorderDatesForInitializationActiveProducts(selectedPreorderDate) {
    if (
      selectedPreorderDate &&
      selectedPreorderDate.hour &&
      selectedPreorderDate.date
    ) {
      const preorderDate = moment(
        `${selectedPreorderDate.date} ${selectedPreorderDate.hour}`,
        "DD-MM-YYYY HH:mm"
      );

      const tomorrowDate = moment()
        .add(1, "day")
        .set("hour", 0)
        .set("minutes", 0);

      if (preorderDate.isAfter(tomorrowDate)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  async calculateonlyForPreorderForCartProducts(
    products,
    selectedPreorderDate
  ) {
    return new Promise(async (resolve, reject) => {
      const storeInfo: any = await this.getStoreInfo();

      if (
        storeInfo &&
        storeInfo.preorderActive &&
        (!selectedPreorderDate ||
          !selectedPreorderDate.date ||
          !selectedPreorderDate.hour)
      ) {
        const categories: any = await this.getCategories();

        if (categories && categories.length) {
          _.each(products, (product) => {
            this.calculateonlyForPreorderForCartProductsActions(
              categories,
              product,
              storeInfo
            );
          });
        } else {
          if (!this.catalog) {
            this.catalog = await this.fetchCatalogCdn();
          }

          _.each(products, (product) => {
            this.calculateonlyForPreorderForCartProductsActions(
              this.catalog,
              product,
              storeInfo
            );
          });
        }
      }

      resolve(_.cloneDeep(products));
    });
  }

  calculateonlyForPreorderForCartProductsActions(
    categories,
    product,
    storeInfo
  ) {
    if (_.has(product, ["onlyForPreorder"])) {
      product = _.omit(_.cloneDeep(product), ["onlyForPreorder"]);
    }

    //here we check the product's category availableTotal and activeTotal and not the product_categories_active_check and product_categories_availability_check attribues because this attributes calculated in catalog page and product details modal
    //we check all categories so not a problem

    _.each(categories, (cat) => {
      if (
        (product &&
          product.tags &&
          product.tags.availableForPreorder &&
          !product.availableTotal &&
          product.activeTotal &&
          cat &&
          cat.tags &&
          cat.tags.availableForPreorder &&
          cat.promoCategory &&
          cat.availableTotal &&
          cat.activeTotal) ||
        ((!product.tags || !product.tags.availableForPreorder) &&
          cat.availableTotal &&
          cat.activeTotal &&
          (!cat.promoCategory || !cat.tags || !cat.tags.availableForPreorder) &&
          !product.availableTotal &&
          !product.activePeriod &&
          !product.availablePeriod &&
          product.activeTotal &&
          product.availableInitializationActive &&
          product.availableInitializationTime &&
          (((!storeInfo.preorderDaysStart ||
            storeInfo.preorderDaysStart === 0) &&
            storeInfo.preorderDaysAhead > 1) ||
            storeInfo.preorderDaysStart > 0) &&
          (!product.preorder_availability_stock_enabled ||
            product.preorder_availability_stock > 0))
      ) {
        const product_index = _.findIndex(cat.products, {
          product_id: product.product_id,
        });

        if (product_index !== -1) {
          product.onlyForPreorder = true;
        }
      }

      if (product && product.options) {
        _.each(product.options, (option) => {
          if (option.bundle && option.choices) {
            _.each(option.choices, (bundle_choice) => {
              if (
                bundle_choice.bundle === true &&
                bundle_choice.bundled_product
              ) {
                this.calculateonlyForPreorderForCartProductsActions(
                  categories,
                  bundle_choice.bundled_product,
                  storeInfo
                );
              }
            });
          }
        });
      }
    });
  }

  public calculateIfProductNeedsEdit(product) {
    _.each(product.options, (option) => {
      _.each(option.choices, (choice) => {
        if (choice.bundle === true && !_.isEmpty(choice.bundled_product)) {
          this.calculateIfProductNeedsEdit(_.cloneDeep(choice.bundled_product));
        }
      });
    });

    // if (product.name === "bundle stock") {
    //   console.log(
    //     "this.checkIfProductNeedsEdit(_.cloneDeep(product))",
    //     product.active,
    //     product.available,
    //     product.activeTotal,
    //     product.availableTotal,
    //     this.checkIfProductNeedsEdit(_.cloneDeep(product))
    //   );
    // }

    product.needsEdit = this.checkIfProductNeedsEdit(_.cloneDeep(product));

    return _.cloneDeep(product);
  }

  public checkIfProductNeedsEdit(product) {
    if (_.isEmpty(product) || product.gift) {
      return false;
    }

    if (
      !this.checkActiveAvailable(product) ||
      (product.options &&
        _.isArray(product.options) &&
        _.find(product.options, (option) => {
          return (
            (!this.checkActiveAvailable(option) &&
              this.checkIfOptionHasChoicesSelected(option)) ||
            (option.choices &&
              _.isArray(option.choices) &&
              _.find(option.choices, (choice) => {
                return (
                  (choice.is_preselected &&
                    !this.checkActiveAvailable(choice)) ||
                  (choice.is_preselected &&
                    choice.bundle === true &&
                    !_.isEmpty(choice.bundled_product) &&
                    !this.checkActiveAvailable(choice.bundled_product))
                );
              }))
          );
        }))
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkActiveAvailable(obj) {
    if (
      _.has(obj, "activeTotal") &&
      obj.activeTotal !== "null" &&
      obj.activeTotal &&
      _.has(obj, "availableTotal") &&
      obj.availableTotal !== "null" &&
      obj.availableTotal
    ) {
      return true;
    } else if (
      (!_.has(obj, "activeTotal") ||
        !_.has(obj, "availableTotal") ||
        obj.activeTotal === "null" ||
        obj.availableTotal === "null") &&
      obj.active &&
      obj.available
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkIfOptionHasChoicesSelected(option) {
    if (
      !_.find(
        _.reject(option.choices, (choice) => {
          return !this.checkActiveAvailable(choice);
        }),
        {
          is_preselected: true,
        }
      )
    ) {
      return false;
    } else {
      return true;
    }
  }

  async checkProductCategoriesAvailability(product) {
    return new Promise(async (resolve, reject) => {
      const catalogUnfiltered: any = await this.getCatalogUnfiltered();

      if (catalogUnfiltered && catalogUnfiltered.length) {
        product = this.checkProductsCategoriesAvailabilityActions(
          product,
          catalogUnfiltered
        );
        resolve(_.cloneDeep(product));
      } else {
        if (!this.catalog) {
          this.catalog = await this.fetchCatalogCdn();
        }
        product = this.checkProductsCategoriesAvailabilityActions(
          product,
          this.catalog
        );

        resolve(_.cloneDeep(product));
      }
    });
  }

  checkProductsCategoriesAvailabilityActions(product, categories) {
    if (product && product.options) {
      _.each(product.options, (opt) => {
        if (opt && opt.choices) {
          _.each(opt.choices, (ch) => {
            if (ch.bundle === true && ch.bundled_product) {
              ch.bundled_product =
                this.checkProductsCategoriesAvailabilityActions(
                  _.cloneDeep(ch.bundled_product),
                  categories
                );
            }
          });
        }
      });
    }

    product = this.checkProductCategoriesAvailabilityActions(
      _.cloneDeep(product),
      categories
    );

    return _.cloneDeep(product);
  }

  checkProductCategoriesAvailabilityActions(product, categories) {
    const original_category = _.find(categories, {
      category_id: product.category_id,
    });

    if (product.categories && product.categories.length) {
      let productCategoryActiveTotal, productCategoryAvailableTotal;

      if (
        original_category &&
        (!original_category.availableTotal || !original_category.activeTotal)
      ) {
        _.each(product.categories, (cat) => {
          const product_category = _.find(categories, {
            category_id: cat.category_id,
          });

          if (
            product_category.promoCategory &&
            !product_category.check_promo_category_products_availability
          ) {
            if (product_category.availableTotal) {
              productCategoryAvailableTotal = true;
            }

            if (product_category.activeTotal) {
              productCategoryActiveTotal = true;
            }
          } else if (!product_category.promoCategory) {
            if (product_category.availableTotal) {
              productCategoryAvailableTotal = true;
            }

            if (product_category.activeTotal) {
              productCategoryActiveTotal = true;
            }
          }
        });

        if (productCategoryActiveTotal) {
          product.product_categories_active_check = true;
        }

        if (productCategoryAvailableTotal) {
          product.product_categories_availability_check = true;
        }
      } else {
        product.product_categories_availability_check = _.cloneDeep(
          original_category.availableTotal
        );

        product.product_categories_active_check = _.cloneDeep(
          original_category.activeTotal
        );
      }
    } else {
      product.product_categories_availability_check = _.cloneDeep(
        original_category.availableTotal
      );
      product.product_categories_active_check = _.cloneDeep(
        original_category.activeTotal
      );
    }

    return _.cloneDeep(product);
  }

  calculateProductQuantityWithStep(product) {
    if (
      product &&
      !product.disable_qty &&
      product.enable_quantity_step &&
      product.quantity_step
    ) {
      if (product.min_quantity) {
        product.quantity = _.cloneDeep(product.min_quantity);
      } else {
        product.quantity = _.cloneDeep(product.quantity_step);
      }
    } else {
      product.quantity = _.cloneDeep(parseInt(product.quantity.toString()));
    }

    return _.cloneDeep(product);
  }

  checkIfProuctHasNotValidDeliveryAreas(
    product,
    orderPickup,
    dineIn,
    selectedAddress,
    validDeliveryAreas
  ) {
    if (
      validDeliveryAreas &&
      validDeliveryAreas.length &&
      !orderPickup &&
      !dineIn &&
      selectedAddress
    ) {
      if (_.has(product, "notValidDeliveryArea")) {
        delete product.notValidDeliveryArea;
      }

      if (
        product &&
        product.validProductDeliveryAreas &&
        product.validProductDeliveryAreas.length
      ) {
        let notValidArea;
        _.each(validDeliveryAreas, (area) => {
          const found = _.find(product.validProductDeliveryAreas, {
            timestamp: area.timestamp,
          });

          if (found && area.status) {
            return false;
          } else {
            notValidArea = true;
          }
        });

        if (!notValidArea) {
          if (product.notValidDeliveryArea) {
            delete product.notValidDeliveryArea;
          }
        } else {
          product.notValidDeliveryArea = true;
        }
      } else {
        if (product.notValidDeliveryArea) {
          delete product.notValidDeliveryArea;
        }
      }
    }

    return _.cloneDeep(product);
  }

  omit_from_product(product) {
    // This function also exists in the backend calculate final price lambda
    const omit_from_product = (product) => {
      return _.omit(product, [
        "initial_price",
        "num_of_options",
        "product_link",
        "description",
        "original_description",
        "image",
        "showOptionInDescription",
        "productIncludedInCart",
      ]);
    };

    const omit_from_option = (option) => {
      return _.omit(option, [
        "num_of_choices",
        "always_open",
        "always_split_columns",
        "new_template_from_cloning",
        "image",
        "optionData",
        "template_id_option_id",
      ]);
    };

    const omit_from_choice = (choice) => {
      return _.omit(choice, [
        "template",
        "image",
        "type",
        "dependenciesWhenNotPreselected",
        "dependentOptions",
        "option_template_id_choice_id",
      ]);
    };
    product = omit_from_product(product);
    product.options = _.filter(
      product.options,
      (option) =>
        !option.dependenciesVariable ||
        option.dependenciesVariable.length === 0 ||
        (option.dependenciesVariable.length > 0 && option.showDependent)
    );

    _.each(product.options, (option, o) => {
      product.options[o] = omit_from_option(option);
      if (_.isEmpty(option.preselectedChoices)) {
        product.options[o].choices = _.filter(
          product.options[o].choices,
          (choice) =>
            choice.is_preselected && choice.activeTotal && choice.availableTotal
        );
      }
      _.each(product.options[o].choices, (choice, c) => {
        product.options[o].choices[c] = omit_from_choice(choice);
      });
    });
  }

  addProductWithNoOptionsToCart(prd) {
    //we dont need to add a cart_id and analytics if is a bundle product of a bundle choice cause we are not gona adding to cart
    prd.cart_id = Date.now().toString();
    this.omit_from_product(prd);
    this.analyticsService.logEventAddToCart();

    this.store
      .select("deliveryMethodAddress")
      .pipe(first())
      .subscribe((state) => {
        this.analyticsService.fbqTrackAddToCart(
          _.cloneDeep(prd),
          state.orderPickup,
          state.dineIn
        );
      })
      .unsubscribe();

    this.analyticsService.dmAnalyticsMessage(
      "added_product",
      null,
      prd,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );

    this.store.dispatch(
      new CartActions.AddUpdateCartProductItem(_.cloneDeep(prd))
    );
  }

  deleteAllProductPrices(product) {
    if (product.price) {
      product.price = 0;
    }
    if (product.specialPrice) {
      product.specialPrice = null;
    }
    if (product.price_takeaway) {
      product.price_takeaway = null;
    }
    if (product.price_dinein) {
      product.price_dinein = null;
    }
    if (product.price_alt_dinein) {
      product.price_alt_dinein = null;
    }

    _.each(product.options, (opt) => {
      _.each(opt.choices, (ch) => {
        if (ch.price) {
          ch.price = 0;
        }
        if (ch.specialPrice) {
          ch.specialPrice = null;
        }
        if (ch.price_takeaway) {
          ch.price_takeaway = null;
        }
        if (ch.price_dinein) {
          ch.price_dinein = null;
        }
        if (ch.price_alt_dinein) {
          ch.price_alt_dinein = null;
        }
      });
    });

    return _.cloneDeep(product);
  }

  checkUserCatalogZeroPricesMode() {
    return new Promise((resolve) => {
      this.store
        .select("auth")
        .pipe(
          first((authState) => {
            if (
              authState &&
              authState.loginState &&
              (authState.loginState === "no_login" ||
                (authState.loginState === "login_completed" &&
                  !_.isEmpty(authState.user)))
            ) {
              return true;
            }
          })
        )
        .subscribe((state) => {
          if (state.user && state.user.catalogZeroPricesMode) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
    });
  }

  addProductToCartFromCatalogInstant(
    store_id,
    product_id,
    languagePath,
    forCustomerPricelists,
    bundleMode,
    parentBundleChoice,
    parentProduct
  ) {
    console.log("fetchProduct", store_id, product_id, languagePath);
    if (store_id && product_id && languagePath) {
      let selectedProduct: any;
      this.dataStorageService
        .getProductDetails(store_id, product_id, languagePath)
        .subscribe({
          next: async (res: any) => {
            if (res && res.success) {
              console.log(res);
              let prd_details = _.cloneDeep(res.productDetails);
              let storeInfo: any = await this.getStoreInfo();
              const userStoreNopricesMode: any =
                await this.checkUserCatalogZeroPricesMode();

              if (
                (storeInfo && storeInfo.catalogZeroPricesMode) ||
                userStoreNopricesMode
              ) {
                prd_details = await this.deleteAllProductPrices(
                  _.cloneDeep(prd_details)
                );
              }

              prd_details = await this.checkForCustomerPricelist(
                _.cloneDeep(prd_details)
              );

              prd_details = await this.checkUpdateStockValues(
                _.cloneDeep(prd_details)
              );

              prd_details = this.calculateProductQuantityWithStep(
                _.cloneDeep(prd_details)
              );

              prd_details = await this.checkProductCategoriesAvailability(
                _.cloneDeep(prd_details)
              );

              selectedProduct = _.cloneDeep(prd_details);

              this.store
                .select(selectors.getStoreInfo)
                .pipe(first())
                .subscribe((store_info) => {
                  this.store
                    .select("deliveryMethodAddress")
                    .pipe(first())
                    .subscribe(async (DeliveryState) => {
                      if (bundleMode) {
                        selectedProduct =
                          this.calculateBundleProductIgnoreOptionPrices(
                            _.cloneDeep(parentProduct),
                            _.cloneDeep(parentBundleChoice),
                            _.cloneDeep(selectedProduct)
                          );
                      }
                      selectedProduct =
                        this.calculateBundleProductOptionsExceptions(
                          _.cloneDeep(selectedProduct),
                          _.cloneDeep(parentBundleChoice)
                        );

                      selectedProduct =
                        await this.calculateBundleChoiceProducts(
                          _.cloneDeep(selectedProduct),
                          _.cloneDeep(parentBundleChoice)
                        );

                      selectedProduct = this.checkIgnoreImage(
                        _.cloneDeep(selectedProduct),
                        _.cloneDeep(parentBundleChoice)
                      );

                      selectedProduct = this.initializeProductOptionsCheckbox(
                        _.cloneDeep(selectedProduct)
                      );
                      selectedProduct = this.checkIsDependentOptions(
                        _.cloneDeep(selectedProduct)
                      );
                      selectedProduct = this.productAllOptionsPriceComments(
                        _.cloneDeep(selectedProduct),
                        DeliveryState.orderPickup,
                        DeliveryState.dineIn,
                        store_info && store_info.dinein
                          ? store_info.alt_dinein_active
                          : false,
                        DeliveryState.selectedDineInTable
                      );

                      selectedProduct =
                        await this.checkProductAvailabilityForPreorder(
                          _.cloneDeep(selectedProduct),
                          DeliveryState.selectedPreorderDate,
                          DeliveryState.dineIn
                        );

                      selectedProduct =
                        this.checkIfProuctHasNotValidDeliveryAreas(
                          _.cloneDeep(selectedProduct),
                          DeliveryState.orderPickup,
                          DeliveryState.dineIn,
                          DeliveryState.selectedAddress,
                          DeliveryState.validDeliveryAreas
                        );

                      if (forCustomerPricelists) {
                        this.store
                          .select("deliveryMethodAddress")
                          .pipe(first())
                          .subscribe(async (state) => {
                            //console.log(":call product price");
                            selectedProduct = this.cartProductPrice(
                              selectedProduct,
                              DeliveryState.orderPickup,
                              DeliveryState.dineIn,
                              parentBundleChoice && bundleMode
                                ? _.cloneDeep(parentBundleChoice)
                                : null,
                              true,
                              store_info && store_info.dinein
                                ? store_info.alt_dinein_active
                                : false,
                              DeliveryState.selectedDineInTable,
                              store_info.decimalPlacesPrices
                            );

                            selectedProduct =
                              await this.calculateSelectedProductPoints(
                                _.cloneDeep(selectedProduct),
                                selectedProduct.productTotal,
                                true
                              );
                          })
                          .unsubscribe();
                      }

                      console.log("set selectedProduct");
                      const openPreorderProductAvailabilityAlert: any =
                        await this.checkShowProductAvailabilityForPreorderAlert(
                          _.cloneDeep(selectedProduct),
                          _.cloneDeep(DeliveryState.selectedPreorderDate),
                          DeliveryState.dineIn
                        );

                      console.log(
                        "openPreorderProductAvailabilityAlert",
                        openPreorderProductAvailabilityAlert
                      );

                      const selectedPrd = _.cloneDeep(selectedProduct);

                      if (
                        selectedPrd &&
                        selectedPrd.num_of_options &&
                        selectedProduct.num_of_options <= 1
                      ) {
                        this.addProductWithNoOptionsToCart(selectedPrd);
                      }
                    })
                    .unsubscribe();
                })
                .unsubscribe();
            } else {
              if (res && res.comment_id) {
                const alert = await this.alertController.create({
                  header: this.translateService.instant("alert"),
                  message:
                    res && res.comment_id
                      ? this.translateService.instant(res.comment_id)
                      : this.translateService.instant(
                          "errorMessages.general_error"
                        ),

                  buttons: ["OK"],
                });
                await alert.present();
              } else if (res && res.comments) {
                const alert = await this.alertController.create({
                  header: this.translateService.instant("alert"),
                  message: this.translateService.instant(res.comments),

                  buttons: ["OK"],
                });
                await alert.present();
              }
            }
          },
          error: async (err) => {
            const alert = await this.alertController.create({
              header: this.translateService.instant("alert"),
              message: this.translateService.instant(
                "errorMessages.problem_reaching_server"
              ),

              buttons: ["OK"],
            });
            await alert.present();
            return err;
          },
        });
    } else {
      console.log("something missing");
    }
  }

  checkForCustomerPricelist(selectedProduct) {
    return new Promise((resolve) => {
      if (selectedProduct && selectedProduct.price) {
        this.store
          .select(selectors.getStoreInfo)
          .pipe(first())
          .subscribe((storeInfo) => {
            if (
              storeInfo &&
              storeInfo.enable_customer_pricelists_mobileorder &&
              storeInfo.enable_customer_pricelists
            ) {
              this.store
                .select("catalog")
                .pipe(first())
                .subscribe((catalogState) => {
                  if (
                    catalogState &&
                    catalogState.customerPricelist &&
                    catalogState.customerPricelist.length > 0
                  ) {
                    let customerProducts = _.cloneDeep(
                      catalogState.customerPricelist
                    );
                    _.each(customerProducts, (customerProduct) => {
                      if (
                        customerProduct.product_id ===
                        selectedProduct.product_id
                      ) {
                        if (customerProduct.price) {
                          selectedProduct.price = _.cloneDeep(
                            parseFloat(
                              customerProduct.price.toString()
                            ).toFixed(
                              storeInfo && storeInfo.decimalPlacesPrices
                                ? storeInfo.decimalPlacesPrices
                                : 2
                            )
                          );
                        }

                        if (customerProduct.price_takeaway) {
                          selectedProduct.price_takeaway = _.cloneDeep(
                            parseFloat(
                              customerProduct.price_takeaway.toString()
                            ).toFixed(
                              storeInfo && storeInfo.decimalPlacesPrices
                                ? storeInfo.decimalPlacesPrices
                                : 2
                            )
                          );
                        }
                      }
                    });

                    resolve(selectedProduct);
                  } else {
                    resolve(selectedProduct);
                  }
                });
            } else {
              resolve(selectedProduct);
            }
          });
      } else {
        resolve(selectedProduct);
      }
    });
  }
  async checkUpdateStockValues(selectedProduct) {
    return new Promise((resolve) => {
      if (
        selectedProduct &&
        (selectedProduct.availability_stock_enabled ||
          selectedProduct.preorder_availability_stock_enabled)
      ) {
        this.store
          .select(selectors.getStoreInfo)
          .pipe(first())
          .subscribe((storeInfo) => {
            this.store
              .select("deliveryMethodAddress")
              .pipe(first())
              .subscribe((deliveryState) => {
                this.store
                  .select("groupApp")
                  .subscribe((groupAppState) => {
                    if (groupAppState && deliveryState) {
                      this.dataStorageService
                        .fetchProductDetailsUpdatedData(
                          storeInfo.store_id,
                          groupAppState.groupApp.group,
                          selectedProduct.product_id,
                          groupAppState.selectedLangugage,
                          deliveryState.orderPickup,
                          deliveryState.dineIn,
                          deliveryState.selectedPreorderDate
                            ? {
                                preorder:
                                  deliveryState.selectedPreorderDate &&
                                  deliveryState.selectedPreorderDate.date &&
                                  deliveryState.selectedPreorderDate.hour
                                    ? true
                                    : false,
                                preorder_day:
                                  deliveryState.selectedPreorderDate.date,
                                preorder_time:
                                  deliveryState.selectedPreorderDate.hour,
                              }
                            : null
                        )
                        .subscribe({
                          next: async (res: any) => {
                            if (!res || !res.success) {
                              const alert = await this.alertController.create({
                                header: this.translateService.instant("alert"),
                                message:
                                  res && res.comment_id
                                    ? this.translateService.instant(
                                        res.comment_id
                                      )
                                    : this.translateService.instant(
                                        "errorMessages.general_error"
                                      ),
                                backdropDismiss: false,
                                buttons: ["OK"],
                              });

                              await alert.present();
                            } else {
                              if (res && res.product) {
                                selectedProduct = _.assign(
                                  _.cloneDeep(selectedProduct),
                                  res.product
                                );
                              }
                            }

                            resolve(_.cloneDeep(selectedProduct));
                          },
                          error: async (err) => {
                            const alert = await this.alertController.create({
                              header: this.translateService.instant("alert"),
                              message: this.translateService.instant(
                                "errorMessages.problem_reaching_server"
                              ),

                              buttons: ["OK"],
                            });
                            await alert.present();

                            resolve(_.cloneDeep(selectedProduct));
                          },
                        });
                    } else {
                      resolve(_.cloneDeep(selectedProduct));
                    }
                  })
                  .unsubscribe();
              })
              .unsubscribe();
          })
          .unsubscribe();
      } else {
        resolve(_.cloneDeep(selectedProduct));
      }
    });
  }

  calculateSelectedProductPoints(product, totalPrice, doNotWriteToState) {
    return new Promise((resolve, reject) => {
      this.store
        .select(selectors.getStoreInfo)
        .pipe(first())
        .subscribe((storeInfo) => {
          this.store
            .select("groupApp")
            .pipe(first())
            .subscribe((groupState) => {
              const groupApp = _.cloneDeep(groupState.groupApp);

              // console.log(
              //   "calculate points to earn product details",
              //   groupApp,
              //   groupApp.pointsSystem,
              //   !_.isEmpty(groupApp.points),
              //   !_.isEmpty(groupApp.points.rules),
              //   storeInfo,
              //   !storeInfo.overridePromoItemsForPointsSystem,
              //   !product.promo,
              //   !product.gift,
              //   product.category,
              //   !product.category.promo
              // );
              if (
                groupApp &&
                groupApp.pointsSystem &&
                !_.isEmpty(groupApp.points) &&
                !_.isEmpty(groupApp.points.rules) &&
                storeInfo &&
                !storeInfo.overridePromoItemsForPointsSystem &&
                storeInfo.design_settings &&
                storeInfo.design_settings.showPointRulesOnProductItems &&
                !product.promo &&
                !product.gift &&
                product.category &&
                !product.category.promo
              ) {
                const order_rule = _.find(groupApp.points.rules, {
                  type: "order",
                });
                if (order_rule) {
                  if (doNotWriteToState) {
                    product.points_to_earn = this.calculatePointsFromPrice(
                      totalPrice,
                      order_rule
                    );
                  } else {
                    this.store.dispatch(
                      new SelectedProductActions.SetSelectedProductPointsToEarn(
                        {
                          selectedProductId: product.selectedProductId,
                          productPointsToEarn: this.calculatePointsFromPrice(
                            totalPrice,
                            order_rule
                          ),
                        }
                      )
                    );
                  }
                }

                resolve(_.cloneDeep(product));
              } else {
                resolve(_.cloneDeep(product));
              }
            })
            .unsubscribe();
        })
        .unsubscribe();
    });
  }

  calculatePointsFromPrice(price, order_rule) {
    if (
      order_rule &&
      order_rule.points_ratio &&
      order_rule.amount_ratio &&
      price
    ) {
      price = parseFloat(
        parseFloat(_.cloneDeep(price).toString().replace("€", "")).toFixed(2)
      );
      return parseInt(
        parseFloat(
          ((price * order_rule.points_ratio) / order_rule.amount_ratio)
            .toFixed(2)
            .toString()
        )
          .toFixed()
          .toString()
      );
    } else {
      return null;
    }
  }
}
