import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import * as _ from "lodash";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../../store/app.reducer";
import * as selectors from "../../../../store/app.selectors";
import { AlertController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import * as CartActions from "../../../../cart/store/cart.actions";
import { distinctUntilChanged } from "rxjs/operators";
import { Subscription } from "rxjs";

@Component({
  selector: "app-last-step",
  templateUrl: "./last-step.component.html",
  styleUrls: ["./last-step.component.scss"],
})
export class LastStepComponent implements OnInit, OnChanges, OnDestroy {
  @Input() selectedProduct: any;

  public finalPriceLoading: boolean;
  public dineIn: any;
  public selectedDineInTable;
  public orderPickup: any;
  public showComponent;
  public store_info;
  private subscription: Subscription;
  private subscription2: Subscription;
  private subscription3: Subscription;

  constructor(private store: Store<fromApp.AppState>) {}

  ngOnInit() {
    if (this.selectedProduct) {
      this.DetailsCalculations(this.selectedProduct);
    }

    this.subscription3 = this.store
      .select(selectors.getStoreInfo)
      .pipe(distinctUntilChanged())
      .subscribe((store_info) => {
        if (store_info && !_.isEqual(this.store_info, store_info)) {
          this.store_info = _.cloneDeep(store_info);
        }
      });

    this.subscription = this.store
      .select("disableFinalPrice")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          !_.isEqual(this.finalPriceLoading, state.disableFinalPrice)
        ) {
          this.finalPriceLoading = _.cloneDeep(state.disableFinalPrice);
        }
      });

    this.subscription2 = this.store
      .select("deliveryMethodAddress")

      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (state && !_.isEqual(this.dineIn, state.dineIn)) {
          this.dineIn = _.cloneDeep(state.dineIn);
        }
        if (state && !_.isEqual(this.orderPickup, state.orderPickup)) {
          this.orderPickup = _.cloneDeep(state.orderPickup);
        }
        if (
          state &&
          !_.isEqual(this.selectedDineInTable, state.selectedDineInTable)
        ) {
          this.selectedDineInTable = _.cloneDeep(state.selectedDineInTable);
        }
      });
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (
      simpleChanges &&
      simpleChanges.selectedProduct &&
      simpleChanges.selectedProduct.previousValue &&
      simpleChanges.selectedProduct.currentValue !==
        simpleChanges.selectedProduct.previousValue
    ) {
      // this.findOnlyPreselectedChoices();
      this.DetailsCalculations(this.selectedProduct);
    }
  }

  calculatePrice() {
    let price = parseFloat(this.selectedProduct.price);
    let quantity = parseFloat(this.selectedProduct.quantity);
    return (
      (price * quantity).toFixed(
        this.store_info && this.store_info.decimalPlacesPrices
          ? this.store_info.decimalPlacesPrices
          : 2
      ) + "€"
    );
  }

  DetailsCalculations(prd) {
    let showProducts = false;

    _.each(prd.options, (option) => {
      if (
        option &&
        option.option_id &&
        (!option.dependenciesVariable ||
          option.dependenciesVariable.length === 0 ||
          (option.dependenciesVariable.length > 0 && option.showDependent))
      ) {
        if (option.type !== "field") {
          if (
            !(
              option.choices &&
              !option.reverseChoices &&
              this.choicesIsPreselected(option.choices).length === 0
            ) ||
            (option.is_require && option.availableTotal)
          ) {
            if (
              !option.doNotPrint ||
              (option.parent_free && option.option_parent_total_price === 0)
            ) {
              // console.log("retuntrue");
              showProducts = true;
            } else {
              // console.log("retunfalse");
            }
          }
          if (
            option.type === "checkbox" &&
            option.reverseChoices &&
            option.choicesComment
          ) {
            showProducts = true;
            //  console.log("retuntrue");
          }
          _.each(option.choices, (choice) => {
            if (
              !choice.doNotPrint &&
              choice.is_preselected &&
              (option.type !== "checkbox" ||
                !option.reverseChoices ||
                (!option.choicesComment && !option.reverseChoices))
            ) {
              //console.log("retuntrue");
              showProducts = true;
            }
          });
        } else {
          if (option.text) {
            // console.log("retuntrue");
            showProducts = true;
          } else {
            // console.log("retunfalse");
          }
        }
      } else {
        // console.log("retunfalse");
      }
    });
    this.showComponent = showProducts;
    console.log("show component", this.showComponent);
    return showProducts;
  }

  choicesIsPreselected(choices) {
    let preselectedArray = [];
    choices.forEach((choice) => {
      if (choice && choice.is_preselected) {
        preselectedArray.push(choice);
      }
    });

    return preselectedArray;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscription2) {
      this.subscription2.unsubscribe();
    }
    if (this.subscription3) {
      this.subscription3.unsubscribe();
    }
  }
}
