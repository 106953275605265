import { Pipe, PipeTransform } from "@angular/core";
import * as _ from "lodash";

@Pipe({
  name: "filterOnlyDineInDeliveryPickupProducts",
})
export class FilterOnlyDineInDeliveryPickupProductsPipe
  implements PipeTransform
{
  transform(productsArray: any, orderPickup: any, dineIn: any): any {
    const filteredArray = _.cloneDeep(productsArray);

    return _.filter(filteredArray, (product) => {
      if (product.hideOtherDeliveryMethods) {
        if (orderPickup == true && dineIn == false) {
          //take away selected

          if (product.pickup || (!product.delivery && !product.dinein)) {
            return product;
          }
        } else if (orderPickup == false && dineIn == false) {
          //delivery selected

          if (product.delivery || (!product.pickup && !product.dinein)) {
            return product;
          }
        } else if (orderPickup == false && dineIn == true) {
          //dine in selected

          if (product.dinein || (!product.pickup && !product.delivery)) {
            return product;
          }
        } else {
          return product;
        }
      } else {
        return product;
      }
    });
  }
}
