<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">
      <!-- <ion-text style="font-size: medium">
        {{ table.category_id !== "none" ? table.category_name[selectedLanguage] + " " + table.name[selectedLanguage] : table.name[selectedLanguage]}}</ion-text
      > -->
      <ion-text style="font-size: medium; position: relative; top: 2px">
        {{ "reservations.information" | translate }}
      <p class="ion-no-padding ion-no-margin">
        <ion-badge>{{ table.name[selectedLanguage] }}</ion-badge>
      </p>
      </ion-text>
    </ion-title>
    <ion-button
      (click)="closeModal()"
      size="small"
      slot="end"
      fill="clear"
      color="dark"
    >
      <ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <p *ngIf="table && table.prepay_amount" style="font-size: 20px" class="ion-text-wrap ion-text-center">
    {{ "reservations.min_consumption" | translate }} {{ table.prepay_amount }}€
  </p>
  <p style="font-size: 17px" class="ion-text-wrap ion-text-center" *ngIf="table.description">
    {{ table.description[selectedLanguage] }}
  </p>

  <ion-list style="padding-top: 2px; padding-bottom: 2px" *ngFor="let day of days">
    <ion-item-divider [class]="openDays[day] ? '' : 'closed'" button (click)="onDividerClick(day)" mode="ios" style="cursor: pointer">
      {{ day }} <ion-icon slot="end" [name]="openDays[day] ? 'chevron-down-outline' : 'chevron-up-outline'"></ion-icon>
    </ion-item-divider>
    <div *ngIf="openDays[day]">
      <div *ngIf="schedule[day] && schedule[day].length">
        <ion-row [class]="slot.taken ? 'taken' : ''" *ngFor="let slot of schedule[day]">
          <ion-col size="6" class="ion-text-center ion-align-self-center">
            <ion-text *ngIf="slot.start === '00:00' && slot.end === '00:00'">
              {{ "reservations.available_all_day" | translate }}
            </ion-text>
            <ion-text *ngIf="slot.start !== '00:00' || slot.end !== '00:00'">
              {{ slot.start }} - {{ slot.end }}
            </ion-text>
          </ion-col>
          <ion-col size="2" class="ion-text-center ion-align-self-center">
            <ion-text *ngIf="slot.prepay_amount">{{ slot.prepay_amount }}€</ion-text>
          </ion-col>
          <ion-col size="4" class="ion-text-end ion-align-self-center">
            <ion-button fill="clear" (click)="onSelectSlot(slot)" [disabled]="slot.taken">{{ "reservations.reserve" | translate }}</ion-button>
          </ion-col>
        </ion-row>
      </div>
      <ion-row style="margin-top: 8px; margin-bottom: 8px" *ngIf="!schedule[day] || !schedule[day].length">
        <ion-col class="ion-text-center ion-align-self-center">
          <ion-text style="font-size: 1.1rem; font-weight: 600;">{{ "reservations.not_available" | translate }}</ion-text>
        </ion-col>
      </ion-row>
    </div>
  </ion-list>
  <ion-row *ngIf="!days || !days.length">
    <ion-col class="ion-text-center">
      <p>{{ "reservations.no_slots_for_table" | translate }}</p>
    </ion-col>
  </ion-row>

</ion-content>