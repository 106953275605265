import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { AnimationOptions } from "ngx-lottie";
import { Subscription } from "rxjs";

@Component({
  selector: "app-coupon-info-popup",
  templateUrl: "./coupon-info-popup.component.html",
  styleUrls: ["./coupon-info-popup.component.scss"],
})
export class CouponInfoPopupComponent implements OnInit, OnDestroy {
  @Input() title: any;
  @Input() submessage: any;
  @Input() message: any;
  @Input() login_lottie: boolean;
  @Input() coupon_lottie: boolean;
  @Input() ok_button: any;
  @Input() cancel_button: any;
  @Input() closeModalButton: boolean;
  private unsubscribeBackEvent: Subscription;
  loginAnimationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/login-get-coupon.json",
  };
  couponAnimationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/get-coupon.json",
  };

  constructor(
    private modalController: ModalController,
    private platform: Platform
  ) {}

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );
  }

  ngOnDestroy(): void {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
  }

  animationCreated(ev) {}

  cancelButtonClicked() {
    this.modalController.dismiss("cancel");
  }

  okButtonClicked() {
    this.modalController.dismiss("ok");
  }

  closeModal() {
    this.modalController.dismiss();
  }
}
