import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import {
  auditTime,
  debounceTime,
  distinctUntilChanged,
  first,
  Subscription,
} from "rxjs";
import * as fromApp from "../../../store/app.reducer";

@Component({
  selector: "app-complete-order-button",
  templateUrl: "./complete-order-button.component.html",
  styleUrls: ["./complete-order-button.component.scss"],
})
export class CompleteOrderButtonComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() scrollFromBottom: any;
  @Input() clickOnCategory: any;
  @Input() scrollFromTop: any;
  public showButton;
  public firstTime: any = true;
  public cartProducts: any;
  public selectedCategoryId: any;
  public cartPrices: any;
  public windowDimensions: any;
  public disableFinalPrice = false;

  private stopScrollButtonChange: boolean;
  private showButtonChangedNow: any = false;
  private cartPricesSub: Subscription;
  private cartSubscription: Subscription;
  private windowInfoSub: Subscription;
  private categoriesSub: Subscription;
  constructor(private router: Router, private store: Store<fromApp.AppState>) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.scrollFromTop &&
      changes.scrollFromTop.currentValue !== changes.scrollFromTop.previousValue
    ) {
      if (this.scrollFromTop && this.scrollFromTop > 50) {
        // console.log("scroll from top changed");
        if (this.stopScrollButtonChange) {
          this.stopScrollButtonChange = false;
        } else {
          this.showButton = false;
          this.showButtonChangedNow = true;
          setTimeout(() => {
            this.showButtonChangedNow = false;
          }, 2000);
        }
      } else {
        //console.log("complete order button else", this.scrollFromBottom);

        if (this.stopScrollButtonChange) {
          this.stopScrollButtonChange = false;
        } else {
        //  console.log("cartProductsss", this.cartProducts);

          this.showButton = true;
        }
      }
    }
  }

  ngOnInit() {
    this.categoriesSub = this.store
      .select("verticalCategory")
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (
          state &&
          !_.isEqual(this.selectedCategoryId, state.selectedCategoryId)
        ) {
          this.selectedCategoryId = _.cloneDeep(state.selectedCategoryId);
         // console.log("selected cat changed");
          if (
            this.selectedCategoryId &&
            this.cartProducts &&
            this.cartProducts.length > 0
          ) {
            this.showButton = true;
            this.stopScrollButtonChange = true;
          }
        }
      });

    this.cartSubscription = this.store
      .select("cart")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (state && !_.isEqual(this.cartProducts, state.products)) {
          if (!this.cartProducts) {
            setTimeout(() => {
              this.firstTime = false;
            }, 2000);
          }
          this.cartProducts = _.cloneDeep(state.products);
          if (
            this.cartProducts &&
            this.cartProducts.length &&
            this.cartProducts.length > 0
          ) {
            if (this.scrollFromTop && this.scrollFromBottom > 50) {
              if (!this.showButtonChangedNow) {
                this.showButton = true;
              }
            } else {
              //this.showButton = false;
            }
          }
        }
      });

    this.windowInfoSub = this.store
      .select("windowInfo")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.windowDimensions &&
          !_.isEqual(state.windowDimensions, this.windowDimensions)
        ) {
          this.windowDimensions = _.cloneDeep(state.windowDimensions);
        }
      });

    this.cartPricesSub = this.store
      .select("cartPrices")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.cartPrices &&
          !_.isEqual(state.cartPrices, this.cartPrices)
        ) {
          this.cartPrices = _.cloneDeep(state.cartPrices);
        }
      });

    this.cartPricesSub = this.store
      .select("disableFinalPrice")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          !_.isEqual(state.disableFinalPrice, this.disableFinalPrice)
        ) {
          this.disableFinalPrice = _.cloneDeep(state.disableFinalPrice);
        }
      });
  }

  navigateCart() {
    this.router.navigateByUrl("/cart", {
      replaceUrl: true,
    });
  }

  ngOnDestroy(): void {
    if (this.cartPricesSub) {
      this.cartPricesSub.unsubscribe();
    }
    if (this.windowInfoSub) {
      this.windowInfoSub.unsubscribe();
    }

    if (this.cartSubscription) {
      this.cartSubscription.unsubscribe();
    }
    if (this.categoriesSub) {
      this.categoriesSub.unsubscribe();
    }
  }
}
