<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small"
      >{{
        isCustomer
          ? ("popups.scan-login-modal.connect-with-one-click" | translate)
          : ("popups.scan-login-modal.register-with-one-click" | translate)
      }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()" fill="clear" slot="end">
        <ion-icon name="close" slot="icon-only"> </ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list *ngIf="scan_user">
    <ion-item lines="none">
      <ion-label
        style="font-size: medium; margin: 0"
        class="ion-text-wrap ion-text-center"
        ><strong
          >{{ "popups.scan-login-modal.verified-mobile" | translate }}:</strong
        >
        {{ scan_user.mobile }}</ion-label
      >
    </ion-item>
  </ion-list>

  <ion-grid class="ion-text-center ion-no-padding" style="max-width: 200px">
    <ng-lottie
      (animationCreated)="animationCreated($event)"
      [options]="loginAnimationOptions"
    ></ng-lottie>
  </ion-grid>

  <ion-item lines="none" *ngIf="scan_title">
    <ion-label
      class="ion-text-wrap ion-text-center"
      style="font-weight: bold; font-size: small; margin: 0"
    >
      {{ scan_title }}
    </ion-label>
  </ion-item>
</ion-content>

<ion-footer>
  <ion-grid style="width: 100%" *ngIf="scan_user">
    <ion-row style="width: 100%">
      <ion-col size="12" class="ion-text-center">
        <ion-button color="primary" (click)="onClickOk()">
          {{
            !isCustomer
              ? ("popups.scan-login-modal.register" | translate)
              : ("popups.scan-login-modal.login" | translate)
          }}</ion-button
        >
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
