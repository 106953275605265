<ion-header>
  <ion-toolbar mode="md" *ngIf="order">
    <ion-buttons slot="end">
      <ion-button color="dark" fill="clear" (click)="closeModal()">
        <ion-icon slot="icon-only" src="assets/ionicons/close.svg"> </ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title size="small" *ngIf="order && order.order_id && store_info">
      <ion-text>#{{ order.order_id }} </ion-text>
      <ion-text
        *ngIf="store_info.shortOrderIdInitMinutes && order.short_order_id"
      >
        (#{{ order.short_order_id }})
      </ion-text>

      {{ order.timestamp | date : "dd/MM/yyyy HH:mm" }}</ion-title
    >
    <div style="padding-inline-start: 22px">
      <ion-badge *ngIf="order && order.status" [color]="order.status">
        <ion-text *ngIf="order.status === 'pendingPayment'">{{
          "orders.pendingPayment" | translate
        }}</ion-text>
        <ion-text *ngIf="order.status === 'processingPayment'"
          >{{ "orders.processingPayment" | translate }}
        </ion-text>
        <ion-text *ngIf="order.status === 'pending'">{{
          "orders.pending" | translate
        }}</ion-text>
        <span *ngIf="order.status === 'complete' && groupApp">
          <ion-text
            *ngIf="
              !groupApp.design_settings ||
              !groupApp.design_settings.completeOrderLabelCustomText ||
              (groupApp.design_settings &&
                groupApp.design_settings.completeOrderLabelCustomText &&
                !groupApp.design_settings.completeOrderLabelCustomText[
                  selectedLanguage
                ])
            "
          >
            {{ "orders.order-item.complete" | translate }}
          </ion-text>

          <ion-text
            *ngIf="
              groupApp &&
              groupApp.design_settings &&
              groupApp.design_settings.completeOrderLabelCustomText &&
              selectedLanguage &&
              groupApp.design_settings.completeOrderLabelCustomText[
                selectedLanguage
              ]
            "
          >
            {{
              groupApp.design_settings.completeOrderLabelCustomText[
                selectedLanguage
              ]
            }}
          </ion-text>
        </span>
        <ion-text *ngIf="order.status === 'canceled'">{{
          "orders.canceled" | translate
        }}</ion-text>
        <ion-text *ngIf="order.status === 'processing'">{{
          "orders.processing" | translate
        }}</ion-text>
      </ion-badge>
      <ion-badge style="margin-left: 5px" *ngIf="order && order.status">
        <ion-text *ngIf="order.status === 'complete' && order.preorder">{{
          "orders.order-item.preorder" | translate
        }}</ion-text>
      </ion-badge>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid>
    <ion-row style="width: 100%" *ngIf="!order">
      <ion-col class="ion-text-center">
        <ion-spinner></ion-spinner>
      </ion-col>
    </ion-row>
    <app-order-details
      (openTimeLineModalEvent)="showOrderTimelineModal($event)"
      *ngIf="order && order.order_id"
      [order]="order"
    ></app-order-details>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-grid>
    <ion-row style="width: 100%">
      <ion-col class="ion-text-center">
        <ion-button [disabled]="!order" (click)="reorderOrder()">
          {{ "orders.order-item.order-again" | translate }}
          <ion-icon
            slot="end"
            src="assets/flatIcons/reorderFromOrder.svg"
          ></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
