import { Action } from "@ngrx/store";

export const SET_DISABLE_FINAL_PRICE =
  "[DISABLE FINAL PRICE] Set Disable Final Price";
export const SET_TRIGGER_FINAL_PRICE_TIMESTAMP =
  "[DISABLE FINAL PRICE] Set  Trigger Final Price Timestamp";

export class SetDisableFinalPrice implements Action {
  readonly type = SET_DISABLE_FINAL_PRICE;
  constructor(public payload: boolean) {}
}

export class SetTriggerFinalPriceTimestamp implements Action {
  readonly type = SET_TRIGGER_FINAL_PRICE_TIMESTAMP;
  constructor(public payload: any) {}
}

export type DisableFinalPriceActions =
  | SetDisableFinalPrice
  | SetTriggerFinalPriceTimestamp;
