<ion-row
  *ngIf="
    !currentUser ||
    (userPoints && userPoints.records && userPoints.records.length === 0)
  "
>
  <ion-col class="ion-text-center ion-padding">
    <ion-grid style="width: 120px; height: 120px; padding: 0">
      <ng-lottie
        (animationCreated)="animationCreated($event)"
        [options]="historyAnimationOptions"
      ></ng-lottie>
    </ion-grid>
    <ion-text>
      <h5>
        {{ "reservation-app.points-history.no-points-history" | translate }}
      </h5>
    </ion-text>
  </ion-col>
</ion-row>

<ion-row *ngIf="currentUser && !userPoints">
  <ion-col class="ion-text-center ion-padding">
    <ion-spinner></ion-spinner>
  </ion-col>
</ion-row>

<ion-list *ngIf="currentUser && userPoints && groupApp">
  <span *ngIf="groupApp.pointsSystem && userPoints.records">
    <ion-item *ngFor="let record of filteredPointRecords">
      <ion-label>
        {{ record.points }}
        {{ "reservation-app.points-history.points" | translate }} -
        {{ record.timestamp | date : "d/M/yyyy HH:mm" }}
        <p *ngIf="record.type === 'order'">
          {{
            ("reservation-app.points-history.order" | translate) +
              " #" +
              record.order_id
          }}
        </p>
        <p *ngIf="record.used_points">
          {{
            ("reservation-app.points-history.used" | translate) +
              " " +
              record.used_points +
              " " +
              ("reservation-app.points-history.points" | translate)
          }}
        </p>
        <p *ngIf="record.status === 'canceled' && record.points > 0">
          <ion-text>
            {{
              record.points +
                " " +
                ("reservation-app.points-history.points" | translate) +
                " " +
                ("reservation-app.points-history.canceled" | translate)
            }}
            <!-- {{ record.expiration ? record.expiration : "" }} -->
          </ion-text>
        </p>
        <p *ngIf="record.status === 'expired' && record.points > 0">
          <ion-text color="danger">
            {{
              record.points +
                " " +
                ("reservation-app.points-history.points" | translate) +
                " " +
                ("reservation-app.points-history.expired" | translate)
            }}
            {{ record.expiration ? record.expiration : "" }}
          </ion-text>
        </p>
        <p *ngIf="record.status === 'pending' && record.points > 0">
          <ion-text>
            {{
              record.points +
                " " +
                ("reservation-app.points-history.points" | translate) +
                " " +
                ("reservation-app.points-history.pending" | translate)
            }}
          </ion-text>
        </p>
        <p *ngIf="record.type === 'order' && record.points > 0">
          <ion-badge>Online Ραντεβού</ion-badge>
        </p>
        <p *ngIf="record.type === 'instore_order' && record.points > 0">
          <ion-badge>Ραντεβού</ion-badge>
        </p>
        <p *ngIf="record.type === 'login_register' && record.points > 0">
          <ion-badge>Εγγραφή</ion-badge>
        </p>
      </ion-label>
      <ion-badge color="danger" *ngIf="record.expiration && record.showExpDate">
        {{ "reservation-app.points-history.expiration" | translate }}:
        {{ record.expiration | date : "dd/MM/yyyy" }}
      </ion-badge>
    </ion-item>
  </span>
</ion-list>
