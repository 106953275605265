<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">
      {{ "popups.select-store-popup.choose-store-title" | translate }}
    </ion-title>
    <ion-button
      (click)="closeModal()"
      size="small"
      slot="end"
      fill="clear"
      color="dark"
      ><ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon
    ></ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-row
    class="ion-padding"
    *ngIf="!paymentGatewayStoresByID || !storesByStoreID"
  >
    <ion-col class="ion-text-center">
      <ion-spinner> </ion-spinner>
    </ion-col>
  </ion-row>

  <ion-list
    *ngIf="
      paymentGatewayStoresByID &&
      paymentGatewayStoresByID.length &&
      storesByStoreID &&
      selectedLanguage
    "
  >
    <ion-item
      *ngFor="let store_id of paymentGatewayStoresByID"
      button
      detail="false"
      (click)="selectStore(store_id)"
    >
      <ion-label
        class="ion-text-wrap"
        *ngIf="storesByStoreID && storesByStoreID[store_id]"
      >
        {{
          storesByStoreID[store_id].store_name_mobileorder &&
          storesByStoreID[store_id].store_name_mobileorder[selectedLanguage]
            ? storesByStoreID[store_id].store_name_mobileorder[selectedLanguage]
            : storesByStoreID[store_id].store_panel_name_langs &&
              storesByStoreID[store_id].store_panel_name_langs[selectedLanguage]
            ? storesByStoreID[store_id].store_panel_name_langs[selectedLanguage]
            : storesByStoreID[store_id].store_panel_name_langs &&
              storesByStoreID[store_id].store_panel_name_langs.el
            ? storesByStoreID[store_id].store_panel_name_langs.el
            : storesByStoreID[store_id].store_name_panel
            ? storesByStoreID[store_id].store_name_panel
            : storesByStoreID[store_id].store_name
            ? storesByStoreID[store_id].store_name
            : ""
        }}
        <p>{{ storesByStoreID[store_id].address }}</p></ion-label
      >
      <ion-button fill="clear" slot="end">
        <ion-icon
          src="assets/ionicons/chevron-forward-outline.svg"
          slot="icon-only"
        >
        </ion-icon>
      </ion-button>
    </ion-item>
  </ion-list>
</ion-content>
