<ion-item>
  <ion-button
    [disabled]="finalPriceLoading"
    class="ion-no-padding ion-no-margin"
    style="position: absolute; right: 0; top: 0; cursor: pointer; padding: 1px"
    (click)="presentAlertConfirm(cartProduct)"
    fill="clear"
  >
    <ion-icon
      slot="icon-only"
      color="danger"
      src="assets/ionicons/close.svg"
    ></ion-icon>
  </ion-button>

  <ion-row
    style="width: 100%"
    class="ion-justify-content-center ion-align-items-center"
  >
    <ion-col
      size="12"
      style="width: 100%"
      (click)="showProductDetailsModal(cartProduct)"
    >
      <strong style="cursor: pointer; font-size: small; padding-right: 3px">{{
        cartProduct.name
      }}</strong>
    </ion-col>

    <ion-col size="12">
      <ion-text
        style="font-weight: 900; cursor: pointer; font-size: small"
        color="primary"
        *ngIf="
          !showProductItemDetails &&
          productHaveDetails(cartProduct) &&
          groupApp &&
          !groupApp.cartChoicesAlwaysOpen
        "
        (click)="showProductItemDetails = !showProductItemDetails"
      >
        {{
          "cart.cart-components.cart-list.cart-list-product-item.show-more-details"
            | translate
        }}
      </ion-text>
      <span
        *ngIf="
          groupApp && (showProductItemDetails || groupApp.cartChoicesAlwaysOpen)
        "
      >
        <div>
          <span *ngFor="let option of cartProduct.options">
            <div
              *ngIf="
                option.option_id &&
                (!option.dependenciesVariable ||
                  option.dependenciesVariable.length === 0 ||
                  (option.dependenciesVariable.length > 0 &&
                    option.showDependent))
              "
              class="secondaryFontSize item-text-wrap"
            >
              <p
                style="font-size: smaller; margin: 0px"
                *ngIf="
                  option.type !== 'field' &&
                  (!(
                    option.choices &&
                    !option.reverseChoices &&
                    (option.choices | choicesPreselected).length === 0
                  ) ||
                    (option.is_require && option.availableTotal))
                "
              >
                <strong>
                  {{
                    option.doNotPrint
                      ? ""
                      : option.title
                      ? option.title + ": "
                      : ""
                  }}
                  {{
                    option.parent_free && option.option_parent_total_price === 0
                      ? "(" +
                        ("cart.cart-components.cart-list.cart-list-product-item.free"
                          | translate) +
                        ")"
                      : option.option_parent_total_price
                      ? option.parent_free && cartProduct.parent_discount
                        ? option.option_parent_total_price +
                          "€ (-" +
                          cartProduct.parent_discount +
                          "%)"
                        : option.option_parent_total_price + "€"
                      : ""
                  }}
                </strong>
                <span
                  *ngIf="
                    option.type === 'checkbox' &&
                    option.reverseChoices &&
                    option.choicesComment
                  "
                  style="display: inline-block"
                  class="mainFontSize"
                  >{{ option.choicesComment }}
                </span>
                <span
                  *ngFor="
                    let choice of option.choices | choicesPreselected;
                    let last = last
                  "
                >
                  <span
                    style="display: inline-block"
                    *ngIf="
                      !choice.bundle &&
                      !choice.doNotPrint &&
                      (option.type !== 'checkbox' ||
                        !option.reverseChoices ||
                        (!option.choicesComment && !option.reverseChoices))
                    "
                  >
                    {{ choice.printLabel ? choice.printLabel : choice.title
                    }}<strong *ngIf="!choice.choicePriceComment">{{
                      choice.isFree
                        ? ("cart.cart-components.cart-list.cart-list-product-item.free-choice"
                          | translate)
                        : !choice.price
                        ? ""
                        : " " +
                          (orderPickup == true && dineIn == false
                            ? choice.price_takeaway || choice.price
                            : orderPickup == false && dineIn == true
                            ? store_info.dinein &&
                              store_info.alt_dinein_active &&
                              selectedDineInTable &&
                              selectedDineInTable.alt_dinein &&
                              choice.price_alt_dinein
                              ? choice.price_alt_dinein
                              : choice.price_dinein || choice.price
                            : choice.price) +
                          "€"
                    }}</strong
                    ><span *ngIf="choice.choicePriceComment">{{
                      choice.choicePriceComment
                    }}</span
                    >{{ last ? "" : "," }}&nbsp;
                  </span>

                  <app-cart-list-product-choice-comment
                    [bundleProduct]="choice.bundled_product"
                    [bundle_options]="choice.bundle_options"
                    [orderPickup]="orderPickup"
                    [store_info]="store_info"
                    [selectedDineInTable]="selectedDineInTable"
                    [dineIn]="dineIn"
                    *ngIf="
                      choice.bundle &&
                      choice.bundled_with_product_id &&
                      choice.bundled_product &&
                      !choice.doNotPrint &&
                      (option.type !== 'checkbox' ||
                        !option.reverseChoices ||
                        (!option.choicesComment && !option.reverseChoices))
                    "
                  >
                  </app-cart-list-product-choice-comment>
                </span>
                <!-- 
                <span
                  class="cartWarningColor"
                  ng-if="
                    option.choices &&
                    (option.choices | filter: { is_preselected: true }).length ===
                      0 &&
                    option.is_require
                  "
                >
                    {{
                      "cart.cart-components.cart-list.cart-list-product-item.you-must-make-a-choice"
                        | translate
                    }}
                </span> -->
              </p>

              <p
                style="margin: 0 auto 0 0; font-size: smaller"
                *ngIf="option.type === 'field' && option.text"
              >
                <strong>{{ option.title }}:</strong> {{ option.text }}
              </p>
            </div>
          </span>
        </div>
      </span>
    </ion-col>
    <ion-col
      size="6"
      class="ion-no-padding ion-no-margin"
      *ngIf="cartProduct.disable_qty"
    >
    </ion-col>

    <ion-col
      size="6"
      *ngIf="!cartProduct.disable_qty"
      class="ion-no-padding ion-no-margin"
    >
      <app-change-quantity
        *ngIf="cartProduct"
        [buttonsSize]="'small'"
        [disablePlusButton]="
          finalPriceLoading ||
          cartProduct.gift ||
          cartProduct.quantityOnlyFromChoices
        "
        [disableRemoveButton]="
          finalPriceLoading ||
          cartProduct.gift ||
          cartProduct.quantityOnlyFromChoices
        "
        [quantity]="cartProduct.quantity"
        [productQtyWithStep]="
          cartProduct.enable_quantity_step && cartProduct.quantity_step
        "
        [productStepKind]="cartProduct.quantity_step_kind"
        [fromCartCatalogItem]="true"
        (changingQuantity)="changeProductQuantity($event, cartProduct)"
      ></app-change-quantity>
    </ion-col>
    <ion-col
      size="6"
      class="ion-no-padding ion-no-margin"
      class="ion-align-self-center ion-text-right"
    >
      <span
        style="padding: 2px"
        [ngClass]="{
          'line-through': !!(
            cartProduct.specialPrice !== 0 && cartProduct.specialPrice
          ),
          fontSizeSmaller: !!(
            cartProduct.specialPrice !== 0 && cartProduct.specialPrice
          )
        }"
        *ngIf="!cartProduct.gift && cartProduct.productTotal"
        color="dark"
      >
        {{ cartProduct.productTotal }}€
      </span>
      <ion-text *ngIf="cartProduct.gift">
        {{
          "cart.cart-components.cart-list.cart-list-product-item.free"
            | translate
        }}
      </ion-text>
      <ion-text
        class="cartWarningLightColor"
        *ngIf="
          !cartProduct.gift &&
          cartProduct.specialPrice &&
          cartProduct.specialPrice !== 0 &&
          cartProduct.specialPriceTotal
        "
      >
        {{ cartProduct.specialPriceTotal }}€
      </ion-text>
    </ion-col>
  </ion-row>
</ion-item>
