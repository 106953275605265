import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import * as _ from "lodash";
import * as fromApp from "../../store/app.reducer";
import * as selectors from "../../store/app.selectors";
import { Store } from "@ngrx/store";
import {
  auditTime,
  debounceTime,
  distinctUntilChanged,
  first,
} from "rxjs/operators";
import { Subscription } from "rxjs";
import { AlertController, ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import * as PointsActions from "../../points/store/points.actions";
import { Router } from "@angular/router";
import { AnimationOptions } from "ngx-lottie";
import { SelectStorePopupComponent } from "src/app/popups/select-store-popup/select-store-popup.component";
import { KioskGetPromoModalComponent } from "src/app/kiosk/kiosk-get-promo-modal/kiosk-get-promo-modal.component";
import { SubmitUserDataModalComponent } from "src/app/popups/submit-user-data-modal/submit-user-data-modal.component";

@Component({
  selector: "app-rewards",
  templateUrl: "./rewards.component.html",
  styleUrls: ["./rewards.component.scss"],
})
export class RewardsComponent implements OnInit, OnDestroy {
  public currentUser: any;
  public groupApp: any;
  @Input() userPoints: any;
  @Output() successUsePointsEvent = new EventEmitter();
  public rewards: any;
  public currentStoreId: any;
  public mo_token: any;
  public loginState: string;
  public usePointsLoading: boolean = false;
  public storeInfo: any;
  public stores: any;
  public filterType = "mobileorder";
  public kioskRewardsFound;
  public mobileorderRewardsFound;
  public thereIsOnlyKioskOffers = false;
  public windowDimensions;
  public colSize = "6";
  public canGetAtLeastOneReward = false;
  public selectedTheme;
  public selectedLogoFileName;
  public rootDir;
  public flattenedIconBackgroundFlag;

  private selectedLangugage: any;
  private getPromoModal: any;
  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store<fromApp.AppState>,
    private alertController: AlertController,
    private translate: TranslateService,
    private dataStorageService: DataStorageService,
    private router: Router,
    private modalController: ModalController,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select("currentStore")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.currentStoreId &&
            !_.isEqual(state.currentStoreId, this.currentStoreId)
          ) {
            this.currentStoreId = _.cloneDeep(state.currentStoreId);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())
        .pipe(debounceTime(300))
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(state.windowDimensions, this.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
            this.calcualteColSizes();
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(400))
        .subscribe((state) => {
          if (state && !_.isEqual(state.mo_token, this.mo_token)) {
            this.mo_token = _.cloneDeep(state.mo_token);
          }
          if (state && !_.isEqual(state.user, this.currentUser)) {
            this.currentUser = _.cloneDeep(state.user);
          }
          if (state && !_.isEqual(state.loginState, this.loginState)) {
            this.loginState = _.cloneDeep(state.loginState);
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(state.groupApp, this.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);

            if (
              this.groupApp &&
              this.groupApp.points &&
              this.groupApp.points.rewards
            ) {
              this.store
                .select(selectors.getStoreInfo)
                .pipe(first())
                .subscribe((storeInfo) => {
                  if (
                    storeInfo &&
                    storeInfo.dm_kiosk_app_active &&
                    !(
                      this.groupApp.design_settings &&
                      this.groupApp.design_settings
                        .dm_kiosk_app_disable_mobileorder
                    )
                  ) {
                    this.kioskRewardsFound = _.find(
                      this.groupApp.points.rewards,
                      {
                        kiosk: true,
                      }
                    );

                    this.mobileorderRewardsFound = _.find(
                      this.groupApp.points.rewards,
                      (coupon) => {
                        if (!coupon.only_kiosk) {
                          return true;
                        }
                      }
                    );
                  }
                })
                .unsubscribe();
              // this.groupApp.points.rewards = _.cloneDeep(
              //   _.filter(this.groupApp.points.rewards, (reward) => {
              //     if (reward.active) {
              //       return reward;
              //     }
              //   })
              // );
            }

            if (!_.isEmpty(this.groupApp.logosImages)) {
              this.selectedLogoFileName = _.find(this.groupApp.logosImages, {
                selected: true,
              }).fileName;
            }

            this.flattenedIconBackgroundFlag = this.groupApp.flattenedIcon
              ? true
              : false;

            console.log("group app points", this.groupApp.points);
          }

          if (
            state &&
            state.selectedTheme &&
            !_.isEqual(state.selectedTheme, this.selectedTheme)
          ) {
            this.selectedTheme = _.cloneDeep(state.selectedTheme);
          }

          if (
            state &&
            state.rootDir &&
            !_.isEqual(state.rootDir, this.rootDir)
          ) {
            this.rootDir = _.cloneDeep(state.rootDir);
          }

          if (
            state &&
            state.selectedLangugage &&
            !_.isEqual(state.selectedLangugage, this.selectedLangugage)
          ) {
            this.selectedLangugage = _.cloneDeep(state.selectedLangugage);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && state.stores && !_.isEqual(state.stores, this.stores)) {
            this.stores = _.cloneDeep(state.stores);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .subscribe((storeInfo) => {
          if (storeInfo && !_.isEqual(storeInfo, this.storeInfo)) {
            this.storeInfo = _.cloneDeep(storeInfo);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("points")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((state) => {
          if (state && !_.isEqual(state.rewards, this.rewards)) {
            let rewards = _.cloneDeep(state.rewards);
            if (rewards && rewards.length) {
              this.rewards = _.cloneDeep(
                _.filter(rewards, (reward) => {
                  if (reward.active) {
                    return reward;
                  }
                })
              );

              this.store
                .select("groupApp")
                .pipe(first())
                .subscribe((groupState) => {
                  this.store
                    .select(selectors.getStoreInfo)
                    .pipe(first())
                    .subscribe((storeInfo) => {
                      if (
                        storeInfo &&
                        storeInfo.dm_kiosk_app_active &&
                        !(
                          groupState &&
                          groupState.groupApp &&
                          groupState.groupApp.design_settings &&
                          groupState.groupApp.design_settings
                            .dm_kiosk_app_disable_mobileorder
                        )
                      ) {
                        this.kioskRewardsFound = _.find(this.rewards, {
                          kiosk: true,
                        });
                        this.thereIsOnlyKioskOffers = false;
                        _.each(this.rewards, (reward) => {
                          if (reward && reward.only_kiosk) {
                            this.thereIsOnlyKioskOffers = true;
                          }
                        });

                        this.mobileorderRewardsFound = _.find(
                          this.rewards,
                          (coupon) => {
                            if (!coupon.only_kiosk) {
                              return true;
                            }
                          }
                        );
                      }
                    })
                    .unsubscribe();
                })
                .unsubscribe();
            }
            console.log("rewards", this.rewards);
            _.each(this.rewards, (reward) => {
              if (reward && reward.user_points_percent) {
                if (reward.user_points_percent < 50) {
                  document.documentElement.style.setProperty(
                    "--pointsProgressBarColor",
                    "var(--ion-color-black)"
                  );
                  document.documentElement.style.setProperty(
                    "--pointsProgressBarColorLight",
                    "var(--pointsProgressBarColorLightBlack)"
                  );
                } else if (
                  reward.user_points_percent >= 50 &&
                  reward.user_points_percent <= 99
                ) {
                  document.documentElement.style.setProperty(
                    "--pointsProgressBarColor",
                    "var(--ion-color-warning)"
                  );
                  document.documentElement.style.setProperty(
                    "--pointsProgressBarColorLight",
                    "var(--pointsProgressBarColorLightWarning)"
                  );
                } else if (reward.user_points_percent === 100) {
                  this.canGetAtLeastOneReward = true;

                  document.documentElement.style.setProperty(
                    "--pointsProgressBarColor",
                    "var(--ion-color-success)"
                  );
                  document.documentElement.style.setProperty(
                    "--pointsProgressBarColorLight",
                    "var(--pointsProgressBarColorLightSuccess)"
                  );
                }
              }
            });

            //console.log("rewards", this.groupApp.points);
          }
        })
    );
  }

  trackRewards(index) {
    return index;
  }

  calcualteColSizes() {
    if (this.windowDimensions && this.windowDimensions.width) {
      if (this.windowDimensions.width < 350) {
        this.colSize = "12";
      } else if (
        this.windowDimensions.width >= 350 &&
        this.windowDimensions.width < 570
      ) {
        this.colSize = "6";
      } else if (this.windowDimensions.width >= 570) {
        this.colSize = "4";
      }
    }
  }

  async openGetPromoModal(promo) {
    if (
      this.groupApp &&
      this.groupApp.allUserDataRequiredCoupons &&
      this.currentUser &&
      (!this.currentUser.email ||
        !this.currentUser.firstName ||
        !this.currentUser.lastName)
    ) {
      let userDataEntryCompleted = false;
      //ask user for data and show GDPR

      const modal = await this.modalController.create({
        component: SubmitUserDataModalComponent,
        cssClass: "submit-user-data-modal",
        backdropDismiss: false,
        componentProps: {},
      });

      await modal.present();
      await modal.onDidDismiss().then((data) => {
        if (data && data.data && data.data === "success") {
          userDataEntryCompleted = true;
        }
      });

      if (!userDataEntryCompleted) {
        return false;
      }
    }

    this.getPromoModal = await this.modalController.create({
      component: KioskGetPromoModalComponent,
      cssClass: "kioskGetPromoModal",
      backdropDismiss: false,
      componentProps: {
        promo: _.cloneDeep(promo),
        type: "reward",
        groupApp: this.groupApp,
        stores: this.stores,
        showLoginRegisterButton: false,
      },
    });

    await this.getPromoModal.present();

    await this.getPromoModal.onDidDismiss().then(async (data) => {
      if (this.getPromoModal) {
        this.getPromoModal = null;
      }

      if (data && data.data === "coupon_get_success") {
        if (
          this.storeInfo &&
          this.storeInfo.dm_kiosk_app_active &&
          !(
            this.groupApp &&
            this.groupApp.design_settings &&
            this.groupApp.design_settings.dm_kiosk_app_disable_mobileorder
          ) &&
          (promo.only_kiosk || promo.kiosk)
        ) {
          if (promo.only_kiosk) {
            this.router.navigateByUrl("/kiosk?openFirstCoupon=true", {
              replaceUrl: true,
            });
          } else if (promo.kiosk) {
            const confirmationAlert = await this.alertController.create({
              header: this.translate.instant("information"),
              message: this.translate.instant(
                "kiosk-page.do-you-want-to-use-the-coupon-to-kiosk"
              ),

              backdropDismiss: false,
              buttons: [
                {
                  text: this.translate.instant("cancel"),
                  role: "cancel",
                  cssClass: "secondary",
                  handler: (blah) => {
                    console.log("cancel");
                  },
                },
                {
                  text: this.translate.instant("yes"),
                  handler: () => {
                    this.router.navigateByUrl("/kiosk?openFirstCoupon=true", {
                      replaceUrl: true,
                    });
                  },
                },
              ],
            });

            await confirmationAlert.present();
          }
        }
      }
    });
  }

  openInBrowser(reward) {
    window.open(reward.third_party_url, "_system", "location=yes");
  }

  async openSelectStoreModal(storesForSelection) {
    return new Promise(async (resolve, reject) => {
      let cssClass = "selectStorePopupCss";
      if (this.stores && this.stores.length > 1 && this.stores.length <= 3) {
        cssClass = "selectStorePopupCssThreeStores";
      } else if (this.stores && this.stores.length > 3) {
        cssClass = "selectStorePopupCssMultipleStores";
      }
      const modal = await this.modalController.create({
        component: SelectStorePopupComponent,
        componentProps: {
          storesForSelection: storesForSelection,
          onlyReturnSelectedStoreId: true,
        },
        cssClass: cssClass,
        backdropDismiss: false,
      });

      await modal.present();

      await modal.onDidDismiss().then((data) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          resolve(null);
        }
      });
    });
  }

  fetchCustomerPoints() {
    this.dataStorageService
      .fetchCustomerPoint(
        this.mo_token,
        this.groupApp &&
          this.groupApp.points &&
          this.groupApp.points.loyaltyCardLevelsActive
      )
      .subscribe({
        next: async (res: any) => {
          if (res && res.success) {
            let userPoints = res.data;
            this.store.dispatch(
              new PointsActions.SetUserPoints(_.cloneDeep(userPoints))
            );
            let tempGroupApp = _.cloneDeep(this.groupApp);
            this.canGetAtLeastOneReward = false;
            _.each(tempGroupApp.points.rewards, (reward) => {
              if (reward.points <= userPoints.pointsSystem.active) {
                reward.user_points_percent = 100;
                this.canGetAtLeastOneReward = true;
              } else {
                var percent =
                  (userPoints.pointsSystem.active / reward.points) * 100;
                reward.user_points_percent = percent.toFixed();
              }

              console.log("reward", reward.user_points_percent);
            });
            this.store.dispatch(
              new PointsActions.SetRewards(
                _.cloneDeep(tempGroupApp.points.rewards)
              )
            );
            let highestReward = _.maxBy(
              tempGroupApp.points.rewards,
              "user_points_percent"
            );
            this.store.dispatch(
              new PointsActions.SetHighestReward(_.cloneDeep(highestReward))
            );
          } else if (!res || !res.success) {
            const alert = await this.alertController.create({
              header: this.translate.instant("alert"),
              message:
                res && res.comment_id
                  ? this.translate.instant(res.comment_id)
                  : this.translate.instant("errorMessages.general_error"),
              backdropDismiss: false,
              buttons: ["OK"],
            });
            await alert.present();
          }
        },
        error: async (error) => {
          const alert = await this.alertController.create({
            header: this.translate.instant("alert"),
            message: this.translate.instant(
              "errorMessages.problem_reaching_server"
            ),

            buttons: ["OK"],
          });
          await alert.present();
        },
      });
  }

  ngOnDestroy() {
    if (this.subscriptions && !_.isEmpty(this.subscriptions)) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }
}
