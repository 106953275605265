<ion-grid *ngIf="product && category" style="width: 165px; height: 225px">
  <ion-card
    [class]="
      productIsInCart && recommendedModal
        ? 'product-in-cart ion-no-margin'
        : 'ion-no-margin'
    "
    button
    detail="false"
    style="width: 100%; height: 100%"
    (click)="showProductDetailsModal(product)"
    *ngIf="product.activeTotal && category.activeTotal"
  >
    <div
      style="
        position: absolute;
        top: 0px;
        left: 3px;
        z-index: 19;
        padding-top: 3px;
      "
    >
      <ion-badge
        class="badge-margin"
        color="danger"
        *ngIf="
          product &&
          (!product.availableTotal ||
            !product.product_categories_availability_check)
        "
        ><ion-text>{{
          "item-tags.unavailable" | translate
        }}</ion-text></ion-badge
      >

      <ion-badge
        [color]="
          groupStoreData &&
          groupStoreData.store_info &&
          groupStoreData.store_info.design_settings &&
          groupStoreData.store_info.design_settings.tagsColors &&
          groupStoreData.store_info.design_settings.tagsColors.new
            ? groupStoreData.store_info.design_settings.tagsColors.new
            : ''
        "
        class="badge-margin"
        *ngIf="product && product.tags && product.tags.new"
        ><ion-text>{{ "item-tags.new" | translate }}</ion-text></ion-badge
      >

      <ion-badge
        [color]="
          groupStoreData &&
          groupStoreData.store_info &&
          groupStoreData.store_info.design_settings &&
          groupStoreData.store_info.design_settings.tagsColors &&
          groupStoreData.store_info.design_settings.tagsColors.veg
            ? groupStoreData.store_info.design_settings.tagsColors.veg
            : ''
        "
        class="badge-margin"
        *ngIf="product && product.tags && product.tags.veg"
        ><ion-text>{{ "item-tags.veg" | translate }}</ion-text></ion-badge
      >

      <ion-badge
        [color]="
          groupStoreData &&
          groupStoreData.store_info &&
          groupStoreData.store_info.design_settings &&
          groupStoreData.store_info.design_settings.tagsColors &&
          groupStoreData.store_info.design_settings.tagsColors.fasting
            ? groupStoreData.store_info.design_settings.tagsColors.fasting
            : ''
        "
        class="badge-margin"
        *ngIf="product && product.tags && product.tags.fasting"
        ><ion-text>{{ "item-tags.fasting" | translate }}</ion-text></ion-badge
      >

      <ion-badge
        [color]="
          groupStoreData &&
          groupStoreData.store_info &&
          groupStoreData.store_info.design_settings &&
          groupStoreData.store_info.design_settings.tagsColors &&
          groupStoreData.store_info.design_settings.tagsColors.gloutenfree
            ? groupStoreData.store_info.design_settings.tagsColors.gloutenfree
            : ''
        "
        class="badge-margin"
        *ngIf="product && product.tags && product.tags.gloutenfree"
        ><ion-text>{{
          "item-tags.gloutenfree" | translate
        }}</ion-text></ion-badge
      >

      <ion-badge
        [color]="
          groupStoreData &&
          groupStoreData.store_info &&
          groupStoreData.store_info.design_settings &&
          groupStoreData.store_info.design_settings.tagsColors &&
          groupStoreData.store_info.design_settings.tagsColors.spicy
            ? groupStoreData.store_info.design_settings.tagsColors.spicy
            : ''
        "
        class="badge-margin"
        *ngIf="product && product.tags && product.tags.spicy"
        ><ion-text>{{ "item-tags.spicy" | translate }}</ion-text></ion-badge
      >

      <ion-badge
        [color]="
          groupStoreData &&
          groupStoreData.store_info &&
          groupStoreData.store_info.design_settings &&
          groupStoreData.store_info.design_settings.tagsColors &&
          groupStoreData.store_info.design_settings.tagsColors.lactosefree
            ? groupStoreData.store_info.design_settings.tagsColors.lactosefree
            : ''
        "
        class="badge-margin"
        *ngIf="product && product.tags && product.tags.lactosefree"
        ><ion-text>{{
          "item-tags.lactosefree" | translate
        }}</ion-text></ion-badge
      >
      <ion-badge
        [color]="
          groupStoreData &&
          groupStoreData.store_info &&
          groupStoreData.store_info.design_settings &&
          groupStoreData.store_info.design_settings.tagsColors &&
          groupStoreData.store_info.design_settings.tagsColors.frozen
            ? groupStoreData.store_info.design_settings.tagsColors.frozen
            : ''
        "
        class="badge-margin"
        *ngIf="product && product.tags && product.tags.frozen"
        ><ion-text>{{ "item-tags.frozen" | translate }}</ion-text></ion-badge
      >
      <ion-badge
        [color]="
          groupStoreData &&
          groupStoreData.store_info &&
          groupStoreData.store_info.design_settings &&
          groupStoreData.store_info.design_settings.tagsColors &&
          groupStoreData.store_info.design_settings.tagsColors.vegan
            ? groupStoreData.store_info.design_settings.tagsColors.vegan
            : ''
        "
        class="badge-margin"
        *ngIf="product && product.tags && product.tags.vegan"
        ><ion-text>{{ "item-tags.vegan" | translate }}</ion-text></ion-badge
      >
      <ion-badge
        [color]="
          groupStoreData &&
          groupStoreData.store_info &&
          groupStoreData.store_info.design_settings &&
          groupStoreData.store_info.design_settings.tagsColors &&
          groupStoreData.store_info.design_settings.tagsColors.deal
            ? groupStoreData.store_info.design_settings.tagsColors.deal
            : ''
        "
        class="badge-margin"
        *ngIf="product && product.tags && product.tags.deal"
        ><ion-text>{{ "item-tags.deal" | translate }}</ion-text></ion-badge
      >
      <ion-badge
        [color]="
          groupStoreData &&
          groupStoreData.store_info &&
          groupStoreData.store_info.design_settings &&
          groupStoreData.store_info.design_settings.tagsColors &&
          groupStoreData.store_info.design_settings.tagsColors.popular
            ? groupStoreData.store_info.design_settings.tagsColors.popular
            : ''
        "
        class="badge-margin"
        *ngIf="product && product.tags && product.tags.popular"
        ><ion-text>{{ "item-tags.popular" | translate }}</ion-text></ion-badge
      >

      <ion-badge
        [color]="
          groupStoreData &&
          groupStoreData.store_info &&
          groupStoreData.store_info.design_settings &&
          groupStoreData.store_info.design_settings.tagsColors &&
          groupStoreData.store_info.design_settings.tagsColors.custom_tag_1
            ? groupStoreData.store_info.design_settings.tagsColors.custom_tag_1
            : ''
        "
        class="badge-margin"
        *ngIf="
          groupStoreData &&
          groupStoreData.store_info &&
          groupStoreData.store_info.catalogCustomTagsById &&
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_1 &&
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_1.active &&
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_1
            .translations &&
          groupStoreData.groupApp &&
          groupStoreData.groupApp.mo_base &&
          groupStoreData.groupApp.mo_base.code &&
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_1
            .translations[groupStoreData.groupApp.mo_base.code] &&
          product &&
          product.tags &&
          product.tags.custom_tag_1
        "
      >
        <ion-text>{{
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_1
            .translations[groupStoreData.groupApp.selectedLanguage] ||
            groupStoreData.store_info.catalogCustomTagsById.custom_tag_1
              .translations[groupStoreData.groupApp.mo_base.code]
        }}</ion-text>
      </ion-badge>
      <ion-badge
        [color]="
          groupStoreData &&
          groupStoreData.store_info &&
          groupStoreData.store_info.design_settings &&
          groupStoreData.store_info.design_settings.tagsColors &&
          groupStoreData.store_info.design_settings.tagsColors.custom_tag_2
            ? groupStoreData.store_info.design_settings.tagsColors.custom_tag_2
            : ''
        "
        class="badge-margin"
        *ngIf="
          groupStoreData &&
          groupStoreData.store_info &&
          groupStoreData.store_info.catalogCustomTagsById &&
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_2 &&
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_2.active &&
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_2
            .translations &&
          groupStoreData.groupApp &&
          groupStoreData.groupApp.mo_base &&
          groupStoreData.groupApp.mo_base.code &&
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_2
            .translations[groupStoreData.groupApp.mo_base.code] &&
          product &&
          product.tags &&
          product.tags.custom_tag_2
        "
      >
        <ion-text>{{
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_2
            .translations[groupStoreData.groupApp.selectedLanguage] ||
            groupStoreData.store_info.catalogCustomTagsById.custom_tag_2
              .translations[groupStoreData.groupApp.mo_base.code]
        }}</ion-text>
      </ion-badge>
      <ion-badge
        [color]="
          groupStoreData &&
          groupStoreData.store_info &&
          groupStoreData.store_info.design_settings &&
          groupStoreData.store_info.design_settings.tagsColors &&
          groupStoreData.store_info.design_settings.tagsColors.custom_tag_3
            ? groupStoreData.store_info.design_settings.tagsColors.custom_tag_3
            : ''
        "
        class="badge-margin"
        *ngIf="
          groupStoreData &&
          groupStoreData.store_info.catalogCustomTagsById &&
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_3 &&
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_3.active &&
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_3
            .translations &&
          groupStoreData.groupApp &&
          groupStoreData.groupApp.mo_base &&
          groupStoreData.groupApp.mo_base.code &&
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_3
            .translations[groupStoreData.groupApp.mo_base.code] &&
          product &&
          product.tags &&
          product.tags.custom_tag_3
        "
      >
        <ion-text>{{
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_3
            .translations[groupStoreData.groupApp.selectedLanguage] ||
            groupStoreData.store_info.catalogCustomTagsById.custom_tag_3
              .translations[groupStoreData.groupApp.mo_base.code]
        }}</ion-text>
      </ion-badge>
      <ion-badge
        [color]="
          groupStoreData &&
          groupStoreData.store_info &&
          groupStoreData.store_info.design_settings &&
          groupStoreData.store_info.design_settings.tagsColors &&
          groupStoreData.store_info.design_settings.tagsColors.custom_tag_4
            ? groupStoreData.store_info.design_settings.tagsColors.custom_tag_4
            : ''
        "
        class="badge-margin"
        *ngIf="
          groupStoreData &&
          groupStoreData.store_info &&
          groupStoreData.store_info.catalogCustomTagsById &&
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_4 &&
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_4.active &&
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_4
            .translations &&
          groupStoreData.groupApp &&
          groupStoreData.groupApp.mo_base &&
          groupStoreData.groupApp.mo_base.code &&
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_4
            .translations[groupStoreData.groupApp.mo_base.code] &&
          product &&
          product.tags &&
          product.tags.custom_tag_4
        "
      >
        <ion-text>{{
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_4
            .translations[groupStoreData.groupApp.selectedLanguage] ||
            groupStoreData.store_info.catalogCustomTagsById.custom_tag_4
              .translations[groupStoreData.groupApp.mo_base.code]
        }}</ion-text>
      </ion-badge>
      <ion-badge
        [color]="
          groupStoreData &&
          groupStoreData.store_info &&
          groupStoreData.store_info.design_settings &&
          groupStoreData.store_info.design_settings.tagsColors &&
          groupStoreData.store_info.design_settings.tagsColors.custom_tag_5
            ? groupStoreData.store_info.design_settings.tagsColors.custom_tag_5
            : ''
        "
        class="badge-margin"
        *ngIf="
          groupStoreData &&
          groupStoreData.store_info &&
          groupStoreData.store_info.catalogCustomTagsById &&
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_5 &&
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_5.active &&
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_5
            .translations &&
          groupStoreData.groupApp &&
          groupStoreData.groupApp.mo_base &&
          groupStoreData.groupApp.mo_base.code &&
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_5
            .translations[groupStoreData.groupApp.mo_base.code] &&
          product &&
          product.tags &&
          product.tags.custom_tag_5
        "
      >
        <ion-text>{{
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_5
            .translations[groupStoreData.groupApp.selectedLanguage] ||
            groupStoreData.store_info.catalogCustomTagsById.custom_tag_5
              .translations[groupStoreData.groupApp.mo_base.code]
        }}</ion-text>
      </ion-badge>
      <ion-badge
        [color]="
          groupStoreData &&
          groupStoreData.store_info &&
          groupStoreData.store_info.design_settings &&
          groupStoreData.store_info.design_settings.tagsColors &&
          groupStoreData.store_info.design_settings.tagsColors.custom_tag_6
            ? groupStoreData.store_info.design_settings.tagsColors.custom_tag_6
            : ''
        "
        class="badge-margin"
        *ngIf="
          groupStoreData &&
          groupStoreData.store_info &&
          groupStoreData.store_info.catalogCustomTagsById &&
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_6 &&
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_6.active &&
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_6
            .translations &&
          groupStoreData.groupApp &&
          groupStoreData.groupApp.mo_base &&
          groupStoreData.groupApp.mo_base.code &&
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_6
            .translations[groupStoreData.groupApp.mo_base.code] &&
          product &&
          product.tags &&
          product.tags.custom_tag_6
        "
      >
        <ion-text>{{
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_6
            .translations[groupStoreData.groupApp.selectedLanguage] ||
            groupStoreData.store_info.catalogCustomTagsById.custom_tag_6
              .translations[groupStoreData.groupApp.mo_base.code]
        }}</ion-text>
      </ion-badge>
      <ion-badge
        [color]="
          groupStoreData &&
          groupStoreData.store_info &&
          groupStoreData.store_info.design_settings &&
          groupStoreData.store_info.design_settings.tagsColors &&
          groupStoreData.store_info.design_settings.tagsColors.custom_tag_7
            ? groupStoreData.store_info.design_settings.tagsColors.custom_tag_7
            : ''
        "
        class="badge-margin"
        *ngIf="
          groupStoreData &&
          groupStoreData.store_info &&
          groupStoreData.store_info.catalogCustomTagsById &&
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_7 &&
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_7.active &&
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_7
            .translations &&
          groupStoreData.groupApp &&
          groupStoreData.groupApp.mo_base &&
          groupStoreData.groupApp.mo_base.code &&
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_7
            .translations[groupStoreData.groupApp.mo_base.code] &&
          product &&
          product.tags &&
          product.tags.custom_tag_7
        "
      >
        <ion-text>{{
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_7
            .translations[groupStoreData.groupApp.selectedLanguage] ||
            groupStoreData.store_info.catalogCustomTagsById.custom_tag_7
              .translations[groupStoreData.groupApp.mo_base.code]
        }}</ion-text>
      </ion-badge>
      <ion-badge
        [color]="
          groupStoreData &&
          groupStoreData.store_info &&
          groupStoreData.store_info.design_settings &&
          groupStoreData.store_info.design_settings.tagsColors &&
          groupStoreData.store_info.design_settings.tagsColors.custom_tag_8
            ? groupStoreData.store_info.design_settings.tagsColors.custom_tag_8
            : ''
        "
        class="badge-margin"
        *ngIf="
          groupStoreData &&
          groupStoreData.store_info &&
          groupStoreData.store_info.catalogCustomTagsById &&
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_8 &&
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_8.active &&
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_8
            .translations &&
          groupStoreData.groupApp &&
          groupStoreData.groupApp.mo_base &&
          groupStoreData.groupApp.mo_base.code &&
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_8
            .translations[groupStoreData.groupApp.mo_base.code] &&
          product &&
          product.tags &&
          product.tags.custom_tag_8
        "
      >
        <ion-text>{{
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_8
            .translations[groupStoreData.groupApp.selectedLanguage] ||
            groupStoreData.store_info.catalogCustomTagsById.custom_tag_8
              .translations[groupStoreData.groupApp.mo_base.code]
        }}</ion-text>
      </ion-badge>
      <ion-badge
        [color]="
          groupStoreData &&
          groupStoreData.store_info &&
          groupStoreData.store_info.design_settings &&
          groupStoreData.store_info.design_settings.tagsColors &&
          groupStoreData.store_info.design_settings.tagsColors.custom_tag_9
            ? groupStoreData.store_info.design_settings.tagsColors.custom_tag_9
            : ''
        "
        class="badge-margin"
        *ngIf="
          groupStoreData &&
          groupStoreData.store_info &&
          groupStoreData.store_info.catalogCustomTagsById &&
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_9 &&
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_9.active &&
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_9
            .translations &&
          groupStoreData.groupApp &&
          groupStoreData.groupApp.mo_base &&
          groupStoreData.groupApp.mo_base.code &&
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_9
            .translations[groupStoreData.groupApp.mo_base.code] &&
          product &&
          product.tags &&
          product.tags.custom_tag_9
        "
      >
        <ion-text>{{
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_9
            .translations[groupStoreData.groupApp.selectedLanguage] ||
            groupStoreData.store_info.catalogCustomTagsById.custom_tag_9
              .translations[groupStoreData.groupApp.mo_base.code]
        }}</ion-text>
      </ion-badge>

      <ion-badge
        [color]="
          groupStoreData &&
          groupStoreData.store_info &&
          groupStoreData.store_info.design_settings &&
          groupStoreData.store_info.design_settings.tagsColors &&
          groupStoreData.store_info.design_settings.tagsColors.custom_tag_10
            ? groupStoreData.store_info.design_settings.tagsColors.custom_tag_10
            : ''
        "
        class="badge-margin"
        *ngIf="
          groupStoreData &&
          groupStoreData.store_info &&
          groupStoreData.store_info.catalogCustomTagsById &&
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_10 &&
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_10
            .active &&
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_10
            .translations &&
          groupStoreData.groupApp &&
          groupStoreData.groupApp.mo_base &&
          groupStoreData.groupApp.mo_base.code &&
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_10
            .translations[groupStoreData.groupApp.mo_base.code] &&
          product &&
          product.tags &&
          product.tags.custom_tag_10
        "
      >
        <ion-text>{{
          groupStoreData.store_info.catalogCustomTagsById.custom_tag_10
            .translations[groupStoreData.groupApp.selectedLanguage] ||
            groupStoreData.store_info.catalogCustomTagsById.custom_tag_10
              .translations[groupStoreData.groupApp.mo_base.code]
        }}</ion-text>
      </ion-badge>

      <ion-badge
        class="badge-margin"
        *ngIf="
          product &&
          !product.availableTotal &&
          product.tags &&
          product.tags.availableForPreorder &&
          !product.hideAvailableForPreorderTag
        "
      >
        <ion-text *ngIf="product.availableTotal">
          {{ "item-tags.availableForPreorder" | translate }}</ion-text
        >
        <ion-text *ngIf="!product.availableTotal">
          {{ "item-tags.onlyavailableForPreorder" | translate }}</ion-text
        >
      </ion-badge>

      <!-- <ion-badge class="badge-margin" *ngIf="product && product.promo"
      ><ion-text>{{ "item-tags.promo" | translate }}</ion-text></ion-badge
    > -->

      <ion-badge class="badge-margin" *ngIf="product && product.avpc">
        <ion-text>{{ product.avpc }}</ion-text>
      </ion-badge>

      <ion-badge
        class="badge-margin"
        *ngIf="
          product &&
          product.availableTotal &&
          product.tags &&
          product.tags.initializationActivePreorder &&
          !preorderDateIsAfterToday
        "
      >
        <ion-text>
          {{ "item-tags.onlyavailableForPreorder" | translate }}</ion-text
        >
      </ion-badge>

      <ion-badge
        class="badge-margin"
        *ngIf="
          product &&
          product.availableTotal &&
          category &&
          product.product_categories_availability_check &&
          product.availability_stock_enabled &&
          product.availability_stock &&
          (!product.tags || !product.tags.initializationActivePreorder) &&
          (!selectedPreorderDate ||
            !selectedPreorderDate.hour ||
            !selectedPreorderDate.date ||
            !preorderDateIsAfterToday ||
            !product.preorder_availability_stock_enabled)
        "
      >
        <ion-text
          >{{ "item-tags.available-stock" | translate }}:
          {{ product.availability_stock }}</ion-text
        >
      </ion-badge>

      <ion-badge
        class="badge-margin"
        *ngIf="
          product &&
          product.availableTotal &&
          category &&
          product.product_categories_availability_check &&
          product.preorder_availability_stock_enabled &&
          product.preorder_availability_stock &&
          preorderDateIsAfterToday
        "
      >
        <ion-text
          >{{ "item-tags.available-stock" | translate }}:
          {{ product.preorder_availability_stock }}</ion-text
        >
      </ion-badge>

      <app-product-item-order-type-tags
        [productItem]="product"
        [orderPickup]="orderPickup"
        [dineIn]="dineIn"
      >
      </app-product-item-order-type-tags>
    </div>

    <img
      *ngIf="
        product &&
        product.image &&
        product.image.true &&
        product.image.true['4x3'] &&
        product.image.true['4x3'].path
      "
      [alt]="product.name"
      loading="lazy"
      style="z-index: 0px"
      class="ImageDemensions"
      [src]="
        'https://images.deliverymanager.gr/' +
        product.image.true['4x3'].path +
        (webp ? '.webp' : product.image.true['4x3'].type || '.jpg')
      "
    />

    <div
      class="background-image"
      *ngIf="
        recommendedModal &&
        groupStoreData &&
        groupStoreData.groupApp &&
        (!product.image ||
          !product.image.true ||
          !product.image.true['4x3'] ||
          !product.image.true['4x3'].path)
      "
    >
      <img
        loading="lazy"
        style="z-index: 0"
        class="ImageDemensions"
        src="assets/flatIcons/no-image.png"
      />

      <div class="logo-container">
        <img
          src="https://images.deliverymanager.gr/{{
            groupStoreData.groupApp.group
          }}/appicon/{{ groupStoreData.groupApp.iconImage }}"
          class="logo-img"
        />
      </div>
    </div>

    <ion-item
      lines="none"
      [class]="
        product.highlighted
          ? 'highlighted-product ion-no-padding ion-no-margin'
          : 'ion-no-padding ion-no-margin'
      "
    >
      <ion-label class="ion-no-padding ion-no-margin">
        <div
          #productTitle
          [id]="'popular_' + product.product_id"
          class="ion-text-wrap wrapInTwoLines"
        >
          <ion-text
            style="font-weight: bold; font-size: small"
            [color]="product.highlighted ? 'selected' : ''"
          >
            {{ product.name }}
          </ion-text>
        </div>
        <p
          class="ion-text-wrap wrapInTwoLines"
          *ngIf="productTitleRef"
          #productDescription
        >
          <ion-text style="width: 100px" style="font-size: small">
            {{ product.description }}
          </ion-text>
        </p>
      </ion-label>
    </ion-item>

    <div
      class="ion-text-right positionBottomRight"
      *ngIf="
        (orderPickup == false && dineIn == false) ||
        (orderPickup == null && dineIn == null)
      "
      [ngClass]="{
        priceWidthUnavailable: !!product.priceWidthUnavailable,
        priceWidthAvailable: !!product.priceWidthAvailable
      }"
      style="font-size: small"
    >
      <span class="line-through" *ngIf="product.initial_price">
        {{ product.initial_price
        }}{{
          product.enable_quantity_step &&
          product.quantity_step &&
          product.quantity_step_kind
            ? "/" + product.quantity_step_kind
            : ""
        }}
      </span>
      <span>
        <ion-text *ngIf="product.price" color="primary" style="font-weight: 700"
          >{{ product.price
          }}{{
            product.enable_quantity_step &&
            product.quantity_step &&
            product.quantity_step_kind
              ? "/" + product.quantity_step_kind
              : ""
          }}</ion-text
        >

        <ion-text
          *ngIf="!product.price && product.virtual_price"
          color="primary"
          style="font-weight: 700"
          >{{ product.virtual_price
          }}{{
            product.enable_quantity_step &&
            product.quantity_step &&
            product.quantity_step_kind
              ? "/" + product.quantity_step_kind
              : ""
          }}</ion-text
        >
      </span>
    </div>

    <div
      class="ion-text-right positionBottomRight"
      *ngIf="
        orderPickup == false &&
        dineIn == true &&
        groupStoreData &&
        groupStoreData.store_info &&
        (!groupStoreData.store_info.alt_dinein_active ||
          !selectedDineInTable ||
          !selectedDineInTable.alt_dinein)
      "
      [ngClass]="{
        priceWidthUnavailable: !!product.priceWidthUnavailable,
        priceWidthAvailable: !!product.priceWidthAvailable
      }"
      style="font-size: small"
    >
      <span
        *ngIf="product.initial_price && !product.price_dinein"
        class="line-through"
      >
        {{ product.initial_price
        }}{{
          product.enable_quantity_step &&
          product.quantity_step &&
          product.quantity_step_kind
            ? "/" + product.quantity_step_kind
            : ""
        }}
      </span>

      <span
        *ngIf="product.initial_price_dinein && product.price_dinein"
        class="line-through"
      >
        {{ product.initial_price_dinein
        }}{{
          product.enable_quantity_step &&
          product.quantity_step &&
          product.quantity_step_kind
            ? "/" + product.quantity_step_kind
            : ""
        }}
      </span>

      <ion-text
        color="primary"
        *ngIf="product.price_dinein"
        style="font-weight: 700"
        >{{ product.price_dinein
        }}{{
          product.enable_quantity_step &&
          product.quantity_step &&
          product.quantity_step_kind
            ? "/" + product.quantity_step_kind
            : ""
        }}</ion-text
      >

      <ion-text
        *ngIf="!product.price_dinein && product.virtual_price_dinein"
        color="primary"
        style="font-weight: 700"
        >{{ product.virtual_price_dinein
        }}{{
          product.enable_quantity_step &&
          product.quantity_step &&
          product.quantity_step_kind
            ? "/" + product.quantity_step_kind
            : ""
        }}</ion-text
      >

      <ion-text
        *ngIf="!product.price_dinein && !product.virtual_price_dinein"
        color="primary"
        style="font-weight: 700"
        >{{ product.price ? product.price : product.virtual_price
        }}{{
          product.enable_quantity_step &&
          product.quantity_step &&
          product.quantity_step_kind
            ? "/" + product.quantity_step_kind
            : ""
        }}</ion-text
      >
    </div>

    <div
      class="ion-text-right positionBottomRight"
      *ngIf="
        orderPickup == false &&
        dineIn == true &&
        groupStoreData &&
        groupStoreData.store_info &&
        groupStoreData.store_info.alt_dinein_active &&
        selectedDineInTable &&
        selectedDineInTable.alt_dinein
      "
      [ngClass]="{
        priceWidthUnavailable: !!product.priceWidthUnavailable,
        priceWidthAvailable: !!product.priceWidthAvailable
      }"
      style="font-size: small"
    >
      <span
        *ngIf="
          product.initial_price &&
          !product.price_alt_dinein &&
          !product.price_dinein
        "
        class="line-through"
      >
        {{ product.initial_price
        }}{{
          product.enable_quantity_step &&
          product.quantity_step &&
          product.quantity_step_kind
            ? "/" + product.quantity_step_kind
            : ""
        }}
      </span>

      <span
        *ngIf="product.initial_price_alt_dinein && product.price_alt_dinein"
        class="line-through"
      >
        {{ product.initial_price_alt_dinein
        }}{{
          product.enable_quantity_step &&
          product.quantity_step &&
          product.quantity_step_kind
            ? "/" + product.quantity_step_kind
            : ""
        }}
      </span>

      <span
        *ngIf="
          !product.price_alt_dinein &&
          product.initial_price_dinein &&
          product.price_dinein
        "
        class="line-through"
      >
        {{ product.initial_price_dinein
        }}{{
          product.enable_quantity_step &&
          product.quantity_step &&
          product.quantity_step_kind
            ? "/" + product.quantity_step_kind
            : ""
        }}
      </span>

      <ion-text
        *ngIf="product.price_alt_dinein"
        color="primary"
        style="font-weight: 700"
        >{{ product.price_alt_dinein
        }}{{
          product.enable_quantity_step &&
          product.quantity_step &&
          product.quantity_step_kind
            ? "/" + product.quantity_step_kind
            : ""
        }}</ion-text
      >

      <ion-text
        *ngIf="!product.price_alt_dinein && product.virtual_price_alt_dinein"
        color="primary"
        style="font-weight: 700"
        >{{ product.virtual_price_alt_dinein
        }}{{
          product.enable_quantity_step &&
          product.quantity_step &&
          product.quantity_step_kind
            ? "/" + product.quantity_step_kind
            : ""
        }}</ion-text
      >

      <ion-text
        *ngIf="
          !product.price_alt_dinein &&
          product.price_dinein &&
          !product.virtual_price_alt_dinein
        "
        color="primary"
        style="font-weight: 700"
        >{{ product.price_dinein
        }}{{
          product.enable_quantity_step &&
          product.quantity_step &&
          product.quantity_step_kind
            ? "/" + product.quantity_step_kind
            : ""
        }}</ion-text
      >

      <ion-text
        *ngIf="
          !product.price_alt_dinein &&
          !product.price_dinein &&
          !product.virtual_price_alt_dinein &&
          product.virtual_price_dinein
        "
        color="primary"
        style="font-weight: 700"
        >{{ product.virtual_price_dinein
        }}{{
          product.enable_quantity_step &&
          product.quantity_step &&
          product.quantity_step_kind
            ? "/" + product.quantity_step_kind
            : ""
        }}</ion-text
      >

      <ion-text
        *ngIf="
          !product.price_alt_dinein &&
          !product.virtual_price_alt_dinein &&
          !product.price_dinein &&
          !product.virtual_price_dinein
        "
        color="primary"
        style="font-weight: 700"
        >{{ product.price ? product.price : product.virtual_price
        }}{{
          product.enable_quantity_step &&
          product.quantity_step &&
          product.quantity_step_kind
            ? "/" + product.quantity_step_kind
            : ""
        }}</ion-text
      >
    </div>

    <div
      class="ion-text-right positionBottomRight"
      *ngIf="orderPickup == true && dineIn == false"
      [ngClass]="{
        priceWidthUnavailable: !!product.priceWidthUnavailable,
        priceWidthAvailable: !!product.priceWidthAvailable
      }"
      style="font-size: small"
    >
      <span
        *ngIf="product.initial_price && !product.price_takeaway"
        class="line-through"
      >
        {{ product.initial_price
        }}{{
          product.enable_quantity_step &&
          product.quantity_step &&
          product.quantity_step_kind
            ? "/" + product.quantity_step_kind
            : ""
        }}
      </span>

      <span
        *ngIf="product.initial_price_takeaway && product.price_takeaway"
        class="line-through"
      >
        {{ product.initial_price_takeaway
        }}{{
          product.enable_quantity_step &&
          product.quantity_step &&
          product.quantity_step_kind
            ? "/" + product.quantity_step_kind
            : ""
        }}
      </span>

      <ion-text
        *ngIf="product.price_takeaway"
        color="primary"
        style="font-weight: 700"
        >{{ product.price_takeaway
        }}{{
          product.enable_quantity_step &&
          product.quantity_step &&
          product.quantity_step_kind
            ? "/" + product.quantity_step_kind
            : ""
        }}</ion-text
      >

      <ion-text
        *ngIf="!product.price_takeaway && product.virtual_price_takeaway"
        color="primary"
        style="font-weight: 700"
        >{{ product.virtual_price_takeaway
        }}{{
          product.enable_quantity_step &&
          product.quantity_step &&
          product.quantity_step_kind
            ? "/" + product.quantity_step_kind
            : ""
        }}</ion-text
      >

      <ion-text
        *ngIf="!product.price_takeaway && !product.virtual_price_takeaway"
        color="primary"
        style="font-weight: 700"
        >{{ product.price ? product.price : product.virtual_price
        }}{{
          product.enable_quantity_step &&
          product.quantity_step &&
          product.quantity_step_kind
            ? "/" + product.quantity_step_kind
            : ""
        }}</ion-text
      >
    </div>
  </ion-card>
</ion-grid>
