<ion-header>
  <app-no-internet-connection></app-no-internet-connection>
  <app-ionic-update-error></app-ionic-update-error>
  <ion-toolbar>
    <ion-title mode="ios">{{ "pages.discount-coupons" | translate }}</ion-title>

    <ion-buttons slot="start">
      <ion-button color="primary" (click)="navigateBack()">
        <ion-icon name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-button
      *ngIf="currentUser&&
        windowDimensions &&
        windowDimensions.width &&
        windowDimensions.width >=350
      "
      title="{{ 'refresh' | translate }}"
      (click)="getUserCoupons()"
      fill="clear"
      slot="end"
    >
      <ion-icon
        slot="icon-only"
        src="assets/ionicons/refresh-circle-outline.svg"
      >
      </ion-icon>
    </ion-button>
    <ion-button
      [disabled]="couponCodeLoading"
      (click)="addNewCouponCode()"
      slot="end"
      fill="clear"
      *ngIf="
        couponFieldActiveInStore &&
        currentUser &&
        windowDimensions &&
        windowDimensions.width &&
        windowDimensions.width <= 600
      "
    >
      <ion-icon
        *ngIf="!couponCodeLoading"
        slot="icon-only"
        src="assets/ionicons/add-outline.svg"
      ></ion-icon>
      <ion-spinner *ngIf="couponCodeLoading"></ion-spinner>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="windowInfo">
  <ion-grid>
    <ion-row
      *ngIf="(currentUser && !allCoupons) || (!currentUser && !loginLoading)"
      class="ion-text-center"
    >
      <ion-col size="12" class="ion-padding">
        <ion-spinner></ion-spinner>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="currentUser && allCoupons && allCoupons.length === 0">
      <ion-col size="12" class="ion-text-center">
        <ion-icon
          style="font-size: 80px"
          src="assets/flatIcons/no-coupon.svg"
        ></ion-icon>
        <ion-text>
          <h5>
            {{ "reservation-app.discount-coupons.no-coupons" | translate }}
          </h5>
        </ion-text>
      </ion-col>
    </ion-row>
    <ion-row
      *ngIf="
        allCoupons &&
        allCoupons.length > 0 &&
        storeInfo &&
        (!storeInfo.dm_kiosk_app_active ||  
                (
                  groupApp &&
                  groupApp.design_settings &&
                  groupApp.design_settings.dm_kiosk_app_disable_mobileorder
                ))    
      "
    >
      <ion-col>
        <ion-card>
          <ion-item>
            <ion-label class="ion-text-wrap ion-text-center">
              <div>
                {{
                "reservation-app.discount-coupons.only-one-coupon-can-be-applied-to-any-order"
                | translate }}
              </div>

              <div *ngFor="let day of couponsSchedule">
                <p
                  *ngIf="
                    (!todayIsCouponsExceptionDay ||
                      todayIsCouponsExceptionDay !== day.day) &&
                    day &&
                    day.schedule &&
                    day.schedule !== 'disabled'
                  "
                >
                  <ion-text style="font-weight: 800"
                    >{{ day.day | translate }}:
                  </ion-text>
                  {{ "reservation-app.discount-coupons.coupons-is-active" |
                  translate }} {{ day.schedule }}
                </p>
                <p
                  *ngIf="
                    (!todayIsCouponsExceptionDay ||
                      todayIsCouponsExceptionDay !== day.day) &&
                    day &&
                    day.schedule &&
                    day.schedule === 'disabled'
                  "
                >
                  <ion-text style="font-weight: 800"
                    >{{ day.day | translate }}:</ion-text
                  >
                  {{ "reservation-app.discount-coupons.coupons-not-active" |
                  translate }}
                </p>
              </div>

              <div
                *ngIf="todayIsCouponsExceptionDay && currentDayCouponsSchedule"
              >
                <p>
                  <ion-text>
                    <ion-text style="font-weight: 800"
                      >{{ "reservation-app.discount-coupons.today" | translate
                      }}:</ion-text
                    >
                    {{ currentDayCouponsSchedule.active &&
                    currentDayCouponsSchedule.time_periods ?
                    ("reservation-app.discount-coupons.coupons-is-active" |
                    translate) : "" }}
                    <span
                      *ngIf="
                        currentDayCouponsSchedule.active &&
                        currentDayCouponsSchedule.time_periods &&
                        currentDayCouponsSchedule.time_periods.length &&
                        !currentDayCouponsSchedule.all_day
                      "
                    >
                      <ion-text
                        *ngFor="
                          let period of currentDayCouponsSchedule.time_periods
                        "
                        >({{ period.start_timestamp | date : "HH:mm" }} - {{
                        period.stop_timestamp | date : "HH:mm"
                        }})&nbsp;</ion-text
                      >
                    </span>

                    <span *ngIf="!currentDayCouponsSchedule.active">
                      <ion-text
                        >{{
                        "reservation-app.discount-coupons.coupons-not-active" |
                        translate }}</ion-text
                      >
                    </span>
                  </ion-text>
                </p>
              </div>

              <span *ngFor="let day of couponsExceptionDaysSchedule">
                <p *ngIf="day.showExceptionDay">
                  <ion-text>
                    <ion-text style="font-weight: bold">
                      {{ "store-schedule." + (day.date | date : "EEEE") |
                      lowercase | translate }} {{ day.date | date : "dd/MM/YYYY"
                      }}:
                    </ion-text>
                    <span
                      *ngIf="
                        day.active &&
                        day.time_periods &&
                        day.time_periods.length &&
                        !day.all_day
                      "
                    >
                      <ion-text>
                        {{ "reservation-app.discount-coupons.coupons-is-active"
                        | translate }}
                      </ion-text>

                      <ion-text *ngFor="let period of day.time_periods"
                        >{{ period.start_timestamp | date : "HH:mm" }} - {{
                        period.stop_timestamp | date : "HH:mm"
                        }}&nbsp;</ion-text
                      >
                    </span>

                    <span *ngIf="!day.active">
                      <ion-text
                        >{{
                        "reservation-app.discount-coupons.coupons-not-active" |
                        translate }}</ion-text
                      >
                    </span>
                  </ion-text>
                </p>
              </span>
            </ion-label>
          </ion-item>
        </ion-card>
      </ion-col>
    </ion-row>

    <ion-list *ngIf="allCoupons && allCoupons.length > 0">
      <ion-segment
        *ngIf="storeInfo && storeInfo.dm_kiosk_app_active && 
        !(
          groupApp &&
          groupApp.design_settings &&
          groupApp.design_settings.dm_kiosk_app_disable_mobileorder
        )"
        style="min-height: 55px"
        mode="ios"
        [(ngModel)]="filterType"
      >
        <ion-segment-button value="mobileorder">
          <ion-item class="item-segment-style">
            <ion-icon
              slot="start"
              style="font-size: 35px"
              src="assets/flatIcons/smartphone.svg"
            >
            </ion-icon>

            <ion-label> Apps </ion-label>
          </ion-item>
        </ion-segment-button>
        <ion-segment-button value="kiosk">
          <ion-item class="item-segment-style">
            <ion-icon
              slot="start"
              style="font-size: 38px"
              src="assets/flatIcons/kiosk.svg"
            >
            </ion-icon>

            <ion-label> Kiosk </ion-label>
          </ion-item>
        </ion-segment-button>
      </ion-segment>

      <ion-card
        *ngIf="
          storeInfo &&
          storeInfo.dm_kiosk_app_active &&  
          !(groupApp &&
          groupApp.design_settings &&
          groupApp.design_settings.dm_kiosk_app_disable_mobileorder) &&
          filterType === 'mobileorder'
        "
      >
        <ion-item>
          <ion-label class="ion-text-wrap ion-text-center">
            <div>
              {{
              "reservation-app.discount-coupons.only-one-coupon-can-be-applied-to-any-order"
              | translate }}
            </div>

            <div *ngFor="let day of couponsSchedule">
              <p
                *ngIf="
                  (!todayIsCouponsExceptionDay ||
                    todayIsCouponsExceptionDay !== day.day) &&
                  day &&
                  day.schedule &&
                  day.schedule !== 'disabled'
                "
              >
                <ion-text style="font-weight: 800"
                  >{{ day.day | translate }}:
                </ion-text>
                {{ "reservation-app.discount-coupons.coupons-is-active" |
                translate }} {{ day.schedule }}
              </p>
              <p
                *ngIf="
                  (!todayIsCouponsExceptionDay ||
                    todayIsCouponsExceptionDay !== day.day) &&
                  day &&
                  day.schedule &&
                  day.schedule === 'disabled'
                "
              >
                <ion-text style="font-weight: 800"
                  >{{ day.day | translate }}:</ion-text
                >
                {{ "reservation-app.discount-coupons.coupons-not-active" |
                translate }}
              </p>
            </div>

            <div
              *ngIf="todayIsCouponsExceptionDay && currentDayCouponsSchedule"
            >
              <p>
                <ion-text>
                  <ion-text style="font-weight: 800"
                    >{{ "reservation-app.discount-coupons.today" | translate
                    }}:</ion-text
                  >
                  {{ currentDayCouponsSchedule.active &&
                  currentDayCouponsSchedule.time_periods ?
                  ("reservation-app.discount-coupons.coupons-is-active" |
                  translate) : "" }}
                  <span
                    *ngIf="
                      currentDayCouponsSchedule.active &&
                      currentDayCouponsSchedule.time_periods &&
                      currentDayCouponsSchedule.time_periods.length &&
                      !currentDayCouponsSchedule.all_day
                    "
                  >
                    <ion-text
                      *ngFor="
                        let period of currentDayCouponsSchedule.time_periods
                      "
                      >({{ period.start_timestamp | date : "HH:mm" }} - {{
                      period.stop_timestamp | date : "HH:mm" }})&nbsp;</ion-text
                    >
                  </span>

                  <span *ngIf="!currentDayCouponsSchedule.active">
                    <ion-text
                      >{{ "reservation-app.discount-coupons.coupons-not-active"
                      | translate }}</ion-text
                    >
                  </span>
                </ion-text>
              </p>
            </div>

            <span *ngFor="let day of couponsExceptionDaysSchedule">
              <p *ngIf="day.showExceptionDay">
                <ion-text>
                  <ion-text style="font-weight: bold">
                    {{ "store-schedule." + (day.date | date : "EEEE") |
                    lowercase | translate }} {{ day.date | date : "dd/MM/YYYY"
                    }}:
                  </ion-text>
                  <span
                    *ngIf="
                      day.active &&
                      day.time_periods &&
                      day.time_periods.length &&
                      !day.all_day
                    "
                  >
                    <ion-text>
                      {{ "reservation-app.discount-coupons.coupons-is-active" |
                      translate }}
                    </ion-text>

                    <ion-text *ngFor="let period of day.time_periods"
                      >{{ period.start_timestamp | date : "HH:mm" }} - {{
                      period.stop_timestamp | date : "HH:mm" }}&nbsp;</ion-text
                    >
                  </span>

                  <span *ngIf="!day.active">
                    <ion-text
                      >{{ "reservation-app.discount-coupons.coupons-not-active"
                      | translate }}</ion-text
                    >
                  </span>
                </ion-text>
              </p>
            </span>
          </ion-label>
        </ion-item>
      </ion-card>

      <ion-card
        *ngIf="
          allCoupons &&
          allCoupons.length > 0 &&
          storeInfo &&
          groupApp &&
          storeInfo.dm_kiosk_app_active &&  
          !(groupApp &&
          groupApp.design_settings &&
          groupApp.design_settings.dm_kiosk_app_disable_mobileorder)&&
          filterType === 'kiosk' &&
          atLeastOneKioskCoupon
        "
      >
        <ion-item
          ><ion-label class="ion-text-wrap ion-text-center"
            >{{ "kiosk-page.please-choose-the-coupon-you-want-to-scan" |
            translate }}</ion-label
          >
        </ion-item>
      </ion-card>
      <ion-item
        lines="none"
        *ngIf="
          storeInfo && groupApp &&
          storeInfo.dm_kiosk_app_active && !(groupApp &&
          groupApp.design_settings &&
          groupApp.design_settings.dm_kiosk_app_disable_mobileorder) &&
          ((!kioskCouponsFound && filterType === 'kiosk') ||
            (!mobileorderCouponsFound && filterType === 'mobileorder'))
        "
      >
        <ion-label class="ion-text-center ion-text-wrap">
          <ion-grid
            *ngIf="
              (!mobileorderCouponsFound && filterType === 'mobileorder') ||
              (!kioskCouponsFound && filterType === 'kiosk')
            "
            style="max-width: 100px"
          >
            <ng-lottie [options]="sadAnimationOptions"></ng-lottie>
          </ion-grid>
          <ion-text *ngIf="!kioskCouponsFound && filterType === 'kiosk'">
            {{ "reservation-app.discount-coupons.no-kiosk-coupons-found" |
            translate }}
          </ion-text>
          <ion-text
            *ngIf="!mobileorderCouponsFound && filterType === 'mobileorder'"
          >
            {{ "reservation-app.discount-coupons.no-apps-coupons-found" |
            translate }}</ion-text
          >
        </ion-label>
      </ion-item>
      <div *ngIf="!filterType || filterType === 'mobileorder'">
        <app-reservation-coupons-item
          *ngFor="
            let coupon of allCoupons | kioskReward : filterType;
            trackBy: trackFn
          "
          [coupon]="coupon"
        ></app-reservation-coupons-item>
      </div>

      <div *ngIf="filterType && filterType === 'kiosk'">
        <app-reservation-kiosk-coupons-item
          *ngFor="
            let coupon of allCoupons | kioskReward : filterType;
            trackBy: trackFn
          "
          [coupon]="coupon"
        >
        </app-reservation-kiosk-coupons-item>
      </div>
    </ion-list>
  </ion-grid>
</ion-content>

<ion-footer
  *ngIf="
    couponFieldActiveInStore &&
    currentUser &&
    windowDimensions &&
    windowDimensions.width &&
    windowDimensions.width > 600
  "
>
  <ion-grid>
    <ion-row style="width: 100%">
      <ion-col class="ion-text-center">
        <ion-button [disabled]="couponCodeLoading" (click)="addNewCouponCode()">
          <ion-text *ngIf="!couponCodeLoading"
            >{{ "reservation-app.discount-coupons.add-coupon" | translate
            }}</ion-text
          >
          <ion-spinner *ngIf="couponCodeLoading"> </ion-spinner>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
