import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment-timezone";

@Pipe({
  name: "preorderDay",
})
export class PreorderDayPipe implements PipeTransform {
  transform(dateString: string): any {
    if (dateString) {
      const dayName = moment(dateString, "DD-MM-YYY")
        .locale("el")
        .format("ddd");
      const partsOfDate = dateString.split("-");
      let newDate;
      if (partsOfDate && partsOfDate.length === 3) {
        newDate = partsOfDate[0] + "-" + partsOfDate[1];
      }
      if (newDate && dayName) {
        dateString = dayName + " " + newDate;
      }
    }

    return dateString;
  }
}
