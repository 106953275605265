import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  AlertController,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { InvoiceAddEditModalComponent } from "../invoice/invoice-add-edit-modal/invoice-add-edit-modal.component";
import * as _ from "lodash";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../store/app.reducer";
import * as SelectedInvoiceActions from "../invoice/store/selectedInvoice.actions";
import {
  auditTime,
  catchError,
  distinctUntilChanged,
  first,
} from "rxjs/operators";
import { Subscription } from "rxjs";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import { TranslateService } from "@ngx-translate/core";
import * as selectors from "../../../store/app.selectors";
import * as AuthActions from "../../../auth/store/auth.actions";

@Component({
  selector: "app-invoice-list-modal",
  templateUrl: "./invoice-list-modal.component.html",
  styleUrls: ["./invoice-list-modal.component.scss"],
})
export class InvoiceListModalComponent implements OnInit, OnDestroy {
  public currentUser: any;
  public loading: boolean = false;
  public storeInfo: any;
  private subscriptions: Subscription[] = [];
  private unsubscribeBackEvent: Subscription;
  private mo_token: any;
  private selectedCustomerInvoice: any;
  private invoiceAddEditModal: any;
  private invoiceDeleteAlert;

  constructor(
    private modalCtrl: ModalController,
    private store: Store<fromApp.AppState>,
    private dataStorageService: DataStorageService,
    private translateService: TranslateService,
    private toastController: ToastController,
    private alertCtrl: AlertController,
    private platform: Platform
  ) {}

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.modalCtrl.dismiss();
        }
      }
    );
    

    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
         // console.log("subscription modal list invoice");
          if (state && state.user && !_.isEqual(this.currentUser, state.user)) {
          //  console.log("assign to current user invoices");
            this.currentUser = _.cloneDeep(state.user);
          }
          if (
            state &&
            state.mo_token &&
            !_.isEqual(this.mo_token, state.mo_token)
          ) {
          //  console.log("assign to current user invoices");
            this.mo_token = _.cloneDeep(state.mo_token);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())

        .subscribe((storeInfo) => {
          if (storeInfo && !_.isEqual(this.storeInfo, storeInfo)) {
            this.storeInfo = _.cloneDeep(storeInfo);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("selectedInvoice")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
  
          if (
            state &&
            state.selectedInvoice &&
            !_.isEqual(this.selectedCustomerInvoice, state.selectedInvoice)
          ) {
       
            this.selectedCustomerInvoice = _.cloneDeep(state.selectedInvoice);
          }
        })
    );
  }

  presentAddEditModal(addOrEdit, invoice) {
    if (!this.invoiceAddEditModal) {
      this.invoiceAddEditModal = true;
      this.showAddEditModal(addOrEdit, invoice);
    }
  }

  async showAddEditModal(addOrEdit, invoice) {
    this.invoiceAddEditModal = await this.modalCtrl.create({
      component: InvoiceAddEditModalComponent,
      cssClass: "invoiceAddEditModalCss",
      componentProps: {
        addOrEdit: addOrEdit,
        invoiceForEdit: invoice,
      },
      backdropDismiss: false,
    });

    await this.invoiceAddEditModal.present();
    this.invoiceAddEditModal.onDidDismiss().then((invoice) => {
      console.log(invoice);
      this.invoiceAddEditModal = null;
      if (invoice && invoice.data) {
      }

      // this.modalCtrl.dismiss();
    });
  }

  updateCustomerData(obj, mo_token) {
    this.loading = true;
    if (obj && mo_token) {
      this.dataStorageService

        .updateCustomerData(obj, mo_token)

        .subscribe({
          next: (res: any) => {
            if (res.success) {
              let updatedUser = res.data;
              this.store.dispatch(
                new AuthActions.SetUser(_.cloneDeep(updatedUser))
              );

              this.presentErrorToast(
                this.translateService.instant(
                  "cart.cart-components.invoice.invoice-list-modal.successfully-deleted-invoice"
                ),
                "primary"
              );

              this.loading = false;
            } else {
              this.presentErrorToast(
                this.translateService.instant(
                  "cart.cart-components.invoice.invoice-list-modal.an-error-occured-on-delete"
                ),
                "danger"
              );

              this.loading = false;
            }
          },
          error: (err) => {
            let errMsg = this.translateService.instant(
              "cart.cart-components.invoice.invoice-list-modal.an-error-occured-on-delete"
            );
            this.loading = false;
            this.presentErrorToast(errMsg, "danger");
            return err;
            console.log("error updating the information");
          },
        });
    }
  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
          //  console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }

  presentAlertConfirm(invoiceForDelete) {
    if (!this.invoiceDeleteAlert) {
      this.invoiceDeleteAlert = true;
      this.showAlertConfirm(invoiceForDelete);
    }
  }

  async showAlertConfirm(invoiceForDelete) {
    this.invoiceDeleteAlert = await this.alertCtrl.create({
      cssClass: "my-custom-class",
      header: this.translateService.instant(
        "cart.cart-components.invoice.invoice-list-modal.attention"
      ),
      message: this.translateService.instant(
        "cart.cart-components.invoice.invoice-list-modal.do-you-want-to-delete-invoice-data"
      ),
      buttons: [
        {
          text: this.translateService.instant(
            "cart.cart-components.invoice.invoice-list-modal.cancel"
          ),
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: this.translateService.instant(
            "cart.cart-components.invoice.invoice-list-modal.delete"
          ),
          handler: () => {
            this.deleteInvoice(invoiceForDelete);
          },
        },
      ],
    });

    this.invoiceDeleteAlert.present();
    this.invoiceDeleteAlert.onDidDismiss().then(() => {
      this.invoiceDeleteAlert = false;
    });
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  setMigrationCustomerId(migration_customer_id) {
    this.store.dispatch(
      new AuthActions.SetMigrationCustomerId(migration_customer_id)
    );
    if (navigator.cookieEnabled) {
      localStorage.setItem("migration_customer_id", migration_customer_id);
    }
  }

  selectInvoice(invoice) {
    this.store
      .select(selectors.getStoreInfo)
      .pipe(first())
      .subscribe((storeInfo) => {
        console.log("set selected invoice from invoice list");

        if (storeInfo && storeInfo.vatBasedCustomerProductsActive) {
          this.store
            .select("cart")
            .pipe(first())
            .subscribe(async (state) => {
              if (state && state.products && state.products.length) {
                const alert = await this.alertCtrl.create({
                  cssClass: "my-custom-class",
                  header: this.translateService.instant("alert"),
                  message: this.translateService.instant(
                    "cart.cart-components.invoice.your-cart-will-be-changed-based-on-your-selected-invoice-data"
                  ),
                  buttons: [
                    {
                      text: this.translateService.instant("cancel"),
                      role: "cancel",
                      cssClass: "secondary",
                      handler: (blah) => {},
                    },
                    {
                      text: this.translateService.instant("yes"),
                      handler: () => {
                        invoice.enabled = true;
                        this.store.dispatch(
                          new SelectedInvoiceActions.SetSelectedInvoice(invoice)
                        );
                        this.setMigrationCustomerId(
                          invoice.migration_customer_id
                        );
                        this.modalCtrl.dismiss(invoice);
                      },
                    },
                  ],
                });
                await alert.present();
              } else {
                invoice.enabled = true;
                this.store.dispatch(
                  new SelectedInvoiceActions.SetSelectedInvoice(invoice)
                );
                this.setMigrationCustomerId(invoice.migration_customer_id);
                this.modalCtrl.dismiss(invoice);
              }
            });
        } else if (storeInfo && !storeInfo.vatBasedCustomerProductsActive) {
          invoice.enabled = true;
          this.store.dispatch(
            new SelectedInvoiceActions.SetSelectedInvoice(invoice)
          );
          this.modalCtrl.dismiss(invoice);
        }
      })
      .unsubscribe();
  }

  deleteInvoice(invoice) {
    let newUser = _.cloneDeep(this.currentUser);
    let index = _.findIndex(newUser.invoiceData, {
      vatNumber: invoice.vatNumber,
    });
    if (index !== -1) {
      newUser.invoiceData.splice(index, 1);
      if (
        invoice &&
        this.selectedCustomerInvoice &&
        _.isEqual(invoice.vatNumber, this.selectedCustomerInvoice.vatNumber)
      ) {
        this.store.dispatch(new SelectedInvoiceActions.ClearSelectedInvoice());
      }
      this.updateCustomerData({ updatedData: newUser }, this.mo_token);
    } else {
    }
  }

  ngOnDestroy() {
    if (this.invoiceAddEditModal) {
      this.invoiceAddEditModal.dismiss();
      this.invoiceAddEditModal = null;
    }
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }
}
