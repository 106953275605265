import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { ProductDetailsComponent } from "src/app/product-details/product-details.component";
import * as _ from "lodash";
import * as fromApp from "../../../../store/app.reducer";
import * as selectors from "../../../../store/app.selectors";
import { auditTime, distinctUntilChanged } from "rxjs/operators";
declare let window: any;

@Component({
  selector: "app-product-item-mobile-no-image",
  templateUrl: "./product-item-mobile-no-image.component.html",
  styleUrls: ["./product-item-mobile-no-image.component.scss"],
})
export class ProductItemMobileNoImageComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() cartProductIds: any;
  @Input() product_id;
  @Input() category_id;
  @Input() hyperCategories: any;
  @Input() groupStoreData: any;
  @Input() orderPickup: any;
  @Input() dineIn: any;
  @Input() selectedDineInTable;
  @Input() selectedPreorderDate;
  @Input() favoriteProduct: boolean;
  public product: any;
  public productInCart: boolean = false;
  public productInCartCounter: number = 0;

  private productDetailsModal: any;
  private subscription: Subscription;

  constructor(
    private modalCtrl: ModalController,
    private store: Store<fromApp.AppState>
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes &&
      changes.product_id &&
      changes.product_id.currentValue !== changes.product_id.previousValue &&
      changes.product_id.previousValue
    ) {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      if (this.hyperCategories && this.hyperCategories.length > 0) {
        this.subscription = this.store
          .select(
            selectors.getProductItemByCategoryIdProductIdForHyperCategories(
              this.category_id,
              this.product_id
            )
          )
          .pipe(distinctUntilChanged())

          .subscribe((prd) => {
            if (prd && !_.isEqual(prd, this.product)) {
              this.product = _.cloneDeep(prd);
              this.unsubscibeSelectorDebounced();
            }
          });
      } else {
        this.subscription = this.store
          .select(
            !this.favoriteProduct
              ? selectors.getProductItemByCategoryIdProductId(
                  this.category_id,
                  this.product_id
                )
              : selectors.getProductItemAllCategoriesByCategoryIdProductId(
                  this.category_id,
                  this.product_id
                )
          )
          .pipe(distinctUntilChanged())

          .subscribe((prd) => {
            if (prd && !_.isEqual(prd, this.product)) {
              this.product = _.cloneDeep(prd);
              this.unsubscibeSelectorDebounced();
            }
          });
      }
    }
    if (
      changes &&
      changes.cartProductIds &&
      changes.cartProductIds.currentValue !==
        changes.cartProductIds.previousValue &&
      changes.cartProductIds.previousValue
    ) {
      this.checkIfProductIsAddedToCart();
    }
  }

  ngOnInit() {
    if (this.hyperCategories && this.hyperCategories.length > 0) {
      this.subscription = this.store
        .select(
          selectors.getProductItemByCategoryIdProductIdForHyperCategories(
            this.category_id,
            this.product_id
          )
        )
        .pipe(distinctUntilChanged())

        .subscribe((prd) => {
          if (prd && !_.isEqual(prd, this.product)) {
            this.product = _.cloneDeep(prd);
            this.unsubscibeSelectorDebounced();
          }
        });
    } else {
      this.subscription = this.store
        .select(
          selectors.getProductItemByCategoryIdProductId(
            this.category_id,
            this.product_id
          )
        )
        .pipe(distinctUntilChanged())

        .subscribe((prd) => {
          if (prd && !_.isEqual(prd, this.product)) {
            this.product = _.cloneDeep(prd);
            this.unsubscibeSelectorDebounced();
          }
        });
    }

    this.checkIfProductIsAddedToCart();
  }

  checkIfProductIsAddedToCart() {
    this.productInCartCounter = 0;
    if (this.cartProductIds && this.product) {
      this.productInCart = _.includes(
        this.cartProductIds,
        this.product.product_id
      );
      this.productInCartCounter = _.filter(this.cartProductIds, (id) => {
        if (id === this.product.product_id) {
          return id;
        }
      }).length;
    }
  }

  private unsubscibeSelectorDebounced = _.debounce(() => {
    this.unsubscribeSelector();
  }, 1000);

  unsubscribeSelector() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  prdWithoutImage(product) {
    return (
      !product.image ||
      (product.image && !product.image.true) ||
      (product.image && !product.image.true["16x9"]) ||
      (product.image &&
        product.image.true["16x9"] &&
        !product.image.true["16x9"].path)
    );
  }

  showProductDetailsModal(product) {
    let detailsCssClass;
    if (
      product &&
      product.num_of_options &&
      product.num_of_options < 2 &&
      this.prdWithoutImage(product)
    ) {
      detailsCssClass = "productDetailsModalSmall";
    } else if (
      product &&
      product.num_of_options &&
      !product.squareImageDesign &&
      product.num_of_options > 2 &&
      !this.prdWithoutImage(product)
    ) {
      detailsCssClass = "productDetailsModalBig";
    } else if (
      product &&
      product.squareImageDesign &&
      product.num_of_options &&
      product.num_of_options > 2 &&
      window &&
      window.innerWidth &&
      window.innerWidth >= 1200 &&
      !this.prdWithoutImage(product)
    ) {
      detailsCssClass = "productDetailsModalBigSquareImageDesign";
    } else {
      detailsCssClass = "productDetailsModal";
    }
    if (!this.productDetailsModal) {
      this.productDetailsModal = true;
      this.presentProductDetailsModal(product, detailsCssClass);
    }
  }

  async presentProductDetailsModal(product, detailsCssClass) {
    let product_id: string;
    let cart_id: string;
    if (product && product.product_id) {
      product_id = product.product_id;
    }

    if (product && product.cart_id) {
      cart_id = product.product_id;
    }

    this.productDetailsModal = await this.modalCtrl.create({
      component: ProductDetailsComponent,
      cssClass: detailsCssClass,
      animated: true,
      componentProps: {
        product_id: product_id,
        cart_id: cart_id,
      },
      backdropDismiss: false,
    });

    await this.productDetailsModal.present();
    this.productDetailsModal.onDidDismiss().then(() => {
      this.productDetailsModal = null;
    });
  }

  trackFn(index, item) {
    return index;
  }

  ngOnDestroy() {
    if (this.productDetailsModal) {
      this.productDetailsModal.dismiss();
      this.productDetailsModal = null;
    }
    this.product = null;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
