import * as GroupAppActions from "./groupApp.actions";
import * as _ from "lodash";

export interface State {
  groupApp: any;
  cookiesEnabled: boolean;
  imageSettings: any;
  webp: boolean;
  rootDir: string;
  selectedLangugage: string;
  selectedTheme: any;
  checkStoreOnlineExpiresAt: any;
  checkStoreOnlineExpiresNow: any;
  homePageInfoMessagesDisplayed: any;
  catalogPageInfoMessagesDisplayed: any;
  activeRoute: any;
  changeChannelTimestamp: any;
}

const initialState: State = {
  groupApp: null,
  cookiesEnabled: null,
  imageSettings: null,
  webp: false,
  rootDir: null,
  selectedLangugage: null,
  selectedTheme: null,
  checkStoreOnlineExpiresAt: null,
  checkStoreOnlineExpiresNow: null,
  homePageInfoMessagesDisplayed: false,
  catalogPageInfoMessagesDisplayed: false,
  activeRoute: null,
  changeChannelTimestamp: null,
};

export function groupAppReducer(
  state = initialState,
  action: GroupAppActions.GroupAppActions
) {
  switch (action.type) {
    case GroupAppActions.SET_GROUPAPP:
      return {
        ...state,
        groupApp: action.payload,
      };

    case GroupAppActions.CLEAR_GROUPAPP:
      return {
        ...state,
        groupApp: null,
      };

    case GroupAppActions.SET_COOKIES_ENABLED:
      return {
        ...state,
        cookiesEnabled: action.payload,
      };

    case GroupAppActions.SET_IMAGE_SETTINGS:
      return {
        ...state,
        imageSettings: action.payload,
      };

    case GroupAppActions.SET_WEBP:
      return {
        ...state,
        webp: action.payload,
      };

    case GroupAppActions.SET_ROOT_DIR:
      return {
        ...state,
        rootDir: action.payload,
      };
    case GroupAppActions.SET_SELECTED_LANGUAGE:
      return {
        ...state,
        selectedLangugage: action.payload,
      };
    case GroupAppActions.SET_SELECTED_THEME:
      return {
        ...state,
        selectedTheme: action.payload,
      };

    case GroupAppActions.SET_CHECK_STORE_ONLINE_EXPIRES_AT:
      return {
        ...state,
        checkStoreOnlineExpiresAt: action.payload,
      };

    case GroupAppActions.SET_CHECK_STORE_ONLINE_EXPIRES_NOW:
      return {
        ...state,
        checkStoreOnlineExpiresNow: action.payload,
      };

    case GroupAppActions.SET_HOMEPAGE_MODAL_INFO_MESSAGES_DISPLAYED:
      return {
        ...state,
        homePageInfoMessagesDisplayed: action.payload,
      };

    case GroupAppActions.SET_CATALOG_MODAL_INFO_MESSAGES_DISPLAYED:
      return {
        ...state,
        catalogPageInfoMessagesDisplayed: action.payload,
      };

    case GroupAppActions.SET_ACTIVE_ROUTE:
      return {
        ...state,
        activeRoute: action.payload,
      };

    case GroupAppActions.SET_CHANGE_CHANNEL_TIMESTAMP:
      return {
        ...state,
        changeChannelTimestamp: action.payload,
      };
    default:
      return state;
  }
}
