<span
  *ngIf="
    storeInfo &&
    (!category.subCategoriesActive ||
      (category.subCategoriesActive && !category.subCategories) ||
      (category.subCategories && category.subCategories.length === 0)) &&
    !category.sliderDesign &&
    !category.cardsDesign &&
    !category.marketCardsDesign
  "
>
  <span
    *ngFor="
      let product of (storeInfo &&
      storeInfo.design_settings &&
      storeInfo.design_settings.showOnlySelectedCategoryProducts
        ? filteredScrollProducts
        : category.products
      )
        | filterAvailableProducts : storeInfo.showAllUnavailableProducts
        | filterOnlyDineInDeliveryPickupProducts : orderPickup : dineIn;
      trackBy: productsTrackFn
    "
  >
    <app-product-items
      *ngIf="product.activeTotal && category.activeTotal"
      [category_id]="category.category_id"
      [productImage]="product.image"
      [product_id]="product.product_id"
      [cartProductIds]="cartProductIds"
      [groupStoreData]="groupStoreData"
      [selectedDineInTable]="selectedDineInTable"
      [selectedPreorderDate]="selectedPreorderDate"
      [dineIn]="dineIn"
      [orderPickup]="orderPickup"
      [hyperCategories]="hyperCategories"
      [webp]="webp"
    >
    </app-product-items>
  </span>

  <ion-row
    style="width: 100%"
    *ngIf="
      storeInfo &&
      storeInfo.design_settings &&
      storeInfo.design_settings.showOnlySelectedCategoryProducts &&
      category &&
      category.products &&
      filteredScrollProducts &&
      filteredScrollProducts.length !== category.products.length
    "
  >
    <ion-col class="ion-text-center">
      <ion-spinner name="bubbles"></ion-spinner>
    </ion-col>
  </ion-row>
</span>
