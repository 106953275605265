import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-cart-list-product-choice-comment",
  templateUrl: "./cart-list-product-choice-comment.component.html",
  styleUrls: ["./cart-list-product-choice-comment.component.scss"],
})
export class CartListProductChoiceCommentComponent implements OnInit {
  @Input() bundle_options;
  @Input() bundleProduct;
  @Input() orderPickup;
  @Input() dineIn;
  @Input() store_info;
  @Input() selectedDineInTable;

  constructor() {}

  ngOnInit() {
    console.log("bundle choice comment comp on init", this.bundleProduct);
  }
}
