import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Renderer2 } from "@angular/core";
import { Store } from "@ngrx/store";
import firebase from "firebase/compat/app";
import { stat } from "fs";
import {
  auditTime,
  debounceTime,
  distinctUntilChanged,
  Subscription,
} from "rxjs";
import * as fromApp from "../../store/app.reducer";
import * as _ from "lodash";
import { getAuth, RecaptchaVerifier } from "firebase/auth";

import { FirebaseBrowserService } from "src/app/services/firebaseBrowser/firebase-browser.service";
import * as FirebaseRecaptchaActions from "../firebase-recaptcha/store/firebaseRecaptcha.actions";
import { ModalController, Platform, ToastController } from "@ionic/angular";
import { timeStamp } from "console";
import { TranslateService } from "@ngx-translate/core";
import * as AuthActions from "../../auth/store/auth.actions";
import { AnalyticsServiceService } from "src/app/services/analitycsService/analytics-service.service";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import { Capacitor } from "@capacitor/core";

declare let window: any;
@Component({
  selector: "app-firebase-recaptcha-login-reservation-active",
  templateUrl: "./firebase-recaptcha-login-reservation-active.component.html",
  styleUrls: ["./firebase-recaptcha-login-reservation-active.component.scss"],
})
export class FirebaseRecaptchaLoginReservationActiveComponent
  implements OnInit, OnDestroy
{
  @ViewChild("mobileInput", { static: false }) mobileInput;
  @ViewChild("inputMobileRef", { read: ElementRef }) inputMobileRef: ElementRef;
  @Input() customerMobile: any;
  @Input() resend: any;
  public loading: boolean = false;
  public mobile: any;
  public selectedLanguage: string;
  public verified = false;
  public typeOfRecaptcha: string; //normal or invisible
  public reacaptchaLoaded = false;
  public enterKey: any;
  public capacitorPlatform: string = null;
  public groupApp: any;
  public isPhoneNumberValid: boolean;
  public mobileLoading = false;
  public headerLogo: any;
  public storeImage: any;
  public darkHeaderLogo: any;
  public windowDimensions: any;
  public selectedLogoFileName: any;
  public rootDir: string;

  private ErrorToast;
  private sub1: Subscription;
  private sub2: Subscription;
  private unsubscribeBackEvent: Subscription;
  private theme: string = "light";

  constructor(
    private store: Store<fromApp.AppState>,
    private firebaseBrowserService: FirebaseBrowserService,
    private modalController: ModalController,
    private changeDetector: ChangeDetectorRef,
    private toastController: ToastController,
    private translateService: TranslateService,
    private platform: Platform,
    private analyticsService: AnalyticsServiceService,
    private dataStorageService: DataStorageService
  ) {}

  closeModal() {
    this.modalController.dismiss();
  }

  async setModalBreakPointFull(ev) {
    if (
      this.windowDimensions &&
      this.windowDimensions.width &&
      this.windowDimensions.width < 500
    ) {
      const modal: any = await this.modalController.getTop();
      console.log("modalElement", modal);
      if (modal) {
        modal.setCurrentBreakpoint(1);
      }
    }
  }

  ngOnInit() {
    this.findInputElementRef();
    if (this.resend && this.customerMobile) {
      this.mobile = _.cloneDeep(this.customerMobile);
    }
    if (navigator.cookieEnabled) {
      let darkTheme = localStorage.getItem("darkTheme");
      if (darkTheme && darkTheme === "yes") {
        this.theme = "dark";
      } else {
        this.theme = "light";
      }
    }
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      300,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );
    console.log("recaptcha on init");
    this.sub1 = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(200))
      .subscribe((state) => {
        if (
          state &&
          state.selectedLangugage &&
          state.groupApp &&
          !_.isEqual(this.selectedLanguage, state.selectedLangugage)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp);
          if (!_.isEmpty(this.groupApp.logosImages)) {
            this.selectedLogoFileName = _.find(this.groupApp.logosImages, {
              selected: true,
            }).fileName;
          }
          this.dataStorageService.fetchGroupApp(this.groupApp.group).subscribe({
            next: (res: any) => {
              if (res) {
                this.storeImage = res.iconImage;
                this.headerLogo = res.headerLogo;
                console.log("groupAppHeaderLogo", res.headerLogo);
                this.darkHeaderLogo = res.headerLogoDark;
              } else {
              }
            },
            error: (error) => {
              return error;
            },
          });

          if (Capacitor.isNativePlatform()) {
            this.capacitorPlatform = "mobile";
          } else {
            this.capacitorPlatform = "browser";
            if (this.groupApp && this.groupApp.recaptchaVerifierCheckbox) {
              this.typeOfRecaptcha = "normal";
              this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
              this.Initialize(this.selectedLanguage, "normal");
            } else if (
              this.groupApp &&
              !this.groupApp.recaptchaVerifierCheckbox
            ) {
              this.typeOfRecaptcha = "invisible";
              this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
              this.Initialize(this.selectedLanguage, "invisible");
            }
          }
        }
        if (state && state.rootDir && !_.isEqual(this.rootDir, state.rootDir)) {
          this.rootDir = _.cloneDeep(state.rootDir);
        }
      });

    this.sub2 = this.store
      .select("windowInfo")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(300))
      .subscribe((state) => {
        if (!_.isEqual(state.windowDimensions, this.windowDimensions)) {
          this.windowDimensions = _.cloneDeep(state.windowDimensions);
        }
      });
  }

  findInputElementRef() {
    let interval = setInterval(() => {
      if (this.inputMobileRef && this.inputMobileRef.nativeElement) {
        if (interval) {
          clearInterval(interval);
          interval = null;
        }

        this.inputEnterKey();
      }
    }, 1500);
    setTimeout(() => {
      if (interval) {
        clearInterval(interval);
        interval = null;
      }
    }, 10000);
  }

  inputEnterKey() {
    if (this.inputMobileRef && this.inputMobileRef.nativeElement) {
      this.enterKey = (event) => {
        if (event.key === "Enter" || event.keyCode === 13) {
          event.preventDefault();
          this.onClickOk();
        }
        // do something
      };
      this.inputMobileRef.nativeElement.addEventListener(
        "keyup",
        this.enterKey
      );
    }
  }

  async presentErrorToast(message, color) {
    this.ErrorToast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
    });
    this.ErrorToast.present();
  }
  onPhoneNumberChange() {
    // Remove all non-numeric characters
    const numericValue = this.mobile.replace(/[^0-9]/g, "");

    // Limit to 10 characters
    const limitedValue = numericValue.slice(0, 10);

    // Update the ngModel bound variable
    this.mobile = limitedValue;

    // Check if the input contains exactly 10 numbers
    this.isPhoneNumberValid = limitedValue.length === 10;
  }

  onClickOk() {
    if (this.groupApp && this.groupApp.firebaseVerificationEnabled) {
      this.analyticsService.dmAnalyticsMessage(
        "submit_mobile_button",
        null,
        null,
        null,
        null,
        null,
        this.customerMobile,
        null,
        null,
        null,
        null
      );

      if (this.resend) {
        if (
          this.typeOfRecaptcha === "invisible" ||
          (this.capacitorPlatform && this.capacitorPlatform === "mobile")
        ) {
          let newCustomer = {
            gateway: "sms",
            mobile: this.customerMobile,
            createOneTimePass: true,
          };
          console.log(
            "new customer before present magicloginModal",
            newCustomer
          );
          this.store.dispatch(new AuthActions.SetNewCustomer(newCustomer));
        }
      } else {
        if (this.mobile) {
          let mobileRemoveSpaces = this.mobile.toString().replace(/\s/g, "");
          this.mobile = _.cloneDeep(mobileRemoveSpaces);
          let newCustomer = {
            gateway: "sms",
            mobile: this.mobile,
            createOneTimePass: true,
          };
          console.log(
            "new customer before present magicloginModal",
            newCustomer
          );
          this.store.dispatch(new AuthActions.SetNewCustomer(newCustomer));
        } else {
          if (this.ErrorToast) {
            this.ErrorToast.dismiss();
            this.ErrorToast = null;
          }
          this.presentErrorToast(
            this.translateService.instant(
              "popups.magic-login-popup.please-enter-your-mobile"
            ),
            "danger"
          );
          return false;
        }
      }
    } else {
      //firebase not enabled from superadmin
      this.mobileLoading = false;
      this.presentErrorToast(
        this.translateService.instant(
          "popups.magic-login-popup.you-are-in-a-test-version-of-the-app-you-cant-login"
        ),
        "danger"
      );
    }
  }

  InitializeFirebaseRecaptchaInvisible(language_code) {
    //https://developers.google.com/recaptcha/docs/display#render_param see here for the docs
    const firebaseApp = this.firebaseBrowserService.getFireBaseApp();
    if (firebaseApp) {
      const auth = getAuth(firebaseApp);
      auth.languageCode = language_code;

      if (window.recaptchaVerifier) {
        // This is needed so that the reCaptcha will be triggered again, each time the popup opens!
        console.log("clear recaptcha");
        window.recaptchaVerifier.clear();
      }

      console.log("Initializing reCaptcha");
      // We are in the browser version
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container-invisible",
        {
          size: "invisible",
          theme: this.theme,
          callback: (response) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            // ...
            console.log("reCaptcha response", response);

            this.verified = true;
            this.setFocusOnInputDebounce();

            // reCAPTCHA solved, allow signInWithPhoneNumber.
          },
          "expired-callback": () => {
            // Response expired. Ask user to solve reCAPTCHA again.
            // ...
            console.log("Expired callback");
            this.verified = false;

            if (window.recaptchaVerifier) {
              // This is needed so that the reCaptcha will be triggered again, each time the popup opens!
              console.log("clear recaptcha");
              window.recaptchaVerifier.clear();
              window.recaptchaVerifier = null;
            }
            this.InitializeFirebaseRecaptchaInvisible(this.selectedLanguage);
          },
          "error-callback": (e) => {
            console.log("Error callback:", e);
            this.verified = false;
            window.recaptchaVerifier.reset();
            window.recaptchaVerifier
              .render()
              .then((widgetId) => {
                this.reacaptchaLoaded = true;
                console.log("rendered reCaptcha");
              })
              .catch((err) => {
                console.log("error", err);
                console.log("closing modal");
                this.modalController.dismiss();
              });
          },
        }
      );

      window.recaptchaVerifier
        .render(onload)
        .then((widgetId) => {
          console.log("rendered reCaptcha");
          this.reacaptchaLoaded = true;
        })
        .catch((err) => {
          console.log("error", err);
          console.log("closing modal");
          this.modalController.dismiss();
        });
    }
    this.changeDetector.detectChanges();
  }

  Initialize(language_code, type) {
    if (type === "normal") {
      this.initializeFirebaseRecaptchaNormal(language_code);
    } else if (type === "invisible") {
      this.InitializeFirebaseRecaptchaInvisible(language_code);
    }
  }

  initializeFirebaseRecaptchaNormal(language_code) {
    const firebaseApp = this.firebaseBrowserService.getFireBaseApp();
    if (firebaseApp) {
      const auth = getAuth(firebaseApp);
      auth.languageCode = language_code;

      if (window.recaptchaVerifier) {
        // This is needed so that the reCaptcha will be triggered again, each time the popup opens!
        console.log("clear recaptcha");
        window.recaptchaVerifier.clear();
      }

      console.log("Initializing reCaptcha");
      // We are in the browser version
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container-normal",
        {
          size: "normal",
          theme: this.theme,
          callback: (response) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            // ...
            console.log("reCaptcha response", response);
            if (this.resend) {
              let newCustomer = {
                gateway: "sms",
                mobile: this.customerMobile,
                createOneTimePass: true,
              };
              console.log(
                "new customer before present magicloginModal",
                newCustomer
              );
              this.store.dispatch(new AuthActions.SetNewCustomer(newCustomer));
            } else {
              this.verified = true;
              this.setFocusOnInputDebounce();
            }

            // reCAPTCHA solved, allow signInWithPhoneNumber.
          },
          "expired-callback": () => {
            // Response expired. Ask user to solve reCAPTCHA again.
            // ...
            console.log("Expired callback");
            this.verified = false;

            if (window.recaptchaVerifier) {
              // This is needed so that the reCaptcha will be triggered again, each time the popup opens!
              console.log("clear recaptcha");
              window.recaptchaVerifier.clear();
              window.recaptchaVerifier = null;
            }
            this.initializeFirebaseRecaptchaNormal(this.selectedLanguage);
          },
          "error-callback": (e) => {
            console.log("Error callback:", e);
            this.verified = false;
            window.recaptchaVerifier.reset();
            window.recaptchaVerifier
              .render()
              .then((widgetId) => {
                this.reacaptchaLoaded = true;
                console.log("rendered reCaptcha");
              })
              .catch((err) => {
                console.log("error", err);
                console.log("closing modal");
                this.modalController.dismiss();
              });
          },
        }
      );

      window.recaptchaVerifier
        .render()
        .then((widgetId) => {
          this.reacaptchaLoaded = true;
          console.log("rendered reCaptcha");
        })
        .catch((err) => {
          console.log("error", err);
          console.log("closing modal");
          this.modalController.dismiss();
        });
    }
    this.changeDetector.detectChanges();
  }

  setFocusOnInputDebounce = _.debounce(() => {
    this.mobileInput.setFocus();
  }, 300);

  ngOnDestroy() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.sub1) {
      this.sub1.unsubscribe();
    }

    if (this.sub2) {
      this.sub2.unsubscribe();
    }
  }
}
function input() {
  throw new Error("Function not implemented.");
}
