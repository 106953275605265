import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ModalController, Platform, ToastController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { auditTime, distinctUntilChanged } from "rxjs/operators";
import * as fromApp from "../../../../../store/app.reducer";
import * as selectors from "../../../../../store/app.selectors";
import * as _ from "lodash";
import { OptionValidationService } from "../../../option-validation-service/option-validation.service";

@Component({
  selector: "app-checkbox-closed-choices-selection-modal",
  templateUrl: "./checkbox-closed-choices-selection-modal.component.html",
  styleUrls: ["./checkbox-closed-choices-selection-modal.component.scss"],
})
export class CheckboxClosedChoicesSelectionModalComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() selectedProductId: any;
  @Input() product_id;
  @Input() productName: any;
  @Input() bundleMode;
  @Input() option_id: any;
  @Input() must_select_something: any;
  @Input() selectedProductPromo: any;
  @Input() selectedProductCategory: any;
  @Input() modalOpenedIsNotValid: boolean;
  @Input() groupStoreData: any;
  @Input() optionChange = new EventEmitter();
  public option: any;
  changedOption;
  public validationObject: any;
  private unsubscribeBackEvent: Subscription;
  private orderPickup: boolean;
  private lastValidTimestamp: any;
  public windowDimensions: any;
  public choicesSumWeight: any = 0;
  public quantityInChoicesKind: string;
  public store_info;
  public alt_dinein_active;
  private dineIn;
  private subscriptions: Subscription[] = [];
  private optionSubscription: Subscription;

  constructor(
    private store: Store<fromApp.AppState>,
    private modalCtrl: ModalController,
    private optionValidationService: OptionValidationService,
    private toastController: ToastController,
    private platform: Platform
  ) {}

  selectOption() {
    if (this.optionSubscription) {
      this.optionSubscription.unsubscribe();
    }
    this.optionSubscription = this.store
      .select(
        selectors.getOptionByOptionId(this.selectedProductId, this.option_id)
      )
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (!_.isEqual(this.option, state)) {
          this.option = _.cloneDeep(state);
          if (this.option && this.option.showSumChoicesWeight) {
            this.calculateChoicesSumWeight();
          }
          if (this.modalOpenedIsNotValid) {
            this.validationOnOptionChangeDebounce();
            this.modalOpenedIsNotValid = false;
          }

          // console.log("option from modal closed choices", this.option);
        }
      });
  }

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      300,
      () => {
        if (this.platform.is("android")) {
          this.modalCtrl.dismiss();
        }
      }
    );
    this.selectOption();

    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(auditTime(50))
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.windowDimensions &&
            !_.isEqual(this.windowDimensions, state.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
            console.log(
              "window dem from radio closed choices selection",
              this.windowDimensions
            );
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("deliveryMethodAddress")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((state) => {
          if (state && !_.isEqual(this.orderPickup, state.orderPickup)) {
            this.orderPickup = _.cloneDeep(state.orderPickup);
          }

          if (state && !_.isEqual(this.dineIn, state.dineIn)) {
            this.dineIn = _.cloneDeep(state.dineIn);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((store_info) => {
          if (store_info && !_.isEqual(this.store_info, store_info)) {
            this.store_info = _.cloneDeep(store_info);
            if (this.store_info && this.store_info.dinein) {
              this.alt_dinein_active = this.store_info.alt_dinein_active;
            } else {
              this.alt_dinein_active = false;
            }
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("productOptionsValidation")
        .pipe(distinctUntilChanged())
        .subscribe((state: any) => {
          if (
            state &&
            state.lastValidTimestampBySelectedProductId &&
            state.lastValidTimestampBySelectedProductId[
              this.selectedProductId
            ] &&
            !_.isEqual(
              this.lastValidTimestamp,
              state.lastValidTimestampBySelectedProductId[
                this.selectedProductId
              ]
            )
          ) {
            this.lastValidTimestamp = _.cloneDeep(
              state.lastValidTimestampBySelectedProductId[
                this.selectedProductId
              ]
            );
            console.log("call validate opt...");
            this.validationObject = this.optionValidationService.validateOption(
              this.option,
              null,
              this.lastValidTimestamp,
              this.must_select_something,
              this.orderPickup,
              this.bundleMode,
              this.product_id,
              null,
              this.productName,
              this.selectedProductId,

              this.dineIn
            );
            if (!this.validationObject.valid) {
              console.log("option is not valid", this.option.option_id);
              const el: any = document.getElementById("content");
              if (el) {
                el.style.setProperty("--ion-background-color", "#ffd6d6");
              }
            } else {
              console.log("option is valid", this.option.option_id);
              const el: any = document.getElementById(
                this.option.option_id.toString()
              );
              if (el) {
                el.style.setProperty(
                  "--ion-background-color",
                  "--ion-background-color"
                );
              }
            }
          }
        })
    );
  }

  ngOnChanges(simpleChange: SimpleChanges) {
    if (
      simpleChange &&
      simpleChange.option_id &&
      simpleChange.option_id.currentValue !==
        simpleChange.option_id.previousValue &&
      simpleChange.option_id.previousValue
    ) {
      this.selectOption();
    }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  optionChanged(option) {
    this.optionChange.emit(option);
    this.validationOnOptionChangeDebounce();
  }

  validationOnOptionChangeDebounce = _.debounce(() => {
    this.validationOnOptionChange();
  }, 20);

  validationOnOptionChange() {
    console.log("validationOnOptionChange Called");
    console.log("call validate opt...");
    this.validationObject = this.optionValidationService.validateOption(
      this.option,
      null,
      this.lastValidTimestamp,
      this.must_select_something,
      this.orderPickup,
      this.bundleMode,
      this.product_id,
      null,
      this.productName,
      this.selectedProductId,
      this.dineIn
    );
    console.log("validationObjColor", this.validationObject.color);
    if (!this.validationObject.color) {
      console.log("option is not valid", this.option.option_id);
      let el: any = document.getElementById("toolbar");
      if (el) {
        el.style.setProperty("--ion-background-color", "#ffd6d6");
      }
    } else {
      console.log("option is valid", this.option.option_id);
      let el: any = document.getElementById("toolbar");
      if (el) {
        el.style.setProperty(
          "--ion-background-color",
          "--ion-background-color"
        );
      }
    }
  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }

  calculateChoicesSumWeight() {
    if (this.option && this.option.choices) {
      this.choicesSumWeight = 0;
      _.each(this.option.choices, (choice) => {
        if (
          choice &&
          choice.is_preselected &&
          choice.quantity &&
          choice.quantityInChoicesKind
        ) {
          this.choicesSumWeight = _.cloneDeep(
            this.choicesSumWeight +
              _.cloneDeep(parseFloat(choice.quantity.toString()))
          );
          this.quantityInChoicesKind = _.cloneDeep(
            choice.quantityInChoicesKind
          );
        }
      });
      if (this.choicesSumWeight) {
        this.choicesSumWeight = _.cloneDeep(
          parseFloat(this.choicesSumWeight.toString()).toFixed(2)
        );
      }
    }
  }

  selectionsOk() {
    if (this.validationObject && this.validationObject.valid) {
      this.modalCtrl.dismiss();
    } else if (this.validationObject && !this.validationObject.valid) {
      if (
        this.validationObject &&
        this.validationObject.errorArray &&
        this.validationObject.errorArray.length > 0
      ) {
        this.presentErrorToast(this.validationObject.errorArray[0], "danger");
      }
    } else {
      this.modalCtrl.dismiss();
    }
  }

  ngOnDestroy() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }

    if (this.optionSubscription) {
      this.optionSubscription.unsubscribe();
    }
  }
}
