import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { IonSlides } from "@ionic/angular";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import * as HyperCategoriesActions from "../../../store/hyperCategories/hyperCategories.actions";
import * as fromApp from "../../../store/app.reducer";
import * as selectors from "../../../store/app.selectors";
import { auditTime, distinctUntilChanged } from "rxjs/operators";
import { Subscription } from "rxjs";

@Component({
  selector: "app-horizontal-hyper-categories",
  templateUrl: "./horizontal-hyper-categories.component.html",
  styleUrls: ["./horizontal-hyper-categories.component.scss"],
})
export class HorizontalHyperCategoriesComponent implements OnInit, OnDestroy {
  @ViewChild("hyperCategoriesSlider") sliderComponent: IonSlides;

  public hyperCategories: any;
  public storeInfo: any;
  public icons_hyper_categories_design: boolean;
  public icons_hyper_categories_design_only_vertical: boolean;

  public hyperCategoryIconsLink =
    "https://data.deliverymanager.gr/hyperCategoriesIcons/";
  public selectedHyperCategory: any;

  public sliderOptions = {
    allowTouchMove: true,
    slidesPerView: "auto",
    direction: "horizontal",
    slideToClickedSlide: true,
    mousewheel: true,
    freeMode: true,
    freeModeMomentumBounce: false,
    freeModeMomentumRatio: 0.8,
    freeModeMomentumVelocityRatio: 0.8,
    centeredSlides: true,
    spaceBetween: 20,
  };

  private subscriptions: Subscription[] = [];
  private intervalHyperCategoriesSlider: any;

  constructor(private store: Store<fromApp.AppState>) {}

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())

        .subscribe((storeInfo) => {
          if (storeInfo && !_.isEqual(this.storeInfo, storeInfo)) {
            this.storeInfo = _.cloneDeep(storeInfo);
            this.icons_hyper_categories_design = !!_.cloneDeep(
              this.storeInfo.mobileorder_icons_hyper_categories_design
            );

            this.icons_hyper_categories_design_only_vertical = !!_.cloneDeep(
              this.storeInfo.icons_hyper_categories_design_only_vertical
            );
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("hyperCategories")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.hyperCategories &&
            !_.isEqual(this.hyperCategories, state.hyperCategories)
          ) {
            this.hyperCategories = _.cloneDeep(state.hyperCategories);
          }
          if (
            state &&
            state.currentHyperCategory &&
            !_.isEqual(this.selectedHyperCategory, state.currentHyperCategory)
          ) {
            console.log(
              "subscribe selected hyper category",
              this.sliderComponent
            );
            this.selectedHyperCategory = _.cloneDeep(
              state.currentHyperCategory
            );
            if (this.sliderComponent && this.selectedHyperCategory) {
              let key = _.cloneDeep(
                _.findIndex(this.hyperCategories, {
                  hyper_category_id: this.selectedHyperCategory,
                })
              );
              if (key && key !== -1) {
                console.log("slide to", this.selectedHyperCategory);
                this.sliderComponent.getSwiper().then((swiper) => {
                  swiper.slideTo(key);
                });
              }
            } else if (this.selectedHyperCategory && !this.sliderComponent) {
              let key = _.cloneDeep(
                _.findIndex(this.hyperCategories, {
                  hyper_category_id: this.selectedHyperCategory,
                })
              );
              this.intervalCheckForSliderCompoenent(key);
            }
          }
        })
    );
  }

  intervalCheckForSliderCompoenent(key) {
    this.intervalHyperCategoriesSlider = setInterval(() => {
      if (this.sliderComponent) {
        if (key && key !== -1) {
          console.log("slide to", this.selectedHyperCategory);
          this.sliderComponent.getSwiper().then((swiper) => {
            swiper.slideTo(key);
          });
        }
        clearInterval(this.intervalHyperCategoriesSlider);
        this.intervalHyperCategoriesSlider = null;
      }
    }, 800);
  }

  clearIntervalTimeout() {
    setTimeout(() => {
      if (this.intervalHyperCategoriesSlider) {
        clearInterval(this.intervalHyperCategoriesSlider);
      }
    }, 10000);
  }

  trackFn(inx, item) {
    return item.category_id;
  }

  HyperCategorySelection(hyperCat) {
    if (hyperCat) {
      this.store.dispatch(
        new HyperCategoriesActions.SetCurrentHyperCategory(
          _.cloneDeep(hyperCat.hyper_category_id)
        )
      );
      if (navigator.cookieEnabled) {
        localStorage.setItem(
          "selectedHyperCategory",
          hyperCat.hyper_category_id
        );
      }
    } else {
      this.store.dispatch(
        new HyperCategoriesActions.SetCurrentHyperCategory(_.cloneDeep(null))
      );
      if (navigator.cookieEnabled) {
        if (localStorage.getItem("selectedHyperCategory")) {
          localStorage.removeItem("selectedHyperCategory");
        }
      }
    }
  }

  ngOnDestroy() {
    // this.selectedHyperCategory = null;
    // this.store.dispatch(
    //   new HyperCategoriesActions.SetCurrentHyperCategory(_.cloneDeep(null))
    // );
    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }
}
