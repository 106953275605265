import { createSelector } from "@ngrx/store";
import * as fromApp from "./../store/app.reducer";
import * as _ from "lodash";

export const selectedProductsArray = (state: fromApp.AppState) =>
  state.selectedProduct.selectedProductsArray;

export const selectedProductsTotalPricesArr = (state: fromApp.AppState) =>
  state.selectedProduct.selectedProductsTotalPricesArray;

export const selectedProductsPointsToEarnArr = (state: fromApp.AppState) =>
  state.selectedProduct.selectedProductsPointsToEarnArray;
export const selectedProductDimensions = (state: fromApp.AppState) =>
  state.productDetailsDimensions.productDetailsDimensions;

export const currentStoreID = (state: fromApp.AppState) =>
  state.currentStore.currentStoreId;
export const WindowDemensions = (state: fromApp.AppState) =>
  state.windowInfo.windowDimensions;
export const Stores = (state: fromApp.AppState) => state.stores.stores;
export const Categories = (state: fromApp.AppState) => state.catalog.catalog;
export const AllCategories = (state: fromApp.AppState) =>
  state.catalog.allCategories;
export const AllCategoriesFromHyperCategories = (state: fromApp.AppState) =>
  state.hyperCategories.allCategories;
export const PreviousOrders = (state: fromApp.AppState) =>
  state.previousOrders.previousOrders;
export const Reservations = (state: fromApp.AppState) =>
  state.reservations.reservations;

export const getCategoryByCategoryId = (category_id) =>
  createSelector(Categories, (categories) => {
    if (categories) {
      return _.cloneDeep(
        _.find(categories, {
          category_id: category_id,
        })
      );
    } else {
      return;
    }
  });

export const getProductItemByCategoryIdProductId = (category_id, product_id) =>
  createSelector(Categories, (categories) => {
    if (categories) {
      let categoryIndex = _.findIndex(categories, { category_id: category_id });
      if (categoryIndex !== -1) {
        return _.cloneDeep(
          _.find(categories[categoryIndex].products, {
            product_id: product_id,
          })
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  });

export const getProductItemAllCategoriesByCategoryIdProductId = (
  category_id,
  product_id
) =>
  createSelector(AllCategories, (allCategories) => {
    if (allCategories) {
      let categoryIndex = _.findIndex(allCategories, {
        category_id: category_id,
      });
      if (categoryIndex !== -1) {
        return _.cloneDeep(
          _.find(allCategories[categoryIndex].products, {
            product_id: product_id,
          })
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  });

export const getProductItemByCategoryIdProductIdForHyperCategories = (
  category_id,
  product_id
) =>
  createSelector(AllCategoriesFromHyperCategories, (categories) => {
    if (categories) {
      let categoryIndex = _.findIndex(categories, { category_id: category_id });
      if (categoryIndex !== -1) {
        return _.cloneDeep(
          _.find(categories[categoryIndex].products, {
            product_id: product_id,
          })
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  });

export const getSelectedProductBySelectedProductId = (selectedProductId) =>
  createSelector(selectedProductsArray, (arrayOfProducts) => {
    return _.cloneDeep(
      _.find(arrayOfProducts, {
        selectedProductId: selectedProductId,
      })
    );
  });

export const getSelectedProductTotalPriceBySelectedProductId = (
  selectedProductId
) =>
  createSelector(selectedProductsTotalPricesArr, (arrayOfPrices) => {
    return _.cloneDeep(
      _.find(arrayOfPrices, {
        selectedProductId: selectedProductId,
      })
    );
  });

export const getSeletedProductPointsToEarnBySelectedProductId = (
  selectedProductId
) =>
  createSelector(selectedProductsPointsToEarnArr, (arrayOfProductPoints) => {
    return _.cloneDeep(
      _.find(arrayOfProductPoints, {
        selectedProductId: selectedProductId,
      })
    );
  });

export const getSelectedProductModalDimensionsBySelectedProductId = (
  selectedProductId
) =>
  createSelector(selectedProductDimensions, (arrayOfProductsDimensions) => {
    return _.cloneDeep(
      _.find(arrayOfProductsDimensions, {
        selectedProductId: selectedProductId,
      })
    );
  });

export const getOptionByOptionId = (selectedProductId, option_id) =>
  createSelector(selectedProductsArray, (arrayOfProducts) => {
    const prdIndex = _.findIndex(arrayOfProducts, {
      selectedProductId: selectedProductId,
    });
    if (prdIndex !== -1) {
      return _.cloneDeep(
        _.find(
          arrayOfProducts[prdIndex].options,
          {
            option_id: option_id,
          } || null
        )
      );
    } else {
      return;
    }
  });

export const getChoiceByOptionIdChoiceId = (
  selectedProductId,
  option_id,
  choice_id
) =>
  createSelector(selectedProductsArray, (arrayOfProducts) => {
    const prdIndex = _.findIndex(arrayOfProducts, {
      selectedProductId: selectedProductId,
    });
    let product;
    if (prdIndex !== -1) {
      product = arrayOfProducts[prdIndex];
    }

    if (product) {
      let optIndex = _.findIndex(product.options, {
        option_id: option_id,
      });

      if (optIndex !== -1) {
        let chIndex = _.findIndex(product.options[optIndex].choices, {
          choice_id: choice_id,
        });
        if (chIndex !== -1) {
          return _.cloneDeep(product.options[optIndex].choices[chIndex]);
        } else {
          return null;
        }
      } else {
        return null;
      }
    }
  });

// export const getBundleOptionByOptionId = (option_id) =>
//   createSelector(selectBundleProduct, (product) => {
//     if (product && product.options) {
//       return _.cloneDeep(
//         _.find(product.options, {
//           option_id: option_id,
//         })
//       );
//     } else {
//       return;
//     }
//   });

// export const getBundleChoiceByOptionIdChoiceId = (option_id, choice_id) =>
//   createSelector(selectBundleProduct, (product) => {
//     if (product) {
//       let optIndex = _.findIndex(product.options, {
//         option_id: option_id,
//       });

//       if (optIndex !== -1) {
//         let chIndex = _.findIndex(product.options[optIndex].choices, {
//           choice_id: choice_id,
//         });
//         if (chIndex !== -1) {
//           return _.cloneDeep(product.options[optIndex].choices[chIndex]);
//         } else {
//           return null;
//         }
//       } else {
//         return null;
//       }
//     }
//   });

export const getPreviousOrderById = (order_id) =>
  createSelector(PreviousOrders, (orders) => {
    if (orders) {
      return _.cloneDeep(
        _.find(orders, {
          order_id: order_id,
        })
      );
    } else {
      return null;
    }
  });

export const getStoreInfo = createSelector(
  Stores,
  currentStoreID,
  (stores, currentStoreID) => {
    if (stores && currentStoreID) {
      //console.log("from selectors", stores);
      //console.log("from currentStoreID", currentStoreID);
      let store_info = _.find(stores, {
        store_id: currentStoreID,
      });
      return store_info;
    } else {
      return null;
    }
  }
);

export const windowWidth = createSelector(WindowDemensions, (windowDem) => {
  if (windowDem && windowDem.width) {
    //console.log("from selectors", stores);
    //console.log("from currentStoreID", currentStoreID);
    let windowWidth = windowDem.width;
    return windowWidth;
  } else {
    return null;
  }
});

export const getReservationById = (res_id) =>
  createSelector(Reservations, (reservations) => {
    if (res_id && !_.isEmpty(reservations)) {
      return _.cloneDeep(
        _.find(reservations, {
          res_id: res_id,
        })
      );
    } else {
      return null;
    }
  });
