import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { distinctUntilChanged, first } from "rxjs/operators";
import * as fromApp from "../../store/app.reducer";
import * as selectors from "../../store/app.selectors";
import * as _ from "lodash";
import * as SelectedProductActions from "../store/selectedProduct.actions";
import { ProductDetailsOperationsService } from "../product-details-operations.service";
import { Subscription } from "rxjs";
@Component({
  selector: "app-options",
  templateUrl: "./options.component.html",
  styleUrls: ["./options.component.scss"],
})
export class OptionsComponent implements OnInit, OnDestroy {
  @Input() bundleMode: any;
  @Input() parentBundleChoice: any;
  @Input() selectedProductFromParent: any;
  @Input() groupStoreData: any;
  private subscription: Subscription;
  public selectedProduct: any;
  public must_select_something: any;
  public do_not_show_comments_option;

  constructor(
    private store: Store<fromApp.AppState>,
    private productDetailsOperationsService: ProductDetailsOperationsService
  ) {}

  ngOnInit() {
    if (this.selectedProductFromParent) {
      this.selectedProduct = _.cloneDeep(this.selectedProductFromParent);
      this.must_select_something = this.selectedProduct.must_select_something;
      console.log("check dep options");
      this.checkIsDependentOptions();
      this.checkProductQuantityFromChoices();
      this.store
        .select(selectors.getStoreInfo)
        .pipe(first())
        .subscribe((store_info) => {
          this.store
            .select("deliveryMethodAddress")
            .pipe(first())
            .subscribe((state) => {
              // console.log(
              //   ":call product price",
              //   this.bundleMode,
              //   this.parentBundleChoice
              // );
              this.selectedProduct =
                this.productDetailsOperationsService.cartProductPrice(
                  _.cloneDeep(this.selectedProduct),
                  state.orderPickup,
                  state.dineIn,

                  this.parentBundleChoice && this.bundleMode
                    ? _.cloneDeep(this.parentBundleChoice)
                    : null,
                  null,
                  store_info && store_info.dinein
                    ? store_info.alt_dinein_active
                    : false,
                  state.selectedDineInTable,
                  store_info.decimalPlacesPrices
                );

              this.calculateIfShowFieldOption();
            })
            .unsubscribe();
        });

      this.writeProductToState();
    }
    this.subscription = this.store
      .select(
        selectors.getSelectedProductBySelectedProductId(
          this.selectedProductFromParent.selectedProductId
        )
      )
      .pipe(distinctUntilChanged())
      //.pipe(auditTime(200))
      .subscribe((selectedProduct) => {
        if (
          selectedProduct &&
          selectedProduct.quantity &&
          !_.isEqual(this.selectedProduct.quantity, selectedProduct.quantity)
        ) {
          this.selectedProduct = _.cloneDeep(selectedProduct);
          this.calculateIfShowFieldOption();

          this.store
            .select(selectors.getStoreInfo)
            .pipe(first())
            .subscribe((store_info) => {
              this.store
                .select("deliveryMethodAddress")
                .pipe(first())
                .subscribe((state) => {
                  // console.log(
                  //   ":call product price",
                  //   this.bundleMode,
                  //   this.parentBundleChoice
                  // );
                  this.selectedProduct =
                    this.productDetailsOperationsService.cartProductPrice(
                      _.cloneDeep(this.selectedProduct),
                      state.orderPickup,
                      state.dineIn,
                      this.parentBundleChoice && this.bundleMode
                        ? _.cloneDeep(this.parentBundleChoice)
                        : null,
                      null,
                      store_info && store_info.dinein
                        ? store_info.alt_dinein_active
                        : false,
                      state.selectedDineInTable,
                      store_info.decimalPlacesPrices
                    );

                  this.calculateIfShowFieldOption();
                })
                .unsubscribe();
            })
            .unsubscribe();
        }
      });
  }

  trackFnc(index, option) {
    return option.product_id_option_id;
  }

  checkIsDependentOptions() {
    console.log("checkIsDependentOptions", this.selectedProduct.options[0]);

    if (!this.selectedProduct) {
      return;
    }

    this.selectedProduct.options = _.filter(
      this.selectedProduct.options,
      (opt) => {
        return _.isObject(opt);
      }
    );

    _.each(this.selectedProduct.options, (option, o, l) => {
      this.selectedProduct.options[o].dependenciesVariable = [];
    });

    _.each(this.selectedProduct.options, (option, o, l) => {
      _.each(this.selectedProduct.options[o].choices, (choice, c, list) => {
        if (!_.isEmpty(choice.dependentOptions)) {
          console.log(choice.title, choice.dependentOptions);

          _.each(choice.dependentOptions, (dependentOption, k, lo) => {
            console.log("dependentOption.option_id", dependentOption.option_id);
            var index = _.findIndex(this.selectedProduct.options, {
              option_id: dependentOption.option_id,
            });
            if (index !== -1) {
              this.selectedProduct.options[index].dependenciesVariable.push({
                when_is_preselected: true,
                option_id: option.option_id,
                optionTitle: option.title,
                choiceTitle: choice.title,
                choice_id: choice.choice_id,
                option_id_choice_id: choice.option_id_choice_id,
              });
            }
          });
        }

        if (!_.isEmpty(choice.dependenciesWhenNotPreselected)) {
          _.each(
            choice.dependenciesWhenNotPreselected,
            (dependentOption, k, lo) => {
              var index = _.findIndex(this.selectedProduct.options, {
                option_id: dependentOption.option_id,
              });
              if (index !== -1) {
                this.selectedProduct.options[index].dependenciesVariable.push({
                  when_is_preselected: false,
                  option_id: option.option_id,
                  optionTitle: option.title,
                  choiceTitle: choice.title,
                  choice_id: choice.choice_id,
                  option_id_choice_id: choice.option_id_choice_id,
                });
              }
            }
          );
        }
      });
    });
    this.hideShowDependentOptions();
  }

  checkProductQuantityFromChoices() {
    if (this.selectedProduct.quantityOnlyFromChoices) {
      this.selectedProduct.quantity = 1;
      _.each(this.selectedProduct.options, (option: any, o: any, q) => {
        if (
          this.selectedProduct.options &&
          this.selectedProduct.options[o] &&
          !_.isEmpty(this.selectedProduct.options[o]) &&
          this.selectedProduct.options[o].productQuantityChangeChoices &&
          this.selectedProduct.options[o].quantityInChoices
        ) {
          _.each(this.selectedProduct.options[o].choices, (choice, chIndex) => {
            if (
              this.selectedProduct.options[o].choices[chIndex].is_preselected &&
              (!this.selectedProduct.options[o].choices[chIndex].price ||
                this.selectedProduct.options[o].choices[chIndex].price ===
                  "0" ||
                this.selectedProduct.options[o].choices[chIndex].price ===
                  "0.00")
            ) {
              if (
                this.selectedProduct.options[o].choices[chIndex]
                  .productQuantityMultiplier
              ) {
                this.selectedProduct.quantity =
                  this.selectedProduct.quantity +
                  parseInt(
                    (
                      this.selectedProduct.options[o].choices[chIndex]
                        .productQuantityMultiplier *
                      this.selectedProduct.options[o].choices[chIndex].quantity
                    ).toFixed(0)
                  );
              } else {
                this.selectedProduct.quantity =
                  this.selectedProduct.quantity +
                  parseInt(
                    this.selectedProduct.options[o].choices[chIndex].quantity
                  );
              }
            }
          });
        }
      });

      if (this.selectedProduct.quantity > 1) {
        this.selectedProduct.quantity = this.selectedProduct.quantity - 1;
      }
    }
  }

  checkIfAnyChoiceDependencies(dependenciesVariable) {
    var found = false;

    _.each(this.selectedProduct.options, (option: any, o: any, q) => {
      if (
        this.selectedProduct.options &&
        this.selectedProduct.options[o] &&
        !_.isEmpty(this.selectedProduct.options[o]) &&
        (_.isEmpty(this.selectedProduct.options[o].dependenciesVariable) ||
          this.selectedProduct.options[o].showDependent) &&
        this.selectedProduct.options[o].activeTotal &&
        this.selectedProduct.options[o].availableTotal
      ) {
        _.each(this.selectedProduct.options[o].choices, (choice, c, w) => {
          if (choice.activeTotal && choice.availableTotal) {
            var foundChoice = _.filter(dependenciesVariable, {
              when_is_preselected: !!choice.is_preselected,
              option_id: option.option_id,
              choice_id: choice.choice_id,
            });

            if (foundChoice.length > 0) {
              found = true;
            }
          }
        });
      }
    });
    dependenciesVariable = null;
    return found;
  }

  hideShowDependentOptions() {
    console.log("hideShowDependentOptions", this.selectedProduct);

    var optionsThatAreDependent = _.filter(
      this.selectedProduct.options,
      (option) => {
        return (
          option &&
          typeof option === "object" &&
          option.dependenciesVariable &&
          option.dependenciesVariable.length > 0
        );
      }
    );

    _.each(optionsThatAreDependent, (option, key, list) => {
      this.selectedProduct.options[
        _.findIndex(this.selectedProduct.options, {
          option_id: option.option_id,
        })
      ].showDependent = this.checkIfAnyChoiceDependencies(
        option.dependenciesVariable
      );
    });
    optionsThatAreDependent = null;
  }

  selectedProductChanged(ChangedOption) {
    console.log("changed", ChangedOption, this.selectedProduct);
    if (this.selectedProduct && this.selectedProduct.options) {
      let optIndex = _.findIndex(this.selectedProduct.options, {
        option_id: ChangedOption.option_id,
      });
      if (optIndex !== -1) {
        this.selectedProduct.options[optIndex] = _.cloneDeep(ChangedOption);
        console.log("check dep options", ChangedOption);
        this.checkIsDependentOptions();
        this.checkProductQuantityFromChoices();
        this.store
          .select(selectors.getStoreInfo)
          .pipe(first())
          .subscribe((store_info) => {
            this.store
              .select("deliveryMethodAddress")
              .pipe(first())
              .subscribe((state) => {
                console.log(
                  ":call product price",
                  this.bundleMode,
                  this.parentBundleChoice
                );
                this.selectedProduct =
                  this.productDetailsOperationsService.cartProductPrice(
                    _.cloneDeep(this.selectedProduct),
                    state.orderPickup,
                    state.dineIn,
                    this.parentBundleChoice && this.bundleMode
                      ? _.cloneDeep(this.parentBundleChoice)
                      : null,
                    null,
                    store_info && store_info.dinein
                      ? store_info.alt_dinein_active
                      : false,
                    state.selectedDineInTable,
                    store_info.decimalPlacesPrices
                  );

                this.calculateIfShowFieldOption();
                this.writeProductToStateDebounce();
              })
              .unsubscribe();
          });
      } else {
        console.log("something went wrong");
      }
    }
  }

  writeProductToState() {
    this.store
      .select("selectedProduct")
      .pipe(first())
      .subscribe((state) => {
        if (
          state &&
          state.clearSelectedBundleChoiceObject &&
          state.clearSelectedBundleChoiceObject.product_id_option_id &&
          state.clearSelectedBundleChoiceObject.option_id_choice_id &&
          state.clearSelectedBundleChoiceObject.selectedProductId
        ) {
          const newSelectedProductsArray = _.cloneDeep(
            state.selectedProductsArray
          );

          const selectedProductIndex = _.findIndex(
            state.selectedProductsArray,
            {
              selectedProductId:
                state.clearSelectedBundleChoiceObject.selectedProductId,
            }
          );

          if (selectedProductIndex !== -1) {
            const selectedOptionIndex = _.findIndex(
              state.selectedProductsArray[selectedProductIndex].options,
              {
                product_id_option_id:
                  state.clearSelectedBundleChoiceObject.product_id_option_id,
              }
            );
            if (selectedOptionIndex !== -1) {
              const selectedBundleChoiceIndex = _.findIndex(
                state.selectedProductsArray[selectedProductIndex].options[
                  selectedOptionIndex
                ].choices,
                {
                  option_id_choice_id:
                    state.clearSelectedBundleChoiceObject.option_id_choice_id,
                }
              );
              if (selectedBundleChoiceIndex !== -1) {
                this.selectedProduct.options[selectedOptionIndex].choices[
                  selectedBundleChoiceIndex
                ].is_preselected = false;

                this.selectedProduct.options[selectedOptionIndex].choices[
                  selectedBundleChoiceIndex
                ].bundled_product = null;
              }
            }
          }
          this.store.dispatch(
            new SelectedProductActions.clearSelectedBundleChoiceById({
              product_id_option_id: null,
              option_id_choice_id: null,
              selectedProductId: null,
            })
          );
          this.checkIsDependentOptions();
          this.checkProductQuantityFromChoices();
          this.store.dispatch(
            new SelectedProductActions.SetSelectedProduct(
              _.cloneDeep(this.selectedProduct)
            )
          );
        } else {
          this.store.dispatch(
            new SelectedProductActions.SetSelectedProduct(
              _.cloneDeep(this.selectedProduct)
            )
          );
        }
      })
      .unsubscribe();
  }

  calculateIfShowFieldOption() {
    this.store
      .select(selectors.getStoreInfo)
      .pipe(first())
      .subscribe((storeInfo) => {
        if (storeInfo && storeInfo.bridge_split_bundle_products) {
          let onlyBundleOptionsProduct = true;

          if (
            !this.selectedProduct.options ||
            (this.selectedProduct.options &&
              this.selectedProduct.options.length === 1)
          ) {
            onlyBundleOptionsProduct = false;
          }

          _.each(this.selectedProduct.options, (opt) => {
            if (!opt.bundle && !(opt.default || opt.template === "comments")) {
              onlyBundleOptionsProduct = false;
            }
          });
          if (onlyBundleOptionsProduct) {
            this.do_not_show_comments_option = true;
          } else {
            if (
              this.selectedProduct &&
              this.selectedProduct.hideCommentsOption
            ) {
              this.do_not_show_comments_option = true;
            } else {
              this.do_not_show_comments_option = false;
            }
          }
        } else if (
          this.selectedProduct &&
          this.selectedProduct.hideCommentsOption
        ) {
          this.do_not_show_comments_option = true;
        } else {
          this.do_not_show_comments_option = false;
        }
      })
      .unsubscribe();
  }

  //Debounce for writing to state we also have debounce on check-box-choices and radio-choices component for every option
  // You must to sychronize these two debounces to avoid bugs when you change a choice (the other debounce is 600)
  writeProductToStateDebounce = _.debounce(() => {
    this.writeProductToState();
  }, 20);

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
