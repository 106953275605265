<ion-header>
  <app-no-internet-connection></app-no-internet-connection>
  <app-ionic-update-error></app-ionic-update-error>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="primary" (click)="modalCtrl.dismiss()">
        <ion-icon name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{storeAccount?storeAccount.store_name:""}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="item-list">
  <ion-grid *ngIf="storeAccount">
    <ion-item lines="none">
      <ion-icon
        *ngIf="item.res_status !== 'canceled' && item.res_status !== 'rejected'"
        name="checkmark-circle"
        class="green-checkmark"
        slot="start"
        color="success"
      ></ion-icon>
      <ion-label class="ion-text-wrap">
        {{ (item.res_status === 'canceled' || item.res_status === 'rejected' ?
        "reservation-app.canceledDate" : "reservation-app.doneDate") |translate
        }}
        <ion-text style="font-weight: bold" *ngIf="item && item.order_id"
          >#{{ item.order_id }}</ion-text
        ></ion-label
      >
      <!-- <ion-icon *ngIf="item.res_status === 'canceled' || item.res_status === 'rejected'" color="danger" slot="end" name="close" class="red-close"></ion-icon> -->
    </ion-item>
    <ion-item style="cursor: pointer" (click)="openLink()">
      <ion-icon name="location-sharp"></ion-icon>
      <ion-label class="ion-text-wrap" style="margin-left: 10px">
        <ion-text style="font-weight: bold">{{ groupApp.app_name }}</ion-text>
        <ion-text *ngIf="storeAccount.address">
          | {{ storeAccount.address }}</ion-text
        >
      </ion-label>
    </ion-item>
    <ion-item>
      <ion-icon name="calendar"></ion-icon>
      <ion-label
        style="margin-left: 10px; white-space: normal; font-size: 15px"
      >
        <span style="font-weight: bold">{{ item.date | dateDetails }}</span>
      </ion-label>
    </ion-item>
    <ion-item>
      <ion-icon name="person-circle"></ion-icon>
      <ion-label
        style="margin-left: 10px; font-size: 15px"
        class="ion-text-wrap"
        ><span style="font-weight: bold">{{item.product_name}}</span>
        {{"reservation-app.with"|translate}} {{item.table_name.el}}</ion-label
      >
    </ion-item>
    <ion-item *ngIf="item.grand_total">
      <ion-icon name="wallet"></ion-icon>
      <ion-label
        style="margin-left: 10px; font-size: 15px"
        class="ion-text-wrap"
        ><span style="font-weight: bold"
          >{{"reservation-app.total" |translate}}</span
        >
        <span style="font-weight: bold"> {{ item.grand_total }}€</span>
      </ion-label>
    </ion-item>
    <!-- <ion-button
      expand="block"
      shape="round"
      class="absolute-bottom-button"
      id="open-modal"
    >
      {{"reservation-app.review" |translate}}
    </ion-button>

    <ion-modal
      #modal
      trigger="open-modal"
      [initialBreakpoint]="0.50"
      [breakpoints]="[0, 0.25, 0.5, 0.75]"
    >
      <ng-template>
        <ion-content>
          <ion-item
            lines="none"
            class="ion-text-center"
            style="padding-top: 10px"
          >
            <ion-label style="font-size: 18px; font-weight: bold">
              {{"reservation-app.reviewHer" |translate}} {{item.table_name.el}}!
            </ion-label>
          </ion-item>
          <ion-item
            lines="none"
            style="margin: auto;"
            class="ion-align-items-center ion-no-padding"
          >
            <div class="rating" style="margin: auto">
              <ion-icon
                name="star"
                *ngFor="let star of [1, 2, 3, 4, 5]"
                [style.color]="star <= selectedStars ? '#E5097F' : 'gray'"
                (click)="selectStar(star)"
                style="margin-left: 10px"
              ></ion-icon>
            </div>
          </ion-item>
          <ion-item style="margin-top: 15px" lines="none">
            <ion-label>{{"reservation-app.comments" |translate}}</ion-label>
          </ion-item>
          <ion-item lines="none">
            <ion-textarea
              style="
                background-color: rgb(239, 239, 239);
                margin-left: 5px;
                margin-right: 10px;
                border-radius: 10px;
              "
            placeholder="{{'reservation-app.writeHere'| translate}}" 
            [autoGrow]="true"
            >
            </ion-textarea>
          </ion-item>
          <ion-button
            style="max-width: 70%; margin: auto; margin-top: 50px"
            expand="block"
            shape="round"
            (click)="cancel()"
          >
            {{"reservation-app.submit"|translate}}
          </ion-button>
        </ion-content>
      </ng-template>
    </ion-modal> -->
  </ion-grid>
</ion-content>
