<!-- ngif if you want to show choices or not in modal -->
<ion-list
  *ngIf="option && option.option_id"
  [id]="option.option_id"
  class="ion-no-padding"
>
  <app-bundle-option-radio-closed-choices
    *ngIf="option"
    (optionChange)="changedOption($event)"
    [optionValidationObject]="validationObject"
    [option_id]="option_id"
    [product_id]="product_id"
    [selectedProductPromo]="selectedProductPromo"
    [selectedProductCategory]="selectedProductCategory"
    [selectedProductFreeParentOption]="selectedProductFreeParentOption"
    [selectedProductId]="selectedProductId"
    [bundleMode]="bundleMode"
    [selectedProductParentDiscount]="selectedProductParentDiscount"
    [rootProductParentBundleChoice]="rootProductParentBundleChoice"
    [groupStoreData]="groupStoreData"
  ></app-bundle-option-radio-closed-choices>
</ion-list>
