import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ModalController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import { Subscription } from "rxjs";
import { auditTime, distinctUntilChanged, first } from "rxjs/operators";
import { AnalyticsServiceService } from "../services/analitycsService/analytics-service.service";
import * as fromApp from "../store/app.reducer";
import * as selectors from "../store/app.selectors";
import { currentStoreID } from "../store/app.selectors";

@Component({
  selector: "app-payment-terms",
  templateUrl: "./payment-terms.page.html",
  styleUrls: ["./payment-terms.page.scss"],
})
export class PaymentTermsPage implements OnInit, OnDestroy {
  @Input() modal: any;
  public stores: any;
  public storeInfo: any;
  public selectedLanguage: any;
  public groupApp: any;
  public selectedStoreId: any;
  public darkMode: boolean;
  public windowInfo: any;
  public menuMinWidthToShow: any;
  public deliveryManagerPath = "https://images.deliverymanager.gr/";

  private selectedTheme: any;
  private subscriptions: Subscription[] = [];
  private unsubscribeBackEvent: Subscription;

  constructor(
    private store: Store<fromApp.AppState>,
    private analyticsService: AnalyticsServiceService,
    private platform: Platform,
    private router: Router,
    public modalCtrl: ModalController
  ) {}

  ionViewDidEnter() {
    if (this.modal) {
      this.unsubscribeBackEvent =
        this.platform.backButton.subscribeWithPriority(300, () => {
          this.modalCtrl.dismiss();
        });
    } else {
      this.unsubscribeBackEvent =
        this.platform.backButton.subscribeWithPriority(100, () => {
          this.navigateToHome();
        });
    }

    this.analyticsService.fbqTrackPageView();
    this.analyticsService.setCurrentScreenFirebase("paymentTerms");
    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(200))
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(this.groupApp, state.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
          }
          if (
            state &&
            state.selectedTheme &&
            !_.isEqual(this.selectedTheme, state.selectedTheme)
          ) {
            this.selectedTheme = _.cloneDeep(state.selectedTheme);
            console.log("selected theme", this.selectedTheme);
            if (this.selectedTheme === "dark") {
              this.darkMode = true;
            } else {
              this.darkMode = false;
            }
          }

          if (
            state &&
            state.selectedLangugage &&
            !_.isEqual(this.selectedLanguage, state.selectedLangugage)
          ) {
            this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())

        .subscribe((storeInfo) => {
          if (storeInfo && !_.isEqual(this.storeInfo, storeInfo)) {
            this.storeInfo = _.cloneDeep(storeInfo);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("currentStore")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.currentStoreId &&
            !_.isEqual(this.selectedStoreId, state.currentStoreId)
          ) {
            this.selectedStoreId = _.cloneDeep(state.currentStoreId);
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(200))
        .subscribe((state) => {
          if (state && state.stores) {
            this.stores = _.filter(_.cloneDeep(state.stores), (store) => {
              if (store.showPaymentTerms) {
                return store;
              }
            });
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.windowDimensions &&
            !_.isEqual(this.windowInfo, state.windowDimensions)
          ) {
            this.windowInfo = _.cloneDeep(state.windowDimensions);
            this.menuMinWidthToShow = getComputedStyle(
              document.documentElement
            ).getPropertyValue("--menuMinWidthToShow");
            if (this.menuMinWidthToShow) {
              this.menuMinWidthToShow = parseInt(this.menuMinWidthToShow);
            }
            console.log("menu min with to show", this.menuMinWidthToShow);
          }
          console.log("window info", this.windowInfo);
        })
    );
  }

  ngOnInit() {}

  // checkExpanded(store_id) {
  //   if (this.expanded[store_id]) {
  //     this.expanded;
  //   }
  // }

  changeStore() {}

  ngOnDestroy() {
    if (!_.isEmpty(this.subscriptions)) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }

  private navigateToHome() {
    if (this.platform.is("android")) {
      this.router.navigateByUrl("/homepage", {
        replaceUrl: true,
      });
    }
  }

  ionViewDidLeave() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (!_.isEmpty(this.subscriptions)) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    this.subscriptions = [];
  }
}
