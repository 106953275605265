import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import * as _ from "lodash";
import moment from "moment";

@Component({
  selector: "app-product-choice-tags",
  templateUrl: "./product-choice-tags.component.html",
  styleUrls: ["./product-choice-tags.component.scss"],
})
export class ProductChoiceTagsComponent implements OnInit, OnChanges {
  @Input() choiceItem: any;
  @Input() optionItem: any;
  @Input() selectedProductPromo: any;
  @Input() selectedPreorderDate;
  @Input() groupStoreData: any;
  public preorderDateIsAfterToday;
  public optionIndex: any;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.selectedPreorderDate &&
      !_.isEqual(
        changes.selectedPreorderDate.previousValue,
        changes.selectedPreorderDate.currentValue
      )
    ) {
      this.checkPreorderDate();
    }
  }

  checkPreorderDate() {
    if (
      this.selectedPreorderDate &&
      this.selectedPreorderDate.date &&
      this.selectedPreorderDate.hour
    ) {
      const preorderDate = moment(
        `${this.selectedPreorderDate.date} ${this.selectedPreorderDate.hour}`,
        "DD-MM-YYYY HH:mm"
      );

      const tomorrowDate = moment()
        .add(1, "day")
        .set("hour", 0)
        .set("minutes", 0);

      if (preorderDate.isAfter(tomorrowDate)) {
        this.preorderDateIsAfterToday = true;
      } else {
        this.preorderDateIsAfterToday = false;
      }
    } else {
      this.preorderDateIsAfterToday = false;
    }
  }

  constructor() {}

  ngOnInit() {}
}
