<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button fill="clear" (click)="closeModal()">
        <ion-icon src="assets/ionicons/close.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <ion-text>{{ contest.title }} - {{ "loyaltySystem.winner_lottery_numbers" | translate }}</ion-text>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid>
    <ion-row *ngIf="contest.winners" style="width: 100%">
      <ion-col>
        <ion-card class="ion-no-padding ion-align-items-center ion-text-center"
          *ngFor="let winner of contest.winners; let i=index">
          <ion-card-content style="padding: 0">
            <ion-row class="ion-no-padding ion-no-margin ion-justify-content-center ion-align-items-center">
              <ion-col class="ion-text-md-left">
                <p style="font-size:'20px'; margin:0 0 0 10px;">
                  {{i+1}}ος {{ "loyaltySystem.lottery_number" | translate }}
                </p>
              </ion-col>
              <ion-col class="ion-text-md-right">
                <p style="font-size:'26px';  margin:5px 10px 0 0; font-weight:400">
                  #{{winner.lottery_number}}
                </p>
              </ion-col>

            </ion-row>
            <ion-row class="ion-no-padding ion-no-margin">
              <ion-col>
                <p style="margin:0; font-size:'18px'">
                  {{winner.mobile}}
                </p>
              </ion-col>
            </ion-row>
            <ion-row class="ion-no-padding ion-no-margin">
              <ion-col>
                <p style="margin:0; font-size:'18px'">
                  {{winner.gift}}
                </p>
              </ion-col>
            </ion-row>



          </ion-card-content>

        </ion-card>

      </ion-col>
    </ion-row>
  </ion-grid>