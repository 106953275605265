import { Pipe, PipeTransform } from "@angular/core";
import * as _ from "lodash";

@Pipe({
  name: "filterAvailableProducts",
})
export class FilterAvailableProductsPipe implements PipeTransform {
  transform(productsArray: any, storeInfoShowAllAvailablePrds: boolean): any {
    if (!storeInfoShowAllAvailablePrds) {
      return _.filter(productsArray, (product) => {
        if (
          product.availableTotal &&
          product.product_categories_availability_check
        ) {
          return product;
        }
      });
    } else {
      return productsArray;
    }
  }
}
