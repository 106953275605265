<ion-item
  [ngClass]="{
    'line-through': !!(!option.availableTotal || !choice.availableTotal)
  }"
  *ngIf="choice && option"
  lines="none"
>
  <ion-label
    [class]="
      !option.availableTotal ||
      !choice.availableTotal ||
      (option.dependenciesVariable &&
        option.dependenciesVariable.length > 0 &&
        !option.dependentHidden &&
        !option.showDependent)
        ? 'ion-text-wrap opacityDisabled'
        : 'ion-text-wrap'
    "
    style="line-height: 1.2"
  >
    <div>
      {{ choice.title }}
      <ion-icon
        [color]="
          groupStoreData &&
          groupStoreData.store_info &&
          groupStoreData.store_info.design_settings &&
          groupStoreData.store_info.design_settings.tagsColors &&
          groupStoreData.store_info.design_settings.tagsColors.frozen
            ? groupStoreData.store_info.design_settings.tagsColors.frozen
            : 'primary'
        "
        class="badge-margin"
        style="font-size: 10px"
        src="assets/flatIcons/frozen.svg"
        *ngIf="choice.tags && choice.tags.frozen"
      >
        <ion-text>{{ "item-tags.frozen" | translate }}</ion-text>
      </ion-icon>
    </div>

    <div>
      <app-product-choice-tags
        [optionItem]="option"
        [choiceItem]="choice"
        [selectedProductPromo]="selectedProductPromo"
        [groupStoreData]="groupStoreData"
      >
      </app-product-choice-tags>
    </div>
  </ion-label>

  <ion-checkbox
    [disabled]="
      !option.availableTotal ||
      !choice.availableTotal ||
      (option.dependenciesVariable &&
        option.dependenciesVariable.length > 0 &&
        !option.dependentHidden &&
        !option.showDependent)
    "
    #checkbox
    slot="start"
    [(ngModel)]="choice.is_preselected"
    (ionChange)="onCheckboxChanged($event)"
  >
  </ion-checkbox>

  <ion-thumbnail class="thumb" slot="start">
    <img
      [alt]="choice.title"
      loading="lazy"
      *ngIf="imagePath"
      [src]="imagePath"
    />
  </ion-thumbnail>

  <div style="align-items: center; text-align: center; z-index: 10000000">
    <div [class]="choice && choice.is_preselected ? 'price-style' : ''">
      <ion-text
        *ngIf="
          (!choice.isFree ||
            (choice.freeQuantity && choice.quantity > choice.freeQuantity)) &&
          showChoicePrices
        "
      >
        <ion-icon
          src="assets/flatIcons/new.svg"
          slot="end
            "
        ></ion-icon>

        <span
          *ngIf="
            choice.quantityInChoicesKind &&
            choice.quantity &&
            choice.is_preselected
          "
        >
          {{ choice.quantity }} {{ choice.quantityInChoicesKind }}
        </span>
      </ion-text>
      <ion-text
        align="center"
        class="mainFontSize categoriesWarningColor"
        [ngClass]="{
          disabled: !!(
            !option.availableTotal ||
            !choice.availableTotal ||
            (option.dependenciesVariable &&
              option.dependenciesVariable.length > 0 &&
              !option.dependentHidden &&
              !option.showDependent)
          ),
          'line-through ': !!(!option.availableTotal || !choice.availableTotal)
        }"
        *ngIf="
          (choice.isFree &&
            choice.freeQuantity &&
            choice.quantity <= choice.freeQuantity) ||
          (choice.isFree && !choice.freeQuantity)
        "
      >
        {{ "product-details.free" | translate }}
      </ion-text>
    </div>
    <app-change-quantity
      style="margin: 0px; z-index: 100000000"
      [buttonsSize]="'small'"
      [quantityInChoicesKind]="choice.quantityInChoicesKind"
      [choiceStep]="choice.quantityInChoicesStep"
      slot="end"
      *ngIf="choice && choice.is_preselected && showQuantityComponent"
      [disablePlusButton]="
        !option.availableTotal ||
        !choice.availableTotal ||
        (option.dependenciesVariable &&
          option.dependenciesVariable.length > 0 &&
          !option.dependentHidden &&
          !option.showDependent)
      "
      [disableRemoveButton]="
        !option.availableTotal ||
        !choice.availableTotal ||
        (option.dependenciesVariable &&
          option.dependenciesVariable.length > 0 &&
          !option.dependentHidden &&
          !option.showDependent)
      "
      [quantity]="choice.quantity"
      [showIntegerQuantity]="true"
      (changingQuantity)="changeChoiceQuantity($event)"
    ></app-change-quantity>
  </div>
</ion-item>
