<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="primary" (click)="modalCtrl.dismiss()">
        <ion-icon name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ "reservation-app.profile" | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button
        *ngIf="!customerDataSaveLoading"
        (click)="saveOrEdit()"
        [color]="dirtyButton ? 'danger' : 'dark'"
        slot="end"
        fill="clear"
      >
        <ion-icon
          slot="icon-only"
          [src]="'assets/ionicons/save.svg'"
        ></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-spinner *ngIf="customerDataSaveLoading" slot="end"></ion-spinner>
  </ion-toolbar>
</ion-header>

<ion-content class="item-list" *ngIf="groupApp">
  <ion-grid>
    <ion-item lines="none" style="margin-top: 20px">
      <img
        alt="logo"
        style="height: 200px; margin: auto"
        alt="{{ groupApp.group }}"
        src="{{ rootDir }}{{ groupApp.group }}/logos/{{ selectedLogoFileName }}"
      />
    </ion-item>
    <ion-item lines="none" *ngIf="currentUser">
      <div style="padding: 2rem; width: 100%">
        <ion-label
          style="
            position: absolute;
            z-index: 2;
            background-color: white;
            left: 55px;
            top: 18px;
            padding: 5px;
            font-weight: bold;
          "
          >{{ "reservation-app.name" | translate }}</ion-label
        >
        <ion-item
          lines="none"
          style="height: auto; border-radius: 35px; border: 2px solid black"
        >
          <ion-input
            labelPlacement="floating"
            placeholder="{{ 'reservation-app.writeHere' | translate }}"
            name="phoneNumber"
            type="text"
            (ngModelChange)="changed()"
            (ionFocus)="onInputFocus()"
            [(ngModel)]="currentUser.firstName"
          >
          </ion-input>
        </ion-item>
      </div>
    </ion-item>
    <ion-item lines="none" *ngIf="currentUser">
      <div style="padding: 2rem; width: 100%">
        <ion-label
          style="
            position: absolute;
            z-index: 2;
            background-color: white;
            left: 55px;
            top: 18px;
            padding: 5px;
            font-weight: bold;
          "
          >{{ "reservation-app.lastname" | translate }}</ion-label
        >
        <ion-item
          lines="none"
          style="height: auto; border-radius: 35px; border: 2px solid black"
        >
          <ion-input
            labelPlacement="floating"
            name="phoneNumber"
            type="text"
            placeholder="{{ 'reservation-app.writeHere' | translate }}"
            (ngModelChange)="changed()"
            (ionFocus)="onInputFocus()"
            [(ngModel)]="currentUser.lastName"
          >
          </ion-input>
        </ion-item>
      </div>
    </ion-item>
    <ion-item lines="none" *ngIf="currentUser">
      <div style="padding: 2rem; width: 100%">
        <ion-label
          style="
            position: absolute;
            z-index: 2;
            background-color: white;
            left: 55px;
            top: 18px;
            padding: 5px;
            font-weight: bold;
          "
          >Email</ion-label
        >
        <ion-item
          lines="none"
          style="height: auto; border-radius: 35px; border: 2px solid black"
        >
          <ion-input
            labelPlacement="floating"
            #emailInput
            placeholder="{{ 'reservation-app.writeHere' | translate }}"
            type="email"
            required
            (ngModelChange)="changed()"
            [(ngModel)]="currentUser.email"
          >
          </ion-input>
        </ion-item>
      </div>
    </ion-item>
    <ion-item lines="none" *ngIf="currentUser" disabled="true">
      <div style="padding: 2rem; width: 100%">
        <ion-label
          style="
            position: absolute;
            z-index: 2;
            background-color: white;
            left: 55px;
            top: 18px;
            padding: 5px;
            font-weight: bold;
          "
          >{{ "reservation-app.confirmedMobile" | translate }}</ion-label
        >
        <ion-item
          lines="none"
          style="
            height: auto;
            border-radius: 35px;
            border: 2px solid rgb(0, 232, 8);
          "
        >
          <ion-input
            labelPlacement="floating"
            name="phoneNumber"
            placeholder="{{ 'reservation-app.writeHere' | translate }}"
            type="text"
            [(ngModel)]="currentUser.mobile"
          >
          </ion-input>
        </ion-item>
      </div>
      <ion-label
        class="ion-text-center ion-text-wrap"
        style="
          margin: auto;
          position: absolute;
          bottom: 8px;
          color: rgb(0, 232, 8);
          left: 28%;
        "
      ></ion-label>
    </ion-item>
    <ion-item lines="none">
      <ion-label
        class="ion-text-center"
        style="
          color: orange;
          font-size: 14px;
          font-weight: bold;
          cursor: pointer;
        "
        (click)="requestDeleteAccount()"
      >
        {{ "reservation-app.deleteAccount" | translate }}
      </ion-label>
    </ion-item>
  </ion-grid>
</ion-content>
