import * as FirebaseRecaptchaActions from "./firebaseRecaptcha.actions";
import * as _ from "lodash";

export interface State {
  token: string;
}

const initialState: State = {
  token: "no-token",
};

export function firebaseRecaptchaReducer(
  state = initialState,
  action: FirebaseRecaptchaActions.FirebaseRecaptchaActions
) {
  switch (action.type) {
    case FirebaseRecaptchaActions.SET_FIREBASE_RECAPTCHA_TOKEN:
      return {
        ...state,
        token: action.payload,
      };

    case FirebaseRecaptchaActions.CLEAR_FIREBASE_RECAPTCHA_TOKEN:
      return {
        ...state,
        token: null,
      };

    default:
      return state;
  }
}
