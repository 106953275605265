<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">
      <ion-text> {{ "catalog.select-hyper-category" | translate }}</ion-text>
    </ion-title>
    <p
      *ngIf="step && step === 'categoriesSelection' && selectedHyperCategory"
      class="ion-no-padding ion-no-margin"
      style="padding-inline-start: 22px"
    >
      <ion-text style="font-size: small">
        {{ selectedHyperCategory.name }}
      </ion-text>
    </p>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()" fill="clear"
        ><ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon
      ></ion-button>
    </ion-buttons>

    <ion-buttons *ngIf="step && step === 'categoriesSelection'" slot="start">
      <ion-button fill="clear" (click)="goBack()">
        <ion-icon slot="icon-only" name="arrow-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid
    *ngIf="storeInfo && step === 'hyperCategoriesSelection'"
    (click)="closeModal()"
    style="height: 100%; width: 100%"
  >
    <ion-row style="width: 100%" class="ion-align-items-center">
      <ion-col
        class="ion-text-center ion-no-padding"
        size="6"
        *ngFor="let hyperCategory of hyperCategories; trackBy: trackFn"
      >
        <ion-grid
          *ngIf="storeInfo.mobileorder_icons_hyper_categories_design"
          style="width: 165px; min-height: 160px"
          class="ion-no-padding"
        >
          <ion-card
            (click)="HyperCategorySelection(hyperCategory)"
            style="
              z-index: 1000;
              height: 150px;
              display: grid;
              border-radius: 8px;
            "
            button="true"
            color="primary"
          >
            <ion-grid style="height: 100%" class="ion-no-padding">
              <ion-row
                style="height: 100%; width: 100%"
                class="ion-align-items-center ion-justify-content-center"
              >
                <ion-col size="12" class="ion-align-self-center">
                  <ion-icon
                    style="font-size: 50px; margin: 0"
                    [src]="
                      hyperCategoryIconsLink +
                      hyperCategory.mobileorder_hyper_category_icon
                    "
                  ></ion-icon>
                  <div style="margin-top: 10px">
                    <ion-text style="font-size: small; font-weight: bold"
                      >{{
                        hyperCategory.name !== "others"
                          ? hyperCategory.name
                          : ("others" | translate)
                      }}
                    </ion-text>
                  </div>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card>
        </ion-grid>
        <ion-grid *ngIf="!storeInfo.mobileorder_icons_hyper_categories_design">
          <ion-item
            (click)="HyperCategorySelection(hyperCategory)"
            style="z-index: 1000; border-radius: 8px"
            button="true"
            color="primary"
            detail="false"
          >
            <ion-label
              class="ion-text-wrap"
              style="font-size: small; font-weight: bold"
            >
              <div class="wrapInTwoLines">
                <ion-text
                  >{{
                    hyperCategory.name !== "others"
                      ? hyperCategory.name
                      : ("others" | translate)
                  }}
                </ion-text>
              </div>
            </ion-label>

            <ion-icon
              slot="end"
              style="font-size: 20px; margin-left: 5px"
              src="assets/ionicons/chevron-forward-outline.svg"
            >
            </ion-icon>
          </ion-item>
        </ion-grid>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid
    *ngIf="storeInfo && step === 'categoriesSelection'"
    (click)="closeModal()"
    style="height: 100%; width: 100%"
  >
    <ion-row style="width: 100%" *ngIf="!categories"
      ><ion-col class="ion-text-center"> <ion-spinner></ion-spinner> </ion-col
    ></ion-row>
    <ion-row style="width: 100%" class="ion-align-items-center">
      <ion-col
        class="ion-text-center ion-no-padding"
        size="6"
        *ngFor="let category of categories; trackBy: trackFn"
      >
        <ion-grid
          *ngIf="storeInfo.mobileorder_icons_categories_design"
          style="width: 165px; min-height: 160px"
          class="ion-no-padding"
        >
          <ion-card
            (click)="categorySelection(category)"
            style="
              z-index: 1000;
              height: 150px;
              display: grid;
              border-radius: 8px;
            "
            button="true"
            color="primary"
          >
            <ion-grid style="height: 100%" class="ion-no-padding">
              <ion-row
                style="height: 100%; width: 100%"
                class="ion-align-items-center ion-justify-content-center"
              >
                <ion-col size="12" class="ion-align-self-center">
                  <ion-icon
                    style="font-size: 50px; margin: 0"
                    [src]="
                      hyperCategoryIconsLink +
                      category.mobileorder_category_icon
                    "
                  ></ion-icon>
                  <div style="margin-top: 10px">
                    <ion-text style="font-size: small; font-weight: bold"
                      >{{ category.name }}
                    </ion-text>
                  </div>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card>
        </ion-grid>
        <ion-grid *ngIf="!storeInfo.mobileorder_icons_categories_design">
          <ion-item
            (click)="categorySelection(category)"
            style="z-index: 1000; border-radius: 8px"
            button="true"
            color="primary"
            detail="false"
          >
            <ion-label
              class="ion-text-wrap"
              style="font-size: small; font-weight: bold"
            >
              <div class="wrapInTwoLines">
                <ion-text>{{ category.name }} </ion-text>
              </div>
            </ion-label>

            <ion-icon
              slot="end"
              style="font-size: 20px; margin-left: 5px"
              src="assets/ionicons/chevron-forward-outline.svg"
            >
            </ion-icon>
          </ion-item>
        </ion-grid>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
