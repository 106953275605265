import { Component, OnDestroy, OnInit } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import { Subscription } from "rxjs";
import {
  auditTime,
  debounceTime,
  distinctUntilChanged,
  first,
  last,
  take,
} from "rxjs/operators";
import { SelectStoreComponent } from "../payment-cards/modals/select-store/select-store.component";
import { SelectStorePopupComponent } from "../popups/select-store-popup/select-store-popup.component";
import * as fromApp from "../store/app.reducer";
import * as DeliveryMethodAddressActions from "../store/deliveryMethodAddress/deliveryMethodAddress.actions";
import * as selectors from "../store/app.selectors";
import { ValidatingCartService } from "../services/validating-cart/validating-cart.service";
import * as CompleteOrderValidationActions from "../store/complete-order-validation/completeOrderValidation.actions";
import * as DisableFinalPriceActions from "../store/disable-final-price/disable-final-price.actions";
import { AnalyticsServiceService } from "../services/analitycsService/analytics-service.service";
import { CalculateFinalPriceService } from "../services/calculateFinalPrice/calculate-final-price.service";
import { SelectedAddressService } from "../services/selectedAddress/selected-address.service";
import { LoginPopupsServiceService } from "../services/login-popups-service/login-popups-service.service";
import { PaymentGatewaysService } from "../services/payment-gateways/payment-gateways.service";
import { Router } from "@angular/router";
import { OrderNowModalPopupsService } from "../services/orderNowModalPopupsService/orderNowModalPopups.service";
import * as storesActions from "../store/stores/stores.actions";
import { DataStorageService } from "../services/data-storage/data-storage.service";
import { CartPageAlertsService } from "../services/cartPageAlerts/cart-page-alerts.service";
import moment from "moment";

@Component({
  selector: "app-cart",
  templateUrl: "./cart.page.html",
  styleUrls: ["./cart.page.scss"],
})
export class CartPage implements OnDestroy {
  public currentView: string;
  public windowDimensions: any;
  public groupApp: any;
  public isIos: any;
  public pendingOrders: any;
  public stores: any;

  private store_info: any;
  private orderPickup: any;
  private lastValidTimestamp: any;
  private cartProducts: any;
  private subscriptions: Subscription[] = [];
  private unsubscribeBackEvent: Subscription;
  private currentStoreId: string;
  private selectStoreModal: any;

  constructor(
    private store: Store<fromApp.AppState>,
    private modalCtrl: ModalController,
    private cartValidationService: ValidatingCartService,
    private platform: Platform,
    private analyticsService: AnalyticsServiceService,
    private calculateFinalPriceService: CalculateFinalPriceService,
    private addressService: SelectedAddressService,
    private loginPopupsService: LoginPopupsServiceService,
    private paymentGatewaysService: PaymentGatewaysService,
    private cartAlertsService: CartPageAlertsService,
    private router: Router,
    private orderNowModalPopupsService: OrderNowModalPopupsService,
    private dataStorageService: DataStorageService
  ) {}

  ionViewDidEnter() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      100,
      () => {
        if (this.cartAlertsService.getActivatePendingOrderAlert()) {
          this.cartAlertsService.closePendingOrderAlert();
        } else if (this.cartAlertsService.getActivateCouponAlert()) {
          this.cartAlertsService.closeActivateCouponAlert();
        } else if (this.loginPopupsService.checkMobileMagicLoginIsOpen()) {
          this.loginPopupsService.closeMobileMagicLoginAlert();
        } else {
          this.navigateToCatalog();
        }
      }
    );
    this.isIos = this.platform.is("ios");
    this.analyticsService.fbqTrackPageView();
    this.analyticsService.setCurrentScreenFirebase("cart");
    this.store.dispatch(
      new DisableFinalPriceActions.SetTriggerFinalPriceTimestamp(
        Date.now().toString()
      )
    );
    this.store
      .select("deliveryMethodAddress")
      .pipe(first())

      .subscribe((state) => {
        console.log("state address outside if");
        if (state.selectedAddress) {
          console.log(
            "state address inside",
            state.selectedAddress.formatted_address
          );
          let selectedAddress = _.cloneDeep(state.selectedAddress);

          this.store.dispatch(
            new DeliveryMethodAddressActions.setOpenNotValidAddressModal(true)
          );

          this.store.dispatch(
            new DeliveryMethodAddressActions.setCheckSelectedAddress(
              _.cloneDeep(selectedAddress)
            )
          );
        }
      })
      .unsubscribe();

    this.subscriptions.push(
      this.store
        .select("pendingOrders")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((state) => {
          if (
            state &&
            state.pendingOrders &&
            !_.isEqual(state.pendingOrders, this.pendingOrders)
          ) {
            //filter orders that is in panding payment status for 30 minutes because there is a bug that not canceling order sometimes!
            const pendingOrdersFilter = _.filter(
              _.cloneDeep(state.pendingOrders) || [],
              (order) => {
                if (
                  !(
                    order &&
                    order.status === "pendingPayment" &&
                    order.timestamp &&
                    moment().diff(
                      moment(parseInt(order.timestamp.toString())),
                      "minutes"
                    ) > 35
                  )
                ) {
                  return order;
                }
              }
            );
            this.pendingOrders = _.cloneDeep(pendingOrdersFilter);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("completeOrderValidation")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.lastValidTimestamp &&
            !_.isEqual(this.lastValidTimestamp, state.lastValidTimestamp)
          ) {
            this.lastValidTimestamp = _.cloneDeep(state.lastValidTimestamp);
            this.cartValidationService.validateCartDebounce();
            this.completeOrderCartValidation();
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.windowDimensions &&
            !_.isEqual(this.windowDimensions, state.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);

            if (this.windowDimensions && this.windowDimensions.width) {
              let width = this.windowDimensions.width;
              if (width > 1050) {
                this.currentView = "cartBrowserLg";
                document.documentElement.style.setProperty(
                  "--cartGridMaxWidth",
                  "1100px"
                );
              } else if (width >= 600 && width <= 1050) {
                this.currentView = "cartBrowserMd";
                document.documentElement.style.setProperty(
                  "--cartGridMaxWidth",
                  "600px"
                );
              } //else if(){} TO-DO if we have cart small page goes here
              else if (width < 600) {
                this.currentView = "cartMobile";
              }
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("cart")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && !_.isEqual(this.cartProducts, state.products)) {
            this.cartProducts = _.cloneDeep(state.products);
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && !_.isEqual(this.groupApp, state.groupApp)) {
            this.groupApp = _.cloneDeep(state.groupApp);
            if (this.store_info) {
              this.checkStoreOnline();
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("deliveryMethodAddress")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && !_.isEqual(this.orderPickup, state.orderPickup)) {
            console.log("order pickupChanged");
            this.orderPickup = _.cloneDeep(state.orderPickup);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((state) => {
          if (state && state.stores && !_.isEqual(state.stores, this.stores)) {
            this.stores = _.cloneDeep(this.stores);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .subscribe((store_info) => {
          if (store_info && !_.isEqual(this.store_info, store_info)) {
            console.log("store info changed");
            this.store_info = _.cloneDeep(store_info);
            if (this.groupApp) {
              this.checkStoreOnline();
            }
          }
        })
    );

    this.analyticsService.dmAnalyticsMessage(
      "cart_loaded",
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
  }

  completeOrderCartValidation() {
    let validationObject = { id: "cart", valid: true, errorMessages: [] };
    if (
      !this.cartProducts ||
      (this.cartProducts && this.cartProducts.length <= 0)
    ) {
      validationObject.errorMessages.push("cart.please-add-products-to-cart");
      validationObject.valid = false;
    }
    this.store.dispatch(
      new CompleteOrderValidationActions.AddUpdateCompleteOrderValidation(
        _.cloneDeep(validationObject)
      )
    );
  }

  navigateToOrdersPage(store_id, order_id) {
    this.analyticsService.dmAnalyticsMessage(
      "pending_order_cart_button_pressed",
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
    if (store_id && order_id) {
      this.router.navigateByUrl("/orders/" + store_id + "/" + order_id, {
        replaceUrl: true,
      });
    } else {
      this.router.navigateByUrl("/orders", {
        replaceUrl: true,
      });
    }
  }

  async presentSelectStoreModal() {
    if (this.groupApp && this.groupApp.images_select_store_mobileorder) {
      this.selectStoreModal = await this.modalCtrl.create({
        component: SelectStorePopupComponent,
        cssClass: "selectStoreImagePopup",
        backdropDismiss: false,
      });

      await this.selectStoreModal.present();
      this.selectStoreModal.onDidDismiss().then(() => {
        this.selectStoreModal = null;
      });
    } else {
      let cssClass = "selectStorePopupCss";
      if (this.stores && this.stores.length > 1 && this.stores.length <= 3) {
        cssClass = "selectStorePopupCssThreeStores";
      } else if (this.stores && this.stores.length > 3) {
        cssClass = "selectStorePopupCssMultipleStores";
      }
      this.selectStoreModal = await this.modalCtrl.create({
        component: SelectStorePopupComponent,
        cssClass: cssClass,
        backdropDismiss: false,
        componentProps: {},
      });

      await this.selectStoreModal.present();
      this.selectStoreModal.onDidDismiss().then((data) => {
        this.selectStoreModal = null;
        //When you select a store calling selectStoreToSaveCard in order to checkPaymentGatewaysPopup
        if (data && data.data) {
        }
      });
    }
  }

  ngOnDestroy() {
    console.log("cart page component destroyed");
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }

  private navigateToCatalog() {
    if (this.platform.is("android")) {
      this.router.navigateByUrl("/catalog", {
        replaceUrl: true,
      });
    }
  }

  navigate() {
    this.router.navigateByUrl("/catalog", {
      replaceUrl: true,
    });
  }

  checkStoreOnline() {
    this.store
      .select("groupApp")
      .pipe(first())
      .subscribe((state) => {
        if (state && state.groupApp) {
          this.store
            .select(selectors.getStoreInfo)
            .pipe(first())
            .subscribe((store_info) => {
              if (store_info) {
                var checkStoreOnlineParams: any = {
                  languageCode: state.selectedLangugage || "el",
                };

                if (store_info.store_id && state.groupApp.group) {
                  checkStoreOnlineParams.store_id = _.cloneDeep(
                    store_info.store_id
                  );
                  checkStoreOnlineParams.group = _.cloneDeep(
                    state.groupApp.group
                  );
                }
                //Here I need to check every 5 minutes again instead of only checking once.

                this.dataStorageService
                  .checkStoreOnline(checkStoreOnlineParams)
                  .subscribe({
                    next: async (res: any) => {
                      this.store.dispatch(
                        new storesActions.SetCheckStoreInfo(_.cloneDeep(res))
                      );
                    },
                    error: (err) => {},
                  });
              }
            })
            .unsubscribe();
        }
      })
      .unsubscribe();
  }

  ionViewDidLeave() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    //this.paymentGatewaysService.closeAllModals();
    this.loginPopupsService.closeFirebaseModalLogin();
    this.loginPopupsService.closeConfirmationLoginModal();
    this.loginPopupsService.closeMobileMagicLoginAlert();
    this.addressService.closeAddressModals();
    this.orderNowModalPopupsService.closeFirebaseRecaptchaModal();
    this.orderNowModalPopupsService.closeNewAddressModal();
    this.orderNowModalPopupsService.closeSettingsModal();
    this.calculateFinalPriceService.closeProductDetailsModal();
    if (this.selectStoreModal) {
      this.selectStoreModal.dismiss();
      this.selectStoreModal = null;
    }
    this.store.dispatch(
      new CompleteOrderValidationActions.ClearCompleteOrderValidation()
    );
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }
}
