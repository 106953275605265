import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { auditTime, distinctUntilChanged, first, Subscription } from "rxjs";
import * as fromApp from "../../store/app.reducer";
import * as _ from "lodash";
import * as selectors from "../../store/app.selectors";
import { VERSION } from "../../../environments/version";
import {
  AlertController,
  LoadingController,
  ModalController,
  Platform,
} from "@ionic/angular";
@Component({
  selector: 'app-send-reservation-info-popup',
  templateUrl: './send-reservation-info-popup.component.html',
  styleUrls: ['./send-reservation-info-popup.component.scss'],
})
export class SendReservationInfoPopupComponent implements OnInit {
  @Input() reservation;
  @Input() lang;
  @Input() seats;
  @Input() disable_seats;
  @Input() without_table;

  public generalComments: any;
  public loading: boolean;
  private storesSub: Subscription;
  private all_stores: any;
  private store_id_sub: Subscription;
  private current_store_id: any;
  private storeAccount: any;
  public formQuestions: any = [];
  public formAnswers: any = {};
  public prepay_per_person;

  private unsubscribeBackEvent: Subscription;

  constructor(
    private platform: Platform,
    private modalController: ModalController,
    private store: Store<fromApp.AppState>
  ) {}

  ngOnInit() {
    if (!this.seats){
      this.seats = "-";
    }

    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      300,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );

    this.storesSub = this.store.select("stores").subscribe((state) => {
      if (state && state.stores && !_.isEqual(state.stores, this.all_stores)) {
        this.all_stores = _.cloneDeep(state.stores);
        this.get_store_account();
      }
    });

    this.store_id_sub = this.store.select("currentStore").subscribe((state) => {
      if (state && state.currentStoreId && !_.isEqual(state.currentStoreId, this.current_store_id)) {
        this.current_store_id = _.cloneDeep(state.currentStoreId);
        this.get_store_account();
      }
    });
  }

  get_store_account() {
    if (!this.current_store_id || _.isEmpty(this.all_stores)) {
      return;
    }

    const found = _.find(
      this.all_stores,
      (store) => store.store_id === this.current_store_id
    );
    if (found) {
      this.storeAccount = _.cloneDeep(found);
      this.prepay_per_person = this.storeAccount.reservationPrepayPerPerson;
      this.formQuestions = _.filter(this.storeAccount.reservationFormFields, item => item.active && item.type === "text");
      if (this.reservation.formAnswers) {
        _.forEach(this.reservation.formAnswers, item => {
          const index = _.findIndex(this.formQuestions, (question: any) => question.id === item.id);
          if (index !== -1) {
            this.formAnswers[item.id] = item.answer;
          }
        });
      }
      // console.log("FORM QUESTIONS", this.formQuestions, this.storeAccount);
      // console.log("FORM ANSWERS", this.formAnswers, this.reservation);
    }
  }

  // changeChoiceQuantity(ev) {
  //   if (ev && ev.role === "increase") {
  //     if (this.seats === "-"){
  //       this.seats = this.reservation.seats;
  //     } else if (parseInt(this.seats) >= parseInt(this.reservation.max_seats)){
  //       this.seats = this.reservation.max_seats.toString();
  //     } else {
  //       this.seats = (parseInt(this.seats) + 1).toString();
  //     }
  //   } else if (ev && ev.role === "decrease") {
  //     if (this.seats === "-"){
  //       return;
  //     } else if (parseInt(this.seats) <= parseInt(this.reservation.seats)){
  //       this.seats = this.reservation.seats.toString();
  //     } else {
  //       this.seats = (parseInt(this.seats) - 1).toString();
  //     }
  //   } else if (ev && ev.role === "quantity_value") {
  //     const seats = parseInt(ev.value);

  //     //console.log(seats, this.reservation.seats, this.reservation.max_seats);
  //     if (seats > parseInt(this.reservation.max_seats)){
  //       this.seats = this.reservation.max_seats.toString();
  //     } else if (seats < parseInt(this.reservation.seats)){
  //       this.seats = this.reservation.seats.toString();
  //     } else {
  //       this.seats = seats.toString();
  //     }
  //   }
  // }

  continueToPayment() {
    let formAnswers = [];
    _.forEach(this.formAnswers, (text, key) => {
      formAnswers.push({
        id: key,
        answer: text
      });
    });
    if (_.isEmpty(formAnswers)) {
      formAnswers = null;
    }
    this.modalController.dismiss({
      seats: _.cloneDeep(this.seats),
      formAnswers: _.cloneDeep(formAnswers)
    }, "continue");
  }

  closeModal() {
    this.modalController.dismiss();
  }

  ngOnDestroy(): void {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.storesSub) {
      this.storesSub.unsubscribe();
    }
    if (this.store_id_sub) {
      this.store_id_sub.unsubscribe();
    }
  }
}
