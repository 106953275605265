import { Action } from "@ngrx/store";

export const SET_SELECTED_INVOICE = "[SELECTED INVOICE] Set Selected Invoice";
export const CLEAR_SELECTED_INVOICE =
  "[SELECTED INVOICE] Clear Selected Invoice";
export const INVOICE_REQUIRED_OPEN_MODAL =
  "[SELECTED INVOICE] Set Invoice Required Open Modal";

export class SetSelectedInvoice implements Action {
  readonly type = SET_SELECTED_INVOICE;
  constructor(public payload: any) {}
}

export class SetInvoiceRequiredOpenModal implements Action {
  readonly type = INVOICE_REQUIRED_OPEN_MODAL;
  constructor(public payload: any) {}
}

export class ClearSelectedInvoice implements Action {
  readonly type = CLEAR_SELECTED_INVOICE;
  constructor() {}
}

export type SelectedInvoiceActions =
  | SetSelectedInvoice
  | SetInvoiceRequiredOpenModal
  | ClearSelectedInvoice;
