import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ModalController } from "@ionic/angular";
import { ProductDetailsComponent } from "src/app/product-details/product-details.component";
declare let window: any;

@Component({
  selector: "app-product-item-popular-category",
  templateUrl: "./product-item-popular-category.component.html",
  styleUrls: ["./product-item-popular-category.component.scss"],
})
export class ProductItemPopularCategoryComponent implements OnInit, OnDestroy {
  @ViewChild("productTitle") productTitleRef: ElementRef;
  @Input() product: any;
  @Input() category: any;
  @Input() groupStoreData: any;
  @Input() dineIn: any;
  @Input() orderPickup: any;
  @Input() selectedDineInTable: any;
  @Input() webp: any;

  public productDetailsModal: any;

  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {}

  prdWithoutImage(product) {
    return (
      !product.image ||
      (product.image && !product.image.true) ||
      (product.image && !product.image.true["16x9"]) ||
      (product.image &&
        product.image.true["16x9"] &&
        !product.image.true["16x9"].path)
    );
  }

  showProductDetailsModal(product) {
    let detailsCssClass;
    if (
      product &&
      product.num_of_options &&
      product.num_of_options < 2 &&
      this.prdWithoutImage(product)
    ) {
      detailsCssClass = "productDetailsModalSmall";
    } else if (
      product &&
      product.num_of_options &&
      !product.squareImageDesign &&
      product.num_of_options > 2 &&
      !this.prdWithoutImage(product)
    ) {
      detailsCssClass = "productDetailsModalBig";
    } else if (
      product &&
      product.squareImageDesign &&
      product.num_of_options &&
      product.num_of_options > 2 &&
      window &&
      window.innerWidth &&
      window.innerWidth >= 1200 &&
      !this.prdWithoutImage(product)
    ) {
      detailsCssClass = "productDetailsModalBigSquareImageDesign";
    } else {
      detailsCssClass = "productDetailsModal";
    }
    if (!this.productDetailsModal) {
      this.productDetailsModal = true;
      this.presentProductDetailsModal(product, detailsCssClass);
    }
  }

  async presentProductDetailsModal(product, detailsCssClass) {
    let product_id: string;
    let cart_id: string;
    if (product && product.product_id) {
      product_id = product.product_id;
    }

    if (product && product.cart_id) {
      cart_id = product.product_id;
    }

    this.productDetailsModal = await this.modalCtrl.create({
      component: ProductDetailsComponent,
      cssClass: detailsCssClass,
      animated: true,
      componentProps: {
        product_id: product_id,
        cart_id: cart_id,
      },
      backdropDismiss: false,
    });

    await this.productDetailsModal.present();
    this.productDetailsModal.onDidDismiss().then(() => {
      this.productDetailsModal = null;
    });
  }

  ngOnDestroy(): void {
    if (this.productDetailsModal) {
      this.productDetailsModal.dismiss();
      this.productDetailsModal = null;
    }
  }
}
