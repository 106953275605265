import * as LoyaltySystemActions from "./loyalty-system.actions";
import * as _ from "lodash";

export interface State {
  campaignsArray: any;
  customerCoupons: any;
  currentLoyaltyStoreId: string;
  userLoyaltyProducts: any;
  unableToLoadUserLoyaltyProducts: boolean;
  couponIsLoading: any;
  userLoyaltyProductsHistory: any;
  hallOfFame: any;
  userRanking: any;
  activeContestDraws: any;
  groupContests: any;
  latestContestWinners: any;
}

const initialState: State = {
  campaignsArray: null,
  customerCoupons: null,
  currentLoyaltyStoreId: null,
  userLoyaltyProducts: null,
  unableToLoadUserLoyaltyProducts: null,
  couponIsLoading: null,
  userLoyaltyProductsHistory: null,
  hallOfFame: null,
  userRanking: null,
  activeContestDraws: null,
  groupContests: null,
  latestContestWinners: null,
};

export function loyaltySystemReducer(
  state = initialState,
  action: LoyaltySystemActions.LoyaltySystemActions
) {
  switch (action.type) {
    case LoyaltySystemActions.SET_LOYALTY_CAMPAIGNS:
      return {
        ...state,
        campaignsArray: action.payload,
      };
    case LoyaltySystemActions.SET_USER_RANKING:
      return {
        ...state,
        userRanking: action.payload,
      };
    case LoyaltySystemActions.SET_USER_LOYALTY_PRODUCTS:
      return {
        ...state,
        userLoyaltyProducts: _.unionBy(
          state.userLoyaltyProducts,
          action.payload,
          "timestamp"
        ),
      };
    case LoyaltySystemActions.SET_USER_LOYALTY_PRODUCTS_HISTORY:
      return {
        ...state,
        userLoyaltyProductsHistory: _.unionBy(
          state.userLoyaltyProductsHistory,
          action.payload,
          "timestamp"
        ),
      };
    case LoyaltySystemActions.SET_HALL_OF_FAME:
      return {
        ...state,
        hallOfFame: action.payload,
      };
    case LoyaltySystemActions.SET_ACTIVE_CONTEST_CUSTOMER_DRAWS:
      return {
        ...state,
        activeContestDraws: action.payload,
      };
    case LoyaltySystemActions.SET_GROUP_CONTESTS:
      return {
        ...state,
        groupContests: action.payload,
      };
    case LoyaltySystemActions.SET_LATEST_CONTEST_WINNERS:
      return {
        ...state,
        latestContestWinners: action.payload,
      };
    case LoyaltySystemActions.CLEAR_USER_LOYALTY_PRODUCTS_HISTORY:
      return {
        ...state,
        userLoyaltyProductsHistory: null,
      };
    case LoyaltySystemActions.ADD_UPDATE_USER_LOYALTY_PRODUCTS_FROM_SOCKET:
      if (state && state.userLoyaltyProducts) {
        const index = _.findIndex(state.userLoyaltyProducts, (product: any) => {
          return product.timestamp === action.payload.timestamp;
        });
        if (index !== -1) {
          const updatedProduct = {
            ...state.userLoyaltyProducts[index],
            ...action.payload,
          };
          const updatedProducts = [...state.userLoyaltyProducts];
          updatedProducts[index] = updatedProduct;
          return {
            ...state,
            userLoyaltyProducts: updatedProducts,
          };
        } else {
          return {
            ...state,
            userLoyaltyProducts: _.orderBy(
              _.unionBy(
                [...state.userLoyaltyProducts, action.payload],
                "timestamp"
              ),
              ["timestamp"],
              ["desc"]
            ),
          };
        }
      } else {
        return state;
      }
    case LoyaltySystemActions.UPDATE_USER_TOTAL_PRODUCTS_FROM_SOCKET:
      if (state && state.userRanking) {
        const updatedRanking = _.cloneDeep(state.userRanking);
        if (action.payload.total_products) {
          updatedRanking.total_products = action.payload.total_products;
        }
        return {
          ...state,
          userRanking: updatedRanking,
        };
      } else {
        return state;
      }
    case LoyaltySystemActions.UPDATE_USER_RANK_FROM_SOCKET:
      if (state && state.userRanking) {
        const updatedRanking = _.cloneDeep(state.userRanking);
        if (action.payload) {
          updatedRanking.rank = action.payload;
        }
        return {
          ...state,
          userRanking: updatedRanking,
        };
      } else {
        return state;
      }
    case LoyaltySystemActions.REMOVE_USER_LOYALTY_PRODUCT_FROM_SOCKET:
      if (!state.userLoyaltyProducts || _.isEmpty(state.userLoyaltyProducts)) {
        return state;
      }
      return {
        ...state,
        userLoyaltyProducts: state.userLoyaltyProducts.filter((product) => {
          return product.timestamp !== action.payload.timestamp;
        }),
      };
    case LoyaltySystemActions.SET_UNABLE_TO_LOAD_USER_LOYALTY_PRODUCTS:
      return {
        ...state,
        unableToLoadUserLoyaltyProducts: action.payload,
      };
    case LoyaltySystemActions.SET_LOYALTY_COUPONS:
      return {
        ...state,
        customerCoupons: action.payload,
      };
    case LoyaltySystemActions.CLEAR_LOYALTY_COUPONS:
      return {
        ...state,
        customerCoupons: null,
      };
    case LoyaltySystemActions.SET_IS_COUPON_LOADING:
      return {
        ...state,
        couponIsLoading: action.payload,
      };
    case LoyaltySystemActions.SET_CURRENT_LOYALTY_STORE_ID:
      return {
        ...state,
        currentLoyaltyStoreId: action.payload,
      };

    case LoyaltySystemActions.CLEAR_CURRENT_LOYALTY_STORE_ID:
      return {
        ...state,
        currentLoyaltyStoreId: null,
      };

    default:
      return state;
  }
}
