import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { AlertController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as FromApp from "../../../store/app.reducer";
import * as _ from "lodash";
import * as LoyaltySystemActions from "../../store/loyalty-system.actions";

@Component({
  selector: "app-loyalty-coupon-item",
  templateUrl: "./loyalty-coupon-item.component.html",
  styleUrls: ["./loyalty-coupon-item.component.scss"],
})
export class LoyaltyCouponItemComponent implements OnInit, OnDestroy {
  @Input() coupon;
  couponIsLoading: any;

  showSpinner: boolean;
  sub: any;
  badgeColor: string;
  constructor(
    private alertController: AlertController,
    private translateService: TranslateService,
    private store: Store<FromApp.AppState>
  ) {}

  ngOnInit() {
    this.sub = this.store.select("loyaltySystem").subscribe((state) => {
      if (state && !_.isEqual(this.couponIsLoading, state.couponIsLoading)) {
        this.couponIsLoading = _.cloneDeep(state.couponIsLoading);
      }
    });

    if (this.coupon) {
      // console.log("coupon exists");
      if (this.coupon.status === "used") {
        this.badgeColor = "used-coupon";
        ///console.log("set used coupon");
      } else if (this.coupon.status === "active") {
        this.badgeColor = "active-coupon";
        //console.log("set active coupon");
      } else if (this.coupon.status === "expired") {
        this.badgeColor = "expired-coupon";
        //console.log("set exp coupon");
      } else if (this.coupon.status === "outdated") {
        this.badgeColor = "expired-coupon";
        //console.log("set exp coupon");
      } else if (this.coupon.status === "canceled") {
        this.badgeColor = "used-coupon";
        //console.log("set exp coupon");
      } else if (this.coupon.status === "pending_confirmation") {
        this.badgeColor = "warning";
        //console.log("set exp coupon");
      }
    }
  }

  async presentRedeemAlert() {
    const redeemAlert = await this.alertController.create({
      header: this.translateService.instant("alert"),
      message:
        this.translateService.instant("loyaltySystem.redeem-alert") +
        ": " +
        this.translateService.instant(this.coupon.use_type),
      backdropDismiss: false,
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: () => {
            console.log("Confirm Cancel");
          },
        },
        {
          text: "OK",
          handler: () => {
            this.redeemCoupon();
          },
        },
      ],
    });
    await redeemAlert.present();
  }

  redeemCoupon() {
    this.showSpinner = true;
    // TODO call to api
    this.store.dispatch(new LoyaltySystemActions.SetIsCouponLoading(true));
    setTimeout(() => {
      this.store.dispatch(new LoyaltySystemActions.SetIsCouponLoading(false));
      this.showSpinner = false;
    }, 1000);
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
