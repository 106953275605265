<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small" *ngIf="option">
      {{ option.title }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()" color="dark" fill="clear"
        ><ion-icon src="assets/ionicons/close.svg"></ion-icon
      ></ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="windowDimensions && windowDimensions.width < 600">
  <app-radio-choices
    *ngIf="option && option.choices && option.choices.length"
    [option_id]="option_id"
    [selectedProductPromo]="selectedProductPromo"
    [selectedProductCategory]="selectedProductCategory"
    [bundleMode]="bundleMode"
    [selectedProductId]="selectedProductId"
    [alt_dinein_active]="alt_dinein_active"
    [groupStoreData]="groupStoreData"
    (choicesChanged)="optionChanged($event)"
  ></app-radio-choices>
</ion-content>

<div
  class="inner-content"
  *ngIf="windowDimensions && windowDimensions.width >= 600"
>
  <app-radio-choices
    *ngIf="option && option.choices && option.choices.length"
    [option_id]="option_id"
    [selectedProductPromo]="selectedProductPromo"
    [selectedProductCategory]="selectedProductCategory"
    [bundleMode]="bundleMode"
    [selectedProductId]="selectedProductId"
    [alt_dinein_active]="alt_dinein_active"
    [groupStoreData]="groupStoreData"
    (choicesChanged)="optionChanged($event)"
  ></app-radio-choices>
</div>
