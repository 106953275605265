import * as ReservationsActions from "./reservations.actions";
import * as _ from "lodash";

export interface State {
  reservations: any;
  reservationSelectedDate: { date: string; hour: string } | null;
  reservationsFetchTimestamp: any;
}

const initialState: State = {
  reservations: null,
  reservationSelectedDate: null,
  reservationsFetchTimestamp: null,
};

export function reservationsReducer(
  state = initialState,
  action: ReservationsActions.ReservationsActions
): State {
  switch (action.type) {
    case ReservationsActions.SET_RESERVATIONS:
      return {
        ...state,
        reservations: action.payload,
      };

    case ReservationsActions.SET_RESERVATION_SELECTED_DATE:
      return {
        ...state,
        reservationSelectedDate: action.payload,
      };

    case ReservationsActions.SET_FETCH_RESERVATIONS_TIMESTAMP:
      return {
        ...state,
        reservationsFetchTimestamp: action.payload,
      };

    case ReservationsActions.CLEAR_RESERVATIONS:
      return {
        ...state,
        reservations: null,
      };
    case ReservationsActions.UPDATE_RESERVATION_FROM_SOCKET:
      if (!state.reservations) {
        return state;
      }
      const reservations = [...state.reservations];
      const resIndex = _.findIndex(reservations, (res) => {
        return res.res_id === action.payload.res_id;
      });
      if (resIndex !== -1) {
        reservations[resIndex] = {
          ...reservations[resIndex],
          ...action.payload,
        };
      } else {
        reservations.unshift(action.payload);
      }
      return {
        ...state,
        reservations: reservations,
      };
    case ReservationsActions.UPDATE_RESERVATION_FROM_ORDER_SOCKET:
      if (!state.reservations) {
        return state
      }
      let updatedReservations : any[] = _.cloneDeep(state.reservations);
      const resIndex1 = _.findIndex(updatedReservations, res => {
        return res.order_id === action.payload.order_id
      });
      if (resIndex1 !== -1) {
        const obj = {
          selectedPaymentMethodID: action.payload.selectedPaymentMethodID,
          order_status: action.payload.status,
          grand_total: action.payload.grand_total,
          payment_id: action.payload.payment_id || "",
          kiosk_one_time_password: action.payload.kiosk_one_time_password || "",
          kiosk_expiration_timestamp: action.payload.kiosk_expiration_timestamp || "",
          order_updated_timestamp: action.payload.updated_timestamp || "",
          order_timestamp: action.payload.timestamp
        };

        updatedReservations[resIndex1] = _.assign(updatedReservations[resIndex1], obj);
      };
      return {
        ...state,
        reservations: updatedReservations
      }
    default:
      return state;
  }
}
