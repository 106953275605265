import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import * as _ from "lodash";
import * as fromApp from "../store/app.reducer";

@Component({
  selector: "app-ionic-update-error",
  templateUrl: "./ionic-update-error.component.html",
  styleUrls: ["./ionic-update-error.component.scss"],
})
export class IonicUpdateErrorComponent implements OnInit {
  private subscription: Subscription;
  ionicUpdateErrorFlag: string = null;
  internetConnectedFlag: boolean = true;
  constructor(private store: Store<fromApp.AppState>) {}

  ngOnInit() {
    this.subscription = this.store
      .select("auth")
      .pipe(distinctUntilChanged())
      .pipe(debounceTime(300))
      .subscribe((state) => {
        if (
          state &&
          !_.isEqual(state.ionicUpdateErrorFlag, this.ionicUpdateErrorFlag)
        ) {
          this.ionicUpdateErrorFlag = _.cloneDeep(state.ionicUpdateErrorFlag);
          if (this.ionicUpdateErrorFlag && navigator.cookieEnabled) {
            localStorage.setItem(
              "ionicUpdateErrorFlag",
              this.ionicUpdateErrorFlag.toString()
            );
          }
        }

        if (
          state &&
          !_.isEqual(state.internetConnectedFlag, this.internetConnectedFlag)
        ) {
          this.internetConnectedFlag = _.cloneDeep(state.internetConnectedFlag);
        }
      });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
