<ion-header>
  <app-no-internet-connection></app-no-internet-connection>
  <app-ionic-update-error></app-ionic-update-error>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>
    <ion-title>{{"pages.alergens"| translate}}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid>
    <ion-row *ngIf="!alergens">
      <ion-col class="ion-text-center">
        <ion-spinner></ion-spinner>
      </ion-col>
    </ion-row>
    <div *ngIf="alergens" [innerHTML]="alergens"></div>
  </ion-grid>
</ion-content>
