<ion-button
  color="black"
  fill="clear"
  (click)="closeModal()"
  style="position: absolute; top: -10px; right: 0px"
>
  <ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
</ion-button>
<div *ngIf="showPiraeusSpinner" style="width: 100%" align="center">
  <ion-spinner></ion-spinner>
</div>
<iframe
  *ngIf="piraeus_3ds_url"
  style="padding: 0; border-width: 0px"
  [width]="frame_width + 'px'"
  [height]="frame_height + 'px'"
  [src]="piraeus_3ds_url"
>
</iframe>
