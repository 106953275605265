import { Pipe, PipeTransform } from "@angular/core";
import * as _ from "lodash";
@Pipe({
  name: "filterStoresSelection",
})
export class FilterStoresSelectionPipe implements PipeTransform {
  transform(storesArray: any, forValidStores: boolean, validStores: any): any {
    if (!forValidStores) {
      return _.filter(storesArray, (store) => {
        if (!store.disableOrderPickup && (!validStores || store.valid)) {
          return store;
        }
      });
    } else {
      return storesArray;
    }
  }
}
