import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { auditTime, distinctUntilChanged, first } from "rxjs/operators";
import * as fromApp from "../../store/app.reducer";
import * as selectors from "../../store/app.selectors";
import * as _ from "lodash";

import { MenuController } from "@ionic/angular";
import { Subscription } from "rxjs";

@Component({
  selector: "app-browser-catalog-lg",
  templateUrl: "./browser-catalog-lg.component.html",
  styleUrls: ["./browser-catalog-lg.component.scss"],
})
export class BrowserCatalogLgComponent implements OnInit, OnChanges, OnDestroy {
  @Output() listScrollFromBottomEv = new EventEmitter();
  @Output() listScrollFromTopEv = new EventEmitter();

  @Input() transparentCatalogElements: boolean;
  @Input() rerenderProductsItemsTimestamp: string | null;
  @Input() catalogInlineInfoMessage;
  @Input() motoCardsProgressValues;
  @Input() infoMessagesCardsHide;

  public categories: any;
  public currentView: string;
  public doNotShowProductsList: boolean = false;
  public productListColSize: any;
  public storeInfo: any;
  public icons_categories_design: any;
  public cartProducts;
  public hyperCategories: any;
  public loading;
  public allCategoriesMarketCardsDesign;

  private subscriptions: Subscription[] = [];
  private windowDimensions: any;

  constructor(private store: Store<fromApp.AppState>) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.rerenderProductsItemsTimestamp &&
      changes.rerenderProductsItemsTimestamp.previousValue &&
      changes.rerenderProductsItemsTimestamp.currentValue &&
      changes.rerenderProductsItemsTimestamp.previousValue !==
        changes.rerenderProductsItemsTimestamp.currentValue
    ) {
      this.doNotShowProductsList = true;
      setTimeout(() => {
        this.doNotShowProductsList = false;
      }, 300);
    }
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .subscribe((storeInfo) => {
          if (storeInfo && !_.isEqual(this.storeInfo, storeInfo)) {
            this.storeInfo = _.cloneDeep(storeInfo);
            this.checkForShowLoading();
            this.icons_categories_design = !!_.cloneDeep(
              this.storeInfo.mobileorder_icons_categories_design
            );
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("hyperCategories")
        .pipe(auditTime(100))
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.hyperCategories &&
            !_.isEqual(this.hyperCategories, state.hyperCategories)
          ) {
            this.hyperCategories = _.cloneDeep(state.hyperCategories);

            console.log(this.hyperCategories);
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("catalog")
        .pipe(auditTime(300))
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.catalog &&
            !_.isEqual(this.categories, state.catalog)
          ) {
            this.categories = _.cloneDeep(state.catalog);
            // console.log(this.categories);
          }

          if (
            state &&
            state.allCategoriesMarketCardsDesign &&
            !_.isEqual(
              this.allCategoriesMarketCardsDesign,
              state.allCategoriesMarketCardsDesign
            )
          ) {
            this.allCategoriesMarketCardsDesign = _.cloneDeep(
              state.allCategoriesMarketCardsDesign
            );
            // console.log(this.categories);
          }
        })
    );
  }

  scrollFromBottomEvent(scrollBottom) {
    this.listScrollFromBottomEv.emit(scrollBottom);
  }

  scrollFromTopEvent(scrollTop) {
    this.listScrollFromTopEv.emit(scrollTop);
  }
  checkForShowLoading() {
    this.store
      .select("groupApp")
      .pipe(first())
      .subscribe((groupState) => {
        if (groupState && groupState.groupApp.store_change_catalog_buttons) {
          this.loading = true;
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        }
      })
      .unsubscribe();
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }
}
