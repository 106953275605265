import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Loader } from "@googlemaps/js-api-loader";
import {
  AlertController,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import { Subscription, auditTime, distinctUntilChanged } from "rxjs";
import * as fromApp from "../../store/app.reducer";
import mapStyleJson from "../../../assets/mapStyle/mapStyle.json";
import mapStyleJsonDark from "../../../assets/mapStyle/mapStyleDark.json";
import { TranslateService } from "@ngx-translate/core";
import MarkerClusterer from "@googlemaps/markerclustererplus";
import { Geolocation } from "@capacitor/geolocation";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import * as selectors from "../../store/app.selectors";
import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";

declare let window: any;
declare global {
  interface Window {
    initMap: () => void;
  }
}

@Component({
  selector: "app-reservation-location-preview",
  templateUrl: "./reservation-location-preview.page.html",
  styleUrls: ["./reservation-location-preview.page.scss"],
})
export class ReservationLocationPreviewPage implements OnInit, OnDestroy {
  @Input() selectedStore;

  @Input() selectedStoreId: any;
  @Input() height: any;
  map: google.maps.Map;
  sub: any;
  groupApp: any;
  selectedLanguage: any;
  selectedTheme: any;
  store_markers: any[];
  infoMarkerInfoToast: any;

  closestStoreToCurrentPosition: any;
  loader: Loader;
  libraryLoaded: boolean = false;
  storeInfoSub: any;
  selectedStoreInfo: any;
  selectedStoreSchedule: any;
  unsubscribeBackEvent: any;
  private subscriptions: Subscription[] = [];

  constructor(
    private modalController: ModalController,
    private store: Store<fromApp.AppState>,
    private changeDetector: ChangeDetectorRef,
    private alertController: AlertController,
    private translateService: TranslateService,
    private toastController: ToastController,
    private dataStorageSevice: DataStorageService,
    private alertCtrl: AlertController,
    private platform: Platform,
    public modalCtrl: ModalController
  ) {}

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );

    if (!this.selectedStore) {
      this.storeInfoSub = this.store
        .select(selectors.getStoreInfo)
        .pipe()
        .subscribe((storeInfo) => {
          if (storeInfo) {
            console.log("storeINFooo", storeInfo);
            this.selectedStoreInfo = _.cloneDeep(storeInfo);
            if (this.selectedStoreInfo.loyaltyapp_schedule) {
              this.selectedStoreSchedule = this.formatStoreSchedule(
                this.selectedStoreInfo.loyaltyapp_schedule
              );
            }
          }
        });
    }

    // window.initMap = this.initMap();

    this.sub = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (
          state &&
          state.groupApp &&
          !_.isEqual(this.groupApp, state.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp);
        }
        this.selectedTheme = "light";
        if (
          state &&
          state.selectedLangugage &&
          !_.isEqual(this.selectedLanguage, state.selectedLangugage)
        ) {
          this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
          if (this.groupApp && this.selectedLanguage && this.selectedTheme) {
            console.log("inside map initialization 2");
            if (!document.getElementById("googleMapsLoader")) {
              this.loader = new Loader({
                id: "googleMapsLoader",
                apiKey: this.groupApp.googleMapsKey,
                language: this.selectedLanguage,
                libraries: ["geometry", "drawing", "places"],
              });
              this.loader.load().then(() => {
                console.log("library loaded");
                this.libraryLoaded = true;
                this.changeDetector.detectChanges();
                this.initializationSetAddress();
              });
            } else {
              this.libraryLoaded = true;
              this.changeDetector.detectChanges();
              this.initializationSetAddress();
            }
          }
        }
      });
  }

  openLink = async () => {
    const storeInfo = _.cloneDeep(this.selectedStore || this.selectedStoreInfo);
    if (
      storeInfo &&
      storeInfo.mapCenterStoreLat &&
      storeInfo.mapCenterStoreLng
    ) {
      //open link external on new page
      if (Capacitor.isNativePlatform()) {
        window.open(
          `https://www.google.com/maps/search/?api=1&query=${storeInfo.mapCenterStoreLat},${storeInfo.mapCenterStoreLng}`,
          "_system"
        );
      } else {
        window.open(
          `https://www.google.com/maps/search/?api=1&query=${storeInfo.mapCenterStoreLat},${storeInfo.mapCenterStoreLng}`,
          "_blank"
        );
      }
    }
  };

  initializationSetAddress() {
    console.log("initializationSetAddress");
    this.map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        zoom: parseInt(this.groupApp.groupMapZoom),
        gestureHandling: "greedy",
        center: {
          lat: parseFloat(this.groupApp.groupMapCenterLat),
          lng: parseFloat(this.groupApp.groupMapCenterLng),
        },

        zoomControl: true,
        styles:
          this.selectedTheme && this.selectedTheme === "dark"
            ? mapStyleJsonDark
            : mapStyleJson,
        scaleControl: false,
        minZoom: 19,
        scrollwheel: true,
        disableDefaultUI: true,
        mapTypeControl: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      }
    );

    // this.deliveryAreasInitialization(this.map);

    // this.checkMapZoomPresentInfo();
    this.store_markers = [];
    this.store_markers.push({
      marker: new google.maps.Marker({
        animation: google.maps.Animation.DROP,
        icon: "assets/ionicons/home2.png",

        position: {
          lat: this.selectedStore.mapCenterStoreLat
            ? parseFloat(this.selectedStore.mapCenterStoreLat) - 0.00003
            : 0,
          lng: this.selectedStore.mapCenterStoreLng
            ? parseFloat(this.selectedStore.mapCenterStoreLng)
            : 0,
        },
        map: this.map,
        anchorPoint: new google.maps.Point(0, -29),
        draggable: false,
      }),
      store_id: this.selectedStore.store_id,
      infoBox: new google.maps.InfoWindow({
        content:
          "<h6 style='padding:0;margin:2px;'>" +
          (this.selectedStore.store_panel_name_langs &&
          this.selectedStore.store_panel_name_langs[this.selectedLanguage]
            ? this.selectedStore.store_panel_name_langs[this.selectedLanguage]
            : this.selectedStore.store_panel_name_langs &&
              this.selectedStore.store_panel_name_langs.el
            ? this.selectedStore.store_panel_name_langs.el
            : this.selectedStore.store_name_panel
            ? this.selectedStore.store_name_panel
            : this.selectedStore.store_name
            ? this.selectedStore.store_name
            : "") +
          "</h6>" +
          "<p style='padding:0;margin:0px'>" +
          this.selectedStore.address +
          "</p>",
        disableAutoPan: true,
      }),
      infoWindowIsOpen: false,
    });

    let markers = [];
    _.each(this.store_markers, (marker) => {
      marker.marker.setVisible(true);
      markers.push(marker.marker);
      marker.marker.addListener("click", () => {
        this.onStoreMarkerClick(marker);
      });
    });
    console.log("storeMarkers", this.store_markers);
    const markerCluster = new MarkerClusterer(this.map, markers, {
      imagePath:
        "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
    });

    this.map.setCenter({
      lat: this.selectedStore.mapCenterStoreLat + 0.00008,
      lng: this.selectedStore.mapCenterStoreLng,
    });

    this.map.setZoom(18);

    this.changeDetector.detectChanges();
  }

  onStoreMarkerClick(marker) {
    if (!marker.infoBox.isOpened) {
      marker.infoBox.open(this.map, marker.marker);
      marker.infoWindowIsOpen = true;
    }

    this.setInfoMarkerAndListeners(marker.marker.getPosition(), false, false);

    this.changeDetector.detectChanges();
  }

  async confirmPositionToClosestStore(closestStore) {
    const alert = await this.alertCtrl.create({
      header: this.translateService.instant(
        "loyaltySystem.confirm-closest-store"
      ),
      subHeader: closestStore.store_name,
      message: this.translateService.instant(
        "loyaltySystem.confirm-closest-store-message"
      ),
      buttons: [
        {
          text: this.translateService.instant(
            "popups.logout-alert-popup.cancel"
          ),
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: this.translateService.instant("popups.logout-alert-popup.yes"),
          handler: () => {
            this.selectedStoreId = closestStore.store_id;
          },
        },
      ],
    });

    await alert.present();
  }

  setInfoMarkerAndListeners(position, onCenter, setMarkerMapCenter) {
    let positionOfMarker = position;

    if (!onCenter) {
      positionOfMarker = {
        lat: position.lat() - 0.004,
        lng: position.lng(),
      };
    }

    console.log("position", position);
    var icon = {
      url: "assets/flatIcons/info.png", // url
      scaledSize: new google.maps.Size(70, 70), // scaled size
    };
    if (setMarkerMapCenter) {
      this.map.set("center", positionOfMarker);
    }

    if (this.infoMarkerInfoToast) {
      this.infoMarkerInfoToast.dismiss();
      this.infoMarkerInfoToast = null;
    }

    this.map.setOptions({ draggable: true });

    this.changeDetector.detectChanges();
  }

  formatStoreSchedule(schedule) {
    var weekDays = [
      this.translateService.instant("store-schedule.sunday"),
      this.translateService.instant("store-schedule.monday"),
      this.translateService.instant("store-schedule.tuesday"),
      this.translateService.instant("store-schedule.wednesday"),
      this.translateService.instant("store-schedule.thursday"),
      this.translateService.instant("store-schedule.friday"),
      this.translateService.instant("store-schedule.saturday"),
    ];

    var singleScheduleArray = [];

    _.each(schedule, (scheduleDay, key, list) => {
      if (scheduleDay.active && !scheduleDay.all_day) {
        var commentSchedule = "";
        scheduleDay.time_periods.forEach((tp, i) => {
          if (i > 0) {
            commentSchedule += ", ";
          }
          commentSchedule +=
            this.toHHMMSS(tp.start) + " - " + this.toHHMMSS(tp.stop);
        });

        singleScheduleArray.push({
          day: weekDays[key],
          schedule: commentSchedule,
        });
      } else if (scheduleDay.active && scheduleDay.all_day) {
        singleScheduleArray.push({
          day: weekDays[key],
          schedule: this.translateService.instant("store-schedule.24-hours"),
        });
      } else {
        singleScheduleArray.push({
          day: weekDays[key],
          schedule: this.translateService.instant("store-schedule.closed"),
        });
      }
    });

    var sunday = _.head(singleScheduleArray);
    singleScheduleArray = _.takeRight(singleScheduleArray, 6);
    singleScheduleArray.push(sunday);
    console.log("singleScheduleArray", singleScheduleArray);
    return singleScheduleArray;
  }

  toHHMMSS(val) {
    console.log("val", val);
    if (val) {
      let sec_num = parseInt(val, 10); // don't forget the second param
      let hours = Math.floor(sec_num / 3600);
      let minutes = Math.floor((sec_num - hours * 3600) / 60);
      let newhours = hours.toString();
      let newmins = minutes.toString();
      if (hours < 10) {
        newhours = "0" + hours;
      }
      if (minutes < 10) {
        newmins = "0" + minutes;
      }

      return newhours + ":" + newmins;
    } else {
      return "00:00";
    }
  }

  closeModal() {
    this.modalController.dismiss();
  }

  selectStore() {
    // this.modalController.dismiss(this.selectedStoreId);
  }

  onSave() {
    this.modalController.dismiss(this.selectedStoreId);
  }

  ngOnDestroy() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.storeInfoSub) {
      this.storeInfoSub.unsubscribe();
    }
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
