<ion-header>
  <ion-toolbar>
    <ion-title>Scanning</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-row
    style="
      position: relative;
      z-index: 10;
      background-color: var(--ion-color-primary);
    "
  >
    <ion-col class="ion-text-center">
      <ion-text style="color: white; font-weight: bold">
        Σκανάρετε το QR που βρίσκεται στο τραπέζι σας για να συνεχίσετε.
      </ion-text>
    </ion-col>
  </ion-row>
  <div #square class="square"></div>
  <!-- <ion-fab *ngIf="isTorchAvailable" slot="fixed" horizontal="end" vertical="bottom">
    <ion-fab-button (click)="toggleTorch()">
      <ion-icon name="flashlight"></ion-icon>
    </ion-fab-button>
  </ion-fab> -->
</ion-content>
