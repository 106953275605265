import { Action } from "@ngrx/store";

export const SET_RESERVATIONS = "[RESERVATIONS] Set Reservations";
export const CLEAR_RESERVATIONS = "[RESERVATIONS] Clear Reservations";
export const UPDATE_RESERVATION_FROM_SOCKET =
  "[RESERVATIONS] Update Reservation From Socket";
export const SET_RESERVATION_SELECTED_DATE =
  "[RESERVATIONS] Set Reservation Selected Date"; //this action is used in reservation system (reservation only app)

export const SET_FETCH_RESERVATIONS_TIMESTAMP =
  "[RESERVATIONS] Set Fetch Reservations Timestamp"; //this action is used in reservation system (reservation only app)
export const UPDATE_RESERVATION_FROM_ORDER_SOCKET = "[RESERVATIONS] Update Reservation From Order Socket";

export class SetReservations implements Action {
  readonly type = SET_RESERVATIONS;
  constructor(public payload: any) {}
}

export class SetReservationSelectedDate implements Action {
  readonly type = SET_RESERVATION_SELECTED_DATE;
  constructor(public payload: any) {}
}

export class SetFetchReservationsTimestamp implements Action {
  readonly type = SET_FETCH_RESERVATIONS_TIMESTAMP;
  constructor(public payload: any) {}
}

export class ClearReservations implements Action {
  readonly type = CLEAR_RESERVATIONS;
  constructor() {}
}

export class UpdateReservationFromSocket implements Action {
  readonly type = UPDATE_RESERVATION_FROM_SOCKET;
  constructor(public payload) {}
}

export class UpdateReservationFromOrderSocket implements Action {
  readonly type = UPDATE_RESERVATION_FROM_ORDER_SOCKET;
  constructor(public payload) {}
}

export type ReservationsActions =
  | SetReservations
  | SetReservationSelectedDate
  | SetFetchReservationsTimestamp
  | ClearReservations
  | UpdateReservationFromSocket
  | UpdateReservationFromOrderSocket;
