import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  AlertController,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { auditTime, distinctUntilChanged, first } from "rxjs";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import * as _ from "lodash";
import { Store } from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";
import * as AffiliateActions from "../store/affiliate.actions";
import * as CustomerCouponsActions from "../../discount-coupons/store/coupons.actions";
import { TranslateService } from "@ngx-translate/core";
import { AnimationOptions } from "ngx-lottie";

@Component({
  selector: "app-affiliate-receivers-coupons",
  templateUrl: "./affiliate-receivers-coupons.component.html",
  styleUrls: ["./affiliate-receivers-coupons.component.scss"],
})
export class AffiliateReceiversCouponsComponent implements OnInit, OnDestroy {
  @Input() affiliate_coupon_code;
  noReferedFriendsOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/searching-for-opportunities.json",
  };

  unsubscribeBackEvent: any;
  mo_token: any;
  receiversCoupons: any;
  allCoupons: any;
  affiliateSub: any;
  allCouponsSub: any;
  subscriptions: any[] = [];
  selectedLangugage: any;
  currentStoreId: any;
  stores: any;
  currentUser: any;
  creatingCoupon: boolean;
  triggerFetchReceiversCouponsTimestamp: any;

  constructor(
    private modalController: ModalController,
    private platform: Platform,
    private dataStorageService: DataStorageService,
    private store: Store<fromApp.AppState>,
    private toastController: ToastController,
    private translateService: TranslateService,
    private alertController: AlertController
  ) {}

  ngOnInit() {
    console.log(this.affiliate_coupon_code);
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        this.closeModal();
      }
    );

    this.subscriptions.push(
      this.store
        .select("affiliate")
        .pipe(auditTime(200))
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.affiliateReceiversCoupons &&
            !_.isEqual(this.receiversCoupons, state.affiliateReceiversCoupons)
          ) {
            this.receiversCoupons = _.orderBy(
              _.cloneDeep(state.affiliateReceiversCoupons),
              [
                (obj) => {
                  switch (obj.status) {
                    case "used":
                      return 0;
                    case "active":
                      return 1;
                    case "expired":
                      return 2;
                  }
                },
              ]
            );
            console.log(this.receiversCoupons);
          }
          if (
            state &&
            state.triggerFetchReceiversCouponsTimestamp &&
            !_.isEqual(
              this.triggerFetchReceiversCouponsTimestamp,
              state.triggerFetchReceiversCouponsTimestamp
            )
          ) {
            this.triggerFetchReceiversCouponsTimestamp = _.cloneDeep(
              state.triggerFetchReceiversCouponsTimestamp
            );
            if (this.mo_token) {
              this.fetchReceiversCoupons();
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && state.stores && !_.isEqual(state.stores, this.stores)) {
            this.stores = _.cloneDeep(state.stores);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("customerCoupons")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            state.allCoupons &&
            !_.isEqual(state.allCoupons, this.allCoupons)
          ) {
            this.allCoupons = _.cloneDeep(state.allCoupons);
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("currentStore")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.currentStoreId &&
            !_.isEqual(state.currentStoreId, this.currentStoreId)
          ) {
            this.currentStoreId = _.cloneDeep(state.currentStoreId);
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(200))
        .subscribe((state) => {
          console.log(state.mo_token);
          if (state && !_.isEqual(state.mo_token, this.mo_token)) {
            this.mo_token = _.cloneDeep(state.mo_token);
            this.fetchReceiversCoupons();
            // this.getUserCoupons();
          }
          if (state && !_.isEqual(state.user, this.currentUser)) {
            this.currentUser = _.cloneDeep(state.user);
          }
          // if (state && !_.isEqual(state.loginState, this.loginState)) {
          //   this.loginState = _.cloneDeep(state.loginState);
          // }
        })
    );
    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((state) => {
          if (
            state &&
            state.selectedLangugage &&
            !_.isEqual(state.selectedLangugage, this.selectedLangugage)
          ) {
            this.selectedLangugage = _.cloneDeep(state.selectedLangugage);
          }
        })
    );
  }

  senderCreateCoupon(coupon, selectedStoreId) {
    console.log("token subscription", this.mo_token);
    this.dataStorageService
      .createSendersCoupon(this.mo_token, coupon, selectedStoreId)
      .subscribe({
        next: async (res: any) => {
          console.log("senders-coupon-created???", res);
          if (res && res.response && res.response.success) {
            const alert = await this.alertController.create({
              header: this.translateService.instant(
                "affiliate.senders-coupon-created"
              ),
              message: res.response.comments,

              buttons: ["OK"],
            });
            await alert.present();
            this.creatingCoupon = false;
          } else if (res && !res.response.success) {
            this.presentErrorToast(res.response.comment_id, "danger");
            this.creatingCoupon = false;
          }
        },
        error: (error) => {
          console.log(error);
          this.presentErrorToast(error, "danger");
          this.creatingCoupon = false;
          return error;
        },
      });
  }

  async confirmCreateCoupon(coupon) {
    this.creatingCoupon = true;
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: this.translateService.instant("affiliate.senders-coupon-created"),
      message: this.translateService.instant(
        "affiliate.sender-do-you-want-tocreate-coupon"
      ),
      buttons: [
        {
          text: this.translateService.instant("cancel"),
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel: blah");
            this.creatingCoupon = false;
          },
        },
        {
          text: this.translateService.instant("yes"),
          handler: () => {
            if (this.stores && this.stores.length === 1) {
              console.log(this.stores);
              this.senderCreateCoupon(coupon, this.currentStoreId);
            } else if (this.stores && this.stores.length > 1) {
              const inputs = [];
              _.each(this.stores, (store) => {
                if (store && store.store_id) {
                  inputs.push({
                    label:
                      store.store_name_langs &&
                      store.store_name_langs[this.selectedLangugage]
                        ? store.store_name_langs[this.selectedLangugage]
                        : store.store_name_langs && store.store_name_langs.el
                        ? store.store_name_langs.el
                        : store.store_name
                        ? store.store_name
                        : "",
                    type: "radio",
                    checked:
                      store.store_id === this.currentStoreId ? true : false,
                    value: store.store_id,
                  });
                }
              });
              this.selectStore(inputs, coupon);
            }
          },
        },
      ],
    });

    await alert.present();
  }

  async selectStore(inputs, coupon) {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: this.translateService.instant(
        "points.rewards.select-store-for-cash-out"
      ),

      inputs: inputs,

      buttons: [
        {
          text: this.translateService.instant("cancel"),
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel: blah");
          },
        },
        {
          text: this.translateService.instant("points.rewards.cash-out"),
          handler: (data) => {
            if (data) {
              this.senderCreateCoupon(coupon, data);
            }
            console.log("data", data);
          },
        },
      ],
    });

    await alert.present();
  }

  getUserCoupons() {
    this.allCoupons = null;
    this.dataStorageService
      .getCustomerCoupons(this.mo_token, null, null)

      .subscribe({
        next: (res: any) => {
          if (res && res.success) {
            console.log("success", res);
            let allCoupons = res.coupons;
            this.store.dispatch(
              new CustomerCouponsActions.SetAllCoupons(allCoupons)
            );
          } else {
            if (res.comment_id) {
              let errorMsg = this.translateService.instant(
                "errorMessages-an-error-occurred"
              );
              this.presentErrorToast(errorMsg, "danger");
            } else {
              this.presentErrorToast(res.comments, "danger");
            }
          }
        },
        error: async (err) => {
          const alert = await this.alertController.create({
            header: this.translateService.instant("alert"),
            message: this.translateService.instant(
              "errorMessages.problem_reaching_server"
            ),

            buttons: ["OK"],
          });
          await alert.present();
          return err;
        },
      });
  }

  fetchReceiversCoupons() {
    this.dataStorageService
      .fetchReceiversCoupons(this.mo_token, this.affiliate_coupon_code)
      .subscribe({
        next: (res: any) => {
          if (res && res.success) {
            let receiversCoupons = res.receiversCoupons;
            console.log(receiversCoupons);
            this.store.dispatch(
              new AffiliateActions.SetAffiliateReceiversCoupons(
                _.cloneDeep(receiversCoupons)
              )
            );
          } else if (res && !res.success) {
            this.presentErrorToast(res.comments, "danger");
          }
        },
        error: (error) => {
          console.log(error);
          this.presentErrorToast(error, "danger");
          return error;
        },
      });
  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }

  calculateBadgeColor(coupon) {
    if (coupon) {
      if (coupon.status === "used") {
        return "used-coupon";
      } else if (coupon.status === "active") {
        return "active-coupon";
      } else if (coupon.status === "expired") {
        return "expired-coupon";
      } else if (coupon.status === "outdated") {
        return "expired-coupon";
      } else if (coupon.status === "canceled") {
        return "used-coupon";
      } else if (coupon.status === "pending_confirmation") {
        return "warning";
        //console.log("set exp coupon");
      }
    }
  }

  trackTimestamp(index, option) {
    return option.timestamp;
  }

  closeModal() {
    this.modalController.dismiss();
  }

  ngOnDestroy(): void {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.subscriptions && !_.isEmpty(this.subscriptions)) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    this.allCoupons = null;
    this.mo_token = null;
    this.receiversCoupons = null;
  }
}
