<ion-badge
  class="badge-margin"
  color="danger"
  *ngIf="
    productItem &&
    (!productItem.availableTotal ||
      !productItem.product_categories_availability_check)
  "
>
  <ion-text>{{ "item-tags.unavailable" | translate }}</ion-text>
</ion-badge>
<ion-badge
  *ngIf="
    previousOrderItem &&
    productItem &&
    (!productItem.activeTotal || !productItem.product_categories_active_check)
  "
  class="badge-margin"
  color="tertiary"
>
  <ion-text>{{ "item-tags.out-of-catalog" | translate }}</ion-text>
</ion-badge>

<ion-badge
  *ngIf="
    productItem &&
    !productItem.pointsSystemScheduleActive &&
    groupApp &&
    groupApp.pointsSystem &&
    store_info &&
    store_info.overridePromoItemsForPointsSystem &&
    !productItem.pointsSystemException &&
    productItem.pointsSystemWeight &&
    productItem.pointsSystemWeight > 1
  "
>
  <!-- <ion-text *ngIf="productItem && !productItem.pointsSystemScheduleActive &&
    productItem.pointsSystemException" color="primary">
    {{'cart.cart-components.cart-list.cart-list-product-item.no-points' | translate}}
  </ion-text> -->

  <ion-text>
    x{{ productItem.pointsSystemWeight }} {{ "item-tags.points" | translate }}
  </ion-text>
</ion-badge>

<ion-badge
  [color]="
    store_info &&
    store_info.design_settings &&
    store_info.design_settings.tagsColors &&
    store_info.design_settings.tagsColors.new
      ? store_info.design_settings.tagsColors.new
      : ''
  "
  class="badge-margin"
  *ngIf="
    productItem &&
    ((productItem.tags && productItem.tags.new) ||
      (parentBundleChoice &&
        parentBundleChoice.tags &&
        parentBundleChoice.tags.new))
  "
>
  <ion-text>{{ "item-tags.new" | translate }}</ion-text>
</ion-badge>

<ion-badge
  [color]="
    store_info &&
    store_info.design_settings &&
    store_info.design_settings.tagsColors &&
    store_info.design_settings.tagsColors.veg
      ? store_info.design_settings.tagsColors.veg
      : ''
  "
  class="badge-margin"
  *ngIf="
    productItem &&
    ((productItem.tags && productItem.tags.veg) ||
      (parentBundleChoice &&
        parentBundleChoice.tags &&
        parentBundleChoice.tags.veg))
  "
>
  <ion-text>{{ "item-tags.veg" | translate }}</ion-text>
</ion-badge>

<ion-badge
  [color]="
    store_info &&
    store_info.design_settings &&
    store_info.design_settings.tagsColors &&
    store_info.design_settings.tagsColors.fasting
      ? store_info.design_settings.tagsColors.fasting
      : ''
  "
  class="badge-margin"
  *ngIf="
    productItem &&
    ((productItem.tags && productItem.tags.fasting) ||
      (parentBundleChoice &&
        parentBundleChoice.tags &&
        parentBundleChoice.tags.fasting))
  "
>
  <ion-text>{{ "item-tags.fasting" | translate }}</ion-text>
</ion-badge>

<ion-badge
  [color]="
    store_info &&
    store_info.design_settings &&
    store_info.design_settings.tagsColors &&
    store_info.design_settings.tagsColors.gloutenfree
      ? store_info.design_settings.tagsColors.gloutenfree
      : ''
  "
  class="badge-margin"
  *ngIf="
    productItem &&
    ((productItem.tags && productItem.tags.gloutenfree) ||
      (parentBundleChoice &&
        parentBundleChoice.tags &&
        parentBundleChoice.tags.gloutenfree))
  "
>
  <ion-text>{{ "item-tags.gloutenfree" | translate }}</ion-text>
</ion-badge>

<ion-badge
  [color]="
    store_info &&
    store_info.design_settings &&
    store_info.design_settings.tagsColors &&
    store_info.design_settings.tagsColors.spicy
      ? store_info.design_settings.tagsColors.spicy
      : ''
  "
  class="badge-margin"
  *ngIf="
    productItem &&
    ((productItem.tags && productItem.tags.spicy) ||
      (parentBundleChoice &&
        parentBundleChoice.tags &&
        parentBundleChoice.tags.spicy))
  "
>
  <ion-text>{{ "item-tags.spicy" | translate }}</ion-text>
</ion-badge>

<ion-badge
  [color]="
    store_info &&
    store_info.design_settings &&
    store_info.design_settings.tagsColors &&
    store_info.design_settings.tagsColors.lactosefree
      ? store_info.design_settings.tagsColors.lactosefree
      : ''
  "
  class="badge-margin"
  *ngIf="
    productItem &&
    ((productItem.tags && productItem.tags.lactosefree) ||
      (parentBundleChoice &&
        parentBundleChoice.tags &&
        parentBundleChoice.tags.lactosefree))
  "
>
  <ion-text>{{ "item-tags.lactosefree" | translate }}</ion-text>
</ion-badge>

<ion-icon
  *ngIf="
    productItem &&
    ((productItem.tags && productItem.tags.frozen) ||
      (parentBundleChoice &&
        parentBundleChoice.tags &&
        parentBundleChoice.tags.frozen))
  "
  [color]="
    store_info &&
    store_info.design_settings &&
    store_info.design_settings.tagsColors &&
    store_info.design_settings.tagsColors.frozen
      ? store_info.design_settings.tagsColors.frozen
      : 'primary'
  "
  class="badge-margin"
  style="font-size: 15px"
  src="assets/flatIcons/frozen.svg"
></ion-icon>

<ion-badge
  [color]="
    store_info &&
    store_info.design_settings &&
    store_info.design_settings.tagsColors &&
    store_info.design_settings.tagsColors.vegan
      ? store_info.design_settings.tagsColors.vegan
      : ''
  "
  class="badge-margin"
  *ngIf="
    productItem &&
    ((productItem.tags && productItem.tags.vegan) ||
      (parentBundleChoice &&
        parentBundleChoice.tags &&
        parentBundleChoice.tags.vegan))
  "
>
  <ion-text>{{ "item-tags.vegan" | translate }}</ion-text>
</ion-badge>
<ion-badge
  [color]="
    store_info &&
    store_info.design_settings &&
    store_info.design_settings.tagsColors &&
    store_info.design_settings.tagsColors.deal
      ? store_info.design_settings.tagsColors.deal
      : ''
  "
  class="badge-margin"
  *ngIf="
    productItem &&
    ((productItem.tags && productItem.tags.deal) ||
      (parentBundleChoice &&
        parentBundleChoice.tags &&
        parentBundleChoice.tags.deal))
  "
>
  <ion-text>{{ "item-tags.deal" | translate }}</ion-text>
</ion-badge>
<ion-badge
  [color]="
    store_info &&
    store_info.design_settings &&
    store_info.design_settings.tagsColors &&
    store_info.design_settings.tagsColors.popular
      ? store_info.design_settings.tagsColors.popular
      : ''
  "
  class="badge-margin"
  *ngIf="
    productItem &&
    ((productItem.tags && productItem.tags.popular) ||
      (parentBundleChoice &&
        parentBundleChoice.tags &&
        parentBundleChoice.tags.popular))
  "
>
  <ion-text>{{ "item-tags.popular" | translate }}</ion-text>
</ion-badge>

<ion-badge
  [color]="
    store_info &&
    store_info.design_settings &&
    store_info.design_settings.tagsColors &&
    store_info.design_settings.tagsColors.custom_tag_1
      ? store_info.design_settings.tagsColors.custom_tag_1
      : ''
  "
  class="badge-margin"
  *ngIf="
    store_info &&
    store_info.catalogCustomTagsById &&
    store_info.catalogCustomTagsById.custom_tag_1 &&
    store_info.catalogCustomTagsById.custom_tag_1.active &&
    store_info.catalogCustomTagsById.custom_tag_1.translations &&
    groupApp &&
    groupApp.mo_base &&
    groupApp.mo_base.code &&
    store_info.catalogCustomTagsById.custom_tag_1.translations[
      groupApp.mo_base.code
    ] &&
    productItem &&
    ((productItem.tags && productItem.tags.custom_tag_1) ||
      (parentBundleChoice &&
        parentBundleChoice.tags &&
        parentBundleChoice.tags.custom_tag_1))
  "
>
  <ion-text>{{
    store_info.catalogCustomTagsById.custom_tag_1.translations[
      groupApp.selectedLanguage
    ] ||
      store_info.catalogCustomTagsById.custom_tag_1.translations[
        groupApp.mo_base.code
      ]
  }}</ion-text>
</ion-badge>
<ion-badge
  [color]="
    store_info &&
    store_info.design_settings &&
    store_info.design_settings.tagsColors &&
    store_info.design_settings.tagsColors.custom_tag_2
      ? store_info.design_settings.tagsColors.custom_tag_2
      : ''
  "
  class="badge-margin"
  *ngIf="
    store_info &&
    store_info.catalogCustomTagsById &&
    store_info.catalogCustomTagsById.custom_tag_2 &&
    store_info.catalogCustomTagsById.custom_tag_2.active &&
    store_info.catalogCustomTagsById.custom_tag_2.translations &&
    groupApp &&
    groupApp.mo_base &&
    groupApp.mo_base.code &&
    store_info.catalogCustomTagsById.custom_tag_2.translations[
      groupApp.mo_base.code
    ] &&
    productItem &&
    ((productItem.tags && productItem.tags.custom_tag_2) ||
      (parentBundleChoice &&
        parentBundleChoice.tags &&
        parentBundleChoice.tags.custom_tag_2))
  "
>
  <ion-text>{{
    store_info.catalogCustomTagsById.custom_tag_2.translations[
      groupApp.selectedLanguage
    ] ||
      store_info.catalogCustomTagsById.custom_tag_2.translations[
        groupApp.mo_base.code
      ]
  }}</ion-text>
</ion-badge>
<ion-badge
  [color]="
    store_info &&
    store_info.design_settings &&
    store_info.design_settings.tagsColors &&
    store_info.design_settings.tagsColors.custom_tag_3
      ? store_info.design_settings.tagsColors.custom_tag_3
      : ''
  "
  class="badge-margin"
  *ngIf="
    store_info &&
    store_info.catalogCustomTagsById &&
    store_info.catalogCustomTagsById.custom_tag_3 &&
    store_info.catalogCustomTagsById.custom_tag_3.active &&
    store_info.catalogCustomTagsById.custom_tag_3.translations &&
    groupApp &&
    groupApp.mo_base &&
    groupApp.mo_base.code &&
    store_info.catalogCustomTagsById.custom_tag_3.translations[
      groupApp.mo_base.code
    ] &&
    productItem &&
    ((productItem.tags && productItem.tags.custom_tag_3) ||
      (parentBundleChoice &&
        parentBundleChoice.tags &&
        parentBundleChoice.tags.custom_tag_3))
  "
>
  <ion-text>{{
    store_info.catalogCustomTagsById.custom_tag_3.translations[
      groupApp.selectedLanguage
    ] ||
      store_info.catalogCustomTagsById.custom_tag_3.translations[
        groupApp.mo_base.code
      ]
  }}</ion-text>
</ion-badge>
<ion-badge
  [color]="
    store_info &&
    store_info.design_settings &&
    store_info.design_settings.tagsColors &&
    store_info.design_settings.tagsColors.custom_tag_4
      ? store_info.design_settings.tagsColors.custom_tag_4
      : ''
  "
  class="badge-margin"
  *ngIf="
    store_info &&
    store_info.catalogCustomTagsById &&
    store_info.catalogCustomTagsById.custom_tag_4 &&
    store_info.catalogCustomTagsById.custom_tag_4.active &&
    store_info.catalogCustomTagsById.custom_tag_4.translations &&
    groupApp &&
    groupApp.mo_base &&
    groupApp.mo_base.code &&
    store_info.catalogCustomTagsById.custom_tag_4.translations[
      groupApp.mo_base.code
    ] &&
    productItem &&
    ((productItem.tags && productItem.tags.custom_tag_4) ||
      (parentBundleChoice &&
        parentBundleChoice.tags &&
        parentBundleChoice.tags.custom_tag_4))
  "
>
  <ion-text>{{
    store_info.catalogCustomTagsById.custom_tag_4.translations[
      groupApp.selectedLanguage
    ] ||
      store_info.catalogCustomTagsById.custom_tag_4.translations[
        groupApp.mo_base.code
      ]
  }}</ion-text>
</ion-badge>
<ion-badge
  [color]="
    store_info &&
    store_info.design_settings &&
    store_info.design_settings.tagsColors &&
    store_info.design_settings.tagsColors.custom_tag_5
      ? store_info.design_settings.tagsColors.custom_tag_5
      : ''
  "
  class="badge-margin"
  *ngIf="
    store_info &&
    store_info.catalogCustomTagsById &&
    store_info.catalogCustomTagsById.custom_tag_5 &&
    store_info.catalogCustomTagsById.custom_tag_5.active &&
    store_info.catalogCustomTagsById.custom_tag_5.translations &&
    groupApp &&
    groupApp.mo_base &&
    groupApp.mo_base.code &&
    store_info.catalogCustomTagsById.custom_tag_5.translations[
      groupApp.mo_base.code
    ] &&
    productItem &&
    ((productItem.tags && productItem.tags.custom_tag_5) ||
      (parentBundleChoice &&
        parentBundleChoice.tags &&
        parentBundleChoice.tags.custom_tag_5))
  "
>
  <ion-text>{{
    store_info.catalogCustomTagsById.custom_tag_5.translations[
      groupApp.selectedLanguage
    ] ||
      store_info.catalogCustomTagsById.custom_tag_5.translations[
        groupApp.mo_base.code
      ]
  }}</ion-text>
</ion-badge>
<ion-badge
  [color]="
    store_info &&
    store_info.design_settings &&
    store_info.design_settings.tagsColors &&
    store_info.design_settings.tagsColors.custom_tag_6
      ? store_info.design_settings.tagsColors.custom_tag_6
      : ''
  "
  class="badge-margin"
  *ngIf="
    store_info &&
    store_info.catalogCustomTagsById &&
    store_info.catalogCustomTagsById.custom_tag_6 &&
    store_info.catalogCustomTagsById.custom_tag_6.active &&
    store_info.catalogCustomTagsById.custom_tag_6.translations &&
    groupApp &&
    groupApp.mo_base &&
    groupApp.mo_base.code &&
    store_info.catalogCustomTagsById.custom_tag_6.translations[
      groupApp.mo_base.code
    ] &&
    productItem &&
    ((productItem.tags && productItem.tags.custom_tag_6) ||
      (parentBundleChoice &&
        parentBundleChoice.tags &&
        parentBundleChoice.tags.custom_tag_6))
  "
>
  <ion-text>{{
    store_info.catalogCustomTagsById.custom_tag_6.translations[
      groupApp.selectedLanguage
    ] ||
      store_info.catalogCustomTagsById.custom_tag_6.translations[
        groupApp.mo_base.code
      ]
  }}</ion-text>
</ion-badge>
<ion-badge
  [color]="
    store_info &&
    store_info.design_settings &&
    store_info.design_settings.tagsColors &&
    store_info.design_settings.tagsColors.custom_tag_7
      ? store_info.design_settings.tagsColors.custom_tag_7
      : ''
  "
  class="badge-margin"
  *ngIf="
    store_info &&
    store_info.catalogCustomTagsById &&
    store_info.catalogCustomTagsById.custom_tag_7 &&
    store_info.catalogCustomTagsById.custom_tag_7.active &&
    store_info.catalogCustomTagsById.custom_tag_7.translations &&
    groupApp &&
    groupApp.mo_base &&
    groupApp.mo_base.code &&
    store_info.catalogCustomTagsById.custom_tag_7.translations[
      groupApp.mo_base.code
    ] &&
    productItem &&
    ((productItem.tags && productItem.tags.custom_tag_7) ||
      (parentBundleChoice &&
        parentBundleChoice.tags &&
        parentBundleChoice.tags.custom_tag_7))
  "
>
  <ion-text>{{
    store_info.catalogCustomTagsById.custom_tag_7.translations[
      groupApp.selectedLanguage
    ] ||
      store_info.catalogCustomTagsById.custom_tag_7.translations[
        groupApp.mo_base.code
      ]
  }}</ion-text>
</ion-badge>
<ion-badge
  [color]="
    store_info &&
    store_info.design_settings &&
    store_info.design_settings.tagsColors &&
    store_info.design_settings.tagsColors.custom_tag_8
      ? store_info.design_settings.tagsColors.custom_tag_8
      : ''
  "
  class="badge-margin"
  *ngIf="
    store_info &&
    store_info.catalogCustomTagsById &&
    store_info.catalogCustomTagsById.custom_tag_8 &&
    store_info.catalogCustomTagsById.custom_tag_8.active &&
    store_info.catalogCustomTagsById.custom_tag_8.translations &&
    groupApp &&
    groupApp.mo_base &&
    groupApp.mo_base.code &&
    store_info.catalogCustomTagsById.custom_tag_8.translations[
      groupApp.mo_base.code
    ] &&
    productItem &&
    ((productItem.tags && productItem.tags.custom_tag_8) ||
      (parentBundleChoice &&
        parentBundleChoice.tags &&
        parentBundleChoice.tags.custom_tag_8))
  "
>
  <ion-text>{{
    store_info.catalogCustomTagsById.custom_tag_8.translations[
      groupApp.selectedLanguage
    ] ||
      store_info.catalogCustomTagsById.custom_tag_8.translations[
        groupApp.mo_base.code
      ]
  }}</ion-text>
</ion-badge>
<ion-badge
  [color]="
    store_info &&
    store_info.design_settings &&
    store_info.design_settings.tagsColors &&
    store_info.design_settings.tagsColors.custom_tag_9
      ? store_info.design_settings.tagsColors.custom_tag_9
      : ''
  "
  class="badge-margin"
  *ngIf="
    store_info &&
    store_info.catalogCustomTagsById &&
    store_info.catalogCustomTagsById.custom_tag_9 &&
    store_info.catalogCustomTagsById.custom_tag_9.active &&
    store_info.catalogCustomTagsById.custom_tag_9.translations &&
    groupApp &&
    groupApp.mo_base &&
    groupApp.mo_base.code &&
    store_info.catalogCustomTagsById.custom_tag_9.translations[
      groupApp.mo_base.code
    ] &&
    productItem &&
    ((productItem.tags && productItem.tags.custom_tag_9) ||
      (parentBundleChoice &&
        parentBundleChoice.tags &&
        parentBundleChoice.tags.custom_tag_9))
  "
>
  <ion-text>{{
    store_info.catalogCustomTagsById.custom_tag_9.translations[
      groupApp.selectedLanguage
    ] ||
      store_info.catalogCustomTagsById.custom_tag_9.translations[
        groupApp.mo_base.code
      ]
  }}</ion-text>
</ion-badge>

<ion-badge
  [color]="
    store_info &&
    store_info.design_settings &&
    store_info.design_settings.tagsColors &&
    store_info.design_settings.tagsColors.custom_tag_10
      ? store_info.design_settings.tagsColors.custom_tag_10
      : ''
  "
  class="badge-margin"
  *ngIf="
    store_info &&
    store_info.catalogCustomTagsById &&
    store_info.catalogCustomTagsById.custom_tag_10 &&
    store_info.catalogCustomTagsById.custom_tag_10.active &&
    store_info.catalogCustomTagsById.custom_tag_10.translations &&
    groupApp &&
    groupApp.mo_base &&
    groupApp.mo_base.code &&
    store_info.catalogCustomTagsById.custom_tag_10.translations[
      groupApp.mo_base.code
    ] &&
    productItem &&
    ((productItem.tags && productItem.tags.custom_tag_10) ||
      (parentBundleChoice &&
        parentBundleChoice.tags &&
        parentBundleChoice.tags.custom_tag_10))
  "
>
  <ion-text>{{
    store_info.catalogCustomTagsById.custom_tag_10.translations[
      groupApp.selectedLanguage
    ] ||
      store_info.catalogCustomTagsById.custom_tag_10.translations[
        groupApp.mo_base.code
      ]
  }}</ion-text>
</ion-badge>

<ion-badge
  class="badge-margin"
  *ngIf="
    productItem &&
    (!previousOrderItem || !productItem.availableTotal) &&
    ((productItem.tags &&
      productItem.tags.availableForPreorder &&
      !productItem.hideAvailableForPreorderTag) ||
      (parentBundleChoice &&
        parentBundleChoice.tags &&
        parentBundleChoice.tags.availableForPreorder &&
        !parentBundleChoice.hideAvailableForPreorderTag))
  "
>
  <ion-text *ngIf="productItem.availableTotal">
    {{ "item-tags.availableForPreorder" | translate }}</ion-text
  >
  <ion-text *ngIf="!productItem.availableTotal">
    {{ "item-tags.onlyavailableForPreorder" | translate }}</ion-text
  >
</ion-badge>

<!-- <ion-badge class="badge-margin" *ngIf="productItem && productItem.promo"
  ><ion-text>{{ "item-tags.promo" | translate }}</ion-text></ion-badge
> -->

<ion-badge class="badge-margin" *ngIf="productItem && productItem.avpc">
  <ion-text>{{ productItem.avpc }}</ion-text>
</ion-badge>

<ion-badge
  class="badge-margin"
  *ngIf="
    productItem &&
    productItem.availableTotal &&
    productItem.tags &&
    productItem.tags.initializationActivePreorder &&
    !preorderDateIsAfterToday
  "
>
  <ion-text> {{ "item-tags.onlyavailableForPreorder" | translate }}</ion-text>
</ion-badge>

<ion-badge
  class="badge-margin"
  *ngIf="
    productItem &&
    productItem.availableTotal &&
    productItem.product_categories_availability_check &&
    productItem.availability_stock_enabled &&
    productItem.availability_stock &&
    (!productItem.tags || !productItem.tags.initializationActivePreorder) &&
    (!selectedPreorderDate ||
      !selectedPreorderDate.hour ||
      !selectedPreorderDate.date ||
      !preorderDateIsAfterToday ||
      !productItem.preorder_availability_stock_enabled)
  "
>
  <ion-text
    >{{ "item-tags.available-stock" | translate }}:
    {{ productItem.availability_stock }}</ion-text
  >
</ion-badge>

<ion-badge
  class="badge-margin"
  *ngIf="
    productItem &&
    productItem.availableTotal &&
    productItem.product_categories_availability_check &&
    productItem.preorder_availability_stock_enabled &&
    productItem.preorder_availability_stock &&
    preorderDateIsAfterToday
  "
>
  <ion-text
    >{{ "item-tags.available-stock" | translate }}:
    {{ productItem.preorder_availability_stock }}</ion-text
  >
</ion-badge>

<app-product-item-order-type-tags
  [productItem]="productItem"
  [orderPickup]="orderPickup"
  [dineIn]="dineIn"
>
</app-product-item-order-type-tags>
