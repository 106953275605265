import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { auditTime, distinctUntilChanged, Subscription } from "rxjs";
import * as fromApp from "../../../store/app.reducer";
import * as selectors from "../../../store/app.selectors";
import * as _ from "lodash";

@Component({
  selector: "app-unavailable-products-modal",
  templateUrl: "./unavailable-products-modal.component.html",
  styleUrls: ["./unavailable-products-modal.component.scss"],
})
export class UnavailableProductsModalComponent implements OnInit, OnDestroy {
  @Input() category: any;
  @Input() products: any;
  public windowDimensions: any;
  public imageBrowser: boolean;
  public colSize: any;
  public hyperCategories: any;
  public groupApp: any;
  public groupStoreData: any = {};
  public webp: any;
  public orderPickup: any;
  public dineIn: any;
  public storeInfo: any;
  public selectedDineInTable;
  public selectedPreorderDate;
  public mo_base;
  public selectedLanguage;
  private unsubscribeBackEvent: Subscription;

  private subscriptions: Subscription[] = [];
  constructor(
    private modalController: ModalController,
    private platform: Platform,
    private store: Store<fromApp.AppState>
  ) {}

  closeModal() {
    this.modalController.dismiss();
  }

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );
    this.subscriptions.push(
      this.store
        .select("hyperCategories")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.hyperCategories &&
            !_.isEqual(state.hyperCategories, this.hyperCategories)
          ) {
            this.hyperCategories = _.cloneDeep(state.hyperCategories);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.windowDimensions &&
            !_.isEqual(this.windowDimensions, state.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
            this.groupStoreData.windowDimensions = _.cloneDeep(
              state.windowDimensions
            );
            if (this.windowDimensions && this.windowDimensions.width > 500) {
              this.imageBrowser = true;
            } else {
              this.imageBrowser = false;
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .subscribe((storeInfo) => {
          if (storeInfo && !_.isEqual(this.storeInfo, storeInfo)) {
            this.storeInfo = _.cloneDeep(storeInfo);
            const catalogCustomTagsById = {};
            _.each(_.cloneDeep(this.storeInfo.catalogCustomTags), (tag) => {
              catalogCustomTagsById[tag.id] = tag;
            });
            this.groupStoreData.store_info = {
              store_id: storeInfo.store_id,
              overridePromoItemsForPointsSystem: _.cloneDeep(
                this.storeInfo.overridePromoItemsForPointsSystem
              ),
              alt_dinein_active: storeInfo.dinein
                ? _.cloneDeep(this.storeInfo.alt_dinein_active)
                : null,
              catalogCustomTagsById: catalogCustomTagsById,
              design_settings: storeInfo.design_settings,
            };
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("windowInfo")

        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.windowDimensions, state.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
            this.calculateColSize();
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(this.groupApp, state.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
            this.groupStoreData.groupApp = {
              group: _.cloneDeep(this.groupApp.group),
              disableCatalogPhoto: _.cloneDeep(
                this.groupApp.disableCatalogPhoto
              ),
              pointsSystem: _.cloneDeep(this.groupApp.pointsSystem),
              iconImage: _.cloneDeep(this.groupApp.iconImage),
            };
            this.groupStoreData.groupApp.mo_base = _.find(
              _.cloneDeep(this.groupApp.languages),
              { mo_base: true }
            );
          }
          if (
            state &&
            state.selectedLangugage &&
            !_.isEqual(this.selectedLanguage, state.selectedLangugage)
          ) {
            this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
            this.groupStoreData.groupApp.selectedLanguage = _.cloneDeep(
              this.selectedLanguage
            );
          }
          if (state && state.webp && !_.isEqual(this.webp, state.webp)) {
            this.webp = _.cloneDeep(state.webp);
            this.groupStoreData.webp = _.cloneDeep(this.webp);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("deliveryMethodAddress")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && !_.isEqual(this.orderPickup, state.orderPickup)) {
            this.orderPickup = _.cloneDeep(state.orderPickup);
          }
          if (state && !_.isEqual(this.dineIn, state.dineIn)) {
            this.dineIn = _.cloneDeep(state.dineIn);
          }
          if (
            state &&
            state.selectedDineInTable &&
            !_.isEqual(this.selectedDineInTable, state.selectedDineInTable)
          ) {
            this.selectedDineInTable = _.cloneDeep(state.selectedDineInTable);
          }
          if (
            state &&
            state.selectedPreorderDate &&
            !_.isEqual(this.selectedPreorderDate, state.selectedPreorderDate)
          ) {
            this.selectedPreorderDate = _.cloneDeep(state.selectedPreorderDate);
          }
        })
    );
  }

  productsTrack(index, item) {
    item.product_id;
  }

  calculateColSize() {
    if (this.windowDimensions && this.windowDimensions.width) {
      if (this.windowDimensions.width < 340) {
        this.colSize = "12";
      } else if (
        this.windowDimensions.width >= 341 &&
        this.windowDimensions.width < 480
      ) {
        this.colSize = "6";
      } else if (
        this.windowDimensions.width >= 480 &&
        this.windowDimensions.width < 650
      ) {
        this.colSize = "4";
      } else if (
        this.windowDimensions.width >= 650 &&
        this.windowDimensions.width < 850
      ) {
        this.colSize = "3";
      } else if (
        this.windowDimensions.width >= 850 &&
        this.windowDimensions.width < 950
      ) {
        this.colSize = "4";
      }
    }
  }

  ngOnDestroy(): void {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }

    if (this.subscriptions) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    this.subscriptions = [];
  }
}
