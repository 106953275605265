import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { AlertController, IonCheckbox } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import { Subscription } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import * as fromApp from "../../../../../../store/app.reducer";
import * as selectors from "../../../../../../store/app.selectors";

@Component({
  selector: "app-quantity-checkbox-choices-with-input",
  templateUrl: "./quantity-checkbox-choices-with-input.component.html",
  styleUrls: ["./quantity-checkbox-choices-with-input.component.scss"],
})
export class QuantityCheckboxChoicesWithInputComponent
  implements OnInit, OnDestroy
{
  @Input() selectedProductId: any;
  @Input() bundleMode: any;
  @Input() option_id;
  @Input() choice_id;
  @Input() selectedProductPromo: any;
  @Input() selectedProductCategory: any;
  @Input() alt_dinein_active: any;
  @Input() groupStoreData: any;
  public showChoicePrices: any = true;
  public selectedProduct: any;
  public option: any;
  public choice: any;
  public checked: boolean;
  public orderPickup;
  public dineIn;
  public thereIsAnImageInOption: boolean;
  public showQuantityComponent: boolean;
  public selectedDineInTable: any;

  private alert: any;
  private subscription: Subscription;
  private optionSubscription: Subscription;

  @ViewChild("checkbox") checkbox: IonCheckbox;
  @Output() choiceChanged = new EventEmitter();
  constructor(
    private translateService: TranslateService,
    private alertCtrl: AlertController,
    private store: Store<fromApp.AppState>
  ) {}

  ionViewDidEnter() {
    this.showQuantityComponent = true;
  }

  selectOption() {
    if (this.optionSubscription) {
      this.optionSubscription.unsubscribe();
    }
    console.log("selectedPrduct id", this.selectedProductId);
    this.optionSubscription = this.store
      .select(
        selectors.getOptionByOptionId(this.selectedProductId, this.option_id)
      )
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (!_.isEqual(this.option, state)) {
          this.option = _.cloneDeep(state);
          this.thereIsAnImageInOption = _.find(
            this.option.choices,
            (choice) => {
              if (
                choice.image &&
                choice.image.true &&
                choice.image.true["4x3"] &&
                choice.image.true["4x3"].path
              ) {
                return choice;
              }
            }
          );
          console.log("image path choice", this.thereIsAnImageInOption);

          if (
            this.option &&
            this.option.freeChoices &&
            this.option.freeChoices !== "0"
          ) {
            if (
              this.option.optionData &&
              this.option.optionData.freeChoices &&
              this.option.freeChoices === this.option.optionData.freeChoices
            ) {
              this.showChoicePrices = true;
            } else {
              this.showChoicePrices = false;
            }
          } else {
            this.showChoicePrices = true;
          }
          if (this.option && this.option.choices) {
            let choiceIndex = _.findIndex(this.option.choices, {
              choice_id: this.choice_id,
            });
            if (
              choiceIndex !== -1 &&
              !_.isEqual(this.choice, this.option.choices[choiceIndex])
            ) {
              this.choice = this.option.choices[choiceIndex];

              this.showQuantityElementDebounce();
            }
          }
        }
      });
  }

  ngOnInit() {
    this.subscription = this.store
      .select("deliveryMethodAddress")

      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (state && !_.isEqual(this.dineIn, state.dineIn)) {
          this.dineIn = _.cloneDeep(state.dineIn);
        }
        if (state && !_.isEqual(this.orderPickup, state.orderPickup)) {
          this.orderPickup = _.cloneDeep(state.orderPickup);
        }

        if (
          state &&
          !_.isEqual(this.selectedDineInTable, state.selectedDineInTable)
        ) {
          this.selectedDineInTable = _.cloneDeep(state.selectedDineInTable);
        }
      });
    this.selectOption();
  }

  ngOnChanges(simpleChange: SimpleChanges) {
    if (
      simpleChange &&
      simpleChange.option_id &&
      simpleChange.option_id.currentValue !==
        simpleChange.option_id.previousValue
    ) {
      this.selectOption();
    }
  }

  showQuantityElementDebounce = _.debounce(() => {
    this.showQuantityComponent = true;
  }, 120);

  onCheckboxChanged(ev) {
    if (document.activeElement.tagName === "ION-CHECKBOX") {
      console.log("checkbox change");

      if (
        this.option &&
        this.option.freeChoices &&
        this.option.freeChoicesBySelectionOrder
      ) {
        if (this.choice.is_preselected) {
          this.choice.selected_timestamp = Date.now().toString();
        } else {
          delete this.choice.selected_timestamp;
        }
      }

      this.choiceChanged.emit(this.choice);
    }
  }

  choiceQuantityDecrease() {
    var qtyStep = this.choice.quantityInChoicesStep || 1;
    console.log("qtyStep", qtyStep);
    if (
      this.choice.quantity - qtyStep > 0 &&
      this.choice.is_preselected &&
      this.choice.activeTotal &&
      this.choice.availableTotal
    ) {
      this.choice.quantity = _.cloneDeep(
        parseFloat(
          _.cloneDeep(
            parseFloat(this.choice.quantity.toString()) -
              parseFloat(qtyStep.toString())
          ).toFixed(2)
        )
      );

      this.choiceChanged.emit(this.choice);
    }
  }

  changeChoiceQuantity(ev) {
    if (ev && ev.role === "increase") {
      this.choiceQuantityIncrease();
    } else if (ev && ev.role === "decrease") {
      this.choiceQuantityDecrease();
    } else if (ev && ev.role === "quantity_value") {
      // console.log("here it is", ev);

      //TO_DO CHECK IF YOU WANT TO ADD A MAX VALUE
      if (ev && ev.value && ev.value <= 1500) {
        if (this.choice.max_quantity) {
          if (ev && ev.value <= this.choice.max_quantity) {
            this.choice.quantity = this.choice.quantityInChoicesStep || 1;
            this.choiceChanged.emit(this.choice);
            if (ev.value <= 1) {
              this.choiceChanged.emit(this.choice);
            }
            let click_amount;
            if (
              this.choice.quantityInChoicesStep &&
              this.choice.quantityInChoicesStep !== 1
            ) {
              click_amount = parseInt(
                (
                  ev.value /
                  parseFloat(this.choice.quantityInChoicesStep.toString())
                ).toString()
              );
            } else {
              click_amount = ev.value;
            }

            console.log("click ammmmmount", click_amount);

            for (let i = 1; i < click_amount; i++) {
              const success = this.choiceQuantityIncreaseLoop(
                i == ev.value - 1
              );
              console.log("success", success);
              if (!success && !this.alert) {
                this.presentAlert(
                  this.translateService.instant(
                    "product-details.options.checkbox-options.maximum-choices-allowed"
                  ) +
                    ": " +
                    this.option.maxChoices
                );

                break;
              }
            }
          } else {
            if (!this.alert) {
              this.presentAlert(
                this.translateService.instant(
                  "product-details.options.checkbox-options.maximum-choice-quantity"
                ) +
                  ": " +
                  this.choice.max_quantity
              );
            }
          }
        } else {
          this.choice.quantity = this.choice.quantityInChoicesStep || 1;
          this.choiceChanged.emit(this.choice);
          if (ev.value <= 1) {
            this.choiceChanged.emit(this.choice);
          }
          let click_amount;
          if (
            this.choice.quantityInChoicesStep &&
            this.choice.quantityInChoicesStep !== 1
          ) {
            click_amount = parseInt(
              (
                ev.value /
                parseFloat(this.choice.quantityInChoicesStep.toString())
              ).toString()
            );
          } else {
            click_amount = ev.value;
          }

          console.log("click ammmmmount", click_amount);

          for (let i = 1; i < click_amount; i++) {
            const success = this.choiceQuantityIncreaseLoop(i == ev.value - 1);
            console.log("success", success);
            if (!success && !this.alert) {
              this.presentAlert(
                this.translateService.instant(
                  "product-details.options.checkbox-options.maximum-choices-allowed"
                ) +
                  ": " +
                  this.option.maxChoices
              );

              break;
            }
          }
        }
      } else {
        if (!this.alert) {
          this.presentAlert(
            this.translateService.instant(
              "product-details.options.checkbox-options.maximum-choice-quantity"
            ) +
              ": " +
              1500
          );
        }
      }
    }
  }

  choiceQuantityIncreaseLoop(emit) {
    let qtyStep = this.choice.quantityInChoicesStep || 1;
    console.log("qtyStep", qtyStep);
    if (
      this.choice.is_preselected &&
      this.choice.activeTotal &&
      this.choice.availableTotal
    ) {
      if (this.checkMaximumChoices()) {
        // console.log("mpika sto increase");
        this.choice.quantity = parseFloat(
          _.cloneDeep(
            _.cloneDeep(
              parseFloat(this.choice.quantity.toString()) +
                parseFloat(qtyStep.toString())
            ).toFixed(2)
          )
        );

        if (emit) {
          // console.log("choice emitted", this.choice);
          this.choiceChanged.emit(this.choice);
        }
        return true;
      } else {
        //console.log("choice emitted", this.choice);
        this.choiceChanged.emit(this.choice);

        return false;
      }
    }
  }

  choiceQuantityIncrease() {
    let qtyStep = this.choice.quantityInChoicesStep || 1;
    console.log("qtyStep", qtyStep);
    if (
      this.choice.is_preselected &&
      this.choice.activeTotal &&
      this.choice.availableTotal
    ) {
      if (this.checkMaximumChoices() && !this.choice.max_quantity) {
        //console.log("mpika sto increase");
        this.choice.quantity = parseFloat(
          _.cloneDeep(
            _.cloneDeep(
              parseFloat(this.choice.quantity.toString()) +
                parseFloat(qtyStep.toString())
            ).toFixed(2)
          )
        );
        // console.log("choice emitted", this.choice);
        this.choiceChanged.emit(this.choice);
      } else if (
        this.checkMaximumChoices() &&
        this.choice.max_quantity &&
        parseFloat(
          _.cloneDeep(
            _.cloneDeep(
              parseFloat(this.choice.quantity.toString()) +
                parseFloat(qtyStep.toString())
            ).toFixed(2)
          )
        ) <= this.choice.max_quantity
      ) {
        this.choice.quantity = parseFloat(
          _.cloneDeep(
            _.cloneDeep(
              parseFloat(this.choice.quantity.toString()) +
                parseFloat(qtyStep.toString())
            ).toFixed(2)
          )
        );
        console.log("choice emitted", this.choice);
        this.choiceChanged.emit(this.choice);
      } else {
        if (!this.checkMaximumChoices()) {
          this.presentAlert(
            this.translateService.instant(
              "product-details.options.checkbox-options.maximum-choices-allowed"
            ) +
              ": " +
              this.option.maxChoices
          );
        } else {
          this.presentAlert(
            this.translateService.instant(
              "product-details.options.checkbox-options.maximum-choice-quantity"
            ) +
              ": " +
              this.choice.max_quantity
          );
        }
      }
    }
  }

  async presentAlertForMaximumChoices(message) {
    const alert = await this.alertCtrl.create({
      message: message,
      buttons: ["OK"],
    });
    this.choice.is_preselected = false;
    await alert.present();
    alert.onDidDismiss().then(() => {});
  }

  async presentAlert(message) {
    this.alert = await this.alertCtrl.create({
      message: message,
      buttons: ["OK"],
    });

    await this.alert.present();
    await this.alert.onDidDismiss().then(() => {
      this.alert = null;
    });
  }

  checkMaximumChoices() {
    //I need to calculate here the choice quantity!

    var optionsMaxChoices;
    var counter = 0;

    if (this.option && this.option.maxChoices) {
      optionsMaxChoices = parseInt(this.option.maxChoices);
    }
    console.log("optionMaxChoices", optionsMaxChoices);
    if (!_.isNaN(optionsMaxChoices) && optionsMaxChoices > 0) {
      _.each(_.sortBy(this.option.choices, "price"), (choice, key, list) => {
        if (
          choice.is_preselected &&
          choice.activeTotal &&
          choice.availableTotal
        ) {
          counter = counter + choice.quantity;
          console.log("counter", counter);
        }
      });
      console.log(counter, optionsMaxChoices);
      if (counter >= optionsMaxChoices) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  ngOnDestroy() {
    this.alert = null;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.optionSubscription) {
      this.optionSubscription.unsubscribe();
    }
  }
}
