import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import { Subscription } from "rxjs";
import { auditTime, distinctUntilChanged } from "rxjs/operators";
import * as fromApp from "../../../../store/app.reducer";
import * as selectors from "../../../../store/app.selectors";
@Component({
  selector: "app-checkbox-choices",
  templateUrl: "./checkbox-choices.component.html",
  styleUrls: ["./checkbox-choices.component.scss"],
})
export class CheckboxChoicesComponent implements OnInit, OnDestroy {
  @Input() selectedProductId: any;
  @Input() bundleMode: any;
  @Input() option_id: any;
  @Input() selectedProductPromo: any;
  @Input() selectedProductCategory: any;
  @Input() showChoicePrices: boolean;
  @Input() alt_dinein_active: any;
  @Input() groupStoreData: any;
  public option: any;
  public selectedProduct;
  public browserPlatform = true;
  public imagePath;
  public disableChoices: boolean;
  public colSize: string;
  public productDetailsModalDimensions: any;

  private storeSub: Subscription;
  private stopReadingFromSelector = false;
  private optionSubscription: Subscription;
  private dimensionsSub: Subscription;
  @Output() choicesChanged = new EventEmitter();

  constructor(private store: Store<fromApp.AppState>) {}

  selectOption() {
    if (this.optionSubscription) {
      this.optionSubscription.unsubscribe();
    }
    this.optionSubscription = this.store
      .select(
        selectors.getOptionByOptionId(this.selectedProductId, this.option_id)
      )
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (!_.isEqual(this.option, state) && !this.stopReadingFromSelector) {
          this.option = _.cloneDeep(state);
          if (
            this.option &&
            this.option.freeChoices &&
            this.option.freeChoices !== "0"
          ) {
            if (this.option.optionData && this.option.optionData.freeChoices) {
              this.showChoicePrices = true;
            } else {
              this.showChoicePrices = false;
            }
          } else {
            this.showChoicePrices = false;
          }

          console.log("option reading from state", this.option);
        }
      });
  }

  ngOnChanges(simpleChange: SimpleChanges) {
    if (
      simpleChange &&
      simpleChange.option_id &&
      simpleChange.option_id.currentValue !==
        simpleChange.option_id.previousValue
    ) {
      this.selectOption();
    }
  }

  ngOnInit() {
    this.selectOption();

    // setInterval(() => {
    //   if (this.option) {
    //     console.log(
    //       "stop reading from selector",
    //       this.option.title,
    //       this.stopReadingFromSelector
    //     );
    //   }
    // }, 2000);
    // this.store
    //   .select("selectedProduct")

    //   .pipe(distinctUntilChanged())
    //   .subscribe((state) => {
    //     if (
    //       state &&
    //       state.selectedProduct &&
    //       !_.isEqual(this.selectedProduct, state.selectedProduct)
    //     ) {
    //       this.selectedProduct = _.cloneDeep(state.selectedProduct);
    //       if (
    //         this.selectedProduct &&
    //         this.selectedProduct.options &&
    //         this.option_id
    //       ) {
    //         let optionInx = _.findIndex(this.selectedProduct.options, {
    //           option_id: this.option_id,
    //         });
    //         console.log("option index checkbox-choices", optionInx);
    //         if (
    //           optionInx !== -1 &&
    //           !_.isEqual(this.selectedProduct.options[optionInx], this.option)
    //         ) {
    //           this.option = _.cloneDeep(
    //             this.selectedProduct.options[optionInx]
    //           );
    //         }
    //       }
    //     }
    //   });

    this.dimensionsSub = this.store
      .select(
        selectors.getSelectedProductModalDimensionsBySelectedProductId(
          this.selectedProductId
        )
      )
      .pipe(distinctUntilChanged())

      .subscribe((productDetailsModalDimensions) => {
        if (
          productDetailsModalDimensions &&
          !_.isEqual(
            this.productDetailsModalDimensions,
            productDetailsModalDimensions
          )
        ) {
          this.productDetailsModalDimensions = _.cloneDeep(
            productDetailsModalDimensions
          );
          console.log("productDetailsDems", this.productDetailsModalDimensions);
          this.calculateColumnsSize();
        }
      });
  }

  checkForImage(choice) {
    if (
      choice.image &&
      choice.image.true &&
      choice.image.true["4x3"] &&
      choice.image.true["4x3"].path
    ) {
      this.imagePath =
        "https://images.deliverymanager.gr/" +
        choice.image.true["4x3"].path +
        (true ? ".webp" : choice.image.true["4x3"].type || ".jpg");

      return true;
    } else {
      return false;
    }
  }

  calculateColumnsSize() {
    console.log("calc columns size called", this.productDetailsModalDimensions);
    if (this.option && this.option.choices && this.option.choices.length > 1) {
      let maxChoiceTitleChars = 0;
      let choiceImage = false;
      _.each(this.option.choices, (ch) => {
        if (ch.title && ch.title.length) {
          if (maxChoiceTitleChars < ch.title.length) {
            maxChoiceTitleChars = ch.title.length;
          }
        }
        if (this.checkForImage(ch)) {
          choiceImage = true;
        }
      });
      console.log("maxChoiceChars", maxChoiceTitleChars);

      if (this.productDetailsModalDimensions.width <= 400) {
        this.colSize = "12";
        console.log("colsize", this.colSize);
      } else if (
        this.productDetailsModalDimensions.width > 400 &&
        this.productDetailsModalDimensions.width < 600
      ) {
        this.colSize = "6";
        console.log("colsize", this.colSize);
      } else if (
        this.productDetailsModalDimensions.width >= 600 &&
        maxChoiceTitleChars < 15 &&
        !choiceImage &&
        !this.option.quantityInChoices
      ) {
        this.colSize = "4";
        console.log("colsize", this.colSize);
      } else if (
        this.productDetailsModalDimensions.width >= 600 &&
        maxChoiceTitleChars > 15
      ) {
        this.colSize = "6";
      } else {
        this.colSize = "6";
      }
      console.log("colsize", this.colSize);
    }
  }

  // checkMaximumChoices() {
  //   //I need to calculate here the choice quantity!

  //   var optionsMaxChoices;
  //   var counter = 0;

  //   if (this.option && this.option.maxChoices) {
  //     optionsMaxChoices = parseInt(this.option.maxChoices);
  //   }
  //   console.log("optionMaxChoices", optionsMaxChoices);
  //   if (!_.isNaN(optionsMaxChoices) && optionsMaxChoices > 0) {
  //     _.each(_.sortBy(this.option.choices, "price"), (choice, key, list) => {
  //       if (
  //         choice.is_preselected &&
  //         choice.activeTotal &&
  //         choice.availableTotal
  //       ) {
  //         counter = counter + choice.quantity;
  //         console.log("counter", counter);
  //       }
  //     });
  //     console.log(counter, optionsMaxChoices);
  //     if (counter >= optionsMaxChoices) {
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   } else {
  //     return true;
  //   }
  // }

  choiceChanged(changedChoice) {
    if (this.option && this.option.choices) {
      let changedChIndex = _.findIndex(this.option.choices, {
        choice_id: changedChoice.choice_id,
      });

      if (changedChIndex !== -1) {
        this.option.choices[changedChIndex] = changedChoice;
        this.stopReadingFromSelector = true;
        this.sendOptionChangedChoicesDebounce();
      }
    }
  }

  sendOptionChangedChoicesDebounce = _.debounce(() => {
    this.sendOptionChangedChoices(this.option);
  }, 400);

  sendOptionChangedChoices(opt) {
    this.choicesChanged.emit(_.cloneDeep(opt));

    this.stopReadingFromSelector = false;
  }

  trackFn(index, choice) {
    return choice.option_id_choice_id;
  }

  ngOnDestroy() {
    if (this.dimensionsSub) {
      this.dimensionsSub.unsubscribe();
    }

    if (this.optionSubscription) {
      this.optionSubscription.unsubscribe();
    }
  }
}
