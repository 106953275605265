import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { distinctUntilChanged } from "rxjs/operators";
import * as fromApp from "../../store/app.reducer";
import * as _ from "lodash";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
@Component({
  selector: "app-point-system",
  templateUrl: "./point-system.component.html",
  styleUrls: ["./point-system.component.scss"],
})
export class PointSystemComponent implements OnInit, OnDestroy {
  @Input() cartCatalogMode: boolean;
  public groupApp: any;
  public currentUser: any;
  public userPoints: any;
  public highestReward: any;
  public loginState: string;
  private subscriptions: Subscription[] = [];

  constructor(private store: Store<fromApp.AppState>, private router: Router) {}

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(state.groupApp, this.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("points")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.userPoints &&
            !_.isEqual(state.userPoints, this.userPoints)
          ) {
            this.userPoints = _.cloneDeep(state.userPoints);
          }
          if (
            state &&
            state.highestReward &&
            !_.isEqual(state.highestReward, this.highestReward)
          ) {
            this.highestReward = _.cloneDeep(state.highestReward);

            if (this.highestReward && this.highestReward.user_points_percent) {
              if (this.highestReward.user_points_percent < 50) {
                document.documentElement.style.setProperty(
                  "--pointsProgressBarColor",
                  "var(--ion-color-black)"
                );
                document.documentElement.style.setProperty(
                  "--pointsProgressBarColorLight",
                  "var(--pointsProgressBarColorLightBlack)"
                );
              } else if (
                this.highestReward.user_points_percent >= 50 &&
                this.highestReward.user_points_percent <= 99
              ) {
                document.documentElement.style.setProperty(
                  "--pointsProgressBarColor",
                  "var(--ion-color-warning)"
                );
                document.documentElement.style.setProperty(
                  "--pointsProgressBarColorLight",
                  "var(--pointsProgressBarColorLightWarning)"
                );
              } else if (this.highestReward.user_points_percent === 100) {
                document.documentElement.style.setProperty(
                  "--pointsProgressBarColor",
                  "var(--ion-color-success)"
                );
                document.documentElement.style.setProperty(
                  "--pointsProgressBarColorLight",
                  "var(--pointsProgressBarColorLightSuccess)"
                );
              }
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (state && !_.isEqual(state.user, this.currentUser)) {
            this.currentUser = _.cloneDeep(state.user);
          }
          if (state && !_.isEqual(state.loginState, this.loginState)) {
            this.loginState = _.cloneDeep(state.loginState);
          }
        })
    );
  }

  getTheCoupon() {
    this.navigateToPoints();
  }

  navigateToPoints() {
    this.router.navigateByUrl("/points", {
      replaceUrl: true,
    });
  }

  ngOnDestroy() {
    if (!_.isEmpty(this.subscriptions)) {
      this.subscriptions.forEach((subs) => {
        subs.unsubscribe();
      });
    }
  }
}
