import { Injectable } from "@angular/core";
import { AlertController, LoadingController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Geolocation } from "@capacitor/geolocation";
import { getDistance } from "geolib";
import * as _ from "lodash";
import { resolve } from "path";
import * as fromApp from "../../store/app.reducer";
import * as StoresActions from "../../store/stores/stores.actions";
import * as deliveryMethodAddressActions from "../../store/deliveryMethodAddress/deliveryMethodAddress.actions";
import { Store } from "@ngrx/store";

@Injectable({
  providedIn: "root",
})
export class CheckLocationDistanceService {
  private geoErrMessage: any;
  private gpsErrorAlert: any;

  constructor(
    private translateService: TranslateService,
    private alertController: AlertController,
    private store: Store<fromApp.AppState>
  ) {}

  //this function calculates distance from all stores and sort it that way, also save the distance value inside every store
  async calculateDistanceFromAllStoresByGPS(stores, enable_gps_alerts) {
    return new Promise((resolve) => {
      Geolocation.getCurrentPosition({
        timeout: 5000,
        enableHighAccuracy: true,
      })
        .then(async (resp) => {
          console.log("inside then with this data", resp);

          if (resp.coords && resp.coords.latitude && resp.coords.longitude) {
            const newStores = _.cloneDeep(stores);
            _.each(newStores, (store) => {
              if (store.mapCenterStoreLat && store.mapCenterStoreLng) {
                const distance = getDistance(
                  {
                    latitude: resp.coords.latitude,
                    longitude: resp.coords.longitude,
                  },
                  {
                    latitude: store.mapCenterStoreLat,
                    longitude: store.mapCenterStoreLng,
                  }
                );
                let factor = 1.55;

                if (distance / 1000 > 15 && distance / 1000 < 60) {
                  factor = 1.25;
                } else if (distance / 1000 > 60) {
                  factor = 1.85;
                }

                store.estimatedDistanceFromClientGps = _.cloneDeep(
                  parseFloat(
                    parseFloat(((distance / 1000) * factor).toString()).toFixed(
                      2
                    )
                  )
                );

                if (store.estimatedDistanceFromClientGps === 0) {
                  store.estimatedDistanceFromClientGps = 0.01;
                }
              }
            });

            this.store.dispatch(
              new StoresActions.SetStores(_.cloneDeep(newStores))
            );
            resolve({ sucess: true, stores: _.cloneDeep(newStores) });
          } else {
            resolve({ error: "gps_general_error" });
          }
        })
        .catch(async (err) => {
          setTimeout(() => {
            resolve({ error: "gps_general_error" });
          }, 2000);

          console.log("errr", err);

          if (
            err &&
            err.message &&
            (err.message === "Timeout expired" ||
              err.message === "User denied Geolocation")
          ) {
            this.geoErrMessage = this.translateService.instant(
              "popups.order-now-popup.please-enable-gps-in-order-to-check-your-position"
            );
            if (enable_gps_alerts) {
              if (this.gpsErrorAlert) {
                this.gpsErrorAlert.dismiss();
                this.gpsErrorAlert = null;
              }
              this.gpsErrorAlert = await this.alertController.create({
                header: this.translateService.instant("alert"),
                message: this.geoErrMessage,
                buttons: [
                  {
                    text: this.translateService.instant("cancel"),
                    role: "cancel",
                    cssClass: "secondary",
                    handler: (blah) => {
                      resolve({
                        error:
                          "popups.order-now-popup.please-enable-gps-in-order-to-check-your-position",
                      });
                    },
                  },
                  {
                    text: this.translateService.instant(
                      "new-address-modal.locate-again"
                    ),

                    handler: () => {
                      this.calculateDistanceFromAllStoresByGPS(
                        stores,
                        enable_gps_alerts
                      );
                    },
                  },
                ],
              });

              await this.gpsErrorAlert.present();
              this.gpsErrorAlert.onDidDismiss().then(() => {
                this.gpsErrorAlert = null;
              });
            } else {
              resolve({
                error:
                  "popups.order-now-popup.please-enable-gps-in-order-to-check-your-position",
              });
            }
          } else if (err && err.message && err.message === "Illegal Access") {
            this.geoErrMessage = this.translateService.instant(
              "popups.order-now-popup.you-can-enable-gps-from-app-setting-in-order-to-find-your-location"
            );

            if (enable_gps_alerts) {
              const alert = await this.alertController.create({
                header: this.translateService.instant("alert"),
                message: this.geoErrMessage,
                buttons: ["OK"],
              });

              await alert.present();
              alert.onDidDismiss().then(() => {
                this.geoErrMessage = null;
                resolve({
                  error:
                    "popups.order-now-popup.you-can-enable-gps-from-app-setting-in-order-to-find-your-location",
                });
              });
            } else {
              resolve({
                error:
                  "popups.order-now-popup.you-can-enable-gps-from-app-setting-in-order-to-find-your-location",
              });
            }
          } else {
            this.geoErrMessage = this.translateService.instant(
              "popups.order-now-popup.we-cant-find-you-location-please-enable-gps-and-grant-permissions"
            );

            if (enable_gps_alerts) {
              const alert = await this.alertController.create({
                header: this.translateService.instant("alert"),
                message: this.geoErrMessage,
                buttons: [
                  {
                    text: this.translateService.instant("cancel"),
                    role: "cancel",
                    cssClass: "secondary",
                    handler: (blah) => {
                      resolve({
                        error:
                          "popups.order-now-popup.we-cant-find-you-location-please-enable-gps-and-grant-permissions",
                      });
                    },
                  },
                  {
                    text: this.translateService.instant(
                      "new-address-modal.locate-again"
                    ),

                    handler: () => {
                      this.calculateDistanceFromAllStoresByGPS(
                        stores,
                        enable_gps_alerts
                      );
                    },
                  },
                ],
              });

              await alert.present();
              alert.onDidDismiss().then(() => {
                this.geoErrMessage = null;
                //this.resetTheProccess();
              });
            } else {
              resolve({
                error:
                  "popups.order-now-popup.we-cant-find-you-location-please-enable-gps-and-grant-permissions",
              });
            }
          }
        });
    });
  }

  async calculateDistanceFromGps(location_point, enable_gps_alerts) {
    return new Promise((resolve) => {
      Geolocation.getCurrentPosition({
        timeout: 5000,
        enableHighAccuracy: true,
      })
        .then(async (resp) => {
          console.log("inside then with this data", resp);

          if (resp.coords && resp.coords.latitude && resp.coords.longitude) {
            const distance = getDistance(
              {
                latitude: resp.coords.latitude,
                longitude: resp.coords.longitude,
              },
              {
                latitude: location_point.lat,
                longitude: location_point.lng,
              }
            );
            console.log("distance", distance);
            resolve({ distance: distance });
          } else {
            resolve({ error: "gps_general_error" });
          }
        })
        .catch(async (err) => {
          setTimeout(() => {
            resolve({ error: "gps_general_error" });
          }, 2000);

          console.log("errr", err);

          if (
            err &&
            err.message &&
            (err.message === "Timeout expired" ||
              err.message === "User denied Geolocation")
          ) {
            this.geoErrMessage = this.translateService.instant(
              "popups.order-now-popup.please-enable-gps-in-order-to-check-your-position"
            );
            if (enable_gps_alerts) {
              if (this.gpsErrorAlert) {
                this.gpsErrorAlert.dismiss();
                this.gpsErrorAlert = null;
              }
              this.gpsErrorAlert = await this.alertController.create({
                header: this.translateService.instant("alert"),
                message: this.geoErrMessage,
                buttons: [
                  {
                    text: this.translateService.instant("cancel"),
                    role: "cancel",
                    cssClass: "secondary",
                    handler: (blah) => {
                      resolve({
                        error:
                          "popups.order-now-popup.please-enable-gps-in-order-to-check-your-position",
                      });
                    },
                  },
                  {
                    text: this.translateService.instant(
                      "new-address-modal.locate-again"
                    ),

                    handler: () => {
                      this.calculateDistanceFromGps(
                        location_point,
                        enable_gps_alerts
                      );
                    },
                  },
                ],
              });

              await this.gpsErrorAlert.present();
              this.gpsErrorAlert.onDidDismiss().then(() => {
                this.gpsErrorAlert = null;
              });
            } else {
              resolve({
                error:
                  "popups.order-now-popup.please-enable-gps-in-order-to-check-your-position",
              });
            }
          } else if (err && err.message && err.message === "Illegal Access") {
            this.geoErrMessage = this.translateService.instant(
              "popups.order-now-popup.you-can-enable-gps-from-app-setting-in-order-to-find-your-location"
            );

            if (enable_gps_alerts) {
              const alert = await this.alertController.create({
                header: this.translateService.instant("alert"),
                message: this.geoErrMessage,
                buttons: ["OK"],
              });

              await alert.present();
              alert.onDidDismiss().then(() => {
                this.geoErrMessage = null;
                resolve({
                  error:
                    "popups.order-now-popup.you-can-enable-gps-from-app-setting-in-order-to-find-your-location",
                });
              });
            } else {
              resolve({
                error:
                  "popups.order-now-popup.you-can-enable-gps-from-app-setting-in-order-to-find-your-location",
              });
            }
          } else {
            this.geoErrMessage = this.translateService.instant(
              "popups.order-now-popup.we-cant-find-you-location-please-enable-gps-and-grant-permissions"
            );

            if (enable_gps_alerts) {
              const alert = await this.alertController.create({
                header: this.translateService.instant("alert"),
                message: this.geoErrMessage,
                buttons: [
                  {
                    text: this.translateService.instant("cancel"),
                    role: "cancel",
                    cssClass: "secondary",
                    handler: (blah) => {
                      resolve({
                        error:
                          "popups.order-now-popup.we-cant-find-you-location-please-enable-gps-and-grant-permissions",
                      });
                    },
                  },
                  {
                    text: this.translateService.instant(
                      "new-address-modal.locate-again"
                    ),

                    handler: () => {
                      this.calculateDistanceFromGps(
                        location_point,
                        enable_gps_alerts
                      );
                    },
                  },
                ],
              });

              await alert.present();
              alert.onDidDismiss().then(() => {
                this.geoErrMessage = null;
                //this.resetTheProccess();
              });
            } else {
              resolve({
                error:
                  "popups.order-now-popup.we-cant-find-you-location-please-enable-gps-and-grant-permissions",
              });
            }
          }
        });
    });
  }

  //this function calculates distance from all stores and sort it that way, also save the distance value inside every store
  async calculateDistanceForFixedPointsAddressesGPS(
    fixedPointsAddresses,
    enable_gps_alerts
  ) {
    return new Promise((resolve) => {
      Geolocation.getCurrentPosition({
        timeout: 5000,
        enableHighAccuracy: true,
      })
        .then(async (resp) => {
          console.log("inside then with this data", resp);
          if (resp.coords && resp.coords.latitude && resp.coords.longitude) {
            const newPoints = _.cloneDeep(fixedPointsAddresses);

            _.each(newPoints, (fixed_point) => {
              if (
                fixed_point &&
                fixed_point.address &&
                fixed_point.address.userLat &&
                fixed_point.address.userLng
              ) {
                const distance = getDistance(
                  {
                    latitude: resp.coords.latitude,
                    longitude: resp.coords.longitude,
                  },
                  {
                    latitude: fixed_point.address.userLat,
                    longitude: fixed_point.address.userLng,
                  }
                );
                let factor = 1.55;

                if (distance / 1000 > 15 && distance / 1000 < 60) {
                  factor = 1.25;
                } else if (distance / 1000 > 60) {
                  factor = 1.85;
                }

                fixed_point.estimatedDistanceFromClientGps = _.cloneDeep(
                  parseFloat(
                    parseFloat(((distance / 1000) * factor).toString()).toFixed(
                      2
                    )
                  )
                );

                if (fixed_point.estimatedDistanceFromClientGps === 0) {
                  fixed_point.estimatedDistanceFromClientGps = 0.01;
                }
              }
            });

            this.store.dispatch(
              new deliveryMethodAddressActions.SetFixedPointsAddresses(
                _.cloneDeep(newPoints)
              )
            );
            resolve({ sucess: true, stores: _.cloneDeep(newPoints) });
          } else {
            resolve({ error: "gps_general_error" });
          }
        })
        .catch(async (err) => {
          setTimeout(() => {
            resolve({ error: "gps_general_error" });
          }, 2000);

          console.log("errr", err);

          if (
            err &&
            err.message &&
            (err.message === "Timeout expired" ||
              err.message === "User denied Geolocation")
          ) {
            this.geoErrMessage = this.translateService.instant(
              "popups.order-now-popup.please-enable-gps-in-order-to-check-your-position"
            );
            if (enable_gps_alerts) {
              if (this.gpsErrorAlert) {
                this.gpsErrorAlert.dismiss();
                this.gpsErrorAlert = null;
              }
              this.gpsErrorAlert = await this.alertController.create({
                header: this.translateService.instant("alert"),
                message: this.geoErrMessage,
                buttons: [
                  {
                    text: this.translateService.instant("cancel"),
                    role: "cancel",
                    cssClass: "secondary",
                    handler: (blah) => {
                      resolve({
                        error:
                          "popups.order-now-popup.please-enable-gps-in-order-to-check-your-position",
                      });
                    },
                  },
                  {
                    text: this.translateService.instant(
                      "new-address-modal.locate-again"
                    ),

                    handler: () => {
                      this.calculateDistanceForFixedPointsAddressesGPS(
                        fixedPointsAddresses,
                        enable_gps_alerts
                      );
                    },
                  },
                ],
              });

              await this.gpsErrorAlert.present();
              this.gpsErrorAlert.onDidDismiss().then(() => {
                this.gpsErrorAlert = null;
              });
            } else {
              resolve({
                error:
                  "popups.order-now-popup.please-enable-gps-in-order-to-check-your-position",
              });
            }
          } else if (err && err.message && err.message === "Illegal Access") {
            this.geoErrMessage = this.translateService.instant(
              "popups.order-now-popup.you-can-enable-gps-from-app-setting-in-order-to-find-your-location"
            );

            if (enable_gps_alerts) {
              const alert = await this.alertController.create({
                header: this.translateService.instant("alert"),
                message: this.geoErrMessage,
                buttons: ["OK"],
              });

              await alert.present();
              alert.onDidDismiss().then(() => {
                this.geoErrMessage = null;
                resolve({
                  error:
                    "popups.order-now-popup.you-can-enable-gps-from-app-setting-in-order-to-find-your-location",
                });
              });
            } else {
              resolve({
                error:
                  "popups.order-now-popup.you-can-enable-gps-from-app-setting-in-order-to-find-your-location",
              });
            }
          } else {
            this.geoErrMessage = this.translateService.instant(
              "popups.order-now-popup.we-cant-find-you-location-please-enable-gps-and-grant-permissions"
            );

            if (enable_gps_alerts) {
              const alert = await this.alertController.create({
                header: this.translateService.instant("alert"),
                message: this.geoErrMessage,
                buttons: [
                  {
                    text: this.translateService.instant("cancel"),
                    role: "cancel",
                    cssClass: "secondary",
                    handler: (blah) => {
                      resolve({
                        error:
                          "popups.order-now-popup.we-cant-find-you-location-please-enable-gps-and-grant-permissions",
                      });
                    },
                  },
                  {
                    text: this.translateService.instant(
                      "new-address-modal.locate-again"
                    ),

                    handler: () => {
                      this.calculateDistanceForFixedPointsAddressesGPS(
                        fixedPointsAddresses,
                        enable_gps_alerts
                      );
                    },
                  },
                ],
              });

              await alert.present();
              alert.onDidDismiss().then(() => {
                this.geoErrMessage = null;
                //this.resetTheProccess();
              });
            } else {
              resolve({
                error:
                  "popups.order-now-popup.we-cant-find-you-location-please-enable-gps-and-grant-permissions",
              });
            }
          }
        });
    });
  }
}
