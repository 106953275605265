import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Subscription } from "rxjs";
import { CheckboxClosedChoicesSelectionModalComponent } from "./checkbox-closed-choices-selection-modal/checkbox-closed-choices-selection-modal.component";
import * as fromApp from "../../../../store/app.reducer";
import * as selectors from "../../../../store/app.selectors";
import * as _ from "lodash";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Store } from "@ngrx/store";
@Component({
  selector: "app-checkbox-option-closed-choices",
  templateUrl: "./checkbox-option-closed-choices.component.html",
  styleUrls: ["./checkbox-option-closed-choices.component.scss"],
})
export class CheckboxOptionClosedChoicesComponent
  implements OnInit, OnChanges, OnDestroy
{
  public option: any;
  public subscriptions: Subscription[] = [];
  public selectedChoices = [];
  public orderPickup;
  public dineIn;
  public store_info;
  public alt_dinein_active;
  public selectedDineInTable;
  @Input() selectedProductId: any;
  @Input() product_id: any;
  @Input() productName: any;
  @Input() bundleMode;
  @Input() option_id;
  @Input() optionValidationObject: any;
  @Input() selectedProductPromo: any;
  @Input() selectedProductCategory: any;
  @Input() must_select_something: any;
  @Input() selectedProductFreeParentOption: any;
  @Input() selectedProductParentDiscount;
  @Input() groupStoreData: any;
  private lastValidTimestamp: any = null;
  private optionValidations: any;
  private modalOpenedIsNotValid: boolean;
  private optionSubscription: Subscription;
  private selectionClosedChoicesModal: any;

  @Output() optionChange = new EventEmitter();
  constructor(
    private modalCtrl: ModalController,
    private store: Store<fromApp.AppState>
  ) {}

  selectOption() {
    if (this.optionSubscription) {
      this.optionSubscription.unsubscribe();
    }
    this.optionSubscription = this.store
      .select(
        selectors.getOptionByOptionId(this.selectedProductId, this.option_id)
      )
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (!_.isEqual(this.option, state)) {
          this.option = _.cloneDeep(state);
          if (this.option && this.option.choices) {
            this.selectedChoices = [];
            _.each(this.option.choices, (ch) => {
              if (ch.is_preselected) {
                this.selectedChoices.push(ch);
              }
            });
          }
          //console.log("option from modal closed choices", this.option);
        }
      });
  }

  ngOnInit() {
    this.selectOption();
    this.subscriptions.push(
      this.optionChange.pipe(distinctUntilChanged()).subscribe((event) => {
        console.log("event changed", event);
      })
    );

    this.subscriptions.push(
      this.store
        .select("deliveryMethodAddress")

        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && !_.isEqual(this.dineIn, state.dineIn)) {
            this.dineIn = _.cloneDeep(state.dineIn);
          }
          if (state && !_.isEqual(this.orderPickup, state.orderPickup)) {
            this.orderPickup = _.cloneDeep(state.orderPickup);
          }
          if (
            state &&
            !_.isEqual(this.selectedDineInTable, state.selectedDineInTable)
          ) {
            this.selectedDineInTable = _.cloneDeep(state.selectedDineInTable);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .subscribe((store_info) => {
          if (store_info && !_.isEqual(this.store_info, store_info)) {
            this.store_info = _.cloneDeep(store_info);
            if (this.store_info && this.store_info.dinein) {
              this.alt_dinein_active = this.store_info.alt_dinein_active;
            } else {
              this.alt_dinein_active = false;
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("productOptionsValidation")
        .pipe(distinctUntilChanged())
        .pipe(debounceTime(300))
        .subscribe((state) => {
          if (
            state &&
            state.lastValidTimestampBySelectedProductId &&
            state.lastValidTimestampBySelectedProductId[
              this.selectedProductId
            ] &&
            !_.isEqual(
              this.lastValidTimestamp,
              state.lastValidTimestampBySelectedProductId[
                this.selectedProductId
              ]
            ) &&
            !_.isEmpty(
              state.optionValidationsBySelectedProductId[this.selectedProductId]
            )
          ) {
            this.lastValidTimestamp = _.cloneDeep(
              state.lastValidTimestampBySelectedProductId[
                this.selectedProductId
              ]
            );
            if (
              !_.isEmpty(
                state.optionValidationsBySelectedProductId[
                  this.selectedProductId
                ]
              )
            ) {
              this.optionValidations = _.cloneDeep(
                state.optionValidationsBySelectedProductId[
                  this.selectedProductId
                ]
              );
              let foundOptionToOpen = _.find(this.optionValidations, {
                optionToOpen: true,
              });
              if (
                foundOptionToOpen &&
                this.option &&
                _.isEqual(foundOptionToOpen.option_id, this.option.option_id)
              ) {
                this.modalOpenedIsNotValid = true;
                if (!this.selectionClosedChoicesModal) {
                  this.selectionClosedChoicesModal = true;
                  this.openSelectionModalDebounce();
                }
              }
            }
          }
        })
    );
  }

  ngOnChanges(simpleChange: SimpleChanges) {
    if (
      simpleChange &&
      simpleChange.option_id &&
      simpleChange.option_id.currentValue !==
        simpleChange.option_id.previousValue
    ) {
      this.selectOption();
    }
  }

  toFloat(num) {
    return parseFloat(num.toString());
  }

  async openSelectionModal(option_id) {
    this.selectionClosedChoicesModal = await this.modalCtrl.create({
      component: CheckboxClosedChoicesSelectionModalComponent,
      cssClass: "checkboxClosedChoicesModal",
      backdropDismiss: false,
      animated: true,

      componentProps: {
        option_id: option_id,
        optionChange: this.optionChange,
        selectedProductPromo: this.selectedProductPromo,
        selectedProductCategory: this.selectedProductCategory,
        must_select_something: this.must_select_something,
        modalOpenedIsNotValid: this.modalOpenedIsNotValid,
        bundleMode: this.bundleMode,
        selectedProductId: this.selectedProductId,
        productName: this.productName,
        groupStoreData: this.groupStoreData,
      },
    });

    await this.selectionClosedChoicesModal.present().then(() => {});
    this.selectionClosedChoicesModal.onDidDismiss().then(() => {
      this.selectionClosedChoicesModal = null;
    });
  }

  openSelectionModalDebounce = _.debounce(
    () => {
      this.openSelectionModal(this.option.option_id);
    },

    100
  );

  optionChanged(newChoicesOption) {
    this.optionChange.emit(newChoicesOption);
  }

  ngOnDestroy() {
    if (this.selectionClosedChoicesModal) {
      this.selectionClosedChoicesModal.dismiss();
      this.selectionClosedChoicesModal = null;
    }
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }

    if (this.optionSubscription) {
      this.optionSubscription.unsubscribe();
    }
  }
}
