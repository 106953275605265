import { Component, OnDestroy, OnInit } from "@angular/core";
import * as fromApp from "../../../store/app.reducer";
import * as _ from "lodash";
import { auditTime, catchError, distinctUntilChanged } from "rxjs/operators";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { ToastController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { userInfo } from "os";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import * as AuthActions from "../../../auth/store/auth.actions";
import * as DeliveryMethdoAddressActions from "../../../store/deliveryMethodAddress/deliveryMethodAddress.actions";
import * as CompleteOrderValidationActions from "../../../store/complete-order-validation/completeOrderValidation.actions";
import * as selectors from "../../../store/app.selectors";
import { SelectedAddressService } from "src/app/services/selectedAddress/selected-address.service";
import { OrderNowModalPopupsService } from "src/app/services/orderNowModalPopupsService/orderNowModalPopups.service";

@Component({
  selector: "app-delivery-info",
  templateUrl: "./delivery-info.component.html",
  styleUrls: ["./delivery-info.component.scss"],
})
export class DeliveryInfoComponent implements OnInit, OnDestroy {
  public currentUser: any;
  public address: any;
  public orderType: string = "delivery";
  public selectRef;
  public finalPriceLoading: boolean;
  public updatingDataLoading: boolean = false;
  public mo_token: any;
  public valid: boolean = true;
  public customerDataSaveLoading: boolean = false;
  public customerDataSaveSuccess: boolean = false;
  public customerDataDirty: boolean = false;
  public storeInfo: any;
  public dineIn: boolean;
  public orderPickup: boolean;
  public groupApp: any;

  private toast: any;
  private subscriptions: Subscription[] = [];
  private lastValidTimestamp: any = null;
  private navigationOnDeliveryMethodChange: any;
  private stores: any;
  private currentStoreId: any;

  constructor(
    private store: Store<fromApp.AppState>,
    private translateService: TranslateService,
    private toastController: ToastController,
    private dataStorageService: DataStorageService,
    private selectedAddressService: SelectedAddressService,
    private orderNowModalPopupsService: OrderNowModalPopupsService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(this.groupApp, state.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("completeOrderValidation")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.lastValidTimestamp &&
            !_.isEqual(this.lastValidTimestamp, state.lastValidTimestamp)
          ) {
            this.lastValidTimestamp = _.cloneDeep(state.lastValidTimestamp);
            this.completeOrderDeliveryInfoValidations();
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("disableFinalPrice")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.finalPriceLoading, state.disableFinalPrice)
          ) {
            this.finalPriceLoading = _.cloneDeep(state.disableFinalPrice);
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())

        .subscribe((storeInfo) => {
          if (!_.isEqual(this.storeInfo, storeInfo)) {
            this.storeInfo = _.cloneDeep(storeInfo);
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("deliveryMethodAddress")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.selectedAddress &&
            !_.isEqual(this.address, state.selectedAddress)
          ) {
            this.customerDataDirty = false;
            this.address = _.cloneDeep(state.selectedAddress);
            this.deliveryInfoValidations();
          }
          if (
            state &&
            state.orderPickup != null &&
            !_.isEqual(this.orderPickup, state.orderPickup)
          ) {
            this.orderPickup = _.cloneDeep(state.orderPickup);
          }

          if (
            state &&
            state.dineIn != null &&
            !_.isEqual(this.dineIn, state.dineIn)
          ) {
            this.dineIn = _.cloneDeep(state.dineIn);
          }
          if (
            state &&
            !_.isEqual(
              state.navigationOnDeliveryMethodChange,
              this.navigationOnDeliveryMethodChange
            )
          ) {
            this.navigationOnDeliveryMethodChange = _.cloneDeep(
              state.navigationOnDeliveryMethodChange
            );
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((state) => {
          if (state && state.stores && !_.isEqual(state.stores, this.stores)) {
            this.stores = _.cloneDeep(state.stores);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("currentStore")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((state) => {
          if (
            state &&
            state.currentStoreId &&
            !_.isEqual(this.currentStoreId, state.currentStoreId)
          ) {
            this.currentStoreId = _.cloneDeep(state.currentStoreId);
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(200))
        .subscribe((state) => {
          if (state && state.user && !_.isEqual(this.currentUser, state.user)) {
            this.currentUser = _.cloneDeep(state.user);

            // console.log(this.address);
          }

          if (
            state &&
            state.mo_token &&
            !_.isEqual(this.mo_token, state.mo_token)
          ) {
            this.mo_token = _.cloneDeep(state.mo_token);
          }
        })
    );
  }

  deliveryInfoValidations() {
    if (this.address) {
      this.valid = true;

      if (
        !this.address.doorbellname &&
        !this.storeInfo.customerInvoiceRequired &&
        !this.groupApp.vatBasedCustomerAddressesActive &&
        !(
          this.address &&
          this.address.fixed_address_id &&
          this.address.fixed_address_type &&
          this.address.fixed_address_type === "meeting_point"
        )
      ) {
        this.valid = false;
      }
      if (
        !this.address.floor &&
        !this.storeInfo.customerInvoiceRequired &&
        !this.groupApp.vatBasedCustomerAddressesActive &&
        !(
          this.address &&
          this.address.fixed_address_id &&
          this.address.fixed_address_type &&
          this.address.fixed_address_type === "meeting_point"
        )
      ) {
        this.valid = false;
      }
    }
  }

  completeOrderDeliveryInfoValidations() {
    if (this.address) {
      this.valid = true;
      let validationObject = {
        id: "delivery-info",
        valid: true,
        errorMessages: [],
      };
      if (
        !this.address.doorbellname &&
        !this.storeInfo.customerInvoiceRequired &&
        !this.groupApp.vatBasedCustomerAddressesActive &&
        !(
          this.address &&
          this.address.fixed_address_id &&
          this.address.fixed_address_type &&
          this.address.fixed_address_type === "meeting_point"
        )
      ) {
        validationObject.errorMessages.push(
          "cart.cart-components.delivery-info.please-type-doorbell-name"
        );
        validationObject.valid = false;
        this.valid = false;
      }
      if (
        !this.address.floor &&
        !this.storeInfo.customerInvoiceRequired &&
        !this.groupApp.vatBasedCustomerAddressesActive &&
        !(
          this.address &&
          this.address.fixed_address_id &&
          this.address.fixed_address_type &&
          this.address.fixed_address_type === "meeting_point"
        )
      ) {
        validationObject.errorMessages.push(
          "cart.cart-components.delivery-info.please-type-floor"
        );
        validationObject.valid = false;
        this.valid = false;
      }

      this.store.dispatch(
        new CompleteOrderValidationActions.AddUpdateCompleteOrderValidation(
          _.cloneDeep(validationObject)
        )
      );
    }
  }

  onInputFocus() {
    this.customerDataDirty = true;
  }

  checkForBlankSpaces(attr) {
    if (attr === "doorbellname" && this.address.doorbellname) {
      this.address.doorbellname = _.cloneDeep(
        _.trim(this.address.doorbellname.toString())
      );
    } else if (attr === "floor" && this.address.floor) {
      this.address.floor = _.cloneDeep(_.trim(this.address.floor.toString()));
    } else if (attr === "company") {
      this.address.company = _.cloneDeep(
        _.trim(this.address.company.toString())
      );
    } else if (attr === "altPhone" && this.address.altPhone) {
      this.address.altPhone = _.cloneDeep(
        _.trim(this.address.altPhone.toString())
      );
    } else if (attr === "comments" && this.address.comments) {
      this.address.comments = _.cloneDeep(
        _.trim(this.address.comments.toString())
      );
    } else if (attr === "company_manager" && this.address.company_manager) {
      this.address.company_manager = _.cloneDeep(
        _.trim(this.address.company_manager.toString())
      );
    }
  }

  updateCustomerDataDebounced = _.debounce((attr) => {
    this.updateCustomerData(attr);
  }, 1500);

  updateCustomerData(attr) {
    if (this.customerDataDirty) {
      this.customerDataSaveSuccess = false;
      this.checkForBlankSpaces(attr);

      if (
        (attr === "doorbellname" && this.address.doorbellname) ||
        (attr === "floor" && this.address.floor) ||
        attr === "company" ||
        attr === "altPhone" ||
        attr === "comments" ||
        attr === "company_manager"
      ) {
        this.deliveryInfoValidations();
        this.customerDataSaveLoading = true;
        let indexAddress = _.findIndex(this.currentUser.addresses, {
          address_id: this.address.address_id,
        });
        if (indexAddress !== -1) {
          let userForSave = _.cloneDeep(this.currentUser);
          userForSave.addresses[indexAddress] = _.cloneDeep(this.address);
          let obj = { updatedData: _.cloneDeep(userForSave) };

          this.dataStorageService

            .updateCustomerData(obj, this.mo_token)

            .subscribe({
              next: (res: any) => {
                if (res && res.success) {
                  this.customerDataSaveLoading = false;
                  this.customerDataSaveSuccess = true;
                  let updatedUser = res.data;
                  this.store.dispatch(
                    new AuthActions.SetUser(_.cloneDeep(updatedUser))
                  );
                  this.store.dispatch(
                    new DeliveryMethdoAddressActions.setSelectedAddress(
                      _.cloneDeep(this.address)
                    )
                  );
                  if (navigator.cookieEnabled) {
                    if (window.localStorage.getItem("deliveryMethodAddress")) {
                      let deliveryMethodAddress = JSON.parse(
                        window.localStorage.getItem("deliveryMethodAddress")
                      );
                      deliveryMethodAddress.selectedAddress = this.address;
                      window.localStorage.setItem(
                        "deliveryMethodAddress",
                        JSON.stringify(deliveryMethodAddress)
                      );
                    } else {
                      let deliveryMethodAddress = {
                        orderPickup: this.orderPickup,
                        dineIn: this.dineIn,
                        selectedAddress: this.address,
                      };
                      window.localStorage.setItem(
                        "deliveryMethodAddress",
                        JSON.stringify(deliveryMethodAddress)
                      );
                    }
                  }

                  this.updatingDataLoading = false;
                } else {
                  this.customerDataSaveLoading = false;
                  if (res.comment_id) {
                    this.presentErrorToast(
                      this.translateService.instant(res.comment_id),
                      "danger"
                    );
                  } else if (res.comments) {
                    this.presentErrorToast(res.comments, "danger");
                  }
                  this.updatingDataLoading = false;
                }
              },
              error: (err) => {
                let errMsg = this.translateService.instant(
                  "errorMessages.error: There was an error on saving"
                );
                this.customerDataSaveLoading = false;
                this.presentErrorToast(errMsg, "danger");
                return err;
                console.log("error updating the information");
              },
            });
        } else {
          this.selectedAddressService.deselectAddress();
          this.customerDataSaveLoading = false;
          let errMsg = this.translateService.instant(
            "errorMessages.an-error-occurred-please-select-address-again"
          );

          this.presentErrorToast(errMsg, "danger");
          this.orderNowModalPopupsService.checkAndOpenSettingsModal(
            "delivery",
            this.currentUser,
            this.address,
            this.stores,
            this.currentStoreId,
            this.navigationOnDeliveryMethodChange,
            true
          );
        }
      } else {
        this.customerDataSaveLoading = false;
        if (
          (attr === "doorbellname" && !this.address.doorbellname) ||
          (attr === "floor" && !this.address.floor)
        ) {
          if (this.toast) {
            this.toast.dismiss();
            this.toast = null;
          }
          this.presentErrorToast(
            this.translateService.instant(
              "cart.cart-components.customer-info.the-filed-is-required"
            ),
            "danger"
          );
        }
      }
    }
  }

  async presentErrorToast(message, color) {
    this.toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            //console.log("Toast Closed");
          },
        },
      ],
    });
    this.toast.present();
  }

  onAddressChange(ev) {}

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }
}
