<ion-app>
  <ion-grid
    *ngIf="
      groupAppLoading ||
      !appInitLoadingStep ||
      appInitLoadingStep !== 'app-loaded' ||
      (groupApp &&
        groupApp.splash_screen_active &&
        groupApp.splashScreenImage &&
        !splashScreenDissmised)
    "
  >
    <ion-row
      class="ion-justify-content-center ion-align-items-center"
      style="width: 100%; height: 100%"
    >
      <ion-col class="ion-align-self-center">
        <ion-spinner color="dark"></ion-spinner>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-split-pane
    *ngIf="
      groupApp &&
      groupTheme &&
      appInitLoadingStep &&
      appInitLoadingStep === 'app-loaded' &&
      !groupApp.only_loyalty_app &&
      !groupApp.active_reservation_app &&
      (!groupApp.splash_screen_active ||
        !groupApp.splashScreenImage ||
        (groupApp.splash_screen_active &&
          groupApp.splashScreenImage &&
          splashScreenDissmised))
    "
    class="spit-pane-width"
    contentId="main-content"
    [when]="menuMinWidthToDisplay"
  >
    <ion-menu
      *ngIf="selectedIndex"
      [swipeGesture]="false"
      class="side-menu"
      contentId="main-content"
      menuId="sideMenu"
      type="overlay"
      id="side-menu-ionic"
    >
      <ion-header>
        <ion-toolbar
          [ngClass]="{
            headerMenuBackgroundHeaderLogo:
              groupApp &&
              groupApp.design_settings &&
              groupApp.design_settings.sideMenuHeaderLogo &&
              groupApp.headerLogoIconBackground &&
              headerLogo &&
              selectedTheme &&
              (selectedTheme === 'light' ||
                (selectedTheme === 'dark' && !darkHeaderLogo)),
            headerMenuBackground:
              groupApp &&
              groupApp.design_settings &&
              groupApp.design_settings.sideMenuHeaderLogo &&
              !groupApp.headerLogoIconBackground &&
              headerLogo &&
              selectedTheme &&
              (selectedTheme === 'light' ||
                (selectedTheme === 'dark' && !darkHeaderLogo)),
            biggerToolBar:
              groupApp.design_settings &&
              groupApp.design_settings.sideMenuHeaderLogo &&
              headerLogo &&
              windowDimensions &&
              windowDimensions.width >= 800
          }"
        >
          <app-side-menu-header-logo
            [groupApp]="groupApp"
            [flattenedIconBackgroundFlag]="flattenedIconBackgroundFlag"
            [biggerImageLogo]="
              groupApp.design_settings &&
              groupApp.design_settings.sideMenuHeaderLogo &&
              headerLogo &&
              windowDimensions &&
              windowDimensions.width >= 800
                ? true
                : false
            "
            [storeImage]="storeImage"
            [headerLogo]="headerLogo"
            [darkHeaderLogo]="darkHeaderLogo"
            (click)="changeChannel()"
          ></app-side-menu-header-logo>
          <ion-button
            *ngIf="
              groupApp &&
              groupApp.flattenedIconBackground &&
              groupApp.design_settings &&
              groupApp.design_settings.sideMenuHeaderLogo &&
              headerLogo &&
              !sideMenuIsOpen
            "
            [class]="
              selectedTheme === 'dark' && !groupApp.headerLogoDark
                ? groupApp.headerLogoIconBackground
                  ? 'headerLogoIconBackgroundInverted'
                  : 'flattenedIconBackgroundInverted'
                : ((!groupApp.headerLogoIconBackground &&
                    groupApp.flattenedIconBackground === primaryColor) ||
                    (groupApp.headerLogoIconBackground &&
                      groupApp.headerLogoIconBackground === primaryColor)) &&
                  selectedTheme !== 'dark'
                ? 'primaryInverted'
                : ''
            "
            (click)="closeSideMenu()"
            slot="end"
            fill="clear"
          >
            <ion-icon src="assets/ionicons/close.svg"></ion-icon>
          </ion-button>

          <ion-button
            *ngIf="
              groupApp &&
              !groupApp.flattenedIconBackground &&
              groupApp.design_settings &&
              groupApp.design_settings.sideMenuHeaderLogo &&
              headerLogo &&
              !sideMenuIsOpen
            "
            (click)="closeSideMenu()"
            slot="end"
            fill="clear"
          >
            <ion-icon src="assets/ionicons/close.svg"></ion-icon>
          </ion-button>
          <ion-button
            color="dark"
            *ngIf="
              groupApp &&
              (!groupApp.design_settings ||
                !groupApp.design_settings.sideMenuHeaderLogo ||
                !headerLogo) &&
              !sideMenuIsOpen
            "
            (click)="closeSideMenu()"
            slot="end"
            fill="clear"
          >
            <ion-icon src="assets/ionicons/close.svg"></ion-icon>
          </ion-button>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list id="inbox-list" lines="none" style="padding: 0px">
          <ion-list-header
            *ngIf="currentUser"
            style="
              padding-left: 0;
              padding-right: 0;
              padding-top: 8px;
              padding-bottom: 8px;
            "
          >
            <ion-item
              (click)="closeSideMenuAndNavigateAccount()"
              button
              detail="false"
              [class]="
                selectedIndex === 'account-settings'
                  ? 'accountSettingsButton tintSelected'
                  : 'accountSettingsButton'
              "
              lines="none"
              style="width: 100%"
            >
              <ion-avatar slot="start"
                ><img
                  alt="user_avatar"
                  src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png"
              /></ion-avatar>
              <ion-label
                *ngIf="
                  currentUser &&
                  (currentUser.firstName || currentUser.lastName) &&
                  currentUser.mobile
                "
                style="font-size: medium"
              >
                &nbsp;{{ currentUser.firstName ? currentUser.firstName : "" }}
                {{ currentUser.lastName ? currentUser.lastName : "" }}
                <p *ngIf="currentUser.mobile">&nbsp;{{ currentUser.mobile }}</p>
              </ion-label>
              <ion-label
                *ngIf="
                  currentUser &&
                  !currentUser.firstName &&
                  !currentUser.lastName &&
                  currentUser.mobile
                "
                style="font-size: medium"
              >
                &nbsp;{{ currentUser.mobile }}
              </ion-label>
              <ion-button
                class="ion-no-padding"
                style="margin-left: 7px"
                slot="end"
                title="{{ 'edit' | translate }}"
                size="small"
                fill="clear"
              >
                <ion-icon
                  slot="icon-only"
                  src="assets/flatIcons/edit.svg"
                ></ion-icon>
              </ion-button>
            </ion-item>
          </ion-list-header>

          <ion-menu-toggle auto-hide="false">
            <ion-item
              button
              (click)="pageChange('homepage')"
              lines="none"
              detail="false"
              [class.selected]="selectedIndex === 'homepage'"
            >
              <ion-icon
                slot="start"
                style="margin-right: 20px"
                [ios]="'home' + '-outline'"
                [md]="'home' + '-sharp'"
              >
              </ion-icon>
              <ion-label>{{ "pages.homepage" | translate }}</ion-label>
            </ion-item>
            <ion-item
              button
              *ngIf="cartProducts && cartProducts.length > 0"
              (click)="pageChange('cart')"
              lines="none"
              detail="false"
              [class.selected]="selectedIndex === 'cart'"
            >
              <ion-icon
                slot="start"
                style="margin-right: 20px"
                [ios]="'cart' + '-outline'"
                [md]="'cart' + '-sharp'"
              >
              </ion-icon>
              <ion-label>
                {{ "pages.cart" | translate }}
              </ion-label>
              <ion-badge
                *ngIf="cartProductsNumber"
                slot="end"
                color="primary"
                >{{ cartProductsNumber }}</ion-badge
              >
            </ion-item>
            <!-- catalog -->
            <ion-item
              button
              *ngIf="
                ((stores && stores.length === 1) || currentStore) &&
                ((currentStore && !currentStore.showOnlyFavoritesCatalog) ||
                  (currentStore &&
                    currentStore.showOnlyFavoritesCatalog &&
                    currentUser))
              "
              (click)="pageChange('catalog')"
              lines="none"
              detail="false"
              [class.selected]="selectedIndex === 'catalog'"
            >
              <ion-icon
                slot="start"
                style="margin-right: 20px"
                [ios]="'list' + '-outline'"
                [md]="'list' + '-sharp'"
              >
              </ion-icon>

              <ion-label>
                <ion-text
                  *ngIf="
                    !groupApp.design_settings ||
                    !groupApp.design_settings
                      .sideCatalogMenuButtonCustomTextEnabled ||
                    (groupApp.design_settings &&
                      groupApp.design_settings
                        .sideCatalogMenuButtonCustomTextEnabled &&
                      (!groupApp.design_settings
                        .sideCatalogMenuButtonCustomText ||
                        !groupApp.design_settings
                          .sideCatalogMenuButtonCustomText[selectedLanguage]))
                  "
                >
                  {{ "pages.catalog" | translate }}
                </ion-text>

                <ion-text
                  *ngIf="
                    groupApp.design_settings &&
                    groupApp.design_settings
                      .sideCatalogMenuButtonCustomTextEnabled &&
                    groupApp.design_settings.sideCatalogMenuButtonCustomText &&
                    groupApp.design_settings.sideCatalogMenuButtonCustomText[
                      selectedLanguage
                    ]
                  "
                >
                  {{
                    groupApp.design_settings.sideCatalogMenuButtonCustomText[
                      selectedLanguage
                    ]
                  }}
                </ion-text>
                <p
                  *ngIf="
                    stores &&
                    stores.length > 1 &&
                    currentStore &&
                    currentStore.store_name &&
                    selectedLanguage
                  "
                >
                  {{
                    currentStore.store_name_mobileorder &&
                    currentStore.store_name_mobileorder[selectedLanguage]
                      ? currentStore.store_name_mobileorder[selectedLanguage]
                      : currentStore.store_name_langs &&
                        currentStore.store_name_langs[selectedLanguage]
                      ? currentStore.store_name_langs[selectedLanguage]
                      : currentStore.store_name_langs &&
                        currentStore.store_name_langs.el
                      ? currentStore.store_name_langs.el
                      : currentStore.store_name
                  }}
                </p>
              </ion-label>
            </ion-item>
            <!-- affiliate -->
            <ion-item
              *ngIf="
                stores &&
                groupApp &&
                (groupApp.affiliateActive ||
                  (groupApp.affiliateProductionMode &&
                    currentUser &&
                    currentUser.mobile === '6945076868'))
              "
              (click)="pageChange('affiliate')"
              lines="none"
              button
              detail="false"
              [class.selected]="selectedIndex === 'affiliate'"
            >
              <ion-icon
                slot="start"
                style="margin-right: 20px"
                [ios]="'megaphone' + '-outline'"
                [md]="'megaphone' + '-sharp'"
              ></ion-icon>
              <ion-label>{{
                groupApp.affiliateSideMenuTitle[selectedLanguage]
              }}</ion-label>
            </ion-item>
            <!-- kiosk -->
            <ion-item
              button
              *ngIf="
                groupApp &&
                (groupApp.pointsSystem || groupApp.couponsPage) &&
                atLeastOneStoreKioskActive &&
                !(
                  groupApp.design_settings &&
                  groupApp.design_settings.dm_kiosk_app_disable_mobileorder
                )
              "
              (click)="pageChange('kiosk')"
              lines="none"
              detail="false"
              [class.selected]="selectedIndex === 'kiosk'"
            >
              <ion-icon
                slot="start"
                style="margin-right: 20px"
                [ios]="'tablet-portrait' + '-outline'"
                [md]="'tablet-portrait' + '-sharp'"
              ></ion-icon>
              <ion-label>{{ "pages.kiosk" | translate }}</ion-label>
            </ion-item>
            <!-- favorite products -->
            <ion-item
              *ngIf="
                currentUser &&
                ((stores && stores.length === 1) || currentStore) &&
                currentStore &&
                !currentStore.showOnlyFavoritesCatalog
              "
              button
              (click)="pageChange('favoriteProducts')"
              lines="none"
              detail="false"
              [class.selected]="selectedIndex === 'favoriteProducts'"
            >
              <ion-icon
                slot="start"
                style="margin-right: 20px"
                [ios]="'heart' + '-outline'"
                [md]="'heart' + '-sharp'"
              >
              </ion-icon>
              <ion-label>{{ "pages.favorites" | translate }}</ion-label>
            </ion-item>
            <!-- orders -->
            <ion-item
              button
              *ngIf="currentUser"
              (click)="pageChange('orders')"
              lines="none"
              detail="false"
              [class.selected]="selectedIndex === 'orders'"
            >
              <ion-icon
                slot="start"
                style="margin-right: 20px"
                [ios]="'reader' + '-outline'"
                [md]="'reader' + '-sharp'"
              ></ion-icon>
              <ion-label>{{ "pages.orders" | translate }}</ion-label>
            </ion-item>
            <!-- reservations -->
            <ion-item
              button
              *ngIf="currentStore && currentStore.reservationMobileorderActive"
              (click)="pageChange('reservations')"
              lines="none"
              detail="false"
              [class.selected]="selectedIndex === 'reservations'"
            >
              <ion-icon
                style="margin-right: 20px"
                slot="start"
                [ios]="'calendar' + '-outline'"
                [md]="'calendar' + '-sharp'"
              ></ion-icon>
              <ion-label>{{ "pages.reservations" | translate }}</ion-label>
            </ion-item>
            <!-- payment-cards -->
            <ion-item
              button
              *ngIf="
                currentUser &&
                (showPaymentCards ||
                  (currentUser.admin && showPaymentCardsAdminOnly) ||
                  showPaymentCardsCustomerOnly)
              "
              (click)="pageChange('payment-cards')"
              lines="none"
              detail="false"
              [class.selected]="selectedIndex === 'payment-cards'"
            >
              <ion-icon
                slot="start"
                style="margin-right: 20px"
                [ios]="'card' + '-outline'"
                [md]="'card' + '-sharp'"
              >
              </ion-icon>
              <ion-label>{{ "pages.payment-cards" | translate }}</ion-label>
            </ion-item>
            <!-- points -->
            <ion-item
              button
              *ngIf="groupApp && groupApp.pointsSystem"
              (click)="pageChange('points')"
              lines="none"
              detail="false"
              [class.selected]="selectedIndex === 'points'"
            >
              <ion-icon
                slot="start"
                style="margin-right: 20px"
                [ios]="'gift' + '-outline'"
                [md]="'gift' + '-sharp'"
              >
              </ion-icon>
              <ion-label
                >{{ "pages.points" | translate }}
                <div
                  style="
                    position: absolute;
                    bottom: 5px;
                    z-index: 100;
                    height: 3px;
                    width: 100%;
                  "
                  *ngIf="
                    currentUser &&
                    userPoints &&
                    highestReward &&
                    highestReward.user_points_percent &&
                    userPoints.pointsSystem &&
                    userPoints.pointsSystem.active > 0
                  "
                >
                  <ion-progress-bar
                    [color]="pointsProgressBarColor"
                    [value]="highestReward.user_points_percent / 100"
                  >
                  </ion-progress-bar>
                </div>
              </ion-label>

              <ion-badge
                [color]="pointsProgressBarColor"
                slot="end"
                *ngIf="
                  currentUser &&
                  userPoints &&
                  userPoints.pointsSystem &&
                  userPoints.pointsSystem.active > 0
                "
              >
                {{
                  userPoints.pointsSystem.active
                    ? userPoints.pointsSystem.active
                    : "0"
                }}
              </ion-badge>
            </ion-item>
            <!-- discount-coupons -->
            <ion-item
              button
              *ngIf="currentUser && groupApp && groupApp.couponsPage"
              (click)="pageChange('discount-coupons')"
              lines="none"
              detail="false"
              [class.selected]="selectedIndex === 'discount-coupons'"
            >
              <ion-icon
                slot="start"
                style="margin-right: 20px"
                [ios]="'ticket' + '-outline'"
                [md]="'ticket' + '-sharp'"
              ></ion-icon>
              <ion-label>{{ "pages.discount-coupons" | translate }}</ion-label>
              <ion-badge
                *ngIf="
                  activeCoupons &&
                  activeCoupons.length &&
                  activeCoupons.length > 0
                "
                slot="end"
                color="primary"
                >{{ activeCoupons.length }}</ion-badge
              >
            </ion-item>

            <!-- Contests Page -->

            <ion-item
              button
              *ngIf="groupApp && groupApp.contestSystemEnabled"
              (click)="pageChange('contests')"
              lines="none"
              detail="false"
              [class.selected]="selectedIndex === 'contests'"
            >
              <ion-icon
                slot="start"
                style="margin-right: 20px"
                [ios]="'trophy' + '-outline'"
                [md]="'trophy' + '-sharp'"
              ></ion-icon>
              <ion-label>{{ "pages.contests" | translate }}</ion-label>
              <ion-badge
                *ngIf="activeDrawsNumber && activeDrawsNumber > 0"
                slot="end"
                color="primary"
              >
                {{ activeDrawsNumber }}</ion-badge
              >
            </ion-item>

            <!-- delivery areas -->
            <ion-item
              *ngIf="currentStore && !currentStore.disableDeliveryAreas"
              button
              (click)="pageChange('deliveryAreas')"
              lines="none"
              detail="false"
              [class.selected]="selectedIndex === 'deliveryAreas'"
            >
              <ion-icon
                slot="start"
                style="margin-right: 20px"
                [ios]="'map' + '-outline'"
                [md]="'map' + '-sharp'"
              >
              </ion-icon>
              <ion-label>{{ "pages.delivery-areas" | translate }}</ion-label>
            </ion-item>
            <!-- loyalty system -->
            <ion-item
              *ngIf="currentStore && groupApp.active_loyalty_app"
              button
              (click)="pageChange('loyaltySystem')"
              lines="none"
              detail="false"
              [class.selected]="selectedIndex === 'loyaltySystem'"
            >
              <ion-icon
                slot="start"
                style="margin-right: 20px"
                name="star-outline"
              ></ion-icon>
              <ion-label>{{ "pages.loyalty-system" | translate }}</ion-label>
            </ion-item>
            <!-- ratings -->
            <ion-item
              *ngIf="
                currentUser &&
                stores &&
                ((stores.length === 1 && stores[0].reviewSystemActivated) ||
                  stores.length > 1)
              "
              (click)="pageChange('ratings')"
              lines="none"
              button
              detail="false"
              [class.selected]="selectedIndex === 'ratings'"
            >
              <ion-icon
                slot="start"
                style="margin-right: 20px"
                [ios]="'star-half' + '-outline'"
                [md]="'star-half' + '-sharp'"
              ></ion-icon>
              <ion-label>{{ "pages.ratings" | translate }}</ion-label>
            </ion-item>
            <!-- store schedule -->
            <ion-item
              button
              (click)="pageChange('storeSchedule')"
              lines="none"
              detail="false"
              [class.selected]="selectedIndex === 'storeSchedule'"
            >
              <ion-icon
                slot="start"
                style="margin-right: 20px"
                [ios]="'information' + '-outline'"
                [md]="'information' + '-sharp'"
              ></ion-icon>
              <ion-label>{{ "pages.store-schedule" | translate }}</ion-label>
            </ion-item>
            <!-- payment terms -->
            <ion-item
              button
              *ngIf="stores && showPaymentTerms"
              (click)="pageChange('payment-terms')"
              lines="none"
              detail="false"
              [class.selected]="selectedIndex === 'payment-terms'"
            >
              <ion-icon
                style="margin-right: 20px"
                slot="start"
                [ios]="'clipboard' + '-outline'"
                [md]="'clipboard' + '-sharp'"
              ></ion-icon>
              <ion-label>{{ "pages.payment-terms" | translate }}</ion-label>
            </ion-item>
            <!-- logout -->
            <ion-item
              button
              *ngIf="currentUser"
              (click)="logout()"
              lines="none"
              detail="false"
            >
              <ion-icon
                style="margin-right: 20px"
                slot="start"
                [ios]="'log-out' + '-outline'"
                [md]="'log-out' + '-sharp'"
              ></ion-icon>
              <ion-label>{{ "pages.logout" | translate }}</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
      <app-menu-footer
        [appChannel]="appChannel"
        [version]="version"
      ></app-menu-footer>
    </ion-menu>
    <ion-router-outlet
      queryParamsHandling="merge"
      [swipeGesture]="false"
      *ngIf="
        groupApp &&
        (!groupApp.splash_screen_active ||
          !groupApp.splashScreenImage ||
          (groupApp.splash_screen_active &&
            groupApp.splashScreenImage &&
            splashScreenDissmised))
      "
      id="main-content"
      animated="false"
    ></ion-router-outlet>
  </ion-split-pane>
  <ion-router-outlet
    queryParamsHandling="merge"
    [swipeGesture]="false"
    *ngIf="
      groupApp &&
      groupApp.only_loyalty_app &&
      groupTheme &&
      appInitLoadingStep &&
      appInitLoadingStep === 'app-loaded' &&
      (!groupApp.splash_screen_active ||
        !groupApp.splashScreenImage ||
        (groupApp.splash_screen_active &&
          groupApp.splashScreenImage &&
          splashScreenDissmised))
    "
    id="main-content"
    animated="false"
  ></ion-router-outlet>
  <ion-router-outlet
    queryParamsHandling="merge"
    [swipeGesture]="false"
    *ngIf="
      groupApp &&
      groupApp.active_reservation_app &&
      groupTheme &&
      appInitLoadingStep &&
      appInitLoadingStep === 'app-loaded' &&
      (!groupApp.splash_screen_active ||
        !groupApp.splashScreenImage ||
        (groupApp.splash_screen_active &&
          groupApp.splashScreenImage &&
          splashScreenDissmised))
    "
    id="main-content"
    animated="false"
  ></ion-router-outlet>
</ion-app>
