<ion-header>
  <ion-toolbar mode="md">
    <ion-title style="padding-right: 5px" size="small">{{
      "new-address-modal.add-address" | translate
    }}</ion-title>
    <ion-buttons slot="end">
      <ion-button
        *ngIf="newAddress"
        [disabled]="loading"
        (click)="resetTheProccess()"
        fill="clear"
      >
        <ion-icon
          src="assets/ionicons/refresh-circle-outline.svg"
          slot="icon-only"
        ></ion-icon>
      </ion-button>
      <ion-button color="danger" fill="clear" (click)="ondismiss()">
        <ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- <form action="?" method="POST">
    <div
      class="g-recaptcha"
      data-sitekey="6LcGSQkcAAAAAJp14bLhU2EwMTbzO8WMNM2FNMZD"
    ></div>

    <input type="submit" value="Submit" />
  </form> -->
  <ion-backdrop *ngIf="geoLocationAnimation"> </ion-backdrop>
  <ion-grid
    *ngIf="geoLocationAnimation"
    style="width: 100%; height: 100%; position: absolute; top: 0; z-index: 1000"
  >
    <ion-row
      style="width: 100%; height: 100%"
      class="ion-align-items-center ion-justify-content-center"
    >
      <ion-col class="ion-align-self-center ion-text-center">
        <ion-grid style="max-width: 300px">
          <ng-lottie
            (animationCreated)="animationCreated($event)"
            [options]="options"
          ></ng-lottie>
        </ion-grid>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid
    class="ion-no-padding ion-no-margin"
    style="width: 100%; position: absolute; top: 0px; z-index: 100"
  >
    <ion-row
      class="ion-padding"
      *ngIf="!libraryLoaded || loading"
      style="width: 100%"
    >
      <ion-col class="ion-text-center">
        <ion-spinner> </ion-spinner>
      </ion-col>
    </ion-row>
    <ion-row [hidden]="newAddress" style="padding: 5px; padding-left: 10px">
      <ion-col
        [class]="
          inputPulseAnimation
            ? 'animate__animated animate__pulse ion-no-margin ion-no-padding'
            : 'ion-no-margin ion-no-padding'
        "
        size="10"
      >
        <div
          [hidden]="!libraryLoaded || loading || gpsMode"
          style="width: 100%"
          id="locationField"
        >
          <input
            #autoCompleteInput
            class="input-button"
            id="autocomplete"
            placeholder="{{ 'new-address-modal.add-new-address' | translate }}"
            data-tap-disabled="true"
            [(ngModel)]="search"
            (focus)="onAutoCompleteFocus()"
            (blur)="onAutoCompleteBlur()"
            type="text"
          />
        </div>
      </ion-col>
      <ion-col
        *ngIf="libraryLoaded && !loading && !gpsMode"
        size="2"
        class="ion-text-center"
      >
        <ion-button mode="md" (click)="onClickSearch()">
          <ion-icon src="assets/ionicons/search.svg"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>

    <ion-row
      [hidden]="geoLocationAnimation"
      style="width: 100%"
      *ngIf="gpsMode && addressFromGps"
    >
      <ion-col>
        <ion-card color="light">
          <ion-item>
            <ion-label class="ion-text-center">
              <ion-spinner *ngIf="getAddressFromCoordinatesLoading">
              </ion-spinner>

              <div *ngIf="!getAddressFromCoordinatesLoading && addressFromGps">
                <!-- <strong>{{ "new-address-modal.address" | translate }}:</strong> -->

                <ion-text color="dark">
                  {{ addressFromGps.street }}
                  {{ addressFromGps.street_number }},
                  {{ addressFromGps.postal_code }}
                </ion-text>
              </div>

              <ion-grid
                [hidden]="
                  !mobilePlatform ||
                  !gpsMode ||
                  !addressFromGps ||
                  geoLocationAnimation
                "
                style="width: 100%; z-index: 100"
              >
                <ion-row style="width: 100%">
                  <ion-col size="12">
                    <div>
                      <ion-button
                        [disabled]="getAddressFromCoordinatesLoading"
                        size="small"
                        (click)="goToNextStep()"
                        >{{ "new-address-modal.fix-address" | translate }}
                      </ion-button>
                      <!-- <ion-button (click)="checkLocationFromGps()" color="primary">{{
                    "new-address-modal.locate-again" | translate
                  }}</ion-button> -->
                    </div>
                  </ion-col>
                </ion-row>
              </ion-grid>
              <!-- <div>
          <strong
            >{{ "new-address-modal.street-number" | translate }}:</strong
          >
        </div>
        <div>
          <strong
            >{{ "new-address-modal.post-code" | translate }}:</strong
          >
        </div> -->
            </ion-label>
          </ion-item>
        </ion-card>
      </ion-col>
    </ion-row>

    <ion-list
      class="ion-no-padding ion-no-margin"
      *ngIf="newAddress && libraryLoaded && !loading && !gpsMode"
    >
      <ion-row style="width: 100%">
        <ion-col class="ion-no-margin ion-no-padding" size="5">
          <ion-item
            style="width: 97%"
            [color]="validation && !validation.street ? 'notValid' : ''"
          >
            <ion-label position="stacked">
              {{ "new-address-modal.street" | translate }}
            </ion-label>
            <ion-input
              placeholder="{{ 'write-here' | translate }}"
              type="text"
              [disabled]="loadingForInputs"
              [class]="
                validation && !validation.street ? 'ng-invalid' : 'ng-valid'
              "
              (ionChange)="checkAddressFields()"
              (ionFocus)="focusOnInput()"
              (ionBlur)="BlurOnInput()"
              [(ngModel)]="newAddress.address_components.street"
            >
            </ion-input>
          </ion-item>
        </ion-col>

        <ion-col class="ion-no-margin ion-no-padding" size="3">
          <ion-item
            style="width: 97%"
            [color]="validation && !validation.street_number ? 'notValid' : ''"
          >
            <ion-label position="stacked">
              {{ "new-address-modal.street-number" | translate }}
            </ion-label>
            <ion-input
              placeholder="{{ 'write-here' | translate }}"
              type="text"
              [disabled]="loadingForInputs"
              [class]="
                validation && !validation.street_number
                  ? 'ng-invalid'
                  : 'ng-valid'
              "
              [(ngModel)]="newAddress.address_components.street_number"
              (ionChange)="checkAddressFields()"
              (ionFocus)="focusOnInput()"
              (ionBlur)="BlurOnInput()"
            >
            </ion-input>
          </ion-item>
        </ion-col>
        <!-- <ion-col class="ion-no-margin ion-no-padding" size="6">
          <ion-item>
            <ion-label position="stacked">
              {{ "new-address-modal.city" | translate }}
            </ion-label>
            <ion-input [(ngModel)]="newAddress.city"> </ion-input>
          </ion-item>
        </ion-col>
        <ion-col class="ion-no-margin ion-no-padding" size="6">
          <ion-item>
            <ion-label position="stacked">
              {{ "new-address-modal.region" | translate }}
            </ion-label>
            <ion-input [(ngModel)]="newAddress.region"> </ion-input>
          </ion-item>
        </ion-col> -->
        <ion-col class="ion-no-margin ion-no-padding" size="4">
          <ion-item
            style="width: 97%"
            [color]="validation && !validation.post_code ? 'notValid' : ''"
          >
            <ion-label position="stacked">
              {{ "new-address-modal.post-code" | translate }}
            </ion-label>
            <ion-input
              [disabled]="loadingForInputs"
              placeholder="{{ 'write-here' | translate }}"
              type="text"
              [class]="
                validation && !validation.post_code ? 'ng-invalid' : 'ng-valid'
              "
              (ionChange)="checkAddressFields()"
              (ionFocus)="focusOnInput()"
              (ionBlur)="BlurOnInput()"
              [(ngModel)]="newAddress.address_components.postal_code"
            >
            </ion-input>
          </ion-item>
        </ion-col>

        <ion-col size="12" class="ion-text-center">
          <ion-button
            [disabled]="loadingForInputs || saveAddressLoading"
            [color]="
              addressInputsChanged && addressInputsFocus && !saveAddressLoading
                ? 'danger'
                : 'primary'
            "
            (click)="
              addressInputsChanged && addressInputsFocus && !saveAddressLoading
                ? onCheckAddress()
                : onSaveAddress()
            "
          >
            <ion-text *ngIf="!loadingForInputs && !saveAddressLoading">
              {{
                addressInputsChanged &&
                addressInputsFocus &&
                !saveAddressLoading
                  ? ("new-address-modal.check-address" | translate)
                  : ("new-address-modal.save-address" | translate)
              }}
            </ion-text>
            <ion-spinner
              *ngIf="loadingForInputs || saveAddressLoading"
            ></ion-spinner>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-list>
    <div
      style="z-index: 100"
      [hidden]="
        !mobilePlatform ||
        !mobileMarkerInfo ||
        !mobileMarkerInfo.header ||
        !newAddress ||
        gpsMode
      "
    >
      <ion-card color="primary" mode="md" style="z-index: 101">
        <ion-card-content
          style="padding: 5px; font-size: small"
          class="ion-no-padding ion-no-margin"
        >
          <div
            style="color: var(--ion-color-primary-contrast)"
            *ngIf="mobileMarkerInfo && mobileMarkerInfo.header"
          >
            <strong> {{ mobileMarkerInfo.header }}</strong>
          </div>
          <div
            style="color: var(--ion-color-primary-contrast)"
            *ngIf="mobileMarkerInfo && mobileMarkerInfo.message1"
          >
            {{ mobileMarkerInfo.message1 }}
          </div>
          <div
            style="color: var(--ion-color-primary-contrast)"
            *ngIf="mobileMarkerInfo && mobileMarkerInfo.message2"
          >
            {{ mobileMarkerInfo.message2 }}
          </div>
        </ion-card-content>
      </ion-card>
    </div>
  </ion-grid>

  <div
    [hidden]="!libraryLoaded || loading || hideMap"
    id="map"
    style="width: 100%; height: 100%"
    data-tap-disabled="true"
  ></div>

  <div
    [hidden]="!mobilePlatform || !mobileMarkerVisible"
    style="
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -50px;
      margin-left: -50px;
      height: 100px;
      width: 100px;
    "
  >
    <img
      style="
        position: absolute;
        top: 20px;
        padding: 0;
        left: 50%;
        margin-top: -25px;
        margin-left: -25px;
      "
      width="50px"
      height="50px"
      [class]="
        showUpInfoMarkerAnimation && !showDownInfoMarkerAnimation
          ? 'moveUpInfoMarkerAnimation'
          : !showUpInfoMarkerAnimation && showDownInfoMarkerAnimation
          ? 'moveDownInfoMarkerAnimation'
          : 'noMoveInfoMarker'
      "
      src=" assets/ionicons/home.png"
    />
    <ion-icon
      [color]="selectedTheme && selectedTheme === 'dark' ? 'white' : 'black'"
      style="
        font-size: 10px;
        position: absolute;
        top: 50%;
        left: 50%;
        padding: 0;
        margin-top: -5px;
        margin-left: -5px;
      "
      src="assets/flatIcons/xmark.svg"
    ></ion-icon>
  </div>
  <ion-grid
    [hidden]="
      hideDeliveryCard || (currentStore && currentStore.disableDeliveryAreas)
    "
    [class]="
      !gpsMode
        ? 'deliveryAreaCard ion-no-padding ion-no-margin'
        : 'deliveryAreaCardGPS ion-no-padding ion-no-margin'
    "
  >
    <ion-card
      class="ion-align-items-center ion-text-center ion-margin"
      *ngIf="renderCardDeliveryAreas && renderCardDeliveryAreas.length === 0"
      color="danger"
    >
      <ion-card-content style="margin: 0px; padding: 5px">
        <ion-label style="font-weight: 800">
          <ion-text color="white">
            {{
              "new-address-modal.no-delivery-areas-that-serves-your-location"
                | translate
            }}
          </ion-text>
        </ion-label>
      </ion-card-content>
    </ion-card>
    <ion-row
      class="ion-no-padding ion-no-margin"
      *ngIf="deliveryAreas && deliveryAreas.length"
      style="z-index: 20; max-width: 230px"
    >
      <ion-col
        *ngIf="renderCardDeliveryAreas && renderCardDeliveryAreas.length"
        size="12"
        class="ion-no-padding ion-no-margin"
      >
        <ion-card
          detail="false"
          (click)="openDeliveryAreaInfoModal(area)"
          style="z-index: 21; margin: 5px"
          class="ion-align-items-center ion-text-center ion-no-margin ion-no-padding"
          button
          color="light"
          *ngFor="
            let area of renderCardDeliveryAreas;
            trackBy: trackDeliveryAreas
          "
        >
          <ion-card-content style="margin: 0; padding: 0">
            <div
              *ngIf="area && area.fillColor"
              [ngStyle]="{
                'background-color': area && area.fillColor ? area.fillColor : ''
              }"
              value="1"
              style="height: 5px"
            ></div>
            <ion-label style="font-weight: 800">
              <div *ngIf="area.store">
                <ion-text color="dark"> {{ area.store }} </ion-text>
              </div>
              <div *ngIf="area">
                <ion-text color="dark" style="font-size: small">
                  {{ "delivery-areas.minimum-order" | translate }}&nbsp;{{
                    area.minOrder
                  }}€&nbsp;
                </ion-text>
              </div>
              <div *ngIf="area" style="font-size: small">
                <span
                  *ngIf="
                    area.deliveryCost &&
                    area.deliveryCost !== '0' &&
                    !area.delivery_cost_per_kilometer_active
                  "
                >
                  <ion-text color="dark"
                    >{{
                      "delivery-areas.cost-for-delivery" | translate
                    }}:&nbsp;{{ area.deliveryCost }}€</ion-text
                  >&nbsp;
                </span>

                <span
                  *ngIf="
                    area.deliveryCost &&
                    area.deliveryCost !== '0' &&
                    area.delivery_cost_per_kilometer_active
                  "
                >
                  <ion-text color="dark"
                    ><strong
                      >{{
                        "delivery-areas.cost-for-delivery" | translate
                      }}:</strong
                    >&nbsp;{{ area.deliveryCost }}€
                    {{ "delivery-areas.per" | translate }}
                    {{
                      area.step_active && area.step_distance
                        ? area.step_distance + " " + ("meters" | translate)
                        : "km"
                    }}</ion-text
                  >

                  <ion-icon
                    color="dark"
                    style="
                      vertical-align: middle;
                      margin-left: 3px;
                      font-size: 21px;
                    "
                    name="information-circle-outline"
                  >
                  </ion-icon>
                </span>
              </div>
              <div
                style="font-size: small"
                *ngIf="area && area.free_delivery_over_amount"
              >
                <ion-text color="danger">
                  {{ "delivery-areas.free-delivery-over" | translate }}
                  {{
                    area.free_delivery_over_amount | twoDecimalsRound : false
                  }}€
                </ion-text>
              </div>
              <div>
                <span *ngIf="area && area.scheduleComment && area.showSchedule">
                  <ion-text style="font-size: small" color="danger">
                    {{ area.scheduleComment }}
                  </ion-text>
                </span>
              </div>
              <div>
                <span *ngIf="area && area.comment">
                  <ion-text color="dark" style="font-size: small">{{
                    area.comment
                  }}</ion-text>
                </span>
              </div>
            </ion-label>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>

    <ion-grid
      *ngIf="newAddress && newAddress.unverified"
      style="max-width: 250px"
      class="ion-text-center"
    >
      <ion-item-divider color="danger" mode="ios">
        <ion-text style="font-size: small">{{
          "new-address-modal.unverified-address" | translate
        }}</ion-text>
      </ion-item-divider>
    </ion-grid>
  </ion-grid>

  <ion-fab
    style="margin-bottom: 20px"
    [hidden]="
      getAddressFromCoordinatesLoading ||
      geoLocationAnimation ||
      newAddress ||
      !mobilePlatform
    "
    vertical="bottom"
    horizontal="end"
    slot="fixed"
  >
    <ion-fab-button
      (click)="gpsMode ? checkLocationFromGps() : gpsPromptAlert()"
    >
      <ion-icon src="assets/flatIcons/gps.svg"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>
