import { Pipe, PipeTransform } from "@angular/core";
import * as _ from "lodash";

@Pipe({
  name: "filterEmptyCategoriesForOnlyDineInDeliveryPickupProducts",
})
export class FilterEmptyCategoriesForOnlyDineInDeliveryPickupProductsPipe
  implements PipeTransform
{
  transform(categories: any, orderPickup: any, dineIn: any): any {
    const filteredCategories = _.filter(_.cloneDeep(categories), (category) => {
      const categoryProducts = _.filter(
        _.cloneDeep(category.products),
        (product) => {
          if (product.hideOtherDeliveryMethods) {
            if (orderPickup == true && dineIn == false) {
              //take away selected

              if (product.pickup || (!product.delivery && !product.dinein)) {
                return product;
              }
            } else if (orderPickup == false && dineIn == false) {
              //delivery selected

              if (product.delivery || (!product.pickup && !product.dinein)) {
                return product;
              }
            } else if (orderPickup == false && dineIn == true) {
              //dine in selected

              if (product.dinein || (!product.pickup && !product.delivery)) {
                return product;
              }
            } else {
              return product;
            }
          } else {
            return product;
          }
        }
      );

      if (
        (categoryProducts && categoryProducts.length) ||
        !category.products ||
        !category.products.length
      ) {
        return category;
      }
    });

    _.each(categories, (categ) => {
      const catIndex = _.findIndex(filteredCategories, {
        category_id: categ.category_id,
      });

      if (catIndex === -1) {
        categ.emptyCategoryForSelectedDeliveryMethod = true;
      } else {
        categ.emptyCategoryForSelectedDeliveryMethod = false;
      }
    });

    return categories;
  }
}
