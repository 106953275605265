import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { distinctUntilChanged, Subscription } from "rxjs";
import * as _ from "lodash";
import * as selectors from "../../store/app.selectors";
import * as fromApp from "../../store/app.reducer";
import { isMoment } from "moment-timezone";
import moment from "moment";
import { TranslateService } from "@ngx-translate/core";
import { ModalController, Platform } from "@ionic/angular";

@Component({
  selector: "app-coupons-schedule-info-popup",
  templateUrl: "./coupons-schedule-info-popup.component.html",
  styleUrls: ["./coupons-schedule-info-popup.component.scss"],
})
export class CouponsScheduleInfoPopupComponent implements OnInit, OnDestroy {
  public couponsSchedule: any;
  public todayIsCouponsExceptionDay: any = null;
  public currentDayName = moment().format("dddd").toLowerCase();
  public currentDayCouponsSchedule: any;
  public couponsExceptionDaysSchedule: any;
  public storeInfo: any;
  private subscription: Subscription;
  private unsubscribeBackEvent: Subscription;

  constructor(
    private store: Store<fromApp.AppState>,
    private translateService: TranslateService,
    private modalController: ModalController,
    private platform: Platform
  ) {}

  closeModal() {
    this.modalController.dismiss();
  }

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );
    this.subscription = this.store
      .select(selectors.getStoreInfo)
      .pipe(distinctUntilChanged())
      .subscribe((storeInfo) => {
        if (storeInfo && !_.isEqual(this.storeInfo, storeInfo)) {
          this.storeInfo = _.cloneDeep(storeInfo);
          this.couponsSchedule = this.formatStoreSchedule(
            this.storeInfo.couponsSchedule
          );
          console.log("coupon schedule", this.couponsSchedule);
          if (!_.isEmpty(this.storeInfo.couponsSchedule)) {
            console.log("couponsschedule", this.storeInfo.couponsSchedule);
            this.initializeActions(
              _.cloneDeep(this.storeInfo.couponsSchedule),
              "couponsSchedule"
            );
          }
          if (!_.isEmpty(this.storeInfo.couponsExceptionDays)) {
            this.initializeActions(
              _.cloneDeep(this.storeInfo.couponsExceptionDays),
              "couponsExceptionDays"
            );
          }

          console.log("coupons schedule", this.couponsSchedule);
        }
      });
  }

  initializeActions(schedule, type) {
    console.log("initialize actions called", schedule, type);
    if (type === "couponsExceptionDays") {
      _.each(schedule, (item, key) => {
        let day = item.date.split("-");
        let date = moment();
        console.log(
          "valuesss",
          parseInt(day[0]),
          parseInt(day[1]),
          parseInt(day[2])
        );
        date.set("date", parseInt(day[0]));
        date.set("month", parseInt(day[1]) - 1);
        date.set("year", parseInt(day[2]));
        let today = moment();
        console.log("date today", date, today);
        console.log("diff", date.diff(today, "days"), key);
        if (date.diff(today, "days") > 0 && date.diff(today, "days") < 10) {
          item.showExceptionDay = true;
        } else {
          item.showExceptionDay = false;
        }
      });
    }

    console.log("schedule", schedule);

    if (!_.isEmpty(schedule)) {
      let allDaysSchedule = schedule.map((item) => {
        if (!_.isObject(item)) {
          item = {};
        }

        if (!_.isArray(item.time_periods)) {
          item.time_periods = [];
        }
        if (item.date) {
          let day = item.date.split("-");
          // let month = item.date.split("-", 1);
          // let year = item.date.split("-", 2);
          // let date = moment().set({
          //   day: parseInt(day[0]),
          //   month: parseInt(day[1]) - 1,
          //   year: parseInt(day[2]),
          //   hour: 0,
          //   minute: 0,
          //   second: 0,
          // });
          let date = moment();
          date.date(parseInt(day[0]));
          date.month(parseInt(day[1]) - 1);
          date.year(parseInt(day[2]));

          console.log(
            "moment date values",
            parseInt(day[0]),
            parseInt(day[1]),
            parseInt(day[2])
          );
          console.log("moment date object", date);
          item.date = _.cloneDeep(date);
        }
        for (let index = 0; index < item.time_periods.length; index++) {
          if (
            !_.isNumber(item.time_periods[index].stop) ||
            !item.time_periods[index].stop
          ) {
            item.time_periods[index].stop = 1;
          }

          if (
            !_.isNumber(item.time_periods[index].start) ||
            !item.time_periods[index].start
          ) {
            item.time_periods[index].start = 1;
          }

          const start_minutes = +item.time_periods[index].start / 60;
          const start_hours = start_minutes / 60;
          const start_rest_minutes = start_minutes % 60;

          item.time_periods[index].start_timestamp = moment(0)
            .hour(start_hours)
            .minutes(0)
            .add(start_rest_minutes, "minutes")
            .startOf("minute")
            .format();

          const stop_minutes = +item.time_periods[index].stop / 60;
          const stop_hours = stop_minutes / 60;
          const stop_rest_minutes = stop_minutes % 60;

          item.time_periods[index].stop_timestamp = moment(0)
            .hour(stop_hours)
            .minutes(0)
            .add(stop_rest_minutes, "minutes")
            .startOf("minute")
            .format();
        }

        return item;
      });

      if (type === "couponsSchedule") {
        this.currentDayCouponsSchedule = _.cloneDeep(
          allDaysSchedule[moment().weekday()]
        );
      } else if (type === "couponsExceptionDays") {
        this.couponsExceptionDaysSchedule = _.cloneDeep(allDaysSchedule);
        this.checkIfIsExceptionDay(this.couponsExceptionDaysSchedule);
      }
    }

    console.log("type shedule", schedule);
  }

  formatStoreSchedule(schedule) {
    var weekDays = [
      this.translateService.instant("store-schedule.sunday"),
      this.translateService.instant("store-schedule.monday"),
      this.translateService.instant("store-schedule.tuesday"),
      this.translateService.instant("store-schedule.wednesday"),
      this.translateService.instant("store-schedule.thursday"),
      this.translateService.instant("store-schedule.friday"),
      this.translateService.instant("store-schedule.saturday"),
    ];

    var singleScheduleArray = [];

    _.each(schedule, (scheduleDay, key, list) => {
      if (scheduleDay.active && !scheduleDay.all_day) {
        var commentSchedule = "";
        scheduleDay.time_periods.forEach((tp, i) => {
          if (i > 0) {
            commentSchedule += ", ";
          }
          commentSchedule +=
            this.toHHMMSS(tp.start) + " - " + this.toHHMMSS(tp.stop);
        });

        singleScheduleArray.push({
          day: weekDays[key],
          schedule: commentSchedule,
        });
      } else if (scheduleDay.active && scheduleDay.all_day) {
        singleScheduleArray.push({
          day: weekDays[key],
          schedule: null,
        });
      } else {
        singleScheduleArray.push({
          day: weekDays[key],
          schedule: "disabled",
        });
      }
    });

    var sunday = _.head(singleScheduleArray);
    singleScheduleArray = _.takeRight(singleScheduleArray, 6);
    singleScheduleArray.push(sunday);
    return singleScheduleArray;
  }

  toHHMMSS(val) {
    console.log("val", val);
    if (val) {
      let sec_num = parseInt(val, 10); // don't forget the second param
      let hours = Math.floor(sec_num / 3600);
      let minutes = Math.floor((sec_num - hours * 3600) / 60);
      let newhours = hours.toString();
      let newmins = minutes.toString();
      if (hours < 10) {
        newhours = "0" + hours;
      }
      if (minutes < 10) {
        newmins = "0" + minutes;
      }

      return newhours + ":" + newmins;
    } else {
      return "00:00";
    }
  }

  checkIfIsExceptionDay(couponsExceptionDays) {
    var weekDays = [
      this.translateService.instant("store-schedule.sunday"),
      this.translateService.instant("store-schedule.monday"),
      this.translateService.instant("store-schedule.tuesday"),
      this.translateService.instant("store-schedule.wednesday"),
      this.translateService.instant("store-schedule.thursday"),
      this.translateService.instant("store-schedule.friday"),
      this.translateService.instant("store-schedule.saturday"),
    ];
    this.todayIsCouponsExceptionDay = null;
    console.log("exception days called", couponsExceptionDays);
    if (!_.isEmpty(couponsExceptionDays)) {
      _.each(couponsExceptionDays, (day, key) => {
        if (isMoment(day.date)) {
          day.date.set("hour", 0);
          day.date.set("minutes", 0);
          day.date.set("seconds", 0);
          day.date.set("milliseconds", 0);
          let now = moment();
          now.set("hour", 0);
          now.set("minutes", 0);
          now.set("seconds", 0);
          now.set("milliseconds", 0);
          if (moment(day.date).isSame(now)) {
            this.todayIsCouponsExceptionDay = weekDays[moment().weekday()];

            this.currentDayCouponsSchedule = _.cloneDeep(
              couponsExceptionDays[key]
            );
            console.log(
              "today is excpetion day",
              this.todayIsCouponsExceptionDay,
              this.currentDayCouponsSchedule
            );
          }
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
