<ion-item
  *ngIf="category"
  class="categoryItem ion-no-padding ion-no-margin"
  [id]="category.category_id"
>
  <ion-row style="width: 100%">
    <ion-col size="12" class="noPaddingCols">
      <strong style="font-size: larger; padding: 0">
        <ion-text color="primary">
          {{ category.name }}
        </ion-text>
      </strong>
    </ion-col>
    <ion-col
      size="12"
      class="noPaddingCols"
      *ngIf="!category.availableTotal || category.avpc"
    >
      <app-category-item-tags
        [categoryItem]="category"
        [groupStoreData]="groupStoreData"
      ></app-category-item-tags>
      <!-- {{ "catalog.categories.category-item.unavailable-category" | translate }} -->
    </ion-col>

    <ion-col size="12" class="DescriptionPaddings" *ngIf="category.description">
      <span
        [ngClass]="{
          'unavailable line-through ': !!!category.availableTotal
        }"
        class="fontSizeSmaller"
      >
        <app-description [description]="category.description"></app-description>
      </span>
    </ion-col>
    <ion-col
      size="12"
      class="noPaddingCols"
      *ngIf="category.promotions && category.promotions.length > 0"
    >
      <div
        *ngFor="
          let promo of category.promotions;
          let i = index;
          trackBy: trackFunc
        "
        class="categoriesWarningColor"
      >
        <span style="font-weight: bold; font-size: small">{{
          promo.description
        }}</span>
      </div>
    </ion-col>
  </ion-row>
</ion-item>

<div *ngIf="category && category.activeTotal && windowDimensions && storeInfo">
  <ion-row style="width: 100%">
    <ion-col
      [size]="colSize"
      *ngFor="
        let product of category.products
          | filterAvailableProducts : storeInfo.showAllUnavailableProducts
          | filterOnlyDineInDeliveryPickupProducts : orderPickup : dineIn;
        trackBy: trackProducts
      "
    >
      <app-product-item-card-mobile
        [category]="category"
        [product]="product"
        [webp]="webp"
        [orderPickup]="orderPickup"
        [selectedDineInTable]="selectedDineInTable"
        [selectedPreorderDate]="selectedPreorderDate"
        [groupStoreData]="groupStoreData"
        [dineIn]="dineIn"
      ></app-product-item-card-mobile>
    </ion-col>
  </ion-row>
</div>
