import { Action } from "@ngrx/store";

export const SET_CURRENT_STORE_ID = "[CURRENT STORE] Set Current Store Id";
export const CLEAR_CURRENT_STORE_ID = "[CURRENT STORE] Clear Current Store Id";

export class SetCurrentStoreId implements Action {
  readonly type = SET_CURRENT_STORE_ID;
  constructor(public payload: string) {}
}

export class ClearCurrentStoreId implements Action {
  readonly type = CLEAR_CURRENT_STORE_ID;
  constructor() {}
}

export type CurrentStoreActions = SetCurrentStoreId | ClearCurrentStoreId;
