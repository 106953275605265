import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { AlertController, ModalController, ToastController } from "@ionic/angular";
import _ from "lodash";
import { Subscription, auditTime, distinctUntilChanged } from "rxjs";
import { Store } from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import * as AuthActions from "../../auth/store/auth.actions";
import { TranslateService } from "@ngx-translate/core";


@Component({
  selector: "app-user-info",
  templateUrl: "./user-info.component.html",
  styleUrls: ["./user-info.component.scss"],
})
export class UserInfoComponent implements OnInit {
  @Input() rootDir :any;
  @Input() selectedLogoFileName :any;
  private userSubscription: Subscription;
  currentUser: any;
  mo_token: any;
  customerDataDirty: boolean;
  customerDataSaveSuccess: boolean;
  customerDataSaveLoading: boolean;
  edit: boolean;
  dirtyButton: boolean;
  @ViewChild("emailInput", { static: false }) emailInputElem;
  deleteAccountLoading: boolean;
  editable: boolean;
  groupApp: any;
  headerLogo: any;
  storeImage: any;
  darkHeaderLogo: any;
  windowDimensions: any;
  private groupSubscription: Subscription;

  constructor(
    public modalCtrl: ModalController,
    private store: Store<fromApp.AppState>,
    private dataStorageService: DataStorageService,
    private toastController: ToastController,
    private translateService: TranslateService,
    private alertController: AlertController,

  ) {}

  ngOnInit() {
    this.userSubscription = this.store
      .select("auth")
      .pipe(auditTime(50))
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (state && state.user && !_.isEqual(state.user, this.currentUser)) {
          this.currentUser = _.cloneDeep(state.user);
          if (!this.currentUser.loyalty_app_settings) {
            this.currentUser.loyalty_app_settings = {
              showLastName: false,
              showImage: false,
            };
          }
        }
        if (state.mo_token && !_.isEqual(state.mo_token, this.mo_token)) {
          this.mo_token = _.cloneDeep(state.mo_token);
        }
      });

      this.groupSubscription = this.store
      .select("groupApp")
      .pipe(auditTime(50))
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.groupApp &&
          !_.isEqual(this.groupApp, state.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp);
            this.dataStorageService.fetchGroupApp(this.groupApp.group).subscribe({
              next: (res: any) => {
                if (res) {  
        
                  this.storeImage = res.iconImage;
                  this.headerLogo = res.headerLogo;
                  console.log("groupAppHeaderLogo", res.headerLogo);
                  this.darkHeaderLogo = res.headerLogoDark;
                } else {
                }
              },
              error: (error) => {
                return error;
              },
            });
        }
      });

      this.store
      .select("windowInfo")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(300))
      .subscribe((state) => {
        if (!_.isEqual(state.windowDimensions, this.windowDimensions)) {
          this.windowDimensions = _.cloneDeep(state.windowDimensions);
        }
      });
  }

  onInputFocus() {
    this.customerDataDirty = true;
  }
  changed() {
    this.dirtyButton = true;
  }

  updateCustomerDataDebounced = _.debounce((attr) => {
    this.updateCustomerDataCustomerInfo(attr);
  }, 1500);

  updateCustomerDataCustomerInfo(attr) {
    if (this.customerDataDirty) {
      this.customerDataSaveSuccess = false;

      if (
        (attr === "firstName" && this.currentUser.firstName) ||
        (attr === "lastName" && this.currentUser.lastName)
      ) {
        let obj = { updatedData: _.cloneDeep(this.currentUser) };
        this.customerDataSaveLoading = true;

        //console.log("customerObjecy", obj);
        //console.log("ngmodelchange", this.currentUser);
        this.dataStorageService

          .updateCustomerData(obj, this.mo_token)

          .subscribe({
            next: (res: any) => {
              if (res && res.success) {
                //  console.log("ngmodelchange", this.currentUser);
                this.customerDataSaveLoading = false;
                this.customerDataSaveSuccess = true;
                this.edit = false;
                this.dirtyButton = false;
                let updatedUser = res.data;
                this.store.dispatch(
                  new AuthActions.SetUser(_.cloneDeep(updatedUser))
                );
              } else {
                this.customerDataSaveLoading = false;
                if (res.comment_id) {
                  this.presentErrorToast(
                    this.translateService.instant(res.comment_id),
                    "danger"
                  );
                } else if (res.comments) {
                  this.presentErrorToast(res.comments, "danger");
                }
              }
            },
            error: (err) => {
              this.customerDataSaveLoading = false;
              let errMsg = this.translateService.instant(
                "errorMessages.error: There was an error on saving"
              );

              this.presentErrorToast(errMsg, "danger");
              return err;
              console.log("error updating the information");
            },
          });
      } else {
        this.customerDataSaveLoading = false;

        this.presentErrorToast(
          this.translateService.instant(
            "cart.cart-components.customer-info.the-filed-is-required"
          ),
          "danger"
        );
      }
    }
  }

  saveOrEdit() {
    const emailRegex =
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

    if (!this.currentUser.email) {
      let errorMessage = this.translateService.instant(
        "account-settings.please-enter-your-email"
      );
      this.presentErrorToast(errorMessage, "danger");
      this.emailInputElem.setFocus();
    } else if (
      this.currentUser.email &&
      !this.currentUser.email.match(emailRegex)
    ) {
      let errorMessage = this.translateService.instant(
        "account-settings.please-enter-a-valid-email"
      );
      this.presentErrorToast(errorMessage, "danger");
      this.emailInputElem.setFocus();
    } else {
      //  console.log("user", this.currentUser, this.mo_token);
      let obj = { updatedData: this.currentUser };
      this.updateCustomerData(obj, this.mo_token, true);
      console.log("updateCustomerData success");
    }
  }

  updateCustomerData(obj, mo_token, show_success_message) {
    this.customerDataSaveLoading = true;
    if (show_success_message) {
    }

    this.dataStorageService.updateCustomerData(obj, mo_token).subscribe({
      next: (res: any) => {
        if (res && res.success) {
          this.dirtyButton = false;
          let updatedUser = res.data;
          this.store.dispatch(
            new AuthActions.SetUser(_.cloneDeep(updatedUser))
          );
          if (show_success_message && res.comment_id) {
            this.presentErrorToast(
              this.translateService.instant(
                "account-settings." + res.comment_id
              ),
              "primary"
            );
          }
          this.edit = false;
          this.customerDataSaveLoading = false;
        } else {
          if (res.comment_id) {
            this.presentErrorToast(
              this.translateService.instant(res.comment_id),
              "danger"
            );
          } else if (res.comments) {
            this.presentErrorToast(res.comments, "danger");
          }
          this.customerDataSaveLoading = false;
        }
      },
      error: (error) => {
        let errMsg = this.translateService.instant(
          "errorMessages.error: There was an error on saving"
        );
        this.customerDataSaveLoading = false;
        this.presentErrorToast(errMsg, "danger");
        return error;
        console.log("error updating the information");
      },
    });
  }

  async requestDeleteAccount() {
    const alert = await this.alertController.create({
      header: this.translateService.instant("alert"),
      message: this.translateService.instant(
        "account-settings.are-you-sure-that-you-want-to-delete-account"
      ),
      buttons: [
        {
          text: this.translateService.instant("cancel"),
          role: "cancel",
          handler: () => {},
        },
        {
          text: this.translateService.instant("yes"),
          role: "confirm",
          handler: async () => {
            //call delete api
            this.deleteAccountLoading = true;
            this.dataStorageService
              .triggerDeleteAccount(this.mo_token)
              .subscribe({
                next: async (res: any) => {
                  this.deleteAccountLoading = false;
                  if (!res || !res.success) {
                    const alert = await this.alertController.create({
                      header: this.translateService.instant("alert"),
                      message:
                        res && res.comment_id
                          ? this.translateService.instant(res.comment_id)
                          : this.translateService.instant(
                              "errorMessages.general_error"
                            ),
                      backdropDismiss: false,
                      buttons: ["OK"],
                    });
                    await alert.present();
                  } else {
                    const alert = await this.alertController.create({
                      header: this.translateService.instant("alert"),
                      message: this.translateService.instant(
                        "account-settings.soon-you-will-receive-an-sms-for-deliting-account"
                      ),
                      buttons: ["OK"],
                    });

                    await alert.present();
                  }
                },
                error: async (err) => {
                  this.deleteAccountLoading = false;
                  const alert = await this.alertController.create({
                    header: this.translateService.instant("alert"),
                    message: this.translateService.instant(
                      "errorMessages.problem_reaching_server"
                    ),

                    buttons: ["OK"],
                  });
                  await alert.present();
                  return err;
                },
              });
          },
        },
      ],
    });

    await alert.present();
  }

  editPage(){
    this.editable=true;
  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            // console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }
  
}
