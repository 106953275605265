import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  AlertController,
  IonInput,
  LoadingController,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { Store } from "@ngrx/store";
import {
  auditTime,
  catchError,
  distinctUntilChanged,
  first,
  Subscription,
} from "rxjs";
import * as fromApp from "../../store/app.reducer";
import * as selectors from "../../store/app.selectors";
import * as _ from "lodash";
import { TranslateService } from "@ngx-translate/core";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import { Loader } from "@googlemaps/js-api-loader";
import mapStyleJson from "../../../assets/mapStyle/mapStyle.json";
import mapStyleJsonDark from "../../../assets/mapStyle/mapStyleDark.json";
import * as DeliveryMethodAddressActions from "../../store/deliveryMethodAddress/deliveryMethodAddress.actions";
import { v4 as uuid } from "uuid";
import { DeliveryAreasInfoModalComponent } from "src/app/delivery-areas/delivery-areas-info-modal/delivery-areas-info-modal.component";
import * as AuthActions from "../../auth/store/auth.actions";
import { AnalyticsServiceService } from "src/app/services/analitycsService/analytics-service.service";
import { Geolocation } from "@capacitor/geolocation";
import { AnimationOptions } from "ngx-lottie";
import { AnimationStyles } from "@ionic/core/dist/types/utils/animation/animation-interface";
import { Capacitor } from "@capacitor/core";

declare let window: any;
@Component({
  selector: "app-new-address-modal",
  templateUrl: "./new-address-modal.component.html",
  styleUrls: ["./new-address-modal.component.scss"],
})
export class NewAddressModalComponent implements OnInit, OnDestroy {
  @ViewChild("autoCompleteInput") input: ElementRef;
  public gpsMode: boolean = false;
  public newAddress: any;
  public deliveryAreas: any;
  public renderCardDeliveryAreas: any;
  public selectedLanguage: string;
  public groupApp: any;
  public libraryLoaded: boolean = false;
  public loading: boolean = false;
  public loadingForInputs: boolean = false;
  public showDownInfoMarkerAnimation;
  public showUpInfoMarkerAnimation;
  public infoForGooglePostalCodeRegion: any;
  public inputPulseAnimation = false;
  public gpsLocation: any;
  public ionLoading: any;
  public stores: any;
  public map: any;
  public mobileMarkerVisible = false;
  public mobilePlatform: boolean;
  public validation = {
    street: true,
    street_number: true,
    post_code: false,
  };
  mapOptions = {};
  search;
  autocomplete;
  marker;
  geocoder;
  autocompleteService;
  hideDeliveryCard: boolean = false;
  public infoForMoveTheMarker: any;
  public infoForStopMovingTheMarker: any;
  public getAddressFromCoordinatesLoading: boolean = false;
  public gpsMarker: any;
  public addressInputsChanged: boolean = false;
  public addressInputsFocus: boolean = false;
  public saveAddressLoading = false;
  public hideMap: boolean = false;
  public loader: any;
  public mo_token: any;
  public mobileMarkerInfo: any;
  public addressFromGps: any;
  public searchButtonDisabled: boolean = true;
  public geoLocationAnimation: boolean = false;
  public geoAnimationInstance: any;
  public selectedTheme: string;
  public currentStore: any;

  private gpsEditAddressAlert: any;
  private subs: Subscription[] = [];
  private currentUser: any;
  private enterKey: any;
  private DeliveryAreaInfoModal: any;
  private unsubscribeBackEvent: Subscription;
  private geoErrMessage: any;
  private gpsErrorAlert: any;
  private postCodeChangeGps: boolean = false;

  options: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/location-animation.json",
  };

  styles: AnimationStyles = {};

  // private store_info: any;
  // private minOrder: any;
  // private deliveryCost: any;

  constructor(
    private modalController: ModalController,
    private store: Store<fromApp.AppState>,
    private changeDetector: ChangeDetectorRef,
    private toastController: ToastController,
    private translateService: TranslateService,
    private dataStorageService: DataStorageService,
    private alertController: AlertController,
    private platform: Platform,
    private analyticsService: AnalyticsServiceService,
    private loadingController: LoadingController
  ) {}

  animationCreated(ev) {
    this.geoAnimationInstance = ev;
    this.geoAnimationInstance.setSpeed(1.5);
  }

  capacitorIsNativePlatform() {
    return Capacitor.isNativePlatform();
  }

  ngOnInit() {
    if (this.capacitorIsNativePlatform()) {
      this.mobilePlatform = true;
      this.mobileMarkerInfo = {
        header: null,
        message1: null,
        message2: null,
      };

      this.gpsPromptAlert();
    } else {
      this.mobilePlatform = false;
    }
    setTimeout(() => {
      this.inputPulseAnimation = true;
      this.changeDetector.detectChanges();
    }, 800);

    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      300,
      () => {
        if (this.platform.is("android")) {
          if (this.gpsEditAddressAlert) {
            this.gpsEditAddressAlert.dismiss();
            this.gpsEditAddressAlert = null;
          } else {
            this.modalController.dismiss();
          }
        }
      }
    );
    this.subs.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(this.groupApp, state.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
            if (
              this.groupApp &&
              this.selectedLanguage &&
              this.stores &&
              this.selectedTheme
            ) {
              this.createMapsScript();
            }
          }
          if (
            state &&
            state.selectedLangugage &&
            this.groupApp &&
            !_.isEqual(this.selectedLanguage, state.selectedLangugage)
          ) {
            this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
            if (
              this.groupApp &&
              this.selectedLanguage &&
              this.stores &&
              this.selectedTheme
            ) {
              this.createMapsScript();
            }
          }
          if (
            state &&
            state.selectedTheme &&
            !_.isEqual(this.selectedTheme, state.selectedTheme)
          ) {
            this.selectedTheme = _.cloneDeep(state.selectedTheme);
            if (this.selectedTheme && this.map) {
              if (this.selectedTheme === "dark") {
                this.map.set("styles", mapStyleJsonDark);
              } else {
                this.map.set("styles", mapStyleJson);
              }
            }
          }
        })
    );

    this.subs.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((storeInfo) => {
          if (storeInfo && !_.isEqual(storeInfo, this.currentStore)) {
            this.currentStore = _.cloneDeep(storeInfo);
          }
        })
    );

    this.subs.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (state && state.stores && !_.isEqual(state.stores, this.stores)) {
            this.stores = _.cloneDeep(state.stores);
            if (
              this.groupApp &&
              this.selectedLanguage &&
              this.stores &&
              this.selectedTheme
            ) {
              this.createMapsScript();
            }
          }
        })
    );
    this.subs.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (state && state.user && !_.isEqual(state.user, this.currentUser)) {
            this.currentUser = _.cloneDeep(state.user);
          }
          if (
            state &&
            state.mo_token &&
            !_.isEqual(state.mo_token, this.mo_token)
          ) {
            this.mo_token = _.cloneDeep(state.mo_token);
          }
        })
    );
    if (!this.capacitorIsNativePlatform()) {
      setTimeout(() => {
        if (this.input && this.input.nativeElement) {
          this.input.nativeElement.focus();
        }
      }, 1000);
    }
  }

  createMapsScript() {
    if (!document.getElementById("googleMapsLoader")) {
      this.loader = new Loader({
        id: "googleMapsLoader",
        apiKey: this.groupApp.googleMapsKey,
        language: this.selectedLanguage,
        libraries: ["geometry", "drawing", "places"],
      });
      this.loader.load().then(() => {
        //  console.log("library loaded");
        this.libraryLoaded = true;
        this.changeDetector.detectChanges();
        this.initializationSetAddress();
      });
    } else {
      this.libraryLoaded = true;
      this.changeDetector.detectChanges();
      this.initializationSetAddress();
      // this.loader = new Loader({
      //   id: "googleMapsLoader",
      //   apiKey: this.groupApp.googleMapsKey,
      //   libraries: ["geometry", "drawing", "places"],
      // });
    }
  }

  async gpsPromptAlert() {
    const alert = await this.alertController.create({
      header: this.translateService.instant("new-address-modal.location"),
      message: this.translateService.instant(
        "new-address-modal.do-you-want-to-find-you-location-from-gps"
      ),
      buttons: [
        {
          text: this.translateService.instant("no"),
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            // if (navigator.cookieEnabled) {
            //   let obj = {
            //     asked: true,
            //     granted: false,
            //   };
            //   localStorage.setItem("gps_permissions", JSON.stringify(obj));
            // }
          },
        },
        {
          text: this.translateService.instant("yes"),
          handler: () => {
            this.gpsMode = true;
            if (this.map) {
              this.map.setOptions({
                draggable: true,
                scaleControl: true,
                scrollwheel: true,
                mapTypeControl: false,
                zoomControlOptions: this.mobilePlatform ? false : true,
                disableDoubleClickZoom: false,

                zoomControl: false,
              });
            }

            // if (navigator.cookieEnabled) {
            //   let obj = {
            //     asked: true,
            //     granted: true,
            //   };
            //   localStorage.setItem("gps_permissions", JSON.stringify(obj));
            // }
            this.checkLocationFromGps();
          },
        },
      ],
    });

    await alert.present();
  }

  goToNextStep() {
    if (
      this.addressFromGps &&
      (this.addressFromGps.postal_code ||
        this.addressFromGps.street ||
        this.addressFromGps.street_number)
    ) {
      //this.getAddressFromCoordinatesLoading = true;
      if (this.gpsLocation.lat && this.gpsLocation.lng) {
        this.showEditAddressAlert(
          this.addressFromGps.street,
          this.addressFromGps.street_number,
          this.addressFromGps.postal_code,
          this.addressFromGps.city,
          this.addressFromGps.state,
          this.addressFromGps.region
        );
      }
      // this.checkAutoCompleteAddress(
      //   this.addressFromGps.street,
      //   this.addressFromGps.street_number,
      //   this.addressFromGps.postal_code,
      //   this.gpsLocation.lat,
      //   this.gpsLocation.lng,
      //   null,
      //   null,
      //   false,
      //   true
      // );
    }
  }

  async showEditAddressAlert(
    street,
    street_number,
    postal_code,
    city,
    state,
    region
  ) {
    const initialPostalCode = postal_code;

    this.gpsEditAddressAlert = await this.alertController.create({
      header: this.translateService.instant("new-address-modal.address"),
      inputs: [
        {
          name: "street",
          type: "text",
          value: street ? street : "",
          placeholder: this.translateService.instant(
            "new-address-modal.street"
          ),
        },
        {
          name: "street_number",
          type: "text",
          value: street_number ? street_number : "",
          placeholder: this.translateService.instant(
            "new-address-modal.street-number"
          ),
        },

        {
          name: "postal_code",
          type: "text",
          value: postal_code ? postal_code : "",
          placeholder: this.translateService.instant(
            "new-address-modal.post-code"
          ),
        },
      ],
      buttons: [
        {
          text: this.translateService.instant("cancel"),
          role: "cancel",
          cssClass: "secondary",
          handler: () => {
            console.log("Confirm Cancel");
          },
        },
        {
          text: this.translateService.instant("new-address-modal.save-address"),
          handler: (data) => {
            if (
              data &&
              (!data.postal_code || !data.street || !data.street_number)
            ) {
              const formatted_address =
                data.street +
                " " +
                data.street_number +
                ", " +
                (city ? city + " " : "") +
                data.postal_code;

              let newAddressGps = {
                googleLat: this.gpsLocation.lat,
                googleLng: this.gpsLocation.lng,
                userLat: this.gpsLocation.lat,
                userLng: this.gpsLocation.lng,
                formatted_address: formatted_address,
                address_components: {
                  street: _.cloneDeep(data.street),
                  street_number: _.cloneDeep(data.street_number),
                  postal_code: _.cloneDeep(data.postal_code),
                  region: _.cloneDeep(region) || null,
                  state: _.cloneDeep(state) || null,
                  city: _.cloneDeep(city) || null,
                },
              };
              this.onSaveAddressFromGps(_.cloneDeep(newAddressGps));
              return false;
            } else {
              const formatted_address =
                data.street +
                " " +
                data.street_number +
                ", " +
                (city ? city + " " : "") +
                data.postal_code;
              let newAddressGps = {
                googleLat: this.gpsLocation.lat,
                googleLng: this.gpsLocation.lng,
                userLat: this.gpsLocation.lat,
                userLng: this.gpsLocation.lng,
                formatted_address: formatted_address,
                address_components: {
                  street: _.cloneDeep(data.street),
                  street_number: _.cloneDeep(data.street_number),
                  postal_code: _.cloneDeep(data.postal_code),
                  region: _.cloneDeep(region) || null,
                  state: _.cloneDeep(state) || null,
                  city: _.cloneDeep(city) || null,
                },
              };
              this.getAddressFromCoordinatesLoading = true;
              this.dataStorageService
                .getAddressFromCoordinates(
                  newAddressGps.userLat,
                  newAddressGps.userLng,
                  this.selectedLanguage,
                  this.groupApp.googleMapsCountryCode,
                  this.groupApp.group,
                  initialPostalCode !== data.postal_code
                    ? data.postal_code
                    : null
                )
                .subscribe({
                  next: async (res: any) => {
                    this.postCodeChangeGps = false;
                    if (!res || !res.success) {
                      this.getAddressFromCoordinatesLoading = false;

                      let errorMessage;

                      if (
                        res &&
                        res.comment_id &&
                        res.comment_id ===
                          "please_check_your_post_code_and_try_again"
                      ) {
                        errorMessage =
                          "errorMessages.please_check_your_post_code_and_try_again";
                      } else if (res && res.comment_id) {
                        errorMessage = res.comment_id;
                      } else if (res.comments) {
                        errorMessage = res.comments;
                      } else {
                        errorMessage = "errorMessages.general_error";
                      }

                      const alert = await this.alertController.create({
                        header: this.translateService.instant("alert"),
                        message: this.translateService.instant(errorMessage),
                        backdropDismiss: false,
                        buttons: ["OK"],
                      });
                      await alert.present();
                    } else {
                      this.getAddressFromCoordinatesLoading = false;
                      this.deliveryAreas = res.deliveryAreas;
                      this.calculateRenderedDeliveryAreas();

                      console.log("this delivery areas", this.deliveryAreas);

                      this.onSaveAddressFromGps(_.cloneDeep(newAddressGps));
                    }
                  },
                  error: async (error) => {
                    this.getAddressFromCoordinatesLoading = false;
                    const alert = await this.alertController.create({
                      header: this.translateService.instant("alert"),
                      message: this.translateService.instant(
                        "errorMessages.problem_reaching_server"
                      ),

                      buttons: ["OK"],
                    });
                    await alert.present();
                    return error;
                  },
                });
            }

            console.log("Confirm Ok");
          },
        },
      ],
    });

    await this.gpsEditAddressAlert.present();
  }

  checkLocationFromGps() {
    this.presentGeolocationLoading();
    // this.getAddressFromCoordinatesDebounce({
    //   lat: 40.6099517,
    //   lng: 22.9564049,
    // });
    this.hideDeliveryCard = true;
    Geolocation.getCurrentPosition({
      timeout: 12000,
      enableHighAccuracy: true,
    })
      .then((resp) => {
        console.log("inside then with this data", resp);
        if (resp.coords && resp.coords.latitude && resp.coords.longitude) {
          let position = new google.maps.LatLng({
            lat: resp.coords.latitude,
            lng: resp.coords.longitude,
          });
          if (this.capacitorIsNativePlatform()) {
            this.getAddressFromCoordinatesDebounce({
              lat: resp.coords.latitude,
              lng: resp.coords.longitude,
            });
            this.map.setCenter(position);

            setTimeout(() => {
              this.map.setZoom(19);
            }, 200);
          } else {
            //gps on browser
          }
        } else {
          // if (this.ionLoading) {
          //   this.ionLoading.dismiss();
          //   this.ionLoading = null;
          // }
          if (this.geoLocationAnimation) {
            this.geoLocationAnimation = false;
          }
          //cant getting coords
        }
      })
      .catch(async (err) => {
        setTimeout(() => {
          // if (this.ionLoading) {
          //   this.ionLoading.dismiss();
          //   this.ionLoading = null;
          // }
          if (this.geoLocationAnimation) {
            this.geoLocationAnimation = false;
          }
        }, 100);

        console.log("errr", err);
        if (
          err &&
          err.message &&
          (err.message === "Timeout expired" ||
            err.message === "User denied Geolocation")
        ) {
          this.geoErrMessage = this.translateService.instant(
            "delivery-areas.please-enable-gps-in-order-to-get-accurate-results"
          );
          if (this.gpsErrorAlert) {
            this.gpsErrorAlert.dismiss();
            this.gpsErrorAlert = null;
          }
          this.gpsErrorAlert = await this.alertController.create({
            header: this.translateService.instant("alert"),
            message: this.geoErrMessage,
            buttons: [
              {
                text: this.translateService.instant("cancel"),
                role: "cancel",
                cssClass: "secondary",
                handler: (blah) => {
                  this.resetTheProccess();
                },
              },
              {
                text: this.translateService.instant(
                  "new-address-modal.locate-again"
                ),

                handler: () => {
                  this.checkLocationFromGps();
                },
              },
            ],
          });

          await this.gpsErrorAlert.present();
          this.gpsErrorAlert.onDidDismiss().then(() => {
            this.gpsErrorAlert = null;
          });
        } else if (err && err.message && err.message === "Illegal Access") {
          this.geoErrMessage = this.translateService.instant(
            "delivery-areas.you-can-enable-gps-from-app-setting-in-order-to-find-your-location"
          );
          const alert = await this.alertController.create({
            header: this.translateService.instant("alert"),
            message: this.geoErrMessage,
            buttons: ["OK"],
          });

          await alert.present();
          alert.onDidDismiss().then(() => {
            this.geoErrMessage = null;
            this.resetTheProccess();
          });
        } else {
          this.geoErrMessage = this.translateService.instant(
            "delivery-areas.we-cant-find-you-location-please-enable-gps-and-grant-permissions"
          );
          const alert = await this.alertController.create({
            header: this.translateService.instant("alert"),
            message: this.geoErrMessage,
            buttons: [
              {
                text: this.translateService.instant("cancel"),
                role: "cancel",
                cssClass: "secondary",
                handler: (blah) => {
                  this.resetTheProccess();
                },
              },
              {
                text: this.translateService.instant(
                  "new-address-modal.locate-again"
                ),

                handler: () => {
                  this.checkLocationFromGps();
                },
              },
            ],
          });

          await alert.present();
          alert.onDidDismiss().then(() => {
            this.geoErrMessage = null;
            //this.resetTheProccess();
          });
        }
      });
  }

  async presentGeolocationLoading() {
    if (this.gpsMarker) {
      this.gpsMarker.setVisible(false);
      this.gpsMarker = null;
    }
    this.geoLocationAnimation = true;

    console.log("Loading dismissed!");
  }

  ondismiss() {
    this.modalController.dismiss();
  }

  onClickSearch() {
    if (
      this.input &&
      this.input.nativeElement &&
      this.input.nativeElement.value
    ) {
      this.checkAutoCompleteAddress(
        this.input.nativeElement.value,
        null,
        null,
        null,
        null,
        null,
        null,
        false,
        false
      );
    } else {
      this.presentErrorToast(
        this.translateService.instant(
          "new-address-modal.please-write-you-address-in-the-input"
        ),
        "danger"
      );
    }
  }

  initializationSetAddress() {
    console.log("initializations map");
    this.map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        zoom: parseInt(this.groupApp.groupMapZoom),
        gestureHandling: "greedy",
        center: {
          lat: parseFloat(this.groupApp.groupMapCenterLat),
          lng: parseFloat(this.groupApp.groupMapCenterLng),
        },

        zoomControl: this.mobilePlatform ? false : true,
        scaleControl: false,
        scrollwheel: false,
        disableDefaultUI: true,
        mapTypeControl: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles:
          this.selectedTheme && this.selectedTheme === "dark"
            ? mapStyleJsonDark
            : mapStyleJson,
      }
    );
    this.store
      .select(selectors.getStoreInfo)
      .pipe(first())
      .subscribe((storeInfo) => {
        if (storeInfo && !storeInfo.disableDeliveryAreas) {
          this.deliveryAreasInitialization(this.map);
        }
      })
      .unsubscribe();

    google.maps.event.addListenerOnce(this.map, "idle", () => {
      this.autoCompleteInitializations();
    });
    var icon = {
      url: "assets/ionicons/home.png", // url
      scaledSize: new google.maps.Size(60, 60),
    };
    if (!this.capacitorIsNativePlatform()) {
      this.marker = new google.maps.Marker({
        animation: google.maps.Animation.DROP,
        icon: icon,

        map: this.map,

        draggable: true,
      });
    } else {
      this.mobileMarkerVisible = false;
    }
    // console.log("drugstart");
    if (!this.capacitorIsNativePlatform()) {
      google.maps.event.addListener(this.marker, "dragstart", () => {
        if (this.infoForMoveTheMarker) {
          this.infoForMoveTheMarker.close();
          this.infoForMoveTheMarker = null;
        }
        if (this.infoForGooglePostalCodeRegion) {
          this.infoForGooglePostalCodeRegion.close();
          this.infoForGooglePostalCodeRegion = null;
        }
      });
    } else {
      this.map.addListener("dragstart", () => {
        this.mobileMarkerInfo = {
          header: null,
          message1: null,
          message2: null,
        };
        // this.showDownInfoMarkerAnimation = false;
        // this.changeDetector.detectChanges();
        // setTimeout(() => {
        //   this.showUpInfoMarkerAnimation = true;
        // }, 50);
      });
    }

    if (!this.capacitorIsNativePlatform()) {
      google.maps.event.addListener(this.marker, "dragend", () => {
        let newPlace = this.marker.getPosition();
        let marker = { lat: newPlace.lat(), lng: newPlace.lng() };

        console.log("moving the marker request again", newPlace);

        if (this.infoForMoveTheMarker) {
          this.infoForMoveTheMarker.close();
          this.infoForMoveTheMarker = null;
        }
        this.infoForStopMovingTheMarker = new google.maps.InfoWindow({
          content:
            "<div style='line-height: 1.35; overflow: hidden; white-space: nowrap; color:black;'>" +
            "<ion-item color='white' lines='none' style='--min-height: 30px; padding: 0; margin: 0; color:black;'> <ion-spinner color='primary' slot='start' name='dots'></ion-spinner><ion-label style='color:black;padding: 0; margin: 0'>" +
            this.translateService.instant("new-address-modal.loading") +
            "</ion-label></ion-item> </div>",
        });
        this.infoForStopMovingTheMarker.open(this.map, this.marker);
        //Send the marker object to the request
        this.marker.setDraggable(false);
        this.checkAutoCompleteAddress(
          this.newAddress.address_components.street,
          this.newAddress.address_components.street_number,
          this.newAddress.address_components.postal_code,
          marker.lat,
          marker.lng,
          this.newAddress.address_components.googleLat,
          this.newAddress.address_components.googleLng,
          true,
          false
        );
      });
    } else {
      //TO_DO FOR MOBILE
      // this.mobileMarkerInfo = this.translateService.instant(
      //   "new-address-modal.loading"
      // );

      this.map.addListener("dragend", () => {
        // console.log("dragend");
        if (!this.gpsMode && this.newAddress) {
          this.dragEndEventMobileCheckAddressDebounce();
        } else {
          // this.showUpInfoMarkerAnimation = false;
          // setTimeout(() => {
          //   this.showDownInfoMarkerAnimation = true;
          // }, 100);
          // if (this.mobilePlatform) {
          //   this.map.setOptions({ draggable: false });
          // }
          // this.getAddressFromCoordinatesDebounce({
          //   lat: this.map.getCenter().lat(),
          //   lng: this.map.getCenter().lng(),
          // });
        }
      });
    }

    this.changeDetector.detectChanges();
  }

  autoCompleteInitializations() {
    var bounds = this.map.getBounds();
    const options = {
      types: ["address"],
      bounds: new google.maps.LatLngBounds(
        bounds.getSouthWest(),
        bounds.getNorthEast()
      ),
      componentRestrictions: {
        country: this.groupApp.googleMapsCountryCode,
      },
    };
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("autocomplete") as HTMLInputElement,
      options
    );

    this.autocompleteService = new google.maps.places.AutocompleteService();
    const geocoder = new google.maps.Geocoder();
    this.autocomplete.bindTo("bounds", this.map);
    if (this.input && this.input.nativeElement) {
      this.enterKey = function (event) {
        if (event.key === "Enter" || event.keyCode === 13) {
          event.preventDefault();
          this.onClickSearch();
        }
        // do something
      };
      this.input.nativeElement.addEventListener("keyup", this.enterKey);
    }

    this.autocomplete.addListener("place_changed", () => {
      const place: google.maps.places.PlaceResult =
        this.autocomplete.getPlace();
      //  console.log("place changed event", place);
      this.checkAutoCompleteAddress(
        place && place.formatted_address ? place.formatted_address : place.name,
        null,
        null,
        null,
        null,
        null,
        null,
        false,
        false
      );
    });
  }

  private getAddressFromCoordinatesDebounce = _.debounce((gpsLocation) => {
    this.gpsLocation = _.cloneDeep(gpsLocation);
    this.getAddressFromCoordinates(gpsLocation);
  }, 1000);

  private dragEndEventMobileCheckAddressDebounce = _.debounce(() => {
    //this.showUpInfoMarkerAnimation = false;
    // setTimeout(() => {
    //   this.showDownInfoMarkerAnimation = true;
    // }, 50);
    if (this.mobilePlatform) {
      this.map.setOptions({ draggable: false });
    }
    this.checkAutoCompleteAddressDebounced(
      this.newAddress.address_components.street,
      this.newAddress.address_components.street_number,
      this.newAddress.address_components.postal_code,
      this.map.getCenter().lat(),
      this.map.getCenter().lng(),
      this.newAddress.address_components.googleLat,
      this.newAddress.address_components.googleLng,
      true
    );
  }, 1000);

  private checkAutoCompleteAddressDebounced = _.debounce(
    (
      street,
      street_number,
      postal_code,
      userLat,
      userLng,
      googleLat,
      googleLng,
      forMarker
    ) => {
      this.checkAutoCompleteAddress(
        street,
        street_number,
        postal_code,
        userLat,
        userLng,
        googleLat,
        googleLng,
        forMarker,
        false
      );
    },
    100
  );

  async checkAutoCompleteAddress(
    street,
    street_number,
    postal_code,
    userLat,
    userLng,
    googleLat,
    googleLng,
    forMarker,
    fixAddressAlert
  ) {
    if (
      street &&
      !street_number &&
      !postal_code &&
      !userLat &&
      !userLng &&
      !googleLat &&
      !googleLng &&
      !forMarker
    ) {
      this.loading = true;
      this.changeDetector.detectChanges();

      this.dataStorageService
        .checkAutoCompleteAddres(
          street,
          null,
          null,
          null,
          null,
          null,
          null,
          this.selectedLanguage,
          this.groupApp.googleMapsCountryCode,
          this.groupApp.group
        )

        .subscribe({
          next: async (res: any) => {
            if (!res || !res.success) {
              if (this.mobilePlatform) {
                if (!this.gpsMode) {
                  this.map.setOptions({ draggable: true });
                } else {
                  this.getAddressFromCoordinatesLoading = false;
                }
              }

              const alert = await this.alertController.create({
                header: this.translateService.instant("alert"),
                message:
                  res && res.comment_id
                    ? this.translateService.instant(res.comment_id)
                    : this.translateService.instant(
                        "errorMessages.general_error"
                      ),
                backdropDismiss: false,
                buttons: ["OK"],
              });
              await alert.present();
            } else {
              if (this.mobilePlatform) {
                this.map.setOptions({
                  draggable: true,
                  scrollwheel: true,
                  zoomControlOptions: true,
                  disableDoubleClickZoom: false,

                  zoomControl: false,
                });
                this.gpsMode = false;
                this.getAddressFromCoordinatesLoading = false;

                this.addressFromGps = null;
                google.maps.event.clearListeners(this.map, "dragstart");
                google.maps.event.clearListeners(this.map, "dragend");
                this.map.addListener("dragstart", () => {
                  this.mobileMarkerInfo = {
                    header: null,
                    message1: null,
                    message2: null,
                  };
                  // this.showDownInfoMarkerAnimation = false;
                  // setTimeout(() => {
                  //   this.showUpInfoMarkerAnimation = true;
                  // }, 50);
                  this.changeDetector.detectChanges();
                });
                this.map.addListener("dragend", () => {
                  // console.log("dragend");
                  this.dragEndEventMobileCheckAddressDebounce();
                });
                this.map.setOptions({ draggable: true });
              }

              this.addressInputsChanged = false;
              this.addressInputsFocus = false;
              if (res.currentAddress) {
                this.newAddress = res.currentAddress;
                if (!this.newAddress.userLat && !this.newAddress.userLng) {
                  const alert = await this.alertController.create({
                    header: this.translateService.instant("alert"),

                    message: this.translateService.instant(
                      "new-address-modal.please-fill-all-the-address-fields"
                    ),
                    buttons: ["OK"],
                  });

                  await alert.present();
                }
              }
              if (res.deliveryAreas) {
                this.deliveryAreas = res.deliveryAreas;
                this.calculateRenderedDeliveryAreas();

                console.log("this delivery areas", this.deliveryAreas);
              }
              console.log("response new address", this.newAddress);
              this.checkAddressFields();
              if (this.newAddress.userLat && this.newAddress.userLng) {
                this.hideMap = false;

                this.setMarker(
                  {
                    lat: this.newAddress.userLat,
                    lng: this.newAddress.userLng,
                  },
                  true
                );

                this.changeDetector.detectChanges();
              } else {
                if (!this.capacitorIsNativePlatform()) {
                  this.marker.setVisible(false);
                  if (this.infoForMoveTheMarker) {
                    this.infoForMoveTheMarker.close();
                    this.infoForMoveTheMarker = null;
                  }
                } else {
                  //TO_DO FOR MOBILE
                  if (fixAddressAlert) {
                    const alert = await this.alertController.create({
                      header: this.translateService.instant("notification"),
                      message: this.translateService.instant(
                        "new-address-modal.fix-address-fields-to-correct-your-address"
                      ),

                      backdropDismiss: false,
                      buttons: ["OK"],
                    });
                    await alert.present();
                  }
                  this.mobileMarkerVisible = false;
                }
                this.hideMap = true;
              }
              this.loading = false;
              this.changeDetector.detectChanges();
            }
            console.log("autocomplete api response", res);
          },
          error: async (err) => {
            if (this.mobilePlatform) {
              if (!this.gpsMode) {
                this.map.setOptions({ draggable: true });
              } else {
                this.getAddressFromCoordinatesLoading = false;
              }
            }

            const alert = await this.alertController.create({
              header: this.translateService.instant("alert"),
              message: this.translateService.instant(
                "errorMessages.problem_reaching_server"
              ),

              buttons: ["OK"],
            });
            await alert.present();
            return err;

            console.log("error updating the information");
          },
        });
    } else {
      if (!street) {
        const alert = await this.alertController.create({
          header: this.translateService.instant("alert"),

          message: this.translateService.instant(
            "new-address-modal.please-fill-street"
          ),
          buttons: ["OK"],
        });

        await alert.present();

        if (this.mobilePlatform) {
          this.map.setOptions({
            draggable: true,
            scrollwheel: true,
            zoomControlOptions: true,
            disableDoubleClickZoom: false,

            zoomControl: false,
          });
          if (this.gpsMarker) {
            this.gpsMarker.setVisible(false);
          }
          this.gpsMode = false;
          this.getAddressFromCoordinatesLoading = false;
          this.mobileMarkerVisible = true;
          this.addressFromGps = null;
          google.maps.event.clearListeners(this.map, "dragstart");
          google.maps.event.clearListeners(this.map, "dragend");
          this.map.addListener("dragstart", () => {
            this.mobileMarkerInfo = {
              header: null,
              message1: null,
              message2: null,
            };
            // this.showDownInfoMarkerAnimation = false;
            // setTimeout(() => {
            //   this.showUpInfoMarkerAnimation = true;
            // }, 50);
            this.changeDetector.detectChanges();
          });
          this.map.addListener("dragend", () => {
            // console.log("dragend");
            this.dragEndEventMobileCheckAddressDebounce();
          });
          this.map.setOptions({ draggable: true });
          if (fixAddressAlert) {
            const alert = await this.alertController.create({
              header: this.translateService.instant("notification"),
              message: this.translateService.instant(
                "new-address-modal.fix-address-fields-to-correct-your-address"
              ),

              backdropDismiss: false,
              buttons: ["OK"],
            });
            await alert.present();
          }
        }

        if (!this.mobilePlatform) {
          if (forMarker) {
            if (this.infoForStopMovingTheMarker) {
              this.infoForStopMovingTheMarker.close();
            }

            this.marker.setDraggable(true);
          } else {
            if (this.newAddress.unverified) {
            }
          }
        }
        this.addressInputsChanged = false;
        this.addressInputsFocus = false;

        this.checkAddressFields();
      } else {
        this.loadingForInputs = true;
        this.changeDetector.detectChanges();
        this.dataStorageService
          .checkAutoCompleteAddres(
            street,
            street_number,
            postal_code,
            userLat,
            userLng,
            googleLat,
            googleLng,
            this.selectedLanguage,
            this.groupApp.googleMapsCountryCode,
            this.groupApp.group
          )
          .subscribe({
            next: async (res: any) => {
              if (!res || !res.success) {
                if (this.mobilePlatform) {
                  this.map.setOptions({
                    draggable: true,
                    scrollwheel: true,
                    zoomControlOptions: true,
                    disableDoubleClickZoom: false,

                    zoomControl: false,
                  });
                  if (this.gpsMarker) {
                    this.gpsMarker.setVisible(false);
                  }
                  this.gpsMode = false;
                  this.getAddressFromCoordinatesLoading = false;
                  this.mobileMarkerVisible = true;
                  this.addressFromGps = null;
                  google.maps.event.clearListeners(this.map, "dragstart");
                  google.maps.event.clearListeners(this.map, "dragend");
                  this.map.addListener("dragstart", () => {
                    this.mobileMarkerInfo = {
                      header: null,
                      message1: null,
                      message2: null,
                    };
                    // this.showDownInfoMarkerAnimation = false;
                    // setTimeout(() => {
                    //   this.showUpInfoMarkerAnimation = true;
                    // }, 50);
                    this.changeDetector.detectChanges();
                  });
                  this.map.addListener("dragend", () => {
                    // console.log("dragend");
                    this.dragEndEventMobileCheckAddressDebounce();
                  });
                  this.map.setOptions({ draggable: true });
                  if (fixAddressAlert) {
                    const alert = await this.alertController.create({
                      header: this.translateService.instant("notification"),
                      message: this.translateService.instant(
                        "new-address-modal.fix-address-fields-to-correct-your-address"
                      ),

                      backdropDismiss: false,
                      buttons: ["OK"],
                    });
                    await alert.present();
                  }
                }

                if (!this.mobilePlatform) {
                  if (forMarker) {
                    if (this.infoForStopMovingTheMarker) {
                      this.infoForStopMovingTheMarker.close();
                    }

                    this.marker.setDraggable(true);
                  } else {
                    if (this.newAddress.unverified) {
                    }
                  }
                }
                this.addressInputsChanged = false;
                this.addressInputsFocus = false;

                this.checkAddressFields();

                const alert = await this.alertController.create({
                  header: this.translateService.instant("alert"),
                  message:
                    res && res.comment_id
                      ? this.translateService.instant(res.comment_id)
                      : this.translateService.instant(
                          "errorMessages.general_error"
                        ),
                  backdropDismiss: false,
                  buttons: ["OK"],
                });
                await alert.present();
              } else {
                if (this.mobilePlatform) {
                  this.map.setOptions({
                    draggable: true,
                    scrollwheel: true,
                    zoomControlOptions: true,
                    disableDoubleClickZoom: false,

                    zoomControl: false,
                  });
                  if (this.gpsMarker) {
                    this.gpsMarker.setVisible(false);
                  }
                  this.gpsMode = false;
                  this.getAddressFromCoordinatesLoading = false;
                  this.mobileMarkerVisible = true;
                  this.addressFromGps = null;
                  google.maps.event.clearListeners(this.map, "dragstart");
                  google.maps.event.clearListeners(this.map, "dragend");
                  this.map.addListener("dragstart", () => {
                    this.mobileMarkerInfo = {
                      header: null,
                      message1: null,
                      message2: null,
                    };
                    // this.showDownInfoMarkerAnimation = false;
                    // setTimeout(() => {
                    //   this.showUpInfoMarkerAnimation = true;
                    // }, 50);
                    this.changeDetector.detectChanges();
                  });
                  this.map.addListener("dragend", () => {
                    // console.log("dragend");
                    this.dragEndEventMobileCheckAddressDebounce();
                  });
                  this.map.setOptions({ draggable: true });
                  if (fixAddressAlert) {
                    const alert = await this.alertController.create({
                      header: this.translateService.instant("notification"),
                      message: this.translateService.instant(
                        "new-address-modal.fix-address-fields-to-correct-your-address"
                      ),

                      backdropDismiss: false,
                      buttons: ["OK"],
                    });
                    await alert.present();
                  }
                }

                if (res.currentAddress) {
                  this.newAddress = res.currentAddress;
                  if (!this.newAddress.userLat && !this.newAddress.userLng) {
                    const alert = await this.alertController.create({
                      header: this.translateService.instant("alert"),

                      message: this.translateService.instant(
                        "new-address-modal.please-fill-all-the-address-fields"
                      ),
                      buttons: ["OK"],
                    });

                    await alert.present();
                  }
                }
                if (res.deliveryAreas) {
                  this.deliveryAreas = res.deliveryAreas;
                  this.calculateRenderedDeliveryAreas();
                  // console.log("this delivery areas", this.deliveryAreas);
                }
                if (!this.mobilePlatform) {
                  if (forMarker) {
                    if (this.infoForStopMovingTheMarker) {
                      this.infoForStopMovingTheMarker.close();
                    }

                    this.marker.setDraggable(true);
                  } else {
                    if (this.newAddress.unverified) {
                    }
                  }
                }
                this.addressInputsChanged = false;
                this.addressInputsFocus = false;

                this.checkAddressFields();
                if (this.newAddress.userLat && this.newAddress.userLng) {
                  this.hideMap = false;
                  if (forMarker) {
                    this.setMarker(
                      {
                        lat: this.newAddress.userLat,
                        lng: this.newAddress.userLng,
                      },
                      false
                    );
                  } else {
                    if (!this.capacitorIsNativePlatform()) {
                      this.setMarker(
                        {
                          lat: this.newAddress.userLat,
                          lng: this.newAddress.userLng,
                        },
                        true
                      );
                    } else {
                      this.setMarker(
                        {
                          lat: this.newAddress.userLat,
                          lng: this.newAddress.userLng,
                        },
                        true
                      );
                    }
                  }

                  this.changeDetector.detectChanges();
                } else {
                  this.hideMap = true;

                  if (!this.capacitorIsNativePlatform()) {
                    this.marker.setVisible(false);

                    if (this.infoForMoveTheMarker) {
                      this.infoForMoveTheMarker.close();
                      this.infoForMoveTheMarker = null;
                    }
                  } else {
                    this.mobileMarkerVisible = false;
                    this.mobileMarkerInfo = {
                      header: null,
                      message1: null,
                      message2: null,
                    };
                    //TO_DO FOR MOBILE
                  }
                }

                this.loadingForInputs = false;
                this.changeDetector.detectChanges();
              }
              console.log("autocomplete api response", res);
            },
            error: async (err) => {
              if (this.mobilePlatform) {
                this.map.setOptions({ draggable: true });
                this.getAddressFromCoordinatesLoading = false;
              }

              const alert = await this.alertController.create({
                header: this.translateService.instant("alert"),
                message: this.translateService.instant(
                  "errorMessages.problem_reaching_server"
                ),

                buttons: ["OK"],
              });
              await alert.present();
              return err;

              console.log("error updating the information");
            },
          });
      }
    }
  }

  calculateRenderedDeliveryAreas() {
    if (this.deliveryAreas && this.deliveryAreas.length) {
      this.renderCardDeliveryAreas = _.cloneDeep(this.deliveryAreas);
    } else {
      this.renderCardDeliveryAreas = [];
    }
    if (
      this.deliveryAreas &&
      this.deliveryAreas.length &&
      this.stores &&
      this.stores.length == 1
    ) {
      let areaWithShedule = _.find(this.deliveryAreas, (area) => {
        if (area.showSchedule) {
          return true;
        }
      });
      if (!areaWithShedule) {
        this.renderCardDeliveryAreas = _.cloneDeep([
          _.minBy(this.deliveryAreas, (area) => {
            return area.minOrder;
          }),
        ]);
      }
    } else if (
      this.deliveryAreas &&
      this.deliveryAreas.length &&
      this.stores &&
      this.stores.length > 1
    ) {
      let areaWithShedule = _.find(this.deliveryAreas, (area) => {
        if (area.showSchedule) {
          return true;
        }
      });
      if (!areaWithShedule) {
        let tempAreas = [];
        _.each(this.stores, (store) => {
          tempAreas.push(
            _.cloneDeep(
              _.minBy(this.deliveryAreas, (area) => {
                if (store.store_id === area.store_id) {
                  return area.minOrder;
                }
              })
            )
          );
        });

        if (tempAreas && tempAreas.length > 0) {
          tempAreas = _.cloneDeep(
            tempAreas.filter((element) => {
              return element !== undefined;
            })
          );
          this.renderCardDeliveryAreas = tempAreas;
          //  console.log("tempsareas", this.renderCardDeliveryAreas);
        }
      }
    }
    this.hideDeliveryCard = false;
    //console.log("rendered delivery areas", this.renderCardDeliveryAreas);
  }

  getAddressFromCoordinates(gpsLocation) {
    if (gpsLocation && gpsLocation.lat && gpsLocation.lng) {
      this.getAddressFromCoordinatesLoading = true;

      this.dataStorageService
        .getAddressFromCoordinates(
          gpsLocation.lat,
          gpsLocation.lng,
          this.selectedLanguage,
          this.groupApp.googleMapsCountryCode,
          this.groupApp.group,
          null
        )
        .subscribe({
          next: async (res: any) => {
            // if (this.ionLoading) {
            //   this.ionLoading.dismiss();
            //   this.ionLoading = null;
            // }
            if (this.geoLocationAnimation) {
              this.geoLocationAnimation = false;
            }

            this.getAddressFromCoordinatesLoading = false;
            if (!res || !res.success) {
              const alert = await this.alertController.create({
                header: this.translateService.instant("alert"),
                message:
                  res && res.comment_id
                    ? this.translateService.instant(res.comment_id)
                    : this.translateService.instant(
                        "errorMessages.general_error"
                      ),
                backdropDismiss: false,
                buttons: ["OK"],
              });
              await alert.present();
            } else {
              // console.log("getAddressFromCoordinates res", res);
              if (res.address) {
                this.addressFromGps = _.cloneDeep(res.address);
                var icon = {
                  url: "assets/flatIcons/gpsPin.svg", // url
                  scaledSize: new google.maps.Size(20, 20),
                };
                if (this.gpsMarker) {
                  this.gpsMarker.setVisible(false);
                  this.gpsMarker = null;
                }
                this.gpsMarker = new google.maps.Marker({
                  animation: google.maps.Animation.DROP,
                  icon: icon,

                  map: this.map,

                  draggable: false,
                });
                this.map.setZoom(19);
                this.gpsMarker.setPosition({
                  lat: gpsLocation.lat,
                  lng: gpsLocation.lng,
                });
                this.gpsMarker.setVisible(true);
                this.changeDetector.detectChanges();
              }
              if (res.deliveryAreas) {
                this.deliveryAreas = _.cloneDeep(res.deliveryAreas);
                this.calculateRenderedDeliveryAreas();
              }
              //TO_DO set map center and show marker for mobile
              //Show ion card with marker address from api
              //Show message to move the map in order to correct address
              //Show button with continue to go to next step
            }
          },
          error: async (err) => {
            // if (this.ionLoading) {
            //   this.ionLoading.dismiss();
            //   this.ionLoading = null;
            // }
            if (this.geoLocationAnimation) {
              this.geoLocationAnimation = false;
            }
            // if (this.mobilePlatform) {
            //   this.map.setOptions({ draggable: true });
            // }
            const alert = await this.alertController.create({
              header: this.translateService.instant("alert"),
              message: this.translateService.instant(
                "errorMessages.problem_reaching_server"
              ),

              buttons: ["OK"],
            });
            await alert.present();
            return err;
          },
        });
    } else {
      //TO_DO ERROR MESSAGE
      if (this.geoLocationAnimation) {
        this.geoLocationAnimation = false;
      }
      // if (this.ionLoading) {
      //   this.ionLoading.dismiss();
      //   this.ionLoading = null;
      // }
    }
  }

  showInfoBoxForMovingMarker() {
    if (this.infoForMoveTheMarker) {
      this.infoForMoveTheMarker = null;
    }
    this.infoForMoveTheMarker = new google.maps.InfoWindow({
      content: this.translateService.instant(
        "new-address-modal.move-the-marker-to-correct-your-address"
      ),
    });

    this.infoForMoveTheMarker.open(this.map, this.marker);
  }

  deliveryAreasInitialization(map) {
    _.each(this.stores, (store, key, list) => {
      if (_.isEmpty(store.deliveryAreas) && store.boundaryCoordinates1) {
        console.log("Creating the group's delivery areas...");
        console.log("How many stores the group has: ", this.stores.length);
        //Polygon coordinates
        var boundary = store.boundaryCoordinates1;
        var boundaryData = [];
        var latlngMap = boundary.split(",");
        var lat = [];
        var lng = [];
        for (var i = 0; i < latlngMap.length; i++) {
          if (i % 2 === 0) {
            lat.push(latlngMap[i]);
          } else {
            lng.push(latlngMap[i]);
          }
        }
        for (i = 0; i < latlngMap.length / 2; i++) {
          boundaryData.push(new google.maps.LatLng(lat[i], lng[i]));
        }

        new google.maps.Polygon({
          paths: boundaryData,
          strokeColor: "#0000FF",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FFCC00", //YEllOW
          fillOpacity: 0.3,
          zIndex: 10,
          map: map,
        });
      } else {
        console.log("The store has the new deliveryAreas", store.deliveryAreas);
        _.each(store.deliveryAreas, (area, k, l) => {
          if (!area.active) {
            return;
          }

          var boundary = area.boundaries;
          var boundaryData = [];
          var latlngMap = boundary.split(",");
          var lat = [];
          var lng = [];
          for (var i = 0; i < latlngMap.length; i++) {
            if (i % 2 === 0) {
              lat.push(parseFloat(latlngMap[i]));
            } else {
              lng.push(parseFloat(latlngMap[i]));
            }
          }
          for (i = 0; i < latlngMap.length / 2; i++) {
            boundaryData.push(new google.maps.LatLng(lat[i], lng[i]));
          }

          new google.maps.Polygon({
            paths: boundaryData,
            strokeColor: area.strokeColor ? area.strokeColor : "#0000FF",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: area.fillColor ? area.fillColor : "#FFCC00",
            fillOpacity: area.fillOpacity ? parseFloat(area.fillOpacity) : 0.3,
            zIndex: 10,
            map: map,
          });
        });
      }
    });
  }

  focusOnInput() {
    this.addressInputsFocus = true;
    if (this.map) {
      this.map.set("zoomControl", false);
    }
    this.hideDeliveryCard = true;
  }

  BlurOnInput() {
    this.hideDeliveryCard = false;
    if (this.map) {
      this.map.set("zoomControl", true);
    }
  }

  onAutoCompleteFocus() {
    this.searchButtonDisabled = false;
    this.hideDeliveryCard = true;
    if (this.map) {
      this.map.set("zoomControl", false);
    }
  }

  onAutoCompleteBlur() {
    this.hideDeliveryCard = false;
    if (this.map) {
      this.map.set("zoomControl", true);
    }
  }

  checkAddressFields() {
    this.addressInputsChanged = true;

    this.validation.street_number = true;
    this.validation.post_code = true;
    this.validation.street = true;
    if (this.newAddress && this.newAddress.address_components) {
      if (!this.newAddress.address_components.postal_code) {
        this.validation.post_code = false;
      }
      if (!this.newAddress.address_components.street) {
        this.validation.street = false;
      }
      if (!this.newAddress.address_components.street_number) {
        this.validation.street_number = false;
      }
    }

    this.changeDetector.detectChanges();
  }

  resetTheProccess() {
    this.newAddress = null;

    if (this.mobilePlatform) {
      this.mobileMarkerInfo = {
        header: null,
        message1: null,
        message2: null,
      };

      if (this.gpsMode) {
        this.gpsLocation = null;
        this.addressFromGps = null;

        if (this.gpsMarker) {
          this.gpsMarker.setVisible(false);
        }
        this.gpsMode = false;
      }
    }

    if (this.input && this.input.nativeElement) {
      this.input.nativeElement.value = null;
    } else if (!this.input) {
      //TO_DO CHECK THIS HEERE
    }

    if (this.infoForMoveTheMarker) {
      this.infoForMoveTheMarker.close();
      this.infoForMoveTheMarker = null;
    }

    if (this.marker) {
      this.marker.setVisible(false);
    }

    if (this.map) {
      this.map.setZoom(parseInt(this.groupApp.groupMapZoom));
      this.map.setCenter({
        lat: parseFloat(this.groupApp.groupMapCenterLat),
        lng: parseFloat(this.groupApp.groupMapCenterLng),
      });
    }
    if (this.capacitorIsNativePlatform()) {
      google.maps.event.clearListeners(this.map, "dragstart");
      google.maps.event.clearListeners(this.map, "dragend");
    } else {
      // gps for browser
    }
    this.deliveryAreas = null;
    this.renderCardDeliveryAreas = null;
    this.hideMap = false;
    this.mobileMarkerVisible = false;
    this.changeDetectorDebounce();
  }

  changeDetectorDebounce = _.debounce(() => {
    this.changeDetector.detectChanges();
  }, 500);

  setMarker(location, setCenter) {
    if (setCenter) {
      this.map.setCenter(location);
      this.map.setZoom(19);
      this.mobileMarkerInfo = {
        header: null,
        message1: null,
        message2: null,
      };
      if (this.infoForMoveTheMarker) {
        this.infoForMoveTheMarker.close();
        this.infoForMoveTheMarker = null;
      }
      if (this.newAddress && this.newAddress.unverified) {
        if (!this.capacitorIsNativePlatform()) {
          this.infoForMoveTheMarker = new google.maps.InfoWindow({
            content:
              "<div style='font-weight: bold;' color='danger'>" +
              "<ion-text color='danger'>" +
              this.translateService.instant(
                "new-address-modal.your-address-is-unverified"
              ) +
              "</ion-text>" +
              "</div>" +
              "<div>" +
              "<ion-text style='font-weight:bold;' color='black'>" +
              this.translateService.instant(
                "new-address-modal.move-the-marker"
              ) +
              "</ion-text>" +
              "</div>" +
              "<div >" +
              "<ion-text style='font-weight:bold;'  color='black'>" +
              this.translateService.instant(
                "new-address-modal.to-correct-your-address"
              ) +
              "</ion-text>" +
              "</div>",
          });

          this.infoForMoveTheMarker.open(this.map, this.marker);
        } else {
          this.mobileMarkerInfo.header = this.translateService.instant(
            "new-address-modal.your-address-is-unverified"
          );
          this.mobileMarkerInfo.message1 =
            this.translateService.instant("new-address-modal.move-the-map") +
            " " +
            this.translateService.instant(
              "new-address-modal.to-correct-your-address"
            );
        }
      } else if (
        this.newAddress &&
        !this.newAddress.unverified &&
        this.newAddress.address_components &&
        this.newAddress.address_components.street &&
        this.newAddress.address_components.street_number &&
        this.newAddress.address_components.postal_code
      ) {
        if (!this.capacitorIsNativePlatform()) {
          this.infoForMoveTheMarker = new google.maps.InfoWindow({
            content:
              "<div style='font-weight:bold; color:black;' >" +
              this.translateService.instant(
                "new-address-modal.move-the-marker"
              ) +
              "</div>" +
              "<div style='font-weight:bold; color:black;'>" +
              this.translateService.instant(
                "new-address-modal.to-correct-your-address"
              ) +
              "</div>",
          });
          this.infoForMoveTheMarker.open(this.map, this.marker);
        } else {
          this.mobileMarkerInfo.header =
            this.translateService.instant("new-address-modal.move-the-map") +
            " " +
            this.translateService.instant(
              "new-address-modal.to-correct-your-address"
            );
        }
      }
    } else {
      if (
        this.newAddress &&
        this.newAddress.address_components &&
        this.newAddress.address_components.user_point_postal_code
      ) {
        if (!this.capacitorIsNativePlatform()) {
          if (this.infoForGooglePostalCodeRegion) {
            this.infoForGooglePostalCodeRegion.close();
            this.infoForGooglePostalCodeRegion = null;
          }
          this.infoForGooglePostalCodeRegion = new google.maps.InfoWindow({
            content:
              "<div style='font-weight:bold; color:black;'>" +
              this.translateService.instant(
                "new-address-modal.marker-information-from-google-map"
              ) +
              "</div>" +
              "<div style='font-weight:bold; color:black;'>" +
              this.translateService.instant("new-address-modal.postal_code") +
              ": " +
              this.newAddress.address_components.user_point_postal_code +
              "</div>" +
              (this.newAddress.address_components.region
                ? "<div style='font-weight:bold; color:black;'>" +
                  this.translateService.instant("new-address-modal.region") +
                  ": " +
                  this.newAddress.address_components.region +
                  "</div>"
                : ""),
          });
          this.infoForGooglePostalCodeRegion.open(this.map, this.marker);
        } else {
          this.mobileMarkerInfo.header = this.translateService.instant(
            "new-address-modal.marker-information-from-google-map"
          );
          this.mobileMarkerInfo.message1 =
            this.translateService.instant("new-address-modal.postal_code") +
            ": " +
            this.newAddress.address_components.user_point_postal_code +
            " ";
          this.mobileMarkerInfo.message2 = this.newAddress.address_components
            .region
            ? this.translateService.instant("new-address-modal.region") +
              ": " +
              this.newAddress.address_components.region
            : "";
        }
      }
    }
    if (!this.capacitorIsNativePlatform()) {
      //console.log("this marker", this.marker);
      var latlng = new google.maps.LatLng(location.lat, location.lng);
      this.marker.setVisible(true);
      this.marker.setPosition(latlng);
    } else {
      this.mobileMarkerVisible = true;
    }

    this.changeDetector.detectChanges();
  }

  trackDeliveryAreas(index, item) {
    return index;
  }

  async openDeliveryAreaInfoModal(area) {
    this.DeliveryAreaInfoModal = await this.modalController.create({
      component: DeliveryAreasInfoModalComponent,
      cssClass: "openDeliveryAreaInfoModal",
      backdropDismiss: false,
      componentProps: {
        currentDeliveryArea: area,
      },
    });
    await this.DeliveryAreaInfoModal.present();
    this.DeliveryAreaInfoModal.onDidDismiss().then(() => {
      this.DeliveryAreaInfoModal = null;
    });
  }

  async presentLoading() {
    let message = this.translateService.instant("loading-please-wait");
    this.ionLoading = await this.loadingController.create({
      duration: 20000,
      message: message,
      cssClass: "ionicLoadingCss",
      keyboardClose: true,
    });
    return await this.ionLoading.present();
  }

  async onSaveAddressFromGps(addressFromGps) {
    this.saveAddressLoading = true;

    console.log("new address", addressFromGps);
    if (
      !addressFromGps ||
      !addressFromGps.address_components ||
      !addressFromGps.address_components.street ||
      !addressFromGps.address_components.street_number ||
      !addressFromGps.address_components.postal_code
    ) {
      const toast = await this.toastController.create({
        header: this.translateService.instant("alert"),
        duration: 3000,
        position: "middle",
        color: "danger",
        message: this.translateService.instant(
          "new-address-modal.please-fill-all-the-address-fields"
        ),
        buttons: ["OK"],
      });

      await toast.present();
      this.saveAddressLoading = false;
    } else if (
      addressFromGps &&
      addressFromGps.address_components &&
      addressFromGps.address_components.street &&
      addressFromGps.address_components.street_number &&
      addressFromGps.address_components.postal_code &&
      !addressFromGps.userLat &&
      !addressFromGps.userLng
    ) {
      const toast = await this.toastController.create({
        header: this.translateService.instant("alert"),
        duration: 3000,
        position: "middle",
        color: "danger",
        message: this.translateService.instant(
          "new-address-modal.please-fill-all-the-address-fields-correctly"
        ),
        buttons: ["OK"],
      });
      await toast.present();
      this.saveAddressLoading = false;
    } else if (
      addressFromGps &&
      addressFromGps.address_components &&
      addressFromGps.address_components.street &&
      addressFromGps.address_components.street_number &&
      addressFromGps.address_components.postal_code &&
      addressFromGps.userLat &&
      addressFromGps.userLng
    ) {
      if (
        ((this.deliveryAreas && this.deliveryAreas.length > 0) ||
          this.currentStore.disableDeliveryAreas) &&
        addressFromGps
      ) {
        await this.presentLoading();

        if (this.currentUser) {
          let currentUser = _.cloneDeep(this.currentUser);
          if (!_.isArray(currentUser.addresses)) {
            currentUser.addresses = [];
          }
          addressFromGps.address_id = uuid();
          currentUser.addresses.push(addressFromGps);
          this.dataStorageService
            .updateCustomerData({ updatedData: currentUser }, this.mo_token)

            .subscribe({
              next: async (res: any) => {
                if (!res || !res.success) {
                  if (this.ionLoading) {
                    await this.ionLoading.dismiss();
                    this.ionLoading = null;
                  }
                  const alert = await this.alertController.create({
                    header: this.translateService.instant("alert"),
                    message:
                      res && res.comment_id
                        ? this.translateService.instant(res.comment_id)
                        : this.translateService.instant(
                            "errorMessages.general_error"
                          ),

                    buttons: ["OK"],
                  });
                  await alert.present();
                  this.saveAddressLoading = false;
                } else {
                  if (this.ionLoading) {
                    await this.ionLoading.dismiss();
                    this.ionLoading = null;
                  }
                  this.saveAddressLoading = false;
                  this.store.dispatch(
                    new DeliveryMethodAddressActions.setDineIn(
                      _.cloneDeep(false)
                    )
                  );
                  this.store.dispatch(
                    new DeliveryMethodAddressActions.setOrderPickup(
                      _.cloneDeep(false)
                    )
                  );
                  this.store.dispatch(
                    new DeliveryMethodAddressActions.setSelectedDineInTable(
                      _.cloneDeep(null)
                    )
                  );
                  console.log("set order pickup false");

                  // this.analyticsService.dmAnalyticsMessage(
                  //   "delivery_method",
                  //   null,
                  //   null,
                  //   null,
                  //   null,
                  //   null,
                  //   null,
                  //   this.sele
                  // );

                  this.store.dispatch(
                    new DeliveryMethodAddressActions.setOpenNotValidAddressModal(
                      true
                    )
                  );
                  this.store.dispatch(
                    new DeliveryMethodAddressActions.setOpenSelectStorePopupDelivery(
                      true
                    )
                  );
                  this.store.dispatch(
                    new DeliveryMethodAddressActions.setCheckSelectedAddress(
                      _.cloneDeep(addressFromGps)
                    )
                  );
                  this.store.dispatch(
                    new AuthActions.SetUser(_.cloneDeep(currentUser))
                  );
                  this.modalController.dismiss(res);
                }
              },
              error: async (err) => {
                if (this.ionLoading) {
                  await this.ionLoading.dismiss();
                  this.ionLoading = null;
                }
                const alert = await this.alertController.create({
                  header: this.translateService.instant("alert"),
                  message: this.translateService.instant(
                    "errorMessages.problem_reaching_server"
                  ),

                  buttons: ["OK"],
                });
                await alert.present();
                this.saveAddressLoading = false;
                return err;
              },
            });
        } else {
          this.saveAddressLoading = false;

          if (this.ionLoading) {
            // console.log("dismiss lllllloading");
            this.ionLoading.dismiss();
            this.ionLoading = null;
          }
          addressFromGps.address_id = uuid();
          this.store.dispatch(
            new DeliveryMethodAddressActions.setDineIn(_.cloneDeep(false))
          );
          this.store.dispatch(
            new DeliveryMethodAddressActions.setOrderPickup(_.cloneDeep(false))
          );
          this.store.dispatch(
            new DeliveryMethodAddressActions.setSelectedDineInTable(
              _.cloneDeep(null)
            )
          );
          console.log("set order pickup false");
          // this.analyticsService.dmAnalyticsMessage(
          //   "delivery_method",
          //   null,
          //   null,
          //   null,
          //   null,
          //   null,
          //   null,
          //   null
          // );
          this.store.dispatch(
            new DeliveryMethodAddressActions.setCheckSelectedAddress(
              _.cloneDeep(addressFromGps)
            )
          );
          this.store.dispatch(
            new DeliveryMethodAddressActions.setOpenSelectStorePopupDelivery(
              true
            )
          );
          this.store.dispatch(
            new DeliveryMethodAddressActions.setOpenNotValidAddressModal(true)
          );
          this.modalController.dismiss();
        }
      } else if (
        !this.deliveryAreas ||
        (this.deliveryAreas && this.deliveryAreas.length === 0)
      ) {
        this.saveAddressLoading = false;
        if (this.ionLoading) {
          this.ionLoading.dismiss();
          this.ionLoading = null;
        }
        const alert = await this.alertController.create({
          header: this.translateService.instant("alert"),
          message: this.translateService.instant(
            "new-address-modal.no-delivery-areas-that-serves-your-location"
          ),

          buttons: ["OK"],
        });
        await alert.present();
      }
    }
  }

  async onSaveAddress() {
    this.saveAddressLoading = true;
    console.log("new address", this.newAddress);
    if (
      !this.newAddress ||
      !this.newAddress.address_components ||
      !this.newAddress.address_components.street ||
      !this.newAddress.address_components.street_number ||
      !this.newAddress.address_components.postal_code
    ) {
      const alert = await this.alertController.create({
        header: this.translateService.instant("alert"),

        message: this.translateService.instant(
          "new-address-modal.please-fill-all-the-address-fields"
        ),
        buttons: ["OK"],
      });

      await alert.present();
      this.saveAddressLoading = false;
    } else if (
      this.newAddress &&
      this.newAddress.address_components &&
      this.newAddress.address_components.street &&
      this.newAddress.address_components.street_number &&
      this.newAddress.address_components.postal_code &&
      !this.newAddress.userLat &&
      !this.newAddress.userLng
    ) {
      const alert = await this.alertController.create({
        header: this.translateService.instant("alert"),

        message: this.translateService.instant(
          "new-address-modal.please-fill-all-the-address-fields-correctly"
        ),
        buttons: ["OK"],
      });
      await alert.present();
      this.saveAddressLoading = false;
    } else if (
      this.newAddress &&
      this.newAddress.address_components &&
      this.newAddress.address_components.street &&
      this.newAddress.address_components.street_number &&
      this.newAddress.address_components.postal_code &&
      this.newAddress.userLat &&
      this.newAddress.userLng
    ) {
      if (
        ((this.deliveryAreas && this.deliveryAreas.length > 0) ||
          this.currentStore.disableDeliveryAreas) &&
        this.newAddress
      ) {
        if (this.currentUser) {
          let currentUser = _.cloneDeep(this.currentUser);
          if (!_.isArray(currentUser.addresses)) {
            currentUser.addresses = [];
          }
          this.newAddress.address_id = uuid();
          currentUser.addresses.push(this.newAddress);
          this.dataStorageService
            .updateCustomerData({ updatedData: currentUser }, this.mo_token)

            .subscribe({
              next: async (res: any) => {
                if (!res || !res.success) {
                  const alert = await this.alertController.create({
                    header: this.translateService.instant("alert"),
                    message:
                      res && res.comment_id
                        ? this.translateService.instant(res.comment_id)
                        : this.translateService.instant(
                            "errorMessages.general_error"
                          ),

                    buttons: ["OK"],
                  });
                  await alert.present();
                  this.saveAddressLoading = false;
                } else {
                  this.saveAddressLoading = false;
                  this.store.dispatch(
                    new DeliveryMethodAddressActions.setDineIn(
                      _.cloneDeep(false)
                    )
                  );
                  this.store.dispatch(
                    new DeliveryMethodAddressActions.setOrderPickup(
                      _.cloneDeep(false)
                    )
                  );
                  this.store.dispatch(
                    new DeliveryMethodAddressActions.setSelectedDineInTable(
                      _.cloneDeep(null)
                    )
                  );
                  console.log("set order pickup false");

                  // this.analyticsService.dmAnalyticsMessage(
                  //   "delivery_method",
                  //   null,
                  //   null,
                  //   null,
                  //   null,
                  //   null,
                  //   null,
                  //   this.sele
                  // );

                  this.store.dispatch(
                    new DeliveryMethodAddressActions.setOpenNotValidAddressModal(
                      true
                    )
                  );
                  this.store.dispatch(
                    new DeliveryMethodAddressActions.setOpenSelectStorePopupDelivery(
                      true
                    )
                  );
                  this.store.dispatch(
                    new DeliveryMethodAddressActions.setCheckSelectedAddress(
                      _.cloneDeep(this.newAddress)
                    )
                  );
                  this.store.dispatch(
                    new AuthActions.SetUser(_.cloneDeep(currentUser))
                  );
                  this.modalController.dismiss(res);
                }
              },
              error: async (err) => {
                const alert = await this.alertController.create({
                  header: this.translateService.instant("alert"),
                  message: this.translateService.instant(
                    "errorMessages.problem_reaching_server"
                  ),

                  buttons: ["OK"],
                });
                await alert.present();
                this.saveAddressLoading = false;
                return err;
              },
            });
        } else {
          this.saveAddressLoading = false;
          this.newAddress.address_id = uuid();
          this.store.dispatch(
            new DeliveryMethodAddressActions.setDineIn(_.cloneDeep(false))
          );
          this.store.dispatch(
            new DeliveryMethodAddressActions.setOrderPickup(_.cloneDeep(false))
          );
          this.store.dispatch(
            new DeliveryMethodAddressActions.setSelectedDineInTable(
              _.cloneDeep(null)
            )
          );
          console.log("set order pickup false");
          // this.analyticsService.dmAnalyticsMessage(
          //   "delivery_method",
          //   null,
          //   null,
          //   null,
          //   null,
          //   null,
          //   null,
          //   null
          // );
          this.store.dispatch(
            new DeliveryMethodAddressActions.setCheckSelectedAddress(
              _.cloneDeep(this.newAddress)
            )
          );
          this.store.dispatch(
            new DeliveryMethodAddressActions.setOpenSelectStorePopupDelivery(
              true
            )
          );
          this.store.dispatch(
            new DeliveryMethodAddressActions.setOpenNotValidAddressModal(true)
          );
          this.modalController.dismiss();
        }
      } else if (
        !this.deliveryAreas ||
        (this.deliveryAreas && this.deliveryAreas.length === 0)
      ) {
        this.saveAddressLoading = false;
        const alert = await this.alertController.create({
          header: this.translateService.instant("alert"),
          message: this.translateService.instant(
            "new-address-modal.no-delivery-areas-that-serves-your-location"
          ),

          buttons: ["OK"],
        });
        await alert.present();
      }
    }
  }

  async onCheckAddress() {
    if (
      this.newAddress &&
      this.newAddress.address_components &&
      this.newAddress.address_components.street
    ) {
      this.checkAutoCompleteAddress(
        this.newAddress.address_components.street,
        this.newAddress.address_components.street_number,
        this.newAddress.address_components.postal_code,
        null,
        null,
        this.newAddress.address_components.googleLat,
        this.newAddress.address_components.googleLng,
        false,
        false
      );
    } else {
      const alert = await this.alertController.create({
        header: this.translateService.instant("alert"),

        message: this.translateService.instant(
          "new-address-modal.please-fill-street"
        ),
        buttons: ["OK"],
      });

      await alert.present();
    }
  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            // console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }

  // checkIfMapsLoaded() {
  //   this.checkIfMapsLoadedInterval = setInterval(() => {
  //     console.log("intervall check");
  //     if (typeof google === "object" && typeof google.maps === "object") {
  //       console.log("intervall dismissed map loaded");
  //       this.libraryLoaded = true;
  //       this.initializationSetAddress();
  //       clearInterval(this.checkIfMapsLoadedInterval);
  //     }
  //   }, 500);
  // }

  ngOnDestroy() {
    if (this.gpsEditAddressAlert) {
      this.gpsEditAddressAlert.dismiss();
      this.gpsEditAddressAlert = null;
    }

    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.DeliveryAreaInfoModal) {
      this.DeliveryAreaInfoModal.dismiss();
      this.DeliveryAreaInfoModal = null;
    }
    if (this.subs && this.subs.length > 0) {
      this.subs.forEach((sub) => {
        sub.unsubscribe();
      });
    }

    if (this.enterKey) {
      document.removeEventListener("keyup", this.enterKey);
    }
    if (this.mobilePlatform) {
      google.maps.event.clearListeners(this.map, "dragstart");
      google.maps.event.clearListeners(this.map, "dragend");
    }

    if (this.marker) {
      google.maps.event.clearListeners(this.marker, "dragend");
      google.maps.event.clearListeners(this.marker, "dragstart");
      this.marker = null;
    }
    if (this.autocomplete) {
      google.maps.event.clearListeners(this.autocomplete, "place_changed");
      this.autocomplete = null;
    }

    if (this.ionLoading) {
      this.ionLoading.dismiss();
      this.ionLoading = null;
    }
  }
}
