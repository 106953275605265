import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-product-option-tags",
  templateUrl: "./product-option-tags.component.html",
  styleUrls: ["./product-option-tags.component.scss"],
})
export class ProductOptionTagsComponent implements OnInit {
  @Input() optionItem: any;
  @Input() selectedProductPromo: any;
  @Input() groupStoreData: any;

  constructor() {}

  ngOnInit() {}
}
