import { Action } from "@ngrx/store";

export const SET_CHECKBOX_PROMOTIONS =
  "[CHECKBOX PROMOTIONS] Set Checkbox Promotions";
export const CLEAR_CHECKBOX_PROMOTIONS =
  "[CHECKBOX PROMOTIONS] Clear Checkbox Promotions";
export const SET_SUBTOTAL_NO_PROMOS =
  "[CHECKBOX PROMOTIONS] Set Subtotal No Promos";
export const CLEAR_SUBTOTAL_NO_PROMOS =
  "[CHECKBOX PROMOTIONS] Clear Subtotal No Promos";

export class SetCheckboxPromotions implements Action {
  readonly type = SET_CHECKBOX_PROMOTIONS;
  constructor(public payload: any) {}
}

export class ClearCheckboxPromotions implements Action {
  readonly type = CLEAR_CHECKBOX_PROMOTIONS;
  constructor() {}
}

export class SetSubtotalNoPromos implements Action {
  readonly type = SET_SUBTOTAL_NO_PROMOS;
  constructor(public payload: any) {}
}

export class ClearSubtotalNoPromos implements Action {
  readonly type = CLEAR_SUBTOTAL_NO_PROMOS;
  constructor() {}
}

export type CheckboxPromotionsActions =
  | SetCheckboxPromotions
  | ClearCheckboxPromotions
  | SetSubtotalNoPromos
  | ClearSubtotalNoPromos;
