import * as CartActiveCouponsActions from "./cartActiveCoupons.actions";
import * as _ from "lodash";

export interface State {
  activeCoupons: any;
}

const initialState: State = {
  activeCoupons: null,
};

export function customerCouponsReducer(
  state = initialState,
  action: CartActiveCouponsActions.CartActiveCouponsActions
) {
  switch (action.type) {
    case CartActiveCouponsActions.SET_ACTIVE_COUPONS:
      return {
        ...state,
        activeCoupons: action.payload,
      };

    case CartActiveCouponsActions.CLEAR_ACTIVE_COUPONS:
      return {
        ...state,
        activeCoupons: null,
      };

    default:
      return state;
  }
}
