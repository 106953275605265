import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Loader } from "@googlemaps/js-api-loader";
import {
  AlertController,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import { auditTime, distinctUntilChanged } from "rxjs";
import * as fromApp from "../../../store/app.reducer";
import mapStyleJson from "../../../../assets/mapStyle/mapStyle.json";
import mapStyleJsonDark from "../../../../assets/mapStyle/mapStyleDark.json";
import { TranslateService } from "@ngx-translate/core";
import MarkerClusterer from "@googlemaps/markerclustererplus";
import { Geolocation } from "@capacitor/geolocation";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import * as selectors from "../../../store/app.selectors";
import { QrcardModalComponent } from "../qrcard-modal/qrcard-modal.component";
import { Capacitor } from "@capacitor/core";

declare let window: any;
declare global {
  interface Window {
    initMap: () => void;
  }
}

@Component({
  selector: "app-select-store-modal",
  templateUrl: "./select-store-modal.component.html",
  styleUrls: ["./select-store-modal.component.scss"],
})
export class SelectStoreModalComponent implements OnInit, OnDestroy {
  @Input() stores;
  @Input() selectedStoreId: any;
  map: google.maps.Map;
  sub: any;
  groupApp: any;
  selectedLanguage: any;
  selectedTheme: any;
  store_markers: any[];
  infoMarkerInfoToast: any;
  infoMarker: any;
  infoForStopMovingTheMarker: any;
  infoForMoveTheMarker: any;
  previousInfoMarkerPosition: any;
  mobilePlatform: boolean;
  geoLocationAnimation: boolean;
  geoErrMessage: any;
  closestStoreToCurrentPosition: any;
  loader: Loader;
  libraryLoaded: boolean = false;
  storeInfoSub: any;
  selectedStoreInfo: any;
  selectedStoreSchedule: any;
  unsubscribeBackEvent: any;

  constructor(
    private modalController: ModalController,
    private store: Store<fromApp.AppState>,
    private changeDetector: ChangeDetectorRef,
    private alertController: AlertController,
    private translateService: TranslateService,
    private toastController: ToastController,
    private dataStorageSevice: DataStorageService,
    private alertCtrl: AlertController,
    private platform: Platform
  ) {}

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );

    this.storeInfoSub = this.store
      .select(selectors.getStoreInfo)
      .pipe()
      .subscribe((storeInfo) => {
        if (storeInfo) {
          console.log("storeINFooo", storeInfo);
          this.selectedStoreInfo = _.cloneDeep(storeInfo);
          if (this.selectedStoreInfo.loyaltyapp_schedule) {
            this.selectedStoreSchedule = this.formatStoreSchedule(
              this.selectedStoreInfo.loyaltyapp_schedule
            );
          }
        }
      });

    // window.initMap = this.initMap();

    if (Capacitor.isNativePlatform()) {
      this.mobilePlatform = true;
    } else {
      this.mobilePlatform = false;
    }

    this.sub = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (
          state &&
          state.groupApp &&
          !_.isEqual(this.groupApp, state.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp);
        }
        if (
          state &&
          state.selectedTheme &&
          !_.isEqual(this.selectedTheme, state.selectedTheme)
        ) {
          this.selectedTheme = _.cloneDeep(state.selectedTheme);
          if (this.selectedTheme && this.map) {
            if (this.selectedTheme === "dark") {
              this.map.set("styles", mapStyleJsonDark);
            } else {
              this.map.set("styles", mapStyleJson);
            }
          }
        }
        if (
          state &&
          state.selectedLangugage &&
          !_.isEqual(this.selectedLanguage, state.selectedLangugage)
        ) {
          this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
          if (
            this.groupApp &&
            this.selectedLanguage &&
            this.stores &&
            this.selectedTheme
          ) {
            console.log("inside map initialization 2");
            if (!document.getElementById("googleMapsLoader")) {
              this.loader = new Loader({
                id: "googleMapsLoader",
                apiKey: this.groupApp.googleMapsKey,
                language: this.selectedLanguage,
                libraries: ["geometry", "drawing", "places"],
              });
              this.loader.load().then(() => {
                console.log("library loaded");
                this.libraryLoaded = true;
                this.changeDetector.detectChanges();
                this.initializationSetAddress();
              });
            } else {
              this.libraryLoaded = true;
              this.changeDetector.detectChanges();
              this.initializationSetAddress();
            }
          }
        }
      });
  }

  initializationSetAddress() {
    this.map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        zoom: parseInt(this.groupApp.groupMapZoom),
        gestureHandling: "greedy",
        center: {
          lat: parseFloat(this.groupApp.groupMapCenterLat),
          lng: parseFloat(this.groupApp.groupMapCenterLng),
        },

        zoomControl: true,
        styles:
          this.selectedTheme && this.selectedTheme === "dark"
            ? mapStyleJsonDark
            : mapStyleJson,
        scaleControl: false,

        scrollwheel: true,
        disableDefaultUI: true,
        mapTypeControl: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      }
    );

    // this.deliveryAreasInitialization(this.map);

    // this.checkMapZoomPresentInfo();

    this.store_markers = [];
    _.each(this.stores, (store) => {
      this.store_markers.push({
        marker: new google.maps.Marker({
          animation: google.maps.Animation.DROP,
          icon: "assets/ionicons/store-location.png",

          position: {
            lat: store.mapCenterStoreLat
              ? parseFloat(store.mapCenterStoreLat)
              : 0,
            lng: store.mapCenterStoreLng
              ? parseFloat(store.mapCenterStoreLng)
              : 0,
          },
          map: this.map,
          anchorPoint: new google.maps.Point(0, -29),
          draggable: false,
        }),
        store_id: store.store_id,
        infoBox: new google.maps.InfoWindow({
          content:
            "<h6 style='padding:0;margin:2px;'>" +
            (store.store_panel_name_langs &&
            store.store_panel_name_langs[this.selectedLanguage]
              ? store.store_panel_name_langs[this.selectedLanguage]
              : store.store_panel_name_langs && store.store_panel_name_langs.el
              ? store.store_panel_name_langs.el
              : store.store_name_panel
              ? store.store_name_panel
              : store.store_name
              ? store.store_name
              : "") +
            "</h6>" +
            "<p style='padding:0;margin:0px'>" +
            store.address +
            "</p>",
          disableAutoPan: true,
        }),
        infoWindowIsOpen: false,
      });
    });
    let markers = [];
    _.each(this.store_markers, (marker) => {
      marker.marker.setVisible(true);
      markers.push(marker.marker);
      marker.marker.addListener("click", () => {
        this.onStoreMarkerClick(marker);
      });
    });
    const markerCluster = new MarkerClusterer(this.map, markers, {
      imagePath:
        "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
    });

    this.map.addListener("zoom_changed", () => {
      // this.checkMapZoomPresentInfoDebounced();
    });

    if (Capacitor.isNativePlatform()) {
      // this.presentInfoMarkerToast();
      this.map.addListener("dragstart", () => {
        // this.showDownInfoMarkerAnimation = false;
        // setTimeout(() => {
        //   this.showUpInfoMarkerAnimation = true;
        // }, 100);
      });

      this.map.addListener("dragend", () => {
        // this.showUpInfoMarkerAnimation = false;
        // setTimeout(() => {
        //   this.showDownInfoMarkerAnimation = true;
        // }, 100);
        // this.mobileCheckMarker();
      });
    } else {
    }
    //this.map.setZoom(19);

    this.map.setCenter({
      lat: this.groupApp.groupMapCenterLat
        ? parseFloat(this.groupApp.groupMapCenterLat)
        : 0,
      lng: this.groupApp.groupMapCenterLng
        ? parseFloat(this.groupApp.groupMapCenterLng)
        : 0,
    });
    if (Capacitor.isNativePlatform()) {
      // this.mobileCheckMarker();
      // this.presentInfoMarkerToast();
    }
    this.changeDetector.detectChanges();
  }

  onStoreMarkerClick(marker) {
    if (!marker.infoBox.isOpened) {
      marker.infoBox.open(this.map, marker.marker);
      marker.infoWindowIsOpen = true;
    }
    if (this.mobilePlatform) {
      //TO_DO CHECK ON STORE CLICK MOBILE
    } else {
      this.setInfoMarkerAndListeners(marker.marker.getPosition(), false, false);
    }

    this.changeDetector.detectChanges();
  }

  checkClosestStoreToPosition(position) {
    let minDistance = 10000000;
    console.log("position", position);
    _.forEach(this.stores, (store) => {
      const storePosition = {
        lat: store.mapCenterStoreLat ? parseFloat(store.mapCenterStoreLat) : 0,
        lng: store.mapCenterStoreLng ? parseFloat(store.mapCenterStoreLng) : 0,
      };

      var R = 6371.071; // Radius of the Earth in Km
      var rlat1 = position.lat() * (Math.PI / 180); // Convert degrees to radians
      var rlat2 = storePosition.lat * (Math.PI / 180); // Convert degrees to radians
      var difflat = rlat2 - rlat1; // Radian difference (latitudes)
      var difflon = (storePosition.lng - position.lng()) * (Math.PI / 180); // Radian difference (longitudes)

      var d =
        2 *
        R *
        Math.asin(
          Math.sqrt(
            Math.sin(difflat / 2) * Math.sin(difflat / 2) +
              Math.cos(rlat1) *
                Math.cos(rlat2) *
                Math.sin(difflon / 2) *
                Math.sin(difflon / 2)
          )
        );
      console.log("position distance", d, "Km");
      this.closestStoreToCurrentPosition = _.cloneDeep(store);
      if (d < minDistance) {
        minDistance = d;
        this.closestStoreToCurrentPosition = _.cloneDeep(store);
        console.log("before alert for position");
      }
    });
    this.confirmPositionToClosestStore(this.closestStoreToCurrentPosition);
  }

  async confirmPositionToClosestStore(closestStore) {
    const alert = await this.alertCtrl.create({
      header: this.translateService.instant(
        "loyaltySystem.confirm-closest-store"
      ),
      subHeader: closestStore.store_name,
      message: this.translateService.instant(
        "loyaltySystem.confirm-closest-store-message"
      ),
      buttons: [
        {
          text: this.translateService.instant(
            "popups.logout-alert-popup.cancel"
          ),
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: this.translateService.instant("popups.logout-alert-popup.yes"),
          handler: () => {
            this.selectedStoreId = closestStore.store_id;
          },
        },
      ],
    });

    await alert.present();
  }

  checkLocationFromGps() {
    this.geoLocationAnimation = true;
    Geolocation.getCurrentPosition({
      timeout: 12000,
      enableHighAccuracy: true,
    })
      .then((resp) => {
        console.log("inside then with this data", resp);
        if (resp.coords && resp.coords.latitude && resp.coords.longitude) {
          let position = new google.maps.LatLng({
            lat: resp.coords.latitude,
            lng: resp.coords.longitude,
          });
          if (Capacitor.isNativePlatform()) {
            this.map.setCenter(position);
            setTimeout(() => {
              // this.mobileCheckMarker();
              this.checkClosestStoreToPosition(position);
              // this.map.setZoom(19);
              if (this.geoLocationAnimation) {
                this.geoLocationAnimation = false;
              }
            }, 1500);
          } else {
            if (!this.infoMarker) {
              this.setInfoMarkerAndListeners(position, true, true);
            }
          }
        } else {
          if (this.geoLocationAnimation) {
            this.geoLocationAnimation = false;
          }
          //cant getting coords
        }
      })
      .catch(async (err) => {
        setTimeout(() => {
          if (this.geoLocationAnimation) {
            this.geoLocationAnimation = false;
          }
        }, 500);
        console.log("errr", err);
        if (
          err &&
          err.message &&
          (err.message === "Timeout expired" ||
            err.message === "User denied Geolocation") &&
          !this.geoErrMessage
        ) {
          this.geoErrMessage = this.translateService.instant(
            "delivery-areas.please-enable-gps-in-order-to-get-accurate-results"
          );
          const alert = await this.alertController.create({
            header: this.translateService.instant("alert"),
            message: this.geoErrMessage,
            buttons: ["OK"],
          });

          await alert.present();
          alert.onDidDismiss().then(() => {
            this.geoErrMessage = null;
          });
        } else if (
          err &&
          err.message &&
          err.message === "Illegal Access" &&
          !this.geoErrMessage
        ) {
          this.geoErrMessage = this.translateService.instant(
            "delivery-areas.you-can-enable-gps-from-app-setting-in-order-to-find-your-location"
          );
          const alert = await this.alertController.create({
            header: this.translateService.instant("alert"),
            message: this.geoErrMessage,
            buttons: ["OK"],
          });

          await alert.present();
          alert.onDidDismiss().then(() => {
            this.geoErrMessage = null;
          });
        } else {
          this.geoErrMessage = this.translateService.instant(
            "delivery-areas.we-cant-find-you-location-please-enable-gps-and-grant-permissions"
          );
          const alert = await this.alertController.create({
            header: this.translateService.instant("alert"),
            message: this.geoErrMessage,
            buttons: ["OK"],
          });

          await alert.present();
          alert.onDidDismiss().then(() => {
            this.geoErrMessage = null;
          });
        }
      });
  }

  mobileCheckMarker() {
    this.mobileCheckMarkerDebounce();
  }

  private mobileCheckMarkerDebounce = _.debounce(() => {
    if (this.infoMarkerInfoToast) {
      this.infoMarkerInfoToast.dismiss();
      this.infoMarkerInfoToast = null;
    }
    // this.checkMarkerLoading = true;
    let centerOfMap = this.map.getCenter();
    let marker = {
      lat: centerOfMap.lat(),

      lng: centerOfMap.lng(),
      group: this.groupApp.group,
      languageCode: this.selectedLanguage,
    };
    this.dataStorageSevice.checkMarker(marker).subscribe({
      next: async (res: any) => {
        if (res && res.success) {
          // this.checkMarkerLoading = false;
        } else {
          // this.checkMarkerLoading = false;
          this.map.setOptions({ draggable: true });
          const alert = await this.alertController.create({
            header: this.translateService.instant("alert"),
            message:
              res && res.comment_id
                ? this.translateService.instant(res.comment_id)
                : this.translateService.instant("errorMessages.general_error"),

            buttons: ["OK"],
          });
          alert.present();
        }
        console.log("res", res);

        this.changeDetector.detectChanges();
      },
      error: async (err) => {
        this.map.setOptions({ draggable: true });
        const alert = await this.alertController.create({
          header: this.translateService.instant("alert"),
          message: this.translateService.instant(
            "errorMessages.problem_reaching_server"
          ),

          buttons: ["OK"],
        });
        await alert.present();
        return err;
      },
    });
    this.changeDetector.detectChanges();
  }, 1200);

  setInfoMarkerAndListeners(position, onCenter, setMarkerMapCenter) {
    if (this.infoMarker) {
      this.infoMarker.setVisible(false);
      google.maps.event.clearListeners(this.infoMarker, "dragstart");
      google.maps.event.clearListeners(this.infoMarker, "dragend");
      this.infoMarker = null;
    }
    let positionOfMarker = position;

    if (!onCenter) {
      positionOfMarker = {
        lat: position.lat() - 0.004,
        lng: position.lng(),
      };
    }
    this.previousInfoMarkerPosition = positionOfMarker;

    console.log("position", position);
    var icon = {
      url: "assets/flatIcons/info.png", // url
      scaledSize: new google.maps.Size(70, 70), // scaled size
    };
    this.infoMarker = new google.maps.Marker({
      animation: google.maps.Animation.DROP,
      icon: icon,
      position: positionOfMarker,
      map: this.map,

      draggable: true,
    });
    this.infoMarker.setVisible(true);
    if (setMarkerMapCenter) {
      this.map.set("center", positionOfMarker);
    }

    this.previousInfoMarkerPosition = positionOfMarker;
    if (this.infoMarkerInfoToast) {
      this.infoMarkerInfoToast.dismiss();
      this.infoMarkerInfoToast = null;
    }

    // this.presentInfoMarkerToast();

    google.maps.event.addListener(this.infoMarker, "dragstart", () => {
      _.each(this.store_markers, (storeMarker) => {
        if (this.infoMarkerInfoToast) {
          this.infoMarkerInfoToast.dismiss();
          this.infoMarkerInfoToast = null;
        }
        if (storeMarker.infoWindowIsOpen) {
          storeMarker.infoBox.close(this.map, storeMarker.marker);
          storeMarker.infoWindowIsOpen = false;
        }
      });
    });

    google.maps.event.addListener(this.infoMarker, "dragend", () => {
      let newPlace = this.infoMarker.getPosition();
      let marker = {
        lat: newPlace.lat(),
        lng: newPlace.lng(),
        group: this.groupApp.group,
        languageCode: this.selectedLanguage,
      };

      console.log("moving the marker request again", newPlace);
      this.infoMarker.setDraggable(false);
      if (this.infoForMoveTheMarker) {
        this.infoForMoveTheMarker.close();
      }
      this.infoForStopMovingTheMarker = new google.maps.InfoWindow({
        content:
          "<div style='line-height: 1.35; overflow: hidden; white-space: nowrap'>" +
          "<ion-item color='white'  lines='none' style='--min-height: 30px; padding: 0; margin: 0'> <ion-spinner color='primary' slot='start' name='dots'></ion-spinner><ion-label style='padding: 0; margin: 0'>" +
          this.translateService.instant("new-address-modal.loading") +
          "</ion-label></ion-item> </div>",
      });
      this.infoForStopMovingTheMarker.open(this.map, this.infoMarker);
      this.map.setOptions({ draggable: false });
      //Send the marker object to the request
      this.dataStorageSevice
        .checkMarker(marker)

        .subscribe({
          next: async (res: any) => {
            if (res && res.success) {
            } else {
              const alert = await this.alertController.create({
                header: this.translateService.instant("alert"),
                message:
                  res && res.comment_id
                    ? this.translateService.instant(res.comment_id)
                    : this.translateService.instant(
                        "errorMessages.general_error"
                      ),

                buttons: ["OK"],
              });
              await alert.present();
            }
            console.log("res", res);
            if (this.infoForStopMovingTheMarker) {
              this.infoForStopMovingTheMarker.close();
            }
            this.infoMarker.setDraggable(true);
            this.map.setOptions({ draggable: true });
            this.changeDetector.detectChanges();
          },
          error: async (err) => {
            const alert = await this.alertController.create({
              header: this.translateService.instant("alert"),
              message: this.translateService.instant(
                "errorMessages.problem_reaching_server"
              ),

              buttons: ["OK"],
            });
            await alert.present();
            return err;
          },
        });
    });
    if (this.infoMarker && this.infoMarker.getPosition()) {
      let data = {
        lat: this.infoMarker.getPosition().lat(),
        lng: this.infoMarker.getPosition().lng(),
        group: this.groupApp.group,
        languageCode: this.selectedLanguage,
      };
      this.infoMarker.setDraggable(false);
      this.dataStorageSevice
        .checkMarker(data)

        .subscribe({
          next: async (res: any) => {
            if (res && res.success) {
            } else {
              const alert = await this.alertController.create({
                header: this.translateService.instant("alert"),
                message:
                  res && res.comment_id
                    ? this.translateService.instant(res.comment_id)
                    : this.translateService.instant(
                        "errorMessages.general_error"
                      ),

                buttons: ["OK"],
              });
              alert.present();
            }
            console.log("res", res);
            if (this.infoForStopMovingTheMarker) {
              this.infoForStopMovingTheMarker.close();
            }
            this.infoMarker.setDraggable(true);
            this.map.setOptions({ draggable: true });
            this.changeDetector.detectChanges();
          },
          error: async (err) => {
            const alert = await this.alertController.create({
              header: this.translateService.instant("alert"),
              message: this.translateService.instant(
                "errorMessages.problem_reaching_server"
              ),

              buttons: ["OK"],
            });
            await alert.present();
            return err;
          },
        });
    }

    this.changeDetector.detectChanges();
  }

  formatStoreSchedule(schedule) {
    var weekDays = [
      this.translateService.instant("store-schedule.sunday"),
      this.translateService.instant("store-schedule.monday"),
      this.translateService.instant("store-schedule.tuesday"),
      this.translateService.instant("store-schedule.wednesday"),
      this.translateService.instant("store-schedule.thursday"),
      this.translateService.instant("store-schedule.friday"),
      this.translateService.instant("store-schedule.saturday"),
    ];

    var singleScheduleArray = [];

    _.each(schedule, (scheduleDay, key, list) => {
      if (scheduleDay.active && !scheduleDay.all_day) {
        var commentSchedule = "";
        scheduleDay.time_periods.forEach((tp, i) => {
          if (i > 0) {
            commentSchedule += ", ";
          }
          commentSchedule +=
            this.toHHMMSS(tp.start) + " - " + this.toHHMMSS(tp.stop);
        });

        singleScheduleArray.push({
          day: weekDays[key],
          schedule: commentSchedule,
        });
      } else if (scheduleDay.active && scheduleDay.all_day) {
        singleScheduleArray.push({
          day: weekDays[key],
          schedule: this.translateService.instant("store-schedule.24-hours"),
        });
      } else {
        singleScheduleArray.push({
          day: weekDays[key],
          schedule: this.translateService.instant("store-schedule.closed"),
        });
      }
    });

    var sunday = _.head(singleScheduleArray);
    singleScheduleArray = _.takeRight(singleScheduleArray, 6);
    singleScheduleArray.push(sunday);
    console.log("singleScheduleArray", singleScheduleArray);
    return singleScheduleArray;
  }

  toHHMMSS(val) {
    console.log("val", val);
    if (val) {
      let sec_num = parseInt(val, 10); // don't forget the second param
      let hours = Math.floor(sec_num / 3600);
      let minutes = Math.floor((sec_num - hours * 3600) / 60);
      let newhours = hours.toString();
      let newmins = minutes.toString();
      if (hours < 10) {
        newhours = "0" + hours;
      }
      if (minutes < 10) {
        newmins = "0" + minutes;
      }

      return newhours + ":" + newmins;
    } else {
      return "00:00";
    }
  }

  async sheetModal() {
    const modal = this.modalController.create({
      component: QrcardModalComponent,
      swipeToClose: true,
    });
  }

  closeModal() {
    this.modalController.dismiss();
  }

  selectStore() {
    // this.modalController.dismiss(this.selectedStoreId);
  }

  onSave() {
    this.modalController.dismiss(this.selectedStoreId);
  }

  ngOnDestroy() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.storeInfoSub) {
      this.storeInfoSub.unsubscribe();
    }
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
