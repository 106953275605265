<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">
      <ion-text color="primary">
        {{ "kiosk-page.kiosk-coupon-qr-display-modal.title" | translate }}
      </ion-text>
    </ion-title>
    <ion-button
      (click)="closeModal()"
      size="small"
      slot="end"
      fill="clear"
      color="dark"
    >
      <ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-list *ngIf="coupon">
      <ion-item lines="none">
        <ion-label class="ion-text-center ion-text-wrap">
          {{
            "kiosk-page.kiosk-coupon-qr-display-modal.scan-the-above-qr-in-kiosk-device-to-enable-the-coupon"
              | translate
          }}
        </ion-label>
      </ion-item>

      <div
        class="ion-text-wrap ion-text-center"
        style="font-weight: bold; margin-bottom: 0px"
      >
        {{ coupon.description }}

        <ion-text color="danger">
          <span
            *ngIf="
              coupon.discountType !== 'percent' &&
              coupon.discountType !== 'combined' &&
              coupon.discount
            "
          >
            -{{ coupon.discount }}€
          </span>
          <span *ngIf="coupon.discountType === 'percent' && coupon.discount">
            -{{ coupon.discount }}%
          </span>
          <span
            *ngIf="
              coupon.discountType === 'combined' &&
              coupon.discount_percent &&
              coupon.discount_fixed
            "
          >
            -{{ coupon.discount_percent }}% & -{{ coupon.discount_fixed }}€
          </span>
          <span
            *ngIf="
              !(
                (coupon.discountType !== 'percent' &&
                  coupon.discountType !== 'combined' &&
                  coupon.discount) ||
                (coupon.discountType === 'combined' &&
                  coupon.discount_percent &&
                  coupon.discount_fixed)
              ) &&
              coupon.product_id &&
              coupon.classic_offer_product &&
              coupon.tag_comment
            "
          >
            {{ coupon.tag_comment }}
          </span>
          <span
            *ngIf="
              !(
                (coupon.discountType !== 'percent' &&
                  coupon.discountType !== 'combined' &&
                  coupon.discount) ||
                (coupon.discountType === 'combined' &&
                  coupon.discount_percent &&
                  coupon.discount_fixed)
              ) &&
              coupon.product_id &&
              !coupon.classic_offer_product
            "
          >
            {{ "free" | translate }}
          </span>
        </ion-text>
      </div>
      <div
        [class]="
          secondsRemaining > 0
            ? 'qrcode ion-text-center'
            : 'qrcode ion-text-center low-opacity'
        "
      >
        <qrcode
          [qrdata]="coupon.kiosk_one_time_password"
          [width]="170"
          [errorCorrectionLevel]="'H'"
          [margin]="1"
        ></qrcode>
      </div>

      <div
        *ngIf="secondsRemaining > 0"
        style="font-weight: bold; margin-top: -5px; text-align: center"
      >
        {{ "kiosk-page.qr-code" | translate }}:
        <span style="font-size: large">
          <strong>{{ coupon.kiosk_one_time_password }}</strong>
        </span>
      </div>

      <div
        *ngIf="
          coupon && coupon.kiosk_expiration_timestamp && secondsRemaining > 0
        "
        class="timerDiv"
        id="timer"
      >
        <ion-grid
          class="ion-text-center"
          style="
            border: 2px solid var(--ion-color-danger);
            border-radius: 2px;
            padding: 3px;
            max-width: 120px;
            min-height: 42px;
          "
        >
          <div style="font-size: small; font-weight: bold">
            <ion-text color="danger">
              {{ "kiosk-page.password-expires-in" | translate }}
            </ion-text>
          </div>
          <ion-text color="danger"> {{ formattedTime }} </ion-text>
        </ion-grid>
      </div>

      <ion-grid
        class="ion-align-items-center ion-justify-content-center"
        (click)="triggerFetchCoupons()"
        *ngIf="
          coupon && coupon.kiosk_expiration_timestamp && secondsRemaining <= 0
        "
        style="
          border: 2px solid var(--ion-color-danger);
          border-radius: 2px;
          padding: 3px;
          max-width: 120px;
          min-height: 42px;
          margin-top: 15px;
          cursor: pointer;
        "
      >
        <ion-row
          style="width: 100%; height: 100%"
          class="ion-justify-content-center ion-align-items-center"
        >
          <ion-col style="height: 100%" size="12" class="ion-align-self-center">
            <ion-text
              color="danger"
              style="font-size: small; font-weight: bold"
              >{{ "kiosk-page.renew-password" | translate }}</ion-text
            >
          </ion-col>
        </ion-row>
        <div></div>
      </ion-grid>
    </ion-list>

    <div
      class="ion-text-center"
      *ngIf="
        stores &&
        stores.length > 1 &&
        storesByStoreId &&
        storesByStoreId[coupon.store_id] &&
        selectedLangugage
      "
    >
      <p>
        {{ "discount-coupons.coupons-item.valid-to-store" | translate
        }}<strong>
          {{
            storesByStoreId[coupon.store_id].store_name_mobileorder &&
            storesByStoreId[coupon.store_id].store_name_mobileorder[
              selectedLangugage
            ]
              ? storesByStoreId[coupon.store_id].store_name_mobileorder[
                  selectedLangugage
                ]
              : storesByStoreId[coupon.store_id].store_name_langs &&
                storesByStoreId[coupon.store_id].store_name_langs[
                  selectedLangugage
                ]
              ? storesByStoreId[coupon.store_id].store_name_langs[
                  selectedLangugage
                ]
              : storesByStoreId[coupon.store_id].store_name_langs &&
                storesByStoreId[coupon.store_id].store_name_langs.el
              ? storesByStoreId[coupon.store_id].store_name_langs.el
              : storesByStoreId[coupon.store_id].store_name
              ? storesByStoreId[coupon.store_id].store_name
              : ""
          }}
        </strong>
      </p>
    </div>
  </ion-grid>
</ion-content>
