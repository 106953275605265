<ion-card
  *ngIf="
    store_info &&
    store_info.cartCustomOptions &&
    cart &&
    cartOptionsShown &&
    cartOptionsShown === 'show'
  "
>
  <ion-item-divider mode="ios">
    <ion-label>
      {{ "cart.cart-components.extra-choices.extra-choices" | translate }}
    </ion-label>
  </ion-item-divider>
  <app-cart-custom-options
    id="cart-custom-options-no-quantity"
    [showQuantityOptions]="'no-quantity-options'"
  >
  </app-cart-custom-options>
  <app-cart-custom-options
    [showQuantityOptions]="'quantity-options'"
  ></app-cart-custom-options>
</ion-card>

<div
  *ngIf="
    !(
      store_info &&
      store_info.cartCustomOptions &&
      cart &&
      cartOptionsShown &&
      cartOptionsShown === 'show'
    )
  "
>
  <app-cart-custom-options
    id="cart-custom-options-no-quantity"
    [showQuantityOptions]="'no-quantity-options'"
  >
  </app-cart-custom-options>
  <app-cart-custom-options
    [showQuantityOptions]="'quantity-options'"
  ></app-cart-custom-options>
</div>
