import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Store } from "@ngrx/store";
import { filter, map, Observable } from "rxjs";
import * as fromApp from "../store/app.reducer";
import * as _ from "lodash";

@Injectable({
  providedIn: "root",
})
export class kioskGuard implements CanActivate {
  constructor(private router: Router, private store: Store<fromApp.AppState>) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store.select("stores").pipe(
      map((stores) => {
        return stores.stores;
      }),
      filter((stores) => stores !== null),
      map((stores) => {
        const atLeastOneStoreKioskActive = !!_.find(stores, (store) => {
          if (store.dm_kiosk_app_active) {
            return true;
          }
        });

        if (!atLeastOneStoreKioskActive) {
          return this.router.createUrlTree(["/homepage"]);
        } else {
          return true;
        }
      })
    );
  }
}
