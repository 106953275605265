import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  AlertController,
  ModalController,
  ToastController,
} from "@ionic/angular";
import { Store } from "@ngrx/store";
import { auditTime, distinctUntilChanged, first, Subscription } from "rxjs";
import * as _ from "lodash";
@Component({
  selector: "app-contest-winners-modal",
  templateUrl: "./contest-winners-modal.component.html",
  styleUrls: ["./contest-winners-modal.component.scss"],
})
export class ContestWinnersModalComponent implements OnInit, OnDestroy {
  @Input() contest;
  @Input() bigScreenStyle;
  private sub: Subscription;

  constructor(private modalController: ModalController) {}

  ngOnInit() {
    this.contest.winners = _.filter(this.contest.winners, (winner) => {
      return _.startsWith(winner.type, "winner");
    });

    _.each(this.contest.winners, (winner, index) => {
      this.contest.winners[index].mobile =
        winner.mobile.substring(0, 2) +
        "******" +
        winner.mobile.substring(8, 10);
    });
  }

  closeModal() {
    this.modalController.dismiss();
  }

  ngOnDestroy(): void {}
}
