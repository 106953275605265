import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { auditTime, distinctUntilChanged, first, Subscription } from 'rxjs';
import * as fromApp from "../../../store/app.reducer";
import * as _ from "lodash";
import { TranslateService } from '@ngx-translate/core';
import { DataStorageService } from '../../../services/data-storage/data-storage.service';
import * as LoyaltySystemActions from '../../store/loyalty-system.actions';
import { KeyValue } from '@angular/common';

@Component({
  selector: "app-loyalty-purchase-history",
  templateUrl: "./loyalty-purchase-history.component.html",
  styleUrls: ["./loyalty-purchase-history.component.scss"],
})
export class LoyaltyPurchaseHistoryComponent implements OnInit, OnDestroy {
  public userLoyaltyProducts: any;
  // public months = [
  //   "December",
  //   "November",
  //   "October",
  //   "September",
  //   "August",
  //   "July",
  //   "June",
  //   "May",
  //   "April",
  //   "March",
  //   "February",
  //   "January",
  // ];
  public months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  public unableToLoadUserLoyaltyProducts: boolean = false;

  private subscriptions: Subscription[] = [];
  private unsubscribeBackEvent: Subscription;
  monthsValues: any;
  productsSortedByYear: any = {};
  lastEvaluatedKey: any;
  userLoyaltyProductsIsLoading: boolean;
  alertIsOpen: boolean;
  disabled: boolean;
  yearList: any[] = [];
  monthsWithProducts: any;
  customerProducts: any;

  constructor(
    private modalController: ModalController,
    private store: Store<fromApp.AppState>,
    private platform: Platform,
    private translateService: TranslateService,
    private dataStorageService: DataStorageService,
    private alertController: AlertController
  ) {}

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        this.closeModal();
      }
    );
    this.fetchUserLoyaltyProducts("", true);
    this.subscriptions.push(
      this.store
        .select("loyaltySystem")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(200))
        .subscribe((state) => {
          if (
            state &&
            state.userLoyaltyProductsHistory &&
            !_.isEqual(this.userLoyaltyProducts, state.userLoyaltyProductsHistory)
          ) {
            this.customerProducts = _.cloneDeep(state.userLoyaltyProductsHistory);

            // this.productsSortedByYear = {};
            this.orderArrayBasedOnYearAndMonth(this.customerProducts);
          }
          if (
            state &&
            !_.isEqual(
              this.unableToLoadUserLoyaltyProducts,
              state.unableToLoadUserLoyaltyProducts
            )
          ) {
            this.unableToLoadUserLoyaltyProducts = _.cloneDeep(
              state.unableToLoadUserLoyaltyProducts
            );
          }
        })
    );
  }

  // not used, we use the orderArrayBasedOnYearAndMonth
  shortProductsByYearsAndMonths(userLoyaltyProducts) {
    _.forEach(userLoyaltyProducts, (product) => {
      // product.timestamp = parseInt(product.timestamp, 10);
      const date = new Date(parseInt(product.timestamp));
      const month = date.getMonth();
      const year = date.getFullYear();
      if (!this.productsSortedByYear[year]) {
        this.productsSortedByYear[year] = [];
      }
      for (let i = 0; i < 12; i++) {
        if (!this.productsSortedByYear[year][i]) {
          this.productsSortedByYear[year][i] = [];
        }
        if (i === month) {
          this.productsSortedByYear[year][i].push(product);
        }
      }
    });

    console.log("productsSortedByYear", this.productsSortedByYear);
  }

  orderArrayBasedOnYearAndMonth(customerProducts) {
    _.forEach(customerProducts, (product) => {
      const date = new Date(parseInt(product.timestamp, 10));
      const month = date.getMonth();
      const year = date.getFullYear();
      product.month = this.months[month];
      product.year = year;
      const index = _.findIndex(this.yearList, (year1) => {
        return year1 === year;
      });
      console.log(index);
      if (index === -1) {
        this.yearList.push(year);
      }
    });
    console.log(customerProducts);
    console.log(this.yearList);
    this.monthsWithProducts = _.filter(this.months, (month) => {
      _.forEach(customerProducts, (product) => {
        return product.month === month;
      });
    });

    this.monthsWithProducts = _.filter(this.months, (month) => {
      if (
        _.find(this.customerProducts, (product) => {
          if (product.month === month) {
            return product;
          }
        })
      ) {
        return month;
      }
    });
    // this.monthsWithProducts.sort((a,b) =>{
    //   return this.months.indexOf(a) > this.months.indexOf(b);
    // });
    this.monthsWithProducts.reverse();
    console.log(this.monthsWithProducts);
  }

  customerProductsTrackBy(index: number, item: any): string {
    return item.timestamp;
  }

  closeModal() {
    this.modalController.dismiss();
  }

  async fetchUserLoyaltyProducts(ev, initLastEvaluatedKey) {
    if (initLastEvaluatedKey) {
      this.lastEvaluatedKey = null;
    }
    this.store
      .select("auth")
      .pipe(first())
      .subscribe((state: any) => {
        if (state.mo_token) {
          this.userLoyaltyProductsIsLoading = true;
          this.dataStorageService
            .fetchUserLoyaltyProducts(state.mo_token, {
              exclusiveStartKey: this.lastEvaluatedKey,
            })
            .subscribe({
              next: async (resData: any) => {
                if (!resData || !resData.success) {
                  this.store.dispatch(new LoyaltySystemActions.SetUnableToLoadUserLoyaltyProducts(true));
                  this.userLoyaltyProductsIsLoading = false;
                  // MISSING  || resData.success
                  //this.alertIsOpen = true;
                  const alert = await this.alertController.create({
                    header: this.translateService.instant("alert"),
                    backdropDismiss: false,
                    message:
                      resData && resData.comment_id
                        ? this.translateService.instant(resData.comment_id)
                        : this.translateService.instant(
                            "There was an error in the process.<br><br> Try again please."
                          ),
                    buttons: [
                      {
                        text: "OK",
                        role: "cancel",
                        handler: () => {
                          console.log("Cancel clicked");
                          this.alertIsOpen = false;
                        },
                      },
                    ],
                  });

                  await alert.present();
                  if (alert) {
                    this.alertIsOpen = true;
                  }
                } else if (resData && resData.success) {
                  this.store.dispatch(new LoyaltySystemActions.SetUnableToLoadUserLoyaltyProducts(false));
                  console.log(resData);
                  this.store.dispatch(
                    new LoyaltySystemActions.SetUserLoyaltyProductsHistory(
                      _.cloneDeep(resData.loyalty_products)
                    )
                  );
                  this.userLoyaltyProductsIsLoading = false;
                  this.lastEvaluatedKey = resData.lastEvaluatedKey;
                  this.disabled = !this.lastEvaluatedKey;
                }
                if (ev) {
                  ev.target.complete();
                }
              },
              error: async (error) => {
                this.store.dispatch(new LoyaltySystemActions.SetUnableToLoadUserLoyaltyProducts(true));
                this.userLoyaltyProductsIsLoading = false;
                //this.alertIsOpen = true;
                const alert = await this.alertController.create({
                  header: this.translateService.instant("alert"),
                  backdropDismiss: false,
                  message: this.translateService.instant(
                    "problem_reaching_server"
                  ),
                  buttons: [
                    {
                      text: "OK",
                      role: "cancel",
                      handler: () => {
                        console.log("Cancel clicked");
                        this.alertIsOpen = false;
                      },
                    },
                  ],
                });
                await alert.present();
                if (alert) {
                  this.alertIsOpen = true;
                }
                console.log("problem_reaching_server", error);
              },
              complete: () => {},
            });
        }
      });
  }

  keyDescOrder = (a: KeyValue<number, string>,b: KeyValue<number, string>): number => {
    return a.key > b.key ? -1 : b.key > a.key ? 1 : 0;
  };

  trackByFunc(index, item) {
    return item.timestamp;
  }

  ngOnDestroy() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    this.customerProducts = null;
    this.store.dispatch(new LoyaltySystemActions.ClearUserLoyaltyProductsHistory());
    this.subscriptions = [];
  }
}
