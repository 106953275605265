<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button color="primary" (click)="modalCtrl.dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title
      class="ion-text-wrap"
      style="font-size: medium; font-weight: bold"
      >{{"reservation-app.instractions" |translate}}</ion-title
    >
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-item lines="none">
    <ion-label
      class="ion-text-wrap ion-text-center"
      >{{"reservation-app.instractionsQr" |translate}}</ion-label
    >
  </ion-item>
</ion-content>
