import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { auditTime, distinctUntilChanged } from "rxjs/operators";
import * as fromApp from "../../store/app.reducer";
import * as selectors from "../../store/app.selectors";
import * as _ from "lodash";
import * as CartActions from "../../cart/store/cart.actions";
import { Subscription } from "rxjs";

@Component({
  selector: "app-browser-catalog-md",
  templateUrl: "./browser-catalog-md.component.html",
  styleUrls: ["./browser-catalog-md.component.scss"],
})
export class BrowserCatalogMdComponent implements OnInit, OnChanges, OnDestroy {
  @Input() transparentCatalogElements: boolean;
  @Input() catalogInlineInfoMessage;
  @Input() motoCardsProgressValues;
  @Input() infoMessagesCardsHide;
  @Input() rerenderProductsItemsTimestamp: string | null;
  @Input() rerenderProductItemsTimestamp: string | null;

  @Output() listScrollFromTopEv = new EventEmitter();
  @Output() listScrollFromBottomEv = new EventEmitter();

  public categories: any;
  public currentView: string;
  public productListColSize: any;
  public doNotShowProductsList;
  public storeInfo;
  private subscription2: Subscription;
  private subscription: Subscription;

  constructor(private store: Store<fromApp.AppState>) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.rerenderProductsItemsTimestamp &&
      changes.rerenderProductsItemsTimestamp.previousValue &&
      changes.rerenderProductsItemsTimestamp.currentValue &&
      changes.rerenderProductsItemsTimestamp.previousValue !==
        changes.rerenderProductsItemsTimestamp.currentValue
    ) {
      this.doNotShowProductsList = true;
      setTimeout(() => {
        this.doNotShowProductsList = false;
      }, 300);
    }
  }

  ngOnInit() {
    this.subscription2 = this.store
      .select(selectors.getStoreInfo)

      .pipe(distinctUntilChanged())
      .subscribe((storeInfo) => {
        if (storeInfo && !_.isEqual(this.storeInfo, storeInfo)) {
          this.storeInfo = _.cloneDeep(storeInfo);
        }
      });

    this.subscription = this.store
      .select("catalog")
      .pipe(auditTime(300))
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.catalog &&
          !_.isEqual(this.categories, state.catalog)
        ) {
          this.categories = _.cloneDeep(state.catalog);
        }
      });
  }

  scrollFromTopEvent(scrollTop) {
    this.listScrollFromTopEv.emit(scrollTop);
  }

  scrollFromBottomEvent(scrollFromBottom) {
    this.listScrollFromBottomEv.emit(scrollFromBottom);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscription2) {
      this.subscription2.unsubscribe();
    }
  }
}
