import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import SwiperCore, {
  Autoplay,
  FreeMode,
  Keyboard,
  Navigation,
  Pagination,
  Zoom,
} from "swiper";
import { AnimationOptions } from "ngx-lottie";
import { Subscription, auditTime, distinctUntilChanged } from "rxjs";
import { Store } from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";
import _ from "lodash";
import moment from "moment";
import { ReservationCartComponent } from "../reservation-cart/reservation-cart.component";
import { TranslateService } from "@ngx-translate/core";
import * as ReservationsActions from "../../reservations/store/reservations.actions";
SwiperCore.use([Navigation, Autoplay, Keyboard, Pagination, Zoom, FreeMode]);

@Component({
  selector: "app-reservation-select-date",
  templateUrl: "./reservation-select-date.page.html",
  styleUrls: ["./reservation-select-date.page.scss"],
})
export class ReservationSelectDatePage implements OnInit, OnDestroy {
  @Input() artist: any;
  @Input() storeInfo: any;
  @Input() product_id: any;
  @Input() selectedLanguage: any;
  selectedProduct;
  cartProducts;
  allHours = [];
  isWobble = false;
  windowDimensions: any;
  columnSize: any;
  slideColors: string[] = [];
  letterColors: string[] = [];
  selectedDateIndex: any;
  selectedHourIndex: any; // Index of the selected artist (-1 means none selected)
  selectedHour: any;
  selectedDate: any;
  currentDate = new Date();
  greekDays = ["Κυρ", "Δευ", "Τρί", "Τετ", "Πέμ", "Παρ", "Σάβ"];
  orderedGreekDays: any;
  enabledHours: any;
  columnSize2: number;
  enabledHours2: string[] = [];
  enabledHours3: boolean[] = [];
  reservationsFetchTimestamp = null;

  public sliderOptions = {
    allowTouchMove: true,
    slidesPerView: "auto",
    direction: "horizontal",
    slideToClickedSlide: true,
    mousewheel: true,
    freeMode: true,
    freeModeMomentumBounce: false,
    freeModeMomentumRatio: 0.8,
    freeModeMomentumVelocityRatio: 0.8,

    centeredSlides: true,
    spaceBetween: 20,
  };

  private unsubscribeBackEvent: Subscription;
  private reservationCartModal: any;
  private subscriptions: Subscription[] = [];

  constructor(
    public modalCtrl: ModalController,
    private platform: Platform,
    private store: Store<fromApp.AppState>,
    private translateService: TranslateService
  ) {}

  public setSwiperInstance(swiper: SwiperCore) {
    // swiper.autoplay.start();
  }
  ngOnInit() {
    console.log("storeINfo", this.storeInfo);
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      300,
      () => {
        if (this.platform.is("android")) {
          this.modalCtrl.dismiss("back");
        }
      }
    );

    this.subscriptions.push(
      this.store
        .select("cart")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(200))
        .subscribe((state) => {
          if (state && !_.isEqual(state.products, this.cartProducts)) {
            this.cartProducts = _.cloneDeep(state.products);
            if (this.cartProducts && this.cartProducts.length) {
              this.selectedProduct = _.find(_.cloneDeep(this.cartProducts), {
                product_id: this.product_id,
              });
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("reservations")
        .pipe(auditTime(300))
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.reservationsFetchTimestamp &&
            !_.isEqual(
              state.reservationsFetchTimestamp,
              this.reservationsFetchTimestamp
            )
          ) {
            this.reservationsFetchTimestamp = _.cloneDeep(
              state.reservationsFetchTimestamp
            );
            if (this.reservationCartModal) {
              this.reservationCartModal.dismiss();
              this.reservationCartModal = null;
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.windowDimensions, state.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
            // [size]="windowDimensions.width > 600 ? '6': '12'"
            if (this.windowDimensions.width > 700) {
              this.columnSize = 8;
              this.columnSize2 = 4;
            } else {
              this.columnSize = 12;
              this.columnSize2 = 12;
            }
          }
        })
    );
    console.log(this.artist);
    const currentDayOfWeek = this.currentDate.getDay();
    this.orderedGreekDays = [
      this.greekDays[currentDayOfWeek], // Current day
      ...this.greekDays.slice(currentDayOfWeek + 1), // Days after the current day
      ...this.greekDays.slice(0, currentDayOfWeek), // Days before the current day
    ];
    this.orderedGreekDays[0] = this.translateService.instant(
      "reservation-app.today"
    );
    this.orderedGreekDays[1] = this.translateService.instant(
      "reservation-app.tomorrow"
    );
    console.log(this.orderedGreekDays);
    this.slideColors = Array(this.artist.dates.length).fill("");
    this.letterColors = Array(this.artist.dates.length).fill("#000000");
  }

  filterHours() {
    // this.enabledHours2 = _.filter(this.allHours, (hour) => {
    //   const timestamp = this.getTimestamp(
    //     hour,
    //     this.selectedDate.hours[0].from
    //   );
    //   return _.some(
    //     this.selectedDate.hours,
    //     (range) => timestamp >= range.from && timestamp <= range.to
    //   );
    // });
    // console.log(this.enabledHours2);

    let previous_time;
    let changed_day = false;
    let show = true;
    const dayStart = this.getTimestamp(this.selectedDate.hours[0].from);

    this.enabledHours2 = _.filter(this.allHours, (hour) => {
      const split = _.split(hour, ":");
      let time = parseInt(split[0]) * 60 * 60 + parseInt(split[1]) * 60;
      if (changed_day) {
        time += 60 * 60 * 24;
      } else if (previous_time && previous_time > time) {
        changed_day = true;
        time += 60 * 60 * 24;
      }
      previous_time = time;

      time += dayStart;
      if (show) {
        console.log("show", time, this.selectedDate.hours);
        show = false;
      }

      const found = _.find(this.selectedDate.hours, (range) => {
        const from = range.from;
        let to = range.to;
        // if (to <= from) {
        //   to += 60*60*24;
        // }
        if (time >= from && time <= to) {
          return true;
        } else {
          return false;
        }
      });

      return !!found;
    });

    let found_first = 0;
    _.forEach(this.allHours, (hour) => {
      if (this.enabledHours2.includes(hour)) {
        return false;
      } else {
        found_first++;
      }
    });

    let found_last = 0;
    _.forEachRight(this.allHours, (hour) => {
      if (this.enabledHours2.includes(hour)) {
        return false;
      } else {
        found_last++;
      }
    });

    this.allHours = _.slice(
      this.allHours,
      found_first,
      this.allHours.length - found_last
    );

    this.enabledHours3 = [];
    this.allHours.forEach((hour) => {
      this.enabledHours3.push(this.enabledHours2.includes(hour));
    });
  }

  getTimestamp(timestamp: number): number {
    const date = new Date(timestamp * 1000); // Convert the day timestamp to milliseconds
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return Math.floor(date.getTime() / 1000);
  }

  secondsToTime(seconds) {
    let hours = _.floor(seconds / (60 * 60)).toString();
    let minutes = _.floor(
      (seconds - parseInt(hours) * 60 * 60) / 60
    ).toString();

    if (hours.length < 2) {
      hours = "0" + hours;
    }
    if (minutes.length < 2) {
      minutes = "0" + minutes;
    }
    return hours + ":" + minutes;
  }

  handleSlideClick(date: any, i: any) {
    this.selectedHour = null;
    this.selectedHourIndex = null;
    this.slideColors = Array(this.artist.dates.length).fill("");
    this.letterColors = Array(this.artist.dates.length).fill("");
    // Perform the desired action when a slide is clicked

    this.slideColors[i] = "var(--ion-color-primary)";
    this.letterColors[i] = "var(--ion-color-primary-contrast)";
    this.selectedDateIndex = i;
    this.selectedDate = date;
    this.enabledHours = this.artist.dates[this.selectedDateIndex].hours;

    let schedule;
    if (this.storeInfo.reservationAcceptScheduleActive) {
      schedule = this.storeInfo.reservationAcceptSchedule;
    } else {
      schedule = this.storeInfo.schedule;
    }

    const d = new Date(date.day * 1000);
    const day = d.getDay();
    const schedule_day = schedule[day];

    let periods;
    if (schedule_day.all_day) {
      periods = [
        {
          start: 0,
          stop: 60 * 60 * 24,
        },
      ];
    } else {
      periods = schedule_day.time_periods;
    }

    let find_min;
    let find_max;
    _.forEach(periods, (period) => {
      if (!find_min || period.start < find_min) {
        find_min = period.start;
      }
      if (!find_max || period.stop > find_max) {
        find_max = period.stop;
      }
    });

    find_min = _.ceil(find_min / (15 * 60)) * 15 * 60;
    //find_max = find_max - this.selectedProduct.service_duration_min * 60;
    find_max = _.floor(find_max / (15 * 60)) * 15 * 60;

    this.allHours = [];
    let current = find_min;
    while (current <= find_max) {
      this.allHours.push(this.secondsToTime(current));
      current += 15 * 60;
    }

    this.filterHours();
  }

  selectHour(index: number, item: any) {
    console.log("Clicked on item:", item);
    console.log("Clicked index:", index);
    if (this.selectedHour) {
      this.isWobble = !this.isWobble;
    }
    this.selectedHourIndex = index;
    this.selectedHour = item;
    setTimeout(() => {
      this.isWobble = false;
    }, 1000);
  }

  async submitDate() {
    this.store.dispatch(
      new ReservationsActions.SetReservationSelectedDate({
        date: _.cloneDeep(this.selectedDate),
        hour: _.cloneDeep(this.selectedHour),
      })
    );

    this.reservationCartModal = await this.modalCtrl.create({
      component: ReservationCartComponent,
      animated: true,
      backdropDismiss: false,
      componentProps: {
        selectedProduct: this.selectedProduct,
        selectedArtist: this.artist,
      },
      cssClass: "fullScreenModal",
    });
    await this.reservationCartModal.present();

    await this.reservationCartModal.onDidDismiss().then((data) => {
      this.reservationCartModal = null;
    });
  }

  ngOnDestroy(): void {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }

    if (this.reservationCartModal) {
      this.reservationCartModal.dismiss();
      this.reservationCartModal = null;
    }
  }
}
