import { Pipe, PipeTransform } from "@angular/core";
import * as _ from "lodash";

@Pipe({
  name: "filterSelectedCategoryProducts",
})
export class FilterSelectedCategoryProductsPipe implements PipeTransform {
  transform(categories: any, selectedCategoryId: any, store_info: any): any {
    if (
      categories &&
      categories.length &&
      selectedCategoryId &&
      store_info &&
      store_info.design_settings &&
      store_info.design_settings.showOnlySelectedCategoryProducts
    ) {
      return _.filter(_.cloneDeep(categories), (categ) => {
        if (categ.category_id === selectedCategoryId) {
          return categ;
        }
      });
    } else {
      return categories;
    }
  }
}
