import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "factorPointRuleOrderType",
})
export class FactorPointRuleOrderTypePipe implements PipeTransform {
  transform(
    order_type: string,
    store_info: any,
    selectedLanguage: any,
    mo_base: any
  ): string {
    if (order_type && order_type === "dinein") {
      if (
        store_info &&
        store_info.dinein_alternative_name &&
        selectedLanguage &&
        store_info.dinein_alternative_name[selectedLanguage]
      ) {
        order_type =
          "Dine In " + store_info.dinein_alternative_name[selectedLanguage];
      } else if (
        store_info &&
        store_info.dinein_alternative_name &&
        mo_base &&
        store_info.dinein_alternative_name[mo_base]
      ) {
        order_type = "Dine In " + store_info.dinein_alternative_name[mo_base];
      }
    } else if (order_type && order_type === "altdinein") {
      if (
        store_info &&
        store_info.alt_dinein_alternative_name &&
        selectedLanguage &&
        store_info.alt_dinein_alternative_name[selectedLanguage]
      ) {
        order_type =
          "Dine In " + store_info.alt_dinein_alternative_name[selectedLanguage];
      } else if (
        store_info &&
        store_info.alt_dinein_alternative_name &&
        mo_base &&
        store_info.alt_dinein_alternative_name[mo_base]
      ) {
        order_type =
          "Dine In " + store_info.alt_dinein_alternative_name[mo_base];
      }
    }
    return order_type;
  }
}
