import { Action } from '@ngrx/store';

export const SET_RECOMMENDED_PRODUCTS =
  '[RECOMMENDED PRODUCTS] Set Recommended Products';

export const CLEAR_RECOMMENDED_PRODUCTS =
  '[PRODUCTS] CLear Recommended Products';

export class SetRecommendedProducts implements Action {
  readonly type = SET_RECOMMENDED_PRODUCTS;
  constructor(public payload: any) {}
}
export class ClearRecommendedProducts implements Action {
  readonly type = CLEAR_RECOMMENDED_PRODUCTS;
  constructor(public payload: any) {}
}

export type RecommendedProductsActions =
  | SetRecommendedProducts
  | ClearRecommendedProducts;
