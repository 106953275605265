<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">
      {{
        "payment-cards.modals.select-payment-gateway-tokenization.select-payment-gateway"
          | translate
      }}
    </ion-title>
    <ion-button
      (click)="closeModal()"
      size="small"
      slot="end"
      fill="clear"
      color="dark"
    >
      <ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list *ngIf="paymentGateways && paymentGateways.length > 0 && storeInfo">
    <span *ngFor="let method of paymentGateways">
      <ion-item
        button
        detail="false"
        (click)="selectedPaymentGateway(1, method, 'saveCard', null)"
      >
        <!-- {{
          "payment-cards.modals.select-payment-gateway-tokenization." +
            method.id | translate
        }} -->
        <ion-row
          class="ion-align-items-center ion-justify-content-center"
          style="width: 100%"
        >
          <ion-col
            size="5"
            *ngIf="method && method.id !== 'cash' && method.id !== 'mobilePOS'"
          >
            <img
              *ngIf="method && method.id === 'ethniki'"
              alt="ethniki"
              width="50px"
              src="https://data.deliverymanager.gr/bankicons/ethniki.png"
            />
            <img
              *ngIf="method && method.id === 'nbg_simplify'"
              alt="nbg_simplify"
              width="50px"
              src="https://data.deliverymanager.gr/bankicons/ethniki.png"
            />
            <img
              *ngIf="method && method.id === 'mypos'"
              height="30px"
              alt="mypos"
              src="https://data.deliverymanager.gr/bankicons/mypos.png"
            />
            <img
              *ngIf="method && method.id === 'viva'"
              width="100px"
              [class]="darkMode ? 'invert' : ''"
              src="https://data.deliverymanager.gr/bankicons/viva.png"
            />
            <img
              *ngIf="method && method.id === 'piraeus'"
              width="30px"
              alt="piraeus"
              src="https://data.deliverymanager.gr/bankicons/piraeus.png"
            />
            <img
              *ngIf="method && method.id === 'eurobank'"
              width="70px"
              alt="eurobank"
              src="https://data.deliverymanager.gr/bankicons/eurobank.png"
            />
            <img
              *ngIf="method && method.id === 'alpha'"
              width="80px"
              alt="alpha"
              src="https://data.deliverymanager.gr/bankicons/alpha.png"
            />
            <img
              *ngIf="method && method.id === 'paypal'"
              width="70px"
              alt="paypal"
              src="https://data.deliverymanager.gr/bankicons/paypal.png"
            />
          </ion-col>
          <ion-col
            size="7"
            *ngIf="
              method &&
              method.id &&
              method.id !== 'cash' &&
              method.id !== 'mobilePOS'
            "
            class="ion-text-center"
          >
            <img
              *ngIf="
                storesByStoreId &&
                selectedStoreId &&
                storesByStoreId[selectedStoreId] &&
                storesByStoreId[selectedStoreId].paymentGateways &&
                storesByStoreId[selectedStoreId].paymentGateways[method.id] &&
                storesByStoreId[selectedStoreId].paymentGateways[method.id]
                  .cards &&
                storesByStoreId[selectedStoreId].paymentGateways[method.id]
                  .cards.visa
              "
              width="35px"
              alt="visa"
              style="padding-left: 2px; padding-right: 2px"
              src="https://data.deliverymanager.gr/bankicons/visa.png"
            />
            <img
              *ngIf="
                storesByStoreId &&
                selectedStoreId &&
                storesByStoreId[selectedStoreId] &&
                storesByStoreId[selectedStoreId].paymentGateways &&
                storesByStoreId[selectedStoreId].paymentGateways[method.id] &&
                storesByStoreId[selectedStoreId].paymentGateways[method.id]
                  .cards &&
                storesByStoreId[selectedStoreId].paymentGateways[method.id]
                  .cards.mastercard
              "
              width="35px"
              style="padding-left: 2px; padding-right: 2px"
              src="https://data.deliverymanager.gr/bankicons/mastercard.png"
            />
            <img
              *ngIf="
                storesByStoreId &&
                selectedStoreId &&
                storesByStoreId[selectedStoreId] &&
                storesByStoreId[selectedStoreId].paymentGateways &&
                storesByStoreId[selectedStoreId].paymentGateways[method.id] &&
                storesByStoreId[selectedStoreId].paymentGateways[method.id]
                  .cards &&
                storesByStoreId[selectedStoreId].paymentGateways[method.id]
                  .cards.maestro
              "
              width="55px"
              alt="maestro"
              style="padding-left: 2px; padding-right: 2px"
              src="https://data.deliverymanager.gr/bankicons/maestro.png"
            />
            <img
              *ngIf="
                storesByStoreId &&
                selectedStoreId &&
                storesByStoreId[selectedStoreId] &&
                storesByStoreId[selectedStoreId].paymentGateways &&
                storesByStoreId[selectedStoreId].paymentGateways[method.id] &&
                storesByStoreId[selectedStoreId].paymentGateways[method.id]
                  .cards &&
                storesByStoreId[selectedStoreId].paymentGateways[method.id]
                  .cards.discover
              "
              width="55px"
              alt="discover"
              style="padding-left: 2px; padding-right: 2px"
              src="https://data.deliverymanager.gr/bankicons/discover.png"
            />
            <img
              *ngIf="
                storesByStoreId &&
                selectedStoreId &&
                storesByStoreId[selectedStoreId] &&
                storesByStoreId[selectedStoreId].paymentGateways &&
                storesByStoreId[selectedStoreId].paymentGateways[method.id] &&
                storesByStoreId[selectedStoreId].paymentGateways[method.id]
                  .cards &&
                storesByStoreId[selectedStoreId].paymentGateways[method.id]
                  .cards.diners
              "
              width="48px"
              alt="diners"
              style="padding-left: 2px; padding-right: 2px"
              src="https://data.deliverymanager.gr/bankicons/diners.png"
            />
            <img
              *ngIf="
                storesByStoreId &&
                selectedStoreId &&
                storesByStoreId[selectedStoreId] &&
                storesByStoreId[selectedStoreId].paymentGateways &&
                storesByStoreId[selectedStoreId].paymentGateways[method.id] &&
                storesByStoreId[selectedStoreId].paymentGateways[method.id]
                  .cards &&
                storesByStoreId[selectedStoreId].paymentGateways[method.id]
                  .cards.american
              "
              width="88px"
              alt="american"
              style="padding-left: 2px; padding-right: 2px"
              src="https://data.deliverymanager.gr/bankicons/american.png"
            />
          </ion-col>
        </ion-row>
        <ion-button fill="clear">
          <ion-icon
            src="assets/ionicons/chevron-forward-outline.svg"
            slot="icon-only"
          >
          </ion-icon>
        </ion-button>
      </ion-item>
    </span>
  </ion-list>
</ion-content>
