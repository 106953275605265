import * as PaymentGateWaysActions from "./payment-gateways.actions";
import * as _ from "lodash";

export interface State {
  paymentGateWays: any;
  cartPaymentGateWays: any;
  allStoresPaymentGateways: any;
  showPaymentCards: any;
  showPaymentCardsAdminOnly: any;
  showPaymentCardsCustomerOnly: any;
  newCardBillingInfo: any;
  bankFrameLinkForOpen: any;
}

const initialState: State = {
  paymentGateWays: null,
  cartPaymentGateWays: null,
  allStoresPaymentGateways: null,
  showPaymentCards: null,
  showPaymentCardsAdminOnly: null,
  showPaymentCardsCustomerOnly: null,
  newCardBillingInfo: null,
  bankFrameLinkForOpen: null,
};

export function paymentGateWaysReducer(
  state = initialState,
  action: PaymentGateWaysActions.PaymentGateWaysActions
) {
  switch (action.type) {
    case PaymentGateWaysActions.SET_PAYMENT_GATEWAYS:
      return {
        ...state,
        paymentGateWays: action.payload,
      };
    case PaymentGateWaysActions.SET_CART_PAYMENT_GATEWAYS:
      return {
        ...state,
        cartPaymentGateWays: action.payload,
      };
    case PaymentGateWaysActions.SET_ALL_STORES_PAYMENT_GATEWAYS:
      return {
        ...state,
        allStoresPaymentGateways: action.payload,
      };

    case PaymentGateWaysActions.SET_SHOW_PAYMENT_CARDS:
      return {
        ...state,
        showPaymentCards: action.payload,
      };
    case PaymentGateWaysActions.SET_SHOW_PAYMENT_CARDS_ADMIN_ONLY:
      return {
        ...state,
        showPaymentCardsAdminOnly: action.payload,
      };
    case PaymentGateWaysActions.SET_SHOW_PAYMENT_CARDS_CUSTOMER_ONLY:
      return {
        ...state,
        showPaymentCardsCustomerOnly: action.payload,
      };

    case PaymentGateWaysActions.SET_NEW_CARD_BILLING_INFO:
      return {
        ...state,
        newCardBillingInfo: action.payload,
      };
    case PaymentGateWaysActions.CLEAR_PAYMENT_GATEWAYS:
      return {
        ...state,
        paymentGateWays: null,
        showPaymentCards: null,
        showPaymentCardsAdminOnly: null,
        showPaymentCardsCustomerOnly: null,
      };

    case PaymentGateWaysActions.SET_BANK_FRAME_LINK_FOR_OPEN:
      return {
        ...state,
        bankFrameLinkForOpen: action.payload,
      };

    default:
      return state;
  }
}
