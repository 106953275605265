import { Action } from "@ngrx/store";

export const ADD_UPDATE_CUSTOM_OPTION_SELECTION =
  "[CART CUSTOM OPTIONS SELECTIONS] Add Update Custom Option Selection";
export const CLEAR_CART_CUSTOM_OPTIONS_SELECTIONS =
  "[CART CUSTOM OPTIONS SELECTIONS] Clear Cart Custom Options Selection";

export class AddUpdateCartCustomOptionSelections implements Action {
  readonly type = ADD_UPDATE_CUSTOM_OPTION_SELECTION;
  constructor(public payload: any) {}
}

export class ClearCartCustomOptionsSelections implements Action {
  readonly type = CLEAR_CART_CUSTOM_OPTIONS_SELECTIONS;
  constructor() {}
}

export type CartCustomOptionsSelectionsActions =
  | AddUpdateCartCustomOptionSelections
  | ClearCartCustomOptionsSelections;
