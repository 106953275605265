<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">
      {{
        "cart.cart-components.invoice.invoice-list-modal.select-invoice-data"
          | translate
      }}
    </ion-title>
    <ion-button
      color="danger"
      slot="end"
      fill="clear"
      color="dark"
      (click)="closeModal()"
      ><ion-icon src="assets/ionicons/close.svg" slot="icon-only"></ion-icon
    ></ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list *ngIf="currentUser && currentUser.invoiceData && storeInfo">
    <ion-item lines="full" *ngFor="let invoice of currentUser.invoiceData">
      <ion-row style="width: 100%" class="ion-padding">
        <ion-col
          [class]="
            storeInfo && storeInfo.vatBasedCustomerProductsActive
              ? 'ion-text-center'
              : ''
          "
          size="12"
          *ngIf="invoice.vatNumber"
        >
          <strong>
            <span>
              {{
                "cart.cart-components.invoice.invoice-list-modal.vat-number"
                  | translate
              }}
              :
              {{ invoice.vatNumber }}
            </span>
          </strong>
        </ion-col>
        <ion-col
          [class]="
            storeInfo && storeInfo.vatBasedCustomerProductsActive
              ? 'ion-text-center small-bold-text'
              : ''
          "
          size="12"
          *ngIf="invoice.company_name"
        >
          <span>
            {{ invoice.company_name }}
          </span>
        </ion-col>
        <ion-col
          size="12"
          *ngIf="
            invoice.company_address && !storeInfo.vatBasedCustomerProductsActive
          "
        >
          <span>
            {{ invoice.company_address }}
          </span>
        </ion-col>
        <ion-col
          size="12"
          *ngIf="invoice.vatOffice && !storeInfo.vatBasedCustomerProductsActive"
        >
          <span>
            {{
              "cart.cart-components.invoice.invoice-list-modal.vat-office"
                | translate
            }}
            :
            {{ invoice.vatOffice }}
          </span>
        </ion-col>
        <ion-col
          [class]="
            storeInfo && storeInfo.vatBasedCustomerProductsActive
              ? 'ion-text-center small-bold-text'
              : ''
          "
          size="12"
          *ngIf="invoice.activity"
        >
          <span>
            {{
              storeInfo && !storeInfo.vatBasedCustomerProductsActive
                ? ("cart.cart-components.invoice.invoice-list-modal.activity"
                  | translate)
                : ""
            }}
            {{
              storeInfo && !storeInfo.vatBasedCustomerProductsActive ? ":" : ""
            }}
            {{ invoice.activity }}
          </span>
        </ion-col>

        <ion-col size="12" class="ion-text-center">
          <ion-button
            [hidden]="storeInfo.vatBasedCustomerProductsActive"
            [disabled]="loading"
            (click)="presentAlertConfirm(invoice)"
            fill="clear"
            color="danger"
            style="font-weight: 600"
          >
            {{ "cart.cart-components.invoice.delete" | translate }}</ion-button
          >
          <ion-button
            *ngIf="!storeInfo.vatBasedCustomerProductsActive"
            [disabled]="loading"
            [fill]="
              storeInfo && storeInfo.vatBasedCustomerProductsActive
                ? 'solid'
                : 'clear'
            "
            color="primary"
            style="font-weight: 600"
            (click)="presentAddEditModal('edit', invoice)"
            >{{ "cart.cart-components.invoice.edit" | translate }}</ion-button
          >
          <ion-button
            [disabled]="loading"
            [fill]="
              storeInfo && storeInfo.vatBasedCustomerProductsActive
                ? 'solid'
                : 'clear'
            "
            color="primary"
            style="font-weight: 600"
            [disabled]="
              (invoice && !invoice.vatNumber) ||
              !invoice.vatOffice ||
              !invoice.company_name ||
              !invoice.company_address ||
              !invoice.activity ||
              (storeInfo &&
                storeInfo.vatBasedCustomerProductsActive &&
                !invoice.migration_customer_id)
            "
            (click)="selectInvoice(invoice)"
            >{{
              storeInfo && storeInfo.vatBasedCustomerProductsActive
                ? ("cart.cart-components.invoice.select-vatnumber" | translate)
                : ("cart.cart-components.invoice.select" | translate)
            }}</ion-button
          >
        </ion-col>

        <ion-col
          size="12"
          *ngIf="
            (invoice && !invoice.vatNumber) ||
            !invoice.vatOffice ||
            !invoice.company_name ||
            !invoice.company_address ||
            !invoice.activity
          "
        >
          <ion-text class="categoriesWarningColor">
            {{
              "cart.cart-components.invoice.invoice-list-modal.please-fill-all-data-to-select-invoice"
                | translate
            }}
          </ion-text>
        </ion-col>

        <ion-col
          size="12"
          *ngIf="
            storeInfo &&
            storeInfo.vatBasedCustomerProductsActive &&
            !invoice.migration_customer_id
          "
        >
          <ion-text class="categoriesWarningColor">
            {{
              "cart.cart-components.invoice.invoice-list-modal.customer-code-missing"
                | translate
            }}
          </ion-text>
        </ion-col>
      </ion-row>
    </ion-item>
  </ion-list>
  <ion-item
    lines="none"
    *ngIf="
      currentUser &&
      (!currentUser.invoiceData ||
        (currentUser &&
          currentUser.invoiceData &&
          currentUser.invoiceData.length === 0))
    "
  >
    <ion-label class="ion-text-center">
      {{ "cart.cart-components.invoice.no-invoice-data" | translate }}
    </ion-label>
  </ion-item>
</ion-content>

<ion-footer>
  <ion-row *ngIf="storeInfo">
    <ion-col class="ion-text-center">
      <ion-button
        [hidden]="
          (storeInfo.enable_unique_invoice &&
            currentUser.invoiceData &&
            currentUser.invoiceData.length > 0) ||
          storeInfo.vatBasedCustomerProductsActive
        "
        [disabled]="loading"
        (click)="presentAddEditModal('add', null)"
      >
        <ion-text *ngIf="!loading">
          {{
            "cart.cart-components.invoice.invoice-list-modal.new-invoice-data"
              | translate
          }}
        </ion-text>
        <ion-spinner *ngIf="loading"> </ion-spinner
      ></ion-button>
    </ion-col>
  </ion-row>
</ion-footer>
