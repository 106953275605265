<ion-item
  *ngIf="
    groupApp &&
    (!groupApp.design_settings ||
      !groupApp.design_settings.sideMenuHeaderLogo ||
      !headerLogo)
  "
  lines="none"
>
  <ion-thumbnail
    slot="start"
    *ngIf="storeImage"
    [ngClass]="{
      flattenedIconBackground: flattenedIconBackgroundFlag
    }"
  >
    <ion-img
      src="https://images.deliverymanager.gr/{{ groupApp.group }}/appicon/{{
        storeImage
      }}"
    ></ion-img>
  </ion-thumbnail>
  <ion-label
    *ngIf="
      groupApp &&
      (!groupApp.design_settings ||
        !groupApp.design_settings.hideHeaderStoreName) &&
      groupApp.app_name
    "
    class="ion-text-wrap"
    style="font-size: large; font-weight: bold"
    >{{ groupApp.app_name }}</ion-label
  >
</ion-item>

<div
  style="padding-inline-start: 10px; padding-inline-end: 10px"
  [ngClass]="{
    headerLogoIconBackground: groupApp.headerLogoIconBackground,
    flattenedIconBackgroundHeaderLogo:
      flattenedIconBackgroundFlag && !groupApp.headerLogoIconBackground,
    headerLogoLightImg: true,
    divHeaderLogoBig: biggerImageLogo ? true : false
  }"
  *ngIf="
    groupApp &&
    groupApp.design_settings &&
    groupApp.design_settings.sideMenuHeaderLogo &&
    headerLogo &&
    !darkHeaderLogo
  "
  lines="none"
>
  <ion-img
    [class]="biggerImageLogo ? 'headerLogoLightImgBig' : 'headerLogoLightImg'"
    *ngIf="headerLogo"
    src="https://images.deliverymanager.gr/{{ groupApp.group }}/header/{{
      headerLogo
    }}"
  ></ion-img>
</div>

<div
  [ngClass]="{
    headerLogoIconBackground: groupApp.headerLogoIconBackground,
    flattenedIconBackgroundHeaderLogo:
      flattenedIconBackgroundFlag && !groupApp.headerLogoIconBackground,
    headerLogoLightImg: true,
    divHeaderLogoBig: biggerImageLogo ? true : false
  }"
  style="padding-inline-start: 10px; padding-inline-end: 10px"
  [class]="biggerImageLogo ? 'headerLogoLightImgBig' : 'headerLogoLightImg'"
  *ngIf="
    groupApp &&
    groupApp.design_settings &&
    groupApp.design_settings.sideMenuHeaderLogo &&
    headerLogo &&
    darkHeaderLogo &&
    selectedTheme &&
    selectedTheme === 'light'
  "
  lines="none"
>
  <ion-img
    *ngIf="headerLogo"
    [class]="biggerImageLogo ? 'headerLogoLightImgBig' : 'headerLogoLightImg'"
    src="https://images.deliverymanager.gr/{{ groupApp.group }}/header/{{
      headerLogo
    }}"
  ></ion-img>
</div>

<div
  style="padding-inline-start: 10px; padding-inline-end: 10px"
  [ngClass]="{
    headerLogoDarkImg: true,
    divHeaderLogoBig: biggerImageLogo ? true : false
  }"
  *ngIf="
    groupApp &&
    groupApp.design_settings &&
    groupApp.design_settings.sideMenuHeaderLogo &&
    headerLogo &&
    darkHeaderLogo &&
    selectedTheme &&
    selectedTheme === 'dark'
  "
>
  <ion-img
    *ngIf="darkHeaderLogo"
    [class]="biggerImageLogo ? 'headerLogoDarkImgBig' : 'headerLogoDarkImg'"
    src="https://images.deliverymanager.gr/{{ groupApp.group }}/header/{{
      darkHeaderLogo
    }}"
  ></ion-img>
</div>
