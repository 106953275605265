<div #divContainerElement *ngIf="description" style="width: 100%">
  <span
    *ngIf="
      !show_option_description ||
      (show_option_description && showOptionInDescriptionJoined)
    "
    #descriptionElement
    [class]="
      !three_lines_description
        ? imageBrowser
          ? !fullDescription
            ? 'descriptionWrapBrowser'
            : ''
          : !fullDescription
          ? 'descriptionWrap'
          : ''
        : fullDescription
        ? ''
        : 'categoriesDescriptionWrap'
    "
    [ngStyle]="{ 'font-size': fontSize, width: '100%', display: 'block' }"
    [innerHTML]="description"
  ></span>
  <ion-button
    style="position: absolute; top: 23px; right: 0"
    *ngIf="
      !noReadMoreButton &&
      displayReadMoreButton &&
      description &&
      !imageBrowser &&
      !three_lines_description
    "
    (click)="showFullDescription(); $event.stopImmediatePropagation()"
    fill="clear"
    size="small"
    color="dark"
    ><ion-text style="font-size: x-small">{{
      "catalog.description.read-more" | translate
    }}</ion-text>
  </ion-button>

  <ion-button
    style="position: absolute; top: 55px; right: 0"
    *ngIf="
      !noReadMoreButton &&
      displayReadMoreButton &&
      description &&
      imageBrowser &&
      !three_lines_description
    "
    (click)="showFullDescription(); $event.stopImmediatePropagation()"
    fill="clear"
    size="small"
    color="dark"
    ><ion-text style="font-size: x-small">{{
      "catalog.description.read-more" | translate
    }}</ion-text>
  </ion-button>

  <ion-button
    style="position: absolute; top: 39px; right: 0"
    *ngIf="
      !noReadMoreButton &&
      displayReadMoreButton &&
      description &&
      !imageBrowser &&
      three_lines_description
    "
    (click)="showFullDescription(); $event.stopImmediatePropagation()"
    fill="clear"
    size="small"
    color="dark"
    ><ion-text style="font-size: x-small">{{
      "catalog.description.read-more" | translate
    }}</ion-text>
  </ion-button>
</div>

<!-- <div
  class="ion-text-wrap ion-no-padding ion-no-margin"
  *ngIf="description && displayedDescription"
>
  <span
    #displayedDescriptionElem
    [ngStyle]="{ 'font-size': fontSize, width: '100%', display: 'block' }"
    [innerHTML]="displayedDescription"
  ></span>
</div>
<!-- <div *ngIf="description" style="width: 100%">
  <span #displayedDescriptionElem
    ><div
      class="ion-text-wrap"
      style="
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-height: 16px;
        max-height: 32px;
        -webkit-box-orient: vertical;
      "
      [ngStyle]="{ 'font-size': fontSize, width: '100%%', display: 'block' }"
      [innerHTML]="description"
    ></div
  ></span>
  <ion-button
    style="position: absolute; top: 17px; right: 0"
    (click)="showFullDescription(); $event.stopImmediatePropagation()"
    fill="clear"
    size="small"
    color="dark"
    ><ion-text style="font-size: x-small">{{
      "catalog.description.read-more" | translate
    }}</ion-text>
  </ion-button>
</div> -->
