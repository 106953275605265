<ion-content [scrollEvents]="false" scrollX="false" scrollY="false">
  <ion-grid style="height: 100%; max-width: 600px">
    <ion-row
      style="width: 100%; height: 100%"
      class="ion-align-items-center ion-justify-content-center"
    >
      <ion-col size="12" class="ion-align-self-center">
        <ion-grid
          [class]="
            mobile &&
            onlyOrderAppSuspendedMessageTranslations && selectedLanguage
              ? 'mobile-grid'
              : 'browser-grid'
          "
        >
          <ng-lottie
            *ngIf="
              !onlyOrderAppSuspendedMessageTranslations || !selectedLanguage
            "
            (animationCreated)="animationCreated($event)"
            [options]="options"
          ></ng-lottie>
          <ng-lottie
            *ngIf="onlyOrderAppSuspendedMessageTranslations && selectedLanguage"
            (animationCreated)="animationCreated($event)"
            [options]="optionsInfoAnimation"
          ></ng-lottie>
        </ion-grid>
        <ion-card color="primary" lines="none">
          <ion-item *ngIf="groupApp" lines="none" color="primary">
            <ion-label class="ion-text-center ion-text-wrap"
              ><ion-text
                *ngIf="
                  !onlyOrderAppSuspendedMessageTranslations || !selectedLanguage
                "
                style="font-size: medium; font-weight: bold"
              >
                {{
                  "app-is-deactivated-please-contact-store" | translate
                }} </ion-text
              ><ion-text
                *ngIf="
                  onlyOrderAppSuspendedMessageTranslations && selectedLanguage
                "
                style="font-size: medium; font-weight: bold"
              >
                {{
                  onlyOrderAppSuspendedMessageTranslations[selectedLanguage]
                    ? onlyOrderAppSuspendedMessageTranslations[selectedLanguage]
                    : onlyOrderAppSuspendedMessageTranslations[base_language]
                }}
              </ion-text></ion-label
            >
          </ion-item>
        </ion-card>

        <ion-grid>
          <ion-row style="width: 100%">
            <ion-col
              [size]="
                onlyOrderAppSuspendedButtons &&
                onlyOrderAppSuspendedButtons.button_one &&
                onlyOrderAppSuspendedButtons.button_one.active &&
                onlyOrderAppSuspendedButtons.button_one.translations &&
                onlyOrderAppSuspendedButtons &&
                onlyOrderAppSuspendedButtons.button_two &&
                onlyOrderAppSuspendedButtons.button_two.active &&
                onlyOrderAppSuspendedButtons.button_two.translations
                  ? '6'
                  : '12'
              "
              *ngIf="
                onlyOrderAppSuspendedButtons &&
                onlyOrderAppSuspendedButtons.button_one &&
                onlyOrderAppSuspendedButtons.button_one.active &&
                onlyOrderAppSuspendedButtons.button_one.translations
              "
              class="ion-text-center"
            >
              <ion-button
                style="font-weight: bold"
                mode="ios"
                [size]="mobile ? 'small' : ''"
                (click)="
                  onlyOrderAppSuspendedButtons.button_one.link
                    ? openLink(onlyOrderAppSuspendedButtons.button_one.link)
                    : ''
                "
                >{{
                  onlyOrderAppSuspendedButtons.button_one.translations[
                    selectedLanguage
                  ]
                    ? onlyOrderAppSuspendedButtons.button_one.translations[
                        selectedLanguage
                      ]
                    : onlyOrderAppSuspendedButtons.button_one.translations[
                        base_language
                      ]
                }}</ion-button
              >
            </ion-col>
            <ion-col
              [size]="
                onlyOrderAppSuspendedButtons &&
                onlyOrderAppSuspendedButtons.button_one &&
                onlyOrderAppSuspendedButtons.button_one.active &&
                onlyOrderAppSuspendedButtons.button_one.translations &&
                onlyOrderAppSuspendedButtons &&
                onlyOrderAppSuspendedButtons.button_two &&
                onlyOrderAppSuspendedButtons.button_two.active &&
                onlyOrderAppSuspendedButtons.button_two.translations
                  ? '6'
                  : '12'
              "
              *ngIf="
                onlyOrderAppSuspendedButtons &&
                onlyOrderAppSuspendedButtons.button_two &&
                onlyOrderAppSuspendedButtons.button_two.active &&
                onlyOrderAppSuspendedButtons.button_two.translations
              "
              class="ion-text-center"
            >
              <ion-button
                style="font-weight: bold"
                mode="ios"
                [size]="mobile ? 'small' : ''"
                (click)="
                  onlyOrderAppSuspendedButtons.button_two.link
                    ? openLink(onlyOrderAppSuspendedButtons.button_two.link)
                    : ''
                "
                >{{
                  onlyOrderAppSuspendedButtons.button_two.translations[
                    selectedLanguage
                  ]
                    ? onlyOrderAppSuspendedButtons.button_two.translations[
                        selectedLanguage
                      ]
                    : onlyOrderAppSuspendedButtons.button_two.translations[
                        base_language
                      ]
                }}</ion-button
              >
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
