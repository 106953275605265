<ion-header>
  <app-no-internet-connection></app-no-internet-connection>
  <app-ionic-update-error></app-ionic-update-error>
  <ion-toolbar>
    <ion-buttons *ngIf="noLoyaltyApp" slot="start">
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>

    <ion-title
      >{{'loyaltySystem.loyalty-contests.loyalty-contests' |
      translate}}</ion-title
    >
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="groupApp">
  <ion-grid *ngIf="noLoyaltyApp && (!groupContests || !loginState)">
    <ion-row style="width: 100%">
      <ion-col class="ion-text-center">
        <ion-spinner></ion-spinner>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid style="max-width: 650px">
    <ion-row *ngIf="groupContests && groupContests.length === 0">
      <ion-col class="ion-text-center">
        {{"loyaltySystem.no-contests"}}
      </ion-col>
    </ion-row>
    <ion-row *ngIf="groupContests">
      <ion-col>
        <app-loyalty-contest-item
          *ngFor="let contest of groupContests; trackBy: trackFnc"
          [contest]="contest"
          [group]="groupApp.group"
          [activeContestDrawsSum]="activeContestDrawsSum"
          [currentUser]="currentUser"
        >
        </app-loyalty-contest-item>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
