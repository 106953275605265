import { Action } from "@ngrx/store";

export const SET_PRODUCT_OPTION_VALIDATION =
  "[ PRODUCT OPTION VALIDATION] Set  Product Option Validation";
export const SET_OPEN_PRODUCT_MODAL_BUNDLE_CHOICE =
  "[ PRODUCT OPTION VALIDATION] Set Open Product Modal Bundle Choice";
export const ADD_UPDATE_PRODUCT_OPTION_VALIDATION =
  "[ PRODUCT OPTION VALIDATION] Add Update  Product Option Validation";
export const CLEAR_PRODUCT_OPTIONS_VALIDATION =
  "[ PRODUCT OPTION VALIDATION] Clear  Product Options Validation";
export const ADD_BUNDLE_OPTION_LOADING =
  "[ PRODUCT OPTION VALIDATION] Add Bundle Option Loading";
export const REMOVE_BUNDLE_OPTION_LOADING =
  "[ PRODUCT OPTION VALIDATION] Remove Bundle Option Loading";

export class SetProductOptionValidation implements Action {
  readonly type = SET_PRODUCT_OPTION_VALIDATION;
  constructor(
    public payload: {
      selectedProductId: any;
      lastValidTimestamp: any;
      optionValidations: any;
    }
  ) {}
}
export class SetOpenProductModalBundleChoice implements Action {
  readonly type = SET_OPEN_PRODUCT_MODAL_BUNDLE_CHOICE;
  constructor(public payload: { product: any; choice_id: any }) {}
}

export class AddUpdateProductOptionValidation implements Action {
  readonly type = ADD_UPDATE_PRODUCT_OPTION_VALIDATION;
  constructor(
    public payload: { selectedProductId: any; validationObject: any }
  ) {}
}

export class ClearProductOptionsValidation implements Action {
  readonly type = CLEAR_PRODUCT_OPTIONS_VALIDATION;
  constructor(public payload: any) {}
}

export class AddBundleOptionLoading implements Action {
  readonly type = ADD_BUNDLE_OPTION_LOADING;
  constructor(public payload: any) {}
}

export class RemoveBundleOptionLoading implements Action {
  readonly type = REMOVE_BUNDLE_OPTION_LOADING;
  constructor(public payload: any) {}
}
export type ProductOptionsValidationActions =
  | SetProductOptionValidation
  | SetOpenProductModalBundleChoice
  | AddUpdateProductOptionValidation
  | ClearProductOptionsValidation
  | AddBundleOptionLoading
  | RemoveBundleOptionLoading;
