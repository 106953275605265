import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import {
  auditTime,
  catchError,
  distinctUntilChanged,
  first,
} from "rxjs/operators";
import * as _ from "lodash";
import * as fromApp from "../store/app.reducer";
import * as selectors from "../store/app.selectors";
import { Store } from "@ngrx/store";
import { DataStorageService } from "../services/data-storage/data-storage.service";
import { TranslateService } from "@ngx-translate/core";
import { AlertController, Platform, ToastController } from "@ionic/angular";
import * as CustomerCouponsActions from "../discount-coupons/store/coupons.actions";
import { Router } from "@angular/router";
import { AnalyticsServiceService } from "../services/analitycsService/analytics-service.service";
import * as CouponsActions from "../discount-coupons/store/coupons.actions";
import { isMoment } from "moment-timezone";
import moment from "moment";
import * as reservationActions from "./store/reservations.actions";
import { AnimationOptions } from "ngx-lottie";
import { AnimationItem } from "lottie-web";

@Component({
  selector: "app-reservations",
  templateUrl: "./reservations.page.html",
  styleUrls: ["./reservations.page.scss"],
})
export class ReservationsPage {
  public reservations;

  public loading = false;
  public currentUser: any;
  public mo_token: any;
  public loginLoading: any;
  public windowInfo: any;
  public menuMinWidthToShow: any;
  public storeInfo: any;
  public stores: any;
  public groupApp: any;
  public windowDimensions: any;
  public currentDayName = moment().format("dddd").toLowerCase();
  private errorToast: any;
  private subscriptions: Subscription[] = [];
  private unsubscribeBackEvent: Subscription;
  public selectedLanguage;
  public animationDeliveryManagerInstance: any;
  constructor(
    private store: Store<fromApp.AppState>,
    private dataStorageService: DataStorageService,
    private translateService: TranslateService,
    private toastController: ToastController,
    private router: Router,
    private analyticsService: AnalyticsServiceService,
    private alertController: AlertController,
    private platform: Platform,
    private changeDetector: ChangeDetectorRef
  ) {}

  AnimationNoReservationsOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/dinning-table.json",
  };

  ionViewDidEnter() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      100,
      () => {
        this.navigateToHome();
      }
    );

    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.windowDimensions &&
            !_.isEqual(this.windowDimensions, state.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(state.groupApp, this.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);

            this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.mo_token &&
            !_.isEqual(this.mo_token, state.mo_token)
          ) {
            this.mo_token = _.cloneDeep(state.mo_token);
          }
          if (state && state.user && !_.isEqual(this.currentUser, state.user)) {
            this.currentUser = _.cloneDeep(state.user);

            this.subscriptions.push(
              this.store
                .select(selectors.getStoreInfo)
                .pipe(distinctUntilChanged())
                .subscribe((storeInfo) => {
                  if (storeInfo && !_.isEqual(this.storeInfo, storeInfo)) {
                    this.storeInfo = _.cloneDeep(storeInfo);
                    if (!this.loading && !this.reservations) {
                      this.getUserReservations();
                    }
                  }
                })
            );
          }
          // if (
          //   state &&
          //   state.loginLoading &&
          //   !_.isEqual(this.loginLoading, state.loginLoading)
          // ) {
          //   this.loginLoading = _.cloneDeep(state.loginLoading);
          //   if (this.loginLoading === "not-loading" && _.isEmpty(state.user)) {
          //     this.presentErrorToast(
          //       this.translateService.instant(
          //         "discount-coupons.you-must-be-logged-in-to-see-your-coupons"
          //       ),
          //       "danger"
          //     );
          //     this.router.navigateByUrl("/homepage", {
          //       replaceUrl: true,
          //     });
          //   }
          // }
        })
    );

    this.subscriptions.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && state.stores && !_.isEqual(this.stores, state.stores)) {
            this.stores = _.cloneDeep(state.stores);
            // if (this.stores && this.stores.length && this.stores.length === 1) {
            //   this.couponsSchedule = this.formatStoreSchedule(
            //     this.stores[0].couponsSchedule
            //   );
            //   console.log("coupon schedule", this.couponsSchedule);
            //   if (!_.isEmpty(this.stores[0].couponsSchedule)) {
            //     console.log("couponsschedule", this.stores[0].couponsSchedule);
            //     this.initializeActions(
            //       _.cloneDeep(this.stores[0].couponsSchedule),
            //       "couponsSchedule"
            //     );
            //   }
            //   if (!_.isEmpty(this.stores[0].couponsExceptionDays)) {
            //     this.initializeActions(
            //       _.cloneDeep(this.stores[0].couponsExceptionDays),
            //       "couponsExceptionDays"
            //     );
            //   }

            //   console.log("coupons schedule", this.couponsSchedule);
            // }
            // if (this.stores)
            //   this.couponFieldActiveInStore = _.find(this.stores, {
            //     couponFieldActive: true,
            //   });
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.windowDimensions &&
            !_.isEqual(this.windowInfo, state.windowDimensions)
          ) {
            this.windowInfo = _.cloneDeep(state.windowDimensions);
            this.menuMinWidthToShow = getComputedStyle(
              document.documentElement
            ).getPropertyValue("--menuMinWidthToShow");
            if (this.menuMinWidthToShow) {
              this.menuMinWidthToShow = parseInt(this.menuMinWidthToShow);
            }
            console.log("menu min with to show", this.menuMinWidthToShow);
          }
          console.log("window info", this.windowInfo);
        })
    );

    this.subscriptions.push(
      this.store.select("reservations").subscribe((state) => {
        if (
          state &&
          state.reservations &&
          !_.isEqual(state.reservations, this.reservations)
        ) {
          this.reservations = _.cloneDeep(state.reservations);
          //this.reservations = [];
        }
      })
    );
  }

  private navigateToHome() {
    if (this.platform.is("android")) {
      this.router.navigateByUrl("/homepage", {
        replaceUrl: true,
      });
    }
  }

  getUserReservations() {
    this.loading = true;
    this.dataStorageService
      .fetchReservations(this.mo_token, this.storeInfo.store_id, null)
      .subscribe({
        next: async (res: any) => {
          this.loading = false;
          if (!res || !res.success) {
            const alert = await this.alertController.create({
              header: this.translateService.instant("alert"),
              message:
                res && res.comment_id
                  ? this.translateService.instant(res.comment_id)
                  : this.translateService.instant(
                      "errorMessages.general_error"
                    ),
              backdropDismiss: false,
              buttons: ["OK"],
            });
            await alert.present();
          } else {
            this.store.dispatch(
              new reservationActions.SetReservations(res.reservations)
            );
          }
        },
        error: async (error) => {
          this.loading = false;
          const alert = await this.alertController.create({
            header: this.translateService.instant("alert"),
            message: this.translateService.instant(
              "errorMessages.problem_reaching_server"
            ),

            buttons: ["OK"],
          });
          await alert.present();
        },
      });
  }

  keyFunc(index, item) {
    return item.res_id;
  }

  addNewReservation() {
    this.router.navigateByUrl("/reservations/new-reservation", {
      replaceUrl: true,
    });
  }

  async presentErrorToast(message, color) {
    this.errorToast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
    });
    this.errorToast.present();
  }

  trackFn(index, item) {
    return item.timestamp;
  }

  animationCreated(ev: AnimationItem) {
    this.animationDeliveryManagerInstance = ev;
  }

  animationLoopCompleted(ev) {
    this.changeDetector.detectChanges();
  }

  topView() {
    this.router.navigateByUrl("/reservations/top-view", {
      replaceUrl: true,
    });
  }

  ionViewDidLeave() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    this.subscriptions = [];
    this.store.dispatch(new reservationActions.ClearReservations());
  }
}
