import { Action } from "@ngrx/store";

export const SET_FAVORITE_PRODUCTS =
  "[FAVORITE PRODUCTS] Set Favorite Products";
export const ADD_FAVORITE_PRODUCT = "[FAVORITE PRODUCTS] ADD Favorite Product";
export const DELETE_FAVORITE_PRODUCT =
  "[FAVORITE PRODUCTS] Delete Favorite Product";
export const CLEAR_CURRENT_STORE_ID =
  "[FAVORITE PRODUCTS] Clear Favorite Products";

export class SetFavoriteProducts implements Action {
  readonly type = SET_FAVORITE_PRODUCTS;
  constructor(public payload: any) {}
}

export class AddFavoriteProduct implements Action {
  readonly type = ADD_FAVORITE_PRODUCT;
  constructor(public payload: any) {}
}

export class DeleteFavoriteProduct implements Action {
  readonly type = DELETE_FAVORITE_PRODUCT;
  constructor(public payload: any) {}
}
export class ClearFavoriteProducts implements Action {
  readonly type = CLEAR_CURRENT_STORE_ID;
  constructor() {}
}

export type FavoriteProductsActions =
  | SetFavoriteProducts
  | AddFavoriteProduct
  | DeleteFavoriteProduct
  | ClearFavoriteProducts;
