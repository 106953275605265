<ion-item
  *ngIf="product"
  lines="none"
  detail="false"
  button
  (click)="addProductToCart(product)"
  [class]="this.productInCart ? 'added_to_cart_border' : ''"
  [disabled]="finalPriceLoading"
>
  <!-- <div
    style="
      position: absolute;
      top: 0;
      left: 0px;
      z-index: 10000;
      font-size: small;
      font-weight: bold;
    "
    *ngIf="productInCartCounter && productInCartCounter > 1"
  >
    <ion-text color="primary"> x{{ productInCartCounter }}</ion-text>
  </div> -->
  <ion-row
    class="ion-padding-bottom ion-padding-top ion-no-padding-left ion-no-padding-right"
  >
    <ion-col
      class="ion-no-padding ion-no-margin"
      [ngClass]="{
        'unavailable categoriesBrowserItemUnavailable': !!(
          !product.availableTotal ||
          !product.product_categories_availability_check
        ),
        categoriesBrowserItemAvailable: product.availableTotal
      }"
      size="12"
      style="line-height: initial"
    >
      <span
        style="font-weight: 700; font-size: 0.9em"
        [ngClass]="{
          'line-through': !!(
            !product.availableTotal ||
            !product.product_categories_availability_check
          )
        }"
      >
        {{ product.name }}
      </span>
    </ion-col>

    <ion-col
      class="ion-no-padding ion-no-margin descriptionPaddings"
      size="12"
      *ngIf="product.description || product.showOptionInDescription"
      style="line-height: initial"
    >
      <span
        [ngClass]="{
          'unavailable line-through ': !!(
            !product.availableTotal ||
            !product.product_categories_availability_check
          )
        }"
        class="fontSizeSmaller"
      >
        <app-description
          *ngIf="
            (orderPickup == null && dineIn == null) ||
            (orderPickup == false && dineIn == false) ||
            (orderPickup == true &&
              dineIn == false &&
              !product.showOptionInDescription_takeaway) ||
            (orderPickup == false &&
              dineIn == true &&
              !product.showOptionInDescription_dinein)
          "
          [show_option_description]="product.showOptionInDescription"
          [description]="product.description"
          [three_lines_description]="
            (!product.tags ||
              (!product.tags.new &&
                !product.tags.veg &&
                !product.tags.fasting &&
                !product.tags.gloutenfree &&
                !product.tags.spicy &&
                !product.tags.lactosefree &&
                !product.tags.frozen &&
                !product.tags.deal &&
                !product.tags.popular &&
                !product.tags.avpc &&
                !product.tags.frozen &&
                !product.tags.vegan &&
                !product.tags.custom_tag_1 &&
                !product.tags.custom_tag_2 &&
                !product.tags.custom_tag_3 &&
                !product.tags.custom_tag_4 &&
                !product.tags.custom_tag_5 &&
                !product.tags.custom_tag_6 &&
                !product.tags.custom_tag_7 &&
                !product.tags.custom_tag_8 &&
                !product.tags.custom_tag_9 &&
                !product.tags.custom_tag_10)) &&
            !(
              product.category &&
              product.product_categories_availability_check &&
              product.availableTotal &&
              product.activeTotal &&
              !(product.pickup ? (orderPickup ? true : false) : true) &&
              !product.delivery &&
              !product.dinein &&
              !(
                !product.pointsSystemScheduleActive &&
                groupStoreData &&
                groupStoreData.groupApp &&
                groupStoreData.groupApp.pointsSystem &&
                groupStoreData &&
                groupStoreData.store_info &&
                groupStoreData.store_info.overridePromoItemsForPointsSystem &&
                !product.pointsSystemException &&
                product.pointsSystemWeight &&
                product.pointsSystemWeight > 1
              )
            )
              ? true
              : false
          "
        >
        </app-description>

        <app-description
          *ngIf="
            orderPickup == true &&
            dineIn == false &&
            product.showOptionInDescription_takeaway
          "
          [show_option_description]="product.showOptionInDescription_takeaway"
          [description]="product.description"
          [three_lines_description]="
            (!product.tags ||
              (!product.tags.new &&
                !product.tags.veg &&
                !product.tags.fasting &&
                !product.tags.gloutenfree &&
                !product.tags.spicy &&
                !product.tags.lactosefree &&
                !product.tags.frozen &&
                !product.tags.deal &&
                !product.tags.popular &&
                !product.tags.avpc &&
                !product.tags.frozen &&
                !product.tags.vegan &&
                !product.tags.custom_tag_1 &&
                !product.tags.custom_tag_2 &&
                !product.tags.custom_tag_3 &&
                !product.tags.custom_tag_4 &&
                !product.tags.custom_tag_5 &&
                !product.tags.custom_tag_6 &&
                !product.tags.custom_tag_7 &&
                !product.tags.custom_tag_8 &&
                !product.tags.custom_tag_9 &&
                !product.tags.custom_tag_10)) &&
            !(
              product.category &&
              product.product_categories_availability_check &&
              product.availableTotal &&
              product.activeTotal &&
              !(product.pickup ? (orderPickup ? true : false) : true) &&
              !product.delivery &&
              !product.dinein &&
              !(
                !product.pointsSystemScheduleActive &&
                groupStoreData &&
                groupStoreData.groupApp &&
                groupStoreData.groupApp.pointsSystem &&
                groupStoreData &&
                groupStoreData.store_info &&
                groupStoreData.store_info.overridePromoItemsForPointsSystem &&
                !product.pointsSystemException &&
                product.pointsSystemWeight &&
                product.pointsSystemWeight > 1
              )
            )
              ? true
              : false
          "
        >
        </app-description>

        <app-description
          *ngIf="
            orderPickup == false &&
            dineIn == true &&
            product.showOptionInDescription_dinein
          "
          [show_option_description]="product.showOptionInDescription_dinein"
          [description]="product.description"
          [three_lines_description]="
            (!product.tags ||
              (!product.tags.new &&
                !product.tags.veg &&
                !product.tags.fasting &&
                !product.tags.gloutenfree &&
                !product.tags.spicy &&
                !product.tags.lactosefree &&
                !product.tags.frozen &&
                !product.tags.deal &&
                !product.tags.popular &&
                !product.tags.avpc &&
                !product.tags.frozen &&
                !product.tags.vegan &&
                !product.tags.custom_tag_1 &&
                !product.tags.custom_tag_2 &&
                !product.tags.custom_tag_3 &&
                !product.tags.custom_tag_4 &&
                !product.tags.custom_tag_5 &&
                !product.tags.custom_tag_6 &&
                !product.tags.custom_tag_7 &&
                !product.tags.custom_tag_8 &&
                !product.tags.custom_tag_9 &&
                !product.tags.custom_tag_10)) &&
            !(
              product.category &&
              product.product_categories_availability_check &&
              product.availableTotal &&
              product.activeTotal &&
              !(product.pickup ? (orderPickup ? true : false) : true) &&
              !product.delivery &&
              !product.dinein &&
              !(
                !product.pointsSystemScheduleActive &&
                groupStoreData &&
                groupStoreData.groupApp &&
                groupStoreData.groupApp.pointsSystem &&
                groupStoreData &&
                groupStoreData.store_info &&
                groupStoreData.store_info.overridePromoItemsForPointsSystem &&
                !product.pointsSystemException &&
                product.pointsSystemWeight &&
                product.pointsSystemWeight > 1
              )
            )
              ? true
              : false
          "
        >
        </app-description>
      </span>
    </ion-col>

    <app-product-show-option-in-description-object
      [product]="product"
      [dineIn]="dineIn"
      [orderPickup]="orderPickup"
      [store_info]="groupStoreData.store_info"
      [selectedDineInTable]="selectedDineInTable"
    ></app-product-show-option-in-description-object>

    <!-- <ion-col
      class="ion-no-padding ion-no-margin"
      size="12"
      *ngIf="product.showOptionInDescription"
      class="fontSizeSmaller"
      [ngClass]="{
        'line-through unavailable': !!(
          !product.availableTotal || !product.product_categories_availability_check
        )
      }"
    >
      <p
        *ngFor="
          let description of product.showOptionInDescription;
          let i = index;
          trackBy: trackFn
        "
      >
        {{ description }}
      </p>
    </ion-col> -->
    <ion-col
      class="ion-no-padding ion-no-margin"
      *ngIf="product.promotions && product.promotions.length > 0"
      [ngClass]="{
        'line-through unavailable': !!(
          !product.availableTotal ||
          !product.product_categories_availability_check
        )
      }"
      class="categoriesWarningColor"
      style="font-weight: bold"
    >
      <p
        *ngFor="
          let promo of product.promotions;
          let i = index;
          trackBy: trackFn
        "
      >
        {{ promo.description }}
      </p>
    </ion-col>

    <ion-col
      size="12"
      class="ion-no-padding ion-no-margin"
      *ngIf="
        (product &&
          !!(
            !product.availableTotal ||
            !product.product_categories_availability_check
          )) ||
        product.avpc ||
        product.promo ||
        product.tags ||
        product.delivery ||
        product.pickup ||
        product.dinein ||
        (product.availableTotal &&
          product.availability_stock_enabled &&
          product.availability_stock) ||
        (product.preorder_availability_stock_enabled &&
          product.preorder_availability_stock) ||
        (product &&
          groupStoreData &&
          groupStoreData.groupApp &&
          groupStoreData.groupApp.pointsSystem &&
          groupStoreData.store_info &&
          groupStoreData.store_info.overridePromoItemsForPointsSystem &&
          !product.pointsSystemScheduleActive &&
          !product.pointsSystemException &&
          product.pointsSystemWeight &&
          product.pointsSystemWeight > 1)
      "
    >
      <app-product-item-tags
        [productItem]="product"
        [groupApp]="
          groupStoreData && groupStoreData.groupApp
            ? groupStoreData.groupApp
            : null
        "
        [store_info]="
          groupStoreData && groupStoreData.store_info
            ? groupStoreData.store_info
            : null
        "
        [selectedPreorderDate]="selectedPreorderDate"
        [orderPickup]="orderPickup"
        [dineIn]="dineIn"
      >
      </app-product-item-tags>
    </ion-col>
  </ion-row>
  <!-- <ion-thumbnail
    *ngIf="product && product.squareImageDesign"
    slot="end"
    [class]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.mobileorder_show_img_borders
        ? 'product-image-border squareIonThumbnail'
        : 'squareIonThumbnail'
    "
    style="
      background: url({{imageUrl}}) no-repeat center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      background-size: cover;
      z-index: 1;
    "
  >
    <!-- <img
      [class]="
        groupStoreData &&
        groupStoreData.store_info &&
        groupStoreData.store_info.mobileorder_show_img_borders
          ? 'product-image-border'
          : ''
      "
      *ngIf="
        product &&
        product.image &&
        product.image.true &&
        product.image.true['4x3'] &&
        product.image.true['4x3'].path &&
        groupStoreData &&
        groupStoreData.groupApp &&
        (!groupStoreData.groupApp.disableCatalogPhoto ||
          groupStoreData.groupApp.disableCatalogPhoto)
      "
      loading="lazy"
      style="border-radius: 10px; width: 100%; height: 100%; object-fit: cover"
      [alt]="product.name"
      [src]="
        'https://images.deliverymanager.gr/' +
        product.image.true['4x3'].path +
        (groupStoreData.webp
          ? '.webp'
          : product.image.true['4x3'].type || '.jpg')
      "
    />
  </ion-thumbnail>
  <ion-thumbnail
    [class]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.mobileorder_show_img_borders
        ? 'standardIonThumbnail product-image-border-radius'
        : 'standardIonThumbnail'
    "
    slot="end"
    *ngIf="!(product && product.squareImageDesign)"
  >
    <img
      *ngIf="
        product &&
        product.image &&
        product.image.true &&
        product.image.true['4x3'] &&
        product.image.true['4x3'].path &&
        groupStoreData &&
        groupStoreData.groupApp &&
        (!groupStoreData.groupApp.disableCatalogPhoto ||
          groupStoreData.groupApp.disableCatalogPhoto)
      "
      loading="lazy"
      style="border-radius: 10px"
      [alt]="product.name"
      [src]="
        'https://images.deliverymanager.gr/' +
        product.image.true['4x3'].path +
        (groupStoreData.webp
          ? '.webp'
          : product.image.true['4x3'].type || '.jpg')
      "
    />
  </ion-thumbnail> -->

  <div
    *ngIf="
      (orderPickup == false && dineIn == false) ||
      (orderPickup == null && dineIn == null)
    "
    style="padding-left: 0px"
    class="pricePaddings"
    slot="end"
    [ngClass]="{
      priceWidthUnavailable: !!product.priceWidthUnavailable,
      priceWidthAvailable: !!product.priceWidthAvailable
    }"
  >
    <span
      style="padding-right: 3px; font-size: smaller"
      *ngIf="product.initial_price"
    >
      <span class="line-through">
        {{ product.initial_price
        }}{{
          product.enable_quantity_step &&
          product.quantity_step &&
          product.quantity_step_kind
            ? "/" + product.quantity_step_kind
            : ""
        }}
      </span>
    </span>
    <span
      [ngClass]="{
        'lowerFromTop unavailable line-through': !!(
          !product.availableTotal ||
          !product.product_categories_availability_check
        )
      }"
    >
      <ion-text
        *ngIf="product.price"
        color="primary"
        style="font-weight: 700; vertical-align: middle"
        >{{ product.price
        }}{{
          product.enable_quantity_step &&
          product.quantity_step &&
          product.quantity_step_kind
            ? "/" + product.quantity_step_kind
            : ""
        }}
      </ion-text>

      <ion-text *ngIf="product.price && product.points_to_earn">
        <span> | </span>
        <span class="points-to-earn">
          {{ product.points_to_earn }}
          {{ "points-translation" | translate }}
        </span>
      </ion-text>

      <ion-text
        *ngIf="!product.price && product.virtual_price"
        color="primary"
        style="font-weight: 700"
        >{{ product.virtual_price
        }}{{
          product.enable_quantity_step &&
          product.quantity_step &&
          product.quantity_step_kind
            ? "/" + product.quantity_step_kind
            : ""
        }}</ion-text
      >
    </span>
    <div
      style="display: flex; align-items: center; vertical-align: middle"
      *ngIf="product.service_duration_min"
    >
      <ion-icon
        slot="end"
        style="font-size: 17px; margin-right: 3px"
        color="primary"
        name="time-outline"
      >
      </ion-icon>
      <ion-text style="font-size: small; font-weight: bold">
        {{ product.service_duration_min }}'
      </ion-text>
    </div>
  </div>

  <div
    *ngIf="orderPickup == true && dineIn == false"
    style="padding-left: 0px"
    class="pricePaddings"
    slot="end"
    [ngClass]="{
      priceWidthUnavailable: !!product.priceWidthUnavailable,
      priceWidthAvailable: !!product.priceWidthAvailable
    }"
  >
    <span
      style="padding-right: 3px; font-size: smaller"
      *ngIf="product.initial_price && !product.price_takeaway"
    >
      <span class="line-through">
        {{ product.initial_price
        }}{{
          product.enable_quantity_step &&
          product.quantity_step &&
          product.quantity_step_kind
            ? "/" + product.quantity_step_kind
            : ""
        }}
      </span>
    </span>
    <span
      style="padding-right: 3px; font-size: smaller"
      *ngIf="product.initial_price_takeaway && product.price_takeaway"
    >
      <span class="line-through">
        {{ product.initial_price_takeaway
        }}{{
          product.enable_quantity_step &&
          product.quantity_step &&
          product.quantity_step_kind
            ? "/" + product.quantity_step_kind
            : ""
        }}
      </span>
    </span>
    <span
      [ngClass]="{
        'lowerFromTop unavailable line-through': !!(
          !product.availableTotal ||
          !product.product_categories_availability_check
        )
      }"
    >
      <ion-text
        *ngIf="product.price_takeaway"
        color="primary"
        style="font-weight: 700"
        >{{ product.price_takeaway
        }}{{
          product.enable_quantity_step &&
          product.quantity_step &&
          product.quantity_step_kind
            ? "/" + product.quantity_step_kind
            : ""
        }}
      </ion-text>

      <ion-text
        *ngIf="product.price_takeaway && product.points_to_earn_takeaway"
      >
        <span> | </span>
        <span class="points-to-earn">
          {{ product.points_to_earn_takeaway }}
          {{ "points-translation" | translate }}
        </span>
      </ion-text>

      <ion-text
        *ngIf="!product.price_takeaway && product.virtual_price_takeaway"
        color="primary"
        style="font-weight: 700"
        >{{ product.virtual_price_takeaway
        }}{{
          product.enable_quantity_step &&
          product.quantity_step &&
          product.quantity_step_kind
            ? "/" + product.quantity_step_kind
            : ""
        }}</ion-text
      >

      <ion-text
        *ngIf="!product.price_takeaway && !product.virtual_price_takeaway"
        color="primary"
        style="font-weight: 700"
        >{{ product.price ? product.price : product.virtual_price
        }}{{
          product.enable_quantity_step &&
          product.quantity_step &&
          product.quantity_step_kind
            ? "/" + product.quantity_step_kind
            : ""
        }}
      </ion-text>

      <ion-text
        *ngIf="
          product.points_to_earn &&
          product.price &&
          !product.price_takeaway &&
          !product.virtual_price_takeaway
        "
      >
        <span> | </span>
        <span class="points-to-earn">
          {{ product.points_to_earn }}
          {{ "points-translation" | translate }}
        </span>
      </ion-text>
    </span>
    <div
      style="display: flex; align-items: center; vertical-align: middle"
      *ngIf="product.service_duration_min"
    >
      <ion-icon
        slot="end"
        style="font-size: 17px; margin-right: 3px"
        color="primary"
        name="time-outline"
      >
      </ion-icon>
      <ion-text style="font-size: small; font-weight: bold">
        {{ product.service_duration_min }}'
      </ion-text>
    </div>
  </div>

  <div
    *ngIf="
      orderPickup == false &&
      dineIn == true &&
      groupStoreData &&
      groupStoreData.store_info &&
      (!groupStoreData.store_info.alt_dinein_active ||
        !selectedDineInTable ||
        !selectedDineInTable.alt_dinein)
    "
    style="padding-left: 0px"
    class="pricePaddings"
    slot="end"
    [ngClass]="{
      priceWidthUnavailable: !!product.priceWidthUnavailable,
      priceWidthAvailable: !!product.priceWidthAvailable
    }"
  >
    <span
      style="padding-right: 3px; font-size: smaller"
      *ngIf="product.initial_price && !product.price_dinein"
    >
      <span class="line-through">
        {{ product.initial_price
        }}{{
          product.enable_quantity_step &&
          product.quantity_step &&
          product.quantity_step_kind
            ? "/" + product.quantity_step_kind
            : ""
        }}
      </span>
    </span>
    <span
      style="padding-right: 3px; font-size: smaller"
      *ngIf="product.initial_price_dinein && product.price_dinein"
    >
      <span class="line-through">
        {{ product.initial_price_dinein
        }}{{
          product.enable_quantity_step &&
          product.quantity_step &&
          product.quantity_step_kind
            ? "/" + product.quantity_step_kind
            : ""
        }}
      </span>
    </span>
    <span
      [ngClass]="{
        'lowerFromTop unavailable line-through': !!(
          !product.availableTotal ||
          !product.product_categories_availability_check
        )
      }"
    >
      <ion-text
        *ngIf="product.price_dinein"
        color="primary"
        style="font-weight: 700"
        >{{ product.price_dinein
        }}{{
          product.enable_quantity_step &&
          product.quantity_step &&
          product.quantity_step_kind
            ? "/" + product.quantity_step_kind
            : ""
        }}
      </ion-text>

      <ion-text *ngIf="product.price_dinein && product.points_to_earn_dinein">
        <span> | </span>
        <span class="points-to-earn">
          {{ product.points_to_earn_dinein }}
          {{ "points-translation" | translate }}
        </span>
      </ion-text>

      <ion-text
        *ngIf="!product.price_dinein && product.virtual_price_dinein"
        color="primary"
        style="font-weight: 700"
        >{{ product.virtual_price_dinein
        }}{{
          product.enable_quantity_step &&
          product.quantity_step &&
          product.quantity_step_kind
            ? "/" + product.quantity_step_kind
            : ""
        }}</ion-text
      >

      <ion-text
        *ngIf="!product.price_dinein && !product.virtual_price_dinein"
        color="primary"
        style="font-weight: 700"
        >{{ product.price ? product.price : product.virtual_price
        }}{{
          product.enable_quantity_step &&
          product.quantity_step &&
          product.quantity_step_kind
            ? "/" + product.quantity_step_kind
            : ""
        }}
      </ion-text>

      <ion-text
        *ngIf="
          product.points_to_earn &&
          product.price &&
          !product.price_dinein &&
          !product.virtual_price_dinein
        "
      >
        <span> | </span>
        <span class="points-to-earn">
          {{ product.points_to_earn }}
          {{ "points-translation" | translate }}
        </span>
      </ion-text>
    </span>
    <div
      style="display: flex; align-items: center; vertical-align: middle"
      *ngIf="product.service_duration_min"
    >
      <ion-icon
        slot="end"
        style="font-size: 17px; margin-right: 3px"
        color="primary"
        name="time-outline"
      >
      </ion-icon>
      <ion-text style="font-size: small; font-weight: bold">
        {{ product.service_duration_min }}'
      </ion-text>
    </div>
  </div>

  <div
    *ngIf="
      orderPickup == false &&
      dineIn == true &&
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.alt_dinein_active &&
      selectedDineInTable &&
      selectedDineInTable.alt_dinein
    "
    style="padding-left: 0px"
    class="pricePaddings"
    slot="end"
    [ngClass]="{
      priceWidthUnavailable: !!product.priceWidthUnavailable,
      priceWidthAvailable: !!product.priceWidthAvailable
    }"
  >
    <span
      style="padding-right: 3px; font-size: smaller"
      *ngIf="
        product.initial_price &&
        !product.price_alt_dinein &&
        !product.price_dinein
      "
    >
      <span class="line-through">
        {{ product.initial_price
        }}{{
          product.enable_quantity_step &&
          product.quantity_step &&
          product.quantity_step_kind
            ? "/" + product.quantity_step_kind
            : ""
        }}
      </span>
    </span>
    <span
      style="padding-right: 3px; font-size: smaller"
      *ngIf="product.initial_price_alt_dinein && product.price_alt_dinein"
    >
      <span class="line-through">
        {{ product.initial_price_alt_dinein
        }}{{
          product.enable_quantity_step &&
          product.quantity_step &&
          product.quantity_step_kind
            ? "/" + product.quantity_step_kind
            : ""
        }}
      </span>
    </span>
    <span
      style="padding-right: 3px; font-size: smaller"
      *ngIf="
        !product.price_alt_dinein &&
        product.initial_price_dinein &&
        product.price_dinein
      "
    >
      <span class="line-through">
        {{ product.initial_price_dinein
        }}{{
          product.enable_quantity_step &&
          product.quantity_step &&
          product.quantity_step_kind
            ? "/" + product.quantity_step_kind
            : ""
        }}
      </span>
    </span>
    <span
      [ngClass]="{
        'lowerFromTop unavailable line-through': !!(
          !product.availableTotal ||
          !product.product_categories_availability_check
        )
      }"
    >
      <ion-text
        *ngIf="product.price_alt_dinein"
        color="primary"
        style="font-weight: 700"
        >{{ product.price_alt_dinein
        }}{{
          product.enable_quantity_step &&
          product.quantity_step &&
          product.quantity_step_kind
            ? "/" + product.quantity_step_kind
            : ""
        }}</ion-text
      >

      <ion-text
        *ngIf="product.price_alt_dinein && product.points_to_earn_alt_dinein"
      >
        <span> | </span>
        <span class="points-to-earn">
          {{ product.points_to_earn_alt_dinein }}
          {{ "points-translation" | translate }}
        </span>
      </ion-text>

      <ion-text
        *ngIf="!product.price_alt_dinein && product.virtual_price_alt_dinein"
        color="primary"
        style="font-weight: 700"
        >{{ product.virtual_price_alt_dinein
        }}{{
          product.enable_quantity_step &&
          product.quantity_step &&
          product.quantity_step_kind
            ? "/" + product.quantity_step_kind
            : ""
        }}</ion-text
      >

      <ion-text
        *ngIf="
          !product.price_alt_dinein &&
          product.price_dinein &&
          !product.virtual_price_alt_dinein
        "
        color="primary"
        style="font-weight: 700"
        >{{ product.price_dinein
        }}{{
          product.enable_quantity_step &&
          product.quantity_step &&
          product.quantity_step_kind
            ? "/" + product.quantity_step_kind
            : ""
        }}</ion-text
      >

      <ion-text
        *ngIf="
          !product.price_alt_dinein &&
          !product.price_dinein &&
          !product.virtual_price_alt_dinein &&
          product.virtual_price_dinein
        "
        color="primary"
        style="font-weight: 700"
        >{{ product.virtual_price_dinein
        }}{{
          product.enable_quantity_step &&
          product.quantity_step &&
          product.quantity_step_kind
            ? "/" + product.quantity_step_kind
            : ""
        }}</ion-text
      >

      <ion-text
        *ngIf="
          !product.price_alt_dinein &&
          !product.virtual_price_alt_dinein &&
          !product.price_dinein &&
          !product.virtual_price_dinein
        "
        color="primary"
        style="font-weight: 700"
        >{{ product.price ? product.price : product.virtual_price
        }}{{
          product.enable_quantity_step &&
          product.quantity_step &&
          product.quantity_step_kind
            ? "/" + product.quantity_step_kind
            : ""
        }}
      </ion-text>

      <ion-text
        *ngIf="
          product.points_to_earn &&
          product.price &&
          !product.price_alt_dinein &&
          !product.virtual_price_alt_dinein &&
          !product.price_dinein &&
          !product.virtual_price_dinein
        "
      >
        <span> | </span>
        <span class="points-to-earn">
          {{ product.points_to_earn }}
          {{ "points-translation" | translate }}
        </span>
      </ion-text>
    </span>
    <div
      style="display: flex; align-items: center; vertical-align: middle"
      *ngIf="product.service_duration_min"
    >
      <ion-icon
        slot="end"
        style="font-size: 17px; margin-right: 3px"
        color="primary"
        name="time-outline"
      >
      </ion-icon>
      <ion-text style="font-size: small; font-weight: bold">
        {{ product.service_duration_min }}'
      </ion-text>
    </div>
  </div>
</ion-item>
