import * as CurrentStoreActions from "./current-store.actions";
import * as _ from "lodash";

export interface State {
  currentStoreId: string;
}

const initialState: State = {
  currentStoreId: null,
};

export function currentStoreReducer(
  state = initialState,
  action: CurrentStoreActions.CurrentStoreActions
) {
  switch (action.type) {
    case CurrentStoreActions.SET_CURRENT_STORE_ID:
      return {
        ...state,
        currentStoreId: action.payload,
      };

    case CurrentStoreActions.CLEAR_CURRENT_STORE_ID:
      return {
        ...state,
        currentStoreId: null,
      };

    default:
      return state;
  }
}
