<div
  style="
    margin-bottom: 5px;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
  "
  *ngIf="
    groupApp &&
    groupApp.pointsSystem &&
    userPoints &&
    userPoints.pointsSystem &&
    userPoints.pointsSystem.active > 0 &&
    currentUser
  "
>
  <ion-item-divider mode="ios">
    <strong>{{ "cart.cart-components.cart-points.points" | translate }}</strong>
    <ion-badge
      [color]="pointsProgressBarColor"
      slot="end"
      *ngIf="currentUser && userPoints && userPoints.pointsSystem"
    >
      {{ "cart.cart-components.cart-points.your-points" | translate }}:
      {{
        userPoints.pointsSystem.active ? userPoints.pointsSystem.active : "0"
      }}
    </ion-badge>
  </ion-item-divider>
  <ion-progress-bar
    *ngIf="
      currentUser && userPoints && userPoints.pointsSystem && highestReward
    "
    [color]="pointsProgressBarColor"
    [value]="highestReward.user_points_percent / 100"
  ></ion-progress-bar>
</div>
<ion-list *ngIf="groupApp && groupApp.pointsSystem && currentUser">
  <ion-row
    class="ion-text-center"
    *ngIf="highestReward && highestReward.user_points_percent === 100"
  >
    <ion-col size="12">
      <ion-button size="small" (click)="getTheCoupon()" color="primary">
        {{ "homepage.point-system.cash-out-points" | translate }}
      </ion-button>
    </ion-col>
  </ion-row>
</ion-list>
