import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import {
  auditTime,
  catchError,
  distinctUntilChanged,
  first,
} from "rxjs/operators";
import * as _ from "lodash";
import * as fromApp from "../../store/app.reducer";
import { Store } from "@ngrx/store";
import { ReservationDatePopupComponent } from "./reservation-date-popup/reservation-date-popup.component";
import {
  AlertController,
  LoadingController,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";
import moment from "moment-timezone";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import { TranslateService } from "@ngx-translate/core";
import { SendOrderPayPopupComponent } from "src/app/popups/send-order-pay-popup/send-order-pay-popup.component";
import { SendReservationInfoPopupComponent } from "../send-reservation-info-popup/send-reservation-info-popup.component";
import { SendReservationPopupComponent } from "../send-reservation-popup/send-reservation-popup.component";
import { Router } from "@angular/router";
import { ReservationTableInfoModalComponent } from "../reservation-table-info-modal/reservation-table-info-modal.component";
import { FirebaseRecaptchaLoginComponent } from "src/app/popups/firebase-recaptcha-login/firebase-recaptcha-login.component";
import * as CompleteOrderValidationActions from "../../store/complete-order-validation/completeOrderValidation.actions";
import { FirebaseBrowserService } from "src/app/services/firebaseBrowser/firebase-browser.service";
import { LoginPopupsServiceService } from "src/app/services/login-popups-service/login-popups-service.service";
import { Capacitor } from "@capacitor/core";
import { FillRequiredNameModalComponent } from "../fill-required-name-modal/fill-required-name-modal.component";
declare let window: any;

@Component({
  selector: "app-create-reservation",
  templateUrl: "./create-reservation.page.html",
  styleUrls: ["./create-reservation.page.scss"],
})
export class CreateReservationPage {
  private subscriptions: Subscription[] = [];
  private mo_token;
  public currentUser;
  private groupApp;
  private group;
  private newCustomer;
  //public windowDimensions: any;
  public reservationMoment: any = "";
  public reservationEndMoment: any;
  public reservationEndTimestamp;
  public reservationTimestamp: any;
  public reservationDateString: any;
  public reservationDayString: any;
  public currentStoreId;
  public openToast = false;
  public loading = false;
  public saving = false;
  public reservationDays;
  public selectedLanguage;
  public openCategories = {};
  private unsubscribeBackEvent: Subscription;
  public seatsSelected = "-";

  public selectedCategories = {};
  public selectedCategory = "all";
  public selectedTable;

  public allTables: any;
  public allCategories: any;

  private minSeats = 2;
  private maxSeats = 99;

  private all_stores: any;
  private storeAccount: any;
  public prepay_per_person;

  constructor(
    private store: Store<fromApp.AppState>,
    private modalController: ModalController,
    private dataStorageService: DataStorageService,
    private alertController: AlertController,
    private translate: TranslateService,
    private loadingController: LoadingController,
    private router: Router,
    private toastController: ToastController,
    private firebaseBrowserService: FirebaseBrowserService,
    private loginPopupService: LoginPopupsServiceService,
    private platform: Platform
  ) {}

  ionViewDidEnter() {
    console.log("ionViewDidEnter");

    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.router.navigateByUrl("/reservations", {
            replaceUrl: true,
          });
        }
      }
    );

    // this.subscriptions.push(
    //   this.store
    //     .select("windowInfo")
    //     .pipe(distinctUntilChanged())

    //     .subscribe((state) => {
    //       if (
    //         state &&
    //         state.windowDimensions &&
    //         !_.isEqual(this.windowDimensions, state.windowDimensions)
    //       ) {
    //         this.windowDimensions = _.cloneDeep(state.windowDimensions);
    //       }
    //     })
    // );

    this.subscriptions.push(
      this.store
        .select("currentStore")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.currentStoreId &&
            !_.isEqual(this.currentStoreId, state.currentStoreId)
          ) {
            this.currentStoreId = _.cloneDeep(state.currentStoreId);
            if (this.group) {
              this.fetchReservationData();
            }
          }
        })
    );

    this.subscriptions.push(
      this.store.select("stores").subscribe((state) => {
        if (
          state &&
          state.stores &&
          !_.isEqual(state.stores, this.all_stores)
        ) {
          this.all_stores = _.cloneDeep(state.stores);
          this.get_store_account();
        }
      })
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            state.selectedLangugage &&
            !_.isEqual(this.selectedLanguage, state.selectedLangugage)
          ) {
            this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
          }
          if (state.groupApp && !_.isEqual(this.groupApp, state.groupApp)) {
            this.groupApp = _.cloneDeep(state.groupApp);

            if (this.currentStoreId && !this.group) {
              this.group = this.groupApp.group;
              this.fetchReservationData();
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.mo_token &&
            !_.isEqual(this.mo_token, state.mo_token)
          ) {
            this.mo_token = _.cloneDeep(state.mo_token);
          }
          if (state && state.user && !_.isEqual(this.currentUser, state.user)) {
            this.currentUser = _.cloneDeep(state.user);
          }
          if (state && !_.isEqual(this.newCustomer, state.newCustomer)) {
            this.newCustomer = _.cloneDeep(state.newCustomer);
          }
        })
    );
  }

  fetchReservationData() {
    this.loading = true;
    this.dataStorageService
      .fetchReservationTables(
        this.group,
        this.currentStoreId,
        this.selectedLanguage
      )
      .subscribe({
        next: async (res: any) => {
          this.loading = false;
          if (!res || !res.success) {
            const alert = await this.alertController.create({
              header: this.translate.instant("alert"),
              message:
                res && res.comment_id
                  ? this.translate.instant(res.comment_id)
                  : this.translate.instant("errorMessages.general_error"),
              backdropDismiss: false,
              buttons: ["OK"],
            });
            await alert.present();
          } else {
            let min = 99;
            let max = 0;
            this.allTables = res.tables;
            _.forEach(this.allTables, tables => {
              _.forEach(tables, table => {
                if (table.seats < min){
                  min = table.seats;
                }
                if (table.max_seats > max){
                  max = table.max_seats;
                }
              });
            });
            this.minSeats = min;
            this.maxSeats = max;
            this.allCategories = _.orderBy(
              res.categories,
              (cat) => cat.position
            );
            this.selectedCategories = {};
            _.forEach(this.allCategories, (c) => {
              this.selectedCategories[c.category_id] = true;
            });
            //this.selectedCategories = _.cloneDeep(this.allCategories);
            this.reservationDays = res.reservationDays;
            _.forEach(this.allCategories, (cat) => {
              this.openCategories[cat.category_id] = true;
            });
            console.log("reservationDays", this.reservationDays);
          }
        },
        error: async (error) => {
          this.loading = false;
          const alert = await this.alertController.create({
            header: this.translate.instant("alert"),
            message: this.translate.instant(
              "errorMessages.problem_reaching_server"
            ),

            buttons: ["OK"],
          });
          await alert.present();
          return error;
        },
      });
  }

  get_store_account() {
    if (!this.currentStoreId || _.isEmpty(this.all_stores)) {
      return;
    }

    const found = _.find(
      this.all_stores,
      (store) => store.store_id === this.currentStoreId
    );
    if (found) {
      this.storeAccount = _.cloneDeep(found);
      if (this.storeAccount.reservationPrepayPerPerson) {
        this.prepay_per_person = this.storeAccount.reservationPrepayPerPerson;
      }
    }
  }

  async createReservationWithoutTable() {
    const modal = await this.modalController.create({
      component: ReservationDatePopupComponent,
      showBackdrop: true,
      backdropDismiss: false,
      cssClass: ["reservationDatePopup"],
      componentProps: {
        reservationDays: _.cloneDeep(this.reservationDays),
        lang: _.cloneDeep(this.selectedLanguage),
        preselectedTimestamp: _.cloneDeep(this.reservationTimestamp || ""),
        preselectedEndTimestamp: _.cloneDeep(
          this.reservationEndTimestamp || ""
        ),
        createReservationWithoutTable: true,
        priority: 300,
      },
    });
    await modal.present();

    modal.onDidDismiss().then((el) => {
      if (el.role === "confirm-create") {
        console.log("onDismiss", el.data);
        this.reservationMoment = moment(
          el.data.day + " " + el.data.start,
          "DD-MM-YYYY HH:mm"
        );
        this.reservationDayString = this.reservationMoment
          .locale(this.selectedLanguage)
          .format("ddd, DD MMM");
        this.reservationDateString = this.reservationMoment
          .locale(this.selectedLanguage)
          .format("ddd, DD MMM HH:mm");
        this.reservationTimestamp = parseInt(
          this.reservationMoment.format("x")
        );
        if (el.data.end) {
          this.reservationEndMoment = moment(
            el.data.day + " " + el.data.end,
            "DD-MM-YYYY HH:mm"
          );
          if (el.data.end <= el.data.start) {
            this.reservationEndMoment.add(1, "day");
          }
          this.reservationEndTimestamp = parseInt(
            this.reservationEndMoment.format("x")
          );
        } else {
          this.reservationEndMoment = null;
          this.reservationEndTimestamp = null;
        }

        this.transformTables();

        this.sendReservation("no_table");
      }
    });
  }

  changeChoiceQuantity(ev) {
    if (ev && ev.role === "increase") {
      if (this.seatsSelected === "-"){
        this.seatsSelected = this.minSeats.toString();
      } else if (parseInt(this.seatsSelected) >= this.maxSeats - 1) {
        this.seatsSelected = this.maxSeats.toString();
      } else {
        this.seatsSelected = (parseInt(this.seatsSelected) + 1).toString();
      }
    } else if (ev && ev.role === "decrease") {
      if (this.seatsSelected === "-"){
        return;
      } else if (parseInt(this.seatsSelected) <= this.minSeats + 1){
        this.seatsSelected = this.minSeats.toString();
      } else {
        this.seatsSelected = (parseInt(this.seatsSelected) - 1).toString();
      }
    } else if (ev && ev.role === "quantity_value") {
      const seats = parseInt(ev.value);

      //console.log(seats, this.reservation.seats, this.reservation.max_seats);
      if (seats < this.minSeats){
        this.seatsSelected = this.minSeats.toString();
      } else if (seats > this.maxSeats){
        this.seatsSelected = this.maxSeats.toString();
      } else {
        this.seatsSelected = seats.toString();
      }
    }
  }

  clearSeatsSelected() {
    this.seatsSelected = "-";
  }

  trackFn(index, item) {
    return item.table_id;
  }

  onDividerClick(category) {
    this.openCategories[category.category_id] =
      !this.openCategories[category.category_id];
  }

  // const toast = await this.toastController.create({
  //   message: "Try again!",
  //   cssClass: "copied-to-clipboard",
  //   color: "primary",
  //   duration: 1000,
  //   position: "middle",
  // });
  // toast.present();

  async showMaximumPeopleToast(min, max) {
    if (this.openToast) {
      return;
    }
    this.openToast = true;
    const toast = await this.toastController.create({
      message:
        this.translate.instant("reservations.recommended_people") +
        ": " +
        (max && max !== min ? min + " - " + max : min),
      cssClass: "copied-to-clipboard",
      position: "middle",
      duration: 1500,
      // buttons: [
      //   {
      //     text: "OK",
      //     role: "cancel",
      //   },
      // ],
    });
    await toast.present();
    toast.onDidDismiss().then(() => {
      this.openToast = false;
    });
  }

  async showTableInfoModal(table) {
    const modal = await this.modalController.create({
      component: ReservationTableInfoModalComponent,
      cssClass: "ReservationTableInfoModal",
      backdropDismiss: false,
      componentProps: {
        table: _.cloneDeep(table),
        reservationTimestamp: _.cloneDeep(this.reservationTimestamp),
        reservationDays: _.cloneDeep(this.reservationDays),
        lang: this.selectedLanguage
      },
    });
    await modal.present();
    modal.onDidDismiss().then((data) => {
      if (data && data.role === "confirm") {
        const mom = moment(parseInt(data.data.timestamp));
        const dayString = mom.format("DD-MM-YYYY");
        //const timeString = mom.format("HH:mm");
        const findReservationDay = _.find(this.reservationDays, (d) => {
          return d.day === dayString;
        });
        if (findReservationDay) {
          this.reservationMoment = moment(parseInt(data.data.timestamp));
          this.reservationDayString = this.reservationMoment
            .locale(this.selectedLanguage)
            .format("ddd, DD MMM");
          this.reservationDateString = this.reservationMoment
            .locale(this.selectedLanguage)
            .format("ddd, DD MMM HH:mm");
          this.reservationTimestamp = parseInt(
            this.reservationMoment.format("x")
          );
          if (data.data.endTimestamp) {
            this.reservationEndMoment = moment(
              parseInt(data.data.endTimestamp)
            );
            this.reservationEndTimestamp = parseInt(
              this.reservationEndMoment.format("x")
            );
          } else {
            this.reservationEndMoment = null;
            this.reservationEndTimestamp = null;
          }
          this.transformTables();
          this.tableClicked(table);
        }
      }
    });
  }

  async tableClicked(table) {
    if (!this.currentUser) {
      const toast = await this.toastController.create({
        duration: 3000,
        position: "middle",
        color: "danger",
        message: this.translate.instant(
          "reservations.you-must-login-to-complete-reservation"
        ),
      });
      await toast.present();
      if (
        this.groupApp &&
        this.groupApp.firebaseVerificationEnabled &&
        this.groupApp.webFirebase &&
        this.groupApp.webFirebase.apiKey &&
        this.firebaseBrowserService.getFireBaseApp() &&
        !Capacitor.isNativePlatform()
      ) {
        this.loginPopupService.closeConfirmationLoginModal();
        this.loginPopupService.closeFirebaseModalLogin();
        this.loginPopupService.presentFirebaseModalLogin(null, null);
      } else if (
        this.groupApp &&
        this.groupApp.firebaseVerificationEnabled &&
        Capacitor.isNativePlatform()
      ) {
        this.loginPopupService.closeMobileMagicLoginAlert();
        this.loginPopupService.presentMobileMagicLoginAlert(
          this.newCustomer,
          this.groupApp
        );
      }
      return;
    }

    if (!table.availablePeriod) {
      return;
    }
    this.selectedTable = _.cloneDeep(table);
    this.sendReservation("");
  }

  onSelect() {
    // if (this.selectedCategory === "all"){
    //   this.selectedCategories = _.cloneDeep(this.allCategories);
    // } else {
    //   this.selectedCategories = [_.find(this.allCategories, c => c.category_id === this.selectedCategory)];
    // }
    _.forEach(this.selectedCategories, (value, key) => {
      this.selectedCategories[key] =
        this.selectedCategory === "all" || key === this.selectedCategory
          ? true
          : false;
    });
  }

  transformTables() {
    if (!this.reservationTimestamp) {
      return;
    }
    console.log(
      "timestamps",
      this.reservationTimestamp,
      this.reservationEndTimestamp
    );
    _.forEach(this.allTables, (value, key) => {
      this.allTables[key] = _.map(value, (t) => {
        t.calendar = _.sortBy(t.calendar, (s) => s.start);
        if (this.reservationEndTimestamp) {
          let index = -1;
          for (let i = 0; i < t.calendar.length; i++) {
            const s = t.calendar[i];
            const next = t.calendar[i + 1];
            if (
              s.active &&
              !s.taken &&
              parseInt(s.start) <= this.reservationTimestamp &&
              parseInt(s.start) < this.reservationEndTimestamp &&
              parseInt(s.end) > this.reservationTimestamp &&
              (parseInt(s.end) >= this.reservationEndTimestamp ||
                (s.virtual &&
                  next &&
                  next.active &&
                  next.virtual &&
                  !next.taken &&
                  parseInt(next.end) >= this.reservationEndTimestamp))
            ) {
              index = i;
              break;
            }
          }
          if (index !== -1) {
            if (t.calendar[index].virtual) {
              t.availablePeriod = _.cloneDeep(t.calendar[index]);
              t.availablePeriod.start = this.reservationTimestamp.toString();
              const after5hours = moment(parseInt(t.availablePeriod.start))
                .add(5, "hours")
                .format("x");
              t.availablePeriod.end =
                after5hours < this.reservationEndTimestamp.toString()
                  ? after5hours
                  : this.reservationEndTimestamp.toString();
            } else {
              t.availablePeriod = _.cloneDeep(t.calendar[index]);
            }
          } else {
            t = _.omit(t, ["availablePeriod"]);
          }
        } else {
          const index = _.findIndex(t.calendar, (s: any) => {
            return (
              s.active &&
              !s.taken &&
              parseInt(s.start) <= this.reservationTimestamp &&
              parseInt(s.end) > this.reservationTimestamp
            );
          });
          if (index !== -1) {
            if (t.calendar[index].virtual) {
              t.availablePeriod = _.cloneDeep(t.calendar[index]);
              t.availablePeriod.start = this.reservationTimestamp.toString();
              t.availablePeriod.end = moment(parseInt(t.availablePeriod.start))
                .add(5, "hours")
                .format("x");
            } else {
              t.availablePeriod = _.cloneDeep(t.calendar[index]);
            }
          } else {
            t = _.omit(t, ["availablePeriod"]);
          }
          console.log("Transform", t.availablePeriod);
        }
        return t;
      });
    });
  }

  async chooseDate() {
    const modal = await this.modalController.create({
      component: ReservationDatePopupComponent,
      showBackdrop: true,
      backdropDismiss: false,
      cssClass: ["reservationDatePopup"],
      componentProps: {
        reservationDays: _.cloneDeep(this.reservationDays),
        lang: _.cloneDeep(this.selectedLanguage),
        preselectedTimestamp: _.cloneDeep(this.reservationTimestamp || ""),
        preselectedEndTimestamp: _.cloneDeep(
          this.reservationEndTimestamp || ""
        ),
        priority: 300,
      },
    });
    await modal.present();
    modal.onDidDismiss().then((el) => {
      if (el.role === "confirm") {
        console.log("onDismiss", el.data);
        this.reservationMoment = moment(
          el.data.day + " " + el.data.start,
          "DD-MM-YYYY HH:mm"
        );
        this.reservationDayString = this.reservationMoment
          .locale(this.selectedLanguage)
          .format("ddd, DD MMM");
        this.reservationDateString = this.reservationMoment
          .locale(this.selectedLanguage)
          .format("ddd, DD MMM HH:mm");
        this.reservationTimestamp = parseInt(
          this.reservationMoment.format("x")
        );
        if (el.data.end) {
          this.reservationEndMoment = moment(
            el.data.day + " " + el.data.end,
            "DD-MM-YYYY HH:mm"
          );
          if (el.data.end <= el.data.start) {
            this.reservationEndMoment.add(1, "day");
          }
          this.reservationEndTimestamp = parseInt(
            this.reservationEndMoment.format("x")
          );
        } else {
          this.reservationEndMoment = null;
          this.reservationEndTimestamp = null;
        }

        this.transformTables();
      }
    });
  }

  async sendReservation(type: "no_table" | "") {
    if (!this.currentUser.firstName || !this.currentUser.lastName) {
      const modal = await this.modalController.create({
        component: FillRequiredNameModalComponent,
        showBackdrop: true,
        backdropDismiss: false,
        cssClass: ["fillRequiredNameModal"],
      });
      await modal.present();
      modal.onDidDismiss().then((el) => {
        if (el.role === "confirm") {
          this.sendReservation(type);
        }
      });
      return;
    }

    let reservation;
    if (type === "no_table") {
      reservation = {
        availablePeriod: {
          start: this.reservationTimestamp,
          end: this.reservationEndTimestamp
        },
        // availablePeriod: this.selectedTable.availablePeriod,
        // prepay_amount:
        //   this.selectedTable.availablePeriod.prepay_amount ||
        //   this.selectedTable.availablePeriod.prepay_amount === 0
        //     ? this.selectedTable.availablePeriod.prepay_amount
        //     : this.selectedTable.prepay_amount,
        // table_category_name: this.selectedTable.category_name,
        // table_category_id: this.selectedTable.category_id,
        // table_name: this.selectedTable.name,
        dateString: this.reservationDayString,
        date: this.reservationMoment.format("x"),
        // table_id: this.selectedTable.table_id,
        // slot_id: this.selectedTable.availablePeriod.slot_id,
        // seats: this.selectedTable.max_seats ? this.selectedTable.seats : 1,
        // max_seats: this.selectedTable.max_seats
        //   ? this.selectedTable.max_seats
        //   : this.selectedTable.seats,
        virtual: true,
      }
    } else {
      reservation = {
        availablePeriod: this.selectedTable.availablePeriod,
        prepay_amount:
          this.selectedTable.availablePeriod.prepay_amount ||
          this.selectedTable.availablePeriod.prepay_amount === 0
            ? this.selectedTable.availablePeriod.prepay_amount
            : this.selectedTable.prepay_amount,
        table_category_name: this.selectedTable.category_name,
        table_category_id: this.selectedTable.category_id,
        table_name: this.selectedTable.name,
        dateString: this.reservationDayString,
        date: this.reservationMoment.format("x"),
        table_id: this.selectedTable.table_id,
        slot_id: this.selectedTable.availablePeriod.slot_id,
        seats: this.selectedTable.max_seats ? this.selectedTable.seats : 1,
        max_seats: this.selectedTable.max_seats
          ? this.selectedTable.max_seats
          : this.selectedTable.seats,
        virtual: !!this.selectedTable.availablePeriod.virtual,
      };
    }

    const modal = await this.modalController.create({
      component: SendReservationInfoPopupComponent,
      backdropDismiss: false,
      cssClass: ["sendReservationAndPayModal"],
      componentProps: {
        reservation: _.cloneDeep(reservation),
        lang: _.cloneDeep(this.selectedLanguage),
        without_table: type === 'no_table' ? true : false,
      },
    });

    await modal.present();
    modal.onDidDismiss().then(async (data) => {
      if (data && data.role && data.role === "continue") {
        let seats;
        if (type === 'no_table') {
          seats = data.data && data.data.seats;
          reservation.prepay_amount = parseInt(data.data.seats) * this.prepay_per_person;
          reservation.no_table = true;
        } else {
          seats = data.data && data.data.seats ? data.data.seats : reservation.max_seats || reservation.seats;
        }
        const formAnswers = data.data.formAnswers || null;
        if (reservation.prepay_amount) {
          this.showSendReservationAndPayModal(_.assign(reservation, {
            customer_id: this.currentUser.customer_id,
            store_id: this.currentStoreId,
            seats: seats,
            formAnswers: formAnswers
          }));
        } else {
          const data = _.assign(reservation, {
            customer_id: this.currentUser.customer_id,
            store_id: this.currentStoreId,
            seats: seats,
            formAnswers: formAnswers
          });
          let message = this.translate.instant("loading-please-wait");
          const loader = await this.loadingController.create({
            duration: 30000,
            message: message,
            cssClass: "ionicLoadingCss",
            keyboardClose: true,
          });
          await loader.present();
          this.dataStorageService
            .createReservation(this.mo_token, data)
            .subscribe({
              next: async (res: any) => {
                loader.dismiss();
                this.saving = false;
                if (!res || !res.success) {
                  const alert = await this.alertController.create({
                    header: this.translate.instant("alert"),
                    message:
                      res && res.comment_id
                        ? this.translate.instant(res.comment_id)
                        : this.translate.instant("errorMessages.general_error"),
                    backdropDismiss: false,
                    buttons: ["OK"],
                  });
                  await alert.present();
                } else {
                  this.router.navigateByUrl("/reservations");
                }
              },
              error: async (error) => {
                loader.dismiss();
                this.saving = false;
                const alert = await this.alertController.create({
                  header: this.translate.instant("alert"),
                  message: this.translate.instant(
                    "errorMessages.problem_reaching_server"
                  ),

                  buttons: ["OK"],
                });
                await alert.present();
                return error;
              },
            });
        }
      }
    });
  }

  async showSendReservationAndPayModal(reservation) {
    const modal = await this.modalController.create({
      component: SendReservationPopupComponent,
      cssClass: "sendOrderAndPayModalSmall",
      backdropDismiss: false,
      componentProps: { reservation: _.cloneDeep(reservation) },
    });
    await modal.present();
  }

  async createReservation() {
    const alert = await this.alertController.create({
      header: this.translate.instant("reservations.create-reservation"),
      message:
        "θέλετε να κάνετε κράτηση για το τραπέζι " +
        this.selectedTable.name +
        " στις " +
        this.reservationMoment.format("DD-MM HH:mm"),
      backdropDismiss: false,
      buttons: [
        {
          text: this.translate.instant("yes"),
          handler: () => {
            const reservation = {
              store_id: this.currentStoreId,
              date: this.reservationMoment.format("x"),
              customer_id: this.currentUser.customer_id,
              table_id: this.selectedTable.table_id,
            };

            this.saving = true;
            this.dataStorageService
              .createReservation(this.mo_token, reservation)
              .subscribe({
                next: async (res: any) => {
                  this.saving = false;
                  if (!res || !res.success) {
                    const alert = await this.alertController.create({
                      header: this.translate.instant("alert"),
                      message:
                        res && res.comment_id
                          ? this.translate.instant(res.comment_id)
                          : this.translate.instant(
                              "errorMessages.general_error"
                            ),
                      backdropDismiss: false,
                      buttons: ["OK"],
                    });
                    await alert.present();
                  }
                },
                error: async (error) => {
                  this.saving = false;
                  const alert = await this.alertController.create({
                    header: this.translate.instant("alert"),
                    message: this.translate.instant(
                      "errorMessages.problem_reaching_server"
                    ),

                    buttons: ["OK"],
                  });
                  await alert.present();
                  return error;
                },
              });
          },
        },
        {
          text: this.translate.instant("cancel"),
          role: "cancel",
        },
      ],
    });
    await alert.present();
  }

  ionViewDidLeave() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
   // console.log("ionviewdidleave");
    if (this.subscriptions) {
      _.forEach(this.subscriptions, (subscription) => {
        if (subscription) {
          subscription.unsubscribe();
        }
      });
    }
    this.subscriptions = [];
    this.mo_token = "";
    this.currentUser = null;
    //windowDimensions;
    this.reservationMoment = null;
    this.reservationTimestamp = "";
    this.reservationDateString = "";
    this.reservationDayString = "";
    this.currentStoreId = "";
    this.openToast = false;
    this.loading = false;
    this.saving = false;
    this.reservationDays = null;
    this.selectedLanguage = "";

    this.selectedCategories = {};
    this.selectedCategory = "all";
    this.selectedTable = null;

    this.allTables = null;
    this.allCategories = null;
  }
}
