import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { AnimationOptions } from "ngx-lottie";
import { distinctUntilChanged, Subscription } from "rxjs";
import * as fromApp from "../../../store/app.reducer";
import * as _ from "lodash";
import { Store } from "@ngrx/store";
declare let window: any;

@Component({
  selector: "app-app-rating-modal",
  templateUrl: "./app-rating-modal.component.html",
  styleUrls: ["./app-rating-modal.component.scss"],
})
export class AppRatingModalComponent implements OnInit, OnDestroy {
  @Input() noStoreReviewMode: boolean;
  public appRatingAnimationInstance: any;
  public selectedLanguage: string;
  private unsubscribeBackEvent: Subscription;
  private subscription: Subscription;
  public device: string;

  canceledOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/review-app.json",
  };
  constructor(
    private modalController: ModalController,
    private platform: Platform,
    private store: Store<fromApp.AppState>
  ) {}

  ngOnInit() {
    this.subscription = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.selectedLangugage &&
          !_.isEqual(state.selectedLangugage, this.selectedLanguage)
        ) {
          this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
        }
      });

    if (this.platform.is("ios")) {
      this.device = "ios";
    } else {
      this.device = "android";
    }
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      300,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );
  }

  closeModal() {
    this.modalController.dismiss();
  }

  animationCreated(ev) {
    this.appRatingAnimationInstance = ev;
    this.appRatingAnimationInstance.setSpeed(1);
  }

  rateApp() {
    this.modalController.dismiss("yes");
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
  }
}
