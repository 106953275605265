import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../../store/app.reducer";
import * as _ from "lodash";
import { auditTime, distinctUntilChanged, Subscription } from "rxjs";

@Component({
  selector: "app-quantity-options",
  templateUrl: "./quantity-options.component.html",
  styleUrls: ["./quantity-options.component.scss"],
})
export class QuantityOptionsComponent implements OnInit, OnDestroy {
  @Input() store_info;
  @Input() cart;
  @Input() selectedPaymentMethodID;
  @Input() orderPickup: boolean;
  @Input() dineIn: boolean;
  @Input() catalogMode: boolean;
  public storeInfoClone;
  public selectedLang: any;
  private subscription: Subscription;

  constructor(private store: Store<fromApp.AppState>) {}

  ngOnInit() {
    //  console.log("quantity options dine in", this.dineIn);
    this.subscription = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (state && !_.isEqual(this.selectedLang, state.selectedLangugage)) {
          this.selectedLang = _.cloneDeep(state.selectedLangugage);
          if (!this.selectedLang) {
            this.selectedLang = "el";
          }
          // console.log("selected lang for cart custom", this.selectedLang);
        }
      });
    if (this.store_info && this.store_info.cartCustomOptions) {
      this.store_info.cartCustomOptions = _.cloneDeep(
        _.orderBy(
          this.store_info.cartCustomOptions,
          (item) => (item.position ? item.position : item.title),
          ["asc"]
        )
      );
    }
  }

  trackTimestamp(index, option) {
    return option.timestamp;
  }
  selectCustomOption(option) {}

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
