import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { Subscription } from "rxjs";

@Component({
  selector: "app-fullscreen-image-popup",
  templateUrl: "./fullscreen-image-popup.component.html",
  styleUrls: ["./fullscreen-image-popup.component.scss"],
})
export class FullscreenImagePopupComponent implements OnInit, OnDestroy {
  @Input() image_path: any;
  private unsubscribeBackEvent: Subscription;

  constructor(
    private modalController: ModalController,
    private platform: Platform
  ) {}

  closeModal() {
    this.modalController.dismiss();
  }

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );
  }

  ngOnDestroy(): void {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
  }
}
