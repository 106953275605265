import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import moment from "moment";
import { Subscription } from "rxjs";
import { auditTime, distinctUntilChanged } from "rxjs/operators";
import * as fromApp from "../../store/app.reducer";

@Component({
  selector: "app-coupons-item",
  templateUrl: "./coupons-item.component.html",
  styleUrls: ["./coupons-item.component.scss"],
})
export class CouponsItemComponent implements OnInit, OnDestroy, OnChanges {
  public stores: any;
  public storesByStoreId: any;
  public badgeColor: string;
  public selectedLangugage: any;
  private subscription: Subscription;
  private subscription2: Subscription;
  @Input() coupon: any;

  constructor(private store: Store<fromApp.AppState>) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.coupon &&
      changes.coupon.previousValue &&
      changes.coupon.previousValue !== changes.coupon.currentValue
    ) {
      if (this.coupon && this.coupon.activeTo) {
        this.coupon.activeTo = moment(
          _.cloneDeep(parseInt(this.coupon.activeTo.toString()))
        )
          .subtract(1, "day")
          .format("x")
          .toString();
      }
    }
  }

  ngOnInit() {
    if (this.coupon && this.coupon.activeTo) {
      this.coupon.activeTo = moment(
        _.cloneDeep(parseInt(this.coupon.activeTo.toString()))
      )
        .subtract(1, "day")
        .format("x")
        .toString();
    }
    if (this.coupon) {
      // console.log("coupon exists");
      if (this.coupon.status === "used") {
        this.badgeColor = "used-coupon";
        ///console.log("set used coupon");
      } else if (this.coupon.status === "active") {
        this.badgeColor = "active-coupon";
        //console.log("set active coupon");
      } else if (this.coupon.status === "expired") {
        this.badgeColor = "expired-coupon";
        //console.log("set exp coupon");
      } else if (this.coupon.status === "outdated") {
        this.badgeColor = "expired-coupon";
        //console.log("set exp coupon");
      } else if (this.coupon.status === "canceled") {
        this.badgeColor = "used-coupon";
        //console.log("set exp coupon");
      } else if (this.coupon.status === "pending_confirmation") {
        this.badgeColor = "warning";
        //console.log("set exp coupon");
      }
    }
    this.subscription = this.store
      .select("stores")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (state && state.stores && !_.isEqual(state.stores, this.stores)) {
          this.stores = _.cloneDeep(state.stores);
          if (this.stores && this.stores.length > 1) {
            this.storesByStoreId = _.groupBy(this.stores, "store_id");

            _.each(this.storesByStoreId, (store, key, list) => {
              this.storesByStoreId[key] = store[0];
            });
          }
        }
      });

    this.subscription2 = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (
          state &&
          state.selectedLangugage &&
          !_.isEqual(state.selectedLangugage, this.selectedLangugage)
        ) {
          this.selectedLangugage = _.cloneDeep(state.selectedLangugage);
        }
      });
  }

  ngOnDestroy() {
    this.coupon = null;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscription2) {
      this.subscription2.unsubscribe();
    }
  }
}
