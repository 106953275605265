import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { AlertController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { distinctUntilChanged, first, Subscription } from "rxjs";
import { DeployService } from "src/app/services/deploy-service/deploy.service";
import * as _ from "lodash";
import * as fromApp from "../../store/app.reducer";
import { AnalyticsServiceService } from "src/app/services/analitycsService/analytics-service.service";
import { AnimationOptions } from "ngx-lottie";

@Component({
  selector: "app-update-modal",
  templateUrl: "./update-modal.component.html",
  styleUrls: ["./update-modal.component.scss"],
})
export class UpdateModalComponent implements OnInit, OnDestroy {
  @Input() appChannel: string;

  public windowDimensions;
  public headerLogo: any;
  public darkHeaderLogo: any;
  public flattenedIconBackgroundFlag: any;
  public storeImage: any;
  public groupApp: any;
  public animation_loader_path: string;
  public startLogoAnimation;
  private animationDeliveryManagerInstance;

  animationOptions: AnimationOptions = {
    path: "",
  };

  animationDeliveryManagerOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/deliverymanager-lottie-dark.json",
  };

  private unsubscribeBackEvent: Subscription;
  private groupSub: Subscription;
  private windowInfoSub: Subscription;
  constructor(
    private translateService: TranslateService,
    private deployService: DeployService,
    private platform: Platform,
    private analyticsService: AnalyticsServiceService,
    private changeDetector: ChangeDetectorRef,
    private store: Store<fromApp.AppState>,
    private alertController: AlertController
  ) {}

  animationCreated(ev) {
    this.animationDeliveryManagerInstance = ev;
    setTimeout(() => {
      this.animationDeliveryManagerInstance.setSpeed(0);
    }, 2100);
  }

  ngOnInit() {
    // setTimeout(() => {
    //   this.startLogoAnimation = true;
    // }, 1500);
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      10000,
      () => {
        this.appExit();
      }
    );

    this.groupSub = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.groupApp &&
          !_.isEqual(state.groupApp, this.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp);

          if (this.groupApp.design_settings) {
            if (this.groupApp.design_settings.animation_loader) {
              this.animationOptions = {
                ...this.animationOptions,
                path:
                  "https://data.deliverymanager.gr/animations/" +
                  this.groupApp.design_settings.animation_loader,
              };
            } else {
              this.animationOptions = {
                ...this.animationOptions,
                path: "https://data.deliverymanager.gr/animations/spinner-default-1.json",
              };
            }
          } else {
            this.animationOptions = {
              ...this.animationOptions,
              path: "https://data.deliverymanager.gr/animations/spinner-default-1.json",
            };
          }

          this.storeImage = this.groupApp.iconImage;
          this.headerLogo = this.groupApp.headerLogo;
          this.darkHeaderLogo = this.groupApp.headerLogoDark;
          this.flattenedIconBackgroundFlag =
            !!this.groupApp.flattenedIconBackground;
          if (
            this.flattenedIconBackgroundFlag &&
            this.groupApp.flattenedIconBackground
          ) {
            // console.log(
            //   "flattenedIconBackground",
            //   this.groupApp.flattenedIconBackground
            // );
            const el = document.documentElement;
            if (el) {
              el.style.setProperty(
                "--flattenedIconBackground",
                this.groupApp.flattenedIconBackground
              );
            }
            //  console.log("el el style", test);
          }
          console.log("test", !!this.groupApp.flattenedIconBackground);
          this.sendFootPrint();
        }
      });

    this.windowInfoSub = this.store
      .select("windowInfo")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.windowDimensions &&
          !_.isEqual(state.windowDimensions, this.windowDimensions)
        ) {
          this.windowDimensions = _.cloneDeep(state.windowDimensions);
        }
      });
  }

  get progress() {
    return this.deployService.getUpdateProgress();
  }

  sendFootPrint() {
    this.store
      .select("platformInfo")
      .pipe(first())
      .subscribe((state) => {
        if (state && state.versionNumber) {
          this.analyticsService.dmAnalyticsMessage(
            "update_modal_loaded",
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            _.cloneDeep(state.versionNumber),
            null,
            null
          );
        }
      });
  }

  private async appExit() {
    const alert = await this.alertController.create({
      header: this.translateService.instant("alert"),
      message: this.translateService.instant("do-you-want-to-exit-app"),
      buttons: [
        {
          text: this.translateService.instant("cancel"),
          role: "cancel",
          handler: () => {
            console.log("Application exit prevented!");
          },
        },
        {
          text: this.translateService.instant("exit"),
          handler: () => {
            // tslint:disable-next-line: no-string-literal
            if (this.platform.is("android")) {
              window.navigator["app"].exitApp(); // Close this application
            }
          },
        },
      ],
    });
    return await alert.present();
  }

  ngOnDestroy() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }

    if (this.groupSub) {
      this.groupSub.unsubscribe();
    }

    if (this.windowInfoSub) {
      this.windowInfoSub.unsubscribe();
    }
  }
}
