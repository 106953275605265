import { Component, OnDestroy, OnInit } from "@angular/core";
import { ModalController, Platform, ToastController } from "@ionic/angular";
import * as _ from "lodash";
import * as fromApp from "../../store/app.reducer";
import { Store } from "@ngrx/store";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import * as AuthActions from "../../auth/store/auth.actions";
import { TranslateService } from "@ngx-translate/core";
import { distinctUntilChanged, Subscription } from "rxjs";

@Component({
  selector: "app-submit-user-data-modal",
  templateUrl: "./submit-user-data-modal.component.html",
  styleUrls: ["./submit-user-data-modal.component.scss"],
})
export class SubmitUserDataModalComponent implements OnInit, OnDestroy {
  public currentUser: any;
  public customerDataSaveLoading: boolean = false;
  public valid: boolean = true;

  private mo_token: any;
  private subscription: Subscription;
  private unsubscribeBackEvent: Subscription;

  constructor(
    private modalController: ModalController,
    private toastController: ToastController,
    private dataStorageService: DataStorageService,
    private translateService: TranslateService,
    private platform: Platform,
    private store: Store<fromApp.AppState>
  ) {}

  closeModal() {
    this.modalController.dismiss();
  }

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );
    this.subscription = this.store
      .select("auth")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (state && !_.isEqual(state.user, this.currentUser)) {
          this.currentUser = _.cloneDeep(state.user);
        }

        if (state && !_.isEqual(state.mo_token, this.mo_token)) {
          this.mo_token = _.cloneDeep(state.mo_token);
        }
      });
  }

  validateForm() {
    this.valid = true;
    if (
      !this.currentUser.email ||
      !this.currentUser.firstName ||
      !this.currentUser.lastName
    ) {
      this.valid = false;
    }
  }

  updateCustomerDataCustomerInfo() {
    this.customerDataSaveLoading = true;

    this.validateForm();

    if (!this.valid) {
      this.customerDataSaveLoading = false;

      this.presentErrorToast(
        this.translateService.instant(
          "cart.cart-components.customer-info.please-fill-all-the-required-fields"
        ),
        "danger"
      );
      return false;
    }

    console.log("user", this.currentUser);

    this.dataStorageService
      .updateCustomerData(
        {
          updatedData: _.cloneDeep(this.currentUser),
        },
        this.mo_token
      )
      .subscribe({
        next: async (res: any) => {
          if (res && res.success) {
            //  console.log("ngmodelchange", this.currentUser);

            let updatedUser = res.data;
            this.store.dispatch(
              new AuthActions.SetUser(_.cloneDeep(updatedUser))
            );

            await this.modalController.dismiss("success");
            this.customerDataSaveLoading = false;
          } else {
            this.customerDataSaveLoading = false;
            if (res.comment_id) {
              this.presentErrorToast(
                this.translateService.instant(res.comment_id),
                "danger"
              );
            } else if (res.comments) {
              this.presentErrorToast(res.comments, "danger");
            }
          }
        },
        error: (err) => {
          this.customerDataSaveLoading = false;
          let errMsg = this.translateService.instant(
            "errorMessages.error: There was an error on saving"
          );

          this.presentErrorToast(errMsg, "danger");
          return err;
        },
      });
  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            // console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
  }
}
