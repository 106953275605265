import { Injectable } from "@angular/core";
import { debounceTime, first } from "rxjs/operators";
import * as _ from "lodash";
import * as fromApp from "../../store/app.reducer";
import { Store } from "@ngrx/store";

@Injectable({
  providedIn: "root",
})
export class PointsCalculationsService {
  constructor(private store: Store<fromApp.AppState>) {}

  checkStoresPointsSystemFactors() {}

  calculateDeliveryMethod(pickup, dinein, alt_dinein_data) {
    let deliveryMethod;
    if (pickup && !dinein) {
      deliveryMethod = "takeaway";
    } else if (!pickup && !dinein) {
      deliveryMethod = "delivery";
    } else if (!pickup && dinein) {
      deliveryMethod = "dinein";
      if (
        alt_dinein_data &&
        alt_dinein_data.alt_dinein_active &&
        alt_dinein_data.selectedDineInTable
      ) {
        deliveryMethod = "altdinein";
      }
    }

    return _.cloneDeep(deliveryMethod);
  }

  calculatePointsFactor(
    groupApp,
    store_info,
    points,
    platform,
    orderPickup,
    dineIn,
    selectedDineInTable
  ) {
    let pointsObject = {
      pointsFactor: null,
      pointsFactorUsed: false,
    };

    const deliveryMethod = this.calculateDeliveryMethod(orderPickup, dineIn, {
      alt_dinein_active:
        store_info &&
        store_info.dinein &&
        store_info.alt_dinein_active &&
        selectedDineInTable &&
        selectedDineInTable.alt_dinein,
      selectedDineInTable: selectedDineInTable,
    });
    if (
      groupApp.pointsSystem &&
      !_.isEmpty(groupApp.points) &&
      !_.isEmpty(groupApp.points.rules) &&
      store_info.pointsSystemStoreSettings &&
      store_info.pointsSystemStoreSettings.active &&
      points &&
      points > 0
    ) {
      if (
        deliveryMethod &&
        deliveryMethod === "delivery" &&
        platform === "web" &&
        store_info.pointsSystemStoreSettings.delivery &&
        store_info.pointsSystemStoreSettings.delivery.web &&
        store_info.pointsSystemStoreSettings.delivery.web.factor &&
        store_info.pointsSystemStoreSettings.delivery.web.factor > 0
      ) {
        pointsObject.pointsFactor = {
          factor: store_info.pointsSystemStoreSettings.delivery.web.factor,
          order_type: "delivery",
          platform: "web",
        };
        pointsObject.pointsFactorUsed = true;
      } else if (
        deliveryMethod &&
        deliveryMethod === "delivery" &&
        platform === "mobile" &&
        store_info.pointsSystemStoreSettings.delivery &&
        store_info.pointsSystemStoreSettings.delivery.apps &&
        store_info.pointsSystemStoreSettings.delivery.apps.factor &&
        store_info.pointsSystemStoreSettings.delivery.apps.factor > 0
      ) {
        pointsObject.pointsFactor = {
          factor: store_info.pointsSystemStoreSettings.delivery.apps.factor,
          order_type: "delivery",
          platform: "mobile",
        };
        pointsObject.pointsFactorUsed = true;
      } else if (
        deliveryMethod &&
        deliveryMethod === "takeaway" &&
        platform === "web" &&
        store_info.pointsSystemStoreSettings.takeaway &&
        store_info.pointsSystemStoreSettings.takeaway.web &&
        store_info.pointsSystemStoreSettings.takeaway.web.factor &&
        store_info.pointsSystemStoreSettings.takeaway.web.factor > 0
      ) {
        pointsObject.pointsFactor = {
          factor: store_info.pointsSystemStoreSettings.takeaway.web.factor,
          order_type: "takeaway",
          platform: "web",
        };
        pointsObject.pointsFactorUsed = true;
      } else if (
        deliveryMethod &&
        deliveryMethod === "takeaway" &&
        platform === "mobile" &&
        store_info.pointsSystemStoreSettings.takeaway &&
        store_info.pointsSystemStoreSettings.takeaway.apps &&
        store_info.pointsSystemStoreSettings.takeaway.apps.factor &&
        store_info.pointsSystemStoreSettings.takeaway.apps.factor > 0
      ) {
        pointsObject.pointsFactor = {
          factor: store_info.pointsSystemStoreSettings.takeaway.apps.factor,
          order_type: "takeaway",
          platform: "mobile",
        };
        pointsObject.pointsFactorUsed = true;
      } else if (
        deliveryMethod &&
        deliveryMethod === "dinein" &&
        platform === "web" &&
        store_info.pointsSystemStoreSettings.dinein &&
        store_info.pointsSystemStoreSettings.dinein.web &&
        store_info.pointsSystemStoreSettings.dinein.web.factor &&
        store_info.pointsSystemStoreSettings.dinein.web.factor > 0
      ) {
        pointsObject.pointsFactor = {
          factor: store_info.pointsSystemStoreSettings.dinein.web.factor,
          order_type: "dinein",
          platform: "web",
        };
        pointsObject.pointsFactorUsed = true;
      } else if (
        deliveryMethod &&
        deliveryMethod === "dinein" &&
        platform === "mobile" &&
        store_info.pointsSystemStoreSettings.dinein &&
        store_info.pointsSystemStoreSettings.dinein.apps &&
        store_info.pointsSystemStoreSettings.dinein.apps.factor &&
        store_info.pointsSystemStoreSettings.dinein.apps.factor > 0
      ) {
        pointsObject.pointsFactor = {
          factor: store_info.pointsSystemStoreSettings.dinein.apps.factor,
          order_type: "dinein",
          platform: "mobile",
        };
        pointsObject.pointsFactorUsed = true;
      } else if (
        deliveryMethod &&
        deliveryMethod === "altdinein" &&
        platform === "web" &&
        store_info.pointsSystemStoreSettings.altdinein &&
        store_info.pointsSystemStoreSettings.altdinein.web &&
        store_info.pointsSystemStoreSettings.altdinein.web.factor &&
        store_info.pointsSystemStoreSettings.altdinein.web.factor > 0
      ) {
        pointsObject.pointsFactor = {
          factor: store_info.pointsSystemStoreSettings.altdinein.web.factor,
          order_type: "altdinein",
          platform: "web",
        };
        pointsObject.pointsFactorUsed = true;
      } else if (
        deliveryMethod &&
        deliveryMethod === "altdinein" &&
        platform === "mobile" &&
        store_info.pointsSystemStoreSettings.altdinein &&
        store_info.pointsSystemStoreSettings.altdinein.apps &&
        store_info.pointsSystemStoreSettings.altdinein.apps.factor &&
        store_info.pointsSystemStoreSettings.altdinein.apps.factor > 0
      ) {
        pointsObject.pointsFactor = {
          factor: store_info.pointsSystemStoreSettings.altdinein.apps.factor,
          order_type: "altdinein",
          platform: "mobile",
        };
        pointsObject.pointsFactorUsed = true;
      }
    }
    return pointsObject;
  }

  calculateDisablePointRule(
    groupApp,
    store_info,
    points,
    platform,
    orderPickup,
    dineIn,
    selectedDineInTable
  ) {
    let pointDisabledRulesArr = [];

    const deliveryMethod = this.calculateDeliveryMethod(orderPickup, dineIn, {
      alt_dinein_active:
        store_info &&
        store_info.dinein &&
        store_info.alt_dinein_active &&
        selectedDineInTable &&
        selectedDineInTable.alt_dinein,
      selectedDineInTable: selectedDineInTable,
    });
    if (
      groupApp.pointsSystem &&
      !_.isEmpty(groupApp.points) &&
      !_.isEmpty(groupApp.points.rules) &&
      store_info.pointsSystemStoreSettings &&
      store_info.pointsSystemStoreSettings.active
    ) {
      if (
        deliveryMethod &&
        deliveryMethod === "delivery" &&
        store_info.pointsSystemStoreSettings.delivery &&
        store_info.pointsSystemStoreSettings.delivery.web &&
        store_info.pointsSystemStoreSettings.delivery.web.disablePointsEarn
      ) {
        pointDisabledRulesArr.push({
          order_type: "delivery",
          platform: "web",
        });
      }
      if (
        deliveryMethod &&
        deliveryMethod === "delivery" &&
        store_info.pointsSystemStoreSettings.delivery &&
        store_info.pointsSystemStoreSettings.delivery.apps &&
        store_info.pointsSystemStoreSettings.delivery.apps.disablePointsEarn
      ) {
        pointDisabledRulesArr.push({
          order_type: "delivery",
          platform: "mobile",
        });
      }
      if (
        deliveryMethod &&
        deliveryMethod === "takeaway" &&
        store_info.pointsSystemStoreSettings.takeaway &&
        store_info.pointsSystemStoreSettings.takeaway.web &&
        store_info.pointsSystemStoreSettings.takeaway.web.disablePointsEarn
      ) {
        pointDisabledRulesArr.push({
          order_type: "takeaway",
          platform: "web",
        });
      }
      if (
        deliveryMethod &&
        deliveryMethod === "takeaway" &&
        store_info.pointsSystemStoreSettings.takeaway &&
        store_info.pointsSystemStoreSettings.takeaway.apps &&
        store_info.pointsSystemStoreSettings.takeaway.apps.disablePointsEarn
      ) {
        pointDisabledRulesArr.push({
          order_type: "takeaway",
          platform: "mobile",
        });
      }
      if (
        deliveryMethod &&
        deliveryMethod === "dinein" &&
        platform === "web" &&
        store_info.pointsSystemStoreSettings.dinein &&
        store_info.pointsSystemStoreSettings.dinein.web &&
        store_info.pointsSystemStoreSettings.dinein.web.disablePointsEarn
      ) {
        pointDisabledRulesArr.push({
          order_type: "dinein",
          platform: "web",
        });
      }
      if (
        deliveryMethod &&
        deliveryMethod === "dinein" &&
        store_info.pointsSystemStoreSettings.dinein &&
        store_info.pointsSystemStoreSettings.dinein.apps &&
        store_info.pointsSystemStoreSettings.dinein.apps.disablePointsEarn
      ) {
        pointDisabledRulesArr.push({
          order_type: "dinein",
          platform: "mobile",
        });
      }
      if (
        deliveryMethod &&
        deliveryMethod === "altdinein" &&
        store_info.pointsSystemStoreSettings.altdinein &&
        store_info.pointsSystemStoreSettings.altdinein.web &&
        store_info.pointsSystemStoreSettings.altdinein.web.disablePointsEarn
      ) {
        pointDisabledRulesArr.push({
          order_type: "altdinein",
          platform: "web",
        });
      }
      if (
        deliveryMethod &&
        deliveryMethod === "altdinein" &&
        store_info.pointsSystemStoreSettings.altdinein &&
        store_info.pointsSystemStoreSettings.altdinein.apps &&
        store_info.pointsSystemStoreSettings.altdinein.apps.disablePointsEarn
      ) {
        pointDisabledRulesArr.push({
          order_type: "altdinein",
          platform: "mobile",
        });
      }
    }

    return this.filterPointsDisabledRulesArr(
      _.cloneDeep(pointDisabledRulesArr),
      platform
    );
  }

  filterPointsDisabledRulesArr(rulesArr, platform) {
    if (rulesArr && rulesArr.length) {
      const deliveryWeb = _.find(rulesArr, {
        order_type: "delivery",
        platform: "web",
      });
      const deliveryApps = _.find(rulesArr, {
        order_type: "delivery",
        platform: "mobile",
      });

      const takeawayWeb = _.find(rulesArr, {
        order_type: "takeaway",
        platform: "web",
      });
      const takeawayApps = _.find(rulesArr, {
        order_type: "takeaway",
        platform: "mobile",
      });

      const dineinWeb = _.find(rulesArr, {
        order_type: "dinein",
        platform: "web",
      });
      const dineinApps = _.find(rulesArr, {
        order_type: "dinein",
        platform: "mobile",
      });

      const altdineinWeb = _.find(rulesArr, {
        order_type: "altdinein",
        platform: "web",
      });
      const altdineinApps = _.find(rulesArr, {
        order_type: "altdinein",
        platform: "mobile",
      });

      if (deliveryWeb && deliveryApps) {
        rulesArr = _.filter(rulesArr, (rule) => {
          if (rule && rule.order_type !== "delivery") {
            return rule;
          }
        });

        rulesArr.push({
          order_type: "delivery",
          platform: "all",
        });
      }

      if (takeawayWeb && takeawayApps) {
        rulesArr = _.filter(rulesArr, (rule) => {
          if (rule && rule.order_type !== "takeaway") {
            return rule;
          }
        });

        rulesArr.push({
          order_type: "takeaway",
          platform: "all",
        });
      }

      if (dineinWeb && dineinApps) {
        rulesArr = _.filter(rulesArr, (rule) => {
          if (rule && rule.order_type !== "dinein") {
            return rule;
          }
        });

        rulesArr.push({
          order_type: "dinein",
          platform: "all",
        });
      }

      if (altdineinWeb && altdineinApps) {
        rulesArr = _.filter(rulesArr, (rule) => {
          if (rule && rule.order_type !== "altdinein") {
            return rule;
          }
        });

        rulesArr.push({
          order_type: "altdinein",
          platform: "all",
        });
      }
    }

    if (rulesArr && rulesArr.length > 1 && platform) {
      rulesArr = _.filter(rulesArr, (rule) => {
        if (rule.platform === platform) {
          return rule;
        }
      });
    }

    return _.cloneDeep(rulesArr);
  }

  filterPointsFactorRulesArr(rulesArr, storeInfo) {
    if (rulesArr && rulesArr.length) {
      const deliveryWeb = _.find(rulesArr, {
        order_type: "delivery",
        platform: "web",
      });
      const deliveryApps = _.find(rulesArr, {
        order_type: "delivery",
        platform: "mobile",
      });

      const takeawayWeb = _.find(rulesArr, {
        order_type: "takeaway",
        platform: "web",
      });
      const takeawayApps = _.find(rulesArr, {
        order_type: "takeaway",
        platform: "mobile",
      });

      const takeawayKiosk = _.find(rulesArr, {
        order_type: "takeaway",
        platform: "kiosk",
      });

      const dineinWeb = _.find(rulesArr, {
        order_type: "dinein",
        platform: "web",
      });
      const dineinApps = _.find(rulesArr, {
        order_type: "dinein",
        platform: "mobile",
      });

      const dineinKiosk = _.find(rulesArr, {
        order_type: "dinein",
        platform: "kiosk",
      });

      const altdineinWeb = _.find(rulesArr, {
        order_type: "altdinein",
        platform: "web",
      });
      const altdineinApps = _.find(rulesArr, {
        order_type: "altdinein",
        platform: "mobile",
      });

      const altdineinKiosk = _.find(rulesArr, {
        order_type: "altdinein",
        platform: "kiosk",
      });

      if (storeInfo && storeInfo.dm_kiosk_app_active) {
        if (
          deliveryWeb &&
          deliveryApps &&
          deliveryWeb.factor === deliveryApps.factor
        ) {
          const mergedFactor = _.cloneDeep(deliveryWeb.factor);
          rulesArr = _.filter(rulesArr, (rule) => {
            if (rule && rule.order_type !== "delivery") {
              return rule;
            }
          });

          rulesArr.push({
            factor: mergedFactor,
            order_type: "delivery",
            platform: "all",
          });
        }
        if (
          takeawayWeb &&
          takeawayApps &&
          takeawayKiosk &&
          takeawayWeb.factor === takeawayApps.factor &&
          takeawayWeb.factor === takeawayKiosk.factor
        ) {
          const mergedFactor = _.cloneDeep(takeawayWeb.factor);
          rulesArr = _.filter(rulesArr, (rule) => {
            if (rule && rule.order_type !== "takeaway") {
              return rule;
            }
          });

          rulesArr.push({
            factor: mergedFactor,
            order_type: "takeaway",
            platform: "all",
          });
        }

        if (
          dineinWeb &&
          dineinApps &&
          dineinKiosk &&
          dineinWeb.factor === dineinApps.factor &&
          dineinWeb.factor === dineinKiosk.factor
        ) {
          const mergedFactor = _.cloneDeep(dineinWeb.factor);
          rulesArr = _.filter(rulesArr, (rule) => {
            if (rule && rule.order_type !== "dinein") {
              return rule;
            }
          });

          rulesArr.push({
            factor: mergedFactor,
            order_type: "dinein",
            platform: "all",
          });
        }

        if (
          altdineinWeb &&
          altdineinApps &&
          altdineinKiosk &&
          altdineinWeb.factor === altdineinApps.factor &&
          altdineinWeb.factor === altdineinKiosk.factor
        ) {
          const mergedFactor = _.cloneDeep(altdineinWeb.factor);
          rulesArr = _.filter(rulesArr, (rule) => {
            if (rule && rule.order_type !== "altdinein") {
              return rule;
            }
          });

          rulesArr.push({
            factor: mergedFactor,
            order_type: "altdinein",
            platform: "all",
          });
        }
      } else {
        if (
          deliveryWeb &&
          deliveryApps &&
          deliveryWeb.factor === deliveryApps.factor
        ) {
          const mergedFactor = _.cloneDeep(deliveryWeb.factor);
          rulesArr = _.filter(rulesArr, (rule) => {
            if (rule && rule.order_type !== "delivery") {
              return rule;
            }
          });

          rulesArr.push({
            factor: mergedFactor,
            order_type: "delivery",
            platform: "all",
          });
        }

        if (
          takeawayWeb &&
          takeawayApps &&
          takeawayWeb.factor === takeawayApps.factor
        ) {
          const mergedFactor = _.cloneDeep(takeawayWeb.factor);
          rulesArr = _.filter(rulesArr, (rule) => {
            if (rule && rule.order_type !== "takeaway") {
              return rule;
            }
          });

          rulesArr.push({
            factor: mergedFactor,
            order_type: "takeaway",
            platform: "all",
          });
        }

        if (dineinWeb && dineinApps && dineinWeb.factor === dineinApps.factor) {
          const mergedFactor = _.cloneDeep(dineinWeb.factor);
          rulesArr = _.filter(rulesArr, (rule) => {
            if (rule && rule.order_type !== "dinein") {
              return rule;
            }
          });

          rulesArr.push({
            factor: mergedFactor,
            order_type: "dinein",
            platform: "all",
          });
        }

        if (
          altdineinWeb &&
          altdineinApps &&
          altdineinWeb.factor === altdineinApps.factor
        ) {
          const mergedFactor = _.cloneDeep(altdineinWeb.factor);
          rulesArr = _.filter(rulesArr, (rule) => {
            if (rule && rule.order_type !== "altdinein") {
              return rule;
            }
          });

          rulesArr.push({
            factor: mergedFactor,
            order_type: "altdinein",
            platform: "all",
          });
        }
      }
    }

    return _.cloneDeep(rulesArr);
  }

  getPointsFactorRulesArray(storeInfo) {
    let pointsFactorsRulesArr = [];
    if (storeInfo) {
      if (
        storeInfo.pointsSystemStoreSettings.delivery &&
        storeInfo.pointsSystemStoreSettings.delivery.web &&
        storeInfo.pointsSystemStoreSettings.delivery.web.disablePointsEarn &&
        storeInfo.pointsSystemStoreSettings.delivery.web.factor &&
        storeInfo.pointsSystemStoreSettings.delivery.web.factor > 0
      ) {
        pointsFactorsRulesArr.push({
          factor: storeInfo.pointsSystemStoreSettings.delivery.web.factor,
          order_type: "delivery",
          platform: "web",
        });
      }
      if (
        storeInfo.pointsSystemStoreSettings.delivery &&
        storeInfo.pointsSystemStoreSettings.delivery.apps &&
        !storeInfo.pointsSystemStoreSettings.delivery.apps.disablePointsEarn &&
        storeInfo.pointsSystemStoreSettings.delivery.apps.factor &&
        storeInfo.pointsSystemStoreSettings.delivery.apps.factor > 0
      ) {
        pointsFactorsRulesArr.push({
          factor: storeInfo.pointsSystemStoreSettings.delivery.apps.factor,
          order_type: "delivery",
          platform: "mobile",
        });
      }
      if (
        storeInfo.dm_kiosk_app_active &&
        !storeInfo.dm_kiosk_app_disable_mobileorder &&
        storeInfo.pointsSystemStoreSettings.delivery &&
        storeInfo.pointsSystemStoreSettings.delivery.kiosk &&
        !storeInfo.pointsSystemStoreSettings.delivery.kiosk.disablePointsEarn &&
        storeInfo.pointsSystemStoreSettings.delivery.kiosk.factor &&
        storeInfo.pointsSystemStoreSettings.delivery.kiosk.factor > 0
      ) {
        pointsFactorsRulesArr.push({
          factor: storeInfo.pointsSystemStoreSettings.delivery.apps.factor,
          order_type: "delivery",
          platform: "kiosk",
        });
      }

      if (
        storeInfo.pointsSystemStoreSettings.takeaway &&
        storeInfo.pointsSystemStoreSettings.takeaway.web &&
        !storeInfo.pointsSystemStoreSettings.takeaway.web.disablePointsEarn &&
        storeInfo.pointsSystemStoreSettings.takeaway.web.factor &&
        storeInfo.pointsSystemStoreSettings.takeaway.web.factor > 0
      ) {
        pointsFactorsRulesArr.push({
          factor: storeInfo.pointsSystemStoreSettings.takeaway.web.factor,
          order_type: "takeaway",
          platform: "web",
        });
      }
      if (
        storeInfo.pointsSystemStoreSettings.takeaway &&
        storeInfo.pointsSystemStoreSettings.takeaway.apps &&
        !storeInfo.pointsSystemStoreSettings.takeaway.apps.disablePointsEarn &&
        storeInfo.pointsSystemStoreSettings.takeaway.apps.factor &&
        storeInfo.pointsSystemStoreSettings.takeaway.apps.factor > 0
      ) {
        pointsFactorsRulesArr.push({
          factor: storeInfo.pointsSystemStoreSettings.takeaway.apps.factor,
          order_type: "takeaway",
          platform: "mobile",
        });
      }
      if (
        storeInfo.dm_kiosk_app_active &&
        !storeInfo.dm_kiosk_app_disable_mobileorder &&
        storeInfo.pointsSystemStoreSettings.takeaway &&
        storeInfo.pointsSystemStoreSettings.takeaway.kiosk &&
        !storeInfo.pointsSystemStoreSettings.takeaway.kiosk.disablePointsEarn &&
        storeInfo.pointsSystemStoreSettings.takeaway.kiosk.factor &&
        storeInfo.pointsSystemStoreSettings.takeaway.kiosk.factor > 0
      ) {
        pointsFactorsRulesArr.push({
          factor: storeInfo.pointsSystemStoreSettings.takeaway.kiosk.factor,
          order_type: "takeaway",
          platform: "kiosk",
        });
      }

      if (
        storeInfo.pointsSystemStoreSettings.dinein &&
        storeInfo.pointsSystemStoreSettings.dinein.web &&
        !storeInfo.pointsSystemStoreSettings.dinein.web.disablePointsEarn &&
        storeInfo.pointsSystemStoreSettings.dinein.web.factor &&
        storeInfo.pointsSystemStoreSettings.dinein.web.factor > 0
      ) {
        pointsFactorsRulesArr.push({
          factor: storeInfo.pointsSystemStoreSettings.dinein.web.factor,
          order_type: "dinein",
          platform: "web",
        });
      }
      if (
        storeInfo.pointsSystemStoreSettings.dinein &&
        storeInfo.pointsSystemStoreSettings.dinein.apps &&
        !storeInfo.pointsSystemStoreSettings.dinein.apps.disablePointsEarn &&
        storeInfo.pointsSystemStoreSettings.dinein.apps.factor &&
        storeInfo.pointsSystemStoreSettings.dinein.apps.factor > 0
      ) {
        pointsFactorsRulesArr.push({
          factor: storeInfo.pointsSystemStoreSettings.dinein.apps.factor,
          order_type: "dinein",
          platform: "mobile",
        });
      }
      if (
        storeInfo.dm_kiosk_app_active &&
        !storeInfo.dm_kiosk_app_disable_mobileorder &&
        storeInfo.pointsSystemStoreSettings.dinein &&
        storeInfo.pointsSystemStoreSettings.dinein.kiosk &&
        !storeInfo.pointsSystemStoreSettings.dinein.kiosk.disablePointsEarn &&
        storeInfo.pointsSystemStoreSettings.dinein.kiosk.factor &&
        storeInfo.pointsSystemStoreSettings.dinein.kiosk.factor > 0
      ) {
        pointsFactorsRulesArr.push({
          factor: storeInfo.pointsSystemStoreSettings.dinein.kiosk.factor,
          order_type: "dinein",
          platform: "kiosk",
        });
      }

      if (
        storeInfo.pointsSystemStoreSettings.altdinein &&
        storeInfo.pointsSystemStoreSettings.altdinein.web &&
        !storeInfo.pointsSystemStoreSettings.altdinein.web.disablePointsEarn &&
        storeInfo.pointsSystemStoreSettings.altdinein.web.factor &&
        storeInfo.pointsSystemStoreSettings.altdinein.web.factor > 0
      ) {
        pointsFactorsRulesArr.push({
          factor: storeInfo.pointsSystemStoreSettings.altdinein.web.factor,
          order_type: "altdinein",
          platform: "web",
        });
      }
      if (
        storeInfo.pointsSystemStoreSettings.altdinein &&
        storeInfo.pointsSystemStoreSettings.altdinein.apps &&
        !storeInfo.pointsSystemStoreSettings.altdinein.apps.disablePointsEarn &&
        storeInfo.pointsSystemStoreSettings.altdinein.apps.factor &&
        storeInfo.pointsSystemStoreSettings.altdinein.apps.factor > 0
      ) {
        pointsFactorsRulesArr.push({
          factor: storeInfo.pointsSystemStoreSettings.altdinein.apps.factor,
          order_type: "altdinein",
          platform: "mobile",
        });
      }
      if (
        storeInfo.dm_kiosk_app_active &&
        !storeInfo.dm_kiosk_app_disable_mobileorder &&
        storeInfo.pointsSystemStoreSettings.altdinein &&
        storeInfo.pointsSystemStoreSettings.altdinein.kiosk &&
        !storeInfo.pointsSystemStoreSettings.altdinein.kiosk
          .disablePointsEarn &&
        storeInfo.pointsSystemStoreSettings.altdinein.kiosk.factor &&
        storeInfo.pointsSystemStoreSettings.altdinein.kiosk.factor > 0
      ) {
        pointsFactorsRulesArr.push({
          factor: storeInfo.pointsSystemStoreSettings.altdinein.kiosk.factor,
          order_type: "altdinein",
          platform: "kiosk",
        });
      }
    }

    pointsFactorsRulesArr = this.filterPointsFactorRulesArr(
      _.cloneDeep(pointsFactorsRulesArr),
      storeInfo
    );

    return pointsFactorsRulesArr;
  }

  getPointsDisableRulesArray(storeInfo) {
    let pointsDisableRulesArr = [];
    if (storeInfo) {
      if (
        storeInfo.pointsSystemStoreSettings.delivery &&
        storeInfo.pointsSystemStoreSettings.delivery.web &&
        storeInfo.pointsSystemStoreSettings.delivery.web.disablePointsEarn
      ) {
        pointsDisableRulesArr.push({
          order_type: "delivery",
          platform: "web",
        });
      }
      if (
        storeInfo.pointsSystemStoreSettings.delivery &&
        storeInfo.pointsSystemStoreSettings.delivery.apps &&
        storeInfo.pointsSystemStoreSettings.delivery.apps.disablePointsEarn
      ) {
        pointsDisableRulesArr.push({
          order_type: "delivery",
          platform: "mobile",
        });
      }
      if (
        storeInfo.dm_kiosk_app_active &&
        !storeInfo.dm_kiosk_app_disable_mobileorder &&
        storeInfo.pointsSystemStoreSettings.delivery &&
        storeInfo.pointsSystemStoreSettings.delivery.kiosk &&
        storeInfo.pointsSystemStoreSettings.delivery.kiosk.disablePointsEarn
      ) {
        pointsDisableRulesArr.push({
          order_type: "delivery",
          platform: "kiosk",
        });
      }

      if (
        storeInfo.pointsSystemStoreSettings.takeaway &&
        storeInfo.pointsSystemStoreSettings.takeaway.web &&
        storeInfo.pointsSystemStoreSettings.takeaway.web.disablePointsEarn
      ) {
        pointsDisableRulesArr.push({
          order_type: "takeaway",
          platform: "web",
        });
      }
      if (
        storeInfo.pointsSystemStoreSettings.takeaway &&
        storeInfo.pointsSystemStoreSettings.takeaway.apps &&
        storeInfo.pointsSystemStoreSettings.takeaway.apps.disablePointsEarn
      ) {
        pointsDisableRulesArr.push({
          order_type: "takeaway",
          platform: "mobile",
        });
      }
      if (
        storeInfo.dm_kiosk_app_active &&
        !storeInfo.dm_kiosk_app_disable_mobileorder &&
        storeInfo.pointsSystemStoreSettings.takeaway &&
        storeInfo.pointsSystemStoreSettings.takeaway.kiosk &&
        storeInfo.pointsSystemStoreSettings.takeaway.kiosk.disablePointsEarn
      ) {
        pointsDisableRulesArr.push({
          order_type: "takeaway",
          platform: "kiosk",
        });
      }

      if (
        storeInfo.pointsSystemStoreSettings.dinein &&
        storeInfo.pointsSystemStoreSettings.dinein.web &&
        storeInfo.pointsSystemStoreSettings.dinein.web.disablePointsEarn
      ) {
        pointsDisableRulesArr.push({
          order_type: "dinein",
          platform: "web",
        });
      }
      if (
        storeInfo.pointsSystemStoreSettings.dinein &&
        storeInfo.pointsSystemStoreSettings.dinein.apps &&
        storeInfo.pointsSystemStoreSettings.dinein.apps.disablePointsEarn
      ) {
        pointsDisableRulesArr.push({
          order_type: "dinein",
          platform: "mobile",
        });
      }
      if (
        storeInfo.dm_kiosk_app_active &&
        !storeInfo.dm_kiosk_app_disable_mobileorder &&
        storeInfo.pointsSystemStoreSettings.dinein &&
        storeInfo.pointsSystemStoreSettings.dinein.kiosk &&
        storeInfo.pointsSystemStoreSettings.dinein.kiosk.disablePointsEarn
      ) {
        pointsDisableRulesArr.push({
          order_type: "dinein",
          platform: "kiosk",
        });
      }

      if (
        storeInfo.pointsSystemStoreSettings.altdinein &&
        storeInfo.pointsSystemStoreSettings.altdinein.web &&
        storeInfo.pointsSystemStoreSettings.altdinein.web.disablePointsEarn
      ) {
        pointsDisableRulesArr.push({
          order_type: "altdinein",
          platform: "web",
        });
      }
      if (
        storeInfo.pointsSystemStoreSettings.altdinein &&
        storeInfo.pointsSystemStoreSettings.altdinein.apps &&
        storeInfo.pointsSystemStoreSettings.altdinein.apps.disablePointsEarn
      ) {
        pointsDisableRulesArr.push({
          order_type: "altdinein",
          platform: "mobile",
        });
      }
      if (
        storeInfo.dm_kiosk_app_active &&
        !storeInfo.dm_kiosk_app_disable_mobileorder &&
        storeInfo.pointsSystemStoreSettings.altdinein &&
        storeInfo.pointsSystemStoreSettings.altdinein.kiosk &&
        storeInfo.pointsSystemStoreSettings.altdinein.kiosk.disablePointsEarn
      ) {
        pointsDisableRulesArr.push({
          order_type: "altdinein",
          platform: "kiosk",
        });
      }
    }

    pointsDisableRulesArr = this.filterPointsDisabledRulesArr(
      _.cloneDeep(pointsDisableRulesArr),
      null
    );

    return pointsDisableRulesArr && pointsDisableRulesArr.length
      ? pointsDisableRulesArr
      : null;
  }

  dineInAltDineinAlternativeNames(
    deliveryMethod,
    store_info,
    selectedLanguage,
    mo_base
  ) {
    if (deliveryMethod && deliveryMethod === "dinein") {
      if (
        store_info &&
        store_info.dinein_alternative_name &&
        selectedLanguage &&
        store_info.dinein_alternative_name[selectedLanguage]
      ) {
        deliveryMethod =
          "Dine In " + store_info.dinein_alternative_name[selectedLanguage];
      } else if (
        store_info &&
        store_info.dinein_alternative_name &&
        mo_base &&
        store_info.dinein_alternative_name[mo_base]
      ) {
        deliveryMethod =
          "Dine In " + store_info.dinein_alternative_name[mo_base];
      }
    } else if (deliveryMethod && deliveryMethod === "altdinein") {
      if (
        store_info &&
        store_info.alt_dinein_alternative_name &&
        selectedLanguage &&
        store_info.alt_dinein_alternative_name[selectedLanguage]
      ) {
        deliveryMethod =
          "Dine In " + store_info.alt_dinein_alternative_name[selectedLanguage];
      } else if (
        store_info &&
        store_info.alt_dinein_alternative_name &&
        mo_base &&
        store_info.alt_dinein_alternative_name[mo_base]
      ) {
        deliveryMethod =
          "Dine In " + store_info.alt_dinein_alternative_name[mo_base];
      }
    }

    return deliveryMethod;
  }
}
