<ion-row style="width: 100%" class="ion-no-padding">
  <ion-col size="12" class="ion-no-padding">
    <app-customer-info></app-customer-info>

    <app-delivery-method-address
      [cartMode]="true"
    ></app-delivery-method-address>
    <app-cart-preorder-item> </app-cart-preorder-item>

    <app-delivery-info
      *ngIf="currentUser && orderPickup == false && dineIn == false"
      size="12"
    ></app-delivery-info>

    <app-payment-methods></app-payment-methods>

    <app-extra-choices></app-extra-choices>

    <app-cart-points></app-cart-points>
    <app-add-coupon-code
      *ngIf="showAddCouponCodeComponent"
    ></app-add-coupon-code>
    <app-cart-active-coupons></app-cart-active-coupons>

    <app-checkbox-promotions></app-checkbox-promotions>
    <app-order-comments></app-order-comments>

    <app-cart-list></app-cart-list>
  </ion-col>
</ion-row>
