<ion-grid
  style="max-width: 95%"
  *ngIf="
    catalogDisclaimer &&
    selectedLanguage &&
    catalogDisclaimer.text &&
    catalogDisclaimer.title
  "
>
  <div
    [innerHTML]="
      catalogDisclaimer.text[selectedLanguage]
        ? catalogDisclaimer.text[selectedLanguage]
        : catalogDisclaimer.text[mo_base]
    "
  ></div>
</ion-grid>
