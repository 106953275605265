<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">
      {{ "popups.create-order-failed-preorder-modal.title" | translate }}
    </ion-title>
    <ion-button
      (click)="closeModal()"
      size="small"
      slot="end"
      fill="clear"
      color="dark"
    >
      <ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid *ngIf="loading">
    <ion-row style="width: 100%">
      <ion-col class="ion-text-center">
        <ion-spinner></ion-spinner>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid
    *ngIf="!loading && !error"
    class="ion-text-center"
    style="max-width: 200px"
  >
    <ng-lottie
      (animationCreated)="animationCreated($event)"
      [options]="deletedUserAnimationOptions"
    ></ng-lottie>
  </ion-grid>
  <ion-list *ngIf="!loading" class="ion-no-padding">
    <ion-item *ngIf="checkStoreOnlineMessage" lines="none">
      <ion-label
        class="ion-text-center ion-text-wrap"
        style="margin: 0; font-weight: bold; font-size: medium"
      >
        {{ checkStoreOnlineMessage }}
        <div *ngIf="!error">
          {{
            "popups.create-order-failed-preorder-modal.do-you-want-to-preorder"
              | translate
          }}
        </div>
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>

<ion-footer>
  <ion-grid *ngIf="!error && !loading">
    <ion-row style="width: 100%">
      <ion-col class="ion-text-center">
        <ion-button (click)="openPreorderModal()">{{
          "popups.create-order-failed-preorder-modal.preorder" | translate
        }}</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
