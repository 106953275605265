import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  Dimensions,
  ImageCroppedEvent,
  ImageTransform,
} from "ngx-image-cropper";
import { base64ToFile } from "ngx-image-cropper";
import { Subscription } from "rxjs";
import { auditTime, distinctUntilChanged, first } from "rxjs/operators";
import * as fromApp from "../store/app.reducer";
import * as _ from "lodash";
import {
  HttpBackend,
  HttpClient,
  HttpEventType,
  HttpHeaders,
} from "@angular/common/http";
import { DataStorageService } from "../services/data-storage/data-storage.service";
// import { uuidv4 } from "@firebase/util";
import { v4 as uuidv4 } from "uuid";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-photo-cropper",
  templateUrl: "./photo-cropper.component.html",
  styleUrls: ["./photo-cropper.component.scss"],
})
export class PhotoCropperComponent implements OnInit, OnDestroy {
  public selectedRatio: number;
  public selectedRatioString: string;
  @Input() itemType: string;
  public itemId: any;
  @Input() image: any;

  public selectedImgForCrop: any;
  public onSaveDisable: boolean;
  private subscriptions: Subscription[] = [];
  public item: any;
  public selectedCropImgOrigional: boolean;
  public selectedCropImgTransparentBackground: boolean;
  public storeAccount: any;
  public groupApp: any;
  private http: HttpClient;
  public uploadsProgressValues = {};
  subPlatformInfo: Subscription;
  isBrowserPlatform: boolean;
  windowDimensions: any;
  currentUser: any;
  moToken: any;
  uploadProgress: any;
  constructor(
    private store: Store<fromApp.AppState>,
    private DataStorageService: DataStorageService,
    private modalController: ModalController,
    handler: HttpBackend
  ) {
    this.http = new HttpClient(handler);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.windowDimensions &&
            !_.isEqual(this.windowDimensions, state.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && state.user && !_.isEqual(this.currentUser, state.user)) {
            this.currentUser = _.cloneDeep(state.user);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(this.groupApp, state.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
          }
        })
    );
  }

  imageChangedEvent: any = "";
  croppedImage: any = "";
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};

  onFileChanged(event) {
    this.imageChangedEvent = event;
  }

  imageLoaded() {
    this.showCropper = true;
    console.log("Image loaded");
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    console.log(base64ToFile(event.base64));
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    console.log("Cropper ready", sourceImageDimensions);
  }

  async uploadImageData(imgBlob, item, fileType, filename) {
    console.log("uploadImageData", imgBlob, item, fileType, filename);

    this.http
      .put(item.signedUrl, imgBlob, {
        reportProgress: true,
        observe: "events",
        headers: new HttpHeaders({
          "Content-Type": fileType,
        }),
      })
      .subscribe({
        next: (events) => {
          if (events.type == HttpEventType.UploadProgress) {
            this.uploadsProgressValues[filename] =
              parseFloat(
                Math.floor((events.loaded / events.total) * 100).toString()
              ) / 100;
            this.uploadProgress = this.uploadsProgressValues[filename];
            console.log(this.uploadsProgressValues);
          } else if (events.type === HttpEventType.Response) {
            console.log("uploadFiles response", events);
            if (events.status === 200) {
              console.log("complete");
              this.onSaveDisable = false;
              if (this.uploadsProgressValues[filename]) {
                delete this.uploadsProgressValues[filename];
              }
              this.modalController.dismiss();
              console.log("ifffloading", this.uploadsProgressValues);
            } else {
              console.log("falied");

              //TO_DO ERRO MESSAGE
              this.onSaveDisable = false;

              if (this.uploadsProgressValues[filename]) {
                delete this.uploadsProgressValues[filename];
              }
            }
          }
        },
        error: (error) => {
          console.log("error uploading", error);
          //TO_DO ERROR MESSAGE
          this.onSaveDisable = false;
          if (this.uploadsProgressValues[filename]) {
            delete this.uploadsProgressValues[filename];
          }
        },
      });
  }

  loadImageFailed() {
    console.log("Load failed");
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut() {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  zoomIn() {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  Saveimage() {
    this.onSaveDisable = true;

    let file: any = base64ToFile(this.croppedImage);
    let filetype: any;
    let directory: any;
    if (file.type === "image/png") {
      filetype = "png";
    } else if (file.type === "image/jpeg") {
      filetype = "jpg";
    } else {
      return;
      //TO_TO present Error message
    }

    console.log(file);
    const fileName = uuidv4() + "." + filetype;

    // filename = this.selectedImgForCrop.imageName;

    directory =
      this.groupApp.group + "/" + this.currentUser.customer_id + "/" + fileName;

    console.log("directory", directory);

    console.log(this.moToken);
    this.DataStorageService.handleImageUpload(fileName, directory).subscribe(
      (res: any) => {
        if (res.success) {
          console.log("successHandleUpload", fileName, directory);
          this.uploadImageData(file, res.data, file.type, fileName);
        } else {
          this.onSaveDisable = false;
          //TO_DO ERRO MESSAGE
        }
      }
    );
  }

  // public calculateItemId() {
  //   if (this.itemType === "categories") {
  //     this.itemId = this.category_id;
  //   } else if (this.itemType === "products") {
  //     this.itemId = this.product_id;
  //   } else if (this.itemType === "choices") {
  //     this.itemId = this.option_id_choice_id;
  //   }
  // }
  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    this.imageChangedEvent = null;
  }
}
