import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ReservationDateInfoPage } from "../reservation-date-info/reservation-date-info.page";
import {
  AlertController,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { ReservationCompletedDateInfoPage } from "../reservation-completed-date-info/reservation-completed-date-info.page";
import { AnalyticsServiceService } from "src/app/services/analitycsService/analytics-service.service";
import _ from "lodash";
import { Store } from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";
import * as AuthActions from "../../auth/store/auth.actions";
import { FirebaseBrowserService } from "src/app/services/firebaseBrowser/firebase-browser.service";
import { TranslateService } from "@ngx-translate/core";
import { Subscription, auditTime, distinctUntilChanged, first } from "rxjs";
import { getAuth, RecaptchaVerifier } from "firebase/auth";
import { FirebaseRecaptchaLoginReservationActiveComponent } from "src/app/popups/firebase-recaptcha-login-reservation-active/firebase-recaptcha-login-reservation-active.component";
import { LoginPopupsServiceService } from "src/app/services/login-popups-service/login-popups-service.service";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import { AnimationOptions } from "ngx-lottie";
import { Router } from "@angular/router";

import * as reservationActions from "../../reservations/store/reservations.actions";

declare let window: any;

@Component({
  selector: "app-reservation-my-dates",
  templateUrl: "./reservation-my-dates.page.html",
  styleUrls: ["./reservation-my-dates.page.scss"],
})
export class ReservationMyDatesPage {
  @ViewChild("mobileInput", { static: false }) mobileInput;
  @ViewChild("inputMobileRef", { read: ElementRef }) inputMobileRef: ElementRef;

  public loading: boolean = false;
  public mobile: any;
  public selectedLanguage: string;
  public verified = false;
  public typeOfRecaptcha: string; //normal or invisible
  public reacaptchaLoaded = false;
  public enterKey: any;
  public isPhoneNumberValid: boolean = false;
  public isCurrentsEnabled: boolean = true;
  public isCompletedEnabled: boolean = false;
  public loginState: any;
  public animationLogin: boolean;
  public reservations: any;
  public activeReservations: any[];
  public completedReservations: any[];
  public colSize: number;
  public windowDimensions: any;
  public currentUser: any;
  public webp: any;
  public ReservationCompletedDateInfoModal;
  public ReservationDateInfoModal;
  options2: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/login-reservations-animation.json",
  };
  options: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/success-animation.json",
  };

  noReservationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/no-reservations-animation-1.json",
  };

  public storesByStoreId: any = {};

  private mo_token: any;
  private newCustomer: any;
  private animationLoginInstance: any;
  private stores: any;
  private unsubscribeBackEvent: Subscription;
  public groupApp: any;
  private subscriptions: Subscription[] = [];

  constructor(
    public modalCtrl: ModalController,
    private store: Store<fromApp.AppState>,
    private toastController: ToastController,
    private translateService: TranslateService,
    private loginPopupsService: LoginPopupsServiceService,
    private alertController: AlertController,
    private dataStorageService: DataStorageService,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private platform: Platform
  ) {}

  ionViewDidEnter() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      100,
      () => {
        if (this.platform.is("android")) {
          this.navigateHome();
        }
      }
    );
    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(auditTime(50))
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(this.groupApp, state.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
          }
          if (state && state.webp && !_.isEqual(this.webp, state.webp)) {
            this.webp = _.cloneDeep(state.webp);
          }
          if (
            state &&
            state.selectedLangugage &&
            !_.isEqual(this.selectedLanguage, state.selectedLangugage)
          ) {
            this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && state.stores && !_.isEqual(this.stores, state.stores)) {
            this.stores = _.cloneDeep(state.stores);
            this.storesByStoreId = {};

            _.each(this.stores, (store) => {
              this.storesByStoreId[store.store_id] = _.cloneDeep(store);
            });
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.windowDimensions, state.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
            // [size]="windowDimensions.width > 600 ? '6': '12'"
            if (
              this.windowDimensions.width > 600 &&
              this.windowDimensions.width < 1100
            ) {
              this.colSize = 6;
            } else if (this.windowDimensions.width < 600) {
              this.colSize = 12;
            } else {
              this.colSize = 6;
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("reservations")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && !_.isEqual(this.reservations, state.reservations)) {
            this.reservations = _.cloneDeep(state.reservations);
            if (this.reservations) {
              const now = Date.now();
              this.activeReservations = _.orderBy(
                _.filter(
                  this.reservations,
                  (res) =>
                    res.res_status !== "rejected" &&
                    res.res_status !== "canceled" &&
                    (parseInt(res.dateEnd) > now || !res.payment_id)
                ),
                (res) => parseInt(res.date),
                "desc"
              );
              this.completedReservations = _.orderBy(
                _.reject(
                  this.reservations,
                  (res) =>
                    res.res_status !== "rejected" &&
                    res.res_status !== "canceled" &&
                    (parseInt(res.dateEnd) > now || !res.payment_id)
                ),
                (res) => parseInt(res.date),
                "desc"
              );
            }
          }
        })
    );
    this.authSubscription();
  }

  animationCreated(ev) {
    this.animationLoginInstance = ev;
    this.animationLoginInstance.setSpeed(0.8);
  }
  animationComplete(ev) {
    this.animationLoginInstance.stop();
    this.animationLogin = false;
    this.changeDetector.detectChanges();
  }

  enableCurrents() {
    this.isCurrentsEnabled = true;
    this.isCompletedEnabled = false;
  }

  enableCompleted() {
    this.isCurrentsEnabled = false;
    this.isCompletedEnabled = true;
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  navigateHome() {
    this.router.navigate(["homepage"]);
  }

  onPhoneNumberChange() {
    // Remove all non-numeric characters
    const numericValue = this.mobile.replace(/[^0-9]/g, "");

    // Limit to 10 characters
    const limitedValue = numericValue.slice(0, 10);

    // Update the ngModel bound variable
    this.mobile = limitedValue;

    // Check if the input contains exactly 10 numbers
    this.isPhoneNumberValid = limitedValue.length === 10;
  }

  async presentModal() {
    this.loginPopupsService.showFirebaseModalLogin(null, null);
  }

  async completedDateInfo(item: any) {
    this.ReservationCompletedDateInfoModal = await this.modalCtrl.create({
      component: ReservationCompletedDateInfoPage,
      cssClass: "fullScreenModal",
      animated: true,
      backdropDismiss: false,
      componentProps: {
        item,
      },
    });
    await this.ReservationCompletedDateInfoModal.present();
  }

  async openDate(item: any) {
    this.ReservationDateInfoModal = await this.modalCtrl.create({
      component: ReservationDateInfoPage,
      cssClass: "fullScreenModal",
      animated: true,
      backdropDismiss: false,
      componentProps: {
        item,
      },
    });
    await this.ReservationDateInfoModal.present();
  }

  authSubscription() {
    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(auditTime(200))
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.mo_token &&
            !_.isEqual(this.mo_token, state.mo_token)
          ) {
            this.mo_token = _.cloneDeep(state.mo_token);
            if (this.mo_token) {
              //TODO CHECK TO DELETE THIS
              // this.store
              //   .select("groupApp")
              //   .pipe(first())
              //   .subscribe((groupAppState) => {
              //     this.store
              //       .select("currentStore")
              //       .pipe(first())
              //       .subscribe((currentStoreState) => {
              //         this.fetchLoyaltyAppCouponRules(
              //           state.mo_token,
              //           currentStoreState.currentStoreId,
              //           false,
              //           groupAppState.groupApp
              //             ? groupAppState.groupApp.group
              //             : null
              //         );
              //       })
              //       .unsubscribe();
              //   });
            }
          }
          if (state && !_.isEqual(state.loginState, this.loginState)) {
            this.loginState = _.cloneDeep(state.loginState);
            if (this.loginState && this.loginState === "login_completed") {
              this.fetchReservations();
            }
            //TODO CHECK TO DELETE THIS
            // if (this.loginState === "no_login") {
            //   this.store
            //     .select("groupApp")
            //     .pipe(first())
            //     .subscribe((groupAppState) => {
            //       this.store
            //         .select("currentStore")
            //         .pipe(first())
            //         .subscribe((currentStoreState) => {
            //           console.log("fetch coupon rules for no user");
            //           this.fetchLoyaltyAppCouponRules(
            //             state.mo_token,
            //             currentStoreState.currentStoreId,
            //             true,
            //             groupAppState.groupApp
            //               ? groupAppState.groupApp.group
            //               : null
            //           );
            //         })
            //         .unsubscribe();
            //     })
            //     .unsubscribe();
            // }
          }

          if (state && state.user && !_.isEqual(state.user, this.currentUser)) {
            this.currentUser = _.cloneDeep(state.user);
            if (state.freshLogin) {
              this.animationLogin = true;
              this.store.dispatch(new AuthActions.SetFreshLogin(false));
            }
          }
          if (state && !_.isEqual(state.newCustomer, this.newCustomer)) {
            this.newCustomer = _.cloneDeep(state.newCustomer);
          }
        })
    );
  }

  fetchReservations() {
    this.loading = true;

    this.dataStorageService
      .fetchReservations(
        this.mo_token,
        this.groupApp.group,
        this.groupApp ? this.groupApp.active_reservation_app : null
      )
      .subscribe({
        next: async (res: any) => {
          this.loading = false;
          if (!res || !res.success) {
            const alert = await this.alertController.create({
              header: this.translateService.instant("alert"),
              message:
                res && res.comment_id
                  ? this.translateService.instant(res.comment_id)
                  : this.translateService.instant(
                      "errorMessages.general_error"
                    ),
              backdropDismiss: false,
              buttons: ["OK"],
            });
            this.loading = false;
            await alert.present();
          } else {
            this.store.dispatch(
              new reservationActions.SetReservations(res.reservations)
            );

            this.loading = false;
          }
        },
        error: async (error) => {
          this.loading = false;
          const alert = await this.alertController.create({
            header: this.translateService.instant("alert"),
            message: this.translateService.instant(
              "errorMessages.problem_reaching_server"
            ),

            buttons: ["OK"],
          });
          await alert.present();
        },
      });
  }

  fetchLoyaltyAppCouponRules(token, store_id, no_user, group) {
    //TODO DO WE NEED LOYALTY APP COUPON RULES ????
    // console.log("fetchLoyaltyAppCouponRules");
    // (no_user
    //   ? this.dataStorageService.getNoUserLoyaltyAppCouponRules(store_id, group)
    //   : this.dataStorageService.getLoyaltyAppCustomerCouponRules(
    //       token,
    //       store_id
    //     )
    // ).subscribe({
    //   next: (res: any) => {
    //     if (res && res.success) {
    //       console.log("success", res);
    //       this.customerLoyaltyCouponRules = res.coupon_rules;
    //     } else {
    //       if (res.comment_id) {
    //         let errorMsg = this.translateService.instant(
    //           "errorMessages-an-error-occurred"
    //         );
    //         this.presentErrorToast(errorMsg, "danger");
    //       } else {
    //         this.presentErrorToast(res.comments, "danger");
    //       }
    //     }
    //   },
    //   error: async (err) => {
    //     const alert = await this.alertController.create({
    //       header: this.translateService.instant("alert"),
    //       message: this.translateService.instant(
    //         "errorMessages.problem_reaching_server"
    //       ),
    //       buttons: ["OK"],
    //     });
    //     await alert.present();
    //     return err;
    //   },
    // });
  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }
  ionViewDidLeave() {
    this.currentUser = null;
    this.mo_token = null;
    this.loginState = null;

    _.each(this.subscriptions, (sub) => {
      if (sub) {
        sub.unsubscribe();
      }
    });

    this.subscriptions = [];

    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }

    if (this.ReservationCompletedDateInfoModal) {
      this.ReservationCompletedDateInfoModal.dismiss();
      this.ReservationCompletedDateInfoModal = null;
    }

    if (this.ReservationDateInfoModal) {
      this.ReservationDateInfoModal.dismiss();
      this.ReservationDateInfoModal = null;
    }
  }
}
