<ion-fab
  *ngIf="windowDimensions && fabView"
  [hidden]="!showSearchFab && forMobile"
  [class]="
    showSearchFab && forMobile && !hideSearchFab
      ? 'animate__animated animate__backInRight'
      : ''
  "
  vertical="bottom"
  horizontal="end"
  slot="fixed"
>
  <ion-fab-button
    [size]="
      windowDimensions &&
      windowDimensions.width &&
      windowDimensions.width <= 360
        ? 'small'
        : 'medium'
    "
    (click)="openSearchModal()"
  >
    <ion-icon src="assets/ionicons/search-outline.svg"></ion-icon>
  </ion-fab-button>
</ion-fab>

<ion-fab-button
  *ngIf="windowDimensions && !fabView && !scrollBottomBrowserList"
  (click)="openSearchModal()"
  color="primary"
  fill="solid"
  size="small"
  shape="round"
>
  <ion-icon src="assets/ionicons/search-outline.svg"></ion-icon>
</ion-fab-button>
