import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../store/app.reducer";
import * as selectors from "../../../store/app.selectors";
import * as HyperCategoriesActions from "../../../store/hyperCategories/hyperCategories.actions";

import * as _ from "lodash";
import { audit, auditTime, distinctUntilChanged } from "rxjs/operators";
import { Subscription } from "rxjs";

@Component({
  selector: "app-vertical-hyper-categories",
  templateUrl: "./vertical-hyper-categories.component.html",
  styleUrls: ["./vertical-hyper-categories.component.scss"],
})
export class VerticalHyperCategoriesComponent implements OnInit, OnDestroy {
  public hyperCategories: any;
  public allCategories: any;
  public storeInfo: any;
  public icons_hyper_categories_design: boolean;
  private subscription: Subscription;
  private storeSubscription: Subscription;
  public hyperCategoryIconsLink =
    "https://data.deliverymanager.gr/hyperCategoriesIcons/";
  public selectedHyperCategory: any;

  constructor(private store: Store<fromApp.AppState>) {}

  ngOnInit() {
    this.storeSubscription = this.store
      .select(selectors.getStoreInfo)
      .pipe(distinctUntilChanged())
      .subscribe((storeInfo) => {
        if (storeInfo && !_.isEqual(this.storeInfo, storeInfo)) {
          this.storeInfo = _.cloneDeep(storeInfo);
          this.icons_hyper_categories_design = !!_.cloneDeep(
            this.storeInfo.mobileorder_icons_hyper_categories_design
          );
        }
      });

    this.subscription = this.store
      .select("hyperCategories")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (
          state &&
          state.hyperCategories &&
          !_.isEqual(this.hyperCategories, state.hyperCategories)
        ) {
          this.hyperCategories = _.cloneDeep(state.hyperCategories);
        }
        if (
          state &&
          state.currentHyperCategory &&
          !_.isEqual(this.selectedHyperCategory, state.currentHyperCategory)
        ) {
          this.selectedHyperCategory = _.cloneDeep(state.currentHyperCategory);
        }
      });
  }

  nameToLowerCase(name) {
    return name.toLowerCase();
  }

  trackFn(inx) {
    return inx;
  }

  HyperCategorySelection(hyperCat) {
    if (hyperCat) {
      this.store.dispatch(
        new HyperCategoriesActions.SetCurrentHyperCategory(
          _.cloneDeep(hyperCat.hyper_category_id)
        )
      );

      if (navigator.cookieEnabled) {
        localStorage.setItem(
          "selectedHyperCategory",
          hyperCat.hyper_category_id
        );
      }
    } else {
      this.store.dispatch(
        new HyperCategoriesActions.SetCurrentHyperCategory(_.cloneDeep(null))
      );
      if (navigator.cookieEnabled) {
        if (localStorage.getItem("selectedHyperCategory")) {
          localStorage.removeItem("selectedHyperCategory");
        }
      }
    }
  }

  ngOnDestroy() {
    // this.selectedHyperCategory = null;
    // this.store.dispatch(
    //   new HyperCategoriesActions.SetCurrentHyperCategory(_.cloneDeep(null))
    // );
    if (this.storeSubscription) {
      this.storeSubscription.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
