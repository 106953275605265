<ion-list *ngIf="option && option.option_id" [id]="option.option_id">
  <ion-item *ngIf="option && option.title && option.always_open" lines="none">
    <ion-row style="width: 100%">
      <ion-col size="12" class="ion-no-padding">
        <ion-label
          class="ion-text-wrap"
          [class]="
            optionValidationObject && optionValidationObject.valid == false
              ? 'notValidOptionMargin'
              : ''
          "
        >
          <strong>
            {{ option.title }}
          </strong>
          <p
            style="font-size: smaller"
            color="danger"
            *ngIf="
              option_stepper_mode &&
              option.dependenciesVariable &&
              option.dependenciesVariable.length > 0 &&
              !option.dependentHidden &&
              !option.showDependent
            "
          >
            <ion-text color="danger">
              {{
                "product-details.options.checkbox-options.based-on-your-choices-you-cant-edit-this-option"
                  | translate
              }}</ion-text
            >
          </p>

          <span
            *ngIf="option.is_require && option.availableTotal"
            style="font-weight: bold"
            >*</span
          >

          <p *ngIf="option && option.description">{{ option.description }}</p>
          <div
            *ngIf="option && option.freeChoices && option.freeChoices !== '0'"
            ng-class="{
              redColor: option.optionData.freeChoices !== option.freeChoices
            }"
            style="font-size: small; font-weight: 700"
          >
            {{
              "product-details.options.checkbox-options.there-are-free-choices"
                | translate
            }}: ({{
              option.optionData && option.optionData.freeChoices
                ? option.optionData.freeChoices
                : 0
            }}
            / {{ option.freeChoices }})
          </div>

          <div
            style="font-size: small; font-weight: bold"
            size="12"
            *ngIf="
              option && option.freeChoices && option.freeChoicesBySelectionOrder
            "
          >
            <ion-text color="danger">
              {{
                "product-details.options.free-options-by-selections-order"
                  | translate
              }}
            </ion-text>
          </div>

          <div
            *ngIf="
              option &&
              option.is_require &&
              option.minChoices &&
              option.minChoices !== '0' &&
              option.minChoices !== '1' &&
              option.maxChoices !== option.minChoices
            "
            ng-class="{
              redColor: option.optionData.minChoices !== option.minChoices
            }"
            style="font-size: small; font-weight: 700"
          >
            {{
              "product-details.options.checkbox-options.minimum-choices-required"
                | translate
            }}: ({{
              option.optionData && option.optionData.minChoices
                ? option.optionData.minChoices
                : 0
            }}
            / {{ option.minChoices }})
          </div>

          <div
            *ngIf="
              option &&
              option.maxChoices &&
              option.maxChoices !== '0' &&
              option.maxChoices !== option.minChoices
            "
            ng-class="{
              redColor: option.optionData.maxChoices !== option.maxChoices
            }"
            style="font-size: small; font-weight: 700"
          >
            {{
              "product-details.options.checkbox-options.maximum-choices-allowed"
                | translate
            }}: ({{
              option.optionData && option.optionData.maxChoices
                ? option.optionData.maxChoices
                : 0
            }}
            / {{ option.maxChoices }})
          </div>

          <div
            *ngIf="
              option &&
              option.is_require &&
              option.minChoices &&
              option.minChoices !== '0' &&
              option.minChoices !== '1' &&
              option.maxChoices &&
              option.maxChoices !== '0' &&
              option.maxChoices === option.minChoices
            "
            ng-class="{
              redColor: option.optionData.minChoices !== option.minChoices
            }"
            style="font-size: small; font-weight: 700"
          >
            {{
              "product-details.options.checkbox-options.choices-required"
                | translate
            }}: ({{
              option.optionData && option.optionData.minChoices
                ? option.optionData.minChoices
                : 0
            }}
            / {{ option.minChoices }})
          </div>
          <div
            *ngIf="
              option &&
              option.is_require &&
              option.minChoices &&
              option.minChoices !== '0' &&
              option.minChoices !== '1'
            "
            ng-class="{
              redColor: option.optionData.minChoices !== option.minChoices
            }"
            style="font-size: small; font-weight: 700"
          >
            {{
              "product-details.options.checkbox-options.minimum-choices-required"
                | translate
            }}: ({{
              option.optionData && option.optionData.minChoices
                ? option.optionData.minChoices
                : 0
            }}
            / {{ option.minChoices }})
          </div>
          <div
            *ngIf="option && option.maxChoices && option.maxChoices !== '0'"
            ng-class="{
              redColor: option.optionData.maxChoices !== option.maxChoices
            }"
            style="font-size: small; font-weight: 700"
          >
            {{
              "product-details.options.checkbox-options.maximum-choices-allowed"
                | translate
            }}: ({{
              option.optionData && option.optionData.maxChoices
                ? option.optionData.maxChoices
                : 0
            }}
            / {{ option.maxChoices }})
          </div>
        </ion-label>
      </ion-col>
      <ion-col
        size="12"
        class="ion-no-padding"
        *ngIf="
          option.promo || option.pickup || option.tags || !option.availableTotal
        "
      >
        <app-product-option-tags
          [selectedProductPromo]="selectedProductPromo"
          [groupStoreData]="groupStoreData"
          [optionItem]="option"
        ></app-product-option-tags>
      </ion-col>

      <ion-col
        *ngIf="
          optionValidationObject && optionValidationObject.errorArray.length
        "
        size="12"
        class="ion-text-right"
      >
        <ion-badge color="danger">
          <ion-text style="font-size: smaller">
            {{ optionValidationObject.errorArray[0] }}
          </ion-text></ion-badge
        ></ion-col
      >
      <ion-col
        class="ion-text-right"
        size="12"
        *ngIf="
          selectedProductFreeParentOption &&
          (!option.dependenciesVariable ||
            option.dependenciesVariable.length === 0) &&
          option.parent_free &&
          option.option_parent_total_price === 0
        "
      >
        <ion-badge color="danger"
          ><ion-text>
            {{ "product-details.options.free" | translate }}
          </ion-text></ion-badge
        >
      </ion-col>
      <ion-col
        class="ion-text-right"
        size="12"
        *ngIf="
          selectedProductFreeParentOption &&
          (!option.dependenciesVariable ||
            option.dependenciesVariable.length === 0) &&
          option.parent_free &&
          option.option_parent_total_price &&
          option.option_total_price &&
          toFloat(option.option_total_price) >
            toFloat(option.option_parent_total_price)
        "
      >
        <ion-badge color="danger">
          <ion-text>
            {{ option.option_parent_total_price }}€ ({{
              selectedProductParentDiscount
            }}% {{ "discount" | translate }})
          </ion-text>
        </ion-badge>
      </ion-col>
    </ion-row>
  </ion-item>

  <app-radio-option-open-choices
    *ngIf="option && option.always_open"
    [option_id]="option_id"
    [selectedProductCategory]="selectedProductCategory"
    [selectedProductPromo]="selectedProductPromo"
    [selectedProductId]="selectedProductId"
    [productName]="productName"
    [bundleMode]="bundleMode"
    [groupStoreData]="groupStoreData"
    (optionChange)="onOptionChanged($event)"
  ></app-radio-option-open-choices>
  <app-radio-option-closed-choices
    *ngIf="option && !option.always_open"
    [option_id]="option_id"
    [product_id]="product_id"
    [optionValidationObject]="optionValidationObject"
    [selectedProductCategory]="selectedProductCategory"
    [selectedProductPromo]="selectedProductPromo"
    [selectedProductId]="selectedProductId"
    [productName]="productName"
    [selectedProductFreeParentOption]="selectedProductFreeParentOption"
    [selectedProductParentDiscount]="selectedProductParentDiscount"
    [bundleMode]="bundleMode"
    [groupStoreData]="groupStoreData"
    (optionChange)="onOptionChanged($event)"
  ></app-radio-option-closed-choices>
</ion-list>
