import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { CatalogSearchModalComponent } from "./catalog-search-modal/catalog-search-modal.component";
import * as fromApp from "../../store/app.reducer";
import { distinctUntilChanged, Subscription } from "rxjs";
import * as _ from "lodash";

@Component({
  selector: "app-catalog-search-component",
  templateUrl: "./catalog-search-component.component.html",
  styleUrls: ["./catalog-search-component.component.scss"],
})
export class CatalogSearchComponentComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() fabView: any;
  @Input() forMobile: boolean;
  @Input() scrollFromTop: any;
  @Input() scrollFromBottom: any;
  @Input() scrollBottomBrowserList: boolean;
  private searchModal: any;
  public hideSearchFab: boolean;
  public showSearchFab: any;
  public windowDimensions: any;
  private subscription: Subscription;
  constructor(
    private modalCtrl: ModalController,
    private store: Store<fromApp.AppState>
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.scrollFromTop && this.forMobile) {
      //console.log("changes scroll", this.scrollFromTop, this.scrollFromBottom);
      this.calculateForHideDebounce();
    }
  }

  calculateForHideDebounce = _.debounce(() => {
    this.calculateForHide();
  }, 300);

  calculateForHide() {
    if (
      this.windowDimensions &&
      this.windowDimensions.width &&
      this.windowDimensions.width < 620
    ) {
      if (this.scrollFromTop && this.scrollFromBottom < 50) {
        this.showSearchFab = false;
      } else {
        this.showSearchFab = true;
      }
    } else {
      this.showSearchFab = true;
    }
  }

  ngOnInit() {
    this.subscription = this.store
      .select("windowInfo")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.windowDimensions &&
          !_.isEqual(state.windowDimensions, this.windowDimensions)
        ) {
          this.windowDimensions = _.cloneDeep(state.windowDimensions);
        }
      });

    setTimeout(() => {
      this.showSearchFab = true;
    }, 350);
  }

  openSearchModal() {
    if (!this.searchModal) {
      this.searchModal = true;
      this.showSearchModal();
    }
  }

  async showSearchModal() {
    this.searchModal = await this.modalCtrl.create({
      component: CatalogSearchModalComponent,
      cssClass: "catalog-search-modal",
      backdropDismiss: false,
    });

    await this.searchModal.present();
    this.searchModal.onDidDismiss().then(() => {
      this.searchModal = null;
    });
  }

  ngOnDestroy() {
    if (this.searchModal) {
      this.searchModal.dismiss();
      this.searchModal = null;
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
