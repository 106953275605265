import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./auth/auth.guard";
import { BlockLoyaltyAppGuard } from "./auth/block-loyalty-app.guard";
import { LoyaltyAppGuard } from "./auth/loyalty-app.guard";
import { LoyaltySystemPage } from "./loyalty-system/loyalty-system.page";
import { kioskGuard } from "./kiosk/kiosk-guard.guard";
import { ReservationAppGuard } from "./auth/reservation-app.guard";
import { BlockReservationAppGuard } from "./auth/block-reservation-app-.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "homepage",
    pathMatch: "full",
  },
  {
    path: "app/categoriesBrowser",
    redirectTo: "catalog",
    pathMatch: "full",
  },
  {
    path: "app/cart",
    redirectTo: "cart",
    pathMatch: "full",
  },
  {
    path: "app/home",
    redirectTo: "homepage",
    pathMatch: "full",
  },
  {
    path: "homepage",
    canActivate: [LoyaltyAppGuard, ReservationAppGuard],
    loadChildren: () =>
      import("./homepage/homepage.module").then((m) => m.HomepagePageModule),
  },
  {
    path: "homepage/:params",
    canActivate: [LoyaltyAppGuard, ReservationAppGuard],
    loadChildren: () =>
      import("./homepage/homepage.module").then((m) => m.HomepagePageModule),
  },
  {
    path: "catalog",
    canActivate: [LoyaltyAppGuard, ReservationAppGuard],
    loadChildren: () =>
      import("./catalog/catalog.module").then((m) => m.CatalogPageModule),
  },
  {
    path: "catalog/:store_id",
    canActivate: [LoyaltyAppGuard, ReservationAppGuard],
    loadChildren: () =>
      import("./catalog/catalog.module").then((m) => m.CatalogPageModule),
  },
  {
    path: "deliveryAreas",
    canActivate: [LoyaltyAppGuard, ReservationAppGuard],
    loadChildren: () =>
      import("./delivery-areas/delivery-areas.module").then(
        (m) => m.DeliveryAreasPageModule
      ),
  },
  {
    path: "loyaltySystem",
    canActivate: [BlockLoyaltyAppGuard],
    loadChildren: () =>
      import("./loyalty-system/loyalty-system.module").then(
        (m) => m.LoyaltySystemPageModule
      ),
  },
  {
    path: "reservationSystem",
    canActivate: [BlockReservationAppGuard],
    loadChildren: () =>
      import("./reservation-system/reservation-system.module").then(
        (m) => m.ReservationSystemPageModule
      ),
  },
  {
    path: "storeSchedule",
    canActivate: [LoyaltyAppGuard, ReservationAppGuard],
    loadChildren: () =>
      import("./store-schedule/store-schedule.module").then(
        (m) => m.StoreSchedulePageModule
      ),
  },
  {
    path: "orders",
    canActivate: [LoyaltyAppGuard, ReservationAppGuard],
    loadChildren: () =>
      import("./orders/orders.module").then((m) => m.OrdersPageModule),
  },
  {
    path: "orders/:store_id/:order_id",
    canActivate: [LoyaltyAppGuard, ReservationAppGuard],
    loadChildren: () =>
      import("./orders/orders.module").then((m) => m.OrdersPageModule),
  },

  {
    path: "ratings",
    canActivate: [LoyaltyAppGuard, ReservationAppGuard],
    loadChildren: () =>
      import("./ratings/ratings.module").then((m) => m.RatingsPageModule),
  },
  {
    path: "payment-cards",
    canActivate: [LoyaltyAppGuard, ReservationAppGuard],
    loadChildren: () =>
      import("./payment-cards/payment-cards.module").then(
        (m) => m.PaymentCardsPageModule
      ),
  },
  {
    path: "account-settings",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./account-settings/account-settings.module").then(
        (m) => m.AccountSettingsPageModule
      ),
  },
  {
    path: "cart",
    canActivate: [LoyaltyAppGuard, ReservationAppGuard],
    loadChildren: () =>
      import("./cart/cart.module").then((m) => m.CartPageModule),
  },
  {
    path: "payment-terms",
    canActivate: [LoyaltyAppGuard, ReservationAppGuard],
    loadChildren: () =>
      import("./payment-terms/payment-terms.module").then(
        (m) => m.PaymentTermsPageModule
      ),
  },
  {
    path: "points",
    // canActivate: [LoyaltyAppGuard],
    canActivate: [ReservationAppGuard],
    loadChildren: () =>
      import("./points/points.module").then((m) => m.PointsPageModule),
  },
  {
    path: "discount-coupons",
    canActivate: [LoyaltyAppGuard, ReservationAppGuard],
    loadChildren: () =>
      import("./discount-coupons/discount-coupons.module").then(
        (m) => m.DiscountCouponsPageModule
      ),
  },
  {
    path: "bundle-update",
    canActivate: [LoyaltyAppGuard, ReservationAppGuard],
    loadChildren: () =>
      import("./bundle-update/bundle-update.module").then(
        (m) => m.BundleUpdatePageModule
      ),
  },
  {
    path: "favoriteProducts",
    canActivate: [LoyaltyAppGuard, ReservationAppGuard],
    loadChildren: () =>
      import("./favorite-products/favorite-products.module").then(
        (m) => m.FavoriteProductsPageModule
      ),
  },
  {
    path: "loyalty-system",
    loadChildren: () =>
      import("./loyalty-system/loyalty-system.module").then(
        (m) => m.LoyaltySystemPageModule
      ),
  },
  {
    path: "reservationSystem",
    loadChildren: () =>
      import("./reservation-system/reservation-system.module").then(
        (m) => m.ReservationSystemPageModule
      ),
  },
  {
    path: "affiliate",
    loadChildren: () =>
      import("./affiliate/affiliate.module").then((m) => m.AffiliatePageModule),
  },
  {
    path: "reservations",
    loadChildren: () =>
      import("./reservations/reservations.module").then(
        (m) => m.ReservationsPageModule
      ),
  },
  {
    path: "reservations/new-reservation",
    loadChildren: () =>
      import(
        "./reservations/create-reservation/create-reservation.module"
      ).then((m) => m.CreateReservationPageModule),
  },
  {
    path: "kiosk",
    canActivate: [LoyaltyAppGuard, ReservationAppGuard, kioskGuard],
    loadChildren: () =>
      import("./kiosk/kiosk.module").then((m) => m.KioskPageModule),
  },

  {
    path: "contests",
    canActivate: [LoyaltyAppGuard, ReservationAppGuard],
    loadChildren: () =>
      import("./loyalty-system/loyalty-contests/loyalty-contests.module").then(
        (m) => m.LoyaltyContestsPageModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
