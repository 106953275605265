<ion-item
  class="quantityButtonsMaxWidth ion-no-padding ion-no-margin"
  lines="none"
  [hidden]="hiddeQuntityComponent"
>
  <ion-button
    color="dark"
    [disabled]="disableRemoveButton"
    (click)="QuantityDecrease()"
    fill="clear"
    [size]="buttonsSize ? buttonsSize : ''"
    ><ion-icon
      color="dark"
      [size]="buttonsSize ? buttonsSize : ''"
      slot="icon-only"
      src="assets/ionicons/remove-outline.svg"
    ></ion-icon
  ></ion-button>
  <ion-chip color="primary" (click)="openInputPopup($event)"
    ><ion-text [ngClass]="{ badgeTextSizeProductDetail: textSizeMedium }"
      >{{ !showIntegerQuantity ? quantity : roundQuantity }}
      {{
        quantityInChoicesKind && !showIntegerQuantity
          ? quantityInChoicesKind
          : ""
      }}
    </ion-text></ion-chip
  >
  <ion-button
    color="dark"
    [disabled]="disablePlusButton"
    (click)="QuantityIncrease()"
    fill="clear"
    [size]="buttonsSize ? buttonsSize : ''"
    ><ion-icon
      color="dark"
      [size]="buttonsSize ? buttonsSize : ''"
      slot="icon-only"
      src="assets/ionicons/add-outline.svg"
    ></ion-icon
  ></ion-button>
</ion-item>
