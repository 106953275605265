<ion-item
  *ngIf="!centerQuantityItem"
  class="quantityButtonsMaxWidth ion-no-padding ion-no-margin"
  lines="none"
  [hidden]="hiddeQuntityComponent"
>
  <ion-button
    color="dark"
    [disabled]="disableRemoveButton"
    [hidden]="hideQuantityButtons"
    (click)="QuantityDecrease()"
    fill="clear"
    [size]="buttonsSize ? buttonsSize : ''"
    ><ion-icon
      color="dark"
      [size]="buttonsSize ? buttonsSize : ''"
      slot="icon-only"
      src="assets/ionicons/remove-outline.svg"
    ></ion-icon
  ></ion-button>
  <ion-badge
    color="medium"
    [class]="
      productQtyWithStep && productStepKind && !fromCartCatalogItem
        ? 'badgeMinWidthProductQtyKind'
        : productQtyWithStep && productStepKind && fromCartCatalogItem
        ? 'badgeExtraMinWidthProductQtyKind'
        : ''
    "
    ><ion-text
      [ngClass]="{
        badgeTextSizeMediumProductDetail: textSizeMedium && !textSizeSmall,
        badgeTextSizeSmallProductDetail: textSizeSmall && !textSizeMedium,
        badgeTextSizeExtraSmallProductDetail:
          textSizeExtraSmall && !textSizeMedium && !textSizeSmall
      }"
      >{{ !showIntegerQuantity ? quantity : roundQuantity }}
      {{ productQtyWithStep && productStepKind ? productStepKind : "" }}
      {{
        quantityInChoicesKind && !showIntegerQuantity
          ? quantityInChoicesKind
          : ""
      }}
    </ion-text></ion-badge
  >
  <ion-button
    color="dark"
    [disabled]="disablePlusButton"
    [hidden]="hideQuantityButtons"
    (click)="QuantityIncrease()"
    fill="clear"
    [size]="buttonsSize ? buttonsSize : ''"
    ><ion-icon
      color="dark"
      [size]="buttonsSize ? buttonsSize : ''"
      slot="icon-only"
      src="assets/ionicons/add-outline.svg"
    ></ion-icon
  ></ion-button>
</ion-item>

<ion-item
  *ngIf="centerQuantityItem"
  class="quantityButtonsMaxWidth ion-no-padding ion-no-margin ion-text-center"
  lines="none"
  [hidden]="hiddeQuntityComponent"
>
  <ion-button
    slot="start"
    style="margin: 5px"
    [disabled]="disableRemoveButton"
    [hidden]="hideQuantityButtons"
    (click)="QuantityDecrease(); $event.stopImmediatePropagation()"
    fill="solid"
    shape="round"
    color="primary"
    [size]="buttonsSize ? buttonsSize : ''"
    ><ion-icon
      style="font-size: 15px"
      src="assets/ionicons/remove-outline.svg"
    ></ion-icon
  ></ion-button>

  <ion-grid class="ion-no-margin ion-no-padding" style="z-index: 10000">
    <ion-row
      class="ion-no-margin ion-no-padding ion-align-items-center ion-justify-content-center"
      style="width: 100%"
    >
      <ion-col
        class="ion-text-center ion-no-margin ion-no-padding ion-align-self-center"
      >
        <ion-badge color="primary"
          ><ion-text
            >{{ !showIntegerQuantity ? quantity : roundQuantity }}
            {{ productQtyWithStep && productStepKind ? productStepKind : "" }}
            {{
              quantityInChoicesKind && !showIntegerQuantity
                ? quantityInChoicesKind
                : ""
            }}
          </ion-text></ion-badge
        >
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-button
    slot="end"
    color="primary"
    [disabled]="disablePlusButton"
    [hidden]="hideQuantityButtons"
    (click)="QuantityIncrease(); $event.stopImmediatePropagation()"
    fill="solid"
    shape="round"
    style="margin: 5px"
    [size]="buttonsSize ? buttonsSize : ''"
    ><ion-icon
      style="font-size: 15px"
      src="assets/ionicons/add-outline.svg"
    ></ion-icon
  ></ion-button>
</ion-item>
