<ion-footer
  [class]="
    playAnimationFadeIn
      ? 'fade-in-from-bottom'
      : playAnimationFadeOut
      ? 'fade-out-to-bottom'
      : ''
  "
  *ngIf="
    groupApp &&
    groupApp.design_settings &&
    groupApp.design_settings.menuFooterTabs &&
    windowDimensions &&
    windowDimensions.width < 900
  "
  [hidden]="hideComponent"
  [ngStyle]="{
    'min-height': '65px',
    background: background,
    position: 'absolute',
    bottom: '0'
  }"
>
  <ion-grid
    [ngClass]="{
      'ion-no-padding': true,
      'footer-grid-border': true
    }"
  >
    <ion-grid style="max-width: 450px" class="ion-no-padding">
      <ion-row
        style="width: 100%"
        class="ion-justify-content-center ion-align-items-center"
      >
        <ion-col size="10" style="padding-left: 10px">
          <ion-button
            [mode]="
              windowDimensions &&
              windowDimensions.width &&
              windowDimensions.width <= 350
                ? 'ios'
                : ''
            "
            size="small"
            (click)="navigateCart()"
          >
            <ion-text>
              {{ "cart.cart-components.cart-list.checkout" | translate }}
              {{
                cartPrices && cartPrices.grand_total
                  ? cartPrices.grand_total + "€"
                  : ""
              }}
            </ion-text>
            <!-- <ion-spinner *ngIf="disableFinalPrice"></ion-spinner> -->
          </ion-button>
        </ion-col>

        <ion-col size="2" class="ion-text-right">
          <app-catalog-search-component
            [fabView]="false"
          ></app-catalog-search-component>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-grid>
</ion-footer>
