import * as _ from "lodash";
import * as ProductOptionsValidationActions from "./productOptionsValidation.actions";

export interface State {
  lastValidTimestampBySelectedProductId: any;
  optionValidationsBySelectedProductId: any;
  openProductModalBundleChoice: any;
  bundleOptionLoadingArray: any;
}

const initialState: State = {
  lastValidTimestampBySelectedProductId: {},
  optionValidationsBySelectedProductId: {},
  openProductModalBundleChoice: null,
  bundleOptionLoadingArray: [],
};

export function productOptionsValidationReducer(
  state = initialState,
  action: ProductOptionsValidationActions.ProductOptionsValidationActions
) {
  switch (action.type) {
    case ProductOptionsValidationActions.SET_OPEN_PRODUCT_MODAL_BUNDLE_CHOICE:
      return {
        ...state,
        openProductModalBundleChoice: action.payload,
      };

    case ProductOptionsValidationActions.SET_PRODUCT_OPTION_VALIDATION:
      let lastValidTimestampBySelectedProductId: any = {};
      let optionValidationsBySelectedProductId: any = {};
      lastValidTimestampBySelectedProductId[action.payload.selectedProductId] =
        action.payload.lastValidTimestamp;
      optionValidationsBySelectedProductId[action.payload.selectedProductId] = [
        action.payload.optionValidations,
      ];

      return {
        ...state,
        lastValidTimestampBySelectedProductId:
          lastValidTimestampBySelectedProductId,
        optionValidationsBySelectedProductId:
          optionValidationsBySelectedProductId,
      };

    case ProductOptionsValidationActions.ADD_UPDATE_PRODUCT_OPTION_VALIDATION:
      console.log(
        "ADD_UPDATE_BUNDLE_PRODUCT_OPTION_VALIDATION",
        state.optionValidationsBySelectedProductId
      );
      if (
        state &&
        state.optionValidationsBySelectedProductId &&
        state.optionValidationsBySelectedProductId[
          action.payload.selectedProductId
        ]
      ) {
        const key = _.findIndex(
          state.optionValidationsBySelectedProductId[
            action.payload.selectedProductId
          ],
          (optValidationObject: any) => {
            return (
              optValidationObject.option_id ===
              action.payload.validationObject.option_id
            );
          }
        );
        console.log(
          "ADD_UPDATE_BUNDLE_PRODUCT_OPTION_VALIDATION key",
          key,
          action.payload.selectedProductId
        );
        if (key !== -1) {
          const updateOption = {
            ...state.optionValidationsBySelectedProductId[
              action.payload.selectedProductId
            ][key],
            ...action.payload.validationObject,
          };
          const updatedOptionValidations = [
            ...state.optionValidationsBySelectedProductId[
              action.payload.selectedProductId
            ],
          ];
          updatedOptionValidations[key] = updateOption;
          let optionValidationsBySelectedProductIdUpdated = _.cloneDeep(
            state.optionValidationsBySelectedProductId
          );
          optionValidationsBySelectedProductIdUpdated[
            action.payload.selectedProductId
          ] = updatedOptionValidations;

          console.log(
            "ADD_UPDATE_BUNDLE_PRODUCT_OPTION_VALIDATION optionValidationsBySelectedProductIdUpdated",
            optionValidationsBySelectedProductIdUpdated
          );

          return {
            ...state,
            optionValidationsBySelectedProductId:
              optionValidationsBySelectedProductIdUpdated,
          };
        } else {
          let newOptionValidationsByPrdId = _.cloneDeep(
            state.optionValidationsBySelectedProductId
          );
          if (!newOptionValidationsByPrdId[action.payload.selectedProductId]) {
            newOptionValidationsByPrdId[action.payload.selectedProductId] = [];
          }

          newOptionValidationsByPrdId[action.payload.selectedProductId].push(
            _.cloneDeep(action.payload.validationObject)
          );

          return {
            ...state,
            optionValidationsBySelectedProductId: newOptionValidationsByPrdId,
          };
        }
      } else {
        let newOptionValidationsByPrdId = _.cloneDeep(
          state.optionValidationsBySelectedProductId
        );
        if (!newOptionValidationsByPrdId[action.payload.selectedProductId]) {
          newOptionValidationsByPrdId[action.payload.selectedProductId] = [];
        }

        newOptionValidationsByPrdId[action.payload.selectedProductId].push(
          _.cloneDeep(action.payload.validationObject)
        );
        console.log(
          "push optionValidationsBySelectedProductId",
          newOptionValidationsByPrdId
        );
        return {
          ...state,
          optionValidationsBySelectedProductId: newOptionValidationsByPrdId,
        };
      }

    case ProductOptionsValidationActions.CLEAR_PRODUCT_OPTIONS_VALIDATION:
      if (action.payload === "all") {
        return {
          ...state,
          lastValidTimestampBySelectedProductId: {},
          optionValidationsBySelectedProductId: {},
        };
      } else {
        let clearedProductLastValidTimestampByPrdId = _.cloneDeep(
          state.lastValidTimestampBySelectedProductId
        );
        let clearedOptionValidationsByPrdId = _.cloneDeep(
          state.optionValidationsBySelectedProductId
        );
        if (
          state &&
          state.lastValidTimestampBySelectedProductId &&
          state.lastValidTimestampBySelectedProductId[action.payload]
        ) {
          delete clearedProductLastValidTimestampByPrdId[action.payload];
        }

        if (
          state &&
          state.optionValidationsBySelectedProductId &&
          state.optionValidationsBySelectedProductId[action.payload]
        ) {
          delete clearedOptionValidationsByPrdId[action.payload];
        }
        return {
          ...state,
          lastValidTimestampBySelectedProductId:
            clearedProductLastValidTimestampByPrdId,
          optionValidationsBySelectedProductId: clearedOptionValidationsByPrdId,
        };
      }
    case ProductOptionsValidationActions.ADD_BUNDLE_OPTION_LOADING:
      const new_arr = _.cloneDeep(state.bundleOptionLoadingArray);
      const opt_index = _.findIndex(state.bundleOptionLoadingArray, {
        product_id_option_id: action.payload,
      });

      if (opt_index === -1) {
        new_arr.push({ product_id_option_id: _.cloneDeep(action.payload) });
      }

      return {
        ...state,
        bundleOptionLoadingArray: new_arr,
      };

    case ProductOptionsValidationActions.REMOVE_BUNDLE_OPTION_LOADING:
      const removed_arr = _.cloneDeep(state.bundleOptionLoadingArray);
      const option_index = _.findIndex(state.bundleOptionLoadingArray, {
        product_id_option_id: action.payload,
      });

      if (option_index !== -1) {
        removed_arr.splice(option_index, 1);
      }

      return {
        ...state,
        bundleOptionLoadingArray: removed_arr,
      };

    default:
      return state;
  }
}
