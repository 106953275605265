import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import * as _ from "lodash";
import * as fromApp from "../../../store/app.reducer";
import * as selectors from "../../../store/app.selectors";
import { Store } from "@ngrx/store";
import {
  auditTime,
  debounceTime,
  distinctUntilChanged,
  first,
} from "rxjs/operators";
import { Subscription } from "rxjs";
import {
  AlertController,
  LoadingController,
  ModalController,
} from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import * as PointsActions from "../../../points/store/points.actions";
import { Router } from "@angular/router";
import { AnimationOptions } from "ngx-lottie";
import { SelectStorePopupComponent } from "src/app/popups/select-store-popup/select-store-popup.component";

@Component({
  selector: "app-reservation-rewards",
  templateUrl: "./reservation-rewards.component.html",
  styleUrls: ["./reservation-rewards.component.scss"],
})
export class ReservationRewardsComponent implements OnInit {
  public currentUser: any;
  public groupApp: any;
  @Input() userPoints: any;
  @Output() rewardsEvents = new EventEmitter();

  public rewards: any;
  public currentStoreId: any;
  public mo_token: any;
  public loginState: string;
  public ionLoading: any = null;
  public storeInfo: any;
  public stores: any;
  public filterType = "mobileorder";
  public kioskRewardsFound;
  public mobileorderRewardsFound;
  public thereIsOnlyKioskOffers = false;
  public colSize = "6";
  public colSizeRewards = "6";
  public loyaltyCardLevelsById;
  public mo_base_lang;
  public selectedLangugage: any;

  private windowDimensions;
  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store<fromApp.AppState>,
    private alertController: AlertController,
    private translate: TranslateService,
    private dataStorageService: DataStorageService,
    private loadingController: LoadingController,
    private router: Router,
    private modalController: ModalController,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select("currentStore")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.currentStoreId &&
            !_.isEqual(state.currentStoreId, this.currentStoreId)
          ) {
            this.currentStoreId = _.cloneDeep(state.currentStoreId);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())
        .pipe(debounceTime(300))
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(state.windowDimensions, this.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
            this.calcualteColSizes();
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(300))
        .subscribe((state) => {
          if (state && !_.isEqual(state.mo_token, this.mo_token)) {
            this.mo_token = _.cloneDeep(state.mo_token);
            this.fetchCustomerPoints();
          }
          if (state && !_.isEqual(state.user, this.currentUser)) {
            this.currentUser = _.cloneDeep(state.user);
          }
          if (state && !_.isEqual(state.loginState, this.loginState)) {
            this.loginState = _.cloneDeep(state.loginState);
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(state.groupApp, this.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);

            if (this.groupApp && this.groupApp.languages) {
              _.each(this.groupApp.languages, (lang) => {
                if (lang && lang.mo_base) {
                  this.mo_base_lang = _.cloneDeep(lang.code);
                }
              });
              if (!this.mo_base_lang) {
                this.mo_base_lang = "en";
              }
            }
            if (
              this.groupApp &&
              this.groupApp.points &&
              this.groupApp.points.rewards
            ) {
              if (
                this.groupApp.points.loyaltyCardLevelsActive &&
                this.groupApp.points.loyaltyCardLevels &&
                this.groupApp.points.loyaltyCardLevels.length
              ) {
                const tempRewards = _.cloneDeep(this.groupApp.points.rewards);
                _.each(tempRewards, (rew, index) => {
                  if (rew.loyaltyCardLevels && rew.loyaltyCardLevels.length) {
                    _.each(rew.loyaltyCardLevels, (levelId, levelIdIndex) => {
                      const found = _.find(
                        this.groupApp.points.loyaltyCardLevels,
                        { id: levelId }
                      );

                      if (!found) {
                        rew.loyaltyCardLevels[levelIdIndex] = "";
                      }
                    });

                    if (rew.loyaltyCardLevels) {
                      rew.loyaltyCardLevels = _.compact(rew.loyaltyCardLevels);
                    }
                  }
                });
                this.groupApp.points.rewards = _.cloneDeep(tempRewards);
              } else {
                _.each(this.groupApp.points.rewards, (reward) => {
                  if (reward.loyaltyCardLevels) {
                    delete reward.loyaltyCardLevels;
                  }
                });
              }

              if (
                this.groupApp.points.loyaltyCardLevelsActive &&
                this.groupApp.points.loyaltyCardLevels &&
                this.groupApp.points.loyaltyCardLevels.length
              ) {
                this.loyaltyCardLevelsById = {};

                _.each(this.groupApp.points.loyaltyCardLevels, (level) => {
                  this.loyaltyCardLevelsById[level.id] = _.cloneDeep(level);
                });

                console.log(
                  "loyaltyCardLevelsById",
                  this.loyaltyCardLevelsById
                );
              }

              this.store
                .select("groupApp")
                .pipe(first())
                .subscribe((groupState) => {
                  const groupApp = groupState.groupApp;

                  this.store
                    .select(selectors.getStoreInfo)
                    .pipe(first())
                    .subscribe((storeInfo) => {
                      if (
                        storeInfo &&
                        storeInfo.dm_kiosk_app_active &&
                        !(
                          groupApp &&
                          groupApp.design_settings &&
                          groupApp.design_settings
                            .dm_kiosk_app_disable_mobileorder
                        )
                      ) {
                        this.kioskRewardsFound = _.find(
                          this.groupApp.points.rewards,
                          {
                            kiosk: true,
                          }
                        );

                        this.mobileorderRewardsFound = _.find(
                          this.groupApp.points.rewards,
                          (coupon) => {
                            if (!coupon.only_kiosk) {
                              return true;
                            }
                          }
                        );
                      }
                    })
                    .unsubscribe();
                })
                .unsubscribe();
            }

            console.log("group app points", this.groupApp.points);
          }

          if (
            state &&
            state.selectedLangugage &&
            !_.isEqual(state.selectedLangugage, this.selectedLangugage)
          ) {
            this.selectedLangugage = _.cloneDeep(state.selectedLangugage);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && state.stores && !_.isEqual(state.stores, this.stores)) {
            this.stores = _.cloneDeep(state.stores);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .subscribe((storeInfo) => {
          if (storeInfo && !_.isEqual(storeInfo, this.storeInfo)) {
            this.storeInfo = _.cloneDeep(storeInfo);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("points")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (state && !_.isEqual(state.rewards, this.rewards)) {
            let rewards;
            this.store
              .select("groupApp")
              .pipe(first())
              .subscribe((groupState) => {
                if (
                  groupState &&
                  groupState.groupApp &&
                  groupState.groupApp.points &&
                  groupState.groupApp.points.loyaltyCardLevelsActive &&
                  groupState.groupApp.points.loyaltyCardLevels &&
                  groupState.groupApp.points.loyaltyCardLevels.length
                ) {
                  const tempRewards = _.cloneDeep(state.rewards);
                  _.each(tempRewards, (rew, index) => {
                    if (rew.loyaltyCardLevels && rew.loyaltyCardLevels.length) {
                      _.each(rew.loyaltyCardLevels, (levelId, levelIdIndex) => {
                        const found = _.find(
                          groupState.groupApp.points.loyaltyCardLevels,
                          { id: levelId }
                        );

                        if (!found) {
                          rew.loyaltyCardLevels[levelIdIndex] = "";
                        }
                      });

                      if (rew.loyaltyCardLevels) {
                        rew.loyaltyCardLevels = _.compact(
                          rew.loyaltyCardLevels
                        );
                      }
                    }
                  });

                  rewards = tempRewards;

                  this.rewardsInitActions(rewards);
                } else {
                  rewards = _.cloneDeep(state.rewards);
                  _.each(rewards, (reward) => {
                    if (reward.loyaltyCardLevels) {
                      delete reward.loyaltyCardLevels;
                    }
                  });
                  this.rewardsInitActions(rewards);
                }
              })
              .unsubscribe();
          }
        })
    );
  }

  rewardsInitActions(rewards) {
    if (rewards && rewards.length) {
      this.rewards = _.cloneDeep(
        _.filter(rewards, (reward) => {
          if (reward.active) {
            return reward;
          }
        })
      );

      this.store
        .select("groupApp")
        .pipe(first())
        .subscribe((groupState) => {
          const groupApp = groupState.groupApp;
          this.store
            .select(selectors.getStoreInfo)
            .pipe(first())
            .subscribe((storeInfo) => {
              if (
                storeInfo &&
                storeInfo.dm_kiosk_app_active &&
                !(
                  groupApp &&
                  groupApp.design_settings &&
                  groupApp.design_settings.dm_kiosk_app_disable_mobileorder
                )
              ) {
                this.kioskRewardsFound = _.find(this.rewards, {
                  kiosk: true,
                });
                this.thereIsOnlyKioskOffers = false;
                _.each(this.rewards, (reward) => {
                  if (reward && reward.only_kiosk) {
                    this.thereIsOnlyKioskOffers = true;
                  }
                });

                this.mobileorderRewardsFound = _.find(
                  this.rewards,
                  (coupon) => {
                    if (!coupon.only_kiosk) {
                      return true;
                    }
                  }
                );
              }
            })
            .unsubscribe();
        })
        .unsubscribe();
    }
    console.log("rewards", this.rewards);
    _.each(this.rewards, (reward) => {
      if (reward && reward.user_points_percent) {
        if (reward.user_points_percent < 50) {
          document.documentElement.style.setProperty(
            "--pointsProgressBarColor",
            "var(--ion-color-black)"
          );
          document.documentElement.style.setProperty(
            "--pointsProgressBarColorLight",
            "var(--pointsProgressBarColorLightBlack)"
          );
        } else if (
          reward.user_points_percent >= 50 &&
          reward.user_points_percent <= 99
        ) {
          document.documentElement.style.setProperty(
            "--pointsProgressBarColor",
            "var(--ion-color-warning)"
          );
          document.documentElement.style.setProperty(
            "--pointsProgressBarColorLight",
            "var(--pointsProgressBarColorLightWarning)"
          );
        } else if (reward.user_points_percent === 100) {
          document.documentElement.style.setProperty(
            "--pointsProgressBarColor",
            "var(--ion-color-success)"
          );
          document.documentElement.style.setProperty(
            "--pointsProgressBarColorLight",
            "var(--pointsProgressBarColorLightSuccess)"
          );
        }
      }
    });
  }

  trackRewards(index) {
    return index;
  }

  calcualteColSizes() {
    if (this.windowDimensions && this.windowDimensions.width) {
      if (this.windowDimensions.width < 350) {
        this.colSize = "12";
      } else if (
        this.windowDimensions.width >= 350 &&
        this.windowDimensions.width < 570
      ) {
        this.colSize = "6";
      } else if (this.windowDimensions.width >= 570) {
        this.colSize = "4";
      }
    }

    if (this.windowDimensions && this.windowDimensions.width) {
      if (this.windowDimensions.width < 650) {
        this.colSizeRewards = "12";
      } else if (this.windowDimensions.width >= 650) {
        this.colSizeRewards = "6";
      }
    }
  }

  openInBrowser(reward) {
    window.open(reward.third_party_url, "_system", "location=yes");
  }

  async confirmUsePoints(reward) {
    if (this.currentUser) {
      let alert;
      if (
        this.groupApp &&
        this.groupApp.points &&
        this.groupApp.points.loyaltyCardLevelsActive &&
        this.groupApp.points.loyaltyCardLevels.length &&
        reward &&
        reward.loyaltyCardLevelsActive &&
        reward.loyaltyCardLevels &&
        reward.loyaltyCardLevels.length &&
        (!this.currentUser.current_loyalty_card_level ||
          !reward.loyaltyCardLevels.includes(
            this.currentUser.current_loyalty_card_level.id
          ))
      ) {
        console.log(reward);
        const levelNeed = _.find(
          this.groupApp.points.loyaltyCardLevels,
          (level) => {
            if (reward.loyaltyCardLevels.includes(level.id)) {
              return true;
            }
          }
        );

        alert = await this.alertController.create({
          cssClass: "my-custom-class",
          header: this.translate.instant("alert"),
          message:
            this.translate.instant(
              "points.rewards.you-need-loyalty-card-level-to-take-coupon"
            ) +
            " " +
            (levelNeed &&
            levelNeed.name &&
            levelNeed.name[this.selectedLangugage]
              ? levelNeed.name[this.selectedLangugage]
              : levelNeed.name[this.mo_base_lang]),
          buttons: ["OK"],
        });
      } else if (
        !this.userPoints ||
        !this.userPoints.pointsSystem ||
        this.userPoints.pointsSystem.active < parseInt(reward.points)
      ) {
        alert = await this.alertController.create({
          cssClass: "my-custom-class",
          header: this.translate.instant("alert"),
          message:
            this.translate.instant("points.rewards.you-need") +
            " " +
            reward.points +
            " " +
            this.translate.instant("points.rewards.points-to-cash-out"),

          buttons: ["OK"],
        });
      } else {
        alert = await this.alertController.create({
          cssClass: "my-custom-class",
          header: this.translate.instant("points.rewards.pointsCapital"),
          message:
            this.translate.instant(
              "points.rewards.do-you-want-to-cash-out-your-points-to-get"
            ) +
            " " +
            reward.title +
            ";",
          buttons: [
            {
              text: this.translate.instant("cancel"),
              role: "cancel",
              cssClass: "secondary",
              handler: (blah) => {
                console.log("Confirm Cancel: blah");
              },
            },
            {
              text: this.translate.instant("yes"),
              handler: async () => {
                if (this.stores && this.stores.length === 1) {
                  this.usePoints(reward, this.currentStoreId);
                } else if (this.stores && this.stores.length > 1) {
                  const storesForSelection = _.cloneDeep(this.stores);
                  _.each(storesForSelection, (store) => {
                    if (
                      store &&
                      reward &&
                      (!reward.only_kiosk ||
                        (store.dm_kiosk_app_active &&
                          !(
                            this.groupApp &&
                            this.groupApp.design_settings &&
                            this.groupApp.design_settings
                              .dm_kiosk_app_disable_mobileorder
                          ))) &&
                      (!reward.store_id ||
                        reward.store_id === "all" ||
                        (reward.store_id === store.store_id &&
                          (reward.product_id ||
                            (reward.products && reward.products.length))))
                    ) {
                      store.hasTheOffer = true;
                    }
                  });

                  const selected_store_id: any =
                    await this.openSelectStoreModal(
                      _.cloneDeep(storesForSelection)
                    );

                  if (selected_store_id) {
                    this.usePoints(reward, selected_store_id);
                  }
                }
              },
            },
          ],
        });
      }

      await alert.present();
    } else {
      this.rewardsEvents.emit({
        event: "show-login-modal",
        store_id: null,
      });
    }
  }

  async openSelectStoreModal(storesForSelection) {
    return new Promise(async (resolve, reject) => {
      let cssClass = "selectStorePopupCss";
      if (this.stores && this.stores.length > 1 && this.stores.length <= 3) {
        cssClass = "selectStorePopupCssThreeStores";
      } else if (this.stores && this.stores.length > 3) {
        cssClass = "selectStorePopupCssMultipleStores";
      }
      const modal = await this.modalController.create({
        component: SelectStorePopupComponent,
        componentProps: {
          storesForSelection: storesForSelection,
          onlyReturnSelectedStoreId: true,
        },
        cssClass: cssClass,
        backdropDismiss: false,
      });

      await modal.present();

      await modal.onDidDismiss().then((data) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          resolve(null);
        }
      });
    });
  }

  async presentLoading() {
    let message = this.translate.instant("loading-please-wait");
    this.ionLoading = await this.loadingController.create({
      duration: 10500,
      message: message,
      cssClass: "ionicLoadingCss",
      keyboardClose: true,
    });
    await this.ionLoading.present();
    await this.ionLoading.onDidDismiss().then(() => {
      console.log("Loading dismissed!");
      this.ionLoading = null;
    });
  }

  async hideLoading() {
    if (this.ionLoading) {
      await this.ionLoading.dismiss();
      this.ionLoading = null;
    }
  }

  usePoints(reward, store_id) {
    this.presentLoading();
    this.dataStorageService
      .usePoints(
        {
          store_id: store_id,
          item: reward,
        },
        this.mo_token
      )
      .subscribe({
        next: async (res: any) => {
          if (!res || !res.success) {
            this.hideLoading();

            const alert = await this.alertController.create({
              header: this.translate.instant("alert"),
              message:
                res && res.comment_id
                  ? this.translate.instant(res.comment_id)
                  : this.translate.instant("errorMessages.general_error"),
              backdropDismiss: false,
              buttons: ["OK"],
            });
            await alert.present();
          } else {
            this.hideLoading();
            this.rewardsEvents.emit({
              event: "successUsePoints",
              store_id: store_id,
            });
            this.fetchCustomerPoints();
            const alert = await this.alertController.create({
              header: res.comment_id
                ? this.translate.instant(res.comment_id)
                : this.translate.instant(res.comments),

              backdropDismiss: false,
              buttons: [
                { text: "OK", role: "cancel" },
                {
                  text: this.translate.instant(
                    "reservation-app.show-active-coupons"
                  ),
                  handler: () => {
                    this.rewardsEvents.emit({
                      event: "show-coupons-modal",
                      store_id: store_id,
                    });
                  },
                },
              ],
            });
            // this.router.navigateByUrl("/cart", {
            //   replaceUrl: true,
            // });
            await alert.present();
            await alert.onDidDismiss().then(async () => {
              //TODO FOR KIOSK COUPONS
              // if (
              //   this.storeInfo &&
              //   this.storeInfo.dm_kiosk_app_active &&
              //   (reward.only_kiosk || reward.kiosk)
              // ) {
              //   if (reward.only_kiosk) {
              //     this.router.navigateByUrl("/kiosk?openFirstCoupon=true", {
              //       replaceUrl: true,
              //     });
              //   } else if (reward.kiosk) {
              //     const confirmationAlert = await this.alertController.create({
              //       header: this.translate.instant("information"),
              //       message: this.translate.instant(
              //         "kiosk-page.do-you-want-to-use-the-coupon-to-kiosk"
              //       ),
              //       backdropDismiss: false,
              //       buttons: [
              //         {
              //           text: this.translate.instant("cancel"),
              //           role: "cancel",
              //           cssClass: "secondary",
              //           handler: (blah) => {
              //             console.log("cancel");
              //           },
              //         },
              //         {
              //           text: this.translate.instant("yes"),
              //           handler: () => {
              //             this.router.navigateByUrl(
              //               "/kiosk?openFirstCoupon=true",
              //               {
              //                 replaceUrl: true,
              //               }
              //             );
              //           },
              //         },
              //       ],
              //     });
              //     await confirmationAlert.present();
              //   }
              // }
            });
            console.log("user points res", res);
          }
        },
        error: async (err) => {
          this.hideLoading();
          const alert = await this.alertController.create({
            header: this.translate.instant("alert"),
            message: this.translate.instant(
              "errorMessages.problem_reaching_server"
            ),

            buttons: ["OK"],
          });
          await alert.present();
          return err;

          console.log("error updating the information");
        },
      });
  }

  fetchCustomerPoints() {
    this.dataStorageService
      .fetchCustomerPoint(
        this.mo_token,
        this.groupApp &&
          this.groupApp.points &&
          this.groupApp.points.loyaltyCardLevelsActive
      )
      .subscribe({
        next: async (res: any) => {
          if (res && res.success) {
            let userPoints = res.data;
            this.store.dispatch(
              new PointsActions.SetUserPoints(_.cloneDeep(userPoints))
            );
            let tempGroupApp = _.cloneDeep(this.groupApp);

            _.each(tempGroupApp.points.rewards, (reward) => {
              if (reward.points <= userPoints.pointsSystem.active) {
                reward.user_points_percent = 100;
              } else {
                var percent =
                  (userPoints.pointsSystem.active / reward.points) * 100;
                reward.user_points_percent = percent.toFixed();
              }

              console.log("reward", reward.user_points_percent);
            });
            this.store.dispatch(
              new PointsActions.SetRewards(
                _.cloneDeep(tempGroupApp.points.rewards)
              )
            );
            let highestReward = _.maxBy(
              tempGroupApp.points.rewards,
              "user_points_percent"
            );
            this.store.dispatch(
              new PointsActions.SetHighestReward(_.cloneDeep(highestReward))
            );
          } else if (!res || !res.success) {
            const alert = await this.alertController.create({
              header: this.translate.instant("alert"),
              message:
                res && res.comment_id
                  ? this.translate.instant(res.comment_id)
                  : this.translate.instant("errorMessages.general_error"),
              backdropDismiss: false,
              buttons: ["OK"],
            });
            await alert.present();
          }
        },
        error: async (error) => {
          const alert = await this.alertController.create({
            header: this.translate.instant("alert"),
            message: this.translate.instant(
              "errorMessages.problem_reaching_server"
            ),

            buttons: ["OK"],
          });
          await alert.present();
        },
      });
  }

  ngOnDestroy() {
    if (this.subscriptions && !_.isEmpty(this.subscriptions)) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }
}
