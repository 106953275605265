import { Component, OnDestroy, OnInit } from "@angular/core";
import { ModalController, Platform, ToastController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import * as _ from "lodash";
import * as fromApp from "../../store/app.reducer";
import { distinctUntilChanged, first, Subscription } from "rxjs";
import { Store } from "@ngrx/store";

@Component({
  selector: "app-allergen-modal",
  templateUrl: "./allergen-modal.component.html",
  styleUrls: ["./allergen-modal.component.scss"],
})
export class AllergenModalComponent implements OnInit, OnDestroy {
  public static_pages: any;
  public groupApp: any;
  public selectedLangugage: any;
  public mo_base_lang: any;
  private subscription: Subscription;
  private staticPagesSub: Subscription;
  private unsubscribeBackEvent: Subscription;

  constructor(
    private modalController: ModalController,
    private dataStorageService: DataStorageService,
    private toastController: ToastController,
    private translateService: TranslateService,
    private store: Store<fromApp.AppState>,
    private platform: Platform
  ) {}

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );
    this.subscription = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.groupApp &&
          !_.isEqual(this.groupApp, state.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp);

          if (this.groupApp && this.groupApp.languages) {
            _.each(this.groupApp.languages, (lang) => {
              if (lang && lang.mo_base) {
                this.mo_base_lang = _.cloneDeep(lang.code);
              }
            });
            if (!this.mo_base_lang) {
              this.mo_base_lang = "en";
            }
          }
        }
        if (
          state &&
          state.selectedLangugage &&
          !_.isEqual(this.selectedLangugage, state.selectedLangugage)
        ) {
          this.selectedLangugage = _.cloneDeep(state.selectedLangugage);
        }
      });

    this.staticPagesSub = this.store
      .select("staticPages")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (state && !_.isEqual(state.static_pages, this.static_pages)) {
          this.static_pages = _.cloneDeep(state.static_pages);
        }
      });
  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }

  closeModal() {
    this.modalController.dismiss();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }

    if (this.staticPagesSub) {
      this.staticPagesSub.unsubscribe();
    }

    this.static_pages = null;
  }
}
