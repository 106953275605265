import { Action } from "@ngrx/store";

export const SET_LOYALTY_USER_POINTS = "[LOYALTY] Set Loyalty User Points";
export const SET_USER_LOYALTY_PRODUCTS = "[LOYALTY] Set User Loyalty Products";
export const SET_USER_LOYALTY_PRODUCTS_HISTORY = "[LOYALTY] Set User Loyalty Products History";
export const SET_ACTIVE_CONTEST_CUSTOMER_DRAWS = "[LOYALTY] Set Active Contest Customer Draws";
export const SET_GROUP_CONTESTS = "[LOYALTY] Set Group Contests";
export const SET_LATEST_CONTEST_WINNERS = "[LOYALTY] Set Latest Contest Winners";
export const CLEAR_USER_LOYALTY_PRODUCTS_HISTORY = "[LOYALTY] Clear User Loyalty Products History";
export const SET_UNABLE_TO_LOAD_USER_LOYALTY_PRODUCTS =
  "[LOYALTY] Set Unable To Load User Loyalty Products";
export const SET_LOYALTY_CAMPAIGNS = "[LOYALTY] Set Loyalty Campaigns";
export const SET_LOYALTY_COUPONS = "[LOYALTY] Set Loyalty Coupons";
export const CLEAR_LOYALTY_COUPONS = "[LOYALTY] Clear Loyalty Coupons";
export const CLEAR_LOYALTY_POINTS = "[LOYALTY] Clear Loyalty Points";
export const SET_CURRENT_LOYALTY_STORE_ID = "[CURRENT STORE] Set Current Loyalty Store Id";
export const CLEAR_CURRENT_LOYALTY_STORE_ID = "[CURRENT STORE] Clear Current Loyalty Store Id";
export const SET_IS_COUPON_LOADING = "[LOYALTY] Set Is Coupon Loading";
export const ADD_UPDATE_USER_LOYALTY_PRODUCTS_FROM_SOCKET =
  "[LOYALTY] ADD UPDATE USER LOYALTY PRODUCTS FROM SOCKET";
export const REMOVE_USER_LOYALTY_PRODUCT_FROM_SOCKET =
  "[LOYALTY] Remove User Loyalty Product From Socket";
export const SET_HALL_OF_FAME = "[LOYALTY] Set Hall Of Fame";
export const SET_USER_RANKING = "[LOYALTY] Set User Ranking";
export const UPDATE_USER_TOTAL_PRODUCTS_FROM_SOCKET =
  "[LOYALTY] Update User Total Products From Socket";
export const UPDATE_USER_RANK_FROM_SOCKET = "[LOYALTY] Update User Rank From Socket";

export class SetUserLoyaltyPoints implements Action {
  readonly type = SET_LOYALTY_USER_POINTS;
  constructor(public payload: any) {}
}

export class SetUserRanking implements Action {
  readonly type = SET_USER_RANKING;
  constructor(public payload: any) {}
}

export class SetHallOfFame implements Action {
  readonly type = SET_HALL_OF_FAME;
  constructor(public payload: any) {}
}

export class SetIsCouponLoading implements Action {
  readonly type = SET_IS_COUPON_LOADING;
  constructor(public payload: any) {}
}

export class SetLoyaltyCampaigns implements Action {
  readonly type = SET_LOYALTY_CAMPAIGNS;
  constructor(public payload: any) {}
}

export class SetUserLoyaltyProducts implements Action {
  readonly type = SET_USER_LOYALTY_PRODUCTS;
  constructor(public payload: any) {}
}

export class SetUserLoyaltyProductsHistory implements Action {
  readonly type = SET_USER_LOYALTY_PRODUCTS_HISTORY;
  constructor(public payload: any) {}
}
export class ClearUserLoyaltyProductsHistory implements Action {
  readonly type = CLEAR_USER_LOYALTY_PRODUCTS_HISTORY;
  constructor() {}
}

export class AddUpdateUserLoyaltyProductsFromSocket implements Action {
  readonly type = ADD_UPDATE_USER_LOYALTY_PRODUCTS_FROM_SOCKET;
  constructor(public payload: any) {}
}

export class UpdateUserRankFromSocket implements Action {
  readonly type = UPDATE_USER_RANK_FROM_SOCKET;
  constructor(public payload: any) {}
}

export class RemoveUserLoyaltyProductFromSocket implements Action {
  readonly type = REMOVE_USER_LOYALTY_PRODUCT_FROM_SOCKET;
  constructor(public payload: any) {}
}

export class SetUnableToLoadUserLoyaltyProducts implements Action {
  readonly type = SET_UNABLE_TO_LOAD_USER_LOYALTY_PRODUCTS;
  constructor(public payload: any) {}
}
export class SetLoyaltyCoupons implements Action {
  readonly type = SET_LOYALTY_COUPONS;
  constructor(public payload: any) {}
}

export class ClearLoyaltyCoupons implements Action {
  readonly type = CLEAR_LOYALTY_COUPONS;
  constructor() {}
}

export class SetCurrentLoyaltyStoreId implements Action {
  readonly type = SET_CURRENT_LOYALTY_STORE_ID;
  constructor(public payload: string) {}
}

export class SetActiveContestCustomerDraws implements Action {
  readonly type = SET_ACTIVE_CONTEST_CUSTOMER_DRAWS;
  constructor(public payload: any) {}
}

export class SetGroupContests implements Action {
  readonly type = SET_GROUP_CONTESTS;
  constructor(public payload: string) {}
}

export class SetLatestContestWinners implements Action {
  readonly type = SET_LATEST_CONTEST_WINNERS;
  constructor(public payload: string) {}
}

export class ClearCurrentLoyaltyStoreId implements Action {
  readonly type = CLEAR_CURRENT_LOYALTY_STORE_ID;
  constructor() {}
}

export class UpdateUserTotalProductsFromSocket implements Action {
  readonly type = UPDATE_USER_TOTAL_PRODUCTS_FROM_SOCKET;
  constructor(public payload: any) {}
}

export type LoyaltySystemActions =
  | SetUserLoyaltyPoints
  | SetLoyaltyCampaigns
  | SetLoyaltyCoupons
  | SetCurrentLoyaltyStoreId
  | ClearCurrentLoyaltyStoreId
  | SetUserLoyaltyProducts
  | SetUnableToLoadUserLoyaltyProducts
  | SetIsCouponLoading
  | AddUpdateUserLoyaltyProductsFromSocket
  | RemoveUserLoyaltyProductFromSocket
  | SetUserLoyaltyProductsHistory
  | ClearUserLoyaltyProductsHistory
  | SetHallOfFame
  | SetUserRanking
  | UpdateUserTotalProductsFromSocket
  | UpdateUserRankFromSocket
  | ClearLoyaltyCoupons
  | SetActiveContestCustomerDraws
  | SetGroupContests
  | SetLatestContestWinners;
