<ion-header>
  <ion-toolbar mode="md">
    <ion-title>
      {{ "popups.firebase-recaptcha-login-modal.title" | translate }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button color="dark" size="small" (click)="closeModal()" fill="clear">
        <ion-icon
          slot="icon-only"
          src="assets/ionicons/close-outline.svg"
        ></ion-icon
      ></ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<div class="inner-scroll">
  <ion-grid
    *ngIf="!reacaptchaLoaded && typeOfRecaptcha && typeOfRecaptcha === 'normal'"
  >
    <ion-row>
      <ion-col class="ion-text-center">
        <ion-spinner color="dark"></ion-spinner>
      </ion-col>
    </ion-row>
  </ion-grid>
  <div id="recaptcha-container-normal"></div>
  <ion-item
    *ngIf="
      (reacaptchaLoaded &&
        verified &&
        typeOfRecaptcha &&
        typeOfRecaptcha === 'normal') ||
      (typeOfRecaptcha && typeOfRecaptcha === 'invisible')
    "
  >
    <ion-label position="stacked">
      {{ "popups.firebase-recaptcha-login-modal.mobile" | translate }}
    </ion-label>
    <ion-input
      #mobileInput
      #inputMobileRef
      placeholder="{{ 'write-here-your-mobile' | translate }}"
      [(ngModel)]="mobile"
      type="text"
    ></ion-input>
  </ion-item>
</div>

<ion-footer>
  <ion-grid
    *ngIf="
      reacaptchaLoaded &&
      verified &&
      typeOfRecaptcha &&
      typeOfRecaptcha === 'normal'
    "
  >
    <ion-row style="width: 100%">
      <ion-col class="ion-text-center">
        <ion-button (click)="onClickOk()">{{
          "add-mobile" | translate
        }}</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid>
    <ion-row
      *ngIf="typeOfRecaptcha && typeOfRecaptcha === 'invisible'"
      style="width: 100%"
    >
      <ion-col class="ion-text-center">
        <ion-button [disabled]="!reacaptchaLoaded" (click)="onClickOk()"
          ><ion-spinner *ngIf="!reacaptchaLoaded"></ion-spinner>
          <ion-text *ngIf="reacaptchaLoaded">{{
            "add-mobile" | translate
          }}</ion-text></ion-button
        >
      </ion-col>
    </ion-row>
    <div
      [class]="
        typeOfRecaptcha && typeOfRecaptcha === 'normal'
          ? 'minHeightZero'
          : 'minHeight'
      "
    >
      <div id="recaptcha-container-invisible"></div>
    </div>
  </ion-grid>
</ion-footer>
