import { Component, Input, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';

@Component({
  selector: 'app-affiliate-info-modal',
  templateUrl: './affiliate-info-modal.component.html',
  styleUrls: ['./affiliate-info-modal.component.scss'],
})
export class AffiliateInfoModalComponent implements OnInit {
  @Input() affiliateInformationAndTerms;
  unsubscribeBackEvent: any;

  constructor(
    private modalController: ModalController,
    private platform: Platform
  ) { }

  ngOnInit() {

    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        this.closeModal();
      }
    );
    
  }

  closeModal(){
    this.modalController.dismiss();
  }

  ngOnDestroy(){
    if(this.unsubscribeBackEvent){
      this.unsubscribeBackEvent.unsubscribe();
    }
  }

}
