import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../../../../store/app.reducer';
import * as CartCustomOptionsSelectionsActions from '../../store/cart-custom-options-selections/cart-custom-options-selections.actions';

import * as _ from 'lodash';
import { auditTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-text-type-choices',
  templateUrl: './text-type-choices.component.html',
  styleUrls: ['./text-type-choices.component.scss'],
})
export class TextTypeChoicesComponent implements OnInit {
  @Input() option_timestamp;
  @Input() selectedLang: any;
  public option: any;
  public CustomSelections;
  private subscriptions: Subscription[] = [];
  public finalPriceLoading;
  public selectedChoiceTimestamp;

  constructor(private store: Store<fromApp.AppState>) {}

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select('cartCustomOptionsSelections')
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.cartCustomOptionsSelections &&
            !_.isEqual(state.cartCustomOptionsSelections, this.CustomSelections)
          ) {
            this.CustomSelections = _.cloneDeep(
              state.cartCustomOptionsSelections
            );
            this.option = _.cloneDeep(
              _.find(this.CustomSelections, {
                timestamp: this.option_timestamp,
              })
            );

            if (
              this.option &&
              this.option.choices &&
              this.option.choices.length > 0
            ) {
              this.option.choices.forEach((choice) => {
                if (choice.selected) {
                  this.selectedChoiceTimestamp = choice.timestamp;
                }
              });
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select('disableFinalPrice')
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(state.disableFinalPrice, this.finalPriceLoading)
          ) {
            this.finalPriceLoading = _.cloneDeep(state.disableFinalPrice);
          }
        })
    );
  }

  onChange(ev) {
    _.each(this.option.choices, (ch) => {
      if (ch && ch.text && ch.text !== '') {
        ch.selected = true;
      } else {
        ch.selected = false;
      }
    });

    this.store.dispatch(
      new CartCustomOptionsSelectionsActions.AddUpdateCartCustomOptionSelections(
        _.cloneDeep(this.option)
      )
    );
  }

  trackTimestamp(index, choice) {
    return choice.timestamp;
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }
}
