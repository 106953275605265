import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
  transform(timestamp: number): string {
    if (timestamp && !isNaN(timestamp)) {
      const date = new Date(timestamp * 1000); // Convert timestamp to milliseconds
      const month = date.getMonth() + 1; // Months are zero-based
      const day = date.getDate();
      return `${day}/${month}`;
    }
    return '';
  }
  
}
