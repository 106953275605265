import { Pipe, PipeTransform } from '@angular/core';
import * as _ from "lodash";
/*
 * reverseArray
 * Usage:
 *   array | reverseArray
*/
@Pipe({name: 'reverseArray'})
export class reverseArrayPipe implements PipeTransform {
  transform(array: []): any[] {
    const newArray = array.reverse();
    return newArray;
  }
}
