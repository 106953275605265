import { Action } from "@ngrx/store";

export const SET_AFFILIATE_RECEIVERS_COUPONS =
  "[AFFILIATE] Set Affiliate Receivers Array";
export const SET_TRIGGER_FETCH_RECEIVERS_COUPONS =
  "[AFFILIATE] Set Trigger Fetch Receivers Coupons";

export class SetAffiliateReceiversCoupons implements Action {
  readonly type = SET_AFFILIATE_RECEIVERS_COUPONS;
  constructor(public payload: any) {}
}

export class SetTriggerFetchReceiversCoupons implements Action {
  readonly type = SET_TRIGGER_FETCH_RECEIVERS_COUPONS;
  constructor(public payload: any) {}
}

export type AffiliateActions =
  | SetAffiliateReceiversCoupons
  | SetTriggerFetchReceiversCoupons;
