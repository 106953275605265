import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { auditTime, catchError, distinctUntilChanged } from "rxjs/operators";
import * as fromApp from "../../store/app.reducer";
import * as _ from "lodash";
import { Subscription } from "rxjs";
import {
  AlertController,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import { CustomerPreferencesModalComponent } from "./customer-preferences-modal/customer-preferences-modal.component";
import { OneSignalService } from "src/app/services/oneSignal/one-signal.service";
import { AppRatingModalComponent } from "./app-rating-modal/app-rating-modal.component";
import { Capacitor } from "@capacitor/core";
import { RateApp } from "capacitor-rate-app";
import * as AuthActions from "../../auth/store/auth.actions";
import * as manuallyTriggerFinalPriceActions from "../../store/manuallyTriggerFinalPriceCheckAddress/manuallyTriggerFinalPrice.actions";
import moment from "moment";

declare let window: any;

@Component({
  selector: "app-order-rating-modal",
  templateUrl: "./order-rating-modal.component.html",
  styleUrls: ["./order-rating-modal.component.scss"],
})
export class OrderRatingModalComponent implements OnInit, OnDestroy {
  @Input() order_id: any;
  @Input() store_id;
  public selectedLanguage: any;
  public currentStore: any;
  public submitted: boolean;
  public loading: boolean = false;
  private stores: any;
  private mo_token: any;
  private groupApp: any;
  private subscriptions: Subscription[] = [];
  private unsubscribeBackEvent: Subscription;
  private customerPrefsModal: any;
  private currentUser: any;
  private androidPlatform: boolean;
  private iOSPlatform: boolean;
  private browserPlatform: boolean;

  constructor(
    private store: Store<fromApp.AppState>,
    private modalController: ModalController,
    private alertController: AlertController,
    private translate: TranslateService,
    private toastController: ToastController,
    private dataStorageService: DataStorageService,
    private ionicPlatform: Platform,
    private oneSignalService: OneSignalService
  ) {}

  closeModal() {
    this.modalController.dismiss();
  }

  ngOnInit() {
    this.unsubscribeBackEvent =
      this.ionicPlatform.backButton.subscribeWithPriority(200, () => {
        if (this.ionicPlatform.is("android")) {
          this.closeModal();
        }
      });
    this.subscriptions.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (state && state.stores && !_.isEqual(state.stores, this.stores)) {
            this.stores = _.cloneDeep(state.stores);
            if (this.stores && this.stores.length) {
              this.store
                .select("currentStore")
                .subscribe((state) => {
                  if (state && state.currentStoreId) {
                    this.currentStore = _.cloneDeep(
                      _.find(this.stores, {
                        store_id: state.currentStoreId,
                      })
                    );
                    console.log("this current store", this.currentStore);
                  }
                })
                .unsubscribe();
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(state.groupApp, this.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
          }
          if (
            state &&
            state.selectedLangugage &&
            !_.isEqual(state.selectedLangugage, this.selectedLanguage)
          ) {
            this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("platformInfo")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.browserPlatform &&
            !_.isEqual(state.browserPlatform, this.browserPlatform)
          ) {
            this.browserPlatform = _.cloneDeep(state.browserPlatform);
          }
          if (
            state &&
            state.androidPlatform &&
            !_.isEqual(state.androidPlatform, this.androidPlatform)
          ) {
            this.androidPlatform = _.cloneDeep(state.androidPlatform);
          }
          if (
            state &&
            state.iOSPlatform &&
            !_.isEqual(state.iOSPlatform, this.iOSPlatform)
          ) {
            this.iOSPlatform = _.cloneDeep(state.iOSPlatform);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            state.mo_token &&
            !_.isEqual(this.mo_token, state.mo_token)
          ) {
            this.mo_token = _.cloneDeep(state.mo_token);
          }
          if (state && state.user && !_.isEqual(this.currentUser, state.user)) {
            this.currentUser = _.cloneDeep(state.user);
          }
        })
    );
  }

  submitReview() {
    this.submitted = true;
    this.loading = true;
  }

  onAnswerEvent(ev) {
    if (ev) {
      this.onSave(ev);
    }
  }

  checkIfUserMustReviewTheApp(reviewQuestionsArrayAnswers) {
    let prompt = "no";

    _.each(reviewQuestionsArrayAnswers, (question) => {
      if (
        question.text === "ΕΜΠΕΙΡΙΑ ONLINE ΠΑΡΑΓΓΕΛΙΑΣ" &&
        question.stars == 5 &&
        this.androidPlatform
      ) {
        prompt = "android";
      } else if (
        question.text === "ΕΜΠΕΙΡΙΑ ONLINE ΠΑΡΑΓΓΕΛΙΑΣ" &&
        question.stars == 5 &&
        this.iOSPlatform
      ) {
        prompt = "ios";
      }
    });

    return prompt;
  }

  private changeSubmittedToFalse = _.debounce(() => {
    this.submitted = false;
  }, 100);

  async onSave(reviewQuestionsArrayAnswers) {
    if (reviewQuestionsArrayAnswers.length === 0) {
      const alert = await this.alertController.create({
        header: this.translate.instant("alert"),
        message: this.translate.instant(
          "orders.order-rating-modal.there-are-no-questions-of-review"
        ),

        buttons: ["OK"],
      });
      await alert.present();
      this.changeSubmittedToFalse();
    } else {
      var reviewObjectIsValid = true;
      for (let i = 0; i < reviewQuestionsArrayAnswers.length; i++) {
        if (
          reviewQuestionsArrayAnswers[i].type === "radio" &&
          reviewQuestionsArrayAnswers[i].required &&
          reviewQuestionsArrayAnswers[i].answer_id
        ) {
          const alert = await this.alertController.create({
            header: this.translate.instant("alert"),
            message: this.translate.instant(
              "orders.order-rating-modal.there-is-an-unanswered-single-choice-question"
            ),

            buttons: ["OK"],
          });
          await alert.present();
          reviewObjectIsValid = false;
          this.changeSubmittedToFalse();
          break;
        }
        if (
          reviewQuestionsArrayAnswers[i].type === "textarea" &&
          reviewQuestionsArrayAnswers[i].required &&
          reviewQuestionsArrayAnswers[i].inputted_text
        ) {
          const alert = await this.alertController.create({
            header: this.translate.instant("alert"),
            message: this.translate.instant(
              "orders.order-rating-modal.there-is-an-unanswered-question"
            ),

            buttons: ["OK"],
          });
          await alert.present();
          reviewObjectIsValid = false;
          this.changeSubmittedToFalse();
          break;
        }
        if (
          reviewQuestionsArrayAnswers[i].type === "stars" &&
          reviewQuestionsArrayAnswers[i].required &&
          (!reviewQuestionsArrayAnswers[i].stars ||
            reviewQuestionsArrayAnswers[i].stars === 0)
        ) {
          const alert = await this.alertController.create({
            header: this.translate.instant("alert"),
            message: this.translate.instant(
              "orders.order-rating-modal.there-is-an-unanswered-star-question"
            ),

            buttons: ["OK"],
          });
          await alert.present();
          reviewObjectIsValid = false;
          this.changeSubmittedToFalse();
          break;
        }
        if (
          reviewQuestionsArrayAnswers[i].type === "checkbox" &&
          reviewQuestionsArrayAnswers[i].required &&
          !_.find(reviewQuestionsArrayAnswers[i].answers, {
            selected: true,
          })
        ) {
          const alert = await this.alertController.create({
            header: this.translate.instant("alert"),
            message: this.translate.instant(
              "orders.order-rating-modal.there-is-an-unanswered-multiple-choice-question"
            ),

            buttons: ["OK"],
          });
          await alert.present();
          reviewObjectIsValid = false;
          this.changeSubmittedToFalse();
          break;
        }
      }
      if (reviewObjectIsValid) {
        this.dataStorageService
          .sendReview(
            this.mo_token,
            this.order_id,
            this.store_id ? this.store_id : this.currentStore.store_id,
            this.androidPlatform
              ? "android"
              : this.iOSPlatform
              ? "ios"
              : "browser",
            reviewQuestionsArrayAnswers
          )
          .subscribe({
            next: async (res: any) => {
              if (!res || !res.success) {
                this.loading = false;
                this.changeSubmittedToFalse();
                const alert = await this.alertController.create({
                  header: this.translate.instant("alert"),
                  message:
                    res && res.comment_id
                      ? this.translate.instant(
                          "orders.order-rating-modal." + res.comment_id
                        )
                      : this.translate.instant("errorMessages.general_error"),
                  backdropDismiss: false,
                  buttons: ["OK"],
                });
                await alert.present();
              } else {
                this.loading = false;
                let userHasToReviewTheApp = this.checkIfUserMustReviewTheApp(
                  reviewQuestionsArrayAnswers
                );
                let askedForReviewsNotificationPermissions;
                if (navigator.cookieEnabled) {
                  askedForReviewsNotificationPermissions = localStorage.getItem(
                    "askedForReviewsNotificationPermissions"
                  );
                }

                if (
                  !askedForReviewsNotificationPermissions ||
                  askedForReviewsNotificationPermissions === "no"
                ) {
                  this.OpenCustomerPrefsModal(
                    res,
                    {
                      currentUser: this.currentUser,
                      mo_token: this.mo_token,
                      order_id: this.order_id,
                      currentStore: this.currentStore,
                      platform: this.androidPlatform
                        ? "android"
                        : this.iOSPlatform
                        ? "ios"
                        : "browser",
                      reviewQuestionsArrayAnswers: reviewQuestionsArrayAnswers,
                    },
                    userHasToReviewTheApp
                  );
                } else {
                  this.presentReviewTheAppAlert(userHasToReviewTheApp, true);
                  this.modalController.dismiss();
                }
              }
            },
            error: async (err) => {
              this.loading = false;
              const alert = await this.alertController.create({
                header: this.translate.instant("alert"),
                message: this.translate.instant(
                  "errorMessages.problem_reaching_server"
                ),
                buttons: ["OK"],
              });
              await alert.present();
              return err;
            },
          });
      } else {
        this.loading = false;
      }
    }
  }

  async OpenCustomerPrefsModal(resData, sentReviewData, userHasToReviewTheApp) {
    this.customerPrefsModal = await this.modalController.create({
      cssClass: "customerPrefsModal",
      component: CustomerPreferencesModalComponent,
      componentProps: {
        currentUser: this.currentUser,
        mo_token: this.mo_token,
      },
    });
    this.customerPrefsModal.present();
    this.customerPrefsModal.onDidDismiss().then((data) => {
      this.customerPrefsModal = null;
      if (data && data.data) {
        if (data.data === "success") {
          this.changeSubmittedToFalse();
          this.presentReviewTheAppAlert(userHasToReviewTheApp, false);
          if (
            this.currentUser &&
            this.currentUser.alertReview &&
            this.currentUser.alertReview.push
          ) {
            console.log("ask permission for one signal");
            this.oneSignalService.OneSignalPrompt();
          }
          this.modalController.dismiss();
        }
      } else {
        if (
          this.currentUser &&
          this.currentUser.alertReview &&
          this.currentUser.alertReview.push
        ) {
          console.log("ask permission for one signal");
          this.oneSignalService.OneSignalPrompt();
        }
        this.changeSubmittedToFalse();
        this.presentReviewTheAppAlert(userHasToReviewTheApp, false);
        this.modalController.dismiss();
      }
    });
  }

  async presentReviewTheAppAlert(userHasToReviewTheApp, showSuccessMessage) {
    if (Capacitor.isNativePlatform()) {
      if (
        (userHasToReviewTheApp === "android" ||
          userHasToReviewTheApp === "ios") &&
        (!this.currentUser.ratingAppDialogShownTimestamp ||
          (
            this.currentUser.ratingAppDialogShownTimestamp &&
            moment(
              parseInt(
                this.currentUser.ratingAppDialogShownTimestamp.toString()
              )
            )
          ).isBefore(moment().subtract(1, "years")))
      ) {
        const modal = await this.modalController.create({
          component: AppRatingModalComponent,
          cssClass: "rateAppModal",
        });
        await modal.present();
        await modal.onDidDismiss().then((data) => {
          if (data && data.data && data.data === "yes") {
            this.rateAppMobileActions(userHasToReviewTheApp);
          }
        });
      } else {
        if (showSuccessMessage) {
          const alert = await this.alertController.create({
            message: this.translate.instant(
              "orders.order-rating-modal.review_sent_successfully"
            ),
            backdropDismiss: false,
            buttons: ["OK"],
          });
          await alert.present();
        }
      }
    } else {
      if (showSuccessMessage) {
        const alert = await this.alertController.create({
          message: this.translate.instant(
            "orders.order-rating-modal.review_sent_successfully"
          ),
          backdropDismiss: false,
          buttons: ["OK"],
        });
        await alert.present();
      }
    }
  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }

  updateCustomerData(updatedUser) {
    this.dataStorageService
      .updateCustomerData({ updatedData: updatedUser }, this.mo_token)
      .pipe(
        catchError((err) => {
          console.log("error", err);
          return err;
        })
      )
      .subscribe((res: any) => {
        if (!res && !res.succees) {
          if (res.comment_id) {
            this.presentErrorToast(
              this.translate.instant(res.comment_id),
              "danger"
            );
          } else if (res.comments) {
            this.presentErrorToast(res.comments, "danger");
          }
        } else {
          //console.log("writing user to state");
          this.store.dispatch(
            new AuthActions.SetUser(_.cloneDeep(updatedUser))
          );
          this.store.dispatch(
            new manuallyTriggerFinalPriceActions.SetTriggerCheckAddressTimestamp(
              Date.now().toString()
            )
          );
        }
      });
  }

  rateAppMobileActions(userHasToReviewTheApp) {
    if (
      this.groupApp &&
      this.groupApp.androidUrl &&
      userHasToReviewTheApp === "android"
    ) {
      RateApp.requestReview();
      this.currentUser.ratingAppDialogShownTimestamp = Date.now().toString();
      this.updateCustomerData(_.cloneDeep(this.currentUser));
    } else if (
      this.groupApp &&
      this.groupApp.iOSUrl &&
      userHasToReviewTheApp === "ios"
    ) {
      RateApp.requestReview();

      this.currentUser.ratingAppDialogShownTimestamp = Date.now().toString();
      this.updateCustomerData(_.cloneDeep(this.currentUser));
    } else {
      console.log("no android or ios urls");
    }
  }
  ngOnDestroy() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.customerPrefsModal) {
      this.customerPrefsModal.dismiss();
      this.customerPrefsModal = null;
    }
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }
}
