import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { AlertController, ModalController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { Subscription, distinctUntilChanged, auditTime, first } from "rxjs";
import * as fromApp from "../../../../../../store/app.reducer";
import * as selectors from "../../../../../../store/app.selectors";
import * as _ from "lodash";
import { TranslateService } from "@ngx-translate/core";
import { ProductDetailsOperationsService } from "src/app/product-details/product-details-operations.service";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import * as SelectedProductActions from "../../../../../store/selectedProduct.actions";

@Component({
  selector: "app-bundle-option-radio-closed-choices-modal",
  templateUrl: "./bundle-option-radio-closed-choices-modal.component.html",
  styleUrls: ["./bundle-option-radio-closed-choices-modal.component.scss"],
})
export class BundleOptionRadioClosedChoicesModalComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() selectedProductId: any;
  @Input() bundleMode: any;
  @Input() option_id: any;
  @Input() selectedProductPromo: any;
  @Input() selectedProductCategory: any;
  @Input() rootProductParentBundleChoice;
  @Input() groupStoreData;
  @Output() optionChange = new EventEmitter();
  public fetchProductsOnProgress: number = 0;
  public option;
  public loginState: any;
  public windowDimensions: any;
  public bundleChoiceProductsLoading = false;
  public customerPricelist;

  private orderPickup;
  private dineIn;
  private subscriptions: Subscription[] = [];
  private optionSubscription: Subscription;
  private unsubscribeBackEvent: Subscription;
  private pricelistsSub: Subscription;
  private loginStateSubscription: any;

  constructor(
    private store: Store<fromApp.AppState>,
    private modalCtrl: ModalController,
    private platform: Platform,
    private translateService: TranslateService,
    private alertController: AlertController,
    private productDetailsOperationsService: ProductDetailsOperationsService,
    private dataStorageService: DataStorageService
  ) {}

  selectOption() {
    if (this.optionSubscription) {
      this.optionSubscription.unsubscribe();
    }
    this.optionSubscription = this.store
      .select(
        selectors.getOptionByOptionId(this.selectedProductId, this.option_id)
      )
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (!_.isEqual(this.option, state)) {
          this.option = _.cloneDeep(state);
          if (!this.loginStateSubscription) {
            this.loginStateSubscription = this.store
              .select("auth")
              .pipe(distinctUntilChanged())
              .subscribe((state) => {
                if (
                  state &&
                  state.loginState &&
                  !_.isEqual(this.loginState, state.loginState)
                ) {
                  this.loginState = _.cloneDeep(state.loginState);
                  if (this.loginState === "no_login") {
                    this.fetchBundleProductsForChoices();
                  } else if (this.loginState === "login_completed") {
                    this.subscribeToCustomerPrices();
                  }
                }
              });
          }

          // console.log("option from modal closed choices", this.option);
        }
      });
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (
      simpleChanges &&
      simpleChanges.option_id &&
      simpleChanges.option_id.currentValue !==
        simpleChanges.option_id.previousValue
    ) {
      this.selectOption();
    }
  }

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      300,
      () => {
        if (this.platform.is("android")) {
          this.modalCtrl.dismiss();
        }
      }
    );
    this.selectOption();
    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(auditTime(50))
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.windowDimensions &&
            !_.isEqual(this.windowDimensions, state.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
            console.log(
              "window dem from radio closed choices selection",
              this.windowDimensions
            );
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("deliveryMethodAddress")

        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && !_.isEqual(this.orderPickup, state.orderPickup)) {
            this.orderPickup = _.cloneDeep(state.orderPickup);
          }

          if (state && !_.isEqual(this.dineIn, state.dineIn)) {
            this.dineIn = _.cloneDeep(state.dineIn);
          }
        })
    );
  }

  subscribeToCustomerPrices() {
    if (this.loginStateSubscription) {
      this.loginStateSubscription.unsubscribe();
      this.loginStateSubscription = true;
    }

    this.pricelistsSub = this.store
      .select("catalog")
      .pipe(distinctUntilChanged())
      .subscribe((catalogState) => {
        if (
          catalogState &&
          !_.isEqual(catalogState.customerPricelist, this.customerPricelist)
        ) {
          this.customerPricelist = _.cloneDeep(catalogState.customerPricelist);
          console.log("catalog pricelists called", this.customerPricelist);

          this.fetchBundleProductsForChoices();
        }
      });
  }

  fetchBundleProductsForChoices() {
    this.store
      .select(
        selectors.getSelectedProductBySelectedProductId(this.selectedProductId)
      )
      .pipe(first())
      .subscribe((root_parent_product) => {
        if (root_parent_product) {
          this.fetchBundleProductsForChoicesActions(root_parent_product);
        }
      })
      .unsubscribe();
  }

  fetchBundleProductsForChoicesActions(root_parent_product) {
    if (this.option && this.option.choices) {
      let fetchBundledProducts = false;
      _.each(this.option.choices, (choice) => {
        if (!choice.bundled_product || _.isEmpty(choice.bundled_product)) {
          fetchBundledProducts = true;
        }
      });

      if (fetchBundledProducts) {
        this.store
          .select("groupApp")
          .pipe(first())
          .subscribe((groupState) => {
            this.store
              .select(selectors.getStoreInfo)
              .pipe(first())
              .subscribe((storeInfo) => {
                if (storeInfo.store_id) {
                  this.store
                    .select("deliveryMethodAddress")
                    .pipe(first())
                    .subscribe((deliveryState) => {
                      this.store
                        .select(
                          selectors.getSelectedProductBySelectedProductId(
                            this.selectedProductId
                          )
                        )
                        .pipe(first())
                        .subscribe((currentProduct) => {
                          this.bundleChoiceProductsLoading = true;
                          this.dataStorageService
                            .getAllProductDetails(
                              storeInfo.store_id,
                              groupState && groupState.groupApp
                                ? groupState.groupApp.group
                                : storeInfo.group,
                              this.option.product_id_option_id,
                              groupState.selectedLangugage,
                              deliveryState.orderPickup,
                              deliveryState.dineIn,
                              currentProduct.product_id,
                              storeInfo.enable_customer_pricelists,
                              storeInfo.enable_customer_pricelists_mobileorder,
                              this.customerPricelist,
                              deliveryState && deliveryState.selectedDineInTable
                                ? deliveryState.selectedDineInTable.table_id
                                : null,
                              {
                                preorder:
                                  deliveryState.selectedPreorderDate &&
                                  deliveryState.selectedPreorderDate.date &&
                                  deliveryState.selectedPreorderDate.hour
                                    ? true
                                    : false,
                                preorder_day: deliveryState.selectedPreorderDate
                                  ? deliveryState.selectedPreorderDate.date
                                  : null,
                                preorder_time:
                                  deliveryState.selectedPreorderDate
                                    ? deliveryState.selectedPreorderDate.hour
                                    : null,
                              },
                              this.rootProductParentBundleChoice,
                              deliveryState.selectedAddress
                            )
                            .subscribe({
                              next: async (res: any) => {
                                if (res && res.success) {
                                  const response_bundle_option =
                                    res.bundle_option;
                                  if (response_bundle_option) {
                                    const preselected_choice_index =
                                      _.findIndex(this.option.choices, {
                                        is_preselected: true,
                                      });

                                    if (preselected_choice_index !== -1) {
                                      response_bundle_option.choices[
                                        preselected_choice_index
                                      ].is_preselected = true;
                                    } else {
                                      this.bundleChoiceProductsLoading = false;
                                    }
                                    this.option = _.cloneDeep(
                                      response_bundle_option
                                    );

                                    this.store
                                      .select(
                                        selectors.getSelectedProductBySelectedProductId(
                                          this.selectedProductId
                                        )
                                      )
                                      .pipe(first())
                                      .subscribe((currentProduct) => {
                                        if (currentProduct) {
                                          let selectedProduct =
                                            _.cloneDeep(currentProduct);
                                          if (
                                            selectedProduct &&
                                            selectedProduct.options
                                          ) {
                                            let optionIndex = _.findIndex(
                                              selectedProduct.options,
                                              {
                                                option_id:
                                                  this.option.option_id,
                                              }
                                            );
                                            if (optionIndex !== -1) {
                                              selectedProduct.options[
                                                optionIndex
                                              ] = _.cloneDeep(this.option);
                                              console.log(
                                                "set selectedProduct",
                                                currentProduct.options[
                                                  optionIndex
                                                ],

                                                selectedProduct.options[
                                                  optionIndex
                                                ]
                                              );

                                              // console.log("fhfhfhfhfhfhf");
                                              this.store.dispatch(
                                                new SelectedProductActions.SetSelectedProduct(
                                                  _.cloneDeep(selectedProduct)
                                                )
                                              );
                                            }
                                          }
                                        }
                                      })
                                      .unsubscribe();
                                  }

                                  _.each(
                                    response_bundle_option.choices,
                                    (choice, index) => {
                                      if (
                                        choice.is_preselected &&
                                        choice.bundled_product &&
                                        choice.activeTotal
                                      ) {
                                        this.store
                                          .select("cart")
                                          .pipe(first())
                                          .subscribe((cartState) => {
                                            if (cartState) {
                                              let product_id = /.+?(?=_)/.exec(
                                                this.option.product_id_option_id
                                              )[0];
                                              const findProductIndex =
                                                _.findIndex(
                                                  cartState.products,
                                                  {
                                                    cart_id:
                                                      root_parent_product.cart_id,
                                                  }
                                                );

                                              if (findProductIndex !== -1) {
                                                this.actionsForCartPreselectedChoice(
                                                  cartState.products,
                                                  root_parent_product,
                                                  choice,
                                                  storeInfo,
                                                  groupState,
                                                  index
                                                );
                                              } else {
                                                this.store
                                                  .select("previousOrders")
                                                  .pipe(first())
                                                  .subscribe(
                                                    (prevOrdersState) => {
                                                      if (
                                                        prevOrdersState &&
                                                        prevOrdersState.previousOrderCart
                                                      ) {
                                                        this.actionsForCartPreselectedChoice(
                                                          prevOrdersState.previousOrderCart,
                                                          root_parent_product,
                                                          choice,
                                                          storeInfo,
                                                          groupState,
                                                          index
                                                        );
                                                      }
                                                    }
                                                  );
                                              }
                                            } else {
                                              this.store
                                                .select("previousOrders")
                                                .pipe(first())
                                                .subscribe(
                                                  (prevOrdersState) => {
                                                    if (
                                                      prevOrdersState &&
                                                      prevOrdersState.previousOrderCart
                                                    ) {
                                                      this.actionsForCartPreselectedChoice(
                                                        prevOrdersState.previousOrderCart,
                                                        root_parent_product,
                                                        choice,
                                                        storeInfo,
                                                        groupState,
                                                        index
                                                      );
                                                    }
                                                  }
                                                );
                                            }
                                          })
                                          .unsubscribe();
                                      } else {
                                        this.bundleChoiceProductsLoading =
                                          false;
                                      }
                                    }
                                  );
                                } else {
                                  if (res && res.comment_id) {
                                    this.bundleChoiceProductsLoading = false;
                                    const alert =
                                      await this.alertController.create({
                                        header:
                                          this.translateService.instant(
                                            "alert"
                                          ),
                                        message:
                                          res && res.comment_id
                                            ? this.translateService.instant(
                                                res.comment_id
                                              )
                                            : this.translateService.instant(
                                                "errorMessages.general_error"
                                              ),

                                        buttons: ["OK"],
                                      });
                                    await alert.present();
                                  } else if (res && res.comments) {
                                    this.bundleChoiceProductsLoading = false;
                                    const alert =
                                      await this.alertController.create({
                                        header:
                                          this.translateService.instant(
                                            "alert"
                                          ),
                                        message: this.translateService.instant(
                                          res.comments
                                        ),

                                        buttons: ["OK"],
                                      });
                                    await alert.present();
                                  }
                                }
                              },
                              error: async (err) => {
                                this.bundleChoiceProductsLoading = false;
                                const alert = await this.alertController.create(
                                  {
                                    header:
                                      this.translateService.instant("alert"),
                                    message: this.translateService.instant(
                                      "errorMessages.problem_reaching_server"
                                    ),

                                    buttons: ["OK"],
                                  }
                                );
                                await alert.present();
                                return err;
                              },
                            });
                        })
                        .unsubscribe();
                    })
                    .unsubscribe();
                }
              });
          });
      }
    }
  }

  actionsForCartPreselectedChoice(
    cart_products,
    root_parent_product,
    choice,
    storeInfo,
    groupState,
    index
  ) {
    let cartProducts = _.cloneDeep(cart_products);
    let product_id = /.+?(?=_)/.exec(this.option.product_id_option_id)[0];
    const findProductIndex = _.findIndex(cartProducts, {
      cart_id: root_parent_product.cart_id,
    });
    console.log(
      "findProduct",
      cartProducts,
      findProductIndex,
      product_id,
      root_parent_product.cart_id
    );
    if (findProductIndex !== -1) {
      const findOptionIndex = _.findIndex(
        cartProducts[findProductIndex].options,
        {
          option_id: this.option.option_id,
        }
      );

      console.log("findOptionIndex", findOptionIndex);
      if (findOptionIndex !== -1) {
        let findChoiceIndex = -1;
        if (
          cartProducts[findProductIndex].options[findOptionIndex] &&
          cartProducts[findProductIndex].options[findOptionIndex].bundle &&
          cartProducts[findProductIndex].options[findOptionIndex]
            .bundle_options &&
          cartProducts[findProductIndex].options[findOptionIndex].bundle_options
            .load_category_choice_products
        ) {
          findChoiceIndex = _.findIndex(
            cartProducts[findProductIndex].options[findOptionIndex].choices,
            {
              bundled_with_product_id: choice.bundled_with_product_id,
            }
          );
        } else {
          console.log(
            "no_load_category_choice_products",
            cartProducts[findProductIndex].options[findOptionIndex].choices,
            choice.option_id_choice_id
          );
          findChoiceIndex = _.findIndex(
            cartProducts[findProductIndex].options[findOptionIndex].choices,
            {
              option_id_choice_id: choice.option_id_choice_id,
            }
          );
        }

        console.log("findChoiceIndex", findChoiceIndex);
        if (findChoiceIndex !== -1) {
          this.fetchProductWithCartId(
            cartProducts[findProductIndex].options[findOptionIndex].choices[
              findChoiceIndex
            ].bundled_product,
            storeInfo.store_id,
            groupState.selectedLangugage,
            index
          );
        }
      }
    }
  }

  optionChanged(option) {
    // console.log("option changedcccc");
    this.modalCtrl.dismiss(option);
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  sleep(ms: number) {
    return new Promise((r) => setTimeout(r, ms));
  }

  fetchAllProducts(store_id, languagePath, products_for_fetch) {
    //TO_DO CUSTOMER PRICE LISTS CHECKS
    // if (
    //   !this.customerPricelist ||
    //   (this.customerPricelist && !this.customerPricelist.length)
    // ) {
    // } else if (this.customerPricelist && this.customerPricelist.length) {
    // }
    //console.log("fetchProduct");
  }

  getStoreInfo() {
    return new Promise((resolve, reject) => {
      this.store
        .select(selectors.getStoreInfo)
        .pipe(first())
        .subscribe((storeInfo) => {
          resolve(storeInfo);
        });
    });
  }

  fetchProductWithCartId(
    cartProduct,
    store_id,
    languagePath,
    bundle_choice_index
  ) {
    console.log(
      "fetch product with cart id",
      this.option,
      this.option.choices,
      bundle_choice_index
    );
    if (this.option && this.option.choices && bundle_choice_index !== -1) {
      this.dataStorageService
        .getProductDetails(store_id, cartProduct.product_id, languagePath)
        .subscribe({
          next: async (res: any) => {
            let product = _.cloneDeep(res.productDetails);

            const storeInfo: any = await this.getStoreInfo();
            const userStoreNopricesMode: any =
              await this.productDetailsOperationsService.checkUserCatalogZeroPricesMode();
            if (
              (storeInfo && storeInfo.catalogZeroPricesMode) ||
              userStoreNopricesMode
            ) {
              product =
                await this.productDetailsOperationsService.deleteAllProductPrices(
                  _.cloneDeep(product)
                );
            }

            product = await this.checkUpdateStockValues(_.cloneDeep(product));

            this.store
              .select(selectors.getStoreInfo)
              .pipe(first())
              .subscribe((store_info) => {
                this.store
                  .select("deliveryMethodAddress")
                  .pipe(first())
                  .subscribe(async (DeliveryState) => {
                    // console.log("Product", product);

                    product.quantity = cartProduct.quantity;
                    product.gift = cartProduct.gift;

                    product =
                      this.productDetailsOperationsService.initializeProductOptionsCheckbox(
                        _.cloneDeep(product)
                      );

                    product =
                      this.productDetailsOperationsService.calculateBundleProductIgnoreOptionPrices(
                        _.cloneDeep(
                          this.option.choices[bundle_choice_index]
                            .bundled_product
                        ),
                        _.cloneDeep(this.option.choices[bundle_choice_index]),
                        _.cloneDeep(product)
                      );
                    product =
                      this.productDetailsOperationsService.calculateBundleProductOptionsExceptions(
                        _.cloneDeep(product),
                        _.cloneDeep(this.option.choices[bundle_choice_index])
                      );

                    // The following script only runs in here, because this is the only way that the product has options
                    if (cartProduct.options) {
                      this.initializeOptions(
                        cartProduct.options,
                        product,

                        DeliveryState,
                        store_info
                      );
                    }

                    product =
                      this.productDetailsOperationsService.checkIsDependentOptions(
                        _.cloneDeep(product)
                      );
                    product =
                      this.productDetailsOperationsService.productAllOptionsPriceComments(
                        _.cloneDeep(product),
                        DeliveryState.orderPickup,
                        DeliveryState.dineIn,
                        store_info && store_info.dinein
                          ? store_info.alt_dinein_active
                          : false,
                        DeliveryState.selectedDineInTable
                      );
                    console.log(":call product price");
                    product = _.cloneDeep(
                      this.productDetailsOperationsService.cartProductPrice(
                        product,
                        DeliveryState.orderPickup,
                        DeliveryState.dineIn,
                        _.cloneDeep(this.option.choices[bundle_choice_index]),
                        true,
                        store_info && store_info.dinein
                          ? store_info.alt_dinein_active
                          : false,
                        DeliveryState.selectedDineInTable,
                        store_info.decimalPlacesPrices
                      )
                    );

                    product =
                      await this.productDetailsOperationsService.calculateSelectedProductPoints(
                        _.cloneDeep(product),
                        product.productTotal,
                        true
                      );
                    const selectChoiceToOpenProductDetailsModal =
                      await this.productDetailsOperationsService.checkSelectChoiceAgainBundleProductPreorderAvailability(
                        _.cloneDeep(product),
                        DeliveryState.selectedPreorderDate,
                        DeliveryState.dineIn
                      );

                    console.log(
                      "selectChoiceAgaing",
                      selectChoiceToOpenProductDetailsModal
                    );

                    if (
                      product &&
                      !product.availableTotal &&
                      !selectChoiceToOpenProductDetailsModal
                    ) {
                      this.option.choices[bundle_choice_index].is_preselected =
                        false;
                    }

                    this.option.choices[bundle_choice_index].bundled_product =
                      _.cloneDeep(product);

                    if (selectChoiceToOpenProductDetailsModal) {
                      this.modalCtrl.dismiss(this.option);
                    }

                    this.store
                      .select(
                        selectors.getSelectedProductBySelectedProductId(
                          this.selectedProductId
                        )
                      )
                      .pipe(first())
                      .subscribe((currentProduct) => {
                        if (currentProduct) {
                          let selectedProduct = _.cloneDeep(currentProduct);
                          if (selectedProduct && selectedProduct.options) {
                            let optionIndex = _.findIndex(
                              selectedProduct.options,
                              {
                                option_id: this.option.option_id,
                              }
                            );
                            if (optionIndex !== -1) {
                              selectedProduct.options[optionIndex].choices[
                                bundle_choice_index
                              ] = _.cloneDeep(
                                this.option.choices[bundle_choice_index]
                              );
                              console.log("set selectedProduct");
                              this.store.dispatch(
                                new SelectedProductActions.SetSelectedProduct(
                                  _.cloneDeep(selectedProduct)
                                )
                              );
                            }
                          }
                        }
                      })
                      .unsubscribe();
                    //assin to choice
                    this.bundleChoiceProductsLoading = false;
                  });
              });
          },
        });
    }
  }

  async checkUpdateStockValues(selectedProduct) {
    return new Promise((resolve) => {
      if (
        selectedProduct &&
        (selectedProduct.availability_stock_enabled ||
          selectedProduct.preorder_availability_stock_enabled)
      ) {
        this.store
          .select(selectors.getStoreInfo)
          .pipe(first())
          .subscribe((storeInfo) => {
            this.store
              .select("deliveryMethodAddress")
              .pipe(first())
              .subscribe((deliveryState) => {
                this.store
                  .select("groupApp")
                  .subscribe((groupAppState) => {
                    if (groupAppState && deliveryState) {
                      this.dataStorageService
                        .fetchProductDetailsUpdatedData(
                          storeInfo.store_id,
                          groupAppState.groupApp.group,
                          selectedProduct.product_id,
                          groupAppState.selectedLangugage,
                          deliveryState.orderPickup,
                          deliveryState.dineIn,
                          deliveryState.selectedPreorderDate
                            ? {
                                preorder:
                                  deliveryState.selectedPreorderDate &&
                                  deliveryState.selectedPreorderDate.date &&
                                  deliveryState.selectedPreorderDate.hour
                                    ? true
                                    : false,
                                preorder_day:
                                  deliveryState.selectedPreorderDate.date,
                                preorder_time:
                                  deliveryState.selectedPreorderDate.hour,
                              }
                            : null
                        )
                        .subscribe({
                          next: async (res: any) => {
                            if (!res || !res.success) {
                              const alert = await this.alertController.create({
                                header: this.translateService.instant("alert"),
                                message:
                                  res && res.comment_id
                                    ? this.translateService.instant(
                                        res.comment_id
                                      )
                                    : this.translateService.instant(
                                        "errorMessages.general_error"
                                      ),
                                backdropDismiss: false,
                                buttons: ["OK"],
                              });

                              await alert.present();
                            } else {
                              if (res && res.product) {
                                selectedProduct = _.assign(
                                  _.cloneDeep(selectedProduct),
                                  res.product
                                );
                              }
                            }

                            resolve(_.cloneDeep(selectedProduct));
                          },
                          error: async (err) => {
                            const alert = await this.alertController.create({
                              header: this.translateService.instant("alert"),
                              message: this.translateService.instant(
                                "errorMessages.problem_reaching_server"
                              ),

                              buttons: ["OK"],
                            });
                            await alert.present();

                            resolve(_.cloneDeep(selectedProduct));
                          },
                        });
                    } else {
                      resolve(_.cloneDeep(selectedProduct));
                    }
                  })
                  .unsubscribe();
              })
              .unsubscribe();
          })
          .unsubscribe();
      } else {
        resolve(_.cloneDeep(selectedProduct));
      }
    });
  }

  initializeOptions(
    cartOptions,
    product,

    DeliveryState,
    store_info
  ) {
    console.log(
      "cartOptions",
      "selectedProductOptions",
      cartOptions,
      product.options
    );
    if (!_.isEmpty(cartOptions)) {
      _.each(_.cloneDeep(cartOptions), (option) => {
        console.log("cartOpption", option);
        if (option.type === "field") {
          var optionIndex = _.findIndex(product.options, {
            option_id: option.option_id,
          });
          product.options[optionIndex].text = option.text;
        } else if (option.type === "checkbox" || option.type === "radio") {
          var optionIndex = _.findIndex(product.options, {
            option_id: option.option_id,
          });
          console.log(
            "product.options",
            product.options,
            product.options[optionIndex]
          );

          product.options[optionIndex].option_parent_total_price =
            option.option_parent_total_price;
          product.options[optionIndex].parent_free = option.parent_free;

          _.each(product.options[optionIndex].choices, (choice) => {
            choice.is_preselected = false;
          });
          _.each(option.choices, (choice) => {
            if (choice.is_preselected) {
              // The filter for the active and available had already been placed in the calculateFinalPrice lambda
              if (
                option.bundle &&
                choice.bundle &&
                choice.bundled_with_product_id &&
                choice.bundled_product
              ) {
                const choiceIndex = _.findIndex(
                  product.options[optionIndex].choices,
                  { choice_id: choice.choice_id }
                );
                console.log(
                  "preselcted choices of selected product from cart",
                  choiceIndex,
                  product.options[optionIndex].choices[choiceIndex]
                );
                if (choiceIndex !== -1) {
                  product.options[optionIndex].choices[
                    choiceIndex
                  ].bundled_product = choice.bundled_product;
                }
              }
              this.clickingOnChoice(option.option_id, choice, product);
              console.log("step", choice.quantityInChoicesStep);
              if (
                choice.quantity > 1 &&
                (!choice.quantityInChoicesStep ||
                  choice.quantityInChoicesStep === 1)
              ) {
                _.each(new Array(choice.quantity - 1), (key) => {
                  this.choiceQuantityIncrease(
                    option.option_id,
                    choice.choice_id,
                    product
                  );
                });
              } else if (
                choice.quantity > choice.quantityInChoicesStep &&
                choice.quantityInChoicesStep &&
                choice.quantityInChoicesStep !== 1
              ) {
                var clicksToMake =
                  (choice.quantity - choice.quantityInChoicesStep) /
                  choice.quantityInChoicesStep;

                console.log("clicksToMake", clicksToMake);
                _.each(new Array(Math.round(clicksToMake)), (key) => {
                  this.choiceQuantityIncrease(
                    option.option_id,
                    choice.choice_id,
                    product
                  );
                });
              } else if (
                choice.quantity < choice.quantityInChoicesStep &&
                choice.quantityInChoicesStep &&
                choice.quantityInChoicesStep !== 1
              ) {
                var clicksToMake =
                  (1 - choice.quantity) / choice.quantityInChoicesStep;
                console.log(
                  "clicksToMake",
                  clicksToMake,
                  choice.quantity,
                  choice.quantityInChoicesStep
                );
                _.each(new Array(Math.round(clicksToMake)), (key) => {
                  this.choiceQuantityDecrease(
                    option.option_id,
                    choice.choice_id,
                    product
                  );
                });
              }
            }
          });
          this.assignFreeChoices(option.option_id, product);
          this.calculateTotalChoiceQuantity(option.option_id, product);

          this.productDetailsOperationsService.productChoicesPriceComment(
            option.option_id,
            DeliveryState.orderPickup,
            DeliveryState.dineIn,
            product,
            store_info && store_info.dinein
              ? store_info.alt_dinein_active
              : false,
            DeliveryState.selectedDineInTable
          );
        }
      });
      console.log("product options after initialization", product.options);
    }
  }

  choiceQuantityDecrease(option_id, choice_id, product) {
    var optionIndex = _.findIndex(product.options, {
      option_id: option_id,
    });
    var choiceIndex = _.findIndex(product.options[optionIndex].choices, {
      choice_id: choice_id,
    });

    var qtyStep =
      product.options[optionIndex].choices[choiceIndex].quantityInChoicesStep ||
      1;
    console.log("qtyStep", qtyStep);
    if (
      product.options[optionIndex].choices[choiceIndex].quantity > qtyStep &&
      product.options[optionIndex].choices[choiceIndex].is_preselected &&
      product.options[optionIndex].choices[choiceIndex].activeTotal &&
      product.options[optionIndex].choices[choiceIndex].availableTotal
    ) {
      product.options[optionIndex].choices[choiceIndex].quantity =
        product.options[optionIndex].choices[choiceIndex].quantity - qtyStep;
    }
  }

  calculateTotalChoiceQuantity(option_id, product) {
    if (!option_id) {
      return;
    }
    var optionIndex = _.findIndex(product.options, {
      option_id: option_id,
    });
    var choicesQuantity = 0;
    _.each(product.options[optionIndex].choices, (choice) => {
      if (
        choice.is_preselected &&
        choice.activeTotal &&
        choice.availableTotal
      ) {
        choicesQuantity = choicesQuantity + choice.quantity;
      }
    });

    if (!product.options[optionIndex].optionData) {
      product.options[optionIndex].optionData = {};
    }

    product.options[optionIndex].optionData.freeChoices =
      choicesQuantity >= parseInt(product.options[optionIndex].freeChoices)
        ? product.options[optionIndex].freeChoices
        : choicesQuantity;
    product.options[optionIndex].optionData.maxChoices =
      choicesQuantity >= parseInt(product.options[optionIndex].maxChoices)
        ? product.options[optionIndex].maxChoices
        : choicesQuantity;
    product.options[optionIndex].optionData.minChoices =
      choicesQuantity >= parseFloat(product.options[optionIndex].minChoices)
        ? product.options[optionIndex].minChoices
        : choicesQuantity;
  }

  clickingOnChoice(option_id, choice, product) {
    console.log("clickingOnChoice", option_id, choice);

    var optionIndex = _.findIndex(product.options, {
      option_id: option_id,
    });
    var choiceIndex = _.findIndex(product.options[optionIndex].choices, {
      choice_id: choice.choice_id,
    });

    console.log(optionIndex, choiceIndex);

    if (
      !product.options[optionIndex].availableTotal ||
      !product.options[optionIndex].choices[choiceIndex].availableTotal ||
      (product.options[optionIndex].dependenciesVariable &&
        product.options[optionIndex].dependenciesVariable.length > 0 &&
        !product.options[optionIndex].dependentHidden &&
        !product.options[optionIndex].showDependent)
    ) {
      console.log("option/choice is not available");
      return;
    }

    if (
      !product.options[optionIndex].choices[choiceIndex].is_preselected &&
      !this.checkMaximumChoices(option_id, product)
    ) {
      product.options[optionIndex].choices[choiceIndex].is_preselected = false;
      //Toast.show($rootScope.languages["MAXIMUM CHOICES ALLOWED"][$rootScope.selectedLanguage] + ": " + $rootScope.currentProduct.options[optionIndex].maxChoices);
      console.log("toast Show");
      //TO_DO SHOW A ERRO MESSAGE
      return;
    }

    product.options[optionIndex].choices[choiceIndex].is_preselected =
      !product.options[optionIndex].choices[choiceIndex].is_preselected;

    if (
      product.options[optionIndex].choices[choiceIndex].is_preselected &&
      choice.selected_timestamp
    ) {
      product.options[optionIndex].choices[choiceIndex].selected_timestamp =
        _.cloneDeep(choice.selected_timestamp);
    }
    // product.options[optionIndex].choices[choiceIndex].quantity = 1;

    if (
      product.options[optionIndex].choices[choiceIndex].is_preselected &&
      choice.selected_timestamp
    ) {
      product.options[optionIndex].choices[choiceIndex].selected_timestamp =
        _.cloneDeep(choice.selected_timestamp);
    }
  }

  choiceQuantityIncrease(option_id, choice_id, product) {
    var optionIndex = _.findIndex(product.options, {
      option_id: option_id,
    });
    var choiceIndex = _.findIndex(product.options[optionIndex].choices, {
      choice_id: choice_id,
    });

    var qtyStep =
      product.options[optionIndex].choices[choiceIndex].quantityInChoicesStep ||
      1;
    console.log("qtyStep", qtyStep);
    if (
      product.options[optionIndex].choices[choiceIndex].is_preselected &&
      product.options[optionIndex].choices[choiceIndex].activeTotal &&
      product.options[optionIndex].choices[choiceIndex].availableTotal
    ) {
      if (this.checkMaximumChoices(option_id, product)) {
        product.options[optionIndex].choices[choiceIndex].quantity =
          product.options[optionIndex].choices[choiceIndex].quantity + qtyStep;
      } else {
        // Toast.show(
        //   $rootScope.languages["MAXIMUM CHOICES ALLOWED"][
        //     $rootScope.selectedLanguage
        //   ] +
        //     ": " +
        //     $rootScope.currentProduct.options[optionIndex].maxChoices
        // );
        console.log("show Toast");
      }
    }
  }

  checkMaximumChoices(option_id, product) {
    //I need to calculate here the choice quantity!

    let option = _.find(product.options, {
      option_id: option_id,
    });

    var optionsMaxChoices = parseInt(option.maxChoices);
    var counter = 0;

    if (!_.isNaN(optionsMaxChoices) && optionsMaxChoices > 0) {
      _.each(_.sortBy(option.choices, "price"), (choice, key, list) => {
        if (
          choice.is_preselected &&
          choice.activeTotal &&
          choice.availableTotal
        ) {
          counter = counter + choice.quantity;
        }
      });
      console.log(counter, optionsMaxChoices);
      if (counter >= optionsMaxChoices) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  assignFreeChoices(option_id, product) {
    if (!option_id) {
      // There are some options that do not have option_id
      return;
    }
    var optionIndex = _.findIndex(product.options, {
      option_id: option_id,
    });

    var optionsFreeChoices = parseInt(product.options[optionIndex].freeChoices);

    let sortByVariable: any = "price";

    if (
      product &&
      product.options &&
      product.options[optionIndex] &&
      product.options[optionIndex].freeChoices &&
      product.options[optionIndex].freeChoicesBySelectionOrder
    ) {
      sortByVariable = "selected_timestamp";
    }

    if (
      product &&
      product.options &&
      product.options[optionIndex] &&
      product.options[optionIndex].freeChoices &&
      product.options[optionIndex].freeChoicesByPosition
    ) {
      sortByVariable = (choice, index) => index;
    }

    const sortedChoices = _.sortBy(
      product.options[optionIndex].choices,
      (choice, index) => {
        if (sortByVariable === "price") {
          if (this.orderPickup && choice.price_takeaway) {
            sortByVariable = "price_takeway";
            return choice.price_takeaway;
          } else if (this.dineIn && choice.price_dinein) {
            sortByVariable = "price_dinein";
            return choice.price_dinein;
          }
          return choice.price;
        } else if (typeof sortByVariable === "function") {
          return sortByVariable(choice, index);
        } else {
          return choice[sortByVariable];
        }
      }
    );

    console.log("sortedChoices", sortedChoices);

    _.each(sortedChoices, (choice) => {
      if (
        this.dineIn &&
        choice.price_dinein === 0 &&
        choice.price === 0 &&
        product.options[optionIndex].freeChoicesbyPriceSkipZeros
      ) {
        return;
      }
      if (
        this.orderPickup &&
        choice.price_takeaway === 0 &&
        choice.price === 0 &&
        product.options[optionIndex].freeChoicesbyPriceSkipZeros
      ) {
        return;
      }

      if (
        !this.orderPickup &&
        !this.dineIn &&
        choice.price === 0 &&
        product.options[optionIndex].freeChoicesbyPriceSkipZeros
      ) {
        return;
      }

      if (
        optionsFreeChoices > 0 &&
        choice.is_preselected &&
        choice.activeTotal &&
        choice.availableTotal
      ) {
        product.options[optionIndex].choices[
          _.findIndex(product.options[optionIndex].choices, {
            choice_id: choice.choice_id,
          })
        ].isFree = true;
        if (choice.quantity > 1) {
          if (optionsFreeChoices - choice.quantity >= 0) {
            product.options[optionIndex].choices[
              _.findIndex(product.options[optionIndex].choices, {
                choice_id: choice.choice_id,
              })
            ].freeQuantity = choice.quantity;
            optionsFreeChoices -= choice.quantity;
          } else {
            product.options[optionIndex].choices[
              _.findIndex(product.options[optionIndex].choices, {
                choice_id: choice.choice_id,
              })
            ].freeQuantity = optionsFreeChoices;
            optionsFreeChoices -= optionsFreeChoices;
          }
        } else {
          product.options[optionIndex].choices[
            _.findIndex(product.options[optionIndex].choices, {
              choice_id: choice.choice_id,
            })
          ].freeQuantity = 1;
          optionsFreeChoices -= 1;
        }
      } else {
        product.options[optionIndex].choices[
          _.findIndex(product.options[optionIndex].choices, {
            choice_id: choice.choice_id,
          })
        ].isFree = false;
        product.options[optionIndex].choices[
          _.findIndex(product.options[optionIndex].choices, {
            choice_id: choice.choice_id,
          })
        ].freeQuantity = 0;
      }
    });

    optionsFreeChoices = null;
  }

  checkForCustomerPricelist(selectedProduct) {
    return new Promise((resolve) => {
      if (selectedProduct && selectedProduct.price) {
        this.store
          .select(selectors.getStoreInfo)
          .pipe(first())
          .subscribe((storeInfo) => {
            if (
              storeInfo &&
              storeInfo.enable_customer_pricelists_mobileorder &&
              storeInfo.enable_customer_pricelists
            ) {
              this.store
                .select("catalog")
                .pipe(first())
                .subscribe((catalogState) => {
                  if (
                    catalogState &&
                    catalogState.customerPricelist &&
                    catalogState.customerPricelist.length > 0
                  ) {
                    let customerProducts = _.cloneDeep(
                      catalogState.customerPricelist
                    );
                    _.each(customerProducts, (customerProduct) => {
                      if (
                        customerProduct.product_id ===
                        selectedProduct.product_id
                      ) {
                        if (customerProduct.price) {
                          selectedProduct.price = _.cloneDeep(
                            parseFloat(
                              customerProduct.price.toString()
                            ).toFixed(2)
                          );
                        }

                        if (customerProduct.price_takeaway) {
                          selectedProduct.price_takeaway = _.cloneDeep(
                            parseFloat(
                              customerProduct.price_takeaway.toString()
                            ).toFixed(2)
                          );
                        }
                      }
                    });

                    resolve(selectedProduct);
                  } else {
                    resolve(selectedProduct);
                  }
                });
            } else {
              resolve(selectedProduct);
            }
          });
      } else {
        resolve(selectedProduct);
      }
    });
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    if (this.optionSubscription) {
      this.optionSubscription.unsubscribe();
    }
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
  }
}
