import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  AlertController,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { Store } from "@ngrx/store";
import { auditTime, distinctUntilChanged } from "rxjs";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import * as fromApp from "../../../store/app.reducer";
import * as _ from "lodash";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-migrate-points",
  templateUrl: "./migrate-points.component.html",
  styleUrls: ["./migrate-points.component.scss"],
})
export class MigratePointsComponent implements OnInit, OnDestroy {
  public memberNumberPhone: any;
  migrationLoading: boolean = false;
  mo_token: any;
  authSub: any;
  unsubscribeBackEvent: any;
  progress: number = 0;
  isProgressModalOpen: boolean = false;
  progressInterval: NodeJS.Timer;
  constructor(
    private modalController: ModalController,
    private dataStorageService: DataStorageService,
    private store: Store<fromApp.AppState>,
    private toastController: ToastController,
    private alertController: AlertController,
    private translateService: TranslateService,
    private platform: Platform
  ) {}

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      300,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );
    this.authSub = this.store
      .select("auth")
      .pipe(auditTime(200))
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (state && !_.isEqual(this.mo_token, state.mo_token)) {
          this.mo_token = _.cloneDeep(state.mo_token);
        }
      });
  }

  closeModal() {
    this.modalController.dismiss();
  }

  async openConfirmationAlertNoOldMemberNumber() {
    const alert = await this.alertController.create({
      header: "Είστε σίγουρος;",
      message: "Επιβεβαιώνω ότι δεν έχω παλιά κάρτα μέλους!",
      buttons: [
        {
          text: this.translateService.instant("yes"),
          handler: () => {
            localStorage.setItem(
              "noOldMemberNumberConfirmed",
              Date.now().toString()
            );
          },
        },
        {
          text: this.translateService.instant("cancel"),
          handler: () => {},
        },
      ],
    });

    alert.present();
  }

  migratePoints() {
    console.log("memberNumberPhone: ", this.memberNumberPhone);

    if (!this.migrationLoading) {
      this.isProgressModalOpen = true;
      this.progressInterval = setInterval(() => {
        console.log("insde interval");
        this.progress += 1 / 300;

        if (this.progress > 1) {
          clearInterval(this.progressInterval);
        }
      }, 50);

      this.migrationLoading = true;
      this.dataStorageService
        .migrateCustomerPoints(this.mo_token, this.memberNumberPhone)
        .subscribe({
          next: async (res: any) => {
            if (res && res.success) {
              console.log(res);
              this.progress = 1;
              clearInterval(this.progressInterval);
              this.migrationLoading = false;

              const alert = await this.alertController.create({
                header: this.translateService.instant("alert"),
                message: this.translateService.instant("success"),
                buttons: [
                  {
                    text: this.translateService.instant("ok"),
                    handler: () => {
                      console.log("Confirm");
                      this.isProgressModalOpen = false;
                      this.progress = 0;
                    },
                  },
                ],
              });
              await alert.present();
            } else {
              this.migrationLoading = false;
              clearInterval(this.progressInterval);

              const alert = await this.alertController.create({
                header: this.translateService.instant("error"),
                message: this.translateService.instant(
                  `loyaltySystem.loyalty-more-page.${res.comment_id}`
                ),
                buttons: [
                  {
                    text: this.translateService.instant("ok"),
                    handler: () => {
                      this.isProgressModalOpen = false;
                      this.progress = 0;
                      console.log("Confirm");
                    },
                  },
                ],
              });
              await alert.present();
            }
          },
          error: (err) => {
            this.migrationLoading = false;
            clearInterval(this.progressInterval);

            console.log(err);

            this.presentErrorToast(err.message, "danger");
          },
        });
    }
  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            this.isProgressModalOpen = false;
            this.progress = 0;
            console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }

  ngOnDestroy(): void {
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    clearInterval(this.progressInterval);
    this.progress = 0;
  }
}
