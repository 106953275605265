<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">
      {{ "orders.order-rating-modal.rate-order" | translate }}
      <div *ngIf="order_id">
       #{{ order_id }}
      </div>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button color="dark" fill="clear" (click)="closeModal()">
        <ion-icon slot="icon-only" src="assets/ionicons/close.svg"> </ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid>
    <lib-user-review
      *ngIf="
        currentStore &&
        currentStore.reviewQuestionsArray &&
        currentStore.reviewQuestionsArray.length
      "
      [reviewQuestionsArray]="currentStore.reviewQuestionsArray"
      [selectedLanguage]="selectedLanguage"
      [submitted]="submitted"
      (reviewAnswersEvent)="onAnswerEvent($event)"
    ></lib-user-review>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-row style="width: 100%">
    <ion-col class="ion-text-center">
      <ion-button [disabled]="loading" (click)="submitReview()"
        ><ion-text *ngIf="!loading">
          {{ "orders.order-rating-modal.save" | translate }}
        </ion-text>
        <ion-spinner *ngIf="loading"> </ion-spinner>
      </ion-button>
    </ion-col>
  </ion-row>
</ion-footer>
