<!-- <ion-header>
  <!-- <ion-grid style="max-width: 800px; border-bottom: 2px solid white"> -->
<!-- <ion-toolbar mode="md">
      <ion-title color="white">
        {{ "popups.select-store-popup.choose-store-title" | translate }}
      </ion-title>
      <ion-button
        (click)="closeModal()"
        slot="end"
        size="small"
        fill="clear"
        color="white"
        style="z-index: 1000"
        ><ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon
      ></ion-button>
    </ion-toolbar> -->
<!-- </ion-grid> 
</ion-header> -->
<ion-button
  (click)="closeModal()"
  size="small"
  fill="clear"
  color="white"
  style="position: absolute; top: 20px; right: 10px; z-index: 1000"
  ><ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon
></ion-button>
<ion-content (click)="closeModal()">
  <ion-grid
    class="centerGrid"
    style="height: 100%"
    *ngIf="
      stores &&
      stores.length &&
      !settingsPopupMode &&
      !showValidStores &&
      selectedLangugage &&
      windowDimensions &&
      !validStores
    "
  >
    <ion-row
      style="width: 100%; height: 100%"
      class="ion-justify-content-center ion-align-items-center"
    >
      <ion-col
        style="width: 100%"
        size="12"
        class="ion-text-center ion-align-self-center"
      >
        <ion-row
          style="width: 100%"
          class="ion-justify-content-center ion-align-items-center"
        >
          <ion-col
            *ngFor="
              let store of stores | filterStoresSelection : false : validStores
            "
            class="ion-text-center"
            [size]="
              windowDimensions && windowDimensions.width > 650 ? '6' : '12'
            "
          >
            <ion-grid [class]="'grid300'">
              <ion-card
                mode="md"
                button="true"
                (click)="selectStore(store.store_id)"
              >
                <div class="image-container">
                  <img
                    width="100%"
                    [src]="
                      deliveryManagerPath +
                      store.store_id +
                      '/' +
                      'storeChangeCatalogButtonsIcons/' +
                      store.select_store_mobileorder_image
                    "
                  />
                  <div
                    style="
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                    "
                  >
                    <ion-text
                      color="white"
                      style="font-size: large; font-weight: bold"
                    >
                      {{
                        store.store_name_catalog_change_langs &&
                        store.store_name_catalog_change_langs[selectedLangugage]
                          ? store.store_name_catalog_change_langs[
                              selectedLangugage
                            ]
                          : store.store_name_catalog_change_langs &&
                            store.store_name_catalog_change_langs.el
                          ? store.store_name_catalog_change_langs.el
                          : store.store_name_mobileorder &&
                            store.store_name_mobileorder[selectedLangugage]
                          ? store.store_name_mobileorder[selectedLangugage]
                          : store.store_name_langs &&
                            store.store_name_langs[selectedLangugage]
                          ? store.store_name_langs[selectedLangugage]
                          : store.store_name_langs && store.store_name_langs.el
                          ? store.store_name_langs.el
                          : store.store_name
                          ? store.store_name
                          : ""
                      }}
                    </ion-text>
                  </div>
                </div>
              </ion-card>
            </ion-grid>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid
    class="centerGrid"
    style="height: 100%"
    *ngIf="
      stores &&
      stores.length &&
      showValidStores &&
      !settingsPopupMode &&
      renderedValidStores &&
      selectedLangugage
    "
  >
    <ion-row
      style="width: 100%; height: 100%"
      class="ion-justify-content-center ion-align-items-center"
    >
      <ion-col
        style="width: 100%"
        size="12"
        class="ion-text-center ion-align-self-center"
      >
        <ion-row
          style="width: 100%"
          class="ion-justify-content-center ion-align-items-center"
        >
          <ion-col
            *ngFor="
              let store of renderedValidStores
                | filterStoresSelection : true : validStores
            "
            class="ion-text-center"
            [size]="
              windowDimensions && windowDimensions.width > 650 ? '6' : '12'
            "
          >
            <ion-grid [class]="'grid300'">
              <ion-card
                mode="md"
                button="true"
                (click)="selectStore(store.store_id)"
              >
                <div class="image-container">
                  <img
                    width="100%"
                    [src]="
                      deliveryManagerPath +
                      store.store_id +
                      '/' +
                      'storeChangeCatalogButtonsIcons/' +
                      store.select_store_mobileorder_image
                    "
                  />
                  <div
                    style="
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                    "
                  >
                    <ion-text
                      color="white"
                      style="font-size: large; font-weight: bold"
                    >
                      {{
                        store.store_name_catalog_change_langs &&
                        store.store_name_catalog_change_langs[selectedLangugage]
                          ? store.store_name_catalog_change_langs[
                              selectedLangugage
                            ]
                          : store.store_name_catalog_change_langs &&
                            store.store_name_catalog_change_langs.el
                          ? store.store_name_catalog_change_langs.el
                          : store.store_name_mobileorder &&
                            store.store_name_mobileorder[selectedLangugage]
                          ? store.store_name_mobileorder[selectedLangugage]
                          : store.store_name_langs &&
                            store.store_name_langs[selectedLangugage]
                          ? store.store_name_langs[selectedLangugage]
                          : store.store_name_langs && store.store_name_langs.el
                          ? store.store_name_langs.el
                          : store.store_name
                          ? store.store_name
                          : ""
                      }}
                    </ion-text>
                  </div>
                </div>
              </ion-card>
            </ion-grid>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid
    class="centerGrid"
    style="height: 100%"
    *ngIf="
      stores &&
      stores.length &&
      settingsPopupMode &&
      selectedLangugage &&
      windowDimensions
    "
  >
    <ion-row
      style="width: 100%; height: 100%"
      class="ion-justify-content-center ion-align-items-center"
    >
      <ion-col
        style="width: 100%"
        size="12"
        class="ion-text-center ion-align-self-center"
      >
        <ion-row
          style="width: 100%"
          class="ion-justify-content-center ion-align-items-center"
        >
          <ion-col
            *ngFor="
              let store of stores | filterStoresSelection : false : validStores
            "
            class="ion-text-center"
            [size]="
              windowDimensions && windowDimensions.width > 650 ? '6' : '12'
            "
          >
            <ion-grid [class]="'grid300'">
              <ion-card
                mode="md"
                button="true"
                (click)="settingsPopupModeSelectStore(store.store_id)"
              >
                <div class="image-container">
                  <img
                    width="100%"
                    [src]="
                      deliveryManagerPath +
                      store.store_id +
                      '/' +
                      'storeChangeCatalogButtonsIcons/' +
                      store.select_store_mobileorder_image
                    "
                  />
                  <div
                    style="
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                    "
                  >
                    <ion-text
                      color="white"
                      style="font-size: large; font-weight: bold"
                    >
                      {{
                        store.store_name_catalog_change_langs &&
                        store.store_name_catalog_change_langs[selectedLangugage]
                          ? store.store_name_catalog_change_langs[
                              selectedLangugage
                            ]
                          : store.store_name_catalog_change_langs &&
                            store.store_name_catalog_change_langs.el
                          ? store.store_name_catalog_change_langs.el
                          : store.store_name_mobileorder &&
                            store.store_name_mobileorder[selectedLangugage]
                          ? store.store_name_mobileorder[selectedLangugage]
                          : store.store_name_langs &&
                            store.store_name_langs[selectedLangugage]
                          ? store.store_name_langs[selectedLangugage]
                          : store.store_name_langs && store.store_name_langs.el
                          ? store.store_name_langs.el
                          : store.store_name
                          ? store.store_name
                          : ""
                      }}
                    </ion-text>
                  </div>
                </div>
              </ion-card>
            </ion-grid>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
