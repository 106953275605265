import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { auditTime, distinctUntilChanged, Subscription } from "rxjs";
import * as fromApp from "../../../../store/app.reducer";
import * as selectors from "../../../../store/app.selectors";
import * as _ from "lodash";
import { PreorderValidationServiceService } from "src/app/services/preorder-validation-service/preorder-validation-service.service";

@Component({
  selector: "app-cart-preorder-item",
  templateUrl: "./cart-preorder-item.component.html",
  styleUrls: ["./cart-preorder-item.component.scss"],
})
export class CartPreorderItemComponent implements OnInit, OnDestroy {
  public stores: any;
  public currentUser: any;
  public finalPriceLoading: boolean;
  public checkStoreOnlineInfo: any;
  public store_info: any;
  public validStores: any;
  public preorderResponse: any;
  public selectedPreorderDate: any;
  public deliveryTimeIsNumber: any;
  public pickUpTimeIsNumber: any;
  public orderPickup: any;
  public dineIn: any;
  public preorderMode: boolean;
  public segment: string = "orderNow";
  private valid: boolean;

  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store<fromApp.AppState>,
    private preorderValidationService: PreorderValidationServiceService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((state) => {
          if (state && !_.isEqual(state.user, this.currentUser)) {
            this.currentUser = _.cloneDeep(state.user);
          }
        })
    );
    this.subscriptions.push(
      this.store.select("disableFinalPrice").subscribe((state) => {
        if (
          state &&
          !_.isEqual(state.disableFinalPrice, this.finalPriceLoading)
        ) {
          this.finalPriceLoading = _.cloneDeep(state.disableFinalPrice);
        }
      })
    );

    this.subscriptions.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((state) => {
          if (state && state.stores && !_.isEqual(state.stores, this.stores)) {
            this.stores = _.cloneDeep(state.stores);
          }
          if (
            state &&
            state.checkStoreOnlineInfo &&
            !_.isEqual(state.checkStoreOnlineInfo, this.checkStoreOnlineInfo)
          ) {
            this.checkStoreOnlineInfo = _.cloneDeep(state.checkStoreOnlineInfo);

            if (
              this.checkStoreOnlineInfo &&
              this.checkStoreOnlineInfo.deliveryTime &&
              this.checkStoreOnlineInfo.deliveryTime
                .toString()
                .match(/^[0-9]+$/) != null
            ) {
              this.deliveryTimeIsNumber = true;
            } else {
              this.deliveryTimeIsNumber = false;
            }
            if (
              this.checkStoreOnlineInfo &&
              this.checkStoreOnlineInfo.pickupDeliveryTime &&
              this.checkStoreOnlineInfo.pickupDeliveryTime
                .toString()
                .match(/^[0-9]+$/) != null
            ) {
              this.pickUpTimeIsNumber = true;
            } else {
              this.pickUpTimeIsNumber = false;
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("deliveryMethodAddress")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (state && !_.isEqual(this.orderPickup, state.orderPickup)) {
            this.orderPickup = _.cloneDeep(state.orderPickup);
            if (
              this.orderPickup != null &&
              !this.preorderValidationService.preorderModalIsOpen() &&
              state.preorderResponse &&
              !_.isEmpty(state.preorderResponse)
            ) {
              if (state.selectedPreorderDate) {
                console.log(
                  "call preorder valid calcs from preorder order pickup change"
                );
                this.preorderValidationService.preorderValidCalculations(
                  state.orderPickup,
                  state.dineIn,
                  state.preorderResponse.preorder_delivery_days,
                  state.preorderResponse.preorder_pickup_days,
                  state.selectedPreorderDate
                );
              }
            }
          }

          if (state && !_.isEqual(this.dineIn, state.dineIn)) {
            this.dineIn = _.cloneDeep(state.dineIn);
          }

          if (
            state &&
            !_.isEqual(this.selectedPreorderDate, state.selectedPreorderDate)
          ) {
            this.selectedPreorderDate = _.cloneDeep(state.selectedPreorderDate);
            if (
              this.selectedPreorderDate &&
              this.selectedPreorderDate.hour &&
              this.selectedPreorderDate.date
            ) {
              this.preorderMode = true;
            } else {
              this.preorderMode = false;
            }
          }

          if (
            state &&
            !_.isEqual(this.preorderResponse, state.preorderResponse)
          ) {
            console.log("preivous pickup", this.orderPickup, state.orderPickup);
            this.preorderResponse = _.cloneDeep(state.preorderResponse);
            if (
              state.orderPickup != null &&
              !this.preorderValidationService.preorderModalIsOpen() &&
              state.preorderResponse &&
              !_.isEmpty(state.preorderResponse)
            ) {
              if (state.selectedPreorderDate) {
                console.log(
                  "call preorder valid calcs from preorder response",
                  state.orderPickup,
                  state.preorderResponse
                );
                this.preorderValidationService.preorderValidCalculations(
                  state.orderPickup,
                  state.dineIn,
                  state.preorderResponse.preorder_delivery_days,
                  state.preorderResponse.preorder_pickup_days,
                  state.selectedPreorderDate
                );
              }
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .subscribe((store_info) => {
          if (store_info && !_.isEqual(this.store_info, store_info)) {
            this.store_info = _.cloneDeep(store_info);

            console.log("store Info Changed", this.store_info);
          }
        })
    );
  }

  presentPreorderModalFromCartButtons(fromCartButtons) {
    this.preorderValidationService.presentPreorderModalFromCartButtons(
      fromCartButtons
    );
  }

  presentPreorderModal(showNotValidDateToast) {
    this.preorderValidationService.presentPreorderModal(showNotValidDateToast);
  }

  ngOnDestroy() {
    if (this.preorderValidationService.preorderModalIsOpen()) {
      this.preorderValidationService.closePreorderModal();
    }

    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }
}
