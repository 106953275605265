<ion-item
  [ngClass]="{
    'line-through': !!(!option.availableTotal || !choice.availableTotal)
  }"
  *ngIf="choice && option"
  lines="none"
>
  <ion-label
    [class]="
      !option.availableTotal ||
      !choice.availableTotal ||
      (option.dependenciesVariable &&
        option.dependenciesVariable.length > 0 &&
        !option.dependentHidden &&
        !option.showDependent)
        ? 'ion-text-wrap opacityDisabled'
        : 'ion-text-wrap'
    "
  >
    <div>
      {{ choice.title }}
      <ion-icon
        [color]="
          groupStoreData &&
          groupStoreData.store_info &&
          groupStoreData.store_info.design_settings &&
          groupStoreData.store_info.design_settings.tagsColors &&
          groupStoreData.store_info.design_settings.tagsColors.frozen
            ? groupStoreData.store_info.design_settings.tagsColors.frozen
            : 'primary'
        "
        class="badge-margin"
        style="font-size: 10px"
        src="assets/flatIcons/frozen.svg"
        *ngIf="choice.tags && choice.tags.frozen"
      >
        <ion-text>{{ "item-tags.frozen" | translate }}</ion-text>
      </ion-icon>
    </div>

    <div>
      <app-product-choice-tags
        [optionItem]="option"
        [choiceItem]="choice"
        [selectedProductPromo]="selectedProductPromo"
        [groupStoreData]="groupStoreData"
      >
      </app-product-choice-tags>
    </div>
  </ion-label>

  <ion-checkbox
    [disabled]="
      !option.availableTotal ||
      !choice.availableTotal ||
      (option.dependenciesVariable &&
        option.dependenciesVariable.length > 0 &&
        !option.dependentHidden &&
        !option.showDependent)
    "
    [(ngModel)]="choice.is_preselected"
    (ionChange)="onCheckboxChanged($event)"
    slot="start"
  ></ion-checkbox>
  <ion-thumbnail slot="start">
    <img
      [alt]="choice.title"
      loading="lazy"
      *ngIf="imagePath"
      [src]="imagePath"
    />
  </ion-thumbnail>
  <ion-text
    class="item-text-wrap"
    *ngIf="!choice.isFree || choice.quantity !== choice.freeQuantity"
    ng-class="{
      'priceQuantity-with-thumb':
        option.quantityInChoices &&
        choice.is_preselected &&
        choice.image[browserPlatform] &&
        choice.image[browserPlatform][imageSettings.imageRatio] &&
        choice.image[browserPlatform][imageSettings.imageRatio].path,
      priceQuantity:
        option.quantityInChoices &&
        !choice.quantityInChoicesKind &&
        choice.is_preselected &&
        !(
          choice.image[browserPlatform] &&
          choice.image[browserPlatform][imageSettings.imageRatio] &&
          choice.image[browserPlatform][imageSettings.imageRatio].path
        ),
      priceQuantityWithStepKind:
        option.quantityInChoices &&
        choice.quantityInChoicesKind &&
        choice.is_preselected &&
        !(
          choice.image[browserPlatform] &&
          choice.image[browserPlatform][imageSettings.imageRatio] &&
          choice.image[browserPlatform][imageSettings.imageRatio].path
        ),
      'choice-title-with-thumb':
        choice.image[browserPlatform] &&
        choice.image[browserPlatform][imageSettings.imageRatio] &&
        choice.image[browserPlatform][imageSettings.imageRatio].path
    }"
  >
    <span
      [ngClass]="{
        disabled: !!(
          !option.availableTotal ||
          !choice.availableTotal ||
          (option.dependenciesVariable &&
            option.dependenciesVariable.length > 0 &&
            !option.dependentHidden &&
            !option.showDependent)
        ),
        'line-through ': !!(!option.availableTotal || !choice.availableTotal)
      }"
      *ngIf="
        ((orderPickup == false && dineIn == false) ||
          (orderPickup == null && dineIn == null) ||
          (orderPickup == true && dineIn == false && !choice.price_takeaway) ||
          (orderPickup == false &&
            dineIn == true &&
            (!alt_dinein_active ||
              !selectedDineInTable ||
              !selectedDineInTable.alt_dinein) &&
            !choice.price_dinein) ||
          (orderPickup == false &&
            dineIn == true &&
            alt_dinein_active &&
            selectedDineInTable &&
            selectedDineInTable.alt_dinein &&
            !choice.price_alt_dinein &&
            !choice.price_dinein)) &&
        choice.price.toString().trim().replace(',', '.') < 0
      "
      >{{
        choice.price.toString().trim().replace(",", ".") | number : "1.2-2"
      }}€</span
    >

    <span
      [ngClass]="{
        disabled: !!(
          !option.availableTotal ||
          !choice.availableTotal ||
          (option.dependenciesVariable &&
            option.dependenciesVariable.length > 0 &&
            !option.dependentHidden &&
            !option.showDependent)
        ),
        'line-through ': !!(!option.availableTotal || !choice.availableTotal)
      }"
      *ngIf="
        ((orderPickup == false && dineIn == false) ||
          (orderPickup == null && dineIn == null) ||
          (orderPickup == true && dineIn == false && !choice.price_takeaway) ||
          (orderPickup == false &&
            dineIn == true &&
            (!alt_dinein_active ||
              !selectedDineInTable ||
              !selectedDineInTable.alt_dinein) &&
            !choice.price_dinein) ||
          (orderPickup == false &&
            dineIn == true &&
            alt_dinein_active &&
            selectedDineInTable &&
            selectedDineInTable.alt_dinein &&
            !choice.price_alt_dinein)) &&
        choice.price.toString().trim().replace(',', '.') > 0
      "
      >{{ !option.changesBasePrice ? "+" : ""
      }}{{
        choice.price.toString().trim().replace(",", ".") | number : "1.2-2"
      }}€</span
    >

    <span
      [ngClass]="{
        disabled: !!(
          !option.availableTotal ||
          !choice.availableTotal ||
          (option.dependenciesVariable &&
            option.dependenciesVariable.length > 0 &&
            !option.dependentHidden &&
            !option.showDependent)
        ),
        'line-through ': !!(!option.availableTotal || !choice.availableTotal)
      }"
      *ngIf="
        orderPickup == true &&
        dineIn == false &&
        choice.price_takeaway &&
        choice.price_takeaway.toString().trim().replace(',', '.') < 0
      "
      >{{
        choice.price_takeaway.toString().trim().replace(",", ".")
          | number : "1.2-2"
      }}€</span
    >

    <span
      [ngClass]="{
        disabled: !!(
          !option.availableTotal ||
          !choice.availableTotal ||
          (option.dependenciesVariable &&
            option.dependenciesVariable.length > 0 &&
            !option.dependentHidden &&
            !option.showDependent)
        ),
        'line-through ': !!(!option.availableTotal || !choice.availableTotal)
      }"
      *ngIf="
        orderPickup == true &&
        dineIn == false &&
        choice.price_takeaway &&
        choice.price_takeaway.toString().trim().replace(',', '.') > 0
      "
      >{{ !option.changesBasePrice ? "+" : ""
      }}{{
        choice.price_takeaway.toString().trim().replace(",", ".")
          | number : "1.2-2"
      }}€</span
    >

    <span
      [ngClass]="{
        disabled: !!(
          !option.availableTotal ||
          !choice.availableTotal ||
          (option.dependenciesVariable &&
            option.dependenciesVariable.length > 0 &&
            !option.dependentHidden &&
            !option.showDependent)
        ),
        'line-through ': !!(!option.availableTotal || !choice.availableTotal)
      }"
      *ngIf="
        orderPickup == false &&
        dineIn == true &&
        choice.price_dinein &&
        (!alt_dinein_active ||
          !selectedDineInTable ||
          !selectedDineInTable.alt_dinein ||
          !choice.price_alt_dinein) &&
        choice.price_dinein.toString().trim().replace(',', '.') < 0
      "
      >{{
        choice.price_dinein.toString().trim().replace(",", ".")
          | number : "1.2-2"
      }}€</span
    >

    <span
      [ngClass]="{
        disabled: !!(
          !option.availableTotal ||
          !choice.availableTotal ||
          (option.dependenciesVariable &&
            option.dependenciesVariable.length > 0 &&
            !option.dependentHidden &&
            !option.showDependent)
        ),
        'line-through ': !!(!option.availableTotal || !choice.availableTotal)
      }"
      *ngIf="
        orderPickup == false &&
        dineIn == true &&
        (!alt_dinein_active ||
          !selectedDineInTable ||
          !selectedDineInTable.alt_dinein ||
          !choice.price_alt_dinein) &&
        choice.price_dinein &&
        choice.price_dinein.toString().trim().replace(',', '.') > 0
      "
      >{{ !option.changesBasePrice ? "+" : ""
      }}{{
        choice.price_dinein.toString().trim().replace(",", ".")
          | number : "1.2-2"
      }}€</span
    >

    <span
      [ngClass]="{
        disabled: !!(
          !option.availableTotal ||
          !choice.availableTotal ||
          (option.dependenciesVariable &&
            option.dependenciesVariable.length > 0 &&
            !option.dependentHidden &&
            !option.showDependent)
        ),
        'line-through ': !!(!option.availableTotal || !choice.availableTotal)
      }"
      *ngIf="
        orderPickup == false &&
        dineIn == true &&
        choice.price_alt_dinein &&
        alt_dinein_active &&
        selectedDineInTable &&
        selectedDineInTable.alt_dinein &&
        choice.price_alt_dinein &&
        choice.price_alt_dinein.toString().trim().replace(',', '.') < 0
      "
      >{{
        choice.price_alt_dinein.toString().trim().replace(",", ".")
          | number : "1.2-2"
      }}€</span
    >

    <span
      [ngClass]="{
        disabled: !!(
          !option.availableTotal ||
          !choice.availableTotal ||
          (option.dependenciesVariable &&
            option.dependenciesVariable.length > 0 &&
            !option.dependentHidden &&
            !option.showDependent)
        ),
        'line-through ': !!(!option.availableTotal || !choice.availableTotal)
      }"
      *ngIf="
        orderPickup == false &&
        dineIn == true &&
        choice.price_alt_dinein &&
        alt_dinein_active &&
        selectedDineInTable &&
        selectedDineInTable.alt_dinein &&
        choice.price_alt_dinein &&
        choice.price_alt_dinein.toString().trim().replace(',', '.') > 0
      "
      >{{ !option.changesBasePrice ? "+" : ""
      }}{{
        choice.price_alt_dinein.toString().trim().replace(",", ".")
          | number : "1.2-2"
      }}€</span
    >
  </ion-text>
  <ion-text
    align="center"
    class="mainFontSize categoriesWarningColor"
    [ngClass]="{
      disabled: !!(
        !option.availableTotal ||
        !choice.availableTotal ||
        (option.dependenciesVariable &&
          option.dependenciesVariable.length > 0 &&
          !option.dependentHidden &&
          !option.showDependent)
      ),
      'line-through ': !!(!option.availableTotal || !choice.availableTotal)
    }"
    *ngIf="choice.isFree && choice.quantity === choice.freeQuantity"
  >
    {{ "product-details.free" | translate }}
  </ion-text>
</ion-item>
