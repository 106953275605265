import { Component, Input, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import { Subscription } from "rxjs";
import { auditTime, distinctUntilChanged } from "rxjs/operators";
import * as fromApp from "../../../store/app.reducer";
import * as selectors from "../../../store/app.selectors";

@Component({
  selector: "app-product-items",
  templateUrl: "./product-items-browser.component.html",
  styleUrls: ["./product-items-browser.component.scss"],
})
export class ProductItemsBrowserComponent implements OnInit {
  @Input() product_id: any;
  @Input() productImage: any;
  @Input() category_id: any;
  @Input() hyperCategories: any;
  @Input() cartProductIds: any;
  @Input() favoriteProduct: boolean;
  @Input() orderPickup;
  @Input() dineIn;
  @Input() webp: any;
  @Input() groupStoreData;
  @Input() selectedDineInTable: any;
  @Input() selectedPreorderDate: any;

  constructor(private store: Store<fromApp.AppState>) {}

  ngOnInit() {}

  ngOnDestroy() {}
  //TO_DO CHECK THIS TRACKBY FOR RETURN ITEM ATTR
  trackFunc(index, item) {
    return index;
  }
}
