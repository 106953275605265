<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">
      <ion-text style="font-weight: bold">
        {{ "customerPreferencesModal.review_sent_successfully" | translate }}
      </ion-text>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button color="dark" fill="clear" (click)="closeModal()">
        <ion-icon slot="icon-only" src="assets/ionicons/close.svg"> </ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list *ngIf="currentUser && currentUser.alertReview">
    <ion-item lines="none">
      <ion-label class="ion-text-center ion-text-wrap">
        <ion-text style="font-weight: bold">
          {{
            "customerPreferencesModal.notification-for-store-answer-selection"
              | translate
          }}
        </ion-text>
      </ion-label>
    </ion-item>
    <ion-item>
      <ion-label>
        <div>
          {{ "account-settings.by-email" | translate }}
        </div>

        <p
          *ngIf="!currentUser.alertReview.email && !currentUser.email"
          style="font-size: small"
        >
          <ion-text color="danger">
            {{ "customerPreferencesModal.no_email_saved" | translate }}
          </ion-text>
        </p>

        <p *ngIf="currentUser.alertReview.email && currentUser.email">
          <ion-text color="primary">
            {{ currentUser.email }}
          </ion-text>
        </p>
      </ion-label>
      <ion-checkbox
        slot="start"
        [disabled]="currentUser.email ? false : true"
        [(ngModel)]="currentUser.alertReview.email"
      ></ion-checkbox>
    </ion-item>

    <ion-item>
      <ion-label>
        <div>
          {{ "account-settings.by-push" | translate }}
        </div>
      </ion-label>
      <ion-checkbox
        slot="start"
        [(ngModel)]="currentUser.alertReview.push"
      ></ion-checkbox>
    </ion-item>

    <ion-item>
      <ion-label>
        {{ "account-settings.by-sms" | translate }}

        <p
          *ngIf="currentUser.alertReview.sms && currentUser.mobile"
          style="font-size: small"
        >
          <ion-text color="primary">
            {{ currentUser.mobile }}
          </ion-text>
        </p>
      </ion-label>
      <ion-checkbox
        slot="start"
        [(ngModel)]="currentUser.alertReview.sms"
      ></ion-checkbox>
    </ion-item>

    <ion-item lines="none">
      <ion-label class="ion-text-center ion-text-wrap" style="line-height: 1.2">
        <ion-text style="font-weight: bold; font-size: small">
          {{
            "orderCustomerNotificationsModal.you-can-always-change-settings-from-account-settings"
              | translate
          }}
        </ion-text>
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>

<ion-footer>
  <ion-grid>
    <ion-row style="width: 100%">
      <ion-col size="12" class="ion-text-center">
        <ion-button
          [disabled]="updatingDataLoading"
          (click)="saveCustomerPreferences()"
        >
          <ion-text *ngIf="!updatingDataLoading">
            {{ "customerPreferencesModal.save" | translate }}
          </ion-text>

          <ion-spinner *ngIf="updatingDataLoading"></ion-spinner>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
