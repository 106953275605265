import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { AlertController, ModalController } from "@ionic/angular";
import moment from "moment";
import { ProductDetailsComponent } from "src/app/product-details/product-details.component";
import * as _ from "lodash";
import { ProductDetailsOperationsService } from "src/app/product-details/product-details-operations.service";
import { first } from "rxjs/operators";
import * as CartActions from "../../../../cart/store/cart.actions";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../../store/app.reducer";
import { TranslateService } from "@ngx-translate/core";
declare let window: any;

@Component({
  selector: "app-product-item-market-type-card-browser",
  templateUrl: "./product-item-market-type-card-browser.component.html",
  styleUrls: ["./product-item-market-type-card-browser.component.scss"],
})
export class ProductItemMarketTypeCardBrowserComponent implements OnInit {
  @ViewChild("productTitle") productTitleRef: ElementRef;
  @Input() groupStoreData;
  @Input() selectedDineInTable;
  @Input() selectedPreorderDate;
  @Input() category: any;
  @Input() product: any;
  @Input() webp: any;
  @Input() orderPickup: any;
  @Input() dineIn: any;
  @Input() unavailableModal: boolean;
  @Input() selectedLanguage;
  @Input() forCustomerPricelists: boolean;
  @Input() finalPriceLoading: boolean;
  @Input() productIncludedInCart: any;
  @Input() cartProductIds;
  public productInCart: boolean = false;
  public productInCartCounter: number = 0;

  preorderDateIsAfterToday;

  private productDetailsModal: any;

  constructor(
    private modalCtrl: ModalController,
    private cdRef: ChangeDetectorRef,
    private productDetailsOperationsService: ProductDetailsOperationsService,
    private alertCtrl: AlertController,
    private translateService: TranslateService,
    private changeDetectro: ChangeDetectorRef,
    private store: Store<fromApp.AppState>
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.selectedPreorderDate &&
      !_.isEqual(
        changes.selectedPreorderDate.previousValue,
        changes.selectedPreorderDate.currentValue
      )
    ) {
      this.checkPreorderDate();
    }

    if (
      changes &&
      changes.cartProductIds &&
      changes.cartProductIds.currentValue !==
        changes.cartProductIds.previousValue &&
      changes.cartProductIds.previousValue
    ) {
      this.checkIfProductIsAddedToCart();
    }
  }

  checkIfProductIsAddedToCart() {
    this.productInCartCounter = 0;
    if (this.cartProductIds && this.product) {
      this.productInCart = _.includes(
        this.cartProductIds,
        this.product.product_id
      );

      this.productInCartCounter = _.filter(this.cartProductIds, (id) => {
        if (id === this.product.product_id) {
          return id;
        }
      }).length;
    }
  }
  addProductToCart(product) {
    console.log(this.groupStoreData);
    if (
      product &&
      product.num_of_options &&
      product.num_of_options <= 1 &&
      this.groupStoreData &&
      this.groupStoreData.store_info &&
      this.groupStoreData.groupApp
    ) {
      this.finalPriceLoading = true;
      this.productDetailsOperationsService.addProductToCartFromCatalogInstant(
        this.groupStoreData.store_info.store_id,
        product.product_id,
        this.groupStoreData.groupApp.selectedLanguage,
        this.groupStoreData.forCustomerPricelists,
        null,
        null,
        null
      );
    } else {
      this.showProductDetailsModal(product);
    }
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngOnInit() {
    this.checkIfProductIsAddedToCart();
  }

  checkPreorderDate() {
    if (
      this.selectedPreorderDate &&
      this.selectedPreorderDate.date &&
      this.selectedPreorderDate.hour
    ) {
      const preorderDate = moment(
        `${this.selectedPreorderDate.date} ${this.selectedPreorderDate.hour}`,
        "DD-MM-YYYY HH:mm"
      );

      const tomorrowDate = moment()
        .add(1, "day")
        .set("hour", 0)
        .set("minutes", 0);

      if (preorderDate.isAfter(tomorrowDate)) {
        this.preorderDateIsAfterToday = true;
      } else {
        this.preorderDateIsAfterToday = false;
      }
    } else {
      this.preorderDateIsAfterToday = false;
    }
  }

  prdWithoutImage(product) {
    return (
      !product.image ||
      (product.image && !product.image.true) ||
      (product.image && !product.image.true["16x9"]) ||
      (product.image &&
        product.image.true["16x9"] &&
        !product.image.true["16x9"].path)
    );
  }

  showProductDetailsModal(product) {
    let detailsCssClass;
    if (
      product &&
      product.num_of_options &&
      product.num_of_options < 2 &&
      this.prdWithoutImage(product)
    ) {
      detailsCssClass = "productDetailsModalSmall";
    } else if (
      product &&
      product.num_of_options &&
      !product.squareImageDesign &&
      product.num_of_options > 2 &&
      !this.prdWithoutImage(product)
    ) {
      detailsCssClass = "productDetailsModalBig";
    } else if (
      product &&
      product.squareImageDesign &&
      product.num_of_options &&
      product.num_of_options > 2 &&
      window &&
      window.innerWidth &&
      window.innerWidth >= 1200 &&
      !this.prdWithoutImage(product)
    ) {
      detailsCssClass = "productDetailsModalBigSquareImageDesign";
    } else {
      detailsCssClass = "productDetailsModal";
    }
    if (!this.productDetailsModal) {
      this.productDetailsModal = true;
      this.presentProductDetailsModal(product, detailsCssClass);
    }
  }

  changeProductQuantity(ev, prd) {
    if (ev === "increase") {
      this.increaseQuantity();
    } else if (ev === "decrease") {
      this.decreaseQuantity();
    }
    // console.log(ev);
  }

  deleteCartItem(product) {
    this.store.dispatch(new CartActions.DeleteCartProductItem(product));
  }

  increaseQuantity() {
    if (
      this.productIncludedInCart &&
      this.productIncludedInCart.quantity < 50
    ) {
      let tempProduct = _.cloneDeep(this.productIncludedInCart);
      if (
        this.productIncludedInCart.enable_quantity_step &&
        this.productIncludedInCart.quantity_step &&
        !this.productIncludedInCart.max_quantity &&
        parseFloat(
          parseFloat(
            _.cloneDeep(
              tempProduct.quantity + tempProduct.quantity_step
            ).toString()
          ).toFixed(2)
        ) < 50
      ) {
        tempProduct.quantity = parseFloat(
          parseFloat(
            _.cloneDeep(
              tempProduct.quantity + tempProduct.quantity_step
            ).toString()
          ).toFixed(2)
        );
      } else if (
        this.productIncludedInCart.enable_quantity_step &&
        this.productIncludedInCart.quantity_step &&
        this.productIncludedInCart.max_quantity &&
        parseFloat(
          parseFloat(
            _.cloneDeep(
              tempProduct.quantity + tempProduct.quantity_step
            ).toString()
          ).toFixed(2)
        ) < this.productIncludedInCart.max_quantity
      ) {
        tempProduct.quantity = parseFloat(
          parseFloat(
            _.cloneDeep(
              tempProduct.quantity + tempProduct.quantity_step
            ).toString()
          ).toFixed(2)
        );
      } else if (
        !this.productIncludedInCart.enable_quantity_step ||
        !this.productIncludedInCart.quantity_step
      ) {
        tempProduct.quantity++;
      }
      //console.log(tempProduct.quantity);
      //console.log("set selectedProduct");
      this.store.dispatch(
        new CartActions.AddUpdateCartProductItem(_.cloneDeep(tempProduct))
      );
    }
  }

  async decreaseQuantity() {
    let newCartProduct = _.cloneDeep(this.productIncludedInCart);

    if (
      newCartProduct &&
      newCartProduct.quantity > 0 &&
      newCartProduct.enable_quantity_step &&
      newCartProduct.quantity_step &&
      (newCartProduct.min_quantity
        ? parseFloat(
            parseFloat(
              _.cloneDeep(
                newCartProduct.quantity - newCartProduct.quantity_step
              ).toString()
            ).toFixed(2)
          ) >= newCartProduct.min_quantity
        : parseFloat(
            parseFloat(
              _.cloneDeep(
                newCartProduct.quantity - newCartProduct.quantity_step
              ).toString()
            ).toFixed(2)
          ) > 0)
    ) {
      newCartProduct.quantity = parseFloat(
        parseFloat(
          _.cloneDeep(
            newCartProduct.quantity - newCartProduct.quantity_step
          ).toString()
        ).toFixed(2)
      );

      this.store.dispatch(
        new CartActions.AddUpdateCartProductItem(_.cloneDeep(newCartProduct))
      );
    } else if (
      newCartProduct &&
      newCartProduct.quantity > 1 &&
      (!newCartProduct.enable_quantity_step || !newCartProduct.quantity_step)
    ) {
      newCartProduct.quantity--;
      this.store.dispatch(
        new CartActions.AddUpdateCartProductItem(newCartProduct)
      );
    } else if (
      (newCartProduct &&
        newCartProduct.quantity === 1 &&
        (!newCartProduct.enable_quantity_step ||
          !newCartProduct.quantity_step)) ||
      (newCartProduct &&
        newCartProduct.enable_quantity_step &&
        newCartProduct.quantity_step &&
        newCartProduct.quantity - newCartProduct.quantity_step <= 0)
    ) {
      const alert = await this.alertCtrl.create({
        header: this.translateService.instant("alert"),
        message: this.translateService.instant(
          "product-details.do-you-want-to-remove-product-from-cart"
        ),

        buttons: [
          {
            text: this.translateService.instant("no"),
            role: "cancel",
            handler: () => {
              console.log("canceled");
            },
          },
          {
            text: this.translateService.instant("yes"),
            handler: () => {
              this.store
                .select("cart")
                .pipe(first())
                .subscribe((cartState) => {
                  const product_index_cart = _.findIndex(cartState.products, {
                    product_id: this.productIncludedInCart.product_id,
                    cart_id: this.productIncludedInCart.cart_id,
                  });

                  if (product_index_cart !== -1) {
                    if (
                      cartState &&
                      cartState.products &&
                      cartState.products.length
                    ) {
                      let cart = _.cloneDeep(cartState.products);
                      cart.splice(product_index_cart, 1);

                      this.store.dispatch(
                        new CartActions.SetCartProducts(_.cloneDeep(cart))
                      );
                    }
                  }
                })
                .unsubscribe();
            },
          },
        ],
      });
      await alert.present();
    }
  }

  async presentProductDetailsModal(product, detailsCssClass) {
    let product_id: string;
    let cart_id: string;
    if (product && product.product_id) {
      product_id = product.product_id;
    }

    if (
      this.productIncludedInCart &&
      this.productIncludedInCart.cart_id &&
      this.product.num_of_options <= 1
    ) {
      cart_id = this.productIncludedInCart.cart_id;
    }

    this.productDetailsModal = await this.modalCtrl.create({
      component: ProductDetailsComponent,
      cssClass: detailsCssClass,
      animated: true,
      componentProps: {
        product_id: product_id,
        cart_id: cart_id,
      },
      backdropDismiss: false,
    });

    await this.productDetailsModal.present();
    this.productDetailsModal.onDidDismiss().then(() => {
      this.productDetailsModal = null;
    });
  }

  ngOnDestroy(): void {
    if (this.productDetailsModal) {
      this.productDetailsModal.dismiss();
      this.productDetailsModal = null;
    }
  }
}
