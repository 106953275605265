<ion-header>
  <app-no-internet-connection></app-no-internet-connection>
  <app-ionic-update-error></app-ionic-update-error>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="primary" (click)="modalCtrl.dismiss()">
        <ion-icon name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title *ngIf="storeInfo" size="small" mode="md"
      >{{ storeInfo.store_name_mobileorder &&
      storeInfo.store_name_mobileorder[selectedLanguage] ?
      storeInfo.store_name_mobileorder[selectedLanguage] :
      storeInfo.store_name_langs && storeInfo.store_name_langs[selectedLanguage]
      ? storeInfo.store_name_langs[selectedLanguage] :
      storeInfo.store_name_langs && storeInfo ? storeInfo.store_name_langs.el :
      storeInfo.store_name }}</ion-title
    >
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="artist" class="item-list">
  <ion-grid>
    <ion-row *ngIf="selectedProduct">
      <ion-col [size]="columnSize2">
        <ion-item lines="none">
          <ion-text style="font-size: 24px; font-weight: bold"
            >{{"reservation-app.chooseDate" |translate}}</ion-text
          >
        </ion-item>
        <ion-item lines="none" style="margin-top: 10px">
          <ion-text
            >{{"reservation-app.service" |translate}}
            <span style="font-weight: bold"> {{selectedProduct.name}}</span>
            <p></p>
            {{"reservation-app.duration" | translate}}
            {{selectedProduct.service_duration_min}} {{"minutes" | translate}}
            <p></p>
            <div *ngIf="!selectedProduct.gift">
              <span *ngIf="selectedProduct.productTotal">
                {{"reservation-app.cost" |translate}}
                {{selectedProduct.productTotal}}€
              </span>
            </div>

            <div *ngIf="selectedProduct.gift">
              <span>
                {{"reservation-app.cost" |translate}} {{'free' | translate}}
              </span>
            </div>
          </ion-text>
        </ion-item>
      </ion-col>
      <ion-col [size]="columnSize">
        <ion-item lines="none">
          <ion-text style="font-weight: bold"
            >{{"reservation-app.whenDoYouWant" |translate}}</ion-text
          >
        </ion-item>

        <swiper [config]="sliderOptions" (swiper)="setSwiperInstance($event)">
          <ng-template
            class="reservationAppSwiperSlide"
            swiperSlide
            *ngFor="let date of artist.dates; let i = index"
          >
            <ion-item
              lines="none"
              button="true"
              detail="false"
              (click)="handleSlideClick(date,i)"
            >
              <ion-label class="ion-text-wrap" style="margin: 0">
                <ion-chip
                  class="circular-chip"
                  [ngStyle]="{'background-color': slideColors[i],'color':letterColors[i]}"
                >
                  <ion-text style="font-weight: bold"
                    >{{ date.day |dateFormat }}</ion-text
                  >
                </ion-chip>
                <div class="ion-text-center">{{ date.day |dayName }}</div>
              </ion-label>
            </ion-item>
          </ng-template>
        </swiper>

        <ion-item
          lines="none"
          *ngIf="selectedDateIndex>=0"
          style="margin-top: 20px"
        >
          <ion-label style="font-weight: bold"
            >{{"reservation-app.whatTime" |translate}}</ion-label
          >
        </ion-item>
        <ion-list *ngIf="selectedDateIndex >= 0">
          <ion-item
            mode="md"
            style="
              margin-left: 10px;
              margin-right: 10px;
              border-radius: 20px;
              margin-top: 10px;
            "
            lines="none"
            color="light"
            button="true"
            detail="false"
            *ngFor="let hour of allHours; let i = index last as isLast"
            (click)="selectHour(i, hour)"
            [class]="i === selectedHourIndex ? 'box' : ''"
            [style.margin-bottom]="isLast ? '40px' : '0'"
            [disabled]="!enabledHours3[i]"
          >
            <ion-label class="ion-text-center">{{ hour }}</ion-label>
          </ion-item>

          <ion-label
            style="margin-left: 20px"
            *ngIf="artist.dates[selectedDateIndex].hours.length === 0"
            class="ion-text-center"
          >
            {{"reservation-app.emptyDate"|translate}}
          </ion-label>
        </ion-list>
      </ion-col>
    </ion-row>

    <div class="button-wrapper">
      <div [ngClass]="{'wobble-animation': isWobble}">
        <ion-button
          *ngIf="selectedHour"
          shape="round"
          expand="block"
          (click)="submitDate()"
        >
          {{"reservation-app.continue" |translate}}
        </ion-button>
      </div>
    </div>
  </ion-grid>
</ion-content>
