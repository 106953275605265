<div
  *ngIf="
    storeInfo &&
    storeInfo.staticPagesCatalogDisclaimerActive &&
    catalogDisclaimer &&
    catalogDisclaimer.title
  "
>
  <ion-item lines="none" class="buttonItem">
    <ion-row style="width: 100%" class="ion-no-padding ion-no-margin">
      <ion-col class="ion-text-center ion-no-padding ion-no-margin">
        <ion-button
          (click)="showCatalogDisclaimerPopup()"
          size="small"
          fill="clear"
        >
          <ion-icon
            style="vertical-align: middle; font-size: 18px; margin-right: 3px"
            src="assets/ionicons/information-circle-outline.svg"
          >
          </ion-icon>
          <ion-text>
            {{
              catalogDisclaimer.title[selectedLanguage]
                ? catalogDisclaimer.title[selectedLanguage]
                : catalogDisclaimer.title[mo_base]
            }}
          </ion-text>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-item>
</div>
