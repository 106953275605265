import * as DisableFinalPriceActions from "./disable-final-price.actions";
import * as _ from "lodash";

export interface State {
  disableFinalPrice: boolean;
  triggerFinalPriceTimestamp: any;
}

const initialState: State = {
  disableFinalPrice: false,
  triggerFinalPriceTimestamp: null,
};

export function disableFinalPriceReducer(
  state = initialState,
  action: DisableFinalPriceActions.DisableFinalPriceActions
) {
  switch (action.type) {
    case DisableFinalPriceActions.SET_DISABLE_FINAL_PRICE:
      return {
        ...state,
        disableFinalPrice: action.payload,
      };
    case DisableFinalPriceActions.SET_TRIGGER_FINAL_PRICE_TIMESTAMP:
      return {
        ...state,
        triggerFinalPriceTimestamp: action.payload,
      };

    default:
      return state;
  }
}
