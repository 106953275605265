import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";
import * as _ from "lodash";

@Pipe({
  name: "kioskExpirationTimestampTimer",
})
export class KioskExpirationTimestampTimerPipe implements PipeTransform {
  transform(
    kiosk_expiration_timestamp: any,
    updatePipeTimerTimestamp: any
  ): any {
    const millsecondsTimestamp = parseInt(
      (parseInt(_.cloneDeep(kiosk_expiration_timestamp)) * 1000).toString()
    );
    const momentTimestamp = moment(parseInt(millsecondsTimestamp.toString()));

    const secondsRemaining = momentTimestamp.diff(moment(), "seconds");

    const minutes = parseInt(
      Math.floor(_.cloneDeep(secondsRemaining) / 60).toString()
    );
    const seconds = parseInt((_.cloneDeep(secondsRemaining) % 60).toString());

    return minutes + ":" + seconds;
  }
}
