<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">
      <ion-text color="primary" *ngIf="catalogDisclaimer && selectedLanguage">
        {{
          catalogDisclaimer.title[selectedLanguage]
            ? catalogDisclaimer.title[selectedLanguage]
            : catalogDisclaimer.title[mo_base]
        }}
      </ion-text>
    </ion-title>
    <ion-button
      (click)="closeModal()"
      size="small"
      slot="end"
      fill="clear"
      color="dark"
    >
      <ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <app-catalog-disclaimer
      [catalogDisclaimer]="catalogDisclaimer"
    ></app-catalog-disclaimer>
  </ion-grid>
</ion-content>
