<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">
      {{ "popups.deleted-user-info-modal.title" | translate }}
    </ion-title>
    <ion-button (click)="closeModal()" size="small" slot="end" fill="clear" color="dark">
      <ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid class="ion-text-center" style="max-width: 155px">
    <ng-lottie (animationCreated)="animationCreated($event)" [options]="deletedUserAnimationOptions"></ng-lottie>
  </ion-grid>
  <ion-list class="ion-no-padding">
    <ion-item lines="none">
      <ion-label class="ion-text-center ion-text-wrap" style="margin: 0; font-weight: bold; font-size: medium">
        {{
        "popups.deleted-user-info-modal.your-account-and-all-your-data-for-mobile"
        | translate
        }}
        {{ mobile }}
        {{ "popups.deleted-user-info-modal.has-been-deleted" | translate }}
      </ion-label>
    </ion-item>
  </ion-list>
  <div style="padding: 2rem; text-align: center;">
    <ion-button (click)="restoreAccount()">
      {{ !restoreLoading ? ("restore_account" | translate) : ''}}
      <ion-spinner *ngIf="restoreLoading"></ion-spinner>
    </ion-button>
  </div>
</ion-content>