import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { distinctUntilChanged, Subscription } from "rxjs";
import * as _ from "lodash";
import * as fromApp from "../../../../store/app.reducer";
import { Store } from "@ngrx/store";

@Component({
  selector: "app-order-details-product-item-options",
  templateUrl: "./order-details-product-item-options.component.html",
  styleUrls: ["./order-details-product-item-options.component.scss"],
})
export class OrderDetailsProductItemOptionsComponent
  implements OnInit, OnDestroy
{
  @Input() option: any;
  @Input() product: any;
  @Input() order: any;
  public groupApp: any;
  private sub: Subscription;

  constructor(private store: Store<fromApp.AppState>) {}

  ngOnInit() {
    this.sub = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.groupApp &&
          !_.isEqual(state.groupApp, this.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp);
        }
      });
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
