import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import moment from "moment";
import * as _ from "lodash";

@Component({
  selector: "app-product-item-tags",
  templateUrl: "./product-item-tags.component.html",
  styleUrls: ["./product-item-tags.component.scss"],
})
export class ProductItemTagsComponent implements OnInit, OnChanges {
  @Input() parentBundleChoice: any;
  @Input() productItem: any;
  @Input() orderPickup: any;
  @Input() dineIn: any;
  @Input() previousOrderItem: boolean;
  @Input() groupApp: any;
  @Input() store_info: any;
  @Input() selectedPreorderDate: any;

  public preorderDateIsAfterToday: boolean;
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.selectedPreorderDate &&
      !_.isEqual(
        changes.selectedPreorderDate.previousValue,
        changes.selectedPreorderDate.currentValue
      )
    ) {
      this.checkPreorderDate();
    }
  }

  ngOnInit() {
    this.checkPreorderDate();
  }

  checkPreorderDate() {
    if (
      this.selectedPreorderDate &&
      this.selectedPreorderDate.date &&
      this.selectedPreorderDate.hour
    ) {
      const preorderDate = moment(
        `${this.selectedPreorderDate.date} ${this.selectedPreorderDate.hour}`,
        "DD-MM-YYYY HH:mm"
      );

      const tomorrowDate = moment()
        .add(1, "day")
        .set("hour", 0)
        .set("minutes", 0);

      if (preorderDate.isAfter(tomorrowDate)) {
        this.preorderDateIsAfterToday = true;
      } else {
        this.preorderDateIsAfterToday = false;
      }
    } else {
      this.preorderDateIsAfterToday = false;
    }
  }
}
