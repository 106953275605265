import * as ReviewsActions from "./reviews.actions";
import * as _ from "lodash";

export interface State {
  reviewsArray: any;
}

const initialState: State = {
  reviewsArray: null,
};

export function customerCouponsReducer(
  state = initialState,
  action: ReviewsActions.ReviewsActions
) {
  switch (action.type) {
    case ReviewsActions.SET_REVIEWS:
      return {
        ...state,
        reviewsArray: action.payload,
      };

    case ReviewsActions.CLEAR_REVIEWS:
      return {
        ...state,
        reviewsArray: null,
      };

    default:
      return state;
  }
}
