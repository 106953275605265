import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { AlertController, ModalController, Platform } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { AnimationOptions } from "ngx-lottie";
import { Subscription } from "rxjs";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import * as fromApp from "../../store/app.reducer";
import { Store } from "@ngrx/store";
import { distinctUntilChanged } from "rxjs/operators";
import * as _ from "lodash";

@Component({
  selector: "app-deleted-user-info-modal",
  templateUrl: "./deleted-user-info-modal.component.html",
  styleUrls: ["./deleted-user-info-modal.component.scss"],
})
export class DeletedUserInfoModalComponent implements OnInit, OnDestroy {
  @Input() mobile: any;
  @Input() group: any;
  public restoreAccountLoading: boolean = false;
  public groupSub: Subscription;
  animationInstance: any;
  deletedUserAnimationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/deleted-user.json",
  };

  private unsubscribeBackEvent: Subscription;
  public restoreLoading: boolean = false;
  public selectedLanguage: any;

  constructor(
    private modalController: ModalController,
    private store: Store<fromApp.AppState>,
    private platform: Platform,
    private translateService: TranslateService,
    private dataStorageService: DataStorageService,
    private alertController: AlertController,
  ) {}

  closeModal() {
    this.modalController.dismiss();
  }

  ngOnInit() {
    this.groupSub = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (
          state &&
          state.selectedLangugage &&
          !_.isEqual(state.selectedLangugage, this.selectedLanguage)
        ) {
          this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
        }
      });
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      300,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );
  }

  async restoreAccount() {
    this.restoreLoading = true;
    const alert = await this.alertController.create({
      header: this.translateService.instant("alert"),
      message: this.translateService.instant(
        "account-settings.are-you-sure-that-you-want-to-restore-account"
      ),
      buttons: [
        {
          text: this.translateService.instant("cancel"),
          role: "cancel",
          handler: () => { 
            this.restoreLoading = false;
          },
        },
        {
          text: this.translateService.instant("yes"),
          role: "confirm",
          handler: async () => {
            //call restore api
            this.restoreLoading = false;
            this.dataStorageService
              .triggerRestoreAccount(this.group, this.mobile, this.selectedLanguage)
              .subscribe({
                next: async (res: any) => {
                  this.restoreLoading = false;
                  if (!res || !res.success) {
                    const alert = await this.alertController.create({
                      header: this.translateService.instant("alert"),
                      message:
                        res && res.comment_id
                          ? this.translateService.instant(res.comment_id)
                          : this.translateService.instant(
                            "errorMessages.general_error"
                          ),
                      backdropDismiss: false,
                      buttons: ["OK"],
                    });
                    await alert.present();
                  } else {
                    const alert = await this.alertController.create({
                      header: this.translateService.instant("alert"),
                      message: this.translateService.instant(
                        "account-settings.soon-you-will-receive-an-sms-for-restoring-account"
                      ),
                      buttons: ["OK"],
                    });
                    this.restoreLoading = false;
                    await alert.present();
                  }
                }, error: async (err) => {
                  this.restoreLoading = false;
                  const alert = await this.alertController.create({
                    header: this.translateService.instant("alert"),
                    message: this.translateService.instant(
                      "errorMessages.problem_reaching_server"
                    ),
                    buttons: ["OK"],
                  });
                  await alert.present();
                  return err;
                },
              })
          }
        }
      ]
    });
    await alert.present();
  }

  animationCreated(ev) {
    if (ev) {
      this.animationInstance = ev;
      this.animationInstance.setSpeed(0.7);
    }
  }

  ngOnDestroy(): void {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
  }
}
