import * as AffiliateActions from "./affiliate.actions";
import * as _ from "lodash";

export interface State {
  affiliateReceiversCoupons: any;
  triggerFetchReceiversCouponsTimestamp: any;
}

const initialState: State = {
  affiliateReceiversCoupons: null,
  triggerFetchReceiversCouponsTimestamp: null
};

export function affiliateReducer(
  state = initialState,
  action: AffiliateActions.AffiliateActions
) {
  switch (action.type) {
    case AffiliateActions.SET_AFFILIATE_RECEIVERS_COUPONS:
      return {
        ...state,
        affiliateReceiversCoupons: action.payload,
      };
    case AffiliateActions.SET_TRIGGER_FETCH_RECEIVERS_COUPONS:
      return {
        ...state,
        triggerFetchReceiversCouponsTimestamp: action.payload,
      };

    default:
      return state;
  }
}
