import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { auditTime, distinctUntilChanged, first, Subscription } from "rxjs";
import * as fromApp from "../store/app.reducer";
import * as _ from "lodash";
import { TranslateService } from "@ngx-translate/core";
import { AlertController, ToastController } from "@ionic/angular";
import { DataStorageService } from "../services/data-storage/data-storage.service";
import * as CustomerCouponsActions from "../discount-coupons/store/coupons.actions";
import * as LoyaltySystemActions from "./store/loyalty-system.actions";
import * as GroupAppActions from "../store/group-app/groupApp.actions";

// SwiperCore.use([Navigation, Autoplay, Keyboard, Pagination, Zoom ]);

@Component({
  selector: "app-loyalty-system",
  templateUrl: "./loyalty-system.page.html",
  styleUrls: ["./loyalty-system.page.scss"],
})
export class LoyaltySystemPage implements OnInit, OnDestroy {
  public imageSettings: any;
  public imagesArray = [];
  public groupApp: any;
  public selectedLanguage: string;
  public flattenedIconBackgroundFlag: boolean = true;
  public storeImage: any;
  public headerLogo: any;
  public darkHeaderLogo: any;
  public currentUser: any;
  public rootDir: string;
  public sliderImages: any;
  public webp: boolean;
  public selectedLogoFileName: string;
  public customerCoupons: any = [];
  public campaignsArray: any;
  public activeCampaigns: any = 0;

  private browserPlatform: boolean;
  private subscriptions: Subscription[] = [];
  private mo_token: any;
  darkTheme: boolean;
  groupContests: any;
  activeCustomerCoupons: any[];
  activeContest: any;
  activeTab: any;
  activeContestDraws: any;
  drawsSum: number;

  constructor(
    private store: Store<fromApp.AppState>,
    private translateService: TranslateService,
    private alertController: AlertController,
    private toastController: ToastController,
    private dataStorageService: DataStorageService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select("loyaltySystem")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(200))
        .subscribe((state) => {
          if (
            state &&
            state.campaignsArray &&
            !_.isEqual(this.campaignsArray, state.campaignsArray)
          ) {
            this.campaignsArray = _.cloneDeep(state.campaignsArray);
          }
          if (
            state &&
            state.groupContests &&
            !_.isEqual(this.groupContests, state.groupContests)
          ) {
            this.groupContests = _.cloneDeep(state.groupContests);
          }
          if (
            state &&
            state.activeContestDraws &&
            !_.isEqual(this.activeContestDraws, state.activeContestDraws)
          ) {
            this.activeContestDraws = _.cloneDeep(state.activeContestDraws);
            console.log("activeContestDraws", this.activeContestDraws);
            this.drawsSum = _.sumBy(
              _.filter(_.cloneDeep(this.activeContestDraws), (draw) => {
                return draw.status === "active";
              }),
              "draws"
            );
            console.log("drawsSum", this.drawsSum);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("customerCoupons")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.allCoupons &&
            !_.isEqual(this.customerCoupons, state.allCoupons)
          ) {
            this.customerCoupons = _.cloneDeep(state.allCoupons);
            this.activeCustomerCoupons = _.filter(this.customerCoupons, {
              status: "active",
            });
            console.log("customerCoupons", this.customerCoupons);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.mo_token &&
            !_.isEqual(this.mo_token, state.mo_token)
          ) {
            this.mo_token = _.cloneDeep(state.mo_token);
            if (this.customerCoupons && _.isEmpty(this.customerCoupons)) {
              this.getUserCoupons();
            }
          }
          if (state && !_.isEqual(state.user, this.currentUser)) {
            this.currentUser = _.cloneDeep(state.user);
            // this.fetchLoyaltyProducts(this.mo_token);
            console.log("this.currentUser", this.currentUser);
            if (this.activeContest) {
              this.fetchCustomerDraws(this.activeContest.contest_id);
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("platformInfo")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.browserPlatform, state.browserPlatform)
          ) {
            this.browserPlatform = _.cloneDeep(state.browserPlatform);
          }
        })
    );
    this.groupAppSubscription();
  }

  groupAppSubscription() {
    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(200))
        .subscribe((state) => {
          this.storeImage = _.cloneDeep(state.groupApp.iconImage);
          this.headerLogo = _.cloneDeep(state.groupApp.headerLogo);
          this.darkHeaderLogo = _.cloneDeep(state.groupApp.headerLogoDark);
          if (
            state &&
            state.imageSettings &&
            state.groupApp &&
            !_.isEqual(this.imageSettings, state.imageSettings)
          ) {
            this.imageSettings = _.cloneDeep(state.imageSettings);
            if (this.browserPlatform && state.groupApp) {
              this.imagesArray = this.findImagesInArray(
                state.groupApp.group + "/home",
                _.cloneDeep(state.groupApp.homeImages)
              );
            }
          }
          if (
            state &&
            state.selectedLangugage &&
            !_.isEqual(this.selectedLanguage, state.selectedLangugage)
          ) {
            console.log("inside selected lAng");
            this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
          }
          if (
            state &&
            state.rootDir &&
            !_.isEqual(this.rootDir, state.rootDir)
          ) {
            console.log("inside rootDir");
            this.rootDir = _.cloneDeep(state.rootDir);
          }
          if (
            state &&
            state.groupApp &&
            !_.isEqual(this.groupApp, state.groupApp)
          ) {
            console.log("inside groupApp");
            this.groupApp = _.cloneDeep(state.groupApp);

            if (navigator.cookieEnabled && this.groupApp.only_loyalty_app) {
              let darkThemeLocalStorage = localStorage.getItem("darkTheme");
              if (darkThemeLocalStorage && darkThemeLocalStorage === "yes") {
                this.darkTheme = true;
                this.store.dispatch(
                  new GroupAppActions.SetSelectedTheme("dark")
                );
              } else {
                this.darkTheme = false;
                this.store.dispatch(
                  new GroupAppActions.SetSelectedTheme("light")
                );
              }
            }

            if (this.groupApp.contestSystemEnabled) {
              this.fetchGroupContests();
            }
            this.store.dispatch(
              new LoyaltySystemActions.SetLoyaltyCampaigns(
                _.cloneDeep(this.groupApp.loyalty_products)
              )
            );

            this.imagesArray = this.findImagesInArray(
              this.groupApp.group + "/home",
              _.cloneDeep(this.groupApp.homeImages)
            );
            console.log("images arrray", this.imagesArray);
            if (!_.isEmpty(this.groupApp.logosImages)) {
              this.selectedLogoFileName = _.find(this.groupApp.logosImages, {
                selected: true,
              }).fileName;
            }

            console.log("selected logo file name", this.selectedLogoFileName);

            if (state && state.webp && !_.isEqual(this.webp, state.webp)) {
              this.webp = _.cloneDeep(state.webp);
              if (this.webp && this.selectedLogoFileName) {
                this.selectedLogoFileName = this.selectedLogoFileName.replace(
                  ".png",
                  "512x512.webp"
                );
              }
            }
            if (!this.webp && this.selectedLogoFileName) {
              this.selectedLogoFileName = this.selectedLogoFileName.replace(
                ".png",
                "512x512.png"
              );
            }
          }
        })
    );
  }

  fetchLoyaltyProducts(mo_token) {
    this.dataStorageService.fetchUserLoyaltyProducts(mo_token, "").subscribe({
      next: async (res: any) => {
        if (res && res.success && res.loyalty_products) {
          console.log("fetchUserLoyaltyProducts", res);
          this.store.dispatch(
            new LoyaltySystemActions.SetUnableToLoadUserLoyaltyProducts(false)
          );
          this.store.dispatch(
            new LoyaltySystemActions.SetUserLoyaltyProducts(
              _.cloneDeep(res.loyalty_products)
            )
          );
        } else {
          if (res.comment_id) {
            this.store.dispatch(
              new LoyaltySystemActions.SetUnableToLoadUserLoyaltyProducts(true)
            );
            const alert = await this.alertController.create({
              header:
                res && res.comment_id
                  ? this.translateService.instant(res.comment_id)
                  : this.translateService.instant(
                      "errorMessages.general_error"
                    ),
              message: "Δεν μπόρεσαν να φορτωθούν τα προϊόντα σου",
              backdropDismiss: false,
              buttons: ["OK"],
            });
            await alert.present();
          } else if (res.comments) {
            this.store.dispatch(
              new LoyaltySystemActions.SetUnableToLoadUserLoyaltyProducts(true)
            );
            this.presentErrorToast(res.comments, "danger");
          }
        }
      },
      error: async (err) => {
        let errMsg = this.translateService.instant(
          "errorMessages.error: There was an error on saving"
        );

        this.presentErrorToast(errMsg, "danger");
        return err;
      },
    });
  }

  getUserCoupons() {
    this.customerCoupons = null;
    this.dataStorageService
      .getCustomerCoupons(this.mo_token, null, null)
      .subscribe({
        next: (res: any) => {
          if (res && res.success) {
            console.log("success", res);
            let allCoupons = res.coupons;
            this.store.dispatch(
              new CustomerCouponsActions.SetAllCoupons(allCoupons)
            );
          } else {
            if (res.comment_id) {
              let errorMsg = this.translateService.instant(
                "errorMessages-an-error-occurred"
              );
              this.presentErrorToast(errorMsg, "danger");
            } else {
              this.presentErrorToast(res.comments, "danger");
            }
          }
        },
        error: async (err) => {
          const alert = await this.alertController.create({
            header: this.translateService.instant("alert"),
            message: this.translateService.instant(
              "errorMessages.problem_reaching_server"
            ),

            buttons: ["OK"],
          });
          await alert.present();
          return err;
        },
      });
  }

  async presentErrorToast(message, color) {
    const errorToast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
    });
    errorToast.present();
  }

  findImagesInArray(directory, images) {
    var newImages = _.filter(images, (image) => {
      return this.findImageObject(image);
    });

    _.each(newImages, (image, key, list) => {
      if (!_.isObject(image.settings) && image.fileName) {
        newImages[key].path = this.rootDir + directory + "/" + image.fileName;
      } else {
        newImages[key].path =
          this.rootDir +
          directory +
          "/" +
          this.imageSettings.imageRatio +
          "/" +
          image.fileName;
      }
    });

    //Replacing all the images with webp
    if (this.webp) {
      _.each(newImages, function (img, key, list) {
        newImages[key].path = newImages[key].path
          .replace(".jpg", ".webp")
          .replace(".png", ".webp");
      });
    }

    return newImages;
  }

  findImageObject(image) {
    if (
      this.imageSettings &&
      image.settings &&
      ((this.browserPlatform &&
        image.settings[this.imageSettings.imageRatio].tinified &&
        image.settings[this.imageSettings.imageRatio].browser) ||
        (!this.browserPlatform &&
          image.settings[this.imageSettings.imageRatio].tinified &&
          image.settings[this.imageSettings.imageRatio].mobile))
    ) {
      return true;
    } else if (!_.isObject(image.settings) && image.fileName) {
      return true;
    } else {
      return false;
    }
  }

  fetchGroupContests() {
    const currentDate = Date.now();
    this.dataStorageService.fetchGroupContests(this.groupApp.group).subscribe({
      next: async (res: any) => {
        if (res && res.success) {
          console.log("fetchGroupContests", res);
          if (res.contests) {
            this.store.dispatch(
              new LoyaltySystemActions.SetGroupContests(
                _.cloneDeep(res.contests)
              )
            );
            this.activeContest = _.find(res.contests, (contest) => {
              return (
                currentDate >= contest.activeFrom &&
                currentDate < contest.activeTo
              );
            });
            if (this.activeContest) {
              this.fetchCustomerDraws(this.activeContest.contest_id);
            }
          }
          if (res.latestContestWinners && res.latestContestWinners.length > 0) {
            this.store.dispatch(
              new LoyaltySystemActions.SetLatestContestWinners(
                _.cloneDeep(res.latestContestWinners)
              )
            );
          }
        } else {
          if (res.comment_id) {
            const alert = await this.alertController.create({
              header:
                res && res.comment_id
                  ? this.translateService.instant(res.comment_id)
                  : this.translateService.instant(
                      "errorMessages.general_error"
                    ),
              message: this.translateService.instant("cant-load-contests"),
              backdropDismiss: false,
              buttons: ["OK"],
            });
            await alert.present();
          } else if (res.comments) {
            const toast = await this.toastController.create({
              message: this.translateService.instant(res.comments),
              color: "danger",
              position: "middle",
              duration: 5000,
              buttons: [
                {
                  side: "end",
                  text: "OK",
                  role: "cancel",
                  handler: () => {
                    console.log("Cancel clicked");
                  },
                },
              ],
            });
            await toast.present();
          }
        }
      },
      error: async (err) => {
        const errMsg = this.translateService.instant(
          "errorMessages.error: There was an error on saving"
        );
        const toast = await this.toastController.create({
          message: errMsg,
          color: "danger",
          position: "middle",
          duration: 5000,
          buttons: [
            {
              side: "end",
              text: "OK",
              role: "cancel",
              handler: () => {
                console.log("Cancel clicked");
              },
            },
          ],
        });
        await toast.present();
        return err;
      },
    });
  }

  setCurrentTab(event) {
    console.log("tab change", event);
    this.activeTab = event.tab;
  }

  fetchCustomerDraws(activeContestId) {
    this.store
      .select("auth")
      .pipe(first())
      .subscribe((state) => {
        if (state && state.mo_token) {
          this.dataStorageService
            .fetchCustomerDraws(
              _.cloneDeep(state.mo_token),
              activeContestId,
              null,
              null
            )
            .subscribe({
              next: async (res: any) => {
                if (res && res.success) {
                  console.log("fetchCustomerDraws", res);
                  if (res.customerDraws) {
                    this.store.dispatch(
                      new LoyaltySystemActions.SetActiveContestCustomerDraws(
                        _.cloneDeep(res.customerDraws)
                      )
                    );
                  }
                } else {
                  if (res.comment_id) {
                    const alert = await this.alertController.create({
                      header:
                        res && res.comment_id
                          ? this.translateService.instant(res.comment_id)
                          : this.translateService.instant(
                              "errorMessages.general_error"
                            ),

                      backdropDismiss: false,
                      buttons: ["OK"],
                    });
                    await alert.present();
                  } else if (res.comments) {
                    const toast = await this.toastController.create({
                      message: this.translateService.instant(res.comments),
                      color: "danger",
                      position: "middle",
                      duration: 5000,
                      buttons: [
                        {
                          side: "end",
                          text: "OK",
                          role: "cancel",
                          handler: () => {
                            console.log("Cancel clicked");
                          },
                        },
                      ],
                    });
                    await toast.present();
                  }
                }
              },
              error: async (err) => {
                const errMsg = this.translateService.instant(
                  "errorMessages.error: There was an error on saving"
                );
                const toast = await this.toastController.create({
                  message: errMsg,
                  color: "danger",
                  position: "middle",
                  duration: 5000,
                  buttons: [
                    {
                      side: "end",
                      text: "OK",
                      role: "cancel",
                      handler: () => {
                        console.log("Cancel clicked");
                      },
                    },
                  ],
                });
                await toast.present();
                return err;
              },
            });
        }
      });
  }

  ngOnDestroy() {
    console.log("onDestroy loyalty system");
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    this.subscriptions = [];
  }
}
