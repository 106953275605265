<ion-content>
  <ion-grid style="height: 100%">
    <ion-row
      class="ion-justify-content-center ion-align-items-center"
      style="width: 100%; height: 100%"
    >
      <ion-col class="ion-align-self-center">
        <ion-grid style="max-width: 180px" class="ion-text-center">
          <ion-img
            style="width: 180px"
            *ngIf="groupApp && storeImage"
            [ngClass]="{
          flattenedIconBackground: flattenedIconBackgroundFlag
        }"
            src="https://images.deliverymanager.gr/{{
            groupApp.group
          }}/appicon/{{ storeImage }}"
          ></ion-img>
        </ion-grid>

        <ion-grid
          *ngIf="androidPlatform"
          style="width: 320px; min-height: 160px; padding: 0"
        >
          <ng-lottie [options]="animationOptionsAndroid"></ng-lottie>
        </ion-grid>

        <ion-grid
          *ngIf="!androidPlatform"
          style="width: 320px; min-height: 160px; padding: 0"
        >
          <ng-lottie [options]="animationOptionsIos"></ng-lottie>
        </ion-grid>

        <ion-item
          *ngIf="groupApp && !groupApp.showNewAppAvailableMessage"
          lines="none"
        >
          <ion-label class="ion-text-wrap ion-text-center">
            <div>
              <strong
                >{{"bundle-update.there-is-a-new-app-available" |
                translate}}</strong
              >
            </div>

            <div>
              <strong>{{"bundle-update.version" | translate}}: </strong
              >{{androidPlatform ? groupApp.androidBundleAppVersion :
              groupApp.iOSBundleAppVersion}}
            </div>
            <div>
              <strong> {{'bundle-update.size' | translate}}: </strong>
              {{androidPlatform ? groupApp.androidBundleAppVersionSize :
              groupApp.iOSBundleAppVersionSize}} MB
            </div>
          </ion-label>
        </ion-item>

        <ion-item
          *ngIf="groupApp && groupApp.showNewAppAvailableMessage"
          lines="none"
        >
          <ion-label class="ion-text-wrap ion-text-center">
            <div>
              <strong
                >{{"bundle-update.there-is-new-app-available" |
                translate}}</strong
              >
            </div>

            <!-- <div>
              <strong>{{"bundle-update.version" | translate}}: </strong
              >{{androidPlatform ? groupApp.androidBundleAppVersion :
              groupApp.iOSBundleAppVersion}}
            </div>
            <div>
              <strong> {{'bundle-update.size' | translate}}: </strong>
              {{androidPlatform ? groupApp.androidBundleAppVersionSize :
              groupApp.iOSBundleAppVersionSize}} MB
            </div> -->
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-grid>
    <ion-row style="width: 100%">
      <ion-col class="ion-text-center">
        <ion-button (click)="openNativeApp()">
          <ion-text *ngIf="groupApp && !groupApp.showNewAppAvailableMessage">
            {{"bundle-update.update-now" | translate}}
          </ion-text>

          <ion-text *ngIf="groupApp && groupApp.showNewAppAvailableMessage">
            {{"DOWNLOAD NOW" | translate}}
          </ion-text>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
