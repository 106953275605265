import * as DeliveryMethodAddressActions from "./deliveryMethodAddress.actions";
import * as _ from "lodash";

export interface State {
  orderPickup: boolean;
  dineIn: boolean;
  checkSelectedAddress: any;
  openNotValidAddressModal: any;
  openSelectStorePopupDelivery: any;
  selectedAddress: any;
  selectedDineInTable: any;
  selectedAddressLoading: any;
  validStores: any;
  validDeliveryAreas: any;
  selectedPreorderDate: any;
  navigationOnDeliveryMethodChange: boolean;
  preorderResponse: any;
  fixedPointsAddresses: any;
}

const initialState: State = {
  orderPickup: null,
  dineIn: null,
  checkSelectedAddress: null,
  openNotValidAddressModal: false,
  openSelectStorePopupDelivery: false,
  selectedAddress: null,
  selectedDineInTable: null,
  selectedAddressLoading: false,
  validStores: null,
  validDeliveryAreas: null,
  selectedPreorderDate: null,
  navigationOnDeliveryMethodChange: false,
  preorderResponse: null,
  fixedPointsAddresses: null,
};

export function deliveryMethodAddressReducer(
  state = initialState,
  action: DeliveryMethodAddressActions.DeliveryMethodAddressActions
) {
  switch (action.type) {
    case DeliveryMethodAddressActions.SET_ORDER_PICKUP:
      return {
        ...state,
        orderPickup: action.payload,
      };

    case DeliveryMethodAddressActions.SET_DINE_IN:
      return {
        ...state,
        dineIn: action.payload,
      };
    case DeliveryMethodAddressActions.SET_SELECTED_ADDRESS:
      return {
        ...state,
        selectedAddress: action.payload,
      };
    case DeliveryMethodAddressActions.SET_SELECTED_DINEIN_TABLE:
      return {
        ...state,
        selectedDineInTable: action.payload,
      };
    case DeliveryMethodAddressActions.SET_OPEN_NOT_VALID_ADDRESS_MODAL:
      return {
        ...state,
        openNotValidAddressModal: action.payload,
      };
    case DeliveryMethodAddressActions.SET_OPEN_SELECT_STORE_POPUP_DELIVERY:
      return {
        ...state,
        openSelectStorePopupDelivery: action.payload,
      };
    case DeliveryMethodAddressActions.SET_CHECK_SELECTED_ADDRESS:
      return {
        ...state,
        checkSelectedAddress: action.payload,
      };
    case DeliveryMethodAddressActions.SET_SELECTED_ADDRESS_LOADING:
      return {
        ...state,
        selectedAddressLoading: action.payload,
      };
    case DeliveryMethodAddressActions.SET_VALID_STORES:
      return {
        ...state,
        validStores: action.payload,
      };

    case DeliveryMethodAddressActions.SET_VALID_DELIVERY_AREAS:
      return {
        ...state,
        validDeliveryAreas: action.payload,
      };

    case DeliveryMethodAddressActions.SET_SELECTED_PREORDER_DATE:
      return {
        ...state,
        selectedPreorderDate: action.payload,
      };

    case DeliveryMethodAddressActions.SET_PREORDER_RESPONSE:
      return {
        ...state,
        preorderResponse: action.payload,
      };

    case DeliveryMethodAddressActions.CLEAR_STATE:
      return {
        ...state,
        orderPickup: null,
        dineIn: null,
        selectedAddress: null,
        checkSelectedAddress: null,
      };
    case DeliveryMethodAddressActions.SET_NAVIGATION_ON_DELIVERY_METHOD_CHANGED:
      return {
        ...state,
        navigationOnDeliveryMethodChange: action.payload,
      };

    case DeliveryMethodAddressActions.SET_FIXED_POINTS_ADDRESSES:
      return {
        ...state,
        fixedPointsAddresses: action.payload,
      };

    default:
      return state;
  }
}
