import { Action } from "@ngrx/store";

export const SET_ACTIVE_COUPONS = "[COUPONS] Set Customer Active Coupons";

export const CLEAR_ACTIVE_COUPONS = "[COUPONS] Clear Customer Active Coupons";

export class SetActiveCoupons implements Action {
  readonly type = SET_ACTIVE_COUPONS;
  constructor(public payload: any) {}
}

export class ClearActiveCoupons implements Action {
  readonly type = CLEAR_ACTIVE_COUPONS;
  constructor() {}
}

export type CartActiveCouponsActions = SetActiveCoupons | ClearActiveCoupons;
