<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">
      {{ "popups.vat-level-details-modal.title" | translate }}
    </ion-title>
    <ion-button
      (click)="closeModal()"
      size="small"
      slot="end"
      fill="clear"
      color="dark"
    >
      <ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-item lines="full">
      <ion-label style="font-weight: bold" slot="start" class="ion-text-wrap">
        {{ "popups.vat-level-details-modal.vat" | translate }}
      </ion-label>
      <div slot="end" style="font-weight: bold">
        {{ "popups.vat-level-details-modal.total-amount" | translate }}
      </div>
    </ion-item>
    <ion-item lines="full" *ngFor="let vatLevel of vatLevelsWithTotals">
      <ion-label slot="start"> {{ vatLevel.percent }}% </ion-label>
      <div slot="end">{{ vatLevel.total }}€</div>
    </ion-item>
  </ion-grid>
</ion-content>