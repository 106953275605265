<ion-header>
  <ion-toolbar mode="md">
    <ion-title *ngIf="fixed_address && fixed_address.name" size="small">
      <ion-text>
        {{ fixed_address.name }}
      </ion-text>
    </ion-title>
    <ion-button
      (click)="closeModal()"
      size="small"
      slot="end"
      fill="clear"
      color="dark"
    >
      <ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid
    *ngIf="fixed_address && groupApp && fixed_address.image"
    class="ion-text-center ion-no-padding"
  >
    <img
      style="max-height: 240px"
      [src]="
        'https://dmfda.deliverymanager.gr/' +
        groupApp.group +
        '/' +
        fixed_address.fixed_address_id +
        '/' +
        'images/' +
        fixed_address.image
      "
    />
  </ion-grid>

  <!-- <ion-grid class="ion-text-center ion-no-padding" style="max-width: 180px">
    <ng-lottie
      (animationCreated)="animationCreated($event)"
      [options]="animationOptions"
    ></ng-lottie>
  </ion-grid> -->
  <ion-grid>
    <ion-item
      lines="full"
      *ngIf="
        fixed_address &&
        fixed_address.address &&
        (fixed_address.address.formatted_address ||
          fixed_address.address.comments ||
          fixed_address.order_comments)
      "
    >
      <ion-label class="ion-text-wrap">
        <strong>{{
          "popups.fixed-address-info-popup.prefixed-delivery-info" | translate
        }}</strong>
        <div
          style="font-size: small"
          *ngIf="fixed_address.address.formatted_address"
        >
          {{ fixed_address.address.formatted_address }}
        </div>
        <div style="font-size: small" *ngIf="fixed_address.address.comments">
          <strong>{{
            "popups.fixed-address-info-popup.address-comments" | translate
          }}</strong
          >: {{ fixed_address.address.comments }}
        </div>
        <div style="font-size: small" *ngIf="fixed_address.order_comments">
          <strong>{{
            "popups.fixed-address-info-popup.comments" | translate
          }}</strong
          >: {{ fixed_address.order_comments }}
        </div>
      </ion-label>
    </ion-item>

    <ion-item
      lines="full"
      *ngIf="fixed_address && fixed_address.coupon_description"
    >
      <ion-label class="ion-text-wrap">
        <strong>{{
          "popups.fixed-address-info-popup.coupon-info" | translate
        }}</strong>

        <div style="font-size: small" *ngIf="fixed_address.coupon_description">
          {{ fixed_address.coupon_description }}
        </div>
      </ion-label>
    </ion-item>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-grid>
    <ion-row style="width: 100%">
      <ion-col class="ion-text-center">
        <ion-button
          [disabled]="!loginState || loginState === 'login_loading'"
          (click)="onClickOk()"
          >{{
            currentUser
              ? ("popups.fixed-address-info-popup.continue" | translate)
              : ("popups.fixed-address-info-popup.login" | translate)
          }}</ion-button
        >
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
