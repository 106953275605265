<app-radio-choices
  *ngIf="option && option.choices && option.choices.length"
  [option_id]="option_id"
  [selectedProductPromo]="selectedProductPromo"
  [selectedProductCategory]="selectedProductCategory"
  [selectedProductId]="selectedProductId"
  [alt_dinein_active]="alt_dinein_active"
  [bundleMode]="bundleMode"
  [groupStoreData]="groupStoreData"
  (choicesChanged)="optionChanged($event)"
></app-radio-choices>
