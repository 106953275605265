<ion-card *ngIf="store_info && store_info.preorderActive">
  <ion-list class="ion-no-padding">
    <ion-item-divider mode="ios">
      <ion-text *ngIf="orderPickup == null && dineIn == null">{{
        "delivery-method-address.delivery-pickup-time" | translate
      }}</ion-text>
      <ion-text *ngIf="orderPickup == false && dineIn == false">{{
        "delivery-method-address.delivery-time" | translate
      }}</ion-text>
      <ion-text *ngIf="orderPickup == true && dineIn == false">{{
        "delivery-method-address.pickup-time" | translate
      }}</ion-text>
      <ion-text *ngIf="orderPickup == false && dineIn == true">{{
        "preorder" | translate
      }}</ion-text>
    </ion-item-divider>
    <ion-grid>
      <div
        class="ion-text-center"
        *ngIf="
          store_info &&
          store_info.preorderActive &&
          !(orderPickup == false && dineIn == true)
        "
      >
        <ion-button
          mode="ios"
          size="small"
          [fill]="preorderMode ? 'outline' : 'solid'"
          (click)="presentPreorderModalFromCartButtons('orderNow')"
        >
          {{
            orderPickup == true && dineIn == false
              ? ("popups.pre-order-popup.instantOrderTakeAway" | translate)
              : orderPickup == false && dineIn == false
              ? ("popups.pre-order-popup.instantOrderDelivery" | translate)
              : ("popups.pre-order-popup.instantOrder" | translate)
          }}
        </ion-button>
        <ion-button
          mode="ios"
          size="small"
          (click)="presentPreorderModalFromCartButtons('preOrder')"
          [fill]="preorderMode ? 'solid' : 'outline'"
        >
          {{ "popups.pre-order-popup.preOrder" | translate }}
        </ion-button>
      </div>

      <ion-item
        style="padding-top: 5px; padding-bottom: 5px"
        color="deliveryMethodAddressButtons"
        button
        detail="true"
        (click)="presentPreorderModal(false)"
        lines="none"
      >
        <ion-spinner *ngIf="!checkStoreOnlineInfo"></ion-spinner>
        <ion-label
          *ngIf="
            checkStoreOnlineInfo &&
            ((orderPickup == null && dineIn == null) ||
              (orderPickup != null &&
                !checkStoreOnlineInfo.deliveryTime &&
                !checkStoreOnlineInfo.pickupTime))
          "
          style="font-size: small"
          class="ion-text-wrap"
          >{{
            orderPickup == false && dineIn == false
              ? ("delivery-method-address.preorder-delivered-hour" | translate)
              : orderPickup == true && dineIn == false
              ? ("delivery-method-address.preorder-pickup-hour" | translate)
              : ("delivery-method-address.preorder-delivered-hour" | translate)
          }}:
          <strong>
            {{
              selectedPreorderDate &&
              selectedPreorderDate.hour &&
              selectedPreorderDate.date
                ? selectedPreorderDate.date +
                  (!selectedPreorderDate.selectedMethod ||
                  (selectedPreorderDate.selectedMethod === "delivery" &&
                    !selectedPreorderDate.preorderAcceptDisableDeliveryTime) ||
                  (selectedPreorderDate.selectedMethod === "takeaway" &&
                    !selectedPreorderDate.preorderAcceptDisablePickupTime)
                    ? " " + selectedPreorderDate.hour
                    : "")
                : ("delivery-method-address.now" | translate)
            }}
          </strong>
        </ion-label>
        <ion-label
          class="ion-text-wrap"
          *ngIf="
            (checkStoreOnlineInfo &&
              orderPickup == false &&
              checkStoreOnlineInfo.deliveryTime) ||
            (orderPickup == true &&
              checkStoreOnlineInfo &&
              checkStoreOnlineInfo.pickupDeliveryTime)
          "
          style="font-size: small"
          >{{
            orderPickup == false && dineIn == false
              ? ("delivery-method-address.preorder-delivered" | translate)
              : orderPickup == true && dineIn == false
              ? ("delivery-method-address.preorder-pickup" | translate)
              : ("delivery-method-address.preorder-delivered" | translate)
          }}:
          <strong>
            {{
              selectedPreorderDate &&
              selectedPreorderDate.hour &&
              selectedPreorderDate.date
                ? selectedPreorderDate.date +
                  (selectedPreorderDate.hour &&
                  ((orderPickup == false &&
                    dineIn == false &&
                    !selectedPreorderDate.preorderAcceptDisableDeliveryTime) ||
                    (orderPickup == true &&
                      dineIn == false &&
                      !selectedPreorderDate.preorderAcceptDisablePickupTime) ||
                    (orderPickup == false && dineIn == true))
                    ? " " + selectedPreorderDate.hour
                    : "")
                : orderPickup == false && checkStoreOnlineInfo.deliveryTime
                ? !deliveryTimeIsNumber
                  ? checkStoreOnlineInfo.deliveryTime
                  : checkStoreOnlineInfo.deliveryTime +
                    " " +
                    ("minutes" | translate)
                : orderPickup == true && checkStoreOnlineInfo.pickupDeliveryTime
                ? !pickUpTimeIsNumber
                  ? checkStoreOnlineInfo.pickupDeliveryTime
                  : checkStoreOnlineInfo.pickupDeliveryTime +
                    " " +
                    ("minutes" | translate)
                : ""
            }}
          </strong>
        </ion-label>
      </ion-item>
    </ion-grid>
  </ion-list>
</ion-card>
