<ion-content
  *ngIf="groupApp && groupApp.group && splashScreenImage"
  scrollX="false"
  scrollY="false"
>
  <img
    src="https://images.deliverymanager.gr/{{ groupApp.group }}/splashscreen/{{
      splashScreenImage
    }}"
    style="height: 100%; width: 100%; object-fit: cover"
  />

  <ion-grid
    *ngIf="
      groupApp &&
      groupApp.design_settings &&
      groupApp.design_settings.animation_loader
    "
    style="
      width: 140px;
      padding: 0;
      margin-bottom: 20px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 100;
    "
  >
    <ng-lottie [options]="animationOptions"></ng-lottie>
  </ion-grid>
  <ion-grid
    *ngIf="
      groupApp &&
      (!groupApp.design_settings || !groupApp.design_settings.animation_loader)
    "
    style="
      height: 180px;
      padding: 0;
      margin-bottom: 20px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 100;
    "
  >
    <ion-row
      class="ion-align-items-center justify-content-center"
      style="width: 100%"
    >
      <ion-col size="12" class="ion-align-self-center">
        <ion-grid style="width: 120px">
          <ng-lottie [options]="animationOptions"></ng-lottie> </ion-grid
      ></ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
