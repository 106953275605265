import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import * as _ from "lodash";
import {
  auditTime,
  debounceTime,
  distinctUntilChanged,
  first,
} from "rxjs/operators";
import * as fromApp from "../../../store/app.reducer";
import * as selectors from "../../../store/app.selectors";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";

@Component({
  selector: "app-subcategory-products-mobile",
  templateUrl: "./subcategory-products-mobile.component.html",
  styleUrls: ["./subcategory-products-mobile.component.scss"],
})
export class SubcategoryProductsMobileComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() category_id: any;
  @Input() unavailableProductsByCategoryId: any;
  @Input() changedUnavailableProductsByCategoryIdTimestamp: string;
  @Input() imageBrowser: any;
  @Input() scrollFromBottom: any;

  public subCategoriesArray: any;
  public filteredSubCategoriesArray;
  public category: any;
  public hyperCategories: any;
  public storeInfo: any;
  public groupApp: any;
  public groupStoreData: any = {};
  public webp: any;
  public orderPickup: any;
  public dineIn: any;
  public selectedDineInTable;
  public selectedPreorderDate: any;
  public cartProductIds: any;
  public selectedLanguage;
  public mo_base;
  public selectedCategoryId;

  private windowDimensions;
  private notChangedCategory: any;
  private cartProducts: any;
  private subscriptions: Subscription[] = [];
  private virtualCategoryProducts = [];
  constructor(
    private store: Store<fromApp.AppState>,
    private changeDetector: ChangeDetectorRef
  ) {}

  trackProducts(index, item) {
    return item.product_id;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.changedUnavailableProductsByCategoryIdTimestamp &&
      changes.changedUnavailableProductsByCategoryIdTimestamp.previousValue !==
        changes.changedUnavailableProductsByCategoryIdTimestamp.currentValue
    ) {
      this.calculations();
    }

    if (
      changes &&
      changes.scrollFromBottom &&
      changes.scrollFromBottom.previousValue !==
        changes.scrollFromBottom.currentValue &&
      this.storeInfo &&
      this.storeInfo.design_settings &&
      this.storeInfo.design_settings.showOnlySelectedCategoryProducts &&
      this.filteredSubCategoriesArray &&
      this.filteredSubCategoriesArray.length
    ) {
      if (this.scrollFromBottom < 150) {
        this.loadData();
      }
    }
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select("verticalCategory")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.selectedCategoryId, state.selectedCategoryId)
          ) {
            this.selectedCategoryId = _.cloneDeep(state.selectedCategoryId);
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .subscribe((storeInfo) => {
          if (storeInfo && !_.isEqual(storeInfo, this.storeInfo)) {
            this.storeInfo = _.cloneDeep(storeInfo);
            const catalogCustomTagsById = {};
            _.each(_.cloneDeep(this.storeInfo.catalogCustomTags), (tag) => {
              catalogCustomTagsById[tag.id] = tag;
            });
            this.groupStoreData.store_info = {
              store_id: storeInfo.store_id,
              overridePromoItemsForPointsSystem: _.cloneDeep(
                this.storeInfo.overridePromoItemsForPointsSystem
              ),
              alt_dinein_active: storeInfo.dinein
                ? _.cloneDeep(this.storeInfo.alt_dinein_active)
                : null,
              catalogCustomTagsById: catalogCustomTagsById,
              design_settings: storeInfo.design_settings,
            };
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("hyperCategories")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.hyperCategories &&
            !_.isEqual(state.hyperCategories, this.hyperCategories)
          ) {
            this.hyperCategories = _.cloneDeep(state.hyperCategories);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.windowDimensions &&
            !_.isEqual(state.windowDimensions, this.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
            this.groupStoreData.windowDimensions = _.cloneDeep(
              state.windowDimensions
            );
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(this.groupApp, state.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
            this.groupStoreData.groupApp = {
              group: _.cloneDeep(this.groupApp.group),
              disableCatalogPhoto: _.cloneDeep(
                this.groupApp.disableCatalogPhoto
              ),
              pointsSystem: _.cloneDeep(this.groupApp.pointsSystem),
              iconImage: _.cloneDeep(this.groupApp.iconImage),
            };
            this.groupStoreData.groupApp.mo_base = _.find(
              _.cloneDeep(this.groupApp.languages),
              { mo_base: true }
            );
          }
          if (
            state &&
            state.selectedLangugage &&
            !_.isEqual(this.selectedLanguage, state.selectedLangugage)
          ) {
            this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
            this.groupStoreData.groupApp.selectedLanguage = _.cloneDeep(
              this.selectedLanguage
            );
          }
          if (state && state.webp && !_.isEqual(this.webp, state.webp)) {
            this.webp = _.cloneDeep(state.webp);
            this.groupStoreData.webp = _.cloneDeep(this.webp);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("deliveryMethodAddress")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && !_.isEqual(state, state.orderPickup)) {
            this.orderPickup = _.cloneDeep(state.orderPickup);
          }
          if (state && !_.isEqual(state, state.dineIn)) {
            this.dineIn = _.cloneDeep(state.dineIn);
          }

          if (state && !_.isEqual(state, state.selectedDineInTable)) {
            this.selectedDineInTable = _.cloneDeep(state.selectedDineInTable);
          }

          if (state && !_.isEqual(state, state.selectedDineInTable)) {
            this.selectedDineInTable = _.cloneDeep(state.selectedDineInTable);
          }
          if (state && !_.isEqual(state, state.selectedPreorderDate)) {
            this.selectedPreorderDate = _.cloneDeep(state.selectedPreorderDate);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select(
          selectors.getCategoryByCategoryId(_.cloneDeep(this.category_id))
        )
        .pipe(distinctUntilChanged())

        .subscribe((category) => {
          if (category && !_.isEqual(this.category, category)) {
            this.category = _.cloneDeep(category);
            this.notChangedCategory = _.cloneDeep(category);
            if (
              this.category &&
              this.category.products &&
              this.category.products.length
            ) {
              this.category.products = _.orderBy(
                _.cloneDeep(this.category.products),
                ["position"],
                "asc"
              );
            }
            this.calculations();
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("cart")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.products &&
            !_.isEqual(this.cartProducts, state.products)
          ) {
            this.cartProducts = _.cloneDeep(state.products);
            this.cartProductIds = [];
            _.each(this.cartProducts, (product) => {
              if (
                !product.quantity ||
                product.quantity === 0 ||
                product.quantity === 1 ||
                (product.enable_quantity_step && product.quantity_step)
              ) {
                this.cartProductIds.push(_.cloneDeep(product.product_id));
              } else {
                for (let i = 0; i < product.quantity; i++) {
                  this.cartProductIds.push(_.cloneDeep(product.product_id));
                }
              }
            });
          }
        })
    );
  }

  trackSubCategories(index, item) {
    return item.subCategoryId;
  }

  private calculations = _.debounce(() => {
    this.removeUnavailableProdutsFromCategory();
  }, 300);

  removeUnavailableProdutsFromCategory() {
    this.store
      .select(selectors.getStoreInfo)
      .pipe(first())
      .subscribe((storeInfo) => {
        if (storeInfo) {
          if (
            storeInfo &&
            !storeInfo.showAllUnavailableProducts &&
            this.category &&
            this.category.subCategoriesActive &&
            this.category.products &&
            this.unavailableProductsByCategoryId &&
            this.unavailableProductsByCategoryId[this.category_id] &&
            this.unavailableProductsByCategoryId[this.category_id].length
          ) {
            for (const category_id in this.unavailableProductsByCategoryId) {
              if (this.unavailableProductsByCategoryId[category_id].length) {
                const unavailablePrdArr =
                  this.unavailableProductsByCategoryId[category_id];
                _.each(unavailablePrdArr, (unavailableProduct) => {
                  const index = _.findIndex(this.category.products, {
                    product_id: unavailableProduct.product_id,
                  });
                  if (index !== -1) {
                    this.category.products.splice(index, 1);
                  }
                });
              }
            }
          }

          this.calculateSubCategoriesArray(storeInfo);
        }
      })
      .unsubscribe();
  }

  async calculateSubCategoriesArray(storeInfo) {
    if (this.category && this.category.subCategoriesActive) {
      this.category = _.cloneDeep(this.notChangedCategory);
      this.category.products =
        await this.filterCategoryProductsByDeliveryMethod(
          _.cloneDeep(this.category.products)
        );
      if (
        !_.isEmpty(this.category.products) &&
        this.category.subCategories &&
        this.category.subCategories.length
      ) {
        let virtualCategoryTop = false;
        this.subCategoriesArray = _.cloneDeep(this.category.subCategories);
        if (
          this.category &&
          this.category.products &&
          _.isArray(this.category.products)
        ) {
          _.each(this.category.products, (product, p: number) => {
            if (product.subCategoryId) {
              _.each(this.subCategoriesArray, (subCategory, key) => {
                if (product.subCategoryId === subCategory.subCategoryId) {
                  if (!this.subCategoriesArray[key].products) {
                    this.subCategoriesArray[key].products = [];
                  }

                  if (
                    !_.find(this.subCategoriesArray[key].products, {
                      product_id: product.product_id,
                    })
                  ) {
                    this.subCategoriesArray[key].products.push(product);
                  }
                }
              });
            } else if (!product.subCategoryId) {
              if (p === 0) {
                virtualCategoryTop = true;
              }
              if (
                !_.find(this.virtualCategoryProducts, {
                  product_id: product.product_id,
                })
              ) {
                this.virtualCategoryProducts.push(product);
              }
            }
          });
        }

        if (!virtualCategoryTop) {
          const virtualCateogory = {
            subCategoryId: "virtual",
            products: this.virtualCategoryProducts,
          };
          if (
            !_.find(this.subCategoriesArray, {
              subCategoryId: virtualCateogory.subCategoryId,
            })
          ) {
            this.subCategoriesArray.push(virtualCateogory);
          }
        } else {
          const virtualCateogory = {
            subCategoryId: "virtual",
            products: this.virtualCategoryProducts,
          };
          //console.log("virtual cat", virtualCateogory);
          if (
            !_.find(this.subCategoriesArray, {
              subCategoryId: virtualCateogory.subCategoryId,
            })
          ) {
            this.subCategoriesArray.unshift(virtualCateogory);
          }
        }
      }

      if (this.subCategoriesArray && this.subCategoriesArray.length) {
        this.subCategoriesArray = _.filter(
          this.subCategoriesArray,
          (subCategory) => {
            if (
              (subCategory && !subCategory.products) ||
              (subCategory &&
                subCategory.products &&
                subCategory.products.length == 0)
            ) {
            } else {
              return subCategory;
            }
          }
        );

        let lastSubCategoryIndx = _.findLastIndex(this.subCategoriesArray);
        if (
          this.subCategoriesArray[lastSubCategoryIndx] &&
          this.subCategoriesArray[lastSubCategoryIndx].subCategoryId ===
            "virtual"
        ) {
          let anotherCategoryWithProducts: boolean;

          _.each(this.subCategoriesArray, (subCat) => {
            if (
              subCat.subCategoryId !== "virtual" &&
              subCat.products &&
              subCat.products.length > 0
            ) {
              anotherCategoryWithProducts = true;
            }
          });

          if (anotherCategoryWithProducts) {
            this.subCategoriesArray[lastSubCategoryIndx].lastCategory = true;
          }
        }
      }
      //console.log("subCategoriesArray", this.subCategoriesArray);
    }

    if (
      this.subCategoriesArray &&
      this.subCategoriesArray.length &&
      storeInfo &&
      storeInfo.design_settings &&
      storeInfo.design_settings.showOnlySelectedCategoryProducts
    ) {
      let productsRenderedCounter;
      let loadUntilSubCategoryIndex = this.subCategoriesArray.length || 0;
      if (this.subCategoriesArray && this.subCategoriesArray.length) {
        _.each(this.subCategoriesArray, (subCat, index) => {
          productsRenderedCounter =
            productsRenderedCounter + subCat.products.length || 0;

          if (productsRenderedCounter > 30) {
            loadUntilSubCategoryIndex = index;
            return false;
          }
        });
      }

      if (productsRenderedCounter <= 30) {
        this.filteredSubCategoriesArray = _.cloneDeep(this.subCategoriesArray);
      }

      if (loadUntilSubCategoryIndex) {
        this.filteredSubCategoriesArray = _.cloneDeep(
          _.slice(this.subCategoriesArray, 0, loadUntilSubCategoryIndex)
        );
      } else if (loadUntilSubCategoryIndex === 0) {
        this.filteredSubCategoriesArray = _.cloneDeep([
          this.subCategoriesArray[0],
        ]);
      }
    }
  }

  filterCategoryProductsByDeliveryMethod(categoryProducts) {
    return new Promise((res, rej) => {
      this.store
        .select("deliveryMethodAddress")
        .pipe(first())
        .subscribe((state) => {
          categoryProducts = _.filter(
            _.cloneDeep(categoryProducts),
            (product) => {
              if (product.hideOtherDeliveryMethods) {
                if (state.orderPickup == true && state.dineIn == false) {
                  //take away selected

                  if (
                    product.pickup ||
                    (!product.delivery && !product.dinein)
                  ) {
                    return product;
                  }
                } else if (
                  state.orderPickup == false &&
                  state.dineIn == false
                ) {
                  //delivery selected

                  if (
                    product.delivery ||
                    (!product.pickup && !product.dinein)
                  ) {
                    return product;
                  }
                } else if (state.orderPickup == false && state.dineIn == true) {
                  //dine in selected

                  if (
                    product.dinein ||
                    (!product.pickup && !product.delivery)
                  ) {
                    return product;
                  }
                } else {
                  return product;
                }
              } else {
                return product;
              }
            }
          );

          res(categoryProducts);
        })
        .unsubscribe();
    });
  }

  loadData() {
    console.log("loda d data caleld");
    this.addMoreItemsDebounce();
  }

  private addMoreItemsDebounce = _.debounce(() => {
    if (
      this.subCategoriesArray.length > this.filteredSubCategoriesArray.length
    ) {
      let productsRenderedCounter: number =
        this.filteredSubCategoriesArray.length || 0;
      let loadUntilSubCategoryIndex;

      const otherCategoriesForLoad = _.differenceBy(
        this.subCategoriesArray,
        this.filteredSubCategoriesArray,
        "subCategoryId"
      );

      if (otherCategoriesForLoad && otherCategoriesForLoad.length) {
        _.each(otherCategoriesForLoad, (subCat: any, index) => {
          productsRenderedCounter =
            productsRenderedCounter + (subCat.products.length || 0);

          if (productsRenderedCounter > 15) {
            loadUntilSubCategoryIndex = index;
            return false;
          }
        });

        if (productsRenderedCounter <= 15) {
          this.filteredSubCategoriesArray = _.cloneDeep(
            this.subCategoriesArray
          );
        }

        if (loadUntilSubCategoryIndex) {
          this.filteredSubCategoriesArray = _.unionBy(
            _.cloneDeep(this.filteredSubCategoriesArray),
            _.cloneDeep(
              _.slice(otherCategoriesForLoad, 0, loadUntilSubCategoryIndex)
            ),
            "subCategoryId"
          );
        } else if (loadUntilSubCategoryIndex === 0) {
          this.filteredSubCategoriesArray = _.unionBy(
            _.cloneDeep(this.filteredSubCategoriesArray),
            _.cloneDeep([otherCategoriesForLoad[0]]),
            "subCategoryId"
          );
        }
      }
    }
  }, 300);

  console(sub) {
    console.log("consola", sub);
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }
}
