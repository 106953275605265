import { Component, Input, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../../../store/app.reducer";
import * as selectors from "../../../../../store/app.selectors";
import * as _ from "lodash";
import * as CartCustomOptionsSelectionsActions from "../../store/cart-custom-options-selections/cart-custom-options-selections.actions";
import { AlertController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { auditTime, distinctUntilChanged, first } from "rxjs/operators";
import { Subscription } from "rxjs";

@Component({
  selector: "app-plastic-bug-with-limit-option",
  templateUrl: "./plastic-bug-with-limit-option.component.html",
  styleUrls: ["./plastic-bug-with-limit-option.component.scss"],
})
export class PlasticBugWithLimitOptionComponent implements OnInit {
  @Input() option_timestamp;
  @Input() selectedLang: any;
  @Input() catalogMode: boolean;
  public option;
  public CustomSelections;
  public cartPrices: any;
  public dineIn: any;
  public orderPickup: any;
  private subscriptions: Subscription[] = [];

  public finalPriceLoading: any;

  constructor(
    private store: Store<fromApp.AppState>,
    private alertCtrl: AlertController,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
   // console.log("plustic bag with limit init");

    this.subscriptions.push(
      this.store
        .select("deliveryMethodAddress")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && !_.isEqual(state.orderPickup, this.orderPickup)) {
            this.orderPickup = _.cloneDeep(state.orderPickup);

            this.calculationSubsDebounced();
          }

          if (state && !_.isEqual(state.dineIn, this.dineIn)) {
            this.dineIn = _.cloneDeep(state.dineIn);

            this.calculationSubsDebounced();
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("cartPrices")
        .pipe(distinctUntilChanged())
        .subscribe((cartPricesState) => {
          if (
            cartPricesState &&
            !_.isEqual(this.cartPrices, cartPricesState.cartPrices)
          ) {
            this.cartPrices = _.cloneDeep(cartPricesState.cartPrices);

            this.calculationSubsDebounced();
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("cartCustomOptionsSelections")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.cartCustomOptionsSelections &&
            !_.isEqual(state.cartCustomOptionsSelections, this.CustomSelections)
          ) {
            this.CustomSelections = _.cloneDeep(
              state.cartCustomOptionsSelections
            );
            this.option = _.cloneDeep(
              _.find(this.CustomSelections, {
                timestamp: this.option_timestamp,
              })
            );
            if (this.cartPrices && this.option && this.option.choices) {
              this.store
                .select(selectors.getStoreInfo)
                .pipe(first())
                .subscribe((storeInfo) => {
                  if (storeInfo) {
                    this.store
                      .select("deliveryMethodAddress")
                      .pipe(first())
                      .subscribe((state) => {
                        this.store
                          .select("cart")
                          .pipe(first())
                          .subscribe((cartState) => {
                            _.each(this.option.choices, (ch) => {
                              ch.qty = this.calculateChoiceQuantity(
                                this.cartPrices,
                                storeInfo.store_id,
                                cartState.products,
                                state.validStores,
                                state.orderPickup,
                                state.dineIn
                              );
                             
                              if (ch.qty >= 0) {
                                ch.totalPrice = this.calculateChoicePrice(ch);
                                this.store.dispatch(
                                  new CartCustomOptionsSelectionsActions.AddUpdateCartCustomOptionSelections(
                                    _.cloneDeep(this.option)
                                  )
                                );
                              }
                            });
                          })
                          .unsubscribe();
                      })
                      .unsubscribe();
                  }
                })
                .unsubscribe();
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("disableFinalPrice")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(state.disableFinalPrice, this.finalPriceLoading)
          ) {
            this.finalPriceLoading = _.cloneDeep(state.disableFinalPrice);
          }
        })
    );
  }

  calculationSubsDebounced = _.debounce(() => {
    this.calculationSubs();
  }, 200);

  calculationSubs() {
    this.store
      .select(selectors.getStoreInfo)
      .pipe(first())
      .subscribe((storeInfo) => {
        if (storeInfo) {
          this.store
            .select("deliveryMethodAddress")
            .pipe(first())
            .subscribe((state) => {
              if (this.option && this.option.choices) {
                this.store
                  .select("cart")
                  .pipe(first())
                  .subscribe((cartState) => {
                    _.each(this.option.choices, (ch) => {
                      ch.qty = this.calculateChoiceQuantity(
                        this.cartPrices,
                        storeInfo.store_id,
                        cartState.products,
                        state.validStores,
                        state.orderPickup,
                        state.dineIn
                      );
                     
                      if (ch.qty >= 0) {
                        ch.totalPrice = this.calculateChoicePrice(ch);
                        this.store.dispatch(
                          new CartCustomOptionsSelectionsActions.AddUpdateCartCustomOptionSelections(
                            _.cloneDeep(this.option)
                          )
                        );
                      }
                    });
                  })
                  .unsubscribe();
              }
            })
            .unsubscribe();
        }
      })
      .unsubscribe();
  }

  calculateChoiceQuantity(
    cartPrices,
    currentStoreId,
    cartProducts,
    validStores,
    orderPickup,
    dineIn
  ) {
    console.log("calculate choice quantity", cartPrices, this.option);
    if (
      cartPrices &&
      this.option &&
      this.option.step_price_total &&
      cartPrices.grand_total
    ) {
      let deliveryCost;
      let minOrder;
      if (validStores && validStores.length > 0) {
        let currentStore = _.find(validStores, {
          store_id: currentStoreId,
        });
        console.log("valid stores", validStores, currentStoreId);
        console.log("current store", currentStore);
        if (currentStore) {
          deliveryCost = currentStore.deliveryCost;
          minOrder = currentStore.minOrder;
        }
        console.log("deliveryCost", deliveryCost);
        console.log("minOrder", minOrder);
        console.log(
          "subtotalOfOmittedProductsFromMinOrder",
          this.cartPrices.subtotalOfOmittedProductsFromMinOrder
        );
      }

      let subtotalOfOmittedProductsFromMinOrder =
        this.cartPrices.subtotalOfOmittedProductsFromMinOrder;
      let totalPrice: any;

      if (
        !orderPickup &&
        !dineIn &&
        parseFloat(subtotalOfOmittedProductsFromMinOrder) > 0
      ) {
        let maxDeliveryCharge = 0;

        _.each(cartProducts, (cartProduct) => {
          if (cartProduct && cartProduct.extra_delivery_charge) {
            if (
              maxDeliveryCharge < parseFloat(cartProduct.extra_delivery_charge)
            ) {
              maxDeliveryCharge = _.cloneDeep(
                parseFloat(cartProduct.extra_delivery_charge)
              );
            }
          }
        });

        if (
          this.cartPrices.subtotal === subtotalOfOmittedProductsFromMinOrder &&
          this.cartPrices.cart &&
          this.cartPrices.cart.length > 0 &&
          maxDeliveryCharge
        ) {
          if (parseFloat(this.cartPrices.deliveryCost) !== maxDeliveryCharge) {
          }
          this.cartPrices.deliveryCost = maxDeliveryCharge.toString();
          console.log("deliveryCost", this.cartPrices.deliveryCost);
        } else {
          this.cartPrices.deliveryCost = "0";

          console.log("deliveryCost", this.cartPrices.deliveryCost);
        }
      }

      totalPrice =
        parseFloat(this.cartPrices.grand_total) -
        parseFloat(
          this.cartPrices.deliveryCost
            ? this.cartPrices.deliveryCost.toString()
            : "0"
        ) -
        parseFloat(
          this.cartPrices.subtotalOfOmittedCartCustomOptionsFromMinOrder
            ? this.cartPrices.subtotalOfOmittedCartCustomOptionsFromMinOrder
            : "0"
        );

      if (totalPrice) {
        totalPrice = totalPrice.toFixed(2);
        console.log("$rootScope.totalPrice 1", totalPrice);
      }

      if (totalPrice === "0.00" || parseFloat(totalPrice) <= 0) {
        totalPrice = null;
      }

      if (orderPickup == false && dineIn == false && totalPrice) {
        return parseInt(
          (
            parseFloat(totalPrice.toString()) /
            parseFloat(this.option.step_price_total.toString())
          ).toString()
        );
      } else if (orderPickup == true && dineIn == false) {
        return parseInt(
          (
            parseFloat(_.cloneDeep(this.cartPrices.grand_total).toString()) /
            parseFloat(this.option.step_price_total.toString())
          ).toString()
        );
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  calculateChoicePrice(choice) {
    if (choice && choice.qty > 0 && choice.price > 0) {
     // console.log("wrapper price", (choice.qty * choice.price).toFixed(2));
      return (choice.qty * choice.price).toFixed(2);
    } else {
      return null;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
    this.subscriptions = [];
  }
}
