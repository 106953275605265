import { Action } from "@ngrx/store";

export const SET_USER_POINTS = "[POINTS] Set User Points";
export const SET_HIGHEST_REWARD = "[POINTS] Set Highest Reward";
export const SET_REWARDS = "[POINTS] Set Rewards";
export const CLEAR_POINTS = "[POINTS] Clear Points";

export class SetUserPoints implements Action {
  readonly type = SET_USER_POINTS;
  constructor(public payload: any) {}
}

export class SetHighestReward implements Action {
  readonly type = SET_HIGHEST_REWARD;
  constructor(public payload: any) {}
}

export class SetRewards implements Action {
  readonly type = SET_REWARDS;
  constructor(public payload: any) {}
}

export class ClearPoints implements Action {
  readonly type = CLEAR_POINTS;
  constructor() {}
}

export type PointsActions =
  | SetUserPoints
  | SetHighestReward
  | SetRewards
  | ClearPoints;
