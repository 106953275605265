<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">{{
      "not-valid-address-modal.title" | translate
    }}</ion-title>
    <ion-buttons slot="end">
      <ion-button color="danger" fill="clear" (click)="ondismiss()">
        <ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content scrollX="false" scrollY="false">
  <ion-grid
    class="ion-no-padding ion-no-margin"
    style="width: 100%; position: absolute; top: 0px; z-index: 19"
  >
    <ion-row *ngIf="libraryLoading || loading">
      <ion-col class="ion-text-center">
        <ion-spinner></ion-spinner>
      </ion-col>
    </ion-row>
    <ion-row
      class="ion-no-padding ion-no-margin"
      style="z-index: 20; width: 100%"
    >
      <ion-col size="12" class="ion-no-padding ion-no-margin">
        <ion-card
          class="ion-align-items-center ion-text-center ion-margin"
          style="z-index: 21; margin: 5px"
          *ngIf="deliveryAreas && deliveryAreas.length === 0"
          color="danger"
        >
          <ion-card-content style="margin: 0px; padding: 5px">
            <ion-label style="font-weight: 800" color="light">
              <div>
                {{
                  "new-address-modal.no-delivery-areas-that-serves-your-location"
                    | translate
                }}
              </div>
            </ion-label>
          </ion-card-content>
        </ion-card>
        <ion-card
          detail="false"
          (click)="openDeliveryAreaInfoModal(area)"
          style="z-index: 21; margin: 5px"
          class="ion-align-items-center ion-text-center ion-no-margin ion-no-padding"
          button
          *ngFor="let area of deliveryAreas; trackBy: trackDeliveryAreas"
          [ngStyle]="{ 'background-color': area.fillColor }"
        >
          <!-- <button style="width: 280px; font-size: medium; margin-top: 5px;" ng-click="openDeliveryAreaInfoPopup(area)" -->
          <!-- class="button button-light button-small"> -->

          <ion-card-content style="margin: 0px; padding: 5px">
            <ion-label style="font-weight: 800" color="dark">
              <div>
                {{ "delivery-areas.minimum-order" | translate }}&nbsp;{{
                  area.minOrder
                }}€&nbsp;
              </div>
              <div>
                <span
                  *ngIf="
                    area.deliveryCost &&
                    area.deliveryCost !== '0' &&
                    !area.delivery_cost_per_kilometer_active
                  "
                >
                  <ion-text color="dark"
                    >{{
                      "delivery-areas.cost-for-delivery" | translate
                    }}:&nbsp;{{ area.deliveryCost }}€</ion-text
                  >&nbsp;
                </span>
                <span
                  *ngIf="
                    area.deliveryCost &&
                    area.deliveryCost !== '0' &&
                    area.delivery_cost_per_kilometer_active
                  "
                >
                  <ion-text color="dark"
                    ><strong
                      >{{
                        "delivery-areas.cost-for-delivery" | translate
                      }}:</strong
                    >&nbsp;{{ area.deliveryCost }}€
                    {{ "delivery-areas.per" | translate }}
                    {{
                      area.step_active && area.step_distance
                        ? area.step_distance + " " + ("meters" | translate)
                        : "km"
                    }}</ion-text
                  >

                  <ion-icon
                    color="dark"
                    style="
                      vertical-align: middle;
                      margin-left: 3px;
                      font-size: 21px;
                    "
                    name="information-circle-outline"
                  >
                  </ion-icon>
                </span>
              </div>

              <div
                style="font-size: small"
                *ngIf="area && area.free_delivery_over_amount"
              >
                <ion-text color="danger">
                  {{ "delivery-areas.free-delivery-over" | translate }}
                  {{
                    area.free_delivery_over_amount | twoDecimalsRound : true
                  }}€
                </ion-text>
              </div>
              <div>
                <span *ngIf="area.scheduleComment && area.showSchedule">
                  <ion-text color="danger">
                    {{ area.scheduleComment }}
                  </ion-text>
                </span>
              </div>
              <div>
                <span *ngIf="area.comment">
                  <ion-text color="dark">{{ area.comment }}</ion-text>
                </span>
              </div>
            </ion-label>
          </ion-card-content>
          <!-- </button> -->
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>

  <div
    [hidden]="libraryLoading || loading"
    id="map"
    style="width: 100%; height: 100%"
    data-tap-disabled="true"
  ></div>
</ion-content>
