import * as CatalogActions from "./catalog.actions";
import * as _ from "lodash";

export interface State {
  catalog: any;
  allCategories: any;
  customerPricelist: any;
  catalog_products_visibility: any;
  catalogUnfiltered: any;
  allCategoriesMarketCardsDesign: boolean;
  hyperCategoriesSelectionModalOpened: boolean;
}

const initialState: State = {
  catalog: null,
  allCategories: null,
  customerPricelist: null,
  catalog_products_visibility: null,
  catalogUnfiltered: null,
  allCategoriesMarketCardsDesign: null,
  hyperCategoriesSelectionModalOpened: null,
};

export function catalogReducer(
  state = initialState,
  action: CatalogActions.CatalogActions
) {
  switch (action.type) {
    case CatalogActions.SET_CATALOG:
      return {
        ...state,
        catalog: action.payload,
      };

    case CatalogActions.UPDATE_CATEGORIES_PROMOTIONS:
      const updatedCategories = _.cloneDeep(action.payload);
      const stateCategoriesForUpdate = _.cloneDeep(state.catalog);

      if (_.isArray(updatedCategories)) {
        _.each(updatedCategories, (category) => {
          const stateCategoryIndex: any = _.findIndex(
            stateCategoriesForUpdate,
            {
              category_id: category.category_id,
            }
          );
          if (
            stateCategoryIndex !== -1 &&
            category.promotions &&
            !_.isEqual(
              category.promotions,
              stateCategoriesForUpdate[stateCategoryIndex].promotions
            )
          ) {
            if (!stateCategoriesForUpdate[stateCategoryIndex].promotions) {
              stateCategoriesForUpdate[stateCategoryIndex].promotions = [];
            }

            stateCategoriesForUpdate[stateCategoryIndex].promotions =
              _.cloneDeep(category.promotions);
          }
        });
      }

      return {
        ...state,
        catalog: stateCategoriesForUpdate,
      };
    case CatalogActions.SET_ALL_CATEGORIES:
      return {
        ...state,
        allCategories: action.payload,
      };

    case CatalogActions.SET_CATALOG_UNFILTERED:
      return {
        ...state,
        catalogUnfiltered: action.payload,
      };
    case CatalogActions.SET_CATEGORY_PRODUCTS:
      let updatedCatalog = _.cloneDeep(state.catalog);
      let categoryIndex = _.findIndex(updatedCatalog, {
        category_id: action.payload.category_id,
      });
      if (categoryIndex !== -1) {
        updatedCatalog[categoryIndex].products = _.cloneDeep(
          action.payload.products
        );
      }
      return {
        ...state,
        catalog: updatedCatalog,
      };
    case CatalogActions.SET_PRODUCTS_CATALOG_VISIBILITY:
      const key = _.findIndex(
        state.catalog_products_visibility,
        (item: any) => {
          return item.product_id === action.payload.product_id;
        }
      );
      if (key !== -1) {
        const updateProduct = {
          ...state.catalog_products_visibility[key],
          ...action.payload,
        };
        const updatedProducts = [...state.catalog_products_visibility];
        updatedProducts[key] = updateProduct;
        return {
          ...state,
          catalog_products_visibility: updatedProducts,
        };
      } else {
        if (!state.catalog_products_visibility) {
          return {
            ...state,
            catalog_products_visibility: [action.payload],
          };
        } else {
          return {
            ...state,
            catalog_products_visibility: [
              ...state.catalog_products_visibility,
              action.payload,
            ],
          };
        }
      }
    case CatalogActions.SET_CUSTOMER_PRICELIST:
      return {
        ...state,
        customerPricelist: action.payload,
      };

    case CatalogActions.SET_ALL_CATEGORIES_MARKET_CARDS_DESIGN:
      return {
        ...state,
        allCategoriesMarketCardsDesign: action.payload,
      };

    case CatalogActions.SET_HYPERCATEGORIES_SELECTION_MODAL_OPENED:
      return {
        ...state,
        hyperCategoriesSelectionModalOpened: action.payload,
      };
    case CatalogActions.CLEAR_CATALOG:
      return {
        ...state,
        catalog: null,
        allCategories: null,
        catalogUnfiltered: null,
      };

    default:
      return state;
  }
}
