<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small"
      >{{ "catalog.previous-order.previous-order-modal.title" | translate }}
      {{ previousOrderId ? "#" + previousOrderId : "" }}
      {{
        previousOrderFromOrderPage && previousOrderFromOrderPage.order_id
          ? "#" + previousOrderFromOrderPage.order_id
          : ""
      }}
    </ion-title>
    <p
      *ngIf="
        previousOrderFromOrderPage &&
        previousOrderStore &&
        stores &&
        stores.length &&
        stores.length > 1 &&
        selectedLangugage
      "
      class="ion-no-padding ion-no-margin"
      style="padding-inline-start: 20px; font-size: small"
    >
      {{
        previousOrderStore.store_name_mobileorder &&
        previousOrderStore.store_name_mobileorder[selectedLangugage]
          ? previousOrderStore.store_name_mobileorder[selectedLangugage]
          : previousOrderStore.store_panel_name_langs &&
            previousOrderStore.store_panel_name_langs[selectedLangugage]
          ? previousOrderStore.store_panel_name_langs[selectedLangugage]
          : previousOrderStore.store_panel_name_langs &&
            previousOrderStore.store_panel_name_langs.el
          ? previousOrderStore.store_panel_name_langs.el
          : previousOrderStore.store_name_panel
          ? previousOrderStore.store_name_panel
          : previousOrderStore.store_name
          ? previousOrderStore.store_name
          : ""
      }}
    </p>

    <ion-buttons slot="end">
      <ion-button fill="clear" color="dark" (click)="closeModal()">
        <ion-icon src="assets/ionicons/close.svg" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row style="width: 100%" *ngIf="!previousOrderCart">
      <ion-col class="ion-text-center">
        <ion-spinner></ion-spinner>
      </ion-col>
    </ion-row>
    <ion-list *ngIf="previousOrderCart && previousOrderCart.length">
      <ion-item
        lines="full"
        button="false"
        *ngFor="let product of previousOrderCart; trackBy: trackProducts"
      >
        <ion-button
          style="margin-right: 15px"
          (click)="selectForReorder(product)"
          fill="clear"
          [disabled]="
            !!(
              (!product.availableTotal &&
                (!product.tags || !product.tags.availableForPreorder) &&
                !product.hideAvailableForPreorderTag) ||
              !product.category ||
              !product.product_categories_availability_check
            ) ||
            !!(
              !product.activeTotal ||
              !product.category ||
              !product.product_categories_active_check
            )
          "
          slot="start"
        >
          <ion-icon
            slot="icon-only"
            *ngIf="product.selectedForReorder"
            color="primary"
            src="assets/ionicons/checkbox.svg"
          ></ion-icon>
          <ion-icon
            slot="icon-only"
            *ngIf="!product.selectedForReorder"
            color="dark"
            src="assets/ionicons/square-outline.svg"
          ></ion-icon>
        </ion-button>
        <ion-label
          [class]="
            !!(
              !product.availableTotal ||
              !product.category ||
              !product.product_categories_availability_check
            ) ||
            !!(
              !product.activeTotal ||
              !product.category ||
              !product.product_categories_active_check
            )
              ? 'line-through'
              : ''
          "
        >
          <ion-text
            *ngIf="!product.enable_quantity_step || !product.quantity_step"
          >
            {{ product.quantity }} x {{ product.name }}
          </ion-text>
          <ion-text
            *ngIf="product.enable_quantity_step && product.quantity_step"
            class="ion-text-wrap"
          >
            {{ product.quantity
            }}{{
              !product.disable_qty && !product.quantity_step_kind
                ? "x"
                : product.quantity_step_kind
            }}
            <ion-text
              ><strong>{{ product.name }}</strong></ion-text
            >
          </ion-text>

          <ion-text
            *ngIf="
              !product.gift &&
              groupApp &&
              (!groupApp.design_settings ||
                !groupApp.design_settings.hideZeroPrices ||
                (groupApp.design_settings &&
                  groupApp.design_settings.hideZeroPrices &&
                  product.price &&
                  product.price !== '0.00'))
            "
            color="dark"
          >
            {{ product.productTotal }}€
          </ion-text>

          <ion-text *ngIf="product.gift">
            {{
              "cart.cart-components.cart-list.cart-list-product-item.free"
                | translate
            }}
          </ion-text>

          <div *ngIf="product && product.category">
            <app-product-item-tags
              [store_info]="store_info"
              [groupApp]="groupApp"
              [previousOrderItem]="true"
              [productItem]="product"
              [orderPickup]="orderPickup"
              [dineIn]="dineIn"
              [selectedPreorderDate]="selectedPreorderDate"
            >
            </app-product-item-tags>
          </div>
          <div>
            <span *ngFor="let option of product.options">
              <div
                *ngIf="
                  option.option_id &&
                  (!option.dependenciesVariable ||
                    option.dependenciesVariable.length === 0 ||
                    (option.dependenciesVariable.length > 0 &&
                      option.showDependent))
                "
                class="secondaryFontSize item-text-wrap"
              >
                <p
                  style="font-size: smaller"
                  *ngIf="
                    option.type !== 'field' &&
                    (!(
                      option.choices &&
                      !option.reverseChoices &&
                      (option.choices | choicesPreselected).length === 0
                    ) ||
                      (option.is_require && option.availableTotal))
                  "
                >
                  <strong>
                    {{
                      option.doNotPrint
                        ? ""
                        : option.title
                        ? option.title + ": "
                        : ""
                    }}
                    {{
                      option.parent_free &&
                      option.option_parent_total_price === 0
                        ? "(" +
                          ("cart.cart-components.cart-list.cart-list-product-item.free"
                            | translate) +
                          ")"
                        : option.option_parent_total_price
                        ? option.parent_free && product.parent_discount
                          ? option.option_parent_total_price +
                            "€ (-" +
                            product.parent_discount +
                            "%)"
                          : option.option_parent_total_price + "€"
                        : ""
                    }}
                  </strong>
                  <span
                    *ngIf="
                      option.type === 'checkbox' &&
                      option.reverseChoices &&
                      option.choicesComment
                    "
                    style="display: inline-block"
                    class="mainFontSize"
                    >{{ option.choicesComment }}
                  </span>
                  <span
                    *ngFor="
                      let choice of option.choices | choicesPreselected;
                      let last = last
                    "
                  >
                    <span
                      style="display: inline-block"
                      *ngIf="
                        !choice.bundle &&
                        !choice.doNotPrint &&
                        (option.type !== 'checkbox' ||
                          !option.reverseChoices ||
                          (!option.choicesComment && !option.reverseChoices))
                      "
                    >
                      {{ choice.printLabel ? choice.printLabel : choice.title
                      }}<strong *ngIf="!choice.choicePriceComment">{{
                        choice.isFree
                          ? ("cart.cart-components.cart-list.cart-list-product-item.free-choice"
                            | translate)
                          : !choice.price
                          ? ""
                          : " " +
                            (orderPickup == true && dineIn == false
                              ? choice.price_takeaway || choice.price
                              : orderPickup == false && dineIn == true
                              ? store_info.dinein &&
                                store_info.alt_dinein_active &&
                                selectedDineInTable &&
                                selectedDineInTable.alt_dinein &&
                                choice.price_alt_dinein
                                ? choice.price_alt_dinein
                                : choice.price_dinein || choice.price
                              : choice.price) +
                            "€"
                      }}</strong
                      ><span *ngIf="choice.choicePriceComment">{{
                        choice.choicePriceComment
                      }}</span
                      >{{ last ? "" : "," }}&nbsp;
                    </span>

                    <app-previous-order-bundle-product-comment
                      [bundle_options]="choice.bundle_options"
                      [bundleProduct]="choice.bundled_product"
                      [orderPickup]="orderPickup"
                      [store_info]="store_info"
                      [selectedDineInTable]="selectedDineInTable"
                      [dineIn]="dineIn"
                      *ngIf="
                        choice.bundle &&
                        choice.bundled_with_product_id &&
                        choice.bundled_product &&
                        !choice.doNotPrint &&
                        (option.type !== 'checkbox' ||
                          !option.reverseChoices ||
                          (!option.choicesComment && !option.reverseChoices))
                      "
                    >
                    </app-previous-order-bundle-product-comment>
                  </span>
                  <!-- 
                  <span
                    class="cartWarningColor"
                    ng-if="
                      option.choices &&
                      (option.choices | filter: { is_preselected: true }).length ===
                        0 &&
                      option.is_require
                    "
                  >
                      {{
                        "cart.cart-components.cart-list.cart-list-product-item.you-must-make-a-choice"
                          | translate
                      }}
                  </span> -->
                </p>

                <!--Field option-->
                <!-- <p
                  style="margin: 0 auto 0 0; font-size: smaller"
                  *ngIf="option.type === 'field' && option.text"
                >
                  <strong>{{ option.title }}:</strong> {{ option.text }}
                </p> -->
              </div>
            </span>
          </div>
          <div *ngIf="product && product | cartProductOptionRequired">
            <ion-text color="danger">
              {{
                "cart.cart-components.cart-list.cart-list-product-item.you-must-make-a-choice"
                  | translate
              }}
            </ion-text>
          </div>

          <div>
            <ion-text
              *ngIf="product && product.needsEdit && product.availableTotal"
              color="danger"
            >
              {{
                "cart.cart-components.cart-list.cart-list-product-item.product-needs-to-be-edited"
                  | translate
              }}
            </ion-text>
          </div>
        </ion-label>
        <ion-button
          style="margin-left: 15px"
          size="small"
          slot="end"
          [disabled]="
            finalPriceLoading ||
            !!(
              !product.activeTotal ||
              !product.category ||
              !product.product_categories_active_check
            )
          "
          (click)="showProductDetailsModal(product)"
          fill="clear"
        >
          <ion-icon
            slot="icon-only"
            color="dark"
            src="assets/ionicons/create.svg"
          ></ion-icon>
        </ion-button>
      </ion-item>
    </ion-list>
    <ion-item
      lines="none"
      *ngIf="previousOrderCart && !previousOrderCart.length"
    >
      <ion-label class="ion-text-wrap ion-text-center">
        {{
          "catalog.previous-order.previous-order-modal.there-are-no-products-for-reorder-here"
            | translate
        }}
      </ion-label>
    </ion-item>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-grid>
    <ion-row
      *ngIf="previousOrderCart && previousOrderCart.length"
      style="width: 100%"
    >
      <ion-col class="ion-text-center">
        <ion-button
          [disabled]="disableAddToCartButton"
          (click)="
            !previousOrderFromOrderPage ? addToCart() : beforeAddingToCart()
          "
          >{{
            previousOrderFromOrderPage
              ? ("catalog.previous-order.previous-order-modal.add-to-cart"
                | translate)
              : ("catalog.previous-order.previous-order-modal.add" | translate)
          }}</ion-button
        >
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
