<swiper [slidesPerView]="1.3" [autoplay]="true" [navigation]="false">
  <ng-template swiperSlide *ngFor="let coupon of loyaltyCoupons" >
    <ion-card>
      <img [src]="coupon.image" />
      <ion-card-header class="ion-text-start">
        <ion-card-subtitle>{{'loyaltySystem.expires-at' | translate}}: {{coupon.expiration_date | date: 'dd/MM' }}</ion-card-subtitle>
        <ion-card-title>
          {{coupon.title}} 
        </ion-card-title>
        <ion-button size="small" (click)="activateCoupon(coupon)" [fill]="coupon.activated_for_scan ? 'outline' : 'solid'">
          {{coupon.activated_for_scan ? ('loyaltySystem.coupon-item.deactivate' | translate) : ('loyaltySystem.coupon-item.activate' | translate)}}
        </ion-button>
      </ion-card-header>
    </ion-card>
  </ng-template>
</swiper>