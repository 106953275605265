<ion-item
  *ngIf="category"
  [class]="
    groupStoreData &&
    groupStoreData.store_info &&
    groupStoreData.store_info.design_settings &&
    groupStoreData.store_info.design_settings.overFlowImageDesign
      ? 'overFlowImgProductCategoryItem'
      : 'categoryItem'
  "
  [id]="category.category_id"
  [lines]="
    groupStoreData &&
    groupStoreData.store_info &&
    groupStoreData.store_info.design_settings &&
    groupStoreData.store_info.design_settings.overFlowImageDesign
      ? 'none'
      : ''
  "
>
  <ion-row style="width: 100%">
    <ion-col size="12" class="noPaddingCols">
      <ion-text color="primary">
        <strong style="font-size: larger">{{ category.name }}</strong>
      </ion-text>
    </ion-col>
    <ion-col
      size="12"
      class="noPaddingCols"
      *ngIf="!category.availableTotal || category.avpc || category.tags"
    >
      <app-category-item-tags
        [categoryItem]="category"
        [groupStoreData]="groupStoreData"
      ></app-category-item-tags>
      <!-- {{ "catalog.categories.category-item.unavailable-category" | translate }} -->
    </ion-col>

    <ion-col size="12" class="DescriptionPaddings" *ngIf="category.description">
      <span
        [ngClass]="{
          'unavailable line-through ': !!!category.availableTotal
        }"
        class="fontSizeSmaller"
      >
        <app-description
          [three_lines_description]="true"
          [description]="category.description"
        ></app-description>
      </span>
    </ion-col>
    <ion-col
      class="noPaddingCols"
      size="12"
      *ngIf="category.promotions && category.promotions.length > 0"
    >
      <div
        *ngFor="
          let promo of category.promotions;
          let i = index;
          trackBy: trackFunc
        "
        class="categoriesWarningColor"
      >
        <span style="font-weight: bold; font-size: small">{{
          promo.description
        }}</span>
      </div>
    </ion-col>
  </ion-row>
</ion-item>
<ion-row *ngIf="category && !category.products" style="width: 100%">
  <ion-col class="ion-text-center">
    <ion-spinner></ion-spinner>
  </ion-col>
</ion-row>
