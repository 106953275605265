import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { AlertController, ModalController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { distinctUntilChanged, first, Subscription } from "rxjs";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import * as fromApp from "../../store/app.reducer";
import * as selectors from "../../store/app.selectors";
import * as _ from "lodash";
import * as DeliveryMethodAddressActions from "../../store/deliveryMethodAddress/deliveryMethodAddress.actions";
import * as currentStoresActions from "../../store/current-store/current-store.actions";
import { Router } from "@angular/router";
import { SelectStorePopupComponent } from "../select-store-popup/select-store-popup.component";
@Component({
  selector: "app-dine-in-modal",
  templateUrl: "./dine-in-modal.component.html",
  styleUrls: ["./dine-in-modal.component.scss"],
})
export class DineInModalComponent implements OnInit, OnDestroy {
  @Input() selectedTableIdFromLink;
  @Input() selectedStoreFromLink;
  public selectedTableFromLinkObject: any;
  public selectedTableId: any;
  public tables: any;
  public orderPickup;
  public dineIn;
  public selectedAddress;
  public selectedPreorderDate;
  public selectedStoreId: any;
  public selectedDineInTable: any;
  public stores: any;
  public currentStoreId: any;
  public selectedLangugage: any;
  public storeInfo: any;
  public fetchTablesLoading;
  public selectedStore;
  public displayOnlySpinner: boolean = false;
  private storeInfoSub: Subscription;
  private groupAppSub: Subscription;
  private subscription: Subscription;
  private storesSubs: Subscription;
  private currentStoreSub: Subscription;
  private unsubscribeBackEvent: Subscription;
  constructor(
    private modalController: ModalController,
    private dataStorageService: DataStorageService,
    private translateService: TranslateService,
    private alertController: AlertController,
    private router: Router,
    private store: Store<fromApp.AppState>,
    private platform: Platform
  ) {}

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );

    this.groupAppSub = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .subscribe(async (state) => {
        if (
          state &&
          state.selectedLangugage &&
          !_.isEqual(state.selectedLangugage, this.selectedLangugage)
        ) {
          this.selectedLangugage = _.cloneDeep(state.selectedLangugage);
        }
      });

    if (this.selectedStoreFromLink) {
      this.displayOnlySpinnerEnabled();
      this.selectedStoreId = _.cloneDeep(this.selectedStoreFromLink);
      this.fetchDineInTablesThrottle(this.selectedStoreId);

      console.log("selected store id from link", this.selectedStoreId);
    }
    this.storesSubs = this.store
      .select("stores")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (state && state.stores && !_.isEqual(state.stores, this.stores)) {
          this.stores = _.cloneDeep(state.stores);
          if (this.selectedStoreId && this.stores) {
            this.selectedStore = _.find(this.stores, {
              store_id: this.selectedStoreId,
            });
          }
          if (
            this.stores &&
            this.stores.length &&
            this.stores.length === 1 &&
            !this.selectedStoreFromLink
          ) {
            this.displayOnlySpinnerEnabled();
            this.store
              .select("currentStore")
              .pipe(first())
              .subscribe(async (state) => {
                if (state && state.currentStoreId) {
                  this.fetchDineInTablesThrottle(state.currentStoreId);
                } else {
                  const alert = await this.alertController.create({
                    header: this.translateService.instant("alert"),
                    message: this.translateService.instant(
                      "errorMessages.problem_reaching_server"
                    ),

                    buttons: ["OK"],
                  });
                  await alert.present();
                }
              })
              .unsubscribe();
          }
        }
      });

    this.currentStoreSub = this.store
      .select("currentStore")
      .pipe(distinctUntilChanged())
      .subscribe(async (state) => {
        if (state && !_.isEqual(state.currentStoreId, this.currentStoreId)) {
          this.currentStoreId = _.cloneDeep(state.currentStoreId);
        }
      });

    this.storeInfoSub = this.store
      .select(selectors.getStoreInfo)
      .pipe(distinctUntilChanged())
      .subscribe((storeInfo) => {
        if (storeInfo && !_.isEqual(storeInfo, this.storeInfo)) {
          this.storeInfo = _.cloneDeep(storeInfo);
        }
      });

    this.subscription = this.store
      .select("deliveryMethodAddress")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (state && !_.isEqual(this.dineIn, state.dineIn)) {
          this.dineIn = _.cloneDeep(state.dineIn);
        }
        if (state && !_.isEqual(this.orderPickup, state.orderPickup)) {
          this.orderPickup = _.cloneDeep(state.orderPickup);
        }
        if (state && !_.isEqual(this.selectedAddress, state.selectedAddress)) {
          this.selectedAddress = _.cloneDeep(state.selectedAddress);
        }
        if (
          state &&
          !_.isEqual(this.selectedPreorderDate, state.selectedPreorderDate)
        ) {
          this.selectedPreorderDate = _.cloneDeep(state.selectedPreorderDate);
        }
        if (
          state &&
          !_.isEqual(this.selectedDineInTable, state.selectedDineInTable) &&
          !this.selectedStoreFromLink
        ) {
          this.selectedDineInTable = _.cloneDeep(state.selectedDineInTable);
          if (!this.selectedTableId && this.selectedDineInTable) {
            this.selectedTableId = _.cloneDeep(
              this.selectedDineInTable.table_id
            );
          }
        }
      });
  }

  closeModal() {
    this.modalController.dismiss();
  }

  selectingTable(ev) {
    console.log("table selection", this.selectedTableId);
  }

  changeStore(ev) {
    this.tables = null;
    if (this.selectedStoreId && this.stores) {
      this.selectedStore = _.find(this.stores, {
        store_id: this.selectedStoreId,
      });
    }

    this.fetchDineInTablesThrottle(this.selectedStoreId);
  }

  clickOkButton() {
    if (this.stores && this.stores.length && this.stores.length === 1) {
      let selectedTable = _.find(this.tables, {
        table_id: this.selectedTableId,
      });

      this.setDineInAndSelectedTableDebounced(selectedTable);
      this.modalController.dismiss();
    } else if (this.stores && this.stores.length && this.stores.length > 1) {
      //HANDLE FOR DIFFERENT STORES

      if (
        this.selectedStoreId &&
        this.selectedStoreId !== this.currentStoreId
      ) {
        this.store.dispatch(
          new currentStoresActions.SetCurrentStoreId(
            _.cloneDeep(this.selectedStoreId)
          )
        );
        let selectedTable = _.find(this.tables, {
          table_id: this.selectedTableId,
        });
        this.setDineInAndSelectedTableDebounced(selectedTable);
        this.modalController.dismiss();
      } else {
        let selectedTable = _.find(this.tables, {
          table_id: this.selectedTableId,
        });

        this.setDineInAndSelectedTableDebounced(selectedTable);
        this.modalController.dismiss();
      }
    }
  }

  private setDineInAndSelectedTableDebounced = _.debounce((selectedTable) => {
    this.setDineInAndSelectedTable(selectedTable);
  }, 300);

  setDineInAndSelectedTable(selectedTable) {
    if (selectedTable) {
      this.store.dispatch(
        new DeliveryMethodAddressActions.setSelectedDineInTable(selectedTable)
      );
      this.store.dispatch(new DeliveryMethodAddressActions.setDineIn(true));
      this.store.dispatch(
        new DeliveryMethodAddressActions.setOrderPickup(false)
      );
      this.store.dispatch(
        new DeliveryMethodAddressActions.setValidDeliveryAreas(null)
      );
      if (this.selectedAddress) {
        this.store.dispatch(
          new DeliveryMethodAddressActions.setCheckSelectedAddress(null)
        );
        this.store.dispatch(
          new DeliveryMethodAddressActions.setSelectedAddress(null)
        );
      }
      if (this.selectedPreorderDate) {
        this.store.dispatch(
          new DeliveryMethodAddressActions.setSelectedPreorderDate(null)
        );
      }
      if (navigator.cookieEnabled) {
        if (localStorage.getItem("deliveryMethodAddress")) {
          localStorage.removeItem("deliveryMethodAddress");
        }

        let deliveryMethodAddress = {
          orderPickup: false,
          dineIn: true,
          selectedDineInTable: selectedTable,
        };
        window.localStorage.setItem(
          "deliveryMethodAddress",
          JSON.stringify(deliveryMethodAddress)
        );
      }
      if (
        this.router &&
        this.router.url &&
        _.includes(_.cloneDeep(this.router.url), "homepage")
      ) {
        this.router.navigateByUrl("/catalog", {
          replaceUrl: true,
        });
      }
    }
  }

  private fetchDineInTablesThrottle = _.throttle(
    (store_id) => {
      this.fetchDineInTables(store_id);
    },
    200,
    { leading: true, trailing: false }
  );

  fetchDineInTables(store_id) {
    this.fetchTablesLoading = true;
    this.dataStorageService.getDineInTables(store_id).subscribe({
      next: async (res: any) => {
        this.fetchTablesLoading = false;
        this.displayOnlySpinnerDisabled();
        if (!res || !res.success) {
          const alert = await this.alertController.create({
            header: this.translateService.instant("alert"),
            message:
              res && res.comment_id
                ? this.translateService.instant(res.comment_id)
                : this.translateService.instant("errorMessages.general_error"),
            backdropDismiss: false,
            buttons: ["OK"],
          });

          await alert.present();
        } else {
          this.tables = res.tables;

          if (
            this.tables &&
            this.tables.length === 1 &&
            !this.selectedTableIdFromLink
          ) {
            const uniqueTable: any = _.first(this.tables);
            this.selectedTableId = _.cloneDeep(uniqueTable.table_id);
            this.clickOkButton();
          }

          if (
            this.selectedTableIdFromLink &&
            this.tables &&
            _.find(this.tables, { table_id: this.selectedTableIdFromLink })
          ) {
            console.log("table found");
            this.selectedTableId = _.cloneDeep(this.selectedTableIdFromLink);
            this.selectedTableFromLinkObject = _.find(this.tables, {
              table_id: this.selectedTableIdFromLink,
            });
            if (this.tables && this.tables.length === 1) {
              this.clickOkButton();
            }
          } else {
            console.log("table not found");
          }
        }
      },
      error: async (error) => {
        this.displayOnlySpinnerDisabled();
        this.fetchTablesLoading = false;
        const alert = await this.alertController.create({
          header: this.translateService.instant("alert"),
          message: this.translateService.instant(
            "errorMessages.problem_reaching_server"
          ),

          buttons: ["OK"],
        });
        await alert.present();
        return error;
      },
    });
  }

  async onStoreSelection(ev) {
    const storesForSelection = _.cloneDeep(this.stores);

    _.each(storesForSelection, (store) => {
      if (store && store.dinein) {
        store.hasTheOffer = true;
      }
    });

    const selected_store_id: any = await this.openSelectStoreModal(
      _.cloneDeep(storesForSelection)
    );

    if (selected_store_id) {
      this.selectedStoreId = _.cloneDeep(selected_store_id);
      this.changeStore("");
    }
  }

  async openSelectStoreModal(storesForSelection) {
    return new Promise(async (resolve, reject) => {
      let cssClass = "selectStorePopupCss";
      if (
        storesForSelection &&
        storesForSelection.length > 1 &&
        storesForSelection.length <= 3
      ) {
        cssClass = "selectStorePopupCssThreeStores";
      } else if (storesForSelection && storesForSelection.length > 3) {
        cssClass = "selectStorePopupCssMultipleStores";
      }
      const modal = await this.modalController.create({
        component: SelectStorePopupComponent,
        componentProps: {
          storesForSelection: storesForSelection,
          forDineinSelection: true,
          onlyReturnSelectedStoreId: true,
        },
        cssClass: cssClass,
        backdropDismiss: false,
      });

      await modal.present();

      await modal.onDidDismiss().then((data) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          resolve(null);
        }
      });
    });
  }

  displayOnlySpinnerEnabled() {
    this.displayOnlySpinner = true;
    const modalElements = document.getElementsByClassName("dineInModal");

    if (
      modalElements &&
      modalElements.item &&
      modalElements.item.length === 1
    ) {
      const element = modalElements[0];

      if (element) {
        element.classList.remove("dineInModal");
        element.classList.add("dineInModalTransparent");
      }
    }
  }

  displayOnlySpinnerDisabled() {
    const modalElements = document.getElementsByClassName(
      "dineInModalTransparent"
    );

    if (
      modalElements &&
      modalElements.item &&
      modalElements.item.length === 1
    ) {
      const element = modalElements[0];

      if (element) {
        element.classList.remove("dineInModalTransparent");
        element.classList.add("dineInModal");
      }
    }

    this.displayOnlySpinner = false;
  }

  ngOnDestroy(): void {
    this.tables = null;
    this.selectedTableId = null;
    this.selectedStoreId = null;

    if (this.groupAppSub) {
      this.groupAppSub.unsubscribe();
    }
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.currentStoreSub) {
      this.currentStoreSub.unsubscribe();
    }
    if (this.storesSubs) {
      this.storesSubs.unsubscribe();
    }
    if (this.storeInfoSub) {
      this.storeInfoSub.unsubscribe();
    }
  }
}
