import { Pipe, PipeTransform } from "@angular/core";
import * as _ from "lodash";

@Pipe({
  name: "calculateNoPromosPointsToEarn",
})
export class CalculateNoPromosPointsToEarnPipe implements PipeTransform {
  transform(product: any, storeInfo: any, groupApp: any): any {
    let order_rule;
    if (
      groupApp &&
      groupApp &&
      groupApp.pointsSystem &&
      groupApp.points &&
      groupApp.points.rules
    ) {
      order_rule = _.find(groupApp.points.rules, {
        type: "order",
      });
    }

    if (
      storeInfo &&
      groupApp &&
      storeInfo &&
      storeInfo.design_settings &&
      storeInfo.design_settings.showPointRulesOnProductItems &&
      !storeInfo.overridePromoItemsForPointsSystem &&
      order_rule &&
      order_rule.points_ratio &&
      order_rule.amount_ratio &&
      product &&
      (product.specialPriceTotal || product.productTotal) &&
      !product.gift &&
      !product.promo
    ) {
      let price = _.cloneDeep(
        product.specialPriceTotal || product.productTotal
      );

      price = parseFloat(
        parseFloat(_.cloneDeep(price).toString().replace("€", "")).toFixed(2)
      );

      return parseInt(
        parseFloat(
          ((price * order_rule.points_ratio) / order_rule.amount_ratio)
            .toFixed(2)
            .toString()
        )
          .toFixed()
          .toString()
      );
    } else {
      return null;
    }
  }
}
