<ion-header>
  <app-no-internet-connection></app-no-internet-connection>
  <app-ionic-update-error></app-ionic-update-error>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="primary" (click)="modalCtrl.dismiss()">
        <ion-icon name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title mode="ios" style="padding-inline: 32px">{{
      "reservation-app.submitDate" | translate
    }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="item-list">
  <ion-grid
    *ngIf="
      !storeInfo ||
      !selectedProduct ||
      !reservationSelectedDate ||
      !reservationSelectedDate.date ||
      !reservationSelectedDate.hour
    "
  >
    <ion-row style="width: 100%">
      <ion-col class="ion-text-center">
        <ion-spinner> </ion-spinner>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid
    *ngIf="
      storeInfo &&
      selectedProduct &&
      reservationSelectedDate &&
      reservationSelectedDate.date &&
      reservationSelectedDate.hour
    "
    style="max-width: 600px"
  >
    <app-customer-info> </app-customer-info>
    <ion-item>
      <ion-icon name="location-sharp"> </ion-icon>
      <ion-label style="margin-left: 10px" class="ion-text-wrap">
        <div style="font-size: small; font-weight: bold; line-height: 1">
          {{
            storeInfo.store_name_mobileorder &&
            storeInfo.store_name_mobileorder[selectedLanguage]
              ? storeInfo.store_name_mobileorder[selectedLanguage]
              : storeInfo.store_name_langs &&
                storeInfo.store_name_langs[selectedLanguage]
              ? storeInfo.store_name_langs[selectedLanguage]
              : storeInfo.store_name_langs
              ? storeInfo.store_name_langs.el
              : storeInfo.store_name
          }}
        </div>
        <div style="font-size: small">
          {{ storeInfo && storeInfo.address ? storeInfo.address : null }}
        </div>
      </ion-label>
    </ion-item>
    <ion-item>
      <ion-icon name="calendar"> </ion-icon>
      <ion-label style="margin-left: 10px" class="ion-text-wrap"
        ><span style="font-weight: bold"
          >{{ reservationSelectedDate.date.day | timestampToString }},{{
            reservationSelectedDate.hour
          }}</span
        >
      </ion-label>
    </ion-item>
    <ion-item *ngIf="selectedArtist && selectedProduct">
      <ion-icon name="person-circle"> </ion-icon>
      <ion-label style="margin-left: 10px" class="ion-text-wrap"
        ><span style="font-weight: bold">{{ selectedProduct.name }}</span> με
        {{
          selectedArtist.name && selectedArtist.name[selectedLanguage]
            ? selectedArtist.name[selectedLanguage]
            : selectedArtist.name.el
        }}</ion-label
      >
    </ion-item>

    <app-reservation-cart-coupons></app-reservation-cart-coupons>

    <app-payment-methods></app-payment-methods>

    <!-- <ion-item lines="none" style="margin-top: 10px">
      <ion-label style="margin-left: 10px" class="ion-text-wrap"
        ><span style="font-weight: bold">{{
          "reservation-app.comments" | translate
        }}</span></ion-label
      >
    </ion-item> -->
    <app-order-comments></app-order-comments>

    <app-cart-prices></app-cart-prices>

    <ion-row style="width: 100%" class="ion-align-items-center">
      <ion-col size="12" class="ion-text-center">
        <ion-button
          shape="round"
          style="min-width: 260px"
          [disabled]="
            finalPriceLoading ||
            checkAddressLoading ||
            manualFinalPriceLoading ||
            preorderDaysLoading ||
            checkLocationDineInLoading
          "
          (click)="completeOrder()"
          >{{
            !finalPriceLoading &&
            !checkAddressLoading &&
            !manualFinalPriceLoading &&
            !preorderDaysLoading &&
            !checkLocationDineInLoading
              ? ("reservation-app.submitDate" | translate)
              : ""
          }}
          <ion-spinner
            *ngIf="
              finalPriceLoading ||
              checkAddressLoading ||
              manualFinalPriceLoading ||
              preorderDaysLoading ||
              checkLocationDineInLoading
            "
          ></ion-spinner>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
