<ion-header *ngIf="groupApp && (groupApp.affiliateActive ||
(groupApp.affiliateProductionMode && currentUser &&
  currentUser.mobile === '6945076868'))">
  <ion-toolbar>
    <ion-title *ngIf="groupApp">{{groupApp.affiliateSideMenuTitle[selectedLanguage]}}
    </ion-title>
    <ion-buttons
      slot="start"
      *ngIf="groupApp && windowDimensions"
    >
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>
    <ion-button [disabled]="couponCodeLoading"
                (click)="addNewCouponCode()"
                slot="end"
                fill="clear"
                *ngIf="currentStoreId && currentUser">
      <ion-icon *ngIf="!couponCodeLoading"
                slot="icon-only"
                src="assets/ionicons/add-outline.svg"></ion-icon>
      <ion-spinner *ngIf="couponCodeLoading"></ion-spinner>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="groupApp && (groupApp.affiliateActive ||
(groupApp.affiliateProductionMode && currentUser &&
  currentUser.mobile === '6945076868'))">
  <ion-grid *ngIf="groupApp && loginState ==='login_loading'">
    <ion-row style="width: 100%">
      <ion-col class="ion-text-center">
        <ion-spinner></ion-spinner>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid style="max-width: 750px"
            *ngIf="groupApp && currentUser && loginState==='login_completed'">
    <ion-card>
      <ion-grid style="max-width: 400px">
        <!-- <ion-img src="assets/flatIcons/refer-a-friend.svg"></ion-img> -->
        <ng-lottie [options]="referAFriendOptions"></ng-lottie>
      </ion-grid>
      <ion-card-header mode="ios">
        <ion-card-title style="font-size: x-large">
          {{'affiliate.bring-your-friends'| translate}}!
        </ion-card-title>
      </ion-card-header>
      <ion-card-content style="padding-bottom: 0">
        <ion-row>
          <ion-col style="padding-left: 0">
            <ion-text [innerHtml]="groupApp.affiliateDescription[selectedLanguage]">
            </ion-text>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col *ngIf="isPlatformBrowser !== null"
                   style="padding-left: 0"
                   class="ion-text-center">
            <ion-chip color="primary"
                      [disabled]="copyButtonDisabled"
                      style="font-size: xx-large; padding: 30px; width: 100%"
                      (click)="copyAffiliateNumber(currentUser.affiliate_coupon_code)">
              <div style="text-align: center; width: 100%">
                <ion-label *ngIf="!failedToCopyText">{{copyButtonDisabled ? ('affiliate.copied' | translate) + "!"
                  : currentUser.affiliate_coupon_code}}</ion-label>
                <ion-label *ngIf="failedToCopyText">Try again</ion-label>
                <ion-icon *ngIf="!copyButtonDisabled"
                          style="font-size: xx-large; margin-left: 10px"
                          name="copy-outline"></ion-icon>
              </div>
            </ion-chip>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="!isPlatformBrowser">
          <ion-col>
            <ion-button expand="block"
                        (click)="socialShareCode(currentUser.affiliate_coupon_code)">
              {{'affiliate.share-your-code'| translate}}
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col [size]="windowDimensions.width > 600 ? '6': '12'"
                   class="ion-align-self-center">
            <ion-item style="border: 1px solid #e0e0e0; border-radius: 10px"
                      lines="none">
              <ion-input type="email"
                         placeholder="Email"
                         [(ngModel)]="recipientEmail">
              </ion-input>
            </ion-item>
          </ion-col>
          <ion-col [size]="windowDimensions.width > 600 ? '6': '12' ">
            <ion-button [disabled]="sendingEmail || !recipientEmail"
                        expand="block"
                        (click)="sendAffiliateCodeEmail(recipientEmail);">
              {{'affiliate.send-invitation-via-email'| translate}}
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-item-divider mode="md"></ion-item-divider>
        <ion-row *ngIf="groupApp.affiliateInformationAndTerms.el">
          <ion-col>
            <ion-item lines="none"
                      style="--padding-start: 0"
                      button
                      (click)="openAffiliateInfoModal()">
              <ion-label>
                Πληροφορίες
                <ion-icon name="information-circle"
                          slot="end"></ion-icon>
              </ion-label>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-card-content>
    </ion-card>
    <ion-row>
      <ion-col class="ion-text-center">
        <!-- <ion-button (click)="openAffiliateReceiversCouponsModal()">
          Κουπόνια από τον κωδικό μου
        </ion-button> -->
        <app-affiliate-receivers-coupons
                                         [affiliate_coupon_code]="currentUser.affiliate_coupon_code"></app-affiliate-receivers-coupons>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid *ngIf="!currentUser && loginState ==='no_login'"
            style="max-width: 750px">
    <ion-card>
      <ion-grid style="max-width: 400px">
        <!-- <ion-img src="assets/flatIcons/refer-a-friend.svg"></ion-img> -->
        <ng-lottie [options]="referAFriendOptions"></ng-lottie>
      </ion-grid>
      <ion-card-header mode="ios">
        <ion-card-title style="font-size: x-large">
          {{'affiliate.bring-your-friends'| translate}}!
        </ion-card-title>
      </ion-card-header>
      <ion-card-content style="padding-bottom: 0">
        <ion-row style="width: 100%">
          <ion-col style="padding-left: 0">
            <ion-text [innerHtml]="groupApp.affiliateDescription[selectedLanguage]">
            </ion-text>
          </ion-col>

          <ion-col size="12"
                   class="ion-text-center">
            <ion-button [disabled]="!loginLoading"
                        color="primary"
                        (click)="presentMagicLoginPopup()">
              {{ "homepage.magic-login" | translate }}
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-card-content>
    </ion-card>
  </ion-grid>
  <div
    *ngIf="groupApp &&
    groupApp.design_settings &&
    groupApp.design_settings.menuFooterTabs && windowDimensions && windowDimensions.width<900"
    style="margin-top: 65px"
  ></div>
</ion-content>

<app-menu-footer-tabs></app-menu-footer-tabs>
