import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { RatingDetailsComponent } from "../modals/rating-details/rating-details.component";
import * as _ from "lodash";

@Component({
  selector: "app-ratings-item",
  templateUrl: "./ratings-item.component.html",
  styleUrls: ["./ratings-item.component.scss"],
})
export class RatingsItemComponent implements OnInit, OnDestroy {
  @Input() currentReview: any;
  private ratingDetailsModal: any;
  public reviewStarAverage: any;
  public starColor;

  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {
    if (this.currentReview) {
      this.calculateDetails();
    }
  }

  async onReviewClick() {
    this.ratingDetailsModal = await this.modalCtrl.create({
      component: RatingDetailsComponent,
      componentProps: { currentReview: this.currentReview },
      backdropDismiss: false,
    });

    await this.ratingDetailsModal.present();
    this.ratingDetailsModal.onDidDismiss().then(() => {
      this.ratingDetailsModal = null;
    });
  }

  calculateDetails() {
    this.reviewStarAverage = null;
    let sum = 0;
    let num = 0;

    _.each(this.currentReview.questions, (question) => {
      if (question && question.stars) {
        sum = sum + question.stars;
        num = num + 1;
      }
    });
    if (num !== 0) {
      this.reviewStarAverage = sum / num;

      this.reviewStarAverage = this.reviewStarAverage.toFixed(2);

      if (
        this.reviewStarAverage &&
        parseFloat(this.reviewStarAverage.toString())
      ) {
        const avg = parseFloat(this.reviewStarAverage.toString());
        if (avg <= 2.5) {
          this.starColor = "danger";
        } else if (avg > 2.5 && avg <= 3.5) {
          this.starColor = "warning";
        } else if (avg > 3.5) {
          this.starColor = "complete";
        }
      }

      console.log("review average", this.reviewStarAverage);
    }
  }

  ngOnDestroy() {
    if (this.ratingDetailsModal) {
      this.ratingDetailsModal.dismiss();
      this.ratingDetailsModal = null;
    }
  }
}
