import { Pipe, PipeTransform } from "@angular/core";
import * as _ from "lodash";

@Pipe({
  name: "checkboxPromotionsOnlinePayment",
})
export class CheckboxPromotionsOnlinePaymentPipe implements PipeTransform {
  transform(array: any): any[] {
   // console.log("pipe called");
    return _.filter(array, {
      onlinePaymentOnly: true,
    });
  }
}
