<ion-grid
  *ngIf="
    categories &&
    hyperCategories &&
    hyperCategories.length &&
    selectedHyperCategory
  "
  class="ion-no-padding ion-text-center"
  style="max-width: 450px"
>
  <ion-item
    color="primary"
    lines="full"
    detail="false"
    button="true"
    (click)="openHypercategoriesSelectionModal()"
  >
    <ion-label
      [class]="
        fontSize === 'small'
          ? 'ion-text-wrap ion-no-margin ion-text-center font-size-small'
          : 'ion-text-wrap ion-no-margin ion-text-center font-size-medium'
      "
    >
      <div class="wrapInTwoLines">
        {{ selectedHyperCategory.name | translate }}
      </div>
    </ion-label>

    <ion-icon
      slot="end"
      style="font-size: 20px; margin-left: 5px"
      src="assets/ionicons/chevron-forward-outline.svg"
    >
    </ion-icon>
  </ion-item>
</ion-grid>
