<ion-radio-group
  allow-empty-selection
  *ngIf="option && option.choices && selectedLang"
  (ionChange)="onRadioChanges($event)"
  [(ngModel)]="selectedChoiceTimestamp"
>
  <ion-item
    lines="none"
    *ngFor="let choice of option.choices; trackBy: trackTimestamp"
  >
    <ion-label
      *ngIf="
        choice.title &&
        (!choice.translations ||
          (choice.translations && !choice.translations.title) ||
          (choice.translations &&
            choice.translations.title &&
            !choice.translations.title[selectedLang]))
      "
      class="ion-text-wrap"
    >
      {{ choice.title
      }}{{
        choice.price ? " - " + choice.price.toFixed(2) + "€" : ""
      }}</ion-label
    >

    <ion-label
      *ngIf="
        choice.translations &&
        choice.translations.title &&
        choice.translations.title[selectedLang]
      "
      class="ion-text-wrap"
    >
      {{ choice.translations.title[selectedLang]
      }}{{ choice.price ? " - " + choice.price.toFixed(2) + "€" : "" }}

      <div
        *ngIf="
          choice.minOrder &&
          choice.selected &&
          option.minOrderCheckChoices &&
          cartPrices &&
          grand_total &&
          grand_total < choice.minOrder
        "
      >
        <ion-text color="danger" style="font-size: small; font-weight: bold">
          {{
            ("cartCustomOptions.choice-has-min-order" | translate) +
              " " +
              choice.minOrder.toFixed(2) +
              "€"
          }}
        </ion-text>
      </div>
    </ion-label>

    <ion-radio
      [disabled]="finalPriceLoading || choice.outOfPeriod"
      [value]="choice.timestamp"
    ></ion-radio>
  </ion-item>
</ion-radio-group>
