import { Action } from "@ngrx/store";

export const SET_PRODUCT_DETAILS_DIMENSIONS =
  "[PRODUCT DETAILS DIMENSIONS] Set Product Details Dimensions";

export const CLEAR_PRODUCT_DETAILS_DEMENSIONS =
  "[PRODUCT DETAILS DIMENSIONS] Clear Product Details Dimensions";

export class SetProductDetailsDimensions implements Action {
  readonly type = SET_PRODUCT_DETAILS_DIMENSIONS;
  constructor(
    public payload: { height: any; width: any; selectedProductId: any }
  ) {}
}

export class ClearProductDetailDimensions implements Action {
  readonly type = CLEAR_PRODUCT_DETAILS_DEMENSIONS;
  constructor(public payload: any) {}
}

export type ProductDetailsDimensionsActions =
  | SetProductDetailsDimensions
  | ClearProductDetailDimensions;
