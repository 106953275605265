<ion-button
  color="black"
  fill="clear"
  (click)="closeModal()"
  style="position: absolute; top: -10px; right: 0px"
  ><ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
</ion-button>
<iframe
  sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
  [src]="nbg_simplify_frame"
  style="padding: 0; border-width: 0px"
  [width]="frame_width + 'px'"
  [height]="frame_height + 'px'"
>
  >
</iframe>
