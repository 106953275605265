import { Action } from "@ngrx/store";

export const SET_SELECTED_CATEGORY =
  "[CATEGORIES SELECTION] Set Selected Category";
export const SET_SCROLL_SELECTED_CATEGORY =
  "[CATEGORIES SELECTION] Set Scroll Selected Category";
export const CLEAR_SELECTED_CATEGORY =
  "[CATEGORIES SELECTION] Clear Vertical Category";

export class SetSelectedCategory implements Action {
  readonly type = SET_SELECTED_CATEGORY;
  constructor(public payload: { category_id: any }) {}
}

export class SetScrollSelectedCategory implements Action {
  readonly type = SET_SCROLL_SELECTED_CATEGORY;
  constructor(public payload: { category_id: any }) {}
}

export class ClearCategoriesSelection implements Action {
  readonly type = CLEAR_SELECTED_CATEGORY;
  constructor() {}
}
export type CategoriesSelectionActions =
  | SetSelectedCategory
  | SetScrollSelectedCategory
  | ClearCategoriesSelection;
