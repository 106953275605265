<ion-footer>
  <ion-toolbar>
    <ion-item
      *ngIf="
        !(
          groupApp &&
          groupApp.design_settings &&
          groupApp.design_settings.disable_app_darkmode
        )
      "
      [color]="appChannel && appChannel === 'mastercapacitor' ? 'notValid' : ''"
      class="ion-no-padding ion-no-margin"
      lines="none"
    >
      <ion-button fill="clear" slot="end" (click)="darkMode()">
        <ion-icon
          slot="icon-only"
          *ngIf="!darkTheme"
          src="assets/ionicons/moon-outline.svg"
        >
        </ion-icon>
        <ion-icon
          slot="icon-only"
          *ngIf="darkTheme"
          src="assets/ionicons/sunny-outline.svg"
        >
        </ion-icon>
      </ion-button>
    </ion-item>
    <ion-item
      class="creditsItemPaddings"
      [color]="appChannel && appChannel === 'mastercapacitor' ? 'notValid' : ''"
      lines="none"
      detail="false"
      [button]="
        groupApp &&
        groupApp.design_settings &&
        groupApp.design_settings.menuFooterTabs &&
        windowDimensions &&
        windowDimensions.width < 500
          ? false
          : true
      "
      (click)="
        groupApp &&
        groupApp.design_settings &&
        groupApp.design_settings.menuFooterTabs &&
        windowDimensions &&
        windowDimensions.width < 500
          ? ''
          : openLink('https://www.deliverymanager.gr')
      "
    >
      <!-- <ion-icon style="font-size: 80px;" slot="start" src="assets/deliveryManagerLogo/deliveryManager.svg">

      </ion-icon>
      <ion-thumbnail slot="start">
     
      </ion-thumbnail> -->
      <ion-avatar slot="start">
        <img
          *ngIf="!animationPlaying"
          [src]="
            darkTheme
              ? 'assets/deliveryManagerLogo/deliveryManagerWhite.png'
              : 'assets/deliveryManagerLogo/deliveryManager.svg'
          "
          alt="delivery-manager"
        />
        <ng-lottie
          *ngIf="startAnimation"
          (animationCreated)="animationCreated($event)"
          (domLoaded)="animStartPlaying()"
          (loopComplete)="animationLoopCompleted($event)"
          (error)="animationError($event)"
          style="z-index: 100"
          [options]="animationDeliveryManagerOptions"
        >
        </ng-lottie>
      </ion-avatar>
      <ion-label>
        <ion-text style="font-size: small" color="dark"
          >Powered by Delivery Manager IKE</ion-text
        >
        <p>
          <ion-text color="dark"> v{{ version }} </ion-text>
        </p>
      </ion-label>
    </ion-item>
  </ion-toolbar>
</ion-footer>
