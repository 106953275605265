import { Component, OnDestroy, OnInit } from "@angular/core";
import * as _ from "lodash";
import { Store } from "@ngrx/store";
import {
  auditTime,
  catchError,
  distinctUntilChanged,
  first,
} from "rxjs/operators";
import { Subscription } from "rxjs";
import * as fromApp from "../store/app.reducer";
import * as ReviewsActions from "./store/reviews.actions";
import { TranslateService } from "@ngx-translate/core";
import {
  AlertController,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { DataStorageService } from "../services/data-storage/data-storage.service";
import { Router } from "@angular/router";
import { AnalyticsServiceService } from "../services/analitycsService/analytics-service.service";
import { OrderNowPopupComponent } from "../popups/order-now-popup/order-now-popup.component";
import * as DeliveryMethodAddressActions from "../store/deliveryMethodAddress/deliveryMethodAddress.actions";
import { SelectStorePopupComponent } from "../popups/select-store-popup/select-store-popup.component";
import { OrderNowModalPopupsService } from "../services/orderNowModalPopupsService/orderNowModalPopups.service";
import { RatingDetailsComponent } from "./modals/rating-details/rating-details.component";
import { AnimationOptions } from "ngx-lottie";
import { SelectStoreImagePopupComponent } from "../popups/select-store-image-popup/select-store-image-popup.component";

@Component({
  selector: "app-ratings",
  templateUrl: "./ratings.page.html",
  styleUrls: ["./ratings.page.scss"],
})
export class RatingsPage implements OnDestroy {
  public lastReviewsArray: any;
  public currentUser: any;
  public loginLoading: any;
  public checkAddressLoading: boolean = false;
  public orderPickup: boolean;
  public dineIn: boolean;
  public selectedDineInTable: any;
  public stores: any;
  public currentStoreId: any;
  public currentStore: any;
  public selectedAddress: any;
  public showDeliveryButton: boolean;
  public showPickupButton: boolean;
  public showDineInButton: boolean;
  public windowInfo: any;
  public menuMinWidthToShow: any;
  public cartProducts: any;
  public selectedLanguage: any;
  public groupApp;

  private oneSignalMessage: any;
  private subscriptions: Subscription[] = [];
  private mo_token: any;
  private unsubscribeBackEvent: Subscription;
  private orderNowModal: any;
  private selectStoreModal;
  private ratingDetailsModal: any;
  private infoShowDeliveryButton;
  animationNoReviewsOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/no-reviews-animation.json",
  };
  constructor(
    private store: Store<fromApp.AppState>,
    private translateService: TranslateService,
    private toastController: ToastController,
    private dataStorageService: DataStorageService,
    private router: Router,
    private analyticsService: AnalyticsServiceService,
    private alertController: AlertController,
    private platform: Platform,
    private modalController: ModalController,
    private orderNowModalPopupsService: OrderNowModalPopupsService
  ) {}

  ionViewDidEnter() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      100,
      () => {
        this.navigateToHome();
      }
    );
    this.analyticsService.fbqTrackPageView();
    this.analyticsService.setCurrentScreenFirebase("ratings");
    this.subscriptions.push(
      this.store
        .select("oneSignalMessages")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.oneSignalMessage &&
            !_.isEqual(state.oneSignalMessage, this.oneSignalMessage)
          ) {
            this.oneSignalMessage = _.cloneDeep(state.oneSignalMessage);
            if (
              this.oneSignalMessage.additionalData &&
              this.oneSignalMessage.additionalData.reason &&
              this.oneSignalMessage.additionalData.reason ===
                "orderStatusUpdate"
            ) {
              this.getLastReviewsArray();
            } else if (
              this.oneSignalMessage &&
              this.oneSignalMessage.additionalData &&
              this.oneSignalMessage.additionalData.reason === "reviewAnswer"
            ) {
              if (this.oneSignalMessage.additionalData.order_id) {
                let currentReview = _.find(this.lastReviewsArray, {
                  order_id: this.oneSignalMessage.additionalData.order_id,
                });
                if (currentReview) {
                  this.presentRatingDetailsModal(_.cloneDeep(currentReview));
                }
              }
            }
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("reviews")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            state.reviewsArray &&
            !_.isEqual(state.reviewsArray, this.lastReviewsArray)
          ) {
            this.lastReviewsArray = _.cloneDeep(state.reviewsArray);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(state.groupApp, this.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("cart")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && !_.isEqual(state.products, this.cartProducts)) {
            this.cartProducts = _.cloneDeep(state.products);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((state) => {
          if (state && state.stores && !_.isEqual(this.stores, state.stores)) {
            this.stores = _.cloneDeep(state.stores);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.selectedLangugage &&
            !_.isEqual(this.selectedLanguage, state.selectedLangugage)
          ) {
            this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("currentStore")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((state) => {
          if (
            state &&
            state.currentStoreId &&
            !_.isEqual(this.currentStoreId, state.currentStoreId)
          ) {
            this.currentStoreId = _.cloneDeep(state.currentStoreId);
            this.store
              .select("stores")
              .subscribe((state) => {
                if (state && state.stores) {
                  this.currentStore = _.cloneDeep(
                    _.find(state.stores, {
                      store_id: this.currentStoreId,
                    })
                  );
                }
              })
              .unsubscribe();
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())

        .subscribe(async (state) => {
          if (state && state.stores && !_.isEqual(this.stores, state.stores)) {
            this.stores = _.cloneDeep(state.stores);

            this.showDeliveryButton = !_.isEmpty(
              _.filter(this.stores, (store) => {
                if (!store.disableOrderDelivery) {
                  return store;
                }
              })
            );

            if (!this.showDeliveryButton) {
              this.infoShowDeliveryButton =
                await this.orderNowModalPopupsService.checkToShowInfoDeliveryButton();

              this.infoShowDeliveryButton = !!this.infoShowDeliveryButton;
            }

            this.showPickupButton = !_.isEmpty(
              _.filter(this.stores, (store) => {
                if (!store.disableOrderPickup) {
                  return store;
                }
              })
            );
            console.log("pickup button", this.showPickupButton);

            this.showDineInButton = !_.isEmpty(
              _.filter(this.stores, (store) => {
                if (store.dinein) {
                  return store;
                }
              })
            );
            console.log("dine in button");
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("deliveryMethodAddress")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (state && !_.isEqual(state.orderPickup, this.orderPickup)) {
            this.orderPickup = _.cloneDeep(state.orderPickup);
          }
          if (state && !_.isEqual(state.dineIn, this.dineIn)) {
            this.dineIn = _.cloneDeep(state.dineIn);
          }
          if (
            state &&
            state.selectedAddress &&
            !_.isEqual(state.selectedAddress, this.selectedAddress)
          ) {
            this.selectedAddress = _.cloneDeep(state.selectedAddress);
          }
          if (
            state &&
            !_.isEqual(this.selectedDineInTable, state.selectedDineInTable)
          ) {
            this.selectedDineInTable = _.cloneDeep(state.selectedDineInTable);
          }
          if (
            state &&
            !_.isEqual(state.selectedAddressLoading, this.checkAddressLoading)
          ) {
            this.checkAddressLoading = _.cloneDeep(
              state.selectedAddressLoading
            );
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.mo_token &&
            !_.isEqual(this.mo_token, state.mo_token)
          ) {
            this.mo_token = _.cloneDeep(state.mo_token);
          }
          if (state && state.user && !_.isEqual(this.currentUser, state.user)) {
            this.currentUser = _.cloneDeep(state.user);
            this.getLastReviewsArray();
          }
          if (
            state &&
            state.loginLoading &&
            !_.isEqual(this.loginLoading, state.loginLoading)
          ) {
            this.loginLoading = _.cloneDeep(state.loginLoading);
            if (this.loginLoading === "not-loading" && _.isEmpty(state.user)) {
              this.presentErrorToast(
                this.translateService.instant(
                  "ratings.you-must-be-logged-in-to-see-your-ratings"
                ),
                "danger"
              );
              this.router.navigateByUrl("/homepage", {
                replaceUrl: true,
              });
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.windowDimensions &&
            !_.isEqual(this.windowInfo, state.windowDimensions)
          ) {
            this.windowInfo = _.cloneDeep(state.windowDimensions);
            this.menuMinWidthToShow = getComputedStyle(
              document.documentElement
            ).getPropertyValue("--menuMinWidthToShow");
            if (this.menuMinWidthToShow) {
              this.menuMinWidthToShow = parseInt(this.menuMinWidthToShow);
            }
            console.log("menu min with to show", this.menuMinWidthToShow);
          }
          console.log("window info", this.windowInfo);
        })
    );
  }

  goToOrdersPage() {
    this.router.navigateByUrl("/orders", {
      replaceUrl: true,
    });
  }

  async presentSelectStoreModal() {
    if (this.groupApp && this.groupApp.images_select_store_mobileorder) {
      this.selectStoreModal = await this.modalController.create({
        component: SelectStoreImagePopupComponent,
        cssClass: "selectStoreImagePopup",
        backdropDismiss: true,
      });

      await this.selectStoreModal.present();
      this.selectStoreModal.onDidDismiss().then(() => {
        this.selectStoreModal = null;
      });
    } else {
      let cssClass = "selectStorePopupCss";
      if (this.stores && this.stores.length > 1 && this.stores.length <= 3) {
        cssClass = "selectStorePopupCssThreeStores";
      } else if (this.stores && this.stores.length > 3) {
        cssClass = "selectStorePopupCssMultipleStores";
      }
      this.selectStoreModal = await this.modalController.create({
        component: cssClass,
        cssClass: "selectStorePopupCss",
        backdropDismiss: false,
      });

      await this.selectStoreModal.present();
      this.selectStoreModal.onDidDismiss().then(() => {
        this.selectStoreModal = null;
      });
    }
  }

  checkTheCatalog() {
    if (
      this.stores &&
      this.stores.length > 1 &&
      this.orderPickup == null &&
      this.dineIn == null
    ) {
      this.presentSelectStoreModal();
    } else {
      // this.store.dispatch(
      //   new CurrentStoreActions.SetCurrentStoreId(this.stores[0].store_id)
      // );
      // if (navigator.cookieEnabled) {
      //   window.localStorage.setItem("store_id", this.currentStoreId);
      // }

      this.router.navigateByUrl("/catalog", {
        replaceUrl: true,
      });
    }
  }

  async presentOrderNowModal() {
    this.store.dispatch(
      new DeliveryMethodAddressActions.SetNavigationOnDeliveryMethodChanged(
        true
      )
    );
    if (!this.showPickupButton && !this.showDineInButton) {
      this.orderNowModalPopupsService.checkAndOpenSettingsModal(
        "delivery",
        this.currentUser,
        this.selectedAddress,
        this.stores,
        this.currentStoreId,
        true,
        false
      );
    } else if (
      !this.showPickupButton &&
      !this.showDeliveryButton &&
      !this.infoShowDeliveryButton
    ) {
      this.orderNowModalPopupsService.checkAndOpenSettingsModal(
        "dine-in",
        this.currentUser,
        this.selectedAddress,
        this.stores,
        this.currentStoreId,
        true,
        false
      );
    } else if (
      !this.showDineInButton &&
      !this.showDeliveryButton &&
      !this.infoShowDeliveryButton
    ) {
      this.orderNowModalPopupsService.checkAndOpenSettingsModal(
        "take-away",
        this.currentUser,
        this.selectedAddress,
        this.stores,
        this.currentStoreId,
        true,
        false
      );
    } else {
      this.orderNowModal = await this.modalController.create({
        component: OrderNowPopupComponent,
        cssClass: "orderNowPopupCss",
        backdropDismiss: true,
      });

      await this.orderNowModal.present();
      this.orderNowModal.onDidDismiss().then(() => {
        this.orderNowModal = null;
      });
    }
  }

  getLastReviewsArray() {
    this.dataStorageService
      .getReviews(this.mo_token)

      .subscribe({
        next: async (res: any) => {
          if (res && res.success) {
            console.log("success", res);
            let lastReviewsArray = res.data;
            this.store.dispatch(
              new ReviewsActions.SetReviews(lastReviewsArray)
            );
          } else if (res && !res.success) {
            if (res.comment_id) {
              let errorMsg = this.translateService.instant(
                "errorMessages-an-error-occurred"
              );
              this.presentErrorToast(errorMsg, "danger");
            } else if (res.comments) {
              this.presentErrorToast(res.comments, "danger");
            }
          } else {
            const alert = await this.alertController.create({
              header: this.translateService.instant("alert"),
              message: this.translateService.instant(
                "errorMessages.problem_reaching_server"
              ),

              buttons: ["OK"],
            });
            await alert.present();
          }
        },
        error: async (err) => {
          const alert = await this.alertController.create({
            header: this.translateService.instant("alert"),
            message: this.translateService.instant(
              "errorMessages.problem_reaching_server"
            ),

            buttons: ["OK"],
          });
          await alert.present();
          return err;
        },
      });
  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }

  trackFn(index, item) {
    return item.timestamp;
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }

  private navigateToHome() {
    if (this.platform.is("android")) {
      this.router.navigateByUrl("/homepage", {
        replaceUrl: true,
      });
    }
  }

  async presentRatingDetailsModal(currentReview) {
    this.ratingDetailsModal = await this.modalController.create({
      component: RatingDetailsComponent,
      componentProps: { currentReview: currentReview },
      backdropDismiss: false,
    });

    await this.ratingDetailsModal.present();
    this.ratingDetailsModal.onDidDismiss().then(() => {
      this.ratingDetailsModal = null;
    });
  }

  ionViewDidLeave() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    this.subscriptions = [];
    if (this.orderNowModal) {
      this.orderNowModal.dismiss();
      this.orderNowModal = null;
    }
    if (this.selectStoreModal) {
      this.selectStoreModal.dismiss();
      this.selectStoreModal = null;
    }
  }
}
