import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { VERSION } from "../../../environments/version";
import { AlertController, ModalController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { auditTime, distinctUntilChanged, first, Subscription } from "rxjs";
import * as fromApp from "../../store/app.reducer";
import * as selectors from "../../store/app.selectors";
import * as _ from "lodash";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import { AnalyticsServiceService } from "src/app/services/analitycsService/analytics-service.service";
import * as CartActions from "../../cart/store/cart.actions";
import * as OrderCommentsActions from "../../cart/cart-components/order-comments/store/orderComments.actions";
import * as DisableFinalPriceActions from "../../store/disable-final-price/disable-final-price.actions";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import * as selectedInvoiceActions from "../../cart/cart-components/invoice/store/selectedInvoice.actions";
import * as DeliveryMethodAddressActions from "../../store/deliveryMethodAddress/deliveryMethodAddress.actions";
import * as CartCustomerOptionsActions from "../../../app/cart/cart-components/cart-custom-options/store/cart-customer-options/cart-customer-options.actions";
import * as CartCustomerOptionsSelectionsActions from "../../../app/cart/cart-components/cart-custom-options/store/cart-custom-options-selections/cart-custom-options-selections.actions";
import { LocalStorageDataService } from "src/app/services/local-storage-data/local-storage-data.service";
import { AnimationOptions } from "ngx-lottie";
import { CartPageAlertsService } from "src/app/services/cartPageAlerts/cart-page-alerts.service";
import { CreateOrderFailedPreorderModalComponent } from "../../popups/create-order-failed-preorder-modal/create-order-failed-preorder-modal.component";
import { Capacitor } from "@capacitor/core";
import { InformationMessagesService } from "src/app/services/informationMessages/information-messages.service";
import * as ReservationsActions from "../../reservations/store/reservations.actions";
import { ExtendModalComponent } from "../extend-modal/extend-modal.component";
declare let window: any;

@Component({
  selector: "app-reservation-send-order-popup",
  templateUrl: "./reservation-send-order-popup.component.html",
  styleUrls: ["./reservation-send-order-popup.component.scss"],
})
export class ReservationSendOrderPopupComponent implements OnInit {
  public orderPickup: any;
  public dineIn: any;
  public selectedAddress: any;
  public currentUser: any;
  public currentStoreId: any;
  public groupApp: any;
  public cartPrices: any;
  public checkBoxPromotions: any;
  public cartCustomOptions: any;
  public cart: any;
  public versionNumber: any;
  public customerInvoice: any;
  public migration_customer_id: any;
  public coupons: any;
  public selectedCoupons;
  public orderComments: any;
  public generalComments: any;
  public selectedPaymentMethod: any;
  public mo_token: any;
  public loading: boolean;
  public store_info: any;
  public version = VERSION.version;

  public checkStoreOnlineInfo: any;
  public deliveryTimeIsNumber: boolean;
  public pickUpTimeIsNumber: boolean;
  public currentOrderPoints: any;
  public selectedLanguage: any;
  public selectedDineInTable: any;
  public createOrderLoadingAnimationInstance: any;
  public createOrderLoadingAnimation: boolean = false;
  public animateSendOrderButton: boolean = false;
  public createOrderModalInfoMessage;
  public mo_base;
  public reservationSelectedDate;

  private developerMode: boolean;
  private subscription: Subscription;
  private storeInfoSubscription: Subscription;
  private createOrderRetried = false;
  private unsubscribeBackEvent: Subscription;
  private storesSub: Subscription;
  private localStorageService: LocalStorageDataService;

  animationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/create-order-animation.json",
  };

  constructor(
    private modalController: ModalController,
    private store: Store<fromApp.AppState>,
    private dataStorageService: DataStorageService,
    private analyticsService: AnalyticsServiceService,
    private translate: TranslateService,
    private alertController: AlertController,
    private router: Router,
    private platform: Platform,
    private infoMessagesService: InformationMessagesService,
    private cartPageAlertsService: CartPageAlertsService
  ) {}

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );
    this.storeInfoSubscription = this.store
      .select(selectors.getStoreInfo)
      .pipe(distinctUntilChanged())
      .subscribe((storeInfo) => {
        if (storeInfo && !_.isEqual(storeInfo, this.store_info)) {
          this.store_info = _.cloneDeep(storeInfo);
          console.log("store Info from cart options", this.store_info);
        }
      });

    this.storesSub = this.store
      .select("stores")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(50))
      .subscribe((state) => {
        if (
          state &&
          state.checkStoreOnlineInfo &&
          !_.isEqual(state.checkStoreOnlineInfo, this.checkStoreOnlineInfo)
        ) {
          this.checkStoreOnlineInfo = _.cloneDeep(state.checkStoreOnlineInfo);

          if (
            this.checkStoreOnlineInfo &&
            this.checkStoreOnlineInfo.deliveryTime &&
            this.checkStoreOnlineInfo.deliveryTime
              .toString()
              .match(/^[0-9]+$/) != null
          ) {
            this.deliveryTimeIsNumber = true;
          } else {
            this.deliveryTimeIsNumber = false;
          }
          if (
            this.checkStoreOnlineInfo &&
            this.checkStoreOnlineInfo.pickupDeliveryTime &&
            this.checkStoreOnlineInfo.pickupDeliveryTime
              .toString()
              .match(/^[0-9]+$/) != null
          ) {
            this.pickUpTimeIsNumber = true;
          } else {
            this.pickUpTimeIsNumber = false;
          }
        }
      });

    this.subscription = this.store
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (
          state &&
          state.auth &&
          state.auth.user &&
          !_.isEqual(state.auth.user, this.currentUser)
        ) {
          this.currentUser = _.cloneDeep(state.auth.user);
        }
        if (
          state &&
          state.auth &&
          state.auth.migration_customer_id &&
          !_.isEqual(
            state.auth.migration_customer_id,
            this.migration_customer_id
          )
        ) {
          this.migration_customer_id = _.cloneDeep(
            state.auth.migration_customer_id
          );
        }
        if (
          state &&
          state.auth &&
          state.auth.mo_token &&
          !_.isEqual(state.auth.mo_token, this.mo_token)
        ) {
          this.mo_token = _.cloneDeep(state.auth.mo_token);
        }
        if (
          state &&
          state.cartCustomerOptions &&
          state.cartCustomerOptions.cartCustomerOptions &&
          !_.isEqual(
            state.cartCustomerOptions.cartCustomerOptions,
            this.cartCustomOptions
          )
        ) {
          this.cartCustomOptions = _.cloneDeep(
            state.cartCustomerOptions.cartCustomerOptions
          );
        }
        if (
          state &&
          state.deliveryMethodAddress &&
          !_.isEqual(state.deliveryMethodAddress.orderPickup, this.orderPickup)
        ) {
          this.orderPickup = _.cloneDeep(
            state.deliveryMethodAddress.orderPickup
          );
        }
        if (
          state &&
          state.deliveryMethodAddress &&
          !_.isEqual(
            state.deliveryMethodAddress.selectedDineInTable,
            this.selectedDineInTable
          )
        ) {
          this.selectedDineInTable = _.cloneDeep(
            state.deliveryMethodAddress.selectedDineInTable
          );
        }
        if (
          state &&
          state.deliveryMethodAddress &&
          !_.isEqual(state.deliveryMethodAddress.dineIn, this.dineIn)
        ) {
          this.dineIn = _.cloneDeep(state.deliveryMethodAddress.dineIn);
        }
        if (
          state &&
          state.currentStore &&
          state.currentStore.currentStoreId &&
          !_.isEqual(state.currentStore.currentStoreId, this.currentStoreId)
        ) {
          this.currentStoreId = _.cloneDeep(state.currentStore.currentStoreId);
        }
        if (
          state &&
          state.deliveryMethodAddress &&
          state.deliveryMethodAddress.selectedAddress &&
          !_.isEqual(
            state.deliveryMethodAddress.selectedAddress,
            this.selectedAddress
          )
        ) {
          this.selectedAddress = _.cloneDeep(
            state.deliveryMethodAddress.selectedAddress
          );
        }
        if (
          state &&
          state.groupApp &&
          state.groupApp.groupApp &&
          !_.isEqual(state.groupApp.groupApp, this.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp.groupApp);

          this.store
            .select(selectors.getStoreInfo)
            .pipe(first())
            .subscribe((store_info) => {
              if (store_info) {
                const infoMessagesGroup =
                  this.infoMessagesService.checkForMobileOrderInfoMessagesGroupApp(
                    this.groupApp,
                    store_info,
                    "completeOrderModal"
                  );

                const infoMessagesStore =
                  this.infoMessagesService.checkForMobileOrderInfoMessagesGroupApp(
                    this.groupApp,
                    store_info,
                    "completeOrderModal"
                  );

                if (infoMessagesStore && infoMessagesStore.length) {
                  this.createOrderModalInfoMessage = infoMessagesStore[0];
                } else {
                  if (infoMessagesGroup && infoMessagesGroup.length) {
                    this.createOrderModalInfoMessage = infoMessagesGroup[0];
                  }
                }
              }
            })
            .unsubscribe();

          this.mo_base = _.cloneDeep(
            _.find(this.groupApp.languages, {
              mo_base: true,
            })
          );
          if (!this.mo_base) {
            this.mo_base = "en";
          }

          if (this.groupApp.design_settings) {
            if (this.groupApp.design_settings.animation_loader) {
              this.animationOptions = {
                ...this.animationOptions,
                path:
                  "https://data.deliverymanager.gr/animations/" +
                  this.groupApp.design_settings.animation_loader,
              };
            } else {
              this.animationOptions = {
                ...this.animationOptions,
                path: "https://data.deliverymanager.gr/animations/spinner-default-1.json",
              };
            }
          } else {
            this.animationOptions = {
              ...this.animationOptions,
              path: "https://data.deliverymanager.gr/animations/spinner-default-1.json",
            };
          }
        }
        if (
          state &&
          state.groupApp &&
          state.groupApp.selectedLangugage &&
          !_.isEqual(state.groupApp.selectedLangugage, this.selectedLanguage)
        ) {
          this.selectedLanguage = _.cloneDeep(state.groupApp.selectedLangugage);
        }
        if (
          state &&
          state.checkboxPromotions &&
          state.checkboxPromotions.checkboxPromotions &&
          !_.isEqual(
            state.checkboxPromotions.checkboxPromotions,
            this.checkBoxPromotions
          )
        ) {
          this.checkBoxPromotions = _.cloneDeep(
            state.checkboxPromotions.checkboxPromotions
          );
        }
        if (
          state &&
          state.cartPrices &&
          state.cartPrices.cartPrices &&
          !_.isEqual(state.cartPrices.cartPrices, this.cartPrices)
        ) {
          this.cartPrices = _.cloneDeep(state.cartPrices.cartPrices);

          if (this.cartPrices && this.cartPrices.currentOrderPoints) {
            this.currentOrderPoints = _.cloneDeep(
              this.cartPrices.currentOrderPoints
            );
          }
        }
        if (
          state &&
          state.cart &&
          state.cart.products &&
          !_.isEqual(state.cart.products, this.cart)
        ) {
          this.cart = _.cloneDeep(state.cart.products);
        }

        if (
          state &&
          state.reservations &&
          state.reservations.reservationSelectedDate &&
          !_.isEqual(
            state.reservations.reservationSelectedDate,
            this.reservationSelectedDate
          )
        ) {
          this.reservationSelectedDate = _.cloneDeep(
            state.reservations.reservationSelectedDate
          );
        }
        if (
          state &&
          state.orderComments &&
          state.orderComments.generalComments &&
          !_.isEqual(state.orderComments.generalComments, this.orderComments)
        ) {
          this.orderComments = _.cloneDeep(state.orderComments.generalComments);
        }
        if (
          state &&
          state.selectedInvoice &&
          state.selectedInvoice.selectedInvoice &&
          !_.isEqual(state.selectedInvoice, this.customerInvoice)
        ) {
          this.customerInvoice = _.cloneDeep(
            state.selectedInvoice.selectedInvoice
          );
        }
        if (
          state &&
          state.cartActiveCoupons &&
          state.cartActiveCoupons.activeCoupons &&
          !_.isEqual(state.cartActiveCoupons.activeCoupons, this.coupons)
        ) {
          this.coupons = _.cloneDeep(state.cartActiveCoupons.activeCoupons);
          if (this.coupons && this.coupons.length) {
            this.selectedCoupons = _.filter(this.coupons, {
              couponCheckbox: true,
            });
          }
        }
        if (
          state.selectedPaymentMethodID &&
          state.selectedPaymentMethodID.selectedPaymentMethodID &&
          !_.isEqual(
            state.selectedPaymentMethodID.selectedPaymentMethodID,
            this.selectedPaymentMethod
          )
        ) {
          this.selectedPaymentMethod = _.cloneDeep(
            state.selectedPaymentMethodID.selectedPaymentMethodID
          );
        }

        if (
          state.developerMode &&
          state.developerMode.developerMode &&
          !_.isEqual(state.developerMode.developerMode, this.developerMode)
        ) {
          this.developerMode = _.cloneDeep(state.developerMode.developerMode);
        }
      });

    setTimeout(() => {
      if (!this.animateSendOrderButton) {
        this.animateSendOrderButton = true;
      }
    }, 500);
  }

  toFloat(num) {
    console.log("toFloat", num);
    if (num) {
      const stringNumb = num.toString();
      console.log("stringNum", parseFloat(stringNumb).toFixed(2));
      return parseFloat(stringNumb).toFixed(2);
    } else {
      return 0;
    }
  }

  closeModal() {
    this.modalController.dismiss();
  }

  // sendOrderSuccess() {
  //   this.loading = true;
  //   setTimeout(() => {
  //     this.presentOrderCompletedAlert();
  //     this.router.navigateByUrl("/orders");
  //     this.closeModal();
  //   }, 500);
  // }

  // sendOrderFailed() {
  //   this.loading = true;
  //   setTimeout(() => {
  //     this.presentErrorOnCompleteOrderAlert();
  //   }, 500);
  // }

  // presentAlertsTest() {
  //   this.presentErrorOnCompleteOrderAlert("test test test");
  //   this.presentOrderCompletedAlert();
  // }

  sendOrder() {
    let platform = "browser";
    if (!Capacitor.isNativePlatform() && this.inIframe()) {
      platform = "facebook";
    } else if (Capacitor.isNativePlatform()) {
      platform = Capacitor.getPlatform();
    }
    var selectedCoupons = _.filter(this.coupons, {
      couponCheckbox: true,
    });
    console.log("coupppp", selectedCoupons, this.coupons);
    this.loading = true;
    this.createOrderLoadingAnimation = true;
    const langs = _.cloneDeep(this.groupApp.languages);
    let baseLanguage;
    const moBaseLanguage = _.find(langs, { mo_base: true });
    if (moBaseLanguage && moBaseLanguage.code) {
      baseLanguage = moBaseLanguage.code;
    }
    this.dataStorageService
      .createOrder(
        {
          store_id: this.currentStoreId,
          firstName: this.currentUser.firstName,
          lastName: this.currentUser.lastName,
          group: this.currentUser.group,
          selectedLanguage: this.selectedLanguage,
          baseLanguage: baseLanguage || "el",
          generalComments: this.orderComments, //Comments for ORDER
          pickup: this.orderPickup,
          dinein: this.dineIn,
          dinein_table_id:
            this.selectedDineInTable && this.selectedDineInTable.table_id
              ? this.selectedDineInTable.table_id
              : null,
          dinein_table_name:
            this.selectedDineInTable && this.selectedDineInTable.name
              ? this.selectedDineInTable.name
              : null,
          deliveryCost: this.cartPrices.deliveryCost,
          versionNumber: this.version,
          checkboxPromotions: this.checkBoxPromotions,
          cartCustomOptions: this.cartCustomOptions
            ? this.cartCustomOptions
            : [],
          coupons: selectedCoupons,
          cart: this.cart,
          customerInvoice:
            this.store_info.customerInvoice && this.customerInvoice
              ? this.customerInvoice
              : {},
          selectedPaymentMethodID: this.selectedPaymentMethod,
          paymentTimestamp: "",
          address_id:
            this.orderPickup || this.dineIn
              ? ""
              : this.selectedAddress.address_id,
          address_unverified:
            this.orderPickup || this.dineIn
              ? false
              : this.selectedAddress.unverified,
          floor:
            this.orderPickup || this.dineIn ? "" : this.selectedAddress.floor,
          company:
            this.orderPickup || this.dineIn ? "" : this.selectedAddress.company,
          company_manager:
            this.orderPickup || this.dineIn
              ? ""
              : this.selectedAddress.company_manager,
          comments:
            this.orderPickup || this.dineIn
              ? ""
              : this.selectedAddress.comments,
          userLat:
            this.orderPickup || this.dineIn ? "" : this.selectedAddress.userLat,
          userLng:
            this.orderPickup || this.dineIn ? "" : this.selectedAddress.userLng,
          formatted_address:
            this.orderPickup || this.dineIn
              ? ""
              : this.selectedAddress.formatted_address,
          postCode:
            this.orderPickup || this.dineIn
              ? ""
              : this.selectedAddress &&
                this.selectedAddress.address_components &&
                this.selectedAddress.address_components.postal_code
              ? this.selectedAddress.address_components.postal_code
              : null,
          doorbellname:
            this.orderPickup || this.dineIn
              ? ""
              : this.selectedAddress.doorbellname,
          altPhone:
            this.orderPickup || this.dineIn
              ? ""
              : this.selectedAddress.altPhone,
          platform: platform,
          timezone: this.store_info.timezone || "Europe/Athens",
          preorder: false,
          preorder_day: "",
          preorder_time: "",
          preorderAcceptDisableDeliveryTime: false,
          preorderAcceptDisablePickupTime: false,
          migration_customer_id:
            this.store_info && this.store_info.vatBasedCustomerProductsActive
              ? this.migration_customer_id
              : null,
          reservation_hour:
            this.reservationSelectedDate && this.reservationSelectedDate.hour
              ? this.reservationSelectedDate.hour
              : "",
          reservation_day:
            this.reservationSelectedDate &&
            this.reservationSelectedDate.date &&
            this.reservationSelectedDate.date.day
              ? this.reservationSelectedDate.date.day
              : "",
          reservation_table_id:
            this.selectedDineInTable &&
            this.selectedDineInTable.reservation_table_id
              ? this.selectedDineInTable.reservation_table_id
              : null,
          channel: this.developerMode ? "mastercapacitor" : "Capacitor",
        },
        this.mo_token
      )
      .subscribe({
        next: (res: any) => {
          if (res.success) {
            this.analyticsService.fbqTrackPurchase(
              this.cartPrices.grand_total,
              this.cart
            );

            this.analyticsService.logEventPurchase(
              res.orderItem.order_id,
              res.orderItem.grand_total
            );
            this.analyticsService.dmAnalyticsMessage(
              "complete_order_success",
              null,
              null,
              null,
              null,
              res.orderItem.order_id,
              null,
              null,
              null,
              null,
              null
            );

            this.createOrderRetried = false;
            this.loading = false;
            this.createOrderLoadingAnimation = false;

            this.cartPageAlertsService.clearPendingOrderAlertAlreadyShowedInCompleteOrder();
            this.store.dispatch(new CartActions.ClearCart());
            this.store.dispatch(
              new selectedInvoiceActions.ClearSelectedInvoice()
            );
            this.store.dispatch(
              new DeliveryMethodAddressActions.setSelectedPreorderDate(null)
            );
            this.store.dispatch(
              new OrderCommentsActions.SetOrderComments(null)
            );
            this.store.dispatch(
              new ReservationsActions.SetReservationSelectedDate(null)
            );
            if (navigator.cookieEnabled) {
              window.localStorage.removeItem("cart");
              window.localStorage.removeItem("cartCustomOptions");
              if (localStorage.getItem("orderComments")) {
                localStorage.removeItem("orderComments");
              }

              let deliveryMethodAddressObj: any = localStorage.getItem(
                "deliveryMethodAddress"
              );
              if (deliveryMethodAddressObj) {
                deliveryMethodAddressObj = JSON.parse(deliveryMethodAddressObj);
                deliveryMethodAddressObj.selectedPreorderDate = null;

                localStorage.setItem(
                  "deliveryMethodAddress",
                  JSON.stringify(deliveryMethodAddressObj)
                );
              }
            }
            this.store.dispatch(
              new CartCustomerOptionsActions.ClearCartCustomerOptions()
            );

            this.store.dispatch(
              new CartCustomerOptionsSelectionsActions.ClearCartCustomOptionsSelections()
            );
            if (
              this.store_info &&
              this.store_info.cartCustomOptions &&
              this.store_info.cartCustomOptions.length > 0
            ) {
              let customOptions = _.cloneDeep(
                this.store_info.cartCustomOptions
              );
              customOptions.forEach((option) => {
                this.store.dispatch(
                  new CartCustomerOptionsSelectionsActions.AddUpdateCartCustomOptionSelections(
                    option
                  )
                );
              });
            }
            //TO_DO CHECK IF YOU WANT TO PRINT COMMENT_ID
            this.presentOrderCompletedModal();
            this.closeModal();

            this.router.navigateByUrl(
              "/reservationSystem/reservation-my-dates",
              {
                replaceUrl: true,
              }
            );
          } else if (!res.success && res.retry && !this.createOrderRetried) {
            this.analyticsService.dmAnalyticsMessage(
              "complete_order_failed",
              res.comment_id,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null
            );
            this.createOrderRetried = true;
            setTimeout(() => {
              this.sendOrder();
            }, 20000);
          } else {
            this.loading = false;
            this.createOrderLoadingAnimation = false;

            this.createOrderRetried = false;
            this.store.dispatch(
              new DisableFinalPriceActions.SetTriggerFinalPriceTimestamp(
                Date.now().toString()
              )
            );
            if (res.comment_id === "prompt_for_preorder") {
              this.modalController.dismiss();
              this.presentPreorderInfoModal();
            } else {
              if (res.comment_id) {
                this.presentErrorAlert(res.comment_id, true);
                if (res.comment_id === "slot_not_available") {
                  this.store.dispatch(
                    new ReservationsActions.SetFetchReservationsTimestamp(
                      Date.now().toString()
                    )
                  );
                }
              } else {
                this.presentErrorAlert(res.comments, true);
              }
            }

            this.analyticsService.dmAnalyticsMessage(
              "complete_order_failed",
              res.comment_id,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null
            );
          }
        },
        error: (error) => {
          console.log("Error on sending order: ", error);

          if (!this.createOrderRetried) {
            this.analyticsService.dmAnalyticsMessage(
              "complete_order_failed",
              "create_order_failed_will_retry",
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null
            );
            this.createOrderRetried = true;

            setTimeout(() => {
              this.sendOrder();
            }, 5000);
          } else {
            this.analyticsService.dmAnalyticsMessage(
              "complete_order_failed",
              "create_order_failed_after_retry",
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null
            );
            this.createOrderRetried = false;
            this.loading = false;
            this.createOrderLoadingAnimation = false;
            this.presentErrorAlert(
              "errorMessages.sending-you-reservation-failed-please-try-again",
              false
            );
          }
        },
      });
  }

  async presentPreorderInfoModal() {
    const modal = await this.modalController.create({
      component: CreateOrderFailedPreorderModalComponent,
      cssClass: "create-order-failed-preorder-modal",
      backdropDismiss: false,
    });
    await modal.present();
  }

  async presentOrderCompletedModal() {
    const modal = await this.modalController.create({
      component: ExtendModalComponent,
      id: "extendModal",
      cssClass: "extendModal",
      backdropDismiss: false,
      componentProps: {
        submit: true,
      },
    });

    await modal.present();
  }
  async presentErrorOnCompleteOrderAlert(message) {
    const alert = await this.alertController.create({
      cssClass: "errorOnOrderCompletedAlert",
      header: this.translate.instant("reservation-failed"),
      message: this.translate.instant(message),

      buttons: [
        {
          text: "OK",
          role: "cancel",
          cssClass: "completedOrderAlertButton",
          handler: (blah) => {
            alert.dismiss();
          },
        },
      ],
    });

    await alert.present();
  }

  async presentErrorAlert(message, reservationAppTranslations) {
    var greekPattern = /[Α-Ωα-ω]/;
    if (message) {
      const hasGreekChar = greekPattern.test(message.toString());
      console.log("hasGreekChar", hasGreekChar);
      const alert = await this.alertController.create({
        cssClass: "errorOnOrderCompletedAlert",
        header: this.translate.instant("errorMessages.alert"),

        message:
          reservationAppTranslations && !hasGreekChar
            ? this.translate.instant("reservation-app." + message)
            : this.translate.instant(message),
        buttons: ["OK"],
      });

      await alert.present();
    }
  }

  animationCreated(ev) {
    this.createOrderLoadingAnimationInstance = ev;
    this.createOrderLoadingAnimationInstance.setSpeed(1);
  }

  inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  ngOnDestroy() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.storeInfoSubscription) {
      this.storeInfoSubscription.unsubscribe();
    }
    if (this.storesSub) {
      this.storesSub.unsubscribe();
    }
  }
}
