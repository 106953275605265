import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-previous-order-bundle-product-comment",
  templateUrl: "./previous-order-bundle-product-comment.component.html",
  styleUrls: ["./previous-order-bundle-product-comment.component.scss"],
})
export class PreviousOrderBundleProductCommentComponent implements OnInit {
  @Input() bundle_options: any;
  @Input() bundleProduct;
  @Input() orderPickup;
  @Input() dineIn;
  @Input() store_info;
  @Input() selectedDineInTable;

  constructor() {}

  ngOnInit() {}
}
