import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { AnimationOptions } from "ngx-lottie";
import * as _ from "lodash";
import { Store } from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";
import { Subscription, auditTime, distinctUntilChanged } from "rxjs";
import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";

@Component({
  selector: "app-block-store-modal",
  templateUrl: "./block-store-modal.component.html",
  styleUrls: ["./block-store-modal.component.scss"],
})
export class BlockStoreModalComponent implements OnInit, OnDestroy {
  @Input() onlyOrderAppSuspendedMessageTranslations;
  @Input() onlyOrderAppSuspendedButtons;
  public selectedLanguage;
  public base_language;
  public animationInstance: any;
  public groupApp;
  public mobile;
  options: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/app_suspended.json",
  };
  optionsInfoAnimation: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/general-info-animation.json",
  };
  private sub: Subscription;
  private sub2: Subscription;
  private windowDimensions;
  constructor(private store: Store<fromApp.AppState>) {}

  openLink = async (link) => {
    //open link external on new page
    if (Capacitor.isNativePlatform()) {
      window.open(link, "_system");
    } else {
      window.open(link, "_blank");
    }
  };
  ngOnInit() {
    this.sub = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.selectedLangugage &&
          !_.isEqual(this.selectedLanguage, state.selectedLangugage)
        ) {
          this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
        }
        if (
          state &&
          state.groupApp &&
          !_.isEqual(this.groupApp, state.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp);

          if (this.groupApp && this.groupApp.languages) {
            _.each(this.groupApp.languages, (lang) => {
              if (lang && lang.mo_base) {
                this.base_language = _.cloneDeep(lang.code);
              }
            });
            if (!this.base_language) {
              this.base_language = "en";
            }
          }
        }
      });

    this.sub2 = this.store
      .select("windowInfo")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.windowDimensions &&
          !_.isEqual(this.windowDimensions, state.windowDimensions)
        ) {
          this.windowDimensions = _.cloneDeep(state.windowDimensions);
          if (this.windowDimensions && this.windowDimensions.width < 500) {
            this.mobile = true;
          } else {
            this.mobile = false;
          }
        }
      });
  }

  animationCreated(ev) {
    this.animationInstance = ev;
    this.animationInstance.setSpeed(1.5);
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.sub2) {
      this.sub2.unsubscribe();
    }
  }
}
