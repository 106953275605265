import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../store/app.reducer";

import * as CatalogActions from "../../store/catalog.actions";
import * as _ from "lodash";
import { audit, auditTime, distinctUntilChanged } from "rxjs/operators";
import { Subscription } from "rxjs";

import { ModalController } from "@ionic/angular";
import { HyperCategoriesSelectionModalComponent } from "./hyper-categories-selection-modal/hyper-categories-selection-modal.component";

@Component({
  selector: "app-hyper-categories-selection",
  templateUrl: "./hyper-categories-selection.component.html",
  styleUrls: ["./hyper-categories-selection.component.scss"],
})
export class HyperCategoriesSelectionComponent implements OnInit, OnDestroy {
  public fontSize: any;

  public categories: any;
  public hyperCategories: any;

  public selectedHyperCategoryId: any;
  public selectedHyperCategory: any;
  public windowDimensions;
  private firstTime = true;
  private subscriptions: Subscription[] = [];
  private categoriesSubscription: Subscription;
  private hyperCategoriesSelectionModal: any;
  constructor(
    private store: Store<fromApp.AppState>,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select("hyperCategories")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.hyperCategories &&
            !_.isEqual(this.categories, state.hyperCategories)
          ) {
            this.hyperCategories = _.cloneDeep(state.hyperCategories);
            this.subscribeToCategories();
          }

          if (
            state &&
            state.currentHyperCategory &&
            !_.isEqual(this.selectedHyperCategoryId, state.currentHyperCategory)
          ) {
            this.selectedHyperCategoryId = _.cloneDeep(
              state.currentHyperCategory
            );
            this.selectedHyperCategory = _.find(this.hyperCategories, {
              hyper_category_id: state.currentHyperCategory,
            });
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.windowDimensions &&
            !_.isEqual(this.windowDimensions, state.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
            if (this.windowDimensions && this.windowDimensions.width < 500) {
              this.fontSize = "small";
            } else {
              this.fontSize = "medium";
            }
          }
        })
    );
  }

  subscribeToCategories() {
    if (!this.categoriesSubscription) {
      this.store
        .select("catalog")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(300))
        .subscribe((state) => {
          if (
            state &&
            state.catalog &&
            !_.isEqual(this.categories, state.catalog)
          ) {
            this.categories = _.cloneDeep(state.catalog);
            if (
              this.firstTime === true &&
              this.hyperCategories &&
              this.hyperCategories.length > 0 &&
              !this.hyperCategoriesSelectionModal &&
              !state.hyperCategoriesSelectionModalOpened
            ) {
              this.firstTime = false;
              console.log("open select hyper categories modal");
              this.openHypercategoriesSelectionModal();
            }
          }
        });
    }
  }

  async openHypercategoriesSelectionModal() {
    this.hyperCategoriesSelectionModal = await this.modalController.create({
      component: HyperCategoriesSelectionModalComponent,
      cssClass: "hypercategories-selection-modal",
      backdropDismiss: false,
    });
    await this.hyperCategoriesSelectionModal.present();
    this.hyperCategoriesSelectionModal.onDidDismiss().then(() => {
      this.hyperCategoriesSelectionModal = null;
    });
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    this.subscriptions = null;

    if (this.categoriesSubscription) {
      this.categoriesSubscription.unsubscribe();
    }
    if (this.hyperCategoriesSelectionModal) {
      this.hyperCategoriesSelectionModal = null;
    }
    this.categoriesSubscription = null;
  }
}
