import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AlertController, ModalController, Platform, ToastController } from '@ionic/angular';
import { AnimationOptions } from "ngx-lottie";
import { auditTime, distinctUntilChanged, first } from 'rxjs';
import * as _ from "lodash";
import { Store } from '@ngrx/store';
import * as fromApp from "../../../store/app.reducer";
import { DataStorageService } from 'src/app/services/data-storage/data-storage.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-create-coupon-modal',
  templateUrl: './create-coupon-modal.component.html',
  styleUrls: ['./create-coupon-modal.component.scss'],
})
export class CreateCouponModalComponent implements OnInit, OnDestroy {
  @Input() campaign;
  public onlineCouponAnimationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/online-sale.json",
  };
  public instoreCouponAnimationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/shopping-store.json",
  };
  public couponCreatedAnimationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/coupon-falling-confetti.json",
  };
  onlineCouponAnimation: any;
  instoreCouponAnimation: any;
  couponCreatedAnimation: any;
  windowInfoSub: any;
  windowDimensions: any;
  setOneRow: boolean;
  mo_token: any;
  isCreatingCoupon: boolean;
  buttonsDisabled: boolean;
  showOnlineSpinner: boolean;
  showInstoreSpinner: boolean;
  showCouponCreatedLottie: boolean;
  unsubscribeBackEvent: any;
  storesSub: any;
  selectedStoreId: any;
  allStores: any;
  currentStoreSub: any;
  currentStoreId: any;
  groupAppSub: any;
  groupApp: any;
  constructor(
    private modalController: ModalController,
    private store: Store<fromApp.AppState>,
    private dataStorageService: DataStorageService,
    private alertController: AlertController,
    private translateService: TranslateService,
    private toastController: ToastController,
    private platform: Platform

  ) { }

  ngOnInit() {

    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );
    console.log(this.campaign);
    this.storesSub = this.store
    .select("stores")
    .pipe(distinctUntilChanged())
    .pipe(auditTime(100))
    .subscribe((state) => {
      if (state && state.stores && !_.isEqual(this.allStores, state.stores)) {
        this.allStores = _.cloneDeep(state.stores);
        console.log('stores',this.allStores)
      }
    })

    this.groupAppSub = this.store
    .select("groupApp")
    .pipe(distinctUntilChanged())
    .pipe(auditTime(200))
    .subscribe((state) => {
      if(state && state.groupApp){
        this.groupApp = _.cloneDeep(state.groupApp);
      }
    })
    this.currentStoreSub = this.store
    .select("currentStore")
    .pipe(distinctUntilChanged())
    .pipe(auditTime(100))
    .subscribe((state) => {
      if (state && state.currentStoreId && !_.isEqual(this.currentStoreId, state.currentStoreId)) {
        this.currentStoreId = _.cloneDeep(state.currentStoreId);
        if(!this.selectedStoreId){
          this.selectedStoreId = this.currentStoreId;
        }
        console.log('stores',this.currentStoreId)
      }
    })

    this.windowInfoSub = this.store
    .select("windowInfo")
    .pipe(distinctUntilChanged())
    .subscribe((state) => {
      if (
        state &&
        state.windowDimensions &&
        !_.isEqual(this.windowDimensions, state.windowDimensions)
      ) {
        this.windowDimensions = _.cloneDeep(state.windowDimensions);

        if (this.windowDimensions && this.windowDimensions.width) {
          let width = this.windowDimensions.width;
          if (width > 1000) {
            this.setOneRow = true;
          } else if (width >= 600 && width <= 1000) {
            this.setOneRow = false;
          }
          else if (width < 600) {
            this.setOneRow = false;

          }
        }
      }
    })
  }

  async presentRedeemAlert(couponType){
    const store_id = this.selectedStoreId ? this.selectedStoreId : this.currentStoreId;
    const selectedStore = _.find(this.allStores, store =>{
      return store.store_id === store_id;
    })
    this.buttonsDisabled = true;
    const redeemAlert = await this.alertController.create({
      header: this.translateService.instant('alert'),
      message: this.translateService.instant('loyaltySystem.create-alert') + ': ' +
      this.translateService.instant(couponType) + "<br>" + "Στο κατάστημα: " + selectedStore.store_name,
      backdropDismiss: false,
      buttons: [
        {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              console.log('Confirm Cancel');
              this.buttonsDisabled = false;
              this.showOnlineSpinner = false;
              this.showInstoreSpinner = false;
            }
        },
        {
            text: 'OK',
            handler: () => {
              this.redeemCoupon(couponType);
            }
        }
    ]
    });
    await redeemAlert.present();
  }

  redeemCoupon(couponType){
    if(couponType === 'instore'){
      this.showInstoreSpinner = true;
    }
    if(couponType === 'online'){
      this.showOnlineSpinner = true;
    }
    const store_id = this.selectedStoreId ? this.selectedStoreId : this.currentStoreId;
    this.store.select('auth')
    .pipe(distinctUntilChanged())
    .pipe(auditTime(200))
    .pipe(first())
    .subscribe(state =>{
      if(state && state.user){
        this.mo_token = _.cloneDeep(state.mo_token);
        this.dataStorageService.createLoyaltyCoupon(this.campaign.loyalty_product_rule_id ,couponType, this.mo_token, store_id).subscribe({
          next: async (res: any) => {
            if (res && res.success) {
              this.isCreatingCoupon = false;
              this.buttonsDisabled = false;
              this.showOnlineSpinner = false;
              this.showInstoreSpinner = false;
              console.log('createCustomerCoupon', res);
              this.showCouponCreatedLottie = true;
              setTimeout(()=>{
                this.showCouponCreatedLottie = false;
                this.closeModal();
              },3000)
            } else {
              if (res.comment_id) {
                this.isCreatingCoupon = false;
                this.buttonsDisabled = false;
                this.showOnlineSpinner = false;
                this.showInstoreSpinner = false;
                const alert = await this.alertController.create({
                  header: res && res.comment_id
                  ? this.translateService.instant(res.comment_id)
                  : this.translateService.instant(
                      'errorMessages.general_error'
                    ),
                  message: 'classic error',
                  backdropDismiss: false,
                  buttons: ['OK'],
                });
                await alert.present();
              } else if (res.comments) {
                this.buttonsDisabled = false;
                this.showOnlineSpinner = false;
                this.showInstoreSpinner = false;
                this.presentErrorToast(res.comments, 'danger');
              }
            }
          },
          error: async (err) => {
            this.isCreatingCoupon = false;
            this.buttonsDisabled = false;
            this.showOnlineSpinner = false;
            this.showInstoreSpinner = false;
            const errMsg = this.translateService.instant(
              'errorMessages.error: There was an error on saving'
            );
            this.presentErrorToast(errMsg, 'danger');
            return err;
          }
        })
      }else {
        this.presentErrorToast('Δεν βρέθηκε χρήστης', 'danger');
        this.isCreatingCoupon = false;
      }
    });
  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }


  closeModal() {
    this.modalController.dismiss();
  }

  onlineCoupon(event){
    this.onlineCouponAnimation = event;
    this.onlineCouponAnimation.setSpeed(0.8);
  }
  instoreCoupon(event){
    this.instoreCouponAnimation = event;
    this.instoreCouponAnimation.setSpeed(1.2);
  }

  couponCreated(event){
    this.couponCreatedAnimation = event;
    this.couponCreatedAnimation.setSpeed(1);
  }

  ngOnDestroy(){
    if(this.windowInfoSub){
      this.windowInfoSub.unsubscribe();
    }
    if(this.unsubscribeBackEvent){
      this.unsubscribeBackEvent.unsubscribe();
    }
    if(this.storesSub){
      this.storesSub.unsubscribe();
    }
    if(this.currentStoreSub){
      this.currentStoreSub.unsubscribe();
    }
  }
}
