import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dayName'
})
export class DayNamePipe implements PipeTransform {
  transform(timestamp: number): string {
    if (timestamp && !isNaN(timestamp)) {
      const daysOfWeek = [
        'Κυρ', // Sunday
        'Δευ', // Monday
        'Τρί',   // Tuesday
        'Τετ', // Wednesday
        'Πέμ',  // Thursday
        'Παρ', // Friday
        'Σάβ'  // Saturday
      ];
      const today = new Date();
      const date = new Date(timestamp * 1000); // Convert timestamp to milliseconds
      const dayIndex = date.getDay(); // Get the day of the week (0-6);

      if (date.toDateString() === today.toDateString()) {
        return 'Σήμερα';
      } else if (date.toDateString() === new Date(today.getTime() + 24 * 60 * 60 * 1000).toDateString()) {
        return 'Αύριο';
      } else {
        return daysOfWeek[dayIndex];
      }
    }
    return '';
  }
}
