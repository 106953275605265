import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { auditTime, distinctUntilChanged } from "rxjs/operators";
import * as fromApp from "../../../../store/app.reducer";
import * as _ from "lodash";
import * as CheckboxPromotionsActions from "../store/checkbox-promotions.actions";
import { ToastController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";

@Component({
  selector: "app-checkbox-promotions-online-payment",
  templateUrl: "./checkbox-promotions-online-payment.component.html",
  styleUrls: ["./checkbox-promotions-online-payment.component.scss"],
})
export class CheckboxPromotionsOnlinePaymentComponent
  implements OnInit, OnDestroy
{
  public checkboxPromotions: any;
  public onlinePaymentOnlyPromos: any;
  public finalPriceLoading: boolean;
  public thereIsPromoOnlinePaymentOnly;
  private subTotalNoPromos: any;
  private subscriptions: Subscription[] = [];
  constructor(
    private store: Store<fromApp.AppState>,
    private toastController: ToastController,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.store.select("disableFinalPrice").subscribe((state) => {
        if (
          state &&
          !_.isEqual(state.disableFinalPrice, this.finalPriceLoading)
        ) {
          this.finalPriceLoading = _.cloneDeep(state.disableFinalPrice);
        }
      })
    );

    this.subscriptions.push(
      this.store
        .select("checkboxPromotions")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.checkboxPromotions &&
            !_.isEqual(this.checkboxPromotions, state.checkboxPromotions)
          ) {
            this.checkboxPromotions = _.cloneDeep(state.checkboxPromotions);

            this.thereIsPromoOnlinePaymentOnly = !!_.find(
              this.checkboxPromotions,
              { onlinePaymentOnly: true }
            );

            this.onlinePaymentOnlyPromos = _.filter(
              this.checkboxPromotions,
              (promos) => {
                return promos.onlinePaymentOnly;
              }
            );
          }

          if (
            state &&
            !_.isEqual(this.subTotalNoPromos, state.subTotalNoPromos)
          ) {
            if (state.subTotalNoPromos) {
              this.subTotalNoPromos = parseInt(
                _.cloneDeep(state.subTotalNoPromos)
              );
            }
            //console.log("subtotal no promos from state", this.subTotalNoPromos);
          }
        })
    );
  }

  clickCheckBoxPromotionDebounce = _.debounce((promo) => {
    this.clickCheckboxPromotion(promo);
  }, 20);

  clickCheckboxPromotion(promo) {
    console.log("promo", promo);
    console.log("checkboxPromotions", this.checkboxPromotions);
    var index = _.findIndex(this.checkboxPromotions, {
      id: promo.id,
    });
    console.log("index", index);
    if (index !== -1) {
      console.log("subTotalNoPromos", this.subTotalNoPromos);
      if (promo.couponSelected) {
        this.checkboxPromotions[index].promoCheckbox = false;
        this.presentErrorToast(
          this.translateService.instant(
            "cart.cart-components.checkbox-promotions.promotion-cannot-combined-with-the-selected-coupons"
          ),
          "danger"
        );
      } else if (this.subTotalNoPromos === 0) {
        this.checkboxPromotions[index].promoCheckbox = false;
        this.presentErrorToast(
          this.translateService.instant(
            "cart.cart-components.checkbox-promotions.promotion-is-not-applied-to-promo-products"
          ),
          "danger"
        );
      } else {
        if (
          this.checkboxPromotions[index].promoCheckbox &&
          this.checkboxPromotions[index].onlinePaymentOnly
        ) {
        }
        if (navigator.cookieEnabled) {
          window.localStorage.setItem(
            "checkboxPromotions",
            JSON.stringify(this.checkboxPromotions)
          );
        }
        console.log("calculateCartInline after clickCheckboxPromotion");
        this.store.dispatch(
          new CheckboxPromotionsActions.SetCheckboxPromotions(
            _.cloneDeep(this.checkboxPromotions)
          )
        );
      }
    }
  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            // console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }

  trackbyFnc(index, item) {
    return item.promo_id;
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }
}
