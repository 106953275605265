<ion-header>
  <app-no-internet-connection></app-no-internet-connection>
  <app-ionic-update-error></app-ionic-update-error>
  <ion-toolbar>
    <ion-title>{{"pages.kiosk" | translate}}</ion-title>
    <ion-buttons slot="start" *ngIf="groupApp && windowDimensions">
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-grid
  *ngIf="usePointsAnimation"
  style="width: 100%; height: 100%; position: absolute; top: 0; z-index: 1000"
>
  <ion-row
    style="width: 100%; height: 100%"
    class="ion-align-items-center ion-justify-content-center"
  >
    <ion-col class="ion-align-self-center ion-text-center">
      <ion-grid style="max-width: 350px">
        <ng-lottie
          (animationCreated)="animationCreated($event)"
          (loopComplete)="animationComplete($event)"
          [options]="usePointsSuccessAnimationOptions"
        ></ng-lottie>
      </ion-grid>
    </ion-col>
  </ion-row>
</ion-grid>

<ion-content>
  <ion-grid
    style="max-width: 720px; height: 100%"
    *ngIf="windowDimensions && windowDimensions.width"
  >
    <ion-row
      *ngIf="!loginState || loginState==='login_loading'"
      style="width: 100%"
    >
      <ion-col class="ion-text-center">
        <ion-spinner> </ion-spinner>
      </ion-col>
    </ion-row>
    <ion-list
      *ngIf="loginState && (loginState==='login_completed' || loginState==='no_login')"
    >
      <ion-grid
        style="max-width: 400px"
        *ngIf="currentUser && currentUser.kiosk_one_time_password"
      >
        <ion-card style="padding-top: 15px; padding-bottom: 15px" lines="none">
          <ion-button
            (click)="openKioskQrInfoModal()"
            fill="clear"
            style="position: absolute; right: 0; top: 0"
          >
            <ion-icon name="information-circle"></ion-icon>
          </ion-button>
          <div class="ion-text-wrap ion-text-center">
            <div style="font-size: large; font-weight: bold">
              <ion-text color="primary"
                >{{'kiosk-page.member-card' | translate}}</ion-text
              >
            </div>
            <div
              [class]="
            secondsRemaining > 0
              ? 'qrcode ion-text-center'
              : 'qrcode ion-text-center low-opacity'
          "
            >
              <qrcode
                [qrdata]="currentUser.kiosk_one_time_password"
                [width]="170"
                [errorCorrectionLevel]="'H'"
                [margin]="1"
              ></qrcode>
            </div>
            <div *ngIf="secondsRemaining > 0" style="margin-top: -5px">
              <span style="font-size: large">
                <strong
                  ><ion-text color="primary"
                    >{{'kiosk-page.qr-code' | translate}}:
                    {{currentUser.kiosk_one_time_password}}</ion-text
                  ></strong
                >
              </span>
            </div>

            <div
              *ngIf="currentUser &&
             currentUser.kiosk_expiration_timestamp &&
              secondsRemaining > 0
          "
              class="timerDiv"
              id="timer"
            >
              <ion-grid
                style="
                  border: 2px solid var(--ion-color-danger);
                  border-radius: 2px;
                  padding: 3px;
                  max-width: 120px;
                  min-height: 42px;
                "
              >
                <div style="font-size: small; font-weight: bold">
                  <ion-text color="danger">
                    {{ 'kiosk-page.password-expires-in'| translate }}
                  </ion-text>
                </div>
                <ion-text color="danger"> {{ formattedTime }} </ion-text>
              </ion-grid>
            </div>

            <ion-grid
              class="ion-align-items-center ion-justify-content-center"
              (click)="triggerRenewToken()"
              *ngIf="currentUser &&
            currentUser.kiosk_expiration_timestamp &&
             secondsRemaining <= 0
         "
              style="
                border: 2px solid var(--ion-color-danger);
                border-radius: 2px;
                padding: 3px;
                max-width: 120px;
                min-height: 42px;
                margin-top: 15px;
                cursor: pointer;
              "
            >
              <ion-row
                style="width: 100%; height: 100%"
                class="ion-justify-content-center ion-align-items-center"
              >
                <ion-col
                  style="height: 100%"
                  size="12"
                  class="ion-align-self-center"
                >
                  <ion-text
                    color="danger"
                    style="font-size: small; font-weight: bold"
                    >{{'kiosk-page.renew-password' | translate}}</ion-text
                  >
                </ion-col>
              </ion-row>
              <div></div>
            </ion-grid>
          </div>
        </ion-card>
      </ion-grid>

      <ion-grid style="max-width: 400px" *ngIf="!currentUser">
        <ion-card
          lines="none"
          style="padding-bottom: 10px; padding-bottom: 10px"
        >
          <ion-grid class="ion-no-padding">
            <ion-row style="width: 100%">
              <ion-col class="ion-text-center">
                <ion-grid style="width: 90px">
                  <img
                    alt="logo"
                    style="width: 100%; height: 100%"
                    alt="{{groupApp.group}}"
                    src="{{rootDir}}{{groupApp.group}}/logos/{{selectedLogoFileName}}"
                  />
                </ion-grid>
                <ion-item lines="none"
                  ><ion-label
                    style="margin: 0"
                    class="ion-text-center ion-text-wrap"
                  >
                    <div style="font-size: small; font-weight: bold">
                      {{'kiosk-page.login-and-start-collecting-points' |
                      translate}}
                    </div>

                    <p
                      style="font-size: small"
                      *ngIf="groupApp && groupApp.pointsSystem"
                    >
                      {{'kiosk-page.you-can-win-offers-and-coupons' |translate}}
                    </p>
                  </ion-label>
                </ion-item>

                <ion-button
                  [disabled]="!loginLoading"
                  color="primary"
                  size="medium"
                  (click)="presentMagicLoginPopup()"
                >
                  <ion-text *ngIf="loginLoading">
                    {{ "homepage.magic-login" | translate }}
                  </ion-text>
                  <ion-spinner *ngIf="!loginLoading"></ion-spinner>
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card>
      </ion-grid>

      <ion-grid
        *ngIf="currentUser && userPoints && userPoints.pointsSystem && groupApp && groupApp.pointsSystem"
        style="margin-bottom: 8px"
        class="ion-text-center"
      >
        <ion-badge [color]="pointsProgressBarColor">
          {{ "cart.cart-components.cart-points.your-points" | translate }}: {{
          userPoints.pointsSystem.active ? userPoints.pointsSystem.active : "0"
          }}
        </ion-badge>
      </ion-grid>

      <ion-segment
        (ionChange)="changePromoType($event)"
        scrollable="true"
        mode="ios"
        [(ngModel)]="segment"
      >
        <ion-segment-button value="couponRules">
          <ion-label>{{'kiosk-page.coupon-rules' | translate}}</ion-label>
        </ion-segment-button>
        <ion-segment-button
          value="pointRewards"
          *ngIf="groupApp && groupApp.pointsSystem"
        >
          <ion-label>{{'kiosk-page.point-rewards' | translate}}</ion-label>
        </ion-segment-button>
        <ion-segment-button *ngIf="currentUser" value="kiosk-coupons">
          <ion-label>{{'kiosk-page.kiosk-coupons' |translate}}</ion-label>
        </ion-segment-button>
      </ion-segment>

      <ion-grid
        style="padding: 0"
        [ngStyle]="{
        'height': '100%',
        'max-width': couponRulesGridWidth
      }"
        *ngIf="segment ==='couponRules'"
      >
        <ion-row style="width: 100%; height: 100%">
          <ion-col
            style="padding: 0"
            class="ion-text-center"
            [size]="colSize"
            *ngFor="let rule of customerCouponRules"
          >
            <ion-grid style="width: 160px; height: 200px; padding: 0">
              <ion-card
                (click)="openGetPromoModal(rule,'couponRule')"
                button="true"
                style="height: 188px"
                mode="md"
                color="light"
              >
                <div *ngIf="!rule.image" style="margin-top: 100px"></div>
                <ion-badge
                  *ngIf="rule.tag_comment"
                  style="position: absolute; top: 5px; right: 5px"
                >
                  {{ rule.tag_comment }}
                </ion-badge>
                <img
                  *ngIf="rule.image"
                  class="rewardImage"
                  alt="rewardImage"
                  loading="lazy"
                  [src]='"https://d3vm1ym7xlqx7l.cloudfront.net/"+ groupApp.group + "/coupon-rule-images/" + rule.image'
                />
                <ion-item color="light" class="noPaddingsItem" lines="none">
                  <ion-label class="ion-text-wrap" style="margin: 0">
                    <div
                      style="
                        font-size: small;
                        font-weight: bold;
                        line-height: 1.1;
                      "
                    >
                      <ion-text> {{rule.description}} </ion-text>
                    </div>
                  </ion-label>
                </ion-item>
              </ion-card>
            </ion-grid>
          </ion-col>
        </ion-row>

        <ion-grid
          *ngIf="customerCouponRules && !customerCouponRules.length"
          style="max-width: 100px"
        >
          <ng-lottie [options]="sadAnimationOptions"></ng-lottie>
        </ion-grid>
        <ion-grid *ngIf=" !customerCouponRules ">
          <ion-row style="width: 100%">
            <ion-col class="ion-text-center">
              <ion-spinner></ion-spinner>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-grid>
      <ion-item
        *ngIf="customerCouponRules && !customerCouponRules.length"
        lines="none"
      >
        <ion-label class="ion-text-wrap ion-text-center">
          {{'kiosk-page.there-are-no-kiosk-coupons-rules-in-your-account' |
          translate}}
        </ion-label>
      </ion-item>

      <ion-grid style="height: 100%" *ngIf="segment ==='pointRewards'">
        <ion-grid style="max-width: 670px; padding: 0">
          <ion-row style="width: 100%">
            <ion-col
              style="padding: 0"
              *ngFor="let reward of customerRewards"
              [size]="windowDimensions && windowDimensions.width && windowDimensions.width>600 && customerRewards && customerRewards.length >1 ? '6' : '12'"
            >
              <ion-grid
                [ngStyle]="{
                'max-width': windowDimensions && windowDimensions.width && windowDimensions.width<=600 ? '450px' : '330px',
                'padding': '0'
              
              }"
              >
                <ion-card
                  class="ion-text-center"
                  [ngStyle]="{
                    height:
                      windowDimensions &&
                      windowDimensions.width &&
                      windowDimensions.width >= 600
                        ? canGetAtLeastOneReward
                          ? reward.image
                            ? '320px'
                            : '150px'
                          : reward.image
                          ? '270px'
                          : '100px'
                        : '',
                    padding: '0'
                  }"
                >
                  <ion-button
                    (click)="openGetPromoModal(reward,'reward')"
                    fill="clear"
                    size="small"
                    style="position: absolute; left: 0; top: 0; z-index: 10000"
                  >
                    <ion-icon
                      style="font-size: 20px"
                      slot="icon-only"
                      name="information-circle"
                    ></ion-icon>
                  </ion-button>
                  <ion-badge
                    *ngIf="reward.image"
                    color="primary"
                    style="position: absolute; top: 8px; right: 8px"
                  >
                    {{ reward.points }} {{ "points.rewards.points" | translate}}
                  </ion-badge>
                  <img
                    [class]="
                      windowDimensions &&
                      windowDimensions.width &&
                      windowDimensions.width >= 600
                      ? 'rewardImageMaxHeight'
                      : ''
                    "
                    alt="rewardImage"
                    loading="lazy"
                    *ngIf="reward.image"
                    [src]="
        'https://d3vm1ym7xlqx7l.cloudfront.net/' +
        groupApp.group +
        '/rewards/' +
        reward.image
      "
                  />
                  <ion-item
                    lines="none"
                    [class]="
                    !currentUser &&
                    !reward.image &&
                    windowDimensions &&
                    windowDimensions.width &&
                    windowDimensions.width >= 600
                      ? 'ion-text-wrap ion-text-center fullHeightItem'
                      : 'ion-text-wrap ion-text-center'
                  "
                  >
                    <ion-label
                      style="line-height: 1.1"
                      class="ion-text-wrap ion-text-center wrapInTwoLines"
                    >
                      <ion-text style="font-size: small; font-weight: bold">
                        {{ reward.title }} {{!reward.image ? ' - '
                        +reward.points : '' }} {{!reward.image ? (
                        ("points.rewards.points" | translate)): ''}}
                      </ion-text>
                    </ion-label>
                  </ion-item>

                  <ion-item
                    lines="none"
                    *ngIf="
                      currentUser &&
                       loginState &&
                       loginState === 'login_completed' &&
                        groupApp && groupApp.pointsSystem &&
                        userPoints &&
                        userPoints.pointsSystem.pending > 0
                      "
                  >
                    {{ userPoints.pointsSystem.pending }} {{
                    "points.rewards.points-pending" | translate }}
                  </ion-item>
                  <ion-item *ngIf="reward.user_points_percent" lines="none">
                    <div
                      style="width: 100%"
                      [class]="reward.user_points_percent<50 ? 'progressbarBlack' : (reward.user_points_percent ===100 ? 'progressbarSuccess': 'progressbarWarning' )"
                    >
                      <div
                        [ngStyle]="{ width: reward.user_points_percent + '%' }"
                      >
                        <ion-text
                          [color]="
                           reward.user_points_percent >= 50 &&
                            reward.user_points_percent < 100
                            ? 'black'
                            : reward.user_points_percent < 50
                            ? 'white'
                            : 'white'
                          "
                          >{{ reward.user_points_percent }}%</ion-text
                        >
                      </div>
                    </div>
                  </ion-item>
                  <ion-button
                    *ngIf="canGetAtLeastOneReward"
                    size="small"
                    style="margin-bottom: 18px"
                    (click)="openGetPromoModal(reward,'reward')"
                    [disabled]="usePointsLoading || reward.user_points_percent !== 100"
                    color="primary"
                  >
                    <ion-label class="ion-text-center">
                      <ion-text *ngIf="!usePointsLoading">
                        {{ "homepage.point-system.get-the-coupon" | translate
                        }}</ion-text
                      >
                      <ion-spinner
                        *ngIf="usePointsLoading"
                        style="color: var(--ion-color-text)"
                      >
                      </ion-spinner>
                    </ion-label>
                  </ion-button>
                </ion-card>
              </ion-grid>
            </ion-col>
          </ion-row>
        </ion-grid>

        <ion-grid
          *ngIf="customerRewards && !customerRewards.length"
          style="max-width: 100px"
        >
          <ng-lottie [options]="sadAnimationOptions"></ng-lottie>
        </ion-grid>

        <ion-item
          lines="none"
          *ngIf="customerRewards && !customerRewards.length"
        >
          <ion-label class="ion-text-wrap ion-text-center">
            {{'kiosk-page.there-are-no-kiosk-rewards-rules-in-your-account' |
            translate}}
          </ion-label>
        </ion-item>
        <ion-grid *ngIf=" !customerRewards ">
          <ion-row style="width: 100%">
            <ion-col class="ion-text-center">
              <ion-spinner></ion-spinner>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-grid>

      <ion-grid
        style="padding: 0; max-width: 600px"
        *ngIf="segment==='kiosk-coupons'"
      >
        <ion-grid
          *ngIf="kioskCoupons && !kioskCoupons.length"
          style="max-width: 100px"
        >
          <ng-lottie [options]="sadAnimationOptions"></ng-lottie>
        </ion-grid>
        <ion-item *ngIf="kioskCoupons && kioskCoupons.length" lines="full">
          <ion-label class="ion-text-wrap ion-text-center">
            {{'kiosk-page.please-choose-the-coupon-you-want-to-scan' |
            translate}}
          </ion-label>
        </ion-item>
        <ion-item lines="none" *ngIf="kioskCoupons && !kioskCoupons.length">
          <ion-label class="ion-text-wrap ion-text-center">
            {{'kiosk-page.there-are-no-kiosk-coupons-in-your-account' |
            translate}}
          </ion-label>
        </ion-item>

        <app-kiosk-coupons-item
          [coupon]="coupon"
          [timestamp_to_open]="timestamp_to_open"
          (clearTimestampToOpenEvent)="clearTimestampToOpen($event)"
          *ngFor="let coupon of kioskCoupons;trackBy:couponTrackFn"
        ></app-kiosk-coupons-item>
      </ion-grid>
      <ion-grid *ngIf="segment==='kiosk-coupons' &&!kioskCoupons">
        <ion-row style="width: 100%">
          <ion-col class="ion-text-center">
            <ion-spinner></ion-spinner>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-list>
  </ion-grid>
  <div
    *ngIf="groupApp &&
      groupApp.design_settings &&
      groupApp.design_settings.menuFooterTabs && windowDimensions && windowDimensions.width<900"
    style="margin-top: 65px"
  ></div>
</ion-content>

<app-menu-footer-tabs></app-menu-footer-tabs>
