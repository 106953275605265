import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import * as _ from "lodash";

@Component({
  selector: "app-reservation-product-list-products",
  templateUrl: "./reservation-product-list-products.component.html",
  styleUrls: ["./reservation-product-list-products.component.scss"],
})
export class ReservationProductListProductsComponent implements OnInit {
  @Input() category_id: any;
  @Input() category: any;
  @Input() storeInfo;
  @Input() cartProductIds;
  @Input() groupStoreData;
  @Input() selectedDineInTable;
  @Input() selectedPreorderDate;
  @Input() dineIn;
  @Input() orderPickup;
  @Input() webp;
  @Input() imageBrowser;
  @Input() scrollFromBottom;
  @Input() finalPriceLoading;

  @Output() showNextCategoryButton = new EventEmitter();

  filteredScrollProducts = [];

  productsTrackFn(index, item) {
    return item.product_id;
  }

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.scrollFromBottom &&
      changes.scrollFromBottom.previousValue !==
        changes.scrollFromBottom.currentValue &&
      this.storeInfo &&
      this.storeInfo.design_settings &&
      this.storeInfo.design_settings.showOnlySelectedCategoryProducts &&
      this.filteredScrollProducts &&
      this.filteredScrollProducts.length
    ) {
      if (this.scrollFromBottom < 150) {
        this.loadData();
      }
    }

    if (
      changes &&
      changes.category_id &&
      changes.category_id.previousValue !== changes.category_id.currentValue &&
      this.storeInfo &&
      this.storeInfo.design_settings &&
      this.storeInfo.design_settings.showOnlySelectedCategoryProducts
    ) {
      this.showNextCategoryButton.emit(false);
    }
  }

  ngOnInit() {
    if (
      this.storeInfo &&
      this.storeInfo.design_settings &&
      this.storeInfo.design_settings.showOnlySelectedCategoryProducts
    ) {
      this.initFilteredScrollProducts();
    }
  }

  calculateFilteredScrollProducts() {
    _.each(this.filteredScrollProducts, (prd, index) => {
      const newPrd = _.find(_.cloneDeep(this.category.products), {
        product_id: prd.product_id,
      });
      if (!_.isEqual(newPrd, prd)) {
        this.filteredScrollProducts[index] = _.cloneDeep(newPrd);
      }
    });
    //DO NOT REMOVE THIS, PIPE filterAvailableProducts NEEDS NEW INSTANCE TO BE FIRED AGAIN!
    this.filteredScrollProducts = _.cloneDeep(this.filteredScrollProducts);
  }

  initFilteredScrollProducts() {
    if (
      this.category &&
      this.category.products &&
      this.category.products.length > 0
    ) {
      this.filteredScrollProducts = _.cloneDeep(
        _.slice(this.category.products, 0, 30)
      );

      if (
        this.filteredScrollProducts.length === this.category.products.length
      ) {
        this.showNextCategoryButton.emit(true);
      }
    }

    console.log("filtered scroll procuts", this.filteredScrollProducts);
  }

  loadData() {
    this.addMoreItemsDebounce();
  }

  private addMoreItemsDebounce = _.debounce(() => {
    if (this.category.products.length > this.filteredScrollProducts.length) {
      const tempList = _.cloneDeep(
        _.slice(
          _.cloneDeep(this.category.products),
          this.filteredScrollProducts.length - 1,
          this.category.products.length >=
            this.filteredScrollProducts.length - 1 + 15
            ? this.filteredScrollProducts.length - 1 + 15
            : this.category.products.length
        )
      );

      _.each(tempList, (prd: any, key) => {
        if (
          !_.find(this.filteredScrollProducts, {
            product_id: prd.product_id,
          })
        ) {
          this.filteredScrollProducts.push(prd);
        }
      });
      //DO NOT REMOVE THIS, PIPE filterAvailableProducts NEEDS NEW INSTANCE TO BE FIRED AGAIN!
      this.filteredScrollProducts = _.cloneDeep(this.filteredScrollProducts);
      //
      if (
        this.filteredScrollProducts.length === this.category.products.length
      ) {
        this.showNextCategoryButton.emit(true);
      }
    }
  }, 300);
}
