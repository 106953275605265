import * as CartCustomOptionsActions from "./cart-customer-options.actions";
import * as _ from "lodash";

export interface State {
  cartCustomerOptions: any[];
  notValidCustomOptions: any;
}

const initialState: State = {
  cartCustomerOptions: null,
  notValidCustomOptions: [],
};

export function cartCustomerOptionsReducer(
  state = initialState,
  action: CartCustomOptionsActions.CartCustomerOptionsActions
) {
  switch (action.type) {
    case CartCustomOptionsActions.SET_CART_CUSTOMER_OPTIONS:
      return {
        ...state,
        cartCustomerOptions: action.payload,
      };
    case CartCustomOptionsActions.SET_NOT_VALID_CUSTOM_OPTIONS:
      return {
        ...state,
        notValidCustomOptions: action.payload,
      };

    case CartCustomOptionsActions.CLEAR_CART_CUSTOMER_OPTIONS:
      return {
        ...state,
        cartCustomerOptions: null,
      };

    default:
      return state;
  }
}
