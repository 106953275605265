import { Pipe, PipeTransform } from "@angular/core";
import * as _ from "lodash";
@Pipe({
  name: "cartProductOptionRequired",
})
export class CartProductOptionRequiredPipe implements PipeTransform {
  transform(product: any): boolean {
    let value: boolean = false;
    if (product && product.options) {
      _.each(product.options, (option) => {
        if (option.is_require && option.choices) {
          let arrayWithPreselectedChoices = _.filter(
            option.choices,
            (choice) => {
              if (choice.is_preselected) {
                return choice;
              }
            }
          );
          if (
            !arrayWithPreselectedChoices ||
            (arrayWithPreselectedChoices &&
              arrayWithPreselectedChoices.length === 0)
          ) {
            value = true;
            return false;
          } else {
            value = false;
            return false;
          }
        }
      });
      return value;
    } else {
      return false;
    }
  }
}
