import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import SwiperCore, {
  Autoplay,
  EffectFade,
  Keyboard,
  Navigation,
  Pagination,
  SwiperOptions,
  Zoom,
} from "swiper";
import { SwiperComponent } from "swiper/angular";

SwiperCore.use([Autoplay, Navigation, Keyboard, Pagination, Zoom, EffectFade]);

@Component({
  selector: "app-loyalty-swiper",
  templateUrl: "./loyalty-swiper.component.html",
  styleUrls: ["./loyalty-swiper.component.scss"],
})
export class LoyaltySwiperComponent implements OnInit, AfterViewInit {
  @ViewChild("swiperSlideShow") swiperSlideShow!: SwiperComponent;
  config: SwiperOptions = {};
  @Input() images: any;
  constructor(private detectChanges: ChangeDetectorRef) {}

  ngOnInit() {
    console.log("modal swiper");
  }

  ngAfterViewInit() {
    if (!this.swiperSlideShow.swiperRef.autoplay.running) {
      setTimeout(() => {
        this.swiperSlideShow.swiperRef.autoplay.start();
        // this.detectChanges.detectChanges();
      }, 1000);
    }
  }

  openInfoModal() {
    console.log("open info modal swiper");
  }
}
