import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import * as CartCustomOptionsSelectionsActions from "../../store/cart-custom-options-selections/cart-custom-options-selections.actions";
import * as fromApp from "../../../../../store/app.reducer";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import { auditTime, distinctUntilChanged } from "rxjs/operators";
import { Subscription } from "rxjs";
import moment from "moment";

@Component({
  selector: "app-checkbox-type-choices",
  templateUrl: "./checkbox-type-choices.component.html",
  styleUrls: ["./checkbox-type-choices.component.scss"],
})
export class CheckboxTypeChoicesComponent implements OnInit, OnDestroy {
  @Input() option_timestamp;
  @Input() selectedLang: any;
  public option;
  public CustomSelections;
  public finalPriceLoading: boolean;

  private doNotCheckAllChoicesPeriod = false;
  private subscriptions: Subscription[] = [];

  constructor(private store: Store<fromApp.AppState>) {}

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select("cartCustomOptionsSelections")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.cartCustomOptionsSelections &&
            !_.isEqual(state.cartCustomOptionsSelections, this.CustomSelections)
          ) {
            console.log("mpika sto subscription selections");
            this.CustomSelections = _.cloneDeep(
              state.cartCustomOptionsSelections
            );

            this.option = _.cloneDeep(
              _.find(this.CustomSelections, {
                timestamp: this.option_timestamp,
              })
            );

            if (this.doNotCheckAllChoicesPeriod) {
              this.doNotCheckAllChoicesPeriod = false;
              this.checkAllChoicesForShowPeriodDebounced();
            } else {
              this.checkAllChoicesForShowPeriodDebounced();
            }
            console.log("this option from checkbox", this.option);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("disableFinalPrice")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(state.disableFinalPrice, this.finalPriceLoading)
          ) {
            this.finalPriceLoading = _.cloneDeep(state.disableFinalPrice);
          }
        })
    );
  }

  private checkAllChoicesForShowPeriodDebounced = _.debounce(() => {
    this.checkAllChoicesForShowPeriod();
  }, 200);

  checkForSelectedChoiceIgnoringPeriod(choice) {
    let ignoreShowPeriod = false;

    if (
      choice &&
      choice.ignoreShowPeriodWhenOptionObject &&
      choice.ignoreShowPeriodWhenOptionObject.option_timestamp &&
      this.CustomSelections
    ) {
      const option = _.find(this.CustomSelections, {
        timestamp: choice.ignoreShowPeriodWhenOptionObject.option_timestamp,
      });

      if (option && option.choices) {
        _.each(
          choice.ignoreShowPeriodWhenOptionObject.choices,
          (ingore_choice) => {
            const found = _.find(option.choices, {
              timestamp: ingore_choice.timestamp,
            });

            if (found && found.selected) {
              ignoreShowPeriod = true;
            }
          }
        );
      }
    }

    return ignoreShowPeriod;
  }

  checkAllChoicesForShowPeriod() {
    if (this.option && this.option.choices && this.option.choices.length > 0) {
      this.option.choices.forEach((choice) => {
        if (
          choice.enableShowPeriod &&
          choice.start_timestamp &&
          choice.stop_timestamp &&
          !this.checkForSelectedChoiceIgnoringPeriod(choice)
        ) {
          choice.outOfPeriod = this.checkShowPeriod(_.cloneDeep(choice));

          if (choice.outOfPeriod) {
            choice.selected = false;
          }
        } else if (
          choice.enableShowPeriod &&
          choice.start_timestamp &&
          choice.stop_timestamp &&
          this.checkForSelectedChoiceIgnoringPeriod(choice)
        ) {
          delete choice.outOfPeriod;
        }
      });
    }
  }
  onCheckboxChange(ev) {
    this.checkAllChoicesForShowPeriod();

    this.store.dispatch(
      new CartCustomOptionsSelectionsActions.AddUpdateCartCustomOptionSelections(
        _.cloneDeep(this.option)
      )
    );
  }

  checkShowPeriod(ch) {
    const today = moment();

    const startDate = moment(ch.start_timestamp).set({
      year: today.year(),
      month: today.month(),
      date: today.date(),
    });

    const stopDate = moment(ch.stop_timestamp).set({
      year: today.year(),
      month: today.month(),
      date: today.date(),
    });

    if (today.isBefore(startDate) || today.isAfter(stopDate)) {
      //out of period
      return true;
    }

    return false;
  }

  trackTimestamp(index, choice) {
    return choice.timestamp;
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }
}
