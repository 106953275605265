import { Action } from "@ngrx/store";
import { PiraeusThreeDsPopupComponent } from "src/app/popups/payment/piraeus-three-ds-popup/piraeus-three-ds-popup.component";

export const SET_ORDERS_ARRAY = "[ORDERS] Set Orders Array";

export const ADD_UPDATE_ORDERS_FROM_SOCKET =
  "[ORDERS] Add Update Orders From Socket";

export const SET_CURRENT_PENDING_PAYMENT_PIRAEUS_ORDER =
  "[ORDERS] Set Current Pending Payment Piraeus Order";

export const SET_FETCH_ORDERS_AGAIN_TIMESTAMP =
  "[ORDERS] Set Fetch Orders Again Timestamp";
export const SET_KLARNAPOPUP_OPEN = "[ORDERS] Set Klarna Popup Open";
export const SET_BRAINTREEPOPUP_OPEN = "[ORDERS] Set Braintree Popup Open";
export const SET_PAYPALPOPUP_OPEN = "[ORDERS] Set Paypal Popup Open";
export const SET_ETHNIKIPOPUP_OPEN = "[ORDERS] Set Ethniki Popup Open";
export const SET_NBG_SIMPLIFY_POPOUP_OPEN =
  "[ORDERS] Set NBG SIMPLIFY Popup Open";
export const SET_VIVAPOPUP_OPEN = "[ORDERS] Set Viva Popup Open";
export const SET_MYPOSPOPUP_OPEN = "[ORDERS] Set MyPos Popup Open";
export const SET_EUROBANKPOPUP_OPEN = "[ORDERS] Set Eurobank Popup Open";
export const SET_PIRAEUSPOPUP_OPEN = "[ORDERS] Set Piraeus Popup Open";
export const SET_ALPHAPOPUP_OPEN = "[ORDERS] Set Alpha Popup Open";
export const CLEAR_ORDERS_ARRAY = "[ORDERS] Clear Orders Array";

export class SetOrdersArray implements Action {
  readonly type = SET_ORDERS_ARRAY;
  constructor(public payload: any) {}
}
export class AddUpdateOrderFromSocket implements Action {
  readonly type = ADD_UPDATE_ORDERS_FROM_SOCKET;
  constructor(public payload: any) {}
}

export class SetFetchOrdersAgainTimestamp implements Action {
  readonly type = SET_FETCH_ORDERS_AGAIN_TIMESTAMP;
  constructor(public payload: any) {}
}

export class SetCurrentPendingPaymentPiraeusOrder implements Action {
  readonly type = SET_CURRENT_PENDING_PAYMENT_PIRAEUS_ORDER;
  constructor(public payload: any) {}
}
export class SetKlarnaPopupOpen implements Action {
  readonly type = SET_KLARNAPOPUP_OPEN;
  constructor(public payload: any) {}
}
export class SetEthnikiPopupOpen implements Action {
  readonly type = SET_ETHNIKIPOPUP_OPEN;
  constructor(public payload: any) {}
}

export class SetNbgSimplifyPopupOpen implements Action {
  readonly type = SET_NBG_SIMPLIFY_POPOUP_OPEN;
  constructor(public payload: any) {}
}
export class SetBrainTreePopupOpen implements Action {
  readonly type = SET_BRAINTREEPOPUP_OPEN;
  constructor(public payload: any) {}
}
export class SetPaypalPopupOpen implements Action {
  readonly type = SET_PAYPALPOPUP_OPEN;
  constructor(public payload: any) {}
}

export class SetVivaPopupOpen implements Action {
  readonly type = SET_VIVAPOPUP_OPEN;
  constructor(public payload: any) {}
}

export class SetMyposPopupOpen implements Action {
  readonly type = SET_MYPOSPOPUP_OPEN;
  constructor(public payload: any) {}
}

export class SetEurobankPopupOpen implements Action {
  readonly type = SET_EUROBANKPOPUP_OPEN;
  constructor(public payload: any) {}
}
export class SetPiraeusPopupOpen implements Action {
  readonly type = SET_PIRAEUSPOPUP_OPEN;
  constructor(public payload: any) {}
}
export class SetAlphaPopupOpen implements Action {
  readonly type = SET_ALPHAPOPUP_OPEN;
  constructor(public payload: any) {}
}

export class ClearOrdersArray implements Action {
  readonly type = CLEAR_ORDERS_ARRAY;
  constructor() {}
}

export type OrdersActions =
  | SetOrdersArray
  | AddUpdateOrderFromSocket
  | SetCurrentPendingPaymentPiraeusOrder
  | SetFetchOrdersAgainTimestamp
  | ClearOrdersArray
  | SetKlarnaPopupOpen
  | SetPaypalPopupOpen
  | SetBrainTreePopupOpen
  | SetMyposPopupOpen
  | SetEthnikiPopupOpen
  | SetNbgSimplifyPopupOpen
  | SetEurobankPopupOpen
  | SetPiraeusPopupOpen
  | SetVivaPopupOpen
  | SetAlphaPopupOpen;
