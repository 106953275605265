import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-alergens",
  templateUrl: "./alergens.page.html",
  styleUrls: ["./alergens.page.scss"],
})
export class AlergensPage implements OnInit {
  public alergens: any;

  constructor() {}

  ngOnInit() {}
}
