<div *ngIf="groupApp && groupApp.languages && groupApp.languages.length > 1">
  <ion-button
    style="margin: 0; height: 2.4em"
    size="small"
    fill="clear"
    [slot]="slot"
    (click)="changeLanguage(lang.code)"
    *ngFor="let lang of groupApp.languages | languageStatus"
  >
    <img
      [alt]="lang.code"
      width="30"
      height="30"
      src="https://data.deliverymanager.gr/flag-{{ lang.code }}.png"
    />
  </ion-button>
</div>
