<ion-header>
  <app-no-internet-connection></app-no-internet-connection>
  <app-ionic-update-error></app-ionic-update-error>
  <ion-toolbar>
    <ion-title>{{"pages.delivery-areas" | translate}}</ion-title>
    <ion-button
      [hidden]="mobilePlatform"
      color="dark"
      fill="clear"
      (click)="setInfoMarkerOnCenter()"
      slot="end"
    >
      <ion-icon slot="icon-only" src="assets/flatIcons/info.svg"></ion-icon>
    </ion-button>
    <ion-buttons *ngIf="groupApp && windowDimensions" slot="start">
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content scrollX="false" scrollY="false">
  <ion-backdrop *ngIf="geoLocationAnimation"> </ion-backdrop>
  <ion-grid
    *ngIf="geoLocationAnimation"
    style="width: 100%; height: 100%; position: absolute; top: 0; z-index: 1000"
  >
    <ion-row
      style="width: 100%; height: 100%"
      class="ion-align-items-center ion-justify-content-center"
    >
      <ion-col class="ion-align-self-center ion-text-center">
        <ion-grid style="max-width: 300px">
          <ng-lottie
            (animationCreated)="animationCreated($event)"
            [options]="options"
          ></ng-lottie>
        </ion-grid>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-progress-bar
    style="position: absolute; top: 0px; z-index: 20"
    *ngIf="checkMarkerLoading"
    type="indeterminate"
  >
  </ion-progress-bar>
  <ion-grid
    align="center"
    style="position: absolute; top: 0px; z-index: 20; width: 320px"
  >
    <ion-card
      class="ion-align-items-center ion-text-center ion-margin"
      *ngIf="deliverAreas && deliverAreas.length===0"
      color="danger"
    >
      <ion-card-content style="margin: 0px; padding: 5px">
        <ion-label style="font-weight: 800">
          <ion-text color="white">
            {{"new-address-modal.no-delivery-areas-that-serves-your-location" |
            translate}}
          </ion-text>
        </ion-label>
      </ion-card-content>
    </ion-card>
    <span *ngIf="deliverAreas && deliverAreas.length>0">
      <ion-card
        color="light"
        class="ion-align-items-center ion-text-center ion-margin"
        button
        (click)="openDeliveryAreaInfoModal(area)"
        *ngFor="let area of deliverAreas ; trackBy: trackDeliveryAreas"
      >
        <!-- <button style="width: 280px; font-size: medium; margin-top: 5px;" ng-click="openDeliveryAreaInfoPopup(area)" -->
        <!-- class="button button-light button-small"> -->

        <ion-card-content style="margin: 0; padding: 0">
          <div
            *ngIf="area && area.fillColor"
            [ngStyle]="{'background-color':area && area.fillColor ? area.fillColor : ''}"
            value="1"
            style="height: 5px"
          ></div>
          <ion-label style="font-weight: 800">
            <div *ngIf="area && area.fixed_address_id && area.fixedAddressName">
              <ion-text color="dark">
                {{ 'delivery-areas.fixed-point-address' | translate}}
              </ion-text>
            </div>

            <div *ngIf="area.store">
              <ion-text color="dark"> {{ (area.store )}} </ion-text>
            </div>
            <div
              style="font-size: small"
              *ngIf="area && area.fixed_address_id && area.fixedAddressName"
            >
              <ion-text color="dark"> {{ area.fixedAddressName }} </ion-text>
            </div>
            <div *ngIf="area">
              <ion-text color="dark" style="font-size: small">
                {{"delivery-areas.minimum-order" |
                translate}}&nbsp;{{area.minOrder}}€&nbsp;
              </ion-text>
            </div>
            <div *ngIf="area" style="font-size: small">
              <span
                *ngIf="area.deliveryCost && area.deliveryCost !== '0' && !area.delivery_cost_per_kilometer_active"
              >
                <ion-text color="dark"
                  >{{"delivery-areas.cost-for-delivery" |
                  translate}}:&nbsp;{{area.deliveryCost}}€</ion-text
                >&nbsp;
              </span>
              <span
                *ngIf="
                area.deliveryCost &&
                area.deliveryCost !== '0' &&
                area.delivery_cost_per_kilometer_active
              "
              >
                <ion-text color="dark"
                  ><strong
                    >{{ "delivery-areas.cost-for-delivery" | translate
                    }}:</strong
                  >&nbsp;{{ area.deliveryCost }}€ {{ "delivery-areas.per" |
                  translate }} {{area.step_active && area.step_distance ?
                  area.step_distance + ' ' + ('meters' | translate) :
                  'km'}}</ion-text
                >

                <ion-icon
                  style="
                    vertical-align: middle;
                    margin-left: 3px;
                    font-size: 21px;
                  "
                  name="information-circle-outline"
                >
                </ion-icon>
              </span>
            </div>
            <div
              style="font-size: small"
              *ngIf="area && area.free_delivery_over_amount"
            >
              <ion-text color="danger">
                {{ "delivery-areas.free-delivery-over" | translate }} {{
                area.free_delivery_over_amount | twoDecimalsRound :false }}€
              </ion-text>
            </div>
            <div>
              <span *ngIf="area && area.scheduleComment && area.showSchedule">
                <ion-text style="font-size: small" color="danger">
                  {{area.scheduleComment}}
                </ion-text>
              </span>
            </div>
            <div>
              <span *ngIf="area && area.comment">
                <ion-text color="dark" style="font-size: small"
                  >{{area.comment}}</ion-text
                >
              </span>
            </div>
          </ion-label>
        </ion-card-content>

        <!-- </button> -->
      </ion-card>
    </span>
    <ion-card
      class="ion-margin"
      *ngIf="groupApp && groupApp.fixedPointsAddressSystemActive && showInfoFixedPointsAddresses"
    >
      <ion-item lines="none">
        <ion-thumbnail
          slot="start"
          style="max-width: 30px; max-height: 30px; margin-right: 5px"
        >
          <img width="30" height="30" src="assets/ionicons/marker-blue.png" />
        </ion-thumbnail>

        <ion-label
          class="ion-text-wrap"
          style="font-size: small; font-weight: bold; line-height: 1.2"
        >
          {{'delivery-areas.fixed-points-addresses' | translate}}
        </ion-label>

        <ion-icon
          style="font-size: 15px; cursor: pointer; margin-left: 5px"
          name="close"
          slot="end"
          (click)="hideInfoFixedPointsAddresses()"
        >
        </ion-icon>
      </ion-item>
    </ion-card>
  </ion-grid>

  <ion-grid *ngIf="loading">
    <ion-row class="ion-padding" style="width: 100%">
      <ion-col class="ion-text-center">
        <ion-spinner> </ion-spinner>
      </ion-col>
    </ion-row>
  </ion-grid>
  <div
    id="map"
    class="gmStyle"
    style="width: 100%; height: 100%"
    data-tap-disabled="true"
  ></div>
  <!-- <ion-grid style="position: absolute; bottom: 0px; z-index: 20; width: 100%">
    <ion-card color="primary">
      <ion-label style="font-weight: 800" color="light"
        >{{'move-the-info-marker-to-see-info-about'}}</ion-label
      >
    </ion-card>
  </ion-grid> -->

  <div
    [hidden]="!mobilePlatform"
    style="
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -50px;
      margin-left: -50px;
      height: 100px;
      width: 100px;
    "
  >
    <!-- [class]="showUpInfoMarkerAnimation && ! showDownInfoMarkerAnimation ? 'moveUpInfoMarkerAnimation' : (!showUpInfoMarkerAnimation && showDownInfoMarkerAnimation ? 'moveDownInfoMarkerAnimation' :'noMoveInfoMarker')" -->
    <img
      style="
        position: absolute;
        top: 20px;
        padding: 0;
        left: 50%;
        margin-top: -25px;
        margin-left: -25px;
      "
      width="50px"
      height="50px"
      src=" assets/flatIcons/info.png"
    />
    <ion-icon
      [color]="selectedTheme && selectedTheme==='dark' ? 'white':''"
      style="
        position: absolute;
        top: 50%;
        left: 50%;
        padding: 0;
        margin-top: -5px;
        margin-left: -5px;
        font-size: 10px;
      "
      width="10px"
      height="10px"
      src="assets/flatIcons/xmark.svg"
    ></ion-icon>
  </div>

  <ion-fab
    [hidden]="!mobilePlatform"
    vertical="bottom"
    horizontal="start"
    slot="fixed"
  >
    <ion-fab-button (click)="checkLocationFromGps()">
      <ion-icon src="assets/flatIcons/gps.svg"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <div
    *ngIf="groupApp &&
      groupApp.design_settings &&
      groupApp.design_settings.menuFooterTabs && windowDimensions && windowDimensions.width<900"
    style="margin-top: 65px"
  ></div>
</ion-content>

<app-menu-footer-tabs></app-menu-footer-tabs>
