import * as RecommendedProductActions from './recommended-products.actions';
import * as _ from 'lodash';

export interface State {
  recommended_products: any;
}

const initialState: State = {
  recommended_products: false,
};

export function recommendedProductsReducer(
  state = initialState,
  action: RecommendedProductActions.RecommendedProductsActions
) {
  switch (action.type) {
    case RecommendedProductActions.SET_RECOMMENDED_PRODUCTS:
      return {
        ...state,
        recommended_products: action.payload,
      };

    case RecommendedProductActions.CLEAR_RECOMMENDED_PRODUCTS:
      return {
        ...state,
        recommended_products: null,
      };

    default:
      return state;
  }
}
