import { Pipe, PipeTransform } from "@angular/core";
import _ from "lodash";

@Pipe({
  name: "orderStoresByDistance",
})
export class OrderStoresByDistancePipe implements PipeTransform {
  transform(stores: any): any {
    const orderStoresByDistance: boolean = !!!_.find(stores, (store) => {
      if (!store.estimatedDistanceFromClientGps) {
        return true;
      }
    });
    if (orderStoresByDistance && stores && stores.length) {
      return _.orderBy(stores, ["estimatedDistanceFromClientGps"], "asc");
    }

    return stores;
  }
}
