<ion-header>
  <app-no-internet-connection></app-no-internet-connection>
  <app-ionic-update-error></app-ionic-update-error>
  <ion-toolbar mode="md">
    <ion-title>{{"account-settings.account" | translate}}</ion-title>
    <ion-buttons slot="start">
      <ion-button
        *ngIf="onlyLoyaltyApp"
        (click)="pageChange('loyaltySystem/loyalty-more')"
      >
        <ion-icon name="arrow-back-outline"></ion-icon>
      </ion-button>
      <ion-menu-button
        *ngIf="groupApp && windowDimensions"
        color="dark"
      ></ion-menu-button>
    </ion-buttons>
    <ion-chip
      (click)="onMobileClick()"
      *ngIf="currentUser && currentUser.mobile"
      slot="end"
      >{{currentUser.mobile}}
    </ion-chip>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row *ngIf="!currentUser">
      <ion-col class="ion-text-center">
        <ion-spinner class="ion-padding"> </ion-spinner>
      </ion-col>
    </ion-row>

    <!-- <ion-item-divider
      lines="none"
      mode="ios"
      *ngIf="currentUser && currentUser.mobile"
    >
      <ion-text class="ion-text-wrap" position="stacked"
        >{{"account-settings.verified-mobile"| translate}}</ion-text
      >
      <ion-text slot="end">  </ion-text>
    </ion-item-divider> -->
    <ion-item-divider mode="ios" [color]="valid ? '' : 'danger'">
      <ion-label>
        {{ "account-settings.customer-info" | translate }}
      </ion-label>
      <ion-spinner
        style="scale: 0.5"
        *ngIf="currentUser && customerDataSaveLoading"
        slot="end"
        name="dots"
      >
      </ion-spinner>
      <ion-icon
        class="ion-no-margin"
        [color]="customerDataSaveSuccess ? 'success' : 'dark'"
        slot="end"
        *ngIf="currentUser && !customerDataSaveLoading && customerDataDirty"
        src="assets/ionicons/checkmark-circle.svg"
      >
      </ion-icon>
    </ion-item-divider>

    <ion-row style="width: 100%" *ngIf="currentUser">
      <ion-col class="ion-align-self-center">
        <ion-item lines="none">
          <ion-button
            *ngIf="currentUser && currentUser.mobile ==='6984136939'"
            (click)="deleteProfilePic()"
            fill="clear"
            style="position: absolute; top: 0; left: 0"
          >
            <ion-icon name="trash-outline" color="danger"></ion-icon>
          </ion-button>
          <ion-button
            *ngIf="!isBrowserPlatform && currentUser.mobile==='6984136939'"
            (click)="selectImageFromGallery()"
            fill="clear"
            style="position: absolute; bottom: 0; left: 0"
          >
            <ion-icon name="cloud-upload"></ion-icon>
          </ion-button>
          <ion-button
            *ngIf="isBrowserPlatform && currentUser.mobile==='6984136939'"
            (click)="openEditImageModal()"
            fill="clear"
            style="position: absolute; bottom: 0; left: 0"
          >
            <ion-icon name="cloud-upload"></ion-icon>
          </ion-button>
          <div style="width: 100%" align="center">
            <ion-avatar class="avatarClass">
              <img
                width="auto"
                height="auto"
                *ngIf="groupApp && currentUser && currentUser.profile_image && currentUser.profile_image.optimized"
                [src]="'https://primg.deliverymanager.gr/'+ groupApp.group + '/'+ currentUser.customer_id + '/' + currentUser.profile_image.filename"
              />
              <ng-lottie
                *ngIf="!currentUser || !currentUser.profile_image || !currentUser.profile_image.optimized"
                (animationCreated)="animationCreated($event)"
                [options]="options"
              >
              </ng-lottie>
              <!-- <img width="auto" height="auto" *ngIf="!currentUser.profile_picture" src="assets/icons/user-picture.svg"> -->
            </ion-avatar>
          </div>
        </ion-item>
      </ion-col>
      <ion-col>
        <ion-row>
          <ion-item
            [color]="
              currentUser && !currentUser.firstName && !valid ? 'notValid' : ''
            "
          >
            <ion-label position="floating"
              >{{ "account-settings.name" | translate }}</ion-label
            >
            <ion-input
              [class]="
                currentUser && !currentUser.firstName ? 'ng-invalid' : 'ng-valid'
              "
              [disabled]="customerDataSaveLoading"
              (ionChange)="updateCustomerDataDebounced('firstName')"
              (ionFocus)="onInputFocus()"
              [(ngModel)]="currentUser.firstName"
              name="lastName"
            ></ion-input>
          </ion-item>
        </ion-row>
        <ion-row>
          <ion-item
            [color]="currentUser && !currentUser.lastName && !valid ? 'notValid' : ''"
          >
            <ion-label position="floating"
              >{{ "account-settings.last-name" | translate }}</ion-label
            >
            <ion-input
              [class]="
                currentUser && !currentUser.lastName ? 'ng-invalid' : 'ng-valid'
              "
              [disabled]="customerDataSaveLoading"
              (ionChange)="updateCustomerDataDebounced('lastName')"
              (ionFocus)="onInputFocus()"
              [(ngModel)]="currentUser.lastName"
              name="firstName"
            ></ion-input>
          </ion-item>
        </ion-row>
      </ion-col>
    </ion-row>

    <ion-item-divider mode="ios" *ngIf="currentUser">
      <ion-text>{{"account-settings.account-info" | translate}}</ion-text>
    </ion-item-divider>
    <ion-list>
      <!-- <ion-item *ngIf="currentUser" class="inputValidationColor">
        <ion-label position="stacked">{{"account-settings.name" | translate}}</ion-label>
        <ion-input #firstNameInput type="text" required (ngModelChange)="changed()" [(ngModel)]="currentUser.firstName">
        </ion-input>
      </ion-item>

      <ion-item *ngIf="currentUser" class="inputValidationColor">
        <ion-label class="ion-text-wrap" position="stacked">{{"account-settings.last-name" | translate}}</ion-label>
        <ion-input #lastNameInput type="text" required (ngModelChange)="changed()" [(ngModel)]="currentUser.lastName">
        </ion-input>
      </ion-item> -->
      <ion-item
        [disabled]="customerDataSaveLoading"
        *ngIf="currentUser"
        class="inputValidationColor"
      >
        <ion-label position="stacked"
          >{{"account-settings.email" | translate}}</ion-label
        >
        <ion-input
          #emailInput
          type="email"
          required
          (ngModelChange)="changed()"
          [(ngModel)]="currentUser.email"
        >
          <!-- <ion-button
            *ngIf="!customerDataSaveLoading"
            (click)="saveOrEdit()"
            [color]="!edit ? 'primary' : 'danger'"
            slot="end"
            fill="clear"
            ><ion-icon
              slot="icon-only"
              [src]="!edit ? 'assets/ionicons/create.svg' : 'assets/ionicons/save.svg'"
            ></ion-icon
          ></ion-button> -->
        </ion-input>
        <ion-button
          *ngIf="!customerDataSaveLoading"
          (click)="saveOrEdit()"
          [color]="dirtyButton ? 'danger':'primary'"
          slot="end"
          fill="clear"
        >
          <ion-icon
            slot="icon-only"
            [src]="'assets/ionicons/save.svg'"
          ></ion-icon>
        </ion-button>
        <ion-spinner *ngIf="customerDataSaveLoading" slot="end"></ion-spinner>
      </ion-item>
    </ion-list>

    <ion-item-divider mode="ios" class="ion-text-center" *ngIf="currentUser">
      <ion-label class="ion-text-center">
        {{"account-settings.notifications" | translate}}
      </ion-label>
    </ion-item-divider>

    <ion-segment
      color="primary"
      *ngIf="currentUser"
      value="alertOrder"
      (ionChange)="hanleSegmentChange($event)"
      scrollable
      style="padding-bottom: 5px"
    >
      <ion-segment-button
        style="min-width: 110px"
        color="accountSettingsEditBtn"
        value="alertOrder"
        >{{"account-settings.orders" | translate}}
      </ion-segment-button>
      <ion-segment-button style="min-width: 110px" value="alertReview"
        >{{"account-settings.reviews" | translate}}</ion-segment-button
      >
      <ion-segment-button style="min-width: 110px" value="alertPromotion"
        >{{"account-settings.promotions" | translate}}</ion-segment-button
      >
      <ion-segment-button
        *ngIf="groupApp.pointsSystem"
        style="min-width: 110px"
        value="alertExpirationPoints"
        >{{"account-settings.points" | translate}}</ion-segment-button
      >
    </ion-segment>

    <ion-item [disabled]="customerDataSaveLoading" *ngIf="currentUser">
      <ion-label>{{"account-settings.by-push" | translate}}</ion-label>
      <ion-toggle
        [disabled]="customerDataSaveLoading"
        color="primary"
        [(ngModel)]="currentUser[accountData.tab].push"
        (ngModelChange)="onPushChange($event)"
      ></ion-toggle>
    </ion-item>
    <ion-item *ngIf="currentUser" [disabled]="customerDataSaveLoading">
      <ion-label>{{"account-settings.by-sms" | translate}}</ion-label>
      <ion-toggle
        [disabled]="customerDataSaveLoading"
        color="primary"
        [(ngModel)]="currentUser[accountData.tab].sms"
        (ngModelChange)="onOtherChange($event)"
      ></ion-toggle>
    </ion-item>
    <ion-item *ngIf="currentUser" [disabled]="customerDataSaveLoading">
      <ion-label>{{"account-settings.by-email" | translate}}</ion-label>
      <ion-toggle
        [disabled]="customerDataSaveLoading"
        color="primary"
        [(ngModel)]="currentUser[accountData.tab].email"
        (ngModelChange)="onOtherChange($event)"
      ></ion-toggle>
    </ion-item>
    <ion-item *ngIf="currentUser">
      <ion-label>{{"account-settings.by-viber" | translate}}</ion-label>
      <ion-toggle
        [disabled]="customerDataSaveLoading"
        color="primary"
        [(ngModel)]="currentUser[accountData.tab].viber"
        (ngModelChange)="onOtherChange($event)"
      ></ion-toggle>
    </ion-item>

    <div *ngIf="groupApp && groupApp.active_loyalty_app">
      <ion-item-divider mode="ios" class="ion-text-center" *ngIf="currentUser">
        <ion-label class="ion-text-center">
          {{"account-settings.hall-of-fame-settings" | translate}}
        </ion-label>
      </ion-item-divider>

      <ion-item [disabled]="customerDataSaveLoading" *ngIf="currentUser">
        <ion-label
          >{{"account-settings.hof-show-last-name" | translate}}</ion-label
        >
        <ion-toggle
          [disabled]="customerDataSaveLoading"
          color="primary"
          [(ngModel)]="currentUser.loyalty_app_settings.showLastName"
          (ngModelChange)="onOtherChange($event)"
        ></ion-toggle>
      </ion-item>
      <ion-item *ngIf="currentUser" [disabled]="customerDataSaveLoading">
        <ion-label>{{"account-settings.hof-show-image" | translate}}</ion-label>
        <ion-toggle
          [disabled]="customerDataSaveLoading"
          color="primary"
          [(ngModel)]="currentUser.loyalty_app_settings.showImage"
          (ngModelChange)="onOtherChange($event)"
        ></ion-toggle>
      </ion-item>
    </div>

    <ion-item-divider mode="ios" class="ion-text-center" *ngIf="currentUser">
      <ion-label class="ion-text-center">
        {{"account-settings.design-settings" | translate}}
      </ion-label>
    </ion-item-divider>
    <ion-item
      *ngIf=" 
    !(groupApp &&
    groupApp.design_settings &&
    groupApp.design_settings.disable_app_darkmode)
  "
    >
      <ion-label> {{'account-settings.dark-theme' | translate}} </ion-label>
      <ion-toggle (ionChange)="darkMode()" [(ngModel)]="darkTheme"></ion-toggle>
    </ion-item>

    <ion-item-divider mode="ios" *ngIf="currentUser">
      {{'account-settings.personal-data' | translate}} (GDPR)
    </ion-item-divider>

    <ion-item>
      <ion-label class="ion-text-wrap">
        {{'account-settings.request-delete-account' | translate}}
      </ion-label>
      <ion-button
        slot="end"
        [disabled]="deleteAccountLoading"
        color="danger"
        fill="outline"
        size="medium"
        mode="md"
        (click)="requestDeleteAccount()"
      >
        <ion-icon
          *ngIf="!deleteAccountLoading"
          slot="icon-only"
          src="assets/flatIcons/delete-account.svg"
        ></ion-icon>
        <ion-spinner *ngIf="deleteAccountLoading"></ion-spinner>
        <!-- <ion-text *ngIf="!deleteAccountLoading">
          {{'account-settings.request-delete-account' | translate}}
        </ion-text> -->
      </ion-button>
    </ion-item>

    <app-invoice-data *ngIf="currentUser && showInvoiceData"></app-invoice-data>
  </ion-grid>
  <div
    *ngIf="groupApp &&
groupApp.design_settings &&
groupApp.design_settings.menuFooterTabs"
    style="margin-top: 65px"
  ></div>
</ion-content>

<app-menu-footer-tabs></app-menu-footer-tabs>

<ion-footer
  *ngIf="groupApp && (!groupApp.design_settings || !groupApp.design_settings.menuFooterTabs)"
>
  <ion-grid>
    <ion-row>
      <ion-col size="12" class="ion-text-center">
        <ion-button (click)="logout()" color="danger">
          {{ "pages.logout" | translate }}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
