<ion-button
  (click)="ondismiss()"
  size="small"
  fill="clear"
  color="white"
  style="position: absolute; top: 20px; right: 10px; z-index: 1000"
  ><ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon
></ion-button>

<ion-grid
  (click)="ondismiss()"
  style="width: 100%; height: 100%; max-width: 350px"
>
  <ion-row
    style="width: 100%; height: 100%"
    class="ion-align-items-center ion-justify-content-center"
  >
    <ion-col size="12">
      <ion-row style="width: 100%">
        <ion-col size="6" class="ion-text-center">
          <ion-grid class="ion-no-padding" style="width: 150px; height: 200px">
            <ion-item
              color="primary"
              button="true"
              lines="none"
              detail="false"
              (click)="onClick('new-address')"
            >
              <ion-label class="ion-text-wrap">
                <ion-grid style="width: 80px">
                  <ng-lottie [options]="newAddressAnimationOptions">
                  </ng-lottie>
                </ion-grid>
                <div
                  class="ion-text-center"
                  style="font-weight: bold; margin-left: 3px; margin-right: 3px"
                >
                  {{
                    "select-fixed-points-address-modal.confirmation-popup.new-address-delivery"
                      | translate
                  }}
                </div>
              </ion-label>
            </ion-item>
          </ion-grid>
        </ion-col>
        <ion-col size="6" class="ion-text-center">
          <ion-grid class="ion-no-padding" style="width: 150px; height: 200px">
            <ion-item
              color="primary"
              button="true"
              lines="none"
              detail="false"
              (click)="onClick('fixed-points-selection')"
            >
              <ion-label class="ion-text-wrap">
                <ion-grid style="width: 80px">
                  <ng-lottie [options]="fixedPointsAnimationOptions">
                  </ng-lottie>
                </ion-grid>
                <div class="ion-text-center" style="font-weight: bold">
                  {{
                    "select-fixed-points-address-modal.confirmation-popup.fixed-points-address"
                      | translate
                  }}
                </div>
              </ion-label>
            </ion-item>
          </ion-grid>
        </ion-col>
      </ion-row>
    </ion-col>
  </ion-row>
</ion-grid>
