<app-bundle-option-radio
  *ngIf="type && type === 'radio'"
  [product_id]="product_id"
  [option_id]="option_id"
  [disable_qty]="disable_qty"
  (bundleRadioOptionChanged)="bundleOptionChange($event)"
  [must_select_something]="must_select_something"
  [selectedProductCategory]="selectedProductCategory"
  [selectedProductPromo]="selectedProductPromo"
  [selectedProductFreeParentOption]="selectedProductFreeParentOption"
  [bundleMode]="bundleMode"
  [selectedProductId]="selectedProductId"
  [selectedProductParentDiscount]="selectedProductParentDiscount"
  [rootProductParentBundleChoice]="rootProductParentBundleChoice"
  [groupStoreData]="groupStoreData"
></app-bundle-option-radio>
