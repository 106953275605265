import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import * as _ from "lodash";
import { Subscription } from 'rxjs';
import * as fromApp from "../../store/app.reducer";
import { Store } from '@ngrx/store';
import moment from "moment";
import { ReservationDatePopupComponent } from '../create-reservation/reservation-date-popup/reservation-date-popup.component';

@Component({
  selector: 'app-reservation-table-info-modal',
  templateUrl: './reservation-table-info-modal.component.html',
  styleUrls: ['./reservation-table-info-modal.component.scss'],
})
export class ReservationTableInfoModalComponent implements OnInit, OnDestroy {
  @Input() table;
  @Input() reservationTimestamp;
  @Input() reservationDays;
  @Input() lang;
  public schedule;
  public days;
  public openDays = {};
  private subscriptions: Subscription[] = [];
  private unsubscribeBackEvent: Subscription;
  public selectedLanguage;

  constructor(private modalController: ModalController,
              private store: Store<fromApp.AppState>,
              private platform: Platform) { }

  ngOnInit() {
    this.days = _.map(this.reservationDays, day => {
      return moment(day.day, "DD-MM-YYYY").locale(this.lang).format("ddd, DD MMM");
    });
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      300,
      () => {
        if (this.platform.is("android")) {
          this.modalController.dismiss();
        }
      }
    );
    this.subscriptions.push(
      this.store
          .select("groupApp")
          .subscribe((state) => {
            if (
              state &&
              state.selectedLangugage &&
              !_.isEqual(this.selectedLanguage, state.selectedLangugage)
            ) {
              this.selectedLanguage = _.cloneDeep(state.selectedLangugage);

              this.schedule = {};
              _.forEach(this.table.calendar, slot => {
                const mom = moment(parseInt(slot.start)).locale(this.selectedLanguage);
                const dateString = mom.format("ddd, DD MMM");
                const startString = mom.format("HH:mm");
                const endString = moment(parseInt(slot.end) + 1).format("HH:mm");
                if (_.isEmpty(this.schedule[dateString])){
                  this.schedule[dateString] = [];
                }
                if (slot.start !== slot.end) {
                  this.schedule[dateString].push({
                    start: startString,
                    end: endString,
                    prepay_amount: slot.prepay_amount,
                    timestamp: slot.start,
                    endTimestamp: slot.end,
                    slot_id: slot.slot_id,
                    taken: !!slot.taken,
                    virtual: !!slot.virtual
                  });
                }
              });
              _.forEach(this.schedule, (value, key) => {
                this.schedule[key] = _.sortBy(value, item => item.start);
              });

              let reservationDate;
              if (this.reservationTimestamp){
                reservationDate = moment(parseInt(this.reservationTimestamp)).locale(this.selectedLanguage).format("ddd, DD MMM");
              }

              _.forEach(this.days, day => {
                if (reservationDate && reservationDate !== day){
                  this.openDays[day] = false;
                } else {
                  this.openDays[day] = true;
                }
              });

              console.log("table", this.table, this.days, this.schedule);
            }
          })
    );
  }

  onDividerClick(day) {
    this.openDays[day] = !this.openDays[day];
  }
  
  closeModal() {
    this.modalController.dismiss();
  }

  onSelectSlot(slot) {
    if (!slot.virtual){
      this.modalController.dismiss({
        timestamp: slot.timestamp,
        endTimestamp: slot.endTimestamp,
        virtual: slot.virtual
      }, "confirm");
    } else {
      const endOfDay = moment(parseInt(slot.endTimestamp) - 2).endOf("day").format("x");
      console.log("here", slot.endTimestamp, +endOfDay + 1);
      let nextSlot = null;
      if (parseInt(slot.endTimestamp) >= parseInt(endOfDay) + 1){
        let flag = false;
        _.forEach(this.days, day => {
          _.forEach(this.schedule[day], s => {
            if (flag){
              if (!s.taken){
                nextSlot = _.cloneDeep(s);
              } else {
                nextSlot = {};
              }
              return false;
            }
            if (s.slot_id === slot.slot_id){
              flag = true;
            }
          });
          if (nextSlot){
            return false;
          }
        });
      }
      if (nextSlot && slot.endTimestamp !== nextSlot.timestamp) {
        nextSlot = null;
      }
      this.chooseDate(slot, nextSlot || {});
    }
  }

  async chooseDate(slot, nextSlot) {
    console.log("slot", slot);

    let endLimit = slot.endTimestamp;
    if (!_.isEmpty(nextSlot)){
      endLimit = nextSlot.endTimestamp
    }

    const modal = await this.modalController.create({
      component: ReservationDatePopupComponent,
      showBackdrop: true,
      backdropDismiss: false,
      cssClass: ['reservationDatePopup'],
      componentProps: { 
        reservationDays: _.cloneDeep(this.reservationDays),
        lang: _.cloneDeep(this.selectedLanguage),
        preselectedDate: _.cloneDeep(slot.timestamp || ""),
        startLimit: _.cloneDeep(slot.timestamp),
        endLimit: endLimit,
        priority: 400
      },
    });
    await modal.present();
    modal.onDidDismiss().then((el) => {
      if (el.role === 'confirm') {
        console.log("onDismiss", el.data);
        let reservationEndMoment;
        const reservationMoment = moment(el.data.day + " " + el.data.start, "DD-MM-YYYY HH:mm");
        if (el.data.end){
          const endDayString = el.data.end > el.data.start ? el.data.day : moment(el.data.day, "DD-MM-YYYY").add(1, "day").format("DD-MM-YYYY");
          reservationEndMoment = moment(endDayString + " " + el.data.end, "DD-MM-YYYY HH:mm");
        } else {
          reservationEndMoment = null;
        }
        this.modalController.dismiss({
          timestamp: reservationMoment.format("x"),
          endTimestamp: reservationEndMoment ? reservationEndMoment.format("x") : "",
          virtual: slot.virtual
        }, "confirm");
      }
    });
  }

  ngOnDestroy(){
    if (this.unsubscribeBackEvent){
      this.unsubscribeBackEvent.unsubscribe();
    }
    _.forEach(this.subscriptions, sub => {
      if (sub){
        sub.unsubscribe();
      }
    })
  }

}
