import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import moment from "moment";
import * as fromApp from "../../store/app.reducer";
import * as AuthActions from "../../auth/store/auth.actions";
import * as _ from "lodash";
import { Subscription, distinctUntilChanged } from "rxjs";

@Component({
  selector: "app-kiosk-qr-info-modal",
  templateUrl: "./kiosk-qr-info-modal.component.html",
  styleUrls: ["./kiosk-qr-info-modal.component.scss"],
})
export class KioskQrInfoModalComponent implements OnInit, OnDestroy {
  public currentUser: any;
  //timer kiosk qr expiration vars
  public timeLimit: number = 60 * 60; // one hour
  public secondsRemaining: number = 0;
  public formattedTime: string = "";
  public groupApp: any;

  private kioskQrTimerInterval;
  private unsubscribeBackEvent: Subscription;
  private groupSub: Subscription;
  private authSubscription: Subscription;

  constructor(
    private modalController: ModalController,
    private platform: Platform,
    private changeDetector: ChangeDetectorRef,
    private store: Store<fromApp.AppState>
  ) {}

  closeModal() {
    this.modalController.dismiss();
  }

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );

    this.groupSub = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (state && !_.isEqual(state.groupApp, this.groupApp)) {
          this.groupApp = _.cloneDeep(state.groupApp);
        }
      });

    this.authSubscription = this.store
      .select("auth")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (state && !_.isEqual(state.user, this.currentUser)) {
          this.currentUser = _.cloneDeep(state.user);
          if (this.currentUser && this.currentUser.kiosk_expiration_timestamp) {
            let momentTimestamp;

            if (
              this.timestampIsMilliseconds(
                _.cloneDeep(this.currentUser.kiosk_expiration_timestamp)
              )
            ) {
              momentTimestamp = moment(
                parseInt(
                  _.cloneDeep(
                    this.currentUser.kiosk_expiration_timestamp
                  ).toString()
                )
              );
            } else {
              momentTimestamp = moment.unix(
                parseInt(
                  _.cloneDeep(
                    this.currentUser.kiosk_expiration_timestamp
                  ).toString()
                )
              );
            }

            // Calculate the remaining seconds
            this.secondsRemaining = _.cloneDeep(
              momentTimestamp.diff(moment(), "seconds")
            );

            if (this.secondsRemaining) {
              this.startTimerKioskQr();
            }
          }
        }
      });
  }

  triggerRenewToken() {
    this.store.dispatch(
      new AuthActions.SetTriggerRenewTokenTimestamp(Date.now().toString())
    );
  }

  startTimerKioskQr() {
    this.updateTimerKioskQr();

    if (this.kioskQrTimerInterval) {
      clearInterval(this.kioskQrTimerInterval);
      this.kioskQrTimerInterval = null;
    }
    this.kioskQrTimerInterval = setInterval(() => {
      this.secondsRemaining = _.cloneDeep(this.secondsRemaining) - 1;

      if (this.secondsRemaining < 0) {
        if (this.kioskQrTimerInterval) {
          clearInterval(this.kioskQrTimerInterval);
          this.kioskQrTimerInterval = null;
        }
      } else {
        this.updateTimerKioskQr();
      }
    }, 1000);
  }

  timestampIsMilliseconds(timestamp) {
    const length = timestamp.toString().length;
    return length === 13;
  }

  updateTimerKioskQr() {
    const minutes = parseInt(
      Math.floor(_.cloneDeep(this.secondsRemaining) / 60).toString()
    );
    const seconds = parseInt(
      (_.cloneDeep(this.secondsRemaining) % 60).toString()
    );

    this.formattedTime = moment()
      .set("minutes", minutes)
      .set("seconds", seconds)
      .format("mm:ss");

    this.changeDetector.detectChanges();
  }
  ngOnDestroy(): void {
    if (this.kioskQrTimerInterval) {
      clearInterval(this.kioskQrTimerInterval);
      this.kioskQrTimerInterval = null;
    }
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
    if (this.groupSub) {
      this.groupSub.unsubscribe();
    }
    this.secondsRemaining = 0;
    this.currentUser = null;
  }
}
