<ion-header>
  <ion-toolbar style="padding-top: 2px; padding-bottom: 2px" mode="md">
    <ion-title size="small">
      <ion-text>
        {{ "reservations.reservation-date" | translate }}
      </ion-text>
    </ion-title>
    <ion-button
      (click)="closeModal()"
      size="small"
      slot="end"
      fill="clear"
      color="dark"
    >
    <ion-icon slot="icon-only" src="assets/ionicons/close-outline.svg"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content scrollY="false">

  <ion-grid>
    <ion-list>
      <ion-item>
        <ion-label>
          {{ "popups.pre-order-popup.select-date" | translate }}
        </ion-label>
        <ion-select *ngIf="!preselectedDate"
          [class]="!selectedDate ? 'selectPlaceHolderDanger' : ''"
          placeholder="{{ 'please-select' | translate }}"
          okText="{{ 'select' | translate }}"
          cancelText="{{ 'cancel' | translate }}"
          (ionChange)="selectDate()"
          [(ngModel)]="selectedDate"
          [disabled]="preselectedDate"
        >
          <span>
            <ion-select-option
              *ngFor="let date of reservationDays"
              [value]="date.day"
              [disabled]="
                date && date.day_schedule && !date.day_schedule.active
              "
              >{{ date.dayString }}</ion-select-option
            >
          </span>
        </ion-select>
        <ion-label class="ion-text-end" *ngIf="preselectedDate && selectedDate">
          {{ selectedDate }}
        </ion-label>
      </ion-item>

      <ion-item
        [disabled]="!selectedDate"
        button
        id="summon_calendar"
      >
        <ion-label [class]="!disableStartHours ? '': 'ion-text-wrap ion-text-center'">
          <ion-text *ngIf="!disableStartHours">
            {{ "reservations.from" | translate }}
          </ion-text>
          <ion-text style="font-size: small; font-weight: bold" color="danger" *ngIf="disableStartHours">
            {{
            "reservations.there-are-no-available-hours" | translate
            }}
          </ion-text>
        </ion-label>

        <ion-label slot="end">
          {{ selectedHour | date:"HH:mm" }}
        </ion-label>

        <!-- <ion-select
          *ngIf="!selectedDate || (availableHours && availableHours.length)"
          [class]="!selectedHour ? 'selectPlaceHolderDanger' : ''"
          placeholder="{{ 'please-select' | translate }}"
          okText="{{ 'select' | translate }}"
          cancelText="{{ 'cancel' | translate }}"
          [(ngModel)]="selectedHour"
          (ionChange)="selectStart()"
        >
          <span>
            <ion-select-option
              [value]="time_slot"
              *ngFor="let time_slot of availableHours"
              >{{ time_slot }}
            </ion-select-option>
          </span>
        </ion-select> -->
      </ion-item>

      <ion-modal (willPresent)="onCalendarFocus()" class="invisibleDateTimePicker" trigger="summon_calendar">
        <ng-template>
          <ion-content>
            <ion-grid>
              <ion-row class="ion-align-items-center ion-justify-content-center" style="width: 100%; height: 100%">
                <ion-col size="12" class="ion-align-self-center ion-text-center">
                  <ion-grid class="ion-text-center ion-no-padding" style="max-width: 352px">
                    <ion-datetime [preferWheel]="true" hourCycle="h23" [(ngModel)]="selectedHour" (ionChange)="onSelectHour()"
                      [min]="startHourMin" [max]="startHourMax" [minuteValues]="[0, 15, 30, 45]" presentation="time" [showDefaultButtons]="true"
                      [locale]="lang" doneText="{{ 'ok' | translate }}" cancelText="{{ 'cancel' | translate }}"><span
                        slot="title">{{ "reservations.select-hour" | translate }}</span></ion-datetime>
                  </ion-grid>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-content>
        </ng-template>
      </ion-modal>

      <ion-item button id="summon_calendar2"
        [disabled]="!selectedDate || !selectedHour"
      >
        <ion-label
        >
          <ion-text>
            {{ "reservations.to" | translate }}
          </ion-text>
          <!-- <ion-text
            style="font-size: small; font-weight: bold"
            color="danger"
            *ngIf="selectedHour && (!availableEndHours || !availableEndHours.length)"
          >
            {{
              "reservations.there-are-no-available-hours" | translate
            }}
          </ion-text> -->
        </ion-label>

        <ion-label slot="end">
          {{ selectedEndHour | date:"HH:mm" }}
        </ion-label>

        <ion-modal (willPresent)="onCalendarEndFocus()" class="invisibleDateTimePicker" trigger="summon_calendar2">
          <ng-template>
            <ion-content>
              <ion-grid>
                <ion-row class="ion-align-items-center ion-justify-content-center" style="width: 100%; height: 100%">
                  <ion-col size="12" class="ion-align-self-center ion-text-center">
                    <ion-grid class="ion-text-center ion-no-padding" style="max-width: 352px">
                      <ion-datetime [preferWheel]="true" hourCycle="h23" [(ngModel)]="selectedEndHour"
                        [min]="endHourMin" [max]="endHourMax" [minuteValues]="[0, 15, 30, 45]" [presentation]="endPresentation"
                        [showDefaultButtons]="true" [locale]="lang" doneText="{{ 'ok' | translate }}"
                        cancelText="{{ 'cancel' | translate }}"><span slot="title">{{ "reservations.select-hour"
                          | translate }}</span></ion-datetime>
                    </ion-grid>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-content>
          </ng-template>
        </ion-modal>

        <!-- <ion-select
          *ngIf="!selectedHour || (availableEndHours && availableEndHours.length)"
          [class]="!selectedEndHour ? 'selectPlaceHolderDanger' : ''"
          placeholder="{{ 'please-select' | translate }}"
          okText="{{ 'select' | translate }}"
          cancelText="{{ 'cancel' | translate }}"
          [(ngModel)]="selectedEndHour"
        >
          <span>
            <ion-select-option
              [value]="time_slot"
              *ngFor="let time_slot of availableEndHours"
              >{{ time_slot }}
            </ion-select-option>
          </span>
        </ion-select> -->
      </ion-item>
    </ion-list>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-grid>
    <ion-row style="width: 100%">
      <ion-col class="ion-text-center">
        <ion-button
          [disabled]="
            !selectedHour || !selectedDate || !selectedEndHour
          "
          (click)="onSubmit()"
        >
          <ion-text>{{
            (createReservationWithoutTable ? "reservations.create-reservation" : "select") | translate
          }}</ion-text>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
