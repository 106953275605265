import * as CartActions from "./cart.actions";
import * as _ from "lodash";

export interface State {
  products: any[];
  validatingCartLoading: any;
  promoCartNoCouponTimestamp: any;
  preorderOnlyNoCouponTimestamp: any;
  onlyAvailableForPreorderCartProductsById: any;
  onlinePaymentOnlyNoCouponTimestamp: any;
}

const initialState: State = {
  products: null,
  validatingCartLoading: false,
  promoCartNoCouponTimestamp: null,
  preorderOnlyNoCouponTimestamp: null,
  onlyAvailableForPreorderCartProductsById: null,
  onlinePaymentOnlyNoCouponTimestamp: null,
};

export function cartReducer(
  state = initialState,
  action: CartActions.CartActions
) {
  switch (action.type) {
    case CartActions.SET_CART_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };

    case CartActions.SET_VALIDATING_CART_LOADING:
      return {
        ...state,
        validatingCartLoading: action.payload,
      };

    case CartActions.SET_PROMO_CART_NO_COUPON_TIMESTAMP:
      return {
        ...state,
        promoCartNoCouponTimestamp: action.payload,
      };

    case CartActions.SET_PREORDER_ONLY_NO_COUPON_TIMESTAMP:
      return {
        ...state,
        preorderOnlyNoCouponTimestamp: action.payload,
      };

    case CartActions.SET_ONLINE_PAYMENT_ONLY_NO_COUPON_TIMESTAMP:
      return {
        ...state,
        onlinePaymentOnlyNoCouponTimestamp: action.payload,
      };

    case CartActions.ADD_UPDATE_CART_PRODUCT_ITEM:
      const key = _.findIndex(state.products, (item) => {
        return item.cart_id === action.payload.cart_id;
      });
      if (key !== -1) {
        const updateProduct = {
          ...state.products[key],
          ...action.payload,
        };
        const updatedProducts = [...state.products];
        updatedProducts[key] = updateProduct;
        return {
          ...state,
          products: updatedProducts,
        };
      } else {
        if (!state.products) {
          return {
            ...state,
            products: [action.payload],
          };
        } else {
          return {
            ...state,
            products: [...state.products, action.payload],
          };
        }
      }

    case CartActions.DELETE_CART_PRODUCT_ITEM:
      const delKey = _.findIndex(state.products, (item) => {
        return item.cart_id === action.payload.cart_id;
      });
      console.log("del key", delKey);
      if (delKey !== -1) {
        let newProducts = [...state.products];
        newProducts.splice(delKey, 1);
        return {
          ...state,
          products: newProducts,
        };
      } else {
        return state;
      }

    case CartActions.SET_ONLY_AVAILABLE_FOR_PREORDER_CART_PRODUCTS:
      return {
        ...state,
        onlyAvailableForPreorderCartProductsById: action.payload,
      };

    case CartActions.CLEAR_CART:
      return {
        ...state,
        products: null,
      };

    default:
      return state;
  }
}
