import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import * as fromApp from "../../../store/app.reducer";
import * as ProductOptionsValidationActions from "../../store/productOptionsValidation/productOptionsValidation.actions";

@Injectable({
  providedIn: "root",
})
export class OptionValidationService {
  realTimeValidationErrors = [];
  constructor(
    private store: Store<fromApp.AppState>,
    private translateService: TranslateService
  ) {}

  validateOption(
    option,
    bundle_option_id,
    lastValidTimestamp,
    must_select_something,
    orderPickup,
    fromBundleProduct,
    product_id,
    choice_id,
    productName,
    selectedProductId,
    dineIn
  ) {
    if (option && option.bundle) {
      _.each(option.choices, (ch) => {
        if (
          ch &&
          ch.is_preselected &&
          ch.bundled_with_product_id &&
          ch.bundled_product
        ) {
          _.each(ch.bundled_product.options, (opt) => {
            console.log("call validate opt for bundle product options...", opt);
            this.validateOption(
              opt,
              option.option_id,
              lastValidTimestamp,
              must_select_something,
              orderPickup,
              true,
              ch.bundled_product.product_id,
              ch.choice_id,
              ch.bundled_product.name,
              selectedProductId,
              dineIn
            );
          });
        }
      });
    }
    this.realTimeValidationErrors = [];
    console.log(
      "validateOptionCalled with these",
      option,
      lastValidTimestamp,
      must_select_something
    );

    let checkToOpenOptionObj = this.checkToOpenOption(
      option,
      orderPickup,
      bundle_option_id,
      dineIn
    );

    if (checkToOpenOptionObj.foundOptionToOpen) {
      if (
        must_select_something &&
        !this.check_for_must_select_something(option)
      ) {
        console.log("inside if for must select something");
        if (
          checkToOpenOptionObj.errorArray &&
          checkToOpenOptionObj.errorArray.length > 0
        ) {
          checkToOpenOptionObj.errorArray.push(
            this.translateService.instant(
              "product-details.you-have-to-fill-the-choice"
            )
          );
          this.realTimeValidationErrors.push(
            this.translateService.instant(
              "product-details.you-have-to-fill-the-choice"
            )
          );
        }
      }
      if (!this.checkMaximumChoices(option)) {
        if (this.checkIfMinChoicesEqualToMaxChoices(option)) {
          checkToOpenOptionObj.errorArray.push(
            this.translateService.instant("product-details.choices-required") +
              ": " +
              option.maxChoices
          );
          this.realTimeValidationErrors.push(
            this.translateService.instant(
              "product-details.you-have-to-choose-exactly"
            ) +
              ": " +
              option.maxChoices
          );

          this.writeToOptionValidationState(
            option.option_id,
            bundle_option_id,
            false,
            checkToOpenOptionObj.errorArray,
            true,
            fromBundleProduct,
            product_id,
            choice_id,
            productName,
            selectedProductId
          );
          return { color: false, errorArray: this.realTimeValidationErrors };
        } else {
          checkToOpenOptionObj.errorArray.push(
            this.translateService.instant(
              "product-details.maximum-choices-allowed"
            ) +
              ": " +
              option.maxChoices
          );
          this.realTimeValidationErrors.push(
            this.translateService.instant(
              "product-details.maximum-choices-allowed"
            ) +
              ": " +
              option.maxChoices
          );

          this.writeToOptionValidationState(
            option.option_id,
            bundle_option_id,
            false,
            checkToOpenOptionObj.errorArray,
            true,
            fromBundleProduct,
            product_id,
            choice_id,
            productName,
            selectedProductId
          );
          return {
            valid: false,
            color: false,
            errorArray: this.realTimeValidationErrors,
          };
        }
      } else if (
        !this.checkMinimumChoices(option) &&
        this.checkIfMinChoicesEqualToMaxChoices(option)
      ) {
        this.writeToOptionValidationState(
          option.option_id,
          bundle_option_id,
          false,
          checkToOpenOptionObj.errorArray,
          true,
          fromBundleProduct,
          product_id,
          choice_id,
          productName,
          selectedProductId
        );
        return {
          valid: false,
          color: true,
          errorArray: this.realTimeValidationErrors,
        };
      }
      if (
        checkToOpenOptionObj.errorArray &&
        checkToOpenOptionObj.errorArray.length
      ) {
        this.writeToOptionValidationState(
          option.option_id,
          bundle_option_id,
          false,
          checkToOpenOptionObj.errorArray,
          true,
          fromBundleProduct,
          product_id,
          choice_id,
          productName,
          selectedProductId
        );
        return {
          valid: false,
          color: false,
          errorArray: this.realTimeValidationErrors,
        };
      } else if (!checkToOpenOptionObj.errorArray.length) {
        this.writeToOptionValidationState(
          option.option_id,
          bundle_option_id,
          true,
          checkToOpenOptionObj.errorArray,
          true,
          fromBundleProduct,
          product_id,
          choice_id,
          productName,
          selectedProductId
        );
        return {
          valid: true,
          color: true,
          errorArray: this.realTimeValidationErrors,
        };
      }
    } else {
      if (!this.checkMaximumChoices(option)) {
        if (this.checkIfMinChoicesEqualToMaxChoices(option)) {
          checkToOpenOptionObj.errorArray.push(
            this.translateService.instant("product-details.choices-required") +
              ": " +
              option.maxChoices
          );
          this.realTimeValidationErrors.push(
            this.translateService.instant(
              "product-details.you-have-to-choose-exactly"
            ) +
              ": " +
              option.maxChoices
          );

          this.writeToOptionValidationState(
            option.option_id,
            bundle_option_id,
            false,
            checkToOpenOptionObj.errorArray,
            false,
            fromBundleProduct,
            product_id,
            choice_id,
            productName,
            selectedProductId
          );
          return {
            valid: false,
            color: false,
            errorArray: this.realTimeValidationErrors,
          };
        } else {
          checkToOpenOptionObj.errorArray.push(
            this.translateService.instant(
              "product-details.maximum-choices-allowed"
            ) +
              ": " +
              option.maxChoices
          );
          this.realTimeValidationErrors.push(
            this.translateService.instant(
              "product-details.maximum-choices-allowed"
            ) +
              ": " +
              option.maxChoices
          );

          this.writeToOptionValidationState(
            option.option_id,
            bundle_option_id,
            false,
            checkToOpenOptionObj.errorArray,
            false,
            fromBundleProduct,
            product_id,
            choice_id,
            productName,
            selectedProductId
          );
          return {
            valid: true,
            color: true,
            errorArray: this.realTimeValidationErrors,
          };
        }
      } else {
        if (
          !this.checkMinimumChoices(option) &&
          this.checkIfMinChoicesEqualToMaxChoices(option)
        ) {
          console.log("case of validation !checkmin && checkIfMinEqualToMax");
          this.writeToOptionValidationState(
            option.option_id,
            bundle_option_id,
            false,
            checkToOpenOptionObj.errorArray,
            false,
            fromBundleProduct,
            product_id,
            choice_id,
            productName,
            selectedProductId
          );
          return {
            valid: false,
            color: true,
            errorArray: this.realTimeValidationErrors,
          };
        }
        if (!this.checkMaximumChoices(option)) {
        }

        console.log("product is valid");

        this.writeToOptionValidationState(
          option.option_id,
          bundle_option_id,
          true,
          null,
          false,
          fromBundleProduct,
          product_id,
          choice_id,
          productName,
          selectedProductId
        );

        return {
          valid: true,
          color: true,
          errorArray: this.realTimeValidationErrors,
        };
      }
    }
  }

  check_for_must_select_something(option) {
    var foundOptionWithSelectedChoice = false;

    if (
      option &&
      typeof option === "object" &&
      option.availableTotal &&
      option.activeTotal &&
      (_.isEmpty(option.dependenciesVariable) || option.showDependent)
    ) {
      if (
        _.find(
          _.reject(option.choices, (ch) => {
            return !ch.availableTotal || !ch.activeTotal;
          }),
          {
            is_preselected: true,
          }
        )
      ) {
        foundOptionWithSelectedChoice = true;
      }
    }

    console.log(
      "must_select_something foundOptionWithSelectedChoice",
      foundOptionWithSelectedChoice
    );
    return foundOptionWithSelectedChoice;
  }

  writeToOptionValidationState(
    option_id,
    bundle_option_id,
    valid,
    erroMessages,
    optionToOpen,
    bundle,
    product_id,
    choice_id,
    productName,
    selectedProductId
  ) {
    let optionValidationObject = {
      option_id: option_id,
      bundle_option_id: bundle_option_id,
      product_id: product_id,
      choice_id: choice_id,
      productName: productName,
      selectedProductId: selectedProductId,
      valid: valid,
      errorMessages: erroMessages,
      optionToOpen: optionToOpen,
    };
    console.log(
      "write to validatio state",
      bundle,
      product_id,
      optionValidationObject
    );

    this.store.dispatch(
      new ProductOptionsValidationActions.AddUpdateProductOptionValidation({
        selectedProductId: selectedProductId,
        validationObject: optionValidationObject,
      })
    );
  }

  checkToOpenOption(option, orderPickup, bundle_option_id, dineIn) {
    console.log("checkToOpenOption");
    let optionErrorArray = [];
    var foundOptionToOpen = false;

    var is_require =
      option.is_require &&
      option.availableTotal &&
      option.activeTotal &&
      !this.checkMinimumChoices(option);

    var isDependentAndShownOrNotDependent =
      option &&
      typeof option === "object" &&
      (_.isEmpty(option.dependenciesVariable) || option.showDependent);

    if (
      (isDependentAndShownOrNotDependent &&
        ((!option.always_open &&
          option.autoOpenOption &&
          !option.optionOpened) ||
          is_require ||
          (!option.always_open && !this.checkMaximumChoices(option)))) ||
      (!option.always_open && !this.checkMaximumChoices(option))
    ) {
      foundOptionToOpen = true;
      let errorReturned = this.selectOption(option, orderPickup, dineIn);
      if (errorReturned) {
        optionErrorArray.push(errorReturned);
      }

      console.log("always_open");
      if (is_require) {
        optionErrorArray.push(
          this.translateService.instant("product-details.is-required") +
            ": " +
            option.title
        );
        this.realTimeValidationErrors.push(
          this.translateService.instant("product-details.is-required")
        );
      }
    }

    console.log("checkToOpenOption return", foundOptionToOpen);
    return {
      foundOptionToOpen: foundOptionToOpen,
      errorArray: optionErrorArray,
    };
  }

  selectOption(option, orderPickup, dineIn) {
    let optionErrorArray = [];
    console.log("selectOption");

    if (!option.always_open) {
      if (
        option &&
        typeof option === "object" &&
        option.dependenciesVariable &&
        option.dependenciesVariable.length > 0 &&
        !option.dependentHidden &&
        !option.showDependent
      ) {
        return this.translateService.instant(
          "product-details.first-select-options"
        );
      }

      if (option.pickup ? !orderPickup : false) {
        return this.translateService.instant(
          "product-details.option-is-available-only-for-pickup"
        );
      }

      option.optionOpened = true;

      if (option.type === "checkbox") {
        this.assignFreeChoices(option, orderPickup, dineIn);
        this.calculateTotalChoiceQuantity(option);
      }
    }
  }

  assignFreeChoices(option, orderPickup, dineIn) {
    if (!option.option_id) {
      //There are some options that do not have option_id
      return;
    }

    let optionsFreeChoices = parseInt(option.freeChoices);

    let sortByVariable: any = "price";

    if (option && option.freeChoices && option.freeChoicesBySelectionOrder) {
      sortByVariable = "selected_timestamp";
    }

    if (option && option.freeChoices && option.freeChoicesByPosition) {
      sortByVariable = (choice, index) => index;
    }

    const sortedChoices = _.sortBy(option.choices, (choice: any, index) => {
      if (sortByVariable === "price") {
        if (orderPickup && choice.price_takeaway) {
          sortByVariable = "price_takeway";
          return choice.price_takeaway;
        } else if (dineIn && choice.price_dinein) {
          sortByVariable = "price_dinein";
          return choice.price_dinein;
        }
        return choice.price;
      } else if (typeof sortByVariable === "function") {
        return sortByVariable(choice, index);
      } else {
        return choice[sortByVariable];
      }
    });

    _.each(sortedChoices, (choice: any, key, list) => {
      if (
        dineIn &&
        (!choice.price || choice.price === 0) &&
        (!choice.price_dinein || choice.price_dinein === 0) &&
        option.freeChoicesbyPriceSkipZeros
      ) {
        return;
      }
      if (
        orderPickup &&
        (!choice.price_takeaway || choice.price_takeaway === 0) &&
        (!choice.price || choice.price === 0) &&
        option.freeChoicesbyPriceSkipZeros
      ) {
        return;
      }

      if (
        !orderPickup &&
        !dineIn &&
        (!choice.price || choice.price === 0) &&
        option.freeChoicesbyPriceSkipZeros
      ) {
        return;
      }

      if (
        optionsFreeChoices > 0 &&
        choice.is_preselected &&
        choice.activeTotal &&
        choice.availableTotal
      ) {
        option.choices[
          _.findIndex(option.choices, {
            choice_id: choice.choice_id,
          })
        ].isFree = true;
        if (choice.quantity > 1) {
          if (optionsFreeChoices - choice.quantity >= 0) {
            option.choices[
              _.findIndex(option.choices, {
                choice_id: choice.choice_id,
              })
            ].freeQuantity = choice.quantitchoicesy;
            optionsFreeChoices -= choice.quantity;
          } else {
            option.choices[
              _.findIndex(option.choices, {
                choice_id: choice.choice_id,
              })
            ].freeQuantity = optionsFreeChoices;
            optionsFreeChoices -= optionsFreeChoices;
          }
        } else {
          option.choices[
            _.findIndex(option.choices, {
              choice_id: choice.choice_id,
            })
          ].freeQuantity = 1;
          optionsFreeChoices -= 1;
        }
      } else {
        option.choices[
          _.findIndex(option.choices, {
            choice_id: choice.choice_id,
          })
        ].isFree = false;
        option.choices[
          _.findIndex(option.choices, {
            choice_id: choice.choice_id,
          })
        ].freeQuantity = 0;
      }
    });
    optionsFreeChoices = null;
  }

  calculateTotalChoiceQuantity(option) {
    if (!option) {
      return;
    }

    var choicesQuantity = 0;
    _.each(option.choices, function (choice) {
      if (
        choice.is_preselected &&
        choice.activeTotal &&
        choice.availableTotal
      ) {
        choicesQuantity = choicesQuantity + choice.quantity;
      }
    });

    option.optionData.freeChoices =
      choicesQuantity >= parseInt(option.freeChoices)
        ? option.freeChoices
        : choicesQuantity;
    option.optionData.maxChoices =
      choicesQuantity >= parseInt(option.maxChoices)
        ? option.maxChoices
        : choicesQuantity;
    option.optionData.minChoices =
      choicesQuantity >= parseFloat(option.minChoices)
        ? option.minChoices
        : choicesQuantity;
  }

  checkMinimumChoices(option) {
    console.log("checkMinimumChoices");

    //I need to calculate here the choice quantity!
    console.log("checkMinChoices option", option);
    var optionsMinChoices = parseFloat(option.minChoices);
    var counter = 0;

    if (!_.isNaN(optionsMinChoices) && optionsMinChoices > 0) {
      _.each(_.sortBy(option.choices, "price"), function (choice, key, list) {
        if (
          choice.is_preselected &&
          choice.activeTotal &&
          choice.availableTotal
        ) {
          counter = counter + choice.quantity;
        }
      });

      if (counter < optionsMinChoices) {
        console.log(
          "checkminimumchoices return false",
          counter,
          optionsMinChoices
        );
        return false;
      } else {
        console.log("checkminimumchoices return true");
        return true;
      }
    } else if (
      !_.find(
        _.reject(option.choices, function (ch) {
          return !ch.availableTotal || !ch.activeTotal;
        }),
        {
          is_preselected: true,
        }
      )
    ) {
      return false;
    } else {
      return true;
    }
  }

  private checkIfMinChoicesEqualToMaxChoices(option) {
    if (
      option &&
      option.minChoices &&
      option.minChoices !== "0" &&
      option.minChoices !== "1" &&
      option.maxChoices &&
      option.maxChoices !== "0" &&
      option.maxChoices === option.minChoices
    ) {
      return true;
    }
  }

  checkMaximumChoices(option) {
    //I need to calculate here the choice quantity!

    var optionsMaxChoices;
    var counter = 0;

    if (option && option.maxChoices) {
      optionsMaxChoices = parseInt(option.maxChoices);
    }
    console.log("optionMaxChoices", optionsMaxChoices);
    if (!_.isNaN(optionsMaxChoices) && optionsMaxChoices > 0) {
      _.each(_.sortBy(option.choices, "price"), (choice, key, list) => {
        if (
          choice.is_preselected &&
          choice.activeTotal &&
          choice.availableTotal
        ) {
          counter = counter + choice.quantity;
          console.log("counter", counter);
        }
      });
      console.log(counter, optionsMaxChoices);
      if (counter > optionsMaxChoices) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
}
