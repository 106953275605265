<ion-row
  *ngIf="!selectedProduct || (selectedProduct && !selectedProduct.options)"
>
  <ion-col class="ion-text-center">
    <ion-spinner></ion-spinner>
  </ion-col>
</ion-row>

<ion-list
  *ngIf="
    selectedProduct && selectedProduct.options && selectedProduct.options.length
  "
>
  <ion-row style="width: 100%">
    <ion-col size="12" class="ion-text-center">
      <ion-radio-group
        mode="md"
        [(ngModel)]="selectedOptionId"
        (ionChange)="selectedOptionChanged($event)"
      >
        <span *ngFor="let option of selectedProduct.options; trackBy: trackFnc">
          <ion-radio
            class="radioStyle"
            type="md"
            *ngIf="
              option &&
              option.option_id &&
              (!option.dependenciesVariable ||
                option.dependenciesVariable.length === 0 ||
                (option.dependenciesVariable.length > 0 &&
                  !option.dependentHidden) ||
                (option.dependenciesVariable.length > 0 &&
                  option.dependentHidden &&
                  option.showDependent))
            "
            [value]="option.option_id"
            style="margin: 2px"
          ></ion-radio>
        </span>
      </ion-radio-group>
    </ion-col>
  </ion-row>

  <app-last-step
    [selectedProduct]="selectedProduct"
    *ngIf="
      selectedProduct &&
      selectedProduct.option_stepper &&
      allOptionSteps &&
      allOptionSteps.length &&
      allOptionSteps[allOptionSteps.length - 1] === selectedOptionId
    "
  ></app-last-step>

  <div *ngFor="let option of selectedProduct.options; trackBy: trackFnc">
    <span
      *ngIf="
        option &&
        option.option_id &&
        (!option.dependenciesVariable ||
          option.dependenciesVariable.length === 0 ||
          (option.dependenciesVariable.length > 0 && !option.dependentHidden) ||
          (option.dependenciesVariable.length > 0 &&
            option.dependentHidden &&
            option.showDependent))
      "
    >
      <app-checkbox-option
        [option_stepper_mode]="
          selectedOptionId !== allOptionSteps[allOptionSteps.length - 1]
            ? true
            : false
        "
        [hidden]="selectedOptionId !== option.option_id"
        *ngIf="selectedOptionId && option.type === 'checkbox'"
        [option_id]="option.option_id"
        (checkboxoptionChanged)="selectedProductChanged($event)"
        [must_select_something]="must_select_something"
        [selectedProductCategory]="selectedProduct.category"
        [selectedProductPromo]="selectedProduct.promo"
        [selectedProductId]="selectedProductId"
        [selectedProductFreeParentOption]="selectedProduct.free_parent_option"
        [groupStoreData]="groupStoreData"
      ></app-checkbox-option>
      <app-radio-option
        [option_stepper_mode]="
          selectedOptionId !== allOptionSteps[allOptionSteps.length - 1]
            ? true
            : false
        "
        [hidden]="selectedOptionId !== option.option_id"
        *ngIf="selectedOptionId && option.type === 'radio'"
        [option_id]="option.option_id"
        (radioOptionChanged)="selectedProductChanged($event)"
        [must_select_something]="must_select_something"
        [selectedProductCategory]="selectedProduct.category"
        [selectedProductPromo]="selectedProduct.promo"
        [selectedProductId]="selectedProductId"
        [selectedProductFreeParentOption]="selectedProduct.free_parent_option"
        [groupStoreData]="groupStoreData"
      ></app-radio-option>

      <app-field-option
        [hidden]="selectedOptionId !== option.option_id"
        *ngIf="selectedOptionId && option.type === 'field'"
        [option_id]="option.option_id"
        (fieldOptionChanged)="selectedProductChanged($event)"
        [selectedProductCategory]="selectedProduct.category"
        [selectedProductPromo]="selectedProduct.promo"
        [selectedProductId]="selectedProductId"
        [do_not_show_comments_option]="do_not_show_comments_option"
        [groupStoreData]="groupStoreData"
      ></app-field-option>
    </span>
  </div>
</ion-list>
