import { Component, OnDestroy, OnInit } from "@angular/core";
import * as fromApp from "../store/app.reducer";
import * as _ from "lodash";
import { distinctUntilChanged } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { InvoiceAddEditModalComponent } from "../cart/cart-components/invoice/invoice-add-edit-modal/invoice-add-edit-modal.component";
import {
  AlertController,
  ModalController,
  ToastController,
} from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { DataStorageService } from "../services/data-storage/data-storage.service";
import * as AuthActions from "../auth/store/auth.actions";
import * as selectors from "../store/app.selectors";

@Component({
  selector: "app-invoice-data",
  templateUrl: "./invoice-data.page.html",
  styleUrls: ["./invoice-data.page.scss"],
})
export class InvoiceDataPage implements OnInit, OnDestroy {
  public currentUser: any;
  public dataCustomerInvoice: any;
  public updatingDataLoading: boolean;
  public mo_token: any;
  public storeInfo: any;

  private subscription: Subscription;
  private subscription2: Subscription;
  private invoiceAddEditModal: any;

  constructor(
    private store: Store<fromApp.AppState>,
    private modalCtrl: ModalController,
    private trasnalteService: TranslateService,
    private alertController: AlertController,
    private dataStorageService: DataStorageService,
    private toastController: ToastController,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.dataCustomerInvoice = {
      showDeleteInvoice: false,
      deletingInvoiceIndex: null,
    };
    this.subscription = this.store
      .select("auth")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (state && state.user && !_.isEqual(this.currentUser, state.user)) {
          this.currentUser = _.cloneDeep(state.user);
        }
        if (
          state &&
          state.mo_token &&
          !_.isEqual(this.mo_token, state.mo_token)
        ) {
          this.mo_token = _.cloneDeep(state.mo_token);
        }
      });

    this.subscription2 = this.store
      .select(selectors.getStoreInfo)
      .pipe(distinctUntilChanged())

      .subscribe((storeInfo) => {
        if (storeInfo && !_.isEqual(this.storeInfo, storeInfo)) {
          this.storeInfo = _.cloneDeep(storeInfo);
        }
      });
  }

  trackInvoices(index, item) {
    return item.vatNumber;
  }

  async presentAddEditModal(addOrEdit, invoice) {
    this.invoiceAddEditModal = await this.modalCtrl.create({
      component: InvoiceAddEditModalComponent,
      cssClass: "invoiceAddEditModalCss",
      componentProps: {
        addOrEdit: addOrEdit,
        invoiceForEdit: invoice,
        fromAccountPage: true,
      },
      backdropDismiss: false,
    });

    await this.invoiceAddEditModal.present();
    this.invoiceAddEditModal.onDidDismiss().then((invoice) => {
      console.log(invoice);
      this.invoiceAddEditModal = null;
      if (invoice && invoice.data) {
      }

      //TO_DO ADD THE USER TO STATE
      this.modalCtrl.dismiss();
    });
  }

  ionViewDidEnter() {}

  deleteInvoiceAlert() {}

  // $rootScope.addInvoice = function () {

  //   $scope.addInvoicePopup = $ionicPopup.show({
  //     "templateUrl": 'templates/popups/invoiceData/invoiceDataPopup.html',
  //     "title": $rootScope.languages['EDIT INVOICE DATA'][$rootScope.selectedLanguage],
  //     "scope": $rootScope,
  //     "buttons": [{
  //         "text": $rootScope.languages.CANCEL[$rootScope.selectedLanguage],
  //         "type": "button-dark button-outline",
  //         "onTap": function (e) {
  //           $rootScope.dataCustomerInvoice.deletingInvoiceIndex = null;

  //           $rootScope.currentUser.invoiceData = _.filter($rootScope.currentUser.invoiceData, function (invoice) {
  //             return invoice.vatNumber && _.has(invoice, 'checked') && invoice.activity && invoice.vatOffice && invoice.company_name && invoice.company_address;
  //           });

  //           if ($rootScope.currentUser && $rootScope.currentUser.invoiceData && $rootScope.currentUser.invoiceData.length === 0) {
  //             $rootScope.customerInvoice.enabled = false;
  //             if ($rootScope.cookieEnabled) {
  //               window.localStorage.setItem("customerInvoice", JSON.stringify($rootScope.customerInvoice));
  //             }
  //           }

  //           //filter invoiceData
  //           $rootScope.updateCustomerData(true, function () {

  //           });

  //         }
  //       },
  //       {
  //         "text": $rootScope.languages.SAVE[$rootScope.selectedLanguage],
  //         "type": "button-positive",
  //         "onTap": function (e) {

  //           e.preventDefault();

  //           console.log("invoiceData", $rootScope.currentUser.invoiceData[$rootScope.currentInvoiceData]);

  //           if ($rootScope.currentUser.invoiceData[$rootScope.currentInvoiceData].vatNumber && !_.has($rootScope.currentUser.invoiceData[$rootScope.currentInvoiceData], 'checked')) {

  //             if ($rootScope.currentUser.invoiceData[$rootScope.currentInvoiceData].vatNumber.toString().length > 8) {

  //               $rootScope.updateCustomerData(true, function () {

  //               });

  //             } else {
  //               Toast.show($rootScope.languages["PLEASE FILL VAT NUMBER CORRECTLY"][$rootScope.selectedLanguage]);
  //             }

  //           } else if ($rootScope.currentUser.invoiceData[$rootScope.currentInvoiceData].vatNumber && $rootScope.currentUser.invoiceData[$rootScope.currentInvoiceData].activity && $rootScope.currentUser.invoiceData[$rootScope.currentInvoiceData].vatOffice && $rootScope.currentUser.invoiceData[$rootScope.currentInvoiceData].company_name && $rootScope.currentUser.invoiceData[$rootScope.currentInvoiceData].company_address) {

  //             $rootScope.updateCustomerData(true, function () {
  //               $rootScope.dataCustomerInvoice.showDeleteInvoice = false;

  //               $rootScope.selectInvoice($rootScope.currentInvoiceData);

  //               $scope.addInvoicePopup.close();
  //             });

  //           } else {

  //             Toast.show($rootScope.languages["PLEASE FILL ALL THE FIELDS"][$rootScope.selectedLanguage]);

  //           }

  //         }
  //       }
  //     ]
  //   });
  // };

  // $rootScope.selectInvoice = function (index) {

  //   $rootScope.customerInvoice = _.extend($rootScope.currentUser.invoiceData[index], {
  //     enabled: true
  //   });

  //   if ($rootScope.selectInvoicePopup) {
  //     $rootScope.selectInvoicePopup.close();
  //   }

  //   console.log("$rootScope.customerInvoice", $rootScope.customerInvoice);

  //   if ($rootScope.cookieEnabled) {
  //     window.localStorage.setItem("customerInvoice", JSON.stringify($rootScope.customerInvoice));
  //   }
  // };

  async deleteInvoice(index) {
    if (this.dataCustomerInvoice.deletingInvoiceIndex === null) {
      this.dataCustomerInvoice.deletingInvoiceIndex = index;

      const alert = await this.alertController.create({
        header: this.trasnalteService.instant("invoice-data.attention!"),
        message: this.trasnalteService.instant(
          "invoice-data.do-you-want-to-delete-your-invoice-data"
        ),
        buttons: [
          {
            text: this.trasnalteService.instant("invoice-data.cancel"),
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              console.log("Confirm Cancel: blah");
            },
          },
          {
            text: "OK",
            handler: () => {
              console.log("Confirm Okay");
              let newUser = _.cloneDeep(this.currentUser);
              this.dataCustomerInvoice.deletingInvoiceIndex = null;
              newUser.invoiceData.splice(index, 1);

              if (
                this.currentUser &&
                this.currentUser.invoiceData &&
                this.currentUser.invoiceData.length === 0
              ) {
                this.currentUser;
                //TO_DO CHECK INVOICE VALID
              }

              //TO_DO this.checkCustomerInvoiceValid();
              let obj = { updatedData: newUser };
              this.updateCustomerData(obj, this.mo_token, true);
            },
          },
        ],
      });

      await alert.present();
    } else {
      console.log("already opened popup");
    }
  }

  updateCustomerData(obj, mo_token, show_success_message) {
    if (show_success_message) {
      this.updatingDataLoading = true;
    }

    this.dataStorageService.updateCustomerData(obj, mo_token).subscribe({
      next: (res: any) => {
        if (res && res.success) {
          let updatedUser = res.data;
          this.store.dispatch(
            new AuthActions.SetUser(_.cloneDeep(updatedUser))
          );
          if (show_success_message && res.comment_id) {
            this.presentErrorToast(
              this.translateService.instant(
                "account-settings." + res.comment_id
              ),
              "primary"
            );
          }

          this.updatingDataLoading = false;
        } else {
          if (res.comment_id) {
            this.presentErrorToast(
              this.translateService.instant(res.comment_id),
              "danger"
            );
          } else if (res.comments) {
            this.presentErrorToast(res.comments, "danger");
          }
          this.updatingDataLoading = false;
        }
      },
      error: (error) => {
        let errMsg = this.translateService.instant(
          "errorMessages.error: There was an error on saving"
        );
        this.updatingDataLoading = false;
        this.presentErrorToast(errMsg, "danger");
        return error;
        console.log("error updating the information");
      },
    });
  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscription2) {
      this.subscription2.unsubscribe();
    }
    if (this.invoiceAddEditModal) {
      this.invoiceAddEditModal.dismiss();
      this.invoiceAddEditModal = null;
    }
  }

  ionViewDidLeave() {}
}
