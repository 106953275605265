import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ModalController, Platform, ToastController } from "@ionic/angular";
import * as fromApp from "../../store/app.reducer";
import * as selectors from "../../store/app.selectors";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import { distinctUntilChanged, first, Subscription } from "rxjs";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-catalog-disclaimer-popup",
  templateUrl: "./catalog-disclaimer-popup.component.html",
  styleUrls: ["./catalog-disclaimer-popup.component.scss"],
})
export class CatalogDisclaimerPopupComponent implements OnInit, OnDestroy {
  public catalogDisclaimer: any;
  public selectedLanguage: any;
  public groupApp: any;
  public mo_base: any;
  public static_pages: any;

  private staticPagesSub: Subscription;
  private groupSubscription: Subscription;
  private unsubscribeBackEvent: Subscription;

  constructor(
    private modalController: ModalController,
    private toastController: ToastController,
    private platform: Platform,
    private store: Store<fromApp.AppState>
  ) {}

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );

    this.groupSubscription = this.store
      .select("groupApp")
      .subscribe((state) => {
        if (
          state &&
          state.selectedLangugage &&
          !_.isEqual(this.selectedLanguage, state.selectedLangugage)
        ) {
          this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
        }

        if (
          state &&
          state.groupApp &&
          !_.isEqual(this.groupApp, state.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp);

          this.mo_base = _.cloneDeep(
            _.find(state.groupApp.languages, {
              mo_base: true,
            })
          );
        }
      });

    this.staticPagesSub = this.store
      .select("staticPages")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (state && !_.isEqual(state.static_pages, this.static_pages)) {
          this.static_pages = _.cloneDeep(state.static_pages);

          if (this.static_pages) {
            this.catalogDisclaimer = this.static_pages.catalogDisclaimer;
          }
        }
      });
  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }

  ngOnDestroy(): void {
    if (this.groupSubscription) {
      this.groupSubscription.unsubscribe();
    }

    if (this.staticPagesSub) {
      this.staticPagesSub.unsubscribe();
    }

    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
  }

  closeModal() {
    this.modalController.dismiss();
  }
}
