<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">
      <ion-icon
        color="primary"
        style="vertical-align: bottom; font-size: 15px; margin-right: 3px"
        src="assets/ionicons/information-circle-outline.svg"
      >
      </ion-icon>
      <ion-text color="primary">
        {{ "popups.alergens-modal.title" | translate }}
      </ion-text>
    </ion-title>
    <ion-button
      (click)="closeModal()"
      size="small"
      slot="end"
      fill="clear"
      color="dark"
    >
      <ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid class="ion-padding">
    <ion-row style="width: 100%" *ngIf="!static_pages">
      <ion-col class="ion-text-center"><ion-spinner> </ion-spinner> </ion-col>
    </ion-row>
    <div
      *ngIf="
        static_pages &&
        static_pages.allergens &&
        static_pages.allergens[mo_base_lang]
      "
      [innerHTML]="
        static_pages.allergens[selectedLangugage]
          ? static_pages.allergens[selectedLangugage]
          : static_pages.allergens[mo_base_lang]
      "
    ></div>
  </ion-grid>
</ion-content>
