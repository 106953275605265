<ion-header>
  <ion-toolbar color="black">
    <ion-title color="white" mode="md">{{
      "homepage.magic-login" | translate
    }}</ion-title>
    <ion-button color="white" (click)="closeModal()" slot="end" fill="clear">
      <ion-icon src="assets/ionicons/close.svg"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content scroll-x="false" scroll-y="false">
  <ion-list
    *ngIf="
      windowDimensions &&
      windowDimensions.height &&
      windowDimensions.height >= 345 &&
      newCustomer &&
      !currentUser
    "
  >
    <ion-item color="none" lines="none">
      <ion-row class="ion-text-center">
        <!-- <ion-col size="12" class="ion-padding">
          <img alt="paper-plane" width="60" height="60" size="small" src="assets/flatIcons/paper-plane.svg" />
        </ion-col> -->
        <ion-col size="12">
          <h4 style="color: white; padding: 0px; margin: 0px">
            {{ "popups.magic-login-confirmation.we-just-send" | translate
            }}{{
              newCustomer.gateway === "sms"
                ? " SMS"
                : ("popups.magic-login-confirmation.we-just-send" | translate) +
                  " Viber"
            }}
          </h4>
        </ion-col>
        <ion-col size="12">
          <h4 style="color: white; padding: 0px; margin: 0px">
            {{ newCustomer.mobile }}
          </h4>
        </ion-col>
      </ion-row>
    </ion-item>
  </ion-list>

  <ion-list style="height: 100%" class="ion-no-padding ion-no-margin">
    <ion-row
      [class]="
        windowDimensions &&
        windowDimensions.height &&
        windowDimensions.height >= 345
          ? ''
          : 'ion-justify-content-center ion-align-items-center width-100'
      "
    >
      <ion-col size="12" class="ion-text-center ion-no-padding">
        <ion-button
          fill="solid"
          lines="none"
          color="white"
          size="small"
          detail="false"
          *ngIf="!currentUser"
          class="ion-text-center"
          (click)="onResend()"
        >
          {{ "popups.magic-login-confirmation.resend-sms" | translate }}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-list>
</ion-content>

<ion-footer>
  <ion-list style="padding: 0px 16px 10px 16px">
    <ion-row
      style="width: 100%"
      color="white"
      *ngIf="!currentUser && newCustomer && showOtpComponent"
      class="input-item"
    >
      <!-- <ion-label position="fixed">
        {{ "popups.magic-login-confirmation.password" | translate }}</ion-label> -->
      <ion-col
        size="12"
        class="ion-text-center"
        style="padding-left: 0px; padding-right: 0px"
      >
        <ion-text style="color: white; padding: 0px; margin: 0px">
          {{
            "popups.magic-login-confirmation.please-type-the-six-digit-code"
              | translate
          }}
        </ion-text>
      </ion-col>
      <ion-col
        class="ion-text-center"
        style="padding-left: 0px; padding-right: 0px"
      >
        <ng-otp-input
          #ngOtpInput
          #ngOtpInputRef
          (onInputChange)="onChange()"
          [config]="config"
          (keydown)="handleEnterButton($event)"
        >
        </ng-otp-input>
      </ion-col>
      <!-- <ion-input
        type="tel"
        name="number"
        placeholder="{{
          'popups.magic-login-confirmation.write-here' | translate
        }}"
        [(ngModel)]="newCustomer.password"
        (ionChange)="onChange()"
      ></ion-input> -->
      <ion-col size="12" class="ion-text-center">
        <ion-button
          *ngIf="!currentUser && groupApp"
          color="white"
          [disabled]="loading || !codeFull"
          (click)="onClickOk()"
        >
          <ion-text *ngIf="!loading">OK</ion-text>
          <ion-spinner style="color: black" *ngIf="loading"></ion-spinner>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-list>
</ion-footer>
