import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import * as fromApp from "../../store/app.reducer";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import { Router } from "@angular/router";
import { distinctUntilChanged } from "rxjs/operators";
import { Subscription } from "rxjs";

@Component({
  selector: "app-menu-footer-tabs-complete-order",
  templateUrl: "./menu-footer-tabs-complete-order.component.html",
  styleUrls: ["./menu-footer-tabs-complete-order.component.scss"],
})
export class MenuFooterTabsCompleteOrderComponent
  implements OnInit, OnChanges, OnDestroy
{
  @ViewChild("footer", { static: false }) footer: any;
  @Input() scrollFromTop: any;
  @Input() scrollFromBottom: any;
  @Input() imageBrowser: any;

  public playAnimationFadeIn: boolean;
  public playAnimationFadeOut: boolean;
  public windowDimensions: any;
  public groupApp: any;
  public cartProducts: any;
  public cartPrices: any;
  public disableFinalPrice;
  public hideComponent = true;
  background;

  private hideComponentTimeout;
  private selectedCategoryId;
  private firstTime;
  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private store: Store<fromApp.AppState>,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.scrollFromTop &&
      changes.scrollFromTop.currentValue !== changes.scrollFromTop.previousValue
    ) {
      this.playAnimationFadeIn = false;
      this.playAnimationFadeOut = true;
      this.changeDetector.detectChanges();
      this.changeHideComponent(true);
    }
  }

  changeHideComponent(value) {
    if (this.hideComponentTimeout) {
      clearTimeout(this.hideComponentTimeout);
      this.hideComponentTimeout = null;
    }

    this.hideComponentTimeout = setTimeout(() => {
      this.hideComponent = value;
      this.changeDetector.detectChanges();
    }, 1000);
  }

  ngOnInit() {
    const el = document.documentElement;
    if (el) {
      this.background = el.style.getPropertyValue("--catalog-background-image");
      console.log("gggbackground", this.background);
      if (!this.background) {
        this.background = "var(--ion-background-color)";
      }
    } else {
      this.background = "var(--ion-background-color)";
    }
    this.subscriptions.push(
      this.store
        .select("verticalCategory")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.selectedCategoryId, state.selectedCategoryId)
          ) {
            this.selectedCategoryId = _.cloneDeep(state.selectedCategoryId);
            // console.log("selected cat changed");
            // if (
            //   this.selectedCategoryId &&
            //   this.cartProducts &&
            //   this.cartProducts.length > 0
            // ) {
            //   this.showButton = true;
            //   this.stopScrollButtonChange = true;
            // }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("cart")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && !_.isEqual(this.cartProducts, state.products)) {
            if (!this.cartProducts) {
              setTimeout(() => {
                this.firstTime = false;
              }, 2000);
            }
            this.cartProducts = _.cloneDeep(state.products);
            console.log("dajksdjklasd", this.cartProducts);
            if (
              this.cartProducts &&
              this.cartProducts.length &&
              this.cartProducts.length > 0
            ) {
              this.playAnimationFadeOut = false;
              this.playAnimationFadeIn = true;

              this.changeHideComponent(false);

              //this.showButton = false;
            } else if (this.cartProducts && !this.cartProducts.length) {
              this.playAnimationFadeOut = true;
              this.playAnimationFadeIn = false;

              this.changeHideComponent(true);
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.windowDimensions &&
            !_.isEqual(state.windowDimensions, this.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(state.groupApp, this.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("cartPrices")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.cartPrices &&
            !_.isEqual(state.cartPrices, this.cartPrices)
          ) {
            this.cartPrices = _.cloneDeep(state.cartPrices);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("disableFinalPrice")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(state.disableFinalPrice, this.disableFinalPrice)
          ) {
            this.disableFinalPrice = _.cloneDeep(state.disableFinalPrice);
          }
        })
    );
  }

  navigateCart() {
    this.router.navigateByUrl("/cart", {
      replaceUrl: true,
    });
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    this.subscriptions = [];
  }
}
