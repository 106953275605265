<div >
  <!-- <ion-row style="width: 100%" *ngIf="!showCropper">
    <ion-col class="ion-text-center">
      <ion-spinner></ion-spinner>
    </ion-col>
  </ion-row> -->
  <ion-grid style="max-width: 1000px;">
    <ion-row >
      <ion-col class="ion-text-center">
        <label for="file-upload" class="custom-file-upload">
          <ion-icon name="cloud-upload"></ion-icon> Upload your photo
        </label>
        <input id="file-upload" type="file" accept="image/png, image/jpeg, image/webp" (change)="onFileChanged($event)">
      </ion-col>
    </ion-row>
    <ion-row style="width: 100%" class="ion-align-items-center ion-justify-content-center">
      <ion-col size="6" >
        <image-cropper
          [imageChangedEvent]="imageChangedEvent"
          [aspectRatio]="4/4"
          [maintainAspectRatio]="true"
          [containWithinAspectRatio]="containWithinAspectRatio"
          [cropperMinWidth]="128"
          [onlyScaleDown]="true"
          [roundCropper]="true"
          [canvasRotation]="canvasRotation"
          [transform]="transform"
          [alignImage]="'center'"
          [style.display]="showCropper ? null : 'none'"
          (imageLoaded)="imageLoaded()"
          (cropperReady)="cropperReady($event)"
          (imageCropped)="imageCropped($event)"
          (loadImageFailed)="loadImageFailed()"
        ></image-cropper>
      </ion-col>
      <ion-col *ngIf="showCropper && windowDimensions.width > 900" class="ion-text-center" size="6" style="border-left: solid 1px;">
        <ion-list>
          <ion-button
            color="primary"
            [disabled]="onSaveDisable"
            fill="clear"
            (click)="zoomIn()"
            ><ion-icon
              color="primary"
              slot="icon-only"
              src="assets/flatIcons/zoom-in.svg"
            ></ion-icon
          ></ion-button>
          <ion-button
            color="primary"
            [disabled]="onSaveDisable"
            fill="clear"
            (click)="zoomOut()"
            ><ion-icon
              color="primary"
              slot="icon-only"
              src="assets/flatIcons/zoom-out.svg"
            ></ion-icon
          ></ion-button>
        </ion-list>
  
        <ion-list>
          <div>
            <img
              style="border-radius: 50%"
              [src]="croppedImage"
              [style.border]="croppedImage ? '1px solid black' : 'none'"
            />
          </div>
          <ion-button
            [disabled]="onSaveDisable"
            color="danger"
            (click)="Saveimage()"
            >{{ "account-settings.crop-image" | translate }}</ion-button
          >
        </ion-list>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="showCropper && windowDimensions.width <= 900" class="ion-align-items-center ion-justify-content-center">
      <ion-col class="ion-text-center" size="12">
        <ion-list>
          <ion-button
            color="primary"
            [disabled]="onSaveDisable"
            fill="clear"
            (click)="zoomIn()"
            ><ion-icon
              color="primary"
              slot="icon-only"
              src="assets/flatIcons/zoom-in.svg"
            ></ion-icon
          ></ion-button>
          <ion-button
            color="primary"
            [disabled]="onSaveDisable"
            fill="clear"
            (click)="zoomOut()"
            ><ion-icon
              color="primary"
              slot="icon-only"
              src="assets/flatIcons/zoom-out.svg"
            ></ion-icon
          ></ion-button>
        </ion-list>
  
        <ion-list>
          <div>
            <img
              style="border-radius: 50%"
              [src]="croppedImage"
              [style.border]="croppedImage ? '1px solid black' : 'none'"
            />
          </div>
          <ion-button
            [disabled]="onSaveDisable"
            color="danger"
            (click)="Saveimage()"
            >{{ "account-settings.crop-image" | translate }}</ion-button
          >
        </ion-list>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="uploadProgress">
      <ion-col>
        <ion-progress-bar [value]="uploadProgress">

        </ion-progress-bar>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
