<ion-list
  class="ion-padding"
  *ngIf="
    groupApp &&
    groupApp.pointsSystem &&
    groupApp.points &&
    groupApp.points.rules
  "
>
  <ion-card *ngFor="let rule of groupApp.points.rules">
    <img *ngIf="rule.image" src="{{ rule.image }}" />
    <ion-item lines="none">
      <ion-label class="ion-text-wrap ion-text-center">
        <h2>{{ rule.title }}</h2>
        <p *ngIf="rule.description">
          {{ rule.description }}
        </p>
      </ion-label>
    </ion-item>
  </ion-card>

  <ion-card *ngIf="pointsFactorsActive">
    <ion-item
      color="primary"
      style="margin-bottom: 10px"
      *ngIf="stores && stores.length > 1 && !sameFactorRulesBetweenStores"
    >
      <ion-label class="ion-text-wrap">{{
        "store-schedule.select-store" | translate
      }}</ion-label>
      <ion-select
        (ionChange)="changeStoreEv($event)"
        [(ngModel)]="selectedStoreId"
        [cancelText]="'cancel' | translate"
      >
        <ion-select-option
          *ngFor="let store of stores"
          [value]="store.store_id"
        >
          {{
            store.store_name_mobileorder &&
            store.store_name_mobileorder[selectedLangugage]
              ? store.store_name_mobileorder[selectedLangugage]
              : store.store_panel_name_langs &&
                store.store_panel_name_langs[selectedLangugage]
              ? store.store_panel_name_langs[selectedLangugage]
              : store.store_panel_name_langs && store.store_panel_name_langs.el
              ? store.store_panel_name_langs.el
              : store.store_name_panel
              ? store.store_name_panel
              : store.store_name
              ? store.store_name
              : ""
          }}
        </ion-select-option>
      </ion-select>
    </ion-item>
    <ion-item
      lines="full"
      *ngIf="
        (!pointsDisableRulesArr && !disablePointsInStores) ||
        (pointsFactorsRulesArr && pointsFactorsRulesArr.length)
      "
    >
      <ion-label
        class="ion-text-center ion-text-wrap"
        style="font-weight: bold; font-size: medium"
      >
        {{ "points.rules.pointsFactorsTitle" | translate }}
      </ion-label>
    </ion-item>

    <ion-item
      lines="none"
      *ngIf="
        pointsFactorsRulesArr &&
        !pointsFactorsRulesArr.length &&
        !pointsDisableRulesArr
      "
    >
      <ion-label
        class="ion-text-center ion-text-wrap"
        style="font-weight: 500; font-size: medium"
      >
        {{
          disablePointsInStores
            ? ("points.rules.there-are-no-special-point-rules-for-store"
              | translate)
            : ("points.rules.there-are-no-points-factors-for-store" | translate)
        }}
      </ion-label>
    </ion-item>
    <ion-item
      class="noPaddingsItem"
      lines="none"
      *ngFor="
        let factorPointRule of pointsFactorsRulesArr
          | filterPointsFactorRulesByOrderType : 'delivery';
        let index = index
      "
    >
      <ion-label class="ion-text-wrap">
        <div
          style="margin-bottom: 15px; margin-top: 15px"
          class="ion-text-center"
          *ngIf="index === 0"
        >
          <strong>
            {{ "points.rules.order" | translate }}
            {{
              factorPointRule.order_type
                | factorPointRuleOrderType
                  : storeInfo
                  : selectedLangugage
                  : mo_base
                | translate
            }}
          </strong>
        </div>

        <div style="font-size: 0.95em">
          {{ "points.rules." + factorPointRule.platform | translate }}:
          {{ (factorPointRule.factor * 100).toFixed(0) + "%" }}
          {{ "points.rules.points" | translate }}
        </div>
      </ion-label>
    </ion-item>

    <ion-item
      class="noPaddingsItem"
      lines="none"
      *ngFor="
        let factorPointRule of pointsFactorsRulesArr
          | filterPointsFactorRulesByOrderType : 'takeaway';
        let index = index
      "
    >
      <ion-label class="ion-text-wrap">
        <div
          style="margin-bottom: 15px; margin-top: 15px"
          class="ion-text-center"
          *ngIf="index === 0"
        >
          <strong>
            {{ "points.rules.order" | translate }}
            {{
              factorPointRule.order_type
                | factorPointRuleOrderType
                  : storeInfo
                  : selectedLangugage
                  : mo_base
                | translate
            }}
          </strong>
        </div>

        <div style="font-size: 0.95em">
          {{ "points.rules." + factorPointRule.platform | translate }}:
          {{ (factorPointRule.factor * 100).toFixed(0) + "%" }}
          {{ "points.rules.points" | translate }}
        </div>
      </ion-label>
    </ion-item>
    <ion-item
      class="noPaddingsItem"
      lines="none"
      *ngFor="
        let factorPointRule of pointsFactorsRulesArr
          | filterPointsFactorRulesByOrderType : 'dinein';
        let index = index
      "
    >
      <ion-label class="ion-text-wrap">
        <div
          style="margin-bottom: 15px; margin-top: 15px"
          class="ion-text-center"
          *ngIf="index === 0"
        >
          <strong>
            {{ "points.rules.order" | translate }}
            {{
              factorPointRule.order_type
                | factorPointRuleOrderType
                  : storeInfo
                  : selectedLangugage
                  : mo_base
                | translate
            }}
          </strong>
        </div>

        <div style="font-size: 0.95em">
          {{ "points.rules." + factorPointRule.platform | translate }}:
          {{ (factorPointRule.factor * 100).toFixed(0) + "%" }}
          {{ "points.rules.points" | translate }}
        </div>
      </ion-label>
    </ion-item>
    <ion-item
      class="noPaddingsItem"
      lines="none"
      *ngFor="
        let factorPointRule of pointsFactorsRulesArr
          | filterPointsFactorRulesByOrderType : 'altdinein';
        let index = index
      "
    >
      <ion-label class="ion-text-wrap">
        <div
          style="margin-bottom: 15px; margin-top: 15px"
          class="ion-text-center"
          *ngIf="index === 0"
        >
          <strong>
            {{ "points.rules.order" | translate }}
            {{
              factorPointRule.order_type
                | factorPointRuleOrderType
                  : storeInfo
                  : selectedLangugage
                  : mo_base
                | translate
            }}
          </strong>
        </div>

        <div style="font-size: 0.95em">
          {{ "points.rules." + factorPointRule.platform | translate }}:
          {{ (factorPointRule.factor * 100).toFixed(0) + "%" }}
          {{ "points.rules.points" | translate }}
        </div>
      </ion-label>
    </ion-item>

    <ion-item
      lines="full"
      *ngIf="
        pointsDisableRulesArr &&
        pointsFactorsRulesArr &&
        pointsDisableRulesArr.length &&
        pointsFactorsRulesArr.length
      "
    >
      <ion-label
        class="ion-text-center ion-text-wrap"
        style="font-weight: bold; font-size: medium"
      >
        {{ "points.rules.special-point-rules" | translate }}
      </ion-label>
    </ion-item>

    <ion-item
      class="noPaddingsItem"
      lines="none"
      *ngFor="
        let factorPointRule of pointsDisableRulesArr
          | filterPointsFactorRulesByOrderType : 'delivery';
        let index = index
      "
    >
      <ion-label class="ion-text-wrap">
        <div
          style="margin-bottom: 15px; margin-top: 15px"
          class="ion-text-center"
          *ngIf="index === 0"
        >
          <strong>
            {{ "points.rules.order" | translate }}
            {{
              factorPointRule.order_type
                | factorPointRuleOrderType
                  : storeInfo
                  : selectedLangugage
                  : mo_base
                | translate
            }}
          </strong>
        </div>

        <div style="font-size: 0.95em">
          {{
            "points.rules.disabledRules." + factorPointRule.platform | translate
          }}

          {{ "points.rules.points" | translate }}
        </div>
      </ion-label>
    </ion-item>

    <ion-item
      class="noPaddingsItem"
      lines="none"
      *ngFor="
        let factorPointRule of pointsDisableRulesArr
          | filterPointsFactorRulesByOrderType : 'takeaway';
        let index = index
      "
    >
      <ion-label class="ion-text-wrap">
        <div
          style="margin-bottom: 15px; margin-top: 15px"
          class="ion-text-center"
          *ngIf="index === 0"
        >
          <strong>
            {{ "points.rules.order" | translate }}
            {{
              factorPointRule.order_type
                | factorPointRuleOrderType
                  : storeInfo
                  : selectedLangugage
                  : mo_base
                | translate
            }}
          </strong>
        </div>

        <div style="font-size: 0.95em">
          {{
            "points.rules.disabledRules." + factorPointRule.platform | translate
          }}
          {{ "points.rules.points-disabled" | translate }}
        </div>
      </ion-label>
    </ion-item>
    <ion-item
      class="noPaddingsItem"
      lines="none"
      *ngFor="
        let factorPointRule of pointsDisableRulesArr
          | filterPointsFactorRulesByOrderType : 'dinein';
        let index = index
      "
    >
      <ion-label class="ion-text-wrap">
        <div
          style="margin-bottom: 15px; margin-top: 15px"
          class="ion-text-center"
          *ngIf="index === 0"
        >
          <strong>
            {{ "points.rules.order" | translate }}
            {{
              factorPointRule.order_type
                | factorPointRuleOrderType
                  : storeInfo
                  : selectedLangugage
                  : mo_base
                | translate
            }}
          </strong>
        </div>
        <div style="font-size: 0.95em">
          {{
            "points.rules.disabledRules." + factorPointRule.platform | translate
          }}
          {{ "points.rules.points-disabled" | translate }}
        </div>
      </ion-label>
    </ion-item>
    <ion-item
      class="noPaddingsItem"
      lines="none"
      *ngFor="
        let factorPointRule of pointsDisableRulesArr
          | filterPointsFactorRulesByOrderType : 'altdinein';
        let index = index
      "
    >
      <ion-label class="ion-text-wrap">
        <div
          style="margin-bottom: 15px; margin-top: 15px"
          class="ion-text-center"
          *ngIf="index === 0"
        >
          <strong>
            {{ "points.rules.order" | translate }}
            {{
              factorPointRule.order_type
                | factorPointRuleOrderType
                  : storeInfo
                  : selectedLangugage
                  : mo_base
                | translate
            }}
          </strong>
        </div>

        <div style="font-size: 0.95em">
          {{
            "points.rules.disabledRules." + factorPointRule.platform | translate
          }}
          {{ "points.rules.points-disabled" | translate }}
        </div>
      </ion-label>
    </ion-item>
  </ion-card>
</ion-list>
