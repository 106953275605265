<ion-header
  *ngIf="windowInfo"
  [hidden]="windowInfo && windowInfo.width && menuMinWidthToShow &&  windowInfo.width> menuMinWidthToShow"
>
  <app-no-internet-connection></app-no-internet-connection>
  <app-ionic-update-error></app-ionic-update-error>
  <ion-toolbar>
    <ion-title>{{"pages.points" | translate}}</ion-title>
    <ion-buttons slot="start">
      <ion-button
        *ngIf="onlyLoyaltyApp"
        (click)="pageChange('loyaltySystem/loyalty-more')"
      >
        <ion-icon name="arrow-back-outline"></ion-icon>
      </ion-button>
      <ion-menu-button
        *ngIf="(!onlyLoyaltyApp && groupApp && windowInfo) || onlyLoyaltyApp"
        color="dark"
      ></ion-menu-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="windowInfo">
  <ion-grid
    *ngIf="usePointsAnimation"
    style="width: 100%; height: 100%; position: absolute; top: 0; z-index: 1000"
  >
    <ion-row
      style="width: 100%; height: 100%"
      class="ion-align-items-center ion-justify-content-center"
    >
      <ion-col class="ion-align-self-center ion-text-center">
        <ion-grid style="max-width: 350px">
          <ng-lottie
            (animationCreated)="animationCreated($event)"
            (loopComplete)="animationComplete($event)"
            [options]="usePointsSuccessAnimationOptions"
          ></ng-lottie>
        </ion-grid>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid
    [class]="windowInfo && windowInfo.width && menuMinWidthToShow &&  windowInfo.width< menuMinWidthToShow ? 'pointsPageGrid': 'ion-padding pointsPageGrid'"
  >
    <ion-segment
      color="primary"
      [value]="segmentValue"
      (ionChange)="handleSegmentChange($event)"
      scrollable
    >
      <ion-segment-button value="rules"
        >{{"points.gather" | translate}}</ion-segment-button
      >
      <ion-segment-button value="rewards"
        >{{"points.gifts" | translate}}</ion-segment-button
      >
      <ion-segment-button value="history"
        >{{"points.history-title" | translate}}</ion-segment-button
      >
      <ion-segment-button value="profile"
        >{{"points.profile-title" | translate}}</ion-segment-button
      >
    </ion-segment>

    <ion-grid
      *ngIf="loading || (!loginState || (loginState && loginState==='login_loading'))"
    >
      <ion-row style="width: 100%">
        <ion-col class="ion-text-center">
          <ion-spinner></ion-spinner>
        </ion-col>
      </ion-row>
    </ion-grid>

    <app-rules
      class="ion-padding"
      [userPoints]="userPoints"
      *ngIf=" currentTab==='rules' && !loading && loginState && ((loginState==='login_completed' && currentUser) || loginState==='no_login')"
    >
    </app-rules>
    <app-rewards
      class="ion-padding"
      (successUsePointsEvent)="successUsePointsEvent($event)"
      [userPoints]="userPoints"
      *ngIf=" currentTab==='rewards' && !loading && loginState && ((loginState==='login_completed' && currentUser)  || loginState==='no_login')"
    >
    </app-rewards>
    <app-history
      class="ion-padding"
      [userPoints]="userPoints"
      *ngIf="currentTab==='history' && !loading && loginState && ((loginState==='login_completed' && currentUser)  || loginState==='no_login')"
    >
    </app-history>
    <app-profile
      class="ion-padding"
      [userPoints]="userPoints"
      *ngIf="currentTab==='profile' && !loading && loginState && ((loginState==='login_completed' && currentUser) || loginState==='no_login')"
    >
    </app-profile>
  </ion-grid>
  <div
    *ngIf="groupApp &&
      groupApp.design_settings &&
      groupApp.design_settings.menuFooterTabs && windowInfo && windowInfo.width<900"
    style="margin-top: 65px"
  ></div>
</ion-content>

<app-menu-footer-tabs></app-menu-footer-tabs>
