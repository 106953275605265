import { Injectable } from "@angular/core";
import {
  AlertController,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { FirebaseRecaptchaLoginComponent } from "src/app/popups/firebase-recaptcha-login/firebase-recaptcha-login.component";
import { MagicLoginConfirmationComponent } from "src/app/popups/magic-login-confirmation/magic-login-confirmation.component";
import * as AuthActions from "../../auth/store/auth.actions";
import * as fromApp from "../../store/app.reducer";
import * as _ from "lodash";
import { AnalyticsServiceService } from "../analitycsService/analytics-service.service";
import { first } from "rxjs";
import { FirebaseRecaptchaLoginReservationActiveComponent } from "src/app/popups/firebase-recaptcha-login-reservation-active/firebase-recaptcha-login-reservation-active.component";
import { MagicLoginConfirmationReservationActiveComponent } from "src/app/popups/magic-login-confirmation-reservation-active/magic-login-confirmation-reservation-active.component";
@Injectable({
  providedIn: "root",
})
export class LoginPopupsServiceService {
  private FirebaseRecaptchaModal: any;
  private modalMagicLoginConfirmation: any;
  private magicLoginAlertMobile: any;
  private errorToast: any;

  constructor(
    private modalCtrl: ModalController,
    private alertController: AlertController,
    private translateService: TranslateService,
    private toastController: ToastController,
    private store: Store<fromApp.AppState>,
    private platform: Platform,
    private analyticsService: AnalyticsServiceService
  ) {}

  presentFirebaseModalLogin(resend, mobile) {
    if (!this.FirebaseRecaptchaModal) {
      this.FirebaseRecaptchaModal = true;
      this.showFirebaseModalLogin(resend, mobile);
    }
  }

  public async showFirebaseModalLogin(resend, mobile) {
    this.store
      .select("groupApp")
      .pipe(first())
      .subscribe(async (state) => {
        const FirebaseRecaptchaModalSettings = {
          component: FirebaseRecaptchaLoginComponent,
          backdropDismiss: false,
          cssClass: "fireBaseRecaptchaModal",
          componentProps: {
            resend: resend,
            customerMobile: mobile,
          },
        };

        const fireBaseRecaptchaLoginReservationActiveModalSettings = {
          component: FirebaseRecaptchaLoginReservationActiveComponent,
          backdropDismiss: true,
          breakpoints: [0, 0.5, 0.8, 1],
          initialBreakpoint: 0.8,
          cssClass: "fireBaseRecaptchaLoginReservationActiveModal",
          componentProps: {
            resend: resend,
            customerMobile: mobile,
          },
        };

        this.FirebaseRecaptchaModal = await this.modalCtrl.create(
          state && state.groupApp && state.groupApp.active_reservation_app
            ? fireBaseRecaptchaLoginReservationActiveModalSettings
            : FirebaseRecaptchaModalSettings
        );
        await this.FirebaseRecaptchaModal.present();
        this.FirebaseRecaptchaModal.onDidDismiss().then(() => {
          this.FirebaseRecaptchaModal = null;
        });
      })
      .unsubscribe();
  }

  public closeFirebaseModalLogin() {
    if (this.FirebaseRecaptchaModal) {
      this.FirebaseRecaptchaModal.dismiss();
      this.FirebaseRecaptchaModal = null;
    }
  }

  public closeConfirmationLoginModal() {
    if (this.modalMagicLoginConfirmation) {
      this.modalMagicLoginConfirmation.dismiss();
      this.modalMagicLoginConfirmation = null;
    }
  }

  public async presentConfiramtionLoginModal(newCustomer) {
    this.store
      .select("groupApp")
      .pipe(first())
      .subscribe(async (state) => {
        const MagicLoginConfirmationSettings = {
          component: MagicLoginConfirmationComponent,
          componentProps: { newCustomer: newCustomer },
          backdropDismiss: false,
          cssClass: "magicLoginConfirmation",
        };

        const MagicLoginConfirmationReservationActiveSettings = {
          component: MagicLoginConfirmationReservationActiveComponent,
          componentProps: { newCustomer: newCustomer },
          backdropDismiss: true,
          breakpoints: [0, 0.5, 0.8, 1],
          initialBreakpoint: 0.8,
          cssClass: "magicLoginConfirmationReservationActive",
        };

        this.modalMagicLoginConfirmation = await this.modalCtrl.create(
          state && state.groupApp && state.groupApp.active_reservation_app
            ? MagicLoginConfirmationReservationActiveSettings
            : MagicLoginConfirmationSettings
        );

        await this.modalMagicLoginConfirmation.present();
        this.modalMagicLoginConfirmation.onDidDismiss().then((data) => {
          this.modalMagicLoginConfirmation = null;
          if (data) {
          }
        });
      })
      .unsubscribe();
    console.log("new customer inside magicloginModal", newCustomer);
  }

  public closeMobileMagicLoginAlert() {
    if (this.magicLoginAlertMobile) {
      this.magicLoginAlertMobile.dismiss();
      this.magicLoginAlertMobile = null;
    }
  }

  checkMobileMagicLoginIsOpen() {
    if (this.magicLoginAlertMobile) {
      return true;
    } else {
      return false;
    }
  }

  presentMobileMagicLoginAlert(newCustomer, groupApp) {
    console.log("magic login alert", this.magicLoginAlertMobile);
    if (!this.magicLoginAlertMobile) {
      this.magicLoginAlertMobile = true;
      this.showMobileMagicLoginAlert(newCustomer, groupApp);
    }
  }

  public async showMobileMagicLoginAlert(newCustomer, groupApp) {
    if (groupApp && groupApp.active_reservation_app) {
      this.magicLoginAlertMobile = null;
      if (groupApp && groupApp.firebaseVerificationEnabled) {
        this.showFirebaseModalLogin(
          false,
          newCustomer && newCustomer.mobile ? newCustomer.mobile : null
        );
      } else {
        this.presentErrorToast(
          this.translateService.instant(
            "popups.magic-login-popup.you-are-in-a-test-version-of-the-app-you-cant-login"
          ),
          "danger"
        );
      }
    } else {
      if (groupApp && groupApp.firebaseVerificationEnabled) {
        this.magicLoginAlertMobile = await this.alertController.create({
          header: this.translateService.instant("homepage.magic-login"),
          cssClass: "maginLoginPopup",
          backdropDismiss: false,
          message: this.translateService.instant(
            "popups.magic-login-popup.please-enter-your-mobile"
          ),
          inputs: [
            {
              name: "mobile",
              type: "tel",
              value:
                newCustomer && newCustomer.mobile ? newCustomer.mobile : "",
              id: "inputMobile",
              placeholder: this.translateService.instant(
                "popups.magic-login-confirmation.write-here"
              ),
            },
          ],
          buttons: [
            {
              text: this.translateService.instant(
                "popups.magic-login-confirmation.cancel"
              ),
              role: "cancel",
              cssClass: "secondary",
              handler: () => {
                console.log("Confirm Cancel");
              },
            },
            {
              text: "OK",
              role: "OK",

              handler: (data) => {
                if (data && data.mobile) {
                  this.analyticsService.dmAnalyticsMessage(
                    "submit_mobile_button",
                    null,
                    null,
                    null,
                    null,
                    null,
                    data.mobile,
                    null,
                    null,
                    null,
                    null
                  );
                  let mobile = _.cloneDeep(
                    data.mobile.toString().replace(/\s/g, "")
                  );
                  let newCustomer = {
                    gateway: "sms",
                    mobile: mobile,
                    createOneTimePass: true,
                  };
                  console.log(
                    "new customer before present magicloginModal",
                    newCustomer
                  );
                  this.store.dispatch(
                    new AuthActions.SetNewCustomer(newCustomer)
                  );
                  //this.presentMagicLoginConfirmationModal(newCustomer);
                } else {
                  if (this.errorToast) {
                    this.errorToast.dismiss();
                    this.errorToast = null;
                  }
                  this.presentErrorToast(
                    this.translateService.instant(
                      "popups.magic-login-popup.please-enter-your-mobile"
                    ),
                    "danger"
                  );
                  return false;
                }
              },
            },
          ],
        });

        const inputMobile: any = document.getElementById("inputMobile");
        if (inputMobile) {
          inputMobile.onkeyup = (ev) => {
            if (ev.key === "enter" || ev.keyCode === 13) {
              ev.preventDefault();
              if (inputMobile && inputMobile.value) {
                if (this.magicLoginAlertMobile) {
                  this.magicLoginAlertMobile.dismiss();
                  this.magicLoginAlertMobile = null;
                }
                this.analyticsService.dmAnalyticsMessage(
                  "submit_mobile_button",
                  null,
                  null,
                  null,
                  null,
                  null,
                  inputMobile.value,
                  null,
                  null,
                  null,
                  null
                );
                let mobile = _.cloneDeep(
                  inputMobile.value.toString().replace(/\s/g, "")
                );
                let newCustomer = {
                  gateway: "sms",
                  mobile: inputMobile.value,
                  createOneTimePass: true,
                };
                console.log(
                  "new customer before present magicloginModal",
                  newCustomer
                );
                this.store.dispatch(
                  new AuthActions.SetNewCustomer(newCustomer)
                );
                //this.presentMagicLoginConfirmationModal(newCustomer);
              } else {
                if (this.errorToast) {
                  this.errorToast.dismiss();
                  this.errorToast = null;
                }
                this.presentErrorToast(
                  this.translateService.instant(
                    "popups.magic-login-popup.please-enter-your-mobile"
                  ),
                  "danger"
                );
                return false;
              }
            }
          };
        }

        await this.magicLoginAlertMobile.present();
        this.magicLoginAlertMobile
          .onDidDismiss()
          .then(() => {
            this.magicLoginAlertMobile = null;
          })
          .finally(() => {});
      } else {
        this.presentErrorToast(
          this.translateService.instant(
            "popups.magic-login-popup.you-are-in-a-test-version-of-the-app-you-cant-login"
          ),
          "danger"
        );
      }
    }
  }

  async presentErrorToast(message, color) {
    this.errorToast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
    });
    this.errorToast.present();
  }
}
