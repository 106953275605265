import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import * as fromApp from "../.././../store/app.reducer";
import * as OrdersActions from ".././../../orders/store/orders.actions";
import { Subscription } from "rxjs";

@Component({
  selector: "app-piraeus-three-ds-popup",
  templateUrl: "./piraeus-three-ds-popup.component.html",
  styleUrls: ["./piraeus-three-ds-popup.component.scss"],
})
export class PiraeusThreeDsPopupComponent implements OnInit, OnDestroy {
  @Input() piraeus_3ds_url;

  @Input() frame_width;
  @Input() frame_height;
  @Input() showPiraeusSpinner: boolean;
  private unsubscribeBackEvent: Subscription;
  constructor(
    private modalCtrl: ModalController,
    private platform: Platform,
    private store: Store<fromApp.AppState>
  ) {}

  ngOnInit() {
    this.store.dispatch(
      new OrdersActions.SetCurrentPendingPaymentPiraeusOrder(null)
    );
    console.log(
      "piraeus 3ds width heigth",
      this.frame_width,
      this.frame_height
    );
    if (this.frame_width && this.frame_height) {
      const el: any = document.querySelector(".paymentModal");
      el.style.setProperty("--paymentModalWidth", this.frame_width + "px");
      el.style.setProperty("--paymentModalHeight", this.frame_height + "px");
    }
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      400,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  ngOnDestroy() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
  }
}
