import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-product-item-order-type-tags",
  templateUrl: "./product-item-order-type-tags.component.html",
  styleUrls: ["./product-item-order-type-tags.component.scss"],
})
export class ProductItemOrderTypeTagsComponent implements OnInit {
  @Input() productItem;
  @Input() orderPickup;
  @Input() dineIn;

  constructor() {}

  ngOnInit() {}
}
