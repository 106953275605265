<span *ngIf="choiceItem && !choiceItem.bundle">
  <ion-badge
    color="danger"
    class="badge-margin"
    *ngIf="choiceItem && !choiceItem.availableTotal"
  >
    <ion-text>{{ "item-tags.unavailable" | translate }}</ion-text>
  </ion-badge>

  <ion-badge
    [color]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.tagsColors &&
      groupStoreData.store_info.design_settings.tagsColors.new
        ? groupStoreData.store_info.design_settings.tagsColors.new
        : ''
    "
    class="badge-margin"
    *ngIf="choiceItem && choiceItem.tags && choiceItem.tags.new"
  >
    <ion-text>{{ "item-tags.new" | translate }}</ion-text>
  </ion-badge>

  <ion-badge
    [color]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.tagsColors &&
      groupStoreData.store_info.design_settings.tagsColors.veg
        ? groupStoreData.store_info.design_settings.tagsColors.veg
        : ''
    "
    class="badge-margin"
    *ngIf="choiceItem && choiceItem.tags && choiceItem.tags.veg"
  >
    <ion-text>{{ "item-tags.veg" | translate }}</ion-text>
  </ion-badge>

  <ion-badge
    [color]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.tagsColors &&
      groupStoreData.store_info.design_settings.tagsColors.fasting
        ? groupStoreData.store_info.design_settings.tagsColors.fasting
        : ''
    "
    class="badge-margin"
    *ngIf="choiceItem && choiceItem.tags && choiceItem.tags.fasting"
  >
    <ion-text>{{ "item-tags.fasting" | translate }}</ion-text>
  </ion-badge>

  <ion-badge
    [color]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.tagsColors &&
      groupStoreData.store_info.design_settings.tagsColors.gloutenfree
        ? groupStoreData.store_info.design_settings.tagsColors.gloutenfree
        : ''
    "
    class="badge-margin"
    *ngIf="choiceItem && choiceItem.tags && choiceItem.tags.gloutenfree"
  >
    <ion-text>{{ "item-tags.gloutenfree" | translate }}</ion-text>
  </ion-badge>

  <ion-badge
    [color]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.tagsColors &&
      groupStoreData.store_info.design_settings.tagsColors.spicy
        ? groupStoreData.store_info.design_settings.tagsColors.spicy
        : ''
    "
    class="badge-margin"
    *ngIf="choiceItem && choiceItem.tags && choiceItem.tags.spicy"
  >
    <ion-text>{{ "item-tags.spicy" | translate }}</ion-text>
  </ion-badge>

  <ion-badge
    [color]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.tagsColors &&
      groupStoreData.store_info.design_settings.tagsColors.lactosefree
        ? groupStoreData.store_info.design_settings.tagsColors.lactosefree
        : ''
    "
    class="badge-margin"
    *ngIf="choiceItem && choiceItem.tags && choiceItem.tags.lactosefree"
  >
    <ion-text>{{ "item-tags.lactosefree" | translate }}</ion-text>
  </ion-badge>

  <ion-badge
    [color]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.tagsColors &&
      groupStoreData.store_info.design_settings.tagsColors.vegan
        ? groupStoreData.store_info.design_settings.tagsColors.vegan
        : ''
    "
    class="badge-margin"
    *ngIf="choiceItem && choiceItem.tags && choiceItem.tags.vegan"
  >
    <ion-text>{{ "item-tags.vegan" | translate }}</ion-text>
  </ion-badge>
  <ion-badge
    [color]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.tagsColors &&
      groupStoreData.store_info.design_settings.tagsColors.deal
        ? groupStoreData.store_info.design_settings.tagsColors.deal
        : ''
    "
    class="badge-margin"
    *ngIf="choiceItem && choiceItem.tags && choiceItem.tags.deal"
  >
    <ion-text>{{ "item-tags.deal" | translate }}</ion-text>
  </ion-badge>
  <ion-badge
    [color]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.tagsColors &&
      groupStoreData.store_info.design_settings.tagsColors.popular
        ? groupStoreData.store_info.design_settings.tagsColors.popular
        : ''
    "
    class="badge-margin"
    *ngIf="choiceItem && choiceItem.tags && choiceItem.tags.popular"
  >
    <ion-text>{{ "item-tags.popular" | translate }}</ion-text>
  </ion-badge>
  <ion-badge
    [color]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.tagsColors &&
      groupStoreData.store_info.design_settings.tagsColors.custom_tag_1
        ? groupStoreData.store_info.design_settings.tagsColors.custom_tag_1
        : ''
    "
    class="badge-margin"
    *ngIf="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.catalogCustomTagsById &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_1 &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_1.active &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_1
        .translations &&
      groupStoreData.groupApp &&
      groupStoreData.groupApp.mo_base &&
      groupStoreData.groupApp.mo_base.code &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_1.translations[
        groupStoreData.groupApp.mo_base.code
      ] &&
      choiceItem &&
      choiceItem.tags &&
      choiceItem.tags.custom_tag_1
    "
  >
    <ion-text>{{
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_1.translations[
        groupStoreData.groupApp.selectedLanguage
      ] ||
        groupStoreData.store_info.catalogCustomTagsById.custom_tag_1
          .translations[groupStoreData.groupApp.mo_base.code]
    }}</ion-text>
  </ion-badge>
  <ion-badge
    [color]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.tagsColors &&
      groupStoreData.store_info.design_settings.tagsColors.custom_tag_2
        ? groupStoreData.store_info.design_settings.tagsColors.custom_tag_2
        : ''
    "
    class="badge-margin"
    *ngIf="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.catalogCustomTagsById &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_2 &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_2.active &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_2
        .translations &&
      groupStoreData.groupApp &&
      groupStoreData.groupApp.mo_base &&
      groupStoreData.groupApp.mo_base.code &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_2.translations[
        groupStoreData.groupApp.mo_base.code
      ] &&
      choiceItem &&
      choiceItem.tags &&
      choiceItem.tags.custom_tag_2
    "
  >
    <ion-text>{{
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_2.translations[
        groupStoreData.groupApp.selectedLanguage
      ] ||
        groupStoreData.store_info.catalogCustomTagsById.custom_tag_2
          .translations[groupStoreData.groupApp.mo_base.code]
    }}</ion-text>
  </ion-badge>
  <ion-badge
    [color]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.tagsColors &&
      groupStoreData.store_info.design_settings.tagsColors.custom_tag_3
        ? groupStoreData.store_info.design_settings.tagsColors.custom_tag_3
        : ''
    "
    class="badge-margin"
    *ngIf="
      groupStoreData &&
      groupStoreData.store_info.catalogCustomTagsById &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_3 &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_3.active &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_3
        .translations &&
      groupStoreData.groupApp &&
      groupStoreData.groupApp.mo_base &&
      groupStoreData.groupApp.mo_base.code &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_3.translations[
        groupStoreData.groupApp.mo_base.code
      ] &&
      choiceItem &&
      choiceItem.tags &&
      choiceItem.tags.custom_tag_3
    "
  >
    <ion-text>{{
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_3.translations[
        groupStoreData.groupApp.selectedLanguage
      ] ||
        groupStoreData.store_info.catalogCustomTagsById.custom_tag_3
          .translations[groupStoreData.groupApp.mo_base.code]
    }}</ion-text>
  </ion-badge>
  <ion-badge
    [color]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.tagsColors &&
      groupStoreData.store_info.design_settings.tagsColors.custom_tag_4
        ? groupStoreData.store_info.design_settings.tagsColors.custom_tag_4
        : ''
    "
    class="badge-margin"
    *ngIf="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.catalogCustomTagsById &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_4 &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_4.active &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_4
        .translations &&
      groupStoreData.groupApp &&
      groupStoreData.groupApp.mo_base &&
      groupStoreData.groupApp.mo_base.code &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_4.translations[
        groupStoreData.groupApp.mo_base.code
      ] &&
      choiceItem &&
      choiceItem.tags &&
      choiceItem.tags.custom_tag_4
    "
  >
    <ion-text>{{
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_4.translations[
        groupStoreData.groupApp.selectedLanguage
      ] ||
        groupStoreData.store_info.catalogCustomTagsById.custom_tag_4
          .translations[groupStoreData.groupApp.mo_base.code]
    }}</ion-text>
  </ion-badge>
  <ion-badge
    [color]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.tagsColors &&
      groupStoreData.store_info.design_settings.tagsColors.custom_tag_5
        ? groupStoreData.store_info.design_settings.tagsColors.custom_tag_5
        : ''
    "
    class="badge-margin"
    *ngIf="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.catalogCustomTagsById &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_5 &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_5.active &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_5
        .translations &&
      groupStoreData.groupApp &&
      groupStoreData.groupApp.mo_base &&
      groupStoreData.groupApp.mo_base.code &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_5.translations[
        groupStoreData.groupApp.mo_base.code
      ] &&
      choiceItem &&
      choiceItem.tags &&
      choiceItem.tags.custom_tag_5
    "
  >
    <ion-text>{{
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_5.translations[
        groupStoreData.groupApp.selectedLanguage
      ] ||
        groupStoreData.store_info.catalogCustomTagsById.custom_tag_5
          .translations[groupStoreData.groupApp.mo_base.code]
    }}</ion-text>
  </ion-badge>
  <ion-badge
    [color]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.tagsColors &&
      groupStoreData.store_info.design_settings.tagsColors.custom_tag_6
        ? groupStoreData.store_info.design_settings.tagsColors.custom_tag_6
        : ''
    "
    class="badge-margin"
    *ngIf="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.catalogCustomTagsById &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_6 &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_6.active &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_6
        .translations &&
      groupStoreData.groupApp &&
      groupStoreData.groupApp.mo_base &&
      groupStoreData.groupApp.mo_base.code &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_6.translations[
        groupStoreData.groupApp.mo_base.code
      ] &&
      choiceItem &&
      choiceItem.tags &&
      choiceItem.tags.custom_tag_6
    "
  >
    <ion-text>{{
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_6.translations[
        groupStoreData.groupApp.selectedLanguage
      ] ||
        groupStoreData.store_info.catalogCustomTagsById.custom_tag_6
          .translations[groupStoreData.groupApp.mo_base.code]
    }}</ion-text>
  </ion-badge>
  <ion-badge
    [color]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.tagsColors &&
      groupStoreData.store_info.design_settings.tagsColors.custom_tag_7
        ? groupStoreData.store_info.design_settings.tagsColors.custom_tag_7
        : ''
    "
    class="badge-margin"
    *ngIf="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.catalogCustomTagsById &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_7 &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_7.active &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_7
        .translations &&
      groupStoreData.groupApp &&
      groupStoreData.groupApp.mo_base &&
      groupStoreData.groupApp.mo_base.code &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_7.translations[
        groupStoreData.groupApp.mo_base.code
      ] &&
      choiceItem &&
      choiceItem.tags &&
      choiceItem.tags.custom_tag_7
    "
  >
    <ion-text>{{
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_7.translations[
        groupStoreData.groupApp.selectedLanguage
      ] ||
        groupStoreData.store_info.catalogCustomTagsById.custom_tag_7
          .translations[groupStoreData.groupApp.mo_base.code]
    }}</ion-text>
  </ion-badge>
  <ion-badge
    [color]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.tagsColors &&
      groupStoreData.store_info.design_settings.tagsColors.custom_tag_8
        ? groupStoreData.store_info.design_settings.tagsColors.custom_tag_8
        : ''
    "
    class="badge-margin"
    *ngIf="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.catalogCustomTagsById &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_8 &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_8.active &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_8
        .translations &&
      groupStoreData.groupApp &&
      groupStoreData.groupApp.mo_base &&
      groupStoreData.groupApp.mo_base.code &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_8.translations[
        groupStoreData.groupApp.mo_base.code
      ] &&
      choiceItem &&
      choiceItem.tags &&
      choiceItem.tags.custom_tag_8
    "
  >
    <ion-text>{{
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_8.translations[
        groupStoreData.groupApp.selectedLanguage
      ] ||
        groupStoreData.store_info.catalogCustomTagsById.custom_tag_8
          .translations[groupStoreData.groupApp.mo_base.code]
    }}</ion-text>
  </ion-badge>
  <ion-badge
    [color]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.tagsColors &&
      groupStoreData.store_info.design_settings.tagsColors.custom_tag_9
        ? groupStoreData.store_info.design_settings.tagsColors.custom_tag_9
        : ''
    "
    class="badge-margin"
    *ngIf="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.catalogCustomTagsById &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_9 &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_9.active &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_9
        .translations &&
      groupStoreData.groupApp &&
      groupStoreData.groupApp.mo_base &&
      groupStoreData.groupApp.mo_base.code &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_9.translations[
        groupStoreData.groupApp.mo_base.code
      ] &&
      choiceItem &&
      choiceItem.tags &&
      choiceItem.tags.custom_tag_9
    "
  >
    <ion-text>{{
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_9.translations[
        groupStoreData.groupApp.selectedLanguage
      ] ||
        groupStoreData.store_info.catalogCustomTagsById.custom_tag_9
          .translations[groupStoreData.groupApp.mo_base.code]
    }}</ion-text>
  </ion-badge>

  <ion-badge
    [color]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.tagsColors &&
      groupStoreData.store_info.design_settings.tagsColors.custom_tag_10
        ? groupStoreData.store_info.design_settings.tagsColors.custom_tag_10
        : ''
    "
    class="badge-margin"
    *ngIf="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.catalogCustomTagsById &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_10 &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_10.active &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_10
        .translations &&
      groupStoreData.groupApp &&
      groupStoreData.groupApp.mo_base &&
      groupStoreData.groupApp.mo_base.code &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_10
        .translations[groupStoreData.groupApp.mo_base.code] &&
      choiceItem &&
      choiceItem.tags &&
      choiceItem.tags.custom_tag_10
    "
  >
    <ion-text>{{
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_10
        .translations[groupStoreData.groupApp.selectedLanguage] ||
        groupStoreData.store_info.catalogCustomTagsById.custom_tag_10
          .translations[groupStoreData.groupApp.mo_base.code]
    }}</ion-text>
  </ion-badge>
</span>

<span *ngIf="choiceItem && choiceItem.bundle && choiceItem.bundled_product">
  <ion-badge
    color="danger"
    class="badge-margin"
    *ngIf="
      !choiceItem.bundled_product.availableTotal ||
      !choiceItem.availableTotal ||
      !choiceItem.bundled_product.product_categories_availability_check
    "
  >
    <ion-text>{{ "item-tags.unavailable" | translate }}</ion-text>
  </ion-badge>

  <ion-badge
    [color]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.tagsColors &&
      groupStoreData.store_info.design_settings.tagsColors.new
        ? groupStoreData.store_info.design_settings.tagsColors.new
        : ''
    "
    class="badge-margin"
    *ngIf="
      (choiceItem.tags && choiceItem.tags.new) ||
      (choiceItem.bundled_product.tags && choiceItem.bundled_product.tags.new)
    "
  >
    <ion-text>{{ "item-tags.new" | translate }}</ion-text>
  </ion-badge>

  <ion-badge
    [color]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.tagsColors &&
      groupStoreData.store_info.design_settings.tagsColors.veg
        ? groupStoreData.store_info.design_settings.tagsColors.veg
        : ''
    "
    class="badge-margin"
    *ngIf="
      (choiceItem.tags && choiceItem.tags.veg) ||
      (choiceItem.bundled_product.tags && choiceItem.bundled_product.tags.veg)
    "
  >
    <ion-text>{{ "item-tags.veg" | translate }}</ion-text>
  </ion-badge>

  <ion-badge
    [color]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.tagsColors &&
      groupStoreData.store_info.design_settings.tagsColors.fasting
        ? groupStoreData.store_info.design_settings.tagsColors.fasting
        : ''
    "
    class="badge-margin"
    *ngIf="
      (choiceItem.tags && choiceItem.tags.fasting) ||
      (choiceItem.bundled_product.tags &&
        choiceItem.bundled_product.tags.fasting)
    "
  >
    <ion-text>{{ "item-tags.fasting" | translate }}</ion-text>
  </ion-badge>

  <ion-badge
    [color]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.tagsColors &&
      groupStoreData.store_info.design_settings.tagsColors.gloutenfree
        ? groupStoreData.store_info.design_settings.tagsColors.gloutenfree
        : ''
    "
    class="badge-margin"
    *ngIf="
      (choiceItem.tags && choiceItem.tags.gloutenfree) ||
      (choiceItem.bundled_product.tags &&
        choiceItem.bundled_product.tags.gloutenfree)
    "
  >
    <ion-text>{{ "item-tags.gloutenfree" | translate }}</ion-text>
  </ion-badge>

  <ion-badge
    [color]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.tagsColors &&
      groupStoreData.store_info.design_settings.tagsColors.spicy
        ? groupStoreData.store_info.design_settings.tagsColors.spicy
        : ''
    "
    class="badge-margin"
    *ngIf="
      (choiceItem.tags && choiceItem.tags.spicy) ||
      (choiceItem.bundled_product.tags && choiceItem.bundled_product.tags.spicy)
    "
  >
    <ion-text>{{ "item-tags.spicy" | translate }}</ion-text>
  </ion-badge>

  <ion-badge
    [color]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.tagsColors &&
      groupStoreData.store_info.design_settings.tagsColors.lactosefree
        ? groupStoreData.store_info.design_settings.tagsColors.lactosefree
        : ''
    "
    class="badge-margin"
    *ngIf="
      (choiceItem.tags && choiceItem.tags.lactosefree) ||
      (choiceItem.bundled_product.tags &&
        choiceItem.bundled_product.tags.lactosefree)
    "
  >
    <ion-text>{{ "item-tags.lactosefree" | translate }}</ion-text>
  </ion-badge>
  <ion-icon
    [color]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.tagsColors &&
      groupStoreData.store_info.design_settings.tagsColors.frozen
        ? groupStoreData.store_info.design_settings.tagsColors.frozen
        : ''
    "
    class="badge-margin"
    style="font-size: 14px"
    src="assets/flatIcons/frozen.svg"
    *ngIf="
      choiceItem.bundled_product &&
      ((choiceItem.tags && choiceItem.tags.frozen) ||
        (choiceItem.bundled_product.tags &&
          choiceItem.bundled_product.tags.frozen))
    "
  >
    <ion-text>{{ "item-tags.frozen" | translate }}</ion-text>
  </ion-icon>
  <ion-badge
    [color]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.tagsColors &&
      groupStoreData.store_info.design_settings.tagsColors.vegan
        ? groupStoreData.store_info.design_settings.tagsColors.vegan
        : ''
    "
    class="badge-margin"
    *ngIf="
      (choiceItem.tags && choiceItem.tags.vegan) ||
      (choiceItem.bundled_product.tags && choiceItem.bundled_product.tags.vegan)
    "
  >
    <ion-text>{{ "item-tags.vegan" | translate }}</ion-text>
  </ion-badge>
  <ion-badge
    [color]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.tagsColors &&
      groupStoreData.store_info.design_settings.tagsColors.deal
        ? groupStoreData.store_info.design_settings.tagsColors.deal
        : ''
    "
    class="badge-margin"
    *ngIf="
      (choiceItem.tags && choiceItem.tags.deal) ||
      (choiceItem.bundled_product.tags && choiceItem.bundled_product.tags.deal)
    "
  >
    <ion-text>{{ "item-tags.deal" | translate }}</ion-text>
  </ion-badge>
  <ion-badge
    [color]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.tagsColors &&
      groupStoreData.store_info.design_settings.tagsColors.popular
        ? groupStoreData.store_info.design_settings.tagsColors.popular
        : ''
    "
    class="badge-margin"
    *ngIf="
      (choiceItem.tags && choiceItem.tags.popular) ||
      (choiceItem.bundled_product.tags &&
        choiceItem.bundled_product.tags.popular)
    "
  >
    <ion-text>{{ "item-tags.popular" | translate }}</ion-text>
  </ion-badge>

  <ion-badge
    [color]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.tagsColors &&
      groupStoreData.store_info.design_settings.tagsColors.custom_tag_1
        ? groupStoreData.store_info.design_settings.tagsColors.custom_tag_1
        : ''
    "
    class="badge-margin"
    *ngIf="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.catalogCustomTagsById &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_1 &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_1.active &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_1
        .translations &&
      groupStoreData.groupApp &&
      groupStoreData.groupApp.mo_base &&
      groupStoreData.groupApp.mo_base.code &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_1.translations[
        groupStoreData.groupApp.mo_base.code
      ] &&
      ((choiceItem.tags && choiceItem.tags.custom_tag_1) ||
        (choiceItem.bundled_product.tags &&
          choiceItem.bundled_product.tags.custom_tag_1))
    "
  >
    <ion-text>{{
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_1.translations[
        groupStoreData.groupApp.selectedLanguage
      ] ||
        groupStoreData.store_info.catalogCustomTagsById.custom_tag_1
          .translations[groupStoreData.groupApp.mo_base.code]
    }}</ion-text>
  </ion-badge>
  <ion-badge
    [color]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.tagsColors &&
      groupStoreData.store_info.design_settings.tagsColors.custom_tag_2
        ? groupStoreData.store_info.design_settings.tagsColors.custom_tag_2
        : ''
    "
    class="badge-margin"
    *ngIf="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.catalogCustomTagsById &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_2 &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_2.active &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_2
        .translations &&
      groupStoreData.groupApp &&
      groupStoreData.groupApp.mo_base &&
      groupStoreData.groupApp.mo_base.code &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_2.translations[
        groupStoreData.groupApp.mo_base.code
      ] &&
      ((choiceItem.tags && choiceItem.tags.custom_tag_2) ||
        (choiceItem.bundled_product.tags &&
          choiceItem.bundled_product.tags.custom_tag_2))
    "
  >
    <ion-text>{{
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_2.translations[
        groupStoreData.groupApp.selectedLanguage
      ] ||
        groupStoreData.store_info.catalogCustomTagsById.custom_tag_2
          .translations[groupStoreData.groupApp.mo_base.code]
    }}</ion-text>
  </ion-badge>
  <ion-badge
    [color]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.tagsColors &&
      groupStoreData.store_info.design_settings.tagsColors.custom_tag_3
        ? groupStoreData.store_info.design_settings.tagsColors.custom_tag_3
        : ''
    "
    class="badge-margin"
    *ngIf="
      groupStoreData &&
      groupStoreData.store_info.catalogCustomTagsById &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_3 &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_3.active &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_3
        .translations &&
      groupStoreData.groupApp &&
      groupStoreData.groupApp.mo_base &&
      groupStoreData.groupApp.mo_base.code &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_3.translations[
        groupStoreData.groupApp.mo_base.code
      ] &&
      ((choiceItem.tags && choiceItem.tags.custom_tag_3) ||
        (choiceItem.bundled_product.tags &&
          choiceItem.bundled_product.tags.custom_tag_3))
    "
  >
    <ion-text>{{
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_3.translations[
        groupStoreData.groupApp.selectedLanguage
      ] ||
        groupStoreData.store_info.catalogCustomTagsById.custom_tag_3
          .translations[groupStoreData.groupApp.mo_base.code]
    }}</ion-text>
  </ion-badge>
  <ion-badge
    [color]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.tagsColors &&
      groupStoreData.store_info.design_settings.tagsColors.custom_tag_4
        ? groupStoreData.store_info.design_settings.tagsColors.custom_tag_4
        : ''
    "
    class="badge-margin"
    *ngIf="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.catalogCustomTagsById &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_4 &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_4.active &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_4
        .translations &&
      groupStoreData.groupApp &&
      groupStoreData.groupApp.mo_base &&
      groupStoreData.groupApp.mo_base.code &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_4.translations[
        groupStoreData.groupApp.mo_base.code
      ] &&
      ((choiceItem.tags && choiceItem.tags.custom_tag_4) ||
        (choiceItem.bundled_product.tags &&
          choiceItem.bundled_product.tags.custom_tag_4))
    "
  >
    <ion-text>{{
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_4.translations[
        groupStoreData.groupApp.selectedLanguage
      ] ||
        groupStoreData.store_info.catalogCustomTagsById.custom_tag_4
          .translations[groupStoreData.groupApp.mo_base.code]
    }}</ion-text>
  </ion-badge>
  <ion-badge
    [color]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.tagsColors &&
      groupStoreData.store_info.design_settings.tagsColors.custom_tag_5
        ? groupStoreData.store_info.design_settings.tagsColors.custom_tag_5
        : ''
    "
    class="badge-margin"
    *ngIf="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.catalogCustomTagsById &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_5 &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_5.active &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_5
        .translations &&
      groupStoreData.groupApp &&
      groupStoreData.groupApp.mo_base &&
      groupStoreData.groupApp.mo_base.code &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_5.translations[
        groupStoreData.groupApp.mo_base.code
      ] &&
      ((choiceItem.tags && choiceItem.tags.custom_tag_5) ||
        (choiceItem.bundled_product.tags &&
          choiceItem.bundled_product.tags.custom_tag_5))
    "
  >
    <ion-text>{{
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_5.translations[
        groupStoreData.groupApp.selectedLanguage
      ] ||
        groupStoreData.store_info.catalogCustomTagsById.custom_tag_5
          .translations[groupStoreData.groupApp.mo_base.code]
    }}</ion-text>
  </ion-badge>
  <ion-badge
    [color]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.tagsColors &&
      groupStoreData.store_info.design_settings.tagsColors.custom_tag_6
        ? groupStoreData.store_info.design_settings.tagsColors.custom_tag_6
        : ''
    "
    class="badge-margin"
    *ngIf="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.catalogCustomTagsById &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_6 &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_6.active &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_6
        .translations &&
      groupStoreData.groupApp &&
      groupStoreData.groupApp.mo_base &&
      groupStoreData.groupApp.mo_base.code &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_6.translations[
        groupStoreData.groupApp.mo_base.code
      ] &&
      ((choiceItem.tags && choiceItem.tags.custom_tag_6) ||
        (choiceItem.bundled_product.tags &&
          choiceItem.bundled_product.tags.custom_tag_6))
    "
  >
    <ion-text>{{
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_6.translations[
        groupStoreData.groupApp.selectedLanguage
      ] ||
        groupStoreData.store_info.catalogCustomTagsById.custom_tag_6
          .translations[groupStoreData.groupApp.mo_base.code]
    }}</ion-text>
  </ion-badge>
  <ion-badge
    [color]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.tagsColors &&
      groupStoreData.store_info.design_settings.tagsColors.custom_tag_7
        ? groupStoreData.store_info.design_settings.tagsColors.custom_tag_7
        : ''
    "
    class="badge-margin"
    *ngIf="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.catalogCustomTagsById &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_7 &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_7.active &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_7
        .translations &&
      groupStoreData.groupApp &&
      groupStoreData.groupApp.mo_base &&
      groupStoreData.groupApp.mo_base.code &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_7.translations[
        groupStoreData.groupApp.mo_base.code
      ] &&
      ((choiceItem.tags && choiceItem.tags.custom_tag_7) ||
        (choiceItem.bundled_product.tags &&
          choiceItem.bundled_product.tags.custom_tag_7))
    "
  >
    <ion-text>{{
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_7.translations[
        groupStoreData.groupApp.selectedLanguage
      ] ||
        groupStoreData.store_info.catalogCustomTagsById.custom_tag_7
          .translations[groupStoreData.groupApp.mo_base.code]
    }}</ion-text>
  </ion-badge>
  <ion-badge
    [color]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.tagsColors &&
      groupStoreData.store_info.design_settings.tagsColors.custom_tag_8
        ? groupStoreData.store_info.design_settings.tagsColors.custom_tag_8
        : ''
    "
    class="badge-margin"
    *ngIf="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.catalogCustomTagsById &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_8 &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_8.active &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_8
        .translations &&
      groupStoreData.groupApp &&
      groupStoreData.groupApp.mo_base &&
      groupStoreData.groupApp.mo_base.code &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_8.translations[
        groupStoreData.groupApp.mo_base.code
      ] &&
      ((choiceItem.tags && choiceItem.tags.custom_tag_8) ||
        (choiceItem.bundled_product.tags &&
          choiceItem.bundled_product.tags.custom_tag_8))
    "
  >
    <ion-text>{{
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_8.translations[
        groupStoreData.groupApp.selectedLanguage
      ] ||
        groupStoreData.store_info.catalogCustomTagsById.custom_tag_8
          .translations[groupStoreData.groupApp.mo_base.code]
    }}</ion-text>
  </ion-badge>
  <ion-badge
    [color]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.tagsColors &&
      groupStoreData.store_info.design_settings.tagsColors.custom_tag_9
        ? groupStoreData.store_info.design_settings.tagsColors.custom_tag_9
        : ''
    "
    class="badge-margin"
    *ngIf="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.catalogCustomTagsById &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_9 &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_9.active &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_9
        .translations &&
      groupStoreData.groupApp &&
      groupStoreData.groupApp.mo_base &&
      groupStoreData.groupApp.mo_base.code &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_9.translations[
        groupStoreData.groupApp.mo_base.code
      ] &&
      ((choiceItem.tags && choiceItem.tags.custom_tag_9) ||
        (choiceItem.bundled_product.tags &&
          choiceItem.bundled_product.tags.custom_tag_9))
    "
  >
    <ion-text>{{
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_9.translations[
        groupStoreData.groupApp.selectedLanguage
      ] ||
        groupStoreData.store_info.catalogCustomTagsById.custom_tag_9
          .translations[groupStoreData.groupApp.mo_base.code]
    }}</ion-text>
  </ion-badge>

  <ion-badge
    [color]="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.tagsColors &&
      groupStoreData.store_info.design_settings.tagsColors.custom_tag_10
        ? groupStoreData.store_info.design_settings.tagsColors.custom_tag_10
        : ''
    "
    class="badge-margin"
    *ngIf="
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.catalogCustomTagsById &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_10 &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_10.active &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_10
        .translations &&
      groupStoreData.groupApp &&
      groupStoreData.groupApp.mo_base &&
      groupStoreData.groupApp.mo_base.code &&
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_10
        .translations[groupStoreData.groupApp.mo_base.code] &&
      ((choiceItem.tags && choiceItem.tags.custom_tag_10) ||
        (choiceItem.bundled_product.tags &&
          choiceItem.bundled_product.tags.custom_tag_10))
    "
  >
    <ion-text>{{
      groupStoreData.store_info.catalogCustomTagsById.custom_tag_10
        .translations[groupStoreData.groupApp.selectedLanguage] ||
        groupStoreData.store_info.catalogCustomTagsById.custom_tag_10
          .translations[groupStoreData.groupApp.mo_base.code]
    }}</ion-text>
  </ion-badge>

  <ion-badge
    class="badge-margin"
    *ngIf="
      (choiceItem.tags && choiceItem.tags.availableForPreorder) ||
      (choiceItem.bundled_product.tags &&
        choiceItem.bundled_product.tags.availableForPreorder &&
        !choiceItem.bundled_product.hideAvailableForPreorderTag)
    "
  >
    <ion-text>{{ "item-tags.availableForPreorder" | translate }}</ion-text>
  </ion-badge>

  <ion-badge
    class="badge-margin"
    *ngIf="
      choiceItem &&
      choiceItem.bundled_product &&
      choiceItem.bundled_product.availableTotal &&
      choiceItem.bundled_product.tags &&
      choiceItem.bundled_product.tags.initializationActivePreorder &&
      !preorderDateIsAfterToday
    "
  >
    <ion-text> {{ "item-tags.onlyavailableForPreorder" | translate }}</ion-text>
  </ion-badge>

  <ion-badge
    class="badge-margin"
    *ngIf="
      choiceItem &&
      choiceItem.bundled_product &&
      choiceItem.bundled_product.availableTotal &&
      choiceItem.bundled_product.availability_stock_enabled &&
      choiceItem.bundled_product.availability_stock &&
      (!choiceItem.bundled_product.tags ||
        !choiceItem.bundled_product.tags.initializationActivePreorder) &&
      (!selectedPreorderDate ||
        !selectedPreorderDate.hour ||
        !selectedPreorderDate.date ||
        !preorderDateIsAfterToday ||
        !choiceItem.bundled_product.preorder_availability_stock_enabled)
    "
  >
    <ion-text
      >{{ "item-tags.available-stock" | translate }}:
      {{ choiceItem.bundled_product.availability_stock }}</ion-text
    >
  </ion-badge>

  <ion-badge
    class="badge-margin"
    *ngIf="
      choiceItem &&
      choiceItem.bundled_product &&
      choiceItem.bundled_product.availableTotal &&
      choiceItem.bundled_product.preorder_availability_stock_enabled &&
      choiceItem.bundled_product.preorder_availability_stock &&
      preorderDateIsAfterToday
    "
  >
    <ion-text
      >{{ "item-tags.available-stock" | translate }}:
      {{ choiceItem.bundled_product.preorder_availability_stock }}</ion-text
    >
  </ion-badge>
</span>
