import * as OptionStepperActions from "./optionStepper.actions";
import * as _ from "lodash";

export interface State {
  selectedProductOptionStepsBySelectedProductId: any;
  // optionsSteps: any;
  // selectedStepOptionId: any;
}

const initialState: State = {
  selectedProductOptionStepsBySelectedProductId: {},
  // optionsSteps: null,
  // selectedStepOptionId: null,
};

export function optionStepperActions(
  state = initialState,
  action: OptionStepperActions.OptionStepperActions
) {
  switch (action.type) {
    case OptionStepperActions.SET_SELECTED_STEP_OPTION_ID:
      let selectedProductOptionStepsBySelectedProductId = _.cloneDeep(
        state.selectedProductOptionStepsBySelectedProductId
      );
      if (
        selectedProductOptionStepsBySelectedProductId &&
        selectedProductOptionStepsBySelectedProductId[
          action.payload.selectedProductId
        ]
      ) {
        selectedProductOptionStepsBySelectedProductId[
          action.payload.selectedProductId
        ].selectedStepOptionId = action.payload.selectedStepOptionId;
      } else {
        selectedProductOptionStepsBySelectedProductId = {};
        selectedProductOptionStepsBySelectedProductId[
          action.payload.selectedProductId
        ] = {};
        selectedProductOptionStepsBySelectedProductId[
          action.payload.selectedProductId
        ].selectedStepOptionId = action.payload.selectedStepOptionId;
      }
      return {
        ...state,
        selectedProductOptionStepsBySelectedProductId:
          selectedProductOptionStepsBySelectedProductId,
      };
    case OptionStepperActions.SET_OPTIONS_STEPS:
      let UpdatedSelectedProductOptionStepsBySelectedProductId = _.cloneDeep(
        state.selectedProductOptionStepsBySelectedProductId
      );
      if (
        UpdatedSelectedProductOptionStepsBySelectedProductId &&
        UpdatedSelectedProductOptionStepsBySelectedProductId[
          action.payload.selectedProductId
        ]
      ) {
        UpdatedSelectedProductOptionStepsBySelectedProductId[
          action.payload.selectedProductId
        ].optionsSteps = action.payload.optionsSteps;
      } else {
        UpdatedSelectedProductOptionStepsBySelectedProductId = {};
        UpdatedSelectedProductOptionStepsBySelectedProductId[
          action.payload.selectedProductId
        ] = {};
        UpdatedSelectedProductOptionStepsBySelectedProductId[
          action.payload.selectedProductId
        ].optionsSteps = action.payload.optionsSteps;
      }
      return {
        ...state,
        selectedProductOptionStepsBySelectedProductId:
          UpdatedSelectedProductOptionStepsBySelectedProductId,
      };
    case OptionStepperActions.CLEAR_STATE_FOR_PRODUCT:
      let ClearedOptionsStepsObect = _.cloneDeep(
        state.selectedProductOptionStepsBySelectedProductId
      );
      if (
        ClearedOptionsStepsObect &&
        ClearedOptionsStepsObect[action.payload]
      ) {
        delete ClearedOptionsStepsObect[action.payload];
      }
      return {
        ...state,
        selectedProductOptionStepsBySelectedProductId: ClearedOptionsStepsObect,
      };
    case OptionStepperActions.CLEAR_STATE:
      return {
        ...state,
        selectedProductOptionStepsBySelectedProductId: {},
      };
    default:
      return state;
  }
}
