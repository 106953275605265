import * as PlatformInfoActions from "./platform-info.actions";
import * as _ from "lodash";

export interface State {
  browserPlatform: boolean;
  androidPlatform: boolean;
  iOSPlatform: boolean;
  androidMobilePlatform: boolean;
  iOSMobilePlatform: boolean;
  versionNumber: string;
  session_id: string;
  device_id: string;
}

const initialState: State = {
  browserPlatform: false,
  androidPlatform: false,
  iOSPlatform: false,
  androidMobilePlatform: false,
  iOSMobilePlatform: false,
  versionNumber: null,
  session_id: null,
  device_id: null,
};

export function platformInfoReducer(
  state = initialState,
  action: PlatformInfoActions.PlatformInfoActions
) {
  switch (action.type) {
    case PlatformInfoActions.SET_VERSION_NUMBER:
      return {
        ...state,
        versionNumber: action.payload,
      };

    case PlatformInfoActions.SET_SESSION_ID:
      return {
        ...state,
        session_id: action.payload,
      };
    case PlatformInfoActions.SET_DEVICE_ID:
      return {
        ...state,
        device_id: action.payload,
      };

    case PlatformInfoActions.SET_BROWSER_PLATFORM:
      return {
        ...state,
        browserPlatform: action.payload,
      };

    case PlatformInfoActions.SET_ANDROID_PLATFORM:
      return {
        ...state,
        androidPlatform: action.payload,
      };

    case PlatformInfoActions.SET_IOS_PLATFORM:
      return {
        ...state,
        iOSPlatform: action.payload,
      };

    case PlatformInfoActions.SET_ANDROID_MOBILE_PLATFORM:
      return {
        ...state,
        androidMobilePlatform: action.payload,
      };

    case PlatformInfoActions.SET_IOS_MOBILE_PLATFORM:
      return {
        ...state,
        iOSMobilePlatform: action.payload,
      };

    case PlatformInfoActions.CLEAR_PLATFORM_INFO:
      return {
        ...state,
        browserPlatform: null,
        androidPlatform: null,
        iOSPlatform: null,
        androidMobilePlatform: null,
        iOSMobilePlatform: null,
      };

    default:
      return state;
  }
}
