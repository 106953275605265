<ion-item
  style="width: 100%"
  *ngIf="reservation"
  [ngClass]="reservation.res_status + '_border'"
>
  <ion-progress-bar
    style="position: absolute; top: 0; height: 1.5px"
    *ngIf="reservation.res_status === 'pending'"
    color="danger"
    type="indeterminate"
  ></ion-progress-bar>
  <ion-row>
    <ion-col size="7">
      <ion-label class="ion-no-padding" class="ion-text-wrap">
        <p *ngIf="dateString">
          <strong>{{ dateString }}</strong>
        </p>
        <p *ngIf="reservation.table_name">
          {{ reservation.table_name[lang] }}
        </p>
        <p *ngIf="reservation.prepay_amount">
          {{
            ("reservations.prepay" | translate) +
              ": " +
              reservation.prepay_amount
          }}€
        </p>
        <p
          *ngIf="
            (reservation.res_status === 'canceled' ||
              reservation.res_status === 'rejected') &&
            reservation.rejectionReason
          "
          class="ion-text-wrap"
        >
          <ion-text color="canceled">{{
            reservation.rejectionReason
          }}</ion-text>
        </p>
        <p
          *ngIf="
            reservation.selectedPaymentMethodID !== 'cash' &&
            reservation.selectedPaymentMethodID !== 'mobilePOS' &&
            reservation.payment_timestamp
          "
        >
          <ion-text
            ><strong>{{ "orders.order-item.payment" | translate }}:</strong>
            {{ reservation.payment_timestamp | date : "dd/MM/yyyy HH:mm" }}
          </ion-text>
        </p>

        <p *ngIf="reservation.refund && reservation.refund.refund_id">
          <ion-text color="danger"
            ><strong>
              {{ reservation.refund.amount
              }}{{ "orders.order-item.refunded" | translate }}
            </strong></ion-text
          >
        </p>
      </ion-label>
    </ion-col>
    <ion-col size="5" class="ion-text-end ion-align-self-center">
      <ion-badge class="ion-text-wrap" *ngIf="color" [color]="color">
        {{
          "reservations.reservation-item." + reservation.res_status | translate
        }}
      </ion-badge>
    </ion-col>
    <ion-col
      *ngIf="
        reservation.res_status === 'pendingPayment' ||
        reservation.res_status === 'processing'
      "
      size="12"
    >
      <p
        class="ion-text-wrap"
        *ngIf="reservation.res_status === 'pendingPayment'"
      >
        <ion-text color="pending"
          >{{ "reservations.reservation-not-send-yet" | translate }}
        </ion-text>
      </p>
      <p class="ion-text-wrap" *ngIf="reservation.res_status === 'processing'">
        <ion-text color="processing"
          >{{ "orders.order-item.processing-your-payment" | translate }} &nbsp;
          {{ "via" | translate }} &nbsp;
          {{ reservation.selectedPaymentMethodID.toString().toUpperCase() }}
        </ion-text>
      </p>
    </ion-col>
    <ion-col
      *ngIf="reservation.res_status === 'pendingPayment'"
      size="12"
      class="ion-text-center"
    >
      <ion-button
        color="danger"
        style="font-weight: 600"
        (click)="sendReservation()"
        >{{ "orders.order-item.complete-payment" | translate }}</ion-button
      >
    </ion-col>
  </ion-row>
</ion-item>
