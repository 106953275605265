import * as StaticPagesActions from "./staticPages.actions";
import * as _ from "lodash";

export interface State {
  static_pages: any;
}

const initialState: State = {
  static_pages: null,
};

export function requestsErrorHandlingReducer(
  state = initialState,
  action: StaticPagesActions.StaticPagesActions
) {
  switch (action.type) {
    case StaticPagesActions.SET_STATIC_PAGES:
      return {
        ...state,
        static_pages: action.payload,
      };

    case StaticPagesActions.CLEAR_STATIC_PAGES:
      return {
        ...state,
        static_pages: null,
      };

    default:
      return state;
  }
}
