import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import axios from "axios";
import { ScanningInstructionsComponent } from "../scanning-instructions/scanning-instructions.component";
import { ExtendModalComponent } from "../extend-modal/extend-modal.component";
import { Subscription, distinctUntilChanged } from "rxjs";
import { Store } from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";
import _ from "lodash";
import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";

@Component({
  selector: "app-reservation-date-info",
  templateUrl: "./reservation-date-info.page.html",
  styleUrls: ["./reservation-date-info.page.scss"],
})
export class ReservationDateInfoPage implements OnInit, OnDestroy {
  @Input() item: any;
  private subscriptions: Subscription[] = [];
  windowDimensions: any;
  columnSize: any;
  infoSize: number;
  private unsubscribeBackEvent: Subscription;
  public storeAccount;
  private allStores;
  private storesSub;
  public selectedLanguage;
  public groupApp;

  constructor(
    public modalCtrl: ModalController,
    private store: Store<fromApp.AppState>,
    private platform: Platform
  ) {}

  async ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.modalCtrl.dismiss();
        }
      }
    );

    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.windowDimensions, state.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
            // [size]="windowDimensions.width > 600 ? '6': '12'"
            if (this.windowDimensions.width > 700) {
              this.columnSize = 8;
              this.infoSize = 4;
            } else {
              this.columnSize = 12;
              this.infoSize = 12;
            }
          }
        })
    );

    this.storesSub = this.store.select("stores").subscribe((state) => {
      if (state && state.stores && !_.isEqual(state.stores, this.allStores)) {
        this.allStores = _.cloneDeep(state.stores);

        const found = _.find(
          this.allStores,
          (store) => store.store_id === this.item.store_id
        );
        if (found) {
          this.storeAccount = _.cloneDeep(found);
        }
      }
    });

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.selectedLangugage &&
            !_.isEqual(state.selectedLangugage, this.selectedLanguage)
          ) {
            this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
          }
          if (
            state &&
            state.groupApp &&
            !_.isEqual(state.groupApp, this.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
          }
        })
    );
  }

  openLink = async () => {
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${this.storeAccount.mapCenterStoreLat},${this.storeAccount.mapCenterStoreLng}`;

    //open link external on new page
    if (Capacitor.isNativePlatform()) {
      window.open(googleMapsUrl, "_system");
    } else {
      window.open(googleMapsUrl, "_blank");
    }
  };

  async scanningInstractions() {
    const modal = await this.modalCtrl.create({
      component: ScanningInstructionsComponent,
      animated: true,
      backdropDismiss: false,
      cssClass: "scanningInstractions",
    });
    await modal.present();
  }

  async editOrCancelDate() {
    const modal = await this.modalCtrl.create({
      component: ExtendModalComponent,
      id: "extendModal",
      animated: true,
      backdropDismiss: false,
      cssClass: "extendModalCancelDate",
      componentProps: {
        reservationForEdit: this.item,
      },
    });
    await modal.present();
    modal.onDidDismiss().then((data) => {
      if (data && data.data) {
        // Perform actions here when the modal is dismissed with data
        this.modalCtrl.dismiss();
      } else {
        // Perform actions here when the modal is dismissed without data
        console.log("Modal dismissed without data");
      }
    });
  }

  ngOnDestroy() {
    _.each(this.subscriptions, (sub) => {
      if (sub) {
        sub.unsubscribe();
      }
    });
    this.subscriptions = [];
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.storesSub) {
      this.storesSub.unsubscribe();
    }
  }
}
