import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../store/app.reducer";
import * as selectors from "../../../store/app.selectors";
import * as _ from "lodash";
import * as CartActions from "../../../cart/store/cart.actions";
import { AlertController, ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { ProductDetailsComponent } from "src/app/product-details/product-details.component";
import { auditTime, distinctUntilChanged, first } from "rxjs/operators";
import { Subscription } from "rxjs";

@Component({
  selector: "app-cart-catalog-product-item",
  templateUrl: "./cart-catalog-product-item.component.html",
  styleUrls: ["./cart-catalog-product-item.component.scss"],
})
export class CartCatalogProductItemComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() cartProduct;

  public finalPriceLoading: boolean;
  public windowDimensions: any;
  public selectedDineInTable: any;
  public dineIn: any;
  public orderPickup: any;
  public store_info: any;
  public groupApp: any;
  public showProductItemDetails: boolean = false;

  private subscriptions: Subscription[] = [];
  private productDetailsModal: any;
  constructor(
    private store: Store<fromApp.AppState>,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private translateService: TranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.checkProductIfBundleOpenChoices();
  }

  ngOnInit() {
    this.checkProductIfBundleOpenChoices();
    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(this.store_info, state.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((store_info) => {
          if (store_info && !_.isEqual(this.store_info, store_info)) {
            this.store_info = _.cloneDeep(store_info);
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("deliveryMethodAddress")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (state && !_.isEqual(this.orderPickup, state.orderPickup)) {
            this.orderPickup = _.cloneDeep(state.orderPickup);
          }

          if (state && !_.isEqual(this.dineIn, state.dineIn)) {
            this.dineIn = _.cloneDeep(state.dineIn);
          }

          if (
            state &&
            !_.isEqual(this.selectedDineInTable, state.selectedDineInTable)
          ) {
            this.selectedDineInTable = _.cloneDeep(state.selectedDineInTable);
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.windowDimensions, state.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("disableFinalPrice")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.finalPriceLoading, state.disableFinalPrice)
          ) {
            this.finalPriceLoading = _.cloneDeep(state.disableFinalPrice);
          }
        })
    );
  }

  async presentAlertConfirm(cartProductItem) {
    const alert = await this.alertCtrl.create({
      cssClass: "my-custom-class",
      header: this.translateService.instant(
        "catalog.cart-catalog.cart-product-item.delete"
      ),
      message: this.translateService.instant(
        "catalog.cart-catalog.cart-product-item.do-you-want-to-delete-product-from-cart"
      ),
      buttons: [
        {
          text: this.translateService.instant(
            "catalog.cart-catalog.cart-product-item.cancel"
          ),
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: this.translateService.instant(
            "catalog.cart-catalog.cart-product-item.delete"
          ),
          handler: () => {
            this.deleteCartItem(cartProductItem);
          },
        },
      ],
    });

    await alert.present();
  }

  changeProductQuantity(ev, prd) {
    if (ev === "increase") {
      this.increaseQuantity();
    } else if (ev === "decrease") {
      this.decreaseQuantity();
    }
    // console.log(ev);
  }

  choicesIsPreselected(choices) {
    let preselectedArray = [];
    choices.forEach((choice) => {
      if (choice && choice.is_preselected) {
        preselectedArray.push(choice);
      }
    });

    return preselectedArray;
  }

  checkProductIfBundleOpenChoices() {
    if (this.cartProduct && this.cartProduct.options) {
      _.each(this.cartProduct.options, (opt) => {
        if (this.showProductItemDetails) {
          return false;
        }
        if (opt.bundle && opt.choices) {
          _.each(opt.choices, (ch) => {
            if (ch.bundle && ch.is_preselected) {
              this.showProductItemDetails = true;
              return false;
            }
          });
        }
      });
    }
  }

  productHaveDetails(prd) {
    let showProducts = false;

    _.each(prd.options, (option) => {
      if (
        option &&
        option.option_id &&
        (!option.dependenciesVariable ||
          option.dependenciesVariable.length === 0 ||
          (option.dependenciesVariable.length > 0 && option.showDependent))
      ) {
        if (option.type !== "field") {
          if (
            !(
              option.choices &&
              !option.reverseChoices &&
              this.choicesIsPreselected(option.choices).length === 0
            ) ||
            (option.is_require && option.availableTotal)
          ) {
            if (
              !option.doNotPrint ||
              (option.parent_free && option.option_parent_total_price === 0)
            ) {
              //console.log("retuntrue");
              showProducts = true;
            } else {
              //console.log("retunfalse");
            }
          }
          if (
            option.type === "checkbox" &&
            option.reverseChoices &&
            option.choicesComment
          ) {
            //console.log("retuntrue");
            showProducts = true;
          }
          _.each(option.choices, (choice) => {
            if (
              !choice.doNotPrint &&
              (option.type !== "checkbox" ||
                !option.reverseChoices ||
                (!option.choicesComment && !option.reverseChoices))
            ) {
              showProducts = true;
            }

            if (choice.choicePriceComment) {
              showProducts = true;
            } else {
              if (choice.price) {
                showProducts = true;
              }
            }
          });
        } else {
          if (option.text) {
            //console.log("retuntrue");
            showProducts = true;
          } else {
            // console.log("retunfalse");
          }
        }
      } else {
        // console.log("retunfalse");
      }
    });
    return showProducts;
  }

  deleteCartItem(product) {
    this.store.dispatch(new CartActions.DeleteCartProductItem(product));
  }

  increaseQuantity() {
    if (this.cartProduct && this.cartProduct.quantity < 50) {
      let tempProduct = _.cloneDeep(this.cartProduct);
      if (
        this.cartProduct.enable_quantity_step &&
        this.cartProduct.quantity_step &&
        !this.cartProduct.max_quantity &&
        parseFloat(
          parseFloat(
            _.cloneDeep(
              tempProduct.quantity + tempProduct.quantity_step
            ).toString()
          ).toFixed(2)
        ) < 50
      ) {
        tempProduct.quantity = parseFloat(
          parseFloat(
            _.cloneDeep(
              tempProduct.quantity + tempProduct.quantity_step
            ).toString()
          ).toFixed(2)
        );
      } else if (
        this.cartProduct.enable_quantity_step &&
        this.cartProduct.quantity_step &&
        this.cartProduct.max_quantity &&
        parseFloat(
          parseFloat(
            _.cloneDeep(
              tempProduct.quantity + tempProduct.quantity_step
            ).toString()
          ).toFixed(2)
        ) < this.cartProduct.max_quantity
      ) {
        tempProduct.quantity = parseFloat(
          parseFloat(
            _.cloneDeep(
              tempProduct.quantity + tempProduct.quantity_step
            ).toString()
          ).toFixed(2)
        );
      } else if (
        !this.cartProduct.enable_quantity_step ||
        !this.cartProduct.quantity_step
      ) {
        tempProduct.quantity++;
      }
      //console.log(tempProduct.quantity);
      //console.log("set selectedProduct");
      this.store.dispatch(
        new CartActions.AddUpdateCartProductItem(_.cloneDeep(tempProduct))
      );
    }
  }

  async decreaseQuantity() {
    let newCartProduct = _.cloneDeep(this.cartProduct);

    if (
      newCartProduct &&
      newCartProduct.quantity > 0 &&
      newCartProduct.enable_quantity_step &&
      newCartProduct.quantity_step &&
      (newCartProduct.min_quantity
        ? parseFloat(
            parseFloat(
              _.cloneDeep(
                newCartProduct.quantity - newCartProduct.quantity_step
              ).toString()
            ).toFixed(2)
          ) >= newCartProduct.min_quantity
        : parseFloat(
            parseFloat(
              _.cloneDeep(
                newCartProduct.quantity - newCartProduct.quantity_step
              ).toString()
            ).toFixed(2)
          ) > 0)
    ) {
      newCartProduct.quantity = parseFloat(
        parseFloat(
          _.cloneDeep(
            newCartProduct.quantity - newCartProduct.quantity_step
          ).toString()
        ).toFixed(2)
      );

      this.store.dispatch(
        new CartActions.AddUpdateCartProductItem(_.cloneDeep(newCartProduct))
      );
    } else if (
      newCartProduct &&
      newCartProduct.quantity > 1 &&
      (!newCartProduct.enable_quantity_step || !newCartProduct.quantity_step)
    ) {
      newCartProduct.quantity--;
      this.store.dispatch(
        new CartActions.AddUpdateCartProductItem(newCartProduct)
      );
    } else if (
      (newCartProduct &&
        newCartProduct.quantity === 1 &&
        (!newCartProduct.enable_quantity_step ||
          !newCartProduct.quantity_step)) ||
      (newCartProduct &&
        newCartProduct.enable_quantity_step &&
        newCartProduct.quantity_step &&
        newCartProduct.quantity - newCartProduct.quantity_step <= 0)
    ) {
      const alert = await this.alertCtrl.create({
        header: this.translateService.instant("alert"),
        message: this.translateService.instant(
          "product-details.do-you-want-to-remove-product-from-cart"
        ),

        buttons: [
          {
            text: this.translateService.instant("no"),
            role: "cancel",
            handler: () => {
              console.log("canceled");
            },
          },
          {
            text: this.translateService.instant("yes"),
            handler: () => {
              this.store
                .select("cart")
                .pipe(first())
                .subscribe((cartState) => {
                  const product_index_cart = _.findIndex(cartState.products, {
                    product_id: this.cartProduct.product_id,
                    cart_id: this.cartProduct.cart_id,
                  });

                  if (product_index_cart !== -1) {
                    if (
                      cartState &&
                      cartState.products &&
                      cartState.products.length
                    ) {
                      let cart = _.cloneDeep(cartState.products);
                      cart.splice(product_index_cart, 1);

                      this.store.dispatch(
                        new CartActions.SetCartProducts(_.cloneDeep(cart))
                      );
                      this.modalCtrl.dismiss();
                    }
                  }
                })
                .unsubscribe();
            },
          },
        ],
      });
      await alert.present();
    }
  }

  showProductDetailsModal(product) {
    let detailsCssClass;
    if (
      product &&
      product.options &&
      product.options.length < 2 &&
      product.noImageProduct
    ) {
      detailsCssClass = "productDetailsModalSmall";
    } else if (
      product &&
      product.options &&
      !product.squareImageDesign &&
      product.options.length > 2 &&
      !product.noImageProduct
    ) {
      detailsCssClass = "productDetailsModalBig";
    } else if (
      product &&
      product.squareImageDesign &&
      product.options &&
      product.options.length > 2 &&
      !product.noImageProduct &&
      this.windowDimensions &&
      this.windowDimensions.width >= 1200
    ) {
      detailsCssClass = "productDetailsModalBigSquareImageDesign";
    } else {
      detailsCssClass = "productDetailsModal";
    }
    if (!this.productDetailsModal) {
      this.productDetailsModal = true;
      this.presentProductDetailsModal(product, detailsCssClass);
    }
  }

  async presentProductDetailsModal(product, detailsCssClass) {
    let product_id: string;
    let cart_id: string;
    if (product && product.product_id) {
      product_id = product.product_id;
    }

    if (product && product.cart_id) {
      cart_id = product.cart_id;
    }

    this.productDetailsModal = await this.modalCtrl.create({
      component: ProductDetailsComponent,
      cssClass: detailsCssClass,
      componentProps: {
        product_id: product_id,
        cart_id: cart_id,
      },
      backdropDismiss: false,
    });

    await this.productDetailsModal.present();
    this.productDetailsModal.onDidDismiss().then(() => {
      this.productDetailsModal = null;
    });
  }

  ngOnDestroy() {
    if (this.productDetailsModal) {
      this.productDetailsModal.dismiss();
      this.productDetailsModal = null;
    }
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });

    this.subscriptions = [];
  }
}
