import { Pipe, PipeTransform } from "@angular/core";
import * as _ from "lodash";
@Pipe({
  name: "choicesPreselected",
})
export class ChoicesPreselectedPipe implements PipeTransform {
  transform(array: any): any[] {
    return _.filter(array, {
      is_preselected: true,
    });
  }
}
