<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">
      <ion-text>
        {{ "popups.coupons-schedule-info-popup.title" | translate }}
      </ion-text>
    </ion-title>
    <ion-button
      (click)="closeModal()"
      size="small"
      slot="end"
      fill="clear"
      color="dark"
    >
      <ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row
      style="width: 100%"
      style="padding-left: 17px; padding-right: 17px"
    >
      <ion-col size="12">
        <div *ngFor="let day of couponsSchedule">
          <p
            *ngIf="
              (!todayIsCouponsExceptionDay ||
                todayIsCouponsExceptionDay !== day.day) &&
              day &&
              day.schedule &&
              day.schedule !== 'disabled'
            "
          >
            <ion-text style="font-weight: 800"
              >{{ day.day | translate }}:
            </ion-text>
            {{ "discount-coupons.coupons-is-active" | translate }}
            {{ day.schedule }}
          </p>
          <p
            *ngIf="
              (!todayIsCouponsExceptionDay ||
                todayIsCouponsExceptionDay !== day.day) &&
              day &&
              day.schedule &&
              day.schedule === 'disabled'
            "
          >
            <ion-text style="font-weight: 800"
              >{{ day.day | translate }}:</ion-text
            >
            {{ "discount-coupons.coupons-not-active" | translate }}
          </p>
        </div>

        <div *ngIf="todayIsCouponsExceptionDay && currentDayCouponsSchedule">
          <p>
            <ion-text>
              <ion-text style="font-weight: 800"
                >{{ "discount-coupons.today" | translate }}:</ion-text
              >
              {{
                currentDayCouponsSchedule.active &&
                currentDayCouponsSchedule.time_periods
                  ? ("discount-coupons.coupons-is-active" | translate)
                  : ""
              }}
              <span
                *ngIf="
                  currentDayCouponsSchedule.active &&
                  currentDayCouponsSchedule.time_periods &&
                  currentDayCouponsSchedule.time_periods.length &&
                  !currentDayCouponsSchedule.all_day
                "
              >
                <ion-text
                  *ngFor="let period of currentDayCouponsSchedule.time_periods"
                  >({{ period.start_timestamp | date: "HH:mm" }} -
                  {{ period.stop_timestamp | date: "HH:mm" }})&nbsp;</ion-text
                >
              </span>

              <span *ngIf="!currentDayCouponsSchedule.active">
                <ion-text>{{
                  "discount-coupons.coupons-not-active" | translate
                }}</ion-text>
              </span>
            </ion-text>
          </p>
        </div>

        <span *ngFor="let day of couponsExceptionDaysSchedule">
          <p *ngIf="day.showExceptionDay">
            <ion-text>
              <ion-text style="font-weight: bold">
                {{
                  "store-schedule." + (day.date | date: "EEEE")
                    | lowercase
                    | translate
                }}
                {{ day.date | date: "dd/MM/YYYY" }}:
              </ion-text>
              <span
                *ngIf="
                  day.active &&
                  day.time_periods &&
                  day.time_periods.length &&
                  !day.all_day
                "
              >
                <ion-text>
                  {{ "discount-coupons.coupons-is-active" | translate }}
                </ion-text>

                <ion-text *ngFor="let period of day.time_periods"
                  >{{ period.start_timestamp | date: "HH:mm" }} -
                  {{ period.stop_timestamp | date: "HH:mm" }}&nbsp;</ion-text
                >
              </span>

              <span *ngIf="!day.active">
                <ion-text>{{
                  "discount-coupons.coupons-not-active" | translate
                }}</ion-text>
              </span>
            </ion-text>
          </p>
        </span>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
