<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">{{
      "orders.order_timeline" | translate
    }}</ion-title>

    <p
      *ngIf="order && storeInfo"
      style="
        padding-inline-start: 20px;
        margin: 0;
        font-size: small;
        font-weight: bold;
      "
    >
      <ion-text>#{{ order.order_id }} </ion-text>
      <ion-text
        *ngIf="storeInfo.shortOrderIdInitMinutes && order.short_order_id"
      >
        (#{{ order.short_order_id }})
      </ion-text>
    </p>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid>
    <ion-row style="width: 100%">
      <ion-col size-xs="12">
        <ion-list class="ion-text-center">
          <ion-item lines="full" *ngIf="order.timestamp">
            <ion-label class="ion-text-center">
              <p>
                <strong>{{ "orders.created_at" | translate }}:</strong>
                {{ order.timestamp | date : "dd/MM/yyyy HH:mm" }}
              </p>
            </ion-label>
          </ion-item>

          <ion-item
            lines="full"
            *ngIf="
              order.selectedPaymentMethodID &&
              order.selectedPaymentMethodID !== 'cash' &&
              order.selectedPaymentMethodID !== 'mobilePOS' &&
              order.payment_timestamp
            "
          >
            <ion-label class="ion-text-wrap ion-text-center">
              <p>
                <strong>{{ "orders.payment" | translate }}:</strong>
                {{ order.payment_timestamp | date : "dd/MM/yyyy HH:mm" }}
              </p>
            </ion-label>
          </ion-item>
          <ion-item
            lines="full"
            *ngIf="order.status && order.time_accepted_rejected"
          >
            <ion-label class="ion-text-center">
              <p>
                <strong
                  >{{
                    (order.status === "canceled"
                      ? "orders.canceled"
                      : "orders.accepted"
                    ) | translate
                  }}:
                </strong>
                {{ order.time_accepted_rejected | date : "dd/MM/yyyy HH:mm" }}
              </p>
            </ion-label>
          </ion-item>
          <!-- <ion-item lines='full'
            *ngIf="storeInfo && order.time_sent && storeInfo.orderProcessing"
          >
            <ion-label class="ion-text-wrap ion-text-center">
              <p>
                <strong>{{ "orders.sent" | translate }}:</strong>
                {{ order.time_sent | date: "dd/MM/yyyy HH:mm" }}
                <ion-text *ngIf="order.customerAcceptedOrderNotified">
                  {{
                    order.customerAcceptedOrderNotificationType.toUpperCase()
                  }}
                </ion-text>
              </p>
            </ion-label>
          </ion-item> -->
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
