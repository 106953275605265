import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { distinctUntilChanged, Subscription } from "rxjs";
import * as fromApp from "../../store/app.reducer";
import * as selectors from "../../store/app.selectors";
import * as _ from "lodash";

@Component({
  selector: "app-order-details",
  templateUrl: "./order-details.component.html",
  styleUrls: ["./order-details.component.scss"],
})
export class OrderDetailsComponent implements OnInit, OnDestroy {
  @Input() order: any;
  @Output() openTimeLineModalEvent = new EventEmitter();
  public groupApp: any;
  public ordersArray: any;
  public ds_status_color: string;
  public storeInfo: any;

  private sub: Subscription;
  private ordersSub: Subscription;
  constructor(
    private modalController: ModalController,
    private store: Store<fromApp.AppState>
  ) {}

  ngOnInit() {
    if (this.order) {
      this.caclulateDsStatusColor();
    }
    this.sub = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.groupApp &&
          !_.isEqual(state.groupApp, this.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp);
        }
      });

    this.ordersSub = this.store
      .select("orders")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.ordersArray &&
          !_.isEqual(state.ordersArray, this.ordersArray)
        ) {
          this.ordersArray = _.cloneDeep(state.ordersArray);
          const orderFromState = _.find(this.ordersArray, {
            order_id: this.order.order_id,
            store_id: this.order.store_id,
          });

          if (!_.isEqual(this.order, _.omit(orderFromState, ["products"]))) {
            this.order = _.assign(
              _.cloneDeep(this.order),
              _.cloneDeep(orderFromState)
            );
            this.caclulateDsStatusColor();
          }
        }
      });
  }

  caclulateDsStatusColor() {
    if (this.order) {
      if (this.order.ds_status === "order_received") {
        this.ds_status_color = "processing";
      } else if (this.order.ds_status === "order_picked_up") {
        this.ds_status_color = "processing";
      } else if (this.order.ds_status === "order_delivered") {
        this.ds_status_color = "complete";
      } else if (this.order.ds_status === "order_rejected") {
        this.ds_status_color = "canceled";
      }
    }
  }

  sendOpenTimeLineModalEvent() {
    this.openTimeLineModalEvent.emit(Date.now().toString());
  }

  isNum(val) {
    return /^\d+$/.test(val);
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.ordersSub) {
      this.ordersSub.unsubscribe();
    }
  }
}
