import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as selectors from "../../store/app.selectors";
import * as fromApp from "../../store/app.reducer";
import { Store } from '@ngrx/store';
import * as _ from "lodash";
import moment from "moment";
import { SendReservationInfoPopupComponent } from '../send-reservation-info-popup/send-reservation-info-popup.component';
import { SendReservationPopupComponent } from '../send-reservation-popup/send-reservation-popup.component';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-reservations-item',
  templateUrl: './reservations-item.component.html',
  styleUrls: ['./reservations-item.component.scss'],
})
export class ReservationsItemComponent implements OnInit, OnDestroy {
  @Input() res_id;
  @Input() lang;
  public reservation;
  public dateString = "";
  //public timeString = "";
  public color = "";

  private subscription;

  constructor(private store: Store<fromApp.AppState>,
              private modalController: ModalController) {}

  ngOnInit() {
    this.subscription = this.store.select(selectors.getReservationById(this.res_id)).subscribe(item => {
      if (item && !_.isEqual(this.reservation, item)){
        this.reservation = _.cloneDeep(item);

        this.color = this.reservation.res_status;
        if (this.color === "rejected"){
          this.color = "canceled";
        }
        if (this.color === "accepted"){
          this.color = "complete";
        }

        if (this.reservation.date){
          this.dateString = moment(parseInt(item.date)).format("DD/MM/YYYY HH:mm");
        }
        if (this.reservation.dateEnd){
          this.dateString += " - " + moment(parseInt(item.dateEnd)).format("HH:mm");
        }
      }
    })
  }

  async sendReservation() {
    const reservation = {
      availablePeriod: {
        start: parseInt(this.reservation.date),
        end: parseInt(this.reservation.dateEnd),
        prepay_amount: this.reservation.prepay_amount,
      },
      prepay_amount: this.reservation.prepay_amount,
      table_category_name: this.reservation.table_category_name,
      table_name: this.reservation.table_name,
      dateString: moment(parseInt(this.reservation.date)).locale(this.lang).format("ddd, DD MMM"),
      date: this.reservation.date,
      table_id: this.reservation.table_id,
      slot_id: this.reservation.slot_id,
      seats: this.reservation.numberofseats,
      res_id: this.reservation.res_id,
      formAnswers: this.reservation.formAnswers
    };
    const modal = await this.modalController.create({
      component: SendReservationInfoPopupComponent,
      backdropDismiss: false,
      cssClass: ['sendReservationAndPayModal'],
      componentProps: {
        reservation: _.cloneDeep(reservation),
        lang: _.cloneDeep(this.lang),
        seats: _.cloneDeep(this.reservation.numberofseats),
        disable_seats: true,
        without_table: reservation.table_id === "no_table" ? true : false
      }
    });
    
    await modal.present();
    modal.onDidDismiss().then((data) => {
      if (data && data.role && data.role === "continue") {
        const seats = data.data && data.data.seats ? data.data.seats : reservation.seats;
        const formAnswers = data.data.formAnswers || null;
        reservation.seats = seats;
        this.showSendReservationAndPayModal(_.assign(reservation, {
          formAnswers: formAnswers
        }));
      }
    });
  }

  async showSendReservationAndPayModal(reservation) {
    const modal = await this.modalController.create({
      component: SendReservationPopupComponent,
      cssClass: "sendOrderAndPayModalSmall",
      backdropDismiss: false,
      componentProps: {
        reservation,
        type: "only_pay"
      }
    });
    await modal.present();
  }

  ngOnDestroy() {
    if (this.subscription){
      this.subscription.unsubscribe();
    }
  }
}
