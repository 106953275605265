<ion-row style="width: 100%">
  <ion-col size="6">
    <app-customer-info class="margins"></app-customer-info>

    <app-delivery-method-address
      [cartMode]="true"
    ></app-delivery-method-address>

    <app-cart-preorder-item> </app-cart-preorder-item>

    <app-delivery-info
      *ngIf="currentUser && orderPickup == false && dineIn == false"
    ></app-delivery-info>
    <app-order-comments></app-order-comments>
  </ion-col>

  <ion-col size="6">
    <app-payment-methods class="margins"></app-payment-methods>
    <app-extra-choices class="margins"></app-extra-choices>

    <app-cart-points class="margins"></app-cart-points>
    <app-add-coupon-code
      *ngIf="showAddCouponCodeComponent"
    ></app-add-coupon-code>
    <app-cart-active-coupons class="margins"></app-cart-active-coupons>
    <app-checkbox-promotions class="margins"></app-checkbox-promotions>

    <div class="margins">
      <app-cart-list></app-cart-list>
    </div>
  </ion-col>
</ion-row>
