<ion-header>
  <ion-toolbar >
    <ion-buttons slot="end">
      <ion-button fill="clear" (click)="closeModal()">
        <ion-icon src="assets/ionicons/close.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <ion-text>{{'loyaltySystem.loyalty-more-page.purchase-history' | translate}}</ion-text>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-row *ngIf="unableToLoadUserLoyaltyProducts">
    <ion-col class="ion-text-center">
      <ion-text color="danger" style="font-size:small; opacity: 0.8">
        {{'loyaltySystem.couldnt-load-products' | translate}}
      </ion-text>
    </ion-col>
  </ion-row>
    <ion-row
      *ngIf="!customerProducts"
      class="ion-justify-content-center ion-align-items-center"
      style="width: 100%;"
    >
      <ion-col class="ion-text-center">
        <ion-spinner color="dark"></ion-spinner>
      </ion-col>
    </ion-row>
  <ion-list *ngIf="customerProducts">
    <!-- <div *ngFor="let item of productsSortedByYear | keyvalue: keyDescOrder">
      <div *ngFor="let month of (item.value | reverseArray); let i=index;">
        <div *ngIf="month && month.length > 0">
          <ion-item-divider mode="ios">
            {{months[i]}} {{item.key}}
          </ion-item-divider>
        </div>
        <ion-item *ngFor="let product of month" lines="full">
          <ion-icon *ngIf="product.type === 'burger'" style="font-size: 30px; margin-right: 10px;" src="/assets/flatIcons/burger-filled.svg"></ion-icon>
          <ion-icon *ngIf="product.type === 'coffee'" style="font-size: 30px; margin-right: 10px;" src="/assets/flatIcons/coffee-filled.svg"></ion-icon>
          <ion-label>
            <div >
              {{'loyaltySystem.purchased-at' | translate}}: {{product.timestamp | date: 'dd/MM/yy, HH:mm' }}
            </div>
            <div>
              {{'loyaltySystem.expires-at' | translate}}: {{product.expiration_date | date: 'dd/MM/yy, HH:mm'}}
            </div>
          </ion-label>
          <ion-badge *ngIf="product.purchased_at === 'instore'" color="primary" slot="end">instore</ion-badge>
          <ion-badge *ngIf="product.purchased_at === 'online'" color="success" slot="end">online</ion-badge>
        </ion-item>
      </div>
    </div>
    <ion-row 
    *ngIf="!userLoyaltyProductsIsLoading && userLoyaltyProducts && userLoyaltyProducts.length === 0"
    class="ion-justify-content-center">
      {{'loyaltySystem.loyalty-more-page.no-products' | translate}}
    </ion-row> -->
    <div *ngFor="let year of yearList">
      <div *ngFor="let month of monthsWithProducts;">
        <ion-item-divider mode="ios">
          {{month}} {{year}}
        </ion-item-divider>
        <div *ngFor="let product of customerProducts; trackBy:customerProductsTrackBy">
          <div *ngIf="product.month === month">
            <ion-item lines="full">
              <ion-icon *ngIf="product.type === 'burger'" style="font-size: 30px; margin-right: 10px;" src="/assets/flatIcons/burger-filled.svg"></ion-icon>
              <ion-icon *ngIf="product.type === 'coffee'" style="font-size: 30px; margin-right: 10px;" src="/assets/flatIcons/coffee-filled.svg"></ion-icon>
              <ion-label>
                <div>
                  {{'loyaltySystem.purchased-at' | translate}}: {{product.timestamp | date: 'dd/MM/yy, HH:mm' }}
                </div>
                <div>
                  {{'loyaltySystem.expires-at' | translate}}: {{product.expiration_date | date: 'dd/MM/yy, HH:mm'}}
                </div>
              </ion-label>
              <ion-badge *ngIf="product.purchased_at === 'instore'" color="primary" slot="end">instore</ion-badge>
              <ion-badge *ngIf="product.purchased_at === 'online'" color="success" slot="end">online</ion-badge>
            </ion-item>
          </div>
        </div>
      </div>
    </div>
    <ion-row 
    *ngIf="!userLoyaltyProductsIsLoading && customerProducts && customerProducts.length === 0"
    class="ion-justify-content-center">
      {{'loyaltySystem.loyalty-more-page.no-products' | translate}}
    </ion-row>
  </ion-list>
  <ion-infinite-scroll
  [disabled]="disabled"
  threshold="100px"
  (ionInfinite)="fetchUserLoyaltyProducts($event, false)"
>
  <ion-infinite-scroll-content
    loadingSpinner="bubbles"
    [loadingText]="'loading-please-wait' | translate"
  >
  </ion-infinite-scroll-content>
</ion-infinite-scroll>
</ion-content>
