<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="primary" (click)="modalCtrl.dismiss()">
        <ion-icon name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ "reservation-app.contact" | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="item-list">
  <ion-grid>
    <ion-item lines="none" *ngIf="groupApp" style="margin-top: 20px">
      <img
        alt="logo"
        style="height: 200px; margin: auto"
        alt="{{ groupApp.group }}"
        src="{{ rootDir }}{{ groupApp.group }}/logos/{{ selectedLogoFileName }}"
      />
    </ion-item>
    <ion-item lines="none">
      <ion-label style="font-weight: bold; font-size: 18px">
        {{ "reservation-app.stores" | translate }}
      </ion-label>
    </ion-item>
    <ion-item *ngFor="let store of stores">
      <ion-button
        *ngIf="store"
        fill="clear"
        style="margin: auto"
        (click)="openLink(store.mapCenterStoreLat, store.mapCenterStoreLng)"
      >
        <ion-icon
          slot="icon-only"
          name="location-sharp"
          style="font-size: 25px"
        ></ion-icon>
      </ion-button>
      <ion-label *ngIf="store" style="margin-left: 5px" class="ion-text-wrap">
        {{
          store.store_name_mobileorder &&
          store.store_name_mobileorder[selectedLanguage]
            ? store.store_name_mobileorder[selectedLanguage]
            : store.store_name_langs && store.store_name_langs[selectedLanguage]
            ? store.store_name_langs[selectedLanguage]
            : store.store_name_langs && store
            ? store.store_name_langs.el
            : store.store_name
        }}

        <p *ngIf="store && store.address">
          {{ store.address }}
        </p>
      </ion-label>
      <ion-button fill="clear" (click)="openInfo(store)">
        <ion-icon
          slot="icon-only"
          name="information-circle-outline"
          size="small"
        ></ion-icon>
      </ion-button>
    </ion-item>
    <ion-item lines="none" style="margin-top: 10px">
      <ion-label style="font-weight: bold; font-size: 18px">
        {{ "reservation-app.phone" | translate }}
      </ion-label>
    </ion-item>
    <ion-item *ngIf="storeInfo" lines="none">
      <ion-icon size="small" name="call"></ion-icon>

      <ion-label
        color="primary"
        style="font-size: 15px; margin-left: 5px; text-decoration: underline"
        >{{ storeInfo.telephone }}</ion-label
      >
    </ion-item>
    <ion-item lines="none" style="margin-top: 10px">
      <ion-label style="font-weight: bold; font-size: 18px"> Email </ion-label>
    </ion-item>
    <ion-item lines="none" *ngIf="storeInfo">
      <ion-icon size="small" name="mail"></ion-icon>
      <ion-label
        color="primary"
        style="font-size: 15px; margin-left: 5px; text-decoration: underline"
        >{{ storeInfo.vatEmailStore }}</ion-label
      >
    </ion-item>
  </ion-grid>
</ion-content>
