import { Action } from "@ngrx/store";

export const SET_COMPLETE_ORDER_VALIDATION =
  "[COMPLETE ORDER VALIDATION] Set Complete Order Validation";
export const ADD_UDPATE_COMPLETE_ORDER_VALIDATION =
  "[COMPLETE ORDER VALIDATION] Add Update Complete Order Validation";
export const CLEAR_COMPLETE_ORDER_VALIDATION =
  "[COMPLETE ORDER VALIDATION] Clear Complete Order Validation";
export const SET_FINAL_PRICE_SUCCESS =
  "[COMPLETE ORDER VALIDATION] Set Final Price Success";
export const SET_CHECK_ADDRESS_SUCCESS =
  "[COMPLETE ORDER VALIDATION] Set check Address Success";

export class SetCompleteOrderValidation implements Action {
  readonly type = SET_COMPLETE_ORDER_VALIDATION;
  constructor(
    public payload: { lastValidTimestamp: any; completeOrderValidations: any }
  ) {}
}

export class AddUpdateCompleteOrderValidation implements Action {
  readonly type = ADD_UDPATE_COMPLETE_ORDER_VALIDATION;
  constructor(public payload: any) {}
}
export class SetFinalPriceSuccess implements Action {
  readonly type = SET_FINAL_PRICE_SUCCESS;
  constructor(public payload: any) {}
}

export class SetCheckAddressSuccess implements Action {
  readonly type = SET_CHECK_ADDRESS_SUCCESS;
  constructor(public payload: any) {}
}

export class ClearCompleteOrderValidation implements Action {
  readonly type = CLEAR_COMPLETE_ORDER_VALIDATION;
  constructor() {}
}
export type CompleteOrderValidationActions =
  | SetCompleteOrderValidation
  | AddUpdateCompleteOrderValidation
  | ClearCompleteOrderValidation
  | SetFinalPriceSuccess
  | SetCheckAddressSuccess;
