<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">{{
      "popups.product-valid-delivery-areas-popup.title" | translate
    }}</ion-title>
    <ion-buttons slot="end">
      <ion-button color="danger" fill="clear" (click)="ondismiss()">
        <ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content scrollX="false" scrollY="false">
  <ion-grid
    class="ion-no-padding ion-no-margin"
    style="width: 100%; position: absolute; top: 0px; z-index: 19"
  >
    <ion-row *ngIf="libraryLoading || loading">
      <ion-col class="ion-text-center">
        <ion-spinner></ion-spinner>
      </ion-col>
    </ion-row>
    <ion-row
      class="ion-no-padding ion-no-margin"
      style="z-index: 20; width: 100%"
    >
      <ion-col size="12" class="ion-no-padding ion-no-margin">
        <ion-card
          class="ion-align-items-center ion-text-center ion-margin"
          style="z-index: 21; margin: 5px"
          *ngIf="deliveryAreas && deliveryAreas.length === 0 && notValidAddress"
          color="danger"
        >
          <ion-card-content style="margin: 0px; padding: 5px">
            <ion-label style="font-weight: 800" color="light">
              <div>
                {{
                  "popups.product-valid-delivery-areas-popup.product-is-valid-only-for-these-delivery-areas"
                    | translate
                }}
              </div>
            </ion-label>
          </ion-card-content>
        </ion-card>
        <ion-card
          class="ion-align-items-center ion-text-center ion-margin"
          style="z-index: 21; margin: 5px"
          *ngIf="!notValidAddress"
          color="danger"
        >
          <ion-card-content style="margin: 0px; padding: 5px">
            <ion-label style="font-weight: 800" color="light">
              <div>
                {{
                  "popups.product-valid-delivery-areas-popup.product-is-valid-only-for-delivery-areas"
                    | translate
                }}
              </div>
            </ion-label>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>

  <div
    [hidden]="libraryLoading || loading"
    id="map"
    style="width: 100%; height: 100%"
    data-tap-disabled="true"
  ></div>
</ion-content>
