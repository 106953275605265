import {
  AfterViewInit,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Barcode, BarcodeScanner } from "@capacitor-mlkit/barcode-scanning";
import { Browser } from "@capacitor/browser";
import { AlertController, ModalController, Platform } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { BranchDeepLinks } from "capacitor-branch-deep-links";
import { OrderNowModalPopupsService } from "src/app/services/orderNowModalPopupsService/orderNowModalPopups.service";
import * as BranchLinkDataActions from "../../../store/branch-link-data/branchLinkData.actions";
import * as fromApp from "../../../store/app.reducer";
import { Store } from "@ngrx/store";
import { App } from "@capacitor/app";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Component({
  selector: "app-qr-scan-modal",
  templateUrl: "./qr-scan-modal.component.html",
  styleUrls: ["./qr-scan-modal.component.scss"],
})
export class QrScanModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("square") public squareElement:
    | ElementRef<HTMLDivElement>
    | undefined;
  unsubscribeBackEvent: any;

  public url = `http://localhost:3000`;

  constructor(
    private readonly ngZone: NgZone,
    private platform: Platform,
    private readonly modalController: ModalController,
    private alertController: AlertController,
    private translateService: TranslateService,
    private orderNowModalPopupsService: OrderNowModalPopupsService,
    private store: Store<fromApp.AppState>,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      300,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.startScan();
    }, 250);
  }

  public ngOnDestroy(): void {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    this.stopScan();
  }

  private async startScan(): Promise<void> {
    // Hide everything behind the modal (see `src/theme/variables.scss`)
    document.querySelector("body")?.classList.add("barcode-scanning-active");

    const squareElementBoundingClientRect =
      this.squareElement?.nativeElement.getBoundingClientRect();
    const scaledRect = squareElementBoundingClientRect
      ? {
          left: squareElementBoundingClientRect.left * window.devicePixelRatio,
          right:
            squareElementBoundingClientRect.right * window.devicePixelRatio,
          top: squareElementBoundingClientRect.top * window.devicePixelRatio,
          bottom:
            squareElementBoundingClientRect.bottom * window.devicePixelRatio,
          width:
            squareElementBoundingClientRect.width * window.devicePixelRatio,
          height:
            squareElementBoundingClientRect.height * window.devicePixelRatio,
        }
      : undefined;
    const detectionCornerPoints = scaledRect
      ? [
          [scaledRect.left, scaledRect.top],
          [scaledRect.left + scaledRect.width, scaledRect.top],
          [
            scaledRect.left + scaledRect.width,
            scaledRect.top + scaledRect.height,
          ],
          [scaledRect.left, scaledRect.top + scaledRect.height],
        ]
      : undefined;
    const listener = await BarcodeScanner.addListener(
      "barcodeScanned",
      async (event) => {
        this.ngZone.run(async () => {
          const cornerPoints = event.barcode.cornerPoints;
          if (detectionCornerPoints && cornerPoints) {
            if (
              detectionCornerPoints[0][0] > cornerPoints[0][0] ||
              detectionCornerPoints[0][1] > cornerPoints[0][1] ||
              detectionCornerPoints[1][0] < cornerPoints[1][0] ||
              detectionCornerPoints[1][1] > cornerPoints[1][1] ||
              detectionCornerPoints[2][0] < cornerPoints[2][0] ||
              detectionCornerPoints[2][1] < cornerPoints[2][1] ||
              detectionCornerPoints[3][0] > cornerPoints[3][0] ||
              detectionCornerPoints[3][1] < cornerPoints[3][1]
            ) {
              return;
            }
          }
          listener.remove();
          //this.closeModal();

          const qrCode: Barcode | undefined = event.barcode;
          console.log("qrCode", qrCode);

          if (qrCode) {
            //window.open(qrCode.rawValue, "_system");
            // window.open(qrCode.rawValue,);
            await BranchDeepLinks.handleUrl({
              url: qrCode.rawValue,
            });
            //window.open(qrCode.rawValue);
            await Browser.open({ url: qrCode.rawValue });
            setTimeout(async () => {
              this.store.dispatch(
                new BranchLinkDataActions.SetTriggerForNewBranchLinkData(
                  Date.now().toString()
                )
              );
            }, 1000);

            // window.open(qrCode.rawValue, "_system");
            this.closeModal();
            
            // await App.exitApp();
            //const qrData=this.getData().subscribe((response) => {this.qrData = });

            // if (data && data.table_id && data.store_id) {
            //   //Open dine in modal with table_id and store_id filled
            //   this.orderNowModalPopupsService.closeDineInModal();
            //   this.orderNowModalPopupsService.openDineInModal(
            //     data.table_id,
            //     data.store_id
            //   );
            // }
            //window.open(qrCode.rawValue);
            //
            // window.location.href = qrCode.rawValue;
            //
          }
          const qrCodeData = JSON.parse(qrCode.rawValue);
          const tableId = qrCodeData.table_id;
          const storeId = qrCodeData.store_id;
          this.orderNowModalPopupsService.openDineInModal(tableId, storeId);
        });
      }
    );

    await BarcodeScanner.startScan().catch((reason) => {
      console.log("reason", reason);
      this.showToastForScanQr();
    });
  }

  async showToastForScanQr() {
    const alert = await this.alertController.create({
      header: this.translateService.instant("alert"),
      message: this.translateService.instant("open_camera_and_scan_table_qr"),
      backdropDismiss: true,
      buttons: ["OK"],
    });
    await alert.present();
    await alert.onDidDismiss().then(() => {
      this.orderNowModalPopupsService.closeSettingsModal();
      this.modalController.dismiss();
    });
  }

  private async stopScan(): Promise<void> {
    // Show everything behind the modal again
    document.querySelector("body")?.classList.remove("barcode-scanning-active");

    await BarcodeScanner.stopScan();
  }

  public async closeModal(): Promise<void> {
    this.modalController.dismiss();
  }
}
