<span *ngIf="option && option.choices && selectedLang">
  <ion-item
    lines="none"
    *ngFor="let choice of option.choices; trackBy: trackTimestamp"
  >
    <ion-label
      *ngIf="
        choice.title &&
        (!choice.translations ||
          (choice.translations && !choice.translations.title) ||
          (choice.translations &&
            choice.translations.title &&
            !choice.translations.title[selectedLang]))
      "
      class="ion-text-wrap"
    >
      {{ choice.title
      }}{{
        choice.price ? " - " + choice.price.toFixed(2) + "€" : ""
      }}</ion-label
    >

    <ion-label
      *ngIf="
        choice.translations &&
        choice.translations.title &&
        choice.translations.title[selectedLang]
      "
      class="ion-text-wrap"
    >
      {{ choice.translations.title[selectedLang]
      }}{{
        choice.price ? " - " + choice.price.toFixed(2) + "€" : ""
      }}</ion-label
    >

    <ion-checkbox
      slot="end"
      [disabled]="finalPriceLoading || choice.outOfPeriod"
      [(ngModel)]="choice.selected"
      (ionChange)="onCheckboxChange($event)"
    >
    </ion-checkbox>
  </ion-item>
</span>
