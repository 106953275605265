<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">
      {{
        "cart.cart-components.cart-points.cart-points-rewards-modal.title"
          | translate
      }}
    </ion-title>
    <ion-badge
      [color]="badge_color ? badge_color : 'primary'"
      style="margin-inline-start: 22px; margin-top: 2px"
      *ngIf="currentUser && userPoints && userPoints.pointsSystem"
    >
      {{ "cart.cart-components.cart-points.your-points" | translate }}:
      <strong>{{ userPoints.pointsSystem.active }}</strong>
    </ion-badge>
    <ion-button
      (click)="closeModal()"
      size="small"
      slot="end"
      fill="clear"
      color="dark"
    >
      <ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid
    *ngIf="usePointsAnimation"
    style="
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 100000;
    "
  >
    <ion-row
      style="width: 100%; height: 100%"
      class="ion-align-items-center ion-justify-content-center"
    >
      <ion-col class="ion-align-self-center ion-text-center">
        <ion-grid style="max-width: 350px">
          <ng-lottie
            (animationCreated)="animationCreated($event)"
            (loopComplete)="animationComplete($event)"
            (error)="onAnimationError($event)"
            [options]="usePointsSuccessAnimationOptions"
          ></ng-lottie>
        </ion-grid>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid class="gridMaxWidth">
    <span
      *ngIf="
        groupApp &&
        groupApp.pointsSystem &&
        (rewards || groupApp.points.rewards)
      "
    >
      <ion-card
        class="ion-text-center"
        *ngFor="let reward of rewards ? rewards : groupApp.points.rewards"
      >
        <img
          alt="rewardImage"
          loading="lazy"
          *ngIf="reward.image"
          src="{{ reward.image }}"
        />
        <ion-item lines="none" class="ion-text-wrap ion-text-center">
          <ion-label class="ion-text-wrap ion-text-center">
            <h2>
              <ion-text>
                {{ reward.title }} - {{ reward.points }}
                {{ "points.rewards.points" | translate }}
              </ion-text>
            </h2>
            <p
              *ngIf="reward.appliesToOrderGreaterThan"
              style="margin: 0"
              color="danger"
            >
              <ion-text style="font-size: small">
                {{ "points.rewards.order-greater-than" | translate }}:
                {{ reward.appliesToOrderGreaterThan }}€
              </ion-text>
            </p>
          </ion-label>
        </ion-item>

        <ion-item
          lines="none"
          *ngIf="
            currentUser && userPoints && userPoints.pointsSystem.pending > 0
          "
        >
          {{ userPoints.pointsSystem.pending }}
          {{ "points.rewards.points-pending" | translate }}
        </ion-item>
        <ion-item
          class="barItem"
          *ngIf="reward.user_points_percent"
          lines="none"
        >
          <div style="width: 100%" class="progressbar">
            <div [ngStyle]="{ width: reward.user_points_percent + '%' }">
              <ion-text
                [color]="
                  reward.user_points_percent >= 50 &&
                  reward.user_points_percent < 100
                    ? 'black'
                    : reward.user_points_percent < 50
                    ? 'white'
                    : 'white'
                "
                >{{ reward.user_points_percent }}%</ion-text
              >
            </div>
          </div>
        </ion-item>
        <ion-button
          style="margin-bottom: 10px"
          (click)="confirmUsePoints(reward)"
          [disabled]="usePointsLoading"
          color="primary"
          *ngIf="reward.user_points_percent === 100"
        >
          <ion-label class="ion-text-center">
            <ion-text *ngIf="!usePointsLoading">
              {{ "homepage.point-system.get-the-coupon" | translate }}</ion-text
            >
            <ion-spinner
              *ngIf="usePointsLoading"
              style="color: var(--ion-color-text)"
            >
            </ion-spinner>
          </ion-label>
        </ion-button>
      </ion-card>
    </span>
  </ion-grid>
</ion-content>
