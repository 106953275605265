import { Pipe, PipeTransform } from "@angular/core";
import * as _ from "lodash";

@Pipe({
  name: "filterPointsFactorRulesByOrderType",
})
export class FilterPointsFactorRulesByOrderTypePipe implements PipeTransform {
  transform(pointsFactorRulesArr: any, orderType: string): any {
    return _.filter(pointsFactorRulesArr, { order_type: orderType });
  }
}
