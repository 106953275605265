<ion-grid>
  <ion-row style="width: 100%">
    <ion-col size="6">
      <div [hidden]="showButton" style="height: 50px"></div>
      <ion-item
        lines="none"
        detail="false"
        button
        color="primary"
        *ngIf="previousHyperCategoryObject || previousCategoryObject"
        [hidden]="!showButton"
        size="small"
        (click)="goToPrevious()"
      >
        <ion-icon
          style="margin-right: 4px; position: absolute; left: 0"
          src="assets/ionicons/chevron-back-outline.svg"
        >
        </ion-icon>
        <ion-label
          class="ion-text-wrap ion-text-center"
          style="margin-inline-start: 19px; margin-inline-end: 10px"
        >
          <ion-text
            *ngIf="!previousCategoryObject"
            style="font-size: small"
            class="wrapInTwoLines"
          >
            {{ previousHyperCategoryObject.name | translate }}
          </ion-text>
          <ion-text
            *ngIf="previousCategoryObject "
            style="font-size: small"
            class="wrapInTwoLines"
          >
            {{ previousCategoryObject.name | translate }}
          </ion-text>
        </ion-label>

        <!-- <ion-spinner *ngIf="disableFinalPrice"></ion-spinner> -->
      </ion-item>
      <!-- <ion-item lines="none" button (click)="goToNext()">
        <ion-label color="primary" class="ion-text-center">
          {{ "catalog.go-to-next-hyper-category" | translate }}
        </ion-label>
      </ion-item> -->
    </ion-col>
    <ion-col size="6">
      <div [hidden]="showButton" style="height: 50px"></div>
      <ion-item
        lines="none"
        detail="false"
        button
        color="primary"
        (click)="goToNext()"
        *ngIf="nextHyperCategoryObject || nextCategoryObject"
        [hidden]="!showButton"
      >
        <ion-label
          class="ion-text-wrap ion-text-center"
          style="margin-inline-start: 10px; margin-inline-end: 19px"
        >
          <ion-text
            *ngIf="!nextCategoryObject"
            class="wrapInTwoLines"
            style="font-size: small"
          >
            {{ nextHyperCategoryObject.name | translate }}
          </ion-text>
          <ion-text
            *ngIf="nextCategoryObject"
            class="wrapInTwoLines"
            style="font-size: small"
          >
            {{ nextCategoryObject.name | translate }}
          </ion-text>
        </ion-label>

        <ion-icon
          style="margin-left: 4px; position: absolute; right: 0"
          src="assets/ionicons/chevron-forward-outline.svg"
        >
        </ion-icon>
        <!-- <ion-spinner *ngIf="disableFinalPrice"></ion-spinner> -->
      </ion-item>
    </ion-col>
  </ion-row>
</ion-grid>
