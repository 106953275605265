import * as DeveloperModeActions from "./developer-mode.actions";
import * as _ from "lodash";

export interface State {
  developerMode: boolean;
}

const initialState: State = {
  developerMode: false,
};

export function developerModeReducer(
  state = initialState,
  action: DeveloperModeActions.DeveloperModeActions
) {
  switch (action.type) {
    case DeveloperModeActions.SET_DEVELOPER_MODE:
      return {
        ...state,
        developerMode: action.payload,
      };

    default:
      return state;
  }
}
