import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-order-details-bundle-product-item",
  templateUrl: "./order-details-bundle-product-item.component.html",
  styleUrls: ["./order-details-bundle-product-item.component.scss"],
})
export class OrderDetailsBundleProductItemComponent implements OnInit {
  @Input() bundle_options: any;
  @Input() product: any = null;
  @Input() order: any;

  constructor() {}

  ngOnInit() {}
}
