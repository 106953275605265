<!-- <ion-header>
  <ion-toolbar mode="ios">
    <ion-title>
      {{ "affiliate.receivers-coupons-modal-title" | translate }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button color="dark" fill="clear" (click)="closeModal()">
        <ion-icon slot="icon-only" src="assets/ionicons/close.svg"> </ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header> -->
<ion-card>
  <ion-item-divider style="font-size: large">
    <ion-text style="font-weight: bold; color: black">
      {{ "affiliate.receivers-coupons-modal-title" | translate }}
    </ion-text>
  </ion-item-divider>

  <ion-grid *ngIf="!receiversCoupons">
    <ion-row>
      <ion-col>
        <ion-spinner> </ion-spinner>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-card-content *ngIf="receiversCoupons && stores && mo_token && currentUser">
    <ion-text *ngIf="receiversCoupons.length === 0">
      {{ "affiliate.no-refered-friends" | translate }}
    </ion-text>
    <ion-grid *ngIf="receiversCoupons.length === 0">
      <ng-lottie [options]="noReferedFriendsOptions"></ng-lottie>
    </ion-grid>
    <ion-item *ngFor="
        let coupon of receiversCoupons;
        let i = index;
        trackBy: trackTimestamp
      "
              class="item item-text-wrap item-icon-left cartCoupons cartButtonsWithBordersStyle">
      <ion-label class="ion-text-wrap">
        <strong *ngIf="
            coupon.discount &&
            coupon.discountType &&
            coupon.discountType !== 'combined'
          ">-{{
          coupon.discount + (coupon.discountType === "percent" ? "%" : "€")
          }}&nbsp;</strong>
        {{ coupon.description }}
        <p style="
            font-size: x-small;
            font-weight: 700;

            line-height: 1.4;
          "
           [ngClass]="{ cartWarningLightColor: !coupon.validDate }"
           *ngIf="coupon.activeFrom || coupon.activeTo">
          {{
          "cart.cart-components.cart-active-coupons.valid" | translate
          }}&nbsp;

          {{ "cart.cart-components.cart-active-coupons.from" | translate }}
          {{
          coupon.activeFrom
          ? (coupon.activeFrom | date : "dd/MM/yyyy HH:mm")
          : ""
          }}

          {{ "cart.cart-components.cart-active-coupons.to" | translate }}
          {{ coupon.activeTo ? (coupon.activeTo | date : "dd/MM/yyyy") : "" }}
        </p>
        <!-- <p style="font-size: small;" *ngIf=" !coupon.validDate  && coupon.validDateException">
      <ion-text color="danger">
        {{"cart.cart-components.cart-active-coupons.today-exceptionally-you-cant-use-the-coupon" |
        translate}}
      </ion-text>
    </p> -->
        <p style="
            font-size: x-small;
            font-weight: 700;

            line-height: 1.4;
          "
           [ngClass]="{
            cartWarningLightColor:
              coupon.validCombo && coupon.validDate && !coupon.validMinOrder
          }"
           *ngIf="
            coupon.appliesToOrderGreaterThan &&
            coupon.appliesToOrderGreaterThan !== '0'
          ">
          {{
          "cart.cart-components.cart-active-coupons.minimum-order"
          | translate
          }}&nbsp;{{ coupon.appliesToOrderGreaterThan }}€
        </p>

        <div>
          <ion-badge *ngIf="coupon.status"
                     [color]="calculateBadgeColor(coupon)">
            {{ "discount-coupons.coupons-item." + coupon.status | translate }}
            <ion-text *ngIf="coupon.order_id && coupon.status === 'used'">#{{ coupon.order_id }}</ion-text>
          </ion-badge>
          <ion-badge *ngIf="!coupon.validCombo && coupon.validDate"
                     color="danger">
            <ion-text class="cartWarningLightColor">
              {{
              "cart.cart-components.cart-active-coupons.coupon-cannot-be-combined-with-your-other-choices"
              | translate
              }}
            </ion-text>
          </ion-badge>
        </div>
      </ion-label>
      <ion-button [fill]="coupon.sender_reward_taken ? 'clear' : 'solid'"
                  [disabled]="
          coupon.sender_reward_taken ||
          coupon.status !== 'used' ||
          creatingCoupon
        "
                  slot="end"
                  (click)="confirmCreateCoupon(coupon)">
        {{
        coupon.sender_reward_taken
        ? ("affiliate.created" | translate)
        : ("affiliate.create-coupon" | translate)
        }}
      </ion-button>
    </ion-item>
  </ion-card-content>
</ion-card>