import * as _ from "lodash";
import * as ManuallyTriggerFinalPriceCheckAddressActions from "./manuallyTriggerFinalPrice.actions";

export interface State {
  triggerFinalPriceTimestamp: any;
  triggerCheckAddressTimestamp: any;
  finalPriceLoading: boolean;
  checkAddressLoading: boolean;
  checkAddressSuccess: boolean;
  finalPriceSuccess: boolean;
}

const initialState: State = {
  triggerFinalPriceTimestamp: null,
  triggerCheckAddressTimestamp: null,
  finalPriceLoading: false,
  checkAddressLoading: false,
  checkAddressSuccess: false,
  finalPriceSuccess: false,
};

export function manuallyTriggerFinalPriceCheckAddressReducer(
  state = initialState,
  action: ManuallyTriggerFinalPriceCheckAddressActions.ManuallyTriggerFinalPriceCheckAddressActions
) {
  switch (action.type) {
    case ManuallyTriggerFinalPriceCheckAddressActions.SET_TRIGGER_FINAL_PRICE_TIMESTAMP:
      return {
        ...state,
        triggerFinalPriceTimestamp: action.payload,
      };

    case ManuallyTriggerFinalPriceCheckAddressActions.SET_TRIGGER_CHECK_ADDRESS_TIMESTAMP:
      return {
        ...state,
        triggerCheckAddressTimestamp: action.payload,
      };

    case ManuallyTriggerFinalPriceCheckAddressActions.SET_FINAL_PRICE_SUCCESS:
      return {
        ...state,
        finalPriceSuccess: action.payload,
      };
    case ManuallyTriggerFinalPriceCheckAddressActions.SET_CHECK_ADDRESS_SUCCESS:
      return {
        ...state,
        checkAddressSuccess: action.payload,
      };

    case ManuallyTriggerFinalPriceCheckAddressActions.SET_FINAL_PRICE_LOADING:
      return {
        ...state,
        finalPriceLoading: action.payload,
      };
    case ManuallyTriggerFinalPriceCheckAddressActions.SET_CHECK_ADDRESS_LOADING:
      return {
        ...state,
        checkAddressLoading: action.payload,
      };

    default:
      return state;
  }
}
