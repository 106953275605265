<ion-header>
  <ion-toolbar>
    <ion-title
      *ngIf="
        selectedProduct &&
        (selectedProduct.gift ||
          (bigProductDetailsDesign && bigProductDetailsDesign === 'yes'))
      "
    >
      <strong>
        <ion-text color="primary" *ngIf="selectedProduct.name">
          {{ selectedProduct.name }}
        </ion-text>
        <span
          *ngIf="
            ((orderPickup == false && dineIn == false) ||
              (orderPickup == null && dineIn == null)) &&
            !selectedProduct.price &&
            selectedProduct.virtual_price
          "
        >
          <span>
            <ion-text color="primary" style="font-weight: 700">{{
              selectedProduct.virtual_price
            }}</ion-text>
          </span>
        </span>
        <span
          *ngIf="
            orderPickup == true &&
            dineIn == false &&
            !selectedProduct.price_takeaway &&
            (selectedProduct.virtual_price_takeaway ||
              selectedProduct.virtual_price)
          "
        >
          <span>
            <ion-text color="primary" style="font-weight: 700">{{
              selectedProduct.virtual_price_takeaway
                ? selectedProduct.virtual_price_takeaway
                : selectedProduct.virtual_price
                ? selectedProduct.virtual_price
                : ""
            }}</ion-text>
          </span>
        </span>
        <span
          *ngIf="
            orderPickup == false &&
            dineIn == true &&
            store_info &&
            (!store_info.alt_dinein_active ||
              !selectedDineInTable ||
              !selectedDineInTable.alt_dinein) &&
            !selectedProduct.price_dinein &&
            (selectedProduct.virtual_price_dinein ||
              selectedProduct.virtual_price)
          "
        >
          <span>
            <ion-text color="primary" style="font-weight: 700">{{
              selectedProduct.virtual_price_dinein
                ? selectedProduct.virtual_price_dinein
                : selectedProduct.virtual_price
                ? selectedProduct.virtual_price
                : ""
            }}</ion-text>
          </span>
        </span>
        <span
          *ngIf="
            orderPickup == false &&
            dineIn == true &&
            store_info &&
            store_info.alt_dinein_active &&
            selectedDineInTable &&
            selectedDineInTable.alt_dinein
          "
        >
          <span
            *ngIf="
              !selectedProduct.price_alt_dinein &&
              !selectedProduct.price_dinein &&
              (selectedProduct.virtual_price_alt_dinein ||
                selectedProduct.virtual_price_dinein ||
                selectedProduct.virtual_price)
            "
          >
            <ion-text color="primary" style="font-weight: 700">{{
              selectedProduct.virtual_price_alt_dinein
                ? selectedProduct.virtual_price_alt_dinein
                : selectedProduct.virtual_price_dinein
                ? selectedProduct.virtual_price_dinein
                : selectedProduct.virtual_price
            }}</ion-text>
          </span>
        </span>
      </strong>
      <div *ngIf="selectedProduct && selectedProduct.gift">
        {{ "product-details.gift" | translate }}
      </div>
    </ion-title>
    <ion-buttons *ngIf="selectedProduct" slot="start">
      <ion-button
        *ngIf="
          !selectedProduct.gift &&
          store_info &&
          !store_info.showOnlyFavoritesCatalog
        "
        [disabled]="loadingFavorites"
        size="small"
        fill="clear"
        color="primary"
        (click)="addToFavorites()"
      >
        <ion-icon
          *ngIf="!loadingFavorites"
          [src]="
            selectedProductIsFavorite
              ? 'assets/flatIcons/liked.svg'
              : 'assets/flatIcons/like.svg'
          "
          slot="icon-only"
        ></ion-icon>
        <ion-spinner
          color="primary"
          *ngIf="loadingFavorites"
          name="dots"
        ></ion-spinner>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button fill="clear" color="dark" (click)="closeModal()">
        <ion-icon src="assets/ionicons/close.svg" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons> </ion-toolbar
></ion-header>
<ion-backdrop
  style="z-index: 1000; height: 100%; pointer-events: none"
  *ngIf="
    swipeInfoAnimation &&
    selectedProduct &&
    selectedProduct.option_stepper &&
    !productHasBundleOptions &&
    windowDimensions.width <= 800
  "
></ion-backdrop
><ion-grid
  *ngIf="
    swipeInfoAnimation &&
    selectedProduct &&
    selectedProduct.option_stepper &&
    !productHasBundleOptions &&
    windowDimensions.width <= 800
  "
  style="
    width: 100%;
    height: 100%;
    position: absolute;
    top: 70px;
    z-index: 1001;
  "
>
  <div style="position: absolute; bottom: 35%; width: 100%; z-index: 1001">
    <ion-grid style="max-width: 250px">
      <ng-lottie
        (animationCreated)="infoAnimationCreated($event)"
        [options]="swipeInfoAnimationOptions"
      ></ng-lottie>
    </ion-grid>
    <ion-grid style="max-width: 320px">
      <ion-card style="border: 1px solid white">
        <ion-grid class="ion-no-padding">
          <ion-row style="width: 100%">
            <ion-col class="ion-text-center">
              <ion-list>
                <ion-item lines="none" style="background-color: transparent">
                  <ion-label
                    style="margin: 0"
                    class="ion-text-wrap ion-text-center"
                  >
                    {{
                      "product-details.you-can-swipe-to-change-step" | translate
                    }}
                  </ion-label>
                </ion-item>
                <ion-item lines="none" style="background-color: transparent">
                  <ion-label style="font-size: small" class="ion-text-wrap">
                    {{ "catalog.dont-show-this-again" | translate }}
                  </ion-label>
                  <ion-checkbox
                    [(ngModel)]="productDetailsInfoCheckbox"
                    slot="end"
                  ></ion-checkbox>
                </ion-item>
                <ion-button size="small" (click)="productDetailsSwipeInfo()"
                  >{{ "catalog.ok-got-it" | translate }}
                </ion-button>
              </ion-list>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card>
    </ion-grid>
  </div></ion-grid
>
<ion-backdrop
  *ngIf="showLoading && selectedProduct && selectedProductLoaded"
  style="z-index: 11000; position: absolute; height: 100%; width: 100%"
></ion-backdrop>
<ion-grid
  *ngIf="showLoading && selectedProduct && selectedProductLoaded"
  style="z-index: 11000; position: absolute; height: 100%; width: 100%"
>
  <ion-row
    style="height: 100%; width: 100%"
    class="ion-align-self-center ion-justify-content-center"
  >
    <ion-col class="ion-text-center ion-align-self-center">
      <ion-spinner color="white"></ion-spinner>
    </ion-col> </ion-row
></ion-grid>
<ion-content [scrollEvents]="true" id="product-details-modal">
  <ion-grid
    *ngIf="!selectedProduct || !selectedProductLoaded"
    style="width: 100%"
  >
    <ion-row class="ion-padding">
      <ion-col size="12" class="ion-text-center">
        <ion-spinner class="ion-text-center"></ion-spinner>
      </ion-col>
    </ion-row>
  </ion-grid>
  <div
    style="width: 100%; height: 100%; z-index: 100"
    #optionsList
    [hidden]="!selectedProductLoaded"
  >
    <ion-grid
      *ngIf="favoriteAnimation"
      style="
        width: 100%;
        height: 90%;
        position: absolute;
        top: 0;
        z-index: 1000;
      "
    >
      <ion-row
        style="width: 100%; height: 100%"
        class="ion-align-items-center ion-justify-content-center"
      >
        <ion-col class="ion-align-self-center ion-text-center">
          <ion-grid style="max-width: 300px">
            <ng-lottie
              (animationCreated)="animationCreated($event)"
              (loopComplete)="animationComplete($event)"
              [options]="options"
            ></ng-lottie>
          </ion-grid>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-grid
      *ngIf="bigProductDetailsDesign && bigProductDetailsDesign === 'yes'"
    >
      <ion-row style="width: 100%">
        <ion-col
          [size]="
            selectedProduct && selectedProduct.squareImageDesign ? '4' : '6'
          "
        >
          <img
            style="border-radius: 10px"
            [alt]="selectedProduct.name"
            *ngIf="
              selectedProduct &&
              !selectedProduct.squareImageDesign &&
              image &&
              image.path &&
              windowDimensions &&
              windowDimensions.height &&
              windowDimensions.height >= 500
            "
            width="100%"
            src="{{ image.path }}"
          />
          <ion-grid style="max-width: 275px">
            <ion-thumbnail
              *ngIf="
                selectedProduct &&
                selectedProduct.squareImageDesign &&
                image &&
                image.path
              "
              [class]="
                groupStoreData &&
                groupStoreData.store_info &&
                groupStoreData.store_info.design_settings &&
                groupStoreData.store_info.design_settings
                  .mobileorder_show_img_borders
                  ? 'product-image-border squareImageThumbBigDesign'
                  : 'squareImageThumbBigDesign'
              "
              style=" background: url({{
                image.path
              }}) no-repeat center center; -webkit-background-size: cover; -moz-background-size: cover; background-size: cover; z-index: 1; border-radius: 8px;"
            >
            </ion-thumbnail>
          </ion-grid>
          <div
            *ngIf="selectedProduct && groupApp"
            style="
              padding-inline-start: 16px;
              margin-inline-end: 19px;
              margin-top: 10px;
              display: flex;
            "
            lines="none"
          >
            <ion-label
              style="font-size: large; margin: 0"
              class="ion-text-wrap"
            >
              <div>
                <app-product-item-tags
                  *ngIf="groupStoreData"
                  [groupApp]="groupStoreData.groupApp"
                  [productItem]="selectedProduct"
                  [parentBundleChoice]="parentBundleChoice"
                  [orderPickup]="orderPickup"
                  [dineIn]="dineIn"
                  [store_info]="groupStoreData.store_info"
                  [selectedPreorderDate]="selectedPreorderDate"
                >
                </app-product-item-tags>
                <p
                  style="
                    font-size: x-small;
                    line-height: 14px;
                    font-weight: bold;
                    white-space: normal;
                    margin: 0px;
                  "
                  *ngIf="
                    selectedProduct &&
                    (selectedProduct.promo ||
                      (selectedProduct.category &&
                        selectedProduct.category.promo))
                  "
                >
                  <ion-text
                    color="primary"
                    *ngIf="
                      !selectedProduct.tags ||
                      (selectedProduct.tags && !selectedProduct.tags.deal)
                    "
                    >{{
                      "product-details.product-not-get-coupons-or-discounts"
                        | translate
                    }}</ion-text
                  >
                  <ion-text
                    *ngIf="selectedProduct.tags && selectedProduct.tags.deal"
                    color="primary"
                    >{{
                      "product-details.product-already-promo-not-get-coupons-or-discounts"
                        | translate
                    }}</ion-text
                  >
                </p>
              </div>
              <p
                style="
                  font-size: x-small;
                  line-height: 14px;
                  font-weight: bold;
                  white-space: normal;
                  margin: 0px;
                "
                *ngIf="
                  selectedProduct.pointsSystemException &&
                  groupApp &&
                  groupApp.pointsSystem &&
                  store_info &&
                  store_info.overridePromoItemsForPointsSystem
                "
              >
                <ion-text color="danger">
                  {{
                    "cart.cart-components.cart-list.cart-list-product-item.product-is-point-exception"
                      | translate
                  }}
                </ion-text>
              </p>
              <p
                *ngIf="plasticWrapperTaxProductPrice"
                style="
                  font-size: x-small;
                  line-height: 14px;
                  font-weight: bold;
                  white-space: normal;
                  margin: 0px;
                "
              >
                <ion-text color="danger">
                  {{
                    "product-details.in-product-will-be-applied-enviromental-fee"
                      | translate
                  }}
                  {{ plasticWrapperTaxProductPrice.toFixed(2) }}€
                  {{ "product-details.with-vat-24-percent" | translate }}
                </ion-text>
              </p>
              <p
                *ngIf="
                  selectedProduct &&
                  !plasticWrapperTaxProductPrice &&
                  selectedProduct.plasticWrapperTax &&
                  selectedProduct.plasticWrapperTaxWeight
                "
                style="margin: 0; padding: 0"
              >
                <ion-text
                  color="primary"
                  style="font-size: x-small; font-weight: bold"
                >
                  {{
                    selectedProduct.plasticWrapperTaxWeight == 2
                      ? ("product-details.tax-for-two-plastic-wrappers"
                        | translate)
                      : ("product-details.tax-for-one-plastic-wrappers"
                        | translate)
                  }}
                </ion-text>
              </p>
            </ion-label>
            <ion-item
              *ngIf="
                selectedProduct &&
                selectedProduct.validProductDeliveryAreas &&
                selectedProduct.validProductDeliveryAreas.length &&
                !selectedAddress &&
                orderPickup == null &&
                dineIn == null
              "
              (click)="showProductValidDeliveryAreasInfoModal()"
              lines="none"
              button="true"
              detail="false"
              class="productValidDeliveryAreasItem"
            >
              <ion-label
                class="ion-text-wrap"
                color="danger"
                style="font-size: x-small; font-weight: bold"
              >
                {{
                  "cart.cart-components.cart-list.product-has-specific-delivery-areas-click-for-info"
                    | translate
                }}
              </ion-label>
              <ion-icon
                style="font-size: 20px; margin-right: 5px"
                color="danger"
                slot="start"
                src="assets/ionicons/information-circle-outline.svg"
              >
              </ion-icon>
            </ion-item>
          </div>
          <ion-item
            lines="none"
            *ngIf="selectedProduct && selectedProduct.description"
          >
            <ion-label
              style="font-size: small; margin: 0"
              class="ion-text-wrap"
            >
              <div [innerHTML]="selectedProduct.description"></div>
            </ion-label>
          </ion-item>
          <ion-item
            *ngIf="
              selectedProduct &&
              selectedProduct.showOptionInDescription &&
              selectedProduct.showOptionInDescription.length
            "
          >
            <ion-label style="margin: 0" class="ion-text-wrap">
              <p
                *ngFor="
                  let description of selectedProduct.showOptionInDescription
                "
              >
                {{ description }}
              </p>
            </ion-label>
          </ion-item>
          <ion-item
            lines="none"
            *ngIf="
              selectedProduct &&
              selectedProduct.promotions &&
              selectedProduct.promotions.length
            "
          >
            <ion-label class="ion-text-wrap">
              <p
                class="categoriesWarningColor"
                *ngFor="let promo of selectedProduct.promotions"
              >
                <span style="font-weight: bold">{{ promo.description }}</span>
              </p>
            </ion-label>
          </ion-item>
          <ion-item
            class="migration-product-id-item"
            lines="none"
            *ngIf="
              store_info &&
              store_info.showMigrationProductIdCatalog &&
              selectedProduct &&
              selectedProduct.migration_product_id
            "
          >
            <ion-label
              class="ion-text-wrap"
              style="font-size: small; margin: 0"
            >
              <ion-text color="primary">
                {{ "product-details.product-code" | translate }}:
                {{ selectedProduct.migration_product_id }}
              </ion-text>
            </ion-label>
          </ion-item>
          <app-last-step
            *ngIf="selectedProduct"
            [selectedProduct]="selectedProduct"
          ></app-last-step>
        </ion-col>
        <ion-col
          [size]="
            selectedProduct && selectedProduct.squareImageDesign ? '8' : '6'
          "
        >
          <ion-grid style="height: 90%" class="ion-no-padding ion-no-margin">
            <!-- <ion-row class="ion-padding" *ngIf="!selectedProduct"> <ion-col size="12" class="ion-text-center"> <ion-spinner class="ion-text-center"></ion-spinner> </ion-col> </ion-row> -->
            <app-options
              [bundleMode]="bundleMode"
              [parentBundleChoice]="parentBundleChoice"
              [selectedProductFromParent]="selectedProduct"
              [groupStoreData]="groupStoreData"
              *ngIf="
                selectedProduct &&
                (!selectedProduct.option_stepper ||
                  (windowDimensions && windowDimensions.width > 800) ||
                  productHasBundleOptions)
              "
            ></app-options>
            <app-option-stepper
              [bundleMode]="bundleMode"
              [parentBundleChoice]="parentBundleChoice"
              style="height: 100%"
              [selectedProductFromParent]="selectedProduct"
              [swipeMoveEventSubject]="swipeMoveEventSubject"
              [selectedProductId]="selectedProductId"
              [groupStoreData]="groupStoreData"
              *ngIf="
                selectedProduct &&
                selectedProduct.option_stepper &&
                !productHasBundleOptions &&
                windowDimensions &&
                windowDimensions.width <= 800
              "
            >
            </app-option-stepper>

            <div
              *ngIf="atLeastOneFrozenTag"
              style="
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              "
              class="ion-text-center"
            >
              <ion-icon
                style="font-size: 14px; margin-right: 5px"
                color="primary"
                src="assets/flatIcons/frozen.svg"
              ></ion-icon>
              <ion-text style="font-size: small; font-weight: 500">
                {{ "item-tags.frozen" | translate }}
              </ion-text>
            </div>

            <div
              style="
                font-size: x-small;
                font-weight: 500;
                text-align: center;
                margin-bottom: 10px;
              "
              *ngIf="
                selectedProduct &&
                selectedProduct.disclaimer &&
                selectedProduct.disclaimer.translations &&
                mo_base &&
                selectedProduct.disclaimer.translations[mo_base]
              "
            >
              <ion-text>{{
                selectedProduct.disclaimer.translations[selectedLangugage] &&
                selectedLangugage
                  ? selectedProduct.disclaimer.translations[selectedLangugage]
                  : selectedProduct.disclaimer.translations[mo_base]
              }}</ion-text>
            </div>
          </ion-grid>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-grid
      *ngIf="bigProductDetailsDesign && bigProductDetailsDesign === 'no'"
      style="height: 90%"
      class="ion-no-padding ion-no-margin"
    >
      <!-- <ion-row class="ion-padding" *ngIf="!selectedProduct"> <ion-col size="12" class="ion-text-center"> <ion-spinner class="ion-text-center"></ion-spinner> </ion-col> </ion-row> -->
      <img
        [alt]="selectedProduct.name"
        *ngIf="
          selectedProduct &&
          !selectedProduct.squareImageDesign &&
          image &&
          image.path &&
          windowDimensions &&
          windowDimensions.height &&
          windowDimensions.height >= 500
        "
        width="100%"
        src="{{ image.path }}"
      />
      <ion-list class="ion-no-padding ion-no-margin">
        <ion-grid *ngIf="selectedProduct && selectedProduct.squareImageDesign">
          <ion-row style="width: 100%">
            <ion-col
              style="padding-top: 5px; padding-bottom: 5px"
              class="ion-text-center"
              size="12"
              *ngIf="selectedProduct.name"
            >
              <strong>
                <ion-text color="primary">
                  {{ selectedProduct.name }}
                </ion-text>
              </strong>
            </ion-col>
            <ion-col class="ion-text-center" size="12">
              <ion-grid style="max-width: 250px">
                <ion-thumbnail
                  *ngIf="
                    selectedProduct &&
                    selectedProduct.squareImageDesign &&
                    image &&
                    image.path
                  "
                  [class]="
                    groupStoreData &&
                    groupStoreData.store_info &&
                    groupStoreData.store_info.design_settings &&
                    groupStoreData.store_info.design_settings
                      .mobileorder_show_img_borders
                      ? 'product-image-border squareImageThumbBig'
                      : 'squareImageThumbBig'
                  "
                  style="background: url({{
                    image.path
                  }}) no-repeat center center; -webkit-background-size: cover; -moz-background-size: cover; background-size: cover; z-index: 1; border-radius: 8px; "
                >
                </ion-thumbnail>
              </ion-grid>
            </ion-col>
            <ion-col size="12">
              <ion-item lines="none">
                <ion-label class="ion-text-wrap">
                  <div style="float: right">
                    <span
                      *ngIf="
                        ((orderPickup == false && dineIn == false) ||
                          (orderPickup == null && dineIn == null)) &&
                        !selectedProduct.price &&
                        selectedProduct.virtual_price
                      "
                    >
                      <span>
                        <ion-text color="primary" style="font-weight: 700">{{
                          selectedProduct.virtual_price
                        }}</ion-text>
                      </span>
                    </span>
                    <span
                      *ngIf="
                        orderPickup == true &&
                        dineIn == false &&
                        !selectedProduct.price_takeaway &&
                        !selectedProduct.price &&
                        selectedProduct.virtual_price
                      "
                    >
                      <span>
                        <ion-text color="primary" style="font-weight: 700">{{
                          selectedProduct.virtual_price_takeaway
                            ? selectedProduct.virtual_price_takeaway
                            : selectedProduct.virtual_price
                            ? selectedProduct.virtual_price
                            : ""
                        }}</ion-text>
                      </span>
                    </span>
                    <span
                      *ngIf="
                        orderPickup == false &&
                        dineIn == true &&
                        store_info &&
                        (!store_info.alt_dinein_active ||
                          !selectedDineInTable ||
                          !selectedDineInTable.alt_dinein) &&
                        !selectedProduct.price_dinein &&
                        (selectedProduct.virtual_price_dinein ||
                          selectedProduct.virtual_price)
                      "
                    >
                      <span>
                        <ion-text color="primary" style="font-weight: 700">{{
                          selectedProduct.virtual_price_dinein
                            ? selectedProduct.virtual_price_dinein
                            : selectedProduct.virtual_price
                            ? selectedProduct.virtual_price
                            : ""
                        }}</ion-text>
                      </span>
                    </span>
                    <span
                      *ngIf="
                        orderPickup == false &&
                        dineIn == true &&
                        store_info &&
                        store_info.alt_dinein_active &&
                        selectedDineInTable &&
                        selectedDineInTable.alt_dinein
                      "
                    >
                      <span
                        *ngIf="
                          !selectedProduct.price_alt_dinein &&
                          !selectedProduct.price_dinein &&
                          (selectedProduct.virtual_price_alt_dinein ||
                            selectedProduct.virtual_price_dinein ||
                            selectedProduct.virtual_price)
                        "
                      >
                        <ion-text color="primary" style="font-weight: 700">{{
                          selectedProduct.virtual_price_alt_dinein
                            ? selectedProduct.virtual_price_alt_dinein
                            : selectedProduct.virtual_price_dinein
                            ? selectedProduct.virtual_price_dinein
                            : selectedProduct.virtual_price
                        }}</ion-text>
                      </span>
                    </span>
                  </div>
                  <div>
                    <app-product-item-tags
                      *ngIf="groupStoreData"
                      [groupApp]="groupStoreData.groupApp"
                      [productItem]="selectedProduct"
                      [parentBundleChoice]="parentBundleChoice"
                      [orderPickup]="orderPickup"
                      [dineIn]="dineIn"
                      [store_info]="groupStoreData.store_info"
                      [selectedPreorderDate]="selectedPreorderDate"
                    >
                    </app-product-item-tags>
                    <p
                      style="
                        font-size: x-small;
                        line-height: 14px;
                        font-weight: bold;
                        white-space: normal;
                        margin: 0px;
                      "
                      *ngIf="
                        selectedProduct &&
                        (selectedProduct.promo ||
                          (selectedProduct.category &&
                            selectedProduct.category.promo))
                      "
                    >
                      <ion-text
                        color="primary"
                        *ngIf="
                          !selectedProduct.tags ||
                          (selectedProduct.tags && !selectedProduct.tags.deal)
                        "
                        >{{
                          "product-details.product-not-get-coupons-or-discounts"
                            | translate
                        }}</ion-text
                      >
                      <ion-text
                        *ngIf="
                          selectedProduct.tags && selectedProduct.tags.deal
                        "
                        color="primary"
                        >{{
                          "product-details.product-already-promo-not-get-coupons-or-discounts"
                            | translate
                        }}</ion-text
                      >
                    </p>
                    <ion-item
                      *ngIf="
                        selectedProduct &&
                        selectedProduct.validProductDeliveryAreas &&
                        selectedProduct.validProductDeliveryAreas.length &&
                        !selectedAddress &&
                        orderPickup == null &&
                        dineIn == null
                      "
                      (click)="showProductValidDeliveryAreasInfoModal()"
                      lines="none"
                      button="true"
                      detail="false"
                      class="productValidDeliveryAreasItem"
                    >
                      <ion-label
                        class="ion-text-wrap"
                        color="danger"
                        style="font-size: x-small; font-weight: bold"
                      >
                        {{
                          "cart.cart-components.cart-list.product-has-specific-delivery-areas-click-for-info"
                            | translate
                        }}
                      </ion-label>
                      <ion-icon
                        style="font-size: 20px; margin-right: 5px"
                        color="danger"
                        slot="start"
                        src="assets/ionicons/information-circle-outline.svg"
                      >
                      </ion-icon>
                    </ion-item>
                  </div>
                  <p
                    *ngIf="
                      selectedProduct &&
                      selectedProduct.plasticWrapperTax &&
                      selectedProduct.plasticWrapperTaxWeight &&
                      !plasticWrapperTaxProductPrice
                    "
                    style="margin: 0; padding: 0"
                  >
                    <ion-text
                      color="primary"
                      style="font-size: x-small; font-weight: bold"
                    >
                      {{
                        selectedProduct.plasticWrapperTaxWeight == 2
                          ? ("product-details.tax-for-two-plastic-wrappers"
                            | translate)
                          : ("product-details.tax-for-one-plastic-wrappers"
                            | translate)
                      }}
                    </ion-text>
                  </p>
                  <p
                    *ngIf="plasticWrapperTaxProductPrice"
                    style="
                      font-size: x-small;
                      line-height: 14px;
                      font-weight: bold;
                      white-space: normal;
                      margin: 0px;
                    "
                  >
                    <ion-text color="danger">
                      {{
                        "product-details.in-product-will-be-applied-enviromental-fee"
                          | translate
                      }}
                      {{ plasticWrapperTaxProductPrice.toFixed(2) }}€
                      {{ "product-details.with-vat-24-percent" | translate }}
                    </ion-text>
                  </p>
                  <p
                    style="
                      font-size: x-small;
                      line-height: 14px;
                      font-weight: bold;
                      white-space: normal;
                      margin: 0px;
                    "
                    *ngIf="
                      selectedProduct.pointsSystemException &&
                      groupApp &&
                      groupApp.pointsSystem &&
                      store_info &&
                      store_info.overridePromoItemsForPointsSystem
                    "
                  >
                    <ion-text color="danger">
                      {{
                        "cart.cart-components.cart-list.cart-list-product-item.product-is-point-exception"
                          | translate
                      }}
                    </ion-text>
                  </p>
                  <div
                    *ngIf="
                      selectedProduct.squareImageDesign &&
                      selectedProduct.description
                    "
                    style="font-size: small; padding-top: 5px"
                    class="ion-text-wrap"
                    [innerHTML]="selectedProduct.description"
                  ></div>
                  <div
                    *ngIf="
                      selectedProduct.squareImageDesign &&
                      selectedProduct.showOptionInDescription &&
                      selectedProduct.showOptionInDescription.length
                    "
                  >
                    <p
                      *ngFor="
                        let description of selectedProduct.showOptionInDescription
                      "
                    >
                      {{ description }}
                    </p>
                  </div>
                </ion-label>
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-grid>
        <ion-item
          lines="none"
          *ngIf="
            selectedProduct &&
            selectedProduct.name &&
            !selectedProduct.squareImageDesign
          "
        >
          <ion-label style="font-size: large" class="ion-text-wrap">
            <strong>
              <ion-text color="primary"> {{ selectedProduct.name }} </ion-text>
            </strong>
            <div style="float: right">
              <span
                *ngIf="
                  ((orderPickup == false && dineIn == false) ||
                    (orderPickup == null && dineIn == null)) &&
                  !selectedProduct.price &&
                  selectedProduct.virtual_price
                "
              >
                <span>
                  <ion-text color="primary" style="font-weight: 700">{{
                    selectedProduct.virtual_price
                  }}</ion-text>
                </span>
              </span>
              <span
                *ngIf="
                  orderPickup == true &&
                  dineIn == false &&
                  !selectedProduct.price_takeaway &&
                  !selectedProduct.price &&
                  selectedProduct.virtual_price
                "
              >
                <span>
                  <ion-text color="primary" style="font-weight: 700">{{
                    selectedProduct.virtual_price_takeaway
                      ? selectedProduct.virtual_price_takeaway
                      : selectedProduct.virtual_price
                      ? selectedProduct.virtual_price
                      : ""
                  }}</ion-text>
                </span>
              </span>
              <span
                *ngIf="
                  orderPickup == false &&
                  dineIn == true &&
                  store_info &&
                  (!store_info.alt_dinein_active ||
                    !selectedDineInTable ||
                    !selectedDineInTable.alt_dinein) &&
                  !selectedProduct.price_dinein &&
                  (selectedProduct.virtual_price_dinein ||
                    selectedProduct.virtual_price)
                "
              >
                <span>
                  <ion-text color="primary" style="font-weight: 700">{{
                    selectedProduct.virtual_price_dinein
                      ? selectedProduct.virtual_price_dinein
                      : selectedProduct.virtual_price
                      ? selectedProduct.virtual_price
                      : ""
                  }}</ion-text>
                </span>
              </span>
              <span
                *ngIf="
                  orderPickup == false &&
                  dineIn == true &&
                  store_info &&
                  store_info.alt_dinein_active &&
                  selectedDineInTable &&
                  selectedDineInTable.alt_dinein
                "
              >
                <span
                  *ngIf="
                    !selectedProduct.price_alt_dinein &&
                    !selectedProduct.price_dinein &&
                    (selectedProduct.virtual_price_alt_dinein ||
                      selectedProduct.virtual_price_dinein ||
                      selectedProduct.virtual_price)
                  "
                >
                  <ion-text color="primary" style="font-weight: 700">{{
                    selectedProduct.virtual_price_alt_dinein
                      ? selectedProduct.virtual_price_alt_dinein
                      : selectedProduct.virtual_price_dinein
                      ? selectedProduct.virtual_price_dinein
                      : selectedProduct.virtual_price
                  }}</ion-text>
                </span>
              </span>
            </div>
            <div>
              <app-product-item-tags
                *ngIf="groupStoreData"
                [groupApp]="groupStoreData.groupApp"
                [productItem]="selectedProduct"
                [parentBundleChoice]="parentBundleChoice"
                [orderPickup]="orderPickup"
                [dineIn]="dineIn"
                [store_info]="groupStoreData.store_info"
                [selectedPreorderDate]="selectedPreorderDate"
              >
              </app-product-item-tags>
              <p
                style="
                  font-size: x-small;
                  line-height: 14px;
                  font-weight: bold;
                  white-space: normal;
                  margin: 0px;
                "
                *ngIf="
                  selectedProduct &&
                  (selectedProduct.promo ||
                    (selectedProduct.category &&
                      selectedProduct.category.promo))
                "
              >
                <ion-text
                  color="primary"
                  *ngIf="
                    !selectedProduct.tags ||
                    (selectedProduct.tags && !selectedProduct.tags.deal)
                  "
                  >{{
                    "product-details.product-not-get-coupons-or-discounts"
                      | translate
                  }}</ion-text
                >
                <ion-text
                  *ngIf="selectedProduct.tags && selectedProduct.tags.deal"
                  color="primary"
                  >{{
                    "product-details.product-already-promo-not-get-coupons-or-discounts"
                      | translate
                  }}</ion-text
                >
              </p>
              <ion-item
                *ngIf="
                  selectedProduct &&
                  selectedProduct.validProductDeliveryAreas &&
                  selectedProduct.validProductDeliveryAreas.length &&
                  !selectedAddress &&
                  orderPickup == null &&
                  dineIn == null
                "
                (click)="showProductValidDeliveryAreasInfoModal()"
                lines="none"
                button="true"
                detail="false"
                class="productValidDeliveryAreasItem"
              >
                <ion-label
                  class="ion-text-wrap"
                  color="danger"
                  style="font-size: x-small; font-weight: bold"
                >
                  {{
                    "cart.cart-components.cart-list.product-has-specific-delivery-areas-click-for-info"
                      | translate
                  }}
                </ion-label>
                <ion-icon
                  style="font-size: 20px; margin-right: 5px"
                  color="danger"
                  slot="start"
                  src="assets/ionicons/information-circle-outline.svg"
                >
                </ion-icon>
              </ion-item>
            </div>
            <p
              *ngIf="
                selectedProduct &&
                selectedProduct.plasticWrapperTax &&
                selectedProduct.plasticWrapperTaxWeight &&
                !plasticWrapperTaxProductPrice
              "
              style="margin: 0; padding: 0"
            >
              <ion-text
                color="primary"
                style="font-size: x-small; font-weight: bold"
              >
                {{
                  selectedProduct.plasticWrapperTaxWeight == 2
                    ? ("product-details.tax-for-two-plastic-wrappers"
                      | translate)
                    : ("product-details.tax-for-one-plastic-wrappers"
                      | translate)
                }}
              </ion-text>
            </p>
            <p
              *ngIf="plasticWrapperTaxProductPrice"
              style="
                font-size: x-small;
                line-height: 14px;
                font-weight: bold;
                white-space: normal;
                margin: 0px;
              "
            >
              <ion-text color="danger">
                {{
                  "product-details.in-product-will-be-applied-enviromental-fee"
                    | translate
                }}
                {{ plasticWrapperTaxProductPrice.toFixed(2) }}€
                {{ "product-details.with-vat-24-percent" | translate }}
              </ion-text>
            </p>
            <p
              style="
                font-size: x-small;
                line-height: 14px;
                font-weight: bold;
                white-space: normal;
                margin: 0px;
              "
              *ngIf="
                selectedProduct.pointsSystemException &&
                groupApp &&
                groupApp.pointsSystem &&
                store_info &&
                store_info.overridePromoItemsForPointsSystem
              "
            >
              <ion-text color="danger">
                {{
                  "cart.cart-components.cart-list.cart-list-product-item.product-is-point-exception"
                    | translate
                }}
              </ion-text>
            </p>
            <div
              *ngIf="
                selectedProduct.squareImageDesign && selectedProduct.description
              "
              style="font-size: small; padding-top: 5px"
              class="ion-text-wrap"
              [innerHTML]="selectedProduct.description"
            ></div>
            <div
              *ngIf="
                selectedProduct.squareImageDesign &&
                selectedProduct.showOptionInDescription &&
                selectedProduct.showOptionInDescription.length
              "
            >
              <p
                *ngFor="
                  let description of selectedProduct.showOptionInDescription
                "
              >
                {{ description }}
              </p>
            </div>
          </ion-label>
        </ion-item>
        <ion-item
          lines="none"
          *ngIf="
            selectedProduct &&
            selectedProduct.description &&
            !selectedProduct.squareImageDesign
          "
        >
          <ion-label style="font-size: small" class="ion-text-wrap">
            <div [innerHTML]="selectedProduct.description"></div>
          </ion-label>
        </ion-item>
        <ion-item
          *ngIf="
            selectedProduct &&
            !selectedProduct.squareImageDesign &&
            selectedProduct.showOptionInDescription &&
            selectedProduct.showOptionInDescription.length
          "
        >
          <ion-label class="ion-text-wrap">
            <p
              *ngFor="
                let description of selectedProduct.showOptionInDescription
              "
            >
              {{ description }}
            </p>
          </ion-label>
        </ion-item>
        <ion-item
          lines="none"
          *ngIf="
            selectedProduct &&
            selectedProduct.promotions &&
            selectedProduct.promotions.length
          "
        >
          <ion-label class="ion-text-wrap">
            <p
              class="categoriesWarningColor"
              *ngFor="let promo of selectedProduct.promotions"
            >
              <span style="font-weight: bold">{{ promo.description }}</span>
            </p>
          </ion-label>
        </ion-item>
        <ion-item
          class="migration-product-id-item"
          lines="none"
          *ngIf="
            store_info &&
            store_info.showMigrationProductIdCatalog &&
            selectedProduct &&
            selectedProduct.migration_product_id
          "
        >
          <ion-label class="ion-text-wrap" style="font-size: small; margin: 0">
            <ion-text color="primary">
              {{ "product-details.product-code" | translate }}:
              {{ selectedProduct.migration_product_id }}
            </ion-text>
          </ion-label>
        </ion-item>
      </ion-list>
      <app-options
        [bundleMode]="bundleMode"
        [parentBundleChoice]="parentBundleChoice"
        [selectedProductFromParent]="selectedProduct"
        [groupStoreData]="groupStoreData"
        *ngIf="
          selectedProduct &&
          (!selectedProduct.option_stepper ||
            (windowDimensions && windowDimensions.width > 800) ||
            productHasBundleOptions)
        "
      ></app-options>
      <app-option-stepper
        [bundleMode]="bundleMode"
        [parentBundleChoice]="parentBundleChoice"
        style="height: 100%"
        [selectedProductFromParent]="selectedProduct"
        [swipeMoveEventSubject]="swipeMoveEventSubject"
        [selectedProductId]="selectedProductId"
        [groupStoreData]="groupStoreData"
        *ngIf="
          selectedProduct &&
          selectedProduct.option_stepper &&
          !productHasBundleOptions &&
          windowDimensions &&
          windowDimensions.width <= 800
        "
      >
      </app-option-stepper>

      <div
        *ngIf="atLeastOneFrozenTag"
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        "
        class="ion-text-center"
      >
        <ion-icon
          style="font-size: 14px; margin-right: 5px"
          color="primary"
          src="assets/flatIcons/frozen.svg"
        ></ion-icon>
        <ion-text style="font-size: small; font-weight: 500">
          {{ "item-tags.frozen" | translate }}
        </ion-text>
      </div>
      <div
        style="
          font-size: x-small;
          font-weight: 500;
          text-align: center;
          margin-bottom: 10px;
        "
        *ngIf="
          selectedProduct &&
          selectedProduct.disclaimer &&
          selectedProduct.disclaimer.translations &&
          mo_base &&
          selectedProduct.disclaimer.translations[mo_base]
        "
      >
        <ion-text>{{
          selectedProduct.disclaimer.translations[selectedLangugage] &&
          selectedLangugage
            ? selectedProduct.disclaimer.translations[selectedLangugage]
            : selectedProduct.disclaimer.translations[mo_base]
        }}</ion-text>
      </div>
    </ion-grid>
  </div></ion-content
>
<ion-footer [hidden]="!selectedProductLoaded">
  <ion-progress-bar
    [value]="stepperProgress"
    *ngIf="
      selectedProduct &&
      selectedProduct.option_stepper &&
      !productHasBundleOptions &&
      windowDimensions.width <= 800
    "
  >
  </ion-progress-bar>
  <ion-item
    lines="none"
    *ngIf="
      selectedProduct &&
      selectedProduct.quantity &&
      (!selectedProduct.option_stepper ||
        (windowDimensions && windowDimensions.width > 800) ||
        productHasBundleOptions)
    "
  >
    <ion-row
      style="width: 100%"
      class="ion-justify-content-center ion-align-items-center"
    >
      <ion-col
        size="12"
        *ngIf="
          selectedProduct &&
          selectedProduct.notValidDeliveryArea &&
          selectedAddress
        "
      >
        <ion-item
          (click)="showProductValidDeliveryAreasInfoModal()"
          lines="none"
          button="true"
          detail="false"
        >
          <ion-label
            class="ion-text-center ion-text-wrap"
            color="danger"
            style="font-size: x-small; font-weight: bold"
          >
            {{
              "cart.cart-components.cart-list.product-has-not-valid-delivery-area-click-for-info"
                | translate
            }}
          </ion-label>
          <ion-icon
            style="font-size: 20px; margin-right: 5px"
            color="danger"
            slot="start"
            src="assets/ionicons/information-circle-outline.svg"
          >
          </ion-icon>
        </ion-item>
      </ion-col>
      <ion-col
        *ngIf="
          (!selectedProduct.disable_qty && !bundleMode) ||
          (bundleMode &&
            bundleOptions &&
            bundleOptions.enable_bundle_product_quantity)
        "
        size-xs="6"
        size-md="4"
        class="ion-no-padding ion-no-margin"
      >
        <app-change-quantity
          *ngIf="
            selectedProduct.product_categories_availability_check &&
            selectedProduct.availableTotal &&
            !disableForDeliveryMethodAddress
          "
          [textSizeMedium]="textSizeMedium"
          [buttonsSize]="changeQuantityButtonsSize"
          [disableRemoveButton]="
            selectedProduct.gift ||
            !(
              selectedProduct.product_categories_availability_check &&
              selectedProduct.availableTotal &&
              !disableForDeliveryMethodAddress
            )
          "
          [disablePlusButton]="
            selectedProduct.gift ||
            !(
              selectedProduct.product_categories_availability_check &&
              selectedProduct.availableTotal &&
              !disableForDeliveryMethodAddress
            )
          "
          [quantity]="selectedProduct.quantity"
          [productQtyWithStep]="
            selectedProduct.enable_quantity_step &&
            selectedProduct.quantity_step
          "
          [productStepKind]="selectedProduct.quantity_step_kind"
          [hideQuantityButtons]="selectedProduct.quantityOnlyFromChoices"
          (changingQuantity)="changeSelectedProductQuantity($event)"
        >
        </app-change-quantity>
      </ion-col>
      <ion-col
        *ngIf="
          (!selectedProduct.disable_qty && !bundleMode) ||
          (bundleMode &&
            bundleOptions &&
            bundleOptions.enable_bundle_product_quantity)
        "
        size-xs="6"
        size-md="4"
        class="ion-no-padding ion-no-margin"
      >
        <ion-button
          [size]="changeQuantityButtonsSize"
          [ngClass]="{
            'animate__animated animate__pulse': animateAddToCartButton,
            'add-edit-button-min-height-points': productPointsToEarn
          }"
          expand="block"
          *ngIf="selectedProduct"
          (click)="optionsValidationForAddingToCart()"
          [disabled]="
            !(
              selectedProduct.product_categories_availability_check &&
              selectedProduct.availableTotal &&
              !disableForDeliveryMethodAddress
            ) ||
            disableAddButton ||
            finalPriceLoading ||
            (selectedProduct && selectedProduct.notValidDeliveryArea)
          "
          size="medium"
        >
          <span *ngIf="!productPointsToEarn">
            {{
              !cart_id
                ? ("product-details.add" | translate)
                : ("product-details.edit" | translate)
            }}
            {{
              selectedProductTotalPrice
                | selectedProductPrice
                  : selectedProduct.gift
                  : plasticWrapperTaxProductPrice
                  : store_info
            }}
          </span>
          <div *ngIf="productPointsToEarn">
            <ion-row class="ion-align-items-center ion-justify-content-center">
              <ion-col size="12" class="ion-text-center ion-no-padding">
                {{
                  !cart_id
                    ? ("product-details.add" | translate)
                    : ("product-details.edit" | translate)
                }}
                {{
                  selectedProductTotalPrice
                    | selectedProductPrice
                      : selectedProduct.gift
                      : plasticWrapperTaxProductPrice
                      : store_info
                }}
              </ion-col>
              <ion-col size="12" class="ion-text-center ion-no-padding">
                <ion-text style="font-size: x-small; font-weight: bold">
                  {{ productPointsToEarn ? productPointsToEarn : "" }}
                  {{ "points-translation" | translate }}
                </ion-text>
              </ion-col>
            </ion-row>
          </div>
        </ion-button>
      </ion-col>
      <ion-col
        *ngIf="
          selectedProduct.disable_qty ||
          (bundleMode &&
            (!bundleOptions || !bundleOptions.enable_bundle_product_quantity))
        "
        class="ion-no-padding ion-no-margin"
        size-md="4"
        size-xs="3"
      >
      </ion-col>
      <ion-col
        *ngIf="
          selectedProduct.disable_qty ||
          (bundleMode &&
            (!bundleOptions || !bundleOptions.enable_bundle_product_quantity))
        "
        size-md="4"
        size-xs="6"
        class="ion-no-padding ion-no-margin"
      >
        <ion-button
          [size]="changeQuantityButtonsSize"
          [ngClass]="{
            'animate__animated animate__pulse': animateAddToCartButton,
            'add-edit-button-min-height-points': productPointsToEarn
          }"
          expand="block"
          *ngIf="selectedProduct"
          (click)="optionsValidationForAddingToCart()"
          [disabled]="
            !(
              selectedProduct.product_categories_availability_check &&
              selectedProduct.availableTotal &&
              !disableForDeliveryMethodAddress
            ) ||
            disableAddButton ||
            finalPriceLoading ||
            (selectedProduct && selectedProduct.notValidDeliveryArea)
          "
        >
          <span *ngIf="!productPointsToEarn">
            {{
              !cart_id
                ? ("product-details.add" | translate)
                : ("product-details.edit" | translate)
            }}
            {{
              selectedProductTotalPrice
                | selectedProductPrice
                  : selectedProduct.gift
                  : plasticWrapperTaxProductPrice
                  : store_info
            }}
          </span>
          <div *ngIf="productPointsToEarn">
            <ion-row class="ion-aling-items-center ion-justify-content-center">
              <ion-col size="12" class="ion-text-center ion-no-padding">
                {{
                  !cart_id
                    ? ("product-details.add" | translate)
                    : ("product-details.edit" | translate)
                }}
                {{
                  selectedProductTotalPrice
                    | selectedProductPrice
                      : selectedProduct.gift
                      : plasticWrapperTaxProductPrice
                      : store_info
                }}
              </ion-col>
              <ion-col size="12" class="ion-text-center ion-no-padding">
                <ion-text style="font-size: x-small; font-weight: bold">
                  {{ productPointsToEarn ? productPointsToEarn : "" }}
                  {{ "points-translation" | translate }}
                </ion-text>
              </ion-col>
            </ion-row>
          </div>
        </ion-button>
      </ion-col>
      <ion-col
        *ngIf="
          selectedProduct.disable_qty ||
          (bundleMode &&
            (!bundleOptions || !bundleOptions.enable_bundle_product_quantity))
        "
        class="ion-no-padding ion-no-margin"
        size-xs="3"
        size-md="4"
      >
      </ion-col>
      <ion-col
        *ngIf="
          (!selectedProduct.disable_qty && !bundleMode) ||
          (bundleMode &&
            bundleOptions &&
            bundleOptions.enable_bundle_product_quantity)
        "
        class="ion-no-padding ion-no-margin"
        size-xs="0"
        size-md="4 "
      >
      </ion-col>
    </ion-row>
  </ion-item>
  <ion-item
    *ngIf="
      selectedProduct &&
      selectedProduct.quantity &&
      selectedProduct.option_stepper &&
      windowDimensions.width <= 800 &&
      !productHasBundleOptions &&
      showAddToCartButtonForStepper
    "
  >
    <ion-row
      style="width: 100%"
      class="ion-justify-content-center ion-align-items-center"
    >
      <ion-col size-xs="6" size-md="4" class="ion-no-padding ion-no-margin">
        <app-change-quantity
          [textSizeMedium]="textSizeMedium"
          [buttonsSize]="changeQuantityButtonsSize"
          *ngIf="
            (!selectedProduct.disable_qty && !bundleMode) ||
            (bundleMode &&
              bundleOptions &&
              bundleOptions.enable_bundle_product_quantity)
          "
          [disableRemoveButton]="
            selectedProduct.gift ||
            !(
              selectedProduct.product_categories_availability_check &&
              selectedProduct.availableTotal &&
              !disableForDeliveryMethodAddress
            )
          "
          [disablePlusButton]="
            selectedProduct.gift ||
            !(
              selectedProduct.product_categories_availability_check &&
              selectedProduct.availableTotal &&
              !disableForDeliveryMethodAddress
            )
          "
          [quantity]="selectedProduct.quantity"
          [productQtyWithStep]="
            selectedProduct.enable_quantity_step &&
            selectedProduct.quantity_step
          "
          [productStepKind]="selectedProduct.quantity_step_kind"
          [hideQuantityButtons]="selectedProduct.quantityOnlyFromChoices"
          (changingQuantity)="changeSelectedProductQuantity($event)"
        >
        </app-change-quantity>
      </ion-col>
      <ion-col size-xs="6" size-md="4" class="ion-no-padding ion-no-margin">
        <ion-button
          [ngClass]="{
            'animate__animated animate__pulse': animateAddToCartButton,
            'add-edit-button-min-height-points': productPointsToEarn
          }"
          expand="block"
          *ngIf="selectedProduct"
          (click)="optionsValidationForAddingToCart()"
          [disabled]="
            !(
              selectedProduct.product_categories_availability_check &&
              selectedProduct.availableTotal &&
              !disableForDeliveryMethodAddress
            ) ||
            disableAddButton ||
            finalPriceLoading ||
            (selectedProduct && selectedProduct.notValidDeliveryArea)
          "
          [size]="changeQuantityButtonsSize"
        >
          <span *ngIf="!productPointsToEarn">
            {{
              !cart_id
                ? ("product-details.add" | translate)
                : ("product-details.edit" | translate)
            }}
            {{
              selectedProductTotalPrice
                | selectedProductPrice
                  : selectedProduct.gift
                  : plasticWrapperTaxProductPrice
                  : store_info
            }}
          </span>
          <div *ngIf="productPointsToEarn">
            <ion-row class="ion-align-items-center ion-justify-content-center">
              <ion-col size="12" class="ion-text-center ion-no-padding">
                {{
                  !cart_id
                    ? ("product-details.add" | translate)
                    : ("product-details.edit" | translate)
                }}
                {{
                  selectedProductTotalPrice
                    | selectedProductPrice
                      : selectedProduct.gift
                      : plasticWrapperTaxProductPrice
                      : store_info
                }}
              </ion-col>
              <ion-col size="12" class="ion-text-center ion-no-padding">
                <ion-text style="font-size: x-small; font-weight: bold">
                  {{ productPointsToEarn ? productPointsToEarn : "" }}
                  {{ "points-translation" | translate }}
                </ion-text>
              </ion-col>
            </ion-row>
          </div>
        </ion-button>
      </ion-col>
      <ion-col class="ion-no-padding ion-no-margin" size-xs="0" size-md="4 ">
      </ion-col>
    </ion-row>
  </ion-item>
  <ion-row
    *ngIf="
      selectedProduct &&
      selectedProduct.option_stepper &&
      !productHasBundleOptions &&
      windowDimensions.width <= 800 &&
      !showAddToCartButtonForStepper
    "
    style="width: 100%"
  >
    <ion-col class="ion-text-center" size="12">
      <ion-button
        [size]="changeQuantityButtonsSize"
        [disabled]="disableNextStepButton"
        (click)="nextStepInOptionsStepper()"
      >
        <ion-text *ngIf="!disableNextStepButton">{{
          "product-details.next" | translate
        }}</ion-text>
        <ion-spinner *ngIf="disableNextStepButton"></ion-spinner>
      </ion-button>
    </ion-col> </ion-row
></ion-footer>
