import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { Subscription } from "rxjs";

@Component({
  selector: "app-rating-details",
  templateUrl: "./rating-details.component.html",
  styleUrls: ["./rating-details.component.scss"],
})
export class RatingDetailsComponent implements OnInit, OnDestroy {
  @Input() currentReview: any;
  private unsubscribeBackEvent: Subscription;

  constructor(private modalCtrl: ModalController, private platform: Platform) {}

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.modalCtrl.dismiss();
        }
      }
    );
    console.log("current-review", this.currentReview);
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  ngOnDestroy() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
  }
}
