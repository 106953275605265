import { Pipe, PipeTransform } from "@angular/core";
import * as _ from "lodash";

@Pipe({
  name: "pickupOnlyPromos",
})
export class PickupOnlyPromosPipe implements PipeTransform {
  transform(array: any): any[] {
    return _.filter(array, {
      pickupOnly: true,
    });
  }
}
