import * as StoresActions from "./stores.actions";
import * as _ from "lodash";

export interface State {
  stores: any;
  checkStoreOnlineInfo: any;
}

const initialState: State = {
  stores: null,
  checkStoreOnlineInfo: null,
};

export function storesReducer(
  state = initialState,
  action: StoresActions.StoresActions
) {
  switch (action.type) {
    case StoresActions.SET_STORES:
      return {
        ...state,
        stores: action.payload,
      };
    case StoresActions.SET_CHECK_STORE_INFO:
      return {
        ...state,
        checkStoreOnlineInfo: action.payload,
      };

    case StoresActions.CLEAR_STORES:
      return {
        ...state,
        stores: null,
      };

    default:
      return state;
  }
}
