import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { ModalController, Platform, ToastController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import {
  audit,
  auditTime,
  catchError,
  distinctUntilChanged,
  first,
} from "rxjs/operators";
import * as fromApp from "../../../../store/app.reducer";
import * as selectors from "../../../../store/app.selectors";
import * as _ from "lodash";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import * as AuthActions from "../../../../auth/store/auth.actions";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import * as SelectedInvoiceActions from "../store/selectedInvoice.actions";

@Component({
  selector: "app-invoice-add-edit-modal",
  templateUrl: "./invoice-add-edit-modal.component.html",
  styleUrls: ["./invoice-add-edit-modal.component.scss"],
})
export class InvoiceAddEditModalComponent implements OnInit, OnDestroy {
  @Input() addOrEdit: string; // if "add"  modal adding, else if "edit" editing
  @Input() fromAccountPage: boolean;
  public addEditForm;
  private currentUserInvoices;
  @Input() invoiceForEdit;
  public newInvoiceLoading: boolean;
  private currentUser;
  private mo_token: any;
  private subscription: Subscription;
  private doEditedSelected: boolean = false;
  private unsubscribeBackEvent: Subscription;

  @ViewChild("vatNumberInput", { static: false }) vatNumberinputElement;

  constructor(
    private modalCtrl: ModalController,
    private store: Store<fromApp.AppState>,
    private dataStorageService: DataStorageService,
    private toastController: ToastController,
    private translateService: TranslateService,
    private platform: Platform
  ) {}

  ionViewDidEnter() {}

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      300,
      () => {
        if (this.platform.is("android")) {
          this.modalCtrl.dismiss();
        }
      }
    );
    this.subscription = this.store
      .select("auth")
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (state && !_.isEqual(this.currentUser, state.user)) {
          this.currentUser = _.cloneDeep(state.user);
          this.currentUserInvoices = _.cloneDeep(state.user.invoiceData);
          if (this.addOrEdit === "edit") {
            let indexOfEditedInvoice = _.findIndex(this.currentUserInvoices, {
              vatNumber: this.invoiceForEdit.vatNumber,
            });
            if (indexOfEditedInvoice !== -1) {
              if (
                !_.isEqual(
                  this.invoiceForEdit,
                  this.currentUserInvoices[indexOfEditedInvoice]
                ) &&
                this.currentUserInvoices.checked
              ) {
              } else {
              }
            }
          }
        }
        if (
          state &&
          state.mo_token &&
          !_.isEqual(this.mo_token, state.mo_token)
        ) {
          this.mo_token = _.cloneDeep(state.mo_token);
        }
      });

    if (this.addOrEdit === "add") {
      this.addEditForm = new FormGroup({
        vatNumber: new FormControl("", [
          Validators.required,
          this.vatValidator,
        ]),
        company_name: new FormControl("", [Validators.required]),
        company_address: new FormControl("", [Validators.required]),
        vatOffice: new FormControl("", [Validators.required]),
        activity: new FormControl("", [Validators.required]),
      });
    } else if (this.addOrEdit === "edit") {
      this.addEditForm = new FormGroup({
        vatNumber: new FormControl(this.invoiceForEdit.vatNumber),
        company_name: new FormControl(this.invoiceForEdit.company_name, [
          Validators.required,
        ]),
        company_address: new FormControl(this.invoiceForEdit.company_address, [
          Validators.required,
        ]),
        vatOffice: new FormControl(this.invoiceForEdit.vatOffice, [
          Validators.required,
        ]),
        activity: new FormControl(this.invoiceForEdit.activity, [
          Validators.required,
        ]),
      });
    }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  addNewVatNumber() {
    console.log(
      "tttt",
      this.addEditForm.get("vatNumber").value,
      this.addEditForm.get("vatNumber").value.length > 8,
      this.addEditForm.get("vatNumber").value.match(/^[0-9]+$/)
    );
    if (
      this.addEditForm.get("vatNumber").value.length > 8 &&
      this.addEditForm.get("vatNumber").value.match(/^[0-9]+$/)
    ) {
      let invoice = {
        vatNumber: this.addEditForm.get("vatNumber").value,
        timestamp: Date.now(),
      };
      console.log(invoice);

      let newUser = _.cloneDeep(this.currentUser);

      if (newUser && newUser.invoiceData) {
        newUser.invoiceData.push(invoice);

        this.updateCustomerData(
          { updatedData: newUser },
          this.mo_token,
          false,
          invoice
        );
      }
    } else {
      if (!this.addEditForm.get("vatNumber").value.match(/^[0-9]+$/)) {
        this.presentErrorToast(
          this.translateService.instant(
            "cart.cart-components.invoice.invoice-add-edit-modal.vat-number-can-contain-only-chars"
          ),
          "danger"
        );
      } else {
        this.presentErrorToast(
          this.translateService.instant(
            "cart.cart-components.invoice.invoice-add-edit-modal.please-type-a-valid-vat-number"
          ),
          "danger"
        );
      }
    }
  }

  vatValidator(v: AbstractControl) {
    //console.log(v.value.length);

    if (v && v.value && v.value.length == 9) {
      return null;
    } else {
      return { vatLength: true };
    }
  }

  updateCustomerData(obj, mo_token, edit, editedInvoice) {
    this.newInvoiceLoading = true;
    if (obj && mo_token) {
      this.dataStorageService

        .updateCustomerData(obj, mo_token)

        .subscribe({
          next: (res: any) => {
            if (res && res.success) {
              let updatedUser = res.data;
              this.store.dispatch(
                new AuthActions.SetUser(_.cloneDeep(updatedUser))
              );

              if (!edit) {
                this.invoiceForEdit = _.find(res.data.invoiceData, {
                  vatNumber: editedInvoice.vatNumber,
                });
                if (this.invoiceForEdit) {
                  editedInvoice = _.cloneDeep(this.invoiceForEdit);
                  console.log("edited invoice", editedInvoice);
                }

                this.addOrEdit = "edit";
                this.doEditedSelected = true;
                this.addEditForm = new FormGroup({
                  vatNumber: new FormControl(this.invoiceForEdit.vatNumber),
                  company_name: new FormControl(
                    this.invoiceForEdit.company_name,
                    [Validators.required]
                  ),
                  company_address: new FormControl(
                    this.invoiceForEdit.company_address,
                    [Validators.required]
                  ),
                  vatOffice: new FormControl(this.invoiceForEdit.vatOffice, [
                    Validators.required,
                  ]),
                  activity: new FormControl(this.invoiceForEdit.activity, [
                    Validators.required,
                  ]),
                });
              } else {
        

                this.store
                  .select("selectedInvoice")
                  .pipe(first())
                  .subscribe((state) => {
                    if (state && state.selectedInvoice) {
                      //console.log("selected invoice", state.selectedInvoice);
                      let selectedInvoice = _.cloneDeep(state.selectedInvoice);
                     
                      if (
                        selectedInvoice.vatNumber === editedInvoice.vatNumber
                      ) {
                        editedInvoice.enabled = true;
                        //console.log("set selected invoice from edit");
                        this.store.dispatch(
                          new SelectedInvoiceActions.SetSelectedInvoice(
                            _.cloneDeep(editedInvoice)
                          )
                        );
                        this.store
                          .select(selectors.getStoreInfo)
                          .pipe(first())
                          .subscribe((storeInfo) => {
                            if (
                              storeInfo &&
                              storeInfo.vatBasedCustomerProductsActive
                            ) {
                              this.setMigrationCustomerId(
                                editedInvoice.migration_customer_id
                              );
                            }
                          })
                          .unsubscribe();
                      }
                    } else if (
                      state &&
                      !state.selectedInvoice &&
                      this.doEditedSelected &&
                      !this.fromAccountPage
                    ) {
                      this.doEditedSelected = false;
                      //console.log("set selected invoice from edit");
                      editedInvoice.enabled = true;
                      this.store.dispatch(
                        new SelectedInvoiceActions.SetSelectedInvoice(
                          _.cloneDeep(editedInvoice)
                        )
                      );
                      this.store
                        .select(selectors.getStoreInfo)
                        .pipe(first())
                        .subscribe((storeInfo) => {
                          if (
                            storeInfo &&
                            storeInfo.vatBasedCustomerProductsActive
                          ) {
                            this.setMigrationCustomerId(
                              editedInvoice.migration_customer_id
                            );
                          }
                        })
                        .unsubscribe();
                    }
                  })
                  .unsubscribe();
                this.modalCtrl.dismiss();
              }

              this.newInvoiceLoading = false;
            } else {
              let errMsg = this.translateService.instant(
                "cart.cart-components.invoice.invoice-add-edit-modal.an-error-occured-on-add-invoice"
              );

              this.presentErrorToast(
                this.translateService.instant(errMsg),
                "danger"
              );
              console.log(res.comment_id);
              console.log(res.comments);

              this.newInvoiceLoading = false;
            }
          },
          error: (err) => {
            let errMsg = this.translateService.instant(
              "cart.cart-components.invoice.invoice-add-edit-modal.an-error-occured-on-add-invoice"
            );
            this.newInvoiceLoading = false;
            this.presentErrorToast(errMsg, "danger");
            return err;
            console.log("error updating the information");
          },
        });
    }
  }

  setMigrationCustomerId(migration_customer_id) {
    this.store.dispatch(
      new AuthActions.SetMigrationCustomerId(migration_customer_id)
    );
    if (navigator.cookieEnabled) {
      localStorage.setItem("migration_customer_id", migration_customer_id);
    }
  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            //console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }

  // getFormValidationErrors() {
  //   Object.keys(this.addEditForm.controls).forEach((key) => {
  //     const controlErrors: ValidationErrors = this.addEditForm.get(key).errors;
  //     if (controlErrors != null) {
  //       Object.keys(controlErrors).forEach((keyError) => {
  //         console.log(
  //           "Key control: " + key + ", keyError: " + keyError + ", err value: ",
  //           controlErrors[keyError]
  //         );
  //       });
  //     }
  //   });
  // }

  onSubmit() {
    if (this.addEditForm.valid && this.currentUserInvoices) {
      let invoiceState = _.find(this.currentUserInvoices, {
        vatNumber: this.addEditForm.get("vatNumber").value,
      });
      if (
        invoiceState &&
        (invoiceState.checked == true || invoiceState.checked == true)
      ) {
        this.invoiceForEdit = {
          vatNumber: this.addEditForm.get("vatNumber").value,
          company_name: this.addEditForm.get("company_name").value,
          company_address: this.addEditForm.get("company_address").value,
          vatOffice: this.addEditForm.get("vatOffice").value,
          activity: this.addEditForm.get("activity").value,
          migration_customer_id: invoiceState.migration_customer_id,
          checked: invoiceState.checked,
        };
      } else {
        this.invoiceForEdit = {
          vatNumber: this.addEditForm.get("vatNumber").value,
          company_name: this.addEditForm.get("company_name").value,
          company_address: this.addEditForm.get("company_address").value,
          vatOffice: this.addEditForm.get("vatOffice").value,
          activity: this.addEditForm.get("activity").value,
          migration_customer_id: invoiceState.migration_customer_id,
        };
      }

      let index = _.findIndex(this.currentUserInvoices, {
        vatNumber: this.invoiceForEdit.vatNumber,
      });
      if (index == -1) {
        console.log(this.currentUserInvoices);
        this.currentUserInvoices.push(this.invoiceForEdit);
      } else {
        this.currentUserInvoices[index] = this.invoiceForEdit;
      }
      let newUser = _.cloneDeep(this.currentUser);
      newUser.invoiceData = this.currentUserInvoices;
      this.updateCustomerData(
        { updatedData: newUser },
        this.mo_token,
        true,
        this.invoiceForEdit
      );
    }
  }

  ngOnDestroy() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
