import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { auditTime, distinctUntilChanged } from "rxjs/operators";
import * as fromApp from "../../../store/app.reducer";
import * as _ from "lodash";
import * as CheckboxPromotionsActions from "./store/checkbox-promotions.actions";
import { ToastController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";

@Component({
  selector: "app-checkbox-promotions",
  templateUrl: "./checkbox-promotions.component.html",
  styleUrls: ["./checkbox-promotions.component.scss"],
})
export class CheckboxPromotionsComponent implements OnInit, OnDestroy {
  public checkboxPromotions: any;
  public finalPriceLoading: boolean;
  public noOnlinePayNoPickupPromos: any;
  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store<fromApp.AppState>,
    private toastController: ToastController,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.store.select("disableFinalPrice").subscribe((state) => {
        if (
          state &&
          !_.isEqual(state.disableFinalPrice, this.finalPriceLoading)
        ) {
          this.finalPriceLoading = _.cloneDeep(state.disableFinalPrice);
        }
      })
    );

    this.subscriptions.push(
      this.store
        .select("checkboxPromotions")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.checkboxPromotions &&
            !_.isEqual(this.checkboxPromotions, state.checkboxPromotions)
          ) {
            this.checkboxPromotions = _.cloneDeep(state.checkboxPromotions);

            this.noOnlinePayNoPickupPromos = _.filter(
              this.checkboxPromotions,
              (promos) => {
                return !promos.onlinePaymentOnly && !promos.pickupOnly;
              }
            );
          }
        })
    );
  }

  clickCheckboxPromotion(promo) {
    this.store.dispatch(
      new CheckboxPromotionsActions.SetCheckboxPromotions(
        _.cloneDeep(this.checkboxPromotions)
      )
    );
    if (navigator.cookieEnabled) {
      window.localStorage.setItem(
        "checkboxPromotions",
        JSON.stringify(this.checkboxPromotions)
      );
    }

  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
           // console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }

  trackbyFnc(index, item) {
    return item.promo_id;
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }
}
