import * as BranchLinkDataActions from "./branchLinkData.actions";
import * as _ from "lodash";

export interface State {
  branchLinkData: string;
  coupon_rule_id: any;
  affiliate_coupon_code: any;
  timestamp: any;
}

const initialState: State = {
  branchLinkData: null,
  coupon_rule_id: null,
  affiliate_coupon_code: null,
  timestamp: null,
};

export function branchLinkDataReducer(
  state = initialState,
  action: BranchLinkDataActions.BranchLinkDataActions
) {
  switch (action.type) {
    case BranchLinkDataActions.SET_BRANCH_LINK_DATA:
      return {
        ...state,
        branchLinkData: action.payload,
      };
    case BranchLinkDataActions.SET_TRIGGER_FOR_NEW_BRANCH_LINK_DATA:
      return {
        ...state,
        timestamp: action.payload,
      };
    case BranchLinkDataActions.SET_COUPON_RULE_ID:
      return {
        ...state,
        coupon_rule_id: action.payload,
      };
    case BranchLinkDataActions.SET_AFFILIATE_COUPON_CODE:
      return {
        ...state,
        affiliate_coupon_code: action.payload,
      };
    case BranchLinkDataActions.CLEAR_BRANCH_LINK_DATA:
      return {
        ...state,
        branchLinkData: null,
      };
    case BranchLinkDataActions.CLEAR_COUPON_RULE_ID:
      return {
        ...state,
        coupon_rule_id: null,
      };
    case BranchLinkDataActions.CLEAR_AFFILIATE_COUPON_CODE:
      return {
        ...state,
        affiliate_coupon_code: null,
      };

    default:
      return state;
  }
}
