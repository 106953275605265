import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../../store/app.reducer";
import * as _ from "lodash";
import { distinctUntilChanged, Subscription } from "rxjs";

@Component({
  selector: "app-no-quantity-options",
  templateUrl: "./no-quantity-options.component.html",
  styleUrls: ["./no-quantity-options.component.scss"],
})
export class NoQuantityOptionsComponent implements OnInit, OnDestroy {
  @Input() store_info: any;
  @Input() cart: any;
  @Input() selectedPaymentMethodID: any;
  @Input() orderPickup: boolean;
  @Input() dineIn: boolean;
  public valid: boolean;
  public cartCustomerOptions: any;
  public storeInfoClone;
  public renderedStoreInfo: any;
  public selectedLang: any;
  private notValidCustomOptions: any;
  private subscription: Subscription;
  private subscription2: Subscription;
  constructor(private store: Store<fromApp.AppState>) {}

  ngOnInit() {
    if (this.store_info && this.store_info.cartCustomOptions) {
      this.store_info.cartCustomOptions = _.cloneDeep(
        _.orderBy(
          this.store_info.cartCustomOptions,
          (item) => (item.position ? item.position : item.title),
          ["asc"]
        )
      );
    }

    this.subscription = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (state && !_.isEqual(this.selectedLang, state.selectedLangugage)) {
          this.selectedLang = _.cloneDeep(state.selectedLangugage);
          if (!this.selectedLang) {
            this.selectedLang = "el";
          }
          console.log("selected lang for cart custom", this.selectedLang);
        }
      });

    this.subscription2 = this.store
      .select("cartCustomerOptions")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          !_.isEqual(this.cartCustomerOptions, state.cartCustomerOptions)
        ) {
          this.cartCustomerOptions = _.cloneDeep(state.cartCustomerOptions);
          this.checkNotValidCustomOptions();
        }
        if (
          state &&
          !_.isEqual(this.notValidCustomOptions, state.notValidCustomOptions)
        ) {
          this.notValidCustomOptions = _.cloneDeep(state.notValidCustomOptions);
          this.checkNotValidCustomOptions();
        }
      });
  }

  checkNotValidCustomOptions() {
    _.each(this.store_info.cartCustomOptions, (option, key) => {
      const notValidOptionIndex = _.findIndex(this.notValidCustomOptions, {
        timestamp: option.timestamp,
      });
      if (notValidOptionIndex !== -1) {
        this.store_info.cartCustomOptions[key].notValid = true;
      } else {
        this.store_info.cartCustomOptions[key].notValid = false;
      }
    });
  }

  trackTimestamp(index, option) {
    return option.timestamp;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
