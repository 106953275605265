import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import * as fromApp from "../../../store/app.reducer";
import * as selectors from "../../../store/app.selectors";
import * as _ from "lodash";
import { auditTime, distinctUntilChanged, first, Subscription } from "rxjs";
import { Store } from "@ngrx/store";

@Component({
  selector: "app-favorites-browser",
  templateUrl: "./favorites-browser.component.html",
  styleUrls: ["./favorites-browser.component.scss"],
})
export class FavoritesBrowserComponent implements OnInit, OnDestroy {
  @Input() enableFavoritesPageSearch: boolean;
  @Input() mobileMode: boolean;
  public renderedFavoriteProducts: any;
  public favoritesSearchTerm: string;
  public allFavoriteProductsBeforeSearch: any;
  public favoriteProducts: any;
  public hyperCategories: any;
  public categories: any;
  public cartProducts: any;
  public cartProductIds: any;
  public groupApp: any;
  public groupStoreData: any = {};
  public webp: any;
  public orderPickup: any;
  public dineIn: any;
  public storeInfo: any;
  public selectedDineInTable: any;
  public selectedPreorderDate: any;
  public selectedLanguage;
  public mo_base;
  public finalPriceLoading;
  public windowDimensions;
  public allCategoriesMarketCardsDesign;
  public colSize;
  public gridMaxWidth = 1150;

  private storeSub: Subscription;
  private hyperCategoriesSubscription: Subscription;
  private categoriesSubscription: Subscription;
  private cartSub: Subscription;
  private subscription: Subscription;
  private deliveryMethodAddressSub: Subscription;
  private groupAppSub: Subscription;
  private windowInfoSub: Subscription;
  private disableFinalPriceSub: Subscription;
  private catalogSub: Subscription;

  constructor(private store: Store<fromApp.AppState>) {}

  ngOnInit() {
    this.deliveryMethodAddressSub = this.store
      .select("deliveryMethodAddress")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (state && !_.isEqual(state, state.orderPickup)) {
          this.orderPickup = _.cloneDeep(state.orderPickup);
        }
        if (state && !_.isEqual(state, state.dineIn)) {
          this.dineIn = _.cloneDeep(state.dineIn);
        }
        if (state && !_.isEqual(state, state.selectedDineInTable)) {
          this.selectedDineInTable = _.cloneDeep(state.selectedDineInTable);
        }
        if (state && !_.isEqual(state, state.selectedPreorderDate)) {
          this.selectedPreorderDate = _.cloneDeep(state.selectedPreorderDate);
        }
      });

    this.groupAppSub = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.groupApp &&
          !_.isEqual(this.groupApp, state.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp);
          this.groupStoreData.groupApp = {
            group: _.cloneDeep(this.groupApp.group),
            disableCatalogPhoto: _.cloneDeep(this.groupApp.disableCatalogPhoto),
            pointsSystem: _.cloneDeep(this.groupApp.pointsSystem),
            iconImage: _.cloneDeep(this.groupApp.iconImage),
          };
          this.groupStoreData.groupApp.mo_base = _.find(
            _.cloneDeep(this.groupApp.languages),
            { mo_base: true }
          );
        }
        if (
          state &&
          state.selectedLangugage &&
          !_.isEqual(this.selectedLanguage, state.selectedLangugage)
        ) {
          this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
          this.groupStoreData.groupApp.selectedLanguage = _.cloneDeep(
            this.selectedLanguage
          );
        }
        //console.log("serch component groupStoreData", this.groupStoreData);
        if (state && state.webp && !_.isEqual(this.webp, state.webp)) {
          this.webp = _.cloneDeep(state.webp);
          this.groupStoreData.webp = _.cloneDeep(this.webp);
        }
      });

    this.storeSub = this.store
      .select(selectors.getStoreInfo)
      .pipe(distinctUntilChanged())
      .subscribe((storeInfo) => {
        if (storeInfo && !_.isEqual(this.storeInfo, storeInfo)) {
          this.storeInfo = _.cloneDeep(storeInfo);
          const catalogCustomTagsById = {};
          _.each(_.cloneDeep(this.storeInfo.catalogCustomTags), (tag) => {
            catalogCustomTagsById[tag.id] = tag;
          });
          this.groupStoreData.store_info = {
            store_id: storeInfo.store_id,
            overridePromoItemsForPointsSystem: _.cloneDeep(
              this.storeInfo.overridePromoItemsForPointsSystem
            ),
            alt_dinein_active: storeInfo.dinein
              ? _.cloneDeep(this.storeInfo.alt_dinein_active)
              : null,
            catalogCustomTagsById: catalogCustomTagsById,
            design_settings: storeInfo.design_settings,
          };
        }
      });

    this.hyperCategoriesSubscription = this.store
      .select("hyperCategories")
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (
          state &&
          state.hyperCategories &&
          !_.isEqual(state.hyperCategories, this.hyperCategories)
        ) {
          this.hyperCategories = _.cloneDeep(state.hyperCategories);
          if (this.hyperCategories && this.hyperCategories.length === 0) {
            this.subscribeToCategories(false);
          } else if (this.hyperCategories && this.hyperCategories.length > 0) {
            this.subscribeToCategories(true);
          }
        }
      });

    this.windowInfoSub = this.store
      .select("windowInfo")
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (
          state &&
          state.windowDimensions &&
          !_.isEqual(state.windowDimensions, this.windowDimensions)
        ) {
          this.windowDimensions = _.cloneDeep(state.windowDimensions);
          this.groupStoreData.windowDimensions = _.cloneDeep(
            state.windowDimensions
          );
          this.store
            .select("catalog")
            .pipe(first())
            .subscribe((state) => {
              if (state && state.allCategoriesMarketCardsDesign) {
                this.calculateColSizeGridMaxWidth();
              }
            });
        }
      });

    this.catalogSub = this.store
      .select("catalog")
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (
          state &&
          !_.isEqual(
            state.allCategoriesMarketCardsDesign,
            this.allCategoriesMarketCardsDesign
          )
        ) {
          this.allCategoriesMarketCardsDesign = _.cloneDeep(
            state.allCategoriesMarketCardsDesign
          );
        }
      });

    this.disableFinalPriceSub = this.store
      .select("disableFinalPrice")
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (
          state &&
          !_.isEqual(state.disableFinalPrice, this.finalPriceLoading)
        ) {
          this.finalPriceLoading = _.cloneDeep(state.disableFinalPrice);
        }
      });

    this.cartSub = this.store
      .select("cart")
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (
          state &&
          state.products &&
          !_.isEqual(this.cartProducts, state.products)
        ) {
          this.cartProducts = _.cloneDeep(state.products);
          this.cartProductIds = [];
          _.each(this.cartProducts, (product) => {
            if (
              !product.quantity ||
              product.quantity === 0 ||
              product.quantity === 1 ||
              (product.enable_quantity_step && product.quantity_step)
            ) {
              this.cartProductIds.push(_.cloneDeep(product.product_id));
            } else {
              for (let i = 0; i < product.quantity; i++) {
                this.cartProductIds.push(_.cloneDeep(product.product_id));
              }
            }
            this.calculateAllProductIncudedIncart();
          });
        }
      });
  }

  subscribeToCategories(hyperCategories) {
    if (this.hyperCategoriesSubscription) {
      this.hyperCategoriesSubscription.unsubscribe();
    }
    if (hyperCategories) {
      this.categoriesSubscription = this.store
        .select("hyperCategories")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.allCategories &&
            !_.isEqual(state.allCategories, this.categories)
          ) {
            this.categories = _.cloneDeep(state.allCategories);
            this.subscriptionToFavorites();
          }
        });
    } else {
      this.categoriesSubscription = this.store
        .select("catalog")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.allCategories &&
            !_.isEqual(state.allCategories, this.categories)
          ) {
            this.categories = _.cloneDeep(state.allCategories);
            this.subscriptionToFavorites();
          }
        });
    }
  }

  handleResetSearch() {
    this.renderedFavoriteProducts = _.cloneDeep(
      this.allFavoriteProductsBeforeSearch
    );
    this.calculateAllProductIncudedIncart();
  }

  onSearch() {
    if (this.favoritesSearchTerm) {
      console.log(
        "favoritesSearchTerm",
        this.favoritesSearchTerm,
        this.allFavoriteProductsBeforeSearch
      );
      this.renderedFavoriteProducts = _.filter(
        _.cloneDeep(this.allFavoriteProductsBeforeSearch),
        (product) => {
          if (
            (product.name &&
              product.name
                .toString()
                .toLowerCase()
                .indexOf(this.favoritesSearchTerm.toLowerCase()) > -1) ||
            (product.description &&
              product.description
                .toString()
                .toLowerCase()
                .indexOf(this.favoritesSearchTerm.toLowerCase()) > -1)
          ) {
            return product;
          }
        }
      );
      // console.log("rendered fav prd", this.renderedFavoriteProducts);
    } else {
      this.renderedFavoriteProducts = _.cloneDeep(
        this.allFavoriteProductsBeforeSearch
      );
    }
    this.calculateAllProductIncudedIncart();
  }

  subscriptionToFavorites() {
    if (!this.subscription) {
      this.subscription = this.store
        .select("favoriteProducts")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.favoriteProducts &&
            !_.isEqual(this.favoriteProducts, state.favoriteProducts)
          ) {
            this.favoriteProducts = _.cloneDeep(state.favoriteProducts);
            this.renderedFavoriteProducts = [];
            // console.log(
            //   "favorites categories",
            //   this.categories,
            //   this.favoriteProducts
            // );
            _.each(this.categories, (category) => {
              _.each(category.products, (category_product) => {
                _.each(this.favoriteProducts, (favorite_product) => {
                  if (
                    category_product.product_id === favorite_product.product_id
                  ) {
                    let prd = _.cloneDeep(category_product);
                    prd.category = _.cloneDeep(category);

                    if (
                      !_.find(this.renderedFavoriteProducts, {
                        product_id: prd.product_id,
                      })
                    ) {
                      this.renderedFavoriteProducts.push(prd);
                    }
                  }
                });
              });
            });
            this.allFavoriteProductsBeforeSearch = _.cloneDeep(
              this.renderedFavoriteProducts
            );
            //console.log("rendered favorites", this.renderedFavoriteProducts);
          }
        });
      this.calculateAllProductIncudedIncart();
    }
  }

  favoriteProductsTrackFn(index, item) {
    item.product_id;
  }

  calculateColSizeGridMaxWidth() {
    this.store
      .select(selectors.getStoreInfo)
      .pipe(first())
      .subscribe((storeInfo) => {
        this.store
          .select("hyperCategories")
          .pipe(first())
          .subscribe((hyperCatState) => {
            this.store
              .select("catalog")
              .pipe(first())
              .subscribe((state) => {
                if (
                  hyperCatState.hyperCategories &&
                  hyperCatState.hyperCategories.length > 0
                ) {
                  if (
                    storeInfo &&
                    storeInfo.design_settings &&
                    storeInfo.design_settings.mobileorderHideCartCatalog &&
                    state.allCategoriesMarketCardsDesign
                  ) {
                    if (this.windowDimensions.width >= 1600) {
                      this.colSize = "2";
                      this.gridMaxWidth = 1150;
                    } else if (this.windowDimensions.width < 1600) {
                      this.colSize = "3";
                      this.gridMaxWidth = 780;
                    }
                  } else {
                    this.colSize = "4";
                    this.gridMaxWidth = 1150;
                  }
                } else if (
                  hyperCatState &&
                  hyperCatState.hyperCategories &&
                  hyperCatState.hyperCategories.length === 0
                ) {
                  if (
                    storeInfo &&
                    ((storeInfo.design_settings &&
                      storeInfo.design_settings.mobileorderHideCartCatalog) ||
                      this.enableFavoritesPageSearch) &&
                    state.allCategoriesMarketCardsDesign
                  ) {
                    if (this.windowDimensions.width >= 1600) {
                      this.colSize = "2";
                      this.gridMaxWidth = 1150;
                    } else if (this.windowDimensions.width < 1600) {
                      this.colSize = "3";
                      this.gridMaxWidth = 780;
                    }
                  } else {
                    this.colSize = "4";
                    this.gridMaxWidth = 1150;
                  }
                }
              })
              .unsubscribe();
          })
          .unsubscribe();
      })
      .unsubscribe();
  }

  calculateAllProductIncudedIncart() {
    _.each(this.renderedFavoriteProducts, (prd, indx) => {
      this.renderedFavoriteProducts[indx] = this.calculateProductIncudedIncart(
        _.cloneDeep(prd)
      );
    });
  }

  calculateProductIncudedIncart(product) {
    if (product.productIncludedInCart) {
      delete product.productIncludedInCart;
    }

    if (
      _.find(_.cloneDeep(this.cartProducts), {
        product_id: product.product_id,
      })
    ) {
      product.productIncludedInCart = _.find(_.cloneDeep(this.cartProducts), {
        product_id: product.product_id,
      });
    }

    return product;
  }

  ngOnDestroy() {
    if (this.windowInfoSub) {
      this.windowInfoSub;
      this.windowInfoSub.unsubscribe();
    }
    if (this.catalogSub) {
      this.catalogSub.unsubscribe();
    }
    if (this.disableFinalPriceSub) {
      this.disableFinalPriceSub;
      this.disableFinalPriceSub.unsubscribe();
    }

    if (this.groupAppSub) {
      this.groupAppSub.unsubscribe();
    }

    if (this.deliveryMethodAddressSub) {
      this.deliveryMethodAddressSub.unsubscribe();
    }

    if (this.hyperCategoriesSubscription) {
      this.hyperCategoriesSubscription.unsubscribe();
    }
    if (this.categoriesSubscription) {
      this.categoriesSubscription.unsubscribe();
    }
    if (this.cartSub) {
      this.cartSub.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
  }
}
