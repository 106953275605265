<ion-row style="width: 100%">
  <ion-col *ngIf="currentUser" size="12">
    <app-customer-info></app-customer-info>
  </ion-col>
  <ion-col *ngIf="currentUser && orderPickup == false && dineIn == false">
    <app-delivery-info></app-delivery-info>
  </ion-col>
  <ion-col *ngIf="currentUser">
    <app-delivery-method-address
      [cartMode]="true"
    ></app-delivery-method-address>
    <app-cart-preorder-item> </app-cart-preorder-item>
  </ion-col>
  <ion-col size="12">
    <app-payment-methods></app-payment-methods>
  </ion-col>
  <ion-col *ngIf="!currentUser" size="12">
    <app-customer-info></app-customer-info>
    <app-delivery-method-address
      [cartMode]="true"
    ></app-delivery-method-address>
    <app-cart-preorder-item> </app-cart-preorder-item>
  </ion-col>
  <ion-col size="12">
    <app-cart-points></app-cart-points>
  </ion-col>
  <ion-col size="12">
    <app-extra-choices></app-extra-choices>
    <app-add-coupon-code
      *ngIf="showAddCouponCodeComponent"
    ></app-add-coupon-code>
    <app-cart-active-coupons></app-cart-active-coupons>
    <app-checkbox-promotions></app-checkbox-promotions>
    <app-order-comments></app-order-comments>
    <app-cart-list></app-cart-list>
  </ion-col>
</ion-row>
