<span
  *ngFor="
    let subCategory of storeInfo &&
    storeInfo.design_settings &&
    storeInfo.design_settings.showOnlySelectedCategoryProducts
      ? filteredSubCategoriesArray
      : subCategoriesArray;
    trackBy: trackSubCategories
  "
>
  <ion-item
    *ngIf="subCategory.subCategoryId && subCategory.subCategoryId !== 'virtual'"
    [class]="{
      categoryItem:
        subCategory.subCategoryId && subCategory.subCategoryId !== 'virtual'
    }"
  >
    <ion-text slot="end" style="font-weight: 700">{{
      subCategory.name
    }}</ion-text>
  </ion-item>

  <div
    *ngIf="storeInfo"
    [class]="{
      topItemBorder:
        subCategory.lastCategory && subCategory.subCategoryId === 'virtual'
    }"
  >
    <span
      *ngFor="
        let product of subCategory.products
          | filterAvailableProducts : storeInfo.showAllUnavailableProducts
          | filterOnlyDineInDeliveryPickupProducts : orderPickup : dineIn;
        trackBy: trackProducts
      "
    >
      <app-product-items
        *ngIf="product.activeTotal && category.activeTotal"
        [category_id]="product.category_id"
        [productImage]="product.image"
        [product_id]="product.product_id"
        [hyperCategories]="hyperCategories"
        [cartProductIds]="cartProductIds"
        [groupStoreData]="groupStoreData"
        [selectedDineInTable]="selectedDineInTable"
        [selectedPreorderDate]="selectedPreorderDate"
        [dineIn]="dineIn"
        [orderPickup]="orderPickup"
        [webp]="webp"
      ></app-product-items>
    </span>
  </div>
</span>

<ion-row
  style="width: 100%"
  *ngIf="
    storeInfo &&
    storeInfo.design_settings &&
    storeInfo.design_settings.showOnlySelectedCategoryProducts &&
    filteredSubCategoriesArray &&
    filteredSubCategoriesArray.length !== subCategoriesArray.length
  "
>
  <ion-col class="ion-text-center">
    <ion-spinner name="bubbles"></ion-spinner>
  </ion-col>
</ion-row>
