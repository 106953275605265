import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { IonSlides, ModalController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { distinctUntilChanged, Observable, Subscription, from } from "rxjs";
import * as _ from "lodash";
import * as fromApp from "../../../store/app.reducer";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import { PreviousOrderModalComponent } from "../previous-order-modal/previous-order-modal.component";
import * as PreviousOrdersActions from "../../../catalog/previous-order/store/previousOrders.actions";

@Component({
  selector: "app-previous-orders-slider-mobile",
  templateUrl: "./previous-orders-slider-mobile.component.html",
  styleUrls: ["./previous-orders-slider-mobile.component.scss"],
})
export class PreviousOrdersSliderMobileComponent implements OnInit {
  @ViewChild("previousOrdersSlider") slider: IonSlides;
  @Input() sliderButtons: boolean;
  public previousOrders: any;
  public currentUser: any;
  public browserPlatform: any;
  public groupApp: any;
  public isBeginning: Observable<boolean>;
  public isEnd: Observable<boolean>;
  public webp: any;
  private authSubscription: Subscription;
  private previousOrdersSubscription: Subscription;
  public sliderOptions = {
    allowTouchMove: true,
    slidesPerView: "auto",
    direction: "horizontal",
    slideToClickedSlide: true,
    mousewheel: true,
    freeMode: true,
    freeModeMomentumBounce: false,

    // autoplay:true,
    spaceBetween: 2,

    watchSlidesVisibility: true,
  };
  private previousOrderModal: any;

  constructor(
    private store: Store<fromApp.AppState>,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    this.authSubscription = this.store
      .select("auth")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (state && !_.isEqual(state.user, this.currentUser)) {
          this.currentUser = _.cloneDeep(state.user);
        }
      });

    this.previousOrdersSubscription = this.store
      .select("previousOrders")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.previousOrders &&
          !_.isEqual(state.previousOrders, this.previousOrders)
        ) {
          this.previousOrders = _.cloneDeep(state.previousOrders);

          console.log("previous orders", this.previousOrders);
        }
      });
  }

  trackOrders(index, order) {
    return order.order_id.toString() + "_" + order.store_id.toString();
  }

  showPreviousOrderModal(selectedOrder) {
    this.store.dispatch(
      new PreviousOrdersActions.SetPreviousOrderCart(
        _.cloneDeep(selectedOrder.products)
      )
    );

    this.presentPreviousOrderModal(selectedOrder.order_id);
  }

  async presentPreviousOrderModal(order_id) {
    this.previousOrderModal = await this.modalController.create({
      component: PreviousOrderModalComponent,
      cssClass: "previousOrderModal",
      animated: true,
      componentProps: { previousOrderId: order_id },
      backdropDismiss: false,
    });

    await this.previousOrderModal.present();
    this.previousOrderModal.onDidDismiss().then(() => {
      this.previousOrderModal = null;
    });
  }

  nextSlide() {
    if (this.slider) {
      this.slider.slideNext();
    }
  }

  prevSlide() {
    if (this.slider) {
      this.slider.slidePrev();
    }
  }

  updateSliderIconState() {
    this.isEnd = from(this.slider.isEnd());
    this.isBeginning = from(this.slider.isBeginning());
  }

  ngOnDestroy() {
    if (this.previousOrderModal) {
      this.previousOrderModal.dismiss();
      this.previousOrderModal = null;
    }
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
    if (this.previousOrdersSubscription) {
      this.previousOrdersSubscription.unsubscribe();
    }
  }
}
