import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import { auditTime, distinctUntilChanged, first, Subscription } from "rxjs";
import * as fromApp from "../../../store/app.reducer";
import * as selectors from "../../../store/app.selectors";
import * as _ from "lodash";
import {
  IonSelect,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { Router } from "@angular/router";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: "app-catalog-search-modal",
  templateUrl: "./catalog-search-modal.component.html",
  styleUrls: ["./catalog-search-modal.component.scss"],
})
export class CatalogSearchModalComponent implements OnInit, OnDestroy {
  @ViewChild("selectFilter", { static: false }) filterSelection: IonSelect;
  public state: any;
  public allCategories: any;
  public renderedCategories: any;
  public searchTerm: string;
  public selectedCategory;
  public hyperCategories: any;
  public currentView: string; // hypercategories or categories or products
  public currentStoreId: string;
  public searchMode: boolean;
  public loading: boolean = false;
  public renderedSearchProducts: any;
  public allRenderSearchProducts: any;
  public finalPriceLoading: any;
  public cartLength: any;
  public windowDimensions: any;
  public imageBrowser: boolean;
  public selectedHyperCategory: any;
  public filtersArray = [];
  public currentUser: any;
  public groupApp: any;
  public groupStoreData: any = {};
  public webp: any;
  public orderPickup: any;
  public dineIn: any;
  public cartProductIds: any;
  public storeInfo: any;
  public selectedDineInTable: any;
  public selectedPreorderDate;
  public mo_base;
  public selectedLanguage;
  public allCategoriesMarketCardsDesign;
  allSelectedCategoryProducts;
  private subCatalog: Subscription;
  private storeSub: Subscription;
  private cartProducts: any;
  private deliveryMethodAddressSub: Subscription;

  private unsubscribeBackEvent: Subscription;
  private hyperCategoriesSub: Subscription;
  private categoriesSub: Subscription;
  private currentStoreSub: Subscription;
  private finalPriceSubscription: Subscription;
  private sub: Subscription;
  private groupAppSub: Subscription;
  private userSub: Subscription;
  private translateService: TranslateService;
  private toastController: ToastController;

  constructor(
    private store: Store<fromApp.AppState>,
    private modalController: ModalController,
    private router: Router,
    private dataStorageService: DataStorageService,
    private platform: Platform
  ) {}

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          if (this.hyperCategories && this.hyperCategories.length > 0) {
            if (this.currentView === "hyperCategories") {
              this.modalController.dismiss();
            } else {
              this.goBack();
            }
          } else if (this.hyperCategories && !this.hyperCategories.length) {
            if (this.currentView === "categories") {
              this.modalController.dismiss();
            } else {
              this.goBack();
            }
          }
        }
      }
    );

    this.subCatalog = this.store
      .select("catalog")
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (
          state &&
          state.allCategoriesMarketCardsDesign &&
          !_.isEqual(
            state.allCategoriesMarketCardsDesign,
            this.allCategoriesMarketCardsDesign
          )
        ) {
          this.allCategoriesMarketCardsDesign = _.cloneDeep(
            state.allCategoriesMarketCardsDesign
          );
        }
      });

    this.deliveryMethodAddressSub = this.store
      .select("deliveryMethodAddress")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (state && !_.isEqual(state, state.orderPickup)) {
          this.orderPickup = _.cloneDeep(state.orderPickup);
        }
        if (state && !_.isEqual(state, state.dineIn)) {
          this.dineIn = _.cloneDeep(state.dineIn);
        }
        if (state && !_.isEqual(state, state.selectedDineInTable)) {
          this.selectedDineInTable = _.cloneDeep(state.selectedDineInTable);
        }
        if (state && !_.isEqual(state, state.selectedPreorderDate)) {
          this.selectedPreorderDate = _.cloneDeep(state.selectedPreorderDate);
        }
      });

    this.storeSub = this.store
      .select(selectors.getStoreInfo)
      .pipe(distinctUntilChanged())
      .subscribe((storeInfo) => {
        if (storeInfo && !_.isEqual(this.storeInfo, storeInfo)) {
          this.storeInfo = _.cloneDeep(storeInfo);
          const catalogCustomTagsById = {};
          _.each(_.cloneDeep(this.storeInfo.catalogCustomTags), (tag) => {
            catalogCustomTagsById[tag.id] = tag;
          });
          this.groupStoreData.store_info = {
            store_id: storeInfo.store_id,
            overridePromoItemsForPointsSystem: _.cloneDeep(
              this.storeInfo.overridePromoItemsForPointsSystem
            ),
            alt_dinein_active: storeInfo.dinein
              ? _.cloneDeep(this.storeInfo.alt_dinein_active)
              : null,
            catalogCustomTagsById: catalogCustomTagsById,
            design_settings: storeInfo.design_settings,
          };
        }
      });

    this.groupAppSub = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.groupApp &&
          !_.isEqual(this.groupApp, state.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp);
          this.groupStoreData.groupApp = {
            group: _.cloneDeep(this.groupApp.group),
            disableCatalogPhoto: _.cloneDeep(this.groupApp.disableCatalogPhoto),
            pointsSystem: _.cloneDeep(this.groupApp.pointsSystem),
            iconImage: _.cloneDeep(this.groupApp.iconImage),
          };
          this.groupStoreData.groupApp.mo_base = _.find(
            _.cloneDeep(this.groupApp.languages),
            { mo_base: true }
          );
        }
        if (
          state &&
          state.selectedLangugage &&
          !_.isEqual(this.selectedLanguage, state.selectedLangugage)
        ) {
          this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
          this.groupStoreData.groupApp.selectedLanguage = _.cloneDeep(
            this.selectedLanguage
          );
        }
        // console.log("serch component groupStoreData", this.groupStoreData);
        if (state && state.webp && !_.isEqual(this.webp, state.webp)) {
          this.webp = _.cloneDeep(state.webp);
          this.groupStoreData.webp = _.cloneDeep(this.webp);
        }

        if (
          state &&
          state.selectedLangugage &&
          !_.isEqual(state.selectedLangugage, this.selectedLanguage)
        ) {
          this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
        }
      });

    this.userSub = this.store
      .select("auth")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (state && !_.isEqual(state.user, this.currentUser)) {
          this.currentUser = _.cloneDeep(state.user);
        }
      });

    this.currentStoreSub = this.store
      .select("windowInfo")
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (
          state &&
          state.windowDimensions &&
          !_.isEqual(this.windowDimensions, state.windowDimensions)
        ) {
          this.windowDimensions = _.cloneDeep(state.windowDimensions);
          this.groupStoreData.windowDimensions = _.cloneDeep(
            state.windowDimensions
          );
          if (this.windowDimensions && this.windowDimensions.width > 500) {
            this.imageBrowser = true;
          } else {
            this.imageBrowser = false;
          }
        }
      });
    this.currentStoreSub = this.store
      .select("currentStore")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (
          state &&
          state.currentStoreId &&
          !_.isEqual(this.currentStoreId, state.currentStoreId)
        ) {
          this.currentStoreId = _.cloneDeep(state.currentStoreId);
        }
      });
    this.hyperCategoriesSub = this.store
      .select("hyperCategories")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (
          state &&
          state.hyperCategories &&
          !_.isEqual(state.hyperCategories, this.hyperCategories)
        ) {
          this.hyperCategories = _.cloneDeep(state.hyperCategories);
          if (this.hyperCategories && this.hyperCategories.length === 0) {
            this.subscribeToCategories(false);
            this.currentView = "categories";
            //console.log("current view categories");
          } else if (this.hyperCategories && this.hyperCategories.length > 0) {
            this.subscribeToCategories(true);
            this.currentView = "hyperCategories";
            //console.log("current view", this.hyperCategories);
          }
        }
      });

    this.finalPriceSubscription = this.store
      .select("disableFinalPrice")
      .subscribe((state) => {
        if (
          state &&
          !_.isEqual(state.disableFinalPrice, this.finalPriceLoading)
        ) {
          this.finalPriceLoading = _.cloneDeep(state.disableFinalPrice);
        }
      });
    this.sub = this.store
      .select("cart")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(200))
      .subscribe((state) => {
        if (
          state &&
          state.products &&
          !_.isEqual(this.cartProducts, state.products)
        ) {
          this.cartProducts = _.cloneDeep(state.products);
          this.calculateAllCartIncludedProducts();
          this.cartProductIds = [];
          _.each(this.cartProducts, (product) => {
            if (
              !product.quantity ||
              product.quantity === 0 ||
              product.quantity === 1 ||
              (product.enable_quantity_step && product.quantity_step)
            ) {
              this.cartProductIds.push(_.cloneDeep(product.product_id));
            } else {
              for (let i = 0; i < product.quantity; i++) {
                this.cartProductIds.push(_.cloneDeep(product.product_id));
              }
            }
          });
        }
        if (state && state.products) {
          this.cartLength = _.cloneDeep(state.products.length);
          console.log(this.cartLength);
        }
      });
  }

  calculateAllCartIncludedProducts() {
    console.log("calculateAllCartIncludedProducts");
    if (this.renderedSearchProducts && this.renderedSearchProducts.length) {
      _.each(this.renderedSearchProducts, (prd, indx) => {
        if (prd.category && prd.category.marketCardsDesign) {
          this.renderedSearchProducts[indx] =
            this.calculateProductIncudedIncart(_.cloneDeep(prd));
        }
      });
    }

    if (this.selectedCategory && this.selectedCategory.marketCardsDesign) {
      _.each(this.selectedCategory.products, (prd, indx) => {
        this.selectedCategory.products[indx] =
          this.calculateProductIncudedIncart(_.cloneDeep(prd));
      });
    }
  }

  calculateProductIncudedIncart(product) {
    if (product.productIncludedInCart) {
      delete product.productIncludedInCart;
    }

    if (
      _.find(_.cloneDeep(this.cartProducts), {
        product_id: product.product_id,
      })
    ) {
      product.productIncludedInCart = _.find(_.cloneDeep(this.cartProducts), {
        product_id: product.product_id,
      });
    }

    return product;
  }

  selectFilter() {
    this.filterSelection.open();
  }

  filtersChanged(ev) {
    this.filterSearchResult();
  }

  hyperCategoriesTrack(index, item) {
    return item.hyper_category_id;
  }

  categoriesTrack(index, item) {
    item.category_id;
  }

  productsTrack(index, item) {
    item.product_id;
  }

  goBack() {
    //console.log("go back called");
    if (this.currentView === "hyperCategories") {
      this.searchTerm = null;
    } else if (this.currentView === "categories") {
      if (this.hyperCategories && this.hyperCategories.length > 0) {
        this.renderedCategories = null;
        this.searchTerm = null;
        this.currentView = "hyperCategories";
      } else {
      }
    } else if (this.currentView === "products") {
      if (
        this.renderedCategories &&
        this.renderedCategories.length === 1 &&
        this.hyperCategories &&
        this.hyperCategories.length > 0
      ) {
        this.selectedCategory = null;
        this.allSelectedCategoryProducts = null;
        this.currentView = "hyperCategories";
        this.searchTerm = null;
      } else {
        this.selectedCategory = null;
        this.allSelectedCategoryProducts = null;
        this.currentView = "categories";
        this.searchTerm = null;
      }
    }
  }

  navigateAndClose() {
    this.ondismiss();
    this.router.navigateByUrl("/cart", {
      replaceUrl: true,
    });
  }

  ondismiss() {
    this.modalController.dismiss();
  }

  handleResetSearch() {
    this.searchTerm = null;
  }

  filterSearchResult() {
    if (this.filtersArray && this.filtersArray.includes("favorites")) {
      this.store
        .select("favoriteProducts")
        .pipe(first())
        .subscribe((state) => {
          if (state && state.favoriteProducts) {
            this.renderedSearchProducts = _.filter(
              _.cloneDeep(this.allRenderSearchProducts),
              (product) => {
                const found = _.find(state.favoriteProducts, {
                  product_id: product.product_id,
                });
                if (found) {
                  return product;
                }
              }
            );

            this.calculateAllCartIncludedProducts();
          }
        });
    } else {
      this.renderedSearchProducts = _.cloneDeep(this.allRenderSearchProducts);
      this.calculateAllCartIncludedProducts();
    }
  }

  filterCategoryProductsByDeliveryMethod(categoryProducts) {
    return new Promise((res, rej) => {
      this.store
        .select("deliveryMethodAddress")
        .pipe(first())
        .subscribe((state) => {
          categoryProducts = _.filter(
            _.cloneDeep(categoryProducts),
            (product) => {
              if (product.hideOtherDeliveryMethods) {
                if (state.orderPickup == true && state.dineIn == false) {
                  //take away selected

                  if (
                    product.pickup ||
                    (!product.delivery && !product.dinein)
                  ) {
                    return product;
                  }
                } else if (
                  state.orderPickup == false &&
                  state.dineIn == false
                ) {
                  //delivery selected

                  if (
                    product.delivery ||
                    (!product.pickup && !product.dinein)
                  ) {
                    return product;
                  }
                } else if (state.orderPickup == false && state.dineIn == true) {
                  //dine in selected

                  if (
                    product.dinein ||
                    (!product.pickup && !product.delivery)
                  ) {
                    return product;
                  }
                } else {
                  return product;
                }
              } else {
                return product;
              }
            }
          );

          res(categoryProducts);
        })
        .unsubscribe();
    });
  }

  onSearch() {
    console.log("on search called");
    if (this.searchTerm && this.currentStoreId) {
      this.searchMode = true;
      this.loading = true;
      this.renderedSearchProducts = null;

      this.dataStorageService
        .searchCatalog(
          this.selectedLanguage,
          this.currentStoreId,
          this.searchTerm,
          this.onlyIds(this.currentView)
        )
        .subscribe({
          next: (res: any) => {
            if (res && res.success) {
              this.loading = false;
              if (res.products && res.products.length > 0) {
                this.renderedSearchProducts = [];
                console.log("all categories", this.allCategories, res.products);
                _.each(res.products, (searchedProduct) => {
                  _.each(this.allCategories, (category) => {
                    if (category.products) {
                      _.each(category.products, (productOfCategory) => {
                        if (
                          searchedProduct.product_id ===
                          productOfCategory.product_id
                        ) {
                          let prd = _.cloneDeep(productOfCategory);

                          prd.category = _.cloneDeep(category);
                          if (
                            !!!_.find(this.renderedSearchProducts, {
                              product_id: prd.product_id,
                            })
                          ) {
                            this.renderedSearchProducts.push(prd);
                          }
                        }
                      });
                    }
                  });
                });
                this.allRenderSearchProducts = _.cloneDeep(
                  this.renderedSearchProducts
                );
                this.calculateAllCartIncludedProducts();
                this.filterSearchResult();
              } else {
                this.renderedSearchProducts = [];
                this.allRenderSearchProducts = [];
              }

              //console.log("res", res);
            } else {
              if (res && res.comment_id) {
                let errorMsg = this.translateService.instant(
                  "errorMessages." + res.comment_id
                );
                this.presentErrorToast(errorMsg, "danger");
              } else if (res && res.comments) {
                this.presentErrorToast(res.comments, "danger");
              }
            }
          },
          error: (err) => {
            let errMsg = this.translateService.instant(
              "errorMessages.problem_reaching_server"
            );

            this.presentErrorToast(errMsg, "danger");
          },
        });
    } else if (!this.searchTerm) {
      this.searchMode = false;
      // if (this.hyperCategories && this.hyperCategories.length > 0) {
      //   this.currentView = "hyperCategories";
      // } else {
      //   this.currentView = "categories";
      // }
    }
  }

  onlyIds(type) {
    // console.log("type", type);
    if (type === "categories") {
      if (this.renderedCategories) {
        let category_ids = [];
        console.log("rendered ategories", this.renderedCategories);
        _.each(this.renderedCategories, (category) => {
          if (category && category.category_id) {
            // console.log("push category id");
            category_ids.push(_.cloneDeep(category.category_id));
          }
        });

        return category_ids;
      }
    } else if (type === "products") {
      if (this.selectedCategory) {
        return [this.selectedCategory.category_id];
      }
    }
  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            //console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }

  subscribeToCategories(hyperCategories) {
    if (this.hyperCategoriesSub) {
      this.hyperCategoriesSub.unsubscribe();
    }
    if (hyperCategories) {
      this.categoriesSub = this.store
        .select("hyperCategories")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            state.allCategories &&
            !_.isEqual(state.allCategories, this.allCategories)
          ) {
            this.allCategories = _.cloneDeep(state.allCategories);
            this.renderedCategories = _.cloneDeep(this.allCategories);
          }
        });
    } else {
      this.categoriesSub = this.store
        .select("catalog")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            state.catalog &&
            !_.isEqual(state.catalog, this.allCategories)
          ) {
            this.allCategories = _.cloneDeep(state.catalog);
            this.renderedCategories = _.cloneDeep(this.allCategories);
          }
        });
    }
  }

  selectHyperCategory(hyperCategory) {
    console.log("select hypercategory", hyperCategory);
    this.selectedHyperCategory = _.cloneDeep(hyperCategory);
    this.renderedCategories = _.filter(this.allCategories, {
      hyper_category_id: hyperCategory.hyper_category_id,
    });
    if (this.renderedCategories && this.renderedCategories.length > 1) {
      this.currentView = "categories";
    } else if (
      this.renderedCategories &&
      this.renderedCategories.length === 1
    ) {
      this.selectCategory(this.renderedCategories[0]);
    }
  }

  loadData(event) {
    this.addMoreItemsDebounce(event);
  }

  private addMoreItemsDebounce = _.debounce((ev) => {
    if (
      this.allSelectedCategoryProducts.length >
      this.selectedCategory.products.length
    ) {
      const tempList = _.cloneDeep(
        _.slice(
          _.cloneDeep(this.allSelectedCategoryProducts),
          this.selectedCategory.products.length - 1,
          this.allSelectedCategoryProducts.length >=
            this.selectedCategory.products.length - 1 + 20
            ? this.selectedCategory.products.length - 1 + 20
            : this.allSelectedCategoryProducts.length
        )
      );

      _.each(tempList, (prd: any, key) => {
        if (
          !_.find(this.selectedCategory.products, {
            product_id: prd.product_id,
          })
        ) {
          this.selectedCategory.products.push(prd);
        }
      });
      //DO NOT REMOVE THIS, PIPE filterAvailableProducts NEEDS NEW INSTANCE TO BE FIRED AGAIN!
      this.selectedCategory.products = _.cloneDeep(
        this.selectedCategory.products
      );
      //

      ev.target.complete();
    } else {
      console.log("infinite scroll else");
    }
  }, 300);

  async selectCategory(selectedCategory) {
    console.log("select category", selectedCategory);
    this.allSelectedCategoryProducts = _.cloneDeep(
      selectedCategory.products || []
    );
    if (
      this.storeInfo &&
      this.storeInfo.design_settings &&
      this.storeInfo.design_settings.showOnlySelectedCategoryProducts
    ) {
      const selCat = _.cloneDeep(selectedCategory);
      selCat.products = [];
      this.selectedCategory = _.cloneDeep(selCat);
      const categoryHasProducts = !!(
        this.selectedCategory &&
        this.selectedCategory.products &&
        this.selectedCategory.products.length
      );
      this.selectedCategory.products =
        await this.filterCategoryProductsByDeliveryMethod(
          _.cloneDeep(this.selectedCategory.products)
        );
      if (
        categoryHasProducts &&
        this.selectedCategory &&
        this.selectedCategory.products &&
        !this.selectedCategory.products.length
      ) {
        this.selectedCategory.emptyCategoryForSelectedDeliveryMethod = true;
      } else {
        this.selectedCategory.emptyCategoryForSelectedDeliveryMethod = false;
      }
      this.selectedCategory.products = _.cloneDeep(
        _.slice(selectedCategory.products, 0, 20)
      );
    } else {
      this.selectedCategory = _.cloneDeep(selectedCategory);
      const categoryHasProducts = !!(
        this.selectedCategory &&
        this.selectedCategory.products &&
        this.selectedCategory.products.length
      );
      this.selectedCategory.products =
        await this.filterCategoryProductsByDeliveryMethod(
          _.cloneDeep(this.selectedCategory.products)
        );
      if (
        categoryHasProducts &&
        this.selectedCategory &&
        this.selectedCategory.products &&
        !this.selectedCategory.products.length
      ) {
        this.selectedCategory.emptyCategoryForSelectedDeliveryMethod = true;
      } else {
        this.selectedCategory.emptyCategoryForSelectedDeliveryMethod = false;
      }
    }

    this.calculateAllCartIncludedProducts();
    this.filterSearchResult();
    this.currentView = "products";
  }

  ngOnDestroy() {
    if (this.subCatalog) {
      this.subCatalog.unsubscribe();
    }

    if (this.deliveryMethodAddressSub) {
      this.deliveryMethodAddressSub.unsubscribe();
    }

    if (this.userSub) {
      this.userSub.unsubscribe();
    }
    if (this.groupAppSub) {
      this.groupAppSub.unsubscribe();
    }
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.hyperCategoriesSub) {
      this.hyperCategoriesSub.unsubscribe();
    }
    if (this.categoriesSub) {
      this.categoriesSub.unsubscribe();
    }
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.finalPriceSubscription) {
      this.finalPriceSubscription.unsubscribe();
    }
    if (this.currentStoreSub) {
      this.currentStoreSub.unsubscribe();
    }
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
  }
}
