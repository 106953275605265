<ion-content>
  <ion-grid style="height: 100%; width: 100%">
    <ion-row
      style="height: 100%; width: 100%"
      class="ion-justify-content-center ion-align-items-center"
    >
      <ion-col>
        <ion-row>
          <ion-col>
            <h1 style="color: white; font-weight: bold; text-align: center">
              Συγχαρητήρια μόλις κέρδισες <br />
              {{ winner.gift }}
            </h1>
          </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-center ion-align-items-center">
          <ion-col class="ion-text-center">
            <div style="max-width: 500px; display: inline-block">
              <ng-lottie
                (animationCreated)="contestWinnerAnimationCreated($event)"
                [options]="winnerAnimationOptions"
              ></ng-lottie>
            </div>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
<ion-footer>
  <ion-row>
    <ion-col class="ion-text-center">
      <ion-button
        color="secondary"
        size="large"
        (click)="dismissModal()"
      >
        OKEY
      </ion-button>
    </ion-col>
  </ion-row>
</ion-footer>
