import { Component, OnDestroy, ViewChild } from "@angular/core";

import {
  AlertController,
  IonContent,
  LoadingController,
  MenuController,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import { SplashScreen } from "@capacitor/splash-screen";
import { StatusBar, Style } from "@capacitor/status-bar";
import { VERSION } from "../environments/version";
import { TranslateConfigService } from "./services/translate-config/translate-config.service";
import { DataStorageService } from "./services/data-storage/data-storage.service";
import * as fromApp from "./store/app.reducer";
import * as GroupAppActions from "./store/group-app/groupApp.actions";
import { Store } from "@ngrx/store";
import { App } from "@capacitor/app";
import {
  auditTime,
  catchError,
  debounceTime,
  distinctUntilChanged,
  first,
} from "rxjs/operators";
import * as WindowInfoActions from "./store/window-info/windowInfo.actions";
import * as _ from "lodash";
import { interval, Subscription } from "rxjs";
import * as StoresActions from "./store/stores/stores.actions";
import * as CurrentStoreActions from "./store/current-store/current-store.actions";
import { LocalStorageDataService } from "./services/local-storage-data/local-storage-data.service";
import * as selectors from "./store/app.selectors";
import * as PlatformInfoActions from "./store/platform-info/platform-info.actions";
import { TranslateService } from "@ngx-translate/core";
import { PaymentGatewaysService } from "./services/payment-gateways/payment-gateways.service";
import { CalculateFinalPriceService } from "./services/calculateFinalPrice/calculate-final-price.service";
import { ValidatingCartService } from "./services/validating-cart/validating-cart.service";
import * as PointsActions from "./points/store/points.actions";

import * as DeveloperModeActions from "./store/developer-mode/developer-mode.actions";
import * as AuthActions from "./auth/store/auth.actions";
import { PiraeusThreeDsPopupComponent } from "./popups/payment/piraeus-three-ds-popup/piraeus-three-ds-popup.component";
import { DomSanitizer } from "@angular/platform-browser";
import * as OrdersActions from "./orders/store/orders.actions";
import { SelectedAddressService } from "./services/selectedAddress/selected-address.service";
import { Renderer2 } from "@angular/core";
import {
  GuardsCheckEnd,
  GuardsCheckStart,
  NavigationCancel,
  NavigationEnd,
  Router,
} from "@angular/router";
import { BranchDeepLinks, BranchInitEvent } from "capacitor-branch-deep-links";
import { FirebaseBrowserService } from "./services/firebaseBrowser/firebase-browser.service";
import * as DeliveryMethodAddressActions from "./store/deliveryMethodAddress/deliveryMethodAddress.actions";
import { ProductDetailsComponent } from "./product-details/product-details.component";
import * as BranchLinkDataActions from "./store/branch-link-data/branchLinkData.actions";
import { getAuth, signInWithPhoneNumber } from "firebase/auth";
import { LoginPopupsServiceService } from "./services/login-popups-service/login-popups-service.service";
import { ScanLoginModalComponent } from "./popups/scan-login-modal/scan-login-modal.component";
import { Deploy } from "cordova-plugin-ionic/dist/ngx";
import { Capacitor } from "@capacitor/core";
import { DeployService } from "./services/deploy-service/deploy.service";
import { UpdateModalComponent } from "./popups/update-modal/update-modal.component";
import * as FavoriteProductsActions from "./store/favorite-products/favoriteProducts.actions";
import * as PreviousOrdersActions from "./catalog/previous-order/store/previousOrders.actions";
import * as CatalogActions from "./catalog/store/catalog.actions";
import { AnalyticsServiceService } from "./services/analitycsService/analytics-service.service";
import * as selectedInvoiceActions from "./cart/cart-components/invoice/store/selectedInvoice.actions";
import * as RequestsErrorHandlingActions from "./store/requestsErrorHandling/requestsErrorHandling.actions";
import { v4 as uuid } from "uuid";
import { OneSignalService } from "./services/oneSignal/one-signal.service";
import * as CouponsActions from "./discount-coupons/store/coupons.actions";
import { PreorderValidationServiceService } from "./services/preorder-validation-service/preorder-validation-service.service";
import * as DisableFinalPriceActions from "./store/disable-final-price/disable-final-price.actions";
import { OrderNowModalPopupsService } from "./services/orderNowModalPopupsService/orderNowModalPopups.service";
import * as PendingOrdersActions from "./store/pending-orders/pendingOrders.actions";
import * as cartCustomOptionsSelectionsActions from "./cart/cart-components/cart-custom-options/store/cart-custom-options-selections/cart-custom-options-selections.actions";
import { BlockStoreModalComponent } from "./popups/block-store-modal/block-store-modal.component";
import * as LoyaltySystemActions from "./loyalty-system/store/loyalty-system.actions";
import * as SelectedInvoiceActions from "./cart/cart-components/invoice/store/selectedInvoice.actions";
import { NoLocationDineInModalComponent } from "./popups/no-location-dine-in-modal/no-location-dine-in-modal.component";
import { CheckLocationDistanceService } from "./services/checkLocationDistance/check-location-distance.service";
import { CouponInfoPopupComponent } from "./popups/coupon-info-popup/coupon-info-popup.component";
import { DeletedUserInfoModalComponent } from "./popups/deleted-user-info-modal/deleted-user-info-modal.component";
import * as AffiliateActions from "./affiliate/store/affiliate.actions";
import * as SelectedProductActions from "./product-details/store/selectedProduct.actions";
import { LoadingSplashscreenComponent } from "./loading-splashscreen/loading-splashscreen.component";
import { ProductDetailsOperationsService } from "./product-details/product-details-operations.service";
import * as ReservationActions from "./reservations/store/reservations.actions";
import { Device } from "@capacitor/device";
import * as reservationActions from "./reservations/store/reservations.actions";
import { FixedAddressInfoPopupComponent } from "./popups/fixed-address-info-popup/fixed-address-info-popup.component";
import * as OrderCommentsActions from "./cart/cart-components/order-comments/store/orderComments.actions";
import SwiperCore, {
  Autoplay,
  FreeMode,
  Keyboard,
  Navigation,
  Pagination,
  Zoom,
} from "swiper";
import OneSignal from "onesignal-cordova-plugin";
import * as AppInitLoadingActions from "./store/app-init-loading/appInitLoading.actions";
import * as manuallyTriggerFinalPriceActions from "./store/manuallyTriggerFinalPriceCheckAddress/manuallyTriggerFinalPrice.actions";
import { fixedAddressesService } from "./services/fixedAddressesService/fixed-addresses.service";
import moment from "moment-timezone";
import * as ReviewsActions from "./ratings/store/reviews.actions";
import * as CustomerCouponsActions from "./discount-coupons/store/coupons.actions";
import * as StaticPagesActions from "./store/static-pages/staticPages.actions";

SwiperCore.use([Navigation, Autoplay, Keyboard, Pagination, Zoom, FreeMode]);

declare let window: any;
@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnDestroy {
  @ViewChild(IonContent) content: IonContent;
  public toggle = document.querySelector("#themeToggle");
  public selectedIndex = "homepage";
  public selectedLanguage: string;
  public version = VERSION.version;
  public update: boolean;
  public menuMinWidthToDisplay: any = "(min-width: 1500px)";
  public imageSettings: any;
  public stores;
  public showPaymentTerms: boolean;
  public cartProductsNumber: number;
  public showPaymentCards: boolean;
  public showPaymentCardsAdminOnly: boolean;
  public showPaymentCardsCustomerOnly: boolean;
  public currentUser: any;
  public sideMenuIsOpen: boolean;
  public storeImage: any;
  public headerLogo: any;
  public darkHeaderLogo: any;
  public groupApp: any;
  public cartProducts: any;
  public flattenedIconBackgroundFlag: boolean = true;
  public currentStore: any;
  public appChannel: string;
  public groupAppLoading = false;
  public groupTheme = false;
  public counterChannel: number = 0;
  public internetConnectedFlag = true;
  public userPoints: any;
  public highestReward: any;
  public pointsProgressBarColor: any;
  public selectedTheme: string;
  public branchLinkEvent: string;
  public windowDimensions: any;
  public activeCoupons: any;
  public primaryColor;
  public affiliate_coupon_code: any;
  public guardLoading: boolean;
  public selectedPreorderDate: any;
  public openProductDetailsModalAppComponentProductId: any;
  public appInitLoadingStep: any;
  public activeDrawsNumber;
  public atLeastOneStoreKioskActive;
  public changeChannelTimestamp: any;
  public splashScreenDissmised = false;
  public firstTimeSignInWithPhoneNumber: boolean = true;
  public branchTriggerTimestamp: any;

  private migration_customer_id = null;
  private infoShowDeliveryButton;
  private piraeusPendingPaymentOrder: any;
  private piraeusPendingPaymentOrderInterval: any;
  private showDeliveryButton: boolean;
  private showPickupButton: boolean;
  private showDineInButton: boolean;
  private cartActiveCoupons: any;
  private checkLocationDineInModal: any;
  private loginState: string;
  private subscriptionsForCalculateFinalPriceStarted: boolean = false;
  private addEventListenerToResizeWindow: boolean = false;
  private triggerCouponCodeCheckObject: any;
  private coupon_rule_id: any;
  private retryCheckUpdate = 0;
  private developerMode: boolean;
  private webP: boolean = false;
  private store_info;
  private socket: any;
  private selectedPaymentMethodID;
  private cookiesEnabled: boolean;
  private cartSubscription: Subscription;
  private deliveryMethodAddresSubscription: Subscription;
  private currentStoreId: string;
  private localStorageSubscriptions: Subscription[] = [];
  private newCustomer: any;
  private branch_link_data: any;
  private mo_token: any;
  private ionLoading: any;
  private logoutFlagTimestamp: any;
  private piraeusPopupInfo: any;
  private ordersArray: any;
  private loginLoading: boolean;
  private localhostGroupDevelopement = "amandi";
  private keepAlive: any;
  private requestsErrorArray: any;
  private toastSocket: any;
  private versionNumber: any;
  private iOSPlatform: any;
  private androidPlatform: any;
  private scanLoginModal: any;
  private productDetailsModal: any;
  private piraeus3dsModal: any;
  private orderPickup: any;
  private dinein: any;
  private selectedDineInTable: any;
  private groupAppSplashScreenSubscription: Subscription;
  private currentStoreIdSub: Subscription;
  private splashScreenModal: any;
  private fixedAddressModal: any;
  private branchIoDataBeforeWritingState;
  private appInitLoadingSub: Subscription;
  private appInitInterval: any;
  private triggerRenewTokenTimestamp: any;
  private calculateWindowDimensionsTimestamp;
  private cashoutRewardBranchIONavigateToPoints = false;

  constructor(
    private platform: Platform,
    private translateConfigService: TranslateConfigService,
    private dataStorageService: DataStorageService,
    private localStorageDataService: LocalStorageDataService,
    private paymentGateWaysService: PaymentGatewaysService,
    private store: Store<fromApp.AppState>,
    private menuCtrl: MenuController,
    private alertCtrl: AlertController,
    private translateService: TranslateService,
    private validatingCartService: ValidatingCartService,
    private calculateFinalPriceService: CalculateFinalPriceService,
    private modalCtrl: ModalController,
    private toastController: ToastController,
    private loadingController: LoadingController,
    private DomSinitizer: DomSanitizer,
    private selectedAddressService: SelectedAddressService,
    private renderer: Renderer2,
    private router: Router,
    private firebaseBrowserService: FirebaseBrowserService,
    private analyticsService: AnalyticsServiceService,
    private alertController: AlertController,
    private loginPopupService: LoginPopupsServiceService,
    private deploy: Deploy,
    private deployService: DeployService,
    private oneSignalService: OneSignalService,
    private preorderValidationService: PreorderValidationServiceService,
    private orderNowPopupService: OrderNowModalPopupsService,
    private checkLocationDistanceService: CheckLocationDistanceService,
    private productDetailsOperationsService: ProductDetailsOperationsService,
    private fixedAddressesService: fixedAddressesService,
    private domSanitizer: DomSanitizer
  ) {
    console.log("app channel before initializeApp", this.appChannel);
    this.routeParamsSubscription();
    this.initializeApp();
  }

  private timeout = (ms) => new Promise((res) => setTimeout(res, ms));
  initializeApp() {
    this.platform.ready().then(async () => {
      this.appInitInterval = setInterval(() => {
        if (
          this.appInitLoadingStep &&
          this.appInitLoadingStep === "app-loaded" &&
          this.appInitLoadingSub
        ) {
          console.log("unsubscribe app init interval");
          this.appInitLoadingSub.unsubscribe();
          clearInterval(this.appInitInterval);
        }
      }, 4000);
      this.appInitLoadingSub = this.store
        .select("appInitLoading")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.appInitLoadingStep &&
            !_.isEqual(this.appInitLoadingStep, state.appInitLoadingStep)
          ) {
            this.appInitLoadingStep = _.cloneDeep(state.appInitLoadingStep);
            if (this.appInitLoadingStep === "init") {
              this.getGroupAppStep();
            } else if (this.appInitLoadingStep === "check-update") {
              this.checkUpdateStep();
              this.splashScreenOnBrowserApp();
            } else if (this.appInitLoadingStep === "update-checked") {
              this.appInitActionsStep();
            } else if (this.appInitLoadingStep === "app-loaded") {
              this.store
                .select("groupApp")
                .pipe(first())
                .subscribe((state) => {
                  this.initBranchIOSettingListeners(state.groupApp);
                  this.checkSocketAndShowToast(false);
                  this.connectMoSocket(null);
                  this.couponCodeCheckSubscription();

                  this.authSubscription();
                  this.analyticsService.dmAnalyticsMessage(
                    "app_loaded",
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null
                  );
                })
                .unsubscribe();
            }
          }
        });

      //check and send footprint if previous app start has a problem with ionic deploy

      this.checkGuardSub();

      // this.firebaseMobileInit();
    });
  }

  splashScreenOnBrowserApp() {
    //this is for splashScreen in browser app

    if (!Capacitor.isNativePlatform()) {
      if (
        !this.groupAppSplashScreenSubscription &&
        !this.updateModal &&
        window &&
        window.innerWidth &&
        window.innerWidth < 900
      ) {
        this.groupAppSplashScreenSubscription = this.store
          .select("groupApp")
          .subscribe((state) => {
            if (
              state &&
              state.groupApp &&
              state.groupApp.splash_screen_active &&
              state.groupApp.splashScreenImage &&
              state.groupApp.splash_screen_active_browser &&
              !this.splashScreenModal
            ) {
              this.splashScreenModal = true;
              this.presentSplashScreenModal(
                _.cloneDeep(state.groupApp.splashScreenImage),
                _.pick(_.cloneDeep(state.groupApp), [
                  "group",
                  "design_settings",
                ])
              );
            }
          });
      } else {
        this.splashScreenDissmised = true;
      }
    }
  }

  checkLocalStorageChangeAppChannel() {
    if (
      Capacitor.isNativePlatform() ||
      (!Capacitor.isNativePlatform() && navigator.cookieEnabled)
    ) {
      if (localStorage.getItem("appChannel") === "Production") {
        localStorage.setItem("appChannel", "Capacitor");
      }
      if (localStorage.getItem("appChannel") === "Development") {
        localStorage.setItem("appChannel", "mastercapacitor");
      }
      if (
        localStorage.getItem("appChannel") !== "Capacitor" &&
        localStorage.getItem("appChannel") !== "mastercapacitor"
      ) {
        localStorage.removeItem("appChannel");
      }
      if (localStorage.getItem("appChannel") === null) {
        localStorage.setItem("appChannel", "Capacitor");
      }
    }
  }

  checkUpdateStep() {
    if (Capacitor.isNativePlatform()) {
      this.localStorageDataService.checkForIonicUpdateErrorFlag();
      StatusBar.setStyle({
        style: Style.Default,
      });
      SplashScreen.hide();
    }

    this.checkLocalStorageChangeAppChannel();
    console.log(
      "app channel in local storage",
      localStorage.getItem("appChannel")
    );
    this.appChannel =
      navigator.cookieEnabled && localStorage.getItem("appChannel")
        ? localStorage.getItem("appChannel")
        : "Capacitor";

    if (this.appChannel) {
      if (navigator.cookieEnabled && !localStorage.getItem("appChannel")) {
        localStorage.setItem("appChannel", this.appChannel);
      }
    }
    console.log("this.AppChannel before check update", this.appChannel);

    if (Capacitor.isNativePlatform()) {
      this.checkUpdate();
      if (this.appChannel === "mastercapacitor") {
        console.log("Periodic check called");
        this.periodCheck();
      }
    }
    this.initDeveloperMode();
    this.styleStatusBar();

    if (!Capacitor.isNativePlatform()) {
      this.store.dispatch(
        new AppInitLoadingActions.SetAppInitLoadingStep("update-checked")
      );
    }
  }

  getGroupAppStep() {
    this.checkPlatformInfoGetGroupApp();
    this.windowDimensionsCalc();
  }

  appInitActionsStep() {
    this.checkWebPSupport();
    this.storesSubscription();

    this.validatingCartService.validatingCartSubscriptions();
    this.checkBrowserCookies();
    this.setRootDir();
    this.paymentGateWaysService.startCheckPaymentGateways();
    this.paymentGateWaysSubscription();
    this.grouAppSubscription();
    this.ordersArraySubscription();
    this.subscribeToCart();
    this.selectedAddressService.checkToSelectAddressSubs();
    this.pauseEvent();
    this.onResumeEvent();
    this.productDetailsOperationsService.productDetailsDisableFinalPriceSubscriprion();
    this.productDetailsOperationsService.productDetailsDeliveryMethodAddressSub();
    this.intervalForCheckConnection();
    this.setSessionId();
    this.setDeviceId();
    this.piraeusPendingPaymentOrderSub();
    this.analyticsService.groupAppSubscription();
    this.loginStateSystem();
    this.windowDimensionsSub();
    this.subscribeUserPoints();
    this.checkProductDetailsModalOpening();
    this.branchIOSubscription();
    this.store.dispatch(
      new AppInitLoadingActions.SetAppInitLoadingStep("app-loaded")
    );

    //retry to calculate divice screen size if its not successfull the first time
    setTimeout(() => {
      this.addEventListenerToResizeWindow = true;
      this.windowDimensionsCalc();

      this.store
        .select("windowInfo")
        .pipe(auditTime(100))
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.calculateWindowDimensionsTimestamp &&
            !_.isEqual(
              state.calculateWindowDimensionsTimestamp,
              this.calculateWindowDimensionsTimestamp
            )
          ) {
            this.calculateWindowDimensionsTimestamp = _.cloneDeep(
              state.calculateWindowDimensionsTimestamp
            );
            this.windowDimensionsCalc();
          }
        });
    }, 2000);
  }

  //The below commented out code is just for tests

  // async openFixedAddressModalForTest() {
  //   const modal = await this.modalCtrl.create({
  //     component: SelectedFixedPointsAddressModalComponent,
  //     cssClass: "",
  //     backdropDismiss: false,
  //     componentProps: {},
  //   });
  //   await modal.present();
  // }

  // async presentNotValidAddressModal() {
  //   const modal = await this.modalCtrl.create({
  //     component: SelectStoreComponent,
  //     cssClass: "selectStoreInPaymentCardsPopup",
  //     backdropDismiss: false,
  //     componentProps: {
  //       storesByStoreID: _.cloneDeep(this.stores),
  //       paymentGatewayStoresByID: "piraeus",
  //       selectedLanguage: "el",
  //     },
  //   });

  //   await modal.present();
  // }

  // handleKeyboardShow() {
  //   if (
  //     (this.platform.is("android") || this.platform.is("ios")) &&
  //     window.cordova &&
  //     window.cordova.platformId === "browser"
  //   ) {
  //     this.platform.keyboardDidShow.subscribe((ev) => {
  //       console.log("keybord show", ev);
  //       if (ev && ev.keyboardHeight) {
  //       }
  //     });
  //     this.platform.keyboardDidHide.subscribe((ev) => {
  //       console.log("keybord hide", ev);
  //     });
  //   }
  // }

  // async testBillingInfo() {
  //   const billinfo = await this.modalCtrl.create({
  //     component: BillingInfoComponent,
  //     cssClass: "billingInfoPopupCss",
  //     backdropDismiss: false,
  //     componentProps: {
  //       billingInfo: { gateway_id: "piraeus" },
  //       billing_order_formatted_address: {},
  //     },
  //   });

  //   await billinfo.present();
  // }

  // async updateModalTest() {
  //   this.updateModal = await this.modalCtrl.create({
  //     component: UpdateModalComponent,

  //     cssClass: "update-modal",
  //     backdropDismiss: true,
  //   });
  //   await this.updateModal.present();
  // }

  piraeusPendingPaymentOrderSub() {
    this.store
      .select("orders")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(200))
      .subscribe((state) => {
        if (
          state &&
          !_.isEqual(
            state.piraeusPendingPaymentOrder,
            this.piraeusPendingPaymentOrder
          )
        ) {
          this.piraeusPendingPaymentOrder = _.cloneDeep(
            state.piraeusPendingPaymentOrder
          );

          if (
            this.piraeusPendingPaymentOrder &&
            this.piraeusPendingPaymentOrder.order_id &&
            this.piraeusPendingPaymentOrder.store_id &&
            this.piraeusPendingPaymentOrder.last_payment_timestamp
          ) {
            this.piraeusPendingPaymentOrderInterval = setInterval(() => {
              //request for piraeus confirmation popups

              this.getPiraeusHtml3ds(this.piraeusPendingPaymentOrder);
            }, 4000);

            setTimeout(() => {
              if (this.piraeusPendingPaymentOrderInterval) {
                clearInterval(this.piraeusPendingPaymentOrderInterval);
                this.piraeusPendingPaymentOrderInterval = null;
                this.store.dispatch(
                  new OrdersActions.SetCurrentPendingPaymentPiraeusOrder(null)
                );
              }
            }, 16300);
          } else if (
            !this.piraeusPendingPaymentOrder &&
            this.piraeusPendingPaymentOrderInterval
          ) {
            clearInterval(this.piraeusPendingPaymentOrderInterval);
            this.piraeusPendingPaymentOrderInterval = null;
          }
        }
      });
  }

  getPiraeusHtml3ds(data) {
    this.dataStorageService.getPiraeusHtml3ds(data, this.mo_token).subscribe({
      next: (res: any) => {
        if (!res || !res.success) {
          if (res && res.comment_id === "order_not_found") {
            //clear interval
            this.store.dispatch(
              new OrdersActions.SetCurrentPendingPaymentPiraeusOrder(null)
            );
          } else if (
            res &&
            res.comment_id === "order_last_payment_timestamp_does_not_match"
          ) {
            //clear interval
            this.store.dispatch(
              new OrdersActions.SetCurrentPendingPaymentPiraeusOrder(null)
            );
          }
        } else {
          if (res.piraeus_3ds_url) {
            let frame_width = window.innerWidth > 700 ? 700 : window.innerWidth;
            let frame_height =
              window.innerHeight > 900 ? 850 : window.innerHeight - 100;
            let piraeus_3ds_url = this.trustedUrl(res.piraeus_3ds_url);
            if (!this.piraeus3dsModal) {
              this.piraeus3dsModal = true;
              this.presentPiraeus3dsModal(
                frame_width,
                frame_height,
                piraeus_3ds_url
              );
            }
          } else {
            if (res.comment_id === "status_changed_fetch_orders") {
              //fetch user orders again
              this.store.dispatch(
                new OrdersActions.SetFetchOrdersAgainTimestamp(
                  Date.now().toString()
                )
              );
              //clear interval
              this.store.dispatch(
                new OrdersActions.SetCurrentPendingPaymentPiraeusOrder(null)
              );
            } else if (res.comment_id === "piraeus_3ds_url_not_found_yet") {
              //wait for next API call
            } else if (res.comment_id === "order_supposed_to_be_canceled") {
              //30 mins passed and order have to be canceled, cant pay now!
              //clear the interval.
              this.store.dispatch(
                new OrdersActions.SetCurrentPendingPaymentPiraeusOrder(null)
              );
            }
          }
        }
      },
      error: (err) => {},
    });
  }

  checkGuardSub() {
    this.router.events.subscribe((event) => {
      if (event instanceof GuardsCheckStart) {
        this.guardLoading = true;
        console.log("GuardStart");
      }
      if (
        event instanceof GuardsCheckEnd ||
        event instanceof NavigationCancel
      ) {
        this.guardLoading = false;
        console.log("GuardEnd");
      }
    });
  }

  intervalForCheckConnection() {
    interval(55000).subscribe(() => {
      console.log("interval user", this.currentUser);

      this.sendSocketMessage(
        {
          type: "user-test-connection", // This tests the connection of the socket using a mock socket api!
        },
        3
      );
    });
  }

  initDeveloperMode() {
    this.setDeveloperMode(_.cloneDeep(this.appChannel !== "Capacitor"));
  }

  windowDimensionsSub() {
    this.store
      .select("windowInfo")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(300))
      .subscribe((state) => {
        if (!_.isEqual(state.windowDimensions, this.windowDimensions)) {
          this.windowDimensions = _.cloneDeep(state.windowDimensions);
        }
      });
  }

  deliveryMethodAddressSub(checkForGpsDineIn) {
    if (this.deliveryMethodAddresSubscription) {
      this.deliveryMethodAddresSubscription.unsubscribe();
    }

    this.deliveryMethodAddresSubscription = this.store
      .select("deliveryMethodAddress")
      .pipe(distinctUntilChanged())
      .pipe(debounceTime(500))
      .subscribe((state) => {
        console.log("deliveryMethodAddress Appco");
        if (!_.isEqual(state.orderPickup, this.orderPickup)) {
          this.orderPickup = _.cloneDeep(state.orderPickup);
        }
        if (!_.isEqual(state.dineIn, this.dinein)) {
          this.dinein = _.cloneDeep(state.dineIn);
          this.selectedDineInTable = _.cloneDeep(state.selectedDineInTable);
          if (this.dinein && this.selectedDineInTable && checkForGpsDineIn) {
            this.checkDeviceLocation();
          }
        }

        if (!_.isEqual(state.selectedPreorderDate, this.selectedPreorderDate)) {
          this.selectedPreorderDate = _.cloneDeep(state.selectedPreorderDate);
          let preorderData;
          if (
            this.selectedPreorderDate &&
            this.selectedPreorderDate.date &&
            this.selectedPreorderDate.hour
          ) {
            preorderData = {
              preorder: true,
              preorder_day: this.selectedPreorderDate.date,
              preorder_time: this.selectedPreorderDate.hour,
            };
          } else {
            preorderData = null;
          }
          this.store
            .select("previousOrders")
            .pipe(first())
            .subscribe((prevOrderState) => {
              if (
                prevOrderState &&
                prevOrderState.previousOrders &&
                prevOrderState.previousOrders.length &&
                prevOrderState.onPreorderChangeFetchAgain
              ) {
                this.store
                  .select("auth")
                  .pipe(first())
                  .subscribe((authState) => {
                    this.fetchCustomerPreviousOrders(
                      this.store_info.store_id,
                      state.orderPickup,
                      state.dineIn,
                      preorderData,
                      state.selectedDineInTable
                        ? state.selectedDineInTable.table_id
                        : null,
                      authState.migration_customer_id
                        ? authState.migration_customer_id
                        : null
                    );
                  })
                  .unsubscribe();
              }
            })
            .unsubscribe();
        }
      });
  }

  fetchStaticPages(currentStore) {
    this.store.dispatch(new StaticPagesActions.ClearStaticPages());
    if (
      currentStore &&
      (currentStore.staticPagesAllergensActive ||
        currentStore.staticPagesCatalogDisclaimerActive)
    ) {
      this.dataStorageService
        .fetchStaticPages(currentStore.store_id, this.groupApp.group)
        .subscribe({
          next: (res: any) => {
            if (!res || !res.success) {
              if (res && res.comment_id) {
                const errorMsg = this.translateService.instant(
                  "errorMessages." + res.comment_id
                );
              } else if (res && res.comments) {
              } else {
                const errMsg = this.translateService.instant(
                  "errorMessages.problem_reaching_server"
                );
              }
            } else {
              //TODO WRITE TO STATE
              this.store.dispatch(
                new StaticPagesActions.SetStaticPages(res.static_pages)
              );
            }
          },
          error: (err) => {
            const errMsg = this.translateService.instant(
              "errorMessages.problem_reaching_server"
            );

            this.presentErrorToast(errMsg, "danger");
          },
        });
    }
  }

  async autoAddressAssignGps(userAddresses, selectedAddress) {
    //TO-DO this needs tests
    // let distances: any = [];
    // for (let i = 0; i < userAddresses.length; i++) {
    //   const distance_object: any =
    //     await this.checkLocationDistanceService.calculateDistanceFromGps(
    //       {
    //         lat: userAddresses[i].userLat,
    //         lng: userAddresses[i].userLng,
    //       },
    //       false
    //     );
    //   if (
    //     distance_object &&
    //     distance_object.distance &&
    //     !distance_object.error
    //   ) {
    //     distances.push({
    //       address_id: userAddresses[i].address_id,
    //       distance: distance_object.distance,
    //     });
    //   } else if (!distance_object || distance_object.error) {
    //     break;
    //   }
    // }
    // if (
    //   userAddresses &&
    //   userAddresses.length &&
    //   distances.length &&
    //   userAddresses.length === distances.length
    // ) {
    //   const minDistanceAddress = _.minBy(distances, "distance");
    //   if (minDistanceAddress && minDistanceAddress.address_id) {
    //     const address = _.find(userAddresses, {
    //       address_id: minDistanceAddress.address_id,
    //     });
    //     if (
    //       address &&
    //       !_.isEmpty(address) &&
    //       (!selectedAddress ||
    //         selectedAddress.address_id !== address.address_id)
    //     ) {
    //       this.store.dispatch(
    //         new DeliveryMethodAddressActions.setOpenSelectStorePopupDelivery(
    //           true
    //         )
    //       );
    //       this.store.dispatch(
    //         new DeliveryMethodAddressActions.setOpenNotValidAddressModal(false)
    //       );
    //       this.store.dispatch(
    //         new DeliveryMethodAddressActions.setCheckSelectedAddress(address)
    //       );
    //     }
    //   }
    // }
  }

  async checkDeviceLocation() {
    let distance: any;
    const distance_object: any =
      await this.checkLocationDistanceService.calculateDistanceFromGps(
        {
          lat: this.currentStore.mapCenterStoreLat,
          lng: this.currentStore.mapCenterStoreLng,
        },
        false
      );

    // console.log("distance_object", distance_object);
    if (distance_object && distance_object.distance) {
      distance = distance_object.distance;
    }

    if (distance) {
      if (distance <= 100) {
      } else {
        this.openNoLocationDineInModal(
          "popups.no-location-dine-in-modal.you-are-out-of-store"
        );
      }
    } else {
      this.openNoLocationDineInModal(
        this.translateService.instant(
          "popups.no-location-dine-in-modal.we-have-to-access-your-location-to-continue-for-dine-in"
        )
      );
    }
  }

  async openNoLocationDineInModal(message) {
    this.checkLocationDineInModal = await this.modalCtrl.create({
      component: NoLocationDineInModalComponent,
      cssClass: "no-location-dine-in-modal",
      animated: true,
      componentProps: {
        orderNowModal: false,
        appComponentCheck: true,
        message: message,
      },
      backdropDismiss: false,
    });

    await this.checkLocationDineInModal.present();
    this.checkLocationDineInModal.onDidDismiss().then(async (data) => {
      this.checkLocationDineInModal = null;
    });
  }

  setSessionId() {
    const session_id = uuid();
    this.store.dispatch(
      new PlatformInfoActions.SetSessionId(_.cloneDeep(session_id))
    );
  }

  async setDeviceId() {
    if (Capacitor.isNativePlatform()) {
      const device_id = await Device.getId();
      this.store.dispatch(
        new PlatformInfoActions.SetDeviceId(_.cloneDeep(device_id.identifier))
      );
    } else {
      if (
        navigator.cookieEnabled &&
        localStorage.getItem("browser_device_id")
      ) {
        const device_id = localStorage.getItem("browser_device_id");
        this.store.dispatch(
          new PlatformInfoActions.SetDeviceId(_.cloneDeep(device_id))
        );
      } else if (
        navigator.cookieEnabled &&
        !localStorage.getItem("browser_device_id")
      ) {
        const device_id = uuid();
        this.store.dispatch(
          new PlatformInfoActions.SetDeviceId(_.cloneDeep(device_id))
        );
        localStorage.setItem("browser_device_id", device_id);
      } else {
        const device_id = uuid();
        this.store.dispatch(
          new PlatformInfoActions.SetDeviceId(_.cloneDeep(device_id))
        );
      }
    }
  }

  subscribeUserPoints() {
    this.store
      .select("points")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.userPoints &&
          !_.isEqual(this.userPoints, state.userPoints)
        ) {
          this.userPoints = _.cloneDeep(state.userPoints);
          console.log("user points", this.userPoints);
        }
        if (
          state &&
          state.highestReward &&
          !_.isEqual(this.highestReward, state.highestReward)
        ) {
          this.highestReward = _.cloneDeep(state.highestReward);

          if (this.highestReward && this.highestReward.user_points_percent) {
            if (this.highestReward.user_points_percent < 50) {
              this.pointsProgressBarColor = "dark";
            } else if (
              this.highestReward.user_points_percent >= 50 &&
              this.highestReward.user_points_percent <= 99
            ) {
              this.pointsProgressBarColor = "warning";
            } else if (this.highestReward.user_points_percent === 100) {
              this.pointsProgressBarColor = "success";
            }
          }
        }
      });

    this.store
      .select("cartActiveCoupons")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (state && !_.isEqual(this.cartActiveCoupons, state.activeCoupons)) {
          this.cartActiveCoupons = _.cloneDeep(state.activeCoupons);
          let activeCoupons = [];
          if (this.cartActiveCoupons) {
            activeCoupons = _.filter(
              _.cloneDeep(this.cartActiveCoupons),
              (coupon) => {
                if (coupon && coupon.status === "active") {
                  return coupon;
                }
              }
            );
          }

          this.activeCoupons = _.cloneDeep(activeCoupons);
        }
      });
  }

  routeParamsSubscription() {
    this.router.events.pipe(distinctUntilChanged()).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Hide progress spinner or progress bar
        console.log("event url", event.url);
        if (event.url.includes("catalog")) {
          this.selectedIndex = "catalog";
        } else if (event.url.includes("cart")) {
          this.selectedIndex = "cart";
        } else if (event.url.includes("homepage")) {
          this.selectedIndex = "homepage";
        } else if (event.url.includes("orders")) {
          this.selectedIndex = "orders";
        } else if (event.url.includes("payment-cards")) {
          this.selectedIndex = "payment-cards";
        } else if (event.url.includes("discount-coupons")) {
          this.selectedIndex = "discount-coupons";
        } else if (event.url.includes("deliveryAreas")) {
          this.selectedIndex = "deliveryAreas";
        } else if (event.url.includes("loyaltySystem")) {
          this.selectedIndex = "loyaltySystem";
        } else if (event.url.includes("storeSchedule")) {
          this.selectedIndex = "storeSchedule";
        } else if (event.url.includes("payment-terms")) {
          this.selectedIndex = "payment-terms";
        } else if (event.url.includes("points")) {
          this.selectedIndex = "points";
        } else if (event.url.includes("ratings")) {
          this.selectedIndex = "ratings";
        } else if (event.url.includes("categoriesBrowser")) {
          this.selectedIndex = "catalog";
        } else if (event.url.includes("home")) {
          this.selectedIndex = "homepage";
        } else if (event.url.includes("favoriteProducts")) {
          this.selectedIndex = "favoriteProducts";
        } else if (event.url.includes("account-settings")) {
          this.selectedIndex = "account-settings";
        } else if (event.url.includes("affiliate")) {
          this.selectedIndex = "affiliate";
        } else if (event.url.includes("reservations")) {
          this.selectedIndex = "reservations";
        } else if (event.url.includes("kiosk")) {
          this.selectedIndex = "kiosk";
        } else if (event.url.includes("contests")) {
          this.selectedIndex = "contests";
        }

        this.store.dispatch(
          new GroupAppActions.SetActiveRoute(
            _.cloneDeep(this.selectedIndex || null)
          )
        );
      }
    });
  }

  pageChange(route) {
    this.router.navigateByUrl("/" + route, {
      replaceUrl: true,
    });
  }

  requestsErrorHandling() {
    this.store
      .select("requestsErrorHandling")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(200))
      .subscribe((state) => {
        if (
          state &&
          state.requestsErrorsArray &&
          !_.isEqual(state.requestsErrorsArray, this.requestsErrorArray)
        ) {
          this.requestsErrorArray = _.cloneDeep(state.requestsErrorsArray);
          if (!_.isEmpty(this.requestsErrorArray)) {
            this.haldleRequestErrorAlerts();
          }
        }
      });
  }

  async haldleRequestErrorAlerts() {
    console.log("haddle request alerts", this.requestsErrorArray);
    let errorForAppRestart = _.find(this.requestsErrorArray, {
      restartApp: true,
      request: false,
    });
    console.log("error for app restart", errorForAppRestart);
    if (errorForAppRestart) {
      const alert = await this.alertController.create({
        header: this.translateService.instant("alert"),
        message: this.translateService.instant(
          "errorMessages.something-went-wrong-check-your-internet-connection"
        ),
        backdropDismiss: false,
        buttons: [
          {
            text: this.translateService.instant("cancel"),
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              console.log("cancel restarting app");
            },
          },
          {
            text: this.translateService.instant("restart-app"),
            handler: () => {
              console.log("Confirm restarting app");
              location.reload();
            },
          },
        ],
      });

      await alert.present();
    } else {
      let error = _.find(this.requestsErrorArray, {
        request: false,
      });
      if (error) {
        const alert = await this.alertController.create({
          header: this.translateService.instant("alert"),
          message: this.translateService.instant(
            "errorMessages.something-went-wrong-check-your-internet-connection"
          ),
          buttons: [
            {
              text: this.translateService.instant("cancel"),
              role: "cancel",
              cssClass: "secondary",
              handler: (blah) => {
                console.log("cancel");
              },
            },
            {
              text: this.translateService.instant("ok"),
              handler: () => {
                console.log("Confirm");
              },
            },
          ],
        });
        await alert.present();
      }
    }
  }

  fetchCustomerReviews() {
    this.dataStorageService
      .getReviews(this.mo_token)

      .subscribe({
        next: async (res: any) => {
          if (res && res.success) {
            this.store.dispatch(
              new ReviewsActions.SetReviews(_.cloneDeep(res.data))
            );
          }
        },
        error: async (err) => {
          return err;
        },
      });
  }

  firebaseMobileInit() {
    if (
      this.groupApp &&
      this.groupApp.firebaseVerificationEnabled &&
      Capacitor.isNativePlatform()
    ) {
    }
  }

  pauseEvent() {
    this.platform.pause.subscribe(() => {
      console.log("pause event");
      this.clearPingTimeout();
      //footprint
      //this.sendFootprint("pause_event_called", null);
    });
  }

  onResumeEvent() {
    this.platform.resume.subscribe(() => {
      console.log("resume event");
      //footprint
      // this.sendFootprint("resume_event_called", null);

      this.sendSocketMessage(
        {
          type: "user-test-connection", // This tests the connection of the socket using a mock socket api!
        },
        3
      );

      if (
        this.currentUser &&
        this.mo_token &&
        this.router &&
        this.router.url &&
        this.router.url.toString().includes("orders")
      ) {
        this.fetchUserOrdersDebounce();
      }

      if (
        this.currentUser &&
        this.mo_token &&
        this.router &&
        this.router.url &&
        this.router.url.toString().includes("reservations") &&
        this.currentStore &&
        this.currentStore.reservationMobileorderActive
      ) {
        this.fetchUserOrdersDebounce();
      }

      if (
        Capacitor.isNativePlatform() &&
        this.groupApp &&
        this.groupApp.branch_key &&
        this.groupApp.social_links
      ) {
        console.log("BranchDeepLinks on resume");

        BranchDeepLinks.addListener("init", (event: BranchInitEvent) => {
          // Retrieve deeplink keys from 'referringParams' and evaluate the values to determine where to route the user
          // Check '+clicked_branch_link' before deciding whether to use your Branch routing logic
          console.log("Deep Link branch Data:", event.referringParams);

          if (
            event.referringParams &&
            _.isObject(event.referringParams) &&
            event.referringParams.event
          ) {
            const data = event.referringParams;
            this.branchIoDataBeforeWritingState = data;
            localStorage.setItem("branchLinkData", JSON.stringify(data));
            this.startHandleBranchLinkData();
          }
        });

        BranchDeepLinks.addListener("initError", (error: any) => {
          console.log("error on init session", error);

          //TODO what we want to do here ????
        });
      }
    });
  }

  async getGroupFetchingStores() {
    if (Capacitor.isNativePlatform()) {
      const appInfo = await App.getInfo();
      this.versionNumber = appInfo.version;

      this.store.dispatch(
        new PlatformInfoActions.SetVersionNumber(
          _.cloneDeep(this.versionNumber)
        )
      );
      let group: any;
      if (appInfo.id.split(".")[1] === "deliverymanager") {
        group = appInfo.id.split(".")[2];
      } else if (
        appInfo.id === "gr.prassas_grill.prassasgrill" ||
        appInfo.id === "gr.wrk.Prassas-Grill"
      ) {
        group = "prassas";
      } else if (
        appInfo.id === "gr.itworx.polis" ||
        appInfo.id === "gr.itworx.Polis"
      ) {
        group = "polis";
      } else if (
        appInfo.id === "com.quick.n.easy.pizza_express" ||
        appInfo.id === "com.smartup.pizzaexpress"
      ) {
        group = "pizzaexpress";
      } else {
        group = appInfo.id.split(".")[1];
      }
      this.getGroupApp(group);
    } else {
      if (
        window.location.hostname === "localhost" ||
        window.location.hostname.indexOf(".deliverymanager.app") >= 0 ||
        !_.isNaN(parseInt(window.location.hostname.split(".")[0]))
      ) {
        this.setDeveloperMode(true);
      } else {
        this.setDeveloperMode(false);
      }
      if (window.location.hostname.indexOf(".deliverymanager.app") >= 0) {
        let group =
          window.location.hostname.split(".")[0] !== "localhost" &&
          _.isNaN(parseInt(window.location.hostname.split(".")[0]))
            ? window.location.hostname.split(".")[0]
            : this.localhostGroupDevelopement;
        this.getGroupApp(group);
      } else {
        let group;
        if (
          window.location.hostname.split(".")[0] !== "localhost" &&
          _.isNaN(parseInt(window.location.hostname.split(".")[0]))
        ) {
          try {
            const first_part = window.location.hostname
              .split(".")[0]
              .split("-")[0];
            group =
              first_part +
              window.location.hostname.split(".")[1] +
              window.location.hostname.split(".")[2];

            if (!group) {
              group = window.location.hostname.replace(/\./g, "");
            }
          } catch {
            group = window.location.hostname.replace(/\./g, "");
          }
        } else {
          group = this.localhostGroupDevelopement;
        }

        this.getGroupApp(group);
      }
    }
  }

  oneSignalBrowserInitScript() {
    const script = document.createElement("script");

    script.text = "window.OneSignal = window.OneSignal || [];";
    this.renderer.appendChild(document.head, script);
  }

  dynamicScriptInitialization(src: string, id: string): HTMLScriptElement {
    const script = document.createElement("script");
    script.async = true;
    script.src = src;
    this.renderer.appendChild(document.head, script);
    return script;
  }

  getGroupApp(param_for_fetching_group) {
    this.requestsErrorHandling();
    this.groupAppLoading = true;

    this.dataStorageService.fetchGroupApp(param_for_fetching_group).subscribe({
      next: (res: any) => {
        if (res) {
          //TODO DELETE THIS TEST AND ADD ATTR TO SUPERADMIN
          if (res && res.group && res.group === "beat") {
            res.splash_screen_active_browser = true;
          }

          if (
            (!Capacitor.isNativePlatform() &&
              !res.splash_screen_active_browser) ||
            !res.splash_screen_active ||
            !res.splashScreenImage
          ) {
            this.splashScreenDissmised = true;
          }

          this.trustHomepageCustomButtonsHTML(res);
          this.getAllStores(res);
          this.setDefaultLanguage(res);

          this.fetchGroupTheme(
            res.group,
            res.design_settings && res.design_settings.disable_app_darkmode
              ? true
              : false
          );

          if (res && res.fixedPointsAddressSystemActive) {
            this.fetchFixedPointsAdresses(res.group);
          }

          if (
            !this.developerMode &&
            Capacitor.isNativePlatform() &&
            this.checkBundleVersion(_.cloneDeep(res))
          ) {
            res.appSuspended = false;
            res.onlyOrderAppSuspended = false;
            this.store.dispatch(
              new GroupAppActions.SetGroupApp(_.cloneDeep(res))
            );

            this.router.navigateByUrl("/bundle-update", {
              replaceUrl: true,
            }); //navigate to nundleUpdatePage
          }

          if (res && res.appSuspended === true) {
            this.openStoreBlockModal(null, null);
          }

          if (res && res.onlyOrderAppSuspended) {
            this.openStoreBlockModal(
              res.onlyOrderAppSuspendedMessageTranslations,
              res.onlyOrderAppSuspendedButtons
            );
          }

          this.storeImage = res.iconImage;
          this.headerLogo = res.headerLogo;
          console.log("groupAppHeaderLogo", res.headerLogo);
          this.darkHeaderLogo = res.headerLogoDark;
          console.log("groupAppHeaderLogoDark", res.headerLogoDark);

          this.store.dispatch(
            new GroupAppActions.SetGroupApp(_.cloneDeep(res))
          );
          this.store.dispatch(
            new AppInitLoadingActions.SetAppInitLoadingStep("check-update")
          );
          console.log("groupAppRes", res);

          if (res.facebookPixel) {
            this.analyticsService.initializeFbq();
            this.analyticsService.fbqTrackPageView();
          }

          if (
            res &&
            res.webFirebase &&
            res.webFirebase.apiKey &&
            (!Capacitor.isNativePlatform() ||
              (!Capacitor.isNativePlatform() &&
                window.location.hostname === "localhost"))
          ) {
            console.log("initialize firebase analytics and recaptcha");
            // In this case we check that the apps are empty so that we can initialize them
            this.firebaseBrowserService.initializeFirebaseBrowser(
              _.cloneDeep(res)
            );
            this.analyticsService.initializeFirebaseAnalytics();
          }
        } else {
          this.store.dispatch(
            new RequestsErrorHandlingActions.AddUpdateRequestsErrorHandling({
              id: "groupApp",
              request: false,
              restartApp: true,
            })
          );
        }
      },
      error: (error) => {
        this.groupAppLoading = false;
        this.store.dispatch(
          new RequestsErrorHandlingActions.AddUpdateRequestsErrorHandling({
            id: "groupApp",
            request: false,
            restartApp: true,
          })
        );
        return error;
      },
    });
  }

  trustHomepageCustomButtonsHTML(res) {
    if (
      res &&
      res.design_settings &&
      res.design_settings.customHomepageButtons &&
      res.design_settings.customHomepageButtons.active
    ) {
      if (
        res.design_settings.customHomepageButtons.button_one &&
        res.design_settings.customHomepageButtons.button_one.translations
      ) {
        for (const property in res.design_settings.customHomepageButtons
          .button_one.translations) {
          if (
            res.design_settings.customHomepageButtons.button_one.translations[
              property
            ]
          ) {
            res.design_settings.customHomepageButtons.button_one.translations[
              property
            ] = _.cloneDeep(
              this.domSanitizer.bypassSecurityTrustHtml(
                res.design_settings.customHomepageButtons.button_one
                  .translations[property]
              )
            );
          }
        }
      }

      if (
        res.design_settings.customHomepageButtons.button_two &&
        res.design_settings.customHomepageButtons.button_two.translations
      ) {
        for (const property in res.design_settings.customHomepageButtons
          .button_two.translations) {
          if (
            res.design_settings.customHomepageButtons.button_two.translations[
              property
            ]
          ) {
            res.design_settings.customHomepageButtons.button_two.translations[
              property
            ] = _.cloneDeep(
              this.domSanitizer.bypassSecurityTrustHtml(
                res.design_settings.customHomepageButtons.button_two
                  .translations[property]
              )
            );
          }
        }
      }
    }
  }

  initBranchIOSettingListeners(groupApp) {
    if (
      window &&
      (!Capacitor.isNativePlatform() ||
        (!Capacitor.isNativePlatform() &&
          window.location.hostname === "localhost"))
    ) {
      this.initializationOfBrowserScripts(groupApp);
    } else if (
      Capacitor.isNativePlatform() &&
      groupApp.branch_key &&
      groupApp.social_links
    ) {
      console.log("called init session from mobile branch link");

      BranchDeepLinks.addListener("init", (event: BranchInitEvent) => {
        // Retrieve deeplink keys from 'referringParams' and evaluate the values to determine where to route the user
        // Check '+clicked_branch_link' before deciding whether to use your Branch routing logic
        console.log("Deep Link branch Data:", event.referringParams);

        if (
          event.referringParams &&
          _.isObject(event.referringParams) &&
          event.referringParams.event
        ) {
          const data = event.referringParams;
          this.branchIoDataBeforeWritingState = data;
          localStorage.setItem("branchLinkData", JSON.stringify(data));
          this.startHandleBranchLinkData();
        }
      });

      BranchDeepLinks.addListener("initError", (error: any) => {
        console.log("error on init session", error);

        //TODO what we want to do here ????
      });

      this.oneSignalService.initOneSignalMobile(groupApp);

      // window.Branch.initSession()
      //   .then((data) => {
      //     if (data && typeof data === "object") {
      //       console.log("Deep Link branch Data:", data);
      //       // console.log(
      //       //   "Deep Link branch Data: coupon_rule_id ",
      //       //   data.coupon_rule_id
      //       // );
      //     }
      //   })
      //   .catch((error) => {
      //     console.log("error on init session", error);
      //     console.log(
      //       "error on init session:try calling getLatestReferingParams()"
      //     );
      //     window.Branch.getLatestReferringParams().then((data) => {
      //       if (data && typeof data === "object") {
      //         console.log("Deep Link branch Data:", data);
      //         // console.log(
      //         //   "Deep Link branch Data: coupon_rule_id ",
      //         //   data.coupon_rule_id
      //         // );
      //         this.store.dispatch(
      //           new BranchLinkDataActions.SetBranchLinkData(data)
      //         );
      //         if (data && data.coupon_rule_id) {
      //           this.store.dispatch(
      //             new BranchLinkDataActions.SetCouponRuleId(
      //               _.cloneDeep(data.coupon_rule_id)
      //             )
      //           );
      //         }
      //         if (data && data.affiliate_coupon_code) {
      //           this.store.dispatch(
      //             new BranchLinkDataActions.SetAffiliateCouponCode(
      //               _.cloneDeep(data.affiliate_coupon_code)
      //             )
      //           );
      //         }
      //       }
      //     });
      //   });
    }
  }

  startHandleBranchLinkData() {
    let data: any;
    if (Capacitor.isNativePlatform()) {
      if (
        this.branchIoDataBeforeWritingState &&
        !_.isEmpty(this.branchIoDataBeforeWritingState)
      ) {
        data = this.branchIoDataBeforeWritingState;
      } else {
        let localStorageData = localStorage.getItem("branchLinkData");
        if (localStorageData) {
          data = JSON.parse(localStorageData);
          localStorage.removeItem("branchLinkData");
        }
        this.branchIoDataBeforeWritingState = null;
      }
    } else {
      if (
        this.branchIoDataBeforeWritingState &&
        !_.isEmpty(this.branchIoDataBeforeWritingState)
      ) {
        data = this.branchIoDataBeforeWritingState;
        this.branchIoDataBeforeWritingState = null;
      }
    }
    console.log("inside startHandleBranchLinkData", data);
    if (data && !_.isEmpty(data)) {
      this.store.dispatch(new BranchLinkDataActions.SetBranchLinkData(data));
      if (data && data.coupon_rule_id) {
        this.store.dispatch(
          new BranchLinkDataActions.SetCouponRuleId(
            _.cloneDeep(data.coupon_rule_id)
          )
        );
      }
      if (data && data.affiliate_coupon_code) {
        this.store.dispatch(
          new BranchLinkDataActions.SetAffiliateCouponCode(
            _.cloneDeep(data.affiliate_coupon_code)
          )
        );
      }
    }
  }

  async presentSplashScreenModal(splashScreenImage, groupApp) {
    this.splashScreenModal = await this.modalCtrl.create({
      component: LoadingSplashscreenComponent,
      cssClass: "loading-splashscreen-modal",
      animated: true,
      componentProps: {
        groupApp: groupApp,
        splashScreenImage: splashScreenImage,
      },
      backdropDismiss: false,
    });

    await this.splashScreenModal.present();

    setTimeout(async () => {
      this.splashScreenDissmised = true;

      if (this.groupAppSplashScreenSubscription) {
        this.groupAppSplashScreenSubscription.unsubscribe();
      }
      await this.splashScreenModal.dismiss();
    }, 4000);
  }
  async openStoreBlockModal(
    onlyOrderAppSuspendedMessageTranslations,
    onlyOrderAppSuspendedButtons
  ) {
    const modal = await this.modalCtrl.create({
      component: BlockStoreModalComponent,
      cssClass: "block-store-modal",
      animated: true,

      backdropDismiss: false,
      componentProps: {
        onlyOrderAppSuspendedMessageTranslations:
          onlyOrderAppSuspendedMessageTranslations,
        onlyOrderAppSuspendedButtons: onlyOrderAppSuspendedButtons,
      },
    });

    await modal.present();
  }

  grouAppSubscription() {
    this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(200))
      .subscribe((state) => {
        if (
          state &&
          state.selectedLangugage &&
          !_.isEqual(this.selectedLanguage, state.selectedLangugage)
        ) {
          if (this.selectedLanguage) {
            this.setLanguage(_.cloneDeep(state.selectedLangugage));
          }
          this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
          console.log("getting language from state", this.selectedLanguage);
        }

        if (
          state &&
          state.selectedTheme &&
          !_.isEqual(this.selectedTheme, state.selectedTheme)
        ) {
          this.selectedTheme = _.cloneDeep(state.selectedTheme);
        }

        if (
          state &&
          state.changeChannelTimestamp &&
          !_.isEqual(this.changeChannelTimestamp, state.changeChannelTimestamp)
        ) {
          this.changeChannelTimestamp = _.cloneDeep(
            state.changeChannelTimestamp
          );
          this.changeChannel();
        }

        if (
          state &&
          state.groupApp &&
          !_.isEqual(this.groupApp, state.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp);

          if (this.stores && this.groupApp) {
            this.currentStoreIdSubscription();
          }

          if (this.currentStoreId) {
            this.analyticsService.dmAnalyticsMessage(
              "app_loaded",
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null
            );
          }
          this.flattenedIconBackgroundFlag = this.groupApp.flattenedIcon
            ? true
            : false;

          // console.log("flattenedIconBackground", this.groupApp.flattenedIcon);

          if (
            this.groupApp &&
            this.groupApp.headerLogo &&
            this.groupApp.headerLogoIconBackground
          ) {
            const el = document.documentElement;
            if (el) {
              el.style.setProperty(
                "--headerLogoIconBackground",
                this.groupApp.headerLogoIconBackground
              );
            }
          }

          if (
            this.flattenedIconBackgroundFlag &&
            this.groupApp.flattenedIconBackground
          ) {
            // console.log(
            //   "flattenedIconBackground",
            //   this.groupApp.flattenedIconBackground
            // );
            const el = document.documentElement;
            if (el) {
              el.style.setProperty(
                "--flattenedIconBackground",
                this.groupApp.flattenedIconBackground
              );
            }
            //  console.log("el el style", test);
          }
        }
      });
  }

  checkBundleVersion(groupApp) {
    console.log("versionNumber", this.versionNumber);
    var majorInstalledNumber = parseInt(this.versionNumber.split(".")[0]);
    var minorInstalledNumber = parseInt(this.versionNumber.split(".")[1]);
    var patchInstalledNumber = parseInt(this.versionNumber.split(".")[2]);
    console.log("majorInstalledNumber", majorInstalledNumber);
    console.log("minorInstalledNumber", minorInstalledNumber);
    console.log("patchInstalledNumber", patchInstalledNumber);
    console.log("groupApp.iOSBundleAppVersion", groupApp.iOSBundleAppVersion);
    console.log(
      "groupApp.iOSBundleAppVersionSize",
      groupApp.iOSBundleAppVersionSize
    );
    if (
      this.iOSPlatform &&
      groupApp.iOSBundleAppVersion &&
      groupApp.iOSBundleAppVersionSize &&
      groupApp.iOSNativeUrl
    ) {
      if (
        majorInstalledNumber <
          parseInt(groupApp.iOSBundleAppVersion.split(".")[0]) ||
        (majorInstalledNumber <=
          parseInt(groupApp.iOSBundleAppVersion.split(".")[0]) &&
          minorInstalledNumber <
            parseInt(groupApp.iOSBundleAppVersion.split(".")[1])) ||
        (majorInstalledNumber <=
          parseInt(groupApp.iOSBundleAppVersion.split(".")[0]) &&
          minorInstalledNumber <=
            parseInt(groupApp.iOSBundleAppVersion.split(".")[1]) &&
          patchInstalledNumber <
            parseInt(groupApp.iOSBundleAppVersion.split(".")[2]))
      ) {
        return true;
      } else {
        return false;
      }
    } else if (
      this.androidPlatform &&
      groupApp.androidBundleAppVersion &&
      groupApp.androidBundleAppVersionSize &&
      groupApp.androidNativeUrl
    ) {
      if (
        majorInstalledNumber <
          parseInt(groupApp.androidBundleAppVersion.split(".")[0]) ||
        (majorInstalledNumber <=
          parseInt(groupApp.androidBundleAppVersion.split(".")[0]) &&
          minorInstalledNumber <
            parseInt(groupApp.androidBundleAppVersion.split(".")[1])) ||
        (majorInstalledNumber <=
          parseInt(groupApp.androidBundleAppVersion.split(".")[0]) &&
          minorInstalledNumber <=
            parseInt(groupApp.androidBundleAppVersion.split(".")[1]) &&
          patchInstalledNumber <
            parseInt(groupApp.androidBundleAppVersion.split(".")[2]))
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  couponCodeCheckSubscription() {
    this.store
      .select("customerCoupons")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (
          state &&
          !_.isEqual(
            state.triggerCouponCodeCheckObject,
            this.triggerCouponCodeCheckObject
          )
        ) {
          this.triggerCouponCodeCheckObject = _.cloneDeep(
            state.triggerCouponCodeCheckObject
          );
          if (
            this.triggerCouponCodeCheckObject &&
            this.triggerCouponCodeCheckObject.coupon_code &&
            this.triggerCouponCodeCheckObject.type === "coupon_rule_id"
          ) {
            this.sendSocketCouponRuleId(
              this.triggerCouponCodeCheckObject.coupon_code
            );
          } else if (
            this.triggerCouponCodeCheckObject &&
            this.triggerCouponCodeCheckObject.coupon_code &&
            this.triggerCouponCodeCheckObject.type === "affiliate_coupon_code"
          ) {
            this.sendSocketAffiliateCouponCode(
              this.triggerCouponCodeCheckObject.coupon_code
            );
          } else if (
            this.triggerCouponCodeCheckObject &&
            this.triggerCouponCodeCheckObject.coupon_code &&
            this.triggerCouponCodeCheckObject.type === "partner_coupon_id"
          ) {
            this.sendSocketPartnerCouponId(
              this.triggerCouponCodeCheckObject.coupon_code
            );
          }
        }
      });
  }

  addListenerToHandleBranchData() {
    console.log("inside addListenerToHandleBranchData");
    // BranchDeepLinks.handleUrl()
    BranchDeepLinks.addListener("init", (event: BranchInitEvent) => {
      // Retrieve deeplink keys from 'referringParams' and evaluate the values to determine where to route the user
      // Check '+clicked_branch_link' before deciding whether to use your Branch routing logic
      console.log("Deep Link branch Data:", event.referringParams);

      if (
        event.referringParams &&
        _.isObject(event.referringParams) &&
        event.referringParams.event
      ) {
        const data = event.referringParams;
        this.branchIoDataBeforeWritingState = data;
        localStorage.setItem("branchLinkData", JSON.stringify(data));
        this.startHandleBranchLinkData();
      }
    });
  }

  branchIOSubscription() {
    this.store
      .select("branchLinkData")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(200))
      .subscribe((state) => {
        if (
          state.timestamp &&
          !_.isEqual(this.branchTriggerTimestamp, state.timestamp)
        ) {
          this.branchTriggerTimestamp = _.cloneDeep(state.timestamp);
          // this.addListenerToHandleBranchData();
          // let localStorageData = localStorage.getItem("branchLinkData");
          // if (localStorageData) {
          //   const data = JSON.parse(localStorageData);
          //   localStorage.removeItem("branchLinkData");
          //   console.log("data", data);
          //   BranchDeepLinks.handleUrl({ url: data["~referring_link"] });
          // }
          // this.startHandleBranchLinkData();
        }
        if (state && !_.isEqual(this.branch_link_data, state.branchLinkData)) {
          this.branch_link_data = _.cloneDeep(state.branchLinkData);
          if (this.branch_link_data) {
            this.handleBranchLinkData(_.cloneDeep(this.branch_link_data));
          }
        }
        if (state && !_.isEqual(this.coupon_rule_id, state.coupon_rule_id)) {
          this.coupon_rule_id = _.cloneDeep(state.coupon_rule_id);
        }
        if (
          state &&
          !_.isEqual(this.affiliate_coupon_code, state.affiliate_coupon_code)
        ) {
          this.affiliate_coupon_code = _.cloneDeep(state.affiliate_coupon_code);
        }
      });
  }

  prdWithoutImage(product) {
    return (
      !product.image ||
      (product.image && !product.image.true) ||
      (product.image && !product.image.true["16x9"]) ||
      (product.image &&
        product.image.true["16x9"] &&
        !product.image.true["16x9"].path)
    );
  }

  showProductDetailsModal(product) {
    let detailsCssClass;
    if (
      product &&
      product.num_of_options &&
      product.num_of_options < 2 &&
      this.prdWithoutImage(product)
    ) {
      detailsCssClass = "productDetailsModalSmall";
    } else if (
      product &&
      !product.squareImageDesign &&
      product.num_of_options &&
      product.num_of_options > 2 &&
      !this.prdWithoutImage(product)
    ) {
      detailsCssClass = "productDetailsModalBig";
    } else if (
      product &&
      product.squareImageDesign &&
      product.num_of_options &&
      product.num_of_options > 2 &&
      window &&
      window.innerWidth &&
      window.innerWidth >= 1200 &&
      !this.prdWithoutImage(product)
    ) {
      detailsCssClass = "productDetailsModalBigSquareImageDesign";
    } else {
      detailsCssClass = "productDetailsModal";
    }
    if (!this.productDetailsModal) {
      this.productDetailsModal = true;
      this.presentProductDetailsModal(product, detailsCssClass);
    }
  }

  async presentProductDetailsModal(product, cssClass) {
    this.productDetailsModal = await this.modalCtrl.create({
      component: ProductDetailsComponent,
      cssClass: cssClass,
      animated: true,
      componentProps: {
        product_id: product.product_id,
        cart_id: null,
      },
      backdropDismiss: false,
    });

    await this.productDetailsModal.present();
    this.productDetailsModal.onDidDismiss().then(() => {
      this.productDetailsModal = null;
    });
  }

  async presentMagicLoginPopup() {
    this.analyticsService.dmAnalyticsMessage(
      "login_button",
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );

    if (!Capacitor.isNativePlatform()) {
      this.loginPopupService.presentFirebaseModalLogin(null, null);
    } else if (Capacitor.isNativePlatform()) {
      this.loginPopupService.closeMobileMagicLoginAlert();
      this.loginPopupService.presentMobileMagicLoginAlert(
        this.newCustomer,
        this.groupApp
      );
    }
  }

  branchLinkNoUserPromoLinkActions(data) {
    let obj;
    if (data.coupon_rule_id) {
      obj = {
        coupon_rule_id: data.coupon_rule_id,
        group: this.groupApp.group,
      };
    } else if (data.affiliate_coupon_code) {
      obj = {
        affiliate_coupon_code: data.affiliate_coupon_code,
        group: this.groupApp.group,
      };
    }
    this.dataStorageService
      .moCheckCouponCode(obj)
      .pipe(
        catchError((err) => {
          console.log("checkPromoLink err", err);
          return err;
        })
      )
      .subscribe(async (res: any) => {
        // if (res) {
        //   this.store.dispatch(
        //     new CouponsActions.SetCouponCheckRequestCompleted(true)
        //   );
        // }
        if (res && (data.coupon_rule_id || data.affiliate_coupon_code)) {
          if (res && res.success) {
            const modal = await this.modalCtrl.create({
              component: CouponInfoPopupComponent,
              componentProps: {
                title: this.translateService.instant("information"),
                submessage: res.coupon_rule_description
                  ? res.coupon_rule_description
                  : "Κωδικός πρόσκλησης: " +
                    res.owner_of_code.affiliate_coupon_code,
                message: res.comment_id
                  ? this.translateService.instant(res.comment_id) +
                    " " +
                    this.translateService.instant(
                      "please-connect-to-get-the-promo"
                    )
                  : res.comments +
                    " " +
                    this.translateService.instant(
                      "please-connect-to-get-the-promo"
                    ),
                closeModalButton: true,
                ok_button: {
                  text: this.translateService.instant("homepage.magic-login"),
                },
                login_lottie: true,
              },
              backdropDismiss: false,
              cssClass: "coupon-info-popup",
            });

            await modal.present();
            await modal.onDidDismiss().then((data) => {
              if (data && data.data) {
                if (data.data === "ok") {
                  this.presentMagicLoginPopup();
                }
              }
            });
          } else if (res && !res.success) {
            if (res.comment_id || res.comments) {
              const alert = await this.alertController.create({
                header: this.translateService.instant("alert"),
                message: this.translateService.instant(
                  res.comment_id || res.comments
                ),

                buttons: ["OK"],
              });
              await alert.present();
            } else {
              const alert = await this.alertController.create({
                header: this.translateService.instant("alert"),
                message: this.translateService.instant(
                  "errorMessages.an-error-occurred"
                ),

                buttons: ["OK"],
              });
              await alert.present();
            }
          }
        }

        if (!res.success) {
          console.log("The coupon rule id is false or something");
          data.coupon_rule_id = null;
          data.affiliate_coupon_code = null;
          this.store.dispatch(new BranchLinkDataActions.ClearCouponRuleId());
          this.store.dispatch(
            new BranchLinkDataActions.ClearAffiliateCouponCode()
          );
          this.store.dispatch(
            new CouponsActions.SetTriggerCouponCodeCheck({
              timestamp: null,
              coupon_code: null,
              type: "",
            })
          );
        }
        console.log(
          "after moCheckCouponRuleId coupon_rule_id",
          data.coupon_rule_id
        );
        if (data.coupon_rule_id || data.affiliate_coupon_code) {
          const coupon_code = data.coupon_rule_id
            ? data.coupon_rule_id
            : data.affiliate_coupon_code;
          const coupon_type = data.coupon_rule_id
            ? "coupon_rule_id"
            : "affiliate_coupon_code";
          this.store.dispatch(
            new CouponsActions.SetTriggerCouponCodeCheck({
              timestamp: Date.now(),
              coupon_code: _.cloneDeep(coupon_code),
              type: coupon_type,
            })
          );
        }
      });
  }

  handleBranchLinkData(data) {
    console.log("handle_branch_link data", data);

    if (data && typeof data === "object" && data.event === "product") {
      if (data.product_id) {
        this.showProductDetailsModal(data);
      }
    } else if (
      data &&
      typeof data === "object" &&
      data.event === "scanned_order"
    ) {
      this.branchLinkEvent = "scanned_order";
      if (
        !this.mo_token &&
        !this.currentUser &&
        (!navigator.cookieEnabled ||
          (navigator.cookieEnabled &&
            (!localStorage.getItem("mo_token") ||
              localStorage.getItem("mo_token") === "")))
      ) {
        this.mo_socket_login(
          {
            data_id: data.data_id,
            password: data.password,
          },
          true,
          "scanned_order"
        );
      }
    } else if (data && typeof data === "object" && data.event === "login") {
      //This is from the branch link
      this.branchLinkEvent = "login";
      if (
        !this.mo_token &&
        !this.currentUser &&
        (!navigator.cookieEnabled ||
          (navigator.cookieEnabled &&
            (!localStorage.getItem("mo_token") ||
              localStorage.getItem("mo_token") === "")))
      ) {
        this.mo_socket_login(
          {
            mobile: data.mobile,
            password: data.password,
            coupon_rule_id: data.coupon_rule_id,
          },
          true,
          "login"
        );
      }
      // TODO I should add another option here where there is data.coupon_rule_id for data.affiliate_coupon_code
      if (data.coupon_rule_id) {
        //This is the case of a promo link in an SMS that also can login and contain a promo coupon rule id
        console.log("coupon_rule_id", data.coupon_rule_id);
        //Very important so that if the socket is not connected then it will be created when the socket opens!
        this.dataStorageService
          .moCheckCouponRuleId({
            //In this case I added the mobile too, so that I will be able to responde correctly about the coupon rule id
            coupon_rule_id: data.coupon_rule_id,
            mobile: data.mobile,
            group: this.groupApp.group,
          })
          .pipe(
            catchError((err) => {
              console.log("checkPromoLink err", err);
              return err;
            })
          )
          .subscribe(async (res: any) => {
            if (res) {
              this.store.dispatch(
                new CouponsActions.SetCouponCheckRequestCompleted(true)
              );
              const alert = await this.alertController.create({
                header: this.translateService.instant("information"),
                message: res.comment_id
                  ? this.translateService.instant(res.comment_id)
                  : res.comments,
                buttons: ["OK"],
              });
              await alert.present();
            } else {
              console.log("checkPromoLink no response");
            }
          });
      }
    } else if (
      data &&
      typeof data === "object" &&
      data.event === "promo_link"
    ) {
      //This is from the branch link, a promo link that is not causing the user to login too, as we do not know his mobile yet.
      console.log("saving the promo_link data");
      this.branchLinkEvent = "promo_link";
      if (data.coupon_rule_id || data.affiliate_coupon_code) {
        let coupon_type = data.coupon_rule_id
          ? "coupon_rule_id"
          : "affiliate_coupon_code";
        console.log(
          "coupon_code",
          data.coupon_rule_id ? data.coupon_rule_id : data.affiliate_coupon_code
        );
        if (
          !this.mo_token &&
          !this.currentUser &&
          (!navigator.cookieEnabled ||
            (navigator.cookieEnabled &&
              (!localStorage.getItem("mo_token") ||
                localStorage.getItem("mo_token") === "")))
        ) {
          this.branchLinkNoUserPromoLinkActions(data);
        } else if (this.currentUser && this.mo_token) {
          //This case is only for mobiler version when a resume event occures and tryies to handle link data with user loaded, in browser this is called in getUserData function
          let obj;
          if (coupon_type === "coupon_rule_id") {
            obj = {
              coupon_rule_id: data.coupon_rule_id,
              group: this.groupApp.group,
              mobile: this.currentUser.mobile,
            };
          } else {
            obj = {
              affiliate_coupon_code: data.affiliate_coupon_code,
              group: this.groupApp.group,
              mobile: this.currentUser.mobile,
            };
          }
          this.moCheckCouponCodeThrottle(obj);
        }
      }
    } else if (
      data &&
      typeof data === "object" &&
      data.event === "dinein-table"
    ) {
      //dine in QR scan

      if (data && data.table_id && data.store_id) {
        //Open dine in modal with table_id and store_id filled
        this.orderNowPopupService.closeDineInModal();
        this.orderNowPopupService.openDineInModal(data.table_id, data.store_id);
      }
    } else if (
      data &&
      typeof data === "object" &&
      data.event === "fixed-delivery-address" &&
      data.fixed_address_id &&
      data.group
    ) {
      // fixed-delivery-address scan
      this.fetchFixedAddressData(data.fixed_address_id, data.group);
      console.log("request for fixed delivery address", data);
    } else if (
      data &&
      typeof data === "object" &&
      data.event === "cashout-reward" &&
      data.password &&
      data.group &&
      data.mobile
    ) {
      // cash out reward email notification
      if (
        !this.mo_token &&
        !this.currentUser &&
        (!navigator.cookieEnabled ||
          (navigator.cookieEnabled &&
            (!localStorage.getItem("mo_token") ||
              localStorage.getItem("mo_token") === "")))
      ) {
        this.cashoutRewardBranchIONotificationHandler(
          null,
          data.mobile,
          data.password
        );
      } else if (this.currentUser && this.mo_token) {
        this.cashoutRewardBranchIONotificationHandler(
          this.currentUser,
          data.mobile,
          data.password
        );
      }
    }
  }

  private sendSocketCouponRuleId = _.throttle(
    (coupon_rule_id) => {
      if (
        this.socket &&
        this.socket.readyState === WebSocket.OPEN &&
        this.socket.url.indexOf("mousers") >= 0
      ) {
        this.socket.send(
          JSON.stringify({
            type: "check_coupon_rule_id",
            coupon_rule_id: coupon_rule_id,
          })
        );
      } else {
        console.log("no connection to mo users socket");
      }
    },
    5000,
    { leading: true, trailing: false }
  );

  private sendSocketAffiliateCouponCode = _.throttle(
    (affiliate_coupon_code) => {
      if (
        this.socket &&
        this.socket.readyState === WebSocket.OPEN &&
        this.socket.url.indexOf("mousers") >= 0
      ) {
        this.socket.send(
          JSON.stringify({
            type: "mo_users_affiliate_coupon_code",
            affiliate_coupon_code: affiliate_coupon_code,
          })
        );
      } else {
        console.log("no connection to mo users socket");
      }
    },
    5000,
    { leading: true, trailing: false }
  );

  private sendSocketPartnerCouponId = _.throttle(
    (partner_coupon_id) => {
      if (
        this.socket &&
        this.socket.readyState === WebSocket.OPEN &&
        this.socket.url.indexOf("mousers") >= 0
      ) {
        this.socket.send(
          JSON.stringify({
            type: "mo_users_partner_coupon_code",
            partner_coupon_id: partner_coupon_id,
          })
        );
      } else {
        console.log("no connection to mo users socket");
      }
    },
    5000,
    { leading: true, trailing: false }
  );

  private moCheckCouponCodeThrottle = _.throttle(
    (obj) => {
      console.log("Inside throttle check coupon code");
      this.dataStorageService
        .moCheckCouponCode(obj)
        .pipe(
          catchError((err) => {
            console.log("checkPromoLink err", err);
            return err;
          })
        )
        .subscribe(async (res: any) => {
          console.log(
            "callMoCheckCouponRuleIdFromGetUserData Response",
            obj.coupon_rule_id ? obj.coupon_rule_id : obj.affiliate_coupon_code
          );
          if (res) {
            this.store.dispatch(
              new CouponsActions.SetCouponCheckRequestCompleted(true)
            );
          }
          if (res && (obj.coupon_rule_id || obj.affiliate_coupon_code)) {
            if (res.success) {
              const modal = await this.modalCtrl.create({
                component: CouponInfoPopupComponent,
                componentProps: {
                  title: this.translateService.instant("information"),
                  submessage: res.coupon_rule_description
                    ? res.coupon_rule_description
                    : res.owner_of_code.affiliate_coupon_code,
                  message: res.comment_id
                    ? this.translateService.instant(res.comment_id) +
                      " " +
                      this.translateService.instant(
                        "wait-a-minute-for-your-coupon-to-be-created"
                      )
                    : res.comments +
                      " " +
                      this.translateService.instant(
                        "wait-a-minute-for-your-coupon-to-be-created"
                      ),
                  closeModalButton: true,
                  ok_button: {
                    text: this.translateService.instant(
                      "cart.cart-components.cart-active-coupons.my-coupons"
                    ),
                  },
                  coupon_lottie: true,
                },
                backdropDismiss: false,
                cssClass: "coupon-info-popup",
              });

              await modal.present();
              await modal.onDidDismiss().then((data) => {
                if (data && data.data) {
                  if (data.data === "ok") {
                    this.router.navigateByUrl("/discount-coupons", {
                      replaceUrl: true,
                    });
                  }
                }
              });
            } else {
              if (res.comment_id || res.comments) {
                const alert = await this.alertController.create({
                  header: this.translateService.instant("alert"),
                  message: this.translateService.instant(
                    res.comment_id || res.comments
                  ),

                  buttons: ["OK"],
                });
                await alert.present();
              } else {
                const alert = await this.alertController.create({
                  header: this.translateService.instant("alert"),
                  message: this.translateService.instant(
                    "errorMessages.an-error-occurred"
                  ),

                  buttons: ["OK"],
                });
                await alert.present();
              }
            }
          }

          if (!res.success) {
            console.log("The coupon rule id is false or something");
            obj.coupon_rule_id = null;
            obj.affiliate_coupon_code = null;
            this.store.dispatch(new BranchLinkDataActions.ClearCouponRuleId());
            this.store.dispatch(
              new BranchLinkDataActions.ClearAffiliateCouponCode()
            );
            this.store.dispatch(
              new CouponsActions.SetTriggerCouponCodeCheck({
                timestamp: null,
                coupon_code: null,
                type: "",
              })
            );
          }
          console.log("after moCheckCouponCode obj", obj);
          if (obj.coupon_rule_id || obj.affiliate_coupon_code) {
            const coupon_code = obj.coupon_rule_id
              ? obj.coupon_rule_id
              : obj.affiliate_coupon_code;
            const coupon_type = obj.coupon_rule_id
              ? "coupon_rule_id"
              : "affiliate_coupon_code";
            this.store.dispatch(
              new CouponsActions.SetTriggerCouponCodeCheck({
                timestamp: Date.now(),
                coupon_code: _.cloneDeep(coupon_code),
                type: coupon_type,
              })
            );
          }
        });
    },
    1500,
    { leading: true, trailing: false }
  );

  async cashoutRewardBranchIONotificationHandler(
    currentUser,
    mobile,
    password
  ) {
    if (currentUser) {
      if (currentUser.mobile === mobile) {
        this.router.navigateByUrl("/points", {
          replaceUrl: true,
        });
      } else {
        const alert = await this.alertController.create({
          header: this.translateService.instant("alert"),
          message: this.translateService.instant(
            "link_belongs_to_another_account"
          ),
          buttons: ["OK"],
        });

        await alert.present();
      }
    } else {
      this.presentLoading();

      this.mo_socket_login(
        {
          mobile: mobile,
          password: password,
        },
        true,
        "login"
      );

      this.cashoutRewardBranchIONavigateToPoints = true;
    }
  }

  storesSubscription() {
    this.store
      .select("stores")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe(async (state) => {
        if (state && state.stores && !_.isEqual(this.stores, state.stores)) {
          this.stores = _.cloneDeep(state.stores);

          if (this.stores && this.groupApp) {
            this.currentStoreIdSubscription();
          }

          this.showDeliveryButton = !_.isEmpty(
            _.filter(this.stores, (store) => {
              if (!store.disableOrderDelivery) {
                return store;
              }
            })
          );

          if (!this.showDeliveryButton) {
            this.infoShowDeliveryButton =
              await this.orderNowPopupService.checkToShowInfoDeliveryButton();

            this.infoShowDeliveryButton = !!this.infoShowDeliveryButton;
          }

          this.showPickupButton = !_.isEmpty(
            _.filter(this.stores, (store) => {
              if (!store.disableOrderPickup) {
                return store;
              }
            })
          );
          console.log("pickup button", this.showPickupButton);

          this.showDineInButton = !_.isEmpty(
            _.filter(this.stores, (store) => {
              if (store.dinein) {
                return store;
              }
            })
          );

          this.checkValidDeliveryMethod();
          this.checkAtLeastOneStoreKioskActive();

          this.showPaymentTerms = !!_.find(this.stores, {
            showPaymentTerms: true,
          });
        }
      });
  }

  checkAtLeastOneStoreKioskActive() {
    this.store
      .select("groupApp")
      .pipe(first())
      .subscribe((groupState) => {
        const groupApp = groupState.groupApp;
        this.atLeastOneStoreKioskActive = !!_.find(this.stores, (store) => {
          if (
            store.dm_kiosk_app_active &&
            !(
              groupApp &&
              groupApp.design_settings &&
              groupApp.design_settings.dm_kiosk_app_disable_mobileorder
            )
          ) {
            return true;
          }
        });
      })
      .unsubscribe();
  }

  clearDeliveryMethod() {
    this.store.dispatch(
      new DeliveryMethodAddressActions.setSelectedAddress(_.cloneDeep(null))
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setValidStores(_.cloneDeep(null))
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setValidDeliveryAreas(_.cloneDeep(null))
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setOpenNotValidAddressModal(false)
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setOpenSelectStorePopupDelivery(false)
    );
    this.store.dispatch(new DeliveryMethodAddressActions.setDineIn(null));
    this.store.dispatch(new DeliveryMethodAddressActions.setOrderPickup(null));

    if (navigator.cookieEnabled) {
      let deliveryMethodAddressObj: any = localStorage.getItem(
        "deliveryMethodAddress"
      );
      if (deliveryMethodAddressObj) {
        deliveryMethodAddressObj = JSON.parse(deliveryMethodAddressObj);
        deliveryMethodAddressObj.dineIn = null;
        deliveryMethodAddressObj.orderPickup = null;
        deliveryMethodAddressObj.selectedAddress = null;
        deliveryMethodAddressObj.selectedDineInTable = null;

        localStorage.setItem(
          "deliveryMethodAddress",
          JSON.stringify(deliveryMethodAddressObj)
        );
      }
    }
  }

  checkValidDeliveryMethod() {
    if (
      (this.showPickupButton &&
        !this.showDeliveryButton &&
        !this.showPickupButton) ||
      (this.showDeliveryButton &&
        !this.showPickupButton &&
        !this.showDineInButton) ||
      (this.showDineInButton &&
        !this.showPickupButton &&
        !this.showDeliveryButton)
    ) {
      this.store
        .select("deliveryMethodAddress")
        .pipe(first())
        .subscribe((state) => {
          const onlyDelivery = !!(
            this.showDeliveryButton &&
            !this.showPickupButton &&
            !this.showDineInButton
          );
          const onlyTakeaway = !!(
            this.showPickupButton &&
            !this.showDeliveryButton &&
            !this.showPickupButton
          );
          const onlyDinein = !!(
            this.showDineInButton &&
            !this.showPickupButton &&
            !this.showDeliveryButton
          );

          if (onlyDelivery && (state.orderPickup || state.dineIn)) {
            this.clearDeliveryMethod();
          } else if (
            (onlyTakeaway &&
              state.orderPickup == false &&
              state.dineIn == false) ||
            (state.orderPickup == false && state.dineIn == true)
          ) {
            this.clearDeliveryMethod();
          } else if (
            onlyDinein &&
            (state.orderPickup ||
              (state.orderPickup == false && state.dineIn == false))
          ) {
            this.clearDeliveryMethod();
          }
        })
        .unsubscribe();
    }
  }

  getAllStores(groupApp) {
    this.dataStorageService.fetchAllStores(groupApp.group).subscribe({
      next: (res: any) => {
        if (res && !_.isEmpty(res)) {
          let stores = _.filter(res, (store) => {
            return !store.storeDeactivated;
          });

          if (
            window &&
            !Capacitor.isNativePlatform() &&
            window.location &&
            _.find(stores, { single_store_subdomain: window.location.hostname })
          ) {
            stores = _.filter(_.cloneDeep(stores), {
              single_store_subdomain: window.location.hostname,
            });
          }
          if (stores && stores.length) {
            if (groupApp && groupApp.images_select_store_mobileorder) {
              let foundStoreWithoutImage;

              _.each(stores, (store) => {
                if (store && !store.select_store_mobileorder_image) {
                  foundStoreWithoutImage = true;
                }
              });

              if (foundStoreWithoutImage) {
                const newGroupApp = _.cloneDeep(groupApp);
                newGroupApp.images_select_store_mobileorder = false;

                this.store.dispatch(
                  new GroupAppActions.SetGroupApp(_.cloneDeep(newGroupApp))
                );
              }
            }

            this.store.dispatch(new StoresActions.SetStores(stores));

            if (this.router && !_.startsWith(this.router.url, "/catalog/")) {
              console.log("inside router url check");
              if (navigator.cookieEnabled) {
                let store_id = window.localStorage.getItem("store_id");
                if (store_id && store_id !== "") {
                  if (_.find(stores, { store_id: store_id })) {
                    console.log("set store id app component");
                    this.store.dispatch(
                      new CurrentStoreActions.SetCurrentStoreId(
                        _.cloneDeep(store_id)
                      )
                    );
                  } else {
                    console.log("set store id app component");
                    this.store.dispatch(
                      new CurrentStoreActions.SetCurrentStoreId(
                        stores[0].store_id
                      )
                    );
                  }
                } else {
                  console.log("set store id app component");
                  this.store.dispatch(
                    new CurrentStoreActions.SetCurrentStoreId(
                      stores[0].store_id
                    )
                  );
                }
              } else {
                console.log("set store id app component");
                this.store.dispatch(
                  new CurrentStoreActions.SetCurrentStoreId(stores[0].store_id)
                );
                console.log(stores);
              }
            }
          } else {
            console.log("no stores open block modal");
            this.openStoreBlockModal(null, null);
          }
        } else {
          this.store.dispatch(
            new RequestsErrorHandlingActions.AddUpdateRequestsErrorHandling({
              id: "stores",
              request: false,
              restartApp: true,
            })
          );
        }
      },
      error: (error) => {
        this.store.dispatch(
          new RequestsErrorHandlingActions.AddUpdateRequestsErrorHandling({
            id: "stores",
            request: false,
            restartApp: true,
          })
        );
        return error;
      },
    });
  }

  setDefaultLanguage(groupApp) {
    console.log("setDefaultLanguage");

    if (
      navigator.cookieEnabled &&
      window.localStorage.getItem("default_language") &&
      this.IsJsonString(window.localStorage.getItem("default_language"))
    ) {
      let lang = JSON.parse(window.localStorage.getItem("default_language"));
      let findLangInGroupApp = _.find(groupApp.languages, { code: lang });
      if (findLangInGroupApp) {
        this.setLanguage(lang);
      } else {
        var mo_lang = _.find(groupApp.languages, {
          mo_base: true,
        });
        console.log("mo_lang", mo_lang);
        this.setLanguage(mo_lang ? mo_lang.code : "el");
      }
    } else {
      var mo_lang = _.find(groupApp.languages, {
        mo_base: true,
      });
      console.log("mo_lang", mo_lang);
      this.setLanguage(mo_lang ? mo_lang.code : "el");
    }
  }

  IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  setLanguage(lang) {
    console.log("set language called", lang);
    this.store.dispatch(new GroupAppActions.SetSelectedLanguage(lang));
    if (navigator.cookieEnabled) {
      localStorage.setItem("default_language", lang);
    }
    this.translateConfigService.setLanguage(lang);
  }

  ordersArraySubscription() {
    this.store
      .select("orders")
      .pipe(auditTime(100))
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.ordersArray &&
          !_.isEqual(this.ordersArray, state.ordersArray)
        ) {
          this.ordersArray = _.cloneDeep(state.ordersArray);
        }
      });
  }

  setDeveloperMode(devMode) {
    this.developerMode = _.cloneDeep(devMode);
    this.store.dispatch(
      new DeveloperModeActions.SetDeveloperMode(_.cloneDeep(devMode))
    );
  }

  windowDimensionsCalc() {
    this.imageSettings = {};
    let windowInnerWidth = window.innerWidth;
    let windowInnerHeight = window.innerHeight;
    let screenRatio = window.innerWidth / window.innerHeight;
    this.imageSettings.innerWidth = windowInnerWidth;
    this.imageSettings.innerHeight = windowInnerHeight;
    this.imageSettings.screenRatio = screenRatio;
    let windowDem = { height: windowInnerHeight, width: windowInnerWidth };
    let menuMinWidthCssVariable =
      document.documentElement.style.getPropertyValue("--menuMinWidthToShow") ||
      "1500";
    console.log("css variable", menuMinWidthCssVariable);
    this.menuMinWidthToDisplay =
      "(min-width: " + menuMinWidthCssVariable + "px)";
    console.log("menu", this.menuMinWidthToDisplay);
    if (windowInnerWidth < parseInt(menuMinWidthCssVariable)) {
      this.sideMenuIsOpen = false;
      this.store.dispatch(
        new WindowInfoActions.SetSideMenuIsOpen(
          _.cloneDeep(this.sideMenuIsOpen)
        )
      );
    } else {
      this.sideMenuIsOpen = true;
      this.store.dispatch(
        new WindowInfoActions.SetSideMenuIsOpen(
          _.cloneDeep(this.sideMenuIsOpen)
        )
      );
    }

    if (this.imageSettings.screenRatio <= 0.68) {
      this.imageSettings.imageRatio = "9x16";
    } else if (
      this.imageSettings.screenRatio > 0.68 &&
      this.imageSettings.screenRatio <= 1.25
    ) {
      this.imageSettings.imageRatio = "1x1";
    } else {
      this.imageSettings.imageRatio = "16x9";
    }

    this.store.dispatch(
      new GroupAppActions.SetImageSettings(_.cloneDeep(this.imageSettings))
    );
    this.store.dispatch(
      new WindowInfoActions.SetWindowDimensions(_.cloneDeep(windowDem))
    );

    if (this.addEventListenerToResizeWindow) {
      window.addEventListener(
        "resize",
        _.debounce(
          () => {
            console.log("resize");
            let menuMinWidthCssVariable =
              document.documentElement.style.getPropertyValue(
                "--menuMinWidthToShow"
              ) || "1500";

            this.menuMinWidthToDisplay =
              "(min-width: " + menuMinWidthCssVariable + "px)";

            if (windowInnerWidth < parseInt(menuMinWidthCssVariable)) {
              this.sideMenuIsOpen = false;
              this.store.dispatch(
                new WindowInfoActions.SetSideMenuIsOpen(
                  _.cloneDeep(this.sideMenuIsOpen)
                )
              );
            } else {
              this.sideMenuIsOpen = true;
              this.store.dispatch(
                new WindowInfoActions.SetSideMenuIsOpen(
                  _.cloneDeep(this.sideMenuIsOpen)
                )
              );
            }
            windowInnerWidth = window.innerWidth;
            windowInnerHeight = window.innerHeight;
            screenRatio = window.innerWidth / window.innerHeight;
            windowDem = { height: windowInnerHeight, width: windowInnerWidth };
            if (windowInnerWidth > parseInt(menuMinWidthCssVariable)) {
              this.sideMenuIsOpen = true;
              this.store.dispatch(
                new WindowInfoActions.SetSideMenuIsOpen(
                  _.cloneDeep(this.sideMenuIsOpen)
                )
              );
            } else {
              this.sideMenuIsOpen = false;
              this.store.dispatch(
                new WindowInfoActions.SetSideMenuIsOpen(
                  _.cloneDeep(this.sideMenuIsOpen)
                )
              );
            }

            this.imageSettings.innerWidth = windowInnerWidth;
            this.imageSettings.innerHeight = windowInnerHeight;
            this.imageSettings.screenRatio = screenRatio;
            let oldImageRatio = this.imageSettings.imageRatio;
            if (this.imageSettings.screenRatio <= 0.68) {
              this.imageSettings.imageRatio = "9x16";
            } else if (
              this.imageSettings.screenRatio > 0.68 &&
              this.imageSettings.screenRatio <= 1.25
            ) {
              this.imageSettings.imageRatio = "1x1";
            } else {
              this.imageSettings.imageRatio = "16x9";
            }
            this.store.dispatch(
              new WindowInfoActions.SetWindowDimensions(_.cloneDeep(windowDem))
            ),
              this.store.dispatch(
                new GroupAppActions.SetImageSettings(
                  _.cloneDeep(this.imageSettings)
                )
              );
            console.log(
              "innerWidth",
              windowInnerWidth,
              "innerHeight",
              windowInnerHeight,
              "screenRation",
              screenRatio
            );
          },
          400,
          { maxWait: 400 }
        )
      );
    }

    console.log(
      "innerWidth",
      windowInnerWidth,
      "innerHeight",
      windowInnerHeight,
      "screenRation",
      screenRatio
    );
  }

  checkBrowserCookies() {
    var cookieEnabled = navigator.cookieEnabled;
    if (!cookieEnabled) {
      document.cookie = "testcookie";
      cookieEnabled = document.cookie.indexOf("testcookie") != -1;
    }
    this.cookiesEnabled = cookieEnabled;

    this.store.dispatch(
      new GroupAppActions.SetCookiesEnabled(this.cookiesEnabled)
    );

    console.log("Cookies", this.cookiesEnabled);
  }

  fetchCustomerPoints() {
    this.dataStorageService
      .fetchCustomerPoint(
        this.mo_token,
        this.groupApp &&
          this.groupApp.points &&
          this.groupApp.points.loyaltyCardLevelsActive
      )
      .subscribe({
        next: (res: any) => {
          if (res && res.success) {
            let userPoints = res.data;
            this.store.dispatch(
              new PointsActions.SetUserPoints(_.cloneDeep(userPoints))
            );
            let tempGroupApp = _.cloneDeep(this.groupApp);

            _.each(tempGroupApp.points.rewards, (reward) => {
              if (reward.points <= userPoints.pointsSystem.active) {
                reward.user_points_percent = 100;
              } else {
                var percent =
                  (userPoints.pointsSystem.active / reward.points) * 100;
                reward.user_points_percent = percent.toFixed();
              }

              console.log("reward", reward.user_points_percent);
            });
            this.store.dispatch(
              new PointsActions.SetRewards(
                _.cloneDeep(tempGroupApp.points.rewards)
              )
            );
            let highestReward = _.maxBy(
              tempGroupApp.points.rewards,
              "user_points_percent"
            );
            this.store.dispatch(
              new PointsActions.SetHighestReward(_.cloneDeep(highestReward))
            );
          } else if (res && !res.success) {
            this.presentErrorToast(res.comments, "danger");
          } else if (!res) {
            this.store.dispatch(
              new RequestsErrorHandlingActions.AddUpdateRequestsErrorHandling({
                id: "points",
                restartApp: false,
                request: false,
              })
            );
          }
        },
        error: (error) => {
          this.store.dispatch(
            new RequestsErrorHandlingActions.AddUpdateRequestsErrorHandling({
              id: "points",
              restartApp: false,
              request: false,
            })
          );
          return error;
        },
      });
  }

  subscribeToCart() {
    this.cartSubscription = this.store
      .select("cart")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (state && !_.isEqual(this.cartProducts, state.products)) {
          this.cartProducts = _.cloneDeep(state.products);
          this.cartProductsNumber = 0;
          _.each(this.cartProducts, (product) => {
            if (
              product.quantity &&
              !product.disable_qty &&
              (!product.enable_quantity_step || !product.quantity_step)
            ) {
              this.cartProductsNumber =
                this.cartProductsNumber + product.quantity;
            } else {
              this.cartProductsNumber = this.cartProductsNumber + 1;
            }
          });
        }
      });
  }

  currentStoreIdSubscription() {
    if (!this.currentStoreIdSub) {
      this.currentStoreIdSub = this.store
        .select("currentStore")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            state.currentStoreId &&
            !_.isEqual(this.currentStoreId, state.currentStoreId)
          ) {
            this.currentStoreId = _.cloneDeep(state.currentStoreId);

            console.log(
              "this groupApp from current store subscription",
              this.groupApp
            );
            let storeChanged = false;
            if (this.stores) {
              if (
                this.currentStore &&
                this.currentStore.store_id &&
                this.currentStore.store_id !== this.currentStoreId
              ) {
                storeChanged = true;
              }
              this.currentStore = _.cloneDeep(
                _.find(this.stores, {
                  store_id: this.currentStoreId,
                })
              );
              if (storeChanged) {
                this.handleCartCustomOptionsOnStoreChanged();
              }

              if (storeChanged) {
                this.store.dispatch(
                  new GroupAppActions.SetCheckStoreOnlineExpiresNow(
                    _.cloneDeep(null)
                  )
                );
                this.store.dispatch(
                  new StoresActions.SetCheckStoreInfo(_.cloneDeep(null))
                );
              }

              this.fetchStaticPages(_.cloneDeep(this.currentStore));
            }

            this.store
              .select("stores")
              .pipe(first())
              .subscribe((storeState) => {
                if (storeState && storeState.stores) {
                  let currentStore = _.find(storeState.stores, {
                    store_id: state.currentStoreId,
                  });

                  this.deliveryMethodAddressSub(currentStore.dineinGpsCheck);

                  if (
                    currentStore &&
                    !currentStore.vatBasedCustomerProductsActive
                  ) {
                    this.clearMigrationCustomerId();
                  }
                }
              })
              .unsubscribe();

            this.store.dispatch(new CatalogActions.SetCatalog(null));
            this.store.dispatch(
              new FavoriteProductsActions.SetFavoriteProducts(null)
            );

            if (this.currentStore && this.currentStore.preorderActive) {
              if (navigator.cookieEnabled) {
                let deliveryMethodAddress: any = localStorage.getItem(
                  "deliveryMethodAddress"
                );
                if (deliveryMethodAddress) {
                  deliveryMethodAddress = JSON.parse(deliveryMethodAddress);
                  if (
                    deliveryMethodAddress.selectedPreorderDate &&
                    deliveryMethodAddress.selectedPreorderDate.date &&
                    deliveryMethodAddress.selectedPreorderDate.hour
                  ) {
                    this.getPreorderDays(
                      this.currentStoreId,
                      this.groupApp,
                      this.selectedLanguage
                    );
                  }
                }
              }
            } else if (this.currentStore && !this.currentStore.preorderActive) {
              this.store
                .select("deliveryMethodAddress")
                .pipe(first())
                .subscribe(async (state) => {
                  if (
                    (state && state.selectedPreorderDate) ||
                    state.preorderResponse
                  ) {
                    this.store.dispatch(
                      new DeliveryMethodAddressActions.setPreorderResponse(null)
                    );
                    this.store.dispatch(
                      new DeliveryMethodAddressActions.setSelectedPreorderDate(
                        null
                      )
                    );
                    if (navigator.cookieEnabled) {
                      let deliveryMethodAddress: any =
                        window.localStorage.getItem("deliveryMethodAddress");

                      if (deliveryMethodAddress) {
                        deliveryMethodAddress = JSON.parse(
                          deliveryMethodAddress
                        );
                        if (
                          deliveryMethodAddress &&
                          deliveryMethodAddress.selectedPreorderDate
                        ) {
                          deliveryMethodAddress.selectedPreorderDate = null;
                          localStorage.setItem(
                            "deliveryMethodAddress",
                            JSON.stringify(deliveryMethodAddress)
                          );
                        }
                      }
                    }
                    const alert = await this.alertController.create({
                      header: this.translateService.instant("alert"),
                      message: this.translateService.instant(
                        "popups.pre-order-popup.store-does-not-have-preorder-enalbed"
                      ),
                      buttons: ["OK"],
                    });

                    await alert.present();
                  }
                })
                .unsubscribe();
            }
            if (this.currentUser && this.mo_token) {
              this.store
                .select("stores")
                .pipe(first())
                .subscribe((storeState) => {
                  if (storeState && storeState.stores) {
                    let currentStore = _.find(storeState.stores, {
                      store_id: state.currentStoreId,
                    });

                    if (
                      currentStore &&
                      currentStore.enable_customer_pricelists_mobileorder &&
                      currentStore.enable_customer_pricelists
                    ) {
                      if (currentStore.vatBasedCustomerProductsActive) {
                        this.store
                          .select("auth")
                          .pipe(first())
                          .subscribe((authState) => {
                            const migrationCustomerId =
                              this.initMigrationCustomerId(
                                this.currentUser,
                                state,
                                this.currentStore,
                                authState
                              );
                            if (
                              (authState && authState.migration_customer_id) ||
                              migrationCustomerId
                            ) {
                              this.fetchUserPricelists(
                                state.currentStoreId,
                                authState.migration_customer_id ||
                                  migrationCustomerId
                              );
                            } else {
                              console.log(
                                "error: no migration customer id found"
                              );
                            }
                          })
                          .unsubscribe();
                      } else {
                        this.fetchUserPricelists(state.currentStoreId, null);
                      }
                    }

                    if (
                      currentStore &&
                      !currentStore.showOnlyFavoritesCatalog &&
                      !currentStore.vatBasedCustomerProductsActive
                    ) {
                      this.fetchCustomerFavoriteProducts(
                        state.currentStoreId,
                        null
                      );
                    } else if (
                      currentStore &&
                      !currentStore.showOnlyFavoritesCatalog &&
                      currentStore.vatBasedCustomerProductsActive
                    ) {
                      this.store
                        .select("auth")
                        .pipe(first())
                        .subscribe((authState) => {
                          if (authState && authState.migration_customer_id) {
                            this.fetchCustomerFavoriteProducts(
                              state.currentStoreId,
                              authState.migration_customer_id
                            );
                          } else {
                            console.log(
                              "error: no migration customer id found"
                            );
                          }
                        })
                        .unsubscribe();
                    }
                  }
                })
                .unsubscribe();

              let orderPickup = null;
              let dineIn = null;
              let preorderData = {};
              let selectedDineInTable;
              let selectedAddress;
              if (navigator.cookieEnabled) {
                if (
                  window.localStorage.getItem("deliveryMethodAddress") &&
                  window.localStorage.getItem("deliveryMethodAddress") !==
                    "{}" &&
                  window.localStorage.getItem("deliveryMethodAddress") !== ""
                ) {
                  console.log(
                    "cookie Enabled and deliveryMethodAddress Object exists"
                  );
                  let deliveryMethodAddress = JSON.parse(
                    window.localStorage.getItem("deliveryMethodAddress")
                  );
                  console.log(
                    "deliveryMethodObjectFromLocalStorage",
                    deliveryMethodAddress
                  );
                  if (
                    deliveryMethodAddress &&
                    deliveryMethodAddress.orderPickup != null
                  ) {
                    console.log(
                      "set order pickup from local storage",
                      deliveryMethodAddress.orderPickup
                    );

                    orderPickup = deliveryMethodAddress.orderPickup;
                  } else {
                    orderPickup = null;
                  }
                  if (
                    deliveryMethodAddress &&
                    deliveryMethodAddress.dineIn != null
                  ) {
                    dineIn = deliveryMethodAddress.dineIn;
                    if (dineIn && deliveryMethodAddress.selectedDineInTable) {
                      selectedDineInTable =
                        deliveryMethodAddress.selectedDineInTable;
                    }
                  } else {
                    dineIn = null;
                  }

                  if (
                    deliveryMethodAddress &&
                    deliveryMethodAddress.selectedPreorderDate &&
                    deliveryMethodAddress.selectedPreorderDate.date &&
                    deliveryMethodAddress.selectedPreorderDate.hour
                  ) {
                    preorderData = {
                      preorder: true,
                      preorder_day:
                        deliveryMethodAddress.selectedPreorderDate.date,
                      preorder_time:
                        deliveryMethodAddress.selectedPreorderDate.hour,
                    };
                  } else {
                    preorderData = null;
                  }

                  if (
                    deliveryMethodAddress &&
                    deliveryMethodAddress.orderPickup === false &&
                    deliveryMethodAddress.dinein === false &&
                    deliveryMethodAddress.selectedAddress
                  ) {
                    selectedAddress = deliveryMethodAddress.selectedAddress;
                  }
                } else {
                  orderPickup = null;
                  dineIn = null;
                  preorderData = null;
                  selectedAddress = null;
                }
                this.store
                  .select("auth")
                  .pipe(first())
                  .subscribe((authState) => {
                    this.fetchCustomerPreviousOrders(
                      state.currentStoreId,
                      orderPickup,
                      dineIn,
                      preorderData,
                      selectedDineInTable ? selectedDineInTable.table_id : null,
                      authState.migration_customer_id
                        ? authState.migration_customer_id
                        : null
                    );
                  })
                  .unsubscribe();
              } else {
                this.store
                  .select("auth")
                  .pipe(first())
                  .subscribe((authState) => {
                    this.fetchCustomerPreviousOrders(
                      state.currentStoreId,
                      orderPickup,
                      dineIn,
                      preorderData,
                      selectedDineInTable ? selectedDineInTable.table_id : null,
                      authState.migration_customer_id
                        ? authState.migration_customer_id
                        : null
                    );
                  })
                  .unsubscribe();
              }
            }

            if (navigator.cookieEnabled) {
              if (
                this.currentStoreId !== window.localStorage.getItem("store_id")
              ) {
                this.clearLocalStorageOnStoreChange();
                window.localStorage.setItem("store_id", this.currentStoreId);
                this.loadFromLocalStorage();
              } else {
                window.localStorage.setItem("store_id", this.currentStoreId);
                this.loadFromLocalStorage();
              }
            } else {
              window.localStorage.setItem("store_id", this.currentStoreId);
              this.loadFromLocalStorage();
            }
          }
        });
    }
  }

  clearMigrationCustomerId() {
    this.store.dispatch(new AuthActions.SetMigrationCustomerId(null));
    if (navigator.cookieEnabled) {
      localStorage.removeItem("migration_customer_id");
    }
  }

  fetchCustomerPreviousOrders(
    currentStoreId,
    orderPickup,
    dineIn,
    preorderData,
    dinein_table_id,
    migration_customer_id
  ) {
    this.dataStorageService
      .fetchCustomerPreviousOrders(
        currentStoreId,
        this.selectedLanguage,
        this.groupApp ? this.groupApp.languages : "",
        this.mo_token,
        orderPickup,
        dineIn,
        preorderData,
        dinein_table_id,
        this.currentStore.vatBasedCustomerProductsActive,
        migration_customer_id
      )

      .subscribe({
        next: (res: any) => {
          if (res && res.success) {
            this.store.dispatch(
              new PreviousOrdersActions.SetPreviousOrders(res.orders)
            );
            this.store.dispatch(
              new PreviousOrdersActions.SetOnPreorderChangeFetchAgain(
                res.fetchInPreorderChanges || null
              )
            );
          } else if (res && !res.success) {
            if (res && res.comment_id) {
              this.presentErrorToast(
                this.translateService.instant(
                  "errorMessages." + res.comment_id
                ),
                "danger"
              );
            } else if (res && res.comments) {
              this.presentErrorToast(
                this.translateService.instant(res.comments),
                "danger"
              );
            }
          } else if (!res) {
            this.store.dispatch(
              new RequestsErrorHandlingActions.AddUpdateRequestsErrorHandling({
                id: "previousOrders",
                restartApp: false,
                request: false,
              })
            );
          }
        },
        error: (error) => {
          this.store.dispatch(
            new RequestsErrorHandlingActions.AddUpdateRequestsErrorHandling({
              id: "previousOrders",
              restartApp: false,
              request: false,
            })
          );
          return error;
        },
      });
  }

  fetchCustomerFavoriteProducts(currentStoreId, migration_customer_id) {
    this.dataStorageService
      .fetchCustomerFavoriteProducts(
        currentStoreId,
        this.mo_token,
        migration_customer_id
      )

      .subscribe({
        next: (res: any) => {
          if (res && res.success) {
            this.store.dispatch(
              new FavoriteProductsActions.SetFavoriteProducts(res.products)
            );
          } else if (res && !res.success) {
            if (res && res.comment_id) {
              this.presentErrorToast(
                this.translateService.instant(
                  "errorMessages." + res.comment_id
                ),
                "danger"
              );
            } else if (res && res.comments) {
              this.presentErrorToast(
                this.translateService.instant(res.comments),
                "danger"
              );
            }
          } else if (!res) {
            this.store.dispatch(
              new RequestsErrorHandlingActions.AddUpdateRequestsErrorHandling({
                id: "favorites",
                restartApp: false,
                request: false,
              })
            );
          }
        },
        error: (error) => {
          this.store.dispatch(
            new RequestsErrorHandlingActions.AddUpdateRequestsErrorHandling({
              id: "favorites",
              restartApp: false,
              request: false,
            })
          );
          return error;
        },
      });
  }

  fetchUserPricelists(store_id, migration_customer_id) {
    if (migration_customer_id) {
      this.dataStorageService
        .getUserPricelist(this.mo_token, store_id, migration_customer_id)
        .subscribe({
          next: (res: any) => {
            this.store.dispatch(
              new CatalogActions.SetCustomerPricelist(res.products)
            );
          },
          error: (err) => {},
        });
    } else {
      this.dataStorageService
        .getUserPricelist(this.mo_token, store_id, null)
        .subscribe({
          next: (res: any) => {
            this.store.dispatch(
              new CatalogActions.SetCustomerPricelist(res.products)
            );
          },
          error: (err) => {},
        });
    }
  }

  clearLocalStorageOnStoreChange() {
    if (navigator.cookieEnabled) {
      window.localStorage.removeItem("cartCustomOptions");
      window.localStorage.removeItem("selectedPaymentMethodID");
      window.localStorage.removeItem("checkboxPromotions");
    }
  }

  checkProductDetailsModalOpening() {
    this.store
      .select("selectedProduct")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          !_.isEqual(
            state.openProductDetailsModalAppComponentProductId,
            this.openProductDetailsModalAppComponentProductId
          )
        ) {
          this.openProductDetailsModalAppComponentProductId = _.cloneDeep(
            state.openProductDetailsModalAppComponentProductId
          );
          if (this.openProductDetailsModalAppComponentProductId) {
            this.router.navigateByUrl("/catalog", {
              replaceUrl: true,
            });
            setTimeout(() => {
              this.showProductDetailsModal({
                product_id: this.openProductDetailsModalAppComponentProductId,
              });

              this.store.dispatch(
                new SelectedProductActions.SetOpenProductDetailsModalFromAppComponent(
                  null
                )
              );
            }, 1000);
          }
        }
      });
  }

  paymentGateWaysSubscription() {
    this.store
      .select("paymentGateWays")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(200))
      .subscribe((state) => {
        if (
          state &&
          state.showPaymentCards &&
          !_.isEqual(state.showPaymentCards, this.showPaymentCards)
        ) {
          this.showPaymentCards = _.cloneDeep(state.showPaymentCards);
        }
        if (
          state &&
          state.showPaymentCardsAdminOnly &&
          !_.isEqual(
            state.showPaymentCardsAdminOnly,
            this.showPaymentCardsAdminOnly
          )
        ) {
          this.showPaymentCardsAdminOnly = _.cloneDeep(
            state.showPaymentCardsAdminOnly
          );
        }

        if (
          state &&
          state.showPaymentCardsCustomerOnly &&
          !_.isEqual(
            state.showPaymentCardsCustomerOnly,
            this.showPaymentCardsCustomerOnly
          )
        ) {
          this.showPaymentCardsCustomerOnly = _.cloneDeep(
            state.showPaymentCardsCustomerOnly
          );
        }
      });
  }

  async checkWebPSupportPromise() {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.onload = function () {
        resolve("resolve");
      };
      img.onerror = function () {
        reject();
      };
      img.src = "http://www.gstatic.com/webp/gallery/1.webp";
    });
  }

  checkWebPSupport() {
    this.checkWebPSupportPromise().then(
      () => {
        this.webP = true;
        this.store.dispatch(
          new GroupAppActions.SetWebp(_.cloneDeep(this.webP))
        );
      },
      () => {
        this.webP = false;
        this.store.dispatch(
          new GroupAppActions.SetWebp(_.cloneDeep(this.webP))
        );
      }
    );
  }

  initializationOfBrowserScripts(groupApp) {
    this.dynamicScriptInitialization(
      "https://cdn.branch.io/branch-2.59.0.min.js",
      "branchIOScript"
    ).onload = () => {
      console.log("branch IO script loaded");
      if (!Capacitor.isNativePlatform()) {
        if (groupApp && groupApp.branch_key && window.branch) {
          window.branch.init(groupApp.branch_key, (err, data) => {
            if (err) console.log(err);

            console.log("Deep Link Data: ", data);
            if (data && typeof data === "object" && data.data_parsed) {
              this.branchIoDataBeforeWritingState = data.data_parsed;
            }
          });
        }
      }
    };

    this.oneSignalBrowserInitScript();

    this.dynamicScriptInitialization(
      "https://cdn.onesignal.com/sdks/OneSignalSDK.js",
      "oneSignalScript"
    ).onload = () => {
      console.log("one signal script loaded");

      this.oneSignalService.initOneSignalBrowser(groupApp);
    };
  }

  setRootDir() {
    let rootDir = "https://images.deliverymanager.gr/";
    this.store.dispatch(new GroupAppActions.SetRootDir(_.cloneDeep(rootDir)));
  }

  async checkPlatformInfoGetGroupApp() {
    console.log("check platform info");
    if (this.platform.is("android")) {
      console.log("check platform info is android");
      this.store.dispatch(new PlatformInfoActions.SetAndroidPlatform(true));
      this.androidPlatform = true;
    }
    if (this.platform.is("ios")) {
      console.log("check platform info is ios");
      this.store.dispatch(new PlatformInfoActions.SetIosPlatform(true));
      this.iOSPlatform = true;
    }
    if (this.platform.is("desktop")) {
      console.log("check platform info is desktop");
      this.store.dispatch(new PlatformInfoActions.SetBrowserPlatform(true));
    }
    if (Capacitor.isNativePlatform()) {
      console.log("check platform not browser");
      const appInfo = await App.getInfo();

      // I am placing this check, because in the ios the above function .isIOS() does not work properly!
      this.versionNumber = appInfo.version;

      this.store.dispatch(
        new PlatformInfoActions.SetVersionNumber(
          _.cloneDeep(this.versionNumber)
        )
      );

      if (Capacitor.getPlatform() === "android") {
        this.store.dispatch(new PlatformInfoActions.SetAndroidPlatform(true));
        this.androidPlatform = true;
      } else if (Capacitor.getPlatform() === "ios") {
        this.store.dispatch(new PlatformInfoActions.SetIosPlatform(true));
        this.iOSPlatform = true;
      }

      this.store
        .select("platformInfo")
        .pipe(first())
        .subscribe((state) => {
          if (state.androidPlatform) {
            this.store.dispatch(
              new PlatformInfoActions.SetAndroidMobilePlatform(true)
            );
          }

          if (state.iOSPlatform) {
            this.store.dispatch(
              new PlatformInfoActions.SetIosMobilePlatform(true)
            );
          }
        })
        .unsubscribe();
    } else {
      this.store.dispatch(new PlatformInfoActions.SetBrowserPlatform(true));
    }
    //Get groupApp and fetch stores, placing this here because we need platrform info first
    this.getGroupFetchingStores();
  }

  fetchGroupTheme(group, disable_app_darkmode) {
    this.dataStorageService.fetchTheme(group).subscribe({
      next: (themeJSON) => {
        if (themeJSON && !_.isEmpty(themeJSON)) {
          this.localStorageDataService.loadThemeJSON(
            themeJSON,
            group,
            disable_app_darkmode
          );
          this.primaryColor = document.documentElement.style.getPropertyValue(
            "--ion-color-primary"
          );

          this.groupAppLoading = false;
          this.groupTheme = true;
        } else {
          this.store.dispatch(
            new RequestsErrorHandlingActions.AddUpdateRequestsErrorHandling({
              id: "groupApp",
              request: false,
              restartApp: true,
            })
          );
        }
      },
      error: (error) => {
        console.log("error", error);
        this.groupAppLoading = false;
        if (
          error &&
          error.status === 404 &&
          error.error &&
          error.error.toString().indexOf("NoSuchKey") !== -1
        ) {
          this.fetchDefaultTheme();
        } else {
          this.store.dispatch(
            new RequestsErrorHandlingActions.AddUpdateRequestsErrorHandling({
              id: "groupApp",
              request: false,
              restartApp: true,
            })
          );
        }
      },
    });
  }

  fetchDefaultTheme() {
    //this is a default theme in case of group theme does not exists in S3
    this.dataStorageService.fetchTheme("amandi").subscribe({
      next: (themeJSON) => {
        if (themeJSON && !_.isEmpty(themeJSON)) {
          this.localStorageDataService.loadThemeJSON(
            themeJSON,
            "amandi",
            false
          );
          this.primaryColor = document.documentElement.style.getPropertyValue(
            "--ion-color-primary"
          );

          this.groupAppLoading = false;
          this.groupTheme = true;
        } else {
          this.store.dispatch(
            new RequestsErrorHandlingActions.AddUpdateRequestsErrorHandling({
              id: "groupApp",
              request: false,
              restartApp: true,
            })
          );
        }
      },
      error: (error) => {
        console.log("error", error);
        this.groupAppLoading = false;
        this.store.dispatch(
          new RequestsErrorHandlingActions.AddUpdateRequestsErrorHandling({
            id: "groupApp",
            request: false,
            restartApp: true,
          })
        );
      },
    });
  }

  authSubscription() {
    this.store
      .select("auth")
      .pipe(auditTime(200))
      .pipe(distinctUntilChanged())
      .subscribe(async (state) => {
        if (
          state &&
          !_.isEqual(state.internetConnectedFlag, this.internetConnectedFlag)
        ) {
          this.internetConnectedFlag = _.cloneDeep(state.internetConnectedFlag);
        }
        if (state && !_.isEqual(this.mo_token, state.mo_token)) {
          this.mo_token = _.cloneDeep(state.mo_token);

          if (this.mo_token) {
            this.getUserDataThrottle();
          }
        }

        if (
          state &&
          state.newCustomer &&
          !_.isEqual(state.newCustomer, this.newCustomer)
        ) {
          this.newCustomer = _.cloneDeep(state.newCustomer);
          console.log("this.newCustomer", this.newCustomer);
          //create one time password
          if (this.newCustomer && this.newCustomer.resend) {
            if (
              this.groupApp &&
              this.groupApp.firebaseVerificationEnabled &&
              this.groupApp.webFirebase &&
              this.groupApp.webFirebase.apiKey &&
              this.firebaseBrowserService.getFireBaseApp() &&
              !Capacitor.isNativePlatform()
            ) {
              this.loginPopupService.closeConfirmationLoginModal();
              this.loginPopupService.presentFirebaseModalLogin(
                "resendModal",
                this.newCustomer.mobile
              );
            } else if (
              this.groupApp &&
              this.groupApp.firebaseVerificationEnabled &&
              Capacitor.isNativePlatform()
            ) {
            }
          }
          if (this.newCustomer && this.newCustomer.createOneTimePass) {
            this.create_one_time_password(this.newCustomer);
            this.presentLoading();
          }
          if (this.newCustomer && this.newCustomer.password) {
            console.log("call mo_socket login");
            this.mo_socket_login(this.newCustomer, false, "login");
            this.presentLoading();
          }
        }

        if (state && !_.isEqual(state.user, this.currentUser)) {
          if (!_.isEmpty(state.user) && !this.currentUser) {
            //remove socket connection in order to reconnect function triggered and handle the connection
            if (this.socket) {
              console.log("socket close() called from auth subscription");
              this.socket.close();
            }
          }
          this.currentUser = _.cloneDeep(state.user);
          if (this.currentUser) {
            //console.log("currentUser-subsription", this.currentUser);
            this.loginPopupService.closeFirebaseModalLogin();
            this.loginPopupService.closeConfirmationLoginModal();
          }
        }

        if (
          state &&
          !_.isEqual(state.logoutFlagTimestamp, this.logoutFlagTimestamp)
        ) {
          this.logoutFlagTimestamp = _.cloneDeep(state.logoutFlagTimestamp);
          if (this.logoutFlagTimestamp) {
            this.store.dispatch(
              new BranchLinkDataActions.ClearBranchLinkData()
            );
            this.logoutErase();
          }
        }

        if (state && !_.isEqual(this.loginLoading, state.loginLoading)) {
          this.loginLoading = _.cloneDeep(state.loginLoading);
        }

        if (
          state &&
          !_.isEqual(this.migration_customer_id, state.migration_customer_id)
        ) {
          this.migration_customer_id = _.cloneDeep(state.migration_customer_id);

          this.store
            .select("deliveryMethodAddress")
            .pipe(first())

            .subscribe((state) => {
              console.log("state address outside if");
              if (state.selectedAddress) {
                console.log(
                  "state address inside",
                  state.selectedAddress.formatted_address
                );
                let selectedAddress = _.cloneDeep(state.selectedAddress);

                this.store.dispatch(
                  new DeliveryMethodAddressActions.setOpenNotValidAddressModal(
                    true
                  )
                );

                this.store.dispatch(
                  new DeliveryMethodAddressActions.setCheckSelectedAddress(
                    _.cloneDeep(selectedAddress)
                  )
                );
              }
            })
            .unsubscribe();
        }

        if (state && !_.isEqual(this.loginState, state.loginState)) {
          this.loginState = _.cloneDeep(state.loginState);
          if (
            this.loginState &&
            (this.loginState === "no_login" ||
              this.loginState === "login_completed") &&
            !this.subscriptionsForCalculateFinalPriceStarted
          ) {
            this.subscriptionsForCalculateFinalPriceStarted = true;
            this.calculateFinalPriceService.calculateFinalPriceSubscriptions();
            setTimeout(() => {
              //this is when we write the branch link data variable to state in order to call handleBranchLinkData function
              this.startHandleBranchLinkData();
            }, 2000);
          }
        }

        if (
          state &&
          state.triggerRenewTokenTimestamp &&
          state.mo_token &&
          state.user &&
          state.loginState &&
          state.loginState === "login_completed" &&
          !_.isEqual(
            this.triggerRenewTokenTimestamp,
            state.triggerRenewTokenTimestamp
          )
        ) {
          if (
            !this.triggerRenewTokenTimestamp ||
            (this.triggerRenewTokenTimestamp &&
              moment(parseInt(this.triggerRenewTokenTimestamp.toString())) &&
              moment(
                parseInt(
                  _.cloneDeep(state.triggerRenewTokenTimestamp).toString()
                )
              ).diff(
                moment(parseInt(this.triggerRenewTokenTimestamp.toString())),
                "minutes"
              )) > 1
          ) {
            this.triggerRenewTokenTimestamp = _.cloneDeep(
              state.triggerRenewTokenTimestamp
            );
            console.log("renew token");
            let message = {
              type: "renew_token",
              languageCode: this.selectedLanguage,
              token: state.mo_token,
            };
            this.analyticsService.dmAnalyticsMessage(
              "trigger_renew_token",
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null
            );
            this.sendSocketMessage(message);
          } else {
            const alert = await this.alertController.create({
              header: this.translateService.instant("alert"),
              message: this.translateService.instant(
                "errorMessages.please-wait-one-minute"
              ),

              buttons: ["OK"],
            });
            await alert.present();
          }
        }
      });
  }

  private handle_user_socket_message(body) {
    console.log("user socket body", body);

    if (body.event === "renew_token") {
      //TODO check for aggelakia tzimis invalid token
      console.log(
        "renew token socket",
        this.coupon_rule_id,
        "or",
        this.affiliate_coupon_code
      );
      if (body.mo_token) {
        this.analyticsService.dmAnalyticsMessage(
          "renew_token",
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null
        );
        this.save_mo_token(body.mo_token);
        if (body && body.orders) {
          if (
            this.store_info &&
            this.store_info.vatBasedCustomerProductsActive &&
            this.currentUser
          ) {
            let orders = _.cloneDeep(body.orders);
            if (
              this.currentUser &&
              this.currentUser.invoiceData &&
              this.currentUser.invoiceData.length
            ) {
              orders = _.filter(_.cloneDeep(orders), (order) => {
                const isCustomerVatOrder = !!_.find(
                  this.currentUser.invoiceData,
                  (invoice) => {
                    if (
                      invoice.migration_customer_id &&
                      invoice.migration_customer_id ===
                        order.migration_customer_id
                    ) {
                      return true;
                    }
                  }
                );

                if (isCustomerVatOrder) {
                  return order;
                }
              });

              if (orders) {
                this.store.dispatch(new AuthActions.SetCustomerOrders(orders));
                this.store.dispatch(
                  new PendingOrdersActions.CheckPendingOrderFromFetch(
                    body.orders
                  )
                );
              }
            }
          } else {
            this.store.dispatch(new AuthActions.SetCustomerOrders(body.orders));
            this.store.dispatch(
              new PendingOrdersActions.CheckPendingOrderFromFetch(body.orders)
            );
          }
        }
        if (body && body.kiosk_orders) {
          this.store.dispatch(
            new AuthActions.SetKioskCustomerOrders(body.kiosk_orders)
          );
        }

        console.log(
          "renew token branch link data coupon rule id",
          this.coupon_rule_id,
          "affiliate",
          this.affiliate_coupon_code
        );

        this.store.dispatch(new AuthActions.SetLoginState("login_completed"));
        this.store
          .select("customerCoupons")
          .pipe(first())
          .subscribe((state) => {
            if (
              state &&
              state.couponIdCheckRequestCompleted &&
              (this.coupon_rule_id || this.affiliate_coupon_code)
            ) {
              const coupon_code = this.coupon_rule_id
                ? this.coupon_rule_id
                : this.affiliate_coupon_code;
              const coupon_type = this.coupon_rule_id
                ? "coupon_rule_id"
                : "affiliate_coupon_code";
              console.log(
                "coupon_rule_id or affiliate_coupon_code exists sending from socket to create it",
                this.coupon_rule_id,
                this.affiliate_coupon_code
              );
              this.store.dispatch(
                new CouponsActions.SetTriggerCouponCodeCheck({
                  timestamp: Date.now(),
                  coupon_code: _.cloneDeep(coupon_code),
                  type: coupon_type,
                })
              );
            }
          })
          .unsubscribe();
      } else {
        console.log("token that was sent was invalid");

        if (this.ionLoading) {
          this.ionLoading.dismiss();
          this.ionLoading = null;
        }
      }
    } else if (body.event === "order") {
      console.log("order socket event", body.order.order_id, body.order.status);

      if (
        this.store_info &&
        this.store_info.vatBasedCustomerProductsActive &&
        this.currentUser
      ) {
        const order = _.cloneDeep(body.order);
        if (
          this.currentUser &&
          this.currentUser.invoiceData &&
          this.currentUser.invoiceData.length
        ) {
          const isCustomerVatOrder = !!_.find(
            this.currentUser.invoiceData,
            (invoice) => {
              if (
                invoice.migration_customer_id &&
                invoice.migration_customer_id === order.migration_customer_id
              ) {
                return true;
              }
            }
          );
          if (isCustomerVatOrder) {
            if (body.order.reservation_id) {
              this.store.dispatch(
                new ReservationActions.UpdateReservationFromOrderSocket(
                  _.cloneDeep(body.order)
                )
              );
            }
            this.store.dispatch(
              new OrdersActions.AddUpdateOrderFromSocket(
                _.cloneDeep(body.order)
              )
            );
            this.handlePaymentPopupsInSocket(body);

            this.store.dispatch(
              new PendingOrdersActions.UpdatePendingOrder(body.order)
            );
          }
        }
      } else {
        if (body.order.reservation_id) {
          this.store.dispatch(
            new ReservationActions.UpdateReservationFromOrderSocket(
              _.cloneDeep(body.order)
            )
          );
        }
        this.store.dispatch(
          new OrdersActions.AddUpdateOrderFromSocket(_.cloneDeep(body.order))
        );
        this.handlePaymentPopupsInSocket(body);

        this.store.dispatch(
          new PendingOrdersActions.UpdatePendingOrder(body.order)
        );
      }
    } else if (body.event === "loyaltyapp-product-updated") {
      console.log("loyaltyapp-product-updated", body.data);
      this.store.dispatch(
        new LoyaltySystemActions.AddUpdateUserLoyaltyProductsFromSocket(
          _.cloneDeep(body.data)
        )
      );
    } else if (body.event === "loyaltyapp-product-removed") {
      console.log("loyaltyapp-product-removed");
      this.store.dispatch(
        new LoyaltySystemActions.RemoveUserLoyaltyProductFromSocket(
          _.cloneDeep(body.data)
        )
      );
    } else if (body.event === "loyaltyapp-ranked-up") {
      console.log("loyaltyapp-ranked-up");
      this.store.dispatch(
        new LoyaltySystemActions.UpdateUserRankFromSocket(
          _.cloneDeep(body.data)
        )
      );
    } else if (body.event === "loyaltyapp-total-products-updated") {
      console.log("loyaltyapp-total-products-updated", body.data);
      this.store.dispatch(
        new LoyaltySystemActions.UpdateUserTotalProductsFromSocket(body.data)
      );
    } else if (body.event === "customer_cards") {
      console.log("customer_cards socket event", body.event, body.cards);
      if (
        this.currentUser &&
        this.currentUser.customer_id === body.customer_id
      ) {
        if (body && body.cards && body.cards.length) {
          _.each(body.cards, (card) => {
            if (card.paymentGateway && card.paymentGateway === "viva") {
              card.invalid = true;
            }
          });
        }
        this.currentUser.cards = body.cards;
        console.log("writing user to state");
        this.store.dispatch(
          new AuthActions.SetUser(_.cloneDeep(this.currentUser))
        );
      }
    } else if (body.event === "coupon-created") {
      console.log("body event for coupon created triggering final price");
      console.log(
        "coupon-created router url",
        this.router.url,
        this.router.url.toString().includes("cart")
      );
      if (this.router.url && this.router.url.toString().includes("cart")) {
        this.store.dispatch(
          new DisableFinalPriceActions.SetTriggerFinalPriceTimestamp(
            Date.now().toString()
          )
        );
      } else if (
        this.router.url &&
        (this.router.url.toString().includes("discount-coupons") ||
          this.router.url.toString().includes("loyaltySystem"))
      ) {
        this.getUserCouponsThrottleFunction();
      } else if (
        this.router.url &&
        this.router.url.toString().includes("affiliate")
      ) {
        console.log("SetTriggerFetchReceiversCoupons");
        this.store.dispatch(
          new AffiliateActions.SetTriggerFetchReceiversCoupons(
            Date.now().toString()
          )
        );
      } else if (
        this.router.url &&
        this.router.url.toString().includes("kiosk")
      ) {
        this.store.dispatch(
          new CustomerCouponsActions.SetFetchCouponsAgainTimestamp(
            Date.now().toString()
          )
        );
      }
    } else if (body.event === "coupon-used") {
      if (
        this.router.url &&
        this.router.url.toString().includes("loyaltySystem")
      ) {
        this.getUserCouponsThrottleFunction();
      }
    } else if (body.event === "check_coupon_rule_id_response") {
      this.store.dispatch(new BranchLinkDataActions.ClearCouponRuleId());
      this.store.dispatch(
        new CouponsActions.SetTriggerCouponCodeCheck({
          timestamp: null,
          coupon_code: null,
          type: "",
        })
      );
      // console.log("check_coupon_rule_id_response", body.comment_id);
    } else if (body.event === "check_affiliate_coupon_code_response") {
      this.store.dispatch(new BranchLinkDataActions.ClearAffiliateCouponCode());
      this.store.dispatch(
        new CouponsActions.SetTriggerCouponCodeCheck({
          timestamp: null,
          coupon_code: null,
          type: "",
        })
      );
    } else if (body.event === "reservation-updated") {
      console.log(body.event, body);
      this.store.dispatch(
        new ReservationActions.UpdateReservationFromSocket(body.data)
      );
    } else if (body.event === "invalid_token") {
      //no_login
      if (this.ionLoading) {
        this.ionLoading.dismiss();
        this.ionLoading = null;
      }
    } else if (body.event === "piraeus_tds_popup") {
      console.log("piraeus_tds_popup");
      this.paymentGateWaysService.closeNavigationToBankModal();
      this.paymentGateWaysService.dismissLoading();
      if (this.ionLoading) {
        this.ionLoading.dismiss();
        this.ionLoading = null;
      }
      if (this.piraeusPopupInfo) {
        this.piraeusPopupInfo.dismiss();
      }
      let frame_width = window.innerWidth > 700 ? 700 : window.innerWidth;
      let frame_height =
        window.innerHeight > 900 ? 850 : window.innerHeight - 100;
      let piraeus_3ds_url = this.trustedUrl(body.tds_url);
      if (!this.piraeus3dsModal) {
        this.piraeus3dsModal = true;
        this.presentPiraeus3dsModal(frame_width, frame_height, piraeus_3ds_url);
      }
    } else if (body.event === "piraeus_acs_popup") {
      this.paymentGateWaysService.closeNavigationToBankModal();
      this.paymentGateWaysService.dismissLoading();
      console.log("piraeus_acs_popup");
      console.log("piraeus acs loading", this.ionLoading);
      if (this.ionLoading) {
        this.ionLoading.dismiss();
        this.ionLoading = null;
        console.log("piraeus acs loading dismissed");
      }
      if (this.piraeusPopupInfo) {
        this.piraeusPopupInfo.dismiss();
      }
      let frame_width = window.innerWidth > 700 ? 700 : window.innerWidth;
      let frame_height =
        window.innerHeight > 900 ? 850 : window.innerHeight - 100;
      let piraeus_3ds_url = this.trustedUrl(body.acs_url);
      if (!this.piraeus3dsModal) {
        this.piraeus3dsModal = true;
        this.presentPiraeus3dsModal(frame_width, frame_height, piraeus_3ds_url);
      }
    } else if (body.event === "piraeus_info_popup") {
      this.paymentGateWaysService.closeNavigationToBankModal();
      this.paymentGateWaysService.dismissLoading();
      console.log("piraeus_info_popup");
      if (this.ionLoading) {
        this.ionLoading.dismiss();
        this.ionLoading = null;
      }
      if (this.piraeusPopupInfo) {
        this.piraeusPopupInfo.dismiss();
      }
      if (this.piraeus3dsModal) {
        this.piraeus3dsModal.dismiss();
      }
      this.presentPiraeusPopupInfo(body);
    } else if (body.event === "navigation_to_bank_modal") {
      this.paymentGateWaysService.closeNavigationToBankModal();
    } else if (
      body &&
      typeof body === "object" &&
      body.statusCode === 200 &&
      body.connectionId
    ) {
      console.log("socket response current_connection_id");
      this.current_connection_id = body.connectionId;
      this.socketHeartBeatTerminateTimeout(); // This is the heartbeat ping request terminate timeout that will be cancelled if we get the response!
    }
  }

  private getUserCouponsThrottleFunction = _.throttle(
    () => {
      this.getUserCoupons();
    },
    5000,
    { leading: true, trailing: false }
  );

  getUserCoupons() {
    this.dataStorageService
      .getCustomerCoupons(this.mo_token, null, null)

      .subscribe({
        next: (res: any) => {
          if (res && res.success) {
            console.log("success", res);
            let allCoupons = res.coupons;
            this.store.dispatch(new CouponsActions.SetAllCoupons(allCoupons));
          } else {
            console.log("error getting coupons");
          }
        },
        error: async (err) => {
          const alert = await this.alertController.create({
            header: this.translateService.instant("alert"),
            message: this.translateService.instant(
              "errorMessages.problem_reaching_server"
            ),

            buttons: ["OK"],
          });
          await alert.present();
          return err;
        },
      });
  }

  private async presentPiraeusPopupInfo(body) {
    this.piraeusPopupInfo = await this.alertController.create({
      header: this.translateService.instant("information"),
      message: this.translateService.instant(
        body.comment_id
          ? this.translateService.instant(body.comment_id)
          : body.comments
      ),

      backdropDismiss: false,
      buttons: ["OK"],
    });
    await this.piraeusPopupInfo.present();
    this.piraeusPopupInfo.onDidDismiss().then(() => {
      this.piraeusPopupInfo = null;
    });
  }

  private async handle_socket_message(body) {
    console.log("mo socket message", body);
    if (body.event === "one_time_password") {
      if (
        body.gateway === "firebase" &&
        body.success &&
        body.send_sms_gateway
      ) {
        if (
          this.groupApp &&
          this.groupApp.firebaseVerificationEnabled &&
          this.groupApp.webFirebase &&
          this.groupApp.webFirebase.apiKey &&
          this.firebaseBrowserService.getFireBaseApp() &&
          !Capacitor.isNativePlatform()
        ) {
          let fireBaseApp = this.firebaseBrowserService.getFireBaseApp();
          const auth = getAuth(fireBaseApp);
          console.log("verifier", window.recaptchaVerifier);

          signInWithPhoneNumber(
            auth,
            body.mobile_firebase,
            window.recaptchaVerifier
          )
            .then((confirmationResult) => {
              window.confirmationResult = confirmationResult;
              console.log("message sent");
              if (this.ionLoading) {
                this.ionLoading.dismiss();
                this.ionLoading = null;
              }
              this.loginPopupService.closeFirebaseModalLogin();
              this.loginPopupService.closeConfirmationLoginModal();
              this.loginPopupService.presentConfiramtionLoginModal(
                _.cloneDeep(this.newCustomer)
              );
            })
            .catch((err) => {
              console.log("there was an error on sent message", err);
              if (err && err.code === "auth/too-many-requests") {
                if (this.ionLoading) {
                  this.ionLoading.dismiss();
                  this.ionLoading = null;
                }
                this.loginPopupService.closeFirebaseModalLogin();

                this.loginPopupService.closeConfirmationLoginModal();

                this.presentErrorToast(
                  this.translateService.instant(
                    "errorMessages.too_many_requests_please_try_again_later"
                  ),
                  "danger"
                );
              }
            });
        } else if (
          this.groupApp &&
          this.groupApp.firebaseVerificationEnabled &&
          Capacitor.isNativePlatform()
        ) {
          if (this.selectedLanguage) {
            try {
              await FirebaseAuthentication.setLanguageCode({
                languageCode: _.cloneDeep(this.selectedLanguage),
              });
            } catch (error) {
              console.log(
                "Use new function UseAppLanguage because of: ",
                error
              );
            }
          }
          console.log("one time password socket message", body);

          window.firebaseVerificationId = null;

          await FirebaseAuthentication.removeAllListeners();
          await FirebaseAuthentication.addListener(
            "phoneCodeSent",
            async (event) => {
              console.log("phoneCodeSent", event);
              if (event && event.verificationId) {
                this.firstTimeSignInWithPhoneNumber = false;
                window.firebaseVerificationId = event.verificationId;
                console.log("SMS sent", window.firebaseVerificationId);
                // this.loginPopupService.closeFirebaseModalLogin();
                // this.loginPopupService.closeConfirmationLoginModal();
                // this.loginPopupService.presentConfiramtionLoginModal(
                //   _.cloneDeep(this.newCustomer)
                // );
              }
              // if (this.ionLoading) {
              //   this.ionLoading.dismiss();
              //   this.ionLoading = null;
              // }
            }
          ).catch((err) => {
            console.error("phoner number verification failed", err);
            this.presentErrorToast(err, "danger");
            if (this.ionLoading) {
              this.ionLoading.dismiss();
              this.ionLoading = null;
            }
          });

          await FirebaseAuthentication.addListener(
            "phoneVerificationFailed",
            async (event) => {
              this.firstTimeSignInWithPhoneNumber = true;
              this.presentErrorToast(event.message, "danger");
              if (this.ionLoading) {
                this.ionLoading.dismiss();
                this.ionLoading = null;
              }
            }
          ).catch((err) => {
            console.error("phoner number verification failed", err);
            this.presentErrorToast(err, "danger");
            if (this.ionLoading) {
              this.ionLoading.dismiss();
              this.ionLoading = null;
            }
          });

          // await FirebaseAuthentication.addListener(
          //   "authStateChange",
          //   (authState) => {
          //     console.log("authState", authState);
          //   }
          // );

          // console.log(
          //   "value of signInWithPhoneNumber",
          //   this.firstTimeSignInWithPhoneNumber
          // );
          await FirebaseAuthentication.signInWithPhoneNumber({
            phoneNumber: body.mobile_firebase,
            resendCode: !this.firstTimeSignInWithPhoneNumber,
          })
            .then((value) => {
              this.loginPopupService.closeFirebaseModalLogin();
              this.loginPopupService.closeConfirmationLoginModal();
              this.loginPopupService.presentConfiramtionLoginModal(
                _.cloneDeep(this.newCustomer)
              );
              if (this.ionLoading) {
                this.ionLoading.dismiss();
                this.ionLoading = null;
              }
            })
            .catch((err) => {
              this.firstTimeSignInWithPhoneNumber = true;
              console.error("phoner number verification failed", err);
              this.presentErrorToast(err, "danger");
              if (this.ionLoading) {
                this.ionLoading.dismiss();
                this.ionLoading = null;
              }
            });
        }
      } else if (
        body.gateway === "firebase" &&
        body.success &&
        !body.send_sms_gateway
      ) {
        if (this.ionLoading) {
          this.ionLoading.dismiss();
          this.ionLoading = null;
        }
        this.loginPopupService.closeFirebaseModalLogin();
        this.loginPopupService.closeConfirmationLoginModal();
        this.loginPopupService.presentConfiramtionLoginModal(
          _.cloneDeep(this.newCustomer)
        );
      } else if (
        body.gateway === "sms" &&
        body.success &&
        !body.send_sms_gateway
      ) {
        console.log("sent with apifon");
        window.firebaseVerificationId = null;
        window.confirmationResult = null;
        if (this.ionLoading) {
          this.ionLoading.dismiss();
          this.ionLoading = null;
        }
        this.loginPopupService.closeFirebaseModalLogin();
        this.loginPopupService.closeConfirmationLoginModal();
        this.loginPopupService.presentConfiramtionLoginModal(
          _.cloneDeep(this.newCustomer)
        );
      }

      if (body.comment_id || body.comments) {
        if (this.ionLoading) {
          this.ionLoading.dismiss();
          this.ionLoading = null;
        }
        if (body.comment_id) {
          if (body.comment_id === "customer_deleted_account") {
            //present deleted account info modal
            this.presentDeletedAccountInfoModal(this.newCustomer.mobile);
          } else {
            let errorMsg = this.translateService.instant(
              "errorMessages." + body.comment_id
            );
            this.presentErrorToast(errorMsg, "danger");
          }
        }

        if (!body.comment_id) {
          this.presentErrorToast(body.comments, "danger");
        }
      }
    } else if (body.event === "login") {
      //This means that we just made a login!

      this.store.dispatch(new BranchLinkDataActions.ClearBranchLinkData());
      this.save_mo_token(body.mo_token);
    } else if (body.event === "login_response") {
      console.log("login_repsonse");
      this.store.dispatch(new BranchLinkDataActions.ClearBranchLinkData());
      if (!body.validPassword && !body.auto_login) {
        if (this.ionLoading) {
          this.ionLoading.dismiss();
          this.ionLoading = null;
        }
        this.presentErrorToast("Λανθασμένος ή ληγμένος κωδικός!", "danger");
      } else if (body.validPassword) {
        //No loading hide here, so that it can continue with the other socket connection mouser
        if (body.mo_token && !body.auto_login) {
          if (
            this.router &&
            this.router.url &&
            (_.includes(this.router.url, "homepage") ||
              _.includes(this.router.url, "loyalty-home"))
          ) {
            this.store.dispatch(new AuthActions.SetFreshLogin(true));
          }
        }

        this.save_mo_token(body.mo_token);
      } else {
        if (this.ionLoading) {
          this.ionLoading.dismiss();
          this.ionLoading = null;
        }
      }
    } else if (
      body.event === "scanned_order_response" ||
      body.event === "scanned_order_already_customer"
    ) {
      this.store.dispatch(new BranchLinkDataActions.ClearBranchLinkData());

      if (this.ionLoading) {
        this.ionLoading.dismiss();
        this.ionLoading = null;
      }
      console.log("scan response", body.event);

      let scan_user = body.address;
      scan_user.isCustomer = body.event === "scanned_order_already_customer";
      scan_user.mobile = body.mobile;

      //The mobile and password came here, so I can make the login!
      if (body.success && scan_user) {
        let scan_title = scan_user.isCustomer
          ? this.groupApp.scan_login_promo_text
          : this.groupApp.scan_login_promo_text_register ||
            this.groupApp.scan_login_promo_text;

        if (body.coupon_rule_promo_message) {
          scan_title = body.coupon_rule_promo_message;
        }

        //I will show a popup with all the data and I will say create account
        //Then if the user presses ok create account then I will send login with the address data to be updated or created for the customer
        this.presentScanLoginModal(
          scan_user,
          scan_title,
          scan_user.isCustomer,
          body
        );
      } else if (body.success) {
        this.presentLoading();

        this.mo_socket_login(
          {
            mobile: body.mobile,
            password: body.password,
          },
          true,
          "login"
        );
      }
    } else if (
      body &&
      typeof body === "object" &&
      body.statusCode === 200 &&
      body.connectionId
    ) {
      console.log("socket response current_connection_id");
      this.current_connection_id = body.connectionId;
      this.socketHeartBeatTerminateTimeout(); // This is the heartbeat ping request terminate timeout that will be cancelled if we get the response!
    }
  }

  async presentDeletedAccountInfoModal(mobile) {
    const modal = await this.modalCtrl.create({
      component: DeletedUserInfoModalComponent,
      cssClass: "deleted-user-info-modal",
      backdropDismiss: false,
      componentProps: {
        mobile: mobile,
        group: this.groupApp.group,
      },
    });
    await modal.present();
  }

  async presentScanLoginModal(scan_user, scan_title, is_customer, body) {
    this.scanLoginModal = await this.modalCtrl.create({
      component: ScanLoginModalComponent,
      id: "scanLoginModal",
      cssClass:
        !scan_title ||
        (scan_title && !scan_user.firstName && !scan_user.lastName)
          ? "scanLoginModalSmall"
          : "scanLoginModal",
      backdropDismiss: false,
      componentProps: {
        scan_user: _.cloneDeep(scan_user),
        scan_title: _.cloneDeep(scan_title),
        isCustomer: _.cloneDeep(is_customer),
      },
    });
    await this.scanLoginModal.present();
    this.scanLoginModal
      .onDidDismiss()
      .then((data) => {
        if (data && data.data) {
          console.log(data);
          this.presentLoading();

          this.mo_socket_login(
            {
              mobile: body.mobile,
              password: body.password,
              store_id: body.store_id, //used only for stats
              data_id: body.data_id, //used only for stats
              address: null,
            },
            true,
            "login"
          );
        }
      })
      .catch(() => {});
  }

  private connectMoSocket(loginData) {
    console.log("clear socket called");
    this.clearSocket();
    console.log("connect mo socket");
    this.socket = new WebSocket(
      "wss:/mosocket.deliverymanager.gr/" +
        (this.developerMode ? "dev" : "prod")
    );
    this.socket.onopen = (openEvent) => {
      //footprint
      this.socketListenersEventsFootprint("on_open_mo_socket", null);

      console.log("mosocket WebSocket OPEN: " + JSON.stringify(openEvent));
      this.sendSocketMessage(
        {
          type: "user-test-connection", // This tests the connection of the socket using a mock socket api!
        },
        3
      );
      this.reconnectTimeoutRetries = 0;
      this.clearReconnectTimeout();
      this.checkSocketAndShowToast(true);
    };

    this.socket.onclose = async (closeEvent) => {
      console.log("mosocket WebSocket CLOSE: ", closeEvent);
      // Make new connection as it just closed as 10 minutes passed on idle!
      //footprint
      this.socketListenersEventsFootprint(
        "on_close_mo_socket",
        closeEvent && closeEvent.code ? closeEvent.code : null
      );

      this.current_connection_id = null;
      this.checkSocketAndShowToast(false);
      this.reconnectDebounced();
    };

    this.socket.onerror = (errorEvent) => {
      this.socketListenersEventsFootprint("on_error_mo_socket", null);

      console.log("mosocket WebSocket ERROR: ", errorEvent);
    };

    this.socket.onmessage = (message) => {
      let body = JSON.parse(message.data);

      console.log("socket body", body);
      this.handle_socket_message(body);
    };
  }

  private createToast = async (message_id) => {
    return await this.toastController.create({
      header: this.translateService.instant("alert"),
      message: this.translateService.instant(message_id),
      color: "danger",
      position: "bottom",
      buttons: [
        {
          text: "OK",
          role: "cancel",
          handler: () => {
            console.log("Cancel clicked");
          },
        },
      ],
    });
  };

  private showNoInternetToastMessage = async () => {
    clearTimeout(this.noInternetToastTimeout);
    this.noInternetToastTimeout = setTimeout(async () => {
      this.toastSocket = await this.createToast(
        "errorMessages.no_internet_connection"
      );
      if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
        // We need this extra check in case we are in an edge case!
        this.toastSocket.present();
      }
    }, 6000); // We need this timeout in order to have time in case of quick reconnect!
  };

  private checkSocketAndShowToast = async (hide) => {
    if (this.internetConnectedFlag && !hide) {
      this.showNoInternetToastMessage();
    } else if (hide) {
      clearTimeout(this.noInternetToastTimeout);
      // This timeout exists so that in case we have a very fast disconnect and reconnect then we need to instantly cancel the toast appearence
      // But it has managed to appear then we dimiss it.
      if (this.toastSocket) {
        this.toastSocket.dismiss();
      }
    }
    this.internetConnectedFlag = hide;
    this.store.dispatch(new AuthActions.SetInternetConnectedFlag(hide)); // This variable in the state causes the no-internet component to appear instantly, when the socket closes, until it is open again after 5 seconds.
  };

  private createUserSocketConnection(renew_token) {
    if (this.mo_token) {
      console.log("clear socket called");
      this.clearSocket();
      this.socket = new WebSocket(
        "wss:/mousers.deliverymanager.gr/" +
          (this.developerMode ? "dev" : "prod") +
          '?Authorizer="' +
          this.mo_token +
          '"'
      );

      this.socket.onopen = (openEvent) => {
        //footprint
        this.socketListenersEventsFootprint("on_open_user_socket", null);

        console.log("mousers WebSocket OPEN: ", openEvent);
        this.sendSocketMessage(
          {
            type: "user-test-connection", // This tests the connection of the socket using a mock socket api!
          },
          3
        );

        this.reconnectTimeoutRetries = 0;
        this.clearReconnectTimeout();
        this.checkSocketAndShowToast(true);
        this.store
          .select("customerCoupons")
          .pipe(first())
          .subscribe((state) => {
            if (
              state &&
              state.couponIdCheckRequestCompleted &&
              this.triggerCouponCodeCheckObject &&
              this.triggerCouponCodeCheckObject.coupon_code
            ) {
              console.log(
                "coupon_rule_id/affiliate_coupon exists sending from socket to create it",

                this.triggerCouponCodeCheckObject.coupon_code
              );
              if (this.triggerCouponCodeCheckObject.type === "coupon_rule_id") {
                this.sendSocketCouponRuleId(
                  this.triggerCouponCodeCheckObject.coupon_code
                );
              } else if (
                this.triggerCouponCodeCheckObject.type ===
                "affiliate_coupon_code"
              ) {
                this.sendSocketAffiliateCouponCode(
                  this.triggerCouponCodeCheckObject.coupon_code
                );
              } else if (
                this.triggerCouponCodeCheckObject.type === "partner_coupon_id"
              ) {
                this.sendSocketPartnerCouponId(
                  this.triggerCouponCodeCheckObject.coupon_code
                );
              }
            }
          })
          .unsubscribe();

        if (renew_token) {
          //I need to renew the token and then get_user_data
          console.log("renew token");
          let message = {
            type: "renew_token",
            languageCode: this.selectedLanguage,
            token: this.mo_token,
          };
          this.analyticsService.dmAnalyticsMessage(
            "trigger_renew_token",
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null
          );
          this.sendSocketMessage(message);
        } else {
          console.log("no renew as this is a fresh login!");
          this.presentLoading();
          this.getUserDataThrottle();
        }
      };
      this.socket.onclose = async (closeEvent) => {
        console.log("WebSocket CLOSE: ", closeEvent);
        //Make new connection as it just closed as 10 minutes passed on idle!
        //footprint
        this.socketListenersEventsFootprint(
          "on_close_user_socket",
          closeEvent && closeEvent.code ? closeEvent.code : null
        );

        this.current_connection_id = null;
        this.checkSocketAndShowToast(false);
        this.reconnectDebounced();
      };
      this.socket.onerror = (errorEvent) => {
        this.socketListenersEventsFootprint("on_error_user_socket", null);

        console.log("mousers WebSocket ERROR: ", errorEvent);
      };
      this.socket.onmessage = (message) => {
        console.log("mouser messageEvent", message);
        var body = JSON.parse(message.data);
        console.log("body", body);
        this.handle_user_socket_message(body);
      };
    } else {
      console.log("trying to create user socket connection but no token found");
    }
  }

  private current_connection_id;
  private noInternetToastTimeout;
  private pingTimeout;
  private reconnectTimeout;
  private clearReconnectTimeout = () => {
    // This needs to be in a separate function
    console.log("clearReconnectTimeout");
    clearTimeout(this.reconnectTimeout);
  };
  private clearPingTimeout = () => {
    // This needs to be in a separate function
    console.log("clearPingTimeout");
    clearTimeout(this.pingTimeout);
  };

  private socketHeartBeatTerminateTimeout = () => {
    // Here every time this function is called I will first clear the previous timeout and then call it again until I receive the ping response
    this.clearPingTimeout();

    this.pingTimeout = setTimeout(() => {
      //footprint
      this.analyticsService.dmAnalyticsMessage(
        "heart_beat_timeout_called",
        this.socket && this.socket.readyState
          ? this.socket.readyState
          : "readyStateIsNull",
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      );

      if (this.socket) {
        console.log("Previous socket terminated from heartbeat timeout!");
        //remove socket connection in order reconnect function triggered and handles the connection
        this.socket.close();
        //footprint
        // this.analyticsService.dmAnalyticsMessage(
        //   "after_socket_close",
        //   (this.socket && this.socket.readyState
        //     ? this.socket.readyState
        //     : "readyStateIsNull") +
        //     (this.socket && this.socket.url ? this.socket.url : " UrlIsNull"),
        //   null,
        //   null,
        //   null,
        //   null,
        //   null,
        //   null,
        //   null,
        //   null,
        //   null
        // );
      }
    }, 59500); // This is based on the interval value of pings plus a latency for the requests
  };
  private reconnectTimeoutRetries = 0;

  private reconnectDebounced = _.debounce(() => {
    this.reconnect();
  }, 1000);
  private reconnect = () => {
    console.log("reconnect", this.reconnectTimeoutRetries);
    this.clearPingTimeout();
    this.clearReconnectTimeout();
    let time_for_timeout = 0;
    if (this.reconnectTimeoutRetries >= 5) {
      time_for_timeout = 5000;
    } else {
      time_for_timeout = 1000 * this.reconnectTimeoutRetries;
    }
    this.reconnectTimeoutRetries++;
    this.reconnectTimeout = setTimeout(() => {
      // With the following I think I do not permit an infinite loop
      //footprint
      this.analyticsService.dmAnalyticsMessage(
        "reconnect_socket_called",
        this.socket && this.socket.readyState
          ? this.socket.readyState
          : "readyStateIsNull",
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      );
      if (this.socket) {
        console.log(
          "this socket reconnect",
          this.socket.readyState,
          this.socket.url,
          this.mo_token
        );
      }

      if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
        console.log("Calling connectSocket from inside the close event");
        // eslint-disable-next-line no-use-before-define
        if (this.mo_token) {
          this.createUserSocketConnection(true);
        } else {
          this.connectMoSocket(null);
        }
      }
    }, time_for_timeout);
  };

  private clearSocket() {
    //footprint

    // this.analyticsService.dmAnalyticsMessage(
    //   "disconnect_socket_called",
    //   null,
    //   null,
    //   null,
    //   null,
    //   null,
    //   null,
    //   null,
    //   null,
    //   null,
    //   null
    // );
    if (this.socket) {
      this.socket.close();

      this.socket.removeEventListener("onclose", () => {
        console.log("onclose event removed!");
      });
      this.socket.removeEventListener("onerror", () => {
        console.log("onerror event removed!");
      });
      this.socket.removeEventListener("onmessage", () => {
        console.log("onmessage event removed!");
      });

      //footprint
      // this.analyticsService.dmAnalyticsMessage(
      //   "after_socket_close",
      //   (this.socket && this.socket.readyState
      //     ? this.socket.readyState
      //     : "readyStateIsNull") +
      //     (this.socket && this.socket.url ? this.socket.url : " UrlIsNull"),
      //   null,
      //   null,
      //   null,
      //   null,
      //   null,
      //   null,
      //   null,
      //   null,
      //   null
      // );

      this.socket = null;
      //footprint
    }
  }

  private sendSocketMessage = async (message, tries = 0) => {
    try {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        //footprint
        if (message && message.type === "user-test-connection") {
          // this.sendSocketMessageFootprint(
          //   "send_socket_message_test_user_connection"
          // );
        }

        this.socket.send(JSON.stringify(message));
      } else {
        if (message && message.type === "user-test-connection") {
          this.sendFootprint(
            "error_send_socket_message_test_user_connection",
            this.socket && this.socket.readyState
              ? this.socket.readyState
              : "readyStateIsNull"
          );
        }

        if (
          this.socket &&
          (this.socket.readyState === WebSocket.CLOSING ||
            this.socket.readyState === WebSocket.CLOSED) &&
          this.reconnectTimeoutRetries === 0
        ) {
          console.log("clear socket called");
          this.clearSocket();
          this.current_connection_id = null;
          this.checkSocketAndShowToast(false);
          this.reconnectDebounced();
        }
        throw new Error("socket_not_opened_yet");
      }
    } catch (err) {
      if (tries < 3) {
        if (message && message.type === "user-test-connection") {
          this.sendSocketMessageFootprint(
            "retry_send_socket_message_test_user_connection"
          );
        }

        await this.timeout(2000);
        await this.sendSocketMessage(message, tries + 1);
      }
    }
  };

  sendFootprint(action_id, comment_id) {
    this.analyticsService.dmAnalyticsMessage(
      action_id,
      comment_id,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
  }

  socketListenersEventsFootprint(action_id, comment_id) {
    this.analyticsService.dmAnalyticsMessage(
      action_id,
      comment_id,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
  }

  sendSocketMessageFootprint(action_id) {
    this.analyticsService.dmAnalyticsMessage(
      action_id,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
  }

  save_mo_token(mo_token) {
    console.log("saving mo_token");
    if (mo_token && mo_token !== "null") {
      if (navigator.cookieEnabled) {
        window.localStorage.setItem("mo_token", mo_token);
      }
      // console.log("mo_token", this.mo_token);
      this.store.dispatch(new AuthActions.SetMoToken(_.cloneDeep(mo_token)));
    }
  }

  mo_socket_login(obj, auto_login, type) {
    console.log("mo_socket_login", obj);
    console.log("type", type);

    if (!obj.password) {
      //return Toast.show("Παρακαλώ εισάγετε τον κωδικό που λάβατε στο μήνυμα!");
      //TO_DO PRESENT TOAST ERROR
    }

    var branch_link_data = {
      type: type,
      languageCode: this.selectedLanguage,
      group: this.groupApp.group,
      store_id: obj.store_id,
      mobile: obj.mobile,
      data_id: obj.data_id,
      password: obj.password,
      address: obj.address,
      firebase_id_token: obj.firebase_id_token,
      auto_login: auto_login,
    };

    console.log("$rootScope.branch_link_data", branch_link_data, this.socket);
    if (
      this.socket &&
      this.socket.readyState === WebSocket.OPEN &&
      this.socket.url.indexOf("mosocket") >= 0
    ) {
      console.log("We are connected to ", this.socket.url);
      this.sendSocketMessage(branch_link_data);
      if (this.newCustomer) {
        let newCust = _.cloneDeep(this.newCustomer);
        newCust.password = null;
        this.store.dispatch(new AuthActions.SetNewCustomer(newCust));
      }
    } else if (
      this.socket &&
      this.socket.readyState === WebSocket.OPEN &&
      this.socket.url.indexOf("mousers") >= 0
    ) {
      console.log("WE DO NOTHING AS We are connected to ", this.socket.url);

      if (type === "scanned_order") {
        this.socket.send(
          JSON.stringify({
            type: "scanned_order_check_coupon",
            data_id: branch_link_data.data_id,
          })
        );
      } else if (type === "login" && obj.coupon_rule_id) {
        this.socket.send(
          JSON.stringify({
            type: "login_check_coupon",
            coupon_rule_id: obj.coupon_rule_id,
            data_id: branch_link_data.data_id,
          })
        );
      } else {
        console.log("no connection to any socket yet! no problem");
      }
    }
  }

  create_one_time_password(user) {
    if (this.groupApp && user.mobile) {
      console.warn("sending mobile to SOCKET !!!!!!!!!");

      if (this.groupApp && this.newCustomer.mobile) {
        let messageForSocket = {
          type: "create_one_time_password",
          gateway: "sms",
          firebaseVerificationEnabled: true,
          languageCode: this.selectedLanguage,
          group: this.groupApp.group.toString(),
          mobile: user.mobile,
          email: user.email,
          coupon_rule_id: "", //TO_DO CHECK THIS ON WHAT TO SEND
          platform: Capacitor.isNativePlatform()
            ? Capacitor.getPlatform()
            : "browser",
          device: window.device ? window.device.model : "",
          hybridSmsVerificationEnabled: true,
          // gsmSmsVerificationEnabled: true,
        };
        let newCust = _.cloneDeep(this.newCustomer);
        newCust.createOneTimePass = false;
        newCust.password = null;
        this.store.dispatch(new AuthActions.SetNewCustomer(newCust));
        this.sendSocketMessage(messageForSocket);
      }
    }
  }

  private getUserDataThrottle = _.throttle(
    () => {
      this.getUserData();
    },
    5000,
    { leading: true, trailing: false }
  );

  getUserData() {
    console.log("getUserData Called", this.mo_token);
    this.dataStorageService
      .get_user_data(
        this.mo_token,
        this.groupApp.active_loyalty_app,
        this.groupApp.contestSystemEnabled
      )
      .subscribe({
        next: async (res: any) => {
          if (res && res.success) {
            let currentUser = res.user;
            if (
              !currentUser.invoiceData ||
              !_.isArray(currentUser.invoiceData)
            ) {
              currentUser.invoiceData = [];
            }

            if (currentUser && currentUser.cards && currentUser.cards.length) {
              _.each(currentUser.cards, (card) => {
                if (card.paymentGateway && card.paymentGateway === "viva") {
                  card.invalid = true;
                }
              });
            }
            console.log("writing user to state", currentUser);

            this.store.dispatch(
              new AuthActions.SetUser(_.cloneDeep(currentUser))
            );

            if (this.cashoutRewardBranchIONavigateToPoints) {
              this.cashoutRewardBranchIONavigateToPoints = false;
              this.router.navigateByUrl("/points", {
                replaceUrl: true,
              });
            }

            if (res.loyaltyapp) {
              let loyaltyapp = res.loyaltyapp;
              this.store.dispatch(
                new LoyaltySystemActions.SetUserLoyaltyProducts(
                  loyaltyapp.products
                )
              );
              if (loyaltyapp.total_products && loyaltyapp.rank) {
                let ranking = {
                  total_products: loyaltyapp.total_products,
                  rank: loyaltyapp.rank,
                };
                this.store.dispatch(
                  new LoyaltySystemActions.SetUserRanking(ranking)
                );
              }
            }

            if (res.activeDrawsNumber) {
              this.activeDrawsNumber = res.activeDrawsNumber;
            } else {
              this.activeDrawsNumber = null;
            }

            //console.log("group App from get user", this.groupApp);
            if (
              currentUser &&
              (this.coupon_rule_id || this.affiliate_coupon_code)
            ) {
              let coupon_code = this.coupon_rule_id
                ? _.cloneDeep(this.coupon_rule_id)
                : _.cloneDeep(this.affiliate_coupon_code);
              console.log(
                "callMoCheckCouponRuleIdFromGetUserData",
                this.coupon_rule_id,
                coupon_code
              );
              let obj;
              if (this.coupon_rule_id) {
                obj = {
                  coupon_rule_id: coupon_code,
                  group: this.groupApp.group,
                  mobile: currentUser.mobile,
                };
              } else if (this.affiliate_coupon_code) {
                obj = {
                  affiliate_coupon_code: coupon_code,
                  group: this.groupApp.group,
                  mobile: currentUser.mobile,
                };
              }
              this.moCheckCouponCodeThrottle(obj);
            }

            //initialize addresses and pricelists and favorites for user
            this.checkingUserAddresses(currentUser);
            this.checkUserPricelistsAndFavorites(currentUser);

            if (this.groupApp && this.groupApp.pointsSystem) {
              this.fetchCustomerPoints();
              console.log("fetching Customer Points");
            }

            if (
              ((currentUser && !currentUser.ratingAppDialogShownTimestamp) ||
                (
                  currentUser.ratingAppDialogShownTimestamp &&
                  moment(
                    parseInt(
                      currentUser.ratingAppDialogShownTimestamp.toString()
                    )
                  )
                ).isBefore(moment().subtract(1, "years"))) &&
              Capacitor.isNativePlatform()
            ) {
              this.fetchCustomerReviews();
            }

            if (this.ionLoading) {
              this.ionLoading.dismiss();
              this.ionLoading = null;
            }

            this.saveRegId(
              currentUser,
              this.mo_token,
              this.groupApp,
              this.versionNumber
            );

            this.store.dispatch(new AuthActions.SetLoginLoading("not-loading"));
          } else {
            if (res && res.comment_id) {
              this.presentErrorToast(
                this.translateService.instant(
                  "errorMessages." + res.comment_id
                ),
                "danger"
              );
            } else if (res && res.comments) {
              this.presentErrorToast(
                this.translateService.instant(res.comments),
                "danger"
              );
            }
          }

          this.store.dispatch(new AuthActions.SetLoginState("login_completed"));
          if (this.ionLoading) {
            this.ionLoading.dismiss();
            this.ionLoading = null;
          }

          this.store.dispatch(new AuthActions.SetLoginLoading("not-loading"));
        },
        error: (errorResponse) => {
          console.log("error getting user data", errorResponse);
          if (
            errorResponse.status === 401 &&
            errorResponse.statusText === "Unauthorized"
          ) {
            this.logoutErase();
            this.currentUser = null;
            this.mo_token = null;
            this.store.dispatch(new AuthActions.SetLoginState("no_login"));
            if (
              (this.coupon_rule_id || this.affiliate_coupon_code) &&
              this.branch_link_data &&
              this.branchLinkEvent === "promo_link"
            ) {
              this.branchLinkEvent = null;
              this.handleBranchLinkData(_.cloneDeep(this.branch_link_data));
            }
            if (
              this.branchLinkEvent === "scanned_order" &&
              this.branch_link_data
            ) {
              this.branchLinkEvent = null;
              this.handleBranchLinkData(_.cloneDeep(this.branch_link_data));
            } else if (
              this.branchLinkEvent === "login" &&
              this.branch_link_data
            ) {
              this.branchLinkEvent = null;
              this.handleBranchLinkData(_.cloneDeep(this.branch_link_data));
            }
            console.log(errorResponse);
            return errorResponse;
          }
        },
      });
  }

  initMigrationCustomerId(
    currentUser,
    currentStorestate,
    currentStore,
    authState
  ) {
    let invoicesWithMigrationCustomerId;
    if (currentUser && currentUser.invoiceData) {
      invoicesWithMigrationCustomerId = _.filter(
        currentUser.invoiceData,
        (invoice) => {
          if (invoice.migration_customer_id) {
            return invoice;
          }
        }
      );
    }
    let migration_customer_id;

    if (
      invoicesWithMigrationCustomerId &&
      invoicesWithMigrationCustomerId.length
    ) {
      this.store.dispatch(
        new AuthActions.SetMigrationCustomerId(
          invoicesWithMigrationCustomerId[0].migration_customer_id
        )
      );
      migration_customer_id = _.cloneDeep(
        invoicesWithMigrationCustomerId[0].migration_customer_id
      );
      const selectedInvoice = invoicesWithMigrationCustomerId[0];

      if (selectedInvoice && !_.isEmpty(selectedInvoice)) {
        selectedInvoice.selected = true;
      }

      this.store.dispatch(
        new SelectedInvoiceActions.SetSelectedInvoice(
          _.cloneDeep(selectedInvoice)
        )
      );
      if (navigator.cookieEnabled) {
        localStorage.setItem(
          "migration_customer_id",
          invoicesWithMigrationCustomerId[0].migration_customer_id
        );
      }
      this.fetchCustomerFavoriteProducts(
        currentStorestate.currentStoreId,
        invoicesWithMigrationCustomerId[0].migration_customer_id
      );
    } else {
      console.log("error: there are no invoices with migration customer id");
    }

    if (
      currentStore &&
      currentStore.enable_customer_pricelists_mobileorder &&
      currentStore.enable_customer_pricelists
    ) {
      this.fetchUserPricelists(currentStore.store_id, migration_customer_id);
    } else {
      this.store.dispatch(new CatalogActions.SetCustomerPricelist([]));
    }

    return invoicesWithMigrationCustomerId &&
      invoicesWithMigrationCustomerId.length &&
      invoicesWithMigrationCustomerId[0].migration_customer_id
      ? invoicesWithMigrationCustomerId[0].migration_customer_id
      : null;
  }

  initOnlyFavoritesCatalog(currentStore, currentStorestate, authState) {
    if (currentStore && !currentStore.showOnlyFavoritesCatalog) {
      this.fetchCustomerFavoriteProducts(
        currentStorestate.currentStoreId,
        authState.migration_customer_id
      );
    }

    if (
      currentStore &&
      currentStore.enable_customer_pricelists_mobileorder &&
      currentStore.enable_customer_pricelists
    ) {
      this.fetchUserPricelists(
        currentStore.store_id,
        authState.migration_customer_id
      );
    } else {
      this.store.dispatch(new CatalogActions.SetCustomerPricelist([]));
    }
  }

  checkUserPricelistsAndFavorites(currentUser) {
    this.store
      .select("currentStore")
      .pipe(first())
      .subscribe((currentStorestate) => {
        if (currentStorestate && currentStorestate.currentStoreId) {
          if (currentUser && this.mo_token) {
            console.log(
              "before fetch",
              this.currentStoreId,
              this.currentStore.enable_customer_pricelists_mobileorder,
              this.currentStore.enable_customer_pricelists
            );
            this.store
              .select("stores")
              .pipe(first())
              .subscribe((storeState) => {
                if (storeState && storeState.stores) {
                  let currentStore = _.find(storeState.stores, {
                    store_id: currentStorestate.currentStoreId,
                  });
                  if (currentStore) {
                    this.localStorageDataService.loadMigrationCustomerId(
                      currentUser,
                      currentStore
                    );
                  }

                  if (
                    currentStore &&
                    currentStore.vatBasedCustomerProductsActive
                  ) {
                    console.log("get user vatBasedCustomerProductsActive");
                    this.store
                      .select("auth")
                      .pipe(first())
                      .subscribe((authState) => {
                        console.log("get user auth state", authState);
                        if (authState) {
                          if (!authState.migration_customer_id) {
                            this.initMigrationCustomerId(
                              currentUser,
                              currentStorestate,
                              currentStore,
                              authState
                            );
                          } else {
                            this.initOnlyFavoritesCatalog(
                              currentStore,
                              currentStorestate,
                              authState
                            );
                          }
                        }
                      })
                      .unsubscribe();
                  } else {
                    if (
                      currentStore &&
                      currentStore.enable_customer_pricelists_mobileorder &&
                      currentStore.enable_customer_pricelists
                    ) {
                      if (!currentStore.vatBasedCustomerProductsActive) {
                        this.fetchUserPricelists(currentStore.store_id, null);
                      } else {
                        this.store.dispatch(
                          new CatalogActions.SetCustomerPricelist([])
                        );
                      }
                    } else {
                      this.store.dispatch(
                        new CatalogActions.SetCustomerPricelist([])
                      );
                    }

                    if (
                      currentStore &&
                      !currentStore.showOnlyFavoritesCatalog
                    ) {
                      this.fetchCustomerFavoriteProducts(
                        currentStorestate.currentStoreId,
                        null
                      );
                    }
                  }
                }
              })
              .unsubscribe();

            this.store
              .select("deliveryMethodAddress")
              .pipe(first())
              .subscribe((state) => {
                let preorderData;
                if (
                  state &&
                  state.selectedPreorderDate &&
                  state.selectedPreorderDate.date &&
                  state.selectedPreorderDate.hour
                ) {
                  preorderData = {
                    preorder: true,
                    preorder_day: state.selectedPreorderDate.date,
                    preorder_time: state.selectedPreorderDate.hour,
                  };
                } else {
                  preorderData = null;
                }

                this.store
                  .select("auth")
                  .pipe(first())
                  .subscribe((authState) => {
                    this.fetchCustomerPreviousOrders(
                      currentStorestate.currentStoreId,
                      state.orderPickup,
                      state.dineIn,
                      preorderData,
                      state.selectedDineInTable
                        ? state.selectedDineInTable.table_id
                        : null,
                      authState.migration_customer_id
                        ? authState.migration_customer_id
                        : null
                    );
                  })
                  .unsubscribe();
              })
              .unsubscribe();
          }
        }
      })
      .unsubscribe();
  }

  checkingUserAddresses(currentUser) {
    this.store
      .select("deliveryMethodAddress")
      .pipe(first())
      .subscribe((state) => {
        if (state && state.selectedAddress) {
          let selectedAddr = _.find(currentUser.addresses, {
            address_id: state.selectedAddress.address_id,
          });
          console.log(
            "find selected address in user",
            selectedAddr,
            currentUser
          );
          if (
            !selectedAddr &&
            currentUser &&
            !this.groupApp &&
            !this.groupApp.vatBasedCustomerAddressesActive
          ) {
            if (!currentUser.addresses) {
              currentUser.addresses = [];
            }
            currentUser.addresses.push(_.cloneDeep(state.selectedAddress));

            //console.log("current user before request", currentUser);
            this.dataStorageService
              .updateCustomerData({ updatedData: currentUser }, this.mo_token)
              .pipe(
                catchError((err) => {
                  console.log("error", err);
                  return err;
                })
              )
              .subscribe((res: any) => {
                if (!res && !res.succees) {
                  if (res.comment_id) {
                    this.presentErrorToast(
                      this.translateService.instant(res.comment_id),
                      "danger"
                    );
                  } else if (res.comments) {
                    this.presentErrorToast(res.comments, "danger");
                  }
                } else {
                  //console.log("writing user to state");
                  this.store.dispatch(
                    new AuthActions.SetUser(_.cloneDeep(currentUser))
                  );
                  this.store.dispatch(
                    new manuallyTriggerFinalPriceActions.SetTriggerCheckAddressTimestamp(
                      Date.now().toString()
                    )
                  );
                }
              });
          } else {
            //clear selectedAddress and delivery method
            this.clearDeliveryMethod();
          }
        }
        //auto assign the min distance address
        if (
          state &&
          !state.orderPickup &&
          !state.dineIn &&
          (this.platform.is("ios") || this.platform.is("android"))
        ) {
          this.store
            .select("cart")
            .pipe(first())
            .subscribe((cartState) => {
              if (cartState) {
                if (!cartState.products || !cartState.products.length) {
                  this.autoAddressAssignGps(
                    currentUser.addresses || [],
                    state.selectedAddress
                  );
                }
              }
            })
            .unsubscribe();
        }
      })
      .unsubscribe();
  }

  async handlePaymentPopupsInSocket(body) {
    if (!_.isEmpty(this.ordersArray)) {
      var index = _.findIndex(this.ordersArray, {
        order_id: body.order.order_id,
        store_id: body.order.store_id,
      });

      // console.log("order index", index);
      if (index > -1) {
        if (
          body.order.status === "pending" &&
          body.order.payment_id &&
          body.order.selectedPaymentMethodID === "klarna"
        ) {
          this.paymentGateWaysService.closeKlarnaModal();

          // if (klarnaInAppBrowserRef) {
          //   //Closing the in app browser.
          //   klarnaInAppBrowserRef.close();
          // }
        } else if (
          body.order.status === "processingPayment" &&
          body.order.payment_id &&
          body.order.selectedPaymentMethodID === "braintree"
        ) {
          this.paymentGateWaysService.closeBrainTreeModal();
        } else if (
          body.order.status === "processingPayment" &&
          body.order.payment_id &&
          body.order.selectedPaymentMethodID === "paypal"
        ) {
          this.paymentGateWaysService.closeInAppBrowserRef();
          this.paymentGateWaysService.closePaypalModal();

          // if (paypalInAppBrowserRef) {
          //   //Closing the in app browser.
          //   paypalInAppBrowserRef.close();
          // }
        } else if (
          body.order.status === "pending" &&
          body.order.payment_id &&
          body.order.selectedPaymentMethodID === "ethniki"
        ) {
          this.paymentGateWaysService.closeEthnikiModal();
        } else if (
          body.order.status === "pending" &&
          body.order.payment_id &&
          body.order.selectedPaymentMethodID === "nbg_simplify"
        ) {
          this.paymentGateWaysService.closeNbgSimplifyModal();
        } else if (
          body.order.status === "pending" &&
          body.order.payment_id &&
          body.order.selectedPaymentMethodID === "piraeus"
        ) {
          this.paymentGateWaysService.closeInAppBrowserRef();
          if (this.piraeusPopupInfo) {
            this.piraeusPopupInfo.dismiss();
            this.piraeusPopupInfo = null;
          }
          if (this.piraeus3dsModal) {
            this.piraeus3dsModal.dismiss();
            this.piraeus3dsModal;
          }
        } else if (body.order.status === "pending" && body.order.payment_id) {
          {
            this.paymentGateWaysService.closeNavigationToBankModal();

            this.paymentGateWaysService.closeInAppBrowserRef();
          }
        }
      }
    }
  }

  loginStateSystem() {
    if (
      !this.mo_token &&
      !this.currentUser &&
      (!navigator.cookieEnabled ||
        (navigator.cookieEnabled &&
          (!localStorage.getItem("mo_token") ||
            localStorage.getItem("mo_token") === "")))
    ) {
      this.store.dispatch(new AuthActions.SetLoginState("no_login"));
    } else if (this.currentUser) {
      this.store.dispatch(new AuthActions.SetLoginState("login_completed"));
    } else if (!this.currentUser && localStorage.getItem("mo_token")) {
      //get user data or 401 (login_loading)
      this.store.dispatch(new AuthActions.SetLoginState("login_loading"));
    }
  }

  loadFromLocalStorage() {
    this.store
      .select("groupApp")
      .pipe(first())
      .subscribe((groupState) => {
        if (
          !groupState ||
          !groupState.groupApp ||
          !groupState.groupApp.active_reservation_app
        ) {
          this.localStorageDataService.loadCart();
          this.store
            .select(selectors.getStoreInfo)
            .pipe(first())
            .subscribe((storeInfo) => {
              if (storeInfo) {
                //console.log("load delivery method address from local STRG");
                this.localStorageDataService.loadDeliveryMethodAddress(
                  storeInfo
                );
              }
            })
            .unsubscribe();
        } else {
          //delete delivery method address

          if (
            window.localStorage.getItem("deliveryMethodAddress") &&
            window.localStorage.getItem("deliveryMethodAddress") !== "{}" &&
            window.localStorage.getItem("deliveryMethodAddress") !== ""
          ) {
            window.localStorage.removeItem("deliveryMethodAddress");
          }
        }
      })
      .unsubscribe();

    this.localStorageDataService.loadCheckboxPromotions();
    this.localStorageDataService.loadMoToken();
    this.localStorageSubscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .subscribe((storeInfo) => {
          if (storeInfo && !_.isEqual(storeInfo, this.store_info)) {
            console.log("inside stores sub");
            this.store_info = _.cloneDeep(storeInfo);
            console.log("store info invoicess", this.store_info);
            this.localStorageSubscriptions.push(
              this.store
                .select("selectedPaymentMethodID")
                .subscribe((state) => {
                  if (
                    state &&
                    state.selectedPaymentMethodID &&
                    !_.isEqual(
                      this.selectedPaymentMethodID,
                      state.selectedPaymentMethodID
                    )
                  ) {
                    this.selectedPaymentMethodID = _.cloneDeep(
                      state.selectedPaymentMethodID
                    );

                    this.localStorageDataService.loadCustomOptions(
                      this.store_info
                    );
                    this.localStorageLoaded();
                  } else if (
                    state &&
                    state.selectedPaymentMethodID &&
                    _.isEqual(
                      this.selectedPaymentMethodID,
                      state.selectedPaymentMethodID
                    )
                  ) {
                    this.localStorageLoaded();
                  }
                })
            );
          }
        })
    );
  }

  localStorageLoaded() {
    if (
      navigator.cookieEnabled &&
      this.localStorageSubscriptions &&
      !_.isEmpty(this.localStorageSubscriptions)
    ) {
      this.localStorageSubscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }

    this.checkForAutoSelectTakeAwayMethod();
  }

  fetchFixedPointsAdresses(group) {
    this.dataStorageService.fetchFixedPointsAddresses(group).subscribe({
      next: async (res: any) => {
        if (res && res.success) {
          this.store.dispatch(
            new DeliveryMethodAddressActions.SetFixedPointsAddresses(
              res.fixedDeliveryAddresses
            )
          );
        } else {
          console.log("error fetch fixed points addresses");
        }
      },
      error: async (err) => {
        console.log("error fetch user ixed points addresses (no response)");
        return err;
      },
    });
  }

  checkForAutoSelectTakeAwayMethod() {
    console.log("check For Auto select take away called");
    setTimeout(() => {
      this.store
        .select("deliveryMethodAddress")
        .pipe(first())
        .subscribe((deliveryMethodAddress) => {
          this.store
            .select(selectors.getStoreInfo)
            .pipe(first())
            .subscribe((store_info) => {
              if (
                store_info &&
                store_info.disableOrderDelivery &&
                !store_info.dinein &&
                !store_info.disableOrderPickup &&
                !deliveryMethodAddress.orderPickup
              ) {
                this.autoSelectTakeaway();
              }
            })
            .unsubscribe();
        })
        .unsubscribe();
    }, 600);
  }

  autoSelectTakeaway() {
    console.log("auto select take away");
    this.store.dispatch(
      new DeliveryMethodAddressActions.setSelectedAddress(null)
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setCheckSelectedAddress(null)
    );
    this.store.dispatch(new DeliveryMethodAddressActions.setOrderPickup(true));
    this.store.dispatch(new DeliveryMethodAddressActions.setDineIn(false));

    this.store.dispatch(
      new DeliveryMethodAddressActions.setSelectedDineInTable(_.cloneDeep(null))
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setValidDeliveryAreas(null)
    );

    this.analyticsService.dmAnalyticsMessage(
      "takeaway_method",
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );

    if (navigator.cookieEnabled) {
      let deliveryMethodAddressObj: any = localStorage.getItem(
        "deliveryMethodAddress"
      );
      if (deliveryMethodAddressObj) {
        deliveryMethodAddressObj = JSON.parse(deliveryMethodAddressObj);
        if (deliveryMethodAddressObj) {
          deliveryMethodAddressObj.orderPickup = true;
          deliveryMethodAddressObj.dineIn = false;
          deliveryMethodAddressObj.selectedAddress = null;
          deliveryMethodAddressObj.selectedDineInTable = null;
        } else {
          deliveryMethodAddressObj = {
            orderPickup: true,
            dineIn: false,
          };
        }
      } else {
        deliveryMethodAddressObj = {
          orderPickup: true,
          dineIn: false,
        };
      }

      window.localStorage.setItem(
        "deliveryMethodAddress",
        JSON.stringify(deliveryMethodAddressObj)
      );
    }
  }

  private fetchUserOrdersDebounce = _.debounce(() => {
    this.getUserOrders();
  }, 2000);

  fetchUserReservationsDebounce = _.debounce(() => {
    this.getReservations();
  }, 2000);

  getUserOrders() {
    this.store
      .select(selectors.getStoreInfo)
      .pipe(first())
      .subscribe((storeInfo) => {
        this.dataStorageService
          .getUserOrders(
            this.mo_token,
            storeInfo.vatBasedCustomerProductsActive
          )

          .subscribe({
            next: async (res: any) => {
              if (res && res.success) {
                console.log("success fetching user orders");
                let orderArray = res.data;
                this.store.dispatch(
                  new OrdersActions.SetOrdersArray(orderArray)
                );
                this.store.dispatch(
                  new PendingOrdersActions.CheckPendingOrderFromFetch(
                    orderArray
                  )
                );
              } else {
                console.log("error fetch user orders");
              }
            },
            error: async (err) => {
              console.log("error fetch user orders (no response)");

              return err;
            },
          });
      })
      .unsubscribe();
  }

  getReservations() {
    this.dataStorageService
      .fetchReservations(
        this.mo_token,
        this.currentStore.store_id,
        this.groupApp ? this.groupApp.active_reservation_app : null
      )
      .subscribe({
        next: async (res: any) => {
          if (res && res.reservations) {
            this.store.dispatch(
              new reservationActions.SetReservations(res.reservations)
            );
          } else {
            console.log("error fetch user reservations");
          }
        },
        error: async (error) => {
          console.log("error fetch user reservations (no response)");
          return error;
        },
      });
  }

  closeSideMenuAndNavigateAccount() {
    this.router.navigateByUrl("/account-settings", {
      replaceUrl: true,
    });
    this.menuCtrl.close();
  }

  closeSideMenu() {
    this.menuCtrl.close();
  }

  public async changeChannel() {
    console.log("changeChannel");
    if (Capacitor.isNativePlatform()) {
      console.log("changeChannel", this.changeChannel);
      this.counterChannel++;
      if (this.counterChannel === 10) {
        if (
          !localStorage.getItem("appChannel") ||
          this.appChannel === "Capacitor"
        ) {
          this.appChannel = "mastercapacitor";
          localStorage.setItem("appChannel", "mastercapacitor");
          this.setDeveloperMode(true);

          this.checkUpdate();
          this.periodCheck();
        } else {
          this.appChannel = "Capacitor";
          localStorage.setItem("appChannel", "Capacitor");
          this.setDeveloperMode(false);

          this.checkUpdate();
        }
        this.counterChannel = 0;
        const toast = await this.toastController.create({
          message: `You are now in ${this.appChannel} branch`,
          color: "dark",
          duration: 5000,
          buttons: [
            {
              text: "OK",
              role: "cancel",
              handler: () => {
                console.log("Close clicked");
              },
            },
          ],
        });
        toast.present();
      }
    }
  }

  periodCheck() {
    console.log("periodic check");
    if (!this.keepAlive) {
      this.keepAlive = setInterval(async () => {
        if (
          Capacitor.isNativePlatform() &&
          this.appChannel === "mastercapacitor"
        ) {
          await this.checkUpdate();
        }
      }, 30000);
    }
  }

  private alertUpdate;
  private updateModal;
  private extractedDownloadedUpdate;
  async checkUpdate() {
    console.log("inside checkUpdate app channel", this.appChannel);
    if (Capacitor.isNativePlatform()) {
      try {
        console.log("checkUpdate");
        await this.deploy.configure({
          channel: this.appChannel,
        });

        const update = await this.deploy.checkForUpdate();
        if (update.available && !this.extractedDownloadedUpdate) {
          this.splashScreenDissmised = true;

          if (this.checkLocationDineInModal) {
            this.checkLocationDineInModal.dismiss();
            this.checkLocationDineInModal = null;
          }
          if (this.updateModal) {
            this.updateModal.dismiss();
            this.updateModal = null;
          }
          this.updateModal = await this.modalCtrl.create({
            component: UpdateModalComponent,

            showBackdrop: true,
            backdropDismiss: false,
            cssClass: "update-modal-class",
            componentProps: {
              appChannel: this.appChannel,
            },
          });
          await this.updateModal.present();

          const downloadedUpdate = await this.deploy
            .downloadUpdate((prog) => {
              console.log("Downloading ", prog);
              this.deployService.setUpdateProgress(prog);
            })
            .catch((err) => {
              console.log("Downloading update ionic err", err);
              this.store.dispatch(
                new AuthActions.SetIonicUpdateErrorFlag("downloading-error")
              );
              this.store.dispatch(
                new AppInitLoadingActions.SetAppInitLoadingStep(
                  "update-checked"
                )
              );

              if (this.updateModal) {
                this.updateModal.dismiss();
                this.updateModal = null;
              }
              return Promise.resolve(false);
            });
          console.log("downloadedUpdate", downloadedUpdate);
          if (downloadedUpdate) {
            this.extractedDownloadedUpdate = await this.deploy
              .extractUpdate((prog) => {
                console.log("Extracting ", prog);
              })
              .catch((err) => {
                console.log("Extracting update ionic err", err);

                this.store.dispatch(
                  new AuthActions.SetIonicUpdateErrorFlag("extract-error")
                );
                this.store.dispatch(
                  new AppInitLoadingActions.SetAppInitLoadingStep(
                    "update-checked"
                  )
                );
                if (this.updateModal) {
                  this.updateModal.dismiss();
                  this.updateModal = null;
                }
                return Promise.resolve(false);
              });
            console.log(
              "extractedDownloadedUpdate",
              this.extractedDownloadedUpdate
            );
            if (this.extractedDownloadedUpdate) {
              if (this.alertUpdate) {
                this.alertUpdate.dismiss();
              }

              this.deploy.reloadApp();
            }
          }
        } else {
          if (!this.groupAppSplashScreenSubscription && !this.updateModal) {
            this.groupAppSplashScreenSubscription = this.store
              .select("groupApp")
              .subscribe((state) => {
                if (
                  state &&
                  state.groupApp &&
                  state.groupApp.splash_screen_active &&
                  state.groupApp.splashScreenImage &&
                  !this.splashScreenModal
                ) {
                  this.splashScreenModal = true;
                  this.presentSplashScreenModal(
                    _.cloneDeep(state.groupApp.splashScreenImage),
                    _.pick(_.cloneDeep(state.groupApp), [
                      "group",
                      "design_settings",
                    ])
                  );
                }
              });
          } else {
            this.splashScreenDissmised = true;
          }
          this.store.dispatch(
            new AppInitLoadingActions.SetAppInitLoadingStep("update-checked")
          );
        }
      } catch (err) {
        // We encountered an error.
        console.error("ionic deploy sync", err);
        if (
          (this.appChannel === "Capacitor" && this.retryCheckUpdate >= 7) ||
          this.appChannel === "mastercapacitor"
        ) {
          //ionic deploy error sync
          this.store.dispatch(
            new AuthActions.SetIonicUpdateErrorFlag("general-error")
          );
          this.store.dispatch(
            new AppInitLoadingActions.SetAppInitLoadingStep("update-checked")
          );
        }
        if (this.appChannel === "Capacitor" && this.retryCheckUpdate < 7) {
          setTimeout(() => {
            this.retryCheckUpdate++;
            this.checkUpdate();
          }, 1000 * this.retryCheckUpdate);
        } else {
          this.splashScreenDissmised = true;

          this.store.dispatch(
            new AppInitLoadingActions.SetAppInitLoadingStep("update-checked")
          );
        }
      }
    }
  }

  getPreorderDays(currentStoreId, groupApp, selectedLanguage) {
    console.log("preorder days fetch", selectedLanguage);
    this.dataStorageService
      .getPreorderDays(currentStoreId, groupApp.group, selectedLanguage)
      .subscribe({
        next: async (res: any) => {
          if (!res || !res.success) {
            const alert = await this.alertController.create({
              header: this.translateService.instant("alert"),
              message:
                res && res.comment_id
                  ? this.translateService.instant(res.comment_id)
                  : this.translateService.instant(
                      "errorMessages.general_error"
                    ),
              backdropDismiss: false,
              buttons: ["OK"],
            });

            await alert.present();
          } else {
            this.store.dispatch(
              new DeliveryMethodAddressActions.setPreorderResponse({
                preorder_delivery_days: res.preorder_delivery_days,
                preorder_pickup_days: res.preorder_pickup_days,
              })
            );

            if (this.stores && this.stores.length && this.stores.length > 1) {
              console.log("preorderValidCalculationsChangeStore", this.stores);
              this.store
                .select("deliveryMethodAddress")
                .pipe(first())
                .subscribe((state) => {
                  if (state.selectedPreorderDate) {
                    if (this.orderPickup && !this.dinein) {
                      this.preorderValidationService.preorderValidCalculationsChangeStore(
                        res,
                        state.orderPickup,
                        state.dineIn,
                        state.selectedPreorderDate
                      );
                    } else if (!this.orderPickup && !this.dinein) {
                      this.preorderValidationService.preorderValidCalculationsChangeStore(
                        res,
                        state.orderPickup,
                        state.dineIn,
                        state.selectedPreorderDate
                      );
                    } else if (!this.orderPickup && this.dinein) {
                      //TO_DO IMPLEMENT FOR DINE IN IF NEEDED
                    }
                  }
                })
                .unsubscribe();
            }
          }
        },
        error: async (error) => {
          const alert = await this.alertController.create({
            header: this.translateService.instant("alert"),
            message: this.translateService.instant(
              "errorMessages.problem_reaching_server"
            ),

            buttons: ["OK"],
          });
          await alert.present();
          return error;
        },
      });
  }

  async presentPiraeus3dsModal(frame_width, frame_height, piraeus_3ds_url) {
    this.piraeus3dsModal = await this.modalCtrl.create({
      component: PiraeusThreeDsPopupComponent,
      cssClass: "paymentModal",
      backdropDismiss: false,
      componentProps: {
        frame_width: frame_width,
        frame_height: frame_height,
        piraeus_3ds_url: piraeus_3ds_url,
      },
    });

    await this.piraeus3dsModal.present();
    this.piraeus3dsModal.onDidDismiss().then((data) => {
      this.piraeus3dsModal = null;
      if (data && data.data) {
        console.log(data);
      }
    });
  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }

  async presentLoading() {
    let message = this.translateService.instant("loading-please-wait");
    this.ionLoading = await this.loadingController.create({
      duration: 30000,
      message: message,
      cssClass: "ionicLoadingCss",
      keyboardClose: true,
    });
    await this.ionLoading.present();

    console.log("Loading dismissed!");
  }

  styleStatusBar() {
    if (this.platform && this.platform.is("android")) {
      StatusBar.setStyle({
        style: Style.Default,
      });
    } else if (this.platform && this.platform.is("ios")) {
      StatusBar.hide();
    }
  }

  saveRegId(currentUser, mo_token, groupApp, versionNumber) {
    console.log("menu", "saving RegId");

    if (Capacitor.isNativePlatform()) {
      try {
        if (this.platform && this.platform.is("android")) {
          OneSignal.disablePush(false);
        }
        OneSignal.getTags((tags: any) => {
          console.log("menu", "OneSignal getTags:", tags);

          //Updating Tags
          if (!tags || !tags.group) {
            tags = {
              mobile: currentUser ? currentUser.mobile : "",
              group: groupApp.group,
              version: versionNumber,
            };
          } else {
            tags.mobile = currentUser ? currentUser.mobile : "";
            tags.group = groupApp.group;
            tags.version = versionNumber;
          }

          console.log("menu", "Tag(s) to be pushed: ", tags);
          OneSignal.sendTags(tags);
        });

        OneSignal.getDeviceState((stateChanges) => {
          console.log(
            "OneSignal getDeviceState: " + JSON.stringify(stateChanges)
          );
          if (currentUser && stateChanges) {
            currentUser.device = {
              pushToken: stateChanges.pushToken,
              userId: stateChanges.userId,
              timestamp: Date.now().toString(),
            };
            this.updateCustomerData(
              { updatedData: _.cloneDeep(currentUser) },
              mo_token,
              false
            );
          }
        });
      } catch (err) {
        console.log("one signal err", err);
      }
    } else if (
      !Capacitor.isNativePlatform() &&
      window.location.hostname !== "localhost" &&
      window.OneSignal
    ) {
      console.log(
        "we are on the browser and not localhost for onesignal",
        window.OneSignal
      );

      window.OneSignal.push([
        "sendTags",
        {
          mobile: currentUser ? currentUser.mobile : "",
          group: groupApp.group,
        },
      ]);

      if (currentUser) {
        window.OneSignal.getUserId((userId) => {
          window.OneSignal.push([
            "getRegistrationId",
            (registrationId) => {
              currentUser.device = {
                pushToken: registrationId,
                userId: userId,
                timestamp: Date.now().toString(),
              };

              this.updateCustomerData(
                { updatedData: _.cloneDeep(currentUser) },
                mo_token,
                false
              );
            },
          ]);
        });
      }
    }
  }

  async showFixedAddressInfoModal(fixed_address) {
    if (this.fixedAddressModal) {
      await this.fixedAddressModal.dismiss();
      this.fixedAddressModal = null;
    }

    this.fixedAddressModal = await this.modalCtrl.create({
      component: FixedAddressInfoPopupComponent,
      componentProps: {
        fixed_address: _.cloneDeep(fixed_address),
      },
      cssClass: "fixed-address-info-modal",
      backdropDismiss: false,
    });
    await this.fixedAddressModal.present();

    await this.fixedAddressModal.onDidDismiss().then(async (data) => {
      if (
        data &&
        data.data === "accepted" &&
        this.currentUser &&
        this.mo_token &&
        fixed_address
      ) {
        let new_user = _.cloneDeep(this.currentUser);
        if (!new_user.addresses) {
          new_user.addresses = [];
        }

        if (
          !_.find(new_user.addresses, {
            address_id: fixed_address.address.address_id,
          })
        ) {
          if (fixed_address && fixed_address.address) {
            fixed_address.address.fixed_address_id = _.cloneDeep(
              fixed_address.fixed_address_id
            );
          }

          new_user.addresses.push(_.cloneDeep(fixed_address.address));
        } else {
          const addressIndex = _.findIndex(new_user.addresses, {
            address_id: fixed_address.address.address_id,
          });
          if (addressIndex !== -1 && fixed_address && fixed_address.address) {
            new_user.addresses[addressIndex].fixed_address_id = _.cloneDeep(
              fixed_address.fixed_address_id
            );
          }
        }

        if (
          fixed_address.firstName &&
          fixed_address.firstName !== "" &&
          fixed_address.firstName !== "null"
        ) {
          new_user.firstName = fixed_address.firstName;
        }
        if (
          fixed_address.lastName &&
          fixed_address.firstName !== "" &&
          fixed_address.firstName !== "null"
        ) {
          new_user.lastName = fixed_address.lastName;
        }

        //await for response and then select the address
        const response = await this.fixedAddressesService.updateCustomerData(
          { updatedData: _.cloneDeep(new_user) },
          this.mo_token
        );

        if (response === "success") {
          this.fixedAddressesService.selectAddress(fixed_address.address);

          if (fixed_address.order_comments) {
            this.store.dispatch(
              new OrderCommentsActions.SetOrderComments(
                _.cloneDeep(fixed_address.order_comments)
              )
            );
          }

          //HANDLE COUPON RULE ID
          if (fixed_address.coupon_rule_id) {
            this.handleBranchLinkData({
              coupon_rule_id: fixed_address.coupon_rule_id,
              event: "promo_link",
            });
          }

          //TODO DELETE THE ABOVE NAVIGATION PROPABLY DONT WONT THIS AND MAY HAVE PROBLEMS WHEN STORES > 1
          if (
            this.router &&
            this.router.url &&
            this.router.url.toString().includes("homepage")
          ) {
            this.router.navigateByUrl("/catalog", {
              replaceUrl: true,
            });
          }
        } else {
          this.presentErrorToast(
            this.translateService.instant(
              "errorMessages.something_went_wrong_try_again"
            ),
            "danger"
          );
        }
      }
    });
  }

  fetchFixedAddressData(fixed_address_id, group) {
    this.dataStorageService
      .fetchFixedAddressData(fixed_address_id, group)
      .subscribe({
        next: (res: any) => {
          if (!res || !res.success) {
          } else {
            if (
              res.fixed_address &&
              !_.isEmpty(res.fixed_address) &&
              res.fixed_address.address &&
              res.fixed_address.address.address_id
            ) {
              this.showFixedAddressInfoModal(res.fixed_address);
            }
          }
        },
        error: (error) => {
          // let errMsg = this.translateService.instant(
          //   "errorMessages.error: There was an error on saving"
          // );

          // this.presentErrorToast(errMsg, "danger");
          return error;
          console.log("error updating the information");
        },
      });
  }

  updateCustomerData(obj, mo_token, show_success_message) {
    this.dataStorageService.updateCustomerData(obj, mo_token).subscribe({
      next: (res: any) => {
        if (res && res.success) {
          let updatedUser = res.data;
          console.log("writing user to state");
          this.store.dispatch(
            new AuthActions.SetUser(_.cloneDeep(updatedUser))
          );
          if (show_success_message && res.comment_id) {
            this.presentErrorToast(
              this.translateService.instant(
                "account-settings." + res.comment_id
              ),
              "primary"
            );
          }
        } else {
          if (res.comment_id) {
            this.presentErrorToast(
              this.translateService.instant(res.comment_id),
              "danger"
            );
          } else if (res.comments) {
            this.presentErrorToast(res.comments, "danger");
          }
        }
      },
      error: (error) => {
        let errMsg = this.translateService.instant(
          "errorMessages.error: There was an error on saving"
        );

        this.presentErrorToast(errMsg, "danger");
        return error;
        console.log("error updating the information");
      },
    });
  }

  async logoutErase() {
    this.mo_token = null;
    console.log("logout erase called");
    this.store.dispatch(new AuthActions.ClearUser());
    this.store.dispatch(new AuthActions.ClearMoToken());
    this.store.dispatch(new AuthActions.ClearNewCustomer());
    this.store.dispatch(new PointsActions.ClearPoints());
    this.store.dispatch(new FavoriteProductsActions.ClearFavoriteProducts());
    this.store.dispatch(new CatalogActions.SetCustomerPricelist(null));
    this.store.dispatch(new DeliveryMethodAddressActions.clearState());
    this.store.dispatch(new selectedInvoiceActions.ClearSelectedInvoice());
    this.store.dispatch(new AuthActions.SetMigrationCustomerId(null));
    this.store.dispatch(new AuthActions.SetLoginLoading("not-loading"));
    this.store.dispatch(new AuthActions.SetLoginState("no_login"));
    this.store.dispatch(new AuthActions.SetCustomerOrders(null));
    this.store.dispatch(new AuthActions.SetKioskCustomerOrders(null));
    this.store.dispatch(new LoyaltySystemActions.ClearLoyaltyCoupons());

    if (navigator.cookieEnabled) {
      window.localStorage.removeItem("deliveryMethodAddress");
      window.localStorage.removeItem("mo_token");
      window.localStorage.removeItem("customerInvoice");
      window.localStorage.removeItem("cartCustomOptions");
      window.localStorage.removeItem("migration_customer_id");
    }
    //remove socket connection in order reconnect function triggered and handles the connection
    if (this.socket) {
      this.socket.close();
    }

    if (
      this.groupApp &&
      this.groupApp.firebaseVerificationEnabled &&
      Capacitor.isNativePlatform()
    ) {
      await FirebaseAuthentication.signOut().catch((error) => {
        console.log("error signout from firebase", error);
      });
    } else if (
      this.groupApp &&
      this.groupApp.firebaseVerificationEnabled &&
      this.groupApp.webFirebase &&
      this.groupApp.webFirebase.apiKey &&
      !Capacitor.isNativePlatform() &&
      this.firebaseBrowserService.getFireBaseApp()
    ) {
      let fireBaseApp = this.firebaseBrowserService.getFireBaseApp();
      const auth = getAuth(fireBaseApp);
      auth
        .signOut()
        .then(() => {
          // Sign-out successful.
          console.log("Successfull signout from firebase");
        })
        .catch((error) => {
          // An error happened.
          console.log("error signout from firebase", error);
        });
    }
  }

  handleCartCustomOptionsOnStoreChanged() {
    console.log("handle cart options on store changed called");
    this.store.dispatch(
      new cartCustomOptionsSelectionsActions.ClearCartCustomOptionsSelections()
    );
    if (
      this.currentStore &&
      this.currentStore.cartCustomOptionsActive &&
      this.currentStore.cartCustomOptions &&
      this.currentStore.cartCustomOptions.length > 0
    ) {
      let customOptions = _.cloneDeep(this.currentStore.cartCustomOptions);
      customOptions.forEach((option) => {
        this.store.dispatch(
          new cartCustomOptionsSelectionsActions.AddUpdateCartCustomOptionSelections(
            option
          )
        );
      });
    }
  }

  async logout() {
    const alert = await this.alertCtrl.create({
      header: this.translateService.instant("popups.logout-alert-popup.exit"),
      message: this.translateService.instant(
        "popups.logout-alert-popup.do-you-want-to-logout"
      ),
      buttons: [
        {
          text: this.translateService.instant(
            "popups.logout-alert-popup.cancel"
          ),
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: this.translateService.instant("popups.logout-alert-popup.yes"),
          handler: () => {
            this.analyticsService.dmAnalyticsMessage(
              "logged_out",
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null
            );
            this.store.dispatch(
              new AuthActions.SetLogoutFlagTimestamp(Date.now().toString())
            );
            this.router.navigateByUrl("/homepage", {
              replaceUrl: true,
            });
          },
        },
      ],
    });

    await alert.present();
  }

  trustedUrl(url) {
    console.log("trusted Url", url);
    return this.DomSinitizer.bypassSecurityTrustResourceUrl(url);
  }

  ngOnDestroy() {
    this.logoutFlagTimestamp = null;
    this.mo_token = null;
    this.currentUser = null;

    if (this.cartSubscription) {
      this.cartSubscription.unsubscribe();
    }
  }
}
