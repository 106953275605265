import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { auditTime, distinctUntilChanged, Subscription } from "rxjs";
import * as fromApp from "../../store/app.reducer";
import * as _ from "lodash";
import { ModalController, Platform } from "@ionic/angular";
import { LoyaltyCouponItemComponent } from "./loyalty-coupon-item/loyalty-coupon-item.component";
import { Router } from "@angular/router";
import { AnimationOptions } from "ngx-lottie";
import { LoginPopupsServiceService } from "src/app/services/login-popups-service/login-popups-service.service";
import { Capacitor } from "@capacitor/core";

import { AnalyticsServiceService } from "src/app/services/analitycsService/analytics-service.service";
declare let window;

@Component({
  selector: "app-loyalty-coupons",
  templateUrl: "./loyalty-coupons.page.html",
  styleUrls: ["./loyalty-coupons.page.scss"],
})
export class LoyaltyCouponsPage implements OnInit, OnDestroy {
  public pageCouponAnimationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/discount-coupon.json",
  };
  public customerCoupons: any[];
  public activeLoyaltyCoupons: number = 0;
  windowDimensions: any;
  bigScreenStyle: boolean;

  private subscription: Subscription;
  private unsubscribeBackEvent: Subscription;
  private subscriptions: Subscription[] = [];
  currentUser: any;
  loginState: any;
  couponPageAnimation: any;
  newCustomer: any;
  groupApp: any;

  constructor(
    private store: Store<fromApp.AppState>,
    private modalController: ModalController,
    private platform: Platform,
    private router: Router,
    private loginPopupService: LoginPopupsServiceService,
    private analyticsService: AnalyticsServiceService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select("customerCoupons")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(200))
        .subscribe((state) => {
          console.log(state);
          if (
            state &&
            state.allCoupons &&
            !_.isEqual(this.customerCoupons, state.allCoupons)
          ) {
            this.customerCoupons = _.orderBy(
              _.cloneDeep(state.allCoupons),
              ["status", "type", "timestamp"],
              ["asc", "asc", "desc"]
            );
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(200))
        .subscribe((state) => {
          console.log(state);
          if (state && !_.isEqual(this.currentUser, state.user)) {
            this.currentUser = _.cloneDeep(state.user);
          }
          if (state && !_.isEqual(state.loginState, this.loginState)) {
            this.loginState = _.cloneDeep(state.loginState);
          }
          if (state && !_.isEqual(state.newCustomer, this.newCustomer)) {
            this.newCustomer = _.cloneDeep(state.newCustomer);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(200))
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(this.groupApp, state.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.windowDimensions &&
            !_.isEqual(this.windowDimensions, state.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
            if (this.windowDimensions && this.windowDimensions.width) {
              let width = this.windowDimensions.width;
              if (width > 1000) {
                this.bigScreenStyle = true;
              } else if (width >= 600 && width <= 1000) {
                this.bigScreenStyle = false;
              } else if (width < 600) {
                this.bigScreenStyle = false;
              }
            }
          }
        })
    );
  }

  ionViewDidEnter() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      100,
      () => {
        console.log("navitate to home from coupons page");
        this.navigateToLoyaltyHome();
      }
    );
  }

  couponPageAnimationCreated(ev) {
    this.couponPageAnimation = ev;
    this.couponPageAnimation.setSpeed(1.2);
  }

  async openCouponItemModal(coupon) {
    const modal = await this.modalController.create({
      component: LoyaltyCouponItemComponent,
      componentProps: {
        coupon: coupon,
      },
      backdropDismiss: false,
    });

    await modal.present();
  }

  async presentMagicLoginPopup() {
    this.analyticsService.dmAnalyticsMessage(
      "login_button",
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
    // this.MagicLoginservice.presentLoginModal(this.newCustomer);
    if (!Capacitor.isNativePlatform()) {
      this.loginPopupService.presentFirebaseModalLogin(null, null);
    } else {
      this.loginPopupService.closeMobileMagicLoginAlert();
      this.loginPopupService.presentMobileMagicLoginAlert(
        this.newCustomer,
        this.groupApp
      );
    }
  }

  navigateToLoyaltyHome() {
    if (this.platform.is("android")) {
      this.router.navigateByUrl("/loyaltySystem/loyalty-home", {
        replaceUrl: true,
      });
    }
  }

  ionViewDidLeave() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    this.subscriptions = [];
  }
}
