<ion-footer
  *ngIf="
    groupApp &&
    groupApp.design_settings &&
    groupApp.design_settings.menuFooterTabs &&
    windowDimensions &&
    windowDimensions.width < 900 &&
    activeRoute
  "
  [ngStyle]="{
    'min-height':
      activeRoute && activeRoute !== 'homepage' && !playAnimationFadeOut
        ? '55px'
        : '',
    background:
      activeRoute && activeRoute === 'catalog'
        ? 'var(--catalog-background-image)'
        : ''
  }"
>
  <ion-grid
    style="position: absolute; bottom: 0; width: 100%"
    [ngClass]="{
      'ion-no-padding': true,
      'footer-grid-border': activeRoute !== 'homepage',
      'animate__animated animate__fadeInUp': playAnimationFadeIn,
      'animate__animated animate__fadeOutDown': playAnimationFadeOut
    }"
  >
    <ion-row
      style="width: 100%"
      class="ion-justify-content-center ion-align-items-center ion-no-padding"
    >
      <ion-col
        size="12"
        class="ion-align-self-center ion-text-center ion-no-padding"
      >
        <ion-grid
          [class]="
            windowDimensions &&
            windowDimensions.width > 330 &&
            activeRoute &&
            activeRoute === 'catalog'
              ? 'inside-grid-catalog'
              : 'inside-grid-other-pages'
          "
        >
          <div style="display: flex; justify-content: space-between; gap: 5px">
            <ion-grid style="max-width: 40px; padding: 0">
              <ion-item
                [class]="
                  activeRoute === 'homepage'
                    ? 'tabItemFooter selectedTab'
                    : 'tabItemFooter'
                "
                button="true"
                size="small"
                mode="md"
                [color]="activeRoute === 'homepage' ? 'black' : 'primary'"
                (click)="pageChange('homepage')"
              >
                <div class="ion-text-center" style="width: 100%">
                  <ion-icon
                    style="font-size: 30px"
                    src="assets/flatIcons/coffe.svg"
                  ></ion-icon>
                </div>
              </ion-item>
            </ion-grid>
            <ion-grid style="max-width: 40px; padding: 0">
              <ion-item
                [class]="
                  activeRoute === 'catalog'
                    ? 'tabItemFooter selectedTab'
                    : 'tabItemFooter'
                "
                button="true"
                size="small"
                mode="md"
                color="primary"
                [color]="activeRoute === 'catalog' ? 'black' : 'primary'"
                (click)="presentOrderNowModal()"
              >
                <div class="ion-text-center" style="width: 100%">
                  <ion-icon
                    style="font-size: 30px"
                    src="assets/ionicons/bag-outline.svg"
                  ></ion-icon>
                </div>
              </ion-item>
            </ion-grid>
            <ion-grid style="max-width: 40px; padding: 0">
              <ion-item
                [class]="
                  activeRoute === 'points'
                    ? 'tabItemFooter selectedTab'
                    : 'tabItemFooter'
                "
                button="true"
                size="small"
                mode="md"
                color="primary"
                [color]="activeRoute === 'points' ? 'black' : 'primary'"
                (click)="pageChange('points')"
              >
                <div class="ion-text-center" style="width: 100%">
                  <ion-icon
                    style="font-size: 30px"
                    src="assets/flatIcons/b-letter.svg"
                  ></ion-icon>
                </div>
              </ion-item>
            </ion-grid>
            <ion-grid style="max-width: 40px; padding: 0">
              <ion-item
                [class]="
                  activeRoute === 'account-settings'
                    ? 'tabItemFooter selectedTab'
                    : 'tabItemFooter'
                "
                button="true"
                size="small"
                mode="md"
                [color]="
                  activeRoute === 'account-settings' ? 'black' : 'primary'
                "
                (click)="
                  currentUser
                    ? pageChange('account-settings')
                    : presentMagicLoginPopup()
                "
              >
                <div class="ion-text-center" style="width: 100%">
                  <ion-icon
                    style="font-size: 30px"
                    src="assets/flatIcons/login.svg"
                  ></ion-icon>
                </div>
              </ion-item>
            </ion-grid>
            <!-- <ion-grid
              style="max-width: 40px; padding: 0; margin-left: 40px"
              *ngIf="activeRoute && activeRoute === 'homepage'"
            >
              <ion-item
                (click)="openSideMenu()"
                [class]="
                  sideMenuIsOpen ? 'tabItemFooter selectedTab' : 'tabItemFooter'
                "
                button="true"
                size="small"
                mode="md"
                color="primary"
              >
                <div class="ion-text-center" style="width: 100%">
                  <ion-icon
                    style="font-size: 30px"
                    name="menu-outline"
                  ></ion-icon>
                  <div
                    style="
                      font-size: x-small;
                      font-weight: bold;
                      position: absolute;
                      bottom: 4px;
                      width: 100%;
                      text-align: center;
                    "
                  >
                    MENU
                  </div>
                </div>
              </ion-item>
            </ion-grid> -->

            <ion-grid
              style="max-width: 40px; padding: 0; margin-left: 15px"
              *ngIf="
                activeRoute &&
                activeRoute === 'catalog' &&
                windowDimensions &&
                windowDimensions.width > 330
              "
            >
              <ion-item
                (click)="showSearchModal()"
                button="true"
                size="small"
                mode="md"
                color="primary"
                class="tabItemFooter"
              >
                <div class="ion-text-center" style="width: 100%">
                  <ion-icon
                    style="font-size: 30px"
                    src="assets/ionicons/search-outline.svg"
                  ></ion-icon>
                </div>
              </ion-item>
            </ion-grid>
          </div>
        </ion-grid>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
