import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { distinctUntilChanged, first } from "rxjs/operators";
import * as fromApp from "../../../store/app.reducer";
import * as _ from "lodash";

import { ModalController, Platform } from "@ionic/angular";
import { InvoiceAddEditModalComponent } from "./invoice-add-edit-modal/invoice-add-edit-modal.component";
import { InvoiceListModalComponent } from "../invoice-list-modal/invoice-list-modal.component";
import * as selectors from "../../../store/app.selectors";
import * as SelectedInvoiceActions from "../invoice/store/selectedInvoice.actions";
import * as CompleteOrderValidationActions from "../../../store/complete-order-validation/completeOrderValidation.actions";
import * as AuthActions from "../../../auth/store/auth.actions";

@Component({
  selector: "app-invoice",
  templateUrl: "./invoice.component.html",
  styleUrls: ["./invoice.component.scss"],
})
export class InvoiceComponent implements OnInit, OnDestroy {
  public store_info: any;
  public currentUser: any;
  public invoiceData: any;
  public valid: boolean = true;
  public selectedCustomerInvoice: any;
  public invoicePaymentError: boolean;
  public invoiceCheckboxValue: any;
  public finalPriceLoading: boolean = false;
  public invoiceRequiredOpenModal: boolean;
  private unsubscribeBackEvent: Subscription;
  private lastValidTimestamp: any = null;
  private subscriptions: Subscription[] = [];
  private invoiceAddEditModal: any;
  private selectInvoiceModal: any;

  constructor(
    private store: Store<fromApp.AppState>,
    private modalCtrl: ModalController,
    private platform: Platform
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && !_.isEqual(this.currentUser, state.user)) {
            this.currentUser = _.cloneDeep(state.user);

            if (this.invoicePaymentError) {
              this.invoicePaymentError = false;
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("selectedInvoice")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.selectedCustomerInvoice, state.selectedInvoice)
          ) {
            this.selectedCustomerInvoice = _.cloneDeep(state.selectedInvoice);

            if (this.selectedCustomerInvoice && !this.invoiceCheckboxValue) {
          
              this.changeInvoiceCheckboxDebounce(true);
            } else if (
              !this.selectedCustomerInvoice &&
              this.invoiceCheckboxValue
            ) {
              this.changeInvoiceCheckboxDebounce(false);
            }
            this.invoiceValidation();
          }
          if (
            state &&
            !_.isEqual(
              this.invoiceRequiredOpenModal,
              state.invoiceRequiredOpenModal
            )
          ) {
            this.invoiceRequiredOpenModal = _.cloneDeep(
              state.invoiceRequiredOpenModal
            );
            console.log(
              "invoice Required Modal",
              this.invoiceRequiredOpenModal
            );
            if (this.invoiceRequiredOpenModal) {
              this.openInvoiceModal();
              this.store.dispatch(
                new SelectedInvoiceActions.SetInvoiceRequiredOpenModal(false)
              );
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .subscribe((storeInfo) => {
          if (storeInfo && !_.isEqual(storeInfo, this.store_info)) {
            this.store_info = _.cloneDeep(storeInfo);

            this.checkInvoiceRequiredForSelectDebouce();
            //console.log("store Info from cart options", this.store_info);
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("completeOrderValidation")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.lastValidTimestamp &&
            !_.isEqual(this.lastValidTimestamp, state.lastValidTimestamp)
          ) {
            this.lastValidTimestamp = _.cloneDeep(state.lastValidTimestamp);
            this.completeOrderInvoiceValidations();
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("disableFinalPrice")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(state.disableFinalPrice, this.finalPriceLoading)
          ) {
            this.finalPriceLoading = _.cloneDeep(state.disableFinalPrice);
          }
        })
    );
  }

  private checkInvoiceRequiredForSelectDebouce = _.debounce(() => {
    this.store
      .select("auth")
      .pipe(first())
      .subscribe((auth) => {
        if (auth && auth.user) {
       
          this.store
            .select(selectors.getStoreInfo)

            .pipe(first())
            .subscribe((storeInfo) => {
              
              if (storeInfo && storeInfo.customerInvoiceRequired) {
                if (
                  auth.user.invoiceData &&
                  auth.user.invoiceData.length &&
                  auth.user.invoiceData.length === 1
                ) {
                  let selectedInvoce = _.cloneDeep(auth.user.invoiceData[0]);
                  selectedInvoce.enabled = true;
                  this.changeInvoiceCheckboxDebounce(true);
                  console.log("set selected invoice from invoice component");
                  this.store.dispatch(
                    new SelectedInvoiceActions.SetSelectedInvoice(
                      _.cloneDeep(selectedInvoce)
                    )
                  );
                  if (storeInfo && storeInfo.vatBasedCustomerProductsActive) {
                    this.setMigrationCustomerId(
                      auth.user.invoiceData[0].migration_customer_id
                    );
                  }
                }
              }
            })
            .unsubscribe();
        }
      })
      .unsubscribe();
  }, 300);

  private changeInvoiceCheckboxDebounce = _.debounce((value) => {
    this.invoiceCheckboxValue = value;
  }, 200);

  setMigrationCustomerId(migration_customer_id) {
    this.store.dispatch(
      new AuthActions.SetMigrationCustomerId(migration_customer_id)
    );
    if (navigator.cookieEnabled) {
      localStorage.setItem("migration_customer_id", migration_customer_id);
    }
  }

  openInvoiceModal() {
    console.log("open invoice modal called");
    this.invoiceCheckboxValue = !this.invoiceCheckboxValue;
    this.invoicePaymentError = false;
    console.log(this.invoiceCheckboxValue);
    if (this.invoiceCheckboxValue && this.currentUser) {
      if (
        this.currentUser &&
        this.currentUser.invoiceData &&
        this.currentUser.invoiceData.length <= 0
      ) {
        this.presentAddEditModal("add", null);
      } else if (
        this.currentUser &&
        this.currentUser.invoiceData &&
        this.currentUser.invoiceData.length
      ) {
        this.presentSelectInvoiceModal(this.currentUser.invoiceData);
      }
    } else if (this.invoiceCheckboxValue && !this.currentUser) {
      this.invoicePaymentError = true;
      this.invoiceCheckboxValue = false;
    }
    // if (!this.invoiceCheckboxValue && this.currentUser) {
    //   this.store.dispatch(new SelectedInvoiceActions.ClearSelectedInvoice());
    // }
  }

  //taking the cases for which modal should open
  checkForCustomerInvoices(ev) {
    this.invoiceCheckboxValue = !this.invoiceCheckboxValue;
    this.invoicePaymentError = false;

    console.log(
      "check for customer invoices",
      this.invoiceCheckboxValue,
      this.currentUser
    );
    if (this.invoiceCheckboxValue && this.currentUser) {
      if (
        this.currentUser &&
        this.currentUser.invoiceData &&
        this.currentUser.invoiceData.length <= 0
      ) {
        console.log("check for customer invoices length <=0");
        this.presentAddEditModal("add", null);
      } else if (
        this.currentUser &&
        this.currentUser.invoiceData &&
        this.currentUser.invoiceData.length > 1
      ) {
        this.presentSelectInvoiceModal(this.currentUser.invoiceData);
        console.log("check for customer invoices length >1");
      } else if (
        this.currentUser &&
        this.currentUser.invoiceData &&
        this.currentUser.invoiceData.length == 1 &&
        this.currentUser.invoiceData[0].vatNumber &&
        this.currentUser.invoiceData[0].vatOffice &&
        this.currentUser.invoiceData[0].company_name &&
        this.currentUser.invoiceData[0].company_address &&
        this.currentUser.invoiceData[0].activity
      ) {
        console.log("check for customer invoices length ==1");
        let selectedInvoce = this.currentUser.invoiceData[0];
        selectedInvoce.enabled = true;
        console.log("set selected invoice from invoice component");
        this.store.dispatch(
          new SelectedInvoiceActions.SetSelectedInvoice(
            _.cloneDeep(selectedInvoce)
          )
        );
        this.store
          .select(selectors.getStoreInfo)
          .pipe(first())
          .subscribe((storeInfo) => {
            if (storeInfo && storeInfo.vatBasedCustomerProductsActive) {
              this.setMigrationCustomerId(selectedInvoce.migration_customer_id);
            }
          })
          .unsubscribe();
      } else if (
        this.currentUser &&
        this.currentUser.invoiceData &&
        this.currentUser.invoiceData.length == 1 &&
        this.currentUser.invoiceData[0].vatNumber &&
        (!this.currentUser.invoiceData[0].vatOffice ||
          !this.currentUser.invoiceData[0].company_name ||
          !this.currentUser.invoiceData[0].company_address ||
          !this.currentUser.invoiceData[0].activity)
      ) {
        this.presentSelectInvoiceModal(this.currentUser.invoiceData);
      }
    } else if (this.invoiceCheckboxValue && !this.currentUser) {
      this.invoicePaymentError = true;
      this.invoiceCheckboxValue = false;
    }
    if (!this.invoiceCheckboxValue && this.currentUser) {
      this.store.dispatch(new SelectedInvoiceActions.ClearSelectedInvoice());
    }
  }

  async presentSelectInvoiceModal(userInvoices) {
    this.selectInvoiceModal = await this.modalCtrl.create({
      component: InvoiceListModalComponent,
      cssClass: "invoiceListModal",
      backdropDismiss: false,
    });

    await this.selectInvoiceModal.present();
    this.selectInvoiceModal.onDidDismiss().then((invoice) => {
      console.log("on dismiss", invoice);
      this.selectInvoiceModal = null;

      if (!this.selectedCustomerInvoice) {
        this.invoiceCheckboxValue = false;
      }
    });
  }

  async presentAddEditModal(addOrEdit, invoice) {
    this.invoiceAddEditModal = await this.modalCtrl.create({
      component: InvoiceAddEditModalComponent,
      cssClass: "invoiceAddEditModalCss",
      componentProps: {
        addOrEdit: addOrEdit,
        invoiceForEdit: invoice,
      },
      backdropDismiss: false,
    });

    await this.invoiceAddEditModal.present();
    this.invoiceAddEditModal.onDidDismiss().then((invoice: any) => {
      if (
        invoice &&
        invoice.vatNumber &&
        invoice.vatOffice &&
        invoice.company_name &&
        invoice.company_address &&
        invoice.activity
      ) {
        invoice.enabled = true;
        console.log("set selected invoice from invoice component");
        this.store.dispatch(
          new SelectedInvoiceActions.SetSelectedInvoice(_.cloneDeep(invoice))
        );
        this.store
          .select(selectors.getStoreInfo)
          .pipe(first())
          .subscribe((storeInfo) => {
            if (storeInfo && storeInfo.vatBasedCustomerProductsActive) {
              this.setMigrationCustomerId(invoice.migration_customer_id);
            }
          })
          .unsubscribe();
        this.checkForCustomerInvoices(null);
      }
      this.invoiceAddEditModal = null;
      if (!this.selectedCustomerInvoice) {
        this.invoiceCheckboxValue = false;
      }
    });
  }

  invoiceValidation() {
    this.valid = true;
    if (
      this.store_info &&
      this.store_info.customerInvoiceRequired &&
      (!this.selectedCustomerInvoice ||
        (this.selectedCustomerInvoice &&
          !this.selectedCustomerInvoice.vatNumber))
    ) {
      this.valid = false;
    } else {
      if (
        this.invoiceCheckboxValue &&
        (!this.selectedCustomerInvoice ||
          (this.selectedCustomerInvoice &&
            !this.selectedCustomerInvoice.vatNumber))
      ) {
        this.valid = false;
      }
    }
  }

  completeOrderInvoiceValidations() {
    this.valid = true;
    let validationObject = {
      id: "invoice",
      valid: true,
      errorMessages: [],
      isRequired: false,
    };

    if (
      this.store_info &&
      this.store_info.customerInvoiceRequired &&
      (!this.selectedCustomerInvoice ||
        (this.selectedCustomerInvoice &&
          !this.selectedCustomerInvoice.vatNumber))
    ) {
      this.valid = false;
      validationObject.errorMessages.push(
        "cart.cart-components.invoice.invoice-is-required"
      );
      validationObject.valid = false;
      this.valid = false;
      validationObject.isRequired = true;
    } else {
      if (
        this.invoiceCheckboxValue &&
        (!this.selectedCustomerInvoice ||
          (this.selectedCustomerInvoice &&
            !this.selectedCustomerInvoice.vatNumber))
      ) {
        validationObject.errorMessages.push(
          "cart.cart-components.invoice.please-select-a-valid-invoice"
        );
        validationObject.valid = false;
        this.valid = false;
      }
    }

    this.store.dispatch(
      new CompleteOrderValidationActions.AddUpdateCompleteOrderValidation(
        _.cloneDeep(validationObject)
      )
    );
  }

  ngOnDestroy() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.selectInvoiceModal) {
      this.selectInvoiceModal.dismiss();
      this.selectInvoiceModal = null;
    }
    if (this.invoiceAddEditModal) {
      this.invoiceAddEditModal.dismiss();
      this.invoiceAddEditModal = null;
    }
    console.log("destroyed");
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    this.subscriptions = [];
  }
}
