import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import * as _ from "lodash";
import * as fromApp from "../../store/app.reducer";
import { Store } from "@ngrx/store";
import { auditTime, distinctUntilChanged } from "rxjs/operators";
import { Subscription } from "rxjs";
import { OrderNowPopupComponent } from "src/app/popups/order-now-popup/order-now-popup.component";
import { SelectStorePopupComponent } from "src/app/popups/select-store-popup/select-store-popup.component";
import { ModalController } from "@ionic/angular";
import * as DeliveryMethodAddressActions from "../../store/deliveryMethodAddress/deliveryMethodAddress.actions";
import { Router } from "@angular/router";
import { OrderNowModalPopupsService } from "src/app/services/orderNowModalPopupsService/orderNowModalPopups.service";
import moment from "moment";
import { AnimationOptions } from "ngx-lottie";

@Component({
  selector: "app-history",
  templateUrl: "./history.component.html",
  styleUrls: ["./history.component.scss"],
})
export class HistoryComponent implements OnInit, OnDestroy, OnChanges {
  @Input() userPoints: any;
  public currentUser: any;
  public groupApp: any;
  public checkAddressLoading: boolean = false;
  public orderPickup: boolean;
  public dineIn: boolean;
  public stores: any;
  public selectedDineInTable: any;
  public currentStoreId: any;
  public currentStore: any;
  public selectedAddress: any;
  public showDeliveryButton: boolean;
  public showPickupButton: boolean;
  public showDineInButton: boolean;
  public cartProducts: any;
  public selectedLangugage: any;

  private infoShowDeliveryButton;
  private animationInstance: any;
  private subscriptions: Subscription[] = [];
  private selectStoreModal: any;
  private orderNowModal: any;

  historyAnimationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/history-animation.json",
  };

  constructor(
    private store: Store<fromApp.AppState>,
    private modalController: ModalController,
    private router: Router,
    private orderNowModalPopupsService: OrderNowModalPopupsService,

    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.userPoints &&
      !_.isEqual(
        changes.userPoints.currentValue,
        changes.userPoints.previousValue
      )
    ) {
      this.initializeExpirationDate();
    }
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (state && state.user && !_.isEqual(state.user, this.currentUser)) {
            this.currentUser = _.cloneDeep(state.user);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("cart")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && !_.isEqual(state.products, this.cartProducts)) {
            this.cartProducts = _.cloneDeep(state.products);
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(state.groupApp, this.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe(async (state) => {
          if (state && state.stores && !_.isEqual(this.stores, state.stores)) {
            this.stores = _.cloneDeep(state.stores);
            this.showDeliveryButton = !_.isEmpty(
              _.filter(this.stores, (store) => {
                if (!store.disableOrderDelivery) {
                  return store;
                }
              })
            );

            if (!this.showDeliveryButton) {
              this.infoShowDeliveryButton =
                await this.orderNowModalPopupsService.checkToShowInfoDeliveryButton();

              this.infoShowDeliveryButton = !!this.infoShowDeliveryButton;
            }

            this.showPickupButton = !_.isEmpty(
              _.filter(this.stores, (store) => {
                if (!store.disableOrderPickup) {
                  return store;
                }
              })
            );
            console.log("pickup button", this.showPickupButton);

            this.showDineInButton = !_.isEmpty(
              _.filter(this.stores, (store) => {
                if (store.dinein) {
                  return store;
                }
              })
            );
            console.log("dine in butto");
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("currentStore")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((state) => {
          if (
            state &&
            state.currentStoreId &&
            !_.isEqual(this.currentStoreId, state.currentStoreId)
          ) {
            this.currentStoreId = _.cloneDeep(state.currentStoreId);
            this.store
              .select("stores")
              .subscribe((state) => {
                if (state && state.stores) {
                  this.currentStore = _.cloneDeep(
                    _.find(state.stores, {
                      store_id: this.currentStoreId,
                    })
                  );
                }
              })
              .unsubscribe();
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.selectedLangugage &&
            !_.isEqual(this.selectedLangugage, state.selectedLangugage)
          ) {
            this.selectedLangugage = _.cloneDeep(state.selectedLangugage);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("deliveryMethodAddress")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (state && !_.isEqual(state.orderPickup, this.orderPickup)) {
            this.orderPickup = _.cloneDeep(state.orderPickup);
          }
          if (state && !_.isEqual(state.dineIn, this.dineIn)) {
            this.dineIn = _.cloneDeep(state.dineIn);
          }
          if (
            state &&
            state.selectedAddress &&
            !_.isEqual(state.selectedAddress, this.selectedAddress)
          ) {
            this.selectedAddress = _.cloneDeep(state.selectedAddress);
          }
          if (
            state &&
            !_.isEqual(this.selectedDineInTable, state.selectedDineInTable)
          ) {
            this.selectedDineInTable = _.cloneDeep(state.selectedDineInTable);
          }
          if (
            state &&
            !_.isEqual(state.selectedAddressLoading, this.checkAddressLoading)
          ) {
            this.checkAddressLoading = _.cloneDeep(
              state.selectedAddressLoading
            );
          }
        })
    );

    this.initializeExpirationDate();
  }

  initializeExpirationDate() {
    if (this.userPoints && this.userPoints.records) {
      console.log("initialize exp date run");
      _.each(this.userPoints.records, (record) => {
        console.log("record", record);
        if (record && record.expiration) {
          let expDate = moment(_.cloneDeep(parseInt(record.expiration)));
          let afterAyear = moment().set("year", moment().get("year") + 1);
          // console.log("expDate", expDate);
          // console.log("afterAyear", afterAyear);
          if (
            expDate.isBefore(afterAyear) &&
            record.points &&
            record.points - (record.used_points ? record.used_points : 0) !==
              0 &&
            record.status === "active"
          ) {
            record.showExpDate = true;
          } else {
            record.showExpDate = false;
          }
        }
      });
      this.changeDetector.detectChanges();
    }
  }

  animationCreated(ev) {
    console.log("animation created");
    if (!this.animationInstance) {
      this.animationInstance = ev;
      this.animationInstance.setSpeed(0.7);
    }
  }

  async presentSelectStoreModal() {
    let cssClass = "selectStorePopupCss";
    if (this.stores && this.stores.length > 1 && this.stores.length <= 3) {
      cssClass = "selectStorePopupCssThreeStores";
    } else if (this.stores && this.stores.length > 3) {
      cssClass = "selectStorePopupCssMultipleStores";
    }

    this.selectStoreModal = await this.modalController.create({
      component: SelectStorePopupComponent,
      cssClass: cssClass,
      backdropDismiss: false,
    });

    await this.selectStoreModal.present();
    this.selectStoreModal.onDidDismiss().then(() => {
      this.selectStoreModal = null;
    });
  }

  checkTheCatalog() {
    if (
      this.stores &&
      this.stores.length > 1 &&
      this.orderPickup == null &&
      this.dineIn == null
    ) {
      this.presentSelectStoreModal();
    } else {
      // this.store.dispatch(
      //   new CurrentStoreActions.SetCurrentStoreId(this.stores[0].store_id)
      // );
      // if (navigator.cookieEnabled) {
      //   window.localStorage.setItem("store_id", this.currentStoreId);
      // }

      this.router.navigateByUrl("/catalog", {
        replaceUrl: true,
      });
    }
  }

  async presentOrderNowModal() {
    this.store.dispatch(
      new DeliveryMethodAddressActions.SetNavigationOnDeliveryMethodChanged(
        true
      )
    );
    if (!this.showPickupButton && !this.showDineInButton) {
      this.orderNowModalPopupsService.checkAndOpenSettingsModal(
        "delivery",
        this.currentUser,
        this.selectedAddress,
        this.stores,
        this.currentStoreId,
        true,
        false
      );
    } else if (
      !this.showPickupButton &&
      !this.showDeliveryButton &&
      !this.infoShowDeliveryButton
    ) {
      this.orderNowModalPopupsService.checkAndOpenSettingsModal(
        "dine-in",
        this.currentUser,
        this.selectedAddress,
        this.stores,
        this.currentStoreId,
        true,
        false
      );
    } else if (
      !this.showDineInButton &&
      !this.showDeliveryButton &&
      !this.infoShowDeliveryButton
    ) {
      this.orderNowModalPopupsService.checkAndOpenSettingsModal(
        "take-away",
        this.currentUser,
        this.selectedAddress,
        this.stores,
        this.currentStoreId,
        true,
        false
      );
    } else {
      this.orderNowModal = await this.modalController.create({
        component: OrderNowPopupComponent,
        cssClass: "orderNowPopupCss",
        backdropDismiss: true,
      });

      await this.orderNowModal.present();
      this.orderNowModal.onDidDismiss().then(() => {
        this.orderNowModal = null;
      });
    }
  }

  ngOnDestroy() {
    this.userPoints = null;
    if (this.orderNowModal) {
      this.orderNowModal.dismiss();
      this.orderNowModal = null;
    }
    if (this.selectStoreModal) {
      this.selectStoreModal.dismiss();
      this.selectStoreModal = null;
    }
    if (this.subscriptions && !_.isEmpty(this.subscriptions)) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }
}
