<ion-card
  style="margin: 10px"
  class="points-card-border"
  button
  *ngIf="
    groupApp &&
    groupApp.pointsSystem &&
    loginState &&
    (loginState === 'no_login' || loginState === 'login_completed')
  "
  (click)="navigateToPoints()"
>
  <ion-list class="ion-no-margin ion-no-padding">
    <ion-item
      color="homepageButtons noPaddingInlineEnd"
      class="ion-no-padding"
      lines="none"
      *ngIf="!currentUser"
    >
      <ion-label class="ion-text-wrap ion-text-center">
        <div
          style="padding: 2px"
          [class]="cartCatalogMode ? 'fontSizeSmall' : ''"
          [innerHTML]="groupApp.points.homePageMoto"
        ></div>
      </ion-label>
    </ion-item>
    <ion-item
      color="homepageButtons"
      lines="none"
      *ngIf="
        currentUser &&
        userPoints &&
        highestReward &&
        highestReward.user_points_percent !== 100
      "
    >
      <ion-label
        [class]="
          cartCatalogMode ? 'fontSizeSmall ion-text-center' : 'ion-text-center'
        "
      >
        {{ highestReward.title }}</ion-label
      >
    </ion-item>
    <ion-item
      color="homepageButtons"
      lines="none"
      *ngIf="
        currentUser &&
        userPoints &&
        highestReward &&
        highestReward.user_points_percent === 100
      "
    >
      <ion-label
        [class]="
          cartCatalogMode ? 'fontSizeSmall ion-text-center' : 'ion-text-center'
        "
      >
        {{ "homepage.point-system.your-points" | translate }}:
        {{ userPoints.pointsSystem.active }}
      </ion-label>
    </ion-item>
    <ion-item
      color="homepageButtons"
      lines="none"
      class="item"
      *ngIf="currentUser && userPoints && userPoints.pointsSystem.pending > 0"
    >
      <ion-label
        [class]="
          cartCatalogMode ? 'fontSizeSmall ion-text-center' : 'ion-text-center'
        "
      >
        {{ userPoints.pointsSystem.pending }} πόντοι σε αναμονή</ion-label
      >
    </ion-item>
    <ion-item
      color="homepageButtons"
      lines="none"
      *ngIf="
        currentUser &&
        highestReward &&
        highestReward.user_points_percent !== 100
      "
    >
      <div
        style="width: 100%; margin-bottom: 12px"
        [class]="
          cartCatalogMode ? 'progressbarSmall fontSizeSmall' : 'progressbar'
        "
        *ngIf="highestReward.user_points_percent"
      >
        <div [ngStyle]="{ width: highestReward.user_points_percent + '%' }">
          <!-- <span>{{
            highestReward.user_points_percent >= 60
              ? userPoints.pointsSystem.active + "/" + highestReward.points
              : ""
          }}</span> -->
          <span>
            <ion-text
              [color]="
                highestReward.user_points_percent >= 50 &&
                highestReward.user_points_percent < 100
                  ? 'black'
                  : highestReward.user_points_percent < 50
                  ? 'white'
                  : 'white'
              "
              >{{ highestReward.user_points_percent }}%</ion-text
            >
          </span>
        </div>
      </div>
    </ion-item>

    <ion-row
      class="ion-text-center"
      *ngIf="
        highestReward &&
        highestReward.user_points_percent === 100 &&
        currentUser
      "
    >
      <ion-col size="12">
        <ion-button
          [size]="cartCatalogMode ? 'small' : 'medium'"
          (click)="getTheCoupon()"
          color="homepageButtons"
        >
          {{ "homepage.point-system.cash-out-points" | translate }}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-list>
</ion-card>
