import { Action } from "@ngrx/store";

export const SET_STATIC_PAGES = "[STATIC PAGES] Set Static Pages";

export const CLEAR_STATIC_PAGES = "[STATIC PAGES] Clear Static Pages";

export class SetStaticPages implements Action {
  readonly type = SET_STATIC_PAGES;
  constructor(public payload: any) {}
}

export class ClearStaticPages implements Action {
  readonly type = CLEAR_STATIC_PAGES;
  constructor() {}
}

export type StaticPagesActions = SetStaticPages | ClearStaticPages;
