import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class DeployService {
  private progress = 0;
  constructor() {}

  getUpdateProgress(): number {
    return this.progress;
  }

  setUpdateProgress(progress: number) {
    this.progress = progress;
  }
}
