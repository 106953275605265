import {
  AfterContentChecked,
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { IonSlides, ModalController } from "@ionic/angular";
import { from, Observable, Subscription } from "rxjs";
import * as _ from "lodash";
import { auditTime, distinctUntilChanged } from "rxjs/operators";
import * as fromApp from "../../../store/app.reducer";
import * as selectors from "../../../store/app.selectors";
import { Store } from "@ngrx/store";
import { ProductDetailsComponent } from "src/app/product-details/product-details.component";

@Component({
  selector: "app-product-items-popular-category",
  templateUrl: "./product-items-popular-category.component.html",
  styleUrls: ["./product-items-popular-category.component.scss"],
})
export class ProductItemsPopularCategoryComponent
  implements OnInit, AfterViewChecked, OnDestroy
{
  @Input() category_id: any;
  @Input() groupStoreData;
  @Input() selectedDineInTable;
  @Input() selectedPreorderDate;
  @ViewChild("productsSlider") slider: IonSlides;

  public isBeginning: Observable<boolean>;
  public isEnd: Observable<boolean>;
  public category: any;
  public categoriesSlider: any;
  public browserPlatform: any;
  public groupApp: any;
  public webp: any;
  public orderPickup: any;
  public dineIn: any;

  public sliderOptions = {
    allowTouchMove: true,
    slidesPerView: "auto",
    direction: "horizontal",
    slideToClickedSlide: true,
    mousewheel: true,
    freeMode: true,
    freeModeMomentumBounce: false,

    // autoplay:true,
    spaceBetween: 2,

    watchSlidesVisibility: true,
  };

  private subscriptions: Subscription[] = [];
  constructor(
    private store: Store<fromApp.AppState>,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    // console.log("popular category component");
    this.subscriptions.push(
      this.store
        .select(selectors.getCategoryByCategoryId(this.category_id))
        .pipe(distinctUntilChanged())

        .subscribe((category) => {
          if (category && !_.isEqual(category, this.category)) {
            let tempCategory = _.cloneDeep(category);
            _.each(tempCategory.products, (product) => {
              if (
                product &&
                (!product.availableTotal || !category.availableTotal)
              ) {
                product.tags = [];
              } else {
                if (product.tags) {
                  let keys = _.keys(product.tags);

                  const fistKey = keys[0].toString();

                  if (fistKey) {
                    product.tags = {};
                    product.tags[fistKey] = true;
                  }
                }
              }
            });
            this.category = _.cloneDeep(tempCategory);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("platformInfo")
        .pipe(auditTime(200))
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.browserPlatform, state.browserPlatform)
          ) {
            this.browserPlatform = _.cloneDeep(state.browserPlatform);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("deliveryMethodAddress")

        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && !_.isEqual(this.dineIn, state.dineIn)) {
            this.dineIn = _.cloneDeep(state.dineIn);
          }
          if (state && !_.isEqual(this.orderPickup, state.orderPickup)) {
            this.orderPickup = _.cloneDeep(state.orderPickup);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(auditTime(200))
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(this.groupApp, state.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
          }
          if (state && state.webp && !_.isEqual(this.webp, state.webp)) {
            this.webp = _.cloneDeep(state.webp);
          }
        })
    );
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  productTrack(index, item) {
    return item.product_id;
  }

  nextSlide() {
    if (this.slider) {
      this.slider.slideNext();
    }
  }

  prevSlide() {
    if (this.slider) {
      this.slider.slidePrev();
    }
  }

  updateSliderIconState() {
    this.isEnd = from(this.slider.isEnd());
    this.isBeginning = from(this.slider.isBeginning());
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }
}
