import * as HyperCategoriesActions from "./hyperCategories.actions";
import * as _ from "lodash";

export interface State {
  allCategories: any; //only for hyper categories
  hyperCategories: any;
  currentHyperCategory: any;
}

const initialState: State = {
  allCategories: null, //only for hyper categories
  hyperCategories: null,
  currentHyperCategory: null,
};

export function hyperCategoriesReducer(
  state = initialState,
  action: HyperCategoriesActions.HyperCategoriesActions
) {
  switch (action.type) {
    //only for hyper categories
    case HyperCategoriesActions.SET_ALL_CATEGORIES:
      return {
        ...state,
        allCategories: action.payload,
      };

    case HyperCategoriesActions.CLEAR_ALL_CATEGORIES:
      return {
        ...state,
        allCategories: null,
      };
    case HyperCategoriesActions.SET_HYPER_CATEGORIES:
      return {
        ...state,
        hyperCategories: action.payload,
      };
    case HyperCategoriesActions.SET_CURRENT_HYPER_CATEGORY:
      return {
        ...state,
        currentHyperCategory: action.payload,
      };

    case HyperCategoriesActions.CLEAR_HYPER_CATEGORIES:
      return {
        ...state,
        hyperCategories: null,
      };

    default:
      return state;
  }
}
