<span *ngIf="productItem && productItem.hideOtherDeliveryMethods">
  <ion-badge
    lines="none"
    color="danger"
    *ngIf="
      productItem &&
      productItem.product_categories_availability_check &&
      productItem.availableTotal &&
      productItem.activeTotal &&
      productItem.pickup &&
      !productItem.delivery &&
      !productItem.dinein &&
      !orderPickup
    "
  >
    <ion-text> {{ "product-details.only-for-pick-up" | translate }}</ion-text>
  </ion-badge>

  <ion-badge
    lines="none"
    color="danger"
    *ngIf="
      productItem &&
      productItem.product_categories_availability_check &&
      productItem.availableTotal &&
      productItem.activeTotal &&
      productItem.delivery &&
      !productItem.dinein &&
      !productItem.pickup &&
      (orderPickup || dineIn || (dineIn === null && orderPickup === null))
    "
  >
    <ion-text> {{ "product-details.only-for-delivery" | translate }}</ion-text>
  </ion-badge>

  <ion-badge
    lines="none"
    color="danger"
    *ngIf="
      productItem &&
      productItem.product_categories_availability_check &&
      productItem.availableTotal &&
      productItem.activeTotal &&
      productItem.dinein &&
      !productItem.delivery &&
      !productItem.pickup &&
      !dineIn
    "
  >
    <ion-text> {{ "product-details.only-for-dinein" | translate }}</ion-text>
  </ion-badge>

  <ion-badge
    lines="none"
    color="danger"
    *ngIf="
      productItem &&
      productItem.product_categories_availability_check &&
      productItem.availableTotal &&
      productItem.activeTotal &&
      productItem.dinein &&
      productItem.delivery &&
      !productItem.pickup &&
      (orderPickup || (dineIn === null && orderPickup === null))
    "
  >
    <ion-text>
      {{ "product-details.only-for-delivery-and-dinein" | translate }}</ion-text
    >
  </ion-badge>

  <ion-badge
    lines="none"
    color="danger"
    *ngIf="
      productItem &&
      productItem.product_categories_availability_check &&
      productItem.availableTotal &&
      productItem.activeTotal &&
      productItem.delivery &&
      productItem.pickup &&
      !productItem.dinein &&
      (dineIn || (dineIn === null && orderPickup === null))
    "
  >
    <ion-text>
      {{
        "product-details.only-for-delivery-and-takeaway" | translate
      }}</ion-text
    >
  </ion-badge>

  <ion-badge
    lines="none"
    color="danger"
    *ngIf="
      productItem &&
      productItem.product_categories_availability_check &&
      productItem.availableTotal &&
      productItem.activeTotal &&
      !productItem.delivery &&
      productItem.pickup &&
      productItem.dinein &&
      !orderPickup &&
      !dineIn
    "
  >
    <ion-text>
      {{ "product-details.only-for-takeaway-and-dinein" | translate }}</ion-text
    >
  </ion-badge>
</span>

<span *ngIf="productItem && !productItem.hideOtherDeliveryMethods">
  <ion-badge
    lines="none"
    color="danger"
    *ngIf="
      productItem &&
      productItem.product_categories_availability_check &&
      productItem.availableTotal &&
      productItem.activeTotal &&
      productItem.pickup &&
      !productItem.delivery &&
      !productItem.dinein &&
      orderPickup == false
    "
  >
    <ion-text> {{ "product-details.only-for-pick-up" | translate }}</ion-text>
  </ion-badge>

  <ion-badge
    lines="none"
    color="danger"
    *ngIf="
      productItem &&
      productItem.product_categories_availability_check &&
      productItem.availableTotal &&
      productItem.activeTotal &&
      productItem.delivery &&
      !productItem.dinein &&
      !productItem.pickup &&
      (orderPickup || dineIn)
    "
  >
    <ion-text> {{ "product-details.only-for-delivery" | translate }}</ion-text>
  </ion-badge>

  <ion-badge
    lines="none"
    color="danger"
    *ngIf="
      productItem &&
      productItem.product_categories_availability_check &&
      productItem.availableTotal &&
      productItem.activeTotal &&
      productItem.dinein &&
      !productItem.delivery &&
      !productItem.pickup &&
      dineIn == false
    "
  >
    <ion-text> {{ "product-details.only-for-dinein" | translate }}</ion-text>
  </ion-badge>

  <ion-badge
    lines="none"
    color="danger"
    *ngIf="
      productItem &&
      productItem.product_categories_availability_check &&
      productItem.availableTotal &&
      productItem.activeTotal &&
      productItem.dinein &&
      productItem.delivery &&
      !productItem.pickup &&
      orderPickup
    "
  >
    <ion-text>
      {{ "product-details.only-for-delivery-and-dinein" | translate }}</ion-text
    >
  </ion-badge>

  <ion-badge
    lines="none"
    color="danger"
    *ngIf="
      productItem &&
      productItem.product_categories_availability_check &&
      productItem.availableTotal &&
      productItem.activeTotal &&
      productItem.delivery &&
      productItem.pickup &&
      !productItem.dinein &&
      dineIn
    "
  >
    <ion-text>
      {{
        "product-details.only-for-delivery-and-takeaway" | translate
      }}</ion-text
    >
  </ion-badge>

  <ion-badge
    lines="none"
    color="danger"
    *ngIf="
      productItem &&
      productItem.product_categories_availability_check &&
      productItem.availableTotal &&
      productItem.activeTotal &&
      !productItem.delivery &&
      productItem.pickup &&
      productItem.dinein &&
      !orderPickup &&
      dineIn == false
    "
  >
    <ion-text>
      {{ "product-details.only-for-takeaway-and-dinein" | translate }}</ion-text
    >
  </ion-badge>
</span>
