import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  AlertController,
  LoadingController,
  ModalController,
  Platform,
} from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { AnimationOptions } from "ngx-lottie";
import { auditTime, distinctUntilChanged } from "rxjs/operators";
import * as fromApp from "../../store/app.reducer";
import * as selectors from "../../store/app.selectors";
import * as _ from "lodash";
import { Subscription } from "rxjs";
import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";

declare let window: any;

@Component({
  selector: "app-navigation-to-bank-modal",
  templateUrl: "./navigation-to-bank-modal.component.html",
  styleUrls: ["./navigation-to-bank-modal.component.scss"],
})
export class NavigationToBankModalComponent implements OnInit, OnDestroy {
  @Input() order_id;
  @Input() store_id;
  @Input() bank_link: any;
  @Input() type;
  public device;

  private orderArray: any;
  private reservationArray: any;
  private current_order: any;
  private current_reservation: any;
  private sub: Subscription;
  // options: AnimationOptions = {
  //   path: "https://data.deliverymanager.gr/animations/navigation-to-bank-lottie.json",
  // };
  options: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/navigation-to-bank-lottie.json",
  };

  constructor(
    private modalController: ModalController,
    private store: Store<fromApp.AppState>
  ) {}

  closeModal() {
    this.modalController.dismiss();
  }

  ngOnInit() {
    if (this.type === "reservation") {
      this.sub = this.store
        .select("reservations")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.reservations &&
            !_.isEqual(this.reservationArray, state.reservations)
          ) {
            this.reservationArray = _.cloneDeep(state.reservations);

            this.current_reservation = _.find(
              _.cloneDeep(this.reservationArray),
              {
                res_id: this.order_id,
                store_id: this.store_id,
              }
            );
            if (
              this.current_reservation &&
              this.current_reservation.res_status &&
              (this.current_reservation.res_status === "pending" ||
                this.current_reservation.res_status === "complete")
            ) {
              this.closeModal();
            }
          }
        });
    } else {
      this.sub = this.store
        .select("orders")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.ordersArray &&
            !_.isEqual(this.orderArray, state.ordersArray)
          ) {
            this.orderArray = _.cloneDeep(state.ordersArray);

            this.current_order = _.find(_.cloneDeep(this.orderArray), {
              order_id: this.order_id,
              store_id: this.store_id,
            });
            if (
              this.current_order &&
              this.current_order.status &&
              (this.current_order.status === "pending" ||
                this.current_order.status === "accepted" ||
                this.current_order.status === "complete")
            ) {
              this.closeModal();
            }
          }
        });
    }
    if (Capacitor.isNativePlatform()) {
      this.device = "mobile";
    } else {
      this.device = "browser";
    }
  }

  animationCreated(ev) {}

  tryAgain() {
    if (this.bank_link) {
      this.openLink(this.bank_link);
    }
  }

  async openLink(link) {
    await Browser.open({ url: link });
  }

  ngOnDestroy(): void {
    this.order_id = null;
    this.store_id = null;
    this.current_order = null;
    this.orderArray = null;

    if (this.sub) {
      this.sub.unsubscribe();
      this.sub = null;
    }
  }
}
