<ion-header>
  <ion-toolbar mode="md">
    <ion-buttons slot="end">
      <ion-button color="dark" fill="clear" (click)="closeModal()">
        <ion-icon src="assets/ionicons/close.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title size="small">
      <ion-text >Edit Image</ion-text>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-row>
    <ion-col>
      <app-photo-cropper>

      </app-photo-cropper>
    </ion-col>
  </ion-row>
</ion-content>
