<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">
      <ion-text *ngIf="orderPickup == true && dineIn == false">
        {{ "popups.pre-order-popup.title-take-away" | translate }}
      </ion-text>
      <ion-text *ngIf="orderPickup == false && dineIn == false">
        {{ "popups.pre-order-popup.title-delivery" | translate }}
      </ion-text>
      <ion-text *ngIf="orderPickup == null && dineIn == null">
        {{ "popups.pre-order-popup.title-delivery" | translate }}
      </ion-text>
    </ion-title>
    <ion-button
      (click)="closeModal()"
      size="small"
      slot="end"
      fill="clear"
      color="dark"
    >
      <ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-segment
    [(ngModel)]="segment"
    (ionChange)="onSegmentChange($event)"
    mode="ios"
  >
    <ion-segment-button
      [class]="!preorderMode ? 'selected-segment' : ''"
      value="orderNow"
    >
      {{
        orderPickup == true && dineIn == false
          ? ("popups.pre-order-popup.instantOrderTakeAway" | translate)
          : orderPickup == false && dineIn == false
          ? ("popups.pre-order-popup.instantOrderDelivery" | translate)
          : ("popups.pre-order-popup.instantOrder" | translate)
      }}
    </ion-segment-button>
    <ion-segment-button
      [class]="preorderMode === true ? 'selected-segment' : ''"
      value="preOrder"
    >
      {{ "popups.pre-order-popup.preOrder" | translate }}
    </ion-segment-button>
  </ion-segment>

  <ion-grid *ngIf="loading">
    <ion-row style="width: 100%">
      <ion-col class="ion-text-center">
        <ion-spinner></ion-spinner>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid *ngIf="!loading">
    <ion-list *ngIf="!preorderMode">
      <ion-item lines="none">
        <ion-label
          class="ion-text-center ion-text-wrap"
          style="font-weight: bold"
        >
          <ion-text
            *ngIf="
              checkStoreOnlineInfo && orderPickup == true && dineIn == false
            "
          >
            {{
              !checkStoreOnlineInfo.pickupDeliveryTime
                ? ("popups.pre-order-popup.order-will-be-delivered-as-soon-as-possible-takeaway"
                  | translate)
                : pickUpTimeIsNumber
                ? ("popups.pre-order-popup.order-will-be-ready-in"
                    | translate) +
                  " " +
                  checkStoreOnlineInfo.pickupDeliveryTime +
                  " " +
                  ("minutes" | translate)
                : checkStoreOnlineInfo.pickupDeliveryTime
            }}
          </ion-text>
          <ion-text
            *ngIf="
              checkStoreOnlineInfo && orderPickup == false && dineIn == false
            "
          >
            {{
              !checkStoreOnlineInfo.deliveryTime
                ? ("popups.pre-order-popup.order-will-be-delivered-as-soon-as-possible-takeaway"
                  | translate)
                : deliveryTimeIsNumber
                ? ("popups.pre-order-popup.order-will-be-delivered-in"
                    | translate) +
                  " " +
                  checkStoreOnlineInfo.deliveryTime +
                  " " +
                  ("minutes" | translate)
                : checkStoreOnlineInfo.deliveryTime
            }}
          </ion-text>
          <ion-text
            *ngIf="
              checkStoreOnlineInfo && orderPickup == null && dineIn == null
            "
          >
            {{
              "popups.pre-order-popup.order-will-be-delivered-as-soon-as-possible-delivery"
                | translate
            }}
          </ion-text>
        </ion-label>
      </ion-item>
    </ion-list>
    <ion-list *ngIf="preorderMode">
      <ion-item
        *ngIf="
          ((showDeliveryButton && showPickupButton) ||
            (showDeliveryButton && showDineInButton) ||
            (showPickupButton && showDineInButton)) &&
          dineIn === null &&
          orderPickup === null
        "
      >
        <ion-label>
          {{ "popups.pre-order-popup.select-method" | translate }}
        </ion-label>

        <ion-select
          [class]="!preorderDeliveryMethod ? 'selectPlaceHolderDanger' : ''"
          okText="{{ 'select' | translate }}"
          cancelText="{{ 'cancel' | translate }}"
          (ionChange)="changeMethod()"
          placeholder="{{ 'please-select' | translate }}"
          [(ngModel)]="preorderDeliveryMethod"
        >
          <ion-select-option
            *ngIf="showDeliveryButton"
            [disabled]="!preorderDeliveryDays || !preorderDeliveryDays.length"
            value="delivery"
            >Delivery
          </ion-select-option>
          <ion-select-option
            *ngIf="showPickupButton"
            [disabled]="!preorderPickupDays || !preorderPickupDays.length"
            value="takeaway"
            >Take away</ion-select-option
          >
          <ion-select-option
            *ngIf="showDineInButton"
            [disabled]="!preorderDineinDays || !preorderDineinDays.length"
            value="dinein"
            >Dine in</ion-select-option
          >
        </ion-select>
      </ion-item>
      <ion-item
        [hidden]="preorderDeliveryDays && preorderDeliveryDays.length === 1"
        *ngIf="preorderDeliveryMethod"
      >
        <ion-label>
          <ion-label>
            {{ "popups.pre-order-popup.select-date" | translate }}
          </ion-label>
        </ion-label>
        <ion-select
          [class]="!selectedDate ? 'selectPlaceHolderDanger' : ''"
          placeholder="{{ 'please-select' | translate }}"
          okText="{{ 'select' | translate }}"
          cancelText="{{ 'cancel' | translate }}"
          (ionChange)="selectDate()"
          [(ngModel)]="selectedDate"
        >
          <span *ngIf="preorderDeliveryMethod === 'delivery'">
            <ion-select-option
              *ngFor="let date of preorderDeliveryDays"
              [value]="date.day"
              [disabled]="
                date && date.day_schedule && !date.day_schedule.active
              "
              >{{ date.day | preorderDay }}</ion-select-option
            >
          </span>
          <span *ngIf="preorderDeliveryMethod === 'takeaway'">
            <ion-select-option
              [value]="date.day"
              [disabled]="
                date && date.day_schedule && !date.day_schedule.active
              "
              *ngFor="let date of preorderPickupDays"
              >{{ date.day | preorderDay }}</ion-select-option
            >
          </span>
          <span *ngIf="preorderDeliveryMethod === 'dinein'">
            <ion-select-option
              [value]="date.day"
              [disabled]="
                date && date.day_schedule && !date.day_schedule.active
              "
              *ngFor="let date of preorderDineinDays"
              >{{ date.day | preorderDay }}</ion-select-option
            >
          </span>
        </ion-select>
      </ion-item>

      <ion-item
        *ngIf="selectedDateSchedule && selectedDateSchedule.schedule_comment"
      >
        <ion-label
          style="line-height: 1.1"
          class="ion-text-center ion-text-wrap"
        >
          <ion-text color="danger" style="font-size: small">
            <strong *ngIf="preorderDeliveryMethod"
              >{{ preorderDeliveryMethod | translate }}:</strong
            >
            {{ selectedDateSchedule.schedule_comment }}
          </ion-text>
        </ion-label>
      </ion-item>

      <ion-item
        [hidden]="
          (preorderAcceptDisableDeliveryTime &&
            preorderDeliveryMethod === 'delivery') ||
          (preorderAcceptDisablePickupTime &&
            preorderDeliveryMethod === 'takeaway')
        "
        *ngIf="selectedDate && availableHours"
      >
        <ion-label
          [class]="
            availableHours && availableHours.length
              ? ''
              : 'ion-text-wrap ion-text-center'
          "
        >
          <ion-text *ngIf="availableHours && availableHours.length">
            {{ "popups.pre-order-popup.select-hour" | translate }}
          </ion-text>
          <ion-text
            style="font-size: small; font-weight: bold"
            color="danger"
            *ngIf="!availableHours || !availableHours.length"
          >
            {{
              "popups.pre-order-popup.there-are-no-available-hours" | translate
            }}
          </ion-text>
        </ion-label>

        <ion-select
          *ngIf="availableHours && availableHours.length"
          [class]="!selectedHour ? 'selectPlaceHolderDanger' : ''"
          placeholder="{{ 'please-select' | translate }}"
          okText="{{ 'select' | translate }}"
          cancelText="{{ 'cancel' | translate }}"
          [(ngModel)]="selectedHour"
        >
          <span>
            <ion-select-option
              [value]="time_slot"
              *ngFor="let time_slot of availableHours"
              >{{ time_slot }}
            </ion-select-option>
          </span>
        </ion-select>
      </ion-item>
    </ion-list>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-grid>
    <ion-row style="width: 100%">
      <ion-col class="ion-text-center">
        <ion-button
          [disabled]="
            (preorderMode && (!selectedHour || !selectedDate)) || loading
          "
          (click)="onClickContinue()"
        >
          <ion-spinner *ngIf="loading"></ion-spinner>
          <ion-text *ngIf="!loading">{{
            "popups.pre-order-popup.continue" | translate
          }}</ion-text>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
