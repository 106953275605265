import { Component, OnDestroy, OnInit } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { AnimationOptions } from "ngx-lottie";
import { Subscription } from "rxjs";

@Component({
  selector: "app-fixed-points-address-confirmation-popup",
  templateUrl: "./fixed-points-address-confirmation-popup.component.html",
  styleUrls: ["./fixed-points-address-confirmation-popup.component.scss"],
})
export class FixedPointsAddressConfirmationPopupComponent
  implements OnInit, OnDestroy
{
  fixedPointsAnimationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/fixed-points-address-animation.json",
  };
  newAddressAnimationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/new-address-animation.json",
  };

  private unsubscribeBackEvent: Subscription;

  constructor(
    private modalController: ModalController,
    private platform: Platform
  ) {}

  ondismiss() {
    this.modalController.dismiss();
  }

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      300,
      () => {
        if (this.platform.is("android")) {
          this.modalController.dismiss();
        }
      }
    );
  }

  ngOnDestroy(): void {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
  }

  onClick(action) {
    this.modalController.dismiss(action);
  }
}
