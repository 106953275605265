import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { auditTime, distinctUntilChanged } from "rxjs/operators";
import * as fromApp from "../../store/app.reducer";
import * as _ from "lodash";
import { Subscription } from "rxjs";
import { Platform } from "@ionic/angular";

@Component({
  selector: "app-cart-icon",
  templateUrl: "./cart-icon.component.html",
  styleUrls: ["./cart-icon.component.scss"],
})
export class CartIconComponent implements OnInit, OnDestroy {
  @Input() mobileorderHideCartCatalog: any;
  public cartLength: number;
  public finalPriceLoading: boolean = false;
  public animateButtonContinue: boolean = false;
  public isIosPlatform: boolean;
  public cartProductsNumber: number;
  public cartPrices;
  public groupApp: any;
  public mobileorderMobileCartIconStyle: any;
  private previousProductsInCart: any;
  private cartProducts: any;
  private sub: Subscription;
  private cartPricesSub: Subscription;
  private groupSub: Subscription;
  private finalPriceSubscription: Subscription;

  constructor(
    private store: Store<fromApp.AppState>,
    private platform: Platform
  ) {}

  ngOnInit() {
    console.log("mobileorderHideCartCatalog", this.mobileorderHideCartCatalog);
    if (
      this.platform.is("ios") ||
      this.platform.is("iphone") ||
      this.platform.is("ipad")
    ) {
      this.isIosPlatform = true;
    }
    this.finalPriceSubscription = this.store
      .select("disableFinalPrice")
      .subscribe((state) => {
        if (
          state &&
          !_.isEqual(state.disableFinalPrice, this.finalPriceLoading)
        ) {
          this.finalPriceLoading = _.cloneDeep(state.disableFinalPrice);
        }
      });
    this.sub = this.store
      .select("cart")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(200))
      .subscribe((state) => {
        if (state && state.products) {
          this.previousProductsInCart = _.cloneDeep(this.cartProducts);
          this.cartLength = _.cloneDeep(state.products.length);

          this.cartProducts = _.cloneDeep(state.products);
          this.cartProductsNumber = 0;
          _.each(this.cartProducts, (product) => {
            if (
              product.quantity &&
              !product.disable_qty &&
              (!product.enable_quantity_step || !product.quantity_step)
            ) {
              this.cartProductsNumber =
                this.cartProductsNumber + product.quantity;
            } else {
              this.cartProductsNumber = this.cartProductsNumber + 1;
            }
          });

          if (
            this.cartProducts &&
            this.cartProducts.length &&
            this.cartProducts.length > 0 &&
            this.previousProductsInCart &&
            this.previousProductsInCart.length < this.cartProducts.length
          ) {
            this.animateButtonContinue = true;
          } else {
            this.animateButtonContinue = false;
          }
          if (navigator.cookieEnabled) {
            window.localStorage.setItem(
              "cart",
              JSON.stringify(this.cartProducts)
            );
          }
        }
      });

    this.cartPricesSub = this.store
      .select("cartPrices")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(200))
      .subscribe((state) => {
        if (
          state &&
          state.cartPrices &&
          !_.isEqual(this.cartPrices, state.cartPrices)
        ) {
          this.cartPrices = _.cloneDeep(state.cartPrices);
        }
      });

    this.cartPricesSub = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (
          state &&
          state.groupApp &&
          !_.isEqual(this.groupApp, state.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp);

          if (
            this.groupApp &&
            this.groupApp.design_settings &&
            this.groupApp.design_settings.mobileorderMobileCartIconStyle
          ) {
            this.mobileorderMobileCartIconStyle = _.cloneDeep(
              this.groupApp.design_settings.mobileorderMobileCartIconStyle
            );
          }
        }
      });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.finalPriceSubscription) {
      this.finalPriceSubscription.unsubscribe();
    }

    if (this.groupSub) {
      this.groupSub.unsubscribe();
    }

    if (this.cartPricesSub) {
      this.cartPricesSub.unsubscribe();
    }
  }
}
