import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import * as fromApp from "../../../store/app.reducer";
import * as _ from "lodash";
import { auditTime, distinctUntilChanged } from "rxjs/operators";
import { ModalController, ToastController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import * as CompleteOrderValidationActions from "../../../store/complete-order-validation/completeOrderValidation.actions";
import { Router } from "@angular/router";
import { CartPointsRewardsModalComponent } from "./cart-points-rewards-modal/cart-points-rewards-modal.component";

@Component({
  selector: "app-cart-points",
  templateUrl: "./cart-points.component.html",
  styleUrls: ["./cart-points.component.scss"],
})
export class CartPointsComponent implements OnInit {
  public groupApp: any;
  public userPoints: any;
  public currentUser: any;
  public highestReward: any;
  public pointsProgressBarColor: string;
  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store<fromApp.AppState>,
    private toastController: ToastController,
    private translateService: TranslateService,
    private router: Router,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(state.groupApp, this.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("points")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.userPoints &&
            !_.isEqual(state.userPoints, this.userPoints)
          ) {
            this.userPoints = _.cloneDeep(state.userPoints);
          }
          if (
            state &&
            state.highestReward &&
            !_.isEqual(state.highestReward, this.highestReward)
          ) {
            this.highestReward = _.cloneDeep(state.highestReward);

            if (this.highestReward && this.highestReward.user_points_percent) {
              if (this.highestReward.user_points_percent < 50) {
                this.pointsProgressBarColor = "dark";
              } else if (
                this.highestReward.user_points_percent >= 50 &&
                this.highestReward.user_points_percent <= 99
              ) {
                this.pointsProgressBarColor = "warning";
              } else if (this.highestReward.user_points_percent === 100) {
                this.pointsProgressBarColor = "success";
              }
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (state && !_.isEqual(state.user, this.currentUser)) {
            this.currentUser = _.cloneDeep(state.user);
          }
        })
    );
  }

  async getTheCoupon() {
    const modal = await this.modalController.create({
      component: CartPointsRewardsModalComponent,
      cssClass: "cartPointsRewardsModal",
      componentProps: {
        badge_color: this.pointsProgressBarColor,
      },
      backdropDismiss: false,
    });
    return await modal.present();
  }
  // this.router.navigateByUrl("/points", {
  //   replaceUrl: true,
  // });
}
