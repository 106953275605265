import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { distinctUntilChanged, first, Subscription } from "rxjs";
import * as fromApp from "../store/app.reducer";
import * as _ from "lodash";
import { AlertController, Platform } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { AnalyticsServiceService } from "../services/analitycsService/analytics-service.service";
import { AnimationOptions } from "ngx-lottie";
import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
declare let window: any;

@Component({
  selector: "app-bundle-update",
  templateUrl: "./bundle-update.page.html",
  styleUrls: ["./bundle-update.page.scss"],
})
export class BundleUpdatePage implements OnInit, OnDestroy {
  public androidPlatform: any;
  public groupApp: any;
  public flattenedIconBackgroundFlag: any;
  public storeImage: any;
  private unsubscribeBackEvent: Subscription;
  private groupSub: Subscription;
  private subscription: Subscription;

  animationOptionsAndroid: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/download-now-android.json",
  };

  animationOptionsIos: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/download-now-ios.json",
  };

  constructor(
    private store: Store<fromApp.AppState>,
    private platform: Platform,
    private translateService: TranslateService,
    private analyticsService: AnalyticsServiceService,
    private alertController: AlertController
  ) {}

  ionViewDidEnter() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      1000,
      () => {
        if (this.platform.is("android")) {
          this.appExit();
        }
      }
    );
    this.groupSub = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.groupApp &&
          !_.isEqual(state.groupApp, this.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp);

          this.sendFootPrint();

          this.storeImage = this.groupApp.iconImage;

          this.flattenedIconBackgroundFlag = this.groupApp.flattenedIcon
            ? true
            : false;

          if (
            this.flattenedIconBackgroundFlag &&
            this.groupApp.flattenedIconBackground
          ) {
            const el = document.documentElement;
            if (el) {
              el.style.setProperty(
                "--flattenedIconBackground",
                this.groupApp.flattenedIconBackground
              );
            }
          }
        }
      });
    this.subscription = this.store
      .select("platformInfo")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (state && !_.isEqual(state.androidPlatform, this.androidPlatform)) {
          this.androidPlatform = _.cloneDeep(state.androidPlatform);
        }
      });
  }

  sendFootPrint() {
    this.store
      .select("platformInfo")
      .pipe(first())
      .subscribe((state) => {
        if (state && state.versionNumber) {
          this.analyticsService.dmAnalyticsMessage(
            "bundle_update_page_loaded",
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            _.cloneDeep(state.versionNumber),
            this.platform.is("android")
              ? this.groupApp.androidBundleAppVersion
              : this.groupApp.iOSBundleAppVersion,
            null
          );
        }
      });
  }

  ngOnInit() {}

  async openNativeApp() {
    console.log("openNativeApp");
    console.log("androidPlatform", this.androidPlatform);

    if (Capacitor.isNativePlatform()) {
      console.log("platform mobile");
      if (Capacitor.getPlatform() === "android") {
        console.log("open native app android");
        await window.open(this.groupApp.androidNativeUrl, "_system");
      } else if (Capacitor.getPlatform() === "ios") {
        console.log("open native app ios");
        await window.open(this.groupApp.iOSNativeUrl, "_system");
      }
    } else {
      console.log("platform id browser");
      if (this.platform.is("android")) {
        window.open(this.groupApp.androidNativeUrl, "_system");
      } else {
        window.open(this.groupApp.iOSNativeUrl, "_system");
      }
    }
  }

  private async appExit() {
    const alert = await this.alertController.create({
      header: this.translateService.instant("alert"),
      message: this.translateService.instant("do-you-want-to-exit-app"),
      buttons: [
        {
          text: this.translateService.instant("cancel"),
          role: "cancel",
          handler: () => {
            console.log("Application exit prevented!");
          },
        },
        {
          text: this.translateService.instant("exit"),
          handler: () => {
            // tslint:disable-next-line: no-string-literal
            if (this.platform.is("android")) {
              window.navigator["app"].exitApp(); // Close this application
            }
          },
        },
      ],
    });
    return await alert.present();
  }

  ionViewDidLeave() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.groupSub) {
      this.groupSub.unsubscribe();
    }
  }

  ngOnDestroy() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.groupSub) {
      this.groupSub.unsubscribe();
    }
  }
}
