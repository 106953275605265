import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../store/app.reducer";
import * as _ from "lodash";
import { distinctUntilChanged, first } from "rxjs/operators";
import { Subscription } from "rxjs";
import * as OrderCommentsActions from "./store/orderComments.actions";

@Component({
  selector: "app-order-comments",
  templateUrl: "./order-comments.component.html",
  styleUrls: ["./order-comments.component.scss"],
})
export class OrderCommentsComponent implements OnInit, OnDestroy {
  public generalComments: any;
  public currentUser: any;
  public groupApp: any;
  private subscription: Subscription;
  private groupAppSub: Subscription;
  constructor(private store: Store<fromApp.AppState>) {}

  ngOnInit() {
    this.subscription = this.store
      .select("auth")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (state && state.user && !_.isEqual(this.currentUser, state.user)) {
          this.currentUser = _.cloneDeep(state.user);
        }
      });

    this.groupAppSub = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.groupApp &&
          !_.isEqual(this.groupApp, state.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp);
        }
      });

    this.store
      .select("orderComments")
      .pipe(first())
      .subscribe((state) => {
        if (state && state.generalComments) {
          this.generalComments = _.cloneDeep(state.generalComments);
        } else {
          this.loadOrderComments();
        }
      })
      .unsubscribe();
  }

  loadOrderComments() {
    if (navigator.cookieEnabled) {
      if (
        window.localStorage.getItem("orderComments") &&
        window.localStorage.getItem("orderComments") !== "" &&
        window.localStorage.getItem("orderComments") !== "{}"
      ) {
        let orderComments = JSON.parse(
          window.localStorage.getItem("orderComments")
        );
        this.generalComments = orderComments;
        this.store.dispatch(
          new OrderCommentsActions.SetOrderComments(orderComments)
        );
      }
    } else {
      this.generalComments = null;
    }
  }

  onChange() {
    this.store.dispatch(
      new OrderCommentsActions.SetOrderComments(
        _.cloneDeep(this.generalComments)
      )
    );
    if (navigator.cookieEnabled) {
      if (this.generalComments) {
        localStorage.setItem(
          "orderComments",
          JSON.stringify(_.cloneDeep(this.generalComments))
        );
      } else {
        if (localStorage.getItem("orderComments")) {
          localStorage.removeItem("orderComments");
        }
      }
    }
  }

  ngOnDestroy() {
    console.log("order comments component destroyed");
    this.generalComments = null;
    //this.writeToState = false;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.groupAppSub) {
      this.groupAppSub.unsubscribe();
    }
  }
}
