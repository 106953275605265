<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small" *ngIf="!loading">
      <ion-text
        *ngIf="
          (numberOfRecommendedCategories &&
            numberOfRecommendedCategories !== 1) ||
          !uniqueCategory
        "
      >
        {{ "popups.recommended-products-popup.title" | translate }}
      </ion-text>

      <ion-text
        *ngIf="
          numberOfRecommendedCategories &&
          numberOfRecommendedCategories === 1 &&
          uniqueCategory
        "
      >
        {{
          uniqueCategory.recommendedProductsCategoryTitle &&
          uniqueCategory.recommendedProductsCategoryTitle.translations &&
          selectedLanguage &&
          uniqueCategory.recommendedProductsCategoryTitle.translations[
            selectedLanguage
          ]
            ? uniqueCategory.recommendedProductsCategoryTitle.translations[
                selectedLanguage
              ]
            : ("popups.recommended-products-popup.title" | translate)
        }}
      </ion-text>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button color="danger" fill="clear" (click)="closeModal()">
        <ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid *ngIf="loading">
    <ion-row style="width: 100%">
      <ion-col class="ion-text-center">
        <ion-spinner></ion-spinner>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid *ngIf="!loading">
    <!-- <ion-item lines="none">
      <ion-label
        class="ion-text-wrap ion-text-center"
        style="font-weight: bold"
      >
        {{
          "popups.recommended-products-popup.do-you-want-to-add-some-of-these-products-in-your-order"
            | translate
        }}
      </ion-label>
    </ion-item> -->

    <span *ngIf="recommended_products_by_category_id">
      <span
        *ngFor="
          let categoryProducts of recommended_products_by_category_id | keyvalue
        "
      >
        <span
          *ngIf="
            categoryProducts &&
            $any(categoryProducts.value) &&
            $any(categoryProducts.value).length
          "
        >
          <ion-item
            *ngIf="
              numberOfRecommendedCategories > 1 &&
              categoryProducts &&
              $any(categoryProducts.value) &&
              $any(categoryProducts.value).length
            "
            class="category"
          >
            <ion-row>
              <ion-col size="12" class="noPaddingCols">
                <ion-text color="primary">
                  <strong>
                    {{
                      $any(categoryProducts.value)[0].category &&
                      $any(categoryProducts.value)[0].category
                        .recommendedProductsCategoryTitle &&
                      $any(categoryProducts.value)[0].category
                        .recommendedProductsCategoryTitle.translations &&
                      selectedLanguage
                        ? $any(categoryProducts.value)[0].category
                            .recommendedProductsCategoryTitle.translations[
                            selectedLanguage
                          ]
                        : $any(categoryProducts.value)[0].category.name
                    }}</strong
                  >
                </ion-text>
              </ion-col>
            </ion-row>
          </ion-item>

          <ion-grid [ngStyle]="{ 'max-width': gridProductsMaxWidth }">
            <ion-row
              style="width: 100%"
              *ngIf="windowDimensions && windowDimensions.width >= 850"
            >
              <ion-col
                [size]="productsColSizeBrowser"
                *ngFor="
                  let recommended_product of $any(categoryProducts.value);
                  trackBy: productsTrackFn
                "
              >
                <app-product-item-card
                  [category]="recommended_product.category"
                  [product]="recommended_product"
                  [webp]="webp"
                  [orderPickup]="orderPickup"
                  [selectedDineInTable]="selectedDineInTable"
                  [selectedPreorderDate]="selectedPreorderDate"
                  [groupStoreData]="groupStoreData"
                  [dineIn]="dineIn"
                  [recommendedModal]="true"
                ></app-product-item-card>
                <!-- <app-product-item
      *ngIf="
        recommended_product.activeTotal &&
        recommended_product.category.activeTotal
      "
      [category_id]="recommended_product.category.category_id"
      [product_id]="recommended_product.product_id"
      [cartProductIds]="cartProductIds"
      [category]="recommended_product.category"
      [groupStoreData]="groupStoreData"
      [hyperCategories]="hyperCategories"
      [orderPickup]="orderPickup"
      [selectedDineInTable]="selectedDineInTable"
      [dineIn]="dineIn"
      [webp]="webp"
      [fromRecommendedProducts]="true"
      [recommended_product]="recommended_product"
    >
    </app-product-item -->
              </ion-col>
            </ion-row>

            <ion-row
              style="width: 100%"
              *ngIf="windowDimensions && windowDimensions.width < 850"
            >
              <ion-col
                [size]="productsColSizeMobile"
                *ngFor="
                  let recommended_product of $any(categoryProducts.value);
                  trackBy: productsTrackFn
                "
              >
                <app-product-item-card-mobile
                  [category]="recommended_product.category"
                  [product]="recommended_product"
                  [webp]="webp"
                  [orderPickup]="orderPickup"
                  [selectedDineInTable]="selectedDineInTable"
                  [selectedPreorderDate]="selectedPreorderDate"
                  [groupStoreData]="groupStoreData"
                  [recommendedModal]="true"
                  [dineIn]="dineIn"
                ></app-product-item-card-mobile>

                <!-- <app-product-item
  *ngIf="
    recommended_product.activeTotal &&
    recommended_product.category.activeTotal
  "
  [category_id]="recommended_product.category.category_id"
  [product_id]="recommended_product.product_id"
  [cartProductIds]="cartProductIds"
  [category]="recommended_product.category"
  [groupStoreData]="groupStoreData"
  [hyperCategories]="hyperCategories"
  [orderPickup]="orderPickup"
  [selectedDineInTable]="selectedDineInTable"
  [dineIn]="dineIn"
  [webp]="webp"
  [fromRecommendedProducts]="true"
  [recommended_product]="recommended_product"
>
</app-product-item -->
              </ion-col>
            </ion-row>
          </ion-grid>
        </span>
      </span>
    </span>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-grid>
    <ion-row style="width: 1005">
      <ion-col class="ion-text-center">
        <ion-button
          [disabled]="finalPriceLoading"
          (click)="closeModal()"
          style="min-width: 200px"
        >
          <ion-text *ngIf="!finalPriceLoading">
            <span *ngIf="!thereIsARecommendedInCart">
              {{ "popups.recommended-products-popup.no-thanks" | translate }}
            </span>
            <span *ngIf="thereIsARecommendedInCart">
              {{ "popups.recommended-products-popup.continue" | translate }}
            </span>
          </ion-text>
          <ion-spinner *ngIf="finalPriceLoading"> </ion-spinner>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
