import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timestampToString'
})
export class TimestampToStringPipe implements PipeTransform {
  transform(timestamp: number): string {
    // Assuming that the input timestamp is in seconds, convert it to milliseconds
    const date = new Date(timestamp * 1000);

    const days = [
      'Κυριακή',
      'Δευτέρα',
      'Τρίτη',
      'Τετάρτη',
      'Πέμπτη',
      'Παρασκευή',
      'Σάββατο'
    ];

    const months = [
      'Ιανουαρίου',
      'Φεβρουαρίου',
      'Μαρτίου',
      'Απριλίου',
      'Μαΐου',
      'Ιουνίου',
      'Ιουλίου',
      'Αυγούστου',
      'Σεπτεμβρίου',
      'Οκτωβρίου',
      'Νοεμβρίου',
      'Δεκεμβρίου'
    ];

    const day = days[date.getDay()];
    const dayOfMonth = date.getDate();
    const month = months[date.getMonth()];

    return `${day}, ${dayOfMonth} ${month}`;
  }
}
