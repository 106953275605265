<!-- <ion-header
  class="ion-no-border"
  *ngIf="groupApp"
>
  <ion-toolbar
    style="--background: var(--ion-color-primary)"
    mode="ios"
    [class]="
      groupApp &&
      groupApp.design_settings &&
      groupApp.design_settings.sideMenuHeaderLogo &&
      headerLogo &&
      selectedTheme &&
      (selectedTheme === 'light' ||
        (selectedTheme === 'dark' && !darkHeaderLogo))
        ? 'headerMenuBackground'
        : ''"
  >
    <ion-buttons
      slot="start"
      *ngIf="!groupApp.only_loyalty_app"
    >
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>
    <ion-buttons
      slot="end"
      *ngIf="groupApp.only_loyalty_app"
    >
      <ion-button
        *ngIf="!currentUser && loginState && loginState === 'no_login'"
        size="large"
        (click)="presentMagicLoginPopup()"
      >
        <ion-icon
          style="color: var(--ion-color-primary-contrast);"
          name="log-in-outline"
        > </ion-icon>
      </ion-button>
      <ion-button
        *ngIf="currentUser"
        size="large"
        (click)="openQrCodeModal()"
      >
        <ion-icon
          style="color: var(--ion-color-primary-contrast);"
          name="qr-code-outline"
        ></ion-icon>
      </ion-button>
    </ion-buttons>
    <app-side-menu-header-logo
      [ngStyle]="{'width': bigScreenStyle ? '50%': ''}"
      *ngIf="groupApp && groupApp.only_loyalty_app"
      [groupApp]="groupApp"
      [flattenedIconBackgroundFlag]="flattenedIconBackgroundFlag"
      [storeImage]="storeImage"
      [headerLogo]="headerLogo"
      [darkHeaderLogo]="darkHeaderLogo"
    ></app-side-menu-header-logo>
  </ion-toolbar>
</ion-header> -->

<ion-backdrop *ngIf="playRankUpAnimation"> </ion-backdrop>
<ion-grid *ngIf="animationLogin"
          style="width: 100%; height: 100%; position: absolute; top: 0; z-index: 1000">
  <ion-row style="width: 100%; height: 100%"
           class="ion-align-items-center ion-justify-content-center">
    <ion-col class="ion-align-self-center ion-text-center">
      <ion-grid style="max-width: 300px">
        <ng-lottie (animationCreated)="animationCreated($event)"
                   (loopComplete)="animationComplete($event)"
                   [options]="options">
        </ng-lottie>
      </ion-grid>
    </ion-col>
  </ion-row>
</ion-grid>
<ion-grid *ngIf="playRankUpAnimation"
          style="width: 100%; height: 100%; position: absolute; top: 0; z-index: 1000">
  <ion-row style="width: 100%; height: 100%"
           class="ion-align-items-center ion-justify-content-center">
    <ion-col class="ion-align-self-center ion-text-center">
      <ion-grid style="max-width: 500px">
        <ion-text class="blink_me"
                  style="
            font-size: xx-large;
            color: white;
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%);
          ">Rank Up!</ion-text>
        <ng-lottie (animationCreated)="rankUpAnimationCreated($event)"
                   [options]="rankUpAnimationOptions"></ng-lottie>
      </ion-grid>
    </ion-col>
  </ion-row>
</ion-grid>

<ion-content *ngIf="groupApp">
  <!-- points and draws -->
  <ion-grid [ngStyle]="{'width': bigScreenStyle ? '50%': '','padding-bottom': '0'}"
            class="loyalty-header">
    <ion-card>
      <ion-row class="ion-align-items-center"
               style="background-color: var(--ion-color-primary); min-height: 75px;">
        <ion-col>

        </ion-col>
        <ion-col>
          <ion-img style="max-width: 150px; margin: auto;"
                   *ngIf="headerLogo"
                   src="https://images.deliverymanager.gr/{{ groupApp.group }}/header/{{headerLogo}}"></ion-img>
        </ion-col>
        <ion-col class="ion-text-right">
          <div *ngIf="groupApp.only_loyalty_app">
            <ion-button *ngIf="!currentUser && loginState && loginState === 'no_login'"
                        fill="clear"
                        size="large"
                        (click)="presentMagicLoginPopup()">
              <ion-icon size="large"
                        style="color: var(--ion-color-primary-contrast)"
                        name="log-in-outline">
              </ion-icon>
            </ion-button>
            <ion-button *ngIf="currentUser"
                        fill="clear"
                        size="large"
                        (click)="openQrCodeModal()">
              <ion-icon size="large"
                        style="color: var(--ion-color-primary-contrast)"
                        name="qr-code-outline"></ion-icon>
            </ion-button>
          </div>
        </ion-col>
      </ion-row>
      <div *ngIf="currentUser">
        <ion-row class="ion-justify-content-center">
          <ion-col>
            <ion-item style="--background: transparent"
                      lines="none">
              <ion-label *ngIf="currentTab === 'points'"
                         class="ion-text-wrap"
                         style="font-size: x-large; font-weight: bold">
                {{'loyaltySystem.points' | translate}}
              </ion-label>
              <ion-label *ngIf="currentTab === 'draws'"
                         class="ion-text-wrap"
                         style="font-size: x-large; font-weight: bold">
                {{'loyaltySystem.entries' | translate}}
              </ion-label>
              <ion-button *ngIf="currentTab === 'points'"
                          fill="outline"
                          slot="end"
                          (click)="pageChange('points')">
                {{'points.history-title' | translate}}
              </ion-button>
              <ion-button *ngIf="currentTab === 'draws'"
                          fill="outline"
                          slot="end"
                          (click)="openContestModal()">
                {{'loyaltySystem.contest' | translate}}
              </ion-button>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-center">
          <ion-button *ngIf="currentTab === 'points' "
                      size="small"
                      (click)="openMigratePointsModal()">
            {{'loyaltySystem.transfer-points' | translate}}
          </ion-button>
        </ion-row>
        <ion-row *ngIf="userPoints">
          <ion-col>
            <ion-item *ngIf="currentTab === 'points'"
                      style="--background: transparent"
                      lines="none">
              <ion-icon style="
                  margin-top: 0;
                  margin-bottom: 0;
                  font-size: -webkit-xxx-large;
                "
                        slot="start"
                        src="/assets/flatIcons/coins.svg"></ion-icon>
              <ion-label>
                <ion-text style="font-size: xx-large; font-weight: bold">
                  {{userPoints.pointsSystem.active}}
                </ion-text>
              </ion-label>
            </ion-item>
            <ion-item *ngIf="currentTab === 'draws'"
                      style="--background: transparent"
                      lines="none">
              <ion-icon style="margin-top: 0; margin-bottom: 0; font-size: -webkit-xxx-large; "
                        slot="start"
                        src="/assets/flatIcons/lottery.svg"></ion-icon>
              <ion-label style="font-size: xx-large; font-weight: bold">{{drawsSum || 0}}</ion-label>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row style="margin-left: 10px;">
          <ion-col>
            <ion-note *ngIf="currentTab === 'points'"
                      color="danger"
                      style="font-size: x-small; margin-bottom: 10px;">
              *Ο συγχρονισμός των πόντων μπορεί να διαρκέσει μερικά λεπτά
            </ion-note>
            <ion-segment mode="ios"
                         style="width: 50%; margin-top: 10px"
                         value="points"
                         (ionChange)="handleSegmentChange($event)">
              <ion-segment-button value="points">
                <ion-label>Πόντοι</ion-label>
              </ion-segment-button>
              <ion-segment-button value="draws">
                <ion-label>Συμμετοχές</ion-label>
              </ion-segment-button>
            </ion-segment>
          </ion-col>
        </ion-row>
      </div>
    </ion-card>
  </ion-grid>
  <ion-grid [ngStyle]="{'width': bigScreenStyle ? '50%': ''}">
    <!-- <div class="hello-row">
      <ion-row>
        <ion-title
          mode="md"
          class="ion-text-center"
          *ngIf="currentUser && (currentUser.firstName || currentUser.lastName)"
          style="font-weight: bold"
        >
          {{'loyaltySystem.greeting' | translate}} {{currentUser.firstName ?
          currentUser.firstName : currentUser.lastName}} 👋 !
        </ion-title>
        <ion-title
          mode="md"
          class="ion-text-center"
          *ngIf="!currentUser && loginState !== 'login_loading'"
          style="font-weight: bold"
        >
          {{'loyaltySystem.greeting' | translate}} 👋!
        </ion-title>
        <ion-title
          mode="md"
          *ngIf="currentUser && !currentUser.firstName && !currentUser.lastName && currentUser.mobile"
          style="font-weight: bold"
          >{{'loyaltySystem.greeting' | translate}}, {{currentUser.mobile}}
          👋!</ion-title
        >
      </ion-row>
    </div> -->
    <!-- start of user ranking card -->
    <ion-row *ngIf="currentUser && userRanking"
             style="background-color: white"
             class="hello-row">
      <ion-grid style="max-width: fit-content">
        <ion-row>
          <ion-col class="ion-text-center">
            <ion-item mode="ios"
                      style="
                --background: #e0e0e0;
                --border-radius: 50px;
                width: fit-content;
              "
                      lines="none">
              <ion-icon slot="start"
                        style="font-size: 60px; margin: 0"
                        *ngIf="userRanking.rank === 1"
                        src="/assets/flatIcons/first-rank.svg"></ion-icon>
              <ion-icon slot="start"
                        style="font-size: 50px; margin: 0"
                        *ngIf="userRanking.rank === 2"
                        src="/assets/flatIcons/second-rank.svg"></ion-icon>
              <ion-icon slot="start"
                        style="font-size: 40px; margin: 0"
                        *ngIf="userRanking.rank === 3"
                        src="/assets/flatIcons/third-rank.svg"></ion-icon>
              <ion-text slot="start"
                        style="font-weight: bold; font-size: x-large"
                        *ngIf="userRanking.rank !== 1 && userRanking.rank !== 2 && userRanking.rank !== 3">
                {{userRanking.rank}}.
              </ion-text>
              <ion-avatar slot="start"
                          style="height: 50px; width: 50px">
                <img *ngIf="!currentUser.profile_image"
                     alt="Silhouette of mountains"
                     src="https://ionicframework.com/docs/img/demos/thumbnail.svg" />

                <img width="auto"
                     height="auto"
                     *ngIf="groupApp && currentUser.profile_image && currentUser.profile_image.optimized"
                     [src]="'https://primg.deliverymanager.gr/'+ groupApp.group + '/'+ currentUser.customer_id + '/' + currentUser.profile_image.filename" />
              </ion-avatar>
              <ion-label>
                <ion-text style="font-size: large; font-weight: bold">
                  {{currentUser.firstName}} {{currentUser.lastName}}
                </ion-text>
                <div>Συνολικά προϊόντα: {{userRanking.total_products}}</div>
              </ion-label>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-row>
    <!-- end of user ranking card -->

    <!-- start of selected Store Info -->
    <!-- <ion-row>
      <ion-col>
        <ion-card *ngIf="selectedStoreInfo && !loading">
          <ion-item-divider style="font-size: large">
            <ion-text style="font-weight: bold">
              <ion-icon
                style="
                  margin-right: 10px;
                  vertical-align: text-top;
                  font-size: xx-large;
                "
                src="/assets/ionicons/store.svg"
              ></ion-icon>
              {{stores.length !== 1 ? selectedStoreInfo.store_name : null}}
            </ion-text>
            <ion-button
              slot="end"
              fill="clear"
              (click)="openStoreSelectionModal()"
            >
              {{'loyaltySystem.informations' | translate}}
            </ion-button>
          </ion-item-divider>

          <ion-card-content>
            <ion-text
              *ngIf="currentScheduleOfStore && currentScheduleOfStore.isOpen"
              style="color: black; opacity: 0.8"
            >
              {{currentScheduleOfStore.comments}}
            </ion-text>
            <ion-text
              *ngIf="currentScheduleOfStore && !currentScheduleOfStore.isOpen"
              style="color: red; opacity: 0.8"
            >
              {{currentScheduleOfStore.comments}}
            </ion-text>
          </ion-card-content>
        </ion-card>
        <ion-card *ngIf="loading">
          <ion-row style="width: 100%">
            <ion-col class="ion-text-center">
              <ion-spinner></ion-spinner>
            </ion-col>
          </ion-row>
        </ion-card>
      </ion-col>
    </ion-row> -->
    <!-- end of selected Store Info -->

    <!-- start of campaigns with icons -->
    <ion-row *ngIf="(!userLoyaltyProducts && !unableToLoadUserLoyaltyProducts) || (campaignsArrayRules && campaignsArrayRules.length > 0 && 
      (userLoyaltyProducts && userLoyaltyProducts.length === 0 || unableToLoadUserLoyaltyProducts))">
      <ion-col>
        <!-- <ion-card
          *ngIf="!userLoyaltyProducts && !unableToLoadUserLoyaltyProducts"
          style="min-height: 200px"
          class="ion-text-center"
        >
          <ion-row>
            <ion-col>
              <ion-spinner></ion-spinner>
            </ion-col>
          </ion-row>
        </ion-card> -->
        <ion-card *ngIf="userLoyaltyProducts && userLoyaltyProducts.length > 0 && 
        userLoyaltyCampaigns && userLoyaltyCampaigns.length > 0">
          <ion-row *ngFor="let campaign of userLoyaltyCampaigns; trackBy: customerLoyaltyCampaignsTrackBy">
            <ion-item-divider style="color: black">
              {{campaign.title.el}}
              <ion-button slot="end"
                          fill="clear"
                          (click)="informationsForCampaign(userLoyaltyProducts, campaign)">
                {{'loyaltySystem.informations' | translate}}
              </ion-button>
            </ion-item-divider>

            <!-- ICONS FOR COFFEE -->
            <ion-row *ngIf="campaign.product_type === 'coffee' && campaign.target_quantity !== coffeesUsedForRule.length"
                     class="ion-align-items-center ion-justify-content-center"
                     style="width: 100%">
              <ion-icon *ngFor="let coffee of coffeesUsedForRule"
                        style="font-size: 40px; cursor: pointer"
                        src="/assets/flatIcons/coffee-filled.svg"
                        (click)="presentPopover($event,coffee)"></ion-icon>

              <ion-icon *ngFor="let _ of [].constructor(campaign.target_quantity-coffeesUsedForRule.length)"
                        style="font-size: 40px"
                        src="/assets/flatIcons/coffee-empty.svg"></ion-icon>

              <ion-icon size="large"
                        style="margin-left: 10px"
                        src="/assets/flatIcons/plus.svg"></ion-icon>

              <div style="max-width: 80px">
                <ng-lottie (animationCreated)="freeCoffeeAnimationCreated($event)"
                           [options]="freeCoffeeAnimationOptions"></ng-lottie>
              </div>
            </ion-row>
            <ion-row style="width: 100%"
                     *ngIf="campaign.product_type === 'coffee' && campaign.target_quantity === coffeesUsedForRule.length"
                     class="ion-align-items-center ion-justify-content-center">
              <ion-col>
                <ion-row style="margin-top: 15px; width: 100%"
                         class="ion-align-items-center ion-justify-content-center">
                  <ion-icon *ngFor="let coffee of coffeesUsedForRule"
                            style="font-size: 30px; cursor: pointer"
                            src="/assets/flatIcons/coffee-filled.svg"
                            (click)="presentPopover($event,coffee)"></ion-icon>
                </ion-row>
                <ion-row class="ion-align-items-center ion-justify-content-center">
                  <div style="max-width: 100px">
                    <ng-lottie (animationCreated)="freeCoffeeEarnedAnimationCreated($event)"
                               [options]="freeCoffeeAnimationOptions"></ng-lottie>
                  </div>
                </ion-row>
                <ion-row>
                  <ion-col class="ion-text-center"
                           size="12">
                    <ion-button color="danger"
                                size="small"
                                (click)="createCouponModal(campaign)">
                      {{'loyaltySystem.gift-available' | translate}}
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-col>
            </ion-row>

            <!-- ICONS FOR BURGER -->
            <ion-row style="width: 100%"
                     class="ion-align-items-center ion-justify-content-center"
                     *ngIf="campaign.product_type === 'burger' && campaign.target_quantity !== burgersUsedForRule.length">
              <ion-icon *ngFor="let burger of burgersUsedForRule"
                        style="font-size: 58px; cursor: pointer"
                        src="/assets/flatIcons/burger-filled.svg"
                        (click)="presentPopover($event,burger)">
              </ion-icon>

              <ion-icon *ngFor="let _ of [].constructor(campaign.target_quantity-burgersUsedForRule.length)"
                        style="font-size: 58px"
                        src="/assets/flatIcons/burger-empty.svg">
              </ion-icon>

              <ion-icon size="large"
                        style="margin-left: 10px"
                        src="/assets/flatIcons/plus.svg"></ion-icon>

              <div style="max-width: 100px">
                <ng-lottie (animationCreated)="freeBurgerAnimationCreated($event)"
                           [options]="freeBurgerAnimationOptions"></ng-lottie>
              </div>
            </ion-row>

            <ion-row *ngIf="campaign.product_type === 'burger' && campaign.target_quantity === burgersUsedForRule.length"
                     class="ion-align-items-center ion-justify-content-center"
                     style="width: 100%">
              <ion-col>
                <ion-row style="margin-top: 15px; width: 100%"
                         class="ion-align-items-center ion-justify-content-center">
                  <ion-icon *ngFor="let burger of burgersUsedForRule"
                            style="font-size: 50px; cursor: pointer"
                            src="/assets/flatIcons/burger-filled.svg"
                            (click)="presentPopover($event,burger)"></ion-icon>
                </ion-row>
                <ion-row class="ion-align-items-center ion-justify-content-center">
                  <div style="max-width: 100px">
                    <ng-lottie (animationCreated)="freeBurgerEarnedAnimationCreated($event)"
                               [options]="freeBurgerAnimationOptions"></ng-lottie>
                  </div>
                </ion-row>
                <ion-row>
                  <ion-col class="ion-text-center"
                           size="12">
                    <ion-button size="small"
                                (click)="createCouponModal(campaign)">
                      {{'loyaltySystem.gift-available' | translate}}
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-col>
            </ion-row>
            <!-- END OF icons for burger -->
          </ion-row>
        </ion-card>

        <!-- Card to show for not logged in users or users with no purchases -->
        <ion-card *ngIf="campaignsArrayRules && campaignsArrayRules.length > 0 && 
        (userLoyaltyProducts && userLoyaltyProducts.length === 0 || unableToLoadUserLoyaltyProducts)">
          <ion-row *ngFor="let campaign of campaignsArrayRules">
            <ion-item-divider style="color: black">
              {{campaign.title.el}}
              <ion-button slot="end"
                          fill="clear"
                          (click)="informationsForCampaign(userLoyaltyProducts, campaign)">
                {{'loyaltySystem.informations' | translate}}
              </ion-button>
            </ion-item-divider>
            <!-- ICONS FOR COFFEE -->
            <ion-row *ngIf="campaign.product_type === 'coffee'"
                     class="ion-align-items-center ion-justify-content-center"
                     style="width: 100%">
              <ion-icon *ngFor="let _ of [].constructor(campaign.target_quantity)"
                        style="font-size: 40px"
                        src="/assets/flatIcons/coffee-empty.svg"></ion-icon>

              <ion-icon size="large"
                        style="margin-left: 10px"
                        src="/assets/flatIcons/plus.svg"></ion-icon>

              <div style="max-width: 80px">
                <ng-lottie (animationCreated)="freeCoffeeAnimationCreated($event)"
                           [options]="freeCoffeeAnimationOptions"></ng-lottie>
              </div>
            </ion-row>
            <!-- ICONS FOR BURGER -->
            <ion-row style="width: 100%"
                     class="ion-align-items-center ion-justify-content-center"
                     *ngIf="campaign.product_type === 'burger'">
              <ion-icon *ngFor="let _ of [].constructor(campaign.target_quantity)"
                        style="font-size: 58px"
                        src="/assets/flatIcons/burger-empty.svg">
              </ion-icon>

              <ion-icon size="large"
                        style="margin-left: 10px"
                        src="/assets/flatIcons/plus.svg"></ion-icon>
              <div style="max-width: 100px">
                <ng-lottie (animationCreated)="freeBurgerAnimationCreated($event)"
                           [options]="freeBurgerAnimationOptions"></ng-lottie>
              </div>
            </ion-row>
          </ion-row>
        </ion-card>
      </ion-col>
    </ion-row>
    <!-- end of campaigns with icons -->

    <!-- <ion-row>
      <ion-col>
        <ion-card *ngIf="imagesArray && imagesArray.length > 0">
          <app-loyalty-swiper [images]="imagesArray"> </app-loyalty-swiper>
        </ion-card>
      </ion-col>
    </ion-row> -->

    <!-- coupon rules slider -->
    <ion-row *ngIf="customerLoyaltyCouponRules && customerLoyaltyCouponRules.length > 0">
      <ion-col>
        <ion-card>
          <!-- <ion-item-divider style="color: black">
            {{'loyaltySystem.coupon-rules' | translate}}
          </ion-item-divider> -->
          <ion-row>
            <ion-col class="ion-no-padding">
              <swiper class="loyalty-swiper"
                      [loop]="true"
                      [pagination]="{ clickable: true }"
                      autoplay="true"
                      effect="fade">
                <ng-template swiperSlide
                             *ngFor="let rule of customerLoyaltyCouponRules">
                  <ion-grid class="ion-no-padding">
                    <ion-row style="width: 100%">
                      <ion-img (click)='openGetPromoModal(rule, "couponRule")'
                               [src]='"https://d3vm1ym7xlqx7l.cloudfront.net/"+ groupApp.group + "/coupon-rule-images/" + rule.image'></ion-img>
                    </ion-row>
                  </ion-grid>
                </ng-template>
              </swiper>
            </ion-col>
          </ion-row>
        </ion-card>
      </ion-col>
    </ion-row>

    <!-- start of contests card -->
    <ion-row *ngIf="activeContest">
      <ion-col>
        <ion-card>
          <ion-item-divider
                            style="color: black; background-color: #F69D1A; min-height: 75px; font-size: 18px; font-weight: bold;">
            <!-- {{'loyaltySystem.active-contest' | translate}} -->
            {{activeContest ? ('loyaltySystem.contest' | translate) + " " + (activeContest.activeTo | date: 'dd/MM/YY')
            :
            "loyaltySystem.active-contest" | translate }}
            <ion-button slot="end"
                        fill="clear"
                        (click)="pageChange('loyaltySystem/loyalty-contests')">
              <ion-icon size="large"
                        style="color: black;"
                        name="information-circle-outline"></ion-icon>
            </ion-button>
          </ion-item-divider>
          <ion-row *ngIf="groupContests && !activeContest || !groupContests">
            <ion-col class="ion-text-center">
              {{'loyaltySystem.no-active-contest' | translate}}</ion-col>
          </ion-row>
          <div style="min-height: 170px"
               *ngIf="activeContest && activeContest.gifts && activeContest.gifts.length > 0">
            <swiper (swiper)="onSwiper($event)"
                    pagination="true"
                    [autoplay]="{disableOnInteraction: false}"
                    effect="cards"
                    grabCursor="true"
                    centeredSlides="true"
                    [slidesPerView]="1"
                    #swiperSlideShow
                    [cardsEffect]="{slideShadows : false}">
              <ng-template swiperSlide
                           *ngFor="let prize of activeContest.gifts; let i=index">
                <ion-grid class="ion-no-padding">
                  <ion-row style="width: 100%">
                    <ion-col class="ion-text-center">
                      <ion-img *ngIf="prize?.image"
                               style="width: 100%; height: 190px"
                               [src]="'https://d3vm1ym7xlqx7l.cloudfront.net/' + groupApp.group + '/contests/' + activeContest.contest_id + '/' + prize.image"></ion-img>
                      <ion-img *ngIf="!prize.image"
                               style="width: 100%; height: 190px"
                               [src]="'https://d3vm1ym7xlqx7l.cloudfront.net/' + groupApp.group + '/contests/default-gift.jpg'"></ion-img>
                    </ion-col>
                  </ion-row>
                  <ion-row *ngIf="activeContest?.show_gifts_names">
                    <ion-col class="ion-text-center"
                             style="margin: 10px 0 10px 0">
                      <ion-text *ngIf="activeSwiperIndex === i"
                                style="
                          color: #343c60;
                          font-size: larger;
                          font-weight: bold;
                          text-align: center;
                        ">
                        {{prize.title}}
                      </ion-text>
                      <!-- <p *ngIf="activeSwiperIndex === i" style="text-align: center;">{{prize.description}}</p> -->
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ng-template>
            </swiper>
          </div>
          <ion-card-header *ngIf="activeContest"
                           style="border-top: 1px solid #d1d1d1; padding-bottom: 0">
            <!-- <ion-card-title>{{activeContest.title}}</ion-card-title> -->
          </ion-card-header>
          <ion-card-content *ngIf="activeContest">
            <ion-item lines="none"
                      style="
                --padding-start: 0px !important;
                border-top: 1px solid grey;
                border-bottom: 1px solid grey;
                margin-bottom: 15px;
              "
                      (click)="showRaffleGiftsModal(activeContest)">
              <ion-icon name="gift"
                        color="primary"
                        style="margin-right: 10px"></ion-icon>
              <ion-label>
                <h2>Δώρα κλήρωσης</h2>
              </ion-label>
              <ion-icon name="information-circle"
                        color="primary"
                        style="margin-right: 10px"></ion-icon>
            </ion-item>
            <p style="margin-bottom: 15px">
              {{activeContest.short_description}}
            </p>
            <ion-grid *ngIf="activeContest && drawsSum !== null"
                      style="max-width: 350px">
              <ion-row style="
                  background-color: var(--ion-color-primary);
                  border-radius: 20px;
                ">
                <ion-col>
                  <ion-item lines="none"
                            style="
                      --border-radius: 20px;
                      --background: var(--ion-color-primary);
                    "
                            (click)="openContestModal()">
                    <ion-avatar slot="start"
                                style="height: 50px; width: 50px">
                      <img *ngIf="!currentUser || (currentUser && !currentUser.profile_image)"
                           alt="Silhouette of mountains"
                           src="https://ionicframework.com/docs/img/demos/thumbnail.svg" />

                      <img width="auto"
                           height="auto"
                           *ngIf="groupApp && currentUser && currentUser.profile_image && currentUser.profile_image.optimized"
                           [src]="'https://primg.deliverymanager.gr/'+ groupApp.group + '/'+ currentUser.customer_id + '/' + currentUser.profile_image.filename" />
                    </ion-avatar>
                    <ion-label style="color: white; font-weight: bold">
                      Οι συμμετοχές μου
                      <!-- <p style="color: white">{{drawsSum}}</p> -->
                    </ion-label>
                    <ion-button class="drawsSum"
                                style="cursor: none"
                                shape="round">
                      {{drawsSum}}
                    </ion-button>
                  </ion-item>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
    <!-- end of contests card -->

    <!-- start of coupons card -->
    <ion-row *ngIf="currentUser">
      <ion-col>
        <ion-card *ngIf="customerCoupons && customerCoupons.length > 0">
          <ion-item-divider
                            style="color: black; background-color: var(--ion-color-primary); min-height: 75px; font-size: 18px; font-weight: bold;">
            {{'loyaltySystem.instore-coupons' | translate}}
            <ion-button slot="end"
                        fill="clear"
                        (click)="pageChange('loyaltySystem/loyalty-coupons')">
              <!-- {{'loyaltySystem.show-all' | translate}} -->
              <ion-icon size="large"
                        style="color: black;"
                        name="information-circle-outline"></ion-icon>
            </ion-button>
          </ion-item-divider>
          <ion-row *ngFor="let coupon of activeInStoreCoupons">
            <ion-col *ngIf="activeInStoreCoupons.length > 0 && currentUser">
              <app-loyalty-coupon-item [coupon]="coupon">
              </app-loyalty-coupon-item>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="activeInStoreCoupons.length === 0 || !activeInStoreCoupons">
            <ion-col class="ion-text-center">
              <ion-text>
                {{'loyaltySystem.no-instore-active-coupons' | translate}}
              </ion-text>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="activeInStoreCoupons.length === 0 || !activeInStoreCoupons"
                   class="ion-justify-content-center ion-align-items-center">
            <ion-col class="ion-text-center">
              <div style="max-width: 150px; display: inline-block">
                <ng-lottie (animationCreated)="noDiscountCouponAnimationCreated($event)"
                           [options]="noDiscountCouponOptions"></ng-lottie>
              </div>
            </ion-col>
          </ion-row>
        </ion-card>
      </ion-col>
    </ion-row>
    <!-- end of coupons card -->

    <ion-row class="loyalty-home-footer"> </ion-row>
  </ion-grid>
</ion-content>

<ion-button *ngIf="currentUser && instoreCoupons"
            shape="round"
            class="floating-card-button"
            (click)="openQrCodeModal()">
  <ion-icon name="qr-code-outline"></ion-icon>
  <ion-text style="margin-left: 10px; text-transform: uppercase">
    ΚΑΡΤΑ {{storeName}}</ion-text>
</ion-button>
<ion-button [disabled]="loginState === 'login_loading'"
            *ngIf="!currentUser && loginState && loginState === 'no_login'"
            shape="round"
            class="floating-card-button"
            (click)="presentMagicLoginPopup()">
  <ion-icon name="log-in-outline"></ion-icon>
  <ion-text style="margin-left: 10px; text-transform: uppercase">
    Σύνδεση</ion-text>
</ion-button>