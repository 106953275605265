import { Action } from "@ngrx/store";

export const SET_PAYMENT_GATEWAYS = "[PAYMENT GATEWAYS] Set Payment Gateways";
export const SET_CART_PAYMENT_GATEWAYS =
  "[PAYMENT GATEWAYS] Set Cart Payment GateWays";
export const CLEAR_PAYMENT_GATEWAYS =
  "[PAYMENT GATEWAYS] Clear Payment Gateways";
export const SET_SHOW_PAYMENT_CARDS = "[PAYMENT GATEWAYS] Set ShowPaymentCards";
export const SET_ALL_STORES_PAYMENT_GATEWAYS =
  "[PAYMENT GATEWAYS] Set All Stores PaymentGateways";
export const SET_SHOW_PAYMENT_CARDS_ADMIN_ONLY =
  "[PAYMENT GATEWAYS] Set ShowPaymentCardsAdminOnly";

export const SET_SHOW_PAYMENT_CARDS_CUSTOMER_ONLY =
  "[PAYMENT GATEWAYS] Set ShowPaymentCardsCustomerOnly";
export const SET_NEW_CARD_BILLING_INFO =
  "[PAYMENT GATEWAYS] Set New Card Billing Info";

export const SET_BANK_FRAME_LINK_FOR_OPEN =
  "[PAYMENT GATEWAYS] Set Bank Frame Link For Open";

export class SetPaymentGateWays implements Action {
  readonly type = SET_PAYMENT_GATEWAYS;
  constructor(public payload: any) {}
}

export class SetCartPaymentGateways implements Action {
  readonly type = SET_CART_PAYMENT_GATEWAYS;
  constructor(public payload: any) {}
}

export class SetAllStoresPaymentGateways implements Action {
  readonly type = SET_ALL_STORES_PAYMENT_GATEWAYS;
  constructor(public payload: any) {}
}

export class ClearPaymentGateWays implements Action {
  readonly type = CLEAR_PAYMENT_GATEWAYS;
  constructor() {}
}

export class SetShowPaymentCards implements Action {
  readonly type = SET_SHOW_PAYMENT_CARDS;
  constructor(public payload: any) {}
}

export class SetShowPaymentCardsAdminOnly implements Action {
  readonly type = SET_SHOW_PAYMENT_CARDS_ADMIN_ONLY;
  constructor(public payload: any) {}
}

export class SetShowPaymentCardsCustomerOnly implements Action {
  readonly type = SET_SHOW_PAYMENT_CARDS_CUSTOMER_ONLY;
  constructor(public payload: any) {}
}

export class SetNewCardBillingInfo implements Action {
  readonly type = SET_NEW_CARD_BILLING_INFO;
  constructor(public payload: any) {}
}

export class SetBankFrameLinkForOpen implements Action {
  readonly type = SET_BANK_FRAME_LINK_FOR_OPEN;
  constructor(public payload: any) {}
}

export type PaymentGateWaysActions =
  | SetPaymentGateWays
  | SetCartPaymentGateways
  | SetAllStoresPaymentGateways
  | ClearPaymentGateWays
  | SetShowPaymentCards
  | SetNewCardBillingInfo
  | SetShowPaymentCardsAdminOnly
  | SetShowPaymentCardsCustomerOnly
  | SetBankFrameLinkForOpen;
