import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { IonSlides } from "@ionic/angular";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import * as CategoriesSelectionActions from "../store/categoriesSelection.actions";
import * as fromApp from "../../../store/app.reducer";
import { auditTime, distinctUntilChanged, first } from "rxjs/operators";
import { Subscription } from "rxjs";
import * as selectors from "../../../store/app.selectors";

@Component({
  selector: "app-horizontal-categories",
  templateUrl: "./horizontal-categories.component.html",
  styleUrls: ["./horizontal-categories.component.scss"],
})
export class HorizontalCategoriesComponent implements OnInit, OnDestroy {
  public categories: any;
  @ViewChild("categoriesSlider") sliderComponent: IonSlides;
  public categoriesSlider: any;
  public currentScrollCategoryId: any;
  public storeInfo: any;
  public selectedCategoryId;
  public icons_categories_design: boolean;
  public icons_categories_design_only_vertical: boolean;
  public categoryIconLink = "https://data.deliverymanager.gr/categoriesIcons/";
  public windowDimensions;
  public sliderOptions = {
    allowTouchMove: true,
    slidesPerView: "auto",
    direction: "horizontal",
    slideToClickedSlide: true,
    mousewheel: true,
    freeMode: true,
    freeModeMomentumBounce: false,
    freeModeMomentumRatio: 0.8,
    freeModeMomentumVelocityRatio: 0.8,

    centeredSlides: true,
    spaceBetween: 20,
  };

  public noIconssliderOptions = {
    allowTouchMove: true,
    slidesPerView: "auto",
    direction: "horizontal",
    slideToClickedSlide: false,
    mousewheel: false,
    freeMode: true,
    freeModeMomentumBounce: false,
    freeModeMomentumRatio: 0.8,
    freeModeMomentumVelocityRatio: 0.8,
    centeredSlides: true,
    spaceBetween: 10,
  };
  private waitForCategoriesToSlide = false;

  private subscriptions: Subscription[] = [];

  constructor(private store: Store<fromApp.AppState>) {}

  ngOnInit() {
    console.log("horizontal Cat", this.categories);

    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .subscribe((storeInfo) => {
          if (storeInfo && !_.isEqual(this.storeInfo, storeInfo)) {
            this.storeInfo = _.cloneDeep(storeInfo);

            this.icons_categories_design = !!_.cloneDeep(
              this.storeInfo.mobileorder_icons_categories_design
            );
            this.icons_categories_design_only_vertical = !!_.cloneDeep(
              this.storeInfo.icons_categories_design_only_vertical
            );
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("catalog")

        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.catalog &&
            !_.isEqual(this.categories, state.catalog)
          ) {
            this.categories = _.cloneDeep(state.catalog);

            if (this.waitForCategoriesToSlide) {
              this.slideToCategoryForOnlySelectedCategorySystem();
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("windowInfo")

        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.windowDimensions &&
            !_.isEqual(this.windowDimensions, state.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("verticalCategory")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(
              this.currentScrollCategoryId,
              state.selectedScrollCategoryId
            )
          ) {
            this.currentScrollCategoryId = _.cloneDeep(
              state.selectedScrollCategoryId
            );
            let key = _.findIndex(this.categories, {
              category_id: this.currentScrollCategoryId,
            });

            if (this.sliderComponent && key !== -1) {
              this.sliderComponent.getSwiper().then((swiper) => {
                swiper.slideTo(key);
              });
            }
          }

          if (
            state &&
            !_.isEqual(this.selectedCategoryId, state.selectedCategoryId)
          ) {
            this.selectedCategoryId = _.cloneDeep(state.selectedCategoryId);
            let key = _.findIndex(this.categories, {
              category_id: this.selectedCategoryId,
            });
            if (
              this.storeInfo &&
              this.storeInfo.design_settings &&
              this.storeInfo.design_settings.showOnlySelectedCategoryProducts &&
              key !== -1
            ) {
              if (this.sliderComponent) {
                this.sliderComponent.getSwiper().then((swiper) => {
                  swiper.slideTo(key);
                });
              }
            } else if (!this.storeInfo || !this.categories || key === -1) {
              this.store
                .select(selectors.getStoreInfo)
                .pipe(first())
                .subscribe((storeInfo) => {
                  if (
                    storeInfo &&
                    storeInfo.design_settings &&
                    storeInfo.design_settings.showOnlySelectedCategoryProducts
                  ) {
                    console.log("key", key, this.categories);
                    this.waitForCategoriesToSlide = true;
                  }
                })
                .unsubscribe();
            }
          }
        })
    );
    // setTimeout(() => {
    //   console.log(this.sliderComponent);
    //   this.sliderComponent.getSwiper().then((swiper) => {
    //     this.categoriesSlider = swiper;
    //   });

    //   this.categoriesSlider.removeAllSlides();
    // }, 3000);
  }

  slideToCategoryForOnlySelectedCategorySystem() {
    this.waitForCategoriesToSlide = false;
    let key = _.findIndex(this.categories, {
      category_id: this.selectedCategoryId,
    });

    if (this.sliderComponent && key !== -1) {
      this.sliderComponent.getSwiper().then((swiper) => {
        swiper.slideTo(key);
      });
    }
  }

  trackFn(inx, item) {
    return item.category_id;
  }

  categorySelection(cat) {
    if (cat) {
      this.store.dispatch(
        new CategoriesSelectionActions.SetSelectedCategory(
          _.cloneDeep(cat.category_id)
        )
      );
      if (navigator.cookieEnabled) {
        localStorage.setItem("selectedCategory", cat.category_id);
      }
      this.store.dispatch(
        new CategoriesSelectionActions.SetScrollSelectedCategory(
          _.cloneDeep(null)
        )
      );
    } else {
      if (navigator.cookieEnabled) {
        if (localStorage.getItem("selectedCategory")) {
          localStorage.removeItem("selectedCategory");
        }
      }
    }
  }

  ngOnDestroy() {
    if (this.categories && this.categories.length) {
      this.store.dispatch(
        new CategoriesSelectionActions.SetSelectedCategory(
          _.cloneDeep(this.categories[0].category_id)
        )
      );
      this.store.dispatch(
        new CategoriesSelectionActions.SetScrollSelectedCategory(
          _.cloneDeep(null)
        )
      );
    }
    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }
}
