import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { Subscription } from "rxjs";
import * as _ from "lodash";
import { AnimationOptions } from "ngx-lottie";

@Component({
  selector: "app-scan-login-modal",
  templateUrl: "./scan-login-modal.component.html",
  styleUrls: ["./scan-login-modal.component.scss"],
})
export class ScanLoginModalComponent implements OnInit, OnDestroy {
  @Input() scan_title: string;
  @Input() scan_user: any;
  @Input() isCustomer: boolean;
  private unsubscribeBackEvent: Subscription;

  loginAnimationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/login-get-coupon.json",
  };
  constructor(
    private modalController: ModalController,
    private platform: Platform
  ) {}

  ngOnInit() {
    if (this.scan_user && this.scan_user.formatted_address) {
      this.scan_user.save_address = true;
    }
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.onClickCancel();
        }
      }
    );
  }

  animationCreated(ev) {}

  onClickOk() {
    this.modalController.dismiss(
      _.cloneDeep(this.scan_user),
      "",
      "scanLoginModal"
    );
  }

  closeModal() {
    this.modalController.dismiss();
  }

  onClickCancel() {
    this.modalController.dismiss();
  }

  ngOnDestroy() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
  }
}
