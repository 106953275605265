<ion-header>
  <app-no-internet-connection></app-no-internet-connection>
  <ion-toolbar>
    <ion-title>{{"reservations.new-reservation" | translate}}</ion-title>
    <ion-buttons slot="start">
      <ion-back-button [defaultHref]="'/reservations'"></ion-back-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- <ion-grid style="display: flex; align-items: center; height: 100vh"> -->
  <ion-grid>
    <ion-row style="width: 100%" class="ion-justify-content-center">
      <ion-col size-xl="4" size-md="6" size-xs="12" style="display: flex; justify-content: center;">
        <ion-card style="height: 90px; width: 330px">
          <ion-item-divider
            style="margin-bottom: 4px"
            mode="ios"
            class="ion-text-center"
            >{{ "reservations.date" | translate }}</ion-item-divider
          >
          <ion-item
            [disabled]="!reservationDays || !reservationDays.length"
            (click)="chooseDate()"
            button
            lines="none"
            style="width: 100%"
          >
            <ion-label style="margin-right: 6px"
              >{{ "select" | translate }}</ion-label
            >
            <ion-label slot="end" class="ion-text-end"
              ><ion-text
                style="position: relative; bottom: 8px"
                *ngIf="reservationMoment"
                >{{ reservationDateString }}</ion-text
              ><ion-icon
                style="margin-left: 6px; margin-right: 6px"
                slot="end"
                size="large"
                [ios]="'calendar' + '-outline'"
                [md]="'calendar' + '-sharp'"
              ></ion-icon
            ></ion-label>
          </ion-item>
        </ion-card>
      </ion-col>

      <ion-col size-xl="4" size-md="6" size-xs="12" style="display: flex; justify-content: center;">
        <ion-card style="height: 90px; width: 330px">
          <ion-item-divider
            mode="ios"
            style="margin-bottom: 3px"
            class="ion-text-center"
            >{{ "reservations.reservation_area" | translate }}</ion-item-divider
          >
          <ion-item
            lines="none"
            style="width: 100%; position: relative; top: 4px"
          >
            <ion-label style="margin-right: 6px"
              >{{ "select" | translate }}</ion-label
            >
            <ion-select
              cancelText="{{ 'cancel' | translate }}"
              [(ngModel)]="selectedCategory"
              [class]="!selectedCategory ? 'selectPlaceHolderDanger' : ''"
              placeholder="{{ 'please-select' | translate }}"
              [disabled]="loading"
              (ionChange)="onSelect()"
            >
              <ion-select-option value="all"
                >{{ "reservations.all" | translate }}
              </ion-select-option>
              <ion-select-option
                *ngFor="let category of allCategories"
                [value]="category.category_id"
                >{{ category.category_id === "none" ?
                ("reservations.without_category" | translate) :
                category.category_name[selectedLanguage] }}
              </ion-select-option>
            </ion-select>
          </ion-item>
        </ion-card>
      </ion-col>
      <ion-col size-xl="4" size-md="6" size-xs="12" style="display: flex; justify-content: center;">
        <ion-card style="height: 90px; width: 330px">
          <ion-item-divider
            mode="ios"
            style="margin-bottom: 3px"
            class="ion-text-center"
            >{{ "reservations.seats" | translate }}</ion-item-divider
          >
          <ion-item
            lines="none"
            style="width: 100%; position: relative; top: 4px"
          >
            <ion-label style="margin-right: 6px"
              >{{ "select" | translate }}</ion-label
            >
            <app-change-quantity-input
              style="margin: 0px"
              [buttonsSize]="'small'"
              (changingQuantity)="changeChoiceQuantity($event)"
              [quantity]="seatsSelected"
            ></app-change-quantity-input>
            <ion-button (click)="clearSeatsSelected()" [disabled]="!seatsSelected || seatsSelected === '-'" fill="clear">
              <ion-icon slot="icon-only" [color]="!seatsSelected || seatsSelected === '-' ? 'grey' : 'danger'" name="close"></ion-icon>
            </ion-button>
          </ion-item>
        </ion-card>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="prepay_per_person">
      <ion-col class="ion-text-center">
        <ion-button [disabled]="loading" (click)="createReservationWithoutTable()">{{ "reservations.create_reservation_without_table" | translate }}</ion-button>
      </ion-col>
    </ion-row>

    <ion-row class="ion-justify-content-center" style="width: 100%">
      <p style="margin-left: 6px; max-width: 700px; text-align: justify;">
        <b>{{ "reservations.note" | translate }}:</b> {{
        "reservations.prepay_amount_text" | translate }}
      </p>
    </ion-row>

    <div
      *ngIf="!loading"
      style="width: 100%; margin-bottom: 20px; min-width: 300px"
      class="ion-justify-content-center"
    >
      <div *ngFor="let category of allCategories">
        <div
          style="
            margin-bottom: 4px;
            border: 2px solid black;
            border-radius: 12px;
          "
          *ngIf="allTables[category.category_id] && allTables[category.category_id].length && selectedCategories[category.category_id] && (allTables[category.category_id] | reservationTables:seatsSelected).length"
        >
          <ion-item-divider
            (click)="onDividerClick(category)"
            style="cursor: pointer; border-radius: 12px 12px 0px 0px"
            mode="ios"
            [class]="openCategories[category.category_id] ? 'open' : 'closed'"
            >{{ (category.category_id === "none" ?
            ("reservations.without_category" | translate ) :
            category.category_name[selectedLanguage]) | titlecase }}<ion-icon
              slot="end"
              [name]="openCategories[category.category_id] ? 'chevron-down-outline' : 'chevron-up-outline'"
            ></ion-icon
          ></ion-item-divider>
          <ion-grid
            style="padding: 0px"
            *ngIf="openCategories[category.category_id]"
          >
            <ion-row style="width: 100%">
              <ion-col
                style="padding: 0px"
                *ngFor="let table of allTables[category.category_id] | reservationTables:seatsSelected; trackBy: trackFn"
                size-xl="4"
                size-md="6"
                size-sm="12"
              >
                <ion-card
                  style="margin: 6px; max-width: 350px; min-width: 250px;"
                  [class]="selectedTable && table.table_id === selectedTable.table_id ? '' : ''"
                >
                  <div style="position: relative; height: 188px">
                    <p class="lezanta" style="margin: 0px">
                      {{ table.name[selectedLanguage] }}
                    </p>
                    <ion-badge style="pointer-events: none" class="money-badge"
                      >{{table.availablePeriod &&
                      (table.availablePeriod.prepay_amount ||
                      table.availablePeriod.prepay_amount === 0)?
                      table.availablePeriod.prepay_amount : table.prepay_amount
                      || 0 }} €</ion-badge
                    >
                    <ion-badge
                      (click)="showMaximumPeopleToast(table.seats, table.max_seats)"
                      [title]="'reservations.recommended_people' | translate"
                      class="people-badge"
                      >{{ table.seats }}<ion-text *ngIf="table.max_seats && table.seats !== table.max_seats">
                        - {{ table.max_seats }}</ion-text
                      >&nbsp;<ion-icon
                        name="person-outline"
                        style="
                          position: relative;
                          top: 2px;
                          pointer-events: none;
                        "
                      ></ion-icon
                    ></ion-badge>
                    <img
                      *ngIf="table.image"
                      loading="lazy"
                      class="imageDimensions"
                      [src]="
                        'https://d2mxhjcn1mi2et.cloudfront.net/' +
                        table.store_id +
                        '/' +
                        table.image
                      "
                    />
                    <div
                      *ngIf="!table.image"
                      class="noImage ion-text-wrap"
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                      "
                    >
                      {{ "no_image" | translate }}
                    </div>
                  </div>
                  <p
                    *ngIf="!table.availablePeriod && reservationTimestamp"
                    class="period"
                  >
                    <b
                      ><ion-text color="danger"
                        >{{ "reservations.not_available" | translate
                        }}</ion-text
                      ></b
                    >
                  </p>
                  <p *ngIf="table.availablePeriod" class="period">
                    <b
                      >{{ table.availablePeriod.start | date:'HH:mm' }} - {{
                      table.availablePeriod.end | date:'HH:mm' }}</b
                    >
                  </p>
                  <ion-grid style="padding: 0px">
                    <ion-row>
                      <ion-col class="right" *ngIf="reservationTimestamp">
                        <ion-button
                          (click)="tableClicked(table)"
                          style="width: 100%"
                          [disabled]="!table.calendar || !table.calendar.length || !table.availablePeriod"
                          expand="full"
                          >{{ "select" | translate }}</ion-button
                        >
                      </ion-col>
                      <ion-col [class]="reservationTimestamp ? 'left' : 'full'">
                        <ion-button
                          (click)="showTableInfoModal(table)"
                          expand="full"
                          >{{ "reservations.information" | translate
                          }}</ion-button
                        >
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </ion-card>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </div>
    </div>
    <div *ngIf="loading" style="width: 100%" class="ion-text-center">
      <ion-spinner></ion-spinner>
    </div>
  </ion-grid>
</ion-content>
