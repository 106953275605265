import * as OrderCommentsActions from "./orderComments.actions";
import * as _ from "lodash";

export interface State {
  generalComments: any;
}

const initialState: State = {
  generalComments: null,
};

export function orderCommentsReducer(
  state = initialState,
  action: OrderCommentsActions.OrderCommentsActions
) {
  switch (action.type) {
    case OrderCommentsActions.SET_ORDER_COMMENTS:
      return {
        ...state,
        generalComments: action.payload,
      };

    default:
      return state;
  }
}
