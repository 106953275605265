import * as AuthActions from "./auth.actions";
import * as _ from "lodash";

export interface State {
  user: any;
  mo_token: any;
  migration_customer_id: any;
  newCustomer: any;
  customerOrders: any;
  kioskCustomerOrders: any;
  loginLoading: any;
  logoutFlagTimestamp: any;
  internetConnectedFlag: boolean;
  ionicUpdateErrorFlag: string;
  closeFirebaseRecaptchaModal: boolean;
  freshLogin: boolean;
  loginState: string;
  reconnectSocketTimestamp: any;
  triggerRenewTokenTimestamp: any;
}

const initialState: State = {
  user: null,
  mo_token: null,
  migration_customer_id: null,
  newCustomer: null,
  customerOrders: null,
  kioskCustomerOrders: null,
  logoutFlagTimestamp: null,
  loginLoading: null,
  internetConnectedFlag: true,
  ionicUpdateErrorFlag: null,
  closeFirebaseRecaptchaModal: false,
  freshLogin: false,
  loginState: null,
  reconnectSocketTimestamp: null,
  triggerRenewTokenTimestamp: null,
};

export function authReducer(
  state = initialState,
  action: AuthActions.AuthActions
) {
  switch (action.type) {
    case AuthActions.SET_USER:
      // console.log("SET_USER_ACTION", action.payload);
      return {
        ...state,
        user: action.payload,
      };

    case AuthActions.SET_NEW_CUSTOMER:
      return {
        ...state,
        newCustomer: action.payload,
      };

    case AuthActions.SET_MIGRATION_CUSTOMER_ID:
      return {
        ...state,
        migration_customer_id: action.payload,
      };

    case AuthActions.SET_CUSTOMER_ORDERS:
      return {
        ...state,
        customerOrders: action.payload,
      };

    case AuthActions.SET_KIOSK_CUSTOMER_ORDERS:
      return {
        ...state,
        kioskCustomerOrders: action.payload,
      };

    case AuthActions.SET_INTERNET_CONNECTED_FLAG:
      return {
        ...state,
        internetConnectedFlag: action.payload,
      };

    case AuthActions.SET_IONIC_UPDATE_ERROR_FLAG:
      return {
        ...state,
        ionicUpdateErrorFlag: action.payload,
      };

    case AuthActions.SET_MO_TOKEN:
      return {
        ...state,
        mo_token: action.payload,
      };

    case AuthActions.SET_FRESH_LOGIN:
      return {
        ...state,
        freshLogin: action.payload,
      };
    case AuthActions.SET_LOGIN_LOADING:
      return {
        ...state,
        loginLoading: action.payload,
      };
    case AuthActions.SET_LOGOUT_FLAG_TIMESTAMP:
      return {
        ...state,
        logoutFlagTimestamp: action.payload,
      };
    case AuthActions.SET_RECONNECT_SOCKET_TIMESTAMP:
      return {
        ...state,
        reconnectSocketTimestamp: action.payload,
      };

    case AuthActions.SET_TRIGGER_RENEW_TOKEN_TIMESTAMP:
      return {
        ...state,
        triggerRenewTokenTimestamp: action.payload,
      };

    case AuthActions.CLEAR_USER:
      return {
        ...state,
        user: null,
      };

    case AuthActions.CLEAR_NEW_CUSTOMER:
      return {
        ...state,
        newCustomer: null,
      };

    case AuthActions.CLEAR_MO_TOKEN:
      return {
        ...state,
        mo_token: null,
      };

    case AuthActions.SET_LOGIN_STATE:
      return {
        ...state,
        loginState: action.payload,
      };

    default:
      return state;
  }
}
