<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">{{
      "popups.billing-info.verification" | translate
    }}</ion-title>
    <ion-buttons slot="end">
      <ion-button fill="clear" color="dark" (click)="closeModal()">
        <ion-icon src="assets/ionicons/close.svg" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list *ngIf="verifyAddressRes && verifyAddressRes.billingInfo">
    <ion-item
      lines="none"
      *ngIf="verifyAddressRes.billingInfo.gateway_id === 'piraeus'"
    >
      <strong>
        {{ "popups.billing-info.card-holder-name" | translate }}
        :
      </strong>
      {{ verifyAddressRes.billingInfo.CardHolderName }}
    </ion-item>
    <ion-item lines="none">
      <strong> {{ "popups.billing-info.address" | translate }}:&nbsp;</strong>
      {{ verifyAddressRes.billingInfo.address }}
    </ion-item>
    <ion-item lines="none">
      <strong>
        {{ "popups.billing-info.postal_code" | translate }}:&nbsp;</strong
      >
      {{ verifyAddressRes.billingInfo.postal_code }}
    </ion-item>
    <ion-item lines="none">
      <strong> {{ "popups.billing-info.city" | translate }}:&nbsp;</strong>
      {{ verifyAddressRes.billingInfo.city }}
    </ion-item>
    <ion-item lines="none">
      <strong> {{ "popups.billing-info.state" | translate }}:&nbsp;</strong>
      {{ verifyAddressRes.billingInfo.state }}
    </ion-item>
    <ion-item lines="none">
      <strong>
        {{ "popups.billing-info.countryCode" | translate }}:&nbsp;
      </strong>
      {{ verifyAddressRes.billingInfo.countryCode }}
    </ion-item>
    <ion-item lines="none">
      <strong> {{ "popups.billing-info.mobile" | translate }}:&nbsp;</strong>
      {{ verifyAddressRes.mobile }}
    </ion-item>
    <!-- We try to hide this message, if this message is required we need to show it again -->
    <!-- <ion-item lines="none">
      <ion-label
        style="font-size: x-small; line-height: 1.2"
        class="ion-text-wrap"
      >
        <strong>
          {{ "popups.billing-info.verification-message" | translate }}</strong
        >
      </ion-label>
    </ion-item> -->
  </ion-list>
</ion-content>

<ion-footer>
  <ion-grid>
    <ion-row style="width: 100%">
      <ion-col (click)="onClick('fix')" size="6">
        <ion-button color="danger">
          {{ "popups.billing-info.fix" | translate }}
        </ion-button>
      </ion-col>
      <ion-col (click)="onClick('continue')" class="ion-text-right">
        <ion-button>
          {{ "popups.billing-info.continue" | translate }}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
