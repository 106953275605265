<ion-item
  button
  lines="none"
  detail="false"
  *ngIf="option"
  (click)="openSelectionModal(option.option_id)"
>
  <ion-row class="ion-no-padding ion-no-margin" style="width: 100%">
    <ion-col
      class="ion-no-padding ion-no-margin"
      size="12"
      class="ion-justify-content-center ion-text-right"
    >
      <ion-badge
        *ngIf="
          optionValidationObject &&
          optionValidationObject.errorArray &&
          optionValidationObject.errorArray.length > 0
        "
        color="danger"
      >
        <ion-text style="font-size: smaller">
          {{ optionValidationObject.errorArray[0] }}
        </ion-text></ion-badge
      >
    </ion-col>
    <ion-col
      class="ion-text-right"
      size="12"
      *ngIf="
        selectedProductFreeParentOption &&
        (!option.dependenciesVariable ||
          option.dependenciesVariable.length === 0) &&
        option.parent_free &&
        option.option_parent_total_price === 0
      "
    >
      <ion-badge color="danger"
        ><ion-text>
          {{ "product-details.options.free" | translate }}
        </ion-text></ion-badge
      >
    </ion-col>
    <ion-col
      class="ion-text-right"
      size="12"
      *ngIf="
        selectedProductFreeParentOption &&
        (!option.dependenciesVariable ||
          option.dependenciesVariable.length === 0) &&
        option.parent_free &&
        option.option_parent_total_price &&
        option.option_total_price &&
        toFloat(option.option_total_price) >
          toFloat(option.option_parent_total_price)
      "
    >
      <
      <ion-badge color="danger">
        <ion-text>
          {{ option.option_parent_total_price }}€ ({{
            selectedProductParentDiscount
          }}% {{ "discount" | translate }})
        </ion-text>
      </ion-badge>
    </ion-col>

    <ion-col class="ion-no-padding ion-no-margin" size="12">
      <ion-label>
        <strong>
          {{ option.title }}
          <span
            *ngIf="option.is_require && option.availableTotal"
            style="font-weight: bold"
            >*</span
          >
        </strong>

        <p *ngIf="option && option.description">{{ option.description }}</p>
        <div
          *ngIf="
            option.promo ||
            option.pickup ||
            option.tags ||
            !option.availableTotal
          "
        >
          <app-product-option-tags
            [selectedProductPromo]="selectedProductPromo"
            [groupStoreData]="groupStoreData"
            [optionItem]="option"
          ></app-product-option-tags>
        </div>
        <p *ngFor="let choice of selectedChoices">
          <span *ngIf="option.quantityInChoices && choice.quantity > 1"
            >{{ choice.quantity }} x</span
          >
          {{ choice.title }}

          <span
            *ngIf="
              (orderPickup == false && dineIn == false) ||
              (orderPickup == null && dineIn == null) ||
              (orderPickup == true &&
                dineIn == false &&
                !choice.price_takeaway) ||
              (orderPickup == false &&
                dineIn == true &&
                (!alt_dinein_active ||
                  !selectedDineInTable ||
                  !selectedDineInTable.alt_dinein) &&
                !choice.price_dinein) ||
              (orderPickup == false &&
                dineIn == true &&
                alt_dinein_active &&
                selectedDineInTable &&
                selectedDineInTable.alt_dinein &&
                !choice.price_alt_dinein)
            "
          >
            <span *ngIf="choice.price.toString().trim().replace(',', '.') > 0"
              >{{ !option.changesBasePrice ? "+" : ""
              }}{{ choice.price.toString().trim().replace(",", ".") }}€</span
            >
            <span *ngIf="choice.price.toString().trim().replace(',', '.') < 0"
              >{{
                choice.price.toString().trim().replace(",", ".")
                  | number : "1.2-2"
              }}€</span
            >
          </span>

          <span
            *ngIf="
              orderPickup == true && dineIn == false && choice.price_takeaway
            "
          >
            <span
              *ngIf="
                choice.price_takeaway.toString().trim().replace(',', '.') > 0
              "
              >{{ !option.changesBasePrice ? "+" : ""
              }}{{
                choice.price_takeaway.toString().trim().replace(",", ".")
              }}€</span
            >
            <span
              *ngIf="
                choice.price_takeaway.toString().trim().replace(',', '.') < 0
              "
              >{{
                choice.price_takeaway.toString().trim().replace(",", ".")
                  | number : "1.2-2"
              }}€</span
            >
          </span>

          <span
            *ngIf="
              orderPickup == false &&
              dineIn == true &&
              (!alt_dinein_active ||
                !selectedDineInTable ||
                !selectedDineInTable.alt_dinein ||
                !choice.price_alt_dinein) &&
              choice.price_dinein
            "
          >
            <span
              *ngIf="
                choice.price_dinein.toString().trim().replace(',', '.') > 0
              "
              >{{ !option.changesBasePrice ? "+" : ""
              }}{{
                choice.price_dinein.toString().trim().replace(",", ".")
              }}€</span
            >
            <span
              *ngIf="
                choice.price_dinein.toString().trim().replace(',', '.') < 0
              "
              >{{
                choice.price_dinein.toString().trim().replace(",", ".")
                  | number : "1.2-2"
              }}€</span
            >
          </span>

          <span
            *ngIf="
              orderPickup == false &&
              dineIn == true &&
              alt_dinein_active &&
              selectedDineInTable &&
              selectedDineInTable.alt_dinein &&
              choice.price_alt_dinein
            "
          >
            <span
              *ngIf="
                choice.price_alt_dinein.toString().trim().replace(',', '.') > 0
              "
              >{{ !option.changesBasePrice ? "+" : ""
              }}{{
                choice.price_alt_dinein.toString().trim().replace(",", ".")
              }}€</span
            >
            <span
              *ngIf="
                choice.price_alt_dinein.toString().trim().replace(',', '.') < 0
              "
              >{{
                choice.price_alt_dinein.toString().trim().replace(",", ".")
                  | number : "1.2-2"
              }}€</span
            >
          </span>
        </p>
        <ion-text
          color="danger"
          *ngIf="
            !selectedChoices ||
            (selectedChoices && selectedChoices.length === 0)
          "
        >
          <p>
            {{ "product-details.no-choice" | translate }}
          </p>
        </ion-text>
      </ion-label>
    </ion-col>
  </ion-row>
  <ion-icon
    slot="end"
    src="assets/ionicons/chevron-down-outline.svg"
  ></ion-icon>
</ion-item>
