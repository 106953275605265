import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../../store/app.reducer";
import * as selectors from "../../../../store/app.selectors";

import * as _ from "lodash";
import { auditTime, debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Subscription } from "rxjs";
import * as HyperCategoriesActions from "../../../../store/hyperCategories/hyperCategories.actions";
import { ModalController, Platform } from "@ionic/angular";
import * as CatalogActions from "../../../store/catalog.actions";
import * as CategoriesSelectionActions from "../../../categories/store/categoriesSelection.actions";

@Component({
  selector: "app-hyper-categories-selection-modal",
  templateUrl: "./hyper-categories-selection-modal.component.html",
  styleUrls: ["./hyper-categories-selection-modal.component.scss"],
})
export class HyperCategoriesSelectionModalComponent
  implements OnInit, OnDestroy
{
  @Input() fontSize: any;

  public hyperCategories: any;
  public allCategories: any;
  public hyperCategoryIconsLink =
    "https://data.deliverymanager.gr/hyperCategoriesIcons/";
  public storeInfo;
  public selectedHyperCategory: any;
  public windowDimensions: any;
  public step = "hyperCategoriesSelection";
  public categories;
  public selectedCategoryId;
  public selectedHyperCatObject;
  private subscription: Subscription;
  private subscription3: Subscription;
  private subscription2: Subscription;
  private subscription4: Subscription;
  private subscription5: Subscription;
  private unsubscribeBackEvent: Subscription;

  constructor(
    private store: Store<fromApp.AppState>,
    private modalController: ModalController,
    private platform: Platform
  ) {}

  closeModal() {
    this.modalController.dismiss();
  }

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.modalController.dismiss();
        }
      }
    );
    this.subscription = this.store
      .select("hyperCategories")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (
          state &&
          state.hyperCategories &&
          !_.isEqual(this.hyperCategories, state.hyperCategories)
        ) {
          this.hyperCategories = _.cloneDeep(state.hyperCategories);
        }
        if (
          state &&
          state.currentHyperCategory &&
          !_.isEqual(this.selectedHyperCategory, state.currentHyperCategory)
        ) {
          this.selectedHyperCategory = _.cloneDeep(state.currentHyperCategory);
          console.log("testa", this.selectedHyperCategory);
        }
      });

    this.subscription4 = this.store
      .select("catalog")
      .pipe(distinctUntilChanged())
      .pipe(debounceTime(600))
      .subscribe((state) => {
        if (
          state &&
          state.catalog &&
          !_.isEqual(this.categories, state.catalog)
        ) {
          if (
            this.step &&
            this.step === "categoriesSelection" &&
            state.catalog &&
            state.catalog.length === 1
          ) {
            this.categorySelection(_.cloneDeep(state.catalog[0]));
            this.modalController.dismiss();
          }
          this.categories = _.cloneDeep(state.catalog);
        }
      });

    this.subscription5 = this.store
      .select("verticalCategory")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.selectedCategoryId &&
          !_.isEqual(this.selectedCategoryId, state.selectedCategoryId)
        ) {
          this.selectedCategoryId = _.cloneDeep(state.selectedCategoryId);
        }
      });

    this.subscription2 = this.store
      .select(selectors.getStoreInfo)
      .pipe(distinctUntilChanged())
      .subscribe((storeInfo) => {
        if (storeInfo && !_.isEqual(this.storeInfo, storeInfo)) {
          this.storeInfo = _.cloneDeep(storeInfo);
        }
      });

    this.subscription3 = this.store
      .select("windowInfo")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.windowDimensions &&
          !_.isEqual(this.windowDimensions, state.windowDimensions)
        ) {
          this.windowDimensions = _.cloneDeep(state.windowDimensions);
          if (this.windowDimensions && this.windowDimensions.width > 1250) {
            this.closeModal();
          }
        }
      });

    this.store.dispatch(
      new CatalogActions.SetHyperCategoriesSelectionModalOpened(true)
    );
  }

  nameToLowerCase(name) {
    return name.toLowerCase();
  }

  trackFn(inx) {
    return inx;
  }

  HyperCategorySelection(hyperCat) {
    if (hyperCat) {
      if (this.selectedHyperCategory !== hyperCat.hyper_category_id) {
        this.categories = null;
        this.step = "categoriesSelection";
        this.store.dispatch(
          new HyperCategoriesActions.SetCurrentHyperCategory(
            _.cloneDeep(hyperCat.hyper_category_id)
          )
        );

        if (navigator.cookieEnabled) {
          localStorage.setItem(
            "selectedHyperCategory",
            hyperCat.hyper_category_id
          );
        }
      } else {
        this.step = "categoriesSelection";
        if (this.categories && this.categories.length === 1) {
          this.categorySelection(this.categories[0]);
          this.modalController.dismiss();
        }
      }
    } else {
      this.store.dispatch(
        new HyperCategoriesActions.SetCurrentHyperCategory(_.cloneDeep(null))
      );
      if (navigator.cookieEnabled) {
        if (localStorage.getItem("selectedHyperCategory")) {
          localStorage.removeItem("selectedHyperCategory");
        }
      }
    }
  }

  goBack() {
    if (this.step === "categoriesSelection") {
      this.step = "hyperCategoriesSelection";
    }
  }

  categorySelection(category) {
    this.store.dispatch(
      new CategoriesSelectionActions.SetSelectedCategory(
        _.cloneDeep(category.category_id)
      )
    );

    this.modalController.dismiss();
  }

  ngOnDestroy() {
    if (this.subscription2) {
      this.subscription2.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscription3) {
      this.subscription3.unsubscribe();
    }
    if (this.subscription4) {
      this.subscription3.unsubscribe();
    }
    if (this.subscription5) {
      this.subscription3.unsubscribe();
    }
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
  }
}
