import { Action } from "@ngrx/store";

export const SET_BRANCH_LINK_DATA = "[BRANCH LINK DATA] Set Branch Link Data";
export const SET_COUPON_RULE_ID = "[BRANCH LINK DATA] Set Coupon Rule Id";
export const SET_AFFILIATE_COUPON_CODE =
  "[BRANCH LINK DATA] Set Affiliate Coupon Code";
export const CLEAR_COUPON_RULE_ID = "[BRANCH LINK DATA] Clear Coupon Rule Id";
export const CLEAR_AFFILIATE_COUPON_CODE =
  "[BRANCH LINK DATA] Clear Affiliate Coupon Code";
export const CLEAR_BRANCH_LINK_DATA =
  "[BRANCH LINK DATA]  Clear Branch Link Data";
export const SET_TRIGGER_FOR_NEW_BRANCH_LINK_DATA =
  "[BRANCH LINK DATA]  SET TRIGGER FOR NEW BRANCH LINK DATA";

export class SetBranchLinkData implements Action {
  readonly type = SET_BRANCH_LINK_DATA;
  constructor(public payload: any) {}
}

export class SetTriggerForNewBranchLinkData implements Action {
  readonly type = SET_TRIGGER_FOR_NEW_BRANCH_LINK_DATA;
  constructor(public payload: any) {}
}

export class SetCouponRuleId implements Action {
  readonly type = SET_COUPON_RULE_ID;
  constructor(public payload: any) {}
}

export class SetAffiliateCouponCode implements Action {
  readonly type = SET_AFFILIATE_COUPON_CODE;
  constructor(public payload: any) {}
}

export class ClearCouponRuleId implements Action {
  readonly type = CLEAR_COUPON_RULE_ID;
  constructor() {}
}
export class ClearAffiliateCouponCode implements Action {
  readonly type = CLEAR_AFFILIATE_COUPON_CODE;
  constructor() {}
}

export class ClearBranchLinkData implements Action {
  readonly type = CLEAR_BRANCH_LINK_DATA;
  constructor() {}
}

export type BranchLinkDataActions =
  | SetBranchLinkData
  | SetCouponRuleId
  | ClearBranchLinkData
  | ClearCouponRuleId
  | SetAffiliateCouponCode
  | ClearAffiliateCouponCode
  | SetTriggerForNewBranchLinkData;
