<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">
      {{ "firebase-recaptcha-modal.security-check" | translate }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button color="dark" (click)="closeModal()" fill="clear">
        <ion-icon
          slot="icon-only"
          src="assets/ionicons/close-outline.svg"
        ></ion-icon
      ></ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<div id="recaptcha-container"></div>
