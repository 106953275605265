<ion-header>
  <app-no-internet-connection></app-no-internet-connection>
  <app-ionic-update-error></app-ionic-update-error>
  <ion-toolbar>
    <ion-buttons
      slot="start"
      *ngIf="groupApp && windowInfo"
    >
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>
    <ion-title>{{"pages.reservations" | translate}}</ion-title>
    <ion-buttons slot="end">
      <!-- <ion-button
        (click)="topView()"
        fill="clear"
      >
        <ion-icon
          slot="icon-only"
          src="assets/ionicons/close.svg"
        ></ion-icon>
      </ion-button> -->
      <ion-button (click)="addNewReservation()" fill="clear">
        <ion-icon
          slot="icon-only"
          src="assets/ionicons/add-outline.svg"
        ></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="windowInfo">
  <ion-grid>
    <ion-row
      *ngIf="loading && (!reservations || !reservations.length)"
      class="ion-text-center"
    >
      <ion-col size="12" class="ion-padding">
        <ion-spinner></ion-spinner>
      </ion-col>
    </ion-row>

    <ion-row
      *ngIf="!loading && (!reservations || !reservations.length)"
      class="ion-align-items-center ion-justify-content-center ion-no-margin ion-no-padding"
      style="height: 100%; width: 100%"
    >
      <ion-col class="ion-text-center" style="height: calc(50vh)">
        <ng-lottie
          style="z-index: 100"
          [options]="AnimationNoReservationsOptions"
          (animationCreated)="animationCreated($event)"
          (loopComplete)="animationLoopCompleted($event)"
        >
        </ng-lottie>
        <h5 style="margin-top: 36px; font-size: 24px" class="ion-no-margin">
          {{"reservations.no-reservations" | translate}}
        </h5>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="reservations && reservations.length">
      <ion-col size="12" class="ion-text-center">
        <app-reservations-item
          *ngFor="let reservation of reservations; trackBy: keyFunc"
          [res_id]="reservation.res_id"
          [lang]="selectedLanguage"
        ></app-reservations-item>
      </ion-col>
    </ion-row>
  </ion-grid>
  <div
    *ngIf="groupApp &&
      groupApp.design_settings &&
      groupApp.design_settings.menuFooterTabs && windowInfo && windowInfo.width<900"
    style="margin-top: 65px"
  ></div>
</ion-content>

<app-menu-footer-tabs></app-menu-footer-tabs>

<ion-footer
  *ngIf="windowDimensions && groupApp && (((!groupApp.design_settings || !groupApp.design_settings.menuFooterTabs) && windowDimensions.width>600) || (groupApp.design_settings && groupApp.design_settings.menuFooterTabs && windowDimensions.width>=900))"
>
  <ion-grid>
    <ion-row style="width: 100%">
      <ion-col class="ion-text-center">
        <ion-button (click)="addNewReservation()">
          <ion-text>{{"reservations.new-reservation" | translate}}</ion-text>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
