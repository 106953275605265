import { Action } from "@ngrx/store";

export const SET_USER = "[AUTH] Set User";
export const SET_MO_TOKEN = "[AUTH] Set mo_token";
export const SET_NEW_CUSTOMER = "[AUTH] Set New Customer";
export const SET_MIGRATION_CUSTOMER_ID = "[AUTH] Set migration customer ID";
export const SET_LOGOUT_FLAG_TIMESTAMP = "[AUTH] Set Logout Flag timestamp";
export const SET_LOGIN_LOADING = "[AUTH] Set Login Loading";
export const SET_INTERNET_CONNECTED_FLAG = "[AUTH] Set Internet Connected Flag";
export const SET_IONIC_UPDATE_ERROR_FLAG = "[AUTH] Set Ionic Update Error Flag";
export const SET_FRESH_LOGIN = "[AUTH] Set Fresh Login";
export const SET_CUSTOMER_ORDERS = "[AUTH] Set Customer Orders(renew token)";
export const SET_KIOSK_CUSTOMER_ORDERS =
  "[AUTH] Set Kiosk Customer Orders(renew token)";
export const SET_LOGIN_STATE = "[AUTH] Set Login State";
export const SET_RECONNECT_SOCKET_TIMESTAMP =
  "[AUTH] Set Reconnect Socket Timestamp";
export const SET_TRIGGER_RENEW_TOKEN_TIMESTAMP =
  "[AUTH] Set Trigger Renew Token Timestamp";

export const CLEAR_USER = "[AUTH] Clear User";
export const CLEAR_MO_TOKEN = "[AUTH] Clear mo_token";
export const CLEAR_NEW_CUSTOMER = "[AUTH] Clear New Customer";

export class SetUser implements Action {
  readonly type = SET_USER;
  constructor(public payload: any) {}
}

export class SetMoToken implements Action {
  readonly type = SET_MO_TOKEN;
  constructor(public payload: any) {}
}

export class SetMigrationCustomerId implements Action {
  readonly type = SET_MIGRATION_CUSTOMER_ID;
  constructor(public payload: any) {}
}

export class SetNewCustomer implements Action {
  readonly type = SET_NEW_CUSTOMER;
  constructor(public payload: any) {}
}

export class SetCustomerOrders implements Action {
  readonly type = SET_CUSTOMER_ORDERS;
  constructor(public payload: any) {}
}

export class SetKioskCustomerOrders implements Action {
  readonly type = SET_KIOSK_CUSTOMER_ORDERS;
  constructor(public payload: any) {}
}

export class SetLogoutFlagTimestamp implements Action {
  readonly type = SET_LOGOUT_FLAG_TIMESTAMP;
  constructor(public payload: any) {}
}

export class SetInternetConnectedFlag implements Action {
  readonly type = SET_INTERNET_CONNECTED_FLAG;
  constructor(public payload: any) {}
}

export class SetIonicUpdateErrorFlag implements Action {
  readonly type = SET_IONIC_UPDATE_ERROR_FLAG;
  constructor(public payload: any) {}
}

export class SetLoginLoading implements Action {
  readonly type = SET_LOGIN_LOADING;
  constructor(public payload: any) {}
}

export class SetFreshLogin implements Action {
  readonly type = SET_FRESH_LOGIN;
  constructor(public payload: any) {}
}

export class SetReconnectSocketTimestamp implements Action {
  readonly type = SET_RECONNECT_SOCKET_TIMESTAMP;
  constructor(public payload: any) {}
}

export class SetTriggerRenewTokenTimestamp implements Action {
  readonly type = SET_TRIGGER_RENEW_TOKEN_TIMESTAMP;
  constructor(public payload: any) {}
}

export class ClearUser implements Action {
  readonly type = CLEAR_USER;
  constructor() {}
}
export class ClearMoToken implements Action {
  readonly type = CLEAR_MO_TOKEN;
  constructor() {}
}
export class ClearNewCustomer implements Action {
  readonly type = CLEAR_NEW_CUSTOMER;
  constructor() {}
}

export class SetLoginState implements Action {
  readonly type = SET_LOGIN_STATE;
  constructor(public payload: any) {}
}

export type AuthActions =
  | SetUser
  | SetMoToken
  | SetMigrationCustomerId
  | SetNewCustomer
  | SetCustomerOrders
  | SetFreshLogin
  | SetInternetConnectedFlag
  | SetIonicUpdateErrorFlag
  | SetLogoutFlagTimestamp
  | SetLoginLoading
  | SetLoginState
  | SetReconnectSocketTimestamp
  | SetKioskCustomerOrders
  | SetTriggerRenewTokenTimestamp
  | ClearMoToken
  | ClearUser
  | ClearNewCustomer;
