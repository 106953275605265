<div
  *ngIf="hyperCategories && hyperCategories.length && storeInfo"
  style="display: block"
>
  <ion-slides
    #hyperCategoriesSlider
    [class]="
      icons_hyper_categories_design
        ? 'slidesHeightWithIcons ion-text-center'
        : 'slidesHeightNoIcons ion-text-center'
    "
    *ngIf="hyperCategories"
    scrollbar
    [options]="sliderOptions"
  >
    <ion-slide
      [id]="hyperCategory.hyper_category_id"
      style="width: auto"
      *ngFor="let hyperCategory of hyperCategories; trackBy: trackFn"
    >
      <ion-item
        *ngIf="
          icons_hyper_categories_design &&
          !icons_hyper_categories_design_only_vertical
        "
        (click)="HyperCategorySelection(hyperCategory)"
        button="true"
        lines="none"
        detail="false"
      >
        <ion-label
          style="max-width: 140px; height: 100%; align-items: top"
          [ngClass]="{ buttonBold: true }"
          class="ion-text-center ion-no-margin"
        >
          <ion-icon
            [color]="
              hyperCategory.hyper_category_id === selectedHyperCategory
                ? 'selected'
                : 'primary'
            "
            style="font-size: 35px; margin: 0"
            [src]="
              hyperCategoryIconsLink +
              hyperCategory.mobileorder_hyper_category_icon
            "
          ></ion-icon>
          <div>
            <ion-text
              style="font-size: small"
              [color]="
                hyperCategory.hyper_category_id === selectedHyperCategory
                  ? 'selected'
                  : 'primary'
              "
            >
              {{ hyperCategory.name }}</ion-text
            >
          </div>
        </ion-label>
      </ion-item>

      <ion-chip
        *ngIf="
          !icons_hyper_categories_design ||
          icons_hyper_categories_design_only_vertical
        "
        (click)="HyperCategorySelection(hyperCategory)"
        [color]="
          hyperCategory.hyper_category_id === selectedHyperCategory
            ? 'selected'
            : 'primary'
        "
        ><ion-label [ngClass]="{ buttonBold: true }">{{
          hyperCategory.name !== "others"
            ? hyperCategory.name
            : ("others" | translate)
        }}</ion-label></ion-chip
      >
    </ion-slide>
  </ion-slides>
</div>
