import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AlertController, ModalController, Platform, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DataStorageService } from 'src/app/services/data-storage/data-storage.service';
import * as fromApp from "../../store/app.reducer";
import { Store } from '@ngrx/store';
import * as _ from "lodash";
import { first } from "rxjs/operators";
import * as AuthActions from "../../auth/store/auth.actions";

@Component({
  selector: 'app-fill-required-name-modal',
  templateUrl: './fill-required-name-modal.component.html',
  styleUrls: ['./fill-required-name-modal.component.scss'],
})
export class FillRequiredNameModalComponent implements OnInit, OnDestroy {
  public user;
  public firstName;
  public lastName;
  public loading = false;
  private userSubscription: Subscription;
  private mo_token;
  private unsubscribeBackEvent: Subscription;

  constructor(private modalController: ModalController,
              private dataStorageService: DataStorageService,
              private translate: TranslateService,
              private store: Store<fromApp.AppState>,
              private toastController: ToastController,
              private platform: Platform) { }

  closeModal(){
    this.modalController.dismiss();
  }

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      300,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );

    this.userSubscription = this.store
    .select("auth")
    .pipe(first())
    .subscribe((state) => {
      if (state && state.user && !_.isEqual(state.user, this.user)) {
        this.user = _.cloneDeep(state.user);
        
        this.firstName = this.user.firstName || "";
        this.lastName = this.user.lastName || "";
      }
      if (state.mo_token && !_.isEqual(state.mo_token, this.mo_token)) {
        this.mo_token = _.cloneDeep(state.mo_token);
      }
    });
  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
           // console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }

  submit() {
    if (!this.firstName || !this.lastName){
      return;
    }

    const user = _.cloneDeep(this.user);
    user.firstName = this.firstName;
    user.lastName = this.lastName;
    const obj = { updatedData: _.cloneDeep(user) };
    this.loading = true;
    this.dataStorageService
    .updateCustomerData(obj, this.mo_token)

    .subscribe({
      next: (res: any) => {
        this.loading = false;
        if (res && res.success) {

          let updatedUser = res.data;
          this.store.dispatch(
            new AuthActions.SetUser(_.cloneDeep(updatedUser))
          );
          this.modalController.dismiss(null, "confirm");
        } else {
          if (res.comment_id) {
            this.presentErrorToast(
              this.translate.instant(res.comment_id),
              "danger"
            );
          } else if (res.comments) {
            this.presentErrorToast(res.comments, "danger");
          }
        }
      },
      error: (err) => {
        this.loading = false;
        let errMsg = this.translate.instant(
          "errorMessages.error: There was an error on saving"
        );

        this.presentErrorToast(errMsg, "danger");
        return err;
      },
    });
  }

  ngOnDestroy() {
    if (this.userSubscription){
      this.userSubscription.unsubscribe();
    }
    if (this.unsubscribeBackEvent){
      this.unsubscribeBackEvent.unsubscribe();
    }
  }

}
