<ion-header *ngIf="currentDeliveryArea">
  <ion-toolbar
    mode="md"
    class="ion-color"
    [style.background-color]="
      currentDeliveryArea && currentDeliveryArea.fillColor
        ? currentDeliveryArea.fillColor
        : ''
    "
  >
    <ion-buttons slot="end">
      <ion-button color="dark" fill="clear" (click)="closeModal()">
        <ion-icon src="assets/ionicons/close.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title size="small">
      <ion-text color="white"> {{ currentDeliveryArea.store }}</ion-text>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list *ngIf="currentDeliveryArea">
    <ion-item lines="none">
      <ion-label class="ion-text-wrap">
        <div
          style="font-weight: bold"
          *ngIf="
            currentDeliveryArea &&
            currentDeliveryArea.fixed_address_id &&
            currentDeliveryArea.fixedAddressName
          "
        >
          <ion-text color="dark">
            {{ "delivery-areas.fixed-point-address" | translate }}
          </ion-text>
        </div>

        <div
          style="font-size: small"
          *ngIf="
            currentDeliveryArea &&
            currentDeliveryArea.fixed_address_id &&
            currentDeliveryArea.fixedAddressName
          "
        >
          <ion-text color="dark">
            {{ currentDeliveryArea.fixedAddressName }}
          </ion-text>
        </div>
        <p>
          <ion-text style="font-weight: bold">
            {{ "delivery-areas.minimum-order" | translate }}:</ion-text
          >&nbsp;
          <ion-text style="font-weight: bold"
            >{{ currentDeliveryArea.minOrder }}€</ion-text
          >
        </p>

        <p
          *ngIf="currentDeliveryArea"
          style="font-size: small; font-weight: bold"
        >
          <span
            *ngIf="
              currentDeliveryArea.deliveryCost &&
              currentDeliveryArea.deliveryCost !== '0' &&
              !currentDeliveryArea.delivery_cost_per_kilometer_active
            "
          >
            <ion-text color="dark"
              >{{ "delivery-areas.cost-for-delivery" | translate }}:&nbsp;{{
                currentDeliveryArea.deliveryCost
              }}€</ion-text
            >&nbsp;
          </span>

          <span
            *ngIf="
              currentDeliveryArea.deliveryCost &&
              currentDeliveryArea.deliveryCost !== '0' &&
              currentDeliveryArea.delivery_cost_per_kilometer_active
            "
          >
            <ion-text color="dark"
              ><strong
                >{{ "delivery-areas.cost-for-delivery" | translate }}:</strong
              >&nbsp;{{ currentDeliveryArea.deliveryCost }}€
              {{ "delivery-areas.per" | translate }}
              {{
                currentDeliveryArea.step_active &&
                currentDeliveryArea.step_distance
                  ? currentDeliveryArea.step_distance +
                    " " +
                    ("meters" | translate)
                  : "km"
              }}</ion-text
            >

            <ion-text
              color="dark"
              *ngIf="currentDeliveryArea.charge_after_distance"
            >
              {{ "delivery-areas.with-charge-after" | translate }}
              {{ currentDeliveryArea.charge_after_distance }}
              {{ "meters" | translate }}
            </ion-text>
            <ion-text color="dark" *ngIf="currentDeliveryArea.fixed_charge">
              {{ "delivery-areas.and-fixed-charge" | translate }}
              {{ currentDeliveryArea.fixed_charge }}€
            </ion-text>

            <ion-text
              *ngIf="
                currentDeliveryArea &&
                currentDeliveryArea.distance_charge_type &&
                currentDeliveryArea.distance_charge_type === 'levels'
              "
            >
              <strong>
                ({{ "delivery-areas.charge-levels-active" | translate }})
              </strong>
            </ion-text>
          </span>
        </p>

        <p
          *ngIf="
            currentDeliveryArea.scheduleComment &&
            currentDeliveryArea.showSchedule
          "
        >
          <ion-text color="danger" style="font-weight: bold">{{
            currentDeliveryArea.scheduleComment
          }}</ion-text>
        </p>

        <div *ngIf="currentDeliveryArea.comment">
          <p>{{ currentDeliveryArea.comment }}</p>
        </div>
        <div *ngIf="currentDeliveryArea.showSchedule">
          <div
            *ngFor="let scheduleDay of currentDeliveryArea.scheduleArray"
            class="item item-text-wrap"
          >
            {{ scheduleDay.day }}
            <span style="float: right; white-space: nowrap">{{
              scheduleDay.schedule
            }}</span>
          </div>
        </div>
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>
