import { Action } from "@ngrx/store";

export const SET_SELECTED_PAYMENT_METHOD_ID =
  "[SELECTED PAYMENT METHOD] Set Selected Payment Method ID";
export const CLEAR_SELECTED_PAYMENT_METHOD_ID =
  "[SELECTED PAYMENT METHOD] Clear Selected Payment Method ID";

export class SetSelectedPaymentMethodID implements Action {
  readonly type = SET_SELECTED_PAYMENT_METHOD_ID;
  constructor(public payload: any) {}
}

export class ClearSelectedPaymentMethodID implements Action {
  readonly type = CLEAR_SELECTED_PAYMENT_METHOD_ID;
  constructor() {}
}

export type SelectedPaymentMethodIDActions =
  | SetSelectedPaymentMethodID
  | ClearSelectedPaymentMethodID;
