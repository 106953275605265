<ion-button
  [mode]="
    windowDimensions && windowDimensions.width && windowDimensions.width <= 350
      ? 'ios'
      : ''
  "
  [class]="
    showButton && !firstTime
      ? 'animate__animated animate__pulse'
      : showButton && firstTime
      ? 'animate__animated animate__backInUp'
      : !showButton
      ? 'animate__animated animate__fadeOutDownBig'
      : ''
  "
  size="small"
  style="position: absolute; bottom: 20px; left: 8px; z-index: 100"
  (click)="navigateCart()"
>
  <ion-text>
    {{ "cart.cart-components.cart-list.checkout" | translate }}
    {{
      cartPrices && cartPrices.grand_total ? cartPrices.grand_total + "€" : ""
    }}
  </ion-text>
  <!-- <ion-spinner *ngIf="disableFinalPrice"></ion-spinner> -->
</ion-button>
