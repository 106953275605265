import { Injectable } from "@angular/core";
import { DataStorageService } from "../data-storage/data-storage.service";
import * as AuthActions from "../../auth/store/auth.actions";
import * as fromApp from "../../store/app.reducer";
import * as DeliveryMethodAddressActions from "../../store/deliveryMethodAddress/deliveryMethodAddress.actions";
import * as _ from "lodash";
import { Store } from "@ngrx/store";

@Injectable({
  providedIn: "root",
})
export class fixedAddressesService {
  constructor(
    private dataStorageService: DataStorageService,
    private store: Store<fromApp.AppState>
  ) {}

  updateCustomerData(userObject, mo_token) {
    return new Promise((resolve) => {
      this.dataStorageService
        .updateCustomerData(userObject, mo_token)
        .subscribe({
          next: (res: any) => {
            if (res && res.success) {
              let updatedUser = res.data;
              console.log("writing user to state");
              this.store.dispatch(
                new AuthActions.SetUser(_.cloneDeep(updatedUser))
              );
              resolve("success");
            } else {
              resolve("error");
            }
          },
          error: (error) => {
            resolve("error");

            return error;
          },
        });
    });
  }

  selectAddress(address) {
    this.store.dispatch(
      new DeliveryMethodAddressActions.setOpenSelectStorePopupDelivery(true)
    );

    this.store.dispatch(
      new DeliveryMethodAddressActions.setOpenNotValidAddressModal(true)
    );

    this.store.dispatch(
      new DeliveryMethodAddressActions.setCheckSelectedAddress(
        _.cloneDeep(address)
      )
    );
  }
}
