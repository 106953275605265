<ion-item-divider
  mode="ios"
  *ngIf="noOnlinePayNoPickupPromos && noOnlinePayNoPickupPromos.length > 0"
>
  <ion-label>
    <strong>{{
      "cart.cart-components.checkbox-promotions.promotions" | translate
    }}</strong>
  </ion-label>
</ion-item-divider>

<div *ngIf="checkboxPromotions && checkboxPromotions.length > 0">
  <ion-item
    *ngFor="
      let promo of checkboxPromotions | noOnlinePaymentNoPickupOnly;
      trackBy: trackbyFnc
    "
  >
    <ion-label class="ion-text-wrap"
      >{{ promo.descriptionCheckbox }}
      <p class="cartWarningColor" *ngIf="promo.couponSelected">
        {{
          "cart.cart-components.checkbox-promotions.promotions-cannot-be-combined-with-the-selected-coupons"
            | translate
        }}
      </p></ion-label
    >
    <ion-checkbox
      [disabled]="promo.couponSelected || finalPriceLoading"
      slot="start"
      [(ngModel)]="promo.promoCheckbox"
      (ionChange)="clickCheckboxPromotion($event)"
    ></ion-checkbox>
  </ion-item>
</div>
