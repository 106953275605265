<ion-radio-group
  *ngIf="option && option.choices"
  allow-empty-selection
  (ionChange)="radioSelection($event)"
  [(ngModel)]="selectedChoiceTimestamp"
>
  <ion-item
    lines="none"
    *ngFor="
      let choice of option.choices;
      let index = index;
      trackBy: trackTimestamp
    "
  >
    <ion-label
      *ngIf="
        choice.title &&
        (!choice.translations ||
          (choice.translations && !choice.translations.title) ||
          (choice.translations &&
            choice.translations.title &&
            !choice.translations.title[selectedLang]))
      "
      class="ion-text-wrap"
    >
      {{ choice.title
      }}{{
        choice.price ? " - " + choice.price.toFixed(2) + "€" : ""
      }}</ion-label
    >

    <ion-label
      *ngIf="
        choice.translations &&
        choice.translations.title &&
        choice.translations.title[selectedLang]
      "
      class="ion-text-wrap"
    >
      {{ choice.translations.title[selectedLang]
      }}{{
        choice.price ? " - " + choice.price.toFixed(2) + "€" : ""
      }}</ion-label
    >
    <ion-radio
      slot="start"
      [disabled]="finalPriceLoading"
      [value]="choice.timestamp"
    ></ion-radio>
    <span slot="end">
      <app-change-quantity
        [buttonsSize]="'small'"
        [disablePlusButton]="!choice.selected || finalPriceLoading"
        [disableRemoveButton]="!choice.selected || finalPriceLoading"
        [quantity]="choice.qty"
        (changingQuantity)="customChoiceQtyChange($event, choice, index)"
      >
      </app-change-quantity>
    </span>
    <ion-text
      style="position: absolute; top: 0; right: 3px; font-size: x-small"
      *ngIf="
        choice.qty > 1 && choice.totalPrice && choice.totalPrice !== '0.00'
      "
    >
      {{ choice.totalPrice + "€" }}
    </ion-text>
  </ion-item>
</ion-radio-group>
