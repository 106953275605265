<span
  *ngIf="
    storeInfo &&
    (!category.subCategoriesActive ||
      (category.subCategoriesActive && !category.subCategories) ||
      (category.subCategories && category.subCategories.length === 0)) &&
    !category.sliderDesign &&
    !category.cardsDesign &&
    !category.marketCardsDesign
  "
>
  <!-- <app-category-item-mobile
    [hidden]="true"
    *ngIf="
      category.activeTotal &&
      !category.sliderDesign &&
      !category.cardsDesign &&
      !category.marketCardsDesign
    "
    id="{{ 'category_' + category.category_id }}"
    [category_id]="category.category_id"
    [groupStoreData]="groupStoreData"
  >
  </app-category-item-mobile> -->
  <ion-grid
    *ngFor="
      let product of (storeInfo &&
      storeInfo.design_settings &&
      storeInfo.design_settings.showOnlySelectedCategoryProducts
        ? filteredScrollProducts
        : category.products
      )
        | filterAvailableProducts : storeInfo.showAllUnavailableProducts
        | filterOnlyDineInDeliveryPickupProducts : orderPickup : dineIn;
      trackBy: productsTrackFn
    "
    style="max-width: 500px"
    class="ion-text-center"
  >
    <app-reservation-product-item
      *ngIf="product.activeTotal && category.activeTotal"
      [category_id]="category.category_id"
      [product_id]="product.product_id"
      [imageBrowser]="imageBrowser"
      [cartProductIds]="cartProductIds"
      [dineIn]="dineIn"
      [orderPickup]="orderPickup"
      [groupStoreData]="groupStoreData"
      [selectedDineInTable]="selectedDineInTable"
      [selectedPreorderDate]="selectedPreorderDate"
      [finalPriceLoading]="finalPriceLoading"
      [webp]="webp"
    >
    </app-reservation-product-item>
    <!-- <app-product-items-mobile
    *ngIf="product.activeTotal && category.activeTotal"
    inViewport
    [inViewportOptions]="{ partial: true, threshold: [0, 1] }"
    (inViewportAction)="onIntersection($event, product)"
    [category_id]="category.category_id"
    [product_id]="product.product_id"
    [productImage]="product.image"
    [categoryAvailableTotal]="category.availableTotal"
    [imageBrowser]="imageBrowser"
    [cartProductIds]="cartProductIds"
  >
  </app-product-items-mobile> -->
  </ion-grid>
  <ion-row
    style="width: 100%"
    *ngIf="
      storeInfo &&
      storeInfo.design_settings &&
      storeInfo.design_settings.showOnlySelectedCategoryProducts &&
      category &&
      category.products &&
      filteredScrollProducts &&
      filteredScrollProducts.length !== category.products.length
    "
  >
    <ion-col class="ion-text-center">
      <ion-spinner name="bubbles"></ion-spinner>
    </ion-col>
  </ion-row>
</span>
