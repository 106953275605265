<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button fill="clear"
                  (click)="closeModal()">
        <ion-icon src="assets/ionicons/close.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <ion-text>Κάρτα Μέλους</ion-text>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid style="max-width: 400px;">
    <ion-card>
      <ion-row class="ion-justify-content-center">
        <ion-col class="ion-text-center"
                 [ngStyle]="{'border-right': activeContest ? '1px solid': ''}"
                 style="padding-bottom: 20px;"
                 [size]="activeContest ? '6' : '12'">
          <h3>{{'loyaltySystem.points' | translate}}</h3>

          <ion-icon style="margin-top: 0; margin-bottom: 0; margin-right: 10px; vertical-align: bottom;"
                    size="large"
                    src="/assets/flatIcons/coins.svg">
          </ion-icon>
          <ion-text style="font-size: x-large; font-weight: bold">
            {{userPoints.pointsSystem.active || 0}}
          </ion-text>
        </ion-col>
        <ion-col *ngIf="activeContest"
                 class="ion-text-center">
          <h3>{{'loyaltySystem.entries' | translate}}</h3>

          <ion-icon style="margin-top: 0; margin-bottom: 0; margin-right: 10px; vertical-align: bottom;"
                    size="large"
                    src="/assets/flatIcons/lottery.svg">
          </ion-icon>
          <ion-text style="font-size: x-large; font-weight: bold">
            {{drawsSum || 0}}
          </ion-text>
        </ion-col>
      </ion-row>

    </ion-card>
    <ion-row>
      <ion-col class="ion-no-padding">
        <ion-card [hidden]="!imageLoaded"
                  class="ion-text-center ion-padding">
          <ion-row class="ion-justify-content-center">
            <ion-col>
              <img style="max-width: 180px;"
                   src="https://d2r9dct3dw909h.cloudfront.net/{{group}}/{{currentUser.customer_id}}.png"
                   (load)="onImageLoaded()" />
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="ion-text-center ion-padding">
              <ion-badge>
                Αριθμός Μέλους
              </ion-badge>
              <div style="font-size: 38px; font-weight: bold;">
                {{currentUser.member_number}}
              </div>
            </ion-col>
          </ion-row>
        </ion-card>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="!noOldMemberNumberConfirmed && (currentUser && !currentUser.migration_points_taken)">
      <ion-col class="ion-text-center">
        <ion-button style="margin-bottom: 10px;"
                    (click)="openMigratePointsModal()"
                    size="small">
          {{'loyaltySystem.already-have-member-number' | translate}}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-item-divider mode="ios"> {{'loyaltySystem.instore-active-coupons' | translate}}</ion-item-divider>
  <ion-row *ngIf="!instoreCoupons || instoreCoupons.length === 0">
    <ion-col class="ion-text-center">
      {{'loyaltySystem.no-instore-active-coupons' | translate}}
    </ion-col>
  </ion-row>
  <ion-row *ngIf="instoreCoupons.length === 0 || !instoreCoupons"
           class="ion-justify-content-center ion-align-items-center">
    <ion-col class="ion-text-center">
      <div style="max-width: 150px; display: inline-block">
        <ng-lottie (animationCreated)="noDiscountCouponAnimationCreated($event)"
                   [options]="noDiscountCouponOptions"></ng-lottie>
      </div>
    </ion-col>
  </ion-row>
  <ion-row *ngIf="instoreCoupons && instoreCoupons.length > 0"
           class="ion-align-items-center ion-justify-content-center">
    <ion-col>
      <app-loyalty-coupon-item *ngFor="let coupon of instoreCoupons"
                               [coupon]='coupon'>
      </app-loyalty-coupon-item>
    </ion-col>
  </ion-row>
</ion-content>