<ion-row
  *ngIf="!currentUser || (userPoints && userPoints.records && userPoints.records.length === 0)"
>
  <ion-col class="ion-text-center ion-padding">
    <ion-grid style="width: 120px; height: 120px; padding: 0">
      <ng-lottie
        (animationCreated)="animationCreated($event)"
        [options]="historyAnimationOptions"
      ></ng-lottie>
    </ion-grid>
    <ion-text>
      <h5>{{ "points.history.no-points-history" | translate }}</h5>
    </ion-text>
    <ion-grid class="ion-no-padding ion-no-margin" style="max-width: 400px" class="ion-text-center">
      <ion-item
        *ngIf="orderPickup != null && dineIn != null"
        [disabled]="checkAddressLoading"
        lines="none"
        detail="false"
        button
        class="menuButtons"
        color="primary"
        (click)="checkTheCatalog()"
      >
        <ion-label
          style="font-weight: 700; padding: 5px"
          class="ion-no-padding ion-no-margin ion-text-center"
        >
          <ion-spinner *ngIf="checkAddressLoading"> </ion-spinner>

          <div *ngIf="!checkAddressLoading">
            <div
              *ngIf="
                orderPickup == true ||
                (orderPickup == false && dineIn == true) ||
                (orderPickup == false && dineIn == false)
              "
            >
              {{
                cartProducts && cartProducts.length
                  ? ("homepage.continue-order" | translate)
                  : ("homepage.continue-to-catalog" | translate)
              }}
            </div>

            <p
              *ngIf="orderPickup == true && selectedLangugage"
              style="font-size: small; padding: 0; margin: 0"
            >
              {{ "homepage.take-away" | translate }}:
              {{
                currentStore.store_name_mobileorder &&
                currentStore.store_name_mobileorder[selectedLangugage]
                  ? currentStore.store_name_mobileorder[selectedLangugage]
                  : currentStore.store_name_langs &&
                    currentStore.store_name_langs[selectedLangugage]
                  ? currentStore.store_name_langs[selectedLangugage]
                  : currentStore.store_name_langs && currentStore.store_name_langs.el
                  ? currentStore.store_name_langs.el
                  : currentStore.store_name
                  ? currentStore.store_name
                  : ""
              }}
            </p>
            <p
              *ngIf="orderPickup == false && dineIn == true"
              style="font-size: small; padding: 0; margin: 0"
            >
              {{ "homepage.dine-in" | translate }}:
              {{ selectedDineInTable ? selectedDineInTable.name : "" }}
            </p>

            <p
              *ngIf="orderPickup == false && dineIn == false"
              style="font-size: small; padding: 0; margin: 0"
            >
              {{ "homepage.delivery" | translate }}:
              {{ selectedAddress ? selectedAddress.formatted_address : "" }}
            </p>
          </div>
        </ion-label>
      </ion-item>
      <ion-item
        *ngIf="orderPickup == null && dineIn == null"
        [disabled]="checkAddressLoading"
        lines="none"
        detail="false"
        button
        class="menuButtons"
        color="primary"
        expand="block"
        (click)="presentOrderNowModal()"
      >
        <ion-label
          style="font-weight: 700; padding: 10px"
          class="ion-text-wrap ion-no-padding ion-no-margin ion-text-center"
        >
          <div>{{ "homepage.new-order" | translate }}</div>
        </ion-label>
      </ion-item>
    </ion-grid>
  </ion-col>
</ion-row>

<ion-row *ngIf="currentUser && !userPoints">
  <ion-col class="ion-text-center ion-padding">
    <ion-spinner></ion-spinner>
  </ion-col>
</ion-row>

<ion-list *ngIf="currentUser && userPoints && groupApp">
  <span *ngIf="groupApp.pointsSystem && userPoints.records">
    <ion-item *ngFor="let record of userPoints.records">
      <ion-label>
        {{ record.points }} {{ "points.history.points" | translate }} -
        {{ record.timestamp | date : "d/M/yyyy HH:mm" }}
        <p *ngIf="record.type === 'order'">
          {{ ("points.history.order" | translate) + " #" + record.order_id }}
        </p>
        <p *ngIf="record.used_points">
          {{
            ("points.history.used" | translate) +
              " " +
              record.used_points +
              " " +
              ("points.history.points" | translate)
          }}
        </p>
        <p *ngIf="record.status === 'canceled' && record.points > 0">
          <ion-text>
            {{
              record.points +
                " " +
                ("points.history.points" | translate) +
                " " +
                ("points.history.canceled" | translate)
            }}
            <!-- {{ record.expiration ? record.expiration : "" }} -->
          </ion-text>
        </p>
        <p *ngIf="record.status === 'expired' && record.points > 0">
          <ion-text color="danger">
            {{
              record.points +
                " " +
                ("points.history.points" | translate) +
                " " +
                ("points.history.expired" | translate)
            }}
            {{ record.expiration ? record.expiration : "" }}
          </ion-text>
        </p>
        <p *ngIf="record.status === 'pending' && record.points > 0">
          <ion-text>
            {{
              record.points +
                " " +
                ("points.history.points" | translate) +
                " " +
                ("points.history.pending" | translate)
            }}
          </ion-text>
        </p>
        <p *ngIf="record.type === 'order' && record.points > 0">
          <ion-badge>Online Παραγγελία</ion-badge>
        </p>
        <p *ngIf="record.type === 'instore_order' && record.points > 0">
          <ion-badge>Παραγγελία</ion-badge>
        </p>
        <p *ngIf="record.type === 'login_register' && record.points > 0">
          <ion-badge>Εγγραφή</ion-badge>
        </p>
      </ion-label>
      <ion-badge color="danger" *ngIf="record.expiration && record.showExpDate">
        {{ "points.history.expiration" | translate }}:
        {{ record.expiration | date : "dd/MM/yyyy" }}
      </ion-badge>
    </ion-item>
  </span>
</ion-list>
