import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../store/app.reducer";
import * as CategoriesSelectionActions from "../store/categoriesSelection.actions";
import * as selectors from "../../../store/app.selectors";
import * as _ from "lodash";
import { audit, auditTime, distinctUntilChanged } from "rxjs/operators";
import { Subscription } from "rxjs";
@Component({
  selector: "app-vertical-categories",
  templateUrl: "./vertical-categories.component.html",
  styleUrls: ["./vertical-categories.component.scss"],
})
export class VerticalCategoriesComponent implements OnInit, OnDestroy {
  public categories: any;
  public hyperCategories: any;
  public storeInfo: any;
  public scrollCategoryId: any;
  public verticalCategoryId: any;
  public icons_categories_design: boolean;
  public categoryIconLink = "https://data.deliverymanager.gr/categoriesIcons/";

  private subscriptions: Subscription[] = [];
  private categoriesSubscription: Subscription;

  constructor(private store: Store<fromApp.AppState>) {}

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .subscribe((storeInfo) => {
          if (storeInfo && !_.isEqual(this.storeInfo, storeInfo)) {
            this.storeInfo = _.cloneDeep(storeInfo);
            this.icons_categories_design = !!_.cloneDeep(
              this.storeInfo.mobileorder_icons_categories_design
            );
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("hyperCategories")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.hyperCategories &&
            !_.isEqual(this.categories, state.hyperCategories)
          ) {
            this.hyperCategories = _.cloneDeep(state.hyperCategories);
            this.subscribeToCategories();
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("verticalCategory")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(state.selectedScrollCategoryId, this.scrollCategoryId)
          ) {
            this.scrollCategoryId = _.cloneDeep(state.selectedScrollCategoryId);
            if (this.scrollCategoryId) {
              let lastElement: any = _.last(_.cloneDeep(this.categories));
              let scrollElem = document.getElementById(
                "verticalCategoriesMenu_" + this.scrollCategoryId
              );
              if (
                scrollElem &&
                lastElement &&
                lastElement.category_id === this.scrollCategoryId
              ) {
                scrollElem.scrollIntoView({ block: "nearest" });
              } else if (
                scrollElem &&
                lastElement &&
                lastElement.category_id !== this.scrollCategoryId
              ) {
                scrollElem.scrollIntoView({ block: "end" });
              }
            }
          }
          if (
            state &&
            !_.isEqual(this.verticalCategoryId, state.selectedCategoryId)
          ) {
            this.verticalCategoryId = _.cloneDeep(state.selectedCategoryId);
          }
        })
    );
  }

  subscribeToCategories() {
    if (!this.categoriesSubscription) {
      this.categoriesSubscription = this.store
        .select("catalog")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.catalog &&
            !_.isEqual(this.categories, state.catalog)
          ) {
            this.categories = _.cloneDeep(state.catalog);
          }
        });
    }
  }

  nameToLowerCase(name) {
    return name.toLowerCase();
  }

  trackFn(inx) {
    return inx;
  }

  categorySelection(cat) {
    if (cat) {
      this.store.dispatch(
        new CategoriesSelectionActions.SetSelectedCategory(
          _.cloneDeep(cat.category_id)
        )
      );
      if (navigator.cookieEnabled) {
        localStorage.setItem("selectedCategory", cat.category_id);
      }
      this.store.dispatch(
        new CategoriesSelectionActions.SetScrollSelectedCategory(
          _.cloneDeep(null)
        )
      );
    } else {
      if (navigator.cookieEnabled) {
        if (localStorage.getItem("selectedCategory")) {
          localStorage.removeItem("selectedCategory");
        }
      }
    }
  }

  ngOnDestroy() {
    if (this.categories && this.categories.length) {
      this.store.dispatch(
        new CategoriesSelectionActions.SetSelectedCategory(
          _.cloneDeep(this.categories[0].category_id)
        )
      );
      this.store.dispatch(
        new CategoriesSelectionActions.SetScrollSelectedCategory(
          _.cloneDeep(null)
        )
      );
    }

    if (this.categoriesSubscription) {
      this.categoriesSubscription.unsubscribe();
    }
    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }
}
