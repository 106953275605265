import { Action } from "@ngrx/store";

export const SET_DEVELOPER_MODE = "[DEVELOPER MODE] Set Developer Mode";

export class SetDeveloperMode implements Action {
  readonly type = SET_DEVELOPER_MODE;
  constructor(public payload: boolean) {}
}

export type DeveloperModeActions = SetDeveloperMode;
