import { Injectable } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { RecommendedProductsPopupComponent } from "../../popups/recommended-products-popup/recommended-products-popup.component";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";
import * as selectors from "../../store/app.selectors";
import { first } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class RecommendedProductsServiceService {
  private recommendedProductsModal: any;

  constructor(
    private modalController: ModalController,
    private store: Store<fromApp.AppState>,
    private router: Router
  ) {}

  private async openRecommendedProductsModal() {
    this.recommendedProductsModal = await this.modalController.create({
      component: RecommendedProductsPopupComponent,
      cssClass: "recommended-products-modal",
      backdropDismiss: false,
      componentProps: {},
    });
    await this.recommendedProductsModal.present();
    this.recommendedProductsModal.onDidDismiss().then((data) => {
      if (this.recommendedProductsModal) {
        this.recommendedProductsModal.dismiss();
        this.recommendedProductsModal = null;
      }
    });
  }

  public closeRecommendedProductsModal() {
    if (this.recommendedProductsModal) {
      this.recommendedProductsModal.dismiss();
      this.recommendedProductsModal = null;
    }
  }

  public async showRecommendedProductsModal() {
    this.store
      .select(selectors.getStoreInfo)
      .pipe(first())
      .subscribe(async (storeInfo) => {
        if (storeInfo && !storeInfo.mobileorderShowRecommendedProductsOnPages) {
          if (
            this.router &&
            this.router.url &&
            this.router.url.toString().includes("cart")
          ) {
            if (!this.recommendedProductsModal) {
              this.recommendedProductsModal = true;
              await this.openRecommendedProductsModal();
            }
          }
        } else if (
          storeInfo &&
          storeInfo.mobileorderShowRecommendedProductsOnPages
        ) {
          if (
            this.router &&
            this.router.url &&
            this.router.url.toString().includes("cart") &&
            storeInfo.mobileorderShowRecommendedProductsOnPages &&
            storeInfo.mobileorderShowRecommendedProductsOnPages.indexOf(
              "cart"
            ) !== -1
          ) {
            if (!this.recommendedProductsModal) {
              this.recommendedProductsModal = true;
              await this.openRecommendedProductsModal();
            }
          } else if (
            this.router &&
            this.router.url &&
            this.router.url.toString().includes("catalog") &&
            storeInfo.mobileorderShowRecommendedProductsOnPages &&
            storeInfo.mobileorderShowRecommendedProductsOnPages.indexOf(
              "catalog"
            ) !== -1
          ) {
            if (!this.recommendedProductsModal) {
              this.recommendedProductsModal = true;
              await this.openRecommendedProductsModal();
            }
          }
        }
      })
      .unsubscribe();
  }
}
