<ion-row *ngIf="!categories">
  <ion-col class="ion-text-center">
    <ion-spinner></ion-spinner>
  </ion-col>
</ion-row>

<ion-row>
  <ion-col
    [ngStyle]="{
      'padding-left':
        storeInfo &&
        storeInfo.design_settings &&
        storeInfo.design_settings.mobileorderHideCartCatalog
          ? '25px'
          : ''
    }"
    size="3"
    [class]="loading ? 'ion-text-center' : ''"
  >
    <app-vertical-hyper-categories
      *ngIf="
        categories && hyperCategories && hyperCategories.length > 0 && !loading
      "
    ></app-vertical-hyper-categories>
    <app-vertical-categories
      *ngIf="
        categories &&
        hyperCategories &&
        hyperCategories.length === 0 &&
        !loading
      "
    ></app-vertical-categories>

    <ion-spinner *ngIf="loading"></ion-spinner>
  </ion-col>
  <ion-col
    [size]="
      storeInfo &&
      storeInfo.design_settings &&
      storeInfo.design_settings.mobileorderHideCartCatalog
        ? '9'
        : '6'
    "
  >
    <ion-grid *ngIf="doNotShowProductsList">
      <ion-row style="width: 100%">
        <ion-col class="ion-text-center"><ion-spinner></ion-spinner> </ion-col>
      </ion-row>
    </ion-grid>
    <app-browser-product-list
      *ngIf="!doNotShowProductsList"
      (listScrollFromBottomEvent)="scrollFromBottomEvent($event)"
      (listScrollFromTopEvent)="scrollFromTopEvent($event)"
    ></app-browser-product-list>
  </ion-col>
  <!-- <ion-col
    style="text-align: center; border-left: 1px solid black"
    class="ion-padding"
    size="3"
    class="ion-text-wrap"
    >
  </ion-col> -->
</ion-row>
<app-cart-catalog
  *ngIf="
    storeInfo &&
    (!storeInfo.design_settings ||
      !storeInfo.design_settings.mobileorderHideCartCatalog)
  "
  [grid]="'wrapper-grid-lg'"
  [transparentCatalogElements]="transparentCatalogElements"
  [catalogInlineInfoMessage]="catalogInlineInfoMessage"
  [motoCardsProgressValues]="motoCardsProgressValues"
  [infoMessagesCardsHide]="infoMessagesCardsHide"
></app-cart-catalog>
