import { Component, OnInit, OnDestroy } from "@angular/core";
import { AlertController, ModalController, Platform } from "@ionic/angular";
import { UserInfoComponent } from "../user-info/user-info.component";
import { GroupInfoComponent } from "../group-info/group-info.component";
import { TranslateService } from "@ngx-translate/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";
import * as AuthActions from "../../auth/store/auth.actions";
import { AnalyticsServiceService } from "src/app/services/analitycsService/analytics-service.service";
import { Router } from "@angular/router";
import { Subscription, auditTime, distinctUntilChanged } from "rxjs";
import _ from "lodash";
import { PaymentTermsPage } from "src/app/payment-terms/payment-terms.page";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import { Browser } from "@capacitor/browser";
import { LoginPopupsServiceService } from "src/app/services/login-popups-service/login-popups-service.service";
import * as GroupAppActions from "../../store/group-app/groupApp.actions";

import { VERSION } from "../../../environments/version";
import { ReservationCouponsSelectionModalComponent } from "../reservation-coupons-selection-modal/reservation-coupons-selection-modal.component";
import { ReservationSystemModalsService } from "../reservation-system-modals.service";

@Component({
  selector: "app-reservation-more-info",
  templateUrl: "./reservation-more-info.page.html",
  styleUrls: ["./reservation-more-info.page.scss"],
})
export class ReservationMoreInfoPage implements OnDestroy {
  public version = VERSION.version;
  public currentUser: any;
  public mo_token: any;
  public groupApp: any;
  public headerLogo: any;
  public storeImage: any;
  public darkHeaderLogo: any;
  public windowDimensions: any;
  public selectedLogoFileName: any;
  public rootDir: any;
  public loginState;

  private userSubscription: Subscription;
  private windowInfoSubscription: Subscription;
  private groupSubscription: Subscription;
  private unsubscribeBackEvent: Subscription;

  constructor(
    public modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private translateService: TranslateService,
    private analyticsService: AnalyticsServiceService,
    private store: Store<fromApp.AppState>,
    private router: Router,
    private dataStorageService: DataStorageService,
    private reservationSystemModalsService: ReservationSystemModalsService,
    private loginPopupsService: LoginPopupsServiceService,

    private platform: Platform
  ) {}

  private navigateToHome() {
    this.router.navigateByUrl("/reservationSystem/reservation-home", {
      replaceUrl: true,
    });
  }

  ionViewDidEnter() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      100,
      () => {
        if (this.platform.is("android")) {
          this.navigateToHome();
        }
      }
    );

    this.userSubscription = this.store
      .select("auth")

      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.loginState &&
          !_.isEqual(state.loginState, this.loginState)
        ) {
          this.loginState = _.cloneDeep(state.loginState);
        }

        if (state && state.user && !_.isEqual(state.user, this.currentUser)) {
          this.currentUser = _.cloneDeep(state.user);
          if (!this.currentUser.loyalty_app_settings) {
            this.currentUser.loyalty_app_settings = {
              showLastName: false,
              showImage: false,
            };
          }
        }
        if (state.mo_token && !_.isEqual(state.mo_token, this.mo_token)) {
          this.mo_token = _.cloneDeep(state.mo_token);
        }
      });

    this.groupSubscription = this.store
      .select("groupApp")
      .pipe(auditTime(50))
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.groupApp &&
          !_.isEqual(this.groupApp, state.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp);
          if (!_.isEmpty(this.groupApp.logosImages)) {
            this.selectedLogoFileName = _.find(this.groupApp.logosImages, {
              selected: true,
            }).fileName;
          }
          this.dataStorageService.fetchGroupApp(this.groupApp.group).subscribe({
            next: (res: any) => {
              if (res) {
                this.storeImage = res.iconImage;
                this.headerLogo = res.headerLogo;
                console.log("groupAppHeaderLogo", res.headerLogo);
                this.darkHeaderLogo = res.headerLogoDark;
              } else {
              }
            },
            error: (error) => {
              return error;
            },
          });
        }
        if (state && state.rootDir && !_.isEqual(this.rootDir, state.rootDir)) {
          this.rootDir = _.cloneDeep(state.rootDir);
        }
      });

    this.windowInfoSubscription = this.store
      .select("windowInfo")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(300))
      .subscribe((state) => {
        if (!_.isEqual(state.windowDimensions, this.windowDimensions)) {
          this.windowDimensions = _.cloneDeep(state.windowDimensions);
        }
      });
  }

  async openUserInfo() {
    const modal = await this.modalCtrl.create({
      component: UserInfoComponent,
      animated: true,
      backdropDismiss: false,
      componentProps: {
        rootDir: this.rootDir,
        selectedLogoFileName: this.selectedLogoFileName,
      },
      cssClass: "fullScreenModal",
    });
    await modal.present();
  }

  async presentModal() {
    this.loginPopupsService.showFirebaseModalLogin(null, null);
  }

  async navigateCouponsPage() {
    this.router.navigateByUrl(
      "/reservationSystem/reservation-discount-coupons",
      {
        replaceUrl: true,
      }
    );
  }

  async openTerms() {
    const modal = await this.modalCtrl.create({
      component: PaymentTermsPage,
      animated: true,
      backdropDismiss: false,
      componentProps: {
        modal: true,
      },
      cssClass: "fullScreenModal",
    });
    await modal.present();
  }

  changeChannel() {
    this.store.dispatch(
      new GroupAppActions.SetChangeChannelTimestamp(Date.now())
    );
  }

  async openGroupInfo() {
    const modal = await this.modalCtrl.create({
      component: GroupInfoComponent,
      animated: true,
      backdropDismiss: false,
      componentProps: {
        rootDir: this.rootDir,
        selectedLogoFileName: this.selectedLogoFileName,
      },
      cssClass: "fullScreenModal",
    });
    await modal.present();
  }

  async openBrowserURL(url) {
    const fullUrl = `https://${url}`;
    window.open(fullUrl, "_system");
  }

  async logout() {
    const alert = await this.alertCtrl.create({
      header: this.translateService.instant("popups.logout-alert-popup.exit"),
      message: this.translateService.instant(
        "popups.logout-alert-popup.do-you-want-to-logout"
      ),
      buttons: [
        {
          text: this.translateService.instant(
            "popups.logout-alert-popup.cancel"
          ),
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: this.translateService.instant("popups.logout-alert-popup.yes"),
          handler: () => {
            this.analyticsService.dmAnalyticsMessage(
              "logged_out",
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null
            );
            this.store.dispatch(
              new AuthActions.SetLogoutFlagTimestamp(Date.now().toString())
            );

            this.router.navigateByUrl("/homepage", {
              replaceUrl: true,
            });
          },
        },
      ],
    });

    await alert.present();
  }

  navigateHome() {
    this.router.navigateByUrl("/reservationSystem/reservation-home", {
      replaceUrl: true,
    });
  }

  ionViewDidLeave() {
    this.currentUser = null;
    this.mo_token = null;

    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.groupSubscription) {
      this.groupSubscription.unsubscribe();
    }
    if (this.windowInfoSubscription) {
      this.windowInfoSubscription.unsubscribe();
    }
  }

  ngOnDestroy() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }

    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.groupSubscription) {
      this.groupSubscription.unsubscribe();
    }
    if (this.windowInfoSubscription) {
      this.windowInfoSubscription.unsubscribe();
    }
  }
}
