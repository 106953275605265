import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  auditTime,
  catchError,
  distinctUntilChanged,
  first,
} from "rxjs/operators";
import * as fromApp from "../store/app.reducer";
import * as _ from "lodash";
import {
  AlertController,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import * as AuthActions from "../auth/store/auth.actions";
import { Router } from "@angular/router";
import { DataStorageService } from "../services/data-storage/data-storage.service";
import { AnalyticsServiceService } from "../services/analitycsService/analytics-service.service";
import { OneSignalService } from "../services/oneSignal/one-signal.service";
import * as GroupAppActions from "../store/group-app/groupApp.actions";
import { AnimationOptions } from "ngx-lottie";
import { AnimationItem } from "lottie-web";
import { Camera, CameraResultType } from "@capacitor/camera";
import { EditProfileImageModalComponent } from "./edit-profile-image-modal/edit-profile-image-modal.component";
import { Browser } from "@capacitor/browser";

@Component({
  selector: "app-account-settings",
  templateUrl: "./account-settings.page.html",
  styleUrls: ["./account-settings.page.scss"],
})
export class AccountSettingsPage implements OnDestroy {
  options: AnimationOptions = {
    path: "https://assets3.lottiefiles.com/packages/lf20_gzoqde3x.json",
  };
  animation: AnimationItem;
  public currentUser: any;
  public edit: boolean;
  private stores: any;
  public showInvoiceData: boolean;
  public accountData = { tab: "alertOrder" };
  public dirtyButton: boolean;
  public darkTheme;
  public customerDataSaveLoading: boolean = false;
  public customerDataSaveSuccess: boolean = false;
  public customerDataDirty: boolean = false;
  public valid: any = true;
  public deleteAccountLoading: boolean;
  subPlatformInfo: Subscription;
  isBrowserPlatform: boolean = false;
  public groupApp: any;
  onlyLoyaltyApp: boolean = false;
  public windowDimensions;

  private windowDimSub: Subscription;
  private selectedTheme: any;
  private userSubscription: Subscription;
  private storesSubscription: Subscription;
  private unsubscribeBackEvent: Subscription;
  private subscription: Subscription;
  private mo_token: any;
  @ViewChild("firstNameInput", { static: false }) firstNameInputElem;
  @ViewChild("lastNameInput", { static: false }) lastNameInputElem;
  @ViewChild("emailInput", { static: false }) emailInputElem;

  constructor(
    private store: Store<fromApp.AppState>,
    private toastController: ToastController,
    private translateService: TranslateService,
    private alertCtrl: AlertController,
    private router: Router,
    private dataStorageService: DataStorageService,
    private analyticsService: AnalyticsServiceService,
    private platform: Platform,
    private oneSignalService: OneSignalService,
    private alertController: AlertController,
    private modalController: ModalController
  ) {}

  ionViewDidEnter() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      100,
      () => {
        if (this.onlyLoyaltyApp) {
          this.pageChange("loyaltySystem/loyalty-more");
        } else {
          this.navigateToHome();
        }
      }
    );

    this.windowDimSub = this.store
      .select("windowInfo")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.windowDimensions &&
          !_.isEqual(state.windowDimensions, this.windowDimensions)
        ) {
          this.windowDimensions = _.cloneDeep(state.windowDimensions);
        }
      });

    this.subPlatformInfo = this.store
      .select("platformInfo")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (state && state.browserPlatform) {
          this.isBrowserPlatform = true;
        }
      });

    this.analyticsService.fbqTrackPageView();
    this.analyticsService.setCurrentScreenFirebase("accountSettings");

    this.subscription = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.groupApp &&
          !_.isEqual(this.groupApp, state.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp);
          console.log("groupApp", this.groupApp);

          if (this.groupApp.only_loyalty_app) {
            this.onlyLoyaltyApp = true;
          }
        }
        if (
          state &&
          state.selectedTheme &&
          !_.isEqual(this.groupApp, state.selectedTheme)
        ) {
          this.selectedTheme = _.cloneDeep(state.selectedTheme);
          if (
            this.selectedTheme &&
            this.selectedTheme === "light" &&
            this.darkMode
          ) {
            this.darkTheme = false;
          } else if (
            this.selectedTheme &&
            this.selectedTheme === "dark" &&
            !this.darkTheme
          ) {
            this.darkTheme = true;
          }
        }
      });
    if (navigator.cookieEnabled) {
      let darkThemeLocalStorage = localStorage.getItem("darkTheme");
      if (darkThemeLocalStorage && darkThemeLocalStorage === "yes") {
        this.darkTheme = true;
        this.store.dispatch(new GroupAppActions.SetSelectedTheme("dark"));
      } else {
        this.darkTheme = false;
        this.store.dispatch(new GroupAppActions.SetSelectedTheme("light"));
      }
    }

    this.userSubscription = this.store
      .select("auth")
      .pipe(auditTime(50))
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (state && state.user && !_.isEqual(state.user, this.currentUser)) {
          this.currentUser = _.cloneDeep(state.user);
          if (!this.currentUser.loyalty_app_settings) {
            this.currentUser.loyalty_app_settings = {
              showLastName: false,
              showImage: false,
            };
            if (!this.currentUser.alertExpirationPoints) {
              this.currentUser.alertExpirationPoints = {
                push: false,
                sms: false,
                viber: false,
                email: true,
              };
            }
          }
        }
        if (state.mo_token && !_.isEqual(state.mo_token, this.mo_token)) {
          this.mo_token = _.cloneDeep(state.mo_token);
        }
      });

    this.storesSubscription = this.store
      .select("stores")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (state && state.stores && !_.isEqual(state.stores, this.stores)) {
          this.stores = _.cloneDeep(state.stores);
          this.showInvoiceData = !!_.find(this.stores, {
            customerInvoice: true,
          });
        }
      });
  }

  private navigateToHome() {
    if (this.platform.is("android")) {
      this.router.navigateByUrl("/homepage", {
        replaceUrl: true,
      });
    }
  }

  pageChange(route) {
    this.router.navigateByUrl("/" + route, {
      replaceUrl: true,
    });
  }

  onInputFocus() {
    this.customerDataDirty = true;
  }

  updateCustomerDataDebounced = _.debounce((attr) => {
    this.updateCustomerDataCustomerInfo(attr);
  }, 1500);

  updateCustomerDataCustomerInfo(attr) {
    if (this.customerDataDirty) {
      this.customerDataSaveSuccess = false;

      if (
        (attr === "firstName" && this.currentUser.firstName) ||
        (attr === "lastName" && this.currentUser.lastName)
      ) {
        let obj = { updatedData: _.cloneDeep(this.currentUser) };
        this.customerDataSaveLoading = true;

        //console.log("customerObjecy", obj);
        //console.log("ngmodelchange", this.currentUser);
        this.dataStorageService

          .updateCustomerData(obj, this.mo_token)

          .subscribe({
            next: (res: any) => {
              if (res && res.success) {
                //  console.log("ngmodelchange", this.currentUser);
                this.customerDataSaveLoading = false;
                this.customerDataSaveSuccess = true;
                this.edit = false;
                this.dirtyButton = false;
                let updatedUser = res.data;
                this.store.dispatch(
                  new AuthActions.SetUser(_.cloneDeep(updatedUser))
                );
              } else {
                this.customerDataSaveLoading = false;
                if (res.comment_id) {
                  this.presentErrorToast(
                    this.translateService.instant(res.comment_id),
                    "danger"
                  );
                } else if (res.comments) {
                  this.presentErrorToast(res.comments, "danger");
                }
              }
            },
            error: (err) => {
              this.customerDataSaveLoading = false;
              let errMsg = this.translateService.instant(
                "errorMessages.error: There was an error on saving"
              );

              this.presentErrorToast(errMsg, "danger");
              return err;
              console.log("error updating the information");
            },
          });
      } else {
        this.customerDataSaveLoading = false;

        this.presentErrorToast(
          this.translateService.instant(
            "cart.cart-components.customer-info.the-filed-is-required"
          ),
          "danger"
        );
      }
    }
  }

  hanleSegmentChange(ev) {
    console.log("event", ev.target.value);

    if (ev && ev.target && ev.target.value) {
      this.accountData.tab = ev.target.value;
    }
  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            // console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }

  async requestDeleteAccount() {
    const alert = await this.alertController.create({
      header: this.translateService.instant("alert"),
      message: this.translateService.instant(
        "account-settings.are-you-sure-that-you-want-to-delete-account"
      ),
      buttons: [
        {
          text: this.translateService.instant("cancel"),
          role: "cancel",
          handler: () => {},
        },
        {
          text: this.translateService.instant("yes"),
          role: "confirm",
          handler: async () => {
            //call delete api
            this.deleteAccountLoading = true;
            this.dataStorageService
              .triggerDeleteAccount(this.mo_token)
              .subscribe({
                next: async (res: any) => {
                  this.deleteAccountLoading = false;
                  if (!res || !res.success) {
                    const alert = await this.alertController.create({
                      header: this.translateService.instant("alert"),
                      message:
                        res && res.comment_id
                          ? this.translateService.instant(res.comment_id)
                          : this.translateService.instant(
                              "errorMessages.general_error"
                            ),
                      backdropDismiss: false,
                      buttons: ["OK"],
                    });
                    await alert.present();
                  } else {
                    const alert = await this.alertController.create({
                      header: this.translateService.instant("alert"),
                      message: this.translateService.instant(
                        "account-settings.soon-you-will-receive-an-sms-for-deliting-account"
                      ),
                      buttons: ["OK"],
                    });

                    await alert.present();
                  }
                },
                error: async (err) => {
                  this.deleteAccountLoading = false;
                  const alert = await this.alertController.create({
                    header: this.translateService.instant("alert"),
                    message: this.translateService.instant(
                      "errorMessages.problem_reaching_server"
                    ),

                    buttons: ["OK"],
                  });
                  await alert.present();
                  return err;
                },
              });
          },
        },
      ],
    });

    await alert.present();
  }

  onMobileClick() {
    let notify = this.translateService.instant(
      "account-settings.this-mobile-number-cannot-be-changed"
    );
    this.presentErrorToast(notify, "primary");
  }

  onOtherChange(ev) {
    if (this.currentUser) {
      let obj = { updatedData: this.currentUser };
      this.updateCustomerDataDebounce(obj, this.mo_token, false);
    }
  }

  onPushChange(ev) {
    if (this.currentUser) {
      let obj = { updatedData: this.currentUser };
      this.updateCustomerDataDebounce(obj, this.mo_token, false);
      if (
        this.currentUser &&
        this.accountData &&
        this.accountData.tab &&
        this.currentUser[this.accountData.tab] &&
        this.currentUser[this.accountData.tab].push
      ) {
        // console.log("ask permission for one signal");
        this.oneSignalService.OneSignalPrompt();
      }
    }
  }

  changed() {
    this.dirtyButton = true;
  }

  saveOrEdit() {
    const emailRegex =
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

    if (!this.currentUser.email) {
      let errorMessage = this.translateService.instant(
        "account-settings.please-enter-your-email"
      );
      this.presentErrorToast(errorMessage, "danger");
      this.emailInputElem.setFocus();
    } else if (
      this.currentUser.email &&
      !this.currentUser.email.match(emailRegex)
    ) {
      let errorMessage = this.translateService.instant(
        "account-settings.please-enter-a-valid-email"
      );
      this.presentErrorToast(errorMessage, "danger");
      this.emailInputElem.setFocus();
    } else {
      //  console.log("user", this.currentUser, this.mo_token);
      let obj = { updatedData: this.currentUser };
      this.updateCustomerData(obj, this.mo_token, true);
      console.log("updateCustomerData success");
    }
  }

  updateCustomerDataDebounce = _.debounce(
    (obj, mo_token, show_success_message) => {
      this.updateCustomerData(obj, mo_token, show_success_message);
    },
    800
  );

  updateCustomerData(obj, mo_token, show_success_message) {
    this.customerDataSaveLoading = true;
    if (show_success_message) {
    }

    this.dataStorageService.updateCustomerData(obj, mo_token).subscribe({
      next: (res: any) => {
        if (res && res.success) {
          this.dirtyButton = false;
          let updatedUser = res.data;
          this.store.dispatch(
            new AuthActions.SetUser(_.cloneDeep(updatedUser))
          );
          if (show_success_message && res.comment_id) {
            this.presentErrorToast(
              this.translateService.instant(
                "account-settings." + res.comment_id
              ),
              "primary"
            );
          }
          this.edit = false;
          this.customerDataSaveLoading = false;
        } else {
          if (res.comment_id) {
            this.presentErrorToast(
              this.translateService.instant(res.comment_id),
              "danger"
            );
          } else if (res.comments) {
            this.presentErrorToast(res.comments, "danger");
          }
          this.customerDataSaveLoading = false;
        }
      },
      error: (error) => {
        let errMsg = this.translateService.instant(
          "errorMessages.error: There was an error on saving"
        );
        this.customerDataSaveLoading = false;
        this.presentErrorToast(errMsg, "danger");
        return error;
        console.log("error updating the information");
      },
    });
  }

  async logout() {
    const alert = await this.alertCtrl.create({
      header: this.translateService.instant("popups.logout-alert-popup.exit"),
      message: this.translateService.instant(
        "popups.logout-alert-popup.do-you-want-to-logout"
      ),
      buttons: [
        {
          text: this.translateService.instant(
            "popups.logout-alert-popup.cancel"
          ),
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: this.translateService.instant("popups.logout-alert-popup.yes"),
          handler: () => {
            this.analyticsService.dmAnalyticsMessage(
              "logged_out",
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null
            );
            this.store.dispatch(
              new AuthActions.SetLogoutFlagTimestamp(Date.now().toString())
            );
            this.router.navigateByUrl("/homepage", {
              replaceUrl: true,
            });
          },
        },
      ],
    });

    await alert.present();
  }

  darkMode() {
    // console.log("dark mode fun ", this.darkTheme, this.groupApp);
    if (this.groupApp) {
      //this.darkTheme = !this.darkTheme;
      this.analyticsService.dmAnalyticsMessage(
        "dark_mode",
        _.cloneDeep(this.darkTheme),
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      );
      if (this.darkTheme) {
        if (this.groupApp) {
          this.store.dispatch(new GroupAppActions.SetSelectedTheme("dark"));

          if (this.groupApp.group === "battlenet") {
            document.body.classList.toggle("battlenet-dark", true);
          } else {
            document.body.classList.toggle("dark", true);
          }

          if (navigator.cookieEnabled) {
            localStorage.setItem("darkTheme", "yes");
          }
        }
      } else {
        if (this.groupApp) {
          this.store.dispatch(new GroupAppActions.SetSelectedTheme("light"));
          if (this.groupApp.group === "battlenet") {
            document.body.classList.toggle("battlenet-dark", false);
          } else {
            document.body.classList.toggle("dark", false);
          }

          if (navigator.cookieEnabled) {
            localStorage.setItem("darkTheme", "no");
          }
        }
      }
    }
  }

  async openEditImageModal() {
    const modal = await this.modalController.create({
      component: EditProfileImageModalComponent,
      backdropDismiss: false,
      cssClass: "fullScreenModal",
    });
    await modal.present();
  }

  deleteProfilePic() {
    this.currentUser.profile_picture = null;
  }

  animationCreated(ev: AnimationItem) {
    this.animation = ev;
    this.animation.setSpeed(0.5);
    this.animation.loop = false;
  }

  selectMedia() {}

  selectImageFromGallery() {
    if (this.platform.is("android") || this.platform.is("ios")) {
      Camera.getPhoto({
        resultType: CameraResultType.Uri,
        correctOrientation: true,
        allowEditing: true,
        quality: 70,
      }).then(
        (imageData) => {
          console.log(imageData);
          if (imageData.path) {
            // this.copyFileToLocalDir(imageData.path, "profile_picture");
          }
        },
        (err) => console.error(err)
      );
    }
  }

  // copyFileToLocalDir(fullPath, type) {
  //   console.log("copyFileToLocalDir fullPath", fullPath);
  //   let myPath = fullPath;
  //   // Make sure we copy from the right location
  //   if (fullPath.indexOf("file://") < 0) {
  //     myPath = "file://" + fullPath;
  //   }

  //   const ext = myPath.split(".").pop();
  //   const d = uuid();
  //   const newName = `${d}.${ext}`;

  //   const name = myPath.substr(myPath.lastIndexOf("/") + 1);
  //   const copyFrom = myPath.substr(0, myPath.lastIndexOf("/") + 1);
  //   const copyTo =
  //     this.file.dataDirectory + this.currentUser.event_id + "/" + type;

  //   console.log("name", name);
  //   console.log("copyFrom", copyFrom);
  //   console.log("copyTo", copyTo);
  //   console.log("newName", newName);

  //   this.file.copyFile(copyFrom, name, copyTo, newName).then(
  //     async (success) => {
  //       console.log("File was successfully copied inside the local directory!");
  //       await this.dataService.addFilesToUpload(
  //         [
  //           {
  //             fileName: newName,
  //             localDirectory: copyTo,
  //           },
  //         ],
  //         type
  //       );
  //     },
  //     (error) => {
  //       console.log("error: ", error);
  //     }
  //   );
  // }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.storesSubscription) {
      this.storesSubscription.unsubscribe();
    }
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }

    if (this.windowDimSub) {
      this.windowDimSub.unsubscribe();
    }
  }

  ionViewDidLeave() {
    if (this.windowDimSub) {
      this.windowDimSub.unsubscribe();
    }

    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.storesSubscription) {
      this.storesSubscription.unsubscribe();
    }
    if (this.subPlatformInfo) {
      this.subPlatformInfo.unsubscribe();
    }
  }
}
