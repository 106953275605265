import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import { ModalController, Platform, ToastController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import OneSignal from "onesignal-cordova-plugin";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
declare let window: any;
import * as _ from "lodash";
import * as AuthActions from "../../auth/store/auth.actions";
import * as fromApp from "../../store/app.reducer";
import { auditTime, distinctUntilChanged } from "rxjs";

@Component({
  selector: "app-account-info-for-contest-modal",
  templateUrl: "./account-info-for-contest-modal.component.html",
  styleUrls: ["./account-info-for-contest-modal.component.scss"],
})
export class AccountInfoForContestModalComponent implements OnInit, OnDestroy {
  @Input() currentUser;
  currentDrawAcceptedTerms: any;
  customerDataSaveLoading: boolean;
  mo_token: any;
  authSub: any;
  unsubscribeBackEvent: any;
  constructor(
    private modalController: ModalController,
    private store: Store<fromApp.AppState>,
    private translateService: TranslateService,
    private dataStorageService: DataStorageService,
    private toastController: ToastController,
    private platform: Platform
  ) {}

  ngOnInit() {
    if (navigator.cookieEnabled) {
      this.currentDrawAcceptedTerms = localStorage.getItem(
        "currentDrawAcceptedTerms"
      )
        ? true
        : false;
    }
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      300,
      () => {
        if (this.platform.is("android")) {
          this.cancel();
        }
      }
    );

    this.authSub = this.store
      .select("auth")
      .pipe(auditTime(200))
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.mo_token &&
          !_.isEqual(state.mo_token, this.mo_token)
        ) {
          this.mo_token = _.cloneDeep(state.mo_token);
        }
      });
  }

  cancel() {
    this.OneSignalPrompt();
    this.modalController.dismiss();
  }

  confirm(accountInfoForm: NgForm) {
    accountInfoForm.controls["acceptTerms"].markAsTouched();
    if (accountInfoForm.invalid || !this.currentDrawAcceptedTerms) {
      return;
    }
    if (navigator.cookieEnabled && this.currentDrawAcceptedTerms) {
      localStorage.setItem("currentDrawAcceptedTerms", Date.now().toString());
    }
    this.OneSignalPrompt();
    this.updateCurrentUser();
  }

  updateCurrentUser() {
    let obj = { updatedData: _.cloneDeep(this.currentUser) };
    this.customerDataSaveLoading = true;

    this.dataStorageService

      .updateCustomerData(obj, this.mo_token)

      .subscribe({
        next: (res: any) => {
          if (res && res.success) {
            //  console.log("ngmodelchange", this.currentUser);
            this.customerDataSaveLoading = false;
            this.modalController.dismiss();
            let updatedUser = res.data;
            this.store.dispatch(
              new AuthActions.SetUser(_.cloneDeep(updatedUser))
            );
          } else {
            this.customerDataSaveLoading = false;
            this.modalController.dismiss();

            if (res.comment_id) {
              this.presentErrorToast(
                this.translateService.instant(res.comment_id),
                "danger"
              );
            } else if (res.comments) {
              this.presentErrorToast(res.comments, "danger");
            }
          }
        },
        error: (err) => {
          this.customerDataSaveLoading = false;
          this.modalController.dismiss();

          let errMsg = this.translateService.instant(
            "errorMessages.error: There was an error on saving"
          );

          this.presentErrorToast(errMsg, "danger");
          return err;
        },
      });
  }

  openLink(link) {
    Browser.open({ url: link });
  }

  OneSignalPrompt() {
    if (Capacitor.isNativePlatform()) {
      try {
        OneSignal.promptForPushNotificationsWithUserResponse(
          true,
          (accepted) => {
            console.log("User accepted notifications fallbackkkk: " + accepted);
            if (accepted) {
              OneSignal.provideUserConsent(accepted);
              OneSignal.disablePush(false);
            }
          }
        );
      } catch (err) {
        console.log("one signal err", err);
      }
    } else if (window.OneSignal) {
      window.OneSignal.push([
        "getNotificationPermission",
        (permission) => {
          console.log("menu", "Site Notification Permission:", permission);
        },
      ]);

      window.OneSignal.push([
        "isPushNotificationsEnabled",
        (enabled) => {
          console.log("isPushNotificationsEnabled", enabled);
          if (!enabled) {
            window.OneSignal.push(() => {
              window.OneSignal.registerForPushNotifications();
              window.OneSignal.push(["setSubscription", true]);
            });
          }
        },
      ]);
    } else {
      console.warn("OneSignalPrompt warning");
    }
  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            // console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }

  ngOnDestroy(): void {
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
  }
}
