<ion-content scrollEvents="false" scrollX="false" scrollY="false">
  <ion-item lines="none" style="margin-top: 15px">
    <img
      alt="logo"
      [class]="
        windowDimensions &&
        windowDimensions.width &&
        windowDimensions.width > 450
          ? 'imgLogoBrowser'
          : 'imgLogoMobile'
      "
      alt="{{ groupApp?.group }}"
      src="{{ rootDir }}{{ groupApp?.group }}/logos/{{ selectedLogoFileName }}"
    />
  </ion-item>
  <ion-item lines="none">
    <div style="padding: 2rem; width: 100%">
      <ion-label
        style="
          position: absolute;
          z-index: 2;
          background-color: white;
          left: 55px;
          top: 18px;
          padding: 5px;
        "
        >Αριθμός τηλεφώνου</ion-label
      >
      <ion-item
        lines="none"
        style="
          height: auto;
          border-radius: 35px;
          border: 2px solid var(--ion-color-primary);
        "
      >
        <ion-input
          #mobileInput
          #inputMobileRef
          placeholder="6900354125"
          labelPlacement="floating"
          (ionChange)="onPhoneNumberChange()"
          (ionFocus)="setModalBreakPointFull($event)"
          [(ngModel)]="mobile"
          name="phoneNumber"
          type="text"
          pattern="[0-9]*"
          maxLength="10"
        >
        </ion-input>
      </ion-item>
    </div>
  </ion-item>
  <ion-grid [hidden]="!capacitorPlatform || capacitorPlatform !== 'browser'">
    <ion-row
      *ngIf="typeOfRecaptcha && typeOfRecaptcha === 'invisible'"
      style="width: 100%"
    >
      <ion-col class="ion-text-center">
        <ion-button
          [disabled]="!reacaptchaLoaded || !isPhoneNumberValid"
          (click)="onClickOk()"
          shape="round"
          ><ion-spinner *ngIf="!reacaptchaLoaded"></ion-spinner>
          <ion-text *ngIf="reacaptchaLoaded">Σύνδεση</ion-text></ion-button
        >
      </ion-col>
    </ion-row>
    <div
      [class]="
        typeOfRecaptcha && typeOfRecaptcha === 'normal'
          ? 'minHeightZero'
          : 'minHeight'
      "
    >
      <div id="recaptcha-container-invisible"></div>
    </div>
  </ion-grid>

  <ion-grid *ngIf="capacitorPlatform && capacitorPlatform === 'mobile'">
    <ion-row style="width: 100%">
      <ion-col class="ion-text-center">
        <ion-button
          [disabled]="!isPhoneNumberValid || mobileLoading"
          (click)="onClickOk()"
          shape="round"
          ><ion-spinner *ngIf="mobileLoading"></ion-spinner>
          <ion-text *ngIf="!mobileLoading">Σύνδεση</ion-text></ion-button
        >
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer> </ion-footer>
