import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription, auditTime, distinctUntilChanged } from "rxjs";
import * as _ from "lodash";
import * as fromApp from "../../../store/app.reducer";
import { Store } from "@ngrx/store";
import { ReservationSystemModalsService } from "../../reservation-system-modals.service";

@Component({
  selector: "app-reservation-catalog-coupons",
  templateUrl: "./reservation-catalog-coupons.component.html",
  styleUrls: ["./reservation-catalog-coupons.component.scss"],
})
export class ReservationCatalogCouponsComponent implements OnInit, OnDestroy {
  public activeCoupons: any;

  private subscription: Subscription;
  constructor(
    private store: Store<fromApp.AppState>,
    private reservationSystemModalsService: ReservationSystemModalsService
  ) {}

  ngOnInit() {
    this.subscription = this.store
      .select("cartActiveCoupons")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(50))
      .subscribe((state) => {
        if (state && !_.isEqual(this.activeCoupons, state.activeCoupons)) {
          const activeCoupons = _.cloneDeep(state.activeCoupons);
          this.activeCoupons = _.filter(
            _.cloneDeep(activeCoupons),
            (coupon) => {
              return (
                (!coupon.use_type ||
                  (coupon.use_type &&
                    coupon.use_type !== "third_party" &&
                    coupon.use_type !== "instore")) &&
                !coupon.couponCheckbox
              );
            }
          );
        }
      });
  }

  showSelectCouponModal() {
    this.reservationSystemModalsService.closeReservationCouponsSelectionModal();
    this.reservationSystemModalsService.presentReservationCouponsSelectionModal();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
