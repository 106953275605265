import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import { Subscription } from "rxjs";
import { auditTime, distinctUntilChanged } from "rxjs/operators";
import * as fromApp from "../../../../../store/app.reducer";
import * as selectors from "../../../../../store/app.selectors";

@Component({
  selector: "radio-closed-choices-selection-modal",
  templateUrl: "./radio-closed-choices-selection-modal.component.html",
  styleUrls: ["./radio-closed-choices-selection-modal.component.scss"],
})
export class RadioClosedChoicesModalComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() selectedProductId: any;
  @Input() bundleMode: any;
  @Input() option_id: any;
  @Input() selectedProductPromo: any;
  @Input() selectedProductCategory: any;
  @Input() groupStoreData;
  @Output() optionChange = new EventEmitter();
  public option;
  public windowDimensions: any;
  public store_info;
  public alt_dinein_active;
  private subscriptions: Subscription[] = [];
  private optionSubscription: Subscription;
  private unsubscribeBackEvent: Subscription;

  constructor(
    private store: Store<fromApp.AppState>,
    private modalCtrl: ModalController,
    private platform: Platform
  ) {}

  selectOption() {
    if (this.optionSubscription) {
      this.optionSubscription.unsubscribe();
    }
    this.optionSubscription = this.store
      .select(
        selectors.getOptionByOptionId(this.selectedProductId, this.option_id)
      )
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (!_.isEqual(this.option, state)) {
          this.option = _.cloneDeep(state);
          // console.log("option from modal closed choices", this.option);
        }
      });
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (
      simpleChanges &&
      simpleChanges.option_id &&
      simpleChanges.option_id.currentValue !==
        simpleChanges.option_id.previousValue
    ) {
      this.selectOption();
    }
  }

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      300,
      () => {
        if (this.platform.is("android")) {
          this.modalCtrl.dismiss();
        }
      }
    );
    this.selectOption();
    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(auditTime(50))
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.windowDimensions &&
            !_.isEqual(this.windowDimensions, state.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
            console.log(
              "window dem from radio closed choices selection",
              this.windowDimensions
            );
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((store_info) => {
          if (store_info && !_.isEqual(this.store_info, store_info)) {
            this.store_info = _.cloneDeep(store_info);
            if (this.store_info && this.store_info.dinein) {
              this.alt_dinein_active = this.store_info.alt_dinein_active;
            } else {
              this.alt_dinein_active = false;
            }
          }
        })
    );
  }

  optionChanged(option) {
    this.modalCtrl.dismiss(option);
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    if (this.optionSubscription) {
      this.optionSubscription.unsubscribe();
    }
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
  }
}
