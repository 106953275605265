import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";

import { auditTime, distinctUntilChanged } from "rxjs/operators";
import * as fromApp from "../../../store/app.reducer";
import * as _ from "lodash";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import * as OrdersActions from "../../../orders/store/orders.actions";

@Component({
  selector: "app-klarna-modal",
  templateUrl: "./klarna-modal.component.html",
  styleUrls: ["./klarna-modal.component.scss"],
})
export class KlarnaModalComponent implements OnInit, OnDestroy {
  @Input() klarna_iframe;
  private klarnaModalIsOpen: any;
  private subscription: Subscription;
  private unsubscribeBackEvent: Subscription;
  constructor(
    private modalCtrl: ModalController,
    private store: Store<fromApp.AppState>,
    private platform: Platform
  ) {}

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      300,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );
    const el: any = document.querySelector(".paymentModal");
    el.style.setProperty("--paymentModalWidth", "340px");
    el.style.setProperty("--paymentModalHeight", "360px");

    this.store.dispatch(new OrdersActions.SetKlarnaPopupOpen(true));
    this.subscription = this.store
      .select("orders")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (
          state &&
          !_.isEqual(this.klarnaModalIsOpen, state.klarnaPopupOpen)
        ) {
          this.klarnaModalIsOpen = _.cloneDeep(state.klarnaPopupOpen);
          if (!this.klarnaModalIsOpen) {
            this.closeModal();
          }
        }
      });
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  ngOnDestroy() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
