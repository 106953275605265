import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  AlertController,
  LoadingController,
  ModalController,
  Platform,
} from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { AnimationOptions } from "ngx-lottie";
import { auditTime, distinctUntilChanged } from "rxjs/operators";
import * as fromApp from "../../store/app.reducer";
import * as selectors from "../../store/app.selectors";
import * as _ from "lodash";
import { Subscription } from "rxjs";

import { CheckLocationDistanceService } from "src/app/services/checkLocationDistance/check-location-distance.service";

@Component({
  selector: "app-check-gps-coupon-rule-modal",
  templateUrl: "./check-gps-coupon-rule-modal.component.html",
  styleUrls: ["./check-gps-coupon-rule-modal.component.scss"],
})
export class CheckGpsCouponRuleModalComponent implements OnInit {
  @Input() message: any;
  @Input() distance: any;

  public ionLoading: any;
  public disableButton: boolean;

  public storeInfo: any;
  options: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/location-service-off.json",
  };

  public onDismissClearDeliveryMethod: boolean = false;
  private subscription: Subscription;
  private unsubscribeBackEvent: Subscription;
  constructor(
    private modalController: ModalController,
    private translateService: TranslateService,
    private loadingController: LoadingController,
    private alertController: AlertController,
    private checkLocationDistanceService: CheckLocationDistanceService,
    private platform: Platform,
    private store: Store<fromApp.AppState>
  ) {}

  closeModal() {
    this.modalController.dismiss();
  }

  async presentIonLoading() {
    let message = this.translateService.instant("loading-please-wait");
    this.ionLoading = await this.loadingController.create({
      duration: 30000,
      message: message,
      cssClass: "ionicLoadingCss",
      keyboardClose: true,
    });
    return await this.ionLoading.present();
  }

  dismissIonLoading() {
    console.log("Loading dismissed!", this.ionLoading);
    if (this.ionLoading) {
      this.ionLoading.dismiss();
      this.ionLoading = null;
    }
  }

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      300,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );

    this.subscription = this.store
      .select(selectors.getStoreInfo)
      .pipe(distinctUntilChanged())
      .subscribe((storeInfo) => {
        if (storeInfo && !_.isEqual(this.storeInfo, storeInfo)) {
          this.storeInfo = _.cloneDeep(storeInfo);
        }
      });
  }

  async checkLocationAgain() {
    this.disableButton = true;
    this.distance = null;
    await this.presentIonLoading();
    let distance: any;
    const distance_object: any =
      await this.checkLocationDistanceService.calculateDistanceFromGps(
        {
          lat: this.storeInfo.mapCenterStoreLat,
          lng: this.storeInfo.mapCenterStoreLng,
        },
        false
      );
    this.dismissIonLoading();
    this.disableButton = false;
    if (distance_object && distance_object.distance) {
      distance = distance_object.distance;
    } else if (!distance_object || distance_object.error) {
      console.log("error calculating distance", distance_object);
      this.onDismissClearDeliveryMethod = true;
    }

    if (distance) {
      if (distance <= 100) {
        this.modalController.dismiss("success");
      } else {
        this.message = this.translateService.instant(
          "popups.check-gps-coupon-rule-modal.you-are-out-of-store"
        );

        this.distance = distance_object.distance;
      }
    } else {
      if (!distance_object || !distance_object.error) {
        this.message = this.translateService.instant(
          "popups.check-gps-coupon-rule-modal.something-went-wrong-try-again"
        );
      } else if (distance_object && distance_object.error) {
        if (distance_object.error === "no-gps-enabled") {
          this.message = this.translateService.instant(
            "popups.check-gps-coupon-rule-modal.no-gps-enabled"
          );
        } else if (distance_object.error === "no-coordinates") {
          this.message = this.translateService.instant(
            "popups.check-gps-coupon-rule-modal.no-coordinates"
          );
        } else if (distance_object.error) {
          this.message = this.translateService.instant(
            this.translateService.instant(distance_object.error)
          );
        } else {
          console.log("GPS no error");
        }
      }
    }
  }

  animationCreated(ev) {}

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
  }
}
