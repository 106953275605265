import * as AppInitLoadingActions from "./appInitLoading.actions";
import * as _ from "lodash";

export interface State {
  appInitLoadingStep: string;
}

const initialState: State = {
  //steps: init,update-checked
  appInitLoadingStep: "init",
};

export function appInitLoadingReducer(
  state = initialState,
  action: AppInitLoadingActions.AppInitLoadingActions
) {
  switch (action.type) {
    case AppInitLoadingActions.SET_APP_INIT_LOADING_STEP:
      return {
        ...state,
        appInitLoadingStep: action.payload,
      };
    default:
      return state;
  }
}
