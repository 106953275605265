<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">
      <ion-text color="primary">
        {{ "kiosk-page.kiosk-qr-info-modal.title" | translate }}
      </ion-text>
    </ion-title>
    <ion-button
      (click)="closeModal()"
      size="small"
      slot="end"
      fill="clear"
      color="dark"
    >
      <ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid *ngIf="currentUser && groupApp">
    <ion-item lines="none">
      <ion-label class="ion-text-center ion-text-wrap">
        <ion-text *ngIf="groupApp.pointsSystem">
          {{
            "kiosk-page.scan-the-above-qr-code-to-connect-to-kiosk-app"
              | translate
          }}
        </ion-text>
        <ion-text *ngIf="!groupApp.pointsSystem">
          {{
            "kiosk-page.scan-the-above-qr-code-to-connect-to-kiosk-app-without-points"
              | translate
          }}
        </ion-text>
      </ion-label>
    </ion-item>

    <div
      [class]="
        secondsRemaining > 0
          ? 'qrcode ion-text-center'
          : 'qrcode ion-text-center low-opacity'
      "
    >
      <qrcode
        [qrdata]="currentUser.kiosk_one_time_password"
        [width]="170"
        [errorCorrectionLevel]="'H'"
        [margin]="1"
      ></qrcode>
    </div>

    <div *ngIf="secondsRemaining > 0" style="text-align: center">
      {{ "kiosk-page.or-enter-the-code" | translate }}:
      <span style="font-size: large">
        <strong>{{ currentUser.kiosk_one_time_password }}</strong>
      </span>
    </div>

    <div
      *ngIf="
        currentUser &&
        currentUser.kiosk_expiration_timestamp &&
        secondsRemaining > 0
      "
      class="timerDiv ion-text-center"
      id="timer"
    >
      <ion-grid
        style="
          border: 2px solid var(--ion-color-danger);
          border-radius: 2px;
          padding: 3px;
          max-width: 120px;
          min-height: 42px;
        "
      >
        <div style="font-size: small; font-weight: bold">
          <ion-text color="danger">
            {{ "kiosk-page.password-expires-in" | translate }}
          </ion-text>
        </div>
        <ion-text color="danger"> {{ formattedTime }} </ion-text>
      </ion-grid>
    </div>

    <ion-grid
      class="ion-align-items-center ion-justify-content-center"
      (click)="triggerRenewToken()"
      *ngIf="
        currentUser &&
        currentUser.kiosk_expiration_timestamp &&
        secondsRemaining <= 0
      "
      style="
        border: 2px solid var(--ion-color-danger);
        border-radius: 2px;
        padding: 3px;
        max-width: 120px;
        min-height: 42px;
        margin-top: 15px;
        cursor: pointer;
      "
    >
      <ion-row
        style="width: 100%; height: 100%"
        class="ion-justify-content-center ion-align-items-center"
      >
        <ion-col style="height: 100%" size="12" class="ion-align-self-center">
          <ion-text
            color="danger"
            style="font-size: small; font-weight: bold"
            >{{ "kiosk-page.renew-password" | translate }}</ion-text
          >
        </ion-col>
      </ion-row>
      <div></div>
    </ion-grid>
  </ion-grid>
</ion-content>
