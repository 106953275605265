<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">{{
      "popups.extra-address-details-modal.title" | translate
    }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list *ngIf="scan_user">
    <ion-item>
      <ion-label position="stacked">{{
        "popups.extra-address-details-modal.floor" | translate
      }}</ion-label>
      <ion-input [(ngModel)]="scan_user.floor"> </ion-input>
    </ion-item>
    <ion-item>
      <ion-label position="stacked">{{
        "popups.extra-address-details-modal.doorbell" | translate
      }}</ion-label>
      <ion-input [(ngModel)]="scan_user.bell"> </ion-input>
    </ion-item>
    <ion-item>
      <ion-label position="stacked">{{
        "popups.extra-address-details-modal.address-comments" | translate
      }}</ion-label>
      <ion-input [(ngModel)]="scan_user.comments"> </ion-input>
    </ion-item>
  </ion-list>
</ion-content>

<ion-footer>
  <ion-grid style="width: 100%" *ngIf="scan_user">
    <ion-row style="width: 100%">
      <!-- <ion-col size="6" class="ion-text-left">
        <ion-button color="medium" (click)="onClickCancel()"
          >{{ "popups.extra-address-details-modal.cancel" | translate }}
        </ion-button>
      </ion-col> -->
      <ion-col size="12" class="ion-text-center">
        <ion-button color="primary" (click)="onClickOk()">
          {{ "popups.extra-address-details-modal.ok" | translate }}</ion-button
        >
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
