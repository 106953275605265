import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { auditTime, distinctUntilChanged, first } from "rxjs/operators";
import * as fromApp from "../../../store/app.reducer";
import * as _ from "lodash";
import * as CartCustomerOptionsActions from "./store/cart-customer-options/cart-customer-options.actions";
import * as cartCustomOptionsSelectionsActions from "./store/cart-custom-options-selections/cart-custom-options-selections.actions";
import * as selectors from "./../../../store/app.selectors";
import * as CompleteOrderValidationActions from "../../../store/complete-order-validation/completeOrderValidation.actions";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-cart-custom-options",
  templateUrl: "./cart-custom-options.component.html",
  styleUrls: ["./cart-custom-options.component.scss"],
})
export class CartCustomOptionsComponent implements OnInit, OnDestroy {
  @Input() showQuantityOptions: string; // if this set to "quantity-options" component shows only cart-custom-options with quantity, else with "no-quantity-options" shows no-quantity component
  @Input() catalogMode: boolean;
  public store_info: any;
  public cart: any;
  public selectePaymentMethodID: any;
  public orderPickup: boolean;
  public dineIn: boolean;
  public currentUser: any;
  public valid: boolean;
  public notValidCustomOptions = [];

  private cartCustomerOptions: any;
  private lastValidTimestamp = null;
  private currentStoreId: string;
  private stores: any;
  public subscriptions: Subscription[] = [];
  private selectedCustomOptions: any;

  constructor(
    private store: Store<fromApp.AppState>,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select("cart")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.products &&
            !_.isEqual(this.cart, state.products)
          ) {
            this.cart = _.cloneDeep(state.products);
            this.calculateCustomOptionsDebounce(
              this.store_info,
              this.selectePaymentMethodID,
              this.orderPickup,
              this.dineIn,
              this.selectedCustomOptions
            );
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (state && state.user && !_.isEqual(this.currentUser, state.user)) {
            this.currentUser = _.cloneDeep(state.user);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())

        .subscribe((storeInfo) => {
          if (storeInfo && !_.isEqual(storeInfo, this.store_info)) {
            this.store_info = _.cloneDeep(storeInfo);

            this.calculateCustomOptionsDebounce(
              this.store_info,
              this.selectePaymentMethodID,
              this.orderPickup,
              this.dineIn,
              this.selectedCustomOptions
            );

            console.log("store Info from cart options", this.store_info);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("selectedPaymentMethodID")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.selectedPaymentMethodID &&
            !_.isEqual(
              this.selectePaymentMethodID,
              state.selectedPaymentMethodID
            )
          ) {
            this.selectePaymentMethodID = _.cloneDeep(
              state.selectedPaymentMethodID
            );
            this.calculateCustomOptionsDebounce(
              this.store_info,
              this.selectePaymentMethodID,
              this.orderPickup,
              this.dineIn,
              this.selectedCustomOptions
            );

            console.log("call load custom options");
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("deliveryMethodAddress")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (state && !_.isEqual(this.orderPickup, state.orderPickup)) {
            this.orderPickup = _.cloneDeep(state.orderPickup);
            this.calculateCustomOptionsDebounce(
              this.store_info,
              this.selectePaymentMethodID,
              this.orderPickup,
              this.dineIn,
              this.selectedCustomOptions
            );
          }

          if (state && !_.isEqual(this.dineIn, state.dineIn)) {
            this.dineIn = _.cloneDeep(state.dineIn);
            this.calculateCustomOptionsDebounce(
              this.store_info,
              this.selectePaymentMethodID,
              this.orderPickup,
              state.dineIn,
              this.selectedCustomOptions
            );
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("cartCustomerOptions")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.cartCustomerOptions &&
            !_.isEqual(this.cartCustomerOptions, state.cartCustomerOptions)
          ) {
            this.cartCustomerOptions = _.cloneDeep(state.cartCustomerOptions);
            this.calculateNotValidCustomOptions();
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("cartCustomOptionsSelections")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.cartCustomOptionsSelections &&
            !_.isEqual(
              this.selectedCustomOptions,
              state.cartCustomOptionsSelections
            )
          ) {
            this.selectedCustomOptions = _.cloneDeep(
              state.cartCustomOptionsSelections
            );
            console.log("call calculate custom options");

            this.calculateCustomOptionsDebounce(
              this.store_info,
              this.selectePaymentMethodID,
              this.orderPickup,
              this.dineIn,
              this.selectedCustomOptions
            );
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("completeOrderValidation")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.lastValidTimestamp &&
            !_.isEqual(this.lastValidTimestamp, state.lastValidTimestamp)
          ) {
            console.log("inside complete order validation sub");
            this.lastValidTimestamp = _.cloneDeep(state.lastValidTimestamp);
            this.completeOrderCartCustomOptionsValidations();
          }
        })
    );
  }

  private calculateCustomOptionsDebounce = _.debounce(
    (
      store_info,
      selectedPaymentMethodID,
      orderPickup,
      orderDinein,
      selectedCustomOptions
    ) => {
      this.calculateCustomOptions(
        store_info,
        selectedPaymentMethodID,
        orderPickup,
        orderDinein,
        selectedCustomOptions
      );
    },
    300
  );

  calculateCustomOptions(
    store_info,
    selectedPaymentMethodID,
    orderPickup,
    orderDinein,
    selectedCustomOptions
  ) {
    console.log("calculate cart custom options");
    let cartCustomOptions = [];
    this.store.dispatch(
      new CartCustomerOptionsActions.ClearCartCustomerOptions()
    );

    if (store_info && store_info.cartCustomOptionsActive) {
      if (selectedCustomOptions) {
        _.each(selectedCustomOptions, (option, key) => {
          if (
            option.active &&
            !option.only_kiosk &&
            (!option.onlinePaymentOnly ||
              (selectedPaymentMethodID !== "cash" &&
                selectedPaymentMethodID !== "mobilePOS")) &&
            ((!option.pickupOnly && !option.deliveryOnly) ||
              (option.deliveryOnly && !option.pickupOnly && !orderPickup) ||
              (option.pickupOnly && !option.deliveryOnly && orderPickup)) &&
            (!orderDinein || (orderDinein && option.showInDinein))
          ) {
            _.each(option.choices, (choice, index) => {
              if (choice.price) {
                if (
                  typeof choice.qty !== "number" ||
                  typeof choice.price !== "number"
                ) {
                  choice.qty = parseFloat(choice.qty);
                  choice.price = parseFloat(choice.price);
                }
                selectedCustomOptions[key].choices[index].totalPrice = (
                  choice.qty * choice.price
                ).toFixed(2);
                if (
                  typeof selectedCustomOptions[key].choices[index]
                    .totalPrice !== "number"
                ) {
                  selectedCustomOptions[key].choices[index].totalPrice =
                    parseFloat(
                      selectedCustomOptions[key].choices[index].totalPrice
                    );
                }
              } else {
                selectedCustomOptions[key].choices[index].totalPrice = 0;
              }
            });

            cartCustomOptions.push({
              title: option.title,
              preNameId: option.preNameId,
              onlinePaymentOnly: option.onlinePaymentOnly,
              timestamp: option.timestamp,
              type: option.type,
              isRequired: option.isRequired,
              do_not_print_title: option.do_not_print_title,
              minOrderCheckChoices: option.minOrderCheckChoices,
              position: option.position,
              choices: _.map(option.choices, (choice) => {
                if (choice.selected || choice.qty > 0) {
                  let params: any = {
                    title: choice.title,
                    timestamp: choice.timestamp,
                  };

                  if (choice.price) {
                    params.price = choice.price;
                  }
                  if (choice.qty) {
                    params.qty = choice.qty;
                  }
                  if (choice.totalPrice) {
                    params.totalPrice = choice.totalPrice;
                  }
                  if (choice.selected) {
                    params.selected = choice.selected;
                  }

                  if (choice.text) {
                    params.text = choice.text;
                  }

                  if (choice.minOrder) {
                    params.minOrder = choice.minOrder;
                  }

                  return params;
                } else {
                  return null;
                }
              }),
            });

            var customOptionIndex = _.findIndex(cartCustomOptions, {
              timestamp: option.timestamp,
            });
            cartCustomOptions[customOptionIndex].choices = _.compact(
              cartCustomOptions[customOptionIndex].choices
            );

            // console.log("before empty check", cartCustomOptions);
            if (_.isEmpty(cartCustomOptions[customOptionIndex].choices)) {
              cartCustomOptions.splice(customOptionIndex, 1);
            }
            //console.log("after empty check", cartCustomOptions);
          }
        });
        this.store.dispatch(
          new CartCustomerOptionsActions.SetCartCustomerOptions(
            cartCustomOptions
          )
        );

        //console.log("cartCustomOptions", cartCustomOptions);

        if (navigator.cookieEnabled) {
          window.localStorage.setItem(
            "cartCustomOptions",
            JSON.stringify(cartCustomOptions)
          );
        }
      }
    } else {
      console.log("clear custom options local storage");
      let cartCustomOptions = [];
      if (navigator.cookieEnabled) {
        window.localStorage.setItem(
          "cartCustomOptions",
          JSON.stringify(cartCustomOptions)
        );
      }
    }
  }

  completeOrderCartCustomOptionsValidations() {
    this.store
      .select("cartPrices")
      .pipe(first())
      .subscribe((cartPricesState) => {
        this.store
          .select("selectedPaymentMethodID")
          .pipe(first())
          .subscribe((state) => {
            this.notValidCustomOptions = [];
            let isRequiredOptions = [];
            let minOrderOptions = [];
            let validationObject: any;
            this.valid = true;
            _.each(this.selectedCustomOptions, (cartOption) => {
              if (
                cartOption &&
                cartOption.isRequired &&
                this.store_info.cartCustomOptionsActive &&
                this.cart &&
                this.cart.length > 0 &&
                cartOption.active &&
                (!this.dineIn || (this.dineIn && cartOption.showInDinein)) &&
                !cartOption.qtyEnabled &&
                (!cartOption.onlinePaymentOnly ||
                  (state.selectedPaymentMethodID !== "cash" &&
                    state.selectedPaymentMethodID !== "mobilePOS")) &&
                ((!cartOption.pickupOnly && !cartOption.deliveryOnly) ||
                  (cartOption.deliveryOnly &&
                    !cartOption.pickupOnly &&
                    !this.orderPickup) ||
                  (cartOption.pickupOnly &&
                    !cartOption.deliveryOnly &&
                    this.orderPickup))
              ) {
                const indexInArray = _.findIndex(isRequiredOptions, {
                  timestamp: cartOption.timestamp,
                });
                if (indexInArray === -1) {
                  isRequiredOptions.push(cartOption);
                }
              }
            });

            _.each(this.selectedCustomOptions, (cartOption) => {
              if (
                cartOption &&
                cartOption.minOrderCheckChoices &&
                this.store_info.cartCustomOptionsActive &&
                this.cart &&
                this.cart.length > 0 &&
                cartOption.active &&
                (!this.dineIn || (this.dineIn && cartOption.showInDinein)) &&
                !cartOption.qtyEnabled &&
                (!cartOption.onlinePaymentOnly ||
                  (state.selectedPaymentMethodID !== "cash" &&
                    state.selectedPaymentMethodID !== "mobilePOS")) &&
                ((!cartOption.pickupOnly && !cartOption.deliveryOnly) ||
                  (cartOption.deliveryOnly &&
                    !cartOption.pickupOnly &&
                    !this.orderPickup) ||
                  (cartOption.pickupOnly &&
                    !cartOption.deliveryOnly &&
                    this.orderPickup))
              ) {
                const indexInArray = _.findIndex(minOrderOptions, {
                  timestamp: cartOption.timestamp,
                });
                if (indexInArray === -1) {
                  minOrderOptions.push(cartOption);
                }
              }
            });

            validationObject = {
              id: "cart-custom-options",
              valid: true,
              errorMessages: [],
              isRequiredOptions: isRequiredOptions,
              minOrderOptions: minOrderOptions,
            };
            if (
              isRequiredOptions &&
              isRequiredOptions.length &&
              isRequiredOptions.length > 0
            ) {
              _.each(isRequiredOptions, (isRequiredOption) => {
                if (isRequiredOption && isRequiredOption.type !== "text") {
                  const option = _.find(this.cartCustomerOptions, {
                    timestamp: isRequiredOption.timestamp,
                  });

                  if (option) {
                    const selectedChoice = _.find(option.choices, {
                      selected: true,
                    });

                    if (!selectedChoice) {
                      validationObject.errorMessages.push(
                        "cartCustomOptions.you-have-to-select-one-choice"
                      );
                      validationObject.valid = false;
                      this.valid = false;
                      this.notValidCustomOptions.push(isRequiredOption);
                    }
                  } else {
                    validationObject.errorMessages.push(
                      "cartCustomOptions.you-have-to-select-one-choice"
                    );
                    validationObject.valid = false;
                    this.valid = false;
                    this.notValidCustomOptions.push(isRequiredOption);
                  }
                } else if (
                  isRequiredOption &&
                  isRequiredOption.type === "text"
                ) {
                  const option = _.find(this.cartCustomerOptions, {
                    timestamp: isRequiredOption.timestamp,
                  });

                  if (option) {
                    const selectedChoice = _.find(option.choices, (ch) => {
                      if (ch.selected && ch.text) {
                        return true;
                      }
                    });

                    if (!selectedChoice) {
                      validationObject.errorMessages.push(
                        "cartCustomOptions.you-have-to-write-something"
                      );
                      validationObject.valid = false;
                      this.valid = false;
                      this.notValidCustomOptions.push(isRequiredOption);
                    }
                  } else {
                    validationObject.errorMessages.push(
                      "cartCustomOptions.you-have-to-write-something"
                    );
                    validationObject.valid = false;
                    this.valid = false;
                    this.notValidCustomOptions.push(isRequiredOption);
                  }
                }
              });

              validationObject.isRequiredOptions = _.cloneDeep(
                this.notValidCustomOptions || []
              );
            }
            if (
              minOrderOptions &&
              minOrderOptions.length &&
              minOrderOptions.length > 0
            ) {
              _.each(minOrderOptions, (minOrderOption) => {
                const option = _.find(this.cartCustomerOptions, {
                  timestamp: minOrderOption.timestamp,
                });

                console.log(
                  "minOrderOptions",
                  option,
                  cartPricesState.cartPrices.grand_total
                );

                if (option) {
                  const choiceMinOrderError = _.find(option.choices, (ch) => {
                    if (
                      ch &&
                      ch.minOrder &&
                      cartPricesState &&
                      cartPricesState.cartPrices &&
                      cartPricesState.cartPrices.grand_total &&
                      ch.minOrder >
                        parseFloat(
                          _.cloneDeep(cartPricesState.cartPrices.grand_total)
                        )
                    ) {
                      return true;
                    }
                  });

                  if (choiceMinOrderError) {
                    validationObject.valid = false;
                    validationObject.errorMessages.push(
                      this.translate.instant(
                        "cartCustomOptions.option-has-min-order"
                      ) +
                        " " +
                        choiceMinOrderError.minOrder.toFixed(2) +
                        "€"
                    );
                    this.valid = false;
                    this.notValidCustomOptions.push(minOrderOption);
                  }
                }
              });
            }

            this.store.dispatch(
              new CartCustomerOptionsActions.SetNotValidCustomOptions(
                _.cloneDeep(this.notValidCustomOptions)
              )
            );

            this.store.dispatch(
              new CompleteOrderValidationActions.AddUpdateCompleteOrderValidation(
                _.cloneDeep(validationObject)
              )
            );
          })
          .unsubscribe();
      })
      .unsubscribe();
  }

  calculateNotValidCustomOptions() {
    this.store
      .select("selectedPaymentMethodID")
      .pipe(first())
      .subscribe((state) => {
        this.notValidCustomOptions = [];
        let isRequiredOptions = [];

        _.each(this.selectedCustomOptions, (cartOption) => {
          if (
            cartOption &&
            cartOption.isRequired &&
            this.store_info.cartCustomOptionsActive &&
            this.cart &&
            this.cart.length > 0 &&
            cartOption.active &&
            (!this.dineIn || (this.dineIn && cartOption.showInDinein)) &&
            !cartOption.qtyEnabled &&
            (!cartOption.onlinePaymentOnly ||
              (state.selectedPaymentMethodID !== "cash" &&
                state.selectedPaymentMethodID !== "mobilePOS")) &&
            ((!cartOption.pickupOnly && !cartOption.deliveryOnly) ||
              (cartOption.deliveryOnly &&
                !cartOption.pickupOnly &&
                !this.orderPickup) ||
              (cartOption.pickupOnly &&
                !cartOption.deliveryOnly &&
                this.orderPickup))
          ) {
            const indexInArray = _.findIndex(isRequiredOptions, {
              timestamp: cartOption.timestamp,
            });
            if (indexInArray === -1) {
              isRequiredOptions.push(cartOption);
            }
          }
        });

        if (
          isRequiredOptions &&
          isRequiredOptions.length &&
          isRequiredOptions.length > 0
        ) {
          let oneNotFound;
          _.each(isRequiredOptions, (isRequiredOption) => {
            const option = _.find(this.cartCustomerOptions, {
              timestamp: isRequiredOption.timestamp,
            });

            if (option) {
              const selectedChoice = _.find(option.choices, {
                selected: true,
              });

              if (!selectedChoice) {
                oneNotFound = true;
                this.notValidCustomOptions.push(isRequiredOption);
              }
            } else {
              oneNotFound = true;
              this.notValidCustomOptions.push(isRequiredOption);
            }
          });
        }

        this.store.dispatch(
          new CartCustomerOptionsActions.SetNotValidCustomOptions(
            _.cloneDeep(this.notValidCustomOptions)
          )
        );
      })
      .unsubscribe();
  }
  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }
}
