import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  AlertController,
  LoadingController,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import { Subscription } from "rxjs";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import * as AuthActions from "../../../auth/store/auth.actions";
import * as fromApp from "../../../store/app.reducer";

@Component({
  selector: "app-customer-preferences-modal",
  templateUrl: "./customer-preferences-modal.component.html",
  styleUrls: ["./customer-preferences-modal.component.scss"],
})
export class CustomerPreferencesModalComponent implements OnInit, OnDestroy {
  @Input() currentUser: any;
  @Input() mo_token: any;
  updatingDataLoading: boolean = false;

  private unsubscribeBackEvent: Subscription;

  constructor(
    private dataStorageService: DataStorageService,
    private translateService: TranslateService,
    private modalController: ModalController,
    private alertController: AlertController,
    private platform: Platform,
    private toastController: ToastController,
    private store: Store<fromApp.AppState>
  ) {}

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      300,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );
    if (navigator.cookieEnabled) {
      localStorage.setItem("askedForReviewsNotificationPermissions", "yes");
    }
  }

  closeModal() {
    this.modalController.dismiss();
  }

  saveCustomerPreferences() {
    this.updateCustomerData(
      { updatedData: _.cloneDeep(this.currentUser) },
      this.mo_token,
      true
    );
  }

  updateCustomerData(obj, mo_token, show_success_message) {
    if (show_success_message) {
      this.updatingDataLoading = true;
    }

    this.dataStorageService.updateCustomerData(obj, mo_token).subscribe({
      next: (res: any) => {
        if (res && res.success) {
          let updatedUser = res.data;
          this.store.dispatch(
            new AuthActions.SetUser(_.cloneDeep(updatedUser))
          );
          if (show_success_message && res.comment_id) {
            this.presentErrorToast(
              this.translateService.instant(
                "account-settings." + res.comment_id
              ),
              "primary"
            );
          }

          this.updatingDataLoading = false;
          this.modalController.dismiss("success");
        } else {
          if (res.comment_id) {
            this.presentErrorToast(
              this.translateService.instant(res.comment_id),
              "danger"
            );
          } else if (res.comments) {
            this.presentErrorToast(res.comments, "danger");
          }
          this.updatingDataLoading = false;
        }
      },
      error: (error) => {
        let errMsg = this.translateService.instant(
          "errorMessages.error: There was an error on saving"
        );
        this.updatingDataLoading = false;
        this.presentErrorToast(errMsg, "danger");
        return error;
        console.log("error updating the information");
      },
    });
  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }

  ngOnDestroy() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
  }
}
