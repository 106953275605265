<ion-item-divider mode="ios">
  <ion-label>{{"pages.invoice-data" | translate}}</ion-label>

  <ion-button
    *ngIf="storeInfo"
    [hidden]="
      (storeInfo.enable_unique_invoice &&
      currentUser.invoiceData &&
      currentUser.invoiceData.length > 0) || (storeInfo.vatBasedCustomerProductsActive)
      "
    fill="clear"
    slot="end"
    (click)="presentAddEditModal('add',null)"
    ><ion-icon slot="icon-only" src="assets/ionicons/add.svg"></ion-icon
  ></ion-button>
</ion-item-divider>

<ion-row *ngIf="!currentUser || !currentUser.invoiceData">
  <ion-col>
    <ion-spinner> </ion-spinner>
  </ion-col>
</ion-row>

<ion-row
  *ngIf="currentUser && (!currentUser.invoiceData || currentUser.invoiceData.length === 0)"
>
  <ion-col size="12">
    <ion-text><h6>{{"invoice-data.no-invoice-data" | translate}}</h6></ion-text>
  </ion-col>
</ion-row>

<ion-list
  *ngIf="currentUser && currentUser.invoiceData && currentUser.invoiceData.length > 0"
>
  <ion-item
    *ngFor="let invoice of currentUser.invoiceData; let index=index;trackBy:trackInvoices"
  >
    <ion-row>
      <ion-col size="12" class="ion-no-padding ion-no-margin">
        <ion-label class="ion-text-wrap">
          <strong
            ><ion-text *ngIf="invoice.vatNumber">
              {{"invoice-data.vat-number" | translate}} : {{invoice.vatNumber}}
            </ion-text></strong
          >
          <p>
            <ion-text *ngIf="invoice.company_name">
              {{invoice.company_name}}
            </ion-text>
          </p>
          <p>
            <ion-text *ngIf="invoice.company_address">
              {{invoice.company_address}}
            </ion-text>
          </p>
          <p>
            <ion-text *ngIf="invoice.vatOffice">
              {{"invoice-data.vat-office" | translate}}: {{invoice.vatOffice}}
            </ion-text>
          </p>
          <p>
            <ion-text *ngIf="invoice.activity">
              {{"invoice-data.activity" | translate}}: {{invoice.activity}}
            </ion-text>
          </p>
        </ion-label>
      </ion-col>
      <ion-col
        *ngIf="!storeInfo.vatBasedCustomerProductsActive"
        size="12"
        class="ion-text-right ion-no-padding ion-no-margin"
      >
        <ion-button
          fill="clear"
          color="primary"
          style="font-weight: 600"
          (click)="presentAddEditModal('edit', invoice)"
          >{{ "invoice-data.edit" | translate }}</ion-button
        >

        <ion-button
          (click)="deleteInvoice(index)"
          color="danger"
          fill="clear"
          style="font-weight: 600"
          >{{ "invoice-data.delete" | translate }}</ion-button
        >
      </ion-col>
    </ion-row>
  </ion-item>
</ion-list>
