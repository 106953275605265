import * as SelectedInvoiceActions from "./selectedInvoice.actions";
import * as _ from "lodash";

export interface State {
  selectedInvoice: any;
  invoiceRequiredOpenModal: any;
}

const initialState: State = {
  selectedInvoice: null,
  invoiceRequiredOpenModal: false,
};

export function selectedInvoiceReducer(
  state = initialState,
  action: SelectedInvoiceActions.SelectedInvoiceActions
) {
  switch (action.type) {
    case SelectedInvoiceActions.SET_SELECTED_INVOICE:
      return {
        ...state,
        selectedInvoice: action.payload,
      };
    case SelectedInvoiceActions.INVOICE_REQUIRED_OPEN_MODAL:
      return {
        ...state,
        invoiceRequiredOpenModal: action.payload,
      };

    case SelectedInvoiceActions.CLEAR_SELECTED_INVOICE:
      return {
        ...state,
        selectedInvoice: null,
      };

    default:
      return state;
  }
}
