import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../../store/app.reducer";
import * as selectors from "../../../../store/app.selectors";
import * as _ from "lodash";
import { distinctUntilChanged } from "rxjs/operators";
import { Subscription } from "rxjs";

@Component({
  selector: "app-radio-option-open-choices",
  templateUrl: "./radio-option-open-choices.component.html",
  styleUrls: ["./radio-option-open-choices.component.scss"],
})
export class RadioOptionOpenChoicesComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() selectedProductId: any;
  @Input() bundleMode: any;
  @Input() option_id;
  @Input() productName: any;
  @Input() selectedProductPromo: any;
  @Input() selectedProductCategory: any;
  @Input() groupStoreData: any;
  public option: any;
  public store_info;
  public alt_dinein_active;
  private optionSubscription: Subscription;
  private storeSub: Subscription;
  @Output() optionChange = new EventEmitter();

  constructor(private store: Store<fromApp.AppState>) {}

  selectOption() {
    if (this.optionSubscription) {
      this.optionSubscription.unsubscribe();
    }
    this.optionSubscription = this.store
      .select(
        selectors.getOptionByOptionId(this.selectedProductId, this.option_id)
      )
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (!_.isEqual(this.option, state)) {
          this.option = _.cloneDeep(state);
          console.log("option radio option open choices", this.option);
        }
      });
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (
      simpleChanges &&
      simpleChanges.option_id &&
      simpleChanges.option_id.currentValue !==
        simpleChanges.option_id.previousValue
    ) {
      this.selectOption();
    }
  }

  ngOnInit() {
    this.selectOption();

    this.storeSub = this.store
      .select(selectors.getStoreInfo)
      .pipe(distinctUntilChanged())
      .subscribe((store_info) => {
        if (store_info && !_.isEqual(this.store_info, store_info)) {
          this.store_info = _.cloneDeep(store_info);
          if (this.store_info && this.store_info.dinein) {
            this.alt_dinein_active = this.store_info.alt_dinein_active;
          } else {
            this.alt_dinein_active = false;
          }
        }
      });
  }

  optionChanged(opt) {
    console.log("option changed radio open", opt);
    this.optionChange.emit(opt);
  }

  ngOnDestroy() {
    if (this.optionSubscription) {
      this.optionSubscription.unsubscribe();
    }

    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
  }
}
