<ion-header>
  <ion-toolbar mode="ios">
    <ion-title>
      {{ "affiliate.affiliate-order-modal-title" | translate }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button color="dark" fill="clear" (click)="closeModal()">
        <ion-icon slot="icon-only" src="assets/ionicons/close.svg"> </ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-grid >
  <ion-row>
    <ion-col>
      <ion-text [innerHtml]="affiliateInformationAndTerms.el"></ion-text>
    </ion-col>
  </ion-row>
</ion-grid>