import { Pipe, PipeTransform } from "@angular/core";
import * as _ from "lodash";

@Pipe({
  name: "filterPaymentMethod",
})
export class FilterPaymentMethodPipe implements PipeTransform {
  transform(paymentGateways: any, currentUser: any): any {
    for (const key in paymentGateways) {
      if (
        (paymentGateways &&
          paymentGateways[key] &&
          paymentGateways[key].disable_payment_gateway) ||
        !(
          paymentGateways &&
          paymentGateways[key] &&
          paymentGateways[key].active &&
          (!paymentGateways[key].adminOnly ||
            (currentUser && currentUser.admin))
        ) ||
        !(
          paymentGateways &&
          paymentGateways[key] &&
          paymentGateways[key].active &&
          (!paymentGateways[key].customerOnly ||
            (currentUser &&
              ((currentUser.admin && paymentGateways[key].adminOnly) ||
                (currentUser.customerPaymentMethods &&
                  currentUser.customerPaymentMethods[key]))))
        )
      ) {
        delete paymentGateways[key];
      }
    }
    return paymentGateways;
  }
}
