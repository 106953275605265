import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import moment from "moment";
import * as fromApp from "../../store/app.reducer";
import * as CustomerCouponsActions from "../../discount-coupons/store/coupons.actions";
import { Subscription } from "rxjs";

@Component({
  selector: "app-kiosk-coupon-qr-display-modal",
  templateUrl: "./kiosk-coupon-qr-display-modal.component.html",
  styleUrls: ["./kiosk-coupon-qr-display-modal.component.scss"],
})
export class KioskCouponQrDisplayModalComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() stores: any;
  @Input() selectedLangugage: any;
  @Input() coupon: any;
  public storesByStoreId: any;
  //timer kiosk qr expiration vars
  timeLimit: number = 60 * 60; // one hour
  secondsRemaining: number = 0;
  formattedTime: string = "";

  private kioskQrTimerInterval;
  private unsubscribeBackEvent: Subscription;

  constructor(
    private modalController: ModalController,
    private changeDetector: ChangeDetectorRef,
    private store: Store<fromApp.AppState>,
    private platform: Platform
  ) {}

  ngOnChanges(changes: SimpleChanges): void {}

  closeModal() {
    this.modalController.dismiss();
  }

  ngOnInit() {
    this.storesByStoreId = _.groupBy(this.stores, "store_id");

    _.each(this.storesByStoreId, (store, key, list) => {
      this.storesByStoreId[key] = store[0];
    });

    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );

    if (this.coupon && this.coupon.kiosk_expiration_timestamp) {
      let momentTimestamp;

      if (
        this.timestampIsMilliseconds(
          _.cloneDeep(this.coupon.kiosk_expiration_timestamp)
        )
      ) {
        momentTimestamp = moment(
          parseInt(
            _.cloneDeep(this.coupon.kiosk_expiration_timestamp).toString()
          )
        );
      } else {
        momentTimestamp = moment.unix(
          parseInt(
            _.cloneDeep(this.coupon.kiosk_expiration_timestamp).toString()
          )
        );
      }

      // Calculate the remaining seconds
      this.secondsRemaining = momentTimestamp.diff(moment(), "seconds");

      if (this.secondsRemaining) {
        this.startTimerKioskQr();
      }
    }
  }

  triggerFetchCoupons() {
    this.store.dispatch(
      new CustomerCouponsActions.SetFetchCouponsAgainTimestamp(
        Date.now().toString()
      )
    );
    this.modalController.dismiss();
  }

  startTimerKioskQr() {
    this.updateTimerKioskQr();

    if (this.kioskQrTimerInterval) {
      clearInterval(this.kioskQrTimerInterval);
      this.kioskQrTimerInterval = null;
    }
    this.kioskQrTimerInterval = setInterval(() => {
      this.secondsRemaining = _.cloneDeep(this.secondsRemaining) - 1;

      if (this.secondsRemaining < 0) {
        if (this.kioskQrTimerInterval) {
          clearInterval(this.kioskQrTimerInterval);
          this.kioskQrTimerInterval = null;
        }
      } else {
        this.updateTimerKioskQr();
      }
    }, 1000);
  }

  timestampIsMilliseconds(timestamp) {
    const length = timestamp.toString().length;
    return length === 13;
  }

  updateTimerKioskQr() {
    const minutes = parseInt(
      Math.floor(_.cloneDeep(this.secondsRemaining) / 60).toString()
    );
    const seconds = parseInt(
      (_.cloneDeep(this.secondsRemaining) % 60).toString()
    );

    this.formattedTime = moment()
      .set("minutes", minutes)
      .set("seconds", seconds)
      .format("mm:ss");

    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.kioskQrTimerInterval) {
      clearInterval(this.kioskQrTimerInterval);
      this.kioskQrTimerInterval = null;
    }
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }

    this.secondsRemaining = 0;
  }
}
