import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../../store/app.reducer";
import * as selectors from "../../../../store/app.selectors";
import * as _ from "lodash";
import { AlertController, ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import * as CartActions from "../../../../cart/store/cart.actions";
import { distinctUntilChanged, first } from "rxjs/operators";
import { Subscription } from "rxjs";
import { ProductDetailsComponent } from "src/app/product-details/product-details.component";
import moment from "moment";
import { ProductValidDeliveryAreasPopupComponent } from "src/app/popups/product-valid-delivery-areas-popup/product-valid-delivery-areas-popup.component";
import { Console } from "console";

@Component({
  selector: "app-cart-list-product-item",
  templateUrl: "./cart-list-product-item.component.html",
  styleUrls: ["./cart-list-product-item.component.scss"],
})
export class CartListProductItemComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() cartProduct;
  @Input() onlyAvailableForPreorderProductsById: any;
  @Input() selectedAddress: any;
  public finalPriceLoading: boolean;
  public showProductItemDetails: boolean = false;
  public orderPickup: any;
  public dineIn: any;
  public deleteAlertConfirm: any;
  public groupApp: any;
  public productPointsSystemException: boolean;
  public productPointSystemWeight: number = 1;
  public store_info: any;
  public selectedDineInTable: any;
  public doNotShowNeedsEditMessage: boolean = false;
  selectedPreorderDate;
  preorderDayAfterToday;
  cartPrices

  private windowDimensions;
  private allCartProducts: any;
  private productDetailsModal: any;
  private productValidDeliveryAreasModal: any;
  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store<fromApp.AppState>,
    private alertCtrl: AlertController,
    private translateService: TranslateService,
    private modalController: ModalController
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.checkProductIfBundleOpenChoices();
  }

  ngOnInit() {
    this.checkProductIfBundleOpenChoices();
    this.subscriptions.push(
      this.store
        .select("deliveryMethodAddress")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && !_.isEqual(this.orderPickup, state.orderPickup)) {
            this.orderPickup = _.cloneDeep(state.orderPickup);
          }

          if (state && !_.isEqual(this.dineIn, state.dineIn)) {
            this.dineIn = _.cloneDeep(state.dineIn);
          }

          if (
            state &&
            !_.isEqual(this.selectedDineInTable, state.selectedDineInTable)
          ) {
            this.selectedDineInTable = _.cloneDeep(state.selectedDineInTable);
          }

          if (
            state &&
            !_.isEqual(this.selectedPreorderDate, state.selectedPreorderDate)
          ) {
            this.selectedPreorderDate = _.cloneDeep(state.selectedPreorderDate);
            this.preorderDayAfterToday = this.checkPreorderDateIsAfterToday();
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("cart")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(
              this.onlyAvailableForPreorderProductsById,
              state.onlyAvailableForPreorderCartProductsById
            )
          ) {
            this.onlyAvailableForPreorderProductsById = _.cloneDeep(
              state.onlyAvailableForPreorderCartProductsById
            );
          }

          if (
            state &&
            state.products &&
            !_.isEqual(this.allCartProducts, state.products)
          ) {
            this.allCartProducts = _.cloneDeep(state.products);

            this.store
              .select("deliveryMethodAddress")
              .pipe(first())
              .subscribe((state) => {
                this.checkForHideNeedsEditDebounced(
                  state.orderPickup,
                  state.dineIn
                );
              })
              .unsubscribe();
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("disableFinalPrice")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.finalPriceLoading, state.disableFinalPrice)
          ) {
            this.finalPriceLoading = _.cloneDeep(state.disableFinalPrice);
          }
        })
    );

    // if (this.cartProduct) {
    //   this.cartProduct.options.forEach((opt) => {
    //     //console.log(opt);
    //   });
    // }
    // this.subscription2 = this.store
    //   .select("deliveryMethodAddress")
    //   .subscribe((state) => {
    //     if (state && _.isEqual(state.orderPickup, this.orderPickup)) {
    //       this.orderPickup = _.cloneDeep(state.orderPickup);
    //     }
    //   });

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(state.groupApp, this.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .subscribe((store_info) => {
          if (store_info && !_.isEqual(store_info, this.store_info)) {
            this.store_info = _.cloneDeep(store_info);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(state.windowDimensions, this.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("cartPrices")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && !_.isEqual(state.cartPrices, this.cartPrices)) {
            this.cartPrices = _.cloneDeep(state.cartPrices);
          }
        })
    );
  }

  checkProductIfBundleOpenChoices() {
    if (this.cartProduct && this.cartProduct.options) {
      _.each(this.cartProduct.options, (opt) => {
        if (this.showProductItemDetails) {
          return false;
        }
        if (opt.bundle && opt.choices) {
          _.each(opt.choices, (ch) => {
            if (ch.bundle && ch.is_preselected) {
              this.showProductItemDetails = true;
              return false;
            }
          });
        }
      });
    }
  }

  checkPreorderDateIsAfterToday() {
    if (
      this.selectedPreorderDate &&
      this.selectedPreorderDate.date &&
      this.selectedPreorderDate.hour
    ) {
      const preorderDate = moment(
        `${this.selectedPreorderDate.date} ${this.selectedPreorderDate.hour}`,
        "DD-MM-YYYY HH:mm"
      );

      const tomorrowDate = moment()
        .add(1, "day")
        .set("hour", 0)
        .set("minutes", 0);

      if (preorderDate.isAfter(tomorrowDate)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  calculateProductPointSystemAttributes() {}

  // calculatePrice() {
  //   let price = parseFloat(this.cartProduct.price);
  //   let quantity = parseFloat(this.cartProduct.quantity);
  //   return (price * quantity).toFixed(2) + "€";
  // }

  presentAlertConfirm(cartProductItem) {
    if (!this.deleteAlertConfirm) {
      this.deleteAlertConfirm = true;
      this.showAlertConfirm(cartProductItem);
    }
  }

  async showAlertConfirm(cartProductItem) {
    this.deleteAlertConfirm = await this.alertCtrl.create({
      cssClass: "my-custom-class",
      header: this.translateService.instant(
        "cart.cart-components.cart-list.cart-list-product-item.delete"
      ),
      message: this.translateService.instant(
        "cart.cart-components.cart-list.cart-list-product-item.do-you-want-to-delete-product-from-cart"
      ),
      buttons: [
        {
          text: this.translateService.instant(
            "cart.cart-components.cart-list.cart-list-product-item.cancel"
          ),
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: this.translateService.instant(
            "cart.cart-components.cart-list.cart-list-product-item.delete"
          ),
          handler: () => {
            this.deleteCartItem(cartProductItem);
          },
        },
      ],
    });

    await this.deleteAlertConfirm.present();
    this.deleteAlertConfirm.onDidDismiss().then(() => {
      this.deleteAlertConfirm = false;
    });
  }

  showProductDetailsModal(product) {
    let detailsCssClass;
    if (
      product &&
      product.options &&
      product.options.length < 2 &&
      product.noImageProduct
    ) {
      detailsCssClass = "productDetailsModalSmall";
    } else if (
      product &&
      product.options &&
      !product.squareImageDesign &&
      product.options.length > 2 &&
      !product.noImageProduct
    ) {
      detailsCssClass = "productDetailsModalBig";
    } else if (
      product &&
      product.squareImageDesign &&
      product.options &&
      product.options.length > 2 &&
      !product.noImageProduct &&
      this.windowDimensions &&
      this.windowDimensions.width >= 1200
    ) {
      detailsCssClass = "productDetailsModalBigSquareImageDesign";
    } else {
      detailsCssClass = "productDetailsModal";
    }
    console.log("detals css", detailsCssClass, product);
    if (!this.productDetailsModal) {
      this.productDetailsModal = true;
      this.presentProductDetailsModal(product, detailsCssClass);
    }
  }

  async presentProductDetailsModal(product, cssClass) {
    let product_id: string;
    let cart_id: string;
    if (product && product.product_id) {
      product_id = product.product_id;
    }

    if (product && product.cart_id) {
      cart_id = product.cart_id;
    }

    this.productDetailsModal = await this.modalController.create({
      component: ProductDetailsComponent,
      cssClass: cssClass,
      animated: true,
      componentProps: {
        product_id: product.product_id,
        cart_id: product.cart_id,
      },
      backdropDismiss: false,
    });

    await this.productDetailsModal.present();
    this.productDetailsModal.onDidDismiss().then(() => {
      this.productDetailsModal = null;
    });
  }

  productHaveDetails(prd) {
    let showProducts = false;

    _.each(prd.options, (option) => {
      if (
        option &&
        option.option_id &&
        (!option.dependenciesVariable ||
          option.dependenciesVariable.length === 0 ||
          (option.dependenciesVariable.length > 0 && option.showDependent))
      ) {
        if (option.type !== "field") {
          if (
            !(
              option.choices &&
              !option.reverseChoices &&
              this.choicesIsPreselected(option.choices).length === 0
            ) ||
            (option.is_require && option.availableTotal)
          ) {
            if (
              !option.doNotPrint ||
              (option.parent_free && option.option_parent_total_price === 0)
            ) {
              //console.log("retuntrue");
              showProducts = true;
            } else {
              //console.log("retunfalse");
            }
          }
          if (
            option.type === "checkbox" &&
            option.reverseChoices &&
            option.choicesComment
          ) {
            //console.log("retuntrue");
            showProducts = true;
          }
          _.each(option.choices, (choice) => {
            if (
              !choice.doNotPrint &&
              (option.type !== "checkbox" ||
                !option.reverseChoices ||
                (!option.choicesComment && !option.reverseChoices))
            ) {
              showProducts = true;
            }

            if (choice.choicePriceComment) {
              showProducts = true;
            } else {
              if (choice.price) {
                showProducts = true;
              }
            }
          });
        } else {
          if (option.text) {
            //console.log("retuntrue");
            showProducts = true;
          } else {
            // console.log("retunfalse");
          }
        }
      } else {
        // console.log("retunfalse");
      }
    });
    return showProducts;
  }

  showProductValidDeliveryAreasInfoModal() {
    if (!this.productValidDeliveryAreasModal) {
      this.presentProductValidDeliveryAreasModal();
    }
  }

  async presentProductValidDeliveryAreasModal() {
    this.productValidDeliveryAreasModal = await this.modalController.create({
      component: ProductValidDeliveryAreasPopupComponent,
      cssClass: "productValidDeliveryAreasPopup",
      animated: true,
      componentProps: {
        notValidAddress: this.selectedAddress,
        cartProduct: this.cartProduct,
      },
      backdropDismiss: false,
    });

    await this.productValidDeliveryAreasModal.present();
    this.productValidDeliveryAreasModal.onDidDismiss().then(() => {
      this.productValidDeliveryAreasModal = null;
    });
  }

  choicesIsPreselected(choices) {
    let preselectedArray = [];
    choices.forEach((choice) => {
      if (choice && choice.is_preselected) {
        preselectedArray.push(choice);
      }
    });

    return preselectedArray;
  }

  deleteCartItem(product) {
    if (navigator.cookieEnabled) {
      let cart = JSON.parse(window.localStorage.getItem("cart"));

      if (cart) {
        let indexOfProduct = _.findIndex(cart, {
          cart_id: product.cart_id,
        });

        if (indexOfProduct !== -1) {
          cart.splice(indexOfProduct, 1);

          window.localStorage.removeItem("cart");

          window.localStorage.setItem("cart", JSON.stringify(cart));
        }
      }
    }
    this.store.dispatch(new CartActions.DeleteCartProductItem(product));
  }

  private checkForHideNeedsEditDebounced = _.debounce((orderPickup, dineIn) => {
    this.checkForHideNeedsEdit(orderPickup, dineIn);
  }, 200);

  checkForHideNeedsEdit(orderPickup, dineIn) {
    //this function checking if the product is only delivery, dine in,... etc and hiding needs edit message because we display a badge

    if (
      (this.cartProduct &&
        this.cartProduct.product_categories_availability_check &&
        this.cartProduct.availableTotal &&
        this.cartProduct.activeTotal &&
        this.cartProduct.pickup &&
        !this.cartProduct.delivery &&
        !this.cartProduct.dinein &&
        orderPickup) ||
      (this.cartProduct &&
        this.cartProduct.product_categories_availability_check &&
        this.cartProduct.availableTotal &&
        this.cartProduct.activeTotal &&
        this.cartProduct.delivery &&
        !this.cartProduct.dinein &&
        !this.cartProduct.pickup &&
        (orderPickup || dineIn)) ||
      (this.cartProduct &&
        this.cartProduct.product_categories_availability_check &&
        this.cartProduct.availableTotal &&
        this.cartProduct.activeTotal &&
        this.cartProduct.dinein &&
        !this.cartProduct.delivery &&
        !this.cartProduct.pickup &&
        !dineIn) ||
      (this.cartProduct &&
        this.cartProduct.product_categories_availability_check &&
        this.cartProduct.availableTotal &&
        this.cartProduct.activeTotal &&
        this.cartProduct.dinein &&
        this.cartProduct.delivery &&
        !this.cartProduct.pickup &&
        orderPickup) ||
      (this.cartProduct &&
        this.cartProduct.product_categories_availability_check &&
        this.cartProduct.availableTotal &&
        this.cartProduct.activeTotal &&
        this.cartProduct.delivery &&
        this.cartProduct.pickup &&
        !this.cartProduct.dinein &&
        dineIn) ||
      (this.cartProduct &&
        this.cartProduct.product_categories_availability_check &&
        this.cartProduct.availableTotal &&
        this.cartProduct.activeTotal &&
        !this.cartProduct.delivery &&
        this.cartProduct.pickup &&
        this.cartProduct.dinein &&
        !orderPickup &&
        !dineIn)
    ) {
      this.doNotShowNeedsEditMessage = true;
    } else {
      this.doNotShowNeedsEditMessage = false;
    }
  }

  ngOnDestroy() {
    if (this.productValidDeliveryAreasModal) {
      this.productValidDeliveryAreasModal.dismiss();
      this.productValidDeliveryAreasModal = null;
    }
    if (this.productDetailsModal) {
      this.productDetailsModal.dismiss();
      this.productDetailsModal = null;
    }

    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
      this.subscriptions = [];
    }
  }
}
