<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">
      {{ "catalog.unavailable-products-modal.title" | translate }}
    </ion-title>
    <p
      style="padding-inline-start: 20px; margin: 0; font-size: small"
      *ngIf="category && category.name"
    >
      {{ category.name }}
    </p>
    <ion-buttons slot="end">
      <ion-button color="dark" fill="clear" (click)="closeModal()">
        <ion-icon slot="icon-only" src="assets/ionicons/close.svg"> </ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid *ngIf="category && products && hyperCategories">
    <span
      *ngIf="
        category &&
        !category.cardsDesign &&
        !category.sliderDesign &&
        !category.marketCardsDesign
      "
    >
      <app-product-items-mobile
        *ngFor="let product of products; trackBy: productsTrack"
        [product_id]="product.product_id"
        [productImage]="product.image"
        [category_id]="category.category_id"
        [hyperCategories]="hyperCategories"
        [imageBrowser]="imageBrowser"
        [dineIn]="dineIn"
        [orderPickup]="orderPickup"
        [groupStoreData]="groupStoreData"
        [selectedDineInTable]="selectedDineInTable"
        [selectedPreorderDate]="selectedPreorderDate"
        [fromUnavailableProductsModal]="true"
        [webp]="webp"
      ></app-product-items-mobile>
    </span>

    <span
      *ngIf="
        category &&
        category.cardsDesign &&
        !category.sliderDesign &&
        !category.marketCardsDesign &&
        windowDimensions &&
        products
      "
    >
      <ion-row style="width: 100%" *ngIf="imageBrowser">
        <ion-col
          size="6"
          *ngFor="let product of products; trackBy: productsTrack"
        >
          <app-product-item-card
            [category]="category"
            [product]="product"
            [webp]="webp"
            [orderPickup]="orderPickup"
            [groupStoreData]="groupStoreData"
            [selectedDineInTable]="selectedDineInTable"
            [selectedPreorderDate]="selectedPreorderDate"
            [dineIn]="dineIn"
          >
          </app-product-item-card>
        </ion-col>
      </ion-row>

      <ion-row style="width: 100%" *ngIf="!imageBrowser">
        <ion-col
          size="6"
          *ngFor="let product of products; trackBy: productsTrack"
        >
          <app-product-item-card-mobile
            [category]="category"
            [product]="product"
            [webp]="webp"
            [orderPickup]="orderPickup"
            [groupStoreData]="groupStoreData"
            [selectedDineInTable]="selectedDineInTable"
            [selectedPreorderDate]="selectedPreorderDate"
            [dineIn]="dineIn"
          >
          </app-product-item-card-mobile>
        </ion-col>
      </ion-row>
    </span>

    <span
      *ngIf="
        category &&
        !category.cardsDesign &&
        !category.sliderDesign &&
        category.marketCardsDesign &&
        windowDimensions &&
        products
      "
    >
      <ion-row style="width: 100%" *ngIf="imageBrowser">
        <ion-col
          size="6"
          *ngFor="let product of products; trackBy: productsTrack"
        >
          <app-product-item-market-type-card-browser
            [category]="category"
            [product]="product"
            [webp]="webp"
            [orderPickup]="orderPickup"
            [groupStoreData]="groupStoreData"
            [selectedDineInTable]="selectedDineInTable"
            [selectedPreorderDate]="selectedPreorderDate"
            [dineIn]="dineIn"
          >
          </app-product-item-market-type-card-browser>
        </ion-col>
      </ion-row>

      <ion-row style="width: 100%" *ngIf="!imageBrowser">
        <ion-col
          size="6"
          *ngFor="let product of products; trackBy: productsTrack"
        >
          <app-product-item-market-type-card-mobile
            [category]="category"
            [product]="product"
            [webp]="webp"
            [orderPickup]="orderPickup"
            [groupStoreData]="groupStoreData"
            [selectedDineInTable]="selectedDineInTable"
            [selectedPreorderDate]="selectedPreorderDate"
            [dineIn]="dineIn"
          >
          </app-product-item-market-type-card-mobile>
        </ion-col>
      </ion-row>
    </span>
  </ion-grid>
</ion-content>
