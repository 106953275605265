import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  AlertController,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as fromApp from "../../store/app.reducer";
import * as AuthActions from "../../auth/store/auth.actions";
import * as _ from "lodash";
import { auditTime, distinctUntilChanged } from "rxjs/operators";
import { Subscription } from "rxjs";
import { getAuth } from "@firebase/auth";
import { FirebaseBrowserService } from "src/app/services/firebaseBrowser/firebase-browser.service";
import { AnalyticsServiceService } from "src/app/services/analitycsService/analytics-service.service";
import { Config } from "ng-otp-input/lib/models/config";
import { Capacitor } from "@capacitor/core";
import {
  AuthStateChange,
  FirebaseAuthentication,
} from "@capacitor-firebase/authentication";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";

declare let window: any;
@Component({
  selector: "app-magic-login-confirmation-reservation-active",
  templateUrl: "./magic-login-confirmation-reservation-active.component.html",
  styleUrls: ["./magic-login-confirmation-reservation-active.component.scss"],
})
export class MagicLoginConfirmationReservationActiveComponent
  implements OnInit, OnDestroy
{
  @ViewChild("ngOtpInput") ngOtpInputRef: any;
  @Input() newCustomer: any;
  public currentUser: any;
  public groupApp: any;
  public loading: boolean = false;
  public showOtpComponent: boolean = false;
  public codeFull: boolean = false;
  public enterKey: any;
  public windowDimensions: any;
  private unsubscribeBackEvent: Subscription;
  private subscription: Subscription;
  private subscription2: Subscription;
  private subscription3: Subscription;
  headerLogo: any;
  storeImage: any;
  darkHeaderLogo: any;
  private groupSubscription: Subscription;
  config: Config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    inputStyles: {
      width: "37px",
      height: "37px",
      fontSize: "18px", // Use 'fontSize' instead of 'font-size'
      marginRight: "5px", // Use 'marginRight' instead of 'margin-right'
      color: "var(--ion-color-primary)", // Set the text color of the input elements
      background: "var(--ion-color-primary-contrast)",
    },
  };
  rootDir: string;
  selectedLogoFileName: any;

  constructor(
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private translateService: TranslateService,
    private store: Store<fromApp.AppState>,
    private toastController: ToastController,
    private firebaseBrowserService: FirebaseBrowserService,
    private platform: Platform,
    private analyticsService: AnalyticsServiceService,
    private dataStorageService: DataStorageService
  ) {}

  async ngOnInit() {
    this.showOtpComponent = true;

    console.log(this.newCustomer);
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      300,
      () => {
        if (this.platform.is("android")) {
          this.modalCtrl.dismiss();
        }
      }
    );
    this.subscription = this.store
      .select("auth")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(200))
      .subscribe((state) => {
        if (state && state.user && !_.isEqual(this.currentUser, state.user)) {
          this.currentUser = _.cloneDeep(state.user);
          if (this.currentUser) {
            this.presentAlertSuccessLogin();
            this.modalCtrl.dismiss();
          }
        }
      });

    this.subscription2 = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.groupApp &&
          !_.isEqual(this.groupApp, state.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp);
          if (!_.isEmpty(this.groupApp.logosImages)) {
            this.selectedLogoFileName = _.find(this.groupApp.logosImages, {
              selected: true,
            }).fileName;
          }
          this.dataStorageService.fetchGroupApp(this.groupApp.group).subscribe({
            next: (res: any) => {
              if (res) {
                this.storeImage = res.iconImage;
                this.headerLogo = res.headerLogo;
                console.log("groupAppHeaderLogo", res.headerLogo);
                this.darkHeaderLogo = res.headerLogoDark;
              } else {
              }
            },
            error: (error) => {
              return error;
            },
          });
        }
        if (state && state.rootDir && !_.isEqual(this.rootDir, state.rootDir)) {
          this.rootDir = _.cloneDeep(state.rootDir);
        }
      });

    this.store
      .select("windowInfo")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(300))
      .subscribe((state) => {
        if (!_.isEqual(state.windowDimensions, this.windowDimensions)) {
          this.windowDimensions = _.cloneDeep(state.windowDimensions);
        }
      });

    this.subscription3 = this.store
      .select("windowInfo")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.windowDimensions &&
          !_.isEqual(this.windowDimensions, state.windowDimensions)
        ) {
          this.windowDimensions = _.cloneDeep(state.windowDimensions);
          console.log("window dim", this.windowDimensions);
        }
      });
    // if (this.newCustomer && this.newCustomer.createOneTimePass) {
    //   this.newCustomer.createOneTimePass = false;
    //   _.omit(this.newCustomer, ["createOneTimePass"]);
    //   this.store.dispatch(
    //     new AuthActions.SetNewCustomer(_.cloneDeep(this.newCustomer))
    //   );
    // }

    // await FirebaseAuthentication.addListener(
    //   "authStateChange",
    //   async (event: AuthStateChange) => {
    //     if (event.user) {
    //       console.log("event.user", event.user);
    //       const result = await FirebaseAuthentication.getIdToken();
    //       console.log("result", result);
    //       this.writeToAuthStateDebounce("OK", result.token);
    //     }
    //   }
    // );
  }

  async setModalBreakPointFull(ev) {
    if (
      this.windowDimensions &&
      this.windowDimensions.width &&
      this.windowDimensions.width < 500
    ) {
      const modal: any = await this.modalCtrl.getTop();
      console.log("modalElement", modal);
      if (modal) {
        modal.setCurrentBreakpoint(1);
      }
    }
  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }

  handleEnterButton(event) {
    if ((event.key === "Enter" || event.keyCode === 13) && this.codeFull) {
      this.onClickOk();
    }
  }

  onClickOk() {
    this.analyticsService.dmAnalyticsMessage(
      "submit_password_button",
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
    this.loading = true;
    console.log("this.newCustomer", this.newCustomer);
    if (!this.newCustomer || (this.newCustomer && !this.newCustomer.password)) {
      this.presentErrorToast(
        this.translateService.instant("popups.magic-login-confirmation.please-enter-your-password"),
        "danger"
      );
      this.loading = false;
    } else {
      if (
        this.groupApp &&
        this.groupApp.firebaseVerificationEnabled &&
        this.groupApp.webFirebase &&
        this.groupApp.webFirebase.apiKey &&
        !Capacitor.isNativePlatform()
      ) {
        if (window.confirmationResult) {
          let fireBaseApp = this.firebaseBrowserService.getFireBaseApp();
          const auth = getAuth(fireBaseApp);
          window.confirmationResult
            .confirm(this.newCustomer.password)
            .then((result) => {
              auth.currentUser
                .getIdToken(true)
                .then((idToken) => {
                  console.log("idToken", idToken); // Add here the token!
                  if (
                    this.newCustomer &&
                    this.newCustomer.password &&
                    this.newCustomer.password.length &&
                    idToken
                  ) {
                    this.writeToAuthStateDebounce("OK", idToken);
                    this.loading = false;
                  } else {
                    this.loading = false;
                    console.log("fill the password");
                    //handled by disable ok button
                  }
                })
                .catch((error) => {
                  // Handle error
                  console.log("handle error", error);
                  this.presentErrorToast(
                    this.translateService.instant("errorMessages.something_went_wrong_try_again"),
                    "danger"
                  );
                  this.loading = false;

                  if (error && error.message) {
                    console.log("error message", error.message);
                  }
                });
            })
            .catch((error) => {
              this.loading = false;

              // User couldn't sign in (bad verification code?)
              console.log("handle error", error);

              if (error.message) {
                console.log(error.code);
                if (error.code === "auth/invalid-verification-code") {
                  this.presentErrorToast(
                    this.translateService.instant("errorMessages.please_type_the_right_password"),
                    "danger"
                  );
                } else if (error.code === "auth/code-expired") {
                  this.presentErrorToast(
                    this.translateService.instant("errorMessages.code_expired"),
                    "danger"
                  );
                } else {
                  this.presentErrorToast(
                    this.translateService.instant("errorMessages.something_went_wrong_try_again"),
                    "danger"
                  );
                }
              }
            });
        } else if (this.newCustomer.gateway === "sms") {
          console.log("this.newCustomer", this.newCustomer);
          this.writeToAuthStateDebounce("OK", null);
        }
      } else if (
        this.groupApp &&
        this.groupApp.firebaseVerificationEnabled &&
        Capacitor.isNativePlatform()
      ) {
        if (window.firebaseVerificationId) {
          FirebaseAuthentication.confirmVerificationCode({
            verificationId: window.firebaseVerificationId,
            verificationCode: this.newCustomer.password,
          })
            .then(() => {
              console.log("Now get token id");
              FirebaseAuthentication.getIdToken().then((idToken) => {
                // send token to server to authenticate
                if (
                  this.newCustomer &&
                  this.newCustomer.password &&
                  this.newCustomer.password.length &&
                  idToken &&
                  idToken.token
                ) {
                  this.writeToAuthStateDebounce("OK", idToken.token);
                  this.loading = false;
                } else {
                  this.loading = false;
                  console.log("fill the password");
                  //handled by disable ok button
                }
              });
            })
            .catch((error_text) => {
              console.log("firebaseVerificationId Error", error_text);
              this.loading = false;
              this.presentErrorToast(error_text, "danger");
            });
        } else if (this.newCustomer.gateway === "sms") {
          console.log("this.newCustomer", this.newCustomer);
          this.writeToAuthStateDebounce("OK", null);
        } else {
          this.loading = false;
          console.log("window.firebaseVerificationId undefined!");
          this.presentErrorToast(
            this.translateService.instant("errorMessages.something_went_wrong_try_again"),
            "danger"
          );
        }
      }
      console.log("clicked");
    }
  }

  onResend() {
    this.writeToAuthStateDebounce("resend", null);
  }

  onChange() {
    if (
      this.ngOtpInputRef &&
      this.ngOtpInputRef.otpForm &&
      this.ngOtpInputRef.otpForm.value
    ) {
      let password = "";
      for (var ctrl in this.ngOtpInputRef.otpForm.value) {
        if (this.ngOtpInputRef.otpForm.value[ctrl]) {
          password = password + this.ngOtpInputRef.otpForm.value[ctrl];
        }
      }
      this.newCustomer.password = _.cloneDeep(password);
      if (
        this.newCustomer.password &&
        this.newCustomer.password.length &&
        this.newCustomer.password.length === 6
      ) {
        this.codeFull = true;
      } else {
        this.codeFull = false;
      }
      console.log("changed", password);

      this.setModalBreakPointFull("");
    }
  }

  writeToAuthStateDebounce = _.debounce((clickType, firebase_id_token) => {
    this.writeToAuthState(clickType, firebase_id_token);
  }, 100);

  writeToAuthState(clickType, firebase_id_token) {
    if (clickType === "OK") {
      var newCustomer = _.cloneDeep(this.newCustomer);
      newCustomer.firebase_id_token = firebase_id_token;
      console.log("newCustomer", newCustomer);
      this.store.dispatch(new AuthActions.SetNewCustomer(_.cloneDeep(newCustomer)));
      this.modalCtrl.dismiss(this.newCustomer);
    } else if (clickType === "resend") {
      if (!Capacitor.isNativePlatform()) {
        if (this.newCustomer && this.newCustomer.password) {
          this.newCustomer.password = null;
        }
        this.newCustomer.resend = true;
        this.store.dispatch(new AuthActions.ClearNewCustomer());
        this.store.dispatch(new AuthActions.SetNewCustomer(_.cloneDeep(this.newCustomer)));
        this.modalCtrl.dismiss(this.newCustomer);
      } else if (Capacitor.isNativePlatform()) {
        let newCustomer = {
          gateway: "sms",
          mobile: this.newCustomer.mobile,
          createOneTimePass: true,
        };
        console.log("resend sms mobile", newCustomer);
        this.store.dispatch(new AuthActions.ClearNewCustomer());
        this.store.dispatch(new AuthActions.SetNewCustomer(newCustomer));
      }
    }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  async presentAlertSuccessLogin() {
    const alert = await this.alertCtrl.create({
      header: this.translateService.instant("homepage.magic-login"),
      message: this.translateService.instant(
        "popups.magic-login-confirmation.you-are-connect"
      ),
      buttons: [
        {
          text: "OK",
          handler: () => {},
        },
      ],
    });

    await alert.present();
  }

  ngOnDestroy() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscription2) {
      this.subscription2.unsubscribe();
    }
    if (this.subscription3) {
      this.subscription3.unsubscribe();
    }
  }
}
