import { Action } from "@ngrx/store";

export const SET_CUSTOMER_PROMOTIONS =
  "[CUSTOMER PROMOTIONS] Set Customer Promotions";
export const CLEAR_CUSTOMER_PROMOTIONS =
  "[CUSTOMER PROMOTIONS] Clear Customer Promotions";

export class SetCustomerPromotions implements Action {
  readonly type = SET_CUSTOMER_PROMOTIONS;
  constructor(public payload: any) {}
}

export class ClearCustomerPromotions implements Action {
  readonly type = CLEAR_CUSTOMER_PROMOTIONS;
  constructor() {}
}

export type CustomerPromotionsActions =
  | SetCustomerPromotions
  | ClearCustomerPromotions;
