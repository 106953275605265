import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import * as fromApp from "../../store/app.reducer";
import * as selectors from "../../store/app.selectors";
import { Subscription, auditTime, distinctUntilChanged, first } from "rxjs";
import * as SelectedProductActions from "../../product-details/store/selectedProduct.actions";
import { Router } from "@angular/router";
import { Browser } from "@capacitor/browser";
import { AlertController, IonSlides, ModalController } from "@ionic/angular";
import { SelectStorePopupComponent } from "src/app/popups/select-store-popup/select-store-popup.component";
import * as CurrentStoreActions from "../../store/current-store/current-store.actions";
import { TranslateService } from "@ngx-translate/core";
import * as DeliveryMethodAddressActions from "../../store/deliveryMethodAddress/deliveryMethodAddress.actions";
import { Capacitor } from "@capacitor/core";
declare let window: any;

@Component({
  selector: "app-homepage-upper-slider",
  templateUrl: "./homepage-upper-slider.component.html",
  styleUrls: ["./homepage-upper-slider.component.scss"],
})
export class HomepageUpperSliderComponent
  implements OnInit, OnChanges, OnDestroy
{
  @ViewChild("slides", { static: false }) slides: IonSlides;
  @Input() groupApp;
  @Input() windowDimensions;
  @Input() imagesArray;
  @Input() loginState;
  @Input() homepageUpperSliderDir;
  @Input() rootDir;
  activeSlideIndex = 0;
  public customerOrders;
  public kioskCustomerOrders;
  public homeUpperSliderImages;
  private authSubscription: Subscription;

  public upperSliderOptions = {
    allowTouchMove: true,
    slidesPerView: "auto",
    direction: "horizontal",
    slideToClickedSlide: true,
    mousewheel: true,
    freeMode: true,
    freeModeMomentumBounce: false,
    pager: true,
    pagination: true,
    autoplay: true,
    spaceBetween: 10,
    watchSlidesVisibility: true,
  };

  public upperSliderOptionsMobile = {
    allowTouchMove: true,
    slidesPerView: 1,
    direction: "horizontal",
    slideToClickedSlide: true,
    mousewheel: true,
    freeMode: false,
    freeModeMomentumBounce: false,
    pager: true,
    pagination: true,
    autoplay: {
      delay: 2800, // Adjust the delay to your preference (in milliseconds)
      disableOnInteraction: true,
    },
    spaceBetween: 3,
    watchSlidesVisibility: true,
  };

  public upperSliderOptionsMobileLarge = {
    allowTouchMove: true,
    slidesPerView: "auto",
    direction: "horizontal",
    centeredSlides: true,
    autoplay: {
      delay: 2800, // Adjust the delay to your preference (in milliseconds)
      disableOnInteraction: true,
    },
    pager: true,
    pagination: true,
    spaceBetween: 50,
    watchSlidesVisibility: true,
  };

  constructor(
    private store: Store<fromApp.AppState>,
    private router: Router,
    private modalController: ModalController,
    private alertController: AlertController,
    private translate: TranslateService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.groupApp &&
      changes.groupApp.previousValue !== changes.groupApp.currentValue
    ) {
      this.store
        .select("auth")
        .pipe(first())
        .subscribe((state) => {
          this.caclulateHomeUpperSliderImages(
            state.loginState,
            state.customerOrders,
            state.kioskCustomerOrders
          );
        })
        .unsubscribe();
    }

    if (
      changes &&
      changes.loginState &&
      changes.loginState.previousValue !== changes.loginState.currentValue
    ) {
      this.store
        .select("auth")
        .pipe(first())
        .subscribe((state) => {
          this.caclulateHomeUpperSliderImages(
            changes.loginState.currentValue,
            state.customerOrders,
            state.kioskCustomerOrders
          );
        })
        .unsubscribe();
    }
  }

  ngOnInit() {
    this.authSubscription = this.store
      .select("auth")
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (
          state &&
          state.customerOrders &&
          state.kioskCustomerOrders &&
          (!_.isEqual(state.customerOrders, this.customerOrders) ||
            !_.isEqual(state.kioskCustomerOrders, this.kioskCustomerOrders))
        ) {
          this.customerOrders = _.cloneDeep(state.customerOrders);
          this.kioskCustomerOrders = _.cloneDeep(state.kioskCustomerOrders);

          this.caclulateHomeUpperSliderImages(
            _.cloneDeep(state.loginState),
            this.customerOrders,
            this.kioskCustomerOrders
          );
        }
      });
  }

  clickOnUpperSliderImage(image) {
    if (
      image &&
      image.type === "products" &&
      image.storesProducts &&
      !_.isEmpty(image.storesProducts)
    ) {
      this.store
        .select("deliveryMethodAddress")
        .pipe(first())
        .subscribe((deliveryMethodAddressState) => {
          this.store
            .select(selectors.getStoreInfo)
            .pipe(first())
            .subscribe((storeInfo) => {
              this.store
                .select("stores")
                .pipe(first())
                .subscribe(async (state) => {
                  const stores = _.cloneDeep(state.stores);
                  if (
                    stores &&
                    stores.length === 1 &&
                    image.storesProducts[stores[0].store_id]
                  ) {
                    this.store.dispatch(
                      new SelectedProductActions.SetOpenProductDetailsModalFromAppComponent(
                        _.cloneDeep(image.storesProducts[stores[0].store_id])
                      )
                    );
                  } else if (stores && stores.length > 1) {
                    const storesForSelection = [];
                    for (const property in image.storesProducts) {
                      _.each(stores, (store) => {
                        if (store.store_id === property) {
                          store.hasTheOffer = true;
                          storesForSelection.push(_.cloneDeep(store));
                        } else {
                          storesForSelection.push(_.cloneDeep(store));
                        }
                      });
                    }

                    if (
                      storeInfo &&
                      storeInfo.store_id &&
                      _.find(storesForSelection, {
                        store_id: storeInfo.store_id,
                      }) &&
                      image.storesProducts &&
                      image.storesProducts[storeInfo.store_id.toString()] &&
                      deliveryMethodAddressState.orderPickup !== null &&
                      deliveryMethodAddressState.dineIn !== null
                    ) {
                      setTimeout(() => {
                        this.store.dispatch(
                          new SelectedProductActions.SetOpenProductDetailsModalFromAppComponent(
                            _.cloneDeep(
                              image.storesProducts[
                                storeInfo.store_id.toString()
                              ]
                            )
                          )
                        );
                      }, 300);
                    } else {
                      if (storesForSelection && storesForSelection.length) {
                        const selectedStoreId = await this.openSelectStoreModal(
                          storesForSelection
                        );

                        if (
                          selectedStoreId &&
                          storeInfo &&
                          selectedStoreId === storeInfo.store_id &&
                          image.storesProducts[selectedStoreId.toString()]
                        ) {
                          this.store.dispatch(
                            new CurrentStoreActions.SetCurrentStoreId(
                              _.cloneDeep(selectedStoreId.toString())
                            )
                          );

                          setTimeout(() => {
                            this.store.dispatch(
                              new SelectedProductActions.SetOpenProductDetailsModalFromAppComponent(
                                _.cloneDeep(
                                  image.storesProducts[
                                    selectedStoreId.toString()
                                  ]
                                )
                              )
                            );
                          }, 300);
                        } else if (
                          storeInfo &&
                          selectedStoreId &&
                          selectedStoreId !== storeInfo.store_id &&
                          image.storesProducts[selectedStoreId.toString()]
                        ) {
                          this.changeStoreAlert(
                            selectedStoreId,
                            image.storesProducts[selectedStoreId.toString()]
                          );
                        }
                      }
                    }
                  }
                })
                .unsubscribe();
            });
        })
        .unsubscribe();
    } else if (image && image.type === "external" && image.url) {
      this.openLink(image.url);
    } else if (image && image.type === "internal" && image.navigationPage) {
      this.store
        .select("groupApp")
        .pipe(first())
        .subscribe((groupState) => {
          this.store
            .select(selectors.getStoreInfo)
            .pipe(first())
            .subscribe((storeInfo) => {
              if (image.navigationPage === "catalog") {
                this.naviagateToPage(image.navigationPage);
              } else if (image.navigationPage === "cart") {
                this.naviagateToPage(image.navigationPage);
              } else if (
                image.navigationPage === "kiosk" &&
                storeInfo.dm_kiosk_app_active &&
                !(
                  groupState &&
                  groupState.groupApp &&
                  groupState.groupApp.design_settings &&
                  groupState.groupApp.design_settings
                    .dm_kiosk_app_disable_mobileorder
                )
              ) {
                this.naviagateToPage(image.navigationPage);
              } else if (image.navigationPage === "points") {
                this.naviagateToPage(image.navigationPage);
              } else if (image.navigationPage === "discount-coupons") {
                this.naviagateToPage(image.navigationPage);
              } else if (image.navigationPage === "orders") {
                this.naviagateToPage(image.navigationPage);
              } else if (image.navigationPage === "favorites") {
                this.naviagateToPage(image.navigationPage);
              } else if (image.navigationPage === "loyalty-home") {
                this.naviagateToPage(image.navigationPage);
              } else if (image.navigationPage === "reservations") {
                this.naviagateToPage(image.navigationPage);
              } else if (image.navigationPage === "contests") {
                this.naviagateToPage(image.navigationPage);
              } else if (
                image.navigationPage === "affiliate" &&
                groupState &&
                groupState.groupApp.affiliateActive
              ) {
                this.naviagateToPage(image.navigationPage);
              }
            })
            .unsubscribe();
        })
        .unsubscribe();
    }
  }

  async openSelectStoreModal(storesForSelection) {
    return new Promise(async (resolve, reject) => {
      let cssClass = "selectStorePopupCss";

      if (
        storesForSelection &&
        storesForSelection.length > 1 &&
        storesForSelection.length <= 3
      ) {
        cssClass = "selectStorePopupCssThreeStores";
      } else if (storesForSelection && storesForSelection.length > 3) {
        cssClass = "selectStorePopupCssMultipleStores";
      }

      const modal = await this.modalController.create({
        component: SelectStorePopupComponent,
        componentProps: {
          storesForSelection: storesForSelection,
          onlyReturnSelectedStoreId: true,
        },
        cssClass: cssClass,
        backdropDismiss: false,
      });

      await modal.present();

      await modal.onDidDismiss().then((data) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          resolve(null);
        }
      });
    });
  }

  naviagateToPage(page) {
    this.router.navigateByUrl("/" + page, {
      replaceUrl: true,
    });
  }

  openLink = async (link) => {
    //open link external on new page
    if (Capacitor.isNativePlatform()) {
      window.open(link, "_system");
    } else {
      window.open(link, "_blank");
    }
  };

  swiperChange(swiperChange) {
    // this.slides.getActiveIndex().then((index) => {
    //   console.log("swiperChange", index);
    //   _.each(this.homeUpperSliderImages, (img) => {
    //     img.hidden = true;
    //   });
    //   this.homeUpperSliderImages[index].hidden = false;
    //   this.changeDetector.detectChanges();
    // });
  }

  caclulateHomeUpperSliderImages(
    loginState,
    customerOrders,
    kioskCustomerOrders
  ) {
    if (loginState === "no_login" && this.groupApp) {
      this.homeUpperSliderImages = _.cloneDeep(
        this.groupApp.homeUpperSliderImages
      );
    } else if (loginState === "login_completed" && this.groupApp) {
      if (kioskCustomerOrders && kioskCustomerOrders.length) {
        if (!customerOrders || !customerOrders.length) {
          this.homeUpperSliderImages = _.filter(
            _.cloneDeep(this.groupApp.homeUpperSliderImages),
            (img) => {
              return !img.new_kiosk_customers;
            }
          );
        } else {
          this.homeUpperSliderImages = _.filter(
            _.cloneDeep(this.groupApp.homeUpperSliderImages),
            (img) => {
              return !img.new_kiosk_customers && !img.new_customers;
            }
          );
        }
      } else {
        if (customerOrders && customerOrders.length) {
          //TODO ORDERS FOR KIOSK ?????

          this.homeUpperSliderImages = _.filter(
            _.cloneDeep(this.groupApp.homeUpperSliderImages),
            (img) => {
              return !img.new_customers;
            }
          );
        } else {
          this.homeUpperSliderImages = _.cloneDeep(
            this.groupApp.homeUpperSliderImages
          );
        }
      }
    }
  }

  changeStoreAlert(selectedStoreId, productIdToOpen) {
    this.store
      .select("deliveryMethodAddress")
      .pipe(first())
      .subscribe((deliveryAddressState) => {
        this.store
          .select("cart")
          .pipe(first())
          .subscribe(async (state) => {
            if (
              state &&
              state.products &&
              state.products.length > 0 &&
              !deliveryAddressState.selectedAddress
            ) {
              const alert = await this.alertController.create({
                cssClass: "my-custom-class",
                header: this.translate.instant("alert"),
                message: this.translate.instant(
                  "catalog.previous-order.previous-order-modal.if-you-change-store-your-cart-products-will-be-deleted-do-you-want-to-continue"
                ),
                buttons: [
                  {
                    text: this.translate.instant("cancel"),
                    role: "cancel",
                    cssClass: "secondary",
                    handler: (blah) => {},
                  },
                  {
                    text: this.translate.instant("yes"),
                    handler: () => {
                      this.store.dispatch(
                        new CurrentStoreActions.SetCurrentStoreId(
                          _.cloneDeep(selectedStoreId)
                        )
                      );

                      setTimeout(() => {
                        this.store.dispatch(
                          new SelectedProductActions.SetOpenProductDetailsModalFromAppComponent(
                            _.cloneDeep(productIdToOpen)
                          )
                        );
                      }, 300);
                    },
                  },
                ],
              });
              await alert.present();
            } else if (
              state &&
              state.products &&
              state.products.length > 0 &&
              deliveryAddressState.selectedAddress
            ) {
              const alert = await this.alertController.create({
                cssClass: "my-custom-class",
                header: this.translate.instant("alert"),
                message: this.translate.instant(
                  "catalog.previous-order.previous-order-modal.if-you-change-store-your-cart-products-and-your-address-will-be-deleted-do-you-want-to-continue"
                ),
                buttons: [
                  {
                    text: this.translate.instant("cancel"),
                    role: "cancel",
                    cssClass: "secondary",
                    handler: (blah) => {},
                  },
                  {
                    text: this.translate.instant("yes"),
                    handler: () => {
                      this.deleteSelectedAddress();

                      this.store.dispatch(
                        new CurrentStoreActions.SetCurrentStoreId(
                          _.cloneDeep(selectedStoreId)
                        )
                      );
                      setTimeout(() => {
                        this.store.dispatch(
                          new SelectedProductActions.SetOpenProductDetailsModalFromAppComponent(
                            _.cloneDeep(productIdToOpen)
                          )
                        );
                      }, 300);
                    },
                  },
                ],
              });
              await alert.present();
            } else if (
              state &&
              state.products &&
              state.products.length === 0 &&
              deliveryAddressState.selectedAddress
            ) {
              const alert = await this.alertController.create({
                cssClass: "my-custom-class",
                header: this.translate.instant("alert"),
                message: this.translate.instant(
                  "catalog.previous-order.previous-order-modal.if-you-change-store-your-address-will-be-deleted-do-you-want-to-continue"
                ),
                buttons: [
                  {
                    text: this.translate.instant("cancel"),
                    role: "cancel",
                    cssClass: "secondary",
                    handler: (blah) => {},
                  },
                  {
                    text: this.translate.instant("yes"),
                    handler: () => {
                      this.deleteSelectedAddress();

                      this.store.dispatch(
                        new CurrentStoreActions.SetCurrentStoreId(
                          _.cloneDeep(selectedStoreId)
                        )
                      );
                      setTimeout(() => {
                        this.store.dispatch(
                          new SelectedProductActions.SetOpenProductDetailsModalFromAppComponent(
                            _.cloneDeep(productIdToOpen)
                          )
                        );
                      }, 300);
                    },
                  },
                ],
              });
              await alert.present();
            } else if (
              state &&
              (!state.products ||
                (state.products && state.products.length === 0)) &&
              !deliveryAddressState.selectedAddress
            ) {
              this.store.dispatch(
                new CurrentStoreActions.SetCurrentStoreId(
                  _.cloneDeep(selectedStoreId)
                )
              );
              setTimeout(() => {
                this.store.dispatch(
                  new SelectedProductActions.SetOpenProductDetailsModalFromAppComponent(
                    _.cloneDeep(productIdToOpen)
                  )
                );
              }, 300);
            }
          });
      });
  }

  deleteSelectedAddress() {
    this.store.dispatch(new DeliveryMethodAddressActions.setValidStores(null));
    this.store.dispatch(
      new DeliveryMethodAddressActions.setValidDeliveryAreas(null)
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setCheckSelectedAddress(null)
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setSelectedAddress(null)
    );
    this.store.dispatch(new DeliveryMethodAddressActions.setOrderPickup(null));
    this.store.dispatch(new DeliveryMethodAddressActions.setDineIn(null));
    this.store.dispatch(
      new DeliveryMethodAddressActions.setSelectedDineInTable(_.cloneDeep(null))
    );

    if (navigator.cookieEnabled) {
      let deliveryMethodAddressObj: any = localStorage.getItem(
        "deliveryMethodAddress"
      );
      if (deliveryMethodAddressObj) {
        deliveryMethodAddressObj = JSON.parse(deliveryMethodAddressObj);
        deliveryMethodAddressObj.selectedAddress = null;
        deliveryMethodAddressObj.orderPickup = null;
        deliveryMethodAddressObj.dineIn = null;

        localStorage.setItem(
          "deliveryMethodAddress",
          JSON.stringify(deliveryMethodAddressObj)
        );
      }
    }
  }

  ngOnDestroy(): void {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }
}
