<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">
      <ion-text style="font-size: medium">
        {{ "popups.submit-user-data-modal.title" | translate }}</ion-text
      >
    </ion-title>
    <ion-button
      (click)="closeModal()"
      size="small"
      slot="end"
      fill="clear"
      color="dark"
    >
      <ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list *ngIf="currentUser">
    <ion-item
      [color]="
        currentUser && !currentUser.firstName && !valid ? 'notValid' : ''
      "
    >
      <ion-label [position]="valid ? 'floating' : 'stacked'">{{
        "account-settings.name" | translate
      }}</ion-label>
      <ion-input
        [class]="
          currentUser && !currentUser.firstName ? 'ng-invalid' : 'ng-valid'
        "
        [disabled]="customerDataSaveLoading"
        [(ngModel)]="currentUser.firstName"
        name="firstName"
      ></ion-input>
    </ion-item>

    <ion-item
      [color]="currentUser && !currentUser.lastName && !valid ? 'notValid' : ''"
    >
      <ion-label [position]="valid ? 'floating' : 'stacked'">{{
        "account-settings.last-name" | translate
      }}</ion-label>
      <ion-input
        [class]="
          currentUser && !currentUser.lastName ? 'ng-invalid' : 'ng-valid'
        "
        [disabled]="customerDataSaveLoading"
        [(ngModel)]="currentUser.lastName"
        name="lastName"
      ></ion-input>
    </ion-item>

    <ion-item
      [disabled]="customerDataSaveLoading"
      *ngIf="currentUser"
      [color]="currentUser && !currentUser.email && !valid ? 'notValid' : ''"
    >
      <ion-label [position]="valid ? 'floating' : 'stacked'">{{
        "account-settings.email" | translate
      }}</ion-label>
      <ion-input
        #emailInput
        type="email"
        required
        [(ngModel)]="currentUser.email"
      >
        <!-- <ion-button
        *ngIf="!customerDataSaveLoading"
        (click)="saveOrEdit()"
        [color]="!edit ? 'primary' : 'danger'"
        slot="end"
        fill="clear"
        ><ion-icon
          slot="icon-only"
          [src]="!edit ? 'assets/ionicons/create.svg' : 'assets/ionicons/save.svg'"
        ></ion-icon
      ></ion-button> -->
      </ion-input>
    </ion-item>
  </ion-list>
</ion-content>

<ion-footer>
  <ion-grid>
    <ion-row style="width: 100%">
      <ion-col class="ion-text-center">
        <ion-button
          [disabled]="customerDataSaveLoading"
          (click)="updateCustomerDataCustomerInfo()"
        >
          <ion-text *ngIf="!customerDataSaveLoading">
            {{ "save" | translate }}
          </ion-text>

          <ion-spinner *ngIf="customerDataSaveLoading"></ion-spinner>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
