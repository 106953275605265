import { Action } from "@ngrx/store";

export const SET_REVIEWS = "[REVIEWS] Set Reviews";
export const CLEAR_REVIEWS = "[REVIEWS] Clear Reviews";

export class SetReviews implements Action {
  readonly type = SET_REVIEWS;
  constructor(public payload: any) {}
}

export class ClearReviews implements Action {
  readonly type = CLEAR_REVIEWS;
  constructor() {}
}

export type ReviewsActions = SetReviews | ClearReviews;
