import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { filter, map, take } from "rxjs/operators";
import * as fromApp from "../store/app.reducer";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private store: Store<fromApp.AppState>) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
      return this.store.select("auth").pipe(
        map((authState) => {
          return authState.loginState;
        }),
        filter(loginState => loginState !== 'login_loading'),
        map((loginState) => {
          if (loginState === 'login_completed') {
            return true;
          }
          return this.router.createUrlTree(["/homepage"]);
        })
      );
  }
}
