import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import * as _ from "lodash";
import * as fromApp from "../../../../store/app.reducer";
import { Store } from "@ngrx/store";
import { auditTime, distinctUntilChanged } from "rxjs/operators";
import { Subscription } from "rxjs";
import { AlertController, ModalController, Platform } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import * as PointsActions from "../../../../points/store/points.actions";
import * as DisableFinalPriceActions from "../../../../store/disable-final-price/disable-final-price.actions";
import { Router } from "@angular/router";
import { stat } from "fs";
import { AnimationOptions } from "ngx-lottie";

@Component({
  selector: "app-cart-points-rewards-modal",
  templateUrl: "./cart-points-rewards-modal.component.html",
  styleUrls: ["./cart-points-rewards-modal.component.scss"],
})
export class CartPointsRewardsModalComponent implements OnInit {
  @Input() badge_color: any;
  public currentUser: any;
  public groupApp: any;
  public userPoints: any;
  public rewards: any;
  public currentStoreId: any;
  public mo_token: any;
  public usePointsAnimationInstance: any;
  public usePointsAnimation: boolean = false;
  public usePointsLoading: boolean = false;
  public successResponse: any;
  private subscriptions: Subscription[] = [];
  private unsubscribeBackEvent: Subscription;
  usePointsSuccessAnimationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/use-points-success-animation.json",
  };
  constructor(
    private store: Store<fromApp.AppState>,
    private alertController: AlertController,
    private translate: TranslateService,
    private dataStorageService: DataStorageService,
    private modalController: ModalController,
    private platform: Platform
  ) {}

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );
    this.subscriptions.push(
      this.store
        .select("currentStore")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.currentStoreId &&
            !_.isEqual(state.currentStoreId, this.currentStoreId)
          ) {
            this.currentStoreId = _.cloneDeep(state.currentStoreId);
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (state && !_.isEqual(state.mo_token, this.mo_token)) {
            this.mo_token = _.cloneDeep(state.mo_token);
            this.fetchCustomerPoints();
          }
          if (state && !_.isEqual(state.user, this.currentUser)) {
            this.currentUser = _.cloneDeep(state.user);
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(state.groupApp, this.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
            if (
              this.groupApp &&
              this.groupApp.points &&
              this.groupApp.points.rewards
            ) {
              this.groupApp.points.rewards = _.cloneDeep(
                _.filter(this.groupApp.points.rewards, (reward) => {
                  if (reward.active) {
                    return reward;
                  }
                })
              );
            }

            // console.log("group app points", this.groupApp.points);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("points")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((state) => {
          if (
            state &&
            state.userPoints &&
            !_.isEqual(state.userPoints, this.userPoints)
          ) {
            this.userPoints = _.cloneDeep(state.userPoints);
          }
          if (state && !_.isEqual(state.rewards, this.rewards)) {
            let rewards = _.cloneDeep(state.rewards);
            if (rewards && rewards.length) {
              this.rewards = _.cloneDeep(
                _.filter(rewards, (reward) => {
                  if (reward.active) {
                    return reward;
                  }
                })
              );
            }
            console.log("rewards", this.rewards);
            _.each(this.rewards, (reward) => {
              if (reward && reward.user_points_percent) {
                if (reward.user_points_percent < 50) {
                  document.documentElement.style.setProperty(
                    "--pointsProgressBarColor",
                    "var(--ion-color-black)"
                  );
                  document.documentElement.style.setProperty(
                    "--pointsProgressBarColorLight",
                    "var(--pointsProgressBarColorLightBlack)"
                  );
                } else if (
                  reward.user_points_percent >= 50 &&
                  reward.user_points_percent <= 99
                ) {
                  document.documentElement.style.setProperty(
                    "--pointsProgressBarColor",
                    "var(--ion-color-warning)"
                  );
                  document.documentElement.style.setProperty(
                    "--pointsProgressBarColorLight",
                    "var(--pointsProgressBarColorLightWarning)"
                  );
                } else if (reward.user_points_percent === 100) {
                  document.documentElement.style.setProperty(
                    "--pointsProgressBarColor",
                    "var(--ion-color-success)"
                  );
                  document.documentElement.style.setProperty(
                    "--pointsProgressBarColorLight",
                    "var(--pointsProgressBarColorLightSuccess)"
                  );
                }
              }
            });

            //console.log("rewards", this.groupApp.points);
          }
        })
    );
  }

  closeModal() {
    this.modalController.dismiss();
  }

  async confirmUsePoints(reward) {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: this.translate.instant("points.rewards.pointsCapital"),
      message: this.translate.instant(
        "points.rewards.do-you-want-to-cash-out-your-points"
      ),
      buttons: [
        {
          text: this.translate.instant("cancel"),
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel: blah");
          },
        },
        {
          text: this.translate.instant("yes"),
          handler: () => {
            this.usePoints(reward);
          },
        },
      ],
    });

    await alert.present();
  }

  usePoints(reward) {
    this.usePointsLoading = true;
    this.dataStorageService
      .usePoints(
        {
          store_id: this.currentStoreId,
          item: reward,
        },
        this.mo_token
      )
      .subscribe({
        next: async (res: any) => {
          if (!res || !res.success) {
            this.usePointsLoading = false;
            const alert = await this.alertController.create({
              header: this.translate.instant("alert"),
              message:
                res && res.comment_id
                  ? this.translate.instant(res.comment_id)
                  : this.translate.instant("errorMessages.general_error"),
              backdropDismiss: false,
              buttons: ["OK"],
            });
            await alert.present();
          } else {
            this.usePointsLoading = false;
            this.fetchCustomerPoints();
            this.successResponse = res;
            this.usePointsAnimation = true;
            // console.log("user points res", res);
          }
        },
        error: async (err) => {
          this.usePointsLoading = false;
          const alert = await this.alertController.create({
            header: this.translate.instant("alert"),
            message: this.translate.instant(
              "errorMessages.problem_reaching_server"
            ),

            buttons: ["OK"],
          });
          await alert.present();
          return err;

          console.log("error updating the information");
        },
      });
  }

  fetchCustomerPoints() {
    this.dataStorageService
      .fetchCustomerPoint(
        this.mo_token,
        this.groupApp &&
          this.groupApp.points &&
          this.groupApp.points.loyaltyCardLevelsActive
      )
      .subscribe({
        next: async (res: any) => {
          if (res && res.success) {
            let userPoints = res.data;
            this.store.dispatch(
              new PointsActions.SetUserPoints(_.cloneDeep(userPoints))
            );
            let tempGroupApp = _.cloneDeep(this.groupApp);

            _.each(tempGroupApp.points.rewards, (reward) => {
              if (reward.points <= userPoints.pointsSystem.active) {
                reward.user_points_percent = 100;
              } else {
                var percent =
                  (userPoints.pointsSystem.active / reward.points) * 100;
                reward.user_points_percent = percent.toFixed();
              }

              //console.log("reward", reward.user_points_percent);
            });
            this.store.dispatch(
              new PointsActions.SetRewards(
                _.cloneDeep(tempGroupApp.points.rewards)
              )
            );
            let highestReward = _.maxBy(
              tempGroupApp.points.rewards,
              "user_points_percent"
            );
            this.store.dispatch(
              new PointsActions.SetHighestReward(_.cloneDeep(highestReward))
            );
          } else if (!res || !res.success) {
            const alert = await this.alertController.create({
              header: this.translate.instant("alert"),
              message:
                res && res.comment_id
                  ? this.translate.instant(res.comment_id)
                  : this.translate.instant("errorMessages.general_error"),
              backdropDismiss: false,
              buttons: ["OK"],
            });
            await alert.present();
          }
        },
        error: async (error) => {
          const alert = await this.alertController.create({
            header: this.translate.instant("alert"),
            message: this.translate.instant(
              "errorMessages.problem_reaching_server"
            ),

            buttons: ["OK"],
          });
          await alert.present();
        },
      });
  }

  animationCreated(ev) {
    this.usePointsAnimationInstance = ev;
    this.usePointsAnimationInstance.setSpeed(1.3);
  }

  async onAnimationError(ev) {
    if (this.successResponse) {
      const alert = await this.alertController.create({
        header: this.successResponse.comment_id
          ? this.translate.instant(this.successResponse.comment_id)
          : this.translate.instant(this.successResponse.comments),

        backdropDismiss: false,
        buttons: ["OK"],
      });

      this.usePointsAnimation = true;
      await alert.present();
      alert.onDidDismiss().then(() => {
        this.modalController.dismiss();
      });
    }
  }

  async animationComplete(ev) {
    this.usePointsAnimationInstance.stop();
    this.usePointsAnimation = false;
    if (this.successResponse) {
      const alert = await this.alertController.create({
        header: this.successResponse.comment_id
          ? this.translate.instant(this.successResponse.comment_id)
          : this.translate.instant(this.successResponse.comments),

        backdropDismiss: false,
        buttons: ["OK"],
      });

      this.usePointsAnimation = true;
      await alert.present();
      alert.onDidDismiss().then(() => {
        this.modalController.dismiss();
      });
    }
  }

  ngOnDestroy() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.subscriptions && !_.isEmpty(this.subscriptions)) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }
}
