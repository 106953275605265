import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { distinctUntilChanged } from 'rxjs';
import * as fromApp from "../../store/app.reducer";


@Component({
  selector: 'app-edit-profile-image-modal',
  templateUrl: './edit-profile-image-modal.component.html',
  styleUrls: ['./edit-profile-image-modal.component.scss'],
})
export class EditProfileImageModalComponent implements OnInit {
  imageChangeEvent: any;
  subPlatformInfo: any;
  isBrowserPlatform: boolean;

  constructor(
    private modalController: ModalController,
    private store: Store<fromApp.AppState>,
  ) { }

  ngOnInit() {
    this.subPlatformInfo = this.store.select('platformInfo').pipe(distinctUntilChanged()).subscribe(state =>{
      if(state && state.browserPlatform){
        this.isBrowserPlatform = true;
      }
    })
  }

  closeModal(){
    this.modalController.dismiss();
  }

  ngOnDestroy(){
    if(this.subPlatformInfo){
      this.subPlatformInfo.unsubscribe();
    }
  }



}
