import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  AlertController,
  GestureController,
  IonContent,
  IonModal,
  IonSlides,
  MenuController,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { Store, union } from "@ngrx/store";
import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";

import {
  auditTime,
  debounceTime,
  distinctUntilChanged,
  first,
} from "rxjs/operators";
import SwiperCore, {
  Autoplay,
  FreeMode,
  Keyboard,
  Navigation,
  Pagination,
  Swiper,
  Zoom,
} from "swiper";
import { OrderNowPopupComponent } from "../popups/order-now-popup/order-now-popup.component";
import { SelectStorePopupComponent } from "../popups/select-store-popup/select-store-popup.component";
import * as fromApp from "../store/app.reducer";
import * as _ from "lodash";
import { Subscription } from "rxjs";
import * as selectors from "../store/app.selectors";
import { Router } from "@angular/router";
import * as CurrentStoreActions from "../store/current-store/current-store.actions";
import { LoginPopupsServiceService } from "../services/login-popups-service/login-popups-service.service";
import { TranslateService } from "@ngx-translate/core";
import * as AuthActions from "../auth/store/auth.actions";
import { AnalyticsServiceService } from "../services/analitycsService/analytics-service.service";
import { SelectedAddressService } from "../services/selectedAddress/selected-address.service";
import { OrderNowModalPopupsService } from "../services/orderNowModalPopupsService/orderNowModalPopups.service";
import * as DeliveryMethodAddressActions from "../store/deliveryMethodAddress/deliveryMethodAddress.actions";
import * as GroupAppActions from "../store/group-app/groupApp.actions";
import { DataStorageService } from "../services/data-storage/data-storage.service";
import * as storesActions from "../store/stores/stores.actions";
import { AnimationOptions } from "ngx-lottie";
import { InformationMessagesService } from "../services/informationMessages/information-messages.service";
import { InfoMessageModalComponent } from "../popups/info-message-modal/info-message-modal.component";
import moment from "moment";
import { InvoiceListModalComponent } from "../cart/cart-components/invoice-list-modal/invoice-list-modal.component";
import { PreviousOrderModalComponent } from "../catalog/previous-order/previous-order-modal/previous-order-modal.component";
import * as PreviousOrdersActions from "../catalog/previous-order/store/previousOrders.actions";

import { SelectStoreImagePopupComponent } from "../popups/select-store-image-popup/select-store-image-popup.component";
SwiperCore.use([Navigation, Autoplay, Keyboard, Pagination, Zoom, FreeMode]);

declare let window: any;

@Component({
  selector: "app-homepage",
  templateUrl: "./homepage.page.html",
  styleUrls: ["./homepage.page.scss"],
})
export class HomepagePage implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("slider") sliderComponent: IonSlides;
  @ViewChild("previousOrdersSwipeModal") previousOrdersSwipeModal: IonModal;
  @ViewChild("pageContent") pageContent: ElementRef;
  public imagesArray = [];
  public groupApp: any;
  public rootDir: string;
  public webp: boolean;
  public currentUser: any;
  public selectedLogoFileName: string;
  public imageSettings: any;
  public orderPickup: boolean;
  public newCustomer: any;
  public dineIn: boolean;
  public store_info: any;
  public loginLoading: boolean;
  public stores: any;
  public currentStoreId: any;
  public currentStore: any;
  public selectedAddress: any;
  public checkAddressLoading: boolean = false;
  public sideMenuIsOpen: any = true;
  public selectedTheme: any;
  public buttonShape: string; //TO_DO SET THE BUTTON SHAPE TO ROUND OR NOT FOR CHANGING THE SHAPE OF HOMEPAGE BUTTONS
  public previousOrders: any;
  public deliveryManagerPath = "https://images.deliverymanager.gr/";

  public windowDimensions: any;
  public connectButtonSize: any = "medium";
  public showDeliveryButton: boolean;
  public infoShowDeliveryButton;
  public showPickupButton: boolean;
  public showDineInButton: boolean;
  public homePageInfoMessageLink: any;
  public selectedDineInTable: any;
  public cartProducts: any;
  public animationLogin: boolean = false;
  public animationLoginInstance: any;
  public loadingAnimationInstance: any;
  public loadingAnimation: boolean = false;
  public selectedLangugage: any;
  public motoCardsProgressValues = {};
  public mo_base: any;
  public startAnimationMenu: boolean = false;
  public startAnimationLogo: boolean = false;
  public freshLogin: boolean;
  public loginState: string;
  public gesture: any;
  sizeColImageSelectStore = "3";
  homepageUpperSliderDir = "/homeUpperSliderImages/";
  public threeButtonsActive;
  public fourButtonsActive;

  private previousOrderModal: any;
  private selectInvoiceModal: any;
  private customerOrders: any;
  private customerOrdersFiltered: any;
  private browserPlatform: boolean;
  private ErrorToast: any;
  private navigationOnDeliveryMethodChange: any;
  private subscriptions: Subscription[] = [];
  private orderNowModal: any;
  private selectStoreModal: any;
  private infoMessagesModal: any = {};
  private unsubscribeBackEvent: Subscription;
  private checkStoreOnlineAlert: any;
  private sliderInterval: any;

  options: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/success-animation.json",
  };

  public homepageInfoMessagesStore: any = {
    alert: null,
    inline: null,
    modal: null,
  };
  public homepageInfoMessagesGroup: any = {
    alert: null,
    inline: null,
    modal: null,
  };

  public infoMessagesAnimations = {};
  public infoMessagesCardsHide = {};
  public isIos: boolean;
  public swiper: Swiper;
  constructor(
    private modalCtrl: ModalController,
    private store: Store<fromApp.AppState>,
    private menuCtrl: MenuController,
    private router: Router,
    private LoginPopupsService: LoginPopupsServiceService,
    private alertController: AlertController,
    private translateService: TranslateService,
    private toastController: ToastController,
    private analyticsService: AnalyticsServiceService,
    private addressService: SelectedAddressService,
    private platform: Platform,
    private orderNowModalPopupsService: OrderNowModalPopupsService,
    private dataStorageService: DataStorageService,
    private changeDetector: ChangeDetectorRef,
    private infoMessagesService: InformationMessagesService,
    private gestureController: GestureController
  ) {}

  public setSwiperInstance(swiper: SwiperCore) {
    this.swiper = swiper;
  }

  ngAfterViewInit() {
    //slider autoplay
    if (!this.sliderInterval) {
      this.sliderInterval = setInterval(() => {
        if (this.sliderComponent) {
          if (this.sliderComponent.isEnd()) {
            this.sliderComponent.slidePrev();
          } else if (this.sliderComponent.isBeginning()) {
            this.sliderComponent.slideNext();
          }
        }
      }, 4000);
    }
  }

  ionViewDidEnter() {
    console.log("homepage did enter");
    if (this.platform && this.platform.is("ios")) {
      this.isIos = true;
    }

    this.startAnimationLogo = true;
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      100,
      () => {
        if (this.LoginPopupsService.checkMobileMagicLoginIsOpen()) {
          this.LoginPopupsService.closeMobileMagicLoginAlert();
        } else {
          this.appExit();
        }
      }
    );

    this.analyticsService.fbqTrackPageView();
    this.analyticsService.setCurrentScreenFirebase("homepage");
    this.subscriptions.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe(async (state) => {
          if (state && state.stores && !_.isEqual(state.stores, this.stores)) {
            this.stores = _.cloneDeep(state.stores);
            if (
              this.stores &&
              this.windowDimensions &&
              this.groupApp &&
              this.groupApp.images_select_store_mobileorder
            ) {
              this.calculateImagesStoreColSizesDebounced();
            }
            this.showDeliveryButton = !_.isEmpty(
              _.filter(this.stores, (store) => {
                if (!store.disableOrderDelivery) {
                  return store;
                }
              })
            );

            if (!this.showDeliveryButton) {
              this.infoShowDeliveryButton =
                await this.orderNowModalPopupsService.checkToShowInfoDeliveryButton();

              this.infoShowDeliveryButton = !!this.infoShowDeliveryButton;
            }
            console.log("infoShowDeliveryButton", this.infoShowDeliveryButton);

            this.showPickupButton = !_.isEmpty(
              _.filter(this.stores, (store) => {
                if (!store.disableOrderPickup) {
                  return store;
                }
              })
            );
            console.log("pickup button", this.showPickupButton);

            this.showDineInButton = !_.isEmpty(
              _.filter(this.stores, (store) => {
                if (store.dinein) {
                  return store;
                }
              })
            );
            console.log("dine in butto");
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.windowDimensions &&
            !_.isEqual(state.windowDimensions, this.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
            if (
              this.stores &&
              this.windowDimensions &&
              this.groupApp &&
              this.groupApp.images_select_store_mobileorder
            ) {
              this.calculateImagesStoreColSizesDebounced();
            }

            if (
              this.windowDimensions &&
              this.windowDimensions.width &&
              this.windowDimensions.width < 350 &&
              !this.platform.is("ios")
            ) {
              this.connectButtonSize = "small";
            } else if (
              this.windowDimensions &&
              this.windowDimensions.width &&
              this.windowDimensions.width < 460 &&
              this.platform.is("ios")
            ) {
              this.connectButtonSize = "small";
            } else {
              this.connectButtonSize = "medium";
            }
          }

          if (state && !_.isEqual(this.sideMenuIsOpen, state.sideMenuIsOpen)) {
            this.sideMenuIsOpen = _.cloneDeep(state.sideMenuIsOpen);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("currentStore")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((state) => {
          if (
            state &&
            state.currentStoreId &&
            !_.isEqual(this.currentStoreId, state.currentStoreId)
          ) {
            this.currentStoreId = _.cloneDeep(state.currentStoreId);
            this.store
              .select("stores")
              .pipe(first())
              .subscribe((state) => {
                if (state && state.stores) {
                  this.currentStore = _.cloneDeep(
                    _.find(state.stores, {
                      store_id: this.currentStoreId,
                    })
                  );
                }
              })
              .unsubscribe();
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("cart")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && !_.isEqual(state.products, this.cartProducts)) {
            this.cartProducts = _.cloneDeep(state.products);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && !_.isEqual(state.user, this.currentUser)) {
            this.currentUser = _.cloneDeep(state.user);

            if (state.freshLogin) {
              this.animationLogin = true;
              this.store.dispatch(new AuthActions.SetFreshLogin(false));
            }
          }
          if (state && !_.isEqual(state.newCustomer, this.newCustomer)) {
            this.newCustomer = _.cloneDeep(state.newCustomer);
          }
          if (state && !_.isEqual(state.loginLoading, this.loginLoading)) {
            this.loginLoading = _.cloneDeep(state.loginLoading);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("previousOrders")
        .pipe(distinctUntilChanged())
        .pipe(debounceTime(1500))
        .subscribe((state) => {
          if (
            state &&
            state.previousOrders &&
            !_.isEqual(state.previousOrders, this.previousOrders)
          ) {
            const firstTimeGettingOrders =
              !!state.previousOrders.length && !!!this.previousOrders;
            this.previousOrders = _.cloneDeep(state.previousOrders);
            if (
              this.previousOrders &&
              this.previousOrders.length &&
              this.previousOrdersSwipeModal &&
              firstTimeGettingOrders
            ) {
              this.previousOrdersSwipeModal.present();
              //TO-DO Make gesture to work
              //   this.gesture = this.gestureController.create(
              //     {
              //       el: this.pageContent ? this.pageContent.nativeElement : "",
              //       threshold: this.isIos ? 10 : 10,
              //       maxAngle: 50,
              //       gestureName: "swipe-homepage",

              //       onEnd: (ev) => this.onGestureMove(ev),
              //     },
              //     true
              //   );
              //   this.gesture.enable(true);
              // }

              console.log("previous orders", this.previousOrders);
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(200))
        .subscribe((state) => {
          if (
            state &&
            state.loginState &&
            !_.isEqual(state.loginState, this.loginState)
          ) {
            this.loginState = _.cloneDeep(state.loginState);
            if (this.loginState && this.loginState === "no_login") {
              if (this.groupApp && !_.isEmpty(this.groupApp)) {
                this.calculateHomepageInfoMessagesAndStoreOnlineDebounced(
                  _.cloneDeep(this.groupApp),
                  _.cloneDeep(this.customerOrdersFiltered),
                  "no_login"
                );
              } else {
                this.store
                  .select("groupApp")
                  .pipe(first())
                  .subscribe((groupState) => {
                    if (groupState && groupState.groupApp) {
                      this.calculateHomepageInfoMessagesAndStoreOnlineDebounced(
                        _.cloneDeep(groupState.groupApp),
                        _.cloneDeep(this.customerOrdersFiltered),
                        "no_login"
                      );
                    }
                  })
                  .unsubscribe();
              }
            }
          }
          if (state && !_.isEqual(state.customerOrders, this.customerOrders)) {
            this.customerOrders = _.cloneDeep(state.customerOrders);
            this.customerOrdersFiltered = _.cloneDeep(
              _.filter(_.cloneDeep(state.customerOrders) || [], (order) => {
                return order.status === "complete";
              })
            );
            if (
              this.customerOrdersFiltered &&
              state.loginState &&
              state.loginState === "login_completed"
            ) {
              if (this.groupApp && !_.isEmpty(this.groupApp)) {
                this.calculateHomepageInfoMessagesAndStoreOnlineDebounced(
                  _.cloneDeep(this.groupApp),
                  _.cloneDeep(this.customerOrdersFiltered),
                  null
                );
              } else {
                this.store
                  .select("groupApp")
                  .pipe(first())
                  .subscribe((groupState) => {
                    if (groupState && groupState.groupApp) {
                      console.log("login calculation for info messages");
                      this.calculateHomepageInfoMessagesAndStoreOnlineDebounced(
                        _.cloneDeep(groupState.groupApp),
                        _.cloneDeep(this.customerOrdersFiltered),
                        null
                      );
                    }
                  })
                  .unsubscribe();
              }
            }

            console.log("customersOrders", this.customerOrders);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(200))
        .subscribe((state) => {
          if (
            state &&
            state.imageSettings &&
            state.groupApp &&
            !_.isEqual(this.imageSettings, state.imageSettings)
          ) {
            // console.log("inside image settings");
            this.imageSettings = _.cloneDeep(state.imageSettings);
            if (this.browserPlatform && state.groupApp) {
              this.imagesArray = this.findImagesInArray(
                state.groupApp.group + "/home",
                _.cloneDeep(state.groupApp.homeImages)
              );
            }
          }
          if (
            state &&
            state.selectedLangugage &&
            !_.isEqual(this.selectedLangugage, state.selectedLangugage)
          ) {
            // console.log("inside selected lAng");
            this.selectedLangugage = _.cloneDeep(state.selectedLangugage);
          }
          if (
            state &&
            state.rootDir &&
            !_.isEqual(this.rootDir, state.rootDir)
          ) {
            //console.log("inside rootDir");
            this.rootDir = _.cloneDeep(state.rootDir);
          }
          if (
            state &&
            state.groupApp &&
            !_.isEqual(this.groupApp, state.groupApp)
          ) {
            //console.log("inside groupApp", state.groupApp);
            this.groupApp = _.cloneDeep(state.groupApp);

            this.caluclateButtonsActive();

            if (
              this.stores &&
              this.windowDimensions &&
              this.groupApp &&
              this.groupApp.images_select_store_mobileorder
            ) {
              this.calculateImagesStoreColSizesDebounced();
            }

            this.mo_base = _.cloneDeep(
              _.find(this.groupApp.languages, {
                mo_base: true,
              })
            );
            if (!this.mo_base) {
              this.mo_base = "en";
            }

            if (!this.startAnimationMenu) {
              setTimeout(() => {
                this.startAnimationMenu = true;
              }, 50);
            }

            this.imagesArray = this.findImagesInArray(
              this.groupApp.group + "/home",
              _.cloneDeep(this.groupApp.homeImages)
            );
            console.log("images arrray", this.imagesArray);
            if (!_.isEmpty(this.groupApp.logosImages)) {
              this.selectedLogoFileName = _.find(this.groupApp.logosImages, {
                selected: true,
              }).fileName;
            }

            console.log("selected logo file name", this.selectedLogoFileName);

            if (state && state.webp && !_.isEqual(this.webp, state.webp)) {
              this.webp = _.cloneDeep(state.webp);
              if (this.webp && this.selectedLogoFileName) {
                this.selectedLogoFileName = this.selectedLogoFileName.replace(
                  ".png",
                  "512x512.webp"
                );
              }
            }
            if (!this.webp && this.selectedLogoFileName) {
              this.selectedLogoFileName = this.selectedLogoFileName.replace(
                ".png",
                "512x512.png"
              );
            }

            if (
              state &&
              state.selectedTheme &&
              !_.isEqual(this.selectedTheme, state.selectedTheme)
            ) {
              this.selectedTheme = _.cloneDeep(state.selectedTheme);
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("platformInfo")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.browserPlatform, state.browserPlatform)
          ) {
            this.browserPlatform = _.cloneDeep(state.browserPlatform);

            // console.log(this.imagesArray);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("deliveryMethodAddress")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (state && !_.isEqual(state.orderPickup, this.orderPickup)) {
            this.orderPickup = _.cloneDeep(state.orderPickup);
          }
          if (state && !_.isEqual(state.dineIn, this.dineIn)) {
            this.dineIn = _.cloneDeep(state.dineIn);
          }
          if (
            state &&
            state.selectedAddress &&
            !_.isEqual(state.selectedAddress, this.selectedAddress)
          ) {
            this.selectedAddress = _.cloneDeep(state.selectedAddress);
          }
          if (
            state &&
            !_.isEqual(this.selectedDineInTable, state.selectedDineInTable)
          ) {
            this.selectedDineInTable = _.cloneDeep(state.selectedDineInTable);
          }
          if (
            state &&
            !_.isEqual(state.selectedAddressLoading, this.checkAddressLoading)
          ) {
            this.checkAddressLoading = _.cloneDeep(
              state.selectedAddressLoading
            );
          }
          if (
            state &&
            !_.isEqual(
              state.navigationOnDeliveryMethodChange,
              this.navigationOnDeliveryMethodChange
            )
          ) {
            this.navigationOnDeliveryMethodChange = _.cloneDeep(
              state.navigationOnDeliveryMethodChange
            );
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .subscribe((storeInfo) => {
          if (storeInfo && !_.isEqual(storeInfo, this.store_info)) {
            console.log("inside stores sub");
            this.store_info = _.cloneDeep(storeInfo);
          }
        })
    );

    this.analyticsService.dmAnalyticsMessage(
      "home_page_loaded",
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
  }

  playAnimationsFloatingInfoMessagesCard(id) {
    this.infoMessagesAnimations[id] = true;
    this.infoMessagesCardsHide[id] = false;
  }

  CloseAnimationsFloatingInfoMessagesCard(id, dismiss_seconds) {
    const seconds = parseFloat(dismiss_seconds);
    this.motoCardsProgressValues[id] = 0.0;
    let interval = setInterval(() => {
      // console.log("interval called for", id, seconds / 10000000);
      this.motoCardsProgressValues[id] =
        this.motoCardsProgressValues[id] + 1 / (seconds / 180);
    }, 180);
    setTimeout(() => {
      this.infoMessagesAnimations[id] = false;
      setTimeout(() => {
        this.infoMessagesCardsHide[id] = true;
        clearInterval(interval);
      }, 2000);
    }, dismiss_seconds);
  }

  private calculateHomepageInfoMessagesAndStoreOnlineDebounced = _.debounce(
    (groupApp, customerOrders, no_login) => {
      this.calculateHomepageInfoMessagesAndStoreOnline(
        groupApp,
        customerOrders,
        no_login
      );
    },
    500
  );

  checkIfShowInfoMessage(infoMessage) {
    if (navigator.cookieEnabled && !infoMessage.doNotHideOnClose) {
      let infoMessagesClosingArray: any = localStorage.getItem(
        "infoMessagesClosingArray"
      );
      if (infoMessagesClosingArray) {
        infoMessagesClosingArray = JSON.parse(infoMessagesClosingArray);

        let infoMessageObj = _.find(infoMessagesClosingArray, {
          id: infoMessage.id,
        });

        if (infoMessageObj && infoMessageObj.closing_timestamp) {
          if (
            Math.abs(
              moment(
                parseInt(infoMessageObj.closing_timestamp.toString())
              ).diff(moment(), "hours")
            ) < 23
          ) {
            return false;
          }
        }
      }
    }

    return true;
  }

  onGestureMove(ev) {
    console.log("swipe", ev);
  }

  closeInfoCard(id) {
    this.infoMessagesCardsHide[id] = true;
    if (navigator.cookieEnabled) {
      const infoMessageObj = {
        id: id,
        closing_timestamp: Date.now().toString(),
      };
      let infoMessagesClosingArray: any = localStorage.getItem(
        "infoMessagesClosingArray"
      );
      console.log("infoMessagesClosingArray", infoMessagesClosingArray);
      if (infoMessagesClosingArray && infoMessagesClosingArray !== "{}") {
        infoMessagesClosingArray = JSON.parse(infoMessagesClosingArray);
      }
      if (!infoMessagesClosingArray) {
        console.log("!infoMessagesClosingArray", infoMessagesClosingArray);
        infoMessagesClosingArray = [];
        infoMessagesClosingArray.push(infoMessageObj);
      } else {
        const index = _.findIndex(infoMessagesClosingArray, {
          id: id,
        });
        if (index !== -1) {
          infoMessagesClosingArray.splice(index, 1);
          infoMessagesClosingArray.push(infoMessageObj);
        } else {
          infoMessagesClosingArray.push(infoMessageObj);
        }
      }
      localStorage.setItem(
        "infoMessagesClosingArray",
        JSON.stringify(infoMessagesClosingArray)
      );
    }
  }

  calculateHomepageInfoMessagesAndStoreOnline(
    groupApp,
    customerOrders,
    no_login
  ) {
    for (let property in this.infoMessagesCardsHide) {
      this.infoMessagesCardsHide[property] = false;
    }
    this.store
      .select(selectors.getStoreInfo)
      .pipe(first())
      .subscribe((storeInfo) => {
        if (storeInfo) {
          if (groupApp) {
            let homepageInfoMessagesGroup = _.cloneDeep(
              this.infoMessagesService.checkForMobileOrderInfoMessagesGroupApp(
                _.cloneDeep(groupApp),
                _.cloneDeep(storeInfo),
                "home"
              )
            );
            let homepageInfoMessagesStore = _.cloneDeep(
              this.infoMessagesService.checkForMobileOrderInfoMessagesStore(
                _.cloneDeep(storeInfo),
                "home"
              )
            );

            if (!no_login) {
              homepageInfoMessagesGroup = _.cloneDeep(
                _.filter(
                  _.cloneDeep(homepageInfoMessagesGroup),
                  (infoMessage) => {
                    if (infoMessage && !infoMessage.showOnlyToGuests) {
                      return infoMessage;
                    }
                  }
                )
              );

              homepageInfoMessagesStore = _.cloneDeep(
                _.filter(
                  _.cloneDeep(homepageInfoMessagesStore),
                  (infoMessage) => {
                    if (infoMessage && !infoMessage.showOnlyToGuests) {
                      return infoMessage;
                    }
                  }
                )
              );
            }

            if (
              customerOrders &&
              customerOrders.length &&
              customerOrders.length > 0
            ) {
              //fitler the info messages arrays if the message is only for a new customer
              homepageInfoMessagesGroup = _.cloneDeep(
                _.filter(
                  _.cloneDeep(homepageInfoMessagesGroup),
                  (infoMessage) => {
                    if (infoMessage && !infoMessage.showOnlyNewCustomers) {
                      return infoMessage;
                    }
                  }
                )
              );

              homepageInfoMessagesStore = _.cloneDeep(
                _.filter(
                  _.cloneDeep(homepageInfoMessagesStore),
                  (infoMessage) => {
                    if (infoMessage && !infoMessage.showOnlyNewCustomers) {
                      return infoMessage;
                    }
                  }
                )
              );
            }

            //filter info messages if closed recently
            homepageInfoMessagesGroup = _.cloneDeep(
              _.filter(
                _.cloneDeep(homepageInfoMessagesGroup),
                (infoMessage) => {
                  if (infoMessage && this.checkIfShowInfoMessage(infoMessage)) {
                    return infoMessage;
                  }
                }
              )
            );

            homepageInfoMessagesStore = _.cloneDeep(
              _.filter(
                _.cloneDeep(homepageInfoMessagesStore),
                (infoMessage) => {
                  if (infoMessage && this.checkIfShowInfoMessage(infoMessage)) {
                    return infoMessage;
                  }
                }
              )
            );

            this.homepageInfoMessagesGroup.alert = _.cloneDeep(
              _.filter(homepageInfoMessagesGroup, (infoMessage) => {
                if (
                  infoMessage &&
                  infoMessage.view_type &&
                  _.includes(infoMessage.view_type, "alert")
                ) {
                  return infoMessage;
                }
              })
            );
            this.homepageInfoMessagesStore.alert = _.cloneDeep(
              _.filter(homepageInfoMessagesStore, (infoMessage) => {
                if (
                  infoMessage &&
                  infoMessage.view_type &&
                  _.includes(infoMessage.view_type, "alert")
                ) {
                  return infoMessage;
                }
              })
            );

            this.homepageInfoMessagesGroup.inline = _.cloneDeep(
              _.filter(homepageInfoMessagesGroup, (infoMessage) => {
                if (
                  infoMessage &&
                  infoMessage.view_type &&
                  _.includes(infoMessage.view_type, "inline")
                ) {
                  return infoMessage;
                }
              })
            );
            this.homepageInfoMessagesStore.inline = _.cloneDeep(
              _.filter(homepageInfoMessagesStore, (infoMessage) => {
                if (
                  infoMessage &&
                  infoMessage.view_type &&
                  _.includes(infoMessage.view_type, "inline")
                ) {
                  return infoMessage;
                }
              })
            );

            this.homepageInfoMessagesGroup.modal = _.cloneDeep(
              _.filter(homepageInfoMessagesGroup, (infoMessage) => {
                if (
                  infoMessage &&
                  infoMessage.view_type &&
                  _.includes(infoMessage.view_type, "modal")
                ) {
                  return infoMessage;
                }
              })
            );
            this.homepageInfoMessagesStore.modal = _.cloneDeep(
              _.filter(homepageInfoMessagesStore, (infoMessage) => {
                if (
                  infoMessage &&
                  infoMessage.view_type &&
                  _.includes(infoMessage.view_type, "modal")
                ) {
                  return infoMessage;
                }
              })
            );

            _.each(this.homepageInfoMessagesStore.alert, (infoMessage) => {
              this.playAnimationsFloatingInfoMessagesCard(infoMessage.id);
              if (
                infoMessage &&
                infoMessage.auto_dismiss &&
                infoMessage.auto_dismiss_seconds
              ) {
                this.CloseAnimationsFloatingInfoMessagesCard(
                  infoMessage.id,
                  infoMessage.auto_dismiss_seconds * 1000
                );
              }
            });

            _.each(this.homepageInfoMessagesGroup.alert, (infoMessage) => {
              this.playAnimationsFloatingInfoMessagesCard(infoMessage.id);
              if (
                infoMessage &&
                infoMessage.auto_dismiss &&
                infoMessage.auto_dismiss_seconds
              ) {
                this.CloseAnimationsFloatingInfoMessagesCard(
                  infoMessage.id,
                  infoMessage.auto_dismiss_seconds * 1000
                );
              }
            });
            let dont_show_other_messages: any;
            _.each(
              this.homepageInfoMessagesStore.modal,
              (infoMessage, index) => {
                if (infoMessage.dont_show_other_messages) {
                  dont_show_other_messages = true;
                }
                if (
                  infoMessage &&
                  infoMessage.only_browser &&
                  !Capacitor.isNativePlatform()
                ) {
                  this.handleInfoMessagesModal(infoMessage);
                } else if (
                  infoMessage &&
                  infoMessage.only_app &&
                  Capacitor.isNativePlatform()
                ) {
                  this.handleInfoMessagesModal(infoMessage);
                } else if (!infoMessage.only_app && !infoMessage.only_browser) {
                  this.handleInfoMessagesModal(infoMessage);
                }
              }
            );

            _.each(
              this.homepageInfoMessagesGroup.modal,
              (infoMessage, index) => {
                if (infoMessage.dont_show_other_messages) {
                  dont_show_other_messages = true;
                }
                if (
                  infoMessage &&
                  infoMessage.only_browser &&
                  !Capacitor.isNativePlatform()
                ) {
                  this.handleInfoMessagesModal(infoMessage);
                } else if (
                  infoMessage &&
                  infoMessage.only_app &&
                  Capacitor.isNativePlatform()
                ) {
                  this.handleInfoMessagesModal(infoMessage);
                } else if (!infoMessage.only_app && !infoMessage.only_browser) {
                  this.handleInfoMessagesModal(infoMessage);
                }
              }
            );
            if (!dont_show_other_messages) {
              this.checkStoreOnline();
            }
          }
        }
      })
      .unsubscribe();
  }

  async handleInfoMessagesModal(infoMessage) {
    this.store
      .select("groupApp")
      .pipe(first())
      .subscribe(async (state) => {
        if (state && !state.homePageInfoMessagesDisplayed) {
          console.log(
            "this.infoMessagesModal[infoMessage.id]",
            this.infoMessagesModal[infoMessage.id]
          );
          if (this.infoMessagesModal && !_.isEmpty(this.infoMessagesModal)) {
            for (const idProp in this.infoMessagesModal) {
              if (this.infoMessagesModal[idProp]) {
                this.infoMessagesModal[idProp].dismiss();
                this.infoMessagesModal[idProp] = null;
              }
            }
          }

          this.infoMessagesModal[infoMessage.id] = await this.modalCtrl.create({
            component: InfoMessageModalComponent,
            cssClass:
              window && window.innerWidth <= 420
                ? "infoMessagesModalMobile"
                : "infoMessagesModalBrowser",
            componentProps: {
              infoMessage: infoMessage,
            },
          });
          await this.infoMessagesModal[infoMessage.id].present();

          this.infoMessagesModal[infoMessage.id].onDidDismiss().then(() => {
            this.infoMessagesModal[infoMessage.id] = null;
          });

          this.store.dispatch(
            new GroupAppActions.SetHomepageInfoMessagesDisplayed(true)
          );
        }
      })
      .unsubscribe();
  }

  private async appExit() {
    const alert = await this.alertController.create({
      header: this.translateService.instant("alert"),
      message: this.translateService.instant("do-you-want-to-exit-app"),
      buttons: [
        {
          text: this.translateService.instant("cancel"),
          role: "cancel",
          handler: () => {
            console.log("Application exit prevented!");
          },
        },
        {
          text: this.translateService.instant("exit"),
          handler: () => {
            // tslint:disable-next-line: no-string-literal
            if (this.platform.is("android")) {
              window.navigator["app"].exitApp(); // Close this application
            }
          },
        },
      ],
    });
    return await alert.present();
  }

  checkStoreOnline() {
    console.log("checkstoreonline");
    this.store
      .select("groupApp")
      .pipe(first())
      .subscribe((state) => {
        if (state && state.groupApp) {
          let checkStoreOnlineExpiresAt = _.cloneDeep(
            state.checkStoreOnlineExpiresAt
          );
          let checkStoreOnlineExpiresNow = _.cloneDeep(
            state.checkStoreOnlineExpiresNow
          );
          this.store
            .select(selectors.getStoreInfo)
            .pipe(first())
            .subscribe((store_info) => {
              if (store_info) {
                console.log(
                  "checkstoreOnline",
                  state.groupApp,
                  this.store_info
                );
                var checkStoreOnlineParams: any = {
                  languageCode: state.selectedLangugage || "el",
                };

                if (store_info.store_id && state.groupApp.group) {
                  checkStoreOnlineParams.store_id = _.cloneDeep(
                    store_info.store_id
                  );
                  checkStoreOnlineParams.group = _.cloneDeep(
                    state.groupApp.group
                  );
                }
                //Here I need to check every 5 minutes again instead of only checking once.
                var currentTime = Date.now();
                console.log(
                  "checkStoreOnline",
                  checkStoreOnlineExpiresAt,
                  checkStoreOnlineExpiresNow
                );
                if (
                  (checkStoreOnlineExpiresAt &&
                    checkStoreOnlineExpiresAt < currentTime) ||
                  currentTime - 5 * 60 * 1000 > checkStoreOnlineExpiresNow
                ) {
                  this.dataStorageService
                    .checkStoreOnline(checkStoreOnlineParams)
                    .subscribe({
                      next: async (res: any) => {
                        checkStoreOnlineExpiresNow = Date.now();
                        this.store.dispatch(
                          new GroupAppActions.SetCheckStoreOnlineExpiresNow(
                            _.cloneDeep(checkStoreOnlineExpiresNow)
                          )
                        );
                        this.store.dispatch(
                          new storesActions.SetCheckStoreInfo(_.cloneDeep(res))
                        );

                        this.store
                          .select("deliveryMethodAddress")
                          .pipe(first())
                          .subscribe(async (state) => {
                            if (state) {
                              if (
                                this.checkIfIShouldShowSchedulePopup(
                                  res,
                                  state.orderPickup
                                )
                              ) {
                                checkStoreOnlineExpiresAt = res.expires_at;
                                this.store.dispatch(
                                  new GroupAppActions.SetCheckStoreOnlineExpiresAt(
                                    _.cloneDeep(checkStoreOnlineExpiresAt)
                                  )
                                );
                                if (!this.checkStoreOnlineAlert) {
                                  this.openCheckStoreOnlineAlertDebounced(res);
                                }
                              }
                            }
                          })
                          .unsubscribe();
                      },
                      error: (err) => {
                        checkStoreOnlineExpiresAt = null;
                        checkStoreOnlineExpiresNow = null;
                        this.store.dispatch(
                          new GroupAppActions.SetCheckStoreOnlineExpiresAt(
                            _.cloneDeep(checkStoreOnlineExpiresAt)
                          )
                        );
                        this.store.dispatch(
                          new GroupAppActions.SetCheckStoreOnlineExpiresAt(
                            _.cloneDeep(checkStoreOnlineExpiresNow)
                          )
                        );
                        console.log(err);
                      },
                    });
                }
              }
            });
        }
      })
      .unsubscribe();
  }

  private calculateImagesStoreColSizesDebounced = _.debounce(() => {
    this.calculateImagesStoreColSizes();
  }, 500);

  calculateImagesStoreColSizes() {
    if (this.stores && this.stores.length && this.stores.length === 2) {
      if (this.windowDimensions.width >= 1250) {
        this.sizeColImageSelectStore = "3";
      } else if (
        this.windowDimensions.width < 1250 &&
        this.windowDimensions.width > 950
      ) {
        this.sizeColImageSelectStore = "4";
      } else {
        this.sizeColImageSelectStore = "6";
      }
    } else if (this.stores && this.stores.length && this.stores.length === 3) {
    }
  }

  async openCheckStoreOnlineAlert(res) {
    this.checkStoreOnlineAlert = await this.alertController.create({
      cssClass: "checkStoreOnlineAlert",
      header: this.translateService.instant("alert"),
      message: res.schedule_comments.split("\\n").join("<br>"),
      buttons: ["OK"],
    });

    await this.checkStoreOnlineAlert.present();

    await this.checkStoreOnlineAlert.onDidDismiss().then(() => {
      this.checkStoreOnlineAlert = null;
    });
  }

  private openCheckStoreOnlineAlertDebounced = _.debounce((res) => {
    this.openCheckStoreOnlineAlert(res);
  }, 1000);

  // loadingAnimationCreated(ev) {
  //   this.loadingAnimationInstance = ev;
  //   this.loadingAnimationInstance.setSpeed(1);
  // }

  animationCreated(ev) {
    this.animationLoginInstance = ev;
    this.animationLoginInstance.setSpeed(0.8);
  }

  animationComplete(ev) {
    this.animationLoginInstance.stop();
    this.animationLogin = false;
    this.changeDetector.detectChanges();
  }

  checkIfIShouldShowSchedulePopup(response, orderPickup) {
    if (
      response.__reason === "stopStoreActive" ||
      response.__reason === "offSchedule"
    ) {
      console.log(
        "Store is not online based on the period or the schedule of both pickup and delivery"
      );
      return true;

      //This is the case where we need to check if the user selected pickup or not so that I can show the popup if needed
    } else if (
      (!response.delivery_status && orderPickup == false) ||
      (!response.pickup_status && orderPickup)
    ) {
      return true;
    } else {
      return false;
    }

    //This is the case that we need to overide the delivery_status and pickup_status as period overrules/
  }

  ngOnInit() {
    console.log("on init homepage");
  }

  newOrderWithMigrationCustomerId() {
    if (
      this.currentUser &&
      this.currentUser.invoiceData &&
      this.currentUser.invoiceData.length > 1
    ) {
      let invoices_with_migration_customer_id = 0;
      _.each(this.currentUser.invoiceData, (invoice) => {
        if (invoice.migration_customer_id) {
          invoices_with_migration_customer_id =
            invoices_with_migration_customer_id + 1;
        }
      });
      if (invoices_with_migration_customer_id > 1) {
        this.presentSelectInvoiceModal(true);
      } else if (invoices_with_migration_customer_id === 1) {
        this.presentOrderNowModal();
      } else {
        console.log("error: no invoices with migration customer id");
      }
    } else {
      this.presentOrderNowModal();
    }
  }

  openExternalLink = async (link) => {
    if (link && link !== "") {
      window.open(link, "_system");
    }
  };

  naviagateToPage(page) {
    this.router.navigateByUrl("/" + page, {
      replaceUrl: true,
    });
  }

  openLink = async (link) => {
    //open link external on new page
    if (Capacitor.isNativePlatform()) {
      window.open(link, "_system");
    } else {
      window.open(link, "_blank");
    }
  };

  async presentOrderNowModal() {
    this.store.dispatch(
      new DeliveryMethodAddressActions.SetNavigationOnDeliveryMethodChanged(
        true
      )
    );
    if (!this.showPickupButton && !this.showDineInButton) {
      this.orderNowModalPopupsService.checkAndOpenSettingsModal(
        "delivery",
        this.currentUser,
        this.selectedAddress,
        this.stores,
        this.currentStoreId,
        true,
        false
      );
    } else if (
      !this.showPickupButton &&
      !this.showDeliveryButton &&
      !this.infoShowDeliveryButton
    ) {
      this.orderNowModalPopupsService.checkAndOpenSettingsModal(
        "dine-in",
        this.currentUser,
        this.selectedAddress,
        this.stores,
        this.currentStoreId,
        true,
        false
      );
    } else if (
      !this.showDineInButton &&
      !this.showDeliveryButton &&
      !this.infoShowDeliveryButton
    ) {
      this.orderNowModalPopupsService.checkAndOpenSettingsModal(
        "take-away",
        this.currentUser,
        this.selectedAddress,
        this.stores,
        this.currentStoreId,
        true,
        false
      );
    } else {
      this.orderNowModal = await this.modalCtrl.create({
        component: OrderNowPopupComponent,
        cssClass: "orderNowPopupCss",
        backdropDismiss: true,
      });

      await this.orderNowModal.present();
      this.orderNowModal.onDidDismiss().then(() => {
        this.orderNowModal = null;
      });
    }
  }

  async presentMagicLoginPopup() {
    this.analyticsService.dmAnalyticsMessage(
      "login_button",
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );

    // this.MagicLoginservice.presentLoginModal(this.newCustomer);
    if (!Capacitor.isNativePlatform()) {
      console.log("before presentFirebaseModalLogin");
      this.LoginPopupsService.presentFirebaseModalLogin(null, null);
    } else {
      console.log("before closeMobileMagicLoginAlert");
      this.LoginPopupsService.closeMobileMagicLoginAlert();
      this.LoginPopupsService.presentMobileMagicLoginAlert(
        this.newCustomer,
        this.groupApp
      );
    }
  }

  // async showComingSoonToast() {
  //   const toast = await this.toastController.create({
  //     message: this.translateService.instant("coming-soon"),
  //     duration: 3000,
  //     position: "middle",

  //     color: "primary",
  //     buttons: [
  //       {
  //         side: "end",
  //         icon: "assets/ionicons/close.svg",

  //         handler: () => {
  //           console.log("Toast Closed");
  //         },
  //       },
  //     ],
  //   });
  //   await toast.present();
  // }

  goToReservationsPage() {
    this.router.navigateByUrl("/reservations/new-reservation", {
      replaceUrl: true,
    });
  }

  async presentErrorToast(message, color) {
    this.ErrorToast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
    });
    this.ErrorToast.present();
  }

  async presentSelectStoreModal() {
    if (this.groupApp && this.groupApp.images_select_store_mobileorder) {
      this.selectStoreModal = await this.modalCtrl.create({
        component: SelectStoreImagePopupComponent,
        cssClass: "selectStoreImagePopup",
        backdropDismiss: true,
      });

      await this.selectStoreModal.present();
      this.selectStoreModal.onDidDismiss().then(() => {
        this.selectStoreModal = null;
      });
    } else {
      let cssClass = "selectStorePopupCss";
      if (this.stores && this.stores.length > 1 && this.stores.length <= 3) {
        cssClass = "selectStorePopupCssThreeStores";
      } else if (this.stores && this.stores.length > 3) {
        cssClass = "selectStorePopupCssMultipleStores";
      }
      this.selectStoreModal = await this.modalCtrl.create({
        component: SelectStorePopupComponent,
        cssClass: cssClass,
        backdropDismiss: false,
      });

      await this.selectStoreModal.present();
      this.selectStoreModal.onDidDismiss().then(() => {
        this.selectStoreModal = null;
      });
    }
  }

  findImagesInArray(directory, images) {
    var newImages = _.filter(images, (image) => {
      return this.findImageObject(image);
    });

    _.each(newImages, (image, key, list) => {
      if (!_.isObject(image.settings) && image.fileName) {
        newImages[key].path = this.rootDir + directory + "/" + image.fileName;
      } else {
        newImages[key].path =
          this.rootDir +
          directory +
          "/" +
          this.imageSettings.imageRatio +
          "/" +
          image.fileName;
      }
    });

    //Replacing all the images with webp
    if (this.webp) {
      _.each(newImages, function (img, key, list) {
        newImages[key].path = newImages[key].path
          .replace(".jpg", ".webp")
          .replace(".png", ".webp");
      });
    }

    return newImages;
  }

  findImageObject(image) {
    if (
      this.imageSettings &&
      image.settings &&
      ((this.browserPlatform &&
        image.settings[this.imageSettings.imageRatio].tinified &&
        image.settings[this.imageSettings.imageRatio].browser) ||
        (!this.browserPlatform &&
          image.settings[this.imageSettings.imageRatio].tinified &&
          image.settings[this.imageSettings.imageRatio].mobile))
    ) {
      return true;
    } else if (!_.isObject(image.settings) && image.fileName) {
      return true;
    } else {
      return false;
    }
  }

  checkTheCatalog() {
    if (
      this.stores &&
      this.stores.length > 1 &&
      this.orderPickup == null &&
      this.dineIn == null
    ) {
      this.presentSelectStoreModal();
    } else {
      // this.store.dispatch(
      //   new CurrentStoreActions.SetCurrentStoreId(this.stores[0].store_id)
      // );
      // if (navigator.cookieEnabled) {
      //   window.localStorage.setItem("store_id", this.currentStoreId);
      // }

      this.router.navigateByUrl("/catalog", {
        replaceUrl: true,
      });
    }
  }

  changeStore(store_id) {
    this.store
      .select("cart")
      .pipe(first())
      .subscribe(async (state) => {
        if (
          state &&
          state.products &&
          state.products.length > 0 &&
          store_id !== this.currentStoreId
        ) {
          const alert = await this.alertController.create({
            cssClass: "my-custom-class",
            header: this.translateService.instant("alert"),
            message: this.translateService.instant(
              "catalog.if-you-change-store-your-cart-products-will-be-deleted-do-you-want-to-continue"
            ),
            buttons: [
              {
                text: this.translateService.instant("cancel"),
                role: "cancel",
                cssClass: "secondary",
                handler: (blah) => {},
              },
              {
                text: this.translateService.instant("yes"),
                handler: () => {
                  this.store.dispatch(
                    new CurrentStoreActions.SetCurrentStoreId(
                      _.cloneDeep(store_id)
                    )
                  );

                  this.navigateToCatalog();
                },
              },
            ],
          });
          await alert.present();
        } else if (
          state &&
          (!state.products ||
            (state.products && state.products.length === 0)) &&
          store_id !== this.currentStoreId
        ) {
          this.store.dispatch(
            new CurrentStoreActions.SetCurrentStoreId(_.cloneDeep(store_id))
          );

          this.navigateToCatalog();
        } else if (store_id === this.currentStoreId) {
          this.navigateToCatalog();
        }
      });
  }

  navigateToCatalog() {
    //need timeout in order for user to see the transaction
    setTimeout(() => {
      this.router.navigateByUrl("/catalog", {
        replaceUrl: true,
      });
    }, 500);
  }

  deleteSelectedAddress() {
    this.store.dispatch(new DeliveryMethodAddressActions.setValidStores(null));
    this.store.dispatch(
      new DeliveryMethodAddressActions.setValidDeliveryAreas(null)
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setCheckSelectedAddress(null)
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setSelectedAddress(null)
    );
    this.store.dispatch(new DeliveryMethodAddressActions.setOrderPickup(null));
    this.store.dispatch(new DeliveryMethodAddressActions.setDineIn(null));
    this.store.dispatch(
      new DeliveryMethodAddressActions.setSelectedDineInTable(_.cloneDeep(null))
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setValidDeliveryAreas(null)
    );

    if (navigator.cookieEnabled) {
      let deliveryMethodAddressObj: any = localStorage.getItem(
        "deliveryMethodAddress"
      );
      if (deliveryMethodAddressObj) {
        deliveryMethodAddressObj = JSON.parse(deliveryMethodAddressObj);
        deliveryMethodAddressObj.selectedAddress = null;
        deliveryMethodAddressObj.orderPickup = null;
        deliveryMethodAddressObj.dineIn = null;

        localStorage.setItem(
          "deliveryMethodAddress",
          JSON.stringify(deliveryMethodAddressObj)
        );
      }
    }
  }

  checkMigrationCustomerIdCatalog() {
    if (this.orderPickup == null && this.dineIn == null) {
      if (
        this.currentUser &&
        this.currentUser.invoiceData &&
        this.currentUser.invoiceData.length > 1
      ) {
        let invoices_with_migration_customer_id = 0;
        _.each(this.currentUser.invoiceData, (invoice) => {
          if (invoice.migration_customer_id) {
            invoices_with_migration_customer_id =
              invoices_with_migration_customer_id + 1;
          }
        });
        if (invoices_with_migration_customer_id > 1) {
          this.presentSelectInvoiceModal(false);
        } else if (invoices_with_migration_customer_id === 1) {
          this.router.navigateByUrl("/catalog", {
            replaceUrl: true,
          });
        } else {
          console.log("error: no invoices with migration customer id");
        }
      } else {
        this.checkTheCatalog();
      }
    } else {
      this.checkTheCatalog();
    }
  }

  async presentSelectInvoiceModal(newOrderMode) {
    this.selectInvoiceModal = await this.modalCtrl.create({
      component: InvoiceListModalComponent,
      cssClass: "invoiceListModal",
      backdropDismiss: false,
    });

    await this.selectInvoiceModal.present();
    this.selectInvoiceModal.onDidDismiss().then((data) => {
      console.log("on dismiss", data);

      if (data && data.data) {
        if (
          this.stores &&
          this.stores.length > 1 &&
          this.orderPickup == null &&
          this.dineIn == null
        ) {
          if (newOrderMode) {
            this.presentOrderNowModal();
          } else {
            this.presentSelectStoreModal();
          }
        } else {
          if (newOrderMode) {
            this.presentOrderNowModal();
          } else {
            this.router.navigateByUrl("/catalog", {
              replaceUrl: true,
            });
          }
        }
      }

      this.selectInvoiceModal = null;
    });
  }

  showPreviousOrderModal(selectedOrder) {
    this.store.dispatch(
      new PreviousOrdersActions.SetPreviousOrderCart(
        _.cloneDeep(selectedOrder.products)
      )
    );

    this.presentPreviousOrderModal(selectedOrder.order_id);
  }

  async presentPreviousOrderModal(order_id) {
    this.previousOrderModal = await this.modalCtrl.create({
      component: PreviousOrderModalComponent,
      cssClass: "previousOrderModal",
      animated: true,
      componentProps: { previousOrderId: order_id },
      backdropDismiss: false,
    });

    await this.previousOrderModal.present();
    this.previousOrderModal.onDidDismiss().then(async (data) => {
      this.previousOrderModal = null;
      //console.log("xcxcasdkssjdfnvnv", data);
      if (data && data.data === "added_to_cart") {
        const alert = await this.alertController.create({
          cssClass: "my-custom-class",
          header: this.translateService.instant("success"),
          message: this.translateService.instant(
            "catalog.previous-order.previous-order-modal.products-from-previous-order-added-to-cart"
          ),
          buttons: [
            {
              text: this.translateService.instant("cancel"),
              role: "cancel",
              cssClass: "secondary",
              handler: (blah) => {},
            },
            {
              text: this.translateService.instant(
                "catalog.previous-order.previous-order-modal.navigate-to-cart"
              ),
              cssClass: "secondary",
              handler: (blah) => {
                this.router.navigateByUrl("/cart", {
                  replaceUrl: true,
                });
              },
            },
            {
              text: this.translateService.instant(
                "catalog.previous-order.previous-order-modal.navigate-to-catalog"
              ),
              handler: () => {
                this.router.navigateByUrl("/catalog", {
                  replaceUrl: true,
                });
              },
            },
          ],
        });
        await alert.present();
      }
    });
  }

  openSideMenu() {
    this.menuCtrl.open();
  }

  caluclateButtonsActive() {
    this.fourButtonsActive = false;
    this.threeButtonsActive = false;
    if (
      this.groupApp &&
      this.groupApp.design_settings &&
      this.groupApp.design_settings.customHomepageButtons &&
      this.groupApp.design_settings.customHomepageButtons.active &&
      this.groupApp.design_settings.customHomepageButtons.button_one &&
      this.groupApp.design_settings.customHomepageButtons.button_one.active &&
      !(
        this.groupApp.design_settings.customHomepageButtons.button_two &&
        this.groupApp.design_settings.customHomepageButtons.button_two.active
      )
    ) {
      this.fourButtonsActive = false;
      this.threeButtonsActive = true;
    } else if (
      this.groupApp &&
      this.groupApp.design_settings &&
      this.groupApp.design_settings.customHomepageButtons &&
      this.groupApp.design_settings.customHomepageButtons.active &&
      this.groupApp.design_settings.customHomepageButtons.button_two &&
      this.groupApp.design_settings.customHomepageButtons.button_two.active &&
      !(
        this.groupApp.design_settings.customHomepageButtons.button_one &&
        this.groupApp.design_settings.customHomepageButtons.button_one.active
      )
    ) {
      this.fourButtonsActive = false;
      this.threeButtonsActive = true;
    } else if (
      this.groupApp &&
      this.groupApp.design_settings &&
      this.groupApp.design_settings.customHomepageButtons &&
      this.groupApp.design_settings.customHomepageButtons.active &&
      this.groupApp.design_settings.customHomepageButtons.button_two &&
      this.groupApp.design_settings.customHomepageButtons.button_two.active &&
      this.groupApp.design_settings.customHomepageButtons.button_one &&
      this.groupApp.design_settings.customHomepageButtons.button_one.active
    ) {
      this.fourButtonsActive = true;
      this.threeButtonsActive = false;
    }
  }

  ngOnDestroy() {
    // console.log("on destroy homepagessss");
    if (this.sliderInterval) {
      clearInterval(this.sliderInterval);
      this.sliderInterval = null;
    }
  }

  ionViewWillLeave() {
    if (this.sliderInterval) {
      clearInterval(this.sliderInterval);
      this.sliderInterval = null;
    }
    if (this.infoMessagesModal && !_.isEmpty(this.infoMessagesModal)) {
      for (const idProp in this.infoMessagesModal) {
        if (this.infoMessagesModal[idProp]) {
          this.infoMessagesModal[idProp].dismiss();
          this.infoMessagesModal[idProp] = null;
        }
      }
    }
    if (this.selectInvoiceModal) {
      this.selectInvoiceModal.dismiss();
      this.selectInvoiceModal = null;
    }
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    this.subscriptions = [];
    this.unsubscribeBackEvent.unsubscribe();
    this.LoginPopupsService.closeFirebaseModalLogin();
    this.LoginPopupsService.closeConfirmationLoginModal();
    this.LoginPopupsService.closeMobileMagicLoginAlert();
    this.addressService.closeAddressModals();
    this.orderNowModalPopupsService.closeFirebaseRecaptchaModal();
    this.orderNowModalPopupsService.closeNewAddressModal();
    this.orderNowModalPopupsService.closeSettingsModal();
    //console.log("on destroy homepage");
    if (this.orderNowModal) {
      this.orderNowModal.dismiss();
      this.orderNowModal = null;
    }
    if (this.selectStoreModal) {
      this.selectStoreModal.dismiss();
      this.selectStoreModal = null;
    }
    if (this.previousOrderModal) {
      this.previousOrderModal.dismiss();
      this.previousOrderModal = null;
    }
    if (this.previousOrdersSwipeModal) {
      this.previousOrdersSwipeModal.dismiss();
      this.previousOrdersSwipeModal = null;
    }
    this.imagesArray = null;
    this.groupApp = null;
    this.rootDir = null;
    this.webp = null;
    this.currentUser = null;
    this.browserPlatform = null;
    this.selectedLogoFileName = null;
    this.imageSettings = null;
    this.orderPickup = null;
    this.store_info = null;
    this.loginLoading = null;
    this.selectedAddress = null;
  }
}
