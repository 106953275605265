import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import * as _ from "lodash";
import * as fromApp from "../../store/app.reducer";
import { Store } from "@ngrx/store";
import { auditTime, distinctUntilChanged } from "rxjs/operators";
import { Subscription } from "rxjs";
import { LoginPopupsServiceService } from "src/app/services/login-popups-service/login-popups-service.service";
import { AnalyticsServiceService } from "src/app/services/analitycsService/analytics-service.service";
import { AnimationOptions } from "ngx-lottie";
import { Capacitor } from "@capacitor/core";

declare let window: any;

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit, OnDestroy {
  public currentUser: any;
  public groupApp: any;
  @Input() userPoints: any;
  @Input() doNotShowTitleDivider: boolean;
  public newCustomer: any;
  public loginLoading: any;
  public profileAnimation: boolean;
  private selectedTheme: any;
  private subscriptions: Subscription[] = [];

  profileAnimationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/profile-animation.json",
  };

  constructor(
    private store: Store<fromApp.AppState>,
    private LoginPopupsService: LoginPopupsServiceService,
    private analyticsService: AnalyticsServiceService
  ) {}

  ngOnInit() {
    this.profileAnimation = true;
    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (state && state.user && !_.isEqual(state.user, this.currentUser)) {
            this.currentUser = _.cloneDeep(state.user);
          }
          if (state && !_.isEqual(state.newCustomer, this.newCustomer)) {
            this.newCustomer = _.cloneDeep(state.newCustomer);
          }
          if (state && !_.isEqual(state.loginLoading, this.loginLoading)) {
            this.loginLoading = _.cloneDeep(state.loginLoading);
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(state.groupApp, this.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
          }
          if (
            state &&
            state.selectedTheme &&
            !_.isEqual(state.selectedTheme, this.selectedTheme)
          ) {
            this.selectedTheme = _.cloneDeep(state.selectedTheme);
            if (this.selectedTheme && this.selectedTheme === "dark") {
              this.profileAnimationOptions = {
                ...this.profileAnimationOptions,
                path: "https://data.deliverymanager.gr/animations/profile-animation-dark.json",
              };
            } else if (this.selectedTheme && this.selectedTheme === "light") {
              this.profileAnimationOptions = {
                ...this.profileAnimationOptions,
                path: "https://data.deliverymanager.gr/animations/profile-animation.json",
              };
            }
          }
        })
    );
  }

  async presentMagicLoginPopup() {
    this.analyticsService.dmAnalyticsMessage(
      "login_button",
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
    // this.MagicLoginservice.presentLoginModal(this.newCustomer);
    if (!Capacitor.isNativePlatform()) {
      this.LoginPopupsService.presentFirebaseModalLogin(null, null);
    } else {
      this.LoginPopupsService.closeMobileMagicLoginAlert();
      this.LoginPopupsService.presentMobileMagicLoginAlert(
        this.newCustomer,
        this.groupApp
      );
    }
  }

  ngOnDestroy() {
    if (this.subscriptions && !_.isEmpty(this.subscriptions)) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }
}
