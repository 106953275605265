<ion-header class="ion-no-border">
  <ion-toolbar >
    <ion-buttons slot="end">
      <ion-button color="dark" fill="clear" (click)="closeModal()">
        <ion-icon src="assets/ionicons/close.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <ion-text >{{'loyaltySystem.select-store' | translate}}</ion-text>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- <ion-list> -->
    <ion-item-divider mode="ios" *ngIf="stores.length > 1">
      <ion-label>
        {{'loyaltySystem.available-stores' | translate}}
      </ion-label>
    </ion-item-divider>
    <ion-item lines="full" *ngIf="stores.length > 1">
      <ion-label>
        {{'loyaltySystem.store' | translate}}: 
      </ion-label>
      <ion-select 
        placeholder="Select store"
        (ionChange)="selectStore()"
        [(ngModel)]="selectedStoreId"
      >
        <ion-select-option *ngFor="let store of stores" [value]="store.store_id">{{store.store_name}}</ion-select-option>
      </ion-select>
    </ion-item>
  <!-- </ion-list> -->
  <ion-item-divider mode="ios">
    <ion-label>
      {{'loyaltySystem.loyalty-schedule' | translate}}
    </ion-label>
  </ion-item-divider>
  <ion-grid *ngIf="selectedStoreSchedule">
    <ion-row *ngFor="let schedule of selectedStoreSchedule">
      <ion-col size="6" >
        <ion-text>
          {{schedule.day}}
        </ion-text>
      </ion-col>
      <ion-col size="6">
        <ion-text>
          {{schedule.schedule}}
        </ion-text>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid *ngIf="!selectedStoreSchedule">
    <ion-row>
      <ion-col class="ion-text-center">
        <ion-text style="color: red;">
          Δεν έχει οριστεί ωράριο!
        </ion-text>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-item-divider mode="ios">
    <ion-label>
      {{'loyaltySystem.stores-locations' | translate}}
    </ion-label>
  </ion-item-divider>
  <div 
    [hidden]="!libraryLoaded"
    id="map"
    style="width: 100%; height: 250px;"
    data-tap-disabled="true"
  ></div>
  <ion-fab
  *ngIf="stores.length > 1"
  [hidden]="!mobilePlatform"
  vertical="bottom"
  horizontal="start"
  slot="fixed"
  >
  <ion-fab-button (click)="checkLocationFromGps()">
    <ion-icon src="assets/flatIcons/gps.svg"></ion-icon>
  </ion-fab-button>
</ion-fab>
</ion-content>

<ion-footer>
  <ion-grid>
    <ion-row style="width: 100%">
      <ion-col size="12" class="ion-text-center">
        <ion-button
          (click)="onSave()"
        >
          OK
          <!-- <ion-spinner *ngIf="updatingDataLoading"></ion-spinner> -->
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>