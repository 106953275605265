import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import * as fromApp from "../store/app.reducer";
import * as _ from "lodash";

@Component({
  selector: "app-no-internet-connection",
  templateUrl: "./no-internet-connection.component.html",
  styleUrls: ["./no-internet-connection.component.scss"],
})
export class NoInternetConnectionComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  internetConnectedFlag: boolean = true;
  internetConnectedFlagTemp: boolean = true;
  constructor(private store: Store<fromApp.AppState>) {}

  ngOnInit() {
    this.subscription = this.store
      .select("auth")
      .pipe(distinctUntilChanged())
      .pipe(debounceTime(300))
      .subscribe((state) => {
        if (
          state &&
          !_.isEqual(
            state.internetConnectedFlag,
            this.internetConnectedFlagTemp
          )
        ) {
          this.internetConnectedFlagTemp = _.cloneDeep(
            state.internetConnectedFlag
          );
          if (!this.internetConnectedFlagTemp) {
            this.changeToFalseInternetConnectedFlagDebounce();
          } else {
            this.internetConnectedFlag = true;
          }
        }
      });
  }

  private changeToFalseInternetConnectedFlagDebounce = _.debounce(() => {
    if (!this.internetConnectedFlagTemp) {
      this.internetConnectedFlag = false;
    }
  }, 5000);

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
