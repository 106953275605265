<div *ngIf="mobileorderHideCartCatalog">
  <ion-button
    [class]="
      animateButtonContinue && !finalPriceLoading
        ? 'animate__animated animate__pulse'
        : ''
    "
    [ngStyle]="{ 'max-width.px': mobileorderHideCartCatalog ? 110 : '' }"
    color="primary"
    [fill]="mobileorderHideCartCatalog ? 'solid' : 'clear'"
    [shape]="mobileorderHideCartCatalog ? 'round' : 'sq'"
    [disabled]="finalPriceLoading"
  >
    <!-- <ion-text
      style="position: absolute; left: 5px; font-weight: bold"
      *ngIf="
        mobileorderHideCartCatalog && !finalPriceLoading && cartProductsNumber
      "
    >
      {{ cartProductsNumber }}
    </ion-text> -->
    <ion-icon
      *ngIf="!finalPriceLoading"
      [slot]="!mobileorderHideCartCatalog ? 'icon-only' : ''"
      [ngStyle]="{
        transform:
          mobileorderMobileCartIconStyle &&
          mobileorderMobileCartIconStyle === 'bike-delivery'
            ? isIosPlatform
              ? 'scale(1.4)'
              : 'scale(1.7)'
            : 'none'
      }"
      [src]="
        mobileorderMobileCartIconStyle
          ? 'assets/ionicons/' + mobileorderMobileCartIconStyle + '.svg'
          : 'assets/ionicons/cart.svg'
      "
    ></ion-icon>
    <ion-text
      style="font-weight: bold; font-size: small"
      *ngIf="
        mobileorderHideCartCatalog &&
        !finalPriceLoading &&
        cartPrices &&
        cartPrices.grand_total
      "
      >{{ cartPrices.grand_total }}€
    </ion-text>

    <ion-spinner *ngIf="finalPriceLoading"> </ion-spinner>
  </ion-button>
  <ion-text
    *ngIf="
      !finalPriceLoading && cartProductsNumber && !mobileorderHideCartCatalog
    "
    [class]="isIosPlatform ? 'iosIconClass' : 'mdIconClass'"
    >{{ cartProductsNumber }}
  </ion-text>
</div>

<div *ngIf="!mobileorderHideCartCatalog">
  <ion-button
    [class]="
      animateButtonContinue && !finalPriceLoading
        ? 'animate__animated animate__pulse'
        : ''
    "
    color="primary"
    fill="clear"
    [disabled]="finalPriceLoading"
  >
    <ion-icon
      *ngIf="!finalPriceLoading"
      slot="icon-only"
      [ngStyle]="{
        transform:
          mobileorderMobileCartIconStyle &&
          mobileorderMobileCartIconStyle === 'bike-delivery'
            ? isIosPlatform
              ? 'scale(1.4)'
              : 'scale(1.7)'
            : 'none'
      }"
      [src]="
        mobileorderMobileCartIconStyle
          ? 'assets/ionicons/' + mobileorderMobileCartIconStyle + '.svg'
          : 'assets/ionicons/cart.svg'
      "
    ></ion-icon>
    <ion-spinner color="dark" *ngIf="finalPriceLoading"> </ion-spinner>
  </ion-button>
  <ion-text
    *ngIf="!finalPriceLoading && cartProductsNumber"
    [class]="isIosPlatform ? 'iosIconClass' : 'mdIconClass'"
    >{{ cartProductsNumber }}
  </ion-text>
</div>
