<ion-header>
  <app-no-internet-connection></app-no-internet-connection>
  <app-ionic-update-error></app-ionic-update-error>

  <ion-toolbar
    *ngIf=" windowDimensions && windowDimensions.width<menuMinWidthToShow"
  >
    <ion-title *ngIf="groupApp && !groupApp.categoriesLogoActive && store_info"
      >{{ 'pages.favorites' | translate}}</ion-title
    >
    <ion-buttons slot="start" *ngIf="groupApp && windowDimensions">
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-grid
    style="z-index: 10000; width: 100%"
    class="ion-text-center"
    *ngIf="currentUser &&store_info && store_info.vatBasedCustomerProductsActive "
  >
    <ion-chip (click)="presentSelectInvoiceModal()">
      <ion-text style="font-size: small">
        <strong>{{ "catalog.cart-catalog.vatNumber" | translate }}: </strong>
        {{ selectedInvoice && selectedInvoice.vatNumber ?
        selectedInvoice.vatNumber : ("catalog.cart-catalog.no-selection" |
        translate) }}
      </ion-text>
    </ion-chip>
  </ion-grid>
  <ion-grid
    class="changeStoreMaxWidth"
    *ngIf="stores && stores.length && stores.length>1 && selectedStoreId && languagePath"
  >
    <ion-item lines="none" color="primary">
      <ion-label>{{'favoriteProductsPage.select-store' | translate}}</ion-label>
      <ion-select
        [cancelText]="'cancel' | translate"
        (ionChange)="changeStore()"
        [(ngModel)]="selectedStoreId"
      >
        <ion-select-option
          *ngFor="let store of stores"
          [value]="store.store_id"
        >
          {{ store.store_name_mobileorder &&
          store.store_name_mobileorder[languagePath] ?
          store.store_name_mobileorder[languagePath] :
          (store.store_panel_name_langs &&
          store.store_panel_name_langs[languagePath] ?
          store.store_panel_name_langs[languagePath] :
          store.store_panel_name_langs && store.store_panel_name_langs.el ?
          store.store_panel_name_langs.el : store.store_name_panel ?
          store.store_name_panel : store.store_name ? store.store_name : "" ) }}
        </ion-select-option>
      </ion-select>
    </ion-item>
  </ion-grid>
</ion-header>

<ion-content>
  <ion-grid
    style="height: 100%"
    *ngIf="mobilePlatform==null || loading || !favoriteProducts || !hyperCategories "
  >
    <ion-row
      style="width: 100%; height: 100%"
      class="ion-justify-content-center ion-align-items-center"
    >
      <ion-col size="12" class="ion-align-self-center ion-text-center">
        <ion-spinner></ion-spinner>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid
    [ngClass]="allCategoriesMarketCardsDesign ? '' : 'grid-max-width'"
    *ngIf="hyperCategories &&mobilePlatform!=null && favoriteProducts && !loading"
  >
    <app-previous-orders-slider
      *ngIf="!mobilePlatform"
    ></app-previous-orders-slider>
    <app-previous-orders-slider-mobile
      *ngIf="mobilePlatform"
    ></app-previous-orders-slider-mobile>
    <ion-row
      style="width: 100%"
      *ngIf="favoriteProducts && !favoriteProducts.length "
    >
      <ion-col class="ion-text-center">
        <ion-grid style="width: 120px; height: 120px; padding: 0">
          <ng-lottie [options]="AnimationNoOrderoptions"></ng-lottie>
        </ion-grid>
        <h5 class="ion-no-margin">
          {{"favoriteProductsPage.there-are-no-favorite-products" | translate}}
        </h5>
      </ion-col>
    </ion-row>
    <app-favorites-browser
      [enableFavoritesPageSearch]="true"
      *ngIf="!mobilePlatform && favoriteProducts && favoriteProducts.length"
    ></app-favorites-browser>
    <app-favorites-mobile
      [enableFavoritesPageSearch]="true"
      [mobileMode]="true"
      *ngIf="mobilePlatform && favoriteProducts && favoriteProducts.length"
    ></app-favorites-mobile>
  </ion-grid>
  <ion-fab
    *ngIf="groupApp"
    [class]="groupApp && groupApp.design_settings && groupApp.design_settings.menuFooterTabs ?  'marginBottomTabs' :''"
    vertical="bottom"
    horizontal="end"
    slot="fixed"
  >
    <ion-fab-button (click)="navigateToCart()" [disabled]="finalPriceLoading">
      <ion-icon
        *ngIf="!finalPriceLoading"
        src="assets/ionicons/cart.svg"
      ></ion-icon>
      <ion-spinner *ngIf="finalPriceLoading"> </ion-spinner>

      <ion-text
        *ngIf="!finalPriceLoading && cartProductsNumber"
        [class]="isIosPlatform ? 'iosIconClass':'mdIconClass'"
        >{{ cartProductsNumber }}</ion-text
      >
    </ion-fab-button>
  </ion-fab>
  <div
    *ngIf="groupApp &&
groupApp.design_settings &&
groupApp.design_settings.menuFooterTabs"
    style="margin-top: 65px"
  ></div>
</ion-content>

<ion-footer
  *ngIf="!(groupApp &&
groupApp.design_settings &&
groupApp.design_settings.menuFooterTabs)"
>
  <ion-grid *ngIf="windowDimensions">
    <ion-row>
      <ion-col (click)="navigateToCatalog()" class="ion-text-center">
        <ion-button
          [size]="windowDimensions.width && windowDimensions.width<400 && isIosPlatform ? 'small' : 'medium'"
          >{{'homepage.continue-to-catalog' | translate}}</ion-button
        >
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>

<app-menu-footer-tabs></app-menu-footer-tabs>
