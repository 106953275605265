import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../store/app.reducer";
import * as _ from "lodash";
import { Subscription, auditTime, distinctUntilChanged, first } from "rxjs";

@Component({
  selector: "app-product-show-option-in-description-object",
  templateUrl: "./product-show-option-in-description-object.component.html",
  styleUrls: ["./product-show-option-in-description-object.component.scss"],
})
export class ProductShowOptionInDescriptionObjectComponent
  implements OnInit, OnDestroy
{
  @Input() product;
  @Input() orderPickup;
  @Input() dineIn;
  @Input() store_info;
  @Input() selectedDineInTable;
  fontSizeDiv = "smaller";
  fontSizePointsDiv = "small";
  fontSizePrice = "medium";
  widthTitle: number;
  maxTitleLength = 0;

  public windowDimensions: any;

  private subscription: Subscription;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private store: Store<fromApp.AppState>
  ) {}

  ngOnInit() {
    if (
      (this.product.showOptionInDescriptionObject &&
        this.product.showOptionInDescriptionObject.choices &&
        this.product.showOptionInDescriptionObject.choices.length) ||
      (this.product.showOptionInDescriptionObject_takeaway &&
        this.product.showOptionInDescriptionObject_takeaway.choices &&
        this.product.showOptionInDescriptionObject_takeaway.choices.length) ||
      (this.product.showOptionInDescriptionObject_dinein &&
        this.product.showOptionInDescriptionObject_dinein.choices &&
        this.product.showOptionInDescriptionObject_dinein.choices.length) ||
      (this.product.showOptionInDescriptionObject_alt_dinein &&
        this.product.showOptionInDescriptionObject_alt_dinein.choices &&
        this.product.showOptionInDescriptionObject_alt_dinein.choices.length)
    ) {
      this.subscription = this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(200))
        .subscribe((state) => {
          if (
            state &&
            state.windowDimensions &&
            !_.isEqual(this.windowDimensions, state.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
            if (
              this.windowDimensions &&
              this.windowDimensions.width &&
              this.windowDimensions.width < 450
            ) {
              this.fontSizePrice = "smaller";
              this.fontSizeDiv = "small";
              this.fontSizePointsDiv = "x-small";
            } else {
              this.fontSizeDiv = "smaller";
              this.fontSizePointsDiv = "small";
              this.fontSizePrice = "medium";
            }
            this.checkTitleWidth(this.windowDimensions.width);
          }
        });
    }
  }

  checkTitleWidth(width) {
    let lengthOfMaxObject;
    if (
      (this.orderPickup == null && this.dineIn == null) ||
      (this.orderPickup == false && this.dineIn == false)
    ) {
      if (
        this.product.showOptionInDescriptionObject &&
        this.product.showOptionInDescriptionObject.choices &&
        this.product.showOptionInDescriptionObject.choices.length
      ) {
        lengthOfMaxObject = _.maxBy(
          _.take(this.product.showOptionInDescriptionObject.choices, 3),

          (obj: any) => obj.title.length
        );
      }
    } else if (this.orderPickup == true && this.dineIn == false) {
      if (
        this.product.showOptionInDescriptionObject_takeaway &&
        this.product.showOptionInDescriptionObject_takeaway.choices &&
        this.product.showOptionInDescriptionObject_takeaway.choices.length
      ) {
        lengthOfMaxObject = _.maxBy(
          _.take(
            this.product.showOptionInDescriptionObject_takeaway.choices,
            3
          ),
          (obj: any) => obj.title.length
        );
      } else {
        if (
          this.product.showOptionInDescriptionObject &&
          this.product.showOptionInDescriptionObject.choices &&
          this.product.showOptionInDescriptionObject.choices.length
        ) {
          lengthOfMaxObject = _.maxBy(
            _.take(this.product.showOptionInDescriptionObject.choices, 3),
            (obj: any) => obj.title.length
          );
        }
      }
    } else if (
      this.orderPickup == false &&
      this.dineIn == true &&
      (!this.store_info.alt_dinein_active ||
        !this.selectedDineInTable ||
        !this.selectedDineInTable.alt_dinein)
    ) {
      if (
        this.product.showOptionInDescriptionObject_dinein &&
        this.product.showOptionInDescriptionObject_dinein.choices &&
        this.product.showOptionInDescriptionObject_dinein.choices.length
      ) {
        lengthOfMaxObject = _.maxBy(
          _.take(this.product.showOptionInDescriptionObject_dinein.choices, 3),
          (obj: any) => obj.title.length
        );
      } else {
        if (
          this.product.showOptionInDescriptionObject &&
          this.product.showOptionInDescriptionObject.choices &&
          this.product.showOptionInDescriptionObject.choices.length
        ) {
          lengthOfMaxObject = _.maxBy(
            _.take(this.product.showOptionInDescriptionObject.choices, 3),
            (obj: any) => obj.title.length
          );
        }
      }
    } else if (
      this.orderPickup == false &&
      this.dineIn == true &&
      this.store_info.alt_dinein_active &&
      this.selectedDineInTable &&
      this.selectedDineInTable.alt_dinein
    ) {
      if (
        this.product.showOptionInDescriptionObject_alt_dinein &&
        this.product.showOptionInDescriptionObject_alt_dinein.choices &&
        this.product.showOptionInDescriptionObject_alt_dinein.choices.length
      ) {
        lengthOfMaxObject = _.maxBy(
          _.take(
            this.product.showOptionInDescriptionObject_alt_dinein.choices,
            3
          ),
          (obj: any) => obj.title.length
        );
      } else if (
        this.product.showOptionInDescriptionObject_dinein &&
        this.product.showOptionInDescriptionObject_dinein.choices &&
        this.product.showOptionInDescriptionObject_dinein.choices.length
      ) {
        lengthOfMaxObject = _.maxBy(
          _.take(this.product.showOptionInDescriptionObject_dinein.choices, 3),
          (obj: any) => obj.title.length
        );
      } else {
        if (
          this.product.showOptionInDescriptionObject &&
          this.product.showOptionInDescriptionObject.choices &&
          this.product.showOptionInDescriptionObject.choices.length
        ) {
          lengthOfMaxObject = _.maxBy(
            _.take(this.product.showOptionInDescriptionObject.choices, 3),
            (obj: any) => obj.title.length
          );
        }
      }
    }

    if (lengthOfMaxObject && lengthOfMaxObject.title) {
      if (lengthOfMaxObject.title.length <= 7) {
        this.widthTitle = parseInt(
          (lengthOfMaxObject.title.length * 10).toFixed(0)
        );
      } else {
        this.widthTitle = parseInt(
          (lengthOfMaxObject.title.length * 8).toFixed(0)
        );
      }
    }
    this.maxTitleLength = lengthOfMaxObject.title.length;
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
