<ion-header>
  <app-no-internet-connection></app-no-internet-connection>
  <app-ionic-update-error></app-ionic-update-error>
  <ion-toolbar>
    <ion-title>{{"pages.orders" | translate}}</ion-title>
    <ion-buttons slot="start" *ngIf="groupApp && windowDimensions">
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>
    <ion-button
      title="{{'refresh' | translate}}"
      *ngIf="currentUser"
      (click)="fetchUserOrdersAgain()"
      fill="clear"
      slot="end"
    >
      <ion-icon
        slot="icon-only"
        src="assets/ionicons/refresh-circle-outline.svg"
      >
      </ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid
    *ngIf="orderCompletedAnimation && !customerNotificationsOrderModal"
    style="width: 100%; height: 100%; position: absolute; top: 0; z-index: 1000"
  >
    <ion-row
      style="width: 100%; height: 100%"
      class="ion-align-items-center ion-justify-content-center"
    >
      <ion-col class="ion-align-self-center ion-text-center">
        <ion-grid style="max-width: 300px">
          <ion-card mode="md" color="complete">
            <ion-card-title style="margin: 8px">
              <ion-text>{{'orders.order-completed' | translate}}</ion-text>
            </ion-card-title>
          </ion-card>
          <ng-lottie
            (animationCreated)="animationCreated($event)"
            (loopComplete)="animationComplete($event)"
            [options]="options"
          ></ng-lottie>
        </ion-grid>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid
    *ngIf="orderCanceledAnimation && !customerNotificationsOrderModal"
    style="width: 100%; height: 100%; position: absolute; top: 0; z-index: 1000"
  >
    <ion-row
      style="width: 100%; height: 100%"
      class="ion-align-items-center ion-justify-content-center"
    >
      <ion-col class="ion-align-self-center ion-text-center">
        <ion-grid style="max-width: 300px">
          <ion-card mode="md" color="danger">
            <ion-card-title style="margin: 8px">
              <ion-text>{{'orders.order-canceled' | translate}}</ion-text>
            </ion-card-title>
          </ion-card>
          <ng-lottie
            (animationCreated)="canceledAnimationCreated($event)"
            (loopComplete)="canceledAnimationComplete($event)"
            [options]="canceledOptions"
          ></ng-lottie>
        </ion-grid>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid class="orderPageGrid">
    <ion-row *ngIf="!orderArray || (!currentUser && !loginLoading)">
      <ion-col class="ion-text-center">
        <ion-spinner></ion-spinner>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="orderArray && orderArray.length===0">
      <ion-col class="ion-text-center">
        <ion-grid style="width: 120px; height: 120px; padding: 0">
          <ng-lottie [options]="AnimationNoOrderoptions"></ng-lottie>
        </ion-grid>
        <!-- <ion-icon
          style="font-size: 80px"
          src="assets/flatIcons/no-order.svg"
        ></ion-icon> -->
        <ion-text>
          <h5>{{"orders.no-saved-orders" | translate}}</h5>
        </ion-text>
        <ion-grid
          class="ion-no-padding ion-no-margin"
          style="max-width: 400px"
          class="ion-text-center"
        >
          <ion-item
            *ngIf="orderPickup != null && dineIn != null"
            [disabled]="checkAddressLoading"
            lines="none"
            detail="false"
            button
            class="menuButtons"
            color="primary"
            (click)="checkTheCatalog()"
          >
            <ion-label
              style="font-weight: 700; padding: 5px"
              class="ion-no-padding ion-no-margin ion-text-center"
            >
              <ion-spinner *ngIf="checkAddressLoading"> </ion-spinner>

              <div *ngIf="!checkAddressLoading">
                <div
                  *ngIf="orderPickup == true || (orderPickup==false && dineIn==true) || (orderPickup==false && dineIn==false)"
                >
                  {{ cartProducts && cartProducts.length ?
                  ("homepage.continue-order" | translate ) :
                  ("homepage.continue-to-catalog" | translate )}}
                </div>

                <p
                  *ngIf="orderPickup == true  && selectedLangugage"
                  style="font-size: small; padding: 0; margin: 0"
                >
                  {{'homepage.take-away' | translate}}: {{
                  currentStore.store_name_mobileorder &&
                  currentStore.store_name_mobileorder[selectedLangugage] ?
                  currentStore.store_name_mobileorder[selectedLangugage] :
                  currentStore.store_name_langs &&
                  currentStore.store_name_langs[ selectedLangugage ] ?
                  currentStore.store_name_langs[ selectedLangugage ] :
                  currentStore.store_name_langs &&
                  currentStore.store_name_langs.el ?
                  currentStore.store_name_langs.el : currentStore.store_name ?
                  currentStore.store_name : "" }}
                </p>
                <p
                  *ngIf="orderPickup == false && dineIn ==true"
                  style="font-size: small; padding: 0; margin: 0"
                >
                  {{'homepage.dine-in' | translate}}: {{selectedDineInTable ?
                  selectedDineInTable.name : ''}}
                </p>

                <p
                  *ngIf="orderPickup == false && dineIn ==false"
                  style="font-size: small; padding: 0; margin: 0"
                >
                  {{'homepage.delivery' | translate}}: {{selectedAddress ?
                  selectedAddress.formatted_address : '' }}
                </p>
              </div>
            </ion-label>
          </ion-item>
          <ion-item
            *ngIf="orderPickup == null && dineIn == null"
            [disabled]="checkAddressLoading"
            lines="none"
            detail="false"
            button
            class="menuButtons"
            color="primary"
            expand="block"
            (click)="presentOrderNowModal()"
          >
            <ion-label
              style="font-weight: 700; padding: 10px"
              class="ion-text-wrap ion-no-padding ion-no-margin ion-text-center"
            >
              <div>{{"homepage.new-order"| translate}}</div>
            </ion-label>
          </ion-item>
        </ion-grid>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="orderArray && orderArray.length>0">
      <ion-col>
        <ion-list>
          <app-order-item
            *ngFor="let order of orderArray trackBy: trackByMethod;let index = index"
            [order]="order"
            [openOrderChangePaymentMethodModalForOrderId]="openOrderChangePaymentMethodModalForOrderId"
            [orderForReview]="orderForReview"
            [orderIndex]="index"
            (openedChangePaymentMethodPopup)="openedChangePaymentMethodPopup($event)"
          >
          </app-order-item>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>

  <div
    *ngIf="groupApp &&
  groupApp.design_settings &&
  groupApp.design_settings.menuFooterTabs"
    style="margin-top: 65px"
  ></div>
</ion-content>

<app-menu-footer-tabs></app-menu-footer-tabs>
