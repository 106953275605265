<ion-header class="ion-no-border">
  <ion-toolbar >
    <ion-buttons slot="end">
      <ion-button color="dark" fill="clear" (click)="closeModal()">
        <ion-icon src="assets/ionicons/close.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <ion-text>{{'loyaltySystem.select-coupon' | translate}}</ion-text>
    </ion-title>
  </ion-toolbar>
</ion-header>


<ion-backdrop *ngIf="showCouponCreatedLottie"> </ion-backdrop>
<ion-grid
  *ngIf="showCouponCreatedLottie"
  style="width: 100%; height: 100%; position: absolute; top: 0; z-index: 1000"
>
  <ion-row
    style="width: 100%; height: 100%"
    class="ion-align-items-center ion-justify-content-center"
  >
    <ion-col class="ion-align-self-center ion-text-center">
      <ion-grid style="max-width: 300px">
        <ng-lottie
        (animationCreated)="couponCreated($event)"
        [options]="couponCreatedAnimationOptions"
        ></ng-lottie>
      </ion-grid>
    </ion-col>
  </ion-row>
</ion-grid>
<ion-content *ngIf="allStores && currentStoreId">
  <ion-row *ngif="allStores.length > 1">
    <ion-col class="ion-text-center">
      <ion-text style="font-weight: bold;" color="danger">Επίλεξε σε ποιό κατάστημα θέλεις να δημιουργήσεις το κουπόνι σου!</ion-text>
      <ion-list>
        <ion-item>
          <ion-label>
            {{'loyaltySystem.store' | translate}}: 
          </ion-label>
          <ion-select placeholder="Επιλογή καταστήματος" [(ngModel)]="selectedStoreId">
            <ion-select-option *ngFor="let store of allStores" [value]="store.store_id">{{store.store_name}}</ion-select-option>
          </ion-select>
        </ion-item>
      </ion-list>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col class="ion-no-padding ion-text-center" *ngIf="!groupApp.only_loyalty_app">
      <ion-card>
        <ion-card-header>
          <ion-card-title>
            {{'loyaltySystem.online-coupon' | translate}}
          </ion-card-title>
        </ion-card-header>
        <div style="max-width: 60%; transform: translate(30%, 0%);">
          <ng-lottie
          (animationCreated)="onlineCoupon($event)"
          [options]="onlineCouponAnimationOptions"
          ></ng-lottie>
        </div>
        <ion-card-header>
          <ion-card-title>
            {{campaign.title.el}}
          </ion-card-title>
        </ion-card-header>
        <ion-card-content>
          {{'loyaltySystem.online-coupon-description' | translate}}
        </ion-card-content>
        <ion-button style="min-width: 170px;" (click)="presentRedeemAlert('online')" [disabled]="buttonsDisabled">
          <ion-text *ngIf="!showOnlineSpinner">
            {{'loyaltySystem.create-coupon' | translate}}
          </ion-text>
          <ion-spinner *ngIf="showOnlineSpinner"></ion-spinner>
        </ion-button>
      </ion-card>
    </ion-col>

    <ion-col *ngIf="setOneRow" class="ion-no-padding ion-text-center">
      <ion-card>
        <ion-card-header>
          <ion-card-title>
            {{'loyaltySystem.instore-coupon' | translate}}
          </ion-card-title>
        </ion-card-header>
        <div style="max-width: 60%; transform: translate(30%, 0%);">
          <ng-lottie
          (animationCreated)="instoreCoupon($event)"
          [options]="instoreCouponAnimationOptions"
          ></ng-lottie>
        </div>
        <ion-card-header>
          <ion-card-title>
            {{campaign.title.el}}
          </ion-card-title>
        </ion-card-header>
        <ion-card-content>
          {{'loyaltySystem.instore-coupon-description' | translate}}
        </ion-card-content>
        <ion-button style="min-width: 170px;" (click)="presentRedeemAlert('instore')" [disabled]="buttonsDisabled">
          <ion-text *ngIf="!showInstoreSpinner">
            {{'loyaltySystem.create-coupon' | translate}}
          </ion-text>
          <ion-spinner *ngIf="showInstoreSpinner"></ion-spinner>
        </ion-button>
      </ion-card>
    </ion-col>
  </ion-row>

  <ion-row *ngIf="!setOneRow">
    <ion-col class="ion-no-padding ion-text-center">
      <ion-card>
        <ion-card-header>
          <ion-card-title>
            {{'loyaltySystem.instore-coupon' | translate}}
          </ion-card-title>
        </ion-card-header>
        <div style="max-width: 60%; transform: translate(30%, 0%);">
          <ng-lottie
          (animationCreated)="instoreCoupon($event)"
          [options]="instoreCouponAnimationOptions"
          ></ng-lottie>
        </div>
        <ion-card-header>
          <ion-card-title>
            {{campaign.title.el}}
          </ion-card-title>
        </ion-card-header>
        <ion-card-content>
          {{'loyaltySystem.instore-coupon-description' | translate}}
        </ion-card-content>
        <ion-button style="min-width: 170px;" (click)="presentRedeemAlert('instore')" [disabled]="buttonsDisabled">
          <ion-text *ngIf="!showInstoreSpinner">
            {{'loyaltySystem.create-coupon' | translate}}
          </ion-text>
          <ion-spinner *ngIf="showInstoreSpinner"></ion-spinner>
        </ion-button>
      </ion-card>
    </ion-col>
  </ion-row>
</ion-content>