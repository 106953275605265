<span
  *ngFor="let option of store_info.cartCustomOptions; trackBy: trackTimestamp"
>
  <div
    *ngIf="
      store_info.cartCustomOptionsActive &&
      cart.length > 0 &&
      option.active &&
      !option.only_kiosk &&
      (!dineIn || (dineIn && option.showInDinein)) &&
      !option.qtyEnabled &&
      (!option.onlinePaymentOnly ||
        (selectedPaymentMethodID !== 'cash' &&
          selectedPaymentMethodID !== 'mobilePOS')) &&
      ((!option.pickupOnly && !option.deliveryOnly) ||
        (option.deliveryOnly && !option.pickupOnly && !orderPickup) ||
        (option.pickupOnly && !option.deliveryOnly && orderPickup))
    "
    style="max-width: 700px; text-align: center; border: 0px"
  >
    <ion-item-divider
      mode="ios"
      lines="none"
      class="mainFontSize"
      [color]="option.notValid ? 'danger' : ''"
      *ngIf="option.title"
    >
      <ion-label class="ion-text-wrap">
        <strong
          *ngIf="
            option.title &&
            (!option.translations ||
              (option.translations && !option.translations.title) ||
              (option.translations &&
                option.translations.title &&
                !option.translations.title[selectedLang]))
          "
          >{{ option.title }}</strong
        >
        <strong
          *ngIf="
            option.translations &&
            option.translations.title &&
            option.translations.title[selectedLang]
          "
        >
          {{ option.translations.title[selectedLang] }}</strong
        >
      </ion-label>
    </ion-item-divider>

    <ion-item
      lines="none"
      class="minHeightDescriptionItem"
      style="padding-top: 0; margin-top: 0; padding-bottom: 0; margin-bottom: 0"
      *ngIf="option.description"
    >
      <ion-label>
        <p
          *ngIf="
            option.description &&
            (!option.translations ||
              (option.translations && !option.translations.description) ||
              (option.translations &&
                option.translations.description &&
                !option.translations.description[selectedLang]))
          "
        >
          {{ option.description }}
        </p>
        <p
          *ngIf="
            option.translations &&
            option.translations.description &&
            option.translations.description[selectedLang]
          "
        >
          {{ option.translations.description[selectedLang] }}
        </p>
      </ion-label>
    </ion-item>

    <ion-list class="ion-no-padding ion-no-margin">
      <app-radio-type-choices
        *ngIf="option.type === 'radio' && option.choices.length > 0"
        [option_timestamp]="option.timestamp"
        [selectedLang]="selectedLang"
      ></app-radio-type-choices>

      <app-checkbox-type-choices
        [option_timestamp]="option.timestamp"
        [selectedLang]="selectedLang"
        *ngIf="option.type === 'checkbox'"
      ></app-checkbox-type-choices>
      <app-text-type-choices
        [option_timestamp]="option.timestamp"
        [selectedLang]="selectedLang"
        *ngIf="option.type === 'text'"
      ></app-text-type-choices>
    </ion-list>
  </div>
</span>
