import { ThisReceiver } from "@angular/compiler";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AlertController, ModalController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import { AnimationOptions } from "ngx-lottie";
import { Subscription } from "rxjs";
import { auditTime, distinctUntilChanged, first } from "rxjs/operators";
import { InvoiceListModalComponent } from "src/app/cart/cart-components/invoice-list-modal/invoice-list-modal.component";
import { ProductDetailsOperationsService } from "src/app/product-details/product-details-operations.service";
import * as CartActions from "../../cart/store/cart.actions";
import * as fromApp from "../../store/app.reducer";
import * as selectors from "../../store/app.selectors";

@Component({
  selector: "app-cart-catalog",
  templateUrl: "./cart-catalog.component.html",
  styleUrls: ["./cart-catalog.component.scss"],
})
export class CartCatalogComponent implements OnInit, OnDestroy {
  @Input() grid;
  @Input() transparentCatalogElements;

  @Input() catalogInlineInfoMessage;
  @Input() motoCardsProgressValues;
  @Input() infoMessagesCardsHide;
  public currentUser: any;
  public cartProducts: any = null;
  public finalPriceLoading: boolean = false;
  public orderPickup: boolean;
  public dineIn: boolean;
  public selectedAddress: any;
  public store_info: any;
  public stores: any;
  public prefersDark: any;
  public prefersLight: any;
  public showDeliveryMethodAddressComponent: boolean = true;
  public showEmptyCardImage: boolean = true;
  public showCartPrices: boolean = true;
  public showCartList: boolean = true;
  public showPointSystem: boolean = true;
  public amountToMinimumOrder: any;
  public amountToMinimumOrderProgressValue: any;
  public selectedTheme: string;
  public cartIconSrc: any;
  public animateButtonContinue: boolean = false;
  public previousProductsInCart: any = [];
  public groupApp: any;
  public selectedInvoice: any;
  public hyperCategories: any;
  public windowDimensions: any;
  public cartPrices: any;
  public cartProductsCount: any;
  public categoryImageAnimation;
  public selectedCategoryImageAnimation;
  public canShowAnimationCategories;
  public mo_base;
  public languagePath;

  private selectedCategoryId;
  private selectedScrollCategoryId;
  private categories;
  private allCategories;
  private currentStoreId: string;
  private selectInvoiceModal: any;
  private subscriptions: Subscription[] = [];
  private catalogSubscription: Subscription;
  private clearCategoryAnimationImgTimeout: any;
  private clearCategoryAnimationImgTimeout2: any;

  private cartCatalogComponentHeightInterval: any;
  private cartCatalogComponentHeightCounter = 0;
  private cartCatalogComponentHeight;

  iconCoffeeOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/coffee-cart-animation.json",
  };

  trayIconAnimationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/cart-tray-animation.json",
  };

  otherLottieAnimationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/cart-tray-animation.json",
  };

  constructor(
    private store: Store<fromApp.AppState>,
    private modalCtrl: ModalController,
    private router: Router,
    private translateService: TranslateService,
    private alertController: AlertController,
    private productDetailsOperationsService: ProductDetailsOperationsService
  ) {}

  ngOnInit() {
    this.prefersDark = window.matchMedia("(prefers-color-scheme: dark)");
    this.cartIconSrc = getComputedStyle(document.documentElement)
      .getPropertyValue("--cartIconSrc")
      .toString();

    //this.cartIconSrc = "assets/flatIcons/trayDark.svg";

    this.subscriptions.push(
      this.store
        .select("cartPrices")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(200))
        .subscribe((state) => {
          if (
            state &&
            state.cartPrices &&
            !_.isEqual(this.cartPrices, state.cartPrices)
          ) {
            this.cartPrices = _.cloneDeep(state.cartPrices);
            if (this.cartPrices && this.cartPrices.amountToMinimumOrder) {
              this.amountToMinimumOrder = this.cartPrices.amountToMinimumOrder;
              this.calculateAmountToMinOrderProgressValue();
            } else if (
              this.cartPrices &&
              !this.cartPrices.amountToMinimumOrder
            ) {
              this.amountToMinimumOrder = 0;
              this.amountToMinimumOrderProgressValue = null;
            }
            //console.log(this.cartPrices);
          }
        })
    );

    //console.log("user preference", this.prefersDark, this.prefersLight);
    this.subscriptions.push(
      this.store.select("disableFinalPrice").subscribe((state) => {
        if (
          state &&
          !_.isEqual(state.disableFinalPrice, this.finalPriceLoading)
        ) {
          this.finalPriceLoading = _.cloneDeep(state.disableFinalPrice);
        }
      })
    );

    this.subscriptions.push(
      this.store.select("auth").subscribe((state) => {
        if (state && !_.isEqual(state.user, this.currentUser)) {
          this.currentUser = _.cloneDeep(state.user);
        }
      })
    );

    this.subscriptions.push(
      this.store
        .select("selectedInvoice")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((state) => {
          if (
            state &&
            state.selectedInvoice &&
            !_.isEqual(state.selectedInvoice, this.selectedInvoice)
          ) {
            this.selectedInvoice = _.cloneDeep(state.selectedInvoice);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((state) => {
          if (state && state.stores && !_.isEqual(state.stores, this.stores)) {
            this.stores = _.cloneDeep(state.stores);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("cart")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (state && !_.isEqual(this.cartProducts, state.products)) {
            this.previousProductsInCart = _.cloneDeep(this.cartProducts);
            this.cartProducts = _.cloneDeep(state.products);
            this.calculateOnlyAvailableForPreorderProducts();
            this.cartProductsCount = 0;
            if (this.cartProducts && this.cartProducts.length) {
              _.each(this.cartProducts, (product) => {
                if (
                  product.quantity &&
                  !product.disable_qty &&
                  (!product.enable_quantity_step || !product.quantity_step)
                ) {
                  this.cartProductsCount =
                    this.cartProductsCount + product.quantity;
                } else {
                  this.cartProductsCount = this.cartProductsCount + 1;
                }
              });
            }

            if (this.windowDimensions) {
            }
            if (
              this.cartProducts &&
              this.cartProducts.length &&
              this.cartProducts.length > 0 &&
              this.previousProductsInCart &&
              this.previousProductsInCart.length < this.cartProducts.length
            ) {
              this.animateButtonContinue = true;
            } else {
              this.animateButtonContinue = false;
            }
            if (navigator.cookieEnabled) {
              window.localStorage.setItem(
                "cart",
                JSON.stringify(this.cartProducts)
              );
            }

            if (
              state &&
              state.products &&
              state.products.length &&
              this.windowDimensions &&
              this.windowDimensions.height
            ) {
              this.canShowAnimationCategories = false;
            } else {
              this.calculateIfCanShowAnimationOnCategories();
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.selectedLangugage &&
            !_.isEqual(this.languagePath, state.selectedLangugage)
          ) {
            this.languagePath = _.cloneDeep(state.selectedLangugage);
          }

          if (
            state &&
            state.selectedTheme &&
            !_.isEqual(this.selectedTheme, state.selectedTheme)
          ) {
            this.selectedTheme = _.cloneDeep(state.selectedTheme);
            this.cartIconSrc = getComputedStyle(document.documentElement)
              .getPropertyValue("--cartIconSrc")
              .toString();
          }

          if (
            state &&
            state.groupApp &&
            !_.isEqual(this.groupApp, state.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);

            this.mo_base = _.cloneDeep(
              _.find(this.groupApp.languages, {
                mo_base: true,
              })
            );
            if (!this.mo_base) {
              this.mo_base = "en";
            }

            if (
              this.groupApp &&
              this.groupApp.design_settings &&
              this.groupApp.design_settings.beatCatalogHeader
            ) {
              setTimeout(() => {
                const element = document.getElementById("cart-catalog-grid");
                if (element) {
                  element.style.borderTop = "none";
                  element.style.borderLeft =
                    "1px solid var(--ion-color-primary)";
                }
              }, 50);
            }

            if (
              this.groupApp.design_settings &&
              this.groupApp.design_settings.cartIconName &&
              this.groupApp.design_settings.cartIconName !== "shoppingBag" &&
              this.groupApp.design_settings.cartIconName !== "trayDark" &&
              this.groupApp.design_settings.cartIconName !== "trolley-cart" &&
              this.groupApp.design_settings.cartIconName !== "animationCoffee"
            ) {
              this.otherLottieAnimationOptions = {
                ...this.otherLottieAnimationOptions,
                path:
                  "https://data.deliverymanager.gr/animations/" +
                  this.groupApp.design_settings.cartIconName +
                  ".json",
              };
            }

            console.log("design settings", this.groupApp);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("hyperCategories")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.hyperCategories, state.hyperCategories)
          ) {
            this.hyperCategories = _.cloneDeep(state.hyperCategories);

            this.subscribeToCatalog();
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.windowDimensions, state.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);

            this.store
              .select("cart")
              .pipe(first())
              .subscribe((state) => {
                if (state && state.products && state.products.length) {
                  this.canShowAnimationCategories = false;
                } else {
                  this.calculateIfCanShowAnimationOnCategories();
                }
              })
              .unsubscribe();
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("deliveryMethodAddress")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && !_.isEqual(this.orderPickup, state.orderPickup)) {
            this.orderPickup = _.cloneDeep(state.orderPickup);
          }
          if (state && !_.isEqual(this.dineIn, state.dineIn)) {
            this.dineIn = _.cloneDeep(state.dineIn);
          }
          if (
            state.selectedAddress &&
            !_.isEqual(this.selectedAddress, state.selectedAddress)
          ) {
            this.selectedAddress = _.cloneDeep(state.selectedAddress);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .subscribe((store_info) => {
          if (store_info && !_.isEqual(this.store_info, store_info)) {
            this.store_info = _.cloneDeep(store_info);
            console.log("store Info Changed", this.store_info);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("verticalCategory")
        .pipe(distinctUntilChanged())

        .subscribe(async (state) => {
          if (
            state &&
            state.selectedCategoryId &&
            !state.selectedScrollCategoryId &&
            !_.isEqual(state.selectedCategoryId, this.selectedCategoryId)
          ) {
            this.selectedCategoryId = _.cloneDeep(state.selectedCategoryId);

            if (!this.allCategories) {
              await this.waitCategoriesToLoad();
            }

            const selectedCat = _.find(this.allCategories, {
              category_id: this.selectedCategoryId,
            });

            this.handleCategoriesAnimationImg(selectedCat);
          }

          if (
            state &&
            state.selectedScrollCategoryId &&
            !state.selectedCategoryId &&
            !_.isEqual(
              state.selectedScrollCategoryId,
              this.selectedScrollCategoryId
            )
          ) {
            if (!this.allCategories) {
              await this.waitCategoriesToLoad();
            }
            this.selectedScrollCategoryId = _.cloneDeep(
              state.selectedScrollCategoryId
            );

            const selectedCat = _.find(this.allCategories, {
              category_id: this.selectedScrollCategoryId,
            });

            this.handleCategoriesAnimationImg(selectedCat);
          }
        })
    );

    // this.store
    //   .select("currentStore")
    //   .pipe(distinctUntilChanged())

    //   .subscribe((state) => {
    //     if (
    //       state &&
    //       state.currentStoreId &&
    //       !_.isEqual(this.currentStoreId, state.currentStoreId)
    //     ) {
    //       this.currentStoreId = _.cloneDeep(state.currentStoreId);
    //      this.validateCartDebounce();
    //     }
    //   });
  }

  async waitCategoriesToLoad() {
    return new Promise((res, rej) => {
      let counter = 0;

      if (!this.allCategories) {
        const interval = setInterval(() => {
          if (this.allCategories || counter === 4) {
            res("success");
            clearInterval(interval);
          }
          counter++;
        }, 1000);
      } else {
        res("success");
      }
    });
  }
  navigateToPage(message) {
    this.store
      .select("groupApp")
      .pipe(first())
      .subscribe((groupState) => {
        const groupApp = groupState.groupApp;
        this.store
          .select(selectors.getStoreInfo)
          .pipe(first())
          .subscribe((storeInfo) => {
            if (message.navigationPage === "catalog") {
              this.navigate(message.navigationPage);
            } else if (message.navigationPage === "cart") {
              this.navigate(message.navigationPage);
            } else if (
              message.navigationPage === "kiosk" &&
              storeInfo.dm_kiosk_app_active &&
              !(
                groupApp &&
                groupApp.design_settings &&
                groupApp.design_settings.dm_kiosk_app_disable_mobileorder
              )
            ) {
              this.navigate(message.navigationPage);
            } else if (message.navigationPage === "points") {
              this.navigate(message.navigationPage);
            } else if (message.navigationPage === "discount-coupons") {
              this.navigate(message.navigationPage);
            } else if (message.navigationPage === "orders") {
              this.navigate(message.navigationPage);
            } else if (message.navigationPage === "favorites") {
              this.navigate(message.navigationPage);
            } else if (message.navigationPage === "loyalty-home") {
              this.navigate(message.navigationPage);
            } else if (message.navigationPage === "reservations") {
              this.navigate(message.navigationPage);
            } else if (message.navigationPage === "contests") {
              this.navigate(message.navigationPage);
            } else if (
              message.navigationPage === "affiliate" &&
              groupState &&
              groupState.groupApp.affiliateActive
            ) {
              this.navigate(message.navigationPage);
            }
          })
          .unsubscribe();
      })
      .unsubscribe();
  }

  navigate(page) {
    this.router.navigateByUrl("/" + page, {
      replaceUrl: true,
    });
  }

  coffeeAnimationCreated(ev) {
    ev.setSpeed(0.6);
  }

  async presentSelectInvoiceModal() {
    this.selectInvoiceModal = await this.modalCtrl.create({
      component: InvoiceListModalComponent,
      cssClass: "invoiceListModal",
      backdropDismiss: false,
    });

    await this.selectInvoiceModal.present();
    this.selectInvoiceModal.onDidDismiss().then((invoice) => {
      console.log("on dismiss", invoice);
      this.selectInvoiceModal = null;
    });
  }

  subscribeToCatalog() {
    if (this.hyperCategories && this.hyperCategories.length > 0) {
      if (!this.catalogSubscription) {
        this.catalogSubscription = this.store
          .select("catalog")
          .pipe(distinctUntilChanged())
          .pipe(auditTime(300))
          .subscribe((state) => {
            if (
              state &&
              state.catalog &&
              !_.isEqual(this.categories, state.catalog)
            ) {
              this.categories = _.cloneDeep(state.catalog);
            }
            if (
              state &&
              state.allCategories &&
              !_.isEqual(this.allCategories, state.allCategories)
            ) {
              this.allCategories = _.cloneDeep(state.allCategories);
            }
          });
      }
    } else if (this.hyperCategories && this.hyperCategories.length === 0) {
      if (!this.catalogSubscription) {
        this.catalogSubscription = this.store
          .select("catalog")
          .pipe(distinctUntilChanged())

          .subscribe((state) => {
            if (
              state &&
              state.catalog &&
              !_.isEqual(this.categories, state.catalog)
            ) {
              this.categories = _.cloneDeep(state.catalog);
            }
            if (
              state &&
              state.allCategories &&
              !_.isEqual(this.allCategories, state.allCategories)
            ) {
              this.allCategories = _.cloneDeep(state.allCategories);
            }
          });
      }
    }
  }

  async clearCart() {
    const alert = await this.alertController.create({
      header: this.translateService.instant("alert"),

      message: this.translateService.instant(
        "cart.cart-components.cart-list.do-you-want-to-delete-cart"
      ),
      buttons: [
        {
          text: this.translateService.instant("cancel"),
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel: blah");
          },
        },
        {
          text: this.translateService.instant("yes"),
          handler: () => {
            this.store.dispatch(new CartActions.ClearCart());
            if (navigator.cookieEnabled) {
              window.localStorage.removeItem("cart");
            }
          },
        },
      ],
    });

    await alert.present();
  }

  calculateOnlyAvailableForPreorderProducts() {
    if (this.cartProducts && this.cartProducts.length) {
      this.store
        .select("deliveryMethodAddress")
        .pipe(first())
        .subscribe(async (state) => {
          if (state) {
            const allCartProducts: any =
              await this.productDetailsOperationsService.calculateonlyForPreorderForCartProducts(
                _.cloneDeep(this.cartProducts),
                state.selectedPreorderDate
              );

            if (allCartProducts && allCartProducts.length) {
              const onlyAvailableForPreorderProductsById = {};
              _.each(allCartProducts, (cartPrd) => {
                this.onlyAvailableForPreorderActions(
                  cartPrd,
                  onlyAvailableForPreorderProductsById
                );
              });

              this.store.dispatch(
                new CartActions.SetOnlyAvailableForPreorderCartProducts(
                  _.cloneDeep(onlyAvailableForPreorderProductsById)
                )
              );
            }
          }
        })
        .unsubscribe();
    }
  }

  onlyAvailableForPreorderActions(
    cart_product,
    onlyAvailableForPreorderProductsById
  ) {
    // console.log("onlyAvailableForPreorderActions", cart_product);
    if (cart_product.onlyForPreorder) {
      onlyAvailableForPreorderProductsById[cart_product.product_id] = true;
    } else {
      onlyAvailableForPreorderProductsById[cart_product.product_id] = false;
    }
    if (cart_product && cart_product.options) {
      _.each(cart_product.options, (option) => {
        if (option.bundle && option.choices) {
          _.each(option.choices, (choice) => {
            if (
              choice &&
              choice.bundle === true &&
              choice.bundled_with_product_id &&
              choice.bundled_product
            ) {
              this.onlyAvailableForPreorderActions(
                choice.bundled_product,
                onlyAvailableForPreorderProductsById
              );
            }
          });
        }
      });
    }
  }

  calculateAmountToMinOrderProgressValue() {
    this.store
      .select("deliveryMethodAddress")
      .pipe(first())
      .subscribe((state) => {
        if (state && state.validStores) {
          this.store
            .select(selectors.getStoreInfo)
            .pipe(first())
            .subscribe((storeInfo) => {
              if (storeInfo) {
                const findSelectedStore = _.find(state.validStores, {
                  store_id: storeInfo.store_id,
                });

                if (findSelectedStore) {
                  this.amountToMinimumOrderProgressValue =
                    100 -
                    (100 * this.amountToMinimumOrder) /
                      findSelectedStore.minOrder;
                } else {
                  this.amountToMinimumOrderProgressValue = null;
                }
              }
            });
        }
      });
  }

  trackFn(index, item) {
    return item.cart_id;
  }

  onClickContinue() {
    this.router.navigateByUrl("/cart", {
      replaceUrl: true,
    });

    if (navigator.cookieEnabled) {
      if (this.cartProducts) {
        window.localStorage.setItem("cart", JSON.stringify(this.cartProducts));
      }
    }
  }

  async calculateIfCanShowAnimationOnCategories() {
    this.canShowAnimationCategories = false;

    if (
      this.windowDimensions &&
      this.windowDimensions.height &&
      this.windowDimensions.height >= 900
    ) {
      this.canShowAnimationCategories = true;
    }
  }

  handleCategoriesAnimationImg(selectedCat) {
    console.log("handleCategoriesAnimationImg", selectedCat);
    if (
      selectedCat &&
      selectedCat.catalogCategoryAnimationImages &&
      selectedCat.catalogCategoryAnimationImages.length &&
      _.find(selectedCat.catalogCategoryAnimationImages, {
        position: "browserRight",
      })
    ) {
      const browserLeftAnimation = _.find(
        selectedCat.catalogCategoryAnimationImages,
        {
          position: "browserRight",
        }
      );

      if (this.clearCategoryAnimationImgTimeout) {
        clearTimeout(this.clearCategoryAnimationImgTimeout);
        this.clearCategoryAnimationImgTimeout = null;
      }
      if (this.clearCategoryAnimationImgTimeout2) {
        clearTimeout(this.clearCategoryAnimationImgTimeout2);
        this.clearCategoryAnimationImgTimeout2 = null;
      }
      this.selectedCategoryImageAnimation = null;
      this.categoryImageAnimation = false;

      this.clearCategoryAnimationImgTimeout2 = setTimeout(() => {
        this.categoryImageAnimation = true;
        this.selectedCategoryImageAnimation = _.cloneDeep(browserLeftAnimation);
        // this.clearCategoryAnimationImgTimeout = setTimeout(() => {
        //   this.selectedCategoryImageAnimation = null;
        //   this.categoryImageAnimation = false;
        // }, 5000);
      }, 350);
    } else {
      if (this.clearCategoryAnimationImgTimeout) {
        clearTimeout(this.clearCategoryAnimationImgTimeout);
        this.clearCategoryAnimationImgTimeout = null;
      }
      if (this.clearCategoryAnimationImgTimeout2) {
        clearTimeout(this.clearCategoryAnimationImgTimeout2);
        this.clearCategoryAnimationImgTimeout2 = null;
      }
      this.selectedCategoryImageAnimation = null;
      this.categoryImageAnimation = false;
    }
  }

  ngOnDestroy() {
    console.log("cart catalog destroyed");
    if (this.selectInvoiceModal) {
      this.selectInvoiceModal.dismiss();
      this.selectInvoiceModal = null;
    }

    if ((this.subscriptions && this, this.subscriptions.length > 0)) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }

    if (this.catalogSubscription) {
      this.catalogSubscription.unsubscribe();
    }
    this.subscriptions = [];
  }
}
