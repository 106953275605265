<ion-card>
  <ion-item-divider
    id="customer-info"
    mode="ios"
    [color]="valid ? '' : 'danger'"
  >
    <ion-label>
      {{
        "cart.cart-components.customer-info.customer-delivery-info" | translate
      }}{{ currentUser && currentUser.mobile ? ":" : "" }}
      {{ currentUser && currentUser.mobile ? currentUser.mobile : "" }}
    </ion-label>
    <ion-spinner
      style="scale: 0.5"
      *ngIf="currentUser && customerDataSaveLoading"
      slot="end"
      name="dots"
    ></ion-spinner>
    <ion-icon
      class="ion-no-margin"
      [color]="customerDataSaveSuccess ? 'success' : 'dark'"
      slot="end"
      *ngIf="currentUser && !customerDataSaveLoading && customerDataDirty"
      src="assets/ionicons/checkmark-circle.svg"
    ></ion-icon>
  </ion-item-divider>

  <!-- <ion-item>
    <ion-select
      [disabled]="finalPriceLoading"
      class="ion-no-padding ion-no-margin"
      (ionChange)="selectDeliveryMethod($event)"
      [value]="calculateMethod()"
    >
      <ion-label>{{
        "cart.cart-components.customer-info.select-delivery-method" | translate
      }}</ion-label>
      <ion-select-option value="delivery">{{
        "cart.cart-components.customer-info.delivery" | translate
      }}</ion-select-option>
      <ion-select-option value="takeAway">{{
        "cart.cart-components.customer-info.take-away" | translate
      }}</ion-select-option>
      <ion-select-option value="dineIn">{{
        "cart.cart-components.customer-info.dine-in" | translate
      }}</ion-select-option>
    </ion-select>
  </ion-item> -->

  <!-- <ion-item *ngIf="address && address.formatted_address">
    <ion-chip slot="start">
      <ion-icon src="assets/ionicons/location.svg"></ion-icon>
      <ion-label>{{ address.formatted_address }}</ion-label>
    </ion-chip>
  </ion-item> -->

  <ion-row style="width: 100%" *ngIf="!currentUser">
    <ion-col size="12" class="ion-text-center ion-no-padding">
      <ion-list class="ion-no-margin">
        <ion-button
          [disabled]="!loginLoading"
          color="primary"
          size="medium"
          [shape]="groupApp && groupApp.active_reservation_app ? 'round' : ''"
          (click)="presentMagicLoginPopup()"
          ><ion-text *ngIf="loginLoading">
            {{ "homepage.magic-login" | translate }}
          </ion-text>
          <ion-spinner *ngIf="!loginLoading"></ion-spinner
        ></ion-button>
      </ion-list>
    </ion-col>
  </ion-row>
  <ion-row style="width: 100%" *ngIf="currentUser">
    <ion-col>
      <ion-item
        [color]="
          currentUser && !currentUser.firstName && !valid ? 'notValid' : ''
        "
      >
        <ion-label position="floating">{{
          "cart.cart-components.customer-info.firstname" | translate
        }}</ion-label>
        <ion-input
          [class]="
            currentUser && !currentUser.firstName ? 'ng-invalid' : 'ng-valid'
          "
          [disabled]="customerDataSaveLoading"
          (ionChange)="updateCustomerDataDebounced('firstName')"
          (ionFocus)="onInputFocus()"
          [(ngModel)]="currentUser.firstName"
          name="lastName"
        ></ion-input>
      </ion-item>
    </ion-col>
    <ion-col>
      <ion-item
        [color]="
          currentUser && !currentUser.lastName && !valid ? 'notValid' : ''
        "
      >
        <ion-label position="floating">{{
          "cart.cart-components.customer-info.lastname" | translate
        }}</ion-label>
        <ion-input
          [class]="
            currentUser && !currentUser.lastName ? 'ng-invalid' : 'ng-valid'
          "
          [disabled]="customerDataSaveLoading"
          (ionChange)="updateCustomerDataDebounced('lastName')"
          (ionFocus)="onInputFocus()"
          [(ngModel)]="currentUser.lastName"
          name="firstName"
        ></ion-input>
      </ion-item>
    </ion-col>

    <!-- <ion-col>
      <ion-item (click)="onMobileClick()">
        <ion-label position="stacked">{{
          "cart.cart-components.customer-info.mobile" | translate
        }}</ion-label>
        <ion-input
          *ngIf="currentUser"
          disabled="true"
          [(ngModel)]="currentUser.mobile"
        >
        </ion-input>
      </ion-item>
    </ion-col> -->
  </ion-row>
</ion-card>
