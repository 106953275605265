<ion-card
  *ngIf="
    orderPickup == false &&
    dineIn == false &&
    storeInfo &&
    groupApp &&
    !groupApp.vatBasedCustomerAddressesActive
  "
>
  <div id="delivery-info">
    <ion-item-divider
      *ngIf="address"
      mode="ios"
      [color]="valid ? '' : 'danger'"
    >
      {{ "cart.cart-components.delivery-info.delivery-info" | translate }}
      <ion-spinner
        style="scale: 0.5"
        *ngIf="currentUser && customerDataSaveLoading"
        slot="end"
        name="dots"
      ></ion-spinner>
      <ion-icon
        class="ion-no-margin"
        [color]="customerDataSaveSuccess ? 'success' : 'dark'"
        slot="end"
        *ngIf="currentUser && !customerDataSaveLoading && customerDataDirty"
        src="assets/ionicons/checkmark-circle.svg"
      >
      </ion-icon>
    </ion-item-divider>

    <form *ngIf="address">
      <ion-item
        [color]="address && !address.doorbellname && !valid ? 'notValid' : ''"
        *ngIf="
          orderType === 'delivery' &&
          !storeInfo.customerInvoiceRequired &&
          !(
            address &&
            address.fixed_address_id &&
            address.fixed_address_type &&
            address.fixed_address_type === 'meeting_point'
          )
        "
      >
        <ion-label position="floating">{{
          "cart.cart-components.delivery-info.doorbellname" | translate
        }}</ion-label>
        <ion-input
          type="text"
          [class]="address && !address.doorbellname ? 'ng-invalid' : 'ng-valid'"
          [(ngModel)]="address.doorbellname"
          (ionChange)="updateCustomerDataDebounced('doorbellname')"
          (ionFocus)="onInputFocus()"
          name="doorbellname"
        ></ion-input>
      </ion-item>
      <ion-item
        *ngIf="
          orderType === 'delivery' &&
          !storeInfo.customerInvoiceRequired &&
          !(
            address &&
            address.fixed_address_id &&
            address.fixed_address_type &&
            address.fixed_address_type === 'meeting_point'
          )
        "
        [color]="address && !address.floor && !valid ? 'notValid' : ''"
      >
        <ion-label position="floating">{{
          "cart.cart-components.delivery-info.floor" | translate
        }}</ion-label>
        <ion-input
          name="floor"
          [class]="address && !address.floor ? 'ng-invalid' : 'ng-valid'"
          [(ngModel)]="address.floor"
          (ionChange)="updateCustomerDataDebounced('floor')"
          (ionFocus)="onInputFocus()"
        >
        </ion-input>
      </ion-item>
      <ion-item
        *ngIf="
          currentUser &&
          !(
            address &&
            address.fixed_address_id &&
            address.fixed_address_type &&
            address.fixed_address_type === 'meeting_point'
          )
        "
      >
        <ion-label position="floating">{{
          !storeInfo.customerInvoiceRequired
            ? ("cart.cart-components.delivery-info.company" | translate)
            : ("cart.cart-components.delivery-info.business-title" | translate)
        }}</ion-label>
        <ion-input
          name="company"
          [(ngModel)]="address.company"
          (ionChange)="updateCustomerDataDebounced('company')"
          (ionFocus)="onInputFocus()"
        ></ion-input>
      </ion-item>
      <ion-item *ngIf="currentUser && storeInfo.customerInvoiceRequired">
        <ion-label position="floating">{{
          "cart.cart-components.delivery-info.business-manager" | translate
        }}</ion-label>
        <ion-input
          name="company_manager"
          [(ngModel)]="address.company_manager"
          (ionChange)="updateCustomerDataDebounced('company_manager')"
          (ionFocus)="onInputFocus()"
        ></ion-input>
      </ion-item>
      <ion-item *ngIf="currentUser && orderType === 'delivery'">
        <ion-label position="floating">{{
          "cart.cart-components.delivery-info.alternative-phone" | translate
        }}</ion-label>
        <ion-input
          type="tel"
          name="altPhone"
          [(ngModel)]="address.altPhone"
          (ionChange)="updateCustomerDataDebounced('altPhone')"
          (ionFocus)="onInputFocus()"
        ></ion-input>
      </ion-item>
      <ion-item *ngIf="currentUser && orderType === 'delivery'">
        <ion-label position="floating">{{
          "cart.cart-components.delivery-info.address-comments" | translate
        }}</ion-label>
        <ion-textarea
          type="text"
          name="comments"
          [(ngModel)]="address.comments"
          (ionChange)="updateCustomerDataDebounced('comments')"
          (ionFocus)="onInputFocus()"
        ></ion-textarea>
      </ion-item>
    </form>
  </div>
</ion-card>
