import { Component, OnDestroy, OnInit } from "@angular/core";
import { Renderer2 } from "@angular/core";
import { Store } from "@ngrx/store";
import { auditTime, distinctUntilChanged, Subscription } from "rxjs";
import * as fromApp from "../../store/app.reducer";
import * as _ from "lodash";
import { getAuth, RecaptchaVerifier } from "firebase/auth";
import { FirebaseBrowserService } from "src/app/services/firebaseBrowser/firebase-browser.service";
import * as FirebaseRecaptchaActions from "./store/firebaseRecaptcha.actions";
import { ModalController, Platform } from "@ionic/angular";

@Component({
  selector: "app-firebase-recaptcha",
  templateUrl: "./firebase-recaptcha.component.html",
  styleUrls: ["./firebase-recaptcha.component.scss"],
})
export class FirebaseRecaptchaComponent implements OnInit, OnDestroy {
  public loading: boolean = false;
  public selectedLanguage: string;
  private recapthaVerifier: any;
  private theme: any;
  private unsubscribeBackEvent: Subscription;
  private renderer: Renderer2;
  private sub: Subscription;

  constructor(
    private store: Store<fromApp.AppState>,
    private firebaseBrowserService: FirebaseBrowserService,
    private modalController: ModalController,
    private platform: Platform
  ) {}

  closeModal() {
    this.modalController.dismiss();
  }

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      300,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );
    if (navigator.cookieEnabled) {
      let darkTheme = localStorage.getItem("darkTheme");
      if (darkTheme && darkTheme === "yes") {
        this.theme = "dark";
      } else {
        this.theme = "light";
      }
    }
    console.log("recaptcha on init");
    this.sub = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(50))
      .subscribe((state) => {
        if (
          state &&
          state.selectedLangugage &&
          !_.isEqual(this.selectedLanguage, state.selectedLangugage)
        ) {
          this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
          this.Initialize(this.selectedLanguage);
        }
      });
  }

  Initialize(language_code) {
    const firebaseApp = this.firebaseBrowserService.getFireBaseApp();
    if (firebaseApp) {
      const auth = getAuth(firebaseApp);
      auth.languageCode = language_code;

      if (this.recapthaVerifier) {
        // This is needed so that the reCaptcha will be triggered again, each time the popup opens!
        this.recapthaVerifier.clear();
      }

      console.log("Initializing reCaptcha");
      // We are in the browser version
      this.recapthaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "normal",
          theme: this.theme,
          callback: (response) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            // ...
            console.log("reCaptcha response", response);
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            this.store.dispatch(
              new FirebaseRecaptchaActions.SetFirebaseRecaptchaToken(response)
            );
            this.modalController.dismiss("problem");
          },
          "expired-callback": () => {
            // Response expired. Ask user to solve reCAPTCHA again.
            // ...
            console.log("Expired callback");
            this.recapthaVerifier.reset();
          },
          "error-callback": (e) => {
            console.log("Error callback:", e);
            this.recapthaVerifier.reset();
          },
        }
      );
      this.recapthaVerifier.render().then(function (widgetId) {
        console.log("rendered reCaptcha");
      });
    }
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
  }
}
