<ion-card *ngIf="currentUser && activeCoupons && activeCoupons.length > 0">
  <ion-item-divider mode="ios">
    <strong>{{
      "cart.cart-components.cart-active-coupons.my-coupons" | translate
    }}</strong>

    <ion-button
      *ngIf="showCouponsSheduleButton"
      (click)="openCouponsScheduleInfoModal()"
      slot="end"
      fill="clear"
    >
      <ion-icon
        src="assets/ionicons/information-circle-outline.svg"
        slot="icon-only"
      ></ion-icon>
    </ion-button>
  </ion-item-divider>

  <div style="max-width: 700px; text-align: left" class="padding">
    <ion-item
      *ngIf="allCartProductsPromo && cartProducts && cartProducts.length"
      class="error-item"
      lines="none"
    >
      <ion-label class="ion-text-center ion-no-margin ion-text-wrap">
        <ion-text style="font-size: small; font-weight: bold" color="danger"
          >{{
            "cart.cart-components.cart-active-coupons.cart-products-cant-get-coupon"
              | translate
          }}
        </ion-text>
      </ion-label>
    </ion-item>
    <ion-item
      *ngFor="let coupon of activeCoupons; let i = index; trackBy: trackFunc"
      class="item item-text-wrap item-icon-left cartCoupons cartButtonsWithBordersStyle"
    >
      <ion-label class="ion-text-wrap">
        <strong
          *ngIf="
            coupon.discount &&
            coupon.discountType &&
            (coupon.discountType === 'percent' ||
              coupon.discountType === 'fixed')
          "
          >-{{
            coupon.discount + (coupon.discountType === "percent" ? "%" : "€")
          }}&nbsp;</strong
        >

        <strong
          *ngIf="
            coupon.discount_percent &&
            coupon.discount_fixed &&
            coupon.discountType &&
            coupon.discountType === 'combined'
          "
        >
          -{{ coupon.discount_percent }}% & -{{ coupon.discount_fixed }}€
          &nbsp;</strong
        >

        <strong
          *ngIf="
            !(
              (coupon.discountType !== 'percent' &&
                coupon.discountType !== 'combined' &&
                coupon.discount) ||
              (coupon.discountType === 'combined' &&
                coupon.discount_percent &&
                coupon.discount_fixed)
            ) &&
            coupon.product_id &&
            !coupon.classic_offer_product
          "
        >
          {{ "free" | translate }}
        </strong>

        {{ coupon.description }}
        <p
          style="
            font-size: x-small;
            font-weight: 700;

            line-height: 1.4;
          "
          [ngClass]="{ cartWarningLightColor: !coupon.validDate }"
          *ngIf="coupon.activeFrom || coupon.activeTo"
        >
          {{
            "cart.cart-components.cart-active-coupons.valid" | translate
          }}&nbsp;

          {{ "cart.cart-components.cart-active-coupons.from" | translate }}
          {{
            coupon.activeFrom
              ? (coupon.activeFrom | date : "dd/MM/yyyy HH:mm")
              : ""
          }}

          {{ "cart.cart-components.cart-active-coupons.to" | translate }}
          {{ coupon.activeTo ? (coupon.activeTo | date : "dd/MM/yyyy") : "" }}
        </p>
        <!-- <p style="font-size: small;" *ngIf=" !coupon.validDate  && coupon.validDateException">
        <ion-text color="danger">
          {{"cart.cart-components.cart-active-coupons.today-exceptionally-you-cant-use-the-coupon" |
          translate}}
        </ion-text>
      </p> -->
        <p
          style="
            font-size: x-small;
            font-weight: 700;

            line-height: 1.4;
          "
          [ngClass]="{
            cartWarningLightColor:
              coupon.validCombo && coupon.validDate && !coupon.validMinOrder
          }"
          *ngIf="
            coupon.appliesToOrderGreaterThan &&
            coupon.appliesToOrderGreaterThan !== '0'
          "
        >
          {{
            "cart.cart-components.cart-active-coupons.minimum-order"
              | translate
          }}&nbsp;{{ coupon.appliesToOrderGreaterThan }}€
        </p>

        <p
          style="
            font-size: x-small;
            font-weight: 700;

            line-height: 1.4;
          "
          [ngClass]="{
            cartWarningLightColor:
              coupon.validCombo && coupon.validDate && !coupon.validMinOrder
          }"
          *ngIf="coupon.ignoreMinOrder"
        >
          {{
            "cart.cart-components.cart-active-coupons.store-min-order-not-valid-for-this-coupon"
              | translate
          }}
        </p>

        <p
          style="
            font-size: x-small;
            font-weight: 700;

            line-height: 1.4;
          "
        >
          {{
            "cart.cart-components.cart-active-coupons.cant-used-with-promo-products"
              | translate
          }}
        </p>

        <p
          *ngIf="coupon.preorder_only"
          style="
            font-size: x-small;
            font-weight: 700;

            line-height: 1.4;
          "
        >
          <ion-text color="danger">
            {{
              "cart.cart-components.cart-active-coupons.coupon-can-be-used-only-for-preorder"
                | translate
            }}
          </ion-text>
        </p>

        <p
          *ngIf="coupon.online_payment_only"
          style="
            font-size: x-small;
            font-weight: 700;

            line-height: 1.4;
          "
        >
          <ion-text color="danger">
            {{
              "cart.cart-components.cart-active-coupons.coupon-can-be-used-only-for-online-payment"
                | translate
            }}
          </ion-text>
        </p>

        <p *ngIf="coupon.status === 'pending_confirmation'">
          <ion-text color="danger">
            {{
              "discount-coupons.coupons-item.coupon-must-be-confirmed-from-store"
                | translate
            }}
          </ion-text>
        </p>

        <div>
          <ion-badge
            *ngIf="coupon.status"
            [color]="calculateBadgeColor(coupon)"
          >
            {{ "discount-coupons.coupons-item." + coupon.status | translate }}
            <ion-text *ngIf="coupon.order_id && coupon.status === 'used'"
              >#{{ coupon.order_id }}</ion-text
            >
          </ion-badge>
          <ion-badge
            *ngIf="!coupon.validCombo && coupon.validDate"
            color="danger"
          >
            <ion-text class="cartWarningLightColor">
              {{
                "cart.cart-components.cart-active-coupons.coupon-cannot-be-combined-with-your-other-choices"
                  | translate
              }}
            </ion-text>
          </ion-badge>
        </div>
      </ion-label>
      <ion-checkbox
        [disabled]="finalPriceLoading"
        [(ngModel)]="coupon.couponCheckbox"
        (ionChange)="clickCheckboxCoupon(coupon)"
      >
      </ion-checkbox>
      <div
        style="position: absolute; bottom: 5px; right: 10px; font-size: x-small"
        *ngIf="coupon && coupon.points"
      >
        {{ "discount-coupons.coupons-item.from_lowercase" | translate }}
        {{ coupon.points }}
        {{ "discount-coupons.coupons-item.points" | translate }}
      </div>
    </ion-item>
  </div>
</ion-card>
