import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "calculateDeliveryCostDistanceChargeType",
})
export class CalculateDeliveryCostDistanceChargeTypePipe
  implements PipeTransform
{
  transform(distanceKm: any, stepMeters: number): any {
    const stepRoundUp = (num, param) => {
      if (param <= 0) {
        throw new Error("Parameter must be a positive number");
      }
      return Math.ceil(num / param) * param;
    };

    if (distanceKm) {
      distanceKm =
        stepRoundUp(parseFloat(distanceKm.toString()) * 1000, stepMeters) /
        1000;
    }

    return parseFloat(parseFloat(distanceKm.toString()).toFixed(1)).toFixed(2);
  }
}
