import { Pipe, PipeTransform } from "@angular/core";
import * as _ from "lodash";

@Pipe({
  name: "filterFixedPointsAddressAreas",
})
export class FilterFixedPointsAddressAreasPipe implements PipeTransform {
  transform(areas: any, returnOnlyFixedPoints: boolean): any {
    return _.filter(areas, (area) => {
      if (returnOnlyFixedPoints) {
        if (area.fixed_address_id) {
          return area;
        }
      } else {
        if (!area.fixed_address_id) {
          return area;
        }
      }
    });
  }
}
