import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../store/app.reducer";
import * as _ from "lodash";
import { distinctUntilChanged, Subscription } from "rxjs";

@Component({
  selector: "app-side-menu-header-logo",
  templateUrl: "./side-menu-header-logo.component.html",
  styleUrls: ["./side-menu-header-logo.component.scss"],
})
export class SideMenuHeaderLogoComponent implements OnInit, OnDestroy {
  @Input() groupApp;
  @Input() storeImage;
  @Input() flattenedIconBackgroundFlag;
  @Input() headerLogo;
  @Input() darkHeaderLogo;
  @Input() biggerImageLogo: boolean;
  public selectedTheme: string;
  private subscription: Subscription;

  constructor(private store: Store<fromApp.AppState>) {}

  ngOnInit() {
    console.log("biggerImageLogo", this.biggerImageLogo);
    if (
      this.groupApp &&
      this.groupApp.headerLogo &&
      this.groupApp.headerLogoIconBackground
    ) {
      const el = document.documentElement;
      if (el) {
        el.style.setProperty(
          "--headerLogoIconBackground",
          this.groupApp.headerLogoIconBackground
        );
      }
    }
    if (this.groupApp && this.groupApp.flattenedIconBackground) {
      const el = document.documentElement;
      if (el) {
        el.style.setProperty(
          "--flattenedIconBackground",
          this.groupApp.flattenedIconBackground
        );
      }
    }

    this.subscription = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.selectedTheme &&
          !_.isEqual(this.selectedTheme, state.selectedTheme)
        ) {
          this.selectedTheme = _.cloneDeep(state.selectedTheme);
        }
      });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
