import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-bundle-option",
  templateUrl: "./bundle-option.component.html",
  styleUrls: ["./bundle-option.component.scss"],
})
export class BundleOptionComponent implements OnInit {
  @Input() selectedProductParentDiscount;
  @Input() selectedProductId: any;
  @Input() product_id;
  @Input() productName: any;
  @Input() bundleMode: any;
  @Input() option_id;
  @Input() type: any;
  @Input() must_select_something: any;
  @Input() selectedProductPromo: any;
  @Input() selectedProductCategory: any;
  @Input() selectedProductFreeParentOption: any;
  @Input() disable_qty: any;
  @Input() rootProductParentBundleChoice;
  @Input() groupStoreData: any;
  @Output() bundleOptionChanged = new EventEmitter();
  constructor() {}

  ngOnInit() {}

  bundleOptionChange(option) {
    this.bundleOptionChanged.emit(option);
  }
}
