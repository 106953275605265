<!-- <ion-item-divider
  mode="ios"
  *ngIf="onlinePaymentOnlyPromos && onlinePaymentOnlyPromos.length > 0"
>
  <ion-label>
    <strong>{{
      "cart.cart-components.checkbox-promotions.promotions" | translate
    }}</strong>
  </ion-label>
</ion-item-divider> -->

<ion-card
  *ngIf="
    checkboxPromotions &&
    checkboxPromotions.length > 0 &&
    thereIsPromoOnlinePaymentOnly
  "
>
  <ion-item
    *ngFor="
      let promo of checkboxPromotions | checkboxPromotionsOnlinePayment;
      trackBy: trackbyFnc
    "
  >
    <ion-label class="ion-text-wrap"
      ><ion-text style="font-size: small">{{
        promo.descriptionCheckbox
      }}</ion-text>
      <p class="cartWarningColor" *ngIf="promo.couponSelected">
        {{
          "cart.cart-components.checkbox-promotions.promotions-cannot-be-combined-with-the-selected-coupons"
            | translate
        }}
      </p></ion-label
    >
    <div
      *ngIf="promo.ticketRestaurantCardOnly"
      style="position: absolute; top: 35px; right: 10px"
    >
      <img
        alt="ticketRestaurantCard"
        loading="lazy"
        width="50px"
        src="https://data.deliverymanager.gr/bankicons/ticketRestaurantCard.png"
      />
    </div>
    <ion-checkbox
      [disabled]="promo.couponSelected || finalPriceLoading"
      slot="start"
      [(ngModel)]="promo.promoCheckbox"
      (ionChange)="clickCheckBoxPromotionDebounce(promo)"
    ></ion-checkbox>
  </ion-item>
</ion-card>
