<ion-item
  detail="false"
  button
  lines="none"
  *ngIf="option"
  (click)="openSelectionModal(option.option_id)"
>
  <ion-row class="ion-no-padding ion-no-margin">
    <ion-col
      class="ion-no-padding ion-no-margin"
      size="12"
      class="ion-text-right"
    >
      <ion-badge
        *ngIf="
          optionValidationObject &&
          optionValidationObject.errorArray &&
          optionValidationObject.errorArray.length > 0
        "
        color="danger"
      >
        <ion-text style="font-size: smaller">
          {{ optionValidationObject.errorArray[0] }}
        </ion-text></ion-badge
      >
    </ion-col>
    <ion-col
      class="ion-text-right"
      size="12"
      *ngIf="
        selectedProductFreeParentOption &&
        (!option.dependenciesVariable ||
          option.dependenciesVariable.length === 0) &&
        option.parent_free &&
        option.option_parent_total_price === 0
      "
    >
      <ion-badge color="danger"
        ><ion-text>
          {{ "product-details.options.free" | translate }}
        </ion-text></ion-badge
      >
    </ion-col>
    <ion-col
      class="ion-text-right"
      size="12"
      *ngIf="
        selectedProductFreeParentOption &&
        (!option.dependenciesVariable ||
          option.dependenciesVariable.length === 0) &&
        option.parent_free &&
        option.option_parent_total_price &&
        option.option_total_price &&
        toFloat(option.option_total_price) >
          toFloat(option.option_parent_total_price)
      "
    >
      <ion-badge color="danger">
        <ion-text>
          {{ option.option_parent_total_price }}€ ({{
            selectedProductParentDiscount
          }}% {{ "discount" | translate }})
        </ion-text>
      </ion-badge>
    </ion-col>
    <ion-col size="12" class="ion-no-padding ion-no-margin">
      <ion-label class="ion-text-wrap">
        <strong>
          {{ option.title }}
          <span
            *ngIf="option.is_require && option.availableTotal"
            style="font-weight: bold"
            >*</span
          >
        </strong>

        <p *ngIf="option && option.description">{{ option.description }}</p>

        <div
          style="padding: 0px; margin: 0px"
          *ngIf="
            option.promo ||
            option.pickup ||
            option.tags ||
            !option.availableTotal
          "
        >
          <app-product-option-tags
            [selectedProductPromo]="selectedProductPromo"
            [optionItem]="option"
            [groupStoreData]="groupStoreData"
          ></app-product-option-tags>
        </div>

        <p *ngIf="selectedChoice" class="item-text-wrap">
          <span>{{ selectedChoice.title }}&nbsp;</span>
          <span
            *ngIf="
              ((orderPickup == false && dineIn == false) ||
                (orderPickup == null && dineIn == null) ||
                (orderPickup == true &&
                  dineIn == false &&
                  !selectedChoice.price_takeaway) ||
                (orderPickup == false &&
                  dineIn == true &&
                  (!alt_dinein_active ||
                    !selectedDineInTable ||
                    !selectedDineInTable.alt_dinein) &&
                  !selectedChoice.price_dinein) ||
                (orderPickup == false &&
                  dineIn == true &&
                  alt_dinein_active &&
                  selectedDineInTable &&
                  selectedDineInTable.alt_dinein &&
                  !selectedChoice.price_alt_dinein)) &&
              selectedChoice.price.toString().trim().replace(',', '.') > 0
            "
            >{{
              selectedChoice.price.toString().trim().replace(",", ".")
                | number : "1.2-2"
            }}€</span
          >
          <span
            *ngIf="
              ((orderPickup == false && dineIn == false) ||
                (orderPickup == null && dineIn == null) ||
                (orderPickup == true &&
                  dineIn == false &&
                  !selectedChoice.price_takeaway) ||
                (orderPickup == false &&
                  dineIn == true &&
                  (!alt_dinein_active ||
                    !selectedDineInTable ||
                    !selectedDineInTable.alt_dinein) &&
                  !selectedChoice.price_dinein) ||
                (orderPickup == false &&
                  dineIn == true &&
                  alt_dinein_active &&
                  selectedDineInTable &&
                  selectedDineInTable.alt_dinein &&
                  !selectedChoice.price_alt_dinein)) &&
              selectedChoice.price.toString().trim().replace(',', '.') < 0
            "
            >{{
              selectedChoice.price.toString().trim().replace(",", ".")
                | number : "1.2-2"
            }}€</span
          >

          <span
            *ngIf="
              orderPickup == true &&
              dineIn == false &&
              selectedChoice.price_takeaway &&
              selectedChoice.price_takeaway
                .toString()
                .trim()
                .replace(',', '.') > 0
            "
            >{{
              selectedChoice.price_takeaway.toString().trim().replace(",", ".")
                | number : "1.2-2"
            }}€</span
          >
          <span
            *ngIf="
              orderPickup == true &&
              dineIn == false &&
              selectedChoice.price_takeaway &&
              selectedChoice.price_takeaway
                .toString()
                .trim()
                .replace(',', '.') < 0
            "
            >{{
              selectedChoice.price_takeaway.toString().trim().replace(",", ".")
                | number : "1.2-2"
            }}€</span
          >

          <span
            *ngIf="
              orderPickup == false &&
              dineIn == true &&
              (!alt_dinein_active ||
                !selectedDineInTable ||
                !selectedDineInTable.alt_dinein ||
                !selectedChoice.price_alt_dinein) &&
              selectedChoice.price_dinein &&
              selectedChoice.price_dinein.toString().trim().replace(',', '.') >
                0
            "
            >{{
              selectedChoice.price_dinein.toString().trim().replace(",", ".")
                | number : "1.2-2"
            }}€</span
          >
          <span
            *ngIf="
              orderPickup == false &&
              dineIn == true &&
              (!alt_dinein_active ||
                !selectedDineInTable ||
                !selectedDineInTable.alt_dinein ||
                !selectedChoice.price_alt_dinein) &&
              selectedChoice.price_dinein &&
              selectedChoice.price_dinein.toString().trim().replace(',', '.') <
                0
            "
            >{{
              selectedChoice.price_dinein.toString().trim().replace(",", ".")
                | number : "1.2-2"
            }}€</span
          >

          <span
            *ngIf="
              orderPickup == false &&
              dineIn == true &&
              alt_dinein_active &&
              selectedDineInTable &&
              selectedDineInTable.alt_dinein &&
              selectedChoice.price_alt_dinein &&
              selectedChoice.price_alt_dinein
                .toString()
                .trim()
                .replace(',', '.') > 0
            "
            >{{
              selectedChoice.price_alt_dinein
                .toString()
                .trim()
                .replace(",", ".") | number : "1.2-2"
            }}€</span
          >
          <span
            *ngIf="
              orderPickup == false &&
              dineIn == true &&
              alt_dinein_active &&
              selectedDineInTable &&
              selectedDineInTable.alt_dinein &&
              selectedChoice.price_alt_dinein &&
              selectedChoice.price_alt_dinein
                .toString()
                .trim()
                .replace(',', '.') < 0
            "
            >{{
              selectedChoice.price_alt_dinein
                .toString()
                .trim()
                .replace(",", ".") | number : "1.2-2"
            }}€</span
          >
        </p>
        <ion-text color="danger">
          <p *ngIf="!selectedChoice">
            {{ "product-details.no-choice" | translate }}
          </p>
        </ion-text>
      </ion-label>
    </ion-col>
  </ion-row>

  <ion-icon
    src="assets/ionicons/chevron-down-outline.svg"
    slot="end"
  ></ion-icon>
</ion-item>
