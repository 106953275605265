import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  AlertController,
  LoadingController,
  ModalController,
  Platform,
} from "@ionic/angular";
import { Subscription, distinctUntilChanged } from "rxjs";
import * as _ from "lodash";
import moment from "moment";
import * as fromApp from "../../store/app.reducer";
import * as selectors from "../../store/app.selectors";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import * as PointsActions from "../../points/store/points.actions";
import { AnimationOptions } from "ngx-lottie";
import { SelectStorePopupComponent } from "src/app/popups/select-store-popup/select-store-popup.component";
import { CheckLocationDistanceService } from "src/app/services/checkLocationDistance/check-location-distance.service";
import { CheckGpsCouponRuleModalComponent } from "src/app/popups/check-gps-coupon-rule-modal/check-gps-coupon-rule-modal.component";
import { Capacitor } from "@capacitor/core";
import { InfoMessageModalComponent } from "src/app/popups/info-message-modal/info-message-modal.component";

@Component({
  selector: "app-kiosk-get-promo-modal",
  templateUrl: "./kiosk-get-promo-modal.component.html",
  styleUrls: ["./kiosk-get-promo-modal.component.scss"],
})
export class KioskGetPromoModalComponent implements OnInit, OnDestroy {
  @Input() showLoginRegisterButton: any;
  @Input() promo: any;
  @Input() type: string; //couponRule or reward
  @Input() groupApp: any;
  @Input() stores: any;
  public badgeColor;
  public currentUser: any;
  public loading: boolean = false;
  public usePointsAnimation = false;

  private device_id: any;
  private storeInfo: any;
  private currentStoreId: any;
  private ionLoading: any;
  private mo_token;
  private successResponse;
  private usePointsAnimationInstance: any;
  private subscriptions: Subscription[] = [];
  private unsubscribeBackEvent: Subscription;

  private checkGpsCouponRuleModal: any;
  private InfoMessageModal: any;

  usePointsSuccessAnimationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/use-points-success-animation.json",
  };
  constructor(
    private modalController: ModalController,
    private platform: Platform,
    private translate: TranslateService,
    private dataStorageService: DataStorageService,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private checkLocationDistanceService: CheckLocationDistanceService,

    private store: Store<fromApp.AppState>
  ) {}

  closeModal() {
    this.modalController.dismiss();
  }

  ngOnInit() {
    console.log("promo", this.promo);
    if (this.type === "couponRule" && this.promo && this.promo.activeTo) {
      this.promo.activeTo = moment(
        _.cloneDeep(parseInt(this.promo.activeTo.toString()))
      )
        .subtract(1, "day")
        .format("x")
        .toString();
      if (
        this.promo.activeFrom &&
        moment(
          _.cloneDeep(parseInt(this.promo.activeFrom.toString()))
        ).isBefore(moment())
      ) {
        this.promo.activeFrom = null;
      }
      if (
        this.promo.activeTo &&
        moment(_.cloneDeep(parseInt(this.promo.activeTo.toString()))).isAfter(
          moment().add(1, "year")
        )
      ) {
        this.promo.activeTo = null;
      }
    }
    if (this.promo && this.type === "couponRule") {
      //console.log("coupon exists");
      if (this.promo.status === "used") {
        this.badgeColor = "used-coupon";
        //console.log("set used coupon");
      } else if (this.promo.status === "active") {
        this.badgeColor = "active-coupon";
        //console.log("set active coupon");
      } else if (this.promo.status === "expired") {
        this.badgeColor = "expired-coupon";
        // console.log("set exp coupon");
      } else if (this.promo.status === "outdated") {
        this.badgeColor = "expired-coupon";
        // console.log("set exp coupon");
      } else if (this.promo.status === "canceled") {
        this.badgeColor = "used-coupon";
        // console.log("set exp coupon");
      }
    }

    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );

    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && !_.isEqual(state.user, this.currentUser)) {
            this.currentUser = _.cloneDeep(state.user);
          }
          if (state && !_.isEqual(state.mo_token, this.mo_token)) {
            this.mo_token = _.cloneDeep(state.mo_token);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("currentStore")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && !_.isEqual(state.currentStoreId, this.currentStoreId)) {
            this.currentStoreId = _.cloneDeep(state.currentStoreId);
          }
        })
    );
    this.subscriptions.push(
      this.store.select(selectors.getStoreInfo).subscribe((storeInfo) => {
        if (storeInfo && !_.isEqual(storeInfo, this.storeInfo)) {
          this.storeInfo = _.cloneDeep(storeInfo);
        }
      })
    );

    this.subscriptions.push(
      this.store
        .select("platformInfo")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && !_.isEqual(state.device_id, this.device_id)) {
            this.device_id = _.cloneDeep(state.device_id);
          }
        })
    );
  }

  ngOnDestroy(): void {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }

    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach((sub: Subscription) => {
        sub.unsubscribe();
      });

      this.subscriptions = [];
    }

    if (this.checkGpsCouponRuleModal) {
      this.checkGpsCouponRuleModal.dismiss();
      this.checkGpsCouponRuleModal = null;
    }
  }

  usePoints(reward, store_id) {
    this.loading = true;
    this.dataStorageService
      .usePoints(
        {
          store_id: store_id,
          item: reward,
        },
        this.mo_token
      )
      .subscribe({
        next: async (res: any) => {
          if (!res || !res.success) {
            const alert = await this.alertController.create({
              header: this.translate.instant("alert"),
              message:
                res && res.comment_id
                  ? this.translate.instant(res.comment_id)
                  : this.translate.instant("errorMessages.general_error"),
              backdropDismiss: false,
              buttons: ["OK"],
            });
            await alert.present();
            this.loading = false;
          } else {
            this.fetchCustomerPoints();
            this.successResponse = res;

            if (this.successResponse) {
              const alert = await this.alertController.create({
                header: this.successResponse.comment_id
                  ? this.translate.instant(this.successResponse.comment_id)
                  : this.translate.instant(this.successResponse.comments),

                backdropDismiss: false,
                buttons: ["OK"],
              });

              this.usePointsAnimation = true;
              await alert.present();
              await alert.onDidDismiss().then(() => {
                this.modalController.dismiss("coupon_get_success");
              });
            }
            // console.log("user points res", res);
          }
        },
        error: async (err) => {
          this.loading = false;
          const alert = await this.alertController.create({
            header: this.translate.instant("alert"),
            message: this.translate.instant(
              "errorMessages.problem_reaching_server"
            ),

            buttons: ["OK"],
          });
          await alert.present();
          return err;

          console.log("error updating the information");
        },
      });
  }

  openInBrowser(reward) {
    window.open(reward.third_party_url, "_system", "location=yes");
  }

  fetchCustomerPoints() {
    this.dataStorageService
      .fetchCustomerPoint(
        this.mo_token,
        this.groupApp &&
          this.groupApp.points &&
          this.groupApp.points.loyaltyCardLevelsActive
      )
      .subscribe({
        next: async (res: any) => {
          if (res && res.success) {
            let userPoints = res.data;
            this.store.dispatch(
              new PointsActions.SetUserPoints(_.cloneDeep(userPoints))
            );
            let tempGroupApp = _.cloneDeep(this.groupApp);

            _.each(tempGroupApp.points.rewards, (reward) => {
              if (reward.points <= userPoints.pointsSystem.active) {
                reward.user_points_percent = 100;
              } else {
                var percent =
                  (userPoints.pointsSystem.active / reward.points) * 100;
                reward.user_points_percent = percent.toFixed();
              }

              //console.log("reward", reward.user_points_percent);
            });
            this.store.dispatch(
              new PointsActions.SetRewards(
                _.cloneDeep(tempGroupApp.points.rewards)
              )
            );
            let highestReward = _.maxBy(
              tempGroupApp.points.rewards,
              "user_points_percent"
            );
            this.store.dispatch(
              new PointsActions.SetHighestReward(_.cloneDeep(highestReward))
            );
          } else if (!res || !res.success) {
            const alert = await this.alertController.create({
              header: this.translate.instant("alert"),
              message:
                res && res.comment_id
                  ? this.translate.instant(res.comment_id)
                  : this.translate.instant("errorMessages.general_error"),
              backdropDismiss: false,
              buttons: ["OK"],
            });
            await alert.present();
          }
        },
        error: async (error) => {
          const alert = await this.alertController.create({
            header: this.translate.instant("alert"),
            message: this.translate.instant(
              "errorMessages.problem_reaching_server"
            ),

            buttons: ["OK"],
          });
          await alert.present();
          this.loading = false;
        },
      });
  }

  async usePointsConfirm(reward) {
    if (this.stores && this.stores.length === 1) {
      this.usePoints(reward, this.currentStoreId);
    } else if (this.stores && this.stores.length > 1) {
      const storesForSelection = _.cloneDeep(this.stores);
      _.each(storesForSelection, (store) => {
        if (
          store &&
          reward &&
          (!reward.only_kiosk ||
            (store.dm_kiosk_app_active &&
              !(
                this.groupApp &&
                this.groupApp.design_settings &&
                this.groupApp.design_settings.dm_kiosk_app_disable_mobileorder
              ))) &&
          (!reward.store_id ||
            reward.store_id === "all" ||
            (reward.store_id === store.store_id &&
              (reward.product_id ||
                (reward.products && reward.products.length))))
        ) {
          store.hasTheOffer = true;
        }
      });

      const selected_store_id: any = await this.openSelectStoreModal(
        _.cloneDeep(storesForSelection)
      );

      if (selected_store_id) {
        this.usePoints(reward, selected_store_id);
      }
    }
  }

  async presentIonLoading() {
    let message = this.translate.instant("loading-please-wait");
    this.ionLoading = await this.loadingController.create({
      duration: 30000,
      message: message,
      cssClass: "ionicLoadingCss",
      keyboardClose: true,
    });

    return await this.ionLoading.present();
  }

  dismissIonLoading() {
    console.log("Loading dismissed!", this.ionLoading);
    if (this.ionLoading) {
      this.ionLoading.dismiss();
      this.ionLoading = null;
    }
  }

  async checkDeviceLocation(presentLoading) {
    if (presentLoading) {
      await this.presentIonLoading();
    }

    let distance: any;
    const distance_object: any =
      await this.checkLocationDistanceService.calculateDistanceFromGps(
        {
          lat: this.storeInfo.mapCenterStoreLat,
          lng: this.storeInfo.mapCenterStoreLng,
        },
        false
      );

    this.dismissIonLoading();
    console.log("distance_object", distance_object);
    if (distance_object && distance_object.distance) {
      distance = distance_object.distance;
    }

    if (distance) {
      if (distance <= 100) {
        return Promise.resolve({ message: "success" });
      } else {
        return Promise.resolve({
          message: "popups.check-gps-coupon-rule-modal.you-are-out-of-store",
          distance: distance_object.distance,
        });
      }
    } else {
      if (!distance_object || !distance_object.error) {
        return Promise.resolve({
          message:
            "popups.check-gps-coupon-rule-modal.something-went-wrong-try-again",
        });
      } else if (distance_object && distance_object.error) {
        if (
          distance_object.error ===
          "popups.check-gps-coupon-rule-modal.no-gps-enabled"
        ) {
          return Promise.resolve({
            message: "popups.check-gps-coupon-rule-modal.no-gps-enabled",
          });
        } else if (distance_object.error === "no-coordinates") {
          return Promise.resolve({
            message: "popups.check-gps-coupon-rule-modal.no-coordinates",
          });
        } else if (distance_object.error) {
          return Promise.resolve({ message: distance_object.error });
        } else {
          console.log("GPS no error");
        }
      }
    }
  }

  async useCouponRuleConfirm(coupon_rule) {
    if (coupon_rule.only_apps_redeem && !Capacitor.isNativePlatform()) {
      this.InfoMessageModal = await this.modalController.create({
        component: InfoMessageModalComponent,
        cssClass:
          window && window.innerWidth <= 420
            ? "infoMessagesModalMobileNoImage"
            : "infoMessagesModalBrowserNoImage",
        animated: true,
        componentProps: {
          infoMessage: {
            title: {
              el: "Πληροφορίες προσφοράς",
              en: "Offer information",
              de: "Angebotsinformationen",
            },
            description: {
              el: "Για να αποκτήσετε την προσφορά θα πρέπει να κατεβάσετε την mobile εφαρμογή στο κινητό σας!",
              en: "In order to redeem the coupon you have to download the mobile app!",
              de: "Um den Gutschein einzulösen, müssen Sie die mobile App herunterladen!",
            },
            download_app_btn: true,
          },
        },
        backdropDismiss: false,
      });

      await this.InfoMessageModal.present();
      await this.InfoMessageModal.onDidDismiss().then(async (data) => {
        this.InfoMessageModal = null;
      });

      return Promise.resolve({});
    }

    if (coupon_rule.gps_check) {
      const checkLocationResponse: any = await this.checkDeviceLocation(true);
      if (
        checkLocationResponse &&
        (!checkLocationResponse.message ||
          checkLocationResponse.message !== "success")
      ) {
        console.log("checkLocationResponse", checkLocationResponse);

        this.checkGpsCouponRuleModal = await this.modalController.create({
          component: CheckGpsCouponRuleModalComponent,
          cssClass: "check-gps-coupon-rule-modal",
          animated: true,
          componentProps: {
            message: checkLocationResponse.message,
            distance:
              checkLocationResponse.message ===
                "popups.check-gps-coupon-rule-modal.you-are-out-of-store" &&
              checkLocationResponse.distance
                ? checkLocationResponse.distance
                : null,
          },
          backdropDismiss: false,
        });

        let noLocation = false;

        await this.checkGpsCouponRuleModal.present();
        await this.checkGpsCouponRuleModal.onDidDismiss().then(async (data) => {
          this.checkGpsCouponRuleModal = null;
          if (!data || !data.data || data.data !== "success") {
            noLocation = true;
          }
        });

        if (noLocation) {
          return Promise.resolve({});
        }
      }
    }

    if (
      coupon_rule.only_apps_redeem &&
      Capacitor.isNativePlatform() &&
      coupon_rule.checkDeviceIdCouponStatus
    ) {
      //check with device_id
      if (!this.device_id) {
        //TODO ERROR
        return Promise.resolve({});
      }
    }

    if (this.stores && this.stores.length === 1) {
      this.useCouponRule(coupon_rule, this.currentStoreId, this.device_id);
    } else if (this.stores && this.stores.length > 1) {
      const storesForSelection = _.cloneDeep(this.stores);

      _.each(storesForSelection, (store) => {
        if (
          store &&
          coupon_rule &&
          (!coupon_rule.only_kiosk || store.dm_kiosk_app_active) &&
          coupon_rule.store_id &&
          (coupon_rule.store_id === "all" ||
            coupon_rule.store_id === store.store_id)
        ) {
          store.hasTheOffer = true;
        }
      });

      const selected_store_id: any = await this.openSelectStoreModal(
        _.cloneDeep(storesForSelection)
      );

      if (selected_store_id) {
        this.useCouponRule(coupon_rule, selected_store_id, this.device_id);
      }
    }
  }

  async openSelectStoreModal(storesForSelection) {
    return new Promise(async (resolve, reject) => {
      let cssClass = "selectStorePopupCss";
      if (
        storesForSelection &&
        storesForSelection.length > 1 &&
        storesForSelection.length <= 3
      ) {
        cssClass = "selectStorePopupCssThreeStores";
      } else if (storesForSelection && storesForSelection.length > 3) {
        cssClass = "selectStorePopupCssMultipleStores";
      }
      const modal = await this.modalController.create({
        component: SelectStorePopupComponent,
        componentProps: {
          storesForSelection: _.cloneDeep(storesForSelection),
          onlyReturnSelectedStoreId: true,
        },
        cssClass: cssClass,
        backdropDismiss: false,
      });

      await modal.present();

      await modal.onDidDismiss().then((data) => {
        if (data && data.data) {
          resolve(_.cloneDeep(data.data));
        } else {
          resolve(null);
        }
      });
    });
  }

  useCouponRule(coupon_rule, store_id, device_id) {
    this.loading = true;
    console.log("rule", coupon_rule);
    this.dataStorageService
      .useCouponRule(
        {
          store_id: store_id,
          coupon_rule_id: coupon_rule.id,
          device_id: device_id,
        },
        this.mo_token
      )
      .subscribe({
        next: async (res: any) => {
          if (!res || !res.success) {
            const alert = await this.alertController.create({
              header: this.translate.instant("alert"),
              message:
                res && res.comment_id
                  ? this.translate.instant(res.comment_id)
                  : this.translate.instant("errorMessages.general_error"),
              backdropDismiss: false,
              buttons: ["OK"],
            });
            await alert.present();
            this.loading = false;
            if (
              res &&
              res.comment_id === "active_coupon_exists" &&
              res.coupon_timestamp
            ) {
              await alert.onDidDismiss().then((data) => {
                this.modalController.dismiss({
                  openSpecificCouponTimestamp: res.coupon_timestamp,
                });
              });
            }
          } else {
            this.fetchCustomerPoints();
            this.successResponse = res;
            this.usePointsAnimation = true;
            if (this.successResponse) {
              const alert = await this.alertController.create({
                header: this.successResponse.comment_id
                  ? this.translate.instant(this.successResponse.comment_id)
                  : this.translate.instant(this.successResponse.comments),

                backdropDismiss: false,
                buttons: ["OK"],
              });

              this.usePointsAnimation = true;
              await alert.present();
              await alert.onDidDismiss().then(() => {
                this.modalController.dismiss("navigate_coupons_tab");
              });
            }
            // console.log("user points res", res);
          }
        },
        error: async (err) => {
          const alert = await this.alertController.create({
            header: this.translate.instant("alert"),
            message: this.translate.instant(
              "errorMessages.problem_reaching_server"
            ),

            buttons: ["OK"],
          });
          await alert.present();
          this.loading = false;
          return err;

          console.log("error updating the information");
        },
      });
  }

  animationCreated(ev) {
    this.usePointsAnimationInstance = ev;
    this.usePointsAnimationInstance.setSpeed(1.3);
  }

  async onAnimationError(ev) {
    if (this.successResponse) {
      const alert = await this.alertController.create({
        header: this.successResponse.comment_id
          ? this.translate.instant(this.successResponse.comment_id)
          : this.translate.instant(this.successResponse.comments),

        backdropDismiss: false,
        buttons: ["OK"],
      });

      this.usePointsAnimation = true;
      await alert.present();
      alert.onDidDismiss().then(() => {
        this.modalController.dismiss();
      });
    }
  }

  async showTheCoupon(ruleAlreadyUsedCouponTimestamp) {
    if (_.isString(ruleAlreadyUsedCouponTimestamp)) {
      //string for one store group
      this.modalController.dismiss({
        openSpecificCouponTimestamp: ruleAlreadyUsedCouponTimestamp,
      });
    } else if (
      _.isArray(ruleAlreadyUsedCouponTimestamp) &&
      ruleAlreadyUsedCouponTimestamp.length
    ) {
      //array for multiple stores group
      const storesForSelection = [];
      _.each(ruleAlreadyUsedCouponTimestamp, (obj) => {
        if (obj.store_id && obj.timestamp) {
          const store = _.cloneDeep(
            _.find(this.stores, { store_id: obj.store_id })
          );
          if (store) {
            store.hasTheOffer = true;
            storesForSelection.push(store);
          }
        }
      });

      const store_id = await this.openSelectStoreModal(storesForSelection);

      if (store_id) {
        const obj = _.find(_.cloneDeep(ruleAlreadyUsedCouponTimestamp), {
          store_id: store_id,
        });
        if (obj) {
          this.modalController.dismiss({
            openSpecificCouponTimestamp: obj.timestamp,
          });
        }
      }
    }
  }

  presentMagicLoginPopup() {
    this.modalController.dismiss("showLoginRegister");
  }

  async animationComplete(ev) {
    this.usePointsAnimationInstance.stop();
    this.usePointsAnimation = false;
  }
}
