import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AlertController, ToastController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { data } from "autoprefixer";
declare let window: any;
import * as _ from "lodash";
import * as OneSignalMessagesActions from "../../store/oneSignalMessages/oneSignalMessages.actions";
import * as fromApp from "../../store/app.reducer";
import { first } from "rxjs";
import { Capacitor } from "@capacitor/core";
import OneSignal from "onesignal-cordova-plugin";

@Injectable({
  providedIn: "root",
})
export class OneSignalService {
  constructor(
    private alertController: AlertController,
    private translate: TranslateService,
    private router: Router,
    private store: Store<fromApp.AppState>,
    private toastController: ToastController
  ) {}

  initOneSignalBrowser(groupApp) {
    try {
      window.OneSignal = window.OneSignal || [];

      if (!_.isEmpty(window.OneSignal)) {
        window.OneSignal.push([
          "init",
          {
            path: "/",
            autoRegister: false,
            appId: groupApp.oneSignalAppId,
            safari_web_id: groupApp.safari_web_id,
            notifyButton: {
              enable: false,
            },
          },
        ]);
      }
    } catch (err) {
      console.log("onesignal", err);
      window.OneSignal = null;
    }
  }

  initOneSignalMobile(groupApp) {
    console.log(
      "menu",
      "Initialize OneSignal for mobile apps",
      groupApp.oneSignalAppId
    );
    try {
      //118430501239

      OneSignal.setAppId(groupApp.oneSignalAppId);

      OneSignal.setNotificationOpenedHandler((jsonData) => {
        console.log("notificationOpenedCallback: ", jsonData);
        this.pushNotificationPopup(jsonData);
      });
    } catch (err) {
      console.log("onesignal err", err);
    }
  }

  /**
   *
   * @param jsonData
   */
  private pushNotificationPopup = (jsonData) => {
    this.store
      .select("auth")
      .pipe(first())
      .subscribe((authState) => {
        if (authState) {
          let currentUser = _.cloneDeep(authState.user);
          var payload;
          if (
            jsonData &&
            jsonData.notification &&
            jsonData.notification.additionalData
          ) {
            payload = jsonData.notification;
          } else if (jsonData && jsonData.payload) {
            payload = jsonData.payload;
          } else if (
            jsonData &&
            jsonData.notification &&
            jsonData.notification.payload
          ) {
            payload = jsonData.notification.payload;
          }
          if (
            payload &&
            payload.additionalData &&
            payload.additionalData.reason === "orderStatusUpdate"
          ) {
            this.presentOneSignalAlert(
              payload.body,
              payload.additionalData.title,
              () => {
                if (this.router.url === "/orders") {
                  let message = _.cloneDeep(payload);
                  message.updatedTimestamp = Date.now();
                  this.store.dispatch(
                    new OneSignalMessagesActions.SetOneSignalMessage(
                      _.cloneDeep(message)
                    )
                  );
                } else {
                  this.router.navigateByUrl("/orders", {
                    replaceUrl: true,
                  });
                }
                // } else {
                // this.presentErrorToast(
                //   this.translate.instant(
                //     "orders.order-item.you-must-be-logged-in-to-see-your-orders"
                //   ),
                //   "danger"
                // );
              }
            );
          } else if (
            payload &&
            payload.additionalData &&
            payload.additionalData.reason === "reviewNotification"
          ) {
            if (this.router.url === "/orders") {
              let message = _.cloneDeep(payload);
              message.updatedTimestamp = Date.now();
              this.store.dispatch(
                new OneSignalMessagesActions.SetOneSignalMessage(
                  _.cloneDeep(message)
                )
              );
            } else {
              this.router.navigateByUrl("/orders?openReviewFirstOrder=true", {
                replaceUrl: true,
              });
            }
          } else if (
            payload &&
            payload.additionalData &&
            payload.additionalData.reason === "reviewAnswer"
          ) {
            let message = _.cloneDeep(payload);
            message.updatedTimestamp = Date.now();
            this.router.navigateByUrl("/ratings", {
              replaceUrl: true,
            });
          } else {
            console.log(jsonData);
          } //This is the alert when the user is notified in order to create a review
        }
      })
      .unsubscribe();
  };

  OneSignalPrompt() {
    if (Capacitor.isNativePlatform()) {
      try {
        OneSignal.promptForPushNotificationsWithUserResponse(
          true,
          (accepted) => {
            console.log("User accepted notifications: " + accepted);
            if (accepted) {
              OneSignal.provideUserConsent(accepted);
              OneSignal.disablePush(false);
            } else {
              OneSignal.provideUserConsent(accepted);
              OneSignal.disablePush(false);
            }
          }
        );
      } catch (err) {
        console.log("one signal err", err);
      }
    } else if (window.OneSignal) {
      window.OneSignal.push([
        "getNotificationPermission",
        (permission) => {
          console.log("menu", "Site Notification Permission:", permission);
        },
      ]);

      window.OneSignal.push([
        "isPushNotificationsEnabled",
        (enabled) => {
          console.log("isPushNotificationsEnabled", enabled);
          if (!enabled) {
            window.OneSignal.push(() => {
              window.OneSignal.registerForPushNotifications();
              window.OneSignal.push(["setSubscription", true]);
            });
          }
        },
      ]);
    } else {
      console.warn("OneSignalPrompt warning");
    }
  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: 5000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }

  async presentOneSignalAlert(message, title, viewHandler) {
    const alert = await this.alertController.create({
      header: title,
      message: message,
      buttons: [
        {
          text: "OK",
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel");
          },
        },
        {
          text: this.translate.instant("view"),
          handler: viewHandler,
        },
      ],
    });

    await alert.present();
  }
}
