import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  auditTime,
  distinct,
  distinctUntilChanged,
  first,
} from "rxjs/operators";
import * as fromApp from "../../store/app.reducer";
import * as _ from "lodash";
import * as CurrentStoreActions from "../../store/current-store/current-store.actions";
import { AlertController, ModalController, Platform } from "@ionic/angular";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import * as DeliveryMethodAddressActions from "../../store/deliveryMethodAddress/deliveryMethodAddress.actions";
import { AnalyticsServiceService } from "src/app/services/analitycsService/analytics-service.service";

@Component({
  selector: "app-select-store-image-popup",
  templateUrl: "./select-store-image-popup.component.html",
  styleUrls: ["./select-store-image-popup.component.scss"],
})
export class SelectStoreImagePopupComponent implements OnInit {
  @Input() showValidStores;
  @Input() settingsPopupMode: boolean;
  public stores: any;
  public validStores;
  public orderPickup: boolean;
  public dineIn: boolean;
  renderedValidStores;
  public selectedLangugage: any;
  public windowDimensions: any;

  public deliveryManagerPath = "https://images.deliverymanager.gr/";

  private currentStoreId: any;
  private navigationOnDeliveryMethodChange;
  private subscription5: Subscription;
  private subscription: Subscription;
  private subscription2: Subscription;
  private subscription3: Subscription;
  private subscription4: Subscription;
  private unsubscribeBackEvent: Subscription;
  constructor(
    private store: Store<fromApp.AppState>,
    private modalCtrl: ModalController,
    private router: Router,
    private translateService: TranslateService,
    private alertController: AlertController,
    private analyticsService: AnalyticsServiceService,
    private platform: Platform
  ) {}

  ngOnInit() {
    console.log("showValidStores", this.showValidStores);
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );
    if (this.showValidStores) {
      this.subscription = this.store
        .select("stores")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (state && state.stores && !_.isEqual(state.stores, this.stores)) {
            this.stores = _.cloneDeep(state.stores);

            if (!this.subscription2) {
              this.subscription2 = this.store
                .select("deliveryMethodAddress")
                .pipe(distinctUntilChanged())
                .pipe(auditTime(100))
                .subscribe((state) => {
                  if (
                    state &&
                    !_.isEqual(state.validStores, this.validStores)
                  ) {
                    this.validStores = _.cloneDeep(state.validStores);
                    this.calculateStores();
                  }

                  if (
                    state &&
                    !_.isEqual(
                      state.navigationOnDeliveryMethodChange,
                      this.navigationOnDeliveryMethodChange
                    )
                  ) {
                    this.navigationOnDeliveryMethodChange = _.cloneDeep(
                      state.navigationOnDeliveryMethodChange
                    );
                  }
                });
            }
          }
        });
    } else {
      this.subscription2 = this.store
        .select("stores")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (state && state.stores && !_.isEqual(state.stores, this.stores)) {
            this.stores = _.cloneDeep(state.stores);
            console.log(this.stores);
          }
        });
    }
    this.subscription3 = this.store
      .select("currentStore")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (
          state &&
          state.currentStoreId &&
          !_.isEqual(state.currentStoreId, this.currentStoreId)
        ) {
          this.currentStoreId = _.cloneDeep(state.currentStoreId);
        }
      });

    this.subscription5 = this.store
      .select("windowInfo")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (
          state &&
          state.windowDimensions &&
          !_.isEqual(state.windowDimensions, this.windowDimensions)
        ) {
          this.windowDimensions = _.cloneDeep(state.windowDimensions);
        }
      });

    this.subscription4 = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (
          state &&
          state.selectedLangugage &&
          !_.isEqual(state.selectedLangugage, this.selectedLangugage)
        ) {
          this.selectedLangugage = _.cloneDeep(state.selectedLangugage);
        }
      });
  }

  calculateStores() {
    this.renderedValidStores = [];

    _.each(this.stores, (store) => {
      _.each(this.validStores, (validStores) => {
        if (store.store_id === validStores.store_id) {
          this.renderedValidStores.push(_.cloneDeep(store));
        }
      });
    });

    console.log("renderedValidStores", this.renderedValidStores);
  }

  selectStore(storeID) {
    if (this.showValidStores) {
      if (storeID) {
        this.modalCtrl.dismiss({ selectedStoreId: storeID });
      }
    } else {
      this.store
        .select("cart")
        .pipe(first())
        .subscribe(async (state) => {
          if (
            state &&
            state.products &&
            state.products.length > 0 &&
            !_.isEqual(this.currentStoreId, storeID)
          ) {
            const alert = await this.alertController.create({
              cssClass: "my-custom-class",
              header: this.translateService.instant("alert"),
              message: this.translateService.instant(
                "catalog.if-you-change-store-your-cart-products-will-be-deleted-do-you-want-to-continue"
              ),
              buttons: [
                {
                  text: this.translateService.instant("cancel"),
                  role: "cancel",
                  cssClass: "secondary",
                  handler: (blah) => {},
                },
                {
                  text: this.translateService.instant("yes"),
                  handler: () => {
                    if (storeID) {
                      console.log("set store id select store modal");
                      this.store.dispatch(
                        new CurrentStoreActions.SetCurrentStoreId(storeID)
                      );
                    }
                    this.modalCtrl.dismiss();
                    this.router.navigateByUrl("/catalog", {
                      replaceUrl: true,
                    });
                  },
                },
              ],
            });
            await alert.present();
          } else if (
            (state &&
              (!state.products ||
                (state.products && state.products.length === 0))) ||
            _.isEqual(this.currentStoreId, storeID)
          ) {
            if (storeID) {
              console.log("set store id select store modal");
              this.store.dispatch(
                new CurrentStoreActions.SetCurrentStoreId(storeID)
              );
            }
            this.modalCtrl.dismiss();
            this.router.navigateByUrl("/catalog", {
              replaceUrl: true,
            });
          }
        })
        .unsubscribe();
    }
  }

  selectTakeAwayMethod() {
    console.log("take away selected", this.router.url);
    this.store.dispatch(
      new DeliveryMethodAddressActions.setSelectedAddress(null)
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setCheckSelectedAddress(null)
    );
    this.store.dispatch(new DeliveryMethodAddressActions.setOrderPickup(true));
    this.store.dispatch(new DeliveryMethodAddressActions.setDineIn(false));
    this.store.dispatch(
      new DeliveryMethodAddressActions.setSelectedDineInTable(_.cloneDeep(null))
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setValidDeliveryAreas(null)
    );

    this.analyticsService.dmAnalyticsMessage(
      "takeaway_method",
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );

    if (
      this.router.url === "/homepage" ||
      this.router.url === "/ratings" ||
      this.router.url === "/orders" ||
      this.router.url === "/points"
    ) {
      this.router.navigateByUrl("/catalog", {
        replaceUrl: true,
      });
    }
    if (navigator.cookieEnabled) {
      let deliveryMethodAddressObj: any = localStorage.getItem(
        "deliveryMethodAddress"
      );
      if (deliveryMethodAddressObj) {
        deliveryMethodAddressObj = JSON.parse(deliveryMethodAddressObj);
        if (deliveryMethodAddressObj) {
          deliveryMethodAddressObj.orderPickup = true;
          deliveryMethodAddressObj.dineIn = false;
          deliveryMethodAddressObj.selectedAddress = null;
          deliveryMethodAddressObj.selectedDineInTable = null;
        } else {
          deliveryMethodAddressObj = {
            orderPickup: true,
            dineIn: false,
          };
        }
      } else {
        deliveryMethodAddressObj = {
          orderPickup: true,
          dineIn: false,
        };
      }

      window.localStorage.setItem(
        "deliveryMethodAddress",
        JSON.stringify(deliveryMethodAddressObj)
      );
    }
  }

  settingsPopupModeSelectStore(storeID) {
    this.store
      .select("cart")
      .pipe(first())
      .subscribe(async (state) => {
        if (
          state &&
          state.products &&
          state.products.length > 0 &&
          !_.isEqual(this.currentStoreId, storeID)
        ) {
          const alert = await this.alertController.create({
            cssClass: "my-custom-class",
            header: this.translateService.instant("alert"),
            message: this.translateService.instant(
              "catalog.if-you-change-store-your-cart-products-will-be-deleted-do-you-want-to-continue"
            ),
            buttons: [
              {
                text: this.translateService.instant("cancel"),
                role: "cancel",
                cssClass: "secondary",
                handler: (blah) => {},
              },
              {
                text: this.translateService.instant("yes"),
                handler: () => {
                  if (storeID) {
                    console.log("set store id order now settings modal");
                    this.store.dispatch(
                      new CurrentStoreActions.SetCurrentStoreId(storeID)
                    );
                  }
                  this.modalCtrl.dismiss();
                  // this.router.navigate(["catalog"]);
                  this.selectTakeAwayMethod();
                },
              },
            ],
          });
          await alert.present();
        } else if (
          (state &&
            (!state.products ||
              (state.products && state.products.length === 0))) ||
          _.isEqual(this.currentStoreId, storeID)
        ) {
          if (storeID) {
            console.log("set store id order now settings modal");
            this.store.dispatch(
              new CurrentStoreActions.SetCurrentStoreId(storeID)
            );
          }
          this.modalCtrl.dismiss();
          //this.router.navigate(["catalog"]);
          this.selectTakeAwayMethod();
        }
      })
      .unsubscribe();
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  ngOnDestroy() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.subscription2) {
      this.subscription2.unsubscribe();
    }
    if (this.subscription3) {
      this.subscription3.unsubscribe();
    }

    if (this.subscription4) {
      this.subscription4.unsubscribe();
    }

    if (this.subscription5) {
      this.subscription5.unsubscribe();
    }
  }
}
