import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-scanning-instructions',
  templateUrl: './scanning-instructions.component.html',
  styleUrls: ['./scanning-instructions.component.scss'],
})
export class ScanningInstructionsComponent implements OnInit {

  constructor(public modalCtrl: ModalController) {}

  ngOnInit() {}

}
