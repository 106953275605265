import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import { Subscription } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import * as fromApp from "../../../../../store/app.reducer";
import * as selectors from "../../../../../store/app.selectors";

@Component({
  selector: "app-image-radio-choice",
  templateUrl: "./image-radio-choice.component.html",
  styleUrls: ["./image-radio-choice.component.scss"],
})
export class ImageRadioChoiceComponent implements OnInit, OnDestroy, OnChanges {
  @Input() selectedProductId: any;
  @Input() bundleMode: any;
  @Input() option_id;
  @Input() choice_id;
  @Input() selectedProductPromo: any;
  @Input() selectedProductCategory: any;
  @Input() alt_dinein_active;
  @Input() groupStoreData: any;
  public choice: any;
  public option: any;
  public orderPickup;
  public dineIn;
  public selectedDineInTable;
  @Input() imagePath;
  private optionSubscription: Subscription;
  private subscription: Subscription;
  @Output() choiceChanged = new EventEmitter();

  constructor(private store: Store<fromApp.AppState>) {}

  selectOption() {
    if (this.optionSubscription) {
      this.optionSubscription.unsubscribe();
    }
    this.optionSubscription = this.store
      .select(
        selectors.getOptionByOptionId(this.selectedProductId, this.option_id)
      )
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (!_.isEqual(this.option, state)) {
          this.option = _.cloneDeep(state);
          if (this.option && this.option.choices && this.choice_id) {
            this.choice = _.cloneDeep(
              _.find(this.option.choices, { choice_id: this.choice_id })
            );
          }
        }
      });
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (
      simpleChanges &&
      simpleChanges.option_id &&
      simpleChanges.option_id.currentValue !==
        simpleChanges.option_id.previousValue
    ) {
      this.selectOption();
    }
  }

  ngOnInit() {
    this.subscription = this.store
      .select("deliveryMethodAddress")

      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (state && !_.isEqual(this.dineIn, state.dineIn)) {
          this.dineIn = _.cloneDeep(state.dineIn);
        }
        if (state && !_.isEqual(this.orderPickup, state.orderPickup)) {
          this.orderPickup = _.cloneDeep(state.orderPickup);
        }
        if (
          state &&
          !_.isEqual(this.selectedDineInTable, state.selectedDineInTable)
        ) {
          this.selectedDineInTable = _.cloneDeep(state.selectedDineInTable);
        }
      });
    this.selectOption();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.optionSubscription) {
      this.optionSubscription.unsubscribe();
    }
  }
}
