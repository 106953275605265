import { Action } from "@ngrx/store";

export const SET_CART_PRODUCTS = "[CART] Set Cart Products";
export const CLEAR_CART = "[CART] Clear Cart";
export const ADD_UPDATE_CART_PRODUCT_ITEM =
  "[CART] Add Update Cart Product Item";
export const DELETE_CART_PRODUCT_ITEM = "[CART] Delete Cart Product Item";
export const SET_VALIDATING_CART_LOADING = "[CART] Set Validating Cart Loading";
export const SET_PROMO_CART_NO_COUPON_TIMESTAMP =
  "[CART] Set Promo Cart No Coupon Timestamp";
export const SET_PREORDER_ONLY_NO_COUPON_TIMESTAMP =
  "[CART] Set Preorder Only No Coupon Timestamp";
export const SET_ONLINE_PAYMENT_ONLY_NO_COUPON_TIMESTAMP =
  "[CART] Set Online Payment Only No Coupon Timestamp";

export const SET_ONLY_AVAILABLE_FOR_PREORDER_CART_PRODUCTS =
  "[CART] Set Only Available For Preorder Cart Products";

export class SetCartProducts implements Action {
  readonly type = SET_CART_PRODUCTS;
  constructor(public payload: any) {}
}

export class SetValidatingCartLoading implements Action {
  readonly type = SET_VALIDATING_CART_LOADING;
  constructor(public payload: any) {}
}

export class AddUpdateCartProductItem implements Action {
  readonly type = ADD_UPDATE_CART_PRODUCT_ITEM;
  constructor(public payload: any) {}
}

export class DeleteCartProductItem implements Action {
  readonly type = DELETE_CART_PRODUCT_ITEM;
  constructor(public payload: any) {}
}

export class SetPromoCartNoCouponTimestamp implements Action {
  readonly type = SET_PROMO_CART_NO_COUPON_TIMESTAMP;
  constructor(public payload: any) {}
}

export class setPreorderOnlyNoCouponTimestamp implements Action {
  readonly type = SET_PREORDER_ONLY_NO_COUPON_TIMESTAMP;
  constructor(public payload: any) {}
}

export class setOnlinePaymentOnlyNoCouponTimestamp implements Action {
  readonly type = SET_ONLINE_PAYMENT_ONLY_NO_COUPON_TIMESTAMP;
  constructor(public payload: any) {}
}

export class SetOnlyAvailableForPreorderCartProducts implements Action {
  readonly type = SET_ONLY_AVAILABLE_FOR_PREORDER_CART_PRODUCTS;
  constructor(public payload: any) {}
}

export class ClearCart implements Action {
  readonly type = CLEAR_CART;
  constructor() {}
}
export type CartActions =
  | SetCartProducts
  | ClearCart
  | SetPromoCartNoCouponTimestamp
  | setPreorderOnlyNoCouponTimestamp
  | setOnlinePaymentOnlyNoCouponTimestamp
  | AddUpdateCartProductItem
  | DeleteCartProductItem
  | SetOnlyAvailableForPreorderCartProducts
  | SetValidatingCartLoading;
