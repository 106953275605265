<!-- ngif if you want to show choices or not in modal -->
<ion-list
  *ngIf="option && option.option_id"
  [id]="option.option_id"
  class="ion-no-padding"
>
  <ion-item *ngIf="option && option.title && option.always_open" lines="none">
    <ion-row style="width: 100%">
      <ion-col size="12" class="ion-no-padding">
        <ion-label
          class="ion-text-wrap"
          [class]="
            validationObject && validationObject.valid == false
              ? 'notValidOptionMargin'
              : ''
          "
        >
          <strong>
            {{ option.title }}
          </strong>
          <p
            color="danger"
            style="font-size: smaller"
            *ngIf="
              option_stepper_mode &&
              option.dependenciesVariable &&
              option.dependenciesVariable.length > 0 &&
              !option.dependentHidden &&
              !option.showDependent
            "
          >
            <ion-text color="danger">
              {{
                "product-details.options.checkbox-options.based-on-your-choices-you-cant-edit-this-option"
                  | translate
              }}</ion-text
            >
          </p>
          <span
            *ngIf="option.is_require && option.availableTotal"
            style="font-weight: bold"
            >*</span
          >

          <p *ngIf="option && option.description">{{ option.description }}</p>

          <div
            *ngIf="option && option.freeChoices && option.freeChoices !== '0'"
            style="font-size: small; font-weight: 700"
          >
            <ion-text
              [color]="
                option.optionData && option.optionData.freeChoices
                  ? 'complete'
                  : 'danger'
              "
            >
              {{
                "product-details.options.checkbox-options.there-are-free-choices"
                  | translate
              }} </ion-text
            >: ({{
              option.optionData && option.optionData.freeChoices
                ? option.optionData.freeChoices
                : 0
            }}
            / {{ option.freeChoices }})
          </div>

          <div
            style="font-size: small; font-weight: bold"
            size="12"
            *ngIf="
              option && option.freeChoices && option.freeChoicesBySelectionOrder
            "
          >
            <ion-text color="danger">
              {{
                "product-details.options.free-options-by-selections-order"
                  | translate
              }}
            </ion-text>
          </div>

          <div
            *ngIf="
              option.type === 'checkbox' &&
              option.is_require &&
              option.minChoices &&
              option.minChoices !== '0' &&
              option.minChoices !== '1' &&
              option.minChoices > 1 &&
              option.maxChoices !== option.minChoices
            "
            ng-class="{
              redColor: option.optionData.minChoices !== option.minChoices
            }"
            style="font-size: small; font-weight: 700"
          >
            {{
              "product-details.options.checkbox-options.minimum-choices-required"
                | translate
            }}: ({{
              option.optionData && option.optionData.minChoices
                ? option.optionData.minChoices
                : 0
            }}
            / {{ option.minChoices }})
          </div>

          <div
            *ngIf="
              option &&
              option.maxChoices &&
              option.maxChoices !== '0' &&
              option.maxChoices !== option.minChoices
            "
            ng-class="{
              redColor: option.optionData.maxChoices !== option.maxChoices
            }"
            style="font-size: small; font-weight: 700"
          >
            {{
              "product-details.options.checkbox-options.maximum-choices-allowed"
                | translate
            }}: ({{
              option.optionData && option.optionData.maxChoices
                ? option.optionData.maxChoices
                : 0
            }}
            / {{ option.maxChoices }})
          </div>

          <div
            *ngIf="
              option &&
              option.is_require &&
              option.minChoices &&
              option.minChoices !== '0' &&
              option.minChoices !== '1' &&
              option.maxChoices &&
              option.maxChoices !== '0' &&
              option.maxChoices === option.minChoices
            "
            ng-class="{
              redColor: option.optionData.minChoices !== option.minChoices
            }"
            style="font-size: small; font-weight: 700"
          >
            {{ option.maxChoices }}
            {{
              "product-details.options.checkbox-options.choices-required"
                | translate
            }}: ({{
              option.optionData && option.optionData.minChoices
                ? option.optionData.minChoices
                : 0
            }}
            / {{ option.minChoices }})
          </div>

          <div
            style="font-size: small; float: right; font-weight: bold"
            *ngIf="
              option &&
              option.choices &&
              option.showSumChoicesWeight &&
              choicesSumWeight &&
              quantityInChoicesKind
            "
          >
            <ion-text>{{ "total" | translate }}:</ion-text>
            {{ choicesSumWeight }} {{ quantityInChoicesKind }}
          </div>
        </ion-label>
      </ion-col>
      <ion-col
        size="12"
        class="ion-no-padding"
        *ngIf="
          option.promo || option.pickup || option.tags || !option.availableTotal
        "
      >
        <app-product-option-tags
          [selectedProductPromo]="selectedProductPromo"
          [groupStoreData]="groupStoreData"
          [optionItem]="option"
        ></app-product-option-tags>
      </ion-col>

      <ion-col
        size="12"
        class="ion-text-right"
        *ngIf="validationObject && validationObject.errorArray.length"
      >
        <ion-badge color="danger">
          <ion-text style="font-size: smaller">
            {{ validationObject.errorArray[0] }}
          </ion-text>
        </ion-badge>
      </ion-col>
      <ion-col
        class="ion-text-right"
        size="12"
        *ngIf="
          selectedProductFreeParentOption &&
          (!option.dependenciesVariable ||
            option.dependenciesVariable.length === 0) &&
          option.parent_free &&
          option.option_parent_total_price === 0
        "
      >
        <ion-badge color="danger">
          <ion-text>
            {{ "product-details.options.free" | translate }}
          </ion-text>
        </ion-badge>
      </ion-col>

      <ion-col
        class="ion-text-right"
        size="12"
        *ngIf="
          selectedProductFreeParentOption &&
          (!option.dependenciesVariable ||
            option.dependenciesVariable.length === 0) &&
          option.parent_free &&
          option.option_parent_total_price &&
          option.option_total_price &&
          toFloat(option.option_total_price) >
            toFloat(option.option_parent_total_price)
        "
      >
        <ion-badge color="danger">
          <ion-text>
            {{ option.option_parent_total_price }}€ ({{
              selectedProductParentDiscount
            }}% {{ "discount" | translate }})
          </ion-text>
        </ion-badge>
      </ion-col>
    </ion-row>

    <!-- <ion-button
      (click)="onSelectAll()"
      color="dark"
      size="medium"
      slot="end"
      fill="clear"
      ><ion-icon
        slot="icon-only"
        *ngIf="!selectAllChoices"
        src="assets/ionicons/square-outline.svg"
      ></ion-icon
      ><ion-icon
        slot="icon-only"
        *ngIf="selectAllChoices"
        src="assets/ionicons/checkbox-outline.svg"
      ></ion-icon
    ></ion-button> -->
  </ion-item>

  <app-checkbox-option-open-choices
    *ngIf="option && option.always_open"
    [option_id]="option_id"
    (optionChange)="changedOption($event)"
    [selectedProductPromo]="selectedProductPromo"
    [bundleMode]="bundleMode"
    [selectedProductCategory]="selectedProductCategory"
    [selectedProductId]="selectedProductId"
    [productName]="productName"
    [groupStoreData]="groupStoreData"
  ></app-checkbox-option-open-choices>
  <app-checkbox-option-closed-choices
    *ngIf="option && !option.always_open"
    (optionChange)="changedOption($event)"
    [optionValidationObject]="validationObject"
    [must_select_something]="must_select_something"
    [product_id]="product_id"
    [selectedProductId]="selectedProductId"
    [option_id]="option_id"
    [bundleMode]="bundleMode"
    [productName]="productName"
    [selectedProductPromo]="selectedProductPromo"
    [selectedProductCategory]="selectedProductCategory"
    [selectedProductParentDiscount]="selectedProductParentDiscount"
    [selectedProductFreeParentOption]="selectedProductFreeParentOption"
    [groupStoreData]="groupStoreData"
  ></app-checkbox-option-closed-choices>
</ion-list>
