import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import { Subscription } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import * as fromApp from "../../../store/app.reducer";
import * as selectors from "../../../store/app.selectors";

@Component({
  selector: "app-field-option",
  templateUrl: "./field-option.component.html",
  styleUrls: ["./field-option.component.scss"],
})
export class FieldOptionComponent implements OnInit, OnDestroy, OnChanges {
  @Input() selectedProductId: any;
  @Input() productName: any;
  @Input() bundleMode: any;
  @Input() option_id: any;
  @Input() do_not_show_comments_option;
  @Input() groupStoreData: any;
  public option: any;

  @Output() fieldOptionChanged = new EventEmitter();
  @Input() selectedProductPromo: any;
  @Input() selectedProductCategory: any;
  private optionSub: Subscription;
  private groupSub: Subscription;
  constructor(private store: Store<fromApp.AppState>) {}

  ngOnInit() {
    this.selectOption();
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (
      simpleChanges &&
      simpleChanges.option_id &&
      simpleChanges.option_id.currentValue !==
        simpleChanges.option_id.previousValue
    ) {
      if (this.optionSub) {
        this.optionSub.unsubscribe();
      }
      this.selectOption();
    }
  }

  selectOption() {
    this.optionSub = this.store
      .select(
        selectors.getOptionByOptionId(this.selectedProductId, this.option_id)
      )
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (!_.isEqual(this.option, state)) {
          this.option = _.cloneDeep(state);
        }
      });
  }

  fieldOptionChange(ev) {
    this.fieldOptionChanged.emit(this.option);
  }

  ngOnDestroy() {
    if (this.optionSub) {
      this.optionSub.unsubscribe();
    }
  }
}
