import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";
import { Subscription, auditTime, distinctUntilChanged } from "rxjs";
import * as _ from "lodash";
import { AnimationOptions } from "ngx-lottie";

@Component({
  selector: "app-delivery-cost-info-modal",
  templateUrl: "./delivery-cost-info-modal.component.html",
  styleUrls: ["./delivery-cost-info-modal.component.scss"],
})
export class DeliveryCostInfoModalComponent implements OnInit, OnDestroy {
  @Input() validStore: any;
  @Input() deliveryCost: any;

  public currentStore: any;
  public stores;
  public selectedAddress: any;
  animationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/delivery-info.json",
  };

  public mapUrl = "https://www.google.com/maps/dir/";
  public currentUser: any;

  private storesSubscription: Subscription;
  private deliveryMethodAddressSubscription: Subscription;
  private authSub: Subscription;
  private unsubscribeBackEvent: Subscription;

  constructor(
    private modalController: ModalController,
    private platform: Platform,
    private store: Store<fromApp.AppState>
  ) {}

  closeModal() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );
    this.modalController.dismiss();
  }

  ngOnInit() {
    this.authSub = this.store
      .select("auth")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (state && !_.isEqual(state.user, this.currentUser)) {
          this.currentUser = _.cloneDeep(state.user);
        }
      });

    this.storesSubscription = this.store
      .select("stores")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (state && state.stores && !_.isEqual(this.stores, state.stores)) {
          this.stores = _.cloneDeep(state.stores);
          this.currentStore = _.find(this.stores, {
            store_id: this.validStore.store_id,
          });
        }
      });

    this.deliveryMethodAddressSubscription = this.store
      .select("deliveryMethodAddress")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (
          state &&
          state.selectedAddress &&
          !_.isEqual(this.selectedAddress, state.selectedAddress)
        ) {
          this.selectedAddress = _.cloneDeep(state.selectedAddress);
        }
      });
  }

  openMap() {
    window.open(
      this.mapUrl +
        this.currentStore.mapCenterStoreLat +
        "," +
        this.currentStore.mapCenterStoreLng +
        "/" +
        this.selectedAddress.userLat +
        "," +
        this.selectedAddress.userLng
    );
  }

  ngOnDestroy(): void {
    if (this.deliveryMethodAddressSubscription) {
      this.deliveryMethodAddressSubscription.unsubscribe();
      this.deliveryMethodAddressSubscription = null;
    }

    if (this.storesSubscription) {
      this.storesSubscription.unsubscribe();
      this.storesSubscription = null;
    }

    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
      this.unsubscribeBackEvent = null;
    }

    if (this.authSub) {
      this.authSub.unsubscribe();
      this.authSub = null;
    }
  }
}
