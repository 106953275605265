<ion-header mode="ios" style="max-width: 650px; margin: auto">
  <app-no-internet-connection></app-no-internet-connection>
  <app-ionic-update-error></app-ionic-update-error>
  <ion-toolbar color="primary" class="custom-toolbar">
    <ion-grid style="margin-top: 25px">
      <!-- <ion-row>
        <ion-col style="text-align: end">
         
        </ion-col>
      </ion-row> -->

      <ion-grid style="position: absolute; top: 0; right: 10px">
        <ion-button
          (click)="presentModal()"
          *ngIf="!currentUser && loginState && loginState==='no_login'"
          size="small"
        >
          <ion-icon slot="icon-only" name="log-in-outline"></ion-icon
        ></ion-button>
        <ion-button *ngIf="currentUser" [disabled]="true" size="small">
          <ion-icon slot="icon-only" name="log-in"></ion-icon
        ></ion-button>
      </ion-grid>
      <ion-label style="opacity: 0.8">
        {{"reservation-app.welcome" |translate}}</ion-label
      >
      <br />
      <ion-label *ngIf="groupApp"> {{groupApp.app_name}} </ion-label>
      <p></p>
      <ion-label style="opacity: 0.8; font-size: 14px"
        >{{"reservation-app.easyAndFast" |translate}}</ion-label
      >
      <p></p>
    </ion-grid>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid
    [class]="windowDimensions && windowDimensions.width && windowDimensions.width>600 ? 'browser-page-grid' : 'mobile-page-grid'"
  >
    <ion-row *ngIf="windowDimensions" style="width: 100%">
      <ion-col [size]="colSize" *ngFor="let store of stores">
        <ion-grid style="width: 100%; max-height: 300px; max-width: 550px">
          <ion-card
            (click)="goToCatalogDebounced(store)"
            style="
              background-color: var(--ion-color-light);
              border-radius: 10px;
              margin: 5px;
            "
            button="true"
          >
            <img
              *ngIf="store.reservation_assets&&store.reservation_assets.reservationHomeImages&&store.reservation_assets.reservationHomeImages[0]"
              style="height: 75%"
              src="https://s3-eu-west-1.amazonaws.com/storegroupseditedbgimages/{{
            store.store_id
            }}/reservation_assets/reservationHomeImages/{{ store.reservation_assets.reservationHomeImages[0].fileName }}"
              alt="Item Image"
            />
            <ion-card-content
              ><ion-label
                class="ion-text-wrap"
                style="font-weight: bold"
                color="primary"
                >{{ store.store_name_mobileorder &&
                store.store_name_mobileorder[selectedLanguage] ?
                store.store_name_mobileorder[selectedLanguage] :
                store.store_name_langs &&
                store.store_name_langs[selectedLanguage] ?
                store.store_name_langs[selectedLanguage] :
                store.store_name_langs && store ? store.store_name_langs.el :
                store.store_name }}</ion-label
              >
            </ion-card-content>
          </ion-card>
        </ion-grid>
      </ion-col>
    </ion-row>
    <!-- <ion-fab vertical="bottom" horizontal="end" slot="fixed">
    <ion-fab-button class="chat-button">
      <ion-icon name="chatbox-ellipses"></ion-icon>
    </ion-fab-button>
  </ion-fab> -->
  </ion-grid>
</ion-content>
