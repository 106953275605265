<ion-header>
  <ion-toolbar mode="md">
    <ion-title *ngIf="title" size="small">
      {{ title | translate }}
    </ion-title>
    <ion-button
      *ngIf="closeModalButton"
      (click)="closeModal()"
      size="small"
      slot="end"
      fill="clear"
      color="dark"
    >
      <ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-list>
      <ion-item lines="none" *ngIf="submessage">
        <ion-label
          style="font-size: medium; font-weight: bold"
          class="ion-text-center ion-text-wrap"
        >
          {{ submessage | translate }}
        </ion-label>
      </ion-item>
      <ion-grid class="ion-text-center ion-no-padding" style="max-width: 130px">
        <ng-lottie
          *ngIf="login_lottie && !coupon_lottie"
          (animationCreated)="animationCreated($event)"
          [options]="loginAnimationOptions"
        ></ng-lottie>
        <ng-lottie
          *ngIf="coupon_lottie && !login_lottie"
          (animationCreated)="animationCreated($event)"
          [options]="couponAnimationOptions"
        ></ng-lottie>
      </ion-grid>
      <ion-item lines="none" *ngIf="message">
        <ion-label
          style="font-size: medium; font-weight: bold"
          class="ion-text-center ion-text-wrap"
        >
          {{ message | translate }}
        </ion-label>
      </ion-item>
    </ion-list>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-grid *ngIf="ok_button || cancel_button">
    <ion-row style="width: 100%">
      <ion-col
        *ngIf="cancel_button"
        [size]="
          ok_button && cancel_button
            ? '6'
            : cancel_button && !ok_button
            ? '12'
            : '12'
        "
        [class]="
          ok_button && cancel_button
            ? ' ion-text-left'
            : !ok_button && cancel_button
            ? ' ion-text-center'
            : ' ion-text-center'
        "
      >
        <ion-button (click)="cancelButtonClicked()" size="small" mode="md">{{
          cancel_button.text | translate
        }}</ion-button>
      </ion-col>
      <ion-col
        *ngIf="ok_button"
        [size]="
          ok_button && cancel_button
            ? '6'
            : !cancel_button && ok_button
            ? '12'
            : '12'
        "
        [class]="
          ok_button && cancel_button
            ? ' ion-text-right'
            : ok_button && !cancel_button
            ? ' ion-text-center'
            : ' ion-text-center'
        "
      >
        <ion-button (click)="okButtonClicked()" size="small" mode="md">{{
          ok_button.text | translate
        }}</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
