import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { IonSlides, ModalController } from "@ionic/angular";
import { from, Observable, Subscription } from "rxjs";
import * as _ from "lodash";
import { auditTime, distinctUntilChanged, first } from "rxjs/operators";
import * as fromApp from "../../../store/app.reducer";
import * as selectors from "../../../store/app.selectors";
import { Store } from "@ngrx/store";
import { ProductDetailsComponent } from "src/app/product-details/product-details.component";

@Component({
  selector: "app-product-market-type-cards-category-browser",
  templateUrl: "./product-market-type-cards-category-browser.component.html",
  styleUrls: ["./product-market-type-cards-category-browser.component.scss"],
})
export class ProductMarketTypeCardsCategoryBrowserComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Output() showNextCategoryButtonEvent = new EventEmitter();
  @Input() category_id: any;
  @Input() groupStoreData;
  @Input() selectedDineInTable;
  @Input() selectedPreorderDate;
  @Input() cartProductIds: any;
  @Input() scrollFromBottom;
  public category: any;
  public browserPlatform: any;
  public groupApp: any;
  public webp: any;
  public orderPickup: any;
  public dineIn: any;
  public storeInfo: any;
  public selectedCategoryId;
  public finalPriceLoading;
  public colSize;
  public gridMaxWidth = 1150;
  public filteredScrollProducts = [];
  private windowDimensions;
  private cartProducts: any;
  private productDetailsModal: any;
  private subscriptions: Subscription[] = [];
  private categorySelectorSub: Subscription;
  constructor(
    private store: Store<fromApp.AppState>,
    private modalCtrl: ModalController,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.category_id &&
      changes.category_id.previousValue &&
      changes.category_id.previousValue !== changes.category_id.currentValue
    ) {
      this.cartProducts = null;
      this.categorySelectorSubscription();
    }

    if (
      changes &&
      changes.scrollFromBottom &&
      changes.scrollFromBottom.previousValue !==
        changes.scrollFromBottom.currentValue &&
      this.filteredScrollProducts &&
      this.filteredScrollProducts.length
    ) {
      console.log("Scroll from bottom", this.scrollFromBottom);
      if (this.scrollFromBottom < 150) {
        this.loadData();
      }
    }
  }

  ngOnInit() {
    console.log("market category component");

    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .subscribe((storeInfo) => {
          if (storeInfo && !_.isEqual(storeInfo, this.storeInfo)) {
            this.storeInfo = _.cloneDeep(storeInfo);
            if (
              !(
                this.storeInfo &&
                this.storeInfo.design_settings &&
                this.storeInfo.design_settings.showOnlySelectedCategoryProducts
              )
            ) {
              this.showNextCategoryButtonEvent.emit(true);
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.windowDimensions, state.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
            this.calculateColSizeGridMaxWidth();
          }
        })
    );

    this.categorySelectorSubscription();

    this.subscriptions.push(
      this.store
        .select("cart")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.products &&
            !_.isEqual(this.cartProducts, state.products)
          ) {
            this.cartProducts = _.cloneDeep(state.products);
            if (this.category) {
              _.each(this.category.products, (product) => {
                delete product.productIncludedInCart;
              });
            }

            if (
              this.cartProducts &&
              this.cartProducts.length &&
              this.category &&
              this.category.products
            ) {
              _.each(this.category.products, (product) => {
                product.productIncludedInCart = _.find(
                  _.cloneDeep(this.cartProducts),
                  {
                    product_id: product.product_id,
                  }
                );
              });
            }
            if (
              this.storeInfo &&
              this.storeInfo.design_settings &&
              this.storeInfo.design_settings.showOnlySelectedCategoryProducts
            ) {
              this.calculateFilteredScrollProducts();
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("verticalCategory")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.selectedCategoryId, state.selectedCategoryId)
          ) {
            this.selectedCategoryId = _.cloneDeep(state.selectedCategoryId);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("disableFinalPrice")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.finalPriceLoading, state.disableFinalPrice)
          ) {
            this.finalPriceLoading = _.cloneDeep(state.disableFinalPrice);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("platformInfo")
        .pipe(auditTime(200))
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.browserPlatform, state.browserPlatform)
          ) {
            this.browserPlatform = _.cloneDeep(state.browserPlatform);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("deliveryMethodAddress")

        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && !_.isEqual(this.dineIn, state.dineIn)) {
            this.dineIn = _.cloneDeep(state.dineIn);
          }
          if (state && !_.isEqual(this.orderPickup, state.orderPickup)) {
            this.orderPickup = _.cloneDeep(state.orderPickup);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(auditTime(200))
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(this.groupApp, state.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
          }
          if (state && state.webp && !_.isEqual(this.webp, state.webp)) {
            this.webp = _.cloneDeep(state.webp);
          }
        })
    );

    this.categorySelectorSubscription();
  }

  calculateFilteredScrollProducts() {
    _.each(this.filteredScrollProducts, (prd, index) => {
      const newPrd = _.find(_.cloneDeep(this.category.products), {
        product_id: prd.product_id,
      });
      if (!_.isEqual(newPrd, prd)) {
        this.filteredScrollProducts[index] = _.cloneDeep(newPrd);
      }
    });
    //DO NOT REMOVE THIS, PIPE filterAvailableProducts NEEDS NEW INSTANCE TO BE FIRED AGAIN!
    this.filteredScrollProducts = _.cloneDeep(this.filteredScrollProducts);
  }

  initFilteredScrollProducts() {
    if (
      this.category &&
      this.category.products &&
      this.category.products.length > 0
    ) {
      this.filteredScrollProducts = _.cloneDeep(
        _.slice(this.category.products, 0, 40)
      );

      if (
        this.filteredScrollProducts.length === this.category.products.length
      ) {
        this.showNextCategoryButtonEvent.emit(true);
      }
    }

    console.log("filtered scroll procuts", this.filteredScrollProducts);
  }

  loadData() {
    this.addMoreItemsDebounce();
  }

  private addMoreItemsDebounce = _.debounce(() => {
    if (this.category.products.length > this.filteredScrollProducts.length) {
      const tempList = _.cloneDeep(
        _.slice(
          _.cloneDeep(this.category.products),
          this.filteredScrollProducts.length - 1,
          this.category.products.length >=
            this.filteredScrollProducts.length - 1 + 20
            ? this.filteredScrollProducts.length - 1 + 20
            : this.category.products.length
        )
      );

      _.each(tempList, (prd: any, key) => {
        if (
          !_.find(this.filteredScrollProducts, {
            product_id: prd.product_id,
          })
        ) {
          this.filteredScrollProducts.push(prd);
        }
      });
      //DO NOT REMOVE THIS, PIPE filterAvailableProducts NEEDS NEW INSTANCE TO BE FIRED AGAIN!
      this.filteredScrollProducts = _.cloneDeep(this.filteredScrollProducts);
      //
      if (
        this.filteredScrollProducts.length === this.category.products.length
      ) {
        this.showNextCategoryButtonEvent.emit(true);
      }
    }
  }, 300);

  categorySelectorSubscription() {
    if (this.categorySelectorSub) {
      this.categorySelectorSub.unsubscribe();
      this.categorySelectorSub = null;
    }
    this.categorySelectorSub = this.store
      .select(selectors.getCategoryByCategoryId(this.category_id))
      .pipe(distinctUntilChanged())

      .subscribe((category) => {
        if (category && !_.isEqual(category, this.category)) {
          let tempCategory = _.cloneDeep(category);
          _.each(tempCategory.products, (product) => {
            if (
              product &&
              (!product.availableTotal ||
                !product.product_categories_availability_check)
            ) {
              product.tags = [];
            } else {
              if (product.tags) {
                let keys = _.keys(product.tags);

                const fistKey = keys[0].toString();

                if (fistKey) {
                  product.tags = {};
                  product.tags[fistKey] = true;
                }
              }
            }
          });
          this.showNextCategoryButtonEvent.emit(false);
          this.category = _.cloneDeep(tempCategory);

          _.each(this.category.products, (product) => {
            delete product.productIncludedInCart;
          });
          if (this.cartProducts && this.cartProducts.length) {
            _.each(this.category.products, (product) => {
              product.productIncludedInCart = _.find(
                _.cloneDeep(this.cartProducts),
                {
                  product_id: product.product_id,
                }
              );
            });
          }
          this.store
            .select(selectors.getStoreInfo)
            .pipe(first())
            .subscribe((storeInfo) => {
              if (
                storeInfo &&
                storeInfo.design_settings &&
                storeInfo.design_settings.showOnlySelectedCategoryProducts
              ) {
                this.initFilteredScrollProducts();
              }
            })
            .unsubscribe();
        }
      });
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  calculateColSizeGridMaxWidth() {
    this.store
      .select(selectors.getStoreInfo)
      .pipe(first())
      .subscribe((storeInfo) => {
        this.store
          .select("hyperCategories")
          .pipe(first())
          .subscribe((hyperCatState) => {
            this.store
              .select("catalog")
              .pipe(first())
              .subscribe((state) => {
                if (
                  hyperCatState.hyperCategories &&
                  hyperCatState.hyperCategories.length > 0
                ) {
                  if (
                    storeInfo &&
                    storeInfo.design_settings &&
                    storeInfo.design_settings.mobileorderHideCartCatalog &&
                    state.allCategoriesMarketCardsDesign
                  ) {
                    if (this.windowDimensions.width >= 1600) {
                      this.colSize = "2";
                      this.gridMaxWidth = 1150;
                    } else if (this.windowDimensions.width < 1600) {
                      this.colSize = "3";
                      this.gridMaxWidth = 780;
                    }
                  } else {
                    this.colSize = "4";
                    this.gridMaxWidth = 1150;
                  }
                } else if (
                  hyperCatState &&
                  hyperCatState.hyperCategories &&
                  hyperCatState.hyperCategories.length === 0
                ) {
                  if (
                    storeInfo &&
                    storeInfo.design_settings &&
                    storeInfo.design_settings.mobileorderHideCartCatalog &&
                    state.allCategoriesMarketCardsDesign
                  ) {
                    if (this.windowDimensions.width >= 1600) {
                      this.colSize = "2";
                      this.gridMaxWidth = 1150;
                    } else if (this.windowDimensions.width < 1600) {
                      this.colSize = "3";
                      this.gridMaxWidth = 780;
                    }
                  } else {
                    this.colSize = "4";
                    this.gridMaxWidth = 1150;
                  }
                }
              })
              .unsubscribe();
          })
          .unsubscribe();
      })
      .unsubscribe();
  }

  productTrack(index, item) {
    return item.product_id;
  }

  ngOnDestroy() {
    this.showNextCategoryButtonEvent.emit(false);
    if (this.categorySelectorSub) {
      this.categorySelectorSub.unsubscribe();
      this.categorySelectorSub = null;
    }

    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }
}
