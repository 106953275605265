import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import * as fromApp from "../../../store/app.reducer";
import * as selectors from "../../../store/app.selectors";
import * as _ from "lodash";
import { Store } from "@ngrx/store";
import {
  auditTime,
  debounceTime,
  distinctUntilChanged,
  first,
} from "rxjs/operators";
import { Subscription } from "rxjs";
import * as CompleteOrderValidationActions from "../../../store/complete-order-validation/completeOrderValidation.actions";
import { TranslateService } from "@ngx-translate/core";
import { AlertController, ModalController } from "@ionic/angular";
import { Router } from "@angular/router";
import { DeliveryCostInfoModalComponent } from "src/app/popups/delivery-cost-info-modal/delivery-cost-info-modal.component";
import { InformationMessagesService } from "src/app/services/informationMessages/information-messages.service";
import { Capacitor } from "@capacitor/core";
import { PointsCalculationsService } from "src/app/services/pointsCalculations/points-calculations.service";
import { VatLevelsDetailsModalComponent } from "src/app/popups/vat-levels-details-modal/vat-levels-details-modal.component";

@Component({
  selector: "app-cart-prices",
  templateUrl: "./cart-prices.component.html",
  styleUrls: ["./cart-prices.component.scss"],
})
export class CartPricesComponent implements OnInit, OnDestroy {
  @Input() catalogMode: boolean;
  public subtotal: any;
  public discount_amount: any;
  public deliveryCost: any;
  public cartCustomOptionsTotalPrice: any;
  public vat_total;
  public grand_total_without_delivery_tips;
  public cartCustomOptionsTotalPriceWithoutTipsAndWrapperTax;
  public deliveryTipsTotal;
  public wrapperTaxTotal;
  public currentOrderPoints: any;
  public points_deducted_from_coupon: any;
  public grand_total: any;
  public finalPriceLoading: boolean;
  public cartPrices: any;
  public cartProducts: any;
  public groupApp: any;
  public currentUser: any;
  public lastValidTimestamp: any = null;
  public cartActiveCoupons;
  public selectedCoupons;
  public showCouponDiscountMessage: boolean = false;
  public store_info: any;
  public currentValidStore;
  public deliveryCostReplacedWithPrdMaxDeliveryCharge;
  public orderPickup;
  public dineIn;
  public selectedDineInTable;
  public cartInfoMessagesStore: any = {
    alert: null,
    inline: null,
    modal: null,
  };
  public cartInfoMessagesGroup: any = {
    alert: null,
    inline: null,
    modal: null,
  };
  public pointsDisableRuleArr;
  public selectedLang;
  public mo_base;
  public pointsFactorUsed: boolean;
  public pointsFactor;
  public pointsDisableRuleMessage: string;
  public enviromental_fee_translations: boolean = false;

  private cartCustomOptionsSelections;
  private selectedAddress;
  private deliveryCostInfoModal;
  private validStores;
  private vatLevelsDetailsModal: any;
  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store<fromApp.AppState>,
    private translateService: TranslateService,
    private alertController: AlertController,
    private modalController: ModalController,
    public pointsCalculationsService: PointsCalculationsService,
    private infoMessagesService: InformationMessagesService,
    private router: Router
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && !_.isEqual(this.currentUser, state.user)) {
            this.currentUser = _.cloneDeep(state.user);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(this.groupApp, state.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);

            if (this.store_info && this.groupApp && this.currentOrderPoints) {
              this.calculatePointsFactor(
                this.groupApp,
                this.store_info,
                this.currentOrderPoints,
                Capacitor.isNativePlatform() ? "mobile" : "web"
              );
            }

            this.mo_base = _.cloneDeep(
              _.find(state.groupApp.languages, {
                mo_base: true,
              })
            );

            if (!this.mo_base) {
              this.mo_base = "en";
            }
          }

          if (
            state &&
            state.selectedLangugage &&
            !_.isEqual(this.selectedLang, state.selectedLangugage)
          ) {
            this.selectedLang = _.cloneDeep(state.selectedLangugage);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("cartActiveCoupons")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.cartActiveCoupons, state.activeCoupons)
          ) {
            this.cartActiveCoupons = _.cloneDeep(state.activeCoupons);
            if (this.cartActiveCoupons && this.cartActiveCoupons.length) {
              this.selectedCoupons = _.filter(this.cartActiveCoupons, {
                couponCheckbox: true,
              });
              // if (this.selectedCoupons && this.selectedCoupons.length) {
              //   this.checkAndShowCouponDiscountMessageDebounce();
              // }
            } else {
              //TO_DO WE CAN SHOW AN ALERT IF USER HAS PREVIOUSLY SELECTED A COUPON AND NOW NOT AVAILABLE
              this.selectedCoupons = [];
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("disableFinalPrice")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.finalPriceLoading, state.disableFinalPrice)
          ) {
            if (!state.disableFinalPrice && this.finalPriceLoading) {
              this.checkAndShowCouponDiscountMessageDebounce();
            }

            this.finalPriceLoading = _.cloneDeep(state.disableFinalPrice);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .subscribe((store_info) => {
          if (store_info && !_.isEqual(this.store_info, store_info)) {
            this.store_info = _.cloneDeep(store_info);

            this.checkCartInfoMessages();

            if (this.store_info && this.groupApp && this.currentOrderPoints) {
              this.calculatePointsFactor(
                this.groupApp,
                this.store_info,
                this.currentOrderPoints,
                Capacitor.isNativePlatform() ? "mobile" : "web"
              );
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("cart")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.products &&
            !_.isEqual(this.cartProducts, state.products)
          ) {
            this.cartProducts = _.cloneDeep(state.products);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("deliveryMethodAddress")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && !_.isEqual(state.orderPickup, this.orderPickup)) {
            this.orderPickup = _.cloneDeep(state.orderPickup);
            if (this.groupApp && this.store_info && this.currentOrderPoints) {
              this.calculatePointsFactor(
                this.groupApp,
                this.store_info,
                this.currentOrderPoints,
                Capacitor.isNativePlatform() ? "mobile" : "web"
              );
            } else if (
              this.groupApp &&
              this.store_info &&
              (!this.currentOrderPoints || this.currentOrderPoints === "0")
            ) {
              this.calculatePointsDisableRule(
                this.groupApp,
                this.store_info,
                this.currentOrderPoints,
                Capacitor.isNativePlatform() ? "mobile" : "web"
              );
            }
          }

          if (
            state &&
            !_.isEqual(state.selectedDineInTable, this.selectedDineInTable)
          ) {
            this.selectedDineInTable = _.cloneDeep(state.selectedDineInTable);
          }
          if (state && !_.isEqual(state.dineIn, this.dineIn)) {
            this.dineIn = _.cloneDeep(state.dineIn);
            if (this.groupApp && this.store_info && this.currentOrderPoints) {
              this.calculatePointsFactor(
                this.groupApp,
                this.store_info,
                this.currentOrderPoints,
                Capacitor.isNativePlatform() ? "mobile" : "web"
              );
            } else if (
              this.groupApp &&
              this.store_info &&
              (!this.currentOrderPoints || this.currentOrderPoints === "0")
            ) {
              this.calculatePointsDisableRule(
                this.groupApp,
                this.store_info,
                this.currentOrderPoints,
                Capacitor.isNativePlatform() ? "mobile" : "web"
              );
            }
          }
          if (
            state &&
            !_.isEqual(state.selectedAddress, this.selectedAddress)
          ) {
            this.selectedAddress = _.cloneDeep(state.selectedAddress);
            if (this.selectedAddress) {
              this.store
                .select("currentStore")
                .pipe(first())
                .subscribe((currentStoreState) => {
                  const store_id = currentStoreState.currentStoreId;
                  if (state.validStores && state.validStores.length) {
                    this.currentValidStore = _.cloneDeep(
                      _.find(_.cloneDeep(state.validStores), {
                        store_id: store_id,
                      })
                    );
                  }
                });
            }
          }

          if (state && !_.isEqual(state.validStores, this.validStores)) {
            this.validStores = _.cloneDeep(state.validStores);
            if (this.selectedAddress) {
              this.store
                .select("currentStore")
                .pipe(first())
                .subscribe((currentStoreState) => {
                  const store_id = currentStoreState.currentStoreId;
                  if (state.validStores && state.validStores.length) {
                    this.currentValidStore = _.cloneDeep(
                      _.find(_.cloneDeep(state.validStores), {
                        store_id: store_id,
                      })
                    );
                  }
                });
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("cartPrices")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.cartPrices &&
            !_.isEqual(state.cartPrices, this.cartPrices)
          ) {
            this.cartPrices = _.cloneDeep(state.cartPrices);

            if (this.cartPrices) {
              if (!_.isEqual(this.cartPrices.subtotal, this.subtotal)) {
                this.subtotal = _.cloneDeep(this.cartPrices.subtotal);
              }
              if (
                !_.isEqual(
                  this.cartPrices.discount_amount,
                  this.discount_amount
                )
              ) {
                this.discount_amount = _.cloneDeep(
                  this.cartPrices.discount_amount
                );
              }
              if (!_.isEqual(this.cartPrices.deliveryCost, this.deliveryCost)) {
                this.deliveryCost = _.cloneDeep(this.cartPrices.deliveryCost);
                if (
                  this.deliveryCost &&
                  parseFloat(this.deliveryCost.toString())
                ) {
                  this.deliveryCost = this.deliveryCost.toFixed(2);
                }
              }
              if (
                !_.isEqual(
                  this.cartPrices.cartCustomOptionsTotalPrice,
                  this.cartCustomOptionsTotalPrice
                )
              ) {
                this.cartCustomOptionsTotalPrice = _.cloneDeep(
                  this.cartPrices.cartCustomOptionsTotalPrice
                );
              }

              if (
                !_.isEqual(
                  this.cartPrices.deliveryCostReplacedWithPrdMaxDeliveryCharge,
                  this.deliveryCostReplacedWithPrdMaxDeliveryCharge
                )
              ) {
                this.deliveryCostReplacedWithPrdMaxDeliveryCharge = _.cloneDeep(
                  this.cartPrices.deliveryCostReplacedWithPrdMaxDeliveryCharge
                );
              }

              if (
                !_.isEqual(
                  this.cartPrices.grand_total_without_delivery_tips,
                  this.grand_total_without_delivery_tips
                )
              ) {
                this.grand_total_without_delivery_tips = _.cloneDeep(
                  this.cartPrices.grand_total_without_delivery_tips
                );
              }

              if (
                !_.isEqual(
                  this.cartPrices
                    .cartCustomOptionsTotalPriceWithoutTipsAndWrapperTax,
                  this.cartCustomOptionsTotalPriceWithoutTipsAndWrapperTax
                )
              ) {
                this.cartCustomOptionsTotalPriceWithoutTipsAndWrapperTax =
                  _.cloneDeep(
                    this.cartPrices
                      .cartCustomOptionsTotalPriceWithoutTipsAndWrapperTax
                  );
              }
              if (
                !_.isEqual(
                  this.cartPrices.deliveryTipsTotal,
                  this.deliveryTipsTotal
                )
              ) {
                this.deliveryTipsTotal = _.cloneDeep(
                  this.cartPrices.deliveryTipsTotal
                );
              }

              if (
                !_.isEqual(
                  this.cartPrices.wrapperTaxTotal,
                  this.wrapperTaxTotal
                )
              ) {
                this.wrapperTaxTotal = _.cloneDeep(
                  this.cartPrices.wrapperTaxTotal
                );
              }

              if (!_.isEqual(this.cartPrices.grand_total, this.grand_total)) {
                this.grand_total = _.cloneDeep(this.cartPrices.grand_total);
              }

              if (!_.isEqual(this.cartPrices.grand_total, this.grand_total)) {
                this.grand_total = _.cloneDeep(this.cartPrices.grand_total);
              }
              if (
                !_.isEqual(
                  this.cartPrices.currentOrderPoints,
                  this.currentOrderPoints
                )
              ) {
                this.currentOrderPoints = _.cloneDeep(
                  this.cartPrices.currentOrderPoints
                );

                if (
                  this.store_info &&
                  this.groupApp &&
                  this.currentOrderPoints
                ) {
                  this.calculatePointsFactor(
                    this.groupApp,
                    this.store_info,
                    this.currentOrderPoints,
                    Capacitor.isNativePlatform() ? "mobile" : "web"
                  );
                } else if (
                  this.store_info &&
                  this.groupApp &&
                  this.groupApp.pointsSystem &&
                  (!this.currentOrderPoints || this.currentOrderPoints === "0")
                ) {
                  this.calculatePointsDisableRule(
                    this.groupApp,
                    this.store_info,
                    this.currentOrderPoints,
                    Capacitor.isNativePlatform() ? "mobile" : "web"
                  );
                }
              }

              if (
                !_.isEqual(
                  this.cartPrices.points_deducted_from_coupon,
                  this.points_deducted_from_coupon
                )
              ) {
                this.points_deducted_from_coupon = _.cloneDeep(
                  this.cartPrices.points_deducted_from_coupon
                );
              }

              if (!_.isEqual(this.cartPrices.vat_total, this.vat_total)) {
                this.vat_total = _.cloneDeep(this.cartPrices.vat_total);
              }
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("completeOrderValidation")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.lastValidTimestamp &&
            !_.isEqual(this.lastValidTimestamp, state.lastValidTimestamp)
          ) {
            this.lastValidTimestamp = _.cloneDeep(state.lastValidTimestamp);
            this.cartPricesValidation();
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("cartCustomOptionsSelections")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.cartCustomOptionsSelections &&
            !_.isEqual(
              this.cartCustomOptionsSelections,
              state.cartCustomOptionsSelections
            )
          ) {
            this.cartCustomOptionsSelections = _.cloneDeep(
              state.cartCustomOptionsSelections
            );
            const plastic_wrapper_tax_option = _.find(
              this.cartCustomOptionsSelections,
              { preNameId: "plastic_wrapper_tax" }
            );

            if (
              plastic_wrapper_tax_option &&
              plastic_wrapper_tax_option.enviromental_fee_translations
            ) {
              this.enviromental_fee_translations = true;
            } else {
              this.enviromental_fee_translations = false;
            }
          }
        })
    );
  }

  calculatePointsDisableRule(groupApp, store_info, currentPoints, platform) {
    this.store
      .select("deliveryMethodAddress")
      .pipe(first())
      .subscribe((state) => {
        console.log("pointsFactorUsed");
        const disableRule =
          this.pointsCalculationsService.calculateDisablePointRule(
            groupApp,
            store_info,
            currentPoints,
            platform,
            state.orderPickup,
            state.dineIn,
            state.selectedDineInTable
          );

        console.log("pointsDisableRuleArr", disableRule);

        this.pointsDisableRuleArr = _.cloneDeep(disableRule);
        this.pointsDisableRuleMessage = null;

        if (this.pointsDisableRuleArr && this.pointsDisableRuleArr.length) {
          if (
            this.pointsDisableRuleArr[0] &&
            this.pointsDisableRuleArr[0].platform === "all"
          ) {
            this.pointsDisableRuleMessage =
              this.translateService.instant(
                "cart.cart-components.cart-prices.points-earn-are-disabled-for"
              ) +
              " " +
              this.translateService.instant(
                this.pointsCalculationsService.dineInAltDineinAlternativeNames(
                  this.pointsCalculationsService.calculateDeliveryMethod(
                    this.orderPickup,
                    this.dineIn,
                    {
                      alt_dinein_active:
                        this.store_info &&
                        this.store_info.dinein &&
                        this.store_info.alt_dinein_active &&
                        this.selectedDineInTable &&
                        this.selectedDineInTable.alt_dinein,
                      selectedDineInTable: this.selectedDineInTable,
                    }
                  ),
                  this.store_info,
                  this.selectedLang,
                  this.mo_base
                )
              );
          } else if (
            this.pointsDisableRuleArr[0] &&
            this.pointsDisableRuleArr[0].platform !== "all"
          ) {
            const deliveryMethod =
              this.pointsCalculationsService.calculateDeliveryMethod(
                this.orderPickup,
                this.dineIn,
                {
                  alt_dinein_active:
                    this.store_info &&
                    this.store_info.dinein &&
                    this.store_info.alt_dinein_active &&
                    this.selectedDineInTable &&
                    this.selectedDineInTable.alt_dinein,
                  selectedDineInTable: this.selectedDineInTable,
                }
              );

            let showMessageDisablePoints = false;

            if (
              deliveryMethod === "delivery" &&
              _.find(this.pointsDisableRuleArr, {
                order_type: "delivery",
                platform: platform,
              })
            ) {
              showMessageDisablePoints = true;
            } else if (
              deliveryMethod === "takeaway" &&
              _.find(this.pointsDisableRuleArr, {
                order_type: "takeaway",
                platform: platform,
              })
            ) {
              showMessageDisablePoints = true;
            } else if (
              deliveryMethod === "dinein" &&
              _.find(this.pointsDisableRuleArr, {
                order_type: "dinein",
                platform: platform,
              })
            ) {
              showMessageDisablePoints = true;
            } else if (
              deliveryMethod === "altdinein" &&
              _.find(this.pointsDisableRuleArr, {
                order_type: "altdinein",
                platform: platform,
              })
            ) {
              showMessageDisablePoints = true;
            }

            if (showMessageDisablePoints) {
              this.pointsDisableRuleMessage =
                this.translateService.instant(
                  "cart.cart-components.cart-prices.points-earn-are-disabled-for"
                ) +
                " " +
                this.translateService.instant(
                  this.pointsCalculationsService.dineInAltDineinAlternativeNames(
                    deliveryMethod,
                    this.store_info,
                    this.selectedLang,
                    this.mo_base
                  )
                ) +
                " " +
                this.translateService.instant(
                  "cart.cart-components.cart-prices.from"
                ) +
                " " +
                (Capacitor.isNativePlatform()
                  ? this.translateService.instant(
                      "cart.cart-components.cart-prices.mobileApps"
                    )
                  : this.translateService.instant(
                      "cart.cart-components.cart-prices.webpage"
                    )
                ).replace(",", "") +
                " ";
            }
          }
        }
      })
      .unsubscribe();
  }

  calculatePointsFactor(groupApp, store_info, currentPoints, platform) {
    this.store
      .select("deliveryMethodAddress")
      .pipe(first())
      .subscribe((state) => {
        console.log("pointsFactorUsed");
        const pointsObject =
          this.pointsCalculationsService.calculatePointsFactor(
            groupApp,
            store_info,
            currentPoints,
            platform,
            state.orderPickup,
            state.dineIn,
            state.selectedDineInTable
          );

        console.log("pointsFactorUsed", pointsObject);

        this.pointsFactor = _.cloneDeep(pointsObject.pointsFactor);
        this.pointsFactorUsed = _.cloneDeep(pointsObject.pointsFactorUsed);
      })
      .unsubscribe();
  }

  navigateToPointsPage() {
    this.router.navigateByUrl("/points", {
      replaceUrl: true,
    });
  }

  private checkAndShowCouponDiscountMessageDebounce = _.debounce(() => {
    this.checkShowCouponDiscountMessage();
  }, 200);

  checkShowCouponDiscountMessage() {
    this.showCouponDiscountMessage = false;
    _.each(this.selectedCoupons, (coupon) => {
      if (
        this.cartPrices.discount_amount &&
        coupon &&
        (coupon.discount || coupon.discount_fixed) &&
        coupon.discountType !== "percent" &&
        coupon.discountType &&
        this.toFloat(coupon.discount || coupon.discount_fixed) >
          this.toFloat(this.cartPrices.discount_amount)
      ) {
        this.showCouponDiscountMessage = true;
      }
    });
  }

  checkCartInfoMessages() {
    this.store
      .select("groupApp")
      .pipe(first())
      .subscribe((state) => {
        if (state && state.groupApp) {
          const cartInfoMessagesGroup = _.cloneDeep(
            this.infoMessagesService.checkForMobileOrderInfoMessagesGroupApp(
              _.cloneDeep(state.groupApp),
              _.cloneDeep(this.store_info),
              "cart"
            )
          );
          const cartInfoMessagesStore = _.cloneDeep(
            this.infoMessagesService.checkForMobileOrderInfoMessagesStore(
              _.cloneDeep(this.store_info),
              "cart"
            )
          );

          this.cartInfoMessagesGroup.inline = _.cloneDeep(
            _.filter(cartInfoMessagesGroup, (infoMessage) => {
              if (
                infoMessage &&
                infoMessage.view_type &&
                _.includes(infoMessage.view_type, "inline")
              ) {
                return infoMessage;
              }
            })
          );
          this.cartInfoMessagesStore.inline = _.cloneDeep(
            _.filter(cartInfoMessagesStore, (infoMessage) => {
              if (
                infoMessage &&
                infoMessage.view_type &&
                _.includes(infoMessage.view_type, "inline")
              ) {
                return infoMessage;
              }
            })
          );

          let mo_base = _.cloneDeep(
            _.find(state.groupApp.languages, {
              mo_base: true,
            })
          );

          if (!mo_base) {
            mo_base = "en";
          }
        }
      })
      .unsubscribe();
  }

  async openVatDetailsModal() {
    this.vatLevelsDetailsModal = await this.modalController.create({
      component: VatLevelsDetailsModalComponent,
      componentProps: {},
      backdropDismiss: false,
    });

    await this.vatLevelsDetailsModal.present();

    this.vatLevelsDetailsModal.onDidDismiss().then((data) => {
      this.vatLevelsDetailsModal = null;
    });
  }

  pointsFactorPlatformMessage() {
    const deliveryMethod =
      this.pointsCalculationsService.calculateDeliveryMethod(
        this.orderPickup,
        this.dineIn,
        {
          alt_dinein_active:
            this.store_info &&
            this.store_info.dinein &&
            this.store_info.alt_dinein_active &&
            this.selectedDineInTable &&
            this.selectedDineInTable.alt_dinein,
          selectedDineInTable: this.selectedDineInTable,
        }
      );

    const platform = Capacitor.isNativePlatform() ? "mobile" : "web";

    let returnPlatformMessage = false;

    if (deliveryMethod && deliveryMethod === "delivery") {
      if (
        this.pointsFactorUsed &&
        this.pointsFactor.factor &&
        ((platform === "web" &&
          this.store_info.pointsSystemStoreSettings.delivery &&
          this.store_info.pointsSystemStoreSettings.delivery.apps &&
          this.store_info.pointsSystemStoreSettings.delivery.apps.factor &&
          this.store_info.pointsSystemStoreSettings.delivery.apps.factor > 0 &&
          this.store_info.pointsSystemStoreSettings.delivery.apps.factor !==
            this.pointsFactor.factor) ||
          (platform === "mobile" &&
            this.store_info.pointsSystemStoreSettings.delivery &&
            this.store_info.pointsSystemStoreSettings.delivery.web &&
            this.store_info.pointsSystemStoreSettings.delivery.web.factor &&
            this.store_info.pointsSystemStoreSettings.delivery.web.factor > 0 &&
            this.store_info.pointsSystemStoreSettings.delivery.web.factor !==
              this.pointsFactor.factor))
      ) {
        returnPlatformMessage = true;
      }
    } else if (deliveryMethod && deliveryMethod === "takeaway") {
      if (
        this.pointsFactorUsed &&
        this.pointsFactor.factor &&
        ((platform === "web" &&
          this.store_info.pointsSystemStoreSettings.takeaway &&
          this.store_info.pointsSystemStoreSettings.takeaway.apps &&
          this.store_info.pointsSystemStoreSettings.takeaway.apps.factor &&
          this.store_info.pointsSystemStoreSettings.takeaway.apps.factor > 0 &&
          this.store_info.pointsSystemStoreSettings.takeaway.apps.factor !==
            this.pointsFactor.factor) ||
          (platform === "mobile" &&
            this.store_info.pointsSystemStoreSettings.takeaway &&
            this.store_info.pointsSystemStoreSettings.takeaway.web &&
            this.store_info.pointsSystemStoreSettings.takeaway.web.factor &&
            this.store_info.pointsSystemStoreSettings.takeaway.web.factor > 0 &&
            this.store_info.pointsSystemStoreSettings.takeaway.web.factor !==
              this.pointsFactor.factor))
      ) {
        returnPlatformMessage = true;
      }
    } else if (deliveryMethod && deliveryMethod === "dinein") {
      if (
        this.pointsFactorUsed &&
        this.pointsFactor.factor &&
        ((platform === "web" &&
          this.store_info.pointsSystemStoreSettings.dinein &&
          this.store_info.pointsSystemStoreSettings.dinein.apps &&
          this.store_info.pointsSystemStoreSettings.dinein.apps.factor &&
          this.store_info.pointsSystemStoreSettings.dinein.apps.factor > 0 &&
          this.store_info.pointsSystemStoreSettings.dinein.apps.factor !==
            this.pointsFactor.factor) ||
          (platform === "mobile" &&
            this.store_info.pointsSystemStoreSettings.dinein &&
            this.store_info.pointsSystemStoreSettings.dinein.web &&
            this.store_info.pointsSystemStoreSettings.dinein.web.factor &&
            this.store_info.pointsSystemStoreSettings.dinein.web.factor > 0 &&
            this.store_info.pointsSystemStoreSettings.dinein.web.factor !==
              this.pointsFactor.factor))
      ) {
        returnPlatformMessage = true;
      }
    } else if (deliveryMethod && deliveryMethod === "altdinein") {
      this.pointsFactorUsed &&
        this.pointsFactor.factor &&
        ((platform === "web" &&
          this.store_info.pointsSystemStoreSettings.altdinein &&
          this.store_info.pointsSystemStoreSettings.altdinein.apps &&
          this.store_info.pointsSystemStoreSettings.altdinein.apps.factor &&
          this.store_info.pointsSystemStoreSettings.altdinein.apps.factor > 0 &&
          this.store_info.pointsSystemStoreSettings.altdinein.apps.factor !==
            this.pointsFactor.factor) ||
          (platform === "mobile" &&
            this.store_info.pointsSystemStoreSettings.altdinein &&
            this.store_info.pointsSystemStoreSettings.altdinein.web &&
            this.store_info.pointsSystemStoreSettings.altdinein.web.factor &&
            this.store_info.pointsSystemStoreSettings.altdinein.web.factor >
              0 &&
            this.store_info.pointsSystemStoreSettings.altdinein.web.factor !==
              this.pointsFactor.factor));
      {
        returnPlatformMessage = true;
      }
    }
    if (returnPlatformMessage) {
      return (
        this.translateService.instant("cart.cart-components.cart-prices.from") +
        " " +
        (Capacitor.isNativePlatform()
          ? this.translateService.instant(
              "cart.cart-components.cart-prices.mobileApps"
            )
          : this.translateService.instant(
              "cart.cart-components.cart-prices.webpage"
            )) +
        " "
      );
    }

    return "";
  }

  async openDeliveryCostInfoModal(validStore, deliveryCost) {
    this.deliveryCostInfoModal = await this.modalController.create({
      cssClass: "delivery-cost-info-modal",
      component: DeliveryCostInfoModalComponent,
      componentProps: {
        validStore: validStore,
        deliveryCost: deliveryCost,
      },
      backdropDismiss: false,
    });

    await this.deliveryCostInfoModal.present();

    this.deliveryCostInfoModal.onDidDismiss().then((data) => {
      this.deliveryCostInfoModal = null;
    });
  }

  async openPointsInfoAlert() {
    if (
      this.points_deducted_from_coupon &&
      this.store_info &&
      this.store_info.overridePromoItemsForPointsSystem &&
      !this.pointsFactorUsed
    ) {
      const alert = await this.alertController.create({
        cssClass: "my-custom-class",
        header: this.translateService.instant(
          "cart.cart-components.cart-prices.points-calculation-info"
        ),

        message:
          this.translateService.instant(
            "cart.cart-components.cart-prices.based-on-your-cart-was-removed"
          ) +
          " " +
          this.points_deducted_from_coupon +
          " " +
          this.translateService.instant(
            "cart.cart-components.cart-prices.points-because-of-the-coupon"
          ),
        buttons: ["OK"],
      });

      await alert.present();
    } else if (
      !(
        this.points_deducted_from_coupon &&
        this.store_info &&
        this.store_info.overridePromoItemsForPointsSystem
      ) &&
      this.pointsFactorUsed
    ) {
      const alert = await this.alertController.create({
        cssClass: "my-custom-class",
        header: this.translateService.instant(
          "cart.cart-components.cart-prices.points-calculation-info"
        ),

        message:
          this.translateService.instant(
            "cart.cart-components.cart-prices.for-order"
          ) +
          " " +
          this.translateService.instant(
            this.pointsCalculationsService.dineInAltDineinAlternativeNames(
              this.pointsCalculationsService.calculateDeliveryMethod(
                this.orderPickup,
                this.dineIn,
                {
                  alt_dinein_active:
                    this.store_info &&
                    this.store_info.dinein &&
                    this.store_info.alt_dinein_active &&
                    this.selectedDineInTable &&
                    this.selectedDineInTable.alt_dinein,
                  selectedDineInTable: this.selectedDineInTable,
                }
              ),
              this.store_info,
              this.selectedLang,
              this.mo_base
            )
          ) +
          " " +
          this.pointsFactorPlatformMessage() +
          this.translateService.instant(
            "cart.cart-components.cart-prices.used-factor"
          ) +
          " " +
          (this.pointsFactor.factor * 100).toFixed(0) +
          "%",
        buttons: [
          {
            text: this.translateService.instant("more"),

            handler: () => {
              this.router.navigateByUrl("/points?segment=rules", {
                replaceUrl: true,
              });
            },
          },
          {
            text: "OK",
            role: "cancel",
            handler: () => {},
          },
        ],
      });

      await alert.present();
    } else if (
      this.points_deducted_from_coupon &&
      this.store_info &&
      this.store_info.overridePromoItemsForPointsSystem &&
      this.pointsFactorUsed
    ) {
      const alert = await this.alertController.create({
        cssClass: "my-custom-class",
        header: this.translateService.instant(
          "cart.cart-components.cart-prices.points-calculation-info"
        ),

        message:
          this.translateService.instant(
            "cart.cart-components.cart-prices.based-on-your-cart-was-removed"
          ) +
          " " +
          this.points_deducted_from_coupon +
          " " +
          this.translateService.instant(
            "cart.cart-components.cart-prices.points-because-of-the-coupon"
          ) +
          "<p>" +
          this.translateService.instant(
            "cart.cart-components.cart-prices.for-order"
          ) +
          " " +
          this.translateService.instant(
            this.pointsCalculationsService.dineInAltDineinAlternativeNames(
              this.pointsCalculationsService.calculateDeliveryMethod(
                this.orderPickup,
                this.dineIn,
                {
                  alt_dinein_active:
                    this.store_info &&
                    this.store_info.dinein &&
                    this.store_info.alt_dinein_active &&
                    this.selectedDineInTable &&
                    this.selectedDineInTable.alt_dinein,
                  selectedDineInTable: this.selectedDineInTable,
                }
              ),
              this.store_info,
              this.selectedLang,
              this.mo_base
            )
          ) +
          " " +
          this.pointsFactorPlatformMessage() +
          this.translateService.instant(
            "cart.cart-components.cart-prices.used-factor"
          ) +
          " " +
          (this.pointsFactor.factor * 100).toFixed(0) +
          "%" +
          "</p>",
        buttons: [
          {
            text: this.translateService.instant("more"),
            handler: () => {
              this.router.navigateByUrl("/points?segment=rules", {
                replaceUrl: true,
              });
            },
          },
          {
            text: "OK",
            role: "cancel",
            handler: () => {},
          },
        ],
      });
      await alert.present();
    }
  }

  toFloat(num) {
    if (num) {
      const stringNumb = num.toString();

      return parseFloat(stringNumb).toFixed(2);
    } else {
      return 0;
    }
  }

  substructCouponDiscountAndDiscount(couponDisc, discount) {
    const couponDiscount: number = parseFloat(couponDisc.toString());
    const discounPrices: number = parseFloat(discount.toString());

    if (couponDiscount > discounPrices) {
      return parseFloat((couponDiscount - discounPrices).toString()).toFixed(2);
    }
  }

  cartPricesValidation() {
    let validationObj = {
      id: "cart-prices",
      valid: true,
      errorMessages: [],
    };

    if (this.cartPrices && this.cartPrices.amountToMinimumOrder) {
      validationObj.valid = false;
      validationObj.errorMessages.push(
        this.translateService.instant(
          "cart.cart-components.cart-list.you-need"
        ) +
          " " +
          this.cartPrices.amountToMinimumOrder.toString() +
          "€ " +
          this.translateService.instant(
            "cart.cart-components.cart-list.in-order-to-reach-the-min-order"
          )
      );
    }
    this.store.dispatch(
      new CompleteOrderValidationActions.AddUpdateCompleteOrderValidation(
        _.cloneDeep(validationObj)
      )
    );
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }

    if (this.vatLevelsDetailsModal) {
      this.vatLevelsDetailsModal.dismiss();
      this.vatLevelsDetailsModal = null;
    }

    if (this.deliveryCostInfoModal) {
      this.deliveryCostInfoModal.unsubscribe();
      this.deliveryCostInfoModal = null;
    }
  }
}
