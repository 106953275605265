<ion-grid style="width: 190px; height: 180px; padding: 5px">
  <ion-card
    color="light"
    (click)="openGetPromoModal(reward, 'reward')"
    button="true"
    style="height: 180px"
    mode="md"
  >
    <div *ngIf="!reward.image" style="margin-top: 100px"></div>
    <img
      class="rewardImage"
      alt="rewardImage"
      loading="lazy"
      *ngIf="reward.image"
      [src]="
        'https://d3vm1ym7xlqx7l.cloudfront.net/' +
        groupApp.group +
        '/rewards/' +
        reward.image
      "
    />
    <ion-item color="light" class="noPaddingsItem" lines="none">
      <ion-label class="ion-text-wrap" style="margin: 0">
        <div style="font-size: small; font-weight: bold; line-height: 1.1">
          <ion-text> {{ reward.title }} </ion-text>
        </div>
      </ion-label>
    </ion-item>
    <ion-badge
      *ngIf="reward.points"
      style="position: absolute; top: 5px; right: 5px"
    >
      {{ reward.points }} {{ "points.rewards.points" | translate }}
    </ion-badge>
    <ion-ripple-effect></ion-ripple-effect>
  </ion-card>
</ion-grid>
