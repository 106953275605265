import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { Subscription } from "rxjs";

@Component({
  selector: "app-delivery-areas-info-modal",
  templateUrl: "./delivery-areas-info-modal.component.html",
  styleUrls: ["./delivery-areas-info-modal.component.scss"],
})
export class DeliveryAreasInfoModalComponent implements OnInit, OnDestroy {
  @Input() currentDeliveryArea: any;
  private unsubscribeBackEvent: Subscription;
  constructor(
    private modalController: ModalController,
    private platform: Platform
  ) {}

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      40,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );
    console.log("modal info delivery areas", this.currentDeliveryArea);
  }

  closeModal() {
    this.modalController.dismiss();
  }

  ngOnDestroy() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
  }
}
