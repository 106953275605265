import { Action } from "@ngrx/store";

export const SET_PREVIOUS_ORDERS = "[PREVIOUS ORDERS] Set Previous Orders";

export const CLEAR_PREVIOUS_ORDERS = "[PREVIOUS ORDERS] Clear PreviouSOrders";

export const SET_ON_PREORDER_CHANGE_FETCH_AGAIN =
  "[PREVIOUS ORDERS] Set On Preorder Change Fetch again";

export const SET_PREVIOUS_ORDER_CART =
  "[PREVIOUS ORDERS] Set Previous Order Cart";
export const CLEAR_PREVIOUS_ORDER_CART =
  "[PREVIOUS ORDERS] Clear Previous Order Cart";
export const ADD_UPDATE_PREVIOUS_ORDER_CART_ITEM =
  "[PREVIOUS ORDERS] Add Update Previous Order Cart Item";
export const DELETE_PREVIOUS_ORDER_CART_ITEM =
  "[PREVIOUS ORDERS] Delete Previous Order Cart Item";

export class SetPreviousOrders implements Action {
  readonly type = SET_PREVIOUS_ORDERS;
  constructor(public payload: any) {}
}

export class ClearPreviousOrders implements Action {
  readonly type = CLEAR_PREVIOUS_ORDERS;
  constructor() {}
}

export class SetOnPreorderChangeFetchAgain implements Action {
  readonly type = SET_ON_PREORDER_CHANGE_FETCH_AGAIN;
  constructor(public payload: any) {}
}

export class SetPreviousOrderCart implements Action {
  readonly type = SET_PREVIOUS_ORDER_CART;
  constructor(public payload: any) {}
}

export class ClearPreviousOrderCart implements Action {
  readonly type = CLEAR_PREVIOUS_ORDER_CART;
  constructor() {}
}

export class AddUpdatePreviousOrderCartItem implements Action {
  readonly type = ADD_UPDATE_PREVIOUS_ORDER_CART_ITEM;
  constructor(public payload: any) {}
}

export class DeletePreviousOrderCartItem implements Action {
  readonly type = DELETE_PREVIOUS_ORDER_CART_ITEM;
  constructor(public payload: any) {}
}

export type PreviousOrdersActions =
  | SetPreviousOrders
  | SetOnPreorderChangeFetchAgain
  | ClearPreviousOrders
  | SetPreviousOrderCart
  | ClearPreviousOrderCart
  | AddUpdatePreviousOrderCartItem
  | DeletePreviousOrderCartItem;
