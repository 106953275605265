<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">
      <ion-text>
        {{ "popups.navigation-to-bank-modal.title" | translate }}
      </ion-text>
    </ion-title>
    <ion-button
      (click)="closeModal()"
      size="small"
      slot="end"
      fill="clear"
      color="dark"
    >
      <ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid class="ion-text-center ion-no-padding" style="max-width: 150px">
    <ng-lottie
      (animationCreated)="animationCreated($event)"
      [options]="options"
    ></ng-lottie>
  </ion-grid>
  <ion-grid>
    <ion-item lines="none">
      <ion-label
        class="ion-text-wrap ion-text-center"
        style="font-weight: bold"
        >{{
          "popups.navigation-to-bank-modal.navigating-to-safe-bank-enviroment"
            | translate
        }}</ion-label
      >
    </ion-item>

    <ion-item *ngIf="device && device === 'mobile'">
      <ion-label class="ion-text-wrap ion-text-center" style="font-weight: bold"
        ><p style="font-weight: bold; font-size: small; margin: 0">
          {{
            "popups.navigation-to-bank-modal.if-you-have-a-problem-press-try-again"
              | translate
          }}
        </p>
      </ion-label>
    </ion-item>

    <ion-item
      class="browser-item"
      lines="none"
      *ngIf="device && device === 'browser'"
    >
      <ion-label style="line-height: 1.1" class="ion-text-wrap"
        ><p style="font-weight: bold; font-size: small; margin: 0">
          {{
            "popups.navigation-to-bank-modal.please-check-this-if-you-have-a-problem"
              | translate
          }}
        </p>
      </ion-label>
    </ion-item>

    <ion-item
      class="browser-item"
      lines="none"
      *ngIf="device && device === 'browser'"
    >
      <ion-icon
        slot="start"
        style="font-size: 25px; margin-right: 10px"
        color="success"
        src="assets/ionicons/checkmark-circle-outline.svg"
      >
      </ion-icon>
      <ion-label style="line-height: 1.1" class="ion-text-wrap">
        <p style="font-size: small; margin: 0">
          {{
            "popups.navigation-to-bank-modal.disable-extensions-like-adblock"
              | translate
          }}
        </p>
      </ion-label>
    </ion-item>

    <ion-item
      class="browser-item"
      lines="none"
      *ngIf="device && device === 'browser'"
    >
      <ion-icon
        slot="start"
        style="font-size: 25px; margin-right: 10px"
        color="success"
        src="assets/ionicons/checkmark-circle-outline.svg"
      >
      </ion-icon>
      <ion-label style="line-height: 1.1" class="ion-text-wrap">
        <p style="font-size: small; margin: 0">
          {{
            "popups.navigation-to-bank-modal.you-have-enable-popups-and-redirects-in-your-browser"
              | translate
          }}
        </p>
      </ion-label>
    </ion-item>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-grid>
    <ion-row style="width: 100%">
      <ion-col class="ion-text-center">
        <ion-button (click)="tryAgain()">{{
          "popups.navigation-to-bank-modal.try-again" | translate
        }}</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
