<div
  *ngIf="groupApp && store_info && groupApp.design_settings && groupApp.design_settings.beatCatalogHeader
    && !groupApp.store_change_catalog_buttons && !store_info.virtual_store_change_hyper_categories_buttons 
    && !store_info.vatBasedCustomerProductsActive  
    && groupApp.design_settings.enableMenuHeaderLogos 
    && groupApp.beatMenuHeaderLogo &&
    windowDimensions && ((windowDimensions.width>340 && !(groupApp.design_settings && groupApp.design_settings.menuFooterTabs))|| (windowDimensions.width>340 && (groupApp.design_settings && groupApp.design_settings.menuFooterTabs) )) && !sideMenuIsOpen"
  [ngClass]="
    {
      iosBeatHeaderIconMobile: isIos && windowDimensions && windowDimensions.width<= 550 ,
      iosBeatHeaderIconMedian: isIos && windowDimensions && windowDimensions.width > 550 ,
      iosBeatHeaderIconLarge: isIos && windowDimensions && windowDimensions.width >= 850 ,
      androidBeatHeaderIconLarge:!isIos && windowDimensions && windowDimensions.width > 550 ,
      androidBeatHeaderIconMobile:!isIos && windowDimensions && windowDimensions.width <= 550  
  
    }
  "
>
  <div
    *ngIf="(selectedTheme && selectedTheme==='light') || !groupApp.beatMenuHeaderLogo"
  >
    <img
      alt="logo"
      height="100%"
      src="https://images.deliverymanager.gr/{{ groupApp.group }}/header/{{
        groupApp.beatMenuHeaderLogo
      }}"
    />
  </div>
  <div
    *ngIf="selectedTheme && selectedTheme==='dark' && groupApp.beatMenuHeaderLogoDark"
  >
    <img
      alt="logo"
      height="100%"
      src="https://images.deliverymanager.gr/{{ groupApp.group }}/header/{{
           groupApp.beatMenuHeaderLogoDark
      }}"
    />
  </div>
</div>

<ion-header
  [class]="transparentCatalogElements ? 'transparentCatalogElements catalogBackgroundStyles' : 'nonTransparentCatalogElements'"
>
  <app-no-internet-connection></app-no-internet-connection>
  <app-ionic-update-error></app-ionic-update-error>

  <ion-toolbar
    *ngIf="store_info && store_info.vatBasedCustomerProductsActive && !hyperCategories && catalogEmpty && noMigrationCustomerIdInvoiceData"
  >
    <ion-buttons slot="start" *ngIf="groupApp && windowDimensions">
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>

    <ion-buttons
      slot="end"
      *ngIf="currentView === 'browserCatalogLg' || currentView === 'browserCatalogMd'"
    >
      <app-catalog-search-component
        [fabView]="false"
      ></app-catalog-search-component>
    </ion-buttons>

    <ion-title>{{'catalog.title' |translate}}</ion-title>
  </ion-toolbar>

  <ion-toolbar
    *ngIf="(store_info && store_info.design_settings &&store_info.design_settings.mobileorderHideCartCatalog) || (groupApp && groupApp.store_change_catalog_buttons && stores && stores.length>1) ||((hyperCategories && !hyperCategories.length && windowDimensions && windowDimensions.width<1500) || (hyperCategories && hyperCategories.length && hyperCategories.length >0 && windowDimensions && windowDimensions.width<1600))"
    [ngClass]="{
      transparentToolbar: transparentCatalogElements,
      catalogBackgroundStyles:transparentCatalogElements,
      beatBorderBottom: groupApp && groupApp.design_settings && groupApp.design_settings.beatCatalogHeader
    }"
    style="position: relative"
  >
    <ion-grid
      style="z-index: 10000; width: 100%"
      class="ion-text-center"
      *ngIf=" currentUser &&
        !groupApp.store_change_catalog_buttons && store_info && !store_info.virtual_store_change_hyper_categories_buttons &&
         store_info && store_info.vatBasedCustomerProductsActive &&
         hyperCategories && currentView&&  (currentView==='mobileCatalog' || catalogEmpty )
        "
    >
      <ion-chip (click)="presentSelectInvoiceModal()">
        <ion-text style="font-size: small">
          <strong>{{ "catalog.cart-catalog.vatNumber" | translate }}: </strong>
          {{ selectedInvoice && selectedInvoice.vatNumber ?
          selectedInvoice.vatNumber : ("catalog.cart-catalog.no-selection" |
          translate) }}
        </ion-text>
      </ion-chip>
    </ion-grid>

    <div
      *ngIf="store_info && !store_info.virtual_store_change_hyper_categories_buttons&&
        groupApp.store_change_catalog_buttons &&
        stores &&
        stores.length > 1 &&
        hyperCategories
      "
      style="z-index: 10000; width: 100%"
    >
      <ion-segment
        mode="ios"
        style="max-width: 340px"
        [(ngModel)]="selectedStoreId"
        (ngModelChange)="selectedStoreChangedFromSegment($event)"
      >
        <ion-segment-button
          [ngClass]="{selectedSegment: store &&  selectedStoreId && store.store_id === selectedStoreId, selectedSegmentMinHeight: store.store_change_catalog_buttons_icon}"
          [value]="store.store_id"
          [disabled]="disableChangeStoreSegments"
          *ngFor="let store of stores"
        >
          <ion-icon
            *ngIf="store.store_change_catalog_buttons_icon"
            [src]="deliveryManagerPath +store.store_id + '/' + 'storeChangeCatalogButtonsIcons/' +  store.store_change_catalog_buttons_icon"
          >
          </ion-icon>

          <ion-text style="font-size: small; font-weight: bold">
            {{ store.store_name_catalog_change_langs &&
            store.store_name_catalog_change_langs[languagePath] ?
            store.store_name_catalog_change_langs[languagePath] :
            store.store_name_catalog_change_langs &&
            store.store_name_catalog_change_langs.el ?
            store.store_name_catalog_change_langs.el :
            store.store_name_mobileorder &&
            store.store_name_mobileorder[languagePath] ?
            store.store_name_mobileorder[languagePath] : store.store_name_langs
            && store.store_name_langs[languagePath] ?
            store.store_name_langs[languagePath] : store.store_name_langs &&
            store.store_name_langs.el ? store.store_name_langs.el :
            store.store_name ? store.store_name : "" }}</ion-text
          >
        </ion-segment-button>
      </ion-segment>
    </div>

    <div
      *ngIf=" store_info && 
        store_info.virtual_store_change_hyper_categories_buttons && !groupApp.store_change_catalog_buttons &&
      hyperCategories
    "
      style="z-index: 10000; width: 100%"
    >
      <ion-segment
        mode="ios"
        style="max-width: 450px"
        [(ngModel)]="virtualSelectedHyperCategoryId"
        (ngModelChange)="selectHyperCategoryChangeFromSegment($event)"
      >
        <ion-segment-button
          [class]=" virtualSelectedHyperCategoryId === hyperCat.hyper_category_id ? 'selected-segment':''"
          [value]="hyperCat.hyper_category_id"
          [disabled]="disableChangeStoreSegments"
          *ngFor="let hyperCat of hyperCategories"
        >
          <ion-text style="font-size: small">
            {{hyperCat.name | translate}}</ion-text
          >
        </ion-segment-button>
      </ion-segment>
    </div>

    <ion-title
      *ngIf="groupApp && !groupApp.store_change_catalog_buttons  && (!groupApp.design_settings || !groupApp.design_settings.enableMenuHeaderLogos || (stores && stores.length>1 && groupApp && (!groupApp.design_settings || !groupApp.design_settings.beatCatalogHeader)) || !groupApp.design_settings.sideMenuHeaderLogo || !groupApp.headerLogo) && store_info && !store_info.virtual_store_change_hyper_categories_buttons &&!store_info.vatBasedCustomerProductsActive && languagePath"
    >
      {{ store_info.store_name_mobileorder &&
      store_info.store_name_mobileorder[languagePath] ?
      store_info.store_name_mobileorder[languagePath] :
      (store_info.store_name_langs && store_info.store_name_langs[languagePath]
      ? store_info.store_name_langs[languagePath] : store_info.store_name_langs
      && store_info.store_name_langs.el ? store_info.store_name_langs.el :
      store_info.store_name ? store_info.store_name : "") }}
    </ion-title>

    <ion-title
      [class]="stores && stores.length ===1 ? 'ion-text-center'  : 'padding-title-enableMenuHeaderLogos ion-text-center'"
      *ngIf="groupApp && groupApp.design_settings && groupApp.design_settings.beatCatalogHeader&& !groupApp.store_change_catalog_buttons && !store_info.virtual_store_change_hyper_categories_buttons && !store_info.vatBasedCustomerProductsActive  &&  groupApp.design_settings  && groupApp.design_settings.enableMenuHeaderLogos && groupApp.design_settings.sideMenuHeaderLogo &&
        groupApp.headerLogo && selectedTheme"
    >
      <ion-text
        *ngIf="groupApp && groupApp.design_settings && groupApp.design_settings.beatCatalogHeader"
        [class]="windowDimensions && windowDimensions.width>=450 ? 'mediumFontSizeBeatHeader' : 'smallFontSizeBeatHeader'"
      >
        {{ store_info.store_name_catalog_change_langs &&
        store_info.store_name_catalog_change_langs[languagePath] ?
        store_info.store_name_catalog_change_langs[languagePath] :
        store_info.store_name_catalog_change_langs &&
        store_info.store_name_catalog_change_langs.el ?
        store_info.store_name_catalog_change_langs.el :
        store_info.store_name_mobileorder &&
        store_info.store_name_mobileorder[languagePath] ?
        store_info.store_name_mobileorder[languagePath] :
        store_info.store_name_langs && store_info.store_name_langs[languagePath]
        ? store_info.store_name_langs[languagePath] :
        store_info.store_name_langs && store_info.store_name_langs.el ?
        store_info.store_name_langs.el : store_info.store_name ?
        store_info.store_name : "" }}
      </ion-text>
    </ion-title>

    <ion-title
      mode="ios"
      *ngIf=" groupApp && !groupApp.store_change_catalog_buttons && (!groupApp || !groupApp.design_settings || !groupApp.design_settings.beatCatalogHeader) && !store_info.virtual_store_change_hyper_categories_buttons && !store_info.vatBasedCustomerProductsActive  &&  groupApp.design_settings  && groupApp.design_settings.enableMenuHeaderLogos && groupApp.design_settings.sideMenuHeaderLogo &&
        groupApp.headerLogo && selectedTheme"
    >
      <div
        style="height: 45px"
        *ngIf="(selectedTheme && selectedTheme==='light') || !groupApp.headerLogoDark"
      >
        <img
          alt="logo"
          height="90%"
          src="https://images.deliverymanager.gr/{{ groupApp.group }}/header/{{
            groupApp.headerLogo
          }}"
        />
      </div>
      <div
        style="height: 45px"
        *ngIf="selectedTheme && selectedTheme==='dark' && groupApp.headerLogoDark"
      >
        <img
          alt="logo"
          height="90%"
          src="https://images.deliverymanager.gr/{{ groupApp.group }}/header/{{
            groupApp.headerLogoDark
          }}"
        />
      </div>
    </ion-title>

    <ion-buttons slot="end">
      <ion-buttons
        slot="end"
        *ngIf="currentView === 'browserCatalogLg' || currentView === 'browserCatalogMd'"
      >
        <app-catalog-search-component
          [fabView]="false"
        ></app-catalog-search-component>
      </ion-buttons>

      <app-cart-icon
        routerDirection="root"
        (click)="navigateToCart()"
        [mobileorderHideCartCatalog]="store_info && store_info.design_settings &&store_info.design_settings.mobileorderHideCartCatalog && currentView !== 'mobileCatalog'"
        slot="end"
        *ngIf="store_info && currentView  &&(currentView ==='mobileCatalog' || (store_info && store_info.design_settings && store_info.design_settings.mobileorderHideCartCatalog ))"
      ></app-cart-icon>
    </ion-buttons>

    <ion-buttons slot="start" *ngIf="groupApp && windowDimensions">
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>

    <!-- <div
      *ngIf="groupApp && ((stores && stores.length === 1) || (groupApp && groupApp.design_settings && groupApp.design_settings.beatCatalogHeader))&& !groupApp.store_change_catalog_buttons && !store_info.virtual_store_change_hyper_categories_buttons && !store_info.vatBasedCustomerProductsActive  &&  groupApp.design_settings  && groupApp.design_settings.enableMenuHeaderLogos && groupApp.design_settings.sideMenuHeaderLogo &&
        groupApp.headerLogo && selectedTheme && groupApp.design_settings && groupApp.design_settings.beatCatalogHeader && windowDimensions && windowDimensions.width>340"
      [class]="isIos && windowDimensions && windowDimensions.width <850 ? 'ios-beat-header' : 'android-beat-header'"
    >
      <div
        style="height: 45px"
        *ngIf="(selectedTheme && selectedTheme==='light') || !groupApp.headerLogoDark"
      >
        <ion-img
          alt="logo"
          style="height: 100%"
          src="https://images.deliverymanager.gr/{{ groupApp.group }}/header/{{
            groupApp.headerLogo
          }}"
        ></ion-img>
      </div>
      <div
        style="height: 45px"
        *ngIf="selectedTheme && selectedTheme==='dark' && groupApp.headerLogoDark"
      >
        <ion-img
          alt="logo"
          style="height: 100%"
          src="https://images.deliverymanager.gr/{{ groupApp.group }}/header/{{
            groupApp.headerLogo
           }}"
        ></ion-img>
      </div>
    </div> -->
  </ion-toolbar>

  <app-hyper-categories-selection
    *ngIf=" (currentView ==='mobileCatalog' || currentView === 'browserCatalogMd') && store_info && store_info.design_settings &&
    store_info.design_settings.showOnlySelectedCategoryProducts && hyperCategories && hyperCategories.length>0"
  >
  </app-hyper-categories-selection>

  <app-horizontal-hyper-categories
    *ngIf="(currentView ==='mobileCatalog' || currentView === 'browserCatalogMd') && (hyperCategories && hyperCategories.length>0 && !(store_info &&
    store_info.design_settings &&
    store_info.design_settings.showOnlySelectedCategoryProducts))  && !catalogEmpty && showCategoriesSlider && store_info && !store_info.virtual_store_change_hyper_categories_buttons"
  >
  </app-horizontal-hyper-categories>
  <app-horizontal-categories
    *ngIf="(currentView ==='mobileCatalog' || currentView === 'browserCatalogMd') && ((hyperCategories && hyperCategories.length===0 ) || (store_info &&
    store_info.design_settings &&
    store_info.design_settings.showOnlySelectedCategoryProducts)) && !catalogEmpty && showCategoriesSlider"
  >
  </app-horizontal-categories>
  <app-horizontal-categories
    *ngIf="(currentView ==='mobileCatalog' || currentView === 'browserCatalogMd') && hyperCategories && hyperCategories.length>0 && store_info && store_info.virtual_store_change_hyper_categories_buttons && showCategoriesSlider"
  >
  </app-horizontal-categories>
</ion-header>
<app-catalog-search-component
  style="position: absolute; bottom: 35px; right: 380px; z-index: 1000"
  *ngIf="(!scrollFromBottom ||(scrollFromBottom && scrollFromBottom>50)) && groupApp && !groupApp.store_change_catalog_buttons &&currentView && currentView === 'browserCatalogLg' && ((hyperCategories && hyperCategories.length && windowDimensions.width>=1600) || ((hyperCategories && !hyperCategories.length && windowDimensions.width>=1500))) && !(( store_info && store_info.design_settings &&store_info.design_settings.mobileorderHideCartCatalog))"
  [fabView]="false"
></app-catalog-search-component>
<ion-grid
  style="height: 100%"
  *ngIf="(!windowDimensions && !hyperCategories ) && !catalogEmpty"
>
  <ion-row
    class="ion-justify-content-center ion-align-items-center"
    style="width: 100%; height: 100%"
  >
    <ion-col class="ion-align-self-center">
      <ion-spinner color="dark"> </ion-spinner>
    </ion-col>
  </ion-row>
</ion-grid>
<ion-backdrop
  style="z-index: 1000"
  *ngIf="swipeInfoAnimation && (currentView==='mobileCatalog' ||  currentView==='browserCatalogMd' )"
>
</ion-backdrop>
<ion-grid
  *ngIf="swipeInfoAnimation && (currentView==='mobileCatalog' ||  currentView==='browserCatalogMd' )"
  style="
    width: 100%;
    height: 100%;
    position: absolute;
    top: 100px;
    z-index: 1001;
  "
>
  <div style="position: absolute; bottom: 35%; width: 100%; z-index: 1001">
    <ion-grid style="max-width: 250px">
      <ng-lottie
        (animationCreated)="animationCreated($event)"
        [options]="options"
      ></ng-lottie>
    </ion-grid>
    <ion-grid style="max-width: 320px">
      <ion-card style="border: 1px solid white">
        <ion-grid class="ion-no-padding">
          <ion-row style="width: 100%">
            <ion-col class="ion-text-center">
              <ion-list>
                <ion-item lines="none" style="background-color: transparent">
                  <ion-label
                    style="margin: 0"
                    class="ion-text-wrap ion-text-center"
                  >
                    {{"catalog.you-can-swipe-to-change-category" | translate}}
                  </ion-label>
                </ion-item>
                <ion-item lines="none" style="background-color: transparent">
                  <ion-label style="font-size: small" class="ion-text-wrap">
                    {{"catalog.dont-show-this-again" | translate}}
                  </ion-label>
                  <ion-checkbox
                    [(ngModel)]="catalogInfoSwipeCheckbox"
                    slot="end"
                  ></ion-checkbox>
                </ion-item>
                <ion-button size="small" (click)="closeCatalogSwipeInfo()"
                  >{{'catalog.ok-got-it' | translate}}
                </ion-button>
              </ion-list>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card>
    </ion-grid>
  </div>
</ion-grid>

<ion-content
  *ngIf="catalogEmpty || (hyperCategories && windowInfo)"
  scrollX="false"
  scrollY="false"
  [scrollEvents]="false"
  [class]="transparentCatalogElements ? 'transparentCatalogElements catalogBackgroundStyles' : 'nonTransparentCatalogElements'"
>
  <ion-grid
    style="max-width: 600px"
    *ngIf="catalogEmpty && noMigrationCustomerIdInvoiceData && !hyperCategories && store_info && store_info.vatBasedCustomerProductsActive"
  >
    <ion-grid style="width: 150px; height: 150px; padding: 0">
      <ng-lottie [options]="animationNoProductsOptions"></ng-lottie>
    </ion-grid>
    <ion-item lines="none">
      <ion-label
        style="font-size: medium"
        class="ion-text-wrap ion-text-center"
      >
        {{"catalog.catalog-is-empty-from-admin" | translate}}
      </ion-label>
    </ion-item>
  </ion-grid>
  <ion-grid class="catalogGrid" *ngIf="hyperCategories">
    <div
      [class]="windowDimensions.width<850 ? 'info-message-mobile-card' : 'info-message-browser-card'"
      *ngIf="loginState && (loginState==='no_login' || loginState ==='login_completed')  &&((infoMessagesStore && infoMessagesStore.alert && infoMessagesStore.alert.length)) && languagePath && mo_base && groupApp && windowDimensions && windowDimensions.width && windowDimensions.width<1080"
    >
      <ion-card
        [ngClass]="infoMessagesAnimations[catalogInfoMessage.id] ?    'animate__animated animate__backInRight' :  'animate__animated animate__fadeOut'"
        [hidden]="infoMessagesCardsHide[catalogInfoMessage.id] "
        color="primary"
        mode="md"
        [ngStyle]="{top: (50+ index * 10).toString() + '%'}"
        [button]="infoMessagesStore.alert_link ? true: false"
        (click)="openExternalLink(infoMessagesStore.alert_link)"
        *ngFor="let catalogInfoMessage of infoMessagesStore.alert;let index = index"
      >
        <ion-icon
          (click)="$event.stopImmediatePropagation() ;closeInfoCard(catalogInfoMessage.id)"
          style="
            position: absolute;
            top: 1px;
            right: 1px;
            font-size: 19px;
            cursor: pointer;
            z-index: 150;
          "
          src="assets/ionicons/close.svg"
        ></ion-icon>

        <ion-row
          class="infoMessageCardRow ion-align-items-center ion-justify-content-center"
        >
          <ion-col
            *ngIf="catalogInfoMessage.image"
            size="3"
            class="ion-no-padding"
          >
            <ion-thumbnail class="infoMessageThumb">
              <img
                src="https://s3-eu-west-1.amazonaws.com/storegroupseditedbgimages/{{
              groupApp.group
            }}/info_messages/{{ catalogInfoMessage.image }}"
              />
            </ion-thumbnail>
          </ion-col>
          <ion-col
            [size]="!catalogInfoMessage.image ? '12':'9'"
            class="ion-no-padding"
          >
            <div>
              <ion-text
                [innerHTML]="catalogInfoMessage.title[languagePath] ?
        catalogInfoMessage.title[languagePath]
        :(catalogInfoMessage.title[mo_base] ?
        catalogInfoMessage.title[mo_base] :
        catalogInfoMessage.title['en']) "
                style="line-height: 1.5; font-weight: bold"
                *ngIf="catalogInfoMessage && catalogInfoMessage.title"
              ></ion-text>
            </div>
          </ion-col>
        </ion-row>
        <ion-progress-bar
          *ngIf="catalogInfoMessage.auto_dismiss && catalogInfoMessage.auto_dismiss_seconds"
          [value]="motoCardsProgressValues[catalogInfoMessage.id]"
          color="success"
          style="height: 5px"
        ></ion-progress-bar>
      </ion-card>
    </div>
    <ion-grid
      *ngIf="catalogEmpty && store_info"
      style="width: 150px; height: 150px; padding: 0"
    >
      <ng-lottie [options]="animationNoProductsOptions"></ng-lottie>
    </ion-grid>
    <ion-grid *ngIf="catalogEmpty && store_info" style="max-width: 600px">
      <ion-item lines="none">
        <ion-label
          style="font-size: medium"
          class="ion-text-wrap ion-text-center"
        >
          {{store_info && store_info.vatBasedCustomerProductsActive ?
          ("catalog.catalog-is-empty-for-vat" | translate) :
          ("catalog.catalog-is-empty-from-admin" | translate) }}
        </ion-label>
      </ion-item>
    </ion-grid>

    <ion-grid
      style="z-index: 10000; width: 100%"
      class="ion-text-center"
      *ngIf=" catalogEmpty && currentUser 
      && store_info && !store_info.virtual_store_change_hyper_categories_buttons &&
    !groupApp.store_change_catalog_buttons &&
  store_info.vatBasedCustomerProductsActive &&
     hyperCategories && ((hyperCategories && !hyperCategories.length && windowDimensions && windowDimensions.width>=1500) || (hyperCategories && hyperCategories.length && hyperCategories.length >0 && windowDimensions && windowDimensions.width>=1600))
    "
    >
      <ion-chip (click)="presentSelectInvoiceModal()">
        <ion-text style="font-size: small">
          <strong>{{ "catalog.cart-catalog.vatNumber" | translate }}: </strong>
          {{ selectedInvoice && selectedInvoice.vatNumber ?
          selectedInvoice.vatNumber : ("catalog.cart-catalog.no-selection" |
          translate) }}
        </ion-text>
      </ion-chip>
    </ion-grid>
    <span *ngIf="!catalogEmpty">
      <app-browser-catalog-lg
        [transparentCatalogElements]="transparentCatalogElements"
        (listScrollFromBottomEv)="scrollFromBottomEventCatalogLg($event)"
        (listScrollFromTopEv)="scrollFromTopEvent($event)"
        *ngIf="currentView === 'browserCatalogLg'"
        [catalogInlineInfoMessage]="catalogInlineInfoMessage"
        [motoCardsProgressValues]="motoCardsProgressValues"
        [infoMessagesCardsHide]="infoMessagesCardsHide"
        [rerenderProductsItemsTimestamp]="rerenderProductsItemsTimestamp"
      ></app-browser-catalog-lg>
      <app-browser-catalog-md
        [transparentCatalogElements]="transparentCatalogElements"
        *ngIf="currentView === 'browserCatalogMd'"
        [catalogInlineInfoMessage]="catalogInlineInfoMessage"
        [motoCardsProgressValues]="motoCardsProgressValues"
        [infoMessagesCardsHide]="infoMessagesCardsHide"
        [rerenderProductsItemsTimestamp]="rerenderProductsItemsTimestamp"
        (listScrollFromTopEv)="scrollFromTopEvent($event)"
        (listScrollFromBottomEv)="scrollFromBottomEventCatalogLg($event)"
      ></app-browser-catalog-md>
      <app-mobile-catalog
        *ngIf="currentView === 'mobileCatalog'"
        [rerenderProductsItemsTimestamp]="rerenderProductsItemsTimestamp"
        (listScrollFromTopEv)="scrollFromTopEvent($event)"
        (listScrollFromBottomEv)="scrollFromBottomEventCatalogLg($event)"
      ></app-mobile-catalog>
    </span>
    <span
      *ngIf="loginState && (loginState==='no_login' || loginState ==='login_completed')  &&((infoMessagesStore && infoMessagesStore.alert && infoMessagesStore.alert.length)) && languagePath && mo_base && groupApp && windowDimensions && windowDimensions.width && windowDimensions.width>=1080"
    >
      <div
        style="z-index: 1000"
        [class]="catalogInfoMessage && catalogInfoMessage.position ==='bottom_left' ? 'bottom-left-info-message': 'top-right-info-message'"
        *ngFor="let catalogInfoMessage of infoMessagesStore.alert;let index = index"
      >
        <ion-card
          [ngClass]="infoMessagesAnimations[catalogInfoMessage.id] ?    'animate__animated animate__backInRight' :  'animate__animated animate__fadeOut'"
          [hidden]="infoMessagesCardsHide[catalogInfoMessage.id] "
          [button]="catalogInfoMessage.alert_link ? true: false"
          (click)="openExternalLink(catalogInfoMessage.alert_link)"
          color="primary"
          mode="md"
          [ngStyle]="{top: (50+ index * 10).toString() + '%'}"
        >
          <ion-icon
            (click)="$event.stopImmediatePropagation(); closeInfoCard(catalogInfoMessage.id)"
            style="
              position: absolute;
              top: 1px;
              right: 1px;
              font-size: 19px;
              cursor: pointer;
              z-index: 150;
            "
            src="assets/ionicons/close.svg"
          ></ion-icon>

          <ion-row
            class="infoMessageCardRow ion-align-items-center ion-justify-content-center"
          >
            <ion-col
              *ngIf="catalogInfoMessage.image"
              size="3"
              class="ion-no-padding"
            >
              <ion-thumbnail class="infoMessageThumb">
                <img
                  src="https://s3-eu-west-1.amazonaws.com/storegroupseditedbgimages/{{
              groupApp.group
            }}/info_messages/{{ catalogInfoMessage.image }}"
                />
              </ion-thumbnail>
            </ion-col>
            <ion-col
              [size]="!catalogInfoMessage.image ? '12':'9'"
              class="ion-no-padding"
            >
              <div>
                <ion-text
                  [innerHTML]="catalogInfoMessage.title[languagePath] ?
        catalogInfoMessage.title[languagePath]
        :(catalogInfoMessage.title[mo_base] ?
        catalogInfoMessage.title[mo_base] :
        catalogInfoMessage.title['en']) "
                  style="line-height: 1.5; font-weight: bold"
                  *ngIf="catalogInfoMessage && catalogInfoMessage.title"
                ></ion-text>
              </div>
            </ion-col>
          </ion-row>
          <ion-progress-bar
            *ngIf="catalogInfoMessage.auto_dismiss && catalogInfoMessage.auto_dismiss_seconds"
            [value]="motoCardsProgressValues[catalogInfoMessage.id]"
            color="success"
            style="height: 5px"
          ></ion-progress-bar>
        </ion-card>
      </div>
    </span>
  </ion-grid>

  <app-cart-custom-options
    *ngIf="hyperCategories"
    [catalogMode]="true"
    [showQuantityOptions]="'quantity-options'"
  ></app-cart-custom-options>
</ion-content>

<ion-grid
  *ngIf="selectedCategoryImageAnimation && categoryImageAnimation && canShowAnimationCategories && currentView && currentView ==='browserCatalogLg' && store_info"
  style="
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    max-width: 240px;
    padding: 0;
  "
  [class]=" categoryImageAnimation ? 'animate__animated animate__' + selectedCategoryImageAnimation.animation : ''"
>
  <img
    width="100%"
    [src]="
  'https://images.deliverymanager.gr/' +
  store_info.store_id +
  '/categories/catalogCategoryAnimationImages/' +
  selectedCategoryImageAnimation.fileName
"
  />
</ion-grid>

<app-menu-footer-tabs
  *ngIf="(!cartProducts || !cartProducts.length) && showMenuFooterComponent"
  [hidden]="hideMenuFooterTabs"
  [playAnimationFadeIn]="footerTabsPlayAnimationFadeIn"
  [playAnimationFadeOut]="footerTabsPlayAnimationFadeOut"
>
</app-menu-footer-tabs>

<app-menu-footer-tabs-complete-order
  *ngIf="groupApp && groupApp.design_settings && groupApp.design_settings.menuFooterTabs"
  [scrollFromTop]="scrollFromTop"
  [scrollFromBottom]="scrollFromBottom"
>
</app-menu-footer-tabs-complete-order>
