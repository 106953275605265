<ion-card *ngIf="groupApp && groupApp.pointsSystem">
  <ion-item-divider mode="ios">
    <strong>{{
      "reservation-app.cart-active-coupons.your-active-coupons" | translate
    }}</strong>

    <ion-button
      *ngIf="showCouponsSheduleButton"
      (click)="openCouponsScheduleInfoModal()"
      slot="end"
      fill="clear"
    >
      <ion-icon
        src="assets/ionicons/information-circle-outline.svg"
        slot="icon-only"
      ></ion-icon>
    </ion-button>
  </ion-item-divider>

  <ion-grid *ngIf="activeCoupons && !activeCoupons.length">
    <ion-item lines="none" *ngIf="allActiveCoupons && allActiveCoupons.length"
      ><ion-label class="ion-text-wrap ion-text-center"
        >{{
          "reservation-app.cart-active-coupons.there-are-no-coupons-that-you-can-use-in-this-reservation"
            | translate
        }}
      </ion-label>
    </ion-item>

    <ion-item lines="none" *ngIf="allActiveCoupons && !allActiveCoupons.length"
      ><ion-label class="ion-text-wrap ion-text-center">
        {{
          "reservation-app.cart-active-coupons.there-are-no-coupons" | translate
        }}
      </ion-label>
    </ion-item>
  </ion-grid>
  <ion-grid
    *ngIf="activeCoupons && activeCoupons.length"
    style="max-width: 700px"
    class="padding"
  >
    <ion-item
      *ngIf="allCartProductsPromo && cartProducts && cartProducts.length"
      class="error-item"
      lines="none"
    >
      <ion-label class="ion-text-center ion-no-margin ion-text-wrap">
        <ion-text style="font-size: small; font-weight: bold" color="danger"
          >{{
            "reservation-app.cart-active-coupons.cart-products-cant-get-coupon"
              | translate
          }}
        </ion-text>
      </ion-label>
    </ion-item>
    <ion-item
      *ngFor="let coupon of activeCoupons; let i = index; trackBy: trackFunc"
      class="item item-text-wrap item-icon-left cartCoupons cartButtonsWithBordersStyle"
    >
      <ion-label class="ion-text-wrap">
        <strong
          *ngIf="
            coupon.discount &&
            coupon.discountType &&
            (coupon.discountType === 'percent' ||
              coupon.discountType === 'fixed')
          "
          >-{{
            coupon.discount + (coupon.discountType === "percent" ? "%" : "€")
          }}&nbsp;</strong
        >

        <strong
          *ngIf="
            coupon.discount_percent &&
            coupon.discount_fixed &&
            coupon.discountType &&
            coupon.discountType === 'combined'
          "
        >
          -{{ coupon.discount_percent }}% & -{{ coupon.discount_fixed }}€
          &nbsp;</strong
        >

        <strong
          *ngIf="
            !(
              (coupon.discountType !== 'percent' &&
                coupon.discountType !== 'combined' &&
                coupon.discount) ||
              (coupon.discountType === 'combined' &&
                coupon.discount_percent &&
                coupon.discount_fixed)
            ) &&
            coupon.product_id &&
            !coupon.classic_offer_product
          "
        >
          {{ "free" | translate }}
        </strong>

        {{ coupon.description }}
        <p
          style="
            font-size: x-small;
            font-weight: 700;

            line-height: 1.4;
          "
          [ngClass]="{ cartWarningLightColor: !coupon.validDate }"
          *ngIf="coupon.activeFrom || coupon.activeTo"
        >
          {{ "reservation-app.cart-active-coupons.valid" | translate }}&nbsp;

          {{ "reservation-app.cart-active-coupons.from" | translate }}
          {{
            coupon.activeFrom
              ? (coupon.activeFrom | date : "dd/MM/yyyy HH:mm")
              : ""
          }}

          {{ "reservation-app.cart-active-coupons.to" | translate }}
          {{ coupon.activeTo ? (coupon.activeTo | date : "dd/MM/yyyy") : "" }}
        </p>
        <!-- <p style="font-size: small;" *ngIf=" !coupon.validDate  && coupon.validDateException">
            <ion-text color="danger">
              {{"reservation-app.cart-active-coupons.today-exceptionally-you-cant-use-the-coupon" |
              translate}}
            </ion-text>
          </p> -->
        <p
          style="
            font-size: x-small;
            font-weight: 700;

            line-height: 1.4;
          "
          [ngClass]="{
            cartWarningLightColor:
              coupon.validCombo && coupon.validDate && !coupon.validMinOrder
          }"
          *ngIf="
            coupon.appliesToOrderGreaterThan &&
            coupon.appliesToOrderGreaterThan !== '0'
          "
        >
          {{
            "reservation-app.cart-active-coupons.minimum-order" | translate
          }}&nbsp;{{ coupon.appliesToOrderGreaterThan }}€
        </p>

        <p
          style="
            font-size: x-small;
            font-weight: 700;

            line-height: 1.4;
          "
        >
          {{
            "reservation-app.cart-active-coupons.cant-used-with-promo-products"
              | translate
          }}
        </p>

        <p
          *ngIf="coupon.preorder_only"
          style="
            font-size: x-small;
            font-weight: 700;

            line-height: 1.4;
          "
        >
          <ion-text color="danger">
            {{
              "reservation-app.cart-active-coupons.coupon-can-be-used-only-for-preorder"
                | translate
            }}
          </ion-text>
        </p>

        <p *ngIf="coupon.status === 'pending_confirmation'">
          <ion-text color="danger">
            {{
              "discount-coupons.coupons-item.coupon-must-be-confirmed-from-store"
                | translate
            }}
          </ion-text>
        </p>

        <p
          *ngIf="coupon.online_payment_only"
          style="
            font-size: x-small;
            font-weight: 700;

            line-height: 1.4;
          "
        >
          <ion-text color="danger">
            {{
              "reservation-app.cart-active-coupons.coupon-can-be-used-only-for-online-payment"
                | translate
            }}
          </ion-text>
        </p>

        <div>
          <ion-badge
            *ngIf="coupon.status"
            [color]="calculateBadgeColor(coupon)"
          >
            {{
              "reservation-app.discount-coupons.reservation-coupons-item." +
                coupon.status | translate
            }}
            <ion-text *ngIf="coupon.order_id && coupon.status === 'used'"
              >#{{ coupon.order_id }}</ion-text
            >
          </ion-badge>
          <ion-badge
            *ngIf="!coupon.validCombo && coupon.validDate"
            color="danger"
          >
            <ion-text class="cartWarningLightColor">
              {{
                "reservation-app.cart-active-coupons.coupon-cannot-be-combined-with-your-other-choices"
                  | translate
              }}
            </ion-text>
          </ion-badge>
        </div>
      </ion-label>
      <ion-checkbox
        [disabled]="finalPriceLoading || disableCheckbox"
        [(ngModel)]="coupon.couponCheckbox"
        (ionChange)="clickCheckboxCoupon(coupon)"
      >
      </ion-checkbox>
      <div
        style="position: absolute; bottom: 5px; right: 10px; font-size: x-small"
        *ngIf="coupon && coupon.points"
      >
        {{
          "reservation-app.discount-coupons.reservation-coupons-item.from_lowercase"
            | translate
        }}
        {{ coupon.points }}
        {{
          "reservation-app.discount-coupons.reservation-coupons-item.points"
            | translate
        }}
      </div>
    </ion-item>
  </ion-grid>
</ion-card>
