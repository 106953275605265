import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { auditTime, distinctUntilChanged } from "rxjs/operators";
import * as fromApp from "../../../store/app.reducer";
import * as _ from "lodash";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import * as OrdersActions from "../../../orders/store/orders.actions";

@Component({
  selector: "app-braintree-modal",
  templateUrl: "./braintree-modal.component.html",
  styleUrls: ["./braintree-modal.component.scss"],
})
export class BraintreeModalComponent implements OnInit, OnDestroy {
  @Input() braintree_iframe;
  private braintreeModalIsOpen: boolean = true;
  private subscription: Subscription;
  private unsubscribeBackEvent: Subscription;
  constructor(
    private modalCtrl: ModalController,
    private store: Store<fromApp.AppState>,
    private platform: Platform
  ) {}

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      300,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );
    const el: any = document.querySelector(".paymentModal");
    el.style.setProperty("--paymentModalWidth", "350px");
    el.style.setProperty("--paymentModalHeight", "620px");

    this.store.dispatch(new OrdersActions.SetBrainTreePopupOpen(true));
    this.subscription = this.store
      .select("orders")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (
          state &&
          !_.isEqual(this.braintreeModalIsOpen, state.braintreePopupOpen)
        ) {
          this.braintreeModalIsOpen = _.cloneDeep(state.braintreePopupOpen);
          if (!this.braintreeModalIsOpen) {
            this.closeModal();
          }
        }
      });
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  ngOnDestroy() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
