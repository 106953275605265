import { Action } from "@ngrx/store";

export const SET_PENDING_ORDERS = "[PENDING ORDERS] Set Pending Orders";
export const CLEAR_PENDING_ORDERS = "[PENDING ORDERS] Clear Pending Orders";
export const UPDATE_PENDING_ORDER = "[PENDING ORDERS] Update Pending Orders";
export const CHECK_PENDING_ORDERS_FROM_FETCH =
  "[PENDING ORDERS] Check Pending Orders From Fetch";

export class SetPendingOrders implements Action {
  readonly type = SET_PENDING_ORDERS;
  constructor(public payload: any) {}
}

export class UpdatePendingOrder implements Action {
  readonly type = UPDATE_PENDING_ORDER;
  constructor(public payload: any) {}
}

export class CheckPendingOrderFromFetch implements Action {
  readonly type = CHECK_PENDING_ORDERS_FROM_FETCH;
  constructor(public payload: any) {}
}

export class ClearPendingOrders implements Action {
  readonly type = CLEAR_PENDING_ORDERS;
  constructor() {}
}

export type PendingOrdersActions =
  | SetPendingOrders
  | ClearPendingOrders
  | UpdatePendingOrder
  | CheckPendingOrderFromFetch;
