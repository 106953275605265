<swiper class="loyalty-swiper" [loop]="true" [pagination]="{ clickable: true }" [autoplay]="{disableOnInteraction: false}" effect="fade" #swiperSlideShow>
  <ng-template swiperSlide *ngFor="let image of images">
    <ion-grid class="ion-no-padding">
      <ion-row style="width: 100%;">
        <ion-img [src]="image.path"></ion-img>
      </ion-row>
      <!-- <ion-row style="width: 100%;" class="ion-align-items-center ion-justify-content-center ion-padding">
        <ion-col size="10" class="ion-text-left">
          <ion-text style="font-size: medium;">Check out the offer 
            <ion-icon style="padding-left: 5px; font-size: 18px; vertical-align: middle;" src="/assets/flatIcons/pointing-right.svg"></ion-icon>
          </ion-text>
        </ion-col>
        <ion-col size="2" class="ion-text-right">
          <ion-button (click)="openInfoModal()" class="ion-no-padding" fill="clear" >
            <ion-icon name="information-circle-outline"></ion-icon>
          </ion-button>
        </ion-col>
      </ion-row> -->
    </ion-grid>
    <!-- <div class="swiper-info-div">

    </div> -->
  </ng-template>
</swiper>