<ion-grid
  class="ion-no-padding ion-no-margin"
  *ngIf="previousOrders && previousOrders.length > 0"
>
  <ion-item class="previousOrderItem">
    <ion-label>
      <ion-text color="primary">
        <strong style="font-size: large">{{
          "catalog.previous-order.previous-orders" | translate
        }}</strong>
      </ion-text>
    </ion-label>
    <ion-button
      *ngIf="sliderButtons"
      style="position: absolute; top: 0px; right: 30px"
      size="small"
      fill="clear"
      (click)="prevSlide()"
    >
      <ion-icon slot="icon-only" src="assets/ionicons/chevron-back-outline.svg">
      </ion-icon>
    </ion-button>
    <ion-button
      *ngIf="sliderButtons"
      style="position: absolute; top: 0px; right: 2px"
      size="small"
      (click)="nextSlide()"
      fill="clear"
    >
      <ion-icon
        slot="icon-only"
        src="assets/ionicons/chevron-forward-outline.svg"
      >
      </ion-icon>
    </ion-button>
  </ion-item>

  <ion-slides
    #previousOrdersSlider
    class="ion-text-center"
    scrollbar
    style="
      height: 115px;
      margin-left: 15px;
      margin-top: 10px;
      margin-bottom: 10px;
    "
    [options]="sliderOptions"
  >
    <ion-slide
      *ngFor="let order of previousOrders; trackBy: trackOrders"
      style="width: 190px; height: 110px"
      ><ion-card
        button
        mode="md"
        detail="false"
        (click)="showPreviousOrderModal(order)"
        style="width: 90%; height: 95%; z-index: 20; cursor: pointer"
      >
        <ion-item
          detail="false"
          style="z-index: 10"
          class="productItem"
          lines="none"
        >
          <ion-label class="ion-text-right">
            <ion-text
              color="primary"
              style="font-weight: bold; font-size: small"
              >#{{ order.order_id }}</ion-text
            >
          </ion-label>
        </ion-item>
        <ion-item
          detail="false"
          style="z-index: 10"
          class="productItem"
          lines="none"
          *ngFor="let product of order.products"
        >
          <ion-label style="font-weight: bold; font-size: small">
            <ion-text
              *ngIf="
                product &&
                (!product.enable_quantity_step || !product.quantity_step)
              "
            >
              {{ product.quantity }} x {{ product.name }}
            </ion-text>

            <ion-text
              *ngIf="product.enable_quantity_step && product.quantity_step"
            >
              {{ product.quantity
              }}{{
                !product.disable_qty && !product.quantity_step_kind
                  ? "x"
                  : product.quantity_step_kind
              }}

              {{ product.name }}</ion-text
            >

            <!-- <div>{{ order.sum_total }}€</div> -->
          </ion-label>
        </ion-item>

        <!-- <div
          class="ion-text-right positionBottomRight"
          *ngIf="product.initial_price || product.price"
          [ngClass]="{
            priceWidthUnavailable: !!product.priceWidthUnavailable,
            priceWidthAvailable: !!product.priceWidthAvailable
          }"
          style="font-size: small"
        >
          <span *ngIf="product.initial_price" class="line-through">
            {{ product.initial_price }}
          </span>
          <span>
            <ion-text
              color="primary"
              style="font-weight: 700; padding-right: 10px"
              >{{ product.price }}</ion-text
            >
          </span>
        </div> -->
      </ion-card>
    </ion-slide>
  </ion-slides>
</ion-grid>
