import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import * as _ from "lodash";
import { distinctUntilChanged } from "rxjs";
import * as fromApp from "../../../store/app.reducer";
import { Store } from "@ngrx/store";
@Component({
  selector: "app-loyalty-contest-gifts",
  templateUrl: "./loyalty-contest-gifts.component.html",
  styleUrls: ["./loyalty-contest-gifts.component.scss"],
})
export class LoyaltyContestGiftsComponent implements OnInit {
  @Input() contest: any;
  @Input() group: any;
  windowDimensions: any;
  windowDimInfoSub: any;

  constructor(
    private modalController: ModalController,
    private store: Store<fromApp.AppState>
  ) {}

  ngOnInit() {
    // console.log("gifts", this.contest.gifts);
    this.windowDimInfoSub = this.store
      .select("windowInfo")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          !_.isEqual(this.windowDimensions, state.windowDimensions)
        ) {
          this.windowDimensions = _.cloneDeep(state.windowDimensions);
        }
      });
  }

  closeModal() {
    this.modalController.dismiss();
  }
}
