import { Action } from "@ngrx/store";

export const SET_TRIGGER_FINAL_PRICE_TIMESTAMP =
  "[MANUAL TRIGGER FINAL PRICE CHECK ADDRESS] Set Trigger Final Price Timestamp";
export const SET_TRIGGER_CHECK_ADDRESS_TIMESTAMP =
  "[MANUAL TRIGGER FINAL PRICE CHECK ADDRESS] Set Trigger Check Address Timestamp";
export const SET_FINAL_PRICE_LOADING =
  "[MANUAL TRIGGER FINAL PRICE CHECK ADDRESS] Set Final Price Loading";
export const SET_CHECK_ADDRESS_LOADING =
  "[MANUAL TRIGGER FINAL PRICE CHECK ADDRESS] Set Check Address Loading";

export const SET_FINAL_PRICE_SUCCESS =
  "[MANUAL TRIGGER FINAL PRICE CHECK ADDRESS] Set Final Price Success";
export const SET_CHECK_ADDRESS_SUCCESS =
  "[MANUAL TRIGGER FINAL PRICE CHECK ADDRESS] Set check Address Success";

export class SetTriggerFinalPriceTimestamp implements Action {
  readonly type = SET_TRIGGER_FINAL_PRICE_TIMESTAMP;
  constructor(public payload: any) {}
}

export class SetTriggerCheckAddressTimestamp implements Action {
  readonly type = SET_TRIGGER_CHECK_ADDRESS_TIMESTAMP;
  constructor(public payload: any) {}
}

export class SetFinalPriceSuccess implements Action {
  readonly type = SET_FINAL_PRICE_SUCCESS;
  constructor(public payload: any) {}
}

export class SetCheckAddressSuccess implements Action {
  readonly type = SET_CHECK_ADDRESS_SUCCESS;
  constructor(public payload: any) {}
}

export class SetFinalPriceLoading implements Action {
  readonly type = SET_FINAL_PRICE_LOADING;
  constructor(public payload: any) {}
}

export class SetCheckAddresssLoading implements Action {
  readonly type = SET_CHECK_ADDRESS_LOADING;
  constructor(public payload: any) {}
}

export type ManuallyTriggerFinalPriceCheckAddressActions =
  | SetTriggerCheckAddressTimestamp
  | SetTriggerFinalPriceTimestamp
  | SetFinalPriceLoading
  | SetCheckAddresssLoading
  | SetFinalPriceSuccess
  | SetCheckAddressSuccess;
