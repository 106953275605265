import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import * as _ from "lodash";
import * as selectors from "../../../../store/app.selectors";
import * as fromApp from "../../../../store/app.reducer";
import { auditTime, debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";

@Component({
  selector: "app-radio-choices",
  templateUrl: "./radio-choices.component.html",
  styleUrls: ["./radio-choices.component.scss"],
})
export class RadioChoicesComponent implements OnInit, OnDestroy, OnChanges {
  @Input() selectedProductId: any;
  @Input() bundleMode: any;
  @Input() option_id;
  @Input() selectedProductPromo: any;
  @Input() selectedProductCategory: any;
  @Input() alt_dinein_active: any;
  @Input() groupStoreData: any;
  @Output() choicesChanged = new EventEmitter();
  public option: any;
  public loading: boolean = false;
  public browserPlatform: boolean;
  public imagePath;
  public selectedChoiceId;
  public productDetailsModalDimensions: any;
  public colSize: string;
  private stopReadFromSelector = false;
  private Subscriptions: Subscription[] = [];
  private optionSubscription: Subscription;

  constructor(private store: Store<fromApp.AppState>) {}

  selectOption() {
    if (this.optionSubscription) {
      this.optionSubscription.unsubscribe();
    }
    this.optionSubscription = this.store
      .select(
        selectors.getOptionByOptionId(this.selectedProductId, this.option_id)
      )
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (!_.isEqual(this.option, state) && !this.stopReadFromSelector) {
          this.option = _.cloneDeep(state);
          console.log("radio choices selector", this.option);
          if (!this.selectedChoiceId) {
            this.checkRadioValue();
          }
        }
      });
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (
      simpleChanges &&
      simpleChanges.option_id &&
      simpleChanges.option_id.currentValue !==
        simpleChanges.option_id.previousValue
    ) {
      this.selectOption();
    }
  }

  ngOnInit() {
    this.selectOption();
    this.Subscriptions.push(
      this.store
        .select("platformInfo")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(state.browserPlatform, this.browserPlatform)
          ) {
            this.browserPlatform = _.cloneDeep(state.browserPlatform);
          }
        })
    );

    this.Subscriptions.push(
      this.store
        .select(
          selectors.getSelectedProductModalDimensionsBySelectedProductId(
            this.selectedProductId
          )
        )
        .pipe(distinctUntilChanged())

        .subscribe((productDetailsModalDimensions) => {
          if (
            productDetailsModalDimensions &&
            !_.isEqual(
              this.productDetailsModalDimensions,
              productDetailsModalDimensions
            )
          ) {
            this.productDetailsModalDimensions = _.cloneDeep(
              productDetailsModalDimensions
            );
            this.calculateColumnsSize();
          }
        })
    );
  }
  checkForImage(choice) {
    if (
      choice.image &&
      choice.image[_.toString(this.browserPlatform)] &&
      choice.image[_.toString(this.browserPlatform)]["4x3"] &&
      choice.image[_.toString(this.browserPlatform)]["4x3"].path
    ) {
      this.imagePath =
        "https://images.deliverymanager.gr/" +
        choice.image[_.toString(this.browserPlatform)]["4x3"].path +
        (true
          ? ".webp"
          : choice.image[_.toString(this.browserPlatform)]["4x3"].type ||
            ".jpg");

      return true;
    } else {
      return false;
    }
  }

  onChoiceSelectedChange(ev) {
    this.stopReadFromSelector = true;

    if (this.selectedChoiceId) {
      let choice_id = _.cloneDeep(this.selectedChoiceId);

      let changedChoiceIndex = _.findIndex(this.option.choices, {
        choice_id: choice_id,
      });
      console.log("changedChoiceIndex", changedChoiceIndex);
      if (changedChoiceIndex !== -1) {
        if (
          this.option.choices[changedChoiceIndex].activeTotal &&
          this.option.choices[changedChoiceIndex].activeTotal
        ) {
          _.each(this.option.choices, (ch) => {
            if (ch.is_preselected) {
              ch.is_preselected = false;
            }
          });
          this.option.choices[changedChoiceIndex].is_preselected = true;
          if (document.activeElement.tagName === "ION-RADIO") {
            this.sendOptionChangedChoicesDebounce();
          }
        } else {
          console.log("something went wrong");
        }
      }
    } else {
      _.each(this.option.choices, (ch) => {
        if (ch.is_preselected) {
          ch.is_preselected = false;
        }
      });
      if (document.activeElement.tagName === "ION-RADIO") {
        this.sendOptionChangedChoicesDebounce();
      }
    }
  }

  private sendOptionChangedChoicesDebounce = _.debounce(() => {
    this.sendOptionChangedChoices(this.option);
  }, 400);

  sendOptionChangedChoices(opt) {
    this.choicesChanged.emit(_.cloneDeep(opt));
    this.stopReadFromSelector = false;
  }

  trackFn(index, choice) {
    return choice.option_id_choice_id;
  }

  checkRadioValue() {
    if (this.option) {
      this.option.choices.forEach((choice) => {
        if (choice.is_preselected) {
          console.log("choice id frim checking radio", choice.choice_id);

          this.selectedChoiceId = _.cloneDeep(choice.choice_id);
        }
      });
    }
  }

  calculateColumnsSize() {
    console.log("calc columns size called", this.productDetailsModalDimensions);
    if (this.option && this.option.choices && this.option.choices.length > 1) {
      let maxChoiceTitleChars = 0;
      let choiceImage = false;
      _.each(this.option.choices, (ch) => {
        if (ch.title && ch.title.length) {
          if (maxChoiceTitleChars < ch.title.length) {
            maxChoiceTitleChars = ch.title.length;
          }
        }
        if (this.checkForImage(ch)) {
          choiceImage = true;
        }
      });
      console.log("maxChoiceChars", maxChoiceTitleChars);

      if (this.productDetailsModalDimensions.width <= 400) {
        this.colSize = "12";
        console.log("colsize", this.colSize);
      } else if (
        this.productDetailsModalDimensions.width > 400 &&
        this.productDetailsModalDimensions.width < 600
      ) {
        this.colSize = "6";
        console.log("colsize", this.colSize);
      } else if (
        this.productDetailsModalDimensions.width >= 600 &&
        maxChoiceTitleChars < 15 &&
        !choiceImage
      ) {
        this.colSize = "4";
        console.log("colsize", this.colSize);
      } else if (
        this.productDetailsModalDimensions.width >= 600 &&
        maxChoiceTitleChars > 15
      ) {
        this.colSize = "6";
        console.log("colsize", this.colSize);
      } else {
        this.colSize = "6";
      }
    }
  }

  ngOnDestroy() {
    if (this.Subscriptions && this.Subscriptions.length > 0) {
      this.Subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    if (this.optionSubscription) {
      this.optionSubscription.unsubscribe();
    }
  }
}
