<ion-item
  *ngIf="product"
  lines="none"
  detail="false"
  button
  (click)="showProductDetailsModal(product)"
  [class]="productInCart ? 'added_to_cart_border' : ''"
>
  <div
    style="
      position: absolute;
      top: 0;
      left: 0px;
      z-index: 10000;
      font-size: small;
      font-weight: bold;
    "
    *ngIf="productInCartCounter && productInCartCounter > 1"
  >
    <ion-text color="primary"> x{{ productInCartCounter }}</ion-text>
  </div>
  <ion-row
    style="width: 100%"
    class="ion-padding-bottom ion-padding-top ion-no-padding-left ion-no-padding-right"
  >
    <ion-col
      class="ion-no-padding ion-no-margin"
      [ngClass]="{
        'unavailable categoriesBrowserItemUnavailable': !!(
          !product.availableTotal ||
          !product.product_categories_availability_check
        ),
        categoriesBrowserItemAvailable: product.availableTotal,
        'highlighted-product': product.highlighted
      }"
      size="12"
      style="line-height: initial"
    >
      <ion-text [color]="product.highlighted ? 'selected' : ''">
        <span
          style="font-weight: bold"
          [ngClass]="{
            'line-through': !!(
              !product.availableTotal ||
              !product.product_categories_availability_check
            )
          }"
        >
          {{ product.name }}
        </span>
      </ion-text>
    </ion-col>

    <ion-col
      class="ion-no-padding ion-no-margin descriptionPaddings"
      size="12"
      *ngIf="product.description || product.showOptionInDescription"
      style="line-height: initial"
    >
      <span
        [ngClass]="{
          'unavailable line-through ': !!(
            !product.availableTotal ||
            !product.product_categories_availability_check
          )
        }"
        class="fontSizeSmaller"
      >
        <app-description
          *ngIf="
            (orderPickup == null && dineIn == null) ||
            (orderPickup == false && dineIn == false) ||
            (orderPickup == true &&
              dineIn == false &&
              !product.showOptionInDescription_takeaway) ||
            (orderPickup == false &&
              dineIn == true &&
              !product.showOptionInDescription_dinein)
          "
          [show_option_description]="product.showOptionInDescription"
          [description]="product.description"
        >
        </app-description>

        <app-description
          *ngIf="
            orderPickup == true &&
            dineIn == false &&
            product.showOptionInDescription_takeaway
          "
          [show_option_description]="product.showOptionInDescription_takeaway"
          [description]="product.description"
        >
        </app-description>

        <app-description
          *ngIf="
            orderPickup == false &&
            dineIn == true &&
            product.showOptionInDescription_dinein
          "
          [show_option_description]="product.showOptionInDescription_dinein"
          [description]="product.description"
        >
        </app-description>
      </span>
    </ion-col>

    <app-product-show-option-in-description-object
      [product]="product"
      [dineIn]="dineIn"
      [orderPickup]="orderPickup"
      [store_info]="groupStoreData.store_info"
      [selectedDineInTable]="selectedDineInTable"
    ></app-product-show-option-in-description-object>

    <!-- <ion-col
      class="ion-no-padding ion-no-margin"
      size="12"
      *ngIf="product.showOptionInDescription"
      class="fontSizeSmaller"
      [ngClass]="{
        'line-through unavailable': !!(
          !product.availableTotal || !product.product_categories_availability_check
        )
      }"
    >
      <p
        *ngFor="
          let description of product.showOptionInDescription;
          let i = index;
          trackBy: trackFn
        "
      >
        {{ description }}
      </p>
    </ion-col> -->
    <ion-col
      class="ion-no-padding ion-no-margin"
      *ngIf="product.promotions && product.promotions.length > 0"
      [ngClass]="{
        'line-through unavailable': !!(
          !product.availableTotal ||
          !product.product_categories_availability_check
        )
      }"
      class="ion-no-padding categoriesWarningColor"
      style="font-weight: bold"
    >
      <p
        *ngFor="
          let promo of product.promotions;
          let i = index;
          trackBy: trackFn
        "
      >
        {{ promo.description }}
      </p>
    </ion-col>

    <ion-col
      *ngIf="
        (orderPickup == false && dineIn == false) ||
        (orderPickup == null && dineIn == null)
      "
      style="padding-left: 0px"
      class="pricePaddings"
      size="12"
      [ngClass]="{
        priceWidthUnavailable: !!product.priceWidthUnavailable,
        priceWidthAvailable: !!product.priceWidthAvailable
      }"
    >
      <span
        style="padding-right: 3px; font-size: smaller"
        *ngIf="product.initial_price"
      >
        <span class="line-through">
          {{ product.initial_price
          }}{{
            product.enable_quantity_step &&
            product.quantity_step &&
            product.quantity_step_kind
              ? "/" + product.quantity_step_kind
              : ""
          }}
        </span>
      </span>
      <span
        [ngClass]="{
          'lowerFromTop unavailable line-through': !!(
            !product.availableTotal ||
            !product.product_categories_availability_check
          )
        }"
      >
        <ion-text *ngIf="product.price" color="primary" style="font-weight: 700"
          >{{ product.price
          }}{{
            product.enable_quantity_step &&
            product.quantity_step &&
            product.quantity_step_kind
              ? "/" + product.quantity_step_kind
              : ""
          }}</ion-text
        >

        <ion-text *ngIf="product.points_to_earn && product.price">
          <span> | </span>
          <span class="points-to-earn">
            {{ product.points_to_earn }}
            {{ "points-translation" | translate }}
          </span>
        </ion-text>

        <ion-text
          *ngIf="!product.price && product.virtual_price"
          color="primary"
          style="font-weight: 700"
          >{{ product.virtual_price
          }}{{
            product.enable_quantity_step &&
            product.quantity_step &&
            product.quantity_step_kind
              ? "/" + product.quantity_step_kind
              : ""
          }}</ion-text
        >
      </span>
    </ion-col>

    <ion-col
      *ngIf="orderPickup == true && dineIn == false"
      style="padding-left: 0px"
      class="pricePaddings"
      size="12"
      [ngClass]="{
        priceWidthUnavailable: !!product.priceWidthUnavailable,
        priceWidthAvailable: !!product.priceWidthAvailable
      }"
    >
      <span
        style="padding-right: 3px; font-size: smaller"
        *ngIf="product.initial_price && !product.price_takeaway"
      >
        <span class="line-through">
          {{ product.initial_price
          }}{{
            product.enable_quantity_step &&
            product.quantity_step &&
            product.quantity_step_kind
              ? "/" + product.quantity_step_kind
              : ""
          }}
        </span>
      </span>
      <span
        style="padding-right: 3px; font-size: smaller"
        *ngIf="product.initial_price_takeaway && product.price_takeaway"
      >
        <span class="line-through">
          {{ product.initial_price_takeaway
          }}{{
            product.enable_quantity_step &&
            product.quantity_step &&
            product.quantity_step_kind
              ? "/" + product.quantity_step_kind
              : ""
          }}
        </span>
      </span>
      <span
        [ngClass]="{
          'lowerFromTop unavailable line-through': !!(
            !product.availableTotal ||
            !product.product_categories_availability_check
          )
        }"
      >
        <ion-text
          *ngIf="product.price_takeaway"
          color="primary"
          style="font-weight: 700"
          >{{ product.price_takeaway
          }}{{
            product.enable_quantity_step &&
            product.quantity_step &&
            product.quantity_step_kind
              ? "/" + product.quantity_step_kind
              : ""
          }}</ion-text
        >

        <ion-text
          *ngIf="product.price_takeaway && product.points_to_earn_takeaway"
        >
          <span> | </span>
          <span class="points-to-earn">
            {{ product.points_to_earn_takeaway }}
            {{ "points-translation" | translate }}
          </span>
        </ion-text>

        <ion-text
          *ngIf="!product.price_takeaway && product.virtual_price_takeaway"
          color="primary"
          style="font-weight: 700"
          >{{ product.virtual_price_takeaway
          }}{{
            product.enable_quantity_step &&
            product.quantity_step &&
            product.quantity_step_kind
              ? "/" + product.quantity_step_kind
              : ""
          }}</ion-text
        >

        <ion-text
          *ngIf="!product.price_takeaway && !product.virtual_price_takeaway"
          color="primary"
          style="font-weight: 700"
          >{{ product.price ? product.price : product.virtual_price
          }}{{
            product.enable_quantity_step &&
            product.quantity_step &&
            product.quantity_step_kind
              ? "/" + product.quantity_step_kind
              : ""
          }}</ion-text
        >
        <ion-text
          *ngIf="
            product.points_to_earn &&
            product.price &&
            !product.price_takeaway &&
            !product.virtual_price_takeaway
          "
        >
          <span> | </span>
          <span class="points-to-earn">
            {{ product.points_to_earn }}
            {{ "points-translation" | translate }}
          </span>
        </ion-text>
      </span>
    </ion-col>

    <ion-col
      *ngIf="
        orderPickup == false &&
        dineIn == true &&
        groupStoreData &&
        groupStoreData.store_info &&
        (!groupStoreData.store_info.alt_dinein_active ||
          !selectedDineInTable ||
          !selectedDineInTable.alt_dinein)
      "
      style="padding-left: 0px"
      class="pricePaddings"
      size="12"
      [ngClass]="{
        priceWidthUnavailable: !!product.priceWidthUnavailable,
        priceWidthAvailable: !!product.priceWidthAvailable
      }"
    >
      <span
        style="padding-right: 3px; font-size: smaller"
        *ngIf="product.initial_price && !product.price_dinein"
      >
        <span class="line-through">
          {{ product.initial_price
          }}{{
            product.enable_quantity_step &&
            product.quantity_step &&
            product.quantity_step_kind
              ? "/" + product.quantity_step_kind
              : ""
          }}
        </span>
      </span>
      <span
        style="padding-right: 3px; font-size: smaller"
        *ngIf="product.initial_price_dinein && product.price_dinein"
      >
        <span class="line-through">
          {{ product.initial_price_dinein
          }}{{
            product.enable_quantity_step &&
            product.quantity_step &&
            product.quantity_step_kind
              ? "/" + product.quantity_step_kind
              : ""
          }}
        </span>
      </span>
      <span
        [ngClass]="{
          'lowerFromTop unavailable line-through': !!(
            !product.availableTotal ||
            !product.product_categories_availability_check
          )
        }"
      >
        <ion-text
          *ngIf="product.price_dinein"
          color="primary"
          style="font-weight: 700"
          >{{ product.price_dinein
          }}{{
            product.enable_quantity_step &&
            product.quantity_step &&
            product.quantity_step_kind
              ? "/" + product.quantity_step_kind
              : ""
          }}</ion-text
        >

        <ion-text *ngIf="product.price_dinein && product.points_to_earn_dinein">
          <span> | </span>
          <span class="points-to-earn">
            {{ product.points_to_earn_dinein }}
            {{ "points-translation" | translate }}
          </span>
        </ion-text>

        <ion-text
          *ngIf="!product.price_dinein && product.virtual_price_dinein"
          color="primary"
          style="font-weight: 700"
          >{{ product.virtual_price_dinein
          }}{{
            product.enable_quantity_step &&
            product.quantity_step &&
            product.quantity_step_kind
              ? "/" + product.quantity_step_kind
              : ""
          }}</ion-text
        >

        <ion-text
          *ngIf="!product.price_dinein && !product.virtual_price_dinein"
          color="primary"
          style="font-weight: 700"
          >{{ product.price ? product.price : product.virtual_price
          }}{{
            product.enable_quantity_step &&
            product.quantity_step &&
            product.quantity_step_kind
              ? "/" + product.quantity_step_kind
              : ""
          }}</ion-text
        >
        <ion-text
          *ngIf="
            product.points_to_earn &&
            product.price &&
            !product.price_dinein &&
            !product.virtual_price_dinein
          "
        >
          <span> | </span>
          <span class="points-to-earn">
            {{ product.points_to_earn }}
            {{ "points-translation" | translate }}
          </span>
        </ion-text>
      </span>
    </ion-col>

    <ion-col
      *ngIf="
        orderPickup == false &&
        dineIn == true &&
        groupStoreData &&
        groupStoreData.store_info &&
        groupStoreData.store_info.alt_dinein_active &&
        selectedDineInTable &&
        selectedDineInTable.alt_dinein
      "
      style="padding-left: 0px"
      class="pricePaddings"
      size="12"
      [ngClass]="{
        priceWidthUnavailable: !!product.priceWidthUnavailable,
        priceWidthAvailable: !!product.priceWidthAvailable
      }"
    >
      <span
        style="padding-right: 3px; font-size: smaller"
        *ngIf="
          product.initial_price &&
          !product.price_alt_dinein &&
          !product.price_dinein
        "
      >
        <span class="line-through">
          {{ product.initial_price
          }}{{
            product.enable_quantity_step &&
            product.quantity_step &&
            product.quantity_step_kind
              ? "/" + product.quantity_step_kind
              : ""
          }}
        </span>
      </span>
      <span
        style="padding-right: 3px; font-size: smaller"
        *ngIf="product.initial_price_alt_dinein && product.price_alt_dinein"
      >
        <span class="line-through">
          {{ product.initial_price_alt_dinein
          }}{{
            product.enable_quantity_step &&
            product.quantity_step &&
            product.quantity_step_kind
              ? "/" + product.quantity_step_kind
              : ""
          }}
        </span>
      </span>
      <span
        style="padding-right: 3px; font-size: smaller"
        *ngIf="
          !product.price_alt_dinein &&
          product.initial_price_dinein &&
          product.price_dinein
        "
      >
        <span class="line-through">
          {{ product.initial_price_dinein
          }}{{
            product.enable_quantity_step &&
            product.quantity_step &&
            product.quantity_step_kind
              ? "/" + product.quantity_step_kind
              : ""
          }}
        </span>
      </span>
      <span
        [ngClass]="{
          'lowerFromTop unavailable line-through': !!(
            !product.availableTotal ||
            !product.product_categories_availability_check
          )
        }"
      >
        <ion-text
          *ngIf="product.price_alt_dinein"
          color="primary"
          style="font-weight: 700"
          >{{ product.price_alt_dinein
          }}{{
            product.enable_quantity_step &&
            product.quantity_step &&
            product.quantity_step_kind
              ? "/" + product.quantity_step_kind
              : ""
          }}</ion-text
        >
        <ion-text
          *ngIf="product.price_alt_dinein && product.points_to_earn_alt_dinein"
        >
          <span> | </span>
          <span class="points-to-earn">
            {{ product.points_to_earn_alt_dinein }}
            {{ "points-translation" | translate }}
          </span>
        </ion-text>

        <ion-text
          *ngIf="!product.price_alt_dinein && product.virtual_price_alt_dinein"
          color="primary"
          style="font-weight: 700"
          >{{ product.virtual_price_alt_dinein
          }}{{
            product.enable_quantity_step &&
            product.quantity_step &&
            product.quantity_step_kind
              ? "/" + product.quantity_step_kind
              : ""
          }}</ion-text
        >

        <ion-text
          *ngIf="
            !product.price_alt_dinein &&
            product.price_dinein &&
            !product.virtual_price_alt_dinein
          "
          color="primary"
          style="font-weight: 700"
          >{{ product.price_dinein
          }}{{
            product.enable_quantity_step &&
            product.quantity_step &&
            product.quantity_step_kind
              ? "/" + product.quantity_step_kind
              : ""
          }}</ion-text
        >

        <ion-text
          *ngIf="
            !product.price_alt_dinein &&
            !product.price_dinein &&
            !product.virtual_price_alt_dinein &&
            product.virtual_price_dinein
          "
          color="primary"
          style="font-weight: 700"
          >{{ product.virtual_price_dinein
          }}{{
            product.enable_quantity_step &&
            product.quantity_step &&
            product.quantity_step_kind
              ? "/" + product.quantity_step_kind
              : ""
          }}</ion-text
        >

        <ion-text
          *ngIf="
            !product.price_alt_dinein &&
            !product.virtual_price_alt_dinein &&
            !product.price_dinein &&
            !product.virtual_price_dinein
          "
          color="primary"
          style="font-weight: 700"
          >{{ product.price ? product.price : product.virtual_price
          }}{{
            product.enable_quantity_step &&
            product.quantity_step &&
            product.quantity_step_kind
              ? "/" + product.quantity_step_kind
              : ""
          }}</ion-text
        >

        <ion-text
          *ngIf="
            product.points_to_earn &&
            product.price &&
            !product.price_alt_dinein &&
            !product.virtual_price_alt_dinein &&
            !product.price_dinein &&
            !product.virtual_price_dinein
          "
        >
          <span> | </span>
          <span class="points-to-earn">
            {{ product.points_to_earn }}
            {{ "points-translation" | translate }}
          </span>
        </ion-text>
      </span>
    </ion-col>

    <ion-col
      size="12"
      class="ion-no-padding ion-no-margin"
      *ngIf="
        (product &&
          !!(
            !product.availableTotal ||
            !product.product_categories_availability_check
          )) ||
        product.avpc ||
        product.promo ||
        product.tags ||
        product.delivery ||
        product.pickup ||
        product.dinein ||
        (product.availableTotal &&
          product.availability_stock_enabled &&
          product.availability_stock) ||
        (product.preorder_availability_stock_enabled &&
          product.preorder_availability_stock) ||
        (product &&
          groupStoreData &&
          groupStoreData.groupApp &&
          groupStoreData.groupApp.pointsSystem &&
          groupStoreData.store_info &&
          groupStoreData.store_info.overridePromoItemsForPointsSystem &&
          !product.pointsSystemScheduleActive &&
          !product.pointsSystemException &&
          product.pointsSystemWeight &&
          product.pointsSystemWeight > 1)
      "
    >
      <app-product-item-tags
        [groupApp]="
          groupStoreData && groupStoreData.groupApp
            ? groupStoreData.groupApp
            : null
        "
        [productItem]="product"
        [store_info]="
          groupStoreData && groupStoreData.store_info
            ? groupStoreData.store_info
            : null
        "
        [selectedPreorderDate]="selectedPreorderDate"
        [orderPickup]="orderPickup"
        [dineIn]="dineIn"
      >
      </app-product-item-tags>
    </ion-col>
  </ion-row>
</ion-item>
