<ion-card *ngIf="groupApp && !groupApp.active_reservation_app">
  <ion-item-divider mode="ios">
    <ion-label>
      {{ "cart.cart-components.order-comments.order-comments" | translate }}
    </ion-label>
  </ion-item-divider>

  <ion-item>
    <ion-input
      debounce="1000"
      (ionChange)="onChange()"
      type="text"
      placeholder="{{
        'cart.cart-components.order-comments.write-here' | translate
      }}"
      [(ngModel)]="generalComments"
    ></ion-input>
  </ion-item>
</ion-card>

<ion-grid *ngIf="groupApp && groupApp.active_reservation_app">
  <ion-textarea
    (ionChange)="onChange()"
    [(ngModel)]="generalComments"
    debounce="1000"
    placeholder="{{
      'cart.cart-components.order-comments.write-here' | translate
    }}"
    style="background-color: rgb(239, 239, 239); border-radius: 10px"
  >
  </ion-textarea>
</ion-grid>
