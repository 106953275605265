import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "twoDecimalsRound",
})
export class TwoDecimalsRoundPipe implements PipeTransform {
  transform(value: any, round_to_one_decimal: boolean): string {
    if (value && round_to_one_decimal) {
      return parseFloat(parseFloat(value.toString()).toFixed(1)).toFixed(2);
    } else {
      return parseFloat(value.toString()).toFixed(2);
    }
  }
}
