import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import * as fromApp from "../../../../../store/app.reducer";
import * as _ from "lodash";
import * as CartCustomOptionsSelectionsActions from "../../store/cart-custom-options-selections/cart-custom-options-selections.actions";
import { Store } from "@ngrx/store";
import { AlertController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { auditTime, distinctUntilChanged } from "rxjs/operators";
import { Subscription } from "rxjs";

@Component({
  selector: "app-radio-type-choices-quantity",
  templateUrl: "./radio-type-choices-quantity.component.html",
  styleUrls: ["./radio-type-choices-quantity.component.scss"],
})
export class RadioTypeChoicesQuantityComponent implements OnInit, OnDestroy {
  @Input() option_timestamp;
  @Input() selectedLang: any;
  public option: any;
  public CustomSelections;
  private subscriptions: Subscription[] = [];
  public selectedChoiceTimestamp: any;
  public finalPriceLoading: boolean;
  constructor(
    private store: Store<fromApp.AppState>,
    private alertCtrl: AlertController,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select("cartCustomOptionsSelections")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.cartCustomOptionsSelections &&
            !_.isEqual(state.cartCustomOptionsSelections, this.CustomSelections)
          ) {
            this.CustomSelections = _.cloneDeep(
              state.cartCustomOptionsSelections
            );
            this.option = _.cloneDeep(
              _.find(this.CustomSelections, {
                timestamp: this.option_timestamp,
              })
            );

            this.option.choices.forEach((ch) => {
              if (ch.qty > 0) {
                ch.selected = true;
                this.selectedChoiceTimestamp = ch.timestamp;
                ch.totalPrice = this.calculateChoicePrice(ch);
                this.store.dispatch(
                  new CartCustomOptionsSelectionsActions.AddUpdateCartCustomOptionSelections(
                    _.cloneDeep(this.option)
                  )
                );
              }
            });
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("disableFinalPrice")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(state.disableFinalPrice, this.finalPriceLoading)
          ) {
            this.finalPriceLoading = _.cloneDeep(state.disableFinalPrice);
          }
        })
    );
  }

  trackTimestamp(index, choice) {
    return choice.timestamp;
  }

  customChoiceQtyChange(ev, choice, index) {
    if (ev === "increase") {
      this.customChoiceQuantityIncrease(choice, index);
    } else if (ev === "decrease") {
      this.customChoiceQuantityDecrease(choice, index);
    }
  }

  customChoiceQuantityDecrease(choice, index) {
    if (
      this.option.choices[index].qty > 1 &&
      (!this.option.choices[index].min ||
        this.option.choices[index].qty > this.option.choices[index].min)
    ) {
      choice.qty--;
      if (this.option.choices[index].price) {
        choice.totalPrice = this.calculateChoicePrice(choice);
      }
      this.store.dispatch(
        new CartCustomOptionsSelectionsActions.AddUpdateCartCustomOptionSelections(
          _.cloneDeep(this.option)
        )
      );
    } else {
      let message = this.translateService.instant(
        "cart.cart-components.cart-custom-options.quantity-options.max-quantity"
      );
      this.presentAlert(message);
    }
  }

  customChoiceQuantityIncrease(choice, index) {
    if (
      !this.option.choices[index].max ||
      this.option.choices[index].qty < this.option.choices[index].max
    ) {
      choice.qty++;
      if (this.option.choices[index].price) {
        choice.TesttotalPrice = this.calculateChoicePrice(choice);

        this.store.dispatch(
          new CartCustomOptionsSelectionsActions.AddUpdateCartCustomOptionSelections(
            _.cloneDeep(this.option)
          )
        );
      }
    } else {
      let message = this.translateService.instant(
        "cart.cart-components.cart-custom-options.quantity-options.max-quantity"
      );
      this.presentAlert(message);
    }
  }

  calculateChoicePrice(choice) {
    if (choice && choice.qty > 0 && choice.price > 0) {
      return (choice.qty * choice.price).toFixed(2);
    } else {
      return null;
    }
  }

  async presentAlert(message) {
    const alert = await this.alertCtrl.create({
      message: message,
      buttons: ["OK"],
    });

    await alert.present();
  }

  radioSelection(ev) {
    if (this.selectedChoiceTimestamp) {
      this.option.choices.forEach((ch) => {
        ch.selected = false;
        ch.qty = ch.min || 0;
      });

      let selectedChoiceIndex = _.findIndex(this.option.choices, {
        timestamp: this.selectedChoiceTimestamp,
      });
      if (selectedChoiceIndex !== -1) {
        this.option.choices[selectedChoiceIndex].selected = true;
        if (this.option.choices[selectedChoiceIndex].qty == 0) {
          this.option.choices[selectedChoiceIndex].qty++;
        }
        this.store.dispatch(
          new CartCustomOptionsSelectionsActions.AddUpdateCartCustomOptionSelections(
            _.cloneDeep(this.option)
          )
        );
      }
    } else {
      this.option.choices.forEach((ch) => {
        ch.selected = false;
        ch.qty = ch.min || 0;
      });
      this.store.dispatch(
        new CartCustomOptionsSelectionsActions.AddUpdateCartCustomOptionSelections(
          _.cloneDeep(this.option)
        )
      );
    }
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }
}
