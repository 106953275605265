import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import SwiperCore, {
  Autoplay,
  EffectCreative,
  Keyboard,
  Navigation,
  Pagination,
  Zoom,
} from "swiper";
import { SwiperComponent } from "swiper/angular";
import * as fromApp from "../../../store/app.reducer";
import * as _ from "lodash";
import { distinctUntilChanged } from "rxjs";
import { Store } from "@ngrx/store";
import { ModalController } from "@ionic/angular";
import { ContestModalComponent } from "../contest-modal/contest-modal.component";
import { LoyaltyContestGiftsComponent } from "../loyalty-contest-gifts/loyalty-contest-gifts.component";
import { ContestWinnersModalComponent } from "../contest-winners-modal/contest-winners-modal.component";
SwiperCore.use([
  Autoplay,
  Navigation,
  Keyboard,
  Pagination,
  Zoom,
  EffectCreative,
]);

@Component({
  selector: "app-loyalty-contest-item",
  templateUrl: "./loyalty-contest-item.component.html",
  styleUrls: ["./loyalty-contest-item.component.scss"],
})
export class LoyaltyContestItemComponent implements OnInit, OnDestroy {
  @ViewChild("swiperSlideShow") swiperSlideShow: SwiperComponent;
  @Input() contest;
  @Input() group;
  @Input() activeContestDrawsSum;
  @Input() currentUser;
  windowDimInfoSub: any;
  windowDimensions: any;
  statusColor: string;
  public currentDate: any;
  bigScreenStyle: boolean;
  constructor(
    private store: Store<fromApp.AppState>,
    private modalController: ModalController
  ) {}

  trackGift(index, gift) {
    return gift.gift_id;
  }

  ngOnInit() {
    console.log("contest", this.contest);
    console.log("currentUser", this.currentUser);
    this.currentDate = Date.now();
    if (this.contest.activeFrom > this.currentDate)
      this.statusColor = "warning";
    if (this.contest.activeTo < this.currentDate) this.statusColor = "danger";
    if (
      this.currentDate >= this.contest.activeFrom &&
      this.currentDate < this.contest.activeTo
    )
      this.statusColor = "success";
    this.windowDimInfoSub = this.store
      .select("windowInfo")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          !_.isEqual(this.windowDimensions, state.windowDimensions)
        ) {
          this.windowDimensions = _.cloneDeep(state.windowDimensions);
          if (this.windowDimensions && this.windowDimensions.width) {
            let width = this.windowDimensions.width;
            if (width > 700) {
              this.bigScreenStyle = true;
            } else {
              this.bigScreenStyle = false;
            }
          }
        }
      });
  }

  async showRaffleGiftsModal(contest) {
    const modal = await this.modalController.create({
      component: LoyaltyContestGiftsComponent,
      cssClass: "contestModal",
      componentProps: {
        contest,
        group: this.group,
      },
    });
    await modal.present();
  }

  async openContestModal() {
    const modal = await this.modalController.create({
      component: ContestModalComponent,
      cssClass: "contestModal",
      componentProps: {
        contest: this.contest,
        bigScreenStyle: this.bigScreenStyle,
      },
    });
    await modal.present();
  }

  async openWinnersModal() {
    const modal = await this.modalController.create({
      component: ContestWinnersModalComponent,
      cssClass: "contestModal",
      componentProps: {
        contest: this.contest,
        bigScreenStyle: this.bigScreenStyle,
      },
    });
    await modal.present();
  }

  ngOnDestroy() {
    if (this.windowDimInfoSub) {
      this.windowDimInfoSub.unsubscribe();
    }
  }
}
