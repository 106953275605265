import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { AlertController, ModalController, Platform } from "@ionic/angular";
import { Subscription, distinctUntilChanged } from "rxjs";
import * as _ from "lodash";
import { AnimationOptions } from "ngx-lottie";
import { MigratePointsComponent } from "../../loyalty-more/migrate-points/migrate-points.component";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../store/app.reducer";
import { Router } from "@angular/router";
import { ContestModalComponent } from "../../loyalty-contests/contest-modal/contest-modal.component";
import { AccountInfoForContestModalComponent } from "../../account-info-for-contest-modal/account-info-for-contest-modal.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-qrcard-modal",
  templateUrl: "./qrcard-modal.component.html",
  styleUrls: ["./qrcard-modal.component.scss"],
})
export class QrcardModalComponent implements OnInit, OnDestroy {
  @Input() currentUser;
  @Input() campaignsArray;
  @Input() selectedStoreStoreName;
  @Input() group;
  @Input() activeContest;
  @Input() drawsSum;
  @Input() bigScreenStyle;
  @Input() userPoints;

  public noDiscountCouponOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/no-discount-coupon.json",
  };

  private unsubscribeBackEvent: Subscription;
  public activeLoyaltyCoupons: number = 0;
  imageLoaded: boolean = false;
  noOldMemberNumberConfirmed: string;
  customerCoupons: any;
  activeInStoreCoupons: any[];
  couponsSub: Subscription;
  instoreCoupons: any[];
  accountInfoAlert: any;
  constructor(
    private store: Store<fromApp.AppState>,
    private modalController: ModalController,
    private platform: Platform,
    private router: Router,
    private translateService: TranslateService,
    private alertController: AlertController
  ) {}

  ngOnInit() {
    // console.log(this.currentUser);

    if (
      !this.currentUser.firstName.trim() ||
      !this.currentUser.lastName.trim()
    ) {
      if (!this.accountInfoAlert) {
        this.askForNameAndPushNotifPermission();
      }
    }
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          if (!this.accountInfoAlert) {
            this.closeModal();
          }
        }
      }
    );
    if (navigator?.cookieEnabled) {
      this.noOldMemberNumberConfirmed = localStorage.getItem(
        "noOldMemberNumberConfirmed"
      );
    }

    this.couponsSub = this.store
      .select("customerCoupons")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.allCoupons &&
          !_.isEqual(this.customerCoupons, state.allCoupons)
        ) {
          this.customerCoupons = _.cloneDeep(state.allCoupons);
          this.instoreCoupons = _.filter(this.customerCoupons, (coupon) => {
            return coupon.use_type === "instore";
          });
          console.log("instoreCoupons", this.instoreCoupons);
          this.instoreCoupons = _.orderBy(
            _.cloneDeep(this.instoreCoupons),
            ["status", "type", "timestamp"],
            ["asc", "asc", "desc"]
          );
          this.activeInStoreCoupons = _.filter(
            this.instoreCoupons,
            (coupon) => {
              return coupon.status === "active";
            }
          );
        }
      });
  }

  pageChange(route) {
    this.router.navigateByUrl("/" + route, {
      replaceUrl: true,
    });
  }

  async openContestModal() {
    const modal = await this.modalController.create({
      component: ContestModalComponent,
      cssClass: "contestModal",
      componentProps: {
        contest: this.activeContest,
        bigScreenStyle: this.bigScreenStyle,
      },
    });
    await modal.present();
  }

  async openMigratePointsModal() {
    const modal = await this.modalController.create({
      component: MigratePointsComponent,
      cssClass: "migratePointsModal",
      backdropDismiss: false,
    });

    await modal.present();
  }

  async askForNameAndPushNotifPermission() {
    this.accountInfoAlert = await this.alertController.create({
      header: "Συμμετοχή στην κλήρωση",
      message: "Είναι απαραίτητο να συμπληρώσετε το ονοματεπώνυμο σας",
      backdropDismiss: false,
      inputs: [],
      buttons: [
        {
          text: this.translateService.instant("continue"),
          handler: () => {
            this.openAccountInfoForContestModal();
            this.accountInfoAlert = null;
          },
        },
      ],
    });

    await this.accountInfoAlert.present();
  }

  async openAccountInfoForContestModal() {
    const modal = await this.modalController.create({
      component: AccountInfoForContestModalComponent,
      backdropDismiss: false,
      breakpoints: [0, 0.25, 0.5, 0.75],
      initialBreakpoint: 0.5,
      componentProps: {
        currentUser: this.currentUser,
      },
    });

    await modal.present();
  }

  noDiscountCouponAnimationCreated(ev) {
    const noDiscountCoupon = ev;
    noDiscountCoupon.setSpeed(1);
  }

  onImageLoaded() {
    this.imageLoaded = true;
  }
  closeModal() {
    this.modalController.dismiss();
  }

  ngOnDestroy() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.couponsSub) {
      this.couponsSub.unsubscribe();
    }
  }
}
