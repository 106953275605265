<ion-grid *ngIf="!order || !order.order_id">
  <ion-row style="text-align: center">
    <ion-col class="ion-padding">
      <ion-spinner></ion-spinner>
    </ion-col>
  </ion-row>
</ion-grid>
<ion-grid *ngIf="order && order.order_id">
  <ion-row>
    <ion-col class="ion-text-center">
      <ion-button (click)="sendOpenTimeLineModalEvent()">
        {{ "orders.order_timeline" | translate }}
      </ion-button>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="12">
      <ion-label class="ion-text-wrap ion-text-center">
        <p
          *ngIf="
            !order.preorder &&
            order.status &&
            (order.status === 'complete' || order.status === 'processing') &&
            isNum(order.deliveryTime) &&
            !(
              order.ds_status &&
              ds_status_color &&
              (order.ds_status === 'order_delivered' ||
                order.ds_status === 'order_received' ||
                order.ds_status === 'order_picked_up')
            )
          "
        >
          <ion-text
            >{{ "orders.delivery_time" | translate }}: {{ order.deliveryTime }}
            {{ "orders.minutes" | translate }}
          </ion-text>
        </p>
        <p
          *ngIf="
            !order.preorder &&
            order.status &&
            (order.status === 'complete' || order.status === 'processing') &&
            !isNum(order.deliveryTime) &&
            !(
              order.ds_status &&
              ds_status_color &&
              (order.ds_status === 'order_delivered' ||
                order.ds_status === 'order_received' ||
                order.ds_status === 'order_picked_up')
            )
          "
        >
          <ion-text
            >{{ "orders.delivery_time" | translate }}: {{ order.deliveryTime }}
          </ion-text>
        </p>
        <p
          *ngIf="
            order.preorder &&
            order.preorder_day &&
            order.preorder_time &&
            order.status &&
            (order.status === 'complete' || order.status === 'processing')
          "
        >
          <ion-text
            ><strong>{{ "orders.delivery_time" | translate }}: </strong
            >{{ order.preorder_day }}
            {{
              !order.preorderAcceptDisableDeliveryTime &&
              !order.pickup &&
              !order.dineIn
                ? order.preorder_time
                : !order.preorderAcceptDisablePickupTime &&
                  order.pickup &&
                  !order.dineIn
                ? order.preorder_time
                : ""
            }}
          </ion-text>
        </p>
        <p
          *ngIf="
            order.status && order.status === 'canceled' && order.rejectionReason
          "
        >
          <ion-text color="canceled">{{ order.rejectionReason }}</ion-text>
        </p>
        <p
          *ngIf="
            order.selectedPaymentMethodID &&
            order.selectedPaymentMethodID !== 'cash' &&
            order.selectedPaymentMethodID !== 'mobilePOS' &&
            order.payment_id
          "
        >
          <ion-text color="primary"
            >{{ "orders.paid_online" | translate }}
            {{ "orders.via" | translate }}
          </ion-text>
          <ion-text color="primary"
            >{{ order.selectedPaymentMethodID.toString().toUpperCase() }}
            {{ order.payment_id }}
          </ion-text>
        </p>
        <p
          *ngIf="
            order.selectedPaymentMethodID &&
            order.selectedPaymentMethodID === 'mobilePOS'
          "
        >
          <ion-text *ngIf="order.pickup && !order.dinein">
            {{ "orders.order-item.mobilePOS-takeaway" | translate }}
          </ion-text>
          <ion-text *ngIf="!order.pickup && !order.dinein">
            {{ "orders.order-item.mobilePOS-delivery" | translate }}
          </ion-text>
          <ion-text *ngIf="!order.pickup && order.dinein">
            {{ "orders.order-item.mobilePOS-dinein" | translate }}
          </ion-text>
        </p>
        <p *ngIf="order.refund && order.refund.refund_id">
          <ion-text color="danger"
            >{{ order.refund.amount }}{{ "orders.refunded" | translate }}
          </ion-text>
        </p>
      </ion-label>
    </ion-col>
  </ion-row>
  <ion-row
    *ngIf="
      order.payment_id &&
      order.selectedPaymentMethodID &&
      order.status &&
      order.selectedPaymentMethodID !== 'cash' &&
      order.selectedPaymentMethodID !== 'mobilePOS'
    "
  >
    <!-- Buttons for showing order payment information and refunding -->
  </ion-row>
  <ion-row>
    <ion-col size-xs="12" size-sm="12">
      <!-- Customer's Information -->
      <ion-label class="ion-text-wrap">
        <p>
          <ion-text *ngIf="order.firstName">{{ order.firstName }} </ion-text>
          <ion-text *ngIf="order.lastName">{{ order.lastName }} </ion-text>
          <ion-text *ngIf="order.mobile">{{ order.mobile }} </ion-text>
          <ion-text *ngIf="order.platform">{{
            order.platform | translate
          }}</ion-text>
        </p>
        <p
          *ngIf="
            order.table_name && order.table_id && order.dinein && !order.pickup
          "
        >
          {{ "orders.table" | translate }} {{ order.table_name }}
        </p>
        <p *ngIf="order.company">
          {{ "orders.company" | translate }} {{ order.company }}
        </p>
        <p *ngIf="order.pickup && !order.dinein">
          <strong>{{ "orders.pickup" | translate }}</strong>
        </p>
        <p *ngIf="!order.pickup && order.dinein">
          <strong>{{ "orders.dine-in" | translate }}</strong>
        </p>
        <p *ngIf="!order.pickup && order.altPhone">
          {{ "orders.alt_phone" | translate }}: {{ order.altPhone }}
        </p>

        <p
          *ngIf="
            order &&
            order.fixed_address_id &&
            order.fixed_address_type &&
            order.fixed_address_type === 'meeting_point'
          "
          style="font-weight: bold"
        >
          {{ "delivery-spot" | translate }}
        </p>

        <p
          *ngIf="
            order.formatted_address &&
            !order.pickup &&
            order.fixed_address_id &&
            order.fixed_address_name &&
            order.fixed_address_type &&
            order.fixed_address_type === 'meeting_point'
          "
        >
          <ion-text>{{ order.fixed_address_name }}</ion-text>
        </p>
        <p
          style="display: flex"
          *ngIf="order.formatted_address && !order.pickup && !order.dinein"
        >
          <ion-text class="ion-align-self-center">{{
            order.formatted_address
          }}</ion-text>
        </p>

        <p *ngIf="order.doorbellname && !order.pickup && !order.dinein">
          {{ "orders.bells_door_ring" | translate }}: {{ order.doorbellname }}
        </p>
        <p *ngIf="order.floor && !order.pickup && !order.dinein">
          {{ "orders.floor" | translate }}: {{ order.floor }}
        </p>
        <p *ngIf="order && order.dinein_table_id && order.dinein_table_name">
          <strong>{{ "orders.table" | translate }}:</strong>
          {{ order.dinein_table_name }}
        </p>
      </ion-label>
    </ion-col>
    <ion-col
      *ngIf="order.customerInvoice && order.customerInvoice.enabled"
      size-xs="12"
      size-sm="12"
      size-md="5"
    >
      <!-- Customer's Company Information -->
      <ion-label class="ion-text-wrap">
        <p>{{ "orders.get_company_invoice" | translate }}</p>
        <p *ngIf="order.customerInvoice.vatNumber">
          {{ "orders.VAT_number" | translate }}:
          {{ order.customerInvoice.vatNumber }}
        </p>
        <p *ngIf="order.customerInvoice.company_name">
          {{ order.customerInvoice.company_name }}
        </p>
        <p *ngIf="order.customerInvoice.company_address">
          {{ order.customerInvoice.company_address }}
        </p>
        <p *ngIf="order.customerInvoice.vatOffice">
          {{ "orders.VAT_office" | translate }}:
          {{ order.customerInvoice.vatOffice }}
        </p>
        <p *ngIf="order.customerInvoice.activity">
          {{ "orders.activity" | translate }}:
          {{ order.customerInvoice.activity }}
        </p>
      </ion-label>
    </ion-col>
  </ion-row>
  <ion-row *ngIf="order.comments || order.generalComments">
    <ion-col size-xs="12">
      <ion-label class="ion-text-wrap ion-text-center">
        <p *ngIf="order.comments">
          <strong
            >{{ "orders.comments" | translate }}: {{ order.comments }}</strong
          >
        </p>
        <p *ngIf="order.generalComments">
          <strong
            >{{ "orders.general_comments" | translate }}:
            {{ order.generalComments }}</strong
          >
        </p>
      </ion-label>
    </ion-col>
    <!-- Order's Status Information -->
  </ion-row>

  <ion-row
    style="width: 100%"
    *ngIf="
      order &&
      order.ds_status &&
      ds_status_color &&
      (order.ds_status === 'order_delivered' ||
        order.ds_status === 'order_received' ||
        order.ds_status === 'order_picked_up')
    "
  >
    <ion-col size="12" class="ion-text-center">
      <ion-badge [color]="ds_status_color">
        <div *ngIf="order.ds_status === 'order_received'">
          {{ "orders.delivery-status" | translate }}:
        </div>
        <div
          *ngIf="order.ds_status === 'order_received'"
          style="font-size: small"
        >
          <ion-text *ngIf="order.ds_status === 'order_received'">
            {{ "orders." + order.ds_status.toLowerCase() | translate }} ~{{
              order.ds_info?.pickup_time_eta | date : "HH:mm"
            }}
          </ion-text>
          <ion-text *ngIf="order.ds_status === 'order_picked_up'">
            {{ "orders." + order.ds_status.toLowerCase() | translate }} ~{{
              order.ds_info?.dropoff_time_eta | date : "HH:mm"
            }}
          </ion-text>
          <ion-text
            *ngIf="
              order.ds_status !== 'order_picked_up' &&
              order.ds_status !== 'order_received'
            "
          >
            {{ "orders." + order.ds_status.toLowerCase() | translate }}
          </ion-text>
        </div>

        <div *ngIf="order.ds_status !== 'order_received'">
          <ion-text *ngIf="order.ds_status === 'order_received'">
            {{ "orders.delivery-status" | translate }}:
            {{ "orders." + order.ds_status.toLowerCase() | translate }} ~{{
              order.ds_info?.pickup_time_eta | date : "HH:mm"
            }}
          </ion-text>
          <ion-text *ngIf="order.ds_status === 'order_picked_up'">
            {{ "orders.delivery-status" | translate }}:
            {{ "orders." + order.ds_status.toLowerCase() | translate }} ~{{
              order.ds_info?.dropoff_time_eta | date : "HH:mm"
            }}
          </ion-text>
          <ion-text
            *ngIf="
              order.ds_status !== 'order_picked_up' &&
              order.ds_status !== 'order_received'
            "
          >
            {{ "orders.delivery-status" | translate }}:
            {{ "orders." + order.ds_status.toLowerCase() | translate }}
          </ion-text>
        </div>
      </ion-badge>
    </ion-col>
  </ion-row>

  <ion-row class="ion-margin-top">
    <ion-col size-xs="12" *ngIf="order.subtotal">
      <!-- Order's Financial Information -->
      <ion-label>
        <p style="font-weight: bold">
          {{ "orders.subtotal" | translate }}: {{ order.subtotal }}€
        </p>
      </ion-label>
    </ion-col>
    <ion-col size-xs="12" size-sm="4" *ngIf="order.discount_amount">
      <ion-label>
        <p>
          {{ "orders.discount_amount" | translate }}:
          {{ order.discount_amount }}€
        </p>
      </ion-label>
    </ion-col>
    <ion-col
      *ngIf="
        order.grand_total &&
        groupApp &&
        (!groupApp.design_settings ||
          !groupApp.design_settings.hideZeroPrices ||
          (groupApp.design_settings &&
            groupApp.design_settings.hideZeroPrices &&
            order.grand_total &&
            order.grand_total !== '0.00'))
      "
      size-xs="12"
    >
      <ion-label class="ion-text-left sm_align">
        <p style="font-weight: bold; font-size: 18px">
          {{ "orders.grand_total" | translate }}: {{ order.grand_total }}€
        </p>
      </ion-label>
    </ion-col>
    <ion-col size-xs="12" class="ion-no-padding">
      <ion-label class="ion-text-center">
        <p
          style="font-weight: bold"
          *ngIf="order.deliveryCost && order.deliveryCost !== '0'"
        >
          {{ "orders.cost_of_delivery_included" | translate }}:
          {{ order.deliveryCost }}€
        </p>
      </ion-label>
    </ion-col>
    <ion-col
      size-xs="12"
      class="ion-text-center ion-no-padding"
      *ngIf="
        order.cartCustomOptionsTotalPrice &&
        order.cartCustomOptionsTotalPrice !== '0' &&
        order.cartCustomOptionsTotalPrice !== '0.00'
      "
    >
      <ion-label class="ion-text-center">
        <p style="font-weight: bold">
          {{ "orders.extra_options" | translate }}:
          {{ order.cartCustomOptionsTotalPrice }}€
        </p>
      </ion-label>
    </ion-col>
    <ion-col
      *ngIf="order.coupons && order.coupons.length > 0"
      size="12"
      class="ion-text-center"
    >
      <ion-label class="ion-text-wrap">
        <p *ngFor="let coupon of order.coupons">
          <ion-text>{{ "orders.coupon" | translate }}</ion-text>
          <ion-text
            *ngIf="
              coupon.discount &&
              (coupon.discountType === 'percent' ||
                coupon.discountType === 'fixed')
            "
            color="danger"
          >
            -{{
              coupon.discount + (coupon.discountType === "percent" ? "%" : "€")
            }}</ion-text
          >
          <ion-text
            *ngIf="
              coupon.discount_percent &&
              coupon.discount_fixed &&
              coupon.discountType === 'combined'
            "
            color="danger"
          >
            -{{ coupon.discount_percent }}% & -{{
              coupon.discount_fixed
            }}€</ion-text
          >
          <ion-text> "{{ coupon.description }}"</ion-text>
        </p>
      </ion-label>
    </ion-col>
  </ion-row>

  <!--PRODUCTS-->
  <ion-row *ngIf="!order.products">
    <ion-col size="12">
      <div class="ion-padding ion-text-center" style="width: 100%">
        <ion-spinner></ion-spinner>
      </div>
    </ion-col>
  </ion-row>

  <ion-list *ngIf="order.products">
    <app-order-details-product-item
      *ngFor="let product of order.products"
      [product]="product"
      [order]="order"
    >
    </app-order-details-product-item>
  </ion-list>

  <ion-row
    class="ion-margin-top"
    *ngIf="
      order.cartCustomOptions &&
      order.cartCustomOptions.length > 0 &&
      order.cartCustomOptionsTotalPrice &&
      order.cartCustomOptionsTotalPrice !== '0.00' &&
      order.cartCustomOptionsTotalPrice !== '0'
    "
  >
    <ion-col size="12" class="ion-text-center">
      <!-- Order's Financial Information -->
      <div>
        <ion-label class="ion-text-wrap">
          <p>
            <strong
              >{{ "orders.extra_options" | translate }}
              {{
                order.cartCustomOptionsTotalPrice &&
                order.cartCustomOptionsTotalPrice !== "0" &&
                order.cartCustomOptionsTotalPrice !== "0.00"
                  ? " " + order.cartCustomOptionsTotalPrice + "€"
                  : ""
              }}</strong
            >
          </p>
        </ion-label>
      </div>
      <div *ngFor="let option of order.cartCustomOptions">
        <ion-label class="ion-text-wrap">
          <p>{{ option.title }}</p>
        </ion-label>
        <div *ngFor="let choice of option.choices">
          <ion-label class="ion-text-wrap">
            <p>
              {{ choice.title }}
              <strong
                >{{ choice.price ? choice.price.toFixed(2) + "€" : "" }} x
                {{ choice.qty }}
                {{
                  choice.totalPrice ? "= " + choice.totalPrice + "€" : ""
                }}</strong
              >
            </p>
          </ion-label>
        </div>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>
