<ion-header *ngIf="!height">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="primary" (click)="modalCtrl.dismiss()">
        <ion-icon name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{"reservation-app.map"|translate}}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content *ngIf="!height" class="item-list">
  <div
    [hidden]="!libraryLoaded"
    id="map"
    style="width: 100%; height: 100%"
    data-tap-disabled="true"
  ></div>

  <ion-button
    (click)="openLink()"
    expand="block"
    shape="round"
    class="absolute-bottom-button"
  >
    {{"reservation-app.directionsStore"|translate}}
  </ion-button>
</ion-content>

<ion-content *ngIf="height" class="item-list">
  <div
    [hidden]="!libraryLoaded"
    id="map"
    style="width: 100%; height: 170px"
    data-tap-disabled="true"
  ></div>
</ion-content>
