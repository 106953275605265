import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import { AnimationOptions } from "ngx-lottie";
import { auditTime, distinctUntilChanged, Subscription } from "rxjs";
import { AnalyticsServiceService } from "src/app/services/analitycsService/analytics-service.service";
import * as fromApp from "../../store/app.reducer";
import * as selectors from "../../store/app.selectors";

@Component({
  selector: "app-select-payment-method-popup",
  templateUrl: "./select-payment-method-popup.component.html",
  styleUrls: ["./select-payment-method-popup.component.scss"],
})
export class SelectPaymentMethodPopupComponent implements OnInit, OnDestroy {
  @Input() order: any;
  public store_info: any;
  public currentStoreId;
  public currentUser: any;
  public selectedPaymentMethodID: any;
  public stores: any;
  public storesByStoreId;
  public cartPaymentGateWays;
  public paymentGateways;
  public checkboxPromotions: any;
  public disableAllPaymentSelections: any;
  public darkMode: boolean;
  public windowDimensions: any;
  public loginState: any;
  colSizeBrowser = "6";
  private selectedTheme: any;
  private unsubscribeBackEvent: Subscription;
  private subscriptions: Subscription[] = [];

  cashAnimationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/cash-payment.json",
  };

  mobilePosAnimationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/mobile-pos-payment.json",
  };

  cardAnimationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/card-payment.json",
  };

  constructor(
    private store: Store<fromApp.AppState>,
    private modalController: ModalController,
    private platform: Platform,
    private analyticsService: AnalyticsServiceService
  ) {}

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.modalController.dismiss();
        }
      }
    );

    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && state.user && !_.isEqual(this.currentUser, state.user)) {
            this.currentUser = _.cloneDeep(state.user);
          }
          if (
            state &&
            state.loginState &&
            !_.isEqual(this.loginState, state.loginState)
          ) {
            this.loginState = _.cloneDeep(state.loginState);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((state) => {
          if (
            state &&
            state.windowDimensions &&
            !_.isEqual(this.windowDimensions, state.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.selectedTheme &&
            !_.isEqual(this.selectedTheme, state.selectedTheme)
          ) {
            this.selectedTheme = _.cloneDeep(state.selectedTheme);
            console.log("selected theme", this.selectedTheme);
            if (this.selectedTheme === "dark") {
              this.darkMode = true;
            } else {
              this.darkMode = false;
            }
          }
        })
    );
    this.subscriptions.push(
      this.store.select("selectedPaymentMethodID").subscribe((state) => {
        if (
          state &&
          state.selectedPaymentMethodID &&
          !_.isEqual(
            state.selectedPaymentMethodID,
            this.selectedPaymentMethodID
          )
        ) {
          this.selectedPaymentMethodID = _.cloneDeep(
            state.selectedPaymentMethodID
          );
        }
      })
    );

    this.subscriptions.push(
      this.store.select("stores").subscribe((state) => {
        if (state && state.stores && !_.isEqual(state.stores, this.stores)) {
          this.stores = _.cloneDeep(state.stores);
          this.storesByStoreId = _.groupBy(this.stores, "store_id");
          _.each(this.storesByStoreId, (store, key, list) => {
            this.storesByStoreId[key] = store[0];
          });
        }
      })
    );

    this.subscriptions.push(
      this.store.select("currentStore").subscribe((state) => {
        if (
          state &&
          state.currentStoreId &&
          !_.isEqual(state.currentStoreId, this.currentStoreId)
        ) {
          this.currentStoreId = _.cloneDeep(state.currentStoreId);
        }
      })
    );

    this.subscriptions.push(
      this.store
        .select("paymentGateWays")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(200))
        .subscribe((state) => {
          if (
            state &&
            state.paymentGateWays &&
            !_.isEqual(this.paymentGateways, state.paymentGateWays)
          ) {
            this.paymentGateways = _.cloneDeep(state.paymentGateWays);
            console.log("state payment gateways", this.paymentGateways);
          }
          if (
            state &&
            state.cartPaymentGateWays &&
            !_.isEqual(this.cartPaymentGateWays, state.cartPaymentGateWays)
          ) {
            this.cartPaymentGateWays = _.cloneDeep(state.cartPaymentGateWays);
            this.calculateColSizeBrowser();
            // console.log("carttt payment gateways", this.paymentGateways);
          }
        })
    );
  }

  calculateColSizeBrowser() {
    const activeGateways = _.cloneDeep(this.cartPaymentGateWays);
    for (const key in activeGateways) {
      if (
        (activeGateways &&
          activeGateways[key] &&
          activeGateways[key].disable_payment_gateway) ||
        !(
          activeGateways &&
          activeGateways[key] &&
          activeGateways[key].active &&
          (!activeGateways[key].adminOnly ||
            (this.currentUser && this.currentUser.admin))
        ) ||
        !(
          activeGateways &&
          activeGateways[key] &&
          activeGateways[key].active &&
          (!activeGateways[key].customerOnly ||
            (this.currentUser &&
              ((this.currentUser.admin && activeGateways[key].adminOnly) ||
                (this.currentUser.customerPaymentMethods &&
                  this.currentUser.customerPaymentMethods[key]))))
        )
      ) {
        delete activeGateways[key];
      }
    }

    let numberOfActivePaymentGateways = 0;

    for (const key in activeGateways) {
      numberOfActivePaymentGateways = numberOfActivePaymentGateways + 1;
    }

    if (numberOfActivePaymentGateways === 1) {
      this.colSizeBrowser = "12";
    } else if (numberOfActivePaymentGateways === 2) {
      this.colSizeBrowser = "6";
    } else if (numberOfActivePaymentGateways > 2) {
      this.colSizeBrowser = "4";
    }
  }

  checkForOnlineOnlyPaymentPromos() {
    this.subscriptions.push(
      this.store
        .select("checkboxPromotions")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            state.checkboxPromotions &&
            !_.isEqual(this.checkboxPromotions, state.checkboxPromotions)
          ) {
            this.checkboxPromotions = _.cloneDeep(state.checkboxPromotions);

            if (this.checkboxPromotions && this.checkboxPromotions.length > 0) {
              this.disableAllPaymentSelections = false;
              _.each(this.checkboxPromotions, (promo) => {
                if (promo.onlinePaymentOnly && promo.promoCheckbox) {
                  this.disableAllPaymentSelections = true;
                  if (this.paymentGateways && this.paymentGateways.length > 0) {
                    _.each(this.paymentGateways, (gateway) => {
                      if (gateway.id !== "paypal" && gateway.id !== "klarna") {
                        //TO_DO DO WE NEED THAT HERE
                      }
                    });
                  }
                }
              });
            }
          }
        })
    );
  }

  checkForDisablingSomeMethods(key, ev) {
    if (
      this.disableAllPaymentSelections &&
      (key == "cash" ||
        key === "mobilePOS" ||
        key === "paypal" ||
        key == "klarna")
    ) {
      return true;
    } else {
      return false;
    }
  }

  selectPaymentMethod(method) {
    this.selectedPaymentMethodID = _.cloneDeep(method.key);
    console.log("selected payment method", this.selectedPaymentMethodID);
    this.analyticsService.dmAnalyticsMessage(
      "change_order_payment_method",
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      this.selectedPaymentMethodID
    );
    this.modalController.dismiss(this.selectedPaymentMethodID);
  }

  closeModal() {
    this.modalController.dismiss();
  }

  ngOnDestroy() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    this.subscriptions = [];
  }
}
