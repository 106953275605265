<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button color="primary" (click)="modalCtrl.dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title mode="md" size="small">
      {{ "reservation-app.points-analysis" | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content scrollEvents="true" #content (ionScroll)="onScrollEvent($event)">
  <app-profile [userPoints]="userPoints" *ngIf="userPoints && !details">
  </app-profile>

  <ion-item
    *ngIf="
      !details &&
      groupApp &&
      groupApp.points.loyaltyCardLevelExpirationPointsMonths &&
      groupApp.points &&
      groupApp.points.loyaltyCardLevelsActive &&
      groupApp.points.loyaltyCardLevels &&
      groupApp.points.loyaltyCardLevels.length &&
      userPointsForLevels
    "
  >
    <ion-label class="ion-text-wrap">
      {{ "reservation-app.for-loyalty-levels" | translate }} (<ion-text
        *ngFor="let level of groupApp.points.loyaltyCardLevels"
      >
        {{
          level.name && level.name[selectedLangugage]
            ? level.name[selectedLangugage]
            : level.name[baseLanguage]
        }} </ion-text
      >)
      {{ "reservation-app.valid-points-is-the-points-user-for" | translate }}
      {{ groupApp.points.loyaltyCardLevelExpirationPointsMonths }}
      {{ "reservation-app.months" | translate }}.

      <div>
        {{ "reservation-app.total-points-card-levels" | translate }}:
        <strong>
          {{ userPointsForLevels.total || 0 }}
        </strong>
      </div>
    </ion-label>
  </ion-item>

  <app-reservation-points-history
    [scrollFromBottom]="scrollFromBottom"
    [userPoints]="userPoints"
    *ngIf="userPoints && details"
  >
  </app-reservation-points-history>
</ion-content>

<ion-footer
  *ngIf="userPoints && userPoints.records && userPoints.records.length"
>
  <ion-grid>
    <ion-row style="width: 100%">
      <ion-col class="ion-text-center">
        <ion-button (click)="details = !!!details">
          {{
            !details
              ? ("reservation-app.details" | translate)
              : ("reservation-app.back" | translate)
          }}
        </ion-button>
      </ion-col></ion-row
    >
  </ion-grid>
</ion-footer>
