<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">
      <ion-text>
        {{
          noStoreReviewMode
            ? ("orders.order-rating-modal.rate" | translate)
            : ("customerPreferencesModal.review_sent_successfully" | translate)
        }}
      </ion-text>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button color="dark" fill="clear" (click)="closeModal()">
        <ion-icon slot="icon-only" src="assets/ionicons/close.svg"> </ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content scrollX="false" scrollY="false">
  <ion-grid style="width: 100%; padding-top: 20px; z-index: 1000">
    <ion-row style="width: 100%" *ngIf="device && selectedLanguage">
      <ion-col class="ion-text-center">
        <ion-text>
          <strong>
            {{ "orders.order-rating-modal.please-rate-our-app-in" | translate }}
            <ion-text *ngIf="selectedLanguage && selectedLanguage === 'el'">
              {{
                device && device === "android"
                  ? "Google Play!"
                  : device && device === "ios"
                  ? "App Store!"
                  : ""
              }}
            </ion-text>
            <ion-text *ngIf="selectedLanguage && selectedLanguage === 'en'">
              {{
                device && device === "android"
                  ? "Google Play?"
                  : device && device === "ios"
                  ? "App Store?"
                  : ""
              }}
            </ion-text>
            <ion-text *ngIf="selectedLanguage && selectedLanguage === 'de'">
              {{
                device && device === "android"
                  ? "Google Play?"
                  : device && device === "ios"
                  ? "App Store?"
                  : ""
              }}
            </ion-text>
          </strong>
        </ion-text>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid style="width: 100%; top: 0; position: absolute">
    <ion-row
      style="width: 100%; height: 100%"
      class="ion-align-items-center ion-justify-content-center"
    >
      <ion-col class="ion-align-self-center ion-text-center ion-no-padding">
        <ion-grid style="max-width: 270px">
          <ng-lottie
            (animationCreated)="animationCreated($event)"
            [options]="canceledOptions"
          ></ng-lottie>
        </ion-grid>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-grid>
    <ion-row style="width: 100%">
      <ion-col class="ion-text-center">
        <ion-button size="medium" (click)="rateApp()">
          {{ "orders.order-rating-modal.rate" | translate }}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
