import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-billing-info-verify",
  templateUrl: "./billing-info-verify.component.html",
  styleUrls: ["./billing-info-verify.component.scss"],
})
export class BillingInfoVerifyComponent implements OnInit {
  @Input() verifyAddressRes: any;
  constructor(private modalController: ModalController) {}

  ngOnInit() {
    console.log("billing info verify res", this.verifyAddressRes);
  }

  closeModal() {
    this.modalController.dismiss();
  }

  onClick(action) {
    this.modalController.dismiss(action);
  }
}
