import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  AlertController,
  IonContent,
  ModalController,
  Platform,
} from "@ionic/angular";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../store/app.reducer";
import { AnimationOptions } from "ngx-lottie";
import { CheckLocationDistanceService } from "src/app/services/checkLocationDistance/check-location-distance.service";
import { Subscription, distinctUntilChanged } from "rxjs";

import * as _ from "lodash";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-fixed-points-address-selection-modal",
  templateUrl: "./fixed-points-address-selection-modal.component.html",
  styleUrls: ["./fixed-points-address-selection-modal.component.scss"],
})
export class FixedPointsAddressSelectionModalComponent
  implements OnInit, OnChanges, OnDestroy
{
  @ViewChild(IonContent) content: IonContent;
  public fixedPointsAddresses: any;
  showGpsLoadingAnimation = false;
  gpsDistanceLoading = false;

  options: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/gps-stores-loading-animation.json",
  };

  private unsubscribeBackEvent: Subscription;
  private subscription: Subscription;

  constructor(
    private modalController: ModalController,
    private store: Store<fromApp.AppState>,
    private platform: Platform,
    private alertController: AlertController,
    private translateService: TranslateService,
    private checkLocationDistanceService: CheckLocationDistanceService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
    }
  }

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      400,
      () => {
        if (this.platform.is("android")) {
          this.modalController.dismiss();
        }
      }
    );
    this.subscription = this.store
      .select("deliveryMethodAddress")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          !_.isEqual(state.fixedPointsAddresses, this.fixedPointsAddresses)
        ) {
          if (
            state.fixedPointsAddresses &&
            state.fixedPointsAddresses.length > 1 &&
            this.fixedPointsAddresses
          ) {
            let showLottieGpsLoading = false;

            _.each(_.cloneDeep(state.fixedPointsAddresses), (statePoint) => {
              const previousStoreVar = _.find(this.fixedPointsAddresses, {
                fixed_address_id: statePoint.fixed_address_id,
              });
              if (
                statePoint &&
                previousStoreVar &&
                statePoint.estimatedDistanceFromClientGps &&
                (!previousStoreVar.estimatedDistanceFromClientGps ||
                  previousStoreVar.estimatedDistanceFromClientGps !==
                    statePoint.estimatedDistanceFromClientGps)
              ) {
                showLottieGpsLoading = true;
              }
            });

            if (showLottieGpsLoading) {
              this.showGpsReorderAnimationLoading();
            }
          }

          this.fixedPointsAddresses = _.cloneDeep(state.fixedPointsAddresses);

          if (
            this.fixedPointsAddresses &&
            this.fixedPointsAddresses.length > 1
          ) {
            let gpsPrompt = false;
            _.each(this.fixedPointsAddresses, (point) => {
              if (point && !point.estimatedDistanceFromClientGps) {
                gpsPrompt = true;
              }
            });
            if (gpsPrompt) {
              this.checkFixedAddressesGPS();
            }
          }
        }
      });
  }

  showGpsReorderAnimationLoading() {
    this.showGpsLoadingAnimation = true;
    if (this.content) {
      this.content.scrollToTop();
    }
    setTimeout(() => {
      this.showGpsLoadingAnimation = false;
    }, 1700);
  }

  async calculateLocations() {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      message: this.translateService.instant(
        "select-fixed-points-address-modal.fixed-points-address-selection-modal.do-you-want-to-order-by-location"
      ),
      buttons: [
        {
          text: this.translateService.instant("cancel"),
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {},
        },
        {
          text: this.translateService.instant("yes"),
          handler: async () => {
            if (
              this.fixedPointsAddresses &&
              this.fixedPointsAddresses.length > 1
            ) {
              this.gpsDistanceLoading = true;
              const res =
                await this.checkLocationDistanceService.calculateDistanceForFixedPointsAddressesGPS(
                  this.fixedPointsAddresses,
                  true
                );
              this.gpsDistanceLoading = false;
            }
          },
        },
      ],
    });
    await alert.present();
  }

  async checkFixedAddressesGPS() {
    if (
      this.fixedPointsAddresses &&
      this.fixedPointsAddresses.length &&
      this.fixedPointsAddresses.length > 1
    ) {
      this.gpsDistanceLoading = true;

      const res: any =
        await this.checkLocationDistanceService.calculateDistanceForFixedPointsAddressesGPS(
          this.fixedPointsAddresses,
          false
        );

      this.gpsDistanceLoading = false;
    }
  }

  trackFixedPoints(index, item) {
    return item.fixed_address_id;
  }

  animationCreated(ev) {}

  ondismiss() {
    this.modalController.dismiss();
  }

  selectFixedAdressPoint(fixed_address_id) {
    this.modalController.dismiss(fixed_address_id);
  }

  ngOnDestroy(): void {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
