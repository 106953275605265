<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button fill="clear"
                  [disabled]="migrationLoading"
                  (click)="closeModal()">
        <ion-icon src="assets/ionicons/close.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <ion-text>{{
        "loyaltySystem.loyalty-more-page.migrate-points" | translate
        }}</ion-text>
    </ion-title>
  </ion-toolbar>
</ion-header>


<ion-modal [isOpen]="isProgressModalOpen"
           style="--ion-background-color: black; opacity: 0.8; --width: 100%; --height: 100%">
  <ng-template>
    <ion-content>
      <ion-grid style="max-width: 400px; height: 100%;">
        <ion-row style="height: 100%;"
                 class="ion-align-items-center ion-justify-content-center">
          <ion-col>
            <p style="color: white; margin-bottom: 15px;">
              Η μεταφορά πόντων βρίσκεται σε εξέλιξη. Παρακαλώ περιμένετε.
            </p>
            <ion-progress-bar [value]="progress"></ion-progress-bar>
            <p style="color: white; font-size: 12px;">
              Η διαδικασία διαρκεί λιγότερο από 1 λεπτό
            </p>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ng-template>
</ion-modal>

<ion-grid *ngIf="mo_token">
  <ion-row>
    <ion-col>
      <ion-text color="medium">
        {{'loyaltySystem.loyalty-more-page.migrate-points-info-message' | translate}}
      </ion-text>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <ion-item fill="outline">
        <ion-label position="floating"> Παλιός κωδικός μέλους: </ion-label>
        <ion-input placeholder="γράψτε εδώ"
                   type="tel"
                   [clearInput]="true"
                   [(ngModel)]="memberNumberPhone"
                   minlength="1"
                   maxlength="10">
        </ion-input>
      </ion-item>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="12"
             class="ion-text-center">
      <ion-button (click)="migratePoints()"
                  [disabled]="migrationLoading || !memberNumberPhone">
        <ion-text *ngIf="!migrationLoading">
          {{ "loyaltySystem.loyalty-more-page.migrate" | translate }}
        </ion-text>
        <ion-spinner *ngIf="migrationLoading"> </ion-spinner>
      </ion-button>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="12"
             class="ion-text-center">
      <ion-button fill="clear"
                  (click)="openConfirmationAlertNoOldMemberNumber()">
        <ion-text *ngIf="!migrationLoading">
          {{ "loyaltySystem.no-old-member-number" | translate }}
        </ion-text>
        <!-- <ion-spinner *ngIf="migrationLoading"> </ion-spinner> -->
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>
<!-- <ion-footer>
  <ion-grid>

  </ion-grid>
</ion-footer> -->