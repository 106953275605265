import * as CategoriesSelectionActions from "./categoriesSelection.actions";
import * as _ from "lodash";

export interface State {
  selectedCategoryId: any;
  selectedScrollCategoryId: any;
}

const initialState: State = {
  selectedCategoryId: null,
  selectedScrollCategoryId: null,
};

export function verticalCategoryReducer(
  state = initialState,
  action: CategoriesSelectionActions.CategoriesSelectionActions
) {
  switch (action.type) {
    case CategoriesSelectionActions.SET_SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategoryId: action.payload,
      };

    case CategoriesSelectionActions.SET_SCROLL_SELECTED_CATEGORY:
      return {
        ...state,
        selectedScrollCategoryId: action.payload,
      };

    case CategoriesSelectionActions.CLEAR_SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategoryId: null,
        selectedScrollCategoryId: null,
      };

    default:
      return state;
  }
}
