<ion-item>
  <ion-row style="width: 100%">
    <ion-col *ngIf="order" size="12">
      <ion-label class="ion-text-wrap">
        <!-- Eikonidio otan promos -->
        <ion-text *ngIf="!product.gift"> </ion-text>

        <!--Product name-->
        <!-- I SHOULD NOT USE THE isCategoryPromo FILTER HERE! -->
        <ion-text
          *ngIf="!product.enable_quantity_step || !product.quantity_step"
          class="ion-text-wrap"
        >
          {{ product.quantity }} X
          <ion-text
            ><strong>{{ product.name }}</strong></ion-text
          >
        </ion-text>

        <ion-text
          *ngIf="product.enable_quantity_step && product.quantity_step"
          class="ion-text-wrap"
        >
          {{ product.quantity
          }}{{
            !product.disable_qty && !product.quantity_step_kind
              ? "x"
              : product.quantity_step_kind
          }}
          <ion-text
            ><strong>{{ product.name }}</strong></ion-text
          > </ion-text
        ><br *ngIf="product.promotionDescription" />

        <ion-text *ngIf="product.promotionDescription" color="danger">{{
          product.promotionDescription
        }}</ion-text>

        <!--Product is not available-->
        <p *ngIf="!product.available">
          <ion-text color="danger">{{
            "orders.not_available" | translate
          }}</ion-text>
        </p>

        <!--Price-->
        <ion-text class="ion-float-right">
          <ion-text *ngIf="product.gift" color="danger"
            ><strong>{{ "orders.free" | translate }}</strong></ion-text
          >

          <span
            class="cus_padding"
            *ngIf="
              !product.gift &&
              groupApp &&
              (!groupApp.design_settings ||
                !groupApp.design_settings.hideZeroPrices ||
                (groupApp.design_settings &&
                  groupApp.design_settings.hideZeroPrices &&
                  order.price &&
                  order.price !== '0.00'))
            "
            [ngStyle]="{
              'text-decoration':
                product.specialPrice !== 0 && product.specialPrice
                  ? 'line-through'
                  : 'none'
            }"
          >
            <strong
              >{{
                order.pickup && !order.dinein
                  ? product.price_takeaway
                    ? product.price_takeaway
                    : product.price
                  : !order.pickup && order.dinein
                  ? order.table_alt_dinein && product.price_alt_dinein
                    ? product.price_alt_dinein
                    : product.price_dinein
                    ? product.price_dinein
                    : product.price
                  : product.price
              }}€{{
                product.enable_quantity_step &&
                product.quantity_step &&
                product.quantity_step_kind
                  ? "/" + product.quantity_step_kind
                  : ""
              }}</strong
            >
            <!-- <strong>{{ product.price }}€</strong> -->
          </span>

          <ion-text
            class="cus_padding"
            *ngIf="
              !product.gift &&
              product.specialPrice &&
              product.specialPrice !== 0
            "
            color="danger"
          >
            <strong>
              {{ product.specialPrice }}€{{
                product.enable_quantity_step &&
                product.quantity_step &&
                product.quantity_step_kind
                  ? "/" + product.quantity_step_kind
                  : ""
              }}</strong
            >
          </ion-text>
        </ion-text>
      </ion-label>
      <app-order-details-product-item-options
        *ngFor="let option of product.options"
        [option]="option"
        [product]="product"
        [order]="order"
      >
      </app-order-details-product-item-options>
    </ion-col>
  </ion-row>
</ion-item>
