<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">
      <ion-text style="font-size: medium">
        {{ "popups.send-order-and-pay-popup.reservation-title" | translate }}</ion-text
      >
    </ion-title>

    <ion-button
      (click)="closeModal()"
      size="small"
      slot="end"
      fill="clear"
      color="dark"
    >
      <ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-backdrop
  [visible]="true"
  style="z-index: 1000000; opacity: 0.3"
  *ngIf="createOrderLoadingAnimation"
>
</ion-backdrop>
<ion-grid
  *ngIf="createOrderLoadingAnimation"
  style="
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 1000000000;
  "
>
  <ion-row style="width: 100%; height: 100%">
    <ion-col class="ion-text-center">
      <ion-grid style="max-width: 220px">
        <ng-lottie
          (animationCreated)="animationCreated($event)"
          [options]="animationOptions"
        ></ng-lottie>
      </ion-grid>
    </ion-col>
  </ion-row>
</ion-grid>

<ion-content #content>
  <ion-item
    lines="none"
  >
    <ion-checkbox slot="start" [(ngModel)]="newCard.acceptedTerms">
    </ion-checkbox>
    <ion-label class="ion-text-wrap" style="font-size: small">
      {{
        "popups.send-order-and-pay-popup.i-accept-the-payment-terms" | translate
      }}
    </ion-label>
  </ion-item>
</ion-content>

<ion-footer>
  <ion-grid>
    <ion-row *ngIf="loading" style="width: 100%">
      <ion-col style="padding: 0; margin: 0" class="ion-text-center">
        <ion-spinner color="primary"> </ion-spinner>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="!loading" style="width: 100%">
      <!-- <ion-col style="padding: 0; margin: 0" size="6">
        <ion-button size="medium" (click)="closeModal()" fill="clear">
          {{ "popups.send-order-and-pay-popup.cancel" | translate }}
        </ion-button>
      </ion-col> -->

      <ion-col style="padding: 0; margin: 0" size="12" class="ion-text-center">
        <ion-button
          size="medium"
          (click)="
              checkForSendOrder()
          "
        >
          {{ "popups.send-order-and-pay-popup.send-order-button" | translate }}
          {{
            reservation.prepay_amount
          }}€
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
