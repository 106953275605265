import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-popover',
  templateUrl: './product-popover.component.html',
  styleUrls: ['./product-popover.component.scss'],
})
export class ProductPopoverComponent implements OnInit {
  @Input() product;

  constructor() { }

  ngOnInit() {}

}
