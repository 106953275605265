import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";
import * as _ from "lodash";
import { initializeApp } from "firebase/app";

@Injectable({
  providedIn: "root",
})
export class FirebaseBrowserService {
  private firebaseApp: any;
  private recapthaVerifier: any;

  constructor(private store: Store<fromApp.AppState>) {}

  initializeFirebaseBrowser(groupApp) {
    if (groupApp) {
      console.log("initizalize firebase app");
      this.firebaseApp = initializeApp(groupApp.webFirebase);
    }
  }

  initializeRecaptchaVerifier() {}

  getFireBaseApp() {
    return this.firebaseApp;
  }

  setRecapthaVerifier(verifier) {
    this.recapthaVerifier = verifier;
  }

  getRecapthaVerifier() {
    return this.recapthaVerifier;
  }
}
