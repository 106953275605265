import { Injectable } from "@angular/core";
import * as _ from "lodash";
import moment from "moment-timezone";
import * as scf from "@deliverymanager/schedule_fns";

@Injectable({
  providedIn: "root",
})
export class InformationMessagesService {
  constructor() {}

  checkForMobileOrderInfoMessagesStore(store_info, page) {
    console.log("check mobileorder info messages", store_info, page);
    if (store_info && store_info.mobileorder_info_messages) {
      let InfoMessages = [];
      _.each(store_info.mobileorder_info_messages, (message) => {
        //TO_DO FOR SCHEDULE MESSAGE
        if (
          message.page &&
          message.page.length &&
          _.includes(message.page, page) &&
          message.activePeriod &&
          message.active &&
          message.activePeriod === "permanent" &&
          this.checkSchedule(message.schedule, store_info.timezone)
        ) {
          InfoMessages.push(message);
        } else if (
          message.page &&
          message.page.length &&
          _.includes(message.page, page) &&
          message.active &&
          message.activePeriod &&
          message.activePeriod === "discrete" &&
          message.activeDateFrom &&
          message.activeDateTo &&
          moment().isAfter(moment(parseInt(message.activeDateFrom))) &&
          moment().isBefore(moment(parseInt(message.activeDateTo))) &&
          this.checkSchedule(message.schedule, store_info.timezone)
        ) {
          console.log("info messages with schedule");
          InfoMessages.push(message);
        }
      });
      console.log("info messages", InfoMessages);
      return InfoMessages;
    }
  }

  checkForMobileOrderInfoMessagesGroupApp(groupApp, store_info, page) {
    console.log("check mobileorder info messages", groupApp, page);
    if (groupApp && groupApp.mobileorder_info_messages) {
      let InfoMessages = [];
      _.each(groupApp.mobileorder_info_messages, (message) => {
        //TO_DO FOR SCHEDULE MESSAGE

        if (
          message.page &&
          message.page.length &&
          _.includes(message.page, page) &&
          message.activePeriod &&
          message.active &&
          message.activePeriod === "permanent" &&
          this.checkSchedule(message.schedule, store_info.timezone)
        ) {
          InfoMessages.push(message);
        } else if (
          message.page &&
          message.page.length &&
          _.includes(message.page, page) &&
          message.active &&
          message.activePeriod &&
          message.activePeriod === "discrete" &&
          message.activeDateFrom &&
          message.activeDateTo &&
          moment().isAfter(moment(parseInt(message.activeDateFrom))) &&
          moment().isBefore(moment(parseInt(message.activeDateTo))) &&
          this.checkSchedule(message.schedule, store_info.timezone)
        ) {
          console.log("info messages with schedule");
          InfoMessages.push(message);
        }
      });
      console.log("info messages groupAPp", InfoMessages);
      return InfoMessages;
    }
  }

  private checkSchedule(scheduleArray: any, timezone: any) {
    console.log("schedule array", scheduleArray);
    const arrayOfObjects = _.filter(
      scheduleArray,
      (obj) => _.isObject(obj) && !_.isEmpty(obj)
    );
    return scf.is_store_online(
      scf.format_schedule(
        _.filter(arrayOfObjects, (obj) => _.isObject(obj) && !_.isEmpty(obj))
      ),
      moment()
        .tz(timezone || "Europe/Athens")
        .format("x"),
      null,
      timezone || "Europe/Athens"
    ).open;
  }
}
