import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { ModalController, Platform } from "@ionic/angular";
import { url } from "inspector";
import { AnimationOptions } from "ngx-lottie";

@Component({
  selector: "app-no-scanner-available-modal",
  templateUrl: "./no-scanner-available-modal.component.html",
  styleUrls: ["./no-scanner-available-modal.component.scss"],
})
export class NoScannerAvailableModalComponent implements OnInit, OnDestroy {
  @Input() branchLink;
  @Input() group;
  nativePlatform: string;
  animationOptionsAndroid: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/download-now-android.json",
  };

  animationOptionsIos: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/download-now-ios.json",
  };
  unsubscribeBackEvent: any;

  constructor(
    private readonly modalController: ModalController,
    private platform: Platform
  ) {}

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      300,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );
    if (this.platform.is("android")) {
      this.nativePlatform = "android";
    } else if (this.platform.is("ios")) {
      this.nativePlatform = "ios";
    } else if (this.platform.is("desktop")) {
      this.nativePlatform = null;
    }
    console.log("platform", this.nativePlatform);
  }

  async closeModal(): Promise<void> {
    this.modalController.dismiss();
  }

  openLink(link) {
    window.open("https://" + link);
  }

  ngOnDestroy(): void {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
  }
}
