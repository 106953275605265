<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">
      <ion-text *ngIf="type && type === 'reward'">
        {{ "kiosk-page.kiosk-get-promo-modal.reward_title" | translate }}
      </ion-text>
      <ion-text *ngIf="type && type === 'couponRule'">
        {{ "kiosk-page.kiosk-get-promo-modal.coupon_rule_title" | translate }}
      </ion-text>
    </ion-title>
    <ion-button
      (click)="closeModal()"
      [disabled]="loading"
      size="small"
      slot="end"
      fill="clear"
      color="dark"
    >
      <ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid
    *ngIf="usePointsAnimation"
    style="
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 100000;
    "
  >
    <ion-row
      style="width: 100%; height: 100%"
      class="ion-align-items-center ion-justify-content-center"
    >
      <ion-col class="ion-align-self-center ion-text-center">
        <ion-grid style="max-width: 350px">
          <ng-lottie
            (animationCreated)="animationCreated($event)"
            (loopComplete)="animationComplete($event)"
            (error)="onAnimationError($event)"
            [options]="usePointsSuccessAnimationOptions"
          ></ng-lottie>
        </ion-grid>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid style="padding: 0">
    <img
      style="width: 100%"
      alt="rewardImage"
      *ngIf="promo.image && type && type === 'couponRule'"
      [src]="
        'https://d3vm1ym7xlqx7l.cloudfront.net/' +
        groupApp.group +
        '/coupon-rule-images/' +
        promo.image
      "
    />
    <img
      style="width: 100%"
      alt="rewardImage"
      *ngIf="promo.image && type && type === 'reward'"
      [src]="
        'https://d3vm1ym7xlqx7l.cloudfront.net/' +
        groupApp.group +
        '/rewards/' +
        promo.image
      "
    />

    <ion-item lines="none">
      <ion-label class="ion-text-wrap">
        <ion-text style="font-weight: bold">
          {{
            type === "couponRule" ? promo.description : promo.title
          }}</ion-text
        >
        <div *ngIf="promo && promo.tag_comment">
          <ion-badge>
            {{ promo.tag_comment }}
          </ion-badge>
        </div>
        <p
          *ngIf="promo.loyalty_offer && promo.productDescription"
          style="margin-top: 20px"
        >
          {{ promo.productDescription }}
        </p>
      </ion-label>
    </ion-item>

    <ion-item lines="none" *ngIf="type === 'reward'">
      <ion-label class="ion-text-wrap">
        <p *ngIf="promo.only_kiosk" style="font-size: small; font-weight: 700">
          <ion-text color="danger">
            {{
              "kiosk-page.kiosk-get-promo-modal.this-promo-is-only-kiosk"
                | translate
            }}
          </ion-text>
        </p>
        <p *ngIf="promo.points" style="font-size: small; font-weight: bold">
          <ion-text>
            {{ "points.rewards.needs" | translate }} {{ promo.points }}
            {{ "points.rewards.points-for-cash-out" | translate }}
          </ion-text>
        </p>
        <p *ngIf="promo.appliesToOrderGreaterThan" style="margin: 0">
          <ion-text>
            {{ "points.rewards.order-greater-than" | translate }}:
            {{ promo.appliesToOrderGreaterThan }}€
          </ion-text>
        </p>

        <p
          *ngIf="promo.type === 'third_party' && promo.third_party_description"
          style="margin: 0"
          color="danger"
        >
          <ion-text style="font-size: small">
            {{ promo.third_party_description }}
          </ion-text>
        </p>
      </ion-label>
    </ion-item>

    <div
      class="ion-text-center"
      *ngIf="
        promo &&
        type === 'reward' &&
        promo.type === 'third_party' &&
        promo.third_party_url
      "
    >
      <ion-button
        color="danger"
        (click)="openInBrowser(promo)"
        size="small"
        mode="ios"
      >
        {{ "points.rewards.third-party-store" | translate }}
      </ion-button>
    </div>

    <ion-item lines="none" *ngIf="type === 'couponRule'">
      <ion-label class="ion-no-padding" class="ion-text-wrap">
        <p *ngIf="promo.only_kiosk" style="font-size: small; font-weight: 700">
          <ion-text color="danger">
            {{
              "kiosk-page.kiosk-get-promo-modal.this-promo-is-only-kiosk"
                | translate
            }}
          </ion-text>
        </p>
        <p
          style="font-size: small; font-weight: 700"
          *ngIf="promo.label && promo.appliesToOrderGreaterThan > 0"
        >
          {{ "kiosk-page.kiosk-get-promo-modal.minimum-order" | translate }}:
          {{ promo.appliesToOrderGreaterThan }}‎€
        </p>
        <p
          style="font-size: small; font-weight: 700"
          *ngIf="promo.activeFrom || promo.activeTo"
        >
          {{ "kiosk-page.kiosk-get-promo-modal.valid" | translate }}&nbsp;
          {{
            promo.activeFrom
              ? ("kiosk-page.kiosk-get-promo-modal.from" | translate) +
                " " +
                (promo.activeFrom | date : "dd/MM/yyyy HH:mm") +
                " "
              : ""
          }}
          {{
            promo.activeTo
              ? ("kiosk-page.kiosk-get-promo-modal.to" | translate) +
                " " +
                (promo.activeTo | date : "dd/MM/yyyy")
              : ""
          }}
        </p>

        <!-- <p *ngIf="promo.single_store_use">
          {{
            "kiosk-page.kiosk-get-promo-modal.coupon-single-store-use"
              | translate
          }}
        </p> -->

        <p
          *ngIf="
            stores &&
            stores.length > 1 &&
            promo.store_id &&
            promo.store_id === 'all'
          "
        >
          {{
            "kiosk-page.kiosk-get-promo-modal.valid-to-all-group-stores"
              | translate
          }}
        </p>
        <p
          *ngIf="
            stores &&
            stores.length > 1 &&
            promo.store_id &&
            promo.store_id !== 'all'
          "
        >
          {{
            "kiosk-page.kiosk-get-promo-modal.not-valid-to-all-group-stores"
              | translate
          }}
        </p>
      </ion-label>

      <div
        style="position: absolute; bottom: 5px; right: 10px; font-size: small"
        *ngIf="promo && promo.points"
      >
        {{ "kiosk-page.kiosk-get-promo-modal.from_lowercase" | translate }}
        {{ promo.points }}
        {{ "kiosk-page.kiosk-get-promo-modal.points" | translate }}
      </div>

      <ion-badge style="min-width: 32px; margin-left: 10px">
        <span
          *ngIf="
            promo.discountType !== 'percent' &&
            promo.discountType !== 'combined' &&
            promo.discount
          "
        >
          -{{ promo.discount }}€
        </span>
        <span *ngIf="promo.discountType === 'percent' && promo.discount">
          -{{ promo.discount }}%
        </span>
        <span
          *ngIf="
            promo.discountType === 'combined' &&
            promo.discount_percent &&
            promo.discount_fixed
          "
        >
          -{{ promo.discount_percent }}% & -{{ promo.discount_fixed }}€
        </span>
      </ion-badge>
    </ion-item>
  </ion-grid>
</ion-content>

<ion-footer *ngIf="type">
  <ion-grid>
    <ion-row style="width: 100%">
      <ion-col class="ion-text-center">
        <ion-button
          *ngIf="
            !showLoginRegisterButton &&
            !(type === 'couponRule' && promo.rule_already_used)
          "
          color="primary"
          [disabled]="
            (type === 'reward' &&
              (!promo.user_points_percent ||
                promo.user_points_percent !== 100)) ||
            !currentUser ||
            loading
          "
          (click)="
            type === 'reward'
              ? usePointsConfirm(promo)
              : useCouponRuleConfirm(promo)
          "
        >
          <ion-spinner *ngIf="loading"></ion-spinner>

          <ion-text *ngIf="!loading">
            {{
              type && type === "couponRule"
                ? ("kiosk-page.kiosk-get-promo-modal.get-it" | translate)
                : ("kiosk-page.kiosk-get-promo-modal.cash-out" | translate)
            }}
          </ion-text>
        </ion-button>
        <p
          *ngIf="
            type &&
            type === 'couponRule' &&
            promo.rule_already_used &&
            promo.ruleAlreadyUsedCouponTimestamp
          "
          style="margin: 0; font-weight: bold; font-size: small"
        >
          <ion-text color="complete">
            {{
              "kiosk-page.kiosk-get-promo-modal.coupon-rule-already-used"
                | translate
            }}
          </ion-text>
        </p>
        <ion-button
          *ngIf="
            type &&
            type === 'couponRule' &&
            promo.rule_already_used &&
            promo.ruleAlreadyUsedCouponTimestamp
          "
          (click)="showTheCoupon(promo.ruleAlreadyUsedCouponTimestamp)"
        >
          {{ "kiosk-page.kiosk-get-promo-modal.see-the-coupon" | translate }}
        </ion-button>
        <ion-button
          *ngIf="showLoginRegisterButton"
          color="primary"
          size="medium"
          (click)="presentMagicLoginPopup()"
        >
          <ion-text>
            {{ "homepage.magic-login" | translate }}
          </ion-text>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
