<ion-header
  *ngIf="windowInfo"
  [hidden]="windowInfo && windowInfo.width && menuMinWidthToShow &&  windowInfo.width> menuMinWidthToShow && !modal"
>
  <app-no-internet-connection></app-no-internet-connection>
  <app-ionic-update-error></app-ionic-update-error>
  <ion-toolbar>
    <ion-title>{{"pages.payment-terms" | translate}}</ion-title>
    <ion-buttons slot="start" *ngIf="groupApp && windowInfo && !modal">
      <ion-menu-button color="dark"></ion-menu-button>
    </ion-buttons>
    <ion-buttons slot="start" *ngIf="modal">
      <ion-button color="primary" (click)="modalCtrl.dismiss()">
        <ion-icon name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="windowInfo">
  <ion-grid
    [class]="windowInfo && windowInfo.width && menuMinWidthToShow &&  windowInfo.width< menuMinWidthToShow ? '': 'ion-padding' && selectedLanguage"
    *ngIf="stores"
  >
    <div
      *ngIf="stores && stores.length>1 && storeInfo && !storeInfo.virtual_store_change_hyper_categories_buttons&&
          groupApp.store_change_catalog_button"
      style="z-index: 10000; width: 100%"
    >
      <ion-grid style="max-width: 340px">
        <ion-segment mode="ios" [(ngModel)]="selectedStoreId">
          <ion-segment-button
            [ngClass]="{selectedSegment: store &&  selectedStoreId && store.store_id === selectedStoreId, selectedSegmentMinHeight: store.store_change_catalog_buttons_icon}"
            [value]="store.store_id"
            *ngFor="let store of stores"
          >
            <ion-icon
              style="font-size: 25px"
              *ngIf="store.store_change_catalog_buttons_icon"
              [src]="deliveryManagerPath +store.store_id + '/' + 'storeChangeCatalogButtonsIcons/' +  store.store_change_catalog_buttons_icon"
            >
            </ion-icon>

            <ion-text style="font-size: small; font-weight: bold">
              {{ store.store_name_catalog_change_langs &&
              store.store_name_catalog_change_langs[selectedLanguage] ?
              store.store_name_catalog_change_langs[selectedLanguage] :
              store.store_name_catalog_change_langs &&
              store.store_name_catalog_change_langs.el ?
              store.store_name_catalog_change_langs.el :
              store.store_name_mobileorder &&
              store.store_name_mobileorder[selectedLanguage] ?
              store.store_name_mobileorder[selectedLanguage] :
              store.store_name_langs && store.store_name_langs[selectedLanguage]
              ? store.store_name_langs[selectedLanguage] :
              store.store_name_langs && store.store_name_langs.el ?
              store.store_name_langs.el : store.store_name ? store.store_name :
              "" }}</ion-text
            >
          </ion-segment-button>
        </ion-segment></ion-grid
      >
    </div>
    <ion-item
      color="primary"
      style="margin-bottom: 10px"
      *ngIf="stores.length>1 && !(storeInfo && !storeInfo.virtual_store_change_hyper_categories_buttons&&
      groupApp.store_change_catalog_button)"
    >
      <ion-label>{{'store-schedule.select-store' | translate}}</ion-label>
      <ion-select
        [cancelText]="'cancel' | translate"
        (ionChange)="changeStore()"
        [(ngModel)]="selectedStoreId"
      >
        <ion-select-option
          *ngFor="let store of stores"
          [value]="store.store_id"
        >
          {{ store.store_name_mobileorder &&
          store.store_name_mobileorder[selectedLanguage] ?
          store.store_name_mobileorder[selectedLanguage] : (
          store.store_panel_name_langs &&
          store.store_panel_name_langs[selectedLanguage] ?
          store.store_panel_name_langs[selectedLanguage] :
          store.store_panel_name_langs && store.store_panel_name_langs.el ?
          store.store_panel_name_langs.el : store.store_name_panel ?
          store.store_name_panel : store.store_name ? store.store_name : "") }}
        </ion-select-option>
      </ion-select>
    </ion-item>
    <div *ngFor="let store of stores">
      <ion-list class="ion-no-padding">
        <!-- <ion-item-divider *ngIf="stores.length>1" mode="ios">
          {{store.store_name_panel ? store.store_name_panel : store.store_name}}
          <ion-button
            size="medium"
            (click)="this.expanded[store.store_id] = !this.expanded[store.store_id]"
            slot="end"
            fill="clear"
            ><ion-icon
              slot="icon-only"
              *ngIf="!this.expanded[store.store_id]"
              src="assets/ionicons/add.svg"
            ></ion-icon
            ><ion-icon
              slot="icon-only"
              *ngIf="this.expanded[store.store_id]"
              src="assets/ionicons/remove.svg"
            ></ion-icon
          ></ion-button>
        </ion-item-divider> -->

        <div
          *ngIf="(stores && stores.length>1 && (selectedStoreId === store.store_id)) || stores && stores.length ===1"
        >
          <ion-item lines="none">
            <ion-label class="ion-text-wrap">
              <strong
                >{{"payment-terms.company-information" | translate}}</strong
              >
              <div>{{store.vatName}}</div>
              <div>{{store.vatAddress}}</div>
              <div>
                {{"payment-terms.store-vat" | translate}}: {{store.vatNumber}}
              </div>
              <div>
                {{"payment-terms.telephone" | translate}}: {{store.telephone}}
              </div>
              <div>
                {{"payment-terms.email" | translate}}: {{store.vatEmailStore ?
                store.vatEmailStore : "support@deliverymanager.gr"}}
              </div>
            </ion-label>
          </ion-item>

          <ion-item
            lines="none"
            *ngIf="groupApp && store && (groupApp.storeServices || store.vatActivity)"
          >
            <ion-label class="ion-text-wrap">
              <strong>{{"payment-terms.store-services" | translate}}</strong>
              <div>
                <ion-text *ngIf="!store.vatActivity">
                  {{"payment-terms.store_services_description" | translate}}
                </ion-text>
                <ion-text *ngIf="store.vatActivity">
                  {{store.vatActivity | translate}}
                </ion-text>
              </div>
            </ion-label>
          </ion-item>
          <ion-item
            lines="none"
            *ngIf="store.paymentGateways.alpha && store.paymentGateways.alpha.active"
          >
            <ion-label class="ion-text-wrap">
              <strong>{{"payment-terms.payments-security" | translate}}</strong>
              {{store.paymentGateways.alpha.paymentsSecurity[selectedLanguage]}}
            </ion-label>
          </ion-item>
          <ion-item
            lines="none"
            *ngIf="!(store.paymentTerms && store.paymentTerms.terms && store.paymentTerms.terms[selectedLanguage]) &&groupApp &&groupApp.termsOfUse && groupApp.termsOfUse[selectedLanguage]"
            class="item item-text-wrap"
          >
            <ion-label class="ion-text-wrap">
              <strong>{{"payment-terms.terms-of-use" | translate}}</strong>
              <div>
                <ion-text>
                  {{"payment-terms.payment_terms_of_use" | translate}}
                </ion-text>
              </div>
            </ion-label>
          </ion-item>

          <ion-item
            lines="none"
            *ngIf="store.paymentTerms && store.paymentTerms.terms && store.paymentTerms.terms[selectedLanguage]"
          >
            <div
              class="ion-text-wrap"
              style="margin-top: 5px; margin-bottom: 5px"
              [innerHTML]="store.paymentTerms.terms[selectedLanguage]"
            ></div>
          </ion-item>

          <div
            class="ion-text-center"
            *ngIf="store.paymentGateways.viva && store.paymentGateways.viva.active"
          >
            <img
              alt="vivawallet"
              height="44px"
              [class]="darkMode ? 'invert' : ''"
              src="https://data.deliverymanager.gr/bankicons/vivawallet.svg"
            />
          </div>

          <div
            style="width: 100%; text-align: center"
            *ngIf="store.paymentGateways.piraeus && store.paymentGateways.piraeus.active"
          >
            <ion-item lines="none" class="ion-text-center">
              <ion-label><ion-text>Piraeus ePOS</ion-text></ion-label>
            </ion-item>
            <img
              *ngIf="store.paymentGateways.piraeus.cards && store.paymentGateways.piraeus.cards.visa"
              style="padding-right: 4px"
              width="35px"
              alt="visa"
              src="https://data.deliverymanager.gr/bankicons/visa.png"
            />
            <img
              *ngIf="store.paymentGateways.piraeus.cards && store.paymentGateways.piraeus.cards.mastercard"
              width="35px"
              style="padding-right: 4px"
              alt="mastercard"
              src="https://data.deliverymanager.gr/bankicons/mastercard.png"
            />
            <img
              *ngIf="store.paymentGateways.piraeus.cards && store.paymentGateways.piraeus.cards.maestro"
              width="55px"
              style="padding-right: 4px"
              alt="maestro"
              src="https://data.deliverymanager.gr/bankicons/maestro.png"
            />
            <img
              *ngIf="store.paymentGateways.piraeus.cards && store.paymentGateways.piraeus.cards.discover"
              width="55px"
              alt="discover"
              style="padding-right: 4px"
              src="https://data.deliverymanager.gr/bankicons/discover.png"
            />
            <img
              *ngIf="store.paymentGateways.piraeus.cards && store.paymentGateways.piraeus.cards.diners"
              width="48px"
              alt="diners"
              style="padding-right: 4px"
              src="https://data.deliverymanager.gr/bankicons/diners.png"
            />
            <img
              *ngIf="store.paymentGateways.piraeus.cards && store.paymentGateways.piraeus.cards.american"
              width="88px"
              alt="american"
              style="padding-right: 4px"
              src="https://data.deliverymanager.gr/bankicons/american.png"
            />
          </div>

          <div
            style="width: 100%; text-align: center"
            *ngIf="store.paymentGateways.alpha && store.paymentGateways.alpha.active"
          >
            <ion-item lines="none" class="ion-text-center">
              <ion-label><ion-text>Alpha eCommerce</ion-text></ion-label>
            </ion-item>

            <img
              *ngIf="store.paymentGateways.alpha.cards && store.paymentGateways.alpha.cards.visa"
              width="35px"
              style="padding-right: 4px"
              alt="visa"
              src="https://data.deliverymanager.gr/bankicons/visa.png"
            />
            <img
              *ngIf="store.paymentGateways.alpha.cards && store.paymentGateways.alpha.cards.mastercard"
              width="35px"
              style="padding-right: 4px"
              alt="mastercard"
              src="https://data.deliverymanager.gr/bankicons/mastercard.png"
            />
            <img
              *ngIf="store.paymentGateways.alpha.cards && store.paymentGateways.alpha.cards.maestro"
              width="55px"
              style="padding-right: 4px"
              alt="maestro"
              src="https://data.deliverymanager.gr/bankicons/maestro.png"
            />
            <img
              *ngIf="store.paymentGateways.alpha.cards && store.paymentGateways.alpha.cards.discover"
              width="55px"
              style="padding-right: 4px"
              alt="discover"
              src="https://data.deliverymanager.gr/bankicons/discover.png"
            />
            <img
              *ngIf="store.paymentGateways.alpha.cards && store.paymentGateways.alpha.cards.diners"
              width="48px"
              style="padding-right: 4px"
              alt="diners"
              src="https://data.deliverymanager.gr/bankicons/diners.png"
            />
            <img
              *ngIf="store.paymentGateways.alpha.cards && store.paymentGateways.alpha.cards.american"
              width="88px"
              style="padding-right: 4px"
              alt="american"
              src="https://data.deliverymanager.gr/bankicons/american.png"
            />
          </div>

          <div
            style="width: 100%; text-align: center"
            *ngIf="store.paymentGateways.ethniki && store.paymentGateways.ethniki.active"
          >
            <ion-item lines="none" class="ion-text-center">
              <ion-label><ion-text>NBG eCommerce</ion-text></ion-label>
            </ion-item>

            <img
              *ngIf="store.paymentGateways.ethniki.cards && store.paymentGateways.ethniki.cards.visa"
              width="35px"
              style="padding-right: 4px"
              alt="visa"
              src="https://data.deliverymanager.gr/bankicons/visa.png"
            />
            <img
              *ngIf="store.paymentGateways.ethniki.cards && store.paymentGateways.ethniki.cards.mastercard"
              width="35px"
              style="padding-right: 4px"
              alt="mastercard"
              src="https://data.deliverymanager.gr/bankicons/mastercard.png"
            />
            <img
              *ngIf="store.paymentGateways.ethniki.cards && store.paymentGateways.ethniki.cards.maestro"
              style="padding-right: 4px"
              width="55px"
              alt="maestro"
              src="https://data.deliverymanager.gr/bankicons/maestro.png"
            />
            <img
              *ngIf="store.paymentGateways.ethniki.cards && store.paymentGateways.ethniki.cards.discover"
              width="55px"
              style="padding-right: 4px"
              alt="discover"
              src="https://data.deliverymanager.gr/bankicons/discover.png"
            />
            <img
              *ngIf="store.paymentGateways.ethniki.cards && store.paymentGateways.ethniki.cards.diners"
              width="48px"
              style="padding-right: 4px"
              alt="diners"
              src="https://data.deliverymanager.gr/bankicons/diners.png"
            />
            <img
              *ngIf="store.paymentGateways.ethniki.cards && store.paymentGateways.ethniki.cards.american"
              width="88px"
              style="padding-right: 4px"
              alt="american"
              src="https://data.deliverymanager.gr/bankicons/american.png"
            />
          </div>

          <div
            style="width: 100%; text-align: center"
            *ngIf="store.paymentGateways.nbg_simplify && store.paymentGateways.nbg_simplify.active"
          >
            <ion-item lines="none" class="ion-text-center">
              <ion-label><ion-text>NBG eCommerce</ion-text></ion-label>
            </ion-item>

            <img
              *ngIf="store.paymentGateways.nbg_simplify.cards && store.paymentGateways.nbg_simplify.cards.visa"
              width="35px"
              style="padding-right: 4px"
              alt="visa"
              src="https://data.deliverymanager.gr/bankicons/visa.png"
            />
            <img
              *ngIf="store.paymentGateways.nbg_simplify.cards && store.paymentGateways.nbg_simplify.cards.mastercard"
              width="35px"
              style="padding-right: 4px"
              alt="mastercard"
              src="https://data.deliverymanager.gr/bankicons/mastercard.png"
            />
            <img
              *ngIf="store.paymentGateways.nbg_simplify.cards && store.paymentGateways.nbg_simplify.cards.maestro"
              style="padding-right: 4px"
              width="55px"
              alt="maestro"
              src="https://data.deliverymanager.gr/bankicons/maestro.png"
            />
            <img
              *ngIf="store.paymentGateways.nbg_simplify.cards && store.paymentGateways.nbg_simplify.cards.discover"
              width="55px"
              style="padding-right: 4px"
              alt="discover"
              src="https://data.deliverymanager.gr/bankicons/discover.png"
            />
            <img
              *ngIf="store.paymentGateways.nbg_simplify.cards && store.paymentGateways.nbg_simplify.cards.diners"
              width="48px"
              style="padding-right: 4px"
              alt="diners"
              src="https://data.deliverymanager.gr/bankicons/diners.png"
            />
            <img
              *ngIf="store.paymentGateways.nbg_simplify.cards && store.paymentGateways.nbg_simplify.cards.american"
              width="88px"
              style="padding-right: 4px"
              alt="american"
              src="https://data.deliverymanager.gr/bankicons/american.png"
            />
          </div>

          <div
            style="width: 100%; text-align: center"
            *ngIf="store.paymentGateways.mypos && store.paymentGateways.mypos.active"
          >
            <ion-item lines="none" class="ion-text-center">
              <ion-label><ion-text> MyPOS</ion-text></ion-label>
            </ion-item>

            <img
              *ngIf="store.paymentGateways.mypos.cards && store.paymentGateways.mypos.cards.visa"
              width="35px"
              style="padding-right: 4px"
              alt="visa"
              src="https://data.deliverymanager.gr/bankicons/visa.png"
            />
            <img
              *ngIf="store.paymentGateways.mypos.cards && store.paymentGateways.mypos.cards.mastercard"
              width="35px"
              style="padding-right: 4px"
              alt="mastercard"
              src="https://data.deliverymanager.gr/bankicons/mastercard.png"
            />
            <img
              *ngIf="store.paymentGateways.mypos.cards && store.paymentGateways.mypos.cards.maestro"
              width="55px"
              style="padding-right: 4px"
              alt="maestro"
              src="https://data.deliverymanager.gr/bankicons/maestro.png"
            />
            <img
              *ngIf="store.paymentGateways.mypos.cards && store.paymentGateways.mypos.cards.discover"
              width="55px"
              style="padding-right: 4px"
              alt="discover"
              src="https://data.deliverymanager.gr/bankicons/discover.png"
            />
            <img
              *ngIf="store.paymentGateways.mypos.cards && store.paymentGateways.mypos.cards.diners"
              width="48px"
              style="padding-right: 4px"
              alt="diners"
              src="https://data.deliverymanager.gr/bankicons/diners.png"
            />
            <img
              *ngIf="store.paymentGateways.mypos.cards && store.paymentGateways.mypos.cards.american"
              width="88px"
              style="padding-right: 4px"
              alt="american"
              src="https://data.deliverymanager.gr/bankicons/american.png"
            />
          </div>

          <div
            style="width: 100%; text-align: center"
            *ngIf="store.paymentGateways && store.paymentGateways.eurobank && store.paymentGateways.eurobank.active"
          >
            <ion-item lines="none" class="ion-text-center">
              <ion-label><ion-text>Worldline eCommerce</ion-text></ion-label>
            </ion-item>

            <img
              *ngIf="store.paymentGateways.eurobank.cards && store.paymentGateways.eurobank.cards.visa"
              width="35px"
              style="padding-right: 4px"
              alt="visa"
              src="https://data.deliverymanager.gr/bankicons/visa.png"
            />
            <img
              *ngIf="store.paymentGateways.eurobank.cards && store.paymentGateways.eurobank.cards.mastercard"
              width="35px"
              style="padding-right: 4px"
              alt="mastercard"
              src="https://data.deliverymanager.gr/bankicons/mastercard.png"
            />
            <img
              *ngIf="store.paymentGateways.eurobank.cards && store.paymentGateways.eurobank.cards.maestro"
              width="55px"
              style="padding-right: 4px"
              alt="maestro"
              src="https://data.deliverymanager.gr/bankicons/maestro.png"
            />
            <img
              *ngIf="store.paymentGateways.eurobank.cards && store.paymentGateways.eurobank.cards.discover"
              width="55px"
              style="padding-right: 4px"
              alt="discover"
              src="https://data.deliverymanager.gr/bankicons/discover.png"
            />
            <img
              *ngIf="store.paymentGateways.eurobank.cards && store.paymentGateways.eurobank.cards.diners"
              width="48px"
              style="padding-right: 4px"
              alt="diners"
              src="https://data.deliverymanager.gr/bankicons/diners.png"
            />
            <img
              *ngIf="store.paymentGateways.eurobank.cards && store.paymentGateways.eurobank.cards.american"
              width="88px"
              style="padding-right: 4px"
              alt="american"
              src="https://data.deliverymanager.gr/bankicons/american.png"
            />
          </div>
        </div>
      </ion-list>
    </div>
  </ion-grid>
  <div
    *ngIf="groupApp &&
      groupApp.design_settings &&
      groupApp.design_settings.menuFooterTabs && windowInfo && windowInfo.width<900"
    style="margin-top: 65px"
  ></div>
</ion-content>

<app-menu-footer-tabs></app-menu-footer-tabs>
