import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import {
  NgxFileDropEntry,
  FileSystemFileEntry,
  FileSystemDirectoryEntry,
} from "ngx-file-drop";
import { catchError } from 'rxjs';
import { DataStorageService } from '../services/data-storage/data-storage.service';
import { HttpBackend, HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { v4 as uuidv4 } from 'uuid';


@Component({
  selector: 'app-upload-image-file-drop',
  templateUrl: './upload-image-file-drop.component.html',
  styleUrls: ['./upload-image-file-drop.component.scss'],
})
export class UploadImageFileDropComponent implements OnInit {
  files: NgxFileDropEntry[];
  loading: boolean;
  uploadProgressValue: number;
  interval: any;
  ImageCacheTimestamp: number;
  imageFile: File;

  constructor(
    private translateService: TranslateService,
    private dataStorageService: DataStorageService,
    private alertController: AlertController,
    private http: HttpClient,
    private handler: HttpBackend,
  ) {
    this.http = new HttpClient(handler);
   }

  ngOnInit() {}

  dropped(files: NgxFileDropEntry[]) {
    console.log('dropped');
    this.files = files;
    // if (files.length > 1) {
    //   this.presentErrorAlert(
    //     this.translate.instant(
    //       'foodCategories.food-categories-add-edit-modal.only-one-file-on-every-category-is-allowed'
    //     )
    //   );
    //   return;
    // }
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          // Here you can access the real file
          let filetype: any;
          console.log(droppedFile.relativePath, file);
          this.imageFile = file;
          if (file.type === 'image/png') {
            filetype = 'png';
          } else if (file.type === 'image/jpeg') {
            filetype = 'jpg';
          } else {
            this.presentErrorAlert(
              this.translateService.instant(
                'options.upload-image.only-image-files-is-allowed'
              )
            );
            return;
          }
          const fileName = uuidv4() + '.' + filetype;

          const directory = 'images' + '/' + 'options' + '/' + fileName;
          this.loading = true;
          // this.dataStorageService
          //   .handleImageUpload(fileName, directory)
          //   .pipe(
          //     catchError((err) => {
          //       //TO_DO ERROR MESSAGE
          //       console.log('err', err);

          //       return null;
          //     })
          //   )
          //   .subscribe((res: any) => {
          //     if (res.success) {
          //       console.log(file.type);
          //       this.uploadImageData(file, res.data, file.type, fileName);
          //     } else if (!res || !res.success) {
          //       console.log('errrrrr');
          //       if (res.comment_id) {
          //         this.presentErrorAlert(
          //           this.translateService.instant(
          //             'menu.' + res.comment_id.toString()
          //           )
          //         );
          //       }
          //       this.loading = false;
          //     }
          //   });
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }

  public fileOver(event) {
    console.log(event);
  }

  public fileLeave(event) {
    console.log(event);
  }

  async presentErrorAlert(errorMsg) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: this.translateService.instant('error'),

      message: errorMsg,
      buttons: ['OK'],
    });

    await alert.present();
  }

  async uploadImageData(imgBlob, item, fileType, fileName) {
    console.log('index');
    console.log(fileType);
    this.http
      .put(item.signedUrl, imgBlob, {
        reportProgress: true,
        observe: 'events',
        headers: new HttpHeaders({
          'Content-Type': fileType,
        }),
      })
      .subscribe(
        (events) => {
          if (events.type === HttpEventType.UploadProgress) {
            this.uploadProgressValue =
              parseFloat(
                Math.floor((events.loaded / events.total) * 100).toString()
              ) / 100;
          } else if (events.type === HttpEventType.Response) {
            console.log('uploadFiles response', events);
            if (events.status === 200) {
              console.log('complete');
              this.loading = false;
              this.tryToGetImageUrl(fileName);
              console.log('loading', this.uploadProgressValue);
            } else {
              console.log('failed');
              this.loading = false;
            }
          }
        },
        (error) => {
          console.log('error uploading', error);

          this.loading = false;
        }
      );
  }

  tryToGetImageUrl(filename) {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
    this.interval = setInterval(() => {
      this.ImageCacheTimestamp = Date.now();
    }, 1000);
  }

  imageLoaded() {
    clearInterval(this.interval);
  }
}
