import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";
import * as _ from "lodash";
import { distinctUntilChanged } from "rxjs/operators";
import { Subscription } from "rxjs";

@Component({
  selector: "app-cart-browser-lg",
  templateUrl: "./cart-browser-lg.component.html",
  styleUrls: ["./cart-browser-lg.component.scss"],
})
export class CartBrowserLgComponent implements OnInit, OnDestroy {
  public currentUser;
  public orderPickup;
  public dineIn;
  public showAddCouponCodeComponent: boolean;

  private stores: any;
  private subscription: Subscription;
  private subscription2: Subscription;
  private subscription3: Subscription;
  constructor(private store: Store<fromApp.AppState>) {}

  ngOnInit() {
    this.subscription = this.store
      .select("auth")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (state && !_.isEqual(this.currentUser, state.user)) {
          this.currentUser = _.cloneDeep(state.user);
        }
      });

    this.subscription2 = this.store
      .select("deliveryMethodAddress")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (state && !_.isEqual(this.orderPickup, state.orderPickup)) {
          this.orderPickup = _.cloneDeep(state.orderPickup);
        }

        if (state && !_.isEqual(this.dineIn, state.dineIn)) {
          this.dineIn = _.cloneDeep(state.dineIn);
        }
      });

    this.subscription3 = this.store
      .select("stores")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (state && state.stores && !_.isEqual(this.stores, state.stores)) {
          this.stores = _.cloneDeep(state.stores);
          this.showAddCouponCodeComponent = _.find(this.stores, {
            couponFieldActive: true,
          });
        }
      });
  }

  ngOnDestroy() {
    console.log("cart browser lg component destroyed");
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscription2) {
      this.subscription2.unsubscribe();
    }
    if (this.subscription3) {
      this.subscription3.unsubscribe();
    }
  }
}
