import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { auditTime, distinctUntilChanged } from "rxjs/operators";
import * as fromApp from "../../store/app.reducer";

import * as selectors from "../../store/app.selectors";
import * as _ from "lodash";
import * as CartActions from "../../cart/store/cart.actions";
import { CalculateFinalPriceService } from "../calculateFinalPrice/calculate-final-price.service";

@Injectable({
  providedIn: "root",
})
export class ValidatingCartService {
  private store_info: any;
  private orderPickup: any;
  private cartProducts: any;
  private currentStoreId: string;
  private validatingCartLoading: boolean;
  constructor(
    private store: Store<fromApp.AppState>,
    private calcFinalPriceService: CalculateFinalPriceService
  ) {}

  validatingCartSubscriptions() {
    this.store
      .select("cart")
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (state && !_.isEqual(this.cartProducts, state.products)) {
          this.cartProducts = _.cloneDeep(state.products);
        }
        if (
          state &&
          !_.isEqual(this.validatingCartLoading, state.validatingCartLoading)
        ) {
          this.validatingCartLoading = _.cloneDeep(state.validatingCartLoading);
        }
      });

    this.store
      .select("deliveryMethodAddress")
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (state && !_.isEqual(this.orderPickup, state.orderPickup)) {
          //console.log("order pickupChanged");
          this.orderPickup = _.cloneDeep(state.orderPickup);
          this.validateCartDebounce();
        }
      });

    this.store
      .select(selectors.getStoreInfo)
      .pipe(distinctUntilChanged())
      .subscribe((store_info) => {
        if (store_info && !_.isEqual(this.store_info, store_info)) {
          console.log("store info changed");
          this.store_info = _.cloneDeep(store_info);
          this.validateCartDebounce();
        }
      });

    this.store
      .select("currentStore")
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (
          state &&
          state.currentStoreId &&
          !_.isEqual(this.currentStoreId, state.currentStoreId)
        ) {
          this.currentStoreId = _.cloneDeep(state.currentStoreId);
          this.validateCartDebounce();
        }
      });
  }

  validatingCart(cartProducts, store_info, orderPickup) {
    console.log(
      "validatingCartRun",
      store_info.store_name,
      store_info.store_id,
      cartProducts
    );

    if (
      cartProducts &&
      cartProducts.length > 0 &&
      !_.find(cartProducts, {
        store_id: store_info.store_id,
      })
    ) {
      //console.log("inside if clear state");
      //clear cart from state

      if (navigator.cookieEnabled) {
        window.localStorage.removeItem("cart");
      }
      //console.log("clear cart state");
      this.store.dispatch(new CartActions.ClearCart());
      this.store.dispatch(new CartActions.SetValidatingCartLoading(false));
      return;
    }

 
    this.store.dispatch(new CartActions.SetValidatingCartLoading(false));
  }

  validateCartDebounce = _.debounce(() => {
    console.log("debounce function called");
    if (!this.validatingCartLoading) {
      this.store.dispatch(new CartActions.SetValidatingCartLoading(true));
      console.log(
        "doubounce function attributes",
        this.store_info,
        this.cartProducts,
        this.orderPickup
      );
      if (
        this.store_info &&
        this.cartProducts

        //this.currentStoreId
      ) {
        console.log("inside if and calling validating cart from state");
        //console.log("validating attr", this.store_info, this.cartProducts);
        this.validatingCart(
          this.cartProducts,
          this.store_info,
          this.orderPickup
        );
      } else {
      }
    } else {
      this.store.dispatch(new CartActions.SetValidatingCartLoading(false));
      this.validateCartDebounce();
    }
  }, 200);
}
