<div *ngIf="option && !catalogMode">
  <div *ngFor="let choice of option.choices; let index = index">
    <ion-item
      *ngIf="
        choice.qty > 0 && choice.totalPrice && choice.totalPrice !== '0.00'
      "
      lines="none"
    >
      <ion-label
        *ngIf="!option.enviromental_fee_translations"
        class="ion-text-wrap ion-no-margin"
        ><strong style="margin-right: 30px">{{
          "cartCustomOptions.plastic_wrapper_tax_message" | translate
        }}</strong>

        <p *ngIf="cartProductsWithWrapperTax">
          {{
            "cartCustomOptions.it-was-calculated-plastic-wrapper-tax-from"
              | translate
          }}
          {{ cartProductsWithWrapperTax }}
          {{
            cartProductsWithWrapperTax === 1
              ? ("cartCustomOptions.product" | translate)
              : ("cartCustomOptions.products" | translate)
          }}
        </p>
        <div
          style="
            position: absolute;
            top: 2px;
            right: 3px;
            font-size: medium;
            font-weight: 500;
          "
        >
          {{ choice.totalPrice + "€" }}
        </div>
      </ion-label>
      <ion-label
        *ngIf="option.enviromental_fee_translations"
        class="ion-text-wrap ion-no-margin"
        ><strong style="margin-right: 30px">{{
          "cartCustomOptions.enviromental_fee_message" | translate
        }}</strong>

        <p *ngIf="cartProductsWithWrapperTax">
          {{
            "cartCustomOptions.it-was-calculated-enviromental-fee-from"
              | translate
          }}
          {{ cartProductsWithWrapperTax }}
          {{
            cartProductsWithWrapperTax === 1
              ? ("cartCustomOptions.product" | translate)
              : ("cartCustomOptions.products" | translate)
          }}
          {{ "cartCustomOptions.plus_vat" | translate }}
        </p>
        <div
          style="
            position: absolute;
            top: 2px;
            right: 3px;
            font-size: medium;
            font-weight: 500;
          "
        >
          {{ choice.totalPrice + "€" }}
        </div>
      </ion-label>
    </ion-item>
  </div>
</div>
