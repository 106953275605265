<ion-content scrollEvents="false" scrollX="false" scrollY="false">
  <ion-item lines="none" style="margin-top: 15px">
    <img
      alt="logo"
      [class]="
        windowDimensions &&
        windowDimensions.width &&
        windowDimensions.width > 450
          ? 'imgLogoBrowser'
          : 'imgLogoMobile'
      "
      alt="{{ groupApp?.group }}"
      src="{{ rootDir }}{{ groupApp?.group }}/logos/{{ selectedLogoFileName }}"
    />
  </ion-item>
  <ion-item class="ion-text-center" lines="none">
    <ion-label class="ion-text-wrap">
      Παρακαλώ εισάγετε τον κωδικό που λάβατε με SMS για να συνδεθείτε.
    </ion-label>
  </ion-item>
  <ion-list style="padding: 0px 16px 10px 16px">
    <ion-row
      style="width: 100%"
      *ngIf="!currentUser && newCustomer && showOtpComponent"
      class="input-item"
    >
      <!-- <ion-label position="fixed">
        {{ "popups.magic-login-confirmation.password" | translate }}</ion-label> -->

      <ion-col
        class="ion-text-center"
        style="padding-left: 0px; padding-right: 0px"
      >
        <ng-otp-input
          #ngOtpInput
          #ngOtpInputRef
          (onInputChange)="onChange()"
          [config]="config"
          (keydown)="handleEnterButton($event)"
        >
        </ng-otp-input>
      </ion-col>
      <ion-col size="12" class="ion-text-center">
        <ion-button
          *ngIf="!currentUser && groupApp"
          color="primary"
          [disabled]="loading || !codeFull"
          (click)="onClickOk()"
        >
          <ion-text *ngIf="!loading">OK</ion-text>
          <ion-spinner *ngIf="loading"></ion-spinner>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-list>
  <ion-list class="ion-no-padding ion-no-margin">
    <ion-row
      [class]="
        windowDimensions &&
        windowDimensions.height &&
        windowDimensions.height >= 345
          ? ''
          : 'ion-justify-content-center ion-align-items-center width-100'
      "
    >
      <ion-col size="12" class="ion-text-center ion-no-padding">
        <ion-button
          fill="solid"
          lines="none"
          color="primary"
          size="small"
          detail="false"
          *ngIf="!currentUser"
          class="ion-text-center"
          (click)="onResend()"
        >
          {{ "popups.magic-login-confirmation.resend-sms" | translate }}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-list>
</ion-content>
