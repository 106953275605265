import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../store/app.reducer";
import * as selectors from "../../../store/app.selectors";
import * as _ from "lodash";
import {
  auditTime,
  distinct,
  distinctUntilChanged,
  first,
} from "rxjs/operators";
import {
  AlertController,
  IonContent,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";

import { Subscription } from "rxjs";

import { Router } from "@angular/router";
import { NewAddressModalComponent } from "src/app/address/new-address-modal/new-address-modal.component";
import * as DeliveryMethodAddressActions from "../../../store/deliveryMethodAddress/deliveryMethodAddress.actions";
import * as CurrentStoreActions from "../../../store/current-store/current-store.actions";
import * as AuthActions from "../../../auth/store/auth.actions";
import { TranslateService } from "@ngx-translate/core";
import { AnalyticsServiceService } from "src/app/services/analitycsService/analytics-service.service";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import { SelectedAddressService } from "src/app/services/selectedAddress/selected-address.service";
import { CheckLocationDistanceService } from "src/app/services/checkLocationDistance/check-location-distance.service";
import { AnimationOptions } from "ngx-lottie";

@Component({
  selector: "app-order-now-settings-popup",
  templateUrl: "./order-now-settings-popup.component.html",
  styleUrls: ["./order-now-settings-popup.component.scss"],
})
export class OrderNowSettingsPopupComponent implements OnInit, OnDestroy {
  @ViewChild(IonContent) content: IonContent;
  @Input() mode: string;
  public stores: any;
  public currentUser: any;
  public deleteAddressMode: boolean = false;
  public selectedAddressLoading: boolean = false;
  public onSaveDisable: boolean = false;
  public mo_token: any;
  public selectedLangugage: any;
  public groupApp: any;
  public storeInfo;
  public searchTerm;
  public filteredStores;
  public gpsDistanceLoading = false;
  public searchStoreLoading = false;
  public gpsLoadingAnimation;
  public showGpsLoadingAnimation: boolean = false;
  public migration_customer_id: string;

  private subscriptions: Subscription[] = [];
  private currentStoreId: string;
  private selectedAddress: any;
  private addNewAddressModal: any;
  private navigationOnDeliveryMethodChange: boolean;
  private unsubscribeBackEvent: Subscription;

  options: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/gps-stores-loading-animation.json",
  };
  constructor(
    private store: Store<fromApp.AppState>,
    private modalCtrl: ModalController,
    private router: Router,
    private translateService: TranslateService,
    private alertController: AlertController,
    private platform: Platform,
    private analyticsService: AnalyticsServiceService,
    private toastController: ToastController,
    private dataStorageService: DataStorageService,
    private checkLocationDistanceService: CheckLocationDistanceService,
    private selectedAddressService: SelectedAddressService
  ) {}

  removeGreekAccents(input) {
    const accentsMap = {
      ά: "α",
      έ: "ε",
      ή: "η",
      ί: "ι",
      ύ: "υ",
      ό: "ο",
      ώ: "ω",
      ϊ: "ι",
      ϋ: "υ",
    };

    return input.replace(/[άέήίύόώϊϋ]/g, (match) => accentsMap[match]);
  }

  onSearchType() {
    this.searchStoreLoading = true;

    this.searchStoreDebounced();
  }

  private searchStoreDebounced = _.debounce(() => {
    this.searchStore();
  }, 1200);

  searchStore() {
    if (this.searchTerm) {
      this.filteredStores = _.filter(this.stores, (store) => {
        if (
          store &&
          ((store.store_name_mobileorder &&
            store.store_name_mobileorder[this.selectedLangugage] &&
            this.removeGreekAccents(
              store.store_name_mobileorder[this.selectedLangugage]
                .toString()
                .toLowerCase()
            ).indexOf(this.removeGreekAccents(this.searchTerm.toLowerCase())) >
              -1) ||
            (store.store_name_langs &&
              store.store_name_langs[this.selectedLangugage] &&
              this.removeGreekAccents(
                store.store_name_langs[this.selectedLangugage]
                  .toString()
                  .toLowerCase()
              ).indexOf(
                this.removeGreekAccents(this.searchTerm.toLowerCase())
              ) > -1) ||
            (store.store_name_langs &&
              store.store_name_langs[this.selectedLangugage] &&
              this.removeGreekAccents(
                store.store_name_langs[this.selectedLangugage]
                  .toString()
                  .toLowerCase()
              ).indexOf(
                this.removeGreekAccents(this.searchTerm.toLowerCase())
              ) > -1) ||
            (store.store_name_langs &&
              store.store_name_langs.el &&
              store.store_name_langs.el.toString().indexOf(this.searchTerm) >
                -1) ||
            (store.store_name &&
              this.removeGreekAccents(
                store.store_name.toString().toLowerCase()
              ).indexOf(
                this.removeGreekAccents(this.searchTerm.toLowerCase())
              ) > -1) ||
            (store.address &&
              this.removeGreekAccents(
                store.address.toString().toLowerCase()
              ).indexOf(
                this.removeGreekAccents(this.searchTerm.toLowerCase())
              ) > -1))
        ) {
          return store;
        }
      });
    } else {
      this.filteredStores = _.cloneDeep(this.stores);
    }

    this.searchStoreLoading = false;
  }

  resetSearchStore() {
    this.searchTerm = null;
    this.filteredStores = _.cloneDeep(this.stores);
  }

  trackStores(index, item) {
    return item.store_id;
  }

  animationCreated(ev) {
    this.gpsLoadingAnimation = ev;
  }

  async calculateLocations() {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      message: this.translateService.instant(
        "popups.select-store-popup.do-you-want-to-order-stores-by-location"
      ),
      buttons: [
        {
          text: this.translateService.instant("cancel"),
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {},
        },
        {
          text: this.translateService.instant("yes"),
          handler: async () => {
            if (this.stores && this.stores.length > 1) {
              this.gpsDistanceLoading = true;
              const res =
                await this.checkLocationDistanceService.calculateDistanceFromAllStoresByGPS(
                  this.stores,
                  true
                );
              this.gpsDistanceLoading = false;
            }
          },
        },
      ],
    });
    await alert.present();
  }

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      300,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );
    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((state) => {
          if (state && !_.isEqual(this.currentUser, state.user)) {
            this.currentUser = _.cloneDeep(state.user);

            // this.setHightOfModal();
          }
          if (state && !_.isEqual(this.mo_token, state.mo_token)) {
            this.mo_token = _.cloneDeep(state.mo_token);

            // this.setHightOfModal();
          }

          if (
            state &&
            !_.isEqual(this.migration_customer_id, state.migration_customer_id)
          ) {
            //this is only for filtering addresses if vatBasedCustomerAddressesActive like lampridis (rdc)
            this.store
              .select("groupApp")
              .pipe(first())
              .subscribe((groupAppState) => {
                if (
                  groupAppState &&
                  groupAppState.groupApp &&
                  groupAppState.groupApp.vatBasedCustomerAddressesActive
                ) {
                  this.migration_customer_id = _.cloneDeep(
                    state.migration_customer_id
                  );
                } else {
                  this.migration_customer_id = null;
                }
              })
              .unsubscribe();
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())

        .subscribe((storeInfo) => {
          if (storeInfo && !_.isEqual(storeInfo, this.storeInfo)) {
            this.storeInfo = _.cloneDeep(storeInfo);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe(async (state) => {
          if (state && state.stores && !_.isEqual(state.stores, this.stores)) {
            if (state.stores && state.stores.length > 1 && this.stores) {
              let showLottieGpsLoading = false;

              _.each(_.cloneDeep(state.stores), (stateStore) => {
                const previousStoreVar = _.find(this.stores, {
                  store_id: stateStore.store_id,
                });

                if (
                  stateStore &&
                  previousStoreVar &&
                  stateStore.estimatedDistanceFromClientGps &&
                  (!previousStoreVar.estimatedDistanceFromClientGps ||
                    previousStoreVar.estimatedDistanceFromClientGps !==
                      stateStore.estimatedDistanceFromClientGps)
                ) {
                  showLottieGpsLoading = true;
                }
              });

              if (showLottieGpsLoading) {
                this.showGpsReorderAnimationLoading();
              }
            }
            this.stores = _.cloneDeep(state.stores);
            this.filteredStores = _.cloneDeep(this.stores);
            if (
              state.stores &&
              state.stores.length > 1 &&
              this.mode === "take-away"
            ) {
              this.gpsDistanceLoading = true;
              const res =
                await this.checkLocationDistanceService.calculateDistanceFromAllStoresByGPS(
                  state.stores,
                  false
                );
              this.gpsDistanceLoading = false;
            }
            console.log("stores", this.stores);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("currentStore")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((state) => {
          if (
            state &&
            state.currentStoreId &&
            !_.isEqual(this.currentStoreId, state.currentStoreId)
          ) {
            this.currentStoreId = _.cloneDeep(state.currentStoreId);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.selectedLangugage &&
            !_.isEqual(this.selectedLangugage, state.selectedLangugage)
          ) {
            this.selectedLangugage = _.cloneDeep(state.selectedLangugage);
          }

          if (
            state &&
            state.groupApp &&
            !_.isEqual(this.groupApp, state.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("deliveryMethodAddress")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(
              this.selectedAddressLoading,
              state.selectedAddressLoading
            )
          ) {
            this.selectedAddressLoading = _.cloneDeep(
              state.selectedAddressLoading
            );
            if (this.selectedAddressLoading == false) {
              if (
                state &&
                !_.isEqual(this.selectedAddress, state.selectedAddress)
              ) {
                this.selectedAddress = _.cloneDeep(state.selectedAddress);
                this.modalCtrl.dismiss();
              }
            }
          }
          if (
            state &&
            !_.isEqual(
              state.navigationOnDeliveryMethodChange,
              this.navigationOnDeliveryMethodChange
            )
          ) {
            this.navigationOnDeliveryMethodChange = _.cloneDeep(
              state.navigationOnDeliveryMethodChange
            );
          }
        })
    );
  }

  enableDeleteMode() {
    this.deleteAddressMode = !this.deleteAddressMode;
    if (this.deleteAddressMode) {
      this.presentErrorToast(
        "popups.order-now-popup.tap-to-address-to-delete",
        "danger"
      );
    }
  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: this.translateService.instant(message),
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }

  async presentConfirmDelete(address) {
    const alert = await this.alertController.create({
      header: this.translateService.instant("delete"),
      subHeader:
        address && address.formatted_address ? address.formatted_address : "",
      message: this.translateService.instant(
        "popups.order-now-popup.do-you-want-to-delete-this-address"
      ),
      buttons: [
        {
          text: this.translateService.instant("cancel"),
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("cancel restarting app");
          },
        },
        {
          text: this.translateService.instant("yes"),
          handler: () => {
            console.log("confirm deleting address");
            this.deleteAddress(address);
          },
        },
      ],
    });
    alert.present();
  }

  deleteAddress(address) {
    if (
      this.currentUser &&
      this.currentUser.addresses &&
      !_.isEmpty(this.currentUser.addresses)
    ) {
      let addresses = _.cloneDeep(this.currentUser.addresses);
      console.log("user addresses", addresses);
      const deletedIndex = _.findIndex(addresses, {
        address_id: address.address_id,
      });
      console.log("delete index", deletedIndex);
      if (deletedIndex !== -1) {
        this.store
          .select("deliveryMethodAddress")
          .pipe(first())
          .subscribe((state) => {
            if (
              state &&
              state.selectedAddress &&
              state.selectedAddress.address_id
            ) {
              if (state.selectedAddress.address_id === address.address_id) {
                console.log("deselect selected address called");
                this.selectedAddressService.deselectAddress();
                this.deleteAddressRequest(deletedIndex, addresses);
              } else {
                this.deleteAddressRequest(deletedIndex, addresses);
              }
            } else {
              this.deleteAddressRequest(deletedIndex, addresses);
            }
          })
          .unsubscribe();
        console.log(
          "deselect selected address called",
          address,
          this.selectedAddress
        );
      } else {
        console.log("error on find address in user");
      }
    } else {
      console.log("error on find  user");
    }
  }

  deleteAddressRequest(deletedIndex, addresses) {
    addresses.splice(deletedIndex, 1);
    let newUser = _.cloneDeep(this.currentUser);
    newUser.addresses = _.cloneDeep(addresses);
    this.onSaveDisable = true;
    this.dataStorageService
      .updateCustomerData({ updatedData: newUser }, this.mo_token)

      .subscribe({
        next: async (res: any) => {
          if (res && !res.success) {
            this.onSaveDisable = false;
            const alert = await this.alertController.create({
              header: this.translateService.instant("alert"),
              message:
                res && res.comment_id
                  ? this.translateService.instant(res.comment_id)
                  : this.translateService.instant(
                      "errorMessages.general_error"
                    ),

              buttons: ["OK"],
            });
            await alert.present();
          } else if (res && res.success) {
            this.store.dispatch(new AuthActions.SetUser(_.cloneDeep(newUser)));
            this.onSaveDisable = false;
            this.deleteAddressMode = false;
          } else {
            this.onSaveDisable = false;
            const alert = await this.alertController.create({
              header: this.translateService.instant("alert"),
              message: this.translateService.instant(
                "errorMessages.problem_reaching_server"
              ),

              buttons: ["OK"],
            });
            await alert.present();
          }
        },
        error: async (err) => {
          const alert = await this.alertController.create({
            header: this.translateService.instant("alert"),
            message: this.translateService.instant(
              "errorMessages.problem_reaching_server"
            ),

            buttons: ["OK"],
          });
          await alert.present();
          this.onSaveDisable = false;
          return err;
        },
      });
  }

  async addNewAddress() {
    // this.modalCtrl.dismiss();
    this.addNewAddressModal = await this.modalCtrl.create({
      component: NewAddressModalComponent,
      cssClass: "my-custom-class",
      backdropDismiss: false,
    });
    await this.addNewAddressModal.present();

    this.addNewAddressModal.onDidDismiss().then((userRes) => {
      this.addNewAddressModal = null;
      console.log("on dismiss", userRes);
      if (userRes && userRes.data && userRes.data.data) {
        let updatedUser = userRes.data.data;
        console.log("on updatedUser", updatedUser);
        this.store.dispatch(new AuthActions.SetUser(_.cloneDeep(updatedUser)));
      }
    });
  }

  selectDeliveryMethod(mode) {
    if (mode === "delivery") {
    } else if (mode === "take-away") {
      console.log("take away selected");
      this.store.dispatch(
        new DeliveryMethodAddressActions.setSelectedAddress(null)
      );
      this.store.dispatch(
        new DeliveryMethodAddressActions.setCheckSelectedAddress(null)
      );
      this.store.dispatch(
        new DeliveryMethodAddressActions.setOrderPickup(true)
      );
      this.store.dispatch(new DeliveryMethodAddressActions.setDineIn(false));

      this.store.dispatch(
        new DeliveryMethodAddressActions.setSelectedDineInTable(
          _.cloneDeep(null)
        )
      );
      this.store.dispatch(
        new DeliveryMethodAddressActions.setValidDeliveryAreas(null)
      );

      this.analyticsService.dmAnalyticsMessage(
        "takeaway_method",
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      );

      if (
        (this.router.url === "/homepage" ||
          this.router.url === "/ratings" ||
          this.router.url === "/orders" ||
          this.router.url === "/points") &&
        this.navigationOnDeliveryMethodChange
      ) {
        this.router.navigateByUrl("/catalog", {
          replaceUrl: true,
        });
      }
      if (navigator.cookieEnabled) {
        let deliveryMethodAddressObj: any = localStorage.getItem(
          "deliveryMethodAddress"
        );
        if (deliveryMethodAddressObj) {
          deliveryMethodAddressObj = JSON.parse(deliveryMethodAddressObj);
          if (deliveryMethodAddressObj) {
            deliveryMethodAddressObj.orderPickup = true;
            deliveryMethodAddressObj.dineIn = false;
            deliveryMethodAddressObj.selectedAddress = null;
            deliveryMethodAddressObj.selectedDineInTable = null;
          } else {
            deliveryMethodAddressObj = {
              orderPickup: true,
              dineIn: false,
            };
          }
        } else {
          deliveryMethodAddressObj = {
            orderPickup: true,
            dineIn: false,
          };
        }

        window.localStorage.setItem(
          "deliveryMethodAddress",
          JSON.stringify(deliveryMethodAddressObj)
        );
      }
    } else if (mode === "dine-in") {
      this.analyticsService.dmAnalyticsMessage(
        "dinein_method",
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      );
      this.store.dispatch(
        new DeliveryMethodAddressActions.setSelectedAddress(null)
      );
      this.store.dispatch(
        new DeliveryMethodAddressActions.setSelectedPreorderDate(null)
      );
      this.store.dispatch(
        new DeliveryMethodAddressActions.setOrderPickup(false)
      );
      this.store.dispatch(new DeliveryMethodAddressActions.setDineIn(true));
      if (navigator.cookieEnabled) {
        let deliveryMethodAddressObj: any = localStorage.getItem(
          "deliveryMethodAddress"
        );
        if (deliveryMethodAddressObj) {
          deliveryMethodAddressObj = JSON.parse(deliveryMethodAddressObj);
          if (deliveryMethodAddressObj) {
            deliveryMethodAddressObj.orderPickup = false;
            deliveryMethodAddressObj.dineIn = true;
            deliveryMethodAddressObj.selectedPreorderDate = null;
          } else {
            deliveryMethodAddressObj = {
              orderPickup: false,
              dineIn: true,
            };
          }
        } else {
          deliveryMethodAddressObj = {
            orderPickup: false,
            dineIn: true,
          };
        }

        window.localStorage.setItem(
          "deliveryMethodAddress",
          JSON.stringify(deliveryMethodAddressObj)
        );
      }
    }
  }

  showGpsReorderAnimationLoading() {
    this.showGpsLoadingAnimation = true;
    if (this.content) {
      this.content.scrollToTop();
    }
    setTimeout(() => {
      this.showGpsLoadingAnimation = false;
    }, 1700);
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  selectAddressBtn(address) {
    console.log("select Addres button", this.mode);
    this.store.dispatch(
      new DeliveryMethodAddressActions.setOpenSelectStorePopupDelivery(true)
    );

    this.store.dispatch(
      new DeliveryMethodAddressActions.setOpenNotValidAddressModal(true)
    );

    this.store.dispatch(
      new DeliveryMethodAddressActions.setCheckSelectedAddress(address)
    );

    this.selectDeliveryMethod(this.mode);
  }

  selectStore(storeID) {
    this.store
      .select("cart")
      .pipe(first())
      .subscribe(async (state) => {
        if (
          state &&
          state.products &&
          state.products.length > 0 &&
          !_.isEqual(this.currentStoreId, storeID)
        ) {
          const alert = await this.alertController.create({
            cssClass: "my-custom-class",
            header: this.translateService.instant("alert"),
            message: this.translateService.instant(
              "catalog.if-you-change-store-your-cart-products-will-be-deleted-do-you-want-to-continue"
            ),
            buttons: [
              {
                text: this.translateService.instant("cancel"),
                role: "cancel",
                cssClass: "secondary",
                handler: (blah) => {},
              },
              {
                text: this.translateService.instant("yes"),
                handler: () => {
                  if (storeID) {
                    console.log("set store id order now settings modal");
                    this.store.dispatch(
                      new CurrentStoreActions.SetCurrentStoreId(storeID)
                    );
                  }
                  this.modalCtrl.dismiss();
                  // this.router.navigate(["catalog"]);
                  this.selectDeliveryMethod(this.mode);
                },
              },
            ],
          });
          await alert.present();
        } else if (
          (state &&
            (!state.products ||
              (state.products && state.products.length === 0))) ||
          _.isEqual(this.currentStoreId, storeID)
        ) {
          if (storeID) {
            console.log("set store id order now settings modal");
            this.store.dispatch(
              new CurrentStoreActions.SetCurrentStoreId(storeID)
            );
          }
          this.modalCtrl.dismiss();
          //this.router.navigate(["catalog"]);
          this.selectDeliveryMethod(this.mode);
        }
      })
      .unsubscribe();
  }

  ngOnDestroy() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    this.subscriptions = [];
    // if (this.addNewAddressModal) {
    //   this.addNewAddressModal.dismiss();
    //   this.addNewAddressModal = null;
    // }
  }
}
