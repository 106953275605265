import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../../../store/app.reducer";
import * as _ from "lodash";
import * as CartCustomOptionsSelectionsActions from "../../store/cart-custom-options-selections/cart-custom-options-selections.actions";
import { AlertController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { auditTime, distinctUntilChanged } from "rxjs/operators";
import { Subscription } from "rxjs";

@Component({
  selector: "app-checkbox-type-choices-quantity",
  templateUrl: "./checkbox-type-choices-quantity.component.html",
  styleUrls: ["./checkbox-type-choices-quantity.component.scss"],
})
export class CheckboxTypeChoicesQuantityComponent implements OnInit, OnDestroy {
  @Input() option_timestamp;
  @Input() selectedLang: any;
  public option;
  public CustomSelections;
  private subscriptions: Subscription[] = [];
  public finalPriceLoading: any;
  constructor(
    private store: Store<fromApp.AppState>,
    private alertCtrl: AlertController,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select("cartCustomOptionsSelections")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.cartCustomOptionsSelections &&
            !_.isEqual(state.cartCustomOptionsSelections, this.CustomSelections)
          ) {
            console.log("mpika sto subscription selections");
            this.CustomSelections = _.cloneDeep(
              state.cartCustomOptionsSelections
            );
            // console.log(
            //   "selectioooooooooooooooooooooons",
            //   this.CustomSelections,
            //   this.option_timestamp
            // );
            this.option = _.cloneDeep(
              _.find(this.CustomSelections, {
                timestamp: this.option_timestamp,
              })
            );
            console.log("this option from checkbox", this.option);
            if (this.option) {
              let oneSelected = false;
              this.option.choices.forEach((ch) => {
                if (ch.qty > 0) {
                  ch.selected = true;
                  ch.totalPrice = this.calculateChoicePrice(ch);
                  oneSelected = true;
                }
              });
              if (oneSelected) {
                this.store.dispatch(
                  new CartCustomOptionsSelectionsActions.AddUpdateCartCustomOptionSelections(
                    _.cloneDeep(this.option)
                  )
                );
              }
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("disableFinalPrice")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(state.disableFinalPrice, this.finalPriceLoading)
          ) {
            this.finalPriceLoading = _.cloneDeep(state.disableFinalPrice);
          }
        })
    );
  }

  changeChoiceQty(ev, choice, index) {
    if (ev === "increase") {
      this.customChoiceQuantityIncrease(choice, index);
    } else if (ev === "decrease") {
      this.customChoiceQuantityDecrease(choice, index);
    }
  }

  changeChoiceQtyFromButton(qty, choice, index) {
    choice.qty = qty;

    if (choice.qty == 0) {
      choice.selected = false;
    }
    if (this.option.choices[index].price) {
      choice.totalPrice = this.calculateChoicePrice(choice);
    }
    this.store.dispatch(
      new CartCustomOptionsSelectionsActions.AddUpdateCartCustomOptionSelections(
        _.cloneDeep(this.option)
      )
    );
  }

  customChoiceQuantityDecrease(choice, index) {
    if (
      this.option.choices[index].qty > 0 &&
      (!this.option.choices[index].min ||
        this.option.choices[index].qty > this.option.choices[index].min)
    ) {
      choice.qty--;
      if (choice.qty == 0) {
        choice.selected = false;
      }
      if (this.option.choices[index].price) {
        choice.totalPrice = this.calculateChoicePrice(choice);
      }
      this.store.dispatch(
        new CartCustomOptionsSelectionsActions.AddUpdateCartCustomOptionSelections(
          _.cloneDeep(this.option)
        )
      );
    } else {
      let message = this.translateService.instant(
        "cart.cart-components.cart-custom-options.quantity-options.min-quantity"
      );
      this.presentAlert(message);
    }
  }

  trackTimestamp(index, choice) {
    return choice.timestamp;
  }

  customChoiceQuantityIncrease(choice, index) {
    if (
      !this.option.choices[index].max ||
      this.option.choices[index].qty < this.option.choices[index].max ||
      (this.option.preNameId && this.option.preNameId === "delivery_tip")
    ) {
      choice.qty++;
      this.store.dispatch(
        new CartCustomOptionsSelectionsActions.AddUpdateCartCustomOptionSelections(
          _.cloneDeep(this.option)
        )
      );
      if (this.option.choices[index].price) {
        choice.totalPrice = this.calculateChoicePrice(choice);
      }
      this.store.dispatch(
        new CartCustomOptionsSelectionsActions.AddUpdateCartCustomOptionSelections(
          _.cloneDeep(this.option)
        )
      );
    } else {
      let message = this.translateService.instant(
        "cart.cart-components.cart-custom-options.quantity-options.max-quantity"
      );
      this.presentAlert(message);
    }
  }

  checkbox(choice, ev) {
    console.log(ev.target.checked);
    if (ev && ev.target.checked) {
      choice.qty = choice.min || 1;
    } else if (ev && !ev.target.checked) {
      choice.qty = choice.min || 0;
    }

    this.store.dispatch(
      new CartCustomOptionsSelectionsActions.AddUpdateCartCustomOptionSelections(
        _.cloneDeep(this.option)
      )
    );
  }

  async presentAlert(message) {
    const alert = await this.alertCtrl.create({
      message: message,
      buttons: ["OK"],
    });

    await alert.present();
  }

  calculateChoicePrice(choice) {
    if (choice && choice.qty > 0 && choice.price > 0) {
      return (choice.qty * choice.price).toFixed(2);
    } else {
      return null;
    }
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }
}
