import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import _ from "lodash";
import { Subscription, auditTime, distinctUntilChanged, first } from "rxjs";
import { LoginPopupsServiceService } from "src/app/services/login-popups-service/login-popups-service.service";
import { Store } from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";
import * as AuthActions from "../../auth/store/auth.actions";
import {
  AlertController,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import { Router } from "@angular/router";
import { AnimationOptions } from "ngx-lottie";
import { ReservationSystemModalsService } from "../reservation-system-modals.service";
import { ReservationPointsProfileModalComponent } from "./reservation-points-profile-modal/reservation-points-profile-modal.component";

@Component({
  selector: "app-reservation-points-page",
  templateUrl: "./reservation-points-page.page.html",
  styleUrls: ["./reservation-points-page.page.scss"],
})
export class ReservationPointsPagePage implements OnInit {
  public userPoints;
  public mo_token: any;
  public loginState: any;
  public currentUser: any;
  public animationLogin: boolean;
  public newCustomer: any;
  public animationLoginInstance: any;
  public windowDimensions: any;
  public colSize: number;
  public textPosition: boolean;
  public user: any;
  public firstName: any;
  public lastName: any;
  public groupApp;
  public nextUserLoyaltyCardLevel;
  public selectedLangugage;
  public mo_base_lang;
  usePointsSuccessAnimationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/use-points-success-animation.json",
  };
  public usePointsAnimationInstance: any;
  public usePointsAnimation: boolean = false;

  private subscriptions: Subscription[] = [];
  private unsubscribeBackEvent: Subscription;
  private pointsProfileModal: any;

  constructor(
    public modalCtrl: ModalController,
    private store: Store<fromApp.AppState>,
    private translateService: TranslateService,
    private loginPopupsService: LoginPopupsServiceService,
    private reservationSystemModalsService: ReservationSystemModalsService,
    private platform: Platform,
    private changeDetector: ChangeDetectorRef,
    private toastController: ToastController,
    private router: Router
  ) {}

  ngOnInit() {}
  ionViewDidEnter() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      100,
      () => {
        if (this.platform.is("android")) {
          this.router.navigateByUrl("reservationSystem/reservation-home", {
            replaceUrl: true,
          });
        }
      }
    );
    this.subscriptions.push(
      this.store.select("groupApp").subscribe((state) => {
        if (
          state &&
          state.groupApp &&
          !_.isEqual(this.groupApp, state.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp);
          if (this.groupApp && this.groupApp.languages) {
            _.each(this.groupApp.languages, (lang) => {
              if (lang && lang.mo_base) {
                this.mo_base_lang = _.cloneDeep(lang.code);
              }
            });
            if (!this.mo_base_lang) {
              this.mo_base_lang = "en";
            }
          }
        }

        if (
          state &&
          state.selectedLangugage &&
          !_.isEqual(this.selectedLangugage, state.selectedLangugage)
        ) {
          this.selectedLangugage = _.cloneDeep(state.selectedLangugage);
        }
      })
    );

    this.subscriptions.push(
      this.store
        .select("points")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.userPoints &&
            !_.isEqual(this.userPoints, state.userPoints)
          ) {
            this.userPoints = _.cloneDeep(state.userPoints);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.windowDimensions, state.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
            // [size]="windowDimensions.width > 600 ? '6': '12'"
            if (
              this.windowDimensions.width > 600 &&
              this.windowDimensions.width < 1100
            ) {
              this.colSize = 6;
              this.textPosition = true;
            } else if (this.windowDimensions.width < 600) {
              this.textPosition = false;
              this.colSize = 12;
            } else {
              this.colSize = 4;
              this.textPosition = true;
            }
          }
        })
    );
    this.authSubscription();
  }

  navigateHome() {
    this.router.navigate(["homepage"]);
  }

  async presentModalLogin() {
    this.loginPopupsService.closeFirebaseModalLogin();
    this.loginPopupsService.showFirebaseModalLogin(null, null);
  }

  showLoginModalAndAlert() {
    this.presentErrorToast(
      this.translateService.instant("please-connect-to-get-the-promo"),
      "danger"
    );
    this.presentModalLogin();
  }

  animationCreated(ev) {
    this.usePointsAnimationInstance = ev;
    this.usePointsAnimationInstance.setSpeed(1);
  }

  animationComplete(ev) {
    this.usePointsAnimationInstance.stop();
    this.usePointsAnimation = false;
    this.changeDetector.detectChanges();
  }

  async presentReservationPointsProfileModal() {
    this.pointsProfileModal = await this.modalCtrl.create({
      component: ReservationPointsProfileModalComponent,
      animated: true,
      backdropDismiss: false,
      componentProps: {
        groupApp: this.groupApp,
        userPoints: this.userPoints,
        doNotShowTitleDivider: true,
        selectedLangugage: this.selectedLangugage,
        baseLanguage: this.mo_base_lang,
      },
      cssClass: "reservationPointsProfileModal",
    });
    await this.pointsProfileModal.present();
    await this.pointsProfileModal.onDidDismiss().then(() => {
      this.pointsProfileModal = null;
    });
  }

  authSubscription() {
    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(auditTime(200))
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.mo_token &&
            !_.isEqual(this.mo_token, state.mo_token)
          ) {
            this.mo_token = _.cloneDeep(state.mo_token);
          }
          if (state && !_.isEqual(state.loginState, this.loginState)) {
            this.loginState = _.cloneDeep(state.loginState);
          }

          if (state && state.user && !_.isEqual(state.user, this.currentUser)) {
            this.currentUser = _.cloneDeep(state.user);
            this.calculateNextUserLoyaltyCardLevel();
            if (state.freshLogin) {
              this.animationLogin = true;
              this.store.dispatch(new AuthActions.SetFreshLogin(false));
            }
          }
          if (state && !_.isEqual(state.newCustomer, this.newCustomer)) {
            this.newCustomer = _.cloneDeep(state.newCustomer);
          }
          if (state && !_.isEqual(state.user, this.user)) {
            this.user = _.cloneDeep(state.user);
            this.firstName =
              this.user && this.user.firstName
                ? this.user.firstName.toUpperCase()
                : null;
            this.lastName =
              this.user && this.user.lastName
                ? this.user.lastName.toUpperCase()
                : null;
          }
        })
    );
  }

  rewardsEventsHandler(event) {
    if (event && event.event) {
      const ev = event.event;
      const store_id = event.store_id;
      if (ev === "successUsePoints") {
        this.usePointsAnimation = true;
      } else if (ev === "show-coupons-modal" && store_id) {
        this.router.navigateByUrl(
          "/reservationSystem/reservation-catalog/" +
            store_id.toString() +
            "?showCouponsModal=true",
          {
            replaceUrl: true,
          }
        );
      } else if (ev === "show-login-modal") {
        this.showLoginModalAndAlert();
      }
    }
  }

  calculateNextUserLoyaltyCardLevel() {
    this.store
      .select("groupApp")
      .pipe(first())
      .subscribe((state) => {
        if (state && state.groupApp) {
          const groupApp = _.cloneDeep(state.groupApp);

          if (
            groupApp &&
            groupApp.points &&
            groupApp.points.loyaltyCardLevelsActive &&
            groupApp.points.loyaltyCardLevels
          ) {
            const cardLevelsByPoints = _.orderBy(
              groupApp.points.loyaltyCardLevels,
              ["points"],
              "asc"
            );
            _.each(cardLevelsByPoints, (card_level) => {
              if (
                !this.currentUser.current_loyalty_card_level ||
                (this.currentUser.current_loyalty_card_level &&
                  this.currentUser.current_loyalty_card_level.points <
                    card_level.points)
              ) {
                this.nextUserLoyaltyCardLevel = _.cloneDeep(card_level);
                return false;
              }
            });
            console.log(
              "calculateNextUserLoyaltyCardLevel",
              this.nextUserLoyaltyCardLevel
            );
          }
        }
      })
      .unsubscribe();
  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }

  ionViewDidLeave() {
    this.currentUser = null;
    this.mo_token = null;

    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }

    if (this.subscriptions && this.subscriptions.length) {
      _.each(this.subscriptions, (sub) => {
        sub.unsubscribe();
      });

      this.subscriptions = [];
    }
  }
}
