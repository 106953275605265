import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { IonContent, ModalController, Platform } from "@ionic/angular";
import moment from "moment";

import * as _ from "lodash";
import { Subscription } from "rxjs";

@Component({
  selector: "app-reservation-points-profile-modal",
  templateUrl: "./reservation-points-profile-modal.component.html",
  styleUrls: ["./reservation-points-profile-modal.component.scss"],
})
export class ReservationPointsProfileModalComponent
  implements OnInit, OnDestroy
{
  @ViewChild(IonContent, { static: false }) content: IonContent;
  @Input() userPoints;
  @Input() groupApp;
  @Input() selectedLangugage;
  @Input() baseLanguage: any;
  userPointsForLevels;
  details = false;
  public scrollFromBottom = 10000;

  private unsubscribeBackEvent: Subscription;

  constructor(public modalCtrl: ModalController, private platform: Platform) {}

  async onScrollEvent(ev) {
    const scrollElement = await this.content.getScrollElement();
    this.scrollFromBottom =
      scrollElement.scrollHeight -
      scrollElement.scrollTop -
      scrollElement.clientHeight;

    console.log("on scroll", this.scrollFromBottom);
  }

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          this.modalCtrl.dismiss();
        }
      }
    );

    if (
      this.userPoints &&
      this.userPoints.records &&
      this.groupApp &&
      this.groupApp.points.loyaltyCardLevelExpirationPointsMonths &&
      this.groupApp.points &&
      this.groupApp.points.loyaltyCardLevelsActive &&
      this.groupApp.points.loyaltyCardLevels &&
      this.groupApp.points.loyaltyCardLevels.length
    ) {
      this.calculateCardLevelValidPoints(
        this.groupApp,
        this.userPoints.records
      );
      console.log("userPointsForLevels", this.userPointsForLevels);
    }
  }

  calculateCardLevelValidPoints(groupApp, userPoints) {
    let filteredPoints = _.cloneDeep(userPoints);
    this.userPointsForLevels = {
      total: 0,
      active: 0,
      used: 0,
      expired: 0,
    };
    const now = Date.now();

    if (groupApp.points.loyaltyCardLevelExpirationPointsMonths) {
      filteredPoints = _.filter(_.cloneDeep(filteredPoints), (pointItem) => {
        if (
          moment(parseInt(pointItem.timestamp)).isAfter(
            moment().subtract(
              groupApp.points.loyaltyCardLevelExpirationPointsMonths,
              "months"
            )
          )
        ) {
          return pointItem;
        }
      });
    }

    _.each(filteredPoints, (item) => {
      if (item.status === "active") {
        if (item.used_points > 0) {
          this.userPointsForLevels.used += item.used_points;
        }

        if (item.expiration && item.expiration <= now) {
          //This means that it has expired!
          this.userPointsForLevels.expired += item.points - item.used_points;
        } else {
          this.userPointsForLevels.active +=
            parseInt(item.points) - parseInt(item.used_points);
        }
      }
    });

    this.userPointsForLevels.total =
      this.userPointsForLevels.active +
      this.userPointsForLevels.used +
      this.userPointsForLevels.expired;
  }

  ngOnDestroy(): void {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
  }
}
