import * as OrdersActions from "./orders.actions";
import * as _ from "lodash";

export interface State {
  ordersArray: any;
  piraeusPendingPaymentOrder: any;
  fetchOrdersAgainTimestamp: any;
  klarnaPopupOpen: any;
  paypalPopupOpen: any;
  braintreePopupOpen: any;
  eurobankPopupOpen: any;
  ethnikiPopupOpen: any;
  nbgSimplifyPopupOpen: any;
  vivaPopupOpen: any;
  myposPopupOpen: any;
  piraeusPopupOpen: any;
  alphaPopupOpen: any;
}

const initialState: State = {
  ordersArray: null,
  piraeusPendingPaymentOrder: null,
  fetchOrdersAgainTimestamp: null,
  klarnaPopupOpen: false,
  paypalPopupOpen: false,
  braintreePopupOpen: false,
  eurobankPopupOpen: false,
  ethnikiPopupOpen: false,
  nbgSimplifyPopupOpen: false,
  vivaPopupOpen: false,
  myposPopupOpen: false,
  piraeusPopupOpen: false,
  alphaPopupOpen: false,
};

export function ordersReducer(
  state = initialState,
  action: OrdersActions.OrdersActions
) {
  switch (action.type) {
    case OrdersActions.SET_ORDERS_ARRAY:
      return {
        ...state,
        ordersArray: action.payload,
      };

    case OrdersActions.ADD_UPDATE_ORDERS_FROM_SOCKET:
      if (state && state.ordersArray) {
        const key = _.findIndex(state.ordersArray, (order: any) => {
          return (
            order.order_id === action.payload.order_id &&
            order.store_id === action.payload.store_id
          );
        });
        if (key !== -1) {
          const updatedOrder = {
            ...state.ordersArray[key],
            ...action.payload,
          };
          const updatedOrders = [...state.ordersArray];
          updatedOrders[key] = updatedOrder;
          return {
            ...state,
            ordersArray: updatedOrders,
          };
        } else {
          return {
            ...state,
            ordersArray: _.orderBy(
              _.unionBy([...state.ordersArray, action.payload], "order_id"),
              ["timestamp"],
              ["desc"]
            ),
          };
        }
      } else {
        return state;
      }

    case OrdersActions.SET_CURRENT_PENDING_PAYMENT_PIRAEUS_ORDER:
      return {
        ...state,
        piraeusPendingPaymentOrder: action.payload,
      };

    case OrdersActions.SET_FETCH_ORDERS_AGAIN_TIMESTAMP:
      return {
        ...state,
        fetchOrdersAgainTimestamp: action.payload,
      };

    case OrdersActions.SET_PAYPALPOPUP_OPEN:
      return {
        ...state,
        paypalPopupOpen: action.payload,
      };
    case OrdersActions.SET_KLARNAPOPUP_OPEN:
      return {
        ...state,
        klarnaPopupOpen: action.payload,
      };

    case OrdersActions.SET_MYPOSPOPUP_OPEN:
      return {
        ...state,
        myposPopupOpen: action.payload,
      };

    case OrdersActions.SET_ETHNIKIPOPUP_OPEN:
      return {
        ...state,
        ethnikiPopupOpen: action.payload,
      };

    case OrdersActions.SET_NBG_SIMPLIFY_POPOUP_OPEN:
      return {
        ...state,
        nbgSimplifyPopupOpen: action.payload,
      };
    case OrdersActions.SET_VIVAPOPUP_OPEN:
      return {
        ...state,
        vivaPopupOpen: action.payload,
      };
    case OrdersActions.SET_PIRAEUSPOPUP_OPEN:
      return {
        ...state,
        piraeusPopupOpen: action.payload,
      };
    case OrdersActions.SET_ALPHAPOPUP_OPEN:
      return {
        ...state,
        alphaPopupOpen: action.payload,
      };
    case OrdersActions.SET_EUROBANKPOPUP_OPEN:
      return {
        ...state,
        eurobankPopupOpen: action.payload,
      };

    case OrdersActions.CLEAR_ORDERS_ARRAY:
      return {
        ...state,
        ordersArray: null,
      };

    default:
      return state;
  }
}
