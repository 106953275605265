<ion-header>
  <ion-toolbar>
    <ion-title>
      {{ "account-settings.customer-info" | translate }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button fill="clear" (click)="closeModal()">
        <ion-icon size="large" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [scrollY]="false">
  <p class="ion-text-center">{{ "reservations.please-fill-your-name" | translate }}</p>
  <ion-item>
    <ion-label position="floating">{{ "account-settings.name" | translate }}</ion-label>
    <ion-input [(ngModel)]="firstName">
    </ion-input>
  </ion-item>
  <ion-item>
    <ion-label position="floating">{{ "account-settings.last-name" | translate }}</ion-label>
    <ion-input [(ngModel)]="lastName">
    </ion-input>
  </ion-item>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-row class="ion-justify-content-center ion-text-center">
      <ion-button [disabled]="!firstName || !lastName || loading" (click)="submit()">
        <ion-text *ngIf="!loading">{{ "reservations.continue" | translate }}</ion-text>
        <ion-spinner *ngIf="loading"></ion-spinner>
      </ion-button>
    </ion-row>
  </ion-toolbar>
</ion-footer>