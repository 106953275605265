import * as WindowInfoActions from "./windowInfo.actions";
import * as _ from "lodash";

export interface State {
  windowDimensions: any;
  screenRatio: any;
  sideMenuIsOpen: boolean;
  calculateWindowDimensionsTimestamp: string;
}

const initialState: State = {
  windowDimensions: null,
  screenRatio: null,
  sideMenuIsOpen: false,
  calculateWindowDimensionsTimestamp: null,
};

export function windowInfoReducer(
  state = initialState,
  action: WindowInfoActions.WindowInfoActions
) {
  switch (action.type) {
    case WindowInfoActions.SET_WINDOW_DIMENSIONS:
      return {
        ...state,
        windowDimensions: action.payload,
      };

    case WindowInfoActions.SET_SIDE_MENU_IS_OPEN:
      return {
        ...state,
        sideMenuIsOpen: action.payload,
      };

    case WindowInfoActions.SET_CALCULATE_WINDOW_DIMENSIONS_TIMESTAMP:
      return {
        ...state,
        calculateWindowDimensionsTimestamp: action.payload,
      };

    case WindowInfoActions.CLEAR_WINDOW_DIMENSIONS:
      return {
        ...state,
        windowDimensions: null,
      };

    default:
      return state;
  }
}
