import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription, distinctUntilChanged } from "rxjs";
import * as _ from "lodash";
import { Store } from "@ngrx/store";
import * as fromApp from "../store/app.reducer";
@Component({
  selector: "app-reservation-system",
  templateUrl: "./reservation-system.page.html",
  styleUrls: ["./reservation-system.page.scss"],
})
export class ReservationSystemPage implements OnInit, OnDestroy {
  public selectedTab: string = "home";
  public currentUrl: any;
  public groupApp;

  private subscription: Subscription;
  private subscription2: Subscription;

  constructor(private store: Store<fromApp.AppState>, private router: Router) {}

  ngOnInit() {
    this.subscription2 = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.groupApp &&
          !_.isEqual(state.groupApp, this.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp);
        }
      });

    if (this.router && this.router.url) {
      this.currentUrl = _.cloneDeep(this.router.url);
    }
  }

  changeIcon(ev: any) {
    this.selectedTab = ev.tab;
    console.log("adsasdasd", this.selectedTab);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscription2) {
      this.subscription2.unsubscribe();
    }
  }
}
