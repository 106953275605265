<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">
      {{ "popups.select-store-popup.choose-store-title" | translate }}
    </ion-title>

    <ion-button
      *ngIf="stores && stores.length > 1 && !showValidStores"
      (click)="calculateLocations()"
      slot="end"
      size="small"
      fill="clear"
      color="primary"
    >
      <ion-icon slot="icon-only" src="assets/ionicons/location.svg"> </ion-icon>
    </ion-button>
    <ion-button
      (click)="closeModal()"
      size="small"
      slot="end"
      fill="clear"
      color="dark"
      ><ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon
    ></ion-button>
    <ion-progress-bar
      *ngIf="gpsDistanceLoading || searchStoreLoading"
      color="primary"
      type="indeterminate"
      style="position: absolute; bottom: 0"
    ></ion-progress-bar>
  </ion-toolbar>

  <ion-grid
    *ngIf="stores && stores.length > 3 && !showValidStores"
    style="max-width: 350px"
  >
    <ion-searchbar
      animated
      [placeholder]="'search_store' | translate"
      (ionChange)="onSearchType()"
      (ionClear)="resetSearchStore()"
      type="text"
      [(ngModel)]="searchTerm"
    >
    </ion-searchbar>
  </ion-grid>
</ion-header>

<ion-backdrop
  *ngIf="showGpsLoadingAnimation"
  style="z-index: 2147483647; width: 100%; height: 100%"
  [tappable]="false"
>
</ion-backdrop>
<ion-grid
  *ngIf="showGpsLoadingAnimation"
  style="
    position: absolute;
    top: 25%;
    z-index: 2147483647;
    width: 100%;
    height: 100%;
  "
  class="ion-text-center"
>
  <ion-grid style="max-width: 180px">
    <ng-lottie
      (animationCreated)="animationCreated($event)"
      [options]="options"
    ></ng-lottie>
  </ion-grid>
</ion-grid>

<ion-content>
  <ion-row class="ion-padding" *ngIf="!stores">
    <ion-col class="ion-text-center">
      <ion-spinner> </ion-spinner>
    </ion-col>
  </ion-row>

  <ion-list
    class="ion-no-padding"
    *ngIf="stores && stores.length && !showValidStores && selectedLangugage"
  >
    <div
      *ngFor="
        let store of filteredStores | orderStoresByDistance;
        trackBy: trackStores
      "
    >
      <ion-item
        [class]="
          !showValidStores && stores && stores.length > 1 ? 'storeItemBig' : ''
        "
        lines="full"
        mode="ios"
        button
        detail="false"
        (click)="
          onlyReturnSelectedStoreId
            ? returnSelectedStoreId(store.store_id)
            : selectStore(store.store_id)
        "
        *ngIf="!validStores || store.valid"
      >
        <ion-label class="ion-text-wrap">
          {{
            store.store_name_mobileorder &&
            store.store_name_mobileorder[selectedLangugage]
              ? store.store_name_mobileorder[selectedLangugage]
              : store.store_name_langs &&
                store.store_name_langs[selectedLangugage]
              ? store.store_name_langs[selectedLangugage]
              : store.store_name_langs && store.store_name_langs.el
              ? store.store_name_langs.el
              : store.store_name
              ? store.store_name
              : ""
          }}
          <p>{{ store.address }}</p>

          <ion-text
            color="danger"
            *ngIf="
              onlyReturnSelectedStoreId &&
              storesForSelection &&
              !store.hasTheOffer &&
              !forSaveCard &&
              !forDineinSelection &&
              !store.hasTheOffer
            "
          >
            {{ "homepage.doesnt-have-the-offer" | translate }}
          </ion-text>

          <ion-text
            color="danger"
            *ngIf="
              onlyReturnSelectedStoreId &&
              storesForSelection &&
              !store.supported &&
              !forDineinSelection &&
              forSaveCard
            "
          >
            {{ "payment-cards.store-doesnt-support-save-card" | translate }}
          </ion-text>

          <ion-text
            color="danger"
            *ngIf="
              forDineinSelection &&
              onlyReturnSelectedStoreId &&
              storesForSelection &&
              !store.hasTheOffer &&
              !forSaveCard &&
              forDineinSelection
            "
          >
            {{
              "popups.dine-in-modal.dinein-is-disabled-in-this-store-short"
                | translate
            }}
          </ion-text>
        </ion-label>
        <ion-button fill="clear" slot="end">
          <ion-icon
            src="assets/ionicons/chevron-forward-outline.svg"
            slot="icon-only"
          >
          </ion-icon>
        </ion-button>

        <p
          style="
            margin: 0;
            position: absolute;
            bottom: 5px;
            right: 10px;
            font-size: small;
            z-index: 100;
          "
          *ngIf="
            !showValidStores &&
            store.estimatedDistanceFromClientGps &&
            !gpsDistanceLoading &&
            stores.length > 1
          "
        >
          <ion-text color="primary">
            {{ store.estimatedDistanceFromClientGps }}km
          </ion-text>
        </p>
      </ion-item>
    </div>
  </ion-list>
  <ion-list
    class="ion-no-padding"
    *ngIf="
      stores &&
      stores.length &&
      showValidStores &&
      renderedValidStores &&
      selectedLangugage
    "
  >
    <div style="margin: 8px" *ngFor="let store of renderedValidStores">
      <ion-item
        lines="full"
        mode="ios"
        button
        detail="false"
        (click)="selectStore(store.store_id)"
        *ngIf="!store.disableOrderPickup"
      >
        <ion-label class="ion-text-wrap">
          {{
            store.store_name_mobileorder &&
            store.store_name_mobileorder[selectedLangugage]
              ? store.store_name_mobileorder[selectedLangugage]
              : store.store_name_langs &&
                store.store_name_langs[selectedLangugage]
              ? store.store_name_langs[selectedLangugage]
              : store.store_name_langs && store.store_name_langs.el
              ? store.store_name_langs.el
              : store.store_name
              ? store.store_name
              : ""
          }}
          <p>{{ store.address }}</p></ion-label
        >
        <ion-button fill="clear" slot="end">
          <ion-icon
            src="assets/ionicons/chevron-forward-outline.svg"
            slot="icon-only"
          >
          </ion-icon>
        </ion-button>
      </ion-item>
    </div>
  </ion-list>
</ion-content>
