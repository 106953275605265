import { Pipe, PipeTransform } from "@angular/core";
import * as _ from "lodash";

@Pipe({
  name: "filterInlineCartInfoMessages",
})
export class FilterInlineCartInfoMessagesPipe implements PipeTransform {
  transform(infoMessages: any, cartPrices: any, fromComponent): any {
    let filteredInfoMessages = _.cloneDeep(infoMessages);
    if (fromComponent === "cart-list") {
      filteredInfoMessages = _.filter(infoMessages, (infoMessage) => {
        if (
          (!infoMessage.show_in_cart_amount_condition_enabled ||
            !infoMessage.show_in_cart_amount_condition ||
            !infoMessage.show_in_cart_amount) &&
          (!infoMessage.cartPosition ||
            infoMessage.cartPosition === "down_to_list")
        ) {
          return infoMessage;
        } else if (
          infoMessage.show_in_cart_amount_condition_enabled &&
          infoMessage.show_in_cart_amount_condition &&
          infoMessage.show_in_cart_amount &&
          cartPrices &&
          cartPrices.grand_total &&
          ((infoMessage.show_in_cart_amount_condition === "over" &&
            infoMessage.show_in_cart_amount <=
              parseFloat(cartPrices.grand_total)) ||
            (infoMessage.show_in_cart_amount_condition === "under" &&
              infoMessage.show_in_cart_amount >
                parseFloat(cartPrices.grand_total))) &&
          (!infoMessage.cartPosition ||
            infoMessage.cartPosition === "down_to_list")
        ) {
          return infoMessage;
        }
      });
    } else if (fromComponent === "cart-prices") {
      filteredInfoMessages = _.filter(infoMessages, (infoMessage) => {
        if (
          (!infoMessage.show_in_cart_amount_condition_enabled ||
            !infoMessage.show_in_cart_amount_condition ||
            !infoMessage.show_in_cart_amount) &&
          infoMessage.cartPosition &&
          infoMessage.cartPosition === "below_price"
        ) {
          return infoMessage;
        } else if (
          infoMessage.show_in_cart_amount_condition_enabled &&
          infoMessage.show_in_cart_amount_condition &&
          infoMessage.show_in_cart_amount &&
          cartPrices &&
          cartPrices.grand_total &&
          ((infoMessage.show_in_cart_amount_condition === "over" &&
            infoMessage.show_in_cart_amount <=
              parseFloat(cartPrices.grand_total)) ||
            (infoMessage.show_in_cart_amount_condition === "under" &&
              infoMessage.show_in_cart_amount >
                parseFloat(cartPrices.grand_total))) &&
          infoMessage.cartPosition &&
          infoMessage.cartPosition === "below_price"
        ) {
          return infoMessage;
        }
      });
    }

    return filteredInfoMessages;
  }
}
