import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import * as _ from "lodash";
import { Store } from "@ngrx/store";

import { auditTime, distinctUntilChanged } from "rxjs/operators";
import * as fromApp from "../../../../../../store/app.reducer";
import * as selectors from "../../../../../../store/app.selectors";
import { Subscription } from "rxjs";

@Component({
  selector: "app-no-quantity-image-checkbox-choice",
  templateUrl: "./no-quantity-image-checkbox-choice.component.html",
  styleUrls: ["./no-quantity-image-checkbox-choice.component.scss"],
})
export class NoQuantityImageCheckboxChoiceComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() selectedProductId: any;
  @Input() bundleMode: any;
  @Input() option_id;
  @Input() choice_id;
  @Input() selectedProductPromo: any;
  @Input() selectedProductCategory: any;
  @Input() showChoicePrices: boolean;
  @Input() imagePath;
  @Input() alt_dinein_active: any;
  @Input() groupStoreData: any;
  @Output() choiceChanged = new EventEmitter();

  public option: any;
  public choice;
  public orderPickup;
  public dineIn;
  public selectedDineInTable;

  private selectedProduct: any;
  private subscription: Subscription;
  private optionSubscription: Subscription;

  constructor(private store: Store<fromApp.AppState>) {}
  //TO_DO CHECK WHY TAKING CHOICE WITH SELECTOR ONLY IN THIS COMPONENT
  selectOption() {
    if (this.optionSubscription) {
      this.optionSubscription.unsubscribe();
    }
    this.optionSubscription = this.store
      .select(
        selectors.getOptionByOptionId(this.selectedProductId, this.option_id)
      )
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (!_.isEqual(this.option, state)) {
          this.option = _.cloneDeep(state);
          if (
            this.option &&
            this.option.freeChoices &&
            this.option.freeChoices !== "0"
          ) {
            if (this.option.optionData && this.option.optionData.freeChoices) {
              this.showChoicePrices = true;
            } else {
              this.showChoicePrices = false;
            }
          } else {
            this.showChoicePrices = true;
          }
          if (this.option && this.option.choices) {
            let choiceIndex = _.findIndex(this.option.choices, {
              choice_id: this.choice_id,
            });
            if (
              choiceIndex !== -1 &&
              !_.isEqual(this.choice, this.option.choices[choiceIndex])
            ) {
              this.choice = this.option.choices[choiceIndex];
            }
          }
        }
      });
  }

  ngOnChanges(simpleChange: SimpleChanges) {
    if (
      simpleChange &&
      simpleChange.option_id &&
      simpleChange.option_id.currentValue !==
        simpleChange.option_id.previousValue
    ) {
      this.selectOption();
    }
  }

  ngOnInit() {
    this.selectOption();
    this.subscription = this.store
      .select("deliveryMethodAddress")

      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (state && !_.isEqual(this.dineIn, state.dineIn)) {
          this.dineIn = _.cloneDeep(state.dineIn);
        }
        if (state && !_.isEqual(this.orderPickup, state.orderPickup)) {
          this.orderPickup = _.cloneDeep(state.orderPickup);
        }
        if (
          state &&
          !_.isEqual(state.selectedDineInTable, this.selectedDineInTable)
        ) {
          this.selectedDineInTable = _.cloneDeep(state.selectedDineInTable);
        }
      });

    // this.store
    //   .select("selectedProduct")
    //   .pipe(distinctUntilChanged())
    //   .subscribe((state) => {
    //     if (
    //       state &&
    //       state.selectedProduct &&
    //       !_.isEqual(this.selectedProduct, state.selectedProduct)
    //     ) {
    //       this.selectedProduct = _.cloneDeep(state.selectedProduct);
    //       if (state.selectedProduct.options && this.option_id) {
    //         let optionInx = _.findIndex(this.selectedProduct.options, {
    //           option_id: this.option_id,
    //         });
    //         console.log("option from no quantity", optionInx);
    //         if (
    //           state.selectedProduct.options &&
    //           optionInx !== -1 &&
    //           !_.isEqual(state.selectedProduct.options[optionInx], this.option)
    //         ) {
    //           this.option = _.cloneDeep(
    //             state.selectedProduct.options[optionInx]
    //           );
    //           console.log("option from no quantity", this.option);
    //           if (
    //             state.selectedProduct.options[optionInx].choices &&
    //             this.choice_id
    //           ) {
    //             let choiceInx = _.findIndex(
    //               state.selectedProduct.options[optionInx].choices,
    //               {
    //                 choice_id: this.choice_id,
    //               }
    //             );
    //             console.log("choice index from no quantity", choiceInx);
    //             if (
    //               choiceInx !== -1 &&
    //               !_.isEqual(
    //                 this.choice,
    //                 state.selectedProduct.options[optionInx].choices[choiceInx]
    //               )
    //             ) {
    //               this.choice = _.cloneDeep(
    //                 state.selectedProduct.options[optionInx].choices[choiceInx]
    //               );
    //               console.log("choice from no quantity", this.choice);
    //             }
    //           }
    //         }
    //       }
    //     }
    //   });
  }

  onCheckboxChanged(ev) {
    if (document.activeElement.tagName === "ION-CHECKBOX") {
      if (
        this.option &&
        this.option.freeChoices &&
        this.option.freeChoicesBySelectionOrder
      ) {
        if (this.choice.is_preselected) {
          this.choice.selected_timestamp = Date.now().toString();
        } else {
          delete this.choice.selected_timestamp;
        }
      }

      this.choiceChanged.emit(this.choice);
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.optionSubscription) {
      this.optionSubscription.unsubscribe();
    }
  }
}
