import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { auditTime, distinct, distinctUntilChanged } from "rxjs/operators";
import * as fromApp from "../../store/app.reducer";
import * as _ from "lodash";
import * as GroupAppActions from "../../store/group-app/groupApp.actions";
import { Subscription } from "rxjs";

@Component({
  selector: "app-language-selection",
  templateUrl: "./language-selection.component.html",
  styleUrls: ["./language-selection.component.scss"],
})
export class LanguageSelectionComponent implements OnInit, OnDestroy {
  public groupApp: any;
  @Input() slot: any;
  private subscription: Subscription;

  constructor(private store: Store<fromApp.AppState>) {}

  ngOnInit() {
    this.subscription = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(50))
      .subscribe((state) => {
        if (
          state &&
          state.groupApp &&
          !_.isEqual(state.groupApp, this.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp);
          console.log(this.groupApp.languages);
        }
      });
  }

  changeLanguage(lang) {
    console.log("selected language", lang);
    this.store.dispatch(new GroupAppActions.SetSelectedLanguage(lang));
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
