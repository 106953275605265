import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../store/app.reducer";
import * as selectors from "../store/app.selectors";
import * as _ from "lodash";
import {
  auditTime,
  catchError,
  distinctUntilChanged,
  first,
} from "rxjs/operators";
import { DataStorageService } from "../services/data-storage/data-storage.service";
import * as OrdersActions from "../orders/store/orders.actions";
import { Subscription } from "rxjs";
import {
  AlertController,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AnalyticsServiceService } from "../services/analitycsService/analytics-service.service";
import { PaymentGatewaysService } from "../services/payment-gateways/payment-gateways.service";
import { SelectStorePopupComponent } from "../popups/select-store-popup/select-store-popup.component";
import { OrderNowPopupComponent } from "../popups/order-now-popup/order-now-popup.component";
import * as DeliveryMethodAddressActions from "../store/deliveryMethodAddress/deliveryMethodAddress.actions";
import { OrderNowModalPopupsService } from "../services/orderNowModalPopupsService/orderNowModalPopups.service";
import { OneSignalService } from "../services/oneSignal/one-signal.service";
import * as PreviousOrdersActions from "../catalog/previous-order/store/previousOrders.actions";
import { PreviousOrderModalComponent } from "../catalog/previous-order/previous-order-modal/previous-order-modal.component";
import { AnimationOptions } from "ngx-lottie";
import { AppRatingModalComponent } from "./order-rating-modal/app-rating-modal/app-rating-modal.component";

import * as PendingOrdersActions from "../store/pending-orders/pendingOrders.actions";
import { SelectStoreImagePopupComponent } from "../popups/select-store-image-popup/select-store-image-popup.component";
import { RateApp } from "capacitor-rate-app";
import { OrderCustomerNotificationsModalComponent } from "./order-customer-notifications-modal/order-customer-notifications-modal.component";
import { Capacitor } from "@capacitor/core";
import * as AuthActions from "../auth/store/auth.actions";
import * as manuallyTriggerFinalPriceActions from "../store/manuallyTriggerFinalPriceCheckAddress/manuallyTriggerFinalPrice.actions";
import moment from "moment";
import { DeliveryTrackingModalComponent } from "../delivery-drive/delivery-tracking-modal/delivery-tracking-modal.component";
declare let window: any;
@Component({
  selector: "app-orders",
  templateUrl: "./orders.page.html",
  styleUrls: ["./orders.page.scss"],
})
export class OrdersPage implements OnDestroy {
  public orderArray: any;
  public currentUser: any;
  public loginLoading: any;
  public checkAddressLoading: boolean = false;
  public orderPickup: boolean;
  public selectedDineInTable: any;
  public dineIn: boolean;
  public stores: any;
  public currentStoreId: any;
  public currentStore: any;
  public selectedAddress: any;
  public showDeliveryButton: boolean;
  public showPickupButton: boolean;
  public showDineInButton: boolean;
  public oneSignalMessage: any;
  public cartProducts: any;
  public pendingOrder: any;
  public orderCompletedAnimation: boolean = false;
  public orderCompletedAnimationInstance: any;
  public orderCanceledAnimation: boolean = false;
  public orderCanceledAnimationInstance: any;
  public openOrderChangePaymentMethodModalForOrderId: any;
  public selectedLangugage: any;
  public customerNotificationsOrderModal: any;
  public generalInfoSurveyModal: any;
  public groupApp;
  public windowDimensions;
  public orderForReview = false;

  private fetchOrdersAgainTimestamp = null;
  private userReviews;
  private appRatingModal: any;

  private orderNowModal: any;
  private selectStoreModal;
  private mo_token: any;
  private infoShowDeliveryButton;
  private subscriptions: Subscription[] = [];
  private unsubscribeBackEvent: Subscription;
  private parametersSubscription: Subscription;
  private queryParametersSubscription: Subscription;

  options: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/success-animation.json",
  };

  AnimationNoOrderoptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/no-orders-animation.json",
  };

  canceledOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/order-canceled-animation.json",
  };

  constructor(
    private store: Store<fromApp.AppState>,
    private dataStorageService: DataStorageService,
    private toastController: ToastController,
    private translateService: TranslateService,
    private router: Router,
    private analyticsService: AnalyticsServiceService,
    private alertController: AlertController,
    private paymentGatewaysService: PaymentGatewaysService,
    private platform: Platform,
    private modalController: ModalController,
    private orderNowModalPopupsService: OrderNowModalPopupsService,
    private oneSignalService: OneSignalService,
    private changeDetector: ChangeDetectorRef,
    private paymentGatewayService: PaymentGatewaysService,
    private activetedRoute: ActivatedRoute
  ) {}

  async ionViewDidEnter() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      100,
      () => {
        if (this.paymentGatewayService.billingInfoVerifyModalIsOpen()) {
          this.paymentGatewayService.closeBillingInfoVerifyModal();
        } else {
          this.navigateToHome();
        }
      }
    );

    this.analyticsService.fbqTrackPageView();
    this.analyticsService.setCurrentScreenFirebase("orders");

    this.subscriptions.push(
      this.store
        .select("oneSignalMessages")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.oneSignalMessage &&
            !_.isEqual(state.oneSignalMessage, this.oneSignalMessage)
          ) {
            this.oneSignalMessage = _.cloneDeep(state.oneSignalMessage);
            if (
              this.oneSignalMessage.additionalData &&
              this.oneSignalMessage.additionalData.reason &&
              this.oneSignalMessage.additionalData.reason ===
                "orderStatusUpdate"
            ) {
              this.store
                .select("auth")
                .pipe(first())
                .subscribe((state) => {
                  if (state && state.mo_token) {
                    this.getUserOrders(state.mo_token);
                  }
                })
                .unsubscribe();
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && !_.isEqual(state.groupApp, this.groupApp)) {
            this.groupApp = _.cloneDeep(state.groupApp);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(state.windowDimensions, this.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("cart")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && !_.isEqual(state.products, this.cartProducts)) {
            this.cartProducts = _.cloneDeep(state.products);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("orders")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((state) => {
          if (
            state &&
            state.ordersArray &&
            !_.isEqual(this.orderArray, state.ordersArray)
          ) {
            this.orderArray = _.cloneDeep(state.ordersArray);

            if (this.pendingOrder) {
              const previousPendingOrder = _.find(this.orderArray, {
                order_id: this.pendingOrder.order_id,
                store_id: this.pendingOrder.store_id,
              });
              if (
                previousPendingOrder &&
                previousPendingOrder.status &&
                previousPendingOrder.status === "complete"
              ) {
                this.orderCompletedAnimation = true;
                this.pendingOrder = null;
              } else if (
                previousPendingOrder &&
                previousPendingOrder.status &&
                previousPendingOrder.status === "canceled"
              ) {
                this.orderCanceledAnimation = true;
                this.pendingOrder = null;
              }
            } else {
              this.pendingOrder = _.find(this.orderArray, {
                status: "pending",
              });
              if (this.pendingOrder) {
                this.orderNotifications();
              }
            }
            if (!this.parametersSubscription) {
              this.subscribeToParameters();
            }
          }

          if (
            state &&
            state.fetchOrdersAgainTimestamp &&
            !_.isEqual(
              this.fetchOrdersAgainTimestamp,
              state.fetchOrdersAgainTimestamp
            )
          ) {
            this.fetchOrdersAgainTimestamp = _.cloneDeep(
              state.fetchOrdersAgainTimestamp
            );

            this.fetchUserOrdersAgain();

            this.store.dispatch(
              new OrdersActions.SetFetchOrdersAgainTimestamp(null)
            );
            this.fetchOrdersAgainTimestamp = null;
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((state) => {
          if (state.mo_token && !_.isEqual(state.mo_token, this.mo_token)) {
            this.mo_token = _.cloneDeep(state.mo_token);
          }
          if (state && state.user && !_.isEqual(state.user, this.currentUser)) {
            this.currentUser = _.cloneDeep(state.user);
            this.getUserOrders(state.mo_token);

            this.fetchCustomerPreviousOrders();
          }
          if (
            state &&
            state.loginLoading &&
            !_.isEqual(this.loginLoading, state.loginLoading)
          ) {
            this.loginLoading = _.cloneDeep(state.loginLoading);
            if (this.loginLoading === "not-loading" && _.isEmpty(state.user)) {
              this.presentErrorToast(
                this.translateService.instant(
                  "orders.order-item.you-must-be-logged-in-to-see-your-orders"
                ),
                "danger"
              );
              this.router.navigateByUrl("/homepage", {
                replaceUrl: true,
              });
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe(async (state) => {
          if (state && state.stores && !_.isEqual(this.stores, state.stores)) {
            this.stores = _.cloneDeep(state.stores);

            this.showDeliveryButton = !_.isEmpty(
              _.filter(this.stores, (store) => {
                if (!store.disableOrderDelivery) {
                  return store;
                }
              })
            );

            if (!this.showDeliveryButton) {
              this.infoShowDeliveryButton =
                await this.orderNowModalPopupsService.checkToShowInfoDeliveryButton();

              this.infoShowDeliveryButton = !!this.infoShowDeliveryButton;
            }

            this.showPickupButton = !_.isEmpty(
              _.filter(this.stores, (store) => {
                if (!store.disableOrderPickup) {
                  return store;
                }
              })
            );
            console.log("pickup button", this.showPickupButton);

            this.showDineInButton = !_.isEmpty(
              _.filter(this.stores, (store) => {
                if (store.dinein) {
                  return store;
                }
              })
            );
            console.log("dine in butto");
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("currentStore")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((state) => {
          if (
            state &&
            state.currentStoreId &&
            !_.isEqual(this.currentStoreId, state.currentStoreId)
          ) {
            this.currentStoreId = _.cloneDeep(state.currentStoreId);
            this.store
              .select("stores")
              .subscribe((state) => {
                if (state && state.stores) {
                  this.currentStore = _.cloneDeep(
                    _.find(state.stores, {
                      store_id: this.currentStoreId,
                    })
                  );
                }
              })
              .unsubscribe();
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.selectedLangugage &&
            !_.isEqual(this.selectedLangugage, state.selectedLangugage)
          ) {
            this.selectedLangugage = _.cloneDeep(state.selectedLangugage);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("deliveryMethodAddress")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (state && !_.isEqual(state.orderPickup, this.orderPickup)) {
            this.orderPickup = _.cloneDeep(state.orderPickup);
          }
          if (state && !_.isEqual(state.dineIn, this.dineIn)) {
            this.dineIn = _.cloneDeep(state.dineIn);
          }
          if (
            state &&
            !_.isEqual(this.selectedDineInTable, state.selectedDineInTable)
          ) {
            this.selectedDineInTable = _.cloneDeep(state.selectedDineInTable);
          }
          if (
            state &&
            state.selectedAddress &&
            !_.isEqual(state.selectedAddress, this.selectedAddress)
          ) {
            this.selectedAddress = _.cloneDeep(state.selectedAddress);
          }
          if (
            state &&
            !_.isEqual(state.selectedAddressLoading, this.checkAddressLoading)
          ) {
            this.checkAddressLoading = _.cloneDeep(
              state.selectedAddressLoading
            );
          }
        })
    );
  }

  openedChangePaymentMethodPopup(ev) {
    this.openOrderChangePaymentMethodModalForOrderId = null;
  }

  subscribeToParameters() {
    this.parametersSubscription = this.activetedRoute.paramMap
      .pipe(distinctUntilChanged())
      .subscribe((params) => {
        console.log("daklsjdklas", params);
        if (params && params.get("store_id") && params.get("order_id")) {
          const order_id = _.cloneDeep(params.get("order_id"));
          const store_id = _.cloneDeep(params.get("store_id"));
          const order = _.find(this.orderArray, {
            store_id: store_id,
            order_id: order_id,
          });

          if (order && order.status && order.status === "pendingPayment") {
            this.openOrderChangePaymentMethodModalForOrderId = order_id;

            console.log(
              "set openOrderChangePaymentMethodModalForOrderId",
              order_id
            );
          }
        } else {
          if (!this.queryParametersSubscription) {
            this.queryParametersSubscription = this.activetedRoute.queryParamMap
              .pipe(distinctUntilChanged())
              .subscribe((params) => {
                if (params && params.get("openReviewFirstOrder")) {
                  this.orderForReview = true;
                  setTimeout(() => {
                    this.orderForReview = false;
                  }, 3000);
                  this.router.navigate([], {
                    relativeTo: this.activetedRoute,
                    queryParams: {},
                    replaceUrl: true,
                  });
                }
              });
          }
        }
      });
  }

  private navigateToHome() {
    if (this.platform.is("android")) {
      this.router.navigateByUrl("/homepage", {
        replaceUrl: true,
      });
    }
  }

  fetchUserOrdersAgain() {
    this.orderArray = null;
    this.pendingOrder = null;

    this.store
      .select("auth")
      .pipe(first())
      .subscribe((state) => {
        if (state) {
          this.getUserOrders(state.mo_token);
        }
      })
      .unsubscribe();
  }

  async orderNotifications() {
    let askedForOrderNotifications;
    if (navigator.cookieEnabled) {
      askedForOrderNotifications = localStorage.getItem(
        "askedForOrdersNotificationPermissions"
      );
    }
    if (
      (!askedForOrderNotifications || askedForOrderNotifications === "no") &&
      !this.customerNotificationsOrderModal
    ) {
      this.store
        .select("auth")
        .pipe(first())
        .subscribe((auth) => {
          if (auth && auth.user && auth.mo_token) {
            this.customerNotificationsOrderModal = true;
            this.openCustomerNotifictaionsOrderModal(
              _.cloneDeep(auth.user),
              _.cloneDeep(auth.mo_token)
            );
          }
        })
        .unsubscribe();
    } else if (
      askedForOrderNotifications &&
      askedForOrderNotifications === "yes" &&
      !this.customerNotificationsOrderModal
    ) {
      if (!this.currentUser) {
        await this.getCurrentUser();
      }

      this.store
        .select("reviews")
        .pipe(first())
        .subscribe((state) => {
          if (state) {
            this.userReviews = _.cloneDeep(state.reviewsArray);

            let positiveUserReview = false;

            if (this.userReviews && this.userReviews.length) {
              _.each(this.userReviews, (review) => {
                positiveUserReview = !!_.find(review.questions, {
                  text: "ΕΜΠΕΙΡΙΑ ONLINE ΠΑΡΑΓΓΕΛΙΑΣ",
                  stars: 5,
                });
                if (positiveUserReview) {
                  return false;
                }
              });
            }
            console.log("positiveReview", positiveUserReview);
            if (
              (!this.currentUser.ratingAppDialogShownTimestamp ||
                (
                  this.currentUser.ratingAppDialogShownTimestamp &&
                  moment(
                    parseInt(
                      this.currentUser.ratingAppDialogShownTimestamp.toString()
                    )
                  )
                ).isBefore(moment().subtract(1, "years"))) &&
              !this.appRatingModal &&
              positiveUserReview &&
              Capacitor.isNativePlatform()
            ) {
              this.appRatingModal = true;
              this.openAppRatingModal();
            }
          }
        });
    }
  }

  async openAppRatingModal() {
    this.appRatingModal = await this.modalController.create({
      component: AppRatingModalComponent,
      cssClass: "rateAppModal",
      backdropDismiss: false,
      componentProps: {
        noStoreReviewMode: true,
      },
    });
    await this.appRatingModal.present();
    await this.appRatingModal.onDidDismiss().then((data) => {
      this.appRatingModal = false;
      if (data && data.data && data.data === "yes") {
        this.rateAppMobileActions(
          this.platform.is("android")
            ? "android"
            : this.platform.is("ios")
            ? "ios"
            : null
        );
      }
    });
  }

  rateAppMobileActions(userHasToReviewTheApp) {
    if (
      this.groupApp &&
      this.groupApp.androidUrl &&
      userHasToReviewTheApp === "android"
    ) {
      RateApp.requestReview();
      this.currentUser.ratingAppDialogShownTimestamp = Date.now().toString();
      this.updateCustomerData(_.cloneDeep(this.currentUser));
    } else if (
      this.groupApp &&
      this.groupApp.iOSUrl &&
      userHasToReviewTheApp === "ios"
    ) {
      RateApp.requestReview();
      this.currentUser.ratingAppDialogShownTimestamp = Date.now().toString();
      this.updateCustomerData(_.cloneDeep(this.currentUser));
    } else {
      console.log("no android or ios urls");
    }
  }

  updateCustomerData(updatedUser) {
    this.dataStorageService
      .updateCustomerData({ updatedData: updatedUser }, this.mo_token)
      .pipe(
        catchError((err) => {
          console.log("error", err);
          return err;
        })
      )
      .subscribe((res: any) => {
        if (!res && !res.succees) {
          if (res.comment_id) {
            this.presentErrorToast(
              this.translateService.instant(res.comment_id),
              "danger"
            );
          } else if (res.comments) {
            this.presentErrorToast(res.comments, "danger");
          }
        } else {
          //console.log("writing user to state");
          this.store.dispatch(
            new AuthActions.SetUser(_.cloneDeep(updatedUser))
          );
          this.store.dispatch(
            new manuallyTriggerFinalPriceActions.SetTriggerCheckAddressTimestamp(
              Date.now().toString()
            )
          );
        }
      });
  }

  async openCustomerNotifictaionsOrderModal(current_user, mo_token) {
    this.customerNotificationsOrderModal = await this.modalController.create({
      cssClass: "customerPrefsModal",
      component: OrderCustomerNotificationsModalComponent,
      componentProps: {
        currentUser: current_user,
        mo_token: mo_token,
      },
    });
    this.customerNotificationsOrderModal.present();
    this.customerNotificationsOrderModal.onDidDismiss().then((data) => {
      this.customerNotificationsOrderModal = null;
      if (data && data.data) {
        if (data.data === "success") {
          if (
            this.currentUser &&
            this.currentUser.alertOrder &&
            this.currentUser.alertOrder.push
          ) {
            console.log("ask permission for one signal");
            this.oneSignalService.OneSignalPrompt();
          }
        }
      } else {
        if (
          this.currentUser &&
          this.currentUser.alertOrder &&
          this.currentUser.alertOrder.push
        ) {
          console.log("ask permission for one signal");
          this.oneSignalService.OneSignalPrompt();
        }
      }
    });
  }

  getUserOrders(mo_token) {
    this.store
      .select(selectors.getStoreInfo)
      .pipe(first())
      .subscribe((storeInfo) => {
        this.dataStorageService
          .getUserOrders(mo_token, storeInfo.vatBasedCustomerProductsActive)

          .subscribe({
            next: async (res: any) => {
              if (!res || !res.success) {
                let errorMsg = this.translateService.instant(
                  res.comments
                    ? res.comments
                    : "errorMessages-an-error-occurred"
                );

                this.presentErrorToast(errorMsg, "danger");
              } else {
                let orderArray = res.data;
                this.store.dispatch(
                  new OrdersActions.SetOrdersArray(orderArray)
                );
                this.store.dispatch(
                  new PendingOrdersActions.CheckPendingOrderFromFetch(
                    orderArray
                  )
                );
              }
            },
            error: async (err) => {
              const alert = await this.alertController.create({
                header: this.translateService.instant("alert"),
                message: this.translateService.instant(
                  "errorMessages.problem_reaching_server"
                ),
                buttons: ["OK"],
              });
              await alert.present();
              return err;
            },
          });
      })
      .unsubscribe();
  }

  getCurrentUser() {
    return new Promise((resolve, reject) => {
      this.store
        .select("auth")
        .pipe(first())
        .subscribe((authState) => {
          if (authState && authState.user) {
            if (!this.currentUser) {
              console.log("getCurrentUser");
              this.currentUser = _.cloneDeep(authState.user);
              this.getUserOrders(authState.mo_token);

              this.fetchCustomerPreviousOrders();
            }
            resolve({});
          } else {
            resolve({});
          }
        })
        .unsubscribe();
    });
  }

  trackByMethod(index, order) {
    return order.order_id.toString() + "_" + order.store_id.toString();
    // We should not enter enything else than the order_id, because we just need to watch the creation / deletion of an element in the list.
    // The changes inside each item are handled by the specific component not by the list!
  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }

  async presentOrderNowModal() {
    this.store.dispatch(
      new DeliveryMethodAddressActions.SetNavigationOnDeliveryMethodChanged(
        true
      )
    );
    if (!this.showPickupButton && !this.showDineInButton) {
      this.orderNowModalPopupsService.checkAndOpenSettingsModal(
        "delivery",
        this.currentUser,
        this.selectedAddress,
        this.stores,
        this.currentStoreId,
        true,
        false
      );
    } else if (
      !this.showPickupButton &&
      !this.showDeliveryButton &&
      !this.infoShowDeliveryButton
    ) {
      this.orderNowModalPopupsService.checkAndOpenSettingsModal(
        "dine-in",
        this.currentUser,
        this.selectedAddress,
        this.stores,
        this.currentStoreId,
        true,
        false
      );
    } else if (
      !this.showDineInButton &&
      !this.showDeliveryButton &&
      !this.infoShowDeliveryButton
    ) {
      this.orderNowModalPopupsService.checkAndOpenSettingsModal(
        "take-away",
        this.currentUser,
        this.selectedAddress,
        this.stores,
        this.currentStoreId,
        true,
        false
      );
    } else {
      this.orderNowModal = await this.modalController.create({
        component: OrderNowPopupComponent,
        cssClass: "orderNowPopupCss",
        backdropDismiss: true,
      });

      await this.orderNowModal.present();
      this.orderNowModal.onDidDismiss().then(() => {
        this.orderNowModal = null;
      });
    }
  }

  async presentSelectStoreModal() {
    if (this.groupApp && this.groupApp.images_select_store_mobileorder) {
      this.selectStoreModal = await this.modalController.create({
        component: SelectStoreImagePopupComponent,
        cssClass: "selectStoreImagePopup",
        backdropDismiss: true,
      });

      await this.selectStoreModal.present();
      this.selectStoreModal.onDidDismiss().then(() => {
        this.selectStoreModal = null;
      });
    } else {
      let cssClass = "selectStorePopupCss";
      if (this.stores && this.stores.length > 1 && this.stores.length <= 3) {
        cssClass = "selectStorePopupCssThreeStores";
      } else if (this.stores && this.stores.length > 3) {
        cssClass = "selectStorePopupCssMultipleStores";
      }
      this.selectStoreModal = await this.modalController.create({
        component: SelectStorePopupComponent,
        cssClass: cssClass,
        backdropDismiss: false,
      });

      await this.selectStoreModal.present();
      this.selectStoreModal.onDidDismiss().then(() => {
        this.selectStoreModal = null;
      });
    }
  }

  checkTheCatalog() {
    if (
      this.stores &&
      this.stores.length > 1 &&
      this.orderPickup == null &&
      this.dineIn == null
    ) {
      this.presentSelectStoreModal();
    } else {
      // this.store.dispatch(
      //   new CurrentStoreActions.SetCurrentStoreId(this.stores[0].store_id)
      // );
      // if (navigator.cookieEnabled) {
      //   window.localStorage.setItem("store_id", this.currentStoreId);
      // }

      this.router.navigateByUrl("/catalog", {
        replaceUrl: true,
      });
    }
  }

  fetchCustomerPreviousOrders() {
    console.log("fetchPreviousOrders");
    this.store
      .select("auth")
      .pipe(first())
      .subscribe((authState) => {
        if (authState) {
          this.store
            .select(selectors.getStoreInfo)
            .pipe(first())
            .subscribe((store_info) => {
              if (store_info && store_info.store_id) {
                this.store
                  .select("groupApp")
                  .pipe(first())
                  .subscribe((state) => {
                    if (state && state.selectedLangugage) {
                      this.store
                        .select("deliveryMethodAddress")
                        .pipe(first())
                        .subscribe((deliveryAddressState) => {
                          let preorderData;
                          if (
                            deliveryAddressState &&
                            deliveryAddressState.selectedPreorderDate &&
                            deliveryAddressState.selectedPreorderDate.date &&
                            deliveryAddressState.selectedPreorderDate.hour
                          ) {
                            preorderData = {
                              preorder: true,
                              preorder_day:
                                deliveryAddressState.selectedPreorderDate.date,
                              preorder_time:
                                deliveryAddressState.selectedPreorderDate.hour,
                            };
                          } else {
                            preorderData = null;
                          }

                          this.dataStorageService
                            .fetchCustomerPreviousOrders(
                              store_info.store_id,
                              state.selectedLangugage,
                              "",
                              authState.mo_token,
                              deliveryAddressState.orderPickup,
                              deliveryAddressState.dineIn,
                              preorderData,
                              deliveryAddressState &&
                                deliveryAddressState.selectedDineInTable
                                ? deliveryAddressState.selectedDineInTable
                                    .table_id
                                : null,
                              store_info.vatBasedCustomerProductsActive,
                              authState && authState.migration_customer_id
                                ? authState.migration_customer_id
                                : null
                            )
                            .subscribe({
                              next: (res: any) => {
                                if (res && res.success) {
                                  this.store.dispatch(
                                    new PreviousOrdersActions.SetPreviousOrders(
                                      res.orders
                                    )
                                  );
                                  this.store.dispatch(
                                    new PreviousOrdersActions.SetOnPreorderChangeFetchAgain(
                                      res.fetchInPreorderChanges || null
                                    )
                                  );
                                } else {
                                  console.log("error getting prev orders", res);
                                }
                              },
                              error: (error) => {
                                console.log("error getting prev orders", error);
                                return error;
                              },
                            });
                        })
                        .unsubscribe();
                    }
                  })
                  .unsubscribe();
              }
            })
            .unsubscribe();
        }
      })
      .unsubscribe();
  }

  animationCreated(ev) {
    this.orderCompletedAnimationInstance = ev;
    this.orderCompletedAnimationInstance.setSpeed(0.6);
  }

  animationComplete(ev) {
    this.orderCompletedAnimationInstance.stop();
    this.orderCompletedAnimation = false;
    this.changeDetector.detectChanges();
  }

  canceledAnimationCreated(ev) {
    this.orderCanceledAnimationInstance = ev;
    this.orderCanceledAnimationInstance.setSpeed(0.8);
  }

  canceledAnimationComplete(ev) {
    this.orderCanceledAnimationInstance.stop();
    this.orderCanceledAnimation = false;
    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }

    if (this.customerNotificationsOrderModal) {
      this.customerNotificationsOrderModal.dismiss();
      this.customerNotificationsOrderModal = null;
    }

    if (this.appRatingModal) {
      this.appRatingModal.dismiss();
      this.appRatingModal = null;
    }

    if (this.orderNowModal) {
      this.orderNowModal.dismiss();
      this.orderNowModal = null;
    }
    if (this.selectStoreModal) {
      this.selectStoreModal.dismiss();
      this.selectStoreModal = null;
    }
    if (this.parametersSubscription) {
      this.parametersSubscription.unsubscribe();
      this.parametersSubscription = null;
    }
  }

  ionViewDidLeave() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    this.paymentGatewaysService.closeAllModals();
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }

    if (this.customerNotificationsOrderModal) {
      this.customerNotificationsOrderModal.dismiss();
      this.customerNotificationsOrderModal = null;
    }

    if (this.orderNowModal) {
      this.orderNowModal.dismiss();
      this.orderNowModal = null;
    }
    if (this.selectStoreModal) {
      this.selectStoreModal.dismiss();
      this.selectStoreModal = null;
    }
    if (this.parametersSubscription) {
      this.parametersSubscription.unsubscribe();
      this.parametersSubscription = null;
    }
    if (this.queryParametersSubscription) {
      this.queryParametersSubscription.unsubscribe();
      this.queryParametersSubscription = null;
    }
  }
}
