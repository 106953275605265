
<ion-content>
  <ion-grid>
    <p>
      Αγορά: {{product.timestamp | date: 'dd/MM, HH:mm'}}
    </p>
    <p>
      Λήξη: {{product.expiration_date | date: 'dd/MM, HH:mm'}}
    </p>
  </ion-grid>
</ion-content>