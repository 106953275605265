import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import { Subscription } from "rxjs";
import { audit, auditTime, distinctUntilChanged } from "rxjs/operators";
import * as fromApp from "../../../../../../store/app.reducer";
import * as selectors from "../../../../../../store/app.selectors";

@Component({
  selector: "app-no-quantity-checkbox-choice",
  templateUrl: "./no-quantity-checkbox-choice.component.html",
  styleUrls: ["./no-quantity-checkbox-choice.component.scss"],
})
export class NoQuantityCheckboxChoiceComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() selectedProductId: any;
  @Input() bundleMode: any;
  @Input() option_id;
  @Input() choice_id;
  @Input() selectedProductPromo: any;
  @Input() selectedProductCategory: any;
  @Input() alt_dinein_active: any;
  @Input() groupStoreData: any;
  public showChoicePrices: boolean = true;
  @Output() choiceChanged = new EventEmitter();
  public choice: any;
  public option: any;
  public orderPickup: any;
  public dineIn: any;
  public selectedDineInTable: any;

  private selectedProduct: any;
  private optionSubscription: Subscription;
  private deliveryMethodAddressSub: Subscription;

  constructor(private store: Store<fromApp.AppState>) {}

  selectOption() {
    if (this.optionSubscription) {
      this.optionSubscription.unsubscribe();
    }
    this.optionSubscription = this.store
      .select(
        selectors.getOptionByOptionId(this.selectedProductId, this.option_id)
      )
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (!_.isEqual(this.option, state)) {
          this.option = _.cloneDeep(state);
          if (
            this.option &&
            this.option.freeChoices &&
            this.option.freeChoices !== "0"
          ) {
            if (this.option.optionData && this.option.optionData.freeChoices) {
              this.showChoicePrices = true;
            } else {
              this.showChoicePrices = false;
            }
          } else {
            this.showChoicePrices = true;
          }
          if (this.option && this.option.choices) {
            let choiceIndex = _.findIndex(this.option.choices, {
              choice_id: this.choice_id,
            });
            if (
              choiceIndex !== -1 &&
              !_.isEqual(this.choice, this.option.choices[choiceIndex])
            ) {
              this.choice = this.option.choices[choiceIndex];
            }
          }
        }
      });
  }

  ngOnChanges(simpleChange: SimpleChanges) {
    if (
      simpleChange &&
      simpleChange.option_id &&
      simpleChange.option_id.currentValue !==
        simpleChange.option_id.previousValue
    ) {
      this.selectOption();
    }
  }

  ngOnInit() {
    this.deliveryMethodAddressSub = this.store
      .select("deliveryMethodAddress")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (state && !_.isEqual(state.orderPickup, this.orderPickup)) {
          this.orderPickup = _.cloneDeep(state.orderPickup);
        }
        if (state && !_.isEqual(state.dineIn, this.dineIn)) {
          this.dineIn = _.cloneDeep(state.dineIn);
        }

        if (
          state &&
          !_.isEqual(state.selectedDineInTable, this.selectedDineInTable)
        ) {
          this.selectedDineInTable = _.cloneDeep(state.selectedDineInTable);
        }
      });

    this.selectOption();
    // this.choiceSubscription = this.store
    //   .select(
    //     selectors.getChoiceByOptionIdChoiceId(this.option_id, this.choice_id)
    //   )
    //   .pipe(distinctUntilChanged())
    //   .subscribe((state) => {
    //     console.log("choice subscriptions selector");
    //     if (!_.isEqual(this.choice, state)) {
    //       console.log("issssssssssssssssssssssss equal");
    //       this.choice = _.cloneDeep(state);
    //       console.log("this choice", this.choice);
    //     }
    //   });
  }

  // checkMaximumChoices() {
  //   //I need to calculate here the choice quantity!

  //   var optionsMaxChoices;
  //   var counter = 0;

  //   if (this.option && this.option.maxChoices) {
  //     optionsMaxChoices = parseInt(this.option.maxChoices);
  //   }
  //   console.log("optionMaxChoices", optionsMaxChoices);
  //   if (!_.isNaN(optionsMaxChoices) && optionsMaxChoices > 0) {
  //     _.each(_.sortBy(this.option.choices, "price"), (choice, key, list) => {
  //       if (
  //         choice.is_preselected &&
  //         choice.activeTotal &&
  //         choice.availableTotal
  //       ) {
  //         counter = counter + choice.quantity;
  //         console.log("counter", counter);
  //       }
  //     });
  //     console.log(counter, optionsMaxChoices);
  //     if (counter >= optionsMaxChoices) {
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   } else {
  //     return true;
  //   }
  // }

  onCheckboxChanged(ev) {
    if (document.activeElement.tagName === "ION-CHECKBOX") {
      console.log("checkbox change");

      if (
        this.option &&
        this.option.freeChoices &&
        this.option.freeChoicesBySelectionOrder
      ) {
        if (this.choice.is_preselected) {
          this.choice.selected_timestamp = Date.now().toString();
        } else {
          delete this.choice.selected_timestamp;
        }
      }

      this.choiceChanged.emit(this.choice);
    }
  }

  ngOnDestroy() {
    if (this.optionSubscription) {
      this.optionSubscription.unsubscribe();
    }
    if (this.deliveryMethodAddressSub) {
      this.deliveryMethodAddressSub.unsubscribe();
    }
  }
}
