<ion-item
  *ngIf="
    option &&
    option.title &&
    (!do_not_show_comments_option ||
      !(option.default || option.template === 'comments'))
  "
  lines="none"
>
  <ion-label
    *ngIf="option.default || option.template === 'comments'"
    class="ion-text-wrap"
    position="stacked"
    >{{ "comments_option" | translate }}</ion-label
  >
  <ion-label
    *ngIf="!option.default && option.template !== 'comments'"
    class="ion-text-wrap"
    position="stacked"
    >{{ option.title }}</ion-label
  >

  <ion-textarea
    [placeholder]="'product-details.write-here' | translate"
    (ionChange)="fieldOptionChange($event)"
    [(ngModel)]="option.text"
    debounce="200"
  ></ion-textarea>
</ion-item>
