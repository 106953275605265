import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";
import * as _ from "lodash";
import { auditTime, distinctUntilChanged, sample } from "rxjs/operators";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

import { SelectStorePopupComponent } from "src/app/popups/select-store-popup/select-store-popup.component";
import { ModalController } from "@ionic/angular";
import { PaymentGatewaysService } from "src/app/services/payment-gateways/payment-gateways.service";
import * as selectors from "../../store/app.selectors";
import * as CompleteOrderValidationActions from "../../store/complete-order-validation/completeOrderValidation.actions";

@Component({
  selector: "app-reservation-select-payment-method-modal",
  templateUrl: "./reservation-select-payment-method-modal.component.html",
  styleUrls: ["./reservation-select-payment-method-modal.component.scss"],
})
export class ReservationSelectPaymentMethodModalComponent implements OnInit {
  public cartPaymentGateWays: any;
  public storesByStoreId: any;
  public paymentGateways: any;
  public cart: any;
  public currentUser: any;
  public currentStoreId: string;
  public customerInvoiceEnabled: boolean;
  public subscriptions: Subscription[] = [];
  public selectedPaymentMethodID: any;
  public finalPriceLoading: boolean;
  public disableAllPaymentSelections: boolean;
  public valid: boolean = true;
  public darkMode: boolean;
  public storeInfo: any;
  public groupApp: any;
  private selectedTheme: any;
  private lastValidTimestamp: any;
  private checkboxPromotions: any;
  private stores: any;

  constructor(
    private store: Store<fromApp.AppState>,
    private translateService: TranslateService,
    private modalCtrl: ModalController,
    private paymentGatewaysService: PaymentGatewaysService
  ) {}

  closeModal() {
    this.modalCtrl.dismiss();
  }

  ngOnInit() {
    //TODO HANDLE BACK BUTTON HERE
    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((storeInfo) => {
          if (storeInfo && !_.isEqual(this.storeInfo, storeInfo)) {
            this.storeInfo = _.cloneDeep(storeInfo);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(state.groupApp, this.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
          }

          if (
            state &&
            state.selectedTheme &&
            !_.isEqual(this.selectedTheme, state.selectedTheme)
          ) {
            this.selectedTheme = _.cloneDeep(state.selectedTheme);
            //console.log("selected theme", this.selectedTheme);
            if (this.selectedTheme === "dark") {
              this.darkMode = true;
            } else {
              this.darkMode = false;
            }
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("completeOrderValidation")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.lastValidTimestamp &&
            !_.isEqual(this.lastValidTimestamp, state.lastValidTimestamp)
          ) {
            this.lastValidTimestamp = _.cloneDeep(state.lastValidTimestamp);
            this.paymentMethodValidationsCart(true);
          }
        })
    );
    this.subscriptions.push(
      this.store.select("disableFinalPrice").subscribe((state) => {
        if (
          state &&
          !_.isEqual(state.disableFinalPrice, this.finalPriceLoading)
        ) {
          this.finalPriceLoading = _.cloneDeep(state.disableFinalPrice);
        }
      })
    );
    this.subscriptions.push(
      this.store.select("selectedPaymentMethodID").subscribe((state) => {
        if (
          state &&
          state.selectedPaymentMethodID &&
          !_.isEqual(
            state.selectedPaymentMethodID,
            this.selectedPaymentMethodID
          )
        ) {
          this.selectedPaymentMethodID = _.cloneDeep(
            state.selectedPaymentMethodID
          );
          this.paymentMethodValidationsCart(false);
        }
      })
    );
    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(auditTime(200))
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && state.user && !_.isEqual(this.currentUser, state.user)) {
            this.currentUser = _.cloneDeep(state.user);
          }
        })
    );

    this.subscriptions.push(
      this.store.select("stores").subscribe((state) => {
        if (state && state.stores && !_.isEqual(state.stores, this.stores)) {
          this.stores = _.cloneDeep(state.stores);
          this.storesByStoreId = _.groupBy(this.stores, "store_id");
          _.each(this.storesByStoreId, (store, key, list) => {
            this.storesByStoreId[key] = store[0];
          });
        }
      })
    );

    this.subscriptions.push(
      this.store.select("currentStore").subscribe((state) => {
        if (
          state &&
          state.currentStoreId &&
          !_.isEqual(state.currentStoreId, this.currentStoreId)
        ) {
          this.currentStoreId = _.cloneDeep(state.currentStoreId);
        }
      })
    );

    this.subscriptions.push(
      this.store
        .select("paymentGateWays")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(50))
        .subscribe((state) => {
          if (
            state &&
            state.paymentGateWays &&
            !_.isEqual(this.paymentGateways, state.paymentGateWays)
          ) {
            this.paymentGateways = _.cloneDeep(state.paymentGateWays);
            //  console.log("state payment gateways", this.paymentGateways);
          }
          if (
            state &&
            state.cartPaymentGateWays &&
            !_.isEqual(this.cartPaymentGateWays, state.cartPaymentGateWays)
          ) {
            this.cartPaymentGateWays = _.cloneDeep(state.cartPaymentGateWays);
            // console.log("carttt payment gateways", this.paymentGateways);
          }
        })
    );
    this.checkForOnlineOnlyPaymentPromos();
  }

  paymentMethodValidationsCart(writeToState) {
    let validationObj = {
      id: "payments-methods",
      valid: true,
      errorMessages: [],
    };
    this.valid = true;
    if (!this.selectedPaymentMethodID) {
      validationObj.valid = false;
      validationObj.errorMessages.push(
        this.translateService.instant(
          "cart.cart-components.payment-methods.please-select-payment-method"
        )
      );
      this.valid = false;
    }
    if (writeToState) {
      this.store.dispatch(
        new CompleteOrderValidationActions.AddUpdateCompleteOrderValidation(
          _.cloneDeep(validationObj)
        )
      );
    }
  }

  selectPaymentMethodRadio(ev) {
    let id = this.selectedPaymentMethodID;

    if (document.activeElement.tagName === "ION-RADIO") {
      this.modalCtrl.dismiss(_.cloneDeep(this.selectedPaymentMethodID));
    }
  }

  checkForOnlineOnlyPaymentPromos() {
    this.subscriptions.push(
      this.store
        .select("checkboxPromotions")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            state.checkboxPromotions &&
            !_.isEqual(this.checkboxPromotions, state.checkboxPromotions)
          ) {
            this.checkboxPromotions = _.cloneDeep(state.checkboxPromotions);

            if (this.checkboxPromotions && this.checkboxPromotions.length > 0) {
              this.disableAllPaymentSelections = false;
              _.each(this.checkboxPromotions, (promo) => {
                if (promo.onlinePaymentOnly && promo.promoCheckbox) {
                  this.disableAllPaymentSelections = true;
                  if (this.paymentGateways && this.paymentGateways.length > 0) {
                    _.each(this.paymentGateways, (gateway) => {
                      if (gateway.id !== "paypal" && gateway.id !== "klarna") {
                        this.selectedPaymentMethodID = _.cloneDeep(gateway.id);
                        return;
                      }
                    });
                  }
                }
              });
            }
          }
        })
    );
  }

  checkForDisablingSomeMethods(key, method, ev) {
    if (
      this.disableAllPaymentSelections &&
      (key == "cash" ||
        key === "mobilePOS" ||
        key === "paypal" ||
        key == "klarna")
    ) {
      return true;
    } else {
      if (method.disable_payment_gateway) {
        return true;
      } else {
        return false;
      }
    }
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }
}
