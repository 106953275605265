<ion-header>
  <ion-toolbar mode="md" id="toolbar">
    <ion-buttons slot="end">
      <ion-button color="dark" (click)="closeModal()" fill="clear">
        <ion-icon src="assets/ionicons/close.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title size="small" *ngIf="option">
      {{ option.title }}
    </ion-title>
    <div
      style="padding: 5px"
      *ngIf="validationObject && validationObject.errorArray.length"
    >
      <ion-badge color="danger">
        <ion-text>{{ validationObject.errorArray[0] }}</ion-text>
      </ion-badge>
    </div>
  </ion-toolbar>
  <ion-row style="width: 100%">
    <ion-col class="ion-text-right"> </ion-col>
  </ion-row>

  <ion-label class="ion-text-wrap" style="font-size: small; font-weight: 700">
    <ion-text
      *ngIf="option && option.freeChoices && option.freeChoices !== '0'"
    >
      <p style="margin-left: 10px">
        <ion-text
          [color]="
            option.optionData &&
            option.optionData.freeChoices &&
            option.optionData.freeChoices === option.freeChoices
              ? 'complete'
              : 'danger'
          "
        >
          {{
            "product-details.options.checkbox-options.there-are-free-choices"
              | translate
          }}: ({{
            option.optionData && option.optionData.freeChoices
              ? option.optionData.freeChoices
              : 0
          }}
          / {{ option.freeChoices }})
        </ion-text>
      </p>
      <div
        style="font-size: small; font-weight: bold"
        size="12"
        *ngIf="
          option && option.freeChoices && option.freeChoicesBySelectionOrder
        "
      >
        <ion-text color="danger">
          {{
            "product-details.options.free-options-by-selections-order"
              | translate
          }}
        </ion-text>
      </div>
    </ion-text>

    <ion-text
      *ngIf="
        option &&
        option.is_require &&
        option.minChoices &&
        option.minChoices !== '0' &&
        option.minChoices !== '1' &&
        option.maxChoices !== option.minChoices
      "
      color="dark"
    >
      <p style="margin-left: 10px">
        <ion-text>
          {{
            "product-details.options.checkbox-options.minimum-choices-required"
              | translate
          }}: ({{
            option.optionData && option.optionData.minChoices
              ? option.optionData.minChoices
              : 0
          }}
          / {{ option.minChoices }})
        </ion-text>
      </p>
    </ion-text>

    <ion-text
      *ngIf="
        option &&
        option.maxChoices &&
        option.maxChoices !== '0' &&
        option.maxChoices !== option.minChoices
      "
      color="dark"
    >
      <p style="margin-left: 10px">
        <ion-text>
          {{
            "product-details.options.checkbox-options.maximum-choices-allowed"
              | translate
          }}: ({{
            option.optionData && option.optionData.maxChoices
              ? option.optionData.maxChoices
              : 0
          }}
          / {{ option.maxChoices }})
        </ion-text>
      </p>
    </ion-text>

    <ion-text
      *ngIf="
        option &&
        option.is_require &&
        option.minChoices &&
        option.minChoices !== '0' &&
        option.minChoices !== '1' &&
        option.maxChoices &&
        option.maxChoices !== '0' &&
        option.maxChoices === option.minChoices
      "
      color="dark"
    >
      <p style="margin-left: 10px">
        {{ option.maxChoices }}
        {{
          "product-details.options.checkbox-options.choices-required"
            | translate
        }}: ({{
          option.optionData && option.optionData.minChoices
            ? option.optionData.minChoices
            : 0
        }}
        / {{ option.minChoices }})
      </p>
    </ion-text>
    <div
      style="font-size: small; float: right; font-weight: bold"
      *ngIf="
        option &&
        option.choices &&
        option.showSumChoicesWeight &&
        choicesSumWeight &&
        quantityInChoicesKind
      "
    >
      <ion-text style="font-weight: bold">{{ "total" | translate }}:</ion-text>
      {{ choicesSumWeight }} {{ quantityInChoicesKind }}
    </div>
  </ion-label>
</ion-header>

<div
  id="divContent"
  class="inner-content"
  *ngIf="windowDimensions && windowDimensions.width >= 600"
>
  <app-checkbox-choices
    *ngIf="option && option.choices && option.choices.length"
    [option_id]="option_id"
    [selectedProductPromo]="selectedProductPromo"
    [selectedProductCategory]="selectedProductCategory"
    [selectedProductId]="selectedProductId"
    [alt_dinein_active]="alt_dinein_active"
    [bundleMode]="bundleMode"
    [groupStoreData]="groupStoreData"
    (choicesChanged)="optionChanged($event)"
  ></app-checkbox-choices>
</div>

<ion-content
  id="ionicContent"
  *ngIf="windowDimensions && windowDimensions.width < 600"
>
  <app-checkbox-choices
    *ngIf="option && option.choices && option.choices.length"
    [option_id]="option_id"
    [selectedProductPromo]="selectedProductPromo"
    [selectedProductCategory]="selectedProductCategory"
    [selectedProductId]="selectedProductId"
    [alt_dinein_active]="alt_dinein_active"
    [bundleMode]="bundleMode"
    [groupStoreData]="groupStoreData"
    (choicesChanged)="optionChanged($event)"
  ></app-checkbox-choices>
</ion-content>

<ion-footer>
  <ion-row style="width: 100%">
    <ion-col size="12" class="ion-text-center">
      <ion-button (click)="selectionsOk()">OK</ion-button>
    </ion-col>
  </ion-row>
</ion-footer>
