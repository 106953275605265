import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-category-item-tags",
  templateUrl: "./category-item-tags.component.html",
  styleUrls: ["./category-item-tags.component.scss"],
})
export class CategoryItemTagsComponent implements OnInit {
  @Input() categoryItem: any;
  @Input() groupStoreData: any;

  constructor() {}

  ngOnInit() {}
}
