import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import {
  AlertController,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { Store } from "@ngrx/store";
import { auditTime, distinctUntilChanged, Subscription } from "rxjs";
import * as fromApp from "../../store/app.reducer";
import * as _ from "lodash";
import { TranslateService } from "@ngx-translate/core";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import { Loader } from "@googlemaps/js-api-loader";
import mapStyleJson from "../../../assets/mapStyle/mapStyle.json";
import { DeliveryAreasInfoModalComponent } from "src/app/delivery-areas/delivery-areas-info-modal/delivery-areas-info-modal.component";

@Component({
  selector: "app-not-valid-address-modal",
  templateUrl: "./not-valid-address-modal.component.html",
  styleUrls: ["./not-valid-address-modal.component.scss"],
})
export class NotValidAddressModalComponent implements OnInit, OnDestroy {
  @Input() notValidAddress: any;
  public loader: any;
  public libraryLoading: boolean = false;
  public loading: boolean = false;
  public map: any;
  public marker: any;
  public deliveryAreas: any;
  private groupApp: any;
  private stores: any;
  private selectedLanguage: any;
  private subs: Subscription[] = [];
  private deliveryAreaInfoModal: any;
  private unsubscribeBackEvent: Subscription;

  constructor(
    private modalController: ModalController,
    private store: Store<fromApp.AppState>,
    private changeDetector: ChangeDetectorRef,
    private toastController: ToastController,
    private translateService: TranslateService,
    private dataStorageService: DataStorageService,
    private alertController: AlertController,
    private platform: Platform
  ) {}

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      300,
      () => {
        if (this.platform.is("android")) {
          this.modalController.dismiss();
        }
      }
    );
    this.subs.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(this.groupApp, state.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
            if (this.groupApp && this.selectedLanguage && this.stores) {
              console.log("creting map script");
              this.checkMarker();
            }
          }
          if (
            state &&
            state.selectedLangugage &&
            this.groupApp &&
            !_.isEqual(this.selectedLanguage, state.selectedLangugage)
          ) {
            this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
            if (this.groupApp && this.selectedLanguage && this.stores) {
              console.log("creting map script");
              this.checkMarker();
            }
          }
        })
    );

    this.subs.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (state && state.stores && !_.isEqual(state.stores, this.stores)) {
            this.stores = _.cloneDeep(state.stores);
            if (this.groupApp && this.selectedLanguage && this.stores) {
              console.log("creting map script");
              this.checkMarker();
            }
          }
        })
    );
  }

  trackDeliveryAreas(index, item) {
    return index;
  }

  checkMarker() {
    this.loading = true;
    const data = {
      lat: this.notValidAddress.userLat,
      lng: this.notValidAddress.userLng,
      group: this.groupApp.group,
      languageCode: this.selectedLanguage,
    };

    this.dataStorageService
      .checkMarker(data)

      .subscribe({
        next: async (res: any) => {
          if (res && res.success) {
            this.deliveryAreas = res.deliveryAreas;
            this.createMapsScript();
            this.loading = false;
            this.changeDetector.detectChanges();
          } else {
            const alert = await this.alertController.create({
              header: this.translateService.instant("alert"),
              message:
                res && res.comment_id
                  ? this.translateService.instant(res.comment_id)
                  : this.translateService.instant(
                      "errorMessages.general_error"
                    ),

              buttons: ["OK"],
            });
            await alert.present();
            this.loading = false;

            this.changeDetector.detectChanges();
          }
          console.log("res", res);

          this.changeDetector.detectChanges();
        },
        error: async (err) => {
          const alert = await this.alertController.create({
            header: this.translateService.instant("alert"),
            message: this.translateService.instant(
              "errorMessages.problem_reaching_server"
            ),

            buttons: ["OK"],
          });
          await alert.present();
          this.loading = false;

          this.changeDetector.detectChanges();
          return err;

          console.log("error updating the information");
        },
      });
  }

  createMapsScript() {
    if (!document.getElementById("googleMapsLoader")) {
      this.loader = new Loader({
        id: "googleMapsLoader",
        apiKey: this.groupApp.googleMapsKey,
        language: this.selectedLanguage,
        libraries: ["geometry", "drawing", "places"],
      });
      this.loader.load().then(() => {
        console.log("library loaded");
        this.libraryLoading = false;
        this.changeDetector.detectChanges();
        this.mapInitializations();
      });
    } else {
      this.libraryLoading = false;
      this.changeDetector.detectChanges();
      this.mapInitializations();
      // this.loader = new Loader({
      //   id: "googleMapsLoader",
      //   apiKey: this.groupApp.googleMapsKey,
      //   libraries: ["geometry", "drawing", "places"],
      // });
    }
  }

  async openDeliveryAreaInfoModal(area) {
    this.deliveryAreaInfoModal = await this.modalController.create({
      component: DeliveryAreasInfoModalComponent,
      cssClass: "openDeliveryAreaInfoModal",
      backdropDismiss: false,
      componentProps: {
        currentDeliveryArea: area,
      },
    });
    await this.deliveryAreaInfoModal.present();

    this.deliveryAreaInfoModal.onDidDismiss().then(() => {
      this.deliveryAreaInfoModal = null;
    });
  }

  ondismiss() {
    this.modalController.dismiss();
  }

  mapInitializations() {
    this.map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        zoom: parseInt(this.groupApp.groupMapZoom),
        gestureHandling: "greedy",
        center: {
          lat: parseFloat(this.groupApp.groupMapCenterLat),
          lng: parseFloat(this.groupApp.groupMapCenterLng),
        },

        zoomControl: true,
        scaleControl: false,
        scrollwheel: true,
        disableDefaultUI: true,
        mapTypeControl: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles: mapStyleJson,
      }
    );
    this.deliveryAreasInitialization(this.map);

    this.marker = new google.maps.Marker({
      animation: google.maps.Animation.DROP,
      icon: "assets/ionicons/home2.png",

      map: this.map,
      anchorPoint: new google.maps.Point(0, -29),
      draggable: false,
    });
    this.map.setCenter({
      lat: this.notValidAddress.userLat,
      lng: this.notValidAddress.userLng,
    });
    this.map.setZoom(13);

    this.marker.setPosition({
      lat: this.notValidAddress.userLat,
      lng: this.notValidAddress.userLng,
    });
    this.marker.setVisible(true);
    this.changeDetector.detectChanges();
  }

  deliveryAreasInitialization(map) {
    _.each(this.stores, (store, key, list) => {
      if (_.isEmpty(store.deliveryAreas) && store.boundaryCoordinates1) {
        console.log("Creating the group's delivery areas...");
        console.log("How many stores the group has: ", this.stores.length);
        //Polygon coordinates
        var boundary = store.boundaryCoordinates1;
        var boundaryData = [];
        var latlngMap = boundary.split(",");
        var lat = [];
        var lng = [];
        for (var i = 0; i < latlngMap.length; i++) {
          if (i % 2 === 0) {
            lat.push(latlngMap[i]);
          } else {
            lng.push(latlngMap[i]);
          }
        }
        for (i = 0; i < latlngMap.length / 2; i++) {
          boundaryData.push(new google.maps.LatLng(lat[i], lng[i]));
        }

        new google.maps.Polygon({
          paths: boundaryData,
          strokeColor: "#0000FF",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FFCC00", //YEllOW
          fillOpacity: 0.3,
          zIndex: 10,
          map: map,
        });
      } else {
        console.log("The store has the new deliveryAreas", store.deliveryAreas);
        _.each(store.deliveryAreas, (area, k, l) => {
          if (!area.active) {
            return;
          }

          var boundary = area.boundaries;
          var boundaryData = [];
          var latlngMap = boundary.split(",");
          var lat = [];
          var lng = [];
          for (var i = 0; i < latlngMap.length; i++) {
            if (i % 2 === 0) {
              lat.push(parseFloat(latlngMap[i]));
            } else {
              lng.push(parseFloat(latlngMap[i]));
            }
          }
          for (i = 0; i < latlngMap.length / 2; i++) {
            boundaryData.push(new google.maps.LatLng(lat[i], lng[i]));
          }

          new google.maps.Polygon({
            paths: boundaryData,
            strokeColor: area.strokeColor ? area.strokeColor : "#0000FF",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: area.fillColor ? area.fillColor : "#FFCC00",
            fillOpacity: area.fillOpacity ? parseFloat(area.fillOpacity) : 0.3,
            zIndex: 10,
            map: map,
          });
        });
      }
    });
  }

  ngOnDestroy() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.deliveryAreaInfoModal) {
      this.deliveryAreaInfoModal.dismiss();
      this.deliveryAreaInfoModal = null;
    }
    if (this.subs && this.subs.length > 0) {
      this.subs.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    this.subs = [];
  }
}
