import * as CustomerPromotionsActions from "./customerPromotions.actions";
import * as _ from "lodash";

export interface State {
  customerPromotions: any;
}

const initialState: State = {
  customerPromotions: null,
};

export function customerPromotionsReducer(
  state = initialState,
  action: CustomerPromotionsActions.CustomerPromotionsActions
) {
  switch (action.type) {
    case CustomerPromotionsActions.SET_CUSTOMER_PROMOTIONS:
      return {
        ...state,
        customerPromotions: action.payload,
      };

    case CustomerPromotionsActions.CLEAR_CUSTOMER_PROMOTIONS:
      return {
        ...state,
        customerPromotions: null,
      };

    default:
      return state;
  }
}
