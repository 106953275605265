<span>
  <app-product-item-mobile-no-image
    *ngIf="!productImage"
    [product_id]="product_id"
    [category_id]="category_id"
    [hyperCategories]="hyperCategories"
    [cartProductIds]="cartProductIds"
    [groupStoreData]="groupStoreData"
    [selectedDineInTable]="selectedDineInTable"
    [orderPickup]="orderPickup"
    [dineIn]="dineIn"
    [favoriteProduct]="favoriteProduct"
    [selectedPreorderDate]="selectedPreorderDate"
  >
  </app-product-item-mobile-no-image>

  <app-product-item-mobile-image
    *ngIf="
      productImage &&
      !(
        groupStoreData &&
        groupStoreData.store_info &&
        groupStoreData.store_info.design_settings &&
        groupStoreData.store_info.design_settings.overFlowImageDesign
      )
    "
    [product_id]="product_id"
    [category_id]="category_id"
    [hyperCategories]="hyperCategories"
    [imageBrowser]="imageBrowser"
    [cartProductIds]="cartProductIds"
    [groupStoreData]="groupStoreData"
    [orderPickup]="orderPickup"
    [dineIn]="dineIn"
    [favoriteProduct]="favoriteProduct"
    [selectedDineInTable]="selectedDineInTable"
    [selectedPreorderDate]="selectedPreorderDate"
    [fromUnavailableProductsModal]="fromUnavailableProductsModal"
  >
  </app-product-item-mobile-image>

  <app-product-item-mobile-overflow-image
    *ngIf="
      productImage &&
      groupStoreData &&
      groupStoreData.store_info &&
      groupStoreData.store_info.design_settings &&
      groupStoreData.store_info.design_settings.overFlowImageDesign
    "
    [product_id]="product_id"
    [category_id]="category_id"
    [hyperCategories]="hyperCategories"
    [imageBrowser]="imageBrowser"
    [cartProductIds]="cartProductIds"
    [groupStoreData]="groupStoreData"
    [orderPickup]="orderPickup"
    [dineIn]="dineIn"
    [favoriteProduct]="favoriteProduct"
    [selectedDineInTable]="selectedDineInTable"
    [selectedPreorderDate]="selectedPreorderDate"
  >
  </app-product-item-mobile-overflow-image>
</span>
