import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  AlertController,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { Store } from "@ngrx/store";
import {
  auditTime,
  catchError,
  distinctUntilChanged,
  first,
  Subscription,
} from "rxjs";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import * as fromApp from "../../../store/app.reducer";
import * as _ from "lodash";
import { TranslateService } from "@ngx-translate/core";
import * as PreviousOrdersActions from "../../../catalog/previous-order/store/previousOrders.actions";

import { PreviousOrderModalComponent } from "src/app/catalog/previous-order/previous-order-modal/previous-order-modal.component";
import { OrderTimelineModalComponent } from "../../order-timeline-modal/order-timeline-modal.component";
import * as selectors from "../../../store/app.selectors";

@Component({
  selector: "app-order-item-details",
  templateUrl: "./order-item-details.component.html",
  styleUrls: ["./order-item-details.component.scss"],
})
export class OrderItemDetailsComponent implements OnInit, OnDestroy {
  @Input() order_id: any;
  @Input() store_id: any;
  public order: any;
  public mo_token: any;
  public orderPickup;
  public dineIn;
  public openTimelineModalTimestamp: any;
  public groupApp: any;
  public selectedLanguage: any;
  public selectedPreorderDate: any;
  public selectedDineInTable: any;
  public store_info;
  public selectedAddress;

  private previousOrderModal: any;
  private orderTimelineModal: any;

  private groupSub: Subscription;
  private deliveryMethodAddressSub: Subscription;
  private unsubscribeBackEvent: Subscription;
  private subscription: Subscription;
  private storeInfoSub: Subscription;

  constructor(
    private modalController: ModalController,
    private dataStorageService: DataStorageService,
    private store: Store<fromApp.AppState>,
    private translateService: TranslateService,
    private toastController: ToastController,
    private alertController: AlertController,
    private platform: Platform
  ) {}

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      200,
      () => {
        if (this.platform.is("android")) {
          if (this.orderTimelineModal) {
            this.orderTimelineModal = null;
            this.orderTimelineModal.dismiss();
          } else {
            this.closeModal();
          }
        }
      }
    );

    this.deliveryMethodAddressSub = this.store
      .select("deliveryMethodAddress")
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (state && !_.isEqual(state.orderPickup, this.orderPickup)) {
          this.orderPickup = _.cloneDeep(state.orderPickup);
        }
        if (state && !_.isEqual(state.dineIn, this.dineIn)) {
          this.dineIn = _.cloneDeep(state.dineIn);
        }

        if (
          state &&
          !_.isEqual(state.selectedDineInTable, this.selectedDineInTable)
        ) {
          this.selectedDineInTable = _.cloneDeep(state.selectedDineInTable);
        }
        if (
          state &&
          !_.isEqual(state.selectedPreorderDate, this.selectedPreorderDate)
        ) {
          this.selectedPreorderDate = _.cloneDeep(state.selectedPreorderDate);
        }
        if (state && !_.isEqual(state.selectedAddress, this.selectedAddress)) {
          this.selectedAddress = _.cloneDeep(state.selectedAddress);
        }
      });

    this.groupSub = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (
          state &&
          state.selectedLangugage &&
          !_.isEqual(state.selectedLangugage, this.selectedLanguage)
        ) {
          this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
        }
        if (
          state &&
          state.groupApp &&
          !_.isEqual(state.groupApp, this.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp);
        }
      });

    this.storeInfoSub = this.store
      .select(selectors.getStoreInfo)
      .pipe(distinctUntilChanged())
      .subscribe((store_info) => {
        if (store_info && !_.isEqual(store_info, this.store_info)) {
          this.store_info = _.cloneDeep(store_info);
        }
      });

    this.subscription = this.store
      .select("auth")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(50))
      .subscribe((state) => {
        if (state.mo_token && !_.isEqual(state.mo_token, this.mo_token)) {
          this.mo_token = _.cloneDeep(state.mo_token);
          this.fetchOrderDetails();
        }
      });
  }

  showOrderTimelineModal(timestamp) {
    if (!_.isEqual(timestamp, this.openTimelineModalTimestamp)) {
      this.openTimelineModalTimestamp = _.cloneDeep(timestamp);
      this.orderTimelineModal = true;
      this.presentOrderTimelineModal();
    }
  }

  fetchOrderDetails() {
    if (this.order_id && this.store_id && this.mo_token) {
      this.dataStorageService
        .getUserOrderDetails(this.mo_token, this.order_id, this.store_id)
        .pipe(
          catchError((err) => {
            console.log("error on getting orders", err);
            this.presentErrorToast("errorMessage.an_error_occured", "danger");
            return err;
          })
        )
        .subscribe({
          next: async (res: any) => {
            if (res && res.success) {
              console.log("success");
              this.order = res.data;
            } else {
              if (res && res.comment_id) {
                let errorMsg = this.translateService.instant(
                  "errorMessages-an-error-occurred"
                );
                this.presentErrorToast(errorMsg, "danger");
              } else if (res && res.comments) {
                this.presentErrorToast(res.comments, "danger");
              } else {
                const alert = await this.alertController.create({
                  header: this.translateService.instant("alert"),
                  message: this.translateService.instant(
                    "errorMessages.problem_reaching_server"
                  ),

                  buttons: ["OK"],
                });
                await alert.present();
              }
            }
          },
          error: async (err) => {
            const alert = await this.alertController.create({
              header: this.translateService.instant("alert"),
              message: this.translateService.instant(
                "errorMessages.problem_reaching_server"
              ),

              buttons: ["OK"],
            });
            await alert.present();
            return err;
          },
        });
    }
  }

  reorderOrder() {
    this.showPreviousOrderModal();
    let preorderData;
    let dinein_table_id;
    if (
      this.selectedPreorderDate &&
      this.selectedPreorderDate.date &&
      this.selectedPreorderDate.hour
    ) {
      preorderData = {
        preorder: true,
        preorder_day: this.selectedPreorderDate.date,
        preorder_time: this.selectedPreorderDate.hour,
      };
    }

    if (this.dineIn && this.selectedDineInTable) {
      dinein_table_id = this.selectedDineInTable.table_id;
    }

    this.store
      .select(selectors.getStoreInfo)
      .pipe(first())
      .subscribe((store_info) => {
        this.dataStorageService
          .fetchPreviousOrderProducts(
            this.order.store_id,
            this.order.order_id,
            this.selectedLanguage,
            this.groupApp.languages,
            this.mo_token,
            this.orderPickup,
            this.dineIn,
            preorderData,
            dinein_table_id,
            store_info.vatBasedCustomerProductsActive
          )
          .subscribe({
            next: (res: any) => {
              if (res && res.success) {
                console.log("response", res);
                this.store.dispatch(
                  new PreviousOrdersActions.SetPreviousOrderCart(
                    _.cloneDeep(res.products)
                  )
                );

                // this.store.dispatch(
                //   new PreviousOrdersActions.SetPreviousOrders(res.orders)
                // );
              } else if (res && !res.success) {
                if (res && res.comment_id) {
                  this.presentErrorToast(
                    this.translateService.instant(
                      "errorMessages." + res.comment_id
                    ),
                    "danger"
                  );
                } else if (res && res.comments) {
                  this.presentErrorToast(
                    this.translateService.instant(res.comments),
                    "danger"
                  );
                }
              } else if (!res) {
              }
            },
            error: (error) => {
              this.presentErrorToast(
                this.translateService.instant(
                  "errorMessages.problem_reaching_server"
                ),
                "danger"
              );
              return error;
            },
          });
      })
      .unsubscribe();
  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }

  showPreviousOrderModal() {
    this.previousOrderModal = true;
    this.presentPreviousOrderModal(this.order);
  }

  async presentPreviousOrderModal(order) {
    this.previousOrderModal = await this.modalController.create({
      component: PreviousOrderModalComponent,
      cssClass: "previousOrderModal",
      animated: true,
      componentProps: { previousOrderFromOrderPage: order },
      backdropDismiss: false,
    });

    await this.previousOrderModal.present();
    this.previousOrderModal.onDidDismiss().then(() => {
      this.previousOrderModal = null;
    });
  }

  async presentOrderTimelineModal() {
    this.orderTimelineModal = await this.modalController.create({
      component: OrderTimelineModalComponent,
      cssClass: "previousOrderModal",
      animated: true,
      componentProps: { order: _.cloneDeep(this.order) },
      backdropDismiss: false,
    });

    await this.orderTimelineModal.present();
    this.orderTimelineModal.onDidDismiss().then(() => {
      this.orderTimelineModal = null;
    });
  }

  closeModal() {
    this.modalController.dismiss();
  }

  ngOnDestroy() {
    if (this.orderTimelineModal) {
      this.orderTimelineModal = null;
      this.orderTimelineModal.dismiss();
    }

    if (this.previousOrderModal) {
      this.previousOrderModal = null;
      this.previousOrderModal.dismiss();
    }

    if (this.groupSub) {
      this.groupSub.unsubscribe();
    }
    if (this.deliveryMethodAddressSub) {
      this.deliveryMethodAddressSub.unsubscribe();
    }
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.storeInfoSub) {
      this.storeInfoSub.unsubscribe();
    }
  }
}
