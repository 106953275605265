import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../store/app.reducer";
import * as HyperCategoriesActions from "../../../store/hyperCategories/hyperCategories.actions";

import * as _ from "lodash";
import { audit, auditTime, distinctUntilChanged, first } from "rxjs/operators";
import { Subscription } from "rxjs";
import * as selectors from "../../../store/app.selectors";
import * as CategoriesSelectionActions from "../../categories/store/categoriesSelection.actions";

@Component({
  selector: "app-next-hyper-category-mobile",
  templateUrl: "./next-hyper-category-mobile.component.html",
  styleUrls: ["./next-hyper-category-mobile.component.scss"],
})
export class NextHyperCategoryMobileComponent implements OnInit, OnChanges {
  @Input() scrollFromTop: any;
  public hyperCategories: any;
  public selectedHyperCategory: any;
  public showButton;
  public firstTime: any = true;
  public nextHyperCategoryObject: any;
  public previousHyperCategoryObject: any;
  public nextCategoryObject;
  public previousCategoryObject;
  public storeInfo;
  public selectedCategoryId;

  private subscription: Subscription;
  private subscription3: Subscription;
  private storeSub: Subscription;
  constructor(private store: Store<fromApp.AppState>) {}

  ngOnChanges(changes: SimpleChanges): void {
    // if (
    //   changes &&
    //   changes.scrollFromTop &&
    //   changes.scrollFromTop.previousValue !==
    //     changes.scrollFromTop.currentValue &&
    //   changes.scrollFromTop.currentValue > 60
    // ) {
    //   console.log("scrollFromTop", this.scrollFromTop);
    //     this.showButton = true;
    // } else if (
    //   changes.scrollFromTop &&
    //   changes.scrollFromTop.currentValue &&
    //   changes.scrollFromTop.currentValue <= 60
    // ) {
    //   console.log("scrollFromTop", this.scrollFromTop);
    //   this.showButton = false;
    // }
  }

  ngOnInit() {
    this.storeSub = this.store
      .select(selectors.getStoreInfo)
      .pipe(distinctUntilChanged())
      .subscribe((storeInfo) => {
        if (storeInfo && !_.isEqual(this.storeInfo, storeInfo)) {
          this.storeInfo = _.cloneDeep(storeInfo);
          if (
            this.storeInfo &&
            this.storeInfo.design_settings &&
            this.storeInfo.design_settings.showOnlySelectedCategoryProducts
          ) {
            this.subscriptionToVerticalState();
          }
        }
      });

    //console.log("scroll from top", this.scrollFromTop);
    if (
      !this.scrollFromTop ||
      (this.scrollFromTop && this.scrollFromTop > 60)
    ) {
      this.showButton = true;
    }
    this.subscription = this.store
      .select("hyperCategories")
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (
          state &&
          state.hyperCategories &&
          !_.isEqual(this.hyperCategories, state.hyperCategories)
        ) {
          this.hyperCategories = _.cloneDeep(state.hyperCategories);
        }
        if (
          state &&
          state.currentHyperCategory &&
          !_.isEqual(this.selectedHyperCategory, state.currentHyperCategory)
        ) {
          this.selectedHyperCategory = _.cloneDeep(state.currentHyperCategory);
          if (this.showButton) {
            this.showButton = false;

            setTimeout(() => {
              this.showButton = true;
            }, 1000);
          }

          if (state.hyperCategories) {
            const selectedHyperCatIndex = _.findIndex(state.hyperCategories, {
              hyper_category_id: this.selectedHyperCategory,
            });
            if (
              selectedHyperCatIndex !== -1 &&
              state.hyperCategories.length > selectedHyperCatIndex + 1
            ) {
              this.nextHyperCategoryObject = _.cloneDeep(
                state.hyperCategories[selectedHyperCatIndex + 1]
              );
            } else {
              this.nextHyperCategoryObject = null;
            }

            if (
              selectedHyperCatIndex !== -1 &&
              selectedHyperCatIndex - 1 >= 0 &&
              state.hyperCategories.length > selectedHyperCatIndex - 1
            ) {
              this.previousHyperCategoryObject = _.cloneDeep(
                state.hyperCategories[selectedHyperCatIndex - 1]
              );
            } else {
              this.previousHyperCategoryObject = null;
            }
          }
        }
      });
  }

  goToNextHyperCategory() {
    // console.log("hyper categeories", this.hyperCategories);
    // console.log("selected hyper category", this.selectedHyperCategory);
    const previewsHyperCatIndex = _.findIndex(this.hyperCategories, {
      hyper_category_id: this.selectedHyperCategory,
    });
    // console.log("hyper cat length", this.hyperCategories.length);
    // console.log("preview cat length", previewsHyperCatIndex);
    if (this.hyperCategories.length > previewsHyperCatIndex + 1) {
      const nextHyperCategory = this.hyperCategories[previewsHyperCatIndex + 1];
      // console.log("netx hyper index", nextHyperCategory);
      this.store.dispatch(
        new HyperCategoriesActions.SetCurrentHyperCategory(
          _.cloneDeep(nextHyperCategory.hyper_category_id)
        )
      );
      if (navigator.cookieEnabled) {
        localStorage.setItem(
          "selectedHyperCategory",
          nextHyperCategory.hyper_category_id
        );
      }
    } else {
      const first: any = _.first(this.hyperCategories);
      if (first) {
        this.store.dispatch(
          new HyperCategoriesActions.SetCurrentHyperCategory(
            _.cloneDeep(first.hyper_category_id)
          )
        );
        if (navigator.cookieEnabled) {
          localStorage.setItem(
            "selectedHyperCategory",
            first.hyper_category_id
          );
        }
      }
    }
  }

  goToPreviousHyperCategory() {
    // console.log("hyper categeories", this.hyperCategories);
    // console.log("selected hyper category", this.selectedHyperCategory);
    const selectedHyperCategoryIndex = _.findIndex(this.hyperCategories, {
      hyper_category_id: this.selectedHyperCategory,
    });
    // console.log("hyper cat length", this.hyperCategories.length);
    // console.log("preview cat length", selectedHyperCategoryIndex);
    if (this.hyperCategories.length > selectedHyperCategoryIndex - 1) {
      const previousHyperCategory =
        this.hyperCategories[selectedHyperCategoryIndex - 1];
      //  console.log("netx hyper index", previousHyperCategory);
      this.store.dispatch(
        new HyperCategoriesActions.SetCurrentHyperCategory(
          _.cloneDeep(previousHyperCategory.hyper_category_id)
        )
      );
      if (navigator.cookieEnabled) {
        localStorage.setItem(
          "selectedHyperCategory",
          previousHyperCategory.hyper_category_id
        );
      }
    } else {
      const first: any = _.first(this.hyperCategories);
      if (first) {
        this.store.dispatch(
          new HyperCategoriesActions.SetCurrentHyperCategory(
            _.cloneDeep(first.hyper_category_id)
          )
        );
        if (navigator.cookieEnabled) {
          localStorage.setItem(
            "selectedHyperCategory",
            first.hyper_category_id
          );
        }
      }
    }
  }

  goToNext() {
    if (
      this.storeInfo &&
      this.storeInfo.design_settings &&
      this.storeInfo.design_settings.showOnlySelectedCategoryProducts
    ) {
      this.store
        .select("catalog")
        .pipe(first())
        .subscribe((catalogState) => {
          if (catalogState && catalogState.catalog) {
            console.log("catalogState", catalogState);
            const categories: any[] = _.cloneDeep(catalogState.catalog);
            if (this.selectedCategoryId === _.last(categories).category_id) {
              this.goToNextHyperCategory();
            } else {
              const cat_index = _.findIndex(categories, {
                category_id: this.selectedCategoryId,
              });
              if (cat_index !== -1 && categories[cat_index + 1]) {
                this.store.dispatch(
                  new CategoriesSelectionActions.SetSelectedCategory(
                    _.cloneDeep(categories[cat_index + 1].category_id)
                  )
                );
              }
            }
          }
        })
        .unsubscribe();
    } else {
      this.goToNextHyperCategory();
    }

    this.showButton = false;

    setTimeout(() => {
      this.showButton = true;
    }, 1000);
  }

  goToPrevious() {
    if (
      this.storeInfo &&
      this.storeInfo.design_settings &&
      this.storeInfo.design_settings.showOnlySelectedCategoryProducts
    ) {
      this.store
        .select("catalog")
        .pipe(first())
        .subscribe((catalogState) => {
          if (catalogState && catalogState.catalog) {
            console.log("catalogState", catalogState);
            const categories: any[] = _.cloneDeep(catalogState.catalog);
            if (this.selectedCategoryId === _.first(categories).category_id) {
              this.goToPreviousHyperCategory();
            } else {
              const cat_index = _.findIndex(categories, {
                category_id: this.selectedCategoryId,
              });
              if (cat_index !== -1 && categories[cat_index - 1]) {
                this.store.dispatch(
                  new CategoriesSelectionActions.SetSelectedCategory(
                    _.cloneDeep(categories[cat_index - 1].category_id)
                  )
                );
              }
            }
          }
        })
        .unsubscribe();
    } else {
      this.goToPreviousHyperCategory();
    }

    this.showButton = false;

    setTimeout(() => {
      this.showButton = true;
    }, 1000);
  }

  subscriptionToVerticalState() {
    if (!this.subscription3) {
      this.subscription3 = this.store
        .select("verticalCategory")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.selectedCategoryId &&
            !_.isEqual(this.selectedCategoryId, state.selectedCategoryId)
          ) {
            this.selectedCategoryId = _.cloneDeep(state.selectedCategoryId);
            this.store
              .select("catalog")
              .pipe(first())
              .subscribe((catalogState) => {
                if (catalogState && catalogState.catalog) {
                  const renderedCategories = _.cloneDeep(catalogState.catalog);

                  const selectedCatIndex = _.findIndex(catalogState.catalog, {
                    category_id: this.selectedCategoryId,
                  });
                  if (
                    selectedCatIndex !== -1 &&
                    renderedCategories[selectedCatIndex + 1]
                  ) {
                    this.nextCategoryObject = _.cloneDeep(
                      renderedCategories[selectedCatIndex + 1]
                    );
                  } else {
                    this.nextCategoryObject = null;
                  }
                  if (
                    selectedCatIndex !== -1 &&
                    selectedCatIndex !== 0 &&
                    renderedCategories[selectedCatIndex - 1]
                  ) {
                    this.previousCategoryObject = _.cloneDeep(
                      renderedCategories[selectedCatIndex - 1]
                    );
                  } else {
                    this.previousCategoryObject = null;
                  }
                }
              })
              .unsubscribe();
          }
        });
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
    if (this.subscription3) {
      this.subscription3.unsubscribe();
      this.subscription3 = null;
    }
  }
}
