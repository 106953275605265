<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">{{
      "select-fixed-points-address-modal.title" | translate
    }}</ion-title>
    <ion-buttons slot="end">
      <ion-button color="danger" fill="clear" (click)="ondismiss()">
        <ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content scrollX="false" scrollY="false">
  <ion-grid
    *ngIf="
      libraryLoading ||
      loading ||
      !fixedPointsAddresses ||
      fetchFixedPointsLoading
    "
  >
    <ion-row>
      <ion-col class="ion-text-center">
        <ion-spinner></ion-spinner>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid
    *ngIf="
      fixedPointsAddresses &&
      !fixedPointsAddresses.length &&
      !libraryLoading &&
      !loading &&
      !fetchFixedPointsLoading
    "
  >
    <ion-item lines="none">
      <ion-label class="ion-text-center ion-text-wrap">
        {{
          "select-fixed-points-address-modal.there-are-no-fixed-address-points"
            | translate
        }}
      </ion-label>
    </ion-item>
  </ion-grid>
  <ion-grid
    *ngIf="
      fixedPointsAddresses &&
      fixedPointsAddresses.length &&
      !libraryLoading &&
      !loading &&
      !fetchFixedPointsLoading
    "
    style="z-index: 20"
  >
    <ion-item
      style="border-radius: 5px"
      button="true"
      detail="false"
      color="primary"
      (click)="openFixedPointsAddressSelectionModal()"
    >
      <ion-label style="font-size: small; font-weight: bold">
        <ion-text
          *ngIf="
            !selected_fixed_address_id ||
            !fixedAddressPointsByFixedAddressId ||
            !fixedAddressPointsByFixedAddressId[selected_fixed_address_id]
          "
        >
          {{
            "select-fixed-points-address-modal.please-select-fixed-address-point"
              | translate
          }}
        </ion-text>
        <div
          *ngIf="
            selected_fixed_address_id &&
            fixedAddressPointsByFixedAddressId &&
            fixedAddressPointsByFixedAddressId[selected_fixed_address_id]
          "
        >
          {{
            fixedAddressPointsByFixedAddressId[selected_fixed_address_id].name
          }}
        </div>
        <div
          *ngIf="
            selected_fixed_address_id &&
            fixedAddressPointsByFixedAddressId &&
            fixedAddressPointsByFixedAddressId[selected_fixed_address_id]
              .address
          "
        >
          {{
            fixedAddressPointsByFixedAddressId[selected_fixed_address_id]
              .address.formatted_address
          }}
        </div>
      </ion-label>

      <ion-icon slot="end" src="assets/ionicons/chevron-forward-outline.svg">
      </ion-icon>
    </ion-item>
  </ion-grid>
  <ion-grid class="deliveryAreaCard ion-no-padding ion-no-margin">
    <ion-row
      *ngIf="renderCardDeliveryAreas && renderCardDeliveryAreas.length === 0"
      style="z-index: 20; max-width: 230px"
    >
      <ion-col size="12" class="ion-no-padding ion-no-margin">
        <ion-card
          class="ion-align-items-center ion-text-center ion-margin"
          *ngIf="
            renderCardDeliveryAreas && renderCardDeliveryAreas.length === 0
          "
          color="danger"
        >
          <ion-card-content style="margin: 0px; padding: 5px">
            <ion-label style="font-weight: 800">
              <ion-text color="white">
                {{
                  "select-fixed-points-address-modal.no-delivery-areas-that-serves-this-location"
                    | translate
                }}
              </ion-text>
            </ion-label>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
    <ion-row
      class="ion-no-padding ion-no-margin"
      style="z-index: 20; max-width: 230px"
      *ngIf="deliveryAreas && deliveryAreas.length"
    >
      <ion-col
        *ngIf="renderCardDeliveryAreas && renderCardDeliveryAreas.length"
        size="12"
        class="ion-no-padding ion-no-margin"
      >
        <ion-card
          detail="false"
          (click)="openDeliveryAreaInfoModal(area)"
          style="z-index: 21; margin: 5px"
          class="ion-align-items-center ion-text-center ion-no-margin ion-no-padding"
          button
          color="light"
          *ngFor="
            let area of renderCardDeliveryAreas
              | filterFixedPointsAddressAreas : true;
            trackBy: trackDeliveryAreas
          "
        >
          <ion-card-content style="margin: 0; padding: 0">
            <div
              *ngIf="area && area.fillColor"
              [ngStyle]="{
                'background-color': area && area.fillColor ? area.fillColor : ''
              }"
              value="1"
              style="height: 5px"
            ></div>
            <ion-label style="font-weight: 800">
              <div *ngIf="area.fixed_address_id">
                <ion-text color="dark">
                  {{ "delivery-areas.fixed-point-address" | translate }}
                </ion-text>
              </div>
              <div *ngIf="area.store">
                <ion-text color="dark"> {{ area.store }} </ion-text>
              </div>
              <div *ngIf="area">
                <ion-text color="dark" style="font-size: small">
                  {{ "delivery-areas.minimum-order" | translate }}&nbsp;{{
                    area.minOrder
                  }}€&nbsp;
                </ion-text>
              </div>
              <div *ngIf="area" style="font-size: small">
                <span
                  *ngIf="
                    area.deliveryCost &&
                    area.deliveryCost !== '0' &&
                    !area.delivery_cost_per_kilometer_active
                  "
                >
                  <ion-text color="dark"
                    >{{
                      "delivery-areas.cost-for-delivery" | translate
                    }}:&nbsp;{{ area.deliveryCost }}€</ion-text
                  >&nbsp;
                </span>

                <span
                  *ngIf="
                    area.deliveryCost &&
                    area.deliveryCost !== '0' &&
                    area.delivery_cost_per_kilometer_active
                  "
                >
                  <ion-text color="dark"
                    ><strong
                      >{{
                        "delivery-areas.cost-for-delivery" | translate
                      }}:</strong
                    >&nbsp;{{ area.deliveryCost }}€
                    {{ "delivery-areas.per" | translate }}
                    {{
                      area.step_active && area.step_distance
                        ? area.step_distance + " " + ("meters" | translate)
                        : "km"
                    }}</ion-text
                  >

                  <ion-icon
                    color="dark"
                    style="
                      vertical-align: middle;
                      margin-left: 3px;
                      font-size: 21px;
                    "
                    name="information-circle-outline"
                  >
                  </ion-icon>
                </span>
              </div>
              <div
                style="font-size: small"
                *ngIf="area && area.free_delivery_over_amount"
              >
                <ion-text color="danger">
                  {{ "delivery-areas.free-delivery-over" | translate }}
                  {{
                    area.free_delivery_over_amount | twoDecimalsRound : false
                  }}€
                </ion-text>
              </div>
              <div>
                <span *ngIf="area && area.scheduleComment && area.showSchedule">
                  <ion-text style="font-size: small" color="danger">
                    {{ area.scheduleComment }}
                  </ion-text>
                </span>
              </div>
              <div>
                <span *ngIf="area && area.comment">
                  <ion-text color="dark" style="font-size: small">{{
                    area.comment
                  }}</ion-text>
                </span>
              </div>
            </ion-label>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
  <div
    [hidden]="libraryLoading || loading || fetchFixedPointsLoading"
    id="map"
    style="width: 100%; height: 88%"
    data-tap-disabled="true"
  ></div>
</ion-content>

<ion-footer>
  <ion-grid>
    <ion-row style="width: 100%">
      <ion-col size="12" class="ion-text-center">
        <ion-button
          (click)="selectedFixedPointAddress()"
          [disabled]="
            !selected_fixed_address_id ||
            (renderCardDeliveryAreas && renderCardDeliveryAreas.length === 0)
          "
        >
          {{ "select-fixed-points-address-modal.continue" | translate }}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
