<span
  *ngFor="let option of store_info.cartCustomOptions; trackBy: trackTimestamp"
>
  <div
    *ngIf="
      !catalogMode &&
      option &&
      option.type !== 'plasticTax' &&
      option.type !== 'plastic_bag_with_limit' &&
      store_info.cartCustomOptionsActive &&
      cart.length > 0 &&
      option.active &&
      !option.only_kiosk &&
      (!dineIn || (dineIn && option.showInDinein)) &&
      option.qtyEnabled &&
      (!option.onlinePaymentOnly ||
        (selectedPaymentMethodID !== 'cash' &&
          selectedPaymentMethodID !== 'mobilePOS')) &&
      ((!option.pickupOnly && !option.deliveryOnly) ||
        (option.deliveryOnly && !option.pickupOnly && !orderPickup) ||
        (option.pickupOnly && !option.deliveryOnly && orderPickup))
    "
  >
    <ion-list class="ion-no-padding ion-no-margin">
      <ion-item-divider
        mode="ios"
        lines="none"
        class="mainFontSize"
        *ngIf="option.title"
      >
        <ion-label class="ion-text-wrap">
          <strong
            *ngIf="
              option.title &&
              (!option.translations ||
                (option.translations && !option.translations.title) ||
                (option.translations &&
                  option.translations.title &&
                  !option.translations.title[selectedLang]))
            "
            >{{ option.title }}</strong
          >
          <strong
            *ngIf="
              option.translations &&
              option.translations.title &&
              option.translations.title[selectedLang]
            "
          >
            {{ option.translations.title[selectedLang] }}</strong
          >
        </ion-label>
      </ion-item-divider>

      <ion-item
        lines="none"
        class="minHeightDescriptionItem"
        style="
          padding-top: 0;
          margin-top: 0;
          padding-bottom: 0;
          margin-bottom: 0;
        "
        *ngIf="option.description"
      >
        <ion-label class="ion-text-wrap">
          <p
            *ngIf="
              option.description &&
              (!option.translations ||
                (option.translations && !option.translations.description) ||
                (option.translations &&
                  option.translations.description &&
                  !option.translations.description[selectedLang]))
            "
          >
            {{ option.description }}
          </p>
          <p
            *ngIf="
              option.translations &&
              option.translations.description &&
              option.translations.description[selectedLang]
            "
          >
            {{ option.translations.description[selectedLang] }}
          </p>
        </ion-label>
      </ion-item>

      <app-checkbox-type-choices-quantity
        [selectedLang]="selectedLang"
        [option_timestamp]="option.timestamp"
        (click)="selectCustomOption(option)"
        *ngIf="
          option.type === 'checkbox' &&
          option.choices &&
          option.choices.length > 0
        "
      ></app-checkbox-type-choices-quantity>
      <app-radio-type-choices-quantity
        [selectedLang]="selectedLang"
        [option_timestamp]="option.timestamp"
        (click)="selectCustomOption(option)"
        *ngIf="option.type === 'radio'"
      ></app-radio-type-choices-quantity>
    </ion-list>
  </div>
  <div
    *ngIf="
      option &&
      option.type === 'plasticTax' &&
      (!dineIn || (dineIn && option.showInDinein))
    "
  >
    <app-plastic-bug-tax-option
      [catalogMode]="catalogMode"
      [selectedLang]="selectedLang"
      [option_timestamp]="option.timestamp"
      *ngIf="option.preNameId === 'plastic_bag_tax'"
    ></app-plastic-bug-tax-option>
    <app-plastic-wrapper-tax-option
      [catalogMode]="catalogMode"
      [selectedLang]="selectedLang"
      [option_timestamp]="option.timestamp"
      *ngIf="option.preNameId === 'plastic_wrapper_tax'"
    ></app-plastic-wrapper-tax-option>
  </div>

  <div
    *ngIf="
      option &&
      option.type === 'plastic_bag_with_limit' &&
      (!dineIn || (dineIn && option.showInDinein))
    "
  >
    <app-plastic-bug-with-limit-option
      [catalogMode]="catalogMode"
      [selectedLang]="selectedLang"
      [option_timestamp]="option.timestamp"
      *ngIf="option.preNameId === 'plastic_bag_with_limit'"
    ></app-plastic-bug-with-limit-option>
  </div>
</span>
