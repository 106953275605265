<ion-row
  *ngIf="!selectedProduct || (selectedProduct && !selectedProduct.options)"
>
  <ion-col class="ion-text-center">
    <ion-spinner></ion-spinner>
  </ion-col>
</ion-row>

<ion-list
  *ngIf="
    selectedProduct && selectedProduct.options && selectedProduct.options.length
  "
>
  <div *ngFor="let option of selectedProduct.options; trackBy: trackFnc">
    <span
      *ngIf="
        option &&
        option.option_id &&
        (!option.dependenciesVariable ||
          option.dependenciesVariable.length === 0 ||
          (option.dependenciesVariable.length > 0 && !option.dependentHidden) ||
          (option.dependenciesVariable.length > 0 &&
            option.dependentHidden &&
            option.showDependent))
      "
    >
      <app-checkbox-option
        *ngIf="option.type === 'checkbox' && !option.bundle"
        [product_id]="selectedProduct.product_id"
        [option_id]="option.option_id"
        [bundleMode]="bundleMode"
        (checkboxoptionChanged)="selectedProductChanged($event)"
        [must_select_something]="must_select_something"
        [selectedProductCategory]="selectedProduct.category"
        [disable_qty]="selectedProduct.disable_qty"
        [selectedProductPromo]="selectedProduct.promo"
        [selectedProductFreeParentOption]="selectedProduct.free_parent_option"
        [selectedProductId]="selectedProduct.selectedProductId"
        [productName]="selectedProduct.name"
        [selectedProductParentDiscount]="selectedProduct.parent_discount"
        [groupStoreData]="groupStoreData"
      ></app-checkbox-option>

      <app-radio-option
        *ngIf="option.type === 'radio' && !option.bundle"
        [option_id]="option.option_id"
        [disable_qty]="selectedProduct.disable_qty"
        (radioOptionChanged)="selectedProductChanged($event)"
        [must_select_something]="must_select_something"
        [selectedProductCategory]="selectedProduct.category"
        [selectedProductPromo]="selectedProduct.promo"
        [selectedProductFreeParentOption]="selectedProduct.free_parent_option"
        [selectedProductId]="selectedProduct.selectedProductId"
        [productName]="selectedProduct.name"
        [selectedProductParentDiscount]="selectedProduct.parent_discount"
        [groupStoreData]="groupStoreData"
      ></app-radio-option>

      <app-field-option
        *ngIf="option.type === 'field' && !option.bundle"
        [option_id]="option.option_id"
        (fieldOptionChanged)="selectedProductChanged($event)"
        [selectedProductCategory]="selectedProduct.category"
        [bundleMode]="bundleMode"
        [selectedProductPromo]="selectedProduct.promo"
        [selectedProductId]="selectedProduct.selectedProductId"
        [productName]="selectedProduct.name"
        [do_not_show_comments_option]="do_not_show_comments_option"
        [groupStoreData]="groupStoreData"
      ></app-field-option>

      <app-bundle-option
        *ngIf="option.bundle"
        [product_id]="selectedProduct.product_id"
        [option_id]="option.option_id"
        [type]="option.type"
        [disable_qty]="selectedProduct.disable_qty"
        (bundleOptionChanged)="selectedProductChanged($event)"
        [must_select_something]="must_select_something"
        [selectedProductCategory]="selectedProduct.category"
        [bundleMode]="bundleMode"
        [selectedProductPromo]="selectedProduct.promo"
        [selectedProductFreeParentOption]="selectedProduct.free_parent_option"
        [selectedProductId]="selectedProduct.selectedProductId"
        [productName]="selectedProduct.name"
        [selectedProductParentDiscount]="selectedProduct.parent_discount"
        [rootProductParentBundleChoice]="parentBundleChoice"
        [groupStoreData]="groupStoreData"
      >
      </app-bundle-option>
    </span>
  </div>
</ion-list>
