import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { ModalController } from "@ionic/angular";
import { KioskGetPromoModalComponent } from "../kiosk-get-promo-modal/kiosk-get-promo-modal.component";
import * as _ from "lodash";

@Component({
  selector: "app-kiosk-rewards-item",
  templateUrl: "./kiosk-rewards-item.component.html",
  styleUrls: ["./kiosk-rewards-item.component.scss"],
})
export class KioskRewardsItemComponent implements OnInit, OnDestroy {
  @Output() rewardsItemEventEmitter = new EventEmitter();
  @Input() reward: any;
  @Input() groupApp: any;
  @Input() showLoginRegisterButton;
  @Input() stores;
  private kioskQrInfoModal: any;
  constructor(private modalController: ModalController) {}

  ngOnInit() {}

  async openGetPromoModal(promo, type) {
    this.kioskQrInfoModal = await this.modalController.create({
      component: KioskGetPromoModalComponent,
      cssClass: "kioskGetPromoModal",
      backdropDismiss: false,
      componentProps: {
        promo: _.cloneDeep(promo),
        type: type,
        groupApp: this.groupApp,
        stores: this.stores,
        showLoginRegisterButton: this.showLoginRegisterButton,
      },
    });

    await this.kioskQrInfoModal.present();

    await this.kioskQrInfoModal.onDidDismiss().then((data) => {
      if (this.kioskQrInfoModal) {
        this.kioskQrInfoModal = null;
      }
      if (data && data.data && data.data === "navigate_coupons_tab") {
        setTimeout(() => {
          this.rewardsItemEventEmitter.emit("navigate_coupons_tab");
        }, 300);
      } else if (data && data.data && data.data === "showLoginRegister") {
        this.rewardsItemEventEmitter.emit("showLoginRegister");
      }
    });
  }

  ngOnDestroy(): void {
    if (this.kioskQrInfoModal) {
      this.kioskQrInfoModal.dismiss();
      this.kioskQrInfoModal = null;
    }
  }
}
