import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { AlertController, ModalController, Platform, ToastController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../store/app.reducer";
import * as _ from "lodash";
import * as LoyaltySystemActions from "../../store/loyalty-system.actions";
import { TranslateService } from "@ngx-translate/core";
import { auditTime, distinctUntilChanged, first } from "rxjs/operators";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-hall-of-fame",
  templateUrl: "./hall-of-fame.component.html",
  styleUrls: ["./hall-of-fame.component.scss"],
})
export class HallOfFameComponent implements OnInit, OnDestroy {
  subscription: any;
  hallOfFame: any;
  groupAppSub: any;
  groupApp: any;
  mo_token: any;
  unsubscribeBackEvent: any;
  scrollToUserTimeout: any;
  authSub: any;
  currentUser: any;
  loading: any;

  constructor(
    private modalController: ModalController,
    private dataStorageService: DataStorageService,
    private store: Store<fromApp.AppState>,
    private alertController: AlertController,
    private translateService: TranslateService,
    private toastController: ToastController,
    private platform: Platform,
    private router: Router,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(200, () => {
      this.closeModal();
    });
    this.fetchHallOfFame();
    this.subscription = this.store
      .select("loyaltySystem")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(200))
      .subscribe((state) => {
        if (state && state.hallOfFame && !_.isEqual(this.hallOfFame, state.hallOfFame)) {
          this.hallOfFame = _.cloneDeep(state.hallOfFame);
          this.changeDetector.detectChanges();
          this.scrollToUserTimeout = setTimeout(() => {
            const el = document.getElementById("currentUser");
            if (el) {
              el.scrollIntoView({ behavior: "smooth" });
            }
          }, 1000);
        }
      });

    this.groupAppSub = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(200))
      .subscribe((state) => {
        if (state && state.groupApp && !_.isEqual(this.groupApp, state.groupApp)) {
          this.groupApp = _.cloneDeep(state.groupApp);
        }
      });

    this.authSub = this.store
      .select("auth")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(200))
      .subscribe((state) => {
        if (state && state.user && !_.isEqual(this.currentUser, state.user)) {
          this.currentUser = _.cloneDeep(state.user);
        }
      });
  }

  fetchHallOfFame() {
    this.loading = true;
    this.store
      .select("auth")
      .pipe(first())
      .subscribe((state: any) => {
        if (state && state.mo_token) {
          this.mo_token = _.cloneDeep(state.mo_token);
          this.dataStorageService.fetchHallOfFame(this.mo_token).subscribe({
            next: async (res: any) => {
              if (res && res.success) {
                this.loading = false;
                console.log("fetch hall of fame", res);
                if (res.ranking) {
                  this.store.dispatch(new LoyaltySystemActions.SetHallOfFame(_.cloneDeep(res.ranking)));
                }
              } else {
                if (res.comment_id) {
                  this.loading = false;
                  const alert = await this.alertController.create({
                    header:
                      res && res.comment_id
                        ? this.translateService.instant(res.comment_id)
                        : this.translateService.instant("errorMessages.general_error"),
                    message: this.translateService.instant("cant-load-products"),
                    backdropDismiss: false,
                    buttons: ["OK"],
                  });
                  await alert.present();
                } else if (res.comments) {
                  this.loading = false;

                  this.presentErrorToast(res.comments, "danger");
                }
              }
            },
            error: async (err) => {
              this.loading = false;

              const errMsg = this.translateService.instant("errorMessages.error: There was an error on saving");
              this.presentErrorToast(errMsg, "danger");
              return err;
            },
          });
        }
      });
  }

  async presentErrorToast(message, color) {
    const errorToast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "close-outline",

          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
    });
    errorToast.present();
  }

  closeModal() {
    this.modalController.dismiss();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.groupAppSub) {
      this.groupAppSub.unsubscribe();
    }
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
    if (this.scrollToUserTimeout) {
      clearTimeout(this.scrollToUserTimeout);
    }
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    this.hallOfFame = null;
  }
}
