import { Injectable } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { ReservationCouponsSelectionModalComponent } from "./reservation-coupons-selection-modal/reservation-coupons-selection-modal.component";

@Injectable({
  providedIn: "root",
})
export class ReservationSystemModalsService {
  private reservationCouponsSelectionModal;
  constructor(private modalCtrl: ModalController) {}

  private async showReservationCouponsSelectionModal() {
    this.reservationCouponsSelectionModal = await this.modalCtrl.create({
      component: ReservationCouponsSelectionModalComponent,
      backdropDismiss: true,
      cssClass: "fullScreenModal",
      componentProps: {},
    });
    await this.reservationCouponsSelectionModal.present();
    this.reservationCouponsSelectionModal.onDidDismiss().then(() => {
      this.reservationCouponsSelectionModal = null;
    });
  }

  public presentReservationCouponsSelectionModal() {
    if (!this.reservationCouponsSelectionModal) {
      this.reservationCouponsSelectionModal = true;
      this.showReservationCouponsSelectionModal();
    }
  }

  public closeReservationCouponsSelectionModal() {
    console.log(
      "closeReservationCouponsSelectionModal",
      this.reservationCouponsSelectionModal
    );
    if (this.reservationCouponsSelectionModal) {
      this.reservationCouponsSelectionModal.dismiss();
      this.reservationCouponsSelectionModal = null;
    }
  }
}
