<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button fill="clear" (click)="closeModal()">
        <ion-icon src="assets/ionicons/close.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <ion-text>{{ contest.title }}</ion-text>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid style="max-width: 600px">
    <ion-row style="width: 100%">
      <ion-col>
        <ion-item lines="none" *ngIf="contest.long_description">
          <ion-label class="ion-text-wrap" style="line-height: 1">
            <ion-text style="font-size: small; font-weight: bold" [innerHTML]="contest.long_description">
            </ion-text>
          </ion-label>
        </ion-item>
        <ion-card style="margin-top: 20px">
          <ion-item-divider mode="ios" color="primary">{{
            "loyaltySystem.my-entries" | translate
          }}</ion-item-divider>
          <p style="opacity: 0.5" *ngIf="contestCustomerDraws === null && currentUser" class="ion-text-center">
            <ion-spinner> </ion-spinner>
          </p>
          <p style="opacity: 0.5" *ngIf="contestCustomerDraws?.length === 0" class="ion-text-center">
            {{ "loyaltySystem.no-entries" | translate }}
          </p>
          <ion-grid *ngIf="contestCustomerDraws?.length === 0" style="max-width: 180px">
            <ion-row>
              <ion-col>
                <ng-lottie [options]="noDrawsOptions"> </ng-lottie>
              </ion-col>
            </ion-row>
          </ion-grid>
          <p style="opacity: 0.5" *ngIf="!currentUser" class="ion-text-center">
            {{ "loyaltySystem.log-in-to-see-entries" | translate }}
          </p>

          <ion-grid *ngIf="!currentUser" class="ion-text-center">
            <ion-grid *ngIf="profileAnimation" style="width: 100px; height: 100px">
              <ng-lottie [options]="profileAnimationOptions"></ng-lottie>
            </ion-grid>
            <ion-button [disabled]="!loginLoading" color="primary" size="medium" (click)="presentMagicLoginPopup()">
              <ion-text *ngIf="loginLoading">
                {{ "homepage.magic-login" | translate }}
              </ion-text>
              <ion-spinner *ngIf="!loginLoading"></ion-spinner>
            </ion-button>
          </ion-grid>
          <!-- <ion-row>
            <ion-col class="ion-text-center">
              <div style="max-width: 150px; display: inline-block">
                <ng-lottie
                  (animationCreated)="noDrawsAnimationCreated($event)"
                  [options]="noDrawsOptions"
                ></ng-lottie>
              </div>
            </ion-col>
          </ion-row> -->
          <!-- draws -->
          <ion-row *ngIf="contestCustomerDraws && contestCustomerDraws.length > 0">
            <ion-col class="ion-text-center">
              <ion-item [class]="draw.status + '_border'" lines="full" *ngFor="let draw of contestCustomerDraws">
                <ion-label class="ion-text-wrap">
                  <p style="font-weight: bold" *ngIf="
                      draw.type && draw.type === 'fromOrders' && draw.order_id
                    ">
                    {{ "loyaltySystem.from-order" | translate }}: #{{
                      draw.order_id
                    }}
                    {{ draw.short_order_id ? "(" : ""
                    }}{{ draw.short_order_id ? draw.short_order_id : ""
                    }}{{ draw.short_order_id ? ")" : "" }}
                  </p>

                  <p style="font-weight: bold" *ngIf="draw.type && draw.type === 'fromPoints'">
                    {{ "loyaltySystem.from-points" | translate }}
                  </p>
                  <h2 *ngIf="draw.firstName || draw.lastName">
                    {{ "loyaltySystem.loyalty-contests.name" | translate }}:
                    {{ draw?.firstName }}
                    {{ draw?.lastName }}
                  </h2>
                  <h2 *ngIf="draw.mobile">
                    {{ "loyaltySystem.loyalty-contests.mobile" | translate }}:
                    {{ draw.mobile }}
                  </h2>
                  <p>
                    <!-- {{ "loyaltySystem.loyalty-contests.date" | translate }}: -->
                    {{ draw.timestamp | date : "dd/MM/yyyy HH:mm" }}
                  </p>
                  <p *ngIf="draw.type && draw.type === 'fromPoints'">
                    {{
                      "loyaltySystem.loyalty-contests.old_points" | translate
                    }}: {{ draw.old_points }} |
                    {{
                      "loyaltySystem.loyalty-contests.new_points" | translate
                    }}:
                    {{ draw.new_points }}
                  </p>

                  <p *ngIf="!bigScreenStyle" class="ion-text-center">
                    <ion-badge [color]="
                        draw.status === 'complete'
                          ? 'dark'
                          : draw.status === 'canceled'
                          ? 'danger'
                          : 'complete'
                      ">
                      {{ "loyaltySystem.loyalty-contests.draws" | translate }}:
                      {{ draw.draws }}
                    </ion-badge>
                  </p>

                  <div *ngIf="draw.status && draw.status === 'canceled'">
                    <ion-badge style="font-size: small; font-weight: bold" [color]="'danger'">
                      {{ "loyaltySystem." + draw.status | translate }}
                    </ion-badge>
                  </div>
                </ion-label>
                <ion-badge [color]="
                    draw.status === 'complete'
                      ? 'dark'
                      : draw.status === 'canceled'
                      ? 'danger'
                      : 'complete'
                  " *ngIf="bigScreenStyle">
                  {{ "loyaltySystem.loyalty-contests.draws" | translate }}:
                  {{ draw.draws }}
                </ion-badge>
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-card>
        <ion-card *ngIf="customer_draw_numbers && customer_draw_numbers.length > 0">
          <ion-item-divider mode="ios" color="primary">{{
            "loyaltySystem.my-draws" | translate
          }}</ion-item-divider>
          <ion-row *ngIf="!winner_draws || winner_draws.length===0 && contest.winners && contest.winners.length > 0"
            class="ion-margin">
            <ion-text style="font-weight:bold">
              {{
                "loyaltySystem.no-winner-draws" | translate
              }}

            </ion-text>
          </ion-row>
          <ion-row *ngIf="winner_draws && winner_draws.length > 0" class="ion-margin">
            <ion-text *ngIf="winner_draws && winner_draws.length > 1" style="font-weight:bold">
              {{
                "loyaltySystem.winner-draws-message" | translate
              }}:
              <span
                style="font-weight:bold; font-size:large; background-color: black; color:white; border-radius: 3px; padding:3px; margin:5px"
                *ngFor="let draw of winner_draws">#{{draw.lottery_number}}</span>

            </ion-text>
            <ion-text *ngIf="winner_draws && winner_draws.length === 1" style="font-weight:bold">
              {{
                "loyaltySystem.winner-draw-message" | translate
              }}: <span
                style="font-weight:bold; font-size:large; background-color: black; color:white; border-radius: 3px; padding:3px; margin:5px">#{{winner_draws[0].lottery_number}}</span>
            </ion-text>
          </ion-row>
          <ion-row *ngIf="alternate_draws && alternate_draws.length > 0" class="ion-margin">
            <ion-text style="font-weight:bold">
              {{
                "loyaltySystem.alternate-draw-message" | translate
              }}: <span *ngFor="let draw of alternate_draws"
                style="font-weight:bold; font-size:large; background-color: black; color:white; border-radius: 3px; padding:3px; margin:5px">#{{draw.lottery_number}}</span>
            </ion-text>
            <ion-text style="font-weight:bold">
              {{
                "loyaltySystem.alternate-draw-message-2" | translate
              }}

            </ion-text>

          </ion-row>
          <ion-row class="ion-text-justify" style="width:100%">
            <ion-col size-xs="3" size-sm="2" *ngFor="let draw_number of customer_draw_numbers"
              class="ion-align-items-center ion-text-center">
              <div class="draw_number" [ngStyle]="{
                'background-color': draw_number.isWinner ? '#ff4961' : draw_number.isAlternate ? '#ffd534' : '#0ab900'
              }">
                <ion-text>
                  #{{draw_number.lottery_number}}
                </ion-text>
              </div>
            </ion-col>
          </ion-row>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>