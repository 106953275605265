import { Action } from "@ngrx/store";

export const SET_WINDOW_DIMENSIONS = "[WINDOW INFO] Set Window Dimensions";
export const SET_SIDE_MENU_IS_OPEN = "[WINDOW INFO] Set Side Menu Is Open";

export const SET_CALCULATE_WINDOW_DIMENSIONS_TIMESTAMP =
  "[WINDOW INFO] Set Calculate Window Dimensions Timestamp";
export const CLEAR_WINDOW_DIMENSIONS = "[WINDOW INFO] Clear Window Dimensions";

export class SetWindowDimensions implements Action {
  readonly type = SET_WINDOW_DIMENSIONS;
  constructor(public payload: { height: any; width: any }) {}
}

export class SetSideMenuIsOpen implements Action {
  readonly type = SET_SIDE_MENU_IS_OPEN;
  constructor(public payload: boolean) {}
}

export class SetCalculateWindowDimenionsTimestamp implements Action {
  readonly type = SET_CALCULATE_WINDOW_DIMENSIONS_TIMESTAMP;
  constructor(public payload: any) {}
}

export class ClearWindowDimensions implements Action {
  readonly type = CLEAR_WINDOW_DIMENSIONS;
  constructor() {}
}
export type WindowInfoActions =
  | SetWindowDimensions
  | SetSideMenuIsOpen
  | SetCalculateWindowDimenionsTimestamp
  | ClearWindowDimensions;
